import _ from 'lodash'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { Candidate, actions } from 'builder/modules/resumeDistribution'
import useWebsiteHost from 'builder/components/CIO-Dasboard-2.0/hooks/useWebsiteHost'

import {
  Container,
  Section,
  Box,
  Header,
  SubHeader,
  UserName,
  JobTitle,
  EditButton,
  EditButtonIcon,
} from './styles'
import { ReplaceResume } from './ReplaceResume/ReplaceResume'
import { CURRENCY_SIGN } from './types'

interface DashboardHeaderProps {
  candidate: Candidate
}

const DashboardHeader = ({ candidate }: DashboardHeaderProps) => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const { isPhone, isTablet } = useMediaQueries()
  const { getHost } = useWebsiteHost()

  const [isMenuOpen, toggleMenu] = useState(false)
  const navigate = useNavigate()

  const getLocationObject = (
    candidate: Candidate,
    property: 'location' | 'preferences',
    key: 'country' | 'state' | 'city',
  ) => {
    return {
      id: _.get(candidate, `${property}.${key}Id`),
      text: _.get(candidate, `${property}.${key}Name`),
    }
  }

  const editInfo = async () => {
    dispatch(
      actions.setPersonalDetailsForm({
        firstName: candidate.firstName,
        lastName: candidate.lastName,
        jobTitle: candidate.jobTitle,
        email: candidate.email,
        linkedin: candidate.linkedinUrl,
        fullAddress: candidate.fullAddress,
        edit_file_name: candidate.fileName,
      }),
    )
    dispatch(
      actions.setJobPreferencesForm({
        country: getLocationObject(candidate, 'preferences', 'country'),
        state: getLocationObject(candidate, 'preferences', 'state'),
        industry: candidate.preferences.industryId,
        remote: candidate.preferences.remote.toString(),
        salaryCurrency: candidate.preferences.desiredSalary.currency,
        salaryAmount: candidate.preferences.desiredSalary.amount,
        salaryPeriod: candidate.preferences.desiredSalary.per.toLowerCase(),
      }),
    )
    dispatch(actions.setIsEdit(true))
    trackInternalEvent('rd_edit_settings', {
      website_host: getHost,
    })
    navigate('/resume-distribution/edit', { replace: true })
  }

  const getAnnualSalary = () => {
    return `${
      candidate?.preferences.desiredSalary.currency &&
      CURRENCY_SIGN[candidate.preferences.desiredSalary.currency]
    } ${candidate?.preferences.desiredSalary.amount}`
  }

  const renderEditButtonSection = () => (
    <Section size={0.5}>
      <Box position="end">
        <EditButton onClick={editInfo}>
          <EditButtonIcon />
        </EditButton>
      </Box>
    </Section>
  )

  const renderDetailsSection = (label: string, value: string) => (
    <Section size={1.25}>
      <Box>
        <SubHeader>{i18n.t(`builder.resume_distribution.dashboard.header.${label}`)}</SubHeader>
        <Header>{value}</Header>
      </Box>
    </Section>
  )

  return (
    <Container>
      <Section size={1.25}>
        <Box>
          <UserName>
            {candidate?.firstName} {candidate?.lastName}
          </UserName>
          <JobTitle>{candidate?.jobTitle}</JobTitle>
        </Box>
      </Section>
      {(isPhone || isTablet) && renderEditButtonSection()}
      <Section size={0.75}>
        <Box>
          <ReplaceResume toggle={toggleMenu} show={isMenuOpen} candidate={candidate} />
        </Box>
      </Section>

      {renderDetailsSection('industry', candidate?.preferences?.industryName)}
      {renderDetailsSection('location', candidate?.fullAddress)}
      {renderDetailsSection('annual_base_salary', getAnnualSalary())}
      {!(isPhone || isTablet) && renderEditButtonSection()}
    </Container>
  )
}

export default DashboardHeader
