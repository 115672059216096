import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import { Props } from './types'

export const Container = styled.div<Props>`
  --avatar-size: ${props => props.avatarSize ?? '38px'};
  --border-size: ${props => props.avatarSize ?? '3px'};
  --mobile-avatar-size: ${props => props.mobileAvatarSize ?? '32px'};
  --mobile-border-size: ${props => props.mobileBorderSize ?? '3px'};
  --calculated-avatar-size: calc(var(--avatar-size) + 2 * var(--border-size));

  width: calc(var(--calculated-avatar-size) * 2.5);
  height: var(--calculated-avatar-size);
  position: relative;

  ${Media.Phone`
    --avatar-size: var(--mobile-avatar-size);
    --border-size: var(--mobile-border-size);
  `};
`

export const Avatar = styled.img`
  height: var(--avatar-size);
  width: var(--avatar-size);
  box-sizing: content-box;
  border: var(--border-size) solid ${Colors.White};
  border-radius: 50%;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;

  &:nth-child(2) {
    left: calc(var(--calculated-avatar-size) * 0.75);
    z-index: 2;
  }

  &:nth-child(3) {
    left: calc(var(--calculated-avatar-size) * 1.5);
    z-index: 1;
  }
`
