import { useDispatch } from 'react-redux'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import { CareerPathQuestionnaire } from 'builder/components/CareerPathQuestionnaire'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { actions, CareerPathStep, selectors } from 'builder/modules/careerPath'
import { CareerPathLevelChoice } from 'builder/components/CareerPathLevelChoice'
import { CareerPathConfirmation } from 'builder/components/CareerPathConfirmation'
import * as Styles from './styles'

export const CareerPathView = () => {
  const dispatch = useDispatch()
  const step = useTypedSelector(selectors.step)
  const prevStep = useTypedSelector(selectors.prevStep)

  const stepProps = {
    currentStep: step,
    prevStep: prevStep,
  }

  useEffectOnMount(() => {
    dispatch(actions.setStep(CareerPathStep.QUESTIONNAIRE))
  })

  if (step === CareerPathStep.LEVEL_CHOICE) {
    return (
      <Styles.Container step={stepProps}>
        <CareerPathLevelChoice />
      </Styles.Container>
    )
  }

  if (step === CareerPathStep.CONFIRMATION) {
    return (
      <Styles.Container step={stepProps}>
        <CareerPathConfirmation />
      </Styles.Container>
    )
  }

  return (
    <Styles.Container step={stepProps}>
      <CareerPathQuestionnaire />
    </Styles.Container>
  )
}
