import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

export const ImagesContainer = styled.div`
  width: 312px;
  height: 156px;
  margin-top: 48px;
  position: relative;

  ${Media.Tablet`
    width: 220px;
    height: 110px;
    order: 1;
    margin-top: 0;
    margin-bottom: 38px;
  `};

  ${Media.Phone`
    width: 128px;
    height: 64px;
    margin-bottom: 34px;
  `};
`

export const PersonOne = styled.img`
  position: absolute;
  width: 156px;
  height: 156px;
  border-radius: 1000px;
  border: 4px solid ${Colors.Indigo10};
  z-index: 3;

  ${Media.Tablet`
    width: 110px;
    height: 110px;
  `};

  ${Media.Phone`
    width: 64px;
    height: 64px;
  `};
`

export const PersonTwo = styled.img`
  position: absolute;
  width: 156px;
  height: 156px;
  border-radius: 1000px;
  border: 4px solid ${Colors.Indigo10};
  z-index: 2;
  left: 74px;

  ${Media.Tablet`
    width: 110px;
    height: 110px;
    left: 52px;
  `};

  ${Media.Phone`
    width: 64px;
    height: 64px;
    left: 28px;
  `};
`

export const PersonThree = styled.img`
  position: absolute;
  width: 156px;
  height: 156px;
  border-radius: 1000px;
  border: 4px solid ${Colors.Indigo10};
  z-index: 1;
  left: calc(74px * 2);

  ${Media.Tablet`
    width: 110px;
    height: 110px;
    left: calc(52px * 2);
  `};

  ${Media.Phone`
    width: 64px;
    height: 64px;
    left: calc(28px * 2);
  `};
`
