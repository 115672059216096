import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 832px;
  height: calc(100vh - 64px);
  max-height: 720px;
  background-color: ${Colors.Neutral10};
  border-radius: 6px;

  ${Media.Tablet`
    max-width: 680px;
    height: 640px;
  `}

  ${Media.Phone`
    border-radius: 0;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
  `};
`

export const CloseButton = styled.button`
  position: absolute;
  top: 0px;
  right: 0px;
  color: ${Colors.Neutral30};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border: none;
  cursor: pointer;
  z-index: 10;

  &:focus,
  &:hover {
    outline: none;
    color: ${Colors.Neutral40};
  }

  ${Media.Phone`
    width: 32px;
    height: 32px;
    top: 20px;
    padding: 0;
    right: 20px;
    border-radius: 50%;
    color: ${Colors.White};
    background-color: ${Colors.Neutral30};

    &:hover {
      color: ${Colors.White};
      background-color: ${Colors.Neutral40};
    }
  `}
`
