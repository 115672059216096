import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'

type DrawerProps = {
  $isPhone: boolean
  $animationDurationMs: number
  $isDragging: boolean
}

export const openedHeight = `100dvh - 56px`

export const phoneHeight = '160px'

export const tabletHeight = '170px'

export const Drawer = styled.div<DrawerProps>(props => {
  const { $isPhone, $animationDurationMs, $isDragging } = props
  return css`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: inherit;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.5);
    border-radius: 16px 16px 0px 0px;
    min-height: ${$isPhone ? phoneHeight : tabletHeight};
    background: ${Colors.White};

    ${!$isDragging &&
    css`
      transition: height ${$animationDurationMs}ms ease-in;
    `}
  `
})

export const Header = styled.div`
  width: 100%;
  padding: 8px 4px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const PullContainer = styled.div`
  display: flex;
  padding: 8px 175px 4px 175px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background-color: inherit;
  cursor: grab;
`

export const Pull = styled.div`
  width: 25px;
  height: 4px;
  border-radius: 4px;
  background: ${Colors.Neutral30};
  user-select: none;
`

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 20px;
`

export const BackDrop = styled.div`
  width: 100%;
  height: 100%;
  background: ${Colors.Neutral90};
  opacity: 0.8;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 200ms ease-in;
`
