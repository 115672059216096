import { useState, useEffect, useCallback } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { actions as uiActions } from 'builder/modules/ui'
import { apiClient } from 'builder/modules/apiClient'
import { TABS } from './constants'

interface Props {
  isOpen: boolean
  changeTab: (name: TABS) => void
  isTrDoc?: boolean
}

/**
 * The hook that intercepts `/resumes/:id/statistics` URL,
 * opens the sharing modal and switches it to the "Statistics" tab
 */
export const useRouteInterceptor = ({ isOpen, changeTab, isTrDoc = false }: Props): void => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const match = useMatch('/resumes/:id/statistics')
  const docsMatch = useMatch('/documents/:id/statistics')

  const openSharingModal = useCallback(
    (id: number) => {
      // Trigger modal opening
      isTrDoc
        ? dispatch(uiActions.openSharingTrDocModal(id))
        : dispatch(uiActions.openSharingModal(id))
      // Switch to "Statistics" tab
      changeTab(TABS.stats)
      // Redirect to the regular editor URL
      isTrDoc
        ? navigate(`/documents/${id}/edit`, { replace: true })
        : navigate(`/resumes/${id}/edit`, { replace: true })
    },
    [isTrDoc, dispatch, changeTab, navigate],
  )

  useEffect(() => {
    if (!isOpen && match) openSharingModal(Number(match.params.id))
    if (!isOpen && docsMatch) openSharingModal(Number(docsMatch.params.id))
  }, [docsMatch, isOpen, isTrDoc, match, openSharingModal])
}

/**
 * The hook that activates the sharing feature for the resume
 * and returns its `secureId`
 */
export const useFeatureActivator = (resumeId: number | null, trDocId: number | null): string => {
  const [publicUrl, setPublicUrl] = useState('')

  useEffect(() => {
    let isMounted = true

    if (resumeId) {
      apiClient
        .post<{ id: number; publicUrl: string }>(`/sharing/${resumeId}/activate`)
        .then(({ data }) => {
          if (isMounted && data.id === resumeId) setPublicUrl(data.publicUrl)
        })
    }

    if (trDocId) {
      apiClient
        .post<{ id: number; publicUrl: string }>(`/sharing/${trDocId}/activate`)
        .then(({ data }) => {
          if (isMounted && data.id === trDocId) setPublicUrl(data.publicUrl)
        })
    }

    return () => {
      isMounted = false
    }
  }, [resumeId, trDocId])

  return publicUrl
}
