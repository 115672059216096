import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as userSelectors } from 'builder/modules/user'

export const useRemainingAiProfileAttempts = () => {
  const isPremiumUser = useTypedSelector(userSelectors.premiumAccess)
  const aiProfileFreeAttempts = useTypedSelector(userSelectors.aiProfileFreeAttempts)

  if (isPremiumUser) {
    return Infinity
  }

  return aiProfileFreeAttempts || 0
}
