import { flatMap } from 'lodash'
import { useDispatch } from 'react-redux'
import { Fragment } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import Foldable from 'builder/components/Foldable'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { HeadersType, actions, selectors, Label } from 'builder/modules/careerPlanning'
import { setUserLastSection, updateSearchParams } from 'builder/modules/careerPlanning/utils'
import {
  Wrapper,
  OptionId,
  OptionText,
  SubLevelText,
  HorizontalLine,
  LevelContainer,
  SubLevelContainer,
  FirstLevelContainer,
} from './styles'
import {
  getSecondLevelIds,
  onTopLevelClickUpdateFilters,
  onSecondLevelClickUpdateFilters,
} from './utils'

type Props = {
  handleClick?: () => void
}

const CareerPlanningLevels = ({ handleClick }: Props) => {
  const dispatch = useDispatch()
  const step = useTypedSelector(selectors.step)
  const headers = useTypedSelector(selectors.headers)
  const openContents = useTypedSelector(selectors.openContents)
  const secondLevelId = useTypedSelector(selectors.secondLevelId)
  const filterOptions = useTypedSelector(selectors.filterOptions)

  const completedContents = useTypedSelector(selectors.completedContents)

  const activeFilter = filterOptions.find(option => option.isActive)?.status as string
  const eventName = 'click_subsection'

  const handleTopLevelClick = (topLevelId: number) => {
    const secondLevelIds = flatMap(getSecondLevelIds(topLevelId, headers))
    const index = headers.findIndex(header => header.id === topLevelId)

    dispatch(actions.setStep(topLevelId))
    dispatch(actions.setSecondLevelId(headers[index].secondLevel[0].id))

    updateSearchParams(topLevelId, headers[index].secondLevel[0].id)
    setUserLastSection(topLevelId, headers[index].secondLevel[0].id)

    const updatedFilterOptions = onTopLevelClickUpdateFilters(
      activeFilter,
      filterOptions,
      secondLevelIds,
      openContents,
      completedContents,
    )

    if (updatedFilterOptions) {
      dispatch(actions.updateFilterOptions(updatedFilterOptions))
    }

    dispatch(actions.setIsObserving(true))
    dispatch(actions.setIsScrollToView(true))
    dispatch(actions.setTriggerEvent(true))

    if (handleClick) handleClick()
  }

  const handleSecondLevelClick = (
    secondLevelId: number,
    subsectionName: string,
    sectionName: string,
  ) => {
    trackInternalEvent(eventName, {
      section_name: sectionName,
      subsection_name: subsectionName,
      ...Label,
    })

    dispatch(actions.setSecondLevelId(secondLevelId))
    dispatch(actions.setIsObserving(true))
    dispatch(actions.setIsScrollToView(true))
    dispatch(actions.setTriggerEvent(true))

    updateSearchParams(step, secondLevelId)
    setUserLastSection(step, secondLevelId)

    const updatedFilterOptions = onSecondLevelClickUpdateFilters(
      activeFilter,
      filterOptions,
      secondLevelId,
      openContents,
      completedContents,
    )

    if (updatedFilterOptions) {
      dispatch(actions.updateFilterOptions(updatedFilterOptions))
    }

    if (handleClick) handleClick()
  }

  return (
    <Wrapper>
      {headers?.map((level: HeadersType, index: number) => (
        <Fragment key={level.id}>
          <LevelContainer>
            <FirstLevelContainer>
              <OptionId>{index + 1}</OptionId>
              <OptionText
                isSelected={step === level.id}
                onClick={() => handleTopLevelClick(level.id)}
              >
                {level.title}
              </OptionText>
            </FirstLevelContainer>
            <SubLevelContainer>
              <Foldable isOpen={step === level.id}>
                {level.secondLevel.length &&
                  level.secondLevel.map(secondLevel => (
                    <SubLevelText
                      key={secondLevel.id}
                      isSelected={secondLevelId === secondLevel.id}
                      onClick={() =>
                        handleSecondLevelClick(secondLevel.id, secondLevel.title, level.title)
                      }
                    >
                      {secondLevel.title}
                    </SubLevelText>
                  ))}
              </Foldable>
            </SubLevelContainer>
          </LevelContainer>
          <HorizontalLine isLastIndex={index === headers.length - 1} />
        </Fragment>
      ))}
    </Wrapper>
  )
}

export default CareerPlanningLevels
