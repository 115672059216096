import { useDispatch } from 'react-redux'
import { FormEvent, useCallback } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { actions, selectors, CareerPathStep, Label } from 'builder/modules/careerPath2'

import Header from './components/Header'
import Footer from './components/Footer'
import InputField from './components/InputField'
import { SuggestedSkills } from './components/SuggestedSkills'

import { Container, FormContainer } from './styles'

const MINIMUM_SKILLS = 5

export const Form = () => {
  const dispatch = useDispatch()
  const skills = useTypedSelector(selectors.skills)
  const occupation = useTypedSelector(selectors.occupation)
  const suggestedSkills = useTypedSelector(selectors.suggestedSkills)
  const level1JobTitles = useTypedSelector(selectors.level1JobTitles)

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault()

      if (skills.length >= MINIMUM_SKILLS) {
        if (!level1JobTitles.length) {
          const params = {
            query: occupation,
          }

          dispatch(actions.fetchJobTitles(params))
        }

        dispatch(actions.setStep(CareerPathStep.JOB_PROGRESSION))
        trackInternalEvent('confirm_career_path_welcome', Label)
      } else {
        dispatch(actions.setValidationError(true))
      }
    },
    [dispatch, skills.length, occupation, level1JobTitles],
  )

  return (
    <Container>
      <FormContainer onSubmit={handleSubmit}>
        <Header title="Add your skill set" subtitle="This will help us calculate your skill gap" />
        <InputField />
        {suggestedSkills.length > 0 && <SuggestedSkills />}
        <Footer />
      </FormContainer>
    </Container>
  )
}
