import { RefObject, useLayoutEffect, useRef, useState } from 'react'
import { Icon20 } from 'builder/components/Icon'
import { reflow } from 'builder/utils/reflow'
import { SuggestionConfig } from 'builder/components/RichTextArea/types'
import { AiKeywordsTogglerForPopups, AiIconBadge } from 'builder/components/AiFeatures/Keywords'
import { useClickOutsideExcept } from 'builder/hooks/useClickOutsideExcept'
import { useEscapeHandler } from 'builder/hooks/useEscapeHandler'
import { AddPreWrittenPhrasesButton } from 'builder/components/AiProfileSummary'
import { useI18n } from 'builder/hooks/useI18n'
import { Container, Divider, Title } from './styles'

type Props = {
  suggestionConfig: SuggestionConfig
  phrasesOpenerRef: RefObject<HTMLDivElement>
  editorContainerRef: RefObject<HTMLElement>
  onClose: () => void
  onAddPrewrittenPhrasesClick: () => void
}

export const SuggestionVariantsPopup = (props: Props) => {
  const {
    suggestionConfig: { keywordsConfig },
    phrasesOpenerRef,
    editorContainerRef,
    onClose,
    onAddPrewrittenPhrasesClick,
  } = props
  const [isVisible, setVisible] = useState(false)
  const container = useRef<HTMLDivElement>(null)
  const { i18n } = useI18n()

  useLayoutEffect(() => {
    if (container.current) reflow(container.current)
    setVisible(true)
  }, [])

  useClickOutsideExcept(container, [phrasesOpenerRef, editorContainerRef], onClose)

  useEscapeHandler(onClose)

  return (
    <Container ref={container} isVisible={isVisible}>
      <Title>{i18n.t('builder.resume_editor.ai_writer_dialog.title')}</Title>

      <AddPreWrittenPhrasesButton
        onClick={() => {
          onAddPrewrittenPhrasesClick()
          onClose()
        }}
      >
        <AiIconBadge>
          <Icon20.Featured />
        </AiIconBadge>
        {i18n.t('builder.ai_profile_summary.option.add_prewritten_phrases')}
      </AddPreWrittenPhrasesButton>

      {keywordsConfig && (
        <>
          <Divider />
          <AiKeywordsTogglerForPopups
            section={keywordsConfig.section}
            text={keywordsConfig.buttonText}
            onClick={onClose}
          />
        </>
      )}
    </Container>
  )
}
