import { Container, Text, SalaryInfo, Salary, Duration, DurationText } from './styles'

interface Props {
  duration: string
  avgSalary: number
  onChange: () => void
}

const SalaryFigure = ({ duration, avgSalary, onChange }: Props) => {
  return (
    <Container>
      <SalaryInfo>
        <Salary>${avgSalary.toLocaleString()}</Salary>
        <Duration>
          /{' '}
          <DurationText data-testid="duration" onClick={onChange}>
            {duration}
          </DurationText>
        </Duration>
      </SalaryInfo>
      <Text>Average Salary</Text>
    </Container>
  )
}

export default SalaryFigure
