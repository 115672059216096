import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import ShowMoreText from 'builder/components/ShowMoreText'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors, actions } from 'builder/modules/careerPlanning'
import { Container, Title, Description } from './styles'

type Props = {
  title: string
  description: string
  id: number
}

const CHAR_LIMIT = 210

const Header = ({ title, description, id }: Props) => {
  const dispatch = useDispatch()
  const secondLevelId = useTypedSelector(selectors.secondLevelId)
  const firstContentOffset = useTypedSelector(selectors.firstContentOffset)
  const contentRef = useRef<HTMLDivElement>(null)
  const { current } = contentRef

  useEffect(() => {
    if (current && secondLevelId === id) {
      dispatch(actions.setContentOffset(current.offsetTop))

      if (!firstContentOffset) {
        dispatch(actions.setFirstContentOffset(current.offsetTop))
      }
    }
  }, [dispatch, id, current, secondLevelId, firstContentOffset])

  return (
    <Container ref={contentRef}>
      <Title>{title}</Title>
      <Description>
        {description && <ShowMoreText charLimit={CHAR_LIMIT} text={description} />}
      </Description>
    </Container>
  )
}

export default Header
