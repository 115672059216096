import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as userSelectors } from 'builder/modules/user'
import { selectors as coachSelectors } from 'builder/modules/careerCoaching'
import ContentFailedToDownload from 'builder/components/CareerPostPurchaseBanner/components/ContentFailedToDownload'
import { EmptyState } from '../EmptyState'
import { SessionCard } from './components/SessionCard'
import { Container } from './styles'

const Upcoming = (): JSX.Element => {
  const user = useTypedSelector(userSelectors.userData)
  const { status, response } = useTypedSelector(coachSelectors.coachingSessions)
  const {
    upcoming: upcomingSessions,
    coach_details: coachDetails,
    status_message: statusMessage,
    package_details: packageDetails,
    participant_status: participantStatus,
  } = response

  if (!status) {
    return (
      <Container isSessions={!upcomingSessions.length}>
        <ContentFailedToDownload />
      </Container>
    )
  }

  if (!Object.keys(coachDetails).length) {
    return (
      <Container isSessions={!upcomingSessions.length}>
        <EmptyState
          heading="You don’t have any upcoming sessions"
          subHeading="You’ll have the ability to schedule sessions after matching with a coach"
        />
      </Container>
    )
  }

  const { email } = coachDetails
  const { remaining_sessions: remainingSessions, total_sessions: totalSessions } = packageDetails

  const renderSessions = () => {
    if (remainingSessions === +totalSessions) {
      if (upcomingSessions.length) {
        return upcomingSessions.map((session, index) => (
          <SessionCard key={index} session={session} />
        ))
      }

      const subHeading =
        participantStatus === 'Link Needed' ? (
          // eslint-disable-next-line max-len
          <>
            A link to schedule your first session will be sent to your email, <b>{user?.email}</b>,
            within 48 hours.
          </>
        ) : (
          <>
            Schedule a session through the calendly link in your email, <b>{user?.email}</b>.
          </>
        )

      return <EmptyState heading={statusMessage} subHeading={subHeading} />
    }

    if (remainingSessions !== 0 && remainingSessions < +totalSessions) {
      if (upcomingSessions.length) {
        return upcomingSessions.map((session, index) => (
          <SessionCard key={index} session={session} />
        ))
      }

      return (
        <EmptyState
          email={email}
          buttonText="Request a Session"
          heading="You don’t have any upcoming sessions"
          subHeading="Start the process to get a scheduling link from your coach via email"
        />
      )
    }

    if (remainingSessions === 0) {
      return (
        <EmptyState
          buttonText="Find a Coach"
          heading="You don’t have any upcoming sessions"
          subHeading="Get started with a professional coach to take the next step in your career"
        />
      )
    }
  }

  return <Container isSessions={!upcomingSessions}>{renderSessions()}</Container>
}

export default Upcoming
