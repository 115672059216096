import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import { Icon24 } from 'builder/components/Icon'
import Media from 'builder/styles/media'

export const Container = styled.div`
  margin-bottom: -10px;

  ${Media.Tablet`
    padding-inline: 8px;
  `}
`

export const Content = styled.div`
  margin-top: 26px;
  display: flex;
  gap: 24px;

  ${Media.Phone`
    flex-direction:column;
    gap:16px;
    margin-top:20px;
  `}
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Heading = styled.p`
  ${Typography.S};
  ${FontWeights.Medium};
  align-items: center;

  ${Media.Phone`
    font-size: 19px;
    line-height: 22px;
  `};
`

export const ExploreLink = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const Link = styled.div`
  color: ${Colors.Blue50};
  margin-right: -5px;
  transition: all ease-in 0.3s;

  &:hover {
    ${Colors.Blue60};
  }
`
export const ExploreIcon = styled(Icon24.Chevron)`
  color: ${Colors.Blue50};
`
