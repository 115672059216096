import { useEffect, useState } from 'react'
import ModalOverlay from '../ModalOverlay'
import { DefaultCircle, HoverCircle } from './styles'

interface Props {
  initialCounterValue: number
  onCompleted: () => void
  onCancel: () => void
  cancelTitle: string
}

export const CountDownOverlay = ({
  initialCounterValue,
  onCompleted,
  onCancel,
  cancelTitle,
}: Props) => {
  const [counter, setCounter] = useState<number>(initialCounterValue || 3)
  const [isHeld, setIsHeld] = useState(false)

  const handleStartHold = () => {
    setIsHeld(true)
  }

  const handleReleaseHold = () => {
    setIsHeld(false)
  }

  const handleOnClick = () => {
    if (isHeld && onCancel) {
      onCancel()
    }
  }

  useEffect(() => {
    const setIntervalId = setInterval(
      () => setCounter((prevCounter: number) => (prevCounter <= 0 ? prevCounter : prevCounter - 1)),
      1000,
    )
    return () => clearInterval(setIntervalId)
  }, [])

  useEffect(() => {
    if (counter <= 0) {
      if (onCompleted) {
        onCompleted()
      }
    }
  }, [counter, onCompleted])

  const Circle = isHeld ? HoverCircle : DefaultCircle
  if (counter === 0) {
    return null
  }
  return (
    <ModalOverlay>
      <Circle onMouseOver={handleStartHold} onMouseOut={handleReleaseHold} onClick={handleOnClick}>
        {isHeld ? cancelTitle : counter}
      </Circle>
    </ModalOverlay>
  )
}
