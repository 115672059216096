import { useRef, memo } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useAppSelector } from 'builder/hooks/useAppSelector'
import { Button } from 'builder/components/Button'
import { i18n as I18n } from 'builder/utils/i18n'
import { actions } from 'builder/modules/resumeEditor'
import {
  PrefillResumeEvents,
  ResumeUploadStatus,
  ResumeValidationStatus,
} from 'builder/modules/constants'
import FileSelector from '../FileSelector/FileSelector'
import { ButtonContainer, Div } from '../styles'

const ActionLayout = () => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const dispatch = useDispatch()
  const {
    resumeEditor: {
      prefillResumeSection: {
        isSavingPrefilledResume,
        resumeValidationStatus,
        selectedResume,
        resumeUploadStatus,
      },
      resume,
    },
  } = useAppSelector(state => state)

  const saveAndFetchUpdatedResume = () => {
    trackInternalEvent(PrefillResumeEvents.clickDone)
    if (selectedResume && resume) {
      dispatch(actions.parseAndSavePrefilledResume({ selectedResume, resumeId: resume.id }))
    }
  }
  const handleFileChange = () => {
    trackInternalEvent(PrefillResumeEvents.clickChangeFile)
    dispatch(actions.setResumeUploadStatus(ResumeUploadStatus.notStarted))
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const showChangeFileButton = [
    ResumeValidationStatus.validated,
    ResumeValidationStatus.failed,
  ].includes(resumeValidationStatus)
  const showDoneButton =
    resumeValidationStatus === ResumeValidationStatus.validated &&
    resumeUploadStatus !== ResumeUploadStatus.failed

  return (
    <ButtonContainer {...{ showDoneButton }}>
      {showChangeFileButton && (
        <Div onClick={handleFileChange}>
          <Button theme="ghost">{I18n.t('builder.upload_resume.change_file')}</Button>
          <FileSelector ref={fileInputRef} />
        </Div>
      )}
      {showDoneButton && (
        <Button isLoading={isSavingPrefilledResume} onClick={saveAndFetchUpdatedResume}>
          {I18n.t('builder.upload_resume.done')}
        </Button>
      )}
    </ButtonContainer>
  )
}

export default memo(ActionLayout)
