import DatePicker from 'react-datepicker'

interface DayRangePickerProps {
  calendarStartDay: number
  customInput: React.ReactNode
  startDate: Date | null
  endDate: Date | null
  onChangeHandler: (value: [Date | null, Date | null] | null) => void
}

const DayRangePicker = ({
  calendarStartDay,
  customInput,
  startDate,
  endDate,
  onChangeHandler,
}: DayRangePickerProps) => {
  const currentDate = new Date()

  return (
    <DatePicker
      id="dateStartEnd"
      customInput={customInput}
      selectsRange
      startDate={startDate}
      endDate={endDate}
      onChange={onChangeHandler}
      dateFormat="dd MMM yyyy"
      className="form-control form-control-sm"
      showDisabledMonthNavigation
      showMonthDropdown
      showYearDropdown
      calendarStartDay={calendarStartDay}
      minDate={currentDate}
      yearDropdownItemNumber={10}
    />
  )
}

export default DayRangePicker
