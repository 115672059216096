import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;

  ${Media.Phone`
    width: 100%;
  `};
`
export const Title = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};
`
export const CoachCardContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 32px;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  border: 1px solid ${Colors.Neutral15};

  ${Media.Phone`
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 24px;
  `};
`
