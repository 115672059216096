import Colors from 'builder/styles/colors'
import { Circle, PercentageText, ProgressText, ScoreText } from './styles'

interface Props {
  score: number
}

const MobileProgressBar = ({ score }: Props) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="22.5" stroke={Colors.Blue20} strokeWidth="3" fill="none" />
      <ProgressText x="25" y="25" textAnchor="middle" dominantBaseline="central">
        <ScoreText>{score}</ScoreText>
        <PercentageText dy="1" dx="1">
          %
        </PercentageText>
      </ProgressText>
      <Circle
        d="M24 1.5C27.9496 1.5 31.8296 2.53964 35.25 4.51443C38.6704 6.48922 41.5108 9.32957 43.4856 12.75C45.4604 16.1704 46.5 20.0504 46.5 24C46.5 27.9496 45.4604 31.8296 43.4856 35.25"
        stroke={Colors.Blue50}
        strokeWidth="3"
        fill="none"
        score={score}
        cx="24"
        cy="24"
        r="22.5"
        transform="rotate(-90 24 24)"
        circleCir={Math.PI * 2 * 22.5}
      />
    </svg>
  )
}

export default MobileProgressBar
