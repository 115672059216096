import { Link, useLocation } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'

import { useI18n } from 'builder/hooks/useI18n'

import { ISubMenu } from '../../types'
import { UserProfile } from '../UserProfile/UserProfile'
import { SubMenu } from './SubMenu/SubMenu'
import { Container, MenuItemWrapper } from './styles'

const TRANSLATION = 'builder.side_menu.profile'

interface IProps {
  open?: boolean
  width: string
  isSubMenuActive?: (subMenu: Array<ISubMenu>) => boolean
  styles?: string
}

export const Profile = ({ width, isSubMenuActive, open = true, styles = '' }: IProps) => {
  const { i18n } = useI18n()
  const location = useLocation()

  const to = '/career-profile/profile'
  const subMenu: ISubMenu[] = [
    {
      to: '/career-profile/insights',
      text: i18n.t(`${TRANSLATION}.career_insights`),
      eventParam: 'insights',
    },
    {
      to: '/career-profile/pitch',
      text: i18n.t(`${TRANSLATION}.professional_pitch`),
      eventParam: 'pitch',
    },
    {
      to: '/career-profile/cover-letter',
      text: i18n.t(`${TRANSLATION}.ai_cover_letter`),
      eventParam: 'cover_letter_sample',
    },
  ]

  const isActive = location.pathname === to || (isSubMenuActive && isSubMenuActive(subMenu))

  return (
    <MenuItemWrapper width={width} styles={styles} subMenu={<SubMenu items={subMenu} />}>
      <Link to={to} onClick={() => trackInternalEvent('click_career_profile')}>
        <Container active={isActive}>
          <UserProfile id="profile-menu-onboarding" active={isActive} showOnlyAvatar={!open} />
        </Container>
      </Link>
    </MenuItemWrapper>
  )
}
