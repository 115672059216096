import styled, { css } from 'styled-components'

import {
  Cards,
  PromotionTopic,
} from 'builder/components/SubscriptionCancellationFormNew/common/types'
import Media from 'builder/styles/media'

interface Props {
  topic: PromotionTopic
}

export const PromotionContent = styled.div<Props>(({ topic }) => {
  const desktopViewWidth = [Cards.FeatureOrProblem, Cards.OtherReasons].includes(topic as Cards)
    ? '448px'
    : '544px'

  return css`
    width: ${desktopViewWidth};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    padding-left: 40px;

    ${Media.Tablet`
        width: 540px;
        height: 436px;
        padding-left: unset !important;
        align-items: center;
    `}

    ${Media.Phone`
        width: 335px;
        height: 396px;
        padding-left: unset !important;
        align-items: center;
    `}
  `
})
