import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import { Button } from 'builder/components/Button'

export const Container = styled.div``

export const CoachDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  ${Media.Phone`
    align-items: flex-start;
    gap: 16px
  `};
`

export const CoachImageWrapper = styled.div`
  position: relative;
`

export const CoachAvatar = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
`

export const CoachStar = styled.div`
  width: 21.589px;
  height: 21.6px;
  position: absolute;
  bottom: 8px;
  right: 8px;
`

export const Star = styled.img``

export const CoachCaption = styled.div`
  ${Media.Phone`
    gap: 4px;
`};
`

export const Heading = styled.div`
  display: flex;

  ${Media.Tablet`
    gap: 4px;
    flex-direction: column;
  `};
`

export const SessionLeft = styled.div`
  ${Typography.S};
  ${FontWeights.Medium};

  ${Media.Tablet`
    ${Typography.Subhead};
  `};

  ${Media.Phone`
    ${Typography.Subhead};
  `};
`

export const CoachName = styled.span`
  ${Typography.S};
  margin-left: 4px;

  ${Media.Tablet`
    ${Typography.Subhead};
    margin-left: 0;
  `};

  ${Media.Phone`
    ${Typography.Body};
    margin-left: 0;
  `};
`

export const Caption = styled.div`
  color: ${Colors.Neutral50};

  ${Media.Phone`
    margin-top: 4px;
  `};
`

export const WarningCaption = styled.div`
  font-style: italic;
  color: ${Colors.Red50};
`

export const ActionDetails = styled.div`
  display: flex;
  gap: 12px;

  ${Media.Tablet`
    gap: 8px;
  `}

  ${Media.Phone`
    gap: 8px;
    width: 100%;
    flex-direction: column;
  `};
`

export const StyledButton = styled(Button)`
  ${Media.Phone`
    width: 100%;
  `};
`
