import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

import IBM from './assets/ibm.png'
import AMAZON from './assets/amazon.png'
import APPLE from './assets/apple.png'
import GOOGLE from './assets/google.png'
import META from './assets/meta.png'
import MICROSOFT from './assets/microsoft.png'
import { ColorsProp } from './ExploreJobs'

export const Container = styled.div`
  z-index: 1;
  display: flex;
  gap: 18px;
  position: absolute;
  left: 52%;

  ${Media.Tablet`
    height: 100%;
    left: 59%;
    top: 0;
    align-items: center;
  `}

  ${Media.Phone`
    height: 50%;
    width: 100%;
    left: 0;
    top: 0;
    align-items: center;
    flex-direction: column-reverse;
  `}
`

export const ScoreWrapper = styled.div<{ showPercentage?: boolean; percentageColor: ColorsProp }>`
  width: 72px;
  border-radius: 50%;
  position: relative;
  background: ${({ showPercentage, percentageColor }) =>
    showPercentage && `linear-gradient(to top, ${percentageColor.start}, ${percentageColor.end})`};
  padding: 2px;

  ${Media.Phone`
    top: -13px;
  `}
`

export const Score = styled.div<{ percentageColor: ColorsProp }>`
  width: 30px;
  text-align: center;
  border-radius: 30px;
  padding: 0 4px;
  position: absolute;
  left: 21px;
  bottom: -4px;
  background: ${({ percentageColor }) => percentageColor.start};
  color: ${Colors.White};
  ${Typography.Tiny};
  ${FontWeights.DemiBold};
`

export const AvatarIcon = styled.img`
  width: 68px;
  height: 68px;
  flex: 0 0 68px;
  border-radius: 50%;
`

export const Dot = styled.div`
  width: 4px;
  height: 4px;
  background-color: ${Colors.Neutral70};
  border-radius: 50%;
`

export const TopDots = styled.div`
  display: flex;
  gap: 2.5px;
  transform: rotate(-15deg);
`
export const CenterDots = styled.div`
  display: flex;
  gap: 2.5px;
`
export const BottomDots = styled.div`
  display: flex;
  gap: 2.5px;
  transform: rotate(15deg);
`

export const Dots = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 9px;
`

export const Images = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  height: 100%;
  width: 100%;
  z-index: 1;

  ${Media.Phone`
    justify-content: center;
  `}
`

export const Image = styled.div<{ size: number }>`
  padding: 10px;
  position: relative;
  border-radius: 50%;
  background-origin: content-box;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-color: ${Colors.White};
  box-shadow: 0 0 3px 0 lightgray;
  width: ${({ size }) => `${size || 40}px`};
  height: ${({ size }) => `${size || 40}px`};
`

export const GoogleImage = styled(Image)`
  background-image: ${`url('${GOOGLE}')`};
  top: -10px;
  left: 5px;

  ${Media.Tablet`
    left: -10px;
    top: -35px;
  `}

  ${Media.Phone`
    left: 60px;
    top: 13px;
  `}
`

export const IbmImage = styled(Image)`
  background-image: ${`url('${IBM}')`};
  top: 35px;
  left: -35px;
  padding: 0;

  ${Media.Tablet`
    left: -62px;
  `}

  ${Media.Phone`
    left: -10px;
    top: 110px;
  `}
`

export const MetaImage = styled(Image)`
  background-image: ${`url('${META}')`};
  top: 10px;
  left: -40px;
  padding: 0;

  ${Media.Tablet`
    left: -70px;
    top: 1px;
  `}

  ${Media.Phone`
    left: 30px;
    top: 7px;
  `}
`

export const MicrosoftImage = styled(Image)`
  background-image: ${`url('${MICROSOFT}')`};
  top: -13px;
  left: -40px;
  padding: 4px;

  ${Media.Tablet`
    left: -68px;
    top: -30px;
  `};

  ${Media.Phone`
    left: -135px;
    top: 60px;
  `}
`

export const AppleImage = styled(Image)`
  background-image: ${`url('${APPLE}')`};
  top: 30px;
  left: -70px;

  ${Media.Tablet`
    left: -120px;
    top: 25px;
  `}

  ${Media.Phone`
    left: -30px;
    top: 70px;
  `}
`

export const AmazonImage = styled(Image)`
  background-image: ${`url('${AMAZON}')`};
  top: 0;
  left: -70px;

  ${Media.Tablet`
    display: none;
  `}

  ${Media.Phone`
    display: none;
  `}
`

export const EllipsisContainer = styled.div`
  position: absolute;
  padding-top: 40px;
  opacity: 0.4;

  ${Media.Tablet`
    padding-top: 0;
  `};

  ${Media.Phone`
    position: absolute;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 0;
    top: 0;
    height: 165px;
    overflow: hidden;
  `};
`

export const EllipseFour = styled.div`
  position: absolute;
  width: 606px;
  height: 606px;
  transform: translateY(-50%);
  border-radius: 606px;
  opacity: 0.64;
  background: radial-gradient(
    circle at 0% 50%,
    rgba(195, 229, 254, 0) 68.75%,
    ${Colors.Indigo30} 100%
  );

  &.hover {
    background: radial-gradient(
      circle at 0% 50%,
      rgba(195, 229, 254, 0) 68.75%,
      ${Colors.Indigo40} 100%
    );
  }

  ${Media.Tablet`
    display: none;
  `};
`

export const EllipseOne = styled.div`
  position: absolute;
  width: 415px;
  height: 415px;
  transform: translateY(-50%);
  border-radius: 415px;
  opacity: 0.64;
  left: 60px;
  background: radial-gradient(
    circle at 0% 50%,
    rgba(195, 229, 254, 0) 68.75%,
    ${Colors.Indigo30} 100%
  );

  &.hover {
    background: radial-gradient(
      circle at 0% 50%,
      rgba(195, 229, 254, 0) 68.75%,
      ${Colors.Indigo40} 100%
    );
  }

  ${Media.Tablet`
    width: 253px;
    height: 253px;
    border-radius: 253px;
  `};

  ${Media.Phone`
    border-radius: 290px;
    height: 260px;
    width: 315px;
    top: -10px;
    left: unset;
    transform: none;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(195, 229, 254, 0) 68.75%,
      ${Colors.Indigo30} 100%
    );

    &.hover {
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(195, 229, 254, 0) 68.75%,
        ${Colors.Indigo40} 100%
      );
    }
  `};
`

export const EllipseTwo = styled.div`
  position: absolute;
  width: 276px;
  height: 276px;
  left: 100px;
  transform: translateY(-50%);
  border-radius: 276px;
  opacity: 0.6;
  background: radial-gradient(
    circle at 0% 50%,
    rgba(195, 229, 254, 0) 68.75%,
    ${Colors.Indigo30} 100%
  );

  &.hover {
    background: radial-gradient(
      circle at 0% 50%,
      rgba(195, 229, 254, 0) 68.75%,
      ${Colors.Indigo40} 100%
    );
  }

  ${Media.Tablet`
    left: 45px;
    width: 170px;
    height: 170px;
    border-radius: 170px;
  `};

  ${Media.Phone`
    border-radius: 130px;
    left: unset;
    width: 130px;
    height: 130px;
    transform: none;
    top: 50px;

    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(195, 229, 254, 0) 68.75%,
      ${Colors.Indigo30} 100%
    );

    &.hover {
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(195, 229, 254, 0) 68.75%,
        ${Colors.Indigo40} 100%
      );
    }
  `};
`

export const EllipseThree = styled.div`
  position: absolute;
  width: 157px;
  height: 157px;
  left: 140px;
  transform: translateY(-50%);
  border-radius: 157px;
  opacity: 0.4;
  background: radial-gradient(
    circle at 0% 50%,
    rgba(195, 229, 254, 0) 68.75%,
    ${Colors.Indigo20} 100%
  );

  &.hover {
    background: radial-gradient(
      circle at 0% 50%,
      rgba(195, 229, 254, 0) 68.75%,
      ${Colors.Indigo30} 100%
    );
  }

  ${Media.Tablet`
    display: none;
  `};
`
