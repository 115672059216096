import Lottie, { Options } from 'react-lottie'

interface Props {
  options: Options
  width?: number | string
  height?: number | string
}

export const LottieAnimation = (props: Props) => {
  const { width = '100px', height = '100px', options } = props

  return <Lottie {...{ width, height, options }} />
}
