import { Action } from '@reduxjs/toolkit'
import { Saga } from 'redux-saga'
import { call, put, select, StrictEffect, CallEffect } from 'redux-saga/effects'
import { selectors as initSelectors } from 'builder/modules/init'
import { actions as uiActions } from 'builder/modules/ui'

export type InterceptionHelper = () => Generator<StrictEffect, boolean, unknown>

export type InterceptedAction<A extends Action = Action> = (
  sagaToCall: Saga<[A]>,
  action: A,
) => Generator<CallEffect, void, unknown>

/** Checks if a visitor is online, and shows a snackbar if is not */
export function* needOnline(): Generator<object> {
  if (yield select(initSelectors.isOnline)) return true
  yield put(uiActions.setConnectionLostSnackbarOpen(true))
  return false
}

/** Creates a generator that makes the passed helpers work with redux-sagas */
export function createInterceptor<A extends Action>(
  helpers: InterceptionHelper[] | InterceptionHelper,
) {
  return function* (sagaToCall: Saga<[A]>, action: A): Generator<object> {
    if (!sagaToCall) return

    // Process a single function properly
    const checks = Array.isArray(helpers) ? helpers : [helpers]

    // Do not call the saga if an unsuccessful check was met
    for (const i in checks) {
      let success = yield call(checks[i])
      if (!success) return
    }

    // Run the saga if all of the checks have passed
    yield call(sagaToCall, action)
  }
}
