import { CSSProperties } from 'react'
import { Transition } from 'react-transition-group'
import { CareerPathJobCardTone } from 'builder/components/CareerPathJobCard'
import * as Styles from './styles'

type Props = {
  tone?: CareerPathJobCardTone
  onConfirm: () => void
  isVisible: boolean
}

export const StickedButton = ({ isVisible = false, tone, onConfirm }: Props) => {
  const style = {
    '--color-accent': `var(--${tone}-50)`,
    '--color-accent-hover': `var(--${tone}-60)`,
  } as CSSProperties

  return (
    <Transition in={isVisible} timeout={0} appear>
      {state => (
        <Styles.StickedButtonContainer transitionState={state} style={style}>
          <Styles.ButtonConfirm onClick={onConfirm}>Confirm My Path</Styles.ButtonConfirm>
        </Styles.StickedButtonContainer>
      )}
    </Transition>
  )
}
