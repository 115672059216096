import PropTypes from 'prop-types'

import { SectionTitle, Container, SectionContent } from './styles'

const EditorSection = props => (
  <Container ref={props.containerRef}>
    <SectionTitle>{props.title}</SectionTitle>
    <SectionContent>{props.children}</SectionContent>
  </Container>
)

EditorSection.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  title: PropTypes.string,
  containerRef: PropTypes.shape({ current: PropTypes.object }),
}

export default EditorSection
