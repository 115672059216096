import { memo } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { i18n as I18n } from 'builder/utils/i18n'
import { actions } from 'builder/modules/resumeEditor'
import { AIResumeDraftEventLabels, AIResumeDraftEvents } from 'builder/modules/constants'
import { SuggestedSkillTag, SkillTagsContainer, SuggestedSkillsTitle } from './styles'

type Props = {
  suggestedSkills: string[]
}

const SuggestedSkills = (props: Props) => {
  const dispatch = useDispatch()
  const { suggestedSkills } = props

  const addSuggestedSkill = (suggestedSkill: string) => {
    trackInternalEvent(AIResumeDraftEvents.addSuggestedSkill, {
      label: AIResumeDraftEventLabels.skills,
    })
    trackInternalEvent(AIResumeDraftEvents.addSkill, {
      label: AIResumeDraftEventLabels.skills,
    })
    dispatch(actions.updateCurrentSkills(suggestedSkill))
    // remove skill from suggestions after adding to currentSkills
    dispatch(actions.removeFromSuggestedSkills(suggestedSkill))
  }

  return (
    <SkillTagsContainer data-testid="suggested-skills">
      <SuggestedSkillsTitle>
        {I18n.t('builder.ai_resume_draft.skills_screen.suggested_skills')}
      </SuggestedSkillsTitle>
      {suggestedSkills.map((item, index) => (
        <SuggestedSkillTag
          data-testid="suggested-skill"
          key={index}
          onClick={() => addSuggestedSkill(item)}
        >
          {item}
        </SuggestedSkillTag>
      ))}
    </SkillTagsContainer>
  )
}
export default memo(SuggestedSkills)
