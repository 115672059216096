import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Label = styled.label`
  ${Typography.ButtonS};
  ${FontWeights.DemiBold};

  outline: none;
  border-width: 0;
  cursor: pointer;
  appearance: none;
  user-select: none;
  padding: 8px 14px;
  border-radius: 4px;
  text-align: center;
  white-space: nowrap;
  align-items: center;
  margin-bottom: 92px;
  display: inline-flex;
  justify-content: center;
  border: 1px solid ${Colors.Neutral20};
  background: ${hexToRgba(Colors.Blue50, 0)};

  &:hover,
  &:focus-visible {
    color: ${Colors.Blue50};
    border-color: ${Colors.Blue50};
  }

  &:active {
    background-color: ${hexToRgba(Colors.Blue50, 0.08)};
  }

  ${Media.Phone`
    margin-bottom: 96px;
  `};

  @media (max-width: 374px) {
    margin-bottom: 78px;
  }
`

export const Input = styled.input.attrs({ type: 'file' })`
  display: none;
`
