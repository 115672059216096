import { useMediaQueries } from 'builder/hooks/useMediaQueries'

import { PlaceholderSvg } from './styles'

// border radius
const rectProps = {
  rx: 6,
  ry: 6,
}

export const Placeholder = () => {
  const { isPhone } = useMediaQueries()

  return (
    <PlaceholderSvg width="536" height="492" viewBox="0 0 536 492" preserveAspectRatio="xMinYMin">
      {/* title */}
      <rect x="0" y="0" width="200" height="40" {...rectProps} />
      {/* tabs */}
      <rect x="0" y="56" width={isPhone ? 536 : 272} height="32" {...rectProps} />
      {/* preview */}
      <rect x="0" y="132" width="192" height="270" {...rectProps} />
      {/* name */}
      <rect x="224" y="132" width="248" height="44" {...rectProps} />
      {/* actions */}
      <rect x="224" y="197" width="145" height="24" {...rectProps} />
      <rect x="224" y="233" width="145" height="24" {...rectProps} />
      <rect x="224" y="269" width="145" height="24" {...rectProps} />
    </PlaceholderSvg>
  )
}
