import { ProgressItem, Count, Title, Divider } from './styles'

interface StepProps {
  page: number
  title: string
}

interface ProgressStepsProps {
  step: StepProps
  isActive: boolean
}

export const ProgressSteps = ({ step, isActive }: ProgressStepsProps) => {
  const { page, title } = step
  return (
    <ProgressItem key={page}>
      <Count {...{ isActive }}>{page}</Count>
      <Title {...{ isActive }}>{title}</Title>
      {(page === 1 || page === 2) && <Divider />}
    </ProgressItem>
  )
}
