import { useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'
import { navigate } from 'builder/modules/navigate'
import ErrorLogger from 'builder/services/ErrorLogger'
import { getResumeCritiqueLocalStorageKeysResume } from 'builder/views/ResumeCritiqueView/constants'
import { EHeaderMediaType } from '../CommonAlertModal/constants'
import CommonAlertModal from '../CommonAlertModal/CommonAlertModal'
import ModalBody from './ModalBody'
import ModalFooter from './ModalFooter'
import alertSecondResumeReviewURL from './assets/img/alertSecondResumeReview.svg'

interface IProps {
  resumeId: number
  isSecondReview?: boolean
}

const SecondResumeReviewAlertModal = ({ resumeId, isSecondReview }: IProps) => {
  const location = useLocation()

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    try {
      if (isSecondReview) {
        let isReviewResumeFlag = localStorage.getItem(
          getResumeCritiqueLocalStorageKeysResume(resumeId),
        )
        isReviewResumeFlag = isReviewResumeFlag ? JSON.parse(isReviewResumeFlag) : false
        if (isReviewResumeFlag) {
          setIsOpen(false)
        } else {
          localStorage.setItem(getResumeCritiqueLocalStorageKeysResume(resumeId), 'true')
          setIsOpen(true)
        }

        // Remove params from URL as will not be used again
        setTimeout(() => {
          const newUrlWithoutSecondReview = `${location.pathname}${location?.search?.replaceAll(
            '&secondReview=true',
            '',
          )}`
          navigate(newUrlWithoutSecondReview)
        }, 2000)
      }
    } catch (error) {
      ErrorLogger.log(error)
    }
  }, [isSecondReview, location.pathname, location?.search, resumeId])

  const handleClose = () => {
    setIsOpen(false)
  }

  if (!isOpen) {
    return null
  }

  return (
    <CommonAlertModal
      headerMedia={alertSecondResumeReviewURL}
      headerMediaType={EHeaderMediaType.IMAGE}
      handleClose={handleClose}
    >
      <>
        <ModalBody />
        <ModalFooter handleClose={handleClose} />
      </>
    </CommonAlertModal>
  )
}

export default SecondResumeReviewAlertModal
