import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Container = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;

  ${Media.Tablet`
    padding-inline: 8px;
  `}

  ${Media.Phone`
    gap: 20px;
  `}
`

export const Heading = styled.p`
  padding: 8px 0 8px;
  ${Typography.S};
  ${FontWeights.Medium};

  ${Media.Phone`
    padding: 4px 0 4px
  `}
`
