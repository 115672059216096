import styled from 'styled-components'
import Media from 'builder/styles/media'

export const Container = styled.div`
  max-width: 1120px;
  width: calc(100% - 40px);
  margin: 0 auto;
  padding: 48px 0 96px;
  gap: 96px;
  display: flex;
  flex: auto;
  justify-content: space-between;

  ${Media.Tablet`
    flex-direction: column;
    justify-content: flex-start;
    gap: 48px;
    padding-bottom: 64px;
  `}

  ${Media.Phone`
    padding-bottom: 48px;
  `}
`

export const FixedContainer = styled.div`
  position: sticky;
  top: 100px;
  height: fit-content;
  width: 192px;
`
