import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Title = styled.div`
  ${Typography.XL};
  font-weight: 700;
  color: ${Colors.Blue50};
  margin-bottom: 12px;
  max-width: 555px;
  text-align: center;

  ${Media.Phone`
    text-align: left;
    width: 100%;
  `}
`

export const Subtitle = styled.div`
  margin-bottom: 32px;
  max-width: 544px;
  text-align: center;

  ${Media.Phone`
    text-align: left;
    margin-bottom: 24px;
    max-width: 100%;
  `}
`

export const FieldSuggestion = styled.div`
  ${Typography.Caption};
  cursor: pointer;
`

export const FieldSuggestionText = styled.span`
  color: ${Colors.Neutral50};
  margin-right: 4px;
`

export const FieldSuggestionLink = styled.span`
  display: inline-flex;
  align-items: center;
  color: ${Colors.Blue50};

  & b {
    font-weight: 600;
  }
`

export const WarningContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid ${Colors.Neutral15};
  border-radius: 4px;
  padding: 16px 24px;
`
export const WarningIcon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.Amber40};
  margin-right: 16px;
`

export const WarningText = styled.div`
  text-align: left;
  ${Typography.Caption};
  color: ${Colors.Neutral60};
`
export const LabelWrapper = styled.div`
  & a {
    color: #1a91f0;
  }
`
