import Spinner from 'builder/components/Spinner'
import { Wrapper } from './styles'

const Loader = () => {
  return (
    <Wrapper>
      <Spinner />
    </Wrapper>
  )
}

export default Loader
