import { SkeletonArea, SkeletonValue } from '../Skeletons'
import * as Styles from './styles'

export const Skeleton = () => {
  return (
    <Styles.Wrapper>
      <SkeletonValue />
      <Styles.Chart>
        <SkeletonArea />
      </Styles.Chart>
    </Styles.Wrapper>
  )
}
