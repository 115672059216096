import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Button from 'builder/components/Button'
import { Container as BaseContainer } from 'builder/components/Container'

type CareerPathQuestionnaireFields = {
  occupation: string
  location: string
}
interface ButtonProps {
  isAnyActivated: boolean | undefined
  isLoaded: boolean
  showButton: boolean
}
interface FormProps {
  errors: CareerPathQuestionnaireFields
}

export const Container = styled(BaseContainer)`
  margin-top: 32px;

  ${Media.Phone`
    margin-top: 24px;
  `}
`
export const FormContainer = styled.form<FormProps>`
  display: flex;
  padding: 0 97px;
  align-items: ${({ errors }) =>
    errors.occupation.length || errors.location.length ? 'flex-start' : 'center'};
  justify-content: space-between;

  ${Media.Phone`
    gap: 12px;
    padding: 0;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  `}

  ${Media.Tablet`
    padding: 0;
  `}
`
export const FieldContainer = styled.div`
  width: 352px;
  margin-right: 24px;

  ${Media.Phone`
    width: 100%;
    margin-right: 0;
  `}

  ${Media.Tablet`
    flex: 1;
  `}
`
export const ArrowContainer = styled.button`
  flex-shrink: 0;
  width: 80px;
  height: 32px;
  background: ${Colors.Neutral10};
  border-radius: 20px;
  transform: rotate(0.5turn);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  cursor: pointer;
  border: none;
  & svg {
    min-width: 24px;
    margin: 0 auto;
    color: ${Colors.Neutral50};
  }
  &:hover {
    background: ${Colors.Neutral20};
  }
`
export const ButtonContainer = styled.div<ButtonProps>`
  ${Media.Tablet`
    z-index: 1;
    position: fixed;
    bottom: 104px;
    right: 20px;
  `};

  ${props =>
    !props.isLoaded &&
    Media.Tablet`
      display: none;
    `}

  ${props =>
    props.showButton &&
    Media.Tablet`
      display: block;
  `}

  ${props =>
    props.isAnyActivated &&
    Media.Tablet`
      display: none;
  `}

  ${Media.Phone`
    text-align: center;
    width: calc(100% - 40px);
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    bottom: 76px;
    right: 20px;
  `};

  ${props =>
    !props.isLoaded &&
    Media.Phone`
      display: none;
  `}

  ${props =>
    props.showButton &&
    Media.Phone`
      display: block;
  `}

  ${props =>
    props.isAnyActivated &&
    Media.Phone`
      display: none;
  `}
`

export const FormButton = styled(Button)`
  ${Media.Phone`
    width: 100%;
  `}
`
