import { Fragment, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import EditorField from 'builder/components/EditorField'
import { useI18n } from 'builder/hooks/useI18n'
import { actions } from 'builder/modules/resumeEditor'
import { Store } from 'builder/modules/store'
import { formatDate } from '../../../../../../rendering/templates/shared/utils/formatJapaneseDate'
import CustomDatePicker from '../CustomComponent/CustomDatePicker/CustomDatePicker'

export const SubmissionDate = (): JSX.Element => {
  const { i18n } = useI18n()
  const submissionDate = useSelector(
    (state: Store) => state.resumeEditor.resume?.resumeSubmissionDate,
  )
  const nameRef = useRef('resumeSubmissionDate')
  const { updateSimpleField } = actions
  const dispatch = useDispatch()

  const updateDateRange = useCallback(
    value => {
      const submissionDate = formatDate(value)
      dispatch(
        updateSimpleField({
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          name: nameRef.current as any,
          value: submissionDate,
          debounce: true,
        }),
      )
    },
    [dispatch, updateSimpleField],
  )

  return (
    <Fragment>
      {/* Submission Date */}
      <EditorField>
        <CustomDatePicker
          value={submissionDate || ''}
          onChange={updateDateRange}
          label={i18n.t('builder.resume_editor.submission_date')}
        />
      </EditorField>
    </Fragment>
  )
}
