import { useEffect, useState } from 'react'
import insertSuggestion from '../modifiers/insertSuggestion'
import { SpellCheckSuggestion } from '../../types'
import { Props } from './SpellCheckDropdown'

export const useSpellCheckDropdownActions = (props: Props) => {
  const { spellCheckEmitter, editorState, setEditorState, handleChange } = props

  const [isOpen, setIsOpen] = useState(false)
  const [top, setTop] = useState(0)
  const [left, setLeft] = useState(0)
  const [blockKey, setBlockKey] = useState('')
  const [word, setWord] = useState('')
  const [wordId, setWordId] = useState('')
  const [wordStartIndex, setWordStartIndex] = useState(0)
  const [wordEndIndex, setWordEndIndex] = useState(0)

  const handleShowDropdown = ({
    wordId,
    word,
    position,
    wordStartIndex,
    wordEndIndex,
    blockKey,
  }: SpellCheckSuggestion) => {
    setIsOpen(true)
    setTop(position.top)
    setLeft(position.left)
    setBlockKey(blockKey)
    setWord(word)
    setWordId(wordId)
    setWordStartIndex(wordStartIndex)
    setWordEndIndex(wordEndIndex)
  }

  const handleHideDropdown = () => {
    setIsOpen(false)
    setWordId('')
  }

  const handleMouseOver = () => {
    const position = { top, left }
    spellCheckEmitter.showSuggestions({
      wordId,
      position,
      word,
      wordStartIndex,
      wordEndIndex,
      blockKey,
    })
  }

  const handleMouseLeave = () => {
    spellCheckEmitter.hideSuggestions({ wordId })
  }

  const updateWordInEditor = (word: string) => {
    const newEditorState = insertSuggestion(
      editorState,
      word,
      wordStartIndex,
      wordEndIndex,
      blockKey,
    )

    spellCheckEmitter.hideSuggestionsInstantly({ wordId })
    setEditorState(newEditorState)
    handleChange(newEditorState)
  }

  const handleSuggestionItemClick = (word: string) => {
    updateWordInEditor(word)
  }

  const handleIgnoreClick = () => {
    const ignoredWordsJSON = localStorage.getItem('SPELL_CHECKER_IGNORED_WORDS')
    const ignoredWords = ignoredWordsJSON ? JSON.parse(ignoredWordsJSON) : []
    ignoredWords.push(word)
    localStorage.setItem('SPELL_CHECKER_IGNORED_WORDS', JSON.stringify(ignoredWords))
    updateWordInEditor(word)
  }

  useEffect(() => {
    spellCheckEmitter.on('showDropdown', handleShowDropdown)
    spellCheckEmitter.on('hideDropdown', handleHideDropdown)
    return () => {
      spellCheckEmitter.removeListener('showDropdown', handleShowDropdown)
      spellCheckEmitter.removeListener('hideDropdown', handleHideDropdown)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isOpen,
    top,
    left,
    word,
    handleMouseOver,
    handleMouseLeave,
    handleIgnoreClick,
    handleSuggestionItemClick,
  }
}
