import styled, { keyframes } from 'styled-components'
import Icon from 'builder/components/Icon'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

const collapseFromBottom = keyframes`
  from {
    max-height: 88px;
    opacity: 1;
    padding: 8px;
    margin-bottom: 8px;
  }
  to {
    max-height: 0;
    opacity: 0;
    padding: 0;
    margin-bottom: 0;
  }
`

export const Container = styled.div<{ isHidden?: boolean }>`
  display: flex;
  gap: 6px;
  background-color: ${Colors.Blue10};
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  padding: 8px;
  overflow: hidden;
  transform-origin: bottom;
  animation: ${({ isHidden }) => (isHidden ? collapseFromBottom : 'none')} 0.3s forwards;

  ${Media.Phone`
    align-items: flex-start;
  `}
`

export const InfoText = styled.div`
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${Colors.Blue80};
`

export const HintIcon = styled(Icon.HintOutlined)`
  margin: 3px 0;
  min-width: 16px;
  width: 16px;
  height: 16px;
  color: ${Colors.Blue50};
`

export const CloseIcon = styled(Icon.Close)`
  min-width: 24px;
  color: ${Colors.Blue50};
  cursor: pointer;
`
