import FlipMove from 'react-flip-move'

// Define custom react-flip-move animations
// github.com/joshwcomeau/react-flip-move/blob/master/documentation/enter_leave_animations.md

const hidden: Partial<CSSStyleDeclaration> = {
  transform: 'scale(0.75)',
  opacity: '0',
}

const visible: Partial<CSSStyleDeclaration> = {
  transform: '',
}

export const APPEAR_ANIMATION: FlipMove.Animation = {
  from: hidden,
  to: visible,
}
export const LEAVE_ANIMATION: FlipMove.Animation = {
  from: visible,
  to: {
    ...hidden,
    // sometimes react-flip-moved doesn't remove hidden nodes,
    // to fix that we just make them not clickable:
    pointerEvents: 'none',
  },
}
