import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Button from 'builder/components/Button'
import { FontWeights } from 'builder/styles/typography'
import { Icon24 } from 'builder/components/Icon'

export const Container = styled.div`
  z-index: 1;
  display: flex;
  gap: 18px;
  position: absolute;
  left: 52%;
  height: 100%;

  ${Media.Tablet`
    height: 100%;
    left: 48%;
    top: 0;
    align-items: center;
  `}

  ${Media.Phone`
    height: 50%;
    width: 100%;
    left: 0;
    top: 0;
    align-items: center;
    flex-direction: column-reverse;
  `};
`

export const EllipsisContainer = styled.div`
  position: absolute;
  padding-top: 40px;
  left: 20px;
  opacity: 0.4;

  ${Media.Tablet`
    left: 85px;
    padding-bottom: 50px;
  `};

  ${Media.Phone`
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 0;
    top: 0;
    height: 165px;
    overflow: hidden;
  `};
`

export const EllipseOne = styled.div`
  position: absolute;
  width: 606px;
  height: 606px;
  transform: translateY(-50%);
  border-radius: 606px;
  opacity: 0.64;
  background: radial-gradient(
    circle at 100%,
    rgba(195, 229, 254, 0) 68.75%,
    ${Colors.Indigo30} 100%
  );

  &.hover {
    background: radial-gradient(
      circle at 100%,
      rgba(195, 229, 254, 0) 68.75%,
      ${Colors.Indigo40} 100%
    );
  }

  ${Media.Tablet`
    width: 243px;
    height: 243px;
    border-radius: 243px;

    background: radial-gradient(
      1% 1%,
      rgba(195, 229, 254, 0) 68.75%,
      ${Colors.Indigo20} 100%
    );

    &.hover {
      background: radial-gradient(
        1% 1%,
        rgba(195, 229, 254, 0) 68.75%,
        ${Colors.Indigo30} 100%
      );
    }
  `};

  ${Media.Phone`
    width: 335px;
    height: 335px;
    border-radius: 335px;
    top: -55px;
    transform: none;
  `};
`

export const EllipseTwo = styled.div`
  position: absolute;
  width: 276px;
  height: 276px;
  left: 91px;
  transform: translateY(-50%);
  border-radius: 276px;
  opacity: 0.6;
  background: radial-gradient(
    circle at 100%,
    rgba(195, 229, 254, 0) 68.75%,
    ${Colors.Indigo30} 100%
  );

  &.hover {
    background: radial-gradient(
      circle at 100%,
      rgba(195, 229, 254, 0) 68.75%,
      ${Colors.Indigo40} 100%
    );
  }

  ${Media.Tablet`
    left: 45px;
    width: 160px;
    height: 160px;
    border-radius: 160px;

    background: radial-gradient(
      1% 1%,
      rgba(195, 229, 254, 0) 68.75%,
      ${Colors.Indigo20} 100%
    );

    &.hover {
      background: radial-gradient(
        1% 1%,
        rgba(195, 229, 254, 0) 68.75%,
        ${Colors.Indigo30} 100%
      );
    }
  `};

  ${Media.Phone`
    border-radius: 170px;
    left: unset;
    width: 170px;
    height: 170px;
    transform: none;
    top: 30px;
  `};
`

export const EllipseThree = styled.div`
  position: absolute;
  width: 157px;
  height: 157px;
  left: 171px;
  transform: translateY(-50%);
  border-radius: 157px;
  opacity: 0.4;
  background: radial-gradient(
    circle at 100%,
    rgba(195, 229, 254, 0) 68.75%,
    ${Colors.Indigo30} 100%
  );

  &.hover {
    background: radial-gradient(
      circle at 100%,
      rgba(195, 229, 254, 0) 68.75%,
      ${Colors.Indigo40} 100%
    );
  }

  ${Media.Tablet`
    display: none;
  `};
`

export const ImageContainer = styled.div`
  position: relative;
  left: 170px;
  top: 7px;
  height: 100%;

  ${Media.Tablet`
    top: 12px;
    left: 113px;
  `}

  ${Media.Phone`
    left: 10px;
    top: 3px;
  `}
`

export const JobPostImage = styled.img`
  position: absolute;
  left: 75px;
  z-index: 3;

  ${Media.Tablet`
    left: 0;
  `};

  ${Media.Phone`
    width: 250px;
    height: 76px;
    top: 48px;
    left: calc(50%);
    transform: translate(-50%);
  `};
`

export const JobPostImageGradient = styled.div`
  position: absolute;
  top: -23px;
  left: 237px;
  width: 51px;
  height: 108px;
  background: linear-gradient(270deg, ${Colors.Indigo10} 0%, rgba(241, 242, 255, 0) 100%);
  z-index: 3;

  &.hover {
    background: linear-gradient(270deg, ${Colors.Indigo20} 0%, rgba(241, 242, 255, 0) 100%);
  }

  ${Media.Tablet`
    top: -23px;
    left: 172px;
    height: 147px;
  `};

  ${Media.Phone`
    display: none;
  `};
`

export const RectangleGradientOne = styled.div`
  position: absolute;
  z-index: 2;
  left: 84px;
  top: -4px;
  width: 204px;
  height: 66px;
  border-radius: 5px;
  background-color: ${Colors.Indigo20};

  &.hover {
    background-color: ${Colors.Indigo30};
  }

  ${Media.Tablet`
    left: 9px;
    top: -4px;
    width: 200px;
  `};

  ${Media.Phone`
    width: 217px;
    height: 66px;
    top: 38px;
    left: calc(50%);
    transform: translate(-50%);
  `};
`

export const RectangleGradientTwo = styled.div`
  position: absolute;
  z-index: 2;
  left: 92px;
  top: -9px;
  width: 195px;
  height: 66px;
  opacity: 0.5;
  border-radius: 5px;
  background-color: ${Colors.Indigo20};

  &.hover {
    background-color: ${Colors.Indigo30};
  }

  ${Media.Tablet`
    left: 17px;
    top: -9px;
    width: 192px;
  `};

  ${Media.Phone`
    width: 200px;
    height: 66px;
    top: 28px;
    left: calc(50%);
    transform: translate(-50%);
  `};
`

export const AutoApplyButton = styled(Button)`
  cursor: default;
  position: absolute;
  left: -115px;
  width: 153px;
  height: 27px;
  font-size: 10px;
  ${FontWeights.Medium};
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0;

  ${Media.Tablet`
    left: 2px;
    top: 85px;
    width: 123px;
  `};

  ${Media.Phone`
    top: 130px;
    left: calc(50% - 60px);
    transform: translate(-50%);
    width: 115px;
  `};
`

export const AutoApplyButtonIcon = styled(Icon24.DoubleStars)`
  color: white;
  width: 12px;
  height: 12px;
`

export const ApplyButton = styled(Button)`
  cursor: default;
  position: absolute;
  top: 34px;
  left: -90px;
  width: 153px;
  height: 27px;
  font-size: 10px;
  ${FontWeights.Medium};
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${Colors.Neutral90};
  background-color: ${Colors.Blue10};
  padding: 0;

  &:hover,
  &:focus-visible {
    background-color: ${Colors.Blue20};
  }

  &:active {
    background-color: ${Colors.Blue20};
  }

  ${Media.Tablet`
    left: 130px;
    top: 85px;
    width: 123px;
  `};

  ${Media.Phone`
    top: 130px;
    left: calc(50% + 60px);
    transform: translate(-50%);
    width: 115px;
  `};
`

export const ApplyButtonIcon = styled(Icon24.ExternalLink)`
  color: ${Colors.Blue50};
  width: 12px;
  height: 12px;
`
