import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'

const networkColors = {
  facebook: '#1371f0',
  linkedin: '#2867b2',
  google: '#db4437',
}

const networkHoverColors = {
  facebook: '#1163d3',
  linkedin: '#225695',
  google: '#c53d32',
}

export const ButtonContainer = styled.a`
  position: relative;
  display: block;
  width: 100%;
  padding: 12px 10px 12px 30px;
  ${props => props.network === 'skip' && `padding-left: 10px;`};
  border-radius: 4px;
  border: 1px solid ${props => networkColors[props.network] || Colors.Neutral20};
  background-color: ${props => networkColors[props.network] || Colors.White};
  color: ${props =>
    props.network !== 'email' && props.network !== 'skip' ? Colors.White : Colors.Neutral90};
  ${Typography.ButtonM};
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s;

  ${Media.Phone`
    padding-left: 10px;
  `}

  &:hover,
  &:focus-visible {
    border-color: ${props => networkHoverColors[props.network] || Colors.Blue50};
    background-color: ${props => networkHoverColors[props.network] || Colors.White};
    color: ${props =>
      props.network !== 'email' && props.network !== 'skip' ? Colors.White : Colors.Blue50};
  }
`

export const ButtonIcon = styled.span`
  position: absolute;
  left: 16px;
  top: 50%;
  margin-top: -12px;

  ${Media.Phone`
    display: none;
  `}

  & svg {
    display: block;
  }

  & path {
    fill: ${props => (props.network !== 'email' ? Colors.White : Colors.Blue50)};
  }
`
