import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import DefaultButton from 'builder/components/Button'
import Media from 'builder/styles/media'

export const Title = styled.div`
  ${Typography.Subhead}
  ${FontWeights.DemiBold}

  ${Media.Phone`
    ${Typography.S};
  `}
`
export const ContentWrapper = styled.div<{ $direction?: 'row' | 'column' }>`
  display: flex;
  flex-direction: ${props => props.$direction || 'column'};
  margin-top: 16px;

  ${Media.Phone`
    margin-top: 12px;
  `}
`

export const Content = styled.div`
  ${Typography.Caption}
  ${FontWeights.Regular};
  color: ${Colors.Neutral60};
`

export const ButtonsWrapper = styled.div<{ $justify?: 'space-between' | 'right' | 'left' }>`
  display: flex;
  margin-top: 30px;
  gap: 8px;
  justify-content: ${props => props.$justify || 'space-between'};

  ${Media.Phone`
    margin-top: 14px;
    flex-direction: column-reverse;
  `}
`

export const Button = styled(DefaultButton)`
  ${Typography.Body}
  ${FontWeights.Medium};
`
