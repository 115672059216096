import * as Styled from './Styles'

interface Props {
  onChange: () => void
  isVisible: boolean
  label: string
  onSelected: () => void
  selected: boolean
}

export const CheckBoxLabel = ({ onChange, isVisible, label, onSelected, selected }: Props) => {
  return (
    <Styled.Container>
      <div onClick={onSelected}>{selected ? <Styled.DetailsDone /> : <Styled.StatusStarted />}</div>
      <Styled.Wrapper onClick={onChange}>
        <Styled.Label isVisible={selected}>{label}</Styled.Label>
        <Styled.ButtonArrow isVisible={isVisible} />
      </Styled.Wrapper>
    </Styled.Container>
  )
}
