import expertIcon from 'builder/components/CIO-Dasboard-2.0/assets/expert_content_group.svg'
import { useI18n } from 'builder/hooks/useI18n'
import * as Styled from './styles'

export const ExpertTag = () => {
  const { i18n } = useI18n()
  return (
    <Styled.ExpertTagContainer>
      <Styled.ExpertImage src={expertIcon} />
      <Styled.ExpertTag>
        {i18n.t('builder.dashboard.service_data.tags.expert_content')}
      </Styled.ExpertTag>
    </Styled.ExpertTagContainer>
  )
}
