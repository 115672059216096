import { JobProgressionsPath } from 'builder/modules/careerPath2'
import Card from '../Card'
import { Wrapper } from './styles'

type JobLevelProps = {
  titles: JobProgressionsPath[]
  level: number
}

const JobLevel = ({ titles, level }: JobLevelProps) => {
  return (
    <Wrapper>
      {titles.length ? (
        titles.map((title, index) => (
          <Card key={index} title={title} level={level} cardIndex={index + 1} />
        ))
      ) : (
        <Card />
      )}
    </Wrapper>
  )
}

export default JobLevel
