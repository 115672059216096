import { AbandonmentLocalStorageKeys } from 'builder/modules/ui'

export const shouldOpenPlansPageAbandonment = (
  registeredAt: string,
  plansPageAbandonmentFlag: boolean,
  showAbandonmentFlows: boolean,
) => {
  if (plansPageAbandonmentFlag) {
    const currentTime = new Date().toUTCString()
    const isAbandonmentModalClosedByUser = localStorage.getItem(
      AbandonmentLocalStorageKeys.SHOW_ABANDONMENT_MODAL_FLAG,
    )
    const signupTime = new Date(registeredAt).toUTCString()
    const timeDiff = Math.floor(
      (new Date(currentTime).getTime() - new Date(signupTime).getTime()) / 1000 / 60,
    )
    return isAbandonmentModalClosedByUser === null && timeDiff < 5 && showAbandonmentFlows
  }
  return false
}
