import styled from 'styled-components'
import Button, { ButtonSize } from 'builder/components/Button'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'

export const Container = styled.div`
  display: flex;
  border-radius: 16px;
  padding: 40px 0 28px;
  background: ${Colors.Indigo10};
  flex-direction: column;
  align-items: center;
  gap: 20px;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  max-width: 416px;
  margin: 0 auto;
  text-align: center;
`

export const Header = styled.div`
  ${Typography.S};
  ${FontWeights.Medium};

  color: ${Colors.Blue80};
`

export const SubHeader = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular};

  color: ${Colors.Blue80};
`

export const ActionButton = styled(Button).attrs({ size: ButtonSize.small })`
  margin-top: 4px;
`

export const ImageContainer = styled.img`
  width: 444.28px;
  height: 223px;
`
