import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const InterviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 4px;
`

export const ContainerTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const InterviewTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 4px;
`

export const TopLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const Title = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};
`

export const Question = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};
`

export const SubInfo = styled.div`
  color: ${Colors.Neutral50};
  ${Typography.Body};
  ${FontWeights.Regular};
`

export const HowToAnswer = styled.div`
  color: ${Colors.Neutral50};
  ${FontWeights.DemiBold};
  ${Typography.Caps};
  text-transform: uppercase;
`
export const Hint = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular};
`

export const InterviewDescription = styled.div`
  ${Typography.Body};
  color: ${Colors.Neutral80};
  ${FontWeights.Regular};
`

export const PremiumWarning = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 5px;
`

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`
export const Duration = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`
