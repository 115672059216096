import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import AutoSuggestField from 'builder/components/FindJob/AutoSuggestField'
import Button from 'builder/components/Button'
import { fadeIn } from 'builder/styles/keyframes'
import Sizes from 'builder/styles/sizes'

export const ModalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${Colors.White};
  border-radius: ${Sizes.S};
  padding: ${Sizes.L};
  gap: ${Sizes.L};
  max-height: 804px;
  max-width: 544px;
  width: 100%;
  height: 100%;

  ${Media.AbovePhone`
    height: 80%;
  `}

  ${Media.Phone`
    height: 70%;
    overflow: hidden;
    border-radius: 0;
    max-width: 335px;
  `};
`

export const ModalHeaderContainer = styled.div`
  max-width: 480px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${Sizes['2XS']};
`

export const RoleHeader = styled.img`
  width: 180px;
`

export const LocationHeader = styled.img`
  height: 109px;
  margin: 20px;
`

export const ModalBody = styled.div`
  position: relative;
  width: 100%;
  flex: auto;
`

export const ModalTitle = styled.p`
  color: ${Colors.Neutral90};
  ${Typography.S};
  ${FontWeights.Bold};
  text-align: center;
  width: 100%;

  ${Media.Phone`
    text-align: left;
  `};
`

export const StepButton = styled(Button)<{
  $back?: boolean
  isDisabled?: boolean
  $useAlternativeButton: boolean
  $skip?: boolean
  $fixedMarginLeft?: boolean
}>`
  ${props => (props.$fixedMarginLeft ? `margin-left: ${Sizes['2XS']};` : 'margin-left: auto;')}

  display: block;
  ${props =>
    props.$useAlternativeButton &&
    `
      background: ${Colors.Indigo50};
      color: ${Colors.White};
    `}

  ${props =>
    props.$back &&
    css`
      margin-left: 0;
    `}

  ${props =>
    (props.$back || props.$skip) &&
    css`
      background: ${Colors.White};
      color: ${Colors.Neutral90};
    `}

  ${props =>
    props.isDisabled &&
    css`
      opacity: 0.6;
    `}
`

export const AutoSuggestContainer = styled.div<{ $isExpanded?: boolean }>`
  background: ${Colors.Neutral10};
  border-radius: 4px;

  ${Media.AbovePhone`
    width: 480px;
  `}

  ${Media.Phone`
    width: 100%;
    margin-right: 32px;
  `}

  ${props =>
    props.$isExpanded &&
    css`
      background: ${Colors.White};
    `}
`
export const StyledAutoSuggestField = styled(AutoSuggestField)``

export const ErrorDescriptionContainer = styled.div`
  ${Typography.Caption};
  color: ${Colors.Red50};
  margin-top: ${Sizes.XS};
`
export const ModalFooter = styled.div`
  width: 100%;
  display: flex;
`

export const StyledModalOverlay = styled.div`
  background: rgba(15, 20, 31, 0.8);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(black, 0.8);
  animation: ${fadeIn} 0.3s ease-out;
  & > :first-child {
    z-index: 610;
  }
`

export const Subtitle = styled.p`
  color: ${Colors.Neutral60};
  ${Typography.Body};
  margin-top: ${Sizes.XS};
`

export const CustomAutoSuggestField = styled(AutoSuggestField)`
  background-color: ${Colors.Neutral10};
  border-radius: ${Sizes.XS};
`
