import { useCallback, useMemo } from 'react'
import { useI18n } from 'builder/hooks/useI18n'
import { selectors as jobSearchSelectors } from 'builder/modules/jobSearch'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { FrequencyType } from 'builder/modules/jobSearch/types'

const TRANSLATION = 'builder.job_search.alert_modal.frequencies'

export const useTranslatedFrequenciesString = () => {
  const { i18n } = useI18n()
  const alertFeatureGroup = useTypedSelector(jobSearchSelectors.alertFeatureGroup)

  const getTranslatedTextFrequency = useCallback(
    (frequency: FrequencyType) => {
      switch (frequency) {
        case FrequencyType.daily:
          return i18n.t(`${TRANSLATION}.day`)
        case FrequencyType.weekly:
          return i18n.t(`${TRANSLATION}.week`)
        case FrequencyType.two_weeks:
          return i18n.t(`${TRANSLATION}.two_weeks`)
      }
    },
    [i18n],
  )

  const frequenciesValues = useMemo(() => {
    if (alertFeatureGroup === 'daily') {
      return [FrequencyType.daily]
    }

    if (alertFeatureGroup === 'weekly') {
      return [FrequencyType.weekly]
    }

    return []
  }, [])

  const frequencies = useMemo(() => {
    return frequenciesValues.map((id: FrequencyType) => ({
      id,
      name: getTranslatedTextFrequency(id),
    }))
  }, [frequenciesValues, getTranslatedTextFrequency])

  return { frequencies, getTranslatedTextFrequency }
}
