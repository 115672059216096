import { useMemo } from 'react'
import styled, { css } from 'styled-components'
import {
  Cards,
  PromotionTopic,
} from 'builder/components/SubscriptionCancellationFormNew/common/types'
import Media from 'builder/styles/media'
import { FontWeights } from 'builder/styles/typography'
import { getLineHeightRatio } from 'builder/components/SubscriptionCancellationFormNew/common/utils'
import usePromotionCardValues from 'builder/components/SubscriptionCancellationFormNew/hooks/usePromotionCardValues'
import Colors from 'builder/styles/colors'

interface Props {
  topic: PromotionTopic
}

export const PromotionDescriptionV7 = styled.p<Props>(({ topic }) => {
  const { getCardStyles } = usePromotionCardValues()
  const cardStyles = getCardStyles(topic)
  const conditionalStyles = useMemo(() => {
    switch (topic) {
      case Cards.JobOffer:
        return css`
          ${FontWeights.Medium};
          margin-bottom: 28px;
        `
      case Cards.Discount:
        return css`
          margin-bottom: 52px;
        `
      case Cards.FeatureOrProblem:
        return css`
          margin-bottom: 24px;
        `
      case Cards.DreamJob:
        return css`
          margin-bottom: 24px;
        `
      case Cards.OtherReasons:
        return css`
          margin-bottom: 24px;
        `
      default:
        return css``
    }
  }, [topic])

  return css`
    ${FontWeights.Regular};
    font-size: 19px;
    line-height: ${getLineHeightRatio(24, 19)};
    margin-top: 16px;
    color: ${cardStyles.noticeTextColor};
    & a {
      color: ${Colors.Blue60};
    }
    ${conditionalStyles}

    ${Media.Tablet`
      text-align: center;
      margin-top: 12px;
      margin-bottom: 24px;
    `}

    ${Media.Phone`
      font-size: 16px;
      line-height: ${getLineHeightRatio(20, 16)};
      text-align: center;
      margin-top: 12px;
      margin-bottom: 16px;
    `}
  `
})
