import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import { Container as BaseContainer } from 'builder/components/Container'
import Media from 'builder/styles/media'

export const Footer = styled.div`
  width: 736px;
  height: 260px;
  background-color: ${Colors.Blue10};
  border-radius: 8px;
  margin-top: 23px;
  margin-bottom: 25px;
  padding-top: 15px;
  padding-left: 5px;
  position: relative;

  ${Media.Tablet`
    width: 100%;
    margin-top: 0;
    left:0;
    margin-bottom: 25px;
    margin-top: 24px;
    padding: 32px;
    height: 100%;
  `};

  ${Media.Phone`
    padding: 20px 20px 22px 0;
    margin-top: 24px;
    height: 100%;
    border-radius: 0;
  `}
`

export const FooterContainer = styled(BaseContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const FooterContent = styled.div`
  max-width: 576px;

  ${Media.Tablet`
    max-width: 100%;
  `};
`

export const FooterTitle = styled.div`
  margin-bottom: 8px;
  ${Typography.Subhead};
  ${FontWeights.Medium};
  width: 265px;
  height: 28px;
  align-items: center;
  color: ${Colors.Blue80};

  ${Media.Tablet`
    margin-top: 0;
    left:0;
  `};
`

export const FooterText = styled.div`
  margin-bottom: 20px;
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Blue80};
  width: 347px;
  height: 72px;

  ${Media.Phone`
    width: 245px;
    height: 60px;
    top: 1538px;
  `}
`

export const FooterImage = styled.img`
  width: 280px;
  flex-shrink: 0;
  margin-left: 56px;
  height: 203.63px;
  margin-top: 24px;

  ${Media.Tablet`
    margin-top: 0px;
  `}

  ${Media.Phone`
    width: 142.51px;
    height: 103.64px;
    position: absolute;
    right: 12.5px;
    bottom: 12.5px;
    margin-top: 24px;
  `}
`
