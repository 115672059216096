import styled, { css } from 'styled-components'
import { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import { fadeIn } from 'builder/styles/keyframes'
import Media from 'builder/styles/media'

export const ButtonIcon = styled.i`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 4px;
  padding: 4px 4px;
  box-sizing: content-box;
`

export const ButtonDot = styled.span`
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: currentColor;
  will-change: transform, opacity, border-radius;
  transition: transform 0.25s, opacity 0.25s, border-radius 0.25s;
`

const getDotActiveStyles = (translate: string) => css`
  transform: translate(${translate});
  opacity: 0;
`

const getCrossActiveStyles = (rotate: number, translateY: number) => css`
  transform: rotate(${rotate}deg) scale(0.65, 3.3) translateY(${translateY}px);
  border-radius: 0.5px;
`

export const ButtonContainer = styled.button<{ isActive: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 9px 16px;
  border: none;
  background: none;
  color: ${Colors.Neutral90};
  transition: color 0.2s;
  cursor: pointer;
  outline: none;
  margin-left: 24px;
  background-color: ${Colors.Neutral5};
  border-radius: 32px;
  padding-left: 12px;
  padding-right: 16px;

  ${ButtonDot} {
    color: ${Colors.Blue50};
  }

  ${Media.Tablet`
     margin-left: 16px;
  `}

  ${Media.Phone`
     margin-left: 0;
  `}
  
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: -4px;
    left: -4px;
    padding: 4px;
    box-sizing: content-box;
  }

  &:hover,
  &:focus-visible {
    color: ${Colors.Blue60};
    background-color: ${Colors.Blue10};
  }

  ${props =>
    props.isActive &&
    css`
      & ${ButtonDot}:nth-child(2) {
        ${getDotActiveStyles('0, 6px')}
      }

      & ${ButtonDot}:nth-child(4) {
        ${getDotActiveStyles('6px, 0')}
      }

      & ${ButtonDot}:nth-child(5) {
        opacity: 0;
      }

      & ${ButtonDot}:nth-child(6) {
        ${getDotActiveStyles('-6px, 0')}
      }
      & ${ButtonDot}:nth-child(8) {
        ${getDotActiveStyles('0, -6px')}
      }

      & ${ButtonDot}:nth-child(1) {
        ${getCrossActiveStyles(-45, 1.5)}
      }

      & ${ButtonDot}:nth-child(3) {
        ${getCrossActiveStyles(45, 1.5)}
      }

      & ${ButtonDot}:nth-child(7) {
        ${getCrossActiveStyles(45, -1.5)}
      }

      & ${ButtonDot}:nth-child(9) {
        ${getCrossActiveStyles(-45, -1.5)}
      }
    `};
`

export const ButtonText = styled.span`
  ${FontWeights.Regular};
  animation: ${fadeIn} 0.25s;
  padding-left: 8px;
  white-space: nowrap;
  transition: opacity 0.25s, visibility 0.25s;
`
