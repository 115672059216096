import React from 'react'
// import Icon from 'builder/components/Icon'
// import Colors from 'builder/styles/colors'
import { CardType } from '../InterviewCard'
import { Container, Spinner, Label } from './styles'

export const GenerateStatus: React.FC<{ type?: CardType; error: string }> = ({
  type,
  error = '',
}) => {
  if (type === 'error' && error) {
    return (
      <Container>
        {/* <Icon.Error color={Colors.Red40} /> */}
        <Label>{error}</Label>
      </Container>
    )
  }

  if (type === 'loading') {
    return (
      <Container>
        <Spinner />
        <Label>Generating...</Label>
      </Container>
    )
  }

  return <></>
}
