import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors, Label } from 'builder/modules/careerPath2'
import Loader from '../Loader'
import Content from './components/Content'
import { Container, Heading } from './styles'

const Responsibility = () => {
  const selectedJobTitle = useTypedSelector(selectors.selectedJobTitle)
  const isExploreCareers = useTypedSelector(selectors.isExploreCareers)
  const isLoading = useTypedSelector(selectors.isCareerPagesJobDetailsLoading)
  const { responsibilities } = useTypedSelector(selectors.careerPagesJobDetails)
  const exploreCareersOccupation = useTypedSelector(selectors.exploreCareersOccupation)

  const label = isExploreCareers ? 'explore_careers' : Label.label
  const jobTitle = !isExploreCareers ? selectedJobTitle : exploreCareersOccupation

  return (
    <Container>
      <Heading>Responsibilities</Heading>
      {isLoading ? (
        <Loader />
      ) : (
        <Content jobResponsibilities={responsibilities} jobTitle={jobTitle} label={label} />
      )}
    </Container>
  )
}

export default Responsibility
