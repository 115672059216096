import styled from 'styled-components'
import Button from 'builder/components/Button'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 352px;
  min-width: 352px;
  height: 336px;
  border-radius: var(--S, 16px);
  border: var(--Zero, 1px) solid ${Colors.Neutral15};
  background: ${Colors.White};
  padding: 26px;
`

export const Header = styled.div`
  ${Typography.Caption};
  ${FontWeights.Medium};
  color: ${Colors.Neutral50};
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

export const Title = styled.div`
  padding-left: 8px;
`

export const TitleContainer = styled.div`
  display: flex;
`

export const TrashIconContainer = styled.div`
  cursor: pointer;
  display: none;
  justify-content: center;
  align-items: flex-start;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding-top: 4px;
  ${Media.Hover`
    ${Container}:hover & {
      display: flex;
      background-color: ${Colors.Neutral5};
      color: ${Colors.Neutral40};
    }
  `};
`
export const ButtonContainer = styled.div`
  padding-top: 16px;
  grid-gap: 8px;
  display: flex;
`

export const CustomButton = styled(Button)`
  width: 100%;
  padding: var(--2XS, 8px) var(--XS, 12px);
`

export const JobTitle = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
`

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
`

export const CompanyName = styled.div`
  color: ${Colors.Neutral50};
`

export const LogoWrapper = styled.div`
  padding-bottom: 12px;
`

export const InterviewStatusWrapper = styled.div`
  padding-bottom: 2px;
`
