import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

export const ModalContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  height: calc(100vh - 100px);
  background-color: ${Colors.White};
  border-radius: 6px;

  ${Media.Phone`
    max-width: none;
    height: 100%;
    border-radius: 0;
  `};
`

export const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;
  border-bottom: 1px solid ${Colors.Neutral15};
  background-color: ${Colors.White};

  ${Media.AbovePhone`
    padding-left: 32px;
  `};
`

export const Logo = styled.div`
  width: 114px;
  height: 28px;
  background: url(${require('images/builder/trustpilot/logo.svg')}) no-repeat;
`

export const Content = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  flex-grow: 1;
`

export const Iframe = styled.iframe.attrs({
  frameBorder: 0,
})`
  position: absolute;
  left: 0;
  top: -72px;
  width: 100%;
  height: calc(100% + 72px);
`

export const Close = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${Colors.Neutral40};
  transition: color 0.15s;

  &:hover {
    color: ${Colors.Blue50};
  }

  &:after {
    content: '';
    position: absolute;
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
  }
`
