import styled, { css } from 'styled-components'
import FlipMove from 'react-flip-move'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'
import { Icon20 } from 'builder/components/Icon'

export const Container = styled.div`
  margin-top: 20px;

  ${Media.Phone`
    overflow: hidden;
    margin-left: -20px;
    margin-right: -20px;
  `};
`

export const Content = styled.div`
  display: flex;

  ${Media.Phone`
    padding-bottom: 30px;
    margin-bottom: -30px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  `};
`

export const List = styled(FlipMove)`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px -8px 0;

  ${Media.Phone`
    flex-wrap: nowrap;
    padding: 0 20px;
    margin: 0;
  `};
`

export const Skill = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  margin: 0 12px 12px 0;
  padding: 6px 6px 6px 10px;
  border-radius: 4px;
  background-color: ${Colors.Neutral10};
  color: ${Colors.Neutral90};
  ${Typography.Caption};
  white-space: nowrap;
  transition: background-color 0.1s, color 0.1s;
  will-change: opacity, transform;
  cursor: pointer;

  ${Media.Phone`

    margin: 0 12px 12px 0;

    &:last-child {
      margin-right: 0;
    }
  `};

  ${Media.Hover`
    &:hover {
      background-color: ${Colors.Blue10};
      color: ${Colors.Blue50};
    }
  `};

  ${props =>
    props.isActive &&
    css`
      background-color: ${Colors.Blue50};
      color: ${Colors.White};
      pointer-events: none;
    `};
`

export const SkillAddIcon = styled(Icon20.Add)`
  margin-left: 2px;
  color: ${Colors.Neutral50};
  transition: color 0.1s;

  ${Skill}:hover & {
    color: ${Colors.Blue50};
  }
`

export const SkillTickIcon = styled(Icon20.Tick)`
  margin-left: 2px;
  color: ${Colors.White};
`
