import { Optional, UserDocumentTemplate } from 'packages/types'
import { GeneralEditorStore } from 'builder/modules/generalEditor'
import { i18n as I18n } from 'builder/utils/i18n'
import { ATS_TEMPLATES, TemplateName } from 'builder/components/Helper/constants'
import { filterGreekTemplates } from './filterGreekTemplates'
import { getTemplates } from './getTemplates'

export function templateGroups(
  editorState: GeneralEditorStore,
  documentType: string,
  locale: Optional<string> = '',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any[] {
  // locale check to show japanese template
  const templates = getTemplates(editorState, documentType)
  const isJapaneseFlag = locale === 'ja-JP'

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const japaneseTemplates = templates.filter((template: any) => template.settings.japanese)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let westernTemplates = templates.filter((template: any) => !template.settings.japanese)
  if (isJapaneseFlag) {
    // TODO: Remove this condition after fixing ATS templates
    westernTemplates = westernTemplates.filter(
      (template: UserDocumentTemplate) => !ATS_TEMPLATES.includes(template.id as TemplateName),
    )
  }

  const groups = [
    japaneseTemplates.length &&
      isJapaneseFlag && {
        title: I18n.t('builder.resume_editor.template_groups.japanese'),
        templates: japaneseTemplates,
      },
    westernTemplates.length && {
      title: I18n.t('builder.resume_editor.template_groups.western'),
      // TODO after Greek templates release: Remove the condition
      templates:
        documentType === 'resume'
          ? westernTemplates
          : filterGreekTemplates(westernTemplates, locale),
    },
  ].filter(Boolean)

  return groups
}
