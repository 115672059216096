import { NavigateFunction } from 'react-router-dom'
import { Iframe, SectionWrapper, Title, Container, SubTitle, List, ListItem } from '../../styles'

type Props = {
  navigateTo: NavigateFunction
}

const benefits = [
  { id: 1, benefit: 'Guide you through how to onboard successfully' },
  { id: 2, benefit: 'Focus on how to develop key relationships with your colleagues' },
  { id: 3, benefit: 'Track your progress across across your first 90 days' },
]

export const First90Days = ({ navigateTo }: Props) => {
  return (
    <Container>
      <Iframe src="https://www.youtube.com/embed/JCSFaTxUGwI" />
      <SectionWrapper>
        <Title>
          Starting a new job feels overwhelming! Our plan creates a structure to help you excel from
          day one.
        </Title>
        <SubTitle>
          Together, we&apos;ll:
          <List>
            {benefits.map(({ id, benefit }) => (
              <ListItem key={id}>{benefit}</ListItem>
            ))}
          </List>
          After engaging in this plan, you’ll be confident in yourself, your work, and your
          relationships. If you’re looking for additional support,{' '}
          <span onClick={() => navigateTo('/career-coaching')}>book a session</span> with a coach,
          and we can help you.
        </SubTitle>
      </SectionWrapper>
    </Container>
  )
}
