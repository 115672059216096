import styled, { css, keyframes } from 'styled-components'
import Typography from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import { Icon20 } from 'builder/components/Icon'
import { Button } from 'builder/components/Button'
import Media from 'builder/styles/media'

const POPUP_WIDTH = 480

const appear = keyframes`
  from {
    opacity: 0;
    transform: translateY(12px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
`

export const PopupContainer = styled.div`
  position: absolute;
  z-index: 6;
  width: ${POPUP_WIDTH}px;
  left: calc(50% - ${POPUP_WIDTH / 2}px);
  bottom: 32px;
  display: flex;
  align-items: flex-start;
  padding: 24px 40px 24px 24px;
  border-radius: 6px;
  background-color: ${Colors.White};
  color: ${Colors.Neutral90};
  box-shadow: 0 0 1px rgba(15, 56, 113, 0.32), 0 6px 8px -2px rgba(15, 56, 113, 0.02),
    0 16px 64px -8px rgba(15, 56, 113, 0.18);
  animation: ${appear} 0.25s ease-out;
`

export const PopupVisual = styled.div`
  display: flex;
  flex-direction: column-reverse;
  flex-shrink: 0;
  margin-right: 20px;
`

const borderedCircleStyles = css`
  position: relative;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  box-shadow: 0 0 0 4px ${Colors.White};
  border-radius: 50%;

  &:not(:last-child) {
    margin-top: -4px;
  }
`

export const PopupIconWrapper = styled.div`
  ${borderedCircleStyles};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.Indigo80};
  color: ${Colors.White};
`

export const PopupIcon = styled(Icon20.Star)`
  width: 24px;
  height: 24px;
`

export const PopupPhoto = styled.img`
  ${borderedCircleStyles};
`

export const PopupContent = styled.div`
  flex-grow: 1;
`

export const PopupTitle = styled.div`
  ${Typography.Body};
  font-weight: 600;
`

export const PopupText = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
`

export const PopupButtons = styled.div`
  display: flex;
  margin-top: 16px;
`

export const PopupActionButton = styled(Button)`
  flex-shrink: 0;
  margin-right: 8px;

  ${props =>
    !props.isDisabled &&
    css`
      background-color: ${Colors.Indigo80};

      &:hover {
        background-color: ${Colors.Indigo90};
      }
    `}
`

export const PopupActionButtonIcon = styled(Icon20.ChevronDown)`
  margin-right: -8px;
  margin-left: 2px;
  transform: rotate(-90deg);
`

export const PopupClose = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px;
  display: flex;
  color: ${Colors.Neutral40};
  cursor: pointer;

  &:hover {
    color: ${Colors.Blue60};
  }
`

export const CioPopupContainer = styled.div`
  position: absolute;
  z-index: 6;
  width: 449px;
  height: 212px;
  margin-left: 30%;
  top: 72px;
  display: flex;
  align-items: flex-start;
  padding: 24px 40px 24px 24px;
  border-radius: 6px;
  background-color: ${Colors.White};
  color: ${Colors.Neutral90};
  box-shadow: 0 0 1px rgba(15, 56, 113, 0.32), 0 6px 8px -2px rgba(15, 56, 113, 0.02),
    0 16px 64px -8px rgba(15, 56, 113, 0.18);
  animation: ${appear} 0.25s ease-out;

  ${Media.Tablet`
    margin-left: 33%;
  `};

  ${Media.Phone`
    position: fixed;
    width: 343px;
    padding-left:10px;
    height: 166px;
    margin: 0px;
    left: 50%;
    transform: translate(-50%);
    top: 472px;
  `};
`

export const CioPopupActionButton = styled(Button)`
  flex-shrink: 0;
  margin-left: 8px;

  ${props =>
    !props.isDisabled &&
    css`
      background-color: ${Colors.Blue50};

      &:hover {
        background-color: ${Colors.Blue60};
      }
    `}
`

export const CioPopupButtons = styled.div`
  display: flex;
  margin-top: 30px;
`

export const Close = styled.div`
  position: absolute;
  width: 28px;
  height: 28px;
  top: 25px;
  right: 26px;
  border-radius: 32px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.Neutral30};
  cursor: pointer;
  transition: color 0.1s;
  background-color: ${Colors.Neutral10};

  &:hover {
    color: ${Colors.Blue50};
  }

  ${Media.Phone`
    top: 20px;
    right: 20px;
    padding: 2px;
  `};
`

export const CioPopupText = styled.div`
  ${Typography.Body};
  color: ${Colors.Neutral50};
  width: 281px;

  ${Media.Phone`
    padding-left: 10px;
  `};
`

export const CioPopupPhoto = styled.img`
  position: relative;
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  box-shadow: 0 0 0 4px ${Colors.White};
  border-radius: 50%;

  &:not(:last-child) {
    margin-top: -4px;
  }
`

export const CloseButton = styled(Button)`
  margin-left: 126px;
  background-color: ${Colors.White};

  ${Media.Phone`
     margin-left: 116px;
  `};
`

export const CioPopupTitle = styled.div`
  ${Typography.Subhead};
  font-weight: 600;

  ${Media.Phone`
    padding-left: 10px;
  `};
`

export const CioPopupIconWrapper = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 50%;
  margin-top: 10px;
  margin-left: 30px;
  color: ${Colors.White};
  box-shadow: 0 0 0 4px ${Colors.White};
  background-color: ${Colors.Indigo80};
  border-radius: 50%;
`

export const CioPopupIcon = styled(Icon20.Star)`
  width: 24px;
  height: 24px;
  ${Media.Phone`
  width: 18px;
  height: 18px;
  margin-top: -2px;
  `}
`
