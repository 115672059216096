import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { selectors as userSelectors } from 'builder/modules/user'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useUser } from 'builder/hooks/useUser'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import { CareerInsightsProcessingStatus, actions, selectors } from 'builder/modules/careerProfile'
import { FetchStatuses } from 'builder/modules/constants'
import {
  PreFillModal,
  PreFillModalClose,
  PreFillModalCloseIcon,
} from 'builder/components/CareerPath2Skills'
import { BriefResume } from 'builder/modules/panel'
import { useI18n } from 'builder/hooks/useI18n'
import { actions as editorActions } from 'builder/modules/resumeEditor'
import * as Styled from './styles'
import { HeroSection } from './components/HeroSection'
import { PathSection } from './components/PathSection'
import { RolesSection } from './components/RolesSection'
import { SkillsSection } from './components/SkillsSection'
import { TextSection } from './components/TextSection'
import { ActionSection } from './components/ActionSection'
import { Loader } from './components/Loader'
import { BottomCardList } from './components/BottomCardList'
import { useTextParts } from './hooks/useTextParts'
import { useJobList } from './hooks/useJobList'
import { useSkillsList } from './hooks/useSkillsList'
import { analyticEventsLabel, translationKey } from './constants'
import { useCareerInsightsLoadingEffect } from './hooks/useCareerInsightsLoadingEffect'
import { getTailoringResumeEditorLink } from './helpers'
import { Feedback } from './components/Feedback'

export const CareerInsights = () => {
  const dispatch = useDispatch()
  const user = useUser()
  const { i18n } = useI18n()

  const photoUrl = useTypedSelector(userSelectors.photoUrl)
  const fetchStatus = useTypedSelector(selectors.fetchCareerInsightsStatus)
  const careerInsightsData = useTypedSelector(selectors.careerInsightsData)
  const assessmentResults = useTypedSelector(selectors.careerAssessmentResults)
  const isOptimizeResumeModalOpened = useTypedSelector(selectors.isOptimizeResumeModalOpened)
  const fetchCareerProfileStatus = useTypedSelector(selectors.fetchCareerProfileStatus)
  const careerProfileData = useTypedSelector(selectors.careerProfileData)

  const careerPathsParts = useTextParts(assessmentResults?.careerPaths)
  const nextMoveParts = useTextParts(assessmentResults?.nextMove)
  const transferrableSkillsParts = useTextParts(assessmentResults?.transferableSkills)
  const skillsSourcesParts = useTextParts(assessmentResults?.skillsCourses)
  const progressionJobs = useJobList(assessmentResults?.dataForGraphics?.progressionList)
  const possibleRoles = useJobList(assessmentResults?.dataForGraphics?.transferrableSkillsList)
  const recommendedSkills = useSkillsList(assessmentResults?.dataForGraphics?.skillCoursesList)

  const loadingStatues = [FetchStatuses.notAsked, FetchStatuses.loading]
  const isLoading =
    loadingStatues.includes(fetchStatus) || loadingStatues.includes(fetchCareerProfileStatus)
  const isPending = careerInsightsData?.status === CareerInsightsProcessingStatus.pending

  const onCloseResumeSelectorModal = useCallback(() => {
    dispatch(actions.setIsOptimizeResumeModalOpened(false))
  }, [dispatch])

  const onSelectResumeForOptimization = useCallback(
    (resume: BriefResume) => {
      window.open(getTailoringResumeEditorLink(resume.id), '_target')
      dispatch(actions.setIsOptimizeResumeModalOpened(false))
      dispatch(editorActions.setOpenOptimizerModal(true))
      dispatch(editorActions.startResumeOptimizerProcess())
    },
    [dispatch],
  )

  useEffectOnMount(() => {
    trackInternalEvent('enter_career_insights', { label: analyticEventsLabel })
    dispatch(actions.setSeenPage('insights'))

    if (fetchCareerProfileStatus === FetchStatuses.notAsked) {
      dispatch(actions.fetchCareerProfile())
    }
  })

  useCareerInsightsLoadingEffect()

  return (
    <>
      {isLoading || isPending ? (
        <Styled.LoaderWrapper>
          <Loader isLoading={isLoading} isPending={isPending} />
        </Styled.LoaderWrapper>
      ) : (
        <Styled.Container timeout={0}>
          <Styled.MainSections>
            {user && <HeroSection user={user} photoUrl={photoUrl} />}

            <TextSection textParts={careerPathsParts} />

            {user && !!progressionJobs.length && (
              <PathSection
                user={user}
                photoUrl={photoUrl}
                progressionJobs={progressionJobs}
                // Todo add this from API
                currentJobPosition=""
              />
            )}

            <TextSection textParts={nextMoveParts} />

            {!!possibleRoles.length && <RolesSection roles={possibleRoles} />}

            <TextSection textParts={transferrableSkillsParts} />

            {!!recommendedSkills.length && <SkillsSection skills={recommendedSkills} />}

            <TextSection textParts={skillsSourcesParts} />

            <Feedback />
          </Styled.MainSections>

          {user && careerProfileData && (
            <BottomCardList user={user} userType={careerProfileData.userType} />
          )}

          {user && <ActionSection user={user} />}
        </Styled.Container>
      )}

      {isOptimizeResumeModalOpened ? (
        <PreFillModal
          footer={<></>}
          header={
            <>
              <Styled.SelectResumeModalHeader>
                {i18n.t(`${translationKey}.select_resume_modal_header`)}
              </Styled.SelectResumeModalHeader>
              <PreFillModalClose onClick={onCloseResumeSelectorModal}>
                <PreFillModalCloseIcon />
              </PreFillModalClose>
            </>
          }
          onSelect={onSelectResumeForOptimization}
        />
      ) : null}
    </>
  )
}
