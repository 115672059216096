import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import { CentralColumn } from '../../styles'

export const Container = styled(CentralColumn)<{ $isReversed?: boolean; $isRowOnTablet?: boolean }>`
  display: flex;
  align-items: center;
  flex-flow: row;
  gap: 64px;

  ${Media.Tablet`
    flex-flow: column-reverse;
  `}

  ${props =>
    props.$isRowOnTablet &&
    css`
      ${Media.Tablet`
        flex-flow: row;
      `}
    `}

  ${Media.Phone`
    flex-flow: column-reverse;
    gap: 24px;
  `}

  ${props =>
    props.$isReversed &&
    css`
      ${Media.AboveTablet`
        flex-flow: row-reverse;
      `}
    `}
`

export const TextContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: 16px;

  ${Media.Phone`
    gap: 12px;
  `}
`

export const Text = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral100};
`

export const Description = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral60};
`
