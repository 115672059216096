import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import { Icon20 } from 'builder/components/Icon'
import Typography, { FontWeights } from 'builder/styles/typography'
import Sizes from 'builder/styles/sizes'

export const ChevronIcon = styled(Icon20.ChevronDown)<{ $up: boolean }>`
  height: 24px;
  width: 24px;
  color: ${Colors.Blue50};
  flex-shrink: 0;
  ${({ $up }) =>
    $up &&
    css`
      transform: rotate(180deg);
    `}
`
export const AccordionContainer = styled.div``

export const AccordionItem = styled.div`
  border-bottom: 1px solid ${Colors.Neutral15};
  margin-bottom: ${Sizes.M};
  padding-bottom: ${Sizes.M};
  &:last-child {
    border-bottom: none;
  }
`

export const AccordionTitle = styled.div<{ $hasContent: boolean }>`
  display: flex;
  justify-content: space-between;
  ${({ $hasContent }) => $hasContent && `cursor: pointer;`}
  ${Typography.Body}
  ${FontWeights.Medium}
  color: ${Colors.Neutral90};
`

export const AccordionContent = styled.div<{ $isOpen: boolean }>`
  display: ${props => (props.$isOpen ? 'block' : 'none')};
  overflow: hidden;
  max-height: ${props => (props.$isOpen ? 'auto' : '0')};
  transition: max-height 0.3s ease-out;
  margin-top: ${Sizes.XS};
  ${Typography.Caption}
  ${FontWeights.Regular}
  color: ${Colors.Neutral50};
`
