import { useEffect, useState, useRef } from 'react'
import trim from 'lodash/trim'
import uniq from 'lodash/uniq'
import nanoid from 'nanoid'
import { Resume } from '@rio/types'
import jwtService from 'builder/services/JwtService'
import { useDebouncedEffect } from 'builder/hooks/useDebouncedEffect'

interface Options {
  locale: string
  position: Resume['position']
  currentSkillNames: string[]
}

// Hook to load skill suggestions from the ML-based service
export const useSuggestions = ({ locale, position, currentSkillNames }: Options): string[] => {
  const [suggestions, setSuggestions] = useState<string[]>([])
  const lastRequestId = useRef<string | null>(null)

  // Track component mounting state
  const isMounted = useRef(true)
  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  // Communicate with the microservice
  const fetchSuggestions = () => {
    const requestId = nanoid()
    lastRequestId.current = requestId

    jwtService.client
      .post<{ suggested_skills?: string[] }>('https://skills-ml.resume.io/suggest', {
        locale,
        job_title: position,
        current_skills: currentSkillNames,
      })
      .then(response => {
        if (!isMounted.current) return
        // If previous request took too long, skip it
        if (lastRequestId.current !== requestId) return
        // Avoid displaying empty skills
        const suggestedSkills = (response.data.suggested_skills || []).map(trim).filter(Boolean)
        setSuggestions(uniq(suggestedSkills))
      })
  }

  // Fetch new suggestions only if user changed his job title or updated skill names list
  // Debounce the callback to avoid extra requests and save money.
  useDebouncedEffect(
    fetchSuggestions,
    1000,
    // We have to stringify the skill names list since React compares deps with `===`
    [locale, position, currentSkillNames.sort().join(', ')],
  )

  return suggestions
}
