import { useEscapeHandler } from 'builder/hooks/useEscapeHandler'

type EscapeHandlerProps = {
  onPress: () => void
  children?: React.ReactElement
}

// Simple wrapper that makes possible using the hook inside a class-component
const EscapeHandler = ({ onPress, children }: EscapeHandlerProps) => {
  useEscapeHandler(onPress)
  return children || null
}

export default EscapeHandler
