import { Fragment } from 'react'

import {
  Container,
  Content,
  TopContent,
  Header,
  Radio,
  Option,
  Options,
  SubLabel,
  RadioControl,
  OptionContent,
  OptionContentBox,
} from './styles'

import { INITIALCAREERLEVELS } from './constants'
interface CareerEntryProps {
  onCurrentStageChange: (name: string) => void
  careerStages: string
}

const CareerStage = ({ onCurrentStageChange, careerStages }: CareerEntryProps) => {
  return (
    <Container>
      <Content>
        <TopContent>
          <Header>What is your current career stage?</Header>
        </TopContent>
      </Content>

      <Options>
        {INITIALCAREERLEVELS.map(careerLevel => (
          <Option
            key={careerLevel.name}
            checked={careerLevel.name === careerStages}
            onClick={() => onCurrentStageChange(careerLevel.name)}
          >
            <OptionContent>
              <Radio checked={careerLevel.name === careerStages}>
                <RadioControl checked={careerLevel.name === careerStages} />
              </Radio>
              <OptionContentBox>
                <Fragment>{careerLevel.name}</Fragment>
                <SubLabel>{careerLevel.description}</SubLabel>
              </OptionContentBox>
            </OptionContent>
          </Option>
        ))}
      </Options>
    </Container>
  )
}

export default CareerStage
