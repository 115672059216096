import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { useClickOutside } from 'builder/hooks/useClickOutside'
import ColorSelectButton from 'builder/components/ColorSelectButton'
import { Background, BackgroundColor } from '../types'
import { BACKGROUND_COLORS, DARK_BACKGROUND_COLORS } from '../constants'
import { areEqualBackgroundColors } from '../utils'
import BackgroundPreview from '../BackgroundPreview'
import {
  BgColorPickerWrapper,
  BgColorPickerTrigger,
  BgColorOptionsDropdown,
  BgColorOption,
  BgColorReset,
  BgColorCaption,
} from './styles'

interface PickerProps {
  value: BackgroundColor
  onChange: (color: BackgroundColor) => void
}

export const BackgroundColorPicker = ({ value, onChange }: PickerProps) => {
  const { i18n } = useI18n()
  const [isOpen, setOpen] = useState(false)
  const wrapper = useRef<HTMLDivElement>(null)

  const options: Background[] = useMemo(() => {
    const items = BACKGROUND_COLORS.map(color => ({ color, image: null }))
    items.splice(12, 0, { color: null, image: null })
    return items
  }, [])

  useClickOutside(wrapper, () => setOpen(false))

  useEffect(() => {
    if (isOpen) trackInternalEvent('open_avatar_background_color_picker')
  }, [isOpen])

  const handleChange = (color: BackgroundColor): void => {
    onChange(color)
    setOpen(false)
    trackInternalEvent('choose_avatar_background_color')
  }

  return (
    <BgColorPickerWrapper ref={wrapper}>
      <BgColorPickerTrigger onClick={() => setOpen(!isOpen)}>
        <ColorSelectButton
          size={24}
          active={Boolean(value)}
          color={value}
          onClick={() => setOpen(!isOpen)}
        />
        <BgColorCaption>{i18n.t('builder.avatar_editor.background_color')}</BgColorCaption>
      </BgColorPickerTrigger>
      {isOpen && (
        <BgColorOptionsDropdown>
          {options.map((option, index) => (
            <Fragment key={index}>
              {option.color ? (
                <BgColorOption
                  isActive={areEqualBackgroundColors(value, option.color)}
                  border={DARK_BACKGROUND_COLORS.includes(option.color)}
                  onClick={() => handleChange(option.color)}
                >
                  <BackgroundPreview size={32} background={option} />
                </BgColorOption>
              ) : (
                <BgColorReset
                  isActive={areEqualBackgroundColors(value, null)}
                  onClick={() => handleChange(null)}
                />
              )}
            </Fragment>
          ))}
        </BgColorOptionsDropdown>
      )}
    </BgColorPickerWrapper>
  )
}
