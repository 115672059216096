import { useMemo } from 'react'
import { Resume } from '@rio/types'
import { trackInternalEvent } from '@rio/tracking'
import { useThrottledTaskAnalyzer } from '../../hooks/useThrottledTaskAnalyzer'
import { TaskIds } from '../../types'
import { ButtonContainer, ButtonCounter } from './styles'

interface Props {
  resume: Resume
  skippedTaskId: TaskIds
  onClick: () => void
}

export const TunerEditorFloatingButton = ({ resume, skippedTaskId, onClick }: Props) => {
  const task = useThrottledTaskAnalyzer(resume, skippedTaskId, 350)

  const incompleteIssueIds = useMemo(() => {
    return task ? task.issues.filter(i => i.progress < 1).map(issue => issue.id) : []
  }, [task])

  const handleClick = () => {
    trackInternalEvent('click_tuner_floating_button', { issues: incompleteIssueIds })
    onClick()
  }

  if (task === null) return null

  return (
    <ButtonContainer onClick={handleClick}>
      <ButtonCounter>{incompleteIssueIds.length}</ButtonCounter>
    </ButtonContainer>
  )
}
