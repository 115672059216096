import { useCallback } from 'react'
import { useI18n } from 'builder/hooks/useI18n'
import { Store } from 'builder/modules/store'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { Icon20 } from 'builder/components/Icon'
import { selectors, DistributionDates } from 'builder/modules/resumeDistribution'
import { TooltipQuestion } from 'builder/components/Tooltip/variations'
import { formatDate } from 'builder/utils/formatDate'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import {
  List,
  ListItem,
  ListItemColumn,
  ListItemHeader,
  ListItemSubText,
} from 'builder/components/List'

import {
  TableContainer,
  TableHeader,
  RecruiterBox,
  RecruiterHeader,
  NextScheduleBox,
  RecruiterSubHeader,
  VerticalLine,
  RecruiterSubContainer,
  RecruiterList,
} from './styles'

interface DashboardBannerProps {
  totalRecruiters: number
  distributionDates: DistributionDates
}

const TRANSLATION = 'builder.auto_apply.resume_distribution.rd_completed.loaded'

export const RecruiterTable = ({ totalRecruiters, distributionDates }: DashboardBannerProps) => {
  const { i18n } = useI18n()
  const { isPhone, isTablet } = useMediaQueries()
  const recruiters = useTypedSelector((state: Store) =>
    selectors.recruiters(state, distributionDates.data[0].unixFormat),
  )

  const getDate = useCallback(() => {
    const { day, month, year } = distributionDates.nextDistribution || {}
    if (day && month && year) {
      return formatDate(new Date(`${month}-${day}-${year}`), 'MMM, do')
    }
  }, [distributionDates])

  return (
    <TableContainer>
      <TableHeader>
        <RecruiterBox>
          <RecruiterHeader>{totalRecruiters}</RecruiterHeader>
          <RecruiterSubContainer>
            <RecruiterSubHeader>
              {i18n.t(`${TRANSLATION}.recruiters.count.text`)}
            </RecruiterSubHeader>
            {!isPhone && (
              <TooltipQuestion textAlign="left" multiline width={300}>
                {i18n.t(`${TRANSLATION}.recruiters.count.tooltip`, { number: totalRecruiters })}
              </TooltipQuestion>
            )}
          </RecruiterSubContainer>
        </RecruiterBox>
        <VerticalLine />
        <NextScheduleBox>
          <RecruiterHeader>{getDate()}</RecruiterHeader>
          <RecruiterSubContainer>
            <RecruiterSubHeader>
              {i18n.t(`${TRANSLATION}.recruiters.next_schedule.text`)}
            </RecruiterSubHeader>
            {!isPhone && (
              <TooltipQuestion textAlign="left" multiline width={300}>
                {i18n.t(`${TRANSLATION}.recruiters.next_schedule.tooltip`, { date: getDate() })}
              </TooltipQuestion>
            )}
          </RecruiterSubContainer>
        </NextScheduleBox>
      </TableHeader>
      <RecruiterList>
        <List>
          <>
            {recruiters?.data
              ?.slice(0, isPhone ? 1 : isTablet ? 2 : 5)
              .map(({ location, ...rest }) => (
                <ListItem key={rest.name}>
                  <>
                    <ListItemColumn
                      columnWidth={2}
                      columnTextMargin="0 10px 0 0"
                      header={<ListItemHeader text={rest.name} />}
                      subText={<ListItemSubText text={rest.jobTitle} />}
                    />
                    {!isPhone && <ListItemColumn columnWidth={0.5} />}
                    {!isPhone && (
                      <ListItemColumn
                        columnWidth={2}
                        header={<ListItemHeader text={rest.company} />}
                        subText={
                          <ListItemSubText
                            text={`${location.city}, ${location.state} ${location.country}`}
                          />
                        }
                      />
                    )}
                    <ListItemColumn
                      columnWidth={0.7}
                      columnTextPosition="end"
                      icon={<Icon20.StatusOffer />}
                    />
                  </>
                </ListItem>
              ))}
          </>
        </List>
      </RecruiterList>
    </TableContainer>
  )
}
