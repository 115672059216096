import styled, { css } from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Sizes from 'builder/styles/sizes'
import Button from 'builder/components/Button'
import Media from 'builder/styles/media'
import { mainDesktopPadding } from '../../styles'

export const Container = styled.div<{ isEditActive: boolean }>(props => {
  return css`
    display: flex;
    width: 100%;
    flex-direction: column;
    border-radius: inherit;
    background-color: ${Colors.White};
    position: relative;
    overflow: hidden;
    justify-content: space-between;
    height: calc(100%);

    ${Media.AboveTablet`
      width: 30%;
      border-radius: ${Sizes['2XS']};
      gap: ${Sizes.M};
      height: ${props.isEditActive ? `calc(100% - ${mainDesktopPadding})` : 'fit-content'};
    `};
  `
})

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${Sizes.S} ${Sizes.S} 0;
  width: 100%;
  justify-content: space-between;

  ${Media.AboveTablet`
    flex-direction: column;
    justify-content: unset;
    align-items: unset;
  `};
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.div`
  color: ${Colors.Neutral90};
  font-weight: 19px;
  line-height: 22px;
  ${FontWeights.Medium};
  margin-bottom: 8px;

  ${Media.AboveTablet`
    ${Typography.S};
  `};
`

export const ActionWrapper = styled.div`
  padding: 0 ${Sizes.S} ${Sizes.S};
  background: ${Colors.White};
`

export const JobTitle = styled.div`
  color: ${Colors.Neutral50};
  ${Typography.Caption};
  ${FontWeights.Regular};
  margin-bottom: 12px;

  ${Media.Phone`
    margin-bottom: 0;
  `};
`

export const ScoreContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${Sizes['2XS']};
`

export const ScoreItemNew = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;
  ${Typography.Tiny};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
  padding: ${Sizes['2XS']};
  background-color: ${Colors.Neutral5};
  height: fit-content;
  border-radius: ${Sizes['2XS']};
`

export const ScoreItemOld = styled.div`
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${Typography.Tiny};
  ${FontWeights.Medium};
  color: ${Colors.Neutral50};

  ${Media.AbovePhone`
    display: flex;
  `};
`

export const ScoreValue = styled.div<{ score: number }>`
  ${Typography.Tiny};
  ${FontWeights.DemiBold};
  color: ${Colors.White};
  background: ${props =>
    props.score <= 30
      ? Colors.Red40
      : props.score > 30 && props.score <= 70
      ? Colors.Amber40
      : Colors.Green40};
  &:after {
    content: '%';
    ${Typography.Tiny};
    ${FontWeights.DemiBold};
  }

  border-radius: 4px;
  padding: 1px 4px;
  align-items: center;
  justify-content: center;
`

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`

export const EditButton = styled(Button)`
  display: flex;
  padding: ${Sizes.XS} ${Sizes.M};
  justify-content: center;
  align-items: center;
  border-radius: ${Sizes['3XS']};
  ${Typography.Body};
  ${FontWeights.Medium};
  background-color: ${Colors.White};
  width: 100%;
  color: ${Colors.Neutral90};
  border: 1px solid ${Colors.Neutral20};

  &:hover,
  &:focus-visible {
    box-shadow: inset 0 0 0 1px ${Colors.Blue50};
    color: ${Colors.Blue50};
    background-color: ${hexToRgba(Colors.Blue50, 0)};
  }

  &:active {
    box-shadow: inset 0 0 0 1px ${Colors.Blue50};
    color: ${Colors.Blue50};
    background-color: ${hexToRgba(Colors.Blue50, 0)};
  }
`

export const SaveButton = styled(Button)`
  display: flex;
  padding: ${Sizes.XS} ${Sizes.M};
  justify-content: center;
  align-items: center;
  border-radius: ${Sizes['3XS']};
  ${Typography.Body};
  ${FontWeights.Medium};
  width: 100%;
`

export const FinishEditButton = styled(Button)`
  display: flex;
  padding: ${Sizes.XS} ${Sizes.M};
  justify-content: center;
  align-items: center;
  border-radius: ${Sizes['3XS']};
  ${Typography.Body};
  ${FontWeights.Medium};
  width: 100%;
`
