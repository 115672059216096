import { memo } from 'react'
import { i18n as I18n } from 'builder/utils/i18n'
import { Icon20 } from 'builder/components/Icon'
import {
  Wrapper,
  Progress,
  ProgressBar,
  ProgressCompletedText,
  ProgressText,
  ProgressTextWrapper,
  SuccessIcon,
} from './styles'

type Props = {
  progress: number
}

export const QuestionnaireProgress = memo((props: Props) => {
  const { progress } = props
  return (
    <Wrapper>
      <ProgressTextWrapper>
        {progress > 0 ? (
          <SuccessIcon data-testid="progress-success" />
        ) : (
          <Icon20.StatusNew data-testid="progress-new" />
        )}
        <ProgressText>
          {progress}%{' '}
          <ProgressCompletedText>
            {I18n.t('builder.ai_resume_draft.completed')}
          </ProgressCompletedText>
        </ProgressText>
      </ProgressTextWrapper>
      <Progress>
        <ProgressBar {...{ progress }} />
      </Progress>
    </Wrapper>
  )
})
