import differenceInHours from 'date-fns/differenceInHours'
import { APP_BASE_PATH } from 'builder/modules/constants'
import { navigate } from 'builder/modules/navigate'

export type LeavingOptions = {
  persistPath?: boolean
}

type StorageValue = {
  path: string
  createdAt: number
}

// Persisted path expiration time
const TTL_HOURS = 24

const LS_KEY = 'APP_AUTH_REDIRECT_PATH'

class AuthRedirectService {
  /**
   * Redirects user inside of the app. The dashboard is going to be open by default
   * if user wasn't redirected from another page before that.
   */
  enterApp(path?: string) {
    const persistedPath = this.getPersistedPath()

    // Clear local storage when visiting the app
    if (persistedPath) this.clearStorage()

    if (path) {
      window.location.href = path
    } else if (persistedPath) {
      window.location.href = persistedPath
    } else {
      navigate('?welcome')
    }
  }

  /**
   * Redirects user to the login page. Saves current page path in the local storage if requested.
   */
  leaveApp(options: LeavingOptions = {}) {
    if (options.persistPath) {
      this.persistPath(window.location.pathname + window.location.search)
    }

    // TODO: full page reload isn't actually needed, we could have
    // used react router navigation here, but at the moment we don't clear up
    // user state in store after sign up, so we make page refresh to avoid state inconsistency.
    window.location.href = `${APP_BASE_PATH}/auth/sign-in`
  }

  persistPath(path: string) {
    const value: StorageValue = { path, createdAt: Date.now() }
    localStorage.setItem(LS_KEY, JSON.stringify(value))
  }

  private getPersistedPath(): string | null {
    try {
      const rawString = localStorage.getItem(LS_KEY)

      if (rawString) {
        const { createdAt, path }: StorageValue = JSON.parse(rawString)
        const isExpired = differenceInHours(createdAt, Date.now()) > TTL_HOURS
        if (!isExpired) return path
      }
    } catch {}

    return null
  }

  private clearStorage() {
    localStorage.removeItem(LS_KEY)
  }
}

export const authRedirectService = new AuthRedirectService()
