import * as Styles from './styles'
import { RadioProps } from './types'

export const Radio = ({ checked, className, onChange = () => {}, ...props }: RadioProps) => {
  const onKeyPressHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onChange(String(props.value))
    }
  }

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value)
  }

  return (
    <div>
      <Styles.HiddenInput checked={checked} type="radio" onChange={onChangeHandler} {...props} />
      <Styles.Radio
        invalid={props.invalid}
        onKeyPress={onKeyPressHandler}
        tabIndex={checked ? -1 : 0}
        className={className}
      >
        <Styles.RadioCheckedCircle />
      </Styles.Radio>
    </div>
  )
}
