import { memo } from 'react'
import { useAppSelector } from 'builder/hooks/useAppSelector'
import { AIResumeLinkedInSteps } from 'builder/modules/constants'
import LinkedInPrefill from './LinkedInPrefill'
import LinkedInProfile from './LinkedInProfile'
import LinkedInError from './LinkedInError'

const LinkedInFlow = () => {
  const { currentStep } = useAppSelector(store => store.resumeEditor.aiResume.linkedInData)

  if (currentStep === AIResumeLinkedInSteps.linkedInPrefill) {
    return <LinkedInPrefill />
  } else if (currentStep === AIResumeLinkedInSteps.linkedInProfile) {
    return <LinkedInProfile />
  } else if (currentStep === AIResumeLinkedInSteps.linkedInError) {
    return <LinkedInError />
  }

  return null
}

export default memo(LinkedInFlow)
