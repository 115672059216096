import { AxiosResponse } from 'axios'
import { all, takeEvery, put, select, delay, call } from 'redux-saga/effects'
import { Resume } from '@rio/types'
import { trackInternalEvent } from '@rio/tracking'
import { actions as initActions } from 'builder/modules/init'
import { selectors as userSelectors } from 'builder/modules/user'
import { createInterceptor, needOnline } from 'builder/modules/interceptors'
import { apiClient, baseClient } from 'builder/modules/apiClient'
import { i18n as I18n } from 'builder/utils/i18n'
import { analyzeResume } from 'builder/components/Tuner'
import { billingPlanPageURL } from 'builder/utils/goToBillingPlanPage'
import { actions, selectors, DOWNLOADED_COVER_LETTER_KEY } from './uiModule'
import { SnackbarTypes } from './types'

function* setCancelableSnackbarOpenSaga(
  action: ReturnType<typeof actions.setCancelableSnackbarOpen>,
) {
  const { text, status } = action.payload
  // shake snackbar if it's already open
  const isOpen: boolean = yield select(selectors.isSnackBarOpen)
  if (isOpen && status) yield put(actions.setSnackbarShaking(true))
  // toggle snackbar
  yield put(actions.setSnackBarOpen({ status, text, type: SnackbarTypes.cancelable }))
}

function* setWarningSnackbarOpenSaga(action: ReturnType<typeof actions.setWarningSnackbarOpen>) {
  const { text, timeout } = action.payload
  yield put(actions.setSnackBarOpen({ status: true, text, type: SnackbarTypes.warning }))
  yield delay(timeout)
  yield put(actions.setSnackBarOpen({ status: false, text, type: SnackbarTypes.warning }))
}

function* setConnectionLostSnackbarOpenSaga(
  action: ReturnType<typeof actions.setConnectionLostSnackbarOpen>,
) {
  if (action.payload) {
    const text = I18n.t(`builder.dashboard.lost_internet_connection`)
    yield put(actions.setCancelableSnackbarOpen({ text, status: true }))
  } else {
    yield put(actions.setCancelableSnackbarOpen({ status: false }))
  }
}

function* changeInternetConnectionStatusSaga(
  action: ReturnType<typeof initActions.setInternetConnectionStatus>,
) {
  yield put(actions.setConnectionLostSnackbarOpen(!action.payload.isOnline))
}

function* openCoverLetterModalSaga(action: ReturnType<typeof actions.openCoverLetterModal>) {
  const serialized = localStorage.getItem(DOWNLOADED_COVER_LETTER_KEY)
  const ids: number[] = serialized ? JSON.parse(serialized) : []
  localStorage.setItem(DOWNLOADED_COVER_LETTER_KEY, JSON.stringify([...ids, action.payload.id]))
}

type OpenSharingModalAction = ReturnType<
  typeof actions.openSharingModal | typeof actions.openSharingTrDocModal
>

function* openSharingModalSaga(
  action: OpenSharingModalAction,
): Generator<object, void, AxiosResponse> {
  const resumeId = action.payload

  yield put(actions.setSharingModalResumeId(resumeId))

  // Fetch resume, analyze it and add tuner score to the event payload
  const { data: resume }: AxiosResponse<Resume> = yield call(apiClient.get, `/resumes/${resumeId}`)
  trackInternalEvent('open_sharing_modal', {
    resume_id: resumeId,
    tuner_score: analyzeResume(resume).score,
  })
}

function* openSharingModalTrDocSaga(
  action: OpenSharingModalAction,
): Generator<object, void, AxiosResponse> {
  const documentId = action.payload

  yield put(actions.setSharingModalTrDocId(documentId))

  // Fetch trDoc, analyze it and add tuner score to the event payload
  const { data: resume }: AxiosResponse<Resume> = yield call(
    baseClient.get,
    `/documents/${documentId}`,
  )
  trackInternalEvent('open_sharing_modal', {
    resume_id: documentId,
    tuner_score: analyzeResume(resume).score,
  })
}

/**
 * TODO: Implement a modal or another UI displaying once it's designed.
 * Temporary: Just open a plans page (as we always do on resume.io).
 */
function* openPaywallSaga() {
  const plansUrl: string = yield select(userSelectors.getUpgradeUrl)
  window.location.href = billingPlanPageURL({ upgradeURL: plansUrl })
}

// Export
export const sagas = function* saga() {
  yield all([
    takeEvery(initActions.setInternetConnectionStatus, changeInternetConnectionStatusSaga),
    takeEvery(actions.setCancelableSnackbarOpen, setCancelableSnackbarOpenSaga),
    takeEvery(actions.setWarningSnackbarOpen, setWarningSnackbarOpenSaga),
    takeEvery(actions.setConnectionLostSnackbarOpen, setConnectionLostSnackbarOpenSaga),
    // Specify `takeEvery` overload type manually, because TS chooses the wrong one
    // @ts-expect-error TODO fix TS error
    takeEvery(actions.openSharingModal, createInterceptor([needOnline]), openSharingModalSaga),
    takeEvery(
      // @ts-expect-error TODO fix TS error
      actions.openSharingTrDocModal,
      // @ts-expect-error TODO fix TS error
      createInterceptor([needOnline]),
      openSharingModalTrDocSaga,
    ),
    takeEvery(actions.openCoverLetterModal, openCoverLetterModalSaga),
    takeEvery(actions.openPaywall, openPaywallSaga),
  ])
}
