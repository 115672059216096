import PropTypes from 'prop-types'
import {
  Wizard,
  WizardContextProvider,
  WizardHeader,
  WizardFooter,
} from 'builder/components/Wizard'

import loaderImage from 'images/builder/resume-editor/editor-loader.svg'

import { EditorPanel, EditorScroll, EditorContent, LoaderContainer, Loader } from './styles'

const ResumeEditorStepByStep = ({ editorState, resumeState }) => {
  const { resume, isUploadingPhoto } = resumeState
  const { resumeTemplates, levels } = editorState

  if (!resume || resumeTemplates.length === 0) {
    return (
      <EditorPanel>
        <LoaderContainer>
          <Loader src={loaderImage} />
        </LoaderContainer>
      </EditorPanel>
    )
  }

  return (
    <EditorPanel>
      <EditorScroll>
        <EditorContent>
          <WizardContextProvider>
            <WizardHeader />
            <Wizard
              resume={resume}
              levels={levels}
              templates={resumeTemplates}
              isUploadingPhoto={isUploadingPhoto}
            />
            <WizardFooter />
          </WizardContextProvider>
        </EditorContent>
      </EditorScroll>
    </EditorPanel>
  )
}

ResumeEditorStepByStep.propTypes = {
  editorState: PropTypes.object.isRequired,
  resumeState: PropTypes.object.isRequired,
  updateSimpleField: PropTypes.func.isRequired,
}

export default ResumeEditorStepByStep
