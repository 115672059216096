import { FieldNote, FieldNoteProps } from 'builder/components/FieldNote'
import * as Styled from './styles'

type Props = React.HTMLAttributes<HTMLDivElement> & FieldNoteProps & { label?: string }

export const SelectLayout = ({ label, error, warning, description, children, ...rest }: Props) => {
  return (
    <div {...rest}>
      {label && <Styled.Label>{label}</Styled.Label>}

      <Styled.Wrapper>{children}</Styled.Wrapper>

      <FieldNote error={error} warning={warning} description={description} />
    </div>
  )
}
