import { useCallback } from 'react'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent, trackMarketingEvent } from '@rio/tracking'
import { UserDocumentType } from '@rio/types'
import { actions, selectors, typeToName } from 'builder/modules/panel'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { formatDocumentPreviewUrl } from 'builder/utils/formatDocumentPreviewUrl'
import { i18n as I18n } from 'builder/utils/i18n'
import {
  DocumentPopup,
  NewDocumentContainer,
  DocumentOptionsContainer,
  DocumentOptions,
  OptionPreview,
  OptionRight,
  OptionTitle,
  OptionContent,
  DocumentOptionsGradient,
} from './styles'

type Ref = React.ForwardedRef<HTMLDivElement>
interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onClick'> {
  type: UserDocumentType
}

export const CreateDocumentPopup = React.forwardRef(({ type }: Props, ref: Ref) => {
  const dispatch = useDispatch()
  const data = useTypedSelector(selectors.documents)
  const id = data[0].id
  const renderingToken = data[0].renderingToken
  const version = data[0].version
  const previewUrl = formatDocumentPreviewUrl({ renderingToken, version })

  const ResumeOptions = [
    {
      type: 'newDocument',
      title: I18n.t('builder.resume_optimizer.create_resume.title'),
      content: I18n.t('builder.resume_optimizer.create_resume.content'),
    },
    {
      type: 'duplicateDocument',
      title: I18n.t('builder.resume_optimizer.dupicate_resume.title'),
      content: I18n.t('builder.resume_optimizer.dupicate_resume.content'),
    },
  ]

  const handleCreate = useCallback(
    (optionType: string) => {
      if (optionType === 'newDocument') {
        dispatch(actions.createDocument({ type }))
        trackMarketingEvent('Dashboard', `Create ${typeToName(type)}`)
        trackInternalEvent(`create_${type}`)
        trackInternalEvent('click_create_new_resume')
      } else {
        // use the id from the selected document in the Redux store state
        if (id !== null) {
          dispatch(actions.copyDocument({ id, type }))
        }
        trackInternalEvent(`duplicate_${type}`)
        trackInternalEvent('click_duplicate_existing')
      }
    },
    [dispatch, id, type],
  )

  return (
    <>
      <DocumentPopup ref={ref}>
        <NewDocumentContainer>
          {ResumeOptions.map(option => (
            <DocumentOptionsContainer key={option.type} onClick={() => handleCreate(option.type)}>
              <DocumentOptions>
                <OptionPreview src={option.type === 'newDocument' ? '' : previewUrl} />
                <OptionRight>
                  <OptionTitle>{option.title}</OptionTitle>
                  <OptionContent>{option.content}</OptionContent>
                </OptionRight>
              </DocumentOptions>
              <DocumentOptionsGradient />
            </DocumentOptionsContainer>
          ))}
        </NewDocumentContainer>
      </DocumentPopup>
    </>
  )
})
