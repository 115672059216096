import { useI18n } from 'builder/hooks/useI18n'
import * as Styled from './styles'

interface Props {
  color?: string
}
export const NewTag = ({ color }: Props) => {
  const { i18n } = useI18n()
  return (
    <Styled.NewTag color={color}>{i18n.t('builder.dashboard.service_data.tags.new')}</Styled.NewTag>
  )
}
