import { Component } from 'react'
import PropTypes from 'prop-types'
import { trackInternalEvent } from '@rio/tracking'
import { connect } from 'react-redux'
import { actions } from 'builder/modules/signUp'
import { i18n as I18n } from 'builder/utils/i18n'
import { bindActionToPromise } from 'builder/utils/bindActionToPromise'
import { withMediaQueries } from 'builder/components/MediaQueries'
import * as SimpleForm from 'builder/components/SimpleForm'
import { TextField } from 'builder/components/TextField'
import Checkbox from 'builder/components/Checkbox'
import { Icon20 } from 'builder/components/Icon'
import { FetchStatuses } from 'builder/modules/constants'

import {
  Title,
  Subtitle,
  FieldSuggestion,
  FieldSuggestionText,
  FieldSuggestionLink,
  WarningContainer,
  WarningIcon,
  WarningText,
} from './styles'
import { Container, Content, Wrapper } from '../styles'

class ContactInfo extends Component {
  static propTypes = {
    onBack: PropTypes.func.isRequired,
    onContinue: PropTypes.func.isRequired,
    emailErrorCode: PropTypes.string,
    setEmailErrorCode: PropTypes.func,
    persistSignUpInfo: PropTypes.func,
    email: PropTypes.string,
    confirmEmail: PropTypes.bool,
    mediaQueries: PropTypes.object.isRequired,
    setSuggestedEmail: PropTypes.func,
    suggestedEmail: PropTypes.string,
    validateEmail: PropTypes.func,
    isEmailSuggested: PropTypes.bool,
    isEmailValidating: PropTypes.bool,
    signUpProcess: PropTypes.string,
    documentType: PropTypes.string,
    verifyMarketingConsentUserRegion: PropTypes.func,
    isThankYouPageVisible: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    fieldsContent: {},
  }

  state = {
    emailEmptyError: false,
    show: false,
    showThankYouBlock: false,
  }

  componentDidMount() {
    // Track step opening
    trackInternalEvent('visit_email_sign_up_step')

    // Verify check consent country
    this.props.verifyMarketingConsentUserRegion({ abTestingStep: 'email_step' })

    trackInternalEvent('see_marketing_consent', {
      website_host: window.location.host,
      device: this.props.mediaQueries.isPhone ? 'mobile' : 'desktop',
      step: 'emailstep',
    })

    // Hack to fix animation bug. See https://github.com/reactjs/react-transition-group/issues/10
    requestAnimationFrame(() => {
      this.setState({ show: true })
    })
  }

  get emailError() {
    const { emailErrorCode } = this.props
    if (this.state.emailEmptyError) {
      return I18n.t('builder.sign_up.field_is_required')
    }

    if (emailErrorCode) {
      const logInLink = `<a href='/app/auth/sign-in'>${I18n.t('builder.sign_up.log_in')}</a>`

      return emailErrorCode === 'TAKEN'
        ? I18n.t('builder.sign_up.email_is_taken', { logInLink })
        : I18n.t('builder.sign_up.invalid_email')
    }

    return ''
  }

  get warningText() {
    const dividedSuggestion = this.props.suggestedEmail.split('@')
    const emailLogin = `${dividedSuggestion[0]}@`
    const emailDomain = dividedSuggestion[1]

    return (
      <FieldSuggestion onClick={this.handleSuggestionClick}>
        <FieldSuggestionText>{`${I18n.t('builder.sign_up.did_you_mean')}:`}</FieldSuggestionText>
        <FieldSuggestionLink>
          {emailLogin}
          <b>{emailDomain}</b>
        </FieldSuggestionLink>
      </FieldSuggestion>
    )
  }

  handleFieldChange = e => {
    this.props.persistSignUpInfo({
      type: this.props.documentType,
      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    })

    if (e.target.name === 'email') {
      this.setState({ emailEmptyError: false })
      this.props.setEmailErrorCode('')
      this.props.setSuggestedEmail('')
    }
  }

  handleContinueClick = () => {
    if (this.props.isThankYouPageVisible && !this.state.showThankYouBlock) {
      this.setState({ showThankYouBlock: true })
      trackInternalEvent('saw_thankyou_signup')
      return
    }

    trackInternalEvent('submit_sign_up_email')

    const { email } = this.props
    if (!email) {
      trackInternalEvent('see_sign_up_email_error', { error: 'empty_email' })
      this.setState({ emailEmptyError: true })
      return
    }

    if (this.props.isThankYouPageVisible && this.props.confirmEmail) {
      // Track marketing consent submission event
      trackInternalEvent('submit_marketing_consent', {
        website_host: window.location.host,
        device: this.props.mediaQueries.isPhone ? 'mobile' : 'desktop',
        step: 'emailstep',
      })
    }

    return this.props.validateEmail({ email }).then(() => {
      trackInternalEvent('send_sign_up_email')
      this.props.onContinue()
    })
  }

  handleSuggestionClick = () => {
    this.props.persistSignUpInfo({
      type: this.props.documentType,
      email: this.props.suggestedEmail,
    })
    this.props.setSuggestedEmail('')

    trackInternalEvent('take_suggested_sign_up_email')
  }

  render() {
    const {
      onBack,
      email,
      confirmEmail,
      mediaQueries,
      suggestedEmail,
      isEmailSuggested,
      isEmailValidating,
      signUpProcess,
      isThankYouPageVisible,
    } = this.props

    const warningMarkup = suggestedEmail ? this.warningText : ''

    return (
      <Wrapper>
        <Container>
          <Title>
            {this.state.showThankYouBlock
              ? I18n.t('builder.sign_up.thank_you_title')
              : I18n.t('builder.sign_up.contact_info_title')}
          </Title>
          <Subtitle>
            {this.state.showThankYouBlock
              ? I18n.t('builder.sign_up.thank_you_subtitle')
              : I18n.t('builder.sign_up.contact_info_subtitle')}
          </Subtitle>

          {this.state.show && (
            <Content>
              <SimpleForm.Form
                onSubmit={this.handleContinueClick}
                onBackClick={onBack}
                submitButtonText={
                  isEmailSuggested
                    ? I18n.t('builder.sign_up.confirm')
                    : I18n.t('builder.sign_up.continue')
                }
                isPending={isEmailValidating || signUpProcess === FetchStatuses.loading}
              >
                {!this.state.showThankYouBlock && (
                  <>
                    <SimpleForm.Row
                      style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}
                    >
                      <TextField
                        name="email"
                        label={I18n.t('builder.sign_up.email')}
                        value={email}
                        onChange={this.handleFieldChange}
                        error={this.emailError}
                        warning={warningMarkup}
                        autoFocus={!mediaQueries.isPhone}
                      />
                      <Checkbox
                        label={I18n.t('builder.sign_up.consent_checkbox_label')}
                        checked={confirmEmail}
                        onChange={this.handleFieldChange}
                        name="confirm_email"
                      />
                    </SimpleForm.Row>
                  </>
                )}
                {!this.state.showThankYouBlock && suggestedEmail && (
                  <WarningContainer>
                    <WarningIcon>
                      <Icon20.WarningCircle />
                    </WarningIcon>

                    <WarningText>{I18n.t('builder.sign_up.you_made_typo')}</WarningText>
                  </WarningContainer>
                )}
                {isThankYouPageVisible && this.state.showThankYouBlock && (
                  <>
                    <SimpleForm.Row>
                      <Checkbox
                        label={I18n.t('builder.sign_up.consent_checkbox_label')}
                        checked={confirmEmail}
                        onChange={this.handleFieldChange}
                        name="confirm_email"
                      />
                    </SimpleForm.Row>
                  </>
                )}
              </SimpleForm.Form>
            </Content>
          )}
        </Container>
      </Wrapper>
    )
  }
}

// ---
// Connect to data
// ---

const mapStateToProps = state => ({
  emailErrorCode: state.signUp.emailErrorCode,
  email: state.signUp.signUpInfo.email,
  confirmEmail: state.signUp.signUpInfo.confirm_email,
  suggestedEmail: state.signUp.suggestedEmail,
  isEmailSuggested: state.signUp.isEmailSuggested,
  isEmailValidating: state.signUp.isEmailValidating,
  signUpProcess: state.signUp.signUpProcess,
  isThankYouPageVisible: state.signUp.isThankYouPageVisible,
})

const mapDispatchToProps = function (dispatch) {
  return {
    validateEmail: bindActionToPromise(dispatch, actions.validateEmail),
    setEmailErrorCode: code => dispatch(actions.setEmailErrorCode(code)),
    persistSignUpInfo: data => dispatch(actions.persistSignUpInfo(data)),
    setSuggestedEmail: email => dispatch(actions.setSuggestedEmail(email)),
    verifyMarketingConsentUserRegion: abTestingStep =>
      dispatch(actions.emailMarketingConsent(abTestingStep)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withMediaQueries(ContactInfo))
