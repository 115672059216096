import { trackInternalEvent } from '@rio/tracking'
import { Accomplishment, label } from 'builder/modules/sevenStories'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import AddAccomplishments from '../AddAccomplishments'
import Accomplishments from '../Accomplishments'

type Props = {
  maxAccomplishmentsLength: number
  handleAddAccomplishment: () => void
  accomplishmentData: Accomplishment[]
  getPlaceHolder: (index: number) => string
  handleAccomplishmentChange: (value: string, index: number) => void
}

const AccomplishmentStepOne = ({
  getPlaceHolder,
  accomplishmentData,
  handleAddAccomplishment,
  maxAccomplishmentsLength,
  handleAccomplishmentChange,
}: Props) => {
  // eslint-disable-next-line prettier/prettier

  useEffectOnMount(() => {
    trackInternalEvent('enter_assessment_page', {
      assessment_name: 'Seven Stories',
      ...label,
    })
  })

  return (
    <>
      <Accomplishments {...{ getPlaceHolder, accomplishmentData, handleAccomplishmentChange }} />
      <AddAccomplishments
        {...{ accomplishmentData, handleAddAccomplishment, maxAccomplishmentsLength }}
      />
    </>
  )
}

export default AccomplishmentStepOne
