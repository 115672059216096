import { ReactElement } from 'react'
import { Navigate } from 'react-router-dom'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { AppConfig } from 'builder/modules/init'

interface IProps {
  element: ReactElement
  feature: keyof AppConfig['features']
}

export const FeatureWrapper = ({ element, feature }: IProps) => {
  const { features } = useFeaturesConfig()
  return features[feature] ? element : <Navigate to="/" />
}
