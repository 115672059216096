import styled from 'styled-components'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;

  ${Media.Tablet`
    grid-template-columns: 1fr 1fr;
  `}

  ${Media.Phone`
    grid-template-columns: 1fr;
  `}
`

export const Package = styled.div`
  background: ${Colors.White};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 601px;
  margin: 0 auto;
  padding: 48px 32px 32px 32px;
  border-radius: 6px;
  gap: 10px;
  position: relative;

  ${Media.Phone`
    height: auto;
  `}
`

export const Sections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const Badge = styled.div`
  ${FontWeights.DemiBold};
  ${Typography.Caption};
  background: ${Colors.Indigo20};
  width: 112px;
  height: 24px;
  border-radius: 2px;
  position: absolute;
  color: ${Colors.Indigo70};
  padding: 4px 6px 4px 6px;
  text-align: center;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
`

export const Divider = styled.div`
  width: 288px;
  height: 1px;
  background: ${Colors.Neutral15};
`
