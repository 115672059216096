import styled, { css } from 'styled-components'
import Button, { ButtonSize } from 'builder/components/Button'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Container = styled.div`
  display: flex;
  gap: 13px;
  align-items: center;

  ${Media.Tablet`
    gap: 0;
    justify-content: space-around;
  `};
`

export const ImageContainer = styled.div<{ position: 'left' | 'right' }>`
  height: 304px;
  width: 340px;

  ${Media.Tablet`
    position: absolute;
    overflow: hidden;
  `};

  ${Media.Phone`
    position: absolute;
    overflow: hidden;
  `};

  ${({ position }) =>
    position === 'left'
      ? css`
          left: 0;
        `
      : css`
          right: 0;
        `}
`

export const Image = styled.img<{ flip?: boolean; position: 'left' | 'right' }>`
  height: 304px;
  width: 340px;

  ${Media.Tablet`
    position: absolute;
    height: 228px;
    width: 254px;
  `};

  ${Media.Phone`
    position: absolute;
    width: 217px;
    height: 195px;
  `};

  ${({ flip }) =>
    flip &&
    css`
      transform: scaleX(-1);
    `}

  ${({ position }) =>
    position === 'left'
      ? css`
          ${Media.Tablet`
            left: -22px;
          `};

          ${Media.Phone`
            top: -10px;
            left: -60px;
          `};
        `
      : css`
          ${Media.Tablet`
            right: -100px;
          `};

          ${Media.Phone`
            top: -10px;
            right: -60px;
          `};
        `}
`

export const Box = styled.div`
  padding: 13px;
  min-width: 420px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;

  ${Media.Tablet`
    max-width: 420px;
  `};

  ${Media.Phone`
    min-width: 300px;
  `};
`

export const SuccessImage = styled.img`
  width: 196px;
  height: 180px;
  margin-bottom: 4px;
`

export const Header = styled.div`
  color: ${Colors.Neutral90};
  ${Typography.Subhead};
  ${FontWeights.Medium};
  text-align: center;
`

export const SubHeader = styled.div`
  color: ${Colors.Neutral90};
  ${Typography.Body};
  ${FontWeights.Regular};
  text-align: center;
`

export const ActionButton = styled(Button).attrs({ size: ButtonSize.small })`
  margin-top: 8px;
`
