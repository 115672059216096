import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { CSSObjectWithLabel, StylesConfig, components, MultiValueRemoveProps } from 'react-select'
import { trackingInfo } from 'builder/modules/careerCoaching/constants'

import { Icon20 } from 'builder/components/Icon'
import { apiClient } from 'builder/modules/apiClient'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { SuggestedIndustry, selectors, actions } from 'builder/modules/careerCoaching'

import { StyledMultiSelect, NoOptionMessage } from './styles'

interface MultiSelectProps {
  onChange: (selected: SuggestedIndustry[]) => void
}

const MultiSelect = ({ onChange }: MultiSelectProps) => {
  const dispatch = useDispatch()
  const targetedIndustries = useTypedSelector(selectors.targetedIndustries)

  const MultiValueRemove = (props: MultiValueRemoveProps) => {
    return (
      <div>
        <components.MultiValueRemove {...props}>
          <Icon20.Close />
        </components.MultiValueRemove>
      </div>
    )
  }

  // on focus add styles to container
  const customStyles: StylesConfig = {
    container: (provided: CSSObjectWithLabel) => {
      return {
        ...provided,
      }
    },
  }

  const trackSelectEvent = useCallback((label: string) => {
    trackInternalEvent('select_industry', { ...trackingInfo, industry_name: label })
  }, [])

  const trackDeleteEvent = useCallback((label: string) => {
    trackInternalEvent('delete_industry', { ...trackingInfo, industry_name: label })
  }, [])

  const handleChange = useCallback(
    (newValues: SuggestedIndustry[] | [], actionMeta) => {
      // add skills to select
      dispatch(actions.setTargetedIndustries(newValues))

      if (actionMeta.action === 'select-option') {
        trackSelectEvent(actionMeta.option.label)
      } else {
        trackDeleteEvent(actionMeta.removedValue.label)
      }
      onChange(newValues)
    },
    [dispatch, trackSelectEvent, trackDeleteEvent, onChange],
  )
  // API call to get remote data
  const promiseOptions = useCallback(async (inputValue: string) => {
    const { data } = await apiClient.get(`/coaching/career-industries?query=${inputValue}&limit=10`)

    const options = data?.careerIndustries.map((industry: { id: number; name: string }) => ({
      value: industry.id,
      label: industry.name,
    }))

    return options
  }, [])

  const customNoOptionsMessage = () => {
    return <NoOptionMessage>No options</NoOptionMessage>
  }

  return (
    <StyledMultiSelect
      components={{ MultiValueRemove }}
      isMulti
      autoFocus
      noOptionsMessage={customNoOptionsMessage}
      isSearchable
      name="industries"
      value={targetedIndustries}
      styles={customStyles}
      classNamePrefix="select"
      openMenuOnClick={false}
      loadOptions={promiseOptions}
      placeholder="Add your targeted industries"
      onChange={(newValue, actionMeta) =>
        Array.isArray(newValue) && handleChange(newValue, actionMeta)
      }
    />
  )
}

export default MultiSelect
