import Colors from 'builder/styles/colors'
import { Circle, PercentageText, ProgressText, ScoreText } from './styles'

interface Props {
  score: number
}

const TabletProgressBar = ({ score }: Props) => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="32" cy="32" r="30.5" stroke={Colors.Blue20} strokeWidth="3" fill="none" />
      <ProgressText x="35" y="32" textAnchor="middle" dominantBaseline="central">
        <ScoreText>{score}</ScoreText>
        <PercentageText dy="3" dx="2">
          %
        </PercentageText>
      </ProgressText>
      <Circle
        d="M32 1.5C37.3539 1.5 42.6134 2.90929 47.25 5.58623C51.8866 8.26316 55.7368 12.1134 58.4138 16.75C61.0907 21.3866 62.5 26.6461 62.5 32C62.5 37.3539 61.0907 42.6134 58.4138 47.25"
        stroke={Colors.Blue50}
        strokeWidth="3"
        fill="none"
        score={score}
        cx="32"
        cy="32"
        r="30.5"
        transform="rotate(-90 32 32)"
        circleCir={Math.PI * 2 * 30.5}
      />
    </svg>
  )
}

export default TabletProgressBar
