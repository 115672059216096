import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Sizes from 'builder/styles/sizes'
import { Icon20 } from 'builder/components/Icon'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Sizes.L};
  border-radius: ${Sizes.S};
  background-color: ${Colors.White};
  padding: ${Sizes.L};
  position: relative;
  width: 544px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Sizes.XS};
`
export const Title = styled.p`
  color: ${Colors.Neutral90};
  ${Typography.S};
  ${FontWeights.DemiBold};
`
export const SubTitle = styled.div`
  color: ${Colors.Neutral50};
  ${Typography.Body};
  ${FontWeights.Regular};
`

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${Sizes['2XS']};
  width: 100%;
  justify-content: flex-end;
`

export const CloseIconWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`

export const CloseIcon = styled(Icon20.Close)`
  color: ${Colors.Neutral90};
  cursor: pointer;
`
