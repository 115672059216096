import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import { Icon20, Icon24 } from 'builder/components/Icon'

export const Label = styled.div<{ isVisible?: boolean }>`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${props => (props.isVisible ? Colors.Neutral40 : Colors.Neutral80)};
  text-decoration: ${props => props.isVisible && 'line-through'};

  ${Media.Phone`
  ${Typography.Caption};
  `}
`

export const ButtonArrow = styled(Icon24.Chevron)<{ isVisible?: boolean }>`
  transform: ${props => (props.isVisible ? 'rotate(-90deg)' : 'rotate(90deg)')};
  color: ${Colors.Neutral30};
  position: absolute;
  right: 0;
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: var(--2XS, 8px);
  position: relative;
  align-self: stretch;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;

  ${Media.Phone`
padding: 8px 0px;
  `}
`

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
`

export const DetailsDone = styled(Icon20.StatusDone)`
  color: ${Colors.Green50};
`

export const StatusStarted = styled(Icon20.StatusStarted)`
  color: ${Colors.Neutral30};
`
