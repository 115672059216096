import { useLocation } from 'react-router-dom'
import { CareerProfileEditorUrlParams } from '../types'

export const useCareerProfileEditorUrlParams = () => {
  const location = useLocation()
  const params = new URLSearchParams({
    [CareerProfileEditorUrlParams.returnUrl]: location.pathname,
  })

  return params
}
