import { API_BASE_URL, APP_BASE_PATH, BASE_URL } from 'builder/modules/constants'

export const baseConfig = {
  baseURL: API_BASE_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
}

export const baseApiConfig = {
  baseURL: BASE_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
}

export const baseAppConfig = {
  baseURL: APP_BASE_PATH,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
}
