import Spinner from 'builder/components/Spinner'
import * as Styles from './styles'

export const StatsSkeleton = () => {
  return (
    <Styles.BannerContainer>
      <Styles.Box />
      <Styles.Box />
    </Styles.BannerContainer>
  )
}

export const JobListSkeleton = () => {
  return (
    <Styles.ListLoader>
      <Spinner />
    </Styles.ListLoader>
  )
}

export const ActionsSkeleton = () => {
  return <Styles.ActionContainer />
}
