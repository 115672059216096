import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { Optional } from 'packages/types'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import ModalOverlay from 'builder/components/ModalOverlay'
import Button, { ButtonTheme } from 'builder/components/Button'
import { Icon24 } from 'builder/components/Icon'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { FetchStatuses } from 'builder/modules/constants'
import { actions, selectors } from 'builder/modules/user'
import { i18n as I18n } from 'builder/utils/i18n'
import { InternalEvents, ReasonType } from '../../common/types'
import { Container, Close, Visual, Content, Title, Text, ButtonContainer } from './styles'

interface PauseModalProps {
  period?: 'week' | 'month'
  cancellationReason: Optional<string>
  onClose: () => void
}

export const PauseModal = ({ period, cancellationReason, onClose }: PauseModalProps) => {
  const { isPhone } = useMediaQueries()
  const dispatch = useDispatch()
  const [isPauseButtonLoading, setIsPauseButtonLoading] = useState<boolean>(false)
  const [isCancelButtonLoading, setIsCancelButtonLoading] = useState<boolean>(false)
  const pauseSubscriptionStatus = useTypedSelector(selectors.pauseSubscriptionStatus)
  const cancelSubscriptionStatus = useTypedSelector(selectors.cancellationStatus)

  // Set pause button status to loading based on pause subscription status
  useEffect(() => {
    setIsPauseButtonLoading(pauseSubscriptionStatus === FetchStatuses.loading)
  }, [pauseSubscriptionStatus])

  // Set cancel button status to loading based on cancel subscription status
  useEffect(() => {
    setIsCancelButtonLoading(cancelSubscriptionStatus === FetchStatuses.loading)
  }, [cancelSubscriptionStatus])

  useEffect(() => {
    trackInternalEvent(InternalEvents.viewPauseModal, { period_type: period })
  }, [period])

  const handleClose = useCallback(() => {
    onClose()
    trackInternalEvent(InternalEvents.closePauseModal, { period_type: period })
  }, [onClose, period])

  const handlePauseSubscription = useCallback(() => {
    trackInternalEvent(InternalEvents.pause, {
      period,
    })
    dispatch(actions.pauseUserSubscription())
  }, [dispatch, period])

  const handleCancelSubscription = useCallback(() => {
    dispatch(
      actions.cancelUserSubscription({
        [ReasonType.cancel]: cancellationReason ?? '',
      }),
    )
  }, [dispatch, cancellationReason])

  return (
    <ModalOverlay
      fullScreen={isPhone}
      overlayFadeDuration={250}
      contentSlideDuration={0}
      onClick={handleClose}
    >
      <Container>
        <Visual />
        <Content>
          <Title>
            {I18n.t('builder.subscription_cancellation.pause_modal.free_period', {
              period: period ?? 'week',
            })}
          </Title>
          <Text>
            <p>
              {I18n.t('builder.subscription_cancellation.pause_modal.description_line_one', {
                period: period ?? 'week',
              })}
            </p>
            <p>
              {I18n.t('builder.subscription_cancellation.pause_modal.description_line_two', {
                period: period === 'month' ? 'these 30 days' : 'this week',
              })}
            </p>
            <p>{I18n.t('builder.subscription_cancellation.pause_modal.description_line_three')}</p>
          </Text>
        </Content>
        <ButtonContainer>
          <Button
            isLoading={isCancelButtonLoading}
            theme={ButtonTheme.ghost}
            onClick={handleCancelSubscription}
          >
            {I18n.t('builder.subscription_cancellation.pause_modal.cancel_now')}
          </Button>
          <Button isLoading={isPauseButtonLoading} onClick={handlePauseSubscription}>
            {period === 'month'
              ? I18n.t('builder.subscription_cancellation.pause_modal.free_month')
              : I18n.t('builder.subscription_cancellation.pause_modal.free_week')}
          </Button>
        </ButtonContainer>
        <Close onClick={handleClose}>
          <Icon24.Close />
        </Close>
      </Container>
    </ModalOverlay>
  )
}
