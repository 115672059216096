import { trackInternalEvent } from '@rio/tracking'
import { Course as CourseType } from 'builder/modules/careerPath2'
import { Card } from './styles'
import { Body } from './Components/Body'
import { Header } from './Components/Header'
import { Footer } from './Components/Footer'

interface Props {
  jobTitle: string
  eventLabel: string
  course: CourseType
  isSwiperEnabled?: boolean
}

export const CourseCard = ({ course, jobTitle, eventLabel, isSwiperEnabled }: Props) => {
  const onUrlOpen = () => {
    // Open course url in new window
    window.open(course?.url)

    trackInternalEvent('click_course_card', {
      label: eventLabel,
      job_title: jobTitle,
      course_name: course.title,
    })
  }

  return (
    <Card onClick={onUrlOpen} {...{ isSwiperEnabled }}>
      <Header {...{ course }} />
      <Body {...{ course }} />
      <Footer {...{ course }} />
    </Card>
  )
}
