import { useMemo } from 'react'
import styled, { css } from 'styled-components'
import {
  Cards,
  PromotionTopic,
} from 'builder/components/SubscriptionCancellationFormNew/common/types'
import Media from 'builder/styles/media'

interface Props {
  isACIORegisteredUser: boolean
  topic: PromotionTopic
}

export const PromotionImage = styled.div<Props>(({ isACIORegisteredUser, topic }) => {
  const imageStyles = useMemo(() => {
    switch (topic) {
      case Cards.JobOffer:
        return css`
          width: 220px;
          height: 184px;

          ${Media.Tablet`
              width: ${isACIORegisteredUser ? '196px' : '138px'};
              height: ${isACIORegisteredUser ? '164px' : '116px'};
          `}

          ${Media.Phone`
              width: ${isACIORegisteredUser ? '191px' : '118px'};
              height: ${isACIORegisteredUser ? '160px' : '100px'};
          `}
        `
      case Cards.Discount:
        return css`
          width: 276px;
          height: 192px;

          ${Media.Tablet`
              width: 272px;
              height: 188px;
          `}

          ${Media.Phone`
              width: 258px;
              height: 180px;
          `}
        `
      case Cards.DreamJob:
        return css`
          width: 292px;
          height: 204px;

          ${Media.Tablet`
              width: ${isACIORegisteredUser ? '230px' : '160px'};
              height: ${isACIORegisteredUser ? '160px' : '112px'};
          `}

          ${Media.Phone`
              width: ${isACIORegisteredUser ? '224px' : '138px'};
              height: ${isACIORegisteredUser ? '156px' : '96px'};
          `}
        `
      default:
        return ''
    }
  }, [isACIORegisteredUser, topic])

  return css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 384px;

    ${Media.Tablet`
      height: 220px;
      width: 100%;
    `}

    ${Media.Phone`
      height: 196px;
      width: 100%;
    `}

    & img {
      ${Media.Tablet`
        margin-top: 32px;
      `}
      ${Media.Phone`
        margin-top: 20px;
      `}
      ${imageStyles}
    }
  `
})
