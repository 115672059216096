import { useState } from 'react'
import { Tabs } from 'builder/components/Tabs'
import { Container, StyledTab, TabsContainer, Heading, SessionContainer } from './styles'
import Previous from './components/Previous'
import Upcoming from './components/Upcoming'

interface CareerPostPurchaseSessionsProps {
  onShowMoreSessions: () => void
  isMorePreviousSessions: boolean
}

const CareerPostPurchaseSessions = ({
  onShowMoreSessions,
  isMorePreviousSessions,
}: CareerPostPurchaseSessionsProps): JSX.Element => {
  const TABS = ['Upcoming', 'Previous']
  const [selectedTab, setSelectedTab] = useState<string>(TABS[0])

  const handleChangeTab = (tab: string) => {
    setSelectedTab(tab)
  }

  return (
    <Container>
      <Heading>Sessions</Heading>
      <TabsContainer>
        <Tabs>
          {TABS.map(tab => (
            <StyledTab
              key={tab}
              isActive={selectedTab === tab}
              onClick={() => handleChangeTab(tab)}
            >
              {tab}
            </StyledTab>
          ))}
        </Tabs>
      </TabsContainer>

      <SessionContainer>
        {selectedTab === TABS[0] ? (
          <Upcoming />
        ) : (
          <Previous {...{ onShowMoreSessions, isMorePreviousSessions }} />
        )}
      </SessionContainer>
    </Container>
  )
}

export default CareerPostPurchaseSessions
