import styled, { css } from 'styled-components'
import { Optional } from 'packages/types'
import Media from 'builder/styles/media'
import {
  Cards,
  PromotionTopic,
} from 'builder/components/SubscriptionCancellationFormNew/common/types'

interface Props {
  topic: PromotionTopic
  activeCard: Optional<Cards>
}

export const Card = styled.div<Props>(({ activeCard, topic }) => {
  const imageFilter = activeCard && activeCard !== topic ? 'grayscale(1)' : ''

  return css`
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & img {
      filter: ${imageFilter};
      }
    }

    ${Media.Tablet`
      flex-direction: row;
      margin: 0px !important;
      justify-content: unset;
      width: 538px !important;
      height: 85px !important;
    `}

    ${Media.Phone`
      flex-direction: row;
      justify-content: unset;
      margin: 0px !important;
      width: 333px !important;
      height: 79px !important;
    `};
  `
})
