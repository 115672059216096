import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  EWidgetsName,
  IHelperWidget,
  IWidgetsStatus,
  selectors,
  actions,
  IWidgetsInfo,
} from 'builder/modules/panel'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { JobSearchHelper } from 'builder/components/JobSearchHelper'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import CircularProgressBar from './CircularProgressBar'
import * as Styles from './styles'
import { HelpModalLabelPayload } from './types'
import { useHelpScout } from './hooks/useHelpScout'
import { useIsScrolled } from './hooks/useIsScrolled'

interface IProps {
  resumeEditor?: boolean
  currentWidgetName: EWidgetsName
  isHelperModalOpen: boolean
  setIsHelperModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setModalAsCentre: React.Dispatch<React.SetStateAction<boolean>>
  modalAsCentre: boolean
  labelPayload: HelpModalLabelPayload
  onStepActionClick: (step_name: string) => void
  handleClose: () => void
  widgetFetchCondition?: boolean
  TrackingEvents: (option: IWidgetsInfo) => void
  helperLabel: string
}

const FloatingCIOHelperButton = ({
  resumeEditor,
  currentWidgetName,
  isHelperModalOpen,
  setIsHelperModalOpen,
  modalAsCentre,
  setModalAsCentre,
  labelPayload,
  onStepActionClick,
  handleClose,
  widgetFetchCondition,
  TrackingEvents,
  helperLabel,
}: IProps) => {
  const dispatch = useDispatch()
  const { isMidDesktop, isLaptop } = useMediaQueries()

  const helperWidgetsData = useTypedSelector(selectors.helperWidgets)

  const [isHovering, setIsHovering] = useState<boolean>(false)
  const { isHelpScoutPresent } = useHelpScout(resumeEditor)
  const { isScrolled } = useIsScrolled()

  const widgetInfo: IHelperWidget | undefined = useMemo(
    () =>
      helperWidgetsData?.filter(
        (value: IHelperWidget) => value.widgetCollectionName === currentWidgetName,
      )?.[0],
    [currentWidgetName, helperWidgetsData],
  )

  useEffect(() => {
    if (widgetFetchCondition) {
      dispatch(actions.fetchHelperWidgets({ widgetName: currentWidgetName }))
    }
  }, [currentWidgetName, dispatch, widgetFetchCondition])

  const handleMouseOver = () => {
    setIsHovering(true)
  }

  const handleMouseOut = () => {
    setIsHovering(false)
  }

  const handleTriggerClick = () => {
    setIsHelperModalOpen((prev: boolean) => !prev)
  }

  const getCompletedSteps = (): number =>
    widgetInfo?.widgetsList?.filter(steps => steps.status === IWidgetsStatus.COMPLETED)?.length || 0

  const getTotalSteps = (): number => widgetInfo?.widgetsList?.length || 0

  return (
    <>
      {resumeEditor && isMidDesktop && !isLaptop ? (
        <Styles.StaticButtonContainer
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          onClick={handleTriggerClick}
          isHelperOpen={isHelperModalOpen}
        >
          <CircularProgressBar totalSteps={getTotalSteps()} completedSteps={getCompletedSteps()} />
        </Styles.StaticButtonContainer>
      ) : (
        <Styles.Container
          onMouseOver={handleMouseOver}
          isHovering={isHovering}
          onMouseOut={handleMouseOut}
          isScrolled={isScrolled}
          resumeEditor={resumeEditor}
          isHelpScoutPresent={isHelpScoutPresent && !resumeEditor}
          onClick={handleTriggerClick}
          isHelperOpen={isHelperModalOpen}
          currentWidgetName={currentWidgetName}
        >
          <Styles.TextMobile isScrolled={isScrolled}>
            <Styles.CollapseTitle>{labelPayload?.buttonTitle}</Styles.CollapseTitle>
          </Styles.TextMobile>
          <CircularProgressBar totalSteps={getTotalSteps()} completedSteps={getCompletedSteps()} />
          <Styles.Text>
            <Styles.Title>{labelPayload?.buttonTitle}</Styles.Title>
            <Styles.SubTitle isHovering={isHovering}>
              {labelPayload?.buttonDescription}
            </Styles.SubTitle>
          </Styles.Text>
        </Styles.Container>
      )}
      <JobSearchHelper
        totalSteps={getTotalSteps()}
        completedSteps={getCompletedSteps()}
        {...{
          isHelperModalOpen,
          currentWidgetName,
          JobSearchHelper,
          labelPayload,
          setIsHelperModalOpen,
          onStepActionClick,
          setModalAsCentre,
          handleClose,
          modalAsCentre,
          TrackingEvents,
          helperLabel,
        }}
      />
    </>
  )
}

export default FloatingCIOHelperButton
