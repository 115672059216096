import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import { fadeIn } from 'builder/styles/keyframes'

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 680px;
  max-height: 620px;
  padding-top: 48px;
  background-color: ${Colors.Neutral10};
  text-align: center;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  ${Media.Phone`
    border-radius: 0;
    max-width: 100%;
    padding-top: 77px;
    height: 100%;
    max-height: 100%;
  `};
`

export const SwiperContainer = styled.div`
  position: relative;
  &::after {
    position: absolute;
    background: linear-gradient(270deg, ${Colors.White} 0%, rgba(255, 255, 255, 0) 101.56%);
    content: ' ';
    width: 447px;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 1;
    border-radius: 16px;

    ${Media.Laptop`
      width: 145px;
    `}

    ${Media.Tablet`
      display: none;
    `}

    ${Media.Phone`
      display: none;
  `}
  }
`

export const SliderContent = styled.div`
  width: 100%;
  max-width: 1120px;
  margin: 30px auto 64px auto;
  animation: ${fadeIn} 0.3s ease;
  animation-fill-mode: forwards;
  position: relative;

  ${Media.Laptop`
    padding: 0 0 0 20px;
  `}

  &::before {
    position: absolute;
    background: ${Colors.White};
    content: ' ';
    width: 100%;
    height: 100%;
    top: 0;
    right: 100%;
    z-index: 1;

    ${Media.Tablet`
      display: none;
    `}

    ${Media.Phone`
      display:none
    `}
  }

  ${Media.Phone`
    margin-top: 24px;
  `}

  & .swiper-container {
    position: relative;
    overflow: visible;

    ${Media.Tablet`
      overflow: hidden;
    `}
  }

  & .swiper-wrapper {
    display: flex;
    position: relative;
    transition-property: transform;
  }

  .swiper-slide-active,
  .swiper-slide-next,
  .swiper-slide-prev {
    opacity: 1;
  }
`

export const SliderPlaceholder = styled.div`
  height: 464px;
  width: 100%;

  ${Media.Phone`
    height: auto;
  `}
`

export const SliderContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-top: 70px;

  ${Media.Phone`
    margin-top: 48px;
  `}
`

export const SliderContainerBorder = styled.div`
  height: 1px;
  width: 100%;
  max-width: 1120px;
  margin: auto;
  background: ${Colors.Neutral15};

  ${Media.Laptop`
    width: calc(100% - 40px);
    margin-left:20px;
  `}
`

interface CircleButtonOptions {
  direction?: 'left' | 'right'
  size?: number
}

export const ServicesButton = styled.button<CircleButtonOptions>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  width: ${props => props.size || 40}px;
  height: ${props => props.size || 40}px;
  border: none;
  border-radius: 50%;
  background-color: ${Colors.Neutral10};
  color: ${Colors.Neutral40};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${Colors.Neutral20};
  }

  ${props =>
    props.direction === 'left' &&
    css`
      transform: rotate(180deg);
    `}

  position: absolute;
  z-index: 2;
  top: calc(50%);

  ${Media.Phone`
    display: none;
  `}
`

export const ServicesLeftButton = styled(ServicesButton).attrs({
  direction: 'right',
})`
  right: calc(50% - 50px - 12px);
  top: 0;
`

export const ServicesRightButton = styled(ServicesButton).attrs({
  direction: 'left',
})`
  left: calc(50% - 10px - 24px);
  top: 0;
`

export const NavigationButtons = styled.div`
  transform: scaleX(-1);
  width: 92px;
  height: 40px;
`

export const SectionHeading = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100% - 40px);
`

export const HeaderText = styled.div`
  ${Typography.S};
  ${FontWeights.Medium};
  align-self: center;
`

export const Card = styled.div<{ service: { color: string; bgColor: string } }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  gap: 24px;
  width: 363px;
  height: 432px;
  background: ${props => props.service.bgColor};
  color: ${props => props.service.color};
  border-radius: 16px;

  ${Media.Tablet`
    width: 348px;
    
  `}

  ${Media.Phone`
    width: 256px;
    height: 378px;
    padding: 24px;
    gap:0
  `}
`

export const Testimonial = styled.div``

export const Text = styled.p`
  ${Typography.Subhead};
  ${FontWeights.Medium};

  ${Media.Phone`
    margin: 16px 0 8px 0;
  `}
`

export const SubText = styled.p``

export const Name = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
`
