import styled from 'styled-components'
import Typography from 'builder/styles/typography'
import Colors from 'builder/styles/colors'

export const Container = styled.div`
  text-align: center;
`

export const Image = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto 32px;
  object-fit: contain;
`

export const ImageContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

export const Title = styled.div`
  ${Typography.XL};
  margin-bottom: 12px;
`

export const Content = styled.div`
  max-width: 600px;
  margin: 0 auto;

  & a {
    color: ${Colors.Blue50};
  }

  & a:hover {
    color: ${Colors.Blue70};
  }
`
