import { useState, useCallback, useRef } from 'react'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useI18n } from 'builder/hooks/useI18n'
import Icon from 'builder/components/Icon'
import Button from 'builder/components/Button'
import ModalOverlay from 'builder/components/ModalOverlay'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { DocumentTypes } from 'builder/modules/constants'
import { actions as panelActions } from 'builder/modules/panel'
import { actions as uiActions } from 'builder/modules/ui'
import { formatImageUrl } from 'builder/utils/formatImageUrl'
import DEFAULT_LETTER_IMAGE_URL from 'images/modals/cover-letter-modal-letter.png'
import DEFAULT_RESUME_IMAGE_URL from 'images/modals/cover-letter-modal-resume.png'

import { useTemplateThumbnails } from './hooks'
import calcPreviewRotation, { Coordinates } from './calcPreviewRotation'

import {
  Close,
  Container,
  Content,
  ImagesContainer,
  LetterImage,
  ResumeImage,
  Subtitle,
  Title,
} from './styles'

interface CoverLetterModalProps {
  letterId: number
}

const CoverLetterModal = ({ letterId }: CoverLetterModalProps) => {
  const modalRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)

  const { i18n } = useI18n()
  const { isPhone } = useMediaQueries()
  const dispatch = useDispatch()
  const { data: thumbnails, isLoading } = useTemplateThumbnails(letterId)

  const [rotation, rotatePreviews] = useState<Coordinates>({ x: 0, y: 0 })

  const handleMouseMove = useCallback((e: React.MouseEvent) => {
    if (!modalRef.current) return

    const newRotation = calcPreviewRotation({
      mousePosition: {
        x: e.pageX,
        y: e.pageY,
      },
      element: modalRef.current,
      range: 20,
    })

    rotatePreviews(newRotation)
  }, [])

  const handleMouseLeave = useCallback(() => {
    rotatePreviews({ x: 0, y: 0 })
  }, [])

  const handleClose = useCallback(() => {
    dispatch(uiActions.closeCoverLetterModal())
  }, [dispatch])

  const handleCreateResume = useCallback(() => {
    dispatch(uiActions.closeCoverLetterModal())
    dispatch(panelActions.createDocument({ type: DocumentTypes.resume }))
  }, [dispatch])

  const resumeUrl = formatImageUrl(thumbnails?.resume || DEFAULT_RESUME_IMAGE_URL, {
    width: 200,
  })
  const letterUrl = formatImageUrl(thumbnails?.coverLetter || DEFAULT_LETTER_IMAGE_URL, {
    width: 200,
  })

  const resumeTransform = `
    rotateX(${-rotation.y + 2}deg) rotateY(${rotation.x + 2}deg)
    translate3d(${-10 + rotation.x}px, -10px, 0)`

  const letterTransform = `
    rotateX(${-rotation.y - 2}deg) rotateY(${rotation.x - 2}deg)
    translate3d(10px, 10px, -20px)`

  return (
    <ModalOverlay
      fullScreen={isPhone}
      onClick={handleClose}
      overlayFadeDuration={150}
      contentSlideDuration={0}
    >
      <Container ref={modalRef} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
        <Content>
          <Close onClick={handleClose}>
            <Icon.Close />
          </Close>

          <ImagesContainer>
            {!isLoading && (
              <>
                <ResumeImage
                  style={{
                    backgroundImage: `url(${resumeUrl})`,
                    transform: resumeTransform,
                  }}
                />

                <LetterImage
                  style={{
                    backgroundImage: `url(${letterUrl})`,
                    transform: letterTransform,
                  }}
                />
              </>
            )}
          </ImagesContainer>

          <Title>{i18n.t('builder.cover_letter_editor.download_modal.title')}</Title>
          <Subtitle>{i18n.t('builder.cover_letter_editor.download_modal.subtitle')}</Subtitle>
          <Button onClick={handleCreateResume}>
            {i18n.t('builder.cover_letter_editor.download_modal.create_resume')}
          </Button>
        </Content>
      </Container>
    </ModalOverlay>
  )
}

export default CoverLetterModal
