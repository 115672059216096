import styled, { css } from 'styled-components'
import TabsComponent from 'builder/components/Tabs'
import { Badge } from 'builder/components/Badge'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import { TABS } from './constants'

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 540px;
  background-color: ${Colors.White};
  border-radius: 6px;
  z-index: 999;

  ${props =>
    props.activeTab === TABS.stats &&
    css`
      height: calc(100vh - 64px);
      max-height: 640px;
    `};

  ${Media.Phone`
    height: 100%;
    max-width: none;
    max-height: none;
    border-radius: 0;
  `};
`

export const Header = styled.div`
  position: relative;
  flex-shrink: 0;
`

export const Tabs = styled(TabsComponent)`
  padding: 18px 32px 0;
`

export const TabLabel = styled(Badge).attrs({ variant: 'blue' })`
  margin-left: 8px;
  text-transform: uppercase;
`

export const Close = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const DesktopClose = styled(Close)`
  top: 0;
  right: 0;
  padding: 17px 16px;
  color: ${Colors.Neutral40};
  transition: color 0.15s;

  &:hover {
    color: ${Colors.Blue50};
  }
`

export const MobileClose = styled(Close)`
  top: calc(50% - 16px);
  right: 20px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${Colors.Neutral30};
  color: ${Colors.White};
`
