import styled, { css } from 'styled-components'
import { Optional } from 'packages/types'
import Media from 'builder/styles/media'
import { Cards, PromotionTopic } from '../../../common/types'
import usePromotionCardValues from '../../../hooks/usePromotionCardValues'

interface Props {
  activeCard: Optional<Cards>
  topic: PromotionTopic
  isNlLocale: boolean
}

const CARD_SECTION_DESKTOP_VIEW_WIDTH = 187 // in pixels

export const PromotionContainer = styled.div<Props>(({ activeCard, topic, isNlLocale }) => {
  const { getCardStyles } = usePromotionCardValues()
  const cardStyles = getCardStyles(topic)

  const index =
    isNlLocale && Object.values(Cards).indexOf(activeCard as Cards) !== 0
      ? Object.values(Cards).indexOf(activeCard as Cards) - 1
      : Object.values(Cards).indexOf(activeCard as Cards)

  const bubbleArrowOffset = isNlLocale
    ? CARD_SECTION_DESKTOP_VIEW_WIDTH + index * CARD_SECTION_DESKTOP_VIEW_WIDTH
    : CARD_SECTION_DESKTOP_VIEW_WIDTH / 2 + index * CARD_SECTION_DESKTOP_VIEW_WIDTH

  return css`
    display: flex;
    width: 928px;
    height: 272px;
    background-color: ${cardStyles.backgroundColor};
    border-radius: 16px;
    justify-content: center;
    align-items: center;
    position: relative;

    ${Media.AboveTablet`
      margin-top: 24px;
      &:after {
        content: '';
        position: absolute;
        bottom: 100%;
        left: ${bubbleArrowOffset}px;
        width: 8px;
        margin-left: -8px;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid ${cardStyles.backgroundColor};
      }
    `}

    ${Media.Tablet`
      width: 540px;
      height: 436px;
      flex-direction: column-reverse;
    `}
    ${Media.Phone`
      width: 335px;
      height: 396px;
      flex-direction: column-reverse;
    `}
  `
})
