import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Button from 'builder/components/Button'
import Media from 'builder/styles/media'
import Sizes from 'builder/styles/sizes'

export const SubHead = styled.div`
  display: flex;
  gap: 6px;
  color: ${Colors.Neutral90};
  ${Typography.Subhead}
  ${FontWeights.Medium}
`

export const CustomButton = styled(Button)`
  margin-top: 16px;
  width: 100%;
  height: 96px;
  ${Media.Phone`
    margin-top: 12px;
    border-radius: ${Sizes['2XS']};
    height: 70px;
  `}

  &:hover ${SubHead} {
    color: ${Colors.Blue50};
  }
`
