import styled from 'styled-components'
import { Typography, FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'

export const Container = styled.div`
  background-color: ${Colors.White};
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 100%;
  align-items: flex-start;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
`

export const Header = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};
  width: 100%;
  mix-blend-mode: normal;
  text-align: center;
`

export const FormContainer = styled.form`
  ${Media.Phone`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  `}
`

export const FieldsContainer = styled.div`
  margin: 4px 0 12px;
`

export const FieldContainer = styled.div`
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const SkillsText = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
`

export const SkillsTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
