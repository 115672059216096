import styled from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Avatar from 'builder/components/Avatar'

export const Container = styled.div`
  position: relative;

  /* react-autosuggest changes the cursor over text fields to 'pointer', but we don't need that */
  & [aria-controls] {
    cursor: initial;
  }

  /* library uses 'ul'/'li' tags as list and element wrappers, so we have to reset default styles */
  & .react-autosuggest__suggestions-list,
  & .react-autosuggest__suggestion {
    margin: 0;
    padding: 0;
    list-style: none;
  }
`

export const Menu = styled.div<{ isEmpty: boolean }>`
  display: ${props => (props.isEmpty ? 'none' : 'block')};
  position: absolute;
  z-index: 1;
  top: 116%;
  left: 0;
  right: 0;
  padding: 6px 0;
  border-radius: 4px;
  box-shadow: 0px 2px 14px rgba(23, 68, 130, 0.15), 0px 0px 1px rgba(23, 68, 130, 0.2);
  background-color: ${Colors.White};
  transition: transform 0.14s ease;
  transform-origin: 50% 0;
`

export const MenuItem = styled.div<{ isHighlighted: boolean }>`
  display: flex;
  align-items: center;
  padding: 6px 16px;
  cursor: pointer;
  color: ${props => (props.isHighlighted ? Colors.Blue50 : Colors.Neutral90)};
  background-color: ${props =>
    props.isHighlighted ? hexToRgba(Colors.Blue20, 0.5) : 'transparent'};
`

export const MenuItemLogo = styled(Avatar)`
  margin-right: 12px;
`
