import styled, { css } from 'styled-components'
import Button from 'builder/components/Button'
import { Colors } from 'builder/styles/colors'
import { Shadows } from 'builder/styles/shadows'
import { Spinner } from 'builder/components/Spinner'
import { FontWeights, Typography } from 'builder/styles/typography'
import { Container as BaseContainer } from 'builder/components/Container'
import { HorizontalTransitionFade } from 'builder/components/HorizontalTransitionFade'

export const Container = styled.div`
  overflow: visible;
`

export const BackgroundContainer = styled(HorizontalTransitionFade)``

export const MatrixContainer = styled(BaseContainer)`
  overflow: visible;
  padding: 0;
`

export const CardRect = styled.rect`
  fill: ${Colors.Neutral5};
`

const AbsoluteCenter = css`
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
`

export const ShadowContainer = styled.div`
  ${AbsoluteCenter};
  width: 100%;
  height: 460px;
  opacity: 0.7;
  background: radial-gradient(50% 50% at 50% 50%, #fff 58.58%, rgba(255, 255, 255, 0) 100%);
`

export const ContentWrapper = styled.div`
  ${AbsoluteCenter};
  gap: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const ProcessWrapper = styled(ContentWrapper)``

export const SpinnerWrapper = styled.div`
  ${Shadows.light.mid};
  width: 64px;
  height: 64px;
  border-radius: 50%;
  align-self: center;
  position: relative;
  background: ${Colors.White};
`

export const SpinnerContainer = styled(Spinner)`
  ${AbsoluteCenter};
`

export const TextWrapper = styled.div`
  ${Typography.Subhead};

  width: 100%;
  align-self: center;
  position: relative;
  text-align: center;
`

export const StaticText = styled.div`
  max-width: 396px;
  align-self: center;
  text-align: center;
`

export const Title = styled.div`
  ${Typography.S};
  ${FontWeights.Medium};
  text-align: center;
`

export const StyledButton = styled(Button)`
  margin-top: 8px;
`
