import {
  BannerButtonContainer,
  BannerContentContainer,
  BannerImage,
  BannerImageContainer,
  BannerPrimaryButton,
  BannerSubtitle,
  BannerTitle,
  JobSearchBannerContainer,
} from './styles'

interface IJobSearchBannerProps {
  titleColor: string
  subtitleColor: string
  btnBgColor: string
  btnBgHoverColor: string
  title: string
  subtitle: string
  btnTxt: string
  imgUrl: string
  handleClick: () => void
  top?: number
  open: boolean
}

export const JobSearchBanner = ({
  titleColor,
  btnBgColor,
  btnBgHoverColor,
  subtitleColor,
  btnTxt,
  subtitle,
  title,
  imgUrl,
  handleClick,
  top,
  open,
}: IJobSearchBannerProps) => {
  return (
    <JobSearchBannerContainer open={open}>
      <BannerContentContainer long={title.split(' ').length > 7}>
        <BannerTitle color={titleColor}>{title}</BannerTitle>
        <BannerSubtitle color={subtitleColor}>{subtitle}</BannerSubtitle>
        <BannerButtonContainer>
          <BannerPrimaryButton
            onClick={handleClick}
            btnBgColor={btnBgColor}
            btnBgHoverColor={btnBgHoverColor}
          >
            {btnTxt}
          </BannerPrimaryButton>
        </BannerButtonContainer>
      </BannerContentContainer>
      <BannerImageContainer top={top}>
        <BannerImage src={imgUrl} alt={title} />
      </BannerImageContainer>
    </JobSearchBannerContainer>
  )
}
