import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Avatar from 'builder/components/Avatar'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Icon24, Icon20 } from 'builder/components/Icon'
import StatusIconComponent from 'builder/components/JobTrackingStatusIcon'
import Media from 'builder/styles/media'
import { Button } from 'builder/components/Button'
import Spinner from 'builder/components/Spinner'

export const Container = styled.div<{ isDashboard: boolean }>`
  width: 656px;
  height: 100vh;
  z-index: ${({ isDashboard }) => (isDashboard ? 598 : 600)};
  padding-top: 30px;
  border-radius: 6px;
  background: ${Colors.White};
  position: fixed;
  top: ${({ isDashboard }) => (isDashboard ? '80px' : 0)};
  right: 0;
  box-shadow: 0px 6px 8px -2px rgba(15, 56, 113, 0.02), 0px 16px 64px -8px rgba(15, 56, 113, 0.18);
  display: flex;
  flex-direction: column;
  ${Media.Phone`
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: inherit;
    padding-top: 24px;
  `};

  ${({ isDashboard }) =>
    isDashboard &&
    css`
      ${Media.Phone`
        top: 72px;
      `};
    `}
`

export const PaddedContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 32px;
`

export const ScrollContainer = styled.div`
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  height: 100%;
  &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${Colors.Neutral20};
    border: none;
    border-radius: 4px;
    border: 2px solid ${Colors.Neutral10};
    transition: border-color 0.1s ease;

    &:hover {
      background-color: ${Colors.Neutral30};
    }
  }
`

export const Header = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`

export const CompanyLogoContainer = styled.div`
  align-self: flex-start;
  width: 48px;
`

export const CompanyNameContainer = styled.div`
  width: 500px;
`
export const DocumentDeleteContainer = styled.div`
  margin-left: auto;
  margin-right: 20px;
`
export const DeleteIconContainer = styled.div<{ documentBtn?: boolean }>`
  ${Typography.S};
  color: ${Colors.Neutral50};
  width: 10%;
  text-align: right;
  cursor: pointer;
  margin-left: auto;
  opacity: 0.5;
  ${props =>
    props.documentBtn
      ? css`
          width: inherit;
          margin-left: inherit;
          position: relative;
          opacity: 0.25;
          :hover {
            opacity: 0.5;
          }
        `
      : ''}
`
export const DeleteDocumentConfirmation = styled.div`
  & > :nth-child(2) {
    margin-left: 5px;
  }
  & > svg {
    opacity: 0.7;
  }
  & > svg:hover {
    opacity: 1;
  }
  text-align: right;
  cursor: pointer;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const ElipsisContainer = styled.div`
  width: 30%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const CompanyLogo = styled(Avatar)`
  pointer-events: none;
`
export const CompanyLabel = styled.div`
  ${Typography.Tiny};
  color: ${Colors.Neutral50};
  pointer-events: none;
`
export const CompanyName = styled.input`
  color: ${Colors.Neutral100};
  width: 100%;
  ${Typography.S};
  ${FontWeights.Medium};
  border: none;
  border-radius: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 0px;

  &:hover {
    background-color: ${Colors.Neutral10};
  }
  &:focus {
    background-color: ${Colors.Neutral20};
  }
`

export const EllipsisIcon = styled(Icon24.Ellipsis)`
  margin-right: 4px;
  cursor: pointer;
`

export const ExternalLink = styled(Icon24.ExternalLink)`
  margin-right: 4px;
  cursor: pointer;
`

export const Line = styled.div`
  width: 51px;
  border-top: 2px solid ${Colors.Blue40};
  margin-top: 20px;
`

export const ButtonContainer = styled.div<{ fromDashboard: boolean }>(({ fromDashboard }) => {
  return css`
    margin-top: 24px;
    display: flex;
    gap: 10px;
    ${Media.Phone`
      padding: 20px 20px 32px 20px;
      margin-bottom: ${fromDashboard ? '48px' : '24px'};
      margin-top: 0px;
    `};
  `
})

export const PrimaryButton = styled.button`
  border-radius: 4px;
  ${Typography.Body};
  ${FontWeights.Medium};
  background-color: ${Colors.Blue50};
  color: ${Colors.White};
  border: none;
  padding: 14px 0px;
  width: 100%;
  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: ${Colors.Blue60};
  }
`

export const SecondaryButton = styled.button<{ showApply: boolean }>`
  border-radius: 4px;
  ${Typography.Body};
  ${FontWeights.Medium};
  background-color: ${Colors.White};
  border: 1px solid ${Colors.Neutral20};
  color: ${Colors.Neutral100};
  padding: 14px 0px;
  width: ${props => (props.showApply ? '50%' : '100%')};
  cursor: pointer;

  &:hover {
    border: 1px solid ${Colors.Neutral30};
  }
`

export const TabsContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid ${Colors.Neutral10};
  display: flex;
  gap: 20px;
  padding: 16px 32px;
`

export const Tab = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral100};
`

export const InformationContainer = styled.div`
  padding: 16px 0;
  display: flex;
  border-top: 1px solid ${Colors.Neutral10};
`
export const AutoApplyStatusQuestions = styled.div`
  margin-bottom: 16px;
  font-size: 16px;
  color: ${Colors.Neutral50};
  ${FontWeights.Regular};
  & > a {
    color: ${Colors.Blue50};
  }
`

export const BannerContainer = styled.div`
  display: flex;
  margin: 16px 0px 32px 0px;
`

export const Title = styled.div<{ shortWidth?: boolean }>`
  ${Typography.Body};
  ${FontWeights.Medium};
  ${props =>
    props.shortWidth
      ? css`
          margin-right: 8px;
        `
      : css`
          width: 160px;
        `}
  gap: 5px;
  display: flex;
  align-items: center;
`

export const Information = styled.input`
  color: ${Colors.Neutral50};
  ${Typography.Body};
  width: 65%;
  font-weight: 400;
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;

  &:hover {
    background-color: ${Colors.Neutral10};
  }
  &:focus {
    background-color: ${Colors.Neutral20};
  }
  ::placeholder {
    color: ${Colors.Neutral50};
  }

  ::-ms-input-placeholder {
    color: ${Colors.Neutral50};
  }
`
export const InformationIcon = styled.img`
  filter: brightness(0.1);
  height: 23px;
`

export const ResumeTitle = styled.a`
  color: ${Colors.Blue50};
  width: 65%;
  cursor: pointer;
  font-size: 16px;
  padding: 8px;
  font-weight: 400;
  border: none;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
`

export const InformationDiv = styled.div`
  color: ${Colors.Neutral100};
  width: 65%;
  padding-left: 8px;
  ${Typography.Tiny};
  border: none;
  align-items: center;
  position: relative;
  display: flex;
`

export const Status = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
  ${Typography.Caption};
  ${FontWeights.DemiBold};
  transition: color 0.15s;
  cursor: pointer;

  &:hover {
    color: ${Colors.Blue50};
  }

  & select {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    outline: none;
    cursor: pointer;
  }
`

export const StatusIcon = styled(StatusIconComponent)`
  margin-right: 8px;
`

export const ResumeMatch = styled.div`
  text-wrap: nowrap;
`

export const Document = styled(Icon24.Documents)`
  color: ${Colors.Neutral90};
  width: 20px;
  filter: brightness(0.5);
  svg > :nth-child(1) {
    display: none;
  }
`
export const ResumeDocumentIcon = styled(Icon24.Documents)`
  filter: brightness(0.5);
  width: 20px;
  svg > :nth-child(1) {
    display: none;
  }
`

export const StatusChevron = styled(Icon20.ChevronDown)`
  color: ${Colors.Neutral40};
  transition: color 0.15s;

  ${Status}:hover & {
    color: ${Colors.Blue50};
  }
`

export const ResumePercentage = styled.div<{ poorResumeScore: boolean; simple?: boolean }>`
  background: ${props => (props.poorResumeScore ? Colors.Red10 : Colors.Green10)};
  color: ${props => (props.poorResumeScore ? Colors.Red50 : Colors.Green50)};
  ${Typography.Caption};
  ${FontWeights.Regular};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props =>
    props.simple
      ? css`
          background: none;
        `
      : css`
          padding: 2px 6px;
          margin-right: 8px;
          width: 39px;
          height: 24px;
        `}
`

export const ResumeMatchText = styled.div`
  ${Typography.Caption};
  ${FontWeights.Regular};
`

export const DescriptionImage = styled.img``

export const TextAreaContainer = styled.div`
  border-top: 1px solid ${Colors.Neutral10};
  padding: 24px 0;
  margin: 0px 32px;
`

export const FieldLabel = styled.div`
  color: ${Colors.Neutral100};
  ${Typography.Body};
  ${FontWeights.Medium};
  gap: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const FieldContainer = styled.label<{ multiline: string; isDescriptionVisible: boolean }>`
  position: relative;
  width: ${props => (props.multiline ? '100%' : 'calc(50% - 12px)')};
  margin-bottom: 16px;
  height: ${props => (props.isDescriptionVisible ? 'fit-content' : '0.5%')};

  ${Media.Phone`
    width: 100%;
  `};

  &:last-child {
    margin-bottom: 0;
  }
`

export const FieldInputBase = styled.input`
  display: block;
  width: 100%;
  margin: 0;
  padding: 16px 32px 8px 0;
  border: none;
  background: none;
  color: ${Colors.Neutral90};
  caret-color: ${Colors.Blue60};
  resize: none;
  ${Typography.Caption};

  &::placeholder {
    ${Typography.Caption};
    color: ${Colors.Neutral30};
    opacity: 1;
  }
`

export const FieldInput = styled(FieldInputBase)`
  opacity: 1;

  textarea& {
    min-height: 16px;
  }
`

export const IrrelevantBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  width: 100%;
  max-width: 544px;
  height: 192px;
  margin: 15px 0;
  background-color: ${Colors.Neutral5};
  border: 1px solid ${Colors.Neutral10};
  border-radius: 6px;
  z-index: 999;
`

export const IrrelevantBoxTitle = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Regular};
  color: #000000;
`

export const IrrelevantBoxSubtitle = styled.div`
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${Colors.Neutral70};
`

export const TickIcon = styled(Icon20.Tick)`
  width: 20px;
  height: 20px;
`

export const Actions = styled.div`
  display: flex;
  align-items: stretch;
  margin-top: 20px;
  > :not(:last-child) {
    margin-right: 8px;
  }
  ${Media.Tablet`
    flex-direction: column;
    > :not(:last-child) {
      margin-right: 0;
      margin-bottom: 8px;
    }
  `}
`

export const IrrelevantButton = styled(Button)`
  ${FontWeights.Medium};
  ${Typography.Caption};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 20px 8px 16px;
  gap: 4px;
  width: 144px;
  height: 36px;
  background: ${Colors.Blue50};
  border-radius: 4px;
`

export const IrrelevantDeleteButton = styled(Button)`
  ${FontWeights.Medium};
  ${Typography.Caption};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  width: 82px;
  height: 36px;
  background: ${Colors.Red10};
  color: ${Colors.Red50};
  border-radius: 4px;
  &:hover {
    background: ${Colors.Red50};
    color: ${Colors.White};
  }
`
export const Chevron = styled(Icon24.Chevron)<{ collapsed?: number }>`
  cursor: pointer;
  margin-left: auto;
  transition: 0.3s;

  ${({ collapsed }) =>
    collapsed
      ? css`
          transform: rotate(270deg);
        `
      : css`
          transform: rotate(90deg);
        `}
`

export const RecycleIcon = styled(Icon24.Trash)`
  color: ${Colors.Neutral100};
  cursor: pointer;
`
export const DocumentsContent = styled.div`
  padding: 32px 0 0 0;
`

export const DocumentsLineHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`

export const DocumentsLineTitle = styled.div`
  color: ${Colors.Neutral50};
  font-size: 16px;
  font-weight: 400;
  gap: 5px;
  display: flex;
  align-items: center;
`

export const DocumentsLineButton = styled.button`
  cursor: pointer;
  border: none;
`
export const DocumentsLineImg = styled.img`
  opacity: 0.6;
`
export const DocumentsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > :nth-child(even) > :first-child {
    /* margin-left: 24px; */
  }
`

export const DocumentsListItem = styled.a`
  flex: 0 0 50%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

export const DocumentThumbnail = styled.img`
  width: 48px;
  box-shadow: 0px 0px 2px 0px #0f387152;
  border-radius: 5px;
`
export const DocumentPlaceholderOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.25;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`
export const ThumbnailPlaceholderContainer = styled.div`
  position: relative;
`
export const ThumbnailSpinner = styled(Spinner)``

export const DocumentDownload = styled.a`
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  max-width: 150px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 16px;
`

export const DocumentDownloadPlaceholder = styled.p`
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  max-width: 180px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 16px;
`

export const DocumentErrorLine = styled.div`
  margin: 16px;
  padding: 16px 24px;
  background: ${Colors.Neutral5};
  border-radius: var(--size-2xs);
  cursor: pointer;
  & > :nth-child(1) {
    color: ${Colors.Neutral90};
    ${Typography.Caption};
    ${FontWeights.Medium};
  }
  & > :nth-child(2) {
    color: ${Colors.Red50} !important;
    ${Typography.Caption};
    ${FontWeights.Regular};
  }
`
export const DropSection = styled.div`
  background: ${Colors.Blue10};
  color: ${Colors.Blue50};
  border: 1px dashed ${Colors.Blue50};
  height: 80px;
  width: 100%;
  ${Typography.Body};
  ${FontWeights.Medium};
  display: flex;
  align-items: center;
  justify-content: center;
  & > :first-child {
    margin-right: 6px;
  }
`

export const CurrentStatus = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  background: ${({ color }) => color};
  cursor: pointer;
  padding: 2px 8px;
  width: auto;
  border-radius: 4px;
  gap: 4px;
`

export const StatusContainer = styled.div`
  position: relative;
`
