import styled from 'styled-components'
import { Typography, FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Button from 'builder/components/Button'
import Colors from 'builder/styles/colors'

export const FormContainer = styled.form`
  max-width: 524px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

export const Image = styled.img`
  width: 250px;
  height: 230px;
`

export const Title = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};
  margin-top: 40px;
  text-align: center;
  color: ${Colors.Neutral90};

  ${Media.Phone`
    margin-top: 24px;
  `}
`

export const Subtitle = styled.div`
  ${Typography.Caption};
  width: 100%;
  text-align: center;
  margin: 12px 0 24px;
  color: ${Colors.Neutral50};
`

export const FormButton = styled(Button)`
  margin-top: 40px;

  ${Media.Phone`
    width: 100%;
  `}
`
