import { differenceWith, orderBy, filter, isEmpty } from 'lodash'
import { useDispatch } from 'react-redux'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { SnackbarTypes, actions as uiActions } from 'builder/modules/ui'
import {
  MAX_LENGTH,
  defaultStep,
  HeaderProps,
  AccSkillsType,
  Accomplishment,
  defaultAccomplishments,
  MAX_ACCOMPLISMENT_LIMIT,
  MAX_SKILLS_SELECTION_LIMIT,
  defaultSkill,
  defaultStory,
  minAccomplishment,
  actions,
  selectors,
  MAX_SKILLS_SELECTED,
  label,
} from 'builder/modules/sevenStories'

export const useGetFiveAccomplishmentHook = () => {
  const dispatch = useDispatch()
  const accomplishmentsStatus = useTypedSelector(selectors.status)

  const HandlePostAccomplishments = () => {
    // Get selcted accomplishments
    const selectedAccomplishments = JSON.parse(
      localStorage.getItem('selectedAccomplishments') || '',
    )
    // Get all selected accomplishments
    const mainAccomplishments = JSON.parse(localStorage.getItem('accomplishments') || '')

    // Filter data based on selection
    const filterData = mainAccomplishments.filter(
      (item: { isSelected: boolean }) => !item.isSelected,
    )
    // Create main array for API
    const mainPayloadData = [...selectedAccomplishments, ...filterData]

    // Create main array for API without blank text
    const payloadData = mainPayloadData.filter(data => data.description)

    // Creating a final payload for API
    const apiPayload = payloadData.map((item: { description: string }, index: number) => {
      return {
        title: item.description,
        rank: index + 1,
      }
    })

    dispatch(actions.setAddAccomplishment(apiPayload))
  }

  const maxAccomplishmentsLength = MAX_LENGTH

  const [step, setStep] = useState(defaultStep)
  const [isMaxSelection, setIsMaxSelection] = useState<boolean>(false)
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0)
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
  const [showStoryId, setShowStoryId] = useState<number>(0)

  const contentData: Accomplishment[] = useMemo(() => {
    const accomplishments = localStorage.getItem('accomplishments')
    const parsedData = accomplishments ? JSON.parse(accomplishments) : null
    return parsedData || defaultAccomplishments
  }, [])

  // Selcted Accomplishments
  const selectedAccsData: Accomplishment[] = useMemo(() => {
    const accomplishments = localStorage.getItem('selectedAccomplishments')
    const parsedData = accomplishments ? JSON.parse(accomplishments) : []

    return parsedData
  }, [])

  const [accomplishmentData, setAccomplishmentData] = useState<Accomplishment[]>(contentData)
  const [selectedAccomplishment, setSelectedAccomplishment] =
    useState<Accomplishment[]>(selectedAccsData)
  const [isSkillError, setIsSkillError] = useState<boolean>(false)
  const [accomplishmentsSkills, setAccomplishmentsSkills] = useState<AccSkillsType[]>([])

  const skillsData: AccSkillsType[] = useMemo(() => {
    const skills = localStorage.getItem('selectedSkills')
    let parsedSkills: AccSkillsType[] = skills ? JSON.parse(skills) : null

    // Store all accomplishments skills
    const defaultSkills: AccSkillsType[] = []

    if (!parsedSkills) {
      selectedAccomplishment.forEach(data => {
        const skills = data.skills.map(skill => {
          const newSkill = {
            ...skill,
            storyID: data.id,
          }

          return newSkill
        })

        defaultSkills.push(...skills)
      })

      const updatedDefaultSkills = defaultSkills.map((skill, index) => ({
        ...skill,
        id: index + 1,
      }))

      const filteredSkills = updatedDefaultSkills.filter(skill => skill.text)

      return filteredSkills
    } else {
      const selectedSkills: AccSkillsType[] = []

      selectedAccomplishment.forEach(acc => {
        acc.skills.forEach(skill => {
          if (skill.text) {
            const newSkill = {
              ...skill,
              storyID: acc.id,
            }

            selectedSkills.push(newSkill)
          }
        })
      })

      const diffSkillsToAdd = differenceWith(
        selectedSkills,
        parsedSkills,
        (obj1, obj2) => obj1.text === obj2.text,
      )

      const diffSkillsToRemove = differenceWith(
        parsedSkills,
        selectedSkills,
        (obj1, obj2) => obj1.text === obj2.text,
      )

      parsedSkills = [...parsedSkills, ...diffSkillsToAdd]

      parsedSkills = parsedSkills.filter(skill => !diffSkillsToRemove.includes(skill))

      localStorage.setItem('selectedSkills', JSON.stringify(parsedSkills))
    }

    return parsedSkills
  }, [selectedAccomplishment])

  useEffect(() => {
    setAccomplishmentsSkills(skillsData)
  }, [skillsData])

  const handleSnackbar = (type: SnackbarTypes, message: string) => {
    dispatch(
      uiActions.setSnackBarOpen({
        status: true,
        type: type,
        text: message,
      }),
    )

    setTimeout(() => {
      dispatch(
        uiActions.setSnackBarOpen({
          status: false,
          type: type,
        }),
      )
    }, 3000)
  }

  // Store wizzard step on local storage
  const storeStep = useCallback(
    (sign: '+' | '-') => {
      if (sign === '+') {
        localStorage.setItem('step', JSON.stringify(step + 1))
      } else {
        localStorage.setItem('step', JSON.stringify(step - 1))
      }
    },
    [step],
  )

  const handleNextStep = useCallback(() => {
    if (step <= 5) {
      setStep(prevStep => prevStep + 1)

      // Store Current step on storage
      storeStep('+')
    }
  }, [step, storeStep])

  const handlePreviousStep = () => {
    if (step > 0) {
      setStep(prevStep => prevStep - 1)
      // Store Current step on storage
      storeStep('-')
    }

    if (step === 5) {
      trackInternalEvent('click_back_to_assessment_button', {
        assessment_name: 'Seven Stories',
        ...label,
      })
    }
  }

  useEffect(() => {
    const { isSuccess } = accomplishmentsStatus
    if (isSuccess && (step === 2 || step === 4)) {
      handleNextStep()
      // Ste default step
      dispatch(actions.setAccomplishmentStatus({ isSuccess: false }))
    }
  }, [accomplishmentsStatus, dispatch, handleNextStep, step])

  const handleAccomplishmentChange = (value: string, index: number) => {
    const newAccomplishments = [...accomplishmentData]
    const updatedAccomplishment = {
      ...newAccomplishments[index],
      description: value,
      isSelected: false,
    }
    newAccomplishments[index] = updatedAccomplishment

    const accId = newAccomplishments[index].id
    const diffSkillsToAdd = filter(selectedAccomplishment, obj => obj.id === accId)

    if (diffSkillsToAdd.length) {
      const selectedAcc = selectedAccomplishment.filter(acc => acc.id !== accId)
      setSelectedAccomplishment(selectedAcc)
      localStorage.setItem('selectedAccomplishments', JSON.stringify(selectedAcc))
    }

    setAccomplishmentData(newAccomplishments)
    localStorage.setItem('accomplishments', JSON.stringify(newAccomplishments))
  }

  useEffect(() => {
    // Get max selection from storage
    const isMaxSelection = localStorage.getItem('isMaxSelection')
    const isValue = isMaxSelection ? JSON.parse(isMaxSelection) : false
    setIsMaxSelection(isValue)

    // Get Step from storage
    const newStep = parseInt(localStorage.getItem('step') ?? '0')
    setStep(newStep)

    // Get current story step
    const newStoryStep = parseInt(localStorage.getItem('currentStoryStep') ?? '0')
    setCurrentStoryIndex(newStoryStep === 0 ? 0 : newStoryStep - 1)
  }, [])

  useEffect(() => {
    const maxSelection =
      selectedAccomplishment.filter((item: { isSelected: boolean }) => item.isSelected).length >
      MAX_ACCOMPLISMENT_LIMIT

    // Check Maximum selction
    setIsMaxSelection(maxSelection)
    localStorage.setItem('isMaxSelection', JSON.stringify(maxSelection))
  }, [accomplishmentData, selectedAccomplishment])

  useEffect(() => {
    if (step === 5) {
      dispatch(actions.fetchAssessmentResult())
    }
  }, [dispatch, step])

  // Change the satet of accomplishment selection
  const handleAccomplishmentSelect = (value: boolean, id: number) => {
    const newSelected = [...selectedAccomplishment]
    const mainAccomplishment = [...accomplishmentData]
    const index = mainAccomplishment.findIndex(item => item.id === id)

    // Check max slection
    const maxSelection =
      newSelected.filter(item => item.isSelected).length <= MAX_ACCOMPLISMENT_LIMIT

    if (!value && maxSelection) {
      if (selectedAccomplishment.length === MAX_ACCOMPLISMENT_LIMIT) {
        handleSnackbar(SnackbarTypes.simple, 'You’ve reached the maximum number of selections')
      }

      const updatedAccomplishment = {
        ...mainAccomplishment[index],
        isSelected: true,
      }

      newSelected.push(updatedAccomplishment)
      mainAccomplishment[index] = updatedAccomplishment

      setSelectedAccomplishment(newSelected)
      setAccomplishmentData(mainAccomplishment)

      // set storage with updated data
      localStorage.setItem('selectedAccomplishments', JSON.stringify(newSelected))
      localStorage.setItem('accomplishments', JSON.stringify(mainAccomplishment))
    } else {
      const updatedAccomplishment = {
        ...mainAccomplishment[index],
        isSelected: false,
        skills: [defaultSkill],
      }

      mainAccomplishment[index] = updatedAccomplishment
      setAccomplishmentData(mainAccomplishment)

      const newData = selectedAccomplishment?.filter((item: { id: number }) => item.id !== id)
      setSelectedAccomplishment(newData)

      // Store update data on storage
      localStorage.setItem('selectedAccomplishments', JSON.stringify(newData))
      localStorage.setItem('accomplishments', JSON.stringify(mainAccomplishment))
    }
  }

  // Set Accomplishment story answer
  const onStoryChange = (value: string, index: number, field: string) => {
    const newSelected = [...selectedAccomplishment]
    let currentStory = newSelected[currentStoryIndex]

    // Update stories value
    const updatedStories = {
      ...currentStory.stories,
      [field]: value,
    }

    currentStory.stories = updatedStories
    newSelected[currentStoryIndex] = currentStory

    setSelectedAccomplishment(newSelected)

    // Store update data on storage
    localStorage.setItem('selectedAccomplishments', JSON.stringify(newSelected))
  }

  // Set Accomplishment skills
  const onSkillChange = (value: string, index: number) => {
    const newSelected = [...selectedAccomplishment]
    const currentStory = newSelected[currentStoryIndex]
    const currenSkill = [...currentStory.skills]

    const newSkill = {
      ...currenSkill[index],
      text: value,
    }

    currenSkill[index] = newSkill

    // // Update stories value
    const updatedStories = {
      ...currentStory,
      skills: currenSkill,
    }

    newSelected[currentStoryIndex] = updatedStories
    setSelectedAccomplishment(newSelected)

    // Store update data on storage
    localStorage.setItem('selectedAccomplishments', JSON.stringify(newSelected))
  }

  const handleAddAccomplishment = () => {
    const payload = [
      ...accomplishmentData,
      {
        id: accomplishmentData.length + 1,
        rank: accomplishmentData.length + 1,
        isSelected: false,
        description: '',
        stories: defaultStory,
        skills: [defaultSkill],
        error: '',
      },
    ]

    setAccomplishmentData(payload)

    if (accomplishmentData.length === maxAccomplishmentsLength - 1) {
      handleSnackbar(SnackbarTypes.simple, 'You’ve reached the maximum number of entries')
    }
  }

  const handleContinue = () => {
    const newAccomplishments = accomplishmentData.filter(acc => acc.description.trim() !== '')
    const validationStatus = newAccomplishments.length >= minAccomplishment

    if (step === 1 && validationStatus) {
      handleNextStep()
    }

    if (step === 1 && !validationStatus) {
      handleSnackbar(SnackbarTypes.failure, 'Enter at least 7 accomplishments to move forward')
    }

    if (step === 2) {
      // Check At least one accomplishment select or not
      const isSelectedItem = newAccomplishments.filter(item => item.isSelected).length
      if (isSelectedItem === 0) {
        handleSnackbar(SnackbarTypes.failure, 'Select at least one accomplishment to move forward')
      } else if (isSelectedItem < 7) {
        setShowConfirmModal(true)
      } else {
        HandlePostAccomplishments()
      }
    } else if (step === 4) {
      const isAnySkillSelected = accomplishmentsSkills.filter(item => item.isSelected).length

      if (!isAnySkillSelected) {
        handleSnackbar(SnackbarTypes.failure, 'Select at least one skill to move forward')
        return
      }

      const apiPayload = localStorage.getItem('apiPayload')
      const payload = apiPayload ? JSON.parse(apiPayload) : []

      const accSkillsTexts = accomplishmentsSkills
        .filter(skill => skill.isSelected)
        .map(skill => skill.text)
        .filter((_, index) => index < MAX_SKILLS_SELECTION_LIMIT)

      payload.assessments.skills = accSkillsTexts

      dispatch(actions.setSaveAccomplishments(payload))

      handleNextStep()
    }
  }

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    const newItems = [...selectedAccomplishment]

    if (oldIndex !== null && oldIndex !== newIndex) {
      newItems.splice(newIndex, 0, newItems.splice(oldIndex, 1)[0])

      setSelectedAccomplishment(newItems)

      // Store update data on storage
      localStorage.setItem('selectedAccomplishments', JSON.stringify(newItems))
    }
  }

  const getHeaderTitle = (): HeaderProps => {
    switch (step) {
      case 1:
        return {
          title: 'Recall Your Most Satisfying Accomplishments',
          description:
            'List at least 20 personal, life, and work accomplishments that meet the above definition.',
        }
      case 2:
        return {
          title: 'Select And Rank Your Top Seven Accomplishments',
          description:
            'Choose seven accomplishments that you enjoyed the most and rank them in descending order.',
        }
      case 3:
        return {
          title: 'Build Your Seven Stories',
          description:
            'Now, let’s take a deeper dive into your top accomplishments. If the right word for skills is eluding you, please visit this reference for a list of skills.',
        }
      case 4:
        return {
          title: 'Review Your Enjoyable Skills',
          description:
            'Reflect on your seven stories and all of the enjoyable skills that you listed. Select up to 8 skills and rank them. ',
        }
      default:
        return {
          title: '',
          description: '',
        }
    }
  }

  const getPlaceHolder = (index: number) => {
    switch (index) {
      case 0:
        return '(e.g. Placed third in Nassau Bike Race)'
      case 1:
        return '(e.g. addressed my science class on climate change'
      case 2:
        return '(e.g. helping my grandparents with technology)'
      default:
        return 'Your accomplishment'
    }
  }

  const handleSortEnd = (oldIndex: number, newIndex: number) => {
    const newSkills = [...accomplishmentsSkills]
    const sortedSkill = newSkills[oldIndex]

    newSkills.splice(oldIndex, 1)
    newSkills.splice(newIndex, 0, sortedSkill)

    const sortedSkills = orderBy(newSkills, (_, index) => index, 'asc')

    // store skills
    localStorage.setItem('selectedSkills', JSON.stringify(sortedSkills))

    setAccomplishmentsSkills(sortedSkills)
  }

  const checkMaxSkillsSelection = (selectedSkills: AccSkillsType[]) => {
    const sortedSelectedSkills = selectedSkills.filter(skill => skill.isSelected)

    const isMaxSkillsSelection = sortedSelectedSkills.length === MAX_SKILLS_SELECTION_LIMIT

    if (isMaxSkillsSelection) {
      handleSnackbar(SnackbarTypes.simple, 'You’ve reached the maximum number of selections')
    }
  }

  const handleSkillSelect = (id: number, storyID: number) => {
    // Check if already selected
    const isSelected = accomplishmentsSkills.findIndex(skill => skill.id === id && skill.isSelected)

    // Check max selection
    const isSelectionAllowed =
      accomplishmentsSkills.filter(item => item.isSelected).length < MAX_SKILLS_SELECTION_LIMIT

    if (isSelectionAllowed || isSelected !== -1) {
      const updatedSkills = accomplishmentsSkills.map(skill => {
        if (skill.id === id && skill.storyID === storyID) {
          skill.isSelected = !skill.isSelected
        }

        return skill
      })

      // Order skills based on selection
      const sortedSkills = orderBy(updatedSkills, skill => !skill.isSelected, 'asc')

      setAccomplishmentsSkills(sortedSkills)

      // Store skills back to accomplishments
      const updatedStorySkills = accomplishmentsSkills.filter(
        accSkill => accSkill.storyID === storyID,
      )

      const newAccomplishments = accomplishmentData.map(newAccs => {
        if (newAccs.id === storyID) {
          const newStorySkills = updatedStorySkills.map(({ id, rank, text, isSelected, error }) => {
            // omit storyID
            return {
              id,
              rank,
              text,
              error,
              isSelected,
            }
          })

          return {
            ...newAccs,
            skills: [...newStorySkills],
          }
        }

        return newAccs
      })

      // Store updated accomplishments
      localStorage.setItem('accomplishments', JSON.stringify(newAccomplishments))

      // Store skills
      localStorage.setItem('selectedSkills', JSON.stringify(sortedSkills))

      // set updated accomplishments
      setAccomplishmentData(newAccomplishments)

      // check if max skill selection is reached
      checkMaxSkillsSelection(sortedSkills)
    }
  }

  // Check  Skill validation
  const checkSkillValidation = (isError: boolean) => {
    setIsSkillError(isError)
  }

  // Go to next accomplishment story
  const onNextStory = () => {
    const newAccomplishment = [...selectedAccomplishment]

    const currentStoryStep = currentStoryIndex + 1
    const lastStep = newAccomplishment.length - 1
    const totalDescribes = newAccomplishment.length
    const newAccomplishmentTotalSkills = filter(
      newAccomplishment[currentStoryIndex].skills,
      obj => !isEmpty(obj.text),
    )

    if (newAccomplishmentTotalSkills.length === 0) {
      window.scrollTo({
        top: document.body.scrollHeight,
      })
      checkSkillValidation(true)
    } else if (currentStoryStep === totalDescribes) {
      // Creating a final payload for API
      const accomplishments = newAccomplishment.map((item, index) => {
        const story = newAccomplishment[index].stories

        return {
          title: item.description,
          rank: index + 1,
          story_1: story.story_1,
          story_2: story.story_2,
          story_3: story.story_3,
          story_4: story.story_4,
          story_5: story.story_5,
          story_6: story.story_6,
          story_7: story.story_7,
        }
      })

      const apiPayload = {
        assessments: {
          accomplishments,
          skills: [],
        },
      }

      // Store final accomplishments
      localStorage.setItem('apiPayload', JSON.stringify(apiPayload))

      handleNextStep()

      checkSkillValidation(false)
    } else if (currentStoryStep <= lastStep) {
      checkSkillValidation(false)
      setCurrentStoryIndex(prevStep => prevStep + 1)
      window.scrollTo({
        top: 0,
      })
      localStorage.setItem('currentStoryStep', JSON.stringify(currentStoryStep + 1))
    }

    setSelectedAccomplishment(newAccomplishment)
  }

  // Go to previous  accomplishment story
  const onPrevStory = () => {
    const currentStoryStep = currentStoryIndex + 1

    checkSkillValidation(false)

    if (currentStoryIndex === 0) {
      handlePreviousStep()
    } else if (currentStoryIndex > 0) {
      setCurrentStoryIndex(prevStep => prevStep - 1)
      window.scrollTo({
        top: 0,
      })
      localStorage.setItem('currentStoryStep', JSON.stringify(currentStoryStep - 1))
    }
  }

  // Add new Skill
  const onAddNewSkill = () => {
    const newselectedAccomplishment = [...selectedAccomplishment]
    const newSkills = [...(newselectedAccomplishment[currentStoryIndex]?.skills || [])]

    if (newselectedAccomplishment[currentStoryIndex].skills[0].text === '') {
      checkSkillValidation(true)
    } else {
      checkSkillValidation(false)

      newSkills.push({
        id: newSkills.length + 1,
        rank: newSkills.length + 1,
        isSelected: false,
        text: '',
      })

      const newAccomplishment = {
        ...newselectedAccomplishment[currentStoryIndex],
        skills: newSkills,
      }
      newselectedAccomplishment[currentStoryIndex] = newAccomplishment

      if (newSkills.length === MAX_SKILLS_SELECTED) {
        handleSnackbar(SnackbarTypes.simple, 'You’ve reached the maximum number of entries')
      }
    }

    setSelectedAccomplishment(newselectedAccomplishment)
    localStorage.setItem('selectedAccomplishments', JSON.stringify(newselectedAccomplishment))
  }

  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false)
  }

  const handleSubmitConfirmModal = () => {
    setShowConfirmModal(false)
    HandlePostAccomplishments()
  }

  const getThirdStepProgress = (storyNumber: number, totalStory: number) => {
    const total = Math.trunc(25 / totalStory)
    switch (storyNumber) {
      case 1:
        return 50
      case 2:
        return 50 + total
      case 3:
        return 50 + total * 2
      case 4:
        return 50 + total * 3
      case 5:
        return 50 + total * 4
      case 6:
        return 50 + total * 5
      case 7:
        return 50 + total * 6
      default:
        return 0
    }
  }

  const getProgress = (storyNumber?: number, totalStory?: number) => {
    switch (step) {
      case 1:
        return 0
      case 2:
        return 25
      case 3:
        const thirdStepProgress = getThirdStepProgress(Number(storyNumber), Number(totalStory))

        return thirdStepProgress
      case 4:
        return 75
    }
  }

  const handleShowStory = (id: number) => {
    setShowStoryId(id)

    trackInternalEvent('click_open_story', {
      assessment_name: 'Seven Stories',
      story_id: id,
      ...label,
    })
  }

  return {
    onNextStory,
    onPrevStory,
    currentStoryIndex,
    step,
    getPlaceHolder,
    handleContinue,
    getHeaderTitle,
    handleNextStep,
    isMaxSelection,
    handleSortEnd,
    handleSkillSelect,
    handlePreviousStep,
    accomplishmentData,
    accomplishmentsSkills,
    handleAddAccomplishment,
    maxAccomplishmentsLength,
    handleAccomplishmentChange,
    handleAccomplishmentSelect,
    onSortEnd,
    selectedAccomplishment,
    onAddNewSkill,
    showConfirmModal,
    handleCloseConfirmModal,
    handleSubmitConfirmModal,
    onStoryChange,
    onSkillChange,
    isSkillError,
    getProgress,
    showStoryId,
    handleShowStory,
  }
}
