import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import CareerEntry from 'builder/components/CareerEntryTest/components/CareerEntry/CareerEntry'
import EntryFocus from 'builder/components/CareerEntryTest/components/CareerEntryFocus/EntryFocus'
import CareerStage from 'builder/components/CareerEntryTest/components/CareerEntryStage/CareerStage'
import { JobFunction } from 'builder/components/CareerEntryTest/components/CareerEntryJobFunction'
import { Loader } from 'builder/components/CareerCoachingSessions/components/Loader'
import { ButtonTheme } from 'builder/components/Button'
import Location from './components/CareerLocation/Location'
import { AvailableDays } from './components/CareerDay/AvailableDays'
import AvailableTime from './components/CareerTiming/AvailableTime'
import Focus from './components/CareerFocusArea/Focus'
import IntakeTest from './components/CompleteIntakeTest/IntakeTest'
import { CareerIndustries } from './components/CareerIndustries'
import {
  FooterContainer,
  Container,
  RightActionContainer,
  SkipButton,
  ButtonContainer,
} from './styles'
import { useEntryTest } from './hooks/useEntryTest'
import { CareerProgressBar } from './components/CareerProgressBar'
import { CareerLinkedInProfile } from './components/CareerLinkedInProfile'
import { BackButton } from './components/BackButton'
import { ContinueButton } from './components/ContinueButton'

const EntryTest = () => {
  const {
    page,
    form,
    continueNext,
    handlePrev,
    onLocationhandleChange,
    onEntryFocusChange,
    onSpecificAreasFocusChange,
    onCurrentStageChange,
    onYourJobFunctionChange,
    handleDaysChange,
    handleAvailableTimesChange,
    onTargetingIndustriesChange,
    onHandleTimeZoneChange,
    onHandleLocationChange,
    handleEditIntakeTest,
    onSkipPage,
    intakeProgress,
    onIntakeSubmit,
    isFetchIntakeTestLoading,
    onHandleLinkedinUrlChange,
    isExecutive,
  } = useEntryTest()
  const { features } = useFeaturesConfig()

  const renderNormalIntakeTest = () => {
    switch (page) {
      case 0: {
        return <CareerEntry {...{ continueNext }} />
      }
      case 1: {
        return (
          <CareerStage
            {...{ onCurrentStageChange }}
            careerStages={form?.preferences?.careerStages}
          />
        )
      }
      case 2: {
        return <EntryFocus {...{ onEntryFocusChange }} services={form?.preferences?.services} />
      }
      case 3: {
        return (
          <Focus
            {...{ onSkipPage, onSpecificAreasFocusChange }}
            focusTopic={form?.preferences?.focusTopic}
          />
        )
      }
      case 4: {
        return (
          <JobFunction
            {...{ onYourJobFunctionChange }}
            jobFunction={form?.preferences?.jobFunctions}
          />
        )
      }
      case 5: {
        return <CareerIndustries {...{ onTargetingIndustriesChange }} />
      }
      case 6: {
        return (
          <Location
            {...{
              onHandleTimeZoneChange,
              onHandleLocationChange,
              onLocationhandleChange,
            }}
            location={form.preferences.location}
            timezone={form.preferences.timezone}
          />
        )
      }
      case 7: {
        return (
          <AvailableDays
            {...{ handleDaysChange }}
            availableDays={form?.preferences?.availableDays}
          />
        )
      }
      case 8: {
        return (
          <AvailableTime
            {...{ handleAvailableTimesChange }}
            availableTimes={form?.preferences?.availableTimes}
          />
        )
      }
      case 9: {
        return (
          <IntakeTest
            formData={form}
            handleEdit={handleEditIntakeTest}
            onIntakeSubmit={onIntakeSubmit}
          />
        )
      }
      default:
        return null
    }
  }

  const renderExecutiveIntakeTest = () => {
    switch (page) {
      case 0: {
        return <CareerEntry {...{ continueNext }} />
      }
      case 1: {
        return (
          <CareerStage
            {...{ onCurrentStageChange }}
            careerStages={form?.preferences?.careerStages}
          />
        )
      }
      case 2: {
        return (
          <Focus
            {...{ onSkipPage, onSpecificAreasFocusChange, isExecutive }}
            focusTopic={form?.preferences?.focusTopic}
          />
        )
      }
      case 3: {
        return (
          <CareerLinkedInProfile
            {...{ onHandleLinkedinUrlChange }}
            linkedinUrl={form?.preferences?.linkedinUrl}
          />
        )
      }
      case 4: {
        return (
          <IntakeTest
            formData={form}
            handleEdit={handleEditIntakeTest}
            onIntakeSubmit={onIntakeSubmit}
            isExecutive={isExecutive}
          />
        )
      }
      default:
        return null
    }
  }

  const renderFooterActions = () => {
    const isActionsButtonVisible = (!isExecutive && page !== 9) || (isExecutive && page !== 4)
    const isSkipPage = page === 3
    return (
      <>
        {page !== 0 && (
          <FooterContainer isLocalization={!(features.international || features.superApp)}>
            {isActionsButtonVisible && (
              <ButtonContainer>
                <BackButton {...{ handlePrev }} />
                <RightActionContainer>
                  {!isExecutive && isSkipPage && (
                    <SkipButton theme={ButtonTheme.ghost} onClick={onSkipPage}>
                      Skip
                    </SkipButton>
                  )}
                  <ContinueButton {...{ continueNext }} />
                </RightActionContainer>
              </ButtonContainer>
            )}

            {page !== 0 && !isFetchIntakeTestLoading && (
              <CareerProgressBar {...{ intakeProgress }} />
            )}
          </FooterContainer>
        )}
      </>
    )
  }

  return (
    <Container {...{ page }}>
      {isFetchIntakeTestLoading ? (
        <Loader />
      ) : isExecutive ? (
        renderExecutiveIntakeTest()
      ) : (
        renderNormalIntakeTest()
      )}
      {!isFetchIntakeTestLoading && renderFooterActions()}
    </Container>
  )
}

export default EntryTest
