import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { ButtonTheme } from 'builder/components/Button'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import {
  Label,
  actions,
  selectors,
  CareerPathStep,
  JobProgressionsPath,
} from 'builder/modules/careerPath2'
import {
  Text,
  AddIcon,
  Heading,
  LeftArrow,
  StyledButton,
  ArrowContainer,
  HeaderContainer,
} from './styles'

type LevelType = null | number | undefined

const AddToPath = () => {
  const dispatch = useDispatch()
  const { isPhone, isLaptop } = useMediaQueries()

  const addToPathLevel = useTypedSelector(selectors.addToPathLevel)
  const level1JobTitles = useTypedSelector(selectors.level1JobTitles)
  const level2JobTitles = useTypedSelector(selectors.level2JobTitles)
  const level3JobTitles = useTypedSelector(selectors.level3JobTitles)
  const skillsMatchScore = useTypedSelector(selectors.skillsMatchScore)
  const addToPathJobTitle = useTypedSelector(selectors.addToPathJobTitle)
  const isExploreCareers = useTypedSelector(selectors.isExploreCareers)

  const handleClick = useCallback(() => {
    if (isExploreCareers) {
      dispatch(actions.setStep(CareerPathStep.QUESTIONNAIRE))
    } else {
      dispatch(actions.setStep(CareerPathStep.JOB_PROGRESSION))

      trackInternalEvent('click_career_details_page_back_button', {
        ...Label,
      })
    }
  }, [dispatch, isExploreCareers])

  const fetchJobTitles = useCallback(
    (level: number) => {
      if (addToPathJobTitle) {
        const params = {
          level,
          query: addToPathJobTitle,
        }

        dispatch(actions.fetchJobTitles(params))
        dispatch(actions.setStep(CareerPathStep.JOB_PROGRESSION))
      }
    },
    [dispatch, addToPathJobTitle],
  )

  const addSorting = useCallback(
    (clickedTitle: string | undefined, level: number) => {
      switch (level) {
        case 1: {
          const index = level1JobTitles.findIndex(jobTitle => jobTitle?.target === clickedTitle)
          if (index > -1) {
            let level1JobTitleMap: JobProgressionsPath[] = [level1JobTitles[index]]
            level1JobTitles.map((val, mapIndex: number) => {
              if (mapIndex !== index) {
                level1JobTitleMap.push(val)
              }
              return val
            })

            dispatch(actions.setLevel1JobTitles(level1JobTitleMap))
          }
          break
        }
        case 2: {
          const index = level2JobTitles.findIndex(jobTitle => jobTitle?.target === clickedTitle)
          if (index > -1) {
            let level2JobTitleMap: JobProgressionsPath[] = [level2JobTitles[index]]
            level2JobTitles.map((val, mapIndex: number) => {
              if (mapIndex !== index) {
                level2JobTitleMap.push(val)
              }
              return val
            })

            dispatch(actions.setLevel2JobTitles(level2JobTitleMap))
          }
          break
        }
        case 3: {
          const index = level3JobTitles.findIndex(jobTitle => jobTitle?.target === clickedTitle)
          if (index > -1) {
            let level3JobTitleMap: JobProgressionsPath[] = [level3JobTitles[index]]
            level3JobTitles.map((val, mapIndex: number) => {
              if (mapIndex !== index) {
                level3JobTitleMap.push(val)
              }
              return val
            })

            dispatch(actions.setLevel3JobTitles(level3JobTitleMap))
          }
          break
        }
      }
    },
    [dispatch, level1JobTitles, level2JobTitles, level3JobTitles],
  )

  const setSelectedLevelJobTitleIndex = useCallback(
    (level: LevelType) => {
      switch (level) {
        case 1:
          dispatch(actions.setSelectedLevel1JobTitleIndex(0))
          break
        case 2:
          dispatch(actions.setSelectedLevel2JobTitleIndex(0))
          break
        case 3:
          dispatch(actions.setSelectedLevel3JobTitleIndex(0))
          break
      }
    },
    [dispatch],
  )

  const handleAddToPath = useCallback(() => {
    let jobLevel: number
    const data = {
      title: addToPathJobTitle,
      score: skillsMatchScore,
    }
    switch (addToPathLevel) {
      case 1: {
        jobLevel = 1

        dispatch(actions.setCareerPath1Level1(data))
        addSorting(addToPathJobTitle, jobLevel)
        setSelectedLevelJobTitleIndex(jobLevel)

        fetchJobTitles(jobLevel)
        break
      }
      case 2: {
        jobLevel = 2

        dispatch(actions.setCareerPath1Level2(data))
        addSorting(addToPathJobTitle, jobLevel)
        setSelectedLevelJobTitleIndex(jobLevel)

        fetchJobTitles(jobLevel)
        break
      }
      case 3: {
        jobLevel = 3

        dispatch(actions.setCareerPath1Level3(data))
        addSorting(addToPathJobTitle, jobLevel)
        setSelectedLevelJobTitleIndex(jobLevel)

        dispatch(actions.setIsCareerPath1Set(true))
        dispatch(actions.setStep(CareerPathStep.JOB_PROGRESSION))
        break
      }
    }
    trackInternalEvent('click_add_path_on_job_detailed_page', {
      ...Label,
      title: addToPathJobTitle,
    })
  }, [
    dispatch,
    addSorting,
    addToPathLevel,
    fetchJobTitles,
    skillsMatchScore,
    addToPathJobTitle,
    setSelectedLevelJobTitleIndex,
  ])

  return (
    <HeaderContainer>
      <ArrowContainer onClick={handleClick}>
        <LeftArrow />
        {!isPhone && <Text>Back</Text>}
      </ArrowContainer>
      <Heading>{addToPathJobTitle}</Heading>
      {!isExploreCareers ? (
        <StyledButton
          onClick={handleAddToPath}
          theme={isPhone ? ButtonTheme.default : ButtonTheme.ghost}
        >
          {isLaptop && !isPhone ? 'Add' : 'Add to Path'}
          <AddIcon />
        </StyledButton>
      ) : null}
    </HeaderContainer>
  )
}

export default AddToPath
