export const FAQS = [
  {
    id: 1,
    question: 'What if I already have career.io subscription?',
    answer:
      "If you already have a Career.io subscriptionб we can freeze your payments for one, two, or three months, ensuring that you won't be charged during that period. However, please note that once the complimentary subscription ends, it will automatically renew to the regular pricing plan. For the monthly plan, the renewal price will be $24.95 per month. Alternatively, if you have the half-year plan, the renewal price will be $44.95, and for the annual plan, it will be $74.95. This ensures a seamless continuation of your subscription with Career.io. ",
  },
  {
    id: 2,
    question: 'What if I need to reschedule my coaching session?',
    answer:
      'Once you’ve booked a coaching session it will appear on your dashboard. On your dashboard use the “Message a Coach” option to contact your coach directly for rescheduling.',
  },
  {
    id: 3,
    question: 'What if my coach is not available when I am?',
    answer:
      'We understand the importance of finding a coach who can accommodate your schedule. During the intake process, we asked you to provide your preferred session times and days. Rest assured that after payment, you will only see coaches who are available during the time slots you indicated. However, in the unlikely event that your preferred coach is unavailable during your desired session times, our platform has a backup system in place. We will suggest alternative coaches who are available within 24 hours, ensuring that you can still have timely sessions and receive the support you need.',
  },
  {
    id: 4,
    question: 'What is the best way to prepare for my session?',
    answer:
      'Remember, coaching is a collaborative process, and your active involvement is crucial to its success. By preparing beforehand and actively engaging during the session, you can make the most of your coaching experience and achieve your desired outcomes. To prepare for your coaching session: 1. Reflect on your goals and objectives. 2. Come with specific challenges or questions. 3. Be open, engaged, and take notes during the session.',
  },
]
