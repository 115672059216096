import { Resume } from '@rio/types'
import { i18n } from 'builder/utils/i18n'
import { CheckFn, Task, TaskIds, Report } from '../types'
import { MIN_SCORE } from '../constants'
import * as checks from '../checks'

interface RawTask extends Pick<Task, 'id' | 'score'> {
  checks: CheckFn[]
}

/**
 * Validates given resume data
 * and returns tasks list, including their completion state and validation errors
 */
const getResumeTasks = (resume: Resume | null): Task[] => {
  if (!resume) return []

  const rawTasks: RawTask[] = [
    {
      id: TaskIds.contacts,
      checks: [checks.checkEmail, checks.checkPhone, checks.checkLinks],
      score: 2,
    },
    {
      id: TaskIds.details,
      checks: [checks.checkJobTitle, checks.checkWork, checks.checkEducation, checks.checkSkills],
      score: 2,
    },
    {
      id: TaskIds.summary,
      checks: [checks.checkSummaryLength, checks.checkSummaryContent],
      score: 3,
    },
  ]

  return rawTasks.map(({ checks, ...task }) => {
    const issues = checks.map(check => check(resume)).map(issue => ({ ...issue, taskId: task.id }))

    return {
      ...task,
      title: i18n.t(`builder.resume_tuner.tasks.${task.id}.title`),
      text: i18n.t(`builder.resume_tuner.tasks.${task.id}.text`),
      issues,
      done: issues.length === issues.filter(issue => issue.progress === 1).length,
    }
  })
}

/** Performs resume data analysis, returns tuner tasks and resume score */
export const analyzeResume = (resume: Resume | null): Report => {
  const tasks = getResumeTasks(resume)

  return {
    tasks,
    score: MIN_SCORE + tasks.filter(task => task.done).reduce((sum, task) => sum + task.score, 0),
  }
}
