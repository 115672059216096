import { Fragment, useEffect, useState } from 'react'
import * as Styles from './styles'

const texts = [
  'Reviewing job postings…',
  'Analyzing the job demand…',
  'Getting salary information…',
  'Building Available Paths...',
]

const CHANGE_TEXT_DELAY = 2500

const isPrevious = (index: number, currentId: number) =>
  currentId === 0 ? index === texts.length - 1 : index === currentId - 1

export const Process = () => {
  const [currentId, setCurrentId] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      // stop at last text
      if (currentId <= texts.length - 2) {
        setCurrentId(currentId => (currentId + 1 >= texts.length ? 0 : currentId + 1))
      }
    }, CHANGE_TEXT_DELAY)

    return () => {
      interval && clearInterval(interval)
    }
  }, [currentId])

  return (
    <Fragment>
      <Styles.ShadowContainer />
      <Styles.ProcessWrapper>
        <Styles.SpinnerWrapper>
          <Styles.SpinnerContainer />
        </Styles.SpinnerWrapper>
        <Styles.TextWrapper>
          {texts.map((text, index) => (
            <Styles.AnimatedText
              key={text}
              isActive={index === currentId}
              isPrevious={isPrevious(index, currentId)}
            >
              {text}
            </Styles.AnimatedText>
          ))}
        </Styles.TextWrapper>
      </Styles.ProcessWrapper>
    </Fragment>
  )
}
