import { FC } from 'react'
import { useI18n } from 'builder/hooks/useI18n'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import {
  ProgressbarBody,
  ProgressbarContainer,
  ProgressbarPercent,
  ProgressbarHead,
  ProgressbarTextContainer,
  CompleteIcon,
} from './styles'

export const Progressbar: FC<{
  percent: number
}> = ({ percent }) => {
  const { i18n } = useI18n()
  const roundedPercent = Math.round(percent)
  const { features } = useFeaturesConfig()

  return (
    <ProgressbarContainer $isDashV2={features.international || features.superApp}>
      <ProgressbarHead>
        <CompleteIcon />
        <ProgressbarTextContainer>
          <ProgressbarPercent>{roundedPercent} %</ProgressbarPercent>
          {i18n.t('builder.career_profile.chat.progressbar.completed')}
        </ProgressbarTextContainer>
      </ProgressbarHead>
      <ProgressbarBody $progress={roundedPercent} />
    </ProgressbarContainer>
  )
}
