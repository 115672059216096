import { ComponentPropsWithoutRef, ForwardedRef, forwardRef } from 'react'
import { Icon, StyledButton } from './styles'

type ButtonProps = {
  isActive?: boolean
}

type Props = ButtonProps & Omit<ComponentPropsWithoutRef<'button'>, keyof ButtonProps>

type Ref = ForwardedRef<HTMLButtonElement>

export const AiSuggestionsButton = forwardRef((props: Props, ref: Ref) => {
  const { isActive, ...rest } = props

  return (
    <StyledButton $isActive={isActive} ref={ref} {...rest}>
      <Icon />
    </StyledButton>
  )
})
