import { useState } from 'react'
import Foldable from 'builder/components/Foldable'
import { Text, ReadMore, ArrowDown, ContentWrapper } from '../../styles'

const Content = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <ContentWrapper>
      <Text>
        Deciding what you want in your career starts with understanding your most satisfying
        accomplishments and the skills you enjoy using. This widely-admired assessment will help you
        to develop (or course correct) your career direction and find the work you were meant to do.
      </Text>
      {!isOpen && (
        <ReadMore onClick={() => setIsOpen(true)}>
          <Text>Read more </Text>
          <ArrowDown />
        </ReadMore>
      )}
      <Foldable isOpen={isOpen}>
        <Text>
          This technique for identifying your enjoyable accomplishments has its roots in the work of
          Bernard Haldane, who helped military personnel transition their skills to civilian life
          after WWII. Its overwhelming success won the attention of Harvard Business School where it
          went on to become a significant part of its Manual for Alumni Placement. Today, it is
          known globally and utilized at top colleges & universities and in the private sector.
        </Text>
      </Foldable>
    </ContentWrapper>
  )
}

export default Content
