import styled from 'styled-components'
import Shadows from 'builder/styles/shadows'
import DocumentImagePreview from 'builder/components/DocumentImagePreview'

export const Container = styled.div`
  padding: 32px;
`

export const ImagePreview = styled(DocumentImagePreview)`
  ${Shadows.template.high};
  border-radius: 3px;
`
