import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Button from 'builder/components/Button'
import { Icon20 } from 'builder/components/Icon'

export const Container = styled.div<{ daysLeftPremium: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-right: ${props => (props.daysLeftPremium < 1 ? '-4px' : '12px')};
`

export const DaysCountButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px 4px 4px;
  gap: 6px;
  flex-shrink: 0;
  border-radius: 32px;
  background: ${Colors.Indigo10};
`

export const CrownIconContainer = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 3px solid ${Colors.Indigo30};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CrownIcon = styled(Icon20.Crown)`
  color: ${Colors.Indigo80};
`

export const TrailDaysText = styled.div`
  ${Typography.Caption};
  ${FontWeights.DemiBold};
  color: ${Colors.Indigo80};
`
export const RenewButton = styled(Button)`
  ${Typography.Caption};
  ${FontWeights.DemiBold};
  color: ${Colors.White};
  padding: 7px 12px;
  border-radius: 32px;
  background: ${Colors.Indigo80};

  &:hover {
    background: ${Colors.Indigo90};
  }
`
