import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Checkbox from 'builder/components/Checkbox'
import Shadows from 'builder/styles/shadows'

export const Container = styled.div`
  width: 100%;
  background-color: ${Colors.White};
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`

export const Content = styled.div`
  gap: 32px;
  display: flex;
  flex-direction: column;
  text-align: center;
`

export const TopContent = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`

export const Header = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};
  width: 100%;
  text-align: center;
`

export const Form = styled.form``

export const Options = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`

export const EntryFocusCheckbox = styled(Checkbox)`
  input[type='checkbox'] {
    border-color: ${Colors.Neutral30};
    transition: border ease-in 0.2s;

    &:checked {
      border-color: ${Colors.Blue50};
    }
  }
`

export const Option = styled.label<{ checked: boolean }>`
  width: 100%;
  border-radius: 8px;
  padding: ${props => (props.checked ? `8px 15px` : `9px 16px`)};
  border: ${props =>
    props.checked ? `2px solid ${Colors.Blue50}` : `1px solid ${Colors.Neutral15}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  cursor: pointer;
  transition: shadow ease-in 0.3s;

  &:hover {
    ${Shadows.lightWithBorder.low};

    ${EntryFocusCheckbox} {
      input[type='checkbox'] {
        border-color: ${Colors.Blue50};
      }
    }
  }
`

export const OptionContent = styled.div`
  gap: 1px;
  display: flex;
  align-items: center;
  flex: 1 0 0;

  ${Media.Phone`
    gap:2px;
 `};
`

export const OptionContentBox = styled.div``

export const SubLabel = styled.div`
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  letter-spacing: 0.2px;
`
