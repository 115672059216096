import { stringifyUrl } from 'query-string'
import { UserDocument, UserDocumentTemplateId } from '@rio/types'
import { DPR } from 'builder/modules/constants'
import { hasWebpSupport } from './hasWebpSupport'

type Format = 'png' | 'jpg' | 'webp'

type Options = {
  page?: number
  format?: Format
  size?: number
  template?: UserDocumentTemplateId
  color?: string
}

type Document = Pick<UserDocument, 'renderingToken' | 'version'>

export function formatDocumentPreviewUrl(document: Document, options: Options = {}): string {
  const { renderingToken, version } = document
  const {
    page = 1,
    // SSR renders 600px image by default
    size,
    // Use to WebP is supported
    format = hasWebpSupport() ? 'webp' : 'jpg',
    ...rest
  } = options

  return stringifyUrl({
    url: `${process.env.SSR_HOST}/to-image/${renderingToken}-${page}.${format}`,
    query: {
      cache: version,
      size: size ? size * DPR : undefined,
      ...rest,
    },
  })
}
