import PropTypes from 'prop-types'
import { useI18n } from 'builder/hooks/useI18n'

import { Container, Text, DismissButton } from './styles'

const CancelableSnackBar = ({ text, onDismiss, isShaking }) => {
  const { i18n } = useI18n()

  return (
    <Container isShaking={isShaking}>
      <Text>{text}</Text>
      <DismissButton onClick={onDismiss}>{i18n.t('builder.resume_editor.dismiss')}</DismissButton>
    </Container>
  )
}

CancelableSnackBar.propTypes = {
  text: PropTypes.string,
  onDismiss: PropTypes.func,
  isShaking: PropTypes.bool,
}

CancelableSnackBar.defaultProps = {
  onDismiss: () => {},
}

export default CancelableSnackBar
