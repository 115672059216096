import styled from 'styled-components'
import Media from 'builder/styles/media'

export const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: ${({ fullScreen }) => (fullScreen ? '0' : '32px 40px')};
  z-index: 12000;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition-property: opacity;
  transition-timing-function: ease-out;
  transition-duration: ${props => `${props.duration}ms`};
  background: ${props => {
    return props.background ? props.background : `rgba(15, 20, 31, ${props.backgroundOpacity})`
  }};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  ${Media.Phone`
    padding: ${({ fullScreen }) => (fullScreen ? '0' : '16px')};
  `};
`

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
  transform: ${props => (props.isVisible ? 'none' : 'translateY(-100px)')};
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition-property: opacity, transform;
  transition-timing-function: ease-out;
  transition-duration: ${props => `${props.duration}ms`};

  ${({ fullScreen }) =>
    fullScreen &&
    `
    height: 100%;
  `}
`
