import styled from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Shadows from 'builder/styles/shadows'

export const Container = styled.div`
  display: grid;
  padding: 56px 32px;
  grid-gap: 6px;
  grid-template-columns: repeat(3, 1fr);
`

export const Tile = styled.div<{ color: string }>`
  height: 48px;
  border-radius: 8px;
  background-color: ${props => props.color};
`

export const Plate = styled.div`
  position: absolute;
  left: 80px;
  right: 80px;
  top: 40px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  box-shadow: 0px 24px 40px ${hexToRgba(Colors.Blue50, 0.24)},
    inset 0 -1px 0 0 ${hexToRgba(Colors.Blue70, 0.16)}, inset 0 1px 0 0 ${Colors.White};
  background-color: ${hexToRgba(Colors.Blue20, 0.5)};
  backdrop-filter: blur(8px);
`

export const Photo = styled.img`
  ${Shadows.light.low};
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${Colors.White};
`
