import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

const flexColumn = css`
  display: flex;
  flex-flow: column;
`

export const Body = styled.div`
  ${flexColumn};
  gap: 24px;

  ${Media.Phone`
  `}
`

export const MainSection = styled.div`
  ${flexColumn};
  gap: 8px;
`

export const HeaderWrapper = styled.div`
  ${flexColumn};
  gap: 12px;
`

export const PremiumBadge = styled.div`
  display: flex;
  gap: 4px;
  background-color: ${Colors.Indigo10};
  color: ${Colors.Indigo70};
  border-radius: 40px;
  align-self: flex-start;
  align-items: center;
  padding: 4px 12px 4px 8px;
`

export const HeaderText = styled.div`
  ${Typography.S};
  ${FontWeights.DemiBold};
`

export const MainText = styled.div`
  color: ${Colors.Neutral50};
`

export const UnlocksSection = styled(MainSection)`
  color: ${Colors.Neutral80};
`

export const UnlocksHeader = styled.div`
  ${Typography.Caps};
  ${FontWeights.DemiBold};
`

export const UnlocksList = styled.div`
  ${flexColumn};
  padding: 12px;
  gap: 8px;
  background-color: ${Colors.Neutral5};
`

export const UnlocksItem = styled.div`
  display: flex;
  gap: 4px;
  ${Typography.Caption};
`
