import {
  ChartOptions,
  ChartTypeRegistry,
  ScriptableContext,
  ScriptableScaleContext,
} from 'chart.js'
import Colors, { hexToRgba } from 'builder/styles/colors'
import { matchMediaQueries } from 'builder/utils/matchMediaQueries'

export const getOptions = (maxY: number): ChartOptions<keyof ChartTypeRegistry> => {
  const fontAxes = {
    font: {
      size: 15,
      family: 'TT Commons',
    },
  }

  const axisYMax = Math.round(maxY + maxY * 0.15)

  return {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          color: Colors.Neutral40,
          ...fontAxes,
        },
        grid: {
          display: false,
        },
      },
      y: {
        max: axisYMax,
        ticks: {
          ...fontAxes,
          includeBounds: false,
          color: Colors.Neutral40,
          padding: 8,
        },
        grid: {
          display: true,
          borderDash: [4, 4],
          color: (context: ScriptableScaleContext) =>
            context.index === 0 || context.tick.value === axisYMax
              ? 'transparent'
              : Colors.Neutral20,
          drawTicks: false,
        },
      },
    },
  }
}

export const getDatasetOptions = ({ isTablet }: ReturnType<typeof matchMediaQueries>) => {
  const fadeCoefficient = isTablet ? 2 : 2.5

  return {
    fill: true,
    borderColor: hexToRgba(Colors.Blue50),
    backgroundColor: (context: ScriptableContext<keyof ChartTypeRegistry>) => {
      const ctx = context.chart.ctx
      const gradient = ctx.createLinearGradient(
        0,
        0,
        0,
        Math.round(ctx.canvas.height / fadeCoefficient),
      )
      gradient.addColorStop(0, hexToRgba(Colors.Blue40, 0.5))
      gradient.addColorStop(1, hexToRgba(Colors.Blue40, 0))
      return gradient
    },
  }
}
