import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import image from './images/pitch-preview-background.png'

export const Container = styled.div`
  --message-border: 1px solid ${Colors.Neutral15};

  user-select: none;
  padding: 32px 32px 0;
  width: 100%;
  height: 280px;
  overflow: hidden;
  background-color: ${Colors.Neutral5};

  ${Media.Phone`
    padding: 24px 24px 0;
  `}
`

export const Content = styled.div`
  max-height: 100%;
  max-width: 405px;
  display: flex;
  gap: 24px;
  position: relative;
  margin: auto;

  &::before {
    position: absolute;
    content: '';
    background-image: url(${image});
    top: 30px;
    left: -90px;
    transform: rotate(-150deg);
    background-size: cover;
    width: 369.85px;
    height: 317.61px;
    opacity: 0.7;
  }
`

export const Avatar = styled.img`
  --avatar-size: 60px;

  height: var(--avatar-size);
  flex: 0 0 var(--avatar-size);
  border-radius: 50%;
  z-index: 1;
  margin-top: 11px;

  ${Media.Phone`
    --avatar-size: 36px;
  `}
`

export const MessageContainer = styled.div`
  flex: auto;
  z-index: 1;
  position: relative;

  // bubble arrow
  &::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0;
    top: 30px;
    border-left: var(--message-border);
    border-bottom: var(--message-border);
    background-color: ${Colors.White};
    transform: translateX(-50%) rotate(45deg);
  }

  // bottom gradient
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 72px;
    background: linear-gradient(180deg, transparent 0%, ${Colors.Neutral5} 43.23%);

    ${Media.Phone`
      height: 44px;
    `}
  }
`

export const Message = styled.div`
  --padding: 32px;

  padding: var(--padding) var(--padding) 0;
  border: var(--message-border);
  border-radius: 16px;
  background-color: ${Colors.White};
  height: 100%;
  overflow: hidden;
  color: ${Colors.Neutral60};
  white-space: pre-wrap;

  ${Media.Phone`
    --padding: 24px;
  `}
`
