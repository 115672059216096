import Loader from '../../../Loader'
import { Container, Heading, Description } from './styles'

type Props = {
  description: string | null
  isSkeleton: boolean
}

const RoleDescription = ({ description, isSkeleton }: Props) => {
  return (
    <Container>
      <Heading>Role Description</Heading>
      {isSkeleton ? <Loader /> : <Description>{description}</Description>}
    </Container>
  )
}

export default RoleDescription
