import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 12px 0 12px;
`

export const Button = styled.div<{ isActive?: boolean; isDisabled?: boolean }>`
  width: 24px;
  height: 24px;
  padding: 0;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.Neutral70};
  background-color: ${Colors.Neutral10};

  &:hover {
    color: ${Colors.Blue60};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${Colors.Blue60};
    `};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: ${Colors.Neutral40};
      pointer-events: none;
    `};
`

export const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 12px;
`

export const Right = styled.div`
  display: flex;
  align-items: center;
`

export const Divider = styled.div`
  height: 20px;
  width: 1px;
  margin: 0 10px;
  background-color: ${Colors.Neutral30};
`
