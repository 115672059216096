import styled, { keyframes, css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div<{ score: number }>`
  display: flex;
  padding: 2px 10px 2px 6px;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background: ${props =>
    props.score <= 30
      ? Colors.Red50
      : props.score > 30 && props.score <= 70
      ? Colors.Amber40
      : Colors.Green40};
  ${Typography.Body};
  ${FontWeights.DemiBold};
  color: ${Colors.White};
`

export const ScoreValue = styled.div<{ score: number }>`
  ${Typography.Body};
  ${FontWeights.DemiBold};
  color: ${Colors.White};
  background: ${props =>
    props.score <= 30
      ? Colors.Red40
      : props.score > 30 && props.score <= 70
      ? Colors.Amber40
      : Colors.Green40};
  &:after {
    content: '%';
    ${Typography.Caption};
  }
  border-radius: 6px;
  padding: 2px 6px;
  width: 42px;
  height: 27px;
  align-items: center;
  justify-content: center;
`

const gradientTransition = keyframes`
  0% {
    background-position: 0% 50%;
  }
  25% {
    background-position: 100% 50%;
  }
  50% {
    background-position: 0% 50%;
  }
  75% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`

export const LoadingContainer = styled.div`
  width: 48px;
  height: 32px;
  font-size: 30px;
  text-align: center;
  line-height: 100px;
  color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  background: linear-gradient(
    114deg,
    #fb4458,
    #ec930c,
    #d0e205,
    #e6d626,
    #f1b711,
    #ed990d,
    #ed990d
  );
  background-size: 900% 900%;
  animation: ${gradientTransition} 7s infinite;
  position: relative;
`

export const DotsContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 120px;
  height: 20px;
  display: flex;
  justify-content: center;
  justify-items: flex-end;
  gap: 4px;
  align-items: center;
  transform: translate(-50%, -50%);
  padding-bottom: 6px;
`

const dot1 = keyframes`
  0%{
    transform: translateY(0px) ;
  }
  16.6%{
    transform: translateY(3px);
  }
  33.3% {
    transform: translateY(6px);
  }
  49.9% {
    transform: translateY(3px);
  }
  66.6% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0px);
  }
`

const dot2 = keyframes`
  0%{
    transform: translateY(0px);
  }
  16.6%{
    transform: translateY(0px);
  }
  33.3% {
    transform: translateY(3px);
  }
  49.9% {
    transform: translateY(6px);
  }
  66.6% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(0px);
  }
`

const dot3 = keyframes`
  0%{
    transform: translateY(0px);
  }
  16.6%{
    transform: translateY(0px);
  }
  33.3% {
    transform: translateY(0px);
  }
  49.9% {
    transform: translateY(3px);
  }
  66.6% {
    transform: translateY(6px);
  }
  100% {
    transform: translateY(3px);
  }
`

const animationStyles = css`
  &:nth-child(1) {
    animation: ${dot1} 1s infinite;
  }

  &:nth-child(2) {
    animation: ${dot2} 1s infinite;
  }

  &:nth-child(3) {
    animation: ${dot3} 1s infinite;
  }
`

export const Dot = styled.span`
  width: 6px;
  height: 6px;
  background-color: ${Colors.White};
  border-radius: 50%;
  transform: all cubic-bezier(0.06, 1.31, 0.6, 0.76);
  ${animationStyles}
`
