import styled from 'styled-components'
import Sizes from 'builder/styles/sizes'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const GetExtensionButton = styled.div`
  display: flex;
  gap: ${Sizes['3XS']};
  color: ${Colors.Neutral90};
  font-weight: ${FontWeights.Regular};
  ${Typography.Body};
  align-items: center;
  background: ${Colors.White};
  padding: 8px 0;
  border-radius: 12px;

  cursor: pointer;
  & svg {
    height: 24px;
    width: 24px;
  }

  &:hover {
    color: ${Colors.Blue50};
  }

  ${Media.Phone`
    display: none;
  `}
`
