import { ReactElement } from 'react'
import { Container, MatchedSkill, NotMatchedSkill } from './styles'
interface Props {
  title: ReactElement
  userSkills?: string[]
  jobSkills?: string[]
}
const stringNormalizer = (word: string) =>
  word
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()

const userSkills: string[] = []

const jobSkills: string[] = []

export const RequiredSkillsSection = ({ title }: Props) => {
  if (!jobSkills.length) {
    return null
  }

  const jobSkillsMap = new Map(jobSkills.map(skill => [stringNormalizer(skill), skill]))
  const userSkillsMap = new Map(userSkills?.map(skill => [stringNormalizer(skill), skill]) || [])
  return (
    <>
      {title}
      <Container>
        {userSkills?.map(skill => {
          const normalizedSkill = stringNormalizer(skill)
          return jobSkillsMap.has(normalizedSkill) ? (
            <MatchedSkill key={skill}>{jobSkillsMap.get(normalizedSkill)}</MatchedSkill>
          ) : null
        })}
        {jobSkills.map(skill => {
          const normalizedSkill = stringNormalizer(skill)
          return !userSkillsMap.has(normalizedSkill) ? (
            <NotMatchedSkill key={skill}>{skill}</NotMatchedSkill>
          ) : null
        })}
      </Container>
    </>
  )
}
