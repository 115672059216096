import { useSelector } from 'react-redux'
import { selectors as userSelectors } from 'builder/modules/user'
import { createMarketingEventHandler } from '@rio/tracking'
import { translate } from './utils'

import { PaywallContainer, PaywallTitle, PaywallDescription, PaywallButton } from './styles'
import { billingPlanPageURL } from 'builder/utils/goToBillingPlanPage'

const Paywall = () => {
  const upgradeUrl = billingPlanPageURL({ upgradeURL: useSelector(userSelectors.getUpgradeUrl) })

  return (
    <PaywallContainer>
      <PaywallTitle>{translate('paywall.title')}</PaywallTitle>
      <PaywallDescription>{translate('paywall.description')}</PaywallDescription>
      <PaywallButton
        href={upgradeUrl}
        onClick={createMarketingEventHandler('Sharing Modal', 'Start Upgrading')}
      >
        {translate('paywall.action')}
      </PaywallButton>
    </PaywallContainer>
  )
}

export default Paywall
