import { Fragment } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { UserData } from 'builder/modules/user'
import { useI18n } from 'builder/hooks/useI18n'
import { goToBillingPlanPage } from 'builder/utils/goToBillingPlanPage'
import { SuperAppButton, UpgradeButtonContainer, UpgradeButtonIcon } from './styles'

interface AdditionalButtonsProps {
  user?: UserData
  hasActivatedSuperApp: boolean
}

export const AdditionalButtons = ({ user, hasActivatedSuperApp }: AdditionalButtonsProps) => {
  const { i18n } = useI18n()

  const handleSuperAppClick = () => {
    trackInternalEvent('click_cio_button')
  }

  return (
    <Fragment>
      {/* User has activated career.io account and has premium */}
      {hasActivatedSuperApp && user?.hasPremiumFeatures && (
        <SuperAppButton
          href="/api/app/career-identity/redirect-with-login"
          onClick={handleSuperAppClick}
        />
      )}

      {/* User doesn't have premium */}
      {!user?.hasPremiumFeatures && (
        <UpgradeButtonContainer
          onClick={() => {
            if (user) {
              goToBillingPlanPage({ upgradeURL: user?.billingInfo?.upgradeUrl })
            }
          }}
          href={user ? user?.billingInfo.upgradeUrl : undefined}
        >
          <UpgradeButtonIcon />
          {i18n.t('builder.navbar.upgrade_now_button')}
        </UpgradeButtonContainer>
      )}
    </Fragment>
  )
}
