import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { actions, Label } from 'builder/modules/careerPlanning'
import { StyledButton } from './styles'

type TrackingProps = {
  action_item_name: string
  sub_section_name: string
  user_id: number | undefined
}

type Props = {
  url?: string
  text?: string
  isPremiumUser: boolean
  availableForFreeUsers: boolean
  trackingEventProps: TrackingProps
}

const Feed = ({ url, text, isPremiumUser, trackingEventProps, availableForFreeUsers }: Props) => {
  const dispatch = useDispatch()

  const triggerEvent = useCallback(() => {
    trackInternalEvent('click_action_item_cta', {
      ...Label,
      ...trackingEventProps,
    })
  }, [trackingEventProps])

  const handleClick = useCallback(() => {
    triggerEvent()

    if (isPremiumUser || availableForFreeUsers) {
      window.open(url, '_blank')
      return
    }

    dispatch(actions.setShowLimitModal(true))
  }, [url, dispatch, triggerEvent, isPremiumUser, availableForFreeUsers])

  return <StyledButton onClick={handleClick}>{text}</StyledButton>
}

export default Feed
