import CareerCoachingSlider from './components/CareerCoachingSlider'
import AboutCoachingSessions from './components/AboutCoachingSessions'
import CareerCoachingWorking from './components/CareerCoachingWorking'
import Testimonials from './components/Testimonials'
import CoachCardsAnimation from './components/CoachCardsAnimation'
import { Container } from './styles'

const CareerCoachingMainPage = (): JSX.Element => {
  return (
    <Container>
      <AboutCoachingSessions />
      <CareerCoachingSlider />
      <CareerCoachingWorking />
      <CoachCardsAnimation />
      <Testimonials />
    </Container>
  )
}

export default CareerCoachingMainPage
