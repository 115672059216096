import { Icon20 } from 'builder/components/Icon'
import {
  ProgressCircle,
  ProgressRing,
  BackgroundCircle,
  ProgressRingCircle,
  CompletedContainer,
} from './styles'

interface IProps {
  totalSteps: number
  completedSteps: number
  radius: number
  size: number
  strokeWidth: number
}

export const CircularProgressBar = ({
  totalSteps,
  completedSteps,
  radius,
  size,
  strokeWidth,
}: IProps) => {
  if (totalSteps === completedSteps) {
    return (
      <CompletedContainer>
        <Icon20.Tick />
      </CompletedContainer>
    )
  }
  const circumference = Math.PI * 2 * radius
  const offset = circumference - (completedSteps / totalSteps) * circumference
  const position = size / 2

  return (
    <ProgressCircle>
      <ProgressRing width={size} height={size}>
        <BackgroundCircle strokeWidth={strokeWidth} r={radius} cx={position} cy={position} />
        <ProgressRingCircle
          $circumference={circumference}
          strokeWidth={strokeWidth}
          fill="transparent"
          r={radius}
          cx={position}
          cy={position}
          offset={offset}
        />
      </ProgressRing>
    </ProgressCircle>
  )
}
