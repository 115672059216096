import { Fragment, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useLocation, useParams } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { formatDocumentPreviewUrl } from 'builder/utils/formatDocumentPreviewUrl'
import {
  actions,
  selectors,
  ResumeCritique as IResumeCritique,
  ResumeQualityStatuses,
} from 'builder/modules/resumeReview'
import { actions as critiqueActions } from 'builder/modules/resumeCritiqueReview'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useResume } from 'builder/hooks/useResume'
import Button from 'builder/components/Button'
import { Icon24 } from 'builder/components/Icon'
import { selectors as resumeSelector, actions as panelActions } from 'builder/modules/panel'
import CritiqueReview from 'builder/components/Panel/CIOCritiqueReview/CritiqueReviewResumeSectionBanner'
import { CritiqueResumeReviewStaticStatus } from 'builder/components/Panel/ResumeReviewStatus/CritiqueResumeReviewStaticStatus'
import { getScoreSuggestions } from 'builder/modules/resumeEditor/resumeScoreOracle'
import { useI18n } from 'builder/hooks/useI18n'
import SecondResumeReviewAlertModal from 'builder/components/SecondResmeReviewAlertModal'
import {
  Container,
  Header,
  HeaderTitle,
  HeaderName,
  HeaderBackButton,
  HeaderBackButtonIcon,
  HeaderEditButton,
  HeaderEditButtonIcon,
  Content,
  Critique,
  Sidebar,
  SidebarPreview,
  SidebarPreviewImage,
  SidebarPreviewAction,
  Footer,
  FooterContainer,
  FooterContent,
  FooterTitle,
  FooterText,
  FooterImage,
  SidebarWrapper,
  HeaderLengthyTitle,
} from './styles'
import EditResumeWithResumeScore from './components/LowBandTRLeads/EditResumeWithResumeScore'
import DiscountBanner from './components/LowBandTRLeads/DiscountBanner'
import InsightsBanner from './components/LowBandTRLeads/InsightsBanner'
import StruggleToWriteFooter from './components/LowBandTRLeads/StruggleToWriteFooter'
import FindDreamJob from './components/LowBandTRLeads/FindDreamJob'

// TODO: i18n
type LeadsParams = number | string | null

export const ResumeCritiqueView = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const { isTablet } = useMediaQueries()
  const location = useLocation()

  const resumeId = Number(params.id)
  const resumeEditorUrl = `/resumes/${resumeId}/edit`
  const resumeEditorExploreTemplatesUrl = `/resumes/${resumeId}/edit?show_templates=1`

  const resume = useResume(resumeId)
  const critique = useTypedSelector(store => selectors.critique(store, resumeId))
  const documents = useTypedSelector(resumeSelector.documents)
  const { i18n } = useI18n()

  // adding the discount parameter to the iFrame
  const modifiedCritique = {
    ...critique,
    iframeUrl: `${critique?.iframeUrl}&dt=TRCIO2023`,
  }

  const [critiqueParams, setCritiqueParams] = useState<number | string | null>(null)
  useEffect(() => {
    const critiqueQueryParams = new URLSearchParams(location.search)
    const critiqueKey = critiqueQueryParams.get('review')
    setCritiqueParams(critiqueKey)
  }, [location])
  const newCritique = critiqueParams === '1'

  const v2CritiqueQueryParam = new URLSearchParams(location.search)
  const v2Params = v2CritiqueQueryParam.get('v2')
  const critiqueV2 = v2Params === '1'

  const dashboardParams = v2CritiqueQueryParam.get('fromDashboard')
  const isFromDashBoard = dashboardParams === '1'

  const isSecondReviewParam = v2CritiqueQueryParam.get('secondReview')
  const isSecondReview = !!isSecondReviewParam

  useEffect(() => {
    trackInternalEvent('see_resume_critique_page')
  }, [])

  useEffect(() => {
    dispatch(actions.fetchCritiques())
  }, [dispatch])

  useEffect(() => {
    if (!documents || documents?.length < 1) {
      dispatch(panelActions.fetchDocuments())
    }
  }, [dispatch, documents])

  const resumeName = useMemo(() => {
    if (!resume) return null
    if (resume.name) return resume.name

    const userFullName = [resume.firstName, resume.lastName].filter(Boolean).join(' ')
    return [userFullName, resume.position].filter(Boolean).join(' - ')
  }, [resume])

  const previewImageSrc = useMemo(() => {
    if (!resume) return null

    return formatDocumentPreviewUrl(resume, { size: 352 })
  }, [resume])

  // lowLeads critique functionality
  const tunerResume = useResume(resumeId)
  const [lowLeadsParams, setLowLeadsParams] = useState<LeadsParams>(null)

  const resumeScore = useMemo(
    () => (tunerResume ? getScoreSuggestions(tunerResume)?.points : 0),
    [tunerResume],
  )

  useEffect(() => {
    if (tunerResume) {
      dispatch(
        critiqueActions.updateScoreForRemoteResume({ resume: tunerResume, score: resumeScore }),
      )
    }
  }, [dispatch, resumeScore, tunerResume])

  useEffect(() => {
    const lowLeadsQueryParams = new URLSearchParams(location.search)
    const lowLeadsKey = lowLeadsQueryParams.get('lowLeads')
    setLowLeadsParams(lowLeadsKey)
  }, [location])

  const lowLeads = lowLeadsParams === '1'
  const showTabletDiscountBanner = lowLeads && isTablet
  const showDiscountBanner = lowLeads && !isTablet
  const isResumeTroubled = critique?.resumeStatus === ResumeQualityStatuses.trouble
  const isResumeNearlyPerfect = critique?.resumeStatus === ResumeQualityStatuses.nearly_perfect

  const renderSideBarContent = () => {
    const showEditResumeScoreBanner = tunerResume !== null && lowLeads

    if (isTablet) return <></>

    if (showEditResumeScoreBanner) {
      return (
        <EditResumeWithResumeScore
          resume={tunerResume}
          report={documents?.filter(
            document => document?.type === 'resume' && document.id === resumeId,
          )}
          isResumeTroubled={isResumeTroubled}
          isResumeNearlyPerfect={isResumeNearlyPerfect}
          resumeScore={resumeScore}
        />
      )
    } else {
      return (
        <SidebarPreview
          onClick={() => trackInternalEvent('click_edit_resume_button', { section: 'side' })}
          to={resumeEditorUrl}
        >
          <SidebarPreviewImage src={previewImageSrc} />
          <SidebarPreviewAction value="Edit my resume">
            <Icon24.Edit />
          </SidebarPreviewAction>
        </SidebarPreview>
      )
    }
  }

  const renderTitle = () => {
    if (isResumeTroubled) {
      return (
        <HeaderLengthyTitle>
          {i18n.t('builder.panel.resume_critique.trouble_resume')}
        </HeaderLengthyTitle>
      )
    } else if (isResumeNearlyPerfect) {
      return (
        <HeaderLengthyTitle>
          {i18n.t('builder.panel.resume_critique.perfect_resume')}
        </HeaderLengthyTitle>
      )
    }
    return <HeaderTitle>{!isFromDashBoard ? 'Your ' : ''}Resume Review</HeaderTitle>
  }

  const renderCTAButton = () => {
    if (!isTablet) {
      if (isResumeTroubled) {
        return 'Improve My Resume'
      } else if (isResumeNearlyPerfect) {
        return 'Explore Templates'
      }
      return (
        <>
          <HeaderEditButtonIcon />
          {`Edit ${isFromDashBoard ? 'My' : ''} Resume`}
        </>
      )
    }
    return <HeaderEditButtonIcon />
  }

  return (
    <Fragment>
      <Container>
        <Header>
          {isFromDashBoard && (
            <HeaderBackButton to="/">
              <HeaderBackButtonIcon />
              {!isTablet && 'Back to Dashboard'}
            </HeaderBackButton>
          )}
          {renderTitle()}
          {resumeName && isFromDashBoard && <HeaderName>{resumeName}</HeaderName>}

          <HeaderEditButton
            onClick={() => trackInternalEvent('click_edit_resume_button', { section: 'top' })}
            as={Link}
            to={isResumeNearlyPerfect ? resumeEditorExploreTemplatesUrl : resumeEditorUrl}
          >
            {renderCTAButton()}
          </HeaderEditButton>
        </Header>

        {!isFromDashBoard && critique && (
          <CritiqueResumeReviewStaticStatus
            critique={critique}
            isResumeNearlyPerfect={isResumeNearlyPerfect}
          />
        )}
        {newCritique && <CritiqueReview isReviewPage={false} />}
        {showTabletDiscountBanner && <DiscountBanner isDiscountAvailable={!critiqueV2} />}
        <Content>
          {!critiqueV2 ? (
            <Critique critique={modifiedCritique as IResumeCritique} />
          ) : (
            <Critique critique={critique} />
          )}
          {!isTablet && (
            <SidebarWrapper>
              <Sidebar>
                {renderSideBarContent()}
                {showDiscountBanner && !isResumeNearlyPerfect && (
                  <DiscountBanner isDiscountAvailable={!critiqueV2} />
                )}
                {isResumeNearlyPerfect && <FindDreamJob />}
              </Sidebar>
            </SidebarWrapper>
          )}
        </Content>
        {lowLeads && <InsightsBanner />}
      </Container>

      {!lowLeads && (
        <Footer data-cta="edit">
          <FooterContainer>
            <FooterContent>
              <FooterTitle>Found Some Useful Insights?</FooterTitle>
              <FooterText>
                Take your time to rewrite your resume based on this in-depth critique and get your
                dream job with higher chances.
              </FooterText>
              <Button
                onClick={() =>
                  trackInternalEvent('click_edit_resume_button', { section: 'bottom' })
                }
                as={Link}
                to={resumeEditorUrl}
              >
                Edit My Resume
              </Button>
            </FooterContent>
            <FooterImage src={require('images/builder/resume-critique/edit-cta-visual.svg')} />
          </FooterContainer>
        </Footer>
      )}

      {!lowLeads && <StruggleToWriteFooter />}
      <SecondResumeReviewAlertModal isSecondReview={isSecondReview} resumeId={resumeId} />
    </Fragment>
  )
}
