import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Icon24 from 'builder/components/Icon'

export const HeartIconOutline = styled.img`
  width: 15px;
`

export const ActionButton = styled.div<{ isDisabled?: boolean }>`
  width: 32px;
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 4px;
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};

  position: relative;
  transition: all 0.1s ease;

  ${props =>
    props.isDisabled
      ? null
      : css`
          :hover {
            background: ${Colors.Neutral10};
          }
        `}
`

export const DefaultHeartIcon = styled(Icon24.Heart)`
  width: 20px;
  height: 20px;
  color: ${Colors.Neutral50};
`
