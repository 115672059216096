import { Suspense, lazy } from 'react'

const UiKit = lazy(() => import('builder/components/UiKit'))

export const UiKitView = () => {
  return (
    <Suspense fallback={null}>
      <UiKit />
    </Suspense>
  )
}
