import { Job } from 'builder/modules/jobTracking'
import { Colors } from 'builder/styles/colors'
import { formatInterviewTitle } from '../../utils'
import { CardType } from '../index'
import { Title, Description, DescriptionLoading } from './styles'

type Props = {
  type: CardType
  job: Partial<Job>
}

export const CardTitle = ({ type, job }: Props) => {
  const title = formatInterviewTitle(job)

  const isPlaceholderVisible = ['loading', 'empty', 'error'].includes(type)

  if (isPlaceholderVisible) {
    return (
      <>
        <Title>{title}</Title>
        <DescriptionLoading animated={type === 'loading'} />
      </>
    )
  }

  return (
    <>
      <Title color={Colors.Green80}>{title}</Title>
      <Description>Interview based off this specific job&apos;s description</Description>
    </>
  )
}
