import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import { Media } from 'builder/styles/media'
import { Shadows } from 'builder/styles/shadows'
import { Typography, FontWeights } from 'builder/styles/typography'
import DocumentImagePreview from 'builder/components/DocumentImagePreview'

export const Container = styled.div`
  padding: 32px;
`

export const ImagePreview = styled(DocumentImagePreview)`
  border-radius: 3px;
`

export const Score = styled.div<{ barColor: string; progress: number }>`
  ${Shadows.light.mid};
  position: absolute;
  z-index: 2;
  left: 24px;
  bottom: 24px;
  width: 96px;
  height: 96px;
  border-radius: 50%;
  border: 4px solid ${Colors.White};
  background-color: ${Colors.White};

  ${props => css`
    background-image: conic-gradient(
      ${props.barColor} 0% ${props.progress * 100}%,
      ${Colors.Neutral15} 0% 100%
    );
  `};
`

export const ScoreContent = styled.div`
  position: absolute;
  left: 4px;
  right: 4px;
  bottom: 4px;
  top: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  border-radius: 50%;
  background-color: ${Colors.White};
  text-align: center;
`

export const ScoreValue = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral90};
  margin-bottom: -4px;

  ${Media.Phone`
    margin-bottom: -2px;
  `};
`

export const ScoreLabel = styled.div`
  ${Typography.Tiny};
  color: ${Colors.Neutral50};
`

export const Percent = styled.span`
  ${Typography.Caption};
  ${FontWeights.DemiBold};
`
