import { Background, BackgroundColor } from './types'

export const ACCEPT = 'image/jpeg, image/x-png, .jpg, .jpeg, .png'

/** Compress a processed image to make a network request faster */
export const OUTPUT_IMAGE_QUALITY = 0.8

/** The large squared version of the avatar must be 1000x1000 maximum */
export const MAX_OUTPUT_SQUARE_SIDE = 1000

export const DEFAULT_X = 0.5
export const DEFAULT_Y = 0.5
export const DEFAULT_ZOOM = 1
export const DEFAULT_ANGLE = 0

/** Default background state */
export const DEFAULT_BACKGROUND: Background = {
  color: null,
  image: null,
}

/** Avatar editor modes/tabs */
export enum MODES {
  crop = 'crop',
  background = 'background',
}

/** Predefined background colors */
export const BACKGROUND_COLORS: BackgroundColor[] = [
  ['#fff279', '#f86f3a'],
  ['#a7d5ff', '#613fb0'],
  ['#e8a580', '#5728c3'],
  ['#73eeff', '#006fc7'],
  ['#d37bfc', '#c3283b'],
  ['#b9f47d', '#0ea175'],
  '#f68559',
  '#5863dd',
  '#22a860',
  '#613fb0',
  '#ff4c4c',
  '#b8ddf0',
  '#f8f9fc',
  '#e6ebf4',
  '#98a1b3',
  '#262b33',
  '#0f141f',
]

export const BRIGHT_BACKGROUND_COLORS = BACKGROUND_COLORS.slice(0, -6)
export const DARK_BACKGROUND_COLORS = BACKGROUND_COLORS.slice(-2)

/** Available background image names (same as frame names in Figma) */
export const BACKGROUND_IMAGE_NAMES = [
  // pixels
  'pattern1',
  // terrain
  'pattern7',
  // rhombuses
  'pattern4',
  // diagonal stripes
  'pattern12',
  // dots
  'pattern9',
  // circles
  'pattern5',
  'pattern6',
  'pattern11',
  // motherboard
  'pattern10',
  // worms
  'pattern8',
  'pattern2',
  'pattern3',
  // blurred images
  'bokeh1',
  'bokeh2',
  // photos
  'photo1',
  'photo2',
  'photo3',
  'photo4',
  'photo5',
  'photo6',
]
