import { Fragment } from 'react'
import uniqueId from 'lodash/uniqueId'
import { useConstant } from 'builder/hooks/useConstant'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'

import { LevelConnectionProps } from './types'
import * as Styles from './styles'

export const Level3 = ({ type, connections, gradient: { from, to } }: LevelConnectionProps) => {
  const { isPhone } = useMediaQueries()
  const width = isPhone ? 24 : 32

  const id1 = useConstant(() => uniqueId('l31'))
  const id2 = useConstant(() => uniqueId('l32'))
  const id3 = useConstant(() => uniqueId('l33'))

  return (
    <Styles.Container
      level={3}
      type={type}
      isPhone={isPhone}
      width={width}
      height="304"
      viewBox={`0 0 ${width} 304`}
      xmlns="http://www.w3.org/2000/svg"
    >
      {connections.includes(1) && (
        <Fragment>
          <path
            d={
              isPhone
                ? 'M24 2V2C17.3726 2 12 7.37258 12 14V276C12 282.627 6.62742 288 0 288V288'
                : 'M0 302V302C8.83656 302 16 294.837 16 286V18C16 9.16344 23.1634 2 32 2V2'
            }
            stroke={`url(#${id1})`}
            strokeWidth="4"
          />
          <defs>
            <linearGradient
              id={id1}
              x1={isPhone ? 18 : 6}
              y1={isPhone ? -301.979 : 330}
              x2={isPhone ? -1.99999 : 26}
              y2={isPhone ? -301.97 : 330}
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor={from} />
              <stop offset="1" stopColor={to} />
            </linearGradient>
          </defs>
        </Fragment>
      )}
      {connections.includes(2) && (
        <Fragment>
          <path
            d={
              isPhone
                ? 'M24 145V145C17.3726 145 12 150.373 12 157V278C12 284.627 6.62742 290 0 290V290'
                : 'M0 302V302C8.83656 302 16 296.837 16 286V165C16 156.163 23.1634 148 32 148V148'
            }
            stroke={`url(#${id2})`}
            strokeWidth="4"
          />
          <defs>
            <linearGradient
              id={id2}
              x1={isPhone ? 18 : 6}
              y1={isPhone ? -302.025 : 492.998}
              x2={isPhone ? -1.99999 : 26}
              y2={isPhone ? -302.025 : 492.998}
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor={from} />
              <stop offset="1" stopColor={to} />
            </linearGradient>
          </defs>
        </Fragment>
      )}
      {connections.includes(3) && (
        <Fragment>
          <path d={`M0 ${isPhone ? '290H24' : '302H32'}`} stroke={`url(#${id3})`} strokeWidth="4" />
          <defs>
            <linearGradient
              id={id3}
              x1={isPhone ? 4.5 : 6}
              y1={isPhone ? 297 : -326.995}
              x2={isPhone ? 19.5 : 26}
              y2={isPhone ? 297 : -326.995}
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor={from} />
              <stop offset="1" stopColor={to} />
            </linearGradient>
          </defs>
        </Fragment>
      )}
    </Styles.Container>
  )
}
