import styled from 'styled-components'
import Media from 'builder/styles/media'

/**
 * The dimensions for the styles mentioned below have been referred from the figma
 * https://www.figma.com/file/wdX0fbRVlodq1Is8l1GwoV/APP-1372%3A-Subscription-cancelation?node-id=1%3A2&t=sJ8R8w5Ggv06RqH5-0
 */

export const CardImage = styled.img`
  display: flex;
  justify-content: center;
  width: 50px;
  height: 54px;

  ${Media.Tablet`
    margin: 17px 19px !important;
  `}

  ${Media.Phone`
    width: 43px;
    height: 46px;
    margin: 17px 10.5px 17px 19px !important;
  `}
`
