import { Fragment } from 'react'
import * as Styles from './styles'

const person1 = require('images/critique-review/Person1.svg')
const person2 = require('images/critique-review/Person2.svg')

interface Props {
  isDiscountAvailable: boolean
  handleTRBannerClick?: () => void
}

const DiscountTabletBanner = ({ isDiscountAvailable, handleTRBannerClick }: Props) => {
  return (
    <Fragment>
      <Styles.Container>
        <Styles.SideContainer>
          <Styles.Title>
            {isDiscountAvailable
              ? 'Make sure your resume gets you to the interview - get expert help with a discount of up to 60%'
              : 'Make sure that your resume gets you to the interview - get expert help in writing a resume'}
          </Styles.Title>
          <Styles.Gradient />
          <Styles.Button onClick={handleTRBannerClick}>Get Expert Help</Styles.Button>
        </Styles.SideContainer>
        <Styles.AvatarsSideContainer>
          <Styles.PopupTabletPhoto src={person1} isPerson1={true} />
          <Styles.PopupTabletPhoto src={person2} isPerson1={false} />
        </Styles.AvatarsSideContainer>
      </Styles.Container>
    </Fragment>
  )
}

export default DiscountTabletBanner
