import styled from 'styled-components'
import { animated } from '@react-spring/web'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Shadows from 'builder/styles/shadows'
import { FontWeights } from 'builder/styles/typography'

export const ModalContainer = styled(animated.div)`
  ${Shadows.lightWithBorder.low};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 24px;
  width: 100%;
  max-width: 352px;
  background-color: ${Colors.White};
  border-radius: 12px;
  position: fixed;
  right: 25px;
  bottom: 32px;
  z-index: 9999999999; // make modal appear on top of the question icon button and feedback button

  ${Media.Phone`
    left: 0;
    right: 0;
    bottom: 16px;
    margin: 0 auto;
    padding: 24px 20px 32px;
    max-width: calc(100% - 32px);
  `}
`

export const ModalContent = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Image = styled.img`
  margin-bottom: 8px;
`

export const Text = styled.div`
  ${FontWeights.DemiBold};

  text-align: center;
  margin-bottom: 4px;

  ${Media.Phone`
    margin-bottom: 10px;
  `}
`

export const SubText = styled.div`
  color: ${Colors.Neutral50};

  text-align: center;
  margin-bottom: 16px;

  ${Media.Phone`
    margin-bottom: 24px;
  `}
`

export const Close = styled.div`
  position: absolute;
  width: 28px;
  height: 28px;
  top: 24px;
  right: 24px;
  border-radius: 32px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.Neutral70};
  cursor: pointer;
  transition: color 0.1s;
  background-color: ${Colors.Neutral10};

  &:hover {
    color: ${Colors.Blue50};
    background-color: ${Colors.Blue10};
  }

  ${Media.Phone`
    top: 20px;
    right: 20px;
  `}
`
