import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/careerPlanning'
import CareerPlanningAnimation from '../CareerPlanningAnimation'
import ProgressBar from './components/ProgressBar'
import TabletProgressBar from './components/TabletProgressBar'
import MobileProgressBar from './components/MobileProgressBar'
import { ProgressContainer, Text } from './styles'

interface Props {
  score: number
  isOpen?: boolean
  isDeskTop?: boolean
  isMobile?: boolean
}

const CareerPlanningProgress = ({ score, isMobile, isDeskTop, isOpen }: Props) => {
  const showAnimation = useTypedSelector(selectors.showAnimation)

  return (
    <ProgressContainer>
      {isDeskTop ? (
        <ProgressBar score={score} />
      ) : isMobile ? (
        <MobileProgressBar score={score} />
      ) : (
        <TabletProgressBar score={score} />
      )}
      <Text>Your Progress</Text>
      {showAnimation && <CareerPlanningAnimation isOpen={isOpen} />}
    </ProgressContainer>
  )
}

export default CareerPlanningProgress
