import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'

import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useI18n } from 'builder/hooks/useI18n'

import { Container, Button, Subtitle, Title } from '../styles'
import LOGO from './assets/logo.png'
import { BlankContainer, ImagesContainer, Logo } from './styles'
import {
  GroupFive,
  GroupFour,
  GroupOne,
  GroupThree,
  GroupTwo,
  GroupFivePhone,
  GroupFiveTablet,
  GroupFourPhone,
  GroupFourTablet,
  GroupOnePhone,
  GroupOneTablet,
  GroupThreePhone,
  GroupThreeTablet,
  GroupTwoPhone,
  GroupTwoTablet,
} from './assets/groups'

const TRANSLATION = 'builder.dashboard_v2.banners.job_search'

export const JobSearchBanner = () => {
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const { isPhone, isTablet } = useMediaQueries()

  const getStartedOnClick = useCallback(() => {
    trackInternalEvent('click_job_search_strategy_banner', { label: 'find_a_job' })
    navigate('/career-plans/execute-a-job-search?cp_top_level=2&cp_second_level=3')
  }, [navigate])

  return (
    <Container className="job-search">
      <Title className="job-search">{i18n.t(`${TRANSLATION}.title`)}</Title>
      <Subtitle className="job-search">{i18n.t(`${TRANSLATION}.sub_title`)}</Subtitle>
      <Button className="job-search" onClick={getStartedOnClick}>
        {i18n.t(`${TRANSLATION}.button`)}
      </Button>
      <ImagesContainer>
        <Logo src={LOGO} />
        <BlankContainer>
          {isPhone && (
            <>
              <GroupOnePhone />
              <GroupTwoPhone />
              <GroupThreePhone />
              <GroupFourPhone />
              <GroupFivePhone />
            </>
          )}
          {isTablet && !isPhone && (
            <>
              <GroupOneTablet />
              <GroupTwoTablet />
              <GroupThreeTablet />
              <GroupFourTablet />
              <GroupFiveTablet />
            </>
          )}
          {!isPhone && !isTablet && (
            <>
              <GroupOne />
              <GroupTwo />
              <GroupThree />
              <GroupFour />
              <GroupFive />
            </>
          )}
        </BlankContainer>
      </ImagesContainer>
    </Container>
  )
}
