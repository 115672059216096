import { useCallback } from 'react'
import { SimpleLayout } from 'builder/components/SimpleLayout'
import { CreateAccount } from 'builder/components/CreateAccount'
import { SignUpCopyright } from 'builder/components/SignUpCopyright'
import { useQueryParam } from 'builder/hooks/useQueryParam'
import * as Styled from './styles'

export const CreateAccountView = () => {
  const [regionPrefix] = useQueryParam('current_region_prefix')

  const handleLeave = useCallback(() => {
    window.location.href = `/${regionPrefix ?? ''}`
  }, [regionPrefix])

  return (
    <SimpleLayout onLogoClick={handleLeave} onCloseClick={handleLeave}>
      <Styled.Main>
        <CreateAccount />
      </Styled.Main>
      <SignUpCopyright />
    </SimpleLayout>
  )
}
