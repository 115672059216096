import { useEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { trackingInfo } from 'builder/modules/careerCoaching/constants'
import CareerPostPurchaseBanner from 'builder/components/CareerPostPurchaseBanner'
import { Loader } from 'builder/components/CareerCoachingSessions/components/Loader'
import CareerPostPurchaseSessions from 'builder/components/CareerPostPurchaseSessions'
import CareerPurchaseActionBanner from 'builder/components/CareerPurchaseActionBanner'
import { Container } from './styles'
import { usePostPurchase } from './hooks/usePostPurchase'

const PostPurchaseView = (): JSX.Element => {
  const { isCoachSessionsLoading, onShowMoreSessions, isMorePreviousSessions } = usePostPurchase()

  useEffect(() => {
    trackInternalEvent('enter_post_purchase_page', { ...trackingInfo })
  }, [])

  if (isCoachSessionsLoading) return <Loader />

  return (
    <Container>
      <CareerPostPurchaseBanner />
      <CareerPostPurchaseSessions {...{ onShowMoreSessions, isMorePreviousSessions }} />
      <CareerPurchaseActionBanner />
    </Container>
  )
}

export default PostPurchaseView
