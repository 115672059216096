import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Container = styled.div`
  background-color: ${Colors.White};
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  align-items: flex-start;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
`

export const Header = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};
  text-align: center;
  mix-blend-mode: normal;
  width: 100%;
`

export const SubHeader = styled.div`
  text-align: center;
  mix-blend-mode: normal;
`

export const FieldContainer = styled.div`
  width: 100%;
  border-radius: 4px;
  gap: 4px;
`

export const Note = styled.div`
  margin-top: 100px;

  ${Media.Phone`
    margin-top: 28px;
  `}
`
