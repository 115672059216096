import styled from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Icon24 } from 'builder/components/Icon'

export const Overlay = styled.div<{ isMenuVisible: boolean }>`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 32px 20px;
  background-color: ${hexToRgba(Colors.Neutral100, 0.8)};

  transition: opacity 0.2s;
  opacity: ${props => (props.isMenuVisible ? 1 : 0)};
`

export const MobileTabsMenuContainer = styled.div<{ isMenuVisible: boolean }>`
  padding: 20px;
  border-radius: 4px;
  background-color: ${Colors.White};

  opacity: ${props => (props.isMenuVisible ? 1 : 0)};
  transition-property: opacity, transform;
  transition-timing-function: ease-out;
  transition-duration: 0.15s;
  border-radius: 6px;
  transition-delay: 0.05s;
  transition-duration: 0.25s;
  transform: translateY(${props => (props.isMenuVisible ? 0 : 28)}px) translateZ(0);

  display: flex;
  flex-direction: column;
  gap: 30px;
`

export const CancelButton = styled.div<{ isMenuVisible: boolean }>`
  padding: 12px;
  margin-top: 8px;
  border-radius: 6px;
  background-color: ${Colors.White};
  color: ${Colors.Blue50};
  text-align: center;
  cursor: pointer;
  ${Typography.S};
  ${FontWeights.Medium};
  transition-property: opacity, transform;
  transition-timing-function: ease-out;
  transition-delay: 0.05s;
  transition-duration: 0.3s;
  opacity: ${props => (props.isMenuVisible ? 1 : 0)};
  transform: translateY(${props => (props.isMenuVisible ? 0 : 28)}px) translateZ(0);
`

export const Item = styled.div`
  padding: 0px;
  cursor: pointer;
`

export const ItemContent = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: space-between;
`

export const ItemIcon = styled.div`
  display: flex;
  flex-shrink: 0;
  color: ${Colors.Blue50};
  margin-right: 8px;
`

export const ItemText = styled.div<{ selected: boolean }>`
  transition: color 0.15s;
  color: ${props => (props.selected ? Colors.Blue50 : Colors.Neutral90)};
  ${Typography.S};
  ${FontWeights.Medium};
  ${Item}:hover && {
    color: ${Colors.Blue50};
  }
`

export const SelectedIcon = styled(Icon24.TickLarge)<{ selected: boolean }>`
  color: ${Colors.Blue50};
  display: ${props => (props.selected ? 'block' : 'none')};
`
