import { useState } from 'react'
import { AutoApplyTooltip, AutoApplyTooltipContent } from 'builder/components/JobTracking/styles'
import { useI18n } from 'builder/hooks/useI18n'
import Icon24 from 'builder/components/Icon'
import { DeleteDocumentConfirmation, DeleteIconContainer, DocumentDeleteContainer } from './styles'
import trashIcon from './Icons/trash.svg'

export const DocumentDeleteButton = ({ handleAction }: { handleAction: () => void }) => {
  const [confirm, setConfirm] = useState(false)
  const { i18n } = useI18n()

  return (
    <AutoApplyTooltip
      visible={confirm}
      content={
        <AutoApplyTooltipContent>
          <span>{i18n.t('builder.job_search.delete_confirmation')}</span>
        </AutoApplyTooltipContent>
      }
    >
      <DocumentDeleteContainer>
        {!confirm && (
          <DeleteIconContainer documentBtn>
            <img
              src={trashIcon}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                setConfirm(true)
              }}
            />
          </DeleteIconContainer>
        )}
        {confirm && (
          <DeleteDocumentConfirmation>
            <Icon24.TickLarge
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                handleAction()
                setConfirm(false)
              }}
            />
            <Icon24.Error
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                setConfirm(false)
              }}
            />
          </DeleteDocumentConfirmation>
        )}
      </DocumentDeleteContainer>
    </AutoApplyTooltip>
  )
}
