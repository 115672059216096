import { useDispatch } from 'react-redux'
import React, { ForwardedRef, useRef } from 'react'
import { ColumnName, Job, actions } from 'builder/modules/jobTracking'
import { useI18n } from 'builder/hooks/useI18n'
import { useClickOutside } from 'builder/hooks/useClickOutside'
import { DrawerContainer, CustomDrawer, HeaderClose } from '../CardDrawer/styles'
import { StatusSelect } from './StatusSelect'

import { Header, HeaderTitle } from './styles'

interface IProps {
  onClose: () => void
  columnNames: ColumnName[]
  editJob: (job: Job) => void
  onDelete: (id: number) => void
  selectedJob: Job
}

export const CardStatusDrawer = React.forwardRef(
  (props: IProps, ref: ForwardedRef<HTMLDivElement>) => {
    const drawerRef = useRef<HTMLDivElement>(null)
    const { onClose, onDelete, selectedJob, columnNames, editJob } = props
    const { i18n } = useI18n()
    const dispatch = useDispatch()
    const handleEditJob = (job: Job) => {
      editJob(job)
      dispatch(actions.setOpenStatusChangeDrawer(false))
    }

    useClickOutside(drawerRef, onClose)

    return (
      <DrawerContainer ref={ref}>
        <CustomDrawer ref={drawerRef} autoHeight={true}>
          <Header>
            <HeaderTitle>{i18n.t('builder.job_tracking.status_change_drawer.title')}</HeaderTitle>
            <HeaderClose onClick={onClose} />
          </Header>
          <StatusSelect
            columnNames={columnNames}
            selectedJob={selectedJob}
            editJob={handleEditJob}
            onDelete={onDelete}
            drawer={true}
            closeSelect={onClose}
          />
        </CustomDrawer>
      </DrawerContainer>
    )
  },
)
