import merge from 'lodash/merge'
import { ChartOptions, TooltipItem } from 'chart.js'
import { CareerPathEducation } from 'builder/modules/careerPath'
import { getOptions } from '../../utils'

type RawProps = CareerPathEducation & { x: string; y: number }

export const normalizeChartData = (data: Array<CareerPathEducation>) =>
  data.reduce((accum: Array<RawProps>, item: CareerPathEducation) => {
    // Not include 'not specified'
    if (item.abbr) {
      accum.push({
        ...item,
        x: item.abbr,
        y: item.rate * 100,
      })
    }
    return accum
  }, [])

export const getEducationChartOptions = (maxY: number): ChartOptions<'bar'> =>
  merge(getOptions(maxY), {
    scales: {
      y: {
        ticks: {
          callback: (value: string) => (value ? `${value}%` : ''),
          includeBounds: true,
          maxTicksLimit: 5,
          major: {
            enabled: true,
          },
        },
        stacked: true,
        type: 'logarithmic',
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: (items: Array<TooltipItem<'bar'>>) => (items[0]?.raw as RawProps)?.title,
          label: ({ raw }: { raw: RawProps }) => {
            const percentage = (raw.rate * 100).toFixed(2)
            return `Job Openings: ${raw.jobPostings} (${percentage}%)`
          },
        },
        displayColors: false,
      },
    },
  }) as ChartOptions<'bar'>
