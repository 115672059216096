import { useEffect } from 'react'
import { useEvent } from './useEvent'

export type ClickOutsideRef<T> = React.RefObject<T> | React.MutableRefObject<T | null>

export const isIntersecting = (element: Element | null, e: MouseEvent | TouchEvent) => {
  return !!element && e.target instanceof Element && element.contains(e.target)
}

// Improved version of https://usehooks.com/useOnClickOutside/
export const useClickOutside = <T extends HTMLElement>(
  ref: ClickOutsideRef<T>,
  handler: (event: MouseEvent) => void,
) => {
  const clickOutsideCallback = useEvent(handler)

  useEffect(() => {
    let startedInside = false

    const listener = (e: MouseEvent) => {
      // Do nothing if `mousedown` or `touchstart` started inside ref element
      if (startedInside) return

      // Do nothing if clicking ref's element or descendent elements
      if (isIntersecting(ref.current, e)) return

      clickOutsideCallback(e)
    }

    const validateEventStart = (e: MouseEvent | TouchEvent) => {
      startedInside = isIntersecting(ref.current, e)
    }

    document.addEventListener('mousedown', validateEventStart)
    document.addEventListener('touchstart', validateEventStart)
    document.addEventListener('click', listener, true)

    return () => {
      document.removeEventListener('mousedown', validateEventStart)
      document.removeEventListener('touchstart', validateEventStart)
      document.removeEventListener('click', listener, true)
    }
  }, [ref, clickOutsideCallback])
}
