import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Sizes from 'builder/styles/sizes'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Container = styled.div`
  color: ${Colors.Blue50};
  ${FontWeights.Regular};
  ${Typography.Body};
  flex-shrink: 0;
  display: flex;
  gap: ${Sizes['3XS']};
  cursor: pointer;

  ${Media.Tablet`
    margin-bottom: 0

    `}

  ${Media.Phone`
    align-items: center;
    margin-bottom: 0;
  `}
`
