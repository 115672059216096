import { all, put, call, takeLatest } from 'redux-saga/effects'
import { baseClient, ApiResponse } from 'builder/modules/apiClient'
import ErrorLogger from 'builder/services/ErrorLogger'
import { actions } from './dashboardModule'
import { StepsData } from './types'

function* fetchStepsDataSaga() {
  try {
    const url = `/dashboard-stepper`
    const response: ApiResponse<StepsData> = yield call(baseClient.get, url)
    yield put(actions.fetchStepsDataSuccess(response.data))
  } catch (error) {
    ErrorLogger.log(error)
  }
}

function* fetchStepperSuccessStateSaga() {
  try {
    const url = `/dashboard-stepper/step_status?step=stepper_success_state_seen`
    const response: ApiResponse<boolean> = yield call(baseClient.get, url)
    yield put(actions.fetchStepperSuccessStateSuccess(response.data))
  } catch (error) {
    ErrorLogger.log(error)
  }
}

function* updateStepperSuccessStateSaga() {
  try {
    const url = `/dashboard-stepper/complete_step?step=stepper_success_state_seen`
    yield call(baseClient.post, url)
  } catch (error) {
    ErrorLogger.log(error)
  }
}

function* updatePersonalizedJobsStateSaga() {
  try {
    const url = `/dashboard-stepper/complete_step?step=personalized_jobs`
    yield call(baseClient.post, url)
  } catch (error) {
    ErrorLogger.log(error)
  }
}

export const sagas = function* saga() {
  yield all([takeLatest(actions.fetchStepsDataRequest, fetchStepsDataSaga)])
  yield all([takeLatest(actions.fetchStepperSuccessStateRequest, fetchStepperSuccessStateSaga)])
  yield all([takeLatest(actions.updateStepperSuccessStateRequest, updateStepperSuccessStateSaga)])
  yield all([
    takeLatest(actions.updatePersonalizedJobsStateRequest, updatePersonalizedJobsStateSaga),
  ])
}
