// Dummy data to show 3 coaches animation on MarketPlace Main Page
export const COACHES = [
  {
    id: 34,
    coachAvatar: require('./img/rishi_bryan.png'),
    fullName: 'Rishi Bryan',
    location: 'Los Angeles, CA',
    aboutMe:
      "Hi there! I'm Rishi, a certified career coach with over a decade of experience in HR executive positions.",
    status: 'active',
    careerStages: ['Entry-level', 'Individual Contributors', 'Leaders'],
    services: [
      {
        id: 1,
        name: 'Personal Branding',
        description: '',
      },
      {
        id: 2,
        name: 'Job Search Strategy',
        description: '',
      },
      {
        id: 4,
        name: 'Interview Prep',
        description: '',
      },
      {
        id: 5,
        name: 'Salary Negotiation',
        description: '',
      },
    ],
    industries: ['Technology', 'Finance', 'Healthcare', 'Marketing', 'Non Profit'],
    jobFunctions: [
      'Finance',
      'Human Resources',
      'Marketing',
      'Operations',
      'Pr/Communication',
      'Sales',
      'Support',
      'Technology',
    ],
  },
  {
    id: 35,
    coachAvatar:
      'https://stagingbucket.resume.io/uploads/career_coaching/coach/coach_avatar/35/kate_robinson.jpeg',
    fullName: 'Kate Robinson',
    location: 'New York, NY',
    aboutMe:
      'Passionate career coach dedicated to empowering individuals to achieve their aspirations.',
    status: 'active',
    careerStages: ['Entry-level', 'Individual Contributors', 'Leaders'],
    services: [
      {
        id: 1,
        name: 'Personal Branding',
        description: '',
      },
      {
        id: 3,
        name: 'Career Planning',
        description: '',
      },
    ],
    industries: ['Technology', 'Finance', 'Healthcare', 'Marketing', 'Non Profit'],
    jobFunctions: [
      'Finance',
      'Human Resources',
      'Marketing',
      'Operations',
      'Pr/Communication',
      'Sales',
      'Support',
      'Technology',
    ],
  },
  {
    id: 36,
    coachAvatar:
      'https://stagingbucket.resume.io/uploads/career_coaching/coach/coach_avatar/36/michael_anderson.png',
    fullName: 'Michael Anderson',
    location: 'Los Angeles, CA',
    aboutMe:
      "Hello there! I'm Michael, a dedicated career coach with a passion for helping individuals overcome challenges and achieve success in their professional lives.",
    status: 'active',
    careerStages: ['Entry-level', 'Individual Contributors', 'Leaders'],
    services: [
      {
        id: 1,
        name: 'Personal Branding',
        description: '',
      },
      {
        id: 2,
        name: 'Job Search Strategy',
        description: '',
      },
      {
        id: 4,
        name: 'Interview Prep',
        description: '',
      },
      {
        id: 5,
        name: 'Salary Negotiation',
        description: '',
      },
    ],
    industries: ['Technology', 'Finance', 'Healthcare', 'Marketing', 'Non Profit'],
    jobFunctions: [
      'Finance',
      'Human Resources',
      'Marketing',
      'Operations',
      'Pr/Communication',
      'Sales',
      'Support',
      'Technology',
    ],
  },
]
