import {
  CareerPathRoleOccupationMatrix,
  CareerPathRoleOccupationMatrixItem,
} from 'builder/modules/careerPath/types'

export const getCardById = (
  id: string | null,
  matrix: CareerPathRoleOccupationMatrix,
): CareerPathRoleOccupationMatrixItem | null => {
  for (const grade of Object.values(matrix)) {
    if (!id) return null

    for (const item of grade) {
      if (item.id === id) return item
    }
  }

  return null
}
