export type ValidateOptions = {
  allowAvailableOnly?: boolean
}

export enum ValidateErrorCodes {
  taken = 'email_taken',
  invalid = 'email_invalid',
}

// Response format from app/services/sign_up/registration_service.rb
export type ValidateResponse =
  | { success: true; activatedBy?: string }
  | { success: false; errorCode: ValidateErrorCodes; activatedBy?: string }

// Response format from app/controllers/api/builder/sign_up_controller.rb
export type SuggestResponse =
  | { success: false }
  | { success: true; email: string; suggested: string | false }
