import styled from 'styled-components'
import Typography from 'builder/styles/typography'
import { Icon20 } from 'builder/components/Icon'
import Button, { ButtonSize } from 'builder/components/Button'
import Media from 'builder/styles/media'

export const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  text-align: center;
`

export const EmptyStateImage = styled.img`
  max-width: 180px;
  width: 100%;
  height: 100%;
  margin-bottom: 24px;

  ${Media.Phone`
    margin-bottom: 12px;
  `};
`

export const EmptyStateTitle = styled.div`
  ${Typography.Subhead};
  font-weight: 600;
  margin-bottom: 8px;
`

export const EmptyStateText = styled.div``

export const EmptyStateButton = styled(Button).attrs({ size: ButtonSize.small })`
  padding-left: 4px;
  margin-top: 32px;

  ${Media.Phone`
    display: none;
  `};
`

export const EmptyStateButtonIcon = styled(Icon20.Add)`
  margin-right: 2px;
`
