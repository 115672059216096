import styled, { css } from 'styled-components'

export const SvgContainer = styled.svg`
  position: absolute;
  fill: none;
`

export const Container = styled(SvgContainer)<{
  type: 'left' | 'right'
  level: number
  isPhone: boolean
}>`
  ${props =>
    props.type === 'right'
      ? css`
          right: ${props.isPhone ? '-24' : '-32'}px;
        `
      : css`
          transform: scaleX(-1);
          left: ${props.isPhone ? '-24' : '-32'}px;
        `}

  ${props => props.level === 1 && `top: ${props.isPhone ? '55' : '61'}px;`}

  ${props => props.level === 2 && `top: ${props.isPhone ? '-86' : '-90'}px;`}

  ${props => props.level === 3 && `bottom: ${props.isPhone ? '56' : '60'}px;`}
`

export const SiblingContainer = styled(SvgContainer)<{ type: 'top' | 'bottom'; isPhone: boolean }>`
  left: ${props => (props.isPhone ? '160' : '176')}px;
  ${props =>
    props.type === 'top'
      ? `bottom: ${props.isPhone ? '-16' : '-32'}px;`
      : `top: ${props.isPhone ? '-16' : '-32'}px;`}
`

export const Sibling2Container = styled(SvgContainer)<{ type: 'top' | 'bottom'; isPhone: boolean }>`
  right: ${props => (props.isPhone ? '-14' : '-18')}px;
  ${props =>
    props.type === 'top' ? (props.isPhone ? `top: 55px; ` : `top: 61px; `) : `bottom: 60px;`}
`
