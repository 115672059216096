import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { ListItemHeader, ListItemSubText } from 'builder/components/List'
import Button, { ButtonSize, ButtonTheme } from 'builder/components/Button'

export const Container = styled.div`
  margin-top: 24px;
  height: 219px;
  max-height: 219px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const SkeletonContainer = styled.div`
  margin-top: 8px;
`

export const ListHeader = styled(ListItemHeader)`
  ${Typography.Caption};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
`

export const ListSubHeader = styled(ListItemSubText)`
  ${Typography.Tiny};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
`

export const ListDivider = styled.div`
  height: 1px;
  background: ${Colors.Neutral15};
`

export const ActionContainer = styled.div`
  position: relative;
`

export const ShadowEffect = styled.div`
  position: absolute;
  bottom: 35px;
  height: 32px;
  width: 100%;
  background: linear-gradient(360deg, #fff 16.56%, rgba(255, 255, 255, 0) 100%);
`

export const ActionBtn = styled(Button).attrs({
  theme: ButtonTheme.ghost,
  size: ButtonSize.small,
})`
  width: 100%;
`

export const PauseContainer = styled.div`
  height: 207px;
  display: flex;
  align-items: center;
  text-align: center;
`

export const PauseContainerText = styled.p`
  padding: 0 32px;
  ${Typography.Body}
  ${FontWeights.Regular}

  color: ${Colors.Neutral90};
`
