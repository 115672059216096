import { useQuery } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'

import { apiClient } from 'builder/modules/apiClient'
import { AIRefreshStatus, selectors, actions } from 'builder/modules/panel'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

type AiStatusResponse = {
  data: { resumeId: number; status: AIRefreshStatus }[]
}

export const useResumeStatusPolling = (id: number) => {
  const dispatch = useDispatch()
  const resumeAiJob = useTypedSelector(selectors.aiJobStatusById(id))
  const isPending = resumeAiJob.status === AIRefreshStatus.PENDING

  const getAiStatus = async () => {
    const response = await apiClient.get<AiStatusResponse>(`/resumes/ai-status`, {
      params: { ids: id },
    })
    return response.data
  }

  const updateResumeStatus = (res: AiStatusResponse) => {
    if (res?.data && res?.data[0]) {
      dispatch(
        actions.setDocumentAiJobStatus({
          id: res.data[0].resumeId,
          aiJobStatus: res.data[0].status,
        }),
      )
    }
  }

  const { data } = useQuery({
    queryKey: ['ai-resume-status', id],
    queryFn: getAiStatus,
    onSuccess: updateResumeStatus,
    enabled: !!id && isPending,
    refetchInterval: isPending ? 1000 : false,
  })

  return data?.data[0] || null
}
