import { i18n as I18n } from 'builder/utils/i18n'
import { ButtonSize } from 'builder/components/Button'
import ModalOverlay from 'builder/components/ModalOverlay'
import {
  ErrorCloseIcon,
  ErrorContiner,
  ErrorContent,
  ErrorTitle,
  ErrorTryAgainButton,
  TopWrapper,
} from './styles'

interface GenerationErrorProps {
  onClose: () => void
  onTryAgain: () => void
  ButtonSize: ButtonSize
}

export const ErrorState = ({ onClose, onTryAgain, ButtonSize }: GenerationErrorProps) => {
  return (
    <ModalOverlay overlayFadeDuration={300} contentSlideDuration={300}>
      <ErrorContiner>
        <TopWrapper>
          <ErrorTitle>{I18n.t('builder.ai_cover_letter.error_screen.title')}</ErrorTitle>
          <ErrorCloseIcon onClick={onClose} />
        </TopWrapper>
        <ErrorContent>{I18n.t('builder.ai_cover_letter.error_screen.content')}</ErrorContent>
        <ErrorTryAgainButton size={ButtonSize} isTryAgain={true} onClick={onTryAgain}>
          {I18n.t('builder.resume_optimizer.job_posting.try_again')}
        </ErrorTryAgainButton>
      </ErrorContiner>
    </ModalOverlay>
  )
}
