import { useCallback } from 'react'

import { i18n } from 'builder/utils/i18n'
import { formatDate } from 'builder/utils/formatDate'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { BriefExpertDoc, BriefLetter, BriefResume } from 'builder/modules/panel'
import { DocumentPreview } from 'builder/components/Panel/Document/styles'
import { formatDocumentPreviewUrl } from 'builder/utils/formatDocumentPreviewUrl'
import FieldSpinnerLoader from 'builder/views/ResumeDistributionView/components/FormLayout/FieldSpinnerLoader'

import {
  DESKTOP_PREVIEW_WIDTH,
  PHONE_PREVIEW_WIDTH,
} from 'builder/components/Panel/Document/constants'
import { DocumentTypes } from 'builder/modules/constants'

import {
  ChangeResumeButton,
  DocumentPreviewContainer,
  ResumeSelectionContainer,
  RightBottonShadow,
  SelectLeft,
  SelectResumeDate,
  SelectResumeTitle,
  SelectRight,
} from './styles'

type Props = {
  uploadedResume?: File
  selectedResume?: BriefResume | BriefExpertDoc | BriefLetter
  isParserLoading: boolean
  handleBuilderResumeChange: () => void
}

const TRANSLATION = 'builder.auto_apply.form.personal_information'

export const ResumePreview = ({
  uploadedResume,
  selectedResume,
  isParserLoading,
  handleBuilderResumeChange,
}: Props) => {
  const { isPhone } = useMediaQueries()

  const previewUrl = useCallback(
    document => {
      return formatDocumentPreviewUrl(document, {
        size: isPhone ? PHONE_PREVIEW_WIDTH : DESKTOP_PREVIEW_WIDTH,
      })
    },
    [isPhone],
  )

  const getThumbnailImg = () => {
    if (selectedResume?.type === DocumentTypes.trDocument && selectedResume?.thumbnail?.url) {
      return selectedResume?.thumbnail?.url
    }
    return selectedResume ? previewUrl(selectedResume) : null
  }

  const getDocumentName = () => {
    if (selectedResume?.type === DocumentTypes.trDocument && selectedResume?.title) {
      return selectedResume?.title
    }

    const name = selectedResume?.name || i18n.t('builder.dashboard.untitled')
    return selectedResume?.jobTitle ? `${name}, ${selectedResume?.jobTitle}` : name
  }

  const formatUpdateDate = () => {
    const date = selectedResume?.updatedAt || selectedResume?.createdAt || new Date().toISOString()
    return formatDate(new Date(date), 'dd MMM, HH:mm')
  }

  return (
    <ResumeSelectionContainer>
      <SelectLeft>
        <SelectResumeTitle>{getDocumentName() || uploadedResume?.name}</SelectResumeTitle>
        <SelectResumeDate>
          {i18n.t(`${TRANSLATION}.updated`, { date: formatUpdateDate() })}
        </SelectResumeDate>
        <ChangeResumeButton onClick={() => handleBuilderResumeChange()}>
          {isParserLoading ? <FieldSpinnerLoader /> : i18n.t(`${TRANSLATION}.change_resume`)}
        </ChangeResumeButton>
      </SelectLeft>
      <SelectRight>
        <DocumentPreviewContainer>
          <DocumentPreview showUnavailablePreview={false} src={getThumbnailImg()} />
        </DocumentPreviewContainer>
        <RightBottonShadow />
      </SelectRight>
    </ResumeSelectionContainer>
  )
}
