import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Icon from 'builder/components/Icon'
import { gradient } from 'builder/styles/keyframes'
import { AvatarNavigation } from 'builder/components/AvatarNavigation'
import { JobSearchSolution } from 'builder/views/AutoApply/components/JobSearchSolution/JobSearchSolution'

export const EditorPanel = styled.div`
  flex: 1;
  max-width: 860px;
  height: 100%;
  background-color: ${Colors.White};
  margin: auto;
`

export const EditorScroll = styled.div``

export const EditorContent = styled.div`
  padding: 48px 48px;
  & > div:nth-last-child(2) {
    margin-bottom: 16px;
  }

  ${Media.Phone`
    padding: 20px;
  `};
`

export const EditorSubtitle = styled.div`
  display: flex;
  justify-content: center;

  margin-bottom: ${({ withoutScore }) => (withoutScore ? '16px' : '0')};
`

export const EditorTitleSeparator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${Colors.Neutral20};
  margin-bottom: 32px;
`

export const LoaderContainer = styled.div`
  animation: ${gradient} 1s linear infinite;
  background: ${Colors.Neutral10};
  background: linear-gradient(
    to right,
    ${Colors.Neutral10} 5%,
    #eaeef6 15%,
    ${Colors.Neutral10} 25%
  );
  background-size: 100vw 100vh;
  height: 100vh;
  position: relative;
  overflow: hidden;
`

export const Loader = styled.img`
  height: 1044px;
  width: 100%;
`

export const Note = styled.a`
  display: flex;
  align-items: center;
  ${Typography.Caption};
  color: ${Colors.Neutral50};
  transition: color 0.2s;

  &:hover {
    color: ${Colors.Blue70};
  }
`

export const NoteIcon = styled(Icon.HintOutlined)`
  width: 20px;
  height: 20px;
  color: ${Colors.Blue50};
  margin-right: 8px;
`

export const NoteText = styled.span``

export const UserNavigation = styled(AvatarNavigation)`
  position: fixed;
  top: 16px;
  right: 16px;
  margin-left: 0;
  z-index: 50;

  ${Media.Laptop`
    position: absolute;
  `};
`

export const JobSearchContainer = styled.div`
  top: 6px;
  position: absolute;
  width: 270px;
  left: calc(50% - 270px);

  @media (min-width: 1900px) {
    left: calc(960px - 270px);
  }

  ${Media.Laptop`
    top: 6px;
    position: absolute;
    left: unset;
    right: 40px;
    width: 300px;
    margin-top: 0;
  `};

  ${Media.Phone`
    position: unset;
    width: unset;
    float: unset;
    margin: 24px 20px 5px;
  `}
`

export const VerticalDivider = styled.div`
  display: none;
  width: 1px;
  border-right: 1px solid ${Colors.Neutral15};
  height: 24px;
  margin: 0 24px;

  ${Media.Laptop`
    display: block;
    position: absolute;
    right: 0;
    top: 15px;
  `};

  ${Media.Phone`
    display: none;
  `}
`

export const JobSearch = styled(JobSearchSolution)`
  width: fit-content;

  ${Media.Laptop`
    position: relative;
  `}

  ${Media.Phone`
    position: relative;
    width: 100%;
  `}
`

export const Separator = styled.div`
  margin-bottom: 24px;
`

export const RegenerateSection = styled.div`
  display: flex;
  align-items: center;
  ${Media.Phone`
    flex-direction: column;
  `};
`

export const RemainingAttemptsText = styled.p`
  ${Typography.Body};
  color: ${Colors.Indigo60};
  margin-left: 16px;
  ${Media.Phone`
    margin-top: 16px;
  `};
`
