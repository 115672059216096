import * as React from 'react'
import { useI18n } from 'builder/hooks/useI18n'
import { TextField } from 'builder/components/TextField'
import EditorField from 'builder/components/EditorField'
import { useDispatch } from 'react-redux'
import { actions as editorActions } from 'builder/modules/resumeEditor'
import PropTypes from 'prop-types'
export function PostalCode(props) {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const { postalCode } = props
  const handleSimpleFieldUpdate = React.useCallback(
    event => {
      const { name, value } = event.target
      dispatch(editorActions.updateSimpleField({ name, value, debounce: true }))
    },
    [dispatch],
  )

  return (
    <>
      {/* ZIP Code */}
      <EditorField>
        <TextField
          label={i18n.t('builder.resume_editor.postal_code')}
          value={postalCode || ''}
          name="postalCode"
          onChange={handleSimpleFieldUpdate}
        />
      </EditorField>
    </>
  )
}
PostalCode.propTypes = {
  postalCode: PropTypes.string,
}
