import styled, { keyframes } from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import { Icon24 } from 'builder/components/Icon'
import { Settings3 } from 'builder/components/Icon/32x32'
import { Z_INDEX } from '../../constants'

const fadeIn = keyframes`
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`

export const Container = styled.div<{ isPremium?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
`

export const UserDropdownTrigger = styled.button<{ isOpen: boolean; color?: string }>`
  position: relative;
  display: flex;
  overflow: hidden;
  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  color: ${props => (props.color ? props.color : Colors.Neutral50)};

  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
  }
`

export const TriggerIcon = styled(Settings3)``

export const DropdownContainer = styled.menu`
  box-shadow: 0px 0px 1px 0px rgba(15, 56, 113, 0.32), 0px 6px 8px -2px rgba(15, 56, 113, 0.02),
    0px 16px 64px -8px rgba(15, 56, 113, 0.18);
  position: absolute;
  z-index: ${Z_INDEX + 1};
  right: 0;
  top: calc(100% + 12px);
  padding: 0;
  margin: 0;
  width: 352px;
  border-radius: 16px;
  background-color: ${Colors.White};

  transform-origin: 100% 0;
  animation: ${fadeIn} 0.25s;
  animation-fill-mode: forwards;
`

export const ProfileContainer = styled.div`
  padding: 24px 20px 0 20px;
`

export const ProfileContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${Colors.Neutral15};
`

export const ProfileName = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};
  color: ${Colors.Neutral100};
  transition: color 0.2s;
`

export const ProfileNote = styled.div`
  ${Typography.Body};
  color: ${Colors.Neutral50};
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Links = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  gap: 4px;
  padding: 0 12px 12px;
`

export const LinkContainer = styled.div`
  display: flex;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${Colors.Neutral5};
  }
`

export const SettingsIcon = styled(Icon24.Settings2)`
  color: ${Colors.Neutral90};

  &:hover {
    background-color: ${Colors.Neutral5};
  }
`

export const FaqIcon = styled(Icon24.Info)`
  color: ${Colors.Neutral90};
`

export const LogOutIcon = styled(Icon24.LogOut)`
  color: ${Colors.Neutral90};
`

export const Link = styled.p`
  display: block;
  color: ${Colors.Neutral90};
  transition: color 0.2s;
  cursor: pointer;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  text-align: start;
`
