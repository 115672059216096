import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { actions as editorActions } from 'builder/modules/resumeEditor'
import Colors from 'builder/styles/colors'
import { Job } from 'builder/modules/jobTracking'
import { LOW_SCORE } from 'builder/components/Helper/constants'
import { TunerScore } from '../TunerScore'
import {
  JobInfo,
  Header,
  HeaderText,
  Body,
  SecondaryText,
  ButtonContainer,
  PrimaryButton,
  MarketingImageContainer,
  MarketingImage,
  Card,
} from './styles'
import marketingImage from './marketingImage.svg'

type ImproveResumeScoreProps = {
  job: Job
  fromSidebar: boolean
  open: boolean
  hovered?: boolean
}

const ImproveResumeScore = ({ job, fromSidebar, open, hovered }: ImproveResumeScoreProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { resumeId, resumeScore: score } = job

  const backgroundColor = useMemo(() => {
    if (score >= LOW_SCORE) return hovered ? '#FFEDC5' : Colors.Amber10
    return hovered ? '#FFDFE2' : Colors.Red10
  }, [score, hovered])

  const handleImproveResume = useCallback(() => {
    dispatch(editorActions.setFromJobTracker(true))

    trackInternalEvent('click_improve_resume')
    const editorUrl = `/resumes/${resumeId}/edit`
    navigate(editorUrl)
  }, [dispatch, navigate, resumeId])

  const tunerScoreProps = useMemo(() => {
    return {
      score: score,
      fromSidebar: fromSidebar,
    }
  }, [score, fromSidebar])

  const secondaryTextColor = useMemo(() => {
    if (score >= LOW_SCORE) return Colors.Amber70
    return Colors.Red70
  }, [score])

  const primaryButtonScore = useMemo(() => {
    if (score >= LOW_SCORE) return 'medium'
    return 'low'
  }, [score])

  return (
    <Card backgroundColor={backgroundColor} open={open}>
      <JobInfo>
        <Header>
          <HeaderText color={secondaryTextColor}>Your resume score is quite low</HeaderText>
        </Header>
        <Body>
          <SecondaryText color={secondaryTextColor}>
            Go to resume builder to improve it for this job.
          </SecondaryText>
        </Body>
        <ButtonContainer>
          <PrimaryButton onClick={handleImproveResume} score={primaryButtonScore}>
            Improve Your Resume
          </PrimaryButton>
        </ButtonContainer>
      </JobInfo>
      {score && <TunerScore {...tunerScoreProps} />}
      <MarketingImageContainer>
        <MarketingImage score={primaryButtonScore} src={marketingImage} />
      </MarketingImageContainer>
    </Card>
  )
}

export default ImproveResumeScore
