import { useMutation } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { baseClient } from 'builder/modules/apiClient'
import { actions } from 'builder/modules/autoApply/autoApplyModule'
import { AutoApplyForm, Profile } from 'builder/modules/autoApply/types'
import { mapCareerResponse } from './mappers'

type ResumeSelect = {
  resumeId?: number
  trResumeId?: number
}

export const useSelectResume = (onSuccess: (data: AutoApplyForm) => void) => {
  const dispatch = useDispatch()
  return useMutation({
    mutationFn: async ({ resumeId, trResumeId }: ResumeSelect) => {
      const payload: ResumeSelect = {}

      if (trResumeId) {
        dispatch(actions.selectTRResume(trResumeId))
        payload.trResumeId = trResumeId
      } else {
        dispatch(actions.selectResume(resumeId))
        payload.resumeId = resumeId
      }

      const response = await baseClient.post<Profile>(`/career/v1/profile/upload-resume`, payload)
      dispatch(actions.updateForm(response.data))
      return mapCareerResponse(response.data)
    },
    onSuccess,
  })
}
