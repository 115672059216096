import styled, { CSSProp } from 'styled-components'
import { Colors } from 'builder/styles/colors'

type LightBlockType = {
  variant: CSSProp
}

export const LightBlock = styled.div<LightBlockType>`
  ${p => p.variant};
  border-radius: 6px;
  background: white;
  width: 256px;
  height: 128px;
  margin: 15px 15px 0 0;
`

export const DarkBlock = styled(LightBlock)`
  background: ${Colors.Neutral90};
`

export const BlocksGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
