import save from '../assets/save.svg'
import edit from '../assets/edit.svg'
import organize from '../assets/organize.svg'

export const PROMOTO_BANNER_STEPS = [
  {
    imgUrl: save,
    title: 'builder.promote_banners.chrome_extension.save.title',
    subtitle: 'builder.promote_banners.chrome_extension.save.subtitle',
    caption: 'builder.promote_banners.chrome_extension.save.caption',
  },
  {
    imgUrl: edit,
    title: 'builder.promote_banners.chrome_extension.edit.title',
    subtitle: 'builder.promote_banners.chrome_extension.edit.subtitle',
    caption: 'builder.promote_banners.chrome_extension.edit.caption',
  },
  {
    imgUrl: organize,
    title: 'builder.promote_banners.chrome_extension.organize.title',
    subtitle: 'builder.promote_banners.chrome_extension.organize.subtitle',
    caption: 'builder.promote_banners.chrome_extension.organize.caption',
  },
]
