import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Sizes from 'builder/styles/sizes'
import Media from 'builder/styles/media'

export const Counter = styled.span`
  ${Typography.Tiny};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral90};
`

export const Container = styled.button`
  ${Typography.Body};
  display: flex;
  align-items: center;
  gap: ${Sizes['2XS']};
  color: ${Colors.Neutral80};
  transition: color 0.2s ease-out;
  padding: 12px;
  background: ${Colors.White};
  border: none;
  outline: none;
  cursor: pointer;

  ${Media.Hover`
    :hover {
      background: ${Colors.White};
      color: ${Colors.Indigo60};
    }

    :hover ${Counter} {
      color: ${Colors.Indigo70};
    }
  `}
`

export const AiIconBadge = styled.div`
  display: flex;
  padding: 0 2px;
  color: ${Colors.Indigo50};
  background: ${Colors.Indigo10};
  border-radius: 4px;
`
