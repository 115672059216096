import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const TopResumeVisualContainer = styled.div`
  padding: 25px;
`
export const TopResumeVisualFooterWrapperContainer = styled.div`
  margin-top: -14%;
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 27px;
  ${Media.Phone`
    bottom: 17px;
  `};
`

export const TopResumeVisualWrapper = styled.img`
  margin-left: -20px;
  margin-top: 20px;
`

export const TopResumeVisualWrapperContainer = styled.div`
  padding: 15px;
  display: flex;
  justify-content: center;
`

export const Title = styled.div`
  color: ${Colors.Neutral80};
  ${Typography.Body};
  ${FontWeights.DemiBold};
  display: flex;
  padding-bottom: 4px;
  align-items: center;
`

export const Text = styled.div`
  color: ${Colors.Neutral80};
  ${Typography.Caption};
  ${FontWeights.Regular};
`
