import styled, { css } from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import DefaultButton from 'builder/components/Button'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

const flexColumn = css`
  display: flex;
  flex-flow: column;
`

export const Container = styled.div`
  ${flexColumn};
  gap: 12px;
  justify-content: space-between;
  background-color: ${Colors.Neutral5};
  border-radius: 16px;
  overflow: hidden;

  ${Media.Phone`
    border-radius: 12px;
  `}
`

export const MainContainer = styled.div<{ $isCardEmpty: boolean }>`
  --bottom-card-padding: 64px;

  ${flexColumn};
  padding: var(--bottom-card-padding) var(--bottom-card-padding) 0;
  gap: 24px;

  ${props =>
    props.$isCardEmpty &&
    css`
      padding: var(--bottom-card-padding);
    `}

  ${Media.Tablet`
    --bottom-card-padding: 48px;
  `}

  ${Media.Phone`
    --bottom-card-padding: 24px;
    gap: 20px;
  `}
`

export const TextContainer = styled.div`
  ${flexColumn};
  gap: 16px;

  ${Media.Phone`
    gap: 12px;
  `}
`

export const Header = styled.div`
  ${Typography.S};
  ${FontWeights.Medium};
`

export const Text = styled.div`
  ${Typography.Caption};
`

export const Button = styled(DefaultButton)`
  align-self: flex-start;
`
