import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'

export const Container = styled.div`
  gap: 32px;
  display: flex;
  position: relative;
  padding-top: 16px;
`

export const TabletContainer = styled.div`
  padding: 16px 0 80px;
  position: relative;
  margin-top: -10px;

  & .swiper-container {
    position: relative;
    overflow: visible;
  }

  & .swiper-wrapper {
    display: flex;
    position: relative;
  }

  & .swiper-slide:not(:last-child) {
    margin-right: 32px;

    ${Media.Phone`
      margin-right: 24px;
      margin-bottom: 90px;
    `}
  }

  & .career-matrix-scrollbar {
    width: 100%;
    border-radius: 10px;
    background: ${Colors.Neutral15};
  }

  & .swiper-scrollbar-drag {
    width: 100%;
    height: 2px;
    background: ${Colors.Blue50};
  }

  & .swiper-scrollbar-cursor-drag {
    cursor: move;
  }

  ${Media.Tablet`
    padding: 0 0 160px 0;
  `}

  ${Media.Phone`
    margin-top: 2px;
    padding: 0 0 90px 0;
  `}
`

export const ScrollbarContainer = styled.div`
  width: 100%;
  padding: 20px 20px 42px;
  background: ${Colors.White};
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1;
  height: 64px;

  ${Media.Phone`
    height: 48px;
    padding: 20px;
  `}
`
