import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Sizes from 'builder/styles/sizes'
import Button from 'builder/components/Button'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: ${Colors.White};
  border-radius: ${Sizes.S};
  padding: ${Sizes['2XL']} ${Sizes.L} ${Sizes.L};
  height: 752px;

  ${Media.Phone`
    padding: ${Sizes.L} 18px;
  `}
`

export const Title = styled.p`
  ${Typography.S};
  ${FontWeights.DemiBold};
  margin-top: 23px;
  text-align: center;
  max-width: 382px;
  width: 100%;

  ${Media.Phone`
    max-width: 208px;
  `}
`

export const Logo = styled.img`
  width: 180px;
  height: 148px;
`

export const Close = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  top: 20px;
  right: 20px;
  width: 28px;
  height: 28px;
  color: ${Colors.Neutral50};
  background: ${Colors.Neutral10};
  border-radius: ${Sizes.L};
  transition: color 0.15s;

  &:hover {
    color: ${Colors.Blue50};
  }
`

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Sizes.S};
  margin-top: ${Sizes.L};
`

export const BackButton = styled(Button)`
  margin-top: auto;
  margin-right: auto;
`
