import styled from 'styled-components'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'

export const Container = styled.div`
  width: 416px;
  gap: 32px;
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: center;
  padding-top: 40px;

  ${Media.Phone`
    width: 335px;
  `};
`

export const Content = styled.div`
  width: 416px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: center;
  margin: auto;
  align-items: flex-start;
  padding: 0px;
  align-items: flex-start;

  ${Media.Phone`
    width: 335px;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  `};
`

export const CareerEntryImage = styled.img`
  width: 350px;
  height: 142.773px;
  flex-shrink: 0;
  margin: auto;

  ${Media.Tablet`
    margin:auto;
  `};

  ${Media.Phone`
    width: 245.14px;
    height: 100px;
    margin:auto;
  `};
`

export const Heading = styled.div`
  ${Typography.S};
  ${FontWeights.DemiBold};
  width: 352px;
  margin: auto;

  ${Media.Phone`
    width: 335px;
    height: 60px;
    margin:auto;
  `};
`

export const SubHeader = styled.div`
  color: ${Colors.Neutral90};
  width: 374px;
  margin: auto;

  ${Media.Phone`
    width: 335px;
    margin:auto;
  `};
`

export const Name = styled.div`
  ${FontWeights.DemiBold};
`

export const ButtonContainer = styled.div`
  width: 100%;
`

export const Footer = styled.div`
  position: fixed;
  left: 0;
  width: 100%;
  bottom: 48px;
  display: flex;
  justify-content: center;

  ${Media.Phone`
    flex-direction: column;
  `};
`

export const Description = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
  display: flex;

  ${Media.Phone`
    justify-content: center;
  `};
`

export const SupportUrl = styled.a`
  ${Typography.Caption};
  color: ${Colors.Blue50};
  margin: 0 5px;
`
