import styled from 'styled-components'
import Media from 'builder/styles/media'
import Shadows from 'builder/styles/shadows'
import Colors from 'builder/styles/colors'

export const Container = styled.div`
  position: relative;
  padding-bottom: 32px;
  flex: 0 0 auto;
`

export const CaourseImage = styled.div`
  height: 236px;

  ${Media.Tablet`
    height: 240px;
  `}

  ${Media.Phone`
    height: 224px;
  `}
`

export const Image = styled.img`
  width: 100%;
  border-radius: 16px 16px 0 0;
  height: 100%;
  object-fit: cover;
`

export const LogoContainer = styled.div`
  ${Shadows.light.mid};
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 32px;
  border-radius: 50%;
  background-color: ${Colors.White};

  ${Media.Phone`
    width: 56px;
    height: 56px;
   `}
`

export const Logo = styled.img`
  width: 100%;
  height: 100%;
  max-width: 32px;
  max-height: 32px;

  ${Media.Phone`
    max-width: 24px;
    max-height: 24px;
   `}
`
