import EditorField from 'builder/components/EditorField'
import { TextField } from 'builder/components/TextField'
import { useWorkExperienceCardContext } from '../../ExpandableCardContext'
import { useShokumuTranslations } from '../../useShokumuTranslations'

const Employer: React.FC = () => {
  const { item, onCardValueChange } = useWorkExperienceCardContext()
  const { i18n } = useShokumuTranslations()
  const label = i18n('work_experience.employment_details.employer')

  const updateEmployerDetails = (e: React.ChangeEvent<HTMLInputElement>) => {
    onCardValueChange({ employer: e.target.value }, true)
  }

  return (
    <EditorField>
      <TextField
        label={label}
        name="employer"
        onChange={updateEmployerDetails}
        value={item.employer || ''}
      />
    </EditorField>
  )
}
export default Employer
