import { useEvent } from './useEvent'
import { isIntersecting, useClickOutside, ClickOutsideRef } from './useClickOutside'

export const useClickOutsideExcept = <T extends HTMLElement>(
  ref: ClickOutsideRef<T>,
  exceptRefs: ClickOutsideRef<T>[],
  handler: (event: MouseEvent) => void,
) => {
  const clickOutsideCallback = useEvent(handler)

  useClickOutside(ref, event => {
    const isIntersectingSomeElement = exceptRefs.some(exceptRef =>
      isIntersecting(exceptRef.current, event),
    )

    if (!isIntersectingSomeElement) {
      clickOutsideCallback(event)
    }
  })
}
