import { useCallback, useState } from 'react'
import { Transition } from 'react-transition-group'
import { components, MultiValueRemoveProps } from 'react-select'
import AsyncSelect from 'react-select/async'
import { Icon20 } from 'builder/components/Icon'
import { FieldNote, FieldNoteProps } from '../FieldNote'
import {
  Bar,
  Container,
  InputWrapper,
  Item,
  Label,
  LabelContainer,
  LabelContent,
  Required,
} from './styles'

type SelectItem = {
  value: string
  label: string
}

type Props = {
  list: SelectItem[]
  selectedList: SelectItem[]
  onSelect: (item: SelectItem) => void
  onUnselect: (newValue: SelectItem[]) => void
  className?: string
  label?: string
  active?: boolean
  showLimit?: number
  placeholder?: string
} & FieldNoteProps

export const ClusterPicker = (props: Props) => {
  const {
    list,
    selectedList,
    onSelect,
    onUnselect,
    description,
    error,
    errorNoMsg,
    required,
    warning,
    active: isActive,
    className,
    label,
    showLimit,
    placeholder = '',
  } = props
  const [isFocused, toggleFocus] = useState(false)

  const handleFocus = useCallback(() => {
    toggleFocus(true)
  }, [])

  const handleBlur = useCallback(() => {
    toggleFocus(false)
  }, [])

  const hasError = !!error || errorNoMsg
  const hasWarning = !!warning

  const isBarVisible = isFocused || isActive || hasError || hasWarning

  const MultiValueRemove = (props: MultiValueRemoveProps<SelectItem>) => {
    return (
      <div>
        <components.MultiValueRemove {...props}>
          <Icon20.Close />
        </components.MultiValueRemove>
      </div>
    )
  }

  return (
    <div className={className}>
      {label && (
        <LabelContainer>
          <Label $hasError={hasError}>
            <span>
              <LabelContent>{label}</LabelContent>
              {required && <Required> *</Required>}
            </span>
          </Label>
        </LabelContainer>
      )}
      <InputWrapper>
        <AsyncSelect<SelectItem, boolean>
          onBlur={handleBlur}
          onFocus={handleFocus}
          components={{ MultiValueRemove }}
          value={selectedList}
          isMulti
          isSearchable
          autoFocus
          classNamePrefix="select"
          placeholder={placeholder}
          openMenuOnClick={false}
          loadOptions={async val => list.filter(item => item.label.toLowerCase().includes(val))}
          onChange={newValue => {
            onUnselect(Array.isArray(newValue) ? newValue : [newValue])
          }}
        />
        <Transition in={isBarVisible} timeout={100}>
          {state => <Bar $in={state} $hasError={hasError} $hasWarning={hasWarning} />}
        </Transition>
      </InputWrapper>
      <FieldNote error={error} warning={warning} description={description} />
      <Container>
        {list.slice(0, showLimit).map(item => (
          <Item key={item.value} onClick={() => onSelect(item)}>
            {item.label}
          </Item>
        ))}
      </Container>
    </div>
  )
}
