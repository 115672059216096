import styled, { css } from 'styled-components'

// --
// Animations
// --
const SLIDE_EASING = 'cubic-bezier(0.645, 0.045, 0.355, 1)'

const slideAnimation = css`
  &.route-transition-switch-slide-enter {
    transform: ${({ direction }) =>
      direction === 'forward' ? 'translateX(600px)' : 'translateX(-600px)'};
    opacity: 0;
    transition: transform ${({ duration }) => duration}ms ${SLIDE_EASING},
      opacity ${({ duration }) => duration}ms ${SLIDE_EASING};
  }

  &.route-transition-switch-slide-enter.route-transition-switch-slide-enter-active {
    opacity: 1;
    transform: translateX(0);
  }

  &.route-transition-switch-slide-exit {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    transform: translateX(0);
    opacity: 1;
    transition: transform ${({ duration }) => duration}ms ${SLIDE_EASING},
      opacity ${({ duration }) => duration}ms ${SLIDE_EASING};
  }

  &.route-transition-switch-slide-exit.route-transition-switch-slide-exit-active {
    transform: ${({ direction, animationEffect }) =>
      direction === 'forward' ? 'translateX(-600px)' : 'translateX(600px)'};
    opacity: 0;
  }
`

const fadeAnimation = css`
  &.route-transition-switch-fade-enter {
    opacity: 0;
    transition: opacity ${({ duration }) => duration}ms ease;
  }

  &.route-transition-switch-fade-enter.route-transition-switch-fade-enter-active {
    opacity: 1;
  }

  &.route-transition-switch-fade-exit {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 1;
    transition: opacity ${({ duration }) => duration}ms ease;
  }

  &.route-transition-switch-fade-exit.route-transition-switch-fade-exit-active {
    opacity: 0;
  }
`

// --
// Components
// --

export const TransitionWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`

export const AnimationWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  will-change: opacity, transform;

  ${slideAnimation};
  ${fadeAnimation};
`
