import { memo } from 'react'
import { i18n as I18n } from 'builder/utils/i18n'
import { useAppSelector } from 'builder/hooks/useAppSelector'
import { AIResumeFlows, AIResumeLinkedInSteps } from 'builder/modules/constants'
import aiStarsImg from '../../../../../../app/assets/images/builder/ai-resume/ai-stars.svg'
import useResumeWithAI from '../useResumeWithAI'
import { AIAssitedBadge, AIResumeHeader, AIResumeHeaderAlt, AIResumeHeaderIcon } from './styles'

export const AIAssistedHeader = memo(() => {
  const { step } = useResumeWithAI()
  const {
    aiResumeFlow,
    linkedInData: { currentStep },
  } = useAppSelector(store => store.resumeEditor.aiResume)

  // For work experience mobile screen, the bottom margin of Header is less than default
  const ResumeHeader = step === 1 ? AIResumeHeaderAlt : AIResumeHeader

  if (
    aiResumeFlow === AIResumeFlows.LinkedIn &&
    currentStep === AIResumeLinkedInSteps.linkedInError
  ) {
    // No AI Assited header for LinkedIn Error screen
    return null
  }

  return (
    <ResumeHeader>
      <AIResumeHeaderIcon src={aiStarsImg}></AIResumeHeaderIcon>
      <AIAssitedBadge>{I18n.t('builder.ai_resume_draft.ai_assisted_resume')}</AIAssitedBadge>
    </ResumeHeader>
  )
})
