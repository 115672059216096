import { i18n } from 'builder/utils/i18n'
import Colors from 'builder/styles/colors'

interface IProps {
  totalSteps: number
  completedSteps: number
}

const CircularProgressBar = ({ totalSteps, completedSteps }: IProps) => {
  const radius = 52
  const circumference = 2 * Math.PI * radius
  const progress = (completedSteps / totalSteps) * circumference

  return (
    <svg width="104" height="104" viewBox="-10 -10 120 120">
      <circle cx="52" cy="52" r="52" fill="none" stroke={Colors.Neutral15} strokeWidth="6" />
      <circle
        cx="52"
        cy="52"
        r="52"
        fill="none"
        stroke={Colors.Indigo50}
        strokeWidth="6"
        strokeDasharray={`${circumference} ${circumference}`}
        strokeDashoffset={circumference - progress}
        transform="rotate(-90 52 52)"
      />
      <text
        x="52"
        y="45"
        fill="#000"
        fontSize="33px"
        fontWeight={600}
        textAnchor="middle"
        alignmentBaseline="central"
      >
        {i18n.t(`builder.navigation.post_premium_modal.days_left_count`, {
          days_left_count: completedSteps,
        })}
      </text>
      <text
        x="52"
        y="67"
        fill={Colors.Neutral50}
        fontSize="16px"
        fontWeight={500}
        textAnchor="middle"
        alignmentBaseline="central"
      >
        {i18n.t(`builder.navigation.post_premium_modal.days_left`)}
      </text>
    </svg>
  )
}

export default CircularProgressBar
