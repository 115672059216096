import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Icon20 } from 'builder/components/Icon'
import Button from 'builder/components/Button'
import Media from 'builder/styles/media'

export const SuccessIcon = styled(Icon20.Tick)`
  fill: ${Colors.White};
  background: ${Colors.Green50};
  border-radius: 50%;
  height: 16px;
  width: 16px;
  position: relative;
  top: 10px;
  right: 10px;
`

export const JobPostingTitle = styled.div`
  ${Typography.Subhead};
  ${FontWeights.DemiBold}
  width: 480px;
  margin-bottom: 12px;
  color: ${Colors.Neutral90};

  ${Media.Phone`
    ${Typography.S};
    ${FontWeights.DemiBold}
    width: 303px;
    height: 48px;
  `}
`

export const JobPostingStatus = styled.div`
  ${Typography.Caption};
  ${FontWeights.Regular}
  color: ${Colors.Neutral50};
  margin-top: 4px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 32px;

  ${Media.Phone`
    margin-top:20px;
  `}
`

export const CancelButton = styled(Button)`
  margin-right: 8px;
`

export const ContinueButton = styled(Button)``
