import styled from 'styled-components'
import Media from 'builder/styles/media'
import { Colors } from 'builder/styles/colors'
import { Typography } from 'builder/styles/typography'

export const Circle = styled.div`
  position: absolute;
  left: calc(50% - 32px);
  top: calc(20%);
  width: 64px;
  height: 64px;
  border-radius: 50%;
  color: ${Colors.Neutral30};
  background-color: ${Colors.Neutral10};
  transition: background-color 0.15s, color 0.15s;

  &:after,
  &:before {
    content: '';
    position: absolute;
    top: calc(50% - 1px);
    left: calc(50% - 12px);
    width: 24px;
    height: 2px;
    background-color: currentColor;
  }

  &:after {
    transform: rotate(90deg);
  }

  ${Media.Phone`
    top: calc(26%);
  `}
`

export const Text = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: calc(53%);
`

export const Title = styled.div`
  ${Typography.Subhead};
  text-align: center;
  color: ${Colors.Neutral40};
`

export const SubTitle = styled.div`
  ${Typography.Tiny};
  max-width: 320px;
  text-align: center;
  color: ${Colors.Neutral40};
`

export const Container = styled.div`
  width: 544px;
  min-height: 256px;
  cursor: pointer;
  border-radius: 16px;
  background: ${Colors.White};
  border: 1px solid ${Colors.Neutral10};
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:active {
    background-color: ${Colors.Blue10};

    ${Title} {
      color: ${Colors.Blue60};
    }

    ${SubTitle} {
      color: ${Colors.Neutral50};
    }

    ${Circle} {
      color: ${Colors.Blue60};
      background-color: ${Colors.Blue20};
    }
  }

  &:hover {
    border-color: ${Colors.Blue50};

    ${Title} {
      color: ${Colors.Blue50};
    }

    ${Circle} {
      color: ${Colors.Blue50};
      background-color: ${Colors.Blue10};
    }
  }

  ${Media.Tablet`
    width: 100%;
  `}

  ${Media.Phone`
    width: 100%;
    height: 335px;
  `}
`
