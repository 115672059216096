import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Container = styled.div``

export const Title = styled.div`
  ${Typography.Subhead};
  font-weight: 600;
  margin-bottom: 8px;
`

export const List = styled.div`
  position: relative;
  left: -4px;
  display: flex;
  flex-flow: row wrap;
`

export const Item = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: 12px;
  user-select: none;
  width: calc(50% - 32px);
  flex: 0 0 calc(50% - 32px);
  margin-right: 32px;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  color: ${props => (props.disabled ? Colors.Neutral50 : Colors.Neutral90)};

  &:hover {
    color: ${props => (props.disabled ? Colors.Neutral50 : Colors.Blue50)};
  }

  &:nth-child(2n) {
    margin-right: 0;
  }

  ${Media.Phone`
    width: 100%;
    flex: 0 0 100%;
    margin-right: 0;
  `};

  &:before {
    content: '';
    margin-right: 8px;
    display: block;
    width: 40px;
    height: 40px;
    background-image: url(${props =>
      require(`images/builder/resume-editor/section-${props.type}${
        props.disabled ? '-gray' : ''
      }.svg`)});
  }
`
