import { memo } from 'react'
import {
  LimitedFeatureContainer,
  LimitedFeatureCard,
  ImageWrapper,
  LimitedFeatureImage,
  LimitedFeaturesText,
} from '../styles'

import DocumentLimitImg from '../assets/document_limit.svg'
import DownloadLimitImg from '../assets/download_limit.svg'
import AttemptInterviewImg from '../assets/attempt_limit.svg'

const LimitedFeatureView = () => {
  const FeatureViewData = [
    {
      featureTitle: (
        <>
          Only <b>1 resume</b> and <b>1 cover letter</b>
        </>
      ),
      featureIcon: DocumentLimitImg,
    },
    {
      featureTitle: (
        <>
          Only <b>3 attempts for AI usage</b>, now you have unlimited
        </>
      ),
      featureIcon: AttemptInterviewImg,
    },
    {
      featureTitle: <>No downloads or export for you documents, only share link</>,
      featureIcon: DownloadLimitImg,
    },
  ]

  return (
    <LimitedFeatureContainer>
      {FeatureViewData.map(({ featureIcon, featureTitle }, index) => (
        <LimitedFeatureCard key={index}>
          <ImageWrapper>
            <LimitedFeatureImage src={featureIcon} alt="" />
          </ImageWrapper>
          <LimitedFeaturesText>{featureTitle}</LimitedFeaturesText>
        </LimitedFeatureCard>
      ))}
    </LimitedFeatureContainer>
  )
}

export default memo(LimitedFeatureView)
