import styled from 'styled-components'

import Media from 'builder/styles/media'
import Spinner from 'builder/components/Spinner'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Button from 'builder/components/Button'

export const Container = styled.div`
  margin: 56px auto;
  display: flex;
  gap: 128px;

  ${Media.Tablet`
    width: 488px;
    flex-direction: column;
    margin: 24px auto;
    gap: 32px;
  `}

  ${Media.Phone`
    width: 100%;
    flex-direction: column;
    padding: 0 20px;
    margin: 24px auto;
    gap: 12px;
  `}
`

export const InfoBox = styled.div`
  width: 448px;

  ${Media.Tablet`
    width: 488px;
  `}

  ${Media.Phone`
    width: 100%;
  `}
`

export const InfoHeadContiner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const InfoHeader = styled.div`
  color: ${Colors.Neutral90};

  ${Typography.L}
  ${FontWeights.Medium}
`

export const InfoSubHeader = styled.div`
  color: ${Colors.Neutral50};

  ${Typography.Body}
  ${FontWeights.Regular}
`

export const InfoItems = styled.div`
  margin: 32px 0;
  display: flex;
  gap: 12px;
  flex-direction: column;
`

export const InfoItem = styled.div`
  display: flex;
  gap: 4px;
`

export const InfoItemText = styled.div`
  color: ${Colors.Neutral80};

  ${Typography.Body}
  ${FontWeights.Regular}
`

export const IconContainer = styled.div``
export const InfoItemSpinner = styled(Spinner)`
  width: 15px;
  height: 23px;
  margin-right: 4px;
`

export const ActionButton = styled(Button)`
  width: 100%;
`

export const AnimationBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 544px;
  border-radius: 16px;
  background: ${Colors.Neutral5};

  ${Media.Tablet`
    width: 488px;
  `}

  ${Media.Phone`
    width: 100%;
  `}
`

export const Animation = styled.div`
  width: 544px;
  height: 474px;
  background-position: top;
  border-radius: 16px;
  overflow: hidden;
  position: relative;

  ${Media.Tablet`
    width: 488px;
    height: 300px;
  `}

  ${Media.Phone`
    width: 100%;
    height: 209px;
  `}
`

export const Video = styled.video`
  position: absolute;
  height: inherit;
  width: inherit;

  ${Media.Tablet`
    width: 488px;
    height: unset;
  `}

  ${Media.Phone`
    width: inherit;
    height: unset;
  `}
`

export const AnimationShadow = styled.div`
  position: absolute;
  bottom: 0;
  width: inherit;
  height: 160px;
  border-radius: 0px 0px 16px 16px;
  background: linear-gradient(180deg, rgba(247, 249, 252, 0) 0%, #f7f9fc 76.74%);

  ${Media.Tablet`
    height: 95px;
  `}

  ${Media.Phone`
    height: 32px;
  `}
`
