import { Step, CallBackProps } from 'react-joyride'
import { useDispatch } from 'react-redux'

import { useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import { actions } from 'builder/modules/panel'
import { actions as userActions } from 'builder/modules/user'

import { JoyRide } from 'builder/components/JoyRide'
import { setSpotlightsStyles } from 'builder/components/JoyRide/utils'

import { useI18n } from 'builder/hooks/useI18n'
import { useUser } from 'builder/hooks/useUser'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'

const TRANSLATION = 'builder.dashboard_v2.onboarding'

export const Onboarding = () => {
  const user = useUser()
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const { isPhone } = useMediaQueries()
  const [searchParams] = useSearchParams()

  const steps: Step[] = [
    {
      title: i18n.t(`${TRANSLATION}.step_1.title`),
      placement: 'top',
      disableBeacon: true,
      target: '#first-step-onboarding',
      content: i18n.t(`${TRANSLATION}.step_1.sub_title`),
    },
    {
      title: i18n.t(`${TRANSLATION}.step_2.title`),
      placement: 'top-start',
      target: '#documents',
      content: i18n.t(`${TRANSLATION}.step_2.sub_title`),
    },
    {
      title: i18n.t(`${TRANSLATION}.step_3.title`),
      placement: isPhone ? 'top' : 'right',
      target: isPhone ? '#bottom-menu-mobile' : '#side-menu-desktop',
      styles: !isPhone ? { spotlight: { marginLeft: -10, marginTop: 10 } } : {},
      content: i18n.t(`${TRANSLATION}.step_3.sub_title`),
    },
    {
      title: i18n.t(`${TRANSLATION}.step_4.title`),
      placement: isPhone ? 'bottom-end' : 'auto',
      target: isPhone ? '#mobile-profile-menu-onboarding' : '#profile-menu-onboarding',
      styles: { spotlight: { borderRadius: 12 } },
      content: i18n.t(`${TRANSLATION}.step_4.sub_title`),
    },
  ]

  if (!isPhone) {
    steps.push({
      title: i18n.t(`${TRANSLATION}.step_5.title`),
      placement: 'right-end',
      styles: {
        spotlight: { borderRadius: 12 },
        tooltipContainer: { position: 'absolute', bottom: -60 },
      },
      target: '#side-menu-chrome-extenstion',
      content: i18n.t(`${TRANSLATION}.step_5.sub_title`),
    })
  }

  const setTourStatus = (activate: boolean) => {
    dispatch(actions.setUIStatesConfigStatus({ dashv2_tour_completed: activate }))
    dispatch(userActions.setUIUserState({ dashv2TourCompleted: activate }))
  }

  const onCallback = (data: CallBackProps) => {
    if (['skip', 'close'].includes(data.action) && !user?.uiStates?.dashv2TourCompleted) {
      setTourStatus(true)
    }

    if (data.lifecycle === 'tooltip') {
      if (data.index === 3 && !isPhone) {
        setSpotlightsStyles({ marginTop: '4px', left: '2px', width: '55px', height: '60px' })
      }

      if (data.index === 4 && !isPhone) {
        setSpotlightsStyles({ marginTop: '4px', left: '4px', width: '52px', height: '52px' })
      }
    }
  }

  const isRDModalParametersPresent = () => {
    return (
      new URLSearchParams(searchParams).has('rd_modal') ||
      new URLSearchParams(searchParams).has('rd_promo')
    )
  }

  const isUserProvisioned = () => {
    return searchParams.get('onboarding') || searchParams.get('ai-refresh')
  }

  const userGotAutoApplyCredits = () => {
    return user?.scopes.autoApply && user.scopes.resumeDistribution
  }

  useEffect(() => {
    if (isRDModalParametersPresent()) {
      setTourStatus(true)
    }

    if (isUserProvisioned()) {
      setTourStatus(true)
    }

    if (userGotAutoApplyCredits()) {
      setTourStatus(true)
    }
  }, [searchParams])

  return (
    <>
      {!userGotAutoApplyCredits() &&
        !isUserProvisioned() &&
        !user?.uiStates?.dashv2TourCompleted &&
        !isRDModalParametersPresent() && (
          <JoyRide
            run
            steps={steps}
            callback={onCallback}
            skipButtonLabel={i18n.t(`${TRANSLATION}.skip_button`)}
            nextButtonLabel={i18n.t(`${TRANSLATION}.next_button`)}
            finalButtonLabel={i18n.t(`${TRANSLATION}.final_button`)}
          />
        )}
    </>
  )
}
