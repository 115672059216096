import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/init'
import { Cards } from 'builder/components/SubscriptionCancellationFormNew/common/types'
import { selectors as userSelectors } from 'builder/modules/user'
import BackAndCancelButtons from './BackAndCancelButtons'
import FeedbackSubmitResult from './FeedbackSubmittedSection'
import { useCancellationFormContext } from './FormSection'
import PromotionCards from './PromotionCards'
import ContentView from './PromotionSection/ContentView'
import { PromotionContainerV7 } from './PromotionSection/PromotionContainerV7'
import { PromotionContainer } from './PromotionSection/PromotionContainer'

export const FormView = ({ isCardClicked }: { isCardClicked: boolean }) => {
  const { activeCard, feedbackSubmitted, promotionTopic } = useCancellationFormContext()
  const locale = useTypedSelector(selectors.locale)
  const threePromoSubCancellation = useTypedSelector(
    userSelectors.userData,
  )?.threePromoSubCancellation

  return (
    <>
      <PromotionCards threePromoSubCancellation={threePromoSubCancellation} />
      {isCardClicked ? (
        threePromoSubCancellation && promotionTopic === Cards.DontNeedPromos ? (
          <>
            <PromotionContainerV7
              activeCard={activeCard}
              topic={promotionTopic}
              isNlLocale={locale === 'nl-NL'}
            >
              <ContentView showV7={true} />
            </PromotionContainerV7>
            <BackAndCancelButtons />
          </>
        ) : (
          <>
            <PromotionContainer
              activeCard={activeCard}
              topic={promotionTopic}
              isNlLocale={locale === 'nl-NL'}
            >
              {feedbackSubmitted ? <FeedbackSubmitResult /> : <ContentView showV7={false} />}
            </PromotionContainer>
            <BackAndCancelButtons />
          </>
        )
      ) : null}
    </>
  )
}
