import { useRef, Ref, useCallback, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import debounce from 'lodash/debounce'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { actions, selectors } from 'builder/modules/careerProfile'
import { Spinner } from 'builder/components/Spinner'
import { selectors as userSelectors } from 'builder/modules/user'
import { useUser } from 'builder/hooks/useUser'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import ChatBotAvatar from './assets/chatbot_avatar.svg'
import {
  MainContainer,
  UserAvatar,
  ChatContainer,
  BottomControlsContainer,
  ChatBotAvatarContainer,
  SpinnerContainer,
  MessageSeparator,
} from './styles'
import { ChatInput } from './ChatInput'
import { MessageLine } from './MessageLine'
import { ChatIntro } from './ChatIntro'
import { ChatOutro } from './ChatOutro'
import { ChatSignup } from './ChatSignup'
import { Progressbar } from './Progressbar'
import { ChatEnd } from './ChatEnd'
import useChatbot from './hooks/useChatBot'

const CareerChatView = () => {
  const dispatch = useDispatch()
  const photoUrl = useTypedSelector(userSelectors.photoUrl)
  const careerInsightsData = useTypedSelector(selectors.careerInsightsData)
  const messagesEndRef: Ref<HTMLDivElement> = useRef(null)
  const user = useUser()
  const [isScrolled, setIsScrolled] = useState(false)

  const scrollToBottom = useCallback(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }, [])

  const {
    questions,
    asked,
    answers,
    persona,
    selectedResumeText,
    setEditMessage,
    disableChat,
    isLoading,
    isChatbotCompleted,
    areAllTextQuestionsAnswered,
    editMessage,
  } = useChatbot({ handleScroll: scrollToBottom })

  const sendChatbotData = useCallback(() => {
    dispatch(actions.postChatbotAnswers())
  }, [dispatch])

  useEffectOnMount(() => {
    trackInternalEvent('enter_chatbot')
    const handleScroll = debounce(() => {
      const scrollPosition = window.scrollY + window.innerHeight
      const delta = document.documentElement.scrollHeight - scrollPosition

      if (delta > 100) setIsScrolled(true)
      else setIsScrolled(false)
    }, 500)

    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => window.removeEventListener('scroll', handleScroll)
  })

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (!isScrolled) {
        scrollToBottom()
      }
    })

    if (messagesEndRef.current) {
      observer.observe(messagesEndRef.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [scrollToBottom, isScrolled])

  const chatBotQuestions = asked.map((askedQ, idx) => {
    // disable avatar on auto response for looks
    const isAutoResponse = idx > 0 && askedQ.text === asked[idx - 1]?.response?.text

    return {
      time: askedQ.time || Date.now(),
      el: (
        <MessageLine
          key={`asked-${askedQ.id}-${idx}`}
          type="asked"
          avatar={<ChatBotAvatarContainer src={ChatBotAvatar} alt="chatbot avatar" />}
          last={!areAllTextQuestionsAnswered && !isAutoResponse && asked.length === idx + 1}
          amountOfWords={askedQ.text.split(' ').length}
        >
          {askedQ}
        </MessageLine>
      ),
    }
  })

  const userResponses = answers.map((answer, idx) => ({
    time: answer.time || Date.now(),
    el: (
      <MessageLine
        key={`answer-${answer.id}-${idx}`}
        type="answer"
        avatar={<UserAvatar src={photoUrl} />}
        last={!isChatbotCompleted && answers.length === idx + 1}
        setEditMessage={disableChat ? undefined : setEditMessage}
        handleEditTracking={() => {
          trackInternalEvent('click_edit_answer', {
            question_number: idx + 1,
            label: 'career_assessment',
          })
        }}
      >
        {answer}
      </MessageLine>
    ),
  }))

  const conversation = [...chatBotQuestions, ...userResponses]
    .sort((a, b) => (a?.time >= b?.time ? 1 : -1))
    .flatMap(({ el, time }) => [<MessageSeparator key={`separator-${time}`} />, el])

  // +1 is for resume selector, +1 for persona selector
  const allQuestionsCount = questions.length + 2
  const answeredCount = answers.length + (selectedResumeText ? 1 : 0) + (persona ? 1 : 0)
  const progressPercent = (answeredCount / allQuestionsCount) * 100

  return (
    <>
      <MainContainer>
        <ChatContainer ref={messagesEndRef}>
          {isLoading ? (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          ) : (
            <>
              <ChatIntro
                noEdit={isChatbotCompleted}
                asked={!!asked.length}
                answered={!!answers.length}
              />
              {conversation}
            </>
          )}

          {/* @TODO: handle unregistered users and login / signup redirect */}
          {!isLoading && areAllTextQuestionsAnswered && !user?.registeredAt && <ChatSignup />}
          {!isLoading && areAllTextQuestionsAnswered && user?.registeredAt && (
            <ChatOutro hideAvatar={isChatbotCompleted} />
          )}

          {isChatbotCompleted ? (
            <ChatEnd processingStatus={careerInsightsData?.status} onRetry={sendChatbotData} />
          ) : null}

          <BottomControlsContainer $isMessageEditing={!!editMessage}>
            {persona && !areAllTextQuestionsAnswered ? (
              <ChatInput
                editMessage={editMessage}
                setEditMessage={setEditMessage}
                disabled={disableChat}
              />
            ) : null}
            <Progressbar percent={progressPercent} />
          </BottomControlsContainer>
        </ChatContainer>
      </MainContainer>
    </>
  )
}
export default CareerChatView
