import { useEffect, useRef, useState } from 'react'
import jwtService from 'builder/services/JwtService'

class SpellChecker {
  initialization: Promise<void>
  supportedLocales: string[] = []

  init() {
    if (this.initialization) return this.initialization
    this.initialization = this.loadSupportedLocales().then(values => {
      this.supportedLocales = values
    })

    return this.initialization
  }

  isServiceEnabled() {
    return !!process.env.SPELL_HOST
  }

  checkSpelling(text: string, locale: string) {
    if (!this.isServiceEnabled()) {
      const error = new Error(
        "The Spellchecker service isn't enabled. " +
          'Please check that the SPELL_HOST variable is configured in the current env.',
      )

      return Promise.reject(error)
    }

    return jwtService.client
      .post(`${process.env.SPELL_HOST}/spellcheck`, { text, locale })
      .then(response => response.data.incorrectWords)
  }

  loadSupportedLocales() {
    // no locales supported if the service isn't enabled
    if (!this.isServiceEnabled()) return Promise.resolve([])

    return jwtService.client
      .get(`${process.env.SPELL_HOST}/info`)
      .then(response => response.data.supportedLocales)
  }
}

const spellChecker = new SpellChecker()
const createSpeller = () => ({
  spellchecker: spellChecker,
  supportedLocales: spellChecker.supportedLocales,
})

export const useSpellchecker = () => {
  const mounted = useRef(true)
  const [state, update] = useState(createSpeller)

  useEffect(() => {
    if (spellChecker.isServiceEnabled() && !spellChecker.supportedLocales.length) {
      spellChecker.init().then(() => {
        if (mounted.current) update(createSpeller)
      })

      return () => {
        mounted.current = false
      }
    }
  }, [])

  return state
}
