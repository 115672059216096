import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'

const { Body, S } = Typography
const { White, Neutral50, Neutral10, Blue50 } = Colors

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-color: ${White};
  border-radius: 16px;
  z-index: 30;
  padding: 52px 70px;
  ${Media.Tablet`
    padding: 52px 42px;
  `}
  ${Media.Phone`
    padding: 32px 18px;
  `}
`

export const Title = styled.p`
  ${S};
  font-weight: 600;
  margin-bottom: 8px;
  text-align: center;
  ${Media.Phone`
    max-width: 208px;
  `}
`

export const SubTitle = styled.p`
  ${Body};
  text-align: center;
  color: ${Neutral50};
  margin-bottom: 32px;
  ${Media.Phone`
    margin-bottom: 16px;
    max-width: 311px;
  `}
`

export const OptionsContainerB = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-color: ${White};
  border-radius: 16px;
  z-index: 30;
  padding: 62px 72px 82px 72px;
  ${Media.Tablet`
    padding: 52px 52px 55px 52px;
  `}
  ${Media.Phone`
    padding: 32px 16px;
  `}
`

export const TitleB = styled.p`
  ${S};
  font-weight: 600;
  margin-bottom: 8px;
  text-align: center;
  ${Media.Phone`
    max-width: 208px;
  `}
`

export const SubTitleB = styled.p`
  ${Body};
  text-align: center;
  color: ${Neutral50};
  margin-bottom: 32px;
  ${Media.Phone`
    margin-bottom: 16px;
    max-width: 300px;
  `}
`

export const Close = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const DesktopClose = styled(Close)`
  top: 20px;
  right: 20px;
  width: 28px;
  height: 28px;
  color: ${Neutral50};
  background: ${Neutral10};
  border-radius: 32px;
  transition: color 0.15s;
  ${Media.AboveTablet`
    &:hover {
      color: ${Blue50};
    }
  `}
`
