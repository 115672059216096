import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { EventCode, trackInternalEvent } from '@rio/tracking'
import {
  CareerInsightsPageType,
  CareerInsightsProcessingStatus,
  selectors,
  actions,
} from 'builder/modules/careerProfile'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useUser } from 'builder/hooks/useUser'
import { DefaultLink } from './styles'

export const CareerProfileLinkGuard: FC<{
  type: CareerInsightsPageType
  className?: string
  eventCode?: EventCode
}> = ({ type, children, className, eventCode }) => {
  const user = useUser()
  const dispatch = useDispatch()
  const data = useTypedSelector(selectors.careerInsightsData)
  const isReady = data?.status === CareerInsightsProcessingStatus.success
  const isAvailableForCurrentUser = type === 'insights' || user?.hasPremiumFeatures

  let tool: string = type
  if (type === 'cover-letter') {
    tool = 'cover_letter_sample'
  }
  const onGuardedLinkClick = () => {
    if (eventCode) {
      trackInternalEvent(eventCode, { tool })
    }
    if (!isReady) {
      return dispatch(actions.setCareerInsightsStatusModalType(type))
    }

    if (!isAvailableForCurrentUser) {
      return dispatch(actions.setOpenedPaywallModalType(type))
    }
  }

  return isReady && isAvailableForCurrentUser ? (
    <Link
      to={`/career-profile/${type}`}
      className={className}
      onClick={() => {
        if (eventCode) {
          trackInternalEvent(eventCode, { tool })
        }
      }}
    >
      {children}
    </Link>
  ) : (
    <DefaultLink className={className} onClick={onGuardedLinkClick}>
      {children}
    </DefaultLink>
  )
}
