import { trackInternalEvent } from '@rio/tracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

import { ButtonSize } from 'builder/components/Button'
import { selectors } from 'builder/modules/careerPath2'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import {
  Text,
  Image,
  Title,
  Content,
  Container,
  TabletImage,
  StyledButton,
  TabletContainer,
} from './styles'

const salaryAnalyzerUrl = require('./assets/img/salary-analyzer.svg')

const SalaryAnalyzerBanner = () => {
  const { isTablet } = useMediaQueries()
  const isExploreCareers = useTypedSelector(selectors.isExploreCareers)

  const MainContainer = isTablet ? TabletContainer : Container
  const MainImage = isTablet ? TabletImage : Image

  return (
    <MainContainer>
      <MainImage src={salaryAnalyzerUrl} alt="salary-analyzer-img" />
      <Content>
        <Title>Know Your Worth</Title>
        <Text>Compare your salary to compensation reported by others</Text>
      </Content>
      <StyledButton
        size={ButtonSize.small}
        onClick={() => {
          window.open('/app/offer-analyzer', '_blank')

          trackInternalEvent('click_salary_analyzer_banner', {
            label: isExploreCareers ? 'explore_careers' : 'career_path2',
          })
        }}
      >
        Compare
      </StyledButton>
    </MainContainer>
  )
}

export default SalaryAnalyzerBanner
