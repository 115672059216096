import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import enhanceWithClickOutside from 'react-click-outside'
import { Transition } from 'react-transition-group'
import { i18n as I18n } from 'builder/utils/i18n'
import { Container, Field, Flag, Title, Menu, Scroll, List, Item } from './styles'

const UNSUPPORTED_LOCALES = ['hi', 'he', 'zh-HK']

class LanguageSelect extends PureComponent {
  static propTypes = {
    options: PropTypes.array,
    selected: PropTypes.any,
    onSelect: PropTypes.func,
  }

  state = {
    isOpen: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    if (!this.state.isOpen) return
    this.setState({ isOpen: false })
  }

  handleOpen = () => {
    this.setState({ isOpen: true })
  }

  handleClickOutside = () => {
    if (!this.state.isOpen) return
    this.setState({ isOpen: false })
  }

  handleClose = e => {
    e.stopPropagation()
    this.setState({ isOpen: false })
  }

  handleSelect = id => {
    this.props.onSelect(id)
    this.setState({ isOpen: false })
  }

  render() {
    const { options, selected } = this.props
    const { isOpen } = this.state

    const withKR = window.location.search.includes('kr')

    const languagesList = options.filter(lang => {
      if (!withKR && lang.id === 'ko-KR') return false
      if (UNSUPPORTED_LOCALES.includes(lang.id)) return false
      return true
    })

    const currentOption = languagesList.find(option => option.id === selected) ||
      options.find(option => option.id === selected && UNSUPPORTED_LOCALES.includes(option.id)) || {
        id: 'unknown',
        name: I18n.t('builder.resume_editor.unknown_language'),
      }

    return (
      <Container>
        <Field onClick={this.handleOpen}>
          <Flag locale={currentOption.id} />
          {currentOption.name}
        </Field>

        <Transition in={isOpen} timeout={140}>
          {state => (
            <Menu in={state} isOpen={isOpen} onClick={this.handleClose}>
              <Title>{I18n.t('builder.resume_editor.resume_language')}</Title>
              <Scroll>
                <List>{languagesList.map(this.renderDropdownItem)}</List>
              </Scroll>
            </Menu>
          )}
        </Transition>
      </Container>
    )
  }

  renderDropdownItem = option => {
    const isSelected = option.id === this.props.selected

    return (
      <Item key={option.id} isSelected={isSelected} onClick={() => this.handleSelect(option.id)}>
        <Flag locale={option.id} isSelected={isSelected} />
        {option.name}
      </Item>
    )
  }
}

export default enhanceWithClickOutside(LanguageSelect)
