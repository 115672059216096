import { DraftBlockType, DraftHandleValue, EditorState } from 'draft-js'
import { EditorPlugin, PluginFunctions } from '@draft-js-plugins/editor'

import { shouldEnterOl, shouldEnterUl, startList } from './helpers'

export const BlockTypes: Record<string, DraftBlockType> = {
  UL: 'unordered-list-item',
  OL: 'ordered-list-item',
  TEXT: 'unstyled',
}

const createListPlugin = (): EditorPlugin => {
  const { olRegex, ulChars } = {
    olRegex: /\d\./,
    ulChars: ['-', '–', '*'],
  }

  const plugin: EditorPlugin = {
    /**
     * Handle space key presses
     */
    handleBeforeInput(
      chars: string,
      editorState: EditorState,
      _eventTimeStamp: number,
      { setEditorState }: PluginFunctions,
    ): DraftHandleValue {
      // On space press: Start list if necessary
      if (chars === ' ') {
        // Get editor content
        const content = editorState.getCurrentContent()

        // Get currently selected block
        const selection = editorState.getSelection()
        const blockKey = selection.getStartKey()
        const block = content.getBlockForKey(blockKey)

        // Exit if already in a list
        if (block.getType() !== BlockTypes.TEXT) {
          return 'not-handled'
        }

        // Get current position of cursor in block
        const cursorPos = selection.getStartOffset()

        // Get block text
        const text = `${block.getText()} `

        // Calculate position of first space character in block
        const firstSpacePos = text.indexOf(' ')

        /** If the typed space is the first one in the block and
         *  there is only one character in the block */
        if (cursorPos === firstSpacePos && text.trim().length === 1) {
          if (shouldEnterUl(text, ulChars)) {
            // Start unordered list
            const updatedState = startList(editorState, content, blockKey, BlockTypes.UL)
            setEditorState(updatedState)
            return 'handled'
          }
          if (shouldEnterOl(text, olRegex)) {
            // Start ordered list
            const updatedState = startList(editorState, content, blockKey, BlockTypes.OL)
            setEditorState(updatedState)
            return 'handled'
          }
        } else {
          // If the typed space is not at the start of the block (e.g. inserted in between)

          let listType: string | null = null

          const olMatch = olRegex.exec(text)
          if (olMatch && olMatch.index === 0) {
            // Handle ordered list
            listType = BlockTypes.OL
          } else if (shouldEnterUl(text, ulChars)) {
            // Handle unordered list
            listType = BlockTypes.UL
          }

          if (listType) {
            // Start the respective list
            const updatedState = startList(editorState, content, blockKey, listType)
            setEditorState(updatedState)
            return 'handled'
          }
        }
      }

      return 'not-handled'
    },
  }

  return plugin
}

export default createListPlugin
