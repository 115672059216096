import { useEffect, useMemo } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  selectors as jobSearchSelectors,
  actions as jobSearchActions,
} from 'builder/modules/jobSearch'
import { useI18n } from 'builder/hooks/useI18n'
import {
  JobSearchNotificationType,
  DataNotification,
  AlertTypes,
  DataNotificationType,
} from 'builder/modules/jobSearch/types'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import Icon24, { Icon16 } from 'builder/components/Icon'
import {
  NotificationContainer,
  LinkOpenButton,
  MessageContainer,
  IconContainer,
  NotificationText,
  Open,
} from './styles'

const JobSearchNotification = () => {
  const dispatch = useDispatch()
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const autoApplyBubbles = useTypedSelector(jobSearchSelectors.autoApplyBubbles)
  const notification = useTypedSelector(jobSearchSelectors.notification)

  const notifications = useMemo<Record<JobSearchNotificationType, DataNotification>>(
    () => ({
      auto_apply_added: {
        type: DataNotificationType.success,
        titleText: i18n.t(`builder.job_tracking.auto_apply.notification.added`),
        actionText: i18n.t(`builder.job_search.open`),
        onClickAction: () => {
          trackInternalEvent('open_job_tracking', { label: 'notification' })
          navigate('/job-tracking')
        },
      },

      job_card_added: {
        type: DataNotificationType.success,
        titleText: i18n.t(`builder.job_search.notification.job_card_added`),
        actionText: i18n.t(`builder.job_search.open`),
        onClickAction: () => {
          trackInternalEvent('open_job_tracking', { label: 'notification' })
          navigate('/job-tracking')
        },
      },
      create_job_alert: {
        type: DataNotificationType.success,
        titleText: i18n.t('builder.job_search.alert_modal.snackbar_create_job_alert.title'),
        actionText: i18n.t(`builder.job_search.alert_modal.snackbar_create_job_alert.action`),
        onClickAction: () => {
          dispatch(jobSearchActions.setAlertType({ type: AlertTypes.manage }))
        },
      },
      delete_job_alert: {
        type: DataNotificationType.success,
        titleText: i18n.t('builder.job_search.alert_modal.snackbar_delete_job_alert.title'),
        forceOnTop: true,
      },
      snackbar_fail: {
        type: DataNotificationType.fail,
        titleText: i18n.t('builder.job_search.alert_modal.snackbar_fail.title'),
        forceOnTop: true,
      },
    }),
    [dispatch, i18n],
  )

  useEffect(() => {
    let setTimeoutId: NodeJS.Timeout
    if (typeof notification === 'string') {
      trackInternalEvent('view_notification', {
        label: 'notification',
        titleText: notifications[notification]?.titleText,
      })
      setTimeoutId = setTimeout(() => {
        dispatch(jobSearchActions.setNotification(undefined))
      }, 4000)
    }

    return () => {
      if (!setTimeoutId) {
        clearTimeout(setTimeoutId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification])

  useEffect(() => {
    return () => {
      dispatch(jobSearchActions.setNotification(undefined))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (autoApplyBubbles.running || !notification || !notifications[notification]) {
    return null
  }

  const { titleText, actionText, onClickAction, type, forceOnTop } = notifications[notification]

  return (
    <NotificationContainer $aboveModal={!!forceOnTop}>
      <MessageContainer>
        <IconContainer>
          {type === DataNotificationType.success && <Icon16.SuccessIcon />}
          {type === DataNotificationType.fail && <Icon24.CircleError />}
        </IconContainer>
        <NotificationText>{titleText}</NotificationText>
      </MessageContainer>
      {onClickAction && (
        <LinkOpenButton
          onClick={() => {
            dispatch(jobSearchActions.setNotification(undefined))
            onClickAction()
          }}
        >
          <Open>{actionText}</Open>
        </LinkOpenButton>
      )}
    </NotificationContainer>
  )
}

export default JobSearchNotification
