import { all, put, call, takeLatest, delay, select, fork } from 'redux-saga/effects'
import axios from 'axios'
import { Resume } from 'packages/types'
import { UserData, selectors as userSelectors, actions as userActions } from 'builder/modules/user'
import ErrorLogger from 'builder/services/ErrorLogger'
import { navigate } from 'builder/modules/navigate'
import { ONBOARDING_STRUCTURED_RESUME_DATA_KEY } from 'builder/components/FindJob/types'
import { actions as editorActions } from 'builder/modules/resumeEditor'
import { FetchStatuses, ResumeUploadStatus, ResumeUploadStep } from '../constants'
import { ApiResponse, apiClient, baseClient } from '../apiClient'
import { actions, selectors } from './careerProfileModule'
import {
  Answer,
  CareerInsightsData,
  CareerInsightsProcessingStatus,
  CareerProfileCompletenessApiResponse,
  CareerProfileData,
  ChatPersona,
  Question,
  QuestionListApiResponse,
  ResumeParserApiResponse,
  StructuredResumeData,
} from './types'
import { localStorageSagas } from './careerProfileLocalStorageSagas'
import { createResumeFromParsedData } from './utils'

const POLLING_PERIOD_MS = 10_000

export function* fetchQuestionsSaga() {
  try {
    yield put(actions.setFetchQuestionsStatus(FetchStatuses.loading))

    const { data }: ApiResponse<QuestionListApiResponse> = yield call(
      baseClient.get,
      '/career/v1/assessments',
    )

    yield put(actions.setInitialQuestions(data.assessmentQuestions))
    yield put(actions.setFetchQuestionsStatus(FetchStatuses.loaded))
  } catch (error) {
    yield put(actions.setFetchQuestionsStatus(FetchStatuses.failed))
  }
}

export function* fetchSelectedResumeSaga({
  payload,
}: ReturnType<typeof actions.setSelectedResume>) {
  try {
    if (!payload) {
      yield put(actions.setSelectedResumeText(''))
      return
    }

    yield put(actions.setFetchSelectedResumeStatus(FetchStatuses.loading))

    const textRequest = call(apiClient.get, `/resumes/${payload.id}/resume_as_string`)
    const dataRequest = call(apiClient.get, `/resumes/${payload.id}`)

    const [{ data: resumeTextData }, { data: resumeData }]: [
      ApiResponse<{ resume: string }>,
      ApiResponse<Resume>,
    ] = yield all([textRequest, dataRequest])

    const structuredResumeData: StructuredResumeData = {
      workExperiencesAttributes: resumeData.workExperiences.map(item => ({
        title: item.title,
        employer: item.employer,
        city: item.city,
        dateFrom: item.dateFrom,
        dateUntil: item.dateUntil,
        description: item.description,
      })),
      skillsAttributes: resumeData.skills.map(item => ({
        skill: item.skill,
        level: item.level,
      })),
      educationsAttributes: resumeData.educations.map(item => ({
        school: item.school,
        degree: item.degree,
        city: item.city,
        dateFrom: item.dateFrom,
        dateUntil: item.dateUntil,
        description: item.description,
      })),
      coursesAttributes: resumeData.courses.map(item => ({
        course: item.course,
        institution: item.institution,
        dateFrom: item.dateFrom,
        dateUntil: item.dateUntil,
      })),
    }

    yield put(actions.setSelectedResumeText(resumeTextData.resume))
    yield put(actions.setStructuredResumeData(structuredResumeData))
    yield put(actions.setFetchSelectedResumeStatus(FetchStatuses.loaded))
  } catch (error) {
    yield put(actions.setFetchSelectedResumeStatus(FetchStatuses.failed))
  }
}

export function* postChatbotAnswersSaga() {
  try {
    const user: UserData | null = yield select(userSelectors.userData)
    const persona: ChatPersona | null = yield select(selectors.persona)
    const questions: Question[] = yield select(selectors.questions)
    const answers: Answer[] = yield select(selectors.answers)
    const selectedResumeText: string | null = yield select(selectors.selectedResumeText)
    const structuredResumeData: StructuredResumeData | null = yield select(
      selectors.structuredResumeData,
    )

    const questionAnswers: Record<string, string> = questions.reduce(
      (acc, item, index) => ({
        ...acc,
        [item.text]: answers[index].text,
      }),
      {},
    )

    if (!user || !persona || !questionAnswers || !selectedResumeText || !structuredResumeData) {
      console.warn('Unable to post chatbot answers, check input data')
      return
    }

    const data = {
      name: user.firstName,
      userType: persona,
      questionAnswers: questionAnswers,
      cvText: selectedResumeText,
      cvStructured: structuredResumeData,
    }

    yield put(actions.setIsDataSentDuringCurrentSession(true))
    yield put(actions.setCareerInsightsData(null))
    yield put(actions.setFetchCareerInsightsStatus(FetchStatuses.loading))

    yield call(baseClient.post, '/career/v1/assessments', data)

    yield put(actions.setIsDataSentDuringCurrentSession(true))
    yield put(actions.startCareerInsightsPolling())
  } catch (error) {
    yield put(actions.setFetchCareerInsightsStatus(FetchStatuses.failed))
  }
}

export function* startCareerInsightsPollingSaga() {
  try {
    const currentStatus: FetchStatuses = yield select(selectors.fetchCareerInsightsStatus)

    if (currentStatus === FetchStatuses.notAsked) {
      yield put(actions.setFetchCareerInsightsStatus(FetchStatuses.loading))
    }

    const { data, status }: ApiResponse<CareerInsightsData> = yield call(
      baseClient.get,
      '/career/v1/insights',
    )

    if (status === 204) {
      yield put(actions.setCareerInsightsData(null))
      yield put(actions.setFetchCareerInsightsStatus(FetchStatuses.loaded))
      return
    }

    yield put(actions.setCareerInsightsData(data))
    yield put(actions.setFetchCareerInsightsStatus(FetchStatuses.loaded))

    switch (data.status) {
      case CareerInsightsProcessingStatus.pending:
        yield delay(POLLING_PERIOD_MS)
        yield put(actions.startCareerInsightsPolling())
        break
      case CareerInsightsProcessingStatus.success:
      case CareerInsightsProcessingStatus.failure:
      case CareerInsightsProcessingStatus.retry:
        yield put(actions.fetchCompleteness())
        const isSeen: boolean = yield select(store =>
          selectors.isTemporalModalSeen(store, 'insights-result'),
        )

        if (!isSeen) {
          yield put(actions.setCareerInsightsStatusModalType('insights'))
        }
    }
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 404) {
      yield put(actions.setCareerInsightsData(null))
      yield put(actions.setFetchCareerInsightsStatus(FetchStatuses.loaded))
    } else {
      yield put(actions.setFetchCareerInsightsStatus(FetchStatuses.failed))
    }
  }
}

export function* parseResumeSaga({ payload }: { payload: File }) {
  yield put(editorActions.setIsSavingPrefilledResume(true))
  try {
    const formData = new FormData()
    formData.append('file', payload)

    const response: ApiResponse<ResumeParserApiResponse> = yield call(
      apiClient.post,
      `/resume-parser/parse-resume-data`,
      formData,
    )

    if (response?.data) {
      yield put(editorActions.setResumeUploadStatus(ResumeUploadStatus.uploaded))
      yield put(actions.setUploadedFilename(payload.name))

      if (!response.data?.rawText) {
        throw new Error('received empty text from parser')
      }

      const structuredResumeData = createResumeFromParsedData(response.data)

      yield put(actions.setSelectedResumeText(response.data.rawText))
      yield put(actions.setStructuredResumeData(structuredResumeData))
      yield delay(1000)
    } else {
      throw new Error(response.data.error)
    }
  } catch (error) {
    yield put(editorActions.setResumeUploadStatus(ResumeUploadStatus.failed))
    if (error instanceof Error) {
      yield put(
        editorActions.setPrefillResumeGenericError({
          error: error.message,
          errorSource: ResumeUploadStep.parsing,
        }),
      )
    }
  }
  yield put(editorActions.setIsSavingPrefilledResume(false))
}

export function* parseResumeOnboardingSaga({
  payload,
}: {
  payload: { file: File; redirectUrl: string }
}) {
  yield put(editorActions.setIsSavingPrefilledResume(true))
  try {
    const formData = new FormData()
    formData.append('file', payload.file)

    const response: ApiResponse<ResumeParserApiResponse> = yield call(
      apiClient.post,
      `/resume-parser/parse-resume-data`,
      formData,
    )

    if (response?.data) {
      yield put(editorActions.setResumeUploadStatus(ResumeUploadStatus.uploaded))

      if (!response.data?.rawText) {
        throw new Error('received empty text from parser')
      }

      const structuredResumeData = createResumeFromParsedData(response.data)

      localStorage.setItem(
        ONBOARDING_STRUCTURED_RESUME_DATA_KEY,
        JSON.stringify(structuredResumeData),
      )
      navigate(payload.redirectUrl)
      yield put(actions.setOnboardingStructuredResumeData(structuredResumeData))
    } else {
      throw new Error(response.data.error)
    }
  } catch (error) {
    yield put(editorActions.setResumeUploadStatus(ResumeUploadStatus.failed))
    if (error instanceof Error) {
      yield put(
        editorActions.setPrefillResumeGenericError({
          error: error.message,
          errorSource: ResumeUploadStep.parsing,
        }),
      )
    }
  }
  yield put(editorActions.setIsSavingPrefilledResume(false))
}

export function* fetchCareerProfileCompletenessSaga() {
  try {
    yield put(actions.setFetchCompletenessStatus(FetchStatuses.loading))

    const { data }: ApiResponse<CareerProfileCompletenessApiResponse> = yield call(
      baseClient.get,
      'career/v1/profile/completeness-state',
    )

    yield put(actions.setCompletenessPercent(data.completenessState))
    yield put(actions.setCompletenessPendingItems(data.completenessPending))
    yield put(actions.setFetchCompletenessStatus(FetchStatuses.loaded))
  } catch (error) {
    yield put(actions.setFetchCompletenessStatus(FetchStatuses.failed))
  }
}

export function* fetchIsAutoApplyCompleteSaga() {
  try {
    yield put(actions.setFetchIsAutoApplyCompleteStatus(FetchStatuses.loading))

    const { data }: ApiResponse<{ autoApplyDataCompleted: boolean }> = yield call(
      baseClient.get,
      'career/v1/profile/auto-apply-data/state',
    )

    yield put(actions.setIsAutoApplyComplete(data.autoApplyDataCompleted))
    yield put(actions.setFetchIsAutoApplyCompleteStatus(FetchStatuses.loaded))
  } catch (error) {
    yield put(actions.setFetchIsAutoApplyCompleteStatus(FetchStatuses.failed))
  }
}

export function* fetchCareerProfileSaga() {
  try {
    yield put(actions.setFetchCareerProfileStatus(FetchStatuses.loading))

    const { data }: ApiResponse<CareerProfileData> = yield call(baseClient.get, 'career/v1/profile')

    yield put(actions.setCareerProfileData(data))
    yield put(actions.setFetchCareerProfileStatus(FetchStatuses.loaded))
  } catch (error) {
    yield put(actions.setFetchCareerProfileStatus(FetchStatuses.failed))
  }
}

export function* updateProfileDataSaga({ payload }: ReturnType<typeof actions.updateProfileData>) {
  try {
    // remove readonly fields before update
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { userType, profilePicture, ...careerProfile } = payload

    if (!Object.keys(careerProfile).length) {
      return
    }

    const { data }: ApiResponse<CareerProfileData> = yield call(
      baseClient.patch,
      'career/v1/profile',
      { careerProfile },
    )

    yield put(actions.setCareerProfileData(data))
    yield put(actions.fetchCompleteness())
  } catch (error) {
    ErrorLogger.log(error)
  }
}

function* uploadImageSaga({ payload }: ReturnType<typeof actions.updateProfileImage>) {
  try {
    yield put(actions.setIsProfilePhotoUploading(true))

    const file = payload.file
    const transform = 'transform' in payload ? payload.transform : undefined
    const processedFile = 'processedFile' in payload ? payload.processedFile : undefined
    const url = `/career/v1/profile/upload-photo`

    const form = new FormData()
    if (file) form.append('original_file', file)
    if (processedFile) form.append('processed_file', processedFile)
    form.append('transform', JSON.stringify(transform))

    const { data } = yield call(baseClient.patch, url, form)

    yield put(actions.updateProfileData({ profilePicture: data }))
    yield put(actions.setIsProfilePhotoUploading(false))
    yield put(userActions.fetchUserRequest({ silent: true }))
  } catch (error) {
    ErrorLogger.log(error)
    yield put(actions.setIsProfilePhotoUploading(false))
  }
}

function* deleteImageSaga() {
  try {
    yield put(actions.setIsProfilePhotoUploading(true))

    const url = `/career/v1/profile/upload-photo`

    const { data } = yield call(baseClient.patch, url, {
      original_file: null,
      processed_file: null,
      transform: null,
    })

    yield put(actions.updateProfileData({ profilePicture: data }))
    yield put(actions.setIsProfilePhotoUploading(false))
    yield put(userActions.fetchUserRequest({ silent: true }))
  } catch (error) {
    ErrorLogger.log(error)
    yield put(actions.setIsProfilePhotoUploading(false))
  }
}

export function* prefillProfileSaga() {
  yield put(actions.setFetchCareerProfileStatus(FetchStatuses.loading))
  yield put(actions.setFetchIsNewProfileStatus(FetchStatuses.loading))

  try {
    const { data }: ApiResponse<CareerProfileData> = yield call(
      baseClient.post,
      '/career/v1/profile/prefill/me',
    )
    yield put(actions.setFetchIsNewProfileStatus(FetchStatuses.loaded))
    yield put(actions.setFetchCareerProfileStatus(FetchStatuses.loaded))
    yield put(actions.setIsNewProfile(data.newProfile))
    yield put(actions.setCareerProfileData(data))
  } catch (error) {
    ErrorLogger.log(error)
    yield put(actions.setFetchCareerProfileStatus(FetchStatuses.failed))
    yield put(actions.setFetchIsNewProfileStatus(FetchStatuses.failed))
  }
}

export function* prefillProfileAndFetchProfileSaga() {
  yield put(actions.setFetchCareerProfileStatus(FetchStatuses.loading))
  yield put(actions.setFetchIsNewProfileStatus(FetchStatuses.loading))

  try {
    const { data: prefillData }: ApiResponse<CareerProfileData> = yield call(
      baseClient.post,
      '/career/v1/profile/prefill/me',
    )
    const { data }: ApiResponse<CareerProfileData> = yield call(baseClient.get, 'career/v1/profile')

    yield put(actions.setFetchIsNewProfileStatus(FetchStatuses.loaded))
    yield put(actions.setFetchCareerProfileStatus(FetchStatuses.loaded))
    yield put(actions.setIsNewProfile(data.newProfile))
    yield put(actions.setCareerProfileData(data))

    if (prefillData.newProfile === true && data.newProfile === false) {
      yield put(actions.setWasProfilePreffiled(true))
    }
  } catch (error) {
    ErrorLogger.log(error)
    yield put(actions.setFetchCareerProfileStatus(FetchStatuses.failed))
    yield put(actions.setFetchIsNewProfileStatus(FetchStatuses.failed))
  }
}

/** Bind side-effect handlers */
export const sagas = function* saga() {
  yield fork(localStorageSagas)

  yield all([takeLatest(actions.fetchQuestions, fetchQuestionsSaga)])
  yield all([takeLatest(actions.setSelectedResume, fetchSelectedResumeSaga)])
  yield all([takeLatest(actions.postChatbotAnswers, postChatbotAnswersSaga)])
  yield all([takeLatest(actions.startCareerInsightsPolling, startCareerInsightsPollingSaga)])
  yield all([takeLatest(actions.fetchCompleteness, fetchCareerProfileCompletenessSaga)])
  yield all([takeLatest(actions.parseResume, parseResumeSaga)])
  yield all([takeLatest(actions.fetchCareerProfile, fetchCareerProfileSaga)])
  yield all([takeLatest(actions.updateProfileData, updateProfileDataSaga)])
  yield all([takeLatest(actions.updateProfileImage, uploadImageSaga)])
  yield all([takeLatest(actions.fetchIsAutoApplyComplete, fetchIsAutoApplyCompleteSaga)])
  yield all([takeLatest(actions.deleteProfileImage, deleteImageSaga)])
  yield all([takeLatest(actions.prefillProfile, prefillProfileSaga)])
  yield all([takeLatest(actions.parseResumeOnboarding, parseResumeOnboardingSaga)])
  yield all([takeLatest(actions.prefillProfileAndFetchProfile, prefillProfileAndFetchProfileSaga)])
}
