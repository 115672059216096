import React, { useMemo } from 'react'
import Colors from 'builder/styles/colors'
import { Score, ScoreContent, ScoreLabel, ScoreValue } from './styles'

type Props = {
  score: number
  fromSidebar: boolean
}

export const TunerScore: React.FC<Props> = ({ score, fromSidebar }) => {
  const backgroundImage = useMemo(() => {
    if (score >= 70) {
      return `conic-gradient(
        ${Colors.Green40} 0% ${score}%,
        ${Colors.Neutral15} 0% 100%)`
    }

    if (score >= 30) {
      return `conic-gradient(
        ${Colors.Amber40} 0% ${score}%,
        ${Colors.Neutral15} 0% 100%
      )`
    }

    return `conic-gradient(
      ${Colors.Red50} 0% ${score}%,
      ${Colors.Neutral15} 0% 100%
    )`
  }, [score])

  return (
    <Score style={{ backgroundImage }} fromSidebar={fromSidebar}>
      <ScoreContent>
        <ScoreValue fromSidebar={fromSidebar}>{score}%</ScoreValue>
        <ScoreLabel fromSidebar={fromSidebar}>Resume Score</ScoreLabel>
      </ScoreContent>
    </Score>
  )
}
