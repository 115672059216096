import { BillingItem, BillingItemData } from './BillingItem'
import { PlanListContainer } from './styles'

type Props = {
  items: Array<BillingItemData>
}

export const BillingItemList = ({ items }: Props) => {
  return (
    <PlanListContainer>
      {items.map((item, index) => (
        <BillingItem key={index} item={item} />
      ))}
    </PlanListContainer>
  )
}
