import EditorField from 'builder/components/EditorField'
import Select from 'builder/components/Select'
import { useI18n } from 'builder/hooks/useI18n'
import { actions } from 'builder/modules/resumeEditor'
import { useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CommuteTimes } from '../../constants/constants'

export function CommuteTime() {
  const { i18n } = useI18n()

  const options = useRef([
    {
      id: CommuteTimes.LESS_THAN_30_MINS,
      name: i18n.t(
        'builder.resume_editor.professional_summary.commute_options.less_than_thirty_minutes',
      ),
    },
    {
      id: CommuteTimes.LESS_THAN_1_HOUR,
      name: i18n.t('builder.resume_editor.professional_summary.commute_options.less_than_one_hour'),
    },
    {
      id: CommuteTimes.LESS_THAN_1_AND_HALF_HOURS,
      name: i18n.t(
        'builder.resume_editor.professional_summary.commute_options.less_than_one_and_half_hour',
      ),
    },
    {
      id: CommuteTimes.LESS_THAN_2_HOURS,
      name: i18n.t(
        'builder.resume_editor.professional_summary.commute_options.less_than_two_hours',
      ),
    },
    {
      id: CommuteTimes.LESS_THAN_2_AND_HALF_HOURS,
      name: i18n.t(
        'builder.resume_editor.professional_summary.commute_options.less_than_two_and_half_hours',
      ),
    },
    {
      id: CommuteTimes.LESS_THAN_3_HOURS,
      name: i18n.t(
        'builder.resume_editor.professional_summary.commute_options.less_than_three_hours',
      ),
    },
    {
      id: CommuteTimes.MORE_THAN_3_HOURS,
      name: i18n.t(
        'builder.resume_editor.professional_summary.commute_options.greater_than_three_hours',
      ),
    },
  ])

  const { updateSimpleField } = actions
  const commutingTime = useSelector(state => state.resumeEditor.resume.commutingTime)
  const dispatch = useDispatch()

  const handleSelect = useCallback(
    value => {
      dispatch(updateSimpleField({ name: 'commutingTime', value }))
    },
    [dispatch, updateSimpleField],
  )

  return (
    <>
      <EditorField>
        <Select
          label={i18n.t('builder.resume_editor.professional_summary.commute_time')}
          selected={commutingTime}
          onSelect={handleSelect}
          options={options.current}
        />
      </EditorField>
    </>
  )
}
