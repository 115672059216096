import { ChartOptions } from 'chart.js'
import merge from 'lodash/merge'
import { CareerPathSeries } from 'builder/modules/careerPath'
import { formatDate } from 'builder/utils/formatDate'
import { matchMediaQueries } from 'builder/utils/matchMediaQueries'
import { getOptions } from '../../utils'

export const normalizeChartData = (data: CareerPathSeries) =>
  data.map(item => {
    return {
      ...item,
      x: formatDate(new Date(item.x), 'MMM, yy'),
    }
  })

export const getDemandChartOptions = (
  { isTablet, isPhone }: ReturnType<typeof matchMediaQueries>,
  maxY: number,
): ChartOptions<'line'> => {
  const maxXTicksLimit = isPhone || !isTablet ? 5 : 10

  return merge(getOptions(maxY), {
    plugins: {
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      x: {
        ticks: {
          maxTicksLimit: maxXTicksLimit,
        },
      },
      y: {
        ticks: {
          maxTicksLimit: 5,
        },
        beginAtZero: true,
      },
    },
  }) as ChartOptions<'line'>
}
