import { memo } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { SectionNames, actions } from 'builder/modules/resumeEditor'
import { Icon20 } from 'builder/components/Icon'
import { useKeywords } from '../../hooks/useKeywords'
import { KEYWORDS_ANALYTICS_LABEL } from '../../constants'
import { Counter, Container, AiIconBadge } from './styles'

type Props = {
  section: SectionNames
  text: string
  onClick?: () => void
}

export const AiKeywordsTogglerForPopups = memo((props: Props) => {
  const { section, text, onClick } = props
  const dispatch = useDispatch()
  const { allKeywords, addedKeywords, isKeywordsAvailable } = useKeywords()

  const handleClick = () => {
    dispatch(actions.setOpenedKeywordsModalType(section))
    onClick?.()
    if (section in KEYWORDS_ANALYTICS_LABEL) {
      trackInternalEvent('click_add_keywords', {
        label: 'ai_writer_dialog',
        section: KEYWORDS_ANALYTICS_LABEL[section],
      })
    }
  }

  return isKeywordsAvailable ? (
    <Container onClick={handleClick}>
      <AiIconBadge>
        <Icon20.ArrowTarget2 />
      </AiIconBadge>
      {text}
      <Counter>
        {addedKeywords.length}/{allKeywords.length}
      </Counter>
    </Container>
  ) : null
})
