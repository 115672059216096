import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Icon24 from 'builder/components/Icon'

export const BaseButton = css`
  display: block;
  width: fit-content;
  border-radius: 8px;
  background-color: rgba(30, 37, 50, 0.7);
  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${Colors.White};
  ${Media.Hover`
    &:hover {
      background-color: rgba(30, 37, 50, 0.80);
      cursor: pointer;
    }
  `};
`

export const NextButton = styled.div`
  ${BaseButton};
  padding: var(--2XS, 8px) var(--XS, 12px) var(--2XS, 8px) var(--M, 24px);
`

export const RightArrowIcon = styled(Icon24.RightArrow)`
  padding-left: 3px;
`
