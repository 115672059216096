/* eslint-disable max-len */
import { useMemo, useEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import {
  ResumeCritique,
  ResumeCritiqueStatuses as STATUSES,
  useTimeLeftFormatter,
} from 'builder/modules/resumeReview'
import { Button, ButtonTheme, ButtonSize } from 'builder/components/Button'
import { Icon24 } from 'builder/components/Icon'
import { IFRAME_PARAMS } from 'builder/modules/resumeCritiqueReview/constants'
import {
  PopupContainer,
  PopupVisual,
  PopupIconWrapper,
  PopupIcon,
  PopupPhoto,
  PopupContent,
  PopupTitle,
  PopupText,
  PopupButtons,
  PopupActionButton,
  PopupActionButtonIcon,
  PopupClose,
} from './styles'

interface Props {
  critique: ResumeCritique
  onClose: () => void
}

/** Just renders the popup with the given data. Nothing else. */
export const ResumeReviewPopupUi = ({ critique, onClose }: Props) => {
  const { status } = critique

  const timeLeft = useTimeLeftFormatter(critique)

  useEffect(() => {
    trackInternalEvent('see_resume_critique_banner', {
      source: 'resume_editor',
      status,
    })
  }, [status])

  const title = useMemo(() => {
    if (status === STATUSES.finished) return 'Your Resume Review is Ready'
    return 'Your Resume Review is Almost Ready'
  }, [status])

  const text = useMemo(() => {
    if (status === STATUSES.finished) {
      return `Personalized tips by resume expert ${critique.reviewer.name} on how you can improve your resume`
    }
    return `Our experts are writing a comprehensive review of your resume – check back in ${timeLeft}!`
  }, [status, critique.reviewer.name, timeLeft])

  const onClickStatusButtonFinished = (url: string) => {
    trackInternalEvent('click_read_resume_critique_button', {
      source: 'resume_editor',
    })
    window.open(`${url}${IFRAME_PARAMS}`)
  }

  return (
    <PopupContainer>
      <PopupVisual>
        {status === STATUSES.pending && (
          <PopupPhoto src={require('images/builder/panel/resume-review-status/photo-2.jpg')} />
        )}
        {status === STATUSES.pending && (
          <PopupPhoto src={require('images/builder/panel/resume-review-status/photo-1.jpg')} />
        )}
        {status === STATUSES.finished && <PopupPhoto src={critique.reviewer.photoUrl} />}
        <PopupIconWrapper>
          <PopupIcon />
        </PopupIconWrapper>
      </PopupVisual>

      <PopupContent>
        <PopupTitle>{title}</PopupTitle>
        <PopupText>{text}</PopupText>

        <PopupButtons>
          {critique.status === STATUSES.pending && (
            <PopupActionButton size={ButtonSize.small} isDisabled>
              {timeLeft} left
            </PopupActionButton>
          )}

          {critique.status === STATUSES.finished && (
            <PopupActionButton
              onClick={() => onClickStatusButtonFinished(critique.iframeUrl)}
              size={ButtonSize.small}
            >
              Read the Review
              <PopupActionButtonIcon />
            </PopupActionButton>
          )}

          <Button size={ButtonSize.small} theme={ButtonTheme.ghost} onClick={onClose}>
            Close
          </Button>
        </PopupButtons>
      </PopupContent>

      <PopupClose onClick={onClose}>
        <Icon24.Close />
      </PopupClose>
    </PopupContainer>
  )
}
