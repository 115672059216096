import { BriefLetter, BriefResume } from 'builder/modules/panel'
import { formatDocumentPreviewUrl } from 'builder/utils/formatDocumentPreviewUrl'
import * as Styled from './styles'
const resume = require('../../assets/Resume.svg')
const AddIcon = require('../../assets/AddIcon.svg')

type Props = {
  document: BriefResume | BriefLetter | null | undefined
  hasLetter: boolean
}

export const CoverLetter = (props: Props) => {
  const { document } = props
  const imageSrc = document ? formatDocumentPreviewUrl(document, { size: 256 }) : resume

  return (
    <Styled.CoverLetterContainer>
      <Styled.CoverLetterImageContainer>
        <Styled.CoverLetterImage src={imageSrc} />
        <Styled.ResumeGradient />
        <Styled.CoverLetterIconContainer>
          <Styled.CoverLetterIconImage src={AddIcon} />
        </Styled.CoverLetterIconContainer>
      </Styled.CoverLetterImageContainer>
    </Styled.CoverLetterContainer>
  )
}
