import { useI18n } from 'builder/hooks/useI18n'
import * as Styles from './styles'

const ModalBody = () => {
  const { i18n } = useI18n()

  return (
    <>
      <Styles.Description>
        <Styles.Header>
          <Styles.Title>{i18n.t(`builder.panel.second_review_alert.title`)}</Styles.Title>
        </Styles.Header>
        <Styles.SubTitle>{i18n.t(`builder.panel.second_review_alert.text`)}</Styles.SubTitle>
      </Styles.Description>
    </>
  )
}

export default ModalBody
