import styled, { css, keyframes } from 'styled-components'
import Button from 'builder/components/Button'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Banner = styled.div`
  background: ${Colors.Indigo10};
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  margin-bottom: 40px;
  align-items: center;
  justify-content: space-between;
  ${Media.Phone`
    flex-direction: column;
    align-items: start;
  `}
`
export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  ${Media.Phone`
    margin-bottom: 6px;
  `}
`
export const Heading = styled.span`
  color: ${Colors.Indigo80};
  ${Typography.Body};
  ${FontWeights.DemiBold};
`

export const SubHeading = styled.div`
  color: ${Colors.Indigo80};
  ${Typography.Caption};
  ${Media.Phone`
    ${Typography.Tiny};
  `}
`

export const Star = styled.img<{ $showAnimation: boolean }>(({ $showAnimation }) => {
  return $showAnimation
    ? css`
        animation: ${rotateAnimation} 1s infinite linear;
      `
    : ``
})

export const GenerateButton = styled(Button)`
  padding: 12px 24px 12px 20px;
  gap: 4px;
  background: ${Colors.Indigo50};
  color: ${Colors.White};
  ${Typography.Body};
  ${FontWeights.Medium};
  ${Media.Phone`
    ${Typography.Caption};
    padding: 7px 12px 7px 8px;
    width: 100%;
    margin-top: 10px;
    height: 34px;
  `}
  &:hover {
    background-color: ${Colors.Indigo60};
  }
`

// Badge Banner
export const BadgeBanner = styled(Banner)`
  padding: 16px;
`
export const BadgeInfoContainer = styled.div`
  margin-right: 23px;
  ${Media.Phone`
    margin-right: 0px;
  `}
`

export const AIPoweredBadge = styled.div`
  display: flex;
  padding: 2px 8px 2px 4px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: rgba(219, 222, 255, 0.5);
  color: ${Colors.Indigo60};
  margin-left: 8px;
`
export const AIPoweredText = styled.div`
  ${Typography.Tiny};
  ${FontWeights.DemiBold};
  margin-left: 2px;
`

// Robo Banner
export const RoboBanner = styled(Banner)`
  padding: 24px 16px;
  ${Media.Phone`
    padding: 16px 16px 26px 16px;
  `}
`
export const RoboInfoContainer = styled.div`
  display: flex;
  align-items: center;
`
export const RoboInfoTextContainer = styled.div`
  margin-left: 8px;
  margin-right: 24px;
  ${Media.Phone`
    margin-right: 0px;
  `}
`

export const Robo = styled.img`
  margin-left: -16px;
  margin-bottom: -24px;
  ${Media.Phone`
    margin-bottom: 0px;   
  `}
`

export const GenerateButtonRobo = styled(GenerateButton)`
  height: 48px;
  width: 144px;
  padding: 12px 24px 12px 20px;
  ${Typography.Body};
  ${Media.Phone`
    padding: 7px 12px 7px 8px;
    ${Typography.Caption};
    width: 100%;
    height: 34px;
  `}
`
