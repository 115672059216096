import { useMemo } from 'react'
import { selectors, UpdatedExperienceLevelSalary } from 'builder/modules/careerPath2'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import Bar from '../Bar'
import Loader from '../Loader'

import { formatNumber, getWidth } from '../../utils'
import { Container, Title, Bars } from './styles'

const WorkExperience = () => {
  const isSalaryFailed = useTypedSelector(selectors.isSalaryFailed)
  const isSalaryLoading = useTypedSelector(selectors.isSalaryLoading)
  const { experienceLevelSalary } = useTypedSelector(selectors.salaryDetails)

  const updatedExperienceLevelSalary = useMemo(() => {
    return {
      '10+ years': {
        salary: experienceLevelSalary['10+'],
        width: getWidth(experienceLevelSalary['10+'], experienceLevelSalary['10+']),
      },
      '6-9 years': {
        salary: experienceLevelSalary['6to9'],
        width: getWidth(experienceLevelSalary['6to9'], experienceLevelSalary['10+']),
      },
      '3-6 years': {
        salary: experienceLevelSalary['3to6'],
        width: getWidth(experienceLevelSalary['3to6'], experienceLevelSalary['10+']),
      },
      '1-3 years': {
        salary: experienceLevelSalary['1to3'],
        width: getWidth(experienceLevelSalary['1to3'], experienceLevelSalary['10+']),
      },
      Fresher: {
        salary: experienceLevelSalary.fresher,
        width: getWidth(experienceLevelSalary.fresher, experienceLevelSalary['10+']),
      },
    }
  }, [experienceLevelSalary])

  if (isSalaryFailed) {
    return null
  }

  return (
    <Container data-testid="work-experience-section">
      <Title>Work Experience</Title>
      {isSalaryLoading ? (
        <Loader />
      ) : (
        <Bars>
          {Object.keys(updatedExperienceLevelSalary).map(key => {
            const formattedValue = formatNumber(
              updatedExperienceLevelSalary[key as keyof UpdatedExperienceLevelSalary].salary,
            )

            const width =
              updatedExperienceLevelSalary[key as keyof UpdatedExperienceLevelSalary].width

            return <Bar key={key} text={key} value={formattedValue} width={width} />
          })}
        </Bars>
      )}
    </Container>
  )
}

export default WorkExperience
