import { createAction, createReducer } from '@reduxjs/toolkit'
import { Store } from '../store'
import { DashboardStore, StepsData } from './types'

export const selectors = {
  isLoaded: (state: Store) => state.dashboard.isLoaded,
  stepsData: (state: Store) => state.dashboard.stepsData,
  stepperSuccessStateSeen: (state: Store) => state.dashboard.stepperSuccessStateSeen,
}

export const actions = {
  fetchStepsDataRequest: createAction('builder/FETCH_STEPS_DATA_REQUEST'),
  fetchStepsDataSuccess: createAction<StepsData>('builder/FETCH_STEPS_DATA_SUCCESS'),
  fetchStepperSuccessStateRequest: createAction('builder/FETCH_STEPPER_SUCCESS_STATE_REQUEST'),
  fetchStepperSuccessStateSuccess: createAction<boolean>(
    'builder/FETCH_STEPPER_SUCCESS_STATE_SUCCESS',
  ),
  setStepsLoadedStatus: createAction<boolean>('builder/SET_STEPS_LOADED_DATA_STATUS'),
  updateStepperSuccessStateRequest: createAction('builder/UPDATE_STEPPER_SUCCESS_STATE_REQUEST'),
  updatePersonalizedJobsStateRequest: createAction(
    'builder/UPDATE_PERSONALIZED_JOBS_STATE_REQUEST',
  ),
}

export const reducer = createReducer<DashboardStore>(
  {
    isLoaded: false,
    stepperSuccessStateSeen: false,
    stepsData: {
      apply_for_you: {
        completed: false,
      },
      resume_distribution: {
        completed: false,
      },
      personalized_jobs: {
        completed: false,
      },
      job_tracker_wishlist: {
        completed: false,
        needed: 0,
      },
      resume_creation: {
        completed: false,
        resume_id: 0,
        score: 0,
      },
      career_profile: {
        completed: false,
        current_score: 0,
      },
    },
  },
  reducer => {
    reducer.addCase(actions.fetchStepsDataSuccess, (draft, { payload }) => {
      draft.stepsData = payload
      draft.isLoaded = true
    })
    reducer.addCase(actions.fetchStepperSuccessStateSuccess, (draft, { payload }) => {
      draft.stepperSuccessStateSeen = payload
    })
    reducer.addCase(actions.setStepsLoadedStatus, (draft, { payload }) => {
      draft.isLoaded = payload
    })
  },
)
