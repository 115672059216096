import { useQuery } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { actions } from 'builder/modules/autoApply/autoApplyModule'
import { baseClient } from 'builder/modules/apiClient'
import { Profile } from 'builder/modules/autoApply/types'
import { mapCareerResponse } from './mappers'

export const useGetCareerProfile = () => {
  const dispatch = useDispatch()
  return useQuery(['useGetCareerProfile'], async () => {
    const response = await baseClient.get<Profile>(`/career/v1/profile`)
    const mappedData = mapCareerResponse(response.data)
    dispatch(actions.updateForm(mappedData))
    return mappedData
  })
}
