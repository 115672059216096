import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { Typography } from 'builder/styles/typography'

export const MessageContainer = styled.div`
  ${Typography.Caption};
  margin-top: 4px;
  display: flex;
  justify-content: flex-start;
  color: ${Colors.Red50};
`

export const Message = styled.div`
  flex: 1 1 0;
`
