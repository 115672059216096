import { useState, useEffect, useRef } from 'react'
import nanoid from 'nanoid'
import { MouseClickEvent } from 'builder/modules/ui'
import { Props } from './SpellingError'

export const useSpellingErrorActions = (props: Props) => {
  const { children, decoratedText, spellCheckEmitter } = props

  const [isActive, setIsActive] = useState(false)

  const wordId = useRef(nanoid())

  const handleHighlightWord = (wordIdProp: string) => {
    if (wordId.current === wordIdProp) {
      setIsActive(true)
    }
  }

  const handleDimWord = (wordIdProp: string) => {
    if (wordId.current === wordIdProp) {
      setIsActive(false)
    }
  }

  const handleMouseEnter = (e: MouseClickEvent) => {
    const wordRect = (e.target as HTMLElement).getBoundingClientRect()
    const scrollTop =
      window.scrollY ||
      window.pageYOffset ||
      (document.documentElement ? document.documentElement.scrollTop : 0)

    const position = {
      top: Math.round(wordRect.top + scrollTop + wordRect.height + 1),
      left: Math.round(wordRect.left - 16),
    }

    spellCheckEmitter.showSuggestions({
      blockKey: children[0].props.block.getKey(),
      wordId: wordId.current,
      word: decoratedText,
      position: position,
      wordStartIndex: children[0].props.start,
      wordEndIndex: children[0].props.start + decoratedText.length,
    })
  }

  const handleMouseLeave = () => {
    spellCheckEmitter.hideSuggestions({ wordId: wordId.current })
  }

  useEffect(() => {
    spellCheckEmitter.on('highlightWord', handleHighlightWord)
    spellCheckEmitter.on('dimWord', handleDimWord)
    return () => {
      spellCheckEmitter.removeListener('highlightWord', handleHighlightWord)
      spellCheckEmitter.removeListener('dimWord', handleDimWord)
    }
  }, [spellCheckEmitter])

  return { isActive, handleMouseEnter, handleMouseLeave }
}
