import { useRef, useEffect } from 'react'

type Callback = () => void

/*
 * A utility hook that allows to execute effects *only* after the first mount.
 * Ignores the dependencies array.
 */
export const useEffectOnMount = (callback: React.EffectCallback) => {
  // by saving the callback into a ref we guarantee that
  // the call to `useEffect` below is safe
  const cbRef = useRef<Callback>(callback)

  useEffect(() => {
    if (typeof cbRef.current === 'function') return cbRef.current()
  }, [])
}
