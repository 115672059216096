import { CardType } from '../InterviewCard'
import {
  GreenLayerOne,
  LayerOne,
  LayerThree,
  LayerTwo,
  GreenLayerTwo,
  GreenLayerThree,
} from './styles'

type Props = {
  type: CardType
}

export const Layers: React.FC<Props> = ({ children, type }) => {
  if (type === 'default') {
    return (
      <GreenLayerOne>
        <GreenLayerTwo>
          <GreenLayerThree>{children}</GreenLayerThree>
        </GreenLayerTwo>
      </GreenLayerOne>
    )
  }

  return (
    <LayerOne>
      <LayerTwo>
        <LayerThree>{children}</LayerThree>
      </LayerTwo>
    </LayerOne>
  )
}

export default Layers
