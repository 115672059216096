import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'

import { useI18n } from 'builder/hooks/useI18n'
import { Step } from 'builder/features/dashboard/components/Stepper/Steps/Step'

import { SHOW_CHECK_PROFILE_BANNER_STORAGE_KEY } from '../../types'
import {
  EllipseOne,
  EllipseThree,
  EllipseTwo,
  ResumeImage,
  EllipsisContainer,
  ResumeImageGradient,
  AddIconContainer,
  AddIcon,
  Container,
  ImageContainer,
} from './styles'
import RESUME from './assets/resume.png'

export const CheckProfileBanner = () => {
  const [showHover, setShowHover] = useState(false)
  const navigate = useNavigate()
  const { i18n } = useI18n()

  return (
    <Step
      title={i18n.t('builder.job_search.check_profile_banner.title')}
      subtitle={i18n.t('builder.job_search.check_profile_banner.description')}
      isCompleted={false}
      isOpen={true}
      setIsOpen={() => {}}
      handleOnClick={() => {
        trackInternalEvent('click_profile_check_banner', { label: 'recommended_jobs' })
        localStorage.removeItem(SHOW_CHECK_PROFILE_BANNER_STORAGE_KEY)
        trackInternalEvent('view_profile_editor', { label: 'recommended_jobs' })
        navigate(`/career-profile/edit?returnUrl=/job-search?view=recommendation`)
      }}
      buttonText={i18n.t('builder.job_search.check_profile_banner.button')}
      excludeAnalytics
      titleClassname="external-banner"
      Images={
        <Container>
          <ImageContainer>
            <ResumeImage src={RESUME} />
            <ResumeImageGradient className={showHover ? 'hover' : ''} />
            <AddIconContainer className={showHover ? 'hover' : ''}>
              <AddIcon className={showHover ? 'hover' : ''} />
            </AddIconContainer>
          </ImageContainer>
          <EllipsisContainer>
            <EllipseOne className={showHover ? 'hover' : ''} />
            <EllipseTwo className={showHover ? 'hover' : ''} />
            <EllipseThree className={showHover ? 'hover' : ''} />
          </EllipsisContainer>
        </Container>
      }
      setShowHover={setShowHover}
    />
  )
}
