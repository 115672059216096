import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import { Icon20 } from 'builder/components/Icon'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 544px;
  background-color: ${Colors.White};
  border-radius: 16px;
  z-index: 999;

  ${Media.Phone`
    max-width: 343px;
  `};
`
export const Visual = styled.img`
  width: 70%;
  border-radius: 16px 16px 0 0;
  margin: 0 auto;
`
export const Close = styled.div`
  position: absolute;
  width: 28px;
  height: 28px;
  top: 32px;
  right: 32px;
  border-radius: 32px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.Neutral30};
  cursor: pointer;
  transition: color 0.1s;
  background-color: ${Colors.White};

  &:hover {
    color: ${Colors.Blue50};
  }

  ${Media.Phone`
    top: 20px;
    right: 20px;
    padding: 2px;
  `};
`
export const DescriptionSection = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  padding: 32px;

  ${Media.Phone`
    gap: 16px;
    padding: 20px;
  `};
`
export const Description = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: flex-start;

  ${Media.Phone`
    gap: 4px;
  `};
`
export const Header = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: flex-start;

  ${Media.Phone`
    gap: 8px;
  `};
`
export const PremiumBadge = styled.div`
  width: 218px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 40px;
  padding: 4px 12px 4px 8px;
  background-color: ${Colors.Indigo10};

  ${Media.Phone`
    width: 191px;
  `};
`
export const PremiumText = styled.div`
  ${FontWeights.DemiBold};
  color: ${Colors.Indigo70};
`
export const PremiumIcon = styled(Icon20.Crown)`
  width: 24px;
  height: 24px;
  color: ${Colors.Indigo70};
`
export const Title = styled.div`
  ${Typography.S};
  ${FontWeights.DemiBold};
`
export const SubTitle = styled.div`
  color: ${Colors.Neutral50};
`
export const UnlockFeaturesSection = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;

  ${Media.Phone`
    gap: 12px;
  `};
`
export const UnlockText = styled.div`
  ${Typography.Caps};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral80};
`
export const FeaturesContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  padding: 12px;
  background-color: ${Colors.Neutral5};
`
export const FeatureItem = styled.div`
  display: flex;
  gap: 4px;
`
export const Feature = styled.div`
  ${Typography.Caption};
`
export const GreenTick = styled(Icon20.Tick)`
  color: ${Colors.Green40};
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 4px;

  ${Media.Phone`
    gap: 8px;
  `};
`
