import {
  Container,
  Content,
  Header,
  Option,
  Options,
  Service,
  TimeText,
  RangeText,
  AvailableTimeCheckbox,
} from './styles'
import { availableTime } from './constants'

interface CareerEntryProps {
  handleAvailableTimesChange: (name: string) => void
  availableTimes: string[]
}
const AvailableTime = ({ handleAvailableTimesChange, availableTimes }: CareerEntryProps) => {
  return (
    <Container>
      <Content>
        <Header>What time work for you ?</Header>
        <Options>
          {availableTime?.map(time => {
            const timeRange = time.timeRange.replace(/\s/g, '')
            return (
              <Option
                htmlFor={timeRange}
                key={time.period}
                checked={availableTimes.includes(timeRange)}
              >
                <AvailableTimeCheckbox
                  id={timeRange}
                  onChange={() => {
                    handleAvailableTimesChange(timeRange)
                  }}
                  checked={availableTimes.includes(timeRange)}
                />
                <Service>
                  <TimeText>{time.period}</TimeText>
                  <RangeText>{time.timeRange}</RangeText>
                </Service>
              </Option>
            )
          })}
        </Options>
      </Content>
    </Container>
  )
}

export default AvailableTime
