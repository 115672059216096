import axios from 'axios'
import isPast from 'date-fns/isPast'
import { parseDate } from 'builder/utils/formatDate'
import { bindActionToPromise } from 'builder/utils/bindActionToPromise'
import { actions } from 'builder/modules/user'

export class JwtService {
  constructor(customToken) {
    this.client = axios.create()

    // Sign requests with a token
    this.client.interceptors.request.use(async config => {
      if (customToken) {
        config.headers.Authorization = `Bearer ${customToken}`
        return config
      }

      const token = await this.getToken()
      config.headers.Authorization = `Bearer ${token}`
      return config
    })
  }

  connect(store) {
    this.store = store
    this.extendJwt = bindActionToPromise(store.dispatch, actions.extendJwtRequest)
  }

  getToken = async () => {
    const state = this.store.getState()
    const token = state.user.jwt
    const expiresAt = state.user.jwtExpiresAt

    // Token expired
    if (expiresAt && isPast(parseDate(expiresAt))) {
      return this.extendJwt({ token })
    }

    // No token provided
    if (!token) throw new Error('No JWT token provided')

    return token
  }
}

export default new JwtService()
