import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { DragData, useElementDrag } from 'builder/hooks/useElementDrag'
import {
  Drawer,
  Header,
  Pull,
  PullContainer,
  ContentContainer,
  BackDrop,
  openedHeight,
  phoneHeight,
  tabletHeight,
} from './styles'
import { EditDrawerProps } from './types'

const animationDuration = 500
const swipeDistanceThresholdPx = 60

export const EditDrawer: React.FC<EditDrawerProps> = props => {
  const { isOpen, onToggle, children } = props
  const { isPhone } = useMediaQueries()
  const [isLocalOpen, setIsLocalOpen] = useState(false)
  const timeout = useRef<NodeJS.Timeout>()
  const pullRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isOpen !== undefined) {
      setIsLocalOpen(isOpen)
    }
  }, [isOpen])

  useEffect(() => () => clearTimeout(timeout.current), [])

  const onLocalToggle = useCallback(() => {
    const newValue = !isLocalOpen
    setIsLocalOpen(!isLocalOpen)

    if (newValue) {
      onToggle?.()
    } else {
      clearTimeout(timeout.current)
      timeout.current = setTimeout(() => onToggle?.(), animationDuration)
    }
  }, [isLocalOpen, onToggle])

  const onDragEnd = useCallback(
    (event: DragData) => {
      if (
        !!event.deltaY &&
        ((!isLocalOpen && event.deltaY < swipeDistanceThresholdPx) ||
          (isLocalOpen && event.deltaY > swipeDistanceThresholdPx))
      ) {
        onLocalToggle()
      }
    },
    [onLocalToggle, isLocalOpen],
  )

  const { isDragging, draggingPosition } = useElementDrag({ elementRef: pullRef, onDragEnd })

  const deltaY = isDragging ? -draggingPosition.deltaY : 0
  const height = isLocalOpen ? openedHeight : isPhone ? phoneHeight : tabletHeight

  return (
    <>
      {isOpen && <BackDrop onClick={onLocalToggle} />}
      <Drawer
        $isPhone={isPhone}
        $animationDurationMs={animationDuration}
        $isDragging={isDragging}
        style={{
          height: `calc(${height} + ${deltaY}px)`,
        }}
      >
        <Header ref={pullRef}>
          <PullContainer onClick={onLocalToggle}>
            <Pull />
          </PullContainer>
        </Header>
        <ContentContainer>{children}</ContentContainer>
      </Drawer>
    </>
  )
}
