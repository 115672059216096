import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import Colors from 'builder/styles/colors'
import { Typography, FontWeights } from 'builder/styles/typography'
import { Icon20 } from 'builder/components/Icon'

export const DesktopMenuContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
`

export const Divider = styled.div`
  flex-shrink: 0;
  margin: 0 40px;
  height: 16px;
  width: 1px;
  background-color: ${Colors.Neutral20};
`

export const Links = styled.div`
  display: flex;
  align-items: center;
`

export const MainPart = styled.div`
  flex-shrink: 0;
`

export const MenuLink = styled(NavLink)<{ $isActive: boolean }>`
  transition: color 0.15s;
  color: ${p => (p.$isActive ? Colors.Neutral50 : 'inherit')};

  &:hover {
    color: ${p => (p.$isActive ? Colors.Neutral50 : Colors.Blue50)};
  }

  &.active {
    color: ${Colors.Neutral50};
  }
`

export const MenuLinkWrapper = styled.div`
  position: relative;
  margin-right: 32px;

  &:last-child {
    margin-right: 0;
  }
`

export const RightContainer = styled.div`
  display: flex;
  align-items: center;
`

export const UpgradeButtonContainer = styled.a`
  ${Typography.Caption};
  ${FontWeights.DemiBold};
  display: flex;
  align-items: center;
  padding: 8px 11px 8px 8px;
  margin-right: 32px;
  border-radius: 4px;
  background-color: ${Colors.Indigo50};
  color: ${Colors.White};
  transition: background-color 0.15s;

  &:hover {
    background-color: ${Colors.Indigo60};
  }

  &:active {
    background-color: ${Colors.Indigo70};
  }
`

export const UpgradeButtonIcon = styled(Icon20.Crown)`
  margin-right: 4px;
`
