import CareerPlanningLevels from 'builder/components/CareerPlanningLevels'
import CareerPlanningProgress from 'builder/components/CareerPlanningProgress'

import { FoldableContainer } from '../../styles'

type Props = {
  score: number
  open: boolean
  isPhone: boolean
  handleClick: () => void
}

const MainContent = ({ score, isPhone, open, handleClick }: Props) => {
  return (
    <FoldableContainer $isOpen={open}>
      <CareerPlanningProgress score={score} isOpen={open} isDeskTop={false} isMobile={isPhone} />
      <CareerPlanningLevels handleClick={handleClick} />
    </FoldableContainer>
  )
}

export default MainContent
