import { useState, useRef, useCallback, useLayoutEffect } from 'react'
import * as React from 'react'
import { useI18n } from 'builder/hooks/useI18n'
import { roundNumber } from 'builder/utils/roundNumber'
import { clampNumber } from 'builder/utils/clampNumber'
import {
  RotateWrapper,
  RotateTitle,
  RotateControl,
  RotateTrack,
  RotateMarkers,
  RotateMarker,
  RotateMarkerLabel,
  RotateValueMarker,
} from './styles'

interface RotateSliderProps {
  value: number
  onChange: (value: number) => void
}

const SENSITIVITY = 0.5

export const RotateSlider = ({ value, onChange }: RotateSliderProps) => {
  const { i18n } = useI18n()
  const [isDragging, setDragging] = useState(false)
  const startingValue = useRef<number>(value)
  const startingPageX = useRef<number>(0)

  const track = useRef<HTMLDivElement>(null)
  const trackTransform = `translateX(calc(${(-value / 90) * 100}% + ${(value / 90) * 2}px))`

  const handleMoveStart = (event: React.MouseEvent) => {
    event.preventDefault()
    startingPageX.current = event.pageX
    startingValue.current = value
    setDragging(true)
  }

  const handleMove = useCallback(
    (event: MouseEvent) => {
      if (track.current && event.buttons > 0) {
        const relativeDistance = (startingPageX.current - event.pageX) / track.current.clientWidth

        let newValue = startingValue.current + relativeDistance * 90 * SENSITIVITY
        newValue = clampNumber(newValue, -45, 45)
        newValue = roundNumber(newValue, 1)
        if (newValue > -1 && newValue < 1) newValue = 0 // stick to zero

        onChange(newValue)
      } else {
        setDragging(false)
      }
    },
    [onChange],
  )

  const handleMoveEnd = useCallback(() => {
    setDragging(false)
  }, [])

  const toggleDocumentEvents = useCallback(
    state => {
      const toggleEvent = state ? window.addEventListener : window.removeEventListener
      toggleEvent('mousemove', handleMove)
      toggleEvent('mouseup', handleMoveEnd)
    },
    [handleMove, handleMoveEnd],
  )

  useLayoutEffect(() => {
    toggleDocumentEvents(isDragging)
    return () => {
      if (isDragging) toggleDocumentEvents(false)
    }
  }, [isDragging, toggleDocumentEvents])

  return (
    <RotateWrapper>
      <RotateTitle>{i18n.t('builder.avatar_editor.cropper.rotate_slider_label')}</RotateTitle>
      <RotateControl onMouseDown={handleMoveStart}>
        <RotateTrack ref={track} style={{ transform: trackTransform }}>
          <RotateMarkers>
            {[-45, -30, -15, 0, 15, 30, 45].map(marker => (
              <RotateMarker key={marker}>
                <RotateMarkerLabel isNegative={marker < 0}>{Math.abs(marker)}</RotateMarkerLabel>
              </RotateMarker>
            ))}
          </RotateMarkers>
        </RotateTrack>
        <RotateValueMarker>
          <RotateMarkerLabel isNegative={roundNumber(value) < 0}>
            {Math.abs(roundNumber(value))}
          </RotateMarkerLabel>
        </RotateValueMarker>
      </RotateControl>
    </RotateWrapper>
  )
}
