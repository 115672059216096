import { useMemo } from 'react'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/careerProfile'
import { useUser } from 'builder/hooks/useUser'
import { useI18n } from 'builder/hooks/useI18n'
import { DocumentTypes } from 'builder/modules/constants'
import { useCoverLetterTextParts } from './useCoverLetterTextParts'

export const useCoverLetterPreviewDocument = () => {
  const careerInsightsData = useTypedSelector(selectors.careerInsightsData)
  const user = useUser()
  const { i18n } = useI18n()
  const coverLetterTextParts = useCoverLetterTextParts()
  const applyingPosition =
    careerInsightsData?.careerAssessmentResults?.dataForGraphics?.progressionList?.jobTitle[0]
  const senderName = user?.fullName
  const currentLocale = i18n.currentLocale()

  return useMemo(() => {
    if (!coverLetterTextParts.length) {
      return null
    }

    const coverLetterText = coverLetterTextParts.map(item => `<p>${item}</p>`).join('')

    return {
      type: DocumentTypes.coverLetter,
      id: 0,
      name: null,
      locale: currentLocale,
      color: '#2196F3',
      template: 'stockholm',
      company: 'Google',
      address: '',
      city: null,
      phoneNumber: '(412) 479-6342',
      email: 'example@gmail.com',
      senderName,
      companyRecipient: null,
      content: coverLetterText,
      applyingPosition,
      version: 'ae9af1426d',
      aiGenerated: false,
      openCl: false,
      sourceResumeId: null,
      spacing: 0,
    }
  }, [coverLetterTextParts, applyingPosition, currentLocale, senderName])
}
