import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import { Icon24 } from 'builder/components/Icon'

export const StoriesWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 124px;
`

export const Title = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};
  margin-bottom: 24px;
`

export const StoriesTitleContainer = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid ${Colors.Neutral10};
  background: ${Colors.White};
  margin-bottom: 12px;
`

export const RowWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`

export const StoriesTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
`

export const StoryTitle = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
`

export const OpenSectionIcon = styled(Icon24.Chevron)`
  transform: rotate(-90deg);
  cursor: pointer;
  color: ${Colors.Neutral50};

  &:hover {
    color: ${Colors.Neutral90};
  }
`

export const CloseSectionIcon = styled(Icon24.Chevron)`
  transform: rotate(90deg);
  cursor: pointer;
  color: ${Colors.Neutral50};

  &:hover {
    color: ${Colors.Neutral90};
  }
`

export const RectangleStoriesContainer = styled.div`
  height: 1px;
  background: ${Colors.Neutral15};
  width: 100%;
  margin: 16px 0;
`

export const FoldableContainer = styled.div`
  width: 100%;
`

export const QuestionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 12px;
`

export const AnswerText = styled.div`
  color: ${Colors.Neutral50};
`
