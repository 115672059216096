import styled from 'styled-components'
import Media from 'builder/styles/media'

export const Form = styled.form<{ hasError: boolean }>`
  width: 416px;
  margin-bottom: -29px;

  ${Media.Tablet`
    width: 504px;
    height: auto;
  `}

  ${Media.Phone`
    width: 100%;
  `}

  ${props => Media.Phone`
    width: calc(100% - 40px);
    margin-bottom: ${props.hasError ? '-5' : '-29'}px;
  `}

  @media (max-width: 374px) {
    margin-bottom: 40px;
  }
`

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const TextFieldContainer = styled.div<{ hasError: boolean }>`
  width: 100%;
  margin-bottom: ${({ hasError }) => (hasError ? 16 : 24)}px;

  ${props => Media.Phone`
    margin-bottom: ${props.hasError ? 12 : 16}px;
    margin-top: 24px
  `}
`
