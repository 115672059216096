import { useCallback, useEffect, useState } from 'react'
import { useLocalStorage } from 'builder/hooks/useLocalStorage'
import { useJobSearch } from '../useJobSearch'
import { LOCAL_STORAGE_JS_HISTORY_QUERY_KEY } from '../SearchBar/constants'
import { useJobTitleFromLatestUpdateResume } from './useJobTitleFromLatestUpdateResume'
import { useGetProfile } from './useGetProfile'

export const usePrefillQuery = () => {
  const { term } = useJobSearch()
  const [prefillValue, setPrefillValue] = useState('')

  const jobTitleFromProfileResponse = useGetProfile()
  const jobTitleFromResumeResponse = useJobTitleFromLatestUpdateResume()
  const [recentSearches] = useLocalStorage<string[]>(LOCAL_STORAGE_JS_HISTORY_QUERY_KEY, [])
  const [isDonePrefill, setIsDonePrefill] = useState(false)

  const updateJobTitle = useCallback(
    (value: string) => {
      setPrefillValue(value)
      setIsDonePrefill(true)
    },
    [setPrefillValue],
  )

  useEffect(() => {
    if (isDonePrefill) {
      return
    }
    // 1. Get from query string
    if (term.trim().length > 0) {
      return updateJobTitle(term)
    }
    if (jobTitleFromProfileResponse.loading) {
      return
    }
    // 2. Get job title from profile
    if (jobTitleFromProfileResponse.response?.targetRole) {
      return updateJobTitle(jobTitleFromProfileResponse.response?.targetRole)
    }

    if (jobTitleFromResumeResponse.loading) {
      return
    }
    // 3. Get job title from last updated resume
    if (jobTitleFromResumeResponse.resume?.position) {
      return updateJobTitle(jobTitleFromResumeResponse.resume?.position)
    }

    // 4. Get job title from local storage
    const lastSearch = recentSearches[recentSearches.length - 1]
    if (lastSearch) {
      return updateJobTitle(lastSearch)
    }

    return updateJobTitle('')
  }, [
    isDonePrefill,
    jobTitleFromProfileResponse,
    jobTitleFromResumeResponse,
    recentSearches,
    term,
    updateJobTitle,
  ])

  return { isDonePrefill, prefillValue }
}
