import map from 'lodash/map'
import { STEPS } from '../SignUpStepManager'
import { Container, SocialProfileImage, IntroductionImage, ContactInfoImage } from './styles'

export interface Props {
  currentStepName: string
}

const stepNames = map(STEPS, name => name)

const isVisited = (targetStepName: string, currentStepName: string): boolean => {
  return stepNames.indexOf(currentStepName) >= stepNames.indexOf(targetStepName)
}

const Illustrations = ({ currentStepName }: Props) => {
  // In order to prevent the blinking effect (when one image is fading out and the other fading in)
  // we don't hide the previous step image but overlay it with a new one.

  return (
    <Container isVisible={isVisited(STEPS.socialProfile, currentStepName)}>
      <SocialProfileImage isVisible={STEPS.socialProfile === currentStepName} />
      <IntroductionImage isVisible={STEPS.introduction === currentStepName} />
      <ContactInfoImage isVisible={STEPS.contactInfo === currentStepName} />
    </Container>
  )
}

export default Illustrations
