import { Background } from './Background'
import { Process } from './Process'
import { Failed } from './Failed'
import { Container, BackgroundContainer, MatrixContainer } from './styles'

type Props = {
  isFailed: boolean
}

export const Loader = ({ isFailed }: Props) => {
  return (
    <Container>
      <BackgroundContainer timeout={0}>
        <MatrixContainer>
          <Background />
        </MatrixContainer>
        {isFailed ? <Failed /> : <Process />}
      </BackgroundContainer>
    </Container>
  )
}
