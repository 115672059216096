import type { CloudflareImageTransformations } from './image-transformations'

const SEPARATOR = ','
const ASSIGNER = '='

export const parse = (params?: string): Record<string, string> => {
  return params
    ? Object.fromEntries(params.split(SEPARATOR).map(operation => operation.split(ASSIGNER)))
    : {}
}

export const stringify = (
  params: Record<string, string | number | boolean> | undefined = {},
): string => {
  const entries = Object.entries(params)
  if (entries.length === 0) return ''
  return entries.map(operation => operation.join(ASSIGNER)).join(SEPARATOR)
}

type ImageUrlOptions = {
  base?: string
  defaults?: CloudflareImageTransformations
}

export const createImageUrlFormatter = ({ base, defaults }: ImageUrlOptions) => {
  return (url: string, options: CloudflareImageTransformations = {}) => {
    const parsedUrl = new URL(url, base)

    if (Object.keys(options).length === 0) {
      return url
    } else {
      const [, params, rest] = /\/cdn-cgi\/image\/([^/]+)(\/.*)/.exec(parsedUrl.pathname) ?? []
      const mergedOptions = Object.assign({}, defaults, parse(params), options)

      parsedUrl.pathname =
        '/cdn-cgi/image/' + stringify(mergedOptions) + (rest ?? parsedUrl.pathname)
    }

    return parsedUrl.toString()
  }
}
