import { UserData } from 'builder/modules/user'
import { i18n as I18n } from 'builder/utils/i18n'
import { translationKey } from '../../constants'
import * as Styled from './styles'

type Props = {
  user: UserData
  photoUrl: string
}

export const HeroSection = ({ user, photoUrl }: Props) => {
  return (
    <Styled.Container>
      <Styled.Content>
        <Styled.UserPhoto alt="User photo" src={photoUrl} />
        <Styled.TextContainer>
          <Styled.TextBold>
            {I18n.t(`${translationKey}.here_are_your_career_insights`, { name: user.firstName })}
          </Styled.TextBold>
          <Styled.Text>{I18n.t(`${translationKey}.begin_a_journey`)}</Styled.Text>
        </Styled.TextContainer>
      </Styled.Content>
    </Styled.Container>
  )
}
