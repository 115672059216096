import styled from 'styled-components'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import Button from 'builder/components/Button'
import Colors from 'builder/styles/colors'

export const Container = styled.div``

export const Content = styled.div`
  width: 558px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  ${Media.Tablet`
    width: 382px;
  `};

  ${Media.Phone`
    width: 287px;
    align-items: center;
  `};
`

export const Header = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};
  color: ${Colors.Green80};
  margin-bottom: 12px;
  width: 100%;

  ${Media.Tablet`
    ${Typography.S};
  `};

  ${Media.Phone`
    margin-bottom: 4px;
  `};
`

export const SubHeader = styled.div`
  width: 100%;
  gap: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  color: ${Colors.Green80};
`

export const TopContent = styled.div`
  width: 100%;
  gap: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`

export const Service = styled.div`
  ${Typography.S};
  ${FontWeights.DemiBold};
  width: 214px;

  ${Media.Phone`
    ${Typography.Subhead};
    width: 100%;
  `};
`

export const Text = styled.div`
  width: 100%;
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 32px;
  gap: 16px;

  ${Media.Phone`
    flex-direction: column;
    width: 100%;
    margin-top: 24px;
    gap: 12px;
  `};
`

export const StyledButton = styled(Button)`
  width: 148px;
  height: 48px;
  ${FontWeights.Medium};
  ${Typography.Body};

  ${Media.Phone`
    width: 100%;
  `};
`
