import { memo, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { i18n } from 'builder/utils/i18n'
import { SectionNames, selectors, actions } from 'builder/modules/resumeEditor'
import { Icon20 } from 'builder/components/Icon'
import {
  AiSuggestionsButton,
  AiSuggestionsPopupLayout,
  AiSuggestionsPopupListItem,
} from 'builder/components/AiFeatures/Suggestions'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useAutoTailoring } from 'builder/components/AutoTailoring/hooks/useAutoTailoring'
import { useKeywords } from '../../hooks/useKeywords'
import { KEYWORDS_ANALYTICS_LABEL } from '../../constants'
import { KeywordsCounter } from '../KeywordsCounter'
import { KeywordsInfoIcon } from '../KeywordsInfoIcon'
import { Description, CounterContainer, CounterHeader } from './styles'

const TRANSLATION = 'builder.resume_editor.keywords'

type Props = {
  section: SectionNames
  onClick?: () => void
  onActiveStateChange?: (isActive: boolean) => void
}

export const AiKeywordsToggler = memo((props: Props) => {
  const { section, onClick, onActiveStateChange } = props
  const dispatch = useDispatch()
  const { recommendedJobTitle } = useTypedSelector(selectors.jobPostingAPIData)
  const openedKeywordsModalType = useTypedSelector(selectors.openedKeywordsModalType)
  const openedAISuggestionsPopup = useTypedSelector(selectors.openedAISuggestionsPopup)
  const { allKeywords, addedKeywords, isKeywordsAvailable } = useKeywords()
  const [copiedKeyword, setCopiedKeyword] = useState('')
  const isPopupOpen = openedKeywordsModalType === section
  const { showApplyAllButton, tryToStartAutoTailoring } = useAutoTailoring()

  // auto hide popup on suggestion popup open
  useEffect(() => {
    if (openedAISuggestionsPopup) {
      dispatch(actions.setOpenedKeywordsModalType(null))
    }
  }, [openedAISuggestionsPopup, dispatch])

  useEffect(() => {
    if (openedKeywordsModalType === section && section in KEYWORDS_ANALYTICS_LABEL) {
      trackInternalEvent('open_ai_suggest_resume_optimizer', {
        label: KEYWORDS_ANALYTICS_LABEL[section],
        keywords_total: allKeywords.length,
        keywords_added: addedKeywords.length,
      })
    }
    // no need to add allKeywords and addedKeywords here because we need to report this thing
    // only on popup open
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedKeywordsModalType, section])

  const onClose = useCallback(() => {
    dispatch(actions.setOpenedKeywordsModalType(null))
  }, [dispatch])

  const onTogglerClick = useCallback(() => {
    if (!isPopupOpen) {
      dispatch(actions.setOpenedKeywordsModalType(section))

      if (section in KEYWORDS_ANALYTICS_LABEL) {
        trackInternalEvent('click_ai_suggest_resume_optimizer', {
          label: KEYWORDS_ANALYTICS_LABEL[section],
        })
      }
    } else {
      onClose()
    }
    onClick?.()
  }, [onClose, onClick, isPopupOpen, dispatch, section])

  const isKeywordAdded = (keyword: string) => {
    return addedKeywords.includes(keyword)
  }

  const onKeywordClick = (keyword: string) => {
    setCopiedKeyword(keyword)

    if (section in KEYWORDS_ANALYTICS_LABEL) {
      trackInternalEvent('copy_suggested_keyword', {
        label: KEYWORDS_ANALYTICS_LABEL[section],
        keyword,
      })
    }

    if (!navigator.clipboard) {
      console.warn(
        `[AiKeywordsToggler]
        Your text is not copied because of local environment.
        Everything will be ok in prod`,
      )
      return
    }

    navigator.clipboard.writeText(keyword)
  }

  useEffect(() => {
    let timeout: number

    if (copiedKeyword) {
      timeout = window.setTimeout(() => setCopiedKeyword(''), 2000)
    }

    return () => clearTimeout(timeout)
  }, [copiedKeyword])

  useEffect(() => {
    onActiveStateChange?.(isPopupOpen)
  }, [isPopupOpen, onActiveStateChange])

  const onApplyAll = useCallback(() => {
    tryToStartAutoTailoring()

    trackInternalEvent('click_apply_all_auto_tailoring', {
      label: KEYWORDS_ANALYTICS_LABEL[section],
    })
  }, [section, tryToStartAutoTailoring])

  return isKeywordsAvailable ? (
    <AiSuggestionsPopupLayout
      {...{
        showApplyAllButton,
        onClose,
        isPopupOpen,
        tailoredDesignation: recommendedJobTitle,
        shouldCloseOnClickOutside: false,
        onApplyAll,
        content: (
          <>
            <CounterContainer>
              <CounterHeader>
                <KeywordsCounter count={addedKeywords.length} total={allKeywords.length} />
                <KeywordsInfoIcon />
              </CounterHeader>
              <Description>{i18n.t(`${TRANSLATION}.popup.description`)}</Description>
            </CounterContainer>
            {allKeywords.map(item => {
              const isAdded = isKeywordAdded(item)
              const isCopied = copiedKeyword === item
              const onClick = () => {
                if (!isAdded) onKeywordClick(item)
              }

              const icon = isAdded ? (
                <Icon20.Check />
              ) : (
                <>
                  <Icon20.Copy />
                  {isCopied ? i18n.t(`${TRANSLATION}.popup.copied`) : null}
                </>
              )

              return (
                <AiSuggestionsPopupListItem
                  key={item}
                  isActive={isCopied}
                  text={item}
                  icon={icon}
                  isDisabled={isAdded}
                  onClick={onClick}
                />
              )
            })}
          </>
        ),
      }}
    >
      <AiSuggestionsButton onClick={onTogglerClick} isActive={isPopupOpen} />
    </AiSuggestionsPopupLayout>
  ) : null
})
