import { memo } from 'react'
import { useAppSelector } from 'builder/hooks/useAppSelector'
import { i18n as I18n } from 'builder/utils/i18n'
import Tooltip from 'builder/components/Tooltip/Tooltip'
import ResumeUploadedIllustration from '../../../../../../app/assets/images/builder/fill-resume/upload-success-illustration.png'
import { Div, UploadSuccessIllustration, SuccessTitle, SuccessCaption } from '../styles'

const FileUploadSuccess = () => {
  const selectedResume = useAppSelector(
    state => state.resumeEditor.prefillResumeSection.selectedResume,
  )
  const selectedResumeName = selectedResume?.name

  return (
    <>
      <Div>
        <UploadSuccessIllustration src={ResumeUploadedIllustration} />
      </Div>
      <SuccessTitle>{I18n.t('builder.upload_resume.upload_complete')}</SuccessTitle>
      <Tooltip value={selectedResumeName} multiline>
        <SuccessCaption>{selectedResumeName}</SuccessCaption>
      </Tooltip>
    </>
  )
}

export default memo(FileUploadSuccess)
