import { EventEmitter } from 'eventemitter3'
import debounce from 'lodash/debounce'
import { SpellCheckSuggestion } from '../types'

class SpellCheckEmitter extends EventEmitter {
  private wordId: string | null = null
  private isDropdownOpen = false

  showSuggestions({
    wordId,
    word,
    position,
    wordStartIndex,
    wordEndIndex,
    blockKey,
  }: SpellCheckSuggestion) {
    // Going back to the same word when dropdown is hidden
    // restarts delay
    if (this.wordId === wordId && !this.isDropdownOpen) {
      this.showDropdown.cancel()
      this.showDropdown({ wordId, word, position, wordStartIndex, wordEndIndex, blockKey })
      return
    }

    // Going back to the same word when dropdown is hidden
    // cancels closing event
    if (this.wordId === wordId && this.isDropdownOpen) {
      this.hideDropdown.cancel()
      return
    }

    // Moving to next word when drop down
    if (this.wordId !== wordId && this.isDropdownOpen) {
      this.emit('dimWord', this.wordId)
      this.emit('highlightWord', wordId)
      this.emit('showDropdown', { wordId, word, position, wordStartIndex, wordEndIndex, blockKey })
      this.wordId = wordId
      this.hideDropdown.cancel()
      return
    }

    if (this.wordId === null) {
      this.wordId = wordId
      this.showDropdown({ wordId, word, position, wordStartIndex, wordEndIndex, blockKey })
      this.hideDropdown.cancel()
    }
  }

  hideSuggestions({ wordId }: { wordId: string }) {
    if (!this.isDropdownOpen) {
      this.showDropdown.cancel()
      this.wordId = null
      this.emit('dimWord', wordId)
      return
    }

    this.hideDropdown(wordId)
  }

  showDropdown = debounce(({ wordId, word, position, wordStartIndex, wordEndIndex, blockKey }) => {
    this.isDropdownOpen = true
    this.emit('highlightWord', wordId)
    this.emit('showDropdown', { wordId, word, position, wordStartIndex, wordEndIndex, blockKey })
  }, 300)

  hideDropdown = debounce(wordId => {
    this.isDropdownOpen = false
    this.wordId = null
    this.emit('dimWord', wordId)
    this.emit('hideDropdown')
  }, 1000)

  hideSuggestionsInstantly = ({ wordId }: { wordId: string }) => {
    this.isDropdownOpen = false
    this.wordId = null
    this.emit('dimWord', wordId)
    this.emit('hideDropdown')
  }
}

export default SpellCheckEmitter
