import { Styles } from 'react-joyride'
import { Styles as FloaterStyles } from 'react-floater'

export const DEFAULT_STYLES: Partial<Styles> = {
  options: { zIndex: 10000 },
}

export const DEFAULT_ARROW: FloaterStyles['arrow'] = {
  color: '#fff',
  length: 10,
  spread: 22,
}
