import styled, { keyframes } from 'styled-components'
import Typography from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 832px;
  max-height: 640px;
  padding: 68px 0 72px;
  text-align: center;
  background-color: ${Colors.Neutral10};
  background-image: url(${require('images/modals/cover-letter-modal-bg.svg')});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto;
  border-radius: 6px;

  ${Media.Tablet`
    max-width: 640px;
    margin: 0 44px;
  `}

  ${Media.Phone`
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    padding: 0 20px;
    background-size: auto 120%;
    margin: 0;
    border-radius: 0;
  `}
`

export const Content = styled.div`
  max-width: 540px;
  padding: 0 60px;
  margin: 0 auto;

  ${Media.Tablet`
    max-width: 440px;
  `}

  ${Media.Phone`
    max-width: 100%;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `}
`

export const Close = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  color: ${Colors.Neutral30};
  display: flex;
  padding: 16px;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover {
    color: ${Colors.Neutral40};
  }

  ${Media.Phone`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 32px;
    height: 32px;
    top: 20px;
    right: 20px;
    border-radius: 50%;
    color: ${Colors.White};
    background-color: ${Colors.Neutral30};

    &:hover {
      background-color: ${Colors.Neutral40};
      color: ${Colors.White};
    }
  `}
`

export const Title = styled.div`
  ${Typography.M}
  font-weight: bold;
  margin-bottom: 8px;
`

export const Subtitle = styled.div`
  ${Typography.Body}
  margin-bottom: 24px;
`

export const ImagesContainer = styled.div`
  display: inline-block;
  position: relative;
  margin: 0 auto 24px;
  width: 200px;
  height: 280px;
  perspective: 800px;

  ${Media.Phone`
    width: 166px;
    height: 232px;
  `}
`

const slideFromRight = keyframes`
  0% {
    right: -100px;
    opacity: 0;
  }

  100% {
    opacity: 1;
    right: 0;
  }
`

const slideFromLeft = keyframes`
  0% {
    left: -100px;
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    left: 0;
  }
`

const Image = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  border-radius: 4px;
  box-shadow: 0px 32px 48px rgba(38, 43, 51, 0.12), 0px 4px 16px rgba(87, 117, 167, 0.12);
  transition: transform 0.1s linear;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`

export const ResumeImage = styled(Image)`
  background-color: ${Colors.Neutral20};
  animation: ${slideFromLeft} 2s ease;
  z-index: 2;
`

export const LetterImage = styled(Image)`
  background-color: ${Colors.Neutral30};
  animation: ${slideFromRight} 2s ease;
  z-index: 1;
`
