import axios, { AxiosError } from 'axios'

/**
 * Extracts an error message sent from the API or
 * reads message string from any Error class instance (can be both axios network or JS error)
 */
export const extractErrorMessage = (
  error: Error | AxiosError<{ errorMessage?: string }>,
): string => {
  return (axios.isAxiosError(error) && error.response?.data?.errorMessage) || error.message
}
