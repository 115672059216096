import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { Typography, FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding-inline: 8px;
`

export const Separator = styled.div`
  border: 1px solid ${Colors.Neutral15};
`

export const Title = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};
`

export const Value = styled.div`
  ${Typography.M};
`
