/* eslint-disable max-len */
import { useMemo } from 'react'

import * as React from 'react'
import { ResumeCritique } from 'builder/modules/resumeReview'

import {
  StatusContainerStatic,
  StatusContent,
  StatusTitle,
  StatusText,
  StatusPhotoStar,
  StatusPhotoCritiuqePage,
  StatusVisualCritque,
  StatusInnerCritiquePage,
} from './styles'

interface ResumeReviewStatusUiProps extends React.HTMLAttributes<HTMLDivElement> {
  critique: ResumeCritique
  isResumeNearlyPerfect?: boolean
}

export const CritiqueResumeReviewStaticStatus = ({
  critique,
  isResumeNearlyPerfect,
  ...rest
}: ResumeReviewStatusUiProps) => {
  const { status } = critique

  const title = useMemo(() => {
    return 'We have already wrapped your resume in the winning template!'
  }, [])

  const text = useMemo(() => {
    if (isResumeNearlyPerfect) {
      return `Our resume expert ${critique?.reviewer?.name} checked your resume and it seems it's almost perfect.`
    }
    return `Check your personalized tips below by resume expert ${critique?.reviewer?.name} on how else you can improve your uploaded resume`
  }, [critique?.reviewer?.name, isResumeNearlyPerfect])

  if (!critique.reviewer.photoUrl) {
    return null
  }

  return (
    <StatusContainerStatic {...rest} status={status}>
      <StatusVisualCritque>
        <StatusPhotoCritiuqePage src={critique.reviewer.photoUrl} />
        <StatusPhotoStar
          src={require('images/builder/panel/resume-review-status/reviewStar.svg')}
        />
      </StatusVisualCritque>

      <StatusInnerCritiquePage>
        <StatusContent>
          <StatusTitle>{title}</StatusTitle>
          <StatusText>{text}</StatusText>
        </StatusContent>
      </StatusInnerCritiquePage>
    </StatusContainerStatic>
  )
}
