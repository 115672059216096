import { CareerCardType } from 'builder/modules/careerPath2'
import * as Styled from './styles'

type Props = {
  userPhotoUrl: string
  careerCards: CareerCardType[]
}

export const CareerRolesVisual = ({ careerCards, userPhotoUrl }: Props) => {
  const careerPaths = careerCards[careerCards.length - 1].pathways.path1.map(path => path.title)

  return (
    <Styled.Plate>
      <Styled.Photo alt="User photo" src={userPhotoUrl} />
      <Styled.TextContainer>
        {careerPaths?.map((careerPath, index) => (
          <Styled.Text key={index}>{careerPath}</Styled.Text>
        ))}
      </Styled.TextContainer>
      <Styled.Gradient />
    </Styled.Plate>
  )
}
