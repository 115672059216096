import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useI18n } from 'builder/hooks/useI18n'
import Spinner from 'builder/components/Spinner'
import { useUser } from 'builder/hooks/useUser'
import { useJobTitle } from 'builder/views/Interview/hooks/useJobTitle'
import { QuestionTemplate } from 'builder/modules/interview/types'
import { selectors } from 'builder/modules/interview/interviewModule'
import { useQueryParam } from 'builder/hooks/useQueryParam'
import { SettingsIcon } from 'builder/components/Navigation/components/UserSettings/styles'
import { ChangeJobModal } from '../DashboardView/ChangeJobModal/ChangeJobModal'
import { PremiumDialog } from '../PremiumDialog/PremiumDialog'
import { LIMIT_QUESTIONS_FREE_USER_SEES_ON_QUESTIONS_LIBRARY } from '../../constants'
import { PracticeThisQuestionModal } from '../DashboardView/Modals/PracticeThisModal/PracticeThisQuestionModal'
import { useInfiniteScrollQuestionTemplate } from '../../hooks/useInfiniteScrollQuestionTemplate'

import { QuestionCard } from './QuestionCard/QuestionCard'
import {
  Container,
  LeftArrowIcon,
  BackButton,
  Title,
  Preposition,
  Role,
  Subtitle,
  PrepositionRoleContainer,
  HeaderContainer,
  Body,
  SpinnerContainer,
  RoleContainer,
} from './style'
import { PremiumBanner } from './components/PremiumBanner/PremiumBanner'

const TRANSLATION_KEY = 'builder.interview_prep_view.question_library'

export const QuestionsLibraryView = () => {
  const user = useUser()
  const navigate = useNavigate()
  const [jobTitleIdQueryParam, setJobTitleIdQueryParam] = useQueryParam('jobTitleId')
  const parsedJobTitleIdQueryParam =
    typeof jobTitleIdQueryParam === 'string' ? parseInt(jobTitleIdQueryParam) : undefined

  const interviewJobTitleId = useSelector(selectors.jobTitleId)
  const previousInterviewJobTitleIdRef = useRef(interviewJobTitleId)

  const jobTitleId = parsedJobTitleIdQueryParam || interviewJobTitleId

  useEffect(() => {
    if (jobTitleIdQueryParam && previousInterviewJobTitleIdRef.current !== interviewJobTitleId) {
      setJobTitleIdQueryParam(String(interviewJobTitleId))
    }

    previousInterviewJobTitleIdRef.current = interviewJobTitleId
  }, [jobTitleIdQueryParam, interviewJobTitleId, setJobTitleIdQueryParam])

  useEffect(() => {
    if (!jobTitleId) {
      return navigate('/interview-preparation/start')
    }
  }, [jobTitleId, navigate])

  useEffect(() => {
    trackInternalEvent('visit_interview_questions_library', {
      label: 'interview_prep',
    })
  }, [])

  const isFreePlan = !user?.hasPremiumFeatures
  const { i18n } = useI18n()

  const { data: jobTitleData } = useJobTitle(jobTitleId)
  const [selectedQuestion, setSelectedQuestion] = useState<QuestionTemplate>()

  const questionTemplate = useInfiniteScrollQuestionTemplate({
    jobTitleId: jobTitleId,
    perPage: 50,
    total: 50,
  })
  const questions = (questionTemplate?.data?.pages || []).flatMap(page => page.items)
  const [showJobTitleModal, setShowJobTitleModal] = useState(false)
  const [showPremiumModal, setShowPremiumModal] = useState(false)

  const openChangeJobTitleDialogModal = () => setShowJobTitleModal(true)
  const closeChangeJobTitleDialogModal = () => setShowJobTitleModal(false)
  const { isPhone } = useMediaQueries()

  const goToDashboard = () => {
    navigate('/interview-preparation/dashboard')
  }

  let jobSuggestion
  if (jobTitleId && jobTitleData?.jobTitle) {
    jobSuggestion = {
      text: jobTitleData?.jobTitle,
      id: jobTitleId,
    }
  }
  const showPaywall =
    questions.length >= LIMIT_QUESTIONS_FREE_USER_SEES_ON_QUESTIONS_LIBRARY && isFreePlan

  return (
    <Container $isFreeUser={showPaywall}>
      {!parsedJobTitleIdQueryParam && (
        <BackButton onClick={goToDashboard}>
          <LeftArrowIcon />
          {!isPhone && i18n.t(`${TRANSLATION_KEY}.back`)}
        </BackButton>
      )}
      <HeaderContainer>
        <div>
          <Title>{i18n.t(`${TRANSLATION_KEY}.title`)}</Title>
          {jobTitleData?.jobTitle && (
            <PrepositionRoleContainer>
              <Preposition>for</Preposition>
              <RoleContainer $isFreePremium={isFreePlan}>
                <Role onClick={openChangeJobTitleDialogModal}>{jobTitleData?.jobTitle}</Role>
                <SettingsIcon onClick={openChangeJobTitleDialogModal} />
              </RoleContainer>
            </PrepositionRoleContainer>
          )}
        </div>
      </HeaderContainer>
      <Subtitle>{i18n.t(`${TRANSLATION_KEY}.subtitle`)}</Subtitle>
      <Body>
        {questions.length === 0 && (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )}
        {questions.map(question => (
          <QuestionCard
            key={question.id}
            question={question}
            type="question"
            onClick={() => {
              setSelectedQuestion(question)
              trackInternalEvent('click_interview_question', {
                label: 'interview_prep',
                parameter: 'library',
              })
            }}
          />
        ))}
      </Body>

      {selectedQuestion && (
        <PracticeThisQuestionModal
          trackingParameter="library"
          question={selectedQuestion}
          onConfirmation={() => {
            if (isFreePlan) {
              setShowPremiumModal(true)
              setSelectedQuestion(undefined)
            }
          }}
          onClose={() => setSelectedQuestion(undefined)}
        />
      )}
      {showPremiumModal && (
        <PremiumDialog trackingParameter="library" onClose={() => setShowPremiumModal(false)} />
      )}
      {showPaywall && <PremiumBanner />}

      {showJobTitleModal && (
        <ChangeJobModal
          trackingParameter="library"
          onClose={closeChangeJobTitleDialogModal}
          jobSuggestion={jobSuggestion}
        />
      )}
    </Container>
  )
}
