import { createSlice } from '@reduxjs/toolkit'
import { Store } from 'builder/modules/store'
import { actions as userActions } from '../user/userModule'
import { AutoApplyStore, WorkingTypes } from './types'
const initialState: AutoApplyStore = {
  form: {
    id: 0,
    firstName: '',
    lastName: '',
    currentJobTitle: '',
    gender: '',
    resumeUrl: '',
    race: '',
    location: '',
    fullAddress: '',
    email: '',
    phoneNumber: '',
    targetRole: '',
    countriesAuthorizedToWorkIn: [],
    socialLinksAttributes: [
      {
        link: '',
        title: '',
      },
    ],
    jobPreferenceAttributes: {
      currentSalary: undefined,
      currentCurrency: 'USD',
      currentSalaryPlan: 'annually',
      expectedSalary: undefined,
      expectedCurrency: 'USD',
      expectedSalaryPlan: 'annually',
      expectedWorkingTypes: [WorkingTypes.office],
      openForBusinessTrips: false,
      willingToRelocate: false,
      willingToRelocateAtYourOwnCost: false,
      expectedStartWork: 'immediate',
      fullyVaccinatedAgreementAgainstCovid: false,
      hasRestrictionLimiting: false,
      openToRemoteOpportunities: false,
    },
    coursesAttributes: [
      {
        course: '',
        dateFrom: '',
        dateUntil: '',
        institution: '',
      },
    ],
    autoApplyDataCompleted: false,
    targetLocations: [],
  },
  uploadedResume: undefined,
  uploadedResumeLink: '',
  selectedResumeId: 0,
  selectedTRResumeId: 0,
  lastResumeAction: 'upload',
  resumeDistributionForm: {
    jobPreferences: {
      country: undefined,
      state: undefined,
      industry: '',
      remote: 'false',
      salaryCurrency: 'USD',
      salaryAmount: '',
      salaryPeriod: 'year',
    },
    location: {
      country: undefined,
      state: undefined,
      city: undefined,
    },
  },
}

export const selectors = {
  form: (state: Store) => state.autoApply.form,
  resumeDistributionForm: (state: Store) => state.autoApply.resumeDistributionForm,
  uploadedResume: (state: Store) => state.autoApply.uploadedResume,
  selectedResumeId: (state: Store) => state.autoApply.selectedResumeId,
  selectedTRResumeId: (state: Store) => state.autoApply.selectedTRResumeId,
  selectedResume: (state: Store) =>
    state.panel.documents.find(d => d.id === state.autoApply.selectedResumeId),
  selectLastResumeAction: (state: Store) => state.autoApply.lastResumeAction,
  isAutoApplyFormCompleted: (state: Store) => state.autoApply.form?.autoApplyDataCompleted,
}

const { reducer, actions } = createSlice({
  name: 'autoApply',
  initialState,
  reducers: {
    updateForm: (state, action) => {
      state.form = { ...state.form, ...structuredClone(action.payload) }
    },
    updateResumeDistributionForm: (state, action) => {
      state.resumeDistributionForm = {
        ...state.resumeDistributionForm,
        ...structuredClone(action.payload),
      }
    },
    uploadResume: (state, action) => {
      state.uploadedResume = action.payload
      state.lastResumeAction = 'upload'
    },
    selectResume: (state, action) => {
      state.selectedResumeId = action.payload
      state.lastResumeAction = 'select'
    },
    selectTRResume: (state, action) => {
      state.selectedTRResumeId = action.payload
      state.lastResumeAction = 'select'
    },
  },
  extraReducers: builder => {
    builder.addCase(userActions.setAccount, (state, action) => {
      state.form.email = action.payload.user?.email || ''
      state.form.firstName = action.payload.user?.firstName || ''
      state.form.lastName = action.payload.user?.lastName || ''
    })
  },
})

export { reducer, actions }
