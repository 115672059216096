import { trackInternalEvent } from '@rio/tracking'
import { Cards } from 'builder/components/SubscriptionCancellationFormNew/common/types'
import { useConfig } from 'builder/hooks/useConfig'
import {
  CIOProductsInterview,
  CIOProductsJobTracker,
  CIOProductsPitch,
} from 'builder/components/Icon/32x32'
import { TryItChevron } from 'builder/components/Icon/24x24'
import { i18n as I18n } from 'builder/utils/i18n'
import { useCancellationFormContext } from '../../FormSection'
import { GettingStartedText } from '../../PromotionSection/PromotionButton/GettingStartedText'
import { Anchor } from '../PromotionLinksV7/Anchor'
import { PromotionLinksV7 } from './PromotionLinksV7'
import { PromotionListContainerV7 } from './PromotionListContainerV7'

const Link = () => {
  const {
    activeCard,
    isACIORegisteredUser,
    isPromotionButtonLoading,
    promotionButtonText,
    promotionTopic,
    dontNeedPromoSelectedIndex,
    setDontNeedPromoSelectedIndex,
  } = useCancellationFormContext()

  const config = useConfig()
  const isSubscriptionV4Enabled = config?.features.subCancellationV4 || false

  const showGettingStartedText =
    !isACIORegisteredUser && [Cards.JobOffer, Cards.DreamJob].includes(activeCard as Cards)

  const handlePromoClick = (index: number, promo: string) => {
    trackInternalEvent('promo_show', { promo: promo, where: 'cancellation' })
    setDontNeedPromoSelectedIndex(index)
  }

  const handleAnchorClick = (promo: string) => {
    trackInternalEvent('promo_acceptance', { promo: promo, where: 'cancellation' })
  }

  return (
    <PromotionListContainerV7>
      <PromotionLinksV7
        isActive={dontNeedPromoSelectedIndex === 0}
        isLoading={isPromotionButtonLoading}
        onClick={() => handlePromoClick(0, 'job_tracker')}
        topic={promotionTopic}
      >
        <CIOProductsJobTracker />
        {promotionButtonText[0]}
        <Anchor
          href="https://career.io/app/job-tracking"
          onClick={() => handleAnchorClick('job_tracker')}
        >
          Try it
          <TryItChevron />
        </Anchor>
      </PromotionLinksV7>
      <PromotionLinksV7
        isActive={dontNeedPromoSelectedIndex === 1}
        isLoading={isPromotionButtonLoading}
        onClick={() => handlePromoClick(1, 'profile')}
        topic={promotionTopic}
      >
        <CIOProductsPitch />
        {promotionButtonText[1]}
        <Anchor
          href="https://career.io/app/career-profile/profile"
          onClick={() => handleAnchorClick('profile')}
        >
          Try it
          <TryItChevron />
        </Anchor>
      </PromotionLinksV7>
      <PromotionLinksV7
        isActive={dontNeedPromoSelectedIndex === 2}
        isLoading={isPromotionButtonLoading}
        onClick={() => handlePromoClick(2, 'interview_prep')}
        topic={promotionTopic}
      >
        <CIOProductsInterview />
        {promotionButtonText[2]}
        <Anchor
          href="https://career.io/app/interview-preparation/start"
          onClick={() => handleAnchorClick('interview_prep')}
        >
          Try it
          <TryItChevron />
        </Anchor>
      </PromotionLinksV7>
      {/* Do we need this? */}
      {!isSubscriptionV4Enabled && showGettingStartedText && (
        <GettingStartedText topic={promotionTopic}>
          {I18n.t('builder.subscription_cancellation.promotion_section.get_started')}
        </GettingStartedText>
      )}
    </PromotionListContainerV7>
  )
}

export default Link
