import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as React from 'react'
import { Resume } from '@rio/types'
import { trackInternalEvent } from '@rio/tracking'
import AnchorManager from 'builder/services/AnchorManager'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useQueryParam } from 'builder/hooks/useQueryParam'
import { analyzeResume } from '../utils/analyzeResume'
import { useThrottledTaskAnalyzer } from '../hooks/useThrottledTaskAnalyzer'
import { TunerEditorStatusModal } from '../components/TunerEditorStatusModal'
import { TunerEditorFloatingButton } from '../components/TunerEditorFloatingButton'
import { TunerEditorContextValue, CheckIssueId, TaskIds } from '../types'

export const TunerEditorContext = createContext<TunerEditorContextValue>({
  issues: [],
  navigationIssueIds: [],
  activeIssueId: null,
  nextIssueId: null,
  activateIssue: () => {},
  showStatus: () => {},
  cancel: () => {},
})

export const TunerEditorProvider: React.FC<{ resume: Resume }> = ({ resume, children }) => {
  // The tuner editor feature doesn't work on small screens
  const { isDesktop: isBigScreen } = useMediaQueries()

  // The tuner editor can be turned on and off via query string
  // Watch "tuner_tips" query param to detect task to analyze
  const [taskId, setTaskId] = useQueryParam('tuner_tips')
  // Resume analysis is probably require some resources, so we shouldn't perform it too often
  const task = useThrottledTaskAnalyzer(resume, taskId || null, 350)

  // State variables to control the UI and navigation
  const lastTaskId = useRef<string | null>(null)
  const completedTaskIds = useRef<TaskIds[]>([])
  const [activeIssueId, activateIssue] = useState<CheckIssueId | null>(null)
  const [navigationIssueIds, setNavigationIssueIds] = useState<CheckIssueId[]>([])
  const [isModalOpen, toggleModal] = useState(false)
  const [skippedTaskId, setSkippedTaskId] = useState<TaskIds | null>(null)

  // All of the issues available for the task (included fixed ones)
  const issues = useMemo(() => {
    return task ? task.issues : []
  }, [task])

  // Find and cache IDs of all issues that needs to be fixed
  const incompleteIssueIds = useMemo(() => {
    return issues.filter(issue => issue.progress < 1).map(issue => issue.id)
  }, [issues])

  // React on task changes
  useEffect(() => {
    // Reset everything if no task found (empty or incorrect query)
    if (task === null) {
      setNavigationIssueIds([])
      activateIssue(null)
      lastTaskId.current = null
    } else if (lastTaskId.current !== task.id) {
      // Persist these ids to display bullets and perform navigation between tooltips
      setNavigationIssueIds(incompleteIssueIds)
      activateIssue(incompleteIssueIds.length > 0 ? incompleteIssueIds[0] : null)
      setSkippedTaskId(null)
      lastTaskId.current = task.id
    }
  }, [task, incompleteIssueIds])

  // Track task completion (no issues left for the current task)
  useEffect(() => {
    if (task === null) return

    if (incompleteIssueIds.length === 0 && completedTaskIds.current.includes(task.id) === false) {
      trackInternalEvent('complete_tuner_task', { task: task.id })
      completedTaskIds.current.push(task.id)
    }
  }, [incompleteIssueIds.length, task])

  // Detect the section containing the current issue
  const activeSectionId = useMemo(() => {
    return issues.find(issue => issue.id === activeIssueId)?.sectionId
  }, [activeIssueId, issues])

  // Auto scroll to the active issue
  useEffect(() => {
    if (activeSectionId) AnchorManager.scrollToAnchor(activeSectionId)
  }, [activeSectionId])

  // ID of the issue that can be shown by "Go To Next Step" button
  // `null` means the active issue is the last (and/or the only) one
  const nextIssueId = useMemo(() => {
    if (!activeIssueId) return null
    const currentIndex = navigationIssueIds.indexOf(activeIssueId)
    return navigationIssueIds[currentIndex + 1] || null
  }, [activeIssueId, navigationIssueIds])

  // Cancels the flow and closes an active tooltip or modal
  const cancel = useCallback(() => {
    setTaskId(null, { replace: true })
    toggleModal(false)
    // Show button that allows returning to the resume tuning flow
    const skippedTask = task || analyzeResume(resume)?.tasks?.find(t => t.done === false)
    setSkippedTaskId(skippedTask ? skippedTask.id : null)
  }, [resume, task, setTaskId])

  // Changes an active task manually
  const activateTask = useCallback(
    (taskId: TaskIds) => {
      setSkippedTaskId(null)
      toggleModal(false)
      setTaskId(taskId, { replace: true })
    },
    [setTaskId],
  )

  // Shows the modal with the current status of the resume tuning
  const showStatus = useCallback(() => {
    setTaskId(null, { replace: true })
    toggleModal(true)
  }, [setTaskId])

  // Close and hide everything if user screen is to small to use the tuner
  useEffect(() => {
    if (!isBigScreen && isModalOpen) {
      setTaskId(null, { replace: true })
      setSkippedTaskId(null)
      toggleModal(false)
    }
  }, [isBigScreen, isModalOpen, setTaskId])

  return (
    <TunerEditorContext.Provider
      value={{
        issues,
        navigationIssueIds,
        activeIssueId,
        nextIssueId,
        activateIssue,
        showStatus,
        cancel,
      }}
    >
      {children}

      {isModalOpen && (
        <TunerEditorStatusModal resume={resume} onTaskClick={activateTask} onClose={cancel} />
      )}

      {skippedTaskId && (
        <TunerEditorFloatingButton
          resume={resume}
          skippedTaskId={skippedTaskId}
          onClick={() => activateTask(skippedTaskId)}
        />
      )}
    </TunerEditorContext.Provider>
  )
}
