import { useMemo } from 'react'
import { useResume } from 'builder/hooks/useResume'
import { TunerBanner } from './components/TunerBanner'
import { TunerContext } from './contexts/TunerContext'
import { useAnalyzer } from './hooks/useAnalyzer'

interface Props {
  resumeId: number
}

export const Tuner = ({ resumeId }: Props) => {
  const resume = useResume(resumeId)
  const { tasks, score } = useAnalyzer(resume)
  const completeTasks = useMemo(() => tasks?.filter(task => task.done), [tasks])

  // TODO: Skeleton
  if (!resume) return null

  // Render nothing if tuner is complete/inactive
  if (completeTasks?.length === tasks?.length) return null

  return (
    <TunerContext.Provider value={{ resume, tasks, score }}>
      <TunerBanner />
    </TunerContext.Provider>
  )
}
