import { useI18n } from 'builder/hooks/useI18n'

import { useAutoApply } from 'builder/views/AutoApply/hooks/useAutoApply'
import {
  Close,
  CloseButtonContainer,
  CloseIcon,
  NotEnoughCreditsDescription,
  NotEnoughCreditsFeature,
  NotEnoughCreditsFeatureTitle,
  NotEnoughCreditsFeatures,
  NotEnoughCreditsFeaturesContainer,
  NotEnoughCreditsHeaderContainer,
  NotEnoughCreditsTextContainer,
  NotEnoughCreditsTitle,
  HeaderImage,
  ModalSection,
  Overlay,
  RootModalContainer,
} from './styles'
import { useResumeDistributionNotEnoughCreditsModal } from './useResumeDistributionNotEnoughCreditsModal'

const DEFAULT_MAX_CREDITS = 20

export const ResumeDistributionNotEnoughCreditsModal = () => {
  const { i18n } = useI18n()
  const { isOpen, handleModalClose } = useResumeDistributionNotEnoughCreditsModal()

  const { getAutoApplyCredit } = useAutoApply()

  const { creditCountMax } = getAutoApplyCredit()

  const rdMaxCreditCount = creditCountMax || DEFAULT_MAX_CREDITS
  if (!isOpen) return null

  return (
    <Overlay>
      <RootModalContainer>
        <ModalSection>
          <CloseButtonContainer>
            <Close onClick={handleModalClose}>
              <CloseIcon />
            </Close>
          </CloseButtonContainer>
          <HeaderImage />
          <NotEnoughCreditsTextContainer>
            <NotEnoughCreditsHeaderContainer>
              <NotEnoughCreditsTitle>
                {i18n.t('builder.resume_distribution.not_enough_credits_modal.title')}
              </NotEnoughCreditsTitle>
              <NotEnoughCreditsDescription>
                {i18n.t('builder.resume_distribution.not_enough_credits_modal.description')}
              </NotEnoughCreditsDescription>
            </NotEnoughCreditsHeaderContainer>
            <NotEnoughCreditsFeaturesContainer>
              <NotEnoughCreditsFeatureTitle>
                {i18n.t('builder.resume_distribution.not_enough_credits_modal.features_title')}
              </NotEnoughCreditsFeatureTitle>
              <NotEnoughCreditsFeatures>
                <NotEnoughCreditsFeature>
                  {i18n.t(
                    'builder.resume_distribution.not_enough_credits_modal.features.job_applications',
                    { count: rdMaxCreditCount },
                  )}
                </NotEnoughCreditsFeature>
                <NotEnoughCreditsFeature>
                  {i18n.t(
                    'builder.resume_distribution.not_enough_credits_modal.features.team_of_experts',
                  )}
                </NotEnoughCreditsFeature>
                <NotEnoughCreditsFeature>
                  {i18n.t(
                    'builder.resume_distribution.not_enough_credits_modal.features.job_application_screenshots',
                  )}
                </NotEnoughCreditsFeature>
                <NotEnoughCreditsFeature>
                  {i18n.t(
                    'builder.resume_distribution.not_enough_credits_modal.features.weekly_jobs_shortlist',
                  )}
                </NotEnoughCreditsFeature>
              </NotEnoughCreditsFeatures>
            </NotEnoughCreditsFeaturesContainer>
          </NotEnoughCreditsTextContainer>
        </ModalSection>
      </RootModalContainer>
    </Overlay>
  )
}
