import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions as careerActions, selectors as careerSelectors } from 'builder/modules/careerPath'

import { FetchStatuses } from 'builder/modules/constants'
import { selectors as panelSelector } from 'builder/modules/panel'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as userSelectors } from 'builder/modules/user'
import { useConfig } from 'builder/hooks/useConfig'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useServicesData } from 'builder/components/CIO-Dasboard-2.0/hooks/useServiceData'
import UpcomingCareersModal from 'builder/components/UpcomingCareersModal'
import * as StyledPanel from '../styles'
import { CareerGoalHeader } from './components/CareerGoalHeader'
import { CareerGoalSlider } from './components/CareerGoalSlider'
import { CareerGoalSliderPlaceholder } from './components/CareerGoalSliderPlaceholder'
import { PoweredByBanner } from './components/PoweredByBanner'
import { CareerGoalGrid } from './components/CareerGoalGrid'
import { useCareerServiceTitle } from './hooks/useCareerServiceTitle'

export const CareerGoal = () => {
  const dispatch = useDispatch()

  const careerGoalStatus = useTypedSelector(careerSelectors.goalStatus)
  const activeTabId = useTypedSelector(panelSelector.activeTabId)

  const user = useSelector(userSelectors.userData)
  const config = useConfig()
  const isSuperApp = config?.features.superApp
  const isRioDomain = config?.features.international
  const isFreePlan = !user?.hasPremiumFeatures && !isSuperApp && isRioDomain
  const { isPhone, isDesktopWithDash, isDesktop } = useMediaQueries()
  const tab = useCareerServiceTitle({ index: activeTabId })
  const isDesktopScreenResolution = isSuperApp || isRioDomain ? isDesktopWithDash : isDesktop

  // Fetch career goal
  useEffect(() => {
    if (careerGoalStatus === FetchStatuses.notAsked) dispatch(careerActions.fetchGoal())
  }, [dispatch, careerGoalStatus])

  const { isLoaded, services, onServiceCardClick, isComingSoonModal, comingSoonService } =
    useServicesData(activeTabId)

  const renderCards = () => {
    if (!isLoaded) {
      return <CareerGoalSliderPlaceholder />
    }

    if (isDesktopScreenResolution) {
      return (
        <CareerGoalGrid
          services={services}
          onServiceCardClick={onServiceCardClick}
          tabTitle={tab?.name}
          isSuperApp={isSuperApp}
        />
      )
    }
    return (
      <CareerGoalSlider
        showShadow={isSuperApp || isRioDomain}
        showNavigationButton={isSuperApp || isRioDomain}
        services={services}
        onServiceCardClick={onServiceCardClick}
      />
    )
  }

  return (
    <StyledPanel.Section marginTop={activeTabId === 0 ? 56 : 0}>
      {isComingSoonModal && (
        <UpcomingCareersModal
          visual={comingSoonService?.modalVisual || ''}
          trackingLabel="execute_a_job_search"
          title={comingSoonService?.modalTitle || ''}
          text={comingSoonService?.modalDescription || ''}
          onClose={onServiceCardClick}
        />
      )}
      <CareerGoalHeader isFreeUser={isFreePlan} title={tab?.text} />
      {renderCards()}
      {isPhone && !isSuperApp && <PoweredByBanner />}
    </StyledPanel.Section>
  )
}
