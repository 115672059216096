import { useMutation, useQueryClient } from '@tanstack/react-query'
import { baseClient } from 'builder/modules/apiClient'
import { CustomAlertType } from 'builder/modules/jobSearch/types'

export const useMutationCustomAlert = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async ({
      id,
      targetRole,
      location,
      locationId,
      onlyRemoteJobs,
      frequency,
    }: CustomAlertType): Promise<void> => {
      const res = await baseClient.put(`/job-alerts/v1/custom-subscriptions/${id}`, {
        location,
        target_role: targetRole,
        location_id: locationId,
        only_remote_jobs: onlyRemoteJobs,
        frequency,
      })
      return res.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['useGetCustomAlerts'])
      },
    },
  )
}
