import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: ${Colors.Neutral50};

  ${Media.Phone`
    width: 100%;
  `}
`

export const Bar = styled.div`
  width: 377px;
  height: 16px;
  background-color: ${Colors.Blue20};
  display: grid;
  place-items: center;

  ${Media.Phone`
    width: 100%;
  `}
`

export const Handler = styled.div`
  width: 8px;
  height: 100%;
  background-color: ${Colors.Blue50};
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`
