import styled from 'styled-components'
import Icon from 'builder/components/Icon'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

export const ModalContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 650px;
  height: 400px;
  border-radius: 6px;
  background-color: ${Colors.White};
  user-select: none;
`

export const ModalClose = styled.div`
  position: relative;
  z-index: 5;
  top: -220px;
  right: -6px;
  padding: 6px;
  display: flex;
  border-radius: 50%;
  height: fit-content;
  color: ${Colors.White};
  background-color: ${Colors.Neutral80};
  transition: opacity 0.15s;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

export const ModalIframe = styled.iframe`
  height: 365px;
  width: 615px;
  border-radius: 2px;

  ${Media.Phone`
    height: 95%;
    width: 95%;  
  `}
`

export const ModalCloseIcon = styled(Icon.Close)``
