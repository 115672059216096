import styled, { css } from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Sizes from 'builder/styles/sizes'

export const Container = styled.div`
  margin: 20px 0px;
  background-color: ${Colors.White};

  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid ${hexToRgba(Colors.Neutral50, 0.2)};
  border-radius: 16px;
  padding: 48px;

  ${Media.Phone`
     flex-direction: column;
     gap: 5px;
  `}
`

export const Heading = styled.h2`
  ${FontWeights.DemiBold}
  ${Typography.L}
  text-align: center;
`

export const Image = styled.img`
  width: fit-content;
  max-height: 90px;
`

export const EmptyResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.White};
  border-radius: ${Sizes.S};
  flex: 1.25;
  text-align: center;
`

export const Title = styled.span`
  ${FontWeights.DemiBold};
  ${Typography.S};

  max-width: 398px;
  width: 100%;
  color: ${Colors.Neutral90};
`

export const Subtitle = styled.span<{ withMaxWidth?: boolean }>`
  color: ${Colors.Neutral60};
  ${Typography.Body};

  ${({ withMaxWidth }) =>
    withMaxWidth &&
    css`
      max-width: 544px;
    `}
`

export const NoResultsImage = styled.img`
  width: 112px;
  height: 105px;
  margin-bottom: ${Sizes.M};
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: ${Sizes.XS};
  margin-top: ${Sizes.M};
  align-items: center;

  button {
    width: fit-content;
    ${Media.Phone`
      width: 100%;
    `}
  }

  ${Media.Phone`
    flex-direction: column;
    width: 100%;
  `}
`

export const NoSearchResultsImage = styled.img`
  width: 60px;
  height: 80px;
  margin-bottom: ${Sizes.L};

  ${Media.Phone`
    width: 48px;
    height: 64px;
  `}
`

export const NoResultSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 0.75;
`

export const NoResultSearchTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Sizes['2XS']};
  align-items: center;
`

export const EmptySearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 260px);

  ${Media.Phone`
    height: 100%;
    margin-top: 55px;
  `}
`

export const ChromeExtensionBanner = styled.div`
  display: flex;
  flex: 0.75;
  justify-content: center;
  align-items: flex-end;
`

export const EmptyRecommendationContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 330px);

  ${Media.Tablet`
    height: 100%;
    margin-bottom: 36px;
  `}
`
