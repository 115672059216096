import styled from 'styled-components'
import Media from 'builder/styles/media'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
  width: 288px;
  flex-shrink: 0;

  ${Media.Tablet`
    flex-direction: row;
    margin: 0 0 40px 0;
    width: 100%;
  `}

  ${Media.Phone`
    flex-direction: column;
    margin-bottom: 32px;
  `}
`
