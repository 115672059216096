import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Icon24, { Icon20 } from 'builder/components/Icon'
import Typography, { FontWeights } from 'builder/styles/typography'
import { getScoreBasedColor } from 'builder/modules/careerPath2/utils'

export const AddIcon = styled(Icon24.Add)`
  opacity: 0;
  color: ${Colors.Neutral50};
`

export const Container = styled.div<{
  content?: string
  cardIndex?: number
  clickCount?: number
  titleSelected?: boolean
}>`
  width: 328px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: ${props => (props.content ? 'flex-start' : 'center')};
  position: relative;
  border-radius: 12px;
  background-color: ${props => props.titleSelected && Colors.Blue10};

  &:hover {
    padding-left: ${props => props.content && '12px'};
    width: ${props => props.content && '320px'};
    cursor: ${props => (props.content ? 'pointer' : 'default')};
    background-color: ${props => props.content && Colors.Blue10};
  }

  &:hover ${AddIcon} {
    opacity: 1;
  }

  ${props => Media.Tablet`
    border-bottom: 1px solid ${Colors.Neutral15};
    border-radius: ${props.cardIndex === 1 ? '12px 12px 0 0' : 0};

    &:hover {
      width: 328px;
      background-color: ${props.clickCount && props.clickCount > 0 ? Colors.Blue10 : Colors.White};
    }
  `};
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

export const Text = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
`

export const Title = styled.div`
  ${Typography.Subhead};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const MoreIcon = styled(Icon24.More)`
  align-self: center;
`

export const Tick = styled(Icon24.Tick)`
  color: ${Colors.Blue50};
`

export const TitleValue = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const SubTitleContainer = styled.div`
  margin-top: 8px;
  gap: 8px;
  display: flex;
  align-items: center;
`

export const TimeIcon = styled.img`
  width: 20px;
  height: 20px;
`

export const AverageTimeSpan = styled.div<{ IsYearOrMonth: boolean }>`
  ${Typography.Body};
  color: ${Colors.Neutral90};
`

export const HalfStatus = styled(Icon20.StatusHalf)<{ score?: number }>`
  width: 20px;
  height: 20px;
  transform: rotate(-90deg);
  color: ${props => getScoreBasedColor('color', props.score)};
`

export const SkillScore = styled.div<{ score?: number }>`
  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${props => getScoreBasedColor('color', props.score)};
`
