import styled from 'styled-components'
import Button, { ButtonSize, ButtonTheme } from 'builder/components/Button'
import Spinner from 'builder/components/Spinner'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import { FontWeights } from 'builder/styles/typography'

export const ButtonWrapper = styled.div`
  min-width: 90px;
  display: flex;
  align-items: center;
`

export const ActionButton = styled(Button).attrs({
  size: ButtonSize.small,
  theme: ButtonTheme.ghost,
})`
  ${FontWeights.Medium};
  ${Media.Phone`
      width: 100%;
    `}

  span svg {
    margin-right: 5px;
  }

  &:hover {
    &svg {
      color: ${Colors.Blue50};
    }
  }
`

export const LoadingButton = styled(Spinner)`
  width: 23px;
  height: 23px;
`
