import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: center;
`

export const Sessions = styled.div`
  ${FontWeights.DemiBold};
  ${Typography.S};
`

export const Price = styled.div`
  ${FontWeights.Medium};
  ${Typography.Subhead};
  color: ${Colors.Neutral50};
`

export const PerSession = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
`
