import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { Typography, FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  margin: 0 auto;
  width: calc(100% - 40px);
`

export const Header = styled.div`
  margin-bottom: 24px;
  text-align: center;
`

export const Title = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};
  margin-bottom: 12px;
`

export const Subtitle = styled.div`
  color: ${Colors.Neutral50};
`

export const Content = styled.div`
  width: 100%;
  max-width: 416px;
  margin: 0 auto;
`
