import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useQueryParam } from 'builder/hooks/useQueryParam'
import { actions } from 'builder/modules/resumeEditor'
import { useI18n } from 'builder/hooks/useI18n'
import { Container, Content, StyledSpinner, Title, Description } from './styles'

const translationKey = 'builder.auto_tailoring.continue_auto_tailoring_page'

/**
 * Proxy url component to tailor resume from url params and
 * redirect to editor page (for example after payment)
 */
export const ResumeAutoTailoringView = () => {
  const dispatch = useDispatch()
  const [dataStr] = useQueryParam('data')
  const { id } = useParams()
  const { i18n } = useI18n()

  useEffect(() => {
    if (!dataStr || !id) return

    const { selectedKeywords, jobPostingId } = JSON.parse(dataStr)
    const resumeId = +id

    dispatch(
      actions.autoTailorAfterPayment({
        resumeId,
        selectedKeywords,
        jobPostingId,
      }),
    )
  }, [dataStr, dispatch, id])

  return (
    <Container>
      <Content>
        <StyledSpinner />
        <Title>{i18n.t(`${translationKey}.title`)}</Title>
        <Description>{i18n.t(`${translationKey}.description`)}</Description>
      </Content>
    </Container>
  )
}
