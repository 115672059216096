import { useState } from 'react'
import { Column, ComponentPreview, Group, Label } from 'builder/components/UiKit/styles'
import Toggle from 'builder/components/Toggle'
import { CustomLabel } from './styles'
import { toggleState } from './constants'

export const ToggleSection = () => {
  const [state, setState] = useState(toggleState)

  const onClickHandler = (index: number) => {
    const newState = [...state]
    newState[index].checked = !newState[index].checked
    setState(newState)
  }

  return (
    <Group>
      <Label>Toggle</Label>
      <ComponentPreview>
        <Column>
          {state.map((checkbox, index) => (
            <Toggle onChange={() => onClickHandler(index)} key={checkbox.label} {...checkbox} />
          ))}
          <Toggle
            label={
              <CustomLabel>
                <b>Custom label</b>
                <span>Toggle without onChange</span>
              </CustomLabel>
            }
            checked
          />
        </Column>
      </ComponentPreview>
    </Group>
  )
}
