import { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { trackInternalEvent, trackMarketingEvent } from '@rio/tracking'
import mapObject from 'lodash/map'
import startCase from 'lodash/startCase'
import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { actions as uiActions } from 'builder/modules/ui'
import ModalOverlay from 'builder/components/ModalOverlay'
import { Tab } from 'builder/components/Tabs'
import { Icon24 } from 'builder/components/Icon'
import { TABS } from './constants'
import { useRouteInterceptor, useFeatureActivator } from './hooks'
import Sharing from './Sharing'
import Stats from './Stats'

import { Container, Header, Tabs, TabLabel, DesktopClose, MobileClose } from './styles'
import { useConfig } from 'builder/hooks/useConfig'

const ShareModal = () => {
  const [activeTab, setActiveTab] = useState(TABS.share)
  const { isPhone } = useMediaQueries()
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const config = useConfig()
  const resumeId = useSelector(state => state.ui.sharingModalResumeId)
  const trDocId = useSelector(state => state.ui.sharingModalTrDocId)
  const handleClose = useCallback(() => dispatch(uiActions.closeSharingModal()), [dispatch])
  const hideShareLinkStatistics =
    config?.features.isWidget && config?.features.widgetFeatures.shareLinkStatistic !== true

  // Open the modal automatically if user visits `/resumes/:id/statistics` URL
  useRouteInterceptor({ isOpen: !!resumeId, changeTab: setActiveTab, isTrDoc: !!trDocId })

  // Enables the feature for chosen resume
  const url = useFeatureActivator(resumeId, trDocId)

  if (!url || (!resumeId && !trDocId)) return null

  const changeTab = name => {
    setActiveTab(name)
    trackMarketingEvent('Sharing Modal', `Open ${startCase(name)} Tab`)
    trackInternalEvent(`open_sharing_modal_${name}_tab`)
  }

  const Close = isPhone ? MobileClose : DesktopClose
  const CloseIcon = isPhone ? Icon24.Close : Icon24.CloseLarge
  const tabs = Object.values(TABS).filter(t => t !== TABS.stats || !hideShareLinkStatistics)

  return (
    <ModalOverlay
      onClick={handleClose}
      overlayFadeDuration={isPhone ? 0 : 150}
      contentSlideDuration={isPhone ? 0 : 150}
      fullScreen={isPhone}
    >
      <Container activeTab={activeTab}>
        <Header>
          <Tabs>
            {mapObject(tabs, tabName => (
              <Tab
                key={tabName}
                isActive={tabName === activeTab}
                onClick={() => changeTab(tabName)}
              >
                {i18n.t(`builder.resume_editor.share_modal.tabs.${tabName}`)}
                {tabName === TABS.stats && <TabLabel>New</TabLabel>}
              </Tab>
            ))}
          </Tabs>
          <Close onClick={handleClose}>
            <CloseIcon />
          </Close>
        </Header>
        {activeTab === TABS.share && <Sharing url={url} />}
        {activeTab === TABS.stats && <Stats resumeId={resumeId} />}
      </Container>
    </ModalOverlay>
  )
}

export default ShareModal
