/* eslint-disable prettier/prettier */
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useI18n } from 'builder/hooks/useI18n'
import * as Styled from './styles'

const Loader = require('./assets/Loader.gif')
const TickMark = require('./assets/tick.png')
const CardToolTipImage = require('./assets/Reverse_Union.png')
const MobileCardToolTipImage = require('./assets/Mobile_Reverse_Union.png')

export const DocumentLoaderTooltip = () => {
  const { isPhone } = useMediaQueries()
  const { i18n } = useI18n()

  const text = [
    { data: i18n.t('builder.panel.card_loader_tooltip.text_line1') },
    {
      data: i18n.t('builder.panel.card_loader_tooltip.text_line2'),
    },
  ]

  return (
    <Styled.DocumentLoaderContainer>
      <Styled.ToolTip src={isPhone ? MobileCardToolTipImage : CardToolTipImage} />
      <Styled.DocumentImageTitle>
        {i18n.t('builder.panel.card_loader_tooltip.title')}
      </Styled.DocumentImageTitle>
      <Styled.DocumentIconLoading src={Loader} />
      <Styled.TextContainer>
        {text.map((key, index) => (
          <Styled.TextSubContainer key={index}>
            <Styled.TickMark src={TickMark} />
            <Styled.Text>{key.data}</Styled.Text>
          </Styled.TextSubContainer>
        ))}
      </Styled.TextContainer>
    </Styled.DocumentLoaderContainer>
  )
}
