import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { Content, LeftContent, RightContent, BannerWrapper } from './styles'

import JobPostings from './components/JobPostings'
import AboutJobTitle from './components/AboutJobTitle'
import CompaniesList from './components/CompaniesList'
import RequiredSkills from './components/RequiredSkills'
import SalaryRange from './components/SalaryRange'
import JobSearchBanner from './components/Banners/JobSearch'
import CoachingBanner from './components/Banners/Coaching'
import Responsibility from './components/Responsibility'
import FrequentQuestions from './components/FrequentQuestions'
import RecommendedJobs from './components/RecommendedJobs'
import SalaryAnalyzerBanner from './components/Banners/SalaryAnalyzer'
import WorkExperience from './components/WorkExperience'
import EducationLevel from './components/EducationLevel'
import TopPayingRegions from './components/TopPayingRegions'
import { RecommendedCourses } from './components/RecommendedCourses'

interface Props {
  tabs: string[]
  activeTab: string
}

const JobDetails = ({ tabs, activeTab }: Props) => {
  const { isTablet } = useMediaQueries()
  const { features } = useFeaturesConfig()
  const isOverviewTab = activeTab === tabs[0]
  const isSalaryTab = !isOverviewTab

  return (
    <>
      <Content>
        <LeftContent>
          {isOverviewTab && <AboutJobTitle />}
          {isOverviewTab && <Responsibility />}
          <SalaryRange />
          {isSalaryTab && <WorkExperience />}
          {isSalaryTab && <EducationLevel />}
          {isSalaryTab && <TopPayingRegions />}
          {isTablet && isOverviewTab && <JobPostings />}
          {isTablet && isOverviewTab && <CompaniesList />}
          {isOverviewTab && <RequiredSkills />}
          {isTablet && isOverviewTab ? (
            features.careerCoaching && <CoachingBanner />
          ) : (
            <SalaryAnalyzerBanner />
          )}
        </LeftContent>
        {!isTablet && (
          <RightContent>
            <JobPostings />
            <CompaniesList />
            <BannerWrapper>
              {isOverviewTab ? (
                features.careerCoaching && <CoachingBanner />
              ) : (
                <SalaryAnalyzerBanner />
              )}
            </BannerWrapper>
          </RightContent>
        )}
      </Content>
      {isOverviewTab && <RecommendedJobs />}
      {isOverviewTab && <RecommendedCourses />}
      <FrequentQuestions />
      <JobSearchBanner />
    </>
  )
}

export default JobDetails
