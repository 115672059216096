import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'

export const VisualContainer = styled.div`
  position: absolute;
  width: 268px;
  height: 214px;
  left: 0px;
  top: 0px;

  ${Media.Phone`
    width: 242px;
    height: 212px;
  `}
`

export const VisualImageContainer = styled.div`
  position: absolute;
  left: 72px;
  top: 68px;
  width: 128px;
  height: 134px;
  z-index: 1;
  border-radius: 50%;

  ${Media.Phone`
    width: 130px;
    height: 130px;
    left: 54px;
    top: 58px;
  `}
`

export const PlayImage = styled.img`
  position: absolute;
  left: 65px;
  top: 158px;
  z-index: 1;

  ${Media.Phone`
    left: 54px;
    top: 148px;
  `}
`

export const VisualImage = styled.img`
  width: 100%;
  border: 2.5px solid ${Colors.White};
  height: 100%;
  border-radius: 50%;
`
