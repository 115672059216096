import { Background } from './Background'
import { Process } from './Process'
import { Failed } from './Failed'
import * as Styles from './styles'

type Props = {
  isFailed: boolean
}

export const Loader = ({ isFailed }: Props) => {
  return (
    // This additional wrapper is necessary to prevent horizontal scrollbar appearance
    // during animation
    <Styles.Container>
      <Styles.BackgroundContainer timeout={0}>
        <Styles.MatrixContainer>
          <Background />
        </Styles.MatrixContainer>
        {isFailed ? <Failed /> : <Process />}
      </Styles.BackgroundContainer>
    </Styles.Container>
  )
}
