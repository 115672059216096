import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Icon from 'builder/components/Icon'
import Button from 'builder/components/Button'

export const Container = styled.div`
  background: ${Colors.White};
  padding: 32px;
  border-radius: 16px;
  ${Media.Phone`
    padding: 20px;
    border-radius: 12px;
  `}
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Title = styled.div`
  ${Typography.S}
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral90};
  width: 436px;
  ${Media.Phone`
    width: 264px;
  `}
`

export const CloseIcon = styled(Icon.AddSmall)`
  fill: ${Colors.Neutral70};
  background: ${Colors.Neutral10};
  border-radius: 50%;
  position: relative;
  float: right;
  height: 28px;
  width: 28px;
  cursor: pointer;
  transform: rotate(225deg);
  &:hover {
    background: ${Colors.Blue50};
    fill: ${Colors.White};
  }
  ${Media.Phone`
    height: 24px;
    width: 24px;
    z-index: 2;
  `}
`

export const Content = styled.p`
  margin-top: 12px;
  ${Typography.Body}
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
  width: 480px;
  ${Media.Phone`
    width: 303px; 
  `}
`

export const ButtonWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
  ${Media.Phone`
    margin-top: 10px;
  `}
`

export const Cancel = styled(Button)`
  margin-right: 8px;
  ${Typography.Body}
  ${FontWeights.Medium};
  ${Media.Phone`
    ${Typography.Caption}
    padding: 8px 12px;
    height: 34px;
    margin-top: 10px;
  `}
`
