import { UseFormReturn } from 'react-hook-form'
import paypalSupportedCurrencies from 'builderStatic/js/components/config/paypalSupportedCurrency'
import { SuggestionSelectedEventData } from 'react-autosuggest'
import * as SimpleForm from 'builder/components/SimpleForm'
import { TextField } from 'builder/components/TextField'
import { useI18n } from 'builder/hooks/useI18n'
import { AutoApplyForm, SalaryPlan as SalaryPlanType } from 'builder/modules/autoApply/types'
import { AsyncAutosuggest } from 'builder/components/AsyncAutosuggest'
import { useJobTitles } from 'builder/hooks/useJobTitles'
import { CurrencySelect, Salary, SalaryPlanSelect, SelectInfoText, SmallTitle } from './styles'

type JobSuggestionType = {
  text: string
  id: number
}

const TRANSLATION = `builder.auto_apply.form.job_preferences_1`

const numberCorrector = (str: string) => {
  return str
    .replace(/[^0-9.]/g, '')
    .replace(/(\..*?)\..*/g, '$1')
    .replace(/^0[^.]/, '0')
}

type Props = {
  form: UseFormReturn<Pick<AutoApplyForm, 'targetRole' | 'jobPreferenceAttributes'>>
}

export const JobPreferencesForm1 = (props: Props) => {
  const { form } = props

  const {
    watch,
    register,
    setValue,
    formState: { errors },
  } = form
  const { mutateAsync: getJobTitle } = useJobTitles()
  const { i18n } = useI18n()

  const required = i18n.t(`${TRANSLATION}.field_is_required`)
  const currencies = paypalSupportedCurrencies.map(c => ({ id: c.id, name: c.id }))

  const SalaryPlan = [
    {
      id: 'annually',
      name: i18n.t(`${TRANSLATION}.salary_plan.annually`),
    },
    {
      id: 'monthly',
      name: i18n.t(`${TRANSLATION}.salary_plan.monthly`),
    },
  ]

  const onSuggestionSelected = (
    _: React.FormEvent<unknown>,
    { suggestion }: SuggestionSelectedEventData<JobSuggestionType>,
  ) => {
    setValue('targetRole', suggestion.text)
  }

  return (
    <>
      <SimpleForm.Row position="relative">
        <AsyncAutosuggest<JobSuggestionType>
          highlightedQuery
          value={watch('targetRole')}
          onChange={e => setValue('targetRole', e.target.value)}
          onSuggestionSelected={onSuggestionSelected}
          fetchItems={async (query: string) => {
            const res = await getJobTitle(query)

            return res.map(title => ({
              text: title.standardTitle,
              id: title.id,
            }))
          }}
        >
          {inputProps => (
            <TextField
              {...register('targetRole', { required })}
              {...inputProps}
              error={errors.targetRole?.message}
              name="targetRole"
              label={i18n.t(`${TRANSLATION}.target_role.label`)}
              placeholder={i18n.t(`${TRANSLATION}.target_role.placeholder`)}
            />
          )}
        </AsyncAutosuggest>
        <SelectInfoText>{i18n.t(`${TRANSLATION}.target_role.info`)}</SelectInfoText>
      </SimpleForm.Row>
      <SimpleForm.Row position="relative">
        <SmallTitle>{i18n.t(`${TRANSLATION}.current_salary.label`)}</SmallTitle>
        <Salary>
          <CurrencySelect
            {...register('jobPreferenceAttributes.currentCurrency', { required })}
            error={errors.jobPreferenceAttributes?.currentCurrency?.message}
            selected={watch('jobPreferenceAttributes.currentCurrency')}
            onSelect={val =>
              setValue('jobPreferenceAttributes.currentCurrency', val?.toString() || '')
            }
            options={currencies}
          />
          <TextField
            {...register('jobPreferenceAttributes.currentSalary', { required })}
            type="number"
            min="0"
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (+e.target.value < 0) {
                e.target.value = numberCorrector(e.target.value)
              }
            }}
            error={errors.jobPreferenceAttributes?.currentSalary?.message}
          />
          <SalaryPlanSelect
            {...register('jobPreferenceAttributes.currentSalaryPlan', { required })}
            error={errors.jobPreferenceAttributes?.currentSalaryPlan?.message}
            selected={watch('jobPreferenceAttributes.currentSalaryPlan')}
            onSelect={val =>
              setValue(
                'jobPreferenceAttributes.currentSalaryPlan',
                (val?.toString() as SalaryPlanType) || 'annually',
              )
            }
            options={SalaryPlan}
          />
        </Salary>
      </SimpleForm.Row>
      <SimpleForm.Row position="relative">
        <SmallTitle>{i18n.t(`${TRANSLATION}.expected_salary.label`)}</SmallTitle>
        <Salary>
          <CurrencySelect
            {...register('jobPreferenceAttributes.expectedCurrency', { required })}
            error={errors.jobPreferenceAttributes?.expectedCurrency?.message}
            selected={watch('jobPreferenceAttributes.expectedCurrency')}
            onSelect={val =>
              setValue('jobPreferenceAttributes.expectedCurrency', val?.toString() || '')
            }
            options={currencies}
          />
          <TextField
            {...register('jobPreferenceAttributes.expectedSalary', { required })}
            type="number"
            min="0"
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (+e.target.value < 0) {
                e.target.value = numberCorrector(e.target.value)
              }
            }}
            error={errors.jobPreferenceAttributes?.expectedSalary?.message}
          />
          <SalaryPlanSelect
            {...register('jobPreferenceAttributes.expectedSalaryPlan', { required })}
            error={errors.jobPreferenceAttributes?.expectedSalaryPlan?.message}
            selected={watch('jobPreferenceAttributes.expectedSalaryPlan')}
            onSelect={val =>
              setValue(
                'jobPreferenceAttributes.expectedSalaryPlan',
                (val?.toString() as SalaryPlanType) || 'annually',
              )
            }
            options={SalaryPlan}
          />
        </Salary>
      </SimpleForm.Row>
    </>
  )
}
