import { ReactElement, useState } from 'react'

import {
  CollapsableItems,
  CollapseButtonWrapper,
  CollapseItem,
  CollapseHeader,
  CollapseWrapper,
  CollapseIconUp,
  CollapseIconDown,
} from './styles'

interface ListItemCollapseProps {
  padding?: string
  isExpanded?: boolean
  header?: ReactElement
  children: ReactElement
}

export const ListItemCollapse = ({
  isExpanded = false,
  padding,
  header,
  children,
}: ListItemCollapseProps) => {
  const [expand, setExpand] = useState<boolean>(isExpanded)

  return (
    <CollapseWrapper>
      <CollapseItem data-testid="list-item-collapse" padding={padding}>
        <CollapseHeader>
          {header}
          <CollapseButtonWrapper
            data-testid="list-item-collapse-button"
            onClick={() => setExpand(!expand)}
          >
            {expand ? <CollapseIconUp /> : <CollapseIconDown />}
          </CollapseButtonWrapper>
        </CollapseHeader>
      </CollapseItem>
      {expand && (
        <CollapsableItems data-testid="list-item-collapsible-items">{children}</CollapsableItems>
      )}
    </CollapseWrapper>
  )
}
