import Logo from 'builder/components/Logo'
import { WizardStepper } from '../WizardStepper'
import { WizardProgress } from '../WizardProgress'
import { WizardHeaderContainer } from './styles'

export const WizardHeader = () => {
  return (
    <WizardHeaderContainer>
      <WizardProgress />
      <Logo />
      <WizardStepper />
    </WizardHeaderContainer>
  )
}
