import { apiClient } from 'builder/modules/apiClient'
import { all, put, call, takeLatest } from 'redux-saga/effects'
import { trackInternalEvent, trackMarketingEvent } from '@rio/tracking'
import { authRedirectService } from 'builder/services/AuthRedirectService'
import { actions } from './signInModule'
import { extractErrorMessage } from './utils'

function* checkEmailSaga(action) {
  try {
    yield put(actions.setEmailCheckingLoading(true))
    yield put(actions.setSignInMethod(null))
    yield put(actions.setEmailCheckingError(null))

    const params = { email: action.payload }
    const { data } = yield call(apiClient.post, '/login/method', params)

    yield put(actions.setSignInMethod(data.method))
  } catch (error) {
    yield put(actions.setEmailCheckingError(extractErrorMessage(error)))
  } finally {
    yield put(actions.setEmailCheckingLoading(false))
  }
}

function* sendMagicLinkSaga(action) {
  try {
    const chromeExtension = action.payload.chrome_extension
    const params = {
      ...(chromeExtension && { chrome_extension: chromeExtension }),
      email: action.payload.email,
    }
    yield call(apiClient.post, '/login/request-link', params)
  } catch (error) {
    yield put(actions.setMagicLinkError(extractErrorMessage(error)))
  }
}

function* sendPasswordLinkSaga(action) {
  try {
    const params = { email: action.payload }
    yield call(apiClient.post, '/login/request-create-password', params)
  } catch (error) {
    yield put(actions.setPasswordLinkError(extractErrorMessage(error)))
  }
}

function* signInSaga(action) {
  try {
    yield put(actions.setSignInFetching(true))
    yield put(actions.setSignInError(null))

    const { data } = yield call(apiClient.post, '/login/auth', action.payload)

    trackMarketingEvent('Sign In', 'Login User', {
      eventLabel: 'By Password',
    })

    // backend tells us that the host change is required
    if (data.redirectTo) {
      authRedirectService.enterApp(data.redirectTo)
    } else {
      authRedirectService.enterApp()
    }
  } catch (error) {
    yield put(actions.setSignInError(extractErrorMessage(error)))
  } finally {
    yield put(actions.setSignInFetching(false))
  }
}

function* checkMagicLinkSaga(action) {
  try {
    yield put(actions.setMagicLinkEmail(null))
    yield put(actions.setMagicLinkCheckError(null))

    const { data } = yield call(apiClient.post, '/login/magic-auth', {
      token: action.payload,
    })

    trackMarketingEvent('Sign In', 'Login User', {
      eventLabel: 'By Magic Link',
    })

    if (data.redirectTo) {
      authRedirectService.enterApp(data.redirectTo)
    } else {
      authRedirectService.enterApp()
    }
  } catch (error) {
    yield put(actions.setMagicLinkEmail(error.response?.data?.email ?? null))
    yield put(actions.setMagicLinkCheckError(extractErrorMessage(error)))
  }
}

function* savePasswordSaga(action) {
  try {
    yield put(actions.setPasswordSaving(true))
    yield put(actions.setPasswordSaved(false))
    yield put(actions.setPasswordSavingError(null))

    yield call(apiClient.post, '/login/change-password', action.payload)
    trackInternalEvent('change_password')

    yield put(actions.setPasswordSaved(true))
  } catch (error) {
    yield put(actions.setPasswordSavingError(extractErrorMessage(error)))
  } finally {
    yield put(actions.setPasswordSaving(false))
  }
}

export const sagas = function* saga() {
  yield all([
    takeLatest(actions.checkEmailRequest, checkEmailSaga),
    takeLatest(actions.sendMagicLinkRequest, sendMagicLinkSaga),
    takeLatest(actions.sendPasswordLinkRequest, sendPasswordLinkSaga),
    takeLatest(actions.signInRequest, signInSaga),
    takeLatest(actions.savePasswordRequest, savePasswordSaga),
    takeLatest(actions.checkMagicLinkRequest, checkMagicLinkSaga),
  ])
}
