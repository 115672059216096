import { useI18n } from 'builder/hooks/useI18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as userSelectors } from 'builder/modules/user'

import { TRANSLATION } from '../constants'
import { Section } from '../styles'
import { Box, ActionsContainer, Text, ActionButton, LoadingButton, SpinnerButton } from './styles'

interface IProps {
  isLoading: boolean
  upgrade: () => void
}

export const ActionBanner = ({ upgrade, isLoading }: IProps) => {
  const { i18n } = useI18n()

  const userAccountData = useTypedSelector(userSelectors.userData)
  const hasPremiumPlan = userAccountData?.hasPremiumFeatures

  if (hasPremiumPlan) return <></>

  return (
    <Section>
      <ActionsContainer>
        <Box>
          <Text>{i18n.t(`${TRANSLATION}.upgrade_text`)}</Text>
        </Box>

        {isLoading ? (
          <LoadingButton>
            <SpinnerButton />
          </LoadingButton>
        ) : (
          <ActionButton onClick={upgrade}>{i18n.t(`${TRANSLATION}.upgrade_button`)}</ActionButton>
        )}
      </ActionsContainer>
    </Section>
  )
}
