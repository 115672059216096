import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useI18n } from 'builder/hooks/useI18n'

import StepsActions from '../StepsActions/StepsActions'
import Scanning from '../../assets/Scanning.webm'
import Builder from '../../assets/Builder.webm'
import StepItems from './StepItems/StepItems'
import {
  Container,
  Box,
  Section,
  Header,
  Description,
  Icon,
  Dot,
  StepSection,
  AnimationContainer,
  AnimationVideo,
  Instruction,
  Presentation,
  Actions,
} from './styles'
import SliderAnimation from './SliderAnimation/SliderAnimation'

const TRANSLATION = `builder.onboarding_ai_enhanced`

interface IProps {
  activeStep: number
  setStep: (prop: number) => void
}

const Steps = ({ activeStep, setStep }: IProps) => {
  const { isPhone, isTablet } = useMediaQueries()
  const { i18n } = useI18n()
  const animations: Record<string, string> = {
    step_1: Scanning,
    step_3: Builder,
  }

  return (
    <Container>
      <Box>
        <Instruction>
          <Presentation>
            <Section>
              <Icon name="Documents" /> {i18n.t(`${TRANSLATION}.header`)} <Dot />
              <StepSection>{i18n.t(`${TRANSLATION}.steps`, { number: activeStep })}</StepSection>
            </Section>
            <Header>{i18n.t(`${TRANSLATION}.step_${activeStep}.header`)}</Header>
            <Description>{i18n.t(`${TRANSLATION}.step_${activeStep}.description`)}</Description>
            <StepItems activeStep={activeStep} />
          </Presentation>
          {!isPhone && !isTablet && (
            <Actions>
              <StepsActions activeStep={activeStep} setStep={setStep} />
            </Actions>
          )}
        </Instruction>
      </Box>
      <Box>
        <AnimationContainer>
          {activeStep === 2 ? (
            <SliderAnimation />
          ) : (
            <AnimationVideo src={animations[`step_${activeStep}`]} />
          )}
        </AnimationContainer>
      </Box>
      {(isPhone || isTablet) && (
        <Box>
          <StepsActions activeStep={activeStep} setStep={setStep} />
        </Box>
      )}
    </Container>
  )
}

export default Steps
