import { memo, RefObject } from 'react'
import { DraftBlockType, EditorState } from 'draft-js'
import { Optional } from 'packages/types'
import { StyleTypes, SuggestionConfig } from '../types'
import { ToolbarContainer, ToolbarDiv, ToolbarLeft } from './styles'
import { useToolbarActions } from './useToolbarActions'
import { useToolbarRenders } from './useToolbarRenders'

export interface Props {
  editorState: EditorState
  onToggleInline: (inlineStyle: StyleTypes) => void
  onToggleBlock: (blockType: DraftBlockType) => void
  onChange: (editorStateProp: EditorState) => void
  focusEditor: () => void
  onToggleSpellChecker: () => void
  isSpellCheckerEnabled: boolean
  suggestionConfig: Optional<SuggestionConfig>
  locale: string
  isSpellCheckerVisible: boolean
  isSpellCheckerPending: boolean
  boldButtonDisable: boolean
  italicButtonDisable: boolean
  linkButtonDisable: boolean
  isAiPhrases: boolean
  isAiProfileSummary: boolean
  editorContainerRef: RefObject<HTMLElement>
}

const RichTextToolbar = (props: Props) => {
  const { boldButtonDisable, italicButtonDisable, linkButtonDisable, isSpellCheckerVisible } = props

  const actions = useToolbarActions(props)

  const {
    renderAction,
    renderBlockButton,
    renderLinkButton,
    renderMarkButton,
    renderSpellCheckButton,
  } = useToolbarRenders({ ...props, actions })

  return (
    <ToolbarContainer data-testid="rich-text-toolbar">
      <ToolbarLeft>
        {!boldButtonDisable && renderMarkButton(StyleTypes.Bold)}
        {!italicButtonDisable && renderMarkButton(StyleTypes.Italic)}
        {renderMarkButton(StyleTypes.Underline)}
        {renderMarkButton(StyleTypes.Strike)}
        <ToolbarDiv />
        {renderBlockButton(StyleTypes.OrderedList)}
        {renderBlockButton(StyleTypes.UnorderedList)}
        {!linkButtonDisable && <ToolbarDiv />}
        {!linkButtonDisable && renderLinkButton()}
        {isSpellCheckerVisible && <ToolbarDiv />}
        {isSpellCheckerVisible && renderSpellCheckButton()}
      </ToolbarLeft>
      {renderAction()}
    </ToolbarContainer>
  )
}

export default memo(RichTextToolbar)
