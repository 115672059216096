import Logo from 'builder/components/DocumentEditor/JobPosting/Images/CompanyLogo.svg'
import Button from 'builder/components/Button'
import { useI18n } from 'builder/hooks/useI18n'
import ModalOverlay from '../../ModalOverlay'
import useGenerateWithTailoredRole from '../hooks/useGenerateWithTailoredRole'
import { Props } from './types'
import {
  Container,
  HeaderContainer,
  Title,
  SubTitle,
  ActionContainer,
  FeaturedIcon,
  OptionContainer,
  Option,
  Radio,
  RadioControl,
  OptionTextContainer,
  OptionHeading,
  FeaturedIconButton,
  CloseIcon,
  CompanyLogo,
} from './styles'

const LOCALIZATION_PREFIX = 'builder.ai_profile_summary.generate_with_tailored_role_modal'

const GenerateWithTailoredRole: React.FC<Props> = (props: Props) => {
  const { i18n } = useI18n()
  const {
    tailoredDesignation,
    includeJobPosting,
    handleSelectOption,
    handleClose,
    handleGenerate,
  } = useGenerateWithTailoredRole({ ...props })

  return tailoredDesignation ? (
    <ModalOverlay overlayFadeDuration={300} contentSlideDuration={300}>
      <Container>
        <CloseIcon onClick={handleClose} />
        <HeaderContainer>
          <FeaturedIcon />
          <Title>{i18n.t(`${LOCALIZATION_PREFIX}.title`)}</Title>
          <SubTitle>{i18n.t(`${LOCALIZATION_PREFIX}.sub_title`)}</SubTitle>
        </HeaderContainer>
        {tailoredDesignation && (
          <OptionContainer>
            <Option checked={includeJobPosting} onClick={() => handleSelectOption(true)}>
              <Radio checked={includeJobPosting}>
                <RadioControl checked={includeJobPosting} />
              </Radio>
              <OptionTextContainer>
                <CompanyLogo size={20} src={Logo} />
                <OptionHeading>{tailoredDesignation}</OptionHeading>
              </OptionTextContainer>
            </Option>
            <Option checked={!includeJobPosting} onClick={() => handleSelectOption(false)}>
              <Radio checked={!includeJobPosting}>
                <RadioControl checked={!includeJobPosting} />
              </Radio>
              <OptionTextContainer>
                <OptionHeading>
                  {i18n.t(`${LOCALIZATION_PREFIX}.do_not_tailor_option_text`)}
                </OptionHeading>
              </OptionTextContainer>
            </Option>
          </OptionContainer>
        )}
        <ActionContainer>
          <Button theme="alternative" onClick={handleGenerate}>
            <FeaturedIconButton />
            {i18n.t(`${LOCALIZATION_PREFIX}.generate_button_text`)}
          </Button>
        </ActionContainer>
      </Container>
    </ModalOverlay>
  ) : (
    <></>
  )
}

export default GenerateWithTailoredRole
