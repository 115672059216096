import { useEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { Label, selectors, CareerCardType } from 'builder/modules/careerPath2'

import { Container, SubContainer, Title } from './styles'
import { AddNewPathCard } from './components/AddNewPathCard'
import { CompletedPathCard } from './components/CompletedPathCard'
import DeletePathModal from './components/CompletedPathCard/components/DeletePathsModal'

export const CareerPathViewCard = () => {
  const allCareerCards = useTypedSelector(selectors.allCareerCards)
  const isModalOpen = useTypedSelector(selectors.isDeletePathsModalOpen)

  useEffect(() => {
    trackInternalEvent('enter_my_pathways_page', Label)
  }, [])

  return (
    <>
      {isModalOpen && <DeletePathModal />}
      <Container timeout={0}>
        <Title>Your Career Paths</Title>
        <SubContainer>
          {allCareerCards?.map((value: CareerCardType) => (
            <CompletedPathCard titles={value.pathways?.path1} key={value.id} cardData={value} />
          ))}
          <AddNewPathCard />
        </SubContainer>
      </Container>
    </>
  )
}
