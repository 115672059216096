import Colors from 'builder/styles/colors'
import { BubbleCornerContainer } from './styles'
import { ChatMessageType } from './types'

export const BubbleCorner = ({ type }: { type: ChatMessageType }) => {
  const color = type === 'answer' ? Colors.Blue50 : Colors.Neutral5
  const side = type === 'answer' ? 'right' : 'left'
  return (
    <BubbleCornerContainer $side={side}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <g clipPath="url(#clip0_9110_1953)">
          <path
            d="M14 9.0001C14 18.9998 18 21.4998 20 23.7498C8 25.9998 2 12.9293 2 3.99981C3.2369 3.99979 3.44921 3.99993 6.00011 3.99979L14 3.99979C14.0014 4.08283 14.0026 4.14248 14.0037 4.18257C14.0092 1.97074 14.0177 4.69902 14.0037 4.18257C14.0017 4.99235 14 6.46431 14 9.0001Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_9110_1953">
            <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 1 24 0)" />
          </clipPath>
        </defs>
      </svg>
    </BubbleCornerContainer>
  )
}
