import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Sizes from 'builder/styles/sizes'
import Typography, { FontWeights } from 'builder/styles/typography'

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: ${Sizes.L};

  height: calc(100vh - 200px);
  overflow: hidden;
  display: flex;
`

export const Image = styled.img`
  padding-bottom: 24px;
  max-height: 350px;
  object-fit: contain;

  ${Media.Tablet`
    margin-top: ${Sizes.L} ;
    padding-top: ${Sizes.M} ;
  `}

  ${Media.Phone`
    max-height: 260px;
  `}
`

export const Title = styled.div`
  color: ${Colors.Neutral90};
  ${Typography.S};
  ${FontWeights.Medium};
  padding-bottom: 8px;
`
export const Subtitle = styled.div`
  color: ${Colors.Neutral60};
  ${Typography.Body};
  ${FontWeights.Regular};
  padding-bottom: 24px;

  ${Media.Tablet`
  
  padding-bottom: ${Sizes.S} ;
`}
`
