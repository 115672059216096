import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as userSelector } from 'builder/modules/user'
import { useCompanyInterviews } from 'builder/views/Interview/hooks/useCompanyInterviews'
import { selectors } from 'builder/modules/interview/interviewModule'
import { useUser } from 'builder/hooks/useUser'
import { AvailablePremiumBanner } from '../../AvailablePremiumBanner/AvailablePremiumBanner'
import { InitialRequest } from '../DashboardView'
import {
  CardsContainer,
  Container,
  HeaderContainer,
  RightText,
  Title,
  TitleContainer,
  CardSkeleton,
  MobileContainer,
  DesktopContainer,
} from './style'
import { Card } from './Card/Card'

export const TRANSLATION_KEY = 'builder.interview_prep_view.interview_dashboard'
interface Props {
  setInitialRequest: React.Dispatch<React.SetStateAction<InitialRequest | undefined>>
}

export const CompanyInterview = ({ setInitialRequest }: Props) => {
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const isPremiumUser = useTypedSelector(userSelector.premiumAccess)
  const jobTitleId = useSelector(selectors.jobTitleId)
  const user = useUser()
  const { data: interviews, isLoading } = useCompanyInterviews(
    jobTitleId,
    user?.id,
    setInitialRequest,
    true,
  )

  return (
    <Container>
      {!isPremiumUser && (
        <MobileContainer>
          <AvailablePremiumBanner />
        </MobileContainer>
      )}
      <HeaderContainer>
        <TitleContainer>
          <Title>{i18n.t(`${TRANSLATION_KEY}.get_ready_for_the_real_interview`)}</Title>
          {!isPremiumUser && (
            <DesktopContainer>
              <AvailablePremiumBanner />
            </DesktopContainer>
          )}
        </TitleContainer>
        <RightText>{i18n.t(`${TRANSLATION_KEY}.based_on_your_job_title`)}</RightText>
      </HeaderContainer>
      <CardsContainer>
        {isLoading && [0, 1, 2, 3].map(i => <CardSkeleton key={i} />)}
        {interviews?.slice(0, 4).map(interview => (
          <Card
            key={interview.company_name + interview.job_title + interview.questions_count}
            companyInterview={interview}
            onClick={() => {
              trackInternalEvent('pick_interview', {
                label: 'interview_prep',
                parameter: 'interview_dash_interviews',
              })
              navigate('/interview-preparation/custom-interview', {
                state: {
                  company: interview.company_name,
                  jobTitle: {
                    id: jobTitleId,
                    text: interview.job_title,
                  },
                },
              })
            }}
          />
        ))}
      </CardsContainer>
    </Container>
  )
}
