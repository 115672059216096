import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Icon from 'builder/components/Icon'

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  ${Media.Tablet`
    gap: 0 12px;
    grid-template-columns: 48px calc(100% - 48px - 32px - 24px) 32px;
  `}
`

export const HeaderInfo = styled.div`
  display: flex;
  align-items: center;
`

export const Logo = styled.div<{ src?: string }>`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 48px;
  height: 48px;
  background-image: url(${p => `"${p.src}"`});
  background-position: center center;
  background-size: contain;
`

export const Actions = styled.div`
  display: flex;
`

export const ActionButton = styled.div`
  width: 32px;
  height: 32px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: all 0.1s ease;

  :hover {
    background: ${Colors.Neutral10};
  }
`

export const HeartIcon = styled(Icon.Heart)`
  color: ${Colors.Neutral90};
`

export const HeartIconOutline = styled(Icon.Heart)`
  color: ${Colors.Red50};
`
