import { SectionContainer, SectionContent } from 'builder/components/ProfileSummary/styles'
import RichTextArea from 'builder/components/RichTextArea/RichTextArea'
import { SectionHeader, SectionHint } from 'builder/components/Section'
import SectionTitle from 'builder/components/SectionTitle'
import { useUpdateSimpleShokomuField } from 'builder/hooks/useUpdateSimpleShokumuField'
import { useShokumuTranslations } from '../../useShokumuTranslations'

export const SelfPR = (): JSX.Element => {
  const { fieldValue, updateField } = useUpdateSimpleShokomuField('pr')

  const { i18n } = useShokumuTranslations()
  const title = i18n('self_pr.title')
  const brief = i18n('self_pr.hint.brief')
  const caution = i18n('self_pr.hint.caution')
  const description = i18n('self_pr.hint.description')
  const optional = i18n('self_pr.hint.optional')

  return (
    <SectionContainer>
      <SectionHeader>
        <SectionTitle title={title} />
        <SectionHint>
          {description}
          <ul>
            <li>{optional}</li>
            <li>{caution} </li>
            <li>{brief}</li>
          </ul>
        </SectionHint>
      </SectionHeader>
      <SectionContent>
        <RichTextArea
          height={96}
          boldButtonDisable
          italicButtonDisable
          linkButtonDisable
          value={fieldValue as string}
          onChange={updateField}
        />
      </SectionContent>
    </SectionContainer>
  )
}
