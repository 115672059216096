import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const SectionHeading = styled.p`
  ${FontWeights.Medium};
  padding-bottom: 16px;
`

export const Header = styled.p`
  ${Typography.Caption};
  padding-bottom: 12px;

  ${Media.Phone`
    padding-bottom: 8px;
  `}
`

export const SpecialitiesContainer = styled.div`
  padding-bottom: 24px;
`
