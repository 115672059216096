import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { FetchStatuses } from 'builder/modules/constants'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { actions, selectors } from 'builder/modules/resumeDistribution'
import { Banner } from './Banner/Banner'
import { Widget } from './Widget/Widget'
import { WidgetLoading } from './Widget/WidgetLoading'

const ResumeDistribution = () => {
  const dispatch = useDispatch()
  const candidate = useTypedSelector(selectors.candidate)
  const candidateFetchStatus = useTypedSelector(selectors.candidateFetchStatus)

  useEffect(() => {
    dispatch(actions.fetchCandidate())
  }, [dispatch])

  if (candidateFetchStatus === FetchStatuses.loading) {
    return <WidgetLoading />
  }

  return candidate ? <Widget candidate={candidate} /> : <Banner />
}

export default React.memo(ResumeDistribution)
