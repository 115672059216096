import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Sizes from 'builder/styles/sizes'
import Typography, { FontWeights } from 'builder/styles/typography'
import { ModalClose } from 'builder/components/ModalClose'
import { ScrollContainer } from 'builder/components/ScrollContainer'
import { Icon20 } from 'builder/components/Icon'

export const Container = styled.div`
  border-radius: ${Sizes.S};
  padding: ${Sizes.L};
  width: 596px;
  background-color: ${Colors.White};
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${Sizes.M};

  ${Media.Phone`
    border-radius: ${Sizes.XS};
    padding: ${Sizes.M};
    width: calc(100vw - 2 * ${Sizes.S});
  `};
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${Sizes.XS};
  text-align: center;
`

export const Title = styled.h2`
  ${Typography.S};
  ${FontWeights.DemiBold};
`

export const Subtitle = styled.p`
  color: ${Colors.Neutral50};
`

export const Content = styled.div`
  display: flex;
  padding: ${Sizes.S};
  flex-direction: column;
  gap: ${Sizes.XS};
  border-radius: ${Sizes['3XS']};
  border: 1px solid ${Colors.Neutral10};
`

export const KeywordsHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${Sizes.S};

  ${Media.Phone`
    flex-direction: column;
    align-items: start;
  `};
`

export const JobTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${Sizes.S};
  align-items: center;
  flex: 1 1 auto;
  min-width: 0;

  ${Media.Phone`
    flex: 0 0 100%;
    width: 100%;
  `};
`

export const JobTitleWrapper = styled.div`
  display: flex;
  gap: ${Sizes['2XS']};
  flex-wrap: nowrap;
  min-width: 0;
`

export const JobTitle = styled.span`
  ${Typography.Caption};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Action = styled.button`
  ${Typography.Caption};
  ${FontWeights.DemiBold};
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  color: ${Colors.Blue50};
  transition: color 0.1s ease-out;
  cursor: pointer;
  white-space: nowrap;

  ${Media.Hover`
    &:hover {
      color: ${Colors.Blue60};
    }
  `}
`

export const CounterContainer = styled.div`
  display: flex;
  gap: ${Sizes.S};
  align-items: center;
`

export const KeywordsContainer = styled(ScrollContainer)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${Sizes['3XS']};
  max-height: 212px;

  ${Media.Phone`
    max-height: 347px;
    grid-template-columns: 1fr;
  `};
`

export const Footer = styled.div`
  display: flex;
  justify-content: center;
`

export const CloseButton = styled(ModalClose)`
  position: absolute;
  top: 31px;
  right: 32px;
`

export const ArrowTargetIconWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-right: 4px;
  color: ${Colors.White};
`

export const ArrowTargetIcon = styled(Icon20.ArrowTarget2)`
  width: 24px;
  height: 24px;
`
