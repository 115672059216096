import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { trackInternalEvent } from '@rio/tracking'
import snakeCase from 'lodash/snakeCase'
import { useI18n } from 'builder/hooks/useI18n'
import Button from 'builder/components/Button'
import SocialProfileButtons from './SocialProfileButtons'

import { Title, Subtitle, ControlButtons } from './styles'
import { Content, Container, Wrapper } from '../styles'

const SocialProfile = ({ onSkip, onBack, documentType, templateId }) => {
  const { i18n } = useI18n()
  const [showContent, setContentVisibility] = useState(false)

  // "cover-letter" → "cover_letter"
  const type = snakeCase(documentType)

  useEffect(() => {
    // Track step opening
    trackInternalEvent('visit_social_methods_sign_up_step')

    // Hack to fix animation bug. See https://github.com/reactjs/react-transition-group/issues/10
    requestAnimationFrame(() => {
      setContentVisibility(true)
    })
  }, [])

  const titleText = i18n.t('builder.sign_up.social_profile_title')
  const subtitleText = i18n.t(`builder.sign_up.social_profile_subtitle_${type}`)

  return (
    <Wrapper>
      <Container>
        <Title>{titleText}</Title>
        <Subtitle>{subtitleText}</Subtitle>

        {showContent && (
          <Content>
            <SocialProfileButtons templateId={templateId} documentType={documentType} />

            <ControlButtons>
              <Button theme="ghost" onClick={onBack}>
                {i18n.t('builder.sign_up.back')}
              </Button>
              <Button onClick={onSkip}>{i18n.t('builder.sign_up.skip')}</Button>
            </ControlButtons>
          </Content>
        )}
      </Container>
    </Wrapper>
  )
}

const nullable =
  propType =>
  (props, propName, ...rest) =>
    props[propName] === null ? null : propType(props, propName, ...rest)

SocialProfile.propTypes = {
  onSkip: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  templateId: nullable(PropTypes.string.isRequired),
  documentType: PropTypes.string.isRequired,
}

export default SocialProfile
