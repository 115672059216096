import { selectors } from 'builder/modules/careerPlanning'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'

import { Wrapper } from './styles'
import TopContent from './components/TopContent'
import MainContent from './components/MainContent'
import { Loader } from './components/MainContent/components/Content/components/Loader'

const CareerPlanningContent = () => {
  const { isTablet } = useMediaQueries()
  const { features } = useFeaturesConfig()
  const isFailed = useTypedSelector(selectors.isContentFailed)
  const isLoaded = useTypedSelector(selectors.isContentLoaded)

  if (!isLoaded) {
    return <Loader {...{ isFailed }} />
  }

  return (
    <Wrapper>
      <TopContent {...{ isTablet, isLoaded, isFindCoachActive: features.careerCoaching }} />
      <MainContent />
    </Wrapper>
  )
}

export default CareerPlanningContent
