import { SizedBox } from '../InterviewCard/styles'
import { ListContainer, ListImage, ListItem, ListItemContent, ListItemTitle } from './styles'

type Props = {
  list: Array<{ iconUrl: string; title: string }>
}

const ModalList: React.FC<Props> = ({ list = [] }) => (
  <ListContainer>
    {list.map((item, index) => (
      <ListItem key={index}>
        <ListItemContent>
          <ListImage src={item.iconUrl} />

          <ListItemTitle>{item.title}</ListItemTitle>
        </ListItemContent>

        {index !== list.length - 1 && <SizedBox size={32} />}
      </ListItem>
    ))}
  </ListContainer>
)

export default ModalList
