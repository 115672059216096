import styled from 'styled-components'
import { TextAreaField, TextField } from 'builder/components/TextField'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Icon24 } from 'builder/components/Icon'

export const Wrapper = styled.div``

export const DescribeStep = styled.div`
  ${Typography.Body}
  ${FontWeights.Medium}
  color: ${Colors.Neutral90};
  text-align: center;
`

export const FormWrapper = styled.div``

export const TextFieldContainer = styled(TextField)`
  width: 100%;
`

export const TextAreaContainer = styled(TextAreaField)`
  width: 100%;
`

export const Label = styled.label`
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
  margin-bottom: 4px;
`

export const Field = styled.div`
  margin-bottom: 16px;
`

export const LabelWithTooltip = styled.div`
  display: flex;
  justify-content: space-between;
`

export const AddSkillsContainer = styled.div`
  height: 36px;
  gap: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  margin-top: 16px;
  padding-inline-start: 3px;

  &:hover {
    background-color: ${Colors.Blue10};
    border-radius: 4px;
  }
`

export const AddIcon = styled(Icon24.Add)`
  color: ${Colors.Blue50};
`

export const AddTitle = styled.div`
  ${Typography.Caption};
  ${FontWeights.DemiBold};
  color: ${Colors.Blue50};
  white-space: nowrap;
`
