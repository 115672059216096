import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { first, size } from 'lodash'
import { trackInternalEvent } from '@rio/tracking'
import { actions as editorActions } from 'builder/modules/resumeEditor'
import { actions as builderActions } from 'builder/modules/panel'
import Colors from 'builder/styles/colors'
import { Job, UpdateSelectedResumePayload, actions, selectors } from 'builder/modules/jobTracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useUser } from 'builder/hooks/useUser'
import { i18n } from 'builder/utils/i18n'
import { TunerScore } from '../TunerScore'
import {
  JobInfo,
  Header,
  HeaderText,
  Body,
  SecondaryText,
  ButtonContainer,
  PrimaryButton,
  MarketingImageContainer,
  MarketingImage,
  Card,
} from './styles'
import marketingImage from './marketingImage.svg'

type TailorYourResumeProps = {
  job: Job
  fromSidebar: boolean
  open: boolean
  hovered?: boolean
}

const LABEL = {
  label: 'sidebar',
}

const TailorYourResume = ({ job, fromSidebar, open, hovered }: TailorYourResumeProps) => {
  const dispatch = useDispatch()
  const user = useUser()

  const resumeList = useTypedSelector(selectors.selectResumeList)
  const {
    id,
    resumeScore: score,
    link,
    externalSlugId,
    description,
    title: jobTitle,
    chromeJob,
  } = job

  const isTailorYourResumePossible = useMemo(
    () => (externalSlugId || chromeJob) && description && jobTitle,
    [chromeJob, description, externalSlugId, jobTitle],
  )
  const handleTailorResume = (event: React.MouseEvent) => {
    trackInternalEvent('click_banner_optimizer')
    event.stopPropagation()
    event.preventDefault()
    if (!isTailorYourResumePossible) {
      dispatch(builderActions.createDocument({ type: 'resume' }))
    } else {
      const resumeMultiSelect = size(resumeList) > 1
      const firstResume = first(resumeList)

      trackInternalEvent('click_tailor_resume_button', LABEL)

      if (resumeMultiSelect) {
        dispatch(actions.setResumeSelection({ id, link }))
      } else {
        dispatch(editorActions.setFromJobTracker(true))
        dispatch(editorActions.setOpenOptimizerModal(true))
        dispatch(editorActions.startResumeOptimizerProcess())
        dispatch(editorActions.setJobPostingLinkForModal(link || ''))

        dispatch(
          actions.UpdateSelectedResumeRequest({
            user_id: user?.id,
            resume_id: firstResume?.id,
            link,
            job_card_id: id,
          } as UpdateSelectedResumePayload),
        )
      }
    }
  }

  const handleCreateResume = useCallback(() => {
    dispatch(builderActions.createDocument({ type: 'resume' }))
    trackInternalEvent(`click_create_resume`, { label: 'job_tracker' })
  }, [dispatch])

  const handleAction = (e: React.MouseEvent) => {
    isTailorYourResumePossible ? handleTailorResume(e) : handleCreateResume()
  }

  useEffect(() => {
    trackInternalEvent('view_banner_optimizer')
  }, [])

  const tunerScoreProps = useMemo(() => {
    return {
      score: score,
      fromSidebar: fromSidebar,
    }
  }, [score, fromSidebar])

  const translationKey = isTailorYourResumePossible
    ? 'tailor_your_resume_banner'
    : 'manual_job_banner'
  const translations = {
    title: i18n.t(`builder.job_tracking.${translationKey}.title`),
    subtitle: i18n.t(`builder.job_tracking.${translationKey}.subtitle`),
    submit: i18n.t(`builder.job_tracking.${translationKey}.submit`),
  }

  const { title, subtitle, submit } = translations

  return (
    <Card backgroundColor={hovered ? '#E0F1FE' : Colors.Blue10} open={open}>
      <JobInfo>
        <Header>
          <HeaderText color={Colors.Blue70}>{title}</HeaderText>
        </Header>
        <Body>
          <SecondaryText color={Colors.Blue70}>{subtitle}</SecondaryText>
        </Body>
        <ButtonContainer>
          <PrimaryButton onClick={handleAction} score="high">
            {submit}
          </PrimaryButton>
        </ButtonContainer>
      </JobInfo>
      {score && <TunerScore {...tunerScoreProps} />}
      <MarketingImageContainer>
        <MarketingImage score="high" src={marketingImage} />
      </MarketingImageContainer>
    </Card>
  )
}

export default TailorYourResume
