import { useI18n } from 'builder/hooks/useI18n'
import { Container, Counter, CounterCaption } from './styles'

interface Props {
  count: number
  total: number
  caption?: string
}

const TRANSLATION = 'builder.resume_editor.keywords'

export const KeywordsCounter = (props: Props) => {
  const { count, total, caption } = props
  const { i18n } = useI18n()
  const text = caption || i18n.t(`${TRANSLATION}.popup.counter_caption`)

  return (
    <Container>
      <Counter>
        {count}/{total}
      </Counter>
      <CounterCaption>{text}</CounterCaption>
    </Container>
  )
}
