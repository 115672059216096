import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { trackInternalEvent } from '@rio/tracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

import {
  selectors as renderingSelectors,
  actions as renderingActions,
} from 'builder/modules/rendering'

const MODAL_SHOWN_STORAGE_KEY = 'rd_get_started_shown'

export const useResumeDistributionGotCreditsModal = () => {
  const dispatch = useDispatch()
  const rdGotCreditsModalVisible = useTypedSelector(renderingSelectors.rdGotCreditsModalVisible)

  const shouldModalOpen = useCallback(() => {
    return rdGotCreditsModalVisible
  }, [rdGotCreditsModalVisible])

  const [isOpen, setIsOpen] = useState(shouldModalOpen)

  function setRDGotCreditsShown() {
    localStorage.setItem(MODAL_SHOWN_STORAGE_KEY, 'true')
  }

  useEffect(() => {
    if (shouldModalOpen()) {
      trackInternalEvent('show_new_credits_congratulation_modal')
    }
    setIsOpen(shouldModalOpen())
  }, [rdGotCreditsModalVisible, shouldModalOpen])

  function handleModalClose() {
    dispatch(renderingActions.closeRDGotCreditsModal())
    setRDGotCreditsShown()
  }

  return {
    isOpen,
    handleModalClose,
  }
}
