import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import {
  TABS,
  Label,
  actions,
  selectors,
  SearchQuery,
  SalaryParams,
  CareerPathStep,
} from 'builder/modules/careerPath2'

import { Form } from './components/Form'
import { CareerPathQuestionnaireFields } from './types'

import { Container, FormContainer } from './styles'

// TODO: translation
export const CareerPath2Questionnaire = () => {
  const dispatch = useDispatch()
  const location = useTypedSelector(selectors.location)
  const occupation = useTypedSelector(selectors.occupation)
  const isExploreCareers = useTypedSelector(selectors.isExploreCareers)
  const exploreCareersLocation = useTypedSelector(selectors.exploreCareersLocation)
  const exploreCareersOccupation = useTypedSelector(selectors.exploreCareersOccupation)

  useEffect(() => {
    if (window.location.pathname === '/app/explore-careers') {
      trackInternalEvent('enter_career_path_welcome', { label: 'explore_careers' })
    } else {
      trackInternalEvent('enter_career_path_welcome', Label)
    }
  }, [])

  const handleClear = (name: keyof CareerPathQuestionnaireFields) => {
    if (!isExploreCareers) {
      if (name === 'occupation') {
        dispatch(actions.setOccupation(''))
      } else {
        dispatch(actions.setLocation(''))
      }
    } else {
      if (name === 'exploreCareersOccupation') {
        dispatch(actions.setExploreCareersOccupation(''))
      } else {
        dispatch(actions.setExploreCareersLocation(''))
      }
    }
  }

  const handleChange = useCallback(
    (name: keyof CareerPathQuestionnaireFields, value: string) => {
      if (!value) {
        return
      }

      if (!isExploreCareers) {
        if (name === 'occupation') {
          dispatch(actions.setOccupation(value))
          dispatch(actions.setSkills([]))
          dispatch(actions.fetchSuggestedSkills(value))
          dispatch(actions.setCareerPath1([]))
          dispatch(actions.setLevel1JobTitles([]))
          dispatch(actions.setLevel2JobTitles([]))
          dispatch(actions.setLevel3JobTitles([]))
          return
        }

        dispatch(actions.setLocation(value))
      } else {
        if (name === 'exploreCareersOccupation') {
          dispatch(actions.setExploreCareersOccupation(value))
          return
        }

        dispatch(actions.setExploreCareersLocation(value))
      }
    },
    [dispatch, isExploreCareers],
  )

  const handleSubmit = () => {
    dispatch(actions.setActiveTab(TABS[0]))

    if (isExploreCareers) {
      const locationParam = isExploreCareers ? exploreCareersLocation : location
      const jobTitleParam = isExploreCareers ? exploreCareersOccupation : occupation

      const queryParams: SearchQuery = {
        query: jobTitleParam,
        location: locationParam,
      }

      const salaryParams: SalaryParams = {
        title: jobTitleParam,
        location: locationParam || null,
      }

      dispatch(actions.fetchJobsRequest())
      dispatch(actions.fetchRecommendedJobCard(queryParams))
      dispatch(actions.fetchSalaryDetails(salaryParams))
      dispatch(actions.setAddToPathJobTitle(jobTitleParam))
      dispatch(actions.fetchCareerPagesJobDetails(jobTitleParam))
      dispatch(actions.fetchCourses({ title: jobTitleParam, limit: 12 }))
      dispatch(actions.fetchJobDemand({ job_title: jobTitleParam, location: locationParam || '' }))

      dispatch(actions.setStep(CareerPathStep.ADD_TO_PATH))
      return
    }

    dispatch(actions.fetchUserDocuments())
    dispatch(actions.setStep(CareerPathStep.SKILLS))
    trackInternalEvent('click_continue_button_on_starting_role', Label)
  }

  return (
    <Container timeout={0}>
      <FormContainer>
        <Form
          onClear={handleClear}
          onChange={handleChange}
          onSubmit={handleSubmit}
          isExploreCareers={isExploreCareers}
          location={isExploreCareers ? exploreCareersLocation : location}
          occupation={isExploreCareers ? exploreCareersOccupation : occupation}
          fields={{ occupation, exploreCareersOccupation, location, exploreCareersLocation }}
        />
      </FormContainer>
    </Container>
  )
}
