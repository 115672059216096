import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import Colors from 'builder/styles/colors'
import { actions as editorActions } from 'builder/modules/resumeEditor'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as panelSelectors } from 'builder/modules/panel'
import { i18n as I18n } from 'builder/utils/i18n'
import { selectors } from 'builder/modules/init'

import { useResumeTailoringBanner } from '../DocumentEditor/ResumeTailoringBanner/useResumeTailoringBanner'

import Collapse from './Collapse'
import { FLOW_IDS } from './constants'
import NEED_GUIDANCE_AI from './assets/images/need_guidance_ai.svg'
import NEED_GUIDANCE_EXPERT from './assets/images/need_guidance_expert.svg'
import NEED_GUIDANCE_EXAMPLES from './assets/images/need_guidance_examples.svg'
import NEED_GUIDANCE_MATCH from './assets/images/need_guidance_match.svg'
import { CollapseWithItemsContainer, PromotionContainerColumn } from './styles'
import ActionItem from './ActionItem'

type Props = {
  handleClose: () => void
}

const ACTIONS = {
  howToStart: 'HOW_TO_START',
  review: 'REVIEW',
}

const NeedGuidance: React.FC<Props> = ({ handleClose }: Props) => {
  const [activeAction, setActiveAction] = useState<string | undefined>(undefined)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const documentsList = useTypedSelector(panelSelectors.documents)
  const { onBannerClick: openTaloring } = useResumeTailoringBanner('builder')
  const locale = useTypedSelector(selectors.locale)

  const startWithAiOnClick = () => {
    trackInternalEvent('promo_acceptance', { promo: 'try_AI', where: 'in_app_abandonment' })
    navigate(`/resumes/${documentsList[0].id}/edit?showAIResumeDraft=true`)
    dispatch(editorActions.resetAIResumeData())
    dispatch(editorActions.setShowAIResumeModal(true))
    handleClose()
  }

  const showExamples = () => {
    trackInternalEvent('promo_acceptance', { promo: 'Examples', where: 'in_app_abandonment' })
    dispatch(editorActions.fetchResumeExamples())
    handleClose()
  }

  const openTailoringPopup = () => {
    trackInternalEvent('promo_acceptance', { promo: 'Tailoring', where: 'in_app_abandonment' })
    openTaloring()
    handleClose()
  }

  const getExpertHelp = (event: React.MouseEvent) => {
    event.stopPropagation()
    trackInternalEvent('promo_acceptance', { promo: 'Expert_help', where: 'in_app_abandonment' })
    window.open(
      'https://topresume.com/resume-writing?pt=UWYxd6Xe2VTS0&utm_source=resumeio&utm_medium=rioappabandonment&utm_campaign=tr_promocard_in_rioappabandonment&utm_content=v1',
      '_blank',
    )
  }

  const handleActiveAction = (action: string) => {
    setActiveAction((prevAction: string | undefined) => {
      if (prevAction && prevAction === action) {
        return undefined
      }

      return action
    })
  }

  return (
    <PromotionContainerColumn activeCardId={FLOW_IDS.needGuidance}>
      <Collapse
        title={I18n.t('builder.in_app_abandonment.need_guidance.how_to_start.title')}
        showContent={activeAction === ACTIONS.howToStart}
        handleActiveAction={() => handleActiveAction(ACTIONS.howToStart)}
      >
        <CollapseWithItemsContainer>
          <ActionItem
            title={I18n.t('builder.in_app_abandonment.need_guidance.how_to_start.ai.title')}
            buttonOnClick={startWithAiOnClick}
            buttonText={I18n.t(
              'builder.in_app_abandonment.need_guidance.how_to_start.ai.button_text',
            )}
            logoSrc={NEED_GUIDANCE_AI}
            backgroundColor={Colors.Indigo10}
          />
          {locale !== 'nl-NL' && (
            <ActionItem
              title={I18n.t('builder.in_app_abandonment.need_guidance.how_to_start.expert.title')}
              buttonOnClick={getExpertHelp}
              buttonText={I18n.t(
                'builder.in_app_abandonment.need_guidance.how_to_start.expert.button_text',
              )}
              logoSrc={NEED_GUIDANCE_EXPERT}
            />
          )}
          <ActionItem
            title={I18n.t('builder.in_app_abandonment.need_guidance.how_to_start.examples.title')}
            buttonText={I18n.t(
              'builder.in_app_abandonment.need_guidance.how_to_start.examples.button_text',
            )}
            buttonOnClick={showExamples}
            logoSrc={NEED_GUIDANCE_EXAMPLES}
          />
        </CollapseWithItemsContainer>
      </Collapse>
      <Collapse
        title={I18n.t('builder.in_app_abandonment.need_guidance.review.title')}
        showContent={activeAction === ACTIONS.review}
        handleActiveAction={() => handleActiveAction(ACTIONS.review)}
      >
        <ActionItem
          title={I18n.t('builder.in_app_abandonment.need_guidance.review.match.title')}
          buttonText={I18n.t('builder.in_app_abandonment.need_guidance.review.match.button_text')}
          logoSrc={NEED_GUIDANCE_MATCH}
          backgroundColor={Colors.Indigo10}
          buttonOnClick={openTailoringPopup}
        />
      </Collapse>
    </PromotionContainerColumn>
  )
}

export default NeedGuidance
