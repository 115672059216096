import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Shadows from 'builder/styles/shadows'

export const Container = styled.div``

export const Card = styled.div<{ isSwiperEnabled?: boolean }>`
  display: flex;
  width: 356px;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid ${Colors.Neutral15};
  transition: shadow ease-in 0.3s;
  cursor: pointer;
  height: ${({ isSwiperEnabled }) => isSwiperEnabled && '100%'};

  &:hover {
    ${Shadows.light.mid};
  }

  ${Media.Tablet`
    min-width: 328px;
    width: 100%;
  `}

  ${Media.Phone`
    width: 335px;
  `}

  ${({ isSwiperEnabled }) => {
    return Media.Phone`
      width: ${() => (!isSwiperEnabled ? '100%' : '335px')};
    `
  }}
`
