import { useI18n } from 'builder/hooks/useI18n'
import {
  CTAContainer,
  CTAPrimary,
  Close,
  CloseButtonContainer,
  CloseIcon,
  GotCreditsDescription,
  GotCreditsFeature,
  GotCreditsFeatureTitle,
  GotCreditsFeatures,
  GotCreditsFeaturesContainer,
  GotCreditsHeaderContainer,
  GotCreditsTextContainer,
  GotCreditsTitle,
  HeaderImage,
  ModalSection,
  Overlay,
  RootModalContainer,
} from './styles'
import { useResumeDistributionGotCreditsModal } from './useResumeDistributionGotCreditsModal'

export const ResumeDistributionGotCreditsModal = () => {
  const { i18n } = useI18n()
  const { isOpen, handleModalClose } = useResumeDistributionGotCreditsModal()

  if (!isOpen) return null

  return (
    <Overlay>
      <RootModalContainer>
        <ModalSection>
          <CloseButtonContainer>
            <Close onClick={handleModalClose}>
              <CloseIcon />
            </Close>
          </CloseButtonContainer>
          <HeaderImage />
          <GotCreditsTextContainer>
            <GotCreditsHeaderContainer>
              <GotCreditsTitle>
                {i18n.t('builder.resume_distribution.got_credits_modal.title')}
              </GotCreditsTitle>
              <GotCreditsDescription>
                {i18n.t('builder.resume_distribution.got_credits_modal.description')}
              </GotCreditsDescription>
            </GotCreditsHeaderContainer>
            <GotCreditsFeaturesContainer>
              <GotCreditsFeatureTitle>
                {i18n.t('builder.resume_distribution.got_credits_modal.features_title')}
              </GotCreditsFeatureTitle>
              <GotCreditsFeatures>
                <GotCreditsFeature>
                  {i18n.t(
                    'builder.resume_distribution.got_credits_modal.features.job_applications',
                  )}
                </GotCreditsFeature>
                <GotCreditsFeature>
                  {i18n.t(
                    'builder.resume_distribution.got_credits_modal.features.weekly_jobs_shortlist',
                  )}
                </GotCreditsFeature>
                <GotCreditsFeature>
                  {i18n.t('builder.resume_distribution.got_credits_modal.features.send_resume')}
                </GotCreditsFeature>
                <GotCreditsFeature>
                  {i18n.t(
                    'builder.resume_distribution.got_credits_modal.features.interview_preparation',
                  )}
                </GotCreditsFeature>
              </GotCreditsFeatures>
            </GotCreditsFeaturesContainer>
            <CTAContainer>
              <CTAPrimary onClick={handleModalClose}>
                {i18n.t('builder.resume_distribution.got_credits_modal.get_started_cta')}
              </CTAPrimary>
            </CTAContainer>
          </GotCreditsTextContainer>
        </ModalSection>
      </RootModalContainer>
    </Overlay>
  )
}
