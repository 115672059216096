import { useEffect, useMemo, useState } from 'react'
import parseISO from 'date-fns/parseISO'
import isFuture from 'date-fns/isFuture'
import { distance } from 'builder/utils/formatDate'
import { ResumeCritique, ResumeCritiqueStatuses } from './types'

/**
 * Formats critique ending time into human-readable distance
 * and triggers a rerender every X seconds.
 */
export const useTimeLeftFormatter = (
  { reviewedAt, status }: ResumeCritique,
  refreshInterval = 15 * 1000,
): string => {
  const [now, updateNow] = useState<number>(() => Date.now())

  useEffect(() => {
    if (status !== ResumeCritiqueStatuses.pending) return

    const timer = setInterval(() => updateNow(Date.now()), refreshInterval)
    return () => clearInterval(timer)
  }, [status, refreshInterval])

  return useMemo(() => {
    if (reviewedAt && isFuture(parseISO(reviewedAt))) return distance(reviewedAt, now)
    return 'a few minutes'
  }, [now, reviewedAt])
}
