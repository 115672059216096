import { i18n as I18n } from 'builder/utils/i18n'
import { TextField } from 'builder/components/TextField'
import { ButtonSize } from 'builder/components/Button'

import {
  JobPostingTitle,
  JobPostingStatus,
  CancelButton,
  ContinueButton,
  ButtonContainer,
} from './styles'

interface BottomContainerprops {
  jobPostingLink: string
  onChange: React.ChangeEventHandler<HTMLInputElement> | undefined
  Icon: JSX.Element | null
  error: string
  status: string
  size: ButtonSize
  onClose: () => void
  onContinue: () => void
  isDisabled: boolean
}

const BottomContainerInitialView = ({
  jobPostingLink,
  onChange,
  Icon,
  error,
  status,
  size,
  onClose,
  onContinue,
  isDisabled,
}: BottomContainerprops) => {
  return (
    <>
      <JobPostingTitle>{I18n.t('builder.resume_optimizer.job_posting.title')}</JobPostingTitle>
      <TextField
        value={jobPostingLink}
        name="link"
        placeholder={I18n.t('builder.resume_optimizer.job_posting.placeholder')}
        onChange={onChange}
        inputIcon={Icon}
        inputIconPosition="right"
        error={error}
      />
      <JobPostingStatus>{status}</JobPostingStatus>
      <ButtonContainer>
        <CancelButton theme="ghost" onClick={onClose} size={size}>
          {I18n.t('builder.resume_optimizer.job_posting.cancel')}
        </CancelButton>
        <ContinueButton isDisabled={isDisabled} onClick={onContinue} size={size}>
          {I18n.t('builder.resume_optimizer.job_posting.continue')}
        </ContinueButton>
      </ButtonContainer>
    </>
  )
}

export default BottomContainerInitialView
