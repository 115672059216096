import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { SuggestionSelectedEventData } from 'react-autosuggest'
import { ChangeEvent, FormEvent, useState, useRef } from 'react'
import {
  ApiSection,
  AsyncAutosuggest,
  createNewSuggestionsApiFetcher,
} from 'builder/components/AsyncAutosuggest'
import { useFields } from 'builder/hooks/useFields'
import { TextField } from 'builder/components/TextField'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { actions, selectors } from 'builder/modules/careerPath'
import { CareerPathLevelChoiceFields, CareerPathLevelChoiceFormProps } from '../../types'

import * as Styles from './styles'

const emptyErrors = {
  occupation: '',
  location: '',
}

const DEFAULT_COUNTRY = 'United States'
const LABEL = {
  label: 'career_pathways',
}

const Form = ({ isAnyActivated, onInputChange }: CareerPathLevelChoiceFormProps) => {
  const dispatch = useDispatch()
  const [showButton, setShowButton] = useState(false)
  const location = useTypedSelector(selectors.location)
  const occupation = useTypedSelector(selectors.occupation)
  const isLoaded = useTypedSelector(selectors.isMatrixLoaded)
  const isFailed = useTypedSelector(selectors.isMatrixFailed)
  const [fields, handleUpdate] = useFields({ location, occupation })
  const [errors, setErrors] =
    useState<{ [key in keyof CareerPathLevelChoiceFields]: string }>(emptyErrors)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrors(emptyErrors)
    onInputChange()

    if (e.type === 'change' && isFailed) {
      setShowButton(true)
    }

    // Condition is need to cut off unnecessary events (e.g. onClick)
    if (e.type === 'change' && e?.target) {
      handleUpdate({ target: { name: e.target.name, value: e.target.value } })
      if (e.target.name === 'location') {
        dispatch(actions.setLocation(e.target.value))
      }
    }
  }

  // Persist recently selected values to prevent extra "type_career_occupation" event calls
  const selectedValuesRef = useRef<{ [key in keyof CareerPathLevelChoiceFields]: string }>({
    location,
    occupation,
  })

  const handleSuggestionSelected =
    (field: keyof CareerPathLevelChoiceFields) =>
    (
      e: FormEvent<HTMLInputElement>,
      selected: SuggestionSelectedEventData<CareerPathLevelChoiceFields>,
    ) => {
      const value = selected.suggestionValue
      selectedValuesRef.current[field] = value

      handleUpdate({
        target: {
          name: field,
          value,
        },
      })

      if (field === 'location') {
        dispatch(actions.setLocation(value))
        trackInternalEvent('edit_career_location', { location: value, ...LABEL })
      }
      if (field === 'occupation') {
        dispatch(actions.setOccupation(value))
        trackInternalEvent('edit_career_job_title', { occupation: value, ...LABEL })
      }
    }

  const validateFields = () => {
    let isValid = true
    const newErrors = Object.assign({}, errors)

    if (!fields.occupation) {
      newErrors.occupation = 'This field is required'
      isValid = false
    }

    if (fields.occupation !== selectedValuesRef.current.occupation) {
      newErrors.occupation = 'Please, try another occupation and select an option from the list'
      isValid = false
    }

    if (fields.location && fields.location !== selectedValuesRef.current.location) {
      newErrors.location = 'Please, try another location and select an option from the list'
      isValid = false
    }

    return { isValid, newErrors }
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    const { newErrors, isValid } = validateFields()

    setErrors(newErrors)

    if (isValid) {
      dispatch(actions.fetchDetails({ occupation, location }))
      trackInternalEvent('update_career_path', LABEL)
    }
  }

  return (
    <Styles.Container>
      <Styles.FormContainer onSubmit={handleSubmit} errors={errors}>
        <Styles.FieldContainer>
          <AsyncAutosuggest<CareerPathLevelChoiceFields>
            name="occupation"
            fetchItems={createNewSuggestionsApiFetcher('job-titles', {
              section: ApiSection.CareerPages,
            })}
            value={fields.occupation}
            onChange={handleChange}
            onSuggestionSelected={handleSuggestionSelected('occupation')}
          >
            {inputProps => (
              <TextField
                {...inputProps}
                placeholder="Your recent job title"
                error={errors.occupation}
              />
            )}
          </AsyncAutosuggest>
        </Styles.FieldContainer>
        <Styles.FieldContainer>
          <AsyncAutosuggest<CareerPathLevelChoiceFields>
            name="location"
            fetchItems={createNewSuggestionsApiFetcher('locations', {
              section: ApiSection.CareerPages,
              country: DEFAULT_COUNTRY,
            })}
            value={fields.location}
            onChange={handleChange}
            onSuggestionSelected={handleSuggestionSelected('location')}
          >
            {inputProps => (
              <TextField
                {...inputProps}
                placeholder="Your desired location"
                error={errors.location}
              />
            )}
          </AsyncAutosuggest>
        </Styles.FieldContainer>
        <Styles.ButtonContainer
          isAnyActivated={isAnyActivated}
          isLoaded={isLoaded}
          showButton={showButton}
        >
          <Styles.FormButton type="submit">Update My Paths</Styles.FormButton>
        </Styles.ButtonContainer>
      </Styles.FormContainer>
    </Styles.Container>
  )
}

export default Form
