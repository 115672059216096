import { i18n } from 'builder/context/I18nContext'
import { Notice, NoticeContent, NoticeIcon } from './styles'

interface Props {
  noticeImage: string
}

const RestrictionNotice = ({ noticeImage }: Props) => {
  return (
    <Notice>
      <NoticeIcon src={noticeImage} alt="Restriction Notice" />
      <NoticeContent>{i18n.t('builder.offer_analyzer.notice_content')}</NoticeContent>
    </Notice>
  )
}

export default RestrictionNotice
