import styled, { css } from 'styled-components'
import { TransitionStatus } from 'react-transition-group'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Sizes from 'builder/styles/sizes'
import Button from '../Button'
import { SnackBarPosition } from './types'
import {
  BOTTOM_VALUE_DESKTOP_DEFAULT,
  BOTTOM_VALUE_PHONE_DEFAULT,
  BOTTOM_ANIMATION_OFFSET,
} from './constants'

interface WrapperProps {
  $bottom?: SnackBarPosition
  $transitionState: TransitionStatus
  $isPhone: boolean
}

const transitionStyles = (bottom: SnackBarPosition | undefined, isPhone: boolean) => {
  const desktopBottom = bottom?.desktop || BOTTOM_VALUE_DESKTOP_DEFAULT
  const phoneBottom = bottom?.phone || BOTTOM_VALUE_PHONE_DEFAULT

  const offSet = isPhone ? phoneBottom : desktopBottom

  return {
    entering: {
      transform: `translateY(-${
        isPhone ? phoneBottom + BOTTOM_ANIMATION_OFFSET : desktopBottom + BOTTOM_ANIMATION_OFFSET
      }px)`,
    },
    entered: {
      transform: `translateY(-${offSet}px)`,
    },
    exiting: {
      transform: `translateY(${offSet}px)`,
    },
    exited: {
      transform: `translateY(${offSet}px)`,
    },
    unmounted: {
      transform: `translateY(${offSet}px)`,
    },
  }
}

const defaultStates = (bottom: SnackBarPosition | undefined, isPhone: boolean) => {
  const desktopBottom = bottom?.desktop || BOTTOM_VALUE_DESKTOP_DEFAULT
  const phoneBottom = bottom?.phone || BOTTOM_VALUE_PHONE_DEFAULT

  const offSet = isPhone ? phoneBottom : desktopBottom

  return {
    transform: `translateY(-${offSet}px)`,
    transition: 'transform 0.3s ease',
  }
}

export const Wrapper = styled.div<WrapperProps>(props => {
  const { $bottom, $transitionState, $isPhone } = props

  const currentTransitionStyles = transitionStyles($bottom, $isPhone)

  const transitionStyle = currentTransitionStyles[$transitionState] || css``

  const defaultStyles = defaultStates($bottom, $isPhone)

  const defaultState = defaultStyles || css``

  return css`
    position: fixed;
    width: 720px;
    display: flex;
    justify-content: center;
    left: calc(50% - 360px);
    bottom: 0;
    z-index: 1500;
    pointer-events: none;

    ${defaultState};
    ${transitionStyle};

    ${Media.Tablet`
      width: 100%;
      left: 0;
      padding: 0 32px;
    `}

    ${Media.Phone`
      width: 100%;
      left: 0;
      padding: 0 20px;
      bottom: 0;
    `}
  `
})

export const DismissButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  ${Typography.Caption};
  color: ${Colors.White};
  background-color: ${Colors.Neutral90};
  padding: 0 14px;
  height: 100%;
  border-left: 1px solid ${Colors.Neutral70};
  border-radius: 0 ${Sizes['2XS']} ${Sizes['2XS']} 0;

  &:hover {
    background-color: ${Colors.Neutral90};
  }

  ${Media.Phone`
    border-left: unset;
    width: 100%;
    justify-content: flex-end;
    padding: 0;
  `}
`

export const Container = styled.div`
  pointer-events: all;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.Neutral90};
  border-radius: 8px;

  ${Media.Phone`
    width: 335px;
    flex-direction: column;
    align-items: flex-start;
    padding: ${Sizes.S};
    gap: ${Sizes.XS};
  `};
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${Sizes['2XS']};
  padding: ${Sizes.XS} ${Sizes.S};

  ${Media.Phone`
    padding: 0;
    align-items: flex-start;
  `};
`

export const Text = styled.div`
  ${Typography.Caption};
  color: ${Colors.White};
`

export const IconWrapper = styled.div``

export const SuccessIconWrapper = styled.div`
  color: ${Colors.Green50};
`
