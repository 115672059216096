import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { ResumeUploadStatus, ResumeValidationStatus } from 'builder/modules/constants'
import FileUploadProgress from 'builder/components/FillResumeModal/UploadResume/FileUploadContainer/FileUploadProgress'
import FileUploadSuccess from 'builder/components/FillResumeModal/UploadResume/FileUploadContainer/FileUploadSuccess'
import FileUploadFailed from 'builder/components/FillResumeModal/UploadResume/FileUploadContainer/FileUploadFailed'
import { useI18n } from 'builder/hooks/useI18n'
import ActionLayout from './ActionLayout'
import { ExpandedUploadContainer, Square, Title, UploadContainer } from './styles'

type Props = {
  onSubmit: (file: File) => void
  expanded?: boolean
}

const UploadResume = ({ onSubmit, expanded = false }: Props) => {
  const { resumeValidationStatus, resumeUploadStatus } = useTypedSelector(
    state => state.resumeEditor.prefillResumeSection,
  )
  const { i18n } = useI18n()

  const resumeStatus =
    resumeUploadStatus === ResumeUploadStatus.failed
      ? ResumeValidationStatus.failed
      : resumeValidationStatus

  const getResumeUploadingStatus = () => {
    switch (resumeStatus) {
      case ResumeValidationStatus.uploading:
        return <FileUploadProgress />
      case ResumeValidationStatus.validated:
        return <FileUploadSuccess />
      case ResumeValidationStatus.failed:
        return <FileUploadFailed />
      default:
        return null
    }
  }

  return expanded ? (
    <ExpandedUploadContainer>
      <Title>{i18n.t('builder.job_search.upload_resume_modal.second_title')}</Title>
      <Square>{getResumeUploadingStatus()}</Square>
      <ActionLayout onSubmit={onSubmit} addSpaceWhenNotVisible />
    </ExpandedUploadContainer>
  ) : (
    <UploadContainer>
      {getResumeUploadingStatus()}
      <ActionLayout onSubmit={onSubmit} />
    </UploadContainer>
  )
}

export default UploadResume
