import { trackInternalEvent } from '@rio/tracking'
import { CareerPathQuestionnaireFields } from '../../types'

interface Params {
  name: keyof CareerPathQuestionnaireFields
  isExploreCareers: boolean
  occupation: string
  location: string
  value: string
}

interface EventParams {
  label: string
  screen: string
  job_title: string
  location?: string
}

export const getEventName = (name: keyof CareerPathQuestionnaireFields) => {
  switch (name) {
    case 'occupation':
    case 'exploreCareersOccupation':
      return 'select_career_job_title'

    default:
      return 'select_location'
  }
}

const getEventParams = ({ name, value, location, occupation, isExploreCareers }: Params) => {
  if (name === 'occupation' || name === 'exploreCareersOccupation') {
    return {
      screen: 'enter',
      job_title: occupation || value,
      label: isExploreCareers ? 'explore_careers' : 'career_path2',
    }
  }

  return {
    screen: 'enter',
    job_title: occupation,
    location: location || value,
    label: isExploreCareers ? 'explore_careers' : 'career_path2',
  }
}

export const sendTrackingEvent = ({
  name,
  value,
  location,
  occupation,
  isExploreCareers,
}: Params) => {
  const eventParams: EventParams = getEventParams({
    name,
    value,
    location,
    occupation,
    isExploreCareers,
  })

  trackInternalEvent(getEventName(name), { ...eventParams })
}
