import { useDispatch } from 'react-redux'
import { useCallback, useMemo, useState } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import isThisYear from 'date-fns/isThisYear'
import { useUser } from 'builder/hooks/useUser'
import { formatDate, parseDate } from 'builder/utils/formatDate'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as userSelectors } from 'builder/modules/user'
import {
  ResumeList,
  UpdateSelectedResumePayload,
  actions,
  selectors,
} from 'builder/modules/jobTracking'
import { actions as editorActions } from 'builder/modules/resumeEditor'
import { FetchStatuses } from 'builder/modules/constants'
import { useDocuments } from 'builder/components/MergeDocumentsModal/hooks'
import { i18n } from 'builder/utils/i18n'
import BackImage from './back.svg'
import ResumeIcon from './resumeIcon.svg'
import {
  Container,
  PaddedContainer,
  Header,
  Back,
  Title,
  BackIcon,
  ResumeListBlock,
  ResumeListItem,
  ResumeImage,
  ResumeText,
  ResumeTitle,
  ResumeDate,
  TailorResumeButton,
  ButtonContainer,
  SubTitle,
} from './styles'

type UpdatedAt = string | undefined | null
type ResumeId = number

type ResumeProps = {
  onClick: (id: number) => void
}

const ResumeSelection = ({ onClick }: ResumeProps) => {
  const dispatch = useDispatch()
  const user = useUser()
  const { resumes } = useDocuments()

  const [selectedResume, setSelectedResume] = useState<number>()
  const resumeList = useTypedSelector(selectors.selectResumeList)
  const resumeSelection = useTypedSelector(selectors.resumeSelection)
  const updateResumeStatus = useTypedSelector(selectors.selectFetchUpdateResumeStatus)
  const isPremiumUser = useTypedSelector(userSelectors.premiumAccess)

  const updateResumeLoading = useMemo(() => {
    return updateResumeStatus?.status === FetchStatuses.loading
  }, [updateResumeStatus])

  const resumeTitle = (item: ResumeList) => {
    let title = item?.displayName
    if (item?.jobTitle) {
      title = `${item?.displayName}, ${item?.jobTitle}`
    }
    return title
  }

  const formatUpdatedDate = useCallback((updatedAt: UpdatedAt) => {
    if (updatedAt) {
      const date = parseDate(updatedAt)
      const format = isThisYear(date) ? 'd MMMM, HH:mm' : 'd MMMM yyyy, HH:mm'

      return formatDate(date, format)
    }
  }, [])

  const handleSelectResume = (resumeId: ResumeId) => {
    trackInternalEvent('select_resume_to_tailor')
    setSelectedResume(resumeId)
  }

  const hasCoverLettersLimitReached = !isPremiumUser && resumes.length >= 3

  const handleTailorResume = () => {
    if (hasCoverLettersLimitReached) {
      dispatch(actions.setCioLimitModal(true))
    } else {
      dispatch(editorActions.setFromJobTracker(true))
      dispatch(editorActions.setOpenOptimizerModal(true))
      dispatch(editorActions.startResumeOptimizerProcess())
      dispatch(editorActions.setJobPostingLinkForModal(resumeSelection?.link || ''))

      dispatch(
        actions.UpdateSelectedResumeRequest({
          user_id: user?.id,
          resume_id: selectedResume,
          link: resumeSelection?.link,
          job_card_id: resumeSelection?.id,
        } as UpdateSelectedResumePayload),
      )
    }
  }

  const handleBack = useCallback(() => {
    dispatch(actions.setResumeSelection(null))

    if (resumeSelection?.id !== undefined) {
      onClick(resumeSelection?.id)
    }
  }, [dispatch, resumeSelection, onClick])

  return (
    <Container>
      <PaddedContainer>
        <Header>
          <Back onClick={handleBack}>
            <BackIcon src={BackImage} />
          </Back>
          <Title>{i18n.t('builder.job_tracking.side_bar.title')}</Title>
        </Header>
        <SubTitle>{i18n.t('builder.job_tracking.side_bar.subtitle')}</SubTitle>
      </PaddedContainer>
      <ResumeListBlock>
        {resumeList.map((item, index) => (
          <ResumeListItem
            key={index}
            onClick={() => handleSelectResume(item?.id)}
            isActive={item?.id === selectedResume}
          >
            <ResumeImage src={ResumeIcon} />
            <ResumeText>
              <ResumeTitle>{resumeTitle(item)}</ResumeTitle>
              <ResumeDate>Updated {formatUpdatedDate(item?.updatedAt)}</ResumeDate>
            </ResumeText>
          </ResumeListItem>
        ))}
      </ResumeListBlock>

      <ButtonContainer>
        <TailorResumeButton
          onClick={handleTailorResume}
          isDisabled={!selectedResume || updateResumeLoading}
          isLoading={updateResumeLoading}
        >
          {i18n.t('builder.job_tracking.job_tracking_card.wishlist_button_two')}
        </TailorResumeButton>
      </ButtonContainer>
    </Container>
  )
}

export default ResumeSelection
