import { useUser } from 'builder/hooks/useUser'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/careerPath2'
import { selectors as userSelectors } from 'builder/modules/user'
import { Container, Avatar, Name, Occupation } from './styles'

const UserProfile = () => {
  const user = useUser()
  const occupation = useTypedSelector(selectors.occupation)
  const photoUrl = useTypedSelector(userSelectors.photoUrl)

  return (
    <Container>
      <Avatar src={photoUrl} />
      <Name>{user?.fullName}</Name>
      <Occupation>{occupation}</Occupation>
    </Container>
  )
}

export default UserProfile
