import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/careerPath2'
import { PreFillModal } from 'builder/components/CareerPath2Skills/components/PreFillModal'
import { Form } from './components/Form'

import { Container, FormContainer } from './styles'

export const CareerPathSkills = () => {
  const openPrefillModal = useTypedSelector(selectors.openPrefillModal)

  return (
    <>
      {openPrefillModal && <PreFillModal />}
      <Container timeout={0}>
        <FormContainer>
          <Form />
        </FormContainer>
      </Container>
    </>
  )
}
