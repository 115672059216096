import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'

export const CareerCardLoaderContainer = styled.div`
  position: absolute;
  width: 465px;
  height: 183px;
  left: auto;
  top: 117px;
  z-index: 4;
`

export const DocumentLoaderContainer = styled.div`
  position: absolute;
  width: 465px;
  height: 183px;
  left: auto;
  top: 40px;
  z-index: 1;
`
export const ToolTip = styled.img`
  position: absolute;
  left: -23px;
`

export const CareerCardImageTitle = styled.div`
  position: absolute;
  width: 187px;
  height: 40px;
  left: 5px;
  top: 25px;
  color: ${Colors.Neutral90};
  ${Typography.ButtonS};
  ${FontWeights.DemiBold};
  display: flex;
  align-items: center;
`

export const CareerCardIconLoading = styled.img`
  position: absolute;
  width: 102px;
  height: 42px;
  left: 215px;
  top: 20px;
  border-radius: 6px;
`

export const DocumentImageTitle = styled.div`
  position: absolute;
  width: 187px;
  height: 40px;
  left: 5px;
  top: 35px;
  color: ${Colors.Neutral90};
  ${Typography.ButtonS};
  ${FontWeights.DemiBold};
  display: flex;
  align-items: center;
`

export const DocumentIconLoading = styled.img`
  position: absolute;
  width: 102px;
  height: 42px;
  left: 215px;
  top: 30px;
  border-radius: 6px;
`

export const TickMark = styled.img`
  position: relative;
  left: 8px;
  right: 20%;
  display: flex;
  top: 25px;
`

export const Text = styled.div`
  width: 279px;
  height: 32px;
  margin-left: 30px;
  margin-top: 11px;
  margin-bottom: 12px;
  color: ${Colors.Neutral90};
  ${Typography.Tiny};
  ${FontWeights.Regular};
`

export const TextContainer = styled.div`
  position: absolute;
  left: 4px;
  top: 66px;
`

export const TextSubContainer = styled.div`
  float: left;
  width: 295px;
  height: 22px;
  margin-bottom: 15px;
`
