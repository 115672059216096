import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Shadows from 'builder/styles/shadows'

export const Card = styled.div`
  ${Shadows.lightWithBorder.low};
  gap: 70px;
  display: flex;
  padding: 32px;
  border-radius: 16px;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
  background: ${Colors.White};

  &:hover {
    ${Shadows.dark.mid};
  }

  ${Media.Phone`
    padding: 24px;
  `}
`

export const LeftContent = styled.div`
  max-width: 580px;
  gap: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 1 auto;

  ${Media.Phone`
    max-width: 100%;
  `}

  ${Media.Tablet`
    max-width: 100%;
  `}
`

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
  padding: 0px;
  gap: 40px;

  ${Media.Phone`
    display: none;
  `}

  ${Media.Tablet`
    display: none;
  `}
`

export const TopContent = styled.div`
  width: 100%;
`

export const Description = styled.p`
  width: 100%;
  color: ${Colors.Neutral50};
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  ${Media.Phone`
    -webkit-line-clamp: 6;
    padding-right: 0px;
  `}
`

export const Divider = styled.div`
  width: 100%;
  border: 1px solid ${Colors.Neutral15};
`

export const BottomContent = styled.div`
  width: 100%;
  gap: 16px;
  display: flex;
  flex-direction: column;
`
