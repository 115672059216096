import { useState } from 'react'
import Colors from 'builder/styles/colors'
import { IService } from '../../types'
import { highlightColors } from '../../hooks/useServiceData'
import * as Styled from './styles'
import ExpandSvg from './Components/ExpandSvg'
import BlurCard from './Components/BlurCard'

interface IServiceCardProps extends React.ButtonHTMLAttributes<HTMLElement> {
  service: IService
  isLoading: boolean
}

const ServiceCard = ({ service, onClick }: IServiceCardProps) => {
  const {
    imgUrl,
    subTitle,
    title,
    colorTone,
    isShowScore,
    isShowAdd,
    isShowIcons,
    isShowResume,
    isShowCoverLetter,
    isCoverLetter,
    visual: Visual,
    userPhotoUrl,
  } = service
  const [isMouseOver, setIsMouseOver] = useState(false)

  const handleMouseEnter = () => setIsMouseOver(true)
  const handleMouseLeave = () => setIsMouseOver(false)

  const colorScheme = {
    tone10: Colors[`${colorTone}${10}`],
    tone15: highlightColors[`${colorTone}`],
    tone20: Colors[`${colorTone}${20}`],
    tone30: Colors[`${colorTone}${30}`],
    tone50: Colors[`${colorTone}${50}`],
    tone80: Colors[`${colorTone}${80}`],
  }
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (onClick) onClick(event)
  }
  return (
    <>
      <Styled.Container
        bgColor={isMouseOver ? colorScheme.tone15 : colorScheme.tone10}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        {isMouseOver && service.isPostPremiumActive && <BlurCard isDocument={false} />}
        <Styled.VisualWrapper>
          <Visual
            imgUrl={imgUrl}
            alt={title}
            isMouseOver={isMouseOver}
            colorScheme={colorScheme}
            isShowAdd={isShowAdd}
            isShowIcons={isShowIcons}
            isShowScore={isShowScore}
            isShowResume={isShowResume}
            isShowCoverLetter={isShowCoverLetter}
            userPhotoUrl={userPhotoUrl}
            isCoverLetter={isCoverLetter}
          />
          <Styled.VisualGradient bgColor={isMouseOver ? colorScheme.tone15 : colorScheme.tone10} />
        </Styled.VisualWrapper>
        <Styled.ContentContainer>
          <Styled.Title color={colorScheme.tone80}>{title}</Styled.Title>
          <Styled.SubTitle
            color={colorScheme.tone80}
            isMouseOver={isMouseOver}
            reduceLineHeight={subTitle.length > 100}
          >
            {subTitle}
          </Styled.SubTitle>
        </Styled.ContentContainer>
        <Styled.LinkContainer bgColor={isMouseOver ? colorScheme.tone50 : colorScheme.tone20}>
          <ExpandSvg fillColor={isMouseOver ? Colors.White : colorScheme.tone50} />
        </Styled.LinkContainer>
      </Styled.Container>
    </>
  )
}

export default ServiceCard
