import { useEffect, useCallback, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors, actions } from 'builder/modules/resumeEditor'
import { useTailoredDesignation } from 'builder/hooks/useTailoredDesignation'
import { suggestionScopeToSectionName } from 'builder/components/RichTextArea'
import { Props } from '../GenerateWithTailoredRole/types'

const useGenerateWithTailoredRole = (props: Props) => {
  const {
    suggestionConfig: { scope },
  } = props
  const { recommendedJobTitle, employerName } = useTypedSelector(selectors.jobPostingAPIData)
  const tailoredDesignation = useTailoredDesignation(recommendedJobTitle, employerName)
  const { includeJobPosting } = useTypedSelector(selectors.aiProfile)
  const GenerateWithTailoredRoleRef = useRef<HTMLDivElement | null>(null)
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(actions.setIsProfileSummaryWithTailoredResumeModalOpen(false))
  }

  useEffect(() => {
    if (tailoredDesignation) {
      trackInternalEvent('open_ai_writer_tailored_dialog', {
        section: suggestionScopeToSectionName(scope),
      })
    }
  }, [scope, tailoredDesignation])

  useEffect(() => {
    dispatch(actions.setIsIncludeJobPostingToProfileSummary(true))
  }, [dispatch])

  const handleSelectOption = useCallback(
    value => {
      dispatch(actions.setIsIncludeJobPostingToProfileSummary(value))
    },
    [dispatch],
  )

  const handleGenerate = useCallback(() => {
    dispatch(actions.setIsProfileSummaryWithTailoredResumeModalOpen(false))
    dispatch(actions.generateResumeAiSummary())
    trackInternalEvent('click_generate', {
      label: 'ai_writer_tailored_dialog',
      section: suggestionScopeToSectionName(scope),
      position_selected: includeJobPosting,
    })
  }, [dispatch, includeJobPosting, scope])

  return {
    tailoredDesignation,
    GenerateWithTailoredRoleRef,
    includeJobPosting,
    handleClose,
    handleSelectOption,
    handleGenerate,
  }
}

export default useGenerateWithTailoredRole
