import { Container, Content, FieldContainer, Header, InputField } from './styles'

interface CareerLinkedInProfileProps {
  onHandleLinkedinUrlChange: (linkedIn: string) => void
  linkedinUrl: string
}

export const CareerLinkedInProfile = ({
  onHandleLinkedinUrlChange,
  linkedinUrl,
}: CareerLinkedInProfileProps) => {
  return (
    <Container>
      <Content>
        <Header>Could you share your LinkedIn profile?</Header>
      </Content>
      <FieldContainer>
        <InputField
          data-testid="linkedinInput"
          value={linkedinUrl}
          placeholder="LinkedIn profile"
          onChange={e => {
            onHandleLinkedinUrlChange(e.target.value)
          }}
        />
      </FieldContainer>
    </Container>
  )
}
