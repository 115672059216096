import {
  Context,
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
  PropsWithChildren,
} from 'react'
import { useAppSelector } from 'builder/hooks/useAppSelector'
import { ResumeExample } from './types'

type ResumeExampleContextType = {
  jobTitles: string[]
  selectedJobTitle: string
  setSelectedJobTitle: (selectedJobTitle: string) => void
  categories: string[]
  selectedCategory: string
  setSelectedCategory: (setSelectedCategory: string) => void
  resumeExamples: ResumeExample[]
  selectedResumeExample: ResumeExample | null
  setSelectedResumeExample: (resumeExample: ResumeExample) => void
  filteredResumeExamples: ResumeExample[]
}

const ResumeExampleContext = createContext<ResumeExampleContextType | null>(null)

type ResumeExampleContextProviderProps = {
  selectedResumeExample: ResumeExample | null
  setSelectedResumeExample: (example: ResumeExample) => void
}

const ResumeExampleContextProvider = (
  props: PropsWithChildren<ResumeExampleContextProviderProps>,
) => {
  const { selectedResumeExample, setSelectedResumeExample, children } = props
  const resumeExamples = useAppSelector(store => store.resumeEditor.useExample.resumeExamples)

  const [selectedJobTitle, setSelectedJobTitle] = useState<string>('')
  const [selectedCategory, setSelectedCategory] = useState<string>('')
  const [filteredResumeExamples, setFilteredResumeExamples] =
    useState<ResumeExample[]>(resumeExamples)

  useEffect(() => {
    if (!selectedCategory) {
      setFilteredResumeExamples(resumeExamples)
    } else {
      setFilteredResumeExamples(
        resumeExamples.filter(ex => ex.exampleCategoryName === selectedCategory),
      )
    }
  }, [selectedCategory, resumeExamples])

  const [categories, jobTitles] = useMemo(() => {
    let categories: string[] = []
    let jobTitles: string[] = []
    for (const resume of resumeExamples) {
      const { exampleCategoryName, jobTitle } = resume
      if (exampleCategoryName && !categories.includes(exampleCategoryName)) {
        categories = [...categories, exampleCategoryName]
      }
      jobTitles = [...jobTitles, jobTitle]
    }
    return [categories, jobTitles]
  }, [resumeExamples])

  return (
    <ResumeExampleContext.Provider
      value={{
        jobTitles,
        selectedJobTitle,
        setSelectedJobTitle,
        categories,
        selectedCategory,
        setSelectedCategory,
        resumeExamples,
        selectedResumeExample,
        setSelectedResumeExample,
        filteredResumeExamples,
      }}
    >
      {children}
    </ResumeExampleContext.Provider>
  )
}

const useResumeExampleContext = (): ResumeExampleContextType => {
  const context = useContext<ResumeExampleContextType>(
    ResumeExampleContext as Context<ResumeExampleContextType>,
  )

  if (!context) {
    throw new Error('No context data found')
  }

  return context
}

export default ResumeExampleContext
export { useResumeExampleContext, ResumeExampleContextProvider }
