import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'
import { rotation } from 'builder/styles/keyframes'

// Magic modal
export const Container = styled.div`
  pointer-events: all;
  width: 380px;
  display: flex;
  align-items: center;
  background-color: ${Colors.Neutral100};
  border-radius: 4px;
  padding: 12px;

  ${Media.Phone`
    width: 300px;
  `};
`

export const Text = styled.div`
  ${Typography.Caption};
  color: ${Colors.White};
`

const Icon = styled.div`
  width: 24px;
  height: 24px;
  background-size: contain;
  margin-right: 10px;
  flex-shrink: 0;
`

export const Spinner = styled(Icon)`
  background-image: url(${require('images/builder/dashboard/spinner-dark.svg')});
  animation: ${rotation} 0.5s infinite linear;
`
