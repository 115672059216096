import { Component } from 'react'
import PropTypes from 'prop-types'
import { reflow } from 'builder/utils/reflow'
import { Transition } from 'react-transition-group'
import { Content } from './styles'

class Foldable extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    duration: PropTypes.number,
    isOpen: PropTypes.bool,
    unmountOnExit: PropTypes.bool,
  }

  static defaultProps = {
    duration: 200,
  }

  handleEnter = node => {
    node.style.height = 0
    node.style.overflowY = 'hidden'
    node.style.opacity = 0
  }

  handleEntering = node => {
    node.style.height = `${node.scrollHeight}px`
    node.style.opacity = 1
  }

  handleEntered = node => {
    node.style.overflow = 'visible'
    node.style.height = 'auto'
  }

  handleExiting = node => {
    node.style.overflowY = 'hidden'
    node.style.height = `${node.scrollHeight}px`
    reflow(node)
    node.style.height = 0
    node.style.opacity = 0
  }

  render() {
    const { isOpen, duration, unmountOnExit = true } = this.props

    return (
      <Transition
        in={isOpen}
        timeout={200}
        onEnter={this.handleEnter}
        onEntering={this.handleEntering}
        onEntered={this.handleEntered}
        onExiting={this.handleExiting}
        unmountOnExit={unmountOnExit}
        appear
      >
        <Content duration={duration}>{this.props.children}</Content>
      </Transition>
    )
  }
}

export default Foldable
