import styled from 'styled-components'
import { ResumeCritique } from 'builder/components/ResumeCritique/ResumeCritique'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

export const VisualContainer = styled.div`
  position: absolute;
  width: 268px;
  height: 214px;
  left: 0px;
  top: 0px;

  ${Media.Phone`
    width: 242px;
    height: 212px;
  `}
`

export const VisualImageContainer = styled.div<{ isCreateCoverLetter?: boolean }>`
  position: absolute;
  width: 129.93px;
  height: 185px;
  left: ${props => (props?.isCreateCoverLetter ? '58px' : '50px')};
  top: 60px;

  ${Media.Phone`
    left: 27px;
    top: 62px;
  `}

  ${props =>
    props.isCreateCoverLetter &&
    Media.Phone`
        left: 55px`}
`

export const VisualImage = styled.img`
  width: 100%;
  height: 100%;
`

export const CompaniesIconsStickContainer = styled.div`
  position: absolute;
  left: 198px;
  top: 60px;

  ${Media.Phone`
    left: 175px;
    top: 62px;
  `}
`

export const AddIconContainer = styled.div`
  position: absolute;
  top: 42px;
  left: 168px;
  width: 48px;
  height: 48px;

  ${Media.Phone`
    top: 39px;
    left: 160px;
  `}
`

export const AddIconImage = styled.img`
  width: 100%;
  height: 100%;
`

export const ScoreContainer = styled.div`
  position: absolute;
  width: 53px;
  height: 68px;
  left: 199px;
  top: 60px;

  ${Media.Phone`
    left: 176px;
    top: 60px;
  `}
`

export const Critique = styled(ResumeCritique)`
  flex-grow: 1;
  border: 1px solid ${Colors.Neutral15};
  overflow: hidden;
  border-radius: 6px;
  height: 100%;

  ${Media.Phone`
    border-radius: 0;
    border-left: none;
    border-right: none;
  `};
`
