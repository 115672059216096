import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Button from 'builder/components/Button'

export const InnerContainer = styled.div`
  width: 100%;
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const TryAgainButton = styled(Button)`
  width: fit-content;
`

export const DownloadFailedText = styled.p`
  color: ${Colors.Neutral50};
`
