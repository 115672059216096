import { useMutation, useQueryClient } from '@tanstack/react-query'
import { baseClient } from 'builder/modules/apiClient'

interface Params {
  active: boolean
}
export const useMutationRecommendationAlert = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ active }: Params): Promise<void> => {
      const res = await baseClient.put('/job-alerts/v1/recommendation-subscription', {
        active,
      })
      return res.data
    },
    {
      onSuccess: (data, { active }: Params) => {
        queryClient.setQueryData<{ active: boolean }>(['useGetRecommendationAlert'], () => {
          return { active }
        })
      },
    },
  )
}
