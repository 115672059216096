import { useState } from 'react'
import { Select } from 'builder/components/Select'
import { ComponentPreview, Group, Label } from '../../styles'
import { TurtleName, MIXED_OPTIONS, NUMBER_OPTIONS, TYPED_OPTIONS } from './constants'

export const SelectSection = () => {
  const [value, setValue] = useState(MIXED_OPTIONS[0].id)
  const [number, setNumber] = useState<number>(NUMBER_OPTIONS[0].id)
  const [turtle, setTurtle] = useState<TurtleName>(TYPED_OPTIONS[0].id)

  return (
    <Group>
      <Label>Select</Label>

      <ComponentPreview>
        <Select label="Label" selected={value} onSelect={setValue} options={MIXED_OPTIONS} />
      </ComponentPreview>

      <ComponentPreview>
        <Select
          disabled
          label="Disabled select"
          selected={value}
          onSelect={setValue}
          options={MIXED_OPTIONS}
        />
      </ComponentPreview>

      <ComponentPreview>
        <Select
          error="Error message"
          label="Select with error"
          selected={value}
          onSelect={setValue}
          options={MIXED_OPTIONS}
        />
      </ComponentPreview>

      <ComponentPreview>
        <Select
          warning="Warning message"
          label="Select with warning"
          selected={value}
          onSelect={setValue}
          options={MIXED_OPTIONS}
        />
      </ComponentPreview>

      {/* TypeScript debug: number values only */}
      <ComponentPreview style={{ display: 'none' }}>
        <Select
          label="Typed select with number values only"
          selected={number}
          onSelect={n => setNumber(n)}
          options={NUMBER_OPTIONS}
        />
      </ComponentPreview>

      {/* TypeScript debug: strictly typed values  */}
      <ComponentPreview style={{ display: 'none' }}>
        <Select
          label="Typed select with strictly defined values only"
          selected={turtle}
          onSelect={t => setTurtle(t)}
          options={TYPED_OPTIONS}
        />
      </ComponentPreview>
    </Group>
  )
}
