import styled from 'styled-components'
import Colors from 'builder/styles/colors'

export const Wrapper = styled.div`
  margin-top: 32px;
  padding-bottom: 32px;
  margin-bottom: 36px;
  border-bottom: 1px solid ${Colors.Neutral15};
`

export const Title = styled.div`
  margin-top: 24px;
`

export const LabelContainer = styled.div`
  margin-top: 12px;
  margin-bottom: -12px;
`
