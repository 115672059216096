import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'
import { FeatureIcon } from 'builder/components/FeatureIcon'

export const ModalContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  background-color: ${Colors.White};
  border-radius: 16px;
  display: flex;
  overflow: hidden;
  position: relative;
  overflow: visible;
  height: 576px;

  ${Media.Tablet`
    max-width: 544px;
    height: unset;
  `}
`

export const LeftSide = styled.div`
  flex: 1 50%;
  padding: 48px;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${Media.Tablet`
    padding: 32px;
    flex: 1 100%;
  `}

  ${Media.Phone`
    padding: 20px;
    flex: 1 100%;
  `}
`

export const RightSide = styled.div<{ isGreen?: boolean }>`
  flex: 1 50%;
  background-color: ${props => (props.isGreen ? Colors.Green20 : Colors.Neutral10)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;

  ${Media.Tablet`
    display: none;
  `}
`

export const InlineContainer = styled.div`
  display: flex;
  align-items: center;
`

export const IconContainer = styled.div`
  display: flex;
  margin-right: 15px;
`

export const HeaderTitle = styled.div`
  color: ${Colors.Neutral90};
  font-weight: 500;

  ${Typography.Body}

  ${Media.Tablet`
    ${Typography.Subhead}
  `}
`

export const Title = styled.div`
  ${Typography.M}
  font-weight: 600;
  margin-top: 32px;

  ${Media.AboveTablet`
    max-width: 300px;
  `}

  ${Media.Tablet`
    ${Typography.S}
    margin-top: 48px;
  `}

  ${Media.Phone`
    margin-top: 24px;
    max-width: 200px;
  `}
`

export const AddJobDescription = styled.div`
  ${Typography.Body};
  color: ${Colors.Neutral50};
  margin-top: 8px;
  margin-bottom: 16px;

  ${Media.Tablet`
    margin-bottom: 19px;
    `}

  ${Media.Phone`
    margin-bottom: 16px;
  `}
`

export const HeaderImage = styled(FeatureIcon)`
  width: 32px;
  height: 32px;

  ${Media.Tablet`
    width: 40px;
    height: 40px;
  `}

  ${Media.Phone`
    width: 32px;
    height: 32px;
  `}
`

export const Close = styled.div`
  position: absolute;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  background-color: ${Colors.White};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  right: 24px;
  top: 24px;

  transition: color 0.15s;
  color: ${Colors.Neutral50};

  ${Media.Tablet`
    top: 16px;
    right: 16px;
    z-index: 10;
    background-color: ${Colors.Neutral10};

    right: 32px;
    top: 32px;
  `}

  ${Media.Phone`
    right: 20px;
    top: 20px;
    width: 24px;
    height: 24px;
  `}

  &:hover {
    color: ${Colors.Blue50};
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${Media.Tablet`
    margin-top: 48px
    `}

  ${Media.Phone`
    margin-top: 32px
  `}
`
