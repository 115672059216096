import styled, { css } from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Label = styled.div<{ isHovered?: boolean }>`
  ${Typography.Subhead};
  ${FontWeights.DemiBold};

  width: 288px;
  cursor: pointer;
  text-align: center;
  margin-bottom: 12px;

  ${props =>
    props.isHovered
      ? css`
          color: ${Colors.Blue50};
        `
      : css`
          color: ${Colors.Neutral90};
        `}

  transition: color 0.4s ease-in-out;
  -webkit-transition: color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out;
  -moz-transition: color 0.4s ease-in-out;

  ${Media.Phone`
    width: calc(100% - 48px);
  `};

  @media (max-width: 374px) {
    width: calc(100% - 32px);
  }
`

export const Input = styled.input.attrs({ type: 'file' })`
  display: none;
`
