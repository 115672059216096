import { ReactElement, useState } from 'react'
import { Divider } from '../DocumentSelect/styles'
import {
  ChevronLightDownIcon,
  ChevronLightUpIcon,
  Column,
  Container,
  ExpandButton,
  Header,
} from './styles'

interface CollapseSectionProps {
  hideDivider?: boolean
  hideExpand?: boolean
  isExpanded?: boolean
  disableHover?: boolean
  children?: ReactElement
  text: string
}

export const CollapseSection = ({
  hideDivider = false,
  hideExpand = false,
  isExpanded = false,
  disableHover = false,
  text,
  children,
}: CollapseSectionProps) => {
  const [expand, setExpand] = useState<boolean>(isExpanded)

  return (
    <>
      <Container
        disableHover={disableHover || !!expand}
        onClick={!hideExpand ? () => setExpand(!expand) : undefined}
      >
        <Column>
          <Header>{text}</Header>
        </Column>
        {!hideExpand && (
          <Column position="end">
            <ExpandButton data-testid="collapse-section-expand">
              {expand ? <ChevronLightUpIcon /> : <ChevronLightDownIcon />}
            </ExpandButton>
          </Column>
        )}
      </Container>
      {hideDivider || (!expand && <Divider data-testid="collapse-section-divider" />)}
      {expand && children}
    </>
  )
}
