import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { Bar } from './components/Bar'
import { TabletProgressbar } from './components/TabletProgressbar'
import { Progressbar } from './components/Progressbar'
import { Container, Content, RightColumn } from './styles'

interface CareerCoachingStepProps {
  activatedPage: number[]
  title: string
}

export const CareerCoachingStepProgress = ({ activatedPage, title }: CareerCoachingStepProps) => {
  const { isTablet } = useMediaQueries()

  return (
    <Container>
      <Content>
        <Bar />
        <RightColumn>
          {isTablet ? (
            <TabletProgressbar {...{ activatedPage, title }} />
          ) : (
            <Progressbar {...{ activatedPage }} />
          )}
        </RightColumn>
      </Content>
    </Container>
  )
}
