import topResumeVisual from 'images/builder/resume-editor/topResume-visual.svg'
import topResumeVisualFooter from 'images/builder/resume-editor/poweredByTopResume.svg'
import * as Styled from './styles'

export const TopResumeVisual = () => {
  return (
    <Styled.TopResumeVisualContainer>
      <Styled.Title>Get Help With Resume</Styled.Title>
      <Styled.Text>You will get one-on-one support from a professional writer.</Styled.Text>
      <Styled.TopResumeVisualWrapperContainer>
        <img src={topResumeVisual} />
      </Styled.TopResumeVisualWrapperContainer>
      <Styled.TopResumeVisualFooterWrapperContainer>
        <img src={topResumeVisualFooter} />
      </Styled.TopResumeVisualFooterWrapperContainer>
    </Styled.TopResumeVisualContainer>
  )
}
