import { useEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'

import { useI18n } from 'builder/hooks/useI18n'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import {
  CTAContainer,
  CTAPrimary,
  CTAPrimaryContainer,
  Close,
  CloseButtonContainer,
  CloseIcon,
  GetStartedDescription,
  GetStartedFeature,
  GetStartedFeatureTitle,
  GetStartedFeatures,
  GetStartedFeaturesContainer,
  GetStartedHeaderContainer,
  GetStartedTextContainer,
  GetStartedTitle,
  ModalSection,
  Image,
  ImageContainer,
  Overlay,
  RootModalContainer,
} from './styles'
import { useResumeDistributionGetStartedModal } from './useResumeDistributionGetStartedModal'
import IMAGE from './assets/nath.png'

const TRANSALTION = 'builder.resume_distribution.get_started_modal'

export const ResumeDistributionGetStartedModal = () => {
  const { i18n } = useI18n()
  const { features } = useFeaturesConfig()
  const { isOpen, handleModalClose, redirectToAutoApply } = useResumeDistributionGetStartedModal()
  const engagementModal = features?.engagementModalV3

  useEffect(() => {
    if (isOpen) {
      trackInternalEvent('show_promo_modal', { type: 'total_job_solution' })
    }
  }, [isOpen])

  if (!isOpen) return null

  return (
    <Overlay>
      <RootModalContainer>
        <CloseButtonContainer>
          <Close onClick={handleModalClose}>
            <CloseIcon />
          </Close>
        </CloseButtonContainer>
        <ImageContainer>
          <Image src={IMAGE} />
        </ImageContainer>
        <ModalSection>
          <GetStartedTextContainer>
            <GetStartedHeaderContainer>
              <GetStartedTitle>{i18n.t(`${TRANSALTION}.title.${engagementModal}`)}</GetStartedTitle>
              <GetStartedDescription>{i18n.t(`${TRANSALTION}.description`)}</GetStartedDescription>
            </GetStartedHeaderContainer>
            <GetStartedFeaturesContainer>
              <GetStartedFeatureTitle>
                {i18n.t(`${TRANSALTION}.features_title`)}
              </GetStartedFeatureTitle>

              <GetStartedFeatures>
                <GetStartedFeature>{i18n.t(`${TRANSALTION}.features.one`)}</GetStartedFeature>
                <GetStartedFeature>{i18n.t(`${TRANSALTION}.features.two`)}</GetStartedFeature>
                <GetStartedFeature>{i18n.t(`${TRANSALTION}.features.three`)}</GetStartedFeature>
              </GetStartedFeatures>
            </GetStartedFeaturesContainer>
          </GetStartedTextContainer>
          <CTAContainer>
            <CTAPrimaryContainer>
              <CTAPrimary onClick={redirectToAutoApply}>
                {i18n.t(`${TRANSALTION}.get_started_cta`)}
              </CTAPrimary>
            </CTAPrimaryContainer>
          </CTAContainer>
        </ModalSection>
      </RootModalContainer>
    </Overlay>
  )
}
