import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Shadows from 'builder/styles/shadows'
import Button from 'builder/components/Button'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

// TODO: Find better alternate solution for positioning last 3 cards pointer
// Assuming we are showing max of upto 9 job title cards
const CARDIDs = [7, 8, 9]

export const Wrapper = styled.div<{ titleId: number; level: number }>`
  z-index: 1;
  padding: 0;
  cursor: default;
  padding-left: 14px;
  position: absolute;

  ${props => Media.Tablet`
    top: ${props.titleId < 7 ? '105px' : '-289px'};
    bottom: ${props.titleId >= 7 && '102px'};
    left: 33px;
    z-index: 9999;
    padding-left: 0;
  `};

  ${props => Media.Phone`
    top: ${props.titleId < 7 ? '105px' : '-270px'};
    bottom: ${props.titleId >= 7 && '102px'};
  `};

  ${props => props.titleId < 7 && 'top: -12px'};
  ${props => props.level !== 3 && 'left: 320px'};
  ${props => props.level === 3 && 'right: 320px'};
  ${props => props.level !== 3 && 'padding-left: 14px'};
  ${props => props.level === 3 && 'padding-right: 14px'};

  // Find better alternate solution for positioning last 3 cards
  ${props => props.titleId >= 7 && 'bottom: -7px'}
`

export const Container = styled.div`
  ${Shadows.light.high};
  width: 280px;
  padding: 16px;
  border-radius: 8px;
  color: ${Colors.Neutral50};
  background-color: ${Colors.White};

  ${Media.Tablet`
    width: 260px;
  `}
`

export const AvgTime = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  justify-content: space-between;
`

export const AvgTimeText = styled.div`
  ${Typography.Caption};
`

export const AvgTimeValue = styled.div`
  color: ${Colors.Neutral90};
`

export const SkillMatch = styled(AvgTime)`
  margin-bottom: 14px;
`

export const SkillMatchText = styled(AvgTimeText)``

export const SkillMatchValue = styled(AvgTimeValue)``

export const SkillsContainer = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`

export const SkillsTitle = styled.div`
  ${Typography.Caps};
  ${FontWeights.DemiBold};
`

export const SkillsList = styled.div`
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  height: 120px;
  overflow: hidden;

  ${Media.Phone`
    height: 107px;
  `}
`

export const Skill = styled.div<{ isKnown: boolean; isCountSkills?: boolean }>`
  width: fit-content;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: ${props => (props.isKnown ? Colors.Green10 : Colors.Neutral10)};
  ${props => props.isKnown && `color: ${Colors.Green50}`};
`

export const StyledButton = styled(Button)`
  width: 100%;
`

export const Square = styled.div<{ titleId: number; level: number }>`
  width: 12px;
  height: 12px;
  background-color: ${Colors.White};
  transform: rotate(-45deg);
  position: absolute;
  left: ${props => (props.level === 3 ? '288px' : '7px')};

  ${props => Media.Tablet`
    left: 130px; 
    top: ${CARDIDs.includes(props.titleId) ? '267px' : '-5px'};
  `}

  ${props => Media.Phone`
    top: ${CARDIDs.includes(props.titleId) ? '245px' : '-5px'};
  `}

  // Find better alternate solution for positioning last 3 cards pointer
  ${props => CARDIDs.includes(props.titleId) && 'bottom: 30px'}
  ${props => !CARDIDs.includes(props.titleId) && 'top: 37px'}
`
