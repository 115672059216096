import { ButtonTheme } from 'builder/components/Button'
import { ActionButton } from './styles'
interface BackButtonProps {
  handlePrev: () => void
}

export const BackButton = ({ handlePrev }: BackButtonProps) => {
  return (
    <ActionButton type="back" theme={ButtonTheme.ghost} onClick={handlePrev}>
      Back
    </ActionButton>
  )
}
