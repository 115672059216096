import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { EXTENSION_ID, actions, selectors } from 'builder/modules/jobTracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { Icon20 } from 'builder/components/Icon'

export const useChromeExtension = () => {
  const dispatch = useDispatch()
  const { isPhone, isTablet } = useMediaQueries()
  const installed = useTypedSelector(selectors.hasInstalledExtension)
  const canInstallExtension = useTypedSelector(selectors.canInstallExtension)

  const checkExtensionInstalled = async () => {
    return fetch(`chrome-extension://${EXTENSION_ID}/icon128.png`)
      .then(() => {
        return true
      })
      .catch(() => {
        return false
      })
  }

  const getBrowserType = () => {
    return {
      isChrome: /Chrome/i.test(navigator.userAgent),
      isEdge: /Edg/i.test(navigator.userAgent),
      // @ts-expect-error Brave
      isBrave: !!navigator.brave,
    }
  }

  const getBrowserIcon = () => {
    const { isBrave, isChrome, isEdge } = getBrowserType()

    if (isBrave) return <Icon20.Brave />
    if (isEdge) return <Icon20.Edge />
    if (isChrome) return <Icon20.Chrome />

    return <></>
  }

  const checkAvailabilityToInstallExtension = useCallback(async () => {
    const { isBrave, isChrome, isEdge } = getBrowserType()
    const isChromiumDesktop = (isChrome || isEdge || isBrave) && !(isPhone || isTablet)

    const isExtensionInstalled = await checkExtensionInstalled()
    dispatch(actions.setHasInstalledExtension(isExtensionInstalled))
    dispatch(actions.setCanInstallExtension(isChromiumDesktop && !isExtensionInstalled))
  }, [isPhone, isTablet, dispatch])

  useEffect(() => {
    checkAvailabilityToInstallExtension()
  }, [checkAvailabilityToInstallExtension])

  return {
    isInstalled: installed,
    canInstallExtension,
    browserType: getBrowserType(),
    getBrowserIcon,
  }
}
