import { Fragment } from 'react'
import { Title, Subtitle } from '../../styles'

type Props = {
  title: string
  subtitle: string
}

const Header = ({ title, subtitle }: Props) => {
  return (
    <Fragment>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </Fragment>
  )
}

export default Header
