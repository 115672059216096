import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import { CentralColumn, Section } from '../../styles'

export const Container = styled(Section)`
  background-color: ${Colors.Indigo10};
  color: ${Colors.Indigo90};
`

export const HeaderImage = styled.img`
  width: 268px;

  ${Media.Tablet`
    width: 210px;
  `};

  ${Media.Phone`
    width: 160px;
  `};
`

export const ListContainer = styled(CentralColumn)`
  display: flex;
  flex-flow: column;
  gap: 16px;
`
