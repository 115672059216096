export const yearsOfExperienceOptions = [
  { id: 1, name: '1-3 years', key: { lower_bound: 1, upper_bound: 3 } },
  { id: 2, name: '3-6 years', key: { lower_bound: 3, upper_bound: 6 } },
  { id: 3, name: '6-9 years', key: { lower_bound: 6, upper_bound: 9 } },
  { id: 4, name: '10+ years', key: { lower_bound: 7, upper_bound: 13 } },
]

export const yearsOfExperienceOptionsForOfferAnalyzer = [
  { id: 1, name: 'Fresher', key: 'Fresher' },
  { id: 2, name: '1-3 years', key: '1-3' },
  { id: 3, name: '3-6 years', key: '3-6' },
  { id: 4, name: '6-9 years', key: '6-9' },
  { id: 5, name: '10+ years', key: '10+' },
]

export function getExperinceNameById(id: number) {
  const exp = yearsOfExperienceOptionsForOfferAnalyzer.find(exp => exp.id === id)
  return exp ? exp.name : ''
}

export function getExperinceIdByName(name: string) {
  const exp = yearsOfExperienceOptionsForOfferAnalyzer.find(exp => exp.name === name)
  return exp ? exp.id : -1
}

export function getExperienceKeyByName(name: string) {
  const exp = yearsOfExperienceOptionsForOfferAnalyzer.find(exp => exp.name === name)
  return exp ? exp.key : ''
}
