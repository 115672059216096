import * as Styles from './styles'

const extensionURL = require('./assets/img/chrome_extension.svg')

export const BetaBanner = () => (
  <Styles.Container>
    <Styles.Image src={extensionURL} />
    <Styles.Text>
      This function currently supports a limited number of job titles. We are improving it by adding
      more titles every month.
    </Styles.Text>
  </Styles.Container>
)
