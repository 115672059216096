import { createImageUrlFormatter as implementation, stringify } from './image-url'

export const createImageUrlFormatter: typeof implementation = options => {
  if (process.env.NODE_ENV === 'development') {
    const base = typeof window === 'undefined' ? options.base : window.location.origin
    return (url, transformations) => {
      const parsedURL = new URL(url, base)
      parsedURL.searchParams.append(
        'cdn-cgi',
        stringify(Object.assign({}, options.defaults, transformations)),
      )
      return parsedURL.toString()
    }
  }
  return implementation(options)
}
