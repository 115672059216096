import { useMemo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as userSelectors } from 'builder/modules/user'
import { useI18n } from 'builder/hooks/useI18n'
import { actions, selectors } from 'builder/modules/resumeEditor'
import { FetchStatuses } from 'builder/modules/constants'
import { useAutoTailoring } from '../../hooks/useAutoTailoring'

const LOCALIZATION_PREFIX = 'builder.auto_tailoring.tailoring_model'

export const useAutoTailoringModal = () => {
  const isPremiumUser = useTypedSelector(userSelectors.premiumAccess)
  const status = useTypedSelector(selectors.autoTailoringFetchStatus)
  const originalResume = useTypedSelector(selectors.resume)
  const autoTailoredResume = useTypedSelector(selectors.autoTailoredResume)
  const addedKeywords = useTypedSelector(selectors.addedKeywordsForAutoTailoring)
  const { jobPostingId } = useTypedSelector(selectors.jobPostingDataForAutoTailoring) || {}
  const { jobPostingLinkForModal, saveJobPostingDataStatus } = useTypedSelector(
    selectors.optimizerSection,
  )
  const dispatch = useDispatch()
  const score = autoTailoredResume?.averageScore || 0
  const { i18n } = useI18n()
  const isLoading = status === FetchStatuses.loading || status === FetchStatuses.notAsked
  const isFailed = status === FetchStatuses.failed
  const isCompleted = status === FetchStatuses.loaded
  const { navigateToBilling } = useAutoTailoring()
  const isJobPostingSaving = saveJobPostingDataStatus === FetchStatuses.loading

  const titleText = useMemo(() => {
    if (isLoading) {
      return i18n.t(`${LOCALIZATION_PREFIX}.processing.title`)
    } else if (isFailed) {
      return i18n.t(`${LOCALIZATION_PREFIX}.error.title`)
    } else {
      return i18n.t(`${LOCALIZATION_PREFIX}.result.title`)
    }
  }, [i18n, isFailed, isLoading])

  const descriptionText = useMemo(() => {
    if (isLoading) {
      return i18n.t(`${LOCALIZATION_PREFIX}.processing.sub_title`)
    } else if (isFailed) {
      return i18n.t(`${LOCALIZATION_PREFIX}.error.sub_title`)
    } else {
      return i18n.t(`${LOCALIZATION_PREFIX}.result.sub_title`)
    }
  }, [i18n, isFailed, isLoading])

  const previewDescription = useMemo(() => {
    if (isLoading) {
      return `${LOCALIZATION_PREFIX}.processing.status_text`
    } else if (isFailed) {
      return `${LOCALIZATION_PREFIX}.error.status_text`
    } else {
      return `${LOCALIZATION_PREFIX}.result.status_text`
    }
  }, [isFailed, isLoading])

  const handleClose = useCallback(() => {
    dispatch(actions.setAutoTailoringModalStep(null))
  }, [dispatch])

  const handleRetryClick = useCallback(() => {
    if (!originalResume || !jobPostingId) return

    dispatch(
      actions.autoTailorResume({
        resumeId: originalResume.id,
        selectedKeywords: addedKeywords,
        jobPostingId,
      }),
    )
    trackInternalEvent('click_retry', {
      label: 'auto_tailoring_error_screen',
    })
  }, [addedKeywords, dispatch, jobPostingId, originalResume])

  const handleUnlock = useCallback(() => {
    navigateToBilling()
    trackInternalEvent('click_unlock_premium', {
      label: 'auto_tailoring_result_screen',
    })
  }, [navigateToBilling])

  const handleClickEditManually = useCallback(() => {
    if (!originalResume || !jobPostingLinkForModal) return
    dispatch(
      actions.startImprovingResume({
        jobPostingLink: jobPostingLinkForModal,
        resumeId: originalResume.id,
      }),
    )
    trackInternalEvent('click_edit_manually', {
      label: 'auto_tailoring_result_screen',
    })
  }, [dispatch, jobPostingLinkForModal, originalResume])

  return {
    isPremiumUser,
    isLoading,
    isFailed,
    isCompleted,
    score,
    titleText,
    descriptionText,
    previewDescription,
    handleRetryClick,
    handleClose,
    handleUnlock,
    handleClickEditManually,
    autoTailoredResume,
    isJobPostingSaving,
  }
}
