import { useRef, useState, useLayoutEffect } from 'react'
import ReactDOM from 'react-dom'
import { useI18n } from 'builder/hooks/useI18n'
import { useClickOutside } from 'builder/hooks/useClickOutside'
import { useEscapeHandler } from 'builder/hooks/useEscapeHandler'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { usePortalNode } from 'builder/hooks/usePortalNode'
import { useBodyScrollLock } from 'builder/hooks/useBodyScrollLock'
import { reflow } from 'builder/utils/reflow'
import { ActionMenuItem } from './ActionMenuItem'
import { Overlay, Container, CancelButton } from './styles'
import { Action, Align, Position } from './types'

type Props = {
  align?: Align
  position?: Position
  actions?: Array<Action | false | undefined>
  onClose?: () => void
}

export const ActionMenu = ({
  position,
  align = 'right',
  actions = [],
  onClose = () => {},
}: Props) => {
  const { i18n } = useI18n()
  const { isPhone } = useMediaQueries()
  const [isVisible, setVisible] = useState(false)
  const portalNode = usePortalNode()
  const overlayRef = useRef<HTMLDivElement | null>(null)
  const menuRef = useRef<HTMLDivElement | null>(null)
  const actionMenuPosition = position ?? 'top'

  useClickOutside(menuRef, onClose)
  useEscapeHandler(onClose)

  useLayoutEffect(() => {
    if (overlayRef.current) reflow(overlayRef.current)
    if (menuRef.current) reflow(menuRef.current)
    setVisible(true)
  }, [])

  // Disable page scrolling while mobile sheet is open
  useBodyScrollLock(overlayRef)

  // Filter out empty items
  const options = actions.filter((action): action is Action => !!action)

  // The actions menu itself
  const menu = (
    <Container
      {...{
        isVisible,
        align,
        position: actionMenuPosition,
        ref: menuRef,
      }}
    >
      {options.map((action, index) => (
        <ActionMenuItem
          {...action}
          key={index}
          divider={action.divider || (index > 0 && isPhone)}
          onAfterClick={onClose}
        />
      ))}
    </Container>
  )

  // On mobiles the menu is wrapped in an overlay, and rendered into a DOM node
  // that exists outside of the parent component's hierarchy
  if (isPhone) {
    return ReactDOM.createPortal(
      <Overlay ref={overlayRef} isVisible={isVisible}>
        {menu}
        <CancelButton isVisible={isVisible}>{i18n.t('builder.resume_editor.cancel')}</CancelButton>
      </Overlay>,
      portalNode,
    )
  }

  return menu
}
