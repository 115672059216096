import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
const { Indigo60 } = Colors

export const AIAssitedBadge = styled.div`
  padding: 4px 8px;
  border-radius: 4px;
  background: rgba(219, 222, 255, 0.5);
  width: 133px;
  white-space: nowrap;
  color: ${Indigo60};
  ${FontWeights.DemiBold};
  ${Typography.Tiny};
`

export const AIResumeHeader = styled.div`
  gap: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin-bottom: 32px;
  ${Media.Phone`
    gap: 14px;
  `}
`

export const AIResumeHeaderAlt = styled(AIResumeHeader)`
  ${Media.Phone`
    margin-bottom: 20px;
  `}
`

export const AIResumeHeaderIcon = styled.img`
  ${Media.Phone`
    width: 28px;
    height: 26px;
  `}
`
