import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

export const Container = styled.div`
  display: flex;
  width: 132px;
  height: 70px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 50px;
  background-color: ${Colors.Neutral90};
`

export const IconContainer = styled.div<{ $enableRedBackground: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background-color: ${props => (props.$enableRedBackground ? Colors.Red50 : Colors.Neutral80)};
  color: ${props => (props.$enableRedBackground ? Colors.Red50 : Colors.Neutral80)};

  & svg {
    fill: transparent;
  }

  ${props => Media.Hover`
    &:hover {
      background-color: ${props.$enableRedBackground ? Colors.Red70 : Colors.Neutral70};
      cursor: pointer;
      color: ${props.$enableRedBackground ? Colors.Red70 : Colors.Neutral70};
    }
  `};
`
