import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const MainContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${Media.Phone`
    flex-direction: column;
  `}
`

export const Container = styled.div`
  position: relative;
  background: ${Colors.Neutral5};
  border-radius: 12px;
  height: 68px;
  width: 549px;
`

export const Button = styled.button<{
  isClicked?: boolean
  isHovered?: boolean
  isTabWidth?: number
}>`
  height: 60px;
  width: ${props =>
    props.isTabWidth === 0
      ? '174px'
      : props.isTabWidth === 1
      ? '191px'
      : props.isTabWidth === 2
      ? '172px'
      : '174px'};
  position: relative;
  left: 0.73%;
  right: 67.34%;
  top: 5.88%;
  cursor: pointer;
  bottom: 5.88%;
  border: none;
  margin-right: 2px;
  background: ${props =>
    props.isClicked ? Colors.White : props.isHovered ? Colors.Neutral10 : Colors.Neutral5};
  border-radius: 8px;
  box-shadow: ${props =>
    props.isClicked &&
    `0px 2px 4px 0px rgba(15, 56, 113, 0.08), 0px 1px 2px 0px rgba(15, 56, 113, 0.12)`};
`

export const Divider = styled.div<{ isResonsive: boolean }>`
  height: 36px;
  width: 0px;
  position: absolute;
  right: ${props => (props.isResonsive ? 30.1 : 67.34)}%;
  left: ${props => (props.isResonsive ? 178 : 369)}px;
  top: 23.53%;
  border: 2px solid #eff2f9;
`

export const Text = styled.div<{ isClicked?: boolean; isHovered?: boolean }>`
  ${Typography.Subhead};
  ${FontWeights.Medium};
  color: ${props => (props.isClicked || props.isHovered ? Colors.Neutral90 : Colors.Neutral50)};
`
