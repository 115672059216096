import { useState, useEffect } from 'react'

export function useKeyPress(targetKey: string | number) {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false)

  useEffect(() => {
    // If pressed key is our target key then set to true
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === targetKey || e.keyCode === targetKey) setKeyPressed(true)
    }

    // If released key is our target key then set to false
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === targetKey || e.keyCode === targetKey) setKeyPressed(false)
    }

    // Bind/unbind event listeners
    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [targetKey])

  return keyPressed
}
