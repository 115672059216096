import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Sizes from 'builder/styles/sizes'
import Typography, { FontWeights } from 'builder/styles/typography'

export const SubHead = styled.div`
  display: flex;
  gap: 6px;
  color: ${Colors.Neutral90};
  ${Typography.Subhead}
  ${FontWeights.Medium}
`
export const TimeIcon = styled.div`
  & svg {
    width: 24px;
    height: 24px;
  }
  & path {
    stroke: ${Colors.Neutral60};
  }
`

export const EditAltContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${Colors.Neutral5};
  color: ${Colors.Neutral50};
  & svg {
    flex-shrink: 0;
  }
  & path {
    stroke: ${Colors.Neutral50};
  }
`

export const Ellipsis = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${Media.Phone`
    width: 100%;
  `}
`
export const Body = styled.div`
  display: flex;
  gap: 4px;
  color: ${Colors.Neutral60};
  ${Typography.Body}
  ${FontWeights.Regular}
  display: flex;
  overflow: hidden;
  & svg {
    flex-shrink: 0;
  }

  ${Media.Phone`
    align-items: center;
    width: 100%;
  `}
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: start;
  gap: 12px;
  border-radius: ${Sizes['2XS']};
  border: 1px solid ${Colors.Neutral15};
  background: ${Colors.White};
  width: 100%;
  &:hover {
    border: 1px solid ${Colors.Blue50};
  }
  padding: 20px;

  ${Media.Phone`
    padding: 16px;
  `}

  &:hover ${EditAltContainer} {
    background-color: ${Colors.Blue10};
    color: ${Colors.Blue50};
  }

  &:hover ${EditAltContainer} path {
    stroke: ${Colors.Blue50};
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`
export const ContainerText = styled.div`
  display: flex;
  padding-top: 12px;
  flex-wrap: wrap;
  gap: 16px 32px;
  ${Media.Phone`
    padding-top: 10px;
    flex-direction:   column;
    margin-right: 0px;
    gap: 8px;
  `}
`
