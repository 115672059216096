export const JobFunctions = [
  {
    name: 'Accounting',
    checked: false,
  },
  {
    name: 'Advertising',
    checked: false,
  },
  {
    name: 'Art & Design',
    checked: false,
  },

  {
    name: 'Education',
    checked: false,
  },
  {
    name: 'Engineering',
    checked: false,
  },
  {
    name: 'Finance',
    checked: false,
  },
  {
    name: 'Franchising',
    checked: false,
  },
  {
    name: 'Human Resources',
    checked: false,
  },
  {
    name: 'Marketing',
    checked: false,
  },
  {
    name: 'Operations',
    checked: false,
  },
  {
    name: 'PR/Communications',
    checked: false,
  },
  {
    name: 'Sales',
    checked: false,
  },
  {
    name: 'Support',
    checked: false,
  },
  {
    name: 'Technology',
    checked: false,
  },
  {
    name: 'Other',
    checked: false,
  },
]
