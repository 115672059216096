import { FC, useState, useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import type { Optional } from '@rio/types'
import { actions as resumeEditorActions } from 'builder/modules/resumeEditor'
import { actions as uiActions } from 'builder/modules/ui'
import ModalOverlay from 'builder/components/ModalOverlay'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import Icon from 'builder/components/Icon'
import { CloseButton, Container } from './styles'
import { useCatalog } from './hooks'
import ExamplePreview from './ExamplePreview'
import ExamplesCatalog from './ExamplesCatalog'

interface PrefillModalProps {
  documentId: number
}

const PrefillModal: FC<PrefillModalProps> = ({ documentId }) => {
  const dispatch = useDispatch()
  const media = useMediaQueries()
  const [categories, examples] = useCatalog()

  const [activeExampleId, setActiveExampleId] = useState<Optional<number>>(null)
  const [activeExampleIndex, setActiveExampleIndex] = useState<number>(0)

  // Returns merged selected example's and related category data
  const previewData = useMemo(() => {
    const example = examples.find(({ id }, index) => {
      if (id !== activeExampleId) return false
      setActiveExampleIndex(index)
      return true
    })

    const category = categories.find(({ id }) => id === example?.exampleCategoryId)

    if (example && category) {
      return { example, category }
    }

    return null
  }, [examples, categories, activeExampleId])

  // Checks if selected example is first
  const isFirstActive = useMemo(
    () => examples.length > 0 && activeExampleIndex === 0,
    [examples, activeExampleIndex],
  )

  // Checks if selected example is last
  const isLastActive = useMemo(
    () => examples.length > 0 && activeExampleIndex === examples.length - 1,
    [examples, activeExampleIndex],
  )

  // Return to catalog, resets active example
  const handlePreviewClose = useCallback(() => setActiveExampleId(null), [])

  // Close modal window
  const handleModalClose = useCallback(() => {
    dispatch(uiActions.setPrefillModalDocumentId(null))
  }, [dispatch])

  // Dispatch action with selected example id and document id from modal's props
  const prefill = useCallback(
    (exampleId: number) => {
      dispatch(resumeEditorActions.prefillResume({ documentId, exampleId }))
    },
    [dispatch, documentId],
  )

  // Show previous example
  const handlePrevExample = useCallback(() => {
    if (!isFirstActive) {
      setActiveExampleId(examples[activeExampleIndex - 1].id)
    }
  }, [examples, isFirstActive, activeExampleIndex])

  // Show next example
  const handleNextExample = useCallback(() => {
    if (!isLastActive) {
      setActiveExampleId(examples[activeExampleIndex + 1].id)
    }
  }, [examples, isLastActive, activeExampleIndex])

  return (
    <ModalOverlay
      fullScreen={media.isPhone}
      onClick={handleModalClose}
      overlayFadeDuration={250}
      contentSlideDuration={0}
    >
      <Container>
        <CloseButton onClick={handleModalClose}>
          <Icon.CloseLarge />
        </CloseButton>

        {previewData ? (
          <ExamplePreview
            {...previewData}
            isLast={isLastActive}
            isFirst={isFirstActive}
            onNextClick={handleNextExample}
            onPrevClick={handlePrevExample}
            onClose={handlePreviewClose}
            onSelect={prefill}
          />
        ) : (
          <ExamplesCatalog
            examples={examples}
            categories={categories}
            onExampleSelect={setActiveExampleId}
          />
        )}
      </Container>
    </ModalOverlay>
  )
}

export default PrefillModal
