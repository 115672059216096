import { useNavigate } from 'react-router-dom'
import { useI18n } from 'builder/hooks/useI18n'

import Icon24, { Icon16 } from 'builder/components/Icon'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useAutoApply } from 'builder/views/AutoApply/hooks/useAutoApply'
import { selectors as jobSearchSelectors } from 'builder/modules/jobSearch'

import { AutoApplyGuide } from 'builder/components/FindJob/AutoApplyGuide/AutoApplyGuide'
import { ChromeExtensionButton } from '../ChromeExtensionButton/ChromeExtensionButton'

import {
  AutoApplyButton,
  AutoApplyContainer,
  Bubble,
  ButtonContainer,
  Container,
  IconContainer,
  SettingIconContainer,
} from './styles'

export const JobTrackerHeader = () => {
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const autoApplyBubbles = useTypedSelector(jobSearchSelectors.autoApplyBubbles)
  const { isUserOnAutoApplyPlan, isAutoApplyFeatureEnabled, getCreditCount } = useAutoApply()

  const handleSettingClick = () => {
    navigate(`/auto-apply/personal-info`)
  }

  return (
    <Container>
      <IconContainer>
        <Icon16.Columns />
        {i18n.t(`builder.job_tracking.board_title`)}
      </IconContainer>
      <ButtonContainer>
        {autoApplyBubbles.showAutoApplyGuideButton && <AutoApplyGuide />}
        <AutoApplyContainer>
          {isUserOnAutoApplyPlan && (
            <>
              <AutoApplyButton>
                <Icon24.FeaturedIcon />
                {i18n.t(`builder.job_tracking.auto_apply_button`)}
              </AutoApplyButton>

              <Bubble>{getCreditCount()}</Bubble>
            </>
          )}
          {isAutoApplyFeatureEnabled && (
            <Bubble>
              <SettingIconContainer onClick={handleSettingClick}>
                <Icon16.SettingIcon />
              </SettingIconContainer>
            </Bubble>
          )}
        </AutoApplyContainer>
        {isAutoApplyFeatureEnabled && <ChromeExtensionButton type="job_tracker" />}
      </ButtonContainer>
    </Container>
  )
}
