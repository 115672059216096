import isThisYear from 'date-fns/isThisYear'
import { BriefExpertDoc, BriefLetter, BriefResume } from 'builder/modules/panel'
import { formatDate, parseDate } from 'builder/utils/formatDate'

export const formatUpdatedAt = (document: BriefResume | BriefLetter | BriefExpertDoc): string => {
  const date = parseDate(document.updatedAt || document.createdAt)
  const format = isThisYear(date) ? 'd MMMM, HH:mm' : 'd MMMM yyyy, HH:mm'

  return formatDate(date, format)
}
