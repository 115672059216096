import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  user-select: none;
`

export const Field = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover,
  &:focus {
    color: ${Colors.Blue50};
  }
`

const mapLocaleToBackground = locale => {
  // Order of flags in flags.png
  const order = [
    'en',
    'nl-NL',
    'de-DE',
    'es-ES',
    'sv-SE',
    'da',
    'nb',
    'fr-FR',
    'pt-PT',
    'ru',
    'it',
    'pl',
    'fi',
    'cs',
    'pt-BR',
    'es-MX',
    'ro',
    'af',
    'he',
    'hi',
    'hu',
    'zh-HK',
    'en-GB',
    'ko-KR',
    'ja-JP',
    'gr',
    'en-NZ',
    'de-CH',
    'en-IE',
    'de-AT',
    'bg',
    'sr',
    'es-CL',
    'vi',
  ]
  const index = order.findIndex(el => el === locale)

  if (index < 0)
    return css`
      background-image: url(${require('images/builder/shared/unknown.png')});
    `

  return css`
    background-image: url(${require('images/builder/shared/flags.png')});
    background-position: ${index * -19}px 0;
  `
}

export const Flag = styled.div`
  width: 19px;
  height: 12px;
  background-size: auto 12px;
  ${props => mapLocaleToBackground(props.locale)};
  margin-right: 8px;
  opacity: ${props => (props.isSelected ? 0.5 : 1)};
`

export const Menu = styled.div`
  top: -16px;
  left: 50%;
  position: absolute;
  border-radius: 4px;
  background-color: ${Colors.Neutral100};
  z-index: 3;
  opacity: 0;
  transform: translateX(-50%) scale(1, 0.3);
  pointer-events: ${props => (props.in === 'entered' ? 'auto' : 'none')};
  transform-origin: top center;
  transition: transform 0.14s ease, opacity 0.14s;

  ${props =>
    ({
      entering: 'opacity: 1; transform: translateX(-50%) scale(1, 1);',
      entered: 'opacity: 1; transform: translateX(-50%) scale(1, 1);',
      exiting:
        'opacity: 0; transform: translateX(-50%) scale(1, 1); transition: opacity 0.09s ease;',
    }[props.in])};
`

export const Title = styled.div`
  white-space: nowrap;
  color: ${Colors.White};
  ${Typography.Caption};
  font-weight: 600;
  padding: 16px 20px 8px;
`

export const Scroll = styled.div`
  width: 100%;
  max-height: 174px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 12px;
    background-color: transparent;
    border-right: 2px solid transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${Colors.Neutral70};
    border: 4px solid ${Colors.Neutral100};
    border-radius: 6px;

    &:hover {
      background-color: ${Colors.Neutral30};
    }
  }
`

export const List = styled.div`
  padding-bottom: 8px;
`

export const Item = styled.div`
  ${Typography.Caption};
  color: ${props => (props.isSelected ? Colors.Neutral70 : Colors.White)};
  padding: 4px 0 4px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    color: ${Colors.Blue50};
  }
`
