interface TimePeriod {
  period: string
  timeRange: string
  checked: boolean
}

export const availableTime: TimePeriod[] = [
  {
    period: 'Morning',
    timeRange: '9am - 12pm',
    checked: false,
  },
  {
    period: 'Afternoon',
    timeRange: '12pm - 3pm',
    checked: false,
  },
  {
    period: 'Late Afternoon',
    timeRange: '3pm - 6pm',
    checked: false,
  },
  {
    period: 'Evening',
    timeRange: '6pm - 9pm',
    checked: false,
  },
]
