export const PdfIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 3.2998C5.83726 3.2998 5.3 3.83706 5.3 4.4998V6.9998H4C3.44772 6.9998 3 7.44752 3 7.9998V15.9998C3 16.5521 3.44772 16.9998 4 16.9998H5.3V19.4998C5.3 20.1625 5.83726 20.6998 6.5 20.6998H17.5C18.1627 20.6998 18.7 20.1625 18.7 19.4998V7.70691C18.7 7.38865 18.5736 7.08343 18.3485 6.85838L15.1414 3.65128C14.9164 3.42623 14.6112 3.2998 14.2929 3.2998H6.5ZM6.7 16.9998V19.2998H17.3V7.78975L14.2101 4.6998H6.7V6.9998H12C12.5523 6.9998 13 7.44752 13 7.9998V15.9998C13 16.5521 12.5523 16.9998 12 16.9998H6.7ZM5.25 10.4998C5.25 9.80945 5.80964 9.2498 6.5 9.2498H9.5C10.1904 9.2498 10.75 9.80945 10.75 10.4998V11.9998C10.75 12.6902 10.1904 13.2498 9.5 13.2498H6.75V14.4998C6.75 14.914 6.41421 15.2498 6 15.2498C5.58579 15.2498 5.25 14.914 5.25 14.4998V12.4998V10.4998ZM6.75 11.7498H9.25V10.7498H6.75V11.7498Z"
      fill="#DA0C22"
    />
  </svg>
)
