import styled from 'styled-components'
import { TextField } from 'builder/components/TextField'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Field = styled.div`
  margin-bottom: 16px;
`

export const LabelWithTooltip = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Label = styled.label`
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
  margin-bottom: 4px;
`

export const TextFieldContainer = styled(TextField)`
  width: 100%;
`
export const SkillError = styled.div`
  color: ${Colors.Orange50};
  ${Typography.Caption};
  ${FontWeights.Regular};
`
