import { useI18n } from 'builder/hooks/useI18n'
import { AutoApplyNotificationState } from '../../../modules/jobTracking'
import {
  NotificationParentContainer,
  NotificationContainer,
  NotificationContent,
  TickIcon,
  CircleErrorIcon,
} from './styles'

export const CardAutoApplyNotification = ({ message }: { message: AutoApplyNotificationState }) => {
  const { i18n } = useI18n()
  return (
    <NotificationParentContainer>
      <NotificationContainer>
        <NotificationContent>
          {message === AutoApplyNotificationState.processing_by_agent ? (
            <CircleErrorIcon />
          ) : (
            <TickIcon />
          )}
          {i18n.t(`builder.job_tracking.auto_apply.notification.${message}`)}
        </NotificationContent>
      </NotificationContainer>
    </NotificationParentContainer>
  )
}
