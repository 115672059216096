import axios from 'axios'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { authService } from 'builder/services/AuthService'
import { authRedirectService } from 'builder/services/AuthRedirectService'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import { API_BASE_URL, APP_BASE_PATH } from 'builder/modules/constants'
import { AccountApiSuccessResponse } from 'builder/modules/user'
import { Spinner } from 'builder/components/Spinner'
import { goToBillingPlanPage } from 'builder/utils/goToBillingPlanPage'
import * as Styled from './styles'

type Access = 'free' | 'premium'
type Funnel = 'sign-in' | 'create-account'

export const AccessView = () => {
  const navigate = useNavigate()
  const [params] = useSearchParams()

  // The page user wants to use
  const targetUrl = params.get('target_url') || APP_BASE_PATH

  // Which access level is needed to use the target page
  const requiredAccess = (params.get('required_access') || 'free') as Access

  // Force displaying different sign-in or sign-up pages for unauthorized/new users
  const funnel = (params.get('funnel') || 'sign-in') as Funnel

  const openFunnelPage = () => {
    // Save the current page URL to open it back after successful logging in / signing up
    // and perform the access checks again
    authRedirectService.persistPath(window.location.pathname + window.location.search)
    // Open proper funnel page
    const funnelPath = funnel === 'sign-in' ? `/auth/${funnel}` : `/${funnel}`
    navigate(funnelPath, { replace: true })
  }

  const openPlansPage = () => {
    goToBillingPlanPage({ onSuccessRedirect: targetUrl })
  }

  const openTargetPage = () => {
    window.location.assign(targetUrl)
  }

  // Start checking a user session and access after the view is mounted
  useEffectOnMount(() => {
    // Try to read the token from local storage (might be empty in user)
    const token = authService.getAccessToken()

    // No access token found: user needs to log in or create a new account
    if (!token) return openFunnelPage()

    // Use existing access token to request account info
    axios
      .get<AccountApiSuccessResponse>('/account', {
        baseURL: API_BASE_URL,
        headers: { Authorization: `Bearer ${token}` },
      })
      // Access token is still valid and we got the user data
      .then(({ data }) => {
        // Read current user access level type
        const currentAccess: Access = data.user?.hasPremiumFeatures ? 'premium' : 'free'

        // User is authorized but doesn't have enough access
        if (requiredAccess === 'premium' && currentAccess !== 'premium') return openPlansPage()

        // User is authorized and the requested content doesn't require premium
        openTargetPage()
      })
      // Access token is invalid or session is expired
      .catch(() => {
        openFunnelPage()
      })
  })

  // Display the spinner while checking
  return (
    <Styled.Container>
      <Spinner />
    </Styled.Container>
  )
}
