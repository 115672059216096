import styled, { css } from 'styled-components'
import Media from 'builder/styles/media'
import { Optional } from '../../../../../../../packages/types'
import { Cards, PromotionTopic } from '../../../common/types'
import usePromotionCardValues from '../../../hooks/usePromotionCardValues'

interface Props {
  activeCard: Optional<Cards>
  topic: PromotionTopic
  isNlLocale: boolean
}

export const PromotionContainerV7 = styled.div<Props>(({ topic }) => {
  const { getCardStyles } = usePromotionCardValues()
  const cardStyles = getCardStyles(topic)

  return css`
    display: flex;
    width: 100%;
    max-width: 928px;
    height: 272px;
    background-color: ${cardStyles.backgroundColor};
    border-radius: 16px;
    justify-content: center;
    align-items: center;
    position: relative;

    ${Media.AboveTablet`
      margin-top: 24px;
    `}

    ${Media.Tablet`
      flex-direction: column-reverse;
      height: 100%;
      padding: 40px;
    `}
    
    ${Media.Phone`
      flex-direction: column-reverse;
      height: 100%;
      max-width: 335px;
      padding: 20px;
    `}
  `
})
