import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Icon20 from 'builder/components/Icon'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  max-width: 544px;
  background: ${Colors.White};
  border-radius: 16px;
  position: relative;

  ${Media.Phone`
    border-radius: 12px;
  `}
`

export const Illustration = styled.img`
  width: 100%;
  border-radius: 16px 16px 0 0;

  ${Media.Phone`
    border-radius: 12px 12px 0 0;
  `}
`

export const Content = styled.div`
  padding: 32px;

  ${Media.Phone`
    padding: 20px;
  `}
`

export const Label = styled.div`
  ${FontWeights.DemiBold};
  width: fit-content;
  padding: 4px 12px;
  border-radius: 40px;
  margin-bottom: 12px;
  color: ${Colors.Neutral60};
  background: ${Colors.Neutral10};
`

export const Title = styled.div`
  ${Typography.S};
  ${FontWeights.DemiBold};
  margin-bottom: 8px;

  ${Media.Phone`
    margin-bottom: 4px;
  `}
`

export const Text = styled.div`
  color: ${Colors.Neutral50};
  margin-bottom: 24px;

  ${Media.Phone`
    margin-bottom: 20px;
  `}
`

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const CloseIcon = styled(Icon20.Close)`
  color: ${Colors.Neutral50};
`

export const Close = styled.div`
  width: 28px;
  height: 28px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 32px;
  right: 32px;
  background: ${Colors.White};

  &:hover ${CloseIcon} {
    color: ${Colors.Blue50};
  }

  ${Media.Phone`
    top: 20px;
    right: 20px;
  `}
`
