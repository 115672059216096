import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import { Icon24 } from 'builder/components/Icon'
import Typography from 'builder/styles/typography'
import { TextField } from 'builder/components/TextField'
import Tooltip from 'builder/components/Tooltip/Tooltip'

export const Container = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
`

export const Wrapper = styled.div<{ isError: boolean }>`
  gap: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: ${props => (props.isError ? 96 : 72)}px;

  ${props =>
    props.isError &&
    css`
      margin-block: 10px;

      &:first-child {
        margin-block-start: 0;
      }
    `}
`

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const Title = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
`

export const TipsTitle = styled.div`
  ${Typography.Caption};
  color: ${Colors.Blue50};

  &:hover {
    color: ${Colors.Blue60};
  }
`

export const HintIcon = styled(Icon24.HintOutlined)`
  width: 20px;
  height: 20px;
  color: ${Colors.Blue50};

  &:hover {
    color: ${Colors.Blue60};
  }
`

export const TipsContainer = styled.div`
  width: 50px;
  cursor: pointer;
  gap: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    ${TipsTitle} {
      color: ${Colors.Blue60};
    }

    ${HintIcon} {
      color: ${Colors.Blue60};
    }
  }
`

export const TextFieldContainer = styled(TextField)`
  width: 100%;
`

export const TooltipQuestionWrapper = styled(Tooltip)`
  & > div {
    width: 315px;
    left: auto;
    right: 7px;

    & > svg {
      left: -15px;
      right: auto;
      color: ${Colors.Neutral90};
    }

    .hint-icon {
      color: ${Colors.Blue50};

      &:hover {
        color: ${Colors.Blue60};
      }
    }
  }

  .tips-container {
    width: auto;
  }
`

export const TooltipQuestionWrapperTablet = styled(Tooltip)`
  & > div {
    width: 315px;

    .hint-icon {
      color: ${Colors.Blue50};

      &:hover {
        color: ${Colors.Blue60};
      }
    }
  }

  .tips-container {
    width: auto;
  }
`

export const ToolTip = styled.img`
  width: 50px;
  cursor: pointer;
  height: 20px;
  margin-bottom: 10px;
`
