import { useDispatch } from 'react-redux'
import { useCallback, useEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { trackingInfo } from 'builder/modules/careerCoaching/constants'
import { SuggestedIndustry, actions, selectors } from 'builder/modules/careerCoaching'
import { Title, Container, SuggestedIndustriesText } from './styles'

interface SuggestedSkillsProps {
  handleIndustriesChange: (industries: SuggestedIndustry[]) => void
}

export const SuggestedSkills = ({ handleIndustriesChange }: SuggestedSkillsProps) => {
  const dispatch = useDispatch()
  const targetedIndustries = useTypedSelector(selectors.targetedIndustries)
  const suggestedIndustries = useTypedSelector(selectors.suggestedIndustries)

  const handleClick = useCallback(
    (industry: SuggestedIndustry) => {
      trackInternalEvent('select_industry', { ...trackingInfo, industry_name: industry.label })
      const selectedIndex = targetedIndustries.findIndex(item => item.value === industry.value)

      if (targetedIndustries.length === 0 || selectedIndex === -1) {
        dispatch(actions.setTargetedIndustries([...targetedIndustries, industry]))
        handleIndustriesChange([...targetedIndustries, industry])
      }
    },
    [dispatch, handleIndustriesChange, targetedIndustries],
  )

  useEffect(() => {
    dispatch(actions.fetchSuggestedIndustries())
  }, [dispatch])

  return (
    <Container>
      <SuggestedIndustriesText>Suggested industries:</SuggestedIndustriesText>
      {suggestedIndustries?.map((industry: SuggestedIndustry) => {
        return (
          <Title
            key={industry?.value}
            onClick={() => {
              handleClick(industry)
            }}
          >
            {industry?.label}
          </Title>
        )
      })}
    </Container>
  )
}
