import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Button, { ButtonSize, ButtonTheme } from 'builder/components/Button'

export const Container = styled.div`
  border-radius: 24px;
  border: 1px solid ${Colors.Neutral15};
  display: flex;
  flex-direction: column;
`

export const ActionContainer = styled.div<{ $isDisabled?: boolean }>`
  margin: 36px 31px 31px;
  position: relative;

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      margin: 72px 31px 31px;
    `}
`

export const ActionBtn = styled(Button).attrs({
  theme: ButtonTheme.ghost,
  size: ButtonSize.small,
})`
  width: 100%;
`
