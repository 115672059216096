import { useDispatch } from 'react-redux'
import { useEffect, useMemo } from 'react'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import {
  selectors as critiqueSelectors,
  actions,
  ResumeCritiqueStatuses,
} from 'builder/modules/resumeCritiqueReview'
import {
  selectors as reviewSelectors,
  actions as reviewActions,
  checkIfCritiqueExpired,
} from 'builder/modules/resumeReview'
import { IFRAME_PARAMS } from 'builder/modules/resumeCritiqueReview/constants'
import * as Styles from './styles'
const emptyImage = require('images/builder/panel/resume-review-status/empty_card.svg')

interface Props {
  isReviewPage: boolean
}

const CritiqueReview = ({ isReviewPage }: Props) => {
  const { i18n } = useI18n()
  const { isPhone } = useMediaQueries()
  const dispatch = useDispatch()
  const tunerResumeId = useTypedSelector(critiqueSelectors.tunerResumeId)
  const TriggeredCritiqueStatus = useTypedSelector(critiqueSelectors.triggeredCritiqueStatus)
  const critiques = useTypedSelector(reviewSelectors.critiques)

  useEffect(() => {
    if (critiques?.length < 1) {
      dispatch(reviewActions.fetchCritiques())
    }
  }, [critiques?.length, dispatch])

  useEffect(() => {
    let critiqueInterval: NodeJS.Timeout
    critiqueInterval = setInterval(() => {
      if (TriggeredCritiqueStatus?.status === ResumeCritiqueStatuses.pending && tunerResumeId) {
        dispatch(actions.fetchCritiqueReviewStatus({ resumeId: tunerResumeId }))
      } else {
        clearInterval(critiqueInterval)
      }
    }, 30000)
    return () => clearInterval(critiqueInterval)
  }, [TriggeredCritiqueStatus?.status, dispatch, tunerResumeId])

  const viewResumeReview = (url?: string) => {
    if (url) {
      dispatch(actions.setCritiquePageVisitedCount(true))
      window.open(`${url}${IFRAME_PARAMS}`)
    }
  }

  const isCritiquePageCount = useTypedSelector(critiqueSelectors.isCritiquePageCount)
  const visitedBefore = isCritiquePageCount ? 'true' : 'false'
  localStorage.setItem('visitedBefore', visitedBefore)

  const CritiqueResumeReviewData = useTypedSelector(critiqueSelectors.triggeredCritiqueStatus)
  const status =
    CritiqueResumeReviewData.status === ResumeCritiqueStatuses.failed ||
    CritiqueResumeReviewData.status === ResumeCritiqueStatuses.pending

  const isCritiqueExpired = useMemo(
    () => checkIfCritiqueExpired(CritiqueResumeReviewData.createdAt),
    [CritiqueResumeReviewData.createdAt],
  )

  if (isCritiqueExpired) return <></>

  return (
    <Styles.Container isCritiquePage={isReviewPage}>
      {!isPhone && (
        <>
          <Styles.PopupPhoto
            src={status ? emptyImage : CritiqueResumeReviewData.reviewer?.photoUrl}
          />
          <Styles.PopupIconWrapper>
            <Styles.PopupIcon />
          </Styles.PopupIconWrapper>
        </>
      )}
      {isReviewPage ? (
        <Styles.Title>
          {status
            ? i18n.t('builder.panel.critique_review_pending_state.title')
            : i18n.t('builder.panel.critique_review_success_state.title')}
        </Styles.Title>
      ) : (
        <Styles.Title>We have already wrapped your resume in the winning template!</Styles.Title>
      )}
      {isReviewPage ? (
        <Styles.Text>
          {status
            ? i18n.t('builder.panel.critique_review_pending_state.text')
            : i18n.t('builder.panel.critique_review_success_state.text', {
                critiqueName: CritiqueResumeReviewData.reviewer?.name,
              })}
        </Styles.Text>
      ) : (
        <Styles.Text>
          Check your personalized tips below by resume expert{' '}
          {CritiqueResumeReviewData.reviewer?.name} on how else you can improve your uploaded resume
        </Styles.Text>
      )}
      {isReviewPage && (
        <Styles.ReviewButton
          disabled={status}
          onClick={() => viewResumeReview(CritiqueResumeReviewData.iframeUrl)}
        >
          {status
            ? i18n.t('builder.panel.critique_review_pending_state.button')
            : i18n.t('builder.panel.critique_review_success_state.button')}
          {!status && <Styles.RightArrow />}
        </Styles.ReviewButton>
      )}
    </Styles.Container>
  )
}

export default CritiqueReview
