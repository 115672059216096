import * as Styles from './styles'

const extensionURL = require('./assets/img/Featured.svg')

export const BetaBanner = () => (
  <Styles.Container>
    <Styles.Image src={extensionURL} />
    <Styles.Text>
      Don’t worry about getting these answers perfect. You can always adjust industries and other
      parameters later from the filter
    </Styles.Text>
  </Styles.Container>
)
