import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Button from 'builder/components/Button'

export const MobileAnimatedContainer = styled.div`
  top: 90px;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 533;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.White};
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  max-width: 544px;
  padding: 32px;

  ${Media.Phone`
    height: 100%;
  `};
`

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  ${Typography.S};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
  padding-bottom: 12px;
`

export const Subtitle = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
  padding-bottom: 32px;
`

export const ContainerFields = styled.div`
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background: ${Colors.White};
`

export const Field = styled.div`
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${Colors.Neutral60};
`

export const DateText = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Body = styled.div`
  ${Media.Phone`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
  `};
`
export const RadioLabel = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const LabelContainer = styled.div`
  display: flex;
  gap: 8px;
  margin: 24px 0px;
`
export const RadioGroupContainer = styled.div`
  overflow-y: auto;
  padding-right: 5px;
  max-height: 300px;
  ${Media.Phone`
    max-height: 100%;
  `};
`
export const ButtonContainer = styled.div`
  padding-top: 24px;
  display: flex;
  justify-content: end;
  align-items: baseline;
  gap: 8px;

  ${Media.Phone`
  flex-direction: column;
    width: 100%;
    flex-direction: column-reverse;

  `};
`

export const CustomButton = styled(Button)`
  ${Media.Phone`
    width: 100%;
  `};
`
export const Close = styled.div`
  color: ${Colors.Neutral50};
  background-color: ${Colors.Neutral10};
  display: flex;
  border-radius: 100%;
  align-items: center;
  width: 28px;
  height: 28px;
  justify-content: center;
  cursor: pointer;

  &:hover {
    color: ${Colors.Blue50};
    background-color: ${Colors.Blue10};
  }
`

export const LoadingItem = styled.div`
  display: flex;
  width: 100%;
  height: 74px;
  flex-shrink: 0;
  background: linear-gradient(180deg, ${Colors.Neutral5} 0%, rgba(247, 249, 252, 0) 42.81%);
`
