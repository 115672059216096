import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'

import { useI18n } from 'builder/hooks/useI18n'
import { actions as renderingActions } from 'builder/modules/rendering'
import useWebsiteHost from 'builder/components/CIO-Dasboard-2.0/hooks/useWebsiteHost'

import { TRANSLATION } from '../constants'
import * as Styles from './styles'

export const EmptyState = () => {
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const { getHost } = useWebsiteHost()

  const onClickButton = () => {
    trackInternalEvent('aa_find_more_jobs', { website_host: getHost })
    navigate('/job-search')
  }

  return (
    <Styles.Container>
      <Styles.Box />
      <Styles.Box />
      <Styles.TextContainer>
        <Styles.Header>{i18n.t(`${TRANSLATION}.empty_state_header`)}</Styles.Header>
        <Styles.Action onClick={onClickButton}>
          {i18n.t(`${TRANSLATION}.empty_state_button`)}
        </Styles.Action>
      </Styles.TextContainer>
    </Styles.Container>
  )
}

export const BuyCreditsState = ({ credits }: { credits: string }) => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const { getHost } = useWebsiteHost()

  const onClickBuy = () => {
    trackInternalEvent('aa_buy_more_credits', { website_host: getHost })
    dispatch(renderingActions.showRDUpsellModal())
  }

  return (
    <Styles.CreditsContainer>
      <Styles.Credits>
        <Styles.CreditText>{i18n.t(`${TRANSLATION}.credits`)}</Styles.CreditText>
        <Styles.CreditCount>{credits}</Styles.CreditCount>
      </Styles.Credits>
      <Styles.Header>{i18n.t(`${TRANSLATION}.buy_state_text`)}</Styles.Header>
      <Styles.Action onClick={onClickBuy}>
        {i18n.t(`${TRANSLATION}.buy_state_button`)}
      </Styles.Action>
    </Styles.CreditsContainer>
  )
}
