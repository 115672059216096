import styled, { keyframes } from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import { Typography, FontWeights } from 'builder/styles/typography'
import Button from 'builder/components/Button'
import Shadows from 'builder/styles/shadows'
import { FeatureIcon } from 'builder/components/FeatureIcon'
import { TextField } from 'builder/components/TextField'
import { Icon24 } from 'builder/components/Icon'
import blueDot from './assets/images/blueDot.svg'
import purpleDot from './assets/images/purpleDot.svg'

// Types
interface CardContainerInterface {
  slide: number
}

interface CardProps {
  cardId: number
  prevSlide: number
  currentSlide: number
}

interface SectionTitleProps {
  slide: number
}

interface SectionInnerProps {
  prevSlide: number
  currentSlide: number
}

interface Props {
  isActive: boolean
}

interface SectionProps {
  slide: number
}

// Components
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
`
export const CardContainer = styled.div<CardContainerInterface>`
  width: 544px;
  height: 560px;
  background-color: ${Colors.Indigo10};
  border: none;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;

  padding: ${({ slide }) =>
    slide === 1
      ? '187px 123px 188px 122px'
      : slide === 2
      ? '55px 123px 55px 122px'
      : '140px 123px 141px 122px'};

  ${Media.Phone`
    display: none;
  `};

  ${Media.Tablet`
    display: none;
  `};
`
export const Card = styled.div<{ card: CardProps }>`
  ${Shadows.template.low};

  border: none;
  opacity: ${({ card }) => (card.currentSlide === 1 ? (card.cardId === 1 ? 0 : '') : '')};
  width: ${({ card }) =>
    card.currentSlide === 3 ? (card.cardId === 2 ? '278px' : '299px') : '299px'};
  height: ${({ card }) => (card.cardId === 1 ? '217px' : '185px')};
  position: absolute;
  border-radius: 16px;
  background-color: ${Colors.White};

  transition: all 0.5s ease;
  transform: ${({ card }) =>
    card.prevSlide === 1 && card.currentSlide === 2
      ? card.cardId === 1
        ? 'translateY(-120px)'
        : 'translateY(120px)'
      : card.prevSlide === 2 && card.currentSlide === 3
      ? card.cardId === 1
        ? 'translateY(55px)'
        : 'translateY(-20px)'
      : card.prevSlide === 3 && card.currentSlide === 2
      ? card.cardId === 1
        ? 'translateY(-120px)'
        : 'translateY(120px)'
      : ''};
  z-index: ${({ card }) =>
    card.prevSlide === 2 && card.currentSlide === 3 ? (card.cardId === 1 ? 1 : 0) : ''};
`
export const CardTitle = styled.div`
  ${Typography.Subhead};

  height: 72px;
  display: flex;
  align-items: center;
  color: ${Colors.Neutral90};
  justify-content: space-between;

  padding-top: 24px;
  padding-left: 24px;
  padding-bottom: 20px;
  padding-right: 24px;
  border-bottom: 1px solid ${Colors.Neutral15};
`
export const CardBody = styled.ul`
  ${Typography.Body};

  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 8px;
  width: 100%;
  height: 112px;
  list-style-type: none;
`
export const Container = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  padding-top: 24px;
  margin: 0;

  ${Media.Tablet`
    padding-bottom: 56px;
  `};

  ${Media.Phone`
    padding-bottom: 48px;
  `};
`
export const Content = styled.div`
  width: 1120px;
  margin: 0 auto;

  ${Media.Tablet`
    width: 100%;
  `};

  ${Media.Phone`
    width: 100%;
  `};
`
export const DotsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`
export const Dots = styled.div`
  width: 10px;
  height: 10px;
  margin: 5px;
  background-color: ${Colors.Blue50};
  border: none;
  border-radius: 50%;
`
export const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${Media.AboveTablet`
    height: 560px;
    align-items: center;
    justify-content: space-between;
  `};

  ${Media.Tablet`
    height: 100%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 56px;
  `};

  ${Media.Phone`
    height: 100%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 48px;
  `};
`
export const Section = styled.div<SectionProps>`
  width: 448px;

  ${Media.Tablet`
    width: 504px;
  `};

  ${Media.Phone`
    width: 335px;
  `};
`
export const SectionTitle = styled.div<SectionTitleProps>`
  ${Typography.S};
  ${FontWeights.DemiBold};
  margin-bottom: ${({ slide }) => (slide === 1 ? '12px' : slide === 2 ? '40px' : '32px')};
  color: ${Colors.Neutral90};
`
export const SectionSubTitle = styled.div`
  ${Typography.Body};
  margin-bottom: 32px;
  color: ${Colors.Neutral50};
`
const contentFadeLeftToRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(-10px)
  }

  to {
    opacity: 1;
    transform: translateX(0)
  }
`
const contentFadeRightToLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(10px)
  }

  to {
    opacity: 1;
    transform: translateX(0)
  }
`
export const SectionInner = styled.div<{ slide: SectionInnerProps }>`
  width: 100%;
  border-radius: 6px;
  background-color: ${Colors.White};
  animation: ${({ slide }) =>
      slide.prevSlide < slide.currentSlide ? contentFadeRightToLeft : contentFadeLeftToRight}
    0.5s ease;

  ${Media.Tablet`
    border-radius: 0;
  `};

  ${Media.Phone`
    border-radius: 0;
  `};
`
export const Header = styled.div`
  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${Colors.Indigo90};

  ${Media.Phone`
    ${Typography.Subhead};
  `};
`
export const Box = styled.div`
  display: flex;
  flex-direction: column;
`
export const HeaderBox = styled.div`
  display: flex;
  margin-bottom: 24px;
`
export const BoxItem = styled.div`
  gap: 10px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${Colors.Neutral50};
`
export const HeaderBoxItem = styled.div`
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${Colors.Neutral50};
`
export const StyledFeatureIcon = styled(FeatureIcon)`
  width: 32px;
  height: 32px;

  ${Media.Tablet`
    width: 40px;
    height: 40px;
  `};

  ${Media.Phone`
    width: 40px;
    height: 40px;
  `};
`
export const StyledButton = styled(Button)`
  margin-left: 20px;
`
export const FieldsContainer = styled.div`
  width: 448px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  ${Media.Tablet`
    width: 504px;
  `};

  ${Media.Phone`
    width: 335px;
  `};
`
export const FieldsContentLeftPanel = styled.div``

export const IconsContainer = styled.div`
  gap: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
`
export const IconsHolder = styled.div`
  display: flex;
  height: 64px;
  width: 64px;
  border-radius: 32px;
  background-color: ${Colors.White};
  justify-content: center;
  align-items: center;
`
export const AnimatedLineContainer = styled.div`
  height: 70px;
  width: 30px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  margin-top: -4px;
  margin-left: 24px;
`
const slideAnimUp = keyframes`
  0% { transform: translateY(0); }
  100% { transform: translateY(-30px); }
`
const slideAnimDown = keyframes`
  0% { transform: translateY(-30px); }
  100% { transform: translateY(0); }
`
export const AnimatedLineBlue = styled.div`
  width: 4px;
  height: 100px;
  background-image: url(${blueDot});
  background-repeat: repeat-y;
  animation: ${slideAnimDown} 1.2s infinite alternate;
`
export const AnimatedLinePurple = styled.div`
  width: 4px;
  height: 100px;
  margin-left: 12px;
  background-image: url(${purpleDot});
  background-repeat: repeat-y;
  animation: ${slideAnimUp} 1.2s infinite alternate;
`
export const IconImage = styled.img``

export const WorkBag = styled(Icon24.WorkBag)`
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
  padding-left: 2px;
`
export const Dot = styled.div<Props>`
  width: 8px;
  height: 8px;
  margin-right: 12px;
  border: none;
  border-radius: 50%;
  background-color: ${props => (props.isActive ? Colors.Blue50 : Colors.Neutral15)};
`
export const LabelText = styled.div<Props>`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${props => (props.isActive ? Colors.Neutral90 : Colors.Neutral50)};
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const TextFieldWrapper = styled(TextField)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`
