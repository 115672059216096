import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import Button from 'builder/components/Button'
import { JobSuggestionType } from 'builder/modules/interview/types'
import { useMutateJobTitle } from 'builder/views/Interview/hooks/useMutateJobTitle'
import { actions } from 'builder/modules/interview/interviewModule'
import { JobTitleAsyncAutosuggest } from '../../JobTitleAsyncAutosuggest/JobTitleAsyncAutosuggest'
import { Modal } from '../../Modal/Modal'
import { Title, Description, Bottom, StyledEditor } from './styles'

const TRANSLATION = 'builder.interview_prep_view.interview_dashboard.my_interviews'

interface ChangeJobModalProps {
  trackingParameter: 'interview_dash' | 'library'
  onClose: () => void
  jobSuggestion?: JobSuggestionType
}
export const ChangeJobModal = ({
  trackingParameter,
  onClose,
  jobSuggestion,
}: ChangeJobModalProps) => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()

  const [jobTitle, setJobTitle] = useState(() => jobSuggestion?.text)
  const [jobTitleId, setJobTitleId] = useState(0)
  const [hasError, setHasError] = useState(false)

  const [selectedSuggestion, setSelectedSuggestion] = useState<JobSuggestionType | undefined>(
    jobSuggestion,
  )
  const { mutate: postJobTitle, isLoading } = useMutateJobTitle()

  const onSuggestionSelected = (suggestion: JobSuggestionType) => {
    setJobTitle(suggestion.text)
    setJobTitleId(suggestion.id)
    setSelectedSuggestion(suggestion)
    setHasError(false)
  }
  const onCloseBefore = () => {
    if (!jobTitleId) {
      setHasError(true)
      return
    }
    jobTitle && dispatch(actions.setJobTitle(jobTitle))
    jobTitleId && dispatch(actions.setJobTitleId(jobTitleId))
    trackInternalEvent('change_title', {
      label: 'interview_prep',
      parameter: trackingParameter,
    })
    onClose()
  }

  return (
    <Modal onClose={onClose} title={<Title>{i18n.t(`${TRANSLATION}.change_job`)}</Title>}>
      <Description>{i18n.t(`${TRANSLATION}.change_any_time`)}</Description>
      <StyledEditor fullWidth={true}>
        <JobTitleAsyncAutosuggest
          trackingParameter={trackingParameter}
          jobTitle={jobTitle}
          onChange={e => setJobTitle(e.target.value)}
          onChangeSuggestion={onSuggestionSelected}
          hasError={hasError}
        />
      </StyledEditor>
      <Bottom>
        <Button theme="ghost" onClick={onClose}>
          {i18n.t(`${TRANSLATION}.cancel`)}
        </Button>
        <Button
          isLoading={isLoading}
          onClick={() => {
            if (selectedSuggestion) {
              postJobTitle(selectedSuggestion.id)
            }
            onCloseBefore()
          }}
        >
          {i18n.t(`${TRANSLATION}.save`)}
        </Button>
      </Bottom>
    </Modal>
  )
}
