import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Icon24 } from 'builder/components/Icon'

export const AddAccomplishmentContainer = styled.div`
  height: 36px;
  gap: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  margin-top: 16px;
  padding-inline-start: 3px;

  &:hover {
    background-color: ${Colors.Blue10};
    border-radius: 4px;
  }
`

export const AddIcon = styled(Icon24.Add)`
  color: ${Colors.Blue50};
`

export const AddTitle = styled.div`
  ${Typography.Caption};
  ${FontWeights.DemiBold};
  color: ${Colors.Blue50};
`
