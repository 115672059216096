import { useEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import ModalOverlay from 'builder/components/ModalOverlay'
import Button, { ButtonTheme } from 'builder/components/Button'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as userSelectors } from 'builder/modules/user'
import { SectionNames } from 'builder/modules/resumeEditor'
import { goToBillingPlanPage } from 'builder/utils/goToBillingPlanPage'
import paywallVisual from '../../../../../app/assets/images/builder/ai-cover-letters/paywall.png'
import {
  Container,
  CloseIcon,
  CloseIconWrapper,
  Visual,
  Content,
  Title,
  Text,
  ButtonContainer,
} from './styles'

interface Props {
  onClose: () => void
  isAiProfile: boolean
}

const ANALYTICS_LABEL = 'ai_writer_paywall'

export const Paywall = ({ onClose, isAiProfile = false }: Props) => {
  const { i18n } = useI18n()
  const { isPhone } = useMediaQueries()
  const upgradeUrl = useTypedSelector(userSelectors.getUpgradeUrl)

  useEffect(() => {
    if (!isAiProfile) {
      trackInternalEvent('see_ai_cover_letter_paywall')
    } else {
      trackInternalEvent('see_ai_writer_paywall', { section: SectionNames.profile })
    }
  }, [isAiProfile])

  const handleUpgradeClick = () => {
    goToBillingPlanPage({ upgradeURL: upgradeUrl, onSuccessRedirect: window.location.href })
    if (!isAiProfile) {
      trackInternalEvent('click_upgrade_ai_cover_letter_paywall')
    } else {
      trackInternalEvent('click_upgrade', { label: ANALYTICS_LABEL, section: SectionNames.profile })
    }
  }

  const handleCancelClick = () => {
    onClose()

    if (isAiProfile) {
      trackInternalEvent('click_cancel', { label: ANALYTICS_LABEL, section: SectionNames.profile })
    }
  }

  return (
    <ModalOverlay
      fullScreen={isPhone}
      overlayFadeDuration={250}
      contentSlideDuration={0}
      onClick={onClose}
    >
      <Container>
        <Visual src={paywallVisual} />

        <Content>
          <Title>
            {i18n.t(
              isAiProfile
                ? 'builder.ai_profile_summary.paywall_modal.title'
                : 'builder.ai_cover_letter.usage_limit_banner.title',
            )}
          </Title>
          <Text>
            {i18n.t(
              isAiProfile
                ? 'builder.ai_profile_summary.paywall_modal.description'
                : 'builder.ai_cover_letter.usage_limit_banner.content',
            )}
          </Text>
        </Content>

        <ButtonContainer>
          <Button theme={ButtonTheme.ghost} onClick={handleCancelClick}>
            {i18n.t(
              isAiProfile
                ? 'builder.ai_profile_summary.paywall_modal.button_text.cancel'
                : 'builder.ai_cover_letter.cancel',
            )}
          </Button>
          <Button onClick={handleUpgradeClick}>
            {i18n.t(
              isAiProfile
                ? 'builder.ai_profile_summary.paywall_modal.button_text.upgrade'
                : 'builder.ai_cover_letter.usage_limit_banner.upgrade_to_premium',
            )}
          </Button>
        </ButtonContainer>

        <CloseIconWrapper showHoverState={true} onClick={onClose}>
          <CloseIcon />
        </CloseIconWrapper>
      </Container>
    </ModalOverlay>
  )
}
