import styled from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Button from 'builder/components/Button'
import visualUrl from 'images/builder/account/cancel-subscription/discount-modal-header.svg'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 544px;
  height: 532px;
  border-radius: 16px;
  background-color: ${Colors.White};

  ${Media.Phone`
    width: 343px;
    height: auto;
  `}
`

export const Close = styled.div`
  position: absolute;
  right: 32px;
  top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: ${Colors.White};
  color: ${hexToRgba(Colors.Neutral50)};
  transition: background-color 0.2s;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
  }

  ${Media.Phone`
    width: 24px;
    height: 24px;
    top: 20px;
    right: 20px;
  `}
`

export const Visual = styled.div`
  background-image: url(${visualUrl});
  background-size: cover;
  width: 544px;
  height: 300px;
  border-radius: 16px 16px 0 0;

  ${Media.Phone`
    width: 343px;
    height: 188px;
  `}
`

export const Content = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: left;

  ${Media.Phone`
    padding: 20px;
  `}
`

export const Title = styled.div`
  ${Typography.S};
  ${FontWeights.DemiBold};
  width: 480px;
  margin-bottom: 8px;

  ${Media.Phone`
    width: 303px;
  `}
`

export const Text = styled.div`
  color: ${Colors.Neutral50};
  width: 480px;

  & p:not(:last-child) {
    margin-bottom: 12px;
  }

  ${Media.Phone`
    width: 303px;

    & p:not(:last-child) {
      margin-bottom: 8px;
    }
  `}

  .important {
    ${FontWeights.Medium};
    color: ${Colors.Neutral80};
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0px 32px 32px 32px;

  ${Media.Phone`
    padding: 0px 20px 20px 20px;
    gap: 8px;
  `}

  & ${Button} {
    margin-left: 8px;
    display: flex;
    ${Typography.ButtonM};
    ${FontWeights.DemiBold};
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    ${Media.Phone`
      margin-left: 0px;
      padding: 8px 12px;
      ${Typography.ButtonS};
    `}
  }
`
