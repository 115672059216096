import { Content as Container, Header, Heading, Text, Circle, CloseIcon } from '../../styles'

type Props = {
  handleClose: () => void
}

const Content = ({ handleClose }: Props) => {
  return (
    <Container>
      <Header>
        <Heading>Delete Career Paths</Heading>
        <Circle onClick={handleClose}>
          <CloseIcon />
        </Circle>
      </Header>
      <Text>Are you sure you want to delete Career Paths?</Text>
    </Container>
  )
}

export default Content
