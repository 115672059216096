import type { ChangeEvent } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import Checkbox from 'builder/components/Checkbox'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as userSelectors, actions } from 'builder/modules/user'
import { Label, actions as careerPathActions, CareerPathStep } from 'builder/modules/careerPath2'
import { Image, Title, Subtitle, FormButton, FormContainer } from './styles'

const careerPathsURL = require('./assets/img/career_path_success.svg')

export const Form = () => {
  const dispatch = useDispatch()
  const userData = useTypedSelector(userSelectors.userData)

  const toggle = (event: ChangeEvent<HTMLInputElement>) => {
    let payload = { name: 'career', value: event.target.checked }
    dispatch(actions.toggleEmailNotification(payload))
  }

  const handleClick = () => {
    dispatch(careerPathActions.fetchCareerCards())
    dispatch(careerPathActions.setStep(CareerPathStep.VIEW_CARDS))

    trackInternalEvent('click_view_my_pathway_button', Label)
  }

  return (
    <FormContainer>
      <Image src={careerPathsURL} />
      <Title>Your career path is saved!</Title>
      <Subtitle>
        Soon we&apos;ll launch Career Plans, allowing us to create your development plan based on
        your career path. Meanwhile, you can do career research and learn more on our blog.
      </Subtitle>
      <Checkbox
        onChange={toggle}
        checked={userData?.emailNotifications?.career}
        label="Notify me about the launch of the career planning"
      />
      <FormButton type="submit" onClick={handleClick}>
        View My Paths
      </FormButton>
    </FormContainer>
  )
}
