import { keyframes } from 'styled-components'

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

export const blinking = keyframes`
  0% {
    opacity: 1;
  }
  33% {
    opacity: 0.5;
  }
  66% {
    opacity: 1;
  }
`

export const gradient = keyframes`
  0% {
    background-position: -50vw 0;
  }
  80% {
    background-position: 50vw 0;
  }

  100% {
    background-position: 50vw 0;
  }
`

export const mobileSlideIn = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`

export const mobileSlideOut = keyframes`
  from {
    
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`

export const desktopSlideIn = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`

export const desktopSlideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`
