import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useState, useCallback, useMemo } from 'react'
import { selectors as userSelectors } from 'builder/modules/user'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { actions, CallToAction, Label, selectors, Content } from 'builder/modules/careerPlanning'

import Feed from './components/Feed'
import Header from './components/Header'
import References from './components/References'
import VideoPlayer from './components/VideoPlayer'
import {
  updateAllContents,
  updateFilterOptions,
  updateOpenContentsById,
  updateOpenContentsByStatus,
  updateCompletedContentsById,
  updateCompletedContentsByStatus,
} from './utils'

import {
  Id,
  Icon,
  Check,
  Wrapper,
  Container,
  StyledLock,
  Identifier,
  Description,
  VerticalLine,
} from './styles'

type Props = {
  id: number
  type: string
  position: number
  title: string
  index: number
  length: number
  completed: boolean
  ctas: CallToAction[]
  description?: string
  availableForFreeUsers: boolean
}

export const ActionItem = ({
  id,
  ctas,
  type,
  title,
  index,
  length,
  description,
  completed,
  availableForFreeUsers,
}: Props) => {
  const dispatch = useDispatch()
  const headers = useTypedSelector(selectors.headers)
  const topLevelId = useTypedSelector(selectors.step)
  const user = useTypedSelector(userSelectors.userData)
  const selectedId = useTypedSelector(selectors.selectedId)
  const allContents = useTypedSelector(selectors.allContents)
  const openContents = useTypedSelector(selectors.openContents)
  const secondLevelId = useTypedSelector(selectors.secondLevelId)
  const filterOptions = useTypedSelector(selectors.filterOptions)
  const isPremiumUser = useTypedSelector(userSelectors.premiumAccess)
  const completedContents = useTypedSelector(selectors.completedContents)
  const selectedTopLevelIndex = headers?.findIndex(header => header.id === topLevelId)
  const selectedSecondLevelIndex = headers?.[selectedTopLevelIndex]?.secondLevel?.findIndex(
    secondLevel => secondLevel.id === secondLevelId,
  )
  const subSectionName =
    headers?.[selectedTopLevelIndex]?.secondLevel?.[selectedSecondLevelIndex]?.title
  const filterStatus = filterOptions.find(option => option.isActive)?.status as string
  const startAnimation =
    useTypedSelector(selectors.startAnimation) && id === selectedId && filterStatus !== 'all'

  const [isCompleted, setIsCompleted] = useState(completed)

  const trackingEventProps = useMemo(() => {
    return {
      user_id: user?.id,
      action_item_name: title,
      sub_section_name: subSectionName,
    }
  }, [user?.id, title, subSectionName])

  const getActionItems = () => {
    if (type !== 'reference') {
      return ctas.map((cta, index) => {
        if (cta.ctaType === 'external_url' && (cta.url.includes('youtu.be') || cta.cover)) {
          return (
            <VideoPlayer
              key={index}
              src={cta.url}
              cover={cta.cover}
              isPremiumUser={isPremiumUser}
              availableForFreeUsers={availableForFreeUsers}
              trackingEventProps={trackingEventProps}
            />
          )
        }

        return (
          <Feed
            key={index}
            url={cta.url}
            text={cta.name}
            isPremiumUser={isPremiumUser}
            trackingEventProps={trackingEventProps}
            availableForFreeUsers={availableForFreeUsers}
          />
        )
      })
    }

    return ctas?.map((cta, index) => (
      <References
        key={index}
        id={index + 1}
        link={cta.url}
        icon={cta.icon}
        text={cta.name}
        isPremiumUser={isPremiumUser}
        availableForFreeUsers={availableForFreeUsers}
      />
    ))
  }

  const checkUser = useCallback(() => {
    if (!isPremiumUser && !availableForFreeUsers) {
      dispatch(actions.setShowLimitModal(true))
      return false
    }

    return true
  }, [dispatch, isPremiumUser, availableForFreeUsers])

  const dispatchContentsActions = useCallback(
    (...contents: Content[][]) => {
      const [updatedOpenContents, updatedAllContents, updatedCompletedContents] = contents

      setTimeout(() => {
        dispatch(actions.setOpenContents(updatedOpenContents))
        dispatch(actions.setAllContents(updatedAllContents))
        dispatch(actions.setCompletedContents(updatedCompletedContents))
      }, 300)
    },
    [dispatch],
  )

  const dispatchFilterActions = useCallback(
    (index: number, value: boolean) => {
      const updatedFilterOptions = updateFilterOptions(filterOptions, index, value)
      dispatch(actions.updateFilterOptions(updatedFilterOptions))
    },
    [dispatch, filterOptions],
  )

  const handleClick = useCallback(() => {
    const isAccessible = checkUser()

    if (isAccessible) {
      setIsCompleted(!isCompleted)
      dispatch(actions.setSelectedId(id))
      dispatch(actions.saveCompletedActionItem({ id, isCompleted: !isCompleted }))

      // Animation for removing content
      dispatch(actions.startAnimation(true))

      if (!isCompleted) {
        trackInternalEvent('click_action_item_completed', {
          ...Label,
          ...trackingEventProps,
        })

        // Animation for Confetti
        dispatch(actions.setShowAnimation(true))

        setTimeout(() => {
          dispatch(actions.setShowAnimation(false))
        }, 1000)

        // update open contents
        const updatedOpenContents = updateOpenContentsById(openContents, id)

        // update all contents
        const updatedAllContents = updateAllContents(allContents, id, !isCompleted)

        // update completed contents
        const updatedCompletedContents = updateCompletedContentsByStatus(updatedAllContents)

        if (updatedCompletedContents.length === 1) {
          dispatchFilterActions(1, false)
        } else if (!updatedCompletedContents.length) {
          dispatchFilterActions(1, true)
        }

        dispatchContentsActions(updatedOpenContents, updatedAllContents, updatedCompletedContents)
      } else {
        trackInternalEvent('click_action_item_uncompleted', {
          ...Label,
          ...trackingEventProps,
        })

        // update completed contents
        const updatedCompletedContents = updateCompletedContentsById(completedContents, id)

        // update all contents
        const updatedAllContents = updateAllContents(allContents, id, !isCompleted)

        // update open contents
        const updatedOpenContents = updateOpenContentsByStatus(updatedAllContents)

        if (updatedOpenContents.length === 1) {
          dispatchFilterActions(0, false)
        } else if (!updatedCompletedContents.length) {
          dispatchFilterActions(1, true)
        }

        dispatchContentsActions(updatedOpenContents, updatedAllContents, updatedCompletedContents)
      }

      setTimeout(() => {
        dispatch(actions.startAnimation(false))
      }, 300)
    }
  }, [
    checkUser,
    isCompleted,
    dispatch,
    id,
    trackingEventProps,
    openContents,
    allContents,
    dispatchContentsActions,
    dispatchFilterActions,
    completedContents,
  ])

  return (
    <Container animate={startAnimation}>
      {isCompleted ? (
        <Icon onClick={handleClick}>
          <Check />
        </Icon>
      ) : (
        <Identifier onClick={handleClick}>
          {isPremiumUser || availableForFreeUsers ? (
            <Id data-testid="index-count">{index + 1}</Id>
          ) : (
            <StyledLock data-testid="lock-icon" />
          )}
        </Identifier>
      )}
      <VerticalLine isVisible={!(index === length - 1)} />
      <Wrapper isLastElement={index === length - 1}>
        <Header type={type} title={title} isCompleted={isCompleted} />
        <Description>{description}</Description>
        {getActionItems()}
      </Wrapper>
    </Container>
  )
}

export default ActionItem
