import React, { useCallback } from 'react'
import { Styles as FloaterStyles } from 'react-floater'
import ReactJoyride, { Step, Styles, CallBackProps, TooltipRenderProps } from 'react-joyride'

import { Tooltip } from './Tooltip/Tooltip'
import { DEFAULT_ARROW, DEFAULT_STYLES } from './constants'

type Ref = React.ForwardedRef<ReactJoyride>
interface IProps {
  run: boolean
  steps: Step[]
  skipButtonLabel: string
  nextButtonLabel: string
  finalButtonLabel: string
  arrow?: FloaterStyles['arrow']
  styles?: Partial<Styles>
  callback?: (data: CallBackProps) => void
  isStepsCountEnabled?: boolean
  [key: string]: unknown
  customToolTip: React.ElementType<TooltipRenderProps>
  stepIndex?: number
  showSkipOnLast?: boolean
}

export const JoyRide = React.forwardRef(
  (
    {
      run,
      steps,
      skipButtonLabel,
      nextButtonLabel,
      finalButtonLabel,
      callback,
      isStepsCountEnabled = true,
      styles = DEFAULT_STYLES,
      arrow = DEFAULT_ARROW,
      customToolTip,
      stepIndex,
      showSkipOnLast = false,
    }: IProps,
    ref: Ref,
  ) => {
    const defaultTooltipComponent = useCallback(
      (props: TooltipRenderProps) => {
        return (
          <Tooltip
            {...props}
            skipButtonLabel={skipButtonLabel}
            nextButtonLabel={nextButtonLabel}
            finalButtonLabel={finalButtonLabel}
            isStepsCountEnabled={isStepsCountEnabled}
            showSkipOnLast={showSkipOnLast}
          />
        )
      },
      [finalButtonLabel, isStepsCountEnabled, nextButtonLabel, skipButtonLabel],
    )

    const tooltip = customToolTip || defaultTooltipComponent
    return (
      <ReactJoyride
        ref={ref}
        run={run}
        steps={steps}
        stepIndex={stepIndex}
        styles={styles}
        callback={callback}
        continuous
        disableScrolling
        disableCloseOnEsc
        disableOverlayClose
        disableScrollParentFix
        floaterProps={{ styles: { arrow } }}
        tooltipComponent={tooltip}
      />
    )
  },
)
