export enum CommuteTimes {
  LESS_THAN_30_MINS = '< 30 minutes',
  LESS_THAN_1_HOUR = '< 1 hour',
  LESS_THAN_2_HOURS = '< 2 hours',
  LESS_THAN_3_HOURS = '< 3 hours',
  MORE_THAN_3_HOURS = '> 3 hours',
  LESS_THAN_1_AND_HALF_HOURS = '< 1.5 hours',
  LESS_THAN_2_AND_HALF_HOURS = '< 2.5 hours',
}

export enum GenderValues {
  MALE = 'Male',
  FEMALE = 'Female',
  OTHER = 'Other',
}

export enum DateFormats {
  DAY_MONTH_YEAR = 'dd.MM.yyyy', // 21.12.2012
  JAPANESE_YEAR_MONTH_DAY = 'PPP', // 2012年12月21日
}
