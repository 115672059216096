import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import ErrorLogger from 'builder/services/ErrorLogger'
import { baseClient } from 'builder/modules/apiClient'
import { useClickOutside } from 'builder/hooks/useClickOutside'
import { useResume } from 'builder/hooks/useResume'
import { useI18n } from 'builder/hooks/useI18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

import { selectors as userSelectors } from 'builder/modules/user'
import { Candidate, ResumeDetails, actions, selectors } from 'builder/modules/resumeDistribution'
import { actions as uiActions, SnackbarTypes } from 'builder/modules/ui'
import Icon24, { Icon20 } from 'builder/components/Icon'
import SelectResumeModal from 'builder/views/ResumeDistributionView/components/SelectResumeModal'
import { formatDocumentPreviewUrl } from 'builder/utils/formatDocumentPreviewUrl'

import { FetchStatuses } from 'builder/modules/constants'
import { ReplaceResumePopup } from '../ReplaceResumePopup/ReplaceResumePopup'
import { Icon, ReplaceResumeButton, Resume } from './styles'

interface ReplaceResumeProps {
  toggle: (prop: boolean) => void
  show: boolean
  candidate: Candidate
}

const TRANSLATION = 'builder.resume_distribution.dashboard.header.resume_replace'

export const ReplaceResume = ({ toggle, show, candidate }: ReplaceResumeProps) => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const resume = useResume(candidate.resumeId)

  const [showResumeSelection, setResumeSelection] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const candidateResumePostStatus = useTypedSelector(selectors.candidateResumePostStatus)
  const isSubscriptionActive = useTypedSelector(selectors.isSubscriptionActive)
  const userAccountData = useTypedSelector(userSelectors.userData)

  useClickOutside(containerRef, () => toggle(false))

  const getThumbnailUrl = () => {
    if (!resume) return
    return formatDocumentPreviewUrl(resume)
  }

  const getIconByFileExt = (filename: string) => {
    const extMatch = /(?:\.([^.]+))?$/.exec(filename)
    const ext = extMatch && extMatch[1]

    switch (ext) {
      case 'txt':
        return <Icon24.Text />
      case 'pdf':
        return <Icon24.Pdf />
      case 'doc':
      case 'docx':
        return <Icon24.Word />
      default:
        return <></>
    }
  }

  const replaceResume = async (resume: ResumeDetails) => {
    try {
      if (!isSubscriptionActive) {
        await baseClient.post('resume-distribution/candidates/subscriptions', {})
      }

      dispatch(actions.postCandidateResume(resume))
      toggle(false)
    } catch (err) {
      ErrorLogger.log(err)
    }
  }

  const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedResume = event.target.files?.[0]
    if (selectedResume) {
      try {
        if (!isSubscriptionActive) {
          await baseClient.post('resume-distribution/candidates/subscriptions', {})
        }

        dispatch(actions.postCandidateResume(selectedResume))
        toggle(false)
      } catch (err) {
        ErrorLogger.log(err)
      }
    }
  }

  const openSnackBar = useCallback(() => {
    dispatch(
      uiActions.setSnackBarOpen({
        status: true,
        type: SnackbarTypes.success,
        text: i18n.t(`${TRANSLATION}.update_resume_info`),
      }),
    )

    setTimeout(() => {
      dispatch(uiActions.closeSnackBar())
    }, 5000)
    dispatch(actions.setPostCandidateResumeStatus(FetchStatuses.notAsked))
  }, [dispatch, i18n])

  useEffect(() => {
    if (candidateResumePostStatus === FetchStatuses.loaded) {
      openSnackBar()
    }
  }, [candidateResumePostStatus, openSnackBar])

  return (
    <Resume thumbnail={getThumbnailUrl()}>
      {!candidate.resumeId && <Icon> {getIconByFileExt(candidate.fileName)} </Icon>}
      {userAccountData?.hasPremiumFeatures && (
        <ReplaceResumeButton onClick={() => toggle(!show)} display={show ? 'block' : 'none'}>
          <Icon20.Update />
          {i18n.t(`${TRANSLATION}.button`)}
        </ReplaceResumeButton>
      )}
      {show && (
        <ReplaceResumePopup
          setResumeSelection={setResumeSelection}
          handleFileInputChange={handleFileInputChange}
          ref={containerRef}
        />
      )}

      {showResumeSelection && (
        <SelectResumeModal
          onClose={() => setResumeSelection(false)}
          submitSelectedResume={e => replaceResume(e)}
        />
      )}
    </Resume>
  )
}
