import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { useState } from 'react'
import { Icon20 } from 'builder/components/Icon'
import { useI18n } from 'builder/hooks/useI18n'
import Button from 'builder/components/Button'
import { Interview } from 'builder/modules/interview/types'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as userSelectors } from 'builder/modules/user'
import { useCreateInterviewFromInterview } from 'builder/views/Interview/hooks/useCreateInterviewFromInterview'
import { useCreateInterviewWithNewQuestions } from 'builder/views/Interview/hooks/useCreateInterviewWithNewQuestions'
import { PremiumDialog } from '../../../PremiumDialog/PremiumDialog'
import { Modal } from '../../../Modal/Modal'
import { Title, Bottom, Content, Warning, NoteContent, NoteTitle } from './styles'

const TRANSLATION = 'builder.interview_prep_view.interview_dashboard.restart_modal'

type RestartModalProps = {
  onClose: () => void
  interview: Interview
}

export const RestartModal = ({ onClose, interview }: RestartModalProps) => {
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const createInterviewFromSameQuestions = useCreateInterviewFromInterview()
  const createInterviewWithNewQuestions = useCreateInterviewWithNewQuestions()
  const [showPremiumModal, setShowPremiumModal] = useState(false)

  const isPremiumUser = useTypedSelector(userSelectors.premiumAccess)

  const onNewQuestionsClick = async () => {
    if (!isPremiumUser) {
      setShowPremiumModal(true)
      return
    }

    await createInterviewWithNewQuestions.mutateAsync(interview.id)
    trackInternalEvent('start_interview', {
      label: 'interview_prep',
      parameter: 'new',
    })
    navigate('/interview-preparation/interview-player')
  }

  const onSameQuestionsClick = async () => {
    await createInterviewFromSameQuestions.mutateAsync(interview.id)

    trackInternalEvent('start_interview', {
      label: 'interview_prep',
      parameter: 'restart_same',
    })
    navigate('/interview-preparation/interview-player')
  }

  if (showPremiumModal) {
    return <PremiumDialog trackingParameter="restart_modal" onClose={onClose} />
  }

  return (
    <Modal onClose={onClose} title={<Title>{i18n.t(`${TRANSLATION}.restart_interview`)}</Title>}>
      <Content>{i18n.t(`${TRANSLATION}.you_can_restart`)}</Content>
      <Bottom>
        <Button
          isLoading={createInterviewFromSameQuestions.isLoading}
          disabled={
            createInterviewFromSameQuestions.isLoading || createInterviewWithNewQuestions.isLoading
          }
          theme="ghost"
          onClick={onSameQuestionsClick}
        >
          {i18n.t(`${TRANSLATION}.same_questions`)}
        </Button>

        <Button
          isLoading={createInterviewWithNewQuestions.isLoading}
          disabled={
            createInterviewFromSameQuestions.isLoading || createInterviewWithNewQuestions.isLoading
          }
          theme={isPremiumUser ? 'secondary' : 'alternative'}
          onClick={onNewQuestionsClick}
        >
          {!isPremiumUser && <Icon20.Crown />}
          {i18n.t(`${TRANSLATION}.new_questions`)}
        </Button>
      </Bottom>
      <Warning>
        <NoteTitle>{i18n.t(`${TRANSLATION}.note_title`)}: </NoteTitle>
        <NoteContent>
          {isPremiumUser
            ? i18n.t(`${TRANSLATION}.note_content_premium`)
            : i18n.t(`${TRANSLATION}.note_content_free`)}
        </NoteContent>
      </Warning>
    </Modal>
  )
}
