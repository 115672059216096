import { Filter, HeadersType, UserLastSection } from './types'

export const getHeadingParams = (parsedLastSection: UserLastSection, headings: HeadersType[]) => {
  return {
    cp_top_level_id: parsedLastSection ? parsedLastSection.cpTopLevelId : headings[0].id,
    cp_second_level_id: parsedLastSection
      ? parsedLastSection.cpSecondLevelId
      : headings[0].secondLevel[0].id,
  }
}

export const getUserLastSection = () => {
  const userLastSection = localStorage.getItem('userLastSection')
  const parsedLastSection = userLastSection ? JSON.parse(userLastSection) : null

  return { parsedLastSection }
}

export const getSearchParams = () => {
  const { searchParams } = new URL(window.location.href)
  const cpTopLevelId = searchParams.get('cp_top_level')
  const cpSecondLevelId = searchParams.get('cp_second_level')

  const params = {
    cpTopLevelId,
    cpSecondLevelId,
  }

  return params
}

export const checkForValidIds = (
  headings: HeadersType[],
  cpTopLevelId: number,
  cpSecondLevelId: number,
) => {
  let isValidTopLevelId = false
  let isValidSecondLevelId = false

  const cpTopLevelIndex = headings.findIndex(item => {
    return item.id === cpTopLevelId
  })

  if (cpTopLevelIndex !== -1) {
    isValidTopLevelId = true
    isValidSecondLevelId =
      headings[cpTopLevelIndex].secondLevel.findIndex(item => item.id === cpSecondLevelId) !== -1
  }

  return {
    isValidTopLevelId,
    isValidSecondLevelId,
  }
}

export const setAllFilterActive = (
  filterOptions: Filter[],
  openContentsLength: number,
  completedContentsLength: number,
) => {
  return filterOptions.map(option => {
    if (option.id === 1) {
      return { ...option, isActive: false, isDisabled: !openContentsLength }
    }

    if (option.id === 2) {
      return { ...option, isActive: false, isDisabled: !completedContentsLength }
    }

    return { ...option, isActive: true }
  })
}

export const updateSearchParams = (topLevelId: number, secondLevelId: number) => {
  const url = new URL(window.location.href)
  const { searchParams } = url

  searchParams.set('cp_top_level', `${topLevelId}`)
  searchParams.set('cp_second_level', `${secondLevelId}`)
  window.history.pushState({}, '', url.toString())
}

export const setUserLastSection = (topLevelId: number, secondLevelId: number) => {
  localStorage.setItem(
    'userLastSection',
    JSON.stringify({
      cpTopLevelId: topLevelId,
      cpSecondLevelId: secondLevelId,
    }),
  )
}
