import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { blinking } from 'builder/styles/keyframes'

export const PlaceholderSvg = styled.svg`
  display: block;
  max-width: 100%;
  color: ${Colors.Neutral10};
  animation: ${blinking} 1.5s infinite linear;
`
