import { Fragment } from 'react'
import { CareerPathJobCardTone } from 'builder/components/CareerPathJobCard'
import { CareerPathConnectionType } from 'builder/modules/careerPath'
import { Level1 } from './Level1'
import { Level2 } from './Level2'
import { Level3 } from './Level3'
import { Sibling } from './Sibling'
import { Sibling2 } from './Sibling2'

type Props = {
  connections: number[]
  type?: CareerPathConnectionType
  gradient: [CareerPathJobCardTone, CareerPathJobCardTone] | []
  level?: number
}

const LevelComponents = [Level1, Level2, Level3]

export const Connection = ({ level, type, gradient, connections }: Props) => {
  const style = {
    from: `var(--${gradient?.[type === 'right' ? 0 : 1] || 'green'}-20)`,
    to: `var(--${gradient?.[type === 'right' ? 1 : 0] || 'green'}-20)`,
  }

  if (!level || !type) return null

  if (type === 'same') {
    return (
      <Fragment>
        {connections.map((connection, index) => {
          const directionType = level - connection > 0 ? 'bottom' : 'top'
          const SiblingComponent = Math.abs(level - connection) === 1 ? Sibling : Sibling2

          return <SiblingComponent key={index} gradient={style} type={directionType} />
        })}
      </Fragment>
    )
  } else {
    const LevelComponent = LevelComponents[level - 1]
    return (
      <LevelComponent
        key={`${type}${level}`}
        connections={connections}
        gradient={style}
        type={type}
      />
    )
  }

  return null
}
