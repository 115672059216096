import { Fragment } from 'react'
import { CareerPathJobCardProps, CareerPathJobCardTone } from 'builder/components/CareerPathJobCard'
import {
  CareerPathConnectionType,
  CareerPathRoleOccupationMatrix,
  CareerPathRoleOccupationMatrixItem,
} from 'builder/modules/careerPath'
import { getCardById } from 'builder/modules/careerPath/utils'
import { getAllConnections, getNearestNormalizedConnections, isNearest } from '../../utils'
import { Connection } from '../Connection'
import * as Styles from './styles'

type Props = CareerPathJobCardProps & {
  item: CareerPathRoleOccupationMatrixItem
  activeCardId: string | null
  isAnyActivated: boolean
  matrix: CareerPathRoleOccupationMatrix
}

const ToneGradients: {
  [key in CareerPathConnectionType]: {
    [key in 'low' | 'high' | 'medium']: [CareerPathJobCardTone, CareerPathJobCardTone] | []
  }
} = {
  left: {
    low: [],
    medium: ['green', 'blue'],
    high: ['blue', 'indigo'],
  },
  right: {
    low: ['green', 'blue'],
    medium: ['blue', 'indigo'],
    high: [],
  },
  same: {
    low: ['green', 'green'],
    medium: ['blue', 'blue'],
    high: ['indigo', 'indigo'],
  },
}

export const MatrixCard = ({ item, matrix, activeCardId, isAnyActivated, ...rest }: Props) => {
  const connections = getAllConnections(item.id, matrix)
  const normalizedConnections = getNearestNormalizedConnections(item.grade, connections)
  const activeCardProps = activeCardId ? getCardById(activeCardId, matrix) : null

  // Should be connected with active card and be the nearest
  const isConnected =
    activeCardId &&
    getAllConnections(activeCardId, matrix)?.includes(item.id) &&
    isNearest(activeCardProps?.grade || '', item.grade)

  return (
    <Styles.Container>
      {isAnyActivated && item.id === activeCardId && (
        <Fragment>
          {Object.keys(normalizedConnections).map((type, index) => {
            const indexType = type as CareerPathConnectionType
            return (
              !!normalizedConnections[indexType].length && (
                <Connection
                  key={index}
                  level={item.level}
                  gradient={ToneGradients[indexType][item.grade]}
                  type={indexType}
                  connections={normalizedConnections[indexType]}
                />
              )
            )
          })}
        </Fragment>
      )}

      <Styles.ChoiceCard
        key={item.id}
        {...item}
        {...rest}
        isActive={item.id === activeCardId}
        isFaded={isAnyActivated && !isConnected}
      />
    </Styles.Container>
  )
}
