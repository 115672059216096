/* eslint-disable react/no-unescaped-entities */
import { useState } from 'react'
import ModalOverlay from 'builder/components/ModalOverlay'
import {
  CarrerText,
  ButtonTrailer,
  TrailerImg,
  ModalContainer,
  ModalIframe,
  ModalClose,
  ModalCloseIcon,
} from './styles'
import trailerImage from './assets/img/Trailer.png'

const Footer = () => {
  const [isShow, setIsShow] = useState(false)

  return (
    <>
      <CarrerText>While you're waiting, see what awaits you in Career.io</CarrerText>
      <ButtonTrailer onClick={() => setIsShow(!isShow)}>
        Watch Trailer <TrailerImg src={trailerImage} />
      </ButtonTrailer>
      {isShow && (
        <ModalOverlay
          onClose={() => setIsShow(false)}
          overlayFadeDuration={150}
          contentSlideDuration={0}
        >
          <ModalContainer>
            <ModalIframe
              title="https://www.youtube.com/embed/SieXn9ArkhU"
              allowFullScreen
              frameBorder="0"
              src="https://www.youtube.com/embed/SieXn9ArkhU?autoplay=1"
              allow="autoplay"
            />
            <ModalClose onClick={() => setIsShow(false)}>
              <ModalCloseIcon />
            </ModalClose>
          </ModalContainer>
        </ModalOverlay>
      )}
    </>
  )
}

export default Footer
