import { trackInternalEvent } from '@rio/tracking'
import Button, { ButtonTheme } from 'builder/components/Button'

import { Container, Text, SubText } from './styles'

const docsURL =
  'https://docs.google.com/forms/d/e/1FAIpQLSdV02yv4bks90pG371r0cCzW2RFG0TROFrjetZs3oBVn-NFbg/viewform'

const CareerPlanSuggestion = () => {
  return (
    <Container>
      <Text>New career plans are on the way</Text>
      <SubText>What other plans would you like?</SubText>
      <Button
        as="a"
        target="_blank"
        href={docsURL}
        theme={ButtonTheme.ghost}
        onClick={() => trackInternalEvent('click_suggest_plan')}
      >
        Suggest a plan
      </Button>
    </Container>
  )
}

export default CareerPlanSuggestion
