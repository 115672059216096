import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Sizes from 'builder/styles/sizes'
import DefaultSpinner from 'builder/components/Spinner'

export const Container = styled.div`
  border-radius: ${Sizes.S};
  padding: ${Sizes.L};
  background-color: ${Colors.Indigo10};
`

export const Body = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${Sizes['2XS']};
  max-width: 480px;
`

export const Spinner = styled(DefaultSpinner)`
  width: 24px;
  height: 24px;
`

export const Header = styled.div`
  display: flex;
  gap: ${Sizes.XS};
  align-items: center;
  ${Typography.S};
  ${FontWeights.DemiBold};
`

export const Description = styled.div`
  color: ${Colors.Neutral60};

  b {
    color: ${Colors.Neutral90};
    ${FontWeights.DemiBold};
  }
`
