import { UseFormReturn } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { SuggestionSelectedEventData } from 'react-autosuggest'
import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'

import * as SimpleForm from 'builder/components/SimpleForm'
import { TextField } from 'builder/components/TextField'
import Select from 'builder/components/Select'
import { useI18n } from 'builder/hooks/useI18n'
import { AutoApplyForm } from 'builder/modules/autoApply/types'
import { selectors } from 'builder/modules/autoApply/autoApplyModule'
import { AsyncAutosuggest } from 'builder/components/AsyncAutosuggest'
import { useJobTitles } from 'builder/hooks/useJobTitles'

import { StartCompleteScreenRoute } from '../../constants'
import { ResumePreview } from '../ResumePreview/ResumePreview'
import { SelectInfoText } from './styles'

type JobSuggestionType = {
  text: string
  id: number
}

const TRANSLATION = 'builder.auto_apply.form.personal_information'

type Props = {
  form: UseFormReturn<
    Pick<AutoApplyForm, 'firstName' | 'lastName' | 'currentJobTitle' | 'gender' | 'race'>
  >
}

export const PersonalInfoForm = (props: Props) => {
  const { form } = props

  const {
    register,
    formState: { errors },
    watch,
    setValue,
  } = form

  const { i18n } = useI18n()
  const navigate = useNavigate()
  const uploadedResume = useSelector(selectors.uploadedResume)
  const selectedResume = useSelector(selectors.selectedResume)
  const { mutateAsync: getJobTitle } = useJobTitles()

  const required = i18n.t(`${TRANSLATION}.field_is_required`)
  const genderOptions = [
    { id: 'male', name: i18n.t(`${TRANSLATION}.gender_options.male`) },
    { id: 'female', name: i18n.t(`${TRANSLATION}.gender_options.female`) },
    {
      id: 'prefer_not_to_say',
      name: i18n.t(`${TRANSLATION}.gender_options.prefer_not_to_says`),
    },
  ]

  const raceOptions = [
    { id: 'white', name: i18n.t(`${TRANSLATION}.race_options.white`) },
    {
      id: 'hispanic_or_Latino',
      name: i18n.t(`${TRANSLATION}.race_options.hispanic_or_latino`),
    },
    {
      id: 'black_or_african_american',
      name: i18n.t(`${TRANSLATION}.race_options.black_or_african_american`),
    },
    { id: 'asian', name: i18n.t(`${TRANSLATION}.race_options.asian`) },
    {
      id: 'american_indian_or_alaska_native',
      name: i18n.t(`${TRANSLATION}.race_options.american_indian_or_alaska_native`),
    },
    {
      id: 'middle_eastern_or_north_african',
      name: i18n.t(`${TRANSLATION}.race_options.middle_eastern_or_north_african`),
    },
    {
      id: 'native_hawaiian_or_other_pacific_islander',
      name: i18n.t(`${TRANSLATION}.race_options.native_hawaiian_or_other_pacific_islander`),
    },
    {
      id: 'other_race_ethnicity_or_origin',
      name: i18n.t(`${TRANSLATION}.race_options.other_race_ethnicity_or_origin`),
    },
    {
      id: 'prefer_not_to_say',
      name: i18n.t(`${TRANSLATION}.race_options.prefer_not_to_say`),
    },
  ]

  const onResumeSelect = () => {
    trackInternalEvent('aa_click_change_resume', {
      label: 'total_job_search_solution',
    })
    navigate(`/auto-apply/${StartCompleteScreenRoute.RESUME_SELECT}`)
  }

  const onSuggestionSelected = (
    _: React.FormEvent<unknown>,
    { suggestion }: SuggestionSelectedEventData<JobSuggestionType>,
  ) => {
    setValue('currentJobTitle', suggestion.text)
  }

  return (
    <>
      <ResumePreview
        uploadedResume={uploadedResume}
        selectedResume={selectedResume}
        isParserLoading={false}
        handleBuilderResumeChange={onResumeSelect}
      />

      <SimpleForm.Row position="relative">
        <TextField
          {...register('firstName', { required })}
          label={i18n.t(`${TRANSLATION}.first_name`)}
          placeholder="John"
          error={errors.firstName?.message}
        />
      </SimpleForm.Row>
      <SimpleForm.Row position="relative">
        <TextField
          {...register('lastName', { required })}
          label={i18n.t(`${TRANSLATION}.last_name`)}
          placeholder="Walton"
          error={errors.lastName?.message}
        />
      </SimpleForm.Row>
      <SimpleForm.Row position="relative">
        <AsyncAutosuggest<JobSuggestionType>
          highlightedQuery
          value={watch('currentJobTitle')}
          onChange={e => setValue('currentJobTitle', e.target.value)}
          onSuggestionSelected={onSuggestionSelected}
          fetchItems={async (query: string) => {
            const res = await getJobTitle(query)

            return res.map(title => ({
              text: title.standardTitle,
              id: title.id,
            }))
          }}
        >
          {inputProps => (
            <TextField
              {...register('currentJobTitle', { required })}
              {...inputProps}
              error={errors.currentJobTitle?.message}
              name="jobTitle"
              label={i18n.t(`${TRANSLATION}.latest_job_title`)}
              placeholder={i18n.t(`${TRANSLATION}.current_job_title_placeholder`)}
            />
          )}
        </AsyncAutosuggest>
      </SimpleForm.Row>
      <SimpleForm.Row position="relative">
        <Select
          {...register('gender')}
          label={i18n.t(`${TRANSLATION}.gender`)}
          selected={watch('gender')}
          onSelect={val => setValue('gender', val || '')}
          {...{ options: genderOptions }}
        />
        <SelectInfoText>{i18n.t(`${TRANSLATION}.gender_info`)}</SelectInfoText>
      </SimpleForm.Row>
      <SimpleForm.Row position="relative">
        <Select
          {...register('race')}
          label={i18n.t(`${TRANSLATION}.race_ethniciy`)}
          selected={watch('race')}
          onSelect={val => setValue('race', val)}
          {...{ options: raceOptions }}
        />
        <SelectInfoText>{i18n.t(`${TRANSLATION}.race_ethniciy_info`)}</SelectInfoText>
      </SimpleForm.Row>
    </>
  )
}
