import styled from 'styled-components'
import Button from 'builder/components/Button'
import Colors from 'builder/styles/colors'

export const LoadMoreWrapper = styled.div`
  margin: 50px 0 20px;
  display: flex;
  justify-content: center;
`

export const LoadMoreButton = styled(Button)`
  border-radius: 12px;
  background-color: ${Colors.Neutral5};
  color: ${Colors.Neutral50};

  &:hover {
    background-color: ${Colors.Blue10};
  }
`

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 56px 0;
`
