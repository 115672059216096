import uniqueId from 'lodash/uniqueId'
import { useConstant } from 'builder/hooks/useConstant'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { SiblingConnectionProps } from './types'
import * as Styles from './styles'

export const Sibling2 = ({ type, gradient: { from, to } }: SiblingConnectionProps) => {
  const { isPhone } = useMediaQueries()
  const width = isPhone ? 14 : 18
  const height = isPhone ? 300 : 304

  const id = useConstant(() => uniqueId('s2'))

  return (
    <Styles.Sibling2Container
      type={type}
      isPhone={isPhone}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={
          isPhone
            ? 'M0 2V2C6.62742 2 12 7.37258 12 14V276C12 282.627 6.62742 288 0 288V288'
            : 'M0 2V2C8.83656 2 16 9.16344 16 18V286C16 294.837 8.83656 302 0 302V302'
        }
        stroke={`url(#${id})`}
        strokeWidth="4"
      />
      <defs>
        <linearGradient
          id={id}
          x1={isPhone ? 2.25 : 6}
          y1={isPhone ? -293.996 : -146}
          x2={isPhone ? 9.75 : 26}
          y2={isPhone ? -293.996 : -146}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={from} />
          <stop offset="1" stopColor={to} />
        </linearGradient>
      </defs>
    </Styles.Sibling2Container>
  )
}
