import styled, { keyframes } from 'styled-components'
import { TransitionStatus } from 'react-transition-group'
import { animated } from '@react-spring/web'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Mixins from 'builder/styles/mixins'
import Media from 'builder/styles/media'
import Tooltip from '../Tooltip'

interface PercentProps {
  points: number
}

export const MainSuggestion = styled(animated.div)`
  display: inline-block;
  ${Typography.Tiny};
  ${FontWeights.DemiBold}
  border-radius: 4px;
  margin-right: 6px;
  color: ${Colors.Green60};
  font-variant-numeric: tabular-nums;
  background: ${Colors.Green10};
  height: 20px;
  padding: 2px 4px;

  &:after {
    content: '%';
  }
`

export const PercentSuggestion = styled(animated.div)`
  display: inline-block;
  ${Typography.Caption};
  font-weight: 600;
  margin-right: 6px;
  color: ${Colors.Green50};
  font-variant-numeric: tabular-nums;

  &:after {
    content: '%';
  }
`
export const Container = styled.div<{ isPageScrolled: boolean }>`
  display: block;
  user-select: none;
  margin-bottom: 12px;
  position: sticky;
  top: 0px;
  background-color: ${Colors.White};
  z-index: ${props => (props.isPageScrolled ? 4 : 2)};

  padding-top: 20px;

  ${Media.Phone`
    &:before,
    &:after {
      width: 20px;
    }
    margin-bottom: 12px;
  `};

  &:before {
    right: 100%;
  }

  &:after {
    left: 100%;
  }
`

export const Header = styled.div`
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  max-width: 100%;
  overflow: hidden;
`

export const HeaderMain = styled.div`
  margin-right: 8px;
  margin-bottom: 8px;
  display: flex;
  flex: 0 1 auto;
  overflow: hidden;
`

export const DesignationToolTip = styled(Tooltip)`
  display: inline-block;
  width: fit-content;
  height: 16px;
  color: ${Colors.Blue50};
  transition: color 0.1s ease;
  cursor: pointer;
  background: white;
`

export const HeaderText = styled.p`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
  margin-right: 5px;
  display: block;
  max-width: 180px;
  height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const HeaderAside = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 8px;
`

export const Percent = styled(animated.div)<PercentProps>`
  display: inline-block;
  ${Typography.Tiny};
  ${FontWeights.DemiBold}
  border-radius: 4px;
  margin-right: 8px;
  color: ${Colors.White};
  font-variant-numeric: tabular-nums;
  width: fit-content;
  height: 20px;
  padding: 2px 4.5px;
  ${({ points }) => {
    switch (true) {
      case points > 70:
        return `
          background-color: ${Colors.Green40};
        `
      case points > 30:
        return `
          background-color: ${Colors.Amber40};
        `
      default:
        return `
          background-color: ${Colors.Red40};
        `
    }
  }}

  &:after {
    content: '%';
  }
`

export const TopSuggestionWrapper = styled.div`
  position: relative;
  flex: 1;
  overflow: hidden;

  ${Media.Phone`
    display: none;
  `};
`

export const TopSuggestion = styled.div<{ in: TransitionStatus }>`
  display: flex;
  flex-direction: row-reverse;
  white-space: nowrap;
  ${Typography.Caption};
  color: ${Colors.Neutral50};
  cursor: pointer;
  transition: opacity 0.4s ease, transform 0.4s ease;
  opacity: 0;
  transform: translateY(-100%);

  ${props =>
    ({
      entering: 'opacity: 1; transform: translateY(0);',
      entered: 'opacity: 1; transform: translateY(0);',
      exiting: 'opacity: 0; transform: translateY(100%); position: absolute; top: 0; right: 0;',
      exited: null,
      unmounted: null,
    }[props.in])};

  &:hover {
    color: ${Colors.Blue50};
  }

  &:hover ${PercentSuggestion} {
    color: ${Colors.Blue50};
  }
`

export const Hint = styled.div`
  width: 26px;
  height: 20px;
  color: ${Colors.Green50};
  margin-left: 16px;
  position: relative;
  bottom: 2px;
  cursor: pointer;
  transition: background-color 0.1s ease;

  &:hover {
    color: ${Colors.Blue50};
  }

  & > svg {
    width: 100%;
    height: 100%;
  }
`

export const Close = styled(Hint)`
  color: ${Colors.Neutral30};
`

export const Progress = styled.div`
  height: 3px;
  background-color: ${Colors.Neutral10};
`

export const ProgressInner = styled(animated.div)`
  height: 3px;
  width: 100%;
  transform-origin: left;
`

const fadeIn = keyframes`
  from { opacity: 0; transform: scaleY(0.75); }
  to   { opacity: 1; transform: scaleY(1); }
`

export const SuggestionMenu = styled.div`
  ${Mixins.Shadow};
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: ${Colors.Neutral10};
  animation: ${fadeIn} 0.15s ease-out;
  transform-origin: 50% 0;
`

export const SuggestionList = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: 12px 20px;

  ${Media.Phone`
    padding: 8px;
  `};
`

export const Suggestion = styled.div`
  flex: 0 0 50%;
  display: block;
  padding: 6px;
  cursor: pointer;
  width: 100%;
  ${Typography.Caption};

  ${Media.Phone`
    flex: 0 0 100%;
  `};

  & ${Percent} {
    min-width: 40px;
    text-align: right;
  }

  &:hover {
    color: ${Colors.Blue50};
  }

  &:hover ${PercentSuggestion} {
    color: ${Colors.Blue50};
  }
`

export const Title = styled.div`
  ${Typography.Caption}
  ${FontWeights.Regular}
`

export const TailoredDesignation = styled.div`
  ${Typography.Caption}
  ${FontWeights.Regular}
`
