import styled, { css } from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Button from 'builder/components/Button'
import { getLineHeightRatio } from 'builder/components/SubscriptionCancellationFormNew/common/utils'
import Shadows from 'builder/styles/shadows'

// in pixels
const CARD_SECTION_DESKTOP_VIEW_WIDTH = 186

interface CardSectionProps {
  flowId: number
  activeCardId: number
}

interface PromotionContainerProps {
  activeCardId: number
}

interface CardTitleProps {
  isCardActive: boolean
  isCardHovered: boolean
}

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 620px;
  background-color: ${Colors.White};
  border-radius: 16px;
  padding: 52px 32px 32px 32px;
`

export const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Close = styled.div`
  position: absolute;
  width: 28px;
  height: 28px;
  top: 28px;
  right: 28px;
  border-radius: 32px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.Neutral50};
  cursor: pointer;
  transition: color 0.1s;
  background-color: ${Colors.Neutral10};
  z-index: 999;

  &:hover {
    color: ${Colors.Blue50};
  }
`

export const Header = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: center;
`

export const TitleContainer = styled.div`
  ${FontWeights.DemiBold};
  display: flex;
  flex-direction: column;
`

export const Title = styled.p`
  ${Typography.M};
`

export const Content = styled.div`
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CardContainer = styled.div`
  display: flex;
  width: 100%;
  height: 160px;
  ${Shadows.light.low};
  border-radius: 16px;
  margin-top: 32px;

  & > div:nth-child(-n + 3) {
    border-right: 1px solid ${Colors.Neutral15};
  }
  & > div:first-child {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }
  & > div:last-child {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`

export const CardSection = styled.div<CardSectionProps>(({ activeCardId, flowId }) => {
  const isCardClicked = activeCardId === flowId
  const isOtherCardClicked = activeCardId && !isCardClicked

  return css`
    box-sizing: border-box;
    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: ${Colors.Neutral15};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: ${CARD_SECTION_DESKTOP_VIEW_WIDTH}px;
    height: 160px;
    background-color: ${isCardClicked ? Colors.Blue10 : ''};

    & img {
      filter: ${isOtherCardClicked ? 'grayscale(1)' : ''};
    }

    & > div {
      border-radius: 16px;
    }

    &:hover {
      & img {
        filter: grayscale(0);
      }

      & > div {
        background-color: ${Colors.Blue10};
        border-radius: 12px;
        width: calc(100% - 8px);
        height: 152px;
        margin: 4px;
      }
    }
  `
})

export const Card = styled.div<CardSectionProps>(({ activeCardId, flowId }) => {
  const imageFilter = activeCardId && activeCardId !== flowId ? 'grayscale(1)' : ''

  return css`
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & img {
      filter: ${imageFilter};
    }
  `
})

export const CardImage = styled.img`
  display: flex;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin-bottom: 9px;
`

export const CardTitle = styled.p<CardTitleProps>(({ isCardActive, isCardHovered }) => {
  return css`
    ${FontWeights.Medium};
    width: 155px;
    font-size: 19px;
    line-height: ${getLineHeightRatio(24, 19)};
    text-align: center;
    color: ${isCardActive || isCardHovered ? Colors.Neutral90 : Colors.Neutral50};
  `
})

export const PromotionContainerColumn = styled.div<PromotionContainerProps>(({ activeCardId }) => {
  const bubbleArrowOffset =
    CARD_SECTION_DESKTOP_VIEW_WIDTH / 2 + (activeCardId - 1) * CARD_SECTION_DESKTOP_VIEW_WIDTH

  return css`
    display: flex;
    flex-direction: column;
    background-color: transparent;
    border-radius: 16px;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    gap: 8px;
    width: 100%;
    min-height: 272px;

    ${Media.AboveTablet`
      margin-top: 24px;
      &:after {
        content: '';
        position: absolute;
        bottom: 100%;
        left: ${bubbleArrowOffset}px;
        width: 8px;
        margin-left: -8px;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid ${Colors.Indigo10};
      }
    `}
  `
})

export const PromotionContainerRow = styled.div<PromotionContainerProps>(({ activeCardId }) => {
  const bubbleArrowOffset =
    CARD_SECTION_DESKTOP_VIEW_WIDTH / 2 + (activeCardId - 1) * CARD_SECTION_DESKTOP_VIEW_WIDTH

  return css`
    display: flex;
    flex-direction: row;
    height: 272px;
    background-color: ${Colors.Neutral5};
    border-radius: 16px;
    justify-content: center;
    align-items: center;
    position: relative;

    ${Media.AboveTablet`
      margin-top: 24px;
      &:after {
        content: '';
        position: absolute;
        bottom: 100%;
        left: ${bubbleArrowOffset}px;
        width: 8px;
        margin-left: -8px;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid ${Colors.Indigo10};
      }
    `}
  `
})

export const PromotionContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  padding-left: 40px;
  height: 204px;
`

export const PromotionTitle = styled.p`
  ${FontWeights.DemiBold};
  font-size: 27px;
  line-height: ${getLineHeightRatio(28, 27)};
  color: ${Colors.Blue90};
`

export const PromotionButton = styled(Button)`
  ${Typography.ButtonM};
  ${FontWeights.Medium};
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: ${getLineHeightRatio(22, 18)};
  color: ${Colors.White};
  background-color: ${Colors.Blue50};
  border-radius: 4px;
  height: 48px;
  width: fit-content;
  padding-left: 24px;
  padding-right: 24px;

  &:hover {
    background-color: ${Colors.Blue60};
  }
`

export const PromotionButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const PromotionImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 384px;

  & img {
    width: 220px;
    height: 184px;
  }
`

export const CollapseWithItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`
