import type { UserDocumentType } from '@rio/types'
import { useI18n } from 'builder/hooks/useI18n'
import { DocumentTypes } from 'builder/modules/constants'

import {
  EmptyStateContainer,
  EmptyStateImage,
  EmptyStateTitle,
  EmptyStateText,
  EmptyStateButton,
  EmptyStateButtonIcon,
} from './styles'

interface Props {
  type: UserDocumentType
  onClick: () => void
  isButtonDisabled: boolean
  isAutoTailored: boolean
}

const lettersImageUrl = require('images/builder/dashboard/no-letters.png') as string
const resumesImageUrl = require('images/builder/dashboard/no-resumes.png') as string

export const DocumentsEmptyState = ({ type, onClick, isButtonDisabled, isAutoTailored }: Props) => {
  const { i18n } = useI18n()
  const imageUrl = type === DocumentTypes.coverLetter ? lettersImageUrl : resumesImageUrl

  return (
    <EmptyStateContainer>
      <EmptyStateImage src={imageUrl} />
      <EmptyStateTitle>{i18n.t(`builder.dashboard.empty.${type}s.title`)}</EmptyStateTitle>
      <EmptyStateText>{i18n.t(`builder.dashboard.empty.${type}s.text`)}</EmptyStateText>
      {!isAutoTailored && (
        <EmptyStateButton onClick={onClick} isDisabled={isButtonDisabled}>
          <EmptyStateButtonIcon />
          {i18n.t(`builder.dashboard.create_new_${type}`)}
        </EmptyStateButton>
      )}
    </EmptyStateContainer>
  )
}
