import { useQuery } from '@tanstack/react-query'
import { baseAppClient } from 'builder/modules/apiClient/apiClient'

type Params = {
  plan?: string
  referrerPage?: string
}

export const useGetPlanPrice = (params?: Params) => {
  const getPlanApi = async () => {
    const res = await baseAppClient.get('/billing/plans.json', { params })
    return res.data
  }

  const res = useQuery({
    queryKey: ['useGetPlanPrice', params],
    queryFn: getPlanApi,
    onError: err => console.error(err),
    useErrorBoundary: false,
  })

  const formatedPrice = () => {
    const recurringAmount = res.data?.upsell?.recurring_amount
    if (!recurringAmount) return '0'

    const amount = recurringAmount
    // Ensure the number is at least a three-digit number as a string
    const numStr = amount.toString().padStart(3, '0')
    // Insert the comma two digits from the right
    return `$${numStr.slice(0, -2)},${numStr.slice(-2)}`
  }

  return { ...res, formatedPrice }
}
