import styled from 'styled-components'
import Media from 'builder/styles/media'

export const Container = styled.div`
  max-width: 736px;
  gap: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  ${Media.Phone`
    gap: 32px;
  `}
`
