import { useEffect, useState, useRef } from 'react'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { apiClient } from 'builder/modules/apiClient'
import { FetchStatuses } from 'builder/modules/constants'
import { formatDate } from 'builder/utils/formatDate'

export const useGeneralStats = ({ resumeId }) => {
  const [stats, setStats] = useState({ status: FetchStatuses.loading })

  useEffect(() => {
    let isMounted = true

    apiClient.get(`/sharing/${resumeId}`).then(response => {
      if (isMounted) setStats({ ...response.data, status: FetchStatuses.loaded })
    })

    return () => (isMounted = false)
  }, [resumeId])

  return stats
}

export const useHistory = ({ resumeId, hasPremium }) => {
  const [status, setStatus] = useState(FetchStatuses.notAsked)
  const [items, setItems] = useState([])
  const isMounted = useRef(true)

  // do not fetch data if user doesn't have the premium subscription.
  // `useInfiniteScroll` triggers `onLoadMore` while `hasMore` is `true`,
  // so by setting it to `false` by default we prevent the first page loading
  const [hasMore, setHasMore] = useState(hasPremium)

  // fix edge case: if the sharing stats tab was opened before the user data is loaded
  useEffect(() => {
    setHasMore(hasPremium)
  }, [hasPremium])

  useEffect(() => {
    return () => (isMounted.current = false)
  }, [])

  function formatStartingAfter() {
    if (!items.length) return null

    const { createdAt } = items[items.length - 1]
    return formatDate(createdAt, 't')
  }

  function onLoadMore() {
    setStatus(FetchStatuses.loading)

    const params = { starting_after: formatStartingAfter() }

    apiClient.get(`/sharing/${resumeId}/history`, { params }).then(({ data }) => {
      if (!isMounted.current) return

      setStatus(FetchStatuses.loaded)
      setHasMore(data.hasMore)
      setItems(views => views.concat(data.items))
    })
  }
  const [infiniteRef, { rootRef }] = useInfiniteScroll({
    loading: status === FetchStatuses.loading,
    hasNextPage: hasMore,
    onLoadMore,
  })

  return {
    status,
    hasMore,
    items,
    infiniteRef,
    rootRef,
  }
}

export const useImageLoaded = src => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    let mounted = true

    if (src) {
      const image = new Image()
      image.onload = () => mounted && setLoaded(true)
      image.src = src
    }

    return () => (mounted = false)
  }, [src])

  return loaded
}
