import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
`

const BaseContainer = `
  ${Typography.Caption};
  ${FontWeights.Medium};
  border-radius: 6px;
  color: ${Colors.White};
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  height: 28px;
  width: 28px;
`

export const SelectedContainer = styled.div`
  ${BaseContainer}
  background-color: ${Colors.Blue50};
  align-self: baseline;
`

export const SvgContainer = styled.div`
  ${BaseContainer}
  background-color: ${Colors.Blue10};
  color: ${Colors.Blue50};
`

export const UnselectedContainer = styled.div`
  ${BaseContainer}
  background-color: ${Colors.Neutral10};
  color: ${Colors.Neutral60};
`

export const Text = styled.div<{ $unselected: boolean }>`
  ${Typography.Body};
  ${FontWeights.Regular};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: ${props => (props.$unselected ? Colors.Neutral50 : Colors.Neutral90)};
`
