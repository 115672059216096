import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import { UploadModalContainer } from 'builder/components/FillResumeModal/UploadResume/styles'
import Typography, { FontWeights } from 'builder/styles/typography'
import Sizes from 'builder/styles/sizes'

export const OptionsContainer = styled.div`
  width: 100%;
  background: ${Colors.Neutral5};
  z-index: 1;
  border: 1px solid ${Colors.Neutral15};
  border-radius: 20px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const CardContainer = styled.div`
  max-width: 272px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${Colors.Neutral5};
`

export const Card = styled.div<{ $direction: 'row' | 'column'; $justifyCenter: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: ${props => props.$direction};
  flex-direction: center;
  justify-content: ${props => (props.$justifyCenter ? 'center' : 'flex-start')};
  align-items: center;
  background-color: ${Colors.Neutral5};
  border-radius: 6px;
  padding: 15px 16px 15px 21px;
  cursor: pointer;

  & > div {
    text-align: ${props => (props.$justifyCenter ? 'center' : 'start')};
    text-wrap: balance;
  }
  ${Media.Phone`
    padding: 16px;
  `}

  &:hover {
    background-color: ${Colors.Blue10};
  }
`
export const UploadContainer = styled(UploadModalContainer)`
  width: 100%;
  background: ${Colors.Neutral5};
  z-index: 1;
  border: 1px solid ${Colors.Neutral15};
  border-radius: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  ${Media.AbovePhone`width: 272px;`}
  height: 340px;
  padding: 10px;
  & > div {
    max-width: 200px;
    max-height: 200px;
    margin-top: 0;
  }
  & > div:first-child {
    margin-top: auto;
  }
  & > :last-child {
    margin-top: auto;
  }
  & > p {
    /* error message */
    max-width: 250px;
  }
`

export const IconWrapper = styled.div`
  margin-left: auto;
  ${Media.AboveTablet`
    ${Card}:hover & {
      svg path {
        fill: ${Colors.Blue50};
      }
    }
  `}
`

export const ExpandedUploadContainer = styled(UploadModalContainer)`
  width: 100%;
  height: 752px;
  max-width: 570px;
  background: ${Colors.Neutral5};
  z-index: 1;
  border: 1px solid ${Colors.Neutral15};
  border-radius: 20px;
  display: flex;
  align-items: center;
`

export const Title = styled.span`
  color: ${Colors.Neutral90};
  ${Typography.M};
  ${FontWeights.DemiBold};
  margin-bottom: ${Sizes['2XL']};
`

export const Square = styled.div`
  border-radius: ${Sizes.XS};
  border: 1px dashed ${Colors.Neutral30};
  padding: ${Sizes.XL} ${Sizes['2XL']};
  width: 416px;
  height: 476px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & > p {
    /* error message */
    max-width: 250px;
  }
`
