import { useCallback, useEffect, useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useNavigate } from 'react-router-dom'
import { Optional } from 'packages/types'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import {
  FetchStatuses,
  CIO_CREATE_ACCOUNT_URL,
  CIO_JOB_SEARCH_URL,
  CIO_OFFER_ANALYZER_URL,
} from 'builder/modules/constants'
import {
  MouseClickEvent,
  SnackbarTypes,
  TextAreaChangeEvent,
  actions as uiActions,
} from 'builder/modules/ui'
import { useConfig } from 'builder/hooks/useConfig'
import { actions as userActions, selectors, UserData } from 'builder/modules/user'
import { i18n as I18n } from 'builder/utils/i18n'
import { selectors as initSelectors } from 'builder/modules/init'
import { Cards, FeedbackType } from '../common/types'

const usePromotionSectionActions = (
  activeCard: Optional<Cards>,
  isACIORegisteredUser: boolean,
  user: Optional<UserData>,
) => {
  const [feedbackContent, setFeedbackContent] = useState<string>('')
  const [feedbackSubmitted, setFeedbackSubmitted] = useState<boolean>(false)
  const [feedbackType, setFeedbackType] = useState<Optional<FeedbackType>>(null)
  const [successfulSubmit, setSuccessfulSubmit] = useState<boolean>(false)
  const [reasonContent, setReasonContent] = useState<string>('')
  const [isPromotionButtonLoading, setIsPromotionButtonLoading] = useState<boolean>(false)
  const [dontNeedPromoSelectedIndex, setDontNeedPromoSelectedIndex] = useState<number>(0)
  const dispatch = useDispatch()
  const superAppLoginStatus = useTypedSelector(selectors.superAppLoginStatus)
  const discountAppliedStatus = useTypedSelector(selectors.discountStatus)
  const submitFeedbackStatus = useTypedSelector(selectors.feedbackStatus)
  const discountErrorText = useTypedSelector(selectors.discountErrorText)
  const UserData = useTypedSelector(selectors.userData)
  const discountSubsCancelStatus = UserData?.billingInfo?.alreadyGotDiscountDuringSubCancellation
  const navigate = useNavigate()
  const config = useConfig()
  const isSubscriptionV4Enabled = config?.features.subCancellationV4 || false
  const locale = useTypedSelector(initSelectors.locale)

  // Set button status to loading based on super app login status
  useEffect(() => {
    setIsPromotionButtonLoading(superAppLoginStatus === FetchStatuses.loading)
  }, [superAppLoginStatus])

  // Set button status to loading based on discount status
  useEffect(() => {
    setIsPromotionButtonLoading(discountAppliedStatus === FetchStatuses.loading)
  }, [discountAppliedStatus])

  // Set button status to loading based on submit feedback status
  useEffect(() => {
    setIsPromotionButtonLoading(submitFeedbackStatus === FetchStatuses.loading)
  }, [submitFeedbackStatus])

  // Set feedback submitted status based on redux statue values
  useEffect(() => {
    if ([FetchStatuses.failed, FetchStatuses.loaded].includes(submitFeedbackStatus)) {
      setFeedbackSubmitted(true)
      setSuccessfulSubmit(submitFeedbackStatus === FetchStatuses.loaded)
    }
  }, [submitFeedbackStatus])

  // Reset feedback data when active card is changed
  useEffect(() => {
    if (
      activeCard === Cards.Discount &&
      (discountSubsCancelStatus ||
        (discountErrorText && discountErrorText === 'Already Discounted'))
    ) {
      setFeedbackSubmitted(true)
      setSuccessfulSubmit(true)
      setFeedbackType(FeedbackType.Feedback)
    } else {
      setFeedbackSubmitted(false)
      setSuccessfulSubmit(false)
      setFeedbackType(null)
    }
  }, [activeCard, discountErrorText, discountSubsCancelStatus])

  const handleGetDiscount = useCallback(
    (e: MouseClickEvent) => {
      e.preventDefault()
      dispatch(userActions.getDiscountNew())
    },
    [dispatch],
  )

  const handleCompareJobOffer = useCallback(
    (e: MouseClickEvent) => {
      e.preventDefault()
      if (locale === 'nl-NL') {
        navigate('/')
        return
      }

      if (isSubscriptionV4Enabled) {
        navigate('/career-plans/first-90-days')
      } else {
        if (isACIORegisteredUser) {
          window.open(
            isACIORegisteredUser ? CIO_OFFER_ANALYZER_URL : CIO_CREATE_ACCOUNT_URL,
            '_self',
          )
        } else {
          trackInternalEvent('click_cio_activation_button', { source: 'subscription_cancellation' })
          dispatch(userActions.loginToSuperApp())
        }
      }
    },
    [isACIORegisteredUser, dispatch, navigate, isSubscriptionV4Enabled, locale],
  )

  const handleExploreJobs = useCallback(
    (e: MouseClickEvent) => {
      e.preventDefault()
      if (isSubscriptionV4Enabled) {
        navigate('/job-search?view=recommendation')
      } else {
        window.open(isACIORegisteredUser ? CIO_JOB_SEARCH_URL : CIO_CREATE_ACCOUNT_URL, '_self')
      }
    },
    [isACIORegisteredUser, navigate, isSubscriptionV4Enabled],
  )

  const handleSubmitFeedback = useCallback(
    (message: string, type: FeedbackType) => async (e: MouseClickEvent) => {
      e.preventDefault()
      if (message.trim().length !== 0) {
        setFeedbackType(type)
        dispatch(
          userActions.submitFeedbackForSubscriptionCancellation({
            email: user?.email ?? '',
            name: user?.fullName ?? '',
            feedback: message,
          }),
        )
        if (type === FeedbackType.Feedback) {
          setFeedbackContent('')
        }
        if (type === FeedbackType.OtherReason) {
          setReasonContent('')
        }
      } else {
        e.preventDefault()
        dispatch(
          uiActions.setSnackBarOpen({
            status: true,
            type: SnackbarTypes.failure,
            text: I18n.t('builder.subscription_cancellation.not_entered_feedback'),
          }),
        )
        setTimeout(() => {
          dispatch(uiActions.closeSnackBar())
        }, 3000)
      }
    },
    [dispatch, user],
  )

  const promotionButtonClickHandler = useMemo(() => {
    switch (activeCard) {
      case Cards.JobOffer:
        return handleCompareJobOffer
      case Cards.Discount:
        return handleGetDiscount
      case Cards.FeatureOrProblem:
        return handleSubmitFeedback(feedbackContent, FeedbackType.Feedback)
      case Cards.DreamJob:
        return handleExploreJobs
      case Cards.OtherReasons:
        return handleSubmitFeedback(reasonContent, FeedbackType.OtherReason)
      default:
        return () => {}
    }
  }, [
    activeCard,
    feedbackContent,
    handleCompareJobOffer,
    handleGetDiscount,
    handleExploreJobs,
    handleSubmitFeedback,
    reasonContent,
  ])

  const textAreaChangeHandler = useCallback(
    (e: TextAreaChangeEvent) => {
      activeCard === Cards.FeatureOrProblem
        ? setFeedbackContent(e.target.value)
        : setReasonContent(e.target.value)
    },
    [activeCard],
  )

  const textAreaValue = useMemo(() => {
    return activeCard === Cards.FeatureOrProblem ? feedbackContent : reasonContent
  }, [activeCard, feedbackContent, reasonContent])

  return {
    feedbackType,
    feedbackSubmitted,
    isPromotionButtonLoading,
    promotionButtonClickHandler,
    successfulSubmit,
    textAreaChangeHandler,
    textAreaValue,
    discountErrorText,
    discountSubsCancelStatus,
    dontNeedPromoSelectedIndex,
    setDontNeedPromoSelectedIndex,
  }
}

export default usePromotionSectionActions
