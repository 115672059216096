import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
const { White, Neutral90, Neutral50 } = Colors

export const ProcessingContainer = styled.div`
  width: 520px;
  padding: 82px 86px 139px;
  gap: 32px;
  background: ${White};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  border-radius: 16px;
  ${Media.Phone`
    width: 343px;
    padding: 58px 20px;
    gap: 20px;
  `}
`

export const ProcessingGIF = styled.img`
  height: 200px;
  ${Media.Phone`
    height: 160px;
  `}
`

export const ProcessingText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const ProcessingTitle = styled.p`
  ${Typography.M};
  ${FontWeights.DemiBold};
  color: ${Neutral90};
`

export const ProcessingDescription = styled.p`
  ${Typography.Body};
  color: ${Neutral50};
  white-space: pre-wrap;
`
