import { useCallback } from 'react'
import { Field } from 'builder/components/Card/styles'
import SingleDatePicker from '../../CustomComponent/SingleDatePicker'

interface PropTypes {
  item: { plannedResignationDate: string }
  updateSimpleField: (event: object) => void
}

export const PlannedResignationDate = (props: PropTypes) => {
  const { item, updateSimpleField } = props
  const { plannedResignationDate } = item
  const onChangeCallback = useCallback(
    value => {
      updateSimpleField({
        target: {
          name: 'plannedResignationDate',
          value: value.dateFrom,
        },
      })
    },
    [updateSimpleField],
  )

  return (
    <Field>
      <SingleDatePicker
        value={plannedResignationDate || ''}
        onChange={onChangeCallback}
        label="Planned Resignation Date (Optional)"
      />
    </Field>
  )
}
