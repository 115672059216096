import { Link } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { rolesThatMightSuitYouImageUrl } from 'builder/components/CareerInsights'
import Button, { ButtonTheme } from 'builder/components/Button'
import { translationKey } from '../../consts'
import { Container, Description, Title, Body, Image } from './styles'

export const InsightsBanner = () => {
  const { i18n } = useI18n()

  return (
    <Container>
      <Body>
        <Image src={rolesThatMightSuitYouImageUrl} alt="Career Assessment Banner" />
        <Title>{i18n.t(`${translationKey}.career_assessment_banner.title`)}</Title>
        <Description>
          {i18n.t(`${translationKey}.career_assessment_banner.description`)}
        </Description>
      </Body>
      <Link
        to="/career-profile/chat"
        onClick={() => {
          trackInternalEvent('click_get_started_assessment')
        }}
      >
        <Button theme={ButtonTheme.alternative}>
          {i18n.t(`${translationKey}.career_assessment_banner.button_text`)}
        </Button>
      </Link>
    </Container>
  )
}
