import { useState } from 'react'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import CustomInputDiv from '../CustomInputDiv'
import * as Styled from './styles'

interface ErrorInterface {
  hasError: boolean
  errorMessage: string | null
}

interface Props {
  onDragOver: () => void
  error: ErrorInterface
  fileChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  drag: boolean
}

const UploadDefault = ({ onDragOver, fileChange, error }: Props) => {
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const { isDesktop } = useMediaQueries()

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <>
      <Styled.Container
        onDragOver={onDragOver}
        onDragEnter={onDragOver}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        hasWrongFileType={error.hasError}
      >
        <Styled.Image draggable="false" isHovered={isDesktop && isHovered} />
        <CustomInputDiv handleFileChange={e => fileChange(e)} isHovered={isDesktop && isHovered} />
        <Styled.SubText>
          DOC, DOCX, PDF, ODF, TXT, RTF and JPG, PNG, GIF smaller than 5MB
        </Styled.SubText>
      </Styled.Container>
      {error.hasError ? <Styled.ErrorMessage>{error.errorMessage}</Styled.ErrorMessage> : null}
    </>
  )
}

export default UploadDefault
