import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { DocumentTypes } from 'builder/modules/constants'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import {
  selectors as userSelectors,
  actions as userActions,
  ISmartBannerOrder,
} from 'builder/modules/user'
import { selectors, actions } from 'builder/modules/panel'
import { actions as uiActions } from 'builder/modules/ui'
import { actions as editorActions } from 'builder/modules/resumeEditor'
import { selectors as interviewSelector } from 'builder/modules/interview/interviewModule'
import Colors from 'builder/styles/colors'
import { useUser } from 'builder/hooks/useUser'
import { useConfig } from 'builder/hooks/useConfig'
import ErrorLogger from 'builder/services/ErrorLogger'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { useDashboardStatusUtils } from 'builder/views/CareerProfile/hooks/useDashboardStatusUtils'
import { useCareerServiceTitle } from 'builder/components/Panel/CareerGoal/hooks/useCareerServiceTitle'
import useWebsiteHost from './useWebsiteHost'
import {
  getBannerIdleTimeLimitSeconds,
  getLocalStorageBannerKey,
  IBannerQueueJson,
} from './constants'

const star = require('../assets/Star.svg')
const starContrasted = require('../assets/Star_1.svg')
const expertContent = require('../assets/expertContent.svg')
const expertContentHover = require('../assets/expertContentHover.svg')

interface Props {
  selectedIndex: number
}

interface ContentDataProp {
  enabled: boolean
  img: string
  title: string
  text: string
  tone10: string
  tone15: string
  tone20: string
  tone30?: string
  tone50: string
  tone80: string
  tone100: string
  tabIndex: number[]
  tag?: string
  tagImg?: string
  tagHoverImg?: string
  flagTag?: string
  url?: string
  bannerName: string
  eventTarget?: string
  isPremiumService?: boolean
  cioDomainUrl?: string
}

const getCareerProfileItemTranslationKey = (selectedIndex: number): string => {
  // using different texts for
  // Find a new job (0) / Change your career (2)
  // and Excel at your job (1) tabs
  return selectedIndex === 1
    ? 'builder.dashboard.main_banner.career_assessment_for_excel'
    : 'builder.dashboard.main_banner.career_assessment_for_find_or_change'
}

export const useBannerData = ({ selectedIndex }: Props) => {
  const smartBannersOrderAccount = useTypedSelector(userSelectors.smartBannersOrder)
  const { actionHandler: careerProfileNavigationHandler } = useDashboardStatusUtils()
  const { i18n } = useI18n()
  const config = useConfig()
  const features = useFeaturesConfig()
  const interviewUrl = useSelector(interviewSelector.getInterviewUrl)
  const interviewDomainUrl = useSelector(interviewSelector.getInterviewDomainUrl)

  const contentData: ContentDataProp[] = useMemo(() => {
    return [
      {
        enabled: features?.features?.careerProfile,
        img: require('../assets/getAssessment.png'),
        title: i18n.t(`${getCareerProfileItemTranslationKey(selectedIndex)}.title`),
        text: i18n.t(`${getCareerProfileItemTranslationKey(selectedIndex)}.text`),
        tag: i18n.t(`${getCareerProfileItemTranslationKey(selectedIndex)}.tag`),
        tone10: Colors.Indigo10,
        tone15: '#EDEEFE',
        tone20: Colors.Indigo20,
        tone30: Colors.Indigo30,
        tone50: Colors.Indigo50,
        tone80: Colors.Indigo80,
        tone100: '#7477BC',
        tabIndex: [0, 1, 2],
        url: 'career-profile/',
        bannerName: 'career_assessment',
        eventTarget: 'career_assessment',
        isPremiumService: false,
        cioDomainUrl: 'https://career.io/app/career-profile',
      },
      {
        enabled: features?.features?.resumeOptimizer,
        img: require('../assets/Resume.svg'),
        title: i18n.t('builder.dashboard.main_banner.tailor_your_resume.title'),
        text: i18n.t('builder.dashboard.main_banner.tailor_your_resume.text'),
        tag: i18n.t('builder.dashboard.main_banner.tailor_your_resume.tag'),
        tagImg: star,
        tagHoverImg: starContrasted,
        tone10: Colors.Blue10,
        tone15: '#E0F1FE',
        tone20: Colors.Blue20,
        tone30: Colors.Blue30,
        tone50: Colors.Blue50,
        tone80: Colors.Blue80,
        tone100: '#5F80AA',
        tabIndex: [0],
        url: '',
        bannerName: 'tailor_your_resume',
        eventTarget: 'resume_tailoring',
        isPremiumService: false,
        cioDomainUrl: 'not available',
      },
      {
        enabled: true,
        img: require('../assets/Resume.svg'),
        title: i18n.t('builder.dashboard.main_banner.create_a_cover_letter.title'),
        text: i18n.t('builder.dashboard.main_banner.create_a_cover_letter.text'),
        tag: i18n.t('builder.dashboard.main_banner.create_a_cover_letter.tag'),
        tagImg: star,
        tagHoverImg: starContrasted,
        tone10: Colors.Blue10,
        tone15: '#E0F1FE',
        tone20: Colors.Blue20,
        tone30: Colors.Blue30,
        tone50: Colors.Blue50,
        tone80: Colors.Blue80,
        tone100: '#5F80AA',
        tabIndex: [0],
        url: '',
        bannerName: 'create_a_cover_letter',
        eventTarget: 'cover_letter',
        isPremiumService: false,
        cioDomainUrl: 'not available',
      },
      {
        enabled: features?.features?.careerPath,
        img: require('../assets/ExploreCareers.svg'),
        title: i18n.t('builder.dashboard.main_banner.discover_your_next_steps.title'),
        text: i18n.t('builder.dashboard.main_banner.discover_your_next_steps.text'),
        tag: i18n.t('builder.dashboard.main_banner.discover_your_next_steps.tag'),
        tone10: Colors.Beige10,
        tone15: '#EFEDE8',
        tone20: Colors.Beige20,
        tone30: Colors.Beige30,
        tone50: Colors.Beige50,
        tone80: Colors.Beige80,
        tone100: '#927760',
        tabIndex: [1, 2],
        url: 'career-path',
        bannerName: 'explore_your_next_steps',
        eventTarget: 'explore_your_next_steps',
        isPremiumService: true,
        cioDomainUrl: 'https://career.io/app/career-path',
      },
      {
        enabled: features?.features?.salaryAnalyzer,
        img: require('../assets/KnowYourWorth.svg'),
        title: i18n.t('builder.dashboard.main_banner.know_your_worth.title'),
        text: i18n.t('builder.dashboard.main_banner.know_your_worth.text'),
        tag: i18n.t('builder.dashboard.main_banner.know_your_worth.tag'),
        tone10: Colors.Indigo10,
        tone15: '#EDEEFE',
        tone20: Colors.Indigo20,
        tone30: Colors.Indigo30,
        tone50: Colors.Indigo50,
        tone80: Colors.Indigo80,
        tone100: '#7477BC',
        tabIndex: [0, 1, 2],
        url: 'offer-analyzer',
        bannerName: 'know_your_worth',
        eventTarget: 'salary_analyzer',
        isPremiumService: true,
        cioDomainUrl: 'https://career.io/app/offer-analyzer',
      },
      {
        enabled: features?.features?.careerExplorer,
        img: require('../assets/explore_careers.svg'),
        title: i18n.t('builder.dashboard.main_banner.explore_careers.title'),
        text: i18n.t('builder.dashboard.main_banner.explore_careers.text'),
        tag: i18n.t('builder.dashboard.main_banner.explore_careers.tag'),
        flagTag: 'New',
        tone10: Colors.Beige10,
        tone15: '#efede8',
        tone20: Colors.Beige20,
        tone30: Colors.Beige30,
        tone50: Colors.Beige50,
        tone80: Colors.Beige80,
        tone100: '#927760',
        tabIndex: [2],
        url: 'explore-careers',
        bannerName: 'explore_careers',
        eventTarget: 'explore_careers',
        isPremiumService: true,
        cioDomainUrl: 'https://career.io/app/explore-careers',
      },
      {
        enabled: features?.features?.jobSearchPlan,
        img: require('../assets/JobSearch.svg'),
        title: i18n.t('builder.dashboard.main_banner.master_your_job_search.title'),
        text: i18n.t('builder.dashboard.main_banner.master_your_job_search.text'),
        tag: i18n.t('builder.dashboard.main_banner.master_your_job_search.tag'),
        flagTag: 'New',
        tone10: Colors.Green10,
        tone15: '#E2F1E8',
        tone20: Colors.Green20,
        tone30: Colors.Green30,
        tone50: Colors.Green50,
        tone80: Colors.Green80,
        tone100: '#5B856F',
        tabIndex: [0, 2],
        url: 'career-plans/execute-a-job-search',
        bannerName: 'execute_a_job_search',
        eventTarget: 'job_search_strategy',
        isPremiumService: true,
        cioDomainUrl: 'https://career.io/app/career-plans/execute-a-job-search',
      },
      {
        enabled: features?.features?.interviewSchool,
        img: require('../assets/JobInterview.svg'),
        title: i18n.t('builder.dashboard.main_banner.ace_job_interview.title'),
        text: i18n.t('builder.dashboard.main_banner.ace_job_interview.text'),
        tag: i18n.t('builder.dashboard.main_banner.ace_job_interview.tag'),
        tone10: Colors.Green10,
        tone15: '#E2F1E8',
        tone20: Colors.Green20,
        tone30: Colors.Green30,
        tone50: Colors.Green50,
        tone80: Colors.Green80,
        tone100: '#5B856F',
        tabIndex: [0, 2],
        url: interviewUrl,
        bannerName: 'ace_job_interview',
        eventTarget: 'interview_prep',
        isPremiumService: true,
        cioDomainUrl: interviewDomainUrl,
      },
      {
        enabled: features?.features?.careerAdvice,
        img: require('../assets/SearchAdvice.svg'),
        title: i18n.t('builder.dashboard.main_banner.read_job_search_advice.title'),
        text: i18n.t('builder.dashboard.main_banner.read_job_search_advice.text'),
        tag: i18n.t('builder.dashboard.main_banner.read_job_search_advice.tag'),
        tagImg: expertContent,
        tagHoverImg: expertContentHover,
        tone10: Colors.Neutral10,
        tone15: '#EFF2F9',
        tone20: Colors.Neutral20,
        tone30: Colors.Neutral30,
        tone50: Colors.Neutral50,
        tone80: Colors.Neutral80,
        tone100: '#7A808C',
        tabIndex: [0],
        url: '',
        bannerName: 'read_career_advice_tab_0',
        eventTarget: 'professional_resources',
        isPremiumService: true,
        cioDomainUrl: 'https://career.io/career-advice/',
      },
      {
        enabled: features?.features?.careerAdvice,
        img: require('../assets/SearchAdvice.svg'),
        title: i18n.t('builder.dashboard.main_banner.read_career_advice.title'),
        text: i18n.t('builder.dashboard.main_banner.read_career_advice.text_1'),
        tag: i18n.t('builder.dashboard.main_banner.read_career_advice.tag'),
        tagImg: expertContent,
        tagHoverImg: expertContentHover,
        tone10: Colors.Neutral10,
        tone15: '#EFF2F9',
        tone20: Colors.Neutral20,
        tone30: Colors.Neutral30,
        tone50: Colors.Neutral50,
        tone80: Colors.Neutral80,
        tone100: '#7A808C',
        tabIndex: [1],
        url: '',
        bannerName: 'read_career_advice_tab_1',
        eventTarget: 'professional_resources',
        isPremiumService: true,
        cioDomainUrl: 'https://career.io/career-advice/',
      },
      {
        enabled: features?.features?.careerAdvice,
        img: require('../assets/SearchAdvice.svg'),
        title: i18n.t('builder.dashboard.main_banner.read_career_advice.title'),
        text: i18n.t('builder.dashboard.main_banner.read_career_advice.text_2'),
        tag: i18n.t('builder.dashboard.main_banner.read_career_advice.tag'),
        tagImg: expertContent,
        tagHoverImg: expertContentHover,
        tone10: Colors.Neutral10,
        tone15: '#EFF2F9',
        tone20: Colors.Neutral20,
        tone30: Colors.Neutral30,
        tone50: Colors.Neutral50,
        tone80: Colors.Neutral80,
        tone100: '#7A808C',
        tabIndex: [2],
        url: '',
        bannerName: 'read_career_advice_tab_2',
        eventTarget: 'professional_resources',
        isPremiumService: true,
        cioDomainUrl: 'https://career.io/career-advice/',
      },
      {
        enabled: features?.features?.careerPlans,
        img: require('../assets/First90Days.svg'),
        title: i18n.t('builder.dashboard.main_banner.succeed_in_your_first_90_days.title'),
        text: i18n.t('builder.dashboard.main_banner.succeed_in_your_first_90_days.text'),
        tag: i18n.t('builder.dashboard.main_banner.succeed_in_your_first_90_days.tag'),
        flagTag: 'New',
        tone10: Colors.Green10,
        tone15: '#E2F1E8',
        tone20: Colors.Green20,
        tone30: Colors.Green30,
        tone50: Colors.Green50,
        tone80: Colors.Green80,
        tone100: '#5B856F',
        tabIndex: [1],
        url: 'career-plans/first-90-days',
        bannerName: 'first_90_days_plan',
        eventTarget: 'first_90_days_plan',
        isPremiumService: true,
        cioDomainUrl: 'https://career.io/app/career-plans/first-90-days',
      },
      {
        enabled: features?.features?.careerPlans,
        img: require('../assets/GetaPromotion.svg'),
        title: i18n.t('builder.dashboard.main_banner.get_a_promotion.title'),
        text: i18n.t('builder.dashboard.main_banner.get_a_promotion.text'),
        tag: i18n.t('builder.dashboard.main_banner.get_a_promotion.tag'),
        flagTag: 'New',
        tone10: Colors.Green10,
        tone15: '#E2F1E8',
        tone20: Colors.Green20,
        tone30: Colors.Green30,
        tone50: Colors.Green50,
        tone80: Colors.Green80,
        tone100: '#5B856F',
        tabIndex: [1],
        url: 'career-plans/path-to-promotion',
        bannerName: 'get_a_promotion',
        eventTarget: 'plan_to_promotion',
        isPremiumService: true,
        cioDomainUrl: 'https://career.io/app/career-plans/path-to-promotion',
      },
      {
        enabled: features?.showResumeDistribution(),
        img: require('../assets/ResumeDistribution.svg'),
        title: i18n.t('builder.dashboard.main_banner.resume_distribution.title'),
        text: i18n.t('builder.dashboard.main_banner.resume_distribution.text'),
        tag: i18n.t('builder.dashboard.main_banner.resume_distribution.tag'),
        tone10: Colors.Blue10,
        tone15: '#E0F1FE',
        tone20: Colors.Blue20,
        tone30: Colors.Blue30,
        tone50: Colors.Blue50,
        tone80: Colors.Blue80,
        tone100: '#5F80AA',
        tabIndex: [0],
        url: '/resume-distribution',
        bannerName: 'resume_distribution',
        eventTarget: 'resume_distribution',
        isPremiumService: true,
        cioDomainUrl: 'https://career.io/app/resume-distribution',
      },
      {
        enabled: features?.features?.jobSearch,
        img: require('../assets/TrackJobs.svg'),
        title: i18n.t('builder.dashboard.main_banner.find_your_next_job.title'),
        text: i18n.t('builder.dashboard.main_banner.find_your_next_job.text'),
        tag: i18n.t('builder.dashboard.main_banner.find_your_next_job.tag'),
        tone10: Colors.Indigo10,
        tone15: '#EDEEFE',
        tone20: Colors.Indigo20,
        tone30: Colors.Indigo30,
        tone50: Colors.Indigo50,
        tone80: Colors.Indigo80,
        tone100: '#7477BC',
        tabIndex: [0, 2],
        url: 'job-search',
        bannerName: 'find_your_next_job',
        eventTarget: 'find_your_next_job',
        isPremiumService: true,
        cioDomainUrl: 'https://career.io/app/job-search?view=recommendation',
      },
      {
        enabled: features?.features?.jobTracking,
        img: require('../assets/TrackYourJobs.svg'),
        title: i18n.t('builder.dashboard.main_banner.track_your_job.title'),
        text: i18n.t('builder.dashboard.main_banner.track_your_job.text'),
        tag: i18n.t('builder.dashboard.main_banner.track_your_job.tag'),
        tone10: Colors.Indigo10,
        tone15: '#EDEEFE',
        tone20: Colors.Indigo20,
        tone30: Colors.Indigo30,
        tone50: Colors.Indigo50,
        tone80: Colors.Indigo80,
        tone100: '#7477BC',
        tabIndex: [0, 2],
        url: 'job-tracking',
        bannerName: 'track_your_job',
        eventTarget: 'track_your_job',
        isPremiumService: true,
        cioDomainUrl: 'https://career.io/app/job-tracking',
      },
    ]
  }, [i18n, selectedIndex])

  const [currentIndex, setCurrentIndex] = useState(selectedIndex)
  const [filteredData, setFilteredData] = useState<ContentDataProp[]>(contentData)
  const [isHovered, setIsHovered] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const tab = useCareerServiceTitle({ index: selectedIndex })

  const { getHost } = useWebsiteHost()
  const isRioDomain = config?.features.international
  const isCioDomain = config?.features.superApp
  const user = useUser()
  const { url = '', bannerName = '', eventTarget = '' } = filteredData[currentIndex]

  // For Responsive UI
  const { isPhone } = useMediaQueries()

  // Get user data
  const userPhotoUrl = useTypedSelector(userSelectors.photoUrl)

  // Find user documents
  const resume = useTypedSelector(state => selectors.lastCreated(state, DocumentTypes.resume))
  const letter = useTypedSelector(state => selectors.lastCreated(state, DocumentTypes.coverLetter))

  const tunerResumeId = useTypedSelector(selectors.tunerResumeId)
  const latestId: number | undefined = resume?.id as number | undefined
  const latestDocument = useTypedSelector(selectors.documents)[0]

  const hasLetter = letter !== null
  const isSuperApp = config?.features.superApp
  const isFreePlan = !user?.hasPremiumFeatures

  // Change Array index using banner name
  const changeIndexByTitle = (array: ContentDataProp[], bannerName: string, newIndex: number) => {
    // Find the index of the element with the given title
    const currentIndex = array.findIndex(element => element.bannerName === bannerName)

    if (currentIndex !== -1) {
      // Remove the element from its current index
      const element = array.splice(currentIndex, 1)[0]

      // Insert the element at the new index
      array.splice(newIndex, 0, element)
    }

    return array
  }

  const saveBannerOrderInAccount = useCallback(
    (newOrder: ISmartBannerOrder) => {
      let newStateOrder = []
      for (let i = 0; i < 3; i++) {
        let localStorageTabBannerOrder = localStorage.getItem(getLocalStorageBannerKey(i))
        if (localStorageTabBannerOrder) {
          const orderSmartBanner =
            getLocalStorageBannerKey(i) === newOrder?.tab
              ? newOrder
              : JSON.parse(localStorageTabBannerOrder)
          newStateOrder.push({ tab: getLocalStorageBannerKey(i), ...orderSmartBanner })
        }
      }
      dispatch(userActions.updateSmartBannerOrderRequest(newStateOrder))
    },
    [dispatch],
  )

  const updateBannerOrderIfInActive = useCallback(
    (filterdData: ContentDataProp[]) => {
      try {
        let bannerQueueStatus: IBannerQueueJson | string | null = localStorage.getItem(
          getLocalStorageBannerKey(selectedIndex),
        )
        if (!bannerQueueStatus) {
          if (smartBannersOrderAccount && smartBannersOrderAccount?.length > 0) {
            smartBannersOrderAccount?.map((order: ISmartBannerOrder) => {
              return localStorage.setItem(
                order.tab,
                JSON.stringify({
                  idleBannerName: order.idleBannerName,
                  idleTimeStart: new Date(order.idleTimeStart),
                }),
              )
            })
          } else {
            bannerQueueStatus = {
              idleBannerName: filterdData?.[0]?.bannerName,
              idleTimeStart: new Date(),
            }
            localStorage.setItem(
              getLocalStorageBannerKey(selectedIndex),
              JSON.stringify(bannerQueueStatus),
            )
            saveBannerOrderInAccount({
              tab: getLocalStorageBannerKey(selectedIndex),
              ...bannerQueueStatus,
            })
          }
        } else {
          const { idleTimeStart, idleBannerName } = JSON.parse(
            bannerQueueStatus,
          ) as IBannerQueueJson

          const bannerIdleTime: number =
            (new Date().getTime() - new Date(idleTimeStart || '').getTime()) / 1000

          // Check if banner is idle for more than 3 days
          if (bannerIdleTime > getBannerIdleTimeLimitSeconds()) {
            const bannersListCurrent = filterdData?.map(item => item?.bannerName)

            const currentActiveBannerIndex = bannersListCurrent.findIndex(
              bannerName => bannerName === idleBannerName,
            )
            const newActiveBannerIndex =
              currentActiveBannerIndex === filterdData?.length ? 0 : currentActiveBannerIndex + 1

            const newIdleBannerName =
              filterdData?.[newActiveBannerIndex]?.bannerName || filterdData?.[0]?.bannerName

            // Set Latest Status in Local storage with new Updated new  Active Banner
            const latestBannerOrder = {
              idleBannerName: newIdleBannerName,
              idleTimeStart: new Date(),
            }
            localStorage.setItem(
              getLocalStorageBannerKey(selectedIndex),
              JSON.stringify(latestBannerOrder),
            )

            // Update new Banner Active state in Account
            saveBannerOrderInAccount({
              tab: getLocalStorageBannerKey(selectedIndex),
              ...latestBannerOrder,
            })
            return filterdData?.[newActiveBannerIndex]?.bannerName
          }
        }
      } catch (error) {
        ErrorLogger.log(error)
        return null
      }
    },
    [saveBannerOrderInAccount, selectedIndex, smartBannersOrderAccount],
  )

  useEffect(() => {
    setCurrentIndex(0)
    let filterdData =
      contentData.filter((item: { tabIndex: number[] }) => item.tabIndex.includes(selectedIndex)) ||
      []

    if (!tunerResumeId) {
      filterdData = filterdData.filter((item: { bannerName: string }) => {
        return (
          item.bannerName !== 'optimize_your_resume' && item.bannerName !== 'tailor_your_resume'
        )
      })
    }

    // Re-Order data for *Excel at Your Job* tab
    if (selectedIndex === 0) {
      if (isSuperApp) {
        changeIndexByTitle(filterdData, 'resume_distribution', 0)
        changeIndexByTitle(filterdData, 'find_your_next_job', 1)
        changeIndexByTitle(filterdData, 'optimize_your_resume', 2)
        changeIndexByTitle(filterdData, 'execute_a_job_search', 3)
      } else {
        if (isFreePlan) {
          changeIndexByTitle(filterdData, 'resume_distribution', 0)
          changeIndexByTitle(filterdData, 'optimize_your_resume', 1)
          changeIndexByTitle(filterdData, 'find_your_next_job', 2)
          changeIndexByTitle(filterdData, 'execute_a_job_search', 3)
        } else {
          changeIndexByTitle(filterdData, 'resume_distribution', 0)
          changeIndexByTitle(filterdData, 'find_your_next_job', 1)
          changeIndexByTitle(filterdData, 'optimize_your_resume', 2)
          changeIndexByTitle(filterdData, 'execute_a_job_search', 3)
        }
      }

      changeIndexByTitle(filterdData, 'create_a_cover_letter', 3)
      changeIndexByTitle(filterdData, 'ace_job_interview', 4)
      changeIndexByTitle(filterdData, 'know_your_worth', 5)
      changeIndexByTitle(filterdData, 'read_career_advice_tab_0', 6)
    }

    if (selectedIndex === 1) {
      changeIndexByTitle(filterdData, 'first_90_days_plan', 0)
      changeIndexByTitle(filterdData, 'get_a_promotion', 1)
      changeIndexByTitle(filterdData, 'explore_your_next_steps', 2)
      changeIndexByTitle(filterdData, 'know_your_worth', 3)
      changeIndexByTitle(filterdData, 'read_career_advice_tab_1', 4)
      changeIndexByTitle(filterdData, 'career_assessment', 0)
    }
    // Re-Order data for *Change Career* tab
    else if (selectedIndex === 2) {
      changeIndexByTitle(filterdData, 'career_assessment', 0)
      changeIndexByTitle(filterdData, 'read_career_advice_tab_2', 1)
      changeIndexByTitle(filterdData, 'explore_careers', 2)
      changeIndexByTitle(filterdData, 'explore_your_next_steps', 3)
      changeIndexByTitle(filterdData, 'know_your_worth', 4)
      changeIndexByTitle(filterdData, 'execute_a_job_search', 5)
      changeIndexByTitle(filterdData, 'find_your_next_job', 6)
      changeIndexByTitle(filterdData, 'ace_job_interview', 7)
    }

    // Find a New Job: put career assessment after any of these banners if available in order.
    if (features?.features?.careerProfile && selectedIndex === 0) {
      let prevIndex = -1
      prevIndex = filterdData.findIndex(banner => {
        return banner.enabled && banner.bannerName === 'find_your_next_job'
      })
      if (prevIndex === -1) {
        prevIndex = filterdData.findIndex(banner => {
          return banner.enabled && banner.bannerName === 'get_expert_help'
        })
      }
      if (prevIndex === -1) {
        prevIndex = filterdData.findIndex(banner => {
          return banner.enabled && banner.bannerName === 'resume_distribution'
        })
      }
      if (prevIndex !== -1) {
        changeIndexByTitle(filterdData, 'career_assessment', prevIndex + 1)
      }
    }

    setFilteredData(filterdData.filter(feat => feat.enabled))

    updateBannerOrderIfInActive(filterdData)
    let bannerQueueStatus: IBannerQueueJson | string | null = localStorage.getItem(
      getLocalStorageBannerKey(selectedIndex),
    )
    if (bannerQueueStatus) {
      const { idleBannerName } = JSON.parse(bannerQueueStatus) as IBannerQueueJson
      changeIndexByTitle(filterdData, idleBannerName, 0)
    }
  }, [
    contentData,
    isFreePlan,
    isSuperApp,
    selectedIndex,
    tunerResumeId,
    updateBannerOrderIfInActive,
  ])

  // Display next banner user Actions
  const handleClick = (e: { stopPropagation: () => void }) => {
    e.stopPropagation()
    let newBannerIndex = 0
    if (currentIndex === filteredData.length - 1) {
      setCurrentIndex(0)
    } else {
      setCurrentIndex(prevIndex => {
        newBannerIndex = prevIndex + 1
        const newBannerOrder = {
          idleBannerName: filteredData?.[newBannerIndex]?.bannerName,
          idleTimeStart: new Date(),
        }
        localStorage.setItem(
          getLocalStorageBannerKey(selectedIndex),
          JSON.stringify(newBannerOrder),
        )
        saveBannerOrderInAccount({
          tab: getLocalStorageBannerKey(selectedIndex),
          ...newBannerOrder,
        })
        return newBannerIndex
      })
    }
  }

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const openLink = (url: string) => {
    const cioUrl = 'https://career.io'
    const link = isRioDomain ? cioUrl : window.location.origin
    window.open(`${link}/${url}`, '_blank')
  }

  const onBannerClick = async () => {
    trackInternalEvent('click_smart_banner', {
      label: tab?.name,
      target: eventTarget,
      website_host: getHost,
    })
    const { cioDomainUrl } = filteredData[currentIndex]
    if (bannerName === 'career_assessment') {
      careerProfileNavigationHandler()
    } else if (bannerName === 'read_career_advice_tab_0') {
      openLink('career-advice/finding-a-job')
    } else if (bannerName === 'read_career_advice_tab_1') {
      openLink('career-advice/career-development')
    } else if (bannerName === 'read_career_advice_tab_2') {
      openLink('career-advice/career-change')
    } else if (cioDomainUrl === 'not available') {
      if (bannerName === 'optimize_your_resume') {
        if (!tunerResumeId) {
          dispatch(uiActions.openCreateResumeModal())
        } else {
          navigate(`/resumes/${latestId}/edit`)
        }
      } else if (bannerName === 'create_a_cover_letter') {
        dispatch(actions.createDocument({ type: DocumentTypes.coverLetter }))
      } else if (bannerName === 'get_expert_help') {
        if (isCioDomain) {
          window.open(
            'https://topresume.com/resume-writing?pt=UWYxd6Xe2VTS0&utm_source=careerio&utm_medium=cioplatformcard&utm_campaign=tr_smart_banner_in_ciodash&utm_content=v1',
            '_blank',
          )
        }
        if (isRioDomain) {
          window.open(
            'https://topresume.com/resume-writing?pt=UWYxd6Xe2VTS0&utm_source=resumeio&utm_medium=card&utm_campaign=tr_smart_banner_in_riodash&utm_content=v1',
            '_blank',
          )
        }
      } else if (bannerName === 'tailor_your_resume') {
        if (!tunerResumeId) {
          dispatch(uiActions.openCreateResumeModal())
          dispatch(actions.setTailorResume())
        } else {
          navigate(`/resumes/${latestId}/edit`)
          dispatch(editorActions.setOpenOptimizerModal(true))
        }
      } else if (bannerName === 'resume_distribution') {
        navigate('/resume-distribution')
      }
      url && navigate(url)
    } else {
      url && navigate(url)
    }
  }

  return {
    currentIndex,
    filteredData,
    isHovered,
    isPhone,
    userPhotoUrl,
    resume,
    latestDocument,
    letter,
    hasLetter,
    handleClick,
    handleMouseEnter,
    handleMouseLeave,
    onBannerClick,
  }
}
