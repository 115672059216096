import { createContext } from 'react'

interface ContextInterface {
  jobTitle: string | null
  isSuggestionsOpen: boolean
  toggleSuggestions: (state: { isOpen?: boolean; trigger?: string }) => void
  toggleAiProfileSummary: (state: { isOpen?: boolean; trigger?: string }) => void
  toggleAiProfileSummaryResult: (state: { isOpen?: boolean }) => void
  suggestionQuery: string
  onChangeSuggestionQuery: (text: string) => void
  openHints: () => void
  closeHints: () => void
  hideHintsButtons: () => void
  isHintsButtonsVisible: boolean
  isFocused: boolean
  isHintsOpened: boolean
  isSuggestionVariantsOpen: boolean
  toggleSuggestionVariants: (newState?: { isOpen?: boolean }) => void
}

export const RichTextAreaContext = createContext<ContextInterface>({
  jobTitle: '',
  isSuggestionsOpen: false,
  toggleSuggestions: () => {},
  toggleAiProfileSummary: () => {},
  toggleAiProfileSummaryResult: () => {},
  suggestionQuery: '',
  onChangeSuggestionQuery: () => {},
  openHints: () => {},
  closeHints: () => {},
  hideHintsButtons: () => {},
  isHintsButtonsVisible: false,
  isFocused: false,
  isHintsOpened: false,
  isSuggestionVariantsOpen: false,
  toggleSuggestionVariants: () => {},
})
