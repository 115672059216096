import { useRef, memo } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useAppSelector } from 'builder/hooks/useAppSelector'
import { Button } from 'builder/components/Button'
import { i18n as I18n } from 'builder/utils/i18n'
import { actions } from 'builder/modules/resumeEditor'
import {
  PrefillResumeEvents,
  ResumeUploadStatus,
  ResumeValidationStatus,
} from 'builder/modules/constants'
import FileSelector from 'builder/components/FillResumeModal/UploadResume/FileSelector/FileSelector'
import { ButtonContainer, Div } from 'builder/components/FillResumeModal/UploadResume/styles'

const ActionLayout = ({
  onSubmit,
  addSpaceWhenNotVisible = false,
}: {
  onSubmit: (file: File) => void
  addSpaceWhenNotVisible?: boolean
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const dispatch = useDispatch()
  const {
    resumeEditor: {
      prefillResumeSection: {
        isSavingPrefilledResume,
        resumeValidationStatus,
        selectedResume,
        resumeUploadStatus,
      },
    },
  } = useAppSelector(state => state)

  const handleFileChange = () => {
    trackInternalEvent(PrefillResumeEvents.clickChangeFile)
    dispatch(actions.setResumeUploadStatus(ResumeUploadStatus.notStarted))
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const showChangeFileButton = [
    ResumeValidationStatus.validated,
    ResumeValidationStatus.failed,
  ].includes(resumeValidationStatus)
  const showDoneButton =
    resumeValidationStatus === ResumeValidationStatus.validated &&
    resumeUploadStatus !== ResumeUploadStatus.failed

  return (
    <ButtonContainer {...{ showDoneButton, addSpaceWhenNotVisible }}>
      {showChangeFileButton && (
        <Div onClick={handleFileChange}>
          <Button theme="ghost">{I18n.t('builder.upload_resume.change_file')}</Button>
          <FileSelector ref={fileInputRef} />
        </Div>
      )}
      {showDoneButton && (
        <Button
          isLoading={isSavingPrefilledResume}
          onClick={() => {
            if (selectedResume) {
              onSubmit(selectedResume)
            }
          }}
        >
          {I18n.t('builder.upload_resume.done')}
        </Button>
      )}
    </ButtonContainer>
  )
}

export default memo(ActionLayout)
