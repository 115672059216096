import { useLayoutEffect, useRef } from 'react'
import { reflow } from 'builder/utils/reflow'
import { useClickOutsideExcept } from 'builder/hooks/useClickOutsideExcept'
import { Container } from './styles'
import useImproveResumePanel from './hooks/useImproveResumePanel'
import { CompletenessSuggests } from './components/CompletenessSuggests/CompletenessSuggests'
import { AISuggests } from './components/AISuggests/AISuggests'
import { TailoredSuggests } from './components/TailoredSuggests/TailoredSuggests'

type Props = {
  improveResumeButtonRef: React.RefObject<HTMLDivElement>
}

const ImproveResumePanel: React.FC<Props> = ({ improveResumeButtonRef }) => {
  const {
    isPageScrolled,
    isImproveResumePanelOpen,
    isAICoverLetterActive,
    isAIProfileWriterActive,
    isSuggestionsActive,
    handleClosePanel,
    isResumeOptimizerFeatureEnabled,
  } = useImproveResumePanel()
  const ref = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (ref.current) reflow(ref.current)
  }, [ref])

  useClickOutsideExcept(ref, [improveResumeButtonRef], handleClosePanel)

  return (
    <Container
      isPageScrolled={isPageScrolled}
      isImproveResumePanelOpen={isImproveResumePanelOpen}
      ref={ref}
    >
      {isResumeOptimizerFeatureEnabled && <TailoredSuggests />}
      {(isAICoverLetterActive || isAIProfileWriterActive) && <AISuggests />}
      {isSuggestionsActive && <CompletenessSuggests />}
    </Container>
  )
}

export default ImproveResumePanel
