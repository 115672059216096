import { IVisualProps } from 'builder/components/CIO-Dasboard-2.0/types'
import ComingSoonTag from '../../Tags/ComingSoonTag'
import * as Styled from './styles'

export const GetResumeCritiqueVisual = ({ imgUrl, alt }: IVisualProps) => {
  return (
    <Styled.VisualContainer>
      <ComingSoonTag />
      <Styled.VisualImageContainer>
        <Styled.VisualImage src={imgUrl} alt={alt} />
      </Styled.VisualImageContainer>
    </Styled.VisualContainer>
  )
}
