import styled, { css } from 'styled-components'
import Media from 'builder/styles/media'
import Button from 'builder/components/Button'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 50%;
  min-height: 100vh;

  ${Media.Laptop`
    width: 100%;
    margin: 0 auto;
  `};

  ${Media.Desktop`
    max-width: 960px;
  `};
`

export const LeftSide = styled.div`
  width: 100%;
  max-width: 100%;
  flex: 1;
`

export const FloatingDownloadPdfButton = styled(Button)`
  position: fixed;
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s 0s ease, opacity 0.2s ease, transform 0.2s ease,
    background-color 0.1s ease, box-shadow 0.1s ease;

  ${Media.Laptop`
    right: 30px;
    bottom: 97px;
    border-radius: 36px;
    padding-right: 13px;
    transform: translateX(12px);

    &:after {
      content: '';
      display: inline-block;
      width: 24px;
      height: 24px;
      background-image: url(${require('images/builder/resume-editor/preview-document.svg')});
      background-size: contain;
      margin-left: 8px;

      position: relative;
      top: -1px;
    }
  `}

  ${Media.Desktop`
    bottom: 120px;
    left: 75%;
    transform: translateX(-50%) translateY(12px);
  `}

  @media (min-width: 1920px) {
    left: calc(50% + 480px);
  }

  ${({ isShow }) =>
    isShow &&
    css`
      visibility: visible;
      opacity: 1;

      ${Media.Laptop`
        transform: translateX(0) translateY(0);
      `}

      ${Media.Desktop`
        transform: translateX(-50%) translateY(0);
      `}
    `}
`
