import styled from 'styled-components'
import YouTube from 'react-youtube'
import Colors from 'builder/styles/colors'

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 1;
  position: absolute;
`

export const Wrapper = styled.div`
  width: 100%;
  cursor: pointer;
  position: relative;

  &:hover ${Overlay} {
    opacity: 0.1;
    background-color: ${Colors.Neutral90};
  }
`

export const Thumbnail = styled.img`
  width: 100%;
  object-fit: cover;
`

export const PlayIcon = styled.img`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
`

export const StyledYT = styled(YouTube)`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;

  & > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
