import React from 'react'
import { useDispatch } from 'react-redux'
import { actions, ConfigScopesEnum, selectors } from 'builder/modules/init'
import { useConfig } from 'builder/hooks/useConfig'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { FetchStatuses } from 'builder/modules/constants'

type ConfigScopeProps = {
  children: React.ReactElement
  scope: ConfigScopesEnum
}

export const ConfigScope = ({ scope, children }: ConfigScopeProps) => {
  const config = useConfig(scope)
  const fetchStatus = useTypedSelector(store => selectors.configFetchStatus(store, scope))
  const dispatch = useDispatch()
  const shouldFetchConfig = fetchStatus === FetchStatuses.notAsked

  React.useEffect(() => {
    if (shouldFetchConfig) {
      dispatch(actions.fetchConfig({ scope }))
    }
  }, [dispatch, shouldFetchConfig, scope])

  return config ? children : null
}
