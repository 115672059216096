export const MIXED_OPTIONS = [
  { id: null, name: 'Null Option' },
  { id: 1, name: '1 Number Option' },
  { id: 2, name: '2 Number Option' },
  { id: 'test', name: 'Test String Option' },
]

export const NUMBER_OPTIONS = [
  { id: 1, name: 'Number 1' },
  { id: 2, name: 'Number 2' },
  { id: 3, name: 'Number 3' },
]

export type TurtleName = 'michelangelo' | 'leonardo' | 'donatello' | 'raphael'

export const TYPED_OPTIONS: Array<{ id: TurtleName; name: string }> = [
  { id: 'michelangelo', name: 'Michelangelo' },
  { id: 'leonardo', name: 'Leonardo' },
  { id: 'donatello', name: 'Donatello' },
  { id: 'raphael', name: 'Raphael' },
]
