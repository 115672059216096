import { SectionContainer, SectionTitle, SectionContent } from './styles'

type Props = React.HTMLAttributes<HTMLDivElement> & { title: string }

export const Section = ({ title, children, ...props }: Props) => (
  <SectionContainer {...props}>
    <SectionTitle>{title}</SectionTitle>
    <SectionContent>{children}</SectionContent>
  </SectionContainer>
)
