import styled from 'styled-components'

import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Icon24 from 'builder/components/Icon'
import { CircleButton } from 'builder/components/Button'
import Media from 'builder/styles/media'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 32px 0;
  align-items: center;
`

export const Section = styled.div<{ size?: number }>`
  flex: ${props => props.size || 1};
  height: 100%;
  box-sizing: border-box;
  padding: 0 10px;
  overflow: hidden;

  ${Media.Tablet`
    flex-basis: calc(25% - 20px);
    margin-bottom: 20px;

    &:first-child,
    &:nth-child(2) {
      flex-basis: 50%;
    }
  `};

  ${Media.Phone`
    flex-basis: calc(50% - 20px);
    margin-bottom: 20px;
  `};
`

export const UserName = styled.div`
  ${Typography.M};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0;
`

export const JobTitle = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0;
`

export const Header = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral90};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0;
`

export const SubHeader = styled.div`
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0;
`

export const Box = styled.div<{ position?: 'start' | 'end' | 'center' }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${props => (props.position === 'center' ? 'center' : `flex-${props.position}`)};
  overflow: hidden;
`

export const EditButtonIcon = styled(Icon24.EditAlt)`
  width: 24px;
  height: 24px;
  cursor: pointer;

  path {
    stroke: ${Colors.Neutral30};
    stroke-width: 1.6px;
  }
`

export const EditButton = styled(CircleButton)`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${Colors.Neutral10};
  padding: 0;

  &:focus {
    background-color: ${Colors.Neutral10};
  }

  &:hover {
    background-color: ${Colors.Blue10};
  }

  &:hover ${EditButtonIcon} {
    background-color: ${Colors.Blue10};
    path {
      stroke: ${Colors.Blue60};
    }
  }
`
