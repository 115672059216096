import styled, { css } from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const SectionContainer = styled.div<{ isDragging?: boolean; value: string }>`
  position: relative;
  margin-bottom: 40px;
  opacity: ${props => (props.isDragging ? 0.6 : 1)};

  /* fix cursor */
  pointer-events: auto !important;
  -webkit-touch-callout: none;
  user-select: none;

  &:before {
    content: '';
    display: ${props => (props.isDragging ? 'block' : 'none')};
    position: absolute;
    left: -24px;
    right: -20px;
    bottom: -16px;
    top: -16px;
    border-radius: 4px;
    background-color: ${Colors.White};
    box-shadow: 0px 3px 12px rgba(112, 131, 151, 0.3);
    pointer-events: none;

    ${Media.Laptop`
      left: -20px;
    `};
  }

  &:after {
    content: '';
    display: ${props => (props.isDragging ? 'block' : 'none')};
    position: absolute;
    height: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, ${hexToRgba(Colors.White, 0)}, ${Colors.White});
    pointer-events: none;
  }
`

export const SectionHeaderContainer = styled.div<{ compact?: boolean }>`
  position: relative;
  margin-bottom: 16px;

  ${props =>
    !props.compact &&
    Media.AbovePhone`
      margin-bottom: 24px;
    `}
`

export const SectionContent = styled.div<{ isDragging?: boolean }>`
  position: relative;

  ${props =>
    props.isDragging &&
    css`
      overflow: hidden;
      max-height: 140px;
    `}
`

export const ExtraContainer = styled.div`
  margin-top: 12px;
`

export const SectionHintContainer = styled.div`
  margin-top: 2px;
  ${Typography.Caption};
  color: ${Colors.Neutral50};
`

export const SectionSubtitleContainer = styled.div`
  margin-top: 8px;

  ${Media.Phone`
    margin-top: 2px;
    ${Typography.Caption};
  `}
`
