import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd'
import { useDispatch } from 'react-redux'
import { first, size } from 'lodash'
import { trackInternalEvent } from '@rio/tracking'
import {
  ColumnName,
  Job,
  UpdateSelectedResumePayload,
  actions,
  selectors,
} from 'builder/modules/jobTracking'
import { actions as editorActions } from 'builder/modules/resumeEditor'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useUser } from 'builder/hooks/useUser'
import Colors, { hexToRgba } from 'builder/styles/colors'
import { FetchStatuses } from 'builder/modules/constants'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { i18n } from 'builder/utils/i18n'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { selectors as jobSearchSelectors } from 'builder/modules/jobSearch'
import { Icon20 } from 'builder/components/Icon'
import { ButtonSize } from '../Button'
import { WishlistBanner } from './WishlistBanner'
import { TunerScoreSmall } from './TunerScoreSmall'
import {
  CardContainer,
  CardLogo,
  CardCompany,
  CardPosition,
  ResumeScore,
  Text,
  CardButton,
  TailorYourResume,
  ButtonWrapper,
  AutoApplyCardButton,
  CardHeader,
} from './styles'
import { AutoApplyStatusLabel } from './AutoApplyStatusLabel'
import { ResumePercentage } from './Sidebar/styles'

type CardProps = {
  item: Job
  lastUpdateTime?: number
  provided: DraggableProvided
  snapshot: DraggableStateSnapshot
  onClick: (id: number) => void
  isDragging: boolean
  handleSetInterviewJob: (job: Job) => void
  handleAutoApplyCard?: (job: Job) => void
  triggerCardClick: (id: number, openCardDrawer?: boolean) => void
}

const LABEL = {
  label: 'job_card',
}

const JobCard = ({
  item,
  provided,
  snapshot,
  onClick,
  isDragging,
  handleSetInterviewJob,
  handleAutoApplyCard,
  triggerCardClick,
}: CardProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useUser()
  const mediaQueries = useMediaQueries()
  const { features } = useFeaturesConfig()
  // const { i18n } = useI18n()
  const {
    id,
    status,
    autoApplyStatus,
    resumeId,
    showBanner,
    resumeScore,
    link,
    title,
    description,
    chromeJob,
    externalSlugId,
  } = item
  const resumeList = useTypedSelector(selectors.selectResumeList)
  const updateResumeStatus = useTypedSelector(selectors.selectFetchUpdateResumeStatus)
  const autoApplyBubbles = useTypedSelector(jobSearchSelectors.autoApplyBubbles)

  const resumeExist = status === ColumnName.wishlist && resumeId
  const resumeNotExist = status === ColumnName.wishlist && !resumeId
  const isImproveYourScore = resumeExist && resumeScore < 70
  const isTailorYourResume =
    resumeNotExist &&
    size(resumeList) > 0 &&
    title &&
    description &&
    (chromeJob || externalSlugId != null)

  const isWishlistBanner = resumeNotExist && showBanner
  const validStatuses = [ColumnName.applied, ColumnName.interview]
  const isCardButton = validStatuses.includes(status)
  const statusTextMap: Partial<Record<ColumnName, string>> = {
    [ColumnName.applied]: i18n.t('builder.job_tracking.job_tracking_card.applied_button'),
    [ColumnName.interview]: i18n.t('builder.job_tracking.job_tracking_card.interview_button'),
  }

  const statusText = statusTextMap[status]

  const updateResumeLoading = useMemo(() => {
    return updateResumeStatus?.id === id && updateResumeStatus?.status === FetchStatuses.loading
  }, [updateResumeStatus, id])

  const handleClick = (event: React.MouseEvent | React.TouchEvent) => {
    // `React.MouseEvent` doesn't include "nodeName" attr so we have to force a type here
    const { nodeName } = event.target as HTMLDivElement

    // do not open the modal is user has clicked on job posting link
    if (nodeName.toLowerCase() === 'a') return

    onClick(item.id)
  }

  const handleAction = (event: React.MouseEvent | React.TouchEvent) => {
    if (isDragging) return
    if (autoApplyBubbles.running) {
      return
    }

    if ((mediaQueries.isPhone || mediaQueries.isTablet) && event.type === 'touchend') {
      return
    }
    handleClick(event)
  }

  const handleOptimizer = useCallback(
    (id: number, fromTailorYourResume = false) => {
      dispatch(editorActions.setFromJobTracker(true))

      if (fromTailorYourResume) {
        dispatch(editorActions.setOpenOptimizerModal(true))
        dispatch(editorActions.startResumeOptimizerProcess())
        dispatch(editorActions.setJobPostingLinkForModal(link || ''))
      } else {
        const editorUrl = `/resumes/${id}/edit`
        navigate(editorUrl)
      }
    },
    [dispatch, navigate, link],
  )

  const handleTailorResume = (event: React.MouseEvent, isWishlistBanner: boolean) => {
    trackInternalEvent('click_banner_optimizer')
    event.stopPropagation()
    event.preventDefault()

    const resumeMultiSelect = size(resumeList) > 1
    const firstResume = first(resumeList)

    if (!isWishlistBanner) {
      trackInternalEvent('click_tailor_resume_button', LABEL)
    }

    if (resumeMultiSelect) {
      dispatch(actions.setResumeSelection({ id, link }))
    } else {
      handleOptimizer(firstResume?.id as number, true)
      dispatch(
        actions.UpdateSelectedResumeRequest({
          user_id: user?.id,
          resume_id: firstResume?.id,
          link,
          job_card_id: id,
        } as UpdateSelectedResumePayload),
      )
    }
  }

  const handleImproveScore = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation()
      event.preventDefault()
      handleOptimizer(item?.resumeId)
      trackInternalEvent('click_improve_resume')
    },
    [item?.resumeId, handleOptimizer],
  )

  const handleCardButtonClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation()
    if (isCardButton) {
      const salaryAnalyzerUrl = `/offer-analyzer`
      if (status === ColumnName.wishlist) {
        handleTailorResume(e, true)
      } else if (status === ColumnName.applied) {
        trackInternalEvent('click_interview', { label: 'job_card' })
        handleSetInterviewJob(item)
      } else if (status === ColumnName.interview) {
        trackInternalEvent('click_salary_analyzer', { label: 'job_card' })
        navigate(salaryAnalyzerUrl)
      }
    }
  }

  const handleMoreIconAction = (
    e: React.MouseEvent<HTMLOrSVGElement> | React.TouchEvent<HTMLOrSVGElement>,
  ) => {
    e.stopPropagation()
    triggerCardClick(item.id, false)
    dispatch(actions.setOpenStatusChangeDrawer(true))
  }

  return (
    <CardContainer
      data-testid="job-card"
      ref={provided.innerRef}
      isDragging={snapshot.isDragging && !snapshot.isDropAnimating}
      onClick={handleAction}
      onTouchEnd={handleAction}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={{
        opacity: item?.isIrrelevant ? '0.5' : '1',
        cursor: snapshot.isDragging ? 'grabbing' : 'pointer',
        ...provided.draggableProps.style,
      }}
    >
      <CardHeader>
        <CardLogo
          data-testid="job-card-logo"
          size={32}
          src={item.logo}
          alt={item.company}
          borderRadius="4px"
          border={`1px solid ${hexToRgba(Colors.Neutral100, 0.1)};`}
        />
        {mediaQueries.isPhone && <Icon20.More onClick={handleMoreIconAction} />}
      </CardHeader>
      {item.company && <CardCompany>{item.company}</CardCompany>}

      {item.title && <CardPosition>{item.title}</CardPosition>}

      {features.resumeOptimizer && isWishlistBanner && (
        <WishlistBanner onClick={e => handleTailorResume(e, true)} />
      )}

      {features.resumeOptimizer && resumeScore && resumeExist && (
        <ResumeScore score={resumeScore}>
          <TunerScoreSmall score={resumeScore} />
          <ResumePercentage poorResumeScore={Boolean(isImproveYourScore)} simple>
            {resumeScore}%
          </ResumePercentage>
          <Text>
            {resumeScore < 70
              ? i18n.t('builder.job_tracking.job_tracking_card.resume_match')
              : i18n.t('builder.job_tracking.job_tracking_card.ready_to_apply')}
          </Text>
          {isImproveYourScore && (
            <ButtonWrapper isImproveResume={true}>
              <TailorYourResume size={ButtonSize.small} onClick={handleImproveScore}>
                {i18n.t('builder.job_tracking.job_tracking_card.wishlist_button_one')}
              </TailorYourResume>
            </ButtonWrapper>
          )}
        </ResumeScore>
      )}

      {/* Tailor Resume Button when Auto Apply Disabled */}
      {!handleAutoApplyCard && features.resumeOptimizer && isTailorYourResume && (
        <ButtonWrapper>
          <TailorYourResume
            size={ButtonSize.small}
            onClick={handleTailorResume}
            isLoading={updateResumeLoading}
          >
            {i18n.t('builder.job_tracking.job_tracking_card.wishlist_button_two')}
          </TailorYourResume>
        </ButtonWrapper>
      )}

      {handleAutoApplyCard && (
        <ButtonWrapper
          addScoreSeparator={!!(features.resumeOptimizer && resumeScore && resumeExist)}
        >
          <AutoApplyCardButton
            size={ButtonSize.small}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation()
              handleAutoApplyCard(item)
              trackInternalEvent('auto_apply_for_job', {
                label: 'job_tracker',
                parameter: 'job_card',
              })
            }}
          >
            {i18n.t(`builder.job_tracking.edit_modal.auto_apply_label`)}
          </AutoApplyCardButton>
        </ButtonWrapper>
      )}

      {/* Tailor Resume Button when Auto Apply Enabled */}
      {handleAutoApplyCard && features.resumeOptimizer && isTailorYourResume && (
        <ButtonWrapper>
          <AutoApplyCardButton
            size={ButtonSize.small}
            onClick={handleTailorResume}
            isLoading={updateResumeLoading}
            secondary
          >
            {i18n.t('builder.job_tracking.job_tracking_card.wishlist_button_two')}
          </AutoApplyCardButton>
        </ButtonWrapper>
      )}

      {isCardButton && status === ColumnName.applied && features.interviewSchool && (
        <CardButton onClick={handleCardButtonClick} status={status} size={ButtonSize.small}>
          {statusText}
        </CardButton>
      )}

      {isCardButton && status === ColumnName.interview && features.salaryAnalyzer && (
        <CardButton onClick={handleCardButtonClick} status={status} size={ButtonSize.small}>
          {statusText}
        </CardButton>
      )}

      {autoApplyStatus && (
        <AutoApplyStatusLabel {...{ autoApplyStatus, style: { marginTop: '12px' } }} />
      )}
    </CardContainer>
  )
}

export default JobCard
