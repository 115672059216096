import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Sizes from 'builder/styles/sizes'
import Media from 'builder/styles/media'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.White};
  border-radius: ${Sizes.S};
  padding: 183px 95px;
  text-align: center;

  ${Media.Phone`
    padding: 183px 0;
  `}
`

export const Title = styled.span`
  color: ${Colors.Neutral90};
  ${FontWeights.DemiBold};
  ${Typography.S};
  max-width: 398px;
  width: 100%;

  &.collapsed {
    max-width: 300px;
  }
`

export const Subtitle = styled.span`
  color: ${Colors.Neutral60};
  ${Typography.Body};
  max-width: 360px;
  width: 100%;
  margin-top: ${Sizes['2XS']};
  margin-bottom: ${Sizes.S};

  &.expanded {
    max-width: 480px;
  }
`

export const ImageContainer = styled.div`
  width: 320px;
  height: 216px;
  position: relative;
  overflow: hidden;
`

export const EllipseOne = styled.div`
  position: absolute;
  border-radius: 100%;
  transform: translate(-50%);
  left: calc(50%);
  width: 100%;
  height: calc(150%);
  background: radial-gradient(
    65% 50% at 50% 50%,
    rgba(195, 229, 254, 0) 68.75%,
    ${Colors.Blue10} 100%
  );
`

export const EllipseTwo = styled.div`
  position: absolute;
  border-radius: 100%;
  top: 40px;
  transform: translate(-50%);
  left: calc(50%);
  width: calc(80%);
  height: calc(120%);
  background: radial-gradient(
    60% 50% at 50% 50%,
    rgba(195, 229, 254, 0) 68.75%,
    ${Colors.Blue10} 100%
  );
`

export const EllipseThree = styled.div`
  position: absolute;
  border-radius: 100%;
  top: 80px;
  transform: translate(-50%);
  left: calc(50%);
  width: calc(60%);
  height: calc(100%);
  background: radial-gradient(
    80% 50% at 50% 50%,
    rgba(195, 229, 254, 0) 40%,
    ${Colors.Blue10} 100%
  );
`

export const AvatarIconContainer = styled.div`
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 3px solid ${Colors.Blue40};
  z-index: 2;
  position: absolute;
  transform: translate(-50%);
  left: calc(50%);
  bottom: 24px;
`

export const AvatarIcon = styled.img`
  width: 64px;
  height: 64px;
  flex: 0 0 64px;
  border-radius: 50%;
`

export const CompanyImage = styled.img`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  border-radius: 100%;
  background-color: white;
  box-shadow: 0px 1.5px 3px -0.75px rgba(15, 56, 113, 0.08),
    0px 6px 15px -3px rgba(15, 56, 113, 0.12);
`

export const AppleImage = styled(CompanyImage)`
  width: 44px;
  height: 44px;
  top: 46px;
  left: 236px;
`

export const GoogleImage = styled(CompanyImage)`
  width: 32px;
  height: 32px;
  top: 168px;
  left: 53px;
`

export const AmazonImage = styled(CompanyImage)`
  width: 36px;
  height: 36px;
  top: 157px;
  left: 234px;
`

export const MetaImage = styled(CompanyImage)`
  width: 36px;
  height: 36px;
  top: 60px;
  left: 21px;
`

export const IbmImage = styled(CompanyImage)`
  width: 28px;
  height: 28px;
  top: 100px;
  left: 89px;
`

export const MicrosoftImage = styled(CompanyImage)`
  width: 28px;
  height: 28px;
  top: 81px;
  left: 184px;
`

export const NoRoleImage = styled.img`
  width: 134px;
  height: 115px;
  margin-bottom: ${Sizes.S};
`

export const NoLocationImage = styled.img`
  width: 197px;
  height: 114px;
  margin-bottom: ${Sizes.S};
`

export const NoWorkExperienceImage = styled.img`
  width: 131px;
  height: 146px;
  margin-bottom: ${Sizes.S};
`

export const CustomContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.Neutral5};
  border-radius: ${Sizes.S};
  padding: 40px 144px ${Sizes['4XL']};
  text-align: center;

  ${Media.Phone`
    padding: 40px 10px;
  `}
`
