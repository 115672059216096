import { css, CSSProp } from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'

const lightShadowColor = Colors.Blue80
const darkShadowColor = Colors.Neutral100

const getLightShadowColor = (alpha: number) => hexToRgba(lightShadowColor, alpha)
const getDarkShadowColor = (alpha: number) => hexToRgba(darkShadowColor, alpha)

const makeCssRule = (cssRuleKey: string, cssRuleValue: string): CSSProp =>
  css`
    ${cssRuleKey}: ${cssRuleValue}
  `

const makeBoxShadowRule = (value: string) => makeCssRule('box-shadow', value)

type ShadowVariations = {
  low: CSSProp
  mid: CSSProp
  high: CSSProp
}

type ShadowVariantName = 'light' | 'lightWithBorder' | 'dark' | 'template'

type ShadowsType = Record<ShadowVariantName, ShadowVariations>

/**
 * Shadows copied from our design system:
 * https://www.figma.com/file/ZY7L17bKSFLWnSf3Se84uzYn/Design-System?node-id=4055%3A3824
 *
 * @example
 * const Block = styled.div`
 *   ${Shadows.light.high};
 * `
 */

export const Shadows: ShadowsType = {
  light: {
    low: makeBoxShadowRule(`
      0px 1px 2px ${getLightShadowColor(0.12)},
      0px 2px 4px ${getLightShadowColor(0.08)}
    `),
    mid: makeBoxShadowRule(`
      0px 2px 4px -1px ${getLightShadowColor(0.08)},
      0px 8px 20px -4px ${getLightShadowColor(0.12)}
    `),
    high: makeBoxShadowRule(`
      0px 6px 8px -2px ${getLightShadowColor(0.02)},
      0px 16px 64px -8px ${getLightShadowColor(0.18)}`),
  },
  lightWithBorder: {
    low: makeBoxShadowRule(`
      0px 0px 1px ${getLightShadowColor(0.32)},
      0px 1px 2px ${getLightShadowColor(0.12)},
      0px 2px 4px ${getLightShadowColor(0.08)}
    `),
    mid: makeBoxShadowRule(`
      0px 0px 1px ${getLightShadowColor(0.32)},
      0px 2px 4px -1px ${getLightShadowColor(0.08)},
      0px 8px 20px -4px ${getLightShadowColor(0.12)}
    `),
    high: makeBoxShadowRule(`
      0px 0px 1px ${getLightShadowColor(0.32)},
      0px 6px 8px -2px ${getLightShadowColor(0.02)},
      0px 16px 64px -8px ${getLightShadowColor(0.18)}
    `),
  },
  dark: {
    low: makeBoxShadowRule(`
      0px 1px 2px ${getDarkShadowColor(0.24)},
      0px 2px 4px ${getDarkShadowColor(0.16)}
    `),
    mid: makeBoxShadowRule(`
      0px 2px 4px -1px ${getDarkShadowColor(0.16)},
      0px 8px 20px -4px ${getDarkShadowColor(0.24)}
    `),
    high: makeBoxShadowRule(`
      0px 6px 8px -2px ${getDarkShadowColor(0.08)},
      0px 16px 64px -8px ${getDarkShadowColor(0.32)}
    `),
  },
  template: {
    low: makeBoxShadowRule(`
      0px 0px 1px ${getLightShadowColor(0.4)},
      0px 2px 4px ${getLightShadowColor(0.08)}
    `),
    mid: makeBoxShadowRule(`
      0px 0px 1px ${getLightShadowColor(0.16)},
      0px 8px 20px -4px ${getLightShadowColor(0.08)}
    `),
    high: makeBoxShadowRule(`
      0px 0px 1px ${getLightShadowColor(0.08)},
      0px 16px 64px -8px ${getLightShadowColor(0.08)}
    `),
  },
}

export default Shadows
