import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { selectors } from 'builder/modules/init'
import { useI18n } from 'builder/hooks/useI18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import useWebsiteHost, {
  rioDomainList,
  cioDomainList,
} from 'builder/components/CIO-Dasboard-2.0/hooks/useWebsiteHost'

import { capitalize } from 'builder/utils/capitalize'
import { ImageAnimation, Container, Image, Text } from './styles'

import LOADER_ILLUSTRATION from './assets/img/freeTrailLoad.gif'
import RIO_LOGO from './assets/img/rio-ilustration.svg'

const PremiumTrialLoader = () => {
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const location = useLocation()
  const { getHost } = useWebsiteHost()
  const logo = useTypedSelector(selectors.logo)
  const imageUrl = logo?.default
  const isRioDomain = rioDomainList.includes(getHost)
  const isCioDomain = cioDomainList.includes(getHost)

  const queryParams = new URLSearchParams(location.search)
  const premiumDays = queryParams?.get('premium_days') || 7

  const getHostName = () => {
    return getHost.includes('topresume') ? 'TopResume Career Platform' : getHost
  }

  useEffect(() => {
    setTimeout(() => {
      navigate(`/premium-trail-activated${location.search}`)
    }, 3000)
  }, [navigate, location])

  return (
    <Container>
      {isCioDomain && (
        <Image src={LOADER_ILLUSTRATION} alt="Free Trail Loading" data-testid="cio-loader-icon" />
      )}
      {isRioDomain && (
        <ImageAnimation src={RIO_LOGO} alt="Free Trail Loading" data-testid="rio-loader-icon" />
      )}
      {!isCioDomain && !isRioDomain && (
        <ImageAnimation src={imageUrl} alt="Free Trail Loading" data-testid="logo-loader-icon" />
      )}
      <Text>
        {i18n.t('builder.premium_trial_loader.text', {
          days: premiumDays,
          domain: capitalize(getHostName()),
        })}
      </Text>
    </Container>
  )
}

export default PremiumTrialLoader
