import EditorField from 'builder/components/EditorField'
import { TextField } from 'builder/components/TextField'
import { useWorkExperienceCardContext } from '../../ExpandableCardContext'
import { useShokumuTranslations } from '../../useShokumuTranslations'

const FoundationYear = (): JSX.Element => {
  const {
    item: { employerProfilesAttributes },
    onCardValueChange,
  } = useWorkExperienceCardContext()

  const { i18n } = useShokumuTranslations()
  const label = i18n('work_experience.employer_details.foundation_year')

  const updateFoundationYear = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
    const currentYear = new Date().getFullYear()
    const isInputValid = /^\d+$/.test(inputValue) && Number(inputValue) <= currentYear
    /* Validation - If the input is a valid number format (through regex) 
                  - and that it doesn't exceed the current year */

    if (inputValue === '' || isInputValid) {
      onCardValueChange(
        {
          employerProfilesAttributes: {
            ...employerProfilesAttributes,
            foundationYear: e.target.value,
          },
        },
        true,
      )
    }
  }

  return (
    <EditorField>
      <TextField
        label={label}
        name="foundationYear"
        onChange={updateFoundationYear}
        value={employerProfilesAttributes?.foundationYear || ''}
        maxLength={4}
      />
    </EditorField>
  )
}
export default FoundationYear
