import * as React from 'react'
import MediaQueries from './MediaQueries'

export default function withMediaQuery(Component) {
  return class MediaQueriesHoc extends React.Component {
    render() {
      return (
        <MediaQueries>
          {matches => <Component mediaQueries={matches} {...this.props} />}
        </MediaQueries>
      )
    }
  }
}
