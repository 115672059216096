import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Typography from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import { Icon24 } from 'builder/components/Icon'
import Button from 'builder/components/Button'
import Tooltip from 'builder/components/Tooltip'
import ResumeCritique from 'builder/components/ResumeCritique'
import DocumentImagePreview from 'builder/components/DocumentImagePreview'
import { Container as BaseContainer } from 'builder/components/Container'

export const Container = styled(BaseContainer)`
  padding-top: 32px;
  padding-bottom: 80px;

  ${Media.Tablet`
    padding-bottom: 40px;
  `};

  ${Media.Phone`
    padding: 0;
  `};
`

export const Header = styled.header`
  position: relative;
  padding: 0 256px;
  margin-bottom: 40px;
  text-align: center;

  ${Media.Tablet`
    padding: 0 64px;
  `};

  ${Media.Phone`
    padding: 0 64px;
    margin: 20px;
  `};
`

export const HeaderTitle = styled.h1`
  ${Typography.L};
  font-weight: 600;

  ${Media.Tablet`
    ${Typography.S};
  `};

  ${Media.Phone`
    ${Typography.Subhead};
  `};
`

export const HeaderLengthyTitle = styled.h1`
  ${Typography.M};
  font-weight: 600;

  ${Media.Tablet`
    ${Typography.S};
  `};

  ${Media.Phone`
    ${Typography.Subhead};
  `};
`

export const HeaderName = styled.div`
  margin-top: 8px;
  color: ${Colors.Neutral50};

  ${Media.Tablet`
    margin-top: 4px;
  `};
`

export const HeaderBackButton = styled(Link)`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  background: ${Colors.Neutral10};
  color: ${Colors.Neutral50};
  border-radius: 24px;
  transition: background-color 0.1s, color 0.1s;

  &:hover {
    background-color: ${Colors.Neutral20};
    color: ${Colors.Neutral90};
  }

  ${Media.Tablet`
    padding: 12px;
  `};
`

export const HeaderBackButtonIcon = styled(Icon24.Chevron)`
  margin-right: 4px;
  margin-left: -8px;
  transform: scaleX(-1);

  ${Media.Tablet`
    margin: 0;
  `};
`

export const HeaderEditButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  ${Media.Tablet`
    padding: 12px;
    border-radius: 50%;
  `};
`

export const HeaderEditButtonIcon = styled(Icon24.Edit)`
  margin: -1px 8px -1px -4px;

  ${Media.Tablet`
    margin: 0;
  `};
`

export const Content = styled.main`
  display: flex;
`

export const Critique = styled(ResumeCritique)`
  flex-grow: 1;
  border: 1px solid ${Colors.Neutral15};
  overflow: hidden;
  border-radius: 6px;

  ${Media.Phone`
    border-radius: 0;
    border-left: none;
    border-right: none;
  `};
`
export const SidebarWrapper = styled.div`
  width: 352px;
  flex: 0 0 352px;
  transition: all 1s ease-out;
`

export const Sidebar = styled.aside`
  position: sticky;
  top: 100px;
  flex-shrink: 0;
  margin-left: 32px;
  transition: all 1s ease-out;
`

export const SidebarPreview = styled(Link)`
  left: 0;
  display: block;
  width: 100%;
`

export const SidebarPreviewImage = styled(DocumentImagePreview)`
  border-radius: 6px;
  box-shadow: 0 0 1px rgba(15, 56, 113, 0.4), 0 2px 4px rgba(15, 56, 113, 0.08);
`

export const SidebarPreviewAction = styled(Tooltip)`
  position: absolute;
  left: calc(50% - 24px);
  top: calc(50% - 24px);
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${Colors.Blue50};
  color: ${Colors.White};
  opacity: 0;
  transition: opacity 0.1s, background-color 0.1s;

  ${SidebarPreview}:hover & {
    opacity: 1;
  }

  &:hover {
    background-color: ${Colors.Blue60};
  }
`

export const Footer = styled.footer`
  padding: 80px 0;
  background-color: ${Colors.Neutral5};

  ${Media.Tablet`
    padding: 56px 0;
  `};

  ${Media.Phone`
    padding: 48px 0;
  `};

  &[data-cta='get-help'] {
    background-color: ${Colors.Neutral10};

    & ${Button} {
      background-color: ${Colors.Indigo80};
    }

    & ${Button}:hover {
      background-color: ${Colors.Indigo90};
    }
  }
`

export const FooterContainer = styled(BaseContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const FooterContent = styled.div`
  max-width: 576px;
`

export const FooterTitle = styled.div`
  margin-bottom: 8px;
  ${Typography.M};
  font-weight: 600;

  ${Media.Phone`
    margin-bottom: 4px;
  `};
`

export const FooterText = styled.div`
  margin-bottom: 20px;
`

export const FooterImage = styled.img`
  width: 128px;
  flex-shrink: 0;
  margin-left: 56px;

  ${Media.Phone`
    display: none;
  `};
`
