import { useCallback } from 'react'
import { Transition, TransitionGroup } from 'react-transition-group'
import { ResumeFieldSuggest, selectors } from 'builder/modules/resumeEditor'
import { reflow } from 'builder/utils/reflow'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { TopSuggestionWrapper, TopSuggestion, MainSuggestion } from './styles'

interface Props {
  suggestion: ResumeFieldSuggest
  onClick: () => void
}

export const NextSuggestion = ({ suggestion, onClick }: Props) => {
  const isLinkedToJobPosting = !!useTypedSelector(
    state => state.resumeEditor.optimizerSection.jobPostingAPIData.jobPostingId,
  )
  const resumeScore = useTypedSelector(selectors.resumeScore) || 0
  const handleEnter = useCallback((element: HTMLElement) => {
    reflow(element)
  }, [])

  const points = useCallback(
    (suggestion: ResumeFieldSuggest) => {
      const requiredScore = 100 - resumeScore
      if (suggestion.points > requiredScore) {
        return requiredScore
      }
      if (isLinkedToJobPosting) {
        return suggestion.key === 'position_8' || suggestion.sectionName === 'skills'
          ? suggestion.points
          : Math.ceil(suggestion.points / 2)
      } else {
        return suggestion.points
      }
    },
    [isLinkedToJobPosting, resumeScore],
  )

  return (
    <TopSuggestionWrapper onClick={onClick}>
      <TransitionGroup>
        <Transition key={suggestion.key} timeout={400} onEnter={handleEnter}>
          {state => (
            <TopSuggestion in={state}>
              {suggestion.text}
              <MainSuggestion>+{points(suggestion)}</MainSuggestion>
            </TopSuggestion>
          )}
        </Transition>
      </TransitionGroup>
    </TopSuggestionWrapper>
  )
}

export default NextSuggestion
