import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

export const LogoLink = styled(Link)`
  flex-shrink: 0;
  margin-right: 40px;
`

export const Content = styled.div`
  position: relative;
  background-color: ${Colors.White};
  box-shadow: 0 1px 0 0 ${Colors.Neutral10};

  ${Media.Tablet`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
  `};

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;

  ${Media.Phone`
    padding: 0 20px;
  `};
`

export const Container = styled.div<{ barHeight: number }>`
  &,
  & ${Content} {
    height: ${props => props.barHeight}px;
  }
`
