import { useI18n } from 'builder/hooks/useI18n'
import { ApplyAllButton, ArrowTargetIcon } from './styles'

interface ApplyAllSuggestionsProps {
  hasFullWidth?: boolean
  onClick: () => void
}

export const ApplyAllSuggestionsButton = ({ onClick, hasFullWidth }: ApplyAllSuggestionsProps) => {
  const { i18n } = useI18n()
  return (
    <ApplyAllButton onClick={onClick} $hasFullWidth={hasFullWidth}>
      <ArrowTargetIcon />
      {i18n.t('builder.auto_tailoring.optimizer_button_text')}
    </ApplyAllButton>
  )
}
