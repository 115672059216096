import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Button from 'builder/components/Button'

export const Container = styled.div`
  padding: 40px 32px 32px 32px;
  max-width: 416px;
  border-radius: 8px;
  background-color: ${Colors.White};
  gap: 32px;

  ${Media.Phone`
    padding: 20px;
  `};
`

export const Content = styled.div`
  gap: 32px;
  display: flex;
  flex-direction: column;
`

export const TopContent = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
`

export const Header = styled.div`
  ${Typography.M};
  ${FontWeights.Medium};
  text-align: center;
`

export const SubHeader = styled.p`
  text-align: center;
`

export const Avatar = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 300px;
  align-self: center;
  object-fit: cover;
`

export const StyledButton = styled(Button)`
  width: 100%;
  height: 48px;
`
