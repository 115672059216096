import styled, { css } from 'styled-components'
import Icon24 from 'builder/components/Icon'
import { useI18n } from 'builder/hooks/useI18n'
import { fromNow } from 'builder/utils/formatDate'
import { Job } from '../JobSearchResult/types'

const Container = styled.span<{ overwriteSVGColor?: boolean }>`
  display: flex;
  align-items: center;
  ${({ overwriteSVGColor: overwriteColor }) =>
    overwriteColor &&
    css`
      & svg path {
        fill: currentColor;
      }
    `}
`

interface Props {
  icon: React.ReactNode
  value: string
  overwriteSVGColor?: boolean
}

const Base = ({ icon, value, overwriteSVGColor }: Props) => (
  <Container overwriteSVGColor={overwriteSVGColor}>
    {icon}
    <span>{value}</span>
  </Container>
)

interface MetaProps {
  job?: Job
}

export const Location = ({ job }: MetaProps) => {
  return <Base overwriteSVGColor icon={<Icon24.NewLocation />} value={job?.location || 'N/A'} />
}

export const Remote = ({ job }: MetaProps) => {
  // TODO: Currently missing this value. Need to investigate its origin
  return job ? null : null

  // const { i18n } = useI18n()
  // if (!job?.isRemote) {
  //   return null
  // }
  // return (
  //   <Base
  //     overwriteSVGColor
  //     icon={<Icon24.Location />}
  //     value={i18n.t('builder.job_search.remote')}
  //   />
  // )
}

export const CreatedAt = ({ job }: MetaProps) => {
  if (!job || !job.posted_at) {
    return null
  }
  return <Base icon={<Icon24.ClockIcon />} value={fromNow(job.posted_at, { addSuffix: true })} />
}

export const Salary = ({ job }: MetaProps) => {
  // TODO: Currently missing this value. Need to investigate its origin
  return job ? null : null

  // if (!job?.salary) {
  //   return null
  // }
  // return <Base icon={<Icon24.MoneyIcon />} value={job?.salary} />
}

export const AutoApplyStatus = () => {
  const { i18n } = useI18n()
  return (
    <Base icon={<Icon24.Simple />} value={i18n.t('builder.job_search.auto_apply.meta_label')} />
  )
}
