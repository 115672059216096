import { selectors } from 'builder/modules/careerPath2'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

import Header from './components/Header'
import { Container } from './styles'

const JobPostings = () => {
  const isLoading = useTypedSelector(selectors.isJobDemandLoading)

  return (
    <Container>
      <Header isLoading={isLoading} />
    </Container>
  )
}

export default JobPostings
