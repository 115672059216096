import { Fragment } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { CareerPathItem } from 'builder/modules/careerPath'
import { SkeletonColumns } from '../Skeletons'
import * as CommonStyles from '../../styles'
import * as Styles from './styles'

type Props = {
  softSkills?: Array<CareerPathItem>
  hardSkills?: Array<CareerPathItem>
  isSkeleton: boolean
}

export const Skills = ({ softSkills, hardSkills, isSkeleton = false }: Props) => {
  if (!softSkills?.length && !hardSkills?.length && !isSkeleton) return null

  if (isSkeleton)
    return (
      <Styles.Wrapper>
        <CommonStyles.Title>Required Skills</CommonStyles.Title>
        <SkeletonColumns />
      </Styles.Wrapper>
    )

  const handleClick = (type: 'soft' | 'hard', skill: CareerPathItem) => {
    trackInternalEvent('click_career_skill_tag', { [type]: skill.title })
  }

  return (
    <Styles.Wrapper>
      <CommonStyles.Title>Required Skills</CommonStyles.Title>
      {!!hardSkills?.length && (
        <Fragment>
          <Styles.Title>Hard skills</Styles.Title>
          <Styles.LabelContainer>
            {hardSkills.map((skill, index) => (
              <CommonStyles.Label key={index} onClick={() => handleClick('hard', skill)}>
                {skill.title}
              </CommonStyles.Label>
            ))}
          </Styles.LabelContainer>
        </Fragment>
      )}
      {!!softSkills?.length && (
        <Fragment>
          <Styles.Title>Soft skills</Styles.Title>
          <Styles.LabelContainer>
            {softSkills.map((skill, index) => (
              <CommonStyles.Label key={index} onClick={() => handleClick('soft', skill)}>
                {skill.title}
              </CommonStyles.Label>
            ))}
          </Styles.LabelContainer>
        </Fragment>
      )}
    </Styles.Wrapper>
  )
}
