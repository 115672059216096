import styled, { css } from 'styled-components'
import Icon20 from 'builder/components/Icon'

import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Icon56 } from 'builder/components/Icon/Icon'

export const TickIcon = styled(Icon20.Tick)`
  border-radius: 50%;
  background-color: ${Colors.Green10};
  fill: ${Colors.Green50};
  margin-bottom: 10px;
`

export const WordIcon = styled(Icon56.Word)`
  margin-bottom: 16px;
`

export const BuilderIcon = styled(Icon56.Builder)`
  margin-bottom: 16px;
`

export const TemplateIcon = styled(Icon56.Template)`
  margin-bottom: 16px;
`

export const CostumizationIcon = styled(Icon56.Costumization)`
  margin-bottom: 16px;
`

export const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
`

export const Item = styled.div<{ borderTop?: boolean }>`
  ${props =>
    props.borderTop &&
    css`
      margin-top: 8px;
      padding-top: 32px;
      border-top: 1px ${Colors.Neutral15} solid;
    `}

  width: 45%;
`

export const ItemHeader = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular};
`

export const ItemDescription = styled.div`
  ${Typography.Tiny};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
`
