import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Icon24 } from 'builder/components/Icon'
import Shadows from 'builder/styles/shadows'

export const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`

export const MobileTabsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const MobileStickyTabsContainer = styled.div<{ isSticky: boolean }>`
  opacity: ${props => (props.isSticky ? 1 : 0)};
  transition: all 150ms ease-in;
  z-index: ${props => (props.isSticky ? 549 : -1)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${Colors.White};
  bottom: ${props => (props.isSticky ? '42px' : '0')};
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  border-radius: 30px;
  width: 90%;
  height: 62px;
  padding: 0px 20px;
  ${Shadows.lightWithBorder.mid}
`

export const TabTitle = styled.span<{ isSticky: boolean }>`
  ${props => (props.isSticky ? Typography.S : Typography.L)}
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
`

export const IconContainer = styled.div`
  height: 40px;
  width: 40px;
  background-color: ${Colors.Blue10};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ArrowIcon = styled(Icon24.Chevron)`
  color: ${Colors.Blue50};
`
