import { EditorState, CharacterMetadata, Modifier, ContentBlock, ContentState } from 'draft-js'
import { stateFromHTML } from 'draft-js-import-html'
import { stateToHTML } from 'draft-js-export-html'
import escape from 'lodash/escape'
import { Optional } from 'packages/types'

const getEntityAtOffset = (block: ContentBlock, offset: number) => {
  const entityKey = block.getEntityAt(offset)
  if (entityKey == null) {
    return null
  }

  let startOffset = offset
  while (startOffset > 0 && block.getEntityAt(startOffset - 1) === entityKey) {
    startOffset -= 1
  }

  let endOffset = startOffset
  const blockLength = block.getLength()
  while (endOffset < blockLength && block.getEntityAt(endOffset + 1) === entityKey) {
    endOffset += 1
  }

  return {
    entityKey,
    blockKey: block.getKey(),
    startOffset,
    endOffset: endOffset + 1,
  }
}

export const getEntityAtCursor = (editorState: EditorState) => {
  const selection = editorState.getSelection()
  const startKey = selection.getStartKey()
  const startBlock = editorState.getCurrentContent().getBlockForKey(startKey)
  const startOffset = selection.getStartOffset()

  if (selection.isCollapsed()) {
    // Get the entity before the cursor (unless the cursor is at the start).
    return getEntityAtOffset(startBlock, startOffset === 0 ? startOffset : startOffset - 1)
  }

  if (startKey !== selection.getEndKey()) {
    return null
  }

  const endOffset = selection.getEndOffset()
  const startEntityKey = startBlock.getEntityAt(startOffset)
  for (let i = startOffset; i < endOffset; i++) {
    const entityKey = startBlock.getEntityAt(i)
    if (entityKey == null || entityKey !== startEntityKey) {
      return null
    }
  }
  return {
    entityKey: startEntityKey,
    blockKey: startBlock.getKey(),
    startOffset: startOffset,
    endOffset: endOffset,
  }
}

export const clearEntityForRange = (
  editorState: EditorState,
  blockKey: string,
  startOffset: number,
  endOffset: number,
) => {
  const contentState = editorState.getCurrentContent()
  const blockMap = contentState.getBlockMap()
  const block = blockMap.get(blockKey)
  const charList = block.getCharacterList()

  const newCharList = charList.map((char, i) => {
    if ((i ?? 0) >= startOffset && (i ?? 0) < endOffset) {
      return CharacterMetadata.applyEntity(char as CharacterMetadata, null)
    }
    return char
  })

  const newBlock = block.set('characterList', newCharList) as ContentBlock
  const newBlockMap = blockMap.set(blockKey, newBlock)
  const newContentState = contentState.set('blockMap', newBlockMap) as ContentState
  return EditorState.push(editorState, newContentState, 'apply-entity')
}

export const appendText = (sourceEditorState: EditorState, text: string) => {
  const editorState = EditorState.moveSelectionToEnd(sourceEditorState)
  const selection = editorState.getSelection()
  const contentState = editorState.getCurrentContent()
  // If there is text in the block already, add whitespace before the new sequence
  const separator = contentState.getLastBlock().getLength() === 0 ? '' : ' '

  const withText = Modifier.insertText(contentState, selection, separator + text)
  const newState = EditorState.push(editorState, withText, 'insert-characters')
  return EditorState.moveSelectionToEnd(newState)
}

export const appendHtml = (editorState: EditorState, html: string) => {
  const contentState = editorState.getCurrentContent()
  const isContentHasText = contentState.hasText()
  const currentHtml = stateToHTML(contentState)
  const newContentState = isContentHasText ? stateFromHTML(currentHtml + html) : stateFromHTML(html)
  const newEditorState = EditorState.push(editorState, newContentState, 'insert-fragment')
  return EditorState.moveSelectionToEnd(newEditorState)
}

export const getPhraseContent = (
  item: { phrase: string; isSubphrase: boolean },
  scope: Optional<string>,
): { text: string } | { html: string } => {
  const phrase = escape(item.phrase)

  if (item.isSubphrase) {
    // Add a sentence (should append with whitespace)
    return { text: phrase }
  } else {
    // Insert a paragraph or list item (should append with line break)
    return { html: scope === 'profile' ? `<p>${phrase}</p>` : `<ul><li>${phrase}</li></ul>` }
  }
}

export const processPhrase = (
  item: { phrase: string; isSubphrase: boolean },
  editorState: EditorState,
  scope: Optional<string>,
) => {
  const content = getPhraseContent(item, scope)

  if ('text' in content) {
    return appendText(editorState, content.text)
  } else {
    return appendHtml(editorState, content.html)
  }
}

export const replaceText = (phrase: string, editorState: EditorState): EditorState => {
  const contentState: ContentState = ContentState.createFromText(phrase)
  const newEditorState: EditorState = EditorState.push(
    editorState,
    contentState,
    'insert-characters',
  )

  return EditorState.forceSelection(newEditorState, contentState.getSelectionAfter())
}
