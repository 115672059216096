import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'

import { useUser } from 'builder/hooks/useUser'
import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import useWebsiteHost from 'builder/components/CIO-Dasboard-2.0/hooks/useWebsiteHost'

import { actions } from 'builder/modules/careerProfile'
import { UserSettings } from 'builder/components/Navigation/components/UserSettings'
import { UserProfile } from 'builder/features/navigation/components/UserProfile/UserProfile'
import Colors from 'builder/styles/colors'

import { BuilderType } from '../ResumeSideMenu/types'
import {
  ButtonContainer,
  CloseIcon,
  Container,
  DashboardButton,
  OpenIcon,
  Profile,
  SettingsContainer,
  ToggleButton,
} from './styles'

interface IProps {
  isOnline: boolean
  isOpen: boolean
  builderType: BuilderType
  setOpen: (key: boolean) => void
}

export const Header = ({ isOnline, isOpen, builderType, setOpen }: IProps) => {
  const user = useUser()
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { getHost } = useWebsiteHost()
  const { isPhone, isDesktop } = useMediaQueries()
  const [isUserDropdownOpen, toggleUserDropdown] = useState(false)

  const openMobileMenu = () => {
    dispatch(actions.setMobileProfileMenu(true))
  }

  const handleOpen = () => {
    const eventName = isOpen ? 'nav_click_close_button' : 'nav_click_super_app_menu'
    trackInternalEvent(eventName, { label: builderType, website_host: getHost })
    setOpen(!isOpen)
  }

  const handleDashboardClick = () => {
    trackInternalEvent('nav_click_dashboard', { label: builderType, website_host: getHost })
    navigate('/')
  }

  return (
    <>
      <Container>
        <ToggleButton onClick={handleOpen}>{isOpen ? <CloseIcon /> : <OpenIcon />}</ToggleButton>
      </Container>
      {(!isOpen || isPhone) && (
        <ButtonContainer>
          <DashboardButton onClick={handleDashboardClick}>
            {i18n.t('builder.side_menu.dashboard')}
          </DashboardButton>
        </ButtonContainer>
      )}
      {isPhone && (
        <Profile onClick={openMobileMenu}>
          <UserProfile showOnlyAvatar={true} />
        </Profile>
      )}
      {!isPhone && isOnline && user && (
        <SettingsContainer>
          <UserSettings
            iconColor={isDesktop ? Colors.White : Colors.Neutral50}
            user={user}
            isOpen={isUserDropdownOpen}
            onToggle={toggleUserDropdown}
          />
        </SettingsContainer>
      )}
    </>
  )
}
