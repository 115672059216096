import { Fragment } from 'react'
import uniqueId from 'lodash/uniqueId'
import { useConstant } from 'builder/hooks/useConstant'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { LevelConnectionProps } from './types'
import * as Styles from './styles'

export const Level2 = ({ type, connections, gradient: { from, to } }: LevelConnectionProps) => {
  const { isPhone } = useMediaQueries()
  const width = isPhone ? 24 : 32

  const id1 = useConstant(() => uniqueId('l21'))
  const id2 = useConstant(() => uniqueId('l22'))
  const id3 = useConstant(() => uniqueId('l23'))

  return (
    <Styles.Container
      level={2}
      type={type}
      isPhone={isPhone}
      width={width}
      height="304"
      viewBox={`0 0 ${width} 304`}
      xmlns="http://www.w3.org/2000/svg"
    >
      {connections.includes(1) && (
        <Fragment>
          <path
            d={
              isPhone
                ? 'M24 2V2C17.3726 2 12 7.37258 12 14V138C12 144.627 6.62742 150 0 150V150'
                : 'M0 152V152C8.83656 152 16 144.837 16 136V18C16 9.16345 23.1634 2 32 2V2'
            }
            stroke={`url(#${id1})`}
            strokeWidth="4"
          />
          <defs>
            <linearGradient
              id={id1}
              x1={isPhone ? 18 : 6}
              y1={isPhone ? -301.979 : -161.998}
              x2={isPhone ? -1.99999 : 26}
              y2={isPhone ? -301.979 : -161.998}
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor={from} />
              <stop offset="1" stopColor={to} />
            </linearGradient>
          </defs>
        </Fragment>
      )}
      {connections.includes(2) && (
        <Fragment>
          <path d={`M0 ${isPhone ? '150H24' : '152H32'}`} stroke={`url(#${id2})`} strokeWidth="4" />
          <defs>
            <linearGradient
              id={id2}
              x1={isPhone ? 4.5 : 6}
              y1={isPhone ? 149 : 165}
              x2={isPhone ? 19.5 : 26}
              y2={isPhone ? 149 : 165}
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor={from} />
              <stop offset="1" stopColor={to} />
            </linearGradient>
          </defs>
        </Fragment>
      )}
      {connections.includes(3) && (
        <Fragment>
          <path
            d={
              isPhone
                ? 'M24 288V288C17.3726 288 12 282.627 12 276V162C12 155.373 6.62742 150 0 150V150'
                : 'M0 152V152C8.83656 152 16 159.163 16 182V286C16 294.837 23.1634 302 32 302V302'
            }
            stroke={`url(#${id3})`}
            strokeWidth="4"
          />
          <defs>
            <linearGradient
              id={id3}
              x1={isPhone ? 18 : 6}
              y1={isPhone ? 601.979 : 521.997}
              x2={isPhone ? -1.99999 : 26}
              y2={isPhone ? 601.979 : 521.997}
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor={from} />
              <stop offset="1" stopColor={to} />
            </linearGradient>
          </defs>
        </Fragment>
      )}
    </Styles.Container>
  )
}
