import { memo, useState } from 'react'
import { trackInternalEvent } from '@rio/tracking'

import { useI18n } from 'builder/hooks/useI18n'
import { useChromeExtension } from 'builder/hooks/useChromeExtension'
import useWebsiteHost from 'builder/components/CIO-Dasboard-2.0/hooks/useWebsiteHost'
import { EXTENSION_URL } from 'builder/modules/jobTracking'

import { Popover } from 'builder/components/Popover/Popover'
import { ChromeHoverPromote } from '../ChromeHoverPromote/ChromeHoverPromote'

import { GetExtensionButton } from './style'

type Props = {
  type: 'job_search' | 'job_tracker'
}

export const ChromeExtensionButton = memo(({ type }: Props) => {
  const [openPopover, setOpenPopover] = useState(false)

  const { i18n } = useI18n()
  const { getHost } = useWebsiteHost()
  const { isInstalled, canInstallExtension, getBrowserIcon } = useChromeExtension()

  const getExtension = () => {
    trackInternalEvent('click_install_chrome', {
      host: getHost,
      label: type,
      source: `${type}_header`,
    })
    window.open(EXTENSION_URL, '_blank')
  }

  if (isInstalled || !canInstallExtension) {
    return null
  }

  return (
    <Popover
      visible={openPopover}
      placement="bottom"
      component={<ChromeHoverPromote />}
      containerStyles={{ margin: '-6px 10px 0 0' }}
    >
      <GetExtensionButton
        onMouseEnter={() => setOpenPopover(true)}
        onMouseLeave={() => setOpenPopover(false)}
        onClick={getExtension}
      >
        {i18n.t(`builder.job_tracking.chrome_extension_button`)}
        {getBrowserIcon()}
      </GetExtensionButton>
    </Popover>
  )
})
