import { StoriesType } from 'builder/modules/sevenStories'
import { TextFieldContainer, Label, Field, TextAreaContainer } from '../../style'

type StoryFieldProps = {
  onStoryChange: (value: string, index: number, field: string) => void
  index: number
  stories: StoriesType
  item: {
    id: number
    label: string
    placeholder: string
    type: string
    name: string
  }
}

export const StoryField = ({ stories, index, item, onStoryChange }: StoryFieldProps) => {
  const storyKey = `story_${index + 1}`
  const value = stories?.[storyKey as keyof typeof stories]

  return (
    <Field>
      <Label>{item.label}</Label>
      {item.type === 'text' ? (
        <TextFieldContainer
          value={value}
          placeholder={item.placeholder}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            onStoryChange(event.target.value, index, `story_${index + 1}`)
          }}
        />
      ) : (
        <TextAreaContainer
          value={value}
          placeholder={item.placeholder}
          onChange={event => onStoryChange(event.target.value, index, `story_${index + 1}`)}
        />
      )}
    </Field>
  )
}
