import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const Name = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
`

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 64px;
  padding-inline: 8px;

  &:hover {
    cursor: pointer;
    border-radius: 8px;
    background-color: ${Colors.Blue10};

    ${Name} {
      color: ${Colors.Blue50};
    }
  }

  ${Media.Tablet`
    padding-inline: 8px;
  `}
`

export const Company = styled.div`
  gap: 12px;
  display: flex;
  align-items: center;
`

export const Logo = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`

export const Openings = styled.div`
  color: ${Colors.Neutral50};
  white-space: nowrap;
`
