import { ButtonSize, ButtonTheme } from 'builder/components/Button/types'
import { useAutoTailoringAppConfig } from 'builder/hooks/useAutoTailoringAppConfig'
import { useI18n } from 'builder/hooks/useI18n'
import { Button } from '../styles'
import { ButtonsGroup, ArrowTargetIconWrapper, ArrowTargetIcon } from './styles'

interface BottomContainerProps {
  buttonSize: ButtonSize
  onStartManualTailoring?: () => void
  onStartAutoTailoring?: () => void
  isSavingJobPostingData?: boolean
}

export const TailoringButtonsGroup = (props: BottomContainerProps) => {
  const { buttonSize, onStartManualTailoring, onStartAutoTailoring, isSavingJobPostingData } = props
  const { i18n } = useI18n()
  const { isAutoTailoringEnabled, isManualTailoringAllowed } = useAutoTailoringAppConfig()

  if (isAutoTailoringEnabled) {
    return (
      <ButtonsGroup>
        {isManualTailoringAllowed && !isSavingJobPostingData && (
          <Button size={buttonSize} theme="ghost" onClick={onStartManualTailoring}>
            {i18n.t('builder.resume_optimizer.job_posting.manually_button')}
          </Button>
        )}
        <Button
          theme={ButtonTheme.alternative}
          size={buttonSize}
          onClick={onStartAutoTailoring}
          isLoading={isSavingJobPostingData}
          isDisabled={isSavingJobPostingData}
        >
          <ArrowTargetIconWrapper>
            <ArrowTargetIcon />
          </ArrowTargetIconWrapper>
          {i18n.t('builder.resume_optimizer.job_posting.auto_tailor_button')}
        </Button>
      </ButtonsGroup>
    )
  }

  return (
    <Button
      size={buttonSize}
      onClick={onStartManualTailoring}
      isLoading={isSavingJobPostingData}
      isDisabled={isSavingJobPostingData}
    >
      {i18n.t('builder.resume_optimizer.job_posting.start_improving')}
    </Button>
  )
}
