import { Container, Text, Value } from './styles'

interface Props {
  text: string
  value: string
  width?: number
  isMatching?: boolean
}

const Bar = ({ text, value, width = 100, isMatching = false }: Props) => {
  return (
    <Container width={width} isMatching={isMatching}>
      <Text>{text}</Text>
      <Value>{value}</Value>
    </Container>
  )
}

export default Bar
