import { ChangeEvent, MouseEventHandler } from 'react'

import Icon from 'builder/components/Icon'
import Toggle from 'builder/components/Toggle'
import { useI18n } from 'builder/hooks/useI18n'
import { getMonths } from './months'

import {
  PanelWrapper,
  DatePickerPanel,
  YearPicker,
  Year,
  LeftArrow,
  RightArrow,
  MonthPicker,
  PickersContainer,
  Month,
  CurrentlyContainer,
} from './styles'
import { isDateTooEarly, isDateTooLate, isYearTooEarly, isYearTooLate } from './utils'

type Props = {
  year: number
  month: number | null
  onChangeYear: (value: number) => void
  onChoose: (month: number | null, year: number, shouldClose?: boolean) => void

  onIsPresentToggle?: (value: boolean) => void
  isPresent?: boolean
  showIsPresent?: boolean
  alignRight?: boolean
  currentlyLabel?: string
  noActiveMonth?: boolean
  noActiveYear?: boolean
  maxYear?: number
  maxMonth?: number | null
  minYear?: number
  minMonth?: number | null
  testId?: string
}

const PickerPanel = (props: Props) => {
  const {
    month,
    year,
    isPresent,
    showIsPresent,
    alignRight,
    currentlyLabel,
    noActiveMonth,
    noActiveYear,
    onChangeYear,
    onChoose,
    onIsPresentToggle,
    maxYear,
    maxMonth,
    minYear,
    minMonth,
    testId,
  } = props

  const { i18n } = useI18n()

  const isDecrementYearDisabled = isPresent || isYearTooEarly(year - 1, minYear)
  const isIncrementYearDisabled = isPresent || isYearTooLate(year + 1, maxYear)

  const isMonthDisabled = (month: number) => {
    return (
      isPresent ||
      isDateTooLate(month, year, maxMonth, maxYear) ||
      isDateTooEarly(month, year, minMonth, minYear)
    )
  }

  const handleYearInc = () => {
    if (isIncrementYearDisabled) return
    onChangeYear(+year + 1)
  }

  const handleYearDecr = () => {
    if (isDecrementYearDisabled) return
    onChangeYear(+year - 1)
  }

  const handleChoose: MouseEventHandler = event => {
    const target = event.target as HTMLDivElement
    const newMonth = +(target.dataset.number || 0)
    if (isMonthDisabled(newMonth)) return
    onChoose(newMonth, year)
  }

  const handleYearClick = () => {
    if (isPresent) return
    onChoose(null, year)
  }

  const toggleIsPresent = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement
    onIsPresentToggle?.(target.checked)
  }

  return (
    <PanelWrapper data-testid={testId}>
      <DatePickerPanel $alignRight={alignRight}>
        <PickersContainer>
          <YearPicker>
            <LeftArrow
              onClick={handleYearDecr}
              $isDisabled={isDecrementYearDisabled}
              data-testid={`${testId}-decrement-year`}
              data-disabled={isDecrementYearDisabled}
            >
              <Icon.ToggleArrow />
            </LeftArrow>
            <Year
              onClick={handleYearClick}
              $isActive={noActiveMonth && !noActiveYear}
              $isDisabled={isPresent}
              data-disabled={isPresent}
              data-testid={`${testId}-year-value`}
            >
              {year}
            </Year>
            <RightArrow
              onClick={handleYearInc}
              $isDisabled={isIncrementYearDisabled}
              data-testid={`${testId}-increment-year`}
              data-disabled={isIncrementYearDisabled}
            >
              <Icon.ToggleArrow />
            </RightArrow>
          </YearPicker>
          <MonthPicker>
            {getMonths().map(el => {
              const isDisabled = isMonthDisabled(el.number)
              return (
                <Month
                  key={el.number}
                  $isActive={!noActiveMonth && el.number === month}
                  data-number={el.number}
                  onClick={handleChoose}
                  $isDisabled={isDisabled}
                  data-testid={`${testId}-month-${el.number}`}
                  data-disabled={isDisabled}
                >
                  {el.name}
                </Month>
              )
            })}
          </MonthPicker>
          {showIsPresent && (
            <CurrentlyContainer>
              <Toggle
                tabIndex={-1}
                label={currentlyLabel || i18n.t('builder.date_range_picker.present')}
                checked={isPresent}
                onChange={toggleIsPresent}
                testId={`${testId}-toggle-present`}
              />
            </CurrentlyContainer>
          )}
        </PickersContainer>
      </DatePickerPanel>
    </PanelWrapper>
  )
}

export default PickerPanel
