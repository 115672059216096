import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

const Circle = css`
  border-radius: 100%;
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const DefaultCircle = styled.div`
  ${Circle};
  ${FontWeights.Medium};
  font-size: 80px;
  border: solid 10px ${Colors.Blue10};
  background: ${Colors.White};
  color: ${Colors.Indigo50};
`

export const HoverCircle = styled.div`
  ${Circle};
  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${Colors.Blue50};
  background: ${Colors.Blue10};
`
