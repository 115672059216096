import styled from 'styled-components'
import Media from 'builder/styles/media'
import Button from 'builder/components/Button'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'

export const Container = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`

export const StyledButton = styled(Button)`
  width: 288px;
  height: 48px;

  ${Media.Phone`
    width: 100%;
  `}
`

export const FooterText = styled.div`
  color: ${Colors.Neutral50};
  ${Typography.Caption};
`
