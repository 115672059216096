import { trackInternalEvent } from '@rio/tracking'
import Button, { ButtonTheme } from 'builder/components/Button'
import { useI18n } from 'builder/hooks/useI18n'
import { goToBillingPlanPage } from 'builder/utils/goToBillingPlanPage'
import { TRANSLATION_PREFIX } from '../../FeedbackView'
import { Body, Container, CrownIcon, HeaderContainer, Subtitle, Title } from './style'

export const PremiumBanner = () => {
  const { i18n } = useI18n()

  return (
    <Container>
      <Body>
        <HeaderContainer>
          <CrownIcon />
          <Title>{i18n.t(`${TRANSLATION_PREFIX}.premium_banner.title`)}</Title>
        </HeaderContainer>

        <Subtitle>{i18n.t(`${TRANSLATION_PREFIX}.premium_banner.subtitle`)}</Subtitle>
        <div>
          <Button
            theme={ButtonTheme.alternative}
            onClick={() => {
              goToBillingPlanPage({ referrerPage: 'interviewPrep' })
              trackInternalEvent('click_upgrade_paywall', {
                label: 'interview_prep',
                parameter: 'feedback',
              })
            }}
          >
            {i18n.t(`${TRANSLATION_PREFIX}.premium_banner.upgrade_now`)}
          </Button>
        </div>
      </Body>
    </Container>
  )
}
