import { trackInternalEvent } from '@rio/tracking'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import Button, { ButtonSize } from 'builder/components/Button'
import { useI18n } from 'builder/hooks/useI18n'

import * as Styles from './styles'

interface IProps {
  handleClose: () => void
}

const ModalFooter = ({ handleClose }: IProps) => {
  const { isPhone } = useMediaQueries()
  const { i18n } = useI18n()

  const handleCTA = () => {
    trackInternalEvent('click_tr_banner', { label: 'critique_page' })
    window.open(
      `https://topresume.com/resume-writing?pt=UWYxd6Xe2VTS0&utm_source=careerio&utm_medium=cioplatform&utm_campaign=tr_cta_in_cio_critique_v2&utm_content=cta2`,
      '_blank',
    )
  }

  return (
    <>
      <Styles.ButtonContainer>
        {isPhone ? (
          <Button
            size={!isPhone ? ButtonSize.default : ButtonSize.small}
            theme="ghost"
            onClick={handleClose}
          >
            {i18n.t(`builder.panel.second_review_alert.cancel`)}
          </Button>
        ) : (
          <Button
            size={!isPhone ? ButtonSize.default : ButtonSize.small}
            theme="ghost"
            onClick={handleCTA}
          >
            {i18n.t(`builder.panel.second_review_alert.get_expert_help`)}
          </Button>
        )}
        <Button
          size={!isPhone ? ButtonSize.default : ButtonSize.small}
          onClick={handleClose}
          data-testid="footer-modal-close-button"
        >
          {i18n.t(`builder.panel.second_review_alert.got_it`)}
        </Button>
      </Styles.ButtonContainer>
    </>
  )
}

export default ModalFooter
