import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const TailoredIcon = styled.img``
export const TailoredTitle = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular};
  margin: 0px 8px;
  white-space: nowrap;
`

export const TailoredNew = styled.div`
  ${Typography.Tiny};
  ${FontWeights.DemiBold}
  border-radius: 4px;
  color: ${Colors.Blue50};
  padding: 4px 6px;
  background: ${Colors.Blue10};
`

export const TailoredWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  &:hover {
    color: ${props => (props.disabled ? Colors.Neutral50 : Colors.Blue50)};
  }

  ${TailoredNew} {
    color: ${props => (props.disabled ? Colors.Neutral50 : Colors.Blue50)};
    background: ${props => (props.disabled ? Colors.Neutral10 : Colors.Blue10)};
  }

  ${TailoredTitle} {
    color: ${props => props.disabled && Colors.Neutral50};
  }
`
