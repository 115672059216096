import { FC, memo } from 'react'
import Colors from 'builder/styles/colors'

export const BotWriting: FC<{
  durationMs?: number
  fill?: keyof typeof Colors
  stroke?: keyof typeof Colors
}> = memo(({ durationMs = 1500, fill = Colors.Blue50, stroke = Colors.Neutral20 }) => {
  const stringDuration = `${durationMs}ms`
  const delayFrames = '10; 10; 10; 10; 10; 10; 10;'
  const strokeWidth = '0.5'
  const radius = '3.25'
  return (
    <>
      <svg
        width="32"
        height="20"
        viewBox="0 0 32 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="6" cy="10" r={radius} fill={fill} stroke={stroke} strokeWidth={strokeWidth}>
          <animate
            attributeName="cy"
            values={`10; 7.5; 3.25; 7.5; 10; 10; ${delayFrames}`}
            dur={stringDuration}
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="16" cy="10" r={radius} fill={fill} stroke={stroke} strokeWidth={strokeWidth}>
          <animate
            attributeName="cy"
            values={`10; 10; 7.5; 3.25; 7.5; 10; ${delayFrames}`}
            dur={stringDuration}
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="26" cy="10" r={radius} fill={fill} stroke={stroke} strokeWidth={strokeWidth}>
          <animate
            attributeName="cy"
            values={`10; 10; 10; 7.5; 3.25; 7.5; ${delayFrames}`}
            dur={stringDuration}
            repeatCount="indefinite"
          />
        </circle>
      </svg>
    </>
  )
})
