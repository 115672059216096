import { Chart as ChartJS, BarElement, LogarithmicScale, Tooltip } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { getDatasetOptions } from '../../utils'
import * as CommonStyles from '../../styles'
import { normalizeChartData, getEducationChartOptions } from './utils'
import { Skeleton } from './Skeleton'
import { EducationChartProps } from './types'
import * as Styles from './styles'

ChartJS.register(BarElement, LogarithmicScale, Tooltip)

export const EducationChart = ({ data, isSkeleton = false }: EducationChartProps) => {
  const media = useMediaQueries()

  if (isSkeleton) return <Skeleton />

  const hasData = data.some(item => item.rate)

  if (!data?.length || !hasData) return null

  const chartData = {
    datasets: [
      {
        ...getDatasetOptions(media),
        data: normalizeChartData(data),
        borderWidth: {
          top: 2,
          bottom: 0,
          left: 0,
          right: 0,
        },
      },
    ],
  }

  const maxY = data.reduce((accum, item) => {
    return item.abbr ? Math.max(accum, item.rate * 100) : accum
  }, 0)

  const options = getEducationChartOptions(maxY)

  return (
    <Styles.Wrapper>
      <CommonStyles.Title>Requested Education</CommonStyles.Title>
      <Styles.Chart>
        <Bar options={options} data={chartData} height="198px" />
      </Styles.Chart>
    </Styles.Wrapper>
  )
}
