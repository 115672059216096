import { memo, useEffect, useMemo } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { i18n as I18n } from 'builder/utils/i18n'
import { Tab, PillTabs } from 'builder/components/PillTabs'
import { TABS } from 'builder/components/FillResumeModal/constants'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { AIResumeDraftEventLabels, AIResumeDraftEvents } from 'builder/modules/constants'
import { VoiceInputTabProps } from 'builder/modules/resumeEditor/types'
import TextInputTab from './TextInputTab'
import VoiceInputTab from './VoiceInputTab'

type Props = VoiceInputTabProps & {
  activeTab: TABS
  onTabChange: () => void
}

export const AccomplishmentsAndGoals = memo((props: Props) => {
  const { activeTab, onTabChange, ...rest } = props
  const { step } = useTypedSelector(state => state.resumeEditor.aiResume.questionnaireData)

  const renderTab = useMemo(() => {
    switch (activeTab) {
      case TABS.voice_input:
        return <VoiceInputTab {...rest} />
      case TABS.text_input:
        return <TextInputTab />
    }
  }, [activeTab, rest])

  useEffect(() => {
    trackInternalEvent(
      step === 5
        ? AIResumeDraftEvents.seeProfessionalHighlightsStep
        : AIResumeDraftEvents.seeCareerGoalsStep,
      {
        label: AIResumeDraftEventLabels.aiResume,
      },
    )
  }, [step])

  return (
    <>
      <PillTabs>
        {Object.values(TABS).map((name, index) => (
          <Tab
            key={index}
            isActive={name === activeTab}
            onClick={onTabChange}
            isDisabled={rest.isRecording}
          >
            {I18n.t(`builder.ai_resume_draft.text_voice_input.${name}`)}
          </Tab>
        ))}
      </PillTabs>
      {renderTab}
    </>
  )
})
