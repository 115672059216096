import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'

export const AvatarContainer = styled.div`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  ${({ color, src }) =>
    src
      ? css`
          background-image: url(${src});
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center center;
        `
      : css`
          background-color: ${color || Colors.White};
        `}
  overflow: hidden;
  border-radius: ${({ borderRadius }) => borderRadius || '50%'};
  box-shadow: 0 0 0 1px rgba(15, 20, 31, 0.1) inset;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ border }) =>
    border &&
    css`
      border: ${border};
    `}
`

export const Letter = styled.div`
  font-size: ${({ fontSize }) => fontSize}px;
  line-height: 1.25;
  color: ${Colors.White};
  text-shadow: 0px 1px 2px rgba(15, 20, 31, 0.16);
  font-weight: 600;
  text-transform: uppercase;
`
