import styled from 'styled-components'
import Shadows from 'builder/styles/shadows'
import Sizes from 'builder/styles/sizes'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import SearchBar from '../SearchBar'

export const Content = styled.div`
  display: flex;
  gap: 32px;
  ${Media.Desktop`
    height: calc(100vh - 242px);
  `}
`

export const JobDetailsContainer = styled.div`
  width: 736px;
  overflow: auto;
  border-radius: ${Sizes['2XS']};

  ${Media.Desktop`
    ${Shadows.lightWithBorder.low};
  `}
`

export const DesktopOnlyVisibleContainer = styled.div`
  display: none;
  ${Media.Desktop`
    display: block;
  `}
`

export const CustomSearchBar = styled(SearchBar)`
  padding: 0;
`

export const Title = styled.div`
  color: ${Colors.Neutral80};
  ${FontWeights.Regular}
  ${Typography.Body}
  display: flex;
  align-items: center;
  ${Media.Phone`
    text-align: center;
  
  `}
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${Sizes.M};
  ${Media.Phone`
    flex-direction: column;
    align-items: center;
    
  `};
`
export const ButtonContainer = styled.div`
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  margin: 0 24px;
`

export const MoreJobColumnContainer = styled.div`
  height: 800px;
  width: 304px;
`

export const MoreJobColumnTitle = styled.div`
  color: ${Colors.Neutral50};
  ${Typography.Caps};
  text-transform: uppercase;
  ${FontWeights.DemiBold};
  margin: 0 24px;
`
export const List = styled.ul`
  list-style: none;
  padding: 0px;
  margin: 0px;
`

export const ManagerJobAlertButtonContainer = styled.div`
  margin: 32px 0;
`
