import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'

import { useI18n } from 'builder/hooks/useI18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import useWebsiteHost from 'builder/components/CIO-Dasboard-2.0/hooks/useWebsiteHost'

import Tabs from 'builder/components/Tabs'
import { selectors, actions } from 'builder/modules/panel'

import {
  EXCEL_AT_JOB,
  FIND_NEW_JOB,
  NavigationTab,
  CHANGE_CAREER,
  TABS_INDEXES,
  INDEXES_TABS,
} from 'builder/components/CIO-Dasboard-2.0/ActionBanner/ActionBanner'
import { TabBorder, NavTab, NavTabContainer, TabsContainer } from './styles'

const TABS: NavigationTab[] = [FIND_NEW_JOB, EXCEL_AT_JOB, CHANGE_CAREER]

export const DashboardTabs = () => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const location = useLocation()
  const { getHost } = useWebsiteHost()

  const activeTabIndex = useTypedSelector(selectors.activeTabId)
  const selectedTab = INDEXES_TABS[activeTabIndex]

  const isDashboardPage = useMemo(() => {
    return location.pathname === '/'
  }, [location.pathname])

  const handleTabOnChange = useCallback(
    (t: NavigationTab) => {
      trackInternalEvent('click_dash_tab', {
        label: t,
        website_host: getHost,
      })
      dispatch(actions.setActiveTabID({ tabId: TABS_INDEXES(i18n.t)[t].index }))
    },
    [dispatch, getHost, i18n.t],
  )

  return (
    <Tabs Container={TabsContainer} isDashboardScreen={isDashboardPage}>
      {TABS.map(tab => (
        <NavTabContainer key={tab}>
          {tab === selectedTab && <TabBorder />}
          <NavTab key={tab} isActive={tab === selectedTab} onClick={() => handleTabOnChange(tab)}>
            {tab === 'Change Career'
              ? i18n.t('builder.navbar_v2.switch_career')
              : TABS_INDEXES(i18n.t)[tab].text}
          </NavTab>
        </NavTabContainer>
      ))}
    </Tabs>
  )
}
