import { memo } from 'react'
import { Container, Header, SectionHeading, SpecialitiesContainer } from './styles'
import CoachSpecialities from './components/CoachSpecialities'
interface Specialities {
  industries: Array<string>
  jobFunctions: Array<string>
}

const Specialities = ({ industries, jobFunctions }: Specialities) => {
  return (
    <Container>
      <SectionHeading>Specialities</SectionHeading>
      <SpecialitiesContainer>
        <Header>Job Functions</Header>
        <CoachSpecialities industriesFunctions={jobFunctions} />
      </SpecialitiesContainer>
      <SpecialitiesContainer>
        <Header>Industries</Header>
        <CoachSpecialities industriesFunctions={industries} />
      </SpecialitiesContainer>
    </Container>
  )
}

export default memo(Specialities)
