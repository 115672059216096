import { useCallback } from 'react'
import { selectors } from 'builder/modules/careerPath2'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

import { SkeletonValue } from '../Skeletons'
import Loader from '../Loader'
import HardSkills from './components/HardSkills'
import SoftSkills from './components/SoftSkills'
import { Heading, Container, Header, MatchPercentage } from './styles'

const RequiredSkills = () => {
  const skills = useTypedSelector(selectors.skills)
  const isExploreCareers = useTypedSelector(selectors.isExploreCareers)
  const skillsLabels = skills.map(skill => skill.label)
  const isLoading = useTypedSelector(selectors.isCareerPagesJobDetailsLoading)
  const { hardSkills, softSkills } = useTypedSelector(selectors.careerPagesJobDetails)

  const requiredSkills = [...hardSkills, ...softSkills]
  const matchedSkills = requiredSkills.filter(skill => skillsLabels.includes(skill))

  const getMatchSkillScore = useCallback(() => {
    const matchedSkillsScore = (matchedSkills.length / requiredSkills.length) * 100

    return Math.ceil(matchedSkillsScore)
  }, [matchedSkills.length, requiredSkills.length])

  return (
    <Container>
      <Header>
        <Heading>Required Skills</Heading>
        {!isExploreCareers ? (
          isLoading ? (
            <SkeletonValue />
          ) : (
            <MatchPercentage score={getMatchSkillScore()}>
              {`${getMatchSkillScore()}%`}
            </MatchPercentage>
          )
        ) : null}
      </Header>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <HardSkills hardSkills={hardSkills} />
          <SoftSkills softSkills={softSkills} />
        </>
      )}
    </Container>
  )
}

export default RequiredSkills
