import { ActionsSkeleton, JobListSkeleton, StatsSkeleton } from './Skeleton/Skeleton'
import { Container } from './styles'

export const WidgetLoading = () => {
  return (
    <Container>
      <StatsSkeleton />
      <JobListSkeleton />
      <ActionsSkeleton />
    </Container>
  )
}
