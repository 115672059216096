import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Button from 'builder/components/Button'
import DragHandle from 'builder/components/DragHandle'

export const Wrapper = styled.div`
  padding-bottom: 12px;
`

export const Container = styled.div<{ isDragging?: boolean }>`
  border: 1px solid ${Colors.Neutral15};
  border-radius: 4px;
  background-color: ${Colors.White};

  /* fix cursor */
  pointer-events: auto !important;
  -webkit-touch-callout: none;
  user-select: none;

  ${props =>
    props.isDragging &&
    css`
      box-shadow: 0px 3px 12px rgba(112, 131, 151, 0.2);
      opacity: 0.6;
    `}
`

export const Header = styled.div`
  position: relative;
  user-select: none;
`

export const HeaderInner = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 70px;
  padding: 15px 20px;
  user-select: none;
  cursor: pointer;
`

export const HeaderContent = styled.div`
  flex-grow: 1;
  min-width: 0;
  margin-right: 8px;
`

const HeaderText = styled.div`
  ${Typography.Caption};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 0.15s;
`

export const HeaderTitle = styled(HeaderText)`
  font-weight: 600;

  ${Media.Hover`
    ${HeaderInner}:hover & {
      color: ${Colors.Blue50};
    }
  `};
`

export const HeaderSubtitle = styled(HeaderText)`
  color: ${Colors.Neutral50};
`

const HeaderIcon = styled.div`
  position: relative;
  flex-shrink: 0;
  color: ${Colors.Neutral40};
  cursor: pointer;
  transition: color 0.15s, opacity 0.15s;

  &:before {
    content: '';
    position: absolute;
    left: -8px;
    right: -8px;
    top: -8px;
    bottom: -8px;
  }

  & svg {
    display: block;
  }
`

export const DesktopIcon = styled(HeaderIcon)`
  ${Media.Laptop`
    display: none;
  `};

  ${Media.Hover`
    &:hover {
      color: ${Colors.Blue50};
    }
  `};
`

export const DeleteIcon = styled(DesktopIcon)`
  position: absolute;
  left: 100%;
  top: 50%;
  margin-left: 8px;
  margin-top: -10px;
  opacity: 0;

  ${Media.Hover`
    ${Header}:hover & {
      opacity: 1;
    }
  `};
`

export const ToggleIcon = styled(HeaderIcon)`
  margin-left: 8px;

  ${Media.Phone`
    display: none;
  `};

  ${Media.Hover`
    ${HeaderInner}:hover & {
      color: ${Colors.Blue50};
    }
  `};
`

export const ToggleIconInner = styled.div<{ isOpen?: boolean }>`
  transform: rotate(${props => (props.isOpen ? '-90deg' : '90deg')});
`

export const MobileIcon = styled(HeaderIcon)`
  display: none;
  margin-left: 8px;

  ${Media.Laptop`
    display: block;
  `};
`

export const DragIcon = styled(DragHandle)`
  position: absolute;
  right: 100%;
  top: 50%;
  margin-right: 3px;
  margin-top: -10px;

  ${Media.Phone`
    margin-right: 1px;
  `};

  ${Media.Hover`
    ${Header}:hover & {
      opacity: 1;
    }
  `};
`

export const Tooltip = styled.div`
  font-weight: 400;
  bottom: calc(100% + 6px);
  white-space: nowrap;
  left: 50%;
  transform: translate(-50%, 0px);
  position: absolute;
  ${Typography.Tiny};
  color: ${Colors.White};
  padding: 5px 8px;
  border-radius: 3px;
  background-color: ${Colors.Neutral90};
  user-select: none;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease, transform 0.2s ease;
  transition-delay: 0;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 0 0 2px 0;
    transform: translate(-50%, -50%) rotateZ(45deg);
    background-color: ${Colors.Neutral90};
    top: 100%;
    left: 50%;
  }

  ${DesktopIcon}:hover & {
    opacity: 1;
    transform: translate(-50%, 0px);
    transition-delay: 0.4s;
  }
`

export const CardContentWrapper = styled.div`
  padding: 4px 20px 24px;

  ${Media.Phone`
    padding: 0;
  `};
`

export const Row = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-flow: row wrap;

  &:last-child {
    margin-bottom: 0;
  }
`

export const Field = styled.div<{ fullwidth?: boolean }>`
  flex: 0 0 ${props => (props.fullwidth ? '100%' : 'calc(50% - 20px)')};
  width: ${props => (props.fullwidth ? '100%' : 'calc(50% - 20px)')};
  margin-right: 40px;

  ${Media.Phone`
    flex: 0 0 100%;
    margin-right: 0;
    margin-bottom: 20px;
  `};

  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
`

export const ModalContent = styled.div`
  flex-grow: 1;
  width: 100%;
  padding: 32px 20px 40px;
`

export const ModalFooter = styled.div`
  width: 100%;
  padding: 0 20px 32px;
`

export const ModalHeader = styled.div`
  position: relative;
  margin-bottom: 20px;
  text-align: center;
  padding: 2px 48px 0;
`

export const ModalTitle = styled.div`
  ${Typography.Subhead};
  font-weight: 600;
  margin-bottom: 2px;
`

export const ModalCaption = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const ModalCloseButton = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: ${Colors.Blue10};
  color: ${Colors.Blue50};
  transform: scaleX(-1);
`

export const ModalDoneButton = styled(Button)`
  display: block;
  width: 100%;
`
