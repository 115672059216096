import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Button from 'builder/components/Button'
import { Container as BaseContainer } from 'builder/components/Container'

export const Footer = styled.footer`
  padding: 80px 0;
  background-color: ${Colors.Neutral5};

  ${Media.Tablet`
    padding: 56px 0;
  `};

  ${Media.Phone`
    padding: 48px 0;
  `};

  &[data-cta='get-help'] {
    background-color: ${Colors.Neutral10};

    & ${Button} {
      background-color: ${Colors.Indigo80};
    }

    & ${Button}:hover {
      background-color: ${Colors.Indigo90};
    }
  }
`

export const FooterContainer = styled(BaseContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const FooterContent = styled.div`
  max-width: 576px;
`

export const FooterTitle = styled.div`
  margin-bottom: 8px;
  ${Typography.M};
  ${FontWeights.DemiBold};

  ${Media.Phone`
    margin-bottom: 4px;
  `};
`

export const FooterText = styled.div`
  margin-bottom: 20px;
`

export const FooterImage = styled.img`
  width: 128px;
  flex-shrink: 0;
  margin-left: 56px;

  ${Media.Phone`
    display: none;
  `};
`
