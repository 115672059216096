import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'
import Media from 'builder/styles/media'
import { TextAreaField } from 'builder/components/TextField'
import Button from 'builder/components/Button'
import { UserPhoto } from 'builder/components/Navigation'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import ModalOverlay from 'builder/components/ModalOverlay'
import Shadows from 'builder/styles/shadows'
import { HorizontalTransitionFade } from 'builder/components/HorizontalTransitionFade'
import Sizes from 'builder/styles/sizes'
import { Icon20 } from 'builder/components/Icon'
import { ChatMessageType } from './types'

export const ChatBotAvatarContainer = styled.img`
  width: 75px;
  margin-bottom: 16px;
`
export const SpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  padding-bottom: 72px;
`

export const MainContainer = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  ${Media.Phone`
  padding: 0 20px;
  `}
`
export const ChatContainer = styled.div`
  padding-bottom: 200px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto;

  ${Media.AbovePhone`
    width: 736px;
  `}
`
/* 8px separation between same person messages and 24 between differnt */
const SEPARATOR_HEIGHT = 8

export const MessageSeparator = styled.div`
  height: ${SEPARATOR_HEIGHT}px;
`
export const MessageLineContainer = styled.div<{
  $type: ChatMessageType
  $clickable?: boolean
  $highlight?: boolean
}>`
  position: relative;
  ${props => (props.$clickable ? 'cursor: pointer;' : null)}
  ${props => (props.$highlight ? 'filter: saturate(6);' : null)}
  ${props =>
    props.$type === 'answer' &&
    css`
      /* align answer bubbles regardless of edit button */
      & > :first-child {
        margin-left: auto;
      }
    `}
  display: flex;
  align-items: center;
  ${props =>
    props.$type === 'answer'
      ? css`
          cursor: pointer;
          margin-top: ${24 - SEPARATOR_HEIGHT}px;
          margin-bottom: ${24 - SEPARATOR_HEIGHT}px;
        `
      : css`
          margin-top: 0;
          margin-bottom: 0;
        `}
`

export const UserPersonaOption = styled(Button)<{ $selected?: boolean }>`
  ${props =>
    props.$selected
      ? css`
          background-color: ${Colors.Neutral10};
          &:hover {
            background-color: ${Colors.Neutral10};
          }
        `
      : ``}

  ${Media.AbovePhone`
    width: 445px;
    margin: 0 0 8px auto;
  `}

  ${Media.Phone`
    width: 100%;
    margin: 0 0 8px;
  `}

  ${Typography.Body};
  ${FontWeights.Regular};
  text-align: left;
  text-wrap: pretty;
  white-space: normal;
  text-overflow: wrap;
  margin-bottom: 8px;
  border-radius: ${Sizes.S};
  padding: ${Sizes.XS} ${Sizes.M};
  justify-content: flex-start;
  box-sizing: border-box;
`
export const SignOption = styled(Button)`
  ${FontWeights.Regular};
  border-radius: 30px;
  margin-bottom: 8px;
  ${Media.AbovePhone`
  width: 100px;
  margin-left: auto;
`}
`

export const blurInAnimation = css`
  @-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
`
export const AvatarContainer = styled.div<{ $type: ChatMessageType }>`
  ${blurInAnimation}
  z-index: 2;
  ${props => (props.$type === 'answer' ? `margin-top: auto;` : null)}
`
export const StyledOuterDiv = styled.div<{ $type: ChatMessageType; $hasOneOrTwoWords: boolean }>`
  ${blurInAnimation}
  z-index: 1;
  position: relative;
  align-items: center;
  border-radius: 16px;
  background: ${props => (props.$type === 'asked' ? Colors.Neutral5 : Colors.Blue50)};
  color: ${props => (props.$type === 'asked' ? Colors.Neutral90 : Colors.Neutral5)};
  padding: 12px 20px;
  white-space: pre-wrap;

  ${Media.Phone`
   max-width: 70vw;
  `}
  ${Media.AbovePhone`
   max-width: 70vw;
  `}

  ${props =>
    props.$hasOneOrTwoWords
      ? css`
          ${Media.Desktop`
            max-width: 100%;
          `}
        `
      : css`
          ${Media.Desktop`
            max-width: 80%;
          `}
        `}

  ${props =>
    props.$type === 'answer' &&
    css`
      margin-left: 0;
    `}


  // fit the bubble corner on asked questions.
  ${props =>
    props.$type === 'asked' &&
    css`
      margin-left: 8px;
    `}
`
export const StyledText = styled.div`
  color: inherit;
  ${Typography.Body}
  ${FontWeights.Regular}
  font-family: 'TT Commons';
  word-wrap: break-word;
`
export const HintContainer = styled.div`
  ${blurInAnimation}
  display: flex;
  flex-direction: row;
  & > img {
    margin-bottom: auto;
  }
`

export const StyledOuterDivHint = styled.div`
  align-items: center;
  max-width: 300px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`
export const StyledTextHint = styled.div`
  font-family: 'TT Commons';
  word-wrap: break-word;
  color: var(--Neutral-Neutral-50, #828ba2);
  ${Typography.Caption}
  ${FontWeights.Regular}
`

export const UserAvatar = styled(UserPhoto)`
  border-radius: 50%;
  margin-left: 16px;
  ${Media.Phone`
    width: 32px;
    height: 32px;
    border-radius: 100%;
  `}
  ${Media.Tablet`
    width: 32px;
    height: 32px;
    border-radius: 100%;
  `}
  ${Media.AboveTablet`
    width: 48px;
    height: 48px;
  `}
`
export const EditMessageButton = styled(Button)`
  margin-top: auto;
  margin-right: 4px;
  height: 36px;
  width: 36px;
  display: flex;
  padding: var(--2XS, 8px);
  align-items: flex-end;
  gap: 1px;
  border-radius: var(--Max, 10000px);
  background: var(--neutral-10);
  :hover {
    background: var(--neutral-10);
  }
`
export const BubbleCornerContainer = styled.div<{ $side?: 'right' | 'left' }>`
  position: absolute;
  bottom: 0;
  ${props =>
    props.$side === 'right'
      ? css`
          right: -10px;
        `
      : css`
          left: -10px;
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
        `}
`
export const BottomControlsContainer = styled.div<{ $isMessageEditing?: boolean }>`
  z-index: 10;
  background: ${Colors.White};
  ${Media.Phone`
    width: 100%;
    padding: 0 20px;
    margin: 0;
    left: 0;
  `}
  ${Media.Tablet`
    width: 100%;
    padding: 0 20px;
    margin: 0;
    left: 0;
  `}
  ${Media.AboveTablet`
    width: 736px;
  `}
  position: fixed;
  bottom: 0;

  ${props =>
    props.$isMessageEditing &&
    css`
      ${Media.Phone`
        border-top: 1px solid ${Colors.Neutral15};
      `}
    `}
`
export const ChatInputWrapper = styled.div<{ $isDashV2: boolean }>`
  width: 100%;
  display: flex;
  align-items: flex-end;
  gap: 8px;
  position: relative;

  ${props =>
    props.$isDashV2 &&
    css`
      ${Media.Tablet`
        width: calc(100% - 40px);
        margin-left: auto;
      `}

      ${Media.Phone`
        width: 100%;
        margin-left: unset;
        padding-bottom: 10px;
      `}
    `}
`

export const ChatInputContainer = styled.div<{ $isExpanded?: boolean; $isButtonVisible: boolean }>`
  width: 100%;
  background: ${Colors.White};
  border: 1px solid var(--neutral-15);
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px 16px 8px 24px;
  border-radius: 32px;
  transition: all 0.4s ease-out;
  will-change: padding, border-radius;
  cursor: text;

  ${Media.Phone`
    padding: 12px 16px;
  `}

  ${props =>
    props.$isButtonVisible &&
    css`
      ${Media.Phone`
        width: calc(100% - 40px);
      `}
    `}

  ${props =>
    props.$isExpanded &&
    css`
      padding: 16px 16px 16px 24px;
      border-radius: 16px;
    `}
`
export const ChatTextArea = styled(TextAreaField)`
  flex: auto;
  * > textarea {
    background: none;
    overflow: hidden;
    border: none;
    padding: 0;
  }
  * > div {
    border: none;
  }
  & > * > div[in='entered'],
  div[in='entering'],
  div[in='exited'] {
    display: none;
  }
`
export const ChatInputButtonWrapper = styled(HorizontalTransitionFade)`
  height: 48px;
  flex: 0 0 48px;

  ${Media.Phone`
    height: 32px;
    width: 32px;
    position: absolute;
    right: 0px;
    bottom: 0px;
  `}
`
export const ChatInputButton = styled(Button)<{ $isVisible?: boolean }>`
  height: 100%;
  width: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  align-self: flex-end;
  will-change: opacity;
  transition: opacity 0.2s ease-out;

  ${props =>
    props.$isVisible &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`
export const EditingMessage = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 12px;

  ${Media.Phone`
    margin-top: 12px;
    margin-bottom: 8px;
  `}
`
export const ResumeOptionsContainer = styled.div`
  display: flex;
  flex-flow: column;

  ${Media.AbovePhone`
    margin-left: auto;
    & > button {
      margin-bottom: 8px;
    }
  `}

  ${Media.Phone`
    display: flex;
    width: 100%;
    justify-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  `}
`
export const ResumeOptionButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px 12px 20px;
  gap: 4px;

  min-width: 272px;
  min-height: 48px;

  background: ${Colors.White};
  background-color: ${Colors.White};

  border-color: ${Colors.Neutral15};
  border-radius: 20px;
  color: ${Colors.Neutral90};
  ${FontWeights.Regular}

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  white-space: wrap;

  ${Media.Phone`
    margin: auto;
    margin-bottom: 8px;
    width: 100%;
  `}
`

export const OptionIcon = styled.img`
  margin-right: 5px;
`

export const ResumeSelectOverlay = styled(ModalOverlay).attrs(() => ({
  background: `${Colors.White}`,
}))``

export const SelectedResumeContainerBackup = styled.div`
  width: 218px;
  padding: 16px;
  gap: 13.63px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid ${Colors.Neutral15};

  border: 1px solid ${Colors.Neutral15};
  border-radius: 20px;

  width: 272px;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & > :nth-child(2) {
    margin-top: auto;
    margin-bottom: auto;
  }

  margin-bottom: 8px;
`

export const SelectedResumeContainer = styled.div`
  border: 1px solid ${Colors.Neutral15};
  border-radius: 20px;
  ${Media.Phone`
    margin: auto;
    width: 100%;
    padding: 10px;
    margin-bottom: 8px;
  `}
  margin-bottom: 8px;
  * {
    cursor: pointer;
  }
  cursor: pointer;
  :hover {
    background: ${Colors.Neutral5};
  }
`

export const ProgressbarContainer = styled.div<{ $isDashV2: boolean }>`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 24px 0;
  z-index: 9;

  ${props =>
    props.$isDashV2 &&
    css`
      ${Media.Tablet`
        width: calc(100% - 40px);
        margin-left: auto;
      `}
      ${Media.Phone`
        width: 100%;
        margin-left: unset;
      `}
    `}
`

export const ProgressbarHead = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  ${Typography.Caption};
  color: ${Colors.Neutral50};
`

export const ProgressbarTextContainer = styled.span`
  white-space: nowrap;
`

export const ProgressbarPercent = styled.span`
  ${FontWeights.Medium};
  color: ${Colors.Neutral80};
`

export const ProgressbarBody = styled.div<{ $progress: number }>`
  flex: auto;
  height: 4px;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  background-color: ${Colors.Neutral10};

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 2px;
    background-color: ${Colors.Blue50};
    height: 100%;
    width: ${props => props.$progress}%;
    will-change: width;
    transition: width 0.2s ease-out;
  }
`
export const FilenameWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-wrap: nowrap;
  white-space: normal;
`

export const UploadContainer = styled.div<{ $selection?: boolean }>`
  margin-bottom: 8px;
  ${Media.AbovePhone`
    width: 272px;
    margin-left: auto;
  `}
  ${Media.Phone`
    margin: auto;
    width: 100%;
    padding: 10px;
  `}
`

export const CareerProfileViewContainer = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  width: calc(100% - 40px);
  display: flex;
  flex-flow: column;
  gap: 16px;
  flex: auto;
`

export const CareerProfileViewNavigation = styled.div`
  background-color: ${Colors.Neutral5};
  border-radius: 12px;
  display: flex;
  gap: 4px;
  padding: 4px;
`

export const CareerProfileViewLink = styled(NavLink)`
  ${Typography.Body};
  ${FontWeights.Regular};
  transition: all 0.2s ease-out;
  background-color: transparent;
  color: ${Colors.Neutral60};
  padding: 8px 12px;
  border-radius: 8px;

  ${Media.Hover`
    &:hover {
      background-color: ${Colors.Neutral10};
      color: ${Colors.Neutral90};
    }
  `}

  &.active {
    ${Shadows.template.low};
    background-color: ${Colors.White};
    color: ${Colors.Neutral90};
  }

  ${Media.Phone`
    padding: 10px 12px;
    flex: 1 1 50%;
    text-align: center;
  `}
`

export const CompleteIcon = styled(Icon20.StatusOffer)`
  color: #25b869;
`
