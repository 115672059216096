import React from 'react'
import { CardType } from '../index'
import { DefaultTag, LoadingTag, EmptyTag } from './styles'

export const CardTag: React.FC<{ type: CardType }> = ({ type }) => {
  if (type === 'empty' || type === 'error') {
    return <EmptyTag />
  }

  if (type === 'loading') {
    return <LoadingTag>Generating...</LoadingTag>
  }

  return <DefaultTag>AI-generated</DefaultTag>
}
