import styled, { css } from 'styled-components'
import { TransitionStatus } from 'react-transition-group'

import Typography from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Button from 'builder/components/Button'
import Icon, { Icon20 } from 'builder/components/Icon'
import { rotation } from 'builder/styles/keyframes'
import { PrefillProviders } from 'builder/modules/resumeEditor'
import SPINNER_URL from 'images/builder/shared/spinner.svg'

export const PrefillPopoverContainer = styled.div<{ in: TransitionStatus; isLaptop: boolean }>`
  position: absolute;
  z-index: 15;
  left: -22px;
  top: 32px;
  width: 335px;
  border-radius: 6px;
  background: #ffffff;
  color: ${Colors.Neutral90};
  box-shadow: 0 2px 14px rgba(23, 68, 130, 0.15), 0 0 1px rgba(23, 68, 130, 0.2);
  padding: 16px;
  opacity: 0;
  transform: translate(0, 5px);
  transition-property: opacity, transform;
  transition-timing-function: ease-out;
  transition-duration: 0.15s;

  &:before {
    content: '';
    position: absolute;
    top: -6px;
    left: 25px;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    background-color: inherit;
    transform: rotate(45deg);
  }

  ${Media.Laptop`
    padding: 25px 20px;
    width: auto;
    max-width: 345px;
    top: 50%;
    left: 0;
    right: 0;
    margin: auto;
    transform: translateY(-50%);
    z-index: 150;
    border-radius: 6px;

    &:before {
      display: none;
    }
  `};

  ${props =>
    props.isLaptop
      ? {
          entering: 'opacity: 0; transform: translateY(0);',
          entered: 'opacity: 1; transform: translateY(-50%);',
          exiting: 'opacity: 1; transform: translateY(-50%);',
          exited: 'opacity: 0; transform: translateY(0);',
          unmounted: null,
        }[props.in]
      : {
          entering: 'opacity: 0; transform: translate(0, 10px);',
          entered: 'opacity: 1; transform: translate(0);',
          exiting: 'opacity: 1; transform: translate(0);',
          exited: 'opacity: 0; transform: translate(0, 10px);',
          unmounted: null,
        }[props.in]};
`

export const PrefillPopoverMobileOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${Colors.Neutral90};
  opacity: 0.9;
  z-index: 100;
`

export const PrefillPopupCloseButton = styled(Icon.Close)`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  color: ${Colors.Neutral50};
`

export const PrefillPopupHeaderContainer = styled.div<{ center?: boolean }>`
  display: flex;

  ${Media.Laptop`
    flex-direction: column;
  `};

  ${props =>
    props.center &&
    css`
      align-items: center;
    `}
`

export const PrefillPopupMobileSpinner = styled.div`
  width: 36px;
  height: 36px;
  background-image: url(${SPINNER_URL});
  margin: 0 auto 16px;
  animation: ${rotation} 0.5s infinite linear;
`

export const PrefillPopupHeadMobile = styled.div`
  ${Typography.Subhead}
  font-weight: 600;
  text-align: center;
`

export const PrefillPopupSubheadMobile = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const PrefillPopupLogo = styled.div<{ service: PrefillProviders; inline?: boolean }>`
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  margin-right: 12px;
  background-image: url(${props =>
    require(`images/builder/resume-editor/${props.service}-logo.svg`)});

  ${props =>
    props.inline
      ? css`
          ${Media.Laptop`
            display: inline-flex;
            width: 22px;
            height: 22px;
            border-radius: 2px;
            background-position: center;
            background-size: 40px;
            margin-right: 4px;
            margin-bottom: -3px;
          `};
        `
      : css`
          ${Media.Laptop`
            width: 48px;
            height: 48px;
            margin-right: 4px;
            background-size: contain;
          `};
        `}
`

export const PrefillPopupHead = styled.div<{ center?: boolean }>`
  ${Typography.Body}
  font-weight: 600;
  padding-right: 20px;

  ${props =>
    props.center &&
    css`
      display: flex;
      align-items: center;
      text-align: center;
      padding-right: 0;
    `}
`

export const PrefillPopupList = styled.ul`
  ${Typography.Caption}
  list-style: none;
  padding: 0;
  margin: 12px 0 0 0;

  ${Media.Laptop`
    margin-bottom: 7px;
  `};
`

export const PrefillPopupListItem = styled.li`
  position: relative;
  padding-left: 23px;
  margin-top: 4px;
  letter-spacing: 0.001em;

  &:first-child {
    margin-top: 0;
  }
`

export const PrefillPopupListItemIcon = styled(Icon20.Tick)`
  color: ${Colors.Blue50};
  position: absolute;
  left: 0;
`

export const PrefillPopupLogoContainer = styled.div`
  position: relative;

  ${Media.Laptop`
    margin-bottom: 10px;
  `};
`

export const PrefillPopupLogoIcon = styled.div<{ failure?: boolean }>`
  display: flex;
  color: ${Colors.White};
  background-color: ${props => (!props.failure ? Colors.Green50 : Colors.Red50)};
  position: absolute;
  top: -6px;
  right: 6px;
  border-radius: 50%;

  ${Media.Laptop`
    display: flex;
    right: -4px;
  `};
`

export const PrefillPopupStatusIconContainer = styled.div<{ failure?: boolean }>`
  border-radius: 50%;
  width: 36px;
  height: 36px;
  background-color: ${props => (!props.failure ? Colors.Green10 : Colors.Red10)};
  align-items: center;
  justify-content: center;
  display: flex;
  margin-bottom: 15px;
  margin-top: 12px;
`

export const PrefillPopupStatusSuccessIcon = styled(Icon.TickLarge)`
  color: ${Colors.Green50};
`

export const PrefillPopupLinkedinStatusSuccess = styled.div`
  position: relative;
  width: 48px;
  height: 48px;
  background-image: url(${require(`images/builder/resume-editor/linkedin-logo.svg`)});
  margin-bottom: 15px;
  margin-top: 12px;
`

export const PrefillPopupLinkedinStatusSuccessIcon = styled(Icon.Tick)`
  color: ${Colors.White};
  background: ${Colors.Green50};
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  top: -5px;
  right: -5px;
`

export const PrefillPopupText = styled.div<{ center?: boolean }>`
  ${Typography.Caption};
  color: ${Colors.Neutral90};
  margin-top: 12px;

  ${props =>
    props.center &&
    css`
      text-align: center;
    `}
`

export const PrefillPopupRequestText = styled.div<{ center?: boolean }>`
  ${Typography.Caption};
  font-weight: 600;
  margin: 16px 0 0;

  ${props =>
    props.center &&
    css`
      text-align: center;
    `}
`

export const PrefillPopupAction = styled(Button)<{ $wide?: boolean }>`
  margin-right: 8px;

  ${props =>
    props.$wide &&
    css`
      width: 100%;
    `}
`

export const PrefillPopupActions = styled.div`
  display: flex;
  margin-top: 16px;

  ${Media.Laptop`
    flex-direction: column-reverse;

    ${PrefillPopupAction} {
      margin-bottom: 10px;

      &: first-child {
        margin-bottom: 0;
      }
    }
  `};
`

export const PrefillPopupProceedButton = styled(Button)`
  width: 100%;
`

export const PrefillPopupWideCloseButton = styled(Button)`
  width: 100%;
`
