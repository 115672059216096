import { DefaultSuggestionType } from 'builder/components/AsyncAutosuggest'
import { LocationCategory } from '../types'

export const BAR_HEIGHT = 80

export const PARAM_TERM = 'term'
export const PARAM_QUERY = 'query'
export const PARAM_LOCATION = 'location'
export const PARAM_LOCATION_ID = 'location_id'
export const PARAM_LOCATION_LAT = 'location_latitude'
export const PARAM_LOCATION_LNG = 'location_longitude'
export const PARAM_OPEN_JOB_ALERT = 'open_job_alert'

export const PARAM_TIME = 'within_n_days'

export const PARAM_PAGE = 'page'

export const PARAM_VIEW = 'view'
export const PARAM_ONLY_AUTO_APPLY_JOBS = 'only_auto_apply_jobs'
export const PARAM_ONLY_REMOTE_JOBS = 'only_remote_jobs'
export const PARAM_RADIUS = 'radius'

export const LOCAL_STORAGE_JS_HISTORY_QUERY_KEY = 'JOB_SEARCH_HISTORY_QUERY_V3'
export const LOCAL_STORAGE_JS_HISTORY_LOCATIONS_KEY = 'JOB_SEARCH_HISTORY_LOCATIONS_V3'

export type LocationTypes = 'location_id' | 'near_me' | 'string_location'

export interface Props {
  withBanner?: boolean
  newDash?: boolean
}

export interface DefaultSuggestion {
  title?: string
  suggestions: Array<DefaultSuggestionType>
}

export const NO_LOCATION_ACCESS_RESULTS = [
  {
    suggestions: [
      {
        formatted: 'Remote',
      },
    ],
  },
]

export interface AddressResponse {
  details: {
    locationTitle: string
  }
  success: boolean
}

export interface LocationResponse {
  id: number
  name: string
  short_name: string
  formatted_name: string
  type: LocationCategory
  parent: LocationResponse
}
