import { trackInternalEvent } from '@rio/tracking'
import { selectors, Label } from 'builder/modules/careerPath2'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { numberToDelimited } from 'builder/utils/numberToDelimited'
import Loader from '../Loader'
import { Container, Title, Value } from './styles'

type Props = {
  isLoading: boolean
}

const Header = ({ isLoading }: Props) => {
  const location = useTypedSelector(selectors.location)
  const { jobsCount } = useTypedSelector(selectors.jobDemand)
  const selectedJobTitle = useTypedSelector(selectors.selectedJobTitle)
  const isExploreCareers = useTypedSelector(selectors.isExploreCareers)
  const exploreCareersLocation = useTypedSelector(selectors.exploreCareersLocation)
  const exploreCareersOccupation = useTypedSelector(selectors.exploreCareersOccupation)

  const jobLocation = isExploreCareers ? exploreCareersLocation : location
  const jobTitle = isExploreCareers ? exploreCareersOccupation : selectedJobTitle

  const handleClick = () => {
    const title = jobTitle?.split(' ').join('+')
    const location = jobLocation?.split(' ').join('+')

    trackInternalEvent('click_job_openings', {
      job_title: jobTitle,
      label: isExploreCareers ? 'explore_careers' : Label.label,
    })

    if (location) {
      window.open(`/app/job-search?query=${title}&location=${location}&view=search`, '_blank')
      return
    }

    window.open(`/app/job-search?query=${title}&location=null&view=search`, '_blank')
  }

  return (
    <Container onClick={handleClick}>
      <Title>Job Openings</Title>
      {isLoading ? (
        <Loader />
      ) : (
        typeof jobsCount === 'number' && <Value>{numberToDelimited(jobsCount)}</Value>
      )}
    </Container>
  )
}

export default Header
