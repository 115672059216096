import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const CounterContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: 4px;
  position: sticky;
  top: 0;
  background: ${Colors.White};
  padding-bottom: 4px;
`

export const CounterHeader = styled.div`
  ${FontWeights.DemiBold};
  display: flex;
  align-items: center;
  gap: 8px;
`

export const Description = styled.div`
  ${Typography.Tiny};
  color: ${Colors.Neutral50};
`
