import { css } from 'styled-components'

const createMedia =
  (query: string) =>
  (...params: Parameters<typeof css>) =>
    css`
      @media ${query} {
        ${css(...params)}
      }
    `

/**
 * The breakpoints are copied from our design system.
 * https://www.figma.com/file/ZY7L17bKSFLWnSf3Se84uzYn/Design-System?node-id=8120%3A9684
 */
export const Media = {
  IphoneSE: createMedia('(max-width: 375px)'),

  Phone: createMedia('(max-width: 767px)'),
  AbovePhone: createMedia('(min-width: 768px)'),

  Tablet: createMedia('(max-width: 1023px)'),
  AboveTablet: createMedia('(min-width: 1024px)'),

  Laptop: createMedia('(max-width: 1199px)'),
  Desktop: createMedia('(min-width: 1200px)'),

  Hover: createMedia('(hover: hover)'),
  HoverNotSupported: createMedia('(hover: none)'),
}

export default Media
