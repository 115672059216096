import { createRef, Component } from 'react'
import PropTypes from 'prop-types'

import { Container, LineWrapper, Line } from './styles'

class SliderProgressBar extends Component {
  static propTypes = {
    steps: PropTypes.number,
    currentStep: PropTypes.number,
  }

  static defaultProps = {
    steps: 1,
    currentStep: 0,
  }

  constructor(props) {
    super(props)

    this.progressBarRef = createRef()

    this.state = {
      currentStep: 0,
      progressWrapperTranslate: 0,
      animatedTranslate: true,
    }
  }

  componentDidUpdate(prevProps) {
    const progressBar = this.progressBarRef.current
    if (!progressBar) return 0

    const currentStep = prevProps.currentStep
    const nextStep = this.props.currentStep

    if (currentStep !== nextStep) {
      const { leftEdgeCase, rightEdgeCase } = this.detectEdgeCases(currentStep, nextStep)

      // If it's the end of the list and we push to the right
      if (rightEdgeCase) {
        this.moveLineWrapper(progressBar.offsetWidth, 0)
        return
      }

      if (leftEdgeCase) {
        this.moveLineWrapper(-this.progressLineWidth, this.movingValue * (this.props.steps - 1))
        return
      }

      this.moveLineWrapper(this.movingValue * nextStep)
    }
  }

  get progressLineWidth() {
    const progressBar = this.progressBarRef.current
    if (!progressBar) return 0

    const width = progressBar.offsetWidth / this.props.steps
    const minLineWidth = progressBar.offsetWidth / 6

    return Math.max(width, minLineWidth)
  }

  get movingValue() {
    const progressBar = this.progressBarRef.current
    if (!progressBar) return 0

    return (progressBar.offsetWidth - this.progressLineWidth) / (this.props.steps - 1)
  }

  moveLineWrapper = (firstTranslate, secondTranslate = null) => {
    this.setState({
      progressWrapperTranslate: firstTranslate,
      animatedTranslate: true,
    })

    if (secondTranslate !== null) {
      setTimeout(
        () =>
          this.setState({
            progressWrapperTranslate: secondTranslate,
            animatedTranslate: false,
          }),
        200,
      )
    }
  }

  // Detect the direction in edge cases:
  // when we are on the first slide and goes to the left
  // or when we are on the last slide and goes to the right
  detectEdgeCases(currentSlide, nextSlide) {
    const slideCount = this.props.steps
    const isCurrentSlideLast = currentSlide === slideCount - 1
    const isCurrentSlideFirst = currentSlide === 0
    const isNextSlideLast = nextSlide === slideCount - 1
    const isNextSlideFirst = nextSlide === 0

    if (isCurrentSlideFirst && isNextSlideLast) return { leftEdgeCase: true }
    if (isCurrentSlideLast && isNextSlideFirst) return { rightEdgeCase: true }
    return {}
  }

  render() {
    return (
      <Container ref={this.progressBarRef}>
        <LineWrapper
          animated={this.state.animatedTranslate}
          translate={this.state.progressWrapperTranslate}
        >
          <Line width={this.progressLineWidth} />
          <Line width={this.progressLineWidth} />
          <Line width={this.progressLineWidth} />
        </LineWrapper>
      </Container>
    )
  }
}

export default SliderProgressBar
