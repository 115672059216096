import { ColumnName, Columns, Job, JobCreatePayload } from './types'

const COLUMN_NAMES = Object.values(ColumnName)

export const columnize = (jobs: Job[]) => {
  const columns: Partial<Columns> = {}
  COLUMN_NAMES.forEach(name => {
    columns[name] = jobs.filter(job => job.status === name)
  })
  return columns as Columns
}

export const flatten = (columns: Columns) => {
  const jobs: Job[] = []
  COLUMN_NAMES.forEach(name => {
    const column = columns[name].map((job, index) => ({ ...job, positionNumber: index }))
    column.forEach(item => jobs.push(item))
  })
  return jobs
}

export const createDummyJob = (
  fields: Partial<Omit<JobCreatePayload, 'external_slug_id'>>,
): Job => {
  return {
    autoApplyStatus: 'processing',
    chromeJob: false,
    company: fields.company,
    companyDomain: null,
    createdAt: new Date().toDateString(),
    description: fields.description,
    externalSlugId: '',
    id: 0,
    isIrrelevant: null,
    jobBoardId: 0,
    stillRelevant: true,
    link: null,
    location: fields.location,
    logo: fields.logo,
    movedAt: null,
    notes: null,
    positionNumber: -1,
    resumeId: 0,
    resumeScore: 1,
    salary: null,
    showBanner: false,
    status: ColumnName.auto_apply,
    tags: [],
    tailorResumeStatus: false,
    title: fields.title,
    updatedAt: new Date().toDateString(),
  }
}
