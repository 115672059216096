import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Resume } from 'packages/types'
import { Store } from 'builder/modules/store'
import { useUpdateSimpleField } from './useUpdateSimpleField'

const useUpdateSimpleShokomuField = (fieldName: keyof Resume) => {
  const fieldValue = useSelector((state: Store) => state.resumeEditor.resume?.[fieldName])
  const { updateSimpleField } = useUpdateSimpleField()

  const updateField = useCallback(
    value => {
      updateSimpleField({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        name: fieldName as any,
        value,
        debounce: true,
      })
    },
    [fieldName, updateSimpleField],
  )

  return {
    fieldName,
    fieldValue: fieldValue ?? '',
    updateField,
  }
}

export { useUpdateSimpleShokomuField }
