export const formatSalary = (value: number) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const formatEnteredValue = (value: string) => {
  let typedValue = Number(value).toLocaleString()

  if (!typedValue.startsWith('$')) {
    typedValue = '$' + typedValue
  }

  return typedValue
}

export const formatDate = (date: Date) => {
  let newDate = new Date(date)

  let day: number | string = newDate.getDate()
  let month: number | string = newDate.getMonth() + 1
  let year: number | string = newDate.getFullYear()

  // Ensure two digit formatting
  if (day < 10) {
    day = '0' + day
  }
  if (month < 10) {
    month = '0' + month
  }

  // Format the date as "dd.mm.yyyy"
  const formattedDate = day + '.' + month + '.' + year

  return formattedDate
}
