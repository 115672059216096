import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

export const Anchor = styled.a``
export const Logo = styled.img``
export const Header = styled.div`
  height: 80px;
  padding-left: 32px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: ${Colors.White};

  ${Media.Phone`
    padding-left: 20px;
  `}
`

export const LayoutContainer = styled.div`
  margin: 0 auto;
  height: calc(100vh - 80px);
`
