import React from 'react'

import { useI18n } from 'builder/hooks/useI18n'

import { BackButton, LeftArrowIcon } from './styles'

interface Props {
  onClick: () => void
}

export const BackButtonMobile: React.FC<Props> = ({ onClick }) => {
  const { i18n } = useI18n()

  return (
    <BackButton onClick={onClick}>
      <LeftArrowIcon />
      {i18n.t(`builder.job_search.job_search_results.back`)}
    </BackButton>
  )
}
