import styled from 'styled-components'
import { animated } from '@react-spring/web'
import Media from 'builder/styles/media'
import Shadows from 'builder/styles/shadows'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import DocumentImagePreview from 'builder/components/DocumentImagePreview'
import { Icon20 } from 'builder/components/Icon'

const radialGradient =
  'radial-gradient(100% 116.2% at 0% 0%, rgba(73, 81, 99, 0.2) 0%, rgba(0, 0, 0, 0) 100%)'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 352px;
  height: 236px;
  border-radius: 16px;
  top: 60px;
  left: 96px;
  padding: 16px;
  background: ${radialGradient},
    linear-gradient(180deg, rgba(30, 37, 50, 0.8) 0%, rgba(30, 37, 50, 0) 100%);
  ${Media.Phone`
    width:279px;
    left:32px;
    top: 0px;
    z-index:1;
  `}
`

export const LeftContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
  ${Media.Phone`
    margin-bottom: 9px;
  `}
`
export const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
  ${Media.Phone`
  margin-left: 12px;
  `}
`

export const CompanyLogo = styled.img`
  width: 48px;
  height: 48px;
  ${Shadows.lightWithBorder.low};
  border-radius: 12px;
  ${Media.Phone`
        width: 32px;
        height: 32px;
        border-radius: 4px;
      `};
`

export const CompanyDesignation = styled.div`
  ${Typography.Caption}
  ${FontWeights.Regular}
  max-width: 221px;
  height: 20px;
  color: ${Colors.White};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 8px;
  ${Media.Phone`
    max-width: 192px;  
    height: 16px; 
  `};
`

export const Hostname = styled.div`
  ${Typography.Caption}
  ${FontWeights.Regular}
  color: ${Colors.Neutral40};
`

export const Progress = styled.div`
  height: 3px;
  background-color: ${Colors.Neutral80};
  margin-top: 6px;
`

export const ProgressInner = styled(animated.div)`
  height: 3px;
  width: 100%;
  transform-origin: left;
`

export const ResumePreview = styled(DocumentImagePreview)`
  width: 320px;
  margin-top: 12px;
  border-radius: 2px;
  background: linear-gradient(180deg, #f7f9fc 0%, ${Colors.White} 100%);
  border-image: linear-gradient(to bottom, ${Colors.Neutral15} 60%, ${Colors.White} 100%);
  border-image-slice: 1;
  ${Media.Phone`
    width: 252px;
  `}
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`

export const EditIcon = styled(Icon20.Edit)`
  width: 18px;
  height: 18px;
  margin-left: 4px;
  color: ${Colors.Neutral30};
  cursor: pointer;
  &:hover {
    color: ${Colors.Blue50};
  }
  ${Media.Phone`
  height: 16px;
  width: 16px;
  margin-left: 0px;
  `}
`
