import { useCallback, useEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import ModalOverlay from 'builder/components/ModalOverlay'
import { useI18n } from 'builder/hooks/useI18n'
import Button from 'builder/components/Button'
import { useOverviewModal } from '../../hooks/useOverviewModal'
import {
  Container,
  Header,
  Title,
  SubTitle,
  ActionContainer,
  CloseIconWrapper,
  CloseIcon,
} from './styles'

const EVENT_LABEL = 'auto_tailoring_overview_close_popup'

export const ApproveModal = () => {
  const { i18n } = useI18n()
  const { handleCloseApproveModal, handleApprove, handleLeave } = useOverviewModal()

  useEffect(() => {
    trackInternalEvent('see_auto_tailoring_overview_close_popup')
  }, [])

  const handleLeaveClick = useCallback(() => {
    handleCloseApproveModal()
    handleLeave()
    trackInternalEvent('click_leave', { label: EVENT_LABEL })
  }, [handleCloseApproveModal, handleLeave])

  const handleApproveClick = useCallback(() => {
    handleCloseApproveModal()
    handleApprove()
    trackInternalEvent('click_approve', { label: EVENT_LABEL })
  }, [handleCloseApproveModal, handleApprove])

  return (
    <ModalOverlay>
      <Container>
        <Header>
          <Title>{i18n.t('builder.auto_tailoring.overview.confirmation_modal.title')}</Title>
          <SubTitle>
            {i18n.t('builder.auto_tailoring.overview.confirmation_modal.sub_title')}
          </SubTitle>
        </Header>
        <ActionContainer>
          <Button theme="ghost" onClick={handleLeaveClick}>
            {i18n.t('builder.auto_tailoring.overview.confirmation_modal.leave_button_text')}
          </Button>
          <Button onClick={handleApproveClick}>
            {i18n.t('builder.auto_tailoring.overview.approve_button')}
          </Button>
        </ActionContainer>
        <CloseIconWrapper onClick={handleCloseApproveModal}>
          <CloseIcon />
        </CloseIconWrapper>
      </Container>
    </ModalOverlay>
  )
}
