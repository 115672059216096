import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

export const Navigation = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  list-style-type: none;
  gap: 60px;
  padding: 42px 0;
  width: 100%;
  justify-content: center;

  ${Media.Tablet`
    gap: 32px;
  `}

  ${Media.Phone`
    flex-wrap: wrap;
    gap: 24px 43px;
    padding: 40px 47px 40px 44px;
  `}
`

export const NavigationLink = styled.li`
  color: ${Colors.Neutral50};
  cursor: pointer;
`
