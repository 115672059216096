import { ButtonTheme } from 'builder/components/Button/types'
import Button from 'builder/components/Button'
import useWizardSteps from '../../hooks/useWizardSteps'

import useRedirect from '../../hooks/useRedirect'
import useEmptyStepIds from '../../hooks/useEmptyStepIds'
import { WizardFooterContainer } from './styles'

export const WizardFooter = () => {
  const { currentStep, isFirstStep, isLastStep, nextStep, prevStep } = useWizardSteps()
  const { redirectToEditor } = useRedirect()
  const emptyStepIds = useEmptyStepIds()

  return (
    <WizardFooterContainer>
      {!isFirstStep && (
        <Button theme={ButtonTheme.ghost} onClick={prevStep}>
          Back
        </Button>
      )}
      <Button onClick={isLastStep ? redirectToEditor : nextStep}>
        {emptyStepIds.includes(currentStep.id) ? 'Skip' : 'Next'}
      </Button>
    </WizardFooterContainer>
  )
}
