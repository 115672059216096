import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { Typography, FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
`

export const Title = styled.div`
  ${Typography.L};
  ${FontWeights.DemiBold};
`

export const SubTitle = styled.div`
  color: ${Colors.Neutral50};
`
