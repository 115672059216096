import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography from 'builder/styles/typography'

type Props = {
  $isActive?: boolean
  $isDisabled?: boolean
  $isClickableWhenActive?: boolean
}

export const Container = styled.div<Props>`
  --background-color: ${Colors.Neutral10};
  --text-color: ${Colors.Neutral90};

  ${Typography.Caption};
  color: var(--text-color);
  background-color: var(--background-color);

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 6px 8px 6px 12px;
  gap: 2px;
  border-radius: 4px;
  white-space: nowrap;
  transition: background-color 0.1s, color 0.1s;
  cursor: pointer;

  ${Media.Phone`
    padding: 6px 16px 6px 12px;
  `}

  ${Media.Hover`
    &:hover {
      --text-color: ${Colors.Indigo60};
    }
  `};

  ${props =>
    props.$isActive &&
    css`
      --background-color: #e6e8ff;
      pointer-events: ${props.$isClickableWhenActive ? 'auto' : 'none'};
      --text-color: ${Colors.Indigo80};
    `};

  ${props =>
    props.$isDisabled &&
    css`
      --background-color: ${Colors.Neutral5};
      --text-color: ${Colors.Neutral50};
      pointer-events: none;
    `};
`

export const Text = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`

export const IconContainer = styled.span`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  gap: 2px;
  color: inherit;
`
