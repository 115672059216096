import { trackInternalEvent } from '@rio/tracking'
import { NavigateFunction } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ButtonTheme } from 'builder/components/Button'
import { trackingInfo } from 'builder/modules/careerCoaching/constants'
import { actions, PackageDetails } from 'builder/modules/careerCoaching'
import { getPhrase, getPackageValidity } from '../../utils'

import noCoachURL from '../../assets/img/no_coach.svg'
import {
  CoachDetails,
  ActionDetails,
  CoachImage,
  CoachCaption,
  CoachAvatar,
  Heading,
  Caption,
  StyledButton,
} from './styles'

interface Props {
  coachesAvailable: boolean
  navigate: NavigateFunction
  packageDetails: PackageDetails
}

export const NoCoachAvailable = ({ coachesAvailable, navigate, packageDetails }: Props) => {
  const dispatch = useDispatch()
  const { total_sessions: totalSessions, expiration_date: expirationDate } = packageDetails

  const handleClick = (e: MouseEvent) => {
    const target = e.target as HTMLButtonElement

    if (target.innerText === 'Choose a Coach') {
      dispatch(actions.setUserRedirectState(false))

      navigate('/coach-listing')
      return
    }

    window.open('mailto:coaching@career.io', '_self')
    trackInternalEvent('click_contact_support_button', { ...trackingInfo })
  }

  return (
    <>
      <CoachDetails>
        <CoachImage>
          <CoachAvatar src={noCoachURL} />
        </CoachImage>
        <CoachCaption>
          <Heading>
            {coachesAvailable
              ? 'Choose a coach to get started'
              : 'We’ll find you a coach within 24 hours'}
          </Heading>
          <Caption>
            {/* NOTE: DO NOT ADD EMPTY SPACE */}
            {totalSessions} {getPhrase(+totalSessions)} package{getPackageValidity(expirationDate)}
          </Caption>
        </CoachCaption>
      </CoachDetails>
      <ActionDetails>
        <StyledButton theme={!coachesAvailable && ButtonTheme.ghost} onClick={handleClick}>
          {coachesAvailable ? 'Choose a Coach' : 'Contact Support'}
        </StyledButton>
      </ActionDetails>
    </>
  )
}
