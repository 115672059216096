import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Shadows from 'builder/styles/shadows'
import Icon24 from 'builder/components/Icon'
import Typography from 'builder/styles/typography'

export const Wrapper = styled.div`
  border: 12px solid ${Colors.Neutral5};
  border-radius: 24px;
  height: fit-content;
  gap: 8px;
  display: flex;
  flex-direction: column;
`

export const Title = styled.div`
  ${Typography.Subhead};
`

export const IconBackground = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`

export const EditIcon = styled(Icon24.Edit)`
  opacity: 0;
  color: ${Colors.Blue50};
`

export const Container = styled.div<{ content?: string }>`
  width: 328px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${Colors.Neutral15};
  border-radius: 12px;

  &:hover {
    ${Shadows.lightWithBorder.mid};
    cursor: pointer;
  }

  &:hover ${Title} {
    color: ${Colors.Blue50};
  }

  &:hover ${IconBackground} {
    background-color: ${Colors.Blue10};
  }

  &:hover ${EditIcon} {
    opacity: 1;
  }

  &:active ${Title} {
    color: ${Colors.Blue60};
  }

  &:active ${IconBackground} {
    background-color: ${Colors.Blue20};
  }
`

export const Text = styled.div`
  ${Typography.Caption};
  width: 100%;
  color: ${Colors.Neutral50};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`
