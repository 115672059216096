import Coaches from './components/Coaches'
import { Container } from './styles'

const PlanNewSession = (): JSX.Element => {
  return (
    <Container>
      <Coaches />
    </Container>
  )
}

export default PlanNewSession
