import styled from 'styled-components'
import { TransitionStatus } from 'react-transition-group'
import { Container as BaseContainer } from 'builder/components/Container'
import Colors from 'builder/styles/colors'
import { Button } from 'builder/components/Button'
import Media from 'builder/styles/media'

export const StickedButtonContainer = styled(BaseContainer)<{ transitionState: TransitionStatus }>`
  position: fixed;
  bottom: 0;
  padding-top: 16px;
  padding-bottom: 32px;
  background: ${Colors.White};
  opacity: 0;
  transform: translateY(50px);
  transition: transform 400ms ease-in-out, opacity 300ms ease-in-out;

  ${props =>
    ({
      entering: null,
      entered: 'transform: translateY(0); opacity: 1;',
      exiting: null,
      exited: 'transform: translateY(50px); opacity: 0;',
      unmounted: 'transform: translateY(50px); opacity: 0;',
    }[props?.transitionState])};
`

export const ButtonConfirm = styled(Button)`
  background: var(--color-accent);

  &:hover {
    background-color: var(--color-accent-hover);
  }

  ${Media.Phone`
    width: 100%;
  `};
`
