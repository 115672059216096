import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import { Icon24 } from 'builder/components/Icon'
import Media from 'builder/styles/media'

export const Overlay = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background: #000000ba;
  z-index: 9999;
  height: 100vh;
  width: 100vw;
`

export const ModalRootContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 594px;
  width: 814px;
  background: ${Colors.White};
  border-radius: 16px;
  padding: 40px;
  align-items: end;

  //  for mobile
  ${Media.Phone`
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding: 20px 24px 32px 24px;
    align-items: center;
  `}
`

export const ModalContent = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  ${Media.Phone`
    height: 100%;
    width: 100%;
    max-width: 356px;
  `}
`

export const ModalTitle = styled.h2`
  grid-column-start: center;
  grid-column-end: center;
  text-align: center;
  color: ${Colors.Neutral90};
  font-family: 'TT Commons', system-ui, -apple-system, 'Segoe UI', Roboto, Ubuntu, Cantarell,
    'Noto Sans', sans-serif;
  font-size: 33px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;

  ${Media.Phone`
    font-size: 23px;
    line-height: 24px;
  `}
`
export const ModalCardsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  gap: 30px;

  ${Media.Phone`
    height: 100%;
    width: 100%;
    flex-direction: column;
    gap: 16px;
  `}
`

export const ModalCard = styled.div<{ disabled?: boolean }>`
  width: 352px;
  height: 446px;
  flex-shrink: 0;
  border-radius: 16px;
  backdrop-filter: ${Colors.White};
  border: 1px solid ${Colors.Neutral15};
  transition: box-shadow 0.3s;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 1px 0px rgba(15, 56, 113, 0.32), 0px 6px 8px -2px rgba(15, 56, 113, 0.02),
      0px 16px 64px -8px rgba(15, 56, 113, 0.18);
  }

  ${({ disabled }) =>
    disabled &&
    css`
      box-shadow: none;
      cursor: auto;
      &:hover {
        box-shadow: none;
      }
    `}

  ${Media.Phone`
    width: 100%;
    max-height: 268px;
  `}
`

export const ModalCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 16px;

  ${Media.Phone`
    gap: 8px;
  `}
`

export const ModalCardImage = styled.img`
  width: 100%;
  height: 216px;
  border-radius: 16px 16px 0 0;
  object-fit: cover;

  ${Media.Phone`
    height: 100px;
    border-radius: 16px 16px 0 0;
    object-position: top;
  `}
`

export const ModalCardSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 36px;

  ${Media.Phone`
    height: 100px;
    border-radius: 16px 16px 0 0;
    object-position: top;
  `}
`

export const ModalCardTitle = styled.p`
  color: ${Colors.Neutral90};
  text-align: center;
  font-family: 'TT Commons', system-ui, -apple-system, 'Segoe UI', Roboto, Ubuntu, Cantarell,
    'Noto Sans', sans-serif;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;

  ${Media.Phone`
    font-size: 19px;
    line-height: 22px;
  `}
`

export const ModalCardDescription = styled.p`
  color: ${Colors.Neutral50};
  text-align: center;

  /* [Desktop & Tablet]/Caption • Regular */
  font-family: 'TT Commons', system-ui, -apple-system, 'Segoe UI', Roboto, Ubuntu, Cantarell,
    'Noto Sans', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  ${Media.Phone`
    font-size: 14px;
    line-height: 16px;
  `}
`

export const ModalCardTextContainer = styled.div`
  width: 272px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`

export const ModalCardCTAContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ModalCardCTA = styled.button`
  border-radius: 4px;
  border: none;
  background: ${Colors.Blue50};
  color: ${Colors.White};
  text-align: center;
  font-family: 'TT Commons', system-ui, -apple-system, 'Segoe UI', Roboto, Ubuntu, Cantarell,
    'Noto Sans', sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  display: inline-flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background: ${Colors.Blue60};
  }

  &:active {
    background: ${Colors.Blue70};
  }

  &:disabled {
    color: ${Colors.Neutral50};
    background-color: transparent;
    text-align: center;
    font-family: 'TT Commons', system-ui, -apple-system, 'Segoe UI', Roboto, Ubuntu, Cantarell,
      'Noto Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
  }

  ${Media.Phone`
    font-size: 16px;
    padding: 8px 12px;
  `}
`

export const ModalCardHeader = styled.div`
  display: grid;
  grid-template-columns: [left] 1fr [center] 3fr [right] 1fr;
  width: 100%;
`

export const CloseIcon = styled(Icon24.Close)`
  display: block;
  color: ${Colors.Neutral50};
  transition: color 0.3s;

  &:hover {
    color: ${Colors.Blue50};
  }
`

export const Close = styled.div`
  display: flex;
  justify-self: flex-end;
  right: 0;
  height: fit-content;
  width: fit-content;
  background: ${Colors.Neutral10};
  border-radius: 32px;
  color: ${Colors.Neutral30};
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${Colors.Blue10};
  }

  ${Media.Phone`
    top: 22px;
  `}
`
