import { useCallback } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { actions, selectors } from 'builder/modules/coRegistration'
import ModalOverlay from 'builder/components/ModalOverlay'
import Success from './Success'

import { ModalContainer, ModalClose, ModalCloseIcon } from './styles'

const Modal = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(selectors.isModalOpen)
  const services = useSelector(selectors.services, shallowEqual)
  const handleClose = useCallback(() => dispatch(actions.toggleModal(false)), [dispatch])

  if (!isOpen) return null

  return (
    <ModalOverlay onClick={handleClose} overlayFadeDuration={150} contentSlideDuration={150}>
      <ModalContainer>
        <ModalClose onClick={handleClose}>
          <ModalCloseIcon />
        </ModalClose>
        <Success services={services} itemTheme="bordered" onBack={handleClose} />
      </ModalContainer>
    </ModalOverlay>
  )
}

export default Modal
