import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Button from 'builder/components/Button'
import { TextField } from 'builder/components/TextField'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
`

export const Question = styled.div`
  & > span {
    ${FontWeights.DemiBold};
  }
`

export const Form = styled.form`
  display: flex;
  gap: 16px;

  ${Media.Phone`
    flex-direction: column;
    gap :24px;
  `}
`

export const Input = styled(TextField)`
  width: 100%;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-inner-spin-button,
  input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`

export const JobTitle = styled.span``

export const Location = styled.span``

export const Wrapper = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: column;
  flex: auto;
`

export const Message = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};

  > span {
    ${FontWeights.Medium};
    color: ${Colors.Neutral90};
  }
`

export const StyledButton = styled(Button)`
  height: fit-content;
`

export const InputWrapper = styled.div<{ isEntered: boolean }>`
  display: flex;
  align-items: center;
  padding-left: ${props => (props.isEntered ? 16 : 0)}px;
  background-color: ${Colors.Neutral10};
`

export const Dollar = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`
