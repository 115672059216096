import styled from 'styled-components'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Button from 'builder/components/Button'
import { Icon24 } from 'builder/components/Icon'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;

  ${Media.Phone`
    flex-direction: column;
    justify-content: center;
  `};
`

export const Intake = styled.img`
  width: 69.76px;
  height: 75.52px;
  padding: 2.24px 5.12px;
  justify-content: center;
  align-items: center;
  margin: auto;

  ${Media.Phone`
    width: 57.28px;
    height: 56.96px;
  `};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  margin: 8px auto 16px auto;
`

export const Header = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};
  margin: auto;
  text-align: center;
`

export const SubHeader = styled.div`
  text-align: center;
`
export const ButtonContainer = styled.div`
  width: 161px;
  margin: auto;
`

export const SummaryContainer = styled.div`
  margin-top: 40px;

  ${Media.Phone`
    margin-top: 24px;
  `}
`

export const SummaryCard = styled.div`
  padding: 16px;
  background-color: ${Colors.Neutral5};
  border-radius: 8px;
  margin-bottom: 12px;
`

export const SummaryCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const SummaryCardTitle = styled.div`
  margin-bottom: 8px;
`

export const EditButton = styled(Icon24.EditAlt)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: all ease-in 0.3s;

  &:hover {
    path {
      stroke: ${Colors.Blue60};
    }
  }
`

export const SummaryCardBadgeContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
`
export const SummaryCardWrapper = styled.div`
  display: flex;
  gap: 6px;
`

export const SummaryCardBadge = styled.div`
  ${Typography.Tiny};
  ${FontWeights.Medium};
  background-color: ${Colors.Neutral10};
  color: ${Colors.Neutral50};
  padding: 4px 6px;
  gap: 2px;
  border-radius: 2px;
  cursor: pointer;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limit to 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
`

export const ViewPackages = styled(Button)`
  padding: 12px 24px;
  font-weight: 500;
`
