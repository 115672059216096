import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography from 'builder/styles/typography'

export const Wrapper = styled.div`
  padding-bottom: 32px;
  margin-bottom: 36px;
  margin-top: 8px;
  border-bottom: 1px solid ${Colors.Neutral15};
`

export const Chart = styled.div`
  margin-top: 16px;
  // Hack for resize https://newdevzone.com/posts/how-to-get-react-chartjs-to-resize-back-down-with-window
  width: 99%;
`

export const Title = styled.div`
  ${Typography.Body};
  color: ${Colors.Neutral50};
`

export const Text = styled.div`
  margin-top: 24px;
  color: ${Colors.Neutral50};

  ${Media.Tablet`
    border-bottom: none;
  `};
`

export const EmptyContainer = styled.div`
  padding-bottom: 32px;
  margin-bottom: 36px;
  border-bottom: 1px solid ${Colors.Neutral15};
`

export const DemandContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 36px;
  margin-bottom: 32px;
`
