import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'

import { Container, Button, Subtitle, Title } from '../styles'
import { ImagesContainer, PersonOne, PersonTwo, PersonThree } from './styles'
import PERSON_ONE from './assets/person-one.jpeg'
import PERSON_TWO from './assets/person-two.jpeg'
import PERSON_THREE from './assets/person-three.jpeg'

const TRANSLATION = 'builder.dashboard_v2.banners.coach'

export const CoachBanner = () => {
  const { i18n } = useI18n()
  const navigate = useNavigate()

  const bookCoachOnClick = useCallback(() => {
    trackInternalEvent('click_career_coaching_banner', { label: 'find_a_job' })
    navigate('/career-coaching')
  }, [navigate])

  return (
    <Container>
      <Title>{i18n.t(`${TRANSLATION}.title`)}</Title>
      <Subtitle>{i18n.t(`${TRANSLATION}.sub_title`)}</Subtitle>
      <Button onClick={bookCoachOnClick}>{i18n.t(`${TRANSLATION}.button`)}</Button>
      <ImagesContainer>
        <PersonOne src={PERSON_ONE} />
        <PersonTwo src={PERSON_TWO} />
        <PersonThree src={PERSON_THREE} />
      </ImagesContainer>
    </Container>
  )
}
