import styled, { css } from 'styled-components'

export const DrawerContainer = styled.div<{
  width?: string
  isOpen?: boolean
  isSubMenu?: boolean
  subMenuWidth?: string
}>`
  width: ${({ width }) => width};
  height: calc(100vh - 80px);
  background: #fff;
  padding: 12px 0;

  ${({ isSubMenu, isOpen, width, subMenuWidth }) =>
    isSubMenu &&
    css`
      height: auto;
      position: fixed;
      width: ${subMenuWidth || '240px'};
      display: ${isOpen ? 'block' : 'none'};
      left: ${isSubMenu ? width || '240px' : 0};
      border-radius: 16px;
      box-shadow: 0px 0px 1px 0px rgba(15, 56, 113, 0.32), 0px 6px 8px -2px rgba(15, 56, 113, 0.02),
        0px 16px 64px -8px rgba(15, 56, 113, 0.18);
    `}
`
