import { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import {
  CHANGE_CAREER,
  EXCEL_AT_JOB,
  FIND_NEW_JOB,
  INDEXES_TABS,
  TABS_INDEXES,
} from 'builder/components/CIO-Dasboard-2.0/ActionBanner/ActionBanner'
import { useI18n } from 'builder/hooks/useI18n'
import { actions, selectors } from 'builder/modules/panel'
import { useClickOutside } from 'builder/hooks/useClickOutside'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import useWebsiteHost from 'builder/components/CIO-Dasboard-2.0/hooks/useWebsiteHost'

import {
  TabInSwitcher,
  TabSwitcher,
  Container,
  DropdownContainer,
  TabContainer,
  Tab,
  Tick,
  ArrowDown,
} from './styles'

export const TabsSwitcherTablet = () => {
  const { i18n } = useI18n()
  const activeTabIndex = useTypedSelector(selectors.activeTabId)
  const [isTabsMenuVisible, setIsTabsMenuVisible] = useState(false)
  const ref = useRef<HTMLDivElement | null>(null)
  const dispatch = useDispatch()
  const { getHost } = useWebsiteHost()

  useClickOutside(ref, () => setIsTabsMenuVisible(false))

  const handleTabOnChange = (selectedIndex: number) => {
    setIsTabsMenuVisible(false)
    trackInternalEvent('click_dash_tab', {
      label: INDEXES_TABS[selectedIndex],
      website_host: getHost,
    })
    dispatch(actions.setActiveTabID({ tabId: selectedIndex }))
  }

  const handleTabOnClick = (tabIndex: number) => {
    if (activeTabIndex === tabIndex) {
      return
    }

    handleTabOnChange(tabIndex)
  }

  const selectedTab = INDEXES_TABS[activeTabIndex]

  return (
    <Container>
      <TabSwitcher ref={ref} onClick={() => setIsTabsMenuVisible(!isTabsMenuVisible)}>
        <TabInSwitcher>
          {selectedTab === 'Change Career'
            ? i18n.t('builder.navbar_v2.switch_career')
            : TABS_INDEXES(i18n.t)[selectedTab].text}
        </TabInSwitcher>
        <ArrowDown />

        {isTabsMenuVisible && (
          <DropdownContainer>
            <TabContainer
              onClick={() => handleTabOnClick(TABS_INDEXES(i18n.t)[FIND_NEW_JOB].index)}
            >
              <Tab isActive={selectedTab === FIND_NEW_JOB}>
                {TABS_INDEXES(i18n.t)[FIND_NEW_JOB].text}
              </Tab>
              {selectedTab === FIND_NEW_JOB && <Tick />}
            </TabContainer>
            <TabContainer
              onClick={() => handleTabOnClick(TABS_INDEXES(i18n.t)[EXCEL_AT_JOB].index)}
            >
              <Tab isActive={selectedTab === EXCEL_AT_JOB}>
                {TABS_INDEXES(i18n.t)[EXCEL_AT_JOB].text}
              </Tab>
              {selectedTab === EXCEL_AT_JOB && <Tick />}
            </TabContainer>
            <TabContainer
              onClick={() => handleTabOnClick(TABS_INDEXES(i18n.t)[CHANGE_CAREER].index)}
            >
              <Tab isActive={selectedTab === CHANGE_CAREER}>
                {i18n.t('builder.navbar_v2.switch_career')}
              </Tab>
              {selectedTab === CHANGE_CAREER && <Tick />}
            </TabContainer>
          </DropdownContainer>
        )}
      </TabSwitcher>
    </Container>
  )
}
