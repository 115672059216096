import { BriefResume, AIRefreshStatus } from 'builder/modules/panel'

export const defaultOptimizerScore: BriefResume = {
  id: 0,
  type: 'resume',
  secureId: '',
  renderingToken: '',
  name: '',
  template: 'rirekisho',
  version: '0',
  updatedAt: '',
  createdAt: '',
  resumeScore: 0,
  averageScore: 0,
  jobPostingScore: 0,
  employerName: '',
  jobTitle: '',
  aiJobStatus: AIRefreshStatus.PENDING,
  aiTaskId: null,
  jobPostingId: null,
  autoTailored: false,
  originalResumeId: null,
  draft: false,
}
