import { Fragment, useMemo } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { UploadResumeLocalStoreKeys } from 'builder/views/UploadResumeView/utils'
import { objectToQueryParamsString } from 'builder/components/Panel/CIOCritiqueReview/UploadResume/components/CreateResumeInstantly/constants'
import {
  GET_EXPERT_HELP_LOW_LEADS_URL,
  GET_EXPERT_HELP_V2_NO_DISCOUNT,
} from 'builder/modules/resumeCritiqueReview/constants'
import * as Styles from './styles'
import DiscountTabletBanner from './DiscountTabletBanner'
import DiscountDesktopBanner from './DiscountDesktopBanner'

const divisionImage = require('images/critique-review/divisionImage.svg')

interface Props {
  isDiscountAvailable: boolean
}

const DiscountBanner = ({ isDiscountAvailable }: Props) => {
  const { isTablet } = useMediaQueries()
  const customUTMs = useMemo(() => {
    const myStoryUTM = localStorage.getItem(UploadResumeLocalStoreKeys.MY_STORY_UTMS)
    if (myStoryUTM) {
      return objectToQueryParamsString(JSON.parse(myStoryUTM))
    }
    return null
  }, [])

  const handleTRBannerClick = () => {
    trackInternalEvent('click_tr_banner', { label: 'critique_page' })
    window.open(
      isDiscountAvailable
        ? GET_EXPERT_HELP_LOW_LEADS_URL(customUTMs)
        : GET_EXPERT_HELP_V2_NO_DISCOUNT(customUTMs),
      '_blank',
    )
  }

  return (
    <Fragment>
      {!isTablet && <Styles.DivisionImage src={divisionImage} />}
      {isTablet ? (
        <DiscountTabletBanner
          isDiscountAvailable={isDiscountAvailable}
          handleTRBannerClick={handleTRBannerClick}
        />
      ) : (
        <DiscountDesktopBanner
          isDiscountAvailable={isDiscountAvailable}
          handleTRBannerClick={handleTRBannerClick}
        />
      )}
    </Fragment>
  )
}

export default DiscountBanner
