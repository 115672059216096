import { ModalTitle, Close, CloseIcon } from '../../styles'

type Props = {
  onClose: () => void
}

const Header = ({ onClose }: Props) => {
  return (
    <>
      <ModalTitle>Choose a resume to auto-fill skills</ModalTitle>
      <Close onClick={onClose}>
        <CloseIcon />
      </Close>
    </>
  )
}

export default Header
