export enum StartCompleteScreenRoute {
  START_SCREEN = 'start',
  RESUME_SELECT = 'resume-select',
  COMPLETED = 'completed',
  RD_COMPLETED = 'completed',
  RESUME_DISTRIBUTION = 'rd',
}

export enum Routes {
  PERSONAL_INFO = 'personal-info',
  CONTACT_INFO = 'contact-info',
  JOB_PREFERENCES_1 = 'job-preferences-1',
  JOB_PREFERENCES_2 = 'job-preferences-2',
  LICENSES = 'license-certicate',
}

export enum ResumeDistributionRoutes {
  JOB_PREFERENCES = 'job-preferences',
}

export const TotalPageCount = Object.keys(Routes).length
export const TotalRdPageCount = Object.keys(ResumeDistributionRoutes).length

export const ALLOWED_FILES =
  '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf'

export const DESKTOP_PREVIEW_WIDTH = 192
export const PHONE_PREVIEW_WIDTH = 104

export type SocialItemType = {
  id: string
  name: string
}

export const SOCIAL_OPTIONS: SocialItemType[] = [
  { id: 'linkedin', name: 'Linkedin' },
  { id: 'dribble', name: 'Dribble' },
  { id: 'github', name: 'Github' },
  { id: 'behance', name: 'Behance' },
  { id: 'others', name: 'Others' },
]

export const AUTO_APPLY_VISITED_KEY = 'AUTO_APPLY_VISITED_KEY'
