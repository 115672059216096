import { ToneNames } from 'builder/styles/colors'

export const TABS = ['Upcoming', 'Past']
export const COLOR_TONES_ARR: ToneNames[] = [
  'Indigo',
  'Blue',
  'Green',
  'Amber',
  'Orange',
  'Red',
  'Beige',
]

export const EVENTS_PER_PAGE_SIZE = 9
