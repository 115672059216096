import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as panelSelectors } from 'builder/modules/panel'
import { useCareerServiceTitle } from 'builder/components/Panel/CareerGoal/hooks/useCareerServiceTitle'
import { CoachList } from '../CoachList'
import useWebsiteHost from '../../hooks/useWebsiteHost'
import {
  Container,
  CoachImages,
  CoachDetails,
  CoachTitle,
  CoachDescription,
  CoachAction,
  CoachButton,
} from './Style'
import { CareerCoachDescription, CareerCoachTitles } from './constants'

export const BookCoachBanner = () => {
  const navigate = useNavigate()
  const selectedIndex = useTypedSelector(panelSelectors.activeTabId)
  const { getHost } = useWebsiteHost()
  const tab = useCareerServiceTitle({ index: selectedIndex })

  const renderTitle = useCallback(() => {
    return CareerCoachTitles[selectedIndex]
  }, [selectedIndex])

  const renderDescription = useCallback(() => {
    return CareerCoachDescription[selectedIndex]
  }, [selectedIndex])

  const handleLearnMoreClick = () => {
    navigate('/career-coaching')

    trackInternalEvent('click_career_coaching_banner', {
      label: tab?.name,
      website_host: getHost,
    })
  }

  return (
    <Container>
      <CoachImages>
        <CoachList />
      </CoachImages>
      <CoachDetails>
        <CoachTitle>{renderTitle()}</CoachTitle>
        <CoachDescription>{renderDescription()}</CoachDescription>
      </CoachDetails>
      <CoachAction>
        <CoachButton onClick={handleLearnMoreClick}>Book a Coach</CoachButton>
      </CoachAction>
    </Container>
  )
}
