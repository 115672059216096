import {
  ActionButton,
  Actions,
  Header,
  HeaderInfo,
  HeartIcon,
  HeartIconOutline,
  Logo,
} from './styles'

interface Props {
  logo: string
  handleAddCard: (event: React.MouseEvent<HTMLDivElement>) => void
  isPresent: boolean
}

const TopContent = ({ logo, handleAddCard, isPresent }: Props) => {
  return (
    <Header>
      <HeaderInfo>
        <Logo src={logo} />
      </HeaderInfo>
      <Actions>
        <ActionButton onClick={handleAddCard}>
          {isPresent ? <HeartIconOutline /> : <HeartIcon />}
        </ActionButton>
      </Actions>
    </Header>
  )
}

export default TopContent
