import styled from 'styled-components'
import Media from 'builder/styles/media'

export const VisualContainer = styled.div`
  position: absolute;
  width: 268px;
  height: 214px;
  left: calc(50% - 268px / 2);
  top: 0px;

  ${Media.Phone`
    width: 242px;
    height: 212px;
  `}
`

export const VisualImageContainer = styled.div`
  position: absolute;
  width: 250px;
  height: 130.45px;
  left: 20px;
  top: 71.77px;

  ${Media.Phone`
    width: 224px;
    left: 32px;
  `}
`

export const VisualImage = styled.img`
  width: 100%;
  height: 100%;
`
