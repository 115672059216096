import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Button from 'builder/components/Button'
import Sizes from 'builder/styles/sizes'

export const Container = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  background-color: ${Colors.White};
  padding-top: 8px;
  width: fit-content;

  ${Media.Tablet`
    width: 100%;
    margin-top: 30px;
    padding-right: 32px;
  `};

  ${Media.Phone`
    margin-top: 24px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 22px;
  `};
`

export const Title = styled.p`
  ${Typography.L};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
  max-width: 672px;
  margin-top: 12px;
`

export const Links = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Sizes.XS};
  margin-top: 32px;

  ${Media.Phone`
    margin-top: 24px;
  `};
`

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${Sizes.XS};

  &:not(:first-child) {
    padding-top: ${Sizes.XS};
    border-top: 1px solid ${Colors.Neutral15};
  }

  ${Media.Phone`
    flex-direction: column;
  `};
`

export const Item = styled.p`
  ${Typography.Body};
  color: ${Colors.Neutral90};
  margin-right: ${Sizes.M};

  ${Media.Tablet`
    max-width: 343px;
  `};
`

export const ItemBold = styled.span`
  ${FontWeights.Medium};
  margin-left: 3px;
`

export const ItemButton = styled(Button)`
  margin-left: auto;
  padding: ${Sizes['2XS']} ${Sizes.XS};
  width: 210px;

  ${Media.Tablet`
    font-size: 16px;
  `};

  ${Media.Phone`
    width: 100%;
  `};
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: ${Sizes.L};
  margin-top: 48px;
  align-items: center;

  ${Media.Phone`
    flex-direction: column;
    margin-top: 32px;
    gap: 20px;
  `};
`

export const PremiumButton = styled(Button)`
  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${Colors.White};
  background-color: ${Colors.Blue50};
  border-radius: 4px;
  width: 206px;
  padding: 12px 24px;

  &:hover {
    background-color: ${Colors.Blue60};
  }

  ${Media.Phone`
    width: 100%;
  `};
`

export const CancelSubcriptionText = styled.p`
  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${Colors.Neutral50};
  cursor: pointer;

  &:hover {
    color: ${Colors.Blue50};
  }
`

export const Icon = styled.img`
  ${Media.Phone`
    display: none;
  `};
`

export const IconMobile = styled.img`
  display: none;

  ${Media.Phone`
    display: flex;
  `};
`

export const IconMobileContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

export const Header = styled.span`
  color: ${Colors.Neutral50};
  ${Typography.Caps};
`

export const FeedBackContainer = styled.div`
  margin-top: 40px;
  display: flex;
  gap: 64px;

  ${Media.Tablet`
    gap: 16px;
  `};

  ${Media.Phone`
    margin-top: 32px;
  `};
`

export const WrittenFeedback = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Sizes.S};

  ${Media.Phone`
    display: none;
  `};
`

export const FeedbackAuthor = styled.div`
  display: flex;
  gap: ${Sizes.S};
`

export const FeedbackAuthorAvatar = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 100%;
`

export const FeedbackAuthorData = styled.div`
  display: flex;
  flex-direction: column;
`

export const FeedbackAuthorName = styled.span`
  color: ${Colors.Neutral90};
  ${FontWeights.Medium};
  ${Typography.Subhead};
`

export const CareerUser = styled.span`
  color: ${Colors.Neutral50};
  ${Typography.Body};
`

export const Feedback = styled.p`
  color: ${Colors.Neutral50};
  ${Typography.Body};
  max-width: 384px;

  ${Media.Tablet`
    max-width: 302px;
  `};
`

export const FeedBackTextContainer = styled.div`
  display: flex;
  gap: 18px;
`

export const Quotes = styled.span`
  ${Typography.XL};
  ${FontWeights.DemiBold};
  color: ${Colors.Blue50};
`

export const VideoPreview = styled.img`
  height: 256px;
  width: 412px;
  border-radius: ${Sizes.S};
  cursor: pointer;

  ${Media.Tablet`
    width: 352px;
  `};

  ${Media.Phone`
    width: 100%;
    height: 220px;
  `};
`

export const ModalVideoContainer = styled.div`
  display: flex;
  position: fixed;
  z-index: 3000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(15, 20, 30, 0.8);
  justify-content: center;
  align-items: center;
`

export const ModalVideoIframe = styled.iframe.attrs({ frameBorder: 0 })`
  width: 931px;
  height: 524px;

  ${Media.Tablet`
    width: 681px;
    height: 383px;
  `};

  ${Media.Phone`
    width: 100%;
    height: 211px;
  `};
`
