import { useDispatch } from 'react-redux'
import { FC, useState, useEffect, useRef, KeyboardEvent } from 'react'
import { Answer, actions } from 'builder/modules/careerProfile'
import { useI18n } from 'builder/hooks/useI18n'
import Icon24 from 'builder/components/Icon'
import Colors from 'builder/styles/colors'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import {
  ChatInputContainer,
  ChatTextArea,
  ChatInputButton,
  EditingMessage,
  ChatInputWrapper,
  ChatInputButtonWrapper,
} from './styles'

export const ChatInput: FC<{
  disabled?: boolean
  editMessage?: Answer | null
  setEditMessage?: (message: Answer | null) => void
}> = ({ disabled = false, editMessage = null, setEditMessage }) => {
  const [message, setMessage] = useState('')
  const [inputHeight, setInputHeight] = useState('')
  const [isExpanded, setIsExpanded] = useState(false)
  const dispatch = useDispatch()
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const { i18n } = useI18n()
  const { isPhone } = useMediaQueries()
  const { features } = useFeaturesConfig()

  useEffect(() => {
    if (editMessage) {
      setMessage(editMessage.text)
      textAreaRef.current?.focus()
    }
  }, [editMessage])

  useEffect(() => {
    if (!disabled) {
      textAreaRef.current?.focus()
    }
  }, [textAreaRef, disabled])

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = '0px'
      const scrollHeight = textAreaRef.current.scrollHeight
      const stringScrollHeight = `${scrollHeight}px`
      textAreaRef.current.style.height = stringScrollHeight
      setInputHeight(stringScrollHeight)
    }
  }, [textAreaRef, message])

  useEffect(() => {
    if (textAreaRef.current && inputHeight) {
      const { lineHeight } = getComputedStyle(textAreaRef.current)
      setIsExpanded(inputHeight !== lineHeight)
    }
  }, [inputHeight, textAreaRef])

  const handleMessageSubmit = () => {
    if (!message) {
      return
    }
    const text = message.trim()
    if (editMessage && setEditMessage) {
      dispatch(actions.saveAnswer({ id: editMessage.id, text, time: editMessage.time }))
      setEditMessage(null)
    } else {
      dispatch(actions.saveAnswer({ id: String(-1), text, time: Date.now() }))
    }
    setMessage('')
    textAreaRef.current?.focus()
  }

  const handleKeyPressed = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      handleMessageSubmit()
    }
  }

  const handleInputAreaClick = () => {
    textAreaRef.current?.focus()
  }

  const chatButton = (!isPhone || message) && (
    <ChatInputButtonWrapper timeout={0}>
      <ChatInputButton
        disabled={disabled}
        onClick={() => handleMessageSubmit()}
        $isVisible={!message}
      >
        <Icon24.ArrowUp color={Colors.White} />
      </ChatInputButton>
    </ChatInputButtonWrapper>
  )

  return (
    <>
      {editMessage && (
        <EditingMessage>
          <Icon24.Edit color={Colors.Blue50} />
          {editMessage?.text}
        </EditingMessage>
      )}
      <ChatInputWrapper $isDashV2={features.international || features.superApp}>
        <ChatInputContainer
          $isExpanded={isExpanded}
          $isButtonVisible={!!message}
          onClick={handleInputAreaClick}
        >
          <ChatTextArea
            rows={1}
            disabled={disabled}
            value={message}
            placeholder={i18n.t('builder.career_profile.chat.message_placeholder')}
            onChange={ev => setMessage(ev.target.value)}
            onClick={ev => ev.stopPropagation()}
            onKeyDown={handleKeyPressed}
            ref={textAreaRef}
          />
          {!isPhone && chatButton}
        </ChatInputContainer>

        {isPhone && chatButton}
      </ChatInputWrapper>
    </>
  )
}
