import styled, { css, keyframes } from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

const expandIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    padding-bottom: 25px;
  }
`

const expandOut = keyframes`
  0% {
    opacity: 1;
    padding-bottom: 25px; 
  }

  100% {
    opacity: 0;
  }
`

const expandImage = keyframes`
  0% {
    opacity: 0;
    padding-bottom: 0px;
  }

  20%{
    padding-bottom: 2px;
    opacity: 0.2;
  }

  60%{
    padding-bottom: 4px;
    opacity: 0.4;
  }

  80%{
    padding-bottom: 6px;
    opacity: 0.5;
  }
  
  100% {
    opacity: 1;
    padding-bottom: 8px;
  }
`

export const Container = styled.div<{ drag: boolean }>`
  width: 416px;
  height: 368px;
  display: flex;
  position: relative;
  border-radius: 4px;

  justify-content: center;
  align-items: center;
  background-color: ${Colors.Blue10};
  border: 1px solid ${Colors.Blue60};

  ${Media.Tablet`
    width: 504px;
    height: 366px;
  `}

  ${props =>
    props.drag
      ? css`
          animation: ${expandIn} 0.4s ease-in forwards;
        `
      : css`
          animation: ${expandOut} 0.4s ease-out forwards;
        `}

  ${Media.Phone`
    padding-top: 100px;
    width: calc(100% - 40px)
  `};

  @media (max-width: 374px) {
    padding-top: 80px;
  }
`
export const ContainerContent = styled.div`
  width: 100%;
  text-align: center;
`

export const Text = styled.div`
  ${Typography.Subhead};
  ${FontWeights.DemiBold};

  text-align: center;
  margin-bottom: 12px;
  color: ${Colors.Blue50};
`

export const SubText = styled.div`
  ${Typography.Caption};

  margin-bottom: 16px;
  color: ${Colors.Neutral50};

  ${Media.Phone`
    margin-bottom: 134px;
  `};

  @media (max-width: 374px) {
    margin-bottom: 114px;
  }
`

export const Image = styled.img`
  margin-bottom: 16px;
  animation: ${expandImage} 0.3s ease-out forwards;

  ${Media.Phone`
    margin-bottom: 24px;
  `};
`

export const DropzoneInput = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
`
