import { useQuery } from '@tanstack/react-query'
import { baseClient } from 'builder/modules/apiClient'
import { CustomAlertType } from 'builder/modules/jobSearch/types'
import { transformerCustomAlert } from './utils'

export const useGetCustomAlerts = (enabled: boolean) => {
  return useQuery(
    ['useGetCustomAlerts'],
    async (): Promise<CustomAlertType[]> => {
      const res = await baseClient.get('/job-alerts/v1/custom-subscriptions')
      return res.data.map(transformerCustomAlert)
    },
    {
      enabled,
      staleTime: 3000,
      select: alerts => {
        if (Array.isArray(alerts)) {
          return alerts.sort((a, b) => (a.id || 0) - (b.id || 0))
        }
      },
    },
  )
}
