import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as userSelectors } from 'builder/modules/user'
import { useConfig } from 'builder/hooks/useConfig'

export const useIsCareerProfileAvailable = () => {
  const config = useConfig()
  const isUserFetched = useTypedSelector(userSelectors.isFetched)
  const isCareerProfileFeatureAvailable = config?.features.careerProfile
  const isAvailable = isUserFetched && isCareerProfileFeatureAvailable

  return isAvailable
}
