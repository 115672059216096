import { useState, useEffect } from 'react'
import { UserDocumentTemplate } from '@rio/types'
import { typeToSlug } from 'builder/modules/panel'
import { DocumentTypes } from 'builder/modules/constants'
import { apiClient } from 'builder/modules/apiClient'
import * as Styled from './styles'

type Props = {
  type: DocumentTypes
}

export const CreateDocument = ({ type }: Props) => {
  const [thumbnailUrl, setThumbnailUrl] = useState<string | null>(null)

  useEffect(() => {
    let mounted = true

    const namespace = typeToSlug(type)
    const templateId = type === DocumentTypes.coverLetter ? 'boston' : 'stockholm'

    apiClient.get<UserDocumentTemplate[]>(`/${namespace}/templates`).then(({ data: templates }) => {
      const template = templates.find(template => template.id === templateId)
      if (mounted && template) setThumbnailUrl(template.thumbnail)
    })

    return () => {
      mounted = false
    }
  }, [type])

  return (
    <Styled.Container>
      <Styled.ImagePreview src={thumbnailUrl} />
    </Styled.Container>
  )
}
