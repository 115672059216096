import styled from 'styled-components'
import Media from 'builder/styles/media'
import DefaultSpinner from 'builder/components/Spinner'
import { FetchStatuses } from 'builder/modules/constants'

export const Container = styled.div`
  max-width: 1120px;
  padding-top: 48px;
  width: calc(100% - 40px);
  margin: 0 auto;
  display: flex;
  gap: 128px;
  flex-grow: 1;

  ${Media.Tablet`
    flex-direction: column;
  `}
`

export const Iframe = styled.iframe.attrs({ frameBorder: 0 })`
  width: 100%;
  height: 800px;
  border: none;
  margin-left: 90px;

  &[data-status=${FetchStatuses.loading}] {
    opacity: 0;
    pointer-events: none;
  }

  ${Media.Tablet`
    margin-left: 5px;
  `};

  ${Media.Phone`
    margin-left: 5px;
  `};
`

export const Spinner = styled(DefaultSpinner)`
  position: absolute;
  top: calc(50% - 18px);
  left: calc(50% - 18px);
`

export const FixedContainer = styled.div`
  position: sticky;
  top: 113px;
  height: fit-content;
  width: 256px;
`
