import { Resume, Card, ResumeSkill } from 'packages/types'

/**
 * NOTE: this helper is created for Resume Editor Field Tracking business logic,
 * it can not fit your requirements because of specific fields set
 */
export const isCardEmpty = <K extends keyof Resume>(card: Card | null, section: K): boolean => {
  if (!card) return true
  switch (section) {
    case 'skills':
      const resumeSkillCard = card as ResumeSkill
      return !resumeSkillCard.skill
    default:
      return true
  }
}
