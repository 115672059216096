import { all, put, call, takeLatest } from 'redux-saga/effects'
import { FetchStatuses } from 'builder/modules/constants'
import { apiClient } from 'builder/modules/apiClient'
import { actions } from './careerPathModule'

export function* fetchDetailsSaga({ payload }: ReturnType<typeof actions.fetchDetails>) {
  const { occupation, location } = payload

  try {
    yield put(actions.setFetchDetailsStatus(FetchStatuses.loading))

    const params = { title: occupation, location }
    const { data } = yield call(apiClient.get, `/career-pathways/job-summary-wrapper`, { params })

    yield put(actions.setDetails(data))
    yield put(actions.setFetchDetailsStatus(FetchStatuses.loaded))
  } catch (error) {
    yield put(actions.setFetchDetailsStatus(FetchStatuses.failed))
  }
}

export function* fetchOccupationMatrixSaga({
  payload,
}: ReturnType<typeof actions.fetchOccupationMatrix>) {
  const { occupation, location } = payload

  try {
    yield put(actions.setFetchMatrixStatus(FetchStatuses.loading))

    const params = { occupation, location }
    const { data } = yield call(apiClient.get, `/career-pathways/matrix-wrapper`, { params })

    yield put(actions.setOccupationMatrix(data))
    yield put(actions.setFetchMatrixStatus(FetchStatuses.loaded))
  } catch (error) {
    yield put(actions.setFetchMatrixStatus(FetchStatuses.failed))
  }
}

export function* fetchSummarySaga({ payload }: ReturnType<typeof actions.fetchSummary>) {
  const { occupation: title, location, countofapicalls } = payload

  try {
    yield put(actions.setFetchSummaryStatus(FetchStatuses.loading))

    const params = { title, location, countofapicalls }
    const { data } = yield call(apiClient.get, `/career-pathways/job-details-wrapper`, { params })

    yield put(actions.setSummary(data))
    yield put(actions.setFetchSummaryStatus(FetchStatuses.loaded))
  } catch (error) {
    yield put(actions.setFetchSummaryStatus(FetchStatuses.failed))
  }
}

export function* confirmGoalSaga({ payload }: ReturnType<typeof actions.confirmGoal>) {
  const { currentTitle, targetTitle, location } = payload

  try {
    const params = {
      careerGoal: { currentTitle, targetTitle, location },
    }

    const { data } = yield call(apiClient.post, `/career-pathways/goal`, { ...params })
    yield put(actions.setGoal(data))
  } catch (error) {}
}

export function* fetchGoalSaga() {
  try {
    yield put(actions.setFetchGoalStatus(FetchStatuses.loading))

    const { data } = yield call(apiClient.get, `/career-pathways/goal`)

    yield put(actions.setGoal(data))
    yield put(actions.setFetchGoalStatus(FetchStatuses.loaded))
  } catch (error) {
    yield put(actions.setFetchGoalStatus(FetchStatuses.failed))
  }
}

/** Bind side-effect handlers */
export const sagas = function* saga() {
  yield all([
    takeLatest(actions.fetchDetails, fetchDetailsSaga),
    takeLatest(actions.fetchOccupationMatrix, fetchOccupationMatrixSaga),
    takeLatest(actions.fetchSummary, fetchSummarySaga),
    takeLatest(actions.confirmGoal, confirmGoalSaga),
    takeLatest(actions.fetchGoal, fetchGoalSaga),
  ])
}
