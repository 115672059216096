import React from 'react'
import { Radio, RadioProps } from 'builder/components/Radio'
import * as Styles from './styles'

export type ControlWithLabelProps = {
  label: string | React.ReactNode
} & RadioProps

export const ControlWithLabel = ({ label, ...props }: ControlWithLabelProps) => {
  return (
    <Styles.Label disabled={props.disabled}>
      <Radio {...props} />
      {label}
    </Styles.Label>
  )
}
