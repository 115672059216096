import styled from 'styled-components'
import Media from 'builder/styles/media'

export const Container = styled.div`
  width: 100%;
  padding-top: 40px;
  gap: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${Media.Tablet`
    gap: 64px;
  `};

  ${Media.Phone`
    gap: 32px;
  `};
`
