import { memo } from 'react'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useConfig } from 'builder/hooks/useConfig'
import { ResumeValidationStatus } from 'builder/modules/constants'
import ModalOverlay from '../ModalOverlay'
import OptionsViewOld from './OptionsViewOld'
import OptionsViewNew from './OptionsViewNew'
import UploadResume from './UploadResume'

type Props = {
  onClose: () => void
}

export const FillResumeModal = memo((props: Props) => {
  const { onClose } = props
  const { isPhone } = useMediaQueries()
  const { resumeValidationStatus } = useTypedSelector(
    state => state.resumeEditor.prefillResumeSection,
  )

  const config = useConfig()

  const renderView = () => {
    if (config?.features.newStartScreen === 'new_design_new_text') {
      return <OptionsViewNew {...{ onClose }} />
    } else {
      return <OptionsViewOld {...{ onClose }} />
    }
  }

  const resumeUploadingNotStarted = resumeValidationStatus === ResumeValidationStatus.notStarted

  return (
    <ModalOverlay
      onClick={onClose}
      overlayFadeDuration={150}
      contentSlideDuration={150}
      fullScreen={isPhone}
    >
      {resumeUploadingNotStarted ? renderView() : <UploadResume {...{ onClose }} />}
    </ModalOverlay>
  )
})
