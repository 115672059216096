import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import { FontWeights } from 'builder/styles/typography'
import { Icon20 } from 'builder/components/Icon'

interface ContainerProps {
  showContent: boolean
}

export const Container = styled.div<ContainerProps>(({ showContent }) => {
  return css`
    cursor: pointer;
    min-height: 72px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: ${Colors.Neutral5};
    border-radius: 8px;
    padding: 24px;

    ${showContent
      ? ''
      : `
    &:hover {
      background-color: ${Colors.Blue10};

      & p {
        color: ${Colors.Blue50};
      }

      & svg {
        color: ${Colors.Blue60};
      }
    }
  `}
  `
})

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const Title = styled.p`
  ${FontWeights.DemiBold};
  font-size: 19px;
  line-height: 24px;
  color: ${Colors.Blue90};
`

export const ArrowDown = styled(Icon20.ChevronDown)`
  cursor: pointer;
  color: ${Colors.Blue50};
  width: 24px;
  height: 24px;
`

export const ArrowUp = styled(Icon20.ChevronDown)`
  cursor: pointer;
  color: ${Colors.Blue50};
  width: 24px;
  height: 24px;
  transform: rotate(180deg);
`

export const ChildrenContainer = styled.div`
  width: 100%;
  transition: all 0.6s ease-in-out;

  > * {
    &:first-child {
      margin-top: 12px;
    }
  }

  &.visible {
    visibility: visible;
    opacity: 1;
    max-height: 1000px;
  }

  &.hidden {
    visibility: hidden;
    opacity: 0;
    max-height: 0;
  }
`
