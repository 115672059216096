import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography from 'builder/styles/typography'

export const ListContainer = styled.div`
  margin-top: 29px;
`

export const ListItem = styled.div`
  display: flex;
  flex-direction: column;
`

export const ListItemContent = styled.div`
  display: flex;
`

export const ListItemTitle = styled.div`
  ${Typography.Body}
  color: ${Colors.Neutral50};
  max-width: 320px;
`

export const ListImage = styled.img`
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  margin-right: 16px;

  ${Media.Phone`
    width: 40px;
    height: 40px;
    margin-right: 12px;
  `}
`
