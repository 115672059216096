import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { selectors as userSelectors } from 'builder/modules/user'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { FetchStatuses } from 'builder/modules/constants'
import {
  Distribution,
  SubscriptionStatus,
  selectors,
  actions,
} from 'builder/modules/resumeDistribution'

import { goToBillingPlanPage } from 'builder/utils/goToBillingPlanPage'
import { ActionBanner } from './ActionBanner/ActionBanner'
import { RecruitersInfo } from './RecruitersInfo/RecruitersInfo'
import { NextSchedule } from './NextSchedule/NextSchedule'
import { InfoSection } from './InfoSection/InfoSection'

import { ContactSupport, ContactSupportContainer, Container, VerticalLine } from './styles'
import { SECTION_INFO_KEY, TRANSLATION } from './constants'

export interface DashboardBannerProps {
  totalRecruiters: number
  nextDistribution: Distribution
}

const DashboardBanner = ({ totalRecruiters, nextDistribution }: DashboardBannerProps) => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const { isPhone } = useMediaQueries()
  const [isInfoSectionHiden, setInfoSection] = useState(!!localStorage.getItem(SECTION_INFO_KEY))

  const candidate = useTypedSelector(selectors.candidate)
  const subscriptionPostPauseStatus = useTypedSelector(selectors.subscriptionPostPauseStatus)
  const subscriptionPostResumeStatus = useTypedSelector(selectors.subscriptionPostResumeStatus)
  const subscriptionPostCreateStatus = useTypedSelector(selectors.subscriptionPostCreateStatus)

  const userAccountData = useTypedSelector(userSelectors.userData)
  const upgradeUrl = useTypedSelector(userSelectors.getUpgradeUrl)

  const upgradeNow = () => {
    goToBillingPlanPage({ upgradeURL: upgradeUrl })
  }

  const isActionLoading = () => {
    return (
      subscriptionPostResumeStatus === FetchStatuses.loading ||
      subscriptionPostPauseStatus === FetchStatuses.loading ||
      subscriptionPostCreateStatus === FetchStatuses.loading
    )
  }

  const refetchData = useCallback(() => {
    if (subscriptionPostCreateStatus === FetchStatuses.loaded) {
      dispatch(actions.fetchCandidate())
      dispatch(actions.fetchDistributionDates())
    }
  }, [dispatch, subscriptionPostCreateStatus])

  const pauseOrResume = () => {
    if (candidate?.distributionStatus === SubscriptionStatus.active) {
      dispatch(actions.postSubscriptionPause())
    } else if (candidate?.distributionStatus === SubscriptionStatus.paused) {
      localStorage.removeItem(SECTION_INFO_KEY)
      setInfoSection(false)
      dispatch(actions.postSubscriptionResume())
    } else if (candidate?.distributionStatus === SubscriptionStatus.cancelled) {
      dispatch(actions.postSubscriptionCreate())
    }
  }

  const hasActionError = () => {
    return (
      subscriptionPostResumeStatus === FetchStatuses.failed ||
      subscriptionPostPauseStatus === FetchStatuses.failed ||
      subscriptionPostCreateStatus === FetchStatuses.failed
    )
  }

  useEffect(() => {
    refetchData()
  }, [refetchData, subscriptionPostCreateStatus])

  return (
    <>
      <InfoSection isHidden={isInfoSectionHiden} setIsHidden={setInfoSection} />
      <Container $hasError={hasActionError()}>
        <RecruitersInfo
          totalRecruiters={totalRecruiters}
          distributionWeek={Math.min(...[candidate?.distributionWeek || 0, 4])}
        />

        {!isPhone && <VerticalLine />}

        <NextSchedule
          hideSchedule={!userAccountData?.hasPremiumFeatures}
          candidate={candidate}
          nextDistribution={nextDistribution}
          isLoading={isActionLoading()}
          action={pauseOrResume}
        />

        <ActionBanner
          upgrade={upgradeNow}
          isLoading={subscriptionPostCreateStatus === FetchStatuses.loading}
        />
      </Container>

      {hasActionError() && (
        <ContactSupportContainer>
          {i18n.t(`${TRANSLATION}.error_message`)}{' '}
          <ContactSupport href="/contact" target="_blank">
            {i18n.t(`${TRANSLATION}.contact_support`)}
          </ContactSupport>
        </ContactSupportContainer>
      )}
    </>
  )
}

export default DashboardBanner
