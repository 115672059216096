import { Fragment } from 'react'
import PlanNewSession from '../PlanNewSession'
import { TopSection, HeaderWrapper, Text, SubText, SubTextRow } from './style'

interface Props {
  heading: string
  description: string[]
}

const Header = ({ heading, description }: Props) => {
  return (
    <Fragment>
      <TopSection>
        <HeaderWrapper>
          <Text>{heading}</Text>
          <SubText>
            {description.map((text, index) => (
              <SubTextRow key={index}>{text}</SubTextRow>
            ))}
          </SubText>
        </HeaderWrapper>
        <PlanNewSession />
      </TopSection>
    </Fragment>
  )
}

export default Header
