import styled from 'styled-components'
import Media from 'builder/styles/media'

export const Wrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;

  ${Media.Tablet`
    padding: 0 20px;
  `}
`
