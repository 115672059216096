import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { TransitionStatus } from 'react-transition-group'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'

export type DocumentNameIconWrapperProps = {
  isEditIconVisible: boolean
}

export const DocumentNameContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  padding-right: 26px;
`

export const DocumentNameTitleWrapper = styled.div`
  position: relative;
  max-width: 100%;
`

export const DocumentNameTitle = styled.div`
  ${Typography.Subhead};
  height: 28px;
  min-width: 1px;
  max-width: 100%;
  position: relative;
`

const DocumentNameLinkStyle = css`
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;

  &:hover {
    color: ${Colors.Blue50};
  }
`

export const DocumentNameInternalLink = styled(Link)`
  ${DocumentNameLinkStyle};
`

export const DocumentNameExternalLink = styled.a`
  ${DocumentNameLinkStyle};
`

export const DocumentNameInputWrapper = styled.div`
  position: relative;
  max-width: 100%;
`

export const DocumentNameInput = styled.input`
  ${Typography.Subhead};
  display: block;
  position: absolute;
  border: none;
  padding: 0;
  caret-color: ${Colors.Blue50};
  top: 0;
  left: 0;
  right: -3px;
  bottom: 0;
  width: 100%;

  &::placeholder {
    color: ${Colors.Neutral50};
  }
`

export const DocumentNameBar = styled.div<{ in: TransitionStatus }>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: ${Colors.Blue50};
  pointer-events: none;
  z-index: 1;
  opacity: 0;
  transform: scale(0.5, 0);
  transition: transform 0.1s ease;

  ${props =>
    ({
      entering: 'opacity: 1; transform: scale(1, 1);',
      entered: 'opacity: 1; transform: scale(1, 1);',
      exiting: 'opacity: 0; transform: scale(1, 1); transition: opacity 0.1s ease;',
      unmounted: null,
      exited: null,
    }[props.in])};
`

export const DocumentNameHiddenTitle = styled.div`
  ${Typography.Subhead};
  min-width: 74px;
  height: 28px;
  white-space: pre;
  visibility: hidden;
`

export const DocumentNameIconWrapper = styled.div<DocumentNameIconWrapperProps>(
  ({ isEditIconVisible }) => {
    return css`
      position: absolute;
      top: 2px;
      left: calc(100% + 2px);
      align-items: center;
      justify-content: center;
      display: none;

      ${DocumentNameContainer}:hover & {
        display: flex;

        ${Media.Phone`
          display: none;
        `};
      }

      ${isEditIconVisible &&
      css`
        display: flex;

        ${Media.Phone`
          display: none;
      `};
      `}
    `
  },
)

export const DocumentNameIcon = styled.div`
  cursor: pointer;
  color: ${Colors.Neutral40};
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: ${Colors.Blue50};
  }
`
