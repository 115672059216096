import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { Icon24 } from 'builder/components/Icon'
import Media from 'builder/styles/media'

interface UpsellFeatureProps {
  iconUrl: string
}

export const Overlay = styled.div`
  position: fixed;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  top: 0;
  left: 0;
  background: #000000ba;
  z-index: 9999;
  height: 100vh;
  width: 100vw;
`

export const RootModalContainer = styled.div`
  font-family: 'TT Commons', system-ui, -apple-system, 'Segoe UI', Roboto, Ubuntu, Cantarell,
    'Noto Sans', sans-serif;
  background-color: ${Colors.White};
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 30em;
  max-height: 100%;

  ${Media.Phone`
    flex-direction: column-reverse;
    max-width: 100%;
    max-height: 100%;
  `}

  ${Media.Tablet`
    flex-direction: column-reverse;
    max-width: 80%;
    max-width: 30em;
  `}
`

export const ModalSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  gap: 1.5em;
  padding: 2em;

  ${Media.Tablet`
    width: 100%;
    font-size: 21px;
  `}

  ${Media.Phone`
    width: 100%;
    padding: 24px;
    margin-top: 10px;
    font-size: 18px;
  `}
`

export const CloseIcon = styled(Icon24.Close)`
  display: block;
  color: ${Colors.Neutral50};
  transition: color 0.3s;
  &:hover {
    color: ${Colors.Blue50};
  }
`

export const Close = styled.div`
  display: flex;
  justify-self: flex-end;
  right: 0;
  height: fit-content;
  width: fit-content;
  margin: 1.5em 1.5em 0 1.5em;
  background: ${Colors.White};
  border-radius: 32px;
  color: ${Colors.Neutral30};
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${Colors.Blue10};
  }
  ${Media.Phone`
    margin: 20px;
  `}
  ${Media.Tablet`
    margin: 30px 20px;
  `}
`

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

export const UpsellHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1em;

  ${Media.Phone`
    gap: 8px;
  `}
`

export const UpsellTitle = styled.h2`
  font-size: 1.5em;
  font-weight: 600;
  color: ${Colors.Neutral90};
  line-height: 1em;
  width: 80%;

  /* ${Media.Phone`
    font-size: 23px;
    line-height: 24px;
  `} */
`

export const UpsellFeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--neutral-5);
  border-radius: var(--2XS, 8px);
  padding: 1em 0;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const UpsellFeatures = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1em;
  list-style: none;
  margin: 0;
  padding: 0.5em 1.5em;
`

export const UpsellFeature = styled.div<UpsellFeatureProps>`
  color: ${Colors.Neutral80};
  font-style: normal;
  font-weight: 400;
  height: 2em;
  font-size: 0.8em;
  line-height: 1.5em;

  &:before {
    content: '';
    display: inline-block;
    float: left;
    right: 4px;
    top: 5px;
    background-image: url(${props => props.iconUrl});
    width: 1.3em;
    height: 1.6em;
    margin-right: 0.6em;
    background-size: contain;
    background-repeat: no-repeat;
  }
`
