import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const MainContent = styled.div`
  max-width: 631px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const LocationContainer = styled.div`
  gap: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const TopContent = styled.div`
  gap: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Details = styled.div`
  gap: 20px;
  display: flex;
  justify-content: space-between;

  ${Media.Phone`
    gap: 12px;
    display: flex;
    flex-direction: column;
  `}
`

export const Avatar = styled.img`
  width: 96px;
  height: 96px;
  border-radius: 50%;
  object-fit: cover;

  ${Media.Phone`
    width: 80px;
    height: 80px;  
  `}
`

export const Info = styled.p`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: flex-start;
  gap: 8px;

  ${Media.Phone`
    gap: 4px;
  `}
`

export const Name = styled.p`
  ${Typography.Subhead};
  ${FontWeights.Medium};
`

export const LocationDetails = styled.div`
  gap: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const Location = styled.p`
  width: 100%;
  ${Typography.Tiny};
`
