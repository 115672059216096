import { Job } from 'builder/modules/jobTracking'
import MAGIC_HAND_ICON_URL from './assets/magic-hand.svg'
import DOUBLE_FACES_ICON_URL from './assets/double-faces.svg'

export const existingDataList = [
  {
    iconUrl: MAGIC_HAND_ICON_URL,
    title: 'AI created mock interview with questions you will face in a real job interview',
  },
  {
    iconUrl: DOUBLE_FACES_ICON_URL,
    title: 'We analyze your answers and give instant feedback how to improve yourself',
  },
] as Array<{ title: string; iconUrl: string }>

export type InterviewMockProps = {
  visible: boolean
  selectedJob: Partial<Job> | null
  onClose: () => void
  trackingLabel: 'job_search' | 'job_tracker'
}
