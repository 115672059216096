import { Editor } from '@tiptap/react'
import { Optional } from 'packages/types'
import { RefObject } from 'react'
import { getPhraseContent, useSuggestionRenders } from 'builder/components/RichTextArea'
import { useConfig } from 'builder/hooks/useConfig'
import { SuggestionConfig } from 'builder/components/RichTextArea/types'
import { appendContent, replaceCurrentContent } from '../utils'
import { Container } from './styles'

type Props = {
  editor: Editor
  suggestionConfig?: Optional<SuggestionConfig>
  isAiPhrases: boolean
  isAiProfileSummary: boolean
  locale: string
  editorContainerRef: RefObject<HTMLElement>
}

export const BottomToolbar = (props: Props) => {
  const { editor, isAiPhrases, isAiProfileSummary, suggestionConfig, locale, editorContainerRef } =
    props
  const config = useConfig()

  const isAiProfileAvailable = config?.features.aiProfileSummary || false
  const isAiAction = isAiPhrases || (isAiProfileAvailable && isAiProfileSummary)
  const shouldUseAiProfileSuggestions = !isAiPhrases && isAiProfileAvailable && isAiProfileSummary

  const addPhrase = (item: { phrase: string; isSubphrase: boolean }) => {
    const phraseContent = getPhraseContent(item, suggestionConfig?.scope)
    const contentToAdd = 'text' in phraseContent ? phraseContent.text : phraseContent.html
    appendContent(editor, contentToAdd)
  }

  const replacePhrase = (phrase: string) => {
    replaceCurrentContent(editor, phrase)
  }

  const { renderAction } = useSuggestionRenders({
    locale,
    shouldUseAiProfileSuggestions,
    suggestionConfig,
    addPhrase,
    replacePhrase,
    isAiAction,
    editorContainerRef,
    getEditorText: () => editor.getText(),
  })

  const toolbarItems: JSX.Element | null = renderAction()

  return toolbarItems ? <Container>{toolbarItems}</Container> : null
}
