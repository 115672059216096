import { useAutoTailoringModal } from '../../hooks/useAutoTailoringModal'
import { Container, LoadingContainer, DotsContainer, Dot } from './styles'

export const Score = () => {
  const { isLoading, score } = useAutoTailoringModal()

  return isLoading ? (
    <LoadingContainer>
      <DotsContainer>
        <Dot />
        <Dot />
        <Dot />
      </DotsContainer>
    </LoadingContainer>
  ) : (
    <Container score={score}>{`${score}%`}</Container>
  )
}
