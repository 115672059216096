import { memo } from 'react'
import SpecialityChip from '../SpecialityChip'
import { Container, Title } from './styles'

interface Props {
  coachIndustries: Array<string>
}

const CoachIndustries = ({ coachIndustries }: Props) => {
  return (
    <Container>
      <Title>Industries</Title>
      <SpecialityChip specialities={coachIndustries} />
    </Container>
  )
}

export default memo(CoachIndustries)
