import * as React from 'react'
import Colors from 'builder/styles/colors'
import { ColumnName } from 'builder/modules/jobTracking'
import { Icon24 } from 'builder/components/Icon'
import InterviewIcon from './icons/interview.svg'
import OfferIcon from './icons/Offer.svg'
import FeaturedIcon from './icons/Featured.svg'

interface Props extends React.SVGProps<SVGSVGElement> {
  status: string
}

export const JobTrackingStatusIcon = ({ status, ...rest }: Props) => {
  switch (status) {
    case ColumnName.recommended:
      return <img src={FeaturedIcon} />
    case ColumnName.wishlist:
      return <Icon24.Heart {...rest} style={{ color: Colors.Neutral100 }} />
    case ColumnName.auto_apply:
      return <Icon24.FeaturedIcon {...rest} />
    case ColumnName.applied:
      return <Icon24.Job {...rest} />
    case ColumnName.interview:
      return <img src={InterviewIcon} />
    case ColumnName.offer:
      return <img src={OfferIcon} />
    case ColumnName.rejected:
      return <Icon24.Close {...rest} style={{ color: Colors.Neutral60 }} />
    default:
      return null
  }
}
