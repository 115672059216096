import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import { actions, CareerPathStep, selectors } from 'builder/modules/careerPath2'

import { CareerPathSkills } from 'builder/components/CareerPath2Skills'
import { CareerPathSuccess } from 'builder/components/CareerPathSucess'
import CareerPathJobDetails from 'builder/components/CareerPathJobDetails'
import { CareerPathViewCard } from 'builder/components/CareerPathViewCard'
import CareerPathJobProgression from 'builder/components/CareerPathJobProgression'
import { CareerPath2Questionnaire } from 'builder/components/CareerPath2Questionnaire'

import { Wrapper } from './styles'

export const CareerPathView2 = () => {
  const dispatch = useDispatch()
  const step = useTypedSelector(selectors.step)
  const careerCards = useTypedSelector(selectors.allCareerCards)

  useEffectOnMount(() => {
    dispatch(actions.setIsExploreCareers(false))

    if (careerCards.length) {
      dispatch(actions.setStep(CareerPathStep.VIEW_CARDS))
    } else {
      dispatch(actions.setStep(CareerPathStep.QUESTIONNAIRE))
    }
  })

  if (step === CareerPathStep.SKILLS) {
    return <CareerPathSkills />
  }

  if (step === CareerPathStep.JOB_PROGRESSION) {
    return (
      /* This additional wrapper is necessary to prevent horizontal scrollbar */
      <Wrapper>
        <CareerPathJobProgression />
      </Wrapper>
    )
  }

  if (
    step === CareerPathStep.ADD_TO_PATH ||
    step === CareerPathStep.CONFIRM_MY_PATH ||
    step === CareerPathStep.CHANGE_MY_PATH
  ) {
    return <CareerPathJobDetails />
  }

  if (step === CareerPathStep.SUCCESS) {
    return <CareerPathSuccess />
  }

  if (step === CareerPathStep.VIEW_CARDS) {
    return <CareerPathViewCard />
  }

  return <CareerPath2Questionnaire />
}
