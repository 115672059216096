import { memo } from 'react'
import { i18n as I18n } from 'builder/utils/i18n'
import { CardContainer } from '../styles'
import ExploreCareersImg from '../assets/explore_careers.svg'
import FreeResumeCritiqueImg from '../assets/free_resume_critique.svg'
import InterviewPrepImg from '../assets/interview_prep.svg'
import FeatureCard from './FeatureCard'

const CardLayout = () => {
  const featureDataSet = [
    {
      featureTitle: I18n.t(
        'builder.subscription_cancellation.offered_feature_dialog.feature_title_1',
      ),
      featureDesc: I18n.t(
        'builder.subscription_cancellation.offered_feature_dialog.feature_desc_1',
      ),
      featureIcon: FreeResumeCritiqueImg,
      featureLink: '/',
    },
    {
      featureTitle: I18n.t(
        'builder.subscription_cancellation.offered_feature_dialog.feature_title_2',
      ),
      featureDesc: I18n.t(
        'builder.subscription_cancellation.offered_feature_dialog.feature_desc_2',
      ),
      featureIcon: ExploreCareersImg,
      featureLink: '/app/explore-careers',
    },
    {
      featureTitle: I18n.t(
        'builder.subscription_cancellation.offered_feature_dialog.feature_title_3',
      ),
      featureDesc: I18n.t(
        'builder.subscription_cancellation.offered_feature_dialog.feature_desc_3',
      ),
      featureIcon: InterviewPrepImg,
      featureLink: '/app/interview-preparation/dashboard',
    },
  ]

  return (
    <CardContainer>
      {featureDataSet.map((feature, index) => (
        <FeatureCard key={index} featureItem={feature} />
      ))}
    </CardContainer>
  )
}

export default memo(CardLayout)
