// import { Badge } from 'builder/components/Badge'
import { Icon20 } from 'builder/components/Icon'
import { useI18n } from 'builder/hooks/useI18n'
import { QuestionTemplate } from 'builder/modules/interview/types'
import { Badge } from '../../Badge/Badge'
import { Container, Duration, Header, Question, StatusDoneIcon } from './style'

interface Props {
  type: 'situational' | 'question'
  question: QuestionTemplate
  durationTime?: number
  onClick?: () => void
}
const TRANSLATION_KEY = 'builder.interview_prep_view.question_library'

export const QuestionCard = ({ question, durationTime = 5, type, onClick }: Props) => {
  const { i18n } = useI18n()
  switch (type) {
    case 'situational':
      return (
        <Container onClick={onClick} data-testid={question.id}>
          <Header>
            <Badge $question={false} $status="initiated">
              {i18n.t(`${TRANSLATION_KEY}.situational`)}
            </Badge>
            {question.status === 'completed' && <Icon20.StatusDone />}
          </Header>
          <Question>{question.content}</Question>
        </Container>
      )

    case 'question':
      return (
        <Container onClick={onClick} data-testid={question.id}>
          <Header>
            <Duration>
              <Icon20.Time />
              {durationTime} {i18n.t(`${TRANSLATION_KEY}.minute_abbreviation`)}
            </Duration>

            <div>
              <Badge $question={true} $status="initiated">
                {i18n.t(`${TRANSLATION_KEY}.question`)}
              </Badge>
              {question.status === 'completed' && <StatusDoneIcon />}
            </div>
          </Header>
          <Question>{question.content}</Question>
        </Container>
      )
    default:
      return null
  }
}
