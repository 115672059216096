import Icon24 from 'builder/components/Icon'
import { QuestionStatus } from '../types'
import { Container, SelectedContainer, SvgContainer, Text, UnselectedContainer } from './styles'

interface Props {
  question: string
  index: number
  status: QuestionStatus
}

export const QuestionListItem = ({ question, index, status }: Props) => {
  function getIcon() {
    if (status === 'unselected') {
      return <UnselectedContainer>{index}</UnselectedContainer>
    }

    if (status === 'completed') {
      return (
        <SvgContainer>
          <Icon24.Tick />
        </SvgContainer>
      )
    }

    return <SelectedContainer>{index}</SelectedContainer>
  }
  return (
    <Container>
      {getIcon()}
      <Text $unselected={status === 'unselected'}>{question}</Text>
    </Container>
  )
}
