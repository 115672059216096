import styled from 'styled-components'
import { Media } from 'builder/styles/media'
import { Colors } from 'builder/styles/colors'

export const PanelContainer = styled.div`
  overflow: hidden;
  padding-top: 32px;
  padding-bottom: 200px;

  ${Media.Tablet`
    padding-bottom: 80px;
    padding-top: 20px;
  `};

  ${Media.Phone`
    min-height: calc(100vh - 64px);
    padding-top: 20px;
    padding-bottom: 64px;
  `};
`

export const Section = styled.section<{
  margin?: 'mid' | 'big'
  selectedIndex?: number
  isPhone?: boolean
  marginTop?: number
}>`
  margin-bottom: ${props => (props.margin === 'big' ? 120 : 48)}px;
  margin-top: ${props => (props.marginTop ? props.marginTop : 0)}px;

  ${Media.Tablet`
    margin-bottom: 24px;
  `}

  ${Media.Phone`
    margin-bottom: 24px;
  `}

  &:last-child {
    margin-bottom: ${props =>
      (props.selectedIndex === 1 || props.selectedIndex === 2) && props.isPhone ? 80 : 0}px;
  }
`

export const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
  ${Media.Tablet`
    padding: 0 20px;
  `}
`

export const BannerContainer = styled.div`
  overflow: hidden;
  padding-top: 0px;
  background: ${Colors.Neutral5};
`
