import { useEffect, useState } from 'react'
import type { ResumeExample, ResumeExampleCategory } from '@rio/types'
import { apiClient } from 'builder/modules/apiClient'

const getExamplesFromCategories = (categories: ResumeExampleCategory[]) => {
  let examples: ResumeExample[] = []

  for (let i = 0; i < categories.length; i++) {
    examples = examples.concat(categories[i].examples)
  }

  return examples
}

type Catalog = [ResumeExampleCategory[], ResumeExample[]]

export const useCatalog = (): Catalog => {
  const [catalog, setCatalog] = useState<Catalog>([[], []])

  useEffect(() => {
    apiClient
      .get<{ categories: ResumeExampleCategory[] }>(`/examples/categories`)
      .then(({ data: { categories } }) => {
        setCatalog([categories, getExamplesFromCategories(categories)])
      })
  }, [])

  return catalog
}
