import { useContext, useEffect, useRef } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { useEscapeHandler } from 'builder/hooks/useEscapeHandler'
import Button, { ButtonSize, ButtonTheme } from 'builder/components/Button'
import { TunerEditorContext } from '../../contexts/TunerEditorContext'
import { CheckIssue } from '../../types'
import {
  TooltipTrack,
  TooltipContainer,
  TooltipBullets,
  TooltipBullet,
  TooltipTitle,
  TooltipText,
  TooltipProgress,
  TooltipProgressBar,
  TooltipStatus,
  TooltipStatusLabel,
  TooltipStatusCounter,
  TooltipStatusIcon,
  TooltipActions,
  TooltipClose,
  TooltipCloseIcon,
} from './styles'

export const TunerTooltip = ({ issue }: { issue: CheckIssue }) => {
  const { navigationIssueIds, nextIssueId, activateIssue, showStatus, cancel } =
    useContext(TunerEditorContext)
  const isComplete = useRef(false)
  const { i18n } = useI18n()

  // Track tooltip opening (and rendering another one instead as well)
  useEffect(() => {
    trackInternalEvent('see_tuner_issue', { issue: issue.id, task: issue.taskId })
    isComplete.current = false
  }, [issue.id, issue.taskId])

  // Record issue completion
  useEffect(() => {
    if (issue.progress === 1 && isComplete.current === false) {
      trackInternalEvent('complete_tuner_issue', { issue: issue.id, task: issue.taskId })
      isComplete.current = true
    }
  }, [issue.progress, issue.id, issue.taskId])

  // Close tooltip and record it via ClickHouse
  const handleClose = () => {
    trackInternalEvent('close_tuner_issue', { issue: issue.id, task: issue.taskId })
    cancel()
  }

  // Close tooltip via "Esc" key
  useEscapeHandler(handleClose)

  // Handle "Fix My Summary", "Add email" and similar buttons
  const handleActionClick = () => {
    trackInternalEvent('click_tuner_issue_action_button', { issue: issue.id, task: issue.taskId })
    issue.action()
  }

  // Handle "Skip", "Go To Next Step" and "Continue" buttons
  const handleContinueClick = () => {
    const event = `click_tuner_issue_${issue.progress < 1 ? 'skip' : 'continue'}_button` as const
    trackInternalEvent(event, { issue: issue.id, task: issue.taskId })

    nextIssueId ? activateIssue(nextIssueId) : showStatus()
  }

  return (
    <TooltipTrack>
      <TooltipContainer>
        {navigationIssueIds.length > 1 && (
          <TooltipBullets>
            {navigationIssueIds.map(id => (
              <TooltipBullet key={id} isActive={id === issue.id} />
            ))}
          </TooltipBullets>
        )}

        <TooltipTitle>{issue.title}</TooltipTitle>
        <TooltipText>{issue.description}</TooltipText>

        <TooltipProgress>
          <TooltipProgressBar style={{ transform: `scaleX(${issue.progress})` }} />
        </TooltipProgress>
        <TooltipStatus>
          <TooltipStatusLabel>
            {issue.progress === 1
              ? i18n.t('builder.resume_tuner.tips.common.step_complete')
              : issue.subject}
          </TooltipStatusLabel>
          <TooltipStatusCounter>{issue.counter || `${issue.progress}/1`}</TooltipStatusCounter>
          <TooltipStatusIcon data-done={issue.progress === 1} />
        </TooltipStatus>

        <TooltipActions>
          {issue.progress < 1 && (
            <Button size={ButtonSize.small} onClick={handleActionClick}>
              {issue.actionText}
            </Button>
          )}
          {issue.progress < 1 && (
            <Button size={ButtonSize.small} theme={ButtonTheme.ghost} onClick={handleContinueClick}>
              {i18n.t('builder.resume_tuner.tips.common.skip')}
            </Button>
          )}
          {issue.progress === 1 && (
            <Button size={ButtonSize.small} onClick={handleContinueClick}>
              {nextIssueId
                ? i18n.t('builder.resume_tuner.tips.common.go_to_next_step')
                : i18n.t('builder.resume_tuner.tips.common.continue')}
            </Button>
          )}
        </TooltipActions>

        <TooltipClose onClick={handleClose}>
          <TooltipCloseIcon />
        </TooltipClose>
      </TooltipContainer>
    </TooltipTrack>
  )
}
