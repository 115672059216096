import { memo, useState } from 'react'

import { useI18n } from 'builder/hooks/useI18n'
import { Popover } from 'builder/components/Popover/Popover'

import { TooltipWrapper } from './styles'

export const TooltipPopoverWrapper = memo(
  ({
    open,
    popover,
    children,
    isPostPremium,
  }: {
    open?: boolean
    isPostPremium?: boolean
    popover?: React.ReactElement
    children: React.ReactNode
  }) => {
    const { i18n } = useI18n()
    const [openPopover, setOpenPopover] = useState(false)

    if (popover) {
      return (
        <Popover
          visible={openPopover}
          component={popover}
          placement="right"
          containerStyles={{ margin: '20px 0 16px' }}
        >
          <div
            style={{ position: 'relative' }}
            onMouseEnter={() => setOpenPopover(true)}
            onMouseLeave={() => setOpenPopover(false)}
          >
            {children}
          </div>
        </Popover>
      )
    }

    if (isPostPremium) {
      return (
        <TooltipWrapper
          $isOpen={open}
          position="right"
          value={i18n.t('builder.side_menu.post_premium_tooltip')}
          multiline
        >
          {children}
        </TooltipWrapper>
      )
    }
    return <>{children}</>
  },
)
