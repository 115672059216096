import { Company, Content, Title } from './styles'

interface Props {
  company: string
  title: string
}
const Body = ({ company, title }: Props) => {
  return (
    <Content>
      <Company>{company}</Company>
      <Title>{title}</Title>
    </Content>
  )
}

export default Body
