import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { i18n as I18n } from 'builder/utils/i18n'
import Icon, { Icon20 } from 'builder/components/Icon'
import PhotoIcon from './PhotoIcon'

import {
  Container,
  Wrapper,
  Caption,
  PhotoPlaceholder,
  Photo,
  PhotoOverlay,
  Lock,
  Spinner,
  ButtonBlock,
  UploadButton,
  EditButton,
  DeleteButton,
  IconBlock,
  FileInput,
} from './styles'

class PhotoUploadField extends PureComponent {
  static propTypes = {
    imageUrl: PropTypes.string,
    onUpload: PropTypes.func,
    onDelete: PropTypes.func,
    onClick: PropTypes.func,
    isLocked: PropTypes.bool,
    isUploading: PropTypes.bool,
  }

  handleUpload = () => {
    this.fileInput.click()
  }

  handleChooseFile = e => {
    if (e.target.files[0]) {
      this.props.onUpload({ file: e.target.files[0] })
    }
  }

  handleDelete = () => {
    this.props.onDelete()
  }

  render() {
    return (
      <Container>
        <FileInput
          type="file"
          accept="image/x-png,image/gif,image/jpeg, .jpg, .jpeg, .gif, .png, .tiff"
          ref={ref => {
            this.fileInput = ref
          }}
          onChange={this.handleChooseFile}
        />
        {this.renderBody()}
      </Container>
    )
  }

  renderBody() {
    const { imageUrl, isLocked, isUploading } = this.props

    if (isUploading) {
      return this.renderUploading()
    }

    if (isLocked) {
      return this.renderLock()
    }

    return imageUrl ? this.renderEdit() : this.renderUpload()
  }

  renderUpload() {
    const { onClick } = this.props
    return (
      <Wrapper onClick={onClick || this.handleUpload}>
        <PhotoPlaceholder>
          <PhotoIcon />
        </PhotoPlaceholder>

        <ButtonBlock>
          <UploadButton>{I18n.t('builder.resume_editor.upload_photo')}</UploadButton>
        </ButtonBlock>
      </Wrapper>
    )
  }

  renderUploading() {
    const { imageUrl } = this.props

    return (
      <Wrapper>
        <Photo imageUrl={imageUrl}>
          <PhotoOverlay enable={!!imageUrl}>
            <Spinner dark={!!imageUrl}>
              <Icon.Spinner />
            </Spinner>
          </PhotoOverlay>
        </Photo>

        <ButtonBlock>
          <Caption>{I18n.t('builder.resume_editor.uploading_image')}</Caption>
        </ButtonBlock>
      </Wrapper>
    )
  }

  renderLock() {
    const { imageUrl } = this.props

    return (
      <Wrapper>
        <Photo imageUrl={imageUrl}>
          <PhotoOverlay enable={!!imageUrl}>
            <Lock dark={!!imageUrl}>
              <Icon.Lock />
            </Lock>
          </PhotoOverlay>
        </Photo>

        <ButtonBlock>
          <Caption>{I18n.t('builder.resume_editor.photo_not_supported')}</Caption>
        </ButtonBlock>
      </Wrapper>
    )
  }

  renderEdit() {
    const { imageUrl, onClick } = this.props

    return (
      <Wrapper>
        <Photo imageUrl={imageUrl} />

        <ButtonBlock>
          <EditButton onClick={onClick || this.handleUpload}>
            <IconBlock>
              <Icon20.Edit />
            </IconBlock>
            {I18n.t('builder.resume_editor.edit_photo')}
          </EditButton>

          <DeleteButton onClick={this.handleDelete}>
            <IconBlock>
              <Icon20.Trash />
            </IconBlock>
            {I18n.t('builder.resume_editor.delete')}
          </DeleteButton>
        </ButtonBlock>
      </Wrapper>
    )
  }
}

export default PhotoUploadField
