import SwiperCore from 'swiper'

export const handleOnInit = () => {
  const prevBtn = document.querySelector('.job-prev-button') as HTMLElement

  prevBtn.style.display = 'none'
}

export const handleOnSlideChange = (swiper: SwiperCore) => {
  const prevBtn = document.querySelector('.job-prev-button') as HTMLElement
  const nextBtn = document.querySelector('.job-next-button') as HTMLElement

  nextBtn.style.display = swiper.isEnd ? 'none' : 'block'
  prevBtn.style.display = swiper.isBeginning ? 'none' : 'block'
}

export const handleOnReachBeginning = () => {
  const prevBtn = document.querySelector('.job-prev-button') as HTMLElement

  prevBtn.style.display = 'none'
}

export const handleOnReachEnd = () => {
  const nextBtn = document.querySelector('.job-next-button') as HTMLElement

  nextBtn.style.display = 'none'
}
