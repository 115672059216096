import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Shadows from 'builder/styles/shadows'
import Media from 'builder/styles/media'

interface Props {
  isCardClicked: boolean
}

export const CardContainer = styled.div<Props>(({ isCardClicked }) => {
  return css`
    display: flex;
    height: 160px;
    ${Shadows.light.low};
    border-radius: 16px;

    & > div:nth-child(-n + 4) {
      border-right: 1px solid ${Colors.Neutral15};
    }
    & > div:first-child {
      width: 187px;
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
    }
    & > div:last-child {
      width: 187px;
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
    }

    ${Media.Tablet`
    display: grid;
    width: 540px;
    height: 436px;
    border: 1px solid ${Colors.Neutral15};
    & > div:nth-child( -n + 4) {
      border-right: 0px;
      border-bottom: 1px solid ${Colors.Neutral15};
    }
    & > div:first-child {
      border-bottom-left-radius: 0px;
    }
  `};

    ${Media.Phone`
    display: grid;
    width: 335px;
    height: 396px;
  `};

    ${isCardClicked &&
    css`
      ${Media.Tablet`
        display: none;
        `}
      ${Media.Phone`
        display: none;
        `}
    `}
  `
})
