import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Container = styled.div`
  border: 5px solid white;
  border-radius: 29px;
  position: relative;
  overflow: hidden;
  left: 0;
  width: 290px;

  ${Media.Tablet`
    width: 240px;
  `};

  ${Media.Phone`
    width: fit-content;
    height: 147px;
  `};
`

export const CustomVideo = styled.video`
  border-radius: 10px;
  overflow: hidden;
  height: inherit;
  width: 100%;
`

export const YouText = styled.div`
  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${Colors.White};
  position: absolute;
  left: 16px;
  bottom: 16px;
  display: inline-block;
`

export const RecordingContainer = styled.div`
  ${Typography.Caption};
  ${FontWeights.Medium};
  color: ${Colors.White};
  position: absolute;
  right: 16px;
  bottom: 16px;
  display: inline-block;
  border-radius: var(--2XS, 8px);
  padding: 8px 16px;
  display: flex;
  align-items: center;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%),
    rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(15px);
`

export const RecordingIconContainer = styled.div`
  display: inline-block;
  margin-right: 6px;
  & svg {
    fill: transparent;
  }
`
