import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import mixins from 'builder/styles/mixins'

export const Title = styled.div<{ color?: string }>`
  margin-bottom: 8px;
  color: ${props => props.color || Colors.Neutral90};
  font-weight: 600;
  text-align: center;
  ${Typography.S}
`

export const Description = styled.div`
  color: ${Colors.Green70};
  ${Typography.Caption}
`

export const DescriptionLoading = styled.div<{ animated?: boolean }>`
  width: 200px;
  height: 19px;
  border-radius: 2px;
  background-color: ${Colors.Neutral10};
  ${props => props.animated && mixins.placeholderBackground}
`
