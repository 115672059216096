import { Filter, Content } from 'builder/modules/careerPlanning'

export const updateFilterOptions = (filterOptions: Filter[], index: number, value: boolean) => {
  const updatedFilterOptions = [...filterOptions]
  updatedFilterOptions[index] = {
    ...updatedFilterOptions[index],
    isDisabled: value,
  }

  return updatedFilterOptions
}

export const updateAllContents = (allContents: Content[], id: number, value: boolean) => {
  const updatedAllContents = allContents.map(allContent => {
    const updatedAllContent = allContent.contents.map(content => {
      if (content.id === id) {
        return {
          ...content,
          completed: value,
        }
      }

      return content
    })

    return {
      ...allContent,
      contents: updatedAllContent,
    }
  })

  return updatedAllContents
}

export const updateCompletedContentsById = (completedContents: Content[], id: number) => {
  const updatedCompletedContents = completedContents
    .map(completedContent => {
      const filteredContents = completedContent.contents.filter(content => content.id !== id)

      return {
        ...completedContent,
        contents: filteredContents,
      }
    })
    .filter(completedContent => completedContent.contents.length)

  return updatedCompletedContents
}

export const updateCompletedContentsByStatus = (updatedAllContents: Content[]) => {
  const updatedCompletedContents = updatedAllContents
    .map(allContent => {
      const updatedContents = allContent.contents.filter(content => content.completed)

      return {
        ...allContent,
        contents: updatedContents,
      }
    })
    .filter(allContent => allContent.contents.length)

  return updatedCompletedContents
}

export const updateOpenContentsById = (openContents: Content[], id: number) => {
  const updatedOpenContents = openContents.map(openContent => {
    const filteredContents = openContent.contents.filter(content => content.id !== id)

    return {
      ...openContent,
      contents: filteredContents,
    }
  })

  return updatedOpenContents
}

export const updateOpenContentsByStatus = (updatedAllContents: Content[]) => {
  const updatedOpenContents = updatedAllContents
    .map(allContent => {
      const updatedContents = allContent.contents.filter(content => !content.completed)

      return {
        ...allContent,
        contents: updatedContents,
      }
    })
    .filter(allContent => allContent.contents.length)

  return updatedOpenContents
}
