import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'

export const Title = styled.div`
  ${Typography.S};
  ${FontWeights.DemiBold};
`

export const Content = styled.div`
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
  padding-bottom: 20px;
  padding-top: 4px;
`

export const Bottom = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
`
