import styled, { keyframes } from 'styled-components'

interface StepsInterface {
  currentStep: number
  prevStep: number
}

const contentFadeBottomToTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(100px)
  }

  to {
    opacity: 1;
    transform: translateY(0)
  }
`

const contentFadeRightToLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(100px)
  }

  to {
    opacity: 1;
    transform: translateX(0)
  }
`

const contentFadeLeftToRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(-100px)
  }

  to {
    opacity: 1;
    transform: translateX(0)
  }
`

const getAnimation = (step: StepsInterface) => {
  if (step.prevStep === 0 && step.currentStep === 1) {
    return contentFadeBottomToTop
  }

  if (step.prevStep === 1 && step.currentStep === 2) {
    return contentFadeRightToLeft
  }

  if (step.prevStep === 2 && step.currentStep === 1) {
    return contentFadeLeftToRight
  }
}

export const Container = styled.div<{ step: StepsInterface }>`
  animation: ${({ step }) => getAnimation(step)} 0.5s ease-in-out;
`
