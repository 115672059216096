import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Shadows from 'builder/styles/shadows'

export const Track = styled.div`
  flex: 0 0 30px;
  width: 30px;
  height: 18px;
  border-radius: 9px;
  transition: background-color 0.14s ease;
  background-color: ${Colors.Neutral20};
  margin-right: 12px;
  position: relative;

  ${props => props.theme.checked && `background-color: ${Colors.Blue50};`}
  ${props => props.theme.invalid && `background-color: ${Colors.Red50};`}
  ${props => props.theme.disabled && `background-color: ${Colors.Neutral30};`}
`

export const Label = styled.label`
  ${Typography.Caption};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  user-select: none;
  cursor: ${p => (p.theme.disabled ? 'not-allowed' : 'pointer')};

  &:hover ${Track} {
    background-color: ${Colors.Neutral30};

    ${props => props.theme.checked && `background-color: ${Colors.Blue70};`}
    ${props => props.theme.invalid && `background-color: ${Colors.Red60};`}
    ${props => props.theme.disabled && `background-color: ${Colors.Neutral30};`}
  }
`

export const Thumb = styled.div`
  ${Shadows.light.low};
  position: absolute;
  top: 2px;
  left: 2px;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background-color: ${p => (p.theme.disabled ? Colors.Neutral15 : Colors.White)};
  transition: transform 0.14s ease;
  transform: translateX(${p => (p.theme.checked ? 12 : 0)}px);
`

export const HiddenCheckbox = styled.input`
  appearance: none;
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
  position: absolute;
`
