import React, { useState } from 'react'
import ActionMenu from 'builder/components/ActionMenu'
import { useI18n } from 'builder/hooks/useI18n'
import { useJobSearch } from '../useJobSearch'
import { LocationCategory } from '../types'
import { DropDownIcon, FilterButton } from './styles'
import { DEFAULT_FILTERS } from './constants'

interface Props {
  selected: number
  onSelect: (value: number) => void
}

const TRANSLATION_KEY = 'builder.job_search.filters.distance.metric'

const Distance = ({ selected, onSelect }: Props) => {
  const [selectionOpen, setSelectionOpen] = useState(false)
  const { selectedLocation } = useJobSearch()
  const { i18n } = useI18n()

  const handleSelection = (value: number) => {
    onSelect(value)
    setTimeout(() => setSelectionOpen(false), 100)
  }

  const options = [5, 25, 50, 100, 200]

  const actions = options.map(option => ({
    value: option,
    text: `${option} ${i18n.t(TRANSLATION_KEY)}`,
    onClick: () => handleSelection(option),
  }))

  const disabled = Boolean(
    selectedLocation &&
      (!selectedLocation?.locationCategory ||
        ![LocationCategory.City, LocationCategory.ZipCode].includes(
          selectedLocation?.locationCategory,
        )),
  )
  const isFilterActive = selected !== DEFAULT_FILTERS.radius && !disabled

  const label = actions.find(action => action.value === selected)?.text || actions[0].text
  return (
    <FilterButton
      isDisabled={disabled}
      onClick={() => setSelectionOpen(true)}
      selected={isFilterActive}
    >
      {label}
      <DropDownIcon isDisabled={disabled} selected={isFilterActive} />
      {selectionOpen && (
        <ActionMenu
          align="left"
          position="bottom"
          onClose={() => setSelectionOpen(false)}
          actions={actions}
        />
      )}
    </FilterButton>
  )
}

export default React.memo(Distance)
