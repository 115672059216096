import { FC, memo, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { useDispatch } from 'react-redux'
import { useI18n } from 'builder/hooks/useI18n'
import { Icon20 } from 'builder/components/Icon'
import Colors from 'builder/styles/colors'
import Button, { ButtonTheme } from 'builder/components/Button'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import { ModalTemplate } from 'builder/components/CareerProfile'
import { PaywallModalType, actions } from 'builder/modules/careerProfile'
import { APP_BASE_PATH } from 'builder/modules/constants'
import { analyticEventsLabel, translationKey } from '../../constants'
import { ServiceNamesForAnalytic } from '../../types'
import { ProfessionalPitchPreview } from '../previews/ProfessionalPitchPreview'
import { AiCoverLetterPreview } from '../previews/AiCoverLetterPreview'
import { InterviewPrepPreview } from '../previews/InterviewPrepPreview'
import * as Styled from './styles'

const typeToServiceForAnalyticsMap: Record<PaywallModalType, ServiceNamesForAnalytic> = {
  pitch: ServiceNamesForAnalytic.pitch,
  'cover-letter': ServiceNamesForAnalytic.coverLetter,
  'interview-prep': ServiceNamesForAnalytic.interviewPrep,
}

const typeToTranslationKeyMap: Record<PaywallModalType, string> = {
  pitch: 'pitch',
  'cover-letter': 'cover_letter',
  'interview-prep': 'interview_prep',
}

export const CareerInsightsPaywallModal: FC<{ type: PaywallModalType }> = memo(({ type }) => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const unlocksItems = useMemo(
    () =>
      Array.from(Array(5).keys()).map(
        item => `paywall_modal.by_type.${typeToTranslationKeyMap[type]}.unlocks.item_${item}`,
      ),
    [type],
  )
  const serviceName = typeToServiceForAnalyticsMap[type]

  useEffectOnMount(() => {
    trackInternalEvent('view_paywall', {
      label: analyticEventsLabel,
      service_name: serviceName,
    })
  })

  const onClose = () => {
    dispatch(actions.setOpenedPaywallModalType(null))
  }

  const onUpgradeClick = () => {
    trackInternalEvent('click_upgrade_paywall', {
      label: analyticEventsLabel,
      service_name: serviceName,
    })

    onClose()
  }

  return (
    <ModalTemplate
      onClose={onClose}
      header={
        <>
          {type === 'pitch' && <ProfessionalPitchPreview />}
          {type === 'cover-letter' && <AiCoverLetterPreview />}
          {type === 'interview-prep' && <InterviewPrepPreview />}
        </>
      }
      customContent={
        <Styled.Body>
          <Styled.MainSection>
            <Styled.HeaderWrapper>
              <Styled.PremiumBadge>
                <Icon20.Crown />
                {i18n.t(`${translationKey}.paywall_modal.premium_badge`)}
              </Styled.PremiumBadge>
              <Styled.HeaderText>
                {i18n.t(
                  `${translationKey}.paywall_modal.by_type.${typeToTranslationKeyMap[type]}.title`,
                )}
              </Styled.HeaderText>
            </Styled.HeaderWrapper>
            <Styled.MainText>
              {i18n.t(
                `${translationKey}.paywall_modal.by_type.${typeToTranslationKeyMap[type]}.text`,
              )}
            </Styled.MainText>
          </Styled.MainSection>
          <Styled.UnlocksSection>
            <Styled.UnlocksHeader>
              {i18n.t(`${translationKey}.paywall_modal.also_unlocks`)}
            </Styled.UnlocksHeader>
            <Styled.UnlocksList>
              {unlocksItems.map(item => (
                <Styled.UnlocksItem key={item}>
                  <Icon20.Tick color={Colors.Green40} />
                  {i18n.t(`${translationKey}.${item}`)}
                </Styled.UnlocksItem>
              ))}
            </Styled.UnlocksList>
          </Styled.UnlocksSection>
        </Styled.Body>
      }
      buttons={
        <>
          <Button theme={ButtonTheme.ghost} onClick={onClose}>
            {i18n.t(`${translationKey}.paywall_modal.skip`)}
          </Button>
          <Link to={`${APP_BASE_PATH}/billing/plans`} target="_blank" rel="noopener noreferrer">
            <Button onClick={onUpgradeClick}>
              {i18n.t(`${translationKey}.paywall_modal.upgrade_now`)}
            </Button>
          </Link>
        </>
      }
    />
  )
})
