import styled from 'styled-components'
import { Container } from 'builder/components/Container'

export const Wrapper = styled(Container)`
  padding: 0;
`

export const SpinnerWrapper = styled.div`
  margin: 28% auto;
  padding: 16px;
  border-radius: 32px;
  box-shadow: 0px 8px 20px -4px rgba(15, 56, 113, 0.12), 0px 2px 4px -1px rgba(15, 56, 113, 0.08),
    0px 0px 1px 0px rgba(15, 56, 113, 0.32);
`
