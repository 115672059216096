import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Media from 'builder/styles/media'
import { Logo } from 'builder/components/Logo'
import Typography, { FontWeights } from 'builder/styles/typography'
import Button from 'builder/components/Button'
import { PremiumButton } from 'builder/components/Navigation/components/PremiumButton'
import { PremiumTracker } from 'builder/components/Navigation/components/PremiumTracker'

const BAR_HEIGHT = 80
export const Z_INDEX = 599

export const Container = styled.nav`
  position: sticky;
  z-index: ${Z_INDEX};
  top: 0;
  left: 0;
  width: 100%;
  background: ${hexToRgba(Colors.White, 0.92)};
  backdrop-filter: blur(8px) saturate(200%);

  ${Media.Phone`
    display: none;
  `};
`

export const TabsContainer = styled.div<{ isDashboardScreen: boolean }>`
  top: 0;
  gap: 32px;
  position: sticky;
  padding: 0 40px;
  margin-left: auto;
  margin-right: 50%;
  width: 600px;
  height: 80px;
  z-index: ${Z_INDEX};
  margin-top: -80px;
  display: ${props => (props.isDashboardScreen ? 'flex' : 'none')};

  ${Media.Tablet`
    display: none;
  `};
`

export const NavTabContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

export const NavTab = styled.div<{ isActive: boolean }>`
  color: ${props => (props.isActive ? Colors.Neutral80 : Colors.Neutral50)};
  ${FontWeights.Medium};
  transition: color 0.1s;
  cursor: pointer;
`

export const TabBorder = styled.div`
  position: absolute;
  top: 0;
  height: 0;
  width: 100%;
  border-bottom: 3px solid ${Colors.Blue50};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`

export const Content = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 40px 0 24px;

  display: flex;
  align-items: center;
  position: relative;
  height: ${BAR_HEIGHT}px;

  ${Media.Tablet`
    padding: 0 32px 0 24px;
  `}
`

export const LogoLink = styled(RouterLink).withConfig({
  shouldForwardProp: propName => ['isMenuOpen'].includes(propName) === false,
})<{ isMenuOpen: boolean }>`
  display: flex;
  transition: opacity 0.2s, visibility 0.2s;
  opacity: ${props => (props.isMenuOpen ? 0 : 1)};
  visibility: ${props => (props.isMenuOpen ? 'hidden' : 'visible')};

  ${Media.Phone`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  `}
`

export const CustomLogo = styled.img`
  display: inline-block;
  width: 133px;
  height: 40px;
  vertical-align: middle;
`

export const LogoStyled = styled(Logo)`
  width: 133px;
  height: 40px;
`

export const RightColumn = styled.div<{ isMenuOpen: boolean }>`
  margin-left: auto;
  display: flex;
  align-items: center;
  opacity: ${props => (props.isMenuOpen ? 0 : 1)};
  visibility: ${props => (props.isMenuOpen ? 'hidden' : 'visible')};
  transition: opacity 0.2s, visibility 0.2s;
`

export const CoachButtonContainer = styled.div`
  margin-right: 24px;
  padding-right: 24px;
  border-right: 1px solid ${Colors.Neutral15};
  height: 24px;
  display: flex;
  align-items: center;
`

export const PremiumButtonWrapper = styled(PremiumButton)`
  margin-right: 16px;
`

export const BookCoachButton = styled(Button)`
  padding: 8px 12px;
  ${Typography.Caption};
  ${FontWeights.Medium};
`

export const PremiumTrackerWrapper = styled(PremiumTracker)`
  margin-right: 16px;
`
