import { useState, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { createMarketingEventHandler, trackMarketingEvent, trackInternalEvent } from '@rio/tracking'
import { TextField } from 'builder/components/TextField'
import Icon from 'builder/components/Icon'
import { useI18n } from 'builder/hooks/useI18n'
import { useConfig } from 'builder/hooks/useConfig'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { share } from './utils'

import {
  Container,
  Title,
  Description,
  Panel,
  ShareButtons,
  ShareButton,
  Actions,
  OpenLink,
  CopyButton,
  CopyButtonText,
  CopyButtonNote,
  Footer,
  FooterLink,
  FooterLinkIcon,
  FooterLinkText,
} from './styles'

const Sharing = ({ url }) => {
  const { i18n } = useI18n()
  const { links } = useConfig()
  const { isPhone } = useMediaQueries()

  const [isCopied, setCopied] = useState(false)
  const copiedNoteTimer = useRef()

  const input = useRef()

  const selectInputValue = useCallback(() => input.current.select(), [])

  const copyLink = useCallback(() => {
    selectInputValue()
    document.execCommand('copy')
    setCopied(true)

    clearTimeout(copiedNoteTimer.current)
    copiedNoteTimer.current = setTimeout(() => setCopied(false), 1000)

    trackMarketingEvent('Sharing Modal', 'Copy Link')
    trackInternalEvent('copy_sharing_link')
  }, [selectInputValue])

  const handleOpenLink = useCallback(() => {
    trackMarketingEvent('Sharing Modal', 'Open Link')
    trackInternalEvent('open_sharing_link')
  }, [])

  return (
    <Container>
      <Title>{i18n.t('builder.resume_editor.share_modal.title')}</Title>
      <Description>{i18n.t('builder.resume_editor.share_modal.description')}</Description>
      <TextField
        readOnly
        label={isPhone ? null : i18n.t('builder.resume_editor.share_modal.label')}
        value={url}
        ref={input}
        onClick={selectInputValue}
        onFocus={selectInputValue}
      />

      <Panel>
        <Actions>
          {!isPhone && (
            <OpenLink href={url} target="_blank" rel="noreferrer noopener" onClick={handleOpenLink}>
              {i18n.t('builder.resume_editor.share_modal.open')}
            </OpenLink>
          )}
          <CopyButton onClick={copyLink}>
            <CopyButtonText isCopied={isCopied}>
              {i18n.t('builder.resume_editor.share_modal.copy')}
            </CopyButtonText>
            <CopyButtonNote isCopied={isCopied}>
              {i18n.t('builder.resume_editor.share_modal.copied')}
            </CopyButtonNote>
          </CopyButton>
        </Actions>
        <ShareButtons>
          <ShareButton network="facebook" onClick={() => share(url, 'facebook')}>
            <Icon.Facebook />
          </ShareButton>
          <ShareButton network="linkedin" onClick={() => share(url, 'linkedin')}>
            <Icon.Linkedin />
          </ShareButton>
          <ShareButton network="twitter" onClick={() => share(url, 'twitter')}>
            <Icon.Twitter />
          </ShareButton>
        </ShareButtons>
      </Panel>

      {links.howToUseSharing && (
        <Footer>
          <FooterLink
            target="_blank"
            rel="noreferrer noopener"
            href={links.howToUseSharing}
            onClick={createMarketingEventHandler('Sharing Modal', 'Open FAQ Article')}
          >
            <FooterLinkIcon />
            <FooterLinkText>{i18n.t('builder.resume_editor.share_modal.faq_link')}</FooterLinkText>
          </FooterLink>
        </Footer>
      )}
    </Container>
  )
}

Sharing.propTypes = {
  url: PropTypes.string,
}

export default Sharing
