import Icon24, { Icon20 } from 'builder/components/Icon'
import { IconWrapper, FakeInput, RejectIconWrapper, Container } from './styles'

export const SEARCH_BAR_HEIGHT = 204

interface Props {
  value: string
  onClear: () => void
  onClick: () => void
}

export const FakeSearchInput = ({ value, onClear, onClick }: Props) => {
  return (
    <Container onClick={onClick}>
      <IconWrapper>
        <Icon24.NewSearch />
      </IconWrapper>
      <FakeInput>{value}</FakeInput>
      {value.length > 0 && (
        <RejectIconWrapper>
          <Icon20.RejectIcon onClick={onClear} />
        </RejectIconWrapper>
      )}
    </Container>
  )
}
