import DialogModal from 'builder/components/DialogModal'

interface Props {
  title: string
  text: string
  primaryButtonText: string
  secondaryButtonText: string
  primaryAction: () => void
  secondaryAction: () => void
  onCancel: () => void
}

export const DeletePrompt = (props: Props) => {
  const {
    title,
    text,
    primaryButtonText,
    secondaryButtonText,
    primaryAction,
    secondaryAction,
    onCancel,
  } = props

  return (
    <DialogModal
      title={title}
      text={text}
      primaryButtonText={primaryButtonText}
      secondaryButtonText={secondaryButtonText}
      primaryAction={primaryAction}
      secondaryAction={secondaryAction}
      closeAction={onCancel}
      isOpen
    />
  )
}
