import EditorField from 'builder/components/EditorField'
import { TextField } from 'builder/components/TextField'
import { useLanguageCardContext } from '../../ExpandableCardContext'
import { useShokumuTranslations } from '../../useShokumuTranslations'

const Language = (): JSX.Element => {
  const { item, onCardValueChange } = useLanguageCardContext()

  const updateLanguage = (event: React.ChangeEvent<HTMLInputElement>) => {
    onCardValueChange({ [event.target.name]: event.target.value }, true)
  }

  const { i18n } = useShokumuTranslations()
  const label = i18n('languages.language')

  return (
    <EditorField>
      <TextField
        label={label}
        value={item.language || ''}
        name="language"
        onChange={updateLanguage}
      />
    </EditorField>
  )
}
export default Language
