import styled from 'styled-components'
import Media from 'builder/styles/media'
import { Colors } from 'builder/styles/colors'
import { Typography, FontWeights } from 'builder/styles/typography'
import { HorizontalTransitionFade } from 'builder/components/HorizontalTransitionFade'

export const Container = styled(HorizontalTransitionFade)`
  max-width: 1120px;
  width: calc(100% - 40px);
  margin: 0 auto;
  padding: 40px 0 80px;
  gap: 40px;
  display: flex;
  flex-direction: column;

  ${Media.Tablet`
    gap: 24px;
    padding: 40px 0 48px;
  `}

  ${Media.Phone`
    gap: 16px;
    padding: 24px 0 80px;
  `};
`

export const SubContainer = styled.div`
  width: 100%;
  gap: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`

export const Title = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};
  width: 100%;
  text-align: center;
  color: ${Colors.Neutral90};

  ${Media.Phone`
    ${Typography.S};
  `}
`
