import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography from 'builder/styles/typography'

const { White, Blue90 } = Colors

export const TooltipBody = styled.div<{ position?: 'top' | 'bottom' }>`
  display: flex;
  align-items: center;
  position: absolute;
  background: ${White};
  padding: 8px 12px;
  border-radius: 4px;
  box-shadow: 0px 8px 20px -4px rgba(15, 56, 113, 0.12), 0px 2px 4px -1px rgba(15, 56, 113, 0.08),
    0px 0px 1px 0px rgba(15, 56, 113, 0.32);
  z-index: 20;
  ${props =>
    props.position === 'top' &&
    css`
      top: -100%;
      right: 0%;
      ${Media.Phone`
        top: -120%;
      `}
    `}
  ${props =>
    props.position === 'bottom' &&
    css`
      position: absolute;
      top: 100%;
      left: -50%;
    `}
`

export const TooltipImg = styled.img`
  height: 26px;
  width: 23px;
  margin-right: 8px;
`

export const TooltipText = styled.div<{ position?: 'top' | 'bottom' }>`
  color: ${Blue90};
  ${Typography.Caption};
  text-wrap: nowrap;
  margin-right: 12px;
  ${Media.Phone`
    ${Typography.Body};
    width: 167px;
    text-wrap: wrap;
  `}
  &::after {
    content: ' ';
    position: absolute;
    left: 50%;
    border-width: 8px;
    border-style: solid;
    border-color: ${White} transparent transparent transparent;
    ${props =>
      props.position === 'top' &&
      css`
        top: 100%;
      `}
    ${props =>
      props.position === 'bottom' &&
      css`
        transform: rotate(180deg);
        top: -33%;
      `}
  }
`

export const CloseIcon = styled.div`
  line-height: 1;
  margin-bottom: 12px;
  color: ${Blue90};
  cursor: pointer;
`

export const Overlay = styled.div`
  background: rgba(15, 20, 31, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  pointer-events: none;
`
