import { useDispatch } from 'react-redux'
import { Fragment, useCallback } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { actions, selectors, Label } from 'builder/modules/careerPath2'

import MultiSelect from '../MultiSelect'
import {
  SkillsText,
  FieldContainer,
  FieldsContainer,
  PrefillModalLink,
  SkillsTextContainer,
} from '../../styles'

const InputField = () => {
  const dispatch = useDispatch()
  const documents = useTypedSelector(selectors.userDocuments)

  const handleOpenModal = useCallback(() => {
    dispatch(actions.fetchUserDocuments())
    dispatch(actions.setOpenPreFillModal(true))

    trackInternalEvent('click_prefill_from_resumes_button', Label)
  }, [dispatch])

  return (
    <Fragment>
      <SkillsTextContainer>
        <SkillsText>Skills (minimum 5)</SkillsText>
        <PrefillModalLink onClick={handleOpenModal} documents={documents.length}>
          Pre-fill from resume
        </PrefillModalLink>
      </SkillsTextContainer>
      <FieldsContainer>
        <FieldContainer>
          <MultiSelect />
        </FieldContainer>
      </FieldsContainer>
    </Fragment>
  )
}

export default InputField
