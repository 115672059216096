import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { uniqBy } from 'lodash'
import CareerAdviseVisual from 'builder/components/CIO-Dasboard-2.0/Components/ServiceCard/Components/Visuals/CareerAdviseVisual'
import ExpertHelpVisual from 'builder/components/CIO-Dasboard-2.0/Components/ServiceCard/Components/Visuals/ExpertHelpVisual'
import { ExploreYourNextStepsVisual } from 'builder/components/CIO-Dasboard-2.0/Components/ServiceCard/Components/Visuals/ExploreYourNextStepsVisual'
import JobFairsVisual from 'builder/components/CIO-Dasboard-2.0/Components/ServiceCard//Components/Visuals/JobFairsVisual'
import JobInterviewVisual from 'builder/components/CIO-Dasboard-2.0/Components/ServiceCard/Components/Visuals/JobInterviewVisual'
import JobSearchVisual from 'builder/components/CIO-Dasboard-2.0/Components/ServiceCard/Components/Visuals/JobSearchVisual'
import OptimizeResumeVisual from 'builder/components/CIO-Dasboard-2.0/Components/ServiceCard/Components/Visuals/OptimizeResumeVisual'
import TrackJobs from 'builder/components/CIO-Dasboard-2.0/Components/ServiceCard/Components/Visuals/TrackJobs'
import CareerAssessment from 'builder/components/CIO-Dasboard-2.0/Components/ServiceCard/Components/Visuals/CareerAssessment'
import { actions, BriefResume, selectors } from 'builder/modules/panel'
import { actions as editorActions } from 'builder/modules/resumeEditor'
import { actions as uiActions } from 'builder/modules/ui'
import { actions as careerActions, selectors as careerSelectors } from 'builder/modules/careerPath'
import { DocumentTypes, FetchStatuses } from 'builder/modules/constants'
import { selectors as interviewSelector } from 'builder/modules/interview/interviewModule'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useResume } from 'builder/hooks/useResume'
import { selectors as userSelectors } from 'builder/modules/user'
import { useI18n } from 'builder/hooks/useI18n'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { useDashboardStatusUtils } from 'builder/views/CareerProfile/hooks/useDashboardStatusUtils'
import { IService } from '../types'
import { ProfessionalHeadshot } from '../Components/ServiceCard/Components/Visuals/ProfessionalHeadshot/ProfessionalHeadshot'
import { ExploreCareersVisual } from '../Components/ServiceCard/Components/Visuals/ExploreCareersVisual'
import { GetResumeCritiqueVisual } from '../Components/ServiceCard/Components/Visuals/GetResumeCritiqueVisual'
import ResumeDistributionVisual from '../Components/ServiceCard/Components/Visuals/ResumeDistributionVisual'
import BuildSkillsVisual from '../Components/ServiceCard/Components/Visuals/BuildSkillsVisual'

export enum ColorTones {
  blue = 'Blue',
  neutral = 'Neutral',
  green = 'Green',
  indigo = 'Indigo',
  beige = 'Beige',
}

export const highlightColors = {
  Blue: '#E0F1FE',
  Neutral: '#E7EAF4',
  Green: '#E2F1E8',
  Indigo: '#EDEEFE',
  Beige: '#EFEDE8',
}

export const useServicesData = (activeTabId: number) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { i18n } = useI18n()
  const [comingSoonService, setComingSoonService] = useState<IService>()
  const [isComingSoonModal, setIsComingSoonModal] = useState<boolean>()
  const features = useFeaturesConfig()

  const { actionHandler: careerProfileNavigationHandler } = useDashboardStatusUtils()

  const isCioDomain = features?.features?.superApp
  const isRioDomain = features?.features?.international

  // User documents & data
  const documentsStatus = useTypedSelector(selectors.documentsStatus)

  // Get user profile image
  const userPhotoUrl = useTypedSelector(userSelectors.photoUrl)

  // Find and analyze the resume
  const resumeId = useTypedSelector(selectors.resumeId)
  const resume = useResume(resumeId)

  // Career pathways
  const careerGoalStatus = useTypedSelector(careerSelectors.goalStatus)

  const userType = useTypedSelector(userSelectors.postPremium)

  const interviewUrl = useSelector(interviewSelector.getInterviewUrl)
  const interviewDomainUrl = useSelector(interviewSelector.getInterviewDomainUrl)

  // Start fetching data if needed
  useEffect(() => {
    if (documentsStatus === FetchStatuses.notAsked) dispatch(actions.fetchDocuments())
    if (careerGoalStatus === FetchStatuses.notAsked) dispatch(careerActions.fetchGoal())
  }, [dispatch, documentsStatus, careerGoalStatus])

  // Data fetching status

  const tunerResumeId = useTypedSelector(selectors.tunerResumeId)
  const lastUpdatedResume: BriefResume | null = useTypedSelector(state =>
    selectors.lastCreated(state, DocumentTypes.resume),
  )

  const latestId: number | undefined = lastUpdatedResume?.id as number | undefined

  const isLoaded = useMemo(() => {
    if (
      [FetchStatuses.notAsked, FetchStatuses.loading].includes(careerGoalStatus) ||
      documentsStatus !== FetchStatuses.loaded ||
      (resumeId && !resume)
    )
      return false
    return true
  }, [documentsStatus, careerGoalStatus, resumeId, resume])

  // Service Cards data
  const filteredServices: IService[] = useMemo(() => {
    const resumeAction = (isTailorResume: boolean) => {
      if (!tunerResumeId) {
        dispatch(uiActions.openCreateResumeModal())
        isTailorResume && dispatch(actions.setTailorResume())
      } else {
        navigate(`/resumes/${latestId}/edit`)
        isTailorResume && dispatch(editorActions.setOpenOptimizerModal(true))
      }
    }

    const buildTopResumeUrl = () => {
      const params: { [key: string]: string } = {
        pt: 'UWYxd6Xe2VTS0',
        utm_content: 'get_expert_help',
      }

      if (isCioDomain) {
        params.utm_source = 'careerio'
        params.utm_medium = 'cioplatformcard'
        params.utm_campaign = 'tr_promocard_in_ciodash'
      }

      if (isRioDomain) {
        params.utm_source = 'resumeio'
        params.utm_medium = 'rioplatformcard'
        params.utm_campaign = 'tr_promocard_in_riodash'
      }

      const queryParams = new URLSearchParams(params)
      window.open(`https://topresume.com/resume-writing?${queryParams.toString()}`, '_blank')
    }

    const isResumeDistributionEnabled = features?.showResumeDistribution()

    const data: IService[] = [
      {
        enabled: features?.features?.resumeWriting,
        targetEventName: 'pro_resume_writing',
        imgUrl: require('../assets/ExpertHelp.svg'),
        title: i18n.t('builder.dashboard.service_data.cards.get_expert_help.title'),
        subTitle: i18n.t('builder.dashboard.service_data.cards.get_expert_help.subTitle'),
        action: () => buildTopResumeUrl(),
        colorTone: ColorTones.neutral,
        visual: ExpertHelpVisual,
        order: {
          0: 1,
          1: 0,
          2: 0,
        },
        cardName: 'get_expert_help',
        isPremiumService: false,
        cioDomainUrl:
          'https://topresume.com/resume-writing?pt=UWYxd6Xe2VTS0&utm_source=resumeio&utm_medium=rioplatformcard&utm_campaign=tr_promocard_in_riodash&utm_content=get_expert_help',
      },
      {
        enabled: features?.features?.careerProfile,
        targetEventName: 'career_assessment',
        imgUrl: require('../assets/getAssessment.png'),
        title: i18n.t('builder.dashboard.service_data.cards.career_assessment.title'),
        subTitle: i18n.t('builder.dashboard.service_data.cards.career_assessment.subTitle'),
        action: careerProfileNavigationHandler,
        colorTone: ColorTones.indigo,
        visual: CareerAssessment,
        order: {
          0: 2,
          1: 1,
          2: 1,
        },
        cardName: 'career_assessment',
        userPhotoUrl: userPhotoUrl,
        isPremiumService: false,
        cioDomainUrl: 'https://career.io/app/career-profile/',
      },
      {
        enabled: features?.features?.careerExplorer,
        targetEventName: 'explore_careers',
        imgUrl: require('../assets/ExploreCareersNew.svg'),
        title: i18n.t('builder.dashboard.service_data.cards.explore_careers.title'),
        subTitle: i18n.t('builder.dashboard.service_data.cards.explore_careers.subTitle'),
        action: () => navigate('/explore-careers'),
        colorTone: ColorTones.beige,
        visual: ExploreCareersVisual,
        order: {
          0: 0,
          1: 0,
          2: 2,
        },
        isComingSoon: true,
        modalVisual: require('../assets/ExploreCareersNewModal.svg'),
        cardName: 'explore_careers',
        modalTitle: 'Explore available careers',
        isPremiumService: true,
        cioDomainUrl: 'https://career.io/app/explore-careers',
        modalDescription:
          'Uncover the demand for your desired job titles and discover the specific skills, education, and certifications required to pursue your dream career. ',
      },
      {
        enabled: features?.features?.careerPath,
        targetEventName: 'career_path',
        imgUrl: require('../assets/ExploreCareers.svg'),
        title: i18n.t('builder.dashboard.service_data.cards.discover_your_next_steps.title'),
        subTitle: i18n.t('builder.dashboard.service_data.cards.discover_your_next_steps.subTitle'),
        action: () => navigate('/career-path'),
        colorTone: ColorTones.beige,
        visual: ExploreYourNextStepsVisual,
        order: {
          0: 0,
          1: 3,
          2: 3,
        },
        userPhotoUrl: userPhotoUrl,
        isPremiumService: true,
        cioDomainUrl: 'https://career.io/app/career-path',
      },
      {
        enabled: activeTabId === 1 || activeTabId === 2,
        targetEventName: 'course_report',
        imgUrl: require('../assets/BuildSkills.svg'),
        title: i18n.t('builder.dashboard.service_data.cards.build_skills.title'),
        subTitle: i18n.t('builder.dashboard.service_data.cards.build_skills.subTitle'),
        action: () => window.open('https://www.coursereport.com/get-matched', '_blank'),
        colorTone: ColorTones.green,
        visual: BuildSkillsVisual,
        order: {
          0: 8,
          1: 3,
          2: 2,
        },
        cardName: 'enhance_refresh_skills',
        isPremiumService: false,
      },
      {
        enabled: features?.features?.salaryAnalyzer,
        targetEventName: 'salary_analyzer',
        imgUrl: require('../assets/know_your_worth.svg'),
        title: i18n.t('builder.dashboard.service_data.cards.know_your_worth.title'),
        subTitle: i18n.t('builder.dashboard.service_data.cards.know_your_worth.subTitle'),
        action: () => navigate('/offer-analyzer'),
        colorTone: ColorTones.indigo,
        isPostPremiumActive: userType === 'post_premium',
        visual: TrackJobs,
        order: {
          0: 8,
          1: 4,
          2: 4,
        },
        cardName: 'know_your_worth',
        isPremiumService: true,
        cioDomainUrl: 'https://career.io/app/offer-analyzer',
      },
      {
        enabled: features?.features?.careerAdvice,
        targetEventName: 'professional_resources',
        imgUrl: require('../assets/Cards.svg'),
        title: i18n.t('builder.dashboard.service_data.cards.read_career_advice.title'),
        subTitle: i18n.t('builder.dashboard.service_data.cards.read_career_advice.subTitle'),
        action: () => window.open('https://career.io/career-advice/career-development', '_self'),
        colorTone: ColorTones.neutral,
        visual: CareerAdviseVisual,
        order: {
          0: 0,
          1: 5,
          2: 0,
        },
        cardName: 'read_career_advice',
        isPremiumService: false,
        cioDomainUrl: 'https://career.io/career-advice/career-development',
      },
      {
        enabled: features?.features?.careerAdvice,
        targetEventName: 'professional_resources',
        imgUrl: require('../assets/Cards.svg'),
        title: i18n.t('builder.dashboard.service_data.cards.read_expert_advice.title'),
        subTitle: i18n.t('builder.dashboard.service_data.cards.read_expert_advice.subTitle'),
        action: () => window.open('https://career.io/career-advice/career-change', '_self'),
        colorTone: ColorTones.neutral,
        visual: CareerAdviseVisual,
        order: {
          0: 0,
          1: 0,
          2: 1,
        },
        cardName: 'read_expert_advice',
        isPremiumService: false,
        cioDomainUrl: 'https://career.io/career-advice/career-change',
      },
      {
        enabled: features?.features?.careerAdvice,
        targetEventName: 'professional_resources',
        imgUrl: require('../assets/Cards.svg'),
        title: i18n.t('builder.dashboard.service_data.cards.read_job_search_advice.title'),
        subTitle: i18n.t('builder.dashboard.service_data.cards.read_job_search_advice.subTitle'),
        action: () => window.open('https://career.io/career-advice/finding-a-job', '_self'),
        colorTone: ColorTones.neutral,
        visual: CareerAdviseVisual,
        order: {
          0: 9,
          1: 0,
          2: 0,
        },
        cardName: 'read_job_search_advice',
        isPremiumService: true,
        cioDomainUrl: 'https://career.io/career-advice/finding-a-job',
      },
      {
        enabled: features?.features?.jobSearchPlan,
        targetEventName: 'job_search_strategy',
        imgUrl: require('../assets/JobSearchImage.svg'),
        title: i18n.t('builder.dashboard.service_data.cards.execute_a_job_search.title'),
        subTitle: i18n.t('builder.dashboard.service_data.cards.execute_a_job_search.subTitle'),
        action: () => navigate('/career-plans/execute-a-job-search'),
        colorTone: ColorTones.green,
        isPostPremiumActive: userType === 'post_premium',
        visual: JobSearchVisual,
        order: {
          0: 3,
          1: 0,
          2: 5,
        },
        cardName: 'execute_a_job_search',
        isPremiumService: true,
        cioDomainUrl: 'https://career.io/app/career-plans/execute-a-job-search',
      },
      {
        enabled: features?.features?.virtualHiringEvents,
        targetEventName: 'virtual_events',
        imgUrl: require('../assets/JobFairs.svg'),
        title: 'Virtual Hiring Events',
        subTitle: 'Career events that will help you excel in your career',
        action: () => navigate('/my-events'),
        colorTone: ColorTones.neutral,
        visual: JobFairsVisual,
        order: {
          0: 10,
          1: 0,
          2: 8,
        },
        isComingSoon: false,
        modalVisual: require('../assets/JoinVirtualJobFairs.svg'),
        cardName: 'join_virtual_job_fairs',
        modalTitle: 'Join Virtual Hiring Events',
        isPremiumService: false,
        cioDomainUrl: 'not available',
        modalDescription:
          'Discover endless opportunities by virtually connecting with diverse companies across industries. Expand your job search beyond impersonal job boards and directly engage with employers in virtual events.',
      },
      {
        enabled: features?.features?.careerPlans,
        targetEventName: 'first_90_days_plan',
        imgUrl: require('../assets/Plan.svg'),
        title: i18n.t('builder.dashboard.service_data.cards.first_90_days_plan.title'),
        subTitle: i18n.t('builder.dashboard.service_data.cards.first_90_days_plan.subTitle'),
        action: () => navigate('/career-plans/first-90-days'),
        colorTone: ColorTones.green,
        visual: JobSearchVisual,
        order: {
          0: 0,
          1: 1,
          2: 0,
        },
        cardName: 'first_90_days_plan',
        isPremiumService: true,
        cioDomainUrl: 'https://career.io/app/career-plans/first-90-days',
      },
      {
        enabled: features?.features?.careerPlans,
        targetEventName: 'plan_to_promotion',
        imgUrl: require('../assets/Promotion.svg'),
        title: i18n.t('builder.dashboard.service_data.cards.get_a_promotion.title'),
        subTitle: i18n.t('builder.dashboard.service_data.cards.get_a_promotion.subTitle'),
        action: () => navigate('/career-plans/path-to-promotion'),
        colorTone: ColorTones.green,
        visual: JobSearchVisual,
        order: {
          0: 0,
          1: 3,
          2: 0,
        },
        cardName: 'get_a_promotion',
        isPremiumService: true,
        cioDomainUrl: 'https://career.io/app/career-plans/path-to-promotion',
      },
      {
        enabled: features?.features?.resumeOptimizer,
        targetEventName: 'resume_tailoring',
        imgUrl: require('../assets/Resume.svg'),
        title: i18n.t('builder.dashboard.service_data.cards.tailor_your_resume.title'),
        subTitle: i18n.t('builder.dashboard.service_data.cards.tailor_your_resume.subTitle'),
        action: () => resumeAction(true),
        colorTone: ColorTones.blue,
        visual: OptimizeResumeVisual,
        isShowIcons: true,
        isShowResume: true,
        order: {
          0: 5,
          1: 0,
          2: 0,
        },
        cardName: 'tailor_your_resume',
        isPremiumService: false,
        cioDomainUrl: 'not available',
      },
      {
        enabled: features?.features?.interviewSchool,
        targetEventName: 'interview_prep',
        imgUrl: require('../assets/AceInterview.svg'),
        title: i18n.t('builder.dashboard.service_data.cards.ace_job_interview.title'),
        subTitle: i18n.t('builder.dashboard.service_data.cards.ace_job_interview.subTitle'),
        action: () => navigate(interviewUrl),
        colorTone: ColorTones.green,
        isPostPremiumActive: userType === 'post_premium',
        visual: JobInterviewVisual,
        order: {
          0: 7,
          1: 0,
          2: 7,
        },
        cardName: 'ace_job_interview',
        userPhotoUrl: userPhotoUrl,
        isPremiumService: true,
        cioDomainUrl: interviewDomainUrl,
      },
      {
        enabled: true,
        targetEventName: 'cover_letter',
        imgUrl: require('../assets/Resume.svg'),
        title: i18n.t('builder.dashboard.service_data.cards.create_a_cover_letter.title'),
        subTitle: i18n.t('builder.dashboard.service_data.cards.create_a_cover_letter.subTitle'),
        action: () => dispatch(actions.createDocument({ type: DocumentTypes.coverLetter })),
        colorTone: ColorTones.blue,
        visual: OptimizeResumeVisual,
        isShowAdd: true,
        isCoverLetter: true,
        isShowCoverLetter: true,
        order: {
          0: 6,
          1: 0,
          2: 0,
        },
        cardName: 'create_a_cover_letter',
        isPremiumService: false,
        cioDomainUrl: 'not available',
      },
      {
        enabled: features?.features?.professionalHeadshot,
        targetEventName: 'professional_headshot',
        imgUrl: require('../assets/ProfessionalHeadshot.svg'),
        title: i18n.t('builder.dashboard.service_data.cards.professional_headshot.title'),
        subTitle: i18n.t('builder.dashboard.service_data.cards.professional_headshot.subTitle'),
        action: () => navigate('#'),
        colorTone: ColorTones.neutral,
        visual: ProfessionalHeadshot,
        order: {
          0: 13,
          1: 0,
          2: 0,
        },
        isComingSoon: true,
        modalVisual: require('../assets/ProfessionalHeadshotModal.svg'),
        cardName: 'professional_headshot',
        modalTitle: 'Professional Headshot',
        isPremiumService: false,
        cioDomainUrl: 'not available',
        modalDescription:
          'Always have an updated, professional headshot perfect for any social or employment profile without needing costly photography or difficult editing tools.',
      },
      {
        enabled: features?.features?.resumeReview,
        targetEventName: 'resume_review',
        imgUrl: require('../assets/Critique.svg'),
        title: i18n.t('builder.dashboard.service_data.cards.get_resume_critique.title'),
        subTitle: i18n.t('builder.dashboard.service_data.cards.get_resume_critique.subTitle'),
        action: () => navigate('#'),
        colorTone: ColorTones.neutral,
        visual: GetResumeCritiqueVisual,
        order: {
          0: 11,
          1: 0,
          2: 0,
        },
        isComingSoon: true,
        modalVisual: require('../assets/GetResumeCritiqueModal.svg'),
        cardName: 'get_resume_critique',
        modalTitle: 'Get a Resume Review',
        isPremiumService: false,
        cioDomainUrl: 'not available',
        modalDescription:
          'Receive a professional resume review from our expert team. We will assess readability, work experience, skills, and key information, providing you with valuable insights on how to enhance your resume.',
      },
      {
        enabled: isResumeDistributionEnabled,
        targetEventName: 'resume_distribution',
        imgUrl: require('../assets/ResumeDistribution.svg'),
        title: i18n.t('builder.dashboard.service_data.cards.resume_distribution.title'),
        subTitle: i18n.t('builder.dashboard.service_data.cards.resume_distribution.subTitle'),
        action: () => navigate('/resume-distribution'),
        colorTone: ColorTones.blue,
        visual: ResumeDistributionVisual,
        order: {
          0: 2,
        },
        cardName: 'resume_distribution',
        isPremiumService: false,
        cioDomainUrl: 'not available',
      },
      {
        enabled: features?.features?.jobSearch,
        targetEventName: 'find_your_next_job',
        imgUrl: require('../assets/track_job_image.svg'),
        title: i18n.t('builder.dashboard.service_data.cards.find_your_next_job.title'),
        subTitle: i18n.t('builder.dashboard.service_data.cards.find_your_next_job.subTitle'),
        action: () => navigate('/job-search'),
        colorTone: ColorTones.indigo,
        visual: TrackJobs,
        order: {
          0: 4,
          1: 0,
          2: 6,
        },
        cardName: 'find_your_next_job',
        userPhotoUrl: userPhotoUrl,
        isPremiumService: true,
        cioDomainUrl: 'https://career.io/app/job-search?view=recommendation',
      },
      {
        enabled: features?.features?.jobTracking,
        targetEventName: 'track_your_job',
        imgUrl: require('../assets/TrackYourJobs.svg'),
        title: i18n.t('builder.dashboard.service_data.cards.track_your_job.title'),
        subTitle: i18n.t('builder.dashboard.service_data.cards.track_your_job.subTitle'),
        action: () => navigate('/job-tracking'),
        colorTone: ColorTones.indigo,
        visual: TrackJobs,
        order: {
          0: 4,
          1: 0,
          2: 6,
        },
        cardName: 'track_your_job',
        userPhotoUrl: userPhotoUrl,
        isPremiumService: true,
        cioDomainUrl: 'https://career.io/app/job-tracking',
      },
    ]

    // Determine Career assessment order for Find a New Job tab
    if (features?.features?.careerProfile) {
      const cA = data.find(card => card.cardName === 'career_assessment')
      const eH = data.find(card => card.cardName === 'get_expert_help')
      const rD = data.find(card => card.cardName === 'resume_distribution')
      // Put assessment card after resume distribution if enabled, if not after expert help.
      if (cA) {
        if (eH?.enabled) {
          cA.order[0] = eH.order[0] + 1
        }
        if (isResumeDistributionEnabled && rD) {
          cA.order[0] = rD.order[0] + 1
        }
      }
    }

    if (activeTabId > -1) {
      return data.filter(serv => serv.order[`${activeTabId}`] && serv.enabled)
    }
    return data.filter(serv => serv.enabled)
  }, [
    i18n,
    userPhotoUrl,
    userType,
    features,
    activeTabId,
    tunerResumeId,
    dispatch,
    navigate,
    latestId,
    isCioDomain,
    isRioDomain,
  ])

  const sortedServices: IService[] = useMemo(
    () =>
      filteredServices.sort((a, b) => {
        return a.order[`${activeTabId}`] - b.order[`${activeTabId}`]
      }),
    [activeTabId, filteredServices],
  )

  const services = uniqBy(sortedServices, e => e.targetEventName)

  // Open/Close modal
  const onServiceCardClick = (name?: string | undefined) => {
    // Open and Close comming soon modal
    setIsComingSoonModal(!isComingSoonModal)
    if (!isComingSoonModal && name) {
      const comingSoonData = services.find(service => service.cardName === name)
      comingSoonData && setComingSoonService(comingSoonData)
    }
  }

  return { isLoaded, services, onServiceCardClick, isComingSoonModal, comingSoonService }
}
