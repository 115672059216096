import Loader from '../Loader'
import { CourseCard } from './components/CourseCard'
import { Container, Heading, Content } from './styles'
import SwiperComponent from './components/SwiperComponent'
import { useRecommendedCourses } from './hooks/useRecommendedCourses'

export const RecommendedCourses = () => {
  const { courses, jobTitle, eventLabel, isSwiperEnabled, isCoursesLoading, isCoursesFailed } =
    useRecommendedCourses()

  const renderCourses = () => {
    if (isSwiperEnabled) {
      return <SwiperComponent {...{ courses, jobTitle, eventLabel, isSwiperEnabled }} />
    }

    return courses?.map(course => {
      return <CourseCard key={course.title} {...{ course, jobTitle, eventLabel }} />
    })
  }

  if (isCoursesLoading) {
    return (
      <Container>
        <Heading>Recommended Courses</Heading>
        <Content>
          <Loader />
        </Content>
      </Container>
    )
  }

  if (isCoursesFailed || !courses.length) {
    return null
  }

  return (
    <Container>
      <Heading>Recommended Courses</Heading>
      <Content>{renderCourses()}</Content>
    </Container>
  )
}
