import { memo } from 'react'
import OptionCards from './OptionCards'
import { OptionsContainer } from './styles'

const OptionsView = () => {
  return (
    <OptionsContainer>
      <OptionCards />
    </OptionsContainer>
  )
}

export default memo(OptionsView)
