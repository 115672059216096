import styled from 'styled-components'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  flex-wrap: wrap;
  width: 100%;
  white-space: nowrap;

  ${Media.Phone`
    width: auto;
  `}
`

export const SpecialitiesChip = styled.div`
  ${FontWeights.Medium};
  ${Typography.Tiny};
  color: ${Colors.Neutral50};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;
  height: 24px;
  background: ${Colors.Neutral10};
  border-radius: 2px;
`

export const MoreInfo = styled.p`
  ${FontWeights.Medium};
  ${Typography.Tiny};
  color: ${Colors.Neutral50};
  padding-top: 5px;
`
