import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import {
  HeaderProps,
  skillsRankTipsText,
  accomplishmentTipsText,
  accomplishmentRankTipsText,
} from 'builder/modules/sevenStories'
import {
  Title,
  Wrapper,
  SubTitle,
  Container,
  TipsContainer,
  TooltipQuestionWrapper,
  ThirdDescription,
  TipsTitle,
  HintIcon,
} from './styles'

type Props = {
  step: number
  getHeaderTitle: () => HeaderProps
}

const Header = ({ step, getHeaderTitle }: Props) => {
  const { isPhone } = useMediaQueries()
  const header = getHeaderTitle()

  const getTipsText = () => {
    switch (step) {
      case 1:
        return accomplishmentTipsText
      case 2:
        return accomplishmentRankTipsText
      case 4:
        return skillsRankTipsText
    }
  }

  const getStepThreeDescription = () => (
    <div>
      Now, let’s take a deeper dive into your top accomplishments. If the right word for skills is
      eluding you, please visit this{' '}
      <ThirdDescription
        href="https://www.yourdictionary.com/articles/examples-skills-list"
        target="__blank"
      >
        reference
      </ThirdDescription>{' '}
      for a list of skills.
    </div>
  )

  return (
    <Wrapper>
      <Container>
        <Title>{header.title}</Title>
        <SubTitle>{step === 3 ? getStepThreeDescription() : header.description}</SubTitle>
      </Container>
      {isPhone && step !== 3 ? (
        <TooltipQuestionWrapper value={getTipsText()} position="bottom" multiline>
          <TipsContainer className="tips-container">
            <TipsTitle>Tips</TipsTitle>
            <HintIcon className="hint-icon" />
          </TipsContainer>
        </TooltipQuestionWrapper>
      ) : null}
    </Wrapper>
  )
}

export default Header
