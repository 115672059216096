import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { Description, FooterWrapper, MailLink } from './style'

const Footer = () => {
  const { isPhone } = useMediaQueries()

  const supportUrl = <MailLink href="mailto:coaching@career.io">coaching@career.io</MailLink>

  return (
    <FooterWrapper>
      {isPhone ? (
        <>
          <Description>If you have questions about this coaching service,</Description>
          <Description>reach out to our support via email {supportUrl}</Description>
        </>
      ) : (
        <>
          <Description>
            If you have questions about this coaching service, reach out to our support via email{' '}
            {supportUrl}
          </Description>
        </>
      )}
    </FooterWrapper>
  )
}

export default Footer
