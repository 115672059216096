import { useMemo, useState } from 'react'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { EDashboardLocalStoreKeys, ITabsAction } from '../../types'
import * as Styled from './styles'
import BookCoachButton from './BookCoachButton'

interface Props {
  onSelectedIndexChange: (index: number) => void
  selectedIndex: number
  buttons: ITabsAction[]
}

const ActionBanner = ({ onSelectedIndexChange, selectedIndex, buttons }: Props) => {
  const { features } = useFeaturesConfig()
  const { isPhone, isTablet } = useMediaQueries()
  const getInitButtonStates = useMemo(() => {
    const newButtonColors = Array(3).fill(false)
    newButtonColors[selectedIndex] = true
    return newButtonColors
  }, [selectedIndex])

  const [buttonColors, setButtonColors] = useState(getInitButtonStates)
  const [isHovered, setIsHovered] = useState(Array(3).fill(false))

  const handleMouseEnter = (index: number) => {
    setIsHovered(prevState => {
      const newButton = [...prevState]
      newButton[index] = true
      return newButton
    })
  }

  const handleMouseLeave = (index: number) => {
    setIsHovered(prevState => {
      const newButton = [...prevState]
      newButton[index] = false
      return newButton
    })
  }

  const handleTabClick = (index: number) => {
    const newButtonColors = Array(buttonColors.length).fill(false)
    newButtonColors[index] = true
    setButtonColors(newButtonColors)
    localStorage.setItem(EDashboardLocalStoreKeys.ACTIVE_TAB, JSON.stringify(index))
    onSelectedIndexChange(index)
  }

  if (isPhone || isTablet) return null

  return (
    <Styled.MainContainer>
      <Styled.Container>
        {selectedIndex === 0 && <Styled.Divider isResonsive={false} />}
        {selectedIndex === 2 && <Styled.Divider isResonsive={true} />}
        {buttons.map(button => (
          <Styled.Button
            key={button.index}
            onClick={() => handleTabClick(button.index)}
            isClicked={buttonColors[button.index]}
            onMouseOver={() => handleMouseEnter(button.index)}
            onMouseOut={() => handleMouseLeave(button.index)}
            isHovered={isHovered[button.index]}
            isTabWidth={button.index}
          >
            <Styled.Text isHovered={isHovered[button.index]} isClicked={buttonColors[button.index]}>
              {button.text}
            </Styled.Text>
          </Styled.Button>
        ))}
      </Styled.Container>
      {features?.careerCoaching && <BookCoachButton />}
    </Styled.MainContainer>
  )
}

export default ActionBanner
