import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'

export const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
`

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Text = styled.h4`
  ${FontWeights.DemiBold};
  ${Typography.M};
  display: flex;
  justify-content: center;
`

export const SubText = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`

export const SubTextRow = styled.p`
  ${Typography.Body};
  display: flex;
  justify-content: center;
  color: ${Colors.Neutral50};
`
