import { i18n } from 'builder/utils/i18n'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { Transition } from 'react-transition-group'
import enhanceWithClickOutside from 'react-click-outside'
import {
  convertToExternal,
  convertToFieldValue,
  convertToInternal,
  guessMonth,
  guessYear,
  isDateHidden,
  isMonthHidden,
  isPresent,
} from 'builder/components/DateRangePicker/utils'

import PickerPanel from 'builder/components/DateRangePicker/PickerPanel'
import { TextField } from 'builder/components/TextField'

import {
  Container,
  Label,
  LabelContent,
  LabelHint,
} from 'builder/components/DateRangePicker/styles'

class AquisitionDatePicker extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.object,
    currentlyLabel: PropTypes.string,
    headingLabel: PropTypes.string,
    showLabelHint: PropTypes.bool,
  }

  static defaultProps = {
    onChange: () => {},
    showLabelHint: true,
  }

  constructor(props) {
    super(props)

    const [endValue, endMonth, endYear] = convertToInternal(props.value.dateUntil, {
      isDatePresent: props.value.isDateUntilPresent,
      isMonthHidden: props.value.isMonthUntilHidden,
    })

    this.state = {
      endValue,
      endMonth,
      endYear,
      isEndPickerOpen: false,
    }
  }

  handleEnter = node => {}

  handleEntering = node => {}

  handleExiting = node => {}

  handleClickOutside() {
    this.setState({ isEndPickerOpen: false })
  }

  openEndPicker = () => {
    this.setState({
      isEndPickerOpen: true,
      isStartPickerOpen: false,
    })
  }

  closeEndPicker = () => this.setState({ isEndPickerOpen: false })

  changeEndYear = endYear => {
    const { endMonth } = this.state
    const endValue = convertToFieldValue(endMonth, endYear)

    this.setState({ endYear, endValue })

    this.props.onChange({
      dateUntil: convertToExternal(endMonth, endYear, {
        isDateHidden: isDateHidden(endValue),
      }),
    })
  }

  updateEndPicker = e => {
    const newValue = e.target.value

    const newState = {
      endValue: newValue,
      endMonth: guessMonth(newValue),
      endYear: guessYear(newValue),
    }
    this.setState(newState)

    this.props.onChange({
      dateUntil: convertToExternal(newState.endMonth, newState.endYear, {
        isDateHidden: isDateHidden(newState.endValue),
      }),
      isDateUntilPresent: isPresent(newState.endValue),
      isMonthUntilHidden: isMonthHidden(newState.endValue),
    })
  }

  handleEndChoose = (month, year) => {
    this.setState({
      endMonth: month,
      endValue: convertToFieldValue(month, year),
      isEndPickerOpen: false,
    })

    this.props.onChange({
      dateUntil: convertToExternal(month, year),
      isMonthUntilHidden: !month,
    })
  }

  handlePresentToggle = value => {
    const { endMonth, endYear } = this.state
    this.setState({
      endValue: convertToFieldValue(endMonth, endYear, {
        isDatePresent: value,
      }),
    })

    this.props.onChange({
      isDateUntilPresent: value,
    })
  }

  handleEndBlur = () => {
    const { endMonth, endYear, endValue } = this.state
    this.setState({
      endValue: convertToFieldValue(endMonth, endYear, { prevValue: endValue }),
    })

    this.props.onChange({
      dateUntil: convertToExternal(endMonth, endYear, {
        isDateHidden: isDateHidden(endValue),
      }),
      isDateUntilPresent: isPresent(endValue),
      isMonthUntilHidden: isMonthHidden(endValue),
    })
  }

  handleTab = () => {
    if (this.state.isEndPickerOpen) this.closeEndPicker()
  }

  handleKeyDown = e => {
    let keyCode = e.keyCode

    // tab
    if (keyCode === 9) {
      this.handleTab()
    }
  }

  render() {
    const { isEndPickerOpen, endValue, endMonth, endYear } = this.state

    const { currentlyLabel, showLabelHint } = this.props

    return (
      <Container onKeyDown={this.handleKeyDown}>
        <Label>
          <LabelContent>{this.props.headingLabel}</LabelContent>
          {showLabelHint && (
            <LabelHint multiline>{i18n.t('builder.date_range_picker.hint')}</LabelHint>
          )}
        </Label>
        <TextField
          name="endDate"
          onFocus={this.openEndPicker}
          onBlur={this.handleEndBlur}
          onChange={this.updateEndPicker}
          value={endValue}
          active={isEndPickerOpen}
          placeholder={i18n.t('builder.date_range_picker.placeholder')}
          autoComplete="off"
        />

        <Transition
          in={isEndPickerOpen}
          timeout={0}
          onEnter={this.handleEnter}
          onEntering={this.handleEntering}
          onExiting={this.handleExiting}
          unmountOnExit
          appear
        >
          <PickerPanel
            month={endMonth}
            year={endYear}
            onChangeYear={this.changeEndYear}
            onChoose={this.handleEndChoose}
            showIsPresent
            isPresent={isPresent(endValue)}
            onIsPresentToggle={this.handlePresentToggle}
            alignRight
            currentlyLabel={currentlyLabel}
            noActiveMonth={isDateHidden(endValue) || isMonthHidden(endValue)}
          />
        </Transition>
      </Container>
    )
  }
}
export default enhanceWithClickOutside(AquisitionDatePicker)
