let instance = null

class AnchorManager {
  constructor() {
    if (!instance) {
      instance = this
    }
    this.anchorCallbacks = new Map()
    this.messagesForAnchors = []
    return instance
  }

  attachAnchor = (id, callback) => {
    this.anchorCallbacks.set(id, callback)
    const message = this.getMessageById(id)
    if (message) callback(message.focus)
  }

  detachAnchor = id => {
    this.anchorCallbacks.delete(id)
  }

  scrollToAnchor = id => {
    const callback = this.anchorCallbacks.get(id)
    if (callback) {
      callback()
    } else {
      this.messagesForAnchors.push({ id, focus: window.focus })
    }
  }

  getMessageById = id => {
    const eltIndex = this.messagesForAnchors.map(item => item.id).indexOf(id)
    const res = this.messagesForAnchors[eltIndex]
    this.messagesForAnchors.splice(eltIndex, 1)
    return res
  }
}

export default new AnchorManager()
