import { Resume } from 'packages/types'
import { SectionNames } from 'builder/modules/resumeEditor'
import { i18n as I18n } from 'builder/utils/i18n'
import WorkExperienceCard from './WorkExperience/WorkExperienceCard'
import { LanguageCard } from './LanguageSkills/LanguageCard'

export function getWorkExperienceSection(resume: Resume | null) {
  if (!resume) return []

  const sections = [
    {
      id: SectionNames.workExperiences,
      customTitle: resume.sectionTitles.workExperiences,
      addButtonText: resume.workExperiences.length
        ? I18n.t('builder.resume_editor.work_experience_section.add_one_more')
        : I18n.t('builder.resume_editor.work_experience_section.add'),
      CardComponent: WorkExperienceCard,
      items: resume.workExperiences,
      sortable: false,
    },
  ]

  return sections
}

export function getLanguageSection(resume: Resume | null) {
  if (!resume) return []

  const sections = [
    {
      id: SectionNames.languages,
      customTitle: resume.sectionTitles.languages,
      addButtonText: resume.languages.length
        ? I18n.t('builder.resume_editor.languages_section.add_one_more')
        : I18n.t('builder.resume_editor.languages_section.add'),
      CardComponent: LanguageCard,
      items: resume.languages,
      sortable: false,
    },
  ]

  return sections
}
