import { Step, StepsData } from 'builder/modules/dashboard'

export const stepperDefaultState: Record<Step, boolean> = {
  [Step.RESUME_CREATION]: false,
  [Step.RESUME_DISTRIBUTION]: false,
  [Step.PERSONALIZED_JOBS]: false,
  [Step.JOB_TRACKER_WISHLIST]: false,
  [Step.APPLY_FOR_YOU]: false,
  [Step.CAREER_PROFILE]: false,
}

export type StepProperties = {
  enabled: boolean
  order: number
}

export type Steps = {
  [K in Step]: StepProperties
}

export type MergedStepData = {
  [K in keyof StepsData]: Steps[K] & StepsData[K] & { id: Step }
}[keyof StepsData]
