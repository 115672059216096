import { useState } from 'react'
import { EventCode, trackInternalEvent } from '@rio/tracking'
import { ContentWrapper, List, ListItem, ShowMore } from './styles'

interface Props {
  label: string
  jobTitle?: string
  jobResponsibilities: string
}

const Content = ({ label, jobTitle, jobResponsibilities }: Props) => {
  const [showMore, setShowMore] = useState(false)
  const initialDisplayCount = 7
  const responsibilities = jobResponsibilities.split('\n')

  return (
    <ContentWrapper>
      <List>
        {responsibilities
          .slice(0, showMore ? responsibilities.length : initialDisplayCount)
          .map((responsibility, index) => (
            <ListItem key={index}>{responsibility}</ListItem>
          ))}
      </List>

      {responsibilities.length > initialDisplayCount && !showMore && (
        <ShowMore
          onClick={() => {
            setShowMore(!showMore)
            trackInternalEvent('click_show_more_responsibilities', {
              job_title: jobTitle,
              label: label as EventCode,
            })
          }}
        >
          <span>...more</span>
        </ShowMore>
      )}
    </ContentWrapper>
  )
}

export default Content
