import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { Interview } from 'builder/modules/interview/types'
import Button from 'builder/components/Button'
import { useInterviewDelete } from 'builder/views/Interview/hooks/useInterviewDelete'
import { Modal } from '../../../Modal/Modal'
import { Bottom, Content, Title } from './styles'

const TRANSLATION = 'builder.interview_prep_view.interview_dashboard.delete_modal'

type RestartModalProps = {
  onClose: () => void
  onDelete: () => void
  interview: Interview
}

export const DeleteModal = ({
  onClose,
  onDelete: onDeleteSuccess,
  interview,
}: RestartModalProps) => {
  const { i18n } = useI18n()

  const { mutate: deleteInterview, status } = useInterviewDelete(onDeleteSuccess)

  const onDelete = () => {
    deleteInterview(interview.id)
    trackInternalEvent('delete_interview', {
      label: 'interview_prep',
    })
  }

  return (
    <Modal onClose={onClose} title={<Title>{i18n.t(`${TRANSLATION}.are_you_sure`)}</Title>}>
      <Content>{i18n.t(`${TRANSLATION}.deletes_interview`)}</Content>
      <Bottom>
        <Button theme="ghost" onClick={onClose}>
          {i18n.t(`${TRANSLATION}.cancel`)}
        </Button>
        <Button onClick={onDelete} isLoading={status === 'loading'}>
          {i18n.t(`${TRANSLATION}.delete`)}
        </Button>
      </Bottom>
    </Modal>
  )
}
