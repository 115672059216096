import Icon24, { Icon20 } from 'builder/components/Icon'
import { CustomAlertType } from 'builder/modules/jobSearch/types'
import { useI18n } from 'builder/hooks/useI18n'
import { useTranslatedFrequenciesString } from '../../hooks/useTranslatedFrequenciesString'
import {
  Container,
  ContainerText,
  SubHead,
  Body,
  TimeIcon,
  EditAltContainer,
  Wrapper,
  Ellipsis,
} from './styles'

interface Props extends CustomAlertType {
  onClick: () => void
}

export const CustomAlert = ({
  id,
  targetRole,
  onlyRemoteJobs,
  location,
  frequency,
  onClick,
}: Props) => {
  const { getTranslatedTextFrequency } = useTranslatedFrequenciesString()
  const { i18n } = useI18n()

  return (
    <Container data-testid={id}>
      <Wrapper>
        <SubHead>
          <Ellipsis>{targetRole}</Ellipsis>
        </SubHead>
        <ContainerText>
          <Body>
            <Icon24.NewLocation />
            <Ellipsis>
              {location}
              {onlyRemoteJobs && location && ', '}
              {onlyRemoteJobs && i18n.t(`builder.job_search.remote`)}
            </Ellipsis>
          </Body>
          <Body>
            <TimeIcon>
              <Icon20.Clock />
            </TimeIcon>
            {frequency && getTranslatedTextFrequency(frequency)}
          </Body>
        </ContainerText>
      </Wrapper>
      <EditAltContainer onClick={onClick}>
        <Icon24.EditAlt />
      </EditAltContainer>
    </Container>
  )
}
