import styled, { css } from 'styled-components'
import Icon24 from 'builder/components/Icon'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div<{ $isFreeUser: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width: 100vw;
  padding: 0 40px;
  margin: 40px auto;
  height: calc(100vh - 160px);
  ${({ $isFreeUser }) =>
    $isFreeUser &&
    css`
      overflow: hidden;
    `}
`

export const SpinnerContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
`

export const Body = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(335px, 358px));
  justify-content: center;
  grid-gap: 32px;

  ${Media.Desktop`
    grid-gap: 32px;
  `};

  ${Media.Tablet`
  grid-gap: 24px;
  grid-template-columns: repeat(2, minmax(335px, 358px));;

  `};

  ${Media.Phone`
  grid-gap: 12px;
  grid-template-columns: repeat(1, minmax(335px, 358px));;

  `};
`

export const LeftArrowIcon = styled(Icon24.ChevronLeft)`
  padding-left: 3px;
`

export const BackButton = styled.div`
  position: absolute;
  ${Typography.Caption};
  ${FontWeights.DemiBold};
  display: inline-flex;
  padding: 4px 16px 4px 8px;
  align-items: center;
  border-radius: 20px;
  background: ${Colors.Neutral10};
  align-items: center;
  color: ${Colors.Neutral50};
  height: 32px;
  ${Media.Hover`
    &:hover {
      cursor: pointer;
      background: ${Colors.Blue10};
      color: ${Colors.Blue50}
    }
  `};

  & path {
    fill: currentColor;
  }

  ${Media.Phone`
    display: flex;
    padding: 16px 8px;
    height: 24px;
  `};
`
export const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  ${Media.Phone`
    flex-direction: column;
    align-items: center;
  `};
`

export const Title = styled.span`
  ${Typography.M};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
`

export const Preposition = styled.span`
  ${Typography.Subhead};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
  padding-right: 8px;
  padding-left: 12px;
  ${Media.Phone`
    padding-left: 0;
  `};
`

export const RoleContainer = styled.div<{ $isFreePremium: boolean }>`
  color: ${Colors.Blue50};
  ${Typography.Subhead};
  ${FontWeights.Medium};
  cursor: pointer;
  display: flex;

  &:hover {
    color: ${Colors.Blue60};
    ${({ $isFreePremium }) => !$isFreePremium && `cursor: pointer;`}
  }
`

export const Role = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};
  color: ${Colors.Blue50};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  &:hover {
    color: ${Colors.Blue60};
  }
`

export const Subtitle = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
  text-align: center;
  padding-top: 12px;
  padding-bottom: 40px;
  ${Media.Phone`
    padding-top: 8px;
    padding-bottom: 32px;
  `};
`

export const PrepositionRoleContainer = styled.div`
  display: inline-flex;
  align-items: center;
  ${Media.Phone`
    display: flex;
  `};
`

export const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
`
