import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { IFetchEventData, actions, initialState, selectors } from '../events'
import { baseClient } from '../apiClient'
import { FetchStatuses } from '../constants'

function* fetchAllEventsSaga(action: ReturnType<typeof actions.fetchAllEvents>) {
  try {
    const allEvents: IFetchEventData = yield select(selectors.allEvents)
    yield put(actions.setAllEvents({ ...allEvents, status: FetchStatuses.loading }))
    const url = '/premier-virtual/events'
    const { data } = yield call(baseClient.get, url, {
      params: action.payload,
    })
    const { page_details: pageDetails, event_details: events } = data

    const result: IFetchEventData = {
      ...allEvents,
      status: FetchStatuses.loaded,
      data: {
        events: pageDetails.currentPage > 1 ? [...allEvents.data.events, ...events] : events,
        page: pageDetails.currentPage,
        lastPage: pageDetails.lastPage,
      },
    }
    yield put(actions.setAllEvents(result))
  } catch (error) {
    yield put(
      actions.setAllEvents({
        data: initialState.allEvents.data,
        status: FetchStatuses.failed,
        error: error as string,
      }),
    )
  }
}

function* fetchMyUpcomingEventsSaga(action: ReturnType<typeof actions.fetchMyUpcomingEvents>) {
  try {
    const myUpcomingEvents: IFetchEventData = yield select(selectors.myUpcomingEvents)
    yield put(actions.setMyUpcomingEvents({ ...myUpcomingEvents, status: FetchStatuses.loading }))
    const url = '/premier-virtual/events/registered-pv-events'
    const { data } = yield call(baseClient.get, url, {
      params: action.payload,
    })

    const { page_details: pageDetails, event_details: events } = data

    const result: IFetchEventData = {
      ...myUpcomingEvents,
      status: FetchStatuses.loaded,
      data: {
        events: pageDetails.page > 1 ? [...myUpcomingEvents.data.events, ...events] : events,
        page: pageDetails.currentPage,
        lastPage: pageDetails.lastPage,
      },
    }
    yield put(actions.setMyUpcomingEvents(result))
  } catch (error) {
    yield put(
      actions.setMyUpcomingEvents({
        data: initialState.myUpcomingEvents.data,
        status: FetchStatuses.failed,
        error: error as string,
      }),
    )
  }
}

function* fetchMyEndedEventsSaga(action: ReturnType<typeof actions.fetchMyEndedEvents>) {
  try {
    const myEndedEvents: IFetchEventData = yield select(selectors.myEndedEvents)
    yield put(actions.setMyEndedEvents({ ...myEndedEvents, status: FetchStatuses.loading }))
    const url = '/premier-virtual/events/registered-pv-events'
    const { data } = yield call(baseClient.get, url, {
      params: action.payload,
    })

    const { page_details: pageDetails, event_details: events } = data
    const result: IFetchEventData = {
      ...myEndedEvents,
      status: FetchStatuses.loaded,
      data: {
        events: pageDetails.page > 1 ? [...myEndedEvents.data.events, ...events] : events,
        page: pageDetails.currentPage,
        lastPage: pageDetails.lastPage,
      },
    }
    yield put(actions.setMyEndedEvents(result))
  } catch (error) {
    yield put(
      actions.setMyEndedEvents({
        data: initialState.myEndedEvents.data,
        status: FetchStatuses.failed,
        error: error as string,
      }),
    )
  }
}

export const sagas = function* saga() {
  yield all([
    takeLatest(actions.fetchMyUpcomingEvents, fetchMyUpcomingEventsSaga),
    takeLatest(actions.fetchAllEvents, fetchAllEventsSaga),
    takeLatest(actions.fetchMyEndedEvents, fetchMyEndedEventsSaga),
  ])
}
