import styled, { keyframes } from 'styled-components'

import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100%;
  gap: 32px;
  flex-direction: column;
  justify-content: space-between;

  ${Media.Phone`
    gap: 0;
  `}
`

export const ContainerBody = styled.div`
  display: flex;
  width: 100%;
  gap: 32px;
  flex-direction: column;

  ${Media.Phone`
    gap: 0;
  `}
`

export const Content = styled.div`
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 64px;
  position: relative;

  ${Media.Tablet`
    flex-direction: column;
    gap: 13px;
  `}

  ${Media.Phone`
    flex-direction: column;
    gap: 0;
  `}
`

export const ResumeSelectedContent = styled.div`
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 64px;
  position: relative;

  ${Media.Tablet`
    flex-direction: column;
    gap: 13px;
    width: 416px;
    padding-bottom: 52px;
  `}

  ${Media.Phone`
    flex-direction: column;
    width: 100%;
    max-width: 416px;
    gap: 0;
  `}
`

export const Header = styled.div`
  width: 100%;
  margin-top: 96px;
  text-align: center;
  gap: 8px;

  ${Media.Phone`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px
  `}
`

export const Title = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral90};
  text-align: center;
  padding-bottom: 8px;

  ${Media.Phone`
    width: 335px;
  `}
`

export const SubTitle = styled.div`
  color: ${Colors.Neutral50};
  text-align: center;
  margin: 0 auto;
  width: 416px;

  ${Media.Phone`
    width: 335px;
  `}
`

export const EmailId = styled.div`
  color: ${Colors.Neutral90};
  ${Typography.Body};
  ${FontWeights.DemiBold};
`

export const LeftContent = styled.div`
  width: 416px;
  height: auto;
  display: flex;
  position: relative;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${Media.Tablet`
    width: 504px;
  `}

  ${Media.Phone`
    width: 385px;
    height: 100%;
  `}
`

export const UploadTitle = styled.div`
  ${FontWeights.Medium};
  width: 416px;
  text-align: center;
  color: ${Colors.Neutral90};
  padding-top: 32px;
  margin-bottom: 24px;

  ${Media.Tablet`
    width: 504px;
  `}

  ${Media.Phone`
    padding-top: 24px;
    width: 335px;
  `}
`

export const MiddleContent = styled.div`
  width: 2%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: flex-start;

  ${Media.Tablet`
    width: 100%;
    flex-direction: row;
    gap: 13px;
    margin-top: 31px;
  `}
`

export const VerticalBreak = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  gap: 10px;
  justify-content: center;
  margin-top: 72px;

  ${Media.Tablet`
    flex-direction: row;    
    margin-top: 0;
  `}
`

export const BreakText = styled.div`
  ${Typography.Tiny}
  ${FontWeights.DemiBold}
  text-transform: uppercase;
  color: ${Colors.Neutral50};
`

export const Line = styled.div`
  width: 1px;
  height: 120px;
  background-color: ${Colors.Neutral15};

  ${Media.Tablet`
    width: 211px;
    height: 1px;  
  `}

  ${Media.Phone`
    width:  139px;
    height: 1px;  
  `}
`

export const RightContent = styled.div`
  width: 416px;

  ${Media.Tablet`
    flex-direction: row;
    width: 504px;
    height: auto;
  `}

  ${Media.Phone`
    width: 335px;
    height: auto;
    flex-direction: row;
  `}
`

export const ParseText = styled.div`
  ${Typography.Subhead}
  ${FontWeights.DemiBold}
  animation: 1s ${fadeIn} ease-out;
`

export const Anchor = styled.a`
  position: absolute;
  top: 19px;
  left: 32px;
  z-index: 1;

  ${Media.Tablet`
    top: 20px;
    left: 20px;
  `}
`

export const Logo = styled.img`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  width: 130px;
  height: 32px;
`

export const TopContent = styled.div`
  gap: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  ${Media.Phone`
    width: 100%;
  `}
`

export const Parsing = styled.div`
  gap: 88px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  ${Media.Phone`
    margin: 40px 0;
  `}
`

export const CloseButton = styled.button`
  position: absolute;
  top: 19px;
  right: 32px;
  z-index: 1;
  display: flex;
  color: ${Colors.Neutral40};
  align-items: flex-end;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;

  &:hover,
  &:focus-visible {
    color: ${Colors.Blue50};
  }

  ${Media.Tablet`
    top: 20px;
    right: 20px;
  `}
`

export const HeaderContainer = styled.div`
  position: relative;
`
