import styled from 'styled-components'
import Media from 'builder/styles/media'

export const AnimationContainer = styled.div<{ $isOpen?: boolean }>`
  position: absolute;

  ${props => Media.Tablet`
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -35px;
    left: ${props.$isOpen ? '20px' : '-33px'};
  `};

  ${props => Media.Phone`
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -34px;
    left: ${props.$isOpen ? '10px' : '-34px'};
  `};
`
