import { useCallback, useContext, useMemo } from 'react'
import * as React from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { TunerContext } from '../../contexts/TunerContext'
import { Task, TunerContextValue } from '../../types'
import {
  TaskContainer,
  TaskHeader,
  TaskTitle,
  TaskText,
  TaskContent,
  TaskScore,
  TaskSide,
  TaskArrowIcon,
  TaskSuccessIcon,
} from './styles'

interface Props {
  task: Task
}

export const TunerBannerTask = ({ task }: Props) => {
  const { resume, score } = useContext(TunerContext) as TunerContextValue
  const { isPhone } = useMediaQueries()
  const { i18n } = useI18n()

  const scoreText = useMemo(() => {
    const points = task.score
    if (isPhone) return i18n.t(`builder.resume_tuner.banner.task_score_short`, { points })
    return i18n.t(`builder.resume_tuner.banner.task_score`, { points })
  }, [i18n, isPhone, task.score])

  // Make link inert if the task is done
  const attrs: React.HTMLAttributes<HTMLAnchorElement> = useMemo(() => {
    if (!task.done) return {}
    return { tabIndex: -1, style: { pointerEvents: 'none' } }
  }, [task.done])

  // Track clicks
  const handleClick = useCallback(() => {
    trackInternalEvent('click_tuner_banner', {
      element: `${task.id}_card`,
      resume_id: resume.id,
      tuner_score: score,
    })
  }, [resume.id, task.id, score])

  return (
    <TaskContainer
      {...attrs}
      to={`/resumes/${resume.id}/edit?tuner_tips=${task.id}`}
      onClick={handleClick}
    >
      <TaskContent>
        <TaskHeader>
          <TaskTitle done={task.done}>{task.title}</TaskTitle>
          <TaskScore done={task.done}>{scoreText}</TaskScore>
        </TaskHeader>
        <TaskText>{task.text}</TaskText>
      </TaskContent>
      <TaskSide>{task.done ? <TaskSuccessIcon /> : <TaskArrowIcon />}</TaskSide>
    </TaskContainer>
  )
}
