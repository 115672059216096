import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useI18n } from 'builder/hooks/useI18n'
import { actions as jobSearchActions } from 'builder/modules/jobSearch'

import ImageSrc from '../assets/error-image.png'
import {
  Container,
  ContinueButton,
  Image,
  ImageContainer,
  TextContainer,
  TextHeader,
  TextSubHeader,
} from './styles'

const TRANSLATION = 'builder.auto_apply.resume_distribution.rd_completed.error'

export const ErrorState = () => {
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const onClick = () => {
    dispatch(jobSearchActions.startAutoApplyBubble({ enableFirstPage: true }))

    navigate('/job-search')
  }

  return (
    <Container>
      <ImageContainer>
        <Image src={ImageSrc} />
      </ImageContainer>

      <TextContainer>
        <TextHeader>{i18n.t(`${TRANSLATION}.header`)}</TextHeader>
        <TextSubHeader>{i18n.t(`${TRANSLATION}.subheader`)}</TextSubHeader>
      </TextContainer>

      <ContinueButton onClick={onClick}>{i18n.t(`${TRANSLATION}.button`)}</ContinueButton>
    </Container>
  )
}
