import React, { useEffect, useRef } from 'react'
import { TooltipRenderProps } from 'react-joyride'

import { useI18n } from 'builder/hooks/useI18n'

import Button, { ButtonSize } from 'builder/components/Button'
import { Progress } from '../Progress/Progress'
import { SkipButton } from '../SkipButton/SkipButton'
import {
  Actions,
  Steps,
  TooltipBody,
  TooltipContent,
  TooltipFooter,
  TooltipTitle,
  TooltipSubHeader,
} from './styles'
interface IProps {
  onClick: (event: React.MouseEvent<HTMLElement>) => void
  onSkip: (event: React.MouseEvent<HTMLElement>) => void
}
export const CustomTooltip: React.FC<TooltipRenderProps & IProps> = ({
  step,
  size,
  index,
  onSkip,
  tooltipProps,
  onClick,
}) => {
  const { i18n } = useI18n()
  const divRef = useRef(document.createElement('div'))

  useEffect(() => {
    const div = divRef.current
    document.body.appendChild(div)
    return () => div.remove()
  }, [])

  return (
    <>
      <TooltipBody
        {...tooltipProps}
        style={{ ...step.styles.tooltipContainer, textAlign: 'start' }}
      >
        <TooltipContent>
          {step.title && <TooltipTitle>{step.title}</TooltipTitle>}
          <TooltipSubHeader>{step.content}</TooltipSubHeader>
        </TooltipContent>
        <TooltipFooter>
          <Steps>
            {index + 1} {i18n.t('builder.job_search.onboarding.tooltip.of_preposition')} {size}
          </Steps>

          <Actions>
            <Progress doneStep={index + 1} totalSteps={size} />
            {step?.data?.nextButtonLabel && (
              <Button size={ButtonSize.small} onClick={onClick}>
                {step?.data?.nextButtonLabel}
              </Button>
            )}
          </Actions>
        </TooltipFooter>
      </TooltipBody>
      {!step?.data?.disableSkipButton && <SkipButton onSkip={onSkip} />}
    </>
  )
}
