import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import Icon from 'builder/components/Icon'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 36px 0;

  ${Media.IphoneSE`
    width: fit-content;
  `}
`

export const LoadingText = styled.div`
  ${Typography.M}
  ${FontWeights.DemiBold}

  display: flex;
  justify-content: center;
  max-width: 429px;

  color: ${Colors.Neutral90};
  margin-bottom: 12px;
  text-align: center;

  ${Media.Phone`
    max-width: 335px;
  `}

  ${Media.IphoneSE`
    ${Typography.S};
    max-width: 280px;
  `}
`

export const SubLoadingText = styled.div`
  ${Typography.Body}
  ${FontWeights.Regular}

  display: flex;
  max-width: 416px;
  text-align: center;
  justify-content: center;
  color: ${Colors.Neutral50};

  ${Media.Phone`
    max-width: 335px;
  `}

  ${Media.IphoneSE`
    ${Typography.Caption}
    width: 280px;
  `}
`

export const LoadingImage = styled.img`
  width: 553px;
  height: 421px;
  margin-top: 16px;

  ${Media.Phone`
    width: 375px;
    height: 285px;
    margin-top: 56px;
  `}

  ${Media.IphoneSE`
    width: 320px;
    height: 243px;
    margin-top: 24px;
  `}
`

export const CarrerText = styled.div`
  ${Typography.Body}
  ${FontWeights.Regular}

  width: 393px;
  margin-bottom: 24px;
  text-align: center;

  ${Media.Phone`
    margin-bottom: 12px;
  `}
`

export const ButtonTrailer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 20px 12px 24px;
  gap: 10px;
  width: auto;
  border: 1px solid ${Colors.Neutral20};
  border-radius: 4px;
  cursor: pointer;
  transition: 0.2s;
  ${FontWeights.DemiBold}

  &:hover {
    border: 1px solid ${Colors.Blue50};
    color: ${Colors.Blue50};
  }
`

export const TrailerImg = styled.img`
  width: 14px;
  height: 16px;
  left: 6px;
  top: 4px;
  margin-left: 12px;
`

export const ModalContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 650px;
  height: 500px;
  border-radius: 6px;
  user-select: none;
  padding-top: 70px;
`

export const ModalClose = styled.div`
  position: relative;
  z-index: 5;
  top: -214px;
  right: 34px;
  padding: 6px;
  display: flex;
  border-radius: 50%;
  height: fit-content;
  color: ${Colors.White};
  background-color: ${Colors.Neutral80};
  transition: opacity 0.15s;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  ${Media.Phone`
    position: absolute;
    top: 25px;
    right: 0px;
  `}
`

export const ModalIframe = styled.iframe`
  height: 365px;
  width: 615px;
  border-radius: 12px;

  ${Media.Phone`
    height: 95%;
    width: 100%;  
  `}
`

export const ModalCloseIcon = styled(Icon.Close)``
