import EditorField from 'builder/components/EditorField'
import { TextField } from 'builder/components/TextField'
import { useLanguageCardContext } from '../../ExpandableCardContext'
import { useShokumuTranslations } from '../../useShokumuTranslations'

const Certificate = (): JSX.Element => {
  const { item, onCardValueChange } = useLanguageCardContext()

  const updateCertificate = (event: React.ChangeEvent<HTMLInputElement>) => {
    onCardValueChange({ [event.target.name]: event.target.value }, true)
  }

  const { i18n } = useShokumuTranslations()
  const label = i18n('languages.certificate')

  return (
    <EditorField>
      <TextField
        label={label}
        name="certificate"
        value={item.certificate || ''}
        onChange={updateCertificate}
      />
    </EditorField>
  )
}
export default Certificate
