import { SkeletonRows } from '../Skeletons'
import * as CommonStyles from '../../styles'
import * as Styles from './styles'

type Props = {
  description: string | null
  isSkeleton: boolean
}

export const Description = ({ description, isSkeleton = false }: Props) => {
  if (!description && !isSkeleton) return null

  return (
    <Styles.Wrapper>
      <CommonStyles.Title>Role Description</CommonStyles.Title>
      {isSkeleton ? <SkeletonRows /> : <Styles.Text>{description}</Styles.Text>}
    </Styles.Wrapper>
  )
}
