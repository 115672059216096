import styled from 'styled-components'
import Colors, { ToneNames } from 'builder/styles/colors'
import { Typography, FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const TimerContainer = styled.div<{ colorTone: ToneNames }>`
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${Colors.White};
  border-radius: 12px;
  height: 88px;
  width: 320px;
  border: ${({ colorTone }) => `1px solid ${Colors[`${colorTone}20`]}`};
  cursor: pointer;

  &:hover {
    border: ${({ colorTone }) => `1px solid ${Colors[`${colorTone}50`]}`};
  }

  ${Media.Phone`
    margin: 0px -8px -8px;
  `}
`

export const CardContainer = styled.div<{ color: ToneNames }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${({ color }) => Colors[`${color}10`]};
  padding: 16px;
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    background-color: ${({ color }) => Colors[`${color}20`]};
  }

  &:hover ${TimerContainer} {
    border: ${({ color }) => `1px solid ${Colors[`${color}50`]}`};
  }
`

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 220px;
  margin-bottom: 24px;
`

export const IconContainer = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 8px;
`

export const IconImg = styled.div<{ url: string }>`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${props => `url(${props.url})`};
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
`

export const Title = styled.p<{ color: ToneNames }>`
  ${Typography.Subhead};
  ${FontWeights.Medium};
  color: ${({ color }) => Colors[`${color}90`]};

  &:hover {
    color: ${({ color }) => Colors[`${color}100`]};
  }
`

export const LocationTime = styled.p<{ color: ToneNames }>`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${({ color }) => Colors[`${color}90`]};
`

export const TimerTitle = styled.p`
  ${Typography.Caption};
  ${FontWeights.Medium};
  color: ${Colors.Neutral40};
  text-align: center;
  margin-bottom: 4px;
`

export const TimeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
`

export const TimeLabelGroup = styled.div`
  display: flex;
`

export const TimeEntityContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 48px;
`

export const TimeDotsContainer = styled.p`
  height: 28px;
  width: 16px;
  ${Typography.Subhead};
  ${FontWeights.Regular};
  color: ${Colors.Neutral90};
  text-align: center;
  align-self: center;
`

export const Time = styled.p`
  ${Typography.Subhead};
  ${FontWeights.Regular};
  color: ${Colors.Neutral90};
`

export const TimeLabel = styled.p`
  ${Typography.Caption};
  ${FontWeights.Medium};
  color: ${Colors.Neutral40};
  text-transform: uppercase;
`
