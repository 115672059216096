import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { CSSTransition } from 'react-transition-group'
import { useEffect, useState, useCallback } from 'react'

import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { actions, selectors, Label } from 'builder/modules/careerPlanning'

import { Wrapper } from './styles'
import TopContent from './components/TopContent'
import MainContent from './components/MainContent/MainContent'

const TopBar = () => {
  const dispatch = useDispatch()
  const { isPhone } = useMediaQueries()
  const [sectionName, setSectionName] = useState('')

  const step = useTypedSelector(selectors.step)
  const headers = useTypedSelector(selectors.headers)
  const progress = useTypedSelector(selectors.progress)
  const showFilters = useTypedSelector(selectors.showFilters)
  const isTopBarOpen = useTypedSelector(selectors.isTopBarOpen)
  const secondLevelId = useTypedSelector(selectors.secondLevelId)
  const showAnimation = useTypedSelector(selectors.showAnimation)
  const filterStatus = useTypedSelector(selectors.filterOptions).find(option => option.isActive)
    ?.status as string

  useEffect(() => {
    if (headers.length) {
      const mainIndex = headers.findIndex(header => header.id === step)
      const secondLevel = headers[mainIndex].secondLevel
      const secondaryIndex = secondLevel.findIndex(subHeader => subHeader.id === secondLevelId)
      const sectionNameStr = secondLevel[secondaryIndex]?.title
      setSectionName(sectionNameStr)
    }
  }, [headers, step, secondLevelId])

  const handleClick = useCallback(() => {
    trackInternalEvent('click_on_navigation_bar', Label)
    dispatch(actions.setIsTopBarOpen(!isTopBarOpen))
  }, [dispatch, isTopBarOpen])

  const handleFilters = useCallback(() => {
    trackInternalEvent('click_filter_button', {
      career_plan_name: 'execute_a_job_search',
      ...Label,
    })

    dispatch(actions.setShowFilters(!showFilters))
  }, [dispatch, showFilters])

  return (
    <Wrapper>
      <TopContent
        score={progress}
        name={sectionName}
        open={isTopBarOpen}
        animate={showAnimation}
        handleClick={handleClick}
        handleFilters={handleFilters}
        filterStatus={filterStatus}
        showFilters={showFilters}
      />
      <CSSTransition
        appear
        mountOnEnter
        unmountOnExit
        timeout={300}
        in={isTopBarOpen}
        classNames="menu-transition"
      >
        <MainContent
          score={progress}
          isPhone={isPhone}
          open={isTopBarOpen}
          handleClick={handleClick}
        />
      </CSSTransition>
    </Wrapper>
  )
}

export default TopBar
