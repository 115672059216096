import { SectionNames } from './sectionNames'

export const STEP_NAMES = {
  details: SectionNames.details,
  profile: SectionNames.profile,
  workExperiences: SectionNames.workExperiences,
  educations: SectionNames.educations,
  socialProfiles: SectionNames.socialProfiles,
  skills: SectionNames.skills,
  additional: 'additional',
} as const

export const steps = [
  {
    id: STEP_NAMES.details,
    translationKey: 'builder.resume_editor.personal_details',
    simpleFieldNames: [
      'position',
      'avatar.originalUrl',
      'firstName',
      'lastName',
      'email',
      'phoneNumber',
      'countryName',
      'city',
      'address',
      'postalCode',
      'drivingLicense',
      'nationality',
      'birthPlace',
      'birthDate',
    ],
  },
  {
    id: STEP_NAMES.workExperiences,
    translationKey: 'builder.resume_editor.work_experience_section.title',
    cardFieldNames: ['title', 'employer', 'dateFrom', 'dateUntil', 'city', 'description'],
  },
  {
    id: STEP_NAMES.educations,
    translationKey: 'builder.resume_editor.educations_section.title',
    cardFieldNames: ['school', 'degree', 'dateFrom', 'dateUntil', 'city', 'description'],
  },
  {
    id: STEP_NAMES.skills,
    translationKey: 'builder.resume_editor.skills_section.title',
    cardFieldNames: ['skill'],
  },
  {
    id: STEP_NAMES.profile,
    translationKey: 'builder.resume_editor.professional_summary.title',
    simpleFieldNames: ['profile'],
  },
]
