import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

export const mainDesktopPadding = '8px'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${Colors.Neutral70};
  position: relative;
  overflow: hidden;
`
export const Wrapper = styled.div`
  display: flex;
  padding: ${mainDesktopPadding} ${mainDesktopPadding} 0;
  width: -webkit-fill-available;
  position: absolute;
  top: 64px;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  gap: 16px;

  ${Media.Tablet`
    padding: ${mainDesktopPadding} 16px;
  `};

  ${Media.Phone`
    padding: 20px 16px;
  `};

  @media (min-width: 2560px) {
    margin: 0 80px;
  }

  @media (min-width: 3840px) {
    margin: 0 280px;
  }
`
