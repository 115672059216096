import { RefObject, useCallback, useContext, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as userSelectors } from 'builder/modules/user'
import { useI18n } from 'builder/hooks/useI18n'
import { useEscapeHandler } from 'builder/hooks/useEscapeHandler'
import { RichTextAreaContext } from 'builder/context/RichTextAreaContext'
import { actions, selectors } from 'builder/modules/resumeEditor'
import { useRemainingAiProfileAttempts } from 'builder/hooks/useRemainingAiProfileAttempts'
import { suggestionScopeToSectionName } from 'builder/components/RichTextArea'
import { useClickOutsideExcept } from 'builder/hooks/useClickOutsideExcept'
import { Props } from '../types'
import { DIALOG_ANALYTICS_LABEL } from '../constants'

const useAIProfileSummaryResult = (
  container: RefObject<HTMLElement>,
  suggestionRef: RefObject<HTMLElement>,
  props: Props,
) => {
  const {
    replacePhrase,
    phrasesOpenerRef,
    editorContainerRef,
    suggestionConfig: { scope },
  } = props
  const isPremiumUser = useTypedSelector(userSelectors.premiumAccess)
  const { summary: generatedContent } = useTypedSelector(selectors.aiProfile)
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const [isAnimationActive, setIsAnimationActive] = useState(false)
  const isAIProfileSummaryResultOpen = useTypedSelector(selectors.isAIProfileSummaryResultOpen)
  const { toggleAiProfileSummaryResult } = useContext(RichTextAreaContext)
  const remainingAIGenerationAttempts = useRemainingAiProfileAttempts()

  const attemptLeftText = useMemo(() => {
    if (remainingAIGenerationAttempts !== Infinity) {
      return i18n.t('builder.ai_profile_summary.rephrasing_modal.attempts_left', {
        attempts_left: remainingAIGenerationAttempts,
      })
    }
  }, [i18n, remainingAIGenerationAttempts])

  useClickOutsideExcept(container, [phrasesOpenerRef, editorContainerRef], () => {
    if (isAIProfileSummaryResultOpen) {
      dispatch(actions.setIsAIProfileSummaryResultOpen({ status: false }))
      dispatch(actions.setAIProfileResultContentAnimation(false))
    }
  })

  useEscapeHandler(() => dispatch(actions.setIsAIProfileSummaryResultOpen({ status: false })))

  const handleClose = useCallback(() => {
    dispatch(actions.resetAIProfileSummaryFlow())
    toggleAiProfileSummaryResult({ isOpen: false })
    trackInternalEvent('click_cross', {
      label: DIALOG_ANALYTICS_LABEL,
      section: suggestionScopeToSectionName(scope),
    })
  }, [dispatch, scope, toggleAiProfileSummaryResult])

  const handleRetry = useCallback(() => {
    if (remainingAIGenerationAttempts > 0) {
      dispatch(actions.setIsAIProfileSummaryResultOpen({ status: false }))
      dispatch(actions.setAIProfileResultContentAnimation(true))
      dispatch(actions.resetSummaryStatus())
      dispatch(actions.generateResumeAiSummary())
    } else {
      dispatch(actions.toggleAIProfileSummaryPaywall())
    }
    trackInternalEvent('click_retry', {
      label: DIALOG_ANALYTICS_LABEL,
      section: suggestionScopeToSectionName(scope),
    })
  }, [dispatch, remainingAIGenerationAttempts, scope])

  const handleUse = useCallback(() => {
    if (generatedContent) {
      replacePhrase(generatedContent)
      dispatch(actions.resetAIProfileSummaryFlow())
      dispatch(actions.setIsAIProfileSummaryResultOpen({ status: false }))
      trackInternalEvent('click_use', {
        label: DIALOG_ANALYTICS_LABEL,
        section: suggestionScopeToSectionName(scope),
      })
    }
  }, [replacePhrase, dispatch, generatedContent, scope])

  const animateContent = useCallback(() => {
    if (suggestionRef.current && generatedContent) {
      suggestionRef.current.innerText = ''
      const value =
        generatedContent?.replace(/<\/p><p><br><\/p><p>/g, '\n\n').replace(/<[^>]*>/g, '') ?? ''
      setIsAnimationActive(true)
      for (let i = 0, l = value.length; i < l; i++) {
        setTimeout(
          index => {
            if (suggestionRef.current) {
              suggestionRef.current.innerText = value.substring(0, index + 1)
              if (index + 1 >= l) {
                setIsAnimationActive(false)
              }
            }
          },
          i * 10,
          i,
        )
      }
    }
  }, [generatedContent, suggestionRef])

  return {
    generatedContent,
    attemptLeftText,
    isPremiumUser,
    isAnimationActive,
    handleRetry,
    handleClose,
    animateContent,
    handleUse,
    remainingAIGenerationAttempts,
  }
}

export default useAIProfileSummaryResult
