import { ToneNames } from 'builder/styles/colors'
import { useCountdown } from 'builder/hooks/useCountdown'
import {
  Time,
  TimeContainer,
  TimeDotsContainer,
  TimeEntityContainer,
  TimeLabel,
  TimeLabelGroup,
  TimerContainer,
  TimerTitle,
} from '../styles'
import { useCallback } from 'react'

interface ITimerProps {
  dateTime: Date
  colorTone: ToneNames
  isStarted: boolean
}

export const Timer = ({ dateTime, colorTone, isStarted }: ITimerProps) => {
  const timeLeft = useCountdown(dateTime.toString())
  const getTimeValue = useCallback(
    (value: number) => (value.toString().length < 2 ? '0' + value : value > 99 ? '99+' : value),
    [],
  )
  return (
    <TimerContainer colorTone={colorTone}>
      <TimerTitle>{isStarted ? 'Events ends' : 'Event will start in'}</TimerTitle>
      <TimeContainer>
        {timeLeft.map((entity, i: number) => (
          <TimeLabelGroup key={entity.key}>
            <TimeEntityContainer>
              <Time>{getTimeValue(entity.value)}</Time>
              <TimeLabel>
                {entity.key.length > 4
                  ? entity.key === 'hours'
                    ? 'hrs.'
                    : entity.key.slice(0, 3) + 's.'
                  : entity.key}
              </TimeLabel>
            </TimeEntityContainer>
            {i + 1 < Object.entries(timeLeft).length && <TimeDotsContainer>:</TimeDotsContainer>}
          </TimeLabelGroup>
        ))}
      </TimeContainer>
    </TimerContainer>
  )
}
