import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  width: 544px;
  background-color: ${Colors.White};
  border-radius: 16px;
  z-index: 999;

  ${Media.Phone`
    width: 343px;
  `};
`

export const Visual = styled.img`
  width: 100%;
  border-radius: 16px 16px 0 0;
`

export const VisualVideo = styled.video`
  width: 100%;
  border-radius: 16px 16px 0 0;
`

export const Close = styled.div`
  position: absolute;
  width: 28px;
  height: 28px;
  top: 32px;
  right: 32px;
  z-index: 10;
  border-radius: 32px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.Neutral30};
  cursor: pointer;
  transition: color 0.1s;
  background-color: ${Colors.White};

  &:hover {
    color: ${Colors.Blue50};
  }

  ${Media.Phone`
    top: 20px;
    right: 20px;
    padding: 2px;
  `};
`

export const Header = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: flex-start;

  ${Media.Phone`
    gap: 8px;
  `};
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;

  ${Media.Phone`
    gap: 8px;
  `};
`

export const DescriptionSection = styled.div`
  display: flex;
  gap: 32px;
  flex-direction: column;
  padding: 32px;

  ${Media.Phone`
    gap: 16px;
    padding: 20px;
  `};
`
