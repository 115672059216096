import { Component } from 'react'
import PropTypes from 'prop-types'
import { i18n } from 'builder/utils/i18n'
import DialogModal from 'builder/components/DialogModal'
import CardTitle from './CardTitle'
import Foldable from 'builder/components/Foldable'
import CardModal from './CardModal'
import { withMediaQueries } from 'builder/components/MediaQueries'
import { getCardHighlightId } from 'builder/modules/resumeEditor'
import { Wrapper, Container } from './styles'

class Base extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    item: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    sectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    sectionTitle: PropTypes.string,
    mediaQueries: PropTypes.object.isRequired,
    onMoveUp: PropTypes.func,
    onMoveDown: PropTypes.func,
    onDelete: PropTypes.func,
    onToggle: PropTypes.func,
    isOpen: PropTypes.bool,
    isDragging: PropTypes.bool,
    isCustom: PropTypes.bool,
    disableMove: PropTypes.bool,
    foldableDuration: PropTypes.number,
  }

  state = {
    isModalOpen: false,
  }

  componentDidMount() {}

  handleToggle = () => {
    const { onToggle, item } = this.props
    onToggle(item.cid || item.id)
  }

  handleMoveUp = e => {
    if (e) e.stopPropagation()
    const { onMoveUp, item, sectionId, isCustom } = this.props
    onMoveUp(item.id, sectionId, isCustom)
  }

  handleMoveDown = e => {
    if (e) e.stopPropagation()
    const { onMoveDown, item, sectionId, isCustom } = this.props
    onMoveDown(item.id, sectionId, isCustom)
  }

  showModal = e => {
    if (e) e.stopPropagation()
    this.setState({ isModalOpen: true })
  }

  handleModalConfirm = () => {
    this.setState({ isModalOpen: false })
    const { onDelete, item, sectionId, isCustom } = this.props
    onDelete(item.id, sectionId, isCustom)
  }

  handleModalCancel = () => {
    this.setState({ isModalOpen: false })
  }

  render() {
    const {
      title,
      subtitle,
      sectionId,
      sectionTitle,
      isOpen,
      isDragging,
      disableMove,
      mediaQueries,
      foldableDuration,
      item,
    } = this.props

    return (
      <Wrapper>
        <Container
          isDragging={isDragging}
          data-highlight-id={getCardHighlightId(sectionId, item.cid || item.id)}
        >
          <DialogModal
            title={i18n.t('builder.resume_editor.delete_entry')}
            text={i18n.t('builder.resume_editor.sure_to_delete')}
            primaryButtonText={i18n.t('builder.resume_editor.delete')}
            secondaryButtonText={i18n.t('builder.resume_editor.cancel')}
            dontShowAgainId="deleteCardDialog2" // optional
            isOpen={this.state.isModalOpen}
            primaryAction={this.handleModalConfirm}
            secondaryAction={this.handleModalCancel}
            closeAction={this.handleModalCancel}
          />

          <CardTitle
            title={title}
            subtitle={subtitle}
            isOpen={isOpen}
            isDragging={isDragging}
            onMoveUp={this.handleMoveUp}
            onMoveDown={this.handleMoveDown}
            onToggle={this.handleToggle}
            onDelete={this.showModal}
            disableMove={disableMove}
          />

          {!mediaQueries.isPhone && !isDragging && (
            <Foldable isOpen={isOpen} duration={foldableDuration}>
              {this.props.children}
            </Foldable>
          )}

          {mediaQueries.isPhone && (
            <CardModal
              isOpen={isOpen}
              title={sectionTitle}
              caption={title}
              onClose={this.handleToggle}
            >
              {this.props.children}
            </CardModal>
          )}
        </Container>
      </Wrapper>
    )
  }
}

export default withMediaQueries(Base)
