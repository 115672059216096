import { Icon20 } from 'builder/components/Icon'
import { useI18n } from 'builder/hooks/useI18n'
import useImproveResumePanel from '../../hooks/useImproveResumePanel'
import { Header, Section } from '../../styles'
import { Container, List, Item, Icon } from './styles'

export const AISuggests = () => {
  const { i18n } = useI18n()
  const {
    isAICoverLetterActive,
    isAIProfileWriterActive,
    handleClickCoverLetter,
    handleClickAIWriteProfileSummary,
  } = useImproveResumePanel()

  return (
    <Section>
      <Header>
        {i18n.t('builder.resume_editor.keywords.improve_resume_panel.ai_suggest.title_text')}
      </Header>
      <Container>
        <List>
          {isAICoverLetterActive && (
            <Item onClick={handleClickCoverLetter}>
              <Icon>
                <Icon20.Featured />
              </Icon>
              {i18n.t(
                'builder.resume_editor.keywords.improve_resume_panel.ai_suggest.create_cl_anchor',
              )}
            </Item>
          )}
          {isAIProfileWriterActive && (
            <Item onClick={handleClickAIWriteProfileSummary}>
              <Icon>
                <Icon20.Featured />
              </Icon>
              {i18n.t(
                'builder.resume_editor.keywords.improve_resume_panel.ai_suggest.write_profile_summary_anchor',
              )}
            </Item>
          )}
        </List>
      </Container>
    </Section>
  )
}
