import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Container = styled.div`
  pointer-events: all;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.Neutral100};
  border-radius: 8px;
  padding: 12px;

  ${Media.Phone`
    width: 300px;
  `};
`

export const Text = styled.div`
  ${Typography.Caption};
  color: ${Colors.White};
  margin-left: 12px;
`

export const IconImage = styled.img``
