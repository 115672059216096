import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors, actions, Skill, Label } from 'builder/modules/careerPath2'

import { Title, Container, SuggestedSkillsText } from './styles'

export const SuggestedSkills = () => {
  const dispatch = useDispatch()
  const skills = useTypedSelector(selectors.skills)
  const suggestedSkills = useTypedSelector(selectors.suggestedSkills)

  const handleClick = useCallback(
    (skill: Skill) => {
      trackInternalEvent('click_skills_tag', { ...Label, skill: skill.label })

      dispatch(actions.setValidationError(false))

      const filteredSuggestedSkills = suggestedSkills.filter(
        suggestedSkill => suggestedSkill.value !== skill.value,
      )

      dispatch(actions.setSuggestedSkills(filteredSuggestedSkills))
      dispatch(actions.setSkills([...skills, skill]))
    },
    [dispatch, skills, suggestedSkills],
  )

  return (
    <Container>
      <SuggestedSkillsText>Suggested skills:</SuggestedSkillsText>
      {suggestedSkills.map(skill => (
        <Title key={skill.value} onClick={() => handleClick(skill)}>
          {skill.label}
        </Title>
      ))}
    </Container>
  )
}
