import { Job } from 'builder/modules/careerPath2'
import { useJobTracker } from '../../hooks/useJobTracker'
import Body from './components/Body'
import { Location } from './components/Meta/Meta'
import { MainContent, Wrapper } from './styles'
import TopContent from './components/TopContent'

interface JobCardProps {
  card: Job
  isSliderActive: boolean
  handleClick: (jobTitle?: string) => void
}

const RecommendedJobCard = ({ card, isSliderActive, handleClick }: JobCardProps) => {
  const { company, jobTitle, companyLogo, location } = card
  const { isPresent, handleAddJobBoard } = useJobTracker(card)

  const prevent = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleAddCard = (event: React.MouseEvent<HTMLDivElement>) => {
    prevent(event)
    handleAddJobBoard()
  }

  return (
    <Wrapper onClick={() => handleClick(jobTitle)} {...{ isSliderActive }}>
      <TopContent
        logo={companyLogo}
        handleAddCard={event => handleAddCard(event)}
        isPresent={isPresent}
      />
      <MainContent>
        <Body company={company} title={jobTitle} />
        <Location value={location} />
        {/* Will come in next iteration */}
        {/* {jobType && <Type value={jobType} />}
        {salary && <Salary value={salary} />} */}
      </MainContent>
    </Wrapper>
  )
}

export default RecommendedJobCard
