import styled, { keyframes } from 'styled-components'
import Colors from 'builder/styles/colors'

const progress = keyframes`
  0% {
    stroke-dasharray: 0 100;
  }
`

export const CircularChart = styled.svg<{ $width: number }>`
  width: ${({ $width }) => $width}px;
`

export const Circle = styled.path<{ $strokeWidth: number; $color: string }>`
  fill: none;
  stroke: ${({ $color }) => $color};
  stroke-width: ${({ $strokeWidth }) => $strokeWidth};
  animation: ${progress} 1s ease-out forwards;
`

export const CircleBackground = styled.path<{ $strokeWidth: number }>`
  fill: none;
  stroke: ${Colors.Neutral5};
  stroke-width: ${({ $strokeWidth }) => $strokeWidth};
`
