import styled, { css } from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import { ModalCloseProps } from './types'

const versionCss = {
  '1': css`
    background: ${Colors.Neutral10};
    color: ${Colors.Neutral50};

    ${Media.Hover`
      &:hover {
        background: ${Colors.Blue10};
        color: ${Colors.Blue50};
      }
    `}
  `,
  '2': css`
    background: ${Colors.White};
    color: ${Colors.Neutral50};

    ${Media.Hover`
      &:hover {
        background: ${Colors.White};
        color: ${Colors.Blue50};
      }
    `}
  `,
}

export const CloseButton = styled.button<ModalCloseProps>(({ version = '1' }) => {
  return css`
    --modal-close-size: 28px;

    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: var(--modal-close-size);
    height: var(--modal-close-size);
    flex: 0 0 var(--modal-close-size);
    border-radius: 50%;
    transition: all 0.1s ease-out;

    ${versionCss[version]}

    ${Media.Phone`
      --modal-close-size: 24px;
    `}
  `
})
