import React, { useContext } from 'react'
import { Transition } from 'react-transition-group'
import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { Icon24 } from '../Icon'
import {
  Wrapper,
  Container,
  InfoContainer,
  Text,
  DismissButton,
  IconWrapper,
  SuccessIconWrapper,
} from './styles'
import { SnackBarContext } from './SnackBarContext'
import { SnackBarTypes } from './types'

const SnackBarIcon: React.FC<{ type: SnackBarTypes }> = ({ type }) => {
  const iconMap: Record<SnackBarTypes, React.ReactNode> = {
    [SnackBarTypes.success]: (
      <SuccessIconWrapper>
        <Icon24.CircleSuccess />
      </SuccessIconWrapper>
    ),
    [SnackBarTypes.failure]: <Icon24.CircleError />,
    [SnackBarTypes.simple]: null,
  }

  return <IconWrapper>{iconMap[type]}</IconWrapper>
}

export const SnackBar: React.FC = () => {
  const { i18n } = useI18n()
  const { isPhone } = useMediaQueries()
  const { isOpen, text, type, isDismissible, action, bottom, closeSnackBar, clearSnackBarState } =
    useContext(SnackBarContext)
  const safeType = type ?? SnackBarTypes.simple

  return (
    <Transition
      in={isOpen}
      onExited={clearSnackBarState}
      timeout={{ enter: 300, exit: 300 }}
      mountOnEnter
      unmountOnExit
    >
      {state => (
        <Wrapper $transitionState={state} $bottom={bottom} $isPhone={isPhone}>
          <Container>
            <InfoContainer>
              {type !== SnackBarTypes.simple && <SnackBarIcon type={safeType} />}
              <Text>{text}</Text>
            </InfoContainer>
            {isDismissible && (
              <DismissButton onClick={closeSnackBar}>
                {i18n.t('builder.resume_editor.dismiss')}
              </DismissButton>
            )}
            {action?.onClick && action.text && !isDismissible && (
              <DismissButton onClick={action.onClick}>{action.text}</DismissButton>
            )}
          </Container>
        </Wrapper>
      )}
    </Transition>
  )
}
