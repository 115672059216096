import styled from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const MiniAppsWrapper = styled.div`
  position: relative;
  user-select: none;

  &:before,
  &:after {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    width: calc(50vw);
    background: linear-gradient(
      90deg,
      ${hexToRgba(Colors.White, 0)} 0%,
      ${Colors.White} 128px,
      ${Colors.White} 100%
    );
  }

  &:before {
    right: calc(100% + 32px);
    transform: scaleX(-1);
  }

  &:after {
    left: calc(100% + 32px);
  }

  & .swiper-container {
    position: relative;
    overflow: visible;
  }

  & .swiper-wrapper {
    display: flex;
    position: relative;
    transition-property: transform;
  }

  & .swiper-slide {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
  }

  & .swiper-slide:not(:last-child) {
    margin-right: 16px;

    ${Media.Tablet`
      margin-right: 8px;
    `}
  }

  ${Media.Tablet`
    margin-top: -36px;
    margin-bottom: 36px;
  `}
`

export const MiniAppsMainContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -16px;
  margin-bottom: 72px;

  ${Media.Tablet`
    margin-top: -36px;
    margin-bottom: 28px;
  `}

  ${Media.Phone`
    flex-direction: column;
    overflow-x: auto; /* Add horizontal scroll for smaller screens */
    white-space: nowrap; /* Prevent line breaks */
    scrollbar-width: none; /* Hide the scrollbar on Firefox */
    -ms-overflow-style: none; /* Hide the scrollbar on IE/Edge */
  `}
  &::-webkit-scrollbar {
    width: 0; /* Hide the scrollbar on WebKit browsers (e.g., Chrome, Safari) */
  }
`

export const MiniAppsContainer = styled.div`
  position: relative;
  border-radius: 12px;
  height: 68px;
`

export const Button = styled.button<{
  isClicked?: boolean
  isHovered?: boolean
  isTabWidth?: number
}>`
  height: 112px;
  width: 186px;
  position: relative;
  cursor: pointer;
  border: none;
  background: ${props => props.isHovered && Colors.Neutral5};
  border-radius: 8px;
  box-shadow: ${props =>
    props.isClicked &&
    `0px 2px 4px 0px rgba(15, 56, 113, 0.08), 0px 1px 2px 0px rgba(15, 56, 113, 0.12)`};

  ${Media.Phone`
width: 140px;
height: 80px;
  `}
`

export const Text = styled.div<{ isClicked?: boolean; isHovered?: boolean }>`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral90};
`

export const CrownContainer = styled.img`
  width: var(--M, 24px);
  height: var(--M, 24px);
  flex-shrink: 0;
  top: 16px;
  right: 62px;
  left: 100px;
  bottom: 80;
  position: absolute;
  z-index: 1; /* Set a higher z-index to place it above the Button */
  /* top: -40px; Adjust the top position to your preference */

  ${Media.Phone`
    width: var(--M, 20px);
    height: var(--M, 20px);
    top: 4px;
    left: 80px;
  `}
`
