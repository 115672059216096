import styled from 'styled-components'
import Media from 'builder/styles/media'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${Media.Tablet`
    padding-inline: 8px;
  `}
`

export const InnerContainer = styled(Container)`
  gap: 32px;

  ${Media.Tablet`
    padding-inline: 0;
  `}
`

export const SpinnerWrapper = styled.div`
  width: 100%;
  height: 180px;
  display: grid;
  place-items: center;

  ${Media.Tablet`
    height: 240px;
  `}

  ${Media.Phone`
    height: 240px;
  `}
`
