import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Status } from 'builder/modules/interview/types'

export const Badge = styled.div<{
  $status: Status
  $question: boolean
}>`
  ${Typography.Tiny};
  ${FontWeights.DemiBold};
  border-radius: 4px;
  padding: 4px 6px;
  text-transform: capitalize;
  background-color: ${({ $status, $question }) => {
    if ($status === 'completed') {
      return Colors.Neutral20
    }
    if ($status === 'started') {
      return Colors.Indigo10
    }
    if ($question) {
      return Colors.Blue10
    }
    return Colors.Green10
  }};

  color: ${({ $status, $question }) => {
    if ($status === 'completed') {
      return Colors.Neutral60
    }
    if ($status === 'started') {
      return Colors.Indigo60
    }
    if ($question) {
      return Colors.Blue50
    }
    return Colors.Green50
  }};
`
