import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Shadows from 'builder/styles/shadows'

export const ScoreVariant = {
  positive: {
    color: Colors.Green40,
  },
  negative: {
    color: Colors.Amber40,
  },
}

export const ScoreContent = styled.div`
  ${Shadows.light.low};
  width: 50px;
  height: fit-content;
  position: absolute;
  left: 31px;
  top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3px 3px 6px 3px;
  border-radius: 8.56px;
  background-color: ${Colors.White};
  text-align: center;
`

export const ScoreValue = styled.div<{ score: number }>`
  ${Typography.Body};
  ${FontWeights.DemiBold};
  color: ${Colors.White};
  background: ${props =>
    props.score <= 30
      ? Colors.Red40
      : props.score > 30 && props.score <= 70
      ? Colors.Amber40
      : Colors.Green40};
  &:after {
    content: '%';
    ${Typography.Caption};
  }
  border-radius: 6px;
  padding: 2px 6px;
  width: 42px;
  height: 27px;
  align-items: center;
  justify-content: center;
`

export const ScoreLabel = styled.div`
  color: ${Colors.Neutral50};
  ${FontWeights.Regular};
  font-size: 12px;
  line-height: 10.54px;
  margin-top: 4px;
`
