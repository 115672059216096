import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Icon from 'builder/components/Icon'
import { Typography, FontWeights } from 'builder/styles/typography'
import { Container as BaseContainer } from 'builder/components/Container'

export const Container = styled(BaseContainer)`
  display: flex;
  margin-top: 40px;
  position: relative;
  align-items: center;
  justify-content: center;

  ${Media.Phone`
    margin-top: 32px;
  `}
`
export const Title = styled.div`
  ${Typography.M}
  ${FontWeights.DemiBold}

  display: flex;
  align-items: center;
  justify-content: center;

  ${Media.Phone`
    ${Typography.S}
  `}
`
export const ArrowContainer = styled.button`
  left: 40px;
  width: 80px;
  height: 32px;
  border: none;
  display: flex;
  flex-shrink: 0;
  cursor: pointer;
  position: absolute;
  align-items: center;
  border-radius: 20px;
  justify-content: flex-start;
  background: ${Colors.Neutral10};
  padding: 4px 16px 4px 8px;
  & svg {
    min-width: 24px;
    color: ${Colors.Neutral50};
  }
  &:hover {
    background: ${Colors.Blue10};
  }

  ${Media.Phone`
    left: 16px;
    width: 40px;
    padding: 0px;
    justify-content: center;
  `}

  ${Media.Tablet`
    left: 20px;
    justify-content: center;
  `}
`
export const LeftArrow = styled(Icon.Chevron)`
  transform: rotate(0.5turn);

  ${ArrowContainer}:hover & {
    color: ${Colors.Blue50};
  }
`
export const Text = styled.span`
  ${Typography.Caption};
  ${FontWeights.DemiBold};

  color: ${Colors.Neutral50};

  ${ArrowContainer}:hover & {
    color: ${Colors.Blue50};
  }

  ${Media.Phone`
    display: none;
  `}
`
