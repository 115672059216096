import { useCallback } from 'react'
import { UserDocumentType } from '@rio/types'
import { trackInternalEvent } from '@rio/tracking'
import Colors from 'builder/styles/colors'

import { Icon20 } from 'builder/components/Icon'
import ModalOverlay from 'builder/components/ModalOverlay'
import Button, { ButtonSize } from 'builder/components/Button'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { selectors as userSelectors } from 'builder/modules/user'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import { Label } from 'builder/modules/careerPlanning'

import { i18n as I18n } from 'builder/utils/i18n'
import { billingPlanPageURL } from 'builder/utils/goToBillingPlanPage'
import * as Styles from './styles'

type Features = {
  id: number
  text: string
}

type Value = 'skip' | 'close'

type Props = {
  text?: string
  title?: string
  subTitle?: string
  features?: Features[]
  feature?: string
  documentType?: UserDocumentType
  cancelButtom: string
  upgradebutton: string
  tunerImg: string
  onClose: (text: Value) => void
  isExploreJobs?: boolean
  handleUpgrade?: () => void
}

const TRANSLATION = 'builder.resume_editor.cio_limit_modal'

export const CioLimitModal = ({
  documentType,
  title,
  subTitle,
  text = I18n.t(`${TRANSLATION}.unlock_text`),
  features = [
    { id: 1, text: I18n.t(`${TRANSLATION}.document_features.feature_1`) },
    { id: 2, text: I18n.t(`${TRANSLATION}.document_features.feature_2`) },
    { id: 3, text: I18n.t(`${TRANSLATION}.document_features.feature_3`) },
  ],
  cancelButtom,
  upgradebutton,
  tunerImg,
  onClose,
  isExploreJobs,
  handleUpgrade,
}: Props) => {
  const upgradeUrl = useTypedSelector(userSelectors.getUpgradeUrl)
  const { isPhone } = useMediaQueries()

  useEffectOnMount(() => {
    documentType &&
      (documentType === 'resume' || documentType === 'cover_letter') &&
      trackInternalEvent(`see_${documentType}_limit_modal`)
    title && trackInternalEvent(`view_paywall`, { ...Label })
  })

  const getText = useCallback(
    (text: string) => {
      if (text === 'title') {
        if (title) {
          return title
        }

        if (documentType === 'cover_letter') {
          return I18n.t(`${TRANSLATION}.title_cover_letter`)
        }

        return I18n.t(`${TRANSLATION}.title_resume`)
      } else {
        if (subTitle) {
          return subTitle
        }

        if (documentType === 'cover_letter') {
          return I18n.t(`${TRANSLATION}.subtitle_cover_letter`)
        }

        return I18n.t(`${TRANSLATION}.subtitle_resume`)
      }
    },
    [documentType, title, subTitle],
  )

  const handleUpgradeClick = useCallback(() => {
    window.open(billingPlanPageURL({ upgradeURL: upgradeUrl }), '_self')

    documentType &&
      (documentType === 'resume' || documentType === 'cover_letter') &&
      trackInternalEvent(`click_upgrade_${documentType}_limit_button`)
    title && trackInternalEvent('click_upgrade_paywall', { ...Label })
  }, [documentType, upgradeUrl, title])

  return (
    <ModalOverlay onClick={onClose} overlayFadeDuration={150} contentSlideDuration={150}>
      <Styles.Container>
        <Styles.Close onClick={() => onClose('close')}>
          <Icon20.Close />
        </Styles.Close>
        <Styles.Visual src={tunerImg} />
        <Styles.DescriptionSection>
          <Styles.Description>
            <Styles.Header>
              <Styles.Title>{getText('title')}</Styles.Title>
            </Styles.Header>
            <Styles.SubTitle>{getText('subTitle')}</Styles.SubTitle>
          </Styles.Description>
          <Styles.UnlockFeaturesSection>
            <Styles.IconContainer>
              {isExploreJobs && <Styles.PremiumIcon iscolor={Colors.Amber40} />}
              <Styles.UnlockText>{text}:</Styles.UnlockText>
            </Styles.IconContainer>
            <Styles.FeaturesContainer isMoreThanFour={features.length > 4}>
              {features.map(feature => (
                <Styles.FeatureItem key={feature.id}>
                  <Styles.GreenTick />
                  <Styles.Feature>{feature.text}</Styles.Feature>
                </Styles.FeatureItem>
              ))}
            </Styles.FeaturesContainer>
          </Styles.UnlockFeaturesSection>
          <Styles.ButtonContainer>
            <Button
              theme="ghost"
              onClick={() => onClose('skip')}
              size={!isPhone ? ButtonSize.default : ButtonSize.small}
            >
              {cancelButtom}
            </Button>
            <Button
              onClick={isExploreJobs ? handleUpgrade : handleUpgradeClick}
              size={!isPhone ? ButtonSize.default : ButtonSize.small}
            >
              {upgradebutton}
            </Button>
          </Styles.ButtonContainer>
        </Styles.DescriptionSection>
      </Styles.Container>
    </ModalOverlay>
  )
}
