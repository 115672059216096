import { useMemo } from 'react'
import { CareerInsightsSkillListData } from 'builder/modules/careerProfile'
import { RecommendedSkill } from '../types'

export const useSkillsList = (
  data: CareerInsightsSkillListData | undefined,
): RecommendedSkill[] => {
  return useMemo(() => {
    if (!data) {
      return []
    }

    const { skill: titles, softHard: softOrHards, coursesRecommendation: recommendations } = data

    return titles.map((title, index) => ({
      title,
      hardOrSoft: softOrHards[index],
      description: recommendations[index],
    }))
  }, [data])
}
