import * as Styled from './styles'

import ILLUSTRATION_URL from './assets/illustration.svg'

type Props = {
  userPhotoUrl: string
}

export const JobFinderVisual = ({ userPhotoUrl }: Props) => {
  return (
    <>
      <Styled.Illustration alt="Job illustration" src={ILLUSTRATION_URL} />
      <Styled.Photo alt="User photo" src={userPhotoUrl} />
    </>
  )
}
