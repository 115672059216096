import { useContext } from 'react'
import { TunerEditorContext } from '../../contexts/TunerEditorContext'
import { TunerTooltip } from '../TunerTooltip'

/**
 * Renders a tooltip inside of the parent section if an active issue relates to the section.
 * Must be added in every resume editor section.
 */
export const TunerSectionTooltips = ({ sectionId }: { sectionId: string }) => {
  const { issues, activeIssueId } = useContext(TunerEditorContext)

  // Find the issue to display
  const issue = issues.find(issue => issue.sectionId === sectionId && issue.id === activeIssueId)
  return issue ? <TunerTooltip issue={issue} /> : null
}
