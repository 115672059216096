import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Container as BaseContaienr } from 'builder/components/Container'
import { HorizontalTransitionFade } from 'builder/components/HorizontalTransitionFade'

export const Container = styled(HorizontalTransitionFade)``

export const MainContainer = styled(BaseContaienr)`
  padding-top: 48px;
  padding-bottom: 98px;
  gap: 60px;
  display: flex;
  flex-direction: column;

  ${Media.Phone`
    padding-top: 40px;
  `}
`

export const Header = styled.div`
  max-width: 832px;
  gap: 8px;
  display: flex;
  flex-direction: column;
`

export const Heading = styled.h1`
  ${Typography.L};
  ${FontWeights.DemiBold};
`

export const SubHeading = styled.p`
  color: ${Colors.Neutral50};
`

export const TopContainer = styled.div`
  gap: 32px;
  display: flex;
  flex-direction: column;
`

export const CardsContainer = styled.div`
  gap: 16px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`
