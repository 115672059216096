import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import Icon24, { Icon20 } from 'builder/components/Icon'
import Button, { ButtonTheme } from 'builder/components/Button'
import { goToBillingPlanPage } from 'builder/utils/goToBillingPlanPage'
import { Modal } from '../Modal/Modal'
import {
  BodyContainer,
  ButtonContainer,
  CustomBadge,
  Img,
  ImgContainer,
  PlayIconContainer,
  Text,
  Title,
} from './styles'
import personSrc from './assets/person.png'

const TRANSLATION_KEY = 'builder.interview_prep_view.premium_dialog'
interface Props {
  trackingParameter:
    | 'interview_dash_recommendation'
    | 'interview_dash_questions'
    | 'interview_dash_custom'
    | 'custom'
    | 'library'
    | 'feedback'
    | 'restart_modal'
  onClose: () => void
}
export const PremiumDialog = ({ onClose, trackingParameter }: Props) => {
  const { i18n } = useI18n()

  return (
    <Modal onClose={onClose}>
      <ImgContainer>
        <Img src={personSrc} />
        <PlayIconContainer>
          <Icon24.Play />
        </PlayIconContainer>
      </ImgContainer>
      <BodyContainer>
        <CustomBadge variant="indigo">
          <Icon20.Crown /> {i18n.t(`${TRANSLATION_KEY}.available_premium`)}
        </CustomBadge>
        <Title>{i18n.t(`${TRANSLATION_KEY}.title`)}</Title>
        <Text>{i18n.t(`${TRANSLATION_KEY}.text`)}</Text>
        <ButtonContainer>
          <Button theme={ButtonTheme.ghost} onClick={onClose}>
            {i18n.t(`${TRANSLATION_KEY}.close`)}
          </Button>
          <Button
            onClick={() => {
              goToBillingPlanPage({ referrerPage: 'interviewPrep' })
              trackInternalEvent('click_upgrade_paywall', {
                label: 'interview_prep',
                parameter: trackingParameter,
              })
            }}
          >
            {i18n.t(`${TRANSLATION_KEY}.upgrade_now`)}
          </Button>
        </ButtonContainer>
      </BodyContainer>
    </Modal>
  )
}
