import { useState, useEffect, useRef, useCallback } from 'react'
import { FetchStatuses } from 'builder/modules/constants'
import { apiClient } from 'builder/modules/apiClient'

type UseSearchProps = {
  scope: string
  locale: string
  query: string
}

type Phrase = {
  category: string
  phrase: string
  subphrases: string[]
  highlight: string
  locale: string
  digest: string
  usageCount: number
}

type PhraseGroup = {
  groupType: string
  groupTitle: string
  phrases: Phrase[]
}

type UseSearchResult = {
  query: string
  result: PhraseGroup[]
  categories: string[]
  categoryFilter: string | null
  foundAnything: boolean | null
}

type UseSearchReturn = {
  status: FetchStatuses
  performSearch: (query: string) => Promise<void>
} & UseSearchResult

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useDebouncedEffect = (
  callback: () => void,
  delay: number,
  deps: React.DependencyList = [],
) => {
  useEffect(() => {
    const handler = setTimeout(callback, delay)
    return () => clearTimeout(handler)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay, ...deps])
}

const useSearch = ({ scope, locale, query }: UseSearchProps): UseSearchReturn => {
  const [status, setStatus] = useState<FetchStatuses>(FetchStatuses.notAsked)

  const [result, setResult] = useState<UseSearchResult>({
    query,
    result: [],
    categories: [],
    categoryFilter: null,
    foundAnything: null,
  })

  const lastQueryRef = useRef()

  const performSearch = useCallback(
    async query => {
      // Do not duplicate requests
      if (query === lastQueryRef.current) return

      setStatus(FetchStatuses.loading)
      lastQueryRef.current = query

      const params = { query, locale }
      const { data } = await apiClient.get<UseSearchResult>(`/phrases/${scope}/search`, { params })

      // Catch slow responses
      if (data.query !== lastQueryRef.current) return

      // Apply search results
      setResult(data)
      setStatus(FetchStatuses.loaded)
    },
    [locale, scope],
  )

  // Perform search automatically if query is changed.
  // Debounce the callback to avoid extra requests
  useDebouncedEffect(() => performSearch(query), 350, [query, locale, scope])

  // Data to render search results dropdown
  return {
    ...result,
    status,
    // Provide callback to perform search manually (without 350ms delay)
    performSearch,
  }
}

export default useSearch
