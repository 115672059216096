import styled, { css } from 'styled-components'

import {
  Cards,
  PromotionTopic,
} from 'builder/components/SubscriptionCancellationFormNew/common/types'

import { getLineHeightRatio } from 'builder/components/SubscriptionCancellationFormNew/common/utils'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

interface Props {
  topic: PromotionTopic
}

export const GettingStartedText = styled.p<Props>(({ topic }) => {
  let textColor = ''

  if (topic === Cards.JobOffer) textColor = Colors.Blue80
  if (topic === Cards.DreamJob) textColor = Colors.Green80

  return css`
    width: 297px;
    font-size: 14px;
    line-height: ${getLineHeightRatio(16, 14)};
    margin-left: 16px;
    color: ${textColor};
    opacity: 50%;

    ${Media.Tablet`
        width: 400px;
        text-align: center;
        margin-left: unset !important;
        margin-top: 16px;
    `}

    ${Media.Phone`
        width: 295px;
        font-size: 14px;
        line-height: ${getLineHeightRatio(16, 14)};
        text-align: center;
        margin-left: unset !important;
        margin-top: 12px;
    `}
  `
})
