import { CircularChart, CircleBackground, Circle } from './styles'

type PercentageCircleProps = {
  percentage: number
  width: number
  strokeWidth?: number
  color: string
}

export const PercentageCircle = ({
  percentage,
  width,
  strokeWidth = 2,
  color,
}: PercentageCircleProps) => {
  return (
    <CircularChart viewBox="0 0 40 40" $width={width}>
      <CircleBackground
        $strokeWidth={strokeWidth}
        d={`M20 ${strokeWidth / 2}
        a 15.9155 15.9155 0 0 1 0 31.831
        a 15.9155 15.9155 0 0 1 0 -31.831`}
      />
      <Circle
        $color={color}
        $strokeWidth={strokeWidth}
        strokeDasharray={`${percentage}, 100`}
        d={`M20 ${strokeWidth / 2}
        a 15.9155 15.9155 0 0 1 0 31.831
        a 15.9155 15.9155 0 0 1 0 -31.831`}
      />
    </CircularChart>
  )
}
