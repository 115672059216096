import * as Styled from './styles'

interface Props {
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const CustomInputButton = ({ handleFileChange }: Props) => {
  return (
    <Styled.Label>
      <Styled.Input onChange={handleFileChange} />
      Upload Another
    </Styled.Label>
  )
}

export default CustomInputButton
