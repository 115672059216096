import { Fragment, useMemo } from 'react'
import { Resume } from '@rio/types'
import { useParams } from 'react-router-dom'
import { formatDocumentPreviewUrl } from 'builder/utils/formatDocumentPreviewUrl'
import Colors from 'builder/styles/colors'
import { BriefDocumentType } from 'builder/modules/panel'
import secondResumeTempalteURL from '../../../assets/img/secondResumeTemplate.png'
import resumePreviewStatic from '../../../assets/img/resumePreviewStatic.svg'

import * as Styles from './styles'

type Props = {
  resume: Resume
  report: BriefDocumentType[]
  resumeScore: number
  isResumeTroubled?: boolean
  isResumeNearlyPerfect?: boolean
}

export const TunnerScore = ({ report }: Pick<Props, 'report'>) => {
  // Get the resume's score values
  const critiqueResume = report?.[0]
  const resumeScore = critiqueResume?.resumeScore
  const averageScore = critiqueResume?.averageScore
  const jobTitle = critiqueResume?.jobTitle

  const Score = !jobTitle ? resumeScore : averageScore

  const bgColor = useMemo(() => {
    if (Score <= 30) return Colors.Red40
    if (Score > 30 && Score <= 70) return Colors.Amber40
    return Colors.Green40
  }, [Score])

  return (
    <Styles.OptimizeScoreContainer>
      <Styles.Score bgColor={bgColor}>
        <Styles.ScoreNumber>{Score}</Styles.ScoreNumber>
        <Styles.ScorePercent>%</Styles.ScorePercent>
      </Styles.Score>
    </Styles.OptimizeScoreContainer>
  )
}

const EditResumeWithResumeScore = ({
  resume,
  report,
  resumeScore,
  isResumeTroubled,
  isResumeNearlyPerfect,
}: Props) => {
  const imageSrc = formatDocumentPreviewUrl(resume, { size: 256 })
  const params = useParams()
  const resumeId = Number(params.id)
  const resumeEditorUrl = `/app/resumes/${resumeId}/edit`
  const resumeEditorExploreTemplatesUrl = `/app/resumes/${resumeId}/edit?show_templates=1`

  const renderTitle = () => {
    if (isResumeNearlyPerfect) {
      return 'Explore all available winning resume templates to stand out from the crowd'
    }
    return 'Improve your resume and make it stand out with professional resume builder'
  }

  const renderButtonLabel = () => {
    if (isResumeTroubled) {
      return 'Improve My Resume'
    } else if (isResumeNearlyPerfect) {
      return 'Explore Templates'
    }
    return 'Edit My Resume'
  }

  return (
    <Fragment>
      <Styles.Container data-testid="edit-resume-banner">
        <Styles.Title>{renderTitle()}</Styles.Title>
        <Styles.ImagePreview
          src={isResumeTroubled ? resumePreviewStatic : imageSrc}
          isResumeTroubled={isResumeTroubled || isResumeNearlyPerfect}
        />
        {(isResumeTroubled || isResumeNearlyPerfect) && (
          <Styles.ImageBack src={secondResumeTempalteURL} />
        )}

        <Styles.Gradient />
        <Styles.Button
          data-testid="cta-button"
          href={isResumeNearlyPerfect ? resumeEditorExploreTemplatesUrl : resumeEditorUrl}
          left={isResumeTroubled || isResumeNearlyPerfect ? 72 : 90}
        >
          {renderButtonLabel()}
        </Styles.Button>
        {!(isResumeTroubled || isResumeNearlyPerfect) && (
          <TunnerScore report={[{ ...report?.[0], resumeScore: resumeScore }]} />
        )}
      </Styles.Container>
    </Fragment>
  )
}

export default EditResumeWithResumeScore
