import {
  ProgressbarContainer,
  ProgressbarText,
  ProgressbarIcon,
  ProgressbarTextIcon,
  ProgressBar,
  Progress,
  StepContainer,
  ProgressbarCountText,
  ProgressbarInfoText,
  WithProgressIcon,
  BlankProgressIcon,
} from './style'

interface CareerProgressBarProps {
  intakeProgress: number
}

export const CareerProgressBar = ({ intakeProgress }: CareerProgressBarProps) => {
  return (
    <StepContainer>
      <ProgressbarContainer>
        <ProgressbarText>
          <ProgressbarIcon>
            {intakeProgress > 0 ? (
              <WithProgressIcon data-testid="with-progress-icon" />
            ) : (
              <BlankProgressIcon data-testid="blank-progress-icon" />
            )}
          </ProgressbarIcon>
          <ProgressbarTextIcon>
            <ProgressbarCountText data-testid="progressbar-count-text">
              {intakeProgress}%
            </ProgressbarCountText>
            <ProgressbarInfoText data-testid="progressbar-info-text">Completed</ProgressbarInfoText>
          </ProgressbarTextIcon>
        </ProgressbarText>
        <ProgressBar>
          <Progress percentage={intakeProgress} />
        </ProgressBar>
      </ProgressbarContainer>
    </StepContainer>
  )
}
