import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'

import { useI18n } from 'builder/hooks/useI18n'

import Icon24 from 'builder/components/Icon'
import { Button } from './styles'

interface IProps {
  onSkip: (event: React.MouseEvent<HTMLElement>) => void
}
export const SkipButton: React.FC<IProps> = ({ onSkip }) => {
  const { i18n } = useI18n()
  const divRef = useRef(document.createElement('div'))

  useEffect(() => {
    const div = divRef.current
    document.body.appendChild(div)
    return () => div.remove()
  }, [])

  return ReactDOM.createPortal(
    <Button onClick={onSkip}>
      {i18n.t(`builder.job_search.onboarding.tooltip.skip`)}
      <Icon24.RightArrow />
    </Button>,
    divRef.current,
  )
}
