import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { useState } from 'react'
import { useUser } from 'builder/hooks/useUser'
import { stripHtml } from 'builder/utils/stripHTML'
import { useNewInterview } from 'builder/views/Interview/hooks/useNewInterview'
import { goToBillingPlanPage } from 'builder/utils/goToBillingPlanPage'
import {
  FlexContainer,
  InterviewIcon,
  Notification,
  NotificationContainer,
  Separator,
  Subtitle,
  Title,
  Button,
} from './styles'
import { InterviewMockProps } from './types'
import { useFuzzyJobTitles } from './hooks/useFuzzyJobTitles'
import ExistingDataModal from './modals/ExistingDataModal'

const InterviewMock = ({ visible, selectedJob, onClose, trackingLabel }: InterviewMockProps) => {
  const navigate = useNavigate()
  const user = useUser()
  const hasPremium = user?.hasPremiumFeatures
  const { mutateAsync: postInterview, isLoading } = useNewInterview()

  const { data: matchedJobTitles } = useFuzzyJobTitles(
    hasPremium ? selectedJob?.title ?? null : null,
    1,
  )
  const [isExistingVisible, setExistingVisible] = useState(false)

  const handleUpgrade = () => {
    goToBillingPlanPage({ onSuccessRedirect: window.location.href })
  }

  const handleGenerate = async () => {
    if (matchedJobTitles && matchedJobTitles?.length > 0) {
      trackInternalEvent('generated_interview', { label: trackingLabel })
      const matchedJobTitle = matchedJobTitles[0]
      await postInterview({
        jobTitleCode: Number(matchedJobTitle.id),
        company: selectedJob?.company || undefined,
        jobDescription: selectedJob?.description ? stripHtml(selectedJob?.description) : '',
      })

      return navigate('/interview-preparation/interview-player')
    }

    navigate('/interview-preparation/custom-interview', {
      state: {
        company: selectedJob?.company,
        description: selectedJob?.description ? stripHtml(selectedJob?.description) : '',
      },
    })
  }

  const handleStart = () => {
    setExistingVisible(true)
  }
  const handleModalClose = () => {
    setExistingVisible(false)

    onClose()
  }

  return (
    <>
      <NotificationContainer isVisible={visible && !isExistingVisible}>
        <Notification>
          <Separator>
            <FlexContainer>
              <InterviewIcon name="InterviewPrep" />

              <div>
                <Title>Start practicing for this interview</Title>
                <Subtitle>Try our AI-generated questions based on the job description</Subtitle>
              </div>
            </FlexContainer>

            <FlexContainer>
              <Button theme="ghost" onClick={onClose}>
                Close
              </Button>

              {hasPremium && <Button onClick={handleStart}>Get Started</Button>}
              {!hasPremium && <Button onClick={handleUpgrade}>Upgrade</Button>}
            </FlexContainer>
          </Separator>
        </Notification>
      </NotificationContainer>

      {selectedJob && isExistingVisible && (
        <ExistingDataModal
          job={selectedJob}
          onClose={handleModalClose}
          onStart={handleGenerate}
          isLoading={isLoading}
        />
      )}
    </>
  )
}

export default InterviewMock
