import styled from 'styled-components'
import { Typography, FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Button from 'builder/components/Button'
import Colors from 'builder/styles/colors'

export const Container = styled.div`
  width: 416px;

  ${Media.Tablet`
    max-width: 376px;
    width: 100%;
  `}

  ${Media.Phone`
    min-width: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `}
`

export const Title = styled.div`
  ${Typography.M}
  ${FontWeights.DemiBold}
  text-align: center;
  margin-bottom: 16px;

  ${Media.Phone`
  ${Typography.S};
  `}
`

export const FormContainer = styled.form`
  ${Media.Phone`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-grow: 1;
  `}
`

export const FieldsContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 24px;
`

export const FieldContainer = styled.div`
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  ${Media.Phone`
    margin-bottom: 16px;
  `}
`

export const ButtonContainer = styled.div`
  text-align: right;

  ${Media.Phone`
    text-align: center;
    width: 100%;
    max-width: calc(100vw - 32px);
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    position: sticky;
    bottom: 24px;
  `}
`

export const FormButton = styled(Button)`
  ${Media.Phone`
    max-width: 504px;
    width: 100%;
  `}
`
export const Card = styled.div`
  width: 100%;
  height: 126px;
  display: flex;
  align-items: center;
  border-radius: 16px;
  justify-content: center;
  padding: 20px 91px 12px 79px;
  background-color: ${Colors.Neutral5};

  ${Media.Tablet`
    padding: 12px 80px 12px 36px;
  `}

  ${Media.Phone`
    padding: 12px 80px 12px 36px;
  `}
`
export const Image = styled.img`
  width: 262px;
  height: 110px;
`
