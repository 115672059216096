import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/sevenStories'
import { SkillsTitleContainer, SkillsTitleWrapper, SkillsWrapper, Title } from './styles'

const Skills = () => {
  const assessmentResult = useTypedSelector(selectors.assessmentResult)

  return (
    <SkillsWrapper>
      <Title>Your Top Enjoyable Skills</Title>
      <SkillsTitleContainer>
        {assessmentResult?.skills?.map((skill, index) => (
          <SkillsTitleWrapper key={index}>
            <div>{skill}</div>
          </SkillsTitleWrapper>
        ))}
      </SkillsTitleContainer>
    </SkillsWrapper>
  )
}

export default Skills
