import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import DragHandle from 'builder/components/DragHandle'

const getBgColor = (isSelected: boolean, isSelectionAllowed: boolean) => {
  if (isSelected) return Colors.Blue10
  if (isSelectionAllowed) return Colors.White

  return Colors.Neutral10
}

export const Container = styled.div`
  gap: 12px;
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  color: ${Colors.Neutral40};
  border: 1px solid ${Colors.Neutral15};
`

export const RadioBtn = styled.div<{
  index: number
  isSelected: boolean
  isSelectionAllowed: boolean
}>`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  place-items: center;
  border-radius: 50%;
  color: ${Colors.Blue50};
  background-color: ${props => getBgColor(props.isSelected, props.isSelectionAllowed)};
  outline: 1px solid ${props => (props.isSelected ? Colors.Blue10 : Colors.Neutral30)};
  cursor: ${props => (props.isSelectionAllowed || props.isSelected ? 'pointer' : 'not-allowed')};
`

export const Skill = styled.div`
  flex: auto;
  color: ${Colors.Neutral90};
`

export const DragIcon = styled(DragHandle)<{ isSelected: boolean }>`
  width: 20px;
  height: 20px;
  opacity: 1;
  display: ${props => (props.isSelected ? 'block' : 'none')};
`
