import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import { Section } from '../../styles'

export const Container = styled(Section)`
  background-color: ${Colors.Neutral5};
  padding: 64px 48px 48px;

  ${Media.Phone`
    padding: 24px 0;
  `}
`

export const SectionHeaderWrapper = styled.div`
  ${Media.Phone`
    padding: 0 24px;
  `}
`

export const HeaderImage = styled.img`
  width: 256px;

  ${Media.Tablet`
    width: 182px;
  `};

  ${Media.Phone`
    width: 160px;
  `};
`

export const JobTitle = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};
  color: ${Colors.Neutral100};
  margin-bottom: 4px;
`

export const JobSubtitle = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral90};
`

export const JobDescription = styled.div`
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${Colors.Neutral60};
  margin-top: 16px;
`

export const UserPhoto = styled.img`
  display: block;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  margin-bottom: 16px;
`
