import { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useLocalStorage } from 'builder/hooks/useLocalStorage'
import { useJobSearch } from '../useJobSearch'
import { SearchSuggestionType } from '../AutoSuggestField/types'
import {
  LOCAL_STORAGE_JS_HISTORY_LOCATIONS_KEY,
  PARAM_LOCATION,
  PARAM_LOCATION_ID,
  PARAM_LOCATION_LAT,
  PARAM_LOCATION_LNG,
} from '../SearchBar/constants'

export const usePrefillLocation = () => {
  const {
    getLocationFromServer,
    setLocationText,
    setLocationPrefillCompleted,
    setSelectedLocation,
  } = useJobSearch()
  const [isDonePrefill, setIsDonePrefill] = useState(false)
  const [prefillValue, setPrefillValue] = useState<SearchSuggestionType>()

  const running = useRef(false)
  const [recentLocations] = useLocalStorage<string[]>(LOCAL_STORAGE_JS_HISTORY_LOCATIONS_KEY, [])
  const [query] = useSearchParams()
  const [locationLat] = useState(query.get(PARAM_LOCATION_LAT) || '')
  const [locationLng] = useState(query.get(PARAM_LOCATION_LNG) || '')
  const [locationId] = useState(query.get(PARAM_LOCATION_ID) || '')
  const [location] = useState(query.get(PARAM_LOCATION) || '')

  useEffect(() => {
    if (running.current) {
      return
    }
    running.current = true
    const updateLocation = (suggestion?: SearchSuggestionType) => {
      setPrefillValue(suggestion)
      setIsDonePrefill(true)
      setLocationPrefillCompleted(true)
    }

    async function getLocation() {
      const addressPromise = getLocationFromServer()
      if (location === 'null') {
        return updateLocation(undefined)
      }

      if (locationLat && locationLng && locationId && location) {
        const locationParams: SearchSuggestionType = {
          formatted: location,
          value: locationId,
          text: location,
          lat: locationLat,
          lng: locationLng,
          locationType: 'string_location',
        }
        setLocationText(location)
        return updateLocation(locationParams)
      }

      // try to get only location
      if (locationId.length === 0 && location) {
        const locationParams: SearchSuggestionType = {
          formatted: location,
          value: location,
          text: location,
          locationType: 'string_location',
        }
        setLocationText(location)
        return updateLocation(locationParams)
      }

      // try to get locationId and location valid
      if (locationId.length > 0 && location) {
        const locationParams: SearchSuggestionType = {
          formatted: location,
          value: locationId,
          text: location,
          locationType: 'location_id',
        }
        setLocationText(location)
        return updateLocation(locationParams)
      }

      try {
        // try to get from the browser position api
        const address = await addressPromise
        if (address) {
          return updateLocation(address)
        }
      } catch (e) {
        setLocationPrefillCompleted(true)
        console.log(e)
      }
    }
    getLocation()
  }, [getLocationFromServer, recentLocations, setLocationText, setSelectedLocation])

  return { isDonePrefill, prefillValue }
}
