import {
  PreviousUpcomingSessions,
  selectors as coachSelectors,
} from 'builder/modules/careerCoaching'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import ContentFailedToDownload from 'builder/components/CareerPostPurchaseBanner/components/ContentFailedToDownload'
import { EmptyState } from '../EmptyState'
import { SessionCard } from './components/SessionCard'
import { ButtonContainer, Container, ShowMoreButton, SessionCardContainer } from './styles'
import { PREVIOUS_SESSIONS_LIST_DEFAULT_LIMIT } from './constant'

interface PreviousProps {
  onShowMoreSessions: () => void
  isMorePreviousSessions: boolean
}

const Previous = ({ onShowMoreSessions, isMorePreviousSessions }: PreviousProps): JSX.Element => {
  const { status, response } = useTypedSelector(coachSelectors.coachingSessions)
  const { previous: previousSessions } = response

  const sessionView = isMorePreviousSessions
    ? previousSessions?.length
    : PREVIOUS_SESSIONS_LIST_DEFAULT_LIMIT

  const renderPreviousSessions = () =>
    previousSessions
      ?.slice(0, sessionView)
      ?.map((session: PreviousUpcomingSessions) => (
        <SessionCard session={session} key={session?.date} />
      ))

  return (
    <Container isSessions={!previousSessions.length}>
      {!status ? (
        <ContentFailedToDownload />
      ) : (
        <>
          {previousSessions?.length ? (
            <SessionCardContainer>{renderPreviousSessions()}</SessionCardContainer>
          ) : (
            <EmptyState
              heading="Previous sessions will show here"
              subHeading="You don’t have any previous sessions yet"
            />
          )}

          {previousSessions?.length > PREVIOUS_SESSIONS_LIST_DEFAULT_LIMIT && (
            <ButtonContainer>
              <ShowMoreButton onClick={onShowMoreSessions}>
                {isMorePreviousSessions ? 'See less' : 'See all past sessions'}
              </ShowMoreButton>
            </ButtonContainer>
          )}
        </>
      )}
    </Container>
  )
}

export default Previous
