import { useState, useEffect } from 'react'
import { useI18n } from 'builder/hooks/useI18n'
import { delay } from 'builder/views/Interview/utils/delay'
import { LoadingScreen } from '../../LoadingScreen/LoadingScreen'
const TRANSLATION = 'builder.interview_prep_view.start_screen'

interface Props {
  onClose: () => void
  forceStartLoading: boolean
}

export const StartLoading = ({ onClose, forceStartLoading }: Props) => {
  const { i18n } = useI18n()
  const [counter, setCounter] = useState(0)

  const items = [
    {
      title: i18n.t(`${TRANSLATION}.creating_interview`),
      content: i18n.t(`${TRANSLATION}.creating_interview_subtitle`),
    },
    {
      title: i18n.t(`${TRANSLATION}.generating_interview`),
      content: i18n.t(`${TRANSLATION}.generating_interview_subtitle`),
    },
    {
      title: i18n.t(`${TRANSLATION}.almost_there`),
      content: i18n.t(`${TRANSLATION}.almost_there_subtitle`),
    },
  ]
  useEffect(() => {
    let isMounted = true

    async function wait() {
      await delay(3000)
      if (isMounted) {
        const newCounter = (counter + 1) % items.length
        if (forceStartLoading && counter === 2 && newCounter === 0) {
          return onClose()
        }
        setCounter(newCounter)
      }
    }
    wait()

    return () => {
      isMounted = false
    }
  }, [counter])

  if (items.length === counter) {
    return null
  }
  return <LoadingScreen content={items[counter]} />
}
