import styled from 'styled-components'

export type Position = 'start' | 'end' | 'center'

export const Column = styled.div<{ size?: number; position?: Position }>`
  flex: ${props => props.size || 1};
  display: flex;
  justify-content: ${props => (props.position === 'center' ? 'center' : `flex-${props.position}`)};
  align-items: center;
  overflow: hidden;
`

export const ColumnWithAvatar = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`

export const ColumnWithIcon = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`

export const ColumnText = styled.div<{ margin?: string; padding?: string }>`
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  align-items: center;
  overflow: hidden;
`
