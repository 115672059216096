import { colord } from 'colord'
import { colors } from '@rio/colors'

/**
 * UI and brand colors defined in Figma:
 * https://www.figma.com/file/r9kxSExzSPtyUn7Gx5iFj7/Color-System
 */
export const Colors = colors
export default Colors

/** Converts hexadecimal color string to RGBA string with the given alpha */
export const hexToRgba = (hex: string, alpha = 1): string => colord(hex).alpha(alpha).toRgbString()

// Create a type containing all color base names: 'neutral', 'blue', 'indigo, 'green', etc
type FindVariableTones<T> = T extends `${infer C}100` ? C : never
export type ToneName = Lowercase<FindVariableTones<keyof typeof Colors>>
export type ToneNames = FindVariableTones<keyof typeof Colors>

/** An array containing all color base names: 'neutral', 'blue', 'indigo, 'green', etc */
export const toneNames = Object.keys(colors)
  .filter(key => key.endsWith('100'))
  .map(key => key.toLowerCase().replace(/[0-9]+/, '')) as ToneName[]
