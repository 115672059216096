import { Link } from 'react-router-dom'
import Spinner from 'builder/components/Spinner'
import { useI18n } from 'builder/hooks/useI18n'
import { SalaryAnalyzerV2, Pitch, ResumeAndCoverLetters } from 'builder/components/FeatureIcon'
import Button from 'builder/components/Button'
import * as Styled from './styles'
import image from './images/waiting.svg'

type Props = {
  isLoading: boolean
  isPending: boolean
}

export const Loader = ({ isLoading, isPending }: Props) => {
  const { i18n } = useI18n()
  const description = i18n.t(`builder.career_profile.loader.description`, {
    careerInsightsIconUrl: SalaryAnalyzerV2,
    professionalPitchIconUrl: Pitch,
    aiCoverLetterIconUrl: ResumeAndCoverLetters,
  })

  return (
    <Styled.Container timeout={0}>
      {isLoading && !isPending ? (
        <Spinner />
      ) : (
        <Styled.Wrapper timeout={0}>
          <Styled.Main>
            <Styled.Image src={image} />

            <Styled.TextWrapper>
              <Styled.Header>{i18n.t(`builder.career_profile.loader.please_wait`)}</Styled.Header>
              <Styled.Text dangerouslySetInnerHTML={{ __html: description }} />
            </Styled.TextWrapper>
          </Styled.Main>

          <Link to="/">
            <Button>{i18n.t(`builder.career_profile.loader.go_to_dashboard`)}</Button>
          </Link>
        </Styled.Wrapper>
      )}
    </Styled.Container>
  )
}
