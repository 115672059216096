import { useCallback, useEffect, useMemo, useState } from 'react'
import { size } from 'lodash'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useNavigate } from 'react-router-dom'
import { i18n } from 'builder/utils/i18n'
import { selectors, actions as jobTrackingActions } from 'builder/modules/jobTracking'
import { ColumnName, Job } from 'builder/modules/jobTracking/types'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { LOW_SCORE } from 'builder/components/Helper/constants'
import Colors from 'builder/styles/colors'
import { actions } from 'builder/modules/panel'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { TailorToResume } from 'builder/components/Panel/Document/TailorToResume/TailorToResume'
import { ImproveResumeScore, TailorYourResume } from '../../ResumeScoreBanner'
import sideResume from '../../NoResumeBanner/sideResume.svg'
import knowWorthImg from '../assets/knowWorth.svg'
import interviewPrepImg from '../assets/interviewPrep.svg'
import {
  JobSearchFeatureContainer,
  JobSearchFeatureHeader,
  JobSearchFeatureSerialNumber,
  JobSearchFeatureTitle,
  JobSearchInfoContainer,
  JobSearchInfoFeaturesContainer,
  JobSearchInfoHeader,
  JobSearchInfoSubTitle,
  JobSearchInfoTitle,
} from './styles'
import { JobSearchBanner } from './JobSearchBanner'

interface IJobSearchInfoProps {
  job: Job
  handleSetInterviewJob: (selectedJob: Job) => void
}

export const JobSearchInfo = ({ job, handleSetInterviewJob }: IJobSearchInfoProps) => {
  const { features } = useFeaturesConfig()
  const [openId, setOpenId] = useState(0)
  const [hoverId, setHoverId] = useState(0)
  const resumeList = useTypedSelector(selectors.selectResumeList)
  const { status, positionNumber, link, id, resumeScore } = job

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const JOB_SEARCH_FEATURES = [
    { id: 1, enabled: features.resumeOptimizer, title: 'resume_builder', banner: TailorToResume },
    {
      id: 2,
      enabled: features.interviewSchool,
      title: 'interview_preparation',
      banner: TailorToResume,
    },
    { id: 3, enabled: features.salaryAnalyzer, title: 'offer_analyzer', banner: TailorToResume },
  ]

  const handleCreate = useCallback(() => {
    dispatch(actions.createDocument({ type: 'resume' }))
    trackInternalEvent(`click_create_resume`, { label: 'job_tracker' })
  }, [dispatch])

  const poorResumeScore = useMemo(() => resumeScore && resumeScore < 70, [resumeScore])

  const handleApply = useCallback(() => {
    if (link) {
      window.open(link, '_blank')
      const payload = {
        source: {
          droppableId: status,
          index: positionNumber,
        },
        destination: {
          droppableId: ColumnName.applied,
          index: 0,
        },
        jobId: Number(id),
      }
      dispatch(jobTrackingActions.setApplyTrackingDialog({ show: true, moveData: payload }))
    }
  }, [dispatch, id, link, positionNumber, status])

  const handleAction = useCallback(
    (featureId: number) => {
      switch (featureId) {
        case 1:
          resumeList.length === 0 ? handleCreate() : handleApply()
          break
        case 2:
          trackInternalEvent('click_interview', { label: 'sidebar' })
          handleSetInterviewJob(job)
          break
        case 3:
          trackInternalEvent('click_salary_analyzer', { label: 'sidebar' })
          navigate('/offer-analyzer')
          break
        default:
          break
      }
    },
    [handleApply, handleCreate, handleSetInterviewJob, job, navigate, resumeList.length],
  )

  useEffect(() => {
    if (status === ColumnName.wishlist) setOpenId(1)
    else if (status === ColumnName.applied) setOpenId(2)
    else if (status === ColumnName.interview) setOpenId(3)
  }, [status])

  const getPhraseKey = useCallback(
    (featureId: number) => {
      let phraseKey = 'builder.job_tracking.'
      switch (featureId) {
        case 1:
          phraseKey += resumeScore && link ? 'perfect_resume_banner' : 'no_resume_banner'
          break
        case 2:
          phraseKey += 'interview_preparation_banner'
          break
        case 3:
          phraseKey += 'offer_analyzer_banner'
          break
        default:
          phraseKey += 'no_resume_banner'
      }
      return phraseKey
    },
    [resumeScore, link],
  )

  const getBgColor = useCallback(
    (featureId: number) => {
      switch (featureId) {
        case 1:
          if (poorResumeScore) {
            if (resumeScore >= LOW_SCORE) return 'Amber'
            return 'Red'
          } else return 'Blue'
        case 2:
          return 'Green'
        case 3:
          return 'Indigo'
        default:
          return 'Blue'
      }
    },
    [resumeScore, poorResumeScore],
  )

  const getBannerImg = useCallback((featureId: number) => {
    switch (featureId) {
      case 1:
        return sideResume
      case 2:
        return interviewPrepImg
      case 3:
        return knowWorthImg
      default:
        return sideResume
    }
  }, [])

  const getBackgroundColor = useCallback(
    (featureId: number) => {
      if (openId === featureId) {
        if (hoverId === featureId) {
          switch (featureId) {
            case 1:
              if (poorResumeScore) {
                if (resumeScore >= LOW_SCORE) return '#FFEDC5'
                return '#FFDFE2'
              } else return '#E0F1FE'
            case 2:
              return '#E2F1E8'
            case 3:
              return '#EDEEFE'
            default:
              return Colors[`${getBgColor(featureId)}10`]
          }
        } else return Colors[`${getBgColor(featureId)}10`]
      } else return hoverId === featureId ? Colors[`${getBgColor(featureId)}10`] : Colors.Neutral5
    },
    [getBgColor, hoverId, openId, poorResumeScore, resumeScore],
  )

  const renderFeatureBanner = useCallback(
    (featureId: number) => {
      const open = openId === featureId
      if (featureId === 1 && size(resumeList) >= 1 && resumeScore && poorResumeScore) {
        return (
          <ImproveResumeScore job={job} fromSidebar open={open} hovered={hoverId === featureId} />
        )
      } else if (featureId === 1 && !resumeScore && size(resumeList) >= 1)
        return (
          <TailorYourResume job={job} fromSidebar open={open} hovered={hoverId === featureId} />
        )
      else
        return (
          <JobSearchBanner
            btnBgColor={Colors[`${getBgColor(featureId)}80`]}
            btnBgHoverColor={Colors[`${getBgColor(featureId)}90`]}
            btnTxt={i18n.t(`${getPhraseKey(featureId)}.submit`)}
            subtitle={i18n.t(`${getPhraseKey(featureId)}.subtitle`)}
            subtitleColor={Colors[`${getBgColor(featureId)}70`]}
            title={i18n.t(`${getPhraseKey(featureId)}.title`)}
            titleColor={Colors[`${getBgColor(featureId)}70`]}
            imgUrl={getBannerImg(featureId)}
            handleClick={() => handleAction(featureId)}
            top={featureId === 1 ? 37 : 20}
            open={open}
          />
        )
    },
    [
      openId,
      resumeList,
      resumeScore,
      poorResumeScore,
      job,
      hoverId,
      getBgColor,
      getPhraseKey,
      getBannerImg,
      handleAction,
    ],
  )

  return (
    <>
      <JobSearchInfoContainer>
        {(features.resumeOptimizer || features.interviewSchool || features.salaryAnalyzer) && (
          <JobSearchInfoHeader>
            <JobSearchInfoTitle>
              {i18n.t('builder.job_tracking.edit_modal.job_search_section.title')}
            </JobSearchInfoTitle>
            <JobSearchInfoSubTitle>
              {i18n.t('builder.job_tracking.edit_modal.job_search_section.subtitle')}
            </JobSearchInfoSubTitle>
          </JobSearchInfoHeader>
        )}
        <JobSearchInfoFeaturesContainer>
          {JOB_SEARCH_FEATURES.filter(feat => feat.enabled).map((feature, index) => (
            <JobSearchFeatureContainer
              onMouseEnter={() => setHoverId(feature.id)}
              onMouseLeave={() => setHoverId(0)}
              backgroundColor={getBackgroundColor(feature.id)}
              onClick={() => setOpenId(feature.id)}
              key={feature.id}
              open={feature.id === openId}
            >
              <JobSearchFeatureHeader backgroundColor={getBackgroundColor(feature.id)}>
                <JobSearchFeatureSerialNumber>{index + 1}.</JobSearchFeatureSerialNumber>
                <JobSearchFeatureTitle>
                  {i18n.t(
                    `builder.job_tracking.edit_modal.job_search_section.features.${feature.title}`,
                  )}
                </JobSearchFeatureTitle>
              </JobSearchFeatureHeader>
              {feature.id === openId && <>{renderFeatureBanner(openId)}</>}
            </JobSearchFeatureContainer>
          ))}
        </JobSearchInfoFeaturesContainer>
      </JobSearchInfoContainer>
    </>
  )
}
