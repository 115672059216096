import { useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import countriesArray from 'builderStatic/js/components/config/countriesArray'
import { useI18n } from 'builder/hooks/useI18n'
import { AutoApplyForm, ExpectedStartWork } from 'builder/modules/autoApply/types'

import Select from 'builder/components/Select'
import Toggle from 'builder/components/Toggle'
import { TextField } from 'builder/components/TextField'
import * as SimpleForm from 'builder/components/SimpleForm'
import { Divider } from 'builder/components/DocumentSelect/styles'
import { AsyncAutosuggest } from 'builder/components/AsyncAutosuggest'
import { ClusterPicker } from 'builder/components/ClusterPicker/ClusterPicker'
import { createNewLocationSuggestionsApiFetcher } from 'builder/components/FindJob/utils/createNewSuggestionsApiFetcher'

const countries = countriesArray
  .sort((a, b) => (b.priority || 0) - (a.priority || 0))
  .map(c => ({ value: c.id, label: c.name }))

type Props = {
  form: UseFormReturn<
    Pick<
      AutoApplyForm,
      'countriesAuthorizedToWorkIn' | 'targetLocations' | 'jobPreferenceAttributes'
    >
  >
}

export const JobPreferencesForm2 = (props: Props) => {
  const { form } = props

  const {
    register,
    formState: { errors },
    watch,
    setValue,
  } = form
  const { i18n } = useI18n()
  const [desiredLocation, setDesiredLocation] = useState('')

  const TRANSLATION = `builder.auto_apply.form.job_preferences_2`

  const START_DATE_OPTIONS = [
    { id: 'immediate', name: i18n.t(`${TRANSLATION}.start_date.options.immediate`) },
    { id: 'one_month', name: i18n.t(`${TRANSLATION}.start_date.options.one_month`) },
    { id: 'three_months', name: i18n.t(`${TRANSLATION}.start_date.options.three_months`) },
    { id: 'six_months', name: i18n.t(`${TRANSLATION}.start_date.options.six_months`) },
  ]

  const authorizedCountries = watch('countriesAuthorizedToWorkIn') || []
  const targetLocationValue = watch('targetLocations') ? watch('targetLocations')[0] : null

  const getUnselectedCountries = () => {
    return countries.filter(x => !authorizedCountries.map(item => item.value).includes(x.value))
  }

  return (
    <>
      <SimpleForm.Row position="relative">
        <AsyncAutosuggest
          {...register('targetLocations', {
            required: i18n.t(`${TRANSLATION}.location.error`),
          })}
          fetchItems={async (searchString: string) => {
            const result = await createNewLocationSuggestionsApiFetcher(searchString)
            return result.map(item => ({ text: item.formatted }))
          }}
          onChange={event => {
            setDesiredLocation(event.target.value)
          }}
          value={desiredLocation || targetLocationValue}
          onSuggestionSelected={(_, selected) => {
            setValue('targetLocations', [selected?.suggestion?.text || ''])
          }}
        >
          {inputProps => (
            <TextField
              {...inputProps}
              label={i18n.t(`${TRANSLATION}.location.label`)}
              placeholder={i18n.t(`${TRANSLATION}.location.placeholder`)}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              error={(errors.targetLocations as any)?.message}
            />
          )}
        </AsyncAutosuggest>
      </SimpleForm.Row>
      <SimpleForm.Row position="relative">
        <Toggle
          {...register('jobPreferenceAttributes.willingToRelocate')}
          checked={watch('jobPreferenceAttributes.willingToRelocate')}
          onChange={e =>
            setValue(
              'jobPreferenceAttributes.willingToRelocate',
              (e.target as HTMLInputElement).checked,
            )
          }
          label={i18n.t(`${TRANSLATION}.relocation`)}
        />
      </SimpleForm.Row>

      {watch('jobPreferenceAttributes.willingToRelocate') && (
        <SimpleForm.Row position="relative">
          <Toggle
            {...register('jobPreferenceAttributes.willingToRelocateAtYourOwnCost')}
            checked={watch('jobPreferenceAttributes.willingToRelocateAtYourOwnCost')}
            onChange={e =>
              setValue(
                'jobPreferenceAttributes.willingToRelocateAtYourOwnCost',
                (e.target as HTMLInputElement).checked,
              )
            }
            label={i18n.t(`${TRANSLATION}.relocation_expenses`)}
          />
        </SimpleForm.Row>
      )}

      <SimpleForm.Row position="relative">
        <ClusterPicker
          list={getUnselectedCountries()}
          selectedList={authorizedCountries}
          onSelect={country =>
            setValue('countriesAuthorizedToWorkIn', [country, ...authorizedCountries])
          }
          onUnselect={newVal => setValue('countriesAuthorizedToWorkIn', newVal)}
          label={i18n.t(`${TRANSLATION}.countries_permission.label`)}
          showLimit={7}
          placeholder={i18n.t(`${TRANSLATION}.countries_permission.placeholder`)}
        />
      </SimpleForm.Row>

      <SimpleForm.Row position="relative">
        <Divider />
      </SimpleForm.Row>

      <SimpleForm.Row position="relative">
        <Select
          {...register('jobPreferenceAttributes.expectedStartWork')}
          label={i18n.t(`${TRANSLATION}.start_date.label`)}
          selected={watch('jobPreferenceAttributes.expectedStartWork')}
          onSelect={val =>
            setValue('jobPreferenceAttributes.expectedStartWork', val as ExpectedStartWork)
          }
          {...{ options: START_DATE_OPTIONS }}
        />
      </SimpleForm.Row>
    </>
  )
}
