import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { i18n } from 'builder/utils/i18n'
import * as Styled from './styles'

interface Props {
  isDocument: boolean
}
export const BlurCard = ({ isDocument }: Props) => {
  const { isPhone } = useMediaQueries()
  return (
    <>
      <Styled.Container isDocummentSection={isDocument}>
        <Styled.SubContainer isDocummentSection={isDocument}>
          <Styled.Crown />
          <Styled.Text isDocummentSection={isDocument}>
            {i18n.t(`builder.navigation.post_premium_blur_card.title`)}
          </Styled.Text>
          <Styled.UpgradeNow>
            {isPhone
              ? i18n.t(`builder.navigation.post_premium_blur_card.isPhonebutton`)
              : i18n.t(`builder.navigation.post_premium_blur_card.button`)}
          </Styled.UpgradeNow>
        </Styled.SubContainer>
      </Styled.Container>
    </>
  )
}
