import { SectionContainer, SectionContent } from 'builder/components/ProfileSummary/styles'
import { SectionHeader, SectionHint } from 'builder/components/Section'
import SectionTitle from 'builder/components/SectionTitle'
import EditorRow from 'builder/components/EditorRow'
import { Separator } from 'builder/components/DocumentEditor/styles'
import { useShokumuTranslations } from '../../useShokumuTranslations'
import BusinessType from './BusinessType'
import FoundationYear from './FoundationYear'
import Capital from './Capital'
import Sales from './Sales'
import EmployeeCount from './EmployeeCount'
import StockListings from './StockListings'

export const EmployerProfile = (): JSX.Element => {
  const { i18n } = useShokumuTranslations()
  const title = i18n('work_experience.employer_details.title')
  const hint = i18n('work_experience.employer_details.hint')

  return (
    <SectionContainer>
      <SectionHeader>
        <SectionTitle title={title} />
      </SectionHeader>
      <SectionContent>
        <SectionHint>{hint}</SectionHint>
        <Separator />
        <EditorRow>
          <BusinessType />
          <FoundationYear />
        </EditorRow>
        <EditorRow>
          <Capital />
          <Sales />
        </EditorRow>
        <EditorRow>
          <EmployeeCount />
          <StockListings />
        </EditorRow>
      </SectionContent>
    </SectionContainer>
  )
}
