import styled from 'styled-components'
import Media from 'builder/styles/media'

export const Container = styled.div`
  width: 100%;
  gap: 32px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  ${Media.Phone`
    flex-direction: column;
    align-items: space-between;
    justify-content: flex-start;
  `};

  ${Media.Tablet`
    flex-direction: column;
    align-items: space-between;
    justify-content: flex-start;
  `};

  @media (max-width: 1199px) {
    flex-direction: column;
    align-items: space-between;
    justify-content: flex-start;
  }
`
