import { createAction, createReducer } from '@reduxjs/toolkit'
import { CoverLetter } from '@rio/types'
import { Store } from 'builder/modules/store'
import { wrapTextInPTags } from 'builder/utils/richTextUtils'
import { CoverLetterRegenerationStatus } from '../constants'
import { CoverLetterEditorStore } from './types'

// ---
// Action creators
// ---
export const actions = {
  fetchCoverLetterRequest: createAction<{ id: number }>('builder/FETCH_COVER_LETTER_REQUEST'),
  fetchCoverLetterSuccess: createAction<CoverLetter>('builder/FETCH_COVER_LETTER_SUCCESS'),
  fetchCoverLetterFail: createAction<CoverLetterEditorStore['error']>(
    'builder/FETCH_COVER_LETTER_FAIL',
  ),

  // TODO: Find a way to add types for `value` or rewrite the action payload
  updateSimpleField: createAction<{ name: keyof CoverLetter; value: unknown; debounce?: boolean }>(
    'builder/UPDATE_COVER_LETTER_SIMPLE_FIELD',
  ),

  updateCoverLetterSuccess: createAction<CoverLetter>('builder/UPDATE_COVER_LETTER__SUCCESS'),
  updateCoverLetterFail: createAction<CoverLetterEditorStore['error']>(
    'builder/UPDATE_COVER_LETTER_FAIL',
  ),
  changeEditorState: createAction<Partial<CoverLetterEditorStore>>(
    'builder/COVER_LETTER_CHANGE_EDITOR_STATE',
  ),
  regenerateCoverLetter: createAction<{ id: number }>('builder/REGENERATE_COVER_LETTER'),
  setAIRegenerationStatus: createAction<CoverLetterRegenerationStatus>(
    'builder/SET_AI_REGENERATION_STATUS',
  ),
  setShowAICoverLetterPaywall: createAction('builder/SET_SHOW_AI_COVER_LETTER_PAYWALL'),
  setOpenEditorEventTracked: createAction<boolean>('builder/SET_OPEN_EDITOR_EVENT_TRACKED'),
  setShowConfirmRegenerateModal: createAction<boolean>('builder/SET_SHOW_CONFIRM_REGENERATE_MODAL'),
  resetAIGenerationState: createAction('builder/RESET_AI_GENERATION_STATE'),
}

// --
// Selectors
// --
export const selectors = {
  templates: (state: Store) => state.generalEditor.coverLetterTemplates,
  coverLetter: (state: Store) => state.coverLetterEditor.coverLetter,
  isContentAnimationPending: (state: Store) => !!state.coverLetterEditor.coverLetter?.openCl,
}

// ---
// Reducer
// ---

const initialAIGenerationState = {
  showPaywall: false,
  showConfirmationModal: false,
  regenerationStatus: CoverLetterRegenerationStatus.notStarted,
}

const initialState: CoverLetterEditorStore = {
  isLoading: false,
  isSyncing: false,
  coverLetter: null,
  error: null,
  openEditorEventTracked: false,
  aiGenerationSection: { ...initialAIGenerationState },
}

export const reducer = createReducer(initialState, reducer => {
  reducer.addCase(actions.changeEditorState, (draft, { payload }) => {
    draft = { ...draft, ...payload }
  })

  reducer.addCase(actions.fetchCoverLetterRequest, draft => {
    draft.isLoading = true
    draft.coverLetter = null
    draft.error = null
    draft.openEditorEventTracked = false
    draft.aiGenerationSection = { ...initialAIGenerationState }
  })

  reducer.addCase(actions.fetchCoverLetterFail, (draft, action) => {
    draft.isLoading = false
    draft.error = action.payload
  })

  reducer.addCase(actions.fetchCoverLetterSuccess, (draft, action) => {
    draft.isLoading = false
    const coverLetter = action.payload
    const updatedCoverLetter = {
      ...coverLetter,
      content: wrapTextInPTags(coverLetter.content || ''),
    }
    draft.coverLetter = updatedCoverLetter
  })

  reducer.addCase(actions.updateSimpleField, (draft, action) => {
    if (draft.coverLetter) {
      const { name, value } = action.payload
      draft.coverLetter = { ...draft.coverLetter, [name]: value }
    }
  })

  reducer.addCase(actions.updateCoverLetterFail, (draft, action) => {
    draft.isSyncing = false
    draft.error = action.payload
  })

  reducer.addCase(actions.updateCoverLetterSuccess, (draft, action) => {
    draft.isSyncing = false
    const coverLetter = action.payload
    const updatedCoverLetter = {
      ...coverLetter,
      content: wrapTextInPTags(coverLetter.content || ''),
    }
    draft.coverLetter = updatedCoverLetter
  })
  reducer.addCase(actions.setAIRegenerationStatus, (draft, action) => {
    draft.aiGenerationSection.regenerationStatus = action.payload
  })
  reducer.addCase(actions.setShowAICoverLetterPaywall, draft => {
    draft.aiGenerationSection.showPaywall = !draft.aiGenerationSection.showPaywall
  })
  reducer.addCase(actions.setOpenEditorEventTracked, (draft, action) => {
    draft.openEditorEventTracked = action.payload
  })
  reducer.addCase(actions.setShowConfirmRegenerateModal, (draft, action) => {
    draft.aiGenerationSection.showConfirmationModal = action.payload
  })
  reducer.addCase(actions.resetAIGenerationState, draft => {
    draft.aiGenerationSection = { ...initialAIGenerationState }
  })
})
