import styled, { css, keyframes } from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'
import { Typography } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Shadows from 'builder/styles/shadows'

const fadeIn = keyframes`
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`

export const AvatarName = styled.div<{ isOpen: boolean }>`
  margin-right: 12px;
  color: ${props => (props.isOpen ? `${Colors.Blue60}` : 'inherit')};
  transition: color 0.1s;
`

export const AvatarDropdownTrigger = styled.button<{ isOpen: boolean }>`
  position: relative;
  display: flex;
  overflow: hidden;
  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  border: ${props =>
    props.isOpen ? `2px solid ${Colors.Blue60}` : `1px solid ${hexToRgba(Colors.Neutral90, 0.04)}`};
  transition: border-color 0.1s ease-out;
`

export const AvatarImage = styled.img`
  width: 100%;
`

export const AvatarContainer = styled.div<{ isMenuOpen: boolean }>`
  position: relative;
  transition: opacity 0.2s, visibility 0.2s;
  opacity: ${props => (props.isMenuOpen ? 0 : 1)};
  visibility: ${props => (props.isMenuOpen ? 'hidden' : 'visible')};
  margin-left: auto;
`

export const Avatar = styled.div`
  display: flex;
  align-items: center;

  &:hover ${AvatarName} {
    color: ${Colors.Blue60};
  }

  &:hover ${AvatarDropdownTrigger} {
    border: 2px solid ${Colors.Blue50};

    &:after,
    &:focus-visible:after {
      opacity: 1;
    }
  }

  &:active ${AvatarDropdownTrigger} {
    border-color: ${Colors.Blue60};
  }
`

export const MenuContainer = styled.menu`
  ${Shadows.lightWithBorder.mid};
  position: absolute;
  z-index: 510;
  right: 0;
  top: calc(100% + 12px);
  padding: 0;
  margin: 0;
  width: auto;
  border-radius: 8px;
  background-color: ${Colors.White};

  transform-origin: 100% 0;
  animation: ${fadeIn} 0.1s;
  animation-fill-mode: forwards;
`

export const MenuLinks = styled.div`
  padding: 12px 0;
`

export const MenuItemTitle = styled.div`
  ${Typography.Body};
  color: ${Colors.Neutral90};
  white-space: nowrap;
  display: flex;
  align-items: center;
  transition: color 0.1s;
`

export const MenuItem = styled.div<{ onClick?: () => void }>`
  padding: 6px 24px;
  display: flex;
  flex-direction: row;
  position: relative;

  &:hover ${MenuItemTitle} {
    color: ${Colors.Blue60};
  }
`

export const MenuItemText = styled.div`
  display: flex;
  flex-direction: column;
`

export const MenuItemDescription = styled.div`
  ${Typography.Tiny};
  color: ${Colors.Neutral50};
`

export const MenuDivider = styled.div`
  display: block;
  height: 1px;
  width: 100%;
  background-color: ${Colors.Neutral15};
  margin: 10px 0;
`

export const Container = styled.div<{ hasFeatures: boolean }>`
  cursor: pointer;
  z-index: 500;
  -webkit-tap-highlight-color: transparent;

  ${props =>
    props.hasFeatures &&
    css`
      ${MenuItem} {
        padding-left: 52px;
      }

      ${MenuContainer} {
        width: 320px;
      }
    `}

  ${Media.Laptop`
    top: 20px;
    right: 20px;
  `};
`

export const MenuIcon = styled.div`
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  color: ${Colors.Blue50};
  position: absolute;
  left: 16px;
  top: 14px;
`
