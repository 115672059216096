import { Icon24 } from 'builder/components/Icon'
import { navigate } from 'builder/modules/navigate'
import * as Styled from './styles'

type Props = React.HTMLAttributes<HTMLDivElement> & {
  onLogoClick?: () => void
  onCloseClick?: () => void
  controlsHidden?: boolean
}

export const SimpleLayout = ({
  onLogoClick = () => navigate('/'),
  onCloseClick,
  controlsHidden = false,
  children,
  ...rest
}: Props) => {
  return (
    <Styled.Container {...rest}>
      <Styled.Navbar>
        {!controlsHidden && <Styled.Logo onClick={onLogoClick} />}

        {!controlsHidden && onCloseClick && (
          <Styled.CloseButton aria-label="Close" onClick={onCloseClick}>
            <Icon24.CloseLarge />
          </Styled.CloseButton>
        )}
      </Styled.Navbar>

      <Styled.Content>{children}</Styled.Content>
    </Styled.Container>
  )
}
