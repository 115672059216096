import { FC } from 'react'
import { CareerInsightsProcessingStatus } from 'builder/modules/careerProfile'
import { useI18n } from 'builder/hooks/useI18n'
import { useCareerInsightsStatus } from 'builder/components/CareerInsights'
import Spinner from 'builder/components/Spinner'
import { Container, TextContainer } from './styles'

const translationKey = 'builder.career_profile.loader'

export const CareerProfileLoadingState: FC = () => {
  const { i18n } = useI18n()
  const careerInsightsStatus = useCareerInsightsStatus()
  const description = i18n.t(`${translationKey}.description`)

  return careerInsightsStatus === CareerInsightsProcessingStatus.pending ? (
    <Container>
      <Spinner />
      <TextContainer>
        <div>
          <b>{i18n.t(`${translationKey}.please_wait`)}</b>
        </div>
        <div dangerouslySetInnerHTML={{ __html: description }}></div>
      </TextContainer>
    </Container>
  ) : null
}
