import { Fragment, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { useDispatch } from 'react-redux'
import { DocumentTypes } from 'builder/modules/constants'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import {
  actions as critiqueActions,
  selectors as critiqueSelectors,
  ResumeCritiqueStatuses,
} from 'builder/modules/resumeCritiqueReview'
import { actions, selectors } from 'builder/modules/panel'
import { IFRAME_PARAMS } from 'builder/modules/resumeCritiqueReview/constants'
import BrokenBanner from '../../CareerFoundationBanner/components/BrokenBanner'
import * as Styles from './styles'

const CritiqueReviewReadyBanner = () => {
  const [showFirst, setShowFirst] = useState(true)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const tunerResumeId = useTypedSelector(selectors.tunerResumeId)
  const [searchParams, setSearchParams] = useSearchParams()

  const toggle = () => {
    setShowFirst(!showFirst)
    if (searchParams.has('critique_review')) {
      searchParams.delete('critique_review')
      setSearchParams(searchParams)
    }
  }

  const viewResumeReview = (url?: string) => {
    if (url) {
      dispatch(critiqueActions.setCritiquePageVisitedCount(true))
      window.open(`${url}${IFRAME_PARAMS}`)
    }
  }

  const isCritiquePageCount = useTypedSelector(critiqueSelectors.isCritiquePageCount)
  const visitedBefore = isCritiquePageCount ? 'true' : 'false'
  localStorage.setItem('visitedBefore', visitedBefore)

  const createMyResume = () => {
    if (!tunerResumeId) {
      dispatch(actions.createDocument({ type: DocumentTypes.resume }))
    } else {
      navigate(`/resumes/${tunerResumeId}/edit`)
    }
    trackInternalEvent('tr_banner_click_view_my_resume', {
      label: 'tr_leads_to_cio',
    })
  }

  const refresh = () => {
    window.location.reload()
  }

  const CritiqueResumeReviewData = useTypedSelector(critiqueSelectors.triggeredCritiqueStatus)

  const critiqueFinishedStatus = CritiqueResumeReviewData.status === ResumeCritiqueStatuses.finished
  const critiqueFailedStatus = CritiqueResumeReviewData.status === ResumeCritiqueStatuses.failed

  useEffect(() => {
    if (critiqueFinishedStatus) {
      trackInternalEvent('see_the_critique_banner_critique_ready')
    }
  }, [critiqueFinishedStatus])

  return (
    <>
      {critiqueFinishedStatus && (
        <>
          {showFirst && (
            <Fragment>
              <Styles.CritiqueContainer>
                <Styles.BannerLeftContent>
                  <Styles.CritiqueTitle>Resume review is ready!</Styles.CritiqueTitle>
                  <Styles.CritiqueText>
                    Check personalized tips by resume expert Joe Miller on how you can improve
                    resume
                  </Styles.CritiqueText>
                  <Styles.ButtonContainer>
                    <Styles.NotNowButton onClick={toggle}>Not Now</Styles.NotNowButton>
                    <Styles.ViewMyResumeButton
                      onClick={() => viewResumeReview(CritiqueResumeReviewData.iframeUrl)}
                    >
                      View Resume Review
                    </Styles.ViewMyResumeButton>
                  </Styles.ButtonContainer>
                </Styles.BannerLeftContent>

                <Styles.BannerRightContent>
                  <Styles.CritiqueCardContainer>
                    <Styles.PopupPhoto src={CritiqueResumeReviewData.reviewer?.photoUrl} />
                    <Styles.PopupIconWrapper>
                      <Styles.PopupIcon />
                    </Styles.PopupIconWrapper>
                    <Styles.Title>{CritiqueResumeReviewData.reviewer?.name}</Styles.Title>
                    <Styles.Text>Resume Expert, TopResume</Styles.Text>
                    <Styles.CritiqueResumeContainer src={CritiqueResumeReviewData?.iframeUrl} />
                    <Styles.CritiqueResumeGradient />
                  </Styles.CritiqueCardContainer>
                </Styles.BannerRightContent>
              </Styles.CritiqueContainer>
            </Fragment>
          )}
        </>
      )}
      {critiqueFailedStatus && (
        <BrokenBanner
          title="Oops! Something went wrong"
          subTitle="We couldn't pars your resume. You can try again or create a new resume by using our winning templates"
          leftButton="Try Again"
          rightButton="Create Resume"
          toggle={refresh}
          viewMyResume={createMyResume}
        />
      )}
    </>
  )
}

export default CritiqueReviewReadyBanner
