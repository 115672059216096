import { PrepareForInterviews } from '../HelperModal/components/PrepareForInterviews'
import TrackJobs from '../HelperModal/components/TrackJobs'
import VideoContainer from '../HelperModal/components/VideoContainer'
import { DropDownContentProps } from '../HelperModal/constants'
import BuildResume from './BuildResume'

export const JobSearchModalMetadata: DropDownContentProps[] = [
  {
    id: 1,
    name: 'improve_resume',
    title: 'Improve your resume',
    text: 'Learn the essential elements of a targeted resume',
    buttonName: 'Learn More',
    visual: (
      <VideoContainer videoUrl="https://www.youtube.com/embed/rEhjcu597vs?si=WxQZHtPxmbTAyeH1&showinfo=0" />
    ),
  },
  {
    id: 2,
    name: 'apply_jobs',
    title: 'Apply for at least 5 jobs',
    text: 'Explore best-matched jobs and add at least five to the job tracker',
    buttonName: 'Open Job Search',
    visual: <TrackJobs />,
  },
  {
    id: 3,
    name: 'interview_prep',
    title: 'Prepare for interviews',
    text: 'Practice an interview and get detailed AI-powered feedback',
    buttonName: 'Prepare For Interview',
    visual: <PrepareForInterviews />,
  },
]

export const PremierVirtualModalMetadata: DropDownContentProps[] = [
  {
    id: 1,
    name: 'build_resume',
    title: 'Build a winning resume',
    text: 'Build powerful resumes in only 5 minutes with our easy to use Resume Builder',
    buttonName: 'Create Resume',
    visual: <BuildResume />,
  },
  {
    id: 1,
    name: 'build_resume',
    title: 'Optimize your resume',
    text: 'Learn the essential elements of a targeted resume improvement tips',
    buttonName: 'Optimize Resume',
    visual: <BuildResume />,
  },
  {
    id: 2,
    name: 'pitch',
    title: 'Rock your professional Pitch',
    text: 'Your Professional Pitch will help you to introduce yourself to recruiters',
    buttonName: 'Master Pitch',
    visual: <VideoContainer videoUrl="https://www.youtube.com/embed/QguDte4B2_s" />,
  },
  {
    id: 3,
    name: 'interview_prep',
    title: 'Prepare for Interviews',
    text: 'Interview confidently with advanced simulations and detailed AI feedback.',
    buttonName: 'Prepare For Interview',
    visual: <PrepareForInterviews />,
  },
]
