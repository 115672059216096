import { EnumerableResumeSectionType, ResumeSection, ResumeSkill } from 'packages/types'
import { SectionNames } from './sectionNames'

// Generates client-side uid for a new item
const makeUid = () => '_' + (Math.random() * 0xffffff).toString()

export const newRecordFor = <K extends EnumerableResumeSectionType>(
  fieldType: K | SectionNames.custom,
  uid = makeUid(),
  fields: Omit<ResumeSection[number], 'id' | 'cid'> = {},
) => {
  const baseFields = { ...fields, id: uid, cid: uid }

  switch (fieldType) {
    case SectionNames.skills: {
      const skillCard: ResumeSkill = {
        // level goes first here because it can already be in fields
        // and we don't want to override it
        level: 'expert',
        ...baseFields,
      }
      return skillCard
    }

    default:
      return baseFields
  }
}
