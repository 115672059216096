import styled from 'styled-components'
import Button from 'builder/components/Button'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 64px;

  ${Media.Laptop`
    padding: 0 20px;
  `};

  ${Media.Phone`
    margin-top: 48px;
  `};
`

export const CardsWrapper = styled.div`
  min-height: 390px;
  position: relative;

  ${Media.Laptop`
    min-height: 414px;
  `}

  ${Media.Tablet`
    min-height: 382px;
  `}

  ${Media.Phone`
    min-height: 580px;
  `}
`

export const Cards = styled.div`
  padding: 0px;
  width: 100%;

  ${Media.Phone`
    margin-right: 100px;
  `};
`

export const HeaderText = styled.div`
  ${Typography.S};
  ${FontWeights.Medium};
  gap: 32px;
`
export const Card = styled.div<{ $isActive: boolean }>`
  position: absolute;
  pointer-events: none;
  background-color: ${props => (props.$isActive ? Colors.White : Colors.Neutral5)};
  border: 1px solid ${Colors.Neutral15};
  border-radius: 16px;
  transition: opacity 1s ease;

  &.active {
    opacity: 1;
    overflow: hidden;
    z-index: 1;
  }

  &:not(.active) {
    overflow: hidden;
  }

  &:not(.active):nth-child(1) {
    top: 24px;

    ${Media.Phone`
      top: 16px;
  `};
  }

  &:not(.active):nth-child(2) {
    top: 48px;
    left: 24px;

    ${Media.Phone`
      top: 32px;
      left: 16px;
  `};
  }

  &:not(.active):nth-child(3) {
    top: 72px;
    left: 48px;

    ${Media.Phone`
      top: 48px;
      left: 32px;
  `};
  }

  &:not(.active):nth-child(4) {
    top: 24px;
    left: 0px;

    ${Media.Phone`
      top: 16px;
      left: 0px;
  `};
  }

  &:not(.active):nth-child(5) {
    top: 48px;
    left: 24px;

    ${Media.Phone`
      top: 32px;
      left: 16px;
  `};
  }

  &:not(.active):nth-child(6) {
    top: 72px;
    left: 48px;

    ${Media.Phone`
      top: 48px;
      left: 32px;
  `};
  }
`

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  min-height: 48px;

  ${Media.Phone`
    margin-bottom: 48px;
  `}
`

export const StyledButton = styled(Button)`
  width: 148px;
  height: 48px;

  ${Typography.Body};
  ${FontWeights.Medium};

  ${Media.Phone`
    ${Typography.Subhead};
  `}
`
