import styled, { css } from 'styled-components'
import Media from 'builder/styles/media'
import { Columns } from 'builder/components/Grid/types/types'

export const Cell = styled.div<Columns>`
  ${p => css`
    grid-column: span ${p.desktop ?? 1};

    ${Media.Tablet`
      grid-column: span ${p.tablet ?? 1};
    `}

    ${Media.Phone`
      grid-column: span ${p.phone ?? 1};
    `}
  `}
`
