import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Icon20 } from 'builder/components/Icon'
import Tooltip from 'builder/components/Tooltip'

export const MenuLink = styled(Link)``
export const Container = styled.div<{ active?: boolean; open?: boolean }>`
  gap: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px 9px 12px;
  margin: 0 8px;
  border-radius: 12px;

  ${Typography.Caption};
  ${FontWeights.Medium};
  color: ${Colors.Neutral60};

  &:hover {
    color: ${({ active }) => (active ? Colors.Blue50 : Colors.Neutral70)};
    background-color: ${({ active }) => (active ? Colors.Blue10 : Colors.Neutral10)};
  }

  ${({ active }) =>
    active &&
    css`
      color: ${Colors.Blue50};
      background-color: ${Colors.Blue10};
    `}

  ${({ open }) =>
    !open &&
    css`
      padding: 9px 12px;
    `}
`

export const Text = styled.div`
  padding-top: 2px;
`

export const TextContainer = styled.div`
  gap: 6px;
  display: flex;
  align-items: center;
`

export const PreDecoration = styled.div<{ active?: boolean }>`
  position: relative;
  display: inline-block;
  color: ${Colors.White};

  ${({ active }) =>
    !active &&
    css`
      svg {
        color: ${Colors.Neutral40};
      }

      ${Container}:hover & {
        svg {
          color: ${Colors.Neutral50};
        }
      }
    `}
`

export const PostDecoration = styled.div``
export const Badge = styled.div`
  top: -2px;
  right: -3px;
  position: absolute;
  padding: 4px 4px;
  border-radius: 50%;
  border: 2px solid ${Colors.White};
  background-color: ${Colors.Blue50};
`

export const CrownIcon = styled(Icon20.Crown).attrs({
  width: 16,
  height: 16,
})`
  position: absolute;
  bottom: -5px;
  right: -5px;
  background-color: ${Colors.White};
  border-radius: 40%;
  color: ${Colors.Indigo70} !important;
`

export const TooltipWrapper = styled(Tooltip)<{ $isOpen?: boolean }>`
  & :first-child > :first-child {
    padding: 4px 8px;
    max-width: 142px;
    margin-left: ${({ $isOpen }) => ($isOpen ? '-38px' : '-128px')};

    ${Typography.Tiny}
    ${FontWeights.Regular}
  }

  & :first-child > div > svg {
    color: ${Colors.Neutral90};
    left: -10px;
    height: 5px;
    width: 16px;
  }
`
