import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  display: absolute;
  height: 80px;
  left: 160px;
  background: ${Colors.Neutral5};

  ${Media.Tablet`
    height: 100px;
  `};

  ${Media.Phone`
    height: 144px;
  `};

  @media (max-width: 280px) {
    height: 180px;
  }
`

export const InboxImage = styled.img`
  width: 48px;
  height: 48px;
  position: absolute;
  margin-top: 40px;
  margin-left: 46px;
  flex-shrink: 0;
  border-radius: 50%;
  margin-top: 16px;
  margin-left: 16px;
`

export const Title = styled.div`
  position: absolute;
  height: 24px;
  padding-top: 28px;
  padding-left: 84px;
  ${Typography.Body};
  ${FontWeights.Medium};
  display: flex;
  align-items: center;
  color: ${Colors.Neutral80};

  ${Media.Phone`
    padding-top: 5px;
    height: 45px;
    ${Typography.Body};
    ${FontWeights.Medium};
    margin-bottom: 4px;
  `};
`

export const Text = styled.div`
  position: absolute;
  height: 20px;
  padding-top: 50px;
  padding-left: 84px;
  ${Typography.Caption};
  ${FontWeights.Regular};
  display: flex;
  align-items: center;
  color: ${Colors.Neutral80};

  ${Media.Tablet`
    margin-top:10px;
    width: 550px;
  `};

  ${Media.Phone`
    width: 100%;
    height: 85px;
    margin-right: 4px;
    ${Typography.Caption};
    ${FontWeights.Regular};
    flex-grow: 1;
  `};

  @media (max-width: 280px) {
    margin-top: 19px;
  }
`

export const ButtonContainer = styled.div`
  margin-top: 22px;
  display: flex;
  position: absolute;
  flex-direction: row;
  right: 15px;

  ${Media.Phone`
  margin-top: 98px;
  `};

  @media (max-width: 280px) {
    margin-top: 135px;
  }
`
