import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { MAX_SKILLS_SELECTION_LIMIT, AccSkillsType } from 'builder/modules/sevenStories'
import { Divider } from '../AccomplishmentsRank/style'

import { Container } from './styles'
import Tips from './components/Tips'
import DroppableWrapper from './components/DroppableWrapper'

type Props = {
  accomplishmentsSkills: AccSkillsType[]
  handleSortEnd: (oldIndex: number, newIndex: number) => void
  handleSkillSelect: (id: number, storyID: number) => void
}

const Get5ReviewSkills = ({ handleSortEnd, handleSkillSelect, accomplishmentsSkills }: Props) => {
  const { isPhone } = useMediaQueries()
  const isSelectionAllowed =
    accomplishmentsSkills.filter(skill => skill.isSelected).length < MAX_SKILLS_SELECTION_LIMIT

  const isAnySkillSelected = accomplishmentsSkills.filter(skill => skill.isSelected).length

  const handleSelect = (id: number, storyID: number) => handleSkillSelect(id, storyID)

  return (
    <Container>
      {!isPhone && <Tips />}
      <DroppableWrapper
        flag={true}
        {...{
          handleSelect,
          handleSortEnd,
          isSelectionAllowed,
          accomplishmentsSkills,
        }}
      />
      {isAnySkillSelected ? <Divider isItemSelected={isAnySkillSelected !== -1} /> : null}
      <DroppableWrapper
        flag={false}
        {...{
          handleSelect,
          handleSortEnd,
          isSelectionAllowed,
          accomplishmentsSkills,
        }}
      />
    </Container>
  )
}

export default Get5ReviewSkills
