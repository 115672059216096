import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import Sizes from 'builder/styles/sizes'
import { Icon20 } from 'builder/components/Icon'
import Shadows from 'builder/styles/shadows'

export const Container = styled.div`
  width: 544px;
  border-radius: 16px;
  background: ${Colors.White};
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  ${Media.Phone`
    width: 343px;
    padding: 20px;
  `};
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: ${Sizes.XS};
  margin-bottom: ${Sizes.XS};
  width: 100%;

  ${Media.Phone`
    margin-bottom: 8px;
    gap: 8px;
  `};
`

export const FeaturedIcon = styled(Icon20.Featured)`
  width: 35px;
  height: 35px;
  color: ${Colors.Indigo50};
`

export const FeaturedIconButton = styled(Icon20.Featured)`
  margin-right: 4px;
`

export const Title = styled.p`
  ${Typography.S};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral90};
`

export const SubTitle = styled.p`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
`

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`

export const OptionContainer = styled.div`
  width: 100%;
  gap: 8px;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  ${Media.Phone`
    margin-bottom: 20px;
  `};
`

export const Option = styled.div<{ checked: boolean }>`
  padding: 22px 16px;
  border-radius: 8px;
  border: 2px solid ${props => (props.checked ? Colors.Indigo50 : Colors.Neutral15)};
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;

  &:hover {
    background: ${Colors.Indigo10};
    color: ${Colors.Indigo70};
  }

  ${Media.Phone`
    height: 80px;
  `};
`

export const Radio = styled.label<{ checked: boolean }>`
  display: flex;
  align-items: flex-start;
  color: ${props => (props.checked ? Colors.Indigo50 : Colors.Neutral30)};
  transition: color 0.15s;
  cursor: pointer;
`

export const RadioControl = styled.span<{ checked?: boolean }>`
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid ${Colors.Neutral30};
  transition: border-color 0.15s;

  ${Media.Phone`
    margin-top: 0;
  `};

  ${props =>
    props.checked &&
    css`
      border-width: 2px;
      border-color: ${Colors.Indigo50};
      background-color: ${Colors.Indigo50};
    `}

  &:before {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${Colors.White};
    opacity: ${props => (props.checked ? 1 : 0)};
  }
`

export const OptionTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: inherit;
  gap: 8px;
`

export const OptionHeading = styled.p`
  ${Typography.Body};
  ${FontWeights.Regular};
`

export const CloseIcon = styled(Icon20.Close)`
  color: ${Colors.Neutral50};
  position: absolute;
  top: 32px;
  right: 32px;
  border-radius: 28px;
  width: 28px;
  height: 28px;
  padding: 4px;
  background: ${Colors.Neutral10};
  cursor: pointer;

  &:hover {
    background: ${Colors.Blue10};
    color: ${Colors.Blue50};
  }

  ${Media.Phone`
    top: 20px;
    right: 20px;
  `};
`

export const CompanyLogo = styled.img<{ size: number }>(({ size }) => {
  return css`
    width: ${size}px;
    height: ${size}px;
    ${Shadows.lightWithBorder.low};
    border-radius: 4px;

    ${Media.Phone`
      width: ${size}px;
      height: ${size}px;
    `};
  `
})
