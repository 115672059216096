import { Optional } from 'packages/types'
import { i18n as I18n } from 'builder/utils/i18n'
import { SectionNames } from 'builder/modules/resumeEditor'
import Card from 'builder/components/Card'
import { CareerProfileData } from 'builder/modules/careerProfile'

export function constructSectionsData(careerProfile: CareerProfileData, i18n: typeof I18n) {
  return [
    {
      id: SectionNames.workExperiences,
      title: i18n.t('builder.career_profile.editor.work_experience_section.title'),
      addButtonText: careerProfile.workExperiencesAttributes.length
        ? i18n.t('builder.career_profile.editor.work_experience_section.add_one_more')
        : i18n.t('builder.career_profile.editor.work_experience_section.add'),
      CardComponent: Card.WorkExperience,
      items: careerProfile.workExperiencesAttributes,
      hintText: i18n.t('builder.career_profile.editor.work_experience_section.hint'),
    },
    {
      id: SectionNames.educations,
      title: i18n.t('builder.career_profile.editor.educations_section.title'),
      addButtonText: careerProfile.educationsAttributes.length
        ? i18n.t('builder.career_profile.editor.educations_section.add_one_more')
        : i18n.t('builder.career_profile.editor.educations_section.add'),
      CardComponent: Card.Education,
      items: careerProfile.educationsAttributes,
      hintText: I18n.t('builder.resume_editor.educations_section.hint'),
    },
    {
      id: SectionNames.skills,
      title: i18n.t('builder.career_profile.editor.skills_section.title'),
      addButtonText: careerProfile.skillsAttributes.length
        ? i18n.t('builder.career_profile.editor.skills_section.add_one_more')
        : i18n.t('builder.career_profile.editor.skills_section.add'),
      CardComponent: Card.Skill,
      items: careerProfile.skillsAttributes,
    },
    {
      id: SectionNames.courses,
      title: i18n.t('builder.career_profile.editor.courses_section.title'),
      addButtonText: careerProfile.coursesAttributes.length
        ? i18n.t('builder.career_profile.editor.courses_section.add_one_more')
        : i18n.t('builder.career_profile.editor.courses_section.add'),
      CardComponent: Card.Course,
      items: careerProfile.coursesAttributes,
    },
  ]
}

export const clearTemporalIds = (array: { id: Optional<string | number> }[]) => {
  return array.map(item => (typeof item.id === 'string' ? { ...item, id: undefined } : item))
}
