import styled from 'styled-components'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'

interface ServiceProps {
  color: string
  bgColor: string
}

export const Container = styled.div``

export const Header = styled.div`
  ${FontWeights.Medium};
  padding-bottom: 16px;
`

export const Cards = styled.div`
  gap: 12px;
  display: flex;
  flex-wrap: wrap;

  ${Media.Phone`
    gap: 16px;
  `}
`

export const Card = styled.div<{ service: ServiceProps }>`
  gap: 4px;
  padding: 24px;
  display: ${({ service }) => (service.bgColor ? 'flex' : 'none')};
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  box-sizing: border-box;
  width: 330px;
  background: ${({ service }) => service.bgColor};
  color: ${({ service }) => service.color};

  ${Media.Phone`
    width: 100%
  `}
`

export const Text = styled.div`
  ${FontWeights.Medium};
`

export const SubText = styled.div`
  ${Media.Phone`
    ${Typography.Caption}
`}
`
