import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Button from 'builder/components/Button'

export const BannerContainer = styled.div`
  background-color: ${Colors.White};
  padding: 24px;
  border-radius: 8px;
  z-index: 5;
  position: sticky;
  bottom: 16px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`

export const Banner = styled.div`
  display: flex;
`

export const ContentWrapper = styled.div`
  margin-left: 16px;
  width: 487px;
`

export const BannerImage = styled.img``

export const BannerTitle = styled.div`
  ${Typography.Body};
  ${FontWeights.DemiBold}
`

export const BannerContent = styled.div`
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
  margin-top: 4px;
`
export const CloseButton = styled(Button)`
  padding: 0px 25px;
  margin-right: 8px;
  float: right;
`
export const OpenButton = styled(Button)`
  padding: 0px 25px;
  float: right;
`
