import React, { createContext, useCallback, useMemo, useState, useEffect } from 'react'
import {
  SnackBarTypes,
  SnackBarContextProviderProps,
  OpenSnackBarParams,
  SnackBarContextType,
  SnackBarPosition,
  SnackBarAction,
} from './types'
import {
  BOTTOM_VALUE_PHONE_DEFAULT,
  BOTTOM_VALUE_DESKTOP_DEFAULT,
  AUTO_DISMISS_TIME_DEFAULT,
  AUTO_DISMISS_TIME_MIN,
  AUTO_DISMISS_TIME_MAX,
} from './constants'

const defaultSnackBarState = {
  text: '',
  type: SnackBarTypes.simple,
  isDismissible: false,
  action: {} as SnackBarAction,
  autoDismissTime: AUTO_DISMISS_TIME_DEFAULT,
  bottom: {
    phone: BOTTOM_VALUE_PHONE_DEFAULT,
    desktop: BOTTOM_VALUE_DESKTOP_DEFAULT,
  } as SnackBarPosition,
}

export const SnackBarContext = createContext<SnackBarContextType>({
  ...defaultSnackBarState,
  isOpen: false,
  openSnackBar: () => {},
  closeSnackBar: () => {},
  clearSnackBarState: () => {},
})

export const SnackBarContextProvider: React.FC<SnackBarContextProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [snackBarState, setSnackBarState] = useState(defaultSnackBarState)

  const openSnackBar = useCallback(
    ({
      text = '',
      type = SnackBarTypes.simple,
      isDismissible = false,
      action: { onClick, text: actionText } = {},
      autoDismissTime = AUTO_DISMISS_TIME_DEFAULT,
      bottom = {
        phone: BOTTOM_VALUE_PHONE_DEFAULT,
        desktop: BOTTOM_VALUE_DESKTOP_DEFAULT,
      } as SnackBarPosition,
    }: OpenSnackBarParams) => {
      setIsOpen(true)
      setSnackBarState({
        text,
        type,
        isDismissible,
        action: { onClick, text: actionText || '' },
        autoDismissTime: Math.min(
          Math.max(autoDismissTime, AUTO_DISMISS_TIME_MIN),
          AUTO_DISMISS_TIME_MAX,
        ),
        bottom,
      })
    },
    [],
  )

  const closeSnackBar = useCallback(() => {
    setIsOpen(false)
  }, [])

  const clearSnackBarState = useCallback(() => {
    setSnackBarState(defaultSnackBarState)
  }, [])

  useEffect(() => {
    let timer: NodeJS.Timeout

    if (isOpen && !snackBarState.isDismissible) {
      timer = setTimeout(() => {
        closeSnackBar()
      }, snackBarState.autoDismissTime * 1000)
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [snackBarState, closeSnackBar, isOpen])

  const value = useMemo(
    () => ({
      ...snackBarState,
      isOpen,
      openSnackBar,
      closeSnackBar,
      clearSnackBarState,
    }),
    [snackBarState, isOpen, openSnackBar, closeSnackBar, clearSnackBarState],
  )

  return <SnackBarContext.Provider value={value}>{children}</SnackBarContext.Provider>
}
