import React from 'react'
import { useSelector } from 'react-redux'
import { Store } from 'builder/modules/store'
import { SectionContainer, SectionContent } from 'builder/components/ProfileSummary/styles'
import { SectionHeader, SectionHint } from 'builder/components/Section'
import SectionTitle from 'builder/components/SectionTitle'
import { getWorkExperienceSection } from '../ExpandableSections'
import ExpandableSectionWrapper from '../../ExpandableSectionWrapper'
import { useShokumuTranslations } from '../../useShokumuTranslations'

export const WorkExperience: React.FC = () => {
  const resume = useSelector((state: Store) => state.resumeEditor.resume)
  const { i18n } = useShokumuTranslations()
  const title = i18n('work_experience.title')
  const hint = i18n('work_experience.hint')

  return (
    <SectionContainer>
      <SectionHeader>
        <SectionTitle title={title} />
        <SectionHint>{hint}</SectionHint>
      </SectionHeader>
      <SectionContent>
        <ExpandableSectionWrapper sections={getWorkExperienceSection(resume)} />
      </SectionContent>
    </SectionContainer>
  )
}
