import 'tippy.js/dist/tippy.css'
import Tippy, { TippyProps } from '@tippyjs/react/headless'

import { PopoverArrow, PopoverContainer } from './styles'

type PopoverProps = {
  component: React.ReactElement
  containerStyles?: React.CSSProperties
} & TippyProps

export const Popover = ({ children, component, containerStyles, ...rest }: PopoverProps) => {
  return (
    <Tippy
      {...rest}
      render={attrs => (
        <PopoverContainer {...attrs} style={containerStyles} className="tippy-box">
          <PopoverArrow data-popper-arrow="" className="tippy-arrow" />
          {component}
        </PopoverContainer>
      )}
    >
      {children}
    </Tippy>
  )
}
