import styled, { css } from 'styled-components'
import { Media } from 'builder/styles/media'
import { Button } from 'builder/components/Button'

export const FormContainer = styled.form`
  width: 100%;
`

export const RowContainer = styled.div<{ position?: string }>`
  ${props =>
    props.position &&
    css`
      position: ${props.position || 'static'};
    `}

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`

export const RowsContainer = styled.div`
  width: 100%;
`

export const ButtonsContainer = styled.div<{ signUpFlow: string | undefined }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${props => (props.signUpFlow === 'RECRUITERDOTCOM' ? 32 : 48)}px;

  ${Media.Phone`
    margin-top: 32px;
  `};

  & ${Button}:last-child {
    margin-left: auto;
  }
`
export const Spacer = styled.div`
  flex: 1;
`
export const BottomContainer = styled.div`
  padding-top: 10px;
`
