import { useState, useLayoutEffect, useRef } from 'react'
import { reflow } from 'builder/utils/reflow'
import Icon from 'builder/components/Icon'
import Button, { ButtonSize, ButtonTheme } from 'builder/components/Button'

import {
  CoRegPopupContainer,
  CoRegPopupContent,
  CoRegPopupTitle,
  CoRegPopupText,
  CoRegPopupButtonIcon,
  CoRegPopupVisual,
  CoRegPopupClose,
} from './styles'

export enum CoRegPopupPositions {
  top = 'top',
  bottom = 'bottom',
}

interface CoRegPopupProps {
  onClick: () => void
  onClose: () => void
  position?: CoRegPopupPositions
}

const CoRegPopup = ({
  position = CoRegPopupPositions.top,
  onClick,
  onClose,
  ...rest
}: CoRegPopupProps) => {
  const [isVisible, setVisible] = useState(false)
  const container = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    container.current && reflow(container.current)
    setVisible(true)
  }, [])

  return (
    // TODO: i18n
    <CoRegPopupContainer ref={container} isVisible={isVisible} position={position} {...rest}>
      <CoRegPopupContent>
        <CoRegPopupTitle>Deel jouw CV met alle job boards</CoRegPopupTitle>
        <CoRegPopupText>
          Wordt gevonden door duizenden bedrijven en ontvang meer baan aanbiedingen. Het is gratis.
        </CoRegPopupText>
        <Button size={ButtonSize.small} theme={ButtonTheme.alternative} onClick={onClick}>
          Deel mijn CV
          <CoRegPopupButtonIcon />
        </Button>
        <CoRegPopupVisual />
      </CoRegPopupContent>
      <CoRegPopupClose onClick={onClose}>
        <Icon.Close />
      </CoRegPopupClose>
    </CoRegPopupContainer>
  )
}

export default CoRegPopup
