import { useI18n } from 'builder/hooks/useI18n'
import Button from 'builder/components/Button'
import { Modal } from '../../Modal/Modal'
import { Bottom, Content, Title } from './styles'

export const TRANSLATION = 'builder.interview_prep_view.interview_player'

type Props = {
  onClose: () => void
  onComplete: () => void
}

export const CompleteModal = (props: Props) => {
  const { onClose, onComplete } = props
  const { i18n } = useI18n()
  return (
    <Modal onClose={onClose} title={<Title>{i18n.t(`${TRANSLATION}.complete_modal_title`)}</Title>}>
      <Content>{i18n.t(`${TRANSLATION}.complete_modal_content`)}</Content>
      <Bottom>
        <Button theme="ghost" onClick={onClose}>
          {i18n.t(`${TRANSLATION}.cancel`)}
        </Button>

        <Button onClick={onComplete}>{i18n.t(`${TRANSLATION}.complete`)}</Button>
      </Bottom>
    </Modal>
  )
}
