import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { gradient } from 'builder/styles/keyframes'

export const Container = styled.div`
  position: relative;
  border-radius: 6px;
  background-color: ${Colors.White};
  overflow: hidden;
  width: ${props => `${props.w}px`};
  height: ${props => `${props.h}px`};
`

export const LoaderContainer = styled.div`
  animation: ${gradient} 1s linear infinite;
  background: ${Colors.Neutral10};
  background: linear-gradient(
    to right,
    ${Colors.Neutral10} 5%,
    #eaeef6 15%,
    ${Colors.Neutral10} 25%
  );
  background-size: 100vw 100vh;
`

export const Loader = styled.img`
  height: 100%;
  width: 100%;
`

const Canvas = styled.canvas`
  transform: scale(${props => props.ratio});
  transform-origin: top left;
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: ${props => (props.visible ? 'none' : 'opacity 0.2s')};
  z-index: ${props => (props.visible ? 1 : 2)};
`

export const PrimaryCanvas = styled(Canvas)`
  position: relative;
`

export const SecondaryCanvas = styled(Canvas)`
  position: absolute;
  top: 0;
  left: 0;
`
