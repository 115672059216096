import * as Styled from './styles'

interface IProps {
  totalSteps: number
  completedSteps: number
}

const CircularProgressBar = ({ totalSteps, completedSteps }: IProps) => {
  const circumference = 116.238
  const offset = circumference - (completedSteps / totalSteps) * circumference

  return (
    <Styled.ProgressCircle>
      <Styled.ProgressNumber>
        {completedSteps}/{totalSteps}
      </Styled.ProgressNumber>
      <Styled.ProgressRing width="40" height="40">
        <Styled.BackgroundCircle stroke-width="3" r="18.5" cx="20" cy="20" />
        <Styled.ProgressRingCircle
          stroke-width="3"
          fill="transparent"
          r="18.5"
          cx="20"
          cy="20"
          offset={offset}
        />
      </Styled.ProgressRing>
    </Styled.ProgressCircle>
  )
}

export default CircularProgressBar
