import { useCallback, useMemo, useRef, useState } from 'react'
import { Optional, Resume } from 'packages/types'
import { useDispatch } from 'react-redux'
import { useUpdateSimpleField } from 'builder/hooks/useUpdateSimpleField'
import { SectionNames, actions } from 'builder/modules/resumeEditor'
import { convertHtmlToText } from 'builder/utils/convertHtmlToText'

export const useEditContainer = (resumeOld: Resume | null, resumeTailored: Resume | null) => {
  const { updateSimpleField } = useUpdateSimpleField()
  const dispatch = useDispatch()
  const [openWorExperienceCardId, setOpenWorExperienceCardId] = useState<string | null>(null)
  const scrollRef = useRef<HTMLDivElement>(null)
  const {
    profile: profileSummary,
    skills: skillsOld = [],
    position: jobTitleOld,
    workExperiences: workExperiencesOld = [],
  } = resumeOld ?? {}
  const {
    profile: profileSummaryTailored,
    locale,
    skills: skillsTailored = [],
    position: jobTitleTailored,
    workExperiences: workExperiencesTailored = [],
  } = resumeTailored ?? {}

  const profileSummaryOld = convertHtmlToText(profileSummary || '')

  const isJobTitleChanged = String(jobTitleOld).trim() !== String(jobTitleTailored).trim()

  const isProfileChanged =
    String(profileSummaryOld).trim() !== String(profileSummaryTailored).trim()

  const isWorkExperienceChanged = useMemo(() => {
    if (workExperiencesOld.length !== workExperiencesTailored.length) return true

    return workExperiencesOld.some((item, index) => {
      return (
        item.title !== workExperiencesTailored[index]?.title ||
        item.description !== workExperiencesTailored[index]?.description ||
        item.employer !== workExperiencesTailored[index]?.employer ||
        item.city !== workExperiencesTailored[index]?.city
      )
    })
  }, [workExperiencesOld, workExperiencesTailored])

  const isSkillsChanged = useMemo(() => {
    if (skillsOld.length !== skillsTailored.length) return true

    return skillsOld.some((item, index) => item.skill !== skillsTailored[index]?.skill)
  }, [skillsOld, skillsTailored])

  const handleProfileSummaryChange = useCallback(
    value => updateSimpleField({ name: 'profile', value, debounce: true }),
    [updateSimpleField],
  )

  const handleJobTitleChange = useCallback(
    value => updateSimpleField({ name: 'position', value, debounce: true }),
    [updateSimpleField],
  )

  const handleDeleteSkill = useCallback(
    (cardId: Optional<string | number>) => {
      if (!cardId) return

      dispatch(
        actions.deleteCard({
          sectionName: SectionNames.skills,
          cardId,
        }),
      )
    },
    [dispatch],
  )

  const handleWorkExperienceChange = useCallback(
    (description, cardId) => {
      dispatch(
        actions.updateCard({
          sectionId: SectionNames.workExperiences,
          cardId,
          values: { description },
        }),
      )
    },
    [dispatch],
  )

  const handleWorkExperienceToggle = useCallback(
    cardId => {
      if (cardId === openWorExperienceCardId) {
        setOpenWorExperienceCardId(null)
      } else {
        setOpenWorExperienceCardId(cardId)
      }
    },
    [openWorExperienceCardId],
  )

  return {
    profileSummaryOld,
    profileSummaryTailored,
    skillsOld,
    skillsTailored,
    locale,
    jobTitleOld,
    jobTitleTailored,
    workExperiencesOld,
    workExperiencesTailored,
    openWorExperienceCardId,
    updateSimpleField,
    handleProfileSummaryChange,
    handleDeleteSkill,
    handleJobTitleChange,
    handleWorkExperienceChange,
    handleWorkExperienceToggle,
    isJobTitleChanged,
    isProfileChanged,
    isWorkExperienceChanged,
    isSkillsChanged,
    scrollRef,
  }
}
