import { Colors } from 'builder/styles/colors'
import * as Styles from './styles'

export const Background = () => {
  const activeRectColors = `${Colors.Neutral5}; ${Colors.Neutral10}`
  const notActiveRectColors = `${Colors.Neutral10}; ${Colors.Neutral5}`

  const connectStartColor = Colors.Blue20
  const connectEndColor = Colors.Indigo20

  return (
    <svg
      width="1120"
      height="426"
      viewBox="0 0 1120 426"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <Styles.CardRect width="352" height="126" rx="16" id="e11">
        <animate
          attributeName="fill"
          begin="0.1s;a3c_e33.end"
          dur="0.5s"
          values={activeRectColors}
          fill="freeze"
          id="a1_e11"
        />
        <animate
          attributeName="fill"
          begin="a1_e33.end"
          dur="0.5s"
          values={notActiveRectColors}
          fill="freeze"
          id="ac_e11"
        />
      </Styles.CardRect>
      <Styles.CardRect x="384" width="352" height="126" rx="16" id="e12">
        <animate
          attributeName="fill"
          begin="a3_conn16.end"
          dur="0.5s"
          values={activeRectColors}
          fill="freeze"
          id="a3_e12"
        />
        <animate
          attributeName="fill"
          begin="a3_e33.end"
          dur="0.5s"
          values={notActiveRectColors}
          fill="freeze"
          id="ac_e12"
        />
      </Styles.CardRect>
      <Styles.CardRect x="768" width="352" height="126" rx="16" id="e13">
        <animate
          attributeName="fill"
          begin="a1_conn13.end"
          dur="0.5s"
          values={activeRectColors}
          fill="freeze"
          id="a1_e13"
        />
        <animate
          attributeName="fill"
          begin="a1_e13.end"
          dur="0.5s"
          values={notActiveRectColors}
          fill="freeze"
          id="ac_e13"
        />
      </Styles.CardRect>
      <Styles.CardRect y="150" width="352" height="126" rx="16" id="e21">
        <animate
          attributeName="fill"
          begin="ac_e11.end+0.5s"
          dur="0.5s"
          values={activeRectColors}
          fill="freeze"
          id="a2_e21"
        />
        <animate
          attributeName="fill"
          begin="a2_e23.end"
          dur="0.5s"
          values={notActiveRectColors}
          fill="freeze"
          id="ac_e21"
        />
      </Styles.CardRect>
      <Styles.CardRect x="384" y="150" width="352" height="126" rx="16" id="e22">
        <animate
          attributeName="fill"
          begin="a1_conn11.end"
          dur="0.5s"
          values={activeRectColors}
          fill="freeze"
          id="a1_e22"
        />
        <animate
          attributeName="fill"
          begin="a1_e33.end"
          dur="0.5s"
          values={notActiveRectColors}
          fill="freeze"
          id="ac_e22"
        />
      </Styles.CardRect>
      <Styles.CardRect x="768" y="150" width="352" height="126" rx="16" id="e23">
        <animate
          attributeName="fill"
          begin="a2_conn15.end"
          dur="0.5s"
          values={activeRectColors}
          fill="freeze"
          id="a2_e23"
        />
        <animate
          attributeName="fill"
          begin="a2_e23.end"
          dur="0.5s"
          values={notActiveRectColors}
          fill="freeze"
          id="ac_e23"
        />
      </Styles.CardRect>
      <Styles.CardRect y="300" width="352" height="126" rx="16" id="e31">
        <animate
          attributeName="fill"
          begin="ac_e21.end+0.5s"
          dur="0.5s"
          values={activeRectColors}
          fill="freeze"
          id="a3_e31"
        />
        <animate
          attributeName="fill"
          begin="a3_e33.end"
          dur="0.5s"
          values={notActiveRectColors}
          fill="freeze"
          id="ac_e31"
        />
      </Styles.CardRect>
      <Styles.CardRect x="384" y="300" width="352" height="126" rx="16" id="e32">
        <animate
          attributeName="fill"
          begin="a2_conn14.end"
          dur="0.5s"
          values={activeRectColors}
          fill="freeze"
          id="a2_e32"
        />
        <animate
          attributeName="fill"
          begin="a2_e23.end"
          dur="0.5s"
          values={notActiveRectColors}
          fill="freeze"
          id="ac_e32"
        />
      </Styles.CardRect>
      <Styles.CardRect x="768" y="300" width="352" height="126" rx="16" id="e33">
        <animate
          attributeName="fill"
          begin="a1_conn12.end"
          dur="0.5s"
          values={activeRectColors}
          fill="freeze"
          id="a1_e33"
        />
        <animate
          attributeName="fill"
          begin="a1_e33.end"
          dur="0.5s"
          values={notActiveRectColors}
          fill="freeze"
          id="ac_e33"
        />
        <animate
          attributeName="fill"
          begin="a3_conn17.end"
          dur="0.5s"
          values={activeRectColors}
          fill="freeze"
          id="a3_e33"
        />
        <animate
          attributeName="fill"
          begin="a3_e33.end"
          dur="0.5s"
          values={notActiveRectColors}
          fill="freeze"
          id="a3c_e33"
        />
      </Styles.CardRect>
      <mask id="mask0_117_26487" maskUnits="userSpaceOnUse" x="352" y="61" width="32" height="154">
        <rect x="352" y="61" width="32" height="154" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_117_26487)">
        <Styles.MConnection
          d="M352 63V63C360.837 63 368 70.1634 368 79V197C368 205.837 375.163 213 384 213V213"
          stroke="url(#paint1_linear_2618_66152)"
          strokeWidth="4"
          id="conn11"
        >
          <animate
            attributeName="stroke-dashoffset"
            begin="a1_e11.end"
            dur="0.65s"
            to="0"
            fill="freeze"
            id="a1_conn11"
          />
          <animate
            attributeName="stroke-dashoffset"
            begin="a1_e13.end"
            dur="0.65s"
            to="183"
            fill="freeze"
            id="ac_conn11"
          />
        </Styles.MConnection>
      </g>
      <mask id="mask1_117_26487" maskUnits="userSpaceOnUse" x="736" y="211" width="32" height="154">
        <rect x="736" y="211" width="32" height="154" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask1_117_26487)">
        <Styles.MConnection
          d="M736 213V213C744.837 213 752 220.163 752 229V347C752 355.837 759.163 363 768 363V363"
          stroke="url(#paint2_linear_2618_66152)"
          strokeWidth="4"
          id="conn12"
        >
          <animate
            attributeName="stroke-dashoffset"
            begin="a1_e22.end"
            dur="0.65s"
            to="0"
            fill="freeze"
            id="a1_conn12"
          />
          <animate
            attributeName="stroke-dashoffset"
            begin="a1_e33.end"
            dur="0.65s"
            to="183"
            fill="freeze"
            id="ac_conn12"
          />
        </Styles.MConnection>
      </g>
      <mask id="mask2_117_26487" maskUnits="userSpaceOnUse" x="736" y="61" width="32" height="154">
        <rect x="736" y="61" width="32" height="154" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask2_117_26487)">
        <Styles.MConnection
          d="M736 213V213C744.837 213 752 205.837 752 197V79C752 70.1634 759.163 63 768 63V63"
          stroke="url(#paint3_linear_2618_66152)"
          strokeWidth="4"
          id="conn13"
        >
          <animate
            attributeName="stroke-dashoffset"
            begin="a1_e22.end"
            dur="0.65s"
            to="0"
            fill="freeze"
            id="a1_conn13"
          />
          <animate
            attributeName="stroke-dashoffset"
            begin="a1_e13.end"
            dur="0.65s"
            to="183"
            fill="freeze"
            id="ac_conn13"
          />
        </Styles.MConnection>
      </g>
      <mask
        id="mask3_2618_66152"
        maskUnits="userSpaceOnUse"
        x="352"
        y="211"
        width="32"
        height="154"
      >
        <rect x="352" y="211" width="32" height="154" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask3_2618_66152)">
        <Styles.MConnection
          d="M352 213V213C360.837 213 368 220.163 368 229V347C368 355.837 375.163 363 384 363V363"
          stroke="url(#paint4_linear_2618_66153)"
          strokeWidth="4"
          id="conn14"
        >
          <animate
            attributeName="stroke-dashoffset"
            begin="a2_e21.end"
            dur="0.65s"
            to="0"
            fill="freeze"
            id="a2_conn14"
          />
          <animate
            attributeName="stroke-dashoffset"
            begin="a2_e23.end"
            dur="0.65s"
            to="183"
            fill="freeze"
            id="ac_conn14"
          />
        </Styles.MConnection>
      </g>
      <mask
        id="mask4_2618_66152"
        maskUnits="userSpaceOnUse"
        x="736"
        y="211"
        width="32"
        height="154"
      >
        <rect x="736" y="211" width="32" height="154" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask4_2618_66152)">
        <Styles.MConnection
          d="M736 363V363C744.837 363 752 355.837 752 347V229C752 220.163 759.163 213 768 213V213"
          stroke="url(#paint5_linear_2618_66153)"
          strokeWidth="4"
          id="conn15"
        >
          <animate
            attributeName="stroke-dashoffset"
            begin="a2_e32.end"
            dur="0.65s"
            to="0"
            fill="freeze"
            id="a2_conn15"
          />
          <animate
            attributeName="stroke-dashoffset"
            begin="a2_e23.end"
            dur="0.65s"
            to="183"
            fill="freeze"
            id="ac_conn15"
          />
        </Styles.MConnection>
      </g>
      <mask id="mask5_2618_66152" maskUnits="userSpaceOnUse" x="352" y="61" width="32" height="304">
        <rect x="352" y="61" width="32" height="304" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask5_2618_66152)">
        <Styles.LConnection
          d="M352 363V363C360.837 363 368 355.837 368 347V79C368 70.1634 375.163 63 384 63V63"
          stroke="url(#paint6_linear_2618_66152)"
          strokeWidth="4"
          id="conn16"
        >
          <animate
            attributeName="stroke-dashoffset"
            begin="a3_e31.end"
            dur="0.8s"
            to="0"
            fill="freeze"
            id="a3_conn16"
          />
          <animate
            attributeName="stroke-dashoffset"
            begin="a3_e33.end"
            dur="1s"
            to="357"
            fill="freeze"
            id="ac_conn16"
          />
        </Styles.LConnection>
      </g>
      <mask id="mask6_2618_66152" maskUnits="userSpaceOnUse" x="736" y="61" width="32" height="304">
        <rect x="736" y="61" width="32" height="304" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask6_2618_66152)">
        <Styles.LConnection
          d="M736 63V63C744.837 63 752 70.1634 752 79V347C752 355.837 759.163 363 768 363V363"
          stroke="url(#paint7_linear_2618_66152)"
          strokeWidth="4"
          id="conn17"
        >
          <animate
            attributeName="stroke-dashoffset"
            begin="a3_e12.end"
            dur="0.8s"
            to="0"
            fill="freeze"
            id="a3_conn17"
          />
          <animate
            attributeName="stroke-dashoffset"
            begin="a3_e33.end"
            dur="1s"
            to="357"
            fill="freeze"
            id="ac_conn17"
          />
        </Styles.LConnection>
      </g>
      <defs>
        <linearGradient
          id="paint1_linear_2618_66152"
          x1="365.272"
          y1="-367.139"
          x2="384.562"
          y2="-367.139"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={connectStartColor} />
          <stop offset="1" stopColor={connectEndColor} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2618_66152"
          x1="749.272"
          y1="583.326"
          x2="768.562"
          y2="583.326"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={connectStartColor} />
          <stop offset="1" stopColor={connectEndColor} />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2618_66152"
          x1="749.272"
          y1="-157.326"
          x2="768.562"
          y2="-157.326"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={connectStartColor} />
          <stop offset="1" stopColor={connectEndColor} />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2618_66153"
          x1="365.272"
          y1="-157.326"
          x2="384.562"
          y2="-157.326"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={connectStartColor} />
          <stop offset="1" stopColor={connectEndColor} />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2618_66153"
          x1="749.272"
          y1="-157.326"
          x2="768.562"
          y2="-157.326"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={connectStartColor} />
          <stop offset="1" stopColor={connectEndColor} />
        </linearGradient>
        <linearGradient
          id="paint6_linear_2618_66152"
          x1="365.272"
          y1="1103.65"
          x2="384.562"
          y2="1103.65"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={connectStartColor} />
          <stop offset="1" stopColor={connectEndColor} />
        </linearGradient>
        <linearGradient
          id="paint7_linear_2618_66152"
          x1="749.272"
          y1="-677.652"
          x2="768.562"
          y2="-677.652"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={connectStartColor} />
          <stop offset="1" stopColor={connectEndColor} />
        </linearGradient>
      </defs>
    </svg>
  )
}
