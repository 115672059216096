import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'

export const Container = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`

export const Heading = styled.div``

export const Skills = styled.div`
  gap: 12px;
  display: flex;
  flex-wrap: wrap;
`

export const Skill = styled.div<{ isKnown: boolean; isExploreCareers: boolean }>`
  ${Typography.Caption};
  padding: 6px 10px;
  border-radius: 4px;
  color: ${({ isKnown, isExploreCareers }) => isKnown && !isExploreCareers && Colors.Green50};
  background-color: ${({ isKnown, isExploreCareers }) =>
    isKnown && !isExploreCareers ? Colors.Green10 : Colors.Neutral10};
`
