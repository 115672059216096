import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { Label, selectors, actions, CareerPathStep } from 'builder/modules/careerPath2'
import { Wrapper, Container, Content, Text, Title, IconBackground, EditIcon } from './styles'

const CurrentLevel = () => {
  const dispatch = useDispatch()
  const occupation = useTypedSelector(selectors.occupation)

  const handleEdit = (e: React.MouseEvent) => {
    e.stopPropagation()
    trackInternalEvent('edit_career_job_title', Label)
    dispatch(actions.setStep(CareerPathStep.QUESTIONNAIRE))
  }

  return (
    <Wrapper onClick={handleEdit}>
      <Container>
        <Content>
          <Text>Recent Job Title</Text>
          <Title>{occupation}</Title>
        </Content>
        <IconBackground onClick={handleEdit}>
          <EditIcon />
        </IconBackground>
      </Container>
    </Wrapper>
  )
}

export default CurrentLevel
