import React, { useState } from 'react'
import {
  AccordionContainer,
  AccordionContent,
  AccordionItem,
  AccordionTitle,
  ChevronIcon,
} from './styles'

interface Item {
  title: React.ReactElement | string
  content?: React.ReactElement | string
}
interface Props {
  items: Item[]
}
export const Accordion = ({ items }: Props) => {
  const [activeIndex, setActiveIndex] = useState<number>()

  const handleClick = (index: number) => {
    setActiveIndex(index === activeIndex ? undefined : index)
  }

  return (
    <AccordionContainer>
      {items.map((item: Item, index: number) => (
        <AccordionItem key={index}>
          <AccordionTitle onClick={() => handleClick(index)} $hasContent={!!item.content}>
            {item.title} {item.content && <ChevronIcon $up={index === activeIndex} />}
          </AccordionTitle>
          {item.content && (
            <AccordionContent $isOpen={index === activeIndex}>{item.content}</AccordionContent>
          )}
        </AccordionItem>
      ))}
    </AccordionContainer>
  )
}
