import { useDispatch } from 'react-redux'
import { SectionNames, actions } from 'builder/modules/resumeEditor'

type PropTypes = {
  id: number | SectionNames
  value: string
}

const useRenameSection = () => {
  const dispatch = useDispatch()

  const renameSection = (payload: PropTypes) =>
    dispatch(actions.renameSection({ ...payload, debounce: true }))

  return { renameSection }
}

export { useRenameSection }
