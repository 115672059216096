import styled from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'
import { Typography, FontWeights } from 'builder/styles/typography'
import { Shadows } from 'builder/styles/shadows'

export const Plate = styled.div`
  position: absolute;
  z-index: 1;
  left: 40px;
  right: 40px;
  bottom: 32px;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  box-shadow: 0 16px 24px ${hexToRgba(Colors.Green50, 0.08)},
    inset 0 -1px 0 0 ${hexToRgba(Colors.Green70, 0.16)}, inset 0 1px 0 0 ${Colors.White};
  background-color: ${hexToRgba(Colors.Green20, 0.5)};
`

export const Photo = styled.img`
  ${Shadows.light.low};
  width: 48px;
  height: 48px;
  margin-bottom: 8px;
  border-radius: 50%;
  background-color: ${Colors.White};
`

export const Text = styled.div`
  ${Typography.Caption};
  ${FontWeights.Medium};
  color: ${Colors.Neutral80};
  text-align: center;
`
