import styled from 'styled-components'
import Media from 'builder/styles/media'

export const Container = styled.div<{ isVisible: boolean }>`
  position: fixed;
  z-index: 2;
  left: 0;
  bottom: 24px;
  width: calc(50% - 280px);
  max-width: 321px;
  height: 367px;
  pointer-events: none;
  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: opacity 0.4s, visibility 0.4s;
  transition-delay: ${props => (props.isVisible ? '0.2s' : 0)};

  ${Media.Tablet`
    width: 100%;
    max-width: 100%;
    height: 236px;
    bottom: 150px;
  `};

  ${Media.Phone`
    width: 100%;
    max-width: 100%;
    height: 236px;
    bottom: 100px;
  `};

  ${Media.IphoneSE`
    display: none;
  `};
`

const Image = styled.div<{ isVisible: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom right;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: opacity 0.2s;

  ${Media.Tablet`
    margin: 0 50%;
    max-width: 200px;
    left: -100px;
  `};

  ${Media.Phone`
    margin: 0 50%;
    max-width: 138px;
    left: -69px;
  `};
`

export const SocialProfileImage = styled(Image)`
  background-image: url(${require('images/builder/sign-up/illustrations/sign-up-1.png')});
`

export const IntroductionImage = styled(Image)`
  background-image: url(${require('images/builder/sign-up/illustrations/sign-up-2.png')});
`

export const ContactInfoImage = styled(Image)`
  background-image: url(${require('images/builder/sign-up/illustrations/sign-up-3.png')});
`
