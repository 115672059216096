import { useEffect, useMemo, useState } from 'react'
import type { Resume, CoverLetter, UserDocumentTemplate, Optional } from '@rio/types'
import { apiClient } from 'builder/modules/apiClient'
import { formatDocumentPreviewUrl } from 'builder/utils/formatDocumentPreviewUrl'
import { DocumentTypes } from 'builder/modules/constants'

export const useDocxTemplates = (type: string) => {
  const [templates, setTemplates] = useState<UserDocumentTemplate[]>([])

  useEffect(() => {
    const scope = type === DocumentTypes.resume ? 'resumes' : 'cover-letters'

    apiClient.get<UserDocumentTemplate[]>(`/${scope}/templates`).then(({ data }) => {
      setTemplates(data.filter(t => t.supportedFormats.includes('docx')))
    })
  }, [type])

  return templates
}

export const useDocument = (id: number, type: string) => {
  const [currentDocument, setCurrentDocument] = useState<Resume | CoverLetter>()

  useEffect(() => {
    const scope = type === DocumentTypes.resume ? 'resumes' : 'cover-letters'

    apiClient.get(`/${scope}/${id}`).then(({ data }) => {
      setCurrentDocument(data)
    })
  }, [id, type])

  return currentDocument
}

interface PatchedTemplatePart extends Pick<UserDocumentTemplate, 'id' | 'name' | 'usersChose'> {
  preview: string
}

export const usePatchedTemplates = (
  document: Optional<Resume | CoverLetter>,
  templates: UserDocumentTemplate[],
): PatchedTemplatePart[] => {
  return useMemo(() => {
    if (!document) return []

    return templates.map(template => {
      // override the template's thumbnail URL with the real one
      return {
        id: template.id,
        name: template.name,
        usersChose: template.usersChose,
        preview: formatDocumentPreviewUrl(document, {
          size: 480,
          template: template.id,
          color: template.settings.color?.default,
        }),
      }
    })
  }, [document, templates])
}
