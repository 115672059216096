import { memo, Fragment, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import useHighlight from './hooks/useHighlight'
import Subphrase from './Subphrase'

import {
  Item,
  ItemText,
  ItemIcons,
  ItemArrowIcon,
  ItemCheckIcon,
  ItemSubphraseDivider,
} from './styles'

const Phrase = ({ item, onClick }) => {
  const { isActive, highlight, subphrases } = item
  const [isHovered, setHovered] = useState(false)
  const content = useHighlight(highlight)
  const hasSubphrases = subphrases && subphrases.length > 0
  const classNames = [isActive && 'is-phrase-added', isHovered && 'is-phrase-hover'].filter(Boolean)

  const handlePhraseClick = useCallback(
    event => {
      event.preventDefault()
      onClick(item)
    },
    [onClick, item],
  )

  const handlers = {
    onClick: handlePhraseClick,
    onMouseEnter: useCallback(() => setHovered(true), []),
    onMouseLeave: useCallback(() => setHovered(false), []),
  }

  // The entire element shouldn't be hoverable or clickable in order to provide better subphrases UI
  const wrapperHandlers = hasSubphrases ? {} : handlers
  const iconHandlers = hasSubphrases ? handlers : {}

  // These props make the whole Item component focusable if there're no subphrases
  const focusableProps = hasSubphrases ? {} : { as: 'a', href: '#', tabIndex: 0 }

  return (
    <Item
      className={classNames.join(' ')}
      hasSubphrases={hasSubphrases}
      {...wrapperHandlers}
      {...focusableProps}
    >
      <ItemIcons {...iconHandlers}>
        <ItemArrowIcon />
        <ItemCheckIcon />
      </ItemIcons>

      {!hasSubphrases && <ItemText>{content}</ItemText>}

      {hasSubphrases && (
        <ItemText>
          {subphrases.map(subphrase => (
            <Fragment key={subphrase.digest}>
              <Subphrase item={subphrase} onClick={onClick} />
              <ItemSubphraseDivider>•</ItemSubphraseDivider>
            </Fragment>
          ))}
        </ItemText>
      )}
    </Item>
  )
}

Phrase.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  scope: PropTypes.string,
}

export default memo(Phrase)
