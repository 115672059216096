import { useCallback, useMemo } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useTailoredResumeEdit } from '../../hooks/useTailoredResumeEdit'
import { EditContainer } from '../EditContainer/EditContainer'
import { EditDrawer } from '../EditDrawer/EditDrawer'
import { useOverviewModal } from '../../hooks/useOverviewModal'
import {
  Container,
  Header,
  Title,
  JobTitle,
  ScoreContainer,
  ScoreItemNew,
  ScoreItemOld,
  ScoreValue,
  ActionContainer,
  EditButton,
  SaveButton,
  FinishEditButton,
  TitleWrapper,
  ActionWrapper,
} from './styles'

export const TailoredResumeEdit = () => {
  const { i18n } = useI18n()
  const { handleApprove } = useOverviewModal()
  const {
    oldScore,
    newScore,
    tailoredDesignation,
    toggleEditContainer,
    isEditActive,
    currentResume,
    originalResume,
    isApproving,
  } = useTailoredResumeEdit()

  const handleFinishEditClick = useCallback(() => {
    toggleEditContainer()
    trackInternalEvent('click_finish_editing', {
      label: 'auto_tailoring_overview_screen',
      score: newScore,
    })
  }, [newScore, toggleEditContainer])

  const handleApproveClick = useCallback(() => {
    handleApprove()
    trackInternalEvent('click_approve', {
      label: 'auto_tailoring_overview_screen',
      score: newScore,
    })
  }, [handleApprove, newScore])

  const handleEditClick = useCallback(() => {
    toggleEditContainer()
    trackInternalEvent('click_edit', {
      label: 'auto_tailoring_overview_screen',
      score: newScore,
    })
  }, [toggleEditContainer, newScore])

  const { isTablet, isPhone } = useMediaQueries()

  const EditSection = useMemo(() => {
    const newScoreText = isPhone
      ? i18n.t('builder.auto_tailoring.overview.info_section.new_score_mobile')
      : i18n.t('builder.auto_tailoring.overview.info_section.new_score')
    return (
      <Container isEditActive={isEditActive}>
        <Header>
          <TitleWrapper>
            <Title>{i18n.t('builder.auto_tailoring.overview.info_section.title')}</Title>
            <JobTitle>{tailoredDesignation}</JobTitle>
          </TitleWrapper>
          <ScoreContainer>
            {newScore ? (
              <ScoreItemNew>
                <ScoreValue score={newScore}>{newScore}</ScoreValue>
                {newScoreText}
              </ScoreItemNew>
            ) : null}
            {oldScore ? (
              <ScoreItemOld>
                {`${i18n.t('builder.auto_tailoring.overview.info_section.old_score')} ${oldScore}%`}
              </ScoreItemOld>
            ) : null}
          </ScoreContainer>
        </Header>
        <EditContainer
          isEditActive={isEditActive}
          resumeOld={originalResume}
          resumeTailored={currentResume}
        />
        {currentResume && (
          <ActionWrapper>
            {isEditActive ? (
              <FinishEditButton onClick={handleFinishEditClick}>
                {i18n.t('builder.auto_tailoring.overview.finish_edit_button')}
              </FinishEditButton>
            ) : (
              <ActionContainer>
                <EditButton onClick={handleEditClick} isDisabled={isApproving}>
                  {i18n.t('builder.auto_tailoring.overview.edit_button')}
                </EditButton>
                <SaveButton onClick={handleApproveClick} isDisabled={isApproving}>
                  {i18n.t('builder.auto_tailoring.overview.approve_button')}
                </SaveButton>
              </ActionContainer>
            )}
          </ActionWrapper>
        )}
      </Container>
    )
  }, [
    isPhone,
    i18n,
    isEditActive,
    tailoredDesignation,
    newScore,
    oldScore,
    originalResume,
    currentResume,
    handleFinishEditClick,
    handleEditClick,
    handleApproveClick,
    isApproving,
  ])

  return isTablet ? (
    <EditDrawer isOpen={isEditActive} onToggle={toggleEditContainer}>
      {EditSection}
    </EditDrawer>
  ) : (
    EditSection
  )
}
