import { useEffect, useState } from 'react'
import hark from 'hark'
import { Circle } from './styles'

function useVoiceActivityDetector(audioStream: MediaStream | undefined) {
  const [isSpeaking, setSpeaking] = useState(false)

  useEffect(() => {
    if (!audioStream) return
    let speechEvents: hark.Harker
    speechEvents = hark(audioStream)
    speechEvents.on('speaking', function () {
      const isMicON = audioStream.getTracks().some(t => t.enabled)
      setSpeaking(true && isMicON)
    })

    speechEvents.on('stopped_speaking', function () {
      setSpeaking(false)
    })

    return () => {
      if (speechEvents) {
        speechEvents.stop()
      }
    }
  }, [audioStream])

  return { isSpeaking }
}

interface Props {
  audioStream: MediaStream | undefined
}

export const PulsingCircle = ({ audioStream }: Props) => {
  const { isSpeaking } = useVoiceActivityDetector(audioStream)
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      width="48px"
      height="48px"
      viewBox="0 0 48 48"
      enableBackground="new 0 0 200 200;"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Circle
        cx="24"
        cy="24"
        r="6"
        fill="white"
        $animationOn={isSpeaking}
        $animationName="circle1"
      />
      <Circle
        opacity="0.2"
        cx="24"
        cy="24"
        r="6"
        fill="white"
        $animationName="circle2"
        $animationOn={isSpeaking}
      />
      <Circle
        opacity="0.1"
        cx="24"
        cy="24"
        r="6"
        fill="white"
        $animationOn={isSpeaking}
        $animationName="circle3"
      />
    </svg>
  )
}
