import { memo } from 'react'
import { Container, MoreInfo, SpecialitiesChip } from './styles'

interface Props {
  specialities: Array<string>
}

const SpecialityChip = ({ specialities }: Props) => {
  return (
    <Container>
      {specialities?.map(
        (service: string, index: number) =>
          index < 7 &&
          (index < 6 ? (
            <SpecialitiesChip key={index}>{service}</SpecialitiesChip>
          ) : (
            <MoreInfo key={index}>{`+${specialities?.length - 6} more`}</MoreInfo>
          )),
      )}
    </Container>
  )
}

export default memo(SpecialityChip)
