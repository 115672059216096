import { useI18n } from 'builder/hooks/useI18n'
import { Button } from 'builder/components/Button'
import { ProcessIndicator } from '../ProcessIndicator/ProcessIndicator'
import * as Styled from './styles'

type Props = React.FormHTMLAttributes<HTMLFormElement> & {
  onBackClick?: () => void
  isDisabled?: boolean
  isPending?: boolean
  submitButtonText?: string
  signUpFlow?: string
  disabledI18n?: boolean
  processIndicator?: {
    totalStep: number
    currentStep: number
  }
}

export const SimpleForm = ({
  children,
  submitButtonText,
  onBackClick,
  onSubmit,
  isDisabled = false,
  isPending = false,
  signUpFlow,
  disabledI18n,
  processIndicator,
  ...rest
}: Props) => {
  const { i18n } = useI18n()

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (onSubmit && !isDisabled) onSubmit(event)
  }

  return (
    <Styled.FormContainer {...rest} onSubmit={handleSubmit}>
      <Styled.RowsContainer>{children}</Styled.RowsContainer>
      <Styled.Spacer />
      <Styled.BottomContainer>
        <Styled.ButtonsContainer signUpFlow={signUpFlow}>
          {onBackClick && (
            <Button onClick={onBackClick} theme="ghost" type="button">
              {disabledI18n ? 'Back' : i18n.t('builder.sign_in.back')}
            </Button>
          )}

          {onSubmit && (
            <Button isDisabled={isDisabled || isPending} isLoading={isPending} type="submit">
              {submitButtonText || (disabledI18n ? 'Continue' : i18n.t('builder.sign_in.continue'))}
            </Button>
          )}
        </Styled.ButtonsContainer>
        {processIndicator && (
          <ProcessIndicator
            totalStep={processIndicator.totalStep}
            currentStep={processIndicator.currentStep}
          />
        )}
      </Styled.BottomContainer>
    </Styled.FormContainer>
  )
}
