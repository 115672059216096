import styled from 'styled-components'
import ReactSlider from 'rc-slider'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import { fadeInStyles } from '../styles'

export const ZoomWrapper = styled.div`
  position: absolute;
  z-index: 4;
  right: 78px;
  top: calc(50% - 150px);
  width: 64px;
  height: 240px;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  ${fadeInStyles};
`

export const ZoomTitle = styled.div`
  flex-shrink: 0;
  margin-bottom: 16px;
  ${Typography.Caption};
  color: ${Colors.Neutral60};
  text-align: center;
`

export const ZoomPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`

export const ZoomLimit = styled.div`
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${hexToRgba(Colors.Neutral60, 0.2)};
  border-radius: 50%;
  color: ${hexToRgba(Colors.Neutral60, 0.64)};
  ${Typography.Tiny};
  font-weight: 600;
`

const SLIDER_SIZE = 20
const HANDLE_SPACE = 12

export const ZoomControl = styled(ReactSlider)`
  position: relative;
  flex-grow: 1;
  width: ${SLIDER_SIZE}px;
  pointer-events: auto;
  margin: 16px 0;

  & .rc-slider-rail {
    position: absolute;
    top: -8px;
    bottom: -8px;
    left: calc(50% - 2px);
    width: 4px;
    background-image: url(${require('images/builder/avatar-editor/vertical-zoom-slider-bg.svg')});
    background-repeat: space;
  }

  & .rc-slider-handle {
    position: absolute;
    width: ${SLIDER_SIZE}px;
    height: ${SLIDER_SIZE}px;
    left: 0;
    background-color: ${Colors.Blue50};
    border-radius: 50%;
    outline: none;
    touch-action: pan-y;
    cursor: grab;
    transition: background-color 0.15s, transform 0.15s;
  }

  & .rc-slider-handle:before {
    content: '';
    position: absolute;
    top: ${-HANDLE_SPACE}px;
    left: ${-HANDLE_SPACE}px;
    right: ${-HANDLE_SPACE}px;
    bottom: ${-HANDLE_SPACE}px;
  }

  & .rc-slider-handle:hover {
    background-color: ${Colors.Blue60};
  }

  & .rc-slider-handle:active {
    cursor: grabbing;
    transform: scale(0.9);
  }
`
