import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import { FontWeights } from 'builder/styles/typography'

export const SuggestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 176px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 12px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${Colors.Neutral20};
    border: 4px solid ${Colors.White};
    border-radius: 6px;

    &:hover {
      background-color: ${Colors.Neutral30};
    }
  }
  & > div:not(:last-child) {
    margin-bottom: 4px !important;
  }

  ${Media.Phone`
    overflow-y: scroll;
  `}
`

export const SuggestionText = styled.span`
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const AISuggestButton = styled.button`
  ${FontWeights.DemiBold};
  border: none;
  outline: none;
  align-content: center;
  justify-content: center;
  color: ${Colors.Green60};
  background-color: ${Colors.Green10};
  padding: 2px 8px;
  height: 20px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 8px;
  letter-spacing: 0.3px;
  transition: background-color 0.1s, color 0.1s;
  cursor: pointer;

  &:hover {
    background-color: ${Colors.Green40};
    box-shadow: 0px 2px 12px 0px ${Colors.Green40};
    color: white;
  }
`
