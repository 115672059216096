import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Icon20 } from 'builder/components/Icon'
import Media from 'builder/styles/media'

export const Container = styled.div`
  position: absolute;
  height: 100%;
  left: 0;
  bottom: 0;
  display: flex;
  width: 100%;
  background: linear-gradient(180deg, rgb(255 255 255 / 20%) 1%, ${Colors.White} 100%);
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: var(--S, 8px);
  margin-top: calc(100% - 150px);
  padding-top: 20px;
  display: flex;
  align-items: center;
  width: 100%;

  margin-bottom: 20px;
  z-index: 555;
  background-color: ${Colors.White};
  height: 100%;

  ${Media.Tablet` 
     margin-top: calc(100% - 169px);
  `};

  ${Media.Phone` 
      margin-top: auto;
      height: unset;
  `};
`

export const Title = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};
  color: ${Colors.Neutral80};
  text-align: center;
`
export const Subtitle = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral80};
  padding-top: 10px;
  padding-bottom: 12px;
  text-align: center;
  max-width: 275px;
`

export const CrownIcon = styled(Icon20.Crown)`
  color: ${Colors.Indigo50};
  height: 24px;
  width: 24px;
`
export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`
