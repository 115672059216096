import { useMutation } from '@tanstack/react-query'
import { useCallback, useRef } from 'react'

export const useTextToSpeechBlob = () => {
  const abortControllerRef = useRef(new AbortController())

  const { mutateAsync: getTextToSpeechBlob } = useMutation({
    mutationFn: async (url?: string) => {
      try {
        const response = await fetch(url || '', {
          signal: abortControllerRef.current.signal,
        })
        const blob = await response.blob()
        return blob
      } catch (e) {
        return undefined
      }
    },
  })
  const abortAllPendingRequesting = useCallback(() => abortControllerRef.current.abort(), [])
  return {
    getTextToSpeechBlob,
    abortAllPendingRequesting,
    abortSignal: abortControllerRef.current.signal,
  }
}
