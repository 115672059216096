import { Fragment } from 'react'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import {
  selectors as critiqueSelectors,
  ResumeCritiqueStatuses,
} from 'builder/modules/resumeCritiqueReview'
import CritiqueReviewReadyBanner from '../CritiqueReviewReadyBanner'
import CritiqueReviewBanner from '../CritiqueReviewBanner'

const CritiqueReviewMainBanner = () => {
  const TriggeredCritiqueStatus = useTypedSelector(critiqueSelectors.triggeredCritiqueStatus)
  const triggeredPendingStatus = TriggeredCritiqueStatus?.status === ResumeCritiqueStatuses.pending

  const CritiqueResumeReviewStatus = useTypedSelector(critiqueSelectors.critiqueStatus)
  const critiquePendingStatus = CritiqueResumeReviewStatus === ResumeCritiqueStatuses.pending

  return (
    <Fragment>
      {triggeredPendingStatus && critiquePendingStatus ? (
        <CritiqueReviewBanner />
      ) : (
        <CritiqueReviewReadyBanner />
      )}
    </Fragment>
  )
}

export default CritiqueReviewMainBanner
