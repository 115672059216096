import styled from 'styled-components'

import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 20px 16px 20px;
  margin-top: 16px;
`

export const HeaderTitle = styled.div`
  color: ${Colors.Neutral90};
  ${Typography.Subhead};
  ${FontWeights.Medium};
`

export const StatusItem = styled.div<{ activeColor: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: 44px;
  padding: 2px 20px;
  gap: 8px;
  &:active {
    background: ${({ activeColor }) => activeColor};
  }
`

export const StatusContainer = styled.div<{ drawer: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  ${({ drawer }) =>
    drawer &&
    `
    & > ${StatusItem}:last-child {
      margin-top: 8px;
      color: #da0c22;
    }
  `};
`

export const StatusLabel = styled.div`
  display: flex;
  gap: 8px;
`
