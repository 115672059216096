import styled from 'styled-components'
import { rotation } from 'builder/styles/keyframes'

interface AvatarSpinnerProps {
  size: number
}

const SPINNER_URL = require('images/builder/avatar-editor/spinner.svg')

export const AvatarSpinner = styled.div<AvatarSpinnerProps>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  background-image: url(${SPINNER_URL});
  background-size: 100% 100%;
  background-position: center center;
  animation: ${rotation} 0.5s infinite linear;
`
