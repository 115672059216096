import styled, { keyframes, css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'

const shake = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`

// Magic modal
export const Container = styled.div`
  pointer-events: all;
  display: flex;
  align-items: center;
  background-color: ${Colors.Neutral100};
  border-radius: 4px;
  z-index: 5;

  ${({ isShaking }) =>
    isShaking &&
    css`
      animation: ${shake} 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    `}
`

export const Text = styled.div`
  ${Typography.Caption};
  color: ${Colors.White};
  padding: 8px 12px;
  margin-right: 4px;
`

export const DismissButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${Typography.Caption};
  font-weight: 600;
  color: ${Colors.White};
  background-color: ${Colors.Neutral100};
  height: 100%;
  padding: 8px 14px;
  border-left: 1px solid ${Colors.Neutral70};
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 0 4px 4px 0;

  &:hover {
    background-color: ${Colors.Neutral90};
  }
`
