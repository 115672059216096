import { trackInternalEvent } from '@rio/tracking'

type SDK = (...args: unknown[]) => void

// Make HelpScout SDK and TypeScript friends
declare const Beacon: undefined | SDK

class HelpscoutSdk {
  private sdk: SDK = typeof Beacon === 'undefined' ? () => {} : Beacon

  constructor() {
    this.sdk('on', 'open', () => {
      trackInternalEvent('open_helpscout_beacon')
    })
  }

  public showMessage(widgetId: string) {
    this.sdk('show-message', widgetId, { force: true })
  }
}

/**
 * Service for Beacon from helpscout.com
 * Docs about Beacon https://developer.helpscout.com/beacon-2
 */

export default new HelpscoutSdk()
