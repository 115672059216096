import placeholder from '../../assets/EventPlaceholder.svg'

const s3BucketKeyRegex = /^https:\/\/([^.]*)(?:s3-|s3\.)?(?:.*)\.amazonaws\.com\/(.*)$/i

const S3UrlKeyCacheMap = new Map<string, ParsedS3Url>()

interface ParsedS3Url {
  bucket: string
  key: string
}
export const parseS3Url = (url: string) => {
  if (S3UrlKeyCacheMap.has(url)) return S3UrlKeyCacheMap.get(url)

  if (!url || typeof url !== 'string') return null
  const match = url.match(s3BucketKeyRegex)
  if (!match) return null
  let bucket, key
  try {
    bucket = decodeURIComponent(match[1])
  } catch (error) {
    bucket = match[1]
  }
  try {
    key = decodeURIComponent(match[2])
  } catch (error) {
    key = match[2]
  }
  const response: ParsedS3Url = { bucket, key }

  S3UrlKeyCacheMap.set(url, response)

  return response
}

export const getCDNImageUrl = (url: string, width: number, height: number, fit = 'cover') => {
  const parsedS3Url = parseS3Url(url)
  if (!parsedS3Url) return null
  const { bucket, key } = parsedS3Url
  if (!bucket || !key) return null

  const params = {
    bucket,
    key,
    edits: {
      resize: {
        width,
        height,
        fit,
      },
    },
  }

  const paramsBase64 = btoa(unescape(encodeURIComponent(JSON.stringify(params))))
  return `${process.env.IMAGE_PROCESSOR_URL}/${paramsBase64}`
}

export const getEventBannerSrc = (src: string | null): string => {
  if (!src) return placeholder

  const width = 80
  const height = 80

  return getCDNImageUrl(src, width, height) ?? placeholder
}
