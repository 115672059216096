import { useState } from 'react'
import { Transition } from 'react-transition-group'

import { useI18n } from 'builder/hooks/useI18n'
import { TRANSLATION } from '../constants'
import * as Styles from './styles'

const DASHBOARD_RD_WIDGET_SNACK_BAR_KEY = 'DASHBOARD_RD_WIDGET_SNACK_BAR'

type SnackBarProps = {
  distributedRecruiterCount: number
}

export const SnackBar = ({ distributedRecruiterCount }: SnackBarProps) => {
  const { i18n } = useI18n()

  const hasBeenPresent = localStorage.getItem(DASHBOARD_RD_WIDGET_SNACK_BAR_KEY)
  const showSnackBar = distributedRecruiterCount >= 100 && !hasBeenPresent
  const [isOpen, setIsOpen] = useState<boolean>(!!showSnackBar)

  const onCloseSnackBar = () => {
    localStorage.setItem(DASHBOARD_RD_WIDGET_SNACK_BAR_KEY, 'true')
    setIsOpen(false)
  }

  if (!isOpen) return <></>

  return (
    <Transition in={isOpen} timeout={{ enter: 100, exit: 300 }} mountOnEnter unmountOnExit>
      <Styles.SnackbarContainer>
        <Styles.Snackbar
          text={i18n.t(`${TRANSLATION}.snackbar.header`, { count: distributedRecruiterCount })}
          buttonText={i18n.t(`${TRANSLATION}.snackbar.button`)}
          onOpen={onCloseSnackBar}
        />
      </Styles.SnackbarContainer>
    </Transition>
  )
}
