import { useMutation } from '@tanstack/react-query'
import { stringifyUrl } from 'query-string'
import { baseClient } from 'builder/modules/apiClient'

export const useEmailCanBeUsed = () => {
  return useMutation({
    mutationFn: async (email: string) => {
      const url = stringifyUrl({
        url: '/resume-distribution/candidates/validate_email_address',
        query: { email },
      })

      const response = await baseClient.get(url)
      return response.data.emailCanBeUsed
    },
  })
}
