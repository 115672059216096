import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'

import { useI18n } from 'builder/hooks/useI18n'
import { FetchStatuses } from 'builder/modules/constants'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { actions, selectors } from 'builder/modules/jobTracking'
import { actions as userActions } from 'builder/modules/user'
import { useAutoApply } from 'builder/views/AutoApply/hooks/useAutoApply'
import useWebsiteHost from 'builder/components/CIO-Dasboard-2.0/hooks/useWebsiteHost'

import { Stats } from './Stats/Stats'
import { JobPanel } from './JobPanel/JobPanel'

import { WidgetLoading } from './WidgetLoading'
import { ActionBtn, ActionContainer, Container } from './styles'
import { TRANSLATION } from './constants'

export const Widget = () => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { getHost } = useWebsiteHost()
  const { getAutoApplyCredit, getCreditCount } = useAutoApply()
  const autoAppliedJobs = useTypedSelector(selectors.selectAutoApplyWidgetJobs)
  const autoAppliedJonsFetchStatus = useTypedSelector(selectors.selectFetchStatus)

  const isBuyCreditState = () => {
    const { creditCount, creditCountUsed } = getAutoApplyCredit()
    return creditCount === 3 && creditCount === creditCountUsed
  }

  const navigateToJobs = () => {
    trackInternalEvent('aa_find_more_jobs', { website_host: getHost })
    navigate('/job-search')
  }

  useEffect(() => {
    dispatch(actions.fetchJobsRequest({ per_page: 5, details: true, auto_applied: true }))
    dispatch(userActions.fetchUserRequest({ silent: true }))
  }, [dispatch])

  if (autoAppliedJonsFetchStatus === FetchStatuses.loading) {
    return <WidgetLoading />
  }

  return (
    <Container>
      <Stats autoApplyStatus={autoAppliedJobs.autoApplyStatus} />
      <JobPanel
        jobs={autoAppliedJobs.data}
        creditCount={getCreditCount()}
        autoApplyCredits={getAutoApplyCredit()}
      />

      <ActionContainer $isDisabled={!autoAppliedJobs.data.length}>
        {!!autoAppliedJobs.data.length && !isBuyCreditState() && (
          <ActionBtn onClick={navigateToJobs}>{i18n.t(`${TRANSLATION}.action`)}</ActionBtn>
        )}
      </ActionContainer>
    </Container>
  )
}
