import { FC, useRef, useCallback } from 'react'
import type { ResumeExample, ResumeExampleCategory } from '@rio/types'
import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import Skeleton from './Skeleton'
import ExamplesSearch from './ExamplesSearch'
import ExamplesGroup from './ExamplesGroup'
import {
  Categories,
  CategoriesList,
  CategoriesItem,
  CategoriesTitle,
  Container,
  Content,
  ExamplesContainer,
  ExamplesList,
  Header,
  Title,
} from './styles'

interface ExamplesCatalogProps {
  examples: ResumeExample[]
  categories: ResumeExampleCategory[]
  onExampleSelect: (example: number) => void
}

const ExamplesCatalog: FC<ExamplesCatalogProps> = ({ examples, categories, onExampleSelect }) => {
  const { i18n } = useI18n()
  const media = useMediaQueries()
  const examplesListRef = useRef<HTMLDivElement | null>(null)

  const handleCategorySelect = useCallback(
    (id: number) => () => {
      const categoryNode = examplesListRef.current?.querySelector(`#category-${id}`)

      categoryNode?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    },
    [],
  )

  return (
    <Container>
      <Header>
        <Title>{i18n.t('builder.prefill.catalog_title')}</Title>
        <ExamplesSearch examples={examples} onSelect={onExampleSelect} />
      </Header>

      {!examples.length || !categories.length ? (
        <Skeleton />
      ) : (
        <Content>
          {!media.isPhone && (
            <Categories>
              <CategoriesList>
                <CategoriesTitle>{i18n.t('builder.prefill.category')}</CategoriesTitle>

                {categories.map(({ id, name }) => (
                  <CategoriesItem onClick={handleCategorySelect(id)} key={`category-${id}`}>
                    {name}
                  </CategoriesItem>
                ))}
              </CategoriesList>
            </Categories>
          )}

          <ExamplesContainer ref={examplesListRef}>
            <ExamplesList>
              {categories.map(({ id, name, examples }) => (
                <ExamplesGroup
                  key={`group-${id}`}
                  categoryId={id}
                  categoryName={name}
                  examples={examples}
                  onSelect={onExampleSelect}
                />
              ))}
            </ExamplesList>
          </ExamplesContainer>
        </Content>
      )}
    </Container>
  )
}

export default ExamplesCatalog
