import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const ExpertTagContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 4px;
  position: absolute;
  width: 145px;
  height: 28px;
  left: 20px;
  top: 20px;

  ${Media.Phone`
    width: 140px;
  `}
`

export const ExpertImage = styled.img`
  width: 42px;
  height: 28px;
`

export const ExpertTag = styled.p`
  ${FontWeights.Medium};
  ${Typography.Caption};
  color: ${Colors.Neutral80};
`
