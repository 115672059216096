import { useState, useEffect } from 'react'

const isChrome = navigator.userAgent.includes('Chrome') && navigator.vendor.includes('Google Inc')

export function usePermission(
  initialPermission: 'camera' | 'microphone',
  onChange?: (permission: PermissionState) => void,
) {
  const [permission, setPermission] = useState<PermissionState>('denied')

  useEffect(() => {
    let result: PermissionStatus
    const processPermissions = async () => {
      try {
        if (isChrome) {
          result = await navigator.permissions.query({
            name: initialPermission as PermissionName,
          })

          setPermission(result.state)
          result.onchange = () => {
            setPermission(result.state)
            onChange && onChange(result.state)
          }
        } else {
          setPermission('granted')
        }
      } catch (error) {
        console.error(error)
        setPermission('denied')
      }
    }
    processPermissions()
    return () => {
      if (result) {
        result.onchange = null
      }
    }
  }, [initialPermission])
  return permission
}
