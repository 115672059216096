import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import { Icon20 } from 'builder/components/Icon'

export const Container = styled.div`
  padding: 20px;
  min-width: 335px;
  height: 186px;
  border-radius: var(--S, 16px);
  border: var(--Zero, 1px) solid ${Colors.Neutral15};
  background: ${Colors.White};
  ${Media.Hover`
    &:hover {
      box-shadow: 0px 8px 20px -4px rgba(15, 56, 113, 0.12), 
                  0px 2px 4px -1px rgba(15, 56, 113, 0.08), 
                  0px 0px 1px 0px rgba(15, 56, 113, 0.32);
      cursor: pointer;
    }
  `};
`

export const Duration = styled.div`
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${Colors.Neutral90};
  display: inline-flex;
  justify-content: center;
  height: 20px;
  align-items: center;
  & path {
    fill: ${Colors.White};
  }
`

export const StatusDoneIcon = styled(Icon20.StatusDone)`
  margin-left: 8px;
`

export const Header = styled.div`
  display: flex;
  color: ${Colors.Green50};
  justify-content: space-between;
`

export const Question = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Regular};
  margin-top: 38px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`
