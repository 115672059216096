import { FC, memo } from 'react'
import { Link } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { ButtonTheme } from 'builder/components/Button'
import { CareerInsightsProcessingStatus } from 'builder/modules/careerProfile'
import {
  ResumeOptionButton,
  ResumeOptionsContainer,
  ChatBotAvatarContainer,
  MessageSeparator,
} from './styles'
import { BotWriting } from './BotWriting'
import { MessageLine } from './MessageLine'
import ChatBotAvatar from './assets/chatbot_avatar.svg'

export const ChatEnd: FC<{
  processingStatus: CareerInsightsProcessingStatus | undefined
  onRetry: () => void
}> = memo(({ processingStatus, onRetry }) => {
  const { i18n } = useI18n()

  return (
    <>
      <MessageSeparator />
      <MessageLine
        type="asked"
        avatar={<ChatBotAvatarContainer src={ChatBotAvatar} alt="chatbot avatar" />}
        last={false}
        content={
          <span
            dangerouslySetInnerHTML={{
              __html: i18n.t('builder.career_profile.chat.outro.thank_you_for_takeing_assessment'),
            }}
          ></span>
        }
      />
      <MessageSeparator />
      {processingStatus === CareerInsightsProcessingStatus.pending && (
        <MessageLine
          type="asked"
          avatar={<ChatBotAvatarContainer src={ChatBotAvatar} alt="chatbot avatar" />}
          last={true}
          content={<BotWriting />}
        />
      )}
      <MessageSeparator />
      {processingStatus === CareerInsightsProcessingStatus.success && (
        <>
          <MessageLine
            type="asked"
            avatar={<ChatBotAvatarContainer src={ChatBotAvatar} alt="chatbot avatar" />}
            last={true}
            content={<>{i18n.t('builder.career_profile.chat.outro.your_results_are_ready')}</>}
          />
          <MessageSeparator />
          <ResumeOptionsContainer>
            <Link to="/career-profile/insights" style={{ width: '100%' }}>
              <ResumeOptionButton
                theme={ButtonTheme.ghost}
                onClick={() => {
                  trackInternalEvent('click_view_results', {
                    label: 'career_assessment',
                  })
                }}
              >
                {i18n.t('builder.career_profile.chat.options.view_results')}
              </ResumeOptionButton>
            </Link>
          </ResumeOptionsContainer>
        </>
      )}
      <MessageSeparator />
      {(processingStatus === CareerInsightsProcessingStatus.failure ||
        processingStatus === CareerInsightsProcessingStatus.retry) && (
        <>
          <MessageLine
            type="asked"
            avatar={<ChatBotAvatarContainer src={ChatBotAvatar} alt="chatbot avatar" />}
            last={true}
            content={<>{i18n.t('builder.career_profile.chat.outro.something_went_wrong')}</>}
          />
          <MessageSeparator />
          <ResumeOptionsContainer>
            <ResumeOptionButton theme={ButtonTheme.ghost} onClick={onRetry}>
              {i18n.t('builder.career_profile.chat.options.retry')}
            </ResumeOptionButton>
          </ResumeOptionsContainer>
        </>
      )}
    </>
  )
})
