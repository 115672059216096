import Footer from './components/Footer'
import Content from './components/Content'
import { Title, Container, Illustration } from './styles'

const introVisualURL = require('./assets/img/intro.svg')

type Props = {
  handleNextStep: () => void
}

const Get5SevenStoriesIntro = ({ handleNextStep }: Props) => {
  return (
    <Container>
      <Illustration src={introVisualURL} alt="intro-visual" />
      <Title>The Seven Stories Exercise</Title>
      <Content />
      <Footer {...{ handleNextStep }} />
    </Container>
  )
}

export default Get5SevenStoriesIntro
