import ModalOverlay from 'builder/components/ModalOverlay'
import * as Styles from './styles'

import ModalHeader from './ModalHeader'
import { EHeaderMediaType } from './constants'

interface IProps {
  headerMedia: string
  headerMediaType: EHeaderMediaType
  children: JSX.Element | null
  handleClose: () => void
  modalFadeDuration?: number
}

const CommonAlertModal = ({
  modalFadeDuration = 150,
  headerMedia,
  headerMediaType,
  handleClose,
  children,
}: IProps) => {
  return (
    <ModalOverlay overlayFadeDuration={modalFadeDuration} contentSlideDuration={modalFadeDuration}>
      <Styles.Container>
        <ModalHeader {...{ handleClose, headerMedia, headerMediaType }} />
        <Styles.DescriptionSection>{children}</Styles.DescriptionSection>
      </Styles.Container>
    </ModalOverlay>
  )
}

export default CommonAlertModal
