import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Icon20 } from 'builder/components/Icon'

export const Container = styled.div<{ isTopResumeCard: boolean }>`
  width: 416px;
  height: 180px;
  background-color: ${Colors.Blue10};
  border-radius: 12px;
  margin-bottom: 8px;
  position: relative;
  padding: 24px;
  cursor: pointer;

  ${Media.Tablet`
    width: 504px;
    height: 180px;
  `}

  ${Media.Phone`
    width: 335px;
    height: 136px;
    padding: 16px;
  `}

  background: ${props => (props.isTopResumeCard ? Colors.Indigo10 : Colors.Blue10)};
  transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;

  &:hover {
    box-shadow: 0 0 0 1px ${props => (props.isTopResumeCard ? Colors.Indigo50 : Colors.Blue50)};
  }
`

export const Title = styled.title`
  position: absolute;
  width: 195px;
  height: 24px;
  left: 24px;
  top: 24px;
  ${Typography.Body};
  ${FontWeights.Medium};
  display: flex;
  align-items: center;
  color: ${Colors.Blue80};

  ${Media.Phone`
    height: 20px;
    width: 100%;
    left: 16px;
    top: 16px;
    `}
`

export const Text = styled.text`
  position: absolute;
  width: 193px;
  height: 60px;
  left: 24px;
  top: 52px;
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${Colors.Blue80};

  ${Media.Phone`
    ${Typography.Tiny};
    ${FontWeights.Regular};
    text-align: left;
    height: 48px;
    width: 178px;
    left: 16px;
    top: 40px;
    `}
`

export const BuildMyResumeButton = styled.div<{ isTopResumeCard: boolean }>`
  position: absolute;
  width: 192px;
  height: 22px;
  left: 24px;
  bottom: 24px;
  ${Typography.ButtonM};
  ${FontWeights.Medium};
  display: flex;
  align-items: center;
  color: ${props => (props.isTopResumeCard ? Colors.Indigo50 : Colors.Blue50)};

  ${Media.Phone`
    ${Typography.ButtonS};
    ${FontWeights.DemiBold};
    text-align: left;
    height: 20px;
    width: 100%;
    left: 16px;
    top: 100px; 
    `}
`

export const DummyResume = styled.img<{ isTopResumeCard: boolean }>`
  position: absolute;
  width: ${props => (props.isTopResumeCard ? 192 : 171)}px;
  height: ${props => (props.isTopResumeCard ? 142 : 122.65)}px;
  right: ${props => (props.isTopResumeCard ? -10 : 20)}px;
  top: 15.68px;
  object-fit: contain;

  ${props => Media.Phone`
    height: ${props.isTopResumeCard ? 96 : 86.15956115722656}px;
    width: ${props.isTopResumeCard ? 132 : 120.12631225585938}px;
    left: 202px;
    top: 12.75830078125px;
    `}
`

export const PlusIcon = styled.img`
  position: absolute;
  left: 75.57%;
  right: 5.74%;
  top: 1%;
  bottom: 60.32%;

  ${Media.Tablet`
    left: 79.57%;
    `}

  ${Media.Phone`
    top: -1.4%;
    left: 73%;
    `}
`

export const TopResumeVisualFooterWrapperContainer = styled.img`
  position: absolute;
  display: flex;
  align-items: center;
  width: 170px;
  height: 18px;
  right: 10px;
  bottom: 24px;

  ${Media.Phone`
    height: 16px;
    width: 105px;
    right: 12px;
    `}
`

export const RightArrow = styled(Icon20.ChevronDown)<{ isTopResumeCard: boolean }>`
  transform: rotate(-90deg);
  position: absolute;
  left: ${props => (props.isTopResumeCard ? 100 : 67)}%;
  height: 24px;
  width: 24px;

  ${props => Media.Phone`
    left: ${props.isTopResumeCard ? 51.5 : 34}%;
    `}
`
