import { CSSProperties, ForwardedRef, forwardRef } from 'react'
import { CareerPathJobCardTone } from 'builder/components/CareerPathJobCard'
import * as Styles from './styles'

type Props = {
  photoUrl: string | null
  fullName?: string
  currentRole: string
  desiredRole: string
  tone: CareerPathJobCardTone
  onConfirm?: () => void
}

export const Banner = forwardRef(
  (
    { photoUrl, fullName, desiredRole, tone, currentRole, onConfirm }: Props,
    ref?: ForwardedRef<HTMLDivElement>,
  ) => {
    const style = {
      '--color-background': `var(--${tone}-10)`,
      '--color-accent-background': `var(--${tone}-20)`,
      '--color-accent': `var(--${tone}-50)`,
      '--color-accent-hover': `var(--${tone}-60)`,
    } as CSSProperties

    const hasImage = typeof photoUrl === 'string'

    return (
      <Styles.Wrapper style={style}>
        <Styles.Container hasImage={hasImage}>
          {hasImage && <Styles.PhotoImage src={photoUrl} />}
          <div>
            <Styles.Name>{fullName}</Styles.Name>
            <Styles.RoleContainer>
              <Styles.CurrentRole>{currentRole}</Styles.CurrentRole>
              <Styles.RightArrow />
              <Styles.DesiredRole>{desiredRole}</Styles.DesiredRole>
            </Styles.RoleContainer>
          </div>
          <Styles.ButtonContainer ref={ref}>
            <Styles.ButtonConfirm onClick={onConfirm}>Confirm My Path</Styles.ButtonConfirm>
          </Styles.ButtonContainer>
        </Styles.Container>
      </Styles.Wrapper>
    )
  },
)
