import { TextAreaField } from 'builder/components/TextField'
import { FieldNote } from 'builder/components/FieldNote'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { FocusNotes } from './constant'
import { Container, Header, Content, FieldContainer, SubHeader, Note } from './styles'

interface CareerEntryProps {
  onSpecificAreasFocusChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  focusTopic: string
  isExecutive?: boolean
}

const Focus = ({ onSpecificAreasFocusChange, focusTopic, isExecutive }: CareerEntryProps) => {
  const { isPhone } = useMediaQueries()

  const renderFocusNotes = () =>
    FocusNotes?.map(note => <FieldNote description={note} key={note} />)

  return (
    <Container>
      <Content>
        <Header>
          {isExecutive
            ? 'Could you describe your coach needs?'
            : 'What specific areas would you like to focus on during your coaching sessions?'}
        </Header>
        <SubHeader>
          Please provide details on any career challenges, work-life balance, professional growth,
          or other topics you would like to discuss with your coach.
        </SubHeader>
      </Content>
      <FieldContainer>
        <TextAreaField
          data-testid="focusTextArea"
          label="Topics of Focus"
          placeholder="Poor communication from my boss, 
          I deserve a promotion, 
          How to prepare for a performance review, 
          Help to do my job, i.e. people, tools…"
          rows={isPhone ? 7 : 4}
          value={focusTopic}
          onChange={onSpecificAreasFocusChange}
        />
      </FieldContainer>
      <Note>{renderFocusNotes()}</Note>
    </Container>
  )
}

export default Focus
