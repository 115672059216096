import styled from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Typography from 'builder/styles/typography'

export const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const PaginationButton = styled.div<{ isDisabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 14px;
  background-color: ${hexToRgba(Colors.Neutral100, 0)};
  transition: background-color 0.1s ease;
  opacity: ${props => (props.isDisabled ? 0.3 : 1)};
  pointer-events: ${props => (props.isDisabled ? 'none' : 'auto')};

  &:hover {
    background-color: ${hexToRgba(Colors.Neutral100, 0.15)};
  }
`

export const PaginationLeftButton = styled(PaginationButton)`
  transform: rotate(180deg);
`
export const PaginationRightButton = styled(PaginationButton)``

export const PaginationPageNumber = styled.div`
  text-align: center;
  ${Typography.Caption};
  margin: 0 6px;
  font-variant-numeric: tabular-nums;
`
