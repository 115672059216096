import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

import ArrowLeft from '../assets/arrow-left.svg'
import ArrowRight from '../assets/arrow-right.svg'

export const Container = styled.div`
  &:hover {
    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      display: block;
    }
    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      display: block;
    }
  }

  .swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0 16px;
    height: 148px;
    max-width: 514px;
    z-index: 1;
    display: flex;
    margin: 0 15px;
  }
  .swiper-wrapper {
    position: relative;
    width: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    transition-property: transform;
    box-sizing: content-box;
  }
  .swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 148px;
    gap: 0;
    position: relative;
    display: flex;
    align-items: center;
    transition-property: transform;
  }
  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: 35%;
    width: 40px;
    height: 40px;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    display: none;
  }
  .swiper-button-prev:after,
  .swiper-button-next:after {
    width: 40px;
    height: 40px;
    background: ${Colors.White};
    border-radius: 50%;
    display: flex;
    align-items: flex-end;
    border: 1px solid ${Colors.Neutral10};
    justify-content: center;
    box-shadow: 0px 0px 1px rgba(15, 56, 113, 0.32), 0px 0px 0px rgba(15, 56, 113, 0.12),
      0px 0px 1px rgba(15, 56, 113, 0.08);
  }
  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    display: none;
    left: 0px;
    right: auto;
  }
  .swiper-button-prev:after,
  .swiper-container-rtl .swiper-button-next:after {
    content: url('${ArrowLeft}');
  }
  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    display: none;
    right: 0px;
    left: auto;
  }
  .swiper-button-next:after,
  .swiper-container-rtl .swiper-button-prev:after {
    content: url('${ArrowRight}');
  }
  .swiper-button-lock {
    display: none;
  }
`

export const CardContainer = styled.div`
  width: 100%;
`

export const Card = styled.div`
  background-color: ${Colors.White};
  cursor: pointer;
  transition: all 0.1s ease;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  height: 114px;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid ${Colors.Neutral15};
`

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Logo = styled.div<{ src?: string }>`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 32px;
  height: 32px;
  background-image: url(${p => `"${p.src}"`});
  background-position: center center;
  background-size: contain;
`

export const Title = styled.div`
  ${Typography.Body}
  ${FontWeights.Medium}
  color: ${Colors.Neutral90};

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export const Company = styled.div`
  ${Typography.Caption}
  color: ${Colors.Neutral50};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const HeaderInfo = styled.div`
  display: flex;
  align-items: center;
`

export const TextContainer = styled.div``
