import { memo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useI18n } from 'builder/hooks/useI18n'
import { SectionNames, actions } from 'builder/modules/resumeEditor'
import RichTextArea from 'builder/components/RichTextArea'
import { SectionHeader } from 'builder/components/Section'
import { TunerSectionTooltips } from 'builder/components/Tuner'
import SectionTitle from 'builder/components/SectionTitle'
import { useUpdateSimpleField } from 'builder/hooks/useUpdateSimpleField'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { SectionContainer, SectionContent } from './styles'

const PowerStatement = () => {
  const { i18n } = useI18n()
  const { updateSimpleField } = useUpdateSimpleField()
  const dispatch = useDispatch()

  const resume = useTypedSelector(state => state.resumeEditor.resume)
  const value = resume?.powerStatement
  const sectionTitles = resume?.sectionTitles
  const locale = resume?.locale
  const customTitle = sectionTitles?.powerStatement

  const handleDescriptionChange = useCallback(
    value => updateSimpleField({ name: 'powerStatement', value, debounce: true }),
    [updateSimpleField],
  )

  const handleRename = useCallback(
    value => {
      dispatch(actions.renameSection({ id: SectionNames.powerStatement, value, debounce: true }))
    },
    [dispatch],
  )

  return (
    <SectionContainer>
      <SectionHeader>
        <SectionTitle
          title={i18n.t('builder.resume_editor.power_statement_section.title')}
          customTitle={customTitle}
          onRename={handleRename}
          renamable
        />
      </SectionHeader>
      <SectionContent>
        <RichTextArea value={value || ''} onChange={handleDescriptionChange} locale={locale} />
      </SectionContent>

      <TunerSectionTooltips sectionId={SectionNames.profile} />
    </SectionContainer>
  )
}

export default memo(PowerStatement)
