import { useContext } from 'react'
import * as React from 'react'
import { WizardContext } from 'builder/components/Wizard'
import { SectionHeaderContainer } from './styles'

export const SectionHeader: React.FC = ({ children }) => {
  const wizard = useContext(WizardContext)
  const compact = typeof wizard === 'undefined'

  return <SectionHeaderContainer compact={compact}>{children}</SectionHeaderContainer>
}
