import { createRef, Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { actions as renderingActions } from 'builder/modules/rendering'
import { bindActionToPromise } from 'builder/utils/bindActionToPromise'
import PreviewReloadService from 'builder/services/PreviewReloadService'
import PerformanceLogger from 'builder/services/PerformanceLogger'
import ErrorLogger from 'builder/services/ErrorLogger'
import pdfjs from 'pdfjs-dist'

import { PrimaryCanvas, SecondaryCanvas } from './styles'

import pdfjsWorkerSrc from 'pdfjs-dist/build/pdf.worker.min.js'
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorkerSrc
let worker = null

const PIXEL_RATIO = window.devicePixelRatio

class Renderer extends Component {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    document: PropTypes.object,
    renderer: PropTypes.string,
    onPaginationChange: PropTypes.func,
    onPageRender: PropTypes.func,
    getPdfUrl: PropTypes.func.isRequired,
    onDone: PropTypes.func.isRequired,
    fallbackRenderer: PropTypes.func,
  }

  state = {
    pdf: null,
    currentPage: 1,
    canvasWidth: 900,
    lastRenderedWidth: 900,
    activeLayer: 'primary',
  }

  constructor() {
    super()
    this.primaryCanvas = createRef()
    this.secondaryCanvas = createRef()
    this.isBusy = false
    this.isPending = false
  }

  componentDidMount() {
    PreviewReloadService.on(PreviewReloadService.RESUME_PREVIEW_RELOAD_EVENT, this.listener)
    this.renderPdf()
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.document && this.props.document) this.renderPdf()
  }

  componentWillUnmount() {
    this.unmounted = true

    PreviewReloadService.removeListener(
      PreviewReloadService.RESUME_PREVIEW_RELOAD_EVENT,
      this.listener,
    )
  }

  listener = payload => {
    this.renderPdf()
  }

  renderPdf = async () => {
    const { document, fallbackRenderer, getPdfUrl, renderer } = this.props
    let { currentPage } = this.state

    if (!window.Worker) {
      fallbackRenderer({
        reason: 'Workers are not supported',
      })
      return
    }

    try {
      const url = await getPdfUrl({ document })
      if (this.unmounted) return
      PerformanceLogger.operation('parsePdfDocument')
      const pdf = await this.loadPdf(url)
      PerformanceLogger.operationEnd('parsePdfDocument')
      if (this.unmounted) return

      if (currentPage > pdf.numPages) currentPage = pdf.numPages

      this.setState({ pdf, currentPage }, () => {
        this.renderPage(currentPage)
        this.props.onPaginationChange({
          currentPage: currentPage - 1,
          totalPagesCount: pdf.numPages,
        })
      })
    } catch (error) {
      if (error.name === 'RenderingTimeoutError') {
        fallbackRenderer({
          reason: 'Rendering timeout exceeded',
        })
      } else {
        fallbackRenderer({
          reason: 'Runtime error',
          message: error.toString(),
        })
        ErrorLogger.log(error, { tag: `${renderer}-rendering` })
      }
    }
  }

  loadPdf = url => {
    if (!worker) worker = new pdfjs.PDFWorker({ name: 'pdfjs-worker' })
    const loadingTask = pdfjs.getDocument({ url, worker })
    return loadingTask.promise
  }

  setCanvasSize = ({ width, height }) => {
    const primaryCanvas = this.primaryCanvas.current
    const secondaryCanvas = this.secondaryCanvas.current

    primaryCanvas.width = width
    primaryCanvas.height = height
    secondaryCanvas.width = width
    secondaryCanvas.height = height
  }

  renderPage(pageNumber) {
    PerformanceLogger.operation('renderPdfPage')
    if (this.isBusy) {
      this.isPending = true
      return
    }
    this.isBusy = true
    const { width, onPageRender, onDone, renderer } = this.props
    const { pdf } = this.state
    if (!pdf) return
    pdf
      .getPage(pageNumber)
      .then(page => {
        const unscaledViewport = page.getViewport({ scale: 1 })
        const scale = (width * PIXEL_RATIO) / unscaledViewport.width
        const viewport = page.getViewport({ scale })

        // Prepare canvas using PDF page dimensions
        if (this.state.canvasWidth !== viewport.width) this.setCanvasSize(viewport)

        // Update dimensions in state
        this.setState({ canvasWidth: viewport.width, lastRenderedWidth: width })

        // Choose next canvas
        const primaryCanvas = this.primaryCanvas.current
        const secondaryCanvas = this.secondaryCanvas.current
        const nextCanvas = this.state.activeLayer === 'primary' ? secondaryCanvas : primaryCanvas

        // Render PDF page into canvas context
        const renderTask = page.render({
          canvasContext: nextCanvas.getContext('2d'),
          viewport,
        })
        return renderTask.promise
      })
      .then(() => {
        // Switch canvas visibility
        const activeLayer = this.state.activeLayer === 'primary' ? 'secondary' : 'primary'
        this.setState({ activeLayer })

        if (onPageRender) onPageRender()

        this.isBusy = false
        if (this.isPending) this.renderPage(this.state.currentPage)

        PerformanceLogger.operationEnd('renderPdfPage')
        onDone()

        this.isPending = false
      })
      .catch(err => ErrorLogger.log(err, { tag: `${renderer}-rendering` }))
  }

  nextPage() {
    const { currentPage, pdf } = this.state
    if (!pdf) return
    const nextPage = currentPage + 1
    this.props.onPaginationChange({ currentPage: nextPage - 1, totalPagesCount: pdf.numPages })
    this.setState({ currentPage: nextPage }, () => {
      this.renderPage(nextPage)
    })
  }

  previousPage() {
    const { currentPage, pdf } = this.state
    if (!pdf) return
    const prevPage = currentPage - 1
    this.props.onPaginationChange({ currentPage: prevPage - 1, totalPagesCount: pdf.numPages })
    this.setState({ currentPage: prevPage }, () => {
      this.renderPage(prevPage)
    })
  }

  reset() {
    const { pdf } = this.state
    if (!pdf) return
    const currentPage = 1
    this.props.onPaginationChange({ currentPage: currentPage - 1, totalPagesCount: pdf.numPages })
    this.setState({ currentPage }, () => {
      this.renderPage(currentPage)
    })
  }

  resize() {
    const { width } = this.props
    const { currentPage, lastRenderedWidth } = this.state
    if (width - lastRenderedWidth > 50) {
      this.renderPage(currentPage)
    }
  }

  render() {
    const { width } = this.props
    const { canvasWidth, activeLayer } = this.state
    const scaleRatio = width / canvasWidth

    return (
      <Fragment>
        <PrimaryCanvas
          ref={this.primaryCanvas}
          ratio={scaleRatio}
          visible={activeLayer === 'primary'}
        />
        <SecondaryCanvas
          ref={this.secondaryCanvas}
          ratio={scaleRatio}
          visible={activeLayer === 'secondary'}
        />
      </Fragment>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  onDone: () => dispatch(renderingActions.fetchPreviewDone()),
  fallbackRenderer: payload => dispatch(renderingActions.fallback(payload)),
  getPdfUrl: bindActionToPromise(dispatch, renderingActions.fetchClientPreview),
})

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(Renderer)
