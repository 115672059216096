import RenderingConfigService from 'builder/services/RenderingConfigService'
import { downloadFile } from 'builder/utils/downloadFile'
import { DocumentTypes } from 'builder/modules/constants'

function loadWorker(renderer) {
  return import(/* webpackChunkName: "rendering-core" */ '@rio/web-worker')
}

export default class ReactPdfDownload {
  constructor(document, renderer) {
    this.document = document
    this.renderer = renderer
  }

  async getUrl() {
    const [config, { renderWorker }] = await Promise.all([
      RenderingConfigService.getConfig(this.document.locale),
      loadWorker(this.renderer),
    ])

    const url = await renderWorker({ document: this.document, config })
    return url
  }

  formatBaseName() {
    if (this.document.type === DocumentTypes.coverLetter) {
      return [this.document.senderName, 'Cover Letter'].join(' - ')
    }

    const { firstName, lastName, position } = this.document
    const userFullName = [firstName, lastName].join(' ')
    return [userFullName, position].join(' - ')
  }

  getFileName() {
    const baseName = this.formatBaseName()
      .replace(/\s/g, '_') // replace whitespace characters with underscores
      .replace(/[/\\?%*:|"<>]/g, '') // remove illegal characters
      .substring(0, 250) // capped at 255 characters in length

    return `${baseName}.pdf`
  }

  async start() {
    // Generate PDF and get URL
    const url = await this.getUrl()

    // Download file by URL
    const fileName = this.getFileName()

    downloadFile(url, fileName)

    return url
  }
}
