import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { Icon24 } from 'builder/components/Icon'
import Media from 'builder/styles/media'
import Sizes from 'builder/styles/sizes'
import Typography, { FontWeights } from 'builder/styles/typography'

export const BackButton = styled.div`
  ${Typography.Caption};
  ${FontWeights.DemiBold};
  position: sticky;
  top: 16px;
  left: 0;
  display: inline-flex;
  margin: ${Sizes.S};
  margin-left: 0;
  padding: 4px 16px 4px 8px;
  align-items: center;
  border-radius: 20px;
  background: ${Colors.Neutral10};
  align-items: center;
  color: ${Colors.Neutral50};
  z-index: 3;
  cursor: pointer;

  ${Media.Hover`
    &:hover {
      background: ${Colors.Blue10};
      color: ${Colors.Blue50}
    }
  `};

  & path {
    fill: currentColor;
  }
  ${Media.AboveTablet`
    display: none;
  `};
`

export const LeftArrowIcon = styled(Icon24.ChevronLeft)`
  padding-left: 3px;
`
