import styled from 'styled-components'

export const VideoPlayer = styled.iframe`
  display: flex;
  height: 171px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: var(--3XS, 4px);
  border: 0;
`
