export const INITIALCAREERLEVELS = [
  {
    name: 'Entry Level',
    description: 'Student or only a few years experience',
    checked: false,
  },
  {
    name: 'Individual Contributor',
    description: 'Experienced and/or a mid-level manager',
    checked: false,
  },
  {
    name: 'Leader',
    description: 'Leadership and/or high-level manager',
    checked: false,
  },
  {
    name: 'Executive',
    description: 'Custom Executive Packages',
    checked: false,
  },
]
