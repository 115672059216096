import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Sizes from 'builder/styles/sizes'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${Sizes['2XS']};
  border: 1px solid ${Colors.Neutral15};
  background: ${Colors.White};
  &:hover {
    border: 1px solid ${Colors.Blue50};
  }
  padding: 16px;
  height: 96px;

  ${Media.Phone`
    flex-direction: column;
    align-items: flex-start;
    height: 183px;

  `}
`

export const EmailImg = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 49px;
  height: 49px;
  margin-left: 4px;
  margin-right: 12px;

  ${Media.Phone`
    width: 35px;
    height: 35px;
  `}
`

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 286px;
  margin-right: 24px;
  ${Media.Phone`
    padding-top: 10px;
    padding-bottom: 12px;
  `}
`

export const Title = styled.div`
  color: ${Colors.Neutral90};
  ${Typography.Body}
  ${FontWeights.Medium}
  white-space: nowrap;
`
export const SubTitle = styled.div`
  color: ${Colors.Neutral50};
  ${Typography.Caption}
  ${FontWeights.Regular}
`
export const ContainerButton = styled.div`
  ${Media.Phone`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  `}
`
export const Button = styled.div`
  color: ${Colors.Blue50};
  ${FontWeights.Medium};
  ${Typography.Caption}
  padding: ${Sizes['2XS']};
  border-radius: ${Sizes['2XS']};
  white-space: nowrap;
  cursor: pointer;
`
