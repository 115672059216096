import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import { Typography, FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import { Button as PremiumRenewButton } from 'builder/components/Button'
import { Icon20 } from 'builder/components/Icon'
import { rotation } from 'builder/styles/keyframes'

// Components
export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${Colors.Neutral10};
  padding: 60px 32px 80px 32px;

  ${Media.Phone`
    padding: 40px 0 48px 0;
  `};
`

export const Content = styled.div`
  max-width: 736px;
  margin: 0 auto;

  ${Media.Phone`
    width: 100%;
  `};
`

export const Title = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};
  margin-left: 40px;
  margin-bottom: 20px;

  ${Media.Phone`
    margin-left: 20px;
  `};
`

export const SectionTitle = styled.div`
  ${Typography.Caps};
  margin-left: 40px;
  margin-bottom: 8px;
  color: ${Colors.Neutral50};
  text-transform: uppercase;

  ${Media.Phone`
    margin-left: 20px;
  `};
`

export const SectionInnerTitle = styled.div`
  display: flex;
  align-items: center;

  ${Media.Phone`
  ${props =>
    props.isSevenDaysLeft &&
    css`
      margin: auto;
      display: block;
      text-align: center;
      margin-bottom: 8px;
    `}
    `};
`

export const PremiumPlus = styled.div`
  display: flex;
  align-items: center;
`

export const PremiumPlusText = styled.div`
  color: ${Colors.Neutral90};
  ${Typography.Subhead};
  ${FontWeights.Medium};
`

export const PremiumPlusMonth = styled.div`
  color: ${Colors.Neutral90};
  ${Typography.Body};
  ${FontWeights.Medium};
`

export const Dot = styled.img`
  padding-left: 5px;
  padding-right: 5px;
`

export const BaseImage = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 14px;
  flex-shrink: 0;

  ${Media.Phone`
  ${props =>
    props.isSevenDaysLeft &&
    css`
      margin: auto;
      display: block;
      flex-shrink: 1;
    `}
    `};
`

export const TitleImageNew = styled(BaseImage)``

export const Section = styled.div`
  width: 100%;
  margin-bottom: 28px;
`

export const SectionInner = styled.div`
  width: 100%;
  border-radius: 6px;
  padding: 32px 40px;
  background-color: ${Colors.White};

  ${Media.Phone`
    width: 100%;
    border-radius: 0;
    padding: 20px;
  `};
`

export const SectionInnerWithHeader = styled(SectionInner)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 40px;

  ${Media.Phone`
    flex-direction: column;
    align-items: flex-start;
  `};
`

export const SectionInnerSupport = styled(SectionInner)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px 34px 94px;

  ${Media.Phone`
    flex-direction: column;
    align-items: flex-start;
  `};
`

export const Button = styled.div`
  cursor: pointer;
  color: ${Colors.Blue50};

  ${({ disable }) =>
    disable &&
    `
    color: ${Colors.Neutral50};
    pointer-events: none;
    cursor: unset;
  `}};

  &:hover {
    color: ${Colors.Blue70};
  }
`

export const RedButton = styled.div`
  color: ${Colors.Red50};
  cursor: pointer;

  &:hover {
    color: ${Colors.Red60};
  }
`

export const BlueButton = styled.div`
  color: ${Colors.Blue50};
  cursor: pointer;

  &:hover {
    color: ${Colors.Blue60};
  }
`

export const YourPlanContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ManagePlanButton = styled.div`
  color: ${Colors.Blue50};
  cursor: pointer;
  margin-right: 24px;

  &:hover {
    color: ${Colors.Blue60};
  }
`

export const PostPremiumButton = styled(PremiumRenewButton)`
  background: ${Colors.Indigo50};
  height: 36px;
  width: 141px;
  ${Typography.ButtonS};

  &:hover {
    background-color: ${Colors.Indigo70};
  }

  ${Media.Phone`
    width: 335px;
    max-width: 100%;
  `};
`

export const CrownIcon = styled(Icon20.Crown)`
  margin-right: 2px;
`

export const SectionContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${Media.Phone`
    ${props =>
      props.isSevenDaysLeft
        ? css`
            margin: auto;
            display: block;
            flex-shrink: 1;
          `
        : css`
            align-items: flex-start;
          `}
  `}
`

export const SectionContentCentered = styled(SectionContent)`
  align-items: center;
  justify-content: space-between;

  ${Media.Phone`
    flex-direction: column;
    align-items: flex-start;
  `};
`

export const SectionText = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
  max-width: 448px;

  ${Media.Phone`
  margin-bottom: 12px;
  ${props =>
    props.isSevenDaysLeft &&
    css`
      margin: auto;
      display: block;
      text-align: center;
      margin-bottom: 20px;
    `}
    `};
`

export const Row = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-flow: row wrap;

  &:last-child {
    margin-bottom: 0;
  }

  ${Media.Phone`
    flex-direction: column;
    margin-bottom: 0;

    &:first-child {
      margin-bottom: 20px;
    }
  `};
`

export const Field = styled.div`
  flex: 0 0 calc(50% - 20px);
  margin-right: 40px;

  &:last-of-type {
    margin-right: 0;
    margin-bottom: 0;
  }

  ${Media.Phone`
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0;

    &:last-of-type {
      margin-bottom: 0;
    }
  `};
`

export const EmptyField = styled.div`
  flex: 0 0 calc(50% - 20px);
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${Media.Phone`
    width: 100%;
    margin-bottom: 16px;
    margin-top: -12px;
  `};
`

export const EmptyFieldText = styled.div`
  ${Typography.Caption};
  color: ${props => (props.error ? Colors.Red60 : Colors.Neutral50)};
  padding-top: 26px;

  ${Media.Phone`
    padding-top: 0;
  `};
`

export const SectionBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${Media.Phone`
    justify-content: flex-start;
  `};
`

export const SocialItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid ${Colors.Neutral20};

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`

export const SocialInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-right: 12px;
`

export const SocialImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`

export const SocialName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & a:hover {
    color: ${Colors.Blue50};
  }
`

export const SupportText = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
  max-width: 384px;
  margin-left: 40px;
  margin-top: 12px;

  ${Media.Phone`
    margin-left: 20px;
  `};
`

export const ContactLink = styled.a`
  margin-left: 3px;
  color: ${Colors.Blue50};
`

export const Updating = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const UpdatingIcon = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${require('images/builder/account/spinner.svg')});
  background-size: 100% 100%;
  background-position: center center;
  animation: ${rotation} 0.5s infinite linear;
`

export const UpdatedIcon = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${require('images/builder/account/updated_successfully.svg')});
  background-size: 100% 100%;
  background-position: center center;
`

export const SectionBody = styled.div``

export const ChangePlanButton = styled.div`
  ${Media.Phone`
  ${props =>
    props.isSevenDaysLeft
      ? css`
          position: relative;
          left: 50%;
          margin-bottom: 0px;
          transform: translate(-50%);
        `
      : css`
          padding-left: 54px;
        `}
      `};
`

export const SocialUpdating = styled.div`
  display: flex;
  align-items: center;
  color: ${Colors.Neutral50};
`

export const ActionLink = styled.div`
  display: inline-flex;
  margin: 0 3px;
`
