import styled from 'styled-components'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Container = styled.div`
  gap: 4px;
  display: flex;
  align-items: center;

  ${Media.Phone`
    align-items: start;
  `}
`

export const Expertise = styled.p`
  width: 100%;
  ${Typography.Tiny};
`

export const CoachExpertiseIcon = styled.img``
