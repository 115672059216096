export const radioGroupOptions = [
  {
    value: 'michelangelo',
    label: 'Michelangelo',
  },
  {
    value: 'leonardo',
    label: 'Leonardo',
  },
  {
    value: 'donatello',
    label: 'Donatello',
  },
  {
    value: 'raphael',
    label: 'Raphael',
  },
]
