import styled from 'styled-components'
import Icon24 from 'builder/components/Icon'

export const CollapseWrapper = styled.div``

export const CollapseItem = styled.div<{ padding?: string }>`
  display: flex;
  justify-content: space-between;
  padding: ${props => props.padding || '16px 0'};
`

export const CollapseHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

export const CollapseIconUp = styled(Icon24.ChevronLightUp)`
  svg {
    fill: none;
  }
`
export const CollapseIconDown = styled(Icon24.ChevronLightDown)`
  svg {
    fill: none;
  }
`

export const CollapseButtonWrapper = styled.div``
export const CollapsableItems = styled.div``
