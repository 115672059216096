import { PlanContainer, PlanName, PlanPeriod, PlanPrice, PlanPriceContainer } from './styles'

export type BillingItemData = {
  planName: string
  planPrice: string
  planPeriod: string
}

type Props = {
  item: BillingItemData
}

export const BillingItem = ({ item }: Props) => {
  const { planName, planPrice, planPeriod } = item
  return (
    <PlanContainer>
      <PlanName>{planName}</PlanName>
      <PlanPriceContainer>
        <PlanPrice>{planPrice}</PlanPrice>
        <PlanPeriod>/{planPeriod}</PlanPeriod>
      </PlanPriceContainer>
    </PlanContainer>
  )
}
