import { Category } from '../../types'
import { MenuFeature } from './MenuFeature'
import { CategoryContainer, CategoryTitle } from './styles'

interface Props {
  category?: Category
}

export const MenuCategory = ({ category, ...rest }: Props) => {
  return (
    <CategoryContainer {...rest}>
      <CategoryTitle>{category?.title}</CategoryTitle>
      {category?.features.map((feature, featureIndex) => (
        <MenuFeature key={featureIndex} feature={feature} />
      ))}
    </CategoryContainer>
  )
}
