import { AppConfig } from 'builder/modules/init'
import { UserData } from 'builder/modules/user'

export class FeaturesConfig {
  features: AppConfig['features']
  private user: UserData | null

  constructor(features: AppConfig['features'], user: UserData | null) {
    this.features = features
    this.user = user
  }

  showResumeDistribution() {
    const premiumUserWithoutRD = this.user?.hasPremiumFeatures && !this.user?.hasPremiumPlusFeatures
    return (
      !!this.features?.rdPromo?.rdPromoPlan ||
      (this.features?.resumeDistribution && !premiumUserWithoutRD) ||
      this.user?.scopes?.resumeDistribution
    )
  }

  showLocalizationDashboard() {
    return this.features.dashboardV2
  }
}
