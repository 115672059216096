import * as Styled from './styles'
const ExploreCreers = require('../../assets/ExploreCreers.svg')

export const ExploreYourNextSteps = ({ userPhotoUrl }: { userPhotoUrl: string | undefined }) => {
  return (
    <Styled.ExploreCareersContainer>
      <Styled.ExploreCareersImageContainer>
        <Styled.ExploreCareersImage src={ExploreCreers} />
        <Styled.ExploreCareersUserContainer>
          <Styled.ExploreCareersUserImage src={userPhotoUrl} />
        </Styled.ExploreCareersUserContainer>
      </Styled.ExploreCareersImageContainer>
    </Styled.ExploreCareersContainer>
  )
}
