import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { trackInternalEvent } from '@rio/tracking'
import { connect } from 'react-redux'
import { actions } from 'builder/modules/signUp'
import { useI18n } from 'builder/hooks/useI18n'
import { FetchStatuses } from 'builder/modules/constants'
import { withMediaQueries } from 'builder/components/MediaQueries'
import * as SimpleForm from 'builder/components/SimpleForm'
import { TextField } from 'builder/components/TextField'
import { IntroductionContainer, Title, Subtitle } from './styles'
import { Wrapper, Content } from '../styles'
import useWebsiteHost from 'builder/components/CIO-Dasboard-2.0/hooks/useWebsiteHost'

const Introduction = ({
  onBack,
  onContinue,
  firstName,
  lastName,
  firstNameError,
  lastNameError,
  marketingConsent,
  onChange,
  persistSignUpInfo,
  mediaQueries,
  documentType,
  signUpProcess,
  verifyMarketingConsentUserRegion,
}) => {
  const { i18n } = useI18n()
  const [showContent, setContentVisibility] = useState(false)
  const { getHost } = useWebsiteHost()

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    marketing_consent: '',
  })

  useEffect(() => {
    // Track step opening
    trackInternalEvent('visit_introduction_sign_up_step')
    verifyMarketingConsentUserRegion({ abTestingStep: 'name_step' })

    // Hack to fix animation bug. See https://github.com/reactjs/react-transition-group/issues/10
    requestAnimationFrame(() => {
      setContentVisibility(true)
    })
  }, [getHost, mediaQueries.isPhone, verifyMarketingConsentUserRegion])

  const updateField = e => {
    const { value } = e.target
    let regex =
      // eslint-disable-next-line max-len
      /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g
    if (value.search(regex) === -1) {
      persistSignUpInfo({
        type: documentType,
        [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : value,
      })
      onChange(e)

      setErrors({
        ...errors,
        [e.target.name]: '',
      })
    } else {
      setErrors({
        ...errors,
        [e.target.name]: i18n.t('builder.sign_up.emoji_error'),
      })
    }
  }

  const handleContinue = () => {
    trackInternalEvent('submit_introduction_sign_up_info')

    if (!firstName.trim().length || !lastName.trim().length) {
      const errorString = i18n.t('builder.sign_up.field_is_required')
      setErrors({
        firstName: !firstName.trim().length ? errorString : '',
        lastName: !lastName.trim().length ? errorString : '',
      })
      trackInternalEvent('see_introduction_sign_up_error')
      return
    }

    onContinue({ firstName, lastName, marketingConsent })
  }

  return (
    <Wrapper>
      <IntroductionContainer>
        <Title>{i18n.t('builder.sign_up.introduction_title')}</Title>
        <Subtitle>{i18n.t('builder.sign_up.introduction_subtitle')}</Subtitle>

        {showContent && (
          <Content>
            <SimpleForm.Form
              onSubmit={handleContinue}
              onBackClick={onBack}
              isPending={signUpProcess === FetchStatuses.loading}
            >
              <SimpleForm.Row>
                <TextField
                  name="firstName"
                  label={i18n.t('builder.sign_up.first_name')}
                  value={firstName}
                  onChange={updateField}
                  error={errors.firstName || firstNameError}
                  autoFocus={!mediaQueries.isPhone}
                />
              </SimpleForm.Row>

              <SimpleForm.Row>
                <TextField
                  name="lastName"
                  label={i18n.t('builder.sign_up.last_name')}
                  value={lastName}
                  onChange={updateField}
                  error={errors.lastName || lastNameError}
                />
              </SimpleForm.Row>
            </SimpleForm.Form>
          </Content>
        )}
      </IntroductionContainer>
    </Wrapper>
  )
}

Introduction.propTypes = {
  onBack: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  confirmEmail: PropTypes.bool,
  persistSignUpInfo: PropTypes.func,
  error: PropTypes.bool,
  firstNameError: PropTypes.string,
  lastNameError: PropTypes.string,
  onChange: PropTypes.func,
  mediaQueries: PropTypes.object.isRequired,
  documentType: PropTypes.string,
  signUpProcess: PropTypes.string,
  marketingConsent: PropTypes.bool,
  verifyMarketingConsentUserRegion: PropTypes.func,
}

// ---
// Connect to data
// ---
const mapStateToProps = state => ({
  firstName: state.signUp.signUpInfo.firstName,
  lastName: state.signUp.signUpInfo.lastName,
  confirmEmail: state.signUp.signUpInfo.confirm_email,
  signUpProcess: state.signUp.signUpProcess,
  marketingConsent: state.signUp.signUpInfo.marketingConsent,
})

const mapDispatchToProps = {
  persistSignUpInfo: actions.persistSignUpInfo,
  verifyMarketingConsentUserRegion: actions.emailMarketingConsent,
}

export default connect(mapStateToProps, mapDispatchToProps)(withMediaQueries(Introduction))
