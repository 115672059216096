import React, { useState } from 'react'

import { selectors } from 'builder/modules/careerProfile'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

import { useI18n } from 'builder/hooks/useI18n'
import { useMenuItems } from '../../../hooks/useMenuItems'

import { ISideMenuItems, ISubMenu } from '../../../types'
import { ProfileMobile } from '../../ProfileMobile/ProfileMobile'
import { MenuItems } from './MenuItems'

import {
  Container,
  Drawer,
  Header,
  HeaderClose,
  HeaderTitle,
  HeaderBack,
  BackButton,
  MenuContainer,
} from './styles'

const TRANSLATION = 'builder.side_menu.mobile'

interface SubMenu {
  parent: string
  subMenu: ISubMenu[]
}

interface IProps {
  open: boolean
  setOpen: (prop: boolean) => void
}

export const MobileDrawerMenu = React.forwardRef(
  ({ open, setOpen }: IProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { i18n } = useI18n()
    const { menuItems } = useMenuItems(true)

    const [subMenu, setSubMenu] = useState<SubMenu>()
    const isMobileProfileMenuOpen = useTypedSelector(selectors.isMobileProfileMenuOpen)

    const onCloseSubMenu = () => {
      setSubMenu(undefined)
    }

    const onClickMenuItem = () => {
      setOpen(false)
      setSubMenu(undefined)
    }

    const onClickSubMenu = (item: ISideMenuItems) => {
      if (!item.subMenu) {
        return onClickMenuItem()
      }
      setSubMenu({ parent: item.text, subMenu: item.subMenu })
    }

    if (!open) return <></>

    return (
      <Container>
        <Drawer>
          <Header>
            {subMenu && (
              <BackButton onClick={onCloseSubMenu}>
                <HeaderBack />
              </BackButton>
            )}
            <HeaderTitle isCareerProfile={isMobileProfileMenuOpen} isSubMenu={!!subMenu}>
              {isMobileProfileMenuOpen
                ? i18n.t(`${TRANSLATION}.career_profile`)
                : subMenu?.parent || i18n.t(`${TRANSLATION}.more_menu`)}
            </HeaderTitle>
            {!subMenu && <HeaderClose onClick={() => setOpen(false)} />}
          </Header>
          <MenuContainer ref={ref}>
            {isMobileProfileMenuOpen ? (
              <ProfileMobile />
            ) : (
              <MenuItems
                type={subMenu?.subMenu ? 'submenu' : 'menu'}
                menu={subMenu?.subMenu || menuItems}
                subMenuAction={onClickMenuItem}
                mainMenuAction={onClickSubMenu}
              />
            )}
          </MenuContainer>
        </Drawer>
      </Container>
    )
  },
)
