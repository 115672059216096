import styled, { css } from 'styled-components'
import FlipMove from 'react-flip-move'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Button, { ButtonTheme } from 'builder/components/Button'
import Icon24 from 'builder/components/Icon'
import { rotation } from 'builder/styles/keyframes'
import { FontWeights, Typography } from 'builder/styles/typography'
import { Document, CreateDocument } from '../Document'

enum SPACING {
  Desktop = 56,
  Tablet = 32,
  Phone = 24,
}

export const ListContainer = styled.div``

export const ListAnimatedGrid = styled(FlipMove)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: -${SPACING.Desktop}px;

  ${Media.Tablet`
    margin-right: 0;
    margin-bottom: -${SPACING.Tablet}px;
  `}

  ${Media.Phone`
    margin-bottom: -${SPACING.Phone}px;
  `}
`

const listItemStyles = css`
  width: calc(50% - ${SPACING.Desktop / 2}px);
  margin-bottom: ${SPACING.Desktop}px;

  ${Media.Tablet`
    width: 100%;
    margin-bottom: ${SPACING.Tablet}px;
  `}

  ${Media.Phone`
    margin-bottom: ${SPACING.Phone}px;
  `}
`

export const ListDocument = styled(Document)`
  ${listItemStyles}
`

export const ListCreateDocument = styled(CreateDocument)`
  ${listItemStyles}
`

export const ListExpand = styled(Button).attrs({ theme: ButtonTheme.default })`
  display: block;
  width: auto;
  margin: auto;
  padding: 16px 24px;
  margin-top: 48px;
  margin-bottom: 56px;
  color: ${Colors.Neutral50};
  background-color: ${Colors.Neutral5};
  border-radius: 12px;
  border: none;
  cursor: pointer;
  transition: color 0.15s;

  &:hover {
    color: ${Colors.Blue50};
    background-color: ${Colors.Blue10};
  }

  ${Media.Phone`
    margin-top: 32px;
    margin-bottom: 48px;
  `}
`

export const LoaderContainer = styled.div`
  height: 32px;
  width: 105px;
  left: 77px;
  top: 12px;
  position: absolute;
  background: ${Colors.White};
  box-shadow: 0px 0px 1px rgba(15, 56, 113, 0.32), 0px 1px 2px rgba(15, 56, 113, 0.12),
    0px 2px 4px rgba(15, 56, 113, 0.08);
  border-radius: 30px;

  ${Media.Phone`
  border-radius: 15px;
    left:3px;
    width: 98px;
    height: 25px;
  `}
`
export const Spinner = styled(Icon24.Spinner)`
  color: ${Colors.Blue50};
  animation: ${rotation} 0.5s infinite linear;
  position: absolute;
  left: 4px;
  top: 4px;

  ${Media.Phone`
    top: 2px;
  `}
`

export const PrefillText = styled.div`
  position: absolute;
  left: 31.07%;
  right: 11.65%;
  top: 25%;
  bottom: 25%;
  ${Typography.Tiny};
  ${FontWeights.DemiBold};
  display: flex;
  align-items: center;
  color: ${Colors.Neutral90};

  ${Media.Phone`
    left:25px;
  `}
`
