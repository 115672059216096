import { Resume } from 'packages/types'
import { isTruthy } from 'builder/utils/richTextUtils'
import { SectionFieldNames } from '../constants'
import { SectionNames } from '../sectionNames'

/**
 * NOTE: this helper is created for AI Profile summary business logic,
 * it can not fit your requirements because of specific fields set
 */
export const getEmptyFieldName = <T extends Extract<keyof Resume, 'workExperiences'>>(
  sectionName: T,
  card: Resume[T][number],
): keyof Resume[T][number] | undefined => {
  switch (sectionName) {
    case SectionNames.workExperiences:
      const { title, employer, dateFrom, dateUntil } = SectionFieldNames.workExperiences

      if (!isTruthy(card.title)) {
        return title
      }

      if (!isTruthy(card.employer)) {
        return employer
      }

      if (!card.isMonthFromHidden && !isTruthy(card.dateFrom)) {
        return dateFrom
      }

      if (!card.isMonthUntilHidden && !card.isDateUntilPresent && !isTruthy(card.dateUntil)) {
        return dateUntil
      }
  }

  return undefined
}

/**
 * NOTE: this helper is created for AI Profile summary business logic,
 * it can not fit your requirements because of specific fields set
 */
export const isEmptySection = <K extends keyof Resume>(resume: Resume | null, key: K) => {
  if (!resume) return true
  switch (key) {
    case 'workExperiences':
      const workExperiences = resume.workExperiences

      if (workExperiences.length === 0) {
        return true
      } else {
        return !workExperiences.some(card => !getEmptyFieldName(key, card))
      }
  }
  return false
}
