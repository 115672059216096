import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { actions, selectors } from 'builder/modules/careerCoaching'
import { Loader } from '../CareerCoachingSessions/components/Loader'
import {
  Container,
  Header,
  Text,
  SubText,
  Accept,
  Cards,
  Footer,
  CardLogo,
  FooterText,
} from './styles'
import PackageCard from './components/PackageCard'
import Visa from './assets/images/visa.svg'
import MasterCard from './assets/images/mastercard.svg'
import Amex from './assets/images/amex.svg'

const CareerCoachingPayment = (): JSX.Element => {
  const dispatch = useDispatch()
  const isCoachingPlansLoading = useTypedSelector(selectors.isCoachingPlansLoading)
  const isCoachingPlansLoaded = useTypedSelector(selectors.isCoachingPlansLoaded)

  useEffect(() => {
    dispatch(actions.fetchIntakeTestDetails())
  }, [dispatch])

  const paymentLogo = [Visa, MasterCard, Amex]

  const renderCardLogo = () => paymentLogo?.map(logo => <CardLogo key={logo} src={logo} />)

  return (
    <Container>
      {isCoachingPlansLoading && <Loader />}

      {isCoachingPlansLoaded && (
        <>
          <Header>
            <Text>Explore Our Coaching Pricing Packages</Text>
            <SubText>Select a package and pay to view coaches that best match your needs.</SubText>
          </Header>
          <PackageCard />
          <Footer>
            <Cards>
              <Accept>We accept:</Accept>
              {renderCardLogo()}
            </Cards>
            <FooterText>
              *By placing an order you waive your right of withdrawal and agree to immediate
              delivery of the services and related digital products. Within 7 days after the first
              payment, you can claim the money-back guarantee.
            </FooterText>
          </Footer>
        </>
      )}
    </Container>
  )
}
export default CareerCoachingPayment
