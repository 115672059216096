import { ActionItem as ContentType } from 'builder/modules/careerPlanning'
import ActionItem from './components/ActionItem'
import { Container } from './styles'

type Props = {
  contents: ContentType[]
  isTopBarOpen: boolean
}

const Content = ({ contents, isTopBarOpen }: Props) => {
  return (
    <Container isTopBarOpen={isTopBarOpen}>
      {contents.map((action, index) => {
        const { id, type, position, ctas, title, completed, description, availableForFreeUsers } =
          action

        return (
          <ActionItem
            key={id}
            length={contents.length}
            {...{
              index,
              id,
              type,
              position,
              ctas,
              title,
              completed,
              description,
              availableForFreeUsers,
            }}
          />
        )
      })}
    </Container>
  )
}

export default Content
