export const checkboxesState = [
  {
    label: 'Regular',
  },
  {
    label: 'Checked state',
    checked: true,
  },
  {
    label: 'Invalid state',
    invalid: true,
  },
  {
    label: 'Disabled state',
    disabled: true,
  },
  {
    label: 'Disabled and checked state',
    disabled: true,
    checked: true,
  },
]
