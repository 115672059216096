import styled from 'styled-components'

import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  width: 100%;
  margin-top: 144px;
  text-align: center;
  ${Media.Phone`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px
  `}
`

export const Image = styled.img`
  width: 454.975px;
  height: 300.556px;
  flex-shrink: 0;
  ${Media.Phone`
    width: 324.84px;
    height: 212.976px;
  `}
`

export const TextContainer = styled.div`
  width: 416px;
  margin: 0 auto;
  margin-top: 32.82px;
  text-align: center;
  gap: 8px;
  ${Media.Phone`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px
  `}
`

export const Title = styled.div`
  ${Typography.M};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
  text-align: center;
  padding-bottom: 8px;
  ${Media.Phone`
    width: 335px;
  `}
`

export const SubTitle = styled.div`
  color: ${Colors.Neutral90};
  text-align: center;
  ${Typography.Body};
  ${FontWeights.Regular};
  ${Media.Phone`
    width: 327px;
  `}
`

export const EmailId = styled.div`
  color: ${Colors.Neutral90};
  ${Typography.Body};
  ${FontWeights.DemiBold};
`
