import { Fragment, useState } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import Foldable from 'builder/components/Foldable'
import { FaqItem } from 'builder/modules/careerPath2'
import {
  AnswerContent,
  CloseSectionIcon,
  FoldableContainer,
  Line,
  OpenSectionIcon,
  Question,
  QuestionContainer,
  QuestionContent,
  RowWrapper,
} from './styles'

type Props = {
  label: string
  jobTitle?: string
  questions: FaqItem[]
}

const Questions = ({ questions, label, jobTitle }: Props) => {
  const [questionId, setQuestionId] = useState<number>()
  const questionCount = questions.length

  const handleShowAnswer = (id: number) => {
    if (id === questionId) setQuestionId(-1)
    else {
      setQuestionId(id)
      trackInternalEvent('click_show_faq_answer', {
        label,
        job_title: jobTitle,
      })
    }
  }

  return (
    <>
      {questions.map((faq, index) => (
        <Fragment key={index}>
          <Question onClick={() => handleShowAnswer(index)}>
            <RowWrapper>
              <QuestionContainer>
                <QuestionContent>{faq[`question ${index + 1}`]}</QuestionContent>
              </QuestionContainer>
              {questionId === index ? (
                <OpenSectionIcon data-testid="close-section" />
              ) : (
                <CloseSectionIcon data-testid="open-section" />
              )}
            </RowWrapper>

            <FoldableContainer>
              <Foldable isOpen={questionId === index}>
                <AnswerContent>{faq[`answer ${index + 1}`]}</AnswerContent>
              </Foldable>
            </FoldableContainer>
          </Question>
          <Line isVisible={index + 1 < questionCount} />
        </Fragment>
      ))}
    </>
  )
}

export default Questions
