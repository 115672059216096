import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useGetCustomAlert } from '../AlertModal/hooks/useGetCustomAlert'
import { SearchSuggestionType } from '../AutoSuggestField/types'
import { SearchQuery } from '../JobSearchResult/types'
import { useGetJob } from './hooks/useGetJob'

import { JobAlertPage } from './JobAlertPage'
import { useGetSimilarJobs } from './hooks/useGetSimilarJobs'

export const CustomJobAlertPage = () => {
  const { externalSlugId, customAlertId } = useParams()
  const job = useGetJob(externalSlugId)
  const customAlert = useGetCustomAlert(customAlertId)
  const [params, setParams] = useState<Omit<SearchQuery, 'correct_term' | 'view'> | undefined>()
  const similarJobs = useGetSimilarJobs(params)
  const [nearMeLocation, setNearMeLocation] = useState<SearchSuggestionType | null>()
  useEffect(() => {
    if (params || customAlert.isLoading || job.isLoading) {
      return
    }
    // fallback
    if (customAlertId && customAlert.error) {
      setParams({
        query: job.data?.job_title,
        location_id: job.data?.location_id ? String(job.data?.location_id) : undefined,
        external_slug_id: externalSlugId,
      })
      return
    }

    if (customAlertId && customAlert.data) {
      setParams({
        query: customAlert.data.targetRole,
        location_id: customAlert.data.locationId ? String(customAlert.data.locationId) : undefined,
        external_slug_id: externalSlugId,
      })
    }
  }, [externalSlugId, job, customAlertId, customAlert])

  return (
    <JobAlertPage
      job={job.data}
      cardJobs={similarJobs}
      nearMeLocation={nearMeLocation}
      setNearMeLocation={setNearMeLocation}
    />
  )
}
