import { memo, useEffect } from 'react'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { i18n as I18n } from 'builder/utils/i18n'
import Icon from 'builder/components/Icon'
import Preview from 'builder/components/Preview'
import {
  MobilePaginationPanel,
  MobilePaginationCounter,
  MobilePaginationPrev,
  MobilePaginationNext,
  Content,
  PreviewBox,
  TemplateLabel,
} from './styles'
import { PreviewContentProps } from './types'

const PreviewContent = (props: PreviewContentProps) => {
  const {
    contentRef,
    previewBoxRef,
    previewRef,
    type,
    width,
    height,
    isDataLoaded,
    currentDocument,
    currentTemplate,
    currentPage,
    totalPagesNumber,
    handlePreviousPageClick,
    handleNextPageClick,
    handlePaginationStateChange,
    handlePreviewResize,
  } = props

  useEffect(() => {
    const contentRefCurrent = contentRef.current
    window.addEventListener('resize', handlePreviewResize)
    handlePreviewResize()
    if (contentRefCurrent) disableBodyScroll(contentRefCurrent)
    return () => {
      // Cleanup code or actions before the component is unmounted
      window.removeEventListener('resize', handlePreviewResize)
      if (contentRefCurrent) enableBodyScroll(contentRefCurrent)
    }
  }, [contentRef, handlePreviewResize])

  return (
    <Content ref={contentRef}>
      <PreviewBox ref={previewBoxRef}>
        {isDataLoaded && width > 0 && (
          <Preview
            height={height}
            width={width}
            document={currentDocument}
            documentId={currentDocument.id}
            type={type}
            ref={previewRef}
            onPaginationChange={handlePaginationStateChange}
          />
        )}
        {totalPagesNumber > 1 && (
          <MobilePaginationPanel>
            <MobilePaginationPrev isEnabled={currentPage > 1} onClick={handlePreviousPageClick}>
              <Icon.Chevron />
            </MobilePaginationPrev>
            <MobilePaginationCounter>
              {currentPage} {I18n.t('builder.resume_editor.of')} {totalPagesNumber}
            </MobilePaginationCounter>
            <MobilePaginationNext
              isEnabled={currentPage < totalPagesNumber}
              onClick={handleNextPageClick}
            >
              <Icon.Chevron />
            </MobilePaginationNext>
          </MobilePaginationPanel>
        )}
        {isDataLoaded && !currentTemplate?.premium && <TemplateLabel>Free</TemplateLabel>}
      </PreviewBox>
    </Content>
  )
}

export default memo(PreviewContent)
