import styled, { css } from 'styled-components'
import { Icon20 } from 'builder/components/Icon'
import {
  Container,
  Drawer,
} from 'builder/features/navigation/components/MobileMenu/MobileMoreMenu/styles'

export const HeaderClose = styled(Icon20.Close)`
  margin-left: auto;
`

export const DrawerContainer = styled(Container)`
  z-index: 1000;
  left: 0;
`

export const CustomDrawer = styled(Drawer)<{ autoHeight?: boolean; fromDashboard?: boolean }>`
  border-radius: 16px 16px 0 0;
  min-height: ${({ autoHeight }) => (autoHeight ? 'auto' : '90%')};
  ${props =>
    props.fromDashboard &&
    css`
      background: none;
      min-height: 100%;
    `}
`

export const PullIcon = styled.div`
  display: flex;
  justify-content: center;
  background: rgba(190, 196, 213, 1);
  width: 25px;
  height: 4px;
  border-radius: 4px;
`
export const PullContainer = styled.div`
  width: 100%;
  height: 16px;
  padding: 8px 0 4px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const DrawerActionContainer = styled.div`
  align-self: start;
`
