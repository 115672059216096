import { Resume } from 'packages/types'
import { useRef } from 'react'
import EditorRow from 'builder/components/EditorRow'
import { Container } from 'builder/components/PersonalDetailsSection/styles'
import SimpleProfileSummary from './SimpleProfileSummary'
import { Spouse } from './Spouse'
import { SpouseObligation } from './SpouseObligation'
import { Dependants } from './Dependants'
import { CommuteTime } from './CommuteTime'

interface Props {
  resume: Resume
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function ProfessionalSummary(props: Props) {
  const containerRef = useRef(null)
  const { resume } = props

  return (
    <>
      <Container ref={containerRef}>
        <SimpleProfileSummary value={resume.profile} customTitle={resume.sectionTitles.profile} />

        <EditorRow>
          <CommuteTime />
          <Dependants />
        </EditorRow>

        <EditorRow>
          <Spouse />
          <SpouseObligation />
        </EditorRow>
      </Container>
    </>
  )
}
