import styled from 'styled-components'
import { Container as BaseContainer } from 'builder/components/Container'
import { FontWeights, Typography } from 'builder/styles/typography'
import { Icon24 } from 'builder/components/Icon'
import Media from 'builder/styles/media'
import { Button } from 'builder/components/Button'

export const Wrapper = styled(BaseContainer)`
  margin-top: 40px;

  ${Media.Tablet`
    margin-top: 32px;
  `};

  ${Media.Phone`
    position: relative;
    padding-left: 0;
    padding-right: 0;
  `};
`

export const Container = styled.div<{ hasImage: boolean }>`
  background: var(--color-background);
  padding: 40px;
  border-radius: 16px;
  display: flex;
  align-items: center;

  ${Media.Tablet`
    padding: 24px;
  `};

  ${Media.Phone`
      flex-direction: column;
      text-align: center;
      padding: 24px 20px;
  `}

  ${props =>
    props.hasImage &&
    Media.Phone`
      padding-top: 48px;
      margin-top: 60px;
  `}
`

export const PhotoImage = styled.img`
  border-radius: 50%;
  width: 72px;
  height: 72px;
  margin-right: 24px;

  ${Media.Tablet`
    margin-right: 16px;
  `};

  ${Media.Phone`
    position: absolute;
    top: -36px;
    left: calc(50% - 36px);
  `};
`

export const Name = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};
`

export const RoleContainer = styled.div`
  margin-top: 8px;
  ${Typography.Subhead};

  ${Media.Tablet`
    ${Typography.Body};
  `};

  ${Media.Phone`
    ${Typography.Caption};
  `};
`

export const RightArrow = styled(Icon24.RightArrow)`
  color: var(--color-accent);
  background: var(--color-accent-background);
  border-radius: 50%;
  margin: auto 12px;
  display: inline-block;

  ${Media.Phone`
    margin-left: 8px;
    margin-right: 8px;
  `};
`

export const ButtonContainer = styled.div`
  flex-grow: 2;
  text-align: end;

  ${Media.Phone`
    margin-top: 24px;
    width: 100%;
  `};
`

export const ButtonConfirm = styled(Button)`
  background: var(--color-accent);

  &:hover {
    background-color: var(--color-accent-hover);
  }

  ${Media.Phone`
    width: 100%;
  `};
`

export const CurrentRole = styled.div`
  display: inline-block;
`

export const DesiredRole = styled.div`
  display: inline-block;
`
