import SUCCESS_ICON from '../../../../../app/assets/images/snackbar-success-icon.svg'

import { Container, IconImage, Text } from './styles'

const SuccessSnackBar = ({ text }: { text: string }) => (
  <Container>
    <IconImage src={SUCCESS_ICON} />
    <Text>{text}</Text>
  </Container>
)

export default SuccessSnackBar
