import POST_EMAIL from '../../assets/img/mailbox.png'
import * as Styled from './styles'

interface Props {
  EmailId: string
}

const EmailSent = ({ EmailId }: Props) => {
  return (
    <>
      <Styled.Container>
        <Styled.Image src={POST_EMAIL} />
      </Styled.Container>
      <Styled.TextContainer>
        <>
          <Styled.Title>Check your inbox</Styled.Title>
          <Styled.SubTitle>
            We sent a magic link to <Styled.EmailId>{EmailId}</Styled.EmailId>
            Check your inbox, log in, and then you can read the Resume Review.
          </Styled.SubTitle>
        </>
      </Styled.TextContainer>
    </>
  )
}

export default EmailSent
