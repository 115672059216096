import { FC, useCallback, useRef, useState } from 'react'
import * as React from 'react'
import type { Resume, CoverLetter, UserDocumentTemplate, UserDocumentTemplateId } from '@rio/types'
import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useI18n } from 'builder/hooks/useI18n'
import Icon from 'builder/components/Icon'
import { usePatchedTemplates } from './hooks'
import {
  Dot,
  Dots,
  SliderContainer,
  SliderContent,
  SliderPlaceholder,
  TemplateInfo,
  TemplateName,
  TemplatePreview,
  TemplatesLeftButton,
  TemplatesRightButton,
  UsersCount,
} from './styles'

interface TemplateSliderProps {
  document?: Resume | CoverLetter
  templates: UserDocumentTemplate[]
  onChange: (templateName: UserDocumentTemplateId) => void
}

SwiperCore.use([Navigation])

const TemplateSlider: FC<TemplateSliderProps> = ({ document, templates, onChange }) => {
  const { i18n } = useI18n()

  const [slideIndex, setSlideIndex] = useState(0)
  const patchedTemplates = usePatchedTemplates(document, templates)

  const prevRef: React.RefObject<HTMLButtonElement> = useRef(null)
  const nextRef: React.RefObject<HTMLButtonElement> = useRef(null)

  const handleSlideChange = useCallback(
    ({ realIndex }) => {
      setSlideIndex(realIndex)
      onChange(templates[realIndex].id)
    },
    [templates, onChange],
  )

  return (
    <SliderContainer>
      <TemplatesLeftButton ref={prevRef}>
        <Icon.Chevron />
      </TemplatesLeftButton>

      <TemplatesRightButton ref={nextRef}>
        <Icon.Chevron />
      </TemplatesRightButton>

      <Dots>
        {templates.map((t, dotIndex) => (
          <Dot key={`dot-${dotIndex}`} $isActive={dotIndex === slideIndex} />
        ))}
      </Dots>

      {patchedTemplates.length ? (
        <SliderContent>
          <Swiper
            navigation={{
              prevEl: prevRef.current,
              nextEl: nextRef.current,
            }}
            initialSlide={slideIndex}
            slidesPerView="auto"
            loop={true}
            spaceBetween={20}
            centeredSlides={true}
            slideToClickedSlide={true}
            onSlideChange={handleSlideChange}
          >
            {patchedTemplates.map(({ id, name, usersChose, preview }) => (
              <SwiperSlide key={`template-${id}`}>
                <TemplateInfo>
                  <TemplateName>{name}</TemplateName>

                  <UsersCount>
                    {i18n.t(
                      'builder.resume_editor.docx_template_select.users_chose_this_template',
                      {
                        number: usersChose,
                      },
                    )}
                  </UsersCount>
                </TemplateInfo>

                <TemplatePreview>
                  <img alt={`${id} template`} src={preview} />
                </TemplatePreview>
              </SwiperSlide>
            ))}
          </Swiper>
        </SliderContent>
      ) : (
        <SliderPlaceholder />
      )}
    </SliderContainer>
  )
}

export default TemplateSlider
