import { ReactNode } from 'react'

import * as Styles from './styles'

type Props = {
  onBack?: () => void
  children: ReactNode | string
}

export const CareerPathHeader = ({ children, onBack }: Props) => {
  return (
    <Styles.Container>
      {onBack && (
        <Styles.ArrowContainer onClick={onBack}>
          <Styles.LeftArrow />
          <Styles.Text>Back</Styles.Text>
        </Styles.ArrowContainer>
      )}
      <Styles.Title>{children}</Styles.Title>
    </Styles.Container>
  )
}
