import styled from 'styled-components'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import DefaultButton from 'builder/components/Button'
import { HorizontalTransitionFade } from 'builder/components/HorizontalTransitionFade'

export const Container = styled(HorizontalTransitionFade)`
  max-width: 544px;
  display: flex;
  flex-flow: column;
  gap: 32px;
  margin: 0 auto;
  padding: 48px 0;

  ${Media.Phone`
    gap: 24px;
    max-width: 100%;
    padding: 32px 20px;
  `}
`

export const Header = styled.div`
  display: flex;
  flex-flow: column;
  gap: 16px;
  text-align: center;
`

export const Title = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};
`

export const Description = styled.div`
  color: ${Colors.Neutral50};
`

export const Content = styled.div`
  display: flex;
  flex-flow: column;
  gap: 24px;
  padding: 32px 48px 48px;
  border-radius: 16px;
  background-color: ${Colors.Neutral5};

  ${Media.Phone`
    gap: 20px;
    padding: 24px 32px 32px;
  `}
`

export const Controls = styled.div`
  display: flex;
  gap: 12px;
`

export const Text = styled.div`
  white-space: pre-wrap;
`

export const Button = styled(DefaultButton)`
  background-color: #1a91f01a;
  color: ${Colors.Blue50};
`
