import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useEffect } from 'react'
import CioLimitModal from 'builder/components/CioLimitModal'
import { actions as uiActions, selectors } from 'builder/modules/ui'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as userSelectors } from 'builder/modules/user'
import { i18n as I18n } from 'builder/utils/i18n'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { billingPlanPageURL } from 'builder/utils/goToBillingPlanPage'
import tunerURL from 'images/modals/resume.jpg'

const TRANSLATION = 'builder.job_tracking.paywall_modal'

export const ExploreAndTrackJobsPaywall = () => {
  const dispatch = useDispatch()
  const config = useFeaturesConfig()
  const showTrackJobPaywallModal = useTypedSelector(selectors.trackJobPaywallModal)
  const upgradeUrl = useTypedSelector(userSelectors.getUpgradeUrl)

  const FEATURES_TRANSLATION = `${TRANSLATION}.features.feature`
  const careerPlanningFeatures = [
    { id: 1, enable: true, text: I18n.t(`${FEATURES_TRANSLATION}_1`) },
    { id: 2, enable: true, text: I18n.t(`${FEATURES_TRANSLATION}_2`) },
    { id: 3, enable: true, text: I18n.t(`${FEATURES_TRANSLATION}_3`) },
    { id: 4, enable: config.features?.jobTracking, text: I18n.t(`${FEATURES_TRANSLATION}_4`) },
    { id: 5, enable: config.features?.salaryAnalyzer, text: I18n.t(`${FEATURES_TRANSLATION}_5`) },
    { id: 6, enable: config.features?.interviewSchool, text: I18n.t(`${FEATURES_TRANSLATION}_6`) },
    { id: 7, enable: config.features?.jobSearchPlan, text: I18n.t(`${FEATURES_TRANSLATION}_7`) },
    { id: 8, enable: config.showResumeDistribution(), text: I18n.t(`${FEATURES_TRANSLATION}_8`) },
  ]

  useEffect(() => {
    if (showTrackJobPaywallModal) {
      trackInternalEvent('see_post_premium_paywall')
    }
  })

  const handleUpgradeClick = () => {
    window.open(billingPlanPageURL({ upgradeURL: upgradeUrl }), '_self')
    trackInternalEvent('cta_click_post_premium_paywall')
  }

  return (
    <>
      {showTrackJobPaywallModal ? (
        <CioLimitModal
          title={I18n.t(`${TRANSLATION}.title`)}
          subTitle={I18n.t(`${TRANSLATION}.sub_title`)}
          features={careerPlanningFeatures.filter(feat => feat.enable)}
          text={I18n.t(`${TRANSLATION}.features_header`)}
          cancelButtom={I18n.t(`${TRANSLATION}.cancel_button`)}
          upgradebutton={I18n.t(`${TRANSLATION}.upgrade_button`)}
          tunerImg={tunerURL}
          onClose={() => dispatch(uiActions.setTrackJobPaywall(false))}
          isExploreJobs={true}
          handleUpgrade={handleUpgradeClick}
        />
      ) : null}
    </>
  )
}
