import styled from 'styled-components'
import Typography from 'builder/styles/typography'

export const Container = styled.div`
  margin-bottom: 40px;
`

export const SectionTitle = styled.div`
  ${Typography.Subhead}
  font-weight: 600;
  margin-bottom: 12px;
`

export const SectionContent = styled.div``
