import { JobFunctions } from './constants'
import { Container, Content, Header, Option, Options, Service, JobFunctionCheckbox } from './styles'

interface CareerEntryProps {
  onYourJobFunctionChange: (name: string) => void
  jobFunction: string[]
}

export const JobFunction = ({ onYourJobFunctionChange, jobFunction }: CareerEntryProps) => {
  return (
    <Container>
      <Content>
        <Header>What is your job function?</Header>
        <Options>
          {JobFunctions.map((job, index) => (
            <Option htmlFor={job.name} key={index} checked={jobFunction?.includes(job.name)}>
              <JobFunctionCheckbox
                id={job.name}
                checked={jobFunction?.includes(job.name)}
                onChange={() => onYourJobFunctionChange(job.name)}
              />
              <Service>{job.name}</Service>
            </Option>
          ))}
        </Options>
      </Content>
    </Container>
  )
}
