import { useCallback } from 'react'
import { Resume } from 'packages/types'
import Anchor from 'builder/components/Anchor'
import EditorTitle from 'builder/components/EditorTitle'
import { TunerEditorProvider } from 'builder/components/Tuner'
import { SectionNames } from 'builder/modules/resumeEditor'
import { i18n as I18n } from 'builder/utils/i18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { LanguageLevelData } from 'builder/modules/generalEditor'
import { useUpdateSimpleField } from 'builder/hooks/useUpdateSimpleField'
import { Spacing } from '../Japanese/common/Spacing'
import { EditorContent, EditorPanel, EditorScroll, UserNavigation } from '../styles'
import ShokumuPersonalDetailsSection from './Sections/PersonalDetails/ShokumuPersonalDetailsSection'
import ShokumuSectionsList from './ShokumukeirekishoSectionsList'

interface Props {
  levels: {
    language?: LanguageLevelData[]
  }
  resume: Resume
}

const EditorWithContent = (props: Props): JSX.Element => {
  const isOnline = useTypedSelector(state => state.application.isOnline)
  const { resume } = props
  const { name } = resume
  const { updateSimpleField } = useUpdateSimpleField()

  const handleResumeNameChange = useCallback(
    (value: string) => updateSimpleField({ name: 'name', value: value, debounce: true }),
    [updateSimpleField],
  )

  return (
    <EditorPanel>
      {isOnline && <UserNavigation hasFeatures />}
      <EditorScroll>
        <EditorContent>
          <EditorTitle
            onChange={handleResumeNameChange}
            value={name || I18n.t('builder.resume_editor.untitled')}
          />
          <Spacing height="40px" width="100%" />
          <TunerEditorProvider {...{ resume }}>
            <Anchor id={SectionNames.details} scrollOffsetTop={70}>
              <ShokumuPersonalDetailsSection />
              <Spacing height="16px" width="100%" />
            </Anchor>
            <ShokumuSectionsList />
          </TunerEditorProvider>
        </EditorContent>
      </EditorScroll>
    </EditorPanel>
  )
}
export default EditorWithContent
