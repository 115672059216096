import { useI18n } from 'builder/hooks/useI18n'
import {
  ModalCard,
  ModalCardContainer,
  ModalCardCTA,
  ModalCardCTAContainer,
  ModalCardDescription,
  ModalCardImage,
  ModalCardSection,
  ModalCardTextContainer,
  ModalCardTitle,
} from './styles'

interface CardProps {
  title: string
  description: string
  image: string
  handleCtaClick?: () => void | undefined
  ctaText?: string
  ctaDisabled?: boolean
}

export const Card = ({
  title,
  description,
  image,
  handleCtaClick,
  ctaText,
  ctaDisabled,
}: CardProps) => {
  const { i18n } = useI18n()

  const getCtaText = () => {
    if (ctaText) {
      return ctaText
    }
    if (ctaDisabled) {
      return i18n.t('builder.included_in_your_subscription.coming_soon')
    }
    return i18n.t('builder.included_in_your_subscription.learn_more')
  }

  return (
    <ModalCard disabled={ctaDisabled} onClick={handleCtaClick}>
      <ModalCardContainer>
        <ModalCardSection>
          <ModalCardImage src={image} />
        </ModalCardSection>
        <ModalCardSection>
          <ModalCardTextContainer>
            <ModalCardTitle>{title}</ModalCardTitle>
            <ModalCardDescription>{description}</ModalCardDescription>
          </ModalCardTextContainer>
        </ModalCardSection>
        <ModalCardCTAContainer>
          <ModalCardCTA disabled={ctaDisabled}>{getCtaText()}</ModalCardCTA>
        </ModalCardCTAContainer>
      </ModalCardContainer>
    </ModalCard>
  )
}
