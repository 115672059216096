import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { actions } from 'builder/modules/resumeEditor'
import { i18n as I18n } from 'builder/utils/i18n'
import { UseExampleEvents, UseExampleEventLabels } from 'builder/modules/constants'
import {
  PreviewInfo,
  Title,
  SubTitle,
  PreviewImgContainer,
  PreviewImg,
  UseThisResumeContainer,
  UseThisResumeBtn,
  RatingContainer,
  RatingScore,
  RatingCount,
  Span,
} from './styles'
import { useResumeExampleContext } from './ResumeExampleContext'
import { ResumeExample } from './types'

const SelectedResumeExample = () => {
  const [loading, setLoading] = useState<boolean>(false)

  const dispatch = useDispatch()
  const { selectedResumeExample } = useResumeExampleContext()

  const { id, jobTitle, subtitle, ratingScore, ratingReviews } =
    selectedResumeExample as ResumeExample

  useEffect(() => {
    trackInternalEvent(UseExampleEvents.seeExamplePreview, {
      label: UseExampleEventLabels.examplesPrefill,
      jobTitle,
    })
  }, [jobTitle])

  const onUseExample = () => {
    trackInternalEvent(UseExampleEvents.clickUseExample, {
      label: UseExampleEventLabels.examplesPrefill,
      jobTitle,
    })
    setLoading(true)
    dispatch(actions.generateResumeFromExample({ exampleId: id, jobTitle }))
  }

  return (
    <>
      <PreviewInfo>
        <Title>{jobTitle}</Title>
        <SubTitle>{subtitle}</SubTitle>
        <PreviewImgContainer>
          <PreviewImg src={selectedResumeExample?.previewImageUrl} />
        </PreviewImgContainer>
      </PreviewInfo>

      <UseThisResumeContainer>
        <UseThisResumeBtn onClick={onUseExample} isLoading={loading} isDisabled={loading}>
          {I18n.t('builder.use_example.use_example_action')}
        </UseThisResumeBtn>
        <RatingContainer>
          {ratingReviews && (
            <RatingCount>
              {ratingReviews} {I18n.t('builder.use_example.rating_text')}
            </RatingCount>
          )}
          {ratingScore && (
            <RatingScore>
              <Span>{ratingScore}</Span>
            </RatingScore>
          )}
        </RatingContainer>
      </UseThisResumeContainer>
    </>
  )
}

export default SelectedResumeExample
