import { useState, useCallback, useEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import ModalOverlay from 'builder/components/ModalOverlay'
import { Icon20 } from 'builder/components/Icon'
import { i18n as I18n } from 'builder/utils/i18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/init'

import {
  ABANDONMENT_RESUME_FLOWS,
  FLOW_IDS,
  getFlow,
  HAS_ABANDONMENT_RESUME_MODAL_BEEN_SHOW,
} from './constants'
import {
  Container,
  Close,
  InnerContainer,
  Content,
  Header,
  Title,
  TitleContainer,
  CardContainer,
  CardSection,
  Card,
  CardImage,
  CardTitle,
} from './styles'
import WillBeBack from './WillBeBack'
import NeedGuidance from './NeedGuidance'
import DontLikeService from './DontLikeService'

type Props = {
  handleClose: () => void
  hasUserVisitedPlans: boolean
  email?: string
  fullName?: string
}

const MODAL_OVERLAY_CONFIGURATION = {
  backgroundOpacity: 0.6,
  overlayFadeDuration: 350,
  contentSlideDuration: 350,
}

const AbandonmentResumeModal: React.FC<Props> = ({
  handleClose,
  hasUserVisitedPlans,
  email,
  fullName,
}: Props) => {
  const [activeCardId, setActiveCardId] = useState(FLOW_IDS.willBeBack)
  const [hoveredCardId, setHoveredCardId] = useState(0)
  const [isCardClicked, setIsCardClicked] = useState(true)
  const locale = useTypedSelector(selectors.locale)

  useEffect(() => {
    localStorage.setItem(HAS_ABANDONMENT_RESUME_MODAL_BEEN_SHOW, 'true')
  }, [])

  useEffect(() => {
    trackInternalEvent('show_abandonment_popup', { source: 'builder', type: 'in_app' })
  }, [])

  const onClose = (isManual?: boolean) => {
    if (isManual) {
      trackInternalEvent('close_abandonment_popup')
    }
    handleClose()
  }

  const handleFeatureCardClick = useCallback((cardId: number) => {
    if (cardId && cardId !== -1) {
      if (cardId === FLOW_IDS.willBeBack) {
        trackInternalEvent('abandonment_reason_choice', { abandonment_reason: 'be_back' })
      } else if (cardId === FLOW_IDS.dontLikeTheService) {
        trackInternalEvent('abandonment_reason_choice', { abandonment_reason: 'dont_like_service' })
      } else if (cardId === FLOW_IDS.needGuidance) {
        trackInternalEvent('abandonment_reason_choice', { abandonment_reason: 'need_guidance' })
      }
      setIsCardClicked(true)
      setActiveCardId(cardId)
    } else {
      setIsCardClicked(false)
      setActiveCardId(0)
    }
  }, [])

  const handleCardHover = useCallback(
    (cardId: number) => {
      if (activeCardId === cardId || cardId === -1) {
        setHoveredCardId(-1)
      } else {
        setHoveredCardId(cardId)
      }
    },
    [activeCardId],
  )

  const getImgUrl = useCallback(
    (cardId: number) => {
      if (isCardClicked && cardId === activeCardId) {
        return getFlow(activeCardId, I18n.t)?.logoClicked
      } else if (hoveredCardId === cardId) {
        return getFlow(cardId, I18n.t)?.logoClicked
      } else {
        return getFlow(cardId, I18n.t)?.logo
      }
    },
    [isCardClicked, activeCardId, hoveredCardId],
  )

  return (
    <ModalOverlay onClick={() => onClose(true)} {...MODAL_OVERLAY_CONFIGURATION}>
      <Container>
        <Close onClick={() => onClose(true)}>
          <Icon20.Close />
        </Close>
        <InnerContainer>
          <Content>
            <Header>
              <TitleContainer>
                <Title>{I18n.t('builder.in_app_abandonment.title')}</Title>
                <Title>{I18n.t('builder.in_app_abandonment.subtitle')}</Title>
              </TitleContainer>
            </Header>
            <CardContainer>
              {ABANDONMENT_RESUME_FLOWS(I18n.t).map(card => {
                return (
                  <CardSection
                    onMouseOver={() => handleCardHover(card.id)}
                    onMouseOut={() => handleCardHover(-1)}
                    activeCardId={activeCardId}
                    key={card.id}
                    onClick={() => handleFeatureCardClick(activeCardId === card.id ? -1 : card.id)}
                    flowId={card.id}
                  >
                    <Card activeCardId={activeCardId} flowId={card.id}>
                      <CardImage src={getImgUrl(card.id)} />
                      <CardTitle
                        isCardActive={card.id === activeCardId}
                        isCardHovered={card.id === hoveredCardId}
                      >
                        {getFlow(card.id, I18n.t)?.titleLineOne}
                      </CardTitle>
                      {(locale !== 'nl-NL' || card.id !== FLOW_IDS.needGuidance) && (
                        <CardTitle
                          isCardActive={card.id === activeCardId}
                          isCardHovered={card.id === hoveredCardId}
                        >
                          {getFlow(card.id, I18n.t)?.titleLineTwo}
                        </CardTitle>
                      )}
                    </Card>
                  </CardSection>
                )
              })}
            </CardContainer>
            {activeCardId === FLOW_IDS.willBeBack && <WillBeBack handleClose={onClose} />}
            {activeCardId === FLOW_IDS.needGuidance && <NeedGuidance handleClose={onClose} />}
            {activeCardId === FLOW_IDS.dontLikeTheService && (
              <DontLikeService
                handleClose={onClose}
                hasUserVisitedPlans={hasUserVisitedPlans}
                email={email}
                fullName={fullName}
              />
            )}
          </Content>
        </InnerContainer>
      </Container>
    </ModalOverlay>
  )
}

export default AbandonmentResumeModal
