import { useMemo } from 'react'
import { matchPath, useLocation, Outlet } from 'react-router-dom'
import { useConfig } from 'builder/hooks/useConfig'
import { useUser } from 'builder/hooks/useUser'
import { SuperAppPromotionType } from 'builder/modules/init'
import { SuperAppPromoView, Sources } from 'builder/views/SuperAppPromoView'

export const SuperAppFeatureRoute = () => {
  const { pathname } = useLocation()
  const config = useConfig()
  const user = useUser()
  const isSuperApp = config?.features.superApp
  const isInternational = config?.features.international
  const promotionType = config?.features.superAppPromotionType
  const isFreeUser = !user?.hasPremiumFeatures

  const shouldShowPromo = useMemo(() => {
    // We are on CIO domain already: all feature are available with no banners
    if (isSuperApp || isInternational) return false

    // Free RIO users registered before CIO launch can use the features until they buy premium
    // (after that we invite them to activate CIO)
    if (promotionType === SuperAppPromotionType.old && isFreeUser) return false

    // All RIO users registered after CIO launch date must activate CIO to use the feature
    if (promotionType) return true

    // CIO hasn't launched yet: all features are still available
    return false
  }, [isSuperApp, isFreeUser, promotionType])

  const paths = [
    { route: '/job-search', source: Sources.jobSearch },
    { route: '/job-tracking', source: Sources.jobTracking },
    { route: '/interview-preparation/dashboard', source: Sources.interviewPrep },
  ]
  const featureName = paths.find(({ route }) => matchPath(route, pathname))?.source

  if (shouldShowPromo && featureName) {
    return <SuperAppPromoView source={featureName} />
  }

  return <Outlet />
}
