import { memo, useRef } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { UserData } from 'builder/modules/user'
import { useI18n } from 'builder/hooks/useI18n'
import { useConfig } from 'builder/hooks/useConfig'
import { useEscapeHandler } from 'builder/hooks/useEscapeHandler'
import { authService } from 'builder/services/AuthService'
import { useClickOutside } from 'builder/hooks/useClickOutside'
import Icon24 from 'builder/components/Icon'
import { CareerProfileCompletenessState, CareerProfileLink } from 'builder/components/CareerProfile'
import { selectors as careerProfileSelectors } from 'builder/modules/careerProfile'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { RecommendedJobsLinkContainer } from 'builder/views/CareerProfile/components/MyCareerProfileView/styles'
import {
  FeatureIcon,
  Text,
} from 'builder/components/CareerProfile/components/CareerProfileLink/styles'
import { ToggleCallback } from '../../types'
import {
  DropdownContainer,
  MainLinks,
  DefaultLink,
  RouterLink,
  Container,
  UserDropdownTrigger,
  UserPhoto,
  Title,
  LinkContent,
  ProfileLink,
  UserName,
  MyProfileText,
  ProfileLinks,
  Divider,
  CompletenessBadge,
  UserPhotoWrapper,
  ProfileCompletedBadge,
} from './styles'
import completedBadgeImgUrl from './images/completed-badge.svg'

interface Props {
  user: UserData
  photoUrl: string
  isOpen: boolean
  onToggle: ToggleCallback
}

export const UserDropdownWithCareerProfile = memo(({ user, photoUrl, isOpen, onToggle }: Props) => {
  const { i18n } = useI18n()
  const config = useConfig()
  const userRef = useRef<HTMLDivElement>(null)
  const completenessPercent = useTypedSelector(careerProfileSelectors.completenessPercent)
  const hasCompletenessBadge = completenessPercent !== null && completenessPercent !== 100

  const profilePictureBadge =
    completenessPercent === 100 ? (
      <ProfileCompletedBadge src={completedBadgeImgUrl} alt="Completed on 100%" />
    ) : null

  const statusComponent = hasCompletenessBadge && <CareerProfileCompletenessState />

  useClickOutside(userRef, () => onToggle(false))

  useEscapeHandler(() => onToggle(false))

  return (
    <>
      <Container ref={userRef} isPremium={user.hasPremiumFeatures}>
        <UserDropdownTrigger
          isOpen={isOpen}
          hasCompletenessBadge={hasCompletenessBadge}
          onClick={() => {
            trackInternalEvent('click_career_profile')
            onToggle(!isOpen)
          }}
        >
          <UserPhotoWrapper>
            <UserPhoto src={photoUrl} alt={user.fullName} />
            {profilePictureBadge}
          </UserPhotoWrapper>
          <Title>{i18n.t('builder.navbar.career_profile')}</Title>
          {hasCompletenessBadge && (
            <CompletenessBadge
              variant={completenessPercent >= 90 ? 'green' : 'orange'}
              size="small"
            >
              {completenessPercent}%
            </CompletenessBadge>
          )}
        </UserDropdownTrigger>

        {isOpen && (
          <DropdownContainer>
            <ProfileLink
              to="/career-profile/profile"
              onClick={() => {
                trackInternalEvent('click_profile_name')
              }}
            >
              <UserName>{user.fullName}</UserName>
              <MyProfileText>{i18n.t('builder.navbar.my_career_profile')}</MyProfileText>
            </ProfileLink>

            {statusComponent}

            <ProfileLinks>
              <CareerProfileLink type="insights" />
              <RecommendedJobsLinkContainer to="/job-search?view=recommendation">
                <FeatureIcon name="JobTracker" />
                <Text>{i18n.t(`builder.career_profile.navigation_links.recommended_jobs`)}</Text>
              </RecommendedJobsLinkContainer>
              <CareerProfileLink type="pitch" />
              <CareerProfileLink type="cover-letter" />
            </ProfileLinks>

            <Divider />

            <MainLinks>
              <RouterLink to="/account">
                <LinkContent>
                  <Icon24.Settings2 />
                  {i18n.t('builder.navbar.account_settings')}
                </LinkContent>
              </RouterLink>

              {config?.links.help && (
                <DefaultLink href={config.links.help} target="_blank" rel="noreferrer noopener">
                  <LinkContent>
                    <Icon24.Info />
                    {i18n.t('builder.navbar.faq')}
                  </LinkContent>
                </DefaultLink>
              )}

              <LinkContent onClick={() => authService.logout()}>
                <Icon24.LogOut />
                {i18n.t('builder.navbar.log_out')}
              </LinkContent>
            </MainLinks>
          </DropdownContainer>
        )}
      </Container>
    </>
  )
})
