import { useTransition } from '@react-spring/web'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/enterpriseModal'
import { transitionValues } from './constants'
import * as Styled from './styles'

const JobStatusProgressBar = () => {
  const showProgressBar = useTypedSelector(selectors.showJobStatusProgressBar)
  const transition = useTransition(showProgressBar, transitionValues)

  return transition((style, item) =>
    item ? (
      <Styled.Container style={style}>
        <Styled.DashedCircle>
          <Styled.BlueTick />
        </Styled.DashedCircle>
      </Styled.Container>
    ) : null,
  )
}

export default JobStatusProgressBar
