import { ContentBlock } from 'draft-js'
import { DecoratorCallback } from '../types'

export const findWithRegex = (
  regex: RegExp,
  contentBlock: ContentBlock,
  callback: DecoratorCallback,
) => {
  const text = contentBlock.getText()
  let matchArr, start
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index

    const indexOfLastSymbol = start + matchArr[0].length
    const symbolAfterWord = text.slice(indexOfLastSymbol, indexOfLastSymbol + 1)
    const notTail = start !== 0 ? /[\s\W]/.test(text.slice(start - 1, start)) : true
    const isEnded = symbolAfterWord !== '' ? /[\W]/.test(symbolAfterWord) : true

    if (isEnded && notTail) {
      callback(start, start + matchArr[0].length)
    }
  }
}
