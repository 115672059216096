import { IVisualProps } from 'builder/components/CIO-Dasboard-2.0/types'
import * as Styled from './styles'

export const ResumeDistributionVisual = ({ alt, imgUrl }: IVisualProps) => {
  return (
    <Styled.VisualContainer>
      <Styled.VisualImage src={imgUrl} alt={alt} />
    </Styled.VisualContainer>
  )
}
