import PropTypes from 'prop-types'
import Icon from 'builder/components/Icon'

import { ButtonContainer, ButtonIcon } from './styles'

const NetworkIcon = ({ network }) => {
  switch (network) {
    case 'facebook':
      return <Icon.Facebook />
    case 'linkedin':
      return <Icon.Linkedin />
    case 'google':
      return <Icon.Google />
    case 'email':
      return <Icon.Mail />
    default:
      return null
  }
}

NetworkIcon.propTypes = {
  network: PropTypes.string,
}

const SocialButton = ({ network, onClick, children, href }) => {
  const buttonProps = href ? { href } : { as: 'button' }

  return (
    <ButtonContainer network={network} onClick={() => onClick(network)} {...buttonProps}>
      <ButtonIcon network={network}>
        <NetworkIcon network={network} />
      </ButtonIcon>
      {children}
    </ButtonContainer>
  )
}

SocialButton.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
  network: PropTypes.string,
  children: PropTypes.any,
}

SocialButton.defaultProps = {
  onClick: () => {},
  network: 'email',
}

export default SocialButton
