import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import ModalOverlay from 'builder/components/ModalOverlay'
import Button, { ButtonTheme } from 'builder/components/Button'
import { Icon24 } from 'builder/components/Icon'
import { actions, selectors } from 'builder/modules/user'
import { i18n as I18n } from 'builder/utils/i18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { FetchStatuses } from 'builder/modules/constants'
import { useConfig } from 'builder/hooks/useConfig'
import { InternalEvents } from '../../common/types'
import { Container, Close, Visual, Content, Title, Text, ButtonContainer } from './styles'

interface DiscountModalProps {
  onContinueCancellation: () => void
  onClose: () => void
  priceAfterDiscount?: number
}

export const DiscountModal = ({
  onContinueCancellation,
  onClose,
  priceAfterDiscount,
}: DiscountModalProps) => {
  const { isPhone } = useMediaQueries()
  const dispatch = useDispatch()
  const cancelSubscriptionStatus = useTypedSelector(selectors.cancellationStatus)
  const discountStatus = useTypedSelector(selectors.discountStatus)
  const config = useConfig()
  const isSubscriptionV4Enabled = config?.features.subCancellationV4 || false

  const handleClose = useCallback(() => onClose(), [onClose])

  const handleApplyDiscount = useCallback(() => {
    trackInternalEvent(InternalEvents.abort, { reason: 'discount applied' })
    trackInternalEvent('promo_acceptance', { promo: 'discount', where: 'cancellation' })
    dispatch(actions.getDiscountNew())
  }, [dispatch])

  useEffect(() => {
    trackInternalEvent('see_discount_popup')
  }, [])

  return (
    <ModalOverlay
      fullScreen={isPhone}
      overlayFadeDuration={250}
      contentSlideDuration={0}
      onClick={handleClose}
    >
      <Container>
        <Visual />
        <Content>
          <Title>
            {isSubscriptionV4Enabled
              ? I18n.t('builder.subscription_cancellation_v4.discount_offered_modal.title')
              : I18n.t('builder.subscription_cancellation.discount_offered_modal.title')}
          </Title>
          <Text>
            {isSubscriptionV4Enabled ? (
              <>
                {I18n.t(
                  'builder.subscription_cancellation_v4.discount_offered_modal.description_line_one',
                )}{' '}
                <span className="important">
                  {I18n.t(
                    'builder.subscription_cancellation_v4.discount_offered_modal.description_line_two',
                  )}
                </span>
              </>
            ) : priceAfterDiscount ? (
              I18n.t(
                'builder.subscription_cancellation.discount_offered_modal.description_with_price',
                {
                  price_after_discount: priceAfterDiscount?.toFixed(2),
                  defaultValue: I18n.t(
                    'builder.subscription_cancellation.discount_offered_modal.description',
                  ),
                },
              )
            ) : (
              I18n.t('builder.subscription_cancellation.discount_offered_modal.description')
            )}
          </Text>
        </Content>
        <ButtonContainer>
          <Button
            theme={ButtonTheme.ghost}
            onClick={onContinueCancellation}
            isLoading={cancelSubscriptionStatus === FetchStatuses.loading}
            isDisabled={cancelSubscriptionStatus === FetchStatuses.loading}
          >
            {isSubscriptionV4Enabled
              ? I18n.t(
                  'builder.subscription_cancellation_v4.discount_offered_modal.discard_discount',
                )
              : I18n.t('builder.subscription_cancellation.discount_offered_modal.discard_discount')}
          </Button>
          <Button
            onClick={handleApplyDiscount}
            isLoading={discountStatus === FetchStatuses.loading}
            isDisabled={discountStatus === FetchStatuses.loading}
          >
            {isSubscriptionV4Enabled
              ? I18n.t('builder.subscription_cancellation_v4.discount_offered_modal.apply_discount')
              : I18n.t('builder.subscription_cancellation.discount_offered_modal.apply_discount')}
          </Button>
        </ButtonContainer>
        <Close onClick={handleClose}>
          <Icon24.Close />
        </Close>
      </Container>
    </ModalOverlay>
  )
}
