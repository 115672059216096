import { Optional } from 'packages/types'
import { SectionNames } from 'builder/modules/resumeEditor'
import { SuggestionScope } from './types'

export function shouldUseAiPhrases(scope: Optional<SuggestionScope>, locale: string) {
  return scope === 'workExperience' && ['en', 'en-GB', 'nl-NL'].includes(locale)
}

export function shouldUseAiProfileSummary(scope: Optional<SuggestionScope>, locale: string) {
  return scope === 'profile' && ['en'].includes(locale)
}

export function suggestionScopeToSectionName(scope: SuggestionScope) {
  switch (scope) {
    case 'profile':
      return SectionNames.profile
    case 'workExperience':
      return SectionNames.workExperiences
  }
}
