import Colors from 'builder/styles/colors'
import * as Styled from './styles'

type Props = {
  userPhotoUrl: string
}

export const CareerMatrixVisual = ({ userPhotoUrl }: Props) => {
  return (
    <Styled.Container>
      <Styled.Tile color={Colors.Green30} />
      <Styled.Tile color="transparent" />
      <Styled.Tile color={Colors.Indigo30} />

      <Styled.Tile color={Colors.Green20} />
      <Styled.Tile color={Colors.Beige20} />
      <Styled.Tile color={Colors.Indigo20} />

      <Styled.Tile color={Colors.Green20} />
      <Styled.Tile color={Colors.Beige20} />
      <Styled.Tile color={Colors.Indigo20} />

      <Styled.Plate>
        <Styled.Photo alt="User photo" src={userPhotoUrl} />
      </Styled.Plate>
    </Styled.Container>
  )
}
