import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import { FontWeights } from 'builder/styles/typography'

interface ContainerProps {
  backgroundColor?: string
}

export const Container = styled.div<ContainerProps>(({ backgroundColor }) => {
  return css`
    cursor: pointer;
    height: 72px;
    display: flex;
    align-items: center;
    width: 100%;
    background-color: ${backgroundColor || Colors.Blue10};
    border-radius: 8px;
    padding: 16px 29px 16px 16px;
    gap: 16px;
    border: ${backgroundColor === Colors.Indigo10
      ? `1px solid ${Colors.Indigo20}`
      : `1px solid ${Colors.Blue20}`};

    &:hover {
      background-color: ${backgroundColor === Colors.Indigo10 ? Colors.Indigo20 : Colors.Blue20};

      & button {
        color: ${backgroundColor === Colors.Indigo10 ? Colors.Indigo70 : Colors.Blue60};
      }

      & p {
        color: ${backgroundColor === Colors.Indigo10 ? Colors.Indigo60 : Colors.Blue50};
      }
    }
  `
})

export const Title = styled.p`
  ${FontWeights.Medium};
  font-size: 16px;
  line-height: 20px;
  color: ${Colors.Neutral90};
`

export const Button = styled.button<ContainerProps>(({ backgroundColor }) => {
  return css`
    cursor: pointer;
    color: ${backgroundColor === Colors.Indigo10 ? Colors.Indigo60 : Colors.Blue50};
    margin-left: auto;
    font-size: 16px;
    border: 0;
  `
})

export const LogoContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
`
