import { ActionsSkeleton, BannerSkeleton, RecruiterListSkeleton } from './Skeleton/Skeleton'
import { Container, HeaderContainer, ListContainer, SkeletonContainer } from './styles'

export const WidgetLoading = () => {
  return (
    <Container>
      <HeaderContainer>
        <BannerSkeleton />
      </HeaderContainer>
      <ListContainer>
        <SkeletonContainer>
          <RecruiterListSkeleton />
          <ActionsSkeleton />
        </SkeletonContainer>
      </ListContainer>
    </Container>
  )
}
