import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actions, selectors } from 'builder/modules/jobTracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { i18n } from 'builder/utils/i18n'
import ChromeExtensionIcon from './assets/chrome-extension.svg'
import {
  BannerImage,
  FlexContainer,
  Notification,
  NotificationContainer,
  Separator,
  Subtitle,
  Title,
  Button,
} from './styles'

export const ChromeExtensionNotification = () => {
  const dispatch = useDispatch()
  const showBanner = useTypedSelector(selectors.jtShowBanner)

  const handleOk = () => {
    dispatch(actions.setShowJTChromeBanner(false))
  }

  useEffect(() => {
    const chromeExtension = window.location.href.split('?')[1] === 'chrome_extension=true'
    chromeExtension && dispatch(actions.fetchJTShowBannerRequest())
  }, [dispatch])

  return (
    <>
      <NotificationContainer visible={+showBanner}>
        <Notification>
          <Separator>
            <FlexContainer>
              <BannerImage src={ChromeExtensionIcon} />

              <div>
                <Title>{i18n.t('builder.job_tracking.chrome_extension_notification.title')}</Title>
                <Subtitle>
                  {i18n.t('builder.job_tracking.chrome_extension_notification.subtitle')}
                </Subtitle>
              </div>
            </FlexContainer>

            <FlexContainer>
              <Button onClick={handleOk}>
                {i18n.t('builder.job_tracking.chrome_extension_notification.submit')}
              </Button>
            </FlexContainer>
          </Separator>
        </Notification>
      </NotificationContainer>
    </>
  )
}
