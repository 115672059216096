import { useState, useEffect, MutableRefObject, useRef } from 'react'

export function useVisibilityObserver<T extends Element | null>(
  ref?: MutableRefObject<T> | null,
  observerOptions: IntersectionObserverInit = {},
): boolean {
  // State and setter for storing whether element is visible
  const [isInViewport, setInViewport] = useState<boolean>(false)
  const optionsRef = useRef(observerOptions)

  useEffect(() => {
    const isSupported = 'IntersectionObserver' in window && 'IntersectionObserverEntry' in window
    if (!isSupported) return

    const observer = new IntersectionObserver(([entry]) => {
      // Update our state when observer callback fires
      setInViewport(entry.isIntersecting)
    }, optionsRef.current)

    const current = ref?.current
    if (current) observer.observe(current)

    return () => {
      if (current) observer.unobserve(current)
    }
  }, [ref])
  return isInViewport
}
