import { useI18n } from 'builder/hooks/useI18n'

const basePath = 'builder.resume_editor.shokumukeirekisho'

export const useShokumuTranslations = () => {
  const { i18n: I18n } = useI18n()
  const i18n = (path: string) => I18n.t(`${basePath}.${path}`)

  return { i18n }
}
