import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Container = styled.div`
  width: 100%;
`

export const Header = styled.div`
  ${FontWeights.Medium};
  padding-bottom: 16px;

  ${Media.Phone`
    padding-bottom: 8px;
  `}
`

export const Description = styled.div`
  color: ${Colors.Neutral50};

  b {
    ${FontWeights.Medium};
    color: ${Colors.Neutral90};
    line-height: 50px;
  }
`
