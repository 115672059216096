import { useEffect, ReactNode } from 'react'
import { useLocation } from 'react-router-dom'

type ScrollToTopProps = {
  children: ReactNode
}

export const ScrollToTop = ({ children }: ScrollToTopProps) => {
  const location = useLocation()
  const state = (location.state as { preventScrollToTop: boolean }) || {}

  useEffect(() => {
    if (!state.preventScrollToTop) {
      window.scrollTo(0, 0)
    }
  }, [location.pathname, state.preventScrollToTop])

  return <>{children}</>
}
