import { memo } from 'react'
import { selectors } from 'builder/modules/careerCoaching'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

import { Container } from './styles'
import Specialities from './components/Specialities'
import AboutCoach from './components/AboutCoach'
import CoachServices from './components/CoachServices'
import PersonalDetails from './components/PersonalDetails'

const CoachDetails = () => {
  const coaches = useTypedSelector(selectors.coaches)
  let coachDetails = useTypedSelector(selectors.coachDetails)

  coaches?.forEach(coach => {
    if (coach.id.toString() === localStorage.getItem('coachId')) {
      coachDetails = coach
    }
  })

  const {
    firstName,
    lastName,
    location,
    coachAvatar,
    careerStages,
    aboutMe,
    services,
    industries,
    jobFunctions,
  } = coachDetails
  return (
    <Container>
      <PersonalDetails {...{ firstName, lastName, location, coachAvatar, careerStages }} />
      <AboutCoach {...{ aboutMe }} />
      <CoachServices {...{ services }} />
      <Specialities {...{ industries, jobFunctions }} />
    </Container>
  )
}

export default memo(CoachDetails)
