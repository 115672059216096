import { TextField } from 'builder/components/TextField'
import { TunerEditorContext } from 'builder/components/Tuner'
import { useI18n } from 'builder/hooks/useI18n'
import { useContext } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'builder/components/Card/styles'

export function JobTitle(props) {
  const { i18n } = useI18n()
  const tunerEditor = useContext(TunerEditorContext)
  const isActiveIssue = tunerEditor?.activeIssueId === 'no_work_description'
  const JOB_TITLE = i18n.t('builder.resume_editor.work_experiences_card.title')
  const { item, updateSimpleField, autoFocus } = props

  return (
    <>
      <Field>
        <TextField
          autoFocus={autoFocus && !isActiveIssue}
          label={JOB_TITLE}
          name="title"
          onChange={updateSimpleField}
          value={item.title || ''}
        />
      </Field>
    </>
  )
}

JobTitle.propTypes = {
  item: PropTypes.object,
  updateSimpleField: PropTypes.func,
  autoFocus: PropTypes.bool,
}
