import { useEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { ButtonSize } from 'builder/components/Button'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { i18n } from 'builder/utils/i18n'
import ChromeICon from '../../../../../app/assets/images/builder/job-tracker/chrome.png'
import SuccesIcon from '../../../../../app/assets/images/builder/job-tracker/success.svg'
import {
  BannerSection,
  Icon,
  ChromeButton,
  LeftSection,
  Title,
  Subtitle,
  DetailsSection,
} from './styles'

type ChromeBannerProps = {
  onClick: (event: React.MouseEvent) => void
  isChromeExtensionInstalled: boolean
  componentType: string
}

export const ChromeBanner = ({
  onClick,
  isChromeExtensionInstalled,
  componentType,
}: ChromeBannerProps) => {
  const isSidebar = componentType === 'job_tracker_sidebar'
  const { features } = useFeaturesConfig()

  useEffect(() => {
    trackInternalEvent('view_banner_chrome', { label: 'job_tracker', source: componentType })
  }, [componentType])

  const dashboardTitle =
    features.international || features.superApp
      ? i18n.t('builder.promote_banners.extension_promotion_banner.title_dashboard_v2')
      : i18n.t('builder.promote_banners.extension_promotion_banner.title')

  const title = isChromeExtensionInstalled
    ? i18n.t('builder.promote_banners.extension_promotion_banner.title_2')
    : isSidebar
    ? i18n.t('builder.promote_banners.extension_promotion_banner.title2')
    : dashboardTitle

  const showChromeButton = !isSidebar && !isChromeExtensionInstalled
  const isSubtitle = isSidebar && !isChromeExtensionInstalled

  return (
    <BannerSection
      isChromeExtensionInstalled={isChromeExtensionInstalled}
      onClick={isSidebar ? onClick : undefined}
      isSidebar={isSidebar}
      componentType={componentType}
    >
      <LeftSection>
        <Icon src={isChromeExtensionInstalled ? SuccesIcon : ChromeICon} />
        <DetailsSection>
          <Title isChromeExtensionInstalled={isChromeExtensionInstalled}>{title}</Title>
          {isSubtitle && (
            <Subtitle>
              {i18n.t('builder.promote_banners.extension_promotion_banner.subtitle')}
            </Subtitle>
          )}
        </DetailsSection>
      </LeftSection>
      {showChromeButton && (
        <ChromeButton size={ButtonSize.small} onClick={!isSidebar ? onClick : undefined}>
          {i18n.t('builder.promote_banners.extension_promotion_banner.button')}
        </ChromeButton>
      )}
    </BannerSection>
  )
}
