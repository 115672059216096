import { trackInternalEvent } from '@rio/tracking'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useCareerPlans } from 'builder/components/CareerPlanCard/hooks/useCareerPlans'
import CareerPlanCard from 'builder/components/CareerPlanCard'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import { label } from 'builder/modules/sevenStories'
import Header from './components/Header'
import {
  ContentContainer,
  LeftContentWrapper,
  ResultContainer,
  RightContentWrapper,
  RightRectangleContainer,
} from './styles'
import Summary from './components/Summary'
import Stories from './components/Stories'
import Skills from './components/Skills'

type Props = {
  handleShowStory: (id: number) => void
  showStoryId: number
  handlePreviousStep: () => void
}

const AssessmentResult = ({ handleShowStory, showStoryId, handlePreviousStep }: Props) => {
  const { isTablet } = useMediaQueries()
  const { careerPlans } = useCareerPlans()

  useEffectOnMount(() => {
    trackInternalEvent('enter_assessment_result_page', {
      assessment_name: 'Seven Stories',
      ...label,
    })
  })

  return (
    <ResultContainer>
      <Header {...{ handlePreviousStep }} />

      <ContentContainer>
        {isTablet ? (
          <LeftContentWrapper>
            <Summary />
            <Skills />
            <Stories {...{ handleShowStory, showStoryId }} />
          </LeftContentWrapper>
        ) : (
          <>
            <LeftContentWrapper>
              <Summary />
              <Stories {...{ handleShowStory, showStoryId }} />
            </LeftContentWrapper>

            <RightContentWrapper>
              <Skills />
              <RightRectangleContainer />
              <CareerPlanCard
                key={careerPlans[0].name}
                careerPlan={careerPlans[0]}
                isSevenStoriesExercise={true}
              />
            </RightContentWrapper>
          </>
        )}
      </ContentContainer>
    </ResultContainer>
  )
}

export default AssessmentResult
