import type { Resume, CoverLetter, ExpertDoc, UserDocumentType } from '@rio/types'
import { FetchStatuses, DocumentTypes, ResumeIDStatuses } from 'builder/modules/constants'

export type BriefResume = Pick<
  Resume,
  | 'id'
  | 'type'
  | 'secureId'
  | 'renderingToken'
  | 'name'
  | 'template'
  | 'version'
  | 'updatedAt'
  | 'createdAt'
  | 'resumeScore'
  | 'averageScore'
  | 'jobPostingScore'
  | 'employerName'
  | 'jobTitle'
  | 'aiJobStatus'
  | 'aiTaskId'
  | 'jobPostingId'
  | 'autoTailored'
  | 'draft'
  | 'originalResumeId'
>

export type BriefDocumentType = BriefLetter | BriefResume | BriefExpertDoc

export type BriefLetter = CoverLetter

export type BriefExpertDoc = ExpertDoc

export interface PanelStore {
  documents: Array<BriefResume | BriefLetter | BriefExpertDoc>
  documentsStatus: FetchStatuses
  resumeStatus: ResumeIDStatuses
  resumeId: number
  activeTabId: number
  isTailorResume: boolean
  isWelcomeModelopen?: boolean
  resumeUpdateStatus: ResumeUpdateStatus
  helperWidgets?: IHelperWidget[]
  trDocuments: Record<number, BriefExpertDoc>
  trDocumentsStatus: Record<number, FetchStatuses>
  duplicationUrl: string
  isDuplicationLoading: boolean
  aiJobStatus: string | null
  autoTailoredResumes: BriefResume[]
}

export enum IWidgetsStatus {
  PENDING = 'pending',
  COMPLETED = 'completed',
}

export enum EWidgetsName {
  JOB_SEARCH = 'job_search',
  PREMIER_VIRTUAL = 'my_events',
}

export enum AIRefreshStatus {
  PENDING = 'pending',
  RETRY = 'retry',
  FAILURE = 'failure',
  SUCCESS = 'success',
}

export interface IWidgetsInfo {
  id: number
  name: string
  status: IWidgetsStatus
  order: number
}

export interface IHelperWidget {
  widgetCollectionName: EWidgetsName
  widgetsList: IWidgetsInfo[]
}

export type BriefDocumentMap = {
  [DocumentTypes.resume]: BriefResume
  [DocumentTypes.coverLetter]: BriefLetter
  [DocumentTypes.trDocument]: BriefExpertDoc
}

export interface FetchResumeStatus {
  resumeId: number | null
}

export enum ResumeUpdateStatus {
  Initial = 'Initial',
  Started = 'Started',
  Finished = 'Finished',
}

export enum PanelSessionStorageKeys {
  ACTION_AFTER_UPGRADING_PLAN = 'actionAfterUpgradingPlan',
  DOCUMENT_TYPE = 'documentType',
  DOCUMENT_TO_COPY_ID = 'documentToCopyId',
  COVER_LETTER_TEMPLATE = 'coverLetterTemplate',
}

export type ActionAfterUpgradingPlan = 'createDocument' | 'copyDocument'

export interface UIStatesConfig {
  resume_distribution_popup_viewed?: boolean
  dashv2_tour_completed?: boolean
  total_job_search_tour_completed?: boolean
  user_started_auto_apply_bubbles?: boolean
  show_auto_apply_guide_button?: boolean
}

export interface UIStatesConfigStore {
  resumeDistributionPopupViewed?: boolean
  dashv2TourCompleted?: boolean
  totalJobSearchTourCompleted?: boolean
  userStartedAutoApplyBubbles?: boolean
  showAutoApplyGuideButton?: boolean
}

export enum ResumeEditorQueryParams {
  isResumeTailor = 'isResumeTailor',
}

/**
 * This structure allows us to create cover letter with predefined content
 * and not allow this for other document types
 */
export type CreateDocumentActionPayload =
  | {
      type: Exclude<UserDocumentType, 'cover_letter'>
      name?: string
      target?: '_blank' | '_self'
    }
  | {
      type: Extract<UserDocumentType, 'cover_letter'>
      name?: string
      target?: '_blank' | '_self'
      details?: Partial<BriefLetter>
    }

export interface DocumentAiJobStatus {
  id: number
  aiJobStatus: AIRefreshStatus
}
