import styled from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'
import { fadeInStyles } from '../styles'

export const BackgroundGalleryWrapper = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 36px;
  left: 44px;
  right: 44px;
  ${fadeInStyles};

  & .swiper-container {
    position: relative;
    overflow: visible;
  }

  & .swiper-wrapper {
    display: flex;
    position: relative;
    transition-property: transform;
  }

  & .swiper-slide {
    display: flex;
    align-items: center;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
`

export const BackgroundOption = styled.div<{ isActive: boolean }>`
  position: relative;
  overflow: hidden;
  padding: 4px;
  width: 100px;
  height: 100px;
  border-radius: 8px;
  background-color: ${props => (props.isActive ? Colors.Blue50 : hexToRgba(Colors.Neutral80, 0))};
  transition: background-color 0.15s;
  cursor: pointer;

  &:hover {
    background-color: ${props => (props.isActive ? Colors.Blue50 : Colors.Neutral80)};
  }
`

export const BackgroundGalleryDivider = styled.div`
  width: 1px;
  height: 40px;
  margin-left: 20px;
  background-color: ${Colors.Neutral60};
  opacity: 0.4;
`
