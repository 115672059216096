import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { Typography } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import DocumentImagePreview from 'builder/components/DocumentImagePreview'

export const Container = styled.div`
  width: 100%;
  gap: 16px;
  display: flex;
  flex-wrap: wrap;

  ${Media.Phone`
    gap: 12px;
  `}
`

export const Resume = styled.div<{ isSelected: boolean }>`
  width: 218px;
  padding: 16px;
  gap: 13.63px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid ${props => (props.isSelected ? Colors.Blue50 : Colors.Neutral15)};

  &:hover {
    cursor: pointer;
    border: 1px solid ${Colors.Blue50};
  }

  ${Media.Phone`
    width: 100%;
    gap: 16px;
    padding: 12px;
    flex-direction: row;
  `}
`

export const ResumeImage = styled(DocumentImagePreview)`
  width: 104px;
  height: 146.37px;

  ${Media.Phone`
    width: 72px;
    height: 100.26px;
  `}
`

export const ResumeDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${Media.Phone`
    align-self: center;
  `}
`

export const UserName = styled.div``

export const UserDesignation = styled(UserName)``

export const UpdatedAt = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
`
