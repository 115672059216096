import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Container = styled.div`
  background-color: ${Colors.White};
  border-radius: 16px;
`

export const MainContent = styled.div`
  display: flex;
  gap: 16px;
  padding: 24px;

  ${Media.Phone`
    padding: 24px 24px 24px 16px;
    gap: 12px;
  `}
`

export const CustomContent = styled.div`
  padding: 0 12px 12px;
`

export const IconImage = styled.img`
  flex: 0 0 32px;
  height: 32px;
`

export const TextContainer = styled.div`
  display: flex;
  gap: 16px;

  ${Media.Phone`
    flex-flow: column;
  `}
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: 4px;
  flex: 0 0 220px;

  ${Media.Phone`
    flex: auto;
  `}
`

export const Header = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};
`

export const Subheader = styled.div``

export const Description = styled.div`
  margin: 4px 0;
  flex: auto;
`
