import { useEffect, useState } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { Icon20 } from 'builder/components/Icon'
import { i18n } from 'builder/utils/i18n'
import { goToBillingPlanPage } from 'builder/utils/goToBillingPlanPage'
import CircularProgressBar from './CircularProgressBar'
import * as Styled from './styles'
import { ETrailModalLocalKeys } from './types'

interface IProps {
  daysLeftPremium: number
}

const PremiumRemindModal = ({ daysLeftPremium }: IProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handlePremiumUpgrade = () => {
    trackInternalEvent('cta_click_counter_reminder', { premium_days_left: daysLeftPremium })
    goToBillingPlanPage()
  }

  useEffect(() => {
    const lastShownTime = localStorage.getItem(ETrailModalLocalKeys.lastShownTrialModal)
    let trailModalPeriod: string | null | number = localStorage.getItem('trailModalPeriod')

    if (!trailModalPeriod) {
      trailModalPeriod = 24 * 60 * 60 * 1000
      localStorage.setItem(ETrailModalLocalKeys.trailModalPeriod, trailModalPeriod.toString())
    } else {
      trailModalPeriod = parseInt(trailModalPeriod)
    }

    if (!lastShownTime) {
      setIsOpen(true)
      localStorage.setItem(
        ETrailModalLocalKeys.lastShownTrialModal,
        new Date().getTime().toString(),
      )
      trackInternalEvent('see_counter_reminder', { premium_days_left: daysLeftPremium })
    } else {
      const currentTime = new Date().getTime()
      if (currentTime - parseInt(lastShownTime) > trailModalPeriod) {
        setIsOpen(true)
        localStorage.setItem(ETrailModalLocalKeys.lastShownTrialModal, currentTime.toString())
        trackInternalEvent('see_counter_reminder', { premium_days_left: daysLeftPremium })
      }
    }
  }, [daysLeftPremium])

  const handleClose = () => {
    trackInternalEvent('close_counter_reminder', { premium_days_left: daysLeftPremium })
    setIsOpen(false)
  }

  const getTitle = () => {
    if (daysLeftPremium < 1) {
      return i18n.t(`builder.navigation.post_premium_modal.access_over`)
    }
    return i18n.t(`builder.navigation.post_premium_modal.days_left_to_pro`, {
      days_left_count: daysLeftPremium,
    })
  }

  const getMessage = () => {
    if (daysLeftPremium < 1) {
      return i18n.t(`builder.navigation.post_premium_modal.access_over_text`)
    }
    return i18n.t(`builder.navigation.post_premium_modal.days_left_message`, {
      days_left_count: daysLeftPremium,
    })
  }

  if (!isOpen) {
    return <></>
  }

  return (
    <>
      <Styled.ModalContainer isOpen={isOpen}>
        <Styled.Container>
          <Styled.CloseIcon onClick={handleClose} />
          <Styled.CrownTopIcon daysLeftPremium={daysLeftPremium} />
          <CircularProgressBar totalSteps={7} completedSteps={daysLeftPremium} />
          <Styled.TextContainer>
            <Styled.Title>{getTitle()}</Styled.Title>
            <Styled.Text>{getMessage()}</Styled.Text>
          </Styled.TextContainer>
          <Styled.Footer>
            <Styled.NotNowBUtton onClick={handleClose}>
              {i18n.t(`builder.navigation.post_premium_modal.not_now`)}
            </Styled.NotNowBUtton>
            <Styled.MakePremiumButton onClick={handlePremiumUpgrade}>
              <Icon20.Crown />
              {i18n.t(
                daysLeftPremium < 1
                  ? `builder.navigation.post_premium_modal.renew_pro`
                  : `builder.navigation.post_premium_modal.keep_pro`,
              )}
            </Styled.MakePremiumButton>
          </Styled.Footer>
        </Styled.Container>
      </Styled.ModalContainer>
    </>
  )
}

export default PremiumRemindModal
