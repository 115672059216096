import axios from 'axios'

import { i18n } from 'builder/utils/i18n'
import { apiClient, baseClient } from 'builder/modules/apiClient'
import ErrorLogger from 'builder/services/ErrorLogger'
import PerformanceLogger from 'builder/services/PerformanceLogger'
import { all, put, call, delay, select, takeEvery } from 'redux-saga/effects'
import { actions, selectors } from './userModule'
import { FetchStatuses } from 'builder/modules/constants'
import { navigate } from 'builder/modules/navigate'
import { SnackbarTypes, actions as uiActions } from 'builder/modules/ui'
import { actions as interviewActions } from 'builder/modules/interview/interviewModule'

export function* fetchUserSaga(action) {
  const { silent = false } = action.payload || {}

  try {
    // Don't block the UI if we are just refreshing the user data
    if (!silent) yield put(actions.setFetchStatus(FetchStatuses.loading))

    const url = `/account`
    const { data } = yield call(apiClient.get, url)

    yield put(actions.setAccount(data))

    if (data.user) {
      ErrorLogger.setUser(data.user)
      PerformanceLogger.setUser(data.user)
    }
  } catch (error) {
    yield put(actions.setUserError(error))
    yield put(actions.setFetchStatus(FetchStatuses.failed))
  }
}

function* updateUserSaga({ payload }) {
  try {
    yield put(actions.setUserUpdating(true))
    const url = `/account`
    const { data } = yield call(apiClient.put, url, payload)
    yield put(actions.setUserUpdating(false))
    if (data.success) {
      yield put(actions.setAccount(data))
      yield put(actions.setUserError(null))
    } else {
      yield put(actions.setUserError(data.errorMessage))
    }
  } catch (error) {
    yield put(actions.setUserError(error))
    yield put(actions.setUserUpdating(false))
  }
}

function* deleteUserSaga() {
  try {
    const url = `/account`
    const { data: response } = yield call(apiClient.delete, url)
    if (response.success === true) {
      window.location.href = response.data.redirectUrl
    }
  } catch (error) {}
}

function* disconnectSocialSaga({ payload: provider }) {
  try {
    yield put(actions.setSocialUpdating({ provider, status: true }))
    const url = `/account/social-profile`
    const { data } = yield call(apiClient.delete, url, {
      params: { provider },
    })

    yield put(actions.setAccount(data))
    yield put(actions.setSocialUpdating({ provider, status: false }))
  } catch (error) {
    yield put(actions.setUserError(error))
  }
}

function* extendJwtSaga({ payload }) {
  try {
    const url = `/account/extend-jwt`
    const response = yield call(apiClient.post, url, null, {
      params: { token: payload.token },
    })

    const { success, errorMessage, ...jwtData } = response.data

    if (success) {
      yield put(actions.setUserJwt(jwtData))
      payload.resolve(jwtData.jwt)
    } else {
      ErrorLogger.log(errorMessage)
      payload.reject(errorMessage)
    }
  } catch (error) {
    ErrorLogger.log(error)
  }
}

function* toggleEmailNotificationSaga({ payload }) {
  try {
    const url = `/account/email-notification/${payload.name}`
    yield call(apiClient.put, url, { active: payload.value })
  } catch (error) {}
}

function* cancelUserSubscriptionSaga({ payload }) {
  try {
    yield put(actions.setCancellationStatus(FetchStatuses.loading))

    // Cancel user subscription via API
    const { data } = yield call(apiClient.delete, `/subscription`, {
      data: payload,
    })

    // Update the account data (billing info, etc)
    if (data.success) {
      yield put(actions.setAccount(data))
      yield put(actions.setCancellationStatus(FetchStatuses.loaded))
    } else {
      yield put(actions.setCancellationError(data.errorMessage))
      yield put(actions.setCancellationStatus(FetchStatuses.failed))
    }
  } catch (error) {
    ErrorLogger.log(error)

    const errorMessage = i18n.t('builder.subscription_cancellation.form.server_error')
    yield put(actions.setCancellationError(errorMessage))
    yield put(actions.setCancellationStatus(FetchStatuses.failed))
  }
}

function* pauseUserSubscriptionSaga({ payload = {} }) {
  const { redirectUrl, onLoaded } = payload
  try {
    yield put(actions.setPauseSubscriptionStatus(FetchStatuses.loading))
    const { data } = yield call(apiClient.post, `/subscription/pause`)
    if (data.success) {
      yield put(actions.setAccount(data))
      yield put(actions.setPauseSubscriptionStatus(FetchStatuses.loaded))
      onLoaded && onLoaded()
      navigate(redirectUrl || '/account')
      yield put(
        uiActions.setSnackBarOpen({
          status: true,
          type: SnackbarTypes.success,
          text: 'You are on a free plan now. We are happy to have you onboard!',
        }),
      )
    }
  } catch (error) {
    ErrorLogger.log(error)
    yield put(actions.setPauseSubscriptionStatus(FetchStatuses.loaded))
    onLoaded && onLoaded()
    navigate(redirectUrl || '/account')
    yield put(
      uiActions.setSnackBarOpen({
        status: true,
        type: SnackbarTypes.failure,
        text: 'Free plan could not be activated due to following error: ' + error.message,
      }),
    )
  } finally {
    yield delay(3000)
    yield put(uiActions.closeSnackBar())
  }
}

function* updateSmartBannerOrderRequestSaga({ payload }) {
  try {
    const url = `/update-smart-banner-order`
    yield call(apiClient.post, url, { smart_banner_order: payload })
  } catch (error) {
    ErrorLogger.log(error)
  }
}

/**
 * This saga is used for the new subscription cancellation (v2) page.
 * It calls the api to apply the 50% discount for the user. After that,
 * it dispatches an action to open snackbar - either success or failure,
 * depending on the api response. After that, it waits for 3 seconds and
 * finally dispatches an action to close the snackbar
 */
function* getDiscountSagaV2() {
  try {
    yield put(actions.setDiscountStatus(FetchStatuses.loading))

    const response = yield call(apiClient.post, `/subscription/cancellation_discount`)

    if (response.data.success === true && response.status === 200) {
      yield put(
        uiActions.setSnackBarOpen({
          status: true,
          type: SnackbarTypes.success,
          text: '50% discount applied. We are happy to have you onboard!',
        }),
      )
      yield put(actions.setDiscountStatus(FetchStatuses.loaded))
      navigate('/app')
    } else if (
      response.data.success === false &&
      response.status === 200 &&
      response.data.alreadyGotDiscount
    ) {
      yield put(actions.setDiscountStatus(FetchStatuses.loaded))
      yield put(actions.setDiscountError('Already Discounted'))
    } else {
      yield put(
        uiActions.setSnackBarOpen({
          status: true,
          type: SnackbarTypes.failure,
          text: response.data.errorMessage || 'An unknown error has occured!',
        }),
      )
      yield put(actions.setDiscountStatus(FetchStatuses.loaded))
    }
  } catch (error) {
    ErrorLogger.log(error)
    yield put(actions.setDiscountStatus(FetchStatuses.failed))
    yield put(
      uiActions.setSnackBarOpen({
        status: true,
        type: SnackbarTypes.failure,
        text:
          'Discount could not be applied due to following error: ' +
          (error instanceof Error ? error.message : String(error)),
      }),
    )
  } finally {
    yield delay(3000)
    yield put(uiActions.closeSnackBar())
  }
}

function* applyStayInTouchDiscountSaga() {
  try {
    yield put(actions.setDiscountStatus(FetchStatuses.loading))

    const response = yield call(apiClient.post, `/subscription/stay_in_touch_discount`)

    if (response.data.success === true && response.status === 200) {
      yield put(
        uiActions.setSnackBarOpen({
          status: true,
          type: SnackbarTypes.success,
          text: `Done! You've changed your subscription plan`,
        }),
      )
      yield put(actions.setDiscountStatus(FetchStatuses.loaded))
      navigate('/app')
    } else if (
      response.data.success === false &&
      response.status === 200 &&
      response.data.alreadyGotDiscount
    ) {
      yield put(actions.setDiscountStatus(FetchStatuses.loaded))
      yield put(actions.setDiscountError('Already Discounted'))
    } else {
      yield put(
        uiActions.setSnackBarOpen({
          status: true,
          type: SnackbarTypes.failure,
          text: response.data.errorMessage || 'An unknown error has occured!',
        }),
      )
      yield put(actions.setDiscountStatus(FetchStatuses.loaded))
    }
  } catch (error) {
    ErrorLogger.log(error)
    yield put(actions.setDiscountStatus(FetchStatuses.failed))
    yield put(
      uiActions.setSnackBarOpen({
        status: true,
        type: SnackbarTypes.failure,
        text:
          'Discount could not be applied due to following error: ' +
          (error instanceof Error ? error.message : String(error)),
      }),
    )
  } finally {
    yield delay(3000)
    yield put(uiActions.closeSnackBar())
  }
}

function* getAbandonedDiscountSaga() {
  try {
    yield put(actions.setDiscountStatus(FetchStatuses.loading))

    const response = yield call(apiClient.post, `/subscription/abandoned_discount`)

    if (response.data.success === true && response.status === 200) {
      yield put(
        uiActions.setSnackBarOpen({
          status: true,
          type: SnackbarTypes.success,
          text: '50% discount applied!',
        }),
      )
      yield put(actions.setDiscountStatus(FetchStatuses.loaded))
    } else if (
      response.data.success === false &&
      response.status === 200 &&
      response.data.alreadyGotDiscount
    ) {
      yield put(
        uiActions.setSnackBarOpen({
          status: true,
          type: SnackbarTypes.success,
          text: '50% discount applied!',
        }),
      )
      yield put(actions.setDiscountStatus(FetchStatuses.loaded))
    } else {
      yield put(
        uiActions.setSnackBarOpen({
          status: true,
          type: SnackbarTypes.failure,
          text: response.data.errorMessage || 'An unknown error has occured!',
        }),
      )
      yield put(actions.setDiscountStatus(FetchStatuses.loaded))
    }
  } catch (error) {
    ErrorLogger.log(error)
    yield put(actions.setDiscountStatus(FetchStatuses.failed))
    yield put(
      uiActions.setSnackBarOpen({
        status: true,
        type: SnackbarTypes.failure,
        text:
          'Discount could not be applied due to following error: ' +
          (error instanceof Error ? error.message : String(error)),
      }),
    )
  } finally {
    yield delay(3000)
    yield put(uiActions.closeSnackBar())
  }
}

/**
 * This saga is used for the new subscription cancellation (v2) page.
 * It calls the api to raise a support ticket when user submits his
 * feedback or any other reason for cancelling the subscription. After that,
 * it dispatches an action to open snackbar - either success or failure,
 * depending on the api response. After that, it waits for 3 seconds and
 * finally dispatches an action to close the snackbar
 */
function* submitFeedbackForCancellationSaga({ payload }) {
  try {
    yield put(actions.setSubmitFeedbackStatus(FetchStatuses.loading))

    const axiosInstance = axios.create({
      baseURL: '/',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    })

    const response = yield call(axiosInstance.post, `/support/ticket`, null, {
      params: {
        captcha_token_v3: '',
        topic: 'feedback',
        subject: payload.subject || 'cancellation flow',
        plan: payload.plan || 'premium',
        email: payload.email,
        retype: '',
        name: payload.name,
        message: payload.feedback,
      },
    })

    if (response.data.success && response.status === 200) {
      yield put(actions.setSubmitFeedbackStatus(FetchStatuses.loaded))
    } else {
      yield put(
        uiActions.setSnackBarOpen({
          status: true,
          type: SnackbarTypes.failure,
          text: response.data.errorMessage || 'An unknown error has occured!',
        }),
      )
      yield put(actions.setDiscountStatus(FetchStatuses.loaded))
    }
  } catch (error) {
    ErrorLogger.log(error)
    yield put(actions.setSubmitFeedbackStatus(FetchStatuses.failed))
    yield put(
      uiActions.setSnackBarOpen({
        status: true,
        type: SnackbarTypes.failure,
        text:
          'Feedback could not be submitted due to following error: ' +
          (error instanceof Error ? error.message : String(error)),
      }),
    )
  } finally {
    yield delay(3000)
    yield put(uiActions.closeSnackBar())
  }
}

function* loginToSuperAppSaga() {
  // skip saga if it already started
  const status = yield select(selectors.superAppLoginStatus)
  if (status === FetchStatuses.loading) return

  try {
    yield put(actions.setSuperAppLoginStatus(FetchStatuses.loading))
    const { data } = yield call(apiClient.post, '/career-identity/activate')

    window.location.href = data.loginLink
  } catch (error) {
    // Show snackbar with error message
    yield put(
      uiActions.setWarningSnackbarOpen({
        timeout: 2000,
        text: i18n.t('builder.dashboard.super_app_promo.activation_error'),
      }),
    )
    yield put(actions.setSuperAppLoginStatus(FetchStatuses.failed))
  }
}

export function* fetchFirstTimeLoginStatus() {
  try {
    const { data } = yield call(apiClient.get, `/show-overlay-to-user`)

    let isFirstTimeLogin = data?.isFirstLogin

    if (
      !isFirstTimeLogin &&
      data?.activatedBy &&
      (data?.activatedBy.includes('resume.io') || data?.activatedBy.includes('resume.test'))
    ) {
      isFirstTimeLogin = true
    }

    yield put(actions.setIsUserLoggingFirstTime(isFirstTimeLogin))
    localStorage.setItem('IS_CHECKED_FIRST_LOGIN', true)
  } catch (error) {
    ErrorLogger.log(error)
  }
}

export function* triggerJobTitleIdOnInterviewSaga({ payload }) {
  try {
    if (payload.user) {
      if (
        Number(localStorage.jobTitleId) !== Number(payload.user.currentJobTitleId) &&
        payload.user.currentJobTitleId !== null
      ) {
        yield put(interviewActions.setJobTitleId(payload.user.currentJobTitleId))
        yield put(interviewActions.setJobTitle(''))

        const { data } = yield call(
          baseClient.get,
          `career-data/v1/job-titles/${payload.user.currentJobTitleId}`,
        )
        yield put(interviewActions.setJobTitle(data.jobTitle))
      }
      localStorage.jobTitleId = +payload.user.currentJobTitleId
    }
  } catch (error) {
    ErrorLogger.log(error)
  }
}

// Export
export const sagas = function* saga() {
  yield all([
    takeEvery(actions.setAccount, triggerJobTitleIdOnInterviewSaga),
    takeEvery(actions.fetchUserRequest, fetchUserSaga),
    takeEvery(actions.updateUserRequest, updateUserSaga),
    takeEvery(actions.deleteUserRequest, deleteUserSaga),
    takeEvery(actions.cancelUserSubscription, cancelUserSubscriptionSaga),
    takeEvery(actions.pauseUserSubscription, pauseUserSubscriptionSaga),
    takeEvery(actions.disconnectSocialRequest, disconnectSocialSaga),
    takeEvery(actions.toggleEmailNotification, toggleEmailNotificationSaga),
    takeEvery(actions.extendJwtRequest, extendJwtSaga),
    takeEvery(actions.loginToSuperApp, loginToSuperAppSaga),
    takeEvery(actions.getDiscountNew, getDiscountSagaV2),
    takeEvery(actions.applyStayInTouchDiscount, applyStayInTouchDiscountSaga),
    takeEvery(actions.getAbandonedDiscount, getAbandonedDiscountSaga),
    takeEvery(actions.submitFeedbackForSubscriptionCancellation, submitFeedbackForCancellationSaga),

    takeEvery(actions.fetchFirstTimeLoginStatus, fetchFirstTimeLoginStatus),
    takeEvery(actions.updateSmartBannerOrderRequest, updateSmartBannerOrderRequestSaga),
  ])
}
