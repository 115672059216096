import { selectors } from 'builder/modules/careerPath2'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

import { Container, Heading, Skills, Skill } from './styles'

type Props = {
  hardSkills: string[]
}

const HardSkills = ({ hardSkills }: Props) => {
  const skills = useTypedSelector(selectors.skills)
  const isExploreCareers = useTypedSelector(selectors.isExploreCareers)

  const skillsLabels = skills.map(skill => skill.label)

  return (
    <Container>
      <Heading>Hard Skills</Heading>
      <Skills>
        {hardSkills.map((skill, index) => (
          <Skill
            key={index}
            isExploreCareers={isExploreCareers}
            isKnown={skillsLabels.includes(skill)}
          >
            {skill}
          </Skill>
        ))}
      </Skills>
    </Container>
  )
}

export default HardSkills
