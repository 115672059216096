import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Sizes from 'builder/styles/sizes'
import { Icon20, Icon24 } from 'builder/components/Icon'
import Typography, { FontWeights } from 'builder/styles/typography'
import Button from 'builder/components/Button'
import Media from 'builder/styles/media'
import { ScrollContainer } from 'builder/components/ScrollContainer'

export const ScrollWrapper = styled(ScrollContainer)<{ isEditActive: boolean }>(props => {
  return css`
    transition: all 200ms ease-in;
    height: ${props.isEditActive ? '100%' : 'fit-content'};

    ${Media.Phone`
      height: ${props.isEditActive ? '100%' : '0'};
    `}
  `
})

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Sizes.XS};
  padding: 0 ${Sizes.S};

  ${Media.Phone`
    padding-top: ${Sizes.S};
    padding-bottom: ${Sizes.S};
  `}
`

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${Sizes['2XS']};
  color: ${Colors.Neutral80};
  align-items: center;
  ${Typography.Body};
  ${FontWeights.Medium};
`

export const ArrowTargetIconWrapper = styled.div`
  display: flex;
  padding: 0px 2px;
  align-items: flex-start;
  border-radius: 4px;
  background: ${Colors.Indigo10};
  color: ${Colors.Indigo50};
`

export const ArrowTargetIcon = styled(Icon20.ArrowTarget2)`
  width: 20px;
  height: 20px;
`

export const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 6px;
  border-radius: ${Sizes['3XS']};
  background-color: ${Colors.Indigo10};
  color: ${Colors.Indigo60};
  width: fit-content;
  ${Typography.Tiny};
  ${FontWeights.Regular};
`

export const SectionWrapper = styled.div<{ isEditActive: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  margin-bottom: ${props => (props.isEditActive ? '16px' : '0')};
`

export const BeforeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: inherit;
  gap: 6px;
`

export const Title = styled.div`
  ${Typography.Caption};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
`

export const ContentBefore = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
`

export const AfterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: inherit;
  gap: 16px;
`

export const SkillsBeforeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`

export const SkillBeforeItem = styled.p`
  font-size: 19px;
  line-height: 22px;
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
`

export const SkillsAfterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`

export const SkillAfterItemButton = styled(Button)`
  font-size: 19px;
  line-height: 22px;
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
  border-radius: ${Sizes['4XS']};
  background: ${Colors.Neutral10};
  padding: 4px 8px;
  cursor: pointer;
  gap: 10px;

  &:hover {
    background: ${Colors.Blue10};
    color: ${Colors.Blue50};
  }
`

export const CloseIcon = styled(Icon20.Close)`
  width: 20px;
  height: 20px;
  color: inherit;
`

export const WorkExperienceContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`

export const WorkExperienceWrapper = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${Colors.Neutral15};
  border-radius: 4px;
  padding: 16px 20px;
  gap: ${props => (props.isOpen ? '16px' : '0')};
`

export const WorkExperienceHeader = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: ${props => (props.isOpen ? 'pointer' : 'unset')};
`
export const WorkExperienceTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const WorkExperienceTitle = styled.p`
  ${Typography.Caption};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral90};
`

export const WorkExperienceDate = styled.p`
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
`

export const EditIcon = styled(Icon24.EditAlt)`
  cursor: pointer;
  color: ${Colors.Blue50};
`

export const WorkExperienceContent = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: ${props => (props.isOpen ? 'auto' : '0')};
  overflow: ${props => (props.isOpen ? 'visible' : 'hidden')};
  opacity: ${props => (props.isOpen ? '1' : '0')};
  transition: height 200ms ease, opacity 200ms ease;
`

export const WorkExperienceTextAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Sizes['3XS']};
`

export const TextAreaDescription = styled.p`
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
`
