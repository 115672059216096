import * as Styles from './styles'

interface Props {
  title: string
  subTitle: string
  bannerImage: string
}

const WelcomeBanner = ({ title, subTitle, bannerImage }: Props) => {
  return (
    <Styles.WelcomeContainer>
      <Styles.BannerLeftContent>
        <Styles.WelcomeTitle>{title}</Styles.WelcomeTitle>
        <Styles.WelcomeText>{subTitle}</Styles.WelcomeText>
      </Styles.BannerLeftContent>

      <Styles.BannerRightContent>
        <Styles.WelcomeImgContainer alt="Welcome Banner" src={bannerImage} />
      </Styles.BannerRightContent>
    </Styles.WelcomeContainer>
  )
}

export default WelcomeBanner
