import { Group, ItemName, Label } from 'builder/components/UiKit/styles'
import { Shadows } from 'builder/styles/shadows'
import { Section } from '../../Section'
import { DarkBlock, LightBlock, BlocksGroup, Block } from './styles'

export const ShadowsSection = () => {
  return (
    <Section title="Shadows">
      {Object.entries(Shadows).map(([key, value]) => {
        return (
          <Group key={key}>
            <Label>{key}</Label>
            <BlocksGroup>
              {Object.entries(value).map(([variantKey, variantValue]) => (
                <Block key={key + variantValue}>
                  <LightBlock as={key === 'dark' ? DarkBlock : LightBlock} variant={variantValue} />
                  <ItemName>{variantKey}</ItemName>
                </Block>
              ))}
            </BlocksGroup>
          </Group>
        )
      })}
    </Section>
  )
}
