import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { useI18n } from 'builder/hooks/useI18n'
import { useClickOutside } from 'builder/hooks/useClickOutside'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

import { baseClient } from 'builder/modules/apiClient'
import { FetchStatuses } from 'builder/modules/constants'
import { ResumeDetails, actions, selectors } from 'builder/modules/resumeDistribution'

import Spinner from 'builder/components/Spinner'
import { ReplaceResumePopup } from 'builder/views/ResumeDistributionDashboardView/components/DashboardHeader/ReplaceResumePopup/ReplaceResumePopup'
import SelectResumeModal from '../../SelectResumeModal'
import ImageSrc from '../assets/invalid-document.png'
import {
  ContactContaier,
  ContactSupport,
  Container,
  Image,
  ImageContainer,
  ResumePopupContainer,
  SpinnerWrapper,
  TextContainer,
  TextHeader,
  TextSubHeader,
  UploadButton,
  UploadHeader,
  UploadSection,
} from './styles'

const TRANSLATION = 'builder.resume_distribution.landing.invalid_document'

export const InvalidDocument = () => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [showPopup, setShowPopup] = useState(false)
  const [showResumeSelection, setResumeSelection] = useState(false)
  const [isSubscriptionLoading, setIsSubscriptionLoading] = useState(false)
  const candidateResumePostStatus = useTypedSelector(selectors.candidateResumePostStatus)
  const candidateFetchStatus = useTypedSelector(selectors.candidateFetchStatus)
  const isSubscriptionActive = useTypedSelector(selectors.isSubscriptionActive)

  const containerRef = useRef<HTMLDivElement>(null)
  useClickOutside(containerRef, () => setShowPopup(false))

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedResume = event.target.files?.[0]
    if (selectedResume) {
      dispatch(actions.postCandidateResume(selectedResume))
    }
    setShowPopup(false)
  }

  const handleBuilderResumeChange = (resume: ResumeDetails) => {
    dispatch(actions.postCandidateResume(resume))
    setShowPopup(false)
  }

  const onResumeSubmit = useCallback(async () => {
    if (candidateResumePostStatus === FetchStatuses.loaded) {
      setIsSubscriptionLoading(true)
      if (!isSubscriptionActive) {
        await baseClient.post('resume-distribution/candidates/subscriptions', {})
      }
      setIsSubscriptionLoading(false)
      navigate('/resume-distribution/dashboard')
    }
  }, [candidateResumePostStatus, isSubscriptionActive, navigate])

  useEffect(() => {
    onResumeSubmit()
  }, [candidateResumePostStatus, onResumeSubmit])

  useEffect(() => {
    dispatch(actions.fetchCandidate())
  }, [dispatch])

  if (
    candidateResumePostStatus === FetchStatuses.loading ||
    candidateFetchStatus === FetchStatuses.loading ||
    isSubscriptionLoading
  ) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    )
  }

  return (
    <Container>
      <ImageContainer>
        <Image src={ImageSrc} />
      </ImageContainer>

      <TextContainer>
        <TextHeader>{i18n.t(`${TRANSLATION}.header`)}</TextHeader>
        <TextSubHeader>{i18n.t(`${TRANSLATION}.sub_header`)} </TextSubHeader>
      </TextContainer>

      <UploadSection $hasError={true}>
        <UploadHeader>{i18n.t(`${TRANSLATION}.resume_header`)}</UploadHeader>
        <UploadButton onClick={() => setShowPopup(true)}>
          {i18n.t(`${TRANSLATION}.button`)}
        </UploadButton>
      </UploadSection>
      <ContactContaier>
        {i18n.t(`${TRANSLATION}.question`)}{' '}
        <ContactSupport href="/contact" target="_blank">
          {i18n.t(`${TRANSLATION}.contact_support`)}
        </ContactSupport>
      </ContactContaier>

      {showPopup && (
        <ResumePopupContainer>
          <ReplaceResumePopup
            setResumeSelection={setResumeSelection}
            handleFileInputChange={handleFileInputChange}
            ref={containerRef}
          />
        </ResumePopupContainer>
      )}

      {showResumeSelection && (
        <SelectResumeModal
          onClose={() => setResumeSelection(false)}
          submitSelectedResume={handleBuilderResumeChange}
        />
      )}
    </Container>
  )
}
