import { useState, useCallback, useMemo } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { formatNumber } from '../../../../utils'

import { Container } from './styles'
import SalaryGraph from './components/SalaryGraph'
import SalaryFigure from './components/SalaryFigure'

interface Props {
  label: string
  jobTitle?: string
  averageSalary: number
  minimumSalary: number
  maximumSalary: number
}

const Salary = ({ label, jobTitle, averageSalary, minimumSalary, maximumSalary }: Props) => {
  const [duration, setDuration] = useState<'Year' | 'Month'>('Year')

  const formattedAvgSalary = useMemo(
    () => (duration === 'Year' ? averageSalary : Math.round(averageSalary / 12)),
    [duration, averageSalary],
  )

  const avgSalary = useMemo(
    () =>
      duration === 'Year'
        ? formatNumber(averageSalary)
        : formatNumber(Math.round(averageSalary / 12)),
    [duration, averageSalary],
  )

  const minSalary = useMemo(
    () =>
      duration === 'Year'
        ? formatNumber(minimumSalary)
        : formatNumber(Math.round(minimumSalary / 12)),
    [duration, minimumSalary],
  )

  const maxSalary = useMemo(
    () =>
      duration === 'Year'
        ? formatNumber(maximumSalary)
        : formatNumber(Math.round(maximumSalary / 12)),
    [duration, maximumSalary],
  )

  const handleChange = useCallback(() => {
    duration === 'Year' ? setDuration('Month') : setDuration('Year')

    trackInternalEvent('click_salary_year', { label, job_title: jobTitle })
  }, [label, jobTitle, duration])

  return (
    <Container>
      <SalaryFigure duration={duration} avgSalary={formattedAvgSalary} onChange={handleChange} />
      <SalaryGraph {...{ avgSalary, minSalary, maxSalary }} />
    </Container>
  )
}

export default Salary
