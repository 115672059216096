import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Icon24 from 'builder/components/Icon'
import Button from 'builder/components/Button'
import Sizes from 'builder/styles/sizes'

export const Container = styled.div`
  position: sticky;
  top: 0;
  margin: var(--size-l);
`

export const AutoAppliedLink = styled.div`
  margin-top: 16px;
  color: ${Colors.Neutral50};
  ${Typography.Caption};
  & > a {
    color: ${Colors.Blue50};
  }
`

export const InterviewButton = styled(Button)`
  gap: 5px;
`

export const InterviewButtonIcon = styled(Icon24.Screen)`
  width: 24px;
  flex-shrink: 0;
`
export const SaveButton = styled(Button)<{ isDisabled: boolean }>`
  ${props =>
    props.isDisabled &&
    css`
      background: ${Colors.Neutral20};
      color: ${Colors.Neutral50};
    `}
  img {
    ${props =>
      props.isDisabled &&
      css`
        filter: saturate(0);
        opacity: 0.9;
      `}
  }
`

export const CheckIcon = styled.img`
  padding-right: 10px;
`

export const AppliedButton = styled(Button)`
  cursor: not-allowed;
`

export const AutoAppliedButton = styled(Button)`
  cursor: not-allowed;
  background: ${Colors.Neutral10};
  display: flex;
  align-items: center;
  color: ${Colors.Neutral90};

  &:hover,
  &:focus-visible {
    background-color: inherit;
  }
  &:active {
    background-color: inherit;
  }
`
export const AutoAppliedIcon = styled.img`
  margin-right: 4px;
  width: 18px;
`

export const SaveJobButtonText = styled.span<{
  $isAutoApplyEnabled: boolean
  $mobileVertical?: boolean
}>`
  margin-left: 12px;

  ${({ $isAutoApplyEnabled, $mobileVertical }) =>
    $isAutoApplyEnabled &&
    !$mobileVertical &&
    Media.Phone`
      display: none;
    `}
`

export const ActionButtonsContainer = styled.div<{
  $mobileVertical?: boolean
  $flexBasis?: string
}>(({ $mobileVertical, $flexBasis = '0' }) => {
  return css`
    display: flex;
    top: 143px;
    gap: 8px;
    padding: ${Sizes.M} 0;
    background: ${Colors.White};
    z-index: 1;

    ${Media.AboveTablet`
      position: sticky;
      top: 0;
    `}

    & > * {
      flex: 1 1 ${$flexBasis} !important;
    }

    & ${Button} {
      flex: 1;
    }

    ${Media.Phone`
      ${$mobileVertical && 'flex-direction: column;'}
    `}
  `
})

export const BackButton = styled.div`
  ${Typography.Caption};
  ${FontWeights.DemiBold};
  position: sticky;
  top: 0;
  left: 0;
  display: inline-flex;
  padding: 4px 16px 4px 8px;
  align-items: center;
  border-radius: 20px;
  background: ${Colors.Neutral10};
  align-items: center;
  color: ${Colors.Neutral50};
  z-index: 3;
  cursor: pointer;

  ${Media.Hover`
    &:hover {
      background: ${Colors.Blue10};
      color: ${Colors.Blue50}
    }
  `};

  & path {
    fill: currentColor;
  }
  ${Media.AboveTablet`
    display: none;
  `};

  ${Media.Phone`
    position: unset;
    align-self: start;
    margin-bottom: 12px
  `};
`

export const ActionButtonBottomWrapper = styled.div`
  position: sticky;
  bottom: 0;
`

export const AutoApplyInfoContainer = styled.div`
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  margin-bottom: ${Sizes.S};
`

export const AutoApplyInfoText = styled.p`
  ${Typography.Caption};
  ${FontWeights.Regular}
  color: ${Colors.Neutral80};
`

export const AutoApplyInfoLink = styled.span`
  ${Typography.Caption};
  ${FontWeights.Regular}
  color: ${Colors.Blue50};
  cursor: pointer;
`
