import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Button, { ButtonTheme } from 'builder/components/Button'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  ${Media.Phone`
    flex-direction: column-reverse;
    align-items: center;
    gap: 16px;
    width: 100%;
  `}
`

export const StepsCounter = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  bottom: 0;
  background-color: ${Colors.White};
`

export const Step = styled.div<{ active: boolean }>`
  width: ${props => (props.active ? '16px' : '6px')};
  height: 6px;
  border-radius: 8px;
  background: ${props => (props.active ? Colors.Blue50 : Colors.Neutral20)};
`

export const Actions = styled.div`
  display: flex;
  gap: 12px;
  ${Media.Phone`
    width: 100%;
  `}
`

export const BackButton = styled(Button).attrs({ theme: ButtonTheme.ghost })`
  ${Media.Phone`
    width: 100%;
  `}
`

export const ContinueButton = styled(Button)`
  ${Media.Phone`
    width: 100%;
  `}
`
