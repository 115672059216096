import { sortBy } from 'lodash'
import { useMemo, useState } from 'react'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors, TOP_PAYING_TABS } from 'builder/modules/careerPath2'

import Bar from '../Bar'
import Loader from '../Loader'
import CareerPageTabs from '../CareerPageTabs'
import { formatNumber, getWidth } from '../../utils'
import { Container, Title, Bars } from './styles'

const TopPayingRegions = () => {
  const [activeTab, setActiveTab] = useState(TOP_PAYING_TABS[0])

  const location = useTypedSelector(selectors.location)
  const exploreCareersLocation = useTypedSelector(selectors.exploreCareersLocation)

  const isSalaryFailed = useTypedSelector(selectors.isSalaryFailed)
  const isSalaryLoading = useTypedSelector(selectors.isSalaryLoading)
  const isExploreCareers = useTypedSelector(selectors.isExploreCareers)
  const { topCitiesSalary, topStatesSalary } = useTypedSelector(selectors.salaryDetails)

  const region = useMemo(
    () => (isExploreCareers ? exploreCareersLocation : location),
    [isExploreCareers, location, exploreCareersLocation],
  )

  const sortedTopCitiesBySalary = useMemo(
    () => sortBy(topCitiesSalary, ['salary']).reverse(),
    [topCitiesSalary],
  )

  const sortedTopStatesBySalary = useMemo(
    () => sortBy(topStatesSalary, ['salary']).reverse(),
    [topStatesSalary],
  )

  const topRegions = useMemo(() => {
    const sortedRegions =
      activeTab === TOP_PAYING_TABS[0] ? sortedTopCitiesBySalary : sortedTopStatesBySalary

    return sortedRegions.map((item, index) => {
      return {
        ...item,
        width: getWidth(sortedRegions[index].salary, sortedRegions[0].salary),
      }
    })
  }, [activeTab, sortedTopCitiesBySalary, sortedTopStatesBySalary])

  if (isSalaryFailed) {
    return null
  }

  return (
    <Container data-testid="top-paying-regions-section">
      <Title>Top Paying State</Title>
      {isSalaryLoading ? (
        <Loader />
      ) : (
        <>
          <CareerPageTabs
            tabs={TOP_PAYING_TABS}
            activeTab={activeTab}
            onTabSelect={(tab: string) => setActiveTab(tab)}
          />
          <Bars>
            {topRegions.map(({ name, salary, width }, index) => (
              <Bar
                key={index}
                width={width}
                isMatching={region === name}
                value={formatNumber(salary)}
                text={`${index + 1}. ${name}`}
              />
            ))}
          </Bars>
        </>
      )}
    </Container>
  )
}

export default TopPayingRegions
