import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import ModalOverlay from 'builder/components/ModalOverlay'
import { Icon24 } from 'builder/components/Icon'
import { useUser } from 'builder/hooks/useUser'
import { actions, selectors } from 'builder/modules/user'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { FetchStatuses } from 'builder/modules/constants'
import { useI18n } from 'builder/hooks/useI18n'
import { InternalEvents } from 'builder/components/SubscriptionCancellationFormNew/common/types'
import {
  Container,
  Close,
  PauseLogo,
  Content,
  Title,
  Description,
  ButtonContainer,
  GreatButton,
  CancelButton,
} from './styles'
import PAUSE_LOGO from './assets/pause_logo.png'

interface PauseModalProps {
  onContinueCancellation: () => void
  onClose: () => void
}

export const PauseModal = ({ onContinueCancellation, onClose }: PauseModalProps) => {
  const { isPhone } = useMediaQueries()
  const user = useUser()
  const dispatch = useDispatch()
  const [isPauseButtonLoading, setIsPauseButtonLoading] = useState(false)
  const pauseSubscriptionStatus = useTypedSelector(selectors.pauseSubscriptionStatus)
  const { i18n } = useI18n()

  useEffect(() => {
    trackInternalEvent(InternalEvents.viewPauseModal, {
      period_type: user?.availableFreePeriod || 'month',
    })
  }, [user?.availableFreePeriod])

  useEffect(() => {
    setIsPauseButtonLoading(pauseSubscriptionStatus === FetchStatuses.loading)
  }, [pauseSubscriptionStatus])

  const handlePauseSubscription = () => {
    trackInternalEvent(InternalEvents.abort, { reason: 'subscription paused' })
    dispatch(actions.pauseUserSubscription({ onLoaded: onClose, redirectUrl: '/' }))
  }

  return (
    <ModalOverlay
      fullScreen={isPhone}
      overlayFadeDuration={350}
      contentSlideDuration={350}
      backgroundOpacity={0.8}
      onClick={onClose}
    >
      <Container>
        <PauseLogo src={PAUSE_LOGO} />
        <Content>
          <Title>{i18n.t('builder.subscription_cancellation_v4.pause_modal.title')}</Title>
          <Description>
            {i18n.t('builder.subscription_cancellation_v4.pause_modal.description_line_one')}{' '}
            <span className="important">
              {i18n.t('builder.subscription_cancellation_v4.pause_modal.description_line_two', {
                period: i18n.t(`builder.intervals.${user?.availableFreePeriod || 'month'}`),
              })}
            </span>{' '}
            {i18n.t('builder.subscription_cancellation_v4.pause_modal.description_line_three')}
          </Description>
          <ButtonContainer>
            <CancelButton onClick={onContinueCancellation}>
              {i18n.t('builder.subscription_cancellation_v4.pause_modal.cancel_button')}
            </CancelButton>
            <GreatButton
              onClick={handlePauseSubscription}
              isLoading={isPauseButtonLoading}
              isDisabled={isPauseButtonLoading}
            >
              {i18n.t('builder.subscription_cancellation_v4.pause_modal.great_button')}{' '}
              {i18n.t(`builder.intervals.${user?.availableFreePeriod || 'month'}`)[0].toUpperCase()}
              {i18n.t(`builder.intervals.${user?.availableFreePeriod || 'month'}`).slice(1)}
            </GreatButton>
          </ButtonContainer>
        </Content>
        <Close onClick={onClose}>
          <Icon24.Close />
        </Close>
      </Container>
    </ModalOverlay>
  )
}
