import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

export const Container = styled.div<{ onHover: boolean }>`
  width: 304px;
  height: 172px;
  border-radius: var(--2XS, 8px);
  background: ${props => (props.onHover ? '#E2F1E8' : Colors.Green10)};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;

  ${Media.Phone`
    width: 100%;
  `}
`

export const VisualImageContainer = styled.div`
  position: relative;
  width: 128px;
  height: 134px;
  border-radius: 50%;

  ${Media.Phone`
    width: 130px;
    height: 130px;
  `}
`

export const PlayImage = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1200000000000;
`

export const VisualImage = styled.img`
  width: 100%;
  border: 2.5px solid ${Colors.White};
  height: 100%;
  border-radius: 50%;
`
