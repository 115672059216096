import { ForwardedRef, useEffect, useRef } from 'react'

/**
 * For cases when you want use forwarded ref as simple ref inside component
 */
export function useForwardedRef<T>(ref: ForwardedRef<T>) {
  const innerRef = useRef<T>(null)

  useEffect(() => {
    if (!ref) return
    if (typeof ref === 'function') {
      ref(innerRef.current)
    } else {
      ref.current = innerRef.current
    }
  })

  return innerRef
}
