import styled, { css, keyframes } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import { rotation } from 'builder/styles/keyframes'

export const ToolbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 12px 0 12px;
`

export const ToolbarButton = styled.div<{ isActive: boolean; isDisabled?: boolean }>`
  width: 24px;
  height: 24px;
  background-color: ${Colors.Neutral10};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.Neutral70};

  &:hover {
    color: ${Colors.Blue60};
  }

  ${({ isActive }) =>
    isActive &&
    `
    color: ${Colors.Blue60};
  `};

  ${({ isDisabled }) =>
    isDisabled &&
    `
    color: ${Colors.Neutral40};
    pointer-events: none;
  `};
`

export const ToolbarLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 12px;
`

export const SuggestionTogglerContainer = styled.div<{
  isAiAction: boolean
  isActive: boolean
}>(({ isAiAction, isActive }) => {
  return css`
    display: flex;
    align-items: center;
    ${isAiAction &&
    css`
      background-color: ${isActive ? Colors.Indigo20 : Colors.White};
      border-radius: 100px;
      &:hover {
        background-color: ${isActive ? Colors.Indigo20 : Colors.Indigo40};
      }
    `}
  `
})

export const ToolbarDiv = styled.div`
  height: 20px;
  width: 1px;
  margin: 0 10px;
  background-color: ${Colors.Neutral30};
`

export const LinkElement = styled.a`
  color: ${Colors.Blue50};
`

export const ActionText = styled.span``

export const NewBadgeActionText = styled.span`
  color: ${Colors.Indigo50};
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.3px;
`

export const ActionIcon = styled.div<{
  isActive?: boolean
  isLoading?: boolean
  isAiAction: boolean
}>(({ isLoading, isActive, isAiAction }) => {
  return css`
    margin-left: 8px;
    color: ${Colors.White};
    background-color: ${Colors.Blue50};
    border-radius: 50%;
    transition: transform 0.2s ease, color 0.2s ease, background-color 0.2s ease;
    & svg {
      display: block;
    }
    ${isLoading &&
    css`
      background-color: ${Colors.Neutral50};
      animation: ${rotation} 0.5s infinite linear;
    `}
    ${isActive &&
    css`
      transform: rotate(45deg);
      background-color: ${Colors.Neutral20};
      color: ${Colors.Neutral50};
    `}
    ${isAiAction &&
    css`
      margin-left: 4px;
      background: inherit;
      color: ${Colors.Indigo50};
    `}
  `
})

export const StarIcon = styled.div`
  color: ${Colors.Indigo50};
  padding-right: 4px;
`

export const Action = styled.div<{
  isActive: boolean
  isDisabled?: boolean
  isAiAction: boolean
}>(({ isDisabled, isActive, isAiAction }) => {
  return css`
    display: flex;
    align-items: center;
    ${Typography.Caption};
    color: ${Colors.Neutral50};
    cursor: pointer;
    user-select: none;
    pointer-events: ${isDisabled ? 'none' : 'auto'};

    &:hover {
      ${ActionText} {
        color: ${Colors.Blue50};
      }
    }

    ${isActive &&
    css`
      ${ActionIcon} {
        transform: rotate(45deg);
        background-color: ${Colors.Neutral20};
        color: ${Colors.Neutral50};
      }
    `}

    ${isAiAction &&
    css`
      padding: 4px 4px 4px 8px;
    `}

    ${isAiAction &&
    !isActive &&
    css`
      &:hover {
        ${ActionIcon}, ${NewBadgeActionText}, ${StarIcon} {
          color: ${Colors.Indigo10};
        }
      }
    `}
      ${isActive &&
    isAiAction &&
    css`
      ${ActionIcon} {
        background-color: ${Colors.Indigo50};
        color: ${Colors.Indigo10};
      }
      ${NewBadgeActionText} {
        color: ${Colors.Indigo50};
      }
    `}
  `
})

export const SpellCheckIconWrapper = styled.div`
  position: relative;
`

export const SpellCheckPendingDots = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: 3px;
  bottom: 3px;
`

const pending = keyframes`
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`

export const SpellCheckPendingDot = styled.div`
  width: 3px;
  height: 3px;
  margin-right: 2px;
  background-color: ${Colors.Blue50};
  border-radius: 50%;
  opacity: 0;
  animation: ${pending} 1.5s infinite linear;

  &:last-child {
    margin: 0;
  }
`

export const SpellCheckPendingFirstDot = styled(SpellCheckPendingDot)``

export const SpellCheckPendingSecondDot = styled(SpellCheckPendingDot)`
  animation-delay: 0.2s;
`

export const SpellCheckPendingThirdDot = styled(SpellCheckPendingDot)`
  animation-delay: 0.4s;
`
