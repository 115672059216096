import styled, { css, keyframes } from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Button from 'builder/components/Button'

const { Neutral5, Neutral10, Neutral15, Neutral50, Neutral90, Neutral100, Blue10, Blue50, White } =
  Colors

const hideScroll = css`
  -ms-overflow-style: none; // Internet Explorer 10+
  scrollbar-width: none; // Firefox
  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
`

const revealAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(-100%);
    display: none;
    height: 0;
    margin-bottom: 0;
  }
  to {
    opacity: 1;
    transform: translateY(0);
    height: 44px;
    margin-bottom: 20px;
  }
`

const hideAnimation = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
    height: 44px;
    margin-bottom: 20px;
  }
  to {
    opacity: 0;
    transform: translateY(-100%);
    display: none;
    height: 0;
    margin-bottom: 0;
  }
`

export const Div = styled.div``
export const Span = styled.span``

/* ---------- Styles for UseExample comp ---------- */
export const Container = styled.div<{ isResumePreview?: boolean }>`
  display: flex;
  flex-direction: column;
  background: ${props => (props.isResumePreview ? Neutral10 : White)};
  height: 700px;
  padding: 34px 32px 0px 32px;
  border-radius: 16px;
  position: relative;
  ${Media.Phone`
    height: 100%;
    width: 100%;
    padding: 20px 16px 0px 16px;
    border-radius: 0px;
    position: static;
  `}
`

export const ActionBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 32px;
  right: 32px;
  ${Media.Phone`
    position: static;
    margin-bottom: 10px;
  `}
`

export const BackButton = styled.button<{ isResumePreview?: boolean }>`
  display: inline-flex;
  align-items: center;
  ${Typography.Caption}
  ${FontWeights.DemiBold}
  border: none;
  border-radius: 20px;
  padding: 4px 16px 4px 8px;
  cursor: pointer;
  color: ${props => (props.isResumePreview ? Blue50 : Neutral50)};
  background: ${props => (props.isResumePreview ? White : Neutral10)};
  &:hover {
    color: ${Blue50};
    background: ${Blue10};
  }
`

export const ChevronLeft = styled.span`
  transform: rotate(180deg);
`

export const CloseButton = styled.div<{ isResumePreview?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.15s;
  border-radius: 100%;
  width: 28px;
  height: 28px;
  cursor: pointer;
  color: ${Neutral50};
  background: ${props => (props.isResumePreview ? White : Neutral10)};
  &:hover {
    color: ${Blue50};
    background: ${Blue10};
  }
  ${Media.Phone`
    width: 24px;
    height: 24px;
  `};
`

/* ---------- Styles for ResumeExamples comp ---------- */
export const ExamplesContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 24px;
  gap: 16px;
  overflow-y: auto;
  height: 100%;
  &::-webkit-scrollbar {
    display: none;
  }

  ${Media.Phone`
    gap: 12px 9px;
  `}
`

export const ViewIcon = styled.img`
  position: absolute;
  height: 46px;
  width: 46px;
  left: 87px;
  top: 149px;
  visibility: hidden;
  ${Media.Phone`
    display: none;
  `}
`

export const ResumeCard = styled.button`
  position: relative;
  border: none;
  cursor: pointer;
  background: ${Neutral5};
  border-radius: 6px;
  padding: 12px 16px 16px 16px;
  ${Media.Phone`
    padding: 12px 8px 8px 8px;
  `}
  &:hover {
    background: ${Neutral10};
    ${ViewIcon} {
      visibility: visible;
    }
  }
`

export const ResumeTitle = styled.div`
  color: ${Neutral100};
  text-align: left;
  width: 188px;
  ${Typography.Body};
  display: flex;
  align-items: center;
  height: 44px;
  ${Media.Phone`
    width: 151px;
    ${Typography.Body};
  `}
`

export const ResumeImg = styled.img`
  margin-top: 8px;
  width: 188px;
  height: 264px;
  ${Media.Phone`
    width: 151px;
    height: 212px;
  `}
`

/* ---------- Styles for ViewAllExamples comp ---------- */
export const Heading = styled.div<{ hide?: boolean }>`
  color: ${Neutral90};
  text-align: center;
  ${FontWeights.DemiBold};
  ${Typography.M};
  margin-top: 18px;
  margin-bottom: 32px;

  ${props => Media.Phone`
    ${Typography.S};
    margin-top: 0px;
    animation: ${!props.hide ? revealAnimation : hideAnimation} 200ms forwards;
  `};
`

export const CategoriesAndExamples = styled.div`
  overflow: auto;
  max-width: 712px;
  margin-right: -20px;

  ${Media.Phone`
    overflow: unset;
    max-width: unset;
    margin-right: unset;
    ${hideScroll};
    height: 100%;
  `}
`

/* ---------- Styles for JobTitle comp ---------- */
export const JobTitleSearchContainer = styled.div`
  position: relative;
  display: flex;
  gap: 12px;
  align-items: center;
  background: ${Neutral5};
  border-radius: 6px;
  padding: 12px;
  margin-bottom: 10px;
  color: ${Blue50};
  ${Media.Phone`
    padding: 10px 12px;
  `}
`

export const JobTitleSearch = styled.input`
  padding: 0px;
  background: transparent;
  width: 100%;
  border: none;
  color: ${Neutral90};
  ${Typography.Body};
`

export const SearchResultsContainer = styled.div`
  position: absolute;
  top: 54px; //parent height + 6px top space between parent and child
  left: 0;
  right: 0;
  background: red;
  border-radius: 6px;
  background: ${White};
  box-shadow: 0px 8px 20px -4px rgba(15, 56, 113, 0.12), 0px 2px 4px -1px rgba(15, 56, 113, 0.08),
    0px 0px 1px 0px rgba(15, 56, 113, 0.32);
  z-index: 10;
`

export const SuggestedTitlesContainer = styled(SearchResultsContainer)`
  padding: 12px 4px 12px 16px; // for scrollbar
`

export const SuggestedTitleList = styled.ul`
  list-style: none;
  padding: 0;
  padding-right: 18px;
  margin: 0;
  max-height: 200px;
  overflow: auto;
`

export const SuggestedTitleItem = styled.li`
  border-bottom: 1px solid ${Neutral15};
  padding-top: 12px;
  padding-bottom: 12px;
  cursor: pointer;
  ${Typography.Body};
  color: ${Neutral100};
  &:hover {
    color: ${Blue50};
  }
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
`

export const NoResultsContainer = styled(SearchResultsContainer)``

export const NoResults = styled.div`
  display: flex;
  margin: 24px;
  gap: 8px;
  ${Media.Phone`
    margin: 20px 24px;
  `}
`

export const NoResultsImg = styled.img``

export const NoResultsText = styled.p`
  padding: 0px;
  margin: 0px;
  color: ${Neutral50};
  max-width: 397px;
  ${Typography.Caption};
`

export const AnotherOptionBtn = styled(Button)`
  ${Typography.Caption};
  border: none;
  padding: 0;
  color: ${Blue50};
  box-shadow: none !important;
  ${FontWeights.Regular}
`

/* ---------- Styles for Categories comp ---------- */
export const CategoryContainer = styled.div<{
  expanded?: boolean
  expandedHeight: number
  hide?: boolean
}>`
  background: ${White};
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  margin-bottom: 24px;
  gap: 8px 4px;
  padding-right: 20px;
  height: ${props => (props.expanded ? `${props.expandedHeight}px` : '37px')};
  overflow-x: ${props => (props.expanded ? 'auto' : 'hidden')};
  transition: height 0.4s ease-out;
  z-index: 2;
  top: 0;

  ${props => Media.Phone`
    padding-right: unset;
    max-width: unset;
    height: ${props.hide ? '0px' : 'auto'};;
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
    padding-bottom: 24px;
    margin-bottom: 0px;
    position: ${props.hide ? 'static' : 'sticky'};
    ${hideScroll};
    animation: ${!props.hide ? revealAnimation : hideAnimation} 200ms forwards;
  `};
`

export const CategoryPill = styled.button<{ selected?: boolean }>`
  border-radius: 36px;
  padding: 8px 16px;
  color: ${Neutral90};
  white-space: nowrap;
  border: ${props => (props.selected ? `0.8px solid ${Blue50}` : 'none')};
  background: ${props => (props.selected ? Blue10 : Neutral5)};
  ${Typography.Caption};
  ${Media.Phone`
    padding: 6px 12px;
  `}
  &:hover {
    cursor: pointer;
    background: ${Blue10};
  }
`

export const CategoryToggle = styled(Button)<{ hidden: boolean }>`
  ${FontWeights.Regular};
  background: ${White};
  color: ${Blue50};
  padding: 0px;
  display: flex;
  align-items: center;
  margin-left: auto;
  visibility: ${props => (props.hidden ? 'hidden' : 'visible')};
  &:hover {
    background: ${White};
    color: ${Blue50};
  }
  ${Media.Phone`
    display: none;
  `}
`

export const Chevron = styled.span<{ expanded?: boolean }>`
  transform: ${props => (props.expanded ? 'rotate(-90deg)' : 'rotate(90deg)')};
`

/* ---------- Styles for SelectedResumeExample comp ---------- */
export const PreviewInfo = styled.div`
  width: 592px;
  height: 100%;
  align-self: center;
  text-align: center;
  margin: 0px 50px;
  display: flex;
  flex-direction: column;
  min-height: 0;
  ${Media.Phone`
    width: auto;
    margin: 0px;
  `}
`

export const Title = styled.div`
  margin-bottom: 8px;
  color: ${Neutral90};
  ${FontWeights.DemiBold};
  ${Typography.Subhead};
  ${Media.Phone`
    ${Typography.S};
  `}
`

export const SubTitle = styled.div`
  color: ${Neutral50};
  ${Typography.Caption};
  margin-bottom: 20px;
`

export const PreviewImgContainer = styled.div`
  overflow: auto;
  ${hideScroll};
  margin-bottom: 80px;
  width: inherit;
  height: inherit;
  ${Media.Phone`
    margin-bottom: 75px;
  `}
`
export const PreviewImg = styled.img`
  width: 100%;
`

export const UseThisResumeContainer = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: ${White};
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 22px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  ${Media.Phone`
    position: fixed;
    height: 75px;
    justify-content: space-between;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  `}
`

export const UseThisResumeBtn = styled(Button)`
  padding: 12px 24px;
  ${FontWeights.Medium};
  ${Typography.Body};
  ${Media.Phone`
    margin-left: 0px;
  `}
`

export const RatingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  ${Media.AbovePhone`
    position: absolute;
    right: 22px;
  `}
`

export const RatingCount = styled.div`
  ${Typography.Tiny};
  color: ${Neutral50};
  max-width: 104px;
  text-align: end;
`
export const RatingScore = styled.div`
  border-radius: 100%;
  width: 34px;
  height: 34px;
  color: ${White};
  background: #222384;
  display: flex;
  justify-content: center;
  align-items: center;
  ${FontWeights.DemiBold};
  ${Typography.Caption};
  ${Media.Phone`
    ${Typography.Body};
  `}
`
