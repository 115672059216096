import { useI18n } from 'builder/hooks/useI18n'
import StarSvg from '../../StarSvg'
import * as Styled from './styles'

interface IAiTagProps {
  isMouseOver: boolean
  colorScheme: Record<string, string>
  isCoverLetter?: boolean
}

export const AiTag = ({ isMouseOver, colorScheme, isCoverLetter }: IAiTagProps) => {
  const { i18n } = useI18n()
  return (
    <>
      {!isCoverLetter ? (
        <Styled.AiTagContainer isMouseOver={isMouseOver} bgColor={colorScheme.tone20}>
          <StarSvg fillColor={colorScheme.tone80} />
          <Styled.AiTag color={colorScheme.tone80} isMouseOver={isMouseOver}>
            {i18n.t('builder.dashboard.service_data.tags.ai_powered')}
          </Styled.AiTag>
        </Styled.AiTagContainer>
      ) : null}
    </>
  )
}
