import styled from 'styled-components'
import Icon from 'builder/components/Icon'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Button from 'builder/components/Button'

export const Container = styled.div`
  padding: 32px;

  ${Media.Phone`
    padding: 24px 20px;
  `};
`

export const Title = styled.div`
  ${Typography.S};
  font-weight: 600;
  margin-bottom: 8px;
`

export const Description = styled.div`
  margin-bottom: 16px;
`

export const Panel = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-top: 32px;
  margin-bottom: -12px;

  ${Media.Phone`
    margin-top: 12px;
  `};
`

export const ShareButtons = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 12px 0;
  margin-right: 8px;
`

const networkColors = {
  facebook: '#3B5998',
  linkedin: '#0077B5',
  twitter: '#1DA1F2',
}

export const ShareButton = styled.div`
  display: inline-block;
  padding: 10px;
  margin-right: 8px;
  border: 1px solid ${Colors.Neutral20};
  border-radius: 50%;
  transition: border-color 0.2s, background-color 0.2s;
  cursor: pointer;

  &:hover {
    border-color: ${props => networkColors[props.network]};
    background-color: ${props => networkColors[props.network]};
  }

  & svg {
    display: block;
    width: 20px;
    height: 20px;
  }

  & path {
    transition: fill 0.2s;
  }

  &:hover path {
    fill: ${Colors.White};
  }
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 0;
`

export const CopyButton = styled(Button)`
  position: relative;
  margin-left: 24px;

  &:first-child {
    margin-left: 0;
  }
`

export const CopyButtonText = styled.span`
  opacity: ${props => (props.isCopied ? 0 : 1)};
  transition: opacity 0.2s;
`

export const CopyButtonNote = styled.span`
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  opacity: ${props => (props.isCopied ? 1 : 0)};
  transition: opacity 0.2s;
`

export const OpenLink = styled.a`
  ${Typography.ButtonM};
  color: ${Colors.Blue50};
  transition: color 0.2s;
  font-weight: 600;

  &:hover {
    color: ${Colors.Blue70};
  }
`

export const Footer = styled.div`
  margin-top: 32px;
  padding-top: 12px;
  border-top: 1px solid ${Colors.Neutral15};
`

export const FooterLink = styled.a`
  display: flex;
  align-items: center;
  ${Typography.Caption};
  color: ${Colors.Neutral50};
  transition: color 0.2s;

  &:hover {
    color: ${Colors.Blue70};
  }
`

export const FooterLinkIcon = styled(Icon.HintOutlined)`
  width: 20px;
  height: 20px;
  color: ${Colors.Blue50};
  margin-right: 8px;
`

export const FooterLinkText = styled.span``
