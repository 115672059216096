import { useEffect, useRef, useState } from 'react'

import { trackInternalEvent } from '@rio/tracking'
import {
  CloseFullScreenIcon,
  CloseIcon,
  Controls,
  ExitFullscreenButton,
  FullscreenButton,
  FullScreenIcon,
  MuteIcon,
  PauseIcon,
  PlayButton,
  PlayIcon,
  SoundButton,
  SoundIcon,
  Video,
  VideoPlayerContainer,
} from './styles'

const videoURL = window.location.href.includes('resume')
  ? 'https://videos.ctfassets.net/14q5t4r3zqb4/41bNlYIEQFcBarz3TN7fJr/1d40ce4f5e9bede828f5cb2aa1886fff/resume.io_auto_apply_start.mp4'
  : 'https://videos.ctfassets.net/14q5t4r3zqb4/2j7LXpE1haJefpzbLvLYtv/ba651f1d7023622840c6b47f12fec5cf/career.io_about_us.mp4'

export const VideoPlayer = () => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const playerRef = useRef<HTMLDivElement>(null)
  const [isHover, setIsHover] = useState(false)
  const [isMuted, setIsMuted] = useState(true)
  const [isPlaying, setIsPlaying] = useState(true)
  const [isFullScreen, setIsFullScreen] = useState(false)

  const togglePlayPause = () => {
    if (videoRef.current?.paused) {
      videoRef.current.play()
      setIsPlaying(true)
    } else {
      videoRef.current?.pause()
      setIsPlaying(false)
    }
  }

  const toggleMuteUnMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted
      setIsMuted(videoRef.current.muted)
      trackInternalEvent('click_video_sound_button', {
        label: 'total_job_search_solution',
      })
    }
  }

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      if (playerRef.current?.requestFullscreen) {
        playerRef.current?.requestFullscreen()
      }
      setIsFullScreen(true)
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      }
      setIsFullScreen(false)
    }
    trackInternalEvent('click_video_expand_button', {
      label: 'total_job_search_solution',
    })
  }

  const handleFullScreenChange = () => {
    setIsFullScreen(!!document.fullscreenElement)
  }

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullScreenChange)
    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange)
    }
  }, [])

  return (
    <VideoPlayerContainer
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onPointerEnter={() => setIsHover(true)}
      onBlur={() => setIsHover(false)}
      ref={playerRef}
      className={isFullScreen ? 'fullscreen' : ''}
    >
      <Video
        playsInline
        ref={videoRef}
        muted={isMuted}
        autoPlay={isPlaying}
        className={isFullScreen ? 'fullscreen' : ''}
        onEnded={() => setIsPlaying(false)}
      >
        <source src={videoURL} type="video/mp4" />
      </Video>

      <Controls>
        {isHover && (
          <PlayButton onClick={togglePlayPause} className={isFullScreen ? 'fullscreen' : ''}>
            <PauseIcon />
          </PlayButton>
        )}
        {!isPlaying && (
          <PlayButton onClick={togglePlayPause} className={isFullScreen ? 'fullscreen' : ''}>
            <PlayIcon />
          </PlayButton>
        )}
        <SoundButton onClick={toggleMuteUnMute} className={isFullScreen ? 'fullscreen' : ''}>
          {isMuted ? <MuteIcon /> : <SoundIcon />}
        </SoundButton>
        <FullscreenButton className={isFullScreen ? 'fullscreen' : ''} onClick={toggleFullScreen}>
          {isFullScreen ? <CloseFullScreenIcon /> : <FullScreenIcon />}
        </FullscreenButton>
        {isFullScreen && (
          <ExitFullscreenButton onClick={toggleFullScreen}>
            <CloseIcon />
          </ExitFullscreenButton>
        )}
      </Controls>
    </VideoPlayerContainer>
  )
}
