import { createAction, createReducer } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer/dist/internal'
import { FetchStatuses } from 'builder/modules/constants'
import { Store } from 'builder/modules/store'
import { IEventsStore, IFetchEventData, IFetchEventsQueryParams } from './types'

export const actions = {
  fetchMyUpcomingEvents: createAction<IFetchEventsQueryParams>('builder/FETCH_MY_UPCOMING_EVENTS'),
  fetchMyEndedEvents: createAction<IFetchEventsQueryParams>('builder/FETCH_MY_ENDED_EVENTS'),
  fetchAllEvents: createAction<IFetchEventsQueryParams>('builder/FETCH_ALL_EVENTS'),

  setMyUpcomingEvents: createAction<IFetchEventData>('builder/SET_MY_UPCOMING_EVENTS'),
  setMyEndedEvents: createAction<IFetchEventData>('builder/SET_MY_ENDED_EVENTS'),
  setAllEvents: createAction<IFetchEventData>('builder/SET_ALL_EVENTS'),
  setError: createAction('builder/SET_ERROR'),
  setStatus: createAction('builder/SET_STATUS'),
  setHelperAnimation: createAction<boolean>('builder/SET_HELPER_ANIMATION'),
}

export const selectors = {
  myEndedEvents: (state: Store) => state.events.myEndedEvents,
  myUpcomingEvents: (state: Store) => state.events.myUpcomingEvents,
  allEvents: (state: Store) => state.events.allEvents,
  helperAnimation: (state: Store) => state.events.helperAnimation,
}

export const initialState: IEventsStore = {
  myUpcomingEvents: {
    data: { events: [], page: 0, lastPage: 0 },
    error: '',
    status: FetchStatuses.notAsked,
  },
  myEndedEvents: {
    data: { events: [], page: 0, lastPage: 0 },
    error: '',
    status: FetchStatuses.notAsked,
  },
  allEvents: {
    data: { events: [], page: 0, lastPage: 0 },
    error: '',
    status: FetchStatuses.notAsked,
  },
  helperAnimation: false,
}

export const reducer = createReducer(initialState, reducer => {
  const setEvents = (
    type: 'myUpcomingEvents' | 'myEndedEvents' | 'allEvents',
    action: {
      payload: IFetchEventData
      type: string
    },
    draft: WritableDraft<IEventsStore>,
  ) => {
    const { data, error, status } = action.payload
    draft[type] = {
      error: error || '',
      status: status || FetchStatuses.loaded,
      data: { events: data.events || [], page: data.page, lastPage: data.lastPage },
    }
  }
  reducer.addCase(actions.setAllEvents, (draft, action) => {
    setEvents('allEvents', action, draft)
  })
  reducer.addCase(actions.setMyEndedEvents, (draft, action) => {
    setEvents('myEndedEvents', action, draft)
  })
  reducer.addCase(actions.setMyUpcomingEvents, (draft, action) => {
    setEvents('myUpcomingEvents', action, draft)
  })
  reducer.addCase(actions.setHelperAnimation, (draft, action) => {
    draft.helperAnimation = action.payload
  })
})
