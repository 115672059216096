import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Sizes from 'builder/styles/sizes'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

export const Title = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};
`
export const Subtitle = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium}
`
export const Content = styled.div`
  margin: ${Sizes.L} 0;
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${Media.Phone`
    margin: ${Sizes.M} 0;
  `}
`

export const Field = styled.div`
  height: 72px;
  ${Media.Phone`
    height: 66px;
  `}
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const DeleteJobAlertContainer = styled.div`
  color: ${Colors.Red50};
  gap: ${Sizes['3XS']};
  cursor: pointer;
  &:hover {
    color: ${Colors.Red60};
  }
`
export const WrapperButtons = styled.div`
  display: flex;
  gap: ${Sizes['2XS']};
`
export const RemoteContainer = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral90};
  display: flex;
`

export const ErrorContainer = styled.span`
  ${Typography.Tiny};
  ${FontWeights.Regular};
  color: ${Colors.Red50};
`
