/** Function to calculate percentage -
 * it accepts two numeric values
 */
export const calculatePercentage = (numerator: number, denominator: number) =>
  (numerator / denominator) * 100

/** Function to calculate vw equivalent of px -
 * it accepts a size in px
 */
export function pxToVw(sizeInPx: number): string {
  const percentage = calculatePercentage(sizeInPx, window.innerWidth)
  return `${percentage}vw`
}

/** Function to calculate vw equivalent of px -
 * it accepts a size in px
 */
export function pxToVh(sizeInPx: number): string {
  const percentage = calculatePercentage(sizeInPx, window.innerHeight)
  return `${percentage}vh`
}

/** Function to calculate line height ratio -
 * it accepts line height in px and font size in px
 */
export const getLineHeightRatio = (lineHeightInPx: number, fontSizeInPx: number) =>
  lineHeightInPx / fontSizeInPx
