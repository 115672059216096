import { trackInternalEvent } from '@rio/tracking'
import { memo, useCallback, useState } from 'react'
import { CoachService } from 'builder/modules/careerCoaching'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import CoachDetailsView from 'builder/views/CoachDetailsView'
import { trackingInfo } from 'builder/modules/careerCoaching/constants'
import {
  Card,
  TopContent,
  LeftContent,
  RightContent,
  Description,
  Divider,
  BottomContent,
} from './styles'
import AboutCoach from './components/AboutCoach/AboutCoach'
import CoachServices from './components/CoachServices/CoachServices'
import CoachJobFunctions from './components/CoachJobFunctions/CoachJobFunctions'
import CoachIndustries from './components/CoachIndustries/CoachIndustries'

interface Props {
  coach: {
    id: number
    fullName: string
    location: string
    aboutMe: string
    coachAvatar: string
    industries: Array<string>
    services: Array<CoachService>
    jobFunctions: Array<string>
    careerStages: string[]
  }
}

const Coach = ({ coach }: Props): JSX.Element => {
  const {
    id,
    fullName: name,
    location,
    aboutMe,
    coachAvatar: avatar,
    industries: coachIndustries,
    services: coachServices,
    jobFunctions,
    careerStages,
  } = coach

  const { isPhone } = useMediaQueries()
  const [coachDetailsOpen, setCoachDetailsOpen] = useState<boolean>(false)

  const handleViewProfile = useCallback((id, name) => {
    setCoachDetailsOpen(true)
    localStorage.setItem('coachId', id)

    trackInternalEvent('click_coach_card', { ...trackingInfo, coach_id: id, coach_name: name })
  }, [])

  const handleModalClose = useCallback(() => {
    setCoachDetailsOpen(false)
  }, [])

  return (
    <>
      {coachDetailsOpen && <CoachDetailsView {...{ handleModalClose }} />}
      <Card
        data-testid="coach-card"
        onClick={useCallback(() => handleViewProfile(id, name), [handleViewProfile, id, name])}
      >
        <LeftContent>
          <TopContent>
            <AboutCoach {...{ name, location, avatar, careerStages }} />
          </TopContent>
          <BottomContent>
            <CoachServices {...{ coachServices }} />
            {isPhone && <Divider />}
            <Description>{aboutMe}</Description>
          </BottomContent>
        </LeftContent>
        <RightContent>
          <CoachJobFunctions {...{ jobFunctions }} />
          <CoachIndustries {...{ coachIndustries }} />
        </RightContent>
      </Card>
    </>
  )
}

export default memo(Coach)
