import { useCallback, useEffect, useState } from 'react'

export const useCountdown = (targetDate: string) => {
  const countDownDate = new Date(targetDate).getTime()

  const [countDown, setCountDown] = useState(countDownDate - new Date().getTime())

  const getLeftOverValues = useCallback((countDown: number) => {
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24))
    const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000)

    return [
      { key: 'days', value: days },
      { key: 'hours', value: hours },
      { key: 'minutes', value: minutes },
      { key: 'seconds', value: seconds },
    ]
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      if (countDownDate - new Date().getTime() > 0)
        setCountDown(countDownDate - new Date().getTime())
      else {
        clearInterval(interval)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [countDownDate])

  if (new Date().getTime() - countDownDate >= 0)
    return [
      { key: 'days', value: 0 },
      { key: 'hours', value: 0 },
      { key: 'minutes', value: 0 },
      { key: 'seconds', value: 0 },
    ]

  return getLeftOverValues(countDown)
}
