import styled from 'styled-components'
import Media from 'builder/styles/media'
import Icon24 from 'builder/components/Icon'
import Button from 'builder/components/Button'
import { Colors } from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div<{ feature?: string }>`
  width: ${props => (props.feature === 'lloyd' ? '256px' : '192px')};
  height: fit-content;
  padding: ${props => (props.feature === 'lloyd' ? '24px' : '20px')};
  border-radius: 16px;
  border: 1px solid ${Colors.Neutral15};
  gap: ${props => (props.feature === 'lloyd' ? '16px' : '12px')};
  display: flex;
  flex-direction: column;

  ${Media.Phone`
    width: 100%;
  `}
`

export const Content = styled.div<{ feature?: string }>`
  gap: ${props => (props.feature === 'lloyd' ? '16px' : '12px')};
  display: flex;
  flex-direction: column;
`

export const InnerContent = styled(Content)`
  margin-bottom: 24px;
`

export const Label = styled.div`
  ${Typography.Caption};
  ${FontWeights.Medium};
  width: fit-content;
  padding: 2px 8px;
  border-radius: 6px;
  color: ${Colors.Neutral50};
  background-color: ${Colors.Neutral10};
`

export const Img = styled.img`
  ${Media.Phone`
    height: 80px;
    align-self: flex-start;
  `}

  ${Media.Tablet`
    align-self: flex-start;
  `}

  ${Media.Laptop`
    align-self: flex-start;
  `}
`

export const Text = styled.div`
  ${FontWeights.Medium};

  ${Media.Phone`
    ${Typography.Subhead};
  `}
`

export const SubText = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
`

export const StyledButton = styled(Button)`
  width: fit-content;
  height: fit-content;

  ${Media.Phone`
    width: 100%;
  `}

  ${Media.Tablet`
    align-self: center;
  `}
`

export const TabletContainer = styled.div`
  width: 100%;
  padding: 24px 32px;
  gap: 24px;
  display: flex;
  justify-content: space-between;
  border: 1px solid ${Colors.Neutral15};
  border-radius: 16px;

  ${Media.Phone`
    flex-direction: column;
  `}
`

export const TabletContent = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`

export const TabletBox = styled.div`
  display: flex;
  flex-direction: column;
`

export const TextContainer = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: column;
`

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const StyledIcon = styled(Icon24.Chevron)<{ $isOpen: boolean }>`
  color: ${Colors.Neutral30};
  transform: ${props => (props.$isOpen ? 'rotate(-90deg)' : 'rotate(90deg)')};

  &:hover {
    color: ${Colors.Blue50};
  }
`

export const CollapsedContent = styled.div`
  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & ${Img} {
    height: 36px;
  }
`
