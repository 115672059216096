import times from 'lodash/times'
import {
  Group,
  GroupTitle,
  PlaceholderItem,
  PlaceholderItemIcons,
  ItemText,
  PlaceholderText,
} from './styles'

const Placeholder = () => {
  return (
    <Group>
      <GroupTitle>
        <PlaceholderText />
      </GroupTitle>
      {times(5, index => (
        <PlaceholderItem key={index}>
          <PlaceholderItemIcons />
          <ItemText>
            <PlaceholderText />
            <PlaceholderText />
          </ItemText>
        </PlaceholderItem>
      ))}
    </Group>
  )
}

export default Placeholder
