import { FC, memo } from 'react'
import { useDispatch } from 'react-redux'
import Button, { ButtonSize, ButtonTheme } from 'builder/components/Button'
import { useI18n } from 'builder/hooks/useI18n'
import { Icon20 } from 'builder/components/Icon'
import { SnackbarTypes, actions as uiActions } from 'builder/modules/ui'
import * as Styled from './styles'

export const AssessmentResultSample: FC<{
  title: string
  description: string
  snackbarOnCopyText: string
  textParts: string[]
  onCopyClick?: () => void
}> = memo(({ title, description, textParts, snackbarOnCopyText, children, onCopyClick }) => {
  const dispatch = useDispatch()
  const { i18n } = useI18n()
  const text = textParts.join('\n\n')

  const onCopyText = () => {
    if (onCopyClick) {
      onCopyClick()
    }

    dispatch(
      uiActions.setSnackBarOpen({
        status: true,
        type: SnackbarTypes.simple,
        text: snackbarOnCopyText,
      }),
    )

    setTimeout(() => {
      dispatch(
        uiActions.setSnackBarOpen({
          status: false,
          type: SnackbarTypes.simple,
        }),
      )
    }, 2000)

    if (!navigator.clipboard) {
      console.warn(
        `[AssessmentResultSample]
        Your text is not copied because of local environment.
        Everything will be ok in prod`,
      )
      return
    }

    navigator.clipboard.writeText(text)
  }

  return (
    <Styled.Container timeout={0}>
      <Styled.Header>
        <Styled.Title>{title}</Styled.Title>
        <Styled.Description>{description}</Styled.Description>
      </Styled.Header>

      <Styled.Content>
        <Styled.Controls>
          <Button size={ButtonSize.small} theme={ButtonTheme.secondary} onClick={onCopyText}>
            <Icon20.Copy />
            &nbsp;
            {i18n.t('builder.career_profile.text_sample.copy_text')}
          </Button>
          {children}
        </Styled.Controls>
        <Styled.Text>{text}</Styled.Text>
      </Styled.Content>
    </Styled.Container>
  )
})
