import { selectors } from 'builder/modules/careerPath2'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { Colors } from 'builder/styles/colors'
import { Container } from './styles'

type Props = {
  level: number
  number: number
}

const getHeight = (number: number, isPhone: boolean, isTablet: boolean, isLaptop: boolean) => {
  switch (number) {
    case 0:
      return '8'
    case 1: {
      if (isPhone) return '125'
      if (isTablet) return '129'
      if (isLaptop) return '125'

      return '75'
    }
    case 2:
      if (isPhone) return '226'
      if (isTablet) return '236'
      if (isLaptop) return '236'

      return '142'
    case 3:
      if (isPhone) return '326'
      if (isTablet) return '340'
      if (isLaptop) return '340'

      return '210'
    case 4:
      if (isPhone) return '426'
      if (isTablet) return '444'
      if (isLaptop) return '445'

      return '280'
    case 5:
      if (isPhone) return '526'
      if (isTablet) return '548'
      if (isLaptop) return '545'

      return '348'
    case 6:
      if (isPhone) return '626'
      if (isTablet) return '652'
      if (isLaptop) return '655'

      return '416'
    case 7:
      if (isPhone) return '726'
      if (isTablet) return '756'
      if (isLaptop) return '758'

      return '482'
    case 8:
      if (isPhone) return '826'
      if (isTablet) return '860'
      if (isLaptop) return '862'

      return '550'
  }
}

const getPath = (number: number, isPhone: boolean, isTablet: boolean, isLaptop: boolean) => {
  switch (number) {
    case 0:
      if (isPhone) return 'M0 2H48'
      return 'M0 2H56'
    case 1: {
      if (isPhone) return 'M0 4C48 4 0 100 48 100'
      if (isTablet) return 'M0 4C56 4 0 115 56 115'
      if (isLaptop) return 'M0 4C56 4 0 120 56 120'

      return 'M0 4C56 4 0 71 56 71'
    }
    case 2:
      if (isPhone) return 'M0 4C48 4 0 182 48 182'
      if (isTablet) return 'M0 4C56 4 0 210 56 210'
      if (isLaptop) return 'M0 4C56 4 0 220 56 220'

      return 'M0 4C56 4 0 138 56 138'
    case 3:
      if (isPhone) return 'M0 4C48 4 0 268 48 268'
      if (isTablet) return 'M0 4C56 4 0 304 56 304'
      if (isLaptop) return 'M0 4C56 4 0 320 56 320'

      return 'M0 4C56 4 0 206 56 206'
    case 4:
      if (isPhone) return 'M0 4C48 4 0 352 48 352'
      if (isTablet) return 'M0 4C56 4 0 396 56 396'
      if (isLaptop) return 'M0 4C56 4 0 420 56 420'

      return 'M0 4C56 4 0 276 56 276'
    case 5:
      if (isPhone) return 'M0 4C48 4 0 436 48 436'
      if (isTablet) return 'M0 4C56 4 0 490 56 490'
      if (isLaptop) return 'M0 4C56 4 0 520 56 520'

      return 'M0 4C56 4 0 344 56 344'
    case 6:
      if (isPhone) return 'M0 4C48 4 0 520 48 520'
      if (isTablet) return 'M0 4C56 4 0 584 56 584'
      if (isLaptop) return 'M0 4C56 4 0 620 56 620'

      return 'M0 4C56 4 0 412 56 412'
    case 7:
      if (isPhone) return 'M0 4C48 4 0 604 48 604'
      if (isTablet) return 'M0 4C56 4 0 680 56 680'
      if (isLaptop) return 'M0 4C56 4 0 720 56 720'

      return 'M0 4C56 4 0 478 56 478'
    case 8:
      if (isPhone) return 'M0 4C48 4 0 688 48 688'
      if (isTablet) return 'M0 4C56 4 0 774 56 774'
      if (isLaptop) return 'M0 4C56 4 0 820 56 820'

      return 'M0 4C56 4 0 546 56 546'
  }
}

export const Connection = ({ level, number }: Props) => {
  const { isPhone, isTablet, isLaptop } = useMediaQueries()
  const isSwiperVisible = useTypedSelector(selectors.isSwiperVisible)

  return (
    <Container
      fill="none"
      level={level}
      width={isPhone ? '48' : '56'}
      isSwiperVisible={isSwiperVisible}
      xmlns="http://www.w3.org/2000/svg"
      height={getHeight(number, isPhone, isTablet, isLaptop)}
      viewBox={`0 0 ${isPhone ? '48' : '56'} ${getHeight(number, isPhone, isTablet, isLaptop)}`}
    >
      <path
        fill="none"
        strokeWidth="8"
        stroke={Colors.Blue20}
        d={getPath(number, isPhone, isTablet, isLaptop)}
      />
    </Container>
  )
}
