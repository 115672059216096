import { useState, useMemo, useCallback, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import throttle from 'lodash/throttle'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { Store } from 'builder/modules/store'
import { A4_FACTOR } from 'builder/modules/constants'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { PreviewRefProps } from '../components/DocumentPreview/types'

const PREVIEW_WIDTH = 400
const PREVIEW_WIDTH_MOBILE = 335

export const useDocumentPreview = () => {
  const previewRef = useRef<PreviewRefProps>(null)
  const previewBoxRef = useRef<HTMLDivElement | null>(null)
  const contentRef = useRef<HTMLDivElement | null>(null)
  const resume = useSelector((state: Store) => state.resumeEditor.resume)
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1)
  const [totalPageNumber, setTotalPageNumber] = useState<number>(0)
  const hasPreviousPage = useMemo(() => currentPageNumber !== 1, [currentPageNumber])
  const { isPhone } = useMediaQueries()
  const [dimensions, setDimensions] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  })

  const hasNextPage = useMemo(
    () => currentPageNumber < totalPageNumber,
    [currentPageNumber, totalPageNumber],
  )

  const handlePreviousPageClick = useCallback(() => {
    if (hasPreviousPage) {
      previewRef.current?.previousPage()
      setCurrentPageNumber(currentPageNumber - 1)
    }
  }, [currentPageNumber, hasPreviousPage, previewRef])

  const handleNextPageClick = useCallback(() => {
    if (hasNextPage) {
      previewRef.current?.nextPage()
      setCurrentPageNumber(currentPageNumber + 1)
    }
  }, [currentPageNumber, hasNextPage, previewRef])

  const handlePaginationStateChange = useCallback(
    (state: { currentPage: number; totalPagesCount: number }) => {
      setCurrentPageNumber(state.currentPage + 1)
      setTotalPageNumber(state.totalPagesCount)
    },
    [],
  )

  const handlePreviewResize = useCallback(() => {
    if (!previewBoxRef.current) return

    const previewWidth = previewBoxRef.current?.offsetWidth
    const width = Math.max(previewWidth, isPhone ? PREVIEW_WIDTH_MOBILE : PREVIEW_WIDTH)
    const height = width / A4_FACTOR
    setDimensions({ width, height })

    if (previewRef.current) {
      previewRef.current?.resize(width)
    }
  }, [isPhone, previewBoxRef, previewRef])

  useEffect(() => {
    const contentElement = contentRef.current
    const handleResizeThrottled = throttle(handlePreviewResize)

    window.addEventListener('resize', handleResizeThrottled)
    handleResizeThrottled()

    if (contentElement) disableBodyScroll(contentElement)
    return () => {
      if (contentElement) enableBodyScroll(contentElement)
      window.removeEventListener('resize', handleResizeThrottled)
    }
  }, [contentRef, handlePreviewResize])

  return {
    handlePreviousPageClick,
    handleNextPageClick,
    handlePaginationStateChange,
    hasPreviousPage,
    hasNextPage,
    totalPageNumber,
    currentPageNumber,
    resume,
    dimensions,
    previewRef,
    previewBoxRef,
    contentRef,
  }
}
