import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { hexToRgba, Colors } from 'builder/styles/colors'
import { getDatasetOptions } from '../../utils'
import { Skeleton } from './Skeleton'
import { getDemandChartOptions, normalizeChartData } from './utils'
import { DemandChartProps } from './types'
import * as Styles from './styles'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Filler)

export const DemandChart = ({ demand, data, isSkeleton = false }: DemandChartProps) => {
  const media = useMediaQueries()

  if (isSkeleton) return <Skeleton />

  const hasData = data.some(item => item.y)

  if (!data?.length || !hasData) return <Styles.EmptyContainer />

  const chartData = {
    datasets: [
      {
        ...getDatasetOptions(media),
        data: normalizeChartData(data),
        tension: 0.3,
        pointRadius: 3,
        pointBackgroundColor: hexToRgba(Colors.Blue50),
        borderWidth: 2,
      },
    ],
  }

  const maxY = data.reduce((accum, item) => {
    return Math.max(accum, item.y)
  }, 0)

  const options = getDemandChartOptions(media, maxY)

  return (
    <Styles.Wrapper>
      {demand && <Styles.Title>{demand}</Styles.Title>}
      <Styles.Chart>
        <Line options={options} data={chartData} height="198px" />
      </Styles.Chart>
    </Styles.Wrapper>
  )
}
