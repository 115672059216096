import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { FormEvent, ChangeEvent, useState } from 'react'
import { actions, selectors } from 'builder/modules/careerPath2'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

import { formatEnteredValue } from '../../utils'
import { Container } from './styles'
import Form from './components/Form'
import Greet from './components/Greet'
import Questionnare from './components/Questionnare'

interface Props {
  averageSalary: number
}

let unFormattedValue: string

const Feedback = ({ averageSalary }: Props) => {
  const dispatch = useDispatch()
  const location = useTypedSelector(selectors.location)
  const selectedJobTitle = useTypedSelector(selectors.selectedJobTitle)
  const isExploreCareers = useTypedSelector(selectors.isExploreCareers)
  const exploreCareersLocation = useTypedSelector(selectors.exploreCareersLocation)
  const exploreCareersOccupation = useTypedSelector(selectors.exploreCareersOccupation)

  const jobLocation = isExploreCareers ? exploreCareersLocation : location
  const jobTitle = (isExploreCareers ? exploreCareersOccupation : selectedJobTitle) || ''

  const [value, setValue] = useState('')
  const [accuracy, setAccuracy] = useState('')
  const [isSubmitted, setSubmitted] = useState(false)
  const [isGreetVisible, setGreetVisible] = useState(false)

  const isFormVisible = accuracy !== '' && accuracy !== 'Right'

  const dispatchFeedback = (feedback: number) => {
    setGreetVisible(true)

    const data = {
      title: jobTitle || '',
      salary: averageSalary,
      feedback: feedback,
    }

    dispatch(actions.saveSalaryFeedback(data))
    setTimeout(() => {
      setGreetVisible(false)
      setAccuracy('')
    }, 3000)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target

    // allow only digits
    value = value.replace(/[^\d]/g, '')
    unFormattedValue = value.replace('$', '')

    // format value
    const formattedValue = formatEnteredValue(value)
    setValue(formattedValue)
  }

  const sendTrackingEvent = (value: string) => {
    trackInternalEvent('click_salary_feedback', {
      location,
      feedback: accuracy,
      job_title: jobTitle,
      user_input: value,
      label: isExploreCareers ? 'explore_careers' : 'career_path2',
    })
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>, value: string) => {
    e.preventDefault()

    if (value) {
      setSubmitted(true)
      dispatchFeedback(+unFormattedValue)

      sendTrackingEvent(value)
    }
  }

  const handleClick = (value: string) => {
    if (value === 'Right') {
      dispatchFeedback(averageSalary)
      sendTrackingEvent(value)
    }

    setAccuracy(value)
  }

  return (
    <Container isExpanded={isGreetVisible || isFormVisible}>
      <Questionnare
        accuracy={accuracy}
        onFeed={handleClick}
        isFormVisible={isFormVisible}
        averageSalary={averageSalary}
      />
      {isFormVisible && (
        <Form
          value={value}
          jobTitle={jobTitle}
          location={jobLocation}
          onSubmit={handleSubmit}
          onChange={handleChange}
          isSubmitted={isSubmitted}
        />
      )}
      <Greet isGreetVisible={isGreetVisible} />
    </Container>
  )
}

export default Feedback
