import styled, { css, createGlobalStyle } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import 'draft-js/dist/Draft.css'
import { gradient } from 'builder/styles/keyframes'

export const RichTextAreaGlobalStyle = createGlobalStyle`
  .public-DraftEditor-content,
  .public-DraftEditorPlaceholder-root {
    margin: 0;
    padding: 8px 16px 16px;
  }

  .public-DraftEditorPlaceholder-root {
    position: absolute;
    top: 0;
    left: 0;
    color: ${Colors.Neutral50};
    pointer-events: none;
  }

  .public-DraftEditor-content {
    min-height: 200px;
    /* max-height: 420px; */
    overflow: auto;
    cursor: text;
  }

  .public-DraftStyleDefault-ol,
  .public-DraftStyleDefault-ul {
    padding: 4px 0;
    margin: 0;
  }

  .public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
    margin-left: 24px;
  }

  .public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR {
    margin-left: 48px;
  }

  .public-DraftStyleDefault-depth1.public-DraftStyleDefault-unorderedListItem {
    list-style-type: circle;
  }
`

export const Group = styled.div`
  display: block;
  width: 100%;
  position: relative;
`

export const Label = styled.label`
  display: block;
  ${Typography.Caption};
  color: ${Colors.Neutral50};
  margin-bottom: 6px;
`

export const TextArea = styled.div<{ height: number }>`
  position: relative;
  border-radius: 3px;
  resize: none;
  display: block;
  caret-color: ${Colors.Blue50};
  background-color: ${Colors.Neutral10};
  outline: none;
  border: 0;

  &:focus {
    background-color: ${Colors.Neutral20};
  }

  ${({ height }) =>
    css`
      .public-DraftEditor-content {
        min-height: ${height}px;
      }
    `};
`

export const Bar = styled.div<{ in: string; scale: number; bgColor: string }>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: ${props => props.bgColor || Colors.Blue50};
  border-radius: 0 0 2px 2px;
  pointer-events: none;
  transform-origin: 0 0;
  transition: transform 0.2s ease, background-color 0.2s ease, opacity 0.2s ease;

  ${props =>
    props.in &&
    {
      entering: css`
        opacity: 1;
        transform: scaleX(${props.scale});
      `,

      entered: css`
        opacity: 1;
        transform: scaleX(${props.scale});
      `,

      exiting: css`
        opacity: 0;
        transform: scaleX(${props.scale});
      `,

      exited: css`
        opacity: 0;
        transform: scaleX(0);
      `,
    }[props.in]};

  ${props =>
    props.scale === 1 &&
    css`
      transform-origin: 50% 0;
    `}
`

export const LinkElement = styled.a`
  color: ${Colors.Blue50};
`

export const EditorWrapper = styled.div<{ isAnimated: boolean }>(({ isAnimated }) => {
  return {
    position: 'relative',
    overflow: 'hidden',
    ...(isAnimated ? { opacity: 0 } : {}),
  }
})

export const LoaderPlaceholder = styled.div<{ height: number }>`
  height: ${props => `${props.height + 36}px`};
  border-radius: 3px;
  background: ${Colors.Neutral10};
  background: linear-gradient(
    to right,
    ${Colors.Neutral10} 5%,
    #eaeef6 15%,
    ${Colors.Neutral10} 25%
  );
  background-size: 100vw 100vh;
  animation: ${gradient} 1s linear infinite;
`

export const Progress = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  color: ${Colors.Neutral50};
  ${Typography.Caption};
`

export const ProgressLabel = styled.div``

export const ProgressInfo = styled.div<{ isIdle?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  padding-right: ${props => (props.isIdle ? 0 : 32)}px;
  transition: padding-right 0.3s ease;
`

export const ProgressFace = styled.div<{ isVisible: boolean }>`
  position: absolute;
  right: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #f9d1e0;
  margin-left: 8px;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transform: scale(${props => (props.isVisible ? 1 : 0.6)});
  transition: transform 0.3s ease, opacity 0.3s ease;
`

export const ProgressCounter = styled.div`
  color: ${Colors.Neutral50};
  display: flex;
  align-items: baseline;
`

export const ProgressCount = styled.div`
  color: ${Colors.Neutral90};
`

export const AnimationWrapper = styled.div`
  position: absolute;
  background-color: rgba(255, 255, 255, 0);
  width: 100%;
  height: 100%;
`

export const AIGeneratedDetails = styled.textarea`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  color: ${Colors.Indigo60};
  padding: 12px 16px;
`

export const RelativeDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
