import { useMemo } from 'react'
import { formatDocumentPreviewUrl } from 'builder/utils/formatDocumentPreviewUrl'
import { useResume } from 'builder/hooks/useResume'
import { useI18n } from 'builder/hooks/useI18n'
import { DataContainer } from '../../../styles'
import { ProgressInner } from '../Result/styles'
import { HeaderIcon, HeaderText, Header, ResumePreview, Progress } from './styles'

interface Props {
  resumeId: number
}

export const NotEnoughDataView = (props: Props) => {
  const { resumeId } = props
  const { i18n } = useI18n()
  const title = i18n.t('builder.resume_optimizer.job_posting.missed_data.preview_title')

  const resume = useResume(resumeId || null)
  const previewImageSrc = useMemo(() => {
    if (!resume) return null

    return formatDocumentPreviewUrl(resume, { size: 352 })
  }, [resume])

  return (
    <DataContainer>
      <Header>
        <HeaderIcon />
        <HeaderText>{title}</HeaderText>
      </Header>
      <Progress>
        <ProgressInner />
      </Progress>
      <ResumePreview src={previewImageSrc} />
    </DataContainer>
  )
}
