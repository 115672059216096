import { createAction, createReducer } from '@reduxjs/toolkit'
import { Store } from 'builder/modules/store'
import { FetchStatuses } from 'builder/modules/constants'
import { EnterpriseModal, JobStatusOption } from './types'
import { JobStatusModalStep, jobStatusOptions } from './constants'

// ---
// Action creators
// ---
export const actions = {
  setStep: createAction<JobStatusModalStep>('builder/ENTERPRISE_SET_STEP'),
  openJobStatusModal: createAction('builder/ENTERPRISE_OPEN_JOB_STATUS_MODAL'),
  closeJobStatusModal: createAction('builder/ENTERPRISE_CLOSE_JOB_STATUS_MODAL'),
  showJobStatusProgressBar: createAction('builder/ENTERPRISE_SHOW_JOB_STATUS_PROGRESS_BAR'),
  hideJobStatusProgressBar: createAction('builder/ENTERPRISE_HIDE_JOB_STATUS_PROGRESS_BAR'),
  openJobStatusConfirmationModal: createAction(
    'builder/ENTERPRISE_OPEN_JOB_STATUS_CONFIRMATION_MODAL',
  ),
  closeJobStatusConfirmationModal: createAction(
    'builder/ENTERPRISE_CLOSE_JOB_STATUS_CONFIRMATION_MODAL',
  ),
  openJobStatusHiredModal: createAction('builder/ENTERPRISE_OPEN_JOB_STATUS_HIRED_MODAL'),
  closeJobStatusHiredModal: createAction('builder/ENTERPRISE_CLOSE_JOB_STATUS_HIRED_MODAL'),
  setFetchJobStatus: createAction<FetchStatuses>('builder/ENTERPRISE_SET_FETCH_JOB_STATUS'),
  fetchJobStatus: createAction('builder/ENTERPRISE_FETCH_JOB_STATUS'),
  setEnterpriseUser: createAction<boolean>('builder/ENTERPRISE_SET_USER'),
  setJobStatus: createAction<JobStatusOption[]>('builder/ENTERPRISE_SET_JOB_STATUS'),
  clearJobStatus: createAction('builder/ENTERPRISE_CLEAR_JOB_STATUS'),
  updateJobStatus: createAction<JobStatusOption[]>('builder/ENTERPRISE_UPDATE_JOB_STATUS'),
  sendUpdatedJobStatus: createAction<JobStatusOption[]>(
    'builder/ENTERPRISE_SEND_UPDATED_JOB_STATUS',
  ),
}

// ---
// Selectors
// ---
export const selectors = {
  step: (state: Store) => state.enterpriseModal.step,
  isJobStatusModalOpen: (state: Store) => state.enterpriseModal.isJobStatusModalOpen,
  showJobStatusProgressBar: (state: Store) => state.enterpriseModal.showJobStatusProgressBar,
  isJobStatusConfirmationModalOpen: (state: Store) =>
    state.enterpriseModal.isJobStatusConfirmationModalOpen,
  isJobStatusHiredModalOpen: (state: Store) => state.enterpriseModal.isJobStatusHiredModalOpen,
  isEnterpriseUser: (state: Store) => state.enterpriseModal.isEnterpriseUser,
  jobStatus: (state: Store) => state.enterpriseModal.jobStatus,
}

// ---
// Reducer
// ---
const initialState: EnterpriseModal = {
  step: JobStatusModalStep.DEFAULT,
  isJobStatusModalOpen: false,
  showJobStatusProgressBar: false,
  isJobStatusConfirmationModalOpen: false,
  isJobStatusHiredModalOpen: false,
  fetchJobStatus: FetchStatuses.notAsked,
  isEnterpriseUser: false,
  jobStatus: jobStatusOptions,
}

export const reducer = createReducer(initialState, reducer => {
  reducer.addCase(actions.setStep, (draft, action) => {
    draft.step = action.payload
  })
  reducer.addCase(actions.openJobStatusModal, draft => {
    draft.isJobStatusModalOpen = true
  })
  reducer.addCase(actions.closeJobStatusModal, draft => {
    draft.isJobStatusModalOpen = false
  })
  reducer.addCase(actions.showJobStatusProgressBar, draft => {
    draft.showJobStatusProgressBar = true
  })
  reducer.addCase(actions.hideJobStatusProgressBar, draft => {
    draft.showJobStatusProgressBar = false
  })
  reducer.addCase(actions.openJobStatusConfirmationModal, draft => {
    draft.isJobStatusConfirmationModalOpen = true
  })
  reducer.addCase(actions.closeJobStatusConfirmationModal, draft => {
    draft.isJobStatusConfirmationModalOpen = false
  })
  reducer.addCase(actions.openJobStatusHiredModal, draft => {
    draft.isJobStatusHiredModalOpen = true
  })
  reducer.addCase(actions.closeJobStatusHiredModal, draft => {
    draft.isJobStatusHiredModalOpen = false
  })
  reducer.addCase(actions.setFetchJobStatus, (draft, action) => {
    draft.fetchJobStatus = action.payload
  })
  reducer.addCase(actions.setEnterpriseUser, (draft, action) => {
    draft.isEnterpriseUser = action.payload
  })
  reducer.addCase(actions.setJobStatus, (draft, action) => {
    draft.jobStatus = action.payload
  })
  reducer.addCase(actions.clearJobStatus, draft => {
    draft.jobStatus = jobStatusOptions
  })
  reducer.addCase(actions.updateJobStatus, (draft, action) => {
    draft.jobStatus = action.payload
  })
})
