import { useI18n } from 'builder/hooks/useI18n'
import { ZoomWrapper, ZoomTitle, ZoomPanel, ZoomLimit, ZoomControl } from './styles'

interface ZoomSliderProps {
  value: number
  onChange: (value: number) => void
}

export const VerticalZoomSlider = ({ value, onChange }: ZoomSliderProps) => {
  const { i18n } = useI18n()

  return (
    <ZoomWrapper>
      <ZoomTitle>{i18n.t('builder.avatar_editor.cropper.zoom_slider_label')}</ZoomTitle>
      <ZoomPanel>
        <ZoomLimit>3&times;</ZoomLimit>
        <ZoomControl step={0.01} min={1} max={3} value={value} onChange={onChange} vertical />
        <ZoomLimit>1&times;</ZoomLimit>
      </ZoomPanel>
    </ZoomWrapper>
  )
}
