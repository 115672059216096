import { trackInternalEvent } from '@rio/tracking'
import { CareerPathItem } from 'builder/modules/careerPath'
import { SkeletonColumns } from '../Skeletons'
import * as CommonStyles from '../../styles'
import * as Styles from './styles'

type Props = {
  titles: Array<CareerPathItem>
  isSkeleton: boolean
}

export const CommonJobTitles = ({ titles, isSkeleton = false }: Props) => {
  if (!titles.length && !isSkeleton) return null

  const handleClick = (jobTitle: CareerPathItem) => {
    trackInternalEvent('click_career_job_title_tag', { tag: jobTitle.title })
  }

  return (
    <Styles.Wrapper>
      <CommonStyles.Title>Common Job Titles</CommonStyles.Title>
      {isSkeleton ? (
        <SkeletonColumns />
      ) : (
        <Styles.LabelContainer>
          {titles.map((item, index) => (
            <CommonStyles.Label key={index} onClick={() => handleClick(item)}>
              {item.title}
            </CommonStyles.Label>
          ))}
        </Styles.LabelContainer>
      )}
    </Styles.Wrapper>
  )
}
