import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import { i18n as I18n } from 'builder/utils/i18n'
import { Store } from 'builder/modules/store'
import { formatDocumentPreviewUrl } from 'builder/utils/formatDocumentPreviewUrl'
import { useResume } from 'builder/hooks/useResume'
import SpinnerImg from 'builder/components/DocumentEditor/JobPosting/Images/Spinner.svg'
import { DataContainer } from '../../../styles'
import { ProgressInner } from '../Result/styles'
import { LoadingIcon, LoadingText, LoadingWrapper, ResumePreview, Progress } from './styles'

export const LoadingView = () => {
  const resumeId = useSelector((state: Store) => state.resumeEditor.resume?.id)
  const isClickedOnAiCVBanner = useSelector(
    (state: Store) => state.resumeEditor.isClickedOnAiCVBanner,
  )

  const isOpenAICVOptimizerModal = useSelector(
    (state: Store) => state.resumeEditor.AICoverLetter.isOpenAICVOptimizerModal,
  )

  const loadingText =
    isClickedOnAiCVBanner || isOpenAICVOptimizerModal
      ? I18n.t('builder.ai_cover_letter.loading_screen.extracting_data')
      : I18n.t('builder.resume_optimizer.job_posting.calculating_score')

  const resume = useResume(resumeId || null)
  const previewImageSrc = useMemo(() => {
    if (!resume) return null

    return formatDocumentPreviewUrl(resume, { size: 352 })
  }, [resume])
  return (
    <DataContainer>
      <LoadingWrapper>
        <LoadingIcon src={SpinnerImg} />
        <LoadingText>{loadingText}</LoadingText>
      </LoadingWrapper>
      <Progress>
        <ProgressInner />
      </Progress>
      <ResumePreview src={previewImageSrc} />
    </DataContainer>
  )
}

export default LoadingView
