import styled from 'styled-components'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'

export const LoadingDocument = styled.video`
  width: 257px;
  height: 267.6px;
  flex-shrink: 0;
  margin-left: -32px;
  margin-top: -25px;

  ${Media.Phone`
    width: 166px;
    height: 136px;
    flex-shrink: 0;
    margin-top: 0px;
  `}
`

export const FailedDocument = styled.img`
  width: 257px;
  height: 267px;
  flex-shrink: 0;
  margin-left: -32px;
  margin-top: -25px;

  ${Media.Phone`
    width: 166px;
    height: 136px;
    flex-shrink: 0;
    margin-top: 0px;
  `}
`

export const Text = styled.div`
  ${Typography.Tiny};
  ${FontWeights.Medium};
  color: ${Colors.Red60};
  text-align: center;
`

export const Badge = styled.div`
  display: flex;
  padding: var(--4XS, 2px) var(--XS, 12px) var(--4XS, 2px) 4px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: var(--Red-Red-10, #ffeaec);
  position: absolute;
  top: 12px;
  left: 12px;
`
