import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Button from 'builder/components/Button'

/**
 * The dimensions for the styles mentioned below have been referred from the figma
 * https://www.figma.com/file/wdX0fbRVlodq1Is8l1GwoV/APP-1372%3A-Subscription-cancelation?node-id=1%3A2&t=sJ8R8w5Ggv06RqH5-0
 */

export const Container = styled.div`
  height: fit-content;
  width: fit-content;
  margin-top: 32px;
  align-self: center;
  background-color: ${Colors.White};

  ${Media.Phone`
    margin-top: 8px;
  `};

  /* To make the Container focusable */
  outline: none;
`

export const Content = styled.div`
  height: fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${Media.Tablet`
    align-items: center;
  `};
`

export const Header = styled.header<{ showCancelledView: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${Media.Phone`
    ${showCancelledView =>
      showCancelledView
        ? `
      padding-left: 20px;
      padding-right: 20px;
    `
        : ''};
  `};
`

export const Title = styled.p`
  ${Typography.S};
  ${FontWeights.DemiBold};
  text-align: center;
  color: ${Colors.Neutral90};
  margin-top: 24px;

  ${Media.Phone` 
    margin-top: 16px;
    max-width: 335px;
  `};
`

export const Description = styled.p<{ isCancelled: boolean }>`
  ${Typography.Body};
  max-width: 544px;
  margin-top: 8px;
  text-align: center;
  color: ${props => (props.isCancelled ? Colors.Neutral90 : Colors.Neutral50)};
  margin-bottom: 42px;
  width: 100%;

  .important {
    ${FontWeights.DemiBold};
  }

  ${Media.Tablet`
    margin-bottom: 32px;
  `};

  ${Media.Phone` 
    margin-bottom: 24px;
    max-width: 335px;
  `};
`

export const CancelledIcon = styled.img`
  margin-top: 60px;

  ${Media.Tablet`
    margin-top: 106px;
  `};

  ${Media.Phone`
    margin-top: 54px;
    width: 81px;
  `};
`

export const KeepDataButton = styled(Button)`
  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${Colors.White};
  background-color: ${Colors.Blue50};
  border-radius: 4px;
  padding: 12px 24px;
  border: 1px solid transparent;

  ${Media.Phone` 
    order: 0;
    width: 100%;
  `};
`

export const ReactivateContainer = styled.div`
  width: 927px;
  padding: 16px;
  border-radius: 12px;
  background-color: ${Colors.Neutral5};
  text-align: center;
  margin-bottom: 94px;

  ${Media.Tablet` 
    width: 728px;
    margin-top: 8px;
    margin-bottom: 122px;
  `};

  ${Media.Phone`
    width: 100%;
    margin-top: 0;
    margin-bottom: 42px;
  `};
`

export const ReactivateText = styled.p`
  ${Typography.Body};
  color: ${Colors.Neutral80};

  .important {
    ${FontWeights.DemiBold};
  }

  ${Media.Tablet` 
    ${Typography.Caption};
  `};

  ${Media.Phone` 
    font-size: 16px;
  `};
`

export const ButtonsContainer = styled.div`
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${Media.Phone` 
    flex-direction: column;
    width: 100%;
  `};
`

export const DashboardButton = styled(Button)`
  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${Colors.White};
  background-color: ${Colors.Blue50};
  border-radius: 4px;
  padding: 12px 24px;
  border: 1px solid ${Colors.Neutral20};

  &:hover {
    background-color: ${Colors.Blue60};
  }

  ${Media.Phone` 
    order: 1;
    width: 100%;
  `};
`

export const ReactivateModalContainer = styled.div`
  position: relative;
  width: 352px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  background-color: ${Colors.White};
  border-radius: 12px;
  box-shadow: 0px 16px 64px -8px rgba(15, 20, 30, 0.32), 0px 6px 8px -2px rgba(15, 20, 30, 0.08);

  ${Media.Phone` 
    padding: 20px;
  `};
`

export const Close = styled.div`
  position: absolute;
  right: 24px;
  top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: ${Colors.Neutral10};
  color: ${Colors.Neutral50};
  transition: background-color 0.2s;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
  }

  ${Media.Phone`
    width: 24px;
    height: 24px;
    top: 20px;
    right: 20px;
  `};
`

export const ReactivateTitle = styled.p`
  max-width: 260px;
  ${Typography.Subhead};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral90};

  ${Media.Phone`
    font-size: 23px;
  `};
`

export const ReactivateSubtitle = styled.p`
  ${Typography.Body};
  color: ${Colors.Neutral50};
  margin-top: 8px;
`

export const ReactivateButtonContainer = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;

  ${Media.Phone`
    margin-top: 20px;
  `};
`

export const NoReactivateButton = styled(Button)`
  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
  background-color: ${Colors.White};
  border-radius: 4px;
  padding: 12px 24px;
  border: 1px solid ${Colors.Neutral20};

  ${Media.Phone`
    padding: 8px 12px;
  `};
`

export const ReactivateButton = styled(Button)`
  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${Colors.White};
  background-color: ${Colors.Blue50};
  border-radius: 4px;
  padding: 12px 24px;
  border: 1px solid transparent;

  ${Media.Phone`
    padding: 8px 12px;
  `};
`
