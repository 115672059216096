import { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { i18n as I18n } from 'builder/utils/i18n'
import { actions } from 'builder/modules/resumeEditor'
import { RichTextAreaContext } from 'builder/context/RichTextAreaContext'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import { suggestionScopeToSectionName } from 'builder/components/RichTextArea'
// eslint-disable-next-line import/no-absolute-path
import generationFailed from '/app/assets/images/builder/ai-resume/ai_summary_generation_failed.png'
import { Props } from '../types'
import {
  Container,
  GenerationFailedImg,
  Heading,
  Subheading,
  Actions,
  Back,
  TryAgain,
} from './styles'

export const ANALYTICS_LABEL = 'ai_writer_error_screen'

export const GenerationFailed = (props: Props) => {
  const {
    suggestionConfig: { scope },
  } = props
  const dispatch = useDispatch()
  const { toggleAiProfileSummary } = useContext(RichTextAreaContext)

  const onBack = () => {
    dispatch(actions.resetSummaryStatus())
    toggleAiProfileSummary({ isOpen: true })
    trackInternalEvent('click_back', {
      section: suggestionScopeToSectionName(scope),
      label: ANALYTICS_LABEL,
    })
  }

  const onTryAgain = () => {
    dispatch(actions.generateResumeAiSummary())
    trackInternalEvent('click_try_again', {
      section: suggestionScopeToSectionName(scope),
      label: ANALYTICS_LABEL,
    })
  }

  useEffectOnMount(() => {
    trackInternalEvent('see_ai_writer_error', { section: suggestionScopeToSectionName(scope) })
  })

  return (
    <Container>
      <GenerationFailedImg src={generationFailed} alt="AI profile summary generation failed" />
      <Heading>{I18n.t('builder.ai_profile_summary.error.title')}</Heading>
      <Subheading>{I18n.t('builder.ai_profile_summary.error.description')}</Subheading>
      <Actions>
        <Back theme="ghost" onClick={onBack}>
          {I18n.t('builder.ai_profile_summary.error.button.back')}
        </Back>
        <TryAgain onClick={onTryAgain}>
          {I18n.t('builder.ai_profile_summary.error.button.try_again')}
        </TryAgain>
      </Actions>
    </Container>
  )
}
