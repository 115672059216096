import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import * as Styled from './styles'

interface Props {
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  isHovered?: boolean
}

const CustomInput = ({ handleFileChange, isHovered }: Props) => {
  const { isPhone } = useMediaQueries()

  return (
    <Styled.Label isHovered={isHovered}>
      <Styled.Input onChange={handleFileChange} />
      Upload your resume and get a free {!isPhone && 'resume'} review
    </Styled.Label>
  )
}

export default CustomInput
