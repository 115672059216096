import { UserData } from 'builder/modules/user'
import { i18n as I18n } from 'builder/utils/i18n'
import { ProgressionSlider } from '../ProgressionSlider'
import { JobInsight } from '../../types'
import { SectionHeader } from '../SectionHeader'
import { translationKey } from '../../constants'
import * as Styled from './styles'
import image from './images/path-to-aspiration-role.png'

type Props = {
  user: UserData
  photoUrl: string
  currentJobPosition: string
  progressionJobs: JobInsight[]
}

export const PathSection = ({ user, progressionJobs, photoUrl, currentJobPosition }: Props) => {
  return (
    <Styled.Container>
      <Styled.SectionHeaderWrapper>
        <SectionHeader
          headerText={I18n.t(`${translationKey}.path_to_aspiration`)}
          description={I18n.t(`${translationKey}.explore_trajectory`, { name: user.firstName })}
          isRowOnTablet={true}
        >
          <Styled.HeaderImage src={image} alt="Banner image" />
        </SectionHeader>
      </Styled.SectionHeaderWrapper>

      <ProgressionSlider
        items={[
          <>
            <Styled.UserPhoto alt="user photo" src={photoUrl} />
            {currentJobPosition && <Styled.JobTitle>{currentJobPosition}</Styled.JobTitle>}
            <Styled.JobSubtitle>{I18n.t(`${translationKey}.current_position`)}</Styled.JobSubtitle>
          </>,
          ...progressionJobs.map(item => (
            <>
              <Styled.JobTitle>{item.title}</Styled.JobTitle>
              <Styled.JobSubtitle>{item.salaryRange}</Styled.JobSubtitle>
              <Styled.JobDescription>{item.description}</Styled.JobDescription>
            </>
          )),
        ]}
      />
    </Styled.Container>
  )
}
