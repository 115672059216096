import { useDispatch, useSelector } from 'react-redux'
import { useMemo, useState } from 'react'
import { useUser } from 'builder/hooks/useUser'
import { capitalize } from 'builder/utils/capitalize'
import { useI18n } from 'builder/hooks/useI18n'
import { ChatPersona, actions, selectors } from 'builder/modules/careerProfile'
import { ButtonTheme } from 'builder/components/Button'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as userSelectors } from 'builder/modules/user'
import { ChatBotAvatarContainer, MessageSeparator, UserAvatar, UserPersonaOption } from './styles'
import { MessageLine } from './MessageLine'
import ChatBotAvatar from './assets/chatbot_avatar.svg'

export const ChatIntro = ({
  noEdit,
  answered,
  asked,
}: {
  noEdit?: boolean
  answered?: boolean
  asked?: boolean
}) => {
  const user = useUser()
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const persona = useSelector(selectors.persona)
  const photoUrl = useTypedSelector(userSelectors.photoUrl)
  const [editingPersona, setEditingPersona] = useState(false)

  const PERSONA_TYPES: { type: ChatPersona; text: string }[] = useMemo(
    () => [
      {
        type: 'job_seeker',
        text: i18n.t('builder.career_profile.chat.persona.job_seeker'),
      },
      {
        type: 'explorer',
        text: i18n.t('builder.career_profile.chat.persona.explorer'),
      },
      {
        type: 'hurdler',
        text: i18n.t('builder.career_profile.chat.persona.hurdler'),
      },
      {
        type: 'starter',
        text: i18n.t('builder.career_profile.chat.persona.starter'),
      },
    ],
    [i18n],
  )

  return (
    <>
      <MessageLine
        type="asked"
        avatar={<ChatBotAvatarContainer src={ChatBotAvatar} alt="chatbot avatar" />}
        last={false}
        amountOfWords={2}
        content={
          <>
            {i18n.t('builder.career_profile.chat.greeting', {
              name: capitalize(user?.firstName || 'User'),
            })}
          </>
        }
      />
      <MessageSeparator />
      <MessageLine
        type="asked"
        avatar={<ChatBotAvatarContainer src={ChatBotAvatar} alt="chatbot avatar" />}
        last={false}
        content={
          <div
            dangerouslySetInnerHTML={{ __html: i18n.t('builder.career_profile.chat.intro_text') }}
          ></div>
        }
      />
      <MessageSeparator />
      <MessageLine
        type="asked"
        avatar={<ChatBotAvatarContainer src={ChatBotAvatar} alt="chatbot avatar" />}
        last={!asked}
        content={<>{i18n.t('builder.career_profile.chat.persona.intro')}</>}
      />
      <MessageSeparator />
      {(editingPersona || !persona) &&
        PERSONA_TYPES.map(item => (
          <UserPersonaOption
            key={`user-persona-option-${item.type.replace(/ /g, '')}`}
            onClick={() => {
              dispatch(actions.setPersona(item.type))
              setEditingPersona(false)
            }}
            theme={ButtonTheme.ghost}
            $selected={item.type === persona}
          >
            {item.text}
          </UserPersonaOption>
        ))}
      {!editingPersona && persona && (
        <MessageLine
          type="answer"
          avatar={<UserAvatar src={photoUrl} />}
          last={!answered}
          content={<>{PERSONA_TYPES.find(({ type }) => type === persona)?.text}</>}
          setEditMessage={noEdit ? undefined : () => setEditingPersona(true)}
        />
      )}
    </>
  )
}
