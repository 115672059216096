import { LeftSquare, RightSquare, Animation } from './styles'

export const LoadingAnimation = () => {
  return (
    <Animation>
      <LeftSquare />
      <RightSquare />
    </Animation>
  )
}
