import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import ModalOverlay from 'builder/components/ModalOverlay'
import { actions, selectors } from 'builder/modules/careerPath2'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

import { Container } from './styles'
import Footer from './components/Footer'
import Content from './components/Content'

const DeletePathModal = () => {
  const dispatch = useDispatch()
  const pathsCardIdToDelete = useTypedSelector(selectors.pathsCardIdToDelete)

  const handleOk = useCallback(() => {
    if (pathsCardIdToDelete) {
      dispatch(actions.deletePathsCard({ id: pathsCardIdToDelete }))
      dispatch(actions.setDeletePathsModalStatus(false))
      dispatch(actions.setPathsCardIdToDelete(null))
    }
  }, [dispatch, pathsCardIdToDelete])

  const handleClose = useCallback(() => {
    dispatch(actions.setPathsCardIdToDelete(null))
    dispatch(actions.setDeletePathsModalStatus(false))
  }, [dispatch])

  return (
    <ModalOverlay onClick={handleClose} overlayFadeDuration={150} contentSlideDuration={150}>
      <Container>
        <Content {...{ handleClose }} />
        <Footer {...{ handleOk, handleClose }} />
      </Container>
    </ModalOverlay>
  )
}

export default DeletePathModal
