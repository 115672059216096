import { memo, useState, useRef, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { useClickOutside } from 'builder/hooks/useClickOutside'
import { useEscapeHandler } from 'builder/hooks/useEscapeHandler'
import { reflow } from 'builder/utils/reflow'
import { StyledColorPicker } from './styles'

// Extends `ColorPicker` with the displaying transition,
// and adds click-outside behavior
const ColorPickerControl = ({ onClose, ...rest }) => {
  const [isVisible, setVisible] = useState(false)
  const container = useRef()

  useLayoutEffect(() => {
    reflow(container.current)
    setVisible(true)
  }, [])

  useClickOutside(container, onClose)
  useEscapeHandler(onClose)

  return <StyledColorPicker ref={container} isVisible={isVisible} {...rest} />
}

ColorPickerControl.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default memo(ColorPickerControl)
