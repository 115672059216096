import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import { Icon24 } from 'builder/components/Icon'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 40px;
  border-bottom: 1px solid ${Colors.Neutral15};

  ${Media.Tablet`
    padding-bottom: 32px;
  `}

  ${Media.Phone`
    align-items: flex-start;
    gap: 11px;
  `}
`

export const BackButtonContainer = styled.div`
  display: inline-flex;
  padding: 4px 16px 4px 8px;
  align-items: center;
  border-radius: 20px;
  background-color: ${Colors.Neutral10};
  cursor: pointer;

  ${Media.Phone`
    width: 32px;
    height: 32px;
    padding: 4px;
  `}
`

export const LeftArrow = styled(Icon24.Chevron)`
  color: ${Colors.Neutral50};
  transform: rotate(180deg);
`

export const BackButtonText = styled.div`
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral50};
`

export const Title = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};
  flex: auto;
  text-align: center;
`
