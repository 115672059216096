import EditorField from 'builder/components/EditorField'
import Select from 'builder/components/Select'
import { useI18n } from 'builder/hooks/useI18n'
import { actions } from 'builder/modules/resumeEditor'
import { useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export function SpouseObligation(props) {
  const { i18n } = useI18n()
  const options = useRef([
    { id: true, name: i18n.t('builder.resume_editor.professional_summary.spouse_options.true') },
    { id: false, name: i18n.t('builder.resume_editor.professional_summary.spouse_options.false') },
  ])
  const spouseObligation = useSelector(state => state.resumeEditor.resume.spouseObligation)
  const dispatch = useDispatch()
  const { updateSimpleField } = actions

  const handleSelect = useCallback(
    value => {
      dispatch(updateSimpleField({ name: 'spouseObligation', value }))
    },
    [dispatch, updateSimpleField],
  )

  return (
    <>
      <EditorField>
        <Select
          label={i18n.t('builder.resume_editor.professional_summary.spouse_obligation')}
          selected={spouseObligation}
          onSelect={handleSelect}
          options={options.current}
        />
      </EditorField>
    </>
  )
}
