import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { EventCode, trackInternalEvent } from '@rio/tracking'
import { actions as uiActions } from 'builder/modules/ui'
import ModalOverlay from 'builder/components/ModalOverlay'
import Button, { ButtonSize } from 'builder/components/Button'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useI18n } from 'builder/hooks/useI18n'
import {
  Text,
  Label,
  Title,
  Close,
  Content,
  CloseIcon,
  Container,
  Illustration,
  ButtonContainer,
} from './styles'

type Props = {
  text: string
  title: string
  visual: string
  eventName?: string
  trackingLabel?: string
  onClose?: () => void
}

const UpcomingCareersModal = ({
  text,
  title,
  visual,
  eventName,
  trackingLabel,
  onClose,
}: Props) => {
  const dispatch = useDispatch()
  const { isPhone } = useMediaQueries()
  const { i18n } = useI18n()

  const handleClick = useCallback(() => {
    if (onClose) {
      onClose()
    } else {
      dispatch(uiActions.setShowUpcomingCareersModal(false))
    }

    if (eventName && trackingLabel) {
      trackInternalEvent(eventName as EventCode, { label: trackingLabel })
    }
  }, [dispatch, eventName, onClose, trackingLabel])

  return (
    <ModalOverlay onClick={handleClick} overlayFadeDuration={150} contentSlideDuration={150}>
      <Container>
        <Close onClick={handleClick}>
          <CloseIcon />
        </Close>
        <Illustration src={visual} />
        <Content>
          <Label>{i18n.t('builder.dashboard.service_data.comming_soon')}</Label>
          <Title>{title}</Title>
          <Text>{text}</Text>
          <ButtonContainer>
            <Button onClick={handleClick} size={isPhone ? ButtonSize.small : ButtonSize.default}>
              Got It
            </Button>
          </ButtonContainer>
        </Content>
      </Container>
    </ModalOverlay>
  )
}

export default UpcomingCareersModal
