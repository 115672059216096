import { useCancellationFormContext } from '../../FormSection'
import { PromotionContentV7 } from '../PromotionContentV7'
import { PromotionDescriptionV7 } from '../PromotionDescriptionV7'
import { PromotionTitle } from '../PromotionTitle'
import PromotionLinksV7 from '../PromotionLinksV7'

const DesktopViewV7 = () => {
  const {
    promotionDescription,
    promotionTitle,
    promotionTitleTopic,
    promotionTopic,
    dontNeedPromoSelectedIndex,
  } = useCancellationFormContext()

  return (
    <>
      <PromotionContentV7 topic={promotionTopic}>
        <PromotionLinksV7 />
        <div style={{ maxWidth: '356px' }}>
          <PromotionTitle topic={promotionTitleTopic}>{promotionTitle}</PromotionTitle>
          <PromotionDescriptionV7 topic={promotionTopic}>
            {Array.isArray(promotionDescription)
              ? promotionDescription[dontNeedPromoSelectedIndex]
              : promotionDescription}
          </PromotionDescriptionV7>
        </div>
      </PromotionContentV7>
    </>
  )
}

export default DesktopViewV7
