import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Button from 'builder/components/Button'

export const WizardFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 50%;
  height: 80px;
  padding: 16px 32px;
  border-top: 1px solid ${Colors.Neutral15};
  background-color: ${Colors.White};
  z-index: 1;

  ${Media.Laptop`
    width: 100%;
  `}
  ${Media.Desktop`
    max-width: 960px;
  `}
  ${Media.Phone`
    padding: 16px 20px;
  `}

  ${Button}:first-child {
    order: 1;
  }
`
