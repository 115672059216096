import { trackInternalEvent } from '@rio/tracking'
import { i18n } from 'builder/context/I18nContext'
import { Panel } from '../../types'
import * as Styled from './styles'

export const MenuPanel = ({ name, to, Icon }: Panel) => (
  <Styled.Panel
    onClick={() => trackInternalEvent('click_super_app_menu_link', { target: name })}
    key={name}
    to={to}
  >
    <Styled.IconWrapper>
      <Icon />
    </Styled.IconWrapper>
    <Styled.Content>
      <Styled.Title>{i18n.t(`builder.navigation.panels.${name}.title`)}</Styled.Title>
      <Styled.Description>
        {i18n.t(`builder.navigation.panels.${name}.description`)}
      </Styled.Description>
    </Styled.Content>
    <Styled.ChevronRight />
  </Styled.Panel>
)
