import styled from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Container = styled.aside`
  width: 352px;
  height: 333px;
  position: relative;
  border-radius: 8px;
  flex-shrink: 0;
  background: ${Colors.Green10};
  text-align: center;
`

export const Title = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};
  margin-left: auto;
  margin-right: auto;
  width: 320px;
  padding-top: 24px;
  color: ${Colors.Blue80};
`

export const ImagePreview = styled.img`
  border-radius: 3px;
  height: 210px;
  width: 232.0000457763672px;
  left: 60px;
  margin-top: 18px;
  z-index: 2;
`

export const ImageSide = styled.img`
  position: absolute;
  right: 27px;
  top: 145px;
  width: auto;
  height: auto;
  z-index: 16;
`

export const Gradient = styled.div`
  width: 352px;
  height: 101.2px;
  position: absolute;
  top: 232px;
  background: linear-gradient(
    to bottom,
    ${hexToRgba(Colors.Green10, 0)},
    ${Colors.Green10} 100%,
    ${Colors.Green10} 0%
  );
  border-radius: 8px;
  z-index: 15;
`

export const Button = styled.a<{ left?: number }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  position: absolute;
  width: fit-content;
  height: 48px;
  left: ${props => (props.left ? props.left : '91')}px;
  top: 261px;
  background: ${Colors.Green50};
  border-radius: 4px;
  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${Colors.White};
  z-index: 17;
`

export const DivisionImage = styled.img`
  position: relative;
  width: 330px;
  height: 20px;
  margin: 10px;

  ${Media.Tablet`
    display: none;
  `};

  ${Media.Phone`
    display: none;
  `}
`
