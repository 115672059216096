import styled from 'styled-components'
import Media from 'builder/styles/media'

export const BackButtonContainer = styled.div`
  display: none;
  margin-top: 0px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  ${Media.Tablet`
    display: flex;
  `}

  ${Media.Phone`
    display: flex;
  `}
`
