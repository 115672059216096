import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { selectors } from 'builder/modules/careerProfile'
import { selectors as userSelectors } from 'builder/modules/user'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useI18n } from 'builder/hooks/useI18n'
import Colors from 'builder/styles/colors'

import { Step } from '../Step'
import {
  AmazonImage,
  AppleImage,
  AvatarIcon,
  BottomDots,
  CenterDots,
  Container,
  Dot,
  Dots,
  EllipseFour,
  EllipseOne,
  EllipseThree,
  EllipseTwo,
  EllipsisContainer,
  GoogleImage,
  IbmImage,
  Images,
  MetaImage,
  MicrosoftImage,
  Score,
  ScoreWrapper,
  TopDots,
} from './styles'

interface IProps {
  isCompleted: boolean
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export type ColorsProp = {
  start: string
  end: string
}

const getProgressColor = (completenessPercent: number): ColorsProp => {
  if (completenessPercent < 10) {
    return { start: Colors.Orange50, end: Colors.Orange30 }
  } else if (completenessPercent < 70) {
    return { start: Colors.Amber50, end: Colors.Amber30 }
  } else {
    return { start: Colors.Green50, end: Colors.Green30 }
  }
}

export const ExploreJobs = ({ isCompleted, isOpen, setIsOpen }: IProps) => {
  const { isPhone } = useMediaQueries()
  const [showHover, setShowHover] = useState(false)
  const navigate = useNavigate()
  const { i18n } = useI18n()

  const photoUrl = useTypedSelector(userSelectors.photoUrl)
  const completenessPercent = useTypedSelector(selectors.completenessPercent) || 0

  return (
    <Step
      title={i18n.t('builder.dashboard_v2.steps.explore_jobs.title')}
      subtitle={i18n.t('builder.dashboard_v2.steps.explore_jobs.subtitle')}
      isCompleted={isCompleted}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      handleOnClick={() => navigate('/job-search?view=recommendation')}
      buttonText={i18n.t('builder.dashboard_v2.steps.explore_jobs.button_text')}
      useSecondaryTheme
      analyticsEventsParam="job_recommendations"
      Images={
        <>
          <Container>
            <ScoreWrapper
              showPercentage={!isCompleted}
              percentageColor={getProgressColor(completenessPercent)}
            >
              <AvatarIcon src={photoUrl} />
              <Score percentageColor={getProgressColor(completenessPercent)}>
                {completenessPercent}%
              </Score>
            </ScoreWrapper>
            {!isPhone && (
              <Dots>
                <TopDots>
                  <Dot />
                  <Dot />
                </TopDots>
                <CenterDots>
                  <Dot />
                  <Dot />
                </CenterDots>
                <BottomDots>
                  <Dot />
                  <Dot />
                </BottomDots>
              </Dots>
            )}
            <Images>
              <GoogleImage size={40} />
              <IbmImage size={32} />
              <MetaImage size={50} />
              <MicrosoftImage size={32} />
              <AppleImage size={40} />
              <AmazonImage size={56} />
            </Images>
            <EllipsisContainer>
              <EllipseOne className={showHover ? 'hover' : ''} />
              <EllipseTwo className={showHover ? 'hover' : ''} />
              <EllipseThree className={showHover ? 'hover' : ''} />
              <EllipseFour className={showHover ? 'hover' : ''} />
            </EllipsisContainer>
          </Container>
        </>
      }
      setShowHover={setShowHover}
    />
  )
}
