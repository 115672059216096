import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-flow: row wrap;

  &:last-child {
    margin-bottom: 0;
  }
`

const EditorRow = props => <Container>{props.children}</Container>

EditorRow.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

export default EditorRow
