import React from 'react'

import { Container, Header, Title, ArrowUp, ArrowDown, ChildrenContainer } from './styles'

type Props = {
  children: JSX.Element
  title: string
  showContent: boolean
  handleActiveAction: () => void
}

const Collapse: React.FC<Props> = ({ children, title, showContent, handleActiveAction }: Props) => {
  return (
    <Container showContent={showContent} onClick={handleActiveAction}>
      <Header>
        <Title>{title}</Title>
        {showContent ? <ArrowUp /> : <ArrowDown />}
      </Header>
      <ChildrenContainer className={showContent ? 'visible' : 'hidden'}>
        {children}
      </ChildrenContainer>
    </Container>
  )
}

export default Collapse
