import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import Sizes from 'builder/styles/sizes'
import { TooltipV2 } from 'builder/components/Tooltip'

export const Container = styled.div`
  display: flex;
  gap: 32px;
  height: 45px;

  ${Media.Tablet`
    gap: 20px;
  `}

  ${Media.Phone`
    height: auto;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
  `}
`

export const HiddenOnTablet = styled.div`
  display: block;

  ${Media.Tablet`
    display: none;
  `}

  ${Media.Phone`
    display: block;
  `}
`

export const HiddenOnDesktop = styled.div`
  display: none;

  ${Media.Tablet`
    display: block;
  `}

  ${Media.Phone`
    display: block;
  `}
`

export const AutoApplyButtonContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`

export const SavedJobsCount = styled.div`
  display: flex;
  background: ${Colors.Blue10};
  padding: 0 ${Sizes['3XS']};
  border-radius: 32px;
  ${FontWeights.Regular};
  ${Typography.Caption};
  color: ${Colors.Blue50};
  height: 24px;
  align-items: center;
`

export const SavedJobs = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${Colors.Blue50};
  justify-content: center;

  svg {
    margin-right: 4px;
  }
`

export const SaveJobsContainer = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  padding: 2px 4px;
  border-radius: 32px;
  gap: 5px;

  &:hover {
    background: ${Colors.Blue10};
  }
`

export const AutoApplyButton = styled.div`
  display: flex;
  gap: 2px;
  color: ${Colors.Blue50};
  ${Typography.Body};
  align-items: center;
  cursor: pointer;
  border-radius: 12px;
`

export const Bubble = styled.div`
  border-radius: 100%;
  background: ${Colors.Blue10};
  flex-shrink: 0;
  height: 24px;
  color: ${Colors.Blue50};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CreditValue = styled.div`
  padding: 0 ${Sizes['3XS']};
  color: ${Colors.Blue50};
  ${FontWeights.Regular};
  ${Typography.Body};
`

export const SettingIconContainer = styled.div`
  height: 24px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const Tooltip = styled(TooltipV2)`
  .tippy-content {
    padding: 4px 8px;
  }
`

export const AutoApplyCreditsContainer = styled.div`
  display: flex;
  padding: 2px 4px 2px 8px;
  align-items: center;
  gap: 4px;
  height: 24px;
  border-radius: 32px;

  &:hover {
    background: ${Colors.Blue10};
  }
`
