import { isWorkExpCardEmpty } from 'builder/components/FillResumeModal/CreateResumeWithAI/QuestionnaireFlow/WorkExperience/WorkExperienceStep'
import { isEducationCardEmpty } from 'builder/components/FillResumeModal/CreateResumeWithAI/QuestionnaireFlow/Education/Education'
import { QuestionnaireData } from './types'

export const formatAIResumeQuestionnaireData = (questionnaireData: QuestionnaireData) => {
  const {
    workExperience,
    desiredPosition,
    education,
    currentSkills,
    pastAccomplishments,
    futureGoals,
  } = questionnaireData.userInput

  const formattedWorkExp = workExperience.cards
    .filter(workExp => !isWorkExpCardEmpty(workExp))
    .map(({ position, company, date }) => ({
      position,
      company,
      dateFrom: date.dateFrom,
      dateUntil: date.isDateUntilPresent ? 'Present' : date.dateUntil,
    }))

  const formattedEducation = education.cards
    .filter(education => !isEducationCardEmpty(education))
    .map(({ institute, degree, date }) => ({
      institute,
      degree,
      dateFrom: date.dateFrom,
      dateUntil: date.isDateUntilPresent ? 'Present' : date.dateUntil,
    }))

  return {
    workExperience: formattedWorkExp,
    education: formattedEducation,
    desiredPosition,
    skills: currentSkills,
    pastAccomplishments,
    futureGoals,
  }
}
