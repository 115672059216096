import Layout from './Layout/'
import Templates from './Templates'
import SocialProfile from './SocialProfile'
import Introduction from './Introduction'
import ContactInfo from './ContactInfo'
import Illustrations from './Illustrations'
import SignUpStepManager, { STEPS } from './SignUpStepManager'

export {
  Layout,
  Templates,
  SocialProfile,
  Introduction,
  ContactInfo,
  Illustrations,
  SignUpStepManager,
  STEPS,
}
