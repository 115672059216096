import styled from 'styled-components'
import Media from 'builder/styles/media'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  ${Media.Phone`
    gap: 20px;
    flex-direction: column;
    align-items: flex-start;
  `}
`
