import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Checkbox from 'builder/components/Checkbox'
import Shadows from 'builder/styles/shadows'

export const Container = styled.div`
  background-color: ${Colors.White};
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  align-items: flex-start;
  gap: 24px;
`

export const Content = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;

  ${Media.Phone`
    display: inline-flex;
    align-items: flex-start;
  `};
`

export const Header = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};
  text-align: center;

  ${Media.Phone`
    margin:auto;
  `};
`

export const Options = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0px;
  gap: 12px;
  width: 100%;
`

export const JobFunctionCheckbox = styled(Checkbox)`
  input[type='checkbox'] {
    border-color: ${Colors.Neutral30};
    transition: border ease-in 0.2s;

    &:checked {
      border-color: ${Colors.Blue50};
    }
  }
`

export const Option = styled.label<{ checked: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: ${props => (props.checked ? `8px 16px` : `9px 16px`)};
  border: ${props =>
    props.checked ? `2px solid ${Colors.Blue50}` : `1px solid ${Colors.Neutral15}`};
  gap: 3px;
  min-width: 202px;
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
  transition: shadow ease-in 0.3s;

  &:hover {
    ${Shadows.lightWithBorder.low};

    ${JobFunctionCheckbox} {
      input[type='checkbox'] {
        border-color: ${Colors.Blue50};
      }
    }
  }

  ${Media.Phone`
    min-width: 161px;
    gap:0px;
    height: 42px;
`};
`

export const Service = styled.div`
  color: ${Colors.Neutral90};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
