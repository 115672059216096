import { all, call, put, takeLatest } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import { apiClient } from 'builder/modules/apiClient'
import ErrorLogger from 'builder/services/ErrorLogger'
import { actions } from './resumeCritiqueReviewModule'

function* sendResumeReviewSaga(action: ReturnType<typeof actions.setResumeId>) {
  try {
    const { resumeId, checkSum } = action.payload

    const response: AxiosResponse = yield call(
      apiClient.post,
      `/resumes/${resumeId}/critique/create-free-critique?checksum=${checkSum}`,
    )
    if (response) {
      yield put(actions.setCritiques(response.data?.critique))
    }
  } catch (error) {
    ErrorLogger.log(error)
  }
}

function* fetchCritiqueReviewStatusSaga(action: ReturnType<typeof actions.setResumeId>) {
  try {
    const { resumeId } = action.payload
    const params = { resume_id: resumeId }
    const { data } = yield call(apiClient.get, `/resume-critiques`, { params })
    if (data && data?.critiques?.length > 0) {
      yield put(actions.setCritiqueStatus(data.critiques[0]))
      yield put(actions.setIsTopResumeUser({ isTopResumeUser: data.critiques[0]?.isTopResumeUser }))
    }
  } catch (error) {
    ErrorLogger.log(error)
  }
}

function* updateScoreForRemoteResumeSaga(
  action: ReturnType<typeof actions.updateScoreForRemoteResume>,
) {
  try {
    const { resume, score } = action?.payload
    const url = `/resumes/${resume?.id}`
    yield call(apiClient.patch, url, { ...resume, score })
  } catch (error) {
    ErrorLogger.log(error)
  }
}

/**
 * Set isTopResumeUser Flag
 */
function* updateIsTopResumeUserFlagSaga(
  action: ReturnType<typeof actions.updateIsTopResumeUserFlag>,
) {
  try {
    const { resumeId, isTopResumeUser } = action.payload
    const { data } = yield call(
      apiClient.post,
      `/tr-critique-details?resume_id=${resumeId}&is_top_resume_user=${isTopResumeUser}`,
    )
    if (data) {
      yield put(actions.setIsTopResumeUser({ isTopResumeUser }))
    }
  } catch (error) {
    ErrorLogger.log(error)
  }
}

function* sendCritiquePopupStatusSaga(action: ReturnType<typeof actions.setResumeId>) {
  try {
    const { resumeId } = action.payload
    yield call(apiClient.post, `/update-critique-popup-viewed-at?resume_id=${resumeId}`)
  } catch (error) {
    ErrorLogger.log(error)
  }
}

/** Bind side-effect handlers */
export const sagas = function* saga() {
  yield all([takeLatest(actions.fetchCioCritiques, sendResumeReviewSaga)])
  yield all([takeLatest(actions.fetchCritiqueReviewStatus, fetchCritiqueReviewStatusSaga)])
  yield all([takeLatest(actions.updateIsTopResumeUserFlag, updateIsTopResumeUserFlagSaga)])
  yield all([takeLatest(actions.fetchPopupDate, sendCritiquePopupStatusSaga)])
  yield all([takeLatest(actions.updateScoreForRemoteResume, updateScoreForRemoteResumeSaga)])
}
