import { LOGO_ELEMENT_ID } from 'builder/components/Navigation'
import { LOGO_SCALE, LOGO_SIGN_HEIGHT, LOGO_SIGN_WIDTH } from './constants'
import { Steps } from './types'

export function getLogoStyle(step: Steps, videoNode: HTMLVideoElement | null): React.CSSProperties {
  const logoNode = document.getElementById(LOGO_ELEMENT_ID)

  if (!logoNode || !videoNode) return {}

  const logoRect = logoNode.getBoundingClientRect()
  const videoRect = videoNode.getBoundingClientRect()

  if (step > Steps.videoToSign) {
    return { top: logoRect.top, left: logoRect.left }
  }

  const offsetX = videoRect.x + videoRect.width / 2 - logoRect.left - LOGO_SIGN_WIDTH / 2
  const offsetY = videoRect.y + videoRect.height / 2 - logoRect.top - LOGO_SIGN_HEIGHT / 2
  const scale = (videoRect.width / 640) * LOGO_SCALE

  return {
    top: logoRect.top,
    left: logoRect.left,
    transform: `translate(${offsetX}px, ${offsetY}px) scale(${scale})`,
  }
}
