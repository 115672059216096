import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Sizes from 'builder/styles/sizes'
import { Icon20 } from 'builder/components/Icon'

export const List = styled.div`
  ${Typography.Caption};
  ${FontWeights.Regular};
  margin-top: ${Sizes.XS};
  display: flex;
  flex-direction: column;
  gap: ${Sizes['2XS']};
`

export const ListItem = styled.div`
  display: flex;
  gap: ${Sizes['3XS']};
  align-items: center;
`

export const ListIcon = styled(Icon20.Tick)`
  color: ${Colors.Green40};
`
