import { IVisualProps } from 'builder/components/CIO-Dasboard-2.0/types'
import AiTag from '../../Tags/AiTag'
import * as Styled from './styles'

export const ExploreYourNextStepsVisual = ({
  imgUrl,
  alt,
  colorScheme,
  isMouseOver,
  userPhotoUrl,
}: IVisualProps) => {
  return (
    <Styled.VisualContainer>
      <AiTag isMouseOver={isMouseOver} colorScheme={colorScheme} />
      <Styled.VisualImageContainer>
        <Styled.VisualImage src={imgUrl} alt={alt} />
        <Styled.UserImage src={userPhotoUrl} alt={alt} />
      </Styled.VisualImageContainer>
    </Styled.VisualContainer>
  )
}
