import times from 'lodash/times'
import { JobTitleIndexType, LEVELS } from 'builder/modules/careerPath2'

import { Connection } from './components/LevelConnections'
import { DefaultConnection } from './components/DefaultConnections'

type Props = {
  level: number
  currentlevel: JobTitleIndexType
  level1JobTitleIndex: JobTitleIndexType
  level2JobTitleIndex: JobTitleIndexType
  level3JobTitleIndex: JobTitleIndexType
  selectedLevel1JobTitleIndex: JobTitleIndexType
  selectedLevel2JobTitleIndex: JobTitleIndexType
  selectedLevel3JobTitleIndex: JobTitleIndexType
}

const NUMBER_OF_CONNECTIONS = 9
const NUMBER_OF_DEFAULT_CONNECTIONS = 3

export const Level = ({
  level,
  currentlevel,
  level1JobTitleIndex,
  level2JobTitleIndex,
  level3JobTitleIndex,
  selectedLevel1JobTitleIndex,
  selectedLevel2JobTitleIndex,
  selectedLevel3JobTitleIndex,
}: Props) => {
  const level1Condition =
    (level1JobTitleIndex === 1 && currentlevel === level) || selectedLevel1JobTitleIndex === 0
  const level2Condition =
    (level2JobTitleIndex === 1 && currentlevel === level) || selectedLevel2JobTitleIndex === 0
  const level3Condition =
    (level3JobTitleIndex === 1 && currentlevel === level) || selectedLevel3JobTitleIndex === 0

  return (
    <>
      {/* Default Connections */}
      {times(NUMBER_OF_DEFAULT_CONNECTIONS).map(connection => {
        if (connection === 0 && !level1JobTitleIndex) {
          return <DefaultConnection key={connection} level={LEVELS.ONE} />
        }

        if (connection === 1 && !level2JobTitleIndex) {
          return <DefaultConnection key={connection} level={LEVELS.TWO} />
        }

        if (connection === 2 && !level3JobTitleIndex) {
          return <DefaultConnection key={connection} level={LEVELS.THREE} />
        }

        return null
      })}

      {/* Level1 Connections */}
      {times(NUMBER_OF_CONNECTIONS).map(connection => {
        if (connection === 0 && level1Condition) {
          return <Connection key={connection} number={connection} level={LEVELS.ONE} />
        }

        if (level1JobTitleIndex === connection + 1 && currentlevel === level) {
          return <Connection key={connection} number={connection} level={LEVELS.ONE} />
        }

        return null
      })}

      {/* Level2 Connections */}
      {times(NUMBER_OF_CONNECTIONS).map(connection => {
        if (connection === 0 && level2Condition) {
          return <Connection key={connection} number={connection} level={LEVELS.TWO} />
        }

        if (level2JobTitleIndex === connection + 1 && currentlevel === level) {
          return <Connection key={connection} number={connection} level={LEVELS.TWO} />
        }

        return null
      })}

      {/* Level3 Connections */}
      {times(NUMBER_OF_CONNECTIONS).map(connection => {
        if (connection === 0 && level3Condition) {
          return <Connection key={connection} number={connection} level={LEVELS.THREE} />
        }

        if (level3JobTitleIndex === connection + 1 && currentlevel === level) {
          return <Connection key={connection} number={connection} level={LEVELS.THREE} />
        }

        return null
      })}
    </>
  )
}
