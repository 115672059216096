import { useEffect, useMemo } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useSearchParams } from 'react-router-dom'
import { useQueryParam } from 'builder/hooks/useQueryParam'
import { EDashboardLocalStoreKeys } from 'builder/components/CIO-Dasboard-2.0/types'
import { i18n } from 'builder/utils/i18n'
import PVHelperButton from 'builder/components/FloatingCIOHelperButton/PVHelperButton/PVHelperButton'
import { UpcomingEvents } from './UpcomingEvents'
import { MyEvents } from './MyEvents'
import { Container, Content, MainTitle } from './styles'

export const Events = () => {
  const [queryParamValue] = useQueryParam('pv_event_id')
  const [urlParams] = useSearchParams()
  const utmParamsList = useMemo(
    () => [
      'utm_content',
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_term',
      'utm_adgroup',
      'utm_lpurl',
      'utm_matchtype',
      'utm_device',
      'utm_location',
      'utm_gclid',
      'utm_msclkid',
      'utm_yclid',
    ],
    [],
  )

  const isPV = queryParamValue !== null
  useEffect(() => {
    if (!isPV) return
    localStorage.setItem(EDashboardLocalStoreKeys.IS_PV_REDIRECT, 'true')
  }, [isPV])
  trackInternalEvent('visit_events_page', { label: 'virtual_events', parameter: 'app' })

  useEffect(() => {
    let utmPayload = {}
    utmParamsList?.map((utmName: string) => {
      utmPayload = { ...utmPayload, [utmName]: urlParams.get(utmName) }
      return utmName
    })
    if (Object.values(utmPayload).filter(e => e).length > 0)
      trackInternalEvent('track_utm', utmPayload)
  }, [urlParams, utmParamsList])

  return (
    <Container>
      <PVHelperButton />
      <Content>
        <MainTitle>{i18n.t('builder.my_events.title')}</MainTitle>
        <MyEvents />
        <UpcomingEvents />
      </Content>
    </Container>
  )
}
