import { createAction, handleActions } from 'redux-actions'
import produce from 'immer'

// ---
// Actions
// ---

export const actions = {
  // Setters
  setEmailCheckingLoading: createAction('signIn/SET_EMAIL_CHECKING_STATUS'),
  setEmailCheckingError: createAction('signIn/SET_EMAIL_CHECKING_ERROR'),
  setSignInMethod: createAction('signIn/SET_SIGN_IN_METHOD'),
  setSignInFetching: createAction('signIn/SET_SIGN_IN_FETCHING'),
  setSignInError: createAction('signIn/SET_SIGN_IN_ERROR'),
  setMagicLinkError: createAction('signIn/SET_MAGIC_LINK_ERROR'),
  setPasswordLinkError: createAction('signIn/SET_PASSWORD_LINK_ERROR'),
  setPasswordSaving: createAction('signIn/SET_PASSWORD_SAVING'),
  setPasswordSaved: createAction('signIn/SET_PASSWORD_SAVED'),
  setPasswordSavingError: createAction('signIn/SET_PASSWORD_SAVING_ERROR'),
  setMagicLinkCheckError: createAction('signIn/SET_MAGIC_LINK_CHECK_ERROR'),
  setMagicLinkEmail: createAction('signIn/SET_MAGIC_LINK_EMAIL'),

  // Requests
  checkEmailRequest: createAction('signIn/CHECK_EMAIL_REQUEST'),
  checkMagicLinkRequest: createAction('signIn/CHECK_MAGIC_LINK_REQUEST'),
  sendMagicLinkRequest: createAction('signIn/SEND_MAGIC_LINK_REQUEST'),
  sendPasswordLinkRequest: createAction('signIn/SEND_PASSWORD_LINK_REQUEST'),
  signInRequest: createAction('signIn/SIGN_IN_REQUEST'),
  savePasswordRequest: createAction('signIn/SAVE_PASSWORD_REQUEST'),
}

// ---
// Reducer
// ---

const initialState = {
  isEmailChecking: false,
  emailCheckingError: null,
  signInMethod: null,

  magicLinkError: null,
  passwordLinkError: null,

  isSignInFetching: false,
  signInError: null,

  passwordSavingError: null,
  isPasswordSaving: false,
  isPasswordSaved: false,

  magicLinkCheckError: null,
  magicLinkEmail: null,
}

export const reducer = handleActions(
  {
    [actions.setMagicLinkCheckError]: produce((draft, action) => {
      draft.magicLinkCheckError = action.payload
    }),
    [actions.setMagicLinkEmail]: produce((draft, action) => {
      draft.magicLinkEmail = action.payload
    }),
    [actions.setEmailCheckingLoading]: produce((draft, action) => {
      draft.isEmailChecking = action.payload
    }),
    [actions.setEmailCheckingError]: produce((draft, action) => {
      draft.emailCheckingError = action.payload
    }),
    [actions.setSignInMethod]: produce((draft, action) => {
      draft.signInMethod = action.payload
    }),
    [actions.setSignInFetching]: produce((draft, action) => {
      draft.isSignInFetching = action.payload
    }),
    [actions.setSignInError]: produce((draft, action) => {
      draft.signInError = action.payload
    }),

    [actions.setMagicLinkError]: produce((draft, action) => {
      draft.magicLinkError = action.payload
    }),
    [actions.setPasswordLinkError]: produce((draft, action) => {
      draft.passwordLinkError = action.payload
    }),

    [actions.setPasswordSaving]: produce((draft, action) => {
      draft.isPasswordSaving = action.payload
    }),
    [actions.setPasswordSaved]: produce((draft, action) => {
      draft.isPasswordSaved = action.payload
    }),
    [actions.setPasswordSavingError]: produce((draft, action) => {
      draft.passwordSavingError = action.payload
    }),
  },
  initialState,
)
