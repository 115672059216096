/* eslint-disable max-len */
export const VideoOnIcon = () => (
  <>
    <rect x="12" y="17" width="18" height="14" rx="4" stroke="white" strokeWidth="2" />
    <path
      d="M30 26.1792V21.8208C30 21.4987 30.1551 21.1963 30.4168 21.0085L34.4167 18.1367C35.0783 17.6617 35.9999 18.1345 35.9999 18.949V29.051C35.9999 29.8655 35.0783 30.3383 34.4167 29.8633L30.4168 26.9915C30.1551 26.8037 30 26.5013 30 26.1792Z"
      stroke="white"
      strokeWidth="2"
    />
  </>
)

export const VideoOffIcon = () => (
  <>
    <circle cx="24" cy="24" r="24" fill="#FB4458" />
    <rect x="12" y="17" width="18" height="14" rx="4" stroke="white" strokeWidth="2" />
    <path
      d="M30 26.1792V21.8208C30 21.4987 30.1551 21.1963 30.4168 21.0085L34.4167 18.1367C35.0783 17.6617 35.9999 18.1345 35.9999 18.949V29.051C35.9999 29.8655 35.0783 30.3383 34.4167 29.8633L30.4168 26.9915C30.1551 26.8037 30 26.5013 30 26.1792Z"
      stroke="white"
      strokeWidth="2"
    />
    <rect
      x="12.7266"
      y="15.5547"
      width="2"
      height="26.9731"
      rx="1"
      transform="rotate(-45 12.7266 15.5547)"
      fill="white"
    />
    <rect
      x="14.1406"
      y="14.1406"
      width="2"
      height="26.9731"
      rx="1"
      transform="rotate(-45 14.1406 14.1406)"
      fill="#FB4458"
    />
  </>
)

export const MicOnIcon = () => {
  return (
    <>
      <path d="M20 34H28" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <path d="M24 31V34" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <rect x="20" y="13" width="8" height="14" rx="4" stroke="white" strokeWidth="2" />
      <path
        d="M32 23C32 27.4183 28.4183 31 24 31C19.5817 31 16 27.4183 16 23"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </>
  )
}

export const MicOffIcon = () => {
  return (
    <>
      <path d="M20 34H28" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <path d="M24 31V34" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <rect x="20" y="13" width="8" height="14" rx="4" stroke="white" strokeWidth="2" />
      <path
        d="M32 23C32 27.4183 28.4183 31 24 31C19.5817 31 16 27.4183 16 23"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <rect
        x="13.7266"
        y="15.5547"
        width="2"
        height="26.9731"
        rx="1"
        transform="rotate(-45 13.7266 15.5547)"
        fill="white"
      />
      <rect
        x="15.1406"
        y="14.1406"
        width="2"
        height="26.9731"
        rx="1"
        transform="rotate(-45 15.1406 14.1406)"
        fill="currentColor"
      />
    </>
  )
}

export const StopOnIcon = () => (
  <>
    <rect x="16" y="16" width="16" height="16" rx="4" fill="white" />
  </>
)
