import { useInfiniteQuery } from '@tanstack/react-query'
import { baseClient } from 'builder/modules/apiClient'
import { Interview, Pagination } from 'builder/modules/interview/types'
import { ALL_INTERVAL_REFETCH } from '../constants'
import { InitialRequest } from '../components/DashboardView/DashboardView'
import { usePerformanceOnce } from './usePerformanceOnce'

interface Params {
  byStatus?: string[]
  byStatusNot?: string[]
  setInitialRequest?: React.Dispatch<React.SetStateAction<InitialRequest | undefined>>
  enabledPerformanceMetric?: boolean
  perPage?: number
  baseInterviewId?: number
}

export const useInfiniteScrollInterviews = ({
  byStatus,
  byStatusNot,
  setInitialRequest,
  enabledPerformanceMetric = false,
  perPage = 5,
  baseInterviewId,
}: Params) => {
  const { registerOncePerformance, stopRegisterPerformance } = usePerformanceOnce({
    enabled: enabledPerformanceMetric,
    metricKey: `interviews-request`,
    successCallBack: () => {
      if (setInitialRequest) {
        setInitialRequest(prevState =>
          prevState === undefined ? undefined : { ...prevState, interviews: true },
        )
      }
    },
  })
  return useInfiniteQuery({
    queryKey: ['useInfiniteScrollInterviews', byStatus, perPage, baseInterviewId],
    staleTime: ALL_INTERVAL_REFETCH,
    queryFn: async ({ pageParam = 1 }) => {
      registerOncePerformance()
      const response = await baseClient.get<Pagination<Interview>>(`/interview_prep/interviews`, {
        params: {
          page: pageParam,
          per_page: perPage,
          by_status: byStatus,
          by_status_not: byStatusNot,
          base_interview_id: baseInterviewId,
        },
      })
      stopRegisterPerformance()
      return response.data
    },
    getNextPageParam: (lastPage, allPages) => {
      const questions = (allPages || []).flatMap(page => page.items)
      if (questions.length < lastPage.total) {
        return lastPage.page + 1
      }
    },
  })
}
