import { UserDocumentTemplate, UserDocumentTemplateId } from 'packages/types'
import { useDispatch, useSelector } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useCallback } from 'react'
import Icon from 'builder/components/Icon'
import { Store } from 'builder/modules/store'
import { formatImageUrl } from 'builder/utils/formatImageUrl'
import { actions as coverLetterActions } from 'builder/modules/coverLetterEditor'
import { actions } from 'builder/modules/resumeEditor'
import { isResume } from 'builder/utils/getTemplates'

import {
  Template,
  TemplatePreview,
  TemplateStatus,
  TemplateName,
  FormatBadges,
  OfflineWrapper,
  PlanBadges,
} from './styles'

type Props = {
  type: string
  templates: UserDocumentTemplate[]
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function TemplatesList(props: Props) {
  const { templates, type } = props
  const isTypeResume = isResume(type)

  const dispatch = useDispatch()
  const isOnline = useSelector((state: Store) => state.application.isOnline)
  const resume = useSelector((state: Store) => state.resumeEditor.resume)
  const coverLetter = useSelector((state: Store) => state.coverLetterEditor.coverLetter)

  const handleTemplateUpdate = useCallback(
    (id: UserDocumentTemplateId) => {
      dispatch(actions.updateTemplate({ id }))
      trackInternalEvent('change_resume_template', { template: id })
    },
    [dispatch],
  )

  const handleSimpleFieldUpdate = useCallback(
    (id: string) => {
      dispatch(coverLetterActions.updateSimpleField({ name: 'template', value: id }))
      trackInternalEvent('change_cover_letter_template', { template: id })
    },
    [dispatch],
  )

  const currentDocument = isTypeResume ? resume : coverLetter
  const updateTemplate = isTypeResume ? handleTemplateUpdate : handleSimpleFieldUpdate

  const letterSizeTemplates = ['athens', 'prague', 'shanghai']

  return (
    <>
      {templates.map((template: UserDocumentTemplate) => (
        <Template key={template.id} onClick={() => updateTemplate(template.id)}>
          <TemplateName>{template.name}</TemplateName>
          <TemplatePreview
            isActive={currentDocument?.template === template.id}
            image={formatImageUrl(template.thumbnail, { width: 154 })}
            fitStyle={letterSizeTemplates.includes(template.id) ? 'contain' : 'cover'}
          >
            {/* Display supported formats */}
            <FormatBadges badges={template.supportedFormats} />

            {!template.premium && <PlanBadges badges={['Free']} />}

            {isOnline ? (
              <TemplateStatus isActive={currentDocument?.template === template.id}>
                <Icon.Tick />
              </TemplateStatus>
            ) : (
              <OfflineWrapper />
            )}
          </TemplatePreview>
        </Template>
      ))}
    </>
  )
}
