import styled, { css } from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Button from 'builder/components/Button'
import { Icon20 } from 'builder/components/Icon'
import { FontWeights } from 'builder/styles/typography'

export const Container = styled.div<{ isFormVisible: boolean }>`
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: ${props => props.isFormVisible && 32}px;

  ${props =>
    props.isFormVisible &&
    css`
      ${Media.Phone`
        margin-bottom: 24px;
      `}
    `}
`

export const Heading = styled.div`
  display: flex;
  align-items: center;
`

export const Figure = styled.span`
  ${FontWeights.DemiBold};
`

export const Emoji = styled.img`
  margin-right: 8px;

  ${Media.Phone`
    width: 32px;
    height: 32px;
  `}
`

export const Actions = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  align-self: flex-end;
  padding-left: 56px;

  ${Media.Phone`
    padding-left: 0;
    gap: 8px;
  `}
`

export const StyledButton = styled(Button)<{ isSelected: boolean }>`
  flex: auto;
  background-color: ${Colors.White};

  &:hover {
    background-color: ${Colors.Blue10};
  }

  ${props =>
    props.isSelected &&
    css`
      color: ${Colors.Blue50};
      border: 1px solid ${Colors.Blue50};
      background-color: ${Colors.Blue10};
      pointer-events: none;
    `}

  ${Media.Phone`
    font-size: 15px;
    line-height: 18px;
  `}
`

export const ArrowUp = styled(Icon20.ArrowLeft)`
  transform: rotate(90deg);
`

export const ArrowDown = styled(Icon20.ArrowLeft)`
  transform: rotate(-90deg);
`

export const Text = styled.div`
  ${FontWeights.Medium};

  ${Media.Phone`
    ${FontWeights.Regular};
  `}
`

export const IconWrapper = styled.div`
  margin-right: 4px;

  ${Media.Phone`
    margin-top: -1px;
    margin-bottom: -1px;
  `}
`
