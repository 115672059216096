import { FormEvent, ReactNode, useRef, useState, useEffect, useCallback } from 'react'
import { AutosuggestPropsMultiSection, RenderSuggestionsContainerParams } from 'react-autosuggest'
import { useLocalStorage } from 'builder/hooks/useLocalStorage'
import {
  ApiSection,
  AsyncAutosuggest,
  DefaultSectionType,
  DefaultSuggestionType,
  createNewSuggestionsApiFetcher,
} from 'builder/components/AsyncAutosuggest'
import {
  SearchSuggestionType,
  CareerPathQuestionnaireFields,
} from 'builder/components/CareerPath2Questionnaire'
import { RenderSuggestionComponentStateType } from 'builder/components/AsyncAutosuggest/types'

import {
  Menu,
  Item,
  Clear,
  IconWrapper,
  SearchField,
  InputWrapper,
  SectionHeader,
  FieldContainer,
} from './styles'

interface Props {
  value: string
  icon: ReactNode
  storageKey: string
  placeholder: string
  multiSection: boolean
  suggestionParam: string
  isJobExpanded?: boolean
  isLocationExpanded?: boolean
  onExpand: (value: boolean) => void
  name: keyof CareerPathQuestionnaireFields
  onInputChange?: (e: FormEvent<HTMLInputElement>) => void
  onValueChange?: (value: string) => void
  onSelectAutosuggestion?: (value: string) => void
  onClear: (name: keyof CareerPathQuestionnaireFields) => void
}

const suggestionsApi = {
  section: ApiSection.CareerPages,
}

const FormInput = ({
  icon,
  name,
  value,
  onClear,
  onExpand,
  storageKey,
  placeholder,
  multiSection,
  onInputChange,
  suggestionParam,
  isJobExpanded,
  isLocationExpanded,
  onValueChange,
  onSelectAutosuggestion,
}: Props) => {
  const isMounted = useRef(true)
  const [defaultSuggestions, setDefaultSuggestions] = useState<DefaultSectionType[]>([])
  const [recentSuggestions, setRecentSuggestions] = useLocalStorage<string[]>(storageKey, [])
  const [recommendedSuggestions, setRecommendedSuggestions] = useState<DefaultSuggestionType[]>([])
  const isExpanded = isJobExpanded || isLocationExpanded

  const getRecommendedSuggestions = useCallback(
    (recentSuggestion: string[]) => {
      if (recentSuggestion.length) {
        const dataSource = createNewSuggestionsApiFetcher(suggestionParam, {
          ...suggestionsApi,
          limit: 3,
        })
        const randomSuggestion = Math.floor(Math.random() * recentSuggestion.length)
        const searchTerm = recentSuggestion[randomSuggestion]

        dataSource(searchTerm).then(suggestions => {
          if (isMounted.current) {
            setRecommendedSuggestions(suggestions)
          }
        })
      }
    },
    [suggestionParam],
  )

  const getSuggestions = (
    recentSuggestions: string[],
    recommendedSuggestions: DefaultSuggestionType[],
  ) => {
    const suggestions: DefaultSectionType[] = []

    if (recentSuggestions.length) {
      // list 3 unique recent suggestions
      const history = Array.from(new Set(recentSuggestions))
        .slice(0, 3)
        .map(query => ({ text: query }))
      suggestions.push({ title: 'Recent', suggestions: history })
    }
    if (recommendedSuggestions.length) {
      suggestions.push({ title: 'Recommended', suggestions: recommendedSuggestions })
    }
    return suggestions
  }

  useEffect(() => {
    return () => {
      // Cleanup function to set isMounted to false when the component is unmounted
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    const suggestions = getSuggestions(recentSuggestions, recommendedSuggestions)
    setDefaultSuggestions(suggestions)
  }, [recentSuggestions, recommendedSuggestions])

  useEffect(
    () => getRecommendedSuggestions(recentSuggestions),
    [getRecommendedSuggestions, recentSuggestions],
  )

  const renderDefaultSuggestionsContainer = ({
    containerProps,
    children,
  }: RenderSuggestionsContainerParams) => {
    onExpand(!!children)
    return (
      <Menu {...containerProps} isEmpty={!children}>
        {children}
      </Menu>
    )
  }

  const renderDefaultSuggestions = (
    suggestion: SearchSuggestionType,
    state: RenderSuggestionComponentStateType,
  ) => {
    return (
      <Item isHighlighted={state.isHighlighted}>
        {icon}
        {suggestion.formatted ?? suggestion.text}
      </Item>
    )
  }

  const renderSectionTitle = (section: DefaultSectionType) =>
    section.title && <SectionHeader>{section.title}</SectionHeader>

  const handleChange = useCallback(
    e => {
      if (onInputChange) {
        onInputChange(e)
      }

      if (onValueChange) {
        onValueChange(e.target.value)
      }

      if ((e.type === 'click' || e.key === 'Enter') && e?.target) {
        setRecentSuggestions([e.target.value, ...recentSuggestions])

        if (onSelectAutosuggestion && e.target.value) {
          onSelectAutosuggestion(e.target.value)
        }
      }
    },
    [onInputChange, recentSuggestions, setRecentSuggestions, onValueChange, onSelectAutosuggestion],
  )

  return (
    <FieldContainer isExpanded={isExpanded} isHoverStateDisabled={true}>
      <AsyncAutosuggest<
        SearchSuggestionType,
        AutosuggestPropsMultiSection<SearchSuggestionType, DefaultSectionType>
      >
        name={name}
        value={value}
        onChange={handleChange}
        multiSection={multiSection}
        focusInputOnSuggestionClick={false}
        fetchItems={createNewSuggestionsApiFetcher(suggestionParam, suggestionsApi)}
        renderSuggestionsContainer={renderDefaultSuggestionsContainer}
        renderSectionTitle={renderSectionTitle}
        renderSuggestion={renderDefaultSuggestions}
        getSectionSuggestions={section => section && section.suggestions}
        shouldRenderSuggestions={() => true}
        defaultSuggestions={defaultSuggestions}
        inputProps={{
          value,
          onChange: handleChange,
          name: name,
          placeholder: placeholder,
          onBlur: () => onExpand(false),
        }}
      >
        {inputProps => (
          <InputWrapper isExpanded={isExpanded}>
            {icon}
            <SearchField {...inputProps} />
            <IconWrapper>{value && <Clear onClick={() => onClear(name)} />}</IconWrapper>
          </InputWrapper>
        )}
      </AsyncAutosuggest>
    </FieldContainer>
  )
}

export default FormInput
