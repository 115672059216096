import { RichTextAreaGlobalStyle } from './styles'
import Loader from './Loader'
export default Loader

export { RichTextAreaGlobalStyle }
export { SuggestionsToggler } from './RichTextToolbar/SuggestionsToggler'
export { useSuggestionRenders } from './RichTextToolbar/useSuggestionRenders'
export { shouldUseAiPhrases, suggestionScopeToSectionName } from './utils'
export { useRichTextAreaActions } from './useRichTextAreaActions'
export { getPhraseContent } from './RichTextToolbar/lib'
