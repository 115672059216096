import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import { Icon20 } from 'builder/components/Icon'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Chevron = styled(Icon20.ChevronDown)`
  transform: rotate(-90deg);
  color: ${Colors.Green50};
`

export const IconBox = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  background-color: ${Colors.Green20};
`

export const Text = styled.p<{ isSevenStoriesExercise: boolean }>`
  ${Typography.Caption};
  width: ${props => (props.isSevenStoriesExercise ? '269px' : '188px')};
  opacity: 0.6;

  ${Media.Tablet`
    width: 167px;
  `}

  ${Media.Phone`
    width: 239px;
  `}
`

export const Card = styled.div<{ isSevenStoriesExercise: boolean }>`
  width: ${props => (props.isSevenStoriesExercise ? '100%' : '268px')};
  height: 336px;
  padding: 20px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${Colors.Green10};
  cursor: pointer;

  ${Media.Tablet`
    width: 232px;
    height: 322px;
  `}

  ${Media.Phone`
    width: 100%;
    height: 447px;
    justify-content: space-between;
  `}

  &:hover ${Chevron} {
    color: ${Colors.White};
  }

  &:hover ${IconBox} {
    background-color: ${Colors.Green50};
  }

  &:hover ${Text} {
    opacity: 1;
  }
`

export const VisualContainer = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;

  ${Media.Phone`
    gap: 54px;
  `}
`

export const Label = styled.div`
  ${FontWeights.Medium};
  width: fit-content;
  padding: 2px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  color: ${Colors.White};
  background-color: ${Colors.Green40};
`

export const Visual = styled.img`
  align-self: center;
  margin-bottom: 14px;

  ${Media.Tablet`
    height: 131px;
  `}
`

export const TextContainer = styled.div`
  gap: 2px;
  flex: auto;
  display: flex;
  flex-direction: column;
  color: ${Colors.Green80};

  ${Media.Phone`
    gap: 8px;
    flex: 0 1 auto;
  `}
`

export const Title = styled.p`
  ${FontWeights.Medium};

  ${Media.Phone`
    ${Typography.S};
  `}
`

export const Description = styled.div`
  flex: auto;
  display: flex;
  justify-content: space-between;

  ${Media.Phone`
    flex: 0 1 auto;
  `}
`
