import { memo } from 'react'
import { AddButton, AddButtonText } from './styles'

type Props = {
  text: string
  onAdd: () => void
  isDisabled?: boolean
}

export const AddMoreButton = memo((props: Props) => {
  const { onAdd, text, isDisabled = false } = props
  return (
    <AddButton data-testid="add-more-btn" onClick={onAdd} isDisabled={isDisabled}>
      <AddButtonText>{text}</AddButtonText>
    </AddButton>
  )
})
