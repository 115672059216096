import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 64px;
  position: relative;

  ${Media.Tablet`
    flex-direction: column;
    gap: 13px;
    width: 416px;
    padding-bottom: 52px;
  `}

  ${Media.Phone`
    flex-direction: column;
    width: 100%;
    max-width: 416px;
    gap: 0;
    padding-top: 40px;
  `}
`

export const UserDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 416px;
  height: auto;
`

export const ImageContainer = styled.img`
  width: 96px;
  height: 96px;
  border-radius: 96px;
  border: 3px solid rgba(30, 37, 50, 0.04);
`

export const Title = styled.div`
  ${Typography.S};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral90};
  text-align: center;
  padding-top: 15px;
`

export const Email = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
  text-align: center;
  padding-top: 6px;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  gap: 8px;
`

export const Button = styled.label<{ isCreateNew: boolean }>`
  ${Typography.Body};
  ${FontWeights.Medium};
  width: 288px;
  height: 48px;
  padding: 12px 24px;

  outline: none;
  border-width: 0;
  cursor: pointer;
  appearance: none;
  user-select: none;
  padding: 8px 14px;
  border-radius: 4px;
  text-align: center;
  white-space: nowrap;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  border: 1px solid ${Colors.Neutral20};
  background: ${props => (props.isCreateNew ? Colors.White : Colors.Blue50)};
  color: ${props => (props.isCreateNew ? Colors.Neutral100 : Colors.White)};

  &:hover {
    background-color: ${props => !props.isCreateNew && Colors.Blue60};
    border-color: ${Colors.Blue50};
    color: ${props => props.isCreateNew && Colors.Blue50};
  }
  &:focus-visible {
    color: ${props => (props.isCreateNew ? Colors.Blue50 : Colors.White)};
    border-color: ${Colors.Blue50};
  }

  &:active {
    background-color: ${hexToRgba(Colors.Blue50, 0.08)};
  }

  ${Media.Phone`
    margin-bottom: 5px;
  `};
`
