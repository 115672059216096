import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Button from 'builder/components/Button'

export const ModalContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 544px;
  padding: 32px;
  border-radius: 6px;
  background-color: ${Colors.Neutral100};
  color: ${Colors.White};
`

export const ModalClose = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 16px;
  color: ${Colors.Neutral70};
  transition: color 0.15s;

  &:hover {
    color: ${Colors.Neutral50};
  }
`

export const ModalTitle = styled.div`
  margin-bottom: 8px;
  ${Typography.M};
  font-weight: 600;
`

export const ModalSubtitle = styled.div`
  margin-bottom: 32px;
  color: ${Colors.Neutral50};
`

export const TipsList = styled.div`
  margin-bottom: 32px;
`

export const Tip = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 24px;
    border-bottom: 1px solid ${Colors.Neutral90};
    padding-bottom: 24px;
  }
`

export const TipIcon = styled.div<{ name: string }>`
  flex-shrink: 0;
  width: 56px;
  height: 56px;
  margin-right: 24px;
  background-image: url(${props => require(`images/builder/wizard/tips/${props.name}.svg`)});
`

export const TipText = styled.div`
  flex-grow: 1;
`

export const ModalButton = styled(Button)`
  display: block;
  width: 100%;
  background-color: ${Colors.Indigo60};

  &:hover,
  &:active {
    background-color: #6b76f3;
  }
`
