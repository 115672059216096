import { flatMap, groupBy } from 'lodash'
import { all, put, call, select, takeLatest } from 'redux-saga/effects'
import { FetchStatuses } from 'builder/modules/constants'
import { apiClient } from 'builder/modules/apiClient'
import ErrorLogger from 'builder/services/ErrorLogger'
import {
  Filter,
  actions,
  Content,
  selectors,
  ContentItem,
  HeadersType,
  SecondLevelType,
} from 'builder/modules/careerPlanning'
import {
  checkForValidIds,
  getSearchParams,
  getUserLastSection,
  getHeadingParams,
  setAllFilterActive,
  updateSearchParams,
} from './utils'
import { allContentParams } from './constants'

export function* fetchActionItemsSaga({ payload }: ReturnType<typeof actions.fetchActionItems>) {
  const { topLevelId, secondLevelId } = payload

  try {
    yield put(actions.setFetchActionItemsStatus(FetchStatuses.loading))

    const params = { cp_top_level_id: topLevelId, cp_second_level_id: secondLevelId }
    const { data } = yield call(apiClient.get, `/career-planning/content`, { params })

    yield put(actions.setActionItems(data.content))
    yield put(actions.setFetchActionItemsStatus(FetchStatuses.loaded))

    yield put(actions.saveUserLastSection(params))
  } catch (error) {
    yield put(actions.setFetchActionItemsStatus(FetchStatuses.failed))
  }
}

export function* saveCompletedActionItemSaga({
  payload,
}: ReturnType<typeof actions.saveCompletedActionItem>) {
  const { id, isCompleted } = payload

  try {
    const params = { is_completed: isCompleted }
    yield call(apiClient.post, `/career-planning/${id}/cta-complete`, params)

    const { data } = yield call(apiClient.get, '/career-planning/cta-progress')
    yield put(actions.setProgress(data?.progress))
  } catch (error) {
    ErrorLogger.log(error)
  }
}

export function* fetchHeadingsSaga({ payload }: ReturnType<typeof actions.fetchHeadings>) {
  try {
    const params = { title: payload }

    const {
      data: { headings },
    } = yield call(apiClient.get, '/career-planning/headings', {
      params,
    })

    if (headings.length) {
      yield put(actions.setNavigationData(headings))

      const { parsedLastSection } = getUserLastSection()
      const { cpTopLevelId, cpSecondLevelId } = getSearchParams()
      const filterOptions: Filter[] = yield select(selectors.filterOptions)
      const headingsParams = getHeadingParams(parsedLastSection, headings)

      const {
        data: { content },
      } = yield call(apiClient.get, `/career-planning/all-content`, { params: allContentParams })

      content.sort((a: { position: number }, b: { position: number }) => a.position - b.position)

      // Set all contents
      const groupedAllContents = groupBy(content, 'cpSecondLevelId')

      const headingContents: SecondLevelType[] = flatMap(
        headings.map((heading: HeadersType) => heading.secondLevel),
      )

      const currentHeadings = Object.keys(groupedAllContents).map(key =>
        headingContents.find(({ id }) => id === +key),
      ) as SecondLevelType[]

      // map contents to their corresponding second level id
      const formatedAllContents = currentHeadings.map(heading => {
        if (heading) {
          return {
            ...heading,
            contents: groupedAllContents[heading.id],
          }
        }

        return null
      }) as Content[]

      yield put(actions.setAllContents(formatedAllContents))

      const openContents = content.filter((item: ContentItem) => !item.completed)
      const completedContent = content.filter((item: ContentItem) => item.completed)

      // Disable "Completed" filter if none of the action items are completed
      const anyCompletedContent = content.filter((item: ContentItem) => item.completed)

      if (!anyCompletedContent.length) {
        const updatedFilterOptions = filterOptions.map((option, index) => {
          if (index === 1) {
            return {
              ...option,
              isDisabled: true,
            }
          }

          return option
        })

        yield put(actions.updateFilterOptions(updatedFilterOptions))
      } else {
        // group completed contents by second level id
        const groupedOpenContents = groupBy(completedContent, 'cpSecondLevelId')

        const headingContents: SecondLevelType[] = flatMap(
          headings.map((heading: HeadersType) => heading.secondLevel),
        )

        const currentHeadings = Object.keys(groupedOpenContents).map(key =>
          headingContents.find(({ id }) => id === +key),
        ) as SecondLevelType[]

        // map contents to their corresponding second level id
        const formatedContents = currentHeadings.map(heading => {
          if (heading) {
            return {
              ...heading,
              contents: groupedOpenContents[heading.id],
            }
          }

          return null
        }) as Content[]

        yield put(actions.setCompletedContents(formatedContents))
      }

      // Disable "Open" filter if none of the action items are open
      const anyOpenContent = content.filter((item: ContentItem) => !item.completed)

      if (!anyOpenContent.length) {
        const updatedFilterOptions = filterOptions.map((option, index) => {
          if (index === 0) {
            return {
              ...option,
              isDisabled: true,
            }
          }

          return option
        })

        yield put(actions.updateFilterOptions(updatedFilterOptions))
      } else {
        // group open contents by second level id
        const groupedOpenContents = groupBy(openContents, 'cpSecondLevelId')

        const headingContents: SecondLevelType[] = flatMap(
          headings.map((heading: HeadersType) => heading.secondLevel),
        )

        const currentHeadings = Object.keys(groupedOpenContents).map(key =>
          headingContents.find(({ id }) => id === +key),
        ) as SecondLevelType[]

        // map contents to their corresponding second level id
        const formatedContents = currentHeadings.map(heading => {
          if (heading) {
            return {
              ...heading,
              contents: groupedOpenContents[heading.id],
            }
          }

          return null
        }) as Content[]

        yield put(actions.setOpenContents(formatedContents))
      }

      yield put(actions.setActionItems(content))

      if (cpTopLevelId && cpSecondLevelId) {
        const { isValidTopLevelId, isValidSecondLevelId } = checkForValidIds(
          headings,
          +cpTopLevelId,
          +cpSecondLevelId,
        )

        if (!isValidTopLevelId) {
          const cpTopLevelId = headings[0].id
          const cpSecondLevelId = headings[0].secondLevel[0].id

          yield put(actions.setStep(cpTopLevelId))
          yield put(actions.setSecondLevelId(cpSecondLevelId))

          localStorage.setItem(
            'userLastSection',
            JSON.stringify({
              cpTopLevelId,
              cpSecondLevelId,
            }),
          )
        } else if (isValidTopLevelId && !isValidSecondLevelId) {
          const cpSecondLevelId = headings.find(
            (heading: HeadersType) => heading.id === +cpTopLevelId,
          ).secondLevel[0].id

          yield put(actions.setStep(+cpTopLevelId))
          yield put(actions.setSecondLevelId(cpSecondLevelId))

          localStorage.setItem(
            'userLastSection',
            JSON.stringify({
              cpTopLevelId: +cpTopLevelId,
              cpSecondLevelId,
            }),
          )
        } else {
          yield put(actions.setStep(+cpTopLevelId))
          yield put(actions.setSecondLevelId(+cpSecondLevelId))

          localStorage.setItem(
            'userLastSection',
            JSON.stringify({
              cpTopLevelId: +cpTopLevelId,
              cpSecondLevelId: +cpSecondLevelId,
            }),
          )
        }

        const updatedFilterOptions = setAllFilterActive(
          filterOptions,
          anyOpenContent.length,
          anyCompletedContent.length,
        )

        yield put(actions.updateFilterOptions(updatedFilterOptions))
      } else {
        yield put(actions.setStep(headingsParams.cp_top_level_id))
        yield put(actions.setSecondLevelId(headingsParams.cp_second_level_id))

        localStorage.setItem(
          'userLastSection',
          JSON.stringify({
            cpTopLevelId: headingsParams.cp_top_level_id,
            cpSecondLevelId: headingsParams.cp_second_level_id,
          }),
        )

        updateSearchParams(headingsParams.cp_top_level_id, headingsParams.cp_second_level_id)
      }
      yield put(actions.setFetchActionItemsStatus(FetchStatuses.loaded))
    }
  } catch (error) {
    console.log(error)
  }
}

export function* fetchProgressSaga() {
  try {
    const { data } = yield call(apiClient.get, '/career-planning/cta-progress')

    yield put(actions.setProgress(data?.progress))
  } catch (error) {
    console.log(error)
  }
}

export function* fetchUserLastSectionSaga() {
  try {
    const { data } = yield call(apiClient.get, '/career-planning/user-last-section')

    yield put(actions.setStep(data?.cpTopLevelId))
    yield put(actions.setSecondLevelId(data?.cpSecondLevelId))
  } catch (error) {
    console.log(error)
  }
}

export function* saveUserLastSectionSaga({
  payload,
}: ReturnType<typeof actions.saveUserLastSection>) {
  try {
    yield call(apiClient.post, `/career-planning/user-last-section`, payload)
  } catch (error) {
    ErrorLogger.log(error)
  }
}

/** Bind side-effect handlers */
export const sagas = function* saga() {
  yield all([takeLatest(actions.fetchActionItems, fetchActionItemsSaga)])
  yield all([takeLatest(actions.saveCompletedActionItem, saveCompletedActionItemSaga)])
  yield all([takeLatest(actions.fetchHeadings, fetchHeadingsSaga)])
  yield all([takeLatest(actions.fetchProgress, fetchProgressSaga)])
  yield all([takeLatest(actions.fetchUserLastSection, fetchUserLastSectionSaga)])
  yield all([takeLatest(actions.saveUserLastSection, saveUserLastSectionSaga)])
}
