import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Button from 'builder/components/Button'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 547px;
  height: 552px;
  box-shadow: 0px 16px 64px -8px rgba(15, 20, 30, 0.32), 0px 6px 8px -2px rgba(15, 20, 30, 0.08);

  ${Media.Phone`
    max-width: 343px;
    height: auto;
  `};
`

export const Close = styled.div`
  position: absolute;
  right: 32px;
  top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: ${Colors.White};
  color: ${Colors.Neutral50};
  transition: background-color 0.2s;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
  }

  ${Media.Phone`
    width: 24px;
    height: 24px;
    top: 20px;
    right: 20px;
  `};
`

export const PauseLogo = styled.img`
  border-radius: 16px 16px 0 0;
  background-color: #8bbfff;

  ${Media.Phone`
    max-width: 343px;
  `};
`

export const Content = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  background-color: ${Colors.White};
  border-radius: 0 0 16px 16px;

  ${Media.Phone`
    padding: 20px;
  `};
`

export const Title = styled.p`
  ${Typography.S};
  ${FontWeights.DemiBold};

  ${Media.Phone`
    max-width: 303px;
  `};
`

export const Description = styled.p`
  ${Typography.Body};
  color: ${Colors.Neutral50};
  margin-top: 12px;

  .important {
    color: ${Colors.Neutral80};
    ${FontWeights.Medium};
  }

  ${Media.Phone`
    max-width: 303px;
  `};
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: ${Colors.White};
  margin-top: 32px;
  gap: 8px;

  ${Media.Phone`
    margin-top: 20px;
  `};
`

export const GreatButton = styled(Button)`
  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${props => (props.isDisabled ? Colors.Neutral50 : Colors.White)};
  background-color: ${props => (props.isDisabled ? Colors.Neutral20 : Colors.Blue50)};
  border-radius: 4px;
  padding: 12px 24px;

  &:hover {
    background-color: ${Colors.Blue60};
  }

  ${Media.Phone`
    padding: 8px 12px;
  `};
`

export const CancelButton = styled(Button)`
  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
  background-color: ${Colors.White};
  border-radius: 4px;
  padding: 12px 24px;
  border: 1px solid ${Colors.Neutral20};

  &:hover {
    background-color: ${Colors.White};
    border: 1px solid ${Colors.Blue50};
    color: ${Colors.Blue50};
  }

  ${Media.Phone`
    padding: 8px 12px;
  `};
`
