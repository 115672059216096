import { SKILL_ERROR, SkillsType } from 'builder/modules/sevenStories'
import { ToolTip } from '../Tooltip/ToolTip'
import { Field, Label, LabelWithTooltip, SkillError, TextFieldContainer } from './style'

type ListProps = {
  isSkillError: boolean
  onSkillChange: (value: string, index: number) => void
  skills: SkillsType[]
  index: number
  item: {
    id: number
  }
}

export const SkilList = ({ item, onSkillChange, index, skills, isSkillError }: ListProps) => {
  const skill = skills[index].text

  return (
    <Field key={item.id}>
      <LabelWithTooltip>
        <Label>Enjoyable skill #{item?.id} </Label>
        {item?.id === 1 && <ToolTip />}
      </LabelWithTooltip>
      <TextFieldContainer
        value={skill}
        placeholder="Your enjoyable skill"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          onSkillChange(event.target.value, index)
        }
      />
      {item?.id === 1 && <SkillError>{isSkillError ? SKILL_ERROR : ''}</SkillError>}
    </Field>
  )
}
