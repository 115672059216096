import { capitalize } from 'builder/utils/capitalize'
import { Heading, Type, Text } from './styles'

type Props = {
  type: string
  title: string
  isCompleted: boolean
}

const Header = ({ type, title, isCompleted }: Props) => {
  return (
    <Heading>
      <Text isCompleted={isCompleted}>{title}</Text>
      <Type type={capitalize(type)}>{capitalize(type)}</Type>
    </Heading>
  )
}

export default Header
