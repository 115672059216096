import { useState, useRef, useEffect, useCallback } from 'react'
import { Interview } from 'builder/modules/interview/types'
import { useTextToSpeechBlob } from 'builder/views/Interview/hooks/useTextToSpeechBlob'
import { useInterviewQuestions } from 'builder/views/Interview/hooks/useInterviewQuestions'
import { PerformanceLoggerWithMainDsn as PerformanceLogger } from 'builder/services/PerformanceLogger'
import ErrorLogger from 'builder/services/ErrorLogger'
import { AudioPlayerUtils } from 'builder/views/Interview/utils/AudioPlayerUtils'
import { getNumberOfQuestionOfInterview } from 'builder/views/Interview/utils/getNumberOfQuestionOfInterview'

export function useTextToSpeechPlay(startQuestionIndex: number, interview?: Interview) {
  const { getTextToSpeechBlob, abortAllPendingRequesting, abortSignal } = useTextToSpeechBlob()

  const audioUtilsRef = useRef(new AudioPlayerUtils())

  const [speaking, setSpeaking] = useState(false)
  const [loading, setLoading] = useState(true)
  const [hasDownloadAllAssets, setHasDownloadAllAssets] = useState(false)

  const announcements = useRef<Record<number, Promise<Blob | undefined>>>({})

  const maxQuestions = interview ? getNumberOfQuestionOfInterview(interview) : 0
  const { data: pollingQuestions = [] } = useInterviewQuestions(
    interview?.id,
    pollingQuestions =>
      maxQuestions === pollingQuestions?.filter(question => question.announcement).length,
    abortSignal,
  )

  const stopAnnouncement = useCallback(() => audioUtilsRef.current?.stopSound(), [])

  const firstQuestionId = interview?.questions[startQuestionIndex].id
  const firstQuestion = pollingQuestions.find(question => question.id === firstQuestionId)
  const loadingSentinelRef = useRef<NodeJS.Timeout>()

  useEffect(() => {
    return () => audioUtilsRef.current?.stopSound()
  }, [])

  useEffect(() => {
    async function downloadFirstAnnouncement() {
      if (interview && pollingQuestions) {
        if (!loadingSentinelRef.current) {
          loadingSentinelRef.current = setTimeout(() => {
            setLoading(false)
            setSpeaking(false)
            abortAllPendingRequesting()
          }, 8000)
        }

        if (
          firstQuestion &&
          firstQuestion?.announcement &&
          !announcements.current[firstQuestion.id]
        ) {
          clearTimeout(loadingSentinelRef.current)
          PerformanceLogger.operation('load-first-announcement')
          announcements.current[firstQuestion.id] = getTextToSpeechBlob(firstQuestion.announcement)
          await announcements.current[firstQuestion.id]
          setLoading(false)
          PerformanceLogger.operationEnd('load-first-announcement')
          PerformanceLogger.operation('load-all-announcement', {
            tags: { countQuestions: interview.questions.length },
          })
        }
      }
    }

    downloadFirstAnnouncement()
  }, [interview, pollingQuestions, firstQuestion])

  useEffect(() => {
    async function downloadMissingBlobs() {
      if (!firstQuestion || !interview) {
        return
      }

      const firstAnnouncement = await announcements.current[firstQuestion.id]

      if (firstAnnouncement && pollingQuestions) {
        pollingQuestions
          .filter(question => !announcements.current[question.id] && question.announcement)
          .forEach(question => {
            announcements.current[question.id] = getTextToSpeechBlob(question.announcement)
          })
      }
      if (Object.keys(announcements.current).length === interview?.questions.length) {
        setHasDownloadAllAssets(true)
      }
    }

    downloadMissingBlobs()
  }, [pollingQuestions, firstQuestion, getTextToSpeechBlob, interview])

  useEffect(() => {
    async function checkDownloadAllAssets() {
      if (hasDownloadAllAssets) {
        await Promise.allSettled(Object.values(announcements.current))
        PerformanceLogger.operationEnd('load-all-announcement')
      }
    }

    checkDownloadAllAssets()
  }, [hasDownloadAllAssets])

  const playQuestionAnnouncement = async (id?: number) => {
    if (id === undefined) {
      return
    }
    const announcement = announcements.current[id]
    if (!announcement) {
      return
    }
    try {
      const questionBlob = await Promise.race([announcement, Promise.resolve(undefined)])
      if (!questionBlob) {
        return
      }
      setSpeaking(true)
      await audioUtilsRef.current.playAudioFromBlob(questionBlob)
    } catch (error) {
      ErrorLogger.log(error)
    }

    setSpeaking(false)
  }

  return { playQuestionAnnouncement, loading, speaking, stopAnnouncement }
}
