import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px 0 0 0;
  width: 100%;

  ${Media.Laptop`
    padding: 32px 20px 0 20px;
  `}

  ${Media.Phone`
    padding-top: 16px;
  `}
`

export const BorderBottom = styled.div`
  height: 1px;
  width: 100%;
  max-width: 1120px;
  margin: auto;
  background: ${Colors.Neutral15};
  margin-top: 64px;

  ${Media.Phone`
    margin-top: 48px;
  `}
`

export const Cards = styled.div`
  gap: 32px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-around;

  ${Media.Tablet`
    gap:48px;
  `}
`

export const Card = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 24px;
  width: 352px;

  ${Media.Tablet`
    width: 540px;
  `}

  ${Media.Phone`
    width: 352px;
  `}
`

export const CardImg = styled.img`
  width: 80px;
  max-height: 122px;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 248px;

  ${Media.Tablet`
    width: 436px;
  `}

  ${Media.Phone`
    width: 231px;
  `}
`

export const Text = styled.p`
  ${FontWeights.Medium};
  ${Typography.Subhead};
`

export const SubText = styled.div`
  color: ${Colors.Neutral50};

  a {
    color: ${Colors.Blue50};
  }
`

export const HeaderText = styled.p`
  ${FontWeights.Medium};
  ${Typography.S};
  margin-bottom: 40px;
`
