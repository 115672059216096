import { FormEvent } from 'react'
import { useDispatch } from 'react-redux'
import { useTransition } from '@react-spring/web'
import Button from 'builder/components/Button'
import { Icon20 } from 'builder/components/Icon'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import {
  actions,
  selectors,
  JobStatusModalStep,
  jobStatusOptions,
} from 'builder/modules/enterpriseModal'
import { OptionsInterface } from './types'
import { transitionValues } from './constants'
import * as Styled from './styles'

const JobStatusModal = () => {
  const dispatch = useDispatch()
  const jobStatus = useTypedSelector(selectors.jobStatus)
  const isModalOpen = useTypedSelector(selectors.isJobStatusModalOpen)
  const transition = useTransition(isModalOpen, transitionValues)

  const handleCheck = (selectedOption: OptionsInterface) => {
    dispatch(actions.clearJobStatus())

    const updatedJobStatusOptions = jobStatusOptions.map(option =>
      option.id === selectedOption.id ? { ...option, checked: true } : option,
    )

    dispatch(actions.updateJobStatus(updatedJobStatusOptions))
  }

  const handleClose = () => {
    dispatch(actions.closeJobStatusModal())
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    const checkedJobStatusID = jobStatus.find(status => status.checked)?.id

    if (checkedJobStatusID) {
      switch (checkedJobStatusID) {
        case 1:
          dispatch(actions.setStep(JobStatusModalStep.WORKINGONCV))
          break
        case 2:
          dispatch(actions.setStep(JobStatusModalStep.JOBSEARCHING))
          break
        case 3:
          dispatch(actions.setStep(JobStatusModalStep.APPLYINGJOBS))
          break
        case 4:
          dispatch(actions.setStep(JobStatusModalStep.INTERVIEWING))
          break
        case 5:
          dispatch(actions.setStep(JobStatusModalStep.HIRED))
          break
      }

      dispatch(actions.closeJobStatusModal())
      dispatch(actions.sendUpdatedJobStatus(jobStatus))
      if (checkedJobStatusID !== 5) {
        dispatch(actions.showJobStatusProgressBar())
      } else {
        dispatch(actions.openJobStatusHiredModal())
      }

      setTimeout(() => {
        dispatch(actions.hideJobStatusProgressBar())
      }, 3000)
    }
  }

  return transition((style, item) =>
    item ? (
      <Styled.ModalContainer style={style}>
        <Styled.ModalContent>
          <Styled.Close onClick={handleClose}>
            <Icon20.Close />
          </Styled.Close>
          <Styled.Heading>Where are you in your job search?</Styled.Heading>
          <Styled.SubHeading>
            Please help your career rep understand where you are in your job search so they can best
            assist you
          </Styled.SubHeading>
          <Styled.Options>
            {jobStatus.map(option => {
              const { id, status, checked } = option
              return (
                <Styled.Option key={id} checked={checked} onClick={() => handleCheck(option)}>
                  <Styled.OptionText>{status}</Styled.OptionText>
                  <Styled.Radio checked={checked}>
                    <Styled.RadioControl checked={checked} />
                  </Styled.Radio>
                </Styled.Option>
              )
            })}
          </Styled.Options>
          <Styled.ButtonContainer>
            <Button theme="ghost" size="small" onClick={handleClose}>
              Not Now
            </Button>
            <Button
              size="small"
              onClick={handleSubmit}
              isDisabled={!jobStatus.some(e => e.checked)}
            >
              Submit
            </Button>
          </Styled.ButtonContainer>
        </Styled.ModalContent>
      </Styled.ModalContainer>
    ) : null,
  )
}

export default JobStatusModal
