import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  position: sticky;
  bottom: 0;
  height: 84px;
  width: 100%;
  display: flex;
  background-color: ${Colors.White};
  flex-direction: column;
  flex-shrink: 0;
  padding-top: 16px;
`

export const ContainerText = styled.div`
  ${Typography.Caption};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
  font-size: 15px;
  font-weight: 500;
  padding-bottom: 12px;
`

export const StyledCompleted = styled.span`
  color: ${Colors.Neutral50};
`

export const ProgressContainer = styled.div`
  width: 100%;
  height: 4px;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  background-color: ${Colors.Neutral10};
`

export const Progress = styled.div<{ $score: number }>`
  overflow: hidden;
  width: ${props => props.$score}%;
  height: 100%;
  background-color: ${Colors.Blue50};
  position: absolute;
  top: 0;
  left: 0;
`
