import { memo, Fragment, useState, useRef, useMemo, useCallback, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import findIndex from 'lodash/findIndex'
import { Swiper as ReactSwiper, SwiperSlide } from 'swiper/react'
import { useI18n } from 'builder/hooks/useI18n'
import { reflow } from 'builder/utils/reflow'
import { SPACING } from 'builder/modules/constants'
import Icon from 'builder/components/Icon'
import ColorSelect from 'builder/components/ColorSelect'
import ColorPickerPanel from './ColorPickerPanel'

import {
  Overlay,
  Sheet,
  Title,
  ColorsField,
  Templates,
  Template,
  Spacing,
  SpacingLimit,
  Slider,
  SliderHandle,
} from './styles'
import { useSelector } from 'react-redux'
import { filterGreekTemplates } from 'builder/utils/filterGreekTemplates'
import { ATS_TEMPLATES, JAPANESE_TEMPLATES } from '../Helper/constants'

const TemplateSettingsSheet = ({
  type,
  onClose,
  templates,
  selectedTemplate,
  onTemplateChange,
  colors,
  selectedColor,
  onColorChange,
  spacing,
  isSpacingSupported,
  onSpacingChange,
}) => {
  const { i18n } = useI18n()
  const [isColorPickerOpen, setColorPickerOpen] = useState(false)
  const [isVisible, setVisible] = useState(false)
  const overlay = useRef()
  const templatesGallery = useRef()
  const locale = useSelector(state => state.application.configs.country.locale)

  //  Added the function to filter the template list while locale is greek
  const templateList = useMemo(() => {
    const hideTemplate = []

    if (locale !== 'ja-JP') {
      hideTemplate.push(...JAPANESE_TEMPLATES)
    } else {
      // TODO: remove this condition after fixing ATS templates
      hideTemplate.push(...ATS_TEMPLATES)
    }

    // TODO after Greek templates release: Remove the condition
    return (type === 'resume' ? templates : filterGreekTemplates(templates, locale))?.filter(
      template => !hideTemplate?.includes(template?.id),
    )
  }, [locale, templates, type])

  useLayoutEffect(() => {
    reflow(overlay.current)
    setVisible(true)
  }, [])

  const handleColorPickerOpen = useCallback(() => setColorPickerOpen(true), [])

  const handleColorPickerClose = useCallback(() => setColorPickerOpen(false), [])

  const handleColorPickerCancel = useCallback(
    color => {
      onColorChange(color)
      setColorPickerOpen(false)
    },
    [onColorChange],
  )

  const handleOverlayClick = useCallback(
    event => onClose && event.target === overlay.current && onClose(),
    [onClose],
  )

  const handleTemplateChange = useCallback(() => {
    if (!templatesGallery.current) return
    const { activeIndex = 0 } = templatesGallery.current.swiper
    const { id } = templateList[activeIndex]
    if (onTemplateChange) onTemplateChange(id)
  }, [templateList, onTemplateChange])

  const initialSlide = useMemo(() => {
    return findIndex(templateList, { id: selectedTemplate })
  }, [selectedTemplate, templateList])

  return (
    <Overlay ref={overlay} onClick={handleOverlayClick}>
      <Sheet isVisible={isVisible}>
        {!isColorPickerOpen && (
          <Fragment>
            <Title>{i18n.t('builder.resume_editor.template_select')}</Title>
            <Templates>
              <ReactSwiper
                ref={templatesGallery}
                initialSlide={initialSlide}
                slidesPerView="auto"
                spaceBetween={28}
                centeredSlides={true}
                slideToClickedSlide={true}
                onSlideChange={handleTemplateChange}
              >
                {templateList.map(template => (
                  <SwiperSlide key={template.id}>
                    <Template>{template.name}</Template>
                  </SwiperSlide>
                ))}
              </ReactSwiper>
            </Templates>

            <Title>{i18n.t('builder.resume_editor.color_select')}</Title>
            <ColorsField>
              <ColorSelect
                size={42}
                selected={selectedColor}
                options={colors}
                onSelect={onColorChange}
                onAdd={handleColorPickerOpen}
              />
            </ColorsField>

            {type === 'resume' && (
              <Fragment>
                <Title>{i18n.t('builder.resume_editor.spacing')}</Title>
                <Spacing>
                  <SpacingLimit>50%</SpacingLimit>
                  <Slider
                    disabled={!isSpacingSupported}
                    defaultValue={0}
                    value={spacing}
                    step={SPACING.step}
                    min={SPACING.min}
                    max={SPACING.max}
                    handle={Handle}
                    onChange={onSpacingChange}
                    dots
                  />
                  <SpacingLimit>150%</SpacingLimit>
                </Spacing>
              </Fragment>
            )}
          </Fragment>
        )}
        {isColorPickerOpen && (
          <ColorPickerPanel
            selected={selectedColor}
            onSelect={onColorChange}
            onSubmit={handleColorPickerClose}
            onCancel={handleColorPickerCancel}
          />
        )}
      </Sheet>
    </Overlay>
  )
}

TemplateSettingsSheet.propTypes = {
  type: PropTypes.string,
  onClose: PropTypes.func,

  templates: PropTypes.array.isRequired,
  selectedTemplate: PropTypes.string,
  onTemplateChange: PropTypes.func,

  colors: PropTypes.array,
  selectedColor: PropTypes.string,
  onColorChange: PropTypes.func,

  spacing: PropTypes.number,
  isSpacingSupported: PropTypes.bool,
  onSpacingChange: PropTypes.func,
}

export default memo(TemplateSettingsSheet)

const Handle = ({ value, dragging, index, ...handleProps }) => (
  <SliderHandle value={value} {...handleProps}>
    {handleProps.disabled && <Icon.Lock />}
  </SliderHandle>
)

Handle.propTypes = {
  value: PropTypes.any,
  dragging: PropTypes.any,
  index: PropTypes.number,
  disabled: PropTypes.bool,
}
