import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const AiTagContainer = styled.div<{ bgColor: string; isMouseOver: boolean }>`
  position: absolute;
  width: ${props => (props.isMouseOver ? '109px' : '29px')};
  flex-direction: ${props => (props.isMouseOver ? 'row' : 'row-reverse')};
  height: 24px;
  left: 22px;
  top: 20px;
  padding: ${props => (props.isMouseOver ? '0 0 4px 2px' : '0 0 2px 2px')};
  border-radius: 6px;
  background: ${props => (!props.isMouseOver ? `${props.bgColor}7f` : props.bgColor)};
  transition: width 0.22s;
  overflow: hidden;

  ${Media.Phone`
    width: 105px;
    flex-direction: row;
  `}
`

export const AiTag = styled.p<{ color: string; isMouseOver: boolean }>`
  ${FontWeights.Medium};
  ${Typography.Caption};
  padding: 0 0 4px 0;
  color: ${props => props.color};
  display: inline;
`
