import { useRef, useState } from 'react'
import { i18n } from 'builder/utils/i18n'

import { BriefExpertDoc, BriefResume } from 'builder/modules/panel'
import { useClickOutside } from 'builder/hooks/useClickOutside'
import { ReplaceResumePopup } from 'builder/views/ResumeDistributionDashboardView/components/DashboardHeader/ReplaceResumePopup/ReplaceResumePopup'

import { PdfIcon } from '../../../assets/svg/pdfIcon'
import FieldSpinnerLoader from '../../FormLayout/FieldSpinnerLoader'

import {
  FileSize,
  LabelUpload,
  LabelWrapperUpload,
  ResumePopupContainer,
  UploadButton,
  UploadContainer,
  UploadResumeName,
} from '../styles'
import { formatFileSize } from '../utils'
import SelectResumeModal from '../../SelectResumeModal'

type Props = {
  editFileName: string | null
  resumePayload: File | null
  isParserLoading: boolean
  handleBuilderResumeChange: (data: BriefResume | BriefExpertDoc) => void
  handleFileInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const ResumeFilePreview = ({
  editFileName,
  resumePayload,
  isParserLoading,
  handleFileInputChange,
  handleBuilderResumeChange,
}: Props) => {
  const [showPopup, setShowPopup] = useState(false)
  const [showResumeSelection, setResumeSelection] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  useClickOutside(containerRef, () => setShowPopup(false))

  const getResumeName = (name?: string | null) => {
    return name && name?.length < 20 ? name : `${name?.slice(0, 20) || '-'}`
  }

  return (
    <UploadContainer>
      <LabelUpload>
        <PdfIcon />
        <UploadResumeName>
          {editFileName ||
            (!resumePayload ? 'No Resume Selected' : getResumeName(resumePayload?.name))}
        </UploadResumeName>
        {!editFileName && <FileSize>{resumePayload && formatFileSize(resumePayload)}</FileSize>}
      </LabelUpload>
      <LabelWrapperUpload>
        <UploadButton onClick={() => setShowPopup(true)}>
          {isParserLoading ? (
            <FieldSpinnerLoader />
          ) : (
            i18n.t(`builder.resume_distribution.landing.form_personal_detail.replace_resume`)
          )}
        </UploadButton>

        {showPopup && (
          <ResumePopupContainer>
            <ReplaceResumePopup
              setResumeSelection={setResumeSelection}
              handleFileInputChange={handleFileInputChange}
              ref={containerRef}
            />
          </ResumePopupContainer>
        )}
      </LabelWrapperUpload>
      {showResumeSelection && (
        <SelectResumeModal
          onClose={() => setResumeSelection(false)}
          submitSelectedResume={handleBuilderResumeChange}
        />
      )}
    </UploadContainer>
  )
}
