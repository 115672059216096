import styled from 'styled-components'
import { hexToRgba } from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Container = styled.div<{
  bgColor: string
}>`
  width: 268px;
  height: 336px;
  background: ${props => props.bgColor};
  border-radius: 16px;
  position: relative;
  cursor: pointer;

  ${Media.Phone`
    width: 242px;
    height: 321px;
  `}
`

export const VisualWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 212px;
  flex-shrink: 0;
  border-radius: 16px 16px 0 0;
`

export const VisualGradient = styled.div<{ bgColor: string }>`
  position: absolute;
  top: 90%;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${props => `
    linear-gradient(
      to bottom,
      ${hexToRgba(props.bgColor, 0)} 0%,
      ${props.bgColor} 100%)
    `};
`

export const ContentContainer = styled.div`
  width: 85%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const Title = styled.p<{ color: string }>`
  position: absolute;
  left: 20px;
  top: 67.26%;
  bottom: 24.4%;
  color: ${props => props.color};
  ${FontWeights.Medium};
  ${Typography.Subhead};
  margin-bottom: 2px;

  ${Media.Phone`
    left: 20px;
    top: 68.85%;
    bottom: 24.3%;
  `}
`

export const SubTitle = styled.p<{
  color: string
  isMouseOver: boolean
  reduceLineHeight?: boolean
}>`
  position: absolute;
  left: 7.46%;
  right: 22.39%;
  top: 76.19%;
  bottom: 5.95%;
  ${FontWeights.Regular};
  ${Typography.Caption};
  color: ${props => props.color};
  opacity: ${props => (props.isMouseOver ? 1 : 0.6)};
  margin-top: 0px;

  ${Media.Phone`
    left: 8.26%;
    right: 23.14%;
    top: 76.95%;
    bottom: 6.23%;
  `}
  ${props => (props.reduceLineHeight ? 'line-height: 1;' : null)};
`

export const LinkContainer = styled.div<{ bgColor: string }>`
  position: absolute;
  width: 32px;
  height: 32px;
  left: 216px;
  top: 284px;
  background: ${props => props.bgColor};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${Media.Phone`
    left: 190px;
    top: 269px;
  `}
`
