import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Shadows from 'builder/styles/shadows'

interface CircleButtonOptions {
  direction?: 'left' | 'right'
  size?: number
}

export const Container = styled.div`
  width: 100%;
  position: relative;
`

export const SwiperContainer = styled.div`
  & .swiper-container {
    position: relative;
    overflow: hidden;
  }

  & .swiper-wrapper {
    display: flex;
    position: relative;
    transition-property: transform;
  }

  & .swiper-button-disabled {
    display: none;
  }
`

export const ServicesButton = styled.button<CircleButtonOptions>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  width: ${props => props.size || 40}px;
  height: ${props => props.size || 40}px;
  border: none;
  border-radius: 50%;
  background-color: ${Colors.Neutral5};
  color: ${Colors.Neutral40};
  transition: background-color 0.3s ease;
  ${Shadows.light.low};
  color: ${Colors.Neutral90};

  &:hover {
    background-color: ${Colors.Neutral20};
  }

  ${props =>
    props.direction === 'left' &&
    css`
      transform: rotate(180deg);
    `}

  position: absolute;
  z-index: 2;
  top: calc(50%);

  ${Media.Phone`
      display: none;
    `}
`

export const ServicesLeftButton = styled(ServicesButton).attrs({
  direction: 'right',
})`
  right: -12px;
`

export const ServicesRightButton = styled(ServicesButton).attrs({
  direction: 'left',
})`
  left: -20px;
`

export const NavigationButtons = styled.div`
  width: 100%;

  ${Media.Tablet`
    display: none;
  `}
`
