import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { DocumentTypes } from 'builder/modules/constants'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import {
  selectors as critiqueSelectors,
  ResumeCritiqueStatuses,
} from 'builder/modules/resumeCritiqueReview'
import { actions as panelActions, selectors as panelSelectors } from 'builder/modules/panel'
import { useCareerTasks } from 'builder/components/CareerGoalCard'
import * as Styles from './styles'

const tickMarkImage = require('images/Career-foundation/Status_Offer.png')
const bannerImage = require('images/Career-foundation/Logo.gif')
const mobileVersionBannerImage = require('images/Career-foundation/Mobile_Logo.gif')
const emptyResumeImage = require('images/Career-foundation/empty_resume.svg')

const CritiqueReviewBanner = () => {
  const critiqueStatus = useTypedSelector(critiqueSelectors.triggeredCritiqueStatus)

  const resumeID = useTypedSelector(panelSelectors.tunerResumeId)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isPhone } = useMediaQueries()

  const [tunerResumeId, setTunerResumeId] = useState<number | null>(null)
  const [banner, setBanner] = useState(Boolean)

  const reviewingStatus =
    critiqueStatus.status === ResumeCritiqueStatuses.failed ||
    critiqueStatus.status === ResumeCritiqueStatuses.pending

  const viewMyResume = () => {
    if (!resumeID) {
      dispatch(panelActions.createDocument({ type: DocumentTypes.resume }))
    } else {
      navigate(`/resumes/${resumeID}/edit`)
    }
  }

  useEffect(() => {
    setTunerResumeId(resumeID)
    setBanner(true)
  }, [resumeID])

  const { careerTasks } = useCareerTasks()
  const { visual } = careerTasks[0]

  return (
    <Styles.Container>
      <Styles.BannerLeftContent>
        <Styles.CritiqueTitle isResume={tunerResumeId}>
          {tunerResumeId ? 'Your resume is ready!' : 'Welcome to Career.io'}
        </Styles.CritiqueTitle>
        <Styles.CritiqueText isResume={tunerResumeId}>
          {tunerResumeId
            ? 'We parsed your resume to the resume builder. Choose one of our expertly designed resume templates to wow employers!'
            : 'We’re uploading your resume and preparing a review. You’ll be able to explore new insights to improve your resume in a minute.'}
        </Styles.CritiqueText>
        {tunerResumeId && (
          <Styles.ViewMyResumeButton onClick={viewMyResume}>
            View My Resume
          </Styles.ViewMyResumeButton>
        )}
      </Styles.BannerLeftContent>
      <Styles.BannerRightContent>
        {banner ? (
          <>
            <Styles.LoaderContainer>
              <Styles.PrefillLoader checked={!tunerResumeId}>
                {!tunerResumeId ? (
                  <Styles.Spinner />
                ) : (
                  <Styles.TickMarkContainer src={tickMarkImage} />
                )}
                {!tunerResumeId ? 'Prefilling' : 'Done'}
              </Styles.PrefillLoader>
              <Styles.ReviewLoader status={reviewingStatus}>
                {reviewingStatus ? (
                  <Styles.Spinner />
                ) : (
                  <Styles.TickMarkContainer src={tickMarkImage} />
                )}
                {reviewingStatus ? 'Reviewing' : 'Done'}
              </Styles.ReviewLoader>
            </Styles.LoaderContainer>
            <Styles.CardContainer>
              {tunerResumeId ? (
                <Styles.ResumePreviewContainer>{visual}</Styles.ResumePreviewContainer>
              ) : (
                <Styles.ResumeContainer src={emptyResumeImage} />
              )}
              <Styles.ResumeGradient />
            </Styles.CardContainer>
          </>
        ) : isPhone ? (
          <Styles.mobileLogoContainer src={mobileVersionBannerImage} />
        ) : (
          <Styles.logoContainer src={bannerImage} />
        )}
      </Styles.BannerRightContent>
    </Styles.Container>
  )
}

export default CritiqueReviewBanner
