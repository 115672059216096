import styled from 'styled-components'
import Button, { ButtonSize } from 'builder/components/Button'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  width: 734px;
  display: flex;
  margin: 16px 0;
  border-radius: var(--Spacing-2XS, 8px);
  background: ${Colors.Blue10};
`

export const InfoContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`

export const TextContainer = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
`

export const Header = styled.p`
  ${Typography.Body}
  ${FontWeights.Medium}

  color: ${Colors.Blue80};
  text-align: start;
`

export const Subheader = styled.p`
  ${Typography.Caption}
  ${FontWeights.Regular}
  
  color: ${Colors.Blue80};
  text-align: start;
`

export const ActionsContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  margin: 0 26px 0 32px;
`

export const ActionButton = styled(Button).attrs({ size: ButtonSize.small })`
  width: fit-content;
`

export const ImageContainer = styled.div`
  display: flex;
`

export const Image = styled.img`
  height: 112px;
  object-fit: cover;
  border-radius: var(--Spacing-2XS, 8px);
`
