import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  padding-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid ${Colors.Neutral15};
`

export const Text = styled.div`
  ${Typography.Subhead};
  ${FontWeights.DemiBold};
  margin-bottom: 8px;
`

export const SubText = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
  margin-bottom: 16px;
`
