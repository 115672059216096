import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular};
  position: absolute;
  display: inline-flex;
  align-items: flex-start;
  border-radius: 8px;
  box-shadow: 0px 8px 20px -4px rgba(15, 20, 30, 0.24), 0px 2px 4px -1px rgba(15, 20, 30, 0.16);
  background-color: ${Colors.Neutral90};
  color: ${Colors.White};
  height: 48px;
  display: inline-flex;
  overflow: hidden;
  z-index: 2;
`

export const Text = styled.div<{ $enabledHover?: boolean }>`
  padding: var(--XS, 12px) var(--S, 16px);
  height: 100%;
  ${({ $enabledHover }) =>
    $enabledHover &&
    Media.Hover`
    &:hover  {
      background: ${Colors.Neutral80};
      cursor: pointer;
    }
  `}
`

export const Divider = styled.div`
  width: 1px;
  height: var(--XL, 48px);
  background: ${Colors.Neutral70};
`
