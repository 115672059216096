import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { Icon20, Icon24 } from 'builder/components/Icon'

export const NotificationParentContainer = styled.div`
  margin: 0 auto;
  z-index: 100;
`

export const NotificationContainer = styled.div`
  display: flex;
  position: fixed;
  bottom: 10px;
  width: 100%;
  left: 0;
  justify-content: center;
`

export const NotificationContent = styled.div`
  border-radius: 8px;
  padding: 8px 12px 8px 8px;
  background-color: ${Colors.Neutral100};
  color: ${Colors.White};
  font-size: 16px;
  display: flex;
  gap: 5px;
  align-items: center;
`

export const TickIcon = styled(Icon20.Tick)`
  width: 20px;
  height: 20px;
  color: ${Colors.Green40};
`

export const CircleErrorIcon = styled(Icon24.CircleError)``
