import { useCallback } from 'react'
import { Action } from './types'
import {
  Item,
  ItemContent,
  ItemIcon,
  ItemText,
  ItemBadge,
  ItemDescription,
  ItemMark,
} from './styles'

type ItemProps = Action & { onAfterClick: () => void }

export const ActionMenuItem = ({
  icon,
  text,
  description,
  badge,
  marked,
  divider = false,
  onClick = () => {},
  onAfterClick = () => {},
  rawIcon,
}: ItemProps) => {
  const handleClick = useCallback(() => {
    onClick()
    onAfterClick()
  }, [onClick, onAfterClick])

  return (
    <Item hasDivider={!!divider} onClick={handleClick}>
      <ItemContent>
        <ItemIcon rawIcon={rawIcon}>{icon}</ItemIcon>
        <ItemText>
          {text}
          {badge && <ItemBadge>{badge}</ItemBadge>}
          {marked && <ItemMark />}
        </ItemText>
      </ItemContent>

      {description && <ItemDescription>{description}</ItemDescription>}
    </Item>
  )
}
