import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/careerPath2'
import Loader from '../Loader'
import { Header, Container } from './styles'
import Questions from './components'

const FrequentQuestions = (): JSX.Element => {
  const { faq } = useTypedSelector(selectors.careerPagesJobDetails)
  const isExploreCareers = useTypedSelector(selectors.isExploreCareers)
  const selectedJobTitle = useTypedSelector(selectors.selectedJobTitle)
  const isLoading = useTypedSelector(selectors.isCareerPagesJobDetailsLoading)
  const exploreCareersOccupation = useTypedSelector(selectors.exploreCareersOccupation)

  const label = isExploreCareers ? 'explore_careers' : 'career_path2'
  const jobTitle = isExploreCareers ? exploreCareersOccupation : selectedJobTitle

  return (
    <Container>
      <Header>Frequently Asked Questions</Header>
      {isLoading ? <Loader /> : <Questions questions={faq} jobTitle={jobTitle} label={label} />}
    </Container>
  )
}

export default FrequentQuestions
