import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { BAR_HEIGHT, Z_INDEX } from './constants'

export const Container = styled.header<{ isTranslucent: boolean }>`
  height: ${p => (p.isTranslucent ? 0 : BAR_HEIGHT)}px;
`

export const Border = styled.div<{ isVisible: boolean }>`
  position: fixed;
  z-index: ${Z_INDEX - 2};
  left: 0;
  top: ${BAR_HEIGHT}px;
  width: 100vw;
  height: 1px;
  background-color: ${Colors.Neutral90};
  opacity: ${props => (props.isVisible ? 0.08 : 0)};
  will-change: opacity;
  transition: opacity 0.25s;
`

export const TRxCIOBannerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0;
`

export const BannerImageTRxCio = styled.img``
