import styled, { css } from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'

export const Container = styled.div<{ isExpanded: boolean }>`
  width: 100%;
  padding: 24px;
  border-radius: 8px;
  background-color: ${Colors.Neutral5};
  position: relative;
  height: 150px;
  will-change: height;
  transition: height 0.3s ease-out;
  overflow: hidden;

  ${Media.Phone`
    padding: 20px;
    height: 125px;
  `}

  ${props =>
    props.isExpanded &&
    css`
      height: 292px;

      ${Media.Phone`
        height: 335px;
      `}
    `}
`
