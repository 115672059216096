import styled from 'styled-components'
import { rotation } from 'builder/styles/keyframes'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Title = styled.div<{ isPending: boolean }>`
  ${Typography.Subhead};
  ${FontWeights.Regular};
  margin-bottom: ${props => props.isPending && 26}px;
`

export const Text = styled.div`
  margin-top: 5px;
  ${Typography.Caption};
  ${FontWeights.Regular};
`

export const Spinner = styled.img`
  width: var(--M, 24px);
  height: var(--M, 24px);
  flex-shrink: 0;
  animation: ${rotation} 0.5s infinite linear;
`

export const ButtonContainer = styled.div`
  width: 100%;
  gap: 8px;
  display: flex;
  margin-top: 16px;
`
