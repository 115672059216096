import { useMutation } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { baseClient } from 'builder/modules/apiClient'
import { actions } from 'builder/modules/interview/interviewModule'
import { Interview } from 'builder/modules/interview/types'
import { queryClient } from 'builder/components/Providers/Providers'

export const useCreateInterviewWithNewQuestions = () => {
  const dispatch = useDispatch()
  return useMutation(
    ['useCreateInterviewWithNewQuestion'],
    async (oldInterviewId: number): Promise<Interview> => {
      const res = await baseClient.post(
        `/interview_prep/interviews/${oldInterviewId}/create_from_interview_with_new_questions`,
      )
      return res.data
    },
    {
      onSuccess: res => {
        dispatch(actions.setInterviewId(res.id))
        queryClient.invalidateQueries(['useInterviews'])
        queryClient.invalidateQueries(['useInterview'])
        queryClient.invalidateQueries(['useDailySuggestions'])
      },
    },
  )
}
