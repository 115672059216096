import { Optional } from 'packages/types'
import { formatDate, parseDate } from 'builder/utils/formatDate'
import { i18n } from 'builder/utils/i18n'
import { getMonths } from './months'

export const guessMonth = (value: string) => {
  if (!value) return null
  const matchedBySubstring = getMonths().find(el => {
    if (value.length <= 3)
      return value.toLowerCase() === el.name.toLowerCase().substr(0, value.length)

    return value.toLowerCase().includes(el.name.toLowerCase())
  })
  if (matchedBySubstring) return matchedBySubstring.number

  const matchedByNumbers = value.match(/(0\d|1[012]|\d)\s?[\s/,]?\s?/)
  if (matchedByNumbers) {
    return +matchedByNumbers[1]
  }

  return null
}

export const guessYear = (value: string): number => {
  const currentYear = new Date().getFullYear()
  if (!value) return currentYear
  const matchedFullYear = value.match(/(19|20)\d\d/)
  if (matchedFullYear) return +matchedFullYear[0]

  const matchedShortYear = value.match(/.+\s?[\s/,]+\s?(\d{2})/)
  if (matchedShortYear) {
    if (+matchedShortYear[1] < (currentYear % 100) + 10) {
      return +('20' + matchedShortYear[1])
    }
    return +('19' + matchedShortYear[1])
  }

  return currentYear
}

export const convertToInternal = (
  dateString: Optional<string>,
  options: { isDatePresent?: boolean; isMonthHidden?: boolean } = {},
) => {
  const { isDatePresent = false, isMonthHidden = false } = options
  let normalizedDate: Date

  if (dateString) {
    const parsedDate = parseDate(dateString)
    if (typeof parsedDate === 'number') {
      normalizedDate = new Date(parsedDate)
    } else {
      normalizedDate = parsedDate
    }
  } else {
    normalizedDate = new Date()
  }

  const year = normalizedDate.getFullYear()
  const month = dateString && !isMonthHidden ? normalizedDate.getMonth() + 1 : null

  let value
  if (isDatePresent) {
    value = i18n.t('builder.date_range_picker.present')
  } else if (!dateString) {
    value = ''
  } else if (isMonthHidden) {
    value = `${year}`
  } else {
    value = `${getMonths().find(el => el.number === month)?.name}, ${year}`
  }

  return [value, month, year] as const
}

export const convertToExternal = (
  month: number | null,
  year: number,
  options: { isDateHidden?: boolean } = {},
) => {
  const { isDateHidden } = options
  const monthNumber = month ? +month - 1 : 0
  const date = new Date(year, monthNumber, 1)
  return isDateHidden ? null : formatDate(date, 'yyyy-MM-dd')
}

export const isPresent = (value: Optional<string>) =>
  !!value &&
  ('' + value).toLowerCase() === i18n.t('builder.date_range_picker.present').toLowerCase()

export const isDateHidden = (value: Optional<string>) => !value

export const isMonthHidden = (value: Optional<string>) =>
  !!value && !!('' + value).match(/^\s*(19|20)\d{2}\s*$/)

export const convertToFieldValue = (
  month: number | null,
  year: number,
  options: { prevValue?: Optional<string>; isDatePresent?: boolean } = {},
) => {
  const { prevValue, isDatePresent = false } = options
  if ('prevValue' in options && isDateHidden(prevValue)) return prevValue ?? null
  if (isDatePresent || isPresent(prevValue)) {
    return i18n.t('builder.date_range_picker.present')
  }
  if (!month) return `${year}`
  return `${getMonths().find(el => el.number === month)?.name}, ${year}`
}

export const isYearTooLate = (year: number, maxYear: Optional<number>) => {
  return !!maxYear && year > maxYear
}

export const isYearTooEarly = (year: number, minYear: Optional<number>) => {
  return !!minYear && year < minYear
}

export const isDateTooLate = (
  month: number | null,
  year: number,
  maxMonth: Optional<number>,
  maxYear: Optional<number>,
) => {
  const isMonthTooLate = !!maxYear && !!maxMonth && !!month && maxYear === year && month > maxMonth

  return isYearTooLate(year, maxYear) || isMonthTooLate
}

export const isDateTooEarly = (
  month: number | null,
  year: number,
  minMonth: Optional<number>,
  minYear: Optional<number>,
) => {
  const isMonthTooEarly = !!minYear && !!minMonth && !!month && minYear === year && month < minMonth

  return isYearTooEarly(year, minYear) || isMonthTooEarly
}
