import { useQuery } from '@tanstack/react-query'
import { baseClient } from 'builder/modules/apiClient'
import { Interview } from 'builder/modules/interview/types'
import { POLLING_REFRESH_INTERVAL_IN_MILLIS } from '../constants'
import { getNumberOfQuestionOfInterview } from '../utils/getNumberOfQuestionOfInterview'
interface Props {
  interviewId?: number | string
}

export const useInterview = ({ interviewId }: Props) => {
  return useQuery<Interview | undefined>(
    ['useInterview', interviewId],
    async () => {
      const response = await baseClient.get<Interview>(`/interview_prep/interviews/${interviewId}`)
      return response.data
    },
    {
      enabled: !!interviewId,
      refetchInterval: (data?: Interview) => {
        if (!data) {
          return POLLING_REFRESH_INTERVAL_IN_MILLIS
        }
        const maxQuestions = getNumberOfQuestionOfInterview(data)
        if (data.questions.length === maxQuestions) {
          return false
        }
        return POLLING_REFRESH_INTERVAL_IN_MILLIS
      },
      select: (data?: Interview) => {
        if (!data) {
          return undefined
        }
        const maxQuestions = getNumberOfQuestionOfInterview(data)
        if (data.questions.length === maxQuestions) {
          return data
        }
        return undefined
      },
      useErrorBoundary: false,
    },
  )
}
