import styled from 'styled-components'
import ReactAvatarEditor from 'react-avatar-editor'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import { rotation } from 'builder/styles/keyframes'
import BackgroundPreview from '../BackgroundPreview'
import { fadeInStyles } from '../styles'

export const CropperContainer = styled.div`
  position: relative;
  overflow: hidden;
  flex-grow: 1;

  &:after,
  &:before {
    content: '';
    position: absolute;
    z-index: 3;
    top: 0;
    bottom: 0;
    width: 48px;
    pointer-events: none;
    background: linear-gradient(
      to right,
      ${Colors.Neutral100} 0%,
      ${hexToRgba(Colors.Neutral100, 0)} 100%
    );
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
    transform: scaleX(-1);
  }
`

export const CropperPreviewContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 60px;

  &:after,
  &:before {
    content: '';
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    height: 120px;
    pointer-events: none;
  }

  &:before {
    top: 0;
    background: linear-gradient(
      to bottom,
      ${Colors.Neutral100} 0%,
      ${hexToRgba(Colors.Neutral100, 0)} 100%
    );
  }

  &:after {
    bottom: 0;
    background: linear-gradient(
      to bottom,
      ${hexToRgba(Colors.Neutral100, 0)},
      ${Colors.Neutral100} 65%,
      ${Colors.Neutral100} 100%
    );
  }
`

export const CropperTitle = styled.div`
  position: absolute;
  z-index: 3;
  left: 0;
  top: 64px;
  right: 0;
  ${Typography.Caption};
  color: ${Colors.Neutral60};
  text-align: center;
  pointer-events: none;
  ${fadeInStyles};
`

export const CropperAvatarEditor = styled(ReactAvatarEditor)`
  position: absolute;
  left: 0;
  top: 0;
`

export const CropperBackgroundPreview = styled(BackgroundPreview)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  ${fadeInStyles};
`

export const CropperPreviewOverlay = styled.div<{ size: number }>`
  position: absolute;
  top: 50%;
  left: 50%;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  transform: translate(-50%, -50%);
`

export const CropperRulers = styled(CropperPreviewOverlay)`
  border: 1px solid ${Colors.White};
  border-radius: 3px;
  opacity: 0.24;
  pointer-events: none;

  &:after,
  &:before {
    content: '';
    position: absolute;
    border: 0px solid ${Colors.White};
    ${fadeInStyles};
  }

  &:after {
    left: 0;
    right: 0;
    top: 33.3%;
    bottom: 33.3%;
    border-top-width: 1px;
    border-bottom-width: 1px;
  }

  &:before {
    top: 0;
    bottom: 0;
    left: 33.3%;
    right: 33.3%;
    border-left-width: 1px;
    border-right-width: 1px;
  }
`

export const CropperPreviewLoader = styled(CropperPreviewOverlay)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.Neutral90};

  &:after {
    content: '';
    display: block;
    width: 32px;
    height: 32px;
    border: 3px solid ${Colors.Neutral60};
    border-bottom-color: ${Colors.Neutral80};
    border-radius: 50%;
    animation: ${rotation} 0.5s infinite linear;
  }
`

const RotateButton = styled.div`
  position: absolute;
  z-index: 4;
  bottom: 64px;
  opacity: 0.64;
  transition: opacity 0.15s;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`

export const RotateButtonContent = styled.div`
  ${fadeInStyles};
`

export const RotateButtonLabel = styled.div`
  color: ${Colors.Neutral40};
  ${Typography.Caption};
  font-weight: 600;
`

export const RotateButtonIcon = styled.div`
  display: block;
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-image: url(${require('images/builder/avatar-editor/rotate-left.svg')});
`

export const RotateLeftButton = styled(RotateButton)`
  left: 48px;
`

export const RotateRightButton = styled(RotateButton)`
  right: 48px;

  & ${RotateButtonIcon} {
    transform: scaleX(-1);
  }
`
