import { useState } from 'react'
import { addDays, isAfter } from 'date-fns'
import { trackInternalEvent } from '@rio/tracking'

import { EXTENSION_URL } from 'builder/modules/jobTracking'
import useWebsiteHost from 'builder/components/CIO-Dasboard-2.0/hooks/useWebsiteHost'

const CHROME_EXTENSION_JOB_DETAILS_KEY = 'CHROME_EXTENSION_JOB_DETAILS'
const CHROME_EXTENSION_EMPTY_STATE_KEY = 'CHROME_EXTENSION_EMPTY_STATE'

type BannerProps = {
  type: 'job_listing' | 'empty_state' | 'job_card'
}

export const useChromeExtensionBanner = ({ type }: BannerProps) => {
  const { getHost } = useWebsiteHost()

  const getKey = (type: BannerProps['type']) => {
    if (type === 'job_listing') {
      return CHROME_EXTENSION_JOB_DETAILS_KEY
    } else if (type === 'empty_state') {
      return CHROME_EXTENSION_EMPTY_STATE_KEY
    } else {
      return ''
    }
  }

  const shouldShowBanner = () => {
    const time = localStorage.getItem(getKey(type))
    if (!time) return true

    const oneWeekAfter = addDays(new Date(Number(time)), 7)
    return isAfter(new Date(), oneWeekAfter)
  }
  const [showBanner, setShowBanner] = useState(shouldShowBanner())

  const hideBanner = () => {
    trackInternalEvent('close_banner_chrome', {
      host: getHost,
      label: 'job_search',
      source: type,
    })
    localStorage.setItem(getKey(type), String(+new Date()))
    setShowBanner(false)
  }

  const getExtension = () => {
    trackInternalEvent('click_banner_cta_chrome', {
      host: getHost,
      label: 'job_search',
      source: type,
    })
    window.open(EXTENSION_URL, '_blank')
  }

  return { showBanner, hideBanner, getExtension }
}
