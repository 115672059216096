import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'

import { Store } from 'builder/modules/store'
import { FetchStatuses } from 'builder/modules/constants'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { actions, selectors } from 'builder/modules/resumeDistribution'
import { actions as jobSearchActions } from 'builder/modules/jobSearch'

import {
  ActionButton,
  AnimationBox,
  Animation,
  Container,
  IconContainer,
  InfoBox,
  InfoHeadContiner,
  InfoHeader,
  InfoItem,
  InfoItems,
  InfoItemSpinner,
  InfoItemText,
  InfoSubHeader,
  AnimationShadow,
  Video,
} from './styles'
import { RecruiterTable } from './RecruiterTable/RecruiterTable'
import { ErrorState } from './ErrorState/ErrorState'
import { Candidate } from './Candidate/Candidate'
import RdVideo from './assets/rd-video.webm'

const TRANSLATION = 'builder.auto_apply.resume_distribution.rd_completed'

export const ResumeDistributionPreviewView = () => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isPhone, isTablet } = useMediaQueries()

  const candidate = useTypedSelector(selectors.candidate)
  const distributionDates = useTypedSelector(selectors.distributionDates)
  const recruitersCount = useTypedSelector(selectors.recruitersCount)
  const recruiters = useTypedSelector((state: Store) =>
    selectors.recruiters(state, distributionDates?.data[0]?.unixFormat),
  )

  const candidatePostStatus = useTypedSelector(selectors.candidatePostStatus)
  const distributionDatesFetchStatus = useTypedSelector(selectors.distributionDatesFetchStatus)
  const recruitersFetchStatus = useTypedSelector((state: Store) =>
    selectors.recruitersFetchStatus(state, distributionDates?.data[0]?.unixFormat),
  )

  const isLoading = () => {
    return !candidate || !distributionDates?.data?.length || !recruiters?.data?.length
  }

  const hasError = () => {
    return (
      candidatePostStatus === FetchStatuses.failed ||
      recruitersFetchStatus === FetchStatuses.failed ||
      distributionDatesFetchStatus === FetchStatuses.failed
    )
  }

  const translation = `${TRANSLATION}.${isLoading() ? 'loading' : 'loaded'}`

  const onClick = () => {
    dispatch(jobSearchActions.startAutoApplyBubble({ enableFirstPage: true }))
    navigate('/job-search?view=recommendation')
  }

  useEffect(() => {
    if (candidatePostStatus === FetchStatuses.loaded) {
      dispatch(actions.fetchRecruiters({ count: true }))
      dispatch(actions.fetchCandidate())
      dispatch(actions.fetchDistributionDates())
    }
  }, [dispatch, candidatePostStatus])

  useEffect(() => {
    if (distributionDates?.data?.length) {
      dispatch(actions.fetchRecruiters({ date: distributionDates.data[0].unixFormat }))
    }
  }, [dispatch, distributionDates, recruitersCount])

  useEffect(() => {
    trackInternalEvent('enter_rd_success_screen', {
      label: 'total_job_search_solution',
    })
  }, [])

  if (hasError()) {
    return <ErrorState />
  }

  if (!candidate || isLoading()) {
    return (
      <Container>
        <InfoBox>
          <InfoHeadContiner>
            <InfoHeader>{i18n.t(`${translation}.header`)}</InfoHeader>
            <InfoSubHeader>{i18n.t(`${translation}.sub_header`)}</InfoSubHeader>
          </InfoHeadContiner>
          <InfoItems>
            <InfoItem>
              <IconContainer>
                <InfoItemSpinner />
              </IconContainer>
              <InfoItemText>{i18n.t(`${translation}.items.item_1`)}</InfoItemText>
            </InfoItem>
            <InfoItem>
              <IconContainer>
                <InfoItemSpinner />
              </IconContainer>
              <InfoItemText>{i18n.t(`${translation}.items.item_2`)}</InfoItemText>
            </InfoItem>
          </InfoItems>
        </InfoBox>
        <Animation>
          <Video src={RdVideo} muted autoPlay playsInline loop />
        </Animation>
      </Container>
    )
  }

  return (
    <Container>
      <InfoBox>
        <InfoHeadContiner>
          <InfoHeader>
            {i18n.t(`${translation}.header`, { count: recruiters.data.length })}
          </InfoHeader>
          <InfoSubHeader>{i18n.t(`${translation}.sub_header`)}</InfoSubHeader>
        </InfoHeadContiner>

        <Candidate candidate={candidate} />
        {!isPhone && !isTablet && (
          <ActionButton onClick={onClick}>{i18n.t(`${translation}.button`)}</ActionButton>
        )}
      </InfoBox>

      <AnimationBox>
        <RecruiterTable
          distributionDates={distributionDates}
          totalRecruiters={recruiters.data.length}
        />
        <AnimationShadow />
      </AnimationBox>
      {(isPhone || isTablet) && (
        <ActionButton onClick={onClick}>{i18n.t(`${translation}.button`)}</ActionButton>
      )}
    </Container>
  )
}
