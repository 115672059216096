import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { DocumentTypes, FORMATS } from 'builder/modules/constants'

import { trackInternalEvent, trackMarketingEvent } from '@rio/tracking'
import { bindActionToPromise } from 'builder/utils/bindActionToPromise'

import { ResumeEditorStepByStep } from 'builder/components/DocumentEditor'
import PreviewPanel, { MODES as PREVIEW_PANEL_MODES } from 'builder/components/PreviewPanel'
import { withMediaQueries } from 'builder/components/MediaQueries'

import { Container, LeftSide, FloatingDownloadPdfButton } from './styles'

import { actions, STEP_NAMES } from 'builder/modules/resumeEditor'
import { actions as renderingActions } from 'builder/modules/rendering'
import { actions as coRegActions } from 'builder/modules/coRegistration'
import { actions as generalEditorActions } from 'builder/modules/generalEditor'
import { withRouter } from 'builder/hocs/withRouter'
import { navigate } from 'builder/modules/navigate'

class ResumeEditorStepByStepView extends Component {
  static propTypes = {
    editorState: PropTypes.object.isRequired,
    resumeState: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    mediaQueries: PropTypes.object.isRequired,
    fetchResumeRequest: PropTypes.func.isRequired,
    downloadResume: PropTypes.func,
    isDownloading: PropTypes.bool,
    fetchEditorPayloadRequest: PropTypes.func.isRequired,
    updateSimpleField: PropTypes.func.isRequired,
    dispatch: PropTypes.func,
    initializeCoRegistration: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const resumeId = parseInt(this.props.params.id)
    navigate(`/resumes/${resumeId}/edit`)
    this.props.fetchResumeRequest(resumeId)
    this.props.fetchEditorPayloadRequest()
    this.props.initializeCoRegistration()
    // track the page opening
    trackInternalEvent('visit_resume_editor', { resume_id: resumeId, editor: 'wizard' })
  }

  componentDidUpdate() {
    const { resumeState } = this.props
    if (resumeState.error) navigate('/resumes')
  }

  handleSimpleFieldUpdate = (name, value, debounce) => {
    this.props.updateSimpleField(name, value, debounce)

    if (name === 'name') trackInternalEvent('rename_resume')
    if (name === 'color') trackInternalEvent('change_resume_color')
    if (name === 'locale') trackInternalEvent('change_resume_locale', { locale: value })
  }

  handleDownloadPdf = () => {
    const { downloadResume, params } = this.props
    const resumeId = parseInt(params.id)

    downloadResume({ id: resumeId, type: DocumentTypes.resume, format: FORMATS.pdf })

    // Track in Google Analytics
    trackMarketingEvent('Click Download', 'Download Resume', { eventLabel: FORMATS.pdf })
    // Track in ClickHouse
    trackInternalEvent('click_download_resume_button', { format: FORMATS.pdf, source: 'wizard' })
  }

  render() {
    const { editorState, resumeState, params, mediaQueries, isDownloading, downloadResume } =
      this.props

    const resumeId = parseInt(params.id)
    const { resume, isSyncing, currentWizardStepId } = resumeState

    return (
      <Container>
        <LeftSide>
          <ResumeEditorStepByStep
            editorState={editorState}
            resumeState={resumeState}
            updateSimpleField={this.handleSimpleFieldUpdate}
          />
        </LeftSide>

        {/*
        Unmount PreviewPanel when DocumentPreviewModal is visible. Reasons:
        - prevent render if block is invisible - reduce the load of user's device
        - prevent parallel tasks - pdfjs worker can't do more than one in parallel
        */}
        {mediaQueries.isDesktop && (
          <PreviewPanel
            type="resume"
            documentId={resumeId}
            isSyncing={isSyncing}
            onDownload={downloadResume}
            isDownloading={isDownloading}
            currentDocument={resume}
            mode={PREVIEW_PANEL_MODES.wizard}
          />
        )}

        <FloatingDownloadPdfButton
          onClick={this.handleDownloadPdf}
          isShow={currentWizardStepId !== STEP_NAMES.details}
        >
          Download PDF
        </FloatingDownloadPdfButton>
      </Container>
    )
  }
}

// ---
// Connect to data
// ---
function mapStateToProps(state) {
  return {
    isDownloading: state.rendering.isDocumentDownloading,
    editorState: state.generalEditor,
    resumeState: state.resumeEditor,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: dispatch,
    fetchResumeRequest: id => dispatch(actions.fetchResumeRequest({ id })),
    fetchEditorPayloadRequest: () => dispatch(generalEditorActions.fetchEditorPayloadRequest()),
    updateSimpleField: (name, value, debounce) =>
      dispatch(actions.updateSimpleField({ name, value, debounce })),
    initializeCoRegistration: () => dispatch(coRegActions.initialize()),
    downloadResume: bindActionToPromise(dispatch, renderingActions.download),
  }
}

export default withMediaQueries(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(ResumeEditorStepByStepView)),
)
