import { useRef, useState, useEffect } from 'react'

interface Props {
  inputAudio: Blob[]
  inputAudioDuration: number
}

/** A component using this hook could be mounted and unmounted at times,
 * which resets all data in this hook, hence we have kept an option to
 * provide input data to this hook, which for example could come
 * from redux, hence data would be retained
 */

export const useAudioRecorder = (props?: Props) => {
  const { inputAudio, inputAudioDuration } = props as Props
  const mediaRecorderRef = useRef<MediaRecorder | null>(null)
  const durationInterval = useRef<NodeJS.Timeout | null>(null)
  const [duration, setDuration] = useState(
    inputAudioDuration && inputAudioDuration > 0 ? inputAudioDuration : 0,
  )
  const [audioChunks, setAudioChunks] = useState(
    inputAudio && inputAudio.length > 0 ? [...inputAudio] : [],
  )
  const [errorWhileRecording, setErrorWhileRecording] = useState('')
  const [isRecording, setIsRecording] = useState(false)

  const onClearRecording = () => {
    if (isRecording) return

    mediaRecorderRef.current = null
    durationInterval.current = null
    setDuration(0)
    setAudioChunks([])
    setIsRecording(false)
  }

  const onStartRecording = async () => {
    if (isRecording) return

    try {
      onClearRecording()
      // This triggers the permission for microphone access
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      // This checks for the microphone permission
      const permission = await navigator.permissions.query({ name: 'microphone' as PermissionName })
      if (permission.state === 'granted') {
        mediaRecorderRef.current = new MediaRecorder(stream)
        mediaRecorderRef.current.ondataavailable = e => {
          if (e.data.size > 0) {
            // When audio data is received, we add it to the chunks array
            setAudioChunks(prevChunks => [...prevChunks, e.data])
          }
        }
        mediaRecorderRef.current.start()
        setIsRecording(true)
        durationInterval.current = setInterval(() => setDuration(duration => duration + 1), 1000)
      }
    } catch (error) {
      setErrorWhileRecording(`${error}`)
    }
  }

  const onStopRecording = () => {
    if (!isRecording) return

    if (mediaRecorderRef.current) {
      // This releases the microphone resources after the use
      mediaRecorderRef.current.stream.getTracks().forEach(track => {
        track.stop()
      })
      mediaRecorderRef.current.stop()
      setIsRecording(false)
      clearInterval(durationInterval.current as NodeJS.Timeout)
      durationInterval.current = null
    }
  }

  useEffect(() => {
    if (inputAudioDuration && inputAudioDuration > 0) {
      setDuration(inputAudioDuration)
    }
  }, [inputAudioDuration])

  useEffect(() => {
    if (inputAudio && inputAudio.length > 0) {
      setAudioChunks([...inputAudio])
    }
  }, [inputAudio])

  return {
    audioChunks,
    duration,
    errorWhileRecording,
    isRecording,
    onClearRecording,
    onStartRecording,
    onStopRecording,
  }
}
