import { createAction, createReducer } from '@reduxjs/toolkit'
import { FetchStatuses } from 'builder/modules/constants'
import { Store } from 'builder/modules/store'
import { getDegreeKeyByName } from 'builder/components/JobOfferProComDetails/eduDegreeOptions'
import { getExperienceKeyByName } from 'builder/components/JobOfferProComDetails/yearsOfExperienceOptions'
import {
  OfferAnalyzerStore,
  AnalyzedOfferInterface,
  ErrorObject,
  OfferAnalyzerFields,
  SubmitUserData,
  IFetchOfferSuggestedRequestData,
} from './types'

// ---
// Action creators
// ---
export const actions = {
  setPrevSlide: createAction('builder/OFFER_ANALYZER_SET_PREV_STEP'),
  setNextSlide: createAction('builder/OFFER_ANALYZER_SET_CURRENT_STEP'),
  updateFields: createAction<Partial<OfferAnalyzerFields>>('builder/OFFER_ANALYZER_UPDATE_FIELDS'),
  fetchOfferSuggested: createAction<SubmitUserData>('builder/OFFER_ANALYZER_FETCH_OFFER_SUGGESTED'),
  fetchOfferSuggestedForUser: createAction<IFetchOfferSuggestedRequestData>(
    'builder/OFFER_ANALYZER_FETCH_OFFER_SUGGESTED',
  ),
  setAnalyzedOfferDetails: createAction<AnalyzedOfferInterface>(
    'builder/OFFER_ANALYZER_SET_SUGGESTED_DETAILS',
  ),
  setErrors: createAction<ErrorObject>('builder/OFFER_ANALYZER_SET_ERRORS'),
  setLastFetchedLocations: createAction<string[]>(
    'builder/OFFER_ANALYZER_SET_LAST_FETCHED_LOCATIONS',
  ),
  setLastFetchedJobTitles: createAction<string[]>(
    'builder/OFFER_ANALYZER_SET_LAST_FETCHED_JOB_TITLES',
  ),
  resetOfferAnalyzer: createAction('builder/OFFER_ANALYZER_RESET_DATA'),
  setFetchAnalyzerStatus: createAction<FetchStatuses>('builder/OFFER_ANALYZER_FETCH_STATUS'),
}

// ---
// Selectors
// ---
export const selectors = {
  prevSlide: (state: Store) => state.offerAnalyzer.prevSlide,
  currentSlide: (state: Store) => state.offerAnalyzer.currentSlide,
  fields: (state: Store) => state.offerAnalyzer.fields,
  errors: (state: Store) => state.offerAnalyzer.errors,
  lastFetchedLocations: (state: Store) => state.offerAnalyzer.lastFetchedLocations,
  lastFetchedJobTitles: (state: Store) => state.offerAnalyzer.lastFetchedJobTitles,
  offerAnalyzerUserData: (state: Store) => {
    const {
      companyName,
      location,
      yearsOfExperience,
      educationalQualification,
      baseSalary,
      costToCompany,
      additionalIncentives,
      equity,
      hasHealthCare,
      hasPto,
      hasParentalLeave,
      isNonCompetitive,
      noticeTerms,
    } = state.offerAnalyzer.fields
    return {
      analyzer_details: {
        offer_details: {
          company_name: companyName,
          location: location,
        },
        professional_details: {
          years_of_experience: getExperienceKeyByName(yearsOfExperience),
          educational_qualification: getDegreeKeyByName(educationalQualification),
        },
        compensation_details: {
          base_salary: parseFloat(baseSalary.replace(/\$|,/g, '')),
          cost_to_company: costToCompany,
          additional_incentives: parseFloat(additionalIncentives.replace(/\$|,/g, '')),
          equity: equity ? 'yes' : 'no',
        },
        benefits: {
          health_care: hasHealthCare ? 'yes' : 'no',
          paid_time_off: hasPto ? 'yes' : 'no',
          parental_leave: hasParentalLeave ? 'yes' : 'no',
        },
        terms_conditions: {
          non_compete: isNonCompetitive ? 'yes' : 'no',
          notice_terms: `${noticeTerms} days`,
        },
      },
    }
  },
  offerAnalyzerRequestData: (state: Store) => {
    const { jobTitle, location, yearsOfExperience, educationalQualification, baseSalary } =
      state.offerAnalyzer.fields
    return {
      analyzer_details: {
        title: jobTitle,
        education: getDegreeKeyByName(educationalQualification),
        exp: getExperienceKeyByName(yearsOfExperience),
        Location: location,
        base_salary: parseFloat(baseSalary.replace(/\$|,/g, '')),
      },
    }
  },
  analyzedOfferDetails: (state: Store) => state.offerAnalyzer.analyzedOfferDetails,
  isAnalyzerLoading: (state: Store) =>
    state.offerAnalyzer.fetchAnalyzerStatus === FetchStatuses.loading,
  isAnalyzedDataLoaded: (state: Store) =>
    state.offerAnalyzer.fetchAnalyzerStatus === FetchStatuses.loaded,
}

export const initialState: OfferAnalyzerStore = {
  prevSlide: 1,
  currentSlide: 1,
  fields: {
    jobTitle: '',
    companyName: '',
    location: '',
    yearsOfExperience: '',
    educationalQualification: '',
    baseSalary: '',
    costToCompany: 0,
    additionalIncentives: '',
    equity: '',
    hasHealthCare: false,
    hasPto: false,
    hasParentalLeave: false,
    isNonCompetitive: false,
    noticeTerms: '',
  },
  errors: {
    titleError: '',
    companyError: '',
    locationError: '',
    baseSalaryError: '',
    educationalQualError: '',
    workExpError: '',
  },
  analyzedOfferDetails: {
    averageSalary: '',
    msgBelowSpeedometer: 0,
    flagMsgBelowSpeedometer: '',
    requestFor: 0,
    flagRequestFor: '',
    baseSalaryMsg: '',
    mainTitle: '',
    speedometerPercentage: '0%',
    speedometerPercentageNeedle: '0%',
  },
  fetchAnalyzerStatus: FetchStatuses.notAsked,
  lastFetchedJobTitles: [],
  lastFetchedLocations: [],
}

// ---
// Reducer
// ---
export const reducer = createReducer(initialState, reducer => {
  reducer.addCase(actions.setPrevSlide, draft => {
    if (draft.currentSlide > 0) {
      draft.prevSlide = draft.currentSlide
      draft.currentSlide = draft.currentSlide - 1
    }
  })
  reducer.addCase(actions.setNextSlide, draft => {
    if (draft.currentSlide < 3) {
      draft.prevSlide = draft.currentSlide
      draft.currentSlide = draft.currentSlide + 1
    }
  })
  reducer.addCase(actions.updateFields, (draft, action) => {
    draft.fields = {
      ...draft.fields,
      ...action.payload,
    }
  })
  reducer.addCase(actions.setAnalyzedOfferDetails, (draft, action) => {
    draft.analyzedOfferDetails = action.payload
  })
  reducer.addCase(actions.setErrors, (draft, action) => {
    draft.errors = action.payload
  })
  reducer.addCase(actions.resetOfferAnalyzer, draft => {
    draft.fields = initialState.fields
    draft.prevSlide = 1
    draft.currentSlide = 1
  })
  reducer.addCase(actions.setFetchAnalyzerStatus, (draft, action) => {
    draft.fetchAnalyzerStatus = action.payload
  })
  reducer.addCase(actions.setLastFetchedJobTitles, (draft, action) => {
    draft.lastFetchedJobTitles = action.payload
  })
  reducer.addCase(actions.setLastFetchedLocations, (draft, action) => {
    draft.lastFetchedLocations = action.payload
  })
})
