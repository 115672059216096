import styled from 'styled-components'
import Button from 'builder/components/Button'
import { Icon20 } from 'builder/components/Icon'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'

export const JOB_SUMMARY_HEIGHT = 568
export const Container = styled.div<{ $isCollapsed: boolean; $showCollapseButton: boolean }>`
  position: relative;
  ${({ $isCollapsed, $showCollapseButton }) =>
    $isCollapsed &&
    ` 
      ${$showCollapseButton && `height: ${JOB_SUMMARY_HEIGHT}px`};
      overflow: hidden;
    `}
`

export const ShowMoreContainer = styled.div<{ $isCollapsed: boolean }>`
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 64.58%);
  ${({ $isCollapsed }) => $isCollapsed && `position: absolute;`}
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 100%;
`

export const ShowMoreButton = styled(Button)`
  margin-top: 76px;
  margin-bottom: 16px;
  max-width: fit-content;
`

export const ChevronIcon = styled(Icon20.ChevronDown)<{ $up: boolean }>`
  ${({ $up }) => $up && `    transform: rotate(180deg);`}
`

export const Body = styled.div<{ isHTMLContent: boolean }>`
  ${Typography.Body}
  color: ${Colors.Neutral60};
  ${({ isHTMLContent }) => !isHTMLContent && `white-space: pre-line;`}

  b,
  strong,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${FontWeights.DemiBold}
    color: ${Colors.Neutral90};

    ${Media.Tablet`
      ${Typography.Subhead}
    `}
  }

  p {
    margin: 12px 0px;

    ${Media.Tablet`
      ${Typography.Subhead}
    `}
  }

  li {
    ${Media.Tablet`
      ${Typography.Subhead}
    `}
  }
`
