import { isValidElement, useState, useRef, useLayoutEffect, useCallback } from 'react'
import * as React from 'react'
import { TabsContainer, Tab, TabSelector } from './styles'

type Child = React.ReactElement<React.ComponentProps<typeof Tab>>

interface TabsProps extends React.HTMLAttributes<HTMLDivElement> {
  children: Child[]
  Container?: React.FunctionComponent
  [x: string]: any // rest
}

export const Tabs = ({ children, Container, ...rest }: TabsProps) => {
  const wrapper = useRef<HTMLDivElement | null>(null)
  const activeTab = useRef<HTMLDivElement | null>(null)
  const [activeTabWidth, setActiveTabWidth] = useState(0)
  const [activeTabOffset, setActiveTabOffset] = useState(0)
  let activeTabIndex = 0

  const tabs = React.Children.map(children, (child: Child, index) => {
    if (!isValidElement(child) || !child.props.isActive) return child

    activeTabIndex = index
    return React.cloneElement(child, { ...child.props, ref: activeTab })
  })

  const updateSelectorBoundaries = useCallback(() => {
    if (!activeTab.current || !wrapper.current) return

    const { left, width } = activeTab.current.getBoundingClientRect()
    const offset = left - wrapper.current.getBoundingClientRect().left
    setActiveTabOffset(offset)
    setActiveTabWidth(width)
  }, [])

  useLayoutEffect(() => {
    updateSelectorBoundaries()
  }, [activeTabIndex, updateSelectorBoundaries, tabs])

  useLayoutEffect(() => {
    window.addEventListener('load', updateSelectorBoundaries)
    window.addEventListener('resize', updateSelectorBoundaries)

    return () => {
      window.removeEventListener('load', updateSelectorBoundaries)
      window.removeEventListener('resize', updateSelectorBoundaries)
    }
  }, [updateSelectorBoundaries])

  const Cont = Container || TabsContainer

  return (
    <Cont ref={wrapper} {...rest}>
      {tabs}
      {!rest?.disableSelector && <TabSelector width={activeTabWidth} offset={activeTabOffset} />}
    </Cont>
  )
}
