import { bool } from 'aws-sdk/clients/signer'
import styled from 'styled-components'
import { A4_FACTOR } from 'builder/modules/constants'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography from 'builder/styles/typography'
import Badges from 'builder/components/Badges'
import OFFLINE_ICON_URL from 'images/builder/resume-editor/offline-sync.svg'

export const Template = styled.div<{
  isActive?: bool
}>`
  width: calc(50% - 12px);
  margin-bottom: 20px;
  cursor: ${props => (props.isActive ? 'default' : 'pointer')};

  ${Media.Laptop`
    width: 100%;
    margin-bottom: 20px;
  `}
`

export const TemplateGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${Media.Laptop`
    flex-direction: column;
    flex-wrap: nowrap;
  `}

  &:not(:last-child) {
    margin-bottom: 32px;
  }
`

export const PlanBadges = styled(Badges).attrs({ topic: 'plan' })`
  position: absolute;
  bottom: 6px;
  left: 6px;
`

export const OfflineWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: ${Colors.White};
  z-index: 2;
  border-radius: inherit;

  background-image: url(${OFFLINE_ICON_URL});
  background-position: 50% 50%;
  background-repeat: no-repeat;
`

export const TemplateName = styled.div`
  color: ${Colors.White};
  margin-bottom: 4px;
  text-align: center;
`
export const FormatBadges = styled(Badges)`
  position: absolute;
  bottom: 6px;
  right: 6px;
`

export const TemplateGroupHeader = styled.p`
  width: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 8px 16px 8px;
  color: ${Colors.Neutral50};
  ${Typography.Caption}

  &::after,
  &::before {
    content: '';
    display: block;
    flex: 1 0;
    height: 1px;
    background-color: ${Colors.Neutral60};
  }
`

export const TemplateStatus = styled.div<{ isActive?: bool }>`
  position: absolute;
  width: 40px;
  height: 40px;
  left: 50%;
  top: 50%;
  margin-left: -20px;
  margin-top: -20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.Blue50};
  color: ${Colors.White};
  border-radius: 50%;
  opacity: ${props => (props.isActive ? 1 : 0)};
`

export const TemplatePreview = styled.div<{
  image?: string
  isActive?: bool
  fitStyle?: string
}>`
  position: relative;
  width: 100%;
  border-radius: 3px;
  background-color: ${Colors.White};
  background-size: ${props => (props.fitStyle ? props.fitStyle : 'cover')};
  background-image: ${props => (props.image ? `url(${props.image})` : 'none')};

  &:before {
    content: '';
    display: block;
    padding-bottom: ${(1 / A4_FACTOR) * 100}%;
  }

  &:after {
    content: '';
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
    border-width: 4px;
    border-style: solid;
    border-color: ${Colors.Blue50};
    border-radius: 7px;
    opacity: ${props => (props.isActive ? 1 : 0)};
    transition: opacity 0.15s;
  }

  ${Media.Hover`
    ${Template}:hover &:after {
      opacity: 1;
    }
  `}
`
