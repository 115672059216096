import { memo, useEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { selectors } from 'builder/modules/careerCoaching'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { trackingInfo } from 'builder/modules/careerCoaching/constants'
import Coach from './components/Coach'
import { Container } from './styles'

const Coaches = (): JSX.Element => {
  const coaches = useTypedSelector(selectors.coaches)

  useEffect(() => {
    trackInternalEvent('enter_coach_available_list', { ...trackingInfo })
  }, [])

  return (
    <Container>
      {coaches.map(coach => (
        <Coach key={coach.id} {...{ coach }} />
      ))}
    </Container>
  )
}

export default memo(Coaches)
