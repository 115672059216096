import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { actions, selectors } from 'builder/modules/panel'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { formatDocumentPreviewUrl } from 'builder/utils/formatDocumentPreviewUrl'
import { DocumentTypes } from 'builder/modules/constants'
import createResume from '../assets/CreateResume.png'
import {
  Container,
  Image,
  Wrapper,
  VisualGradient,
  ScoreSection,
  ResumeScore,
  Title,
  OptimizeResume,
  ImagePreview,
} from './styles'

export const BuildResume = () => {
  const dispatch = useDispatch()
  const resume = useTypedSelector(state => selectors.lastCreated(state, DocumentTypes.resume))

  const isResume = resume !== null
  const lastResumeScore = isResume
    ? resume.averageScore
      ? resume.averageScore
      : resume.resumeScore
    : 0

  useEffect(() => {
    dispatch(actions.fetchDocuments())
  }, [dispatch])

  return (
    <Container>
      {isResume ? (
        <OptimizeResume>
          <Wrapper>
            <ImagePreview src={formatDocumentPreviewUrl(resume)} placeholder="resumepreview" />
            <VisualGradient />
          </Wrapper>
          <ScoreSection>
            <ResumeScore score={lastResumeScore}>{lastResumeScore}</ResumeScore>
            <Title>Resume Score</Title>
          </ScoreSection>
        </OptimizeResume>
      ) : (
        <Image src={createResume} />
      )}
    </Container>
  )
}
