import EditorField from 'builder/components/EditorField'
import AquisitionDatePicker from 'builder/components/DocumentEditor/Japanese/Cards/CoursesCard/AquisitionDatePicker'
import { useLanguageCardContext } from '../../ExpandableCardContext'
import { useShokumuTranslations } from '../../useShokumuTranslations'

type AcquisitionDateValue = {
  dateUntil: string
}

const CertificateDate = (): JSX.Element => {
  const { item, onCardValueChange } = useLanguageCardContext()
  const acquisitionDatePickerValue = {
    dateUntil: item.certificateAcquisitionDate,
  }

  const { i18n } = useShokumuTranslations()
  const label = i18n('languages.certificate_date')

  return (
    <EditorField>
      <AquisitionDatePicker
        value={acquisitionDatePickerValue}
        onChange={(value: AcquisitionDateValue) => {
          onCardValueChange({ certificateAcquisitionDate: value.dateUntil }, true)
        }}
        headingLabel={label}
        showLabelHint={false}
      />
    </EditorField>
  )
}
export default CertificateDate
