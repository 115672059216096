import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors, ConfigScopesEnum, AppConfig } from 'builder/modules/init'
import { selectors as userSelectors } from 'builder/modules/user'
import { FeaturesConfig } from './FeaturesConfig'

export const useFeaturesConfig = (): FeaturesConfig | Record<PropertyKey, never> => {
  const user = useTypedSelector(userSelectors.userData)
  const features = useTypedSelector((state): AppConfig['features'] | undefined => {
    return selectors.config(state, ConfigScopesEnum.app)?.features
  })

  return features ? new FeaturesConfig(features, user) : {}
}
