export const options = [
  {
    name: 'Novice',
    id: 'novice',
    rating: 1,
  },
  {
    name: 'Beginner',
    id: 'beginner',
    rating: 2,
  },
  {
    name: 'Skillful',
    id: 'skillful',
    rating: 3,
  },
  {
    name: 'Experienced',
    id: 'experienced',
    rating: 4,
  },
  {
    name: 'Expert',
    id: 'expert',
    rating: 5,
  },
]
