import React, { useState } from 'react'
import ActionMenu from 'builder/components/ActionMenu'
import { useI18n } from 'builder/hooks/useI18n'
import { DropDownIcon, FilterButton } from './styles'
import { DEFAULT_FILTERS } from './constants'

interface Props {
  selected: number
  onSelect: (value: number) => void
}

const TRANSLATION_KEY = 'builder.job_search.filters.titles.time'

const PostedDate = ({ selected, onSelect }: Props) => {
  const [selectionOpen, setSelectionOpen] = useState(false)
  const { i18n } = useI18n()

  const timeToLabel = (t: number) => {
    if (t === 0) return i18n.t(`${TRANSLATION_KEY}.anytime`)
    if (t === 1) return i18n.t(`${TRANSLATION_KEY}.one`)
    return i18n.t(`${TRANSLATION_KEY}.many`, { count: t })
  }

  const handleSelection = (value: number) => {
    onSelect(value)
    setTimeout(() => setSelectionOpen(false), 100)
  }

  const options = [0, 30, 10, 5, 1]

  const actions = options.map(option => ({
    value: option,
    text: timeToLabel(option),
    onClick: () => handleSelection(option),
  }))

  const isFilterActive = selected !== DEFAULT_FILTERS.postedWithinDays
  const label = actions.find(action => action.value === selected)?.text || actions[0].text
  return (
    <FilterButton onClick={() => setSelectionOpen(true)} selected={isFilterActive}>
      {label}
      <DropDownIcon selected={isFilterActive} />
      {selectionOpen && (
        <ActionMenu
          align="left"
          position="bottom"
          onClose={() => setSelectionOpen(false)}
          actions={actions}
        />
      )}
    </FilterButton>
  )
}

export default React.memo(PostedDate)
