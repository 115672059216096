import { lazy, Suspense } from 'react'
import { Skeleton } from './Skeleton'
import { EducationChartProps } from './types'

const ChartComponent = lazy(() =>
  import('./EducationChart').then(module => ({ default: module.EducationChart })),
)

export const EducationChart = (props: EducationChartProps) => (
  <Suspense fallback={<Skeleton />}>
    <ChartComponent {...props} />
  </Suspense>
)
