import styled from 'styled-components'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'

export const Container = styled.div`
  display: flex;
  gap: 12px;
  padding: 24px 32px;
  border-radius: 16px;
  border: 1px solid ${Colors.Neutral15};
  display: flex;
  align-items: center;
  cursor: pointer;

  ${Media.Phone`
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  `}
`

export const CoachDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-right: 286px;

  ${Media.Tablet`
    margin-right: 86px;
  `}

  ${Media.Phone`
    margin-right: 0;
  `}
`

export const CoachImage = styled.img`
  width: 72px;
  height: 72px;
  border-radius: 50%;
  object-fit: cover;

  ${Media.Phone`
    width: 80px;
    height: 80px;
  `}
`

export const CoachCaption = styled.div``

export const CaptionName = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
`

export const CaptionPosition = styled.div`
  color: ${Colors.Neutral90};
`

export const SessionTimeContainer = styled.div``

export const SessionTime = styled.div`
  color: ${Colors.Neutral50};
  text-transform: uppercase;
`

export const SessionDate = styled.div`
  color: ${Colors.Neutral50};
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`
