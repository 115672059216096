import { selectors } from 'builder/modules/careerPath2'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

import { Container, Heading, Skills, Skill } from './styles'

type Props = {
  softSkills: string[]
}

const SoftSkills = ({ softSkills }: Props) => {
  const skills = useTypedSelector(selectors.skills)
  const skillsLabels = skills.map(skill => skill.label)

  return (
    <Container>
      <Heading>Soft Skills</Heading>
      <Skills>
        {softSkills.map((skill, index) => (
          <Skill isKnown={skillsLabels.includes(skill)} key={index}>
            {skill}
          </Skill>
        ))}
      </Skills>
    </Container>
  )
}

export default SoftSkills
