import { memo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { actions, selectors } from 'builder/modules/careerCoaching'
import CareerCoachingSessions from 'builder/components/CareerCoachingSessions'

import { Container } from './styles'

const useUpdateBodyClass = (className: string) => {
  const location = useLocation()

  useEffect(() => {
    document.body.className = className
    return () => {
      document.body.className = ''
    }
  }, [location.pathname, className])
}

const CareerCoachingView = (): JSX.Element => {
  const dispatch = useDispatch()
  const location = useLocation()
  const isModalOpen = useTypedSelector(selectors.isModalOpen)
  const isUserRedirectTo = useTypedSelector(selectors.isUserRedirectTo)

  useUpdateBodyClass('coaching')

  useEffect(() => {
    if (isUserRedirectTo) {
      dispatch(actions.fetchRedirectRoutes({ currentUrl: location.pathname }))
      dispatch(actions.setUserRedirectState(false))
    }
  }, [dispatch, isUserRedirectTo, location.pathname])

  useEffect(() => {
    // Initial values required for filtering coaches
    const params = {
      careerLevels: '',
      careerServiceId: [],
      careerIndustryId: [],
    }

    if (isModalOpen) {
      dispatch(actions.fetchCareerLevels())
      dispatch(actions.fetchIndustries())
      dispatch(actions.fetchServicesOffered())
      dispatch(actions.filterCoaches(params))
    }
  }, [dispatch, isModalOpen])

  return (
    <Container>
      <CareerCoachingSessions />
    </Container>
  )
}

export default memo(CareerCoachingView)
