import { actions } from 'builder/modules/resumeEditor'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SectionHeader, SectionHint } from 'builder/components/Section'
import { SectionContainer, SectionContent } from 'builder/components/Section/styles'
import SectionTitle from 'builder/components/SectionTitle'
import { useI18n } from 'builder/hooks/useI18n'
import RichTextArea from 'builder/components/RichTextArea/RichTextArea'

export function CustomSection() {
  const { i18n } = useI18n()
  const spaceForPersonalRequests = useSelector(
    state => state.resumeEditor.resume.spaceForPersonalRequests,
  )
  const { updateSimpleField } = actions
  const dispatch = useDispatch()
  const onChangeCallback = useCallback(
    value => {
      dispatch(updateSimpleField({ name: 'spaceForPersonalRequests', value }))
    },
    [dispatch, updateSimpleField],
  )

  return (
    <>
      <SectionContainer>
        <SectionHeader>
          <SectionTitle title={i18n.t('builder.resume_editor.custom_section.title')} />
          <SectionHint>{i18n.t('builder.resume_editor.custom_section.hint')}</SectionHint>
        </SectionHeader>
        <SectionContent>
          {/* TODO: Add label to i18n if AB test succeeds */}
          {/* replaced text area with richtext area to support the 
          new line functionality like other sections */}
          <RichTextArea
            rows={10}
            value={spaceForPersonalRequests || ''}
            onChange={onChangeCallback}
            // disableToolbar to disable the toolbar
            disableToolbar
          />
        </SectionContent>
      </SectionContainer>
    </>
  )
}
