import React, { useRef } from 'react'
import ModalOverlay from 'builder/components/ModalOverlay'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useClickOutside } from 'builder/hooks/useClickOutside'
import { CloseIcon, CloseIconContainer, TitleContainer, Wrapper } from './styles'

type ModalProps = {
  onClose: () => void
  title?: React.ReactNode
  width?: number
  className?: string
}

export const Modal = ({
  children,
  title,
  onClose,
  width,
  className,
}: React.PropsWithChildren<ModalProps>) => {
  const { isPhone } = useMediaQueries()
  const modalRef = useRef<HTMLDivElement>(null)
  useClickOutside(modalRef, onClose)
  return (
    <ModalOverlay overlayFadeDuration={350} contentSlideDuration={350} onClose={onClose}>
      <Wrapper $width={width || isPhone ? 343 : 544} className={className} ref={modalRef}>
        <TitleContainer>
          {title ?? <div></div>}
          <CloseIconContainer onClick={onClose}>
            <CloseIcon />
          </CloseIconContainer>
        </TitleContainer>
        {children}
      </Wrapper>
    </ModalOverlay>
  )
}
