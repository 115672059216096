import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Button from 'builder/components/Button'
import Media from 'builder/styles/media'

export const Container = styled.div`
  padding: 24px;
  background-color: ${Colors.Indigo10};
  border-radius: 16px;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 52px;

  ${Media.Tablet`
   flex-direction:column ;
    align-items: start;
  `};
`
export const CoachImages = styled.div`
  width: 160px;
`

export const CoachDetails = styled.div`
  margin-left: 22px;
  width: 100%;
  flex: 1 1 auto;
  max-width: 586px;
  ${Media.Tablet`
    margin: 24px 0 16px 0;
  `};
`
export const CoachTitle = styled.span`
  ${FontWeights.Medium};
  ${Typography.Subhead}
  color: ${Colors.Indigo80};
  margin-bottom: 4px;
`
export const CoachDescription = styled.p`
  ${Typography.Body}
  color: ${Colors.Indigo80};
`
export const CoachAction = styled.div`
  margin-left: auto;
  ${Media.Tablet`
    margin-left: 0;
  `}
`

export const CoachButton = styled(Button)``
