import * as Sentry from '@sentry/browser'

const SENTRY_DSN_KEY_PROD = process.env.SENTRY_BUILDER_ERROR_LOGGER_DSN
const SENTRY_DSN_KEY_STAGING = process.env.SENTRY_BUILDER_ERROR_LOGGER_DSN_STAGING
type Error = { name?: string; message?: string }
/**
 * Check the error to decide should we send it to Sentry or not
 *
 * @param {Error} error - original error exception
 * @returns {boolean}
 */
const shouldSkipError = (error: Error) => {
  // Skip errors coming from downloaded HTML pages
  if (window.location.protocol === 'file:') return true

  // By default browsers use `NetworkError` to handle network errors,
  // but axios throw them as `new Error('Network Error')`
  if (error.name === 'NetworkError' || error.message === 'Network Error') return true

  // That's how axios handles browser request cancellation
  if (error.message === 'Request aborted') return true

  // To ignore any facebook pixel reference error as per https://teamtalent.atlassian.net/browse/RIO-2256
  if (error.message === 'fbq is not defined' || error.message === "Can't find variable: fbq")
    return true

  return false
}

class SentryLogger {
  // Init the Sentry Browser SDK
  constructor(dsn: string | undefined) {
    Sentry.init({
      dsn,
      // Can be used to modify the event. If you return null, the event will be discarded.
      // See https://docs.sentry.io/error-reporting/configuration/filtering/
      beforeSend: (event, hint) => {
        const error = hint.originalException as Error
        if (error && shouldSkipError(error)) return null
        return event
      },
    })
  }

  // Send error to the Sentry database
  log(error: unknown, options: { tag?: string; level?: Sentry.SeverityLevel } = {}) {
    const { level = 'error' } = options

    Sentry.withScope(function (scope) {
      // Set the severity of an event: fatal, error, warning, info or debug.
      // It useful for events filtering: allows to find the most critical of them.
      // See https://docs.sentry.io/enriching-error-data/context/?platform=browser#setting-the-level
      scope.setLevel(level)

      if (options.tag) {
        scope.setTag(options.tag, true)
      }

      Sentry.captureException(error)
    })
  }

  // Tell Sentry to associate errors with user data
  setUser({ id, email }: { id: string; email: string }) {
    Sentry.setUser({ id, email })
  }
}

class DevLogger {
  log(error: unknown, options = {}) {
    console.error('Error Logger: Log', error, options)
  }

  setUser({ id, email }: { id: string; email: string }) {
    console.warn(`Error Logger: Set user`, { id, email })
  }
}

const getDSN = () => {
  if (process.env.NODE_ENV === 'staging') {
    return SENTRY_DSN_KEY_STAGING
  }
  if (process.env.NODE_ENV === 'production') {
    return SENTRY_DSN_KEY_PROD
  }
}

const isDev = process.env.NODE_ENV === 'development'
const errorLogger = isDev ? new DevLogger() : new SentryLogger(getDSN())

export default errorLogger
