/* eslint-disable react/no-unescaped-entities */
import { Container, LoadingImage } from './styles'
import loadingScreen3URL from './assets/img/loadingscreen3.gif'
import Header from './header'
const LoadingScreen3 = () => {
  return (
    <Container>
      <Header
        title="Generating resume score"
        subtitle="Give us a moment and don't close this window, we are preparing your resume review"
      />
      <LoadingImage src={loadingScreen3URL}></LoadingImage>
    </Container>
  )
}

export default LoadingScreen3
