/* eslint-disable react/no-unescaped-entities */
import { Container, LoadingImage } from './styles'
import loadingScreen4URL from './assets/img/loadingscreen4.png'
import Footer from './footer'
import Header from './header'
const LoadingScreen4 = () => {
  return (
    <Container>
      <Header
        title="Generating resume score"
        subtitle="Give us a moment and don't close this window, we are preparing your resume review"
      />
      <LoadingImage src={loadingScreen4URL}></LoadingImage>
      <Footer />
    </Container>
  )
}

export default LoadingScreen4
