import styled, { css } from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Shadows from 'builder/styles/shadows'
import Icon24 from 'builder/components/Icon'
import Button from 'builder/components/Button'
import { Typography, FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  width: 100%;
`

export const Title = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};
  align-self: center;
  text-align: center;
  margin: 32px 0;
  max-width: 416px;

  ${Media.Phone`
    max-width: 335px;
  `}
`

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
`

export const Fields = styled.div<{ borderRadius: string }>`
  ${Shadows.lightWithBorder.low};

  height: 64px;
  display: flex;
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '0px')};
  align-items: center;

  ${Media.Tablet`
    height: auto;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 8px;
    padding: 8px;
  `}

  &:hover,
  &:active {
    ${Shadows.lightWithBorder.mid};
    cursor: pointer;
  }
`

export const FieldSeparator = styled.div`
  height: 24px;
  width: 2px;
  background-color: ${Colors.Neutral20};
  margin-inline: 8px;

  ${Media.Tablet`
    width: 100%;
    height: 1px;
  `}
`

export const ButtonContainer = styled.div`
  text-align: right;
`

export const FormButton = styled(Button)`
  width: 128px;
  margin-inline: 8px;

  ${Media.Tablet`
    width: 100%;
    margin: 0;
    margin-top: 24px;
  `}

  ${Media.Phone`
    margin: 0;
    margin-top: 16px;
  `}
`

export const Image = styled.img<{ isExploreCareers: boolean }>`
  width: ${props => (props.isExploreCareers ? 300 : 416)}px;
  align-self: center;

  ${props => Media.Phone`
    width: ${props.isExploreCareers ? 248 : 335}px;
  `}
`

export const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const SearchField = styled.input`
  ${Typography.Body};
  ${FontWeights.Regular};

  width: 100%;
  border: none;
  background-color: transparent;

  &::placeholder {
    color: ${Colors.Neutral30};
  }
`

export const Menu = styled.div<{ isEmpty: boolean }>`
  ${Shadows.lightWithBorder.mid};

  width: 100%;
  display: ${props => (props.isEmpty ? 'none' : 'block')};
  background-color: ${Colors.White};
  border-radius: 0 0 12px 12px;
  position: absolute;
  padding: 12px 8px;

  & .react-autosuggest__suggestions-list {
    width: 100%;
  }
`

export const Item = styled.div<{ isHighlighted: boolean }>`
  padding: 12px 8px;
  display: flex;
  gap: 8px;
  align-items: center;

  ${props =>
    props.isHighlighted &&
    css`
      background-color: ${Colors.Blue10};
    `}
`

export const SectionHeader = styled.div`
  ${Typography.Tiny};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral50};
  text-transform: uppercase;
  letter-spacing: 1.8px;
  padding-left: 20px;
  margin-top: 16px;
  margin-bottom: 4px;
  background-color: ${Colors.White};
`

export const StyledSearch = styled(Icon24.NewSearch)`
  color: ${Colors.Neutral40};
`

export const StyledLocation = styled(Icon24.NewLocation)`
  color: ${Colors.Neutral40};
`
