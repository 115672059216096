import { useEffect, useRef, useState } from 'react'
import jwtService from 'builder/services/JwtService'
import { convertBase64ToFile } from 'builder/utils/convertBase64ToFile'
import { getBase64ByImageUrl } from './utils'

const removeBackground = async (url: string): Promise<string> => {
  const data = new FormData()
  const base64 = await getBase64ByImageUrl(url)
  data.append('file', convertBase64ToFile(base64, 'photo.jpg'))

  const res = await jwtService.client.post<string>(`${process.env.BG_SERVICE_HOST}/rembg`, data)
  return `data:image/png;base64,${res.data}`
}
/**
 * The hook to simplify the communication with the ML-service
 */
export const useBackgroundRemover = (imageUrl: string | null, isVisible: boolean) => {
  const [noBgImageUrl, setNoBgImageUrl] = useState<string | null>(imageUrl)
  const lastImageUrlRef = useRef<string | null>(null)

  useEffect(() => {
    const hasImageChanged = imageUrl !== lastImageUrlRef.current
    if (hasImageChanged) setNoBgImageUrl(null)

    if (isVisible && hasImageChanged) {
      lastImageUrlRef.current = imageUrl

      removeBackground(imageUrl as string).then(base64 => {
        if (imageUrl === lastImageUrlRef.current) setNoBgImageUrl(base64)
      })
    }
  }, [imageUrl, isVisible])

  return [noBgImageUrl]
}
