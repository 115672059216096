import { SearchSuggestionType } from 'builder/components/FindJob/AutoSuggestField/types'
import { Job } from 'builder/components/FindJob/JobSearchResult/types'
import { Filters, TabType } from 'builder/components/FindJob/types'

export interface SelectedJobForAutoApply {
  data: Job | null
  tab: TabType | null
  done: boolean
}

export interface AutoApplyBubbles {
  userStartedAutoApplyBubbles: boolean
  showAutoApplyGuideButton: boolean
  stepIndex: number
  showFirstPage: boolean
  running: boolean
  initialized: boolean
  selectedJob?: SelectedJobForAutoApply
}

export enum AlertTypes {
  manage = 'manage',
  create = 'create',
  edit = 'edit',
  confirmation_success = 'confirmation_success',
  delete = 'delete',
}

export interface CustomAlertType {
  id?: number
  targetRole?: string
  location?: string
  locationId?: number
  onlyRemoteJobs?: boolean
  frequency?: FrequencyType
}
export interface Alert {
  type: AlertTypes | undefined
  editCustomAlert?: CustomAlertType
}

export enum JobSearchNotificationType {
  auto_apply_added = 'auto_apply_added',
  job_card_added = 'job_card_added',
  create_job_alert = 'create_job_alert',
  delete_job_alert = 'delete_job_alert',
  snackbar_fail = 'snackbar_fail',
}

export enum DataNotificationType {
  success = 'success',
  fail = 'fail',
}

export interface DataNotification {
  type: DataNotificationType
  titleText: string
  actionText?: string
  onClickAction?: () => void
  forceOnTop?: boolean
}

export interface SearchForm {
  term: string | undefined
  selectedLocation: SearchSuggestionType | undefined
  filters: Filters
}
export type JobSearchStore = {
  searchForm: SearchForm
  autoApplyBubbles: AutoApplyBubbles
  alert: Alert
  notification: JobSearchNotificationType | undefined
}

export enum FrequencyType {
  daily = 'daily',
  weekly = 'weekly',
  two_weeks = 'two_weeks',
}

export interface CustomAlertResponse {
  id: number
  user_id: number
  target_role: string
  location: string
  only_remote_jobs: boolean
  frequency: FrequencyType
  location_id: number
}
