import { useNavigate } from 'react-router-dom'
import { Icon20 } from 'builder/components/Icon'
import { useI18n } from 'builder/hooks/useI18n'
import { useJobTitle } from 'builder/views/Interview/hooks/useJobTitle'
import { Interview } from 'builder/modules/interview/types'
import { TRANSLATION_PREFIX } from '../../FeedbackView'
import { Button } from '../Button/Button'
import { formatInterviewDateByLocation } from '../InterviewHistoryDialog/InterviewHistoryDialog'
import {
  Container,
  CloseIconContainer,
  CreatedAt,
  TabletButtonContainer,
  TextContainer,
  Title,
  FeedbackButton,
  ListIcon,
} from './styles'

interface Props {
  toggleSidebar: () => void
  showInterviewHistoryDialog: () => void
  hasPremiumFeatures: boolean
  interview?: Interview
  stopPerformance: () => void
}

export const VideoHeader = ({
  stopPerformance,
  toggleSidebar,
  showInterviewHistoryDialog,
  hasPremiumFeatures,
  interview,
}: Props) => {
  const navigate = useNavigate()
  const { data: jobTitleData } = useJobTitle(interview?.data_api_job_title_id)
  const { i18n } = useI18n()

  const goToDashboard = () => {
    stopPerformance()
    navigate('/interview-preparation/dashboard')
  }

  const company = interview?.company_name
    ? [i18n.t(`${TRANSLATION_PREFIX}.at_preposition`), interview?.company_name]
    : []
  const title = jobTitleData?.jobTitle ? [jobTitleData?.jobTitle, company].flat().join(' ') : ''

  return (
    <Container>
      <CloseIconContainer onClick={goToDashboard}>
        <Icon20.Close />
      </CloseIconContainer>
      {interview && (
        <TextContainer>
          <Title>{title}</Title>
          <CreatedAt>{formatInterviewDateByLocation(interview)}</CreatedAt>
        </TextContainer>
      )}

      <TabletButtonContainer>
        {hasPremiumFeatures && (
          <Button onClick={showInterviewHistoryDialog}>
            <ListIcon />
            {i18n.t(`${TRANSLATION_PREFIX}.history`)}
          </Button>
        )}
        <FeedbackButton onClick={toggleSidebar}>
          {i18n.t(`${TRANSLATION_PREFIX}.feedback`)}
        </FeedbackButton>
      </TabletButtonContainer>
    </Container>
  )
}
