import JobOfferStandards from 'builder/components/JobOfferStandards'

const OfferStandardsView = () => {
  return (
    <div>
      <JobOfferStandards />
    </div>
  )
}

export default OfferStandardsView
