import {
  Container,
  Cards,
  Card,
  Text,
  SubText,
  HeaderText,
  StepsButton,
  StepsHeading,
  Divider,
  BorderBottom,
} from './styles'
import { ABOUT_SESSIONS } from './constants'

const CareerCoachingWorking = () => {
  return (
    <Container>
      <HeaderText>How It works</HeaderText>
      <Cards>
        {ABOUT_SESSIONS.map(({ id, label, description }) => {
          return (
            <Card key={id}>
              <StepsHeading>
                <StepsButton>{id}</StepsButton>
                <Text>{label}</Text>
                {id < 4 && <Divider />}
              </StepsHeading>
              <SubText>{description}</SubText>
            </Card>
          )
        })}
      </Cards>
      <BorderBottom />
    </Container>
  )
}

export default CareerCoachingWorking
