import { useCallback, useEffect } from 'react'
import { useQueryParam } from 'builder/hooks/useQueryParam'
import { SplashScreenAnimation } from './SplashScreenAnimation'
import { EMERGENCY_TIMEOUT } from './constants'

/**
 * The domain component that is up to query params listening and component (un)mounting
 */
export const SplashScreen = () => {
  // Read initial query param value
  const [queryParamValue, changeQueryParam] = useQueryParam('welcome')

  // `?welcome` is equal to "" so we have to check that param is present (not null)
  const isVisible = queryParamValue !== null

  // Unmount the component by wiping out the query parameter
  const unmount = useCallback(() => changeQueryParam(null, { replace: true }), [changeQueryParam])

  // Emergency scenario: close the splash screen after a delay
  useEffect(() => {
    if (!isVisible) return
    const timer = setTimeout(unmount, EMERGENCY_TIMEOUT)
    return () => clearTimeout(timer)
  }, [isVisible, unmount])

  // Show only if query param is present in the window url
  if (isVisible) return <SplashScreenAnimation onEnd={unmount} />

  return null
}
