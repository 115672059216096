import Spinner from 'builder/components/Spinner'
import { LoaderContainer } from './styles'

export const PageLoader = () => {
  return (
    <LoaderContainer timeout={0}>
      <Spinner />
    </LoaderContainer>
  )
}
