import { useI18n } from 'builder/hooks/useI18n'

import { selectors } from 'builder/modules/careerProfile'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { ISubMenu } from 'builder/features/navigation/types'
import {
  PendingItemsContainer,
  ProfileInfo,
  PendingItem,
  BadgePercentage,
  PendingItemText,
  MenuItem,
  MenuItemCont,
} from './style'

const TRANSLATION = 'builder.side_menu.profile'

export const CareerProfile = () => {
  const { i18n } = useI18n()
  const completenessPendingItems = useTypedSelector(selectors.completenessPendingItems)

  const menu: ISubMenu[] = [
    {
      to: '/career-profile/insights',
      text: i18n.t(`${TRANSLATION}.career_insights`),
      eventParam: 'insights',
    },
    {
      to: '/career-profile/pitch',
      text: i18n.t(`${TRANSLATION}.professional_pitch`),
      eventParam: 'pitch',
    },
    {
      to: '/career-profile/cover-letter',
      text: i18n.t(`${TRANSLATION}.ai_cover_letter`),
      eventParam: 'cover_letter_sample',
    },
  ]
  return (
    <>
      {!!completenessPendingItems?.length && (
        <PendingItemsContainer>
          <ProfileInfo>{i18n.t(`${TRANSLATION}.complete`)}</ProfileInfo>
          {completenessPendingItems.map(item => (
            <PendingItem key={item.key}>
              <BadgePercentage variant="green" size="medium">
                +{item.value}%
              </BadgePercentage>
              <PendingItemText>{i18n.t(`${TRANSLATION}.${item.key}`)}</PendingItemText>
            </PendingItem>
          ))}
        </PendingItemsContainer>
      )}

      {menu.map(item => (
        <MenuItem key={item.text}>
          <MenuItemCont
            to={item.to}
            open={true}
            active={item.active}
            endDecoration={item.endDecoration}
            startDecoration={item.startDecoration}
            eventParam={item.eventParam}
          >
            {item.text}
          </MenuItemCont>
        </MenuItem>
      ))}
    </>
  )
}
