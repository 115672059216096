import { Fragment } from 'react'
import { i18n as I18n } from 'builder/utils/i18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { RecommendedSkill } from '../../types'
import { SectionHeader } from '../SectionHeader'
import { ListItem } from '../ListItem'
import { translationKey } from '../../constants'
import * as Styled from './styles'
import bannerImageUrl from './images/recommended-skills.png'
import iconUrl from './images/education-icon.svg'

type Props = {
  skills: RecommendedSkill[]
}

export const SkillsSection = ({ skills }: Props) => {
  const queries = useMediaQueries()

  return (
    <Styled.Container>
      <SectionHeader
        headerText={I18n.t(`${translationKey}.skills_recommendations`)}
        description={I18n.t(`${translationKey}.these_recommendations_based`)}
      >
        <Styled.HeaderImage src={bannerImageUrl} alt="Banner image" />
      </SectionHeader>

      <Styled.ListContainer>
        {!queries.isPhone ? (
          <>
            <Styled.ListHeader>{I18n.t(`${translationKey}.skill`)}</Styled.ListHeader>
            <Styled.ListHeader>{I18n.t(`${translationKey}.recommended_course`)}</Styled.ListHeader>
          </>
        ) : null}

        {!queries.isPhone
          ? skills.map((skill, index) => (
              <Fragment key={`left-${index}`}>
                <ListItem header={skill.title} subheader={skill.hardOrSoft} iconUrl={iconUrl} />
                <ListItem key={`right-${index}`} description={skill.description} />
              </Fragment>
            ))
          : skills.map((skill, index) => (
              <ListItem
                key={index}
                header={skill.title}
                subheader={skill.hardOrSoft}
                iconUrl={iconUrl}
              >
                <Styled.MobileDescription>
                  <Styled.MobileDescriptionHeader>
                    {I18n.t(`${translationKey}.recommended_course`)}
                  </Styled.MobileDescriptionHeader>
                  <Styled.MobileDescriptionText>{skill.description}</Styled.MobileDescriptionText>
                </Styled.MobileDescription>
              </ListItem>
            ))}
      </Styled.ListContainer>
    </Styled.Container>
  )
}
