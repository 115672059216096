import styled from 'styled-components'
import Media from 'builder/styles/media'

export const FeedbackSubmitContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  & img {
    margin-bottom: 22px;
  }

  ${Media.Tablet`
    & div {
      margin-top: 20px;
    }
    & img {
      margin-bottom: 0px;
    }
  `}

  ${Media.Phone`
    & div {
      margin-top: 20px;
    }
    & img {
      margin-bottom: 0px;
    }
  `}
`
