import { RenderingConfig } from '@rio/types'
import { apiClient } from 'builder/modules/apiClient'

class RenderingConfigService {
  configs: Record<string, RenderingConfig> = {}

  getConfig = async (locale = 'en'): Promise<RenderingConfig> => {
    if (this.configs[locale]) return this.configs[locale]

    const url = `/general/renderer-config/${locale}`
    const { data: config } = await apiClient.get<RenderingConfig>(url)
    this.configs[locale] = config
    return config
  }
}

export default new RenderingConfigService()
