import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { Label, actions, CareerPathStep } from 'builder/modules/careerPath2'

import { Circle, Container, Text, Title, SubTitle } from './styles'

export const AddNewPathCard = () => {
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(actions.setPrevStep(CareerPathStep.VIEW_CARDS))
    dispatch(actions.setStep(CareerPathStep.QUESTIONNAIRE))

    // clear previously set values
    dispatch(actions.setOccupation(''))
    dispatch(actions.setSkills([]))
    dispatch(actions.setCareerPath1([]))
    dispatch(actions.setLevel1JobTitles([]))
    dispatch(actions.setLevel2JobTitles([]))
    dispatch(actions.setLevel3JobTitles([]))

    trackInternalEvent('click_new_career_pathway_card', Label)
  }

  return (
    <Container onClick={handleClick}>
      <Circle />
      <Text>
        <Title>New Career Path</Title>
        <SubTitle>
          Provide your recent or desired job title and put your skill set to build a career path
        </SubTitle>
      </Text>
    </Container>
  )
}
