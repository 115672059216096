import styled from 'styled-components'
import { Typography } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import { Media } from 'builder/styles/media'

export const HeaderAside = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  width: 174px;

  ${Media.Tablet`
    width: auto;
  `};

  ${Media.Phone`
    width: 100%;
    justify-content: flex-start;
    padding-top: 13px;
  `};
`

export const PoweredBy = styled.img`
  width: 24px;
  height: 24px;
  margin-bottom: -1px;
`

export const SubText = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
  padding-right: 4px;
`

export const LogoName = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral90};
`
