import { useContext } from 'react'
import { Button } from 'builder/components/Button'
import { SnackBarContext } from 'builder/components/SnackBarV2/SnackBarContext'
import { SnackBarTypes } from 'builder/components/SnackBarV2/types'
import { SnackBar } from 'builder/components/SnackBarV2'
import { ComponentPreview, Group, Label } from '../../styles'

export const SnackBarSectionV2 = () => {
  const { openSnackBar, closeSnackBar } = useContext(SnackBarContext)

  return (
    <>
      <SnackBar />
      <Group>
        <Label>Snackbar V2</Label>
        <ComponentPreview
          style={{ display: 'flex', flexWrap: 'wrap', gap: '12px', alignItems: 'center' }}
        >
          <Button
            size="small"
            onClick={() =>
              openSnackBar({
                text: 'To inform the user that their action (e.g., resume submission) was successful.',
                type: SnackBarTypes.success,
              })
            }
          >
            Show Success Snackbar
          </Button>
          <Button
            size="small"
            onClick={() =>
              openSnackBar({
                text: 'To alert the user that there was an error in processing their action.',
                type: SnackBarTypes.failure,
              })
            }
          >
            Show Failure Snackbar
          </Button>
          <Button
            size="small"
            onClick={() =>
              openSnackBar({
                text: 'To provide a general update or status while an action is in progress.',
                type: SnackBarTypes.simple,
              })
            }
          >
            Show Simple Snackbar
          </Button>
          <Button
            size="small"
            onClick={() =>
              openSnackBar({
                text: 'To notify the user of a completed action with the option to dismiss the message.',
                type: SnackBarTypes.success,
                isDismissible: true,
              })
            }
          >
            Show Dismissible Snackbar
          </Button>
          <Button
            size="small"
            onClick={() =>
              openSnackBar({
                text: 'To encourage the user to take an additional action, such as visiting a helpful link.',
                action: {
                  onClick: () => {
                    window.open('https://career.io', '_blank', 'noopener,noreferrer')
                  },
                  text: 'Open Career.io',
                },
              })
            }
          >
            Show Custom Action Snackbar
          </Button>
          <Button
            size="small"
            onClick={() =>
              openSnackBar({
                text: 'To convey a temporary message that will automatically disappear after a set time.',
                type: SnackBarTypes.success,
                autoDismissTime: 8,
              })
            }
          >
            Show Custom Dismiss Time 8s Snackbar (Min: 4s - Max: 10s)
          </Button>
          <Button
            size="small"
            onClick={() =>
              openSnackBar({
                text: 'To provide a status update while adjusting the visual placement based on device type.',
                type: SnackBarTypes.success,
                isDismissible: true,
                bottom: { phone: 120, desktop: 80 },
              })
            }
          >
            Show Custom Bottom Position Snackbar
          </Button>
          <Button size="small" onClick={() => closeSnackBar()}>
            Close SnackBar
          </Button>
        </ComponentPreview>
      </Group>
    </>
  )
}
