import styled from 'styled-components'
import { Tab } from 'builder/components/Tabs'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`

export const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 48px;
`

export const StyledTab = styled(Tab)`
  margin-right: 48px;

  ${Media.Phone`
    width: 50%;
    text-align: center;
    margin-right: 0;
 `}
`

export const Heading = styled.div`
  ${Typography.M}
  ${FontWeights.Medium}
`

export const SessionContainer = styled.div``
