import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects'
import { parse as parseQuery } from 'query-string'
import { trackInternalEvent } from '@rio/tracking'
import { FetchStatuses } from 'builder/modules/constants'
import { apiClient, ApiResponse } from 'builder/modules/apiClient'
import { actions as appActions } from 'builder/modules/init'
import { selectors as userSelectors } from 'builder/modules/user'
import { actions as critiqueActions } from 'builder/modules/resumeCritiqueReview'
import { actions, selectors } from './resumeReviewModule'
import { createFakeCritique } from './utils'

import {
  ResumeCritique,
  ResumeCritiqueStatuses as STATUSES,
  ResumeReviewScenarios as SCENARIOS,
} from './types'

const REAL_DATA_UPDATING_DELAY = 120 * 1000
const FAKE_DATA_UPDATING_DELAY = 30 * 1000

/**
 * Sends resume to review or requests a critique
 */
function* sendResumeSaga(
  action: ReturnType<typeof actions.sendResume>,
): Generator<object, void, ApiResponse> {
  try {
    const { resumeId } = action.payload
    const { resume_review_scenario: forcedScenario } = parseQuery(window.location.search)
    let scenario: SCENARIOS | null = null

    // Feature is not available on the free plan
    const userHasPremiumAccess = yield select(userSelectors.premiumAccess)
    if (!userHasPremiumAccess) return

    // Get scenario from the query (manual mode) or fetch it from backend
    if (typeof forcedScenario === 'string') {
      scenario = forcedScenario as SCENARIOS
    } else {
      const { data }: ApiResponse<{ scenario: SCENARIOS | null }> = yield call(
        apiClient.get,
        `/features/resume-review`,
      )
      scenario = data.scenario
    }

    // Update application features config
    yield put(appActions.setFeatureValue({ name: 'resumeReviewScenario', value: scenario }))

    // Do nothing if no scenario is supported by the country
    if (!scenario) return

    // A: Call the endpoint which initializes external topresume.com email campaign
    if (scenario === SCENARIOS.external) {
      yield call(apiClient.post, `/resumes/${resumeId}/send-to-review`)
    }

    // B: Send resume to topresume.com API and start fetching critiques
    if (scenario === SCENARIOS.embedded) {
      // Create temporary critique to display loading state in the UI
      yield put(actions.setCritiques([createFakeCritique({ resumeId })]))

      try {
        // Track that frontend will ask a document for TOP Resume Review
        trackInternalEvent('tr_doc_planned_to_be_sent')

        // Send critique
        const response: ApiResponse<{ critique: ResumeCritique }> = yield call(
          apiClient.post,
          `/resumes/${resumeId}/critique`,
        )
        // Replace the fake critique with the received one
        yield put(actions.setCritiques([response.data.critique]))
        // Start polling critiques endpoint
        yield delay(REAL_DATA_UPDATING_DELAY)
        yield put(actions.fetchCritiques())
      } catch {
        // Override critique with its error state if something went wrong
        yield put(actions.setCritiques([createFakeCritique({ resumeId, status: STATUSES.failed })]))
      }
    }
  } catch {}
}

/**
 * Fetches available resume critiques
 */
function* fetchCritiquesSaga() {
  try {
    yield put(actions.setCritiquesFetchStatus(FetchStatuses.loading))

    // Request user's critiques
    const {
      data,
    }: ApiResponse<{
      critiques: ResumeCritique[]
    }> = yield call(apiClient.get, `/resume-critiques`)

    // Write them to the application storage
    if (data.critiques.length > 0) {
      yield put(actions.setCritiques(data.critiques))
      yield put(
        critiqueActions.setIsTopResumeUser({
          isTopResumeUser: data.critiques[0]?.isTopResumeUser,
        }),
      )
    }
    yield put(actions.setCritiquesFetchStatus(FetchStatuses.loaded))

    // Pause before auto-updating
    yield delay(REAL_DATA_UPDATING_DELAY)

    // Find incomplete critiques in the data we got
    const pendingCritiques = data.critiques.filter(c => c.status === STATUSES.pending)

    // Send request again again if there were incomplete critiques in the last response.
    // Important note: Since we call `actions.fetchCritiques` this sagas call itself recursively
    // every X seconds while "pending" critiques coming from the backend
    if (pendingCritiques.length > 0) yield put(actions.fetchCritiques())
  } catch {
    yield put(actions.setCritiquesFetchStatus(FetchStatuses.failed))
  }
}

/**
 * Updates local state (countdowns and fake statuses) of all pending critiques data with a delay.
 */
function* setCritiquesSaga() {
  // Do not do that immediately
  yield delay(FAKE_DATA_UPDATING_DELAY)

  // Find pending critiques
  const critiques: ResumeCritique[] = yield select(selectors.critiques)
  const pendingCritiques = critiques.filter(c => c.status === STATUSES.pending)

  // Stop an async recursion if there is no pending critiques left
  if (pendingCritiques.length === 0) return

  // Trigger incomplete critiques data updating (the reducer will override timestamps and statuses).
  // Important note: Since we call `actions.setCritiques` this sagas call itself recursively
  // every Y seconds until all "pending" critiques are "finished" or "failed"
  yield put(actions.setCritiques(pendingCritiques))
}

/**
 * Bind side-effect handlers
 */
export const sagas = function* saga() {
  yield all([
    takeLatest(actions.sendResume, sendResumeSaga),
    takeLatest(actions.fetchCritiques, fetchCritiquesSaga),
    takeLatest(actions.setCritiques, setCritiquesSaga),
  ])
}
