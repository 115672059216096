import { createBrowserHistory } from 'history'
import { NavigateProps } from 'react-router-dom'
import { APP_BASE_PATH } from './constants'

// eslint-disable-next-line @typescript-eslint/naming-convention
export const _history = createBrowserHistory()

export const navigate = (
  to: NavigateProps['to'] | number,
  options?: { replace: NavigateProps['replace'] },
) => {
  const path = `${APP_BASE_PATH}${to}`

  if (typeof to === 'number') {
    return _history.go(to)
  }

  if (options?.replace) {
    return _history.replace(path)
  }

  _history.push(path)
}
