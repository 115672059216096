import React, { useEffect, useRef, useState } from 'react'
import { Container, SubMenu } from './styles'

interface IProps {
  width?: string
  subMenu?: React.ReactNode
  className?: string
  position?: 'end' | 'start'
  children: React.ReactNode
}

export const MenuItem = ({ width, subMenu, children, className, position }: IProps) => {
  const [subMenuOpen, setSubMenu] = useState(false)
  const [submenuPosition, setSubmenuPosition] = useState(0)
  const menuItemRef = useRef<HTMLLIElement>(null)
  const subMenuRef = useRef<HTMLDivElement>(null)

  const subMenuPosition = () => {
    const menuItemBounds = menuItemRef.current?.getBoundingClientRect()
    const subMenuHeight = subMenuRef.current?.clientHeight || 0
    if (!subMenuHeight) return

    const offset = 10
    const menuItemPosition = menuItemBounds?.top || 0

    if (window.innerHeight - menuItemPosition < subMenuHeight) {
      const bottomDiff = subMenuHeight - (window.innerHeight - menuItemPosition)
      return menuItemPosition - bottomDiff - offset
    }

    return menuItemPosition - offset
  }

  useEffect(() => {
    if (subMenuOpen) {
      setSubmenuPosition(subMenuPosition() || 0)
    }
  }, [subMenuOpen])

  return (
    <Container
      ref={menuItemRef}
      className={className}
      position={position}
      onTouchStart={() => (subMenu ? setSubMenu(true) : undefined)}
      onMouseEnter={() => (subMenu ? setSubMenu(true) : undefined)}
      onMouseLeave={() => (subMenu ? setSubMenu(false) : undefined)}
    >
      {children}
      {subMenu && (
        <SubMenu
          ref={subMenuRef}
          isOpen={subMenuOpen}
          isSubMenu
          width={width}
          position={submenuPosition}
        >
          {subMenu}
        </SubMenu>
      )}
    </Container>
  )
}
