import type { Optional } from '@rio/types'

import { Suspense, lazy } from 'react'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { Group, Label, LoaderPlaceholder } from './styles'
import { SuggestionConfig } from './types'

const RichTextArea = lazy(() => {
  return import(/* webpackChunkName: "rich-text-area" */ './RichTextArea')
})

const NewRichTextArea = lazy(() => {
  return import(/* webpackChunkName: "new-rich-text" */ 'builder/components/TiptapEditor')
})

type PlaceholderProps = {
  label?: string
  height?: number
}

const Placeholder = ({ label = '', height = 200 }: PlaceholderProps) => (
  <Group>
    <Label>{label}</Label>
    <LoaderPlaceholder height={height} />
  </Group>
)

export interface TextAreaProps {
  autoFocus?: boolean
  recommendedLength?: number
  jobTitle?: Optional<string>
  suggestionConfig?: Optional<SuggestionConfig>
  label?: string
  placeholder?: string
  value?: string
  onChange: (html: string) => void
  locale?: string
  height?: number
  labelBadges?: JSX.Element[]
}

const Loader = (props: TextAreaProps) => {
  const { isPhone: isNewEditorEnabled } = useMediaQueries()
  return (
    <Suspense fallback={<Placeholder {...props} />}>
      {isNewEditorEnabled ? <NewRichTextArea {...props} /> : <RichTextArea {...props} />}
    </Suspense>
  )
}

export default Loader
