import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  actions as coachActions,
  selectors as coachSelectors,
} from 'builder/modules/careerCoaching'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as userSelectors } from 'builder/modules/user'

export const usePostPurchase = () => {
  const [isMorePreviousSessions, setIsMorePreviousSessions] = useState(false)
  const dispatch = useDispatch()
  const user = useTypedSelector(userSelectors.userData)
  const isCoachSessionsLoading = useTypedSelector(coachSelectors.isCoachSessionsLoading)

  useEffect(() => {
    const participantId = user?.id

    dispatch(coachActions.fetchCoachSessions({ participantId }))
  }, [dispatch, user?.id])

  const onShowMoreSessions = () => {
    setIsMorePreviousSessions(!isMorePreviousSessions)
  }

  return { isCoachSessionsLoading, onShowMoreSessions, isMorePreviousSessions, user }
}
