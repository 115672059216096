import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Button from 'builder/components/Button'

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 544px;
  background-color: ${Colors.White};
  border-radius: 6px;
  z-index: 999;
  padding: 32px;

  ${Media.Phone`
    height: 100%;
    max-width: none;
    max-height: none;
    border-radius: 0;
    padding: 20px;
  `};
`

export const Close = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const DesktopClose = styled(Close)`
  top: 0;
  right: 0;
  padding: 17px 16px;
  color: ${Colors.Neutral40};
  transition: color 0.15s;

  &:hover {
    color: ${Colors.Blue50};
  }
`

export const MobileClose = styled(Close)`
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${Colors.Neutral30};
  color: ${Colors.White};
`

export const Title = styled.div`
  ${Typography.S};
  font-weight: 600;
  margin-bottom: 8px;

  ${Media.Phone`
    max-width: 238px;
  `};
`

export const Text = styled.div`
  margin-bottom: 24px;

  ${Media.Phone`
    margin-bottom: 8px;
  `};
`

export const SelectContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;

  ${Media.Phone`
    flex-direction: column;
  `};
`

export const SelectWrapper = styled.div`
  width: 100%;
  max-width: 228px;

  ${Media.Phone`
    max-width: none;

    &:first-child {
      margin-bottom: 24px;
    }
  `};
`

export const Buttons = styled.div`
  display: flex;
  align-items: center;
`

export const DownloadButton = styled(Button)`
  margin-right: 8px;
`

export const SelectPlaceholderWrapper = styled.div``

export const SelectPlaceholderLabel = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
  margin-bottom: 6px;
`

export const SelectPlaceholderContainer = styled.div`
  width: 100%;
  height: 191px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 24px;
  background-color: ${Colors.Neutral10};
  border-radius: 4px;
`

export const SelectPlaceholderText = styled.div`
  color: ${Colors.Neutral50};
  margin-bottom: 8px;
`

export const SelectPlaceholderButton = styled.div`
  color: ${Colors.Blue50};
  cursor: pointer;

  &:hover {
    color: ${Colors.Blue70};
  }
`
