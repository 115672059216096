import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { i18n } from 'builder/utils/i18n'
import { TextField, TextAreaField } from 'builder/components/TextField'
import RichTextArea from 'builder/components/RichTextArea'
import Select from 'builder/components/Select'
import { SectionNames } from 'builder/modules/resumeEditor'
import { LevelSelect } from 'builder/components/LevelSelect'
import DateRangePicker from 'builder/components/DateRangePicker'
import { AsyncAutosuggest, createSuggestionsApiFetcher } from 'builder/components/AsyncAutosuggest'
import MediaQueries from 'builder/components/MediaQueries'
import { formatDateRange } from 'builder/utils/formatDateRange'
import Base from './Base'
import { WorkExperienceCard as WorkExperience } from './WorkExperienceCard'

import { CardContentWrapper, Row, Field } from './styles'
import { displayFieldPerTemplateConfig } from '../DocumentEditor/ATSResumeEditor/builderSectionsConfig'
import { TemplateName } from '../Helper/constants'

class Education extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    item: PropTypes.object,
    autoFocus: PropTypes.bool,
    sectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    locale: PropTypes.string,
    template: PropTypes.string,
  }

  formatTitle(item) {
    const joiningString = ' ' + i18n.t('builder.resume_editor.labels.at') + ' '

    return (
      [item.degree, item.school].filter(x => x).join(joiningString) ||
      i18n.t('builder.resume_editor.not_specified')
    )
  }

  updateSimpleField = e => {
    const { onChange, sectionId, item } = this.props
    onChange(sectionId, item.id, { [e.target.name]: e.target.value }, true)
  }

  updateDescription = val => {
    const { onChange, sectionId, item } = this.props
    onChange(sectionId, item.id, { description: val }, true)
  }

  updateDateRange = val => {
    const { onChange, sectionId, item } = this.props
    onChange(sectionId, item.id, val, true)
  }

  render() {
    const { item, autoFocus, locale, template, ...otherProps } = this.props
    const title = this.formatTitle(item)
    const titleDates = formatDateRange(item)
    const isAthensTemplate = template === TemplateName.Athens
    const isPragueTemplate = template === TemplateName.Prague

    const showDescriptionField = displayFieldPerTemplateConfig(
      template,
      SectionNames.educations,
      'description',
    )

    return (
      <Base title={title} subtitle={titleDates} item={item} {...otherProps}>
        <CardContentWrapper>
          <Row>
            <Field>
              <AsyncAutosuggest
                name="school"
                fetchItems={createSuggestionsApiFetcher('university', { limit: 4, locale })}
                value={item.school || ''}
                onChange={this.updateSimpleField}
              >
                {inputProps => (
                  <TextField
                    {...inputProps}
                    autoFocus={autoFocus}
                    label={i18n.t('builder.resume_editor.educations_card.school')}
                  />
                )}
              </AsyncAutosuggest>
            </Field>

            <Field>
              <TextField
                label={i18n.t('builder.resume_editor.educations_card.degree')}
                name="degree"
                onChange={this.updateSimpleField}
                value={item.degree || ''}
              />
            </Field>
          </Row>

          <Row>
            <Field>
              <DateRangePicker
                value={item}
                onChange={this.updateDateRange}
                currentlyLabel={i18n.t('builder.date_range_picker.currently.study')}
              />
            </Field>
            <Field>
              <AsyncAutosuggest
                highlightedQuery
                name="city"
                value={item.city || ''}
                onChange={this.updateSimpleField}
                fetchItems={createSuggestionsApiFetcher('city', { locale })}
              >
                {inputProps => (
                  <TextField
                    {...inputProps}
                    label={
                      isAthensTemplate || isPragueTemplate
                        ? i18n.t('builder.resume_editor.educations_card.location')
                        : i18n.t('builder.resume_editor.educations_card.city')
                    }
                  />
                )}
              </AsyncAutosuggest>
            </Field>
          </Row>

          {showDescriptionField && (
            <Row>
              <Field fullwidth>
                <RichTextArea
                  label={i18n.t('builder.resume_editor.educations_card.description')}
                  placeholder={i18n.t(
                    'builder.resume_editor.educations_card.description_placeholder',
                  )}
                  value={item.description || ''}
                  onChange={this.updateDescription}
                  locale={locale}
                />
              </Field>
            </Row>
          )}
        </CardContentWrapper>
      </Base>
    )
  }
}

class Activity extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    item: PropTypes.object,
    autoFocus: PropTypes.bool,
    sectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    locale: PropTypes.string,
    template: PropTypes.string,
  }

  formatTitle(item) {
    const joiningString = ' ' + i18n.t('builder.resume_editor.labels.at') + ' '

    return (
      [item.title, item.employer].filter(x => x).join(joiningString) ||
      i18n.t('builder.resume_editor.not_specified')
    )
  }

  updateSimpleField = e => {
    const { onChange, sectionId, item } = this.props
    onChange(sectionId, item.id, { [e.target.name]: e.target.value }, true)
  }

  updateDescription = val => {
    const { onChange, sectionId, item } = this.props
    onChange(sectionId, item.id, { description: val }, true)
  }

  updateDateRange = val => {
    const { onChange, sectionId, item } = this.props
    onChange(sectionId, item.id, val, true)
  }

  render() {
    const { item, autoFocus, locale, template, ...otherProps } = this.props
    const title = this.formatTitle(item)
    const titleDates = formatDateRange(item)
    const isAthensTemplate = template === TemplateName.Athens
    const isPragueTemplate = template === TemplateName.Prague

    const showDescriptionField = displayFieldPerTemplateConfig(
      template,
      SectionNames.activities,
      'description',
    )
    const showCityField = displayFieldPerTemplateConfig(template, SectionNames.activities, 'city')

    return (
      <Base title={title} subtitle={titleDates} item={item} {...otherProps}>
        <CardContentWrapper>
          <Row>
            <Field>
              <TextField
                autoFocus={autoFocus}
                label={
                  isAthensTemplate || isPragueTemplate
                    ? i18n.t('builder.resume_editor.activities_card.license_name')
                    : i18n.t('builder.resume_editor.activities_card.title')
                }
                name="title"
                onChange={this.updateSimpleField}
                value={item.title || ''}
              />
            </Field>

            <Field>
              <TextField
                label={
                  isAthensTemplate || isPragueTemplate
                    ? i18n.t('builder.resume_editor.activities_card.issuer')
                    : i18n.t('builder.resume_editor.activities_card.employer')
                }
                name="employer"
                onChange={this.updateSimpleField}
                value={item.employer || ''}
              />
            </Field>
          </Row>

          <Row>
            <Field>
              <DateRangePicker value={item} onChange={this.updateDateRange} />
            </Field>
            {showCityField && (
              <Field>
                <AsyncAutosuggest
                  highlightedQuery
                  name="city"
                  value={item.city || ''}
                  onChange={this.updateSimpleField}
                  fetchItems={createSuggestionsApiFetcher('city', { locale })}
                >
                  {inputProps => (
                    <TextField
                      {...inputProps}
                      label={
                        isAthensTemplate || isPragueTemplate
                          ? i18n.t('builder.resume_editor.activities_card.location')
                          : i18n.t('builder.resume_editor.activities_card.city')
                      }
                    />
                  )}
                </AsyncAutosuggest>
              </Field>
            )}
          </Row>

          {showDescriptionField && (
            <Row>
              <Field fullwidth>
                <RichTextArea
                  label={i18n.t('builder.resume_editor.activities_card.description')}
                  value={item.description || ''}
                  onChange={this.updateDescription}
                  locale={locale}
                />
              </Field>
            </Row>
          )}
        </CardContentWrapper>
      </Base>
    )
  }
}

class Course extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    item: PropTypes.object,
    autoFocus: PropTypes.bool,
    sectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    locale: PropTypes.string,
    template: PropTypes.string,
  }

  formatTitle(item) {
    const joiningString = ' ' + i18n.t('builder.resume_editor.labels.at') + ' '
    const title = [item.course, item.institution].filter(x => x).join(joiningString)
    return title || i18n.t('builder.resume_editor.not_specified')
  }

  updateSimpleField = e => {
    const { onChange, sectionId, item } = this.props
    onChange(sectionId, item.id, { [e.target.name]: e.target.value }, true)
  }

  updateDateRange = val => {
    const { onChange, sectionId, item } = this.props
    onChange(sectionId, item.id, val, true)
  }

  render() {
    const { item, autoFocus, template, ...otherProps } = this.props
    const title = this.formatTitle(item)
    const titleDates = formatDateRange(item)

    const showDatesField = displayFieldPerTemplateConfig(template, SectionNames.courses, 'dates')
    const showInstitutionField = displayFieldPerTemplateConfig(
      template,
      SectionNames.courses,
      'institution',
    )

    return (
      <Base title={title} subtitle={titleDates} item={item} {...otherProps}>
        <CardContentWrapper>
          <Row>
            <Field fullwidth={!showInstitutionField}>
              <TextField
                autoFocus={autoFocus}
                label={i18n.t('builder.resume_editor.courses_card.course')}
                name="course"
                onChange={this.updateSimpleField}
                value={item.course || ''}
              />
            </Field>

            {showInstitutionField && (
              <Field>
                <TextField
                  label={i18n.t('builder.resume_editor.courses_card.institution')}
                  name="institution"
                  onChange={this.updateSimpleField}
                  value={item.institution || ''}
                />
              </Field>
            )}
          </Row>

          {showDatesField && (
            <Row>
              <Field>
                <DateRangePicker value={item} onChange={this.updateDateRange} />
              </Field>
            </Row>
          )}
        </CardContentWrapper>
      </Base>
    )
  }
}

class Hobby extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    item: PropTypes.object,
    sectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }

  updateSimpleField = e => {
    const { onChange, sectionId, item } = this.props
    onChange(sectionId, item.id, { [e.target.name]: e.target.value }, true)
  }

  render() {
    const { item } = this.props
    return (
      <TextAreaField
        rows={3}
        label={i18n.t('builder.resume_editor.hobbies_card.hobby')}
        placeholder={i18n.t('builder.resume_editor.hobbies_card.hobby_placeholder')}
        name="hobby"
        onChange={this.updateSimpleField}
        value={item.hobby || ''}
      />
    )
  }
}

class Skill extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    item: PropTypes.object,
    autoFocus: PropTypes.bool,
    sectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hideSkillLevel: PropTypes.bool,
    locale: PropTypes.string,
    levels: PropTypes.object,
    template: PropTypes.string,
  }

  formatTitle(item) {
    return item.skill || i18n.t('builder.resume_editor.not_specified')
  }

  formatSubtitle(item) {
    const { hideSkillLevel } = this.props
    const isLevelVisible = !hideSkillLevel && item.level && item.level !== 'hidden'
    return isLevelVisible ? i18n.t(`builder.resume_editor.skill_level.${item.level}`) : ''
  }

  updateSimpleField = e => {
    const { onChange, sectionId, item } = this.props
    onChange(sectionId, item.id, { [e.target.name]: e.target.value }, true)
  }

  handleLevelSelect = value => {
    const { onChange, sectionId, item } = this.props
    onChange(sectionId, item.id, { level: value }, false)
  }

  render() {
    const { item, levels, hideSkillLevel, autoFocus, locale, template, ...otherProps } = this.props
    const title = this.formatTitle(item)
    const subtitle = this.formatSubtitle(item)

    const levelOptions = [
      { translation: i18n.t('builder.resume_editor.skills_card.select_level'), name: null },
      ...levels.skill,
    ].map(level => ({
      id: level.name,
      name: level.translation,
      rating: level.rating,
    }))

    const showLevelField = displayFieldPerTemplateConfig(template, SectionNames.skills, 'level')

    return (
      <Base title={title} subtitle={subtitle} item={item} {...otherProps}>
        <CardContentWrapper>
          <Row>
            <Field fullwidth={!showLevelField}>
              <AsyncAutosuggest
                name="skill"
                fetchItems={createSuggestionsApiFetcher('skill', { locale })}
                value={item.skill || ''}
                onChange={this.updateSimpleField}
              >
                {inputProps => (
                  <TextField
                    {...inputProps}
                    autoFocus={autoFocus && !item.skill}
                    label={i18n.t('builder.resume_editor.skills_card.skill')}
                  />
                )}
              </AsyncAutosuggest>
            </Field>

            {showLevelField && (
              <Field>
                <LevelSelect
                  label={i18n.t('builder.resume_editor.skills_card.level')}
                  selected={item.level || null}
                  options={levelOptions}
                  onChange={this.handleLevelSelect}
                  disabled={hideSkillLevel}
                />
              </Field>
            )}
          </Row>
        </CardContentWrapper>
      </Base>
    )
  }
}

class Language extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    item: PropTypes.object,
    autoFocus: PropTypes.bool,
    sectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    locale: PropTypes.string,
    levels: PropTypes.object,
    template: PropTypes.string,
  }

  formatTitle(item) {
    return item.language || i18n.t('builder.resume_editor.not_specified')
  }

  formatSubtitle(item) {
    return item.level ? i18n.t(`builder.resume_editor.language_level.${item.level}`) : ''
  }

  updateSimpleField = e => {
    const { onChange, sectionId, item } = this.props
    onChange(sectionId, item.id, { [e.target.name]: e.target.value }, true)
  }

  handleLevelSelect = value => {
    const { onChange, sectionId, item } = this.props
    onChange(sectionId, item.id, { level: value }, false)
  }

  render() {
    const { item, levels, autoFocus, locale, template, ...otherProps } = this.props
    const title = this.formatTitle(item)
    const subtitle = this.formatSubtitle(item)

    const levelOptions = [
      { translation: i18n.t('builder.resume_editor.languages_card.select_level'), name: null },
      ...levels.language,
    ].map(level => ({ id: level.name, name: level.translation }))

    const showLevelField = displayFieldPerTemplateConfig(template, SectionNames.skills, 'level')

    return (
      <Base title={title} subtitle={subtitle} item={item} {...otherProps}>
        <CardContentWrapper>
          <Row>
            <Field fullwidth={!showLevelField}>
              <AsyncAutosuggest
                name="language"
                fetchItems={createSuggestionsApiFetcher('language', { locale })}
                value={item.language || ''}
                onChange={this.updateSimpleField}
              >
                {inputProps => (
                  <TextField
                    {...inputProps}
                    autoFocus={autoFocus}
                    label={i18n.t('builder.resume_editor.languages_card.language')}
                  />
                )}
              </AsyncAutosuggest>
            </Field>

            {showLevelField && (
              <Field>
                <Select
                  label={i18n.t('builder.resume_editor.languages_card.level')}
                  selected={item.level || null}
                  onSelect={this.handleLevelSelect}
                  options={levelOptions}
                />
              </Field>
            )}
          </Row>
        </CardContentWrapper>
      </Base>
    )
  }
}

class Social extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    item: PropTypes.object,
    autoFocus: PropTypes.bool,
    sectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }

  updateSimpleField = e => {
    const { onChange, sectionId, item } = this.props
    onChange(sectionId, item.id, { [e.target.name]: e.target.value }, true)
  }

  render() {
    const { item, autoFocus, ...otherProps } = this.props

    return (
      <Base
        title={item.label || i18n.t('builder.resume_editor.not_specified')}
        subtitle={item.link || ''}
        item={item}
        {...otherProps}
      >
        <CardContentWrapper>
          <Row>
            <Field>
              <TextField
                autoFocus={autoFocus}
                label={i18n.t('builder.resume_editor.links_card.label')}
                name="label"
                onChange={this.updateSimpleField}
                value={item.label || ''}
              />
            </Field>

            <Field>
              <TextField
                label={i18n.t('builder.resume_editor.links_card.link')}
                name="link"
                onChange={this.updateSimpleField}
                value={item.link || ''}
              />
            </Field>
          </Row>
        </CardContentWrapper>
      </Base>
    )
  }
}

class Reference extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    item: PropTypes.object,
    autoFocus: PropTypes.bool,
    sectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }

  updateSimpleField = e => {
    const { onChange, sectionId, item } = this.props
    onChange(sectionId, item.id, { [e.target.name]: e.target.value }, true)
  }

  render() {
    const { item, autoFocus, ...otherProps } = this.props

    return (
      <Base
        title={item.name || i18n.t('builder.resume_editor.not_specified')}
        subtitle={item.company || ''}
        item={item}
        {...otherProps}
      >
        <CardContentWrapper>
          <Row>
            <Field>
              <TextField
                autoFocus={autoFocus}
                label={i18n.t('builder.resume_editor.references_card.name')}
                name="name"
                onChange={this.updateSimpleField}
                value={item.name || ''}
              />
            </Field>

            <Field>
              <TextField
                label={i18n.t('builder.resume_editor.references_card.company')}
                name="company"
                onChange={this.updateSimpleField}
                value={item.company || ''}
              />
            </Field>
          </Row>

          <Row>
            <Field>
              <TextField
                label={i18n.t('builder.resume_editor.references_card.phone')}
                name="phone"
                onChange={this.updateSimpleField}
                value={item.phone || ''}
              />
            </Field>

            <Field>
              <TextField
                label={i18n.t('builder.resume_editor.references_card.email')}
                name="email"
                onChange={this.updateSimpleField}
                value={item.email || ''}
              />
            </Field>
          </Row>
        </CardContentWrapper>
      </Base>
    )
  }
}

class Custom extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    item: PropTypes.object,
    autoFocus: PropTypes.bool,
    sectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    locale: PropTypes.string,
  }

  formatTitle(item) {
    return (
      [item.title, item.city].filter(x => x).join(', ') ||
      i18n.t('builder.resume_editor.not_specified')
    )
  }

  updateSimpleField = e => {
    const { onChange, sectionId, item } = this.props
    onChange(sectionId, item.id, { [e.target.name]: e.target.value }, true, true)
  }

  updateDescription = val => {
    const { onChange, sectionId, item } = this.props
    onChange(sectionId, item.id, { description: val }, true, true)
  }

  updateDateRange = val => {
    const { onChange, sectionId, item } = this.props
    onChange(sectionId, item.id, val, true, true)
  }

  render() {
    const { item, autoFocus, locale, ...otherProps } = this.props
    const title = this.formatTitle(item)
    const titleDates = formatDateRange(item)

    return (
      <Base title={title} subtitle={titleDates} item={item} isCustom={true} {...otherProps}>
        <CardContentWrapper>
          <Row>
            <Field>
              <TextField
                autoFocus={autoFocus}
                label={i18n.t('builder.resume_editor.custom_card.title')}
                name="title"
                onChange={this.updateSimpleField}
                value={item.title || ''}
              />
            </Field>

            <Field>
              <AsyncAutosuggest
                highlightedQuery
                name="city"
                value={item.city || ''}
                onChange={this.updateSimpleField}
                fetchItems={createSuggestionsApiFetcher('city', { locale })}
              >
                {inputProps => (
                  <TextField
                    {...inputProps}
                    label={i18n.t('builder.resume_editor.custom_card.city')}
                  />
                )}
              </AsyncAutosuggest>
            </Field>
          </Row>

          <Row>
            <Field>
              <DateRangePicker value={item} onChange={this.updateDateRange} />
            </Field>
          </Row>

          <Row>
            <Field fullwidth>
              <RichTextArea
                label={i18n.t('builder.resume_editor.custom_card.description')}
                value={item.description || ''}
                onChange={this.updateDescription}
                locale={locale}
              />
            </Field>
          </Row>
        </CardContentWrapper>
      </Base>
    )
  }
}

const withAutoFocus = Component => {
  return class AutoFocusHoc extends PureComponent {
    render() {
      return (
        <MediaQueries>
          {matches => <Component autoFocus={!matches.isPhone} {...this.props} />}
        </MediaQueries>
      )
    }
  }
}

class CategorizedSkill extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    item: PropTypes.object,
    autoFocus: PropTypes.bool,
    sectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hideSkillLevel: PropTypes.bool,
    locale: PropTypes.string,
    levels: PropTypes.object,
    template: PropTypes.string,
  }

  formatTitle(item) {
    return item.skill || i18n.t('builder.resume_editor.not_specified')
  }

  updateSimpleField = e => {
    const { onChange, sectionId, item } = this.props
    onChange(sectionId, item.id, { [e.target.name]: e.target.value }, true)
  }

  render() {
    const { item, autoFocus, ...otherProps } = this.props

    const title = this.formatTitle(item)

    return (
      <Base title={title} item={item} {...otherProps}>
        <CardContentWrapper>
          <Row>
            <Field>
              <TextField
                autoFocus={autoFocus}
                label={i18n.t('builder.resume_editor.skills_card.title')}
                name="skill"
                onChange={this.updateSimpleField}
                value={item.skill || ''}
              />
            </Field>
          </Row>
          <Row>
            <Field fullwidth>
              <TextAreaField
                rows={3}
                label={i18n.t('builder.resume_editor.skills_card.skills')}
                placeholder={i18n.t('builder.resume_editor.skills_card.skills')}
                name="details"
                onChange={this.updateSimpleField}
                value={item.details || ''}
              />
            </Field>
          </Row>
        </CardContentWrapper>
      </Base>
    )
  }
}

class Accomplishments extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    item: PropTypes.object,
    locale: PropTypes.string,
    sectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }

  updateSimpleField = value => {
    const { onChange, sectionId } = this.props
    onChange(sectionId, value, true)
  }

  render() {
    const { item, locale } = this.props
    return (
      <RichTextArea value={item.data || ''} onChange={this.updateSimpleField} locale={locale} />
    )
  }
}

export default {
  WorkExperience: withAutoFocus(WorkExperience),
  Education: withAutoFocus(Education),
  Skill: withAutoFocus(Skill),
  CategorizedSkill: withAutoFocus(CategorizedSkill),
  Language: withAutoFocus(Language),
  Activity: withAutoFocus(Activity),
  Course: withAutoFocus(Course),
  Social: withAutoFocus(Social),
  Reference: withAutoFocus(Reference),
  Custom: withAutoFocus(Custom),
  Hobby,
  Accomplishments,
}
