import times from 'lodash/times'
import { SkeletonRow, SkeletonAtomsRows } from './styles'

export const SkeletonRows = () => (
  <SkeletonAtomsRows>
    {times(3).map(key => (
      <SkeletonRow key={key} />
    ))}
  </SkeletonAtomsRows>
)
