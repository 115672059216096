import { ContentBlock, ContentState } from 'draft-js'
import { LinkElement } from './styles'
import { DecoratorCallback } from './types'

interface Props {
  children: JSX.Element
  contentState: ContentState
  entityKey: string
}

const Link = (props: Props) => {
  const { children, contentState, entityKey } = props
  const { url } = contentState.getEntity(entityKey).getData()

  return (
    <LinkElement href={url} title={url}>
      {children}
    </LinkElement>
  )
}

const findLinkEntities = (
  contentBlock: ContentBlock,
  callback: DecoratorCallback,
  contentState: ContentState,
) => {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity()
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK'
  }, callback)
}

export default {
  strategy: findLinkEntities,
  component: Link,
}
