import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import { Icon20 } from 'builder/components/Icon'

export const Container = styled.div<{ isOnboarding: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  ${({ isOnboarding }) =>
    isOnboarding &&
    css`
      margin-top: 10px;
    `}

  ${Media.Tablet`
    gap: 24px;
    flex-direction: column;
    margin-bottom: 10px;
  `}
`

export const Box = styled.div`
  flex: 1;
`

export const Header = styled.div`
  color: ${Colors.Neutral90};
  font-size: 33px;
  line-height: 36px;
  ${FontWeights.Regular};

  ${Media.Tablet`
    font-size: 39px;
    line-height: 40px;
    text-align: center;
  `}

  ${Media.Phone`
    font-size: 33px;
    line-height: 36px;
  `}
`

export const Name = styled.span`
  ${FontWeights.Medium};
`

export const SubHeader = styled.div`
  color: ${Colors.Neutral50};
  font-size: 19px;
  line-height: 24px;
  ${FontWeights.Regular};

  ${Media.Tablet`
    text-align: center;
  `}
`

export const Progress = styled.div<{ success: boolean }>`
  color: ${({ success }) => (success ? Colors.Green50 : Colors.Neutral50)};
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 19px;
  line-height: 24px;
  float: right;
  ${FontWeights.Medium};

  ${Media.Tablet`
    text-align: center;
  `}
`

export const Steps = styled.span`
  color: ${Colors.Neutral90};
`

export const CompleteIcon = styled(Icon20.StatusOffer)`
  color: #25b869;
`
