import { Optional } from 'packages/types'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { withMediaQueries } from 'builder/components/MediaQueries'
import SnackBar from 'builder/components/SnackBar'
import { MouseEventHandler, TextAreaChangeHandler } from 'builder/modules/ui'
import { UserData, selectors as userSelectors, actions } from 'builder/modules/user'
import SubscriptionCancellationModal from 'builder/components/SubscriptionCancellationModal'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useConfig } from 'builder/hooks/useConfig'
import { selectors } from 'builder/modules/init'
import {
  Cards,
  FeedbackType,
  MediaQueriesObject,
  PromotionTopic,
  ViewPortTypes,
  ReasonType,
} from '../../common/types'
import usePromotionCardActions from '../../hooks/usePromotionCardActions'
import usePromotionSectionActions from '../../hooks/usePromotionSectionActions'
import usePromotionSectionValues from '../../hooks/usePromotionSectionValues'
import { PauseModal } from '../PauseModal/PauseModal'
import { DiscountModal } from '../DiscountModal/DiscountModal'
import { FormView } from './FormView'

interface Props {
  availableFreePeriod?: 'week' | 'month'
  isACIORegisteredUser: boolean
  user: Optional<UserData>
}

type FormContextProps = {
  activeCard: Optional<Cards>
  feedbackType: Optional<FeedbackType>
  feedbackSubmitted: boolean
  discountErrorText: string | null
  discountSubsCancelStatus?: boolean
  handleOpenFeaturesOfferModal: () => void
  handlePromotionClick: (cardType: Optional<Cards>) => void
  isACIORegisteredUser: boolean
  isPromotionButtonLoading: boolean
  promotionButtonClickHandler: MouseEventHandler<void>
  promotionButtonText: string | string[]
  promotionDescription: string[] | JSX.Element
  promotionImage: string
  promotionTitle: string
  promotionTitleTopic: PromotionTopic
  promotionTopic: PromotionTopic
  successfulSubmit: boolean
  views: ViewPortTypes
  textAreaChangeHandler: TextAreaChangeHandler<void>
  textAreaPlaceholder: string
  textAreaValue: string
  dontNeedPromoSelectedIndex: number
  setDontNeedPromoSelectedIndex: (index: number) => void
}

const CancellationFormContext = React.createContext<FormContextProps>({} as FormContextProps)

const FormSection = ({
  availableFreePeriod,
  isACIORegisteredUser,
  user,
  mediaQueries,
}: Props & MediaQueriesObject) => {
  const [isPauseModalOpen, setPauseModalOpen] = React.useState<boolean>(false)
  const [isDiscountModalOpen, setDiscountModalOpen] = React.useState<boolean>(false)
  const [isFeaturesOfferedModalOpen, setFeaturesOfferedModalOpen] = React.useState<boolean>(false)
  const [isFormVisible, setIsFormVisible] = React.useState<boolean>(true)
  const [priceAfterDiscount, setPriceAfterDiscount] = React.useState<number | undefined>()
  const navigate = useNavigate()
  const userAccountData = useTypedSelector(userSelectors.userData)
  const dispatch = useDispatch()
  const alreadyGotFreePausePeriodDuringCancellation =
    userAccountData?.billingInfo?.alreadyGotFreePausePeriodDuringCancellation
  const locale = useTypedSelector(selectors.locale)
  const appConfig = useConfig()
  const isStayInTouchOfferEnabled = appConfig?.features.stayInTouchOffer

  const { activeCard, cancellationReason, handlePromotionClick, isCardClicked } =
    usePromotionCardActions()

  const {
    feedbackType,
    feedbackSubmitted,
    isPromotionButtonLoading,
    promotionButtonClickHandler,
    successfulSubmit,
    textAreaChangeHandler,
    textAreaValue,
    discountErrorText,
    discountSubsCancelStatus,
    dontNeedPromoSelectedIndex,
    setDontNeedPromoSelectedIndex,
  } = usePromotionSectionActions(activeCard, isACIORegisteredUser, user)

  const {
    promotionButtonText,
    promotionDescription,
    promotionImage,
    promotionTopic,
    promotionTitle,
    promotionTitleTopic,
    textAreaPlaceholder,
  } = usePromotionSectionValues(
    activeCard,
    feedbackSubmitted,
    isACIORegisteredUser,
    successfulSubmit,
    discountErrorText,
    discountSubsCancelStatus,
    priceAfterDiscount,
  )

  const config = useConfig()
  const isSubscriptionV4Enabled = config?.features.subCancellationV4 || false

  const views: ViewPortTypes = React.useMemo(
    () => ({
      isDesktop: !mediaQueries.isPhone && !mediaQueries.isTablet,
      isTablet: mediaQueries.isTablet && !mediaQueries.isPhone,
      isPhone: mediaQueries.isPhone,
    }),
    [mediaQueries],
  )

  const cancelSubscription = React.useCallback(() => {
    dispatch(
      actions.cancelUserSubscription({
        [ReasonType.cancel]: cancellationReason ?? '',
      }),
    )
  }, [dispatch, cancellationReason])

  const handleOpenFeaturesOfferModal = () => {
    if (isSubscriptionV4Enabled) {
      if (!discountSubsCancelStatus && locale !== 'nl-NL' && !isStayInTouchOfferEnabled) {
        setDiscountModalOpen(true)
      } else {
        cancelSubscription()
      }
    } else {
      if (!alreadyGotFreePausePeriodDuringCancellation && locale !== 'nl-NL') {
        setPauseModalOpen(true)
      } else {
        setFeaturesOfferedModalOpen(true)
        setIsFormVisible(false)
      }
    }
  }

  React.useEffect(() => {
    setIsFormVisible(true)
  }, [])

  React.useEffect(() => {
    if (userAccountData?.billingStatus === 'subscription') {
      const recurringAmount = userAccountData.billingInfo.recurringAmount
      if (recurringAmount) {
        setPriceAfterDiscount(Number(recurringAmount.slice(3)) / 2 / 100)
      }
    }
  }, [userAccountData])

  const handleContinueCancellation = () => {
    setFeaturesOfferedModalOpen(false)
    setIsFormVisible(true)
    if (locale === 'nl-NL') {
      cancelSubscription()
      return
    }

    if (!discountSubsCancelStatus && !isStayInTouchOfferEnabled) {
      setDiscountModalOpen(true)
    } else {
      if (!alreadyGotFreePausePeriodDuringCancellation) {
        setPauseModalOpen(true)
      }
    }
  }

  const handleSkipDiscount = () => {
    if (isSubscriptionV4Enabled) {
      cancelSubscription()
    } else {
      setFeaturesOfferedModalOpen(false)
      setIsFormVisible(true)
      if (!alreadyGotFreePausePeriodDuringCancellation && locale !== 'nl-NL') {
        setDiscountModalOpen(false)
        setPauseModalOpen(true)
      } else {
        cancelSubscription()
      }
    }
  }

  const handleCloseDiscount = () => {
    setDiscountModalOpen(false)
  }

  const contextValue: FormContextProps = {
    activeCard,
    feedbackSubmitted,
    feedbackType,
    handleOpenFeaturesOfferModal,
    handlePromotionClick,
    isPromotionButtonLoading,
    isACIORegisteredUser,
    promotionButtonClickHandler,
    promotionButtonText,
    promotionDescription,
    promotionImage,
    promotionTitle,
    promotionTitleTopic,
    promotionTopic,
    successfulSubmit,
    textAreaChangeHandler,
    textAreaPlaceholder,
    textAreaValue,
    views,
    discountErrorText,
    discountSubsCancelStatus,
    dontNeedPromoSelectedIndex,
    setDontNeedPromoSelectedIndex,
  }

  return (
    <CancellationFormContext.Provider value={contextValue}>
      {isFormVisible && <FormView {...{ isCardClicked }} />}
      {isFeaturesOfferedModalOpen && (
        <SubscriptionCancellationModal
          onClose={() => {
            setFeaturesOfferedModalOpen(false)
            setIsFormVisible(true)
            navigate('/')
          }}
          onContinueCancellation={handleContinueCancellation}
        />
      )}
      {isDiscountModalOpen && (
        <DiscountModal
          onContinueCancellation={handleSkipDiscount}
          onClose={handleCloseDiscount}
          priceAfterDiscount={priceAfterDiscount}
        />
      )}
      {isPauseModalOpen && (
        <PauseModal
          period={availableFreePeriod}
          cancellationReason={cancellationReason}
          onClose={() => setPauseModalOpen(false)}
        />
      )}
      <SnackBar />
    </CancellationFormContext.Provider>
  )
}

export default withMediaQueries(FormSection)

export const useCancellationFormContext = () => React.useContext(CancellationFormContext)
