import {
  NotificationParentContainer,
  NotificationContainer,
  NotificationContent,
  TickIcon,
} from './styles'

const CardMovedNotification = () => {
  return (
    <NotificationParentContainer>
      <NotificationContainer>
        <NotificationContent>
          <TickIcon />
          Job Moved
        </NotificationContent>
      </NotificationContainer>
    </NotificationParentContainer>
  )
}

export default CardMovedNotification
