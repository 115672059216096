import styled, { css } from 'styled-components'
import { HorizontalTransitionFade } from 'builder/components/HorizontalTransitionFade'
import { Typography, FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

interface TitleProps {
  currentRole: boolean
}

export const Container = styled.div<{ hasAccent?: boolean }>`
  border-radius: 16px;
  padding: 24px;
  background-color: var(
    ${props => (props.hasAccent ? '--color-background-accent' : '--color-background')}
  );
  position: relative;
  transition: background-color 150ms ease-in-out;

  ${Media.Phone`
    width: 240px;
    min-height: auto;
  `}
`

export const HoverArea = styled.div`
  position: absolute;
  inset: -16px;
`

const JobTitleContainer = styled.div`
  margin-bottom: 8px;
`

const ellipsesLimitedStyles = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const JobTitle = styled(JobTitleContainer)<TitleProps>`
  ${FontWeights.Medium};
  ${ellipsesLimitedStyles};
  margin-bottom: 0;
  width: ${({ currentRole }) => (currentRole ? '205px' : '300px')};

  ${props => Media.Phone`
    width: ${props.currentRole ? '180px' : '275px'};
  `}
`

export const TextContainer = styled.div`
  ${ellipsesLimitedStyles};
  display: flex;
  margin-right: 40px;
  flex-direction: column;
`

export const EmptyJobTitleText = styled(JobTitleContainer)`
  color: ${Colors.Neutral50};
`

export const Title = styled.span`
  ${Typography.Tiny};
  color: ${Colors.Neutral50};

  ${Container}:hover & {
    color: ${Colors.Neutral60};
  }
`

export const Value = styled.span`
  ${Typography.Subhead};
  color: ${Colors.Neutral90};
`

export const IconActive = styled(HorizontalTransitionFade)`
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: var(--color-special);
`
export const Details = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
export const Text = styled.span`
  ${Typography.ButtonS};
  ${FontWeights.DemiBold};
  color: var(--color-special);
`
export const SelectContainer = styled.div`
  gap: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const Badge = styled.div`
  padding: 4px 8px;
  border-radius: 24px;
  background: ${Colors.Green20};
`
export const BadgeText = styled.div`
  ${Typography.Tiny};
  ${FontWeights.DemiBold};
  color: ${Colors.Green50};
`
export const TitleContainer = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`
