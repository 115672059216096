import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import { Icon24, Icon20 } from 'builder/components/Icon'
import Typography, { FontWeights } from 'builder/styles/typography'
import { ColorsProp } from './CareerProfile'

export const Container = styled.div`
  z-index: 1;
  display: flex;
  gap: 18px;
  position: absolute;
  left: 52%;

  ${Media.Tablet`
    height: 100%;
    left: 59%;
    top: 0;
    align-items: center;
  `}

  ${Media.Phone`
    height: 50%;
    width: 100%;
    left: 0;
    top: 0;
    align-items: center;
    flex-direction: column-reverse;
  `}
`

export const EllipsisContainer = styled.div`
  position: relative;
  padding-top: 50px;
  left: -58px;
  opacity: 0.4;

  ${Media.Tablet`
    padding-top: 0;
  `};

  ${Media.Phone`
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 0;
    height: 162px;
    top: -20px;
    overflow: hidden;
    padding-top: 90px;
  `};
`

export const EllipseOne = styled.div`
  position: absolute;
  width: 421px;
  height: 421px;
  transform: translateY(-50%);
  border-radius: 421px;
  opacity: 0.64;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(195, 229, 254, 0) 68.75%,
    ${Colors.Indigo20} 100%
  );

  &.hover {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(195, 229, 254, 0) 68.75%,
      ${Colors.Indigo30} 100%
    );
  }

  ${Media.Tablet`
    width: 217px;
    height: 217px;
    border-radius: 217px;
  `};

  ${Media.Phone`
    border-radius: 304px;
    height: 304px;
    width: 304px;
  `};
`

export const EllipseTwo = styled.div`
  position: absolute;
  width: 268px;
  height: 268px;
  left: 75px;
  transform: translateY(-50%);
  border-radius: 268px;
  opacity: 0.6;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(195, 229, 254, 0) 68.75%,
    ${Colors.Indigo30} 100%
  );

  &.hover {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(195, 229, 254, 0) 68.75%,
      ${Colors.Indigo40} 100%
    );
  }

  ${Media.Tablet`
    left: 38px;
    width: 150px;
    height: 150px;
    border-radius: 150px;
  `};

  ${Media.Phone`
    border-radius: 202px;
    left: unset;
    width: 202px;
    height: 202px;
    top: 84px;
  `};
`

export const EllipseThree = styled.div`
  position: absolute;
  width: 129px;
  height: 129px;
  left: 140px;
  transform: translateY(-50%);
  border-radius: 129px;
  opacity: 0.4;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(195, 229, 254, 0) 68.75%,
    ${Colors.Indigo40} 100%
  );

  &.hover {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(195, 229, 254, 0) 68.75%,
      ${Colors.Indigo50} 100%
    );
  }

  ${Media.Tablet`
    left: 74px;
    width: 80px;
    height: 80px;
    border-radius: 80px;
  `};

  ${Media.Phone`
    border-radius: 115px;
    left: unset;
    width: 115px;
    height: 115px;
    top: 85px;
  `};
`

export const Avatar = styled.div`
  position: relative;
  left: 205px;
  top: 0;

  ${Media.Tablet`
    left: 110px;
    top:-11px;
  `};

  ${Media.Phone`
    position: absolute;
    left: unset;
    top: 1px;
  `};
`

export const ScoreWrapper = styled.div<{ percentageColor: ColorsProp }>`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: relative;
  background: ${({ percentageColor }) =>
    `linear-gradient(to top, ${percentageColor.start}, ${percentageColor.end})`};
  padding: 3.5px;
  top: 10px;

  ${Media.Tablet`
    width: 72px;
    height: 72px;
    padding: 4px;
  `};

  ${Media.Phone`
    left: unset;
    top: 40px;
    left: calc(50%);
    transform: translate(-50%);
  `};
`

export const AvatarIcon = styled.img`
  width: 73px;
  height: 73px;
  flex: 0 0 73px;
  border-radius: 50%;

  ${Media.Tablet`
    width: 64px;
    height: 64px;
  `};
`

export const AddIconContainer = styled.div`
  position: absolute;
  right: -12px;
  top: 0;
  z-index: 3;
  background-color: ${Colors.Indigo20};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.hover {
    background-color: ${Colors.Indigo30};
  }

  ${Media.Tablet`
    width: 32px;
    height: 32px;
    top: 10px;
  `};

  ${Media.Phone`
    left: unset;
    top: 44px;
    left: calc(50% + 30px);
    transform: translate(-50%);
  `};
`

export const AddIcon = styled(Icon24.Add)`
  color: ${Colors.Indigo50};

  &.hover {
    color: ${Colors.Indigo60};
  }
`

export const Score = styled.div<{ percentageColor: ColorsProp }>`
  width: 30px;
  text-align: center;
  border-radius: 30px;
  padding: 0 4px;
  position: absolute;
  left: 25px;
  bottom: -4px;
  background: ${({ percentageColor }) => percentageColor.start};
  color: ${Colors.White};
  ${Typography.Tiny};
  ${FontWeights.DemiBold};
`

export const Duration = styled.div`
  position: absolute;
  right: 50px;
  display: flex;
  color: ${Colors.Neutral50};
  ${FontWeights.Medium};
  ${Typography.Caption};
  align-items: center;
  gap: 4px;

  ${Media.Phone`
    display: none;
  `};
`

export const DurationOpen = styled.div`
  z-index: 4;
  position: absolute;
  display: flex;
  color: ${Colors.Indigo80};
  ${FontWeights.Medium};
  ${Typography.Caption};
  gap: 4px;
  align-items: center;
  right: 15px;

  ${Media.Tablet`
    top: 10px;
  `};

  ${Media.Phone`
    display: none;
  `};
`

export const ClockIcon = styled(Icon20.Clock)<{ $isOpen: boolean }>`
  svg {
    stroke: ${props => (props.$isOpen ? Colors.Indigo80 : Colors.Neutral50)};
  }
`

export const Dot = styled.div<{ isOpen: boolean }>`
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: ${props => (props.isOpen ? Colors.Indigo80 : Colors.Neutral50)};
`

export const DurationInfo = styled.span``
