import { i18n as I18n } from 'builder/utils/i18n'
import { JobInsight } from '../../types'
import { SectionHeader } from '../SectionHeader'
import { ListItem } from '../ListItem'
import { translationKey } from '../../constants'
import * as Styled from './styles'
import bannerImageUrl from './images/roles-might-suit-you.png'
import iconUrl from './images/internship-icon.svg'

type Props = {
  roles: JobInsight[]
}

export const RolesSection = ({ roles }: Props) => {
  return (
    <Styled.Container>
      <SectionHeader
        headerText={I18n.t(`${translationKey}.roles_might_suit_you`)}
        description={I18n.t(`${translationKey}.suggestions`)}
      >
        <Styled.HeaderImage src={bannerImageUrl} alt="Banner image" />
      </SectionHeader>

      <Styled.ListContainer>
        {roles.map((role, index) => (
          <ListItem
            key={index}
            header={role.title}
            subheader={role.salaryRange}
            description={role.description}
            iconUrl={iconUrl}
          />
        ))}
      </Styled.ListContainer>
    </Styled.Container>
  )
}
