import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import SubscriptionCancellationForm from 'builder/components/SubscriptionCancellationFormNew/components/Form/FormSection'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useUser } from 'builder/hooks/useUser'
import { UserBillingStatus } from 'builder/modules/user'
import { i18n as I18n } from 'builder/utils/i18n'

import { Container, Content, Description, Header, Title } from './styles'
import CancelledView from './CancelledView'

enum PageState {
  loading = 'loading',
  cancelling = 'cancelling',
  cancelled = 'cancelled',
  denied = 'denied',
}

export const SubscriptionCancellationViewStepThree = () => {
  const navigate = useNavigate()
  const user = useUser()
  const cancellationStatus = useTypedSelector(state => state.user.cancellationStatus)

  // The page is available for authorized users that have an active or cancelled subscription
  const pageState: PageState = useMemo(() => {
    if (!user) return PageState.loading
    if (user.billingStatus === UserBillingStatus.subscription) return PageState.cancelling
    if (user.billingStatus === UserBillingStatus.cancelled) return PageState.cancelled
    return PageState.denied
  }, [user])

  // Leave the page automatically
  useEffect(() => {
    if (pageState === PageState.denied) navigate('/account', { replace: true })
  }, [pageState, navigate])

  // Once the subscription is cancelled, scroll the page to the top
  // because the form is way longer than the success screen
  useEffect(() => {
    if (pageState === PageState.cancelled) window.scrollTo(0, 0)
  }, [pageState])

  const showCancelledView = cancellationStatus === 'LOADED' || pageState === PageState.cancelled

  if (pageState === PageState.cancelling || pageState === PageState.cancelled) {
    return (
      <Container>
        <Content>
          <Header showCancelledView={showCancelledView}>
            {showCancelledView ? (
              <CancelledView />
            ) : (
              <>
                <Title>
                  {I18n.t('builder.subscription_cancellation.subscription_cancellation_view.title')}
                </Title>
                <Description isCancelled={false}>
                  {I18n.t(
                    'builder.subscription_cancellation.subscription_cancellation_view.description',
                  )}
                </Description>
              </>
            )}
          </Header>
          {pageState === PageState.cancelling && (
            <SubscriptionCancellationForm
              availableFreePeriod={user ? user.availableFreePeriod : null}
              isACIORegisteredUser={!!user?.careerId}
              user={user}
            />
          )}
        </Content>
      </Container>
    )
  }
  return <></>
}
