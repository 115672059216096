import { useEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'

import { useI18n } from 'builder/hooks/useI18n'
import { useChromeExtension } from 'builder/hooks/useChromeExtension'
import useWebsiteHost from 'builder/components/CIO-Dasboard-2.0/hooks/useWebsiteHost'

import {
  Container,
  ImageContainer,
  InfoContainer,
  Image,
  TextContainer,
  Header,
  Subheader,
  ActionButton,
} from '../styles'
import JOBS_IMG from '../assets/job-card.png'
import { useChromeExtensionBanner } from '../hooks/useChromeExtensionBanner'

const TRANSLATION = 'builder.job_search.chrome_extension.card'

export const ChromeExtensionCardBanner = () => {
  const { i18n } = useI18n()
  const { getHost } = useWebsiteHost()
  const { isInstalled, canInstallExtension } = useChromeExtension()
  const { getExtension } = useChromeExtensionBanner({ type: 'job_card' })

  useEffect(() => {
    if (!isInstalled && canInstallExtension) {
      trackInternalEvent('view_banner_chrome', {
        host: getHost,
        label: 'job_search',
        source: 'job_card',
      })
    }
  }, [])

  if (isInstalled || !canInstallExtension) {
    return null
  }

  return (
    <Container>
      <InfoContainer>
        <TextContainer>
          <Header>{i18n.t(`${TRANSLATION}.header`)}</Header>
          <Subheader>{i18n.t(`${TRANSLATION}.sub_header`)}</Subheader>
        </TextContainer>
        <ActionButton onClick={getExtension}>
          {i18n.t(`${TRANSLATION}.approve_action`)}
        </ActionButton>
      </InfoContainer>
      <ImageContainer>
        <Image src={JOBS_IMG} />
      </ImageContainer>
    </Container>
  )
}
