import { useNavigate } from 'react-router-dom'
import { useI18n } from 'builder/hooks/useI18n'

import { OnboardStepsAction } from '../../types'
import { Actions, BackButton, Container, ContinueButton, Step, StepsCounter } from './styles'

const TRANSLATION = `builder.onboarding_ai_enhanced.actions`

interface IProps {
  activeStep: number
  setStep: (prop: number) => void
}

const StepsActions = ({ activeStep, setStep }: IProps) => {
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const steps = Object.values(OnboardStepsAction).map(item => Number(item))

  const onNextStep = () => {
    if (activeStep < 3) {
      setStep(activeStep + 1)
    } else {
      navigate('/?ai-refresh=true', { replace: true })
    }
  }

  const onPreviousStep = () => {
    setStep(activeStep - 1)
  }

  return (
    <Container>
      <StepsCounter>
        {steps.map((step: number) => (
          <Step key={step} active={Number(step) === activeStep} />
        ))}
      </StepsCounter>
      <Actions>
        {activeStep !== 1 && (
          <BackButton onClick={onPreviousStep}>{i18n.t(`${TRANSLATION}.back`)}</BackButton>
        )}
        <ContinueButton onClick={onNextStep}>
          {i18n.t(`${TRANSLATION}.${activeStep === 3 ? 'start' : 'continue'}`)}
        </ContinueButton>
      </Actions>
    </Container>
  )
}

export default StepsActions
