import React from 'react'
import { i18n } from 'builder/utils/i18n'
import { ScoreContent, ScoreLabel, ScoreValue } from './styles'

type Props = {
  score: number
}

export const TunerNewScore: React.FC<Props> = ({ score }) => {
  return (
    <ScoreContent>
      <ScoreValue score={score}>{score}</ScoreValue>
      <ScoreLabel>{i18n.t('builder.resume_tuner.banner.dashboard_score')}</ScoreLabel>
    </ScoreContent>
  )
}
