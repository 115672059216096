import styled, { css, keyframes } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Icon, { Icon20 } from 'builder/components/Icon'
import { rotation } from 'builder/styles/keyframes'
import Media from 'builder/styles/media'
import Button from 'builder/components/Button'
import Employment from './Images/Employment.svg'

const moveUp = keyframes`
  from {
    transform: translateY(30%);
  }
  to {
    transform: translateY(0%);
  }
`

export const MainContainer = styled.div`
  width: 544px;
  height: 556px;
  border-radius: 16px;
  z-index: 3;
  animation: ${moveUp} 0.3s ease-out forwards;

  ${Media.Phone`
    width: 343px;
    height: 410px;
  `};
`

export const TopContainer = styled.div`
  width: 544px;
  height: 300px;
  background: ${Colors.Neutral100};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  overflow: hidden;

  ${Media.Phone`
    width: 343px;
    height: 188px;
   
  `};
`

export const GradientContainer = styled.div`
  position: relative;
  width: 544px;
  height: 96px;
  top: -20%;
  background: linear-gradient(180deg, rgba(15, 20, 30, 0) 0%, rgba(15, 20, 30, 0.8) 100%);
  z-index: 4;

  ${Media.Phone`
    width: 343px;
    height: 48px;
    top: -63%;
  `};
`

export const BottomContainer = styled.div`
  width: 544px;
  background: ${Colors.White};
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  padding: 32px;
  overflow: hidden;

  ${Media.Phone`
    width: 343px;
    padding: 20px;
  `};
`

const radialGradient =
  'radial-gradient(100% 116.2% at 0% 0%, rgba(73, 81, 99, 0.2) 0%, rgba(0, 0, 0, 0) 100%)'

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 352px;
  height: 236px;
  border-radius: 16px;
  top: 60px;
  left: 96px;

  background: ${radialGradient},
    linear-gradient(180deg, rgba(30, 37, 50, 0.8) 0%, rgba(30, 37, 50, 0) 100%);

  ${Media.Phone`
    width:279px;
    left:32px;
    top: 0px;
    z-index:1;
  `}
`

export const DotsImage = styled.img`
  position: relative;
  left: 16px;
  top: 4px;
  margin-bottom: 10px;
  width: 35px;
  height: 23px;
`
export const InputWrapper = styled.div`
  display: flex;
`

export const JobPostingText = styled.input.attrs<{ isSuccess: boolean }>(({ isSuccess }) => {
  return css`
    ${Typography.Caption};
    ${FontWeights.Regular};
    position: relative;
    left: 16px;
    border: 2px solid ${isSuccess ? Colors.Green50 : Colors.Blue50};
    border-radius: 8px;
    width: 320px;
    height: 36px;
    padding-left: 32px;
    background-image: url(${Employment});
    background-repeat: no-repeat;
    background-position: 8px center;
    animation: ${moveUp} 0.5s ease-out forwards;

    ::placeholder {
      color: ${Colors.White};
      width: 240px;
    }

    ${Media.Phone`
    width: 254px;
    left: 13px;

    ::placeholder {
      width: 174px;
    }

  `}
  `
})

export const SuccessIcon = styled(Icon20.Tick)`
  fill: ${Colors.White};
  background: ${Colors.Green50};
  border-radius: 50%;
  height: 16px;
  width: 16px;
  position: relative;
  top: 10px;
  right: 10px;
`

export const JobsField = styled.img`
  position: relative;
  left: 16px;
  top: 4px;
  width: 320px;
  height: 152px;
  animation: ${moveUp} 0.5s ease-out forwards;

  ${Media.Phone`
    width: 254px;
    top: -8px;
    left: 13px;
  `}
`

export const JobPostingTitle = styled.div`
  ${Typography.Subhead};
  ${FontWeights.DemiBold}
  width: 480px;
  height: 28px;
  margin-bottom: 12px;
  color: ${Colors.Neutral90};

  ${Media.Phone`
    ${Typography.S};
    ${FontWeights.DemiBold}
    width: 303px;
    height: 48px;
  `}
`

export const JobPostingStatus = styled.div`
  ${Typography.Caption};
  ${FontWeights.Regular}
  color: ${Colors.Neutral50};
  margin-top: 4px;
`

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const LoadingIcon = styled.img`
  /* height: 29px;
  width: 29px; */
  color: ${Colors.Blue50};
  animation: ${rotation} 0.5s linear infinite;
  margin-left: 17.5px;
`

export const LoadingText = styled.p`
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: white;
  margin-left: 17.5px;
`

export const ValidationLoadingIcon = styled(Icon.Spinner)`
  color: ${Colors.Blue50};
  animation: ${rotation} 0.5s linear infinite;
`

export const ValidationSuccessIcon = styled(Icon.Success)`
  fill: ${Colors.Green50};
`

export const ValidationFailIcon = styled(Icon20.WarningCircle)`
  color: ${Colors.Red50};
  width: 16px;
  height: 16px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 32px;

  ${Media.Phone`
    margin-top:20px;
  `}
`

export const CancelButton = styled(Button).attrs`
  margin-right: 8px;
`

export const ContinueButton = styled(Button)``

export const CloseIconWrapper = styled.div<{ showHoverState: boolean }>(({ showHoverState }) => {
  return css`
    ${showHoverState
      ? ''
      : `& svg {
          &:hover {
            background: ${Colors.White};
            fill: ${Colors.Neutral70};
            cursor: default;
          }
        }`}
  `
})

export const CloseIcon = styled(Icon.AddSmall)`
  fill: ${Colors.Neutral70};
  background: ${Colors.White};
  border-radius: 50%;
  position: relative;
  left: 484px;
  top: 32px;
  height: 28px;
  width: 28px;
  cursor: pointer;
  transform: rotate(225deg);

  &:hover {
    background: ${Colors.Blue50};
    fill: ${Colors.White};
  }

  ${Media.Phone`
    height: 24px;
    width: 24px;
    left: 299px;
    top: 20px;
    z-index: 2;
  `}
`
