import { i18n } from 'builder/utils/i18n'
import { SectionNames } from 'builder/modules/resumeEditor'
import FocusManager from 'builder/services/FocusManager'
import { clampNumber } from 'builder/utils/clampNumber'
import { convertHtmlToText } from 'builder/utils/convertHtmlToText'
import { CheckFn } from '../types'

const MIN_SUMMARY_LENGTH = 75

export const checkSummaryLength: CheckFn = ({ profile }) => {
  const text = convertHtmlToText(profile || '')

  const progress = clampNumber(text.length / MIN_SUMMARY_LENGTH)

  return {
    sectionId: SectionNames.profile,
    progress,
    id: 'short_summary',
    title: i18n.t('builder.resume_tuner.tips.short_summary.title'),
    description: i18n.t('builder.resume_tuner.tips.short_summary.description'),
    subject: i18n.t('builder.resume_tuner.tips.short_summary.subject'),
    counter: i18n.t('builder.resume_tuner.tips.short_summary.counter', { count: text.length }),
    label: i18n.t(`builder.resume_tuner.issues.${progress === 0 ? 'no_summary' : 'short_summary'}`),
    action: () => FocusManager.focus(SectionNames.profile),
    actionText: i18n.t('builder.resume_tuner.tips.short_summary.action_text'),
  }
}
