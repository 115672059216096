import { HelpModalLabelPayload } from '../../FloatingCIOHelperButton/types'

export const PremierVirtualLabelData: HelpModalLabelPayload = {
  buttonTitle: 'Get ready for the Event',
  buttonDescription: 'Follow the steps and be prepared',
  modalTitle: `You're registered for the event! Get ready for it.`,
  modalDescription: 'Follow these steps to stand out and secure your dream job.',
  allStepCompletionStateTitle: 'Now you are ready for the event! Great Job!',
  allStepCompletionStateSubTitle:
    'Keep improving your professional level and you will get your dream job!',
}
