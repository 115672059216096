import { useState } from 'react'
import { useI18n } from 'builder/hooks/useI18n'

import {
  NotificationContainer,
  NotificationLogo,
  NotificationDivider,
  NotificationTitle,
  NotificationText,
  NotificationClose,
} from './styles'

export const TopResumeNotification = () => {
  const { i18n } = useI18n()
  const [isOpen, setOpen] = useState(true)

  if (!isOpen) return null

  return (
    <NotificationContainer>
      <NotificationLogo />
      <NotificationDivider />
      <NotificationTitle>{i18n.t('builder.panel.topresume_notification.title')}</NotificationTitle>
      <NotificationText>{i18n.t('builder.panel.topresume_notification.text')}</NotificationText>
      <NotificationClose onClick={() => setOpen(false)} />
    </NotificationContainer>
  )
}
