import { useState } from 'react'
import { Column, ComponentPreview, Group, Label } from 'builder/components/UiKit/styles'
import { Checkbox } from 'builder/components/Checkbox'
import { CustomLabel } from 'builder/components/UiKit/components/CheckboxSection/styles'
import { checkboxesState } from './constants'

export const CheckboxSection = () => {
  const [state, setState] = useState(checkboxesState)

  const onClickHandler = (index: number) => {
    const newState = [...state]
    newState[index].checked = !newState[index].checked
    setState(newState)
  }

  return (
    <Group>
      <Label>Checkbox</Label>
      <ComponentPreview>
        <Column>
          {state.map((checkbox, index) => (
            <Checkbox onChange={() => onClickHandler(index)} key={checkbox.label} {...checkbox} />
          ))}
          <Checkbox
            label={
              <CustomLabel>
                <b>Custom label</b>
                <span>Checkbox without onChange</span>
              </CustomLabel>
            }
            checked
          />
        </Column>
      </ComponentPreview>
    </Group>
  )
}
