import styled from 'styled-components'
import Colors from 'builder/styles/colors'

export const OpenMenuButton = styled.div`
  display: flex;
  align-items: center;
  color: ${Colors.Blue50};
  cursor: pointer;
`

export const CloseMenuButton = styled(OpenMenuButton)`
  color: ${Colors.Neutral30};
`
