import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  display: flex;
  height: 128px;
  padding: var(--S, 16px);
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: var(--S, 16px);
  background: ${Colors.Neutral5};
  width: 100%;
`

export const CustomImg = styled.img`
  width: 32px;
  height: 32px;
`

export const Title = styled.div`
  ${Typography.Caption}
  ${FontWeights.Regular}
  padding-top: 16px;
  color: ${Colors.Neutral50};
`

export const Result = styled.div`
  ${Typography.Body}
  ${FontWeights.Regular}
  padding-top: 4px;
  color: ${Colors.Neutral100};
  &:first-letter {
    text-transform: capitalize;
  }
`
