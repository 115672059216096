import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Button from 'builder/components/Button'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px;
  border-radius: 16px;
  color: ${Colors.Indigo80};
  background-color: ${Colors.Indigo10};
`

export const Image = styled.img`
  max-width: 192px;
  height: 107px;
  margin-bottom: 20px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 36px;
  flex: auto;

  ${Media.Tablet`
    margin-bottom: 0;
  `}

  ${Media.Phone`
    margin-bottom: 12px;
  `}
`

export const Title = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};
`

export const Text = styled.div`
  ${Typography.Caption};
`

export const StyledButton = styled(Button)`
  height: fit-content;
  background-color: ${Colors.Indigo80};

  &:hover,
  &:active,
  &:focus-visible {
    background-color: ${Colors.Indigo80};
  }
`

export const TabletContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  border-radius: 16px;
  color: ${Colors.Indigo80};
  background-color: ${Colors.Indigo10};

  ${Media.Phone`
    flex-direction: column;
    align-items: flex-start;
  `}
`

export const TabletImage = styled.img`
  max-width: 192px;
  height: 80px;
  margin-right: 24px;

  ${Media.Phone`
    align-self: center;
    margin-bottom: 20px;
  `}
`
