import SpellCheckEmitter from '../spellCheckEmitter'
import { Wrapper } from './styles'
import { useSpellingErrorActions } from './useSpellingErrorActions'

export interface Props {
  children: JSX.Element[]
  decoratedText: string
  spellCheckEmitter: SpellCheckEmitter
}

const SpellingError = (props: Props) => {
  const { children } = props

  const { handleMouseEnter, handleMouseLeave } = useSpellingErrorActions(props)

  return (
    <Wrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {children}
    </Wrapper>
  )
}

export default SpellingError
