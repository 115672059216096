import styled from 'styled-components'
import { Icon20 } from 'builder/components/Icon'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

export const ButtonsGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;

  ${Media.Phone`
    flex-direction: column-reverse;
    width: 100%;
  `};
`

export const ArrowTargetIconWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-right: 4px;
  color: ${Colors.White};
`

export const ArrowTargetIcon = styled(Icon20.ArrowTarget2)`
  width: 24px;
  height: 24px;
`
