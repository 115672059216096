import { CareerPathItem } from 'builder/modules/careerPath'
import { SkeletonRows } from '../Skeletons'
import * as CommonStyles from '../../styles'
import * as Styles from './styles'

type Props = {
  certifications: Array<CareerPathItem>
  isSkeleton: boolean
}

export const Certifications = ({ certifications, isSkeleton = false }: Props) => {
  if (!certifications?.length && !isSkeleton) return null

  return (
    <Styles.Wrapper>
      <CommonStyles.Title>Top Certifications Requested</CommonStyles.Title>
      {isSkeleton ? (
        <SkeletonRows />
      ) : (
        <Styles.Container>
          {certifications.map((certificate, index) => (
            <li key={index}>
              <Styles.Item>{certificate.title}</Styles.Item>
            </li>
          ))}
        </Styles.Container>
      )}
    </Styles.Wrapper>
  )
}
