export const STORAGE_KEYS = {
  shown: 'HELPER_SHOWN',
  doneTaskIds: 'HELPER_DONE_TASK_IDS',
}

export const MATCHING_ROUTES = ['/', '/resumes', '/cover-letters', '/job-tracking', '/job-search']

export enum TemplateName {
  Shokumukeirekisho = 'shokumukeirekisho',
  Rirekisho = 'rirekisho',
  Brussels = 'brussels',
  Athens = 'athens',
  Prague = 'prague',
  Shanghai = 'shanghai',
}

export enum SkillsFieldType {
  Categorized = 'categorized',
  Uncategorized = 'uncategorized',
}

export const JAPANESE_TEMPLATES = [TemplateName.Rirekisho, TemplateName.Shokumukeirekisho]

export const ATS_TEMPLATES = [
  TemplateName.Athens,
  TemplateName.Brussels,
  TemplateName.Prague,
  TemplateName.Shanghai,
]

export const LETTER_FORMAT_TEMPLATES = [
  TemplateName.Athens,
  TemplateName.Prague,
  TemplateName.Shanghai,
]

export const THIRD_JOB_SAVED = 3

export const TENTH_JOB_SAVED = 10

export const LOW_SCORE = 30
