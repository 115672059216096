import { Icon20 } from 'builder/components/Icon'
import * as Styles from './styles'
import cioRioInfoURL from './assets/RIO-CIO.webm'

interface IProps {
  handleClose: () => void
}

const ModalHeader = ({ handleClose }: IProps) => {
  return (
    <>
      <Styles.Close onClick={handleClose}>
        <Icon20.Close />
      </Styles.Close>
      <Styles.Visual src={cioRioInfoURL} autoPlay controls={false} muted />
    </>
  )
}

export default ModalHeader
