import styled, { css, keyframes } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Status } from 'builder/modules/interview/types'
import Media from 'builder/styles/media'

export const Shine = keyframes`
 to {
    background-position: 315px 0, 0 0, 0 190px, 50px 195px;
  }
`

export const Wrapper = styled.div`
  width: 100%;
`
export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  ${Media.Phone`
    flex-direction: column;
    padding: 0 20px;
  `};
  ${Media.Tablet`
    padding: 0 20px;
  `};
  ${Media.Laptop`
    padding: 0 20px;
  `};
`

export const TopLeft = styled.div`
  display: flex;
  align-items: baseline;
  gap: 10px;
  ${Media.Phone`
    flex-direction: column;
  `};
`

export const TopRight = styled.div`
  display: flex;
  ${Media.Phone`
    margin-top: 20px
  `};
`

export const MyInterviewTitle = styled.div`
  ${Typography.M};
  ${FontWeights.Medium};
`

export const For = styled.div`
  color: ${Colors.Neutral50};
  ${Typography.TextS};
  display: inline;
`

export const SelectedJobTitle = styled.div`
  color: ${Colors.Blue50};
  ${Typography.Subhead};
  ${FontWeights.Medium};
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  display: inline-flex;
  &:hover {
    color: ${Colors.Blue60};
  }
`

export const Stats = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

export const CompletedCount = styled.div`
  ${FontWeights.Medium};
`
export const Completed = styled.div`
  color: ${Colors.Neutral50};
  padding-right: 5px;
`

export const Interviews = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 15px;
  overflow-x: overlay;
  padding: 12px 0;

  ${Media.Laptop`
    padding: 12px 4px;
  `};
  -ms-overflow-style: none;
  scrollbar-width: none;
`

export const CardSkeleton = styled.div`
  min-width: 256px;
  max-width: 256px;
  height: 240px;
  background: ${Colors.Neutral5};
  background-image: linear-gradient(
    90deg,
    ${Colors.Neutral5} 0px,
    ${Colors.Neutral10} 45px,
    ${Colors.Neutral5} 90px
  );
  background-size: 300px;
  animation: ${Shine} 0.7s infinite cubic-bezier(0.03, 0.26, 0.5, 0.8);
`

export const InterviewCard = styled.div<{
  $status: Status
  $question: boolean
}>`
  min-width: 256px;
  max-width: 256px;
  height: 240px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  ${({ $status, $question }) => {
    switch ($status) {
      case 'completed':
        return css`
          border: 4px solid ${Colors.White};
          background-color: ${Colors.Neutral5};
          cursor: auto;
          pointer-events: none;
          &:hover {
            transition: background-color 100ms linear;
            background-color: ${Colors.Neutral10};
          }
        `
      case 'cancelled':
      case 'started':
      case 'initiated':
        return css`
          outline: 3px solid ${$question ? Colors.Blue50 : Colors.Green50};
          border: 4px solid ${Colors.White};
          background-color: ${$question ? Colors.Blue10 : Colors.Green10};
          &:hover {
            transition: background-color 100ms linear;
            background-color: ${$question ? Colors.Blue20 : Colors.Green20};
          }
        `
    }
  }}

  border-radius: 20px;
`

export const CardTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CardBottom = styled.div`
  display: flex;
  flex-direction: column;
`

export const DoneStatus = styled.div`
  color: ${Colors.Green50};
  ${Typography.Tiny};
  ${FontWeights.DemiBold};
  text-transform: capitalize;
`

export const Duration = styled.div<{ $question: boolean }>`
  display: flex;
  align-items: center;
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${({ $question }) => {
    if ($question) {
      return Colors.Blue80
    }
    return Colors.Green80
  }};
  svg {
    stroke: ${({ $question }) => {
      if ($question) {
        return Colors.Blue80
      }
      return Colors.Green80
    }};
  }
`

export const JobTitle = styled.div<{ $status: Status }>`
  padding-top: var(--size-2xs);
  ${Typography.Subhead};
  ${FontWeights.Medium};
  text-transform: capitalize;
  color: ${({ $status }) => {
    switch ($status) {
      case 'completed':
        return Colors.Neutral80
      case 'started':
      case 'initiated':
        return Colors.Green80
    }
  }};
`

export const SubInfo = styled.div`
  color: ${Colors.Neutral50};
  ${Typography.Body};
  ${FontWeights.Regular};
`

export const Question = styled.div<{ $status: Status }>`
  ${Typography.Subhead};
  ${FontWeights.Regular};
  color: ${({ $status }) => {
    switch ($status) {
      case 'completed':
        return Colors.Neutral80
      case 'started':
      case 'initiated':
        return Colors.Blue80
    }
  }};
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const RotationAnimation = keyframes`
  0%   { transform: rotate(0deg);   }
  100% { transform: rotate(360deg);  }
`

export const Spinner = styled.span`
  width: 24px;
  height: 24px;

  border: 2px solid ${Colors.Indigo80};
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;

  animation: ${RotationAnimation} ease-in-out 1s infinite;
  color: ${Colors.Indigo80};
`
