import styled from 'styled-components'
import Colors from 'builder/styles/colors'

export const StepsCounter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20px;
  gap: 8px;
  bottom: 0;
  background-color: ${Colors.White};
  padding: 10px;
`

export const BlueStep = styled.div`
  width: 16px;
  height: 6px;
  border-radius: 8px;
  background: ${Colors.Blue50};
`

export const GrayStep = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 8px;
  background: ${Colors.Neutral20};
`
