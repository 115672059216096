import { memo } from 'react'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { selectors } from 'builder/modules/careerCoaching'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { CareerCoachingStepProgress } from '../CareerCoachingStepProgress'
import {
  coachListingProgressTablet,
  coachListingProgressWeb,
} from '../CareerCoachingStepProgress/components/constants'
import SessionTabs from './components/SessionTabs/SessionTabs'
import MobileSessionTabs from './components/SessionTabs/MobileSessionTabs'
import { Loader } from './components/Loader'

const CareerCoachingSessions = (): JSX.Element => {
  const isLoading = useTypedSelector(selectors.isCoachesLoading)
  const isLoaded = useTypedSelector(selectors.isCoachesLoaded)
  const isFailed = useTypedSelector(selectors.isCoachesFailed)
  const { isPhone, isDesktop } = useMediaQueries()

  const renderView = () => {
    const TabsComponent = isPhone ? MobileSessionTabs : SessionTabs

    if (isLoading) {
      return <Loader />
    }

    return isLoading ? <Loader /> : <TabsComponent isLoaded={isLoaded} isFailed={isFailed} />
  }

  return (
    <>
      <CareerCoachingStepProgress
        activatedPage={isDesktop ? coachListingProgressWeb : coachListingProgressTablet}
        title="Choose Coach"
      />
      {renderView()}
    </>
  )
}

export default memo(CareerCoachingSessions)
