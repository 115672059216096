import { memo } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { i18n as I18n } from 'builder/utils/i18n'
import { PrefillResumeEvents } from 'builder/modules/constants'
import CreateNewResumeImg from '../images/create-new-resume.png'
import {
  Card,
  CardImgContainer,
  CreateNewResumeCardImg,
  CardDescription,
  CardTitle,
  CardSubTitle,
} from './styles'

type Props = {
  onClose: () => void
}

const CreateNewResume = (props: Props) => {
  const { onClose } = props

  const handleCardClick = () => {
    trackInternalEvent(PrefillResumeEvents.clickCreateResume)
    onClose()
  }

  return (
    <Card onClick={handleCardClick}>
      <CardImgContainer>
        <CreateNewResumeCardImg src={CreateNewResumeImg} />
      </CardImgContainer>
      <CardDescription>
        <CardTitle>
          {I18n.t('builder.prefill_resume.options_view.options.create_new_resume.title')}
        </CardTitle>
        <CardSubTitle>
          {I18n.t('builder.prefill_resume.options_view.options.create_new_resume.description')}
        </CardSubTitle>
      </CardDescription>
    </Card>
  )
}

export default memo(CreateNewResume)
