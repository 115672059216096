import { useI18n } from 'builder/hooks/useI18n'

import { SECTION_INFO_KEY, TRANSLATION } from '../constants'
import { CloseIcon, Container, HintIcon, InfoText } from './styles'

type Props = {
  isHidden: boolean
  setIsHidden: (prop: boolean) => void
  isSubscriptionCancelled?: boolean
}

export const InfoSection = ({ isHidden, setIsHidden, isSubscriptionCancelled }: Props) => {
  const { i18n } = useI18n()

  const onClose = () => {
    setIsHidden(true)
    localStorage.setItem(SECTION_INFO_KEY, 'true')
  }

  return (
    <Container isHidden={isHidden}>
      <HintIcon />
      <InfoText>
        {i18n.t(`${TRANSLATION}.info_section.${isSubscriptionCancelled ? 'canceled' : 'active'}`)}
      </InfoText>
      {!isSubscriptionCancelled && <CloseIcon onClick={onClose} />}
    </Container>
  )
}
