import styled, { css } from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'

export const Wrapper = styled.span<{ disabled?: boolean }>(({ disabled }) => {
  return css`
    position: relative;
    ${!disabled &&
    `
    background-size: 100% 2px;
    background-position: 0 100%;
    background-image: linear-gradient(
      ${hexToRgba(Colors.Red50, 0.6)},
      ${hexToRgba(Colors.Red50, 0.6)}
    );
    padding-bottom: 1px;
  `}
  `
})
