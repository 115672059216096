import styled, { css } from 'styled-components'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import Typography, { FontWeights } from 'builder/styles/typography'
import Shadows from 'builder/styles/shadows'
import Colors from 'builder/styles/colors'

type Props = {
  $isMultiline?: boolean
  $textAlign?: string
  $width?: number
}

export const TippyTooltip = styled(Tippy)<Props>`
  ${Typography.Caption};
  ${Shadows.dark.mid};
  ${FontWeights.Regular};
  letter-spacing: 0.3px;
  white-space: ${props => (props.$isMultiline ? 'normal' : 'nowrap')};
  width: ${props => (props.$isMultiline ? `${props.$width ?? 200}px` : 'auto')};
  color: ${Colors.White};
  background-color: ${Colors.Neutral90};
  border-radius: 4px;
  z-index: 1201;
  ${props =>
    props.$textAlign &&
    css`
      text-align: ${props.$textAlign};
    `};

  .tippy-content {
    padding: 8px 12px;
  }
`
