import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { Resume } from '@rio/types'
import { DocumentTypes } from 'builder/modules/constants'
import { actions as uiActions } from 'builder/modules/ui'
import { actions as renderingActions } from 'builder/modules/rendering'
import { useI18n } from 'builder/hooks/useI18n'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import ModalOverlay from 'builder/components/ModalOverlay'
import { Icon24 } from 'builder/components/Icon'
import Colors from 'builder/styles/colors'
import { useAnalyzer } from '../../hooks/useAnalyzer'
import { TaskIds, CheckIssue } from '../../types'
import {
  ModalContainer,
  ModalScore,
  ModalScoreContent,
  ModalScoreEmoji,
  ModalScoreValue,
  ModalScoreLabel,
  ModalTitle,
  ModalText,
  ModalTasks,
  ModalTask,
  ModalTaskContent,
  ModalTaskHeader,
  ModalTaskTitle,
  ModalTaskScore,
  ModalTaskText,
  ModalTaskSide,
  ModalActions,
  ModalButton,
  ModalClose,
} from './styles'

interface Props {
  resume: Resume
  onTaskClick: (taskId: TaskIds) => void
  onClose: () => void
}

export const TunerEditorStatusModal = ({ resume, onTaskClick, onClose }: Props) => {
  const dispatch = useDispatch()
  const { tasks, score } = useAnalyzer(resume)
  const { i18n } = useI18n()

  const done = useMemo(() => {
    return tasks?.every(task => task.done)
  }, [tasks])

  const [status, color, emoji] = useMemo(() => {
    if (score >= 10) return ['done', Colors.Green60, 'high']
    if (score >= 7) return ['high', Colors.Green60, 'high']
    if (score >= 5) return ['medium', Colors.Indigo80, 'medium']
    return ['low', Colors.Red50, 'low']
  }, [score])

  useEffectOnMount(() => {
    const incompleteTaskIds = tasks?.filter(task => !task.done).map(task => task.id)
    const issues = tasks?.reduce<CheckIssue[]>((acc, task) => acc.concat(task.issues), [])
    const incompleteIssueIds = issues?.filter(issue => issue.progress < 1).map(issue => issue.id)

    trackInternalEvent('see_tuner_status_modal', {
      resume_id: resume.id,
      tuner_score: score,
      incomplete_tasks: incompleteTaskIds,
      issues: incompleteIssueIds,
    })
  })

  const handleTaskClick = (taskId: TaskIds) => {
    trackInternalEvent('click_tuner_status_modal', { element: `${taskId}_card` })
    onTaskClick(taskId)
  }

  const handleClose = () => {
    trackInternalEvent('close_tuner_status_modal')
    onClose()
  }

  const handleDownload = () => {
    dispatch(renderingActions.download({ id: resume.id, type: DocumentTypes.resume }))
  }

  const handleShare = () => {
    dispatch(uiActions.openSharingModal(resume.id))
  }

  return (
    <ModalOverlay onClick={handleClose} overlayFadeDuration={150} contentSlideDuration={150}>
      <ModalContainer>
        <ModalScore color={color} progress={score / 10}>
          <ModalScoreContent>
            <ModalScoreEmoji emoji={emoji} />
            <ModalScoreValue>{score.toFixed(1)}</ModalScoreValue>
          </ModalScoreContent>
          <ModalScoreLabel>{i18n.t('builder.resume_tuner.banner.score')}</ModalScoreLabel>
        </ModalScore>

        <ModalTitle>{i18n.t(`builder.resume_tuner.status_modal.${status}.title`)}</ModalTitle>
        <ModalText>{i18n.t(`builder.resume_tuner.status_modal.${status}.description`)}</ModalText>

        {done ? (
          <ModalActions>
            <ModalButton onClick={handleDownload}>
              <Icon24.Download />
              {i18n.t('builder.resume_editor.download')}
            </ModalButton>
            <ModalButton onClick={handleShare}>
              <Icon24.Link />
              {i18n.t('builder.resume_editor.share')}
            </ModalButton>
          </ModalActions>
        ) : (
          <ModalTasks>
            {tasks?.map(task => (
              <ModalTask
                key={task.id}
                data-done={task.done}
                onClick={() => handleTaskClick(task.id)}
              >
                <ModalTaskContent>
                  <ModalTaskHeader>
                    <ModalTaskTitle>{task.title}</ModalTaskTitle>
                    <ModalTaskScore>
                      {i18n.t(`builder.resume_tuner.banner.task_score`, { points: task.score })}
                    </ModalTaskScore>
                  </ModalTaskHeader>
                  <ModalTaskText>{task.text}</ModalTaskText>
                </ModalTaskContent>
                <ModalTaskSide>
                  {task.done ? <Icon24.TickLarge /> : <Icon24.Chevron />}
                </ModalTaskSide>
              </ModalTask>
            ))}
          </ModalTasks>
        )}

        <ModalClose onClick={handleClose}>
          <Icon24.CloseLarge />
        </ModalClose>
      </ModalContainer>
    </ModalOverlay>
  )
}
