import { useDispatch } from 'react-redux'
import { actions } from 'builder/modules/panel'
import playImageButton from 'builder/components/Navigation/components/WelcomeModalButton/assets/welcome_modal_play_Buttons.svg'
import * as Styled from './styles'

export const WelcomeModalButtonPanel = () => {
  const dispatch = useDispatch()

  const handleOpenModal = () => {
    dispatch(actions.setOpenWelcomeModal(true))
  }

  return (
    <Styled.PanelButton onClick={handleOpenModal}>
      <Styled.Content>
        <Styled.Title>Explore Career.io</Styled.Title>
      </Styled.Content>
      <Styled.ImageWrapper>
        <Styled.ImagePlay src={playImageButton} />
      </Styled.ImageWrapper>
    </Styled.PanelButton>
  )
}
