import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useAutoTailoringAppConfig } from 'builder/hooks/useAutoTailoringAppConfig'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors, actions } from 'builder/modules/resumeEditor'
import { selectors as userSelectors } from 'builder/modules/user'
import { goToBillingPlanPage } from 'builder/utils/goToBillingPlanPage'
import { APP_BASE_PATH } from 'builder/modules/constants'

export const useAutoTailoring = () => {
  const dispatch = useDispatch()
  const { isAutoTailoringEnabled } = useAutoTailoringAppConfig()
  const resume = useTypedSelector(selectors.resume)
  const user = useTypedSelector(userSelectors.userData)
  const attempts = useTypedSelector(userSelectors.autoTailoringFreeAttempts)
  const isAutotailoredResume = !!resume?.autoTailored
  const showApplyAllButton = isAutoTailoringEnabled && !isAutotailoredResume
  const canUserAutoTailorResume =
    isAutoTailoringEnabled &&
    (!!user?.hasPremiumFeatures || (typeof attempts === 'number' && attempts > 0))

  const { jobPostingId } = useTypedSelector(selectors.jobPostingDataForAutoTailoring) || {}
  const addedKeywords = useTypedSelector(selectors.addedKeywordsForAutoTailoring)

  const navigateToBilling = useCallback(() => {
    if (resume && jobPostingId) {
      const data = {
        jobPostingId: jobPostingId,
        selectedKeywords: addedKeywords,
      }
      const dataStr = JSON.stringify(data)
      // eslint-disable-next-line max-len
      const onSuccessRedirect = `${APP_BASE_PATH}/resumes/${resume.id}/auto-tailoring?data=${dataStr}`

      goToBillingPlanPage({ onSuccessRedirect })
    } else {
      goToBillingPlanPage()
    }
  }, [resume, jobPostingId, addedKeywords])

  const tryToStartAutoTailoring = useCallback(() => {
    if (canUserAutoTailorResume) {
      dispatch(actions.setAutoTailoringModalStep('keywords_selection'))
    } else {
      navigateToBilling()
    }
  }, [dispatch, navigateToBilling, canUserAutoTailorResume])

  return {
    showApplyAllButton,
    tryToStartAutoTailoring,
    navigateToBilling,
    isAutoTailoringEnabled,
  }
}
