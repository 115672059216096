import { Route, Routes } from 'react-router-dom'
import { CustomInterviewView } from './components/CustomInterviewView/CustomInterviewView'
import { InterviewDashboardView } from './components/DashboardView/DashboardView'
import { FeedbackView } from './components/FeedbackView/FeedbackView'
import { InterviewPlayerView } from './components/InterviewPlayerView/InterviewPlayerView'
import { QuestionsLibraryView } from './components/QuestionsLibraryView/QuestionsLibraryView'
import InterviewStartView from './components/Start/InterviewStartView'

export const InterviewPreparation = () => {
  return (
    <Routes>
      <Route path="start" element={<InterviewStartView />} />
      <Route path="interview-player" element={<InterviewPlayerView />} />
      <Route path="dashboard" element={<InterviewDashboardView />} />
      <Route path="questions-library" element={<QuestionsLibraryView />} />
      <Route path="feedback" element={<FeedbackView />} />
      <Route path="custom-interview" element={<CustomInterviewView />} />
    </Routes>
  )
}
