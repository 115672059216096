import Service from './Service'

import { ServicesContainer } from './styles'

interface Service {
  name: string
  title: string
  domain: string
  connected: boolean
  theme: string
  websites: string[]
  disabled: boolean
}

interface ServicesProps {
  services: Service[]
  checkedNames: string[]
  toggleService: (name: string) => void
}

const Services = ({ services, checkedNames, toggleService }: ServicesProps) => {
  return (
    <ServicesContainer>
      {services.map(service => (
        <Service
          {...service}
          key={service.name}
          checked={checkedNames.includes(service.name) || services.length === 1}
          onToggle={() => toggleService(service.name)}
          disabled={services.length <= 1}
        />
      ))}
    </ServicesContainer>
  )
}

export default Services
