import styled from 'styled-components'
import { Colors } from 'builder/styles/colors'

export const IconsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px 24px;
`

export const IconsItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 89px;
`

export const IconsItemPreview = styled.div`
  display: flex;
  border: 1px solid ${Colors.Neutral10};
`
