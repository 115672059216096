import { useEffect, useState } from 'react'
import { useI18n } from 'builder/hooks/useI18n'
import {
  Container,
  Item,
  ArrowTargetIconWrapper,
  ArrowTargetIcon,
  Completeness50Icon,
} from './styles'

const sections = ['job_title', 'professional_summary', 'skills', 'work_experience']

interface TailoringSectionsProps {
  isPassive?: boolean
  isLoading?: boolean
}

export const TailoringSections: React.FC<TailoringSectionsProps> = ({
  isPassive = false,
  isLoading = false,
}) => {
  const { i18n } = useI18n()
  const [activeSectionIndex, setActiveSectionIndex] = useState(-1)

  useEffect(() => {
    if (!isPassive && isLoading) {
      const interval = setInterval(() => {
        setActiveSectionIndex(prevIndex => {
          const nextIndex = prevIndex + 1
          return nextIndex < sections.length ? nextIndex : -1
        })
      }, 1000)

      return () => clearInterval(interval)
    } else {
      setActiveSectionIndex(-1)
    }
  }, [isPassive, isLoading])

  return (
    <Container>
      {sections.map((sectionKey, index) => (
        <Item key={sectionKey} isPassive={isPassive || (isLoading && index > activeSectionIndex)}>
          {isPassive || (isLoading && index > activeSectionIndex) ? (
            <Completeness50Icon />
          ) : (
            <ArrowTargetIconWrapper>
              <ArrowTargetIcon />
            </ArrowTargetIconWrapper>
          )}
          {i18n.t(`builder.auto_tailoring.sections.${sectionKey}`)}
        </Item>
      ))}
    </Container>
  )
}
