import { Outlet, Navigate } from 'react-router-dom'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { useResumeDistribution } from '../ResumeDistributionView/hooks/useResumeDistribution'

export const ResumeDistributionRoute = () => {
  const features = useFeaturesConfig()
  const { isUserRdPromotedWithoutPlan } = useResumeDistribution()

  if (!features?.showResumeDistribution() || isUserRdPromotedWithoutPlan) {
    return <Navigate to="/" />
  }

  return <Outlet />
}
