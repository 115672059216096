import { IconImg } from './styles'
import { getEventBannerSrc } from './utils/bannerImg'

interface IEventBannerImage {
  src: string
}

export const EventBannerImage = ({ src }: IEventBannerImage) => {
  const imageSrc = getEventBannerSrc(src)
  return <IconImg url={imageSrc} data-testid="img-event-banner" />
}
