import * as CommonStyles from '../../styles'
import { SkeletonArea } from '../Skeletons'
import * as Styles from './styles'

export const Skeleton = () => {
  return (
    <Styles.Wrapper>
      <CommonStyles.Title>Requested Education</CommonStyles.Title>
      <Styles.Chart>
        <SkeletonArea />
      </Styles.Chart>
    </Styles.Wrapper>
  )
}
