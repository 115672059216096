import { useContext } from 'react'
import * as React from 'react'
import { WizardContext } from 'builder/components/Wizard'
import { SectionHintContainer, SectionSubtitleContainer } from './styles'

export const SectionHint: React.FC = ({ children }) => {
  const wizard = useContext(WizardContext)

  if (typeof wizard !== 'undefined') {
    return <SectionSubtitleContainer>{children}</SectionSubtitleContainer>
  }

  return <SectionHintContainer>{children}</SectionHintContainer>
}
