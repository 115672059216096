import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Button from 'builder/components/Button'
import Sizes from 'builder/styles/sizes'
import { Icon20 } from 'builder/components/Icon'

export const ArrowTargetIcon = styled(Icon20.ArrowTarget2)`
  width: 20px;
  height: 20px;
`

export const AutoTailoringBaseButton = styled(Button)`
  ${Typography.Body};
  ${FontWeights.Medium};
  padding: 12px 24px 12px 16px;
  border-radius: ${Sizes['3XS']};
  background: ${Colors.Indigo50};
  color: ${Colors.White};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${Sizes['3XS']};
  &:hover {
    background: ${Colors.Indigo60};
  }
`

export const ApplyAllButton = styled(AutoTailoringBaseButton)<{ $hasFullWidth?: boolean }>`
  font-size: 15px;
  line-height: 18px;
  padding: 7px 12px 7px 8px;
  width: ${props => (props.$hasFullWidth ? '100%' : 'fit-content')};
`
