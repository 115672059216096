import { useMemo } from 'react'
import { ConfigScopesEnum } from 'builder/modules/init'
import { useConfig } from 'builder/hooks/useConfig'
import { useI18n } from 'builder/hooks/useI18n'
import { generateLinkMarkup } from './utils'
import * as Styled from './styles'

export const SignUpCopyright = () => {
  const { i18n } = useI18n()
  const config = useConfig(ConfigScopesEnum.signUp)

  const markup = useMemo(() => {
    if (!config) return ''

    const termsOfUseText = i18n.t('builder.sign_up.terms_of_use')
    const termsAndConditionsText = i18n.t('builder.sign_up.terms_and_conditions')
    const privacyPolicyText = i18n.t('builder.sign_up.privacy_policy')

    const { links } = config
    const termsLink = generateLinkMarkup(termsOfUseText, links.tos)
    const privacyLink = generateLinkMarkup(privacyPolicyText, links.privacyPolicy)

    if (links.topResumeTos && links.topResumePrivacyPolicy) {
      return i18n.t('builder.sign_up.agreement_description_with_topresume', {
        termsLink,
        privacyLink,
        topResumeTermsLink: generateLinkMarkup(termsAndConditionsText, links.topResumeTos),
        topResumePrivacyLink: generateLinkMarkup(privacyPolicyText, links.topResumePrivacyPolicy),
      })
    }

    return i18n.t('builder.sign_up.agreement_description', { termsLink, privacyLink })
  }, [config, i18n])

  return <Styled.Wrapper dangerouslySetInnerHTML={{ __html: markup }} />
}
