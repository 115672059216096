import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import { Icon24 } from 'builder/components/Icon'

export const Banner = styled.div<{
  animate: boolean
  isPageScrolled: boolean
  isInsidePanel: boolean
}>(({ animate, isPageScrolled, isInsidePanel }) => {
  return css`
    width: 100%;
    height: 100%;
    background: ${Colors.Indigo10};
    display: flex;
    border-radius: 8px;
    cursor: pointer;
    align-items: center;
    padding: 8px 0 8px 8px;
    margin-top: 12px;

    &:hover {
      background: ${Colors.Indigo110};
    }

    ${animate &&
    css`
      opacity: 0;
      transition: opacity 300ms ease-in;
      &:hover {
        background: white;
      }
    `}

    ${isPageScrolled &&
    css`
      margin-top: 0;
    `}

    ${isInsidePanel &&
    css`
      margin-top: 0;
      margin-bottom: 42px;
    `}

      ${Media.Tablet`
      margin-bottom: 32px;
    `}

    ${Media.Phone`
      padding: 10px 8px;
    `}
  `
})

export const ArrowIcon = styled(Icon24.ChevronRight)`
  fill: ${Colors.Indigo60};
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;

  ${Banner}:hover & {
    fill: ${Colors.Indigo70};
  }
`

export const BannerImage = styled.img`
  width: 66px;
  height: 45px;
  margin: 7px 9px 3px 6px;

  ${Media.Phone`
    width: 60px;
    height: 41px;
    margin: unset;
  `}
`

export const BannerTitle = styled.p`
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${Colors.Indigo90};

  ${Banner}:hover & {
    color: ${Colors.Indigo70};
  }

  ${Media.Phone`
    width: 170px;
  `}
`
export const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  margin-right: 8px;

  ${Media.Phone`
    margin-right: 0;
  `}
`

export const ActionText = styled.p`
  font-size: 16px;
  ${FontWeights.Regular};
  color: ${Colors.Indigo60};

  ${Banner}:hover & {
    color: ${Colors.Indigo70};
  }
`
