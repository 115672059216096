import { Container, Title, CoachCardContainer } from './styles'
import { CoachAvailable } from './components/CoachAvailable'

const CareerPostPurchaseBanner = (): JSX.Element => {
  return (
    <Container>
      <Title>Coaching Package</Title>
      <CoachCardContainer>
        <CoachAvailable />
      </CoachCardContainer>
    </Container>
  )
}

export default CareerPostPurchaseBanner
