import { useState } from 'react'
import { ComponentPreview, Group, Label } from 'builder/components/UiKit/styles'
import { NonModalDialog, NonModalDialogProps } from 'builder/components/NonModalDialog'
import Button from 'builder/components/Button'

const defaultProps: NonModalDialogProps = {
  size: 'M',
  placement: 'bottom',
  title: `I'm a headline`,
  description: 'And I’m a body text',
  onOkButtonClick: () => {},
  okButtonText: 'Ok',
  cancelButtonText: 'Cancel',
}

export const NonModalDialogSection = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [componentProps, setComponentProps] = useState(defaultProps)

  return (
    <Group>
      <Label>Non-modal Dialog</Label>
      <ComponentPreview
        style={{ display: 'flex', flexWrap: 'wrap', gap: '12px', alignItems: 'center' }}
      >
        <Button
          onClick={() => {
            setComponentProps({
              ...defaultProps,
              size: 'M',
              placement: 'top',
            })
            setIsOpen(true)
          }}
        >
          Size: M, placement: top
        </Button>

        <Button
          onClick={() => {
            setComponentProps({
              ...defaultProps,
              size: 'M',
              placement: 'bottom',
            })
            setIsOpen(true)
          }}
        >
          Size: M, placement: bottom
        </Button>

        <Button
          onClick={() => {
            setComponentProps({
              ...defaultProps,
              size: 'M',
              placement: 'bottom',
              cancelButtonText: '',
            })
            setIsOpen(true)
          }}
        >
          Size: M, placement: bottom, only OK button
        </Button>

        <Button
          onClick={() => {
            setComponentProps({
              ...defaultProps,
              size: 'S',
              placement: 'top',
            })
            setIsOpen(true)
          }}
        >
          Size: S, placement: top
        </Button>

        <Button
          onClick={() => {
            setComponentProps({
              ...defaultProps,
              size: 'S',
              placement: 'bottom',
            })
            setIsOpen(true)
          }}
        >
          Size: S, placement: bottom
        </Button>

        <Button
          onClick={() => {
            setComponentProps({
              ...defaultProps,
              size: 'S',
              placement: 'bottom',
              cancelButtonText: '',
            })
            setIsOpen(true)
          }}
        >
          Size: S, placement: bottom, only OK button
        </Button>

        <NonModalDialog
          {...componentProps}
          isOpen={isOpen}
          onCancelButtonClick={() => {
            setIsOpen(false)
          }}
          onOkButtonClick={() => {
            setIsOpen(false)
          }}
          onCloseClick={() => {
            setIsOpen(false)
          }}
          onCloseAnimationEnd={() => {
            setComponentProps(defaultProps)
          }}
        />
      </ComponentPreview>
    </Group>
  )
}
