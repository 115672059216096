import styled, { css, keyframes } from 'styled-components'
import Button from 'builder/components/Button'
import { Tab } from 'builder/components/Tabs'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'

const Shine = keyframes`
 to {
    background-position: 315px 0, 0 0, 0 190px, 50px 195px;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`
export const Title = styled.div`
  display: flex;
  color: ${Colors.Neutral90};
  ${Typography.S};
  ${FontWeights.Medium};
  padding-right: 8px;
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ${Media.Phone`
    flex-flow: column;
    margin-right: 20px;
  `}
`

export const TitleContainer = styled.div`
  display: flex;
  ${Media.Phone`
  flex-flow: column;
  
  `}
`

export const RightText = styled.div`
  color: ${Colors.Neutral50};
  ${Typography.Body};
  ${FontWeights.Regular};
`

export const CardsContainer = styled.div`
  padding-top: 32px;
  display: flex;
  gap: 16px;
  overflow-x: overlay;
  padding-bottom: 20px;
  ${Media.Phone`
    padding-top: 16px;
  `}
`

export const CustomTab = styled(Tab)`
  ${Typography.Body};
  ${FontWeights.Regular};
  padding-bottom: 21px;
`

const CustomTablet = css`
  ${Typography.Body};
  ${FontWeights.Regular};

  gap: 4px;
  padding-right: 12px;
  padding-left: 8px;
  height: 36px;
`

export const CustomTabletDesktopButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  ${CustomTablet};
  ${Media.Phone`
    display: none;
  `}
`

export const CustomMobileButton = styled(Button)`
  ${CustomTablet};
  ${FontWeights.Medium};

  display: none;
  ${Media.Phone`
    margin-top: 16px;
    margin-top: 24px;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
  `}
`

export const CardSkeleton = styled.div`
  width: 268px;
  height: 336px;
  border-radius: var(--S, 16px);
  border: var(--Zero, 1px) solid ${Colors.Neutral15};
  background: ${Colors.Neutral5};
  background-image: linear-gradient(
    90deg,
    ${Colors.Neutral5} 0px,
    ${Colors.Neutral10} 45px,
    ${Colors.Neutral5} 90px
  );
  background-size: 300px;
  animation: ${Shine} 0.7s infinite cubic-bezier(0.03, 0.26, 0.5, 0.8);
  flex-shrink: 0;
`
export const DesktopContainer = styled.div`
  ${Media.Phone`
    display: none;
  `};
`

export const MobileContainer = styled.div`
  display: none;

  ${Media.Phone`
  display: flex;
    margin-bottom: 12px;
  `};
`
