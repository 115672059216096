import * as Styled from './styles'
const TrackJobs = require('../../assets/TrackJobsBanner.svg')

export const FindTrackJobs = ({ userPhotoUrl }: { userPhotoUrl: string | undefined }) => {
  return (
    <Styled.FindTrackJobsContainer>
      <Styled.FindTrackJobsImageContainer>
        <Styled.FindTrackJobsImage src={TrackJobs} />
        <Styled.FindTrackJobsUserContainer>
          <Styled.FindTrackJobsUserImage src={userPhotoUrl} />
        </Styled.FindTrackJobsUserContainer>
      </Styled.FindTrackJobsImageContainer>
    </Styled.FindTrackJobsContainer>
  )
}
