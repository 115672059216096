import { ForwardedRef, forwardRef, Fragment } from 'react'
import { Icon20 } from 'builder/components/Icon'
import { i18n as I18n } from 'builder/utils/i18n'
import {
  Action,
  ActionIcon,
  ActionText,
  NewBadgeActionText,
  StarIcon,
  SuggestionTogglerContainer,
} from './styles'

interface Props {
  isActive: boolean
  isAiAction: boolean
  text?: string
  onToggle: () => void
}

export const SuggestionsToggler = forwardRef((props: Props, ref?: ForwardedRef<HTMLDivElement>) => {
  const { isAiAction, isActive, onToggle, text } = props

  return (
    <SuggestionTogglerContainer {...{ isAiAction, isActive }}>
      <Action onClick={() => onToggle()} {...{ isAiAction, isActive, ref }}>
        {isAiAction && (
          <Fragment>
            <StarIcon>
              <Icon20.Featured />
            </StarIcon>
            <NewBadgeActionText>
              {text || I18n.t('builder.resume_editor.phrase_suggestion.ai_prewritten_phrases')}
            </NewBadgeActionText>
          </Fragment>
        )}
        {!isAiAction && (
          <ActionText>
            {text || I18n.t('builder.resume_editor.phrase_suggestion.prewritten_phrases')}
          </ActionText>
        )}
        <ActionIcon {...{ isAiAction }}>
          <Icon20.Add />
        </ActionIcon>
      </Action>
    </SuggestionTogglerContainer>
  )
})
