import 'core-js/stable'
import 'builder/services/ErrorLogger'
import 'builder/services/HelpscoutSdk'
import ReactDOM from 'react-dom'
import { Route, Routes } from 'react-router-dom'

import { ready } from 'shared/ready'
import { sagaMiddleware, store } from 'builder/modules/store'
import { sagas } from 'builder/modules/sagas'
import jwtService from 'builder/services/JwtService'
import SmartRoute from 'builder/components/SmartRoute'
import { SuperAppFeatureRoute } from 'builder/components/SuperAppFeatureRoute'
import Providers from 'builder/components/Providers'
import { ConfigScopesEnum } from 'builder/modules/init'
import PanelView from 'builder/views/PanelView'
import ResumeCritiqueView from 'builder/views/ResumeCritiqueView'
import ResumeCritiquesView from 'builder/views/ResumeCritiquesView'
import { JobSearchView } from 'builder/views/JobSearchView'
import JobTrackingView from 'builder/views/JobTrackingView'
import InterviewSchoolSwitchView from 'builder/views/InterviewSchoolSwitchView'
import { CareerPathView } from 'builder/views/CareerPathView'
import { CareerPathView2 } from 'builder/views/CareerPathView2'
import { CreateAccountView } from 'builder/views/CreateAccountView'
import AccountView from 'builder/views/AccountView'
import SubscriptionCancellationViewNew from 'builder/views/SubscriptionCancellationViewNew'
import { UiKitView } from 'builder/views/UiKitView'
import OfferAnalyzerView from 'builder/views/OfferAnalyzerView'
import OfferAnalyzerResultView from 'builder/views/OfferAnalyzerResultView'
import ResumeCoRegistrationView from 'builder/views/ResumeCoRegistrationView'
import CoverLetterEditorView from 'builder/views/CoverLetterEditorView'
import ResumeEditorView from 'builder/views/ResumeEditorView'
import ResumeEditorStepByStepView from 'builder/views/ResumeEditorStepByStepView'
import SignUpView from 'builder/views/SignUpView'
import CreatePasswordView from 'builder/views/CreatePasswordView'
import SignInView from 'builder/views/SignInView'
import { AccessView } from 'builder/views/AccessView'
import { DocumentTypes } from 'builder/modules/constants'
import { UnauthorizedZone } from 'builder/components/UnauthorizedZone'
import UploadResumeView from 'builder/views/UploadResumeView'
import CareerCoachingView from 'builder/views/CareerCoachingView'
import ScheduleSessionView from 'builder/views/ScheduleSessionView'
import CareerCoachingPaymentView from 'builder/views/CareerCoachingPaymentView'
import LowLeadsCritiqueView from 'builder/views/LowLeadsCritiqueView'
import UpsiderPlatformV2 from './views/UpsiderPlatformV2'
import CareerPlanningView from './views/CareerPlanningView'
import ExploreCareersView from './views/ExploreCareersView'
import { LloydView } from './views/LloydView/LloydView'
import CareerPlansView from './views/CareerPlansView'
import CoachMarketPlaceView from './views/CoachMarketPlaceView'
import PostPurchaseView from './views/CareerPostPurchaseView'
import SevenStoriesView from './views/SevenStoriesView'
import CareerOnboardView from './views/CareerCoachOnboardView'
import ProfessionalHeadshots from './components/ProfessionalHeadshots'
import UploadGuideLines from './components/ProfessionalHeadshots/UploadGuideLines'
import ThankYouView from './views/ThankYouView'
import PremiumTrialLoader from './views/PremiumTriaLoader'
import PremiumTrialActivated from './views/PremiumTrialActivated/PremiumTrialActivated'
import MyEventsView from './views/MyEventsView'
import TRDocumentView from './views/TRDocumentView'
import ResumeDistributionView from './views/ResumeDistributionView'
import ResumeDistributionDashboardView from './views/ResumeDistributionDashboardView'
import ResumeDistributionEditView from './views/ResumeDistributionView/views/ResumeDistributionEditView'
import CareerProfileView from './views/CareerProfile'
import { ResumeDistributionRoute } from './views/ResumeDistributionRoute/ResumeDistributionRoute'
import AiEnhanceOnboard from './views/AiEnhanceOnboard/AiEnhanceOnboard'
import { FeatureWrapper } from './views/FeatureWrapper/FeatureWrapper'
import { NavigationView } from './views/NavigationView'
import { InterviewPreparation } from './views/Interview/InterviewPreparation'
import { AutoApplyView } from './views/AutoApply/AutoApplyView'
import { ServerError } from './views/ResumeDistributionView/components/Errors/ServerError/ServerError'
import { InvalidDocument } from './views/ResumeDistributionView/components/Errors/InvalidDocument/InvalidDocument'
import RecommendJobAlertPageView from './views/RecommendJobAlertPageView'
import CustomJobAlertPageView from './views/CustomJobAlertPage'
import { ResumeAutoTailoringView } from './views/ResumeAutoTailoringView'
/** Defines application routes and starts the application rendering */

ready(() => {
  const rootElement = document.getElementById('builder-application')
  if (!rootElement) return
  sagaMiddleware.run(sagas)
  jwtService.connect(store)

  ReactDOM.render(
    <>
      <Providers isBaseNameRequired={true}>
        <Routes>
          {/* Sign up and login views */}
          <Route path="/" element={<SmartRoute configScope={ConfigScopesEnum.signUp} />}>
            {/* Unauthorized area */}
            <Route path="/" element={<UnauthorizedZone />}>
              <Route path="auth/sign-in" element={<SignInView />} />
              <Route path="auth/sign-in/create-password/:token" element={<CreatePasswordView />} />
              <Route path="create-account" element={<CreateAccountView />} />
              <Route
                path="create-resume/*"
                element={<SignUpView documentType={DocumentTypes.resume} />}
              />
              <Route
                path="create-cover-letter/*"
                element={<SignUpView documentType={DocumentTypes.coverLetter} />}
              />
              <Route path="upload-resume" element={<UploadResumeView />} />
              <Route path="upsider-platform-v2" element={<UploadResumeView />} />
              <Route path="thank-you" element={<ThankYouView />} />
            </Route>

            {/* Magic links auth (a logged in user can also switch account by a magic link) */}
            <Route path="auth/magic/:token" element={<SignInView />} />
          </Route>

          {/* Authorized user area */}
          <Route path="/" element={<SmartRoute privateRoute />}>
            <Route path="resumes/:id/wizard" element={<ResumeEditorStepByStepView />} />
            <Route path="resumes/:id/edit" element={<ResumeEditorView />} />
            <Route path="resumes/:id/statistics" element={<ResumeEditorView />} />
            <Route path="cover-letters/:id/edit" element={<CoverLetterEditorView />} />
            <Route path="resumes/:id/co-reg" element={<ResumeCoRegistrationView />} />
            <Route path="resumes/:id/auto-tailoring" element={<ResumeAutoTailoringView />} />
            <Route path="tr-documents/:id" element={<TRDocumentView />} />
            <Route path="critique" element={<LowLeadsCritiqueView />} />
            {/* The upload-resume route is being use as both Unauthorized/Authorized */}
            <Route path="upload-resume" element={<UploadResumeView />} />
          </Route>

          {/* Registered user area */}
          <Route path="/" element={<SmartRoute privateRoute />}>
            <Route path="/" element={<NavigationView />}>
              <Route index element={<PanelView />} />
              <Route
                path="resumes"
                element={<PanelView defaultType={DocumentTypes.resume} isDocumentsPage />}
              />
              <Route
                path="cover-letters"
                element={<PanelView defaultType={DocumentTypes.coverLetter} isDocumentsPage />}
              />
              <Route
                path="tr-documents"
                element={<PanelView defaultType={DocumentTypes.trDocument} isDocumentsPage />}
              />
              <Route
                path="auto-tailored-resumes"
                element={
                  <PanelView
                    defaultType={DocumentTypes.resume}
                    isDocumentsPage
                    defaultIsAutoTailored
                  />
                }
              />
              <Route path=":slug" element={<PanelView />} />
              <Route path="resumes/:id/critique" element={<ResumeCritiqueView />} />
              <Route path="critiques" element={<ResumeCritiquesView />} />
              <Route path="account" element={<AccountView />} />
              <Route
                path="interview-preparation/*"
                element={
                  <FeatureWrapper feature="interviewSchool" element={<InterviewPreparation />} />
                }
              />
              <Route path="auto-apply/*" element={<AutoApplyView />} />
              <Route
                path="career-path"
                element={<FeatureWrapper feature="careerPath" element={<CareerPathView2 />} />}
              />
              <Route
                path="career-path-1"
                element={<FeatureWrapper feature="careerPath" element={<CareerPathView />} />}
              />
              <Route
                path="account/cancel-subscription"
                element={<SubscriptionCancellationViewNew />}
              />
              <Route path="ui-kit" element={<UiKitView />} />
              <Route
                path="offer-analyzer"
                element={
                  <FeatureWrapper feature="salaryAnalyzer" element={<OfferAnalyzerView />} />
                }
              />
              <Route
                path="offer-analyzer-result"
                element={
                  <FeatureWrapper feature="salaryAnalyzer" element={<OfferAnalyzerResultView />} />
                }
              />
              <Route
                path="my-events"
                element={
                  <FeatureWrapper feature="virtualHiringEvents" element={<MyEventsView />} />
                }
              />
              <Route path="/" element={<SuperAppFeatureRoute />}>
                <Route
                  path="job-search"
                  element={<FeatureWrapper feature="jobSearch" element={<JobSearchView />} />}
                />

                <Route
                  path="job-search/recommend-alert/:externalSlugId"
                  element={
                    <FeatureWrapper feature="jobAlerts" element={<RecommendJobAlertPageView />} />
                  }
                />
                <Route
                  path="job-search/custom-alert/:customAlertId/:externalSlugId"
                  element={
                    <FeatureWrapper feature="jobAlerts" element={<CustomJobAlertPageView />} />
                  }
                />

                <Route
                  path="job-tracking"
                  element={<FeatureWrapper feature="jobTracking" element={<JobTrackingView />} />}
                />
                <Route path="interview-prep" element={<InterviewSchoolSwitchView />} />
                <Route path="upsider-loading" element={<UpsiderPlatformV2 />} />
              </Route>
              <Route path="packages" element={<CareerCoachingPaymentView />} />
              <Route
                path="career-coaching"
                element={
                  <FeatureWrapper feature="careerCoaching" element={<CoachMarketPlaceView />} />
                }
              />
              <Route path="sessions" element={<PostPurchaseView />} />
              <Route
                path="career-coaching/coach-details/:id/schedule-session"
                element={<ScheduleSessionView />}
              />
              <Route path="explore-careers" element={<ExploreCareersView />} />
              <Route
                path="career-plans"
                element={<FeatureWrapper feature="careerPlans" element={<CareerPlansView />} />}
              />
              <Route
                path="career-plans/:planSlug"
                element={<FeatureWrapper feature="careerPlans" element={<LloydView />} />}
              />
              <Route
                path="career-plans/execute-a-job-search"
                element={
                  <FeatureWrapper feature="jobSearchPlan" element={<CareerPlanningView />} />
                }
              />
              <Route path="career-profile/*" element={<CareerProfileView />} />
              <Route path="coach-listing" element={<CareerCoachingView />} />
              <Route
                path="coach-listing/coach-details/:id/schedule-session"
                element={<ScheduleSessionView />}
              />
              <Route
                path="career-plans/execute-a-job-search/seven-stories-exercise"
                element={<SevenStoriesView />}
              />
              <Route path="onboarding-form" element={<CareerOnboardView />} />
              <Route path="professional-headshots" element={<ProfessionalHeadshots />} />
              <Route
                path="professional-headshots/upload-guidelines"
                element={<UploadGuideLines />}
              />
              <Route path="/" element={<ResumeDistributionRoute />}>
                <Route path="resume-distribution" element={<ResumeDistributionView />} />
                <Route path="resume-distribution/server-error" element={<ServerError />} />
                <Route path="resume-distribution/invalid-document" element={<InvalidDocument />} />
                <Route
                  path="resume-distribution/dashboard"
                  element={<ResumeDistributionDashboardView />}
                />
                <Route path="resume-distribution/edit" element={<ResumeDistributionEditView />} />
              </Route>
            </Route>
            <Route path="premium-trail-access-loading" element={<PremiumTrialLoader />} />
            <Route path="premium-trail-activated" element={<PremiumTrialActivated />} />
            <Route path="premium-trail-onboarding" element={<AiEnhanceOnboard />} />
          </Route>

          {/*  Access validation route */}
          <Route path="/access" element={<AccessView />} />
        </Routes>
      </Providers>
      <Providers isBaseNameRequired={false}>
        <Routes>
          <Route path="mystory" element={<UploadResumeView />} />
        </Routes>
      </Providers>
    </>,
    rootElement,
  )
})
