import * as Styled from './styles'

export const CareerGoalSliderPlaceholder = () => {
  return (
    <Styled.PlaceholderWrapper>
      <Styled.PlaceholderCard />
      <Styled.PlaceholderCard />
      <Styled.PlaceholderCard />
      <Styled.PlaceholderCard />
    </Styled.PlaceholderWrapper>
  )
}
