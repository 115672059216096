import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Icon24 from 'builder/components/Icon'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Heading = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};

  ${Media.Tablet`
    ${Typography.S};
  `}
`

export const Number = styled.div`
  font-size: 33px; // custom font size
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 8px;

  &:hover {
    cursor: pointer;

    ${Heading} {
      color: ${Colors.Blue50};
    }

    ${Number} {
      color: ${Colors.Blue50};
    }
  }

  ${Media.Tablet`
    &:hover {
      ${Heading} {
        color: inherit;
      }

      ${Number} {
        color: inherit;
      }
    }
  `}
`

export const Ratio = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
`

export const ArrowContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  background-color: ${Colors.Green10};
`

export const UpArrow = styled(Icon24.ArrowUp)`
  color: ${Colors.Green60};
`

export const Separator = styled.div`
  border: 1px solid ${Colors.Neutral15};
`
