import { Component } from 'react'
import PropTypes from 'prop-types'
import ErrorLogger from 'builder/services/ErrorLogger'
import Fallback from './Fallback'

export default class ErrorBoundary extends Component {
  state = {
    hasError: false,
  }

  static propTypes = {
    children: PropTypes.node,
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error) {
    ErrorLogger.log(error, { level: 'fatal' })
  }

  render() {
    if (this.state.hasError) return <Fallback />
    return this.props.children
  }
}
