import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

const { Neutral50, Neutral10, Blue50, White } = Colors

export const Div = styled.div``

export const Container = styled.div<{ isLinkedInFlow: boolean }>(props => {
  const { isLinkedInFlow } = props
  return css`
    width: 520px;
    border-radius: 16px;
    padding-top: 32px;
    padding-left: 52px;
    padding-right: 52px;
    background: ${White};
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    ${isLinkedInFlow
      ? css`
          padding-bottom: 130px;
          ${Media.Phone`
            padding-bottom: 0px;
          `}
        `
      : css`
          min-height: 792px;
          padding-bottom: 40px;
          ${Media.Phone`
            padding-bottom: 30px;
          `}
        `};
    ${Media.Phone`
    min-height: unset;
    border-radius: 0px;
    height: 100%;
    padding-top: 52px;
    padding-left: 36px;
    padding-right: 36px;
    overflow: auto;
  `}
  `
})

export const Close = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const DesktopClose = styled(Close)`
  top: 32px;
  right: 32px;
  width: 28px;
  height: 28px;
  color: ${Neutral50};
  background: ${Neutral10};
  border-radius: 100%;
  transition: color 0.15s;
  ${Media.AboveTablet`
    &:hover {
      color: ${Blue50};
    }
  `}
`

// MobileActionBar styles
export const MobileBackButton = styled.button`
  display: inline-flex;
  align-items: center;
  ${Typography.Caption}
  ${FontWeights.DemiBold}
  border: none;
  border-radius: 20px;
  color: ${Neutral50};
  background-color: ${Neutral10};
  padding: 4px 12px 4px 6px;
  position: absolute;
  top: 20px;
  left: 14px;
`
export const MobileCloseButton = styled(DesktopClose)`
  top: 22px;
  right: 22px;
`

export const ChevronLeft = styled.span`
  transform: rotate(180deg);
`
