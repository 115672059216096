import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const ProgressContainer = styled.div`
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  padding: 40px 16px;
  background-color: ${Colors.Blue10};
  border-radius: 16px;

  ${Media.Tablet`
    height: 96px;
    flex-direction: row;
    justify-content: flex-start;
  `}

  ${Media.Phone`
    height: 80px;
    flex-direction: row;
    justify-content: flex-start;
  `}
`

export const Text = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};
  display: flex;
  align-items: center;
  text-align: center;
  color: ${Colors.Neutral100};
`
