import { Settings } from './Settings/Settings'
import { ProfileAvatar } from './ProfileAvatar/ProfileAvatar'

import { Divider, Container } from './styles'
import { CareerProfile } from './CareerProfile/CareerProfile'

export const ProfileMobile = () => {
  return (
    <Container>
      <ProfileAvatar />
      <CareerProfile />
      <Divider />
      <Settings />
    </Container>
  )
}
