import { i18n } from 'builder/utils/i18n'

export const getMonths = () => [
  { number: 1, name: i18n.t('builder.date_range_picker.month.january') },
  { number: 2, name: i18n.t('builder.date_range_picker.month.february') },
  { number: 3, name: i18n.t('builder.date_range_picker.month.march') },
  { number: 4, name: i18n.t('builder.date_range_picker.month.april') },
  { number: 5, name: i18n.t('builder.date_range_picker.month.may') },
  { number: 6, name: i18n.t('builder.date_range_picker.month.june') },
  { number: 7, name: i18n.t('builder.date_range_picker.month.july') },
  { number: 8, name: i18n.t('builder.date_range_picker.month.august') },
  { number: 9, name: i18n.t('builder.date_range_picker.month.september') },
  { number: 10, name: i18n.t('builder.date_range_picker.month.october') },
  { number: 11, name: i18n.t('builder.date_range_picker.month.november') },
  { number: 12, name: i18n.t('builder.date_range_picker.month.december') },
]
