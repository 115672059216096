import { useState, useEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { Optional } from 'packages/types'
import { Cards, InternalEvents } from '../common/types'
import usePromotionCardValues from './usePromotionCardValues'

const usePromotionCardActions = () => {
  const [isCardClicked, setIsCardClicked] = useState<boolean>(false)
  const [activeCard, setActiveCard] = useState<Optional<Cards>>(null)
  const [cancellationReason, setCancellationReason] = useState<Optional<string>>(null)
  const { getCardTitle } = usePromotionCardValues()

  const handlePromotionClick = (cardType: Optional<Cards>) => {
    if (cardType) {
      setIsCardClicked(true)
      setActiveCard(cardType)
    } else {
      setIsCardClicked(false)
      setActiveCard(null)
    }
  }

  // Set cancellation reason when active card is changed
  useEffect(() => {
    if (activeCard) {
      setCancellationReason(getCardTitle(activeCard))
    } else {
      setCancellationReason(null)
    }
  }, [activeCard, getCardTitle])

  // Track internal event based on the card clicked
  useEffect(() => {
    if (cancellationReason) {
      trackInternalEvent(InternalEvents.choice, {
        cancel_reason: cancellationReason,
      })
    }
  }, [cancellationReason])

  return {
    activeCard,
    cancellationReason,
    handlePromotionClick,
    isCardClicked,
  }
}

export default usePromotionCardActions
