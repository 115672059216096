import { useMutation } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { baseClient } from 'builder/modules/apiClient'
import { actions } from 'builder/modules/interview/interviewModule'
import { NewInterviewResponse } from 'builder/modules/interview/types'
import { queryClient } from 'builder/components/Providers/Providers'

export const useCreateInterviewFromInterview = () => {
  const dispatch = useDispatch()
  return useMutation(
    ['useCreateInterviewFromInterview'],
    async (interviewId: number): Promise<NewInterviewResponse> => {
      const res = await baseClient.post(
        `interview_prep/interviews/${interviewId}/create_from_interview`,
      )
      return res.data
    },
    {
      onSuccess: res => {
        dispatch(actions.setInterviewId(res.id))
        queryClient.invalidateQueries(['useInterviews'])
        queryClient.invalidateQueries(['useInterview'])
        queryClient.invalidateQueries(['useDailySuggestions'])
      },
    },
  )
}
