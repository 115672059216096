import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { authService } from 'builder/services/AuthService'

export const authorizationHeaderSetterInterceptor = (req: AxiosRequestConfig) => {
  const token = authService.getAccessToken()

  if (token && req.headers) {
    req.headers.Authorization = `Bearer ${token}`
  }
  return req
}

export const accessTokenGetterInterceptor = (response: AxiosResponse) => {
  const { headers } = response

  if (headers['x-api-token']) {
    authService.login(headers['x-api-token'])
  }
  return response
}

export const unauthorizedErrorInterceptor = (error: AxiosError) => {
  if (error.response && error.response.status === 401) {
    authService.logout({ invalidateSession: false, persistPath: true })
  }
  return Promise.reject(error)
}
