const STEPS = {
  templates: 'templates',
  socialProfile: 'social-profile',
  introduction: 'introduction',
  contactInfo: 'contact-info',
}

class SignUpStepManager {
  constructor(options = {}) {
    const { steps, exclude = [] } = options

    this.steps = (
      steps || [STEPS.templates, STEPS.socialProfile, STEPS.introduction, STEPS.contactInfo]
    ).filter(step => !exclude.includes(step))
  }

  currentStep = () => this.steps[this.currentStepIndex]

  next = currentStep => {
    const currentStepIndex = this.steps.indexOf(currentStep)
    if (currentStepIndex === -1) return this.steps[0]

    const index =
      currentStepIndex !== this.steps.length - 1 ? currentStepIndex + 1 : currentStepIndex

    return this.steps[index]
  }

  previous = currentStep => {
    const currentStepIndex = this.steps.indexOf(currentStep)
    if (currentStepIndex === -1) return this.steps[0]

    const index = currentStepIndex !== 0 ? currentStepIndex - 1 : 0
    return this.steps[index]
  }

  getDirection = (currentStep, nextStep) => {
    const currentStepIndex = this.steps.indexOf(currentStep)
    const nextStepIndex = this.steps.indexOf(nextStep)

    return nextStepIndex > currentStepIndex ? 'forward' : 'backward'
  }

  getStepByUrl = url => {
    const stepUrl = url.split('/').pop()
    const stepIndex = this.steps.indexOf(stepUrl)
    if (stepIndex !== -1) {
      return this.steps[stepIndex]
    }
    return null
  }
}

export default SignUpStepManager
export { STEPS }
