import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const Title = styled.p`
  ${Typography.S};
  ${FontWeights.Medium};
`

export const Bars = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`
