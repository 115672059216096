import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Icon20 } from 'builder/components/Icon'
import { TextField } from 'builder/components/TextField'
import { Button } from 'builder/components/Button'

export const Container = styled.div`
  width: 95%;
  max-width: 550px;
  padding: 20px;
  border-radius: 12px;
  background-color: ${Colors.White};
`

export const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  height: 24px;
`

export const Description = styled.div`
  ${Typography.Body}
  ${FontWeights.Regular}
  margin: 12px 0px 24px 0px;
  color: ${Colors.Neutral50};
`

export const Title = styled.div`
  ${Typography.S}
  ${FontWeights.DemiBold}
  color: ${Colors.Neutral90};
  width: 95%;
  height: 276px;
`

export const CloseIcon = styled(Icon20.Close)`
  background-color: ${Colors.Neutral10};
  border-radius: 32px;
`

export const InputField = styled(TextField)`
  input {
    color: ${Colors.Neutral90};
    ${Typography.Body}
    ${FontWeights.Regular}
    margin-bottom: 16px;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: right;
`

export const Cancel = styled(Button)`
  margin-right: 8px;
`

export const Save = styled(Button)``
