import styled from 'styled-components'
import Media from 'builder/styles/media'

export const Container = styled.div`
  max-width: 1120px;
  width: calc(100% - 32px);
  margin: 0 auto;
  padding: 34px 0 80px;
  gap: 48px;
  display: flex;
  flex-direction: column;
  position: relative;

  ${Media.Phone`
    gap: 32px;
    padding-top: 32px;
  `}
`
