import styled from 'styled-components'
import { gradient } from 'builder/styles/keyframes'
import Colors from 'builder/styles/colors'

export const Skeleton = styled.div`
  animation: ${gradient} 1s linear infinite;
  background: ${Colors.Neutral10};
  background: linear-gradient(
    to right,
    ${Colors.Neutral10} 5%,
    ${Colors.Neutral15} 15%,
    ${Colors.Neutral10} 25%
  );
  border-radius: 2px;
  width: 100%;
  height: 100%;
  background-size: 100vw 100vh;
`

export const SkeletonArea = styled(Skeleton)`
  height: 170px;
  width: 216px;
`

export const SkeletonValue = styled(Skeleton)`
  width: 100%;
  height: 32px;
`

export const SkeletonText = styled(Skeleton)`
  width: 60%;
  height: 28px;
`
