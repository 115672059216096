import styled, { css } from 'styled-components'

import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import { CircleButton } from '../Button'
import Icon24 from '../Icon'

export type Position = 'start' | 'end' | 'center'

export const Column = styled.div<{ size?: number; position?: Position }>`
  flex: ${props => props.size || 1};
  display: flex;
  justify-content: ${props => (props.position === 'center' ? 'center' : `flex-${props.position}`)};
  align-items: center;
`

export const CountSection = styled.div`
  ${Typography.Caption};
  ${FontWeights.Medium};
  color: ${Colors.Neutral50};
  border-radius: 6px;
  background: ${Colors.Neutral10};
  padding: 1px 8px;
  margin-right: 8px;
`

export const Header = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
`

export const ExpandButton = styled(CircleButton)`
  ${Typography.Subhead};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
  background: none;

  &:hover {
    background: none;
  }

  &:focus {
    background: none;
  }

  ${Media.Tablet`
    width: 24px;
    height: 24px;
  `};

  ${Media.Phone`
    width: 24px;
    height: 24px;
  `};
`

export const ChevronLightUpIcon = styled(Icon24.ChevronLightUp)`
  width: 24px;
  height: 24px;
  cursor: pointer;

  svg {
    fill: none;
  }
`

export const ChevronLightDownIcon = styled(Icon24.ChevronLightDown)`
  width: 24px;
  height: 24px;
  cursor: pointer;

  svg {
    fill: none;
  }
`

export const Container = styled.div<{ padding?: string; disableHover?: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: ${props => props.padding || '18px 40px'};
  position: sticky;
  top: 75px;
  background: white;

  ${Media.Tablet`
    padding: 18px 20px;
  `};

  ${Media.Phone`
    padding: 18px 20px;
  `};

  ${props =>
    !props.disableHover &&
    css`
      cursor: pointer;
      &:hover {
        background-color: ${Colors.Blue10};
      }

      &:hover ${CountSection} {
        background-color: ${Colors.Blue30};
        color: ${Colors.Blue60};
      }

      &:hover ${ChevronLightDownIcon} {
        background-color: ${Colors.Blue10};
        path {
          stroke: ${Colors.Blue60};
        }
      }

      &:hover ${ChevronLightUpIcon} {
        background-color: ${Colors.Blue10};
        path {
          stroke: ${Colors.Blue60};
        }
      }
    `}
`
