import { useI18n } from 'builder/hooks/useI18n'

import ModalOverlay from 'builder/components/ModalOverlay'
import TimeUpImg from 'builder/views/Interview/assets/timeUpImg.png'
import Button from 'builder/components/Button'
import { Text, Container, Title, Body, ButtonContainer } from './styles'

interface Props {
  isLastQuestion: boolean
  onClick: () => void
}

export const TRANSLATION_PREFIX = 'builder.interview_prep_view.interview_player.time_up_dialog'

export const TimeUpDialog = ({ isLastQuestion, onClick }: Props) => {
  const { i18n } = useI18n()
  return (
    <ModalOverlay>
      <Container>
        <img src={TimeUpImg} />
        <Body>
          <Title>{i18n.t(`${TRANSLATION_PREFIX}.title`)}</Title>

          <Text>{i18n.t(`${TRANSLATION_PREFIX}.text`)}</Text>
          <ButtonContainer>
            <Button onClick={onClick}>
              {isLastQuestion
                ? i18n.t(`${TRANSLATION_PREFIX}.button_feedback`)
                : i18n.t(`${TRANSLATION_PREFIX}.button_next_question`)}
            </Button>
          </ButtonContainer>
        </Body>
      </Container>
    </ModalOverlay>
  )
}
