import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useMutation } from '@tanstack/react-query'
import { trackInternalEvent } from '@rio/tracking'

import {
  selectors as PanelSelectors,
  actions as PanelActions,
  AIRefreshStatus,
} from 'builder/modules/panel'
import { apiClient } from 'builder/modules/apiClient'
import { useI18n } from 'builder/hooks/useI18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

import Button from 'builder/components/Button'
import * as Styled from './styles'

interface Props {
  id: number
  onRemove: () => void
}

export const AiRefresher = ({ id, onRemove }: Props) => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const aiJob = useTypedSelector(PanelSelectors.aiJobStatusById(id))
  const isAiRefresherPending = aiJob?.status === AIRefreshStatus.PENDING
  const isAiRefresherSuccess = aiJob?.status === AIRefreshStatus.SUCCESS
  const isAiRefresherFailed =
    aiJob?.status === AIRefreshStatus.RETRY || aiJob?.status === AIRefreshStatus.FAILURE

  const postRetryAi = async () => {
    const response = await apiClient.post(`/resumes/${id}/ai-retry`)
    return response.data
  }

  const onSuccess = () => {
    dispatch(PanelActions.setDocumentAiJobStatus({ id, aiJobStatus: AIRefreshStatus.PENDING }))
  }

  const mutation = useMutation({
    mutationFn: postRetryAi,
    onSuccess: onSuccess,
  })

  const handleRetry = async () => {
    if (id === null) return
    mutation.mutate()
    trackInternalEvent('ai_refresh_try_again')
  }

  useEffect(() => {
    if (isAiRefresherSuccess) {
      trackInternalEvent('ai_refreshed_resume_ready')
    } else if (isAiRefresherFailed) {
      trackInternalEvent('ai_refresh_error')
    }
  }, [isAiRefresherSuccess, isAiRefresherFailed])

  return (
    <>
      <Styled.Title isPending={isAiRefresherPending}>
        {isAiRefresherPending && <Styled.Spinner src={require('./assets/Spinner.png')} />}
        {isAiRefresherPending
          ? i18n.t('builder.resume_tuner.ai_refresher.resume_rewriting_title')
          : i18n.t('builder.resume_tuner.ai_refresher.resume_error_title')}
      </Styled.Title>
      {isAiRefresherFailed && (
        <>
          <Styled.Text>{i18n.t('builder.resume_tuner.ai_refresher.resume_error_text')}</Styled.Text>

          <Styled.ButtonContainer>
            <Button size="small" onClick={handleRetry}>
              {i18n.t('builder.resume_tuner.ai_refresher.try_again')}
            </Button>
            <Button theme="ghost" size="small" onClick={onRemove}>
              {i18n.t('builder.resume_tuner.ai_refresher.cancel')}
            </Button>
          </Styled.ButtonContainer>
        </>
      )}
    </>
  )
}
