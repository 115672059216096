import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  align-self: center;
`

export const Text = styled.div``

export const Value = styled.div`
  color: ${Colors.Neutral90};
  ${FontWeights.Medium};
  text-transform: uppercase;
`
