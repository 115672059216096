import { useState, memo, useCallback } from 'react'
import { trackInternalEvent, trackMarketingEvent } from '@rio/tracking'
import { useDispatch, useSelector } from 'react-redux'
import startCase from 'lodash/startCase'
import Icon from 'builder/components/Icon'
import { DocumentActionMenu } from 'builder/components/DocumentActionMenu/DocumentActionMenu'
import { ButtonSize } from 'builder/components/Button'
import { i18n as I18n } from 'builder/utils/i18n'
import { actions as renderingActions } from 'builder/modules/rendering'
import {
  actions as coRegActions,
  selectors as coRegSelectors,
} from 'builder/modules/coRegistration'
import { useConfig } from 'builder/hooks/useConfig'
import { actions as uiActions } from 'builder/modules/ui'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as userSelectors } from 'builder/modules/user'
import { shouldOpenPlansPageAbandonment } from 'builder/components/AbandonmentModal/utils'

import { DocumentTypes, FORMATS } from 'builder/modules/constants'
import { actions, selectors } from 'builder/modules/resumeEditor'
import { getUpdatedTemplateAndFormat } from 'builder/utils/getTemplates'
import CoRegPopup from '../../CoRegPopup'
import { CurrentDocument } from '../../types'
import { Actions, ActionsButton, ActionsButtonBadge, DownloadButton, DownloadMenu } from './styles'

type Props = {
  type: DocumentTypes
  documentId: number
  isDownloading: boolean
  currentDocument: CurrentDocument
}

export const DownloadMenuLayout = memo((props: Props) => {
  const dispatch = useDispatch()
  const toggleCoRegPopup = coRegActions.togglePopup
  const openCoReg = coRegActions.open
  const isCoRegPopupOpen = useSelector(coRegSelectors.isPopupOpen)
  const isReadyToCoReg = useSelector(coRegSelectors.isResumeReady)
  const isFromJobTracker = useSelector(selectors.isFromJobTracker)
  const { type, documentId, isDownloading } = props
  const [isActionMenuOpen, setIsActionMenuOpen] = useState(false)
  const buttonsSize = ButtonSize.small

  const config = useConfig()
  const plansPageAbandonment = config?.features.plansPageAbandonment || false
  const showOneDownloadButton = !!(config?.features.downloadButtonLayout === 'one_button')
  const isResume = type === DocumentTypes.resume
  // Show the download resume option only for resume and the one button layout
  const showUpdatedMenuLayout = isResume && showOneDownloadButton
  const userData = useTypedSelector(userSelectors.userData)

  const handleActionMenuOpen = useCallback(() => {
    setIsActionMenuOpen(true)
    if (isCoRegPopupOpen) dispatch(toggleCoRegPopup(false))
    trackInternalEvent('open_document_action_menu', { source: `${type}_editor` })
  }, [dispatch, isCoRegPopupOpen, toggleCoRegPopup, type])

  const handleActionMenuClose = useCallback(() => {
    setIsActionMenuOpen(false)
  }, [])

  const handleShareClick = useCallback(() => {
    trackInternalEvent('click_share_button', { source: `${type}_editor` })
  }, [type])

  const onCoRegPopupClick = useCallback(
    () => dispatch(openCoReg({ resumeId: documentId, source: 'pop_up' })),
    [dispatch, documentId, openCoReg],
  )
  const onCoRegPopupClose = useCallback(() => toggleCoRegPopup(false), [toggleCoRegPopup])

  const handleDownloadDocument = useCallback(
    ({ format = FORMATS.pdf }) => {
      if (
        window.location.pathname.includes('/resumes') &&
        userData?.registeredAt &&
        shouldOpenPlansPageAbandonment(
          userData.registeredAt,
          plansPageAbandonment || false,
          userData.showAbandonmentFlows,
        )
      ) {
        trackInternalEvent('show_abandonment_popup', { source: 'dashboard', type: 'plans' })
        dispatch(uiActions.openPlansPageAbandonmentModal())
        return
      }

      const { documentId: id, type, currentDocument } = props
      const { updatedTemplate, updatedFormat } = getUpdatedTemplateAndFormat(
        showOneDownloadButton,
        type,
        currentDocument.template as string,
        format,
      )
      dispatch(
        renderingActions.download({
          id,
          type,
          format: updatedFormat,
          source: 'builder',
          template: updatedTemplate,
        }),
      )
      if (isFromJobTracker) {
        dispatch(actions.setOpenSavingInfoBanner(true))
        trackInternalEvent('view_save_banner', {
          label: 'optimizer',
        })
      }
      // Track in Google Analytics
      trackMarketingEvent('Preview Modal', `Download ${startCase(type)}`, {
        eventLabel: updatedFormat,
      })
      // Track in ClickHouse
      trackInternalEvent(`click_download_${type}_button`, {
        format: updatedFormat,
        source: `${type}_editor`,
        template: updatedTemplate,
      })
    },
    [dispatch, isFromJobTracker, props, plansPageAbandonment, userData, showOneDownloadButton],
  )

  return (
    <DownloadMenu>
      <DownloadButton
        onClick={showUpdatedMenuLayout ? handleActionMenuOpen : handleDownloadDocument}
        isDisabled={isDownloading}
        size={buttonsSize}
      >
        {I18n.t(
          `builder.resume_editor.${
            showUpdatedMenuLayout ? 'download_document_menu.button_text' : 'download'
          }`,
        )}
      </DownloadButton>
      <Actions>
        {!showUpdatedMenuLayout && (
          <ActionsButton
            onClick={handleActionMenuOpen}
            isDisabled={isDownloading}
            size={buttonsSize}
          >
            <Icon.More />
          </ActionsButton>
        )}
        <ActionsButtonBadge isVisible={!isCoRegPopupOpen && isReadyToCoReg && !isActionMenuOpen} />
        {isActionMenuOpen && (
          <DocumentActionMenu
            {...{
              position: 'bottom',
              documentId,
              onDownload: handleDownloadDocument,
              documentType: type,
              onClose: handleActionMenuClose,
              onShare: handleShareClick,
            }}
          />
        )}
        {isCoRegPopupOpen && <CoRegPopup onClick={onCoRegPopupClick} onClose={onCoRegPopupClose} />}
      </Actions>
    </DownloadMenu>
  )
})
