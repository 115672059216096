import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { actions, Label } from 'builder/modules/careerPlanning'

import YoutubeLogo from './assets/img/youtube-logo.svg'
import { Wrapper, Thumbnail, PlayIcon, Overlay, StyledYT } from './styles'

type TrackingProps = {
  action_item_name: string
  sub_section_name: string
  user_id: number | undefined
}

type Props = {
  src: string
  cover: string
  isPremiumUser: boolean
  availableForFreeUsers: boolean
  trackingEventProps: TrackingProps
}

const VideoPlayer = ({
  src,
  cover,
  isPremiumUser,
  availableForFreeUsers,
  trackingEventProps,
}: Props) => {
  const dispatch = useDispatch()
  const videoId = src.split('/')[3]

  const opts = {
    playerVars: {
      rel: 0,
      modestbranding: 1,
      origin: window.location.origin,
    },
  }

  const openLimitModal = useCallback(() => {
    dispatch(actions.setShowLimitModal(true))
  }, [dispatch])

  const handleOnPlay = useCallback(() => {
    trackInternalEvent('play_video', { ...Label, ...trackingEventProps })
  }, [trackingEventProps])

  if (!isPremiumUser && !availableForFreeUsers) {
    return (
      <Wrapper onClick={openLimitModal}>
        <Overlay />
        <Thumbnail src={cover} />
        <PlayIcon src={YoutubeLogo} />
      </Wrapper>
    )
  }

  return <StyledYT videoId={videoId} opts={opts} onPlay={handleOnPlay} />
}

export default VideoPlayer
