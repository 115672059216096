import Lottie from 'react-lottie'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import animationData from './assets/Confetti.json'
import { AnimationContainer } from './styles'

interface Props {
  isOpen?: boolean
}

const CareerPlanningAnimation = ({ isOpen }: Props) => {
  const { isTablet } = useMediaQueries()
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  }

  return (
    <AnimationContainer $isOpen={isOpen}>
      <Lottie options={defaultOptions} width="100%" height={isTablet ? '15%' : '215px'} />
    </AnimationContainer>
  )
}

export default CareerPlanningAnimation
