import { useState } from 'react'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import { DateRangePickerChangeValue, DateRangePickerProps } from '../types'
import {
  convertToExternal,
  convertToFieldValue,
  convertToInternal,
  guessMonth,
  guessYear,
  isDateHidden,
  isMonthHidden,
  isPresent,
} from '../utils'

type DateField = Extract<keyof DateRangePickerChangeValue, 'dateFrom' | 'dateUntil'>
type IsMonthHiddenField = Extract<
  keyof DateRangePickerChangeValue,
  'isMonthFromHidden' | 'isMonthUntilHidden'
>
type Props = {
  props: DateRangePickerProps
  dateField: DateField
  isMonthHiddenField: IsMonthHiddenField
  canSetDateUntilPresent?: boolean
}

export const useDateField = (hookProps: Props) => {
  const {
    props: { onChange = () => {}, value: externalValue },
    dateField,
    isMonthHiddenField,
    canSetDateUntilPresent,
  } = hookProps

  const [value, setValue] = useState<string | null>(null)
  const [month, setMonth] = useState<number | null>(null)
  const [year, setYear] = useState(0)
  const [isPickerOpen, setIsPickerOpen] = useState(false)

  useEffectOnMount(() => {
    const [initialValue, initialMonth, initialYear] = convertToInternal(externalValue[dateField], {
      isMonthHidden: externalValue[isMonthHiddenField],
      ...(canSetDateUntilPresent ? { isDatePresent: externalValue.isDateUntilPresent } : {}),
    })

    setValue(initialValue)
    setMonth(initialMonth)
    setYear(initialYear)
  })

  const onYearChange = (newMonth: number | null, newYear: number) => {
    setMonth(newMonth)
    setYear(newYear)

    const prevValue = value
    const newValue = convertToFieldValue(newMonth, newYear, { prevValue })

    if (newValue !== prevValue) {
      setValue(newValue)

      const newExternalValue = convertToExternal(newMonth, newYear, {
        isDateHidden: isDateHidden(newValue),
      })

      onChange({
        [dateField]: newExternalValue,
        [isMonthHiddenField]: !newMonth,
      })
    }
  }

  const onInputValueChange = (newValue: string) => {
    const newMonth = guessMonth(newValue)
    const newYear = guessYear(newValue)
    const newExternalValue = convertToExternal(newMonth, newYear, {
      isDateHidden: isDateHidden(newValue),
    })

    setValue(newValue)
    setMonth(newMonth)
    setYear(newYear)

    onChange({
      [dateField]: newExternalValue,
      [isMonthHiddenField]: isMonthHidden(newValue),
      ...(canSetDateUntilPresent ? { isDateUntilPresent: isPresent(newValue) } : {}),
    })
  }

  const onDateSelect = (month: number | null, year: number, shouldClosePanel = true) => {
    const newInternalValue = convertToFieldValue(month, year)
    const newExternalValue = convertToExternal(month, year)

    setMonth(month)
    setValue(newInternalValue)

    if (shouldClosePanel) {
      setIsPickerOpen(false)
    }

    onChange({
      [dateField]: newExternalValue,
      [isMonthHiddenField]: !month,
    })
  }

  const onInputBlur = () => {
    const newValue = convertToFieldValue(month, year, { prevValue: value })
    if ((!newValue && !value) || newValue === value) {
      return
    }

    const newExternalValue = convertToExternal(month, year, {
      isDateHidden: isDateHidden(value),
    })

    setValue(newValue)

    onChange({
      [dateField]: newExternalValue,
      [isMonthHiddenField]: isMonthHidden(value),
      ...(canSetDateUntilPresent ? { isDateUntilPresent: isPresent(value) } : {}),
    })
  }

  return {
    value,
    month,
    year,
    setValue,
    setMonth,
    setYear,
    onYearChange,
    onInputValueChange,
    isPickerOpen,
    setIsPickerOpen,
    onDateSelect,
    onInputBlur,
  }
}
