import styled from 'styled-components'
import Media from 'builder/styles/media'

export const Container = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  padding-top: 40px;
  width: calc(100% - 40px);
  gap: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${Media.Tablet`
    gap: 32px;
    padding-top: 32px;
    width: calc(100% - 40px);
  `};

  ${Media.Phone`
    gap: 32px;
    padding-top: 32px;
    width: calc(100% - 40px);
  `};
`
