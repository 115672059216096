export const Pointer = (props: React.HTMLAttributes<SVGElement>) => (
  <svg
    width="24"
    height="8"
    viewBox="0 0 24 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24 0V0.00171492C22.8155 0.00840678 22.1125 0.0412078 21.5083 0.201989C20.8354 0.381051 20.1934 0.676253 19.607 1.07629C18.9455 1.52754 18.3834 2.15794 17.2593 3.41875L15.186 5.74412C14.0745 6.99082 13.5187 7.61417 12.8735 7.84668C12.3063 8.05111 11.6932 8.05111 11.1259 7.84668C10.4808 7.61417 9.92499 6.99082 8.81344 5.74412L6.74016 3.41875C5.61604 2.15794 5.05398 1.52754 4.39247 1.07629C3.80606 0.676255 3.16409 0.381053 2.49116 0.201991C1.90672 0.0464747 1.19182 0.0106943 1.9813e-10 0.00246231L0 2.2739e-06L24 0Z"
      fill="currentColor"
    />
  </svg>
)
