import { memo } from 'react'
import { useDispatch } from 'react-redux'
import { DateRangePickerValue } from 'packages/types'
import { TextField } from 'builder/components/TextField'
import { Icon24 } from 'builder/components/Icon'
import DateRangePicker from 'builder/components/DateRangePicker'
import { i18n as I18n } from 'builder/utils/i18n'
import {
  AIResumeWorkExpCardData,
  AIResumeWorkExpCardDataError,
  actions,
} from 'builder/modules/resumeEditor'
import { formatDateRange } from 'builder/utils/formatDateRange'
import { AIResumeQuestionnaireCardType, AIResumeWarningModalTypes } from 'builder/modules/constants'
import {
  Card,
  CollapsedCard,
  CardTitleRow,
  CardDurationRow,
  CardEditIconContainer,
  CardDeleteIconContainer,
  Span,
  Div,
  LeftAlign,
} from '../styles'
import useQuestionnaireCard from '../useQuestionnaireCard'
import { isWorkExpCardEmpty } from './WorkExperienceStep'

type WorkExperienceCardProps = {
  cardData: AIResumeWorkExpCardData
  allCards: AIResumeWorkExpCardData[]
}

const WorkExperienceCard = (props: WorkExperienceCardProps) => {
  const { cardData, allCards } = props
  const { position, company, date, isCollapsed, errors } = cardData
  const firstCardId = allCards[0].id

  const dispatch = useDispatch()
  const { updateSimpleField, updateDateRange, onEditClick, onDelete } = useQuestionnaireCard(
    AIResumeQuestionnaireCardType.workExperience,
    cardData,
  )

  const updateWorkExpSimpleField = (property: string, value: string) => {
    updateSimpleField(property, value)

    if (
      cardData.id === firstCardId &&
      value &&
      errors &&
      errors[property as keyof AIResumeWorkExpCardDataError]
    ) {
      dispatch(
        actions.setQuestionnaireWorkExpCardErrors({
          ...errors,
          [property]: false,
        }),
      )
    }
  }

  const onPositionChange = (event: React.BaseSyntheticEvent) => {
    updateWorkExpSimpleField('position', event.target.value)
  }

  const onCompanyChange = (event: React.BaseSyntheticEvent) => {
    updateWorkExpSimpleField('company', event.target.value)
  }

  const updateWorkExpDateRange = (value: DateRangePickerValue) => {
    updateDateRange(value)

    if (cardData.id === firstCardId && errors) {
      const { dateFrom, dateUntil, isDateUntilPresent } = value
      let dateErrors = {}
      if (dateFrom && errors.dateFrom) {
        dateErrors = {
          dateFrom: false,
        }
      }
      if ((dateUntil || isDateUntilPresent) && errors.dateUntil) {
        dateErrors = {
          ...dateErrors,
          dateUntil: false,
        }
      }

      dispatch(
        actions.setQuestionnaireWorkExpCardErrors({
          ...errors,
          ...dateErrors,
        }),
      )
    }
  }

  const onWorkExpCardEdit = () => {
    const emptyCards = []
    for (const card of allCards) {
      if (card.id !== cardData.id && isWorkExpCardEmpty(card)) {
        emptyCards.push(card.id)
      }
    }
    onEditClick(emptyCards)
  }

  const onWorkExpCardDelete = () => {
    if (isWorkExpCardEmpty(cardData)) {
      onDelete()
      return
    }

    dispatch(actions.setConfirmDeleteQuestionnaireCardId({ deleteCardId: cardData.id }))
    dispatch(
      actions.setShowAIResumeWarningModal({
        showModal: true,
        type: AIResumeWarningModalTypes.removeJob,
      }),
    )
  }

  const EmptyError = <LeftAlign>{I18n.t('builder.ai_resume_draft.linkedin.link_empty')}</LeftAlign>

  if (isCollapsed) {
    return (
      <CollapsedCard>
        <CardTitleRow>
          <Div>
            {(position || company) && <Span>{[position, company].filter(Boolean).join(', ')}</Span>}
          </Div>
          <CardEditIconContainer onClick={onWorkExpCardEdit}>
            <Icon24.EditAlt />
          </CardEditIconContainer>
        </CardTitleRow>
        <CardDurationRow>{formatDateRange(date)}</CardDurationRow>
      </CollapsedCard>
    )
  }

  return (
    <>
      {allCards.length > 1 && (
        <CardDeleteIconContainer>
          <Icon24.RemoveAlt data-testid="delete-btn" onClick={onWorkExpCardDelete} />
        </CardDeleteIconContainer>
      )}
      <Card>
        <TextField
          label={I18n.t('builder.ai_resume_draft.work_experience.job_title')}
          name="title"
          value={position}
          onChange={onPositionChange}
          error={errors?.position && EmptyError}
        />
        <TextField
          label={I18n.t('builder.ai_resume_draft.work_experience.company_name')}
          name="companyName"
          value={company}
          onChange={onCompanyChange}
          error={errors?.company && EmptyError}
        />
        <DateRangePicker
          value={date}
          onChange={updateWorkExpDateRange}
          currentlyLabel={I18n.t('builder.date_range_picker.currently.work')}
          errors={{
            startError: errors?.dateFrom || false,
            endError: errors?.dateUntil || false,
          }}
        />
      </Card>
    </>
  )
}

export default memo(WorkExperienceCard)
