import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { selectors as careerPathSelectors } from 'builder/modules/careerPath'
import { SalaryInfo } from './components/SalaryInfo'
import { JobPostingsInfo } from './components/JobPostingsInfo'
import { CompaniesInfo } from './components/CompaniesInfo'
import { Companies } from './components/Companies'
import { DemandChart } from './components/DemandChart'

export const JobSummary = () => {
  const { companies, totals, salary, postingsTimeseries } = useSelector(careerPathSelectors.summary)
  const isLoading = useSelector(careerPathSelectors.isSummaryLoading)

  return (
    <Fragment>
      <SalaryInfo salary={salary?.median} isSkeleton={isLoading} />
      <JobPostingsInfo jobPostings={totals?.jobPostings} isSkeleton={isLoading} />
      <DemandChart demand={totals?.demandRating} data={postingsTimeseries} isSkeleton={isLoading} />
      <CompaniesInfo companiesNumber={totals?.companies} isSkeleton={isLoading} />
      <Companies companies={companies} isSkeleton={isLoading} />
    </Fragment>
  )
}
