import { createAction, createReducer } from '@reduxjs/toolkit'
import { FetchStatuses } from 'builder/modules/constants'
import { Store } from 'builder/modules/store'
import { UploadResumeStep } from './constants'
import {
  URLParams,
  ErrorInterface,
  UploadResumePayload,
  ParsedResumeDetails,
  UploadResumeInitial,
  CreateUpsiderUserParams,
} from './types'

// ---
// Action creators
// ---
export const actions = {
  parsedDetails: createAction<UploadResumePayload>('builder/UPLOAD_RESUME_PARSED_DETAILS'),
  setparsingStatus: createAction<FetchStatuses>('builder/UPLOAD_RESUME_SET_PARSING_STATUS'),
  setError: createAction<ErrorInterface>('builder/UPLOAD_RESUME_SET_ERRORS'),
  setParsedResumeDetails: createAction<ParsedResumeDetails>(
    'builder/UPLOAD_RESUME_SET_PARSED_RESUME_DETAILS',
  ),
  setStep: createAction<UploadResumeStep>('builder/UPLOAD_RESUME_SET_STEP'),
  setURLParams: createAction<URLParams>('builder/UPLOAD_RESUME_SET_URL_PARAMS'),
  setIsDragging: createAction<boolean>('builder/UPLOAD_RESUME_SET_IS_DRAGGING'),
  setSelectedFile: createAction<File>('builder/UPLOAD_RESUME_SET_SELECTED_FILE'),
  setIsFileSelected: createAction<boolean>('builder/UPLOAD_RESUME_SET_IS_FILE_SELECTED'),
  createUpsiderUser: createAction<CreateUpsiderUserParams>(
    'builder/UPLOAD_RESUME_CREATE_UPSIDER_USER',
  ),
  setEmail: createAction<string>('builder/UPLOAD_RESUME_SET_EMAIL'),
  setUploadProgress: createAction<number>('builder/UPLOAD_RESUME_UPLOAD_PROGRESS'),
  setParsingProgress: createAction<number>('builder/UPLOAD_RESUME_PARSING_PROGRESS'),
  setIsInValidEmail: createAction<boolean>('builder/UPLOAD_RESUME_SET_IS_IN_VALID_EMAIL'),
}

// ---
// Selectors
// ---
export const selectors = {
  step: (state: Store) => state.uploadResume.step,
  email: (state: Store) => state.uploadResume.email,
  errors: (state: Store) => state.uploadResume.errors,
  urlParams: (state: Store) => state.uploadResume.urlParams,
  isDragging: (state: Store) => state.uploadResume.isDragging,
  selectedFile: (state: Store) => state.uploadResume.selectedFile,
  isInValidEmail: (state: Store) => state.uploadResume.isInValidEmail,
  isFileSelected: (state: Store) => state.uploadResume.isFileSelected,
  uploadProgress: (state: Store) => state.uploadResume.uploadProgress,
  parsingProgress: (state: Store) => state.uploadResume.parsingProgress,
  parsedResumeDetails: (state: Store) => state.uploadResume.parsedResumeDetails,
  hasParsed: (state: Store) => state.uploadResume.parsingStatus === FetchStatuses.loaded,
  isParsing: (state: Store) => state.uploadResume.parsingStatus === FetchStatuses.loading,
  hasParsingFailed: (state: Store) => state.uploadResume.parsingStatus === FetchStatuses.failed,
}

// ---
// Reducer
// ---
export const initialState: UploadResumeInitial = {
  isDragging: false,
  selectedFile: null,
  isFileSelected: false,
  step: UploadResumeStep.UPLOAD_RESUME,
  parsingStatus: FetchStatuses.notAsked,
  errors: { hasError: false, errorMessage: '' },
  parsedResumeDetails: {
    firstName: '',
    lastName: '',
    email: '',
    checksum: '',
  },
  urlParams: {
    utm_content: null,
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
  },
  email: '',
  uploadProgress: 0,
  parsingProgress: 0,
  isInValidEmail: true,
}

export const reducer = createReducer(initialState, reducer => {
  reducer.addCase(actions.setparsingStatus, (draft, action) => {
    draft.parsingStatus = action.payload
  })
  reducer.addCase(actions.setError, (draft, action) => {
    draft.errors = action.payload
  })
  reducer.addCase(actions.setParsedResumeDetails, (draft, action) => {
    draft.parsedResumeDetails = action.payload
  })
  reducer.addCase(actions.setURLParams, (draft, action) => {
    draft.urlParams = action.payload
  })
  reducer.addCase(actions.setIsDragging, (draft, action) => {
    draft.isDragging = action.payload
  })
  reducer.addCase(actions.setIsFileSelected, (draft, action) => {
    draft.isFileSelected = action.payload
  })
  reducer.addCase(actions.setSelectedFile, (draft, action) => {
    draft.selectedFile = action.payload
  })
  reducer.addCase(actions.setStep, (draft, action) => {
    draft.step = action.payload
  })
  reducer.addCase(actions.setEmail, (draft, action) => {
    draft.email = action.payload
  })
  reducer.addCase(actions.setUploadProgress, (draft, action) => {
    draft.uploadProgress += action.payload
  })
  reducer.addCase(actions.setParsingProgress, (draft, action) => {
    draft.parsingProgress = action.payload
  })
  reducer.addCase(actions.setIsInValidEmail, (draft, action) => {
    draft.isInValidEmail = action.payload
  })
})
