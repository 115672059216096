import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'

export const SuggestionDropdown = styled.div<{ isOpen?: boolean }>(({ isOpen }) => {
  return css`
    display: ${isOpen ? 'block' : 'none'};
    position: absolute;
    top: 100%;
    left: -12px;
    background-color: ${Colors.White};
    border-radius: 3px;
    box-shadow: 0px 0px 1px rgba(23, 68, 130, 0.2), 0px 2px 14px rgba(23, 68, 130, 0.15);
    min-width: 192px;
    padding: 7px 0px;
    user-select: none;
    z-index: 2;
  `
})

export const SuggestionItem = styled.div`
  padding: 5px 12px;
  display: flex;
  flex-flow: row nowrap;
  cursor: pointer;

  &:hover {
    background-color: ${Colors.Green50};
    color: ${Colors.White};
  }
`

export const SuggestionItemUnknown = styled.div`
  padding: 5px 12px;
  color: ${Colors.Neutral40};
`

export const SuggestionDivider = styled.div`
  height: 1px;
  background-color: ${Colors.Neutral15};
  margin: 5px 12px;
`

export const SuggestionIgnore = styled.div`
  padding: 5px 12px;
  display: flex;
  flex-flow: row nowrap;
  cursor: pointer;

  &:hover {
    background-color: ${Colors.Neutral10};
  }
`

export const SuggestionIcon = styled.div`
  display: inline-block;
  margin-right: 8px;

  ${SuggestionItem} & {
    color: ${Colors.Green50};
  }

  ${SuggestionIgnore} & {
    color: ${Colors.Neutral30};
  }

  ${SuggestionItem}:hover & {
    color: ${Colors.White};
  }
`
