import { useEffect, useMemo, useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { actions, UserBillingStatus } from 'builder/modules/user'
import { useI18n } from 'builder/hooks/useI18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useUser } from 'builder/hooks/useUser'
import Button from 'builder/components/Button'
import SubscriptionCancellationForm from 'builder/components/SubscriptionCancellationForm'
import { Container, Content, Header, HeaderIcon, Title, Description } from './styles'

enum PageState {
  loading = 'loading',
  cancelling = 'cancelling',
  cancelled = 'cancelled',
  denied = 'denied',
}

export const SubscriptionCancellationViewOld = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useUser()
  const { i18n } = useI18n()
  const cancellationStatus = useTypedSelector(state => state.user.cancellationStatus)
  const cancellationError = useTypedSelector(state => state.user.cancellationError)

  // The page is available for authorized users that have an active or cancelled subscription
  const pageState: PageState = useMemo(() => {
    if (!user) return PageState.loading
    if (user.billingStatus === UserBillingStatus.subscription) return PageState.cancelling
    if (user.billingStatus === UserBillingStatus.cancelled) return PageState.cancelled
    return PageState.denied
  }, [user])

  // Leave the page automatically
  useEffect(() => {
    if (pageState === PageState.denied) navigate('/account', { replace: true })
  }, [pageState, navigate])

  // Once the subscription is cancelled, scroll the page to the top
  // because the form is way longer than the success screen
  useEffect(() => {
    if (pageState === PageState.cancelled) window.scrollTo(0, 0)
  }, [pageState])

  // Call the user subscription cancellation saga
  const handleSubmit = useCallback(
    (payload: Record<string, string>) => dispatch(actions.cancelUserSubscription(payload)),
    [dispatch],
  )

  if (pageState === PageState.cancelling || pageState === PageState.cancelled) {
    return (
      <Container>
        <Content>
          <Header>
            <HeaderIcon />
            <Title>{i18n.t(`builder.subscription_cancellation.header.${pageState}.title`)}</Title>
            <Description>
              {i18n.t(`builder.subscription_cancellation.header.${pageState}.description`)}
            </Description>
            {pageState === PageState.cancelled && (
              <Button as={Link} to="/">
                {i18n.t('builder.subscription_cancellation.header.cancelled.action')}
              </Button>
            )}
          </Header>
          {pageState === PageState.cancelling && (
            <SubscriptionCancellationForm
              status={cancellationStatus}
              errorMessage={cancellationError}
              availableFreePeriod={user ? user.availableFreePeriod : null}
              onSubmit={handleSubmit}
            />
          )}
        </Content>
      </Container>
    )
  }

  return null
}
