import { useMemo } from 'react'
import { BriefDocumentType } from 'builder/modules/panel'
import { formatDocumentPreviewUrl } from 'builder/utils/formatDocumentPreviewUrl'
import Colors from 'builder/styles/colors'
import resume from '../../assets/Resume.svg'

import * as Styled from './styles'

type Props = {
  document: BriefDocumentType | null | undefined
  optimizerScore: BriefDocumentType
}

export const ResumeScore = (props: Props) => {
  const { document, optimizerScore } = props
  const imageSrc = document ? formatDocumentPreviewUrl(document, { size: 256 }) : resume

  const { resumeScore, averageScore, jobTitle } = optimizerScore
  const Score = !jobTitle ? resumeScore : averageScore

  const bgColor = useMemo(() => {
    if (Score <= 30) return Colors.Red40
    if (Score > 30 && Score <= 70) return Colors.Amber40
    return Colors.Green40
  }, [Score])

  return (
    <Styled.ResumeContainer>
      <Styled.ResumeImageContainer>
        <Styled.ResumeImage src={imageSrc} />
        <Styled.ResumeGradient />
        <Styled.ResumeScoreContainer>
          <Styled.Score bgColor={bgColor}>{Score}%</Styled.Score>
          <Styled.Text>Resume Score</Styled.Text>
        </Styled.ResumeScoreContainer>
      </Styled.ResumeImageContainer>
    </Styled.ResumeContainer>
  )
}
