import { parse as parseQuery } from 'query-string'
import camelCase from 'lodash/camelCase'

// Parse and save original page query params
const initialParams = parseQuery(window.location.search, {
  parseBooleans: true,
  parseNumbers: true,
})

/**
 * Override feature flags to display different AB test options and toggle features manually
 */
export const overrideFeatureFlags = (features: Record<string, unknown>) => {
  const overrides: Record<string, unknown> = {}

  // Iterate all query params and find related features in the given object
  Object.keys(initialParams).forEach(paramName => {
    const featureName = camelCase(paramName)
    if (featureName in features) overrides[featureName] = initialParams[paramName]
  })

  return { ...features, ...overrides }
}
