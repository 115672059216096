import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { compose } from 'redux'
import { trackInternalEvent } from '@rio/tracking'
import throttle from 'lodash/throttle'
import { useConfig } from 'builder/hooks/useConfig'
import { withRouter } from 'builder/hocs/withRouter'
import { withConfig } from 'builder/components/Config'
import Preview from 'builder/components/Preview'
import Icon from 'builder/components/Icon'
import { DocumentTypes } from 'builder/modules/constants'
import { MouseClickEvent } from 'builder/modules/ui'
import { useAppSelector } from 'builder/hooks/useAppSelector'
import templateHeightFactorSelector from '../Helper/utils/templateHeightFactorSelector'
import { ResumeReviewPopup } from './components/ResumeReviewPopup'
import { CurrentDocument, MODES, PreviewRefProps } from './types'

import { Container, PreviewBox, PreviewWrapper, FullSizeOverlay, FullSizeIcon } from './styles'
import TopbarLayout from './components/TopbarLayout'
import BottomBarLayout from './components/BottomBarLayout'

type Props = {
  type: DocumentTypes
  currentDocument: CurrentDocument
  documentId: number
  isFullScreen: boolean
  isDownloading: boolean
  onFullSize: (event: MouseClickEvent) => void
  mode: MODES
}

const PreviewPanel = (props: Props): JSX.Element => {
  const [width, setWidth] = useState<number>(0)
  const [height, setHeight] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPagesNumber, setTotalPagesNumber] = useState<number>(1)
  const previewRef = useRef<PreviewRefProps>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const isOnline = useAppSelector(state => state.application.isOnline)
  const config = useConfig()

  const {
    type,
    currentDocument,
    documentId,
    isDownloading,
    isFullScreen,
    mode = MODES.editor,
    onFullSize,
  } = props

  let stagingResumeIoHost = window.location.hostname === 'staging.resume.io'
  const isResumeIo = !config?.features.superApp || stagingResumeIoHost

  const handlePreviewResize = throttle(() => {
    if (!containerRef.current) return
    const pdfHeightFactor = templateHeightFactorSelector(currentDocument)
    const previewHeight = containerRef.current.offsetHeight
    const previewWidth = containerRef.current.offsetWidth

    const width = Math.min(previewWidth, previewHeight * pdfHeightFactor)
    setWidth(Math.round(width))

    setHeight(Math.round(width / pdfHeightFactor))

    if (!previewRef.current) return
    previewRef.current.resize(width)
  }, 50)

  useEffect(() => {
    window.addEventListener('resize', handlePreviewResize)
    handlePreviewResize()
    return () => {
      window.removeEventListener('resize', handlePreviewResize)
    }
  }, [handlePreviewResize])

  useEffect(() => {
    if (previewRef.current) previewRef.current.reset()
  }, [isFullScreen])

  const handlePaginationStateChange = useCallback(
    (state: { currentPage: number; totalPagesCount: number }) => {
      const { currentPage, totalPagesCount } = state
      setCurrentPage(currentPage + 1)
      setTotalPagesNumber(totalPagesCount)
    },
    [],
  )

  const handleFullSize = useCallback(
    (event: MouseClickEvent) => {
      onFullSize(event)
      trackInternalEvent(`open_full_screen_${type}_preview`)
    },
    [onFullSize, type],
  )

  const handleOpenFullSize = useCallback(
    (event: MouseClickEvent) => {
      trackInternalEvent(`click_${type}_fullscreen_button`)
      handleFullSize(event)
    },
    [handleFullSize, type],
  )

  return (
    <Container mode={mode} id="preview-panel-container">
      <PreviewBox ref={containerRef}>
        <PreviewWrapper>
          {mode === MODES.editor && (
            <FullSizeOverlay onClick={handleOpenFullSize}>
              {isOnline && (
                <FullSizeIcon>
                  <Icon.FullSizeLarge />
                </FullSizeIcon>
              )}
            </FullSizeOverlay>
          )}
          <TopbarLayout
            {...{
              width,
              type,
              documentId,
              isDownloading,
              currentDocument,
              handleFullSize,
            }}
          />
          {!!width && (
            <Preview
              {...{
                height,
                width,
                documentId,
                type,
                document: currentDocument,
                ref: previewRef,
                onPaginationChange: handlePaginationStateChange,
              }}
            />
          )}
          {mode === MODES.editor && (
            <BottomBarLayout
              {...{
                type,
                currentDocument,
                currentPage,
                totalPagesNumber,
                mode,
                documentId,
                previewRef,
              }}
            />
          )}
        </PreviewWrapper>
      </PreviewBox>
      {type === DocumentTypes.resume && isResumeIo && <ResumeReviewPopup resumeId={documentId} />}
    </Container>
  )
}

export default compose(withConfig, withRouter)(memo(PreviewPanel))
