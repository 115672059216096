import { useI18n } from 'builder/hooks/useI18n'
import {
  Close,
  CloseButtonContainer,
  CloseIcon,
  CTAContainer,
  CTAOutline,
  CTAPrimary,
  ModalImageSection,
  ModalSection,
  ModalSectionBottomContainer,
  Overlay,
  PlanRenewalNoticeContainer,
  PromoDescription,
  PromoFeature,
  PromoFeatures,
  PromoFeaturesContainer,
  PromoFeatureTitle,
  PromoHeaderContainer,
  PromoTextContainer,
  PromoTitle,
  RootModalContainer,
  Video,
} from './styles'
import resumeDistributionPromoGif from './assets/rd-gif-popup.webm'
import { useResumeDistributionPromoModal } from './useResumeDistributionPromoModal'

export const ResumeDistributionPromoModal = () => {
  const { i18n } = useI18n()
  const {
    isOpen,
    handleModalClose,
    getCTAText,
    getPriceFromPlan,
    shouldRenderPlanRenewalNotice,
    redirectToCheckout,
  } = useResumeDistributionPromoModal()

  if (!isOpen) return null

  const ctaText = getCTAText()
  const price = getPriceFromPlan()

  const PlanRenewalNotice = () => {
    if (!shouldRenderPlanRenewalNotice()) return null
    return (
      <PlanRenewalNoticeContainer>
        <p>{i18n.t('builder.resume_distribution.promo.plan_renewal_notice')}</p>
      </PlanRenewalNoticeContainer>
    )
  }

  return (
    <Overlay>
      <RootModalContainer>
        <ModalSection>
          <PromoTextContainer>
            <PromoHeaderContainer>
              <PromoTitle>{i18n.t('builder.resume_distribution.promo.title')}</PromoTitle>
              <PromoDescription>
                {i18n.t('builder.resume_distribution.promo.description')}
              </PromoDescription>
            </PromoHeaderContainer>
            <PromoFeaturesContainer>
              <PromoFeatureTitle>
                {i18n.t('builder.resume_distribution.promo.features_title')}
              </PromoFeatureTitle>
              <PromoFeatures>
                <PromoFeature>
                  {i18n.t('builder.resume_distribution.promo.features.monthly_distribution')}
                </PromoFeature>
                <PromoFeature>
                  {i18n.t('builder.resume_distribution.promo.features.access_to_recruiters')}
                </PromoFeature>
                <PromoFeature>
                  {i18n.t('builder.resume_distribution.promo.features.unlimited_changes')}
                </PromoFeature>
                <PromoFeature>
                  {i18n.t('builder.resume_distribution.promo.features.match_with_recruiters')}
                </PromoFeature>
              </PromoFeatures>
            </PromoFeaturesContainer>
          </PromoTextContainer>
          <ModalSectionBottomContainer>
            <PlanRenewalNotice />
            <CTAContainer>
              <CTAOutline onClick={handleModalClose}>
                {i18n.t('builder.resume_distribution.promo.cta.download_only')}
              </CTAOutline>
              <CTAPrimary onClick={redirectToCheckout}>{i18n.t(ctaText, { price })}</CTAPrimary>
            </CTAContainer>
          </ModalSectionBottomContainer>
        </ModalSection>
        <ModalImageSection>
          <CloseButtonContainer>
            <Close onClick={handleModalClose}>
              <CloseIcon />
            </Close>
          </CloseButtonContainer>
          <Video src={resumeDistributionPromoGif} autoPlay={true} muted={true} />
        </ModalImageSection>
      </RootModalContainer>
    </Overlay>
  )
}
