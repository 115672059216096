import { numberToDelimited } from 'builder/utils/numberToDelimited'
import { SkeletonValue } from '../Skeletons'
import * as CommonStyles from '../../styles'
import * as Styles from './styles'

type Props = {
  companiesNumber?: number | null
  isSkeleton: boolean
}

export const CompaniesInfo = ({ companiesNumber, isSkeleton = false }: Props) => {
  if (!companiesNumber && !isSkeleton) return null

  return (
    <Styles.Container>
      <CommonStyles.Title>Companies hiring</CommonStyles.Title>
      {isSkeleton ? (
        <SkeletonValue />
      ) : (
        typeof companiesNumber === 'number' && (
          <CommonStyles.Value>{numberToDelimited(companiesNumber)}</CommonStyles.Value>
        )
      )}
    </Styles.Container>
  )
}
