import CustomInputButton from '../CustomInputButton'

import * as Styled from './styles'

interface Props {
  progress: number
  fileChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const UploadComplete = ({ progress, fileChange }: Props) => {
  return (
    <Styled.ResumeUploaded progressing={progress < 100}>
      {progress < 100 ? null : <Styled.GreenTick />}
      {progress < 100 ? (
        <Styled.ResumeUploadingText>Uploading will be finished in 5 sec</Styled.ResumeUploadingText>
      ) : (
        <Styled.ResumeUploadedText>Your resume has been uploaded</Styled.ResumeUploadedText>
      )}

      <Styled.ResumeUploadProgressBarContainer>
        <Styled.ResumeUploadProgressBarWrapper progressPercentage={progress}>
          <Styled.ResumeUploadProgressBar progressPercentage={progress} />
        </Styled.ResumeUploadProgressBarWrapper>
      </Styled.ResumeUploadProgressBarContainer>
      {progress < 100 ? null : <CustomInputButton handleFileChange={e => fileChange(e)} />}
    </Styled.ResumeUploaded>
  )
}

export default UploadComplete
