import { useCallback, useState } from 'react'
import * as React from 'react'
import { useI18n } from 'builder/hooks/useI18n'
import { ACCEPT } from '../constants'
import {
  DropzoneContainer,
  DropzoneIcon,
  DropzoneCaption,
  DropzoneButton,
  DropzoneNote,
  DropzoneInput,
} from './styles'

interface DropzoneProps {
  onChange: (file: File) => void
}

export const Dropzone = ({ onChange }: DropzoneProps) => {
  const { i18n } = useI18n()

  const [isDragOver, setDragOver] = useState(false)

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) onChange(e.target.files[0])
    },
    [onChange],
  )

  const handleDragStart = useCallback(() => setDragOver(true), [])
  const handleDragEnd = useCallback(() => setDragOver(false), [])

  return (
    <DropzoneContainer
      isDragOver={isDragOver}
      onDragOver={handleDragStart}
      onDragEnter={handleDragStart}
      onDrop={handleDragEnd}
      onDragEnd={handleDragEnd}
      onDragLeave={handleDragEnd}
    >
      <DropzoneIcon />
      <DropzoneCaption>{i18n.t('builder.avatar_editor.dropzone.caption')}</DropzoneCaption>
      <DropzoneButton>{i18n.t('builder.avatar_editor.dropzone.choose_image')}</DropzoneButton>
      <DropzoneNote>{i18n.t('builder.avatar_editor.dropzone.supported_formats')}</DropzoneNote>
      <DropzoneInput type="file" onChange={handleChange} accept={ACCEPT} />
    </DropzoneContainer>
  )
}
