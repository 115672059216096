import { FC } from 'react'
import * as Styled from './styles'

export const ListItem: FC<{
  header?: string
  subheader?: string
  description?: string
  iconUrl?: string
  iconAlt?: string
}> = ({ header, subheader, description, iconUrl, iconAlt, children }) => {
  return (
    <Styled.Container>
      <Styled.MainContent>
        {iconUrl && <Styled.IconImage src={iconUrl} alt={iconAlt} />}
        {header || subheader || description ? (
          <Styled.TextContainer>
            {(header || subheader) && (
              <Styled.HeaderContainer>
                {header && <Styled.Header>{header}</Styled.Header>}
                {subheader && <Styled.Subheader>{subheader}</Styled.Subheader>}
              </Styled.HeaderContainer>
            )}
            {description && <Styled.Description>{description}</Styled.Description>}
          </Styled.TextContainer>
        ) : null}
      </Styled.MainContent>
      {children && <Styled.CustomContent>{children}</Styled.CustomContent>}
    </Styled.Container>
  )
}
