import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Icon20 } from 'builder/components/Icon'

export const SkillsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
`

export const SkillTagsContainer = styled.div`
  text-align: start;
`

export const SelectedSkillsTitle = styled.span`
  ${Typography.Caption}
  ${FontWeights.DemiBold}
  margin-right: 8px;
`

export const SkillTag = styled.div`
  display: inline-flex;
  flex-direction: row;
  height: 24px;
  padding: 4px 6px;
  margin-right: 6px;
  margin-bottom: 6px;
  justify-content: center;
  align-items: center;
  ${Typography.Tiny}
`

export const SelectedSkillTag = styled(SkillTag)`
  background-color: ${Colors.Blue10};
  color: ${Colors.Blue50};
`

export const RemoveSkillIcon = styled(Icon20.Close)`
  opacity: 0;
  height: 0;
  width: 0;
  transition: all 0.3s ease-in-out;
  ${SelectedSkillTag}:hover & {
    opacity: 1;
    height: auto;
    width: auto;
    transition: all 0.3s ease-in-out;
  }
`

export const SuggestedSkillsTitle = styled.span`
  ${Typography.Caption}
  margin-right: 7px;
`

export const SuggestedSkillTag = styled(SkillTag)`
  background-color: ${Colors.Neutral10};
  color: ${Colors.Neutral50};
  white-space: nowrap;
  transition: background-color 0.1s, color 0.1s;
  will-change: opacity, transform;
  cursor: pointer;

  ${Media.Hover`
    &:hover {
      background-color: ${Colors.Blue10};
      color: ${Colors.Blue50};
    }
  `};

  &:active {
    color: ${Colors.Blue60};
  }
`
