import { useMutation } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { baseClient } from 'builder/modules/apiClient'
import { actions } from 'builder/modules/interview/interviewModule'
interface Response {
  account: {
    user: {
      current_job_title_id: string
    }
  }
}

export const useMutateJobTitle = () => {
  const dispatch = useDispatch()
  return useMutation({
    mutationFn: async (jobTitleId: number) => {
      const response = await baseClient.post<Response>(
        `interview_prep/interviews/set_user_job_title_id`,
        {
          data_api_job_title_id: jobTitleId,
        },
      )
      return response.data
    },
    onSuccess: (data: Response) => {
      dispatch(actions.setJobTitleId(+data.account.user.current_job_title_id))
    },
  })
}
