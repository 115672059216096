import SUCCESS_ICON from '../../../../../app/assets/images/snackbar-success-icon.svg'
import { Container, OpenButton, Wrapper, IconImage, Text } from './styles'

interface Props {
  text: string
  onOpen: () => void
  buttonText?: string
  className?: string
}

const OpenableSnackbar = ({ text, onOpen, buttonText, className }: Props) => {
  return (
    <Container className={className}>
      <Wrapper>
        <IconImage src={SUCCESS_ICON} alt="icon" />
        <Text>{text}</Text>
      </Wrapper>
      <OpenButton onClick={onOpen}>{buttonText || 'Open'}</OpenButton>
    </Container>
  )
}

export default OpenableSnackbar
