import * as Styled from './styles'
import ILLUSTRATION_URL from './assets/illustration.png'

type Props = {
  userPhotoUrl: string
}

export const OfferAnalyzerVisual = ({ userPhotoUrl }: Props) => {
  return (
    <>
      <Styled.Illustration alt="Offer analyzer illustration" src={ILLUSTRATION_URL} />
      <Styled.Photo alt="User photo" src={userPhotoUrl} />
    </>
  )
}
