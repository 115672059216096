import { useEffect } from 'react'
import { selectors } from 'builder/modules/careerCoaching'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { Container } from './styles'
import { getServiceName } from './utils'

// TODO: API integration
const Calendly = () => {
  const selectedService = useTypedSelector(selectors.selectedService)
  const selectedDuration = useTypedSelector(selectors.selectedDuration)

  // Using in-line widget
  const calendlyURL = `https://calendly.com/rakesh-kodagali/${getServiceName(
    selectedService,
  )}-${selectedDuration}min?hide_gdpr_banner=1&text_color=1E2532&primary_color=1A91F0`

  useEffect(() => {
    const head = document.querySelector('head')
    const script = document.createElement('script')
    script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js')
    head?.appendChild(script)
  }, [])

  return <Container className="calendly-inline-widget" data-url={calendlyURL}></Container>
}

export default Calendly
