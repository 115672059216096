import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
import Media from 'builder/styles/media'
import { Logo } from 'builder/components/Logo'

export const LogoLink = styled(RouterLink)`
  display: flex;
`

export const LogoStyled = styled(Logo)`
  ${Media.Tablet`
    background-size: auto 32px;
  `}
`
