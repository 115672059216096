import { useMutation, useQueryClient } from '@tanstack/react-query'
import { baseClient } from 'builder/modules/apiClient'
import { CustomAlertType } from 'builder/modules/jobSearch/types'

interface Params {
  id: number
}
export const useDeleteCustomAlert = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ id }: Params): Promise<void> => {
      const res = await baseClient.delete(`/job-alerts/v1/custom-subscriptions/${id}`)
      return res.data
    },
    {
      onSuccess: (data, { id }: Params) => {
        queryClient.setQueryData<CustomAlertType[]>(['useGetCustomAlerts'], customAlerts => {
          return (customAlerts || []).filter(
            (customAlert: CustomAlertType) => customAlert.id !== id,
          )
        })
      },
    },
  )
}
