import { stringify } from 'query-string'
import jwtService from 'builder/services/JwtService'

export default class DocxDownload {
  constructor(document) {
    this.document = document
  }

  async start() {
    // Get document data
    const jwt = await jwtService.getToken()
    const { renderingToken: token, version } = this.document

    // Download file by URL
    const queryString = stringify({
      token: jwt,
      template: this.document.template,
      version,
    })

    const url = `${process.env.SSR_HOST}/to-docx/${token}?${queryString}`

    window.location = url

    return url
  }
}
