import styled from 'styled-components'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Sizes from 'builder/styles/sizes'

export const Container = styled.div`
  display: flex;
  gap: ${Sizes.M};
  border-radius: ${Sizes.S};
  padding: ${Sizes.L};
  background-color: ${Colors.Neutral5};

  ${Media.Phone`
    flex-flow: column;
    align-items: center;
  `}
`

export const UserAvatar = styled.img`
  --avatar-size: 92px;
  width: var(--avatar-size);
  height: var(--avatar-size);
  flex: 0 0 var(--avatar-size);
  border-radius: 50%;
`

export const UserInfoContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${Sizes.M};
  align-items: start;
`

export const UserInfo = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${Sizes.S};

  ${Media.Phone`
    text-align: center;
    align-items: center;
  `}
`

export const UserName = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};
`

export const UserInfoItem = styled.div`
  display: flex;
  gap: ${Sizes['3XS']};
  align-items: center;
`

export const UserTypeText = styled.div`
  ${Typography.Subhead};
`

export const DefineTargetRole = styled(UserInfoItem)`
  ${FontWeights.Medium};
  color: ${Colors.Blue50};
  will-change: color;
  transition: color 0.2s ease-out;
  cursor: pointer;

  ${Media.Hover`
    &:hover {
      color: ${Colors.Blue60};
    }
  `}
`

export const ButtonContent = styled.div`
  display: flex;
  gap: ${Sizes['3XS']};
`
