import styled from 'styled-components'

export const LogoContainer = styled.span<{ imageUrl?: string; isCioDomain?: boolean }>`
  display: inline-block;
  width: ${props => (props.imageUrl ? `125px` : '120px')};
  height: ${props => (props.imageUrl ? `35.9px` : '32px')};
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: contain;
  background-image: ${props => (props.imageUrl ? `url(${props.imageUrl})` : 'none')};
  vertical-align: middle;
`
