import * as React from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { trackMarketingEvent } from '@rio/tracking'
import { actions as userActions, selectors as userSelectors } from 'builder/modules/user'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

let isTracked = false

interface Props {
  premium?: boolean
}

export const UserScope: React.FC<Props> = ({ children, premium }) => {
  const dispatch = useDispatch()
  const isUserPremium = useTypedSelector(userSelectors.premiumAccess)
  const isUserFetched = useTypedSelector(userSelectors.isFetched)

  useEffect(() => {
    if (!isUserFetched) {
      dispatch(userActions.fetchUserRequest())
    }
  }, [dispatch, isUserFetched])

  useEffect(() => {
    if (!isTracked) {
      trackMarketingEvent('Application', 'Open Builder')
      isTracked = true
    }
  }, [dispatch])

  if (!isUserFetched) return null

  if (premium && !isUserPremium) return <Navigate replace to="/" />

  return <React.Fragment>{children}</React.Fragment>
}
