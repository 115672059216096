import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import times from 'lodash/times'
import { trackInternalEvent } from '@rio/tracking'
import { actions, selectors } from 'builder/modules/offerAnalyzer'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useI18n } from 'builder/hooks/useI18n'
import { useUser } from 'builder/hooks/useUser'
import JobOfferDetails from 'builder/components/JobOfferDetails'
import JobOfferProComDetails from 'builder/components/JobOfferProComDetails'
import JobOfferBenefitsTerms from 'builder/components/JobOfferBenefitsTerms'
import { Icon24 } from '../Icon'

import HEALTH_CARE_ICON from './assets/images/health.svg'
import PARENTAL_LEAVE_ICON from './assets/images/leave.svg'
import PAID_TIME_ICON from './assets/images/paid-time.svg'
import PROFILE_ICON from './assets/images/profile.svg'
import JOB_TITLE_ICON from './assets/images/job.svg'

import { CardInterface, SlideInterface } from './types'

import {
  Row,
  Section,
  Content,
  Container,
  Footer,
  SectionInner,
  SectionTitle,
  SectionSubTitle,
  CardContainer,
  Card,
  CardTitle,
  CardBody,
  DotsContainer,
  ButtonContainer,
  Box,
  BoxItem,
  StyledButton,
  FieldsContainer,
  IconsContainer,
  IconsHolder,
  Dot,
  IconImage,
  LabelText,
  WorkBag,
  AnimatedLineContainer,
  AnimatedLineBlue,
  AnimatedLinePurple,
  FieldsContentLeftPanel,
} from './styles'

const JobOffer = () => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const prevSlideState = useTypedSelector(selectors.prevSlide)
  const currentSlideState = useTypedSelector(selectors.currentSlide)
  const {
    jobTitle,
    companyName,
    location,
    baseSalary,
    yearsOfExperience,
    educationalQualification,
    hasHealthCare,
    hasPto,
    hasParentalLeave,
  } = useTypedSelector(selectors.fields)
  const errors = useTypedSelector(selectors.errors)
  const lastFetchedJobTitles = useTypedSelector(selectors.lastFetchedJobTitles)
  const lastFetchedLocations = useTypedSelector(selectors.lastFetchedLocations)
  // const offerAnalyzerUserData = useTypedSelector(selectors.offerAnalyzerUserData)
  const offerAnalyzerUserData = useTypedSelector(selectors.offerAnalyzerRequestData)

  const user = useUser()
  const navigate = useNavigate()

  useEffect(() => {
    if (currentSlideState === 1) {
      trackInternalEvent('visit_salary_analyzer', { label: 'salary_analyzer', parameter: 'step1' })
    }
  }, [currentSlideState])

  const getSectionTitleBasedOnSlide = (currentSlide: number) => {
    switch (currentSlide) {
      case 1:
        return i18n.t('builder.offer_analyzer.offer_details')
      case 2:
        return i18n.t('builder.offer_analyzer.pro_comp_details')
      case 3:
        return i18n.t('builder.offer_analyzer.benefits_terms')
    }
  }

  const slideProps: SlideInterface = {
    prevSlide: prevSlideState,
    currentSlide: currentSlideState,
  }

  const getSectionInner = (key: number, Component: React.FC) => {
    return (
      <SectionInner slide={slideProps} key={key}>
        <SectionTitle slide={currentSlideState}>
          {i18n.t('builder.offer_analyzer.step', { number: currentSlideState })}:{' '}
          {getSectionTitleBasedOnSlide(currentSlideState)}
        </SectionTitle>

        {currentSlideState === 1 ? (
          <SectionSubTitle>
            {i18n.t('builder.offer_analyzer.request_offer_details')}
          </SectionSubTitle>
        ) : null}
        <Component />
      </SectionInner>
    )
  }

  const getComponentBasedOnSlide = (currentSlide: number) => {
    switch (currentSlide) {
      case 1:
        return getSectionInner(1, JobOfferDetails)
      case 2:
        return getSectionInner(2, JobOfferProComDetails)
      case 3:
        return getSectionInner(3, JobOfferBenefitsTerms)
      default:
        return null
    }
  }

  const isNotEmptyString = (val: string) => {
    if (val && val.length > 0) {
      return true
    }
    return false
  }

  const isValidJobTitle = (value: string) => {
    return lastFetchedJobTitles.find(title => title === value)
  }

  const isValidLocation = (value: string) => {
    return lastFetchedLocations.find(title => title === value)
  }

  const handleNextClick = () => {
    if (currentSlideState === 1) {
      if (
        isNotEmptyString(jobTitle) &&
        isNotEmptyString(companyName) &&
        isNotEmptyString(location)
      ) {
        if (isValidJobTitle(jobTitle) && isValidLocation(location)) {
          dispatch(
            actions.setErrors({ ...errors, titleError: '', companyError: '', locationError: '' }),
          )
          dispatch(actions.setNextSlide())
        } else {
          dispatch(
            actions.setErrors({
              ...errors,
              companyError: '',
              titleError: isValidJobTitle(jobTitle)
                ? ''
                : i18n.t('builder.offer_analyzer.job_title_error'),
              locationError: isValidLocation(location)
                ? ''
                : i18n.t('builder.offer_analyzer.location_error'),
            }),
          )
        }
      } else {
        dispatch(
          actions.setErrors({
            ...errors,
            titleError: !isNotEmptyString(jobTitle) ? 'Please fill job title' : '',
            companyError: !isNotEmptyString(companyName) ? 'Please fill company' : '',
            locationError: !isNotEmptyString(location) ? 'Please fill location' : '',
          }),
        )
      }
    }
    if (currentSlideState === 2) {
      if (educationalQualification !== '' && yearsOfExperience !== '' && baseSalary !== '') {
        dispatch(
          actions.setErrors({
            ...errors,
            educationalQualError: '',
            workExpError: '',
            baseSalaryError: '',
          }),
        )
        dispatch(actions.setNextSlide())
      } else {
        dispatch(
          actions.setErrors({
            ...errors,
            educationalQualError: !isNotEmptyString(educationalQualification)
              ? 'Please fill qualification'
              : '',
            workExpError: !isNotEmptyString(yearsOfExperience) ? 'Please fill experience' : '',
            baseSalaryError: !isNotEmptyString(baseSalary) ? 'Please fill salary' : '',
          }),
        )
      }
    }
  }

  const handleAnalyzeOfferClick = () => {
    dispatch(actions.fetchOfferSuggestedForUser(offerAnalyzerUserData))
    navigate('/offer-analyzer-result')
  }

  const card1Props: CardInterface = {
    cardId: 1,
    prevSlide: prevSlideState,
    currentSlide: currentSlideState,
  }

  const card2Props: CardInterface = {
    cardId: 2,
    prevSlide: prevSlideState,
    currentSlide: currentSlideState,
  }

  return (
    <Container>
      <Content>
        <Row>
          <FieldsContainer>
            <FieldsContentLeftPanel>
              <Section slide={currentSlideState}>
                {getComponentBasedOnSlide(currentSlideState)}
              </Section>
            </FieldsContentLeftPanel>
            <Footer>
              <DotsContainer>
                {times(3, stepIndex => (
                  <Dot key={stepIndex} isActive={stepIndex + 1 === currentSlideState} />
                ))}
              </DotsContainer>
              <ButtonContainer>
                {currentSlideState > 1 ? (
                  <StyledButton
                    onClick={() => {
                      dispatch(actions.setPrevSlide())
                    }}
                    theme="ghost"
                  >
                    {i18n.t('builder.offer_analyzer.back_button')}
                  </StyledButton>
                ) : null}
                {currentSlideState < 3 ? (
                  <StyledButton onClick={handleNextClick}>
                    {i18n.t('builder.offer_analyzer.next_button')}
                  </StyledButton>
                ) : (
                  <StyledButton onClick={handleAnalyzeOfferClick}>
                    {i18n.t('builder.offer_analyzer.analyze_offer_button')}
                  </StyledButton>
                )}
              </ButtonContainer>
            </Footer>
          </FieldsContainer>

          <CardContainer slide={currentSlideState}>
            <Card card={card1Props}>
              <CardTitle>
                {user?.fullName} <IconImage src={PROFILE_ICON} />
              </CardTitle>
              <CardBody>
                <Box>
                  <BoxItem>
                    <Icon24.Simple />
                    <LabelText isActive={isNotEmptyString(educationalQualification)}>
                      {isNotEmptyString(educationalQualification)
                        ? educationalQualification
                        : i18n.t('builder.offer_analyzer.education')}
                    </LabelText>
                  </BoxItem>
                  <BoxItem>
                    <WorkBag />
                    <LabelText isActive={isNotEmptyString(yearsOfExperience)}>
                      {isNotEmptyString(yearsOfExperience)
                        ? `${yearsOfExperience} of work experience`
                        : i18n.t('builder.offer_analyzer.work_experience')}
                    </LabelText>
                  </BoxItem>
                  <BoxItem>
                    <Icon24.Cash />
                    <LabelText isActive={isNotEmptyString(baseSalary)}>
                      {isNotEmptyString(baseSalary)
                        ? `$${new Intl.NumberFormat('en').format(parseInt(baseSalary))}`
                        : i18n.t('builder.offer_analyzer.salary')}
                    </LabelText>
                  </BoxItem>
                </Box>
              </CardBody>
              {currentSlideState === 2 && (
                <AnimatedLineContainer>
                  <AnimatedLineBlue />
                  <AnimatedLinePurple />
                </AnimatedLineContainer>
              )}
              <IconsContainer>
                {hasHealthCare && currentSlideState === 3 && (
                  <IconsHolder>
                    <IconImage src={HEALTH_CARE_ICON} />
                  </IconsHolder>
                )}
                {hasPto && currentSlideState === 3 && (
                  <IconsHolder>
                    <IconImage src={PAID_TIME_ICON} />
                  </IconsHolder>
                )}
                {hasParentalLeave && currentSlideState === 3 && (
                  <IconsHolder>
                    <IconImage src={PARENTAL_LEAVE_ICON} />
                  </IconsHolder>
                )}
              </IconsContainer>
            </Card>

            <Card card={card2Props}>
              <CardTitle>
                {isNotEmptyString(jobTitle) ? jobTitle : i18n.t('builder.offer_analyzer.job_title')}
                {isNotEmptyString(jobTitle) ? <IconImage src={JOB_TITLE_ICON} /> : null}
              </CardTitle>
              <CardBody>
                <Box>
                  <BoxItem>
                    <WorkBag />
                    <LabelText isActive={isNotEmptyString(companyName)}>
                      {isNotEmptyString(companyName)
                        ? companyName
                        : i18n.t('builder.offer_analyzer.company_name')}
                    </LabelText>
                  </BoxItem>
                  <BoxItem>
                    <Icon24.Pin />
                    <LabelText isActive={isNotEmptyString(location)}>
                      {isNotEmptyString(location)
                        ? location
                        : i18n.t('builder.offer_analyzer.location')}
                    </LabelText>
                  </BoxItem>
                </Box>
              </CardBody>
            </Card>
          </CardContainer>
        </Row>
      </Content>
    </Container>
  )
}
export default JobOffer
