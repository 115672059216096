import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Icon from 'builder/components/Icon'
import Button from 'builder/components/Button'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  gap: 32px;
  display: flex;
  flex-direction: column;
`

export const HeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

export const ArrowContainer = styled.button`
  width: 80px;
  height: 32px;
  border: none;
  display: flex;
  flex-shrink: 0;
  cursor: pointer;
  align-items: center;
  align-self: flex-start;
  border-radius: 20px;
  justify-content: flex-start;
  background: ${Colors.Neutral10};
  padding: 4px 16px 4px 8px;

  & svg {
    min-width: 24px;
    color: ${Colors.Neutral50};
  }

  &:hover {
    background: ${Colors.Blue10};
  }

  ${Media.Phone`
    width: 40px;
  `}
`

export const LeftArrow = styled(Icon.Chevron)`
  transform: rotate(0.5turn);

  ${ArrowContainer}:hover & {
    color: ${Colors.Blue50};
  }
`

export const Text = styled.span`
  ${Typography.Caption};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral50};

  ${ArrowContainer}:hover & {
    color: ${Colors.Blue50};
  }

  ${Media.Phone`
    visibility: hidden;
  `}
`

export const StyledButton = styled(Button)`
  ${Media.Phone`
    z-index: 1;
    position: fixed;
    bottom: 32px;
    width: calc(100% - 40px);
  `}
`

export const EditButton = styled(Button)`
  ${Media.Phone`
    width: 48px;
    height: 48px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`
