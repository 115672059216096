import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import { Icon20 } from 'builder/components/Icon'
import Typography, { FontWeights } from 'builder/styles/typography'
import Button from 'builder/components/Button'

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 544px;
  background-color: ${Colors.White};
  border-radius: 16px;
  z-index: 999;

  ${Media.Phone`
    max-width: 343px;
  `};
`

export const ImageContainer = styled.div`
  width: 100%;
  height: 280px;
  cursor: pointer;
  position: relative;
  background-image: url(${require('../../assets/welcome_modal_play_background.svg')});
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px 16px 0 0;

  ${Media.Phone`
    height: 192px;
  `};
`
export const PromoImageContainer = styled.div`
  width: 100%;
  height: 280px;
  cursor: pointer;
  position: relative;
  background-image: url(${require('./assets/cio_promo_modal.gif')});
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px 16px 0 0;

  ${Media.Phone`
    height: 192px;
  `};
`

export const PlayImage = styled.img`
  width: 68px;
  height: 68px;
`

export const Close = styled.div`
  position: absolute;
  width: 28px;
  height: 28px;
  top: 28px;
  right: 28px;
  border-radius: 32px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.Neutral30};
  cursor: pointer;
  transition: color 0.1s;
  background-color: ${Colors.White};

  &:hover {
    color: ${Colors.Blue50};
  }

  ${Media.Phone`
    top: 20px;
    right: 20px;
    padding: 2px;
  `};
`
export const DescriptionSection = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  padding: 28px 32px 32px 32px;

  ${Media.Phone`
    gap: 16px;
    padding: 20px;
  `};
`
export const Description = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: flex-start;

  ${Media.Phone`
    gap: 4px;
  `};
`
export const Header = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: flex-start;

  ${Media.Phone`
    gap: 8px;
  `};
`
export const Title = styled.div`
  ${Typography.S};
  ${FontWeights.DemiBold};
`
export const SubTitle = styled.div`
  color: ${Colors.Neutral50};
  margin: 0px;
`
export const ServicesSection = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;

  ${Media.Phone`
    gap: 12px;
  `};
`
export const ServicesSectionHeading = styled.div`
  ${Typography.Caps};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral80};
`
export const ServicesContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  padding: 12px;
  background-color: ${Colors.Neutral5};
  justify-content: space-between;
`
export const ServiceItem = styled.div<{ isPhone: boolean }>`
  display: flex;
  width: ${props => (props.isPhone ? '100%' : '49%')};
`
export const Service = styled.div`
  ${Typography.Caption};
`
export const ServiceSubText = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral40};
  margin-left: 4px;
`
export const GreenTick = styled(Icon20.Tick)`
  color: ${Colors.Green40};
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 4px;

  ${Media.Phone`
    gap: 8px;
  `};
`
export const StartButton = styled(Button)``
