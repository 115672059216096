import { ReactElement } from 'react'
import { Column, ColumnText, ColumnWithAvatar, ColumnWithIcon, Position } from './styles'

interface ListItemColumnProps {
  // Element props
  avatar?: ReactElement
  header?: ReactElement
  text?: ReactElement
  subText?: ReactElement
  icon?: ReactElement

  // Column props
  columnWidth?: number

  // Columns text props
  columnTextPadding?: string
  columnTextMargin?: string
  columnTextPosition?: Position
}

export const ListItemColumn = ({
  avatar,
  icon,
  header,
  text,
  subText,
  columnWidth,
  columnTextPadding,
  columnTextMargin,
  columnTextPosition,
}: ListItemColumnProps) => {
  if (avatar) {
    return (
      <Column size={columnWidth} position={columnTextPosition}>
        <ColumnWithAvatar>
          {avatar}
          <ColumnText padding={columnTextPadding} margin={columnTextMargin}>
            {header && header}
            {text && text}
            {subText && subText}
          </ColumnText>
        </ColumnWithAvatar>
      </Column>
    )
  }

  if (icon) {
    return (
      <Column size={columnWidth} position={columnTextPosition}>
        <ColumnWithIcon>
          {icon}
          <ColumnText padding={columnTextPadding} margin={columnTextMargin}>
            {header && header}
            {text && text}
            {subText && subText}
          </ColumnText>
        </ColumnWithIcon>
      </Column>
    )
  }

  return (
    <Column size={columnWidth} position={columnTextPosition}>
      <ColumnText padding={columnTextPadding} margin={columnTextMargin}>
        {header && header}
        {text && text}
        {subText && subText}
      </ColumnText>
    </Column>
  )
}
