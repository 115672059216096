import map from 'lodash/map'
import { Icon24, Icon20 } from 'builder/components/Icon'
import { FeatureIcon, featureIconNames } from 'builder/components/FeatureIcon'
import { Section } from '../../Section'
import { Group, ItemName, Label } from '../../styles'
import { IconsList, IconsItem, IconsItemPreview } from './styles'
import { sortIcons } from './utils'

export const IconsSection = () => {
  const iconGroups = {
    '24x24': sortIcons(Icon24),
    '20x20': sortIcons(Icon20),
  }

  return (
    <Section title="Icons">
      {map(iconGroups, (group, groupName) => (
        <Group key={groupName}>
          <Label>{groupName}</Label>
          <IconsList>
            {map(group, (Icon, iconName) => (
              <IconsItem key={iconName}>
                <IconsItemPreview>
                  <Icon />
                </IconsItemPreview>
                <ItemName>{iconName}</ItemName>
              </IconsItem>
            ))}
          </IconsList>
        </Group>
      ))}

      <Group key="featureIcons">
        <Label>FeatureIcon</Label>
        <IconsList>
          {featureIconNames.map(iconName => (
            <IconsItem key={iconName}>
              <IconsItemPreview>
                <FeatureIcon name={iconName} disabled={false} />
              </IconsItemPreview>
              <ItemName>{iconName}</ItemName>
            </IconsItem>
          ))}
        </IconsList>
      </Group>
    </Section>
  )
}
