import { memo } from 'react'
import { AvatarContainer, Letter } from './styles'
import PropTypes from 'prop-types'
import Colors from 'builder/styles/colors'

const AVATAR_COLORS = [
  Colors.Blue50,
  Colors.Orange40,
  Colors.Amber40,
  Colors.Green50,
  '#d34836',
  Colors.Indigo80,
  Colors.Indigo60,
]

const fontSizeRatio = 0.57

const seed = text => Array.from(text.slice(0, 3)).reduce((acc, char) => acc + char.charCodeAt(0), 0)

const Avatar = ({ src, alt, size, ...rest }) => {
  const empty = alt.length < 1
  const color = empty ? Colors.Neutral10 : AVATAR_COLORS[seed(alt) % AVATAR_COLORS.length]

  return (
    <AvatarContainer src={src} color={color} size={size} {...rest}>
      {!src && <Letter fontSize={fontSizeRatio * size}>{empty ? '' : alt[0]}</Letter>}
    </AvatarContainer>
  )
}

Avatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  size: PropTypes.number,
  color: PropTypes.string,
}

Avatar.defaultProps = {
  size: 28,
  alt: '',
}

export default memo(Avatar)
