import styled, { keyframes, css } from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import { Icon24 } from 'builder/components/Icon'

const fadeOut = keyframes`
  from {
    opacity: 1;
    max-height: 200px;
  }
  to {
    opacity: 0;
    max-height: 0;
  }
`

export const Container = styled.div<{ animate: boolean }>`
  gap: 24px;
  display: flex;
  position: relative;

  ${Media.Phone`
    gap: 12px
  `}

  ${props =>
    props.animate &&
    css`
      animation: ${fadeOut} 0.3s ease-out;
      overflow: hidden;
    `}
`

export const Wrapper = styled.div<{ isLastElement: boolean }>`
  width: 100%;
  gap: 16px;
  display: flex;
  flex-direction: column;
  padding-bottom: ${props => (props.isLastElement ? 0 : '64px')};

  ${props => Media.Phone`
    gap: 8px;
    padding-bottom: ${props.isLastElement ? 0 : '48px'};
  `}
`

export const Icon = styled.div`
  width: 24px;
  height: 24px;
  padding: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  color: ${Colors.White};
  background-color: ${Colors.Blue50};
  position: relative;

  &:hover {
    background-color: ${Colors.Blue60};
  }
`

export const Check = styled(Icon24.Tick)`
  position: absolute;
  margin: auto;
`

export const Identifier = styled.div`
  width: 24px;
  height: 24px;
  padding: 16px;
  cursor: pointer;
  display: grid;
  place-items: center;
  border-radius: 100px;
  color: ${Colors.Neutral30};
  background-color: ${Colors.Neutral5};
  position: relative;

  &:hover {
    color: ${Colors.Blue50};
    outline: 2px solid ${Colors.Blue50};
  }
`

export const Id = styled.div`
  position: absolute;
  margin: auto;
`

export const VerticalLine = styled.div<{ isVisible: boolean }>`
  width: 1px;
  background-color: ${Colors.Neutral15};
  position: absolute;
  top: 32px;
  bottom: 0;
  left: 16px;
  display: ${props => (props.isVisible ? 'visible' : 'none')};
`

export const Description = styled.div`
  color: ${Colors.Neutral50};
`

export const StyledLock = styled(Icon24.Lock)`
  color: ${Colors.Neutral30};
  position: absolute;
  margin: auto;

  &:hover {
    color: ${Colors.Blue50};
  }
`
