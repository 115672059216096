import { memo, useEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { Icon20 } from 'builder/components/Icon'
import { i18n as I18n } from 'builder/utils/i18n'
import { PrefillResumeEvents } from 'builder/modules/constants'
import { useConfig } from 'builder/hooks/useConfig'
import { OptionsContainer, DesktopClose as Close, Title, SubTitle } from '../styles'
import OptionCards from './OptionCards'

type Props = {
  onClose: () => void
}

const FillOptions = (props: Props) => {
  const { onClose } = props
  const CloseIcon = Icon20.Close

  const config = useConfig()

  const useNewPhrases = config?.features.newStartScreen === 'old_design_new_text'
  const title = useNewPhrases
    ? I18n.t('builder.prefill_resume.options_view.heading')
    : I18n.t('builder.prefill_resume.start_title')

  useEffect(() => {
    trackInternalEvent(PrefillResumeEvents.seeStartScreen)
  }, [])

  const onStartScreenClose = () => {
    trackInternalEvent(PrefillResumeEvents.clickCrossButtonStartScreen)
    onClose()
  }

  return (
    <OptionsContainer>
      <Close onClick={onStartScreenClose}>
        <CloseIcon />
      </Close>
      <Title>{title}</Title>
      <SubTitle>{I18n.t('builder.prefill_resume.start_subtitle')}</SubTitle>
      <OptionCards {...{ onClose }} />
    </OptionsContainer>
  )
}

export default memo(FillOptions)
