import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import Icon24 from 'builder/components/Icon'
import {
  actions as jobSearchActions,
  selectors as jobSearchSelectors,
} from 'builder/modules/jobSearch'
import { AlertTypes } from 'builder/modules/jobSearch/types'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useGetCustomAlerts } from '../hooks/useGetCustomAlerts'
import { getLabel } from '../getLabel'
import { Container } from './styles'

export const ManagerJobAlertButton = () => {
  const { i18n } = useI18n()

  const dispatch = useDispatch()
  const enableJobAlertFeature = useTypedSelector(jobSearchSelectors.enableJobAlertFeature)
  const customAlerts = useGetCustomAlerts(enableJobAlertFeature)
  const showCreateJobAlert = customAlerts.data?.length === 0

  if (!enableJobAlertFeature || customAlerts.isLoading) {
    return null
  }

  return (
    <Container
      onClick={() => {
        trackInternalEvent('click_manage_job_alert', {
          label: getLabel(),
          parameter: showCreateJobAlert ? 'create' : 'manage',
        })
        dispatch(jobSearchActions.setAlertType({ type: AlertTypes.manage }))
      }}
    >
      <Icon24.BellIcon />
      {i18n.t(`builder.job_search.${showCreateJobAlert ? 'create_job_alert' : 'manage_job_alert'}`)}
    </Container>
  )
}
