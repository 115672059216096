import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { actions as uiActions } from 'builder/modules/ui'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import ModalOverlay from 'builder/components/ModalOverlay'
import { Icon24 } from 'builder/components/Icon'
import FeedbackForm from './FeedbackForm'

import { ModalContainer, MobileClose, DesktopClose } from './styles'

export const RateModal = () => {
  const dispatch = useDispatch()
  const { isPhone } = useMediaQueries()

  const handleClose = useCallback(() => {
    return dispatch(uiActions.closeRateModal())
  }, [dispatch])

  const Close = isPhone ? MobileClose : DesktopClose
  const CloseIcon = isPhone ? Icon24.Close : Icon24.CloseLarge

  return (
    <ModalOverlay
      onClick={handleClose}
      overlayFadeDuration={isPhone ? 0 : 350}
      contentSlideDuration={isPhone ? 0 : 350}
      fullScreen={isPhone}
    >
      <ModalContainer>
        <FeedbackForm />

        <Close onClick={handleClose}>
          <CloseIcon />
        </Close>
      </ModalContainer>
    </ModalOverlay>
  )
}
