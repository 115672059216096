import { useCallback, useContext, useMemo } from 'react'
import * as React from 'react'
import { Optional, ResumeWorkExperience } from '@rio/types'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { formatDateRange } from 'builder/utils/formatDateRange'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { TextField } from 'builder/components/TextField'
import DateRangePicker from 'builder/components/DateRangePicker'
import RichTextArea from 'builder/components/RichTextArea'
import { TunerEditorContext } from 'builder/components/Tuner'
import { AsyncAutosuggest, createSuggestionsApiFetcher } from 'builder/components/AsyncAutosuggest'
import {
  getCardFieldHighlightId,
  SectionFieldNames,
  SectionNames,
} from 'builder/modules/resumeEditor'
import { displayFieldPerTemplateConfig } from '../DocumentEditor/ATSResumeEditor/builderSectionsConfig'
import { TemplateName } from '../Helper/constants'
import { SuggestionConfig } from '../RichTextArea/types'
import { useKeywords, WorkExperienceKeywordsToggler } from '../AiFeatures/Keywords'
import Base from './Base'

import { CardContentWrapper, Row, Field } from './styles'

interface Props {
  item: ResumeWorkExperience
  autoFocus?: boolean
  locale: string
  sectionId: string
  onChange: (
    sectionId: string,
    cardId: ResumeWorkExperience['id'],
    values: Partial<ResumeWorkExperience>,
    debounce?: boolean,
  ) => void
  template: TemplateName
  jobTitle: string
  isRecommendedLengthHidden?: boolean
}

type DateRange = Pick<
  ResumeWorkExperience,
  'dateFrom' | 'dateUntil' | 'isDateUntilPresent' | 'isMonthFromHidden' | 'isMonthUntilHidden'
>

const FieldNames = SectionFieldNames[SectionNames.workExperiences]

export const WorkExperienceCard = ({
  item,
  autoFocus,
  locale,
  sectionId,
  onChange,
  template,
  jobTitle,
  isRecommendedLengthHidden,
  ...rest
}: Props) => {
  const { i18n } = useI18n()

  const { id: cardId, title, employer } = item

  const cardTitle = useMemo(() => {
    const joiningString = ' ' + i18n.t('builder.resume_editor.labels.at') + ' '

    return (
      /* addind space before and after at */
      [title, employer].filter(Boolean).join(joiningString) ||
      i18n.t('builder.resume_editor.not_specified')
    )
  }, [i18n, employer, title])

  const cardSubtitle = formatDateRange(item)

  // Workaround to perform focusing on the description field from the tooltip
  // TODO: Find a better way
  const tunerEditor = useContext(TunerEditorContext)
  const isActiveIssue = tunerEditor?.activeIssueId === 'no_work_description'

  const suggestionLocales = useTypedSelector(state => state.generalEditor.suggestionLocales)
  const { isKeywordsAvailable } = useKeywords()
  const suggestionEnabled = suggestionLocales.workExperience?.includes(locale)
  const suggestionConfig = useMemo<Optional<SuggestionConfig>>(() => {
    return suggestionEnabled
      ? {
          mode: 'phrases',
          scope: 'workExperience',
          keywordsConfig: isKeywordsAvailable
            ? {
                section: SectionNames.workExperiences,
                buttonText: i18n.t('builder.resume_editor.work_experiences_card.add_keywords'),
              }
            : null,
          onOpenSuggestionVariants: () => {
            trackInternalEvent('click_writing_help', {
              section: 'work_experience',
            })
          },
        }
      : null
  }, [suggestionEnabled, isKeywordsAvailable, i18n])
  const isAthensTemplate = template === TemplateName.Athens
  const isPragueTemplate = template === TemplateName.Prague

  const updateSimpleField = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(sectionId, cardId, { [e.target.name]: e.target.value }, true)
    },
    [onChange, sectionId, cardId],
  )

  const updateDescription = useCallback(
    (description: string) => onChange(sectionId, cardId, { description }, true),
    [onChange, sectionId, cardId],
  )

  const updateDateRange = useCallback(
    (value: Partial<DateRange>) => {
      const isChanged = Object.entries(value).some(
        ([key, value]) => value !== item[key as keyof DateRange],
      )

      if (isChanged) {
        onChange(sectionId, cardId, value, true)
      }
    },
    [onChange, sectionId, cardId, item],
  )

  const showDescriptionField = displayFieldPerTemplateConfig(
    template,
    sectionId as SectionNames,
    'description',
  )

  const showCityField = displayFieldPerTemplateConfig(template, sectionId as SectionNames, 'city')

  const descriptionLabelBadges = [<WorkExperienceKeywordsToggler key="1" />]

  return (
    <Base title={cardTitle} subtitle={cardSubtitle} item={item} sectionId={sectionId} {...rest}>
      <CardContentWrapper>
        <Row>
          <Field
            data-highlight-id={getCardFieldHighlightId(
              SectionNames.workExperiences,
              item.cid || item.id,
              FieldNames.title,
            )}
          >
            <TextField
              autoFocus={autoFocus && !isActiveIssue}
              label={i18n.t('builder.resume_editor.work_experiences_card.title')}
              name={FieldNames.title}
              onChange={updateSimpleField}
              value={item.title || ''}
            />
          </Field>

          <Field
            data-highlight-id={getCardFieldHighlightId(
              SectionNames.workExperiences,
              item.cid || item.id,
              FieldNames.employer,
            )}
          >
            <TextField
              label={i18n.t('builder.resume_editor.work_experiences_card.employer')}
              name={FieldNames.employer}
              onChange={updateSimpleField}
              value={item.employer || ''}
            />
          </Field>
        </Row>

        <Row>
          <Field>
            <DateRangePicker
              value={item}
              onChange={updateDateRange}
              currentlyLabel={i18n.t('builder.date_range_picker.currently.work')}
              dateFromHighlightId={getCardFieldHighlightId(
                SectionNames.workExperiences,
                item.cid || item.id,
                FieldNames.dateFrom,
              )}
              dateUntilHighlightId={getCardFieldHighlightId(
                SectionNames.workExperiences,
                item.cid || item.id,
                FieldNames.dateUntil,
              )}
            />
          </Field>
          {showCityField && (
            <Field>
              <AsyncAutosuggest
                highlightedQuery
                name="city"
                value={item.city || ''}
                onChange={updateSimpleField}
                fetchItems={createSuggestionsApiFetcher('city', { locale })}
              >
                {inputProps => (
                  <TextField
                    {...inputProps}
                    label={
                      isAthensTemplate || isPragueTemplate
                        ? i18n.t('builder.resume_editor.work_experiences_card.location')
                        : i18n.t('builder.resume_editor.work_experiences_card.city')
                    }
                  />
                )}
              </AsyncAutosuggest>
            </Field>
          )}
        </Row>

        {showDescriptionField && (
          <Row>
            <Field fullwidth>
              <RichTextArea
                autoFocus={autoFocus && isActiveIssue}
                recommendedLength={isRecommendedLengthHidden ? undefined : 200}
                jobTitle={item.title || jobTitle}
                label={i18n.t('builder.resume_editor.work_experiences_card.description')}
                placeholder={i18n.t(
                  'builder.resume_editor.work_experiences_card.description_placeholder',
                )}
                value={item.description || ''}
                onChange={updateDescription}
                suggestionConfig={suggestionConfig}
                locale={locale}
                labelBadges={descriptionLabelBadges}
              />
            </Field>
          </Row>
        )}
      </CardContentWrapper>
    </Base>
  )
}
