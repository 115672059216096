import { Optional } from 'packages/types'
import { useI18n } from './useI18n'

export const useTailoredDesignation = (
  jobTitle: Optional<string>,
  employerName: Optional<string>,
) => {
  const { i18n } = useI18n()
  const text = !employerName
    ? jobTitle
    : [jobTitle, employerName].join(` ${i18n.t('builder.resume_editor.labels.at')} `)
  return text || ''
}
