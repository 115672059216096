export enum ERDSteps {
  FORM_STEP_1 = 'FORM_STEP_1',
  FORM_STEP_2 = 'FORM_STEP_2',
}

export interface ResumeDistributionPersonalForm {
  firstName?: string | null
  lastName?: string | null
  jobTitle?: string | null
  fullAddress?: string | null
  email?: string | null
  linkedin?: string | null
  edit_file_name?: string | null
}
