import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

import Media from 'builder/styles/media'
import { TextField } from 'builder/components/TextField'
import { Icon20 } from 'builder/components/Icon'
import { FormState } from './EditJobDetails/types'

export const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  flex: 0 1 auto;
  overflow: hidden;
`
export const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  flex: 0 1 auto;
  overflow: hidden;
`

export const Jobtitle = styled(TextField)<{ isJobDetailsFocused: boolean }>`
  input {
    height: 24px;
    color: ${Colors.Neutral50};
    ${Typography.Caption}
    ${FontWeights.Regular}
    background-color: ${props =>
      props.isJobDetailsFocused === true ? Colors.White : Colors.Blue10};
  }
`

export const JoiningText = styled.p`
  ${Typography.Caption}
  ${FontWeights.Regular}
  color: ${Colors.Neutral50};
  margin: 0px 4px;
`

export const CompanyName = styled(TextField)<{ isJobDetailsFocused: boolean }>`
  input {
    height: 24px;
    color: ${Colors.Neutral50};
    ${Typography.Caption}
    ${FontWeights.Regular}
    background-color: ${props =>
      props.isJobDetailsFocused === true ? Colors.White : Colors.Blue10};
  }
`

export const HeaderText = styled.p`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
  margin-right: 5px;
  display: block;
  max-width: 180px;
  height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 2px 0px;
  flex: auto;

  ${Media.Phone`
    max-width: 160px;
  `};
`

export const IconWrapper = styled.div`
  color: ${Colors.Neutral30};
  cursor: pointer;
  user-select: none;
  position: relative;
  margin-right: 4px;

  ${Media.Hover`
    &:hover {
      color: ${Colors.Blue50};
    }
  `};

  & svg {
    display: block;
  }
`

export const RefreshDetailsIcon = styled(Icon20.Revert)<{ isRefresh: boolean }>`
  fill: ${props => (props.isRefresh === true ? Colors.Blue50 : Colors.Neutral30)};
  margin-left: 12px;
`

export const TickIcon = styled(Icon20.Tick)`
  fill: ${Colors.Neutral30};
  margin: 2px 0px 0px 8px;
  border-radius: 32px;
`

export const CloseIcon = styled(Icon20.StatusCanceled)`
  fill: ${Colors.Red50};
  margin: 2px 0px 0px 8px;
`

export const StatusText = styled.p<{ formState: FormState }>`
  ${Typography.Tiny}
  ${FontWeights.DemiBold}
  margin-left: 8px;
  color: ${props =>
    props.formState === FormState.SAVED
      ? Colors.Green50
      : props.formState === FormState.NOT_SAVED
      ? Colors.Red50
      : Colors.Blue50};
  margin-top: 4px;
`

export const StatusWrapper = styled.div<{ formState: FormState }>`
  display: flex;
  cursor: pointer;

  ${props =>
    props.formState === FormState.SAVED &&
    css`
      ${TickIcon} {
        background-color: ${Colors.Green50};
        fill: ${Colors.White};
      }
    `}

  ${props =>
    props.formState === FormState.SAVE &&
    css`
      ${TickIcon} {
        fill: ${Colors.Blue50};
      }
    `}
`
