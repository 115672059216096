import styled, { css } from 'styled-components'
import Tabs, { Tab } from 'builder/components/Tabs'
import Typography, { FontWeights } from 'builder/styles/typography'
import Button, { ButtonTheme } from 'builder/components/Button'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

export const Container = styled.div`
  margin: 57px 0;
`

export const TabContainer = styled.div``
export const MenuTabs = styled(Tabs)`
  border-bottom: none;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;

  ${Media.Tablet`
    padding: 0 20px;
  `}
`

export const NavTab = styled(Tab)`
  padding: 0;
  margin-right: 32px;
  ${Typography.M}
  ${FontWeights.Medium}
`

export const Label = styled.span`
  position: relative;
  padding: 2px 4px;
  border-radius: 4px;
  background: ${Colors.Blue50};
  color: ${Colors.White};
  left: 8px;
  top: -13px;

  ${Typography.Caption};
  ${FontWeights.Medium};
`

export const DocumentsContainer = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
  height: fit-content;

  ${Media.Tablet`
    padding: 0 20px;
  `}
`

export const JobTrackerShadow = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 40px;
  height: 64px;
  background: linear-gradient(0deg, #fff 24.09%, rgba(255, 255, 255, 0) 100%);
`

export const JobTrackerContainer = styled.div`
  margin-top: 16px;

  ${Media.Phone`
    padding: 0 20px;
  `}
`

export const JobTrackerContent = styled.div<{ withBanner?: boolean }>`
  margin-top: 16px;
  max-height: ${({ withBanner }) => (withBanner ? '590px' : '530px')};

  ${Media.Tablet`
    max-height: 540px;
  `}

  ${Media.Phone`
    max-height: 490px;
  `}
`

export const JobTrackerButton = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 40px;

  ${Media.Tablet`
    padding: 0 20px;
  `}

  ${Media.Phone`
    padding: 0;
  `}
`

export const ListExpand = styled(Button).attrs({ theme: ButtonTheme.default })`
  display: block;
  width: auto;
  margin: auto;
  padding: 16px 24px;
  color: ${Colors.Neutral50};
  background-color: ${Colors.Neutral5};
  border-radius: 12px;
  border: none;
  cursor: pointer;
  transition: color 0.15s;
  width: 100%;

  &:hover {
    color: ${Colors.Blue50};
    background-color: ${Colors.Blue10};
  }
`

export const JobSearchContainer = styled.div<{ $reverseRow: boolean }>`
  max-width: 1200px;
  display: flex;
  gap: 32px;
  margin: 40px auto;
  padding: 0 40px;

  ${Media.Tablet`
    margin: 20px 0;
    padding: 0 20px;
  `}

  ${({ $reverseRow }) =>
    $reverseRow &&
    css`
      flex-flow: row-reverse;
    `}
`

export const JobSearchBox = styled.div`
  flex: 1;
  max-width: 544px;
`
