import styled from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Container = styled.aside`
  width: 352px;
  height: 333px;
  position: relative;
  border-radius: 8px;
  flex-shrink: 0;
  background: #051f55;
  text-align: center;

  ${Media.Tablet`
    width: 100%;
    height: 220px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  `};

  ${Media.Phone`
    height: 174px;
    width: 100%;
    border-radius: 0px;
    position: relative;
  `}
`

export const SideContainer = styled.div`
  width: 50%;
  padding: 32px;

  ${Media.Phone`
    width:100%;
    padding: 24px 20px;
  `}
`

export const AvatarsSideContainer = styled.div`
  width: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  ${Media.Phone`
    width: auto;
    position: absolute;
  `}
`

export const Title = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};
  margin-left: auto;
  margin-right: auto;
  width: 320px;
  padding-top: 24px;
  color: ${Colors.White};

  ${Media.Tablet`
    padding-top: 0;
    text-align: left;
    margin-left: 0;
    margin-right: 0;
  `};
`

export const Gradient = styled.div`
  position: absolute;
  border-radius: 8px;
  top: 80%;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    to bottom,
    ${hexToRgba(Colors.Blue80, 0)} 0%,
    ${Colors.Blue80} 100%
  ); ;
`

export const Button = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  cursor: pointer;
  position: absolute;
  width: 171px;
  height: 48px;
  left: 91px;
  top: 261px;
  background: ${Colors.Blue50};
  border-radius: 4px;
  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${Colors.White};

  ${Media.Tablet`
    padding: 0;
    position: relative;
    width: 171px;
    height: 48px;
    left: 0;
    top: 24px;
  `};

  ${Media.Phone`
    top: 16px;
    width: 152px;
    height: 44px;
  `}
`

export const PopupPhoto = styled.img<{ isPerson1?: boolean }>`
  width: 98px;
  height: 96px;
  position: absolute;
  left: ${props => (props.isPerson1 ? 27.84 : 49.15)}%;
  right: ${props => (props.isPerson1 ? 44.32 : 23.01)}%;
  top: ${props => (props.isPerson1 ? 39.64 : 40.54)}%;
  bottom: ${props => (props.isPerson1 ? 31.53 : 31.23)}%;
  z-index: ${props => props.isPerson1 && 1};
`

export const DivisionImage = styled.img`
  position: relative;
  width: 330px;
  height: 20px;
  margin: 10px;

  ${Media.Tablet`
    display: none;
  `};

  ${Media.Phone`
    display: none;
  `}
`

export const PopupTabletPhoto = styled.img<{ isPerson1?: boolean }>`
  width: 109.33px;
  height: 106.89px;
  position: absolute;
  left: ${props => (props.isPerson1 ? 128 : 200)}px;
  right: ${props => (props.isPerson1 ? 44.32 : 23.01)}%;
  top: ${props => (props.isPerson1 ? 59.34 : 59.34)}px;
  bottom: ${props => (props.isPerson1 ? 31.53 : 31.23)}%;
  z-index: ${props => props.isPerson1 && 1};

  ${Media.Phone`
    height: 61.3px;
    width: 63.4px;  
  `}

  ${props =>
    props.isPerson1
      ? Media.Phone`
    left: 243px;
    top: 92px;
  `
      : Media.Phone`
    left: 285.55px;
    top: 93.92px;
  `}
`
