import styled, { css } from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Button from 'builder/components/Button'
import { Form } from 'builder/components/SimpleForm'

export const Container = styled.div<{ isNewDash?: boolean }>`
  width: 100%;
  max-width: 416px;
  height: auto;
  padding-bottom: 16px;

  ${Media.Phone`
    padding-bottom: 76px;
  `};

  ${({ isNewDash }) =>
    isNewDash &&
    css`
      ${Media.Phone`
        padding-bottom: 16px;
      `}
    `}
`

export const Title = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral90};
  text-align: center;
  margin-bottom: 12px;
`

export const Text = styled.div`
  ${Typography.Body};
  color: ${Colors.Neutral50};
  text-align: center;
  margin-bottom: 24px;
`

export const UploadContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  padding: 16px;
  flex-shrink: 0;
  border-radius: var(--2XS, 8px);
  border: 1px solid ${Colors.Neutral15};
`

export const LabelUpload = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
`

export const LabelWrapperUpload = styled.label`
  width: fit-content;
  position: relative;
`

export const UploadResumeName = styled.div`
  ${Typography.Body};
  color: ${Colors.Neutral90};
`

export const FileSize = styled.div`
  ${Typography.Body};
  color: ${Colors.Neutral50};
`

export const UploadButton = styled.label`
  display: flex;
  width: fit-content;
  min-width: 132px;
  height: 36px;
  padding: var(--2XS, 8px) var(--XS, 12px);
  position: relative;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--3XS, 4px);
  border: 1px solid ${Colors.Neutral20};
  transition: border 0.2s ease-in-out, color 0.2s ease-in-out;
  background-color: ${Colors.White};
  color: ${Colors.Neutral90};
  ${Typography.Body};
  ${FontWeights.Medium};

  &:hover {
    transition: border 0.2s ease-in-out, color 0.2s ease-in-out;
    border: 1px solid ${Colors.Blue50};
    background-color: ${Colors.White};
    color: ${Colors.Blue50};
  }
`

export const ResumeSelectionContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 15px;
  flex-shrink: 0;
  border-radius: var(--2XS, 8px);
  border: 1px solid ${Colors.Neutral15};

  ${Media.Phone`
    position: relative;
    height: 128px;  
    margin-bottom: 0;
  `};
`

export const SelectLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
  position: relative;
  max-width: 244px;
`

export const SelectResumeTitle = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral90};
  margin-bottom: 2px;
`

export const SelectResumeDate = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
  margin-bottom: 18px;
`

export const ChangeResumeButton = styled(Button)`
  display: flex;
  width: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 150px;
  height: 36px;
  padding: var(--2XS, 8px) var(--XS, 12px);
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--3XS, 4px);
  ${Typography.Body};
  ${FontWeights.Medium};
  border: 1px solid ${Colors.Neutral20};
  transition: border 0.2s ease-in-out, color 0.2s ease-in-out;
  background-color: ${Colors.White};
  color: ${Colors.Neutral90};

  &:hover {
    transition: border 0.2s ease-in-out, color 0.2s ease-in-out;
    border: 1px solid ${Colors.Blue50};
    background-color: ${Colors.White};
    color: ${Colors.Blue50};
  }
`
export const SelectRight = styled.div`
  display: flex;
  text-align: center;
  position: relative;
  gap: 12px;
  overflow: hidden;
  flex-shrink: 0;

  ${Media.Phone`
      position: absolute;
      right: 30px;
  `};
`

export const DocumentPreviewContainer = styled.div`
  flex-shrink: 0;
  width: 104px;
  max-height: 104px;

  ${Media.Phone`
    width: 104px;
    height: 100px;  
    margin-bottom: 0;
  `};
`

export const RightBottonShadow = styled.div`
  width: 200px;
  height: 25px;
  flex-shrink: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  position: absolute;
  top: 90px;
`

export const FormContainer = styled.div`
  margin-top: 16px;
  width: 100%;
`

export const SpinnerRight = styled.div`
  display: flex;
  position: absolute;
  justify-content: end;
  align-items: center;
  gap: 1px;
  top: 10px;
  right: 10px;
`

export const SpinnerRightBar = styled.div`
  display: flex;
  position: absolute;
  justify-content: end;
  align-items: center;
  gap: 1px;
  top: 0;
  right: 0;
`

export const PreFillingText = styled.div`
  ${Typography.Caption};
  margin-left: 2px;
  color: ${Colors.Neutral90};
`

export const PreFilledText = styled.div`
  ${Typography.Caption};
  color: ${Colors.Green50};
  margin-left: 2px;
`

export const LabelSpinner = styled.div`
  position: relative;
  margin-right: 10px;
`

export const SimpleFormContainer = styled(Form)<{ isNewDash?: boolean }>``

export const ResumePopupContainer = styled.div`
  position: absolute;
  top: -138px;
  right: 94px;
`

export const ResumeBuilderPopupContainer = styled.div`
  position: absolute;
  top: -68px;
  right: 190px;
`

export const EmailErrorContainer = styled.div`
  width: 90%;
`

export const ContactSupport = styled.a`
  ${Typography.Caption}
  ${FontWeights.Regular}

  color: ${Colors.Blue50};

  &:hover {
    border-bottom: 1px solid ${Colors.Blue50};
  }
`
