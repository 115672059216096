import * as React from 'react'
import * as Styled from './styles'

type ActionGroupProps = React.HTMLAttributes<HTMLButtonElement> & {
  icon?: React.ReactNode
}

export const ActionGroupButton = ({ children, icon, ...rest }: ActionGroupProps) => {
  return (
    <Styled.ButtonContainer {...rest}>
      {icon && <Styled.ButtonIcon>{icon}</Styled.ButtonIcon>}
      <Styled.ButtonText>{children}</Styled.ButtonText>
      <Styled.ButtonArrow />
    </Styled.ButtonContainer>
  )
}
