import styled from 'styled-components'
import { Icon24 } from 'builder/components/Icon'
import Colors from 'builder/styles/colors'
import { rotation } from 'builder/styles/keyframes'
import Typography from 'builder/styles/typography'

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const Spinner = styled(Icon24.Spinner)`
  color: ${Colors.Blue50};
  animation: ${rotation} 0.5s infinite linear;
`

export const Label = styled.div`
  ${Typography.Caption}
  color: ${Colors.Neutral90};
  margin-left: 8px;
`
