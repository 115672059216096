import styled, { css } from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import { FontWeights } from 'builder/styles/typography'

export const Wrapper = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${Media.Tablet`
    margin-top: 32px;
  `}

  ${Media.Phone`
    margin-top: 16px;
  `}
`

export const LevelContainer = styled.div`
  min-height: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

export const FirstLevelContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 48px;
`

export const OptionId = styled.div`
  ${FontWeights.Medium};
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.Neutral40};

  &::after {
    content: '.';
  }
`

export const OptionText = styled.div<{ isSelected: boolean }>`
  ${FontWeights.Regular};
  color: ${Colors.Neutral100};
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    color: ${Colors.Blue50};
  }

  ${props => Media.Phone`
    ${props.isSelected ? FontWeights.DemiBold : FontWeights.Medium};
  `}
`

export const SubLevelContainer = styled.div`
  display: flex;
  align-items: center;
  padding-inline-start: 24px;
  cursor: pointer;
`

export const SubLevelText = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  color: ${props => (props.isSelected ? Colors.Blue50 : Colors.Neutral50)};
  margin-bottom: 16px;

  &:hover {
    color: ${Colors.Blue50};
  }

  ${Media.Phone`
    margin-bottom: 20px;
  `}
`

export const HorizontalLine = styled.div<{ isLastIndex: boolean }>`
  width: 100%;
  height: 1px;
  background: ${Colors.Neutral15};
  position: relative;
  left: 24px;

  ${props =>
    props.isLastIndex &&
    css`
      display: none;
    `}
`
