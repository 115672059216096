import { useForm } from 'react-hook-form'
import { useI18n } from 'builder/hooks/useI18n'
import { TextField } from 'builder/components/TextField'
import * as SimpleForm from 'builder/components/SimpleForm'

type Props = Omit<React.ComponentProps<typeof SimpleForm.Form>, 'onSubmit'> & {
  firstName: string
  lastName: string
  signUpFlow?: string
  onSubmit: (payload: { firstName: string; lastName: string }) => void
  disabledI18n?: boolean
}

export const NameForm = ({
  firstName,
  lastName,
  onSubmit,
  signUpFlow,
  disabledI18n,
  ...rest
}: Props) => {
  const { i18n } = useI18n()

  const form = useForm({
    mode: 'onChange',
    defaultValues: { firstName, lastName },
  })

  const { errors, isValid } = form.formState

  return (
    <SimpleForm.Form
      {...rest}
      onSubmit={form.handleSubmit(onSubmit)}
      isDisabled={!isValid}
      signUpFlow={signUpFlow}
      disabledI18n={disabledI18n}
    >
      <SimpleForm.Row>
        <TextField
          {...form.register('firstName', {
            required: disabledI18n
              ? 'This field is required'
              : i18n.t('builder.sign_up.field_is_required'),
          })}
          label={disabledI18n ? 'First Name' : i18n.t('builder.sign_up.first_name')}
          placeholder="John"
          error={errors.firstName?.message}
          autoFocus
        />
      </SimpleForm.Row>

      <SimpleForm.Row>
        <TextField
          {...form.register('lastName', {
            required: disabledI18n
              ? 'This field is required'
              : i18n.t('builder.sign_up.field_is_required'),
          })}
          label={disabledI18n ? 'Last Name' : i18n.t('builder.sign_up.last_name')}
          placeholder="Appleseed"
          error={errors.lastName?.message}
        />
      </SimpleForm.Row>
    </SimpleForm.Form>
  )
}
