import { ReactNode } from 'react'

import { Icon20 } from 'builder/components/Icon'
import Colors from 'builder/styles/colors'
import { useAutoApply } from 'builder/views/AutoApply/hooks/useAutoApply'
import ActionMenu, { Action } from 'builder/components/ActionMenu'
import { useI18n } from 'builder/hooks/useI18n'
import { ColumnName, Job } from 'builder/modules/jobTracking'
import { useIsStatusChangeAllowed } from '../hooks/useIsStatusChangeAllowed'
import { StatusContainer, StatusItem, StatusLabel } from './styles'
interface ColumnStyle {
  icon: ReactNode
  color: string
}

export const statusesStyles: {
  [key in ColumnName]: ColumnStyle
} = {
  [ColumnName.recommended]: {
    icon: <Icon20.Near />,
    color: Colors.Neutral10,
  },
  [ColumnName.wishlist]: {
    icon: <Icon20.Like />,
    color: Colors.Blue10,
  },
  [ColumnName.auto_apply]: {
    icon: <Icon20.Featured />,
    color: Colors.Indigo10,
  },
  [ColumnName.applied]: {
    icon: <Icon20.Job />,
    color: Colors.Beige10,
  },
  [ColumnName.interview]: {
    icon: <Icon20.InterviewPrep />,
    color: Colors.Indigo10,
  },
  [ColumnName.offer]: {
    icon: <Icon20.Complitness />,
    color: Colors.Green10,
  },
  [ColumnName.rejected]: {
    icon: <Icon20.RejectIcon />,
    color: Colors.Indigo10,
  },
  [ColumnName.deleted]: {
    icon: <Icon20.Delete />,
    color: Colors.Red10,
  },
}
interface IProps {
  columnNames: ColumnName[]
  editJob: (job: Job) => void
  onDelete?: (id: number) => void
  selectedJob: Job
  drawer?: boolean
  closeSelect: () => void
}

export const StatusSelect = ({
  drawer = true,
  columnNames,
  onDelete = () => {},
  selectedJob,
  editJob,
  closeSelect,
}: IProps) => {
  const { i18n } = useI18n()
  const {
    isAutoApplyFeatureEnabled,
    redirectToBillingPlans,
    redirectToAutoApplyQuestionnaire,
    isAutoApplyLimitReached,
  } = useAutoApply()

  const { isStatusChangeAllowed } = useIsStatusChangeAllowed(selectedJob)
  const columnEnabled = columnNames
    .filter(name => name !== ColumnName.recommended)
    .filter(el => (isAutoApplyFeatureEnabled ? true : el !== ColumnName.auto_apply))
    .filter(el => isStatusChangeAllowed(el))

  const editCardStatus = (updatedJob: Job) => {
    if (updatedJob.status === ColumnName.auto_apply) {
      if (redirectToBillingPlans()) return
      if (redirectToAutoApplyQuestionnaire()) return
      if (isAutoApplyLimitReached()) return
    }
    editJob(updatedJob)
  }

  const getStatusLabel = (status: ColumnName) => {
    return status === ColumnName.deleted
      ? i18n.t('builder.job_tracking.delete')
      : i18n.t(`builder.job_tracking.columns.${status}`)
  }

  if (!drawer) {
    const actions: Action[] = columnEnabled.map(name => {
      const { icon } = statusesStyles[name]

      const onClick =
        name === ColumnName.deleted
          ? () => onDelete(selectedJob.id)
          : () => editCardStatus({ ...selectedJob, status: name })
      return {
        icon,
        text: getStatusLabel(name),
        onClick,
        rawIcon: true,
      }
    })
    return <ActionMenu position="bottom" onClose={closeSelect} actions={actions} />
  }

  return (
    <StatusContainer drawer={drawer}>
      {columnEnabled.map(status => {
        const { icon, color } = statusesStyles[status]

        const label = getStatusLabel(status)
        const onClick =
          status === ColumnName.deleted
            ? () => onDelete(selectedJob.id)
            : () => editCardStatus({ ...selectedJob, status })
        return (
          <StatusItem onClick={onClick} key={status} activeColor={color}>
            <StatusLabel>
              {icon}
              {label}
            </StatusLabel>
            {selectedJob.status === status ? <Icon20.Tick /> : null}
          </StatusItem>
        )
      })}
    </StatusContainer>
  )
}
