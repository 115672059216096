import { i18n as I18n } from 'builder/utils/i18n'
import { trackMarketingEvent, trackInternalEvent } from '@rio/tracking'

const openPopup = src => {
  const width = 600
  const height = 500
  const left = Math.round(window.screen.width / 2 - width / 2)
  const top = Math.round(window.screen.height / 3 - height / 2)

  const params = `
    left=${left},
    top=${top},
    width=${width},
    height=${height},
    personalbar=0,
    toolbar=0,
    scrollbars=1,
    resizable=1
  `

  const popup = window.open(src, window.name, params)

  if (popup) {
    popup.focus()
    return popup
  }

  window.location.href = src
  return null
}

export const share = (url, network) => {
  const encodedUrl = encodeURIComponent(url)

  const twitterText = I18n.t(`builder.resume_editor.share_modal.twitter_text`)
  const encodedTwitterText = encodeURIComponent(twitterText)

  const popupUrls = {
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`,
    linkedin: `https://www.linkedin.com/shareArticle?url=${encodedUrl}`,
    twitter: `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTwitterText}`,
  }

  openPopup(popupUrls[network])

  const eventNames = {
    facebook: 'Open Facebook Dialog',
    linkedin: 'Open LinkedIn Dialog',
    twitter: 'Open Twitter Dialog',
  }

  trackMarketingEvent('Sharing Modal', eventNames[network])
  trackInternalEvent('share_sharing_link', { network })
}
