import * as React from 'react'
import { ComponentPropsWithoutRef } from 'react'
import * as Styled from './styles'

type ContainerProps = {
  children?: React.ReactNode
}

export const Container = ({
  children,
  ...props
}: ContainerProps & ComponentPropsWithoutRef<'div'>) => (
  <Styled.Wrapper {...props}>{children}</Styled.Wrapper>
)
