import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Icon from 'builder/components/Icon'
import Button from 'builder/components/Button'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  ${Media.Tablet`
    gap: 72px;
    justify-content: flex-start;
  `}

  ${Media.Phone`
    gap: 12px;
    justify-content: flex-start;
  `}
`

export const ArrowContainer = styled.button`
  width: 80px;
  height: 32px;
  border: none;
  display: flex;
  flex-shrink: 0;
  cursor: pointer;
  align-items: center;
  border-radius: 20px;
  justify-content: flex-start;
  background: ${Colors.Neutral10};
  padding: 4px 16px 4px 8px;

  & svg {
    min-width: 24px;
    color: ${Colors.Neutral50};
  }

  &:hover {
    background: ${Colors.Blue10};
  }

  ${Media.Phone`
    width: 40px;
  `}
`

export const LeftArrow = styled(Icon.Chevron)`
  transform: rotate(0.5turn);

  ${ArrowContainer}:hover & {
    color: ${Colors.Blue50};
  }
`

export const Text = styled.span`
  ${Typography.Caption};
  ${FontWeights.DemiBold};

  color: ${Colors.Neutral50};

  ${ArrowContainer}:hover & {
    color: ${Colors.Blue50};
  }

  ${Media.Phone`
    visibility: hidden;
  `}
`

export const HeaderContent = styled.div`
  width: 100%;
  gap: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Heading = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};
`

export const SubHeading = styled.div`
  max-width: 682px;
  text-align: center;
  color: ${Colors.Neutral50};
`

export const StyledButton = styled(Button)<{ $isVisible: boolean }>`
  align-self: flex-start;
  visibility: ${({ $isVisible }) => (!$isVisible ? 'hidden' : 'visible')};

  ${Media.Tablet`
    position: fixed;
    right: 40px;
    bottom: 80px;
    z-index: 1;
  `}

  ${Media.Phone`
    width: calc(100% - 40px);
    position: fixed;
    bottom: 60px;
    left: 19px;
    z-index: 1;
  `}
`
