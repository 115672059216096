import { FC, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { SectionNames, selectors, actions } from 'builder/modules/resumeEditor'
import { SectionTitleBadgeProps } from 'builder/components/SectionTitle'
import { AiKeywordsToggler } from '../AiKeywordsToggler'
import { useKeywords } from '../../hooks/useKeywords'

export const WorkExperienceKeywordsToggler: FC<SectionTitleBadgeProps> = props => {
  const { isKeywordsAvailable } = useKeywords()
  const workExperiences = useTypedSelector(selectors.resume)?.workExperiences
  const openCardId = useTypedSelector(selectors.openCardId)
  const dispatch = useDispatch()

  const onClick = useCallback(() => {
    if (!workExperiences || !workExperiences.length) {
      dispatch(
        actions.addCard({
          sectionName: SectionNames.workExperiences,
          options: { shouldOpen: true },
        }),
      )
      return
    }

    const isWorkExperienceCardOpened =
      !!openCardId &&
      workExperiences.some(item => item.id === openCardId || item.cid === openCardId)

    if (!isWorkExperienceCardOpened) {
      dispatch(actions.openCard(workExperiences[0].id))
    }
  }, [workExperiences, openCardId, dispatch])

  return isKeywordsAvailable ? (
    <AiKeywordsToggler section={SectionNames.workExperiences} onClick={onClick} {...props} />
  ) : null
}
