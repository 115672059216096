import { memo, useCallback, RefObject } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { ValidInput, fromNow } from 'builder/utils/formatDate'
import { DocumentTypes } from 'builder/modules/constants'
import { CurrentDocument, MODES, PreviewRefProps } from '../../types'
import PaginationLayout from '../PaginationLayout'
import { SyncStatus } from '../../SyncStatus'
import { BottomBar, SyncStatusWrapper } from './styles'

type Props = {
  type: DocumentTypes
  currentDocument: CurrentDocument
  currentPage: number
  totalPagesNumber: number
  mode: MODES
  documentId: number
  previewRef: RefObject<PreviewRefProps | null>
}

export const BottomBarLayout = memo((props: Props) => {
  const { type, currentDocument, currentPage, totalPagesNumber, mode, documentId, previewRef } =
    props

  const updatedAt = currentDocument
    ? fromNow(currentDocument.updatedAt as ValidInput, { addSuffix: true })
    : ''

  const hasPreviousPage = currentPage > 1
  const hasNextPage = currentPage < totalPagesNumber

  const handlePreviousPageClick = useCallback(() => {
    if (!previewRef.current) return
    previewRef.current.previousPage()
    trackInternalEvent(`change_${type}_preview_page`)
  }, [previewRef, type])

  const handleNextPageClick = useCallback(() => {
    if (!previewRef.current) return
    previewRef.current.nextPage()
    trackInternalEvent(`change_${type}_preview_page`)
  }, [previewRef, type])

  return (
    <BottomBar>
      <SyncStatusWrapper>
        <SyncStatus
          {...{
            updatedAt,
            mode,
            documentId,
          }}
        />
      </SyncStatusWrapper>

      <PaginationLayout
        {...{
          hasPreviousPage,
          hasNextPage,
          currentPage,
          totalPagesNumber,
          handlePreviousPageClick,
          handleNextPageClick,
        }}
      />
    </BottomBar>
  )
})
