import { memo, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { i18n as I18n } from 'builder/utils/i18n'
import { actions } from 'builder/modules/resumeEditor'
import { useAppSelector } from 'builder/hooks/useAppSelector'
import {
  ResumeUploadStep,
  PrefillResumeEvents,
  ResumeValidationStatus,
} from 'builder/modules/constants'
import UploadResumeIllustration from '../../../../../../app/assets/images/builder/fill-resume/upload-illustration.png'
import { RESUME_MAX_SIZE, IMAGE_FILE_REGEX } from '../constants'
import { UploadingTitle, UploadIllustration, UploadIllustrationWrapper } from '../styles'

const FileUploadProgress = () => {
  const selectedResume = useAppSelector(
    state => state.resumeEditor.prefillResumeSection.selectedResume,
  )

  const dispatch = useDispatch()

  const validateResume = useCallback(() => {
    if (selectedResume) {
      if (selectedResume.size > RESUME_MAX_SIZE) {
        dispatch(actions.setResumeValidationStatus(ResumeValidationStatus.failed))
        dispatch(
          actions.setResumeValidationError({
            error:
              'Your file should be smaller than 10MB in size! Hint: using a different file format or removing images may help',
            errorSource: ResumeUploadStep.validation,
          }),
        )
      } else if (IMAGE_FILE_REGEX.test(selectedResume.type)) {
        dispatch(actions.setResumeValidationStatus(ResumeValidationStatus.failed))
        dispatch(
          actions.setResumeValidationError({
            // eslint-disable-next-line max-len
            error: `This file type isn't supported. Please make sure your resume is saved in DOC, DOCX or PDF formats (less than 10MB in size).`,
            errorSource: ResumeUploadStep.validation,
          }),
        )
      } else {
        trackInternalEvent(PrefillResumeEvents.completeResumeUpload)
        dispatch(actions.setResumeValidationStatus(ResumeValidationStatus.validated))
        // resets validation error if any
        dispatch(
          actions.setResumeValidationError({
            error: null,
            errorSource: null,
          }),
        )
      }
    }
  }, [dispatch, selectedResume])

  useEffect(() => {
    setTimeout(() => {
      validateResume()
    }, 2000) // delay so that upload animation is visible
  }, [validateResume])

  return (
    <>
      <UploadingTitle>{I18n.t('builder.upload_resume.uploading')}</UploadingTitle>
      <UploadIllustrationWrapper>
        <UploadIllustration src={UploadResumeIllustration} />
      </UploadIllustrationWrapper>
    </>
  )
}

export default memo(FileUploadProgress)
