import { memo } from 'react'
import { avatarUrl } from 'builder/modules/careerCoaching/constants'
import { Header, Name, Avatar, Details, Info, TopContent } from './styles'
import CoachExpertise from './components/CoachExpertise'
import CoachLocation from './components/CoachLocation'

interface Props {
  firstName: string
  lastName: string
  location: string
  coachAvatar: string
  careerStages: string[]
}

const PersonalDetails = ({ firstName, lastName, location, coachAvatar, careerStages }: Props) => {
  return (
    <Header>
      <TopContent>
        <Details>
          <Avatar src={coachAvatar || avatarUrl} />
          <Info>
            <Name>{firstName + ' ' + lastName}</Name>
            <CoachExpertise {...{ careerStages }} />
            <CoachLocation {...{ location }} />
          </Info>
        </Details>
      </TopContent>
    </Header>
  )
}

export default memo(PersonalDetails)
