import { useState, useRef, useLayoutEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { Icon24 } from 'builder/components/Icon'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { i18n as I18n } from 'builder/utils/i18n'
import { UseExampleEventLabels, UseExampleEvents } from 'builder/modules/constants'
import { useResumeExampleContext } from '../ResumeExampleContext'
import { CategoryContainer, CategoryPill, CategoryToggle, Chevron, Div } from '../styles'

type Props = {
  hide?: boolean
}

const Categories = ({ hide = false }: Props) => {
  const { categories, selectedCategory, setSelectedCategory } = useResumeExampleContext()
  const [maxCategoriesUnexpanded, setMaxCategoriesUnexpanded] = useState<number>(categories.length)
  const [expanded, setExpanded] = useState<boolean>(false)
  const [categoryContainerHeight, setCategoryContainerHeight] = useState<number>(0)
  const [hideToggleButton, setHideToogleButton] = useState<boolean>(false)

  const containerRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    const categoryPills = containerRef.current?.children
    let availableWidth = 600 // width of desktop category container minus width of expand toggle btn
    let i = 0
    let nextPillWidth = (categoryPills?.[0] as HTMLElement).offsetWidth + 4 // add 4 for margin

    while (availableWidth > nextPillWidth && i < (categoryPills?.length as number) - 1) {
      availableWidth = availableWidth - nextPillWidth
      nextPillWidth = (categoryPills?.[++i] as HTMLElement).offsetWidth + 4
    }
    if (containerRef?.current) {
      setCategoryContainerHeight(containerRef.current.scrollHeight)
    }

    if (categories.length === i - 1) {
      setHideToogleButton(true)
    }
    setMaxCategoriesUnexpanded(i - 1)
  }, [categories.length])

  const { isPhone } = useMediaQueries()

  return (
    <CategoryContainer
      ref={containerRef}
      expanded={expanded}
      expandedHeight={categoryContainerHeight}
      hide={hide}
    >
      <CategoryPill selected={!selectedCategory} onClick={() => setSelectedCategory('')}>
        {I18n.t('builder.use_example.categories.all')}
      </CategoryPill>
      {categories.map((cat, i) => {
        if (isPhone || expanded || i < maxCategoriesUnexpanded) {
          return (
            <CategoryPill
              key={cat}
              selected={cat === selectedCategory}
              onClick={() => {
                trackInternalEvent(UseExampleEvents.clickCategory, {
                  label: UseExampleEventLabels.examplesPrefill,
                  category: cat,
                })
                setSelectedCategory(cat)
              }}
            >
              {cat}
            </CategoryPill>
          )
        }

        return null
      })}
      <CategoryToggle onClick={() => setExpanded(expanded => !expanded)} hidden={hideToggleButton}>
        <Div>
          {expanded
            ? I18n.t('builder.use_example.categories.see_less')
            : I18n.t('builder.use_example.categories.see_more')}
        </Div>
        <Chevron expanded={expanded}>
          <Icon24.Chevron />
        </Chevron>
      </CategoryToggle>
    </CategoryContainer>
  )
}

export default Categories
