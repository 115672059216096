import { ReactNode } from 'react'
import { CustomDrawer, DrawerContainer } from './styles'
interface IProps {
  fromDashboard?: boolean
  children?: ReactNode
}

export const CardDrawer = ({ fromDashboard = false, children }: IProps) => {
  return (
    <DrawerContainer>
      <CustomDrawer fromDashboard={fromDashboard}>{children}</CustomDrawer>
    </DrawerContainer>
  )
}
