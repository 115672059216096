export const stockListingOptions = [
  { id: 'Yes', name: 'Yes' },
  { id: 'No', name: 'No' },
]

export const employmentTypeOptions = [
  { id: 'Permanent Employee', name: '正社員' },
  { id: 'Contract Employee', name: '契約社員' },
  { id: 'Part-time', name: 'アルバイト' },
  { id: 'Internship', name: 'インターンシップ' },
  { id: 'Temporary Worker', name: '派遣社員' },
]

export const numberOfEmployeesOptions = [
  { id: '1-50', name: '1-50' },
  { id: '51-100', name: '51-100' },
  { id: '101-500', name: '101-500' },
  { id: '501-1000', name: '501-1000' },
  { id: '1001+', name: '1001+' },
]
