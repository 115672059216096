import styled from 'styled-components'
import Media from 'builder/styles/media'
import Button from 'builder/components/Button'
import { Colors, hexToRgba } from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Icon20 } from 'builder/components/Icon'

export const CritiqueContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 304px;
  background: ${Colors.Neutral5};

  ${Media.Phone`
    height: 465px;
  `};
`
export const WelcomeContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
  height: 304px;
  background: ${Colors.Neutral5};

  ${Media.Phone`
    height: 340px;
  `};
`

export const CritiqueTitle = styled.div`
  position: absolute;
  height: 36px;
  top: 56px;
  color: ${Colors.Neutral90};
  ${Typography.M};
  ${FontWeights.DemiBold};

  ${Media.Tablet`
    left: 20px;
    top: 56px;
  `};

  ${Media.Phone`
    ${Typography.M};
    ${FontWeights.DemiBold};
    top: 210px;
    width: 100%;
    padding-left:10px;
    margin: 0px;
    left: 50%;
    transform: translate(-50%);
    padding-left:25px;
  `};
`
export const CritiqueText = styled.div`
  position: absolute;
  width: 352px;
  height: 72px;
  top: 104px;
  ${Typography.Body};
  ${FontWeights.Regular};

  ${Media.Tablet`
    left: 20px;
  `};

  ${Media.Phone`
    ${Typography.Caption};
    top: 238px;
    width: 100%;
    height: 40px;
    padding:10px;
    left: 50%;
    transform: translate(-50%);
    padding-left:25px;
  `};

  @media (max-width: 280px) {
    top: 258px;
  }
`

export const ViewMyResumeButton = styled(Button)`
  gap: 10px;
  height: 48px;
  top: 200px;
  width: 174px;
  border-radius: 4px;
  background: ${Colors.Blue50};
  margin: 10px;

  ${Media.Tablet`
    top: 200px;
  `};

  ${Media.Phone`
    position: absolute;
    width: 90%;
    top: 328px;
    border-radius: 4px;
    left: 5%;
    right: 5%;
    margin:0px;
  `}
`

export const NotNowButton = styled(Button)`
  gap: 10px;
  top: 200px;
  width: 114px;
  border: solid ${Colors.Neutral10};
  border-radius: 4px;
  color: ${Colors.Neutral90};
  background: ${Colors.Neutral5};
  &:hover {
    background: ${Colors.Neutral5};
  }

  ${Media.Tablet`
    top: 200px;
  `};

  ${Media.Phone`
    position: absolute;
    width: 90%;
    top: 384px;
    border-radius: 4px;
    left: 5%;
    right: 5%;
  `}
`

export const BannerLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  margin-right: 32px;

  ${Media.Phone`
    margin-right: 0;
    margin-bottom: 20px;
  `};
`

export const BannerRightContent = styled.div`
  display: flex;
  align-items: center;

  ${Media.Tablet`
    width: auto;
  `};

  ${Media.Phone`
    width: 100%;
  `};
`

export const ButtonContainer = styled.div`
  float: left;
`

export const CritiqueCardContainer = styled.div`
  box-sizing: border-box;
  width: 293px;
  margin-right: 72px;
  height: 293px;

  ${Media.Tablet`
    height: 273px;
    position:absolute;
    right: 56px;
    margin-right:12px;
  `}

  ${Media.Phone`
    position:absolute;
    width: 226.33px;
    height: 144.33px;
    top: 21.44px;
    margin: 0;
    left: 50%;
    transform: translate(-50%);
  `}
`

export const PopupPhoto = styled.img`
  position: absolute;
  width: 56px;
  height: 56px;
  flex-shrink: 0;
  border-radius: 50%;
  margin-top: 30px;
  margin-left: 5px;

  ${Media.Tablet`
  margin-left: 8px;
  `}

  ${Media.Phone`
  width: 40.7px;
  height: 40.7px;
  margin-top: -5px;
  margin-left: 14px;
  `}
`

export const PopupIconWrapper = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 50%;
  margin-top: 60px;
  margin-left: 40px;
  color: ${Colors.White};
  box-shadow: 0 0 0 4px ${Colors.Neutral5};
  background-color: ${Colors.Indigo80};
  border-radius: 50%;

  ${Media.Phone`
  width: 20.3px;
  height: 20.3px;
  margin-top: 17px;
  `}
`

export const PopupIcon = styled(Icon20.Star)`
  width: 24px;
  height: 24px;

  ${Media.Phone`
  width: 20.3px;
  height: 20.3px;
  `}
`

export const CritiqueResumeContainer = styled.iframe.attrs({ frameBorder: 0 })`
  width: 278px;
  height: 196px;
  margin-top: 100px;
  overflow: hidden;
  margin-left: 5px;

  ${Media.Tablet`
    height: 187px;
  `}

  ${Media.Phone`
    width: 205px;
    height: 144.53px;
    margin-top: 46.43px;
    margin-left: 15px;
    border-radius: 0px;
  `}
`

export const CritiqueResumeGradient = styled.div`
  width: 293px;
  height: 101.2px;
  position: absolute;
  top: 204px;
  margin-left: -10px;
  background: linear-gradient(
    to bottom,
    ${hexToRgba(Colors.Neutral5, 0)},
    ${Colors.Neutral5} 100%,
    ${Colors.Neutral5} 0%
  );

  ${Media.Phone`
    top: 128px;
    height: 81.2px;
    width: 206.33px;
    margin: 0;
    left: 52%;
    transform: translate(-50%);
  `}

  ${Media.Tablet`
  margin-top: -18px;
  `};
`

export const Title = styled.div`
  position: absolute;
  height: 24px;
  margin-top: 40px;
  margin-left: 76px;
  ${Typography.Body};
  ${FontWeights.DemiBold};
  display: flex;
  align-items: center;
  color: ${Colors.Neutral80};

  ${Media.Phone`
    margin-top: 0px;
    margin-left: 68px;
    ${Typography.Tiny};
    ${FontWeights.DemiBold};
    margin-bottom: 4px;
    height: 18px;
    `};
`

export const Text = styled.div`
  position: absolute;
  height: 16px;
  margin-top: 62px;
  margin-left: 76px;
  ${Typography.Tiny};
  ${FontWeights.Regular};
  display: flex;
  align-items: center;
  color: ${Colors.Neutral50};

  ${Media.Phone`
    margin-top: 18px;
    margin-left: 68px;
    font-size: 10.0172px;
    line-height: 11px;
    margin-bottom: 4px;
    height: 12px;
  `};
`
