import { useQuery } from '@tanstack/react-query'
import { baseClient } from 'builder/modules/apiClient'
import { RecruiterData } from 'builder/modules/resumeDistribution'

export const useGetRecruitersList = () => {
  return useQuery(['useGetRecruitersList'], async (): Promise<RecruiterData> => {
    const url = '/resume-distribution/candidates/recruiters'
    const { data } = await baseClient.get<RecruiterData>(url)
    return data
  })
}
