import { useCallback, useState } from 'react'
import { selectors } from 'builder/modules/resumeEditor'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useTailoredDesignation } from 'builder/hooks/useTailoredDesignation'
import { FetchStatuses } from 'builder/modules/constants'

export const useTailoredResumeEdit = () => {
  const currentResume = useTypedSelector(selectors.resume)
  const originalResume = useTypedSelector(selectors.originalResume)
  const approveAutoTailoredResumeStatus = useTypedSelector(
    selectors.approveAutoTailoredResumeStatus,
  )
  const {
    averageScore: newScore,
    recommendedJobTitle,
    employerName,
  } = useTypedSelector(selectors.jobPostingAPIData)
  const oldScore = currentResume?.originalResumeAvgScore || 0
  const [isEditActive, setIsEditActive] = useState(false)
  const tailoredDesignation = useTailoredDesignation(recommendedJobTitle, employerName)
  const isApproving = approveAutoTailoredResumeStatus === FetchStatuses.loading

  const toggleEditContainer = useCallback(() => {
    setIsEditActive(!isEditActive)
  }, [isEditActive])

  return {
    oldScore,
    newScore,
    tailoredDesignation,
    toggleEditContainer,
    isEditActive,
    currentResume,
    originalResume,
    isApproving,
  }
}
