import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Icon20 } from 'builder/components/Icon'

export const Container = styled.div`
  margin: 16px;
  display: flex;
  gap: 8px;
`
export const Box = styled.div<{ $hoverColor?: string }>`
  flex: 1;
  background: ${Colors.Neutral5};
  border-radius: var(--Spacing-XS, 12px);
  padding: 40px 0;

  &:hover {
    background: ${({ $hoverColor }) => $hoverColor || Colors.Neutral5};
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`

export const HeaderContainer = styled.div``
export const HeaderText = styled.p`
  ${Typography.S}
  ${FontWeights.Regular}

  color: ${Colors.Neutral90};
`
export const HeaderCaption = styled.span`
  ${Typography.Caption}
  ${FontWeights.Regular}

  color: ${Colors.Neutral90};
`

export const SubHeaderContainer = styled.div`
  display: flex;
  gap: 2px;
`

export const SubHeader = styled.p<{ $color?: string }>`
  ${Typography.Caption}
  ${FontWeights.Medium}

  color: ${({ $color }) => $color || Colors.Blue50};
`
export const SubHeaderIconApplied = styled(Icon20.Complitness)`
  color: ${Colors.Blue50};
`
export const SubHeaderIconProcessing = styled(Icon20.Processing)`
  color: ${Colors.Indigo50};
`
export const SubHeaderIconError = styled(Icon20.Stop)`
  color: ${Colors.Red50};
`
