import styled, { css } from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

enum CareerPlanningType {
  Do = 'Do',
  Learn = 'Learn',
  Reference = 'Reference',
}

const getTextColor = (type: string) => {
  switch (type) {
    case CareerPlanningType.Do:
      return Colors.Blue50
    case CareerPlanningType.Learn:
      return Colors.Green50
    case CareerPlanningType.Reference:
      return Colors.Beige50
  }
}

const getBackgroundColor = (type: string) => {
  switch (type) {
    case CareerPlanningType.Do:
      return Colors.Blue10
    case CareerPlanningType.Learn:
      return Colors.Green10
    case CareerPlanningType.Reference:
      return Colors.Beige10
  }
}

export const Heading = styled.div`
  gap: 12px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`

export const Type = styled.div<{ type: string }>`
  ${FontWeights.Medium};
  padding: 4px 12px;
  border-radius: 1000px;
  color: ${props => getTextColor(props.type)};
  background-color: ${props => getBackgroundColor(props.type)};

  ${Media.Phone`
    ${Typography.Caption};
    align-self: flex-start
  `}
`

export const Text = styled.div<{ isCompleted: boolean }>`
  ${Typography.Subhead};
  ${FontWeights.Medium};

  ${props =>
    props.isCompleted &&
    css`
      color: ${Colors.Neutral30};
      text-decoration: line-through;
      line-height: 28px;
    `};
`
