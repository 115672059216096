import styled from 'styled-components'
import Icon24 from 'builder/components/Icon'
import Colors from 'builder/styles/colors'
import { rotation } from 'builder/styles/keyframes'
import Shadows from 'builder/styles/shadows'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
  position: relative;
`

export const Spinner = styled(Icon24.Spinner)`
  width: 64px;
  height: 64px;
  color: ${Colors.Blue50};
  animation: ${rotation} 0.5s infinite linear;
  ${Shadows.lightWithBorder.mid};
  border-radius: 32px;
`

export const SpinnerContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 32px;
`

export const LoadingText = styled.div`
  width: 264px;
  height: 28px;
  text-align: center;
  ${Typography.Subhead};
  ${FontWeights.Regular};
`
