import { AsyncAutosuggest, createSuggestionsApiFetcher } from 'builder/components/AsyncAutosuggest'
import EditorField from 'builder/components/EditorField'
import { TextField } from 'builder/components/TextField'
import { useI18n } from 'builder/hooks/useI18n'
import { actions as editorActions } from 'builder/modules/resumeEditor'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

export function Country(props) {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const { countryName, locale } = props

  const handleSimpleFieldUpdate = React.useCallback(
    event => {
      const { name, value } = event.target
      dispatch(editorActions.updateSimpleField({ name, value, debounce: true }))
    },
    [dispatch],
  )

  return (
    <>
      {/* Country */}
      <EditorField>
        <AsyncAutosuggest
          highlightedQuery
          name="countryName"
          fetchItems={createSuggestionsApiFetcher('country', { locale })}
          value={countryName || ''}
          onChange={handleSimpleFieldUpdate}
        >
          {inputProps => (
            <TextField {...inputProps} label={i18n.t('builder.resume_editor.country')} />
          )}
        </AsyncAutosuggest>
      </EditorField>
    </>
  )
}
Country.propTypes = {
  countryName: PropTypes.string,
  locale: PropTypes.string,
}
