import { JobSummary } from './Sections/JobSummary/JobSummary'
import { ExperienceKnowledgeAndSkills } from './Sections/ExperienceKnowledgeAndSkills/ExperienceKnowledgeAndSkills'
import { TechnicalSkills } from './Sections/TechnicalSkills/TechnicalSkills'
import { Qualification } from './Sections/Qualifications/Qualifications'
import { SelfPR } from './Sections/SelfPR/SelfPR'
import { Motivation } from './Sections/Motivation/Motivation'
import { LanguageSkills } from './Sections/LanguageSkills/LanguageSkills'
import { WorkExperience } from './Sections/WorkExperience/WorkExperience'

export const ShokumukeirekishoSectionsList = (): JSX.Element => {
  return (
    <>
      <JobSummary />
      <ExperienceKnowledgeAndSkills />
      <WorkExperience />
      <TechnicalSkills />
      <Qualification />
      <SelfPR />
      <LanguageSkills />
      <Motivation />
    </>
  )
}

export default ShokumukeirekishoSectionsList
