import styled, { css } from 'styled-components'
import { HorizontalTransitionFade } from 'builder/components/HorizontalTransitionFade'
import Typography, { FontWeights } from 'builder/styles/typography'

const flexColumn = css`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`

export const Container = styled(HorizontalTransitionFade)`
  ${flexColumn};
  width: 100%;
  height: 100%;
  flex: auto;
`

export const Wrapper = styled(HorizontalTransitionFade)`
  ${flexColumn};
  max-width: 577px;
  margin: 24px;
  gap: 24px;
`

export const Main = styled.div`
  ${flexColumn};
  gap: 16px;
`

export const Image = styled.img`
  width: 65px;
  display: block;
`

export const TextWrapper = styled.div`
  ${flexColumn};
  gap: 12px;
  text-align: center;
`

export const Header = styled.div`
  ${Typography.Subhead};
  ${FontWeights.DemiBold}
`

export const Text = styled.div`
  ${Typography.Caption};

  b {
    ${FontWeights.DemiBold}
  }

  img {
    width: 20px;
    height: 20px;
    position: relative;
    top: -2px;
  }

  span {
    flex-wrap: nowrap;
    white-space: nowrap;
    ${FontWeights.DemiBold}
  }
`
