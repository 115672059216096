import styled from 'styled-components'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Colors, { hexToRgba } from 'builder/styles/colors'
import { blinking } from 'builder/styles/keyframes'
import { formatScrollBarStyles } from '../mixins'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  align-items: stretch;
  height: 100%;
`

export const Header = styled.div`
  padding: 32px 32px 0px;

  ${Media.Phone`
    padding: 32px 20px 0;
  `}
`

export const Content = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
`

export const Title = styled.div`
  ${Typography.M}
  margin-bottom: 16px;
  font-weight: 600;
`

export const Categories = styled.div`
  width: calc((100% - 72px) / 4);
  height: 100%;
  flex-shrink: 0;
  margin-right: 24px;
  padding-left: 32px;
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 12px); /* Makes shadow a bit shorter to avoid scrollbar overlap */
    height: 32px;
    z-index: 1;
    background: linear-gradient(to bottom, ${Colors.Neutral10}, ${hexToRgba(Colors.Neutral10, 0)});
  }

  ${Media.Tablet`
    width: calc((100% - 40px) / 3);
    margin-right: 20px;
  `}
`

export const CategoriesList = styled.div`
  ${formatScrollBarStyles()}
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 24px 0 32px;
`

export const CategoriesTitle = styled.div`
  ${Typography.Caps}
  letter-spacing: 1.8px;
  font-weight: 600;
  margin-bottom: 8px;
`

export const CategoriesItem = styled.button`
  ${Typography.Caption}
  border: none;
  background: transparent;
  padding: 0;
  color: ${Colors.Neutral60};
  transition: color 0.2s ease;
  cursor: pointer;
  text-align: left;

  &:not(:last-child) {
    padding-bottom: 4px;
  }

  &:focus,
  &:hover {
    outline: none;
    color: ${Colors.Blue50};
  }
`

export const ExamplesContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 12px); /* Makes shadow a bit shorter to avoid scrollbar overlap */
    height: 32px;
    z-index: 1;
    background: linear-gradient(to bottom, ${Colors.Neutral10}, ${hexToRgba(Colors.Neutral10, 0)});
  }

  ${Media.Phone`
    padding: 0 0 0 20px;
  `}
`

export const ExamplesList = styled.div`
  ${formatScrollBarStyles()}
  height: 100%;
  padding-bottom: 40px;
  overflow-y: auto;
  padding-right: 22px;

  ${Media.Phone`
    padding-right: 8px;
  `}
`

export const ExamplesGroupContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  margin-bottom: 16px;
  padding-top: 24px;

  ${Media.Tablet`
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    margin-bottom: 32px;
  `}

  ${Media.Phone`
    grid-gap: 16px;
    padding-top: 0;
    margin-bottom: 0px;
  `}
`

export const ExamplesGroupHeader = styled.div`
  padding-bottom: 16px;
  border-bottom: 1px solid ${Colors.Neutral20};
  grid-column-start: 1;
  grid-column-end: 4;

  ${Media.Tablet`
    grid-column-end: 3;
  `}

  ${Media.Phone`
    position: sticky;
    top: 0px;
    background: ${Colors.Neutral10};
    z-index: 2;
    padding-top: 20px;
    padding-bottom: 12px;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: calc(100% + 1px);
      left: 0;
      width: 100%;
      height: 24px;
      background: linear-gradient(
        to bottom,
        ${Colors.Neutral10},
        ${hexToRgba(Colors.Neutral10, 0)}
      );
    }
  `}
`

export const ExamplesGroupTitle = styled.div`
  ${Typography.Subhead}
  font-weight: 600;
`

export const ExamplesGroupCount = styled.div`
  ${Typography.Caption}
  color: ${Colors.Neutral50};
`

export const ExampleTitle = styled.div`
  ${Typography.Caption}
  transition: color 0.2s ease;
  margin-top: 12px;
`

export const ExampleRating = styled.div`
  display: flex;
  margin-top: 4px;
`

export const ExamplePreview = styled.div`
  width: 100%;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 4px ${hexToRgba(Colors.Neutral30, 0.12)};
  transition: box-shadow 0.2s ease;
  background: ${Colors.White};

  & img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &::after {
    content: '';
    display: block;
    padding-bottom: 141%;
  }
`

export const ExampleItem = styled.button`
  border: none;
  padding: 0;
  background: transparent;
  text-align: left;
  cursor: pointer;
  margin-bottom: auto;

  &:focus,
  &:hover {
    outline: none;

    ${ExampleTitle} {
      color: ${Colors.Blue50};
    }

    ${ExamplePreview} {
      box-shadow: 0 8px 16px -4px ${hexToRgba(Colors.Neutral50, 0.3)};
    }
  }
`

export const Star = styled.div<{ disabled?: boolean }>`
  width: 20px;
  height: 20px;
  background-image: url(${require('images/builder/resume-prefill-modal/rating_tiles.png')});
  background-size: auto 20px;
  background-position: ${props => (props.disabled ? -20 : 0)}px 0;

  &:not(:last-child) {
    margin-right: 2px;
  }

  ${Media.Phone`
    width: 16px;
    height: 16px;
  `};
`

export const SkeletonBase = styled.div`
  animation: ${blinking} 2s infinite linear;
  background: ${Colors.Neutral20};
  border-radius: 2px;
`

export const ExamplesGroupCountSkeleton = styled(SkeletonBase)`
  width: 200px;
  height: 16px;
  margin: 4px 0 2px;
`

export const ExamplesGroupTitleSkeleton = styled(SkeletonBase)`
  width: 70%;
  height: 22px;
  margin: 3px 0;
`

export const ExamplePreviewSkeleton = styled(SkeletonBase)`
  width: 100%;
  border-radius: 4px;
  background: ${Colors.White};

  &::after {
    content: '';
    display: block;
    padding-bottom: 141%;
  }
`

export const CategoriesItemSkeleton = styled(SkeletonBase)<{ width: number }>`
  width: ${props => props.width}%;
  height: 16px;
  margin: 4px 0;
  flex-shrink: 0;
`

export const SearchContainer = styled.div`
  background: ${Colors.White};
  border-radius: 4px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;

  ${Media.Phone`
    padding: 0 12px;
    z-index: 10;
  `}
`

export const SearchIcon = styled.div`
  margin-right: 12px;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  color: ${Colors.Blue50};
`

export const SearchInput = styled.input`
  width: 100%;
  border: none;
  padding: 12px 0;

  &::placeholder {
    color: ${Colors.Neutral50};
  }
`

export const SearchResults = styled.div`
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  width: 100%;
  border-radius: 4px;
  background-color: ${Colors.White};
  z-index: 2;
  overflow: hidden;
  box-shadow: 0 2px 4px ${hexToRgba(Colors.Neutral30, 0.3)};

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 24px;
    width: calc(100% - 16px);
    background: linear-gradient(to bottom, ${Colors.White}, ${hexToRgba(Colors.White, 0)});
    z-index: 2;
    pointer-events: none;
  }

  &::before {
    top: 0;
  }

  &::after {
    transform: scaleY(-1);
    bottom: 0;
  }
`

export const SearchResultsList = styled.div`
  ${formatScrollBarStyles({ background: Colors.White })}
  position: relative;
  max-height: 250px;
  overflow-y: auto;
  z-index: 1;
  padding: 12px 0;
`

export const SearchItem = styled.button`
  border: none;
  text-align: left;
  width: 100%;
  background: transparent;
  padding: 12px 52px;
  transition: color 0.2s ease;
  cursor: pointer;

  &:not(:last-child)::after {
    content: '';
    display: block;
    position: relative;
    top: 12px;
    width: 100%;
    height: 1px;
    background: ${Colors.Neutral10};
    margin: 0 auto;
  }

  &:focus,
  &:hover {
    outline: none;
    color: ${Colors.Blue50};
  }

  ${Media.Phone`
    padding: 12px 48px;
  `}
`
