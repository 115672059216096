import { useDispatch } from 'react-redux'
import { Fragment, useCallback } from 'react'
import { actions, selectors } from 'builder/modules/careerPath2'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { ShadowContainer, ContentWrapper, Title, StaticText, StyledButton } from './styles'

export const Failed = () => {
  const dispatch = useDispatch()
  const occupation = useTypedSelector(selectors.occupation)
  const careerPath1 = useTypedSelector(selectors.careerPath1)
  const level1JobTitles = useTypedSelector(selectors.level1JobTitles)
  const level2JobTitles = useTypedSelector(selectors.level2JobTitles)
  const level3JobTitles = useTypedSelector(selectors.level3JobTitles)

  const fetchNextLevelJobTitles = useCallback(
    (query: string) => {
      const params = {
        query,
      }

      dispatch(actions.fetchJobTitles(params))
    },
    [dispatch],
  )

  const handleClick = useCallback(() => {
    if (!level1JobTitles.length) {
      const params = {
        query: occupation,
      }

      dispatch(actions.fetchJobTitles(params))
    } else if (!level2JobTitles.length) {
      if (careerPath1[0].title) {
        fetchNextLevelJobTitles(careerPath1[0].title)
      }
    } else if (!level3JobTitles.length) {
      if (careerPath1[1].title) {
        fetchNextLevelJobTitles(careerPath1[1].title)
      }
    }
  }, [
    dispatch,
    occupation,
    careerPath1,
    level1JobTitles,
    level2JobTitles,
    level3JobTitles,
    fetchNextLevelJobTitles,
  ])

  return (
    <Fragment>
      <ShadowContainer />
      <ContentWrapper>
        <Title>Something went wrong</Title>
        <StaticText>
          Please, try changing your job title and your skill set and update your path
        </StaticText>
        <StyledButton onClick={handleClick}>Try Again</StyledButton>
      </ContentWrapper>
    </Fragment>
  )
}
