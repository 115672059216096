import ReactPhoneInput, { PhoneInputProps } from 'react-phone-input-2'
import { Transition } from 'react-transition-group'
import { useCallback, useState } from 'react'
import { FieldNote, FieldNoteProps } from '../FieldNote'
import { Bar, Label, LabelContainer, LabelContent, Required, Wrapper } from './styles'
import 'react-phone-input-2/lib/style.css'

type Props = FieldNoteProps & {
  label?: string
  value: string
  onChange: (val: string) => void
} & PhoneInputProps

export const PhoneInput = (props: Props) => {
  const { label, error, warning, description, errorNoMsg, required, value, onChange, ...rest } =
    props
  const hasError = !!error || errorNoMsg
  const hasWarning = !!warning
  const [isFocused, toggleFocus] = useState(false)

  const handleFocus = useCallback(() => {
    toggleFocus(true)
  }, [])

  const handleBlur = useCallback(() => {
    toggleFocus(false)
  }, [])

  const isBarVisible = isFocused || hasError || hasWarning

  return (
    <div>
      {label && (
        <LabelContainer>
          <Label hasError={hasError}>
            <span>
              <LabelContent>{label}</LabelContent>
              {required && <Required> *</Required>}
            </span>
          </Label>
        </LabelContainer>
      )}

      <Wrapper>
        <ReactPhoneInput
          country="us"
          value={value}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...rest}
        />
        <Transition in={isBarVisible} timeout={100}>
          {state => <Bar $in={state} $hasError={hasError} $hasWarning={hasWarning} />}
        </Transition>
      </Wrapper>

      <FieldNote error={error} warning={warning} description={description} />
    </div>
  )
}
