import { useQuery } from '@tanstack/react-query'
import { baseClient } from 'builder/modules/apiClient'
import { InterviewQuestion } from 'builder/modules/interview/types'

import { POLLING_REFRESH_INTERVAL_IN_MILLIS } from '../constants'

export const useInterviewQuestions = (
  interviewId?: number,
  refetchIntervalConditionPredicate?: (data?: InterviewQuestion[]) => boolean,
  aboutSignal?: AbortSignal,
) => {
  return useQuery(
    ['interviewQuestions', interviewId],
    async () => {
      const response = await baseClient.get<InterviewQuestion[]>(
        `/interview_prep/interviews/${interviewId}/questions`,
        {
          signal: aboutSignal,
        },
      )
      return response.data
    },
    {
      enabled: !!interviewId,
      refetchInterval: (data?: InterviewQuestion[]) => {
        if (
          (aboutSignal && aboutSignal.aborted) ||
          (refetchIntervalConditionPredicate && refetchIntervalConditionPredicate(data))
        ) {
          return false
        }
        return POLLING_REFRESH_INTERVAL_IN_MILLIS
      },
      useErrorBoundary: false,
    },
  )
}
