import * as React from 'react'
import * as icons from './icons'
import { FeatureIconName } from './types'
import { Container } from './styles'

export const featureIconNames = Object.keys(icons).filter(
  key => !key.endsWith('Disabled'),
) as FeatureIconName[]

interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, 'style'> {
  name?: FeatureIconName | ''
  disabled?: boolean
  tone?: string
}

export const FeatureIcon = ({ name, disabled, ...rest }: Props) => {
  const iconName = disabled ? `${name}Disabled` : name
  const iconUrl = icons[iconName as keyof typeof icons]

  return (
    <Container {...rest}>
      <img alt={name} src={iconUrl} />
    </Container>
  )
}
