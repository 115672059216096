import { ENTITY_TYPE } from 'draft-js-utils'
import Icon from 'builder/components/Icon'
import Tooltip from 'builder/components/Tooltip/Tooltip'
import { i18n as I18n } from 'builder/utils/i18n'
import { useConfig } from 'builder/hooks/useConfig'
import { StyleTypes, ToolbarActions } from '../types'
import { getEntityAtCursor } from './lib'
import {
  SpellCheckIconWrapper,
  SpellCheckPendingDots,
  SpellCheckPendingFirstDot,
  SpellCheckPendingSecondDot,
  SpellCheckPendingThirdDot,
  ToolbarButton,
} from './styles'
import { Props } from './RichTextToolbar'
import { useSuggestionRenders } from './useSuggestionRenders'

export const useToolbarRenders = (props: Props & { actions: ToolbarActions }) => {
  const {
    editorState,
    isAiPhrases,
    isAiProfileSummary,
    isSpellCheckerPending,
    isSpellCheckerEnabled,
    locale,
    suggestionConfig,
    editorContainerRef,
    actions: {
      addPhrase,
      replacePhrase,
      handleClickBlock,
      handleClickLink,
      handleClickMark,
      handleClickSpellCheck,
    },
  } = props
  const config = useConfig()
  const isAiProfileAvailable = config?.features.aiProfileSummary || false
  const isAiAction = isAiPhrases || (isAiProfileAvailable && isAiProfileSummary)
  const shouldUseAiProfileSuggestions = !isAiPhrases && isAiProfileAvailable && isAiProfileSummary

  const { renderAction } = useSuggestionRenders({
    locale,
    shouldUseAiProfileSuggestions,
    suggestionConfig,
    addPhrase,
    replacePhrase,
    isAiAction,
    editorContainerRef,
    getEditorText: () => editorState.getCurrentContent().getPlainText(),
  })

  const getCurrentBlockType = () => {
    const selection = editorState.getSelection()
    return editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType()
  }

  const entityAtCursor = () => {
    let contentState = editorState.getCurrentContent()
    let entity = getEntityAtCursor(editorState)
    return !entity ? null : contentState.getEntity(entity.entityKey)
  }

  const renderIcon = (type: StyleTypes) => {
    switch (type) {
      case StyleTypes.Bold:
        return <Icon.Bold />

      case StyleTypes.Italic:
        return <Icon.Italic />

      case StyleTypes.Underline:
        return <Icon.Underline />

      case StyleTypes.Strike:
        return <Icon.Strike />

      case StyleTypes.OrderedList:
        return <Icon.OrderedList />

      case StyleTypes.UnorderedList:
        return <Icon.UnorderedList />

      default:
        return <Icon.Add />
    }
  }

  const renderBlockButton = (type: StyleTypes) => {
    const blockType = getCurrentBlockType()
    const isActive = type === blockType

    return (
      <ToolbarButton onMouseDown={event => handleClickBlock(event, type)} isActive={isActive}>
        {renderIcon(type)}
      </ToolbarButton>
    )
  }

  const renderMarkButton = (type: StyleTypes) => {
    const isActive = editorState.getCurrentInlineStyle().has(type)

    return (
      <ToolbarButton
        data-testid={`toolbar-btn-${type}`}
        onMouseDown={event => handleClickMark(event, type)}
        isActive={isActive}
      >
        {renderIcon(type)}
      </ToolbarButton>
    )
  }

  const renderLinkButton = () => {
    const selection = editorState.getSelection()
    const entity = entityAtCursor()
    const hasSelection = !selection.isCollapsed()
    const isCursorOnLink = entity?.getType() === ENTITY_TYPE.LINK
    const shouldShowLinkButton = hasSelection || isCursorOnLink

    const icon = isCursorOnLink ? <Icon.UnbindLink /> : <Icon.BindLink />

    return (
      <ToolbarButton
        data-testid="toolbar-btn-hyperlink"
        onClick={handleClickLink}
        isDisabled={!shouldShowLinkButton}
        isActive={isCursorOnLink}
      >
        {icon}
      </ToolbarButton>
    )
  }

  const renderSpellCheckButton = () => {
    const tooltipText = I18n.t(
      `builder.resume_editor.spell_checker_${isSpellCheckerEnabled ? 'disable' : 'enable'}`,
    )

    return (
      <ToolbarButton isActive={isSpellCheckerEnabled} onClick={handleClickSpellCheck}>
        <Tooltip value={tooltipText}>
          {isSpellCheckerPending ? (
            <SpellCheckIconWrapper>
              <Icon.SpellCheckPending />
              <SpellCheckPendingDots>
                <SpellCheckPendingFirstDot />
                <SpellCheckPendingSecondDot />
                <SpellCheckPendingThirdDot />
              </SpellCheckPendingDots>
            </SpellCheckIconWrapper>
          ) : (
            <Icon.SpellCheck />
          )}
        </Tooltip>
      </ToolbarButton>
    )
  }

  return {
    renderAction,
    renderBlockButton,
    renderLinkButton,
    renderMarkButton,
    renderSpellCheckButton,
  }
}
