import { useEffect } from 'react'
import { createPortal } from 'react-dom'
import { useDispatch } from 'react-redux'
import { Icon20 } from 'builder/components/Icon'
import { i18n as I18n } from 'builder/utils/i18n'
import { actions } from 'builder/modules/resumeEditor'
import { TooltipBody, TooltipImg, TooltipText, CloseIcon, Overlay } from './styles'
import question from './question.png'
import './styles.css'

type Props = {
  position?: 'top' | 'bottom'
}

const ChangeTemplateTooltip = (props: Props) => {
  const { position = 'bottom' } = props

  const dispatch = useDispatch()

  const closeTooltip = () => {
    dispatch(actions.setShowChangeTemplateTooltip(false))
  }

  useEffect(() => {
    const previewPanel = document.getElementById('preview-panel-container') as HTMLDivElement
    if (previewPanel) {
      previewPanel.classList.add('preview-panel-focus')
    }

    return () => {
      if (previewPanel) {
        previewPanel.classList.remove('preview-panel-focus')
      }
    }
  }, [])

  return (
    <>
      <TooltipBody position={position}>
        <TooltipImg src={question} />
        <TooltipText position={position}>
          {I18n.t('builder.use_example.change_template_info')}
        </TooltipText>
        <CloseIcon onClick={closeTooltip}>
          <Icon20.Close />
        </CloseIcon>
      </TooltipBody>
      {createPortal(<Overlay />, document.body)}
    </>
  )
}

export default ChangeTemplateTooltip
