import { FC, useState, useCallback, useEffect, useRef } from 'react'
import * as React from 'react'
import type { ResumeExample } from '@rio/types'
import useDebouncedEffect from 'use-debounced-effect'
import Icon from 'builder/components/Icon'
import { useI18n } from 'builder/hooks/useI18n'
import { useClickOutside } from 'builder/hooks/useClickOutside'
import { findExamples } from '../utils'
import {
  SearchContainer,
  SearchIcon,
  SearchInput,
  SearchItem,
  SearchResults,
  SearchResultsList,
} from './styles'

interface ExampleSearchProps {
  examples: ResumeExample[]
  onSelect: (id: number) => void
}

const ExamplesSearch: FC<ExampleSearchProps> = ({ examples, onSelect }) => {
  const { i18n } = useI18n()
  const searchRef = useRef(null)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [searchResults, setSearchResults] = useState<ResumeExample[]>([])
  const [searchResultsVisible, setSearchResultsVisibility] = useState(false)

  useClickOutside(searchRef, () => setSearchResultsVisibility(false))

  // Call search callback from props after local search state update
  useDebouncedEffect(() => setSearchResults(findExamples(examples, searchQuery)), 350, [
    searchQuery,
  ])

  // Update local search query with input's value to trigger debounced effect
  const handleSearchInput = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.currentTarget.value)
  }, [])

  // Hide results container if no examples found
  useEffect(() => {
    if (searchResults.length) {
      setSearchResultsVisibility(true)
    }
  }, [searchResults])

  // Show results container on input focus
  const handleFocus = useCallback(() => {
    setSearchResults(findExamples(examples, searchQuery))
  }, [examples, searchQuery])

  return (
    <SearchContainer ref={searchRef}>
      <SearchIcon>
        <Icon.Search />
      </SearchIcon>

      <SearchInput
        placeholder={i18n.t('builder.prefill.search_placeholder')}
        name="search"
        type="search"
        value={searchQuery}
        onInput={handleSearchInput}
        onChange={handleSearchInput}
        onFocus={handleFocus}
      />

      {searchResultsVisible && (
        <SearchResults>
          <SearchResultsList>
            {searchResults.map(({ id, jobTitle }) => (
              <SearchItem key={`result-${id}`} onClick={() => onSelect(id)}>
                {jobTitle}
              </SearchItem>
            ))}
          </SearchResultsList>
        </SearchResults>
      )}
    </SearchContainer>
  )
}

export default ExamplesSearch
