export enum EDashboardLocalStoreKeys {
  ACTIVE_TAB = 'ACTIVE_TAB',
  IS_WELCOME_MODAL_SHOWN = 'IS_WELCOME_MODAL_SHOWN',
  IS_PV_REDIRECT = 'IS_PV_REDIRECT',
}

export enum ColorTones {
  blue = 'Blue',
  neutral = 'Neutral',
  green = 'Green',
  indigo = 'Indigo',
  beige = 'Beige',
}

export interface IVisualProps {
  imgUrl: string
  alt: string
  isMouseOver: boolean
  colorScheme: Record<string, string>
  isShowScore?: boolean
  isShowIcons?: boolean
  isShowAdd?: boolean
  isShowResume?: boolean
  isShowCoverLetter?: boolean
  isComingSoon?: boolean
  modalVisual?: string
  cardName?: string | undefined
  modalTitle?: string | undefined
  modalDescription?: string | undefined
  targetEventName?: string
  userPhotoUrl?: string | undefined
  isCoverLetter?: boolean
}

export interface IService {
  enabled: boolean
  imgUrl: string
  title: string
  subTitle: string
  action: () => void
  colorTone: ColorTones
  isShowScore?: boolean
  isShowIcons?: boolean
  isShowAdd?: boolean
  isShowResume?: boolean
  isShowCoverLetter?: boolean
  visual: React.ComponentType<IVisualProps>
  order: Record<number, number>
  isComingSoon?: boolean
  isPremiumService?: boolean
  modalVisual?: string
  cardName?: string | undefined
  modalTitle?: string | undefined
  modalDescription?: string | undefined
  targetEventName?: string
  userPhotoUrl?: string | undefined
  isCoverLetter?: boolean
  cioDomainUrl?: string
  isPostPremiumActive?: boolean
}

export interface ITabsAction {
  text: string
  index: number
}
