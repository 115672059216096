import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Mixins from 'builder/styles/mixins'
import Tooltip from 'builder/components/Tooltip'
import Button from 'builder/components/Button'
import { MODES as HISTORY_MODES } from './History'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`

export const Header = styled.div`
  flex-shrink: 0;
  padding: 32px 40px 40px;
  text-align: center;

  ${Media.Phone`
    padding: 24px 20px 32px;
  `};
`

export const Title = styled.div`
  ${Typography.S};
  font-weight: 600;
  margin-bottom: 8px;
`

export const Description = styled.div`
  margin-bottom: 32px;

  ${Media.Phone`
    margin-bottom: 24px;
  `};

  & b {
    font-weight: 600;
  }
`

export const Numbers = styled.div`
  display: flex;
`

export const Number = styled.div`
  position: relative;
  width: 50%;

  &:not(:last-child):after {
    content: '';
    position: absolute;
    right: 0;
    top: calc(50% - 10px);
    width: 1px;
    height: 20px;
    background-color: ${Colors.Neutral20};
  }
`

export const NumberValue = styled.div`
  ${Typography.XL};

  ${props =>
    props.locked &&
    css`
      background-image: url(${require('images/builder/share-modal/locked.svg')});
      background-repeat: no-repeat;
      background-position: 50% 50%;
      height: 40px;
    `}
`

export const NumberDiff = styled(Tooltip)`
  display: inline-block;
  margin: 1px 0 0 6px;
  padding: 1px 6px;
  vertical-align: middle;
  border-radius: 9px;
  background-color: ${Colors.Neutral10};
  color: ${Colors.Neutral50};
  ${Typography.Tiny};
  font-weight: 600;

  ${props =>
    props.isActive &&
    props.stat === 'views' &&
    css`
      background-color: ${Colors.Green10};
      color: ${Colors.Green50};
    `}

  ${props =>
    props.isActive &&
    props.stat === 'downloads' &&
    css`
      background-color: ${Colors.Blue10};
      color: ${Colors.Blue50};
    `}
`

export const NumberLabel = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
`

export const HistoryContainer = styled.div`
  flex-grow: 1;
  padding: 32px 40px 48px;
  background-color: ${Colors.Neutral5};

  ${Media.Phone`
    padding: 20px 20px 32px;
  `};

  ${props =>
    [HISTORY_MODES.paywall, HISTORY_MODES.empty].includes(props.mode) &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: center;
    `};

  ${props =>
    HISTORY_MODES.paywall === props.mode &&
    css`
      background-image: url(${require('images/builder/share-modal/blurred-history.png')});
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: 50% 0;
    `};
`

export const HistoryTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  color: ${Colors.Neutral50};
  ${Typography.Caps};
  white-space: nowrap;

  ${Media.Phone`
    display: none;
  `};

  &:before,
  &:after {
    content: '';
    flex-grow: 1;
    border-top: 1px dashed ${Colors.Neutral20};
  }

  &:before {
    margin-right: 16px;
  }

  &:after {
    margin-left: 16px;
  }
`

export const HistoryMessage = styled.div`
  width: 100%;
  max-width: 225px;
  margin: 0 auto;
  color: ${Colors.Neutral50};
  text-align: center;

  &:before {
    content: '';
    display: block;
    width: 40px;
    height: 40px;
    margin: 0 auto 8px;
    background-image: url(${require('images/builder/share-modal/empty.svg')});
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
`

export const View = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid ${Colors.Neutral15};
  }
`

export const ViewAvatarContainer = styled.div`
  flex-shrink: 0;
  overflow: hidden;
  width: 44px;
  height: 44px;
  margin-right: 16px;
  border-radius: 50%;

  background-color: ${Colors.Neutral15};
  ${props => !props.loaded && Mixins.placeholderBackground};

  ${Media.Phone`
    width: 36px;
    height: 36px;
    margin-right: 12px;
  `};
`

export const ViewAvatarImage = styled.img`
  display: block;
  width: 100%;
  opacity: ${props => (props.loaded ? 1 : 0)};
  transition: opacity 0.15s;
`

export const ViewContent = styled.div`
  flex-grow: 1;
`

export const ViewTitle = styled.div`
  ${Media.Phone`
    ${Typography.Caption};
  `};
`

export const ViewSubtitle = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};

  ${Media.Phone`
    ${Typography.Tiny};
  `};
`

export const ViewInfo = styled.div`
  max-width: 45%;
  margin-left: 16px;
  flex-grow: 1;
  text-align: right;
`

export const PaywallContainer = styled.div`
  text-align: center;
`

export const PaywallTitle = styled.div`
  margin-bottom: 8px;
  ${Typography.Subhead};
  font-weight: 600;
`

export const PaywallDescription = styled.div`
  margin-bottom: 20px;
`

export const PaywallButton = styled(Button).attrs({
  as: 'a',
  target: '_blank',
  size: 'small',
})``

export const TextPlaceholder = styled.span`
  display: inline-block;
  height: 1em;
  width: ${props => props.size * 0.4}em;
  max-width: 100%;
  border-radius: 3px;
  ${Mixins.placeholderBackground};
  vertical-align: middle;

  ${Media.Phone`
    width: ${props => props.size * 0.3}em;
  `};
`
