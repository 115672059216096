import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { i18n as I18n } from 'builder/utils/i18n'
import { Icon20 } from 'builder/components/Icon'
import { actions } from 'builder/modules/resumeEditor'
import {
  AIResumeDraftEventLabels,
  AIResumeDraftEvents,
  AIResumeFlows,
  AIResumeLinkedInSteps,
  GenerateResumeStatus,
} from 'builder/modules/constants'
import { useAppSelector } from 'builder/hooks/useAppSelector'
import { DesktopClose as Close } from '../styles'
import generationFailed from '../../../../../../app/assets/images/builder/ai-resume/generation-failed.png'
import {
  Container,
  GenerationFailedImg,
  Heading,
  Subheading,
  Actions,
  TryAgain,
  StartOver,
} from './styles'

export const GenerationFailed = () => {
  const aiResumeFlow = useAppSelector(store => store.resumeEditor.aiResume.aiResumeFlow)
  const dispatch = useDispatch()

  const onStartOver = () => {
    trackInternalEvent(AIResumeDraftEvents.clickStartOver, {
      label: AIResumeDraftEventLabels.generateError,
    })
    if (aiResumeFlow === AIResumeFlows.LinkedIn) {
      dispatch(actions.setAIResumeLinkedInStep(AIResumeLinkedInSteps.linkedInPrefill))
    } else {
      dispatch(actions.setQuestionnaireStep(1))
    }
    dispatch(actions.setGenerateResumeStatus(GenerateResumeStatus.notStarted))
  }

  const onTryAgain = () => {
    trackInternalEvent(AIResumeDraftEvents.clickTryAgain, {
      label: AIResumeDraftEventLabels.generateError,
    })
    if (aiResumeFlow === AIResumeFlows.LinkedIn) {
      dispatch(actions.generateResumeFromLinkedIn())
    } else {
      dispatch(actions.generateResumeFromQuestionnaire())
    }
  }

  const onClose = () => {
    dispatch(actions.setShowAIResumeModal(false))
  }

  return (
    <Container>
      <Close onClick={onClose}>
        <Icon20.Close />
      </Close>
      <GenerationFailedImg src={generationFailed} alt="AI resume generation failed" />
      <Heading>{I18n.t('builder.ai_resume_draft.generation_error.title')}</Heading>
      <Subheading>{I18n.t('builder.ai_resume_draft.generation_error.description')}</Subheading>
      <Actions>
        <StartOver theme="ghost" onClick={onStartOver}>
          {I18n.t('builder.ai_resume_draft.generation_error.start_over')}
        </StartOver>
        <TryAgain onClick={onTryAgain}>
          {I18n.t('builder.ai_resume_draft.generation_error.try_again')}
        </TryAgain>
      </Actions>
    </Container>
  )
}
