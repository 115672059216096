import styled from 'styled-components'

import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'

export const Container = styled.div`
  z-index: 1;
  display: flex;
  gap: 18px;
  top: 0;
  position: absolute;
  left: 50%;
  width: 40%;
  justify-content: center;

  ${Media.Tablet`
    height: 100%;
    left: 59%;
    top: 0;
    align-items: center;
  `}

  ${Media.Phone`
    height: 50%;
    width: 100%;
    left: 0;
    top: 0;
    align-items: center;
    flex-direction: column-reverse;
  `}
`

export const Shadow = styled.div`
  z-index: 2;
  bottom: 0;
  position: absolute;
  height: 41px;
  width: 269px;
  left: 33px;
  background: linear-gradient(0deg, #f1f2ff 0%, rgba(241, 242, 255, 0) 100%);

  ${Media.Tablet`
    left: unset;
    height: 12px;
    width: 220px;
  `}

  ${Media.Phone`
    left: unset;
    height: 12px;
  `}
`

export const Image = styled.img`
  position: relative;
  left: 25px;
  z-index: 1;

  ${Media.Tablet`
    left: -3px;
  `}

  ${Media.Phone`
    left: -9px;
  `}
`

export const ImageContainer = styled.div`
  position: relative;
`

export const EllipsisContainer = styled.div`
  position: relative;
  padding-top: 40px;
  left: -329px;
  opacity: 0.4;

  ${Media.Tablet`
    left: -252px;
    padding-top: 68px;
  `};

  ${Media.Phone`
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 0;
    top: 16px;
    height: 148px;
    overflow: hidden;
  `};
`

export const EllipseOne = styled.div`
  position: absolute;
  width: 421px;
  height: 421px;
  transform: translateY(-50%);
  border-radius: 421px;
  opacity: 0.64;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(195, 229, 254, 0) 68.75%,
    ${Colors.Indigo20} 100%
  );

  &.hover {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(195, 229, 254, 0) 68.75%,
      ${Colors.Indigo30} 100%
    );
  }

  ${Media.Tablet`
    width: 253px;
    height: 253px;
    border-radius: 253px;
  `};

  ${Media.Phone`
    border-radius: 268px;
    height: 268px;
    width: 268px;
    transform: none;
  `};
`

export const EllipseTwo = styled.div`
  position: absolute;
  width: 268px;
  height: 268px;
  left: 75px;
  transform: translateY(-50%);
  border-radius: 268px;
  opacity: 0.6;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(195, 229, 254, 0) 68.75%,
    ${Colors.Indigo30} 100%
  );

  &.hover {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(195, 229, 254, 0) 68.75%,
      ${Colors.Indigo40} 100%
    );
  }

  ${Media.Tablet`
    display: none;
  `};

  ${Media.Phone`
    border-radius: 130px;
    left: unset;
    width: 130px;
    height: 130px;
    transform: none;
    top: 50px;
  `};
`
