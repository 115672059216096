import InputField from './components/InputField'
import { SuggestedSkills } from './components/SuggestedSkills'
import { BetaBanner } from './components/BetaBanner'
import { Container, FormContainer, Content, Header } from './styles'

interface Industries {
  label: string
  value: string
}

interface CareerIndustriesProps {
  onTargetingIndustriesChange: (industries: Industries[]) => void
}

export const CareerIndustries = ({ onTargetingIndustriesChange }: CareerIndustriesProps) => {
  const handleIndustriesChange = (industries: Industries[]) => {
    onTargetingIndustriesChange(industries)
  }

  return (
    <Container>
      <Content>
        <Header>Are you targeting any industries?</Header>
        <FormContainer>
          <BetaBanner />
          <InputField onChange={handleIndustriesChange} />
          <SuggestedSkills handleIndustriesChange={handleIndustriesChange} />
        </FormContainer>
      </Content>
    </Container>
  )
}
