import { all } from 'redux-saga/effects'

import {
  resumeEditorSagas,
  trackingSagas as resumeTrackingSagas,
} from 'builder/modules/resumeEditor'
import { sagas as generalEditorSagas } from 'builder/modules/generalEditor'
import { sagas as renderingSagas } from 'builder/modules/rendering'
import { sagas as uiSagas } from 'builder/modules/ui'
import { sagas as careerPathSagas } from 'builder/modules/careerPath'
import { sagas as coRegistrationSagas } from 'builder/modules/coRegistration'
import { sagas as coverLetterEditorSagas } from 'builder/modules/coverLetterEditor'
import { sagas as panelSagas } from 'builder/modules/panel'
import { sagas as resumeReviewSagas } from 'builder/modules/resumeReview'
import { sagas as jobTrackingSagas } from 'builder/modules/jobTracking'
import { sagas as signInSagas } from 'builder/modules/signIn'
import { sagas as signUpSagas } from 'builder/modules/signUp'
import { sagas as userSagas } from 'builder/modules/user'
import { sagas as initSagas } from 'builder/modules/init'
import { sagas as offerAnalyzerSagas } from 'builder/modules/offerAnalyzer'
import { sagas as uploadResumeSagas } from 'builder/modules/uploadResume'
import { sagas as critiqueReviewSagas } from 'builder/modules/resumeCritiqueReview'
import { sagas as enterpriseSagas } from 'builder/modules/enterpriseModal'
import { sagas as careerCoachingSagas } from 'builder/modules/careerCoaching'
import { sagas as careerPath2Sagas } from 'builder/modules/careerPath2'
import { sagas as careerPlanningSagas } from 'builder/modules/careerPlanning'
import { sagas as lloydSagas } from 'builder/modules/lloyd'
import { sagas as sevenStoriesSagas } from 'builder/modules/sevenStories'
import { sagas as eventSagas } from 'builder/modules/events'
import { sagas as resumeDistributionSagas } from 'builder/modules/resumeDistribution'
import { sagas as careerProfileSagas } from 'builder/modules/careerProfile'
import { sagas as dashboardSagas } from 'builder/modules/dashboard'
import { sagas as jobSearchSagas } from 'builder/modules/jobSearch'

export function* sagas() {
  yield all([
    initSagas(),
    generalEditorSagas(),
    renderingSagas(),
    uiSagas(),
    careerPathSagas(),
    coRegistrationSagas(),
    resumeEditorSagas(),
    resumeTrackingSagas(),
    coverLetterEditorSagas(),
    panelSagas(),
    resumeReviewSagas(),
    jobSearchSagas(),
    jobTrackingSagas(),
    signInSagas(),
    signUpSagas(),
    userSagas(),
    offerAnalyzerSagas(),
    uploadResumeSagas(),
    critiqueReviewSagas(),
    enterpriseSagas(),
    careerCoachingSagas(),
    careerPath2Sagas(),
    careerPlanningSagas(),
    lloydSagas(),
    sevenStoriesSagas(),
    eventSagas(),
    resumeDistributionSagas(),
    careerProfileSagas(),
    dashboardSagas(),
  ])
}
