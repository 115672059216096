import styled from 'styled-components'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'

export const Field = styled.div`
  flex: 0 0 calc(50% - 20px);
  margin-bottom: 24px;

  ${Media.Phone`
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0;

    &:last-of-type {
      margin-bottom: 0;
    }
  `};
`

export const Notice = styled.div`
  display: flex;
  padding: 16px;
  border: 1px solid ${Colors.Neutral10};
  border-radius: 4px;
  top: -8px;
  position: relative;
`
export const NoticeIcon = styled.img`
  width: 32px;
  height: 24px;
  margin-right: 8px;
`
export const NoticeContent = styled.p`
  ${Typography.Caption}
  ${FontWeights.Regular}
  color: ${Colors.Blue90}
`
