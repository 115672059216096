import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { PromoteBannerModal } from 'builder/components/JobTracking/PromoteBanner/PromoteBannerModal'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { ChromeBanner } from 'builder/components/JobTracking/ChromeBanner'
import { EXTENSION_ID, EXTENSION_URL, actions, selectors } from 'builder/modules/jobTracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { PROMOTO_BANNER_STEPS } from './constants'

type JobTrackerExtensionBannerProps = {
  showOnboarding?: boolean
  componentType: string
}

export const JobTrackerExtensionBanner = ({
  showOnboarding,
  componentType,
}: JobTrackerExtensionBannerProps) => {
  const Label = 'job_tracker'
  const [showSteps, setShowSteps] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const mediaQueries = useMediaQueries()
  const timer = useRef<ReturnType<typeof setInterval>>()
  const dispatch = useDispatch()
  const { features } = useFeaturesConfig()
  const installed = useTypedSelector(selectors.hasInstalledExtension)
  const canInstallExtension = useTypedSelector(selectors.canInstallExtension)

  const checkExtensionInstalled = async () => {
    return fetch(`chrome-extension://${EXTENSION_ID}/icon128.png`)
      .then(() => {
        return true
      })
      .catch(() => {
        return false
      })
  }

  const checkAvailabilityToInstallExtension = useCallback(async () => {
    const isDesktopChrome =
      /Chrome/i.test(navigator.userAgent) && !(mediaQueries.isPhone || mediaQueries.isTablet)
    const isExtensionInstalled = await checkExtensionInstalled()
    dispatch(actions.setHasInstalledExtension(isExtensionInstalled))
    dispatch(actions.setCanInstallExtension(isDesktopChrome && !isExtensionInstalled))
  }, [mediaQueries.isPhone, mediaQueries.isTablet, dispatch])

  useEffect(() => {
    checkAvailabilityToInstallExtension()
  }, [checkAvailabilityToInstallExtension])

  useEffect(() => {
    return () => {
      clearInterval(timer.current)
    }
  }, [])

  const handleCloseBanner = () => {
    setShowSteps(false)
  }
  const lastStepClick = () => {
    if (componentType === 'job_tracker_dashboard') {
      trackInternalEvent('click_install_chrome', { label: Label, source: componentType })
    }
    setShowSteps(false)
    clearTimeout(timer.current)
    timer.current = setInterval(async () => {
      const isExtensionInstalled = await checkExtensionInstalled()
      if (isExtensionInstalled) clearInterval(timer.current)
      dispatch(actions.setHasInstalledExtension(isExtensionInstalled))
    }, 4000)
  }

  const handleClick = () => {
    trackInternalEvent('click_banner_cta_chrome', { label: Label, source: componentType })
    if (showOnboarding) {
      setCurrentStep(0)
      setShowSteps(true)
    } else {
      window.open(EXTENSION_URL, '_blank')
      lastStepClick()
    }
  }

  if (!canInstallExtension) return null

  const isBanner = componentType === 'job_tracker_sidebar' && installed

  return (
    <>
      {!isBanner && features?.jobTrackerChromeExtension && features.autoApply && (
        <ChromeBanner
          onClick={handleClick}
          isChromeExtensionInstalled={installed}
          componentType={componentType}
        />
      )}
      <PromoteBannerModal
        steps={PROMOTO_BANNER_STEPS}
        isBannerVisible={showSteps}
        onClose={handleCloseBanner}
        {...{ currentStep, setCurrentStep, lastStepClick }}
      />
    </>
  )
}
