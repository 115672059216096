import styled from 'styled-components'

// Components
export const Row = styled.div`
  display: flex;
  justify-content: space-around;

  &:last-child {
    margin-bottom: 0;
  }
`
export const Field = styled.div`
  flex: 0 0 calc(50% - 5px);
  margin: 15px 0;
`
