import styled from 'styled-components'
import Media from 'builder/styles/media'

export const Container = styled.svg<{ level: number; isSwiperVisible: boolean }>`
  position: absolute;
  top: ${({ isSwiperVisible }) => (isSwiperVisible ? 40 : 56)}px;
  ${props => props.level === 1 && `left: 340px`};
  ${props => props.level === 2 && `left: 724px`};
  ${props => props.level === 3 && `left: 1108px`};

  ${props => props.level === 2 && Media.Phone`left: 715px`};
  ${props => props.level === 3 && Media.Phone`left: 1092px`};
`
