import { ResumeWorkExperience } from 'packages/types'
import { useEffect } from 'react'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { useI18n } from 'builder/hooks/useI18n'
import RichTextArea from 'builder/components/RichTextArea'
import { TextField } from 'builder/components/TextField'
import { formatDateRange } from 'builder/utils/formatDateRange'
import { convertHtmlToText } from 'builder/utils/convertHtmlToText'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { NewSkillContainer } from '../NewSkillContainer/NewSkillContainer'
import { useEditContainer } from '../../hooks/useEditContainer'
import {
  Container,
  Item,
  ArrowTargetIconWrapper,
  ArrowTargetIcon,
  Badge,
  SectionWrapper,
  BeforeContainer,
  Title,
  ContentBefore,
  AfterContainer,
  SkillsBeforeContainer,
  SkillBeforeItem,
  SkillsAfterContainer,
  SkillAfterItemButton,
  CloseIcon,
  WorkExperienceContainer,
  WorkExperienceWrapper,
  WorkExperienceHeader,
  WorkExperienceTitle,
  WorkExperienceDate,
  WorkExperienceTitleContainer,
  WorkExperienceContent,
  EditIcon,
  WorkExperienceTextAreaContainer,
  TextAreaDescription,
  ScrollWrapper,
} from './styles'
import { EditContainerProps } from './types'

export const EditContainer = ({ isEditActive, resumeOld, resumeTailored }: EditContainerProps) => {
  const { i18n } = useI18n()
  const {
    profileSummaryOld,
    profileSummaryTailored,
    locale,
    skillsOld,
    skillsTailored,
    jobTitleOld,
    jobTitleTailored,
    workExperiencesTailored,
    openWorExperienceCardId,
    handleProfileSummaryChange,
    handleJobTitleChange,
    handleDeleteSkill,
    handleWorkExperienceChange,
    handleWorkExperienceToggle,
    isJobTitleChanged,
    isProfileChanged,
    isWorkExperienceChanged,
    isSkillsChanged,
    scrollRef,
  } = useEditContainer(resumeOld, resumeTailored)
  const { isTablet } = useMediaQueries()

  useEffect(() => {
    const element = scrollRef.current
    if (element) disableBodyScroll(element)
    return () => {
      if (element) enableBodyScroll(element)
    }
  })

  return (
    <ScrollWrapper isEditActive={isEditActive} ref={scrollRef}>
      <Container>
        {!isTablet && !isEditActive && (
          <Badge>{i18n.t('builder.auto_tailoring.overview.info_section.changed_text')}</Badge>
        )}
        {/* Job Title */}
        {isJobTitleChanged && (
          <SectionWrapper isEditActive={isEditActive}>
            {(!isTablet || isEditActive) && (
              <Item>
                <ArrowTargetIconWrapper>
                  <ArrowTargetIcon />
                </ArrowTargetIconWrapper>
                {i18n.t('builder.auto_tailoring.sections.job_title')}
              </Item>
            )}
            {isEditActive && (
              <BeforeContainer>
                <Title>{i18n.t('builder.auto_tailoring.overview.info_section.before')}</Title>
                <ContentBefore>
                  {jobTitleOld || i18n.t('builder.auto_tailoring.overview.info_section.none')}
                </ContentBefore>
              </BeforeContainer>
            )}
            {isEditActive && (
              <AfterContainer>
                <Title>{i18n.t('builder.auto_tailoring.overview.info_section.after')}</Title>
                <TextField
                  placeholder={i18n.t('builder.resume_editor.job_title_placeholder')}
                  label={i18n.t('builder.resume_editor.job_title')}
                  value={jobTitleTailored || ''}
                  onChange={event => handleJobTitleChange(event.target.value)}
                  name="jobTitle"
                />
              </AfterContainer>
            )}
          </SectionWrapper>
        )}
        {/* Professional Summary */}
        {isProfileChanged && (
          <SectionWrapper isEditActive={isEditActive}>
            {(!isTablet || isEditActive) && (
              <Item>
                <ArrowTargetIconWrapper>
                  <ArrowTargetIcon />
                </ArrowTargetIconWrapper>
                {i18n.t('builder.auto_tailoring.sections.professional_summary')}
              </Item>
            )}
            {isEditActive && (
              <BeforeContainer>
                <Title>{i18n.t('builder.auto_tailoring.overview.info_section.before')}</Title>
                <ContentBefore>
                  {profileSummaryOld || i18n.t('builder.auto_tailoring.overview.info_section.none')}
                </ContentBefore>
              </BeforeContainer>
            )}
            {isEditActive && (
              <AfterContainer>
                <Title>{i18n.t('builder.auto_tailoring.overview.info_section.after')}</Title>
                <RichTextArea
                  placeholder={i18n.t('builder.resume_editor.professional_summary.placeholder')}
                  value={profileSummaryTailored || ''}
                  onChange={handleProfileSummaryChange}
                  locale={locale}
                />
              </AfterContainer>
            )}
          </SectionWrapper>
        )}
        {/* Skills */}
        {isSkillsChanged && (
          <SectionWrapper isEditActive={isEditActive}>
            {(!isTablet || isEditActive) && (
              <Item>
                <ArrowTargetIconWrapper>
                  <ArrowTargetIcon />
                </ArrowTargetIconWrapper>
                {i18n.t('builder.auto_tailoring.sections.skills')}
              </Item>
            )}
            {isEditActive && (
              <BeforeContainer>
                <Title>{i18n.t('builder.auto_tailoring.overview.info_section.before')}</Title>

                {skillsOld?.length ? (
                  <SkillsBeforeContainer>
                    {skillsOld?.map((item, index) => {
                      return <SkillBeforeItem key={index}>{item?.skill}</SkillBeforeItem>
                    })}
                  </SkillsBeforeContainer>
                ) : (
                  <ContentBefore>
                    {i18n.t('builder.auto_tailoring.overview.info_section.none')}
                  </ContentBefore>
                )}
              </BeforeContainer>
            )}
            {isEditActive && (
              <>
                <AfterContainer>
                  <Title>{i18n.t('builder.auto_tailoring.overview.info_section.after')}</Title>
                  <SkillsAfterContainer>
                    {skillsTailored?.map((item, index) => {
                      return (
                        <SkillAfterItemButton
                          key={index}
                          onClick={() => handleDeleteSkill(item.cid || item.id)}
                        >
                          {item?.skill}
                          <CloseIcon />
                        </SkillAfterItemButton>
                      )
                    })}
                  </SkillsAfterContainer>
                </AfterContainer>
                <NewSkillContainer resumeTailored={resumeTailored} />
              </>
            )}
          </SectionWrapper>
        )}
        {/* Work Experience */}
        {isWorkExperienceChanged && (
          <SectionWrapper isEditActive={isEditActive}>
            {(!isTablet || isEditActive) && (
              <Item>
                <ArrowTargetIconWrapper>
                  <ArrowTargetIcon />
                </ArrowTargetIconWrapper>
                {i18n.t('builder.auto_tailoring.sections.work_experience')}
              </Item>
            )}
            {isEditActive && (
              <WorkExperienceContainer>
                {workExperiencesTailored?.map(
                  (workExperience: ResumeWorkExperience, index: number) => {
                    return (
                      <WorkExperienceWrapper
                        key={index}
                        isOpen={workExperience.id === openWorExperienceCardId}
                      >
                        <WorkExperienceHeader
                          isOpen={workExperience.id === openWorExperienceCardId}
                          onClick={() => handleWorkExperienceToggle(workExperience.id)}
                        >
                          <WorkExperienceTitleContainer>
                            <WorkExperienceTitle>{workExperience?.title}</WorkExperienceTitle>
                            <WorkExperienceDate>
                              {workExperience && formatDateRange(workExperience)}
                            </WorkExperienceDate>
                          </WorkExperienceTitleContainer>
                          {workExperience.id !== openWorExperienceCardId && (
                            <EditIcon
                              onClick={() => handleWorkExperienceToggle(workExperience.id)}
                            />
                          )}
                        </WorkExperienceHeader>
                        <WorkExperienceContent
                          isOpen={workExperience.id === openWorExperienceCardId}
                        >
                          <BeforeContainer>
                            <Title>
                              {i18n.t('builder.auto_tailoring.overview.info_section.before')}
                            </Title>
                            <ContentBefore>
                              {convertHtmlToText(workExperience.description || '')}
                            </ContentBefore>
                          </BeforeContainer>
                          <AfterContainer>
                            <Title>
                              {i18n.t('builder.auto_tailoring.overview.info_section.after')}
                            </Title>
                            <WorkExperienceTextAreaContainer>
                              <TextAreaDescription>
                                {i18n.t('builder.auto_tailoring.overview.info_section.description')}
                              </TextAreaDescription>
                              <RichTextArea
                                value={workExperience.description || ''}
                                onChange={value =>
                                  handleWorkExperienceChange(value, workExperience.id)
                                }
                                locale={locale}
                              />
                            </WorkExperienceTextAreaContainer>
                          </AfterContainer>
                        </WorkExperienceContent>
                      </WorkExperienceWrapper>
                    )
                  },
                )}
              </WorkExperienceContainer>
            )}
          </SectionWrapper>
        )}
      </Container>
    </ScrollWrapper>
  )
}
