import { DefaultSuggestionType } from 'builder/components/AsyncAutosuggest'

export enum FieldType {
  TEXT = 'text',
  EMAIL = 'email',
  PHONE = 'phone',
  ASYNC = 'async',
}

export type FieldObject = {
  label: string
  name: string
  type: FieldType
  value: string
  placeholder?: string
  hint?: string
  fetchItems: (value: string) => Promise<DefaultSuggestionType[]>
  badge?: JSX.Element
}

export type PersonalDetailRow = {
  index: number
  items: Array<FieldObject | JSX.Element>
}
