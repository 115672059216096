import { memo, useMemo } from 'react'
import Button from 'builder/components/Button'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { SpeechToTextStatus } from 'builder/modules/constants'
import { i18n as I18n } from 'builder/utils/i18n'
import { TABS } from '../../constants'
import { ActionBar, ContinueButton, NextStepAction, SkipButton } from './styles'

interface Props {
  currentStep: number
  continueButtonText: string
  showSkipButton: boolean
  handleBack: () => void
  handleContinue: ({ isSkipAction }: { isSkipAction: boolean }) => void
}

const ActionButtons = (props: Props) => {
  const { currentStep, continueButtonText, handleBack, handleContinue, showSkipButton } = props
  const { isPhone } = useMediaQueries()
  const {
    activeAccomplishmentsTab,
    accomplishmentsVoiceProcessingStatus,
    activeGoalsTab,
    goalsVoiceProcessingStatus,
  } = useTypedSelector(state => state.resumeEditor.aiResume.questionnaireData)

  const isFutureGoalsStep = currentStep === 6
  const activeTab = isFutureGoalsStep ? activeGoalsTab : activeAccomplishmentsTab
  const isVoiceInputBeingProcessed =
    (isFutureGoalsStep ? goalsVoiceProcessingStatus : accomplishmentsVoiceProcessingStatus) ===
    SpeechToTextStatus.processing
  const isVoiceInputProcessingFailed =
    (isFutureGoalsStep ? goalsVoiceProcessingStatus : accomplishmentsVoiceProcessingStatus) ===
    SpeechToTextStatus.failed
  const onlyShowPrimaryButton =
    continueButtonText === I18n.t('builder.ai_resume_draft.text_voice_input.stop_recording')
  const showBackButton = !(isPhone || onlyShowPrimaryButton)

  const marginTop = useMemo(
    () => ({
      abovePhone: [5, 6].includes(currentStep)
        ? activeTab === TABS.voice_input
          ? onlyShowPrimaryButton
            ? 26
            : isVoiceInputProcessingFailed
            ? 46
            : 42
          : 32
        : 32,
      phone: [5, 6].includes(currentStep)
        ? activeTab === TABS.voice_input
          ? onlyShowPrimaryButton
            ? 24
            : isVoiceInputProcessingFailed
            ? 24
            : 8
          : 24
        : 32,
    }),
    [activeTab, currentStep, isVoiceInputProcessingFailed, onlyShowPrimaryButton],
  )

  return (
    <ActionBar
      {...{ onlyShowPrimaryButton, marginTop: isPhone ? marginTop.phone : marginTop.abovePhone }}
    >
      {showBackButton && (
        <Button theme="ghost" onClick={handleBack}>
          {I18n.t('builder.ai_resume_draft.back')}
        </Button>
      )}
      <NextStepAction>
        {showSkipButton && (
          <SkipButton onClick={() => handleContinue({ isSkipAction: true })}>
            {I18n.t('builder.ai_resume_draft.skip')}
          </SkipButton>
        )}
        <ContinueButton
          onClick={() => handleContinue({ isSkipAction: false })}
          isLoading={isVoiceInputBeingProcessed}
          isDisabled={isVoiceInputBeingProcessed}
        >
          {continueButtonText}
        </ContinueButton>
      </NextStepAction>
    </ActionBar>
  )
}

export default memo(ActionButtons)
