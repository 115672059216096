import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { i18n as I18n } from 'builder/utils/i18n'
import { UserData } from 'builder/modules/user'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { selectors as interviewSelector } from 'builder/modules/interview/interviewModule'
import { analyticEventsLabel, translationKey } from '../../constants'
import { CoachAvatars } from '../CoachAvatars'
import { useLinkClickHandlers } from '../../hooks/useLinkClickHandlers'
import { ServiceNamesForAnalytic } from '../../types'
import * as Styled from './styles'
import { ActionListItem } from './components/ActionListItem'

export const ActionSection: FC<{
  user: UserData
}> = ({ user }) => {
  const isPremiumUser = user.hasPremiumFeatures
  const { features } = useFeaturesConfig()
  const interviewUrl = useSelector(interviewSelector.getInterviewUrl)
  const {
    onPitchLinkClick,
    onCoverLetterLinkClick,
    onInterviewPrepLinkClick,
    onOptimizeResumeClick,
  } = useLinkClickHandlers('click_assessment_next_move')

  const onCoachingClick = () => {
    trackInternalEvent('click_assessment_next_move', {
      label: analyticEventsLabel,
      service_name: ServiceNamesForAnalytic.coaching,
    })
  }

  return (
    <Styled.Container>
      <Styled.MainSectionContent>
        <Styled.MainSectionTextContainer>
          <Styled.MainSectionTextHeader>
            {I18n.t(`${translationKey}.so_now_what`)}
          </Styled.MainSectionTextHeader>
          <Styled.MainSectionText>
            {I18n.t(`${translationKey}.to_run_successful_search`)}
          </Styled.MainSectionText>
        </Styled.MainSectionTextContainer>

        <Styled.ListContainer>
          <ActionListItem
            iconName="ProfessionalHeadshot"
            header={I18n.t(`${translationKey}.professional_pitch`)}
            text={I18n.t(`${translationKey}.arm_yourself_with_pitch`)}
            link={isPremiumUser ? '/career-profile/pitch' : undefined}
            onClick={!isPremiumUser ? onPitchLinkClick : undefined}
          />
          <ActionListItem
            iconName="ResumeAndCoverLetters"
            header={I18n.t(`${translationKey}.optimized_resume_versions`)}
            text={I18n.t(`${translationKey}.make_sure_your_resume_speaks`)}
            onClick={onOptimizeResumeClick}
          />
          <ActionListItem
            iconName="ProfessionalWritingServices"
            header={I18n.t(`${translationKey}.pitch_perfect_cover_letters`)}
            text={I18n.t(`${translationKey}.get_perfect_cover_letter`)}
            link={isPremiumUser ? '/career-profile/cover-letter' : undefined}
            onClick={!isPremiumUser ? onCoverLetterLinkClick : undefined}
          />
          <ActionListItem
            iconName="InterviewPrepV2"
            header={I18n.t(`${translationKey}.next_level_interview_prep`)}
            text={I18n.t(`${translationKey}.interview_to_win`)}
            link={interviewUrl}
            onClick={!isPremiumUser ? onInterviewPrepLinkClick : undefined}
          />
        </Styled.ListContainer>

        {features.careerCoaching && (
          <Link to="/career-coaching" target="_blank">
            <Styled.Coaching onClick={onCoachingClick}>
              <CoachAvatars />
              <Styled.ListItemTextContainer>
                <Styled.ListItemTextHeader>
                  {I18n.t(`${translationKey}.consult_with_coach`)}
                </Styled.ListItemTextHeader>
                <Styled.ListItemText>
                  {I18n.t(`${translationKey}.expert_in_your_corner`)}
                </Styled.ListItemText>
              </Styled.ListItemTextContainer>
              <Styled.ListItemArrowIcon />
            </Styled.Coaching>
          </Link>
        )}
      </Styled.MainSectionContent>
    </Styled.Container>
  )
}
