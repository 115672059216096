import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { Icon16, Icon24 } from 'builder/components/Icon'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/jobTracking'
import { selectors as jobSearchSelectors } from 'builder/modules/jobSearch'
import { useI18n } from 'builder/hooks/useI18n'
import { useAutoApply } from 'builder/views/AutoApply/hooks/useAutoApply'
import { ChromeExtensionButton } from 'builder/components/JobTracking/ChromeExtensionButton/ChromeExtensionButton'
import { AutoApplyGuide } from 'builder/components/FindJob/AutoApplyGuide/AutoApplyGuide'

import {
  AutoApplyButton,
  AutoApplyButtonContainer,
  HiddenOnTablet,
  Bubble,
  Container,
  CreditValue,
  SavedJobs,
  SavedJobsCount,
  SettingIconContainer,
  SaveJobsContainer,
  Tooltip,
  AutoApplyCreditsContainer,
  HiddenOnDesktop,
} from './styles'

interface Props {
  children?: any
}

export const PageActionButtons = ({ children }: Props) => {
  const navigate = useNavigate()
  const { i18n } = useI18n()

  const { isAutoApplyFeatureEnabled, isUserOnAutoApplyPlan, getCreditCount } = useAutoApply()
  const savedJobsCount = useTypedSelector(selectors.savedJobsCount)
  const autoApplyBubbles = useTypedSelector(jobSearchSelectors.autoApplyBubbles)

  const handleSavedJobs = () => {
    trackInternalEvent('click_saved_jobs', {
      label: 'search',
    })
    navigate(`/job-tracking`)
  }

  const handleAutoApply = () => {
    trackInternalEvent('click_auto_apply', { label: 'job_search' })
    navigate(`/job-tracking`)
  }

  const handleSettingClick = () => {
    navigate(`/auto-apply/personal-info`)
  }

  return (
    <Container>
      {autoApplyBubbles.showAutoApplyGuideButton && <AutoApplyGuide />}
      {isAutoApplyFeatureEnabled && <ChromeExtensionButton type="job_search" />}
      <AutoApplyButtonContainer>
        {isUserOnAutoApplyPlan && (
          <Tooltip
            arrow={false}
            multiline={true}
            placement="bottom"
            textAlign="center"
            content={i18n.t('builder.job_tracking.auto_apply_button_hint')}
          >
            <AutoApplyCreditsContainer>
              <HiddenOnDesktop>
                <Icon24.FeaturedIcon />
              </HiddenOnDesktop>
              <AutoApplyButton onClick={handleAutoApply}>
                <HiddenOnTablet>{i18n.t(`builder.job_tracking.auto_apply_button`)}</HiddenOnTablet>
              </AutoApplyButton>
              <Bubble>
                <CreditValue>{getCreditCount()}</CreditValue>
              </Bubble>
            </AutoApplyCreditsContainer>
          </Tooltip>
        )}
        {isAutoApplyFeatureEnabled && (
          <Bubble>
            <SettingIconContainer onClick={handleSettingClick}>
              <Icon16.SettingIcon />
            </SettingIconContainer>
          </Bubble>
        )}
      </AutoApplyButtonContainer>
      <SavedJobs onClick={handleSavedJobs}>
        <Tooltip
          arrow={false}
          multiline={false}
          placement="bottom"
          content={i18n.t('builder.job_tracking.saved_jobs')}
        >
          <SaveJobsContainer>
            <Icon24.Heart />
            <HiddenOnDesktop>{i18n.t(`builder.job_tracking.saved_jobs`)}</HiddenOnDesktop>
            <SavedJobsCount>{savedJobsCount}</SavedJobsCount>
          </SaveJobsContainer>
        </Tooltip>
      </SavedJobs>
      {children}
    </Container>
  )
}
