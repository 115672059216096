import styled from 'styled-components'
import Media from 'builder/styles/media'

export const FindTrackJobsContainer = styled.div`
  max-width: 284px;
  width: 100%;
  flex: 0 0 284px;

  ${Media.Phone`
    flex:0 0 264px;   
    margin-bottom:24px;
    max-height:200px;
    height: 100%;
  `};
`
export const FindTrackJobsImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  height: 100%;

  ${Media.Phone`
    padding-top: 45px;
  `};
`
export const FindTrackJobsImage = styled.img``

export const FindTrackJobsUserContainer = styled.div`
  position: absolute;
  left: 108px;
  bottom: 37px;
  border-radius: 50%;

  ${Media.Phone`
    bottom: 9px;
    left: 109px;
  `};
`
export const FindTrackJobsUserImage = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  padding: 2px;
`
