import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
import { Typography, FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import { Container as BaseContainer } from 'builder/components/Container'
import { Badge } from 'builder/components/Badge'
import { BAR_HEIGHT, MENU_TRANSITION_DURATION, Z_INDEX } from '../../constants'

export const Container = styled.menu`
  position: fixed;
  z-index: ${Z_INDEX - 1};
  right: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  padding: 0;
  margin: 0;
  background-color: ${Colors.White};

  &.menu-transition-enter {
    opacity: 0;
  }
  &.menu-transition-enter-active {
    opacity: 1;
    transition: opacity ${MENU_TRANSITION_DURATION * 0.75}ms;
  }
  &.menu-transition-exit {
    opacity: 1;
  }
  &.menu-transition-exit-active {
    opacity: 0;
    transition: opacity ${MENU_TRANSITION_DURATION}ms;
  }
`

export const Divider = styled.div`
  position: absolute;
  top: ${BAR_HEIGHT}px;
  right: 0;
  left: 0;

  ${Media.Tablet`
    right: 20px;
    left: 20px;
  `};

  &:after {
    content: '';
    display: block;
    max-width: 100%;
    /* margin: 0 auto; */
    height: 1px;
    background-color: ${Colors.Neutral10};
  }
`

export const ScrollContainer = styled.div`
  position: absolute;
  top: ${BAR_HEIGHT}px;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`

export const Wrapper = styled.div`
  display: flex;
  margin-left: 32px;
  justify-content: space-around;
  flex-wrap: wrap;

  ${Media.Tablet`
    margin-left: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  `}

  ${Media.Phone`
    grid-template-columns: repeat(1, 1fr);
  `}
`
export const MenuGrid = styled.div`
  width: 352px;
  padding-left: 20px;
`

export const Content = styled(BaseContainer)`
  padding-top: 33px;
  padding-bottom: 16px;
  display: flex;

  ${Media.Tablet`
    padding-top: 32px;
    flex-direction: column;
  `}

  ${Media.Phone`
    padding-top: 16px;
    padding-bottom: 16px;
  `}

  ${Container}.menu-transition-enter & {
    transform: translateY(8px);
  }
  ${Container}.menu-transition-enter-active & {
    transform: translateY(0);
    transition: transform ${MENU_TRANSITION_DURATION}ms;
  }
  ${Container}.menu-transition-exit & {
    transform: translateY(0);
  }
  ${Container}.menu-transition-exit-active & {
    opacity: 0;
    transition: opacity ${MENU_TRANSITION_DURATION}ms;
  }
`

export const CategoryContainer = styled.section`
  width: 288px;
  margin: 0 0 32px 0;

  ${Media.Phone`
    margin-bottom: 24px;
  `}
`

export const CategoryTitle = styled.div`
  ${FontWeights.DemiBold};
  ${Typography.Caps}
  color: ${Colors.Neutral40};
  margin-bottom: 16px;
  letter-spacing: 1.8px;
  text-transform: uppercase;

  ${Media.Phone`
    margin-bottom: 12px;
  `}
`

export const FeatureContainer = styled(RouterLink)<{ $isDisabled?: boolean }>`
  display: flex;
  align-items: flex-start;
  pointer-events: ${props => (props.$isDisabled ? 'none' : 'auto')};

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  ${Media.Phone`
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  `}
`

export const FeatureIconStyled = styled.div`
  flex-shrink: 0;
  margin: 4px 20px 4px 0;
  width: 40px;
  height: 40px;
  background-color: ${Colors.Blue10};
  border-radius: 8px;

  ${Media.Phone`
    margin: 0 12px 0 0;
  `};
`

export const FeatureContent = styled.div`
  flex-grow: 1;
`

export const FeatureTitle = styled.div<{ isDisabled?: boolean }>`
  ${FontWeights.Medium};
  color: ${props => (props.isDisabled ? Colors.Neutral50 : Colors.Neutral90)};
`

export const FeatureBadge = styled(Badge)`
  margin-left: 8px;
`

export const FeatureText = styled.div`
  ${Typography.Caption};
  max-width: 15em;
  color: ${Colors.Neutral50};
  transition: color 0.2s;

  ${FeatureContainer}:hover & {
    color: ${Colors.Neutral90};
  }
`
export const LeftMenu = styled.div``

export const ServicePannel = styled.div`
  margin-top: 32px;
`

export const IconContainer = styled(Badge)`
  margin-left: 8px;
  padding: 0;
  border-radius: 50%;
`

export const EmptyHeader = styled.div`
  margin-top: 35px;
`
