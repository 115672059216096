import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'
import { Container } from '../styles'

export const IntroductionContainer = styled(Container)``

export const ContentItem = styled.div`
  &:not(:last-child) {
    margin-bottom: 60px;
  }
`

export const Title = styled.div`
  ${Typography.XL};
  font-weight: 700;
  color: ${Colors.Blue50};
  margin-bottom: 12px;

  ${Media.Phone`
    text-align: left;
  `}
`

export const Subtitle = styled.div`
  max-width: 536px;
  margin-bottom: 32px;
  text-align: center;

  ${Media.Phone`
    text-align: left;
    margin-bottom: 24px;
  `}
`

export const Label = styled.div`
  display: flex;
  align-items: center;
  ${Typography.Caption};
  color: ${Colors.Neutral50};
  margin-bottom: 6px;
`
