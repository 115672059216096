import Button, { ButtonTheme } from 'builder/components/Button'
import { ButtonContainer } from './styles'

type Props = {
  handleContinue: () => void
  handlePreviousStep: () => void
  step: number
  onNextStory: () => void
  onPrevStory: () => void
}

const Footer = ({ handleContinue, handlePreviousStep, onNextStory, onPrevStory, step }: Props) => {
  return (
    <ButtonContainer>
      <Button
        theme={ButtonTheme.ghost}
        onClick={() => {
          step === 3 ? onPrevStory() : handlePreviousStep()
        }}
      >
        Back
      </Button>
      <Button
        onClick={() => {
          step === 3 ? onNextStory() : handleContinue()
        }}
      >
        {step === 3 ? 'Next Story' : 'Continue'}
      </Button>
    </ButtonContainer>
  )
}

export default Footer
