import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Icon24 from 'builder/components/Icon'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Question = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`

export const QuestionContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 0 0;
`

export const RowWrapper = styled(QuestionContainer)`
  align-items: flex-start;
`

export const FoldableContainer = styled.div``

export const QuestionContent = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Regular};
`

export const AnswerContent = styled.div`
  max-width: 832px;
  width: 100%;
  color: ${Colors.Neutral50};
  padding-top: 16px;
`

export const Line = styled.div<{ isVisible: boolean }>`
  width: 100%;
  height: ${props => (props.isVisible ? '1px' : 0)};
  background: ${Colors.Neutral20};
`

export const OpenSectionIcon = styled(Icon24.Chevron)`
  transform: rotate(-90deg);
  color: ${Colors.Blue50};
`

export const CloseSectionIcon = styled(Icon24.Chevron)`
  transform: rotate(90deg);
  color: ${Colors.Blue50};
`
