import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import useInfiniteScroll, {
  UseInfiniteScrollHookRefCallback,
  UseInfiniteScrollHookRootRefCallback,
} from 'react-infinite-scroll-hook'
import { UseInfiniteQueryResult } from '@tanstack/react-query'
import { useI18n } from 'builder/hooks/useI18n'
import Tabs, { Tab } from 'builder/components/Tabs'
import { Icon20 } from 'builder/components/Icon'
import { actions, selectors } from 'builder/modules/interview/interviewModule'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as userSelector } from 'builder/modules/user'
import { ActiveInterviewTab, Interview, Pagination } from 'builder/modules/interview/types'
import { useInfiniteScrollInterviews } from 'builder/views/Interview/hooks/useInfiniteScrollInterviews'
import { PremiumDialog } from '../../PremiumDialog/PremiumDialog'
import { InitialRequest } from '../DashboardView'
import { Card } from './Card/Card'
import { BlankCards } from './BlankCards/BlankCards'
import {
  ButtonWrapper,
  CardSkeleton,
  CardsContainer,
  Container,
  CustomMobileButton,
  CustomTab,
  CustomTabletDesktopButton,
  Top,
} from './style'

export const TRANSLATION_KEY = 'builder.interview_prep_view.interview_dashboard'

type Tab = { labelTab: string; id: ActiveInterviewTab }

interface Props {
  setInitialRequest: React.Dispatch<React.SetStateAction<InitialRequest | undefined>>
}

export const ActiveInterviews = ({ setInitialRequest }: Props) => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const selectedTabId = useSelector(selectors.activeInterviewTab)

  const inProgressInterviews = useInfiniteScrollInterviews({
    byStatusNot: ['completed'],
    setInitialRequest,
    enabledPerformanceMetric: true,
    perPage: 5,
  })
  const completedInterviews = useInfiniteScrollInterviews({
    byStatus: ['completed'],
    setInitialRequest,
    enabledPerformanceMetric: true,
    perPage: 5,
  })

  const navigate = useNavigate()
  const [inProgressInfiniteScrollRef, { rootRef: inProgressInfiniteScrollRootRef }] =
    useInfiniteScroll({
      loading: inProgressInterviews.isLoading,
      hasNextPage: !!inProgressInterviews.hasNextPage,
      onLoadMore: inProgressInterviews.fetchNextPage,
      disabled: !!inProgressInterviews.error,
    })

  const [completeInfiniteScrollRef, { rootRef: completeInfiniteScrollRootRef }] = useInfiniteScroll(
    {
      loading: completedInterviews.isLoading,
      hasNextPage: !!completedInterviews.hasNextPage,
      onLoadMore: completedInterviews.fetchNextPage,
      disabled: !!completedInterviews.error,
    },
  )
  const makeResponseFlat = useCallback(
    (interviewResponse: UseInfiniteQueryResult<Pagination<Interview>, unknown>) => {
      if (!interviewResponse.data) {
        return []
      }
      return interviewResponse.data?.pages.flatMap(p => p.items)
    },
    [],
  )

  const [showPremiumDialog, setShowPremiumDialog] = useState(false)
  const isPremiumUser = useTypedSelector(userSelector.premiumAccess)

  const tabs: Tab[] = [
    {
      labelTab: i18n.t(`${TRANSLATION_KEY}.in_progress`),
      id: 'in_progress',
    },

    {
      labelTab: i18n.t(`${TRANSLATION_KEY}.complete`),
      id: 'complete',
    },
  ]
  const onCreateNewInterview =
    (parameter: 'interview_dash_custom' | 'interview_dash_history') => () => {
      trackInternalEvent('pick_interview', {
        label: 'interview_prep',
        parameter,
      })
      if (isPremiumUser) {
        navigate('/interview-preparation/custom-interview')
      } else {
        setShowPremiumDialog(true)
      }
    }

  const renderInterviews = (
    tab: ActiveInterviewTab,
    InfiniteInterviewData: UseInfiniteQueryResult<Pagination<Interview>, unknown>,
    infiniteScrollRef: UseInfiniteScrollHookRefCallback,
    rootRef: UseInfiniteScrollHookRootRefCallback,
    blankCardType: 'complete' | 'incomplete',
  ) => {
    const interviews = makeResponseFlat(InfiniteInterviewData)
    return (
      <CardsContainer ref={rootRef} style={{ display: selectedTabId === tab ? 'flex' : 'none' }}>
        {InfiniteInterviewData.isSuccess && interviews?.length === 0 && (
          <BlankCards
            type={blankCardType}
            onClick={onCreateNewInterview('interview_dash_history')}
          />
        )}
        {InfiniteInterviewData.isLoading && [1, 2, 3].map(i => <CardSkeleton key={i} />)}
        {interviews?.map(interview => (
          <Card
            key={interview.id}
            interview={interview}
            onDispose={() => InfiniteInterviewData.refetch()}
          />
        ))}
        {(InfiniteInterviewData.isLoading || InfiniteInterviewData.hasNextPage) && (
          <CardSkeleton ref={infiniteScrollRef} />
        )}
      </CardsContainer>
    )
  }

  return (
    <Container>
      <Top>
        <Tabs>
          {tabs.map(tab => (
            <CustomTab
              isActive={tab.id === selectedTabId}
              key={tab.id}
              onClick={() => dispatch(actions.setActiveInterviewTab(tab.id))}
            >
              {tab.labelTab}
            </CustomTab>
          ))}
        </Tabs>
        <CustomTabletDesktopButton onClick={onCreateNewInterview('interview_dash_custom')}>
          <Icon20.Add />
          {i18n.t(`${TRANSLATION_KEY}.create_new`)}
        </CustomTabletDesktopButton>
      </Top>
      <ButtonWrapper>
        <CustomMobileButton onClick={onCreateNewInterview('interview_dash_custom')}>
          {i18n.t(`${TRANSLATION_KEY}.new_interview`)}
        </CustomMobileButton>
      </ButtonWrapper>
      {renderInterviews(
        'in_progress',
        inProgressInterviews,
        inProgressInfiniteScrollRef,
        inProgressInfiniteScrollRootRef,
        'incomplete',
      )}
      {renderInterviews(
        'complete',
        completedInterviews,
        completeInfiniteScrollRef,
        completeInfiniteScrollRootRef,
        'complete',
      )}
      {showPremiumDialog && (
        <PremiumDialog
          trackingParameter="interview_dash_custom"
          onClose={() => setShowPremiumDialog(false)}
        />
      )}
    </Container>
  )
}
