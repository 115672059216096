import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { Icon20 } from 'builder/components/Icon'
import Typography, { FontWeights } from 'builder/styles/typography'
import Sizes from 'builder/styles/sizes'
import Media from 'builder/styles/media'

export const BadgeIconDefault = styled(Icon20.Hint2)`
  transition: opacity 0.2s ease-out;
`

export const BadgeIconHovered = styled(Icon20.Tips)`
  transition: opacity 0.2s ease-out;
  position: absolute;
  right: 0;
  top: 0;
`

export const Badge = styled.div`
  margin-left: ${Sizes['2XS']};
  ${Typography.Tiny};
  ${FontWeights.DemiBold}
  border-radius: ${Sizes['3XS']};
  color: ${Colors.Blue50};
  padding: ${Sizes['3XS']} 6px;
  background: ${Colors.Blue10};
  display: flex;
  gap: ${Sizes['3XS']};
  align-items: center;
  white-space: nowrap;
  flex-wrap: nowrap;

  ${BadgeIconDefault} {
    opacity: 1;
  }

  ${BadgeIconHovered} {
    opacity: 0;
  }

  ${Media.Hover`
    &:hover {
      background: ${Colors.Blue20};
      color: ${Colors.Blue60};
      ${BadgeIconDefault} {
        opacity: 0;
      }

      ${BadgeIconHovered} {
        opacity: 1;
      }
    }
  `}
`

export const BadgeIconWrapper = styled.div`
  position: relative;
`
