import { Container, Navigation, NavigationLink } from './styles'

export const Footer = () => {
  const footerLinks = [
    {
      name: ' Contact us',
      url: 'https://career.io/contact',
      open: '_blank',
    },
    {
      name: 'FAQ',
      url: 'https://help.career.io/',
      open: '_blank',
    },
    {
      name: 'Terms of Service',
      url: 'https://career.io/coaching-tos',
      open: '_blank',
    },
    {
      name: 'Privacy',
      url: 'https://career.io/privacy',
      open: '_blank',
    },
  ]

  const renderFooterLink = () => {
    return footerLinks.map(ui => (
      <NavigationLink
        key={ui.name}
        onClick={() => {
          window.open(ui.url, ui.open)
        }}
      >
        {ui.name}
      </NavigationLink>
    ))
  }

  return (
    <Container>
      <Navigation>{renderFooterLink()}</Navigation>
    </Container>
  )
}
