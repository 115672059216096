import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Close = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const Header = styled.div`
  position: relative;
  flex-shrink: 0;
`

export const ContainerBase = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
`

export const ViewLayoutContainer = styled(ContainerBase)`
  width: 608px;

  ${Media.Tablet`
    width: 530px;
  `}

  ${Media.Phone`
    width: 303px;
  `};
`

export const Title = styled.p`
  ${Typography.S};
  font-weight: 600;
  margin-bottom: 24px;
  text-align: center;
  color: ${Colors.Neutral90};

  ${Media.Phone`
    margin-bottom: 16px;
    width: 208px;
  `}
`

export const BottomContainer = styled.div`
  width: 608px;
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  ${Media.Tablet`
    width: 530px;
  `}

  ${Media.Phone`
    width: 303px;
    margin-top: 24px;
    align-items: center;
  `}
`

export const LimitedFeatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`

export const LimitedFeatureCard = styled.div`
  display: flex;
  margin-top: 8px;
  align-items: center;
`

export const ButtonContainer = styled.div`
  margin-top: 28px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const ExtraInfoContainer = styled.div`
  width: 608px;
  display: flex;
  align-items: start;

  ${Media.Tablet`
    width: 530px;
  `}

  ${Media.Phone`
    width: 303px;
  `}
`

export const ExtraInfoDesc = styled.p`
  ${Typography.Caption};
  margin-top: 16px;
  color: ${Colors.Neutral50};

  ${Media.Phone`
    margin-top: 10px;
  `}
`

export const ImageWrapper = styled.div``

export const ModalContainer = styled.div`
  align-items: center;
  text-align: center;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: ${Colors.White};
  border-radius: 16px;
  z-index: 30;
  padding-top: 52px;

  ${Media.Phone`
    padding-top: 42px;
  `}
`

export const FeaturesLayout = styled(ModalContainer)`
  width: 800px;
  height: 754px;
  border-radius: 16px;

  ${Media.Tablet`
    width: 634px;
    height: 754px;
  `}

  ${Media.Phone`
    width: 343px;
    height: 892px;
  `}
`

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 8px;

  ${Media.Phone`
    padding-top: 0px;
  `}

  & > div:not(:first-child) {
    margin-top: 10px;
  }
`

export const Card = styled.div`
  width: 608px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${Colors.Neutral5};
  border-radius: 8px;

  ${Media.Tablet`
    width: 530px;
    height: 72px;
  `}

  ${Media.Phone`
    width: 303px;
    height: 100px;
  `}
`

export const IconWrapper = styled.div`
  display: flex;
  padding-right: 18px;

  ${Media.Tablet`
    padding-right: 18px;
  `}

  ${Media.Phone`
    padding-right: 12px;
  `}
`

export const BaseImage = styled.img`
  width: 40px;
  height: 40px;
  margin: 16px;
`

export const FeatureImage = styled(BaseImage)``

export const SmallImage = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`

export const LimitedFeatureImage = styled(SmallImage)``

export const CardDescription = styled.div`
  text-align: left;

  ${Media.Phone`
    max-width: 150px;
  `}
`

export const CardTitle = styled.p`
  margin-bottom: 4px;
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
  ${Typography.Caption}

  ${Media.Phone`
    max-width: 150px;
  `}
`

export const CardSubtitle = styled.p`
  color: ${Colors.Neutral50};
  width: 358px;
  ${Typography.Tiny};

  ${Media.Phone`
    width: 150px;
  `}
`

export const LimitedFeaturesText = styled.p`
  text-align: start;
  ${Typography.ButtonS};
`

export const CardLink = styled.p`
  margin-bottom: 4px;
  color: ${Colors.Blue50};

  ${Media.AboveTablet`
    ${Card}:hover & {
      color: ${Colors.Blue50};
    }
  `}
`

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`

export const CancelSubcriptionText = styled.p`
  padding: 12px 24px;
  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${Colors.Neutral50};
  margin-bottom: 32px;
  cursor: pointer;

  &:hover {
    color: ${Colors.Blue50};
  }
`

export const CardTitleContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-bottom: 4px;
`
