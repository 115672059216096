import { useEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import Button, { ButtonSize } from 'builder/components/Button'
import { Icon20 } from 'builder/components/Icon'
import ModalOverlay from 'builder/components/ModalOverlay'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import {
  ButtonContainer,
  Close,
  Container,
  Description,
  DescriptionSection,
  Header,
  SubTitle,
  Title,
} from './styles'

type PaywallProps = {
  handleApplyOk: () => void
  handleApplyCancel: () => void
}

const ApplyDialog: React.FC<PaywallProps> = ({ handleApplyOk, handleApplyCancel }) => {
  const { isPhone } = useMediaQueries()

  useEffect(() => {
    trackInternalEvent('view_apply_banner', { label: 'tracker' })
  }, [])

  return (
    <ModalOverlay overlayFadeDuration={150} contentSlideDuration={150}>
      <Container>
        <Close onClick={handleApplyCancel}>
          <Icon20.Close />
        </Close>
        <DescriptionSection>
          <Description>
            <Header>
              <Title>Did you apply for this job?</Title>
            </Header>
            <SubTitle>
              Let&apos;s add it to the Job Tracker, where you can manage and track your job search.
            </SubTitle>
          </Description>
          <ButtonContainer>
            <Button
              size={!isPhone ? ButtonSize.default : ButtonSize.small}
              theme="ghost"
              onClick={handleApplyCancel}
            >
              No, I didn&apos;t
            </Button>
            <Button size={!isPhone ? ButtonSize.default : ButtonSize.small} onClick={handleApplyOk}>
              Yes, move job to Applied
            </Button>
          </ButtonContainer>
        </DescriptionSection>
      </Container>
    </ModalOverlay>
  )
}

export default ApplyDialog
