import { createAction } from '@reduxjs/toolkit'
import { Resume } from 'packages/types'
import { ResumeDistributionPersonalForm } from 'builder/views/ResumeDistributionView/types'
import { FetchStatuses } from '../constants'
import {
  Candidate,
  DistributionDates,
  IJobPreferencesForm,
  RecruiterData,
  LocationForm,
  ResumeDetails,
  RecruiterPayload,
  RecruiterCountPayload,
  RecruiterList,
  RecruiterStatus,
  CandidatePayload,
  SubscriptionStatus,
} from './types'

export const actions = {
  setIsEdit: createAction<boolean>('resumeDistribution/RESUME_DISTRIBUTION_SET_RESUME_EDIT'),
  setResumeDetails: createAction<ResumeDetails>(
    'resumeDistribution/RESUME_DISTRIBUTION_SET_RESUME_DETAILS',
  ),
  fetchPopulateFormDetailsForResume: createAction<{ resumeId: number }>(
    'builder/FETCH_RESUME_FORM_DETAILS',
  ),
  setPopulationFormDetailsForResume: createAction<Resume | null>('builder/SET_RESUME_FORM_DETAILS'),
  setParsingStatus: createAction<boolean>('builder/SET_PARSING_STATUS'),
  setJobPreferencesForm: createAction<Partial<IJobPreferencesForm>>(
    'builder/SET_JOB_PREFERENCES_FORM',
  ),
  setPersonalDetailsForm: createAction<Partial<ResumeDistributionPersonalForm>>(
    'builder/SET_PERSONAL_DETAILS_FORM',
  ),
  setLocationDetailsForm: createAction<Partial<LocationForm>>('builder/SET_LOCATION_DETAILS_FORM'),

  // Candidate
  fetchCandidate: createAction('builder/FETCH_RESUME_DISTRIBUTION_CANDIDATE'),
  setCandidate: createAction<Candidate | undefined>('builder/SET_RESUME_DISTRIBUTION_CANDIDATE'),
  setFetchCandidateStatus: createAction<FetchStatuses>(
    'builder/SET_RESUME_DISTRIBUTION_CANDIDATE_STATUS',
  ),
  postCandidate: createAction<FormData>('builder/POST_RESUME_DISTRIBUTION_CANDIDATE'),
  setPostCandidateStatus: createAction<FetchStatuses>(
    'builder/SET_RESUME_DISTRIBUTION_POST_CANDIDATE_STATUS',
  ),
  patchCandidate: createAction<CandidatePayload>('builder/PATCH_RESUME_DISTRIBUTION_CANDIDATE'),
  setPatchCandidateStatus: createAction<FetchStatuses>(
    'builder/SET_RESUME_DISTRIBUTION_PATCH_CANDIDATE_STATUS',
  ),
  setCandidateResumeFailed: createAction<boolean>(
    'builder/SET_RESUME_DISTRIBUTION_CANDIDATE_RESUME_FAILED',
  ),
  setServerFailed: createAction<boolean>('builder/SET_RESUME_DISTRIBUTION_SERVER_FAILED'),
  postCandidateResume: createAction<ResumeDetails>(
    'builder/POST_RESUME_DISTRIBUTION_CANDIDATE_RESUME',
  ),
  setPostCandidateResumeStatus: createAction<FetchStatuses>(
    'builder/SET_RESUME_DISTRIBUTION_CANDIDATE_RESUME_STATUS',
  ),

  postSubscriptionPause: createAction('builder/POST_RESUME_DISTRIBUTION_SUBSCRIPTION_PAUSE'),
  setPostSubscriptionPauseStatus: createAction<FetchStatuses>(
    'builder/SET_POST_RESUME_DISTRIBUTION_SUBSCRIPTION_PAUSE_STATUS',
  ),
  postSubscriptionResume: createAction('builder/POST_RESUME_DISTRIBUTION_SUBSCRIPTION_RESUME'),
  setPostSubscriptionResumeStatus: createAction<FetchStatuses>(
    'builder/SET_POST_RESUME_DISTRIBUTION_SUBSCRIPTION_RESUME_STATUS',
  ),
  postSubscriptionCreate: createAction('builder/POST_RESUME_DISTRIBUTION_SUBSCRIPTION_CREATE'),
  setPostSubscriptionCreateStatus: createAction<FetchStatuses>(
    'builder/SET_POST_RESUME_DISTRIBUTION_SUBSCRIPTION_CREATE_STATUS',
  ),
  setSubscriptionStatus: createAction<SubscriptionStatus>(
    'builder/SET_RESUME_DISTRIBUTION_SUBSCRIPTION_STATUS',
  ),

  // Distribution dates
  fetchDistributionDates: createAction('builder/FETCH_RESUME_DISTRIBUTION_DISTRIBUTION_DATES'),
  setDistributionDates: createAction<DistributionDates | undefined>(
    'builder/SET_RESUME_DISTRIBUTION_DISTRIBUTION_DATES',
  ),
  setFetchDistributionDatesStatus: createAction<FetchStatuses>(
    'builder/SET_RESUME_DISTRIBUTION_DISTRIBUTION_DATES_STATUS',
  ),

  // Recruiter list
  fetchRecruitersCount: createAction<RecruiterCountPayload>(
    'builder/FETCH_RESUME_DISTRIBUTION_RECRUITER_COUNT',
  ),
  fetchRecruiters: createAction<RecruiterPayload | undefined>(
    'builder/FETCH_RESUME_DISTRIBUTION_RECRUITER',
  ),
  setRecruitersCount: createAction<number>('builder/SET_RESUME_DISTRIBUTION_RECRUITER_COUNT'),
  setDistributedRecruitersCount: createAction<number>(
    'builder/SET_RESUME_DISTRIBUTION_DISTRIBUTED_RECRUITER_COUNT',
  ),
  setRecruiters: createAction<RecruiterList<RecruiterData>>(
    'builder/SET_RESUME_DISTRIBUTION_RECRUITER',
  ),
  setPaginatedRecruiters: createAction<{ date: number; data: RecruiterData }>(
    'builder/SET_RESUME_DISTRIBUTION_RECRUITER_PAGINATION',
  ),
  setFetchRecruitersStatus: createAction<RecruiterStatus<FetchStatuses>>(
    'builder/SET_RESUME_DISTRIBUTION_RECRUITER_STATUS',
  ),
  parseResumeForPrefill: createAction<File>('builder/PARSE_RESUME_FILE'),
}
