import trim from 'lodash/trim'
import { newRecordFor } from './newRecord'
import { SectionNames } from './sectionNames'

/**
 * Merge all hobbies of the resume into one
 * BC hobbies editor has been replaced from sortable cards to textarea
 *
 * @param {object} resume
 * @returns new resume object with a single complex hobbie
 */
export const mergeResumeHobbies = resume => {
  const sectionName = SectionNames.hobbies
  const currentHobbies = resume.hobbies || []
  const isSectionEnabled = resume.sectionsOrder.indexOf(sectionName) >= 0

  // Nothing to merge
  if (!isSectionEnabled) return resume

  // Add blank hobby card if section is empty
  if (currentHobbies.length === 0) {
    return {
      ...resume,
      hobbies: [newRecordFor(sectionName)],
    }
  }

  // Already merged or user has one hobby record
  if (currentHobbies.length === 1) return resume

  // Merge cards values into one string
  const value = resume.hobbies
    .filter(item => item.hobby)
    .map(item => trim(item.hobby))
    .join(', ')

  // Create merged hobby record based on the first item
  const item = {
    ...currentHobbies[0],
    hobby: value,
  }

  return {
    ...resume,
    hobbies: [item],
  }
}
