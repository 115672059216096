import { Resume } from '@rio/types'
import { SectionNames } from 'builder/modules/resumeEditor'

/** IDs to perform data analysis in ClickHouse */
export type CheckIssueId =
  | 'no_educations'
  | 'no_email'
  | 'no_job_title'
  | 'no_links'
  | 'no_phone'
  | 'not_enough_skills'
  | 'no_job_title_in_summary'
  | 'short_summary'
  | 'no_work_description'

export interface CheckIssue {
  sectionId: SectionNames // ID of the section containing the target field
  progress: number // In [0-1] range where 1 means the issue if solved
  taskId: TaskIds // ID of the parent task
  id: CheckIssueId
  label: string
  title: string // Tooltip title
  description: string // Tooltip description
  subject: string // Thing to fix (displayed under the progress bar until the issue is unsolved)
  counter?: string // Formatted string indicating the progress of issue ("0/1" and "1/1" by default)
  action: () => void // Action performed by clicking the button
  actionText: string // CTA button text
}

/** Must return `CheckIssue` object if an error was found or `null` if the check has passed */
export type CheckFn = (resume: Resume) => Omit<CheckIssue, 'taskId'>

export enum TaskIds {
  summary = 'summary',
  contacts = 'contacts',
  details = 'details',
}

export interface Task {
  id: TaskIds
  title: string
  score: number
  text: string
  done: boolean
  issues: CheckIssue[]
}

export interface Report {
  tasks?: Task[]
  score: number
}

export interface TunerInitialContextValue {
  resume: Resume | null
  tasks: Task[] | undefined
  score: number
}

export interface TunerContextValue extends TunerInitialContextValue {
  resume: Resume
}

export interface TunerEditorContextValue {
  /** All of the issues available for the task (included fixed ones) */
  issues: CheckIssue[]
  /** Issues displayed in bullets list */
  navigationIssueIds: CheckIssueId[]
  /** ID of the currently displayed issue */
  activeIssueId: CheckIssueId | null
  /** ID of the issue that can be shown by "Go To Next Step" button */
  nextIssueId: CheckIssueId | null
  /** Navigates between tooltips */
  activateIssue: (id: CheckIssueId) => void
  /** Shows status modal */
  showStatus: () => void
  /** Closes tooltips */
  cancel: () => void
}
