import styled, { css, keyframes } from 'styled-components'
import Colors from 'builder/styles/colors'

const loading = keyframes`
  to {
    background-position-x: -20%;
  }
`

const Animation = css`
  background-color: ${Colors.Neutral5};
  background: linear-gradient(
      100deg,
      rgba(239, 242, 249, 0) 40%,
      rgba(239, 242, 249, 0.6) 50%,
      rgba(239, 242, 249, 0) 60%
    )
    ${Colors.Neutral5};
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s ${loading} ease-in-out infinite;
`

export const BannerContainer = styled.div`
  height: 177px;
  border-radius: 8px;

  ${Animation};
`

export const ListLoader = styled.div`
  height: 168px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ActionContainer = styled.div`
  height: 36px;
  margin: 32px 0 0;
  border-radius: 8px;

  ${Animation};
`
