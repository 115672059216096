import styled, { keyframes, css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

const expandWidth = (width: number) => keyframes`
  from {
    width: 50%;
  }
  to {
    width: ${width}%;
  }
`

export const Container = styled.div<{ width: number; isMatching: boolean }>`
  width: ${props => props.width}%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  background-color: ${Colors.Blue10};
  border-radius: 4px;
  animation: ${props => expandWidth(props.width)} 800ms ease-out;

  ${props =>
    props.isMatching &&
    css`
      border: 2px solid ${Colors.Blue50};
    `}
`

export const Text = styled.p`
  ${Typography.Caption};
`

export const Value = styled(Text)`
  ${FontWeights.Medium};
`
