export type JobInsight = {
  title: string
  description: string
  salaryRange: string
}

export type RecommendedSkill = {
  title: string
  description: string
  hardOrSoft: string
}

export enum ServiceNamesForAnalytic {
  pitch = 'pitch',
  resumeBuilder = 'resume_builder',
  coverLetter = 'cover_letter',
  interviewPrep = 'interview_prep',
  coaching = 'coaching',
}
