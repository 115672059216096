import { useMemo } from 'react'
import { selectors, UpdatedEducationLevelSalary } from 'builder/modules/careerPath2'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import Bar from '../Bar'
import Loader from '../Loader'

import { formatNumber, getWidth } from '../../utils'
import { Container, Title, Bars } from './styles'

const EducationLevel = () => {
  const isSalaryFailed = useTypedSelector(selectors.isSalaryFailed)
  const isSalaryLoading = useTypedSelector(selectors.isSalaryLoading)
  const { educationLevelSalary } = useTypedSelector(selectors.salaryDetails)

  const updatedEducationLevelSalary = useMemo(() => {
    return {
      "Master's Degree": {
        salary: educationLevelSalary.master,
        width: getWidth(educationLevelSalary.master, educationLevelSalary.master),
      },
      "Bachelor's Degree": {
        salary: educationLevelSalary.bachelor,
        width: getWidth(educationLevelSalary.bachelor, educationLevelSalary.master),
      },
    }
  }, [educationLevelSalary])

  if (isSalaryFailed) {
    return null
  }

  return (
    <Container data-testid="education-level-section">
      <Title>Education Level</Title>
      {isSalaryLoading ? (
        <Loader />
      ) : (
        <Bars>
          {Object.keys(updatedEducationLevelSalary).map(key => {
            const formattedValue = formatNumber(
              updatedEducationLevelSalary[key as keyof UpdatedEducationLevelSalary].salary,
            )

            const width =
              updatedEducationLevelSalary[key as keyof UpdatedEducationLevelSalary].width

            return <Bar key={key} text={key} value={formattedValue} width={width} />
          })}
        </Bars>
      )}
    </Container>
  )
}

export default EducationLevel
