import { useMediaQueries } from 'builder/hooks/useMediaQueries'

import Icon24 from 'builder/components/Icon'
import {
  ListItem,
  ListItemColumn,
  ListItemHeader,
  ListItemIcon,
  ListItemSubText,
  ListItemText,
} from 'builder/components/List'
import { Recruiter } from 'builder/modules/resumeDistribution'

interface DesktopListProps {
  recruiter: Recruiter
}

export const DesktopList = ({ recruiter }: DesktopListProps) => {
  const { isDesktop } = useMediaQueries()
  const { city, state, country } = recruiter.location
  const loaction = `${city}, ${state} ${country}`

  const getIconByFileExt = (filename: string) => {
    const extMatch = /(?:\.([^.]+))?$/.exec(filename)
    const ext = extMatch && extMatch[1]

    switch (ext) {
      case 'txt':
        return <Icon24.Text />
      case 'pdf':
        return <Icon24.Pdf />
      case 'doc':
      case 'docx':
        return <Icon24.Word />
      default:
        return <></>
    }
  }

  return (
    <ListItem>
      <>
        <ListItemColumn
          columnWidth={1.25}
          columnTextMargin="0 10px 0 0"
          header={<ListItemHeader text={recruiter.name} />}
          subText={<ListItemSubText text={recruiter.jobTitle} />}
        />
        <ListItemColumn
          columnTextMargin="0 10px 0 0"
          text={<ListItemText text={recruiter.company} />}
          subText={<ListItemSubText text={loaction} />}
        />
        {isDesktop && (
          <ListItemColumn
            columnTextMargin="0 10px 0 3px"
            icon={<ListItemIcon icon={getIconByFileExt(recruiter.distribution.resumeFile)} />}
            text={<ListItemText text={recruiter.distribution.resumeFile} />}
          />
        )}
        <ListItemColumn
          columnWidth={0.75}
          columnTextPosition="end"
          subText={<ListItemSubText text={recruiter.industry} />}
        />
      </>
    </ListItem>
  )
}
