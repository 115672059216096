import { FC } from 'react'
import * as Styled from './styles'

export const SectionHeader: FC<{
  headerText: string
  description: string
  isReversed?: boolean
  isRowOnTablet?: boolean
}> = ({ headerText, description, isReversed, isRowOnTablet, children }) => {
  return (
    <Styled.Container $isReversed={isReversed} $isRowOnTablet={isRowOnTablet}>
      <Styled.TextContainer>
        <Styled.Text>{headerText}</Styled.Text>
        <Styled.Description>{description}</Styled.Description>
      </Styled.TextContainer>
      {children}
    </Styled.Container>
  )
}
