import styled, { css, keyframes } from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { EWidgetsName } from 'builder/modules/panel/types'

const delayedFadeIn = keyframes`
  0% {
    opacity: 0;
  },
  95% {
    opacity: 0;
  },
  100% {
    opacity: 1;
  }
`

export const Container = styled.div<{
  isHovering?: boolean
  isScrolled?: boolean
  resumeEditor?: boolean
  isHelpScoutPresent?: boolean
  isHelperOpen?: boolean
  currentWidgetName: string
}>`
  display: flex;
  width: 294px;
  padding: 16px;
  align-items: center;
  gap: 12px;
  position: fixed;
  bottom: ${props => (props.isHelperOpen ? '-200px' : '0')};
  right: ${props =>
    props.isHelpScoutPresent
      ? '116px'
      : props.currentWidgetName === EWidgetsName.PREMIER_VIRTUAL
      ? '48px'
      : '16px'};

  cursor: pointer;
  z-index: 10;
  transition: height 0.15s ease-in, width 0.2s ease-in, background-color 0.15s ease-in,
    bottom 0.4s ease-in-out;
  height: ${props => (props.isHovering ? '76px' : '70px')};

  border-radius: 16px 16px 0px 0px;
  background-color: ${props => (props.isHovering ? '#D7EEFF' : Colors.Blue10)};
  box-shadow: 0px 2px 4px 0px rgba(15, 56, 113, 0.08), 0px 1px 2px 0px rgba(15, 56, 113, 0.12);

  ${props =>
    props.isScrolled
      ? Media.Laptop`
        width: 60px;
      `
      : Media.Laptop`
        width: 213px;
      `};

  ${Media.Laptop`
    height: 60px;
    bottom: 20px;
    gap: 12px;
    text-align: center;
    padding: 10px;
    justify-content: end;
    right: 20px;
    border-radius: 1000px;
    
  `};

  ${props =>
    props.resumeEditor
      ? Media.Laptop`
        bottom: 100px;
        right: 24px;
      `
      : Media.Laptop`
        bottom: 20px;
      `};

  ${props =>
    props.isScrolled
      ? Media.Phone`
        bottom: 113px;
        width: 60px;
      `
      : Media.Phone`
        bottom: 20px;
        width: 213px;
      `};

  ${props =>
    props.resumeEditor
      ? Media.Phone`
        bottom: 88px;
        right: 16px;
      `
      : Media.Phone`
        bottom: ${props.isScrolled ? '113px' : '20px'};
      `};
`

export const StaticButtonContainer = styled.div<{ isHelperOpen: boolean }>`
  display: flex;
  align-items: center;
  position: fixed;
  cursor: pointer;
  z-index: 10;
  transition: height 0.15s ease-in, width 0.2s ease-in, background-color 0.15s ease-in,
    bottom 0.4s ease-in-out;

  height: 60px;
  bottom: ${props => (props.isHelperOpen ? '-300px' : '16px')};

  gap: 12px;
  text-align: center;
  padding: 10px;
  justify-content: end;
  right: 16px;
  border-radius: 1000px;

  background-color: ${Colors.Blue10};
  box-shadow: 0px 2px 4px 0px rgba(15, 56, 113, 0.08), 0px 1px 2px 0px rgba(15, 56, 113, 0.12);
`

export const TextMobile = styled.div<{ isScrolled: boolean }>`
  display: none;

  ${props =>
    props.isScrolled
      ? Media.Laptop`
          display: none;
          opacity: 0;
      `
      : Media.Laptop`
          display: flex;
          opacity: 100;
          width: fit-content;
          padding-right: 4px;
          text-align: center;
      `};
`

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;

  ${Media.Laptop`
    display: none;
    width: 0;
  `};
`

export const Title = styled.div`
  color: ${Colors.Blue70};
  ${Typography.Body};
  ${FontWeights.Medium};

  ${Media.Laptop`
    ${Typography.Caption};
  `};
`

export const CollapseTitle = styled.div`
  color: ${Colors.Blue70};
  ${Typography.Body};
  ${FontWeights.Medium};
  animation: ${delayedFadeIn} 1s ease-out;

  ${Media.Laptop`
    ${Typography.Caption};
  `};
`

export const SubTitle = styled.div<{ isHovering?: boolean }>`
  color: ${Colors.Blue70};
  ${Typography.Caption};

  height: 0;
  transition: opacity 0.15s ease-in, height 0.15s ease-in;
  opacity: 0;

  ${props =>
    props.isHovering &&
    css`
      height: auto;
      opacity: 100;
    `};
`

export const ProgressCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const BackgroundCircle = styled.circle`
  stroke: #bbdefb;
  fill: transparent;
`

export const ProgressNumber = styled.span`
  ${Typography.Caption};
  ${FontWeights.DemiBold};
  color: ${Colors.Blue50};
  position: absolute;
`

export const ProgressRing = styled.svg`
  transform: rotate(-90deg);
  stroke: ${Colors.Blue50};
  stroke-width: 2;
`

export const ProgressRingCircle = styled.circle`
  stroke: ${Colors.Blue50};
  stroke-dasharray: 116.238;
  stroke-dashoffset: ${props => props.offset};
  transition: stroke-dashoffset 0.35s;
  stroke-linecap: round;
`
