import styled from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Tabs, { Tab } from 'builder/components/Tabs'

export const TabsContainer = styled(Tabs)`
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  border-bottom-color: ${hexToRgba(Colors.Neutral60, 0.16)};
`

export const TabsItem = styled(Tab)<{ isActive: boolean }>`
  position: relative;
  width: 200px;
  margin: 0;
  padding: 0 0 20px;
  text-align: center;
  ${Typography.ButtonM};
  font-weight: 600;
  color: ${props => (props.isActive ? Colors.Neutral40 : Colors.Neutral60)};
  cursor: ${props => (props.isActive ? 'default' : 'pointer')};

  &:hover {
    color: ${Colors.Neutral40};
  }
`
