import TRCIOLogoURL from '../../assets/img/TRCIOLogo.svg'
import * as Styled from './styles'

const TRCIOHeader = () => {
  return (
    <Styled.HeadingContainer>
      <Styled.NavBar>
        <Styled.Logo src={TRCIOLogoURL}></Styled.Logo>
      </Styled.NavBar>
      <Styled.HeaderBottomBorder />
    </Styled.HeadingContainer>
  )
}

export default TRCIOHeader
