import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import {
  actions,
  selectors,
  SearchQuery,
  SalaryParams,
  CareerPathStep,
} from 'builder/modules/careerPath2'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'

import {
  Text,
  Heading,
  Container,
  LeftArrow,
  SubHeading,
  StyledButton,
  HeaderContent,
  ArrowContainer,
} from './styles'

const Header = (): JSX.Element => {
  const dispatch = useDispatch()
  const { isPhone } = useMediaQueries()
  const location = useTypedSelector(selectors.location)
  const careerPath1 = useTypedSelector(selectors.careerPath1)
  const selectedJobTitle = useTypedSelector(selectors.selectedJobTitle)
  const isExploreCareers = useTypedSelector(selectors.isExploreCareers)
  const exploreCareersLocation = useTypedSelector(selectors.exploreCareersLocation)
  const exploreCareersOccupation = useTypedSelector(selectors.exploreCareersOccupation)

  const handleClick = useCallback(
    () => dispatch(actions.setStep(CareerPathStep.SKILLS)),
    [dispatch],
  )

  const handleContinue = useCallback(() => {
    dispatch(actions.setSelectedJobTitle(careerPath1[0].title))
    dispatch(actions.setStep(CareerPathStep.CONFIRM_MY_PATH))
    dispatch(actions.setPrevStep(CareerPathStep.JOB_PROGRESSION))

    const jobTitleParam = (
      isExploreCareers ? exploreCareersOccupation : careerPath1[0]?.title || selectedJobTitle
    ) as string
    const locationParam = isExploreCareers ? exploreCareersLocation : location

    const queryParams: SearchQuery = {
      query: jobTitleParam,
      location: locationParam,
    }

    const salaryParams: SalaryParams = {
      title: jobTitleParam,
      location: locationParam || null,
    }

    dispatch(actions.fetchJobsRequest())
    dispatch(actions.fetchRecommendedJobCard(queryParams))
    dispatch(actions.fetchSalaryDetails(salaryParams))
    dispatch(actions.setAddToPathJobTitle(jobTitleParam))
    dispatch(actions.fetchCareerPagesJobDetails(jobTitleParam))
    dispatch(actions.fetchCourses({ title: jobTitleParam, limit: 12 }))
    dispatch(actions.fetchJobDemand({ job_title: jobTitleParam, location: locationParam || '' }))
  }, [
    dispatch,
    location,
    careerPath1,
    isExploreCareers,
    selectedJobTitle,
    exploreCareersLocation,
    exploreCareersOccupation,
  ])

  return (
    <Container>
      <ArrowContainer onClick={handleClick}>
        <LeftArrow />
        <Text>Back</Text>
      </ArrowContainer>
      <HeaderContent>
        <Heading>{isPhone ? 'Add job titles' : 'Add job titles to career path'}</Heading>
        <SubHeading>
          Career Path recognizes the value of lateral moves, offering opportunities for both
          promotions and horizontal transitions
        </SubHeading>
      </HeaderContent>
      <StyledButton onClick={handleContinue} $isVisible={careerPath1.length}>
        Continue
      </StyledButton>
    </Container>
  )
}

export default Header
