import styled, { css } from 'styled-components'
import Button from 'builder/components/Button'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Icon, { Icon20 } from 'builder/components/Icon'
import Shadows from 'builder/styles/shadows'
import { TextField } from 'builder/components/TextField'

export const Container = styled.div`
  background: ${Colors.White};
  padding: 32px;
  border-radius: 16px;
  ${Media.Phone`
    padding: 20px;
    border-radius: 12px;
  `}
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`

export const Title = styled.div`
  ${Typography.S}
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral90};
  width: 436px;

  ${Media.Phone`
    width:292px
  `}
`

export const CloseIcon = styled(Icon.AddSmall)<{ showHoverState: boolean }>(
  ({ showHoverState }) => {
    return css`
      fill: ${Colors.Neutral70};
      background: ${Colors.Neutral10};
      border-radius: 50%;
      position: relative;
      float: right;
      height: 28px;
      width: 28px;
      transform: rotate(225deg);
      ${showHoverState
        ? `&:hover {
          background: ${Colors.Blue50};
          fill: ${Colors.White};
          cursor: pointer;
        }`
        : ''}
      ${Media.Phone`
        height: 24px;
        width: 24px;
        z-index: 2;
      `}
    `
  },
)

export const Content = styled.div`
  margin-top: 12px;
  ${Typography.Body}
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
  width: 449px;
  ${Media.Phone`
    width: 303px; 
  `}
`

export const JobDetailsWrapper = styled.div`
  padding: 16px;
  background: ${Colors.Blue10};
  margin-top: 24px;
  display: flex;
  ${Media.Phone`
  padding: 12px;
  margin-top: 8px;
  `}
`

export const EditContainer = styled.div`
  margin-top: 24px;
  ${Media.Phone`
  margin-top: 20px;
  `}
`

export const CompanyLogo = styled.img`
  width: 48px;
  height: 48px;
  ${Shadows.lightWithBorder.low};
  border-radius: 12px;
  ${Media.Phone`
        width: 32px;
        height: 32px;
        border-radius: 4px;
      `};
`

export const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
  ${Media.Phone`
  margin-left: 12px;
  `}
`

export const CompanyDesignation = styled.div`
  ${Typography.Body}
  ${FontWeights.Regular}
  max-width: 221px;
  height: 20px;
  border-radius: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 8px;
  ${Media.Phone`
      max-width: 192px;  
      height: 16px; 
      margin-bottom: none
    `};
`

export const EditIcon = styled(Icon20.Edit)`
  color: ${Colors.Neutral30};
  cursor: pointer;
  &:hover {
    color: ${Colors.Blue50};
  }
`

export const RefreshDetailsIcon = styled(Icon20.Revert)<{ isRefresh: boolean }>`
  fill: ${props => (props.isRefresh === true ? Colors.Blue50 : Colors.Neutral30)};
`

export const Hostname = styled.div`
  ${Typography.Caption}
  ${FontWeights.Regular}
 color: ${Colors.Neutral50};
`

export const ButtonWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  ${Media.Phone`
  margin-top: 20px;
  `}
`

export const Revert = styled(Icon.Revert)``

export const TryAnotherLink = styled.div<{ isDisabled: boolean }>(({ isDisabled }) => {
  return css`
    padding: 13px 0px;
    ${Typography.Caption}
    ${FontWeights.DemiBold};
    color: ${!isDisabled ? Colors.Blue50 : Colors.Neutral30};
    background: ${Colors.White};
    align-items: center;
    cursor: ${!isDisabled ? 'pointer' : 'default'};
    display: flex;
    gap: 8px;

    &:hover {
      color: ${!isDisabled ? Colors.Blue60 : Colors.Neutral30};
    }

    ${Media.Phone`
      padding: 8px 0px;
    `}
  `
})

export const GenerateButton = styled(Button)`
  padding: 12px 24px 12px 20px;
  gap: 4px;
  background: ${Colors.Indigo50};
  color: ${Colors.White};
  ${Typography.Body};
  ${FontWeights.Medium};
  ${Media.Phone`
    ${Typography.Caption};
    padding: 7px 12px 7px 8px;
    height: 34px;
  `}
  &:hover {
    background-color: ${Colors.Indigo60};
  }
`

export const EditButtonWrapper = styled.div`
  display: flex;
  float: right;
`

export const Cancel = styled(Button)`
  margin-right: 8px;
`

export const Save = styled(Button)``

export const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  height: 24px;
  margin-bottom: 8px;
`

export const JobTitleTitle = styled.div`
  ${Typography.S}
  ${FontWeights.DemiBold}
  color: ${Colors.Neutral90};
  width: 95%;
  height: 276px;
`

export const InputField = styled(TextField)`
  input {
    color: ${Colors.Neutral90};
    ${Typography.Body}
    ${FontWeights.Regular}
    margin-bottom: 16px;
  }
`
