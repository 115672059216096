import styled, { css } from 'styled-components'
import Icon from 'builder/components/Icon'
import Colors from 'builder/styles/colors'
import { fadeIn } from 'builder/styles/keyframes'

export const ModalContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 680px;
  height: 651px;
  border-radius: 6px;
  background-color: ${Colors.Neutral100};
  user-select: none;
`

export const ModalClose = styled.div`
  position: absolute;
  z-index: 5;
  top: 0;
  right: 0;
  padding: 16px;
  display: flex;
  color: ${Colors.Neutral50};
  opacity: 0.48;
  transition: opacity 0.15s;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

export const ModalCloseIcon = styled(Icon.CloseLarge)``

export const fadeInStyles = css`
  animation: ${fadeIn} 0.3s ease-out;
`
