import { all, put, takeLatest, select } from 'redux-saga/effects'
import last from 'lodash/last'
import { UserData, selectors as userSelectors, actions as userActions } from 'builder/modules/user'
import { actions, selectors } from './careerProfileModule'
import { LocalStorageCareerProfileState } from './types'
import { filterUnansweredQuestions, getDataLocalStorageKey } from './utils'

function* readLocalStorageSaga() {
  const user: UserData | null = yield select(userSelectors.userData)

  if (!user) {
    return
  }

  const key = getDataLocalStorageKey(user.id)
  const rawData = localStorage.getItem(key)

  if (rawData) {
    const data: LocalStorageCareerProfileState = JSON.parse(rawData)
    yield put(actions.setCareerProfileStateFromLocalStorage(data))
  }

  yield put(actions.setIsSyncedWithLocalStorage(true))
}

function* writeLocalStorageSaga() {
  const user: UserData | null = yield select(userSelectors.userData)

  if (!user) {
    return
  }

  const data: LocalStorageCareerProfileState = yield select(selectors.dataToSyncWithLocalStorage)
  const key = getDataLocalStorageKey(user.id)

  localStorage.setItem(
    key,
    JSON.stringify({
      ...data,
      chat: {
        ...data.chat,
        asked: filterUnansweredQuestions(Number(last(data.chat.answers)?.time), data.chat.asked),
      },
    }),
  )
}

export const localStorageSagas = function* () {
  yield all([takeLatest(userActions.setAccount, readLocalStorageSaga)])

  yield all([takeLatest(actions.setSeenPage, writeLocalStorageSaga)])
  yield all([takeLatest(actions.setQuestions, writeLocalStorageSaga)])
  yield all([takeLatest(actions.setAsked, writeLocalStorageSaga)])
  yield all([takeLatest(actions.setAnswers, writeLocalStorageSaga)])
  yield all([takeLatest(actions.setPersona, writeLocalStorageSaga)])
  yield all([takeLatest(actions.setSelectedResumeText, writeLocalStorageSaga)])
  yield all([takeLatest(actions.setIsDataSentDuringCurrentSession, writeLocalStorageSaga)])
  yield all([takeLatest(actions.setFetchQuestionsStatus, writeLocalStorageSaga)])
  yield all([takeLatest(actions.setUploadedFilename, writeLocalStorageSaga)])
  yield all([takeLatest(actions.setLatestResume, writeLocalStorageSaga)])
  yield all([takeLatest(actions.setSelectedResume, writeLocalStorageSaga)])
  yield all([takeLatest(actions.setSeenTemporalModal, writeLocalStorageSaga)])
  yield all([takeLatest(actions.setInitialChatState, writeLocalStorageSaga)])
  yield all([takeLatest(actions.setStructuredResumeData, writeLocalStorageSaga)])
  yield all([takeLatest(actions.addFloatingHelperPromotionDay, writeLocalStorageSaga)])
  yield all([takeLatest(actions.setIsCompletenessResultClosed, writeLocalStorageSaga)])
}
