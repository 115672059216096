import { useMediaQueries } from 'builder/hooks/useMediaQueries'

const MediaQueries = ({ children }) => {
  const matches = useMediaQueries()

  if (typeof children === 'function') return children(matches)
  return children
}

export default MediaQueries
