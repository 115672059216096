import styled, { css } from 'styled-components'
import Media from 'builder/styles/media'
import { Columns } from './types/types'

export const Wrapper = styled.div<Columns>`
  width: 100%;

  ${p => css`
    grid-template-columns: repeat(${p.desktop ?? 12}, 1fr);
    display: grid;
    grid-gap: 32px;

    ${Media.Tablet`
    grid-template-columns: repeat(${p.tablet ?? 8}, 1fr);
      grid-gap: 24px;
   `}

    ${Media.Phone`
      grid-template-columns: repeat(${p.phone ?? 4}, 1fr);
    `}
  `}
`
