import { Link, useLocation } from 'react-router-dom'
import * as ActionGroup from 'builder/components/ActionGroup'
import { Icon24 } from 'builder/components/Icon'
import { MethodName, NetworkName } from '../../types'
import { useI18n } from '../../../../hooks/useI18n'
import * as Styled from './styles'

type Props = {
  onChoose: (methodName: MethodName) => void
}

const oauthUrls: Record<NetworkName, string> = {
  facebook: '/auth/facebook',
  google: '/auth/google_oauth2',
  linkedin: '/auth/linkedin',
}

export const Methods = ({ onChoose }: Props) => {
  // Copy `location.search` to persist "current_region_prefix"
  const location = useLocation()
  const { i18n } = useI18n()

  const onNetworkChoose = (network: NetworkName) => {
    onChoose(network)
    window.location.href = oauthUrls[network]
  }

  return (
    <>
      <ActionGroup.Container>
        <ActionGroup.Button icon={<Icon24.Google />} onClick={() => onNetworkChoose('google')}>
          {i18n.t('builder.sign_up.sign_up_with')} {i18n.t('builder.sign_up.social_buttons.google')}
        </ActionGroup.Button>
        <ActionGroup.Button icon={<Icon24.Linkedin />} onClick={() => onNetworkChoose('linkedin')}>
          {i18n.t('builder.sign_up.sign_up_with')}{' '}
          {i18n.t('builder.sign_up.social_buttons.linkedin')}
        </ActionGroup.Button>
        <ActionGroup.Button icon={<Icon24.Facebook />} onClick={() => onNetworkChoose('facebook')}>
          {i18n.t('builder.sign_up.sign_up_with')}{' '}
          {i18n.t('builder.sign_up.social_buttons.facebook')}
        </ActionGroup.Button>
      </ActionGroup.Container>

      <Styled.Divider>{i18n.t('builder.sign_up.or')}</Styled.Divider>

      <ActionGroup.Container>
        <ActionGroup.Button icon={<Icon24.Email />} onClick={() => onChoose('email')}>
          {i18n.t('builder.sign_up.sign_up_with')} {i18n.t('builder.sign_up.social_buttons.email')}
        </ActionGroup.Button>
      </ActionGroup.Container>

      <Styled.Footer>
        {i18n.t('builder.sign_up.already_have_account')}
        {' — '}
        <Link to={`/auth/sign-in${location.search}`}>{i18n.t('builder.sign_up.sign_in')}</Link>
      </Styled.Footer>
    </>
  )
}
