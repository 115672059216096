import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { KeywordsCounter, KeywordsInfoIcon } from 'builder/components/AiFeatures/Keywords'
import { CompanyLogo } from 'builder/components/DocumentEditor/JobPosting/DataContainer/Views/Result/styles'
import Logo from 'builder/components/DocumentEditor/JobPosting/Images/CompanyLogo.svg'
import { Icon20 } from 'builder/components/Icon'
import ModalOverlay from 'builder/components/ModalOverlay'
import { useI18n } from 'builder/hooks/useI18n'
import { AiSuggestionsPopupListItem } from 'builder/components/AiFeatures/Suggestions'
import { actions, selectors } from 'builder/modules/resumeEditor'
import Button from 'builder/components/Button'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useTailoredDesignation } from 'builder/hooks/useTailoredDesignation'
import {
  Container,
  Header,
  Title,
  Subtitle,
  Content,
  KeywordsHeader,
  JobTitleContainer,
  JobTitleWrapper,
  Action,
  CounterContainer,
  KeywordsContainer,
  Footer,
  CloseButton,
  ArrowTargetIconWrapper,
  ArrowTargetIcon,
  JobTitle,
} from './styles'

type ActionType = 'clear_all_button' | 'select_all_button'

const TRANSLATION = 'builder.auto_tailoring.select_keywords_modal'
const EVENT_LABEL = 'auto_tailoring_keywords_selection_screen'

export const BeforeAutoTailoringKeywordsModal = () => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const { suggestedJobTitle, jobPostingId, employerName } =
    useTypedSelector(selectors.jobPostingDataForAutoTailoring) || {}
  const keywords = useTypedSelector(selectors.keywordsForAutoTailoring)
  const resume = useTypedSelector(selectors.resume)
  const addedKeywords = useTypedSelector(selectors.addedKeywordsForAutoTailoring)
  const tailoredDesignation = useTailoredDesignation(suggestedJobTitle, employerName)
  const actionType: ActionType = keywords.some(item => !item.isAdded)
    ? 'select_all_button'
    : 'clear_all_button'

  const onClose = () => {
    dispatch(actions.setAutoTailoringModalStep(null))
  }

  const onKeywordClick = (keyword: string) => {
    trackInternalEvent('click_keyword', {
      label: EVENT_LABEL,
      mode: !keywords.find(item => item.keyword === keyword && item.isAdded)
        ? 'select'
        : 'unselect',
      keyword: keyword,
    })
    dispatch(actions.toggleKeywordForAutoTailoring(keyword))
  }

  const onActionClick = () => {
    if (actionType === 'clear_all_button') {
      dispatch(actions.setAllKeywordForAutoTailoring(false))
      trackInternalEvent('click_clear_all', { label: EVENT_LABEL })
    } else {
      dispatch(actions.setAllKeywordForAutoTailoring(true))
      trackInternalEvent('click_select_all', { label: EVENT_LABEL })
    }
  }

  useEffect(() => {
    trackInternalEvent('see_auto_tailoring_keywords_selection_screen')
  }, [])

  const onTailorResumeClick = () => {
    if (!resume || !jobPostingId) return

    dispatch(
      actions.autoTailorResume({
        resumeId: resume.id,
        selectedKeywords: addedKeywords,
        jobPostingId,
      }),
    )
    trackInternalEvent('click_auto_tailor_resume', {
      label: EVENT_LABEL,
      keywords_total: keywords?.length,
      keywords_added: addedKeywords?.length,
    })
  }

  return (
    <ModalOverlay onClose={onClose}>
      <Container>
        <Header>
          <Title>{i18n.t(`${TRANSLATION}.title`)}</Title>
          <Subtitle>{i18n.t(`${TRANSLATION}.sub_title`)}</Subtitle>
        </Header>

        <Content>
          <KeywordsHeader>
            <JobTitleContainer>
              <JobTitleWrapper>
                <CompanyLogo size={20} src={Logo} />
                <JobTitle>{tailoredDesignation}</JobTitle>
              </JobTitleWrapper>
              <Action onClick={onActionClick}>{i18n.t(`${TRANSLATION}.${actionType}`)}</Action>
            </JobTitleContainer>
            <CounterContainer>
              <KeywordsCounter
                count={addedKeywords.length}
                total={keywords.length}
                caption={i18n.t(`${TRANSLATION}.keywords`)}
              />
              <KeywordsInfoIcon tooltipText={i18n.t(`${TRANSLATION}.tooltip_text`)} />
            </CounterContainer>
          </KeywordsHeader>
          <KeywordsContainer>
            {keywords.map(({ keyword, isAdded }) => {
              return (
                <AiSuggestionsPopupListItem
                  key={keyword}
                  isActive={isAdded}
                  text={keyword}
                  icon={isAdded ? <Icon20.Tick /> : <Icon20.Add />}
                  isClickableWhenActive={true}
                  onClick={() => onKeywordClick(keyword)}
                />
              )
            })}
          </KeywordsContainer>
        </Content>

        <Footer>
          <Button theme="alternative" onClick={onTailorResumeClick}>
            <ArrowTargetIconWrapper>
              <ArrowTargetIcon />
            </ArrowTargetIconWrapper>
            {i18n.t(`${TRANSLATION}.auto_tailor_button`)}
          </Button>
        </Footer>

        <CloseButton onClick={onClose} />
      </Container>
    </ModalOverlay>
  )
}
