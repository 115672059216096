import { useCallback } from 'react'
import type { ChangeEvent } from 'react'
import { useI18n } from 'builder/hooks/useI18n'

import {
  NotificationContainer,
  NotificationContent,
  NotificationName,
  NotificationText,
  NotificationInput,
  NotificationToggle,
} from './styles'

interface Props {
  name: string
  enabled: boolean
  onChange: (notification: { name: string; value: boolean }) => void
}

const Notification = ({ name, enabled, onChange }: Props) => {
  const { i18n } = useI18n()

  const toggle = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange({ name, value: event.target.checked })
    },
    [name, onChange],
  )

  return (
    <NotificationContainer $enablePointer>
      <NotificationContent>
        <NotificationName>
          {i18n.t(`builder.account.email_notifications.${name}.name`)}
        </NotificationName>
        <NotificationText>
          {i18n.t(`builder.account.email_notifications.${name}.text`)}
        </NotificationText>
      </NotificationContent>
      <NotificationInput type="checkbox" checked={enabled} onChange={toggle} />
      <NotificationToggle checked={enabled} />
    </NotificationContainer>
  )
}

export default Notification
