import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from 'builder/modules/careerPlanning'

import { Container, Icon } from './styles'

type Props = {
  id: number
  text: string
  link: string
  icon?: string
  isPremiumUser: boolean
  availableForFreeUsers: boolean
}

const References = ({ id, icon, text, link, isPremiumUser, availableForFreeUsers }: Props) => {
  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    if (isPremiumUser || availableForFreeUsers) {
      window.open(link, '_blank')
      return
    }

    dispatch(actions.setShowLimitModal(true))
  }, [link, dispatch, isPremiumUser, availableForFreeUsers])

  return (
    <Container referenceId={id} onClick={handleClick}>
      <Icon src={icon} /> {text}
    </Container>
  )
}

export default References
