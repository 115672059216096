import { useCallback, useMemo } from 'react'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import { generateRandomId } from 'builder/utils/generateRandomId'
import { LevelOption } from 'builder/components/LevelSelect/components/LevelOption/LevelOption'
import { Wrapper, Label, LabelValue, Selector, Handle } from './styles'
import { OptionType } from './types'

type LevelSelectProps = {
  options: OptionType[]
  label?: string
  selected?: string
  disabled?: boolean
  onChange?: (id: string) => void
}

export const LevelSelect = ({
  options,
  selected,
  onChange,
  label,
  disabled = false,
}: LevelSelectProps) => {
  const inputName = useMemo(() => generateRandomId(), [])

  const getCurrentRating = useCallback(() => {
    const { rating } = find(options, { id: selected }) || {}
    return rating
  }, [options, selected])

  const handleSelect = (id: string) => {
    if (onChange) onChange(id)
  }

  const levels = sortBy(options, ['rating']).filter(
    ({ rating }) => typeof rating === 'number' && rating >= 0,
  )
  const selectedLevel = find(levels, { id: selected })
  const selectedIndex = findIndex(levels, { id: selected })
  const rating = useMemo(() => getCurrentRating(), [getCurrentRating])

  const handleStyle = {
    width: `${100 / levels.length}%`,
    transform: `translateX(${selectedIndex * 100}%)`,
  }

  return (
    <Wrapper>
      {label && (
        <Label>
          {label}
          {selectedLevel && ' — '}
          {selectedLevel && (
            <LabelValue currentRating={rating} isDisabled={disabled}>
              {selectedLevel.name}
            </LabelValue>
          )}
        </Label>
      )}
      <Selector currentRating={rating} isDisabled={disabled}>
        {levels.map(({ id }) => (
          <LevelOption
            key={id}
            disabled={disabled}
            id={id}
            rating={rating}
            onSelect={handleSelect}
            inputName={inputName}
            checked={id === selected}
          />
        ))}
        <Handle currentRating={rating} style={handleStyle} isDisabled={disabled} />
      </Selector>
    </Wrapper>
  )
}
