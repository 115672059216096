import * as React from 'react'
import { TextField } from 'builder/components/TextField'
import EditorField from 'builder/components/EditorField'
import { useDispatch } from 'react-redux'
import { actions as editorActions } from 'builder/modules/resumeEditor'
import PropTypes from 'prop-types'
export function PersonalEditorField(props) {
  const dispatch = useDispatch()
  const { label, value, name } = props
  const handleSimpleFieldUpdate = React.useCallback(
    event => {
      const { name, value } = event.target
      dispatch(editorActions.updateSimpleField({ name, value, debounce: true }))
    },
    [dispatch],
  )

  return (
    <>
      {/* Editor Field */}
      <EditorField>
        <TextField
          label={label}
          value={value || ''}
          name={name}
          onChange={handleSimpleFieldUpdate}
        />
      </EditorField>
    </>
  )
}
PersonalEditorField.propTypes = {
  label: PropTypes.any,
  value: PropTypes.any,
  name: PropTypes.any,
}
