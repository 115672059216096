import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  actions,
  selectors,
  ResumeCritiqueStatuses as CRITIQUE_STATUSES,
} from 'builder/modules/resumeReview'
import { FetchStatuses } from 'builder/modules/constants'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { Spinner } from 'builder/components/Spinner'

import { Container } from './styles'

/** Fake page to redirect users coming from emails */
export const ResumeCritiquesView = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const critiques = useTypedSelector(selectors.critiques)
  const fetchStatus = useTypedSelector(selectors.critiquesFetchStatus)
  const critique = critiques.length > 0 ? critiques[0] : null

  // Start loading critiques
  useEffect(() => {
    if (fetchStatus === FetchStatuses.notAsked) dispatch(actions.fetchCritiques())
  }, [dispatch, fetchStatus])

  // Find proper target page url
  const redirectUrl: string | null = useMemo(() => {
    // Go to dashboard if network request failed
    if (fetchStatus === FetchStatuses.failed) return '/'
    // Wait until fetching request is done
    if (fetchStatus === FetchStatuses.loaded) {
      // Go to dashboard if user doesn't have a critique yet or it's incomplete/failed
      if (critique === null || critique.status !== CRITIQUE_STATUSES.finished) return '/'
      // Open finished resume critique page
      return `/resumes/${critique.resumeId}/critique`
    }
    // Do nothing if fetching critiques request is pending
    return null
  }, [fetchStatus, critique])

  // Perform URL replacing
  useEffect(() => {
    if (redirectUrl) return navigate(redirectUrl, { replace: true })
  }, [navigate, redirectUrl])

  // Show spinner while waiting for critiques
  return (
    <Container>
      <Spinner />
    </Container>
  )
}
