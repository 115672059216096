import * as React from 'react'
import EditorField from 'builder/components/EditorField'
import { TextField } from 'builder/components/TextField'
import { useI18n } from 'builder/hooks/useI18n'
import { actions as editorActions } from 'builder/modules/resumeEditor'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
export function FirstName(props) {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const { firstName } = props
  const handleSimpleFieldUpdate = React.useCallback(
    event => {
      const { name, value } = event.target
      dispatch(editorActions.updateSimpleField({ name, value, debounce: true }))
    },
    [dispatch],
  )

  return (
    <>
      {/* First name */}
      <EditorField>
        <TextField
          label={i18n.t('builder.resume_editor.first_name')}
          value={firstName || ''}
          name="firstName"
          onChange={handleSimpleFieldUpdate}
        />
      </EditorField>
    </>
  )
}
FirstName.propTypes = {
  firstName: PropTypes.string,
}
