import styled, { css, ThemeProps } from 'styled-components'
import Colors from 'builder/styles/colors'
import { TooltipProps } from '../../Tooltip'
import { Pointer } from './icons/Pointer'

export const PointerStyled = styled(Pointer)<ThemeProps<TooltipProps>>`
  position: absolute;

  ${props =>
    props.theme.position === 'top' &&
    css`
      left: 50%;
      color: ${Colors.Neutral90};
      top: 100%;
      transform: translate(-50%, 0);
    `}

  ${props =>
    props.theme.position === 'right' &&
    css`
      bottom: initial;
      top: 50%;
      right: calc(100% - 8px);
      left: initial;
      transform: translateY(-50%) rotate(90deg);
    `}

  ${props =>
    props.theme.position === 'left' &&
    css`
      bottom: initial;
      top: 50%;
      left: calc(100% - 8px);
      right: initial;
      transform: translateY(-50%) rotate(270deg);
    `}

  ${props =>
    props.theme.position === 'bottom' &&
    css`
      transform: translate(-50%, 0.2px) rotate(180deg);
      bottom: 100%;
      top: auto;
      color: ${Colors.Neutral90};
    `}

  ${props =>
    props.theme.align === 'left' &&
    css`
      left: 20px;
    `}

  ${props =>
    props.theme.align === 'right' &&
    css`
      right: 20px;
      left: auto;
    `}

    ${props =>
    props.theme.align === 'center' &&
    css`
      left: 50%;
    `}
`
