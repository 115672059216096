import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Icon20 } from 'builder/components/Icon'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

export const Container = styled.div<{
  isJobSearch?: boolean
  isOptimizedResume?: boolean
  isExploreCareers?: boolean
  background?: string
  isRioJobIcon?: boolean
}>`
  display: flex;
  padding: 0 4px;
  border-radius: 16px;
  position: relative;
  margin-bottom: ${props => (props.isRioJobIcon ? 8 : 56)}px;
  background: ${props => props.background};
  align-items: center;
  position: relative;
  min-height: 208px;
  cursor: pointer;
  transition: background-color 150ms ease-out;

  ${Media.Tablet`
    margin-bottom: 40px;
  `};

  ${Media.Phone`
    flex-direction:column;
    height: 300px;
  `};
`

export const ImageContainer = styled.div<{ bannerName?: string }>`
  max-width: 284px;
  flex: 0 0 284px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;

  ${Media.Tablet`
    max-width: 264px;
    flex: 0 0 264px;
    width: 100%;
    margin-top: 25px;
  `};

  ${Media.Phone`
    width: 100%;
    margin-bottom:24px;
    height: 100%;
    height:200px;
    margin-top: 0;
    justify-content: center;
    align-items:center
  `}

  ${props =>
    (props.bannerName === 'execute_a_job_search' ||
      props.bannerName === 'first_90_days_plan' ||
      props.bannerName === 'get_a_promotion') &&
    Media.Phone`
          align-items:flex-start`}
`

export const PlayImage = styled.img<{ maxHeight?: string }>`
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  ${props => props.maxHeight && `max-height: ${props.maxHeight};`}
`
export const Content = styled.div`
  flex: 1 1 auto;
  max-width: 592px;
  margin-left: 39px;
  padding: 24px 0;

  ${Media.Tablet`
    max-width: 380px;
  `};

  ${Media.Phone`
    width: 100%;
    margin-left:20px;
  `};
`
export const FlagTag = styled.div<{ isExploreCareers: boolean }>`
  ${Typography.Caption};
  background-color: ${props => (props.isExploreCareers ? Colors.Beige40 : Colors.Green40)};
  color: ${Colors.White};
  border-radius: 6px;
  padding: 2px 8px;
  display: inline-block;
  text-align: center;
  margin-bottom: 12px;
  transition: background-color 150ms ease-out, color 150ms ease-out;

  ${Media.Phone`
    position: absolute;
    background-color: ${Colors.Amber40};
    top: 16px;
    right:16px;
  `};
`

export const Title = styled.h2`
  ${Typography.S};
  ${FontWeights.Medium};
  color: ${props => props.color};
  margin-bottom: 8px;

  ${Media.Phone`
    position: absolute;
    left: 20px;
    bottom: 24px;
    margin-bottom: 0;
  `};
`
export const Text = styled.p`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${props => props.color};

  ${Media.Phone`
    width: 247px;
  `};
`
export const ActionContainer = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
  padding: 24px;
  width: 60px;
  ${Media.Phone`
   width: 100%;
    flex-direction: row;
    align-items: center;
    padding: 0 20px 24px 20px;
  `};
`

export const NextBannerButton = styled.div<{ background?: string }>`
  width: 32px;
  height: 32px;
  border-radius: 8px;
  border: none;
  background-color: ${props => props.background};
  margin-top: auto;
  transition: background-color 150ms ease-out;
  position: absolute;
  right: 24px;
  bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }

  ${Media.Phone`
   bottom: 20px;
   right: 20px;
  `};
`

export const RightArrow = styled(Icon20.ChevronDown)`
  transform: rotate(-90deg);
  color: ${props => props.color};
  transition: color 150ms ease-out;
`

export const TagContainer = styled.div<{
  background?: string
  textColor?: string
  isAIPowerd?: boolean
  isAdviceBanner?: boolean
  tagImg?: string | undefined
  isResumedistribution?: boolean
}>`
  ${props =>
    props.isResumedistribution
      ? `
    display: inline-flex;
    padding: var(--4XS, 2px) 8px;
    align-items: center;
    gap: 10px;
    height: 28px;
    width: max-content;
    border-radius: 6px;
    background: linear-gradient(94deg, #9ba1fb 0%, #4c89f3 47.53%, #54aff8 100%),
      rgba(195, 229, 254, 0.5);
    `
      : `
  
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${props.isAIPowerd ? '2px 6px 2px 2px' : '2px 8px'};
    background-color: ${!props.isAdviceBanner && props.color};
    border-radius: 6px;
    max-width: ${props.isAIPowerd ? 110 : 146}px;
    width: max-content;
    height: 24px;
    transition: color 150ms ease-out, background-color 150ms ease-out;  `}

  ${Media.Phone`
    position: absolute;
    top: 16px;
    left: 16px;
  `};
`

export const TagLine = styled.div<{
  textColor?: string
  isAIPowerd?: boolean
  isResumedistribution?: boolean
}>`
  ${props =>
    props.isResumedistribution ? `color: ${Colors.White};` : `color: ${props.textColor};`}
  ${Typography.Caption};
  ${FontWeights.Medium};
  margin-left: ${props => props.isAIPowerd && 18}px;
  flex: none;
  order: 0;
  flex-grow: 0;
  transition: color 150ms ease-out;
`
export const TagImageContainer = styled.div<{
  isAIPowerd?: boolean
}>`
  display: flex;
  align-items: center;
`

export const StarImage = styled.img<{
  isAdviceBanner?: boolean
}>`
  width: 25px;
  height: 24px;
  opacity: 0.6;
`

export const Frame = styled.img`
  width: 20px;
  height: 20px;
`
