import { useQuery } from '@tanstack/react-query'
import { baseClient } from 'builder/modules/apiClient'

export const useGetRecommendationAlert = () => {
  return useQuery(
    ['useGetRecommendationAlert'],
    async (): Promise<{ active: boolean }> => {
      const res = await baseClient.get('/job-alerts/v1/recommendation-subscription')
      return res.data
    },
    {
      staleTime: 3000,
    },
  )
}
