import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  actions,
  selectors,
  HOW_TO_NEGOTIATE_OFFER_URL,
  SHOULD_I_NEGOTIATE_OFFER_URL,
  REJECT_OFFER_URL,
} from 'builder/modules/offerAnalyzer'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { numberToDelimited } from 'builder/utils/numberToDelimited'
import { Button } from 'builder/components/Button'
import { useI18n } from 'builder/hooks/useI18n'
import { roundNumber } from 'builder/utils/roundNumber'
import Gauge from 'builder/components/GaugeComponent'
import NO_OFFER_IMAGE from './assets/images/noOffer.svg'

import {
  Card,
  MainTitle,
  Section,
  Content,
  NegotiateCard,
  NegotiateCardHeading,
  NegotiateCardText,
  SubTitle,
  Container,
  SectionInner,
  ButtonContainer,
  OfferDetailsContainer,
  NegotiateCardContainer,
  Box,
  BoxItem,
  StyledButton,
  SectionSubTitle,
  GaugeContainer,
  NoOfferDetailsContainer,
  IconsHolder,
  IconImage,
  NoOfferTitle,
  ButtonHolder,
  NoOfferDetailsLeftPanel,
  ContentHolder,
} from './styles'
import { SkeletonText, SkeletonValue } from './Skeletons'

const JobOfferStandards = () => {
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const analyzedOfferDetails = useTypedSelector(selectors.analyzedOfferDetails)
  const isLoaded = useTypedSelector(selectors.isAnalyzedDataLoaded)
  const isLoading = useTypedSelector(selectors.isAnalyzerLoading)

  const goBack = () => {
    dispatch(actions.resetOfferAnalyzer())
    navigate('/offer-analyzer')
  }

  return (
    <Container>
      <Content>
        {isLoaded && (
          <MainTitle>
            {i18n.t('builder.offer_analyzer_result.market_standards', {
              message: analyzedOfferDetails?.mainTitle,
            })}
          </MainTitle>
        )}
        {isLoading && (
          <MainTitle>
            {i18n.t('builder.offer_analyzer_result.offer_analyzer_calculating')}
          </MainTitle>
        )}
        <Card>
          {(isLoaded || isLoading) && (
            <>
              <GaugeContainer>
                <Gauge isLoading={isLoading} />
              </GaugeContainer>
              <OfferDetailsContainer>
                <ContentHolder>
                  {isLoading ? (
                    <>
                      <SubTitle>
                        <SkeletonValue />
                      </SubTitle>
                      <Box>
                        <SkeletonText />
                        <SkeletonText />
                      </Box>
                    </>
                  ) : (
                    <>
                      <SubTitle>
                        {i18n.t('builder.offer_analyzer_result.market_average', {
                          amount: numberToDelimited(
                            roundNumber(analyzedOfferDetails?.msgBelowSpeedometer, 0),
                          ),
                          message: analyzedOfferDetails?.flagMsgBelowSpeedometer,
                        })}
                      </SubTitle>
                      <Box>
                        {analyzedOfferDetails?.mainTitle === 'below' ? (
                          <BoxItem>
                            {i18n.t('builder.offer_analyzer_result.request_for', {
                              amount: numberToDelimited(
                                roundNumber(analyzedOfferDetails?.requestFor, 0),
                              ),
                            })}
                          </BoxItem>
                        ) : (
                          <BoxItem>{i18n.t('builder.offer_analyzer_result.no_request')}</BoxItem>
                        )}
                      </Box>
                    </>
                  )}
                </ContentHolder>
                <ButtonContainer>
                  <StyledButton theme="ghost" onClick={goBack}>
                    {i18n.t('builder.offer_analyzer_result.try_again_button')}
                  </StyledButton>
                </ButtonContainer>
              </OfferDetailsContainer>
            </>
          )}
          {!isLoaded && !isLoading && (
            <NoOfferDetailsContainer>
              <NoOfferDetailsLeftPanel>
                <IconsHolder>
                  <IconImage src={NO_OFFER_IMAGE} />
                </IconsHolder>
                <NoOfferTitle>
                  {i18n.t('builder.offer_analyzer_result.offer_not_calculated')}
                </NoOfferTitle>
              </NoOfferDetailsLeftPanel>
              <StyledButton theme="ghost" onClick={goBack}>
                {i18n.t('builder.offer_analyzer_result.try_again_button')}
              </StyledButton>
            </NoOfferDetailsContainer>
          )}
        </Card>
        <Section>
          <SectionInner>
            <SectionSubTitle>
              {i18n.t('builder.offer_analyzer_result.how_to_negotiate')}
            </SectionSubTitle>
            <NegotiateCardContainer>
              <NegotiateCard>
                <ContentHolder>
                  <NegotiateCardHeading>
                    {i18n.t('builder.offer_analyzer_result.how_to_negotiate')}
                  </NegotiateCardHeading>
                  <NegotiateCardText>
                    {i18n.t('builder.offer_analyzer_result.how_to_negotiate_details')}
                  </NegotiateCardText>
                </ContentHolder>
                <ButtonHolder>
                  <Button onClick={() => window.open(HOW_TO_NEGOTIATE_OFFER_URL, '_blank')}>
                    {i18n.t('builder.offer_analyzer_result.read_article_button')}
                  </Button>
                </ButtonHolder>
              </NegotiateCard>

              <NegotiateCard>
                <ContentHolder>
                  <NegotiateCardHeading>
                    {i18n.t('builder.offer_analyzer_result.should_i_negotiate')}
                  </NegotiateCardHeading>
                  <NegotiateCardText>
                    {i18n.t('builder.offer_analyzer_result.should_i_negotiate_details')}
                  </NegotiateCardText>
                </ContentHolder>
                <ButtonHolder>
                  <Button onClick={() => window.open(SHOULD_I_NEGOTIATE_OFFER_URL, '_blank')}>
                    {i18n.t('builder.offer_analyzer_result.read_article_button')}
                  </Button>
                </ButtonHolder>
              </NegotiateCard>

              <NegotiateCard>
                <ContentHolder>
                  <NegotiateCardHeading>
                    {i18n.t('builder.offer_analyzer_result.reject_offer')}
                  </NegotiateCardHeading>
                  <NegotiateCardText>
                    {i18n.t('builder.offer_analyzer_result.reject_offer_details')}
                  </NegotiateCardText>
                </ContentHolder>
                <ButtonHolder>
                  <Button onClick={() => window.open(REJECT_OFFER_URL, '_blank')}>
                    {i18n.t('builder.offer_analyzer_result.read_article_button')}
                  </Button>
                </ButtonHolder>
              </NegotiateCard>
            </NegotiateCardContainer>
          </SectionInner>
        </Section>
      </Content>
    </Container>
  )
}

export default JobOfferStandards
