import { Dispatch, SetStateAction } from 'react'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { ITabsAction } from '../../types'
import BookCoachButton from '../Tabs/BookCoachButton'
import * as Styled from './styles'

interface ITabletTabsProps {
  selectedIndex: number
  activeTabTitle: string
  setIsMenuVisible: Dispatch<SetStateAction<boolean>>
  isMenuVisible: boolean
  tabsActions: ITabsAction[]
  handleTabClick: (arg: number) => void
}

export const TabletTabs = ({
  selectedIndex,
  activeTabTitle,
  isMenuVisible,
  setIsMenuVisible,
  tabsActions,
  handleTabClick,
}: ITabletTabsProps) => {
  const { features } = useFeaturesConfig()
  const { isTablet, isPhone } = useMediaQueries()

  const onMenuOpen = () => {
    setIsMenuVisible(prev => !prev)
  }

  // Reusable title and arrow container
  const renderTabTitle = () => {
    return (
      <>
        <Styled.TabTitle onClick={onMenuOpen}>{activeTabTitle}</Styled.TabTitle>
        <Styled.IconContainer onClick={onMenuOpen}>
          <Styled.ArrowIcon isVisible={isMenuVisible} />
        </Styled.IconContainer>
      </>
    )
  }

  const renderMenu = () => {
    return (
      <Styled.MenuContainer isMenuVisible={isMenuVisible}>
        {tabsActions.map((action, index) => (
          <Styled.Item key={index} onClick={() => handleTabClick(action.index)}>
            <Styled.ItemContent>
              <Styled.ItemText selected={action.index === selectedIndex}>
                {action.text}
              </Styled.ItemText>
              <Styled.SelectedIcon selected={action.index === selectedIndex} />
            </Styled.ItemContent>
          </Styled.Item>
        ))}
      </Styled.MenuContainer>
    )
  }

  if (!isTablet || isPhone) return null

  return (
    <Styled.MainContainer>
      <Styled.TabsContainer>
        {renderTabTitle()}
        {renderMenu()}
      </Styled.TabsContainer>
      {features?.careerCoaching && <BookCoachButton />}
    </Styled.MainContainer>
  )
}
