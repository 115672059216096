import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useResumeTailoringBanner } from 'builder/components/DocumentEditor/ResumeTailoringBanner/useResumeTailoringBanner'
import { useI18n } from 'builder/hooks/useI18n'
import TargetImage from 'images/resume_optimizer/target_image.png'
import useImproveResumePanel from '../../hooks/useImproveResumePanel'
import { ArrowIcon, Banner, BannerImage, BannerTitle, ActionWrapper, ActionText } from './styles'

type Props = {
  isInsidePanel?: boolean
}

const TailorResumeBanner = ({ isInsidePanel = false }: Props) => {
  const { i18n } = useI18n()
  const animateContainer = useTypedSelector(
    state => state.resumeEditor.animateResumeOptimizerBanner,
  )
  const { bannerOption, onBannerClick } = useResumeTailoringBanner('builder')
  const { isPageScrolled } = useImproveResumePanel()
  if (!bannerOption) return null

  return (
    <Banner
      onClick={onBannerClick}
      animate={animateContainer}
      isPageScrolled={isPageScrolled}
      isInsidePanel={isInsidePanel}
    >
      <BannerImage src={TargetImage} alt="Target Image" />
      <BannerTitle>
        {i18n.t('builder.resume_editor.keywords.improve_resume_panel.tailor_resume_banner.title')}
      </BannerTitle>
      <ActionWrapper>
        <ActionText>
          {i18n.t(
            'builder.resume_editor.keywords.improve_resume_panel.tailor_resume_banner.button_text',
          )}
        </ActionText>
        <ArrowIcon />
      </ActionWrapper>
    </Banner>
  )
}

export default TailorResumeBanner
