import styled from 'styled-components'
import Sizes from 'builder/styles/sizes'
import Media from 'builder/styles/media'

export const WrapperButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${Sizes.L};
  gap: ${Sizes['2XS']};
  ${Media.Phone`
    margin-top: ${Sizes.M};
  `}
`
