import styled from 'styled-components'
import Icon24, { Icon20 } from 'builder/components/Icon'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  display: flex;
  background: radial-gradient(50% 50% at 50% 50%, ${Colors.Neutral80} 0%, ${Colors.Neutral90} 100%);
  position: relative;
  width: 100%;
  top: 0;
  bottom: 0;
  border-radius: var(--S, 16px);
  margin: 8px;
  overflow: hidden;

  ${Media.Phone`
      border-radius: unset;
      margin: 0;
  `};
`

export const ControlContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.White};
  height: 100%;
  width: 100%;
  ${Media.Hover`
    &:hover {  
        background-color: ${hexToRgba('#000000', 0.4)};
      }
  `};
`

export const CustomVideo = styled.video`
  width: 100%;
  height: auto;
`

export const PlayIcon = styled(Icon20.Play)`
  height: 96px;
  width: 96px;
  cursor: pointer;
`

export const PauseIcon = styled(Icon20.Pause)`
  height: 96px;
  width: 96px;
  cursor: pointer;
`

export const PlayPauseContainer = styled.div`
  padding: 0 28px;
`

export const BackIcon = styled(Icon24.ForwardIcon)`
  transform: rotate(180deg);
`

export const NextForwardButton = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 40px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%),
    rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(15px);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${Media.Hover`
    &:hover {
      background: linear-gradient(0deg, 
        rgba(255, 255, 255, 0.10) 0%, 
        rgba(255, 255, 255, 0.10) 100%)
        , rgba(0, 0, 0, 0.40);

    }
  `};
`

export const FooterContainer = styled.div`
  ${Typography.Tiny};
  ${FontWeights.Regular};
  color: ${Colors.White};
  position: absolute;
  bottom: 36px;
  padding: 0 32px;
  width: 100%;
`

export const TimelineTimeContainer = styled.div`
  display: flex;
  padding-bottom: 12px;
  align-items: center;
`

export const TimelineContainer = styled.div<{ $videoCount: number }>`
  display: flex;
  width: 100%;
  height: 8px;
  display: grid;
  grid-gap: 4px;
  grid-template-columns: repeat(${props => props.$videoCount}, 1fr);
`
export const FullTimeline = styled.div`
  width: 100%;
  border-radius: var(--4XS, 2px);
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  cursor: pointer;
`

export const PlayTimeline = styled.div<{ $progress: number }>`
  width: ${props => props.$progress}%;
  height: 8px;
  border-radius: var(--4XS, 2px) var(--Zero, 0px) var(--Zero, 0px) var(--4XS, 2px);
  border-right: var(--4XS, 2px) solid ${Colors.White};
  background: rgba(255, 255, 255, 0.5);
`

export const Time = styled.div`
  ${Typography.Caption};
  ${FontWeights.DemiBold};
  color: ${Colors.White};
  margin-left: 20px;
  white-space: nowrap;
  width: 74px;
  flex-shrink: 0;
`
export const MobileButtonContainer = styled.div`
  display: none;

  ${Media.Phone`
    display: flex;
    gap: 8px;  
  `};
`

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
`
