import { memo, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { actions, selectors } from 'builder/modules/events'
import { FetchStatuses } from 'builder/modules/constants'
import DayRangePicker from 'builder/components/DateRangePicker/DayRangePicker'
import Icon24 from 'builder/components/Icon'
import { i18n } from 'builder/utils/i18n'
import { selectors as userSelectors } from 'builder/modules/user'
import { useDebouncedEffect } from 'builder/hooks/useDebouncedEffect'
import { convertToLocalJSON } from '../utils'
import {
  BottomContainer,
  DateFilter,
  DateFilterContainer,
  DateFilterOpenIconContainer,
  DateFilterTitle,
  SearchFilter,
  SearchFilterContainer,
  SearchIcon,
  ShowMoreTitle,
  Spinner,
  SpinnerContainer,
  UpcomingEventsContainer,
  UpcomingFilterContainer,
  UpcomingHeader,
  UpcomingTitle,
} from './styles'
import { EventsList } from './EventsList'
import { EVENTS_PER_PAGE_SIZE } from './constants'

const PARAMS = { label: 'virtual_events', parameter: 'app' }

export const UpcomingEvents = memo(() => {
  const dispatch = useDispatch()
  const [isDateSelected, setIsDateSelected] = useState(false)
  const [dateRange, setDateRange] = useState<{ startAt: Date | null; endAt: Date | null }>({
    startAt: new Date(),
    endAt: null,
  })

  const user = useSelector(userSelectors.userData)
  const [searchQuery, setSearchQuery] = useState('')
  const [currPage, setCurrPage] = useState(1)
  const { data, status } = useSelector(selectors.allEvents)
  const { events, lastPage, page } = data
  const isShowMoreVisible = useMemo(
    () => page < lastPage && status === FetchStatuses.loaded,
    [lastPage, page, status],
  )
  const fetchEvents = (page: number, title: string, startAt: Date | null, endAt: Date | null) => {
    endAt?.setHours(23, 59)
    dispatch(
      actions.fetchAllEvents({
        start_at: startAt ? convertToLocalJSON(startAt) || '' : '',
        end_at: endAt ? convertToLocalJSON(endAt) || '' : '',
        title: title,
        page: `${page}`,
        email: encodeURIComponent(user?.email || ''),
        per_page: `${EVENTS_PER_PAGE_SIZE}`,
        include: 'registration-page',
      }),
    )
  }
  useDebouncedEffect(
    () => {
      fetchEvents(currPage, searchQuery, dateRange?.startAt, dateRange?.endAt)
      if (searchQuery) {
        trackInternalEvent('search_for_event_title', PARAMS)
      }
    },
    300,
    [dispatch, searchQuery],
  )
  const handleDateChange = (value: [Date | null, Date | null] | null) => {
    if (!value?.[0] && !value?.[1]) return
    setIsDateSelected(true)
    const newPage = 1
    setCurrPage(newPage)
    setDateRange({
      startAt: value?.[0] || new Date(),
      endAt: value?.[1] || null,
    })
    fetchEvents(newPage, searchQuery, value?.[0] || null, value?.[1] || null)

    trackInternalEvent('search_for_event_date', PARAMS)
  }

  const getDateDisplayValue = () => {
    const startStr = dateRange?.startAt?.toString().slice(4, 10) || ''
    const endStr = dateRange?.endAt?.toString().slice(4, 10) || ''
    return (
      startStr.replace(startStr.charAt(2), '') +
      (dateRange?.endAt ? ` - ${endStr.replace(endStr.charAt(2), '')}` : '') +
      ', ' +
      dateRange?.startAt?.toJSON().slice(0, 4)
    )
  }

  const handleShowMore = () => {
    const newPage = currPage + 1
    setCurrPage(newPage)
    fetchEvents(newPage, searchQuery, dateRange?.startAt, dateRange?.endAt)
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (status === FetchStatuses.loaded && events?.length === 0 && !searchQuery) {
        trackInternalEvent('fetch_no_events_error', PARAMS)
      }
    }, 5000)
    return () => clearTimeout(timeoutId)
  }, [events, searchQuery, status])

  useEffect(() => {
    if (events?.length === 0 && searchQuery) {
      trackInternalEvent('fetch_no_events', PARAMS)
    }
  }, [events])

  return (
    <UpcomingEventsContainer>
      <UpcomingHeader>
        <UpcomingTitle>{i18n.t('builder.my_events.upcoming_events.title')}</UpcomingTitle>
        <UpcomingFilterContainer>
          <SearchFilterContainer isSelected={isDateSelected}>
            <SearchIcon />
            <SearchFilter
              type="text"
              placeholder={i18n.t('builder.my_events.upcoming_events.search_placeholder')}
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
            />
          </SearchFilterContainer>
          <DateFilterContainer>
            <DayRangePicker
              calendarStartDay={1}
              customInput={
                <DateFilter>
                  <DateFilterTitle>
                    {isDateSelected
                      ? getDateDisplayValue()
                      : i18n.t('builder.my_events.upcoming_events.date')}
                  </DateFilterTitle>
                  <DateFilterOpenIconContainer>
                    <Icon24.ChevronDown />
                  </DateFilterOpenIconContainer>
                </DateFilter>
              }
              endDate={dateRange?.endAt ? new Date(dateRange.endAt) : null}
              startDate={dateRange?.startAt ? new Date(dateRange.startAt) : null}
              onChangeHandler={handleDateChange}
            />
          </DateFilterContainer>
        </UpcomingFilterContainer>
      </UpcomingHeader>
      <EventsList
        {...{ events }}
        isLoading={status === FetchStatuses.loading && currPage === 1}
        title={i18n.t('builder.my_events.upcoming_events.no_events')}
      />
      {(isShowMoreVisible || (status === FetchStatuses.loading && events?.length > 0)) && (
        <BottomContainer>
          {isShowMoreVisible ? (
            <ShowMoreTitle onClick={handleShowMore}>
              {i18n.t('builder.my_events.upcoming_events.show_more')}
            </ShowMoreTitle>
          ) : (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          )}
        </BottomContainer>
      )}
    </UpcomingEventsContainer>
  )
})
