export const getServiceName = (serviceName: string) => {
  switch (serviceName) {
    case 'Personal Branding':
      return 'personal-branding'
    case 'Job Strategy':
      return 'job-strategy'
    case 'Career Planning':
      return 'career-planning'
    case 'Interview Prep':
      return 'interview-prep'
    case 'Salary Negotiation':
      return 'salary-negotiaiton'
    case 'Coach Q & A':
      return 'coach-q-a'
  }
}
