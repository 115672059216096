import { UserDocument } from 'packages/types'
import Preview from 'builder/components/Preview'
import Icon from 'builder/components/Icon'
import { DocumentTypes } from 'builder/modules/constants'
import { useDocumentPreview } from '../../hooks/useDocumentPreview'
import {
  Content,
  ContentInner,
  PreviewBox,
  Pagination,
  PaginationPrev,
  PaginationCounter,
  PaginationNext,
} from './styles'

export const DocumentPreview = () => {
  const {
    currentPageNumber,
    totalPageNumber,
    hasPreviousPage,
    hasNextPage,
    handlePreviousPageClick,
    handleNextPageClick,
    resume,
    handlePaginationStateChange,
    dimensions,
    previewRef,
    previewBoxRef,
    contentRef,
  } = useDocumentPreview()

  return (
    <Content>
      <ContentInner ref={contentRef}>
        <PreviewBox ref={previewBoxRef}>
          {resume && (
            <Preview
              width={dimensions.width}
              height={dimensions.height}
              type={DocumentTypes.resume}
              document={resume as UserDocument}
              documentId={resume && resume.id}
              ref={previewRef}
              onPaginationChange={handlePaginationStateChange}
            />
          )}
        </PreviewBox>
      </ContentInner>
      <Pagination>
        <PaginationPrev onClick={handlePreviousPageClick} isDisabled={!hasPreviousPage}>
          <Icon.Chevron />
        </PaginationPrev>
        <PaginationCounter>
          {currentPageNumber} / {totalPageNumber}
        </PaginationCounter>
        <PaginationNext onClick={handleNextPageClick} isDisabled={!hasNextPage}>
          <Icon.Chevron />
        </PaginationNext>
      </Pagination>
    </Content>
  )
}
