import { useRef } from 'react'
import MenuComponent from 'builder/features/navigation/components/SideMenu/Menu'
import { useClickOutside } from 'builder/hooks/useClickOutside'
import { Close, Top, Wrapper } from './styles'

type Props = {
  onClose: () => void
  onClick: (path: string) => void
}
export const Menu = ({ onClose, onClick }: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  useClickOutside(ref, onClose)
  return (
    <Wrapper ref={ref}>
      <Top>
        <Close onClick={onClose} />
      </Top>
      <MenuComponent isOpen={true} width="236px" onClickLink={onClick} />
    </Wrapper>
  )
}
