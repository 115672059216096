import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Icon from 'builder/components/Icon'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'

export const ChevronRight = styled(Icon.Chevron)`
  opacity: 0;
  transition: right 0.25s;
  position: absolute;
  fill: ${Colors.Neutral40};
  top: 35px;
  right: 40px;

  ${Media.Tablet`
    opacity: 1;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
  `}

  ${Media.Phone`
    right: 20px;
  `}
`

export const Description = styled.p`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
  margin: 0;
  transition: color 0.25s;

  ${Media.Phone`
    display: none;
  `}
`
export const IconWrapper = styled.div`
  margin-bottom: 30px;

  svg {
    fill: ${Colors.Neutral50};
  }

  ${Media.Tablet`
    margin: 0 14px 0 0;
  `}
`

export const Panel = styled(Link)`
  border-radius: 16px;
  background: ${Colors.Neutral5};
  padding: 34px 32px 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &:hover {
    background-color: ${Colors.Blue10};
    ${Description} {
      /* color: ${Colors.Neutral90}; */
    }

    ${ChevronRight} {
      opacity: 1;
      fill: ${Colors.Blue50};
      right: 30px;
    }
    ${IconWrapper} {
      svg {
        fill: ${Colors.Blue50};
      }
    }
  }

  ${Media.Tablet`
    padding: 24px;
    flex-direction: row;
    align-items: center;
  `}

  & + & {
    margin-top: 20px;

    ${Media.Tablet`
      margin: 0 0 0 16px
    `}
  }

  ${Media.Phone`
    padding: 18px;
  `}
`

export const PanelButton = styled.div`
  border-radius: 16px;
  background: ${Colors.Neutral5};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 8px;
  padding: 18px 2.3px 18px 18px;
  height: 60px;
`

export const Title = styled.span`
  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`

export const ImageWrapper = styled.div`
  height: 40px;
  width: 59px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${require('builder/components/Navigation/components/WelcomeModalButton/assets/welcome_button_texture.svg')});
  background-position: top;
  background-size: cover;
  background-color: ${Colors.Blue10};
  border-radius: 8px;
  margin-right: 8px;
`

export const ImagePlay = styled.img`
  width: 28px;
  height: 28px;
  margin-top: 3.2px;
`
