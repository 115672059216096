import React from 'react'
import { useDispatch } from 'react-redux'
import { actions, selectors } from 'builder/modules/offerAnalyzer'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useI18n } from 'builder/hooks/useI18n'
import Checkbox from 'builder/components/Checkbox'
import HEALTH_CARE_ICON_URL from '../JobOffer/assets/images/health.svg'
import PARENTAL_LEAVE_ICON_URL from '../JobOffer/assets/images/leave.svg'
import PAID_TIME_ICON_URL from '../JobOffer/assets/images/paid-time.svg'
import { TextFieldWrapper } from '../JobOffer/styles'
import { CardContainer, Field, Box, BoxItem, IconImage } from './styles'
import BenefitsTermsCard from './BenefitsTermsCard'

const JobOfferBenefitsTerms = () => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const { hasParentalLeave, hasHealthCare, hasPto, noticeTerms, isNonCompetitive } =
    useTypedSelector(selectors.fields)

  return (
    <CardContainer>
      <BenefitsTermsCard
        checked={hasHealthCare}
        header={i18n.t('builder.offer_analyzer.healthcare')}
        description={i18n.t('builder.offer_analyzer.healthcare_details')}
        onChange={(e: React.FormEvent<HTMLInputElement>) => {
          dispatch(actions.updateFields({ hasHealthCare: e.currentTarget.checked }))
        }}
        icon={<IconImage src={HEALTH_CARE_ICON_URL} />}
      />

      <BenefitsTermsCard
        checked={hasParentalLeave}
        header={i18n.t('builder.offer_analyzer.parental_leave')}
        description={i18n.t('builder.offer_analyzer.parental_leave_details')}
        onChange={(e: React.FormEvent<HTMLInputElement>) =>
          dispatch(actions.updateFields({ hasParentalLeave: e.currentTarget.checked }))
        }
        icon={<IconImage src={PARENTAL_LEAVE_ICON_URL} />}
      />

      <BenefitsTermsCard
        checked={hasPto}
        header={i18n.t('builder.offer_analyzer.paid_time_off')}
        description={i18n.t('builder.offer_analyzer.paid_time_off_details')}
        onChange={(e: React.FormEvent<HTMLInputElement>) =>
          dispatch(actions.updateFields({ hasPto: e.currentTarget.checked }))
        }
        icon={<IconImage src={PAID_TIME_ICON_URL} />}
      />

      <Field>
        <TextFieldWrapper
          type="number"
          min={0}
          label="Notice Terms"
          name="noticeTerms"
          hint={i18n.t('builder.offer_analyzer.non_competitive_agreement_tooltip')}
          value={noticeTerms}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            dispatch(actions.updateFields({ noticeTerms: e.currentTarget.value }))
          }
        />
      </Field>

      <Box>
        <BoxItem>
          <Checkbox
            label={i18n.t('builder.offer_analyzer.non_competitive_agreement')}
            checked={isNonCompetitive}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              dispatch(actions.updateFields({ isNonCompetitive: e.currentTarget.checked }))
            }
          />
        </BoxItem>
      </Box>
    </CardContainer>
  )
}

export default JobOfferBenefitsTerms
