import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const ComingSoonTagContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 8px;
  gap: 10px;
  position: absolute;
  width: auto;
  height: 24px;
  left: 20px;
  top: 20px;
  background: ${Colors.Neutral40};
  border-radius: 6px;

  ${Media.Phone`
    left: 32px;
  `}
`

export const ComingSoonTag = styled.p`
  text-wrap: nowrap;
  ${FontWeights.Medium};
  ${Typography.Caption};
  color: ${Colors.White};
`
