import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Sizes from 'builder/styles/sizes'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  display: flex;
  border-radius: ${Sizes['2XS']};
  border: 1px solid ${Colors.Neutral15};
  background: ${Colors.White};
  &:hover {
    border: 1px solid ${Colors.Blue50};
  }
  padding: 16px;
  height: 96px;
  ${Media.Phone`
    flex-direction: column;
    height: 140px;
  `}

  & input + div {
    margin-right: 0;
  }
`

export const MobileContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 10px;
`
export const ContainerImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const EmailImg = styled.img`
  width: 49px;
  height: 49px;
  margin: 4px 12px 4px 4px;

  ${Media.Phone`
    width: 35.5px;
    height: 33.595px;
    margin: 0;
  `}
`

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 286px;
  margin-right: 24px;
  ${Media.Phone`
    margin-right: 0;
  `}
`

export const ContainerTooltip = styled.div`
  display: flex;
  margin: auto;
  ${Media.Phone`
    margin: auto 0;
  `}
`

export const ContainerTooltipItems = styled.div`
  display: flex;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: ${Colors.Blue50};
  margin-left: 24px;
  height: 18px;
  ${Media.Phone`
    margin-left: 0;
  `}
`

export const Title = styled.div`
  color: ${Colors.Neutral90};
  ${Typography.Body}
  ${FontWeights.Medium}
  ${Media.Phone`
    margin-bottom: 2px;
  `}
`
export const SubTitle = styled.div`
  color: ${Colors.Neutral50};
  ${Typography.Caption}
  ${FontWeights.Regular}
`
