import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import { Icon20 } from 'builder/components/Icon'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  margin: 100px auto 180px;
  max-width: 448px;
  gap: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${Media.Phone`
    margin-block: 48px 30px;
  `}
`

export const Illustration = styled.img`
  width: 100%;
`

export const Title = styled.h1`
  ${Typography.M};
  ${FontWeights.DemiBold};
  width: 100%;
  text-align: center;
`

export const Text = styled.p`
  color: ${Colors.Neutral50};
`

export const ArrowDown = styled(Icon20.ChevronDown)`
  color: ${Colors.Neutral50};
`

export const ReadMore = styled.div`
  gap: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;

  &:hover {
    ${Text} {
      color: ${Colors.Neutral90};
    }

    ${ArrowDown} {
      color: ${Colors.Neutral90};
    }
  }
`

export const ContentWrapper = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  ${Media.Phone`
    margin-bottom: 24px;
  `}
`

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`
