import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Button from 'builder/components/Button'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { actions, selectors } from 'builder/modules/careerPlanning'

import { Container, Header, Title, StaticText } from './styles'

export const Failed = () => {
  const dispatch = useDispatch()

  const step = useTypedSelector(selectors.step)
  const secondLevelId = useTypedSelector(selectors.secondLevelId)

  const handleClick = useCallback(() => {
    const params = {
      topLevelId: step,
      secondLevelId: secondLevelId,
    }

    dispatch(actions.fetchActionItems(params))
  }, [step, dispatch, secondLevelId])

  return (
    <Container>
      <Header>
        <Title>Something went wrong</Title>
        <StaticText>Please, try again</StaticText>
      </Header>
      <Button onClick={handleClick}>Try Again</Button>
    </Container>
  )
}
