import { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { CareerPathHeader } from 'builder/components/CareerPathHeader'
import {
  actions,
  CareerPathStep,
  ToneByGrade,
  selectors as careerPathSelectors,
  getCardById,
} from 'builder/modules/careerPath'
import { useVisibilityObserver } from 'builder/hooks/useVisibilityObserver'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { selectors as userSelectors } from 'builder/modules/user'
import { Banner } from './components/Banner'
import { CommonJobTitles } from './components/CommonJobTitles'
import { Description } from './components/Description'
import { Skills } from './components/Skills'
import { Certifications } from './components/Certifications'
import { StickedButton } from './components/StickedButton'
import { EducationChart } from './components/EducationChart'
import { JobSummary } from './JobSummary'

import * as Styles from './styles'

const LABEL = {
  label: 'career_pathways',
}

// TODO: translate
export const CareerPathConfirmation = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector(userSelectors.userData)
  const photoUrl = useTypedSelector(state => userSelectors.photoUrl(state, { placeholder: false }))
  const choiceId = useSelector(careerPathSelectors.choiceId)
  const isLoading = useSelector(careerPathSelectors.isSummaryLoading)
  const matrix = useSelector(careerPathSelectors.matrix)
  const location = useSelector(careerPathSelectors.location)
  const currentRole = useSelector(careerPathSelectors.occupation)
  const { commonJobTitles, description, hardSkills, softSkills, certifications, educationLevels } =
    useSelector(careerPathSelectors.summary)

  const { occupation: desiredRole, grade } = getCardById(choiceId, matrix) || {}
  const tone = grade && grade in ToneByGrade ? ToneByGrade[grade] : 'green'

  const handleOnBack = useCallback(() => {
    trackInternalEvent('click_career_details_page_back_button', LABEL)
    dispatch(actions.setStep(CareerPathStep.LEVEL_CHOICE))
    dispatch(actions.setPrevStep(CareerPathStep.CONFIRMATION))
  }, [dispatch])

  const { isTablet, isPhone } = useMediaQueries()

  const ref = useRef<HTMLDivElement | null>(null)
  const isInViewport = useVisibilityObserver<HTMLDivElement | null>(ref, { rootMargin: '-60px' })

  useEffect(() => {
    if (currentRole)
      trackInternalEvent('enter_career_details_page', {
        occupation: currentRole,
        ...LABEL,
      })
  }, [currentRole])

  useEffect(() => {
    // No sense to load confirm not chosen occupation
    if (!desiredRole || !grade) {
      handleOnBack()
    }
  }, [handleOnBack, desiredRole, grade])

  const handleOnConfirm = () => {
    if (desiredRole) {
      trackInternalEvent('confirm_career_goal', {
        current: currentRole,
        desired: desiredRole,
        location,
        ...LABEL,
      })
      dispatch(
        actions.confirmGoal({ location, currentTitle: currentRole, targetTitle: desiredRole }),
      )
      navigate('/', { replace: true })
    }
  }

  return (
    <Styles.Wrapper>
      <Styles.Container timeout={0}>
        <CareerPathHeader onBack={handleOnBack}>Confirm your path</CareerPathHeader>
        {desiredRole && grade && (
          <Banner
            photoUrl={photoUrl}
            fullName={user?.fullName}
            currentRole={currentRole}
            desiredRole={desiredRole}
            tone={tone}
            onConfirm={handleOnConfirm}
            ref={ref}
          />
        )}
        <Styles.ContentContainer>
          <Styles.LeftColumn>
            <CommonJobTitles isSkeleton={isLoading} titles={commonJobTitles} />
            <Description description={description} isSkeleton={isLoading} />
            {isTablet && (
              <Styles.TabletJobSummary>
                <JobSummary />
              </Styles.TabletJobSummary>
            )}
            <Skills softSkills={softSkills} hardSkills={hardSkills} isSkeleton={isLoading} />
            <EducationChart data={educationLevels} isSkeleton={isLoading} />
            <Certifications certifications={certifications} isSkeleton={isLoading} />
          </Styles.LeftColumn>
          {!isTablet && (
            <Styles.RightColumn>
              <JobSummary />
            </Styles.RightColumn>
          )}
        </Styles.ContentContainer>
      </Styles.Container>
      {isPhone && (
        <StickedButton isVisible={!isInViewport} tone={tone} onConfirm={handleOnConfirm} />
      )}
    </Styles.Wrapper>
  )
}
