import { all, put, call, takeLatest } from 'redux-saga/effects'
import differenceInHours from 'date-fns/differenceInHours'
import { FetchStatuses } from 'builder/modules/constants'
import { apiClient } from 'builder/modules/apiClient'
import { JobStatusModalStep, jobStatusOptions } from './constants'
import { actions } from './enterpriseModule'
import { JobStatus } from './types'

const HOURS_AFTER = 72

export function* fetchJobStatusSaga() {
  try {
    yield put(actions.setFetchJobStatus(FetchStatuses.loading))

    const { data } = yield call(apiClient.get, '/job-statuses')

    yield put(actions.setFetchJobStatus(FetchStatuses.loaded))

    // set logged in user as an enterprise user
    if (data.success) yield put(actions.setEnterpriseUser(true))

    // check for current job status
    const selectedJobStatus = data.jobStatuses.find((jobStatus: JobStatus) => jobStatus.selected)

    if (selectedJobStatus) {
      // get difference in hours between current time and last status updated time
      const lastUpdatedTimeInHours = differenceInHours(Date.now(), new Date(data.updatedAt))

      // show modal after every 3 days if previously status was updated
      if (lastUpdatedTimeInHours >= HOURS_AFTER) {
        if (selectedJobStatus.status !== 'hired') {
          yield put(actions.openJobStatusConfirmationModal())
        } else {
          yield put(actions.openJobStatusModal())
        }
        const options = [...jobStatusOptions]

        const updatedOptions = options.map(option => {
          if (selectedJobStatus?.status === 'working_my_cv' && option.id === 1) {
            return { ...option, checked: true }
          }
          if (selectedJobStatus?.status === 'act_job_Search' && option.id === 2) {
            return { ...option, checked: true }
          }
          if (selectedJobStatus?.status === 'apply_job' && option.id === 3) {
            return { ...option, checked: true }
          }
          if (selectedJobStatus?.status === 'current_interviewing' && option.id === 4) {
            return { ...option, checked: true }
          }
          if (selectedJobStatus?.status === 'hired' && option.id === 5) {
            return { ...option, checked: true }
          }
          return option
        })

        yield put(actions.setJobStatus(updatedOptions))

        switch (selectedJobStatus.status) {
          case 'working_my_cv':
            yield put(actions.setStep(JobStatusModalStep.WORKINGONCV))
            break
          case 'act_job_Search':
            yield put(actions.setStep(JobStatusModalStep.JOBSEARCHING))
            break
          case 'apply_job':
            yield put(actions.setStep(JobStatusModalStep.APPLYINGJOBS))
            break
          case 'current_interviewing':
            yield put(actions.setStep(JobStatusModalStep.INTERVIEWING))
            break
          case 'hired':
            yield put(actions.setStep(JobStatusModalStep.HIRED))
            break
        }
      }
    } else {
      yield put(actions.openJobStatusModal())
    }
  } catch (error) {
    yield put(actions.setFetchJobStatus(FetchStatuses.failed))
  }
}

export function* sendUpdatedJobStatusSaga({
  payload,
}: ReturnType<typeof actions.sendUpdatedJobStatus>) {
  const selectedJobStatus = payload.find(jobStatus => jobStatus.checked)

  let jobStatusToSend

  if (selectedJobStatus?.id === 1) {
    jobStatusToSend = { status: 'working_my_cv', selected: true }
  } else if (selectedJobStatus?.id === 2) {
    jobStatusToSend = { status: 'act_job_Search', selected: true }
  } else if (selectedJobStatus?.id === 3) {
    jobStatusToSend = { status: 'apply_job', selected: true }
  } else if (selectedJobStatus?.id === 4) {
    jobStatusToSend = { status: 'current_interviewing', selected: true }
  } else if (selectedJobStatus?.id === 5) {
    jobStatusToSend = { status: 'hired', selected: true }
  }
  try {
    const params = {
      jobStatus: jobStatusToSend,
    }

    yield call(apiClient.post, '/job-statuses/update-job-status', { ...params })
  } catch (error) {}
}

/** Bind side-effect handlers */
export const sagas = function* saga() {
  yield all([takeLatest(actions.fetchJobStatus, fetchJobStatusSaga)])
  yield all([takeLatest(actions.sendUpdatedJobStatus, sendUpdatedJobStatusSaga)])
}
