import { Component } from 'react'
import PropTypes from 'prop-types'
import { i18n as I18n } from 'builder/utils/i18n'
import { SignInLayout } from 'builder/components/SignInLayout'
import Section from 'builder/components/SignInSection'
import * as SimpleForm from 'builder/components/SimpleForm'
import Message from 'builder/components/SignInMessage'
import { TextField } from 'builder/components/TextField'
import { withRouter } from 'builder/hocs/withRouter'

import savedImage from 'images/builder/sign-in/saved.svg'
import { navigate } from 'builder/modules/navigate'

class CreatePassword extends Component {
  state = {
    password: '',
    confirmPassword: '',
    summitError: null,
  }

  static propTypes = {
    save: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    error: PropTypes.string,
    isSaving: PropTypes.bool,
    isSaved: PropTypes.bool,
  }

  componentDidUpdate(prevProps) {
    const { isSaved } = this.props

    if (isSaved && isSaved !== prevProps.isSaved) {
      setTimeout(() => navigate(''), 3000)
    }
  }

  handleInputChange = event => {
    const { name, value } = event.target
    this.setState({
      [name]: value,
    })
  }

  handleSubmit = () => {
    const { password, confirmPassword } = this.state
    const { token } = this.props
    if (!password) return

    if (password !== confirmPassword) {
      return this.setState({
        submitError: I18n.t('builder.create_password.password_doesnt_match'),
      })
    }

    this.setState({ submitError: null })
    this.props.save({ token, password })
  }

  render() {
    const { password, confirmPassword, submitError } = this.state
    const { error, isSaved, isSaving } = this.props

    return (
      <SignInLayout>
        {!isSaved && (
          <Section
            title={I18n.t('builder.create_password.title')}
            description={I18n.t('builder.create_password.description')}
          >
            <SimpleForm.Form
              onSubmit={this.handleSubmit}
              isDisabled={isSaving || password.length === 0}
            >
              <SimpleForm.Row>
                <TextField
                  type="password"
                  name="password"
                  label={I18n.t('builder.create_password.new_password_label')}
                  value={password}
                  onChange={this.handleInputChange}
                />
              </SimpleForm.Row>
              <SimpleForm.Row>
                <TextField
                  type="password"
                  name="confirmPassword"
                  label={I18n.t('builder.create_password.confirm_password_label')}
                  value={confirmPassword}
                  onChange={this.handleInputChange}
                  error={submitError || error}
                />
              </SimpleForm.Row>
            </SimpleForm.Form>
          </Section>
        )}

        {isSaved && (
          <Message image={savedImage} title={I18n.t('builder.create_password.new_password_saved')}>
            {I18n.t('builder.create_password.auto_redirected')}
            <br />
            {I18n.t('builder.create_password.if_not')}{' '}
            <a href="/app">{I18n.t('builder.create_password.click_link')}</a>
          </Message>
        )}
      </SignInLayout>
    )
  }
}

export default withRouter(CreatePassword)
