import { trackInternalEvent } from '@rio/tracking'
import { IEvent } from 'builder/modules/events'
import { i18n } from 'builder/utils/i18n'
import EventCard from '../EventCard'
import { COLOR_TONES_ARR } from './constants'
import { EventCardContainer, MyEventsContainer } from './styles'
import { NoEvents } from './NoEvents'

interface IEventsListProps {
  events: IEvent[]
  isLoading: boolean
  title?: string
}

export const EventsList = ({ events, isLoading, title }: IEventsListProps) => {
  const handleClick = async (url: string) => {
    trackInternalEvent('click_event', {
      label: 'virtual_events',
      parameter: 'app',
    })
    window.location.href = `/api/premier-virtual/events/magic-link?redirect_url=${url}`
  }
  return (
    <MyEventsContainer>
      {!isLoading && events.length > 0 ? (
        events.map((ev, i) => (
          <EventCardContainer key={ev.id} onClick={() => handleClick(ev.url)}>
            <EventCard
              colorTone={COLOR_TONES_ARR[i % 7]}
              dateTime={
                new Date(ev.startAt) < new Date() ? new Date(ev.endAt) : new Date(ev.startAt)
              }
              isStarted={new Date(ev.startAt) < new Date()}
              imgUrl={ev.registration_image || ev.banner}
              location={ev.location}
              title={ev.title}
              id={ev.id}
            />
          </EventCardContainer>
        ))
      ) : (
        <NoEvents title={isLoading ? '' : title || i18n.t('builder.my_events.no_events')} />
      )}
    </MyEventsContainer>
  )
}
