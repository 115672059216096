import { Link } from 'react-router-dom'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as userSelectors } from 'builder/modules/user'
import { i18n as I18n } from 'builder/utils/i18n'
import { useConfig } from 'builder/hooks/useConfig'
import SubscriptionCancelledIconOld from '../../../../../app/assets/images/subscription_cancellation/subscription_cancelled.png'
import SubscriptionCancelledIcon from '../../../../../app/assets/images/subscription_cancellation/new-subscription-cancelled.png'
import { CancelledIcon, DashboardButton, Description, Title } from './styles'

const SubscriptionCancelledView = () => {
  const config = useConfig()
  const user = useTypedSelector(userSelectors.userData)
  const isSubscriptionV4Enabled = config?.features.subCancellationV4 || false

  const getImage = () => {
    return isSubscriptionV4Enabled ? SubscriptionCancelledIcon : SubscriptionCancelledIconOld
  }

  const subscriptionLastDate = new Date(user?.billingInfo.premiumEndsAt || '')
  const month = I18n.t(`builder.date.month.${subscriptionLastDate.getMonth() + 1}`)
  const year = subscriptionLastDate.getFullYear()
  const date = subscriptionLastDate.getDate()

  return (
    <>
      <CancelledIcon src={getImage()} />
      <Title>
        {isSubscriptionV4Enabled
          ? I18n.t('builder.subscription_cancellation_v4.cancelled_view.subscription_canceled')
          : I18n.t('builder.subscription_cancellation.cancelled_view.subscription_canceled')}
      </Title>
      <Description isCancelled={!isSubscriptionV4Enabled}>
        {isSubscriptionV4Enabled ? (
          <>
            {I18n.t('builder.subscription_cancellation_v4.cancelled_view.description_line_one')}
            <span className="important">{` ${month} ${date}, ${year}. `}</span>
            {I18n.t('builder.subscription_cancellation_v4.cancelled_view.description_line_two')}
          </>
        ) : (
          I18n.t('builder.subscription_cancellation.cancelled_view.description')
        )}
      </Description>
      <DashboardButton as={Link} to="/">
        {I18n.t('builder.subscription_cancellation.cancelled_view.back')}
      </DashboardButton>
    </>
  )
}

export default SubscriptionCancelledView
