import { Ref, memo, useCallback, useLayoutEffect, useRef, useState } from 'react'
import throttle from 'lodash/throttle'
import Preview from 'builder/components/Preview'
import { DocumentTypes } from 'builder/modules/constants'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import { useCoverLetterPreviewDocument } from '../../../hooks/useCoverLetterPreviewDocument'
import * as Styled from './styles'
import image from './images/toolbar.svg'

export const AiCoverLetterPreview = memo(() => {
  const document = useCoverLetterPreviewDocument()
  const [widthPx, setWidthPx] = useState(0)
  const ref: Ref<HTMLDivElement> = useRef(null)

  const onWidthUpdate = useCallback(
    throttle(
      () => {
        if (ref.current) {
          setWidthPx(ref.current.clientWidth)
        }
      },
      200,
      { leading: true, trailing: true },
    ),
    [],
  )

  useEffectOnMount(() => {
    onWidthUpdate()
  })

  useLayoutEffect(() => {
    window.addEventListener('resize', onWidthUpdate)
    return () => window.removeEventListener('resize', onWidthUpdate)
  }, [onWidthUpdate])

  return (
    document && (
      <Styled.Container>
        <Styled.PreviewContainer ref={ref}>
          <Preview
            documentId={0}
            document={document}
            type={DocumentTypes.coverLetter}
            width={widthPx}
            height={500}
            onPaginationChange={() => {}}
          />
        </Styled.PreviewContainer>
        <Styled.Toolbar src={image} alt="Toolbar" />
      </Styled.Container>
    )
  )
})
