export const Error3 = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 36C8.059 36 0 27.941 0 18C0 8.059 8.059 0 18 0C27.941 0 36 8.059 36 18C36 27.941 27.941 36 18 36Z"
      fill="#DA0C22"
    />
    <path
      d="M11 11L18.25 18.25M25.5 25.5L18.25 18.25M18.25 18.25L25.5 11M18.25 18.25L11 25.5"
      stroke="white"
      strokeWidth="2"
    />
  </svg>
)
