import * as Styled from './styles'

type Props = {
  isFreeUser?: boolean
  title?: string
}

export const CareerGoalHeader = ({ title }: Props) => {
  return (
    <Styled.Header>
      <Styled.HeaderContent>
        <Styled.Title>{title}</Styled.Title>
      </Styled.HeaderContent>
    </Styled.Header>
  )
}
