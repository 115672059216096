import { memo, useRef } from 'react'
import { InputChangeEvent } from 'builder/modules/ui'
import { useClickOutside } from 'builder/hooks/useClickOutside'
import { useEscapeHandler } from 'builder/hooks/useEscapeHandler'
import { useI18n } from 'builder/hooks/useI18n'
import { TooltipV2 } from 'builder/components/Tooltip'
import {
  Container,
  Jobtitle,
  CompanyName,
  TickIcon,
  StatusText,
  RefreshDetailsIcon,
  StatusWrapper,
  CloseIcon,
  JoiningText,
} from '../styles'
import { FormState } from './types'

type EditJobDetailsProps = {
  recommendedJobTitle?: string
  employerName?: string
  onRefresh: () => void
  onJobTitleChange: (event: InputChangeEvent) => void
  onCompanyNameChange: (event: InputChangeEvent) => void
  formState: FormState
  onSaveJobDetails: () => void
  onJobDetailsFocus: () => void
  onClose: () => void
  isRefresh: boolean
  isJobDetailsFocused: boolean
}

const NormalEditView = memo(
  ({
    recommendedJobTitle,
    employerName,
    onRefresh,
    onJobTitleChange,
    onCompanyNameChange,
    formState,
    onSaveJobDetails,
    isRefresh,
    onJobDetailsFocus,
    isJobDetailsFocused,
    onClose,
  }: EditJobDetailsProps) => {
    const menuRef = useRef<HTMLDivElement | null>(null)
    const { i18n } = useI18n()
    useClickOutside(menuRef, onClose)
    useEscapeHandler(onClose)
    return (
      <Container ref={menuRef}>
        <Jobtitle
          inputTheme="white"
          active={true}
          value={recommendedJobTitle}
          onChange={onJobTitleChange}
          onFocus={onJobDetailsFocus}
          isJobDetailsFocused={isJobDetailsFocused}
        />
        <JoiningText>{i18n.t('builder.resume_editor.labels.at')}</JoiningText>
        <CompanyName
          inputTheme="white"
          active={true}
          value={employerName}
          onChange={onCompanyNameChange}
          onFocus={onJobDetailsFocus}
          isJobDetailsFocused={isJobDetailsFocused}
        />

        <StatusWrapper onClick={onSaveJobDetails} formState={formState}>
          {formState === FormState.NOT_SAVED ? <CloseIcon /> : <TickIcon />}
          <StatusText formState={formState}>
            {i18n.t(`builder.resume_optimizer.edit_job_details.${formState}`)}
          </StatusText>
        </StatusWrapper>
        {formState === FormState.SAVE && (
          <TooltipV2
            value={i18n.t('builder.resume_optimizer.edit_job_details.revert_changes')}
            position="top"
          >
            <RefreshDetailsIcon isRefresh={isRefresh} onClick={onRefresh} />
          </TooltipV2>
        )}
      </Container>
    )
  },
)
export default NormalEditView
