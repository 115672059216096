import { memo } from 'react'
import { i18n as I18n } from 'builder/utils/i18n'
import processingAnimationGIF from '../../../../../../app/assets/images/builder/ai-resume/processing-animation.gif'
import {
  ProcessingContainer,
  ProcessingText,
  ProcessingTitle,
  ProcessingDescription,
  ProcessingGIF,
} from './styles'

export const ProcessAnimation = memo(() => {
  return (
    <ProcessingContainer>
      <ProcessingGIF src={processingAnimationGIF} />
      <ProcessingText>
        <ProcessingTitle>
          {I18n.t('builder.ai_resume_draft.animation_screen.title')}
        </ProcessingTitle>
        <ProcessingDescription>
          {I18n.t('builder.ai_resume_draft.animation_screen.description')}
        </ProcessingDescription>
      </ProcessingText>
    </ProcessingContainer>
  )
})
