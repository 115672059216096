import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Sizes from 'builder/styles/sizes'

export const Container = styled.div<{ $isGood: boolean }>`
  position: relative;
  stroke: ${Colors.Orange40};
  color: ${Colors.Orange50};
  background-color: ${Colors.Orange10};
  border-radius: 50%;

  ${props =>
    props.$isGood &&
    css`
      stroke: ${Colors.Green40};
      color: ${Colors.Green50};
      background-color: ${Colors.Green10};
    `}
`

export const Svg = styled.svg`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
`

export const TextContainer = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  display: flex;
  align-items: flex-start;
  gap: ${Sizes['4XS']};
  padding-left: 10%;
`

export const Text = styled.span`
  ${Typography.S};
  ${FontWeights.Medium};
`
export const Percent = styled.span`
  ${Typography.Body};
  ${FontWeights.DemiBold};
`
