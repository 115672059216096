import { NextButton, RightArrowIcon } from './style'

interface Props {
  onClick: () => void
  children: React.ReactChild
}

export const NextControlButton = ({ children, onClick }: Props) => (
  <NextButton onClick={onClick}>
    {children}
    <RightArrowIcon />
  </NextButton>
)
