import { useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import Foldable from 'builder/components/Foldable'
import { ButtonSize } from 'builder/components/Button'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'

import Coaches from './assets/imgs/coaches.svg'
import {
  Img,
  Text,
  Header,
  SubText,
  Content,
  Container,
  TabletBox,
  StyledIcon,
  StyledButton,
  InnerContent,
  TextContainer,
  TabletContent,
  TabletContainer,
  CollapsedContent,
} from './styles'

type Props = {
  feature?: string
  trackingLabel: string
}

const text = 'You are 78% more likely to achieve your goals working with a coach'
const subText = 'Engage a professional coach and get results!'

const CareerPlanningFindCoach = ({ feature, trackingLabel }: Props) => {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)

  const { isTablet } = useMediaQueries()
  const isPhone = window.innerWidth < 768
  const isCustomTablet = window.innerWidth >= 768 && window.innerWidth <= 1023

  const isFoldable = isPhone && feature === 'lloyd'
  const isCollapsed = !isOpen && feature === 'lloyd' && isTablet

  const handleClick = useCallback(() => {
    navigate('/career-coaching')
    trackInternalEvent('click_book_a_coach', { label: trackingLabel })
  }, [navigate, trackingLabel])

  if (isCustomTablet) {
    return (
      <TabletContainer>
        <Img src={Coaches} />
        <TabletContent>
          <TabletBox>
            <Text>{text}</Text>
            <SubText>{subText}</SubText>
          </TabletBox>
        </TabletContent>
        <StyledButton size={ButtonSize.small} onClick={handleClick}>
          Book a Coach
        </StyledButton>
      </TabletContainer>
    )
  }

  return (
    <Container feature={feature}>
      <Content>
        <Header>
          {isFoldable && <StyledIcon $isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} />}
        </Header>
        {isCollapsed && (
          <CollapsedContent>
            <Img src={Coaches} />
            <Text>Find a coach</Text>
          </CollapsedContent>
        )}
        {isFoldable ? (
          <Foldable isOpen={isOpen}>
            <InnerContent>
              <Img src={Coaches} />
              <TextContainer>
                <Text>{text}</Text>
                <SubText>{subText}</SubText>
              </TextContainer>
            </InnerContent>
            <StyledButton onClick={handleClick} size={ButtonSize.small}>
              Book a Coach
            </StyledButton>
          </Foldable>
        ) : (
          <>
            <Img src={Coaches} />
            <TextContainer>
              <Text>{text}</Text>
              <SubText>{subText}</SubText>
            </TextContainer>
            <StyledButton onClick={handleClick} size={ButtonSize.small}>
              Book a Coach
            </StyledButton>
          </>
        )}
      </Content>
    </Container>
  )
}

export default CareerPlanningFindCoach
