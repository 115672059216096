import EditorField from 'builder/components/EditorField'
import Select from 'builder/components/Select'
import { useI18n } from 'builder/hooks/useI18n'
import { actions } from 'builder/modules/resumeEditor'
import { useCallback, useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GenderValues } from '../constants/constants'

export function Gender(props) {
  const gender = useSelector(state => state.resumeEditor.resume.gender)
  const [localGenderValue, setLocalGenderValue] = useState(gender)
  const dispatch = useDispatch()
  const { updateSimpleField } = actions
  const { i18n } = useI18n()
  const genderMapRef = useRef(
    new Map([
      ['male', GenderValues.MALE],
      ['female', GenderValues.FEMALE],
      ['other', GenderValues.OTHER],
    ]),
  )
  const options = [
    { id: GenderValues.MALE, name: i18n.t('builder.resume_editor.gender_options.male') },
    { id: GenderValues.FEMALE, name: i18n.t('builder.resume_editor.gender_options.female') },
    { id: GenderValues.OTHER, name: i18n.t('builder.resume_editor.gender_options.other') },
  ]
  useEffect(() => {
    setLocalGenderValue(genderMapRef.current.get(gender))
  }, [gender])

  const handleSelect = useCallback(
    value => {
      dispatch(
        updateSimpleField({
          name: 'gender',
          value: value.toString().toLowerCase(),
          debounce: true,
        }),
      )
    },
    [dispatch, updateSimpleField],
  )

  return (
    <>
      {/* Gender */}

      <EditorField>
        <Select
          label={i18n.t('builder.resume_editor.gender')}
          selected={localGenderValue}
          onSelect={handleSelect}
          {...{ options }}
        />
      </EditorField>
    </>
  )
}
