import Button, { ButtonTheme } from 'builder/components/Button'
import { Buttons } from '../../styles'

type Props = {
  handleOk: () => void
  handleClose: () => void
}

const Footer = ({ handleOk, handleClose }: Props) => {
  return (
    <Buttons>
      <Button onClick={handleClose} theme={ButtonTheme.ghost}>
        Cancel
      </Button>
      <Button onClick={handleOk}>Ok</Button>
    </Buttons>
  )
}

export default Footer
