import { i18n } from 'builder/utils/i18n'
import { SectionNames } from 'builder/modules/resumeEditor'
import FocusManager from 'builder/services/FocusManager'
import { CheckFn } from '../types'

export const checkJobTitle: CheckFn = ({ position }) => {
  const isComplete = typeof position === 'string' && position.length > 0

  return {
    sectionId: SectionNames.details,
    progress: isComplete ? 1 : 0,
    id: 'no_job_title',
    title: i18n.t('builder.resume_tuner.tips.no_job_title.title'),
    description: i18n.t('builder.resume_tuner.tips.no_job_title.description'),
    subject: i18n.t('builder.resume_tuner.tips.no_job_title.subject'),
    label: i18n.t('builder.resume_tuner.issues.no_job_title'),
    action: () => FocusManager.focus('position'),
    actionText: i18n.t('builder.resume_tuner.tips.no_job_title.action_text'),
  }
}
