export const INITIALCAREERLEVELS = [
  {
    name: 'Personal Branding',
    description:
      'Approach marketing yourself through content and communication using resumes, LinkedIn, and social media.',
    checked: false,
  },
  {
    name: 'Job Search Strategy',
    description:
      'Establish a strategy to uncover hidden jobs, network, & navigate job boards & recruiters to land your dream job.',
    checked: false,
  },
  {
    name: 'Career Transition',
    description:
      'Whether it’s time for a career change or go after that promotion, lean on your coach to design a plan.',
    checked: false,
  },
  {
    name: 'Interview Prep',
    description:
      'Get the help you need to master the art of interviewing in all formats from preparation to follow-up.',
    checked: false,
  },
  {
    name: 'Salary Negotiation',
    description: 'Learn to negotiate like a champion to maximize your earning potential.',
    checked: false,
  },
  {
    name: 'Workplace Challenges',
    description:
      'Find guidance with workplace challenges like burnout, imposter syndrome, & lack of support from management.',
    checked: false,
  },
]
