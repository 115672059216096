import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { Icon20 } from 'builder/components/Icon'
import Media from 'builder/styles/media'

export const IconDefault = styled(Icon20.Hint2)`
  transition: opacity 0.2s ease-out;
`

export const IconHovered = styled(Icon20.Tips)`
  transition: opacity 0.2s ease-out;
  position: absolute;
  left: 0;
  top: 0;
`

export const IconWrapper = styled.div`
  display: flex;
  color: ${Colors.Blue50};
  cursor: pointer;
  position: relative;

  ${IconDefault} {
    opacity: 1;
  }

  ${IconHovered} {
    opacity: 0;
  }

  ${Media.Hover`
    &:hover {
      ${IconDefault} {
        opacity: 0;
      }

      ${IconHovered} {
        opacity: 1;
      }
    }
  `}
`
