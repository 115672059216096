import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Icon24 from 'builder/components/Icon'
import { rotation } from 'builder/styles/keyframes'
import Button from 'builder/components/Button'

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 304px;

  ${Media.Phone`
    height: 363px;
    width: 100%
  `};

  ${Media.Tablet`
    width: 470px;
  `};

  @media (max-width: 280px) {
    height: 380px;
  }
`

export const CardContainer = styled.div`
  width: 293px;
  margin-right: 102px;
  height: 293px;

  ${Media.Tablet`
    height: 284px;
    position:absolute;
    margin-top: 64px;
    right: 0px;
    margin-right:5px;
  `}

  ${Media.Phone`
    width: 196.33px;
    height: 176.33px;
    top: 6.44px;
    margin: 0;
    left: 25%;
    transform: translate(-30%);
  `}

  @media (max-width: 280px) {
    left: 27%;
    transform: translate(-30%);
  }
`

export const ResumeContainer = styled.img`
  bottom: 69px;
  width: 235px;
  height: 275px;
  overflow: hidden;
  margin-top: 43px;

  ${Media.Tablet`
    height: 250px;
    margin-top: 15px ;
  `}

  ${Media.Phone`
    margin-top: 3px;
    height: 168px;
    width: 157px;
    padding-top: 10.43px;
    border-radius: 0px;
  `}
`

export const ResumePreviewContainer = styled.div`
  position: relative;
  width: 100%;
  flex-shrink: 0;
  border-radius: 16px 16px 0 0;
  height: 293px;
  overflow: hidden;

  ${Media.Tablet`
    height: 250px;
    margin-top: -20px ;

  `}

  ${Media.Phone`
    margin-top: -20px ;
    margin-left: -15px ;
    height: 188px;
    width: 207px;
    border-radius: 0px;
  `}
`

export const ResumeGradient = styled.div`
  width: 293px;
  height: 101.2px;
  position: absolute;
  top: 203px;
  margin-left: -10px;
  background: linear-gradient(
    to bottom,
    ${hexToRgba(Colors.Neutral5, 0)},
    ${Colors.Neutral5} 100%,
    ${Colors.Neutral5} 0%
  );

  ${Media.Tablet`
    margin-top: -53px;
  `};

  ${Media.Phone`
    top: 90px;
    height: 81.2px;
    width: 196.33px;
    margin: 0;
    left: 40%;
    transform: translate(-50%);
  `}
`

export const Spinner = styled(Icon24.Spinner)`
  color: ${Colors.Indigo80};
  animation: ${rotation} 0.5s infinite linear;
  margin-left: -5px;
  margin-top: 2px;
  height: 25px;
  width: 25px;
`

export const TickMarkContainer = styled.img`
  width: 20px;
  height: 20px;
  margin-left: -2px;
  margin-top: 1px;
  margin-right: 2px;
`

export const PrefillLoader = styled.button<{ checked: boolean }>`
  display: flex;
  align-items: center;
  height: 28px;
  padding-right: ${props => props.checked && 14}px;
  margin-left: ${props => (!props.checked ? 80 : 52)}px;
  background: ${props => (props.checked ? Colors.White : Colors.Green10)};
  border-radius: 32px;
  box-shadow: 0px 0px 1px rgba(15, 56, 113, 0.32), 0px 1px 2px rgba(15, 56, 113, 0.12),
    0px 2px 4px rgba(15, 56, 113, 0.08);
  border: none;
  ${Typography.Tiny};
  ${FontWeights.Regular};
  color: ${props => (props.checked ? Colors.Neutral90 : Colors.Green50)};

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  ${props => Media.Tablet`
  margin-left: ${!props.checked ? 60 : 32}px;
  `}

  ${props => Media.Phone`
    margin-top: 13px;
    margin-left: ${props.checked ? 70 : 99}px;
  `}

  @media (max-width: 280px) {
    margin-left: ${props => (props.checked ? 95 : 124)}px;
  }
`

export const ReviewLoader = styled.button<{ status: boolean }>`
  display: flex;
  align-items: center;
  height: 28px;
  margin-top: -5px;
  margin-left: ${props => (!props.status ? 80 : 52)}px;
  background: ${props => (props.status ? Colors.White : Colors.Green10)};
  border-radius: 32px;
  box-shadow: 0px 0px 1px rgba(15, 56, 113, 0.32), 0px 1px 2px rgba(15, 56, 113, 0.12),
    0px 2px 4px rgba(15, 56, 113, 0.08);
  border: none;

  ${Typography.Tiny};
  ${FontWeights.Regular};
  color: ${props => (props.status ? Colors.Neutral90 : Colors.Green50)};

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  ${props => Media.Tablet`
  margin-left: ${!props.status ? 60 : 32}px;
  `}

  ${props => Media.Phone`
    margin-left: ${props.status ? 70 : 99}px;
  `}

  @media (max-width: 280px) {
    margin-left: ${props => (props.status ? 95 : 124)}px;
  }
`

export const BannerLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  margin-right: 32px;

  ${Media.Phone`
    margin-right: 0;
    margin-bottom: 20px;
  `};
`

export const CritiqueTitle = styled.div<{ isResume: number | null }>`
  position: absolute;
  height: 36px;
  top: ${props => (props.isResume ? 56 : 90)}px;
  color: ${Colors.Neutral90};
  ${Typography.M};
  ${FontWeights.DemiBold};

  ${props => Media.Tablet`
    left: 20px;
    top: ${props.isResume ? 56 : 90}px;
  `};

  ${Media.Phone`
    ${Typography.S};
    ${FontWeights.DemiBold};
    top: 185px;
    width: 100%;
    padding-left:10px;
    margin: 0px;
    left: 50%;
    transform: translate(-50%);
    padding-left: 25px;
  `};
`
export const CritiqueText = styled.div<{ isResume: number | null }>`
  position: absolute;
  width: 352px;
  height: 72px;
  top: ${props => (props.isResume ? 104 : 142)}px;
  ${Typography.Body};
  ${FontWeights.Regular};

  ${Media.Tablet`
    left: 20px;
  `};

  ${Media.Phone`
    ${Typography.Caption};
    top: 206px;
    width: 100%;
    height: 40px;
    padding: 10px;
    left: 50%;
    transform: translate(-50%);
    padding-left: 25px;
  `};
`

export const ViewMyResumeButton = styled(Button)`
  gap: 10px;
  height: 48px;
  top: 200px;
  width: 174px;
  border-radius: 4px;
  background: ${Colors.Blue50};

  ${Media.Tablet`
    top: 200px;
  `};

  ${Media.Phone`
    position: absolute;
    width: 90%;
    top: 288px;
    border-radius: 4px;
    left: 5%;
    right: 5%;
    margin: 0px;
  `}

  @media (max-width: 280px) {
    top: 303px;
  }
`

export const BannerRightContent = styled.div`
  display: flex;
  align-items: center;

  ${Media.Tablet`
    width: auto;
  `};

  ${Media.Phone`
    width: 100%;
  `};
`

export const LoaderContainer = styled.div`
  width: 105px;
  margin-top: 43px;
  height: 263px;

  ${Media.AbovePhone`
    width: 125px;
    margin-top: 64px;
  `}

  ${Media.Phone`
    position: absolute;
    width: 176.33px;
    height: 170.33px;
    top: 6.44px;
    margin: 0;
    left: 50%;
    transform: translate(-30%);
  `}

  @media (width: 820px) {
    margin-right: -50px;
  }

  @media (width: 912px) {
    margin-right: -130px;
  }
`

export const mobileLogoContainer = styled.img`
  position: absolute;
  margin: 0;
  left: 50%;
  transform: translate(-50%);
  top: 42px;
  height: 136px;
  width: 260px;
`

export const logoContainer = styled.img`
  margin-right: 102px;
  top: 96px;
  height: 260;
  width: 320px;

  ${Media.Tablet`
    position: absolute;
    margin-right: 5px;
    top: 45px;
  `};
`

export const ResourceCardVisualWrapper = styled.div`
  position: relative;
  width: 100%;
  flex-shrink: 0;
  border-radius: 16px 16px 0 0;
`
