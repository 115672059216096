import styled, { css, keyframes } from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'

const rotation = keyframes`
  from {
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
  }

  to {
    transform: translateX(-50%) translateY(-50%) rotate(360deg);
  }
`

export const SpinnerField = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  animation: ${rotation} 0.5s infinite linear;
`

export const LayoutContainer = styled.div<{ isNewDash?: boolean }>`
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  height: auto;
  background-color: ${Colors.White};
  border-radius: 16px;
  padding: 32px 32px 64px;

  ${Media.Phone`
    padding: 20px;
  `};

  ${({ isNewDash }) =>
    isNewDash &&
    css`
      ${Media.Phone`
        padding-bottom: 70px;
      `};
    `}
`

export const StepsCounter = styled.div<{ isStepOne?: boolean; isNewDash?: boolean }>`
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 58px;
  gap: 8px;
  bottom: 0;
  background-color: ${Colors.White};
  padding: 20px;

  ${({ isStepOne }) =>
    isStepOne &&
    css`
      align-items: flex-start;
      height: 65px;
      padding: 8px;
    `}

  ${({ isNewDash }) =>
    isNewDash &&
    css`
      ${Media.Phone`
        bottom: 70px;
      `};
    `}
`

export const BlueStep = styled.div`
  width: 16px;
  height: 6px;
  border-radius: 8px;
  background: ${Colors.Blue50};
`

export const NormalStep = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 8px;
  background: ${Colors.Neutral20};
`
