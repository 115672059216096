import { createReducer } from '@reduxjs/toolkit'
import { navigate } from '../navigate'
import { FetchStatuses } from '../constants'
import { ResumeDistributionStore } from './types'
import { actions } from '.'

const initialState: ResumeDistributionStore = {
  isEdit: false,
  isResumeSelected: false,
  selectedResumeDetails: null,
  formResumeFetchDetails: null,
  isParsing: false,
  form: {
    jobPreferences: {
      country: undefined,
      state: undefined,
      industry: '',
      remote: 'false',
      salaryCurrency: 'USD',
      salaryAmount: '',
      salaryPeriod: 'year',
    },
    personalDetails: {
      firstName: '',
      lastName: '',
      jobTitle: '',
      email: '',
      linkedin: '',
    },
    locationDetails: {
      country: undefined,
      state: undefined,
      city: undefined,
    },
  },

  // Candidate
  candidate: undefined,
  candidateFetchStatus: FetchStatuses.notAsked,
  candidatePostStatus: FetchStatuses.notAsked,
  candidatePatchStatus: FetchStatuses.notAsked,
  candidateResumePostStatus: FetchStatuses.notAsked,
  candidateResumeFailed: false,
  serverFailed: false,

  // Distribution dates
  distributionDatesFetchStatus: FetchStatuses.notAsked,
  distributionDates: {
    data: [],
    nextDistribution: {
      day: 0,
      month: '',
      year: 0,
      unixFormat: 0,
    },
  },

  // Recruiter List
  recruiters: {},
  recruitersFetchStatus: {},
  recruitersCount: 0,
  distributedRecruiterCount: 0,

  // Subscriptions
  subscriptionPostPauseStatus: FetchStatuses.notAsked,
  subscriptionPostResumeStatus: FetchStatuses.notAsked,
  subscriptionPostCreateStatus: FetchStatuses.notAsked,
}

export const reducer = createReducer(initialState, reducer => {
  reducer.addCase(actions.setJobPreferencesForm, (draft, action) => {
    draft.form.jobPreferences = {
      ...draft.form.jobPreferences,
      ...action.payload,
    }
  })

  reducer.addCase(actions.setPersonalDetailsForm, (draft, action) => {
    draft.form.personalDetails = {
      ...draft.form.personalDetails,
      ...action.payload,
    }
  })

  reducer.addCase(actions.setLocationDetailsForm, (draft, action) => {
    draft.form.locationDetails = {
      ...draft.form.locationDetails,
      ...action.payload,
    }
  })

  reducer.addCase(actions.setResumeDetails, (draft, action) => {
    draft.selectedResumeDetails = action.payload

    navigate('/resume-distribution/edit', { replace: true })
  })

  reducer.addCase(actions.setPopulationFormDetailsForResume, (draft, action) => {
    draft.formResumeFetchDetails = action.payload
  })

  reducer.addCase(actions.setParsingStatus, (draft, action) => {
    draft.isParsing = action.payload
  })

  // Candidate
  reducer.addCase(actions.setCandidate, (draft, action) => {
    draft.candidate = action.payload
  })
  reducer.addCase(actions.setFetchCandidateStatus, (draft, action) => {
    draft.candidateFetchStatus = action.payload
  })
  reducer.addCase(actions.setPostCandidateStatus, (draft, action) => {
    draft.candidatePostStatus = action.payload
  })
  reducer.addCase(actions.setPatchCandidateStatus, (draft, action) => {
    draft.candidatePatchStatus = action.payload
  })
  reducer.addCase(actions.setCandidateResumeFailed, (draft, action) => {
    draft.candidateResumeFailed = action.payload
  })
  reducer.addCase(actions.setServerFailed, (draft, action) => {
    draft.serverFailed = action.payload
  })

  // Subscriptions
  reducer.addCase(actions.setSubscriptionStatus, (draft, action) => {
    if (draft.candidate) {
      draft.candidate.distributionStatus = action.payload
    }
  })
  reducer.addCase(actions.setPostSubscriptionPauseStatus, (draft, action) => {
    draft.subscriptionPostPauseStatus = action.payload
  })
  reducer.addCase(actions.setPostSubscriptionResumeStatus, (draft, action) => {
    draft.subscriptionPostResumeStatus = action.payload
  })
  reducer.addCase(actions.setPostSubscriptionCreateStatus, (draft, action) => {
    draft.subscriptionPostCreateStatus = action.payload
  })

  // Distribution Dates
  reducer.addCase(actions.setDistributionDates, (draft, action) => {
    if (action.payload) {
      draft.distributionDates = action.payload
    }
  })
  reducer.addCase(actions.setFetchDistributionDatesStatus, (draft, action) => {
    draft.distributionDatesFetchStatus = action.payload
  })

  // Recruiters
  reducer.addCase(actions.setRecruiters, (draft, action) => {
    draft.recruiters = {
      ...draft.recruiters,
      ...action.payload,
    }
  })
  reducer.addCase(actions.setPaginatedRecruiters, (draft, action) => {
    const { date, data } = action.payload

    draft.recruiters[date] = {
      data: [...draft.recruiters[date].data, ...data.data],
      pagination: data.pagination,
    }
  })
  reducer.addCase(actions.setFetchRecruitersStatus, (draft, action) => {
    draft.recruitersFetchStatus = { ...draft.recruitersFetchStatus, ...action.payload }
  })
  reducer.addCase(actions.setRecruitersCount, (draft, action) => {
    draft.recruitersCount = action.payload || 0
  })
  reducer.addCase(actions.setDistributedRecruitersCount, (draft, action) => {
    draft.distributedRecruiterCount = action.payload
  })

  // Candidate Resume
  reducer.addCase(actions.setPostCandidateResumeStatus, (draft, action) => {
    draft.candidateResumePostStatus = action.payload
  })

  reducer.addCase(actions.setIsEdit, (draft, action) => {
    draft.isEdit = action.payload
  })
})
