import { useI18n } from 'builder/hooks/useI18n'
import { Container, ProgressContainer, Progress, ContainerText, StyledCompleted } from './styles'

interface Props {
  score: number
}
export const LineProgressBar = ({ score }: Props) => {
  const { i18n } = useI18n()

  return (
    <Container>
      <ContainerText>
        {score}%{' '}
        <StyledCompleted>
          {i18n.t('builder.interview_prep_view.interview_player.completed')}
        </StyledCompleted>
      </ContainerText>
      <ProgressContainer>
        <Progress $score={score} />
      </ProgressContainer>
    </Container>
  )
}
