import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Sizes from 'builder/styles/sizes'
import Typography, { FontWeights } from 'builder/styles/typography'
import Shadows from 'builder/styles/shadows'

export const DesktopContainer = styled.div`
  position: relative;
  padding: 24px;
  padding-bottom: 0;
  border-radius: ${Sizes.S};
  cursor: pointer;

  &:hover {
    background: ${Colors.Neutral5};
    position: relative;
    top: -1px;
  }

  &::after {
    content: '';
    background: ${Colors.Neutral15};
    height: 1px;
    display: block;
    margin-top: 24px;
  }

  &:hover::after {
    background: transparent;
  }

  &:last-of-type::after {
    background: transparent;
  }
`

export const MobileTabletContainer = styled.div`
  position: relative;
  padding: 24px;
  padding-bottom: 0;
  border-radius: ${Sizes['2XS']};
  ${Shadows.lightWithBorder.low};
  cursor: pointer;

  &:hover {
    background: ${Colors.Neutral5};
  }

  margin-bottom: 8px;
`

export const Logo = styled.div<{ src?: string }>`
  flex-shrink: 0;
  border: 1px solid ${Colors.Neutral15};
  border-radius: ${Sizes['3XS']};
  width: 32px;
  height: 32px;
  background-image: url(${p => `"${p.src}"`});
  background-position: center;
  background-size: contain;
`

export const Top = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  color: ${Colors.Neutral60};
  ${FontWeights.Regular};
  ${Typography.Body};
`

const ellipse = `
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

`

export const Company = styled.div`
  ${ellipse}
`

export const Title = styled.div`
  padding: ${Sizes['3XS']};
  color: ${Colors.Neutral100};
  ${FontWeights.Regular};
  ${Typography.Body};
  ${ellipse}
`

export const Location = styled.div`
  padding: ${Sizes['3XS']};
  color: ${Colors.Neutral60};
  ${FontWeights.Regular};
  ${Typography.Body};
  ${ellipse}
`
export const Space = styled.div`
  height: ${Sizes['3XS']};
  width: 100%;
`
