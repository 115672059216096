/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useRef } from 'react'

export const useEvent = <TCallback extends (...args: any[]) => any>(handler?: TCallback | null) => {
  const callback = useRef(handler)

  useEffect(() => {
    callback.current = handler
  }, [handler])

  return useCallback((...args: any[]) => callback.current?.(...args), [callback]) as TCallback
}
