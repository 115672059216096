import { useCallback, useEffect, useRef, useState } from 'react'
import type { ResumeAvatar } from '@rio/types'
import { trackInternalEvent } from '@rio/tracking'
import ModalOverlay from 'builder/components/ModalOverlay'
import { readFileAsBase64 } from 'builder/utils/readFileAsBase64'
import { Position, AvatarEditor, SubmitPayload, Background } from './types'
import { useBackgroundRemover } from './hooks'
import { loadImage, formatTransform, composeOutputImageFile, areEqualBackgrounds } from './utils'
import Footer from './Footer'
import Tabs from './Tabs'
import Dropzone from './Dropzone'
import Cropper from './Cropper'
import {
  MODES,
  DEFAULT_X,
  DEFAULT_Y,
  DEFAULT_ZOOM,
  DEFAULT_ANGLE,
  DEFAULT_BACKGROUND,
} from './constants'
import { ModalContainer, ModalClose, ModalCloseIcon } from './styles'

interface ModalProps {
  avatar: ResumeAvatar
  onSubmit: (payload: SubmitPayload) => void
  onClose: () => void
}

export const AvatarEditorModal = ({ avatar, onSubmit, onClose }: ModalProps) => {
  const [mode, setMode] = useState(MODES.crop)
  const [file, setFile] = useState<File | null>(null)
  const [imageUrl, setImageUrl] = useState<string | null>(null)
  const editor = useRef<AvatarEditor>(null)

  const [x, setX] = useState(DEFAULT_X) // [0, 1]
  const [y, setY] = useState(DEFAULT_Y) // [0, 1]
  const [zoom, setZoom] = useState(DEFAULT_ZOOM) // [1, 3]
  const [angle, setAngle] = useState(DEFAULT_ANGLE)
  const [background, setBackground] = useState<Background>(DEFAULT_BACKGROUND)

  // Fetch image from the BG removal service
  // if the "Background" tab is open or a custom background is activated
  const [noBgImageUrl] = useBackgroundRemover(
    imageUrl,
    mode === MODES.background || !areEqualBackgrounds(background, DEFAULT_BACKGROUND),
  )

  // Process incoming avatar data
  useEffect(() => {
    const { blank, originalUrl, transform } = avatar
    setImageUrl(blank ? null : originalUrl)

    if (!transform) return

    if (typeof transform.zoom === 'number') setZoom(transform.zoom)
    if (typeof transform.angle === 'number') setAngle(transform.angle)
    if (transform.rect) setX(transform.rect.x + transform.rect.width / 2)
    if (transform.rect) setY(transform.rect.y + transform.rect.height / 2)
    if (transform.customBackground) setBackground(transform.customBackground)
  }, [avatar])

  // Reset the cropping area after a user chose a new image file
  useEffect(() => {
    if (file) {
      readFileAsBase64(file).then(url => {
        setImageUrl(url)
        setX(DEFAULT_X)
        setY(DEFAULT_Y)
        setZoom(DEFAULT_ZOOM)
        setAngle(DEFAULT_ANGLE)
      })
    }
  }, [file])

  // Track modal opening
  useEffect(() => {
    trackInternalEvent('open_avatar_editor')
  }, [])

  // Track background tab opening
  useEffect(() => {
    if (mode === MODES.background) trackInternalEvent('open_avatar_background_editor')
  }, [mode])

  const handleMove = useCallback((value: Position) => {
    setX(value.x)
    setY(value.y)
  }, [])

  const handleBackgroundChange = useCallback((newBackground: Background) => {
    setBackground(newBackground)
    trackInternalEvent('choose_avatar_background')
  }, [])

  const handleSave = async () => {
    if (!imageUrl || !editor.current) return

    const [image, processedFile] = await Promise.all([
      loadImage(imageUrl),
      composeOutputImageFile(editor.current.getImage(), background),
    ])

    const transform = formatTransform({ image, x, y, zoom, angle, background })
    onSubmit({ file, processedFile, transform })
  }

  return (
    <ModalOverlay onClose={onClose} overlayFadeDuration={150} contentSlideDuration={0}>
      <ModalContainer>
        {imageUrl === null && <Dropzone onChange={setFile} />}

        {imageUrl !== null && (
          <Cropper
            mode={mode}
            editorRef={editor}
            imageUrl={imageUrl}
            noBgImageUrl={noBgImageUrl}
            x={x}
            y={y}
            zoom={zoom}
            angle={angle}
            background={background}
            onMove={handleMove}
            onZoom={setZoom}
            onRotate={setAngle}
            onBackgroundChange={handleBackgroundChange}
          />
        )}

        {imageUrl !== null && <Tabs value={mode} onChange={setMode} />}

        {imageUrl !== null && <Footer onFileChoose={setFile} onSave={handleSave} />}

        <ModalClose onClick={onClose}>
          <ModalCloseIcon />
        </ModalClose>
      </ModalContainer>
    </ModalOverlay>
  )
}
