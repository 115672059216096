import EditorField from 'builder/components/EditorField'
import { TextField } from 'builder/components/TextField'
import { useWorkExperienceCardContext } from '../../ExpandableCardContext'
import { useShokumuTranslations } from '../../useShokumuTranslations'

const Department = (): JSX.Element => {
  const { item, onCardValueChange } = useWorkExperienceCardContext()
  const { i18n } = useShokumuTranslations()
  const label = i18n('work_experience.employment_details.department')

  const updateDepartmentDetails = (e: React.ChangeEvent<HTMLInputElement>) => {
    onCardValueChange({ department: e.target.value }, true)
  }

  return (
    <EditorField>
      <TextField
        label={label}
        name="department"
        onChange={updateDepartmentDetails}
        value={item.department || ''}
      />
    </EditorField>
  )
}
export default Department
