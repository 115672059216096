import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { trackInternalEvent } from '@rio/tracking'
import { bindActionToPromise } from 'builder/utils/bindActionToPromise'

import { CoverLetterEditor } from 'builder/components/DocumentEditor'
import PreviewPanel from 'builder/components/PreviewPanel'
import DocumentPreviewModal from 'builder/components/DocumentPreviewModal'
import DocumentCustomizationModal from 'builder/components/DocumentCustomizationModal'
import FloatingPreviewButton from 'builder/components/FloatingPreviewButton'
import { withMediaQueries } from 'builder/components/MediaQueries'

import { Container } from './styles'

import { actions, selectors } from 'builder/modules/coverLetterEditor'
import { actions as renderingActions } from 'builder/modules/rendering'
import { actions as generalEditorActions } from 'builder/modules/generalEditor'
import { CoverLetterRegenerationStatus, DocumentTypes } from 'builder/modules/constants'
import { withRouter } from 'builder/hocs/withRouter'
import { navigate } from 'builder/modules/navigate'

class CoverLetterEditorView extends Component {
  static propTypes = {
    editorState: PropTypes.object.isRequired,
    coverLetterState: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    mediaQueries: PropTypes.object.isRequired,
    fetchCoverLetterRequest: PropTypes.func.isRequired,
    downloadDocument: PropTypes.func,
    isDownloading: PropTypes.bool,
    fetchEditorPayloadRequest: PropTypes.func.isRequired,
    updateSimpleField: PropTypes.func.isRequired,
    aiClFreeAttempts: PropTypes.number,
    regenerateCoverLetter: PropTypes.func,
    setShowAICoverLetterPaywall: PropTypes.func,
    isAPremiumUser: PropTypes.bool,
    isContentAnimationPending: PropTypes.bool,
    fallbackLocale: PropTypes.string,
    setOpenEditorEventTracked: PropTypes.func,
    setShowConfirmRegenerateModal: PropTypes.func,
    resetAIRegenerationStatus: PropTypes.func,
    isOnline: PropTypes.bool,
  }

  state = {
    isFullScreen: false,
  }

  componentDidMount() {
    const coverLetterId = parseInt(this.props.params.id)
    this.props.fetchCoverLetterRequest(coverLetterId)
    this.props.fetchEditorPayloadRequest()
  }

  componentDidUpdate() {
    const { coverLetterState, setOpenEditorEventTracked } = this.props
    if (coverLetterState.error) navigate('/cover-letters')

    const coverLetterId = parseInt(this.props.params.id)
    const { coverLetter, openEditorEventTracked } = coverLetterState
    if (coverLetter && !openEditorEventTracked) {
      // track the page opening
      trackInternalEvent('visit_cover_letter_editor', {
        cover_letter_id: coverLetterId,
        ...(coverLetter.aiGenerated ? { label: 'ai_generated' } : {}),
      })
      setOpenEditorEventTracked()
    }
  }

  handleFullSizeOpen = e => {
    e.preventDefault()
    this.setState({ isFullScreen: true })
  }

  handleFloatingButtonClick = () => {
    this.setState({ isFullScreen: true })
    trackInternalEvent('open_full_screen_cover_letter_preview')
  }

  handleFullSizeClose = () => {
    this.setState({ isFullScreen: false })
  }

  handleTemplateUpdate = id => {
    this.handleSimpleFieldUpdate('template', id)
  }

  handleSimpleFieldUpdate = (name, value, debounce) => {
    this.props.updateSimpleField(name, value, debounce)

    if (name === 'name') trackInternalEvent('rename_cover_letter')
    if (name === 'locale') trackInternalEvent('change_cover_letter_locale', { locale: value })
    if (name === 'template') trackInternalEvent('change_cover_letter_template', { template: value })
  }

  render() {
    const {
      editorState,
      coverLetterState,
      params,
      mediaQueries,
      downloadDocument,
      aiClFreeAttempts,
      isAPremiumUser,
      isContentAnimationPending,
      setShowAICoverLetterPaywall,
      fallbackLocale,
      setShowConfirmRegenerateModal,
      resetAIRegenerationStatus,
      isOnline,
    } = this.props

    const coverLetterId = parseInt(params.id)
    const { isFullScreen } = this.state
    const { coverLetter, isSyncing } = coverLetterState
    const isDataLoaded = coverLetter && editorState.isLoaded
    const currentDocument = isContentAnimationPending
      ? { ...coverLetter, content: '' }
      : coverLetter

    const PreviewModal = !mediaQueries.isPhone ? DocumentCustomizationModal : DocumentPreviewModal

    return (
      <Container>
        <CoverLetterEditor
          {...{
            editorState,
            coverLetterState,
            aiClFreeAttempts,
            isAPremiumUser,
            isContentAnimationPending,
            setShowAICoverLetterPaywall,
            fallbackLocale,
            setShowConfirmRegenerateModal,
            coverLetterId,
            resetAIRegenerationStatus,
            isOnline,
          }}
          updateSimpleField={this.handleSimpleFieldUpdate}
        />

        {/* Prevent rendering if the block isn't visible (reduce the load of user's device) */}
        {!isFullScreen && mediaQueries.isDesktop && (
          <PreviewPanel
            onFullSize={this.handleFullSizeOpen}
            type={DocumentTypes.coverLetter}
            documentId={coverLetterId}
            isSyncing={isSyncing}
            isDownloading={this.props.isDownloading}
            onDownload={downloadDocument}
            currentDocument={currentDocument}
            isFullScreen={isFullScreen}
          />
        )}

        {isFullScreen && ( // TODO: remove it after DocumentPreviewModal speed up & refactoring.
          <PreviewModal // Make sure nothing is rendered if panel isn't shown
            type={DocumentTypes.coverLetter}
            isOpen={isFullScreen}
            isSyncing={isSyncing}
            isDownloading={this.props.isDownloading}
            currentDocument={currentDocument}
            editorState={editorState}
            onClose={this.handleFullSizeClose}
            onDownload={downloadDocument}
            updateTemplate={this.handleTemplateUpdate}
            updateSimpleField={this.handleSimpleFieldUpdate}
          />
        )}

        {mediaQueries.isLaptop && isDataLoaded && (
          <FloatingPreviewButton isSaving={isSyncing} onClick={this.handleFloatingButtonClick} />
        )}
      </Container>
    )
  }
}

// ---
// Connect to data
// ---
function mapStateToProps(state) {
  return {
    editorState: state.generalEditor,
    coverLetterState: state.coverLetterEditor,
    isDownloading: state.rendering.isDocumentDownloading,
    aiClFreeAttempts: state.user.data.aiClFreeAttempts,
    isAPremiumUser: state.user.data.hasPremiumFeatures,
    fallbackLocale: state.application.configs.country.locale,
    isContentAnimationPending: selectors.isContentAnimationPending(state),
    isOnline: state.application.isOnline,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCoverLetterRequest: id => dispatch(actions.fetchCoverLetterRequest({ id })),
    fetchEditorPayloadRequest: () => dispatch(generalEditorActions.fetchEditorPayloadRequest()),
    updateSimpleField: (name, value, debounce) =>
      dispatch(actions.updateSimpleField({ name, value, debounce })),
    downloadDocument: bindActionToPromise(dispatch, renderingActions.download),
    regenerateCoverLetter: id => dispatch(actions.regenerateCoverLetter({ id })),
    setShowAICoverLetterPaywall: () => dispatch(actions.setShowAICoverLetterPaywall()),
    setOpenEditorEventTracked: () => dispatch(actions.setOpenEditorEventTracked(true)),
    setShowConfirmRegenerateModal: value => dispatch(actions.setShowConfirmRegenerateModal(value)),
    resetAIRegenerationStatus: () =>
      dispatch(actions.setAIRegenerationStatus(CoverLetterRegenerationStatus.notStarted)),
  }
}

export default withMediaQueries(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(CoverLetterEditorView)),
)
