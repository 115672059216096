import { Course as CourseType } from 'builder/modules/careerPath2'
import Clock from '../../../../asset/Clock.svg'
import { Container, Text, ClockIcon } from './styles'

interface FooterProps {
  course: CourseType
}

export const Footer = ({ course }: FooterProps) => {
  return (
    <Container>
      <ClockIcon src={Clock} />
      <Text>
        {course.hoursperweek ?? 0} {course.hoursperweek ?? 0 > 1 ? 'hours' : 'hour'} |{' '}
        {course.weeks ?? ''} weeks, {course.commit ?? ''}
      </Text>
    </Container>
  )
}
