import styled from 'styled-components'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'

export const Container = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: center;
  width: 100%;
  margin-top: 64px;

  ${Media.Phone`
    margin-top:32px
  `}
`

export const Content = styled.div`
  width: 100%;
  gap: 16px;
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: center;
  align-items: flex-start;
  padding: 0px;

  ${Media.Phone`
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  `};
`

export const CareerEntryImage = styled.img`
  width: 319.98px;
  height: 160px;
  margin: auto;

  ${Media.Tablet`
    margin:auto;
  `};

  ${Media.Phone`
    width: 245.14px;
    height: 100px;
    margin:auto;
  `};
`

export const Heading = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};

  ${Media.Phone`
    height: 60px;
  `};
`

export const SubHeader = styled.div`
  color: ${Colors.Neutral50};
`
export const ButtonContainer = styled.div`
  width: 100%;
`
