import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Icon24 from 'builder/components/Icon'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  gap: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 926px;
  margin: auto;
`

export const Header = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};
`

export const Questions = styled(Container)`
  gap: 24px;
`

export const Question = styled(Questions)`
  gap: 12px;
`
export const QuestionContainer = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const QuestionContent = styled.div<{ isOpted: boolean }>`
  color: ${props => (props.isOpted ? Colors.Blue50 : Colors.Neutral90)};
`

export const AnswerContent = styled.div`
  color: ${Colors.Neutral50};
`

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${Colors.Neutral20};
`

export const CloseIcon = styled(Icon24.Chevron)`
  color: ${Colors.Blue50};
  transform: rotate(90deg);
`

export const OpenIcon = styled(Icon24.Chevron)`
  color: ${Colors.Blue50};
  transform: rotate(-90deg);
`

export const Footer = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
`

export const FAQLink = styled.a`
  ${Typography.Caption};
  color: ${Colors.Blue50};
`
