import { useCallback } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import {
  Text,
  Card,
  Label,
  Title,
  Visual,
  Chevron,
  IconBox,
  Description,
  TextContainer,
  VisualContainer,
} from './styles'
import { CareerPlan } from './types'

type Props = {
  careerPlan: CareerPlan
  isSevenStoriesExercise?: boolean
}

const handleAssessmentEvents = (planName: string, source: string, label?: string) => {
  if (planName === 'custom_plan') {
    trackInternalEvent('career_card_plan_click', {
      plan_name: planName,
      source: source,
    })
  } else {
    trackInternalEvent('click_career_plan_card', {
      career_plan_name: planName,
      source: source,
      label: label,
    })
  }
}

const CareerPlanCard = ({ careerPlan, isSevenStoriesExercise = false }: Props) => {
  const { name, label, visual, title, text, action } = careerPlan

  const handleClick = useCallback(
    (name: string) => {
      action()

      if (isSevenStoriesExercise) {
        handleAssessmentEvents(
          'execute_job_search',
          'seven_stories_result_page',
          'job_search_assessment',
        )
      } else {
        handleAssessmentEvents(name, 'career_plans')
      }
    },
    [action, isSevenStoriesExercise],
  )

  return (
    <Card onClick={() => handleClick(name)} {...{ isSevenStoriesExercise }}>
      <VisualContainer>
        <Label>{label}</Label>
        <Visual src={visual} />
      </VisualContainer>
      <TextContainer>
        <Title>{title}</Title>
        <Description>
          <Text {...{ isSevenStoriesExercise }}>{text}</Text>
          <IconBox>
            <Chevron />
          </IconBox>
        </Description>
      </TextContainer>
    </Card>
  )
}

export default CareerPlanCard
