import { BriefLetter, BriefResume } from 'builder/modules/panel'
import { formatDocumentPreviewUrl } from 'builder/utils/formatDocumentPreviewUrl'
import * as Styled from './styles'

type Props = {
  document: BriefResume | BriefLetter
}

export const DocumentPreviewVisual = (props: Props) => {
  const imageSrc = formatDocumentPreviewUrl(props.document, { size: 256 })

  return (
    <Styled.Container>
      <Styled.ImagePreview src={imageSrc} />
    </Styled.Container>
  )
}
