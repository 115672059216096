import { EditorChangeType, EditorState, RichUtils } from 'draft-js'
import { ENTITY_TYPE } from 'draft-js-utils'
import snakeCase from 'lodash/snakeCase'
import { trackInternalEvent } from '@rio/tracking'
import { MouseClickEvent } from 'builder/modules/ui'
import { StyleTypes, ToolbarActions } from '../types'
import { clearEntityForRange, getEntityAtCursor, processPhrase, replaceText } from './lib'
import { Props } from './RichTextToolbar'

export const useToolbarActions = (props: Props): ToolbarActions => {
  const {
    editorState,
    isSpellCheckerEnabled,
    suggestionConfig,
    focusEditor: focusEditorProp,
    onChange,
    onToggleInline,
    onToggleBlock,
    onToggleSpellChecker,
  } = props

  const addPhrase = (item: { phrase: string; isSubphrase: boolean }) => {
    onChange(processPhrase(item, editorState, suggestionConfig?.scope))
  }

  const replacePhrase = (phrase: string) => {
    onChange(replaceText(phrase, editorState))
  }

  const focusEditor = () => {
    // Hacky: Wait to focus the editor so we don't lose selection.
    setTimeout(() => {
      focusEditorProp()
    }, 50)
  }

  const getCurrentBlockType = () => {
    const selection = editorState.getSelection()
    return editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType()
  }

  const entityAtCursor = () => {
    let contentState = editorState.getCurrentContent()
    let entity = getEntityAtCursor(editorState)
    return !entity ? null : contentState.getEntity(entity.entityKey)
  }

  const wrapLink = (url: string) => {
    let contentState = editorState.getCurrentContent()
    let selection = editorState.getSelection()
    let origSelection = selection
    let canApplyLink = false

    if (selection.isCollapsed()) {
      let entity = getEntityAtCursor(editorState)
      if (entity) {
        canApplyLink = true
        selection = selection.merge({
          anchorOffset: entity.startOffset,
          focusOffset: entity.endOffset,
          isBackward: false,
        })
      }
    } else {
      canApplyLink = true
    }

    if (canApplyLink) {
      contentState = contentState.createEntity(ENTITY_TYPE.LINK, 'MUTABLE', {
        url,
      })
      const entityKey = contentState.getLastCreatedEntityKey()

      // What should be the change type here?
      let updatedEditorState = EditorState.push(editorState, contentState, '' as EditorChangeType)
      updatedEditorState = RichUtils.toggleLink(updatedEditorState, selection, entityKey)
      updatedEditorState = EditorState.acceptSelection(updatedEditorState, origSelection)

      onChange(updatedEditorState)
    }
    focusEditor()
  }

  const unwrapLink = () => {
    const entity = getEntityAtCursor(editorState)

    if (entity) {
      const { blockKey, startOffset, endOffset } = entity
      onChange(clearEntityForRange(editorState, blockKey, startOffset, endOffset))
    }
  }

  const handleClickBlock = (event: MouseClickEvent, type: StyleTypes) => {
    event.preventDefault()
    onToggleBlock(type)
    focusEditor()

    trackInternalEvent('click_rich_text_toolbar_button', {
      button: snakeCase(type),
      action: getCurrentBlockType() === type ? 'disable' : 'enable',
    })
  }

  const handleClickMark = (event: MouseClickEvent, type: StyleTypes) => {
    event.preventDefault()
    onToggleInline(type)

    trackInternalEvent('click_rich_text_toolbar_button', {
      button: snakeCase(type),
      action: editorState.getCurrentInlineStyle().has(type) ? 'disable' : 'enable',
    })
  }

  const handleClickSpellCheck = () => {
    trackInternalEvent('click_rich_text_toolbar_button', {
      button: 'spell_check',
      action: isSpellCheckerEnabled ? 'disable' : 'enable',
    })

    onToggleSpellChecker()
  }

  const handleClickLink = (event: MouseClickEvent) => {
    event.preventDefault()

    const entity = entityAtCursor()
    const isCursorOnLink = entity?.getType() === ENTITY_TYPE.LINK

    if (isCursorOnLink) {
      unwrapLink()
    } else {
      const href = window.prompt('Enter the URL of the link:')
      if (!href) return
      wrapLink(href)
    }

    trackInternalEvent('click_rich_text_toolbar_button', {
      button: 'link',
      action: isCursorOnLink ? 'disable' : 'enable',
    })
  }

  return {
    addPhrase,
    replacePhrase,
    handleClickBlock,
    handleClickLink,
    handleClickMark,
    handleClickSpellCheck,
  }
}
