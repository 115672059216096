import { CareerPathStep } from 'builder/modules/careerPath2/constants'
import { FetchStatuses } from 'builder/modules/constants'
import { BriefResume } from '../panel'

export type SuggestedSkillsType = {
  title: string
}

export type Skill = {
  value: string
  label: string
}

export type JobProgression = {
  level?: number
  query: string | undefined
  selectedValue?: string
}

export type JobProgressionsPath = {
  percentage: number
  target: string
  timeSpan: string
  matchScore?: number
  skills: string[]
}

export type CareerPathPayload = {
  title: string
  level: number | undefined
}

export type CareerPathPayloadType = {
  title?: string
  score?: number
  level?: number
  isEditing?: boolean
}

export type SaveCareerPathsPayload = {
  title: string
  skills: string[]
  pathways: {
    path1: CareerPathPayloadType[]
  }
}

export type CareerPathSalary = {
  min?: number
  median?: string
  max?: number
}

export type CareerPathItem = {
  title: string
  jobPostings?: number
  jobSearchUrl?: string
}

export type CareerPathEducation = {
  title: string
  jobPostings: number
  rate: number
  abbr: string
}

export type CareerPathSeries = Array<{
  x: string
  y: number
}>

export type CareerPathRoleSummaryPayload = {
  jobTitle: string
  description: string
  location: {
    title: string
    country: {
      title: string
      code: string
    }
  }
  salary: CareerPathSalary
  totals: {
    jobPostings?: number
    companies?: number
    demandRating?: string
  }
  hardSkills: string[]
  softSkills: string[]
  companies: Array<CareerPathItem>
  commonJobTitles: Array<CareerPathItem>
  certifications: Array<CareerPathItem>
  educationLevels: Array<CareerPathEducation>
  postingsTimeseries: CareerPathSeries
}

export type PathwaysType = {
  path1: CareerPathPayloadType[]
}

export type CareerCardType = {
  id: number
  title: string
  skills: string[]
  pathways: PathwaysType
  color?: string
  matchScore?: number
}

export type JobTitleIndexType = null | number | undefined

export type JobTitleClickCount = {
  title: string | undefined
  count: number
}

export interface Course {
  commit: string
  delivery: string
  description: string
  hoursperweek: number
  image: string
  logo: string
  title: string
  url: string
  weeks: number
}
interface EducationLevelSalary {
  bachelor: number
  master: number
}

export interface UpdatedExperienceLevelSalary {
  '10+ years': number
  '6-9 years': number
  '3-6 years': number
  '1-3 years': number
  Fresher: number
}

export interface UpdatedEducationLevelSalary {
  "Master's Degree": number
  "Bachelor's Degree": number
}

interface ExperienceLevelSalary {
  '10+': number
  '1to3': number
  '3to6': number
  '6to9': number
  fresher: number
}

interface TopRegionsSalary {
  name: string
  salary: number
}

export interface SalaryDetails {
  averageSalary: number
  educationLevelSalary: EducationLevelSalary
  experienceLevelSalary: ExperienceLevelSalary
  maximumSalary: number
  minimumSalary: number
  title: string
  topCitiesSalary: TopRegionsSalary[]
  topStatesSalary: TopRegionsSalary[]
}
export interface FaqItem {
  [key: string]: string
}

export interface Job {
  id: number
  jobTitle: string
  snippet: string
  company: string
  companyLogo: string
  location: string
  date: string
  url: string
  salary?: string
  jobType?: string
  slug: string
  position?: number
  locationId?: number
}

export interface JobCard extends Job {
  positionNumber: number
  status: ColumnName
  externalSlugId: number
}

export interface JobCreatePayload {
  status?: ColumnName
  title?: string
  company?: string
  link?: string
  description?: string
  open?: boolean
  slug: string
  location?: string
  logo?: string
  dummy?: boolean
}

export enum ColumnName {
  recommended = 'recommended',
  wishlist = 'wishlist',
}

export type Columns = Record<ColumnName, JobCard[]>

export type JobDeletePayload = { id: number }

export interface SearchQuery {
  query: string
  location?: string | null
  page?: number
  perPage?: number
}

export type CareerPagesJobDetailsPayload = {
  faq: FaqItem[]
  responsibilities: string
  title: string
  description: string
  hardSkills: string[]
  softSkills: string[]
}

export type JobDemandParams = {
  job_title: string
  location: string
}

export type CompanySummary = {
  name: string
  jobPostings: number
}

export type JobDemand = {
  jobsCount: number
  companiesCount: number
  companies: CompanySummary[]
}

export interface CareerPathStore {
  step: CareerPathStep
  prevStep: CareerPathStep
  occupation: string
  skills: Skill[]
  suggestedSkills: Skill[]
  suggestedSkillsCopy: Skill[]
  openPrefillModal: boolean
  level1JobTitles: JobProgressionsPath[]
  level2JobTitles: JobProgressionsPath[]
  level3JobTitles: JobProgressionsPath[]
  careerPaths: [{ careerPath1: CareerPathPayloadType[] }]
  isCareerPath1Set: boolean
  validationError: boolean
  userDocuments: BriefResume[]
  resumeID: null | number
  fetchJobProgressionStatus: FetchStatuses
  fetchGoalsStatus: FetchStatuses
  addToPathJobTitle: string | undefined
  hardSkills: string[]
  softSkills: string[]
  skillsMatchScore: number
  requiredSkills: string[]
  jobDetails: {
    commonJobTitles: Array<CareerPathItem>
    description: string | null
    softSkills: CareerPathRoleSummaryPayload['softSkills']
    hardSkills: CareerPathRoleSummaryPayload['hardSkills']
    certifications: Array<CareerPathItem>
    companies: Array<CareerPathItem>
    totals: CareerPathRoleSummaryPayload['totals']
    salary: CareerPathRoleSummaryPayload['salary']
    educationLevels: Array<CareerPathEducation>
    postingsTimeseries: CareerPathRoleSummaryPayload['postingsTimeseries']
    jobTitle: string | null
  }
  fetchJobDetailsStatus: FetchStatuses
  allCareerCards: CareerCardType[]
  level1JobTitleIndex: JobTitleIndexType
  level2JobTitleIndex: JobTitleIndexType
  level3JobTitleIndex: JobTitleIndexType
  selectedLevel1JobTitleIndex: JobTitleIndexType
  selectedLevel2JobTitleIndex: JobTitleIndexType
  selectedLevel3JobTitleIndex: JobTitleIndexType
  currentLevel: JobTitleIndexType
  editCareerCardData: CareerCardType
  fetchJobDetailsCount1Status: FetchStatuses
  fetchJobDetailsCount2Status: FetchStatuses
  fetchJobDetailsCount3Status: FetchStatuses
  fetchJobDetailsCount4Status: FetchStatuses
  addToPathLevel: number
  jobTitleCounts: JobTitleClickCount
  isSwiperVisible: boolean
  isEditingNewAdded: boolean
  selectedJobTitle: string | undefined
  isExploreCareers: boolean
  exploreCareersOccupation: string
  isDeletePathsModalOpen: boolean
  pathsCardIdToDelete: null | number
  resumeSkills: string[]
  courses: Course[]
  fetchCoursesStatus: FetchStatuses
  location: string
  exploreCareersLocation: string
  salaryDetails: SalaryDetails
  fetchSalaryStatus: FetchStatuses
  careerPagesJobDetails: {
    faq: CareerPagesJobDetailsPayload['faq']
    responsibilities: string
    title: string
    description: string
    hardSkills: CareerPagesJobDetailsPayload['hardSkills']
    softSkills: CareerPagesJobDetailsPayload['softSkills']
  }
  fetchCareerPagesJobDetailsStatus: FetchStatuses
  recommendedJobCard: Job[]
  fetchJobCardStatus: FetchStatuses
  wishlistJobCard: JobCard[]
  jobDemand: JobDemand
  fetchJobDemandStatus: FetchStatuses
  fetchJobTitlesStatus: FetchStatuses
  activeTab: string
}

export type SalaryFeedbackParams = {
  title: string
  location?: string
  education?: string
  experience?: string
  salary: number
  feedback: number
}

export type SalaryParams = {
  title: string
  location?: null | string
  education?: string
  experience?: string
}
