import { FC, memo } from 'react'
import { Link } from 'react-router-dom'
import * as Styled from './styles'

export const BottomCard: FC<{
  headerText: string
  text: string
  buttonText: string
  buttonLink?: string
  buttonClickHandler?: () => void
}> = memo(({ headerText, text, buttonText, buttonLink, buttonClickHandler, children }) => {
  const buttonComponent = <Styled.Button onClick={buttonClickHandler}>{buttonText}</Styled.Button>

  return (
    <Styled.Container>
      <Styled.MainContainer $isCardEmpty={!children}>
        <Styled.TextContainer>
          <Styled.Header>{headerText}</Styled.Header>
          <Styled.Text>{text}</Styled.Text>
        </Styled.TextContainer>
        {buttonLink ? (
          <Link to={buttonLink} target="_blank">
            {buttonComponent}
          </Link>
        ) : (
          buttonComponent
        )}
      </Styled.MainContainer>
      {children}
    </Styled.Container>
  )
})
