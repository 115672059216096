import { Listbox } from '@headlessui/react'
import { SelectControlProps as Props, SelectValue } from '../../types'
import { DesktopOptions } from '../DesktopOptions'
import { SelectButton } from '../SelectButton'

export const DesktopControl = <V extends SelectValue>({
  isDisabled,
  value,
  options,
  onChange,
  ...rest
}: Props<V>) => {
  return (
    <Listbox disabled={isDisabled} value={value} onChange={onChange}>
      {({ open: isOpen }) => (
        <div>
          <Listbox.Button as={SelectButton} isOpen={isOpen} isDisabled={isDisabled} {...rest} />
          <DesktopOptions options={options} />
        </div>
      )}
    </Listbox>
  )
}
