import { useState, useEffect } from 'react'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { ExpertDoc } from 'packages/types'
import { useI18n } from 'builder/hooks/useI18n'

import {
  DocumentTypes as TYPES,
  FORMATS,
  FetchStatuses,
  DocumentTypes,
} from 'builder/modules/constants'
import { BriefResume, BriefLetter, typeToSlug, BriefExpertDoc } from 'builder/modules/panel'
import {
  DownloadTRDocumentStorageKeys,
  DownloadWhenPremium,
  actions as renderingActions,
} from 'builder/modules/rendering'
import { actions as uiActions } from 'builder/modules/ui'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { formatDocumentPreviewUrl } from 'builder/utils/formatDocumentPreviewUrl'
import { useAppSelector } from 'builder/hooks/useAppSelector'
import { formatUpdatedAt } from 'builder/components/Panel/Document/utils'
import DocThumbnailLoader from 'builder/components/Panel/Document/DocumentThumbnail/DocThumbnail.svg'
import {
  DESKTOP_PREVIEW_WIDTH,
  PHONE_PREVIEW_WIDTH,
} from 'builder/components/Panel/Document/constants'
import {
  DocumentContainer,
  DocumentPreviewContainer,
  DocumentPreview,
  DocumentContent,
  DocumentNote,
} from 'builder/components/Panel/Document/styles'
import DocumentName from './DocumentName'
import { DefaultDiv, DocumentGridWrapper } from './styles'

// react-flip-move package requires children to be wrapped into "React.forwardRef"
type Ref = React.ForwardedRef<HTMLDivElement>

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  document: BriefResume | BriefLetter | BriefExpertDoc
  isPostPremium?: boolean
  isFirstDocumentFree?: boolean
  miniature?: boolean
}

export const Document = React.forwardRef(
  ({ isFirstDocumentFree, document, miniature, ...rest }: Props, ref: Ref) => {
    const dispatch = useDispatch()
    const [query] = useSearchParams()
    const { panel } = useAppSelector(store => store)
    const { trDocuments, trDocumentsStatus } = panel
    const { i18n } = useI18n()
    const { isPhone } = useMediaQueries()
    const { id, type } = document

    const [trDocument, setTrDocument] = useState<ExpertDoc | null>(null)
    const [trDocumentStatus, setTrDocumentStatus] = useState<FetchStatuses | null>(null)

    const [, setIsHovering] = useState(false)

    const previewUrl = formatDocumentPreviewUrl(document, {
      size: isPhone ? PHONE_PREVIEW_WIDTH : DESKTOP_PREVIEW_WIDTH,
    })

    const isTypeTrDoc = (documentType: string) => {
      return documentType === TYPES.trDocument
    }

    const editorUrl = isTypeTrDoc(type)
      ? `/${typeToSlug(type)}/${id}`
      : `/${typeToSlug(type)}/${id}/edit`

    useEffect(() => {
      if (!trDocument && trDocuments[id]) {
        setTrDocument({ ...trDocuments[id] })
      }
    }, [trDocument, trDocuments, id])

    useEffect(() => {
      setTrDocumentStatus(trDocumentsStatus[id])
    }, [trDocumentsStatus, id])

    // Download TRDocuments when we have the data after the payment and clean session storage
    useEffect(() => {
      const TRDocumentToDownload = sessionStorage.getItem(
        DownloadTRDocumentStorageKeys.DOWNLOAD_WHEN_PREMIUM,
      ) as DownloadWhenPremium

      if (trDocument && trDocumentStatus === FetchStatuses.loaded && TRDocumentToDownload) {
        const trDocDownloadURL = trDocument?.files?.[0]?.url
        const trDocDownloadTYPE = trDocument?.files?.[0]?.content_type
        if (trDocDownloadURL) {
          dispatch(
            renderingActions.download({
              id: Number(trDocument?.id),
              type: trDocument?.doc_type as DocumentTypes,
              format: trDocDownloadTYPE.endsWith('pdf') ? FORMATS.tr_pdf : FORMATS.tr_doc,
              source: 'tr_doc',
              trDocFiles: trDocument?.files ?? [],
            }),
          )
          sessionStorage.removeItem(DownloadTRDocumentStorageKeys.DOWNLOAD_WHEN_PREMIUM)
          sessionStorage.removeItem(DownloadTRDocumentStorageKeys.TR_DOC_ID)
        }
      }
    }, [dispatch, trDocument, trDocumentStatus])

    useEffect(() => {
      if (query.has('isSharing') && query.has('docId')) {
        dispatch(uiActions.openSharingModal(Number(query.get('docId'))))
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleBlurIn = () => {
      setTimeout(() => {
        setIsHovering(true)
      }, 200)
    }

    const handleBlurOut = () => {
      setTimeout(() => {
        setIsHovering(false)
      }, 200)
    }

    const getThumbnailImg = () => {
      if (type === DocumentTypes.trDocument) {
        return document?.thumbnail && document?.thumbnail?.url !== ''
          ? document?.thumbnail?.url
          : DocThumbnailLoader
      }
      return previewUrl
    }

    const getDocumentName = () => {
      if (type === DocumentTypes.trDocument && document?.title) {
        let documentName = document?.title
        return documentName
      }
      return document.name || i18n.t('builder.dashboard.untitled')
    }

    return (
      <DocumentGridWrapper>
        <DocumentContainer
          {...rest}
          ref={ref}
          onMouseOver={handleBlurIn}
          onMouseOut={handleBlurOut}
        >
          <DocumentPreviewContainer
            $miniature={miniature}
            as={DefaultDiv}
            to={!isFirstDocumentFree ? editorUrl : ''}
          >
            <DocumentPreview src={getThumbnailImg()} />
          </DocumentPreviewContainer>

          <DocumentContent isDisabled={isFirstDocumentFree}>
            <DocumentName value={getDocumentName()} />
            <DocumentNote>
              {i18n.t('builder.dashboard.updated')} {formatUpdatedAt(document)}
            </DocumentNote>
          </DocumentContent>
        </DocumentContainer>
      </DocumentGridWrapper>
    )
  },
)

export default Document
