import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { capitalize } from 'builder/utils/capitalize'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { actions, selectors, Label } from 'builder/modules/careerPlanning'
import { ButtonSize, ButtonTheme } from 'builder/components/Button'

import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { FiltersWrapper, StyledButton } from './styles'

const planName = 'execute_a_job_search'

type Option = {
  id: number
  status: string
  isActive: boolean
  isDisabled: boolean
}

type Props = {
  option: Option
}

type FiltersProps = {
  isLoaded: boolean
}

const getTrackingEvent = (id: number) => {
  if (id === 1) {
    return 'click_open_filter'
  } else if (id === 2) {
    return 'click_completed_filter'
  } else {
    return 'click_all_filter'
  }
}

const Filter = ({ option }: Props) => {
  const { id, status, isActive, isDisabled } = option

  const dispatch = useDispatch()
  const { isPhone, isDesktop } = useMediaQueries()
  const filterOptions = useTypedSelector(selectors.filterOptions)
  const firstContentOffset = useTypedSelector(selectors.firstContentOffset)
  const openContents = useTypedSelector(selectors.openContents)
  const completedContents = useTypedSelector(selectors.completedContents)
  const allContents = useTypedSelector(selectors.allContents)

  const updateFilterOptions = useCallback(() => {
    const updatedFilterOptions = filterOptions.map(option => {
      if (option.id === id) {
        const newOption = { ...option, isActive: true }
        return newOption
      }

      return { ...option, isActive: false }
    })

    dispatch(actions.updateFilterOptions(updatedFilterOptions))
  }, [dispatch, filterOptions, id])

  const dispatchActions = useCallback(
    (cpTopLevelId: number, cpSecondLevelId: number) => {
      dispatch(actions.setStep(cpTopLevelId))
      dispatch(actions.setSecondLevelId(cpSecondLevelId))
    },
    [dispatch],
  )

  const updatePanelOptions = useCallback(() => {
    let cpTopLevelId: number
    let cpSecondLevelId: number

    switch (id) {
      case 1:
        cpTopLevelId = openContents[0].contents[0].cpTopLevelId
        cpSecondLevelId = openContents[0].contents[0].cpSecondLevelId
        break
      case 2:
        cpTopLevelId = completedContents[0].contents[0].cpTopLevelId
        cpSecondLevelId = completedContents[0].contents[0].cpSecondLevelId
        break
      default:
        cpTopLevelId = allContents[0].contents[0].cpTopLevelId
        cpSecondLevelId = allContents[0].contents[0].cpSecondLevelId
    }

    dispatchActions(cpTopLevelId, cpSecondLevelId)
  }, [allContents, completedContents, dispatchActions, id, openContents])

  const handleClick = useCallback(() => {
    if (firstContentOffset) {
      const offsetTop = isPhone || isDesktop ? firstContentOffset - 100 : firstContentOffset - 80

      window.scroll({
        top: offsetTop,
        behavior: 'smooth',
      })
    }

    updateFilterOptions()
    dispatch(actions.setIsObserving(true))

    const trackingEvent = getTrackingEvent(id)

    trackInternalEvent(trackingEvent, {
      ...Label,
      career_plan_name: planName,
    })

    updatePanelOptions()
  }, [
    firstContentOffset,
    updateFilterOptions,
    dispatch,
    id,
    updatePanelOptions,
    isPhone,
    isDesktop,
  ])

  return (
    <StyledButton
      onClick={handleClick}
      isDisabled={isDisabled}
      size={ButtonSize.small}
      theme={isActive ? ButtonTheme.default : ButtonTheme.ghost}
    >
      {capitalize(status)}
    </StyledButton>
  )
}

const Filters = ({ isLoaded }: FiltersProps) => {
  const showFilters = useTypedSelector(selectors.showFilters)
  const isTopBarOpen = useTypedSelector(selectors.isTopBarOpen)
  const filterOptions = useTypedSelector(selectors.filterOptions)

  return (
    <FiltersWrapper {...{ showFilters, isTopBarOpen }}>
      {filterOptions.map(option => (
        <Filter key={option.id} {...{ option, isLoaded }} />
      ))}
    </FiltersWrapper>
  )
}

export default Filters
