import { ValidateResult } from 'react-hook-form'
import { i18n } from 'builder/utils/i18n'

export function formatFileSize(file: File) {
  let fileSize: number | string = file.size

  let sizeUnit = 'bytes'
  if (fileSize >= 1024 && fileSize < 1048576) {
    fileSize = (fileSize / 1024).toFixed(2)
    sizeUnit = 'KB'
  } else if (fileSize >= 1048576) {
    fileSize = (fileSize / 1048576).toFixed(2)
    sizeUnit = 'MB'
  }

  return `${fileSize} ${sizeUnit}`
}

export const validateEmail = (value: string | null | undefined): ValidateResult => {
  if (!/^[\w\-.+]+@([\w-]+\.)+[\w-]{2,4}$/.test(value || '')) {
    return i18n.t(`builder.resume_distribution.landing.form_personal_detail.invalid_email`)
  }
}

export const validateLinkedinUrl = (value: string | null | undefined): ValidateResult => {
  if (!value) return
  const regEx = /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*/gm
  if (!regEx.test(value || '')) {
    return i18n.t(`builder.resume_distribution.landing.form_personal_detail.invalid_linkedin_url`)
  }
}

export const validatePhoneNumber = (value?: string | null): ValidateResult => {
  if (!value) return
  const phoneRegex = /^(\+1\s?)?(\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}$/
  if (!phoneRegex.test(value || '')) {
    return 'Invalid phone number'
  }
}
