import { COMPANIES_ICONS } from './companiesIconsData'
import * as Styled from './styles'

export const CompaniesIconsStick = () => {
  return (
    <Styled.StickContainer>
      {COMPANIES_ICONS.map((ic, index) => (
        <Styled.IconContainer key={ic.alt} index={index}>
          <Styled.IconImage src={ic.icon} alt={ic.alt} />
        </Styled.IconContainer>
      ))}
    </Styled.StickContainer>
  )
}
