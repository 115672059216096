import type { Optional, UserDocumentTemplate, UserDocumentTemplateId } from '@rio/types'
import { FetchStatuses } from 'builder/modules/constants'

export enum LocalStorageSignUpDataKeys {
  SHOW_RIO_CIO_INFO_MODAL_FLAG = 'rioCioModalFlag',
}

export interface SignUpInfo {
  template: string
  firstName: string
  lastName: string
  email: string
  phone: string
  confirm_email: boolean
}

export interface SignUpStore {
  resumeTemplates: UserDocumentTemplate[]
  coverLetterTemplates: UserDocumentTemplate[]
  heroTemplateName: Optional<UserDocumentTemplateId>
  emailErrorCode: Optional<string>
  suggestedEmail: Optional<string>
  isEmailSuggested: boolean
  isEmailValidating: boolean
  signUpProcess: FetchStatuses
  signUpInfo: SignUpInfo
  tests: Record<string, string | boolean>
  isThankYouPageVisible: boolean
}
