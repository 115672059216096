import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Icon20, Icon24 } from 'builder/components/Icon'

export const Container = styled.div`
  display: none;

  ${Media.Phone`
    display: block;
  `}
`

export const TopContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 24px 0 4px;
  justify-content: center;
  align-items: center;
  background-color: white;
  flex-direction: column;
`

export const TabContainer = styled.div`
  display: flex;
  cursor: pointer;
`

export const Tab = styled.p`
  color: ${Colors.Blue50};
  ${FontWeights.Medium};
  ${Typography.Subhead};
`

export const ArrowDown = styled(Icon20.ChevronDown)`
  color: ${Colors.Blue50};
`

export const TabSwitcher = styled.div`
  padding: 7px 7px 7px 16px;
  max-width: 335px;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 30px;
  background-color: white;
  position: absolute;
  bottom: -18px;
  z-index: 500;
  left: 50%;
  transform: translate(-50%);
  cursor: pointer;
  box-shadow: 0px 0px 1px 0px rgba(15, 56, 113, 0.32), 0px 2px 4px -1px rgba(15, 56, 113, 0.08),
    0px 8px 20px -4px rgba(15, 56, 113, 0.12);
`

export const TabInSwitcher = styled.p`
  color: ${Colors.Neutral90};
  ${FontWeights.Medium};
  ${Typography.Subhead};
`

export const ArrowRightBackground = styled.div`
  margin-left: auto;
  background-color: ${Colors.Blue10};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ArrowRight = styled(Icon24.ChevronRight)`
  color: ${Colors.Blue50};
`
