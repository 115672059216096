export const parseDateForDatePicker = (value: string) => {
  let includesJapaneseCharsFormat = /([0-9]{4}年[0-9]{1}月[0-9]{2}日)/
  let dayMonthYearDottedFormat = /[0-9]{2}.[0-9]{2}.[0-9]{4}/
  let dayMonthYearSlashedFormat = /[0-9]{2}\/[0-9]{2}\/[0-9]{4}/
  let parsedValue = value
  if (value) {
    if (includesJapaneseCharsFormat.test(value)) {
      parsedValue = `${value.substring(0, 4)}.${value.substring(5, 6)}.${value.substring(7, 9)}`
    }
    if (dayMonthYearDottedFormat.test(value)) {
      parsedValue =
        +value.substring(0, 2) > 12
          ? `${value.substring(3, 5)}.${value.substring(0, 2)}.${value.substring(6, 10)}`
          : value
    }
    if (dayMonthYearSlashedFormat.test(value)) {
      parsedValue =
        +value.substring(0, 2) > 12
          ? `${value.substring(3, 5)}/${value.substring(0, 2)}/${value.substring(6, 10)}`
          : value
    }
    let parsedDate = new Date(parsedValue)
    return isNaN(parsedDate.getTime()) ? '' : parsedDate
  }
  return ''
}

export const formatDate = (value: Date) => {
  const date = value
  const day = `${date.getDate()}`.padStart(2, '0')
  const month = `${date.getMonth() + 1}`.padStart(2, '0')
  const year = `${date.getFullYear()}`
  return `${year}.${month}.${day}`
}
