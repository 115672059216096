import { configureStore, Reducer } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'

import { reducer as resumeEditorReducer, ResumeEditorStore } from 'builder/modules/resumeEditor'
import { reducer as coverLetterEditorReducer } from 'builder/modules/coverLetterEditor'
import { reducer as generalEditorReducer } from 'builder/modules/generalEditor'
import { reducer as renderingReducer } from 'builder/modules/rendering'
import { reducer as panelReducer } from 'builder/modules/panel'
import { reducer as resumeReviewReducer } from 'builder/modules/resumeReview'
import { reducer as jobTrackingReducer } from 'builder/modules/jobTracking'
import { reducer as signInReducer } from 'builder/modules/signIn'
import { reducer as signUpReducer } from 'builder/modules/signUp'
import { reducer as userReducer } from 'builder/modules/user'
import { reducer as initReducer } from 'builder/modules/init'
import { reducer as uiReducer } from 'builder/modules/ui'
import { reducer as coRegistrationReducer } from 'builder/modules/coRegistration'
import { reducer as careerPathReducer } from 'builder/modules/careerPath'
import { reducer as offerAnalyzerReducer } from 'builder/modules/offerAnalyzer'
import { reducer as uploadResumeReducer } from 'builder/modules/uploadResume'
import { reducer as critiqueReviewReducer } from 'builder/modules/resumeCritiqueReview'
import { reducer as enterpriseModalReducer } from 'builder/modules/enterpriseModal'
import { reducer as careerPath2Reducer } from 'builder/modules/careerPath2'
import { reducer as careerCoachingReducer } from 'builder/modules/careerCoaching'
import { reducer as careerPlanningReducer } from 'builder/modules/careerPlanning'
import { reducer as lloydReducer } from 'builder/modules/lloyd'
import { reducer as sevenStoriesReducer } from 'builder/modules/sevenStories'
import { reducer as eventsReducer } from 'builder/modules/events'
import { reducer as resumeDistributionReducer } from 'builder/modules/resumeDistribution'
import { reducer as careerProfileReducer } from 'builder/modules/careerProfile'
import { reducer as interviewReducer } from 'builder/modules/interview'
import { reducer as dashboardReducer } from 'builder/modules/dashboard'
import { reducer as autoApplyReducer } from 'builder/modules/autoApply/autoApplyModule'
import { reducer as jobSearchReducer } from 'builder/modules/jobSearch/jobSearchModule'

export const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
  reducer: {
    generalEditor: generalEditorReducer,
    resumeEditor: resumeEditorReducer as Reducer<ResumeEditorStore>,
    coverLetterEditor: coverLetterEditorReducer,
    rendering: renderingReducer,
    panel: panelReducer,
    resumeReview: resumeReviewReducer,
    jobSearch: jobSearchReducer,
    jobTracking: jobTrackingReducer,
    signIn: signInReducer,
    signUp: signUpReducer,
    user: userReducer,
    application: initReducer,
    ui: uiReducer,
    coRegistration: coRegistrationReducer,
    careerPath: careerPathReducer,
    careerPath2: careerPath2Reducer,
    offerAnalyzer: offerAnalyzerReducer,
    uploadResume: uploadResumeReducer,
    critiqueReview: critiqueReviewReducer,
    enterpriseModal: enterpriseModalReducer,
    careerCoaching: careerCoachingReducer,
    careerPlanning: careerPlanningReducer,
    lloyd: lloydReducer,
    sevenStories: sevenStoriesReducer,
    events: eventsReducer,
    resumeDistribution: resumeDistributionReducer,
    careerProfile: careerProfileReducer,
    interview: interviewReducer,
    dashboard: dashboardReducer,
    autoApply: autoApplyReducer,
  },
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({ serializableCheck: false, thunk: true }).concat(sagaMiddleware)
  },
})

// Build interfaces for the whole store automatically
// https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-state-type
export type Store = ReturnType<typeof store.getState>
export type Dispatch = typeof store.dispatch
