export enum TabType {
  recommendation = 'recommendation',
  search = 'search',
}

export const SHOW_CHECK_PROFILE_BANNER_STORAGE_KEY = 'showCheckProfileBanner'

export const ONBOARDING_STRUCTURED_RESUME_DATA_KEY = 'onboardingStructuredResumeData'

export enum RecommendationsRequiredAttributes {
  Profile = 'profile',
  TargetRole = 'target_role',
  TargetLocation = 'target_location',
  WorkExperience = 'work_experience',
  WorkExperienceWithDetails = 'work_experience_with_description_and_title',
}

export interface Filters {
  onlyRemoteJobs: boolean
  onlyAutoApplyJobs: boolean
  postedWithinDays: number
  radius: number
}
export enum LocationCategory {
  Country = 'country',
  State = 'state',
  County = 'county',
  City = 'city',
  ZipCode = 'zip_code',
}

export type RecommendationsRequiredAttributesType = `${RecommendationsRequiredAttributes}`
