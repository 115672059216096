import { connect } from 'react-redux'
import { actions } from 'builder/modules/signIn'
import SignIn from 'builder/components/SignIn'
import { useConfig } from 'builder/hooks/useConfig'
import { ConfigScopesEnum } from 'builder/modules/init'

const SignInView = props => {
  const config = useConfig(ConfigScopesEnum.signUp)

  const newProps = {
    isWidget: config.features.isWidget,
    ...props,
  }

  return <SignIn {...newProps} />
}

// ---
// Connect to data
// ---
const mapStateToProps = state => ({
  ...state.signIn,
})

const mapDispatchToProps = {
  checkEmail: actions.checkEmailRequest,
  sendMagicLink: actions.sendMagicLinkRequest,
  sendPasswordLink: actions.sendPasswordLinkRequest,
  signIn: actions.signInRequest,
  checkMagicLink: actions.checkMagicLinkRequest,
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInView)
