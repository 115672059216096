import { ReactNode, useState } from 'react'
import { Transition } from 'react-transition-group'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'

import * as Styles from './styles'

type Props = {
  timeout: number
  children: ReactNode
}

export const HorizontalTransitionFade = ({ timeout, children, ...props }: Props) => {
  const [isMounted, setIsMounted] = useState(false)

  useEffectOnMount(() => {
    setIsMounted(true)
  })

  return (
    <Transition in={isMounted} timeout={timeout}>
      {state => (
        <Styles.Wrapper transitionState={state} {...props}>
          {children}
        </Styles.Wrapper>
      )}
    </Transition>
  )
}
