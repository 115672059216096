import styled, { css } from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Shadows from 'builder/styles/shadows'

export const Container = styled.div`
  width: 100%;
  background-color: ${Colors.White};
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`

export const TopContent = styled.div``

export const Header = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};
  width: 366px;
`

export const Form = styled.form`
  gap: 32px;
  display: flex;
  flex-direction: column;
`

export const Options = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Radio = styled.label<{ checked: boolean }>`
  display: flex;
  align-items: flex-start;
  color: ${props => (props.checked ? Colors.Neutral90 : Colors.Neutral60)};
  transition: color 0.15s;
  cursor: pointer;
  transition: color ease-in 0.3s;

  &:hover {
    color: ${Colors.Neutral90};
  }

  &:last-child {
    margin-bottom: 0;
  }
`

export const RadioControl = styled.span<{ checked?: boolean }>`
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid ${Colors.Neutral30};
  transition: all ease-in 0.2s;

  ${Radio}:hover & {
    border-color: ${Colors.Blue50};
  }

  ${Media.Phone`
    margin-top: 0;
  `};

  ${props =>
    props.checked &&
    css`
      border-width: 2px;
      border-color: ${Colors.Blue50};
      background-color: ${Colors.Blue50};
    `}

  &:before {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${Colors.White};
    opacity: ${props => (props.checked ? 1 : 0)};
  }
`

export const Option = styled.div<{ checked: boolean }>`
  width: 416px;
  height: 64px;
  padding: ${props => (props.checked ? `8px 16px` : `9px 16px`)};
  border-radius: 8px;
  border: ${props =>
    props.checked ? `2px solid ${Colors.Blue50}` : `1px solid ${Colors.Neutral15}`};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: shadow ease-in 0.3s;

  ${Media.Phone`
    width:100%;
  `};

  &:hover {
    ${Shadows.lightWithBorder.low};

    ${RadioControl} {
      border-color: ${Colors.Blue50};
    }
  }
`

export const OptionContent = styled.div`
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const OptionContentBox = styled.div``

export const SubLabel = styled.div`
  color: ${Colors.Neutral50};
  ${Typography.Caption};
  ${FontWeights.Regular};
`

export const ButtonContainer = styled.div`
  display: flex;
  width: 416px;
  justify-content: space-between;
  align-items: center;

  ${Media.Phone`
    width:335px;
  `};
`
