import { useCallback } from 'react'
import * as React from 'react'
import { trackInternalEvent } from '@rio/tracking'
import map from 'lodash/map'
import { useI18n } from 'builder/hooks/useI18n'
import { TextAreaField } from 'builder/components/TextField'
import { InternalEvents } from '../SubscriptionCancellationFormNew/common/types'
import { ReasonType, Reason, ReasonToUse, ReasonToCancel } from './types'
import {
  SectionContainer,
  SectionTitle,
  Radio,
  RadioControl,
  RadioText,
  TextFieldWrapper,
  Notice,
  NoticeContent,
  NoticeTitle,
  NoticeDescription,
  NoticeButton,
} from './styles'

interface Props {
  type: ReasonType
  selectedReason: Reason | null
  reasons: typeof ReasonToUse | typeof ReasonToCancel
  customAnswer: string
  onReasonSelect: (type: ReasonType, reason: Reason) => void
  onCustomAnswerChange: (type: ReasonType, newCustomText: string) => void
}

const reasonsToContact = [ReasonToCancel.difficulties, ReasonToCancel.bug]

export const FormSection = ({
  type,
  reasons,
  selectedReason,
  customAnswer,
  onReasonSelect,
  onCustomAnswerChange,
}: Props) => {
  const { i18n } = useI18n()
  const hostName = window.location.hostname
  const contactSupportURL = hostName === 'career.io' ? 'https://career.io/contact-us' : '/contact'
  const cancellationCopy = i18n.t('builder.subscription_cancellation.form.reason_to_use')
  const superAppCancellationCopy = cancellationCopy.replace('Resume.io', 'Career.io')
  const isSuperApp = hostName === 'career.io' || hostName === 'staging.career.io'

  const handleContact = useCallback(() => {
    trackInternalEvent(InternalEvents.abort, { reason: 'contacted support' })
  }, [])

  return (
    <SectionContainer>
      {type === 'reason_to_use' ? (
        <SectionTitle>{isSuperApp ? superAppCancellationCopy : cancellationCopy}</SectionTitle>
      ) : (
        <SectionTitle>
          {i18n.t('builder.subscription_cancellation.form.reason_to_cancel')}
        </SectionTitle>
      )}

      {map(reasons, (reason: Reason) => (
        <React.Fragment key={reason}>
          {/* Radio button */}
          <Radio checked={selectedReason === reason} onClick={() => onReasonSelect(type, reason)}>
            <RadioControl checked={selectedReason === reason} />
            <RadioText>{i18n.t(`builder.subscription_cancellation.form.${reason}`)}</RadioText>
          </Radio>

          {/* Have difficulties or an issue → We are ready to help */}
          {reason === selectedReason && reasonsToContact.includes(reason as ReasonToCancel) && (
            <Notice>
              <NoticeContent>
                <NoticeTitle>
                  {i18n.t('builder.subscription_cancellation.form.we_can_help.title')}
                </NoticeTitle>
                <NoticeDescription>
                  {i18n.t('builder.subscription_cancellation.form.we_can_help.description')}
                </NoticeDescription>
                <NoticeButton as="a" href={contactSupportURL} onClick={handleContact}>
                  {i18n.t('builder.subscription_cancellation.form.we_can_help.action')}
                </NoticeButton>
              </NoticeContent>
            </Notice>
          )}

          {/* Other reason → Type it */}
          {reason === selectedReason && reason === 'other' && (
            <TextFieldWrapper>
              <TextAreaField
                rows={4}
                label={i18n.t('builder.subscription_cancellation.form.tell_us_reason')}
                value={customAnswer}
                onChange={e => onCustomAnswerChange(type, e.target.value)}
              />
            </TextFieldWrapper>
          )}
        </React.Fragment>
      ))}
    </SectionContainer>
  )
}
