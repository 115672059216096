import { memo, useEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { Icon20 } from 'builder/components/Icon'
import { i18n as I18n } from 'builder/utils/i18n'
import { PrefillResumeEvents } from 'builder/modules/constants'
import {
  OptionsContainerB as OptionsContainer,
  DesktopClose as Close,
  TitleB as Title,
  SubTitleB as SubTitle,
} from '../styles'
import OptionCards from './OptionCards'

type Props = {
  onClose: () => void
}

const OptionsViewB = (props: Props) => {
  const { onClose } = props
  const CloseIcon = Icon20.Close

  useEffect(() => {
    trackInternalEvent(PrefillResumeEvents.seeStartScreen)
  }, [])

  const onStartScreenClose = () => {
    trackInternalEvent(PrefillResumeEvents.clickCrossButtonStartScreen)
    onClose()
  }

  return (
    <OptionsContainer>
      <Close onClick={onStartScreenClose}>
        <CloseIcon />
      </Close>
      <Title>{I18n.t('builder.prefill_resume.options_view.heading')}</Title>
      <SubTitle>{I18n.t('builder.prefill_resume.options_view.sub_heading')}</SubTitle>
      <OptionCards {...{ onClose }} />
    </OptionsContainer>
  )
}

export default memo(OptionsViewB)
