import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Button from 'builder/components/Button'
import Icon from 'builder/components/Icon'

const { Blue50, Neutral50, Neutral70, White } = Colors
const { DemiBold, Medium } = FontWeights
const { Body, S } = Typography

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 544px;
  border-radius: 16px;
  background-color: ${White};

  ${Media.Phone`
    width: 343px;
    border-radius: 12px;
  `}
`

export const CloseIconWrapper = styled.div<{ showHoverState: boolean }>(({ showHoverState }) => {
  return css`
    ${showHoverState
      ? ''
      : `& svg {
          &:hover {
            background: ${White};
            fill: ${Neutral70};
            cursor: default;
          }
        }`}
  `
})

export const CloseIcon = styled(Icon.AddSmall)`
  fill: ${Neutral70};
  background: ${White};
  border-radius: 50%;
  position: absolute;
  left: 484px;
  top: 32px;
  height: 28px;
  width: 28px;
  cursor: pointer;
  transform: rotate(225deg);

  &:hover {
    background: ${Blue50};
    fill: ${White};
  }

  ${Media.Phone`
    height: 24px;
    width: 24px;
    left: 299px;
    top: 20px;
    z-index: 2;
  `}
`

export const Visual = styled.img`
  border-radius: 16px 16px 0 0;
  width: 544px;
  height: 300px;
  ${Media.Phone`
    width: 343px;
    height: 188px;
    border-radius: 12px 12px 0 0;
  `}
`

export const Content = styled.div`
  padding: 32px;
  flex-grow: 1;

  ${Media.Phone`
    padding: 20px;
  `}
`

export const Title = styled.p`
  ${S};
  ${DemiBold};
  margin-bottom: 12px;

  ${Media.Phone`
    margin-bottom: 8px;
  `}
`

export const Text = styled.p`
  color: ${Neutral50};
  font-size: 19px;
  font-weight: 400;
  line-height: 24px;

  ${Media.Phone`
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  `}
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 32px 32px;

  ${Media.Phone`
    flex-direction: row;
    padding: 0 20px 20px;
  `}

  & ${Button} {
    margin-left: 8px;
    ${Body}
    ${Medium};
    padding: 12px 24px;

    ${Media.Phone`
      width: max-content;
      padding: 8px 12px;
    `}
  }
`
