import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'

interface ServiceProps {
  color: string
  bgColor: string
}

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  flex-wrap: wrap;
  width: 100%;
`

export const Service = styled.div<{ service: ServiceProps }>`
  display: ${({ service }) => (service.bgColor ? 'inline-block' : 'none')};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;
  height: 24px;
  background: ${({ service }) => service.bgColor};
  color: ${({ service }) => service.color};
  border-radius: 2px;
`

export const ServiceLabel = styled.p`
  ${FontWeights.Medium};
  ${Typography.Tiny};
  display: flex;
  align-items: center;
  justify-content: center;
`
