import { useMemo } from 'react'
import { CareerInsightsJobListData } from 'builder/modules/careerProfile'
import { JobInsight } from '../types'

export const useJobList = (data: CareerInsightsJobListData | undefined): JobInsight[] => {
  return useMemo(() => {
    if (!data) {
      return []
    }

    const { jobTitle: jobTitles, salaryRange: salaryRanges, jobDescription: jobDescriptions } = data

    return jobTitles.map((title, index) => ({
      title,
      salaryRange: salaryRanges[index],
      description: jobDescriptions[index],
    }))
  }, [data])
}
