import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Sizes from 'builder/styles/sizes'

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  border-radius: ${Sizes.S};
  padding: ${Sizes.L};
  background-color: ${Colors.Indigo10};
  gap: ${Sizes.S};
`

export const TextContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${Sizes.XS};
`

export const Header = styled.div`
  display: flex;
  gap: ${Sizes.XS};
  align-items: center;
  ${Typography.S};
  ${FontWeights.DemiBold};
  color: ${Colors.Indigo80};
`

export const Description = styled.div`
  color: ${Colors.Neutral60};
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: ${Sizes.S};
`
