import styled from 'styled-components'
import Colors from 'builder/styles/colors'

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 2px;
  border-radius: 2px;
  overflow: hidden;
  background-color: ${Colors.Neutral20};
`

export const LineWrapper = styled.div`
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  width: 100%;
  transition: ${({ animated }) => (animated ? 'transform 0.2s ease' : 'none')};
  transform: ${({ translate }) => `
    translateX(${translate}px);
  `};
`

export const Line = styled.div`
  position: absolute;
  transform-origin: left center;
  width: ${({ width }) => width}px;
  height: 100%;
  background-color: ${Colors.Blue50};

  &:first-child {
    left: -184px;
  }

  &:nth-child(2) {
    left: 0;
  }

  &:nth-child(3) {
    left: 184px;
  }
`
