import { useI18n } from 'builder/hooks/useI18n'
import { useSelector } from 'react-redux'
import { PersonalEditorField } from './PersonalEditorField'

export function Address(props) {
  const { i18n } = useI18n()
  const address = useSelector(state => state.resumeEditor.resume.address)

  return (
    <>
      {/* Address */}
      <PersonalEditorField
        label={i18n.t('builder.resume_editor.address')}
        value={address}
        name="address"
      />
    </>
  )
}
