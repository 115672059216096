import styled, { css } from 'styled-components'

import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import { TRLogo } from 'builder/components/Icon/20x20'

import Placholder from '../assets/placeholder.png'

export const CandidateContainer = styled.div`
  display: flex;
  margin: 44px 0 40px;
  gap: 36px;

  ${Media.Tablet`
    margin: 32px 0 0;
    gap: 32px;
  `}

  ${Media.Phone`
    margin: 16px 0 12px;
    gap: 20px;
  `}
`

export const CandidateBox = styled.div`
  width: 50%;
  height: 220px;
  position: relative;
`

export const CandidatePreview = styled.div`
  height: 220px;
  overflow: hidden;
`

export const CandidatePreviewShadow = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 48px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
`

export const CandidateResumeImage = styled.div<{ $showPlaceholder: boolean }>`
  border-radius: 4px;
  width: 182px;
  margin: 0 auto;
  box-shadow: 0px 0px 1px 0px rgba(15, 56, 113, 0.32), 0px 2px 4px -1px rgba(15, 56, 113, 0.08),
    0px 8px 20px -4px rgba(15, 56, 113, 0.12);

  ${({ $showPlaceholder }) =>
    $showPlaceholder &&
    css`
      background-image: url(${Placholder});
      background-size: cover;
      background-position: top;
      height: 100%;
      margin-top: 1px;
    `}
`

export const CandidateInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 12px;
`

export const CandidateInfoText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const CandidateInfoLabel = styled.div`
  color: ${Colors.Neutral50};

  ${Typography.Caption}
  ${FontWeights.Regular}
`

export const CandidateInfoHeader = styled.div`
  color: ${Colors.Neutral90};

  ${Typography.Body}
  ${FontWeights.Regular}
`

export const IconContainer = styled.div`
  position: absolute;
  top: 12px;
  right: 22px;

  ${Media.Tablet`
    right: 22px;
  `}

  ${Media.Phone`
    right: 12px;
  `}
`

export const TrResumeLabel = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: ${Colors.Neutral5};
  border: 1px solid ${Colors.Blue40};
  padding: 8px 8px;
  border-radius: 8px;
  bottom: 24px;
  right: 25px;
  z-index: 1;

  ${Media.Tablet`
    right: 35px;
  `}

  ${Media.Phone`
    right: 17px;
  `}
`

export const TrResumeLabelIcon = styled(TRLogo)``
export const TrResumeLabelText = styled.div`
  ${Typography.Caption}
  ${FontWeights.Regular}
`
