import mapValues from 'lodash/mapValues'
import { i18n } from 'builder/utils/i18n'
import { ReasonType, SelectedState, CustomAnswersState, SubmitPayload } from './types'

/**
 * Validates the form state.
 * Users have to select or type reasons for both categories.
 */
export const validate = (
  selectedReasons: SelectedState,
  customAnswers: CustomAnswersState,
): boolean => {
  let key: keyof typeof ReasonType
  for (key in ReasonType) {
    const type = ReasonType[key]
    if (selectedReasons[type] === null) return false
    if (selectedReasons[type] === 'other' && customAnswers[type].length === 0) return false
  }
  return true
}

/**
 * Converts the form state into the backend compatible format.
 * The API receives formatted texts (same as what you see it in UI).
 */
export const formatSubmitPayload = (
  selectedReasons: SelectedState,
  customAnswers: CustomAnswersState,
): SubmitPayload => {
  return mapValues(selectedReasons, (reason, type) => {
    if (reason === 'other') return customAnswers[type as keyof CustomAnswersState]
    if (type === 'reason_to_cancel') return `${reason}`
    return i18n.t(`builder.subscription_cancellation.form.${reason}`)
  })
}
