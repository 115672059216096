import { trackInternalEvent } from '@rio/tracking'

import { useUser } from 'builder/hooks/useUser'
import { useI18n } from 'builder/hooks/useI18n'
import { goToBillingPlanPage } from 'builder/utils/goToBillingPlanPage'
import { Candidate, DistributionDates } from 'builder/modules/resumeDistribution'
import useWebsiteHost from 'builder/components/CIO-Dasboard-2.0/hooks/useWebsiteHost'

import { RecruitersInfo } from 'builder/views/ResumeDistributionDashboardView/components/DashboardBanner/RecruitersInfo/RecruitersInfo'
import { NextSchedule } from 'builder/views/ResumeDistributionDashboardView/components/DashboardBanner/NextSchedule/NextSchedule'

import { BannerSkeleton } from '../Skeleton/Skeleton'
import { TRANSLATION } from '../constants'
import * as Styles from './styles'

type HeaderProps = {
  candidate: Candidate
  distributionDates: DistributionDates
  distributedRecruiterCount: number
  isDistributionDatesLoading: boolean
}

export const Header = ({
  candidate,
  distributionDates,
  distributedRecruiterCount,
  isDistributionDatesLoading,
}: HeaderProps) => {
  const user = useUser()
  const { i18n } = useI18n()
  const { getHost } = useWebsiteHost()

  const upgradeNow = () => {
    trackInternalEvent('rd_click_upgrade', { website_host: getHost })
    goToBillingPlanPage({ upgradeURL: user?.billingInfo.upgradeUrl })
  }

  if (isDistributionDatesLoading) {
    return <BannerSkeleton />
  }

  return (
    <Styles.Container>
      <RecruitersInfo
        isFromWidget
        totalRecruiters={distributedRecruiterCount}
        distributionWeek={candidate?.distributionWeek || 0}
      />
      <Styles.Divider />
      {!user?.hasPremiumFeatures ? (
        <Styles.PremiumContainer>
          <Styles.PremiumTextContainer>
            <Styles.PremiumIcon />
            <Styles.PremiumText>{i18n.t(`${TRANSLATION}.premium_text`)}</Styles.PremiumText>
          </Styles.PremiumTextContainer>
          <Styles.PremiumContainerAction onClick={upgradeNow}>
            {i18n.t(`${TRANSLATION}.premium_button`)}
          </Styles.PremiumContainerAction>
        </Styles.PremiumContainer>
      ) : (
        <NextSchedule
          isFromWidget
          hideSchedule={!user?.hasPremiumFeatures}
          candidate={candidate}
          nextDistribution={distributionDates.nextDistribution}
          isLoading={false}
          action={() => {}}
        />
      )}
    </Styles.Container>
  )
}
