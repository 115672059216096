import loaderImage from 'images/builder/resume-editor/editor-loader.svg'
import { EditorPanel, Loader, LoaderContainer } from '../styles'

const EditorWithoutContent = (): JSX.Element => {
  return (
    <EditorPanel>
      <LoaderContainer>
        <Loader src={loaderImage} />
      </LoaderContainer>
    </EditorPanel>
  )
}
export default EditorWithoutContent
