import styled from 'styled-components'
import Button from 'builder/components/Button'

import Typography from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

import resumePlaceholder from './../assets/resume-placeholder.png'

export const ReplaceResumeButton = styled(Button)`
  display: ${props => props.display};
  size: small;
  ${Typography.Caption};
  background: ${Colors.Blue10};
  color: ${Colors.Blue50};
  border-radius: 28px;
  height: 28px;
  padding: 1px 6px;
  margin: 23% auto;

  &:hover {
    background: ${Colors.Blue20};
  }

  ${Media.Tablet`
    display: block;
  `}

  ${Media.Phone`
    display: block;
  `}
`

export const Resume = styled.div<{ thumbnail?: string }>`
  height: 60px;
  width: 104px;
  border-radius: 4px 4px 0 0;
  background-image: url(${props => props.thumbnail || resumePlaceholder});
  box-shadow: rgb(255 255 255) 0px -12px 20px 3px inset;
  border-left: 1px solid #8080801c;
  border-top: 1px solid #8080801c;
  border-right: 1px solid #8080801c;
  background-size: cover;

  &:hover ${ReplaceResumeButton} {
    display: block;
  }
`

export const Icon = styled.div`
  float: right;
`
