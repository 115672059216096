import { describeAccomplishmentsText } from 'builder/modules/sevenStories'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import {
  TipsContainer,
  TooltipQuestionWrapper,
  TooltipQuestionWrapperTablet,
  TipsTitle,
  HintIcon,
} from './style'

export const ToolTip = () => {
  const { isTablet } = useMediaQueries()

  return (
    <TipsContainer>
      {isTablet ? (
        <TooltipQuestionWrapperTablet
          value={describeAccomplishmentsText}
          position="bottom"
          multiline
        >
          <TipsContainer className="tips-container">
            <TipsTitle>Tips</TipsTitle>
            <HintIcon className="hint-icon" />
          </TipsContainer>
        </TooltipQuestionWrapperTablet>
      ) : (
        <TooltipQuestionWrapper value={describeAccomplishmentsText} position="right" multiline>
          <TipsContainer className="tips-container">
            <TipsTitle>Tips</TipsTitle>
            <HintIcon className="hint-icon" />
          </TipsContainer>
        </TooltipQuestionWrapper>
      )}
    </TipsContainer>
  )
}
