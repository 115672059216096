import { Navigate, Route, Routes } from 'react-router-dom'
import { CareerInsights, useCareerInsightsStatus } from 'builder/components/CareerInsights'
import { useI18n } from 'builder/hooks/useI18n'
import { CareerInsightsProcessingStatus } from 'builder/modules/careerProfile'
import * as Styled from './styles'
import CareerChatView from './CareerChat'
import { ProfessionalPitchView } from './components/ProfessionalPitchView'
import { CoverLetterSampleView } from './components/CoverLetterSampleView'
import { StatusRouteGuard } from './components/StatusRouteGuard'
import { MyCareerProfileView } from './components/MyCareerProfileView'
import { CareerProfileEditorView } from './components/CareerProfileEditorView'
import { PaywallGuard } from './components/PaywallGuard'

export const CareerProfileView = () => {
  const { i18n } = useI18n()
  const careerInsightsStatus = useCareerInsightsStatus()
  const careerInsightsReady = careerInsightsStatus === CareerInsightsProcessingStatus.success

  return (
    <Routes>
      <Route path="chat" element={<CareerChatView />} />
      <Route
        path="pitch"
        element={
          <StatusRouteGuard type="pitch">
            <PaywallGuard type="pitch">
              <ProfessionalPitchView />
            </PaywallGuard>
          </StatusRouteGuard>
        }
      />
      <Route
        path="cover-letter"
        element={
          <StatusRouteGuard type="cover-letter">
            <PaywallGuard type="cover-letter">
              <CoverLetterSampleView />
            </PaywallGuard>
          </StatusRouteGuard>
        }
      />
      <Route path="edit" element={<CareerProfileEditorView />} />
      <Route
        path="*"
        element={
          <Styled.CareerProfileViewContainer>
            {careerInsightsReady ? (
              <Styled.CareerProfileViewNavigation>
                <Styled.CareerProfileViewLink to="profile">
                  {i18n.t(`builder.career_assessment.results.navigation_tabs.my_profile`)}
                </Styled.CareerProfileViewLink>
                <Styled.CareerProfileViewLink to="insights">
                  {i18n.t(`builder.career_assessment.results.navigation_tabs.career_insights`)}
                </Styled.CareerProfileViewLink>
              </Styled.CareerProfileViewNavigation>
            ) : null}
            <Routes>
              <Route
                path="insights"
                element={
                  <StatusRouteGuard type="insights">
                    <CareerInsights />
                  </StatusRouteGuard>
                }
              />
              <Route path="profile" element={<MyCareerProfileView />} />
              <Route path="/" element={<Navigate to="profile" />} />
            </Routes>
          </Styled.CareerProfileViewContainer>
        }
      />
    </Routes>
  )
}
