import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Button from 'builder/components/Button'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import Sizes from 'builder/styles/sizes'
import { PhrasesSuggestionContainer } from '../PhrasesSuggestion'

export const Container = styled(PhrasesSuggestionContainer)`
  overflow: visible;
  width: 358px;
  padding: 6px;
  border-radius: 8px;

  ${props => Media.Phone`
    left: 50%;
    bottom: 50px;
    transform: translate(-50%, 8px);

    ${
      props.isVisible &&
      css`
        transform: translate(-50%, 0);
        opacity: 1;
      `
    }
  `}

  ${props => Media.Desktop`
    margin-left: 16px;
    left: 100%;
    top: 21%;
    transform: translate(-8px, -50%);

    ${
      props.isVisible &&
      css`
        transform: translate(0, -50%);
        opacity: 1;
      `
    }
  `}
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.Indigo10};
  gap: 8px;
  padding: 12px;
  border-radius: 8px;
`

export const Title = styled.div`
  ${Typography.Caps};
  ${FontWeights.DemiBold};
  color: ${Colors.Indigo70};
  display: flex;
  flex-direction: row;
  gap: 4px;
`

export const Badge = styled.div`
  ${Typography.Tiny};
  ${FontWeights.DemiBold};
  display: inline-flex;
  height: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: ${Sizes['3XS']} 6px;
  border-radius: ${Sizes['5XL']};
  background: ${Colors.Indigo60};
  color: ${Colors.Indigo10};
  text-align: center;
  cursor: pointer;
`

export const InfoText = styled.p`
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${Colors.Indigo80};
`

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const ActionButton = styled(Button)`
  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${Colors.Indigo60};
  background-color: ${Colors.Indigo10};
  padding: 9px 0 8px;
  width: 100%;

  &:hover {
    background-color: ${Colors.Indigo20};
    color: ${Colors.Indigo70};
  }
`

export const Separator = styled.div`
  width: 2px;
  height: 24px;
  background-color: #d9d9d9;
`

export const AddPreWrittenPhrasesButton = styled(Button)`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral80};
  justify-content: flex-start;
  gap: ${Sizes['2XS']};

  padding: 12px;
  width: 100%;
  background-color: ${Colors.White};

  &:hover {
    background-color: ${Colors.White};
    color: ${Colors.Indigo60};
  }
`

export const TooltipDescription = styled.div``

export const AdditionalActionsContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 4px;
  margin-top: 4px;

  &:empty {
    display: none;
  }
`

export const Divider = styled.div`
  flex: 0 0 1px;
  margin: 0 12px;
  background: ${Colors.Neutral15};
`
