import EditorField from 'builder/components/EditorField'
import DateRangePicker from 'builder/components/DateRangePicker'
import { useI18n } from 'builder/hooks/useI18n'
import { useLanguageCardContext } from '../../ExpandableCardContext'

const EmploymentDuration: React.FC = () => {
  const { item, onCardValueChange } = useLanguageCardContext()
  const { i18n } = useI18n()

  const updateDateRange = (value: unknown) => {
    onCardValueChange(value, true)
  }

  return (
    <EditorField>
      <DateRangePicker
        value={item}
        onChange={updateDateRange}
        currentlyLabel={i18n.t('builder.date_range_picker.currently.work')}
      />
    </EditorField>
  )
}
export default EmploymentDuration
