import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const BannerSection = styled.div`
  width: 100%;
  height: 117px;
  border-radius: 12px;
  background-color: ${Colors.Blue10};
  margin: 5px 0;
`

export const MainSection = styled.div`
  display: flex;
`

export const MainSectionText = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`

export const MainSectionTitle = styled.div`
  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${Colors.Blue70};
  display: block;
  margin-bottom: 5px;
  width: 143px;
`

export const MainSectionSubTitle = styled.div`
  color: ${Colors.Blue70};
  ${Typography.Caption};
  flex: 1;
`

export const ImageScoreSection = styled.div`
  position: absolute;
  right: 18px;
`

export const BannerImage = styled.img`
  border-radius: 4px;
`
