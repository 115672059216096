import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Icon24 from 'builder/components/Icon'

export const Container = styled.div`
  display: flex;
  margin: 0 8px 16px;
  padding: 8px 12px;
  border-radius: 12px;
  align-items: center;

  &:hover {
    background: ${Colors.Neutral5};
  }
`

export const SubMenuIcon = styled(Icon24.ChevronRight)`
  margin-left: auto;

  path {
    fill: ${Colors.Neutral100};
  }
`
