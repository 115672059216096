import { useContext, useEffect, useMemo, useRef } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { TunerContext } from '../../contexts/TunerContext'
import { TunerContextValue } from '../../types'
import { TunerBannerPreview } from './TunerBannerPreview'
import { TunerBannerScore } from './TunerBannerScore'
import { TunerBannerTask } from './TunerBannerTask'

import { TunerContainer, TunerContent, TunerMain, TunerTitle, TunerText } from './styles'

export const TunerBanner = () => {
  const { tasks, resume, score } = useContext(TunerContext) as TunerContextValue
  const { i18n } = useI18n()
  const isTracked = useRef(false)

  const highlightColor = useMemo(() => {
    if (score >= 7) return '#def4e6'
    if (score >= 5) return '#e4e1f5'
    return '#f5e1f3'
  }, [score])

  useEffect(() => {
    if (isTracked.current) return

    trackInternalEvent('see_tuner_banner', {
      resume_id: resume.id,
      tuner_score: score,
      incomplete_tasks: tasks?.filter(t => !t.done).map(t => t.id),
    })

    isTracked.current = true
  }, [resume.id, score, tasks])

  return (
    <TunerContainer highlightColor={highlightColor}>
      <TunerContent>
        <TunerMain>
          <TunerTitle>{i18n.t('builder.resume_tuner.banner.title')}</TunerTitle>
          <TunerText>{i18n.t('builder.resume_tuner.banner.text')}</TunerText>

          {tasks?.map(task => (
            <TunerBannerTask key={task.id} task={task} />
          ))}
        </TunerMain>

        <TunerBannerPreview highlightColor={highlightColor} />

        <TunerBannerScore />
      </TunerContent>
    </TunerContainer>
  )
}
