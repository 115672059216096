import styled from 'styled-components'
import Colors from 'builder/styles/colors'

export const Container = styled.div``

export const Buttons = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(2, 1fr);
`

export const Note = styled.div`
  margin-top: 24px;
  color: ${Colors.Neutral50};
  text-align: center;

  & a {
    color: ${Colors.Blue50};
  }

  & a:hover {
    color: ${Colors.Blue60};
  }
`
