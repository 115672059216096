import { useRef, useEffect, useLayoutEffect, useState, forwardRef, ForwardedRef } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { reflow } from 'builder/utils/reflow'
import { useForwardedRef } from 'builder/hooks/useForwardedRef'
import { Icon20, Icon24 } from 'builder/components/Icon'
import { useI18n } from 'builder/hooks/useI18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/resumeEditor'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import { suggestionScopeToSectionName } from '../RichTextArea'
import { Props } from './types'
import {
  Container,
  Header,
  Title,
  Description,
  Suggestion,
  Separator,
  Actions,
  AttemptsLeft,
  RetryButton,
  UseButton,
  Icon,
  CloseButton,
  Scroll,
  CloseButtonIconContainer,
} from './styles'
import useAIProfileSummaryResult from './hooks/useAIProfileSummaryResult'
import { AI_PROFILE_SUMMARY_RESULT_MODAL } from './constants'

type Ref = ForwardedRef<HTMLDivElement | null>

const AIProfileSummaryResult = forwardRef((props: Props, ref: Ref) => {
  const {
    suggestionConfig: { scope },
  } = props
  const { i18n } = useI18n()
  const [isVisible, setVisible] = useState(false)
  const container = useForwardedRef(ref)
  const scroll = useRef<HTMLDivElement | null>(null)
  const suggestionRef = useRef<HTMLDivElement>(null)
  const { shouldAnimateResultPopupContent } = useTypedSelector(selectors.aiProfile)
  const {
    generatedContent,
    attemptLeftText,
    isPremiumUser,
    isAnimationActive,
    handleRetry,
    handleUse,
    handleClose,
    animateContent,
    remainingAIGenerationAttempts: remainingAttempts,
  } = useAIProfileSummaryResult(container, suggestionRef, { ...props })

  // Start entering transition
  useLayoutEffect(() => {
    if (container.current) reflow(container.current)
    setVisible(true)
  }, [container])

  useEffect(() => {
    if (generatedContent && shouldAnimateResultPopupContent) {
      animateContent()
    }
  }, [animateContent, shouldAnimateResultPopupContent, generatedContent])

  useEffectOnMount(() => {
    trackInternalEvent('open_ai_suggestion_dialog', {
      section: suggestionScopeToSectionName(scope),
      counter: remainingAttempts === Infinity ? 'unlimited' : remainingAttempts,
    })
  })

  return (
    <Container id={AI_PROFILE_SUMMARY_RESULT_MODAL} ref={container} isVisible={isVisible}>
      <CloseButton onClick={handleClose}>
        <CloseButtonIconContainer>
          <Icon24.Close />
        </CloseButtonIconContainer>
      </CloseButton>
      <Header>
        <Title>{i18n.t('builder.ai_profile_summary.rephrasing_modal.title')}</Title>
        <Description>
          {i18n.t('builder.ai_profile_summary.rephrasing_modal.description')}
        </Description>
      </Header>
      <Separator />
      <Scroll ref={scroll}>
        <Suggestion {...{ isAnimationActive }} ref={suggestionRef}>
          {generatedContent}
        </Suggestion>
      </Scroll>
      <Actions>
        {!isPremiumUser && <AttemptsLeft>{attemptLeftText}</AttemptsLeft>}
        <RetryButton onClick={handleRetry}>
          <Icon>
            <Icon20.Update />
          </Icon>
          {i18n.t('builder.ai_profile_summary.rephrasing_modal.button_text.retry')}
        </RetryButton>
        <UseButton onClick={handleUse}>
          {i18n.t('builder.ai_profile_summary.rephrasing_modal.button_text.use')}
        </UseButton>
      </Actions>
    </Container>
  )
})

export default AIProfileSummaryResult
