import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useI18n } from 'builder/hooks/useI18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { Distribution, actions, selectors } from 'builder/modules/resumeDistribution'
import { formatDate } from 'builder/utils/formatDate'

import { Divider } from 'builder/components/DocumentSelect/styles'
import { Container } from 'builder/components/Container'
import { ButtonTheme } from 'builder/components/Button'
import { List } from 'builder/components/List'
import Spinner from 'builder/components/Spinner'

import { FetchStatuses } from 'builder/modules/constants'
import { Store } from 'builder/modules/store'
import { LoadMoreButton, LoadMoreWrapper, SpinnerWrapper } from './styles'
import { MobileList } from './MobileList/MobileList'
import { DesktopList } from './DesktopList/DesktopList'
import { EmptyList } from './EmptyList/EmptyList'

const LOAD_BATCH = 50
interface RecruiterListProps {
  distribution: Distribution
}

export const RecruiterList = ({ distribution }: RecruiterListProps) => {
  const { i18n } = useI18n()
  const { isPhone } = useMediaQueries()
  const dispatch = useDispatch()
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false)
  const recruiters = useTypedSelector((state: Store) =>
    selectors.recruiters(state, distribution.unixFormat),
  )
  const recruitersFetchStatus = useTypedSelector((state: Store) =>
    selectors.recruitersFetchStatus(state, distribution.unixFormat),
  )

  const hasMoreItems = () => {
    const pagination = recruiters?.pagination
    return (
      pagination?.currentPage &&
      pagination?.totalPages &&
      pagination?.currentPage < pagination?.totalPages
    )
  }

  const getRecruitersLeftNumber = () => {
    const pagination = recruiters?.pagination
    const recruitersLeft =
      (pagination?.totalRecords || 0) - LOAD_BATCH * (pagination?.currentPage || 0)
    return recruitersLeft > 0 ? recruitersLeft : 0
  }

  const distributionDate = () => {
    const { day, month, year } = distribution
    return formatDate(new Date(`${month}-${day}-${year}`), 'MMM do')
  }

  const showSpinnerButton = () => {
    return recruitersFetchStatus !== FetchStatuses.loaded && isLoadingMore
  }

  const loadMore = () => {
    if (hasMoreItems() && recruiters?.pagination.currentPage) {
      dispatch(
        actions.fetchRecruiters({
          date: distribution.unixFormat,
          page: recruiters?.pagination.currentPage,
        }),
      )
      setIsLoadingMore(true)
    }
  }

  useEffect(() => {
    dispatch(actions.fetchRecruiters({ date: distribution.unixFormat }))
  }, [dispatch, distribution])

  if (recruitersFetchStatus === FetchStatuses.loading && !isLoadingMore) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    )
  }

  if (!recruiters?.data.length) {
    return (
      <Container>
        <EmptyList date={distributionDate()} />
      </Container>
    )
  }

  return (
    <Container>
      <List>
        <>
          {recruiters.data.map(recruiter => {
            return isPhone ? (
              <React.Fragment key={recruiter.name}>
                <MobileList recruiter={recruiter} />
                <Divider />
              </React.Fragment>
            ) : (
              <React.Fragment key={recruiter.name}>
                <DesktopList recruiter={recruiter} />
                <Divider />
              </React.Fragment>
            )
          })}
        </>
      </List>
      {hasMoreItems() && (
        <LoadMoreWrapper>
          {showSpinnerButton() ? (
            <Spinner />
          ) : (
            <LoadMoreButton onClick={loadMore} theme={ButtonTheme.ghost}>
              {i18n.t(`builder.resume_distribution.dashboard.recruites_list.load_more_button`, {
                number: getRecruitersLeftNumber(),
              })}
            </LoadMoreButton>
          )}
        </LoadMoreWrapper>
      )}
    </Container>
  )
}
