import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import Button, { ButtonSize } from 'builder/components/Button'
import { useI18n } from 'builder/hooks/useI18n'

import * as Styles from './styles'

interface IProps {
  handleClose: () => void
}

const ModalFooter = ({ handleClose }: IProps) => {
  const { isPhone } = useMediaQueries()
  const { i18n } = useI18n()

  return (
    <>
      <Styles.ButtonContainer>
        <Button size={!isPhone ? ButtonSize.default : ButtonSize.small} onClick={handleClose}>
          {i18n.t(`builder.panel.cio_rio_info_modal.button`)}
        </Button>
      </Styles.ButtonContainer>
    </>
  )
}

export default ModalFooter
