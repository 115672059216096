import { trackInternalEvent } from '@rio/tracking'
import { useDispatch } from 'react-redux'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { FeatureCard } from 'builder/hooks/useFeatureCards'
import { actions as uiActions } from 'builder/modules/ui'
import useWebsiteHost from 'builder/components/CIO-Dasboard-2.0/hooks/useWebsiteHost'
import { useConfig } from 'builder/hooks/useConfig'
import { Icon20 } from 'builder/components/Icon'
import { FeatureIcon } from 'builder/components/FeatureIcon'
import Colors from 'builder/styles/colors'
import {
  FeatureContainer,
  FeatureIconStyled,
  FeatureContent,
  FeatureTitle,
  FeatureBadge,
  FeatureText,
  IconContainer,
} from './styles'

interface Props {
  feature: FeatureCard
}

export const MenuFeature = ({ feature }: Props) => {
  const { isPhone } = useMediaQueries()
  const { getHost } = useWebsiteHost()
  const config = useConfig()
  const dispatch = useDispatch()
  const isCioDomain = config?.features.superApp
  const isRioDomain = config?.features.international
  let linkToRedirect = feature.to ?? '/'

  const isPostPremium =
    ['job_tracking', 'salary_analyzer', 'interview_prep', 'job_search_strategy'].includes(
      feature.name,
    ) && feature.isPostPremiumActive

  return (
    <FeatureContainer
      to={linkToRedirect}
      $isDisabled={!feature.enabled}
      onClick={async e => {
        trackInternalEvent('click_super_app_menu_link', {
          target: feature?.eventTarget,
          website_host: getHost,
        })

        if (feature.name === 'pro_resume_writing_latest') {
          e.preventDefault()
          if (isRioDomain) {
            window.open(feature.cioDomainUrl, '_blank')
          } else {
            window.open(feature.to, '_blank')
          }
        }
        if (isPostPremium) {
          e.preventDefault()
          dispatch(uiActions.setTrackJobPaywall(true))
        } else if (feature.name === 'career_advice' && isCioDomain) {
          e.preventDefault()
          const careerAdviceUrl = feature.isCareerURL
            ? `https://${window.location.hostname}${feature.to}`
            : feature.to
          window.open(careerAdviceUrl, '_self')
        }
      }}
    >
      <FeatureIconStyled>
        {feature.iconName && <FeatureIcon name={feature?.iconName} disabled={!feature.enabled} />}
      </FeatureIconStyled>

      <FeatureContent>
        <FeatureTitle isDisabled={!feature.enabled}>
          {feature.title}
          {feature.isPostPremiumActive && (
            <IconContainer variant="indigo">
              <Icon20.Crown width={24} height={24} color={Colors.Indigo70} />
            </IconContainer>
          )}
          {!feature.enabled && <FeatureBadge variant="neutral">Soon</FeatureBadge>}
        </FeatureTitle>

        {!isPhone && <FeatureText>{feature.text}</FeatureText>}
      </FeatureContent>
    </FeatureContainer>
  )
}
