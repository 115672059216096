import { formatDate } from 'builder/utils/formatDate'

export const getPhrase = (sessions: number) => (sessions > 1 ? 'sessions' : 'session')

export const getPackageValidity = (expirationDate: string) => {
  if (expirationDate) {
    return `, valid until ${formatDate(expirationDate, 'MMMM do, yyyy')}`
  }

  return null
}
