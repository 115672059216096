import { CSSTransition } from 'react-transition-group'
import CareerPlanningAnimation from 'builder/components/CareerPlanningAnimation'
import CompactProgressBar from 'builder/components/CareerPlanningProgress/components/CompactProgressBar'

import {
  Container as ProgressBarContainer,
  SectionName,
  CloseSectionIcon,
  Divider,
  IconWrapper,
  FilterIcon,
} from '../../styles'
import { Container, Wrapper } from './styles'

type Props = {
  name: string
  score: number
  open: boolean
  animate: boolean
  filterStatus: string
  showFilters: boolean
  handleClick: () => void
  handleFilters: () => void
}

const TopContent = ({
  open: isOpen,
  name,
  score,
  animate,
  handleClick,
  handleFilters,
  filterStatus,
  showFilters,
}: Props) => {
  const notify = filterStatus !== 'open'

  return (
    <Container>
      <Wrapper onClick={handleClick}>
        <CSSTransition
          appear
          in={!isOpen}
          mountOnEnter
          unmountOnExit
          timeout={150}
          classNames="progress-bar-transition"
        >
          <ProgressBarContainer>
            <CompactProgressBar score={score} />
            {animate && <CareerPlanningAnimation isOpen={isOpen} />}
          </ProgressBarContainer>
        </CSSTransition>

        <SectionName>{name}</SectionName>
        <IconWrapper>
          <CloseSectionIcon $isOpen={isOpen} />
        </IconWrapper>
      </Wrapper>
      <Divider />
      <IconWrapper onClick={handleFilters} {...{ notify }}>
        <FilterIcon $showFilters={showFilters} />
      </IconWrapper>
    </Container>
  )
}

export default TopContent
