import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import { Icon24 } from 'builder/components/Icon'

export const Container = styled.div`
  z-index: 1;
  display: flex;
  gap: 18px;
  position: absolute;
  left: 52%;
  height: 100%;

  ${Media.Tablet`
    height: 100%;
    left: 46%;
    top: 0;
    align-items: center;
  `}

  ${Media.Phone`
    height: 50%;
    width: 100%;
    left: 0;
    top: 0;
    align-items: center;
    flex-direction: column-reverse;
  `};
`

export const EllipsisContainer = styled.div`
  position: absolute;
  padding-top: 40px;
  left: 25px;
  opacity: 0.4;

  ${Media.Tablet`
    left: 85px;
    padding-top: 68px;
  `};

  ${Media.Phone`
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 0;
    top: 16px;
    height: 148px;
    overflow: hidden;
  `};
`

export const EllipseOne = styled.div`
  position: absolute;
  width: 421px;
  height: 421px;
  transform: translateY(-50%);
  border-radius: 421px;
  opacity: 0.64;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(195, 229, 254, 0) 68.75%,
    ${Colors.Blue20} 100%
  );

  &.hover {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(195, 229, 254, 0) 68.75%,
      ${Colors.Blue30} 100%
    );
  }

  ${Media.Tablet`
    width: 253px;
    height: 253px;
    border-radius: 253px;
  `};

  ${Media.Phone`
    border-radius: 268px;
    height: 268px;
    width: 268px;
    transform: none;
  `};
`

export const EllipseTwo = styled.div`
  position: absolute;
  width: 268px;
  height: 268px;
  left: 75px;
  transform: translateY(-50%);
  border-radius: 268px;
  opacity: 0.6;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(195, 229, 254, 0) 68.75%,
    ${Colors.Blue30} 100%
  );

  &.hover {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(195, 229, 254, 0) 68.75%,
      ${Colors.Blue40} 100%
    );
  }

  ${Media.Tablet`
    left: 45px;
    width: 170px;
    height: 170px;
    border-radius: 170px;
  `};

  ${Media.Phone`
    border-radius: 140px;
    left: unset;
    width: 140px;
    height: 140px;
    transform: none;
    top: 50px;
  `};
`

export const EllipseThree = styled.div`
  position: absolute;
  width: 129px;
  height: 129px;
  left: 140px;
  transform: translateY(-50%);
  border-radius: 129px;
  opacity: 0.4;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(195, 229, 254, 0) 68.75%,
    ${Colors.Blue40} 100%
  );

  &.hover {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(195, 229, 254, 0) 68.75%,
      ${Colors.Blue50} 100%
    );
  }

  ${Media.Tablet`
    display: none;
  `};
`

export const ImageContainer = styled.div``

export const ResumeImage = styled.img`
  position: absolute;
  left: 170px;
  top: 4px;
  width: 136px;
  height: 194px;
  border-radius: 3px;
  z-index: 2;

  ${Media.Tablet`
    left: 170px;
    top: 18px;
    width: 97px;
    height: 138px;
  `};

  ${Media.Phone`
    width: 126px;
    height: 133px;
    top: 36px;
    left: calc(50% + 2px);
    transform: translate(-50%);
    object-fit: cover;
    object-position: top;
  `};
`

export const ResumeImageGradient = styled.div`
  position: absolute;
  left: 170px;
  bottom: 16px;
  width: 160px;
  height: 12px;
  background: linear-gradient(0deg, ${Colors.Blue10} 0%, rgba(234, 246, 255, 0) 100%);
  z-index: 3;

  &.hover {
    background: linear-gradient(0deg, ${Colors.Blue20} 0%, rgba(234, 246, 255, 0) 100%);
  }

  ${Media.Tablet`
    left: 170px;
    width: 117px;
    bottom: 0;
  `};

  ${Media.Phone`
    width: 146px;
    top: 157px;
    left: calc(50% + 2px);
    transform: translate(-50%);
  `};
`

export const AddIconContainer = styled.div`
  position: absolute;
  left: 280px;
  top: -5px;
  z-index: 3;
  background-color: ${Colors.Blue20};
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.hover {
    background-color: ${Colors.Blue30};
  }

  ${Media.Tablet`
    left: 250px;
    top: 6px;
  `};

  ${Media.Phone`
    width: 44px;
    height: 44px;
    top: 24px;
    left: calc(50% + 71px);
    transform: translate(-50%);
`};
`

export const AddIcon = styled(Icon24.Add)`
  color: ${Colors.Blue30};

  &.hover {
    color: ${Colors.Blue40};
  }
`
