import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'

import { ErrorLayout } from './ErrorLayout'
import MULTIPLE_ERRORS from './assets/no_work_experience.png'
import { NoWorkExperienceImage } from './styles'

export const NoTargetRoleAndNoWorkExperience = () => {
  const navigate = useNavigate()

  return (
    <ErrorLayout
      Images={<NoWorkExperienceImage src={MULTIPLE_ERRORS} alt="no_role_no_work_experience" />}
      translationKey="no_target_role_no_work_experience"
      onClick={() => {
        trackInternalEvent('view_profile_editor', { label: 'recommended_jobs' })
        navigate(
          '/career-profile/edit?section=jobPreferences&returnUrl=/job-search?view=recommendation',
        )
      }}
    />
  )
}
