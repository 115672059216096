import styled from 'styled-components'
import { animated } from '@react-spring/web'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Button from 'builder/components/Button'
import { Icon20 } from 'builder/components/Icon'

export const ModalContainer = styled(animated.div)<{
  isOpen: boolean
}>`
  flex-direction: column;
  position: fixed;
  right: 24px;
  bottom: ${props => (props.isOpen ? '24px' : '-100vh')};
  z-index: 9999999999;

  opacity: ${props => (props.isOpen ? '100' : '0')};
  transition: opacity 0.4s ease-in-out, bottom 0.4s ease-in-out;

  ${Media.Phone`
      position: fixed;
      left: 0;
      right: 0;
      top: initial;
      display: initial;
      justify-content: initial;
      align-items: initial; 
  `}

  ${props =>
    props.isOpen
      ? Media.Phone`
      bottom: 12px;
    `
      : Media.Phone`
      bottom: -100vh;
    `}
`

export const Container = styled.div`
  position: relative;
  display: flex;
  width: 352px;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 24px;
  flex-direction: column;
  border-radius: 12px;
  background: ${Colors.White};

  box-shadow: 0px 16px 64px -8px rgba(15, 56, 113, 0.18), 0px 6px 8px -2px rgba(15, 56, 113, 0.02),
    0px 0px 1px 0px rgba(15, 56, 113, 0.32);

  ${Media.Phone`
    max-width: 351px;
    margin: auto;
  `}
`
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`

export const Text = styled.div`
  color: ${Colors.Neutral50};
  ${Typography.Body};
  ${FontWeights.Regular};
  text-align: center;
  width: 304px;

  ${Media.Phone`
    width: 311px;
  `};
`

export const Title = styled.div`
  color: ${Colors.Neutral90};
  ${Typography.Subhead};
  ${FontWeights.DemiBold};
  text-align: center;
  width: 304px;

  ${Media.Phone`
    width: 311px;
  `};
`

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`

export const NotNowBUtton = styled(Button)`
  display: flex;
  max-width: 125px;
  height: 48px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 4px;
  background-color: ${Colors.White};
  color: ${Colors.Neutral90};
  border: 1px solid ${Colors.Neutral20};
  transition: border 0.2s ease-in-out, color 0.2s ease-in-out;

  &:hover {
    transition: border 0.2s ease-in-out, color 0.2s ease-in-out;
    border: 1px solid ${Colors.Blue50};
    background-color: ${Colors.White};
    color: ${Colors.Blue50};
  }
`

export const MakePremiumButton = styled(Button)`
  display: flex;
  max-width: 167px;
  padding: 12px 24px 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: ${Colors.Indigo50};
`

export const CloseIcon = styled(Icon20.Close)`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 20px;
  cursor: pointer;
  height: 20px;
  background-color: ${Colors.Neutral5};
  color: ${Colors.Neutral50};
  flex-shrink: 0;
  border-radius: 50%;
`

export const CrownTopIcon = styled(Icon20.Crown)<{ daysLeftPremium: number }>`
  width: 24px;
  height: 24px;
  color: ${Colors.Amber40};
  margin-bottom: -24px;
  transform: ${props => (props.daysLeftPremium < 1 ? 'rotate(-36.22deg)' : 'none')};
  position: relative;
  left: ${props => (props.daysLeftPremium < 1 ? '-2rem' : 'none')};
  top: ${props => (props.daysLeftPremium < 1 ? '1rem' : 'none')};
`
