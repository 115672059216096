import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'

export const VisualContainer = styled.div`
  position: absolute;
  width: 268px;
  height: 214px;
  left: 0px;
  top: 0px;

  ${Media.Phone`
    width: 242px;
    height: 212px;
  `}
`

export const VisualImageContainer = styled.div`
  position: absolute;
  width: 244px;
  height: 212px;
  right: 15px;
  top: 30px;
  z-index: 1;

  ${Media.Phone`
    right: 0px;
  `}
`

export const VisualImage = styled.img`
  width: 100%;
  height: 100%;
`

export const UserImage = styled.img`
  position: absolute;
  z-index: 100;
  top: 85px;
  width: 43px;
  left: 30px;
  height: 43px;
  border-radius: 50%;
  border: 2px solid ${Colors.White};
`
