import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'
import { rotation } from 'builder/styles/keyframes'

export const ButtonContainer = styled.div`
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 3;
  ${Media.Phone`
    bottom: 16px;
    right: 16px;
  `};
`

export const Button = styled.button<{ collapsed: boolean }>`
  position: relative;
  outline: none;
  height: 60px;
  padding: 10px;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  pointer-events: none;
  -webkit-tap-highlight-color: transparent;

  &:before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: ${props => (props.collapsed ? '60px' : '100%')};
    height: 100%;
    border-radius: 30px;
    background-color: ${Colors.Blue50};
    transition-property: width;
    transition-duration: 0.3s;
    transition-delay: ${props => (props.collapsed ? '0.2s' : 0)};
    will-change: width;
    pointer-events: auto;
  }

  &:hover:before {
    background-color: ${Colors.Blue60};
  }
`

const DOCUMENT_URL = require('images/builder/resume-editor/preview-document.svg')
const SPINNER_URL = require('images/builder/resume-editor/preview-spinner.svg')

export const ButtonText = styled.div<{ collapsed: boolean }>`
  ${Typography.ButtonM};
  font-weight: 600;
  display: block;
  position: relative;
  color: ${Colors.White};
  padding: 0 6px 0 14px;
  opacity: ${props => (props.collapsed ? 0 : 1)};
  transition-property: opacity;
  transition-duration: 0.3s;
  transition-delay: ${props => (props.collapsed ? 0 : '0.2s')};
`

export const ButtonIcon = styled.div<{ isSaving: boolean }>`
  display: block;
  position: relative;
  width: 40px;
  height: 40px;
  background-image: url(${props => (props.isSaving ? SPINNER_URL : DOCUMENT_URL)});
  animation-name: ${props => (props.isSaving ? rotation : 'none')};
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`
