import Spinner from 'builder/components/Spinner'
import { Wrapper } from './styles'

const Loader = () => {
  return (
    <Wrapper data-testid="courses-loader">
      <Spinner />
    </Wrapper>
  )
}

export default Loader
