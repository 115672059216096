import { useContext, useMemo } from 'react'
import Colors from 'builder/styles/colors'
import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { TunerContext } from '../../contexts/TunerContext'
import { TunerContextValue } from '../../types'
import {
  ScoreContainer,
  ScoreContent,
  ScoreValue,
  ScoreLabel,
  ScoreRail,
  ScoreBar,
  ScoreEmoji,
} from './styles'

export const TunerBannerScore = () => {
  const { score } = useContext(TunerContext) as TunerContextValue
  const { isTablet } = useMediaQueries()
  const { i18n } = useI18n()

  const [color, emoji] = useMemo(() => {
    if (score >= 7) return [Colors.Green60, 'high']
    if (score >= 5) return [Colors.Indigo80, 'medium']
    return [Colors.Red50, 'low']
  }, [score])

  return (
    <ScoreContainer style={{ color }}>
      <ScoreContent>
        <ScoreValue>{score.toFixed(1)}</ScoreValue>
        <ScoreLabel>{i18n.t('builder.resume_tuner.banner.score')}</ScoreLabel>
      </ScoreContent>
      <ScoreRail>
        <ScoreBar style={{ [isTablet ? 'width' : 'height']: `${score * 10}%` }}>
          <ScoreEmoji emoji={emoji} />
        </ScoreBar>
      </ScoreRail>
    </ScoreContainer>
  )
}
