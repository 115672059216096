import { Fragment, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import {
  actions,
  selectors as critiqueSelectors,
  ResumeCritiqueStatuses,
} from 'builder/modules/resumeCritiqueReview'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { checkIfCritiqueExpired } from 'builder/modules/resumeReview'
import { IFRAME_PARAMS } from 'builder/modules/resumeCritiqueReview/constants'
import * as Styles from './styles'

interface Props {
  isGoalCardSection: boolean
}

const CritiqueReviewLoader = ({ isGoalCardSection }: Props) => {
  const { isPhone } = useMediaQueries()
  const dispatch = useDispatch()
  const CritiqueResumeReviewData = useTypedSelector(critiqueSelectors.triggeredCritiqueStatus)

  const status =
    CritiqueResumeReviewData.status === ResumeCritiqueStatuses.failed ||
    CritiqueResumeReviewData.status === ResumeCritiqueStatuses.pending

  const viewResumeReview = (url?: string) => {
    if (CritiqueResumeReviewData.status === ResumeCritiqueStatuses.finished && url) {
      dispatch(actions.setCritiquePageVisitedCount(true))
      window.open(`${url}${IFRAME_PARAMS}`)
    }
  }

  const isCritiquePageCount = useTypedSelector(critiqueSelectors.isCritiquePageCount)
  const visitedBefore = isCritiquePageCount ? 'true' : 'false'
  localStorage.setItem('visitedBefore', visitedBefore)

  const isCritiqueExpired = useMemo(
    () => checkIfCritiqueExpired(CritiqueResumeReviewData.createdAt),
    [CritiqueResumeReviewData.createdAt],
  )

  if (isCritiqueExpired) return <></>

  return (
    <Styles.LoaderContainer
      status={status}
      isGoalCardSection={isGoalCardSection}
      onClick={() => viewResumeReview(CritiqueResumeReviewData.iframeUrl)}
    >
      {status ? (
        <Styles.Spinner />
      ) : (
        <Fragment>
          <Styles.PopupIconWrapper>
            <Styles.PopupIcon />
          </Styles.PopupIconWrapper>
          {!isPhone && <Styles.PopupPhoto src={CritiqueResumeReviewData.reviewer?.photoUrl} />}
        </Fragment>
      )}
      {isPhone && !status ? (
        <Styles.PrefillText status={status}> Review </Styles.PrefillText>
      ) : (
        <Styles.PrefillText status={status}>
          {status ? 'In Review' : 'Read Review'}
        </Styles.PrefillText>
      )}
      {!status && <Styles.RightArrow />}
    </Styles.LoaderContainer>
  )
}

export default CritiqueReviewLoader
