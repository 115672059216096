import { Dispatch, SetStateAction } from 'react'
import ModalOverlay from 'builder/components/ModalOverlay'
import * as Styled from './styles'

interface IWelcomeVideoOverlayProps {
  isShowVideo: boolean | undefined
  setIsShowVideo: Dispatch<SetStateAction<boolean>>
}

export const WelcomeVideoOverlay = ({ isShowVideo, setIsShowVideo }: IWelcomeVideoOverlayProps) => {
  if (!isShowVideo) return null

  return (
    <ModalOverlay
      onClose={() => setIsShowVideo(false)}
      overlayFadeDuration={150}
      contentSlideDuration={0}
    >
      <>
        <Styled.ModalContainer>
          <Styled.ModalIframe
            title="Career.io - Overview Video - The future of job search and career planning"
            allowFullScreen
            frameBorder="0"
            src="https://www.youtube.com/embed/_aOvXorwo_8?autoplay=1&rel=0"
            allow="autoplay"
          />
        </Styled.ModalContainer>
        <Styled.ModalClose onClick={() => setIsShowVideo(false)}>
          <Styled.ModalCloseIcon />
        </Styled.ModalClose>
      </>
    </ModalOverlay>
  )
}
