import styled, { css } from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
import Colors, { hexToRgba } from 'builder/styles/colors'
import { Container as BaseContainer } from 'builder/components/Container'
import Media from 'builder/styles/media'
import { Logo } from 'builder/components/Logo'
import { BAR_HEIGHT, Z_INDEX } from '../../constants'

export const Container = styled.nav<{ isTransparent?: boolean }>`
  position: absolute;
  z-index: ${Z_INDEX};
  top: 0;
  left: 0;
  width: 100%;

  ${p =>
    !p.isTransparent &&
    css`
      position: fixed;
      background: ${hexToRgba(Colors.White, 0.92)};
      backdrop-filter: blur(8px) saturate(200%);
    `}

  /* force fix navbar on mobile */
  ${Media.Tablet`
    position: fixed;
      background: ${hexToRgba(Colors.White, 0.92)};
      backdrop-filter: blur(8px) saturate(200%);
  `}
`

export const Content = styled(BaseContainer)`
  display: flex;
  align-items: center;
  position: relative;
  height: ${BAR_HEIGHT}px;
`

export const LogoLink = styled(RouterLink).withConfig({
  shouldForwardProp: propName => ['isMenuOpen'].includes(propName) === false,
})<{ isMenuOpen: boolean }>`
  display: flex;
  transition: opacity 0.2s, visibility 0.2s;
  opacity: ${props => (props.isMenuOpen ? 0 : 1)};
  visibility: ${props => (props.isMenuOpen ? 'hidden' : 'visible')};

  ${Media.Phone`
    position: absolute;
    left: calc(50% + 17px);
    top: 50%;
    transform: translate(-50%, -50%);
  `}
`

export const CustomLogo = styled.img`
  display: inline-block;
  width: 120px;
  height: 32px;
  vertical-align: middle;
`

export const LogoStyled = styled(Logo)`
  ${Media.Tablet`
    background-size: auto 32px;
    width: 34px;
  `}
`

export const RightColumn = styled.div<{ isMenuOpen: boolean }>`
  margin-left: auto;
  display: flex;
  align-items: center;
  opacity: ${props => (props.isMenuOpen ? 0 : 1)};
  visibility: ${props => (props.isMenuOpen ? 'hidden' : 'visible')};
  transition: opacity 0.2s, visibility 0.2s;
`
