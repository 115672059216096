import { useCallback, useContext, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { formatDocumentPreviewUrl } from 'builder/utils/formatDocumentPreviewUrl'
import { TunerContext } from '../../contexts/TunerContext'
import { TunerContextValue } from '../../types'
import { PreviewContainer, PreviewImage, PreviewFixButton } from './styles'

interface Props {
  highlightColor: string
}

export const TunerBannerPreview = ({ highlightColor }: Props) => {
  const { i18n } = useI18n()
  const { resume, score, tasks } = useContext(TunerContext) as TunerContextValue

  const previewUrl = formatDocumentPreviewUrl(resume, { size: 450 })

  const fixResumePath = useMemo(() => {
    const nextTask = tasks?.find(task => !task.done)
    return nextTask ? `/resumes/${resume.id}/edit?tuner_tips=${nextTask.id}` : null
  }, [resume.id, tasks])

  const handleClick = useCallback(() => {
    trackInternalEvent('click_tuner_banner', {
      element: 'cta',
      resume_id: resume.id,
      tuner_score: score,
    })
  }, [resume.id, score])

  return (
    <PreviewContainer highlightColor={highlightColor}>
      <PreviewImage src={previewUrl} />

      {fixResumePath && (
        <PreviewFixButton as={Link} to={fixResumePath} onClick={handleClick}>
          {i18n.t('builder.resume_tuner.banner.cta')}
        </PreviewFixButton>
      )}
    </PreviewContainer>
  )
}
