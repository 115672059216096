import { Optional } from 'packages/types'
import isEmpty from 'lodash/isEmpty'
import trim from 'lodash/trim'

export const isTruthy = (value: Optional<string>) =>
  value
    ? !isEmpty(
        trim(value)
          .replace(/<[^>]*>/g, '') // To replace <p> and </p> tags in the rich text
          .replace(/&nbsp;/g, ''), // To replace blank spaces in the rich text
      )
    : false

export const wrapTextInPTags = (text: string) => {
  if (text.includes('<p>')) return text

  // Split the text with newlines first
  const singleNewlines = text.replace(/\n+/g, '\n')
  const paragraphs = singleNewlines.split('\n').filter(item => item.trim() !== '')
  // Wrap every sentence in the text with paragraph tags
  const wrappedParagraphs = paragraphs.map(paragraph => `<p>${paragraph}</p>`).join('<p><br></p>')
  return wrappedParagraphs.trim()
}

export const handleTextWithHeaders = (value: string) => {
  return value.replace(/<h[1-6][^>]*>.*?<\/h[1-6]>/gi, match => {
    // Extract the content inside the header tag
    const headerContent = match.replace(/<\/?h[1-6][^>]*>/g, '')
    // Wrap the content with a paragraph tag
    return `<p>${headerContent}</p>`
  })
}

export const handleTextWithBullets = (value: string) => {
  // Split the text with newlines first
  const paragraphs = value.split('\n').filter(item => item.trim() !== '')
  let listStarted = false
  return paragraphs
    .map(paragraph => {
      if (paragraph.includes('•')) {
        // Handle sentence with a bullet - by starting a unordered list and wrapping with list item
        const result = `${!listStarted ? '<ul>' : ''}<li>${paragraph.replace(/• /g, '')}</li>`
        listStarted = true
        return result
      }
      // Handle sentence without a bullet - by ending the started list or returning as is
      const result = `${listStarted ? '</ul>' : ''}${paragraph}`
      listStarted = false
      return result
    })
    .join('\n')
}
