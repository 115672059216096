import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { ResumeUploadStatus, ResumeValidationStatus } from 'builder/modules/constants'
import ActionLayout from './ActionLayout'
import FileUploadContainer from './FileUploadContainer/FileUploadContainer'
import FileUploadProgress from './FileUploadContainer/FileUploadProgress'
import FileUploadSuccess from './FileUploadContainer/FileUploadSuccess'
import FileUploadFailed from './FileUploadContainer/FileUploadFailed'

type Props = {
  onClose: () => void
}

const UploadResume = ({ onClose }: Props) => {
  const { resumeValidationStatus, resumeUploadStatus } = useTypedSelector(
    state => state.resumeEditor.prefillResumeSection,
  )

  const resumeStatus =
    resumeUploadStatus === ResumeUploadStatus.failed
      ? ResumeValidationStatus.failed
      : resumeValidationStatus

  const getResumeUploadingStatus = () => {
    switch (resumeStatus) {
      case ResumeValidationStatus.uploading:
        return <FileUploadProgress />
      case ResumeValidationStatus.validated:
        return <FileUploadSuccess />
      case ResumeValidationStatus.failed:
        return <FileUploadFailed />
      default:
        return null
    }
  }

  return (
    <FileUploadContainer {...{ onClose }}>
      {getResumeUploadingStatus()}
      <ActionLayout />
    </FileUploadContainer>
  )
}

export default UploadResume
