import styled from 'styled-components'
import { Typography, FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Button from 'builder/components/Button'
import Colors from 'builder/styles/colors'

export const Container = styled.div`
  width: 100%;
`

export const Title = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};
  width: 100%;
  text-align: center;
  margin-bottom: 12px;
`

export const Subtitle = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 24px;
  color: ${Colors.Neutral50};
`

export const FormContainer = styled.form`
  ${Media.Phone`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  `}
`

export const FieldsContainer = styled.div`
  margin: 4px 0 12px;
`

export const FieldContainer = styled.div`
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const ButtonContainer = styled.div`
  gap: 8px;
  display: flex;
  text-align: right;
`

export const FormButton = styled(Button)`
  flex: auto;
`

export const BottomContainer = styled.div`
  width: 100%;
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${Media.Phone`
    margin-top: 24px;
  `}
`

export const SkillsText = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
`

export const PrefillModalLink = styled.div<{ documents: number }>`
  ${Typography.Caption};
  cursor: pointer;
  pointer-events: ${props => (!props.documents ? 'none' : 'auto')};
  color: ${props => (!props.documents ? Colors.Neutral30 : Colors.Blue50)};
`

export const SkillsTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
