import styled, { keyframes } from 'styled-components'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'

const pulseAnimation = keyframes`
  0% { transform: scale(0.9) }
  50% { transform: scale(1.1) }
  100% { transform: scale(0.9) }
`

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`

export const Image = styled.img`
  width: 126.5px;
  height: 88px;
  flex-shrink: 0;
`

export const ImageAnimation = styled.img`
  width: 126.5px;
  height: 88px;
  flex-shrink: 0;
  animation: ${pulseAnimation} 2s infinite;
`

export const Text = styled.div`
  ${Typography.Subhead}
  text-align: center;

  ${Media.Tablet`
    width: 466px;
  `}

  ${Media.Phone`
    width: 261px;
  `}
`
