import { SectionNames } from 'builder/modules/resumeEditor'
import { i18n as I18n } from 'builder/utils/i18n'
import { Courses } from './Cards/CoursesCard/CoursesCard'
import { EducationCard } from './Cards/EducationCard/EducationCard'
import { EmploymentCard } from './Cards/EmploymentHistoryCard/EmploymentHistoryCard'

export function getJapaneseSectionsList(resume) {
  const sections = [
    {
      id: SectionNames.educations,
      title: I18n.t(`builder.resume_editor.educations_section.title`),
      customTitle: resume.sectionTitles.educations,
      addButtonText: resume.educations.length
        ? I18n.t('builder.resume_editor.educations_section.add_one_more')
        : I18n.t('builder.resume_editor.educations_section.add'),
      CardComponent: EducationCard,
      items: resume.educations,
      hintText: I18n.t('builder.resume_editor.educations_section.hint'),
      sortable: false,
    },

    {
      id: SectionNames.workExperiences,
      title: I18n.t(`builder.resume_editor.work_experience_section.title`),
      customTitle: resume.sectionTitles.workExperiences,
      addButtonText: resume.workExperiences.length
        ? I18n.t('builder.resume_editor.work_experience_section.add_one_more')
        : I18n.t('builder.resume_editor.work_experience_section.add'),
      CardComponent: EmploymentCard,
      items: resume.workExperiences,
      hintText: I18n.t('builder.resume_editor.work_experience_section.hint'),
      sortable: false,
    },

    {
      id: SectionNames.courses,
      title: I18n.t(`builder.resume_editor.courses_section.title`),
      customTitle: resume.sectionTitles.courses,
      addButtonText: resume.courses.length
        ? I18n.t('builder.resume_editor.courses_section.add_one_more')
        : I18n.t('builder.resume_editor.courses_section.add'),
      CardComponent: Courses,
      hintText: I18n.t('builder.resume_editor.courses_section.hint'),
      items: resume.courses,
      sortable: false,
      removable: true,
      hidden: false,
    },
  ]

  return sections
}

export const groupSectionsBy = (sections, key) => {
  const groups = []

  sections.forEach(section => {
    const value = section[key]
    if (groups.length === 0 || groups[groups.length - 1][key] !== value) {
      groups.push({ [key]: value, sections: [section] })
    } else {
      groups[groups.length - 1].sections.push(section)
    }
  })

  return groups
}
