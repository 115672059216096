import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Button from 'builder/components/Button'
import Sizes from 'builder/styles/sizes'

export const DoneButton = styled(Button)`
  width: fit-content;
  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${Colors.White};
  background-color: ${Colors.Blue50};
  border-radius: ${Sizes['3XS']};
  padding: ${Sizes.XS} ${Sizes.M};
`

export const ImageContainer = styled.div`
  height: 373px;
  background-color: ${Colors.Blue10};
  width: 100%;
  position: relative;
  border-radius: ${Sizes.S} ${Sizes.S} 0 0;
  overflow: hidden;

  ${Media.Phone`
    height: 223px;
  `}
`

export const EllipseOne = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100% + 200px);
  left: calc(50%);
  transform: translate(-50%);
  top: -100px;
  opacity: 0.3;
  border-radius: 100%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(195, 229, 254, 0) 68.75%,
    ${Colors.Blue20} 100%
  );

  ${Media.Phone`
    top: -50px;
    height: calc(100% + 100px);
  `}
`

export const EllipseTwo = styled.div`
  left: calc(50%);
  transform: translate(-50%);
  width: calc(80%);
  height: calc(100% + 50px);
  top: -25px;
  opacity: 1;
  border-radius: 100%;
  position: absolute;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(195, 229, 254, 0) 80%,
    ${Colors.Blue20} 120%
  );

  ${Media.Phone`
    height: calc(100% + 25px);
    top: -12.5px;
  `}
`

export const EllipseThree = styled.div`
  left: calc(50%);
  transform: translate(-50%);
  width: calc(60%);
  height: calc(90%);
  top: calc(6.25%);
  opacity: 0.3;
  border-radius: 100%;
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%, rgba(195, 229, 254, 0) 0, ${Colors.Blue20} 90%);
`

export const ResumeImageContainer = styled.div`
  position: absolute;
  background-color: white;
  z-index: 2;
  top: 100px;
  left: calc(50%);
  transform: translate(-50%);
  padding: ${Sizes.M};
  border-radius: ${Sizes['2XS']};
  box-shadow: 0px 0px 0.898px 0px rgba(15, 56, 113, 0.08),
    0px 14.36px 57.441px -7.18px rgba(15, 56, 113, 0.08);

  ${Media.Phone`
    top: 53px;
  `}
`

export const ResumeImage = styled.img`
  width: 286px;
  height: 553px;

  ${Media.Phone`
    width: 171px;
    height: 331px;
  `}
`

export const CompanyImageContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  border-radius: 100%;
  background-color: white;
  box-shadow: 0px 1px 2px 0px rgba(15, 56, 113, 0.12), 0px 2px 4px 0px rgba(15, 56, 113, 0.08);
`

export const CompanyImage = styled.img`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  border-radius: 100%;
  background-color: white;
  box-shadow: 0px 1px 2px 0px rgba(15, 56, 113, 0.12), 0px 2px 4px 0px rgba(15, 56, 113, 0.08);
`

export const AmazonImage = styled(CompanyImage)`
  width: ${Sizes.XL};
  height: ${Sizes.XL};
  top: 60px;
  left: 347px;

  ${Media.Phone`
    width: 29px;
    height: 29px;
    top: 29px;
    left: 207px;
  `}
`

export const TwitterImage = styled(CompanyImageContainer)`
  width: 49px;
  height: 49px;
  top: 125px;
  left: 53px;

  img {
    width: 25px;
    height: 25px;
  }

  ${Media.Phone`
    width: 29px;
    height: 29px;
    top: 73px;
    left: 32px;

    img {
      width: 15px;
      height: 15px;
    }
  `}
`

export const AppleImage = styled(CompanyImage)`
  width: 72px;
  height: 72px;
  top: 35px;
  left: 92px;

  ${Media.Phone`
    width: 43px;
    height: 43px;
    top: 15px;
    left: 55px;
  `}
`

export const GoogleImage = styled(CompanyImage)`
  width: 49px;
  height: 49px;
  top: 250px;
  left: 423px;

  ${Media.Phone`
    width: 29px;
    height: 29px;
    top: 145px;
    left: 253px;
  `}
`

export const AirbnbImage = styled(CompanyImageContainer)`
  width: 66px;
  height: 66px;
  top: 100px;
  left: 439px;

  img {
    width: 34px;
    height: 34px;
  }

  ${Media.Phone`
    width: 40px;
    height: 40px;
    top: 53px;
    left: 262px;

    img {
      width: 20px;
      height: 20px;
    }
  `}
`

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
`
