import styled from 'styled-components'
import Colors from 'builder/styles/colors'

export const Container = styled.div`
  margin-bottom: 48px;
`

export const Divider = styled.div`
  margin: 16px 26px;
  height: 1px;
  border-radius: 12px;
  background: ${Colors.Neutral15};
`
