import styled, { keyframes, css } from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import { fadeInStyles } from '../styles'

export const BgColorPickerWrapper = styled.div`
  position: absolute;
  z-index: 5;
  top: 62px;
  left: calc(50% - 160px);
  width: 320px;
  display: flex;
  justify-content: center;
  pointer-events: none;
  ${fadeInStyles};
`

export const BgColorPickerTrigger = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${Typography.Caption};
  font-weight: 600;
  color: ${Colors.Neutral60};
  cursor: pointer;
  pointer-events: auto;

  &:before {
    content: '';
    position: absolute;
    left: -12px;
    top: -12px;
    right: -12px;
    bottom: -12px;
  }
`

const drop = keyframes`
  from { transform: scale(0.75) translateZ(0); }
  to   { transform: scale(1) translateZ(0); }
`

export const BgColorOptionsDropdown = styled.div`
  position: absolute;
  left: 0;
  top: calc(100% + 16px);
  right: 0;
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
  border-radius: 6px;
  background-color: ${Colors.Neutral90};
  animation: ${drop} 0.25s cubic-bezier(0.34, 1.56, 0.64, 1);
  transform-origin: 50% -12px;
  pointer-events: auto;

  &:before {
    content: '';
    position: absolute;
    left: calc(50% - 6px);
    top: -8px;
    width: 16px;
    height: 16px;
    border-radius: 3px 0 0 0;
    background-color: inherit;
    transform: rotate(45deg);
  }
`

export const BgColorAction = styled.div`
  position: relative;
  overflow: hidden;
  width: 32px;
  height: 32px;
  margin: 8px;
  border-radius: 50%;
  cursor: pointer;
`

export const BgColorOption = styled(BgColorAction)<{ isActive: boolean; border: boolean }>`
  box-shadow: 0 0 0 3px ${hexToRgba(Colors.Neutral70, 0)};
  transition: box-shadow 0.15s;

  &:hover {
    box-shadow: 0 0 0 3px ${Colors.Neutral70};
  }

  ${props =>
    props.isActive &&
    css`
      &,
      &:hover {
        box-shadow: 0 0 0 3px ${Colors.Blue50};
      }
    `}

  ${props =>
    props.border &&
    css`
      &:before {
        content: '';
        position: absolute;
        left: -1px;
        top: -1px;
        right: -1px;
        bottom: -1px;
        border-radius: inherit;
        border: 3px solid ${Colors.Neutral80};
      }
    `};
`

export const BgColorReset = styled(BgColorAction)<{ isActive: boolean }>`
  border: 2px solid ${Colors.Neutral70};
  transition: border-color 0.15s;

  &:after {
    content: '';
    position: absolute;
    left: -2px;
    top: calc(50% - 1px);
    right: -2px;
    transform: rotate(-45deg);
    border-top: 2px solid ${Colors.Neutral70};
    transition: border-color 0.15s;
  }

  &:hover,
  &:hover:after {
    border-color: ${Colors.Neutral60};
  }

  ${props =>
    props.isActive &&
    css`
      &,
      &:after,
      &:hover,
      &:hover:after {
        border-color: ${Colors.Blue50};
      }
    `}
`

export const BgColorCaption = styled.div`
  margin-left: 4px;
`
