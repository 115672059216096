import { memo } from 'react'
import { useDispatch } from 'react-redux'
import { Icon20, Icon24 } from 'builder/components/Icon'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { actions } from 'builder/modules/resumeEditor'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useConfig } from 'builder/hooks/useConfig'
import { useI18n } from 'builder/hooks/useI18n'
import { TooltipV2 } from 'builder/components/Tooltip'
import { Container, HeaderText, IconWrapper, Wrapper } from './styles'

type JobTitleProps = {
  tailoredJobTitle: string
  onLinkChange: () => void
  onPencilClick: () => void
  onDelete: () => void
}

const EditMenu = memo(
  ({ tailoredJobTitle, onLinkChange, onPencilClick, onDelete }: JobTitleProps) => {
    const dispatch = useDispatch()
    const { isPhone } = useMediaQueries()
    const { i18n } = useI18n()
    const showToolTip = useTypedSelector(state => state.resumeEditor.showToolTip)
    const config = useConfig()
    const isResumeOptimizerEnabled = config?.features.resumeOptimizer

    const position = 'bottom'

    const handleMouseEnter = () => {
      dispatch(actions.ShowToolTip(true))
    }

    const handleMouseLeave = () => {
      dispatch(actions.ShowToolTip(false))
    }

    return (
      <Container onMouseLeave={handleMouseLeave}>
        <Wrapper onMouseEnter={handleMouseEnter}>
          <TooltipV2 multiline value={tailoredJobTitle} position={position}>
            <HeaderText>{tailoredJobTitle}</HeaderText>
          </TooltipV2>
          <TooltipV2
            value={i18n.t('builder.resume_optimizer.edit_job_details.edit_job_title')}
            position={position}
          >
            <IconWrapper onClick={onPencilClick}>
              <Icon24.Edit />
            </IconWrapper>
          </TooltipV2>
        </Wrapper>
        {showToolTip && !isPhone && (
          <Wrapper>
            {isResumeOptimizerEnabled && (
              <TooltipV2
                value={i18n.t('builder.resume_optimizer.edit_job_details.change_job_link')}
                position={position}
              >
                <IconWrapper onClick={onLinkChange}>
                  <Icon24.BindLink />
                </IconWrapper>
              </TooltipV2>
            )}
            <TooltipV2
              value={i18n.t('builder.resume_optimizer.edit_job_details.clear_job_title')}
              position={position}
            >
              <IconWrapper onClick={onDelete}>
                <Icon20.Trash />
              </IconWrapper>
            </TooltipV2>
          </Wrapper>
        )}
      </Container>
    )
  },
)

export default EditMenu
