import styled from 'styled-components'
import Colors from 'builder/styles/colors'

export const Anchor = styled.a`
  position: absolute;
  right: 0;
  color: ${Colors.Blue50};
  transition: transform 0.3s ease;

  &:hover {
    color: ${Colors.Blue60};
    transform: translateX(4px);
  }
`
