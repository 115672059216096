import { EnumerableResumeSectionType } from 'packages/types'
import { actions } from 'builder/modules/resumeEditor'
import { store } from 'builder/modules/store'
import { generateRandomId } from 'builder/utils/generateRandomId'

/** Runs the saga creating a new card inside of the given section */
export const addCardTo = (sectionName: EnumerableResumeSectionType) => {
  store.dispatch(
    actions.addCard({
      sectionName,
      cardId: generateRandomId(),
      options: { shouldOpen: true },
    }),
  )
}
