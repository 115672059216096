import { useInfiniteQuery } from '@tanstack/react-query'
import { baseClient } from 'builder/modules/apiClient'
import { Pagination, QuestionTemplate } from 'builder/modules/interview/types'
import { InitialRequest } from '../components/DashboardView/DashboardView'
import { POLLING_REFRESH_INTERVAL_IN_MILLIS } from '../constants'
import { usePerformanceOnce } from './usePerformanceOnce'

interface Params {
  jobTitleId?: number
  questionType?: 'background'
  perPage?: number
  total: number
  setInitialRequest?: React.Dispatch<React.SetStateAction<InitialRequest | undefined>>
  enabledPerformanceMetric?: boolean
}

export const useInfiniteScrollQuestionTemplate = ({
  jobTitleId,
  questionType,
  perPage,
  total,
  setInitialRequest,
  enabledPerformanceMetric = false,
}: Params) => {
  const { registerOncePerformance, stopRegisterPerformance } = usePerformanceOnce({
    enabled: enabledPerformanceMetric,
    metricKey: `interviews-request`,
    successCallBack: () => {
      if (setInitialRequest) {
        setInitialRequest(prevState =>
          prevState === undefined ? undefined : { ...prevState, interviews: true },
        )
      }
    },
  })
  return useInfiniteQuery<Pagination<QuestionTemplate>>({
    enabled: !!jobTitleId,
    queryKey: ['useInfiniteScrollQueryTemplate', jobTitleId, questionType, perPage],
    queryFn: async ({ pageParam = 1 }) => {
      registerOncePerformance()
      const response = await baseClient.get<Pagination<QuestionTemplate>>(
        `/interview_prep/question_templates`,
        {
          params: {
            data_api_job_title_id: jobTitleId,
            question_type: questionType,
            per_page: perPage,
            page: pageParam,
          },
        },
      )
      stopRegisterPerformance()
      return response.data
    },

    refetchInterval: allPages => {
      const questions = (allPages?.pages || []).flatMap(page => page.items)
      const lastPage = allPages?.pages[allPages?.pages.length - 1]

      if (
        allPages?.pages.some(page => (page.items || []).length === lastPage?.per_page) ||
        questions.length >= total
      ) {
        return false
      }

      return POLLING_REFRESH_INTERVAL_IN_MILLIS
    },

    getNextPageParam: (lastPage, allPages) => {
      const questions = (allPages || []).flatMap(page => page.items)

      if (questions.length < total) {
        return lastPage.page + 1
      }
    },
  })
}
