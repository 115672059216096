import styled from 'styled-components'
import Typography from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import { TextField } from 'builder/components/TextField'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Column = styled.div<{ size?: number; position?: 'start' | 'center' | 'end' }>`
  flex: ${props => props.size || 1};
  display: flex;
  justify-content: ${props =>
    props?.position === 'center' ? 'center' : `flex-${props?.position}`};
`

export const Section = styled.div`
  margin: 0 32px;
  width: 160px;
`

export const ToggleLable = styled.div`
  ${Typography.Body};
`

export const Label = styled.label<{ $hasError?: boolean }>`
  padding: 0 0 7px;
  display: flex;
  align-items: center;
  color: ${({ $hasError }) => ($hasError ? Colors.Red50 : Colors.Neutral50)};

  ${Typography.Caption};
`

export const ColumnSection = styled.div`
  display: flex;
`

export const TextFieldAmount = styled(TextField)`
  & input {
    padding-left: 20px;
  }

  & span {
    color: ${Colors.Neutral90};
  }
`
