import Spinner from 'builder/components/Spinner'
import * as Styles from './styles'

export const BannerSkeleton = () => {
  return <Styles.BannerContainer />
}

export const RecruiterListSkeleton = () => {
  return (
    <Styles.ListLoader>
      <Spinner />
    </Styles.ListLoader>
  )
}

export const ActionsSkeleton = () => {
  return <Styles.ActionContainer />
}
