import { FC } from 'react'
import { describeArc } from './helpers'
import { Container, Percent, Svg, Text, TextContainer } from './styles'

export const RoundProgress: FC<{
  size: number
  percent: number
  strokeWidth?: number
}> = ({ size, percent, strokeWidth = 3 }) => {
  const canvasSize = size + strokeWidth
  const radius = size / 2
  const endAngle = (359.99 * percent) / 100
  const centerX = radius + strokeWidth / 2
  const centerY = radius + strokeWidth / 2
  const path = describeArc(centerX, centerY, radius, 0, endAngle)

  return (
    <Container
      $isGood={percent >= 90}
      style={{ width: `${size}px`, height: `${size}px`, flex: `0 0 ${size}px` }}
    >
      {percent < 100 && (
        <Svg
          width={canvasSize}
          height={canvasSize}
          viewBox={`0 0 ${canvasSize} ${canvasSize}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d={path} strokeLinecap="round" strokeWidth={strokeWidth} fill="none" />
        </Svg>
      )}
      <TextContainer>
        <Text>{percent}</Text>
        <Percent>%</Percent>
      </TextContainer>
    </Container>
  )
}
