export enum UploadResumeLocalStoreKeys {
  MY_STORY_VISIT_FLAG = 'myStoryVisitFlag',
  MY_STORY_UTMS = 'myStoryUTMs',
}

export const utmParamsList = [
  'utm_content',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_adgroup',
  'utm_lpurl',
  'utm_matchtype',
  'utm_device',
  'utm_location',
  'utm_gclid',
  'utm_msclkid',
  'utm_yclid',
]
