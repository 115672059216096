import EditorRow from 'builder/components/EditorRow'
import { FirstName } from 'builder/components/DocumentEditor/Japanese/PersonalDetails/FirstName'
import { LastName } from 'builder/components/DocumentEditor/Japanese/PersonalDetails/LastName'
import EditorField from 'builder/components/EditorField'
import { useAppSelector } from 'builder/hooks/useAppSelector'
import { SubmissionDate } from './SubmissionDate'

const ShokumuPersonalDetailsSection = (): JSX.Element => {
  const resume = useAppSelector(store => store.resumeEditor.resume)

  return (
    <EditorRow>
      <SubmissionDate />
      <EditorField quarterWidth={true}>
        <LastName lastName={resume?.lastName} />
      </EditorField>
      <EditorField quarterWidth={true}>
        <FirstName firstName={resume?.firstName} />
      </EditorField>
    </EditorRow>
  )
}
export default ShokumuPersonalDetailsSection
