import * as React from 'react'
import { BadgesContainer, BadgeStyled } from './styles'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  badges: string[]
  topic?: 'format' | 'plan'
}

export const Badges = ({ badges = [], topic, ...props }: Props) => {
  const variant = topic === 'plan' ? 'indigo' : 'amber'

  return (
    <BadgesContainer {...props}>
      {badges.map(text => (
        <BadgeStyled size="small" key={text} variant={variant} dark>
          {text}
        </BadgeStyled>
      ))}
    </BadgesContainer>
  )
}

export default Badges
