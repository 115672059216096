import { i18n } from 'builder/utils/i18n'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'

import Base from 'builder/components/Card/Base'
import { TextField } from 'builder/components/TextField'
import { formatDateRange } from 'utils/formatDateRange'

import { CardContentWrapper, Field, Row } from 'builder/components/Card/styles'
import { withAutoFocus } from '../../../../../hocs/AutoFocusHoc'
import AquisitionDatePicker from './AquisitionDatePicker'

class CoursesCard extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    item: PropTypes.object,
    autoFocus: PropTypes.bool,
    sectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    locale: PropTypes.string,
  }

  formatTitle(item) {
    const joiningString = ' ' + i18n.t('builder.resume_editor.labels.at') + ' '

    return (
      [item.course, item.institution].filter(x => x).join(joiningString) ||
      i18n.t('builder.resume_editor.not_specified')
    )
  }

  updateSimpleField = e => {
    const { onChange, sectionId, item } = this.props
    onChange(sectionId, item.id, { [e.target.name]: e.target.value }, true)
  }

  updateDateRange = val => {
    const { onChange, sectionId, item } = this.props
    onChange(sectionId, item.id, val, true)
  }

  render() {
    const { item, autoFocus, ...otherProps } = this.props
    const title = this.formatTitle(item)
    const titleDates = formatDateRange(item)

    return (
      <Base title={title} subtitle={titleDates} item={item} {...otherProps}>
        <CardContentWrapper>
          <Row>
            <AquisitionDate {...{ autoFocus, item }} updateDateRange={this.updateDateRange} />

            <Course {...{ autoFocus, item }} updateSimpleField={this.updateSimpleField} />
          </Row>
        </CardContentWrapper>
      </Base>
    )
  }
}

function Course(props) {
  const { autoFocus, updateSimpleField, item } = props

  return (
    <>
      <Field>
        <TextField
          autoFocus={autoFocus}
          label={i18n.t('builder.resume_editor.courses_card.course')}
          name="course"
          onChange={updateSimpleField}
          value={item.course || ''}
        />
      </Field>
    </>
  )
}

Course.propTypes = {
  updateSimpleField: PropTypes.func,
  autoFocus: PropTypes.bool,
  item: PropTypes.object,
}

function AquisitionDate(props) {
  const { item, updateDateRange } = props

  return (
    <>
      <Field>
        <AquisitionDatePicker
          value={item}
          onChange={val => updateDateRange(val)}
          currentlyLabel={i18n.t('builder.resume_editor.courses_card.currently_acquiring')}
          headingLabel={i18n.t('builder.resume_editor.courses_card.acquisition_label')}
        />
      </Field>
    </>
  )
}
AquisitionDate.propTypes = {
  updateDateRange: PropTypes.func,
  autoFocus: PropTypes.bool,
  item: PropTypes.object,
}

export const Courses = withAutoFocus(CoursesCard)
