import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import { ColorsProp } from './UserProfile'

export const Container = styled.div`
  display: flex;
  overflow: hidden;
  height: 48px;
`

export const ScoreWrapper = styled.div<{ percentageColor: ColorsProp }>`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  position: relative;
  background: ${({ percentageColor }) =>
    `linear-gradient(to top, ${percentageColor.start}, ${percentageColor.end})`};
  padding: 2px;
`

export const Score = styled.div<{ percentageColor: ColorsProp }>`
  width: 30px;
  text-align: center;
  border-radius: 30px;
  padding: 0 4px;
  position: absolute;
  left: 7.5px;
  bottom: -4px;
  background: ${({ percentageColor }) => percentageColor.start};
  color: ${Colors.White};
  ${Typography.Tiny};
  ${FontWeights.DemiBold};
`

export const AvatarIcon = styled.img`
  width: 40px;
  height: 40px;
  flex: 0 0 40px;
  border-radius: 50%;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 14px;
  overflow: hidden;
  white-space: nowrap;
`

export const Header = styled.div<{ active?: boolean }>`
  color: ${({ active }) => (active ? Colors.Blue50 : Colors.Neutral90)};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  ${Typography.Body};
  ${FontWeights.Medium};

  ${Media.Phone`
    color: ${Colors.Neutral90};
    ${Typography.Subhead};
    ${FontWeights.Medium};
  `}
`

export const SubHeader = styled.div`
  color: ${Colors.Neutral50};
  ${Typography.Caption};
  ${FontWeights.Regular};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
