import styled from 'styled-components'
import { Colors } from 'builder/styles/colors'

export const ColorsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -24px;
  margin-bottom: -16px;
`

export const ColorsItem = styled.div`
  margin-right: 24px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ColorsItemPreview = styled.div`
  display: block;
  width: 40px;
  height: 40px;
  background-color: ${props => props.color};
  border-radius: 50%;
  border: ${props => (props.color === Colors.White ? `1px solid ${Colors.Neutral20}` : 'none')};
`
