import { createAction, createReducer } from '@reduxjs/toolkit'
import { Store } from 'builder/modules/store'
import {
  SevenStoriesStore,
  AccomplishmentPayload,
  SaveAccsPayload,
  AssessmentResult,
} from './types'
import { SevenStoriesSteps } from './constants'

export const actions = {
  setStep: createAction<SevenStoriesSteps>('builder/SEVEN_STORIES_SET_STEP'),
  setAddAccomplishment: createAction<AccomplishmentPayload[]>(
    'builder/SEVEN_STORIES_SET_ADD_ACCOMPLISHMENT',
  ),
  setAccomplishmentStatus: createAction<{ isSuccess: boolean }>(
    'builder/SEVEN_STORIES_SET_ADD_ACCOMPLISHMENT_STATUS',
  ),
  setSaveAccomplishments: createAction<SaveAccsPayload>(
    'builder/SEVEN_STORIES_SET_SAVE_ACCOMPLISHMENTS',
  ),
  setAssessmentResult: createAction<AssessmentResult>(
    'builder/SEVEN_STORIES_SET_ASSESSMENT_RESULT',
  ),
  fetchAssessmentResult: createAction('builder/SEVEN_STORIES_FETCH_ASSESSMENT_RESULT'),
}

export const selectors = {
  step: (state: Store) => state.sevenStories.step,
  accomplishment: (state: Store) => state.sevenStories.accomplishments,
  status: (state: Store) => state.sevenStories.status,
  assessmentResult: (state: Store) => state.sevenStories.assessmentResult,
}

const initialState: SevenStoriesStore = {
  step: SevenStoriesSteps.ACCOMPLISHMENTS,
  status: { isSuccess: false },
  accomplishments: [],
  assessmentResult: { summary: '', accomplishments: [], skills: [] },
}

export const reducer = createReducer(initialState, reducer => {
  reducer.addCase(actions.setAccomplishmentStatus, (draft, action) => {
    draft.status = action.payload
  })
  reducer.addCase(actions.setAssessmentResult, (draft, action) => {
    draft.assessmentResult = action.payload
  })
})
