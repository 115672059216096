import { createRef, Component } from 'react'
import PropTypes from 'prop-types'
import { Overlay, Content } from './styles'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { reflow } from 'builder/utils/reflow'
import EscapeHandler from 'builder/components/EscapeHandler'

class ModalOverlay extends Component {
  static propTypes = {
    children: PropTypes.element,
    onClick: PropTypes.func,
    onClose: PropTypes.func,
    overlayFadeDuration: PropTypes.number,
    contentSlideDuration: PropTypes.number,
    fullScreen: PropTypes.bool,
    backgroundOpacity: PropTypes.number,
    background: PropTypes.string,
  }

  static defaultProps = {
    overlayFadeDuration: 0,
    contentSlideDuration: 100,
    backgroundOpacity: 0.8,
  }

  state = {
    isVisible: false,
    fullScreen: false,
  }

  constructor(props) {
    super(props)

    this.overlayRef = createRef()
    this.contentRef = createRef()
  }

  componentDidMount() {
    this.show()
    disableBodyScroll(this.overlayRef.current)
  }

  componentWillUnmount() {
    enableBodyScroll(this.overlayRef.current)
  }

  show = () => {
    reflow(this.overlayRef.current)
    this.setState({ isVisible: true })
  }

  handleOverlayClick = event => {
    if (!this.props.onClick) return

    const { current: overlayNode } = this.overlayRef
    const { current: contentNode } = this.contentRef

    if (overlayNode === event.target || contentNode === event.target) this.props.onClick(event)
  }

  render() {
    const { isVisible } = this.state
    const { overlayFadeDuration, contentSlideDuration, fullScreen, backgroundOpacity, background } =
      this.props
    const handleEscapePress = this.props.onClose || this.props.onClick

    return (
      <Overlay
        onClick={this.handleOverlayClick}
        ref={this.overlayRef}
        isVisible={isVisible}
        duration={overlayFadeDuration}
        fullScreen={fullScreen}
        backgroundOpacity={backgroundOpacity}
        background={background}
      >
        {handleEscapePress && <EscapeHandler onPress={handleEscapePress} />}

        <Content
          ref={this.contentRef}
          isVisible={isVisible}
          duration={contentSlideDuration}
          fullScreen={fullScreen}
        >
          {this.props.children}
        </Content>
      </Overlay>
    )
  }
}

export default ModalOverlay
