import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'

export const Content = styled.div`
  padding: 16px 32px 0;
  flex: 1 1 auto;

  ${Media.Tablet`
    padding-bottom: 0;
  `};

  ${Media.Phone`
    padding: 16px 24px 0 24px;
   `}
`

export const Title = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};
  margin-bottom: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Description = styled.div`
  color: ${Colors.Neutral50};
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limit to 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  ${Media.Tablet`
    margin-bottom: 0;
   `}
`
