import { useLayoutEffect, useRef } from 'react'
import * as React from 'react'
import { enableBodyScroll, disableBodyScroll, BodyScrollOptions } from 'body-scroll-lock'

export const useBodyScrollLock = (
  ref: React.RefObject<HTMLElement>,
  options?: BodyScrollOptions,
): void => {
  // Copy options to a ref to avoid passing them to deps and prevent extra effect calls
  const optionsRef = useRef(options)

  useLayoutEffect(() => {
    const node = ref.current
    node && disableBodyScroll(node, optionsRef.current)

    return () => {
      node && enableBodyScroll(node)
    }
  }, [ref])
}
