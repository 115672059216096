import { memo, useEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { i18n as I18n } from 'builder/utils/i18n'
import { useAppSelector } from 'builder/hooks/useAppSelector'
import { ResumeUploadStep, PrefillResumeEvents } from 'builder/modules/constants'
import UploadResumeIllustration from '../../../../../../app/assets/images/builder/fill-resume/upload-illustration.png'
import { Div, UploadFailedIllustration, FailedTitle, ErrorMessage } from '../styles'

const FileUploadFailed = () => {
  const { validationError, errorSource } = useAppSelector(
    state => state.resumeEditor.prefillResumeSection,
  )

  useEffect(() => {
    if (errorSource === ResumeUploadStep.validation)
      trackInternalEvent(PrefillResumeEvents.seeResumeUploadError, { error: validationError })
    if (errorSource === ResumeUploadStep.parsing)
      trackInternalEvent(PrefillResumeEvents.seeResumeParsingError)
  }, [errorSource, validationError])

  const genericError =
    'Oops! Something went wrong. We apologize for the inconvenience. Please try again later. If the issue persists, please contact support for assistance.'

  return (
    <>
      <Div>
        <UploadFailedIllustration src={UploadResumeIllustration} />
      </Div>
      <FailedTitle>{I18n.t('builder.upload_resume.upload_failed')}</FailedTitle>
      <ErrorMessage>{validationError || genericError}</ErrorMessage>
    </>
  )
}

export default memo(FileUploadFailed)
