import { useDispatch } from 'react-redux'
import { useTransition } from '@react-spring/web'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import Button from 'builder/components/Button'
import { Icon20 } from 'builder/components/Icon'
import { actions, selectors } from 'builder/modules/enterpriseModal'
import { transitionValues } from './constants'
import * as Styled from './styles'
import targetURL from './assets/img/target.svg'

const JobStatusHiredModal = () => {
  const dispatch = useDispatch()
  const isModalOpen = useTypedSelector(selectors.isJobStatusHiredModalOpen)
  const transition = useTransition(isModalOpen, transitionValues)

  const handleClose = () => {
    dispatch(actions.closeJobStatusHiredModal())
  }

  return transition((style, item) =>
    item ? (
      <Styled.ModalContainer style={style}>
        <Styled.ModalContent>
          <Styled.Close>
            <Icon20.Close onClick={handleClose} />
          </Styled.Close>
          <Styled.Image src={targetURL} />
          <Styled.Text>Congratulations!</Styled.Text>
          <Styled.SubText>
            You managed to complete all steps and landed your dream job!
          </Styled.SubText>
          <Button size="small" onClick={handleClose}>
            Close My Progress
          </Button>
        </Styled.ModalContent>
      </Styled.ModalContainer>
    ) : null,
  )
}

export default JobStatusHiredModal
