import { createAction, createReducer } from '@reduxjs/toolkit'
import { Store } from '../store'
import { GeneralEditorStore, EditorConfigApiPayload } from './types'

// ---
// Selectors
// ---
export const selectors = {
  resumeTemplates: (state: Store) => state.generalEditor.resumeTemplates,
  coverLetterTemplates: (state: Store) => state.generalEditor.coverLetterTemplates,
  levels: (state: Store) => state.generalEditor.levels,
  isLoaded: (state: Store) => state.generalEditor.isLoaded,
}

// ---
// Action creators
// ---
export const actions = {
  fetchEditorPayloadRequest: createAction('builder/FETCH_EDITOR_PAYLOAD_REQUEST'),
  fetchEditorPayloadSuccess: createAction<EditorConfigApiPayload>(
    'builder/FETCH_EDITOR_PAYLOAD_SUCCESS',
  ),
  testForceChoose: createAction<{ experiment: string; choice: unknown }>(
    'builder/TEST_FORCE_CHOOSE',
  ),
}

// ---
// Reducer
// ---
export const reducer = createReducer<GeneralEditorStore>(
  {
    isLoaded: false,
    locales: [],
    levels: {},
    suggestionLocales: {},
    resumeTemplates: [],
    coverLetterTemplates: [],
  },
  reducer => {
    reducer.addCase(actions.fetchEditorPayloadSuccess, (draft, { payload }) => {
      draft.locales = payload.locales
      draft.resumeTemplates = payload.resumeTemplates
      draft.coverLetterTemplates = payload.letterTemplates
      draft.suggestionLocales = payload.suggestionLocales
      draft.levels.skill = payload.skillLevels
      draft.levels.language = payload.languageLevels
      draft.isLoaded = true
    })
  },
)
