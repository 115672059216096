import { CareerCardType } from 'builder/modules/careerPath2'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { Title, TrashIcon, SubContainer, MiniTrashIcon } from './styles'

type Props = {
  cardData: CareerCardType
  handleDeleteCard: (e: React.MouseEvent, id: number) => void
}

export const Header = ({ cardData, handleDeleteCard }: Props) => {
  const { isPhone } = useMediaQueries()

  return (
    <SubContainer>
      <Title>{cardData.title}</Title>
      <div onClick={e => handleDeleteCard(e, cardData.id)}>
        {isPhone ? <MiniTrashIcon /> : <TrashIcon />}
      </div>
    </SubContainer>
  )
}
