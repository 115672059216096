import { useI18n } from 'builder/hooks/useI18n'
import { Container, EmptySection, EmptySectionText, Section } from './styles'

interface EmptyListProps {
  date: string
}

export const EmptyList = ({ date }: EmptyListProps) => {
  const { i18n } = useI18n()

  return (
    <Container>
      <Section />
      <EmptySection>
        <EmptySectionText>
          {i18n.t(`builder.resume_distribution.dashboard.recruites_list.empty_list`, {
            date,
          })}
        </EmptySectionText>
      </EmptySection>
    </Container>
  )
}
