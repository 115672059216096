import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import { TextField } from 'builder/components/TextField'

export const Container = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 100%;
  align-items: flex-start;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
`

export const Header = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};
  text-align: center;
  mix-blend-mode: normal;
  width: 100%;
`

export const FieldContainer = styled.div`
  width: 100%;
  border-radius: 4px;
  gap: 4px;
`

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export const InputField = styled(TextField)``

export const RightActionContainer = styled.div`
  display: flex;
`
