import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { trackingInfo } from 'builder/modules/careerCoaching/constants'
import {
  Container,
  Content,
  Header,
  SubHeader,
  TopContent,
  Text,
  StyledButton,
  Buttons,
} from './styles'

const BannerContent = (): JSX.Element => {
  const navigate = useNavigate()

  const handleClick = useCallback(() => {
    navigate('/onboarding-form')

    trackInternalEvent('click_find_my_coach_button', {
      ...trackingInfo,
      plan_name: 'coach_marketplace',
      button_place: 'top',
    })
  }, [navigate])

  return (
    <Container>
      <Content>
        <Header>Accelerate Your Career Growth with Career.io Coaching Marketplace</Header>
        <SubHeader>
          <TopContent>
            <Text>Find the Perfect Coach to Guide You Towards Success</Text>
          </TopContent>
        </SubHeader>
        <Buttons>
          <StyledButton onClick={handleClick}>Find a Coach</StyledButton>
        </Buttons>
      </Content>
    </Container>
  )
}

export default BannerContent
