import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Container } from 'builder/components/Container'
import Sizes from 'builder/styles/sizes'
import { Tab } from '../Tabs'

export const FindJobContainer = styled(Container)<{ $dashboardV2: boolean }>`
  position: relative;
  top: ${({ $dashboardV2 }) => ($dashboardV2 ? '1' : '81')}px;
  width: 100%;
  background-color: ${Colors.White};
`

export const NearMe = styled.span`
  ${Typography.Caption}
  ${FontWeights.Regular}
color: ${Colors.Neutral50};
`
export const Count = styled.span`
  color: ${Colors.Neutral30};
`
export const Heading = styled.h2`
  ${Typography.Subhead}
  ${FontWeights.DemiBold}
  color: ${Colors.Neutral90};

  .count {
    color: ${Colors.Neutral50};
    margin-left: 4px;
  }
`

export const Banner = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;

  & > img {
    margin-right: 24px;

    ${Media.Tablet`
      margin-right: 20px;
    `}
  }

  & > div {
    ${Typography.Caption}
    color: ${Colors.Blue90};
  }

  ${Media.Tablet`
    padding: 0 20px;
  `}

  ${Media.Phone`
    align-items: flex-start;
  `}
`
export const TabContainer = styled.div`
  position: relative;
  background-color: ${Colors.White};
`

export const PageActionsContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`

export const CustomTab = styled(Tab)`
  & > div {
    padding: 10px var(--Spacing-2XS, 8px) 10px var(--Spacing-XS, 12px);
    display: flex;
    justify-content: center;
  }
`

export const TargetLocationImage = styled.img`
  width: 273px;
  height: 158px;
`
export const ManagerJobAlertButtonContainer = styled.div`
  margin: ${Sizes.L} 0;
  ${Media.Tablet`
    margin: ${Sizes.M} 0 ;

  `}
  ${Media.Phone`
    margin: ${Sizes.XS} 0;

  `}
`
