import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { actions, selectors } from 'builder/modules/careerPath'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { actions as careerPath2Action } from 'builder/modules/careerPath2'
import { SnackbarTypes, actions as uiActions } from 'builder/modules/ui'
import {
  selectors as coachingSelectors,
  actions as coachingActions,
} from 'builder/modules/careerCoaching'
import { FetchStatuses } from 'builder/modules/constants'
import { trackingInfo } from 'builder/modules/careerCoaching/constants'
import { selectors as userSelectors, actions as userActions } from 'builder/modules/user'
import { linkedinUrlRegex } from '../components/constants'
import { getStep } from './utils'

interface IndustriesProps {
  label: string
  value: string
}

export interface IntakeForm {
  services: string[]
  focusTopic: string
  careerStages: string
  jobFunctions: string[]
  availableDays: string[]
  availableTimes: string[]
  industries: IndustriesProps[]
  timezone: string
  location: string
  linkedinUrl: string
}

export const useEntryTest = () => {
  const formLength = 10

  const [page, setPage] = useState(0)
  const [form, setForm] = useState<{ preferences: IntakeForm }>({
    preferences: {
      services: [],
      focusTopic: '',
      careerStages: '',
      jobFunctions: [],
      availableDays: [],
      availableTimes: [],
      industries: [],
      timezone: '',
      location: '',
      linkedinUrl: '',
    },
  })

  const [intakeProgress, setIntakeProgress] = useState(0)

  const [isExecutive, setIsExecutive] = useState(false)

  const location = useTypedSelector(selectors.location)
  const intakeTestDetails = useTypedSelector(coachingSelectors.intakeTestDetails)
  const isIntakeTestLoaded = useTypedSelector(coachingSelectors.isIntakeTestLoaded)
  const isFetchIntakeTestLoading = useTypedSelector(coachingSelectors.isFetchIntakeTestLoading)
  const user = useTypedSelector(userSelectors.userData)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    // Redirect user on packages page
    if (isIntakeTestLoaded) {
      // Added this action to set default state of status
      dispatch(coachingActions.setIntakeTestStatus(FetchStatuses.notAsked))

      navigate('/packages')
    }
  }, [dispatch, isIntakeTestLoaded, navigate])

  // Store intake data on storage
  const onStorageUpdate = (preferences: IntakeForm) => {
    localStorage.setItem('INTAKE_PREFERENCES', JSON.stringify(preferences))
  }

  // Focus on coach value change
  const onEntryFocusChange = (name: string) => {
    let selectedServices = [...(form.preferences?.services || [])]

    if (!selectedServices.includes(name)) {
      trackInternalEvent('click_focus_area_checkbox', { ...trackingInfo, focus_name: name })
      selectedServices.push(name)
    } else {
      selectedServices = selectedServices.filter(topic => topic !== name)
    }

    const preferences = {
      ...form.preferences,
      services: selectedServices,
    }

    setForm({ preferences })
    onStorageUpdate(preferences)
  }

  // Specific focus areas change
  const onSpecificAreasFocusChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const preferences = {
      ...form.preferences,
      focusTopic: event.target.value,
    }

    setForm({ preferences })
    onStorageUpdate(preferences)
  }

  // Current career stage change
  const onCurrentStageChange = (name: string) => {
    const preferences = {
      ...form.preferences,
      careerStages: name,
    }

    setForm({ preferences })
    onStorageUpdate(preferences)

    trackInternalEvent('click_career_stage', { ...trackingInfo, career_stage_name: name })
  }

  // Whats your job function change
  const onYourJobFunctionChange = (name: string) => {
    let selectedJobFunctions = [...(form.preferences.jobFunctions || [])]

    if (!selectedJobFunctions.includes(name)) {
      selectedJobFunctions.push(name)

      trackInternalEvent('click_job_function', { ...trackingInfo, job_function_name: name })
    } else {
      selectedJobFunctions = selectedJobFunctions.filter(topic => topic !== name)
    }

    const preferences = {
      ...form.preferences,
      jobFunctions: selectedJobFunctions,
    }

    setForm({ preferences })
    onStorageUpdate(preferences)
  }

  // Avaible days change
  const handleDaysChange = (name: string) => {
    let selectedAvailableDays = [...(form.preferences.availableDays || [])]

    if (!selectedAvailableDays.includes(name)) {
      selectedAvailableDays.push(name)

      trackInternalEvent('click_free_days', { ...trackingInfo, day_name: name })
    } else {
      selectedAvailableDays = selectedAvailableDays.filter(topic => topic !== name)
    }

    const preferences = {
      ...form.preferences,
      availableDays: selectedAvailableDays,
    }

    setForm({ preferences })
    onStorageUpdate(preferences)
  }

  // What time work for you change
  const handleAvailableTimesChange = (name: string) => {
    let selectedAvailableTimes = [...(form.preferences.availableTimes || [])]

    if (!selectedAvailableTimes.includes(name)) {
      selectedAvailableTimes.push(name)

      trackInternalEvent('click_free_time', { ...trackingInfo, time_name: name })
    } else {
      selectedAvailableTimes = selectedAvailableTimes.filter(topic => topic !== name)
    }

    const preferences = {
      ...form.preferences,
      availableTimes: selectedAvailableTimes,
    }

    setForm({ preferences })
    onStorageUpdate(preferences)
  }

  // Targeting any industries change

  const onTargetingIndustriesChange = (industries: IndustriesProps[]) => {
    const preferences = {
      ...form.preferences,
      industries,
    }

    setForm({ preferences })
    onStorageUpdate(preferences)
  }

  // Timezone change logic
  const onLocationhandleChange = (name: string, value: string) => {
    if (name === 'location') {
      const preferences = {
        ...form.preferences,
        location: value,
      }

      setForm({ preferences })
      onStorageUpdate(preferences)
      dispatch(actions.setLocation(value))
    }
  }

  // Where are you located change
  const onHandleTimeZoneChange = (timezone: string) => {
    const preferences = {
      ...form.preferences,
      timezone,
    }

    setForm({ preferences })
    onStorageUpdate(preferences)

    trackInternalEvent('select_timezone', { ...trackingInfo, timezone_name: timezone })
  }

  // Could you share your LinkedIn profile?
  const onHandleLinkedinUrlChange = (linkedinUrl: string) => {
    const preferences = {
      ...form.preferences,
      linkedinUrl,
    }

    setForm({ preferences })
    onStorageUpdate(preferences)
  }

  // Where are you located change
  const onHandleLocationChange = (location: string) => {
    const preferences = {
      ...form.preferences,
      location,
    }

    setForm({ preferences })
    onStorageUpdate(preferences)

    trackInternalEvent('select_location', { ...trackingInfo, location_name: location })
  }

  useEffect(() => {
    // Get a intake data from storage
    const storagePreferences = localStorage.getItem('INTAKE_PREFERENCES')
    const preferences = storagePreferences ? JSON.parse(storagePreferences) : null

    if (preferences) {
      setForm({ preferences })
      preferences?.industries?.length &&
        dispatch(careerPath2Action.setSkills(preferences?.industries))
    }

    // Get a intake page from storage
    const storedIntakePage = JSON.parse(localStorage.getItem('INTAKE_PAGE') || '0')

    setPage(storedIntakePage)

    // Get a intake test details
    dispatch(coachingActions.fetchIntakeTestDetails())
  }, [dispatch])

  // Set intake test API response on local state
  useEffect(() => {
    const storagePreferences = localStorage.getItem('INTAKE_PREFERENCES')
    const preferences = storagePreferences ? JSON.parse(storagePreferences) : null

    if (intakeTestDetails?.id && !preferences) {
      const {
        services,
        focusTopic,
        careerStages,
        jobFunctions,
        availableDays,
        availableTimes,
        timezone,
        location,
        industries,
      } = intakeTestDetails?.value

      const preferences = {
        services,
        focusTopic,
        careerStages,
        jobFunctions,
        availableDays,
        availableTimes,
        timezone,
        location,
        industries,
        linkedinUrl: user?.linkedinUrl || '',
      }

      // Set api response on state
      setForm({ preferences })

      // Set industries response
      industries?.length && dispatch(careerPath2Action.setSkills(industries))
    } else {
      setForm(prevState => {
        return {
          preferences: {
            ...prevState.preferences,
            linkedinUrl: user?.linkedinUrl || '',
          },
        }
      })
    }
  }, [dispatch, intakeTestDetails, user?.linkedinUrl])

  // Trigger snackbar
  const handleSnackbar = (type: SnackbarTypes, message: string) => {
    dispatch(
      uiActions.setSnackBarOpen({
        status: true,
        type: type,
        text: message,
      }),
    )

    setTimeout(() => {
      dispatch(
        uiActions.setSnackBarOpen({
          status: false,
          type: type,
        }),
      )
    }, 3000)
  }

  // Validate intake test form
  const validateIntake = (page: number) => {
    const preferences = form?.preferences

    // Regular expression for a simple URL pattern
    const urlMatch = preferences?.linkedinUrl.match(linkedinUrlRegex)
    const isValidUrl = urlMatch !== null

    // Define the validation logic for each page in a mapping object.
    const pageValidation: { [key: number]: boolean } = {
      1: preferences?.careerStages !== '',
      2: isExecutive ? preferences?.focusTopic !== '' : preferences?.services.length > 0,
      3: isExecutive ? isValidUrl : true,
      4: preferences?.jobFunctions.length > 0,
      5: preferences?.industries.length > 0,
      6: preferences?.timezone !== '' && preferences?.location !== '',
      7: preferences?.availableDays.length > 0,
      8: preferences?.availableTimes.length > 0,
    }
    return pageValidation[page] ?? true
  }

  const trackInputCareerRequest = () => {
    let focusTopic = ''
    const intakePreferences = localStorage.getItem('INTAKE_PREFERENCES')

    if (intakePreferences) {
      focusTopic = JSON.parse(intakePreferences).focusTopic
    }

    trackInternalEvent('input_career_request', { ...trackingInfo, request: focusTopic })
  }

  // Render validation error message
  const renderErrorMessage = (page: number) => {
    const DefaultMessage = 'Select at least one option to move forward'
    switch (page) {
      case 6:
        return 'Select timezone and location'
      case 2:
        return isExecutive ? 'Please, write your request' : DefaultMessage
      case 3:
        return 'Please add proper linkedin url.'
      default:
        return DefaultMessage
    }
  }

  const continueNext = () => {
    // send event for step 0 of onboarding-form
    if (page === 0) {
      trackInternalEvent('start_onboarding_test_click_continue_button', { ...trackingInfo })
    }

    const isValidate = validateIntake(page)

    if (isValidate) {
      if (isExecutive && page === 2) {
        trackInputCareerRequest()
      }

      // Update the linkedin url in user profile
      if (isExecutive && page === 3) {
        // Anylitic event
        trackInternalEvent('input_linkedin_url', {
          label: 'career_coaching',
          linkedin_url: form.preferences.linkedinUrl,
        })

        const { linkedinUrl } = form.preferences

        const data = {
          firstName: user?.firstName,
          lastName: user?.lastName,
          email: user?.email,
          linkedinUrl,
        }

        dispatch(userActions.updateUserRequest(data))
      }

      setPage(page === formLength - 1 ? 0 : page + 1)
      // Store intake step on storage
      localStorage.setItem('INTAKE_PAGE', JSON.stringify(page === formLength - 1 ? 0 : page + 1))
    } else {
      handleSnackbar(SnackbarTypes.failure, renderErrorMessage(page))
    }
  }

  const handlePrev = () => {
    setPage(page === 0 ? formLength - 1 : page - 1)

    // Store intake step on storage
    localStorage.setItem('INTAKE_PAGE', JSON.stringify(page === 0 ? formLength - 1 : page - 1))
  }

  // Skip page
  const onSkipPage = () => {
    setPage(page === formLength - 1 ? 0 : page + 1)

    trackInternalEvent('click_specific_area_skip_button', { ...trackingInfo })
    trackInputCareerRequest()
  }

  // Edit Intake test
  const handleEditIntakeTest = (page: number) => {
    setPage(page)

    // Store intake step on storage
    localStorage.setItem('INTAKE_PAGE', JSON.stringify(page))
    trackInternalEvent('click_edit_intake_test', { ...trackingInfo, question_name: getStep(page) })
  }

  // Update Intake progress
  useEffect(() => {
    const startingStep = 2
    const progressPerStep = 100 / (isExecutive ? 3 : 8)

    let percentageCompleted

    if (page === 0) {
      percentageCompleted = 0
    } else {
      percentageCompleted = Math.max(
        0,
        Math.min(parseFloat(((page - startingStep + 1) * progressPerStep).toFixed(0)), 100),
      )
    }
    setIntakeProgress(percentageCompleted)
  }, [page, isExecutive])

  useEffect(() => {
    setIsExecutive(form?.preferences?.careerStages === 'Executive')
  }, [form?.preferences?.careerStages])

  // Create and update intake test details
  const onIntakeSubmit = useCallback(() => {
    trackInternalEvent('click_view_packages_button', { ...trackingInfo })

    const {
      availableDays,
      availableTimes,
      careerStages,
      focusTopic,
      industries,
      jobFunctions,
      location,
      services,
      timezone,
    } = form?.preferences

    const payload = {
      availableDays: availableDays || [],
      availableTimes: availableTimes || [],
      careerStages: careerStages || '',
      focusTopic: focusTopic || '',
      industries: industries?.map(item => item?.value) || [],
      jobFunctions: jobFunctions || [],
      location: location || '',
      services: services || [],
      timezone: timezone || '',
    }

    dispatch(coachingActions.setUserRedirectState(false))

    if (intakeTestDetails?.id) {
      // Update intake test
      dispatch(
        coachingActions.updateIntakeTestDetails({
          preferences: payload,
          id: intakeTestDetails?.id,
        }),
      )
    } else {
      // Create intake test
      dispatch(coachingActions.createIntakeTestDetails({ preferences: payload }))
    }
  }, [dispatch, form?.preferences, intakeTestDetails?.id])

  return {
    page,
    form,
    location,
    onLocationhandleChange,
    onEntryFocusChange,
    onSpecificAreasFocusChange,
    onCurrentStageChange,
    onYourJobFunctionChange,
    handleDaysChange,
    handleAvailableTimesChange,
    onTargetingIndustriesChange,
    onHandleTimeZoneChange,
    onHandleLocationChange,
    handleEditIntakeTest,
    continueNext,
    handlePrev,
    onSkipPage,
    intakeProgress,
    onIntakeSubmit,
    isFetchIntakeTestLoading,
    onHandleLinkedinUrlChange,
    isExecutive,
  }
}
