import { Fragment, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { useDispatch } from 'react-redux'
import { useI18n } from 'builder/hooks/useI18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { actions, selectors } from 'builder/modules/panel'
import { DocumentTypes, ResumeIDStatuses } from 'builder/modules/constants'
import { useCareerTasks } from 'builder/components/CareerGoalCard'
import BrokenBanner from '../BrokenBanner'
import * as Styles from './styles'

const tickMarkImage = require('images/Career-foundation/Status_Offer.png')

const ResumeBanner = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { i18n } = useI18n()
  const [showFirst, setShowFirst] = useState(true)
  const tunerResumeId = useTypedSelector(selectors.tunerResumeId)
  const [searchParams, setSearchParams] = useSearchParams()
  const resumeStatus = useTypedSelector(selectors.resumeStatus)
  const state = resumeStatus === ResumeIDStatuses.failed

  // Serialize career plan tasks
  const { careerTasks } = useCareerTasks()
  const { visual } = careerTasks[0]

  const removeParams = () => {
    if (searchParams.has('tr_promo_banner')) {
      searchParams.delete('tr_promo_banner')
      setSearchParams(searchParams)
    }
  }

  const toggle = () => {
    setShowFirst(!showFirst)
    trackInternalEvent('tr_banner_click_try_later', {
      label: 'tr_leads_to_cio',
    })
    localStorage.clear()
    removeParams()
  }

  const viewMyResume = () => {
    if (!tunerResumeId) {
      dispatch(actions.createDocument({ type: DocumentTypes.resume }))
    } else {
      navigate(`/resumes/${tunerResumeId}/edit`)
    }
    trackInternalEvent('tr_banner_click_view_my_resume', {
      label: 'tr_leads_to_cio',
    })
    localStorage.clear()
  }

  return (
    <>
      {!state ? (
        <Fragment>
          {showFirst && (
            <>
              <Styles.ResumeUploadContainer>
                <Styles.BannerLeftContent>
                  <Styles.ResumeUploadTitle>
                    {i18n.t('builder.panel.career_foundation_resume_banner.title')}
                  </Styles.ResumeUploadTitle>
                  <Styles.ResumeUploadText>
                    {i18n.t('builder.panel.career_foundation_resume_banner.text')}
                  </Styles.ResumeUploadText>
                  <Styles.ButtonContainer>
                    <Styles.TryLaterButton onClick={toggle}>
                      {i18n.t('builder.panel.career_foundation_resume_banner.try_later_button')}
                    </Styles.TryLaterButton>
                    <Styles.ViewMyResumeButton onClick={viewMyResume}>
                      {i18n.t(
                        'builder.panel.career_foundation_resume_banner.view_my_resume_button',
                      )}
                    </Styles.ViewMyResumeButton>
                  </Styles.ButtonContainer>
                </Styles.BannerLeftContent>

                <Styles.BannerRightContent>
                  <Styles.CardContainer>
                    <Styles.TickMarkContainer src={tickMarkImage} />
                    <Styles.ResourceCardVisualWrapper>{visual}</Styles.ResourceCardVisualWrapper>
                    <Styles.ResumeGradient />
                  </Styles.CardContainer>
                </Styles.BannerRightContent>
              </Styles.ResumeUploadContainer>
            </>
          )}
        </Fragment>
      ) : (
        <BrokenBanner
          title={i18n.t('builder.panel.career_foundation_error_state_banner.title')}
          subTitle={i18n.t('builder.panel.career_foundation_error_state_banner.text')}
          leftButton={i18n.t('builder.panel.career_foundation_error_state_banner.dismiss_button')}
          rightButton={i18n.t(
            'builder.panel.career_foundation_error_state_banner.build_resume_button',
          )}
          toggle={toggle}
          viewMyResume={viewMyResume}
        />
      )}
    </>
  )
}

export default ResumeBanner
