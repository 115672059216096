import * as React from 'react'
import * as Styled from './styles'

type Props = React.HTMLAttributes<HTMLDivElement>

export const ActionGroupContainer = ({ children, ...rest }: Props) => {
  return (
    <Styled.GroupContainer role="group" {...rest}>
      {children}
    </Styled.GroupContainer>
  )
}
