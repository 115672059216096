import styled from 'styled-components'
import { HorizontalTransitionFade } from 'builder/components/HorizontalTransitionFade'

export const LoaderContainer = styled(HorizontalTransitionFade)`
  height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
