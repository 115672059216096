import Colors from 'builder/styles/colors'

export enum CareerServices {
  PERSONAL_BRANDING = 'Personal Branding',
  JOB_SEARCH_STRATEGY = 'Job Search Strategy',
  CAREER_TRANSITION = 'Career Transition',
  INTERVIEW_PREP = 'Interview Prep',
  SALARY_NEGOTIATION = 'Salary Negotiation',
  WORKPLACE_CHALLENGES = 'Workplace Challenges',
}

export const getThemeColors = (service: string) => {
  let bgColor = ''
  let color = ''
  switch (service) {
    case CareerServices.PERSONAL_BRANDING:
      bgColor = Colors.Blue10
      color = Colors.Blue80
      break

    case CareerServices.JOB_SEARCH_STRATEGY:
      bgColor = Colors.Indigo10
      color = Colors.Indigo80
      break

    case CareerServices.CAREER_TRANSITION:
      bgColor = Colors.Beige10
      color = Colors.Beige80
      break

    case CareerServices.INTERVIEW_PREP:
      bgColor = Colors.Green10
      color = Colors.Green80
      break

    case CareerServices.SALARY_NEGOTIATION:
      bgColor = Colors.Amber10
      color = Colors.Amber80
      break

    case CareerServices.WORKPLACE_CHALLENGES:
      bgColor = '#E6F1F4' // As the color code is not defined in builder colors
      color = '#5A747A'
      break

    default:
      break
  }
  return { bgColor, color }
}
