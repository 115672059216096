import { UseFormReturn } from 'react-hook-form'

import { useI18n } from 'builder/hooks/useI18n'
import { ResumeDistributionPersonalForm } from 'builder/views/ResumeDistributionView/types'
import { createNewLocationSuggestionsApiFetcher } from 'builder/components/FindJob/utils/createNewSuggestionsApiFetcher'

import { TextField } from 'builder/components/TextField'
import * as SimpleForm from 'builder/components/SimpleForm'
import { AsyncAutosuggest } from 'builder/components/AsyncAutosuggest'
import { FullTick } from 'builder/views/ResumeDistributionView/assets/svg/FullTick'

import {
  ContactSupport,
  EmailErrorContainer,
  LabelSpinner,
  PreFilledText,
  PreFillingText,
  SpinnerRight,
  SpinnerRightBar,
} from '../../PersonalDetailsForm/styles'
import FieldSpinnerLoader from '../../FormLayout/FieldSpinnerLoader'
import { validateEmail, validateLinkedinUrl } from '../../PersonalDetailsForm/utils'
import { Container } from './styles'

type Props = {
  form: UseFormReturn<ResumeDistributionPersonalForm>
  isParsing: boolean
  emailIsInUse: boolean
}

const TRANSLATION = 'builder.resume_distribution.landing.form_personal_detail'

export const PersonalDetails = ({ form, isParsing, emailIsInUse }: Props) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = form
  const { i18n } = useI18n()

  const required = i18n.t(`${TRANSLATION}.required_field`)

  return (
    <Container>
      <SimpleForm.Row position="relative">
        <TextField
          {...register('firstName', { required })}
          label={i18n.t(`${TRANSLATION}.first_name`)}
          placeholder="Herman"
          error={errors.firstName?.message}
          autoFocus
        />
        {isParsing && (
          <SpinnerRightBar>
            {isParsing ? (
              <LabelSpinner>
                <FieldSpinnerLoader />
              </LabelSpinner>
            ) : (
              <FullTick />
            )}
            {isParsing ? (
              <PreFillingText>{i18n.t(`${TRANSLATION}.pre_filling`)}...</PreFillingText>
            ) : (
              <PreFilledText>{i18n.t(`${TRANSLATION}.pre_filled`)}</PreFilledText>
            )}
          </SpinnerRightBar>
        )}
      </SimpleForm.Row>

      <SimpleForm.Row position="relative">
        <TextField
          {...register('lastName', { required })}
          label={i18n.t(`${TRANSLATION}.last_name`)}
          placeholder="Walton"
          error={errors.lastName?.message}
        />

        {isParsing && (
          <SpinnerRight>
            <FieldSpinnerLoader />
          </SpinnerRight>
        )}
      </SimpleForm.Row>
      <SimpleForm.Row position="relative">
        <TextField
          {...register('jobTitle', { required })}
          label={i18n.t(`${TRANSLATION}.current_job_title`)}
          placeholder="Software Engineer"
          error={errors.jobTitle?.message}
        />

        {isParsing && (
          <SpinnerRight>
            <FieldSpinnerLoader />
          </SpinnerRight>
        )}
      </SimpleForm.Row>
      <SimpleForm.Row position="relative">
        <AsyncAutosuggest
          {...register('fullAddress', { required })}
          name="fullAddress"
          fetchItems={async (searchString: string) => {
            const result = await createNewLocationSuggestionsApiFetcher(searchString)
            return result.map(item => ({ text: item.formatted }))
          }}
          onChange={event => {
            setValue('fullAddress', event.target.value)
          }}
          value={watch('fullAddress')}
          onSuggestionSelected={(_, selected) => {
            setValue('fullAddress', selected?.suggestion?.text || '')
          }}
        >
          {inputProps => (
            <TextField
              {...inputProps}
              label={i18n.t(`${TRANSLATION}.full_address.label`)}
              placeholder={i18n.t(`${TRANSLATION}.full_address.placeholder`)}
              error={errors.fullAddress?.message}
            />
          )}
        </AsyncAutosuggest>

        {isParsing && (
          <SpinnerRight>
            <FieldSpinnerLoader />
          </SpinnerRight>
        )}
      </SimpleForm.Row>
      <SimpleForm.Row position="relative">
        <TextField
          {...register('email', { required, validate: validateEmail })}
          label={i18n.t(`${TRANSLATION}.email`)}
          placeholder="herman.walton@gmail.com"
          error={
            errors.email?.message && (
              <EmailErrorContainer>
                {errors.email?.message}{' '}
                {emailIsInUse && <ContactSupport href="/contact">Contact Support</ContactSupport>}
              </EmailErrorContainer>
            )
          }
        />

        {isParsing && (
          <SpinnerRight>
            <FieldSpinnerLoader />
          </SpinnerRight>
        )}
      </SimpleForm.Row>
      <SimpleForm.Row position="relative">
        <TextField
          {...register('linkedin', { validate: validateLinkedinUrl })}
          label={i18n.t(`${TRANSLATION}.linkedin`)}
          placeholder="linkedin.com/in/herman.walton"
          error={errors.linkedin?.message}
        />

        {isParsing && (
          <SpinnerRight>
            <FieldSpinnerLoader />
          </SpinnerRight>
        )}
      </SimpleForm.Row>
    </Container>
  )
}
