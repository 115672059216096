import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'

import ModalOverlay from 'builder/components/ModalOverlay'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { Icon20 } from 'builder/components/Icon'
import CreateNewResume from 'builder/components/FillResumeModal/OptionsViewOld/OptionCards/CreateNewResume'
import { ResumeValidationStatus } from 'builder/modules/constants'
import UploadResume from 'builder/views/CareerProfile/ResumeUpload/UploadResume'
import { UploadResume as UploadResumeCard } from 'builder/views/CareerProfile/ResumeUpload/OptionCards'
import { actions } from 'builder/modules/careerProfile'
import { ButtonTheme } from 'builder/components/Button'
import { useI18n } from 'builder/hooks/useI18n'

import { Logo, Title, Container, Close, CardsContainer, BackButton } from './styles'
import LOGO from './assets/logo.png'

interface UploadResumeModalProps {
  onClose: () => void
  goBack: () => void
  eventLabel: 'recommended_jobs' | 'career_profile'
  redirectUrl: string
}

export const UploadResumeModal = ({
  onClose,
  goBack,
  eventLabel,
  redirectUrl,
}: UploadResumeModalProps) => {
  const { isPhone } = useMediaQueries()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { i18n } = useI18n()

  const { resumeValidationStatus } = useTypedSelector(
    state => state.resumeEditor.prefillResumeSection,
  )

  const resumeUploadingNotStarted = resumeValidationStatus === ResumeValidationStatus.notStarted

  return (
    <ModalOverlay
      onClick={onClose}
      overlayFadeDuration={150}
      contentSlideDuration={150}
      fullScreen={isPhone}
    >
      {resumeUploadingNotStarted ? (
        <Container>
          <Close onClick={onClose}>
            <Icon20.Close />
          </Close>
          <Logo src={LOGO} alt="logo" />
          <Title>{i18n.t('builder.job_search.upload_resume_modal.title')}</Title>

          <CardsContainer>
            <UploadResumeCard
              direction="row"
              justifyCenter={false}
              showArrow
              subtitleFixedWidth={373}
              eventLabel={eventLabel}
            />
            <CreateNewResume
              onClose={onClose}
              onClick={() => {
                trackInternalEvent('view_profile_editor', { label: eventLabel })
                navigate(redirectUrl)
              }}
              customTitle={i18n.t(
                'builder.job_search.upload_resume_modal.create_resume_card_title',
              )}
              eventLabel={eventLabel}
            />
          </CardsContainer>

          <BackButton theme={ButtonTheme.ghost} onClick={() => goBack()}>
            {i18n.t('builder.job_search.upload_resume_modal.back')}
          </BackButton>
        </Container>
      ) : (
        <UploadResume
          expanded
          onSubmit={file => {
            trackInternalEvent('view_profile_editor', { label: eventLabel })
            dispatch(actions.parseResumeOnboarding({ file, redirectUrl }))
          }}
        />
      )}
    </ModalOverlay>
  )
}
