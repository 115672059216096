import styled from 'styled-components'
import { Badge } from 'builder/components/Badge'

export const BadgesContainer = styled.div`
  display: flex;
`

export const BadgeStyled = styled(Badge)`
  text-transform: uppercase;
  margin-right: 4px;

  &:last-child {
    margin-right: 0;
  }
`
