import { useCancellationFormContext } from '../../FormSection'
import { PromotionContentV7 } from '../PromotionContentV7'
import { PromotionDescriptionV7 } from '../PromotionDescriptionV7'
import { PromotionTitleV7 } from '../PromotionTitleV7'
import PromotionLinksV7 from '../PromotionLinksV7'

const MobileAndTabletViewV7 = () => {
  const {
    promotionDescription,
    promotionTitle,
    promotionTitleTopic,
    promotionTopic,
    dontNeedPromoSelectedIndex,
  } = useCancellationFormContext()

  return (
    <>
      <PromotionContentV7 topic={promotionTopic}>
        <PromotionLinksV7 />
        <div style={{ maxWidth: '356px', marginBottom: '40px', height: '144px' }}>
          <PromotionTitleV7 topic={promotionTitleTopic}>{promotionTitle}</PromotionTitleV7>
          <PromotionDescriptionV7 topic={promotionTopic}>
            {Array.isArray(promotionDescription)
              ? promotionDescription[dontNeedPromoSelectedIndex]
              : promotionDescription}
          </PromotionDescriptionV7>
        </div>
      </PromotionContentV7>
    </>
  )
}

export default MobileAndTabletViewV7
