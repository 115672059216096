import RichTextArea from 'builder/components/RichTextArea/RichTextArea'
import { SectionHint } from 'builder/components/Section'
import { useWorkExperienceCardContext } from '../../ExpandableCardContext'
import { useShokumuTranslations } from '../../useShokumuTranslations'

const JobDescription = (): JSX.Element => {
  const { item, onCardValueChange } = useWorkExperienceCardContext()
  const { i18n } = useShokumuTranslations()
  const hint = i18n('work_experience.employment_details.description')
  const updateDescription = (value: string) => {
    onCardValueChange({ description: value }, true)
  }

  return (
    <>
      <SectionHint>{hint}</SectionHint>
      <RichTextArea
        boldButtonDisable
        italicButtonDisable
        linkButtonDisable
        value={item.description || ''}
        onChange={updateDescription}
      />
    </>
  )
}

export default JobDescription
