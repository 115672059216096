import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'

export const AddButton = styled.div<{ isDisabled: boolean }>`
  ${Typography.Caption};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  color: ${Colors.Blue50};
  font-weight: 600;
  cursor: pointer;
  outline: none;
  justify-content: center;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 24px;
  pointer-events: ${props => (props.isDisabled ? 'none' : 'auto')};
`

export const AddButtonText = styled.span``

export const IconWrapper = styled.div`
  margin-right: 2px;
`
