import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

export const Wrapper = styled.div`
  margin-top: 32px;
  padding-bottom: 48px;
  border-bottom: 1px solid ${Colors.Neutral15};
`

export const Text = styled.div`
  margin-top: 24px;
  color: ${Colors.Neutral50};

  ${Media.Tablet`
    border-bottom: none;
  `};
`
