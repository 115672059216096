import { createAction, createReducer } from '@reduxjs/toolkit'
import { Store } from 'builder/modules/store'
import { LloydStore } from './types'

// ---
// Action creators
// ---
export const actions = {
  autoLoginCIOUser: createAction<string>('builder/LLOYD_AUTO_LOGIN_CIO_USER'),
  setIsLloydAuthenticated: createAction<boolean>('builder/LLOYD_SET_IS_LLOYD_AUTHENTICATED'),
}

// ---
// Selectors
// ---
export const selectors = {
  isLloydAuthenticated: (state: Store) => state.lloyd.isLloydAuthenticated,
}

// ---
// Reducer
// ---
const initialState: LloydStore = {
  isLloydAuthenticated: false,
}

export const reducer = createReducer(initialState, reducer => {
  reducer.addCase(actions.setIsLloydAuthenticated, (draft, action) => {
    draft.isLloydAuthenticated = action.payload
  })
})
