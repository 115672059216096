import { progressStep } from '../constants'
import { ProgressSteps } from './ProgressSteps'
import { ProgressbarContainer, ProgressWizard } from './styles'

interface ProgressbarProps {
  activatedPage: number[]
}

export const Progressbar = ({ activatedPage }: ProgressbarProps) => {
  const renderProgressStep = () => {
    return progressStep?.map((step: { page: number; title: string }) => {
      return (
        <ProgressSteps key={step.page} {...{ step }} isActive={activatedPage.includes(step.page)} />
      )
    })
  }

  return (
    <ProgressbarContainer>
      <ProgressWizard>{renderProgressStep()}</ProgressWizard>
    </ProgressbarContainer>
  )
}
