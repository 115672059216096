import { HTMLAttributes } from 'react'
import { SubHeader } from './styles'

type ListItemSubTextProps = {
  text: string
} & HTMLAttributes<HTMLDivElement>

export const ListItemSubText = ({ text, ...rest }: ListItemSubTextProps) => {
  return <SubHeader {...rest}>{text}</SubHeader>
}
