import { useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import topResumeVisualFooter from 'images/builder/resume-editor/poweredByTopResume.svg'
import topResumeVisual from './assets/topResume-visual.png'
import * as Styled from './styles'

import resumeImage from './assets/instantResume.png'
import plusIcon from './assets/plusIcon.svg'
import { ExternalURL } from './types'
import { objectToQueryParamsString } from './constants'

interface Props {
  isTopResumeCard: boolean
}

const CreateResumeInstantly = ({ isTopResumeCard }: Props) => {
  const { isPhone } = useMediaQueries()
  const navigate = useNavigate()
  const [urlParams] = useSearchParams()
  const location = useLocation()
  const isMyStoryURL = location.pathname.includes('mystory')

  const [isHovered, setIsHovered] = useState<boolean>(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const handleViewProfile = () => {
    if (isTopResumeCard) {
      trackInternalEvent('ulp_cta_click', { label: 'topresume' })

      const topResumePromotionURL = `${ExternalURL.TOP_RESUME_EXPERT_HELP}${
        ExternalURL.TOP_RESUME_EXPERT_HELP?.includes('?') ? '&' : '?'
      }${objectToQueryParamsString({
        utm_content: urlParams.get('utm_content'),
        utm_source: urlParams.get('utm_source'),
        utm_medium: urlParams.get('utm_medium'),
        utm_campaign: urlParams.get('utm_campaign'),
        utm_term: urlParams.get('utm_term'),
      })}`
      window.open(topResumePromotionURL, '_blank')
    } else {
      trackInternalEvent('ulp_cta_click', { label: 'create_resume' })
      navigate(`${isMyStoryURL ? '/app' : ''}/create-resume/templates`)
    }
  }

  return (
    <Styled.Container
      isTopResumeCard={isTopResumeCard}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleViewProfile}
    >
      <Styled.Title>
        {isTopResumeCard ? 'Let us create it for you' : 'Create a resume instantly'}
      </Styled.Title>
      <Styled.Text>
        {isTopResumeCard
          ? 'Work with expert resume writers to create a professional resume'
          : 'An easy to use resume builder with proven templates for any job'}
      </Styled.Text>
      <Styled.BuildMyResumeButton isTopResumeCard={isTopResumeCard}>
        {isTopResumeCard ? 'Get a Professional Resume' : 'Build My Resume'}
        {isPhone && <Styled.RightArrow isTopResumeCard={isTopResumeCard} />}
        {isHovered && <Styled.RightArrow isTopResumeCard={isTopResumeCard} />}
      </Styled.BuildMyResumeButton>
      <Styled.DummyResume
        isTopResumeCard={isTopResumeCard}
        src={isTopResumeCard ? topResumeVisual : resumeImage}
      />
      {!isTopResumeCard && <Styled.PlusIcon src={plusIcon} />}
      {isTopResumeCard && (
        <Styled.TopResumeVisualFooterWrapperContainer src={topResumeVisualFooter} />
      )}
    </Styled.Container>
  )
}

CreateResumeInstantly.defaultProps = {
  isTopResumeCard: false,
}

export default CreateResumeInstantly
