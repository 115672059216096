import { useI18n } from 'builder/hooks/useI18n'
import ArrowTarget from './ArrowTarget.svg'
import ArrowTargetDisabled from './ArrowTargetDisabled.svg'

import { TailoredIcon, TailoredNew, TailoredTitle, TailoredWrapper } from './styles'

interface Props {
  disabled?: boolean
  onClick: (e: React.MouseEvent) => void
}

export const TailorToResume = (props: Props) => {
  const { i18n } = useI18n()
  return (
    <TailoredWrapper
      disabled={props.disabled}
      onClick={!props.disabled ? props.onClick : undefined}
    >
      <TailoredIcon src={props.disabled ? ArrowTargetDisabled : ArrowTarget} />
      <TailoredTitle>{i18n.t('builder.resume_optimizer.pill.title')}</TailoredTitle>
      <TailoredNew>NEW</TailoredNew>
    </TailoredWrapper>
  )
}
