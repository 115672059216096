import Button from 'builder/components/Button'

import { ButtonContainer } from '../../styles'

type Props = {
  handleNextStep: () => void
}

const Footer = ({ handleNextStep }: Props) => {
  return (
    <ButtonContainer>
      <Button onClick={handleNextStep}>Continue</Button>
    </ButtonContainer>
  )
}

export default Footer
