import { FC, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { actions, PaywallModalType } from 'builder/modules/careerProfile'
import { useUser } from 'builder/hooks/useUser'

export const PaywallGuard: FC<{ type: PaywallModalType }> = ({ type, children }) => {
  const user = useUser()
  const dispatch = useDispatch()
  const isPremiumUser = user?.hasPremiumFeatures

  useEffect(() => {
    if (!isPremiumUser) {
      dispatch(actions.setOpenedPaywallModalType(type))
    }
  }, [isPremiumUser, dispatch, type])

  return isPremiumUser ? <>{children}</> : <Navigate to="/career-profile/insights" replace />
}
