import { Icon24 } from 'builder/components/Icon'
import * as Styled from './styles'

type Props = {
  userPhotoUrl: string
}

export const InterviewPrepVisual = ({ userPhotoUrl }: Props) => {
  return (
    <>
      <Styled.Photo alt="User photo" src={userPhotoUrl} />
      <Styled.IconCircle>
        <Icon24.Play />
      </Styled.IconCircle>
    </>
  )
}
