import { Fragment, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Colors } from 'builder/styles/colors'
import { capitalize } from 'builder/utils/capitalize'
import { useI18n } from 'builder/hooks/useI18n'
import { Icon20 } from 'builder/components/Icon'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as panelSelectors } from 'builder/modules/panel'
import { selectors as critiqueSelectors } from 'builder/modules/resumeCritiqueReview'
import { CareercardLoaderTooltip } from '../LoaderTooltip/CareerCardLoaderTooltip'
import CritiqueReviewLoader from '../Panel/CIOCritiqueReview/CritiqueReviewLoader'
import { CareerTask } from './types'
import * as Styled from './styles'

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  careerTask: CareerTask
  interactive?: boolean
  isLoading?: boolean
  resourceCard?: boolean
  isResumeCard?: boolean
}

export const CareerGoalCard = ({
  careerTask,
  interactive = true,
  isLoading,
  isResumeCard,
  resourceCard,
  onClick,
  ...rest
}: Props) => {
  const { visual, title, text, action, completed } = careerTask
  const tone = capitalize(careerTask.tone)
  const clickable = (interactive && !completed) || !!resourceCard
  const { i18n } = useI18n()
  const bgColor = Colors[`${tone}${10}`]
  const location = useLocation()
  const { status, resumeId: critiqueResumeID } = useTypedSelector(
    critiqueSelectors.triggeredCritiqueStatus,
  )

  const style = {
    '--color-tone-10': bgColor,
    '--color-tone-20': Colors[`${tone}${20}`],
    '--color-tone-50': Colors[`${tone}${50}`],
    '--color-tone-80': Colors[`${tone}${80}`],
  } as React.CSSProperties

  const queryParams = new URLSearchParams(location.search)
  const key = queryParams.get('tr_promo_banner')

  const critiqueQueryParams = new URLSearchParams(location.search)
  const critiqueKey = critiqueQueryParams.get('critique_review') === 'critique'

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) onClick(event)
    if (interactive && !isLoading) action()
  }

  const [isHovering, setIsHovering] = useState(false)
  const [tunerResumeId, setTunerResumeId] = useState<number | null>(null)

  const resumeID = useTypedSelector(panelSelectors.tunerResumeId)
  const isCritiqueLoading = status !== null && critiqueResumeID === resumeID && isResumeCard

  useEffect(() => {
    const timer = setTimeout(() => {
      setTunerResumeId(resumeID)
    }, 15000)
    return () => clearTimeout(timer)
  }, [resumeID])

  const resumePrefilledStatus = !tunerResumeId && critiqueKey && isResumeCard && resumeID === null

  const handleMouseOver = () => {
    setIsHovering(true)
  }

  const handleMouseOut = () => {
    setIsHovering(false)
  }

  const handleTouchEvent = () => {
    setIsHovering(true)
  }
  const handleTouchCloseEvent = () => {
    setIsHovering(false)
  }

  return (
    <>
      <Styled.CardContainer
        completed={completed}
        clickable={clickable}
        resourceCard={resourceCard}
        style={style}
        onClick={handleClick}
        tabIndex={clickable ? undefined : -1}
        {...rest}
      >
        {resourceCard ? (
          <Styled.ResourceCardVisualWrapper>{visual}</Styled.ResourceCardVisualWrapper>
        ) : (
          <Styled.CardVisualWrapper>
            {visual}
            <Styled.CardVisualGradient bgColor={bgColor} />
          </Styled.CardVisualWrapper>
        )}

        <Styled.CardContent>
          {isCritiqueLoading && <CritiqueReviewLoader isGoalCardSection={true} />}
          <Styled.CardTitle>{title}</Styled.CardTitle>
          <Styled.CardText>{text}</Styled.CardText>
          {!resourceCard && (
            <Fragment>
              {(isLoading && key !== null) || resumePrefilledStatus ? (
                <Styled.LoaderContainer
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                  onTouchStart={handleTouchEvent}
                  onTouchCancel={handleTouchCloseEvent}
                >
                  <Styled.SpinnerContainer>
                    <Styled.Spinner />
                  </Styled.SpinnerContainer>
                  <Styled.PrefillText>
                    {i18n.t('builder.panel.loader.prefill_loader_text')}
                  </Styled.PrefillText>
                </Styled.LoaderContainer>
              ) : (
                <Styled.CardButton>
                  {completed ? (
                    <Icon20.Tick />
                  ) : (
                    <Icon20.ChevronDown style={{ transform: 'rotate(-90deg)' }} />
                  )}
                </Styled.CardButton>
              )}
            </Fragment>
          )}
        </Styled.CardContent>
        <>{isHovering && <CareercardLoaderTooltip />}</>
      </Styled.CardContainer>
    </>
  )
}
