import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'

export const AceJobContainer = styled.div`
  max-width: 284px;
  width: 100%;
  flex: 0 0 284px;

  ${Media.Phone`
    flex:0 0 264px;   
    margin-bottom:24px;
    max-height:200px;
    height: 100%;
  `};
`
export const AceJobUserImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  height: 100%;

  ${Media.Phone`
    padding-top: 45px;
  `};
`
export const AceJobImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  padding: 2px;
  background: ${Colors.White};
`

export const AceJobPlayImageContainer = styled.div`
  position: absolute;
  left: 54px;
  bottom: 10px;
  backdrop-filter: blur(4.10271px);
  box-shadow: 0px 16.4109px 30.7703px rgba(51, 157, 93, 0.12);
  border-radius: 50%;

  ${Media.Phone`
    left: 54px;
  `};
`
export const AceJobPlayImage = styled.img`
  height: 50px;
  width: 50px;
`
