import { StoryList } from 'builder/modules/sevenStories'
import { AnswerText, QuestionWrapper, RectangleStoriesContainer } from './styles'

type Props = {
  story: string[]
}

const StoriesList = ({ story }: Props) => {
  return (
    <div>
      <RectangleStoriesContainer />
      {StoryList.map((stories, index) => (
        <QuestionWrapper key={index}>
          <div>{stories.label}</div>
          <AnswerText>{story?.[index]}</AnswerText>
        </QuestionWrapper>
      ))}
    </div>
  )
}

export default StoriesList
