import { MouseEvent } from 'react'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { AdditionalButtons } from 'builder/components/Navbar/components/AdditionalButtons'
import { AvatarNavigation } from 'builder/components/AvatarNavigation'
import { UserData } from 'builder/modules/user'
import { useI18n } from 'builder/hooks/useI18n'
import { SuperAppPromotionType } from 'builder/modules/init'
import { useConfig } from 'builder/hooks/useConfig'

import { billingPlanPageURL, goToBillingPlanPage } from 'builder/utils/goToBillingPlanPage'
import { PrimaryLinks } from '../../types'
import {
  DesktopMenuContainer,
  Divider,
  Links,
  MainPart,
  MenuLink,
  MenuLinkWrapper,
  RightContainer,
  UpgradeButtonContainer,
  UpgradeButtonIcon,
} from './styles'

interface DesktopNavbarProps {
  user: UserData
  primaryLinks: PrimaryLinks
  isDashboardOpen: boolean
  onPrimaryLinkClick: (event: MouseEvent<HTMLAnchorElement>) => void
}

export const DesktopNavbar = ({
  user,
  primaryLinks,
  isDashboardOpen,
  onPrimaryLinkClick,
}: DesktopNavbarProps) => {
  const { i18n } = useI18n()
  const config = useConfig()
  const superAppPromotion = config?.features.superAppPromotionType
  const { isTablet } = useMediaQueries()
  const hasActivatedSuperApp = typeof user?.careerId === 'string'
  const isActivatedPremiumUser = hasActivatedSuperApp && user?.hasPremiumFeatures
  const areLinksVisible =
    superAppPromotion !== SuperAppPromotionType.new && !isTablet && !isActivatedPremiumUser

  return (
    <DesktopMenuContainer>
      <MainPart>
        {areLinksVisible ? (
          <Links>
            {user.billingStatus === 'free' && user.visitedPlans && (
              <UpgradeButtonContainer
                onClick={() => {
                  if (user) {
                    goToBillingPlanPage({ upgradeURL: user?.billingInfo?.upgradeUrl })
                  }
                }}
                href={
                  user ? billingPlanPageURL({ upgradeURL: user.billingInfo.upgradeUrl }) : undefined
                }
              >
                <UpgradeButtonIcon />
                {i18n.t('builder.navbar.upgrade_now_button')}
              </UpgradeButtonContainer>
            )}

            {primaryLinks.map(({ name, isActive, ...link }) => {
              return (
                <MenuLinkWrapper key={name}>
                  <MenuLink
                    $isActive={!!isActive}
                    data-name={name}
                    onClick={onPrimaryLinkClick}
                    {...link}
                  />
                </MenuLinkWrapper>
              )
            })}
          </Links>
        ) : (
          <AdditionalButtons user={user} hasActivatedSuperApp={hasActivatedSuperApp} />
        )}
      </MainPart>

      <RightContainer>
        {areLinksVisible && <Divider />}
        <AvatarNavigation hasFeatures={!isDashboardOpen && !areLinksVisible} />
      </RightContainer>
    </DesktopMenuContainer>
  )
}
