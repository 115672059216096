import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as userSelector } from 'builder/modules/user'
import { selectors } from 'builder/modules/interview/interviewModule'
import { QuestionTemplate } from 'builder/modules/interview/types'
import { useInfiniteScrollQuestionTemplate } from 'builder/views/Interview/hooks/useInfiniteScrollQuestionTemplate'
import { AvailablePremiumBanner } from '../../AvailablePremiumBanner/AvailablePremiumBanner'
import { PracticeThisQuestionModal } from '../../DashboardView/Modals/PracticeThisModal/PracticeThisQuestionModal'
import { InitialRequest } from '../../DashboardView/DashboardView'
import { QuestionCard } from '../QuestionCard/QuestionCard'
import {
  Header,
  Interrogation,
  QuestionCardContainer,
  QuestionLibraryButton,
  Title,
  TitleContainer,
  CardSkeleton,
  DesktopContainer,
  MobileContainer,
} from './style'
const TRANSLATION_KEY = 'builder.interview_prep_view.interview_dashboard'

interface Props {
  setInitialRequest: React.Dispatch<React.SetStateAction<InitialRequest | undefined>>
}

export const QuestionLibrarySection = ({ setInitialRequest }: Props) => {
  const jobTitleId = useSelector(selectors.jobTitleId)
  const questionTemplate = useInfiniteScrollQuestionTemplate({
    jobTitleId: jobTitleId,
    perPage: 3,
    total: 3,
    setInitialRequest,
    enabledPerformanceMetric: true,
  })
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const isPremiumUser = useTypedSelector(userSelector.premiumAccess)
  const [selectedQuestion, setSelectedQuestion] = useState<QuestionTemplate>()
  const questions = (questionTemplate?.data?.pages || []).flatMap(page => page.items)

  return (
    <div>
      {!isPremiumUser && (
        <MobileContainer>
          <AvailablePremiumBanner />
        </MobileContainer>
      )}
      <Header>
        <TitleContainer>
          <Title>{i18n.t(`${TRANSLATION_KEY}.title`)}</Title>
          {!isPremiumUser && (
            <DesktopContainer>
              <AvailablePremiumBanner />
            </DesktopContainer>
          )}
        </TitleContainer>
        <QuestionLibraryButton
          onClick={() => {
            trackInternalEvent('click_questions_library_button', {
              label: 'interview_prep',
            })
            navigate('/interview-preparation/questions-library')
          }}
        >
          {i18n.t(`${TRANSLATION_KEY}.questions_library`)}
          <Interrogation>?</Interrogation>
        </QuestionLibraryButton>
      </Header>
      <QuestionCardContainer>
        {questions.length === 0 && [0, 1, 2].map(i => <CardSkeleton key={i} />)}
        {questions.map(question => (
          <QuestionCard
            key={question.id}
            question={question}
            type="question"
            onClick={() => {
              setSelectedQuestion(question)
              trackInternalEvent('pick_interview', {
                label: 'interview_prep',
                parameter: 'interview_dash_questions',
              })
            }}
          />
        ))}
      </QuestionCardContainer>
      {selectedQuestion && (
        <PracticeThisQuestionModal
          trackingParameter="interview_dash_questions"
          question={selectedQuestion}
          onClose={() => setSelectedQuestion(undefined)}
        />
      )}
    </div>
  )
}
