import React, { useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { useTypedSelector } from 'builder/hooks/useTypedSelector'

import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { selectors as careerProfileSelectors } from 'builder/modules/careerProfile'

import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { selectors as jobSearchSelectors } from 'builder/modules/jobSearch'
import { actions as renderingActions } from 'builder/modules/rendering'

import Tabs, { Tab } from '../Tabs'
import SearchBar from './SearchBar'

import {
  Count,
  CustomTab,
  FindJobContainer,
  ManagerJobAlertButtonContainer,
  PageActionsContainer,
  TabContainer,
} from './styles'
import {
  JobSearchProvider,
  RecommendationsSearchStatus,
  SearchStatus,
  useJobSearch,
} from './useJobSearch'

import { JobSearchResult } from './JobSearchResult'
import { PageActionButtons } from './PageActionButtons/PageActionButtons'
import { usePrefillQuery } from './hooks/usePrefillQuery'
import { usePrefillLocation } from './hooks/usePrefillLocation'
import { RecommendationsRequiredAttributes, TabType } from './types'
import { Loading } from './Loading/Loading'
import { NoTargetRole } from './RecommendationsErrors/NoTargetRole'
import { NoTargetLocation } from './RecommendationsErrors/NoTargetLocation'
import { NoWorkExperience } from './RecommendationsErrors/NoWorkExperience'
import { MultipleErrors } from './RecommendationsErrors/MultipleErrors'
import { NoTargetRoleAndNoLocation } from './RecommendationsErrors/NoTargetRoleAndNoLocation'
import { NoTargetRoleAndNoWorkExperience } from './RecommendationsErrors/NoTargetRoleAndNoWorkExperience'
import { NoTargetLocationAndNoWorkExperience } from './RecommendationsErrors/NoTargetLocationAndNoWorkExperience'

import { JoyRidesJobSearchTrackingSteps } from './JoyRidesJobSearch/JoyRidesJobSearchTrackingSteps'
import { AlertModal } from './AlertModal/AlertModal'
import JobSearchNotification from './JobItem/JobSearchNotification'
import { ManagerJobAlertButton } from './AlertModal/ManageJobAlertButton/ManageJobAlertButton'

interface Tab {
  labelTab: React.ReactElement
  id: TabType
  content: React.ReactElement
  joyRideId?: string
}

const FindJob = () => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const [queryParams] = useSearchParams()
  const { features } = useFeaturesConfig()
  const recommendationsMissingAttributes = useTypedSelector(
    careerProfileSelectors.recommendationsMissingAttributes,
  )
  const { isPhone } = useMediaQueries()
  const enableJobAlertFeature = useTypedSelector(jobSearchSelectors.enableJobAlertFeature)

  const {
    selectedTabId,
    handleChangeSelectedTabId,
    jobSearchResults,
    similarResults,
    similarJobsPageNumber,
    similarJobsStats,
    setSimilarJobsPageNumber,
    scrollableSearchBarType,
    updateSearchParams,
    setRecommendationPage,
    recommendationPage,
    searchStatus,
    recommendations,
    recommendationStatus,
    setActiveJob,
    activeJob,
    recommendActiveJob,
    setRecommendActiveJob,
    jobSearchStats,
    recommendationStats,
    page,
    correctedTerm,
    lastSearchedTerm,
    term,
    timeInterval,
    nearMeLocation,
    locationText,
    selectedLocation,
    setScrollableSearchBarType,
    setTerm,
    setTimeInterval,
    setLocationText,
    setSelectedLocation,
    locationPrefillCompleted,
  } = useJobSearch()

  const prefillLocation = usePrefillLocation()
  const prefillQuery = usePrefillQuery()
  const tabs: Tab[] = useMemo(
    () => [
      {
        labelTab: (
          <>
            {i18n.t(`builder.job_search.recommended_jobs`)}
            {recommendationStats && recommendationStats?.total_jobs > 0 && (
              <Count>&nbsp;({recommendationStats?.total_jobs})</Count>
            )}
          </>
        ),
        id: TabType.recommendation,
        joyRideId: 'recommendation-tab',
        content: (
          <JobSearchResult
            results={recommendations}
            isSearching={recommendationStatus === RecommendationsSearchStatus.searching}
            isLoading={[
              RecommendationsSearchStatus.searching,
              RecommendationsSearchStatus.loadingPage,
            ].includes(recommendationStatus)}
            isNoResults={
              recommendationStatus === RecommendationsSearchStatus.loaded &&
              recommendations.length === 0
            }
            activeJob={recommendActiveJob}
            stats={recommendationStats}
            setActiveJob={setRecommendActiveJob}
            loadNextPage={() => {
              setRecommendationPage((recommendationPage || 1) + 1)
            }}
          />
        ),
      },
      {
        labelTab: (
          <>
            {i18n.t(`builder.job_search.search`)}
            {jobSearchStats && jobSearchStats?.total_jobs > 0 && (
              <Count>&nbsp;({jobSearchStats?.total_jobs})</Count>
            )}
          </>
        ),
        id: TabType.search,
        content: (
          <JobSearchResult
            searchTerm={lastSearchedTerm}
            correctedTerm={correctedTerm}
            results={jobSearchResults}
            isSearching={
              searchStatus === SearchStatus.searching ||
              (searchStatus === SearchStatus.loaded &&
                jobSearchResults.length === 0 &&
                lastSearchedTerm !== '' &&
                !similarJobsStats)
            }
            similarResults={similarResults}
            isLoading={[SearchStatus.searching, SearchStatus.loadingPage].includes(searchStatus)}
            isNoResults={
              searchStatus === SearchStatus.loaded &&
              jobSearchResults.length === 0 &&
              similarResults.length === 0 &&
              (lastSearchedTerm === '' || similarJobsStats?.total_jobs === 0)
            }
            activeJob={activeJob}
            setActiveJob={setActiveJob}
            stats={jobSearchStats}
            similarJobsStats={similarJobsStats}
            loadNextSimilarJobsPage={() => {
              setSimilarJobsPageNumber(similarJobsPageNumber + 1)
            }}
            loadNextPage={() => {
              updateSearchParams({
                location: prefillLocation?.prefillValue,
                page: String((page ? parseInt(page) : 1) + 1),
              })
            }}
          />
        ),
      },
    ],
    [
      activeJob,
      i18n,
      jobSearchResults,
      jobSearchStats,
      page,
      recommendActiveJob,
      recommendationStats,
      recommendationStatus,
      recommendations,
      searchStatus,
      setActiveJob,
      setRecommendActiveJob,
      setRecommendationPage,
      updateSearchParams,
      similarJobsPageNumber,
      similarResults,
      similarJobsStats,
      correctedTerm,
      lastSearchedTerm,
    ],
  )

  const selectedTabContent = useMemo(
    () => tabs.find(tab => tab.id === selectedTabId)?.content,
    [tabs, selectedTabId],
  )

  useEffect(() => {
    if (queryParams.get('show_credit_modal')) {
      dispatch(renderingActions.showRDGotCreditsModal())
    }
  }, [])

  useEffect(() => {
    if (locationPrefillCompleted) {
      updateSearchParams({
        location: prefillLocation.prefillValue,
        term: prefillQuery.prefillValue,
        ignoreSelectedTabId: true,
      })
      setRecommendationPage(1)
    }
  }, [locationPrefillCompleted])

  let RecommendationsError = null
  if (selectedTabId === TabType.recommendation) {
    const hasTargetRole = !recommendationsMissingAttributes.includes(
      RecommendationsRequiredAttributes.TargetRole,
    )
    const hasTargetLocation = !recommendationsMissingAttributes.includes(
      RecommendationsRequiredAttributes.TargetLocation,
    )
    const hasWorkExperience =
      !recommendationsMissingAttributes.includes(
        RecommendationsRequiredAttributes.WorkExperience,
      ) &&
      !recommendationsMissingAttributes.includes(
        RecommendationsRequiredAttributes.WorkExperienceWithDetails,
      )

    if (!hasTargetRole && hasTargetLocation && hasWorkExperience) {
      RecommendationsError = <NoTargetRole />
    } else if (hasTargetRole && !hasTargetLocation && hasWorkExperience) {
      RecommendationsError = <NoTargetLocation />
    } else if (hasTargetRole && hasTargetLocation && !hasWorkExperience) {
      RecommendationsError = <NoWorkExperience />
    } else if (!hasTargetRole && !hasTargetLocation && hasWorkExperience) {
      RecommendationsError = <NoTargetRoleAndNoLocation />
    } else if (!hasTargetRole && hasTargetLocation && !hasWorkExperience) {
      RecommendationsError = <NoTargetRoleAndNoWorkExperience />
    } else if (hasTargetRole && !hasTargetLocation && !hasWorkExperience) {
      RecommendationsError = <NoTargetLocationAndNoWorkExperience />
    }

    if (!RecommendationsError && (!hasTargetLocation || !hasTargetRole || !hasWorkExperience)) {
      RecommendationsError = <MultipleErrors />
    }
  }

  if (!locationPrefillCompleted || !prefillQuery.isDonePrefill) {
    return <Loading />
  }
  const showBlackValues = selectedTabId === TabType.recommendation
  return (
    <>
      <AlertModal selectedTab={selectedTabId} />
      <JoyRidesJobSearchTrackingSteps
        handleChangeSelectedTabId={handleChangeSelectedTabId}
        recommendations={recommendations}
        searchResults={jobSearchResults}
        searchResultsIsLoading={[SearchStatus.searching, SearchStatus.loadingPage].includes(
          searchStatus,
        )}
        recommendationIsLoading={[
          RecommendationsSearchStatus.searching,
          RecommendationsSearchStatus.loadingPage,
        ].includes(recommendationStatus)}
        setRecommendActiveJob={setRecommendActiveJob}
        setSearchActiveJob={setActiveJob}
      />

      <FindJobContainer $dashboardV2={features.superApp || features.international}>
        <SearchBar
          searchBarType="fixed"
          showBlackValues={showBlackValues}
          term={term}
          timeInterval={timeInterval}
          nearMeLocation={nearMeLocation}
          locationText={locationText}
          selectedLocation={selectedLocation}
          setScrollableSearchBarType={setScrollableSearchBarType}
          handleChangeSelectedTabId={handleChangeSelectedTabId}
          updateSearchParams={updateSearchParams}
          setTerm={setTerm}
          setTimeInterval={setTimeInterval}
          setLocationText={setLocationText}
          setSelectedLocation={setSelectedLocation}
          selectedTabId={selectedTabId}
        />

        {selectedTabId === TabType.search && scrollableSearchBarType && (
          <SearchBar
            showBlackValues={showBlackValues}
            searchBarType={scrollableSearchBarType}
            term={term}
            timeInterval={timeInterval}
            nearMeLocation={nearMeLocation}
            locationText={locationText}
            selectedLocation={selectedLocation}
            setScrollableSearchBarType={setScrollableSearchBarType}
            handleChangeSelectedTabId={handleChangeSelectedTabId}
            updateSearchParams={updateSearchParams}
            setTerm={setTerm}
            setTimeInterval={setTimeInterval}
            setLocationText={setLocationText}
            setSelectedLocation={setSelectedLocation}
            selectedTabId={selectedTabId}
          />
        )}
        <TabContainer>
          <Tabs>
            {tabs.map(tab => (
              <CustomTab
                key={tab.id}
                isActive={tab.id === selectedTabId}
                onClick={() => handleChangeSelectedTabId(tab.id)}
              >
                <div id={tab.joyRideId}>{tab.labelTab}</div>
              </CustomTab>
            ))}
          </Tabs>
          <PageActionsContainer> {!isPhone && <PageActionButtons />}</PageActionsContainer>
        </TabContainer>
        {RecommendationsError ? (
          <>
            {enableJobAlertFeature && (
              <ManagerJobAlertButtonContainer>
                <ManagerJobAlertButton />
              </ManagerJobAlertButtonContainer>
            )}
            {RecommendationsError}
          </>
        ) : (
          selectedTabContent
        )}

        <JobSearchNotification />
      </FindJobContainer>
    </>
  )
}

const Wrapper: React.FC = () => (
  <JobSearchProvider>
    <FindJob />
  </JobSearchProvider>
)

export default Wrapper
