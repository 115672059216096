import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Badge } from 'builder/components/Badge'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.White};
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  max-width: 544px;
`

export const Close = styled.div`
  z-index: 1;
  position: absolute;
  top: 32px;
  right: 32px;
  color: ${Colors.Neutral50};
  background-color: ${Colors.White};
  display: flex;
  border-radius: 100%;
  align-items: center;
  width: 28px;
  height: 28px;
  justify-content: center;
  cursor: pointer;

  &:hover {
    color: ${Colors.Blue50};
    background-color: ${Colors.Blue10};
  }
`
export const ImgContainer = styled.div`
  width: 100%;
  height: 280px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.Neutral5};
  position: relative;
`

export const Img = styled.img`
  width: 176.467px;
  height: 176.467px;
  flex-shrink: 0;
`

export const PlayIconContainer = styled.div`
  position: absolute;
  width: 57.23px;
  height: 57.23px;
  background-color: #d1e9db;
  color: ${Colors.Green90};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 56px;
  margin-right: 132px;
`

export const BodyContainer = styled.div`
  padding: 32px;
`

export const Title = styled.div`
  padding-top: 12px;
  ${Typography.S};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral90};
`

export const Text = styled.div`
  padding-top: 8px;
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
`

export const ButtonContainer = styled.div`
  padding-top: 24px;
  display: flex;
  justify-content: end;
  align-items: baseline;
  gap: 8px;
`

export const CustomBadge = styled(Badge)`
  display: inline-flex;

  align-items: center;
`
