import times from 'lodash/times'
import { useDispatch } from 'react-redux'
import { Fragment, useRef, useLayoutEffect, useCallback } from 'react'

import SwiperCore, { Scrollbar } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { actions, selectors, LEVELS } from 'builder/modules/careerPath2'

import JobLevel from './components/JobLevel'
import { Level } from './components/Connections'
import CurrentLevel from './components/CurrentLevel'
import { Container, TabletContainer, ScrollbarContainer } from './styles'

SwiperCore.use([Scrollbar])

const NUMBER_JOB_LEVELS = 3
const CONTAINER_SIZE_IN_PX = 1894

const JobTitles = () => {
  const dispatch = useDispatch()
  const tabletRef = useRef<HTMLDivElement | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const currentLevel = useTypedSelector(selectors.currentLevel)
  const isSwiperVisible = useTypedSelector(selectors.isSwiperVisible)
  const levelOneJobTitles = useTypedSelector(selectors.level1JobTitles)
  const levelTwoJobTitles = useTypedSelector(selectors.level2JobTitles)
  const levelThreeJobTitles = useTypedSelector(selectors.level3JobTitles)
  const level1JobTitleIndex = useTypedSelector(selectors.level1JobTitleIndex)
  const level2JobTitleIndex = useTypedSelector(selectors.level2JobTitleIndex)
  const level3JobTitleIndex = useTypedSelector(selectors.level3JobTitleIndex)
  const selectedLevel1JobTitleIndex = useTypedSelector(selectors.selectedLevel1JobTitleIndex)
  const selectedLevel2JobTitleIndex = useTypedSelector(selectors.selectedLevel2JobTitleIndex)
  const selectedLevel3JobTitleIndex = useTypedSelector(selectors.selectedLevel3JobTitleIndex)

  const checkElementRender = useCallback(() => {
    if (window.innerWidth < CONTAINER_SIZE_IN_PX) {
      dispatch(actions.setIsSwiperVisible(true))
    } else {
      dispatch(actions.setIsSwiperVisible(false))
    }
  }, [dispatch])

  useLayoutEffect(() => {
    checkElementRender()
  }, [checkElementRender])

  useLayoutEffect(() => {
    window.addEventListener('load', checkElementRender)
    window.addEventListener('resize', checkElementRender)

    return () => {
      window.removeEventListener('load', checkElementRender)
      window.removeEventListener('resize', checkElementRender)
    }
  }, [checkElementRender])

  const getLevel = (level: number) => {
    switch (level) {
      case 1:
        return LEVELS.TWO
      case 2:
        return LEVELS.THREE
      default:
        return LEVELS.ONE
    }
  }

  const getJobTitles = (level: number) => {
    switch (level) {
      case 1:
        return levelTwoJobTitles
      case 2:
        return levelThreeJobTitles
      default:
        return levelOneJobTitles
    }
  }

  if (isSwiperVisible) {
    return (
      <TabletContainer ref={tabletRef}>
        <Swiper
          freeMode
          grabCursor
          slideToClickedSlide
          slidesPerView="auto"
          watchSlidesVisibility={true}
          freeModeMomentumBounce={false}
          mousewheel={{ forceToAxis: true }}
          scrollbar={{ el: '.career-matrix-scrollbar' }}
        >
          <SwiperSlide>
            <CurrentLevel />
          </SwiperSlide>
          {times(NUMBER_JOB_LEVELS).map(level => (
            <SwiperSlide key={level}>
              <Level
                level={getLevel(level)}
                currentlevel={currentLevel}
                level1JobTitleIndex={level1JobTitleIndex}
                level2JobTitleIndex={level2JobTitleIndex}
                level3JobTitleIndex={level3JobTitleIndex}
                selectedLevel1JobTitleIndex={selectedLevel1JobTitleIndex}
                selectedLevel2JobTitleIndex={selectedLevel2JobTitleIndex}
                selectedLevel3JobTitleIndex={selectedLevel3JobTitleIndex}
              />
              <JobLevel titles={getJobTitles(level)} level={getLevel(level)} />
            </SwiperSlide>
          ))}
        </Swiper>
        <ScrollbarContainer>
          <div className="career-matrix-scrollbar" />
        </ScrollbarContainer>
      </TabletContainer>
    )
  }

  return (
    <Container ref={containerRef}>
      <CurrentLevel />
      {times(NUMBER_JOB_LEVELS).map(level => (
        <Fragment key={level}>
          <Level
            level={getLevel(level)}
            currentlevel={currentLevel}
            level1JobTitleIndex={level1JobTitleIndex}
            level2JobTitleIndex={level2JobTitleIndex}
            level3JobTitleIndex={level3JobTitleIndex}
            selectedLevel1JobTitleIndex={selectedLevel1JobTitleIndex}
            selectedLevel2JobTitleIndex={selectedLevel2JobTitleIndex}
            selectedLevel3JobTitleIndex={selectedLevel3JobTitleIndex}
          />
          <JobLevel titles={getJobTitles(level)} level={getLevel(level)} />
        </Fragment>
      ))}
    </Container>
  )
}

export default JobTitles
