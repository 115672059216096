import { useEffect, useState } from 'react'
import {
  MAX_QUESTION_TIME_DURATION_IN_SECONDS,
  SHOW_DIALOG_RUNNING_OUT_TIME_IN_SECONDS,
} from 'builder/views/Interview/constants'

export function useTimer(onRunningOutTime: () => void, isRecording: boolean) {
  const [running, setRunning] = useState(false)
  const [seconds, setSeconds] = useState(0)
  const [showTimeTickingModal, setShowTimeTickingModal] = useState(false)
  const [showTimeUpDialog, setShowTimeUpDialog] = useState(false)

  useEffect(() => {
    if (isRecording) {
      setShowTimeUpDialog(false)
      setSeconds(MAX_QUESTION_TIME_DURATION_IN_SECONDS)
      setRunning(true)
      setShowTimeTickingModal(false)
      return
    }
    setRunning(false)
    setSeconds(MAX_QUESTION_TIME_DURATION_IN_SECONDS)
  }, [isRecording])

  useEffect(() => {
    let setIntervalId: NodeJS.Timeout

    if (running) {
      setIntervalId = setInterval(() => {
        setSeconds(s => {
          const newValue = s - 1
          if (newValue === 0) {
            setRunning(false)
            onRunningOutTime()
            setShowTimeTickingModal(false)
            setShowTimeUpDialog(true)
          }
          if (newValue === SHOW_DIALOG_RUNNING_OUT_TIME_IN_SECONDS) {
            setShowTimeTickingModal(true)
          }
          return newValue
        })
      }, 1000)
    }

    return () => {
      setIntervalId && clearInterval(setIntervalId)
    }
  }, [running, setShowTimeTickingModal])

  return {
    time: seconds,
    showTimeTickingModal,
    setShowTimeTickingModal,
    showTimeUpDialog,
    setShowTimeUpDialog,
  }
}
