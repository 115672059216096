import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import ModalOverlay from 'builder/components/ModalOverlay'
import { Icon24 } from 'builder/components/Icon'
import { useI18n } from 'builder/hooks/useI18n'
import { selectors as userSelectors, actions } from 'builder/modules/user'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { FetchStatuses } from 'builder/modules/constants'
import {
  Close,
  Content,
  Title,
  Description,
  ButtonContainer,
  KeepButton,
  SkipButton,
  PlanContainer,
  PlanInfoContainer,
  CrownIcon,
  PlanTitle,
  PlanDescription,
  PlanPrice,
  OfferPlanContainer,
  EyeIcon,
  OfferPlanTagContainer,
  OfferPlanTag,
  PlanDescriptionPhone,
  PlanContainerFirstRow,
} from './styles'

interface StayInTouchModalProps {
  onContinueCancellation: () => void
  onClose: () => void
}

export const StayInTouchModal = ({ onContinueCancellation, onClose }: StayInTouchModalProps) => {
  const { isPhone } = useMediaQueries()
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const discountStatus = useTypedSelector(userSelectors.discountStatus)
  const userAccountData = useTypedSelector(userSelectors.userData)
  const subscriptionAmount = userAccountData?.billingInfo.recurringAmount
    ? Number(userAccountData?.billingInfo.recurringAmount.slice(3))
    : 0
  const amountAfterDiscount = (subscriptionAmount / 2).toFixed(2)

  useEffect(() => {
    trackInternalEvent('promo_show', { where: 'cancellation', promo: 'stay_in_touch' })
  }, [])

  const handleApplyDiscount = useCallback(() => {
    trackInternalEvent('promo_acceptance', { promo: 'stay_in_touch', where: 'cancellation' })
    dispatch(actions.applyStayInTouchDiscount())
  }, [dispatch])

  return (
    <ModalOverlay
      fullScreen={isPhone}
      overlayFadeDuration={350}
      contentSlideDuration={350}
      backgroundOpacity={0.8}
      onClick={onClose}
    >
      <Content>
        <Close onClick={onClose}>
          <Icon24.Close />
        </Close>
        <Title>{i18n.t('builder.subscription_cancellation_v4.stay_in_touch_modal.title')}</Title>
        <Description>
          {i18n.t('builder.subscription_cancellation_v4.stay_in_touch_modal.description')}
        </Description>
        <PlanContainer>
          <PlanContainerFirstRow>
            <CrownIcon />
            <PlanInfoContainer>
              <PlanTitle>
                {i18n.t(
                  'builder.subscription_cancellation_v4.stay_in_touch_modal.current_plan.title',
                )}
              </PlanTitle>
              <PlanDescription>
                {i18n.t(
                  'builder.subscription_cancellation_v4.stay_in_touch_modal.current_plan.description',
                )}
              </PlanDescription>
            </PlanInfoContainer>
            <PlanPrice>
              {subscriptionAmount.toFixed(2)}$/{i18n.t('builder.intervals.month')}
            </PlanPrice>
          </PlanContainerFirstRow>
          <PlanDescriptionPhone>
            {i18n.t(
              'builder.subscription_cancellation_v4.stay_in_touch_modal.current_plan.description',
            )}
          </PlanDescriptionPhone>
        </PlanContainer>
        <OfferPlanContainer>
          <OfferPlanTagContainer>
            <OfferPlanTag>
              {i18n.t('builder.subscription_cancellation_v4.stay_in_touch_modal.special_plan.tag')}
            </OfferPlanTag>
          </OfferPlanTagContainer>
          <PlanContainerFirstRow>
            <EyeIcon />
            <PlanInfoContainer>
              <PlanTitle>
                {i18n.t(
                  'builder.subscription_cancellation_v4.stay_in_touch_modal.special_plan.title',
                )}
              </PlanTitle>
              <PlanDescription>
                {i18n.t(
                  'builder.subscription_cancellation_v4.stay_in_touch_modal.special_plan.description',
                )}
              </PlanDescription>
            </PlanInfoContainer>
            <PlanPrice>
              {amountAfterDiscount}$/{i18n.t('builder.intervals.month')}
            </PlanPrice>
          </PlanContainerFirstRow>
          <PlanDescriptionPhone>
            {i18n.t(
              'builder.subscription_cancellation_v4.stay_in_touch_modal.special_plan.description',
            )}
          </PlanDescriptionPhone>
        </OfferPlanContainer>
        <ButtonContainer>
          <SkipButton onClick={onContinueCancellation}>
            {i18n.t('builder.subscription_cancellation_v4.stay_in_touch_modal.skip_button')}
          </SkipButton>
          <KeepButton
            onClick={handleApplyDiscount}
            isLoading={discountStatus === FetchStatuses.loading}
            isDisabled={discountStatus === FetchStatuses.loading}
          >
            {i18n.t('builder.subscription_cancellation_v4.stay_in_touch_modal.keep_button')}
          </KeepButton>
        </ButtonContainer>
      </Content>
    </ModalOverlay>
  )
}
