import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import DesktopPicker from './DesktopPicker'
import MobilePicker from './MobilePicker'
import { DateRangePickerProps } from './types'

const DateRangePicker = (props: DateRangePickerProps) => {
  const { isPhone, hover } = useMediaQueries()
  const Picker = isPhone && !hover ? MobilePicker : DesktopPicker

  return <Picker {...props} />
}

export default DateRangePicker
