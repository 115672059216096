import { createAction, createReducer } from '@reduxjs/toolkit'
import type { Optional, UserDocumentType, UserDocumentTemplateId } from '@rio/types'
import { Store } from 'builder/modules/store'
import { PremiumServiceModalData } from '../user'
import { SnackbarTypes, UiStore } from './types'

// ---
// Action creators
// ---
export const actions = {
  // sharing modal
  openSharingModal: createAction<number>('builder/UI_OPEN_SHARING_MODAL'),
  setSharingModalResumeId: createAction<number>('builder/UI_SET_SHARING_MODAL_ID'),
  closeSharingModal: createAction('builder/UI_CLOSE_SHARING_MODAL'),

  // sharing trDoc modal
  openSharingTrDocModal: createAction<number>('builder/UI_OPEN_SHARING_TRDOC_MODAL'),
  setSharingModalTrDocId: createAction<number>('builder/UI_SET_SHARING_TRDOC_MODAL_ID'),
  closeSharingTrDocModal: createAction('builder/UI_CLOSE_SHARING_TRDOC_MODAL'),

  // account limit modal
  openLimitModal: createAction<UserDocumentType>('builder/UI_OPEN_LIMIT_MODAL'),
  closeLimitModal: createAction('builder/UI_CLOSE_LIMIT_MODAL'),

  // trustpilot review modal
  openRateModal: createAction('builder/UI_OPEN_RATE_MODAL'),
  closeRateModal: createAction('builder/UI_CLOSE_RATE_MODAL'),

  // document merging modal
  openMergeModal: createAction<{ resumeId: Optional<number>; coverLetterId: Optional<number> }>(
    'builder/UI_OPEN_MERGE_MODAL',
  ),
  closeMergeModal: createAction('builder/UI_CLOSE_MERGE_MODAL'),

  // docx modal
  setDocxModalDocumentId: createAction<number | null>('builder/SET_DOCX_MODAL_DOCUMENT_ID'),
  setDocxModalDocumentType: createAction<UserDocumentType | null>(
    'builder/SET_DOCX_MODAL_DOCUMENT_TYPE',
  ),
  docxModalClosed: createAction('builder/UI_CLOSE_DOCX_MODAL'),
  docxModalClosedDownload: createAction<{ template: UserDocumentTemplateId }>(
    'builder/DOCX_MODAL_CLOSED_DOWNLOAD',
  ),

  // snackbar
  setSnackbarShaking: createAction<boolean>('builder/SET_SNACK_BAR_SHAKING'),
  setSnackBarOpen: createAction<{
    status: boolean
    text?: string
    type?: SnackbarTypes
    url?: string
  }>('builder/SET_SNACK_BAR_OPENED'),
  setCancelableSnackbarOpen: createAction<{ status: boolean; text?: string }>(
    'builder/SHOW_CANCELABLE_SNACKBAR',
  ),
  setWarningSnackbarOpen: createAction<{ timeout: number; text: string }>(
    'builder/SHOW_WARNING_SNACKBAR',
  ),
  setConnectionLostSnackbarOpen: createAction<boolean>('builder/SHOW_CONNECTION_LOST_SNACKBAR'),
  closeSnackBar: createAction('builder/CLOSE_SNACKBAR'),

  // resume prefill modal
  setPrefillModalDocumentId: createAction<number | null>('builder/SET_PREFILL_MODAL_DOCUMENT_ID'),

  // cover letter modal
  openCoverLetterModal: createAction<{ id: number }>('builder/OPEN_COVER_LETTER_MODAL'),
  closeCoverLetterModal: createAction('builder/CLOSE_COVER_LETTER_MODAL'),

  // paywall
  openPaywall: createAction('builder/SHOW_PAYWALL_UI'),
  // ResumeScore Suggestions
  setIsSuggestionsOpen: createAction<{ status: boolean }>('builder/SET_SUGGESTIONS_OPEN'),

  // create your resume modal
  openCreateResumeModal: createAction('builder/UI_OPEN_CREATE_RESUME_MODAL'),
  closeCreateResumeModal: createAction('builder/UI_CLOSE_CREATE_RESUME_MODAL'),

  // Upcoming Careers Modal
  setShowUpcomingCareersModal: createAction<boolean>('builder/SET_SHOW_UPCOMING_CAREERS_MODAL'),
  setPremiumServiceModal: createAction<PremiumServiceModalData>(
    'builder/SET_SHOW_PREMIUM_SERVICE_MODAL',
  ),

  openPlansPageAbandonmentModal: createAction('builder/UI_OPEN_PLANS_PAGE_ABANDONMENT_MODAL'),
  closePlansPageAbandonmentModal: createAction('builder/UI_CLOSE_PLANS_PAGE_ABANDONMENT_MODAL'),

  // TR edit modal
  openTREditModal: createAction<number>('builder/UI_OPEN_TR_EDIT_MODAL'),
  closeTREditModal: createAction<number>('builder/UI_CLOSE_TR_EDIT_MODAL'),

  // Explore & Track Jobs Paywall
  setTrackJobPaywall: createAction<boolean>('builder/TRACK_JOB_SET_SHOW_LIMIT_MODAL'),

  // Side menu
  setSideMenuOpen: createAction<boolean>('builder/SIDE_MENU_TOGGLE'),
}

// ---
// Selectors
// ---
export const selectors = {
  docxModalDocumentId: (state: Store) => state.ui.docxModalDocumentId,
  docxModalDocumentType: (state: Store) => state.ui.docxModalDocumentType,
  limitModalType: (state: Store) => state.ui.limitModalType,
  coverLetterModalId: (state: Store) => state.ui.coverLetterModalId,
  isSnackBarOpen: (state: Store) => state.ui.isSnackBarOpen,
  prefillModalDocumentId: (state: Store) => state.ui.prefillModalDocumentId,
  isRateModalOpen: (state: Store) => state.ui.isRateModalOpen,
  isMergeModalOpen: (state: Store) => {
    return !!(state.ui.mergeModalResumeId || state.ui.mergeModalCoverLetterId)
  },
  isSuggestionsOpen: (state: Store) => state.ui.isSuggestionsOpen,
  isCreateResumeOpen: (state: Store) => state.ui.isCreateResumeOpen,
  showUpcomingCareersModal: (state: Store) => state.ui.showUpcomingCareersModal,
  showPremiumServiceModal: (state: Store) => state.ui.showPremiumServiceModal,
  trackJobPaywallModal: (state: Store) => state.ui.trackJobPaywallModal,
  isPlansPageAbandonmentModalOpen: (state: Store) => state.ui.isPlansPageAbandonmentModalOpen,
  isSideMenuOpen: (state: Store) => state.ui.isSideMenuOpen,
}

// ---
// Reducer
// ---

const initialState: UiStore = {
  sharingModalResumeId: null,
  sharingModalTrDocId: null,
  docxModalDocumentId: null,
  docxModalDocumentType: null,
  isRateModalOpen: false,
  isPlansPageAbandonmentModalOpen: false,
  limitModalType: null,
  isTREditModalOpen: false,
  editModalTrDocId: null,
  coverLetterModalId: null,
  prefillModalDocumentId: null,
  isSnackBarOpen: false,
  isSnackBarShaking: false,
  snackBarText: '',
  snackBarType: SnackbarTypes.loading,
  mergeModalResumeId: null,
  mergeModalCoverLetterId: null,
  isSuggestionsOpen: false,
  isCreateResumeOpen: false,
  showUpcomingCareersModal: false,
  trackJobPaywallModal: false,
  showPremiumServiceModal: { isOpen: false, serviceType: undefined },
  snackBarUrl: '',
  isSideMenuOpen: true,
}

export const reducer = createReducer(initialState, reducer => {
  reducer.addCase(actions.setSharingModalResumeId, (draft, action) => {
    draft.sharingModalResumeId = action.payload
  })
  reducer.addCase(actions.closeSharingModal, draft => {
    draft.sharingModalResumeId = null
  })
  reducer.addCase(actions.setSharingModalTrDocId, (draft, action) => {
    draft.sharingModalTrDocId = action.payload
  })
  reducer.addCase(actions.closeSharingTrDocModal, draft => {
    draft.sharingModalTrDocId = null
  })
  reducer.addCase(actions.openLimitModal, (draft, action) => {
    draft.limitModalType = action.payload
  })
  reducer.addCase(actions.closeLimitModal, draft => {
    draft.limitModalType = null
  })
  reducer.addCase(actions.openRateModal, draft => {
    draft.isRateModalOpen = true
  })
  reducer.addCase(actions.closeRateModal, draft => {
    draft.isRateModalOpen = false
  })
  reducer.addCase(actions.setDocxModalDocumentId, (draft, action) => {
    draft.docxModalDocumentId = action.payload
  })
  reducer.addCase(actions.setDocxModalDocumentType, (draft, action) => {
    draft.docxModalDocumentType = action.payload
  })
  reducer.addCase(actions.setSnackBarOpen, (draft, action) => {
    draft.isSnackBarOpen = action.payload.status
    draft.snackBarText = action.payload.text || draft.snackBarText
    draft.snackBarUrl = action.payload.url || draft.snackBarUrl
    draft.snackBarType = action.payload.type || SnackbarTypes.loading
  })
  reducer.addCase(actions.setPrefillModalDocumentId, (draft, action) => {
    draft.prefillModalDocumentId = action.payload
  })
  reducer.addCase(actions.setSnackbarShaking, (draft, action) => {
    draft.isSnackBarShaking = action.payload
  })
  reducer.addCase(actions.openMergeModal, (draft, action) => {
    draft.mergeModalResumeId = action.payload.resumeId || null
    draft.mergeModalCoverLetterId = action.payload.coverLetterId || null
  })
  reducer.addCase(actions.closeMergeModal, draft => {
    draft.mergeModalResumeId = null
    draft.mergeModalCoverLetterId = null
  })
  reducer.addCase(actions.openCoverLetterModal, (draft, action) => {
    draft.coverLetterModalId = action.payload.id
  })
  reducer.addCase(actions.closeCoverLetterModal, draft => {
    draft.coverLetterModalId = null
  })
  reducer.addCase(actions.closeSnackBar, draft => {
    draft.isSnackBarOpen = false
  })
  reducer.addCase(actions.setIsSuggestionsOpen, (draft, action) => {
    draft.isSuggestionsOpen = action.payload.status
  })
  reducer.addCase(actions.openCreateResumeModal, draft => {
    draft.isCreateResumeOpen = true
  })
  reducer.addCase(actions.closeCreateResumeModal, draft => {
    draft.isCreateResumeOpen = false
  })
  reducer.addCase(actions.setShowUpcomingCareersModal, (draft, action) => {
    draft.showUpcomingCareersModal = action.payload
  })
  reducer.addCase(actions.setPremiumServiceModal, (draft, action) => {
    draft.showPremiumServiceModal = action.payload
  })
  reducer.addCase(actions.openPlansPageAbandonmentModal, draft => {
    draft.isPlansPageAbandonmentModalOpen = true
  })
  reducer.addCase(actions.closePlansPageAbandonmentModal, draft => {
    draft.isPlansPageAbandonmentModalOpen = false
  })
  reducer.addCase(actions.openTREditModal, (draft, action) => {
    draft.isTREditModalOpen = true
    draft.editModalTrDocId = action.payload
  })
  reducer.addCase(actions.closeTREditModal, draft => {
    draft.isTREditModalOpen = false
  })
  reducer.addCase(actions.setTrackJobPaywall, (draft, action) => {
    draft.trackJobPaywallModal = action.payload
  })
  reducer.addCase(actions.setSideMenuOpen, (draft, action) => {
    draft.isSideMenuOpen = action.payload
  })
})

export const DOWNLOADED_COVER_LETTER_KEY = 'DOWNLOADED_COVER_LETTER_KEY'
