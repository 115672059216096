export const GET_EXPERT_HELP_LOW_LEADS_URL = (customUtms?: string | null) =>
  `https://www.topresume.com/new/resume-writing-pricing?dt=TRCIO2023&pt=UWYxd6Xe2VTS0&${
    !customUtms
      ? 'utm_source=careerio&utm_medium=cioplatform&utm_campaign=cio_critique_low_band_tr_leads&utm_content=cta2'
      : customUtms
  }`

export const GET_EXPERT_HELP_V2_NO_DISCOUNT = (customUtms?: string | null) =>
  `https://topresume.com/resume-writing?pt=UWYxd6Xe2VTS0&${
    !customUtms
      ? '&utm_source=careerio&utm_medium=cioplatform&utm_campaign=tr_cta_in_cio_critique_v2&utm_content=cta2'
      : customUtms
  }`

export const IFRAME_PARAMS =
  '&pt=9UiLvwST3Shdz&utm_content=cta_read_the_review&utm_source=rio_dash&utm_medium=rio_dash&utm_campaign=cr_rio'
