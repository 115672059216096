import styled from 'styled-components'
import Media from 'builder/styles/media'
import { CareerPathJobCard, CareerPathJobCardProps } from 'builder/components/CareerPathJobCard'

export const Container = styled.div`
  padding-bottom: 80px;
  overflow: hidden;
  position: relative;

  ${Media.Tablet`
    padding-bottom: 48px;
  `};

  ${Media.Phone`
    padding-bottom: 48px;
  `};
`

export const CardContainer = styled(CareerPathJobCard)<CareerPathJobCardProps>`
  width: 352px;
  height: 126px;
  align-items: center;
  padding: 20px;
  border-radius: 16px;
  cursor: pointer;
  margin-bottom: 24px;

  ${Media.Phone`
    margin-bottom: 16px;
  `}
`
