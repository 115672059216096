import { memo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { i18n as I18n } from 'builder/utils/i18n'
import { useAppSelector } from 'builder/hooks/useAppSelector'
import { AIResumeWorkExpCardData, actions } from 'builder/modules/resumeEditor'
import {
  AIResumeQuestionnaireCardType,
  AIResumeDraftEvents,
  AIResumeDraftEventLabels,
} from 'builder/modules/constants'
import AddMoreButton from '../../AddMoreButton'
import { CardContainer, CardWrapper } from '../styles'
import WorkExperienceCard from './WorkExperienceCard'

export const isWorkExpCardFilled = (card: AIResumeWorkExpCardData) => {
  const {
    position,
    company,
    date: { dateFrom, dateUntil, isDateUntilPresent },
  } = card

  if (!position || !company || !dateFrom || (!dateUntil && !isDateUntilPresent)) {
    return false
  }

  return true
}

export const isWorkExpCardEmpty = (card: AIResumeWorkExpCardData) => {
  const {
    position,
    company,
    date: { dateFrom, dateUntil, isDateUntilPresent },
  } = card

  if (position || company || dateFrom || dateUntil || isDateUntilPresent) {
    return false
  }

  return true
}

export const getErrors = (card: AIResumeWorkExpCardData) => {
  const {
    position,
    company,
    date: { dateFrom, dateUntil, isDateUntilPresent },
  } = card

  return {
    position: !position,
    company: !company,
    dateFrom: !dateFrom,
    dateUntil: !dateUntil && !isDateUntilPresent,
  }
}

export const WorkExperienceStep = memo(() => {
  const { cards } = useAppSelector(
    store => store.resumeEditor.aiResume.questionnaireData.userInput.workExperience,
  )
  const cardsCount = cards.length

  const dispatch = useDispatch()

  useEffect(() => {
    trackInternalEvent(AIResumeDraftEvents.seeWorkExperienceStep, {
      label: AIResumeDraftEventLabels.aiResume,
    })
  }, [])

  const onAdd = () => {
    trackInternalEvent(AIResumeDraftEvents.clickAddMore, {
      label: AIResumeDraftEventLabels.workExperience,
    })
    // If the first card isn't completely filled, show errors
    if (cards.length === 1 && !isWorkExpCardFilled(cards[0])) {
      const errors = getErrors(cards[0])
      dispatch(actions.setQuestionnaireWorkExpCardErrors(errors))
      return
    }

    const latestWorkExpCard = cards[cardsCount - 1]
    // If the latest card isn't atleast partially filled, do nothing
    if (isWorkExpCardEmpty(latestWorkExpCard)) {
      return
    }

    // Collapse the latest card
    dispatch(
      actions.collapseQuestionnaireCards({
        cardType: AIResumeQuestionnaireCardType.workExperience,
      }),
    )
    // Push an empty work exp card in cards array
    dispatch(actions.pushEmptyWorkExpInQuestionnaire())
  }

  return (
    <CardContainer>
      {cards.map((card, index) => (
        <CardWrapper key={card.id} withTopMargin={index > 0}>
          <WorkExperienceCard cardData={card} allCards={cards} />
        </CardWrapper>
      ))}
      {cardsCount < 3 && (
        <AddMoreButton
          {...{ onAdd }}
          text={I18n.t('builder.ai_resume_draft.work_experience.add')}
        />
      )}
    </CardContainer>
  )
})
