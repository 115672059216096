import { FC } from 'react'
import Logo from 'builder/components/Logo'
import Icon from 'builder/components/Icon'
import Steps from 'builder/components/Steps'
import { SignUpCopyright } from 'builder/components/SignUpCopyright'
import { STEPS } from '../SignUpStepManager'
import { CloseButton, Container, Main, Navbar, StepsWrapper } from './styles'

type LayoutProps = {
  steps: string[]
  regionPrefix: string
  currentStepName: keyof typeof STEPS
  currentStepNumber: number
}

const Layout: FC<LayoutProps> = ({
  children,
  steps,
  regionPrefix,
  currentStepName,
  currentStepNumber,
  ...rest
}) => {
  const homepageUrl = `/${regionPrefix}`

  return (
    <Container withImage={currentStepName !== STEPS.templates} {...rest}>
      <Navbar>
        <a aria-label="Logo" href={homepageUrl}>
          <Logo />
        </a>

        <StepsWrapper>
          <Steps steps={steps} currentStep={currentStepNumber} />
        </StepsWrapper>

        <CloseButton aria-label="Close" href={homepageUrl}>
          <Icon.CloseLarge />
        </CloseButton>
      </Navbar>
      <Main>{children}</Main>
      <SignUpCopyright />
    </Container>
  )
}

export default Layout
