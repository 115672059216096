import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import { Icon20 } from 'builder/components/Icon'

export const Container = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  ${Media.Phone`
    padding: 0 12px;
  `};
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 240px;
  background-color: ${Colors.White};
  box-shadow: 0px 16px 64px -8px rgba(15, 56, 113, 0.18), 0px 6px 8px -2px rgba(15, 56, 113, 0.02),
    0px 0px 1px 0px rgba(15, 56, 113, 0.32);
  padding: 30px 24px;
  border-radius: var(--S, 16px);
  margin-bottom: 160px;

  ${Media.AboveTablet`
    width: 608px;
  `};

  ${Media.Tablet`
    width: 70%;
  `};
  ${Media.Phone`
    width: 100%;
  `};
`

export const Title = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};
  color: ${Colors.Neutral80};
  text-align: center;
`
export const Subtitle = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral80};
  padding-top: 10px;
  padding-bottom: 40px;
  max-width: 367px;
  text-align: center;
`

export const CrownIcon = styled(Icon20.Crown)`
  color: ${Colors.Indigo50};
`
export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`
