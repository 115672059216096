import { memo, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import Tabs from 'builder/components/Tabs'
import { actions, selectors } from 'builder/modules/events'
import { FetchStatuses } from 'builder/modules/constants'
import { selectors as userSelectors } from 'builder/modules/user'
import { EventsList } from './EventsList'
import { EVENTS_PER_PAGE_SIZE, TABS } from './constants'
import { StyledTab, TabsContainer } from './styles'

export const MyEvents = memo(() => {
  const [selectedTab, setSelectedTab] = useState(TABS[0])
  const user = useSelector(userSelectors.userData)
  const dispatch = useDispatch()
  useEffect(() => {
    const params = {
      page: `${1}`,
      per_page: `${EVENTS_PER_PAGE_SIZE}`,
      include: 'registration-page',
    }
    if (selectedTab === TABS[0])
      dispatch(actions.fetchMyUpcomingEvents({ ...params, my_event: 'upcoming' }))
    else if (selectedTab === TABS[1])
      dispatch(actions.fetchMyEndedEvents({ ...params, my_event: 'ended' }))
  }, [dispatch, selectedTab, user?.email])
  const {
    data: { events: upcomingEvents },
    status: upcomingEventsStatus,
  } = useSelector(selectors.myUpcomingEvents)
  const {
    data: { events: endedEvents },
    status: endedEventsStatus,
  } = useSelector(selectors.myEndedEvents)

  const handleChangeTab = (tab: string) => {
    setSelectedTab(tab)
  }

  const noResultsTitle = useMemo(() => {
    const isUpcoming = selectedTab === TABS[0]
    trackInternalEvent('fetch_no_events_error', {
      label: 'virtual_events',
      parameter: 'app',
      tab: selectedTab,
    })
    return `There are no ${selectedTab.toLocaleLowerCase()} events that you ${
      isUpcoming ? 'have' : 'had'
    } registered for${isUpcoming ? ' yet' : ''}
    `
  }, [selectedTab])
  return (
    <>
      <TabsContainer>
        <Tabs>
          {TABS.map(tab => (
            <StyledTab
              key={tab}
              isActive={selectedTab === tab}
              onClick={() => handleChangeTab(tab)}
            >
              {tab}
            </StyledTab>
          ))}
        </Tabs>
      </TabsContainer>
      <EventsList
        events={selectedTab === TABS[0] ? upcomingEvents : endedEvents}
        isLoading={
          selectedTab === TABS[0]
            ? upcomingEventsStatus === FetchStatuses.loading
            : endedEventsStatus === FetchStatuses.loading
        }
        title={noResultsTitle}
      />
    </>
  )
})
