import { useConfig } from 'builder/hooks/useConfig'

export const useAutoTailoringAppConfig = () => {
  const config = useConfig()
  const builderAutotailoring = config?.features?.builderAutotailoring

  const isAutoTailoringEnabled = !!builderAutotailoring && builderAutotailoring !== 'control'
  const isManualTailoringAllowed = !isAutoTailoringEnabled || builderAutotailoring === 'button_two'

  return {
    isAutoTailoringEnabled,
    isManualTailoringAllowed,
  }
}
