import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'

export const JobDetailsContainer = styled.div``

export const Content = styled.div`
  width: 100%;
  gap: 127px;
  display: flex;
  justify-content: space-between;
`

export const LeftContent = styled.div`
  width: 100%;
  gap: 48px;
  display: flex;
  flex-direction: column;

  ${Media.Phone`
    gap: 32px;
  `}
`

export const RightContent = styled.div`
  width: 100%;
  max-width: 256px;
  gap: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export const Learning = styled.div`
  padding-top: 40px;
  gap: 40px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${Colors.Neutral15};
`

export const Container = styled.div<{ isSwiperEnabled: boolean }>`
  width: 100%;

  ${({ isSwiperEnabled }) => {
    return Media.Tablet`
    padding: ${() => !isSwiperEnabled && '0px 24px'};
    width: ${() => !isSwiperEnabled && '100%'};
    `
  }}

  ${Media.Phone`
    padding: 0;
    width: calc(100% + 20px);
    margin-top: 36px;
  `}

  ${({ isSwiperEnabled }) => {
    return Media.Phone`
    width: ${() => !isSwiperEnabled && '100%'};
    `
  }}
`

export const BannerWrapper = styled.div`
  width: 100%;
  padding-inline: 8px;
`
