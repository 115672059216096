import styled from 'styled-components'
import Media from 'builder/styles/media'

export const VisualContainer = styled.div`
  position: absolute;
  width: 268px;
  height: 214px;
  left: 0px;
  top: 0px;

  ${Media.Phone`
    width: 242px;
    height: 212px;
  `}
`

export const VisualImageContainer = styled.div`
  position: absolute;
  width: 268px;
  height: 214px;
  z-index: 1;

  ${Media.Phone`
    width: 242px;
    height: 212px;
  `}
`

export const VisualImage = styled.img`
  width: 100%;
  height: 100%;
`
