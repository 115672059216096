import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Tab } from 'builder/components/Tabs'
import Icon24 from 'builder/components/Icon'
import { rotation } from 'builder/styles/keyframes'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  padding-top: 24px;
  margin: 0;

  ${Media.Tablet`
    padding-bottom: 56px;
  `};

  ${Media.Phone`
    padding-bottom: 48px;
  `};
`

export const Content = styled.div`
  width: 1120px;
  margin: 0 auto;

  ${Media.Tablet`
    width: 736px;
  `};

  ${Media.Phone`
    width: 335px;
  `}
`

export const MainTitle = styled.p`
  ${Typography.L}
  ${FontWeights.Medium}
  color: ${Colors.Neutral100};
`

export const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 48px;
  margin-top: 32px;
`

export const StyledTab = styled(Tab)`
  ${Typography.Body}
  margin-right: 48px;

  ${Media.Phone`
    width: 50%;
    text-align: center;
    margin-right: 0;
 `}
`

export const MyEventsContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 32px;
  margin: 32px 0px;
  flex-wrap: wrap;

  ${Media.Phone`
    flex-direction: column;
    margin: 0px;
  `}
`

export const EventCardContainer = styled.div`
  width: 352px;
  height: 364px;

  ${Media.Phone`
    width: 335px;
    height: 310px;
  `}
`

export const UpcomingEventsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

export const UpcomingHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${Media.Phone`
    flex-direction: column;
    justify-content: flex-start;
  `}
`

export const UpcomingTitle = styled.p`
  ${Typography.M}
  ${FontWeights.Medium}
  color: ${Colors.Neutral100};

  ${Media.Phone`
    text-align: left;
    width: 100%;
    margin-bottom: 24px;
  `}
`

export const UpcomingFilterContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  align-items: center;

  ${Media.Phone`
    width: 335px;
  `}
`

export const SearchFilterContainer = styled.div<{ isSelected: boolean }>`
  width: 270px;
  position: relative;

  ${({ isSelected }) => Media.Phone`
    width: ${isSelected ? '149px' : '267px'};
  `}
`

export const SearchFilter = styled.input`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 8px 32px;
  outline: none;
  border-bottom: 1px solid ${Colors.Neutral15};
  border-left: none;
  border-top: none;
  border-right: none;
`

export const SearchIcon = styled(Icon24.EventSearch)`
  position: absolute;
  left: 0;
  top: 40%;
  transform: translateY(-40%);
  color: ${Colors.Neutral50};
`

export const DateFilterContainer = styled.div`
  /* width: 75px; */
`

export const DateFilter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  cursor: pointer;
  width: 100%;
`

export const DateFilterTitle = styled.div`
  ${Typography.Body}
  color: ${Colors.Neutral100};
`

export const DateFilterOpenIconContainer = styled.div`
  text-align: right;
  width: 38px;
`

export const BottomContainer = styled.div`
  width: 100%;
  margin: 24px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ShowMoreTitle = styled.span`
  ${Typography.Body}
  ${FontWeights.Medium}
  color: ${Colors.Neutral50};
  justify-content: center;
  cursor: pointer;
  width: 176px;
  height: 56px;
  display: flex;
  padding: 16px 24px;
  background-color: ${Colors.Neutral5};
  border-radius: 12px;
`

export const Spinner = styled(Icon24.Spinner)`
  color: ${Colors.Blue50};
  animation: ${rotation} 0.5s infinite linear;
  left: 4px;
  top: 4px;
`

export const SpinnerContainer = styled.div`
  width: 32px;
  height: 32px;
  margin-bottom: 24px;
  left: 24px;
  top: 320px;
  background: ${Colors.Blue20};
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
`

export const SkeletonContainer = styled.div`
  display: flex;
  position: relative;
  gap: 32px;
  justify-content: center;
  align-items: center;
`

export const Skeleton = styled.div`
  width: 352px;
  height: 364px;
  border-radius: 16px;
  background: linear-gradient(180deg, ${Colors.Neutral5} 0%, rgba(247, 249, 252, 0) 42.81%);

  ${Media.Phone`
    width: 335px;
    height: 310px;
  `}
`

export const SkeletonTitle = styled.p`
  ${Typography.Body}
  color: ${Colors.Neutral90};
  text-align: center;
  position: absolute;
  margin: auto;
  max-width: 464px;

  ${Media.Phone`
    max-width: 280px;
  `}
`
