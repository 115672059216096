import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { Media } from 'builder/styles/media'
import { blinking } from 'builder/styles/keyframes'

export const PlaceholderWrapper = styled.div`
  display: flex;
`

export const PlaceholderCard = styled.div`
  flex-shrink: 0;
  width: 268px;
  height: 376px;
  margin-right: 16px;
  border-radius: 16px;
  background-color: ${Colors.Neutral10};
  animation: ${blinking} 1s infinite linear;

  &:nth-child(1) {
    animation-delay: -0.3s;
  }

  &:nth-child(2) {
    animation-delay: -0.15s;
  }

  ${Media.Tablet`
    margin-right: 8px;
  `}
`
