import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import Foldable from 'builder/components/Foldable'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'

import { First90Days } from './components/First90Days'
import { PathToPromotion } from './components/PathToPromotion'
import { Close, Header, Wrapper, LearnText, ArrowDown, HeaderTitle, LearnSection } from './styles'

type Props = {
  planSlug: string
}

const Description = ({ planSlug }: Props) => {
  const navigate = useNavigate()
  const [isCollapsed, setIsCollapsed] = useState(false)

  const headerTitle = planSlug === 'custom-plan' ? 'How it works' : 'About career plan'
  const learnText = planSlug === 'custom-plan' ? 'How it works' : 'Learn about career plan'

  const getLabel = () => {
    if (planSlug === 'first-90-days') {
      return 'first_90_days'
    }

    return 'path_to_promotion'
  }

  useEffectOnMount(() => {
    const isCollapsed = localStorage.getItem('HIDE_DETAILS')

    if (!isCollapsed || isCollapsed === 'false') {
      setIsCollapsed(false)
    } else {
      setIsCollapsed(true)
    }
  })

  const handleClick = () => {
    localStorage.setItem('HIDE_DETAILS', 'false')

    trackInternalEvent('open_plan_description', {
      label: getLabel(),
    })

    setIsCollapsed(false)
  }

  const handleClose = () => {
    localStorage.setItem('HIDE_DETAILS', 'true')

    trackInternalEvent('close_plan_description', {
      label: getLabel(),
    })

    setIsCollapsed(true)
  }

  const getContent = () => {
    if (planSlug === 'first-90-days') {
      return <First90Days navigateTo={navigate} />
    }

    return <PathToPromotion navigateTo={navigate} />
  }

  return (
    <>
      {isCollapsed ? (
        <LearnSection onClick={handleClick}>
          <LearnText>{learnText}</LearnText>
          <ArrowDown />
        </LearnSection>
      ) : (
        <Foldable isOpen={!isCollapsed}>
          <Wrapper>
            <Header>
              <HeaderTitle>{headerTitle}</HeaderTitle>
              <Close onClick={handleClose} />
            </Header>
            {getContent()}
          </Wrapper>
        </Foldable>
      )}
    </>
  )
}

export default Description
