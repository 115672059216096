import { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { i18n } from 'builder/utils/i18n'
import Toggle from 'builder/components/Toggle'
import { actions } from 'builder/modules/resumeEditor'
import { ExtraContainer } from './styles'

class ReferenceExtra extends PureComponent {
  static propTypes = {
    updateSimpleField: PropTypes.func,
    hideReferences: PropTypes.bool,
  }

  render() {
    const { hideReferences, updateSimpleField } = this.props
    return (
      <ExtraContainer>
        <Toggle
          label={i18n.t('builder.resume_editor.references_section.hide')}
          checked={hideReferences}
          onChange={event =>
            updateSimpleField('referencesUponRequest', event.target.checked, false)
          }
        />
      </ExtraContainer>
    )
  }
}

function mapStateToProps(state) {
  const resume = state.resumeEditor.resume
  return {
    hideReferences: resume ? resume.referencesUponRequest : false,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateSimpleField: (name, value, debounce) =>
      dispatch(actions.updateSimpleField({ name, value, debounce })),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferenceExtra)
