import { ReactNode } from 'react'
import styled from 'styled-components'
import Tooltip from 'builder/components/Tooltip'
import Icon from 'builder/components/Icon'
import Colors from 'builder/styles/colors'

const Wrapper = styled(Tooltip)`
  display: inline-block;
  width: 16px;
  height: 16px;
  color: ${Colors.Blue50};
  transition: color 0.1s ease;
  cursor: pointer;

  &:hover {
    color: ${Colors.Blue70};
  }

  & > svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`
type TooltipQuestionProps = {
  icon?: ReactNode
  children?: string
} & React.ComponentProps<typeof Tooltip>

export const TooltipQuestion = ({ icon, children, ...props }: TooltipQuestionProps) => (
  <Wrapper value={children} {...props}>
    {icon || <Icon.HintOutlined />}
  </Wrapper>
)
