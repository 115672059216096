import styled from 'styled-components'
import { TransitionStatus } from 'react-transition-group'
import { Colors } from 'builder/styles/colors'
import { Typography } from 'builder/styles/typography'
import { Variant } from './types'

export const FieldNoteContainer = styled.div<{ in: TransitionStatus; variant: Variant }>`
  ${Typography.Caption};
  margin-top: 4px;
  color: ${props => (props.variant === 'error' ? Colors.Red50 : Colors.Neutral50)};
  transition: max-height 0.2s ease;

  & a {
    color: ${Colors.Blue50};
  }

  & a:hover,
  & a:focus-visible {
    text-decoration: ${Colors.Blue60};
  }

  & a:active {
    text-decoration: ${Colors.Blue70};
  }

  ${props =>
    ({
      entering: 'max-height: 80px;',
      entered: 'max-height: auto;',
      exiting: 'max-height: 0;',
      exited: 'max-height: 0;',
      unmounted: null,
    }[props.in])};
`
