import styled from 'styled-components'
import { TransitionProps } from './types'

export const Wrapper = styled.div<TransitionProps>`
  opacity: 0;
  transform: translateX(10px);
  transition: opacity 500ms ease-out, transform 400ms ease-out;

  ${props =>
    ({
      entering: null,
      entered: 'opacity: 1; transform: translateX(0);',
      exiting: 'opacity: 0; translateX(10px);',
      exited: null,
      unmounted: null,
    }[props?.transitionState || 'entered'])};
`
