import styled from 'styled-components'
import Media from 'builder/styles/media'
import { Colors } from 'builder/styles/colors'
import Icon24, { Icon20 } from 'builder/components/Icon'
import { Typography, FontWeights } from 'builder/styles/typography'

export const SubContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${Media.Phone`
    margin-bottom: 12px;
  `}
`

export const Title = styled.div`
  ${Typography.S};
  ${FontWeights.Medium};
`

export const EditContainer = styled.div`
  cursor: pointer;
  gap: 6px;
  display: flex;
  align-items: center;
`

export const EditIcon = styled(Icon20.Edit)`
  width: 32px;
  height: 32px;
  padding: 6px;
  border-radius: 100px;
  color: ${Colors.White};
  background: ${Colors.Blue50};
`

export const TrashIcon = styled(Icon24.Trash)`
  color: ${Colors.Neutral30};

  &:hover {
    color: ${Colors.Blue50};
  }
`

export const MiniTrashIcon = styled(Icon20.Trash)`
  color: ${Colors.Neutral30};

  &:hover {
    color: ${Colors.Blue50};
  }
`

export const EditText = styled.div`
  color: ${Colors.Neutral50};
`
