import { useCallback } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import SpacingControl from 'builder/components/DocumentCustomizationModal/SpacingControl'
import { useUpdateSimpleField } from 'builder/hooks/useUpdateSimpleField'
import { useAppSelector } from 'builder/hooks/useAppSelector'
import { useDocumentPreview } from '../../hooks/useDocumentPreview'
import { useOverviewModal } from '../../hooks/useOverviewModal'
import { Container, CloseIcon, ActionContainer } from './styles'

export const Toolbar = () => {
  const { resume } = useDocumentPreview()
  const { handleOpenApproveModal, score } = useOverviewModal()
  const { updateSimpleField } = useUpdateSimpleField()
  const templates = useAppSelector(store => store.generalEditor.resumeTemplates)
  const currentTemplate = templates.find(template => template.id === resume?.template)
  const supportsSpacing = currentTemplate?.supportsSpacing
  const handleSpacingChange = (spacing: number) => {
    updateSimpleField({ name: 'spacing', value: spacing, debounce: true })
  }

  const handleClose = useCallback(() => {
    handleOpenApproveModal()
    trackInternalEvent('click_close', { label: 'auto_tailoring_overview_screen', score: score })
  }, [handleOpenApproveModal, score])

  return (
    <Container>
      <ActionContainer>
        <SpacingControl
          isSupported={supportsSpacing}
          value={resume?.spacing}
          onChange={handleSpacingChange}
        />
      </ActionContainer>
      <CloseIcon onClick={handleClose} />
    </Container>
  )
}
