import { i18n } from 'builder/utils/i18n'
import PropTypes from 'prop-types'
import { Component } from 'react'
import enhanceWithClickOutside from 'react-click-outside'
import { Transition } from 'react-transition-group'
import {
  convertToExternal,
  convertToFieldValue,
  guessMonth,
  guessYear,
  isDateHidden,
  isMonthHidden,
} from 'builder/components/DateRangePicker/utils'
import PickerPanel from 'builder/components/DateRangePicker/PickerPanel'
import { TextField } from 'builder/components/TextField'
import { Container, Label, LabelContent } from 'builder/components/DateRangePicker/styles'

class SingleDatePicker extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    currentlyLabel: PropTypes.string,
    label: PropTypes.string,
  }

  static defaultProps = {
    onChange: () => {},
  }

  constructor(props) {
    super(props)
    const date = props.value ? new Date(props.value) : new Date()
    const monthInShort = date.toLocaleString('default', { month: 'short' })
    const fullYear = date.getFullYear()
    const startValue = `${monthInShort}, ${fullYear}`
    const startMonth = date.getMonth() + 1
    const startYear = date.getFullYear()
    this.state = {
      startValue,
      startMonth,
      startYear,
      isStartPickerOpen: false,
    }
  }

  handleEnter = node => {}

  handleEntering = node => {}

  handleExiting = node => {}

  handleClickOutside() {
    this.setState({ isStartPickerOpen: false })
  }

  openStartPicker = () => {
    this.setState({
      isStartPickerOpen: true,
    })
  }

  closeStartPicker = () => this.setState({ isStartPickerOpen: false })

  changeStartYear = startYear => {
    const { startMonth } = this.state
    const startValue = convertToFieldValue(startMonth, startYear)

    this.setState({ startYear, startValue })

    this.props.onChange({
      dateFrom: convertToExternal(startMonth, startYear, {
        isDateHidden: isDateHidden(startValue),
      }),
    })
  }

  updateStartPicker = e => {
    const newValue = e.target.value
    const newState = {
      startValue: newValue,
      startMonth: guessMonth(newValue),
      startYear: guessYear(newValue),
    }
    this.setState(newState)

    this.props.onChange({
      dateFrom: convertToExternal(newState.startMonth, newState.startYear, {
        isDateHidden: isDateHidden(newState.startValue),
      }),
      isMonthFromHidden: isMonthHidden(newState.startValue),
    })
  }

  handleStartChoose = (month, year) => {
    this.setState({
      startMonth: month,
      startValue: convertToFieldValue(month, year),
      isStartPickerOpen: false,
    })

    this.props.onChange({
      dateFrom: convertToExternal(month, year),
      isMonthFromHidden: !month,
    })
  }

  handlePresentToggle = value => {
    const { endMonth, endYear } = this.state
    this.setState({
      endValue: convertToFieldValue(endMonth, endYear, {
        isDatePresent: value,
      }),
    })

    this.props.onChange({
      isDateUntilPresent: value,
    })
  }

  handleStartBlur = () => {
    const { startMonth, startYear, startValue } = this.state
    this.setState({
      startValue: convertToFieldValue(startMonth, startYear, { prevValue: startValue }),
    })

    this.props.onChange({
      dateFrom: convertToExternal(startMonth, startYear, {
        isDateHidden: isDateHidden(startValue),
      }),
      isMonthFromHidden: isMonthHidden(startValue),
    })
  }

  handleTab = () => {
    if (this.state.isStartPickerOpen) this.closeStartPicker()
  }

  handleKeyDown = e => {
    let keyCode = e.keyCode

    // tab
    if (keyCode === 9) {
      this.handleTab()
    }
  }

  render() {
    const { isStartPickerOpen, startValue, startMonth, startYear } = this.state

    return (
      <Container onKeyDown={this.handleKeyDown}>
        <Label>
          <LabelContent>{this.props.label}</LabelContent>
        </Label>
        <TextField
          name="startDate"
          onFocus={this.openStartPicker}
          onBlur={this.handleStartBlur}
          onChange={this.updateStartPicker}
          value={startValue}
          active={isStartPickerOpen}
          placeholder={i18n.t('builder.date_range_picker.placeholder')}
          autoComplete="off"
        />

        <Transition
          in={isStartPickerOpen}
          timeout={0}
          onEnter={this.handleEnter}
          onEntering={this.handleEntering}
          onExiting={this.handleExiting}
          unmountOnExit
          appear
        >
          <PickerPanel
            month={startMonth}
            year={startYear}
            onChangeYear={this.changeStartYear}
            onChoose={this.handleStartChoose}
            noActiveMonth={isDateHidden(startValue) || isMonthHidden(startValue)}
          />
        </Transition>
      </Container>
    )
  }
}

export default enhanceWithClickOutside(SingleDatePicker)
