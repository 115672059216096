const Icon = ({ ...props }: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        id="settings"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 5H9V7H21V9H9V11H7V9H3V7H7V5ZM15 15V13H17V15H21V17H17V19H15V17H3V15H15Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Icon
