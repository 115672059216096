import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Shadows from 'builder/styles/shadows'

export const RadioCheckedCircle = styled.div`
  ${Shadows.light.low};
  display: none;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${Colors.White};
`

export const Radio = styled.div<{ invalid?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  border: 2px solid ${Colors.Neutral30};
  transition: background-color 0.1s ease, border-color 0.1s ease;
  outline: none;

  ${props =>
    props.invalid &&
    css`
      border-color: ${Colors.Red50};
    `}

  &:focus-visible {
    border-color: ${props => (props.invalid ? Colors.Red60 : Colors.Blue50)};
  }
`

export const HiddenInput = styled.input<{ invalid?: boolean }>`
  display: none;

  & + ${Radio} {
    cursor: pointer;
  }

  :disabled + ${Radio} {
    cursor: not-allowed;

    ${RadioCheckedCircle} {
      box-shadow: none;
      background: ${Colors.Neutral15};
    }
  }

  :checked + ${Radio} {
    background: ${Colors.Blue50};
    border-color: ${Colors.Blue50};

    ${props =>
      props.invalid &&
      css`
        background: ${Colors.Red50};
        border-color: ${Colors.Red50};
      `}

    ${RadioCheckedCircle} {
      display: block;
    }
  }

  :disabled + ${Radio} {
    border-color: ${Colors.Neutral30};
    background: ${Colors.Neutral30};
  }

  :hover:not(:checked):not(:disabled) + ${Radio} {
    border-color: ${props => (props.invalid ? Colors.Red60 : Colors.Blue50)};
  }
`
