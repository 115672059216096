import { trackInternalEvent } from '@rio/tracking'
import { capitalize } from 'builder/utils/capitalize'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors, logoURL, Label, CompanySummary } from 'builder/modules/careerPath2'
import Loader from '../Loader'
import { Container, ListItem, Company, Logo, Name, Openings } from './styles'

type Props = {
  jobTitle?: string
  isLoading: boolean
  jobLocation: string
  companies: CompanySummary[]
}

const Companies = ({ isLoading, jobTitle, jobLocation, companies }: Props) => {
  const occupation = useTypedSelector(selectors.occupation)
  const isExploreCareers = useTypedSelector(selectors.isExploreCareers)
  const exploreCareersOccupation = useTypedSelector(selectors.exploreCareersOccupation)

  const getTerm = (companyName: string) => {
    if (jobTitle) return jobTitle + ' ' + capitalize(companyName)

    return companyName
  }

  if (isLoading) {
    return <Loader />
  }

  const handleClick = (companyName: string) => {
    if (jobLocation) {
      window.open(
        `/app/job-search?query=${getTerm(companyName)}&location=${jobLocation}&view=search`,
        '_blank',
      )
    } else {
      window.open(
        `/app/job-search?query=${getTerm(companyName)}&location=null&view=search`,
        '_blank',
      )
    }

    trackInternalEvent('click_company_jobs_link', {
      company_name: companyName,
      label: isExploreCareers ? 'explore_careers' : Label.label,
      job_title: !isExploreCareers ? occupation : exploreCareersOccupation,
    })
  }

  return (
    <Container>
      {companies.map((company, index) => (
        <ListItem key={index} onClick={() => handleClick(company.name)}>
          <Company>
            <Logo src={logoURL + company.name} alt={`${company.name}-logo`} />
            <Name>{capitalize(company.name)}</Name>
          </Company>
          <Openings>{`${company.jobPostings} ${
            company.jobPostings === 1 ? 'job' : 'jobs'
          }`}</Openings>
        </ListItem>
      ))}
    </Container>
  )
}

export default Companies
