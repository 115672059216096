import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { actions as panelActions } from 'builder/modules/panel'
import { DocumentTypes } from 'builder/modules/constants'
import { useI18n } from 'builder/hooks/useI18n'

import { Step } from '../Step'
import {
  EllipseOne,
  EllipseThree,
  EllipseTwo,
  ResumeImage,
  EllipsisContainer,
  ResumeImageGradient,
  AddIconContainer,
  AddIcon,
  ScoreContainer,
  ScoreColumn,
  ResumeScore,
  Score,
  ScorePercentage,
  Container,
  ImageContainer,
} from './styles'
import RESUME from './assets/resume.png'

interface Props {
  isCompleted: boolean
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  score?: number
  resumeId?: number
}

export const CreateResumeStep = ({ isCompleted, isOpen, setIsOpen, score, resumeId }: Props) => {
  const [showHover, setShowHover] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { i18n } = useI18n()

  const showScore = !!resumeId && !!score

  return (
    <Step
      title={i18n.t('builder.dashboard_v2.steps.create_resume.title')}
      subtitle={i18n.t('builder.dashboard_v2.steps.create_resume.subtitle')}
      isCompleted={isCompleted}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      handleOnClick={() => {
        if (resumeId) {
          navigate(`/resumes/${resumeId}/edit`)
        } else {
          dispatch(panelActions.createDocument({ type: DocumentTypes.resume }))
        }
      }}
      buttonText={i18n.t('builder.dashboard_v2.steps.create_resume.button_text')}
      analyticsEventsParam="resume"
      Images={
        <Container>
          <ImageContainer>
            <ResumeImage src={RESUME} hasScore={showScore} />
            <ResumeImageGradient className={showHover ? 'hover' : ''} />
            {showScore ? (
              <ScoreColumn>
                <ScoreContainer>
                  <Score>{score}</Score>
                  <ScorePercentage>%</ScorePercentage>
                </ScoreContainer>
                <ResumeScore>
                  {i18n.t('builder.dashboard_v2.steps.create_resume.resume_score')}
                </ResumeScore>
              </ScoreColumn>
            ) : (
              <AddIconContainer className={showHover ? 'hover' : ''}>
                <AddIcon className={showHover ? 'hover' : ''} />
              </AddIconContainer>
            )}
          </ImageContainer>
          <EllipsisContainer>
            <EllipseOne className={showHover ? 'hover' : ''} />
            <EllipseTwo className={showHover ? 'hover' : ''} />
            <EllipseThree className={showHover ? 'hover' : ''} />
          </EllipsisContainer>
        </Container>
      }
      setShowHover={setShowHover}
    />
  )
}
