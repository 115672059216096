import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useEffect } from 'react'
import { useI18n } from 'builder/hooks/useI18n'
import Button, { ButtonTheme } from 'builder/components/Button'
import { ModalTemplate } from 'builder/components/CareerProfile'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/resumeEditor'
import { actions as panelActions } from 'builder/modules/panel'
import { useNotApprovedAutoTailoredResume } from 'builder/components/AutoTailoring/hooks/useNotApprovedAutoTailoredResume'

const translationKey = 'builder.auto_tailoring.prevent_original_resume_edit_modal'

export const PreventOriginalResumeEditModal = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { i18n } = useI18n()
  const resume = useTypedSelector(selectors.resume)
  const notApprovedResume = useNotApprovedAutoTailoredResume(resume?.id)
  const shouldShowModal = !!resume && !!notApprovedResume

  const onClose = () => {
    navigate('/?tab=resume&autotailored=true')
  }

  const onReviewClick = () => {
    trackInternalEvent('click_review_resume', {
      label: 'unapproved_resume_edit_popup',
    })
    navigate(`/resumes/${notApprovedResume?.id}/edit`)
  }

  const onApproveClick = () => {
    if (!notApprovedResume?.id) return
    trackInternalEvent('click_approve', {
      label: 'unapproved_resume_edit_popup',
    })
    dispatch(panelActions.approveAutoTailoredResume({ id: notApprovedResume?.id }))
  }

  useEffect(() => {
    if (shouldShowModal) {
      trackInternalEvent('see_unapproved_resume_edit_popup')
    }
  }, [shouldShowModal])

  return (
    shouldShowModal && (
      <ModalTemplate
        onClose={onClose}
        title={i18n.t(`${translationKey}.title`)}
        description={i18n.t(`${translationKey}.description`)}
        buttons={
          <>
            <Button onClick={onReviewClick} theme={ButtonTheme.ghost}>
              {i18n.t(`${translationKey}.review_resume`)}
            </Button>
            <Button onClick={onApproveClick}>{i18n.t(`${translationKey}.approve`)}</Button>
          </>
        }
      />
    )
  )
}
