import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

export const SkillsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 24px;

  ${Media.Tablet`
    margin-bottom: 60px;
  `}
`

export const Title = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};
`

export const SkillsTitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  flex-wrap: wrap;
`

export const SkillsTitleWrapper = styled.div`
  display: inline-flex;
  padding: 6px 10px;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: ${Colors.Blue10};
  white-space: nowrap;
`
