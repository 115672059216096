import { FormEvent, useEffect, useRef, useState, useMemo, useCallback } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'

import {
  Label,
  selectBothFieldsText,
  selectLocationFieldText,
  selectOccupationFieldText,
} from 'builder/modules/careerPath2'
import { useFields } from 'builder/hooks/useFields'

import FormInput from '../FormInput'
import ErrorMessage from '../ErrorMessage'
import { CareerPathQuestionnaireFields, CareerPathQuestionnaireFormProps } from '../../types'
import {
  Image,
  Title,
  Fields,
  Container,
  FormButton,
  StyledSearch,
  FormContainer,
  FieldSeparator,
  StyledLocation,
} from './styles'
import { sendTrackingEvent } from './utils'

const TRACK_UNSELECTED_DELAY = 2000
const careerPathsURL = require('./assets/img/career_path_job_title.svg')
const exploreCareerPathsURL = require('./assets/img/explore_careers_job_title.svg')

const emptyErrors = {
  occupation: '',
  location: '',
  exploreCareersOccupation: '',
  exploreCareersLocation: '',
  commonError: '',
}

// TODO: translation
export const Form = ({
  onClear,
  onChange,
  onSubmit,
  location,
  occupation,
  isExploreCareers,
  fields: initialValues,
}: CareerPathQuestionnaireFormProps) => {
  const { isTablet } = useMediaQueries()
  const [fields, handleUpdate] = useFields(initialValues)
  const [isJobExpanded, setJobExpanded] = useState(false)
  const [isLocationExpanded, setLocationExpanded] = useState(false)
  const [errors, setErrors] =
    useState<{ [key in keyof CareerPathQuestionnaireFields]: string }>(emptyErrors)
  const showErrorMsgContainer = Object.values(errors).findIndex(value => value !== '') !== -1

  const imgSrc = useMemo(
    () => (isExploreCareers ? exploreCareerPathsURL : careerPathsURL),
    [isExploreCareers],
  )

  // Persist recently selected values to prevent extra "type_career_occupation" event calls
  const selectedValuesRef =
    useRef<{ [key in keyof CareerPathQuestionnaireFields]: string }>(initialValues)

  const title = isExploreCareers
    ? 'Enter the job title you want to know about'
    : 'Add your starting role to build your career path'

  useEffect(() => {
    const interval = setTimeout(() => {
      if (fields.occupation !== selectedValuesRef.current.occupation) {
        trackInternalEvent('type_career_job_title', {
          job_title: fields.occupation,
          ...Label,
        })
      } else if (
        fields.exploreCareersOccupation !== selectedValuesRef.current.exploreCareersOccupation
      ) {
        trackInternalEvent('type_career_job_title', {
          job_title: fields.exploreCareersOccupation,
          label: 'explore_careers',
        })
      }
    }, TRACK_UNSELECTED_DELAY)

    return () => {
      clearTimeout(interval)
    }
  }, [fields, isExploreCareers])

  const validateFields = useCallback(() => {
    let isValid = true
    let newErrors = Object.assign({}, errors)

    if (isExploreCareers) {
      if (
        fields.exploreCareersOccupation !== selectedValuesRef.current.exploreCareersOccupation &&
        fields.exploreCareersLocation !== selectedValuesRef.current.exploreCareersLocation
      ) {
        newErrors.commonError = selectBothFieldsText
      }

      if (!fields.exploreCareersOccupation) {
        isValid = false
        newErrors.exploreCareersOccupation = selectOccupationFieldText

        if (fields.exploreCareersLocation !== selectedValuesRef.current.exploreCareersLocation) {
          newErrors.commonError = selectBothFieldsText
        }
      }

      if (fields.exploreCareersOccupation !== selectedValuesRef.current.exploreCareersOccupation) {
        newErrors.exploreCareersOccupation = selectOccupationFieldText
        isValid = false
      }

      if (fields.exploreCareersLocation !== selectedValuesRef.current.exploreCareersLocation) {
        newErrors.exploreCareersLocation = selectLocationFieldText
        isValid = false
      }
    } else {
      if (
        fields.occupation !== selectedValuesRef.current.occupation &&
        fields.location !== selectedValuesRef.current.location
      ) {
        newErrors.commonError = selectBothFieldsText
      }

      if (!fields.occupation) {
        isValid = false
        newErrors.occupation = selectOccupationFieldText

        if (fields.location !== selectedValuesRef.current.location) {
          newErrors.commonError = selectBothFieldsText
        }
      }

      if (fields.occupation !== selectedValuesRef.current.occupation) {
        newErrors.occupation = selectOccupationFieldText
        isValid = false
      }

      if (fields.location !== selectedValuesRef.current.location) {
        newErrors.location = selectLocationFieldText
        isValid = false
      }
    }

    return { newErrors, isValid }
  }, [errors, fields, isExploreCareers])

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    const { newErrors, isValid } = validateFields()

    setErrors(newErrors)
    if (isValid) onSubmit(e)
  }

  const clearErrors = useCallback(
    (name: keyof CareerPathQuestionnaireFields) => {
      const newErrors = { ...errors, commonError: '' }

      newErrors[name] = ''
      setErrors(newErrors)
    },
    [errors],
  )

  const handleClear = useCallback(
    (name: keyof CareerPathQuestionnaireFields) => {
      handleUpdate({
        target: {
          name: name,
          value: '',
        },
      })

      selectedValuesRef.current[name] = ''

      clearErrors(name)
      onClear(name)
    },
    [clearErrors, onClear, handleUpdate],
  )

  const handleChange = useCallback(
    (e, name: keyof CareerPathQuestionnaireFields) => {
      clearErrors(name)

      handleUpdate({
        target: {
          name: name,
          value: e.target.value,
        },
      })

      if ((e.type === 'click' || e.key === 'Enter') && e.target.value) {
        selectedValuesRef.current[name] = e.target.value
        sendTrackingEvent({ name, value: e.target.value, location, occupation, isExploreCareers })
      }

      onChange(name, e.target.value)
    },
    [clearErrors, onChange, handleUpdate, location, occupation, isExploreCareers],
  )

  const occupationFieldName = useMemo(
    () => (isExploreCareers ? 'exploreCareersOccupation' : 'occupation'),
    [isExploreCareers],
  )
  const occupationFieldValue = useMemo(
    () => (isExploreCareers ? fields.exploreCareersOccupation : fields.occupation),
    [fields, isExploreCareers],
  )
  const locationFieldName = useMemo(
    () => (isExploreCareers ? 'exploreCareersLocation' : 'location'),
    [isExploreCareers],
  )
  const locationFieldValue = useMemo(
    () => (isExploreCareers ? fields.exploreCareersLocation : fields.location),
    [fields, isExploreCareers],
  )

  return (
    <Container>
      <FormContainer onSubmit={handleSubmit}>
        <Image src={imgSrc} isExploreCareers={isExploreCareers} />
        <Title>{title}</Title>
        <Fields borderRadius={isJobExpanded ? '12px 12px 0px 0px' : '12px'}>
          <FormInput
            onClear={handleClear}
            icon={<StyledSearch />}
            placeholder="Job Title"
            name={occupationFieldName}
            value={occupationFieldValue}
            suggestionParam="job-titles"
            storageKey="JOB_TITLE_HISTORY"
            isJobExpanded={isJobExpanded}
            multiSection={!occupationFieldValue.length}
            onExpand={(value: boolean) => setJobExpanded(value)}
            onInputChange={e => handleChange(e, occupationFieldName)}
          />

          <FieldSeparator />

          <FormInput
            onClear={handleClear}
            icon={<StyledLocation />}
            placeholder="Location"
            name={locationFieldName}
            value={locationFieldValue}
            suggestionParam="locations"
            storageKey="JOB_LOCATION_HISTORY"
            isLocationExpanded={isLocationExpanded}
            multiSection={!locationFieldValue.length}
            onInputChange={e => handleChange(e, locationFieldName)}
            onExpand={(value: boolean) => setLocationExpanded(value)}
          />
          {!isTablet && <FormButton type="submit">Search</FormButton>}
        </Fields>
        {isTablet && (
          <>
            {showErrorMsgContainer && (
              <ErrorMessage
                commonError={errors.commonError}
                locationError={errors[locationFieldName]}
                occupationError={errors[occupationFieldName]}
              />
            )}

            <FormButton type="submit">Search</FormButton>
          </>
        )}
      </FormContainer>
      {!isTablet && showErrorMsgContainer && (
        <ErrorMessage
          commonError={errors.commonError}
          locationError={errors[locationFieldName]}
          occupationError={errors[occupationFieldName]}
        />
      )}
    </Container>
  )
}
