import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { selectors, actions } from 'builder/modules/careerProfile'
import { useI18n } from 'builder/hooks/useI18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { Badge } from 'builder/components/Badge'
import { RoundProgress } from '../RoundProgress'
import {
  Container,
  Divider,
  PendingItem,
  PendingItemText,
  PendingItemsContainer,
  Text,
  TextContainer,
  CloseResultIcon,
} from './styles'
import { Size } from './types'

const translationKey = 'builder.career_profile.completeness'

export const CareerProfileCompletenessState: FC<{ className?: string; size?: Size }> = ({
  className,
  size = 's',
}) => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const completenessPercent = useTypedSelector(selectors.completenessPercent)
  const completenessPendingItems = useTypedSelector(selectors.completenessPendingItems)
  const isCompletenessResultClosed = useTypedSelector(selectors.isCompletenessResultClosed)
  const isVisible =
    (size === 's' && completenessPendingItems.length) ||
    (size === 'm' && !isCompletenessResultClosed)

  return isVisible ? (
    <Container className={className} $size={size}>
      <TextContainer>
        {completenessPercent !== null && <RoundProgress size={80} percent={completenessPercent} />}
        <Text $size={size}>
          {completenessPercent !== 100
            ? i18n.t(`${translationKey}.text`)
            : i18n.t(`${translationKey}.text_completed`)}
        </Text>
        {completenessPercent === 100 && size === 'm' && (
          <CloseResultIcon onClick={() => dispatch(actions.setIsCompletenessResultClosed(true))} />
        )}
      </TextContainer>
      {completenessPendingItems.length ? (
        <>
          {size === 'm' && <Divider />}
          <PendingItemsContainer>
            {completenessPendingItems.map(item => (
              <PendingItem key={item.key}>
                <Badge variant="green" size="medium">
                  +{item.value}%
                </Badge>
                <PendingItemText>
                  {i18n.t(`${translationKey}.pending_items.${item.key}`)}
                </PendingItemText>
              </PendingItem>
            ))}
          </PendingItemsContainer>
        </>
      ) : null}
    </Container>
  ) : null
}
