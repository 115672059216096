import ModalOverlay from 'builder/components/ModalOverlay'
import { Icon24 } from 'builder/components/Icon'

import { ModalContainer, Header, Logo, Close, Content, Iframe } from './styles'

interface Props {
  iframeUrl: string
  onClose: () => void
}

export const TrustpilotModal = ({ iframeUrl, onClose }: Props) => {
  return (
    <ModalOverlay onClick={onClose}>
      <ModalContainer>
        <Header>
          <Logo />

          <Close onClick={onClose}>
            <Icon24.Close />
          </Close>
        </Header>

        <Content>
          <Iframe src={iframeUrl} />
        </Content>
      </ModalContainer>
    </ModalOverlay>
  )
}
