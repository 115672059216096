import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import EditorField from 'builder/components/EditorField'

export const Title = styled.div`
  ${Typography.S};
  ${FontWeights.Bold};
`

export const StyledEditor = styled(EditorField)`
  margin-right: 0 !important;
`

export const Description = styled.div`
  color: ${Colors.Neutral50};
  margin-bottom: 20px;
`

export const Bottom = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
  gap: 10px;
`
