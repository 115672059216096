import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import uploadResumeURL from './assets/img/upload_resume.svg'
import dragDropURL from './assets/img/drag-drop.svg'

export const Container = styled.label<{ hasWrongFileType: boolean }>`
  width: 416px;
  display: flex;
  padding-top: 48px;
  align-items: center;
  flex-direction: column;
  border-radius: 12px;
  border: 1px dashed ${props => (props.hasWrongFileType ? Colors.Red50 : Colors.Neutral30)};
  margin-bottom: ${props => (props.hasWrongFileType ? '12px' : '32px')};
  transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;

  ${Media.AboveTablet`
    &:hover {
      background-color: ${Colors.Blue10};
      cursor: pointer;
      border: 1px solid ${Colors.Blue50};
    }
  `}

  ${Media.Tablet`
    width: 504px;
    height: auto;
  `}

  ${Media.Phone`
    width: 100%;
    padding-top: 48px;
  `};

  @media (max-width: 374px) {
    padding-top: 24px;
  }
`

export const Image = styled.div<{ isHovered?: boolean }>`
  width: 120px;
  height: 120px;
  margin-bottom: 16px;

  background-image: url(${props => (props.isHovered ? dragDropURL : uploadResumeURL)});
  background-position: center;
  background-size: cover;

  transition: background-image 0.25s ease-in-out;
  -webkit-transition: background-image 0.25s ease-in-out;
  -o-transition: background-image 0.25s ease-in-out;
  -moz-transition: background-image 0.25s ease-in-out;

  ${Media.Phone`
    margin-bottom: 24px;
  `};

  @media (max-width: 374px) {
    margin-bottom: 24px;
  }
`

export const SubText = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};

  width: 287px;
  text-align: center;
  margin-bottom: 72px;

  ${Media.Phone`
    margin-bottom: 64px;
    width: calc(100% - 48px);
  `};

  @media (max-width: 374px) {
    margin-bottom: 48px;
    width: calc(100% - 32px);
  }
`

export const ErrorMessage = styled.div`
  ${Typography.Caption};

  text-align: center;
  margin-bottom: 24px;
  color: ${Colors.Red50};
`
