import { trackInternalEvent } from '@rio/tracking'
import { i18n } from 'builder/utils/i18n'
import Tooltip from 'builder/components/Tooltip'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { goToBillingPlanPage } from 'builder/utils/goToBillingPlanPage'
import {
  Container,
  RenewButton,
  DaysCountButton,
  CrownIconContainer,
  TrailDaysText,
  CrownIcon,
} from './styles'

interface IProps {
  daysLeftPremium: number
  [x: string]: any // rest
}

export const PremiumTracker = ({ daysLeftPremium, ...rest }: IProps) => {
  const { isPhone } = useMediaQueries()

  const handlePremiumUpgrade = () => {
    if (daysLeftPremium > 0) {
      trackInternalEvent('click_keep_premium', { label: 'navigation' })
    }
    goToBillingPlanPage()
  }

  return (
    <Container daysLeftPremium={daysLeftPremium} {...rest}>
      <Tooltip
        position="bottom"
        multiline={!!isPhone}
        value={
          daysLeftPremium < 1
            ? i18n.t(`builder.navigation.post_premium.premium_over`)
            : i18n.t(`builder.navigation.post_premium.premium_warning`)
        }
      >
        <DaysCountButton>
          <CrownIconContainer>
            <CrownIcon />
          </CrownIconContainer>
          <TrailDaysText>
            {i18n.t(
              isPhone
                ? `builder.navigation.post_premium.days_left_shorthand`
                : `builder.navigation.post_premium.days_left`,
              {
                days_left_count: daysLeftPremium < 1 ? 0 : daysLeftPremium,
              },
            )}
          </TrailDaysText>
        </DaysCountButton>
      </Tooltip>

      {!isPhone && (
        <RenewButton onClick={handlePremiumUpgrade}>
          {daysLeftPremium < 1
            ? i18n.t(`builder.navigation.post_premium.renew`)
            : i18n.t(`builder.navigation.post_premium.keep`)}{' '}
          {i18n.t(`builder.navigation.post_premium.premium`)}
        </RenewButton>
      )}
    </Container>
  )
}
