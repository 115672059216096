import styled from 'styled-components'
import { animated } from '@react-spring/web'
import Media from 'builder/styles/media'
import { Icon20 } from 'builder/components/Icon'
import Colors from 'builder/styles/colors'
import Shadows from 'builder/styles/shadows'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  position: relative;
  display: flex;
  width: 388px;
  align-items: flex-start;
  gap: var(--2XS, 8px);

  ${Media.Phone`
    width: 100%;
    align-items: initial;
    gap: initial;
  `}
`

export const Content = styled(animated.div)`
  position: relative;
  ${Shadows.lightWithBorder.high};

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 352px;
  background-color: ${Colors.White};
  border-radius: 12px;
  z-index: 9999999999;

  ${Media.Phone`
    max-width: 100%;
    border-radius: 12px 12px 0 0;
  `}
`

export const SubContent = styled.div`
  padding: 0;
  width: 100%;
`

export const ContentLabel = styled.div<{ isLastStep: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding: 8px 24px;
  transition: background-color 0.4s ease-in;
  &:hover {
    background-color: ${Colors.Neutral5};
    transition: background-color 0.4s ease-in;
    border-bottom-left-radius: ${props => props.isLastStep && 12}px;
    border-bottom-right-radius: ${props => props.isLastStep && 12}px;
  }
`

export const Heading = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};

  max-width: 236px;
  margin-bottom: 8px;
`

export const SubHeading = styled.div`
  max-width: 260px;
  ${Typography.Caption};
  ${FontWeights.Regular};
  ${Colors.White};
  color: ${Colors.Neutral70};
  padding-bottom: 12px;
`

export const TextContainer = styled.div`
  padding: 24px 24px 0 24px;
`

export const Image = styled.img`
  width: 189.653px;
  height: 180px;
  flex-shrink: 0;
`

export const AnimationContainer = styled.div`
  position: absolute;
  top: 100px;
`

export const AnimationSubContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 260px;
  height: 555px;
`

export const Description = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  max-width: 260px;
`

export const Text = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular};
  ${Colors.White};
  color: ${Colors.Neutral70};
`

export const VideoPlayer = styled.iframe`
  display: flex;
  height: 171px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: var(--3XS, 4px);
  border: 0;
`

export const DropdownContainer = styled.div<{ showContent: boolean }>`
  display: flex;
  padding: ${({ showContent }) => (showContent ? '8px 0' : '0')};
  flex-direction: column;
  align-items: flex-start;
  gap: var(--XS, 12px);
  align-self: stretch;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  max-height: ${({ showContent }) => (showContent ? '500px' : '0')};
`

export const Wrapper = styled.div`
  width: 100%;
  cursor: pointer;
`

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${Colors.Neutral20};
`

export const ServiceCardVisual = styled.img`
  max-height: 171px;
  width: 100%;
  height: auto;
  object-fit: cover;
  align-self: stretch;
  border-radius: var(--2XS, 8px);
`

export const CloseButton = styled.div<{ onHover?: boolean }>`
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  border-radius: var(--L, 32px);
  background: ${props => (props.onHover ? Colors.Blue10 : Colors.Neutral10)};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 12000;
`

export const CloseIcon = styled(Icon20.Close)<{ onHover?: boolean }>`
  color: ${props => (props.onHover ? Colors.Blue50 : Colors.Neutral50)};
  width: 20px;
  height: 20px;
  flex-shrink: 0;
`

export const ProgressBarContainer = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;

  ${Media.Phone`
    top: 20px;
    right: 20px;
  `}
`
