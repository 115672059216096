import { FetchStatuses } from 'builder/modules/constants'

export enum ResumeReviewScenarios {
  external = 'external',
  embedded = 'embedded',
}

export enum ResumeCritiqueStatuses {
  pending = 'pending',
  finished = 'finished',
  failed = 'failed',
}

export enum ResumeQualityStatuses {
  trouble = 'trouble',
  nearly_perfect = 'nearly perfect',
  open = 'open',
  closed = 'closed',
  expired = 'expired',
}

interface ResumeCritiqueBase {
  id: number
  resumeId: number
  status: ResumeCritiqueStatuses
  iframeUrl?: string | null
  reviewer: {
    name?: string | null
    photoUrl?: string | null
  }
  createdAt: string
  reviewedAt?: string | null
  isTopResumeUser?: boolean
  resumeStatus?: ResumeQualityStatuses
}

interface IncompleteResumeCritique extends ResumeCritiqueBase {
  status: Exclude<ResumeCritiqueStatuses, ResumeCritiqueStatuses.finished>
}

interface FinishedResumeCritique extends ResumeCritiqueBase {
  status: ResumeCritiqueStatuses.finished
  iframeUrl: string
  reviewer: {
    name: string
    photoUrl: string
  }
  reviewedAt: string
}

export type ResumeCritique = IncompleteResumeCritique | FinishedResumeCritique

export interface ResumeReviewStore {
  critiquesFetchStatus: FetchStatuses
  critiques: ResumeCritique[]
}
