import { ReactElement } from 'react'
import { useI18n } from 'builder/hooks/useI18n'

import {
  Items,
  Item,
  ItemHeader,
  ItemDescription,
  TickIcon,
  TemplateIcon,
  BuilderIcon,
  WordIcon,
  CostumizationIcon,
} from './styles'

interface IProps {
  activeStep: number
}

const icons: Record<string, { [key: string]: ReactElement }> = {
  step_1: {
    item: <TickIcon />,
  },
  step_2: {
    item_1: <CostumizationIcon />,
    item_2: <TemplateIcon />,
  },
  step_3: {
    item_1: <BuilderIcon />,
    item_2: <WordIcon />,
  },
}

const StepItems = ({ activeStep }: IProps) => {
  const { i18n } = useI18n()
  const TRANSLATION = `builder.onboarding_ai_enhanced.step_${activeStep}.items`

  const getTranslations = () => {
    if (typeof i18n.t(TRANSLATION) === 'object') {
      return i18n.t(TRANSLATION) as unknown as Record<string, { [key: string]: string }>
    }
    return {}
  }

  return (
    <Items>
      {Object.keys(getTranslations())?.map(item => {
        const stepIcons = icons[`step_${activeStep}`]
        const translation = getTranslations()

        return (
          <Item borderTop={activeStep !== 1} key={item}>
            {activeStep === 1 ? stepIcons?.item : stepIcons[item]}
            <ItemHeader>{translation[item]?.header}</ItemHeader>
            <ItemDescription>{translation[item]?.description}</ItemDescription>
          </Item>
        )
      })}
    </Items>
  )
}

export default StepItems
