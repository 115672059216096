/* eslint-disable @typescript-eslint/ban-ts-comment */

import { ProfilePicture } from 'packages/types'
import AVATAR_PLACEHOLDER_URL from 'images/builder/navigation/avatar-placeholder.svg'
import { Store } from '../store'

export const createProfilePictureSelector = (
  pictureDataSelector: (store: Store) => ProfilePicture | undefined,
) => {
  return <T extends boolean = true>(
    store: Store,
    params: { size?: 'medium' | 'thumb' | 'original'; placeholder?: T } = {},
  ): T extends true ? string : string | null => {
    const { size = 'medium', placeholder = true } = params
    const picture = pictureDataSelector(store)
    if (picture?.blank === false) {
      return picture[`${size}Url`]
    } else {
      // @ts-ignore
      return placeholder ? AVATAR_PLACEHOLDER_URL : null
    }
  }
}
