import { useCallback } from 'react'
import { useQueryParam } from 'builder/hooks/useQueryParam'
import { SimpleLayout } from 'builder/components/SimpleLayout'
import * as Styled from './styles'

type Props = React.ComponentProps<typeof SimpleLayout>

export const SignInLayout = ({ children, ...rest }: Props) => {
  const [regionPrefix] = useQueryParam('current_region_prefix')

  const handleLeave = useCallback(() => {
    window.location.href = `/${regionPrefix ?? ''}`
  }, [regionPrefix])

  return (
    <SimpleLayout onLogoClick={handleLeave} onCloseClick={handleLeave} {...rest}>
      <Styled.Container>{children}</Styled.Container>
    </SimpleLayout>
  )
}
