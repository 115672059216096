import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Shadows from 'builder/styles/shadows'

export const CardContainer = styled.div`
  max-width: 368px;
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
  background-color: ${Colors.Neutral5};
  ${Shadows.light.high};
`

export const CardContainerGreen = styled(CardContainer)`
  background-color: ${Colors.Green10};
`

export const SizedBox = styled.div<{ size: number }>`
  width: ${props => props.size || 0}px;
  height: ${props => props.size || 0}px;
`

export const CardDivider = styled.div<{ color?: string }>`
  width: 100%;
  height: 1px;
  background-color: ${props => props.color};
  margin: 24px 0;
`
