import { Accomplishment, MAX_SKILLS_SELECTED, StoryList } from 'builder/modules/sevenStories'
import {
  Wrapper,
  DescribeStep,
  FormWrapper,
  TextFieldContainer,
  Label,
  Field,
  AddSkillsContainer,
  AddIcon,
  AddTitle,
} from './style'
import { SkilList } from './components/SkilList'
import { StoryField } from './components/StoryField'

type Props = {
  isSkillError: boolean
  selectedAccomplishment: Accomplishment[]
  currentStoryIndex: number
  onAddNewSkill: () => void
  onSkillChange: (value: string, index: number) => void
  onStoryChange: (value: string, index: number, field: string) => void
}

export const DescribeAccomplishments = ({
  selectedAccomplishment,
  currentStoryIndex,
  onAddNewSkill,
  onStoryChange,
  onSkillChange,
  isSkillError,
}: Props) => {
  const curentStory = selectedAccomplishment[currentStoryIndex]
  const stories = curentStory.stories
  const skills = curentStory.skills

  const renderSkills = () => {
    return curentStory?.skills.map((item, index) => {
      return <SkilList {...{ item, onSkillChange, index, skills, isSkillError }} key={item.id} />
    })
  }

  const renderStoryField = () => {
    return StoryList.map((item, index) => {
      return (
        <StoryField {...{ item, index, stories }} key={item.id} onStoryChange={onStoryChange} />
      )
    })
  }
  return (
    <Wrapper>
      <DescribeStep>
        Story {currentStoryIndex + 1} of {selectedAccomplishment.length}
      </DescribeStep>

      <FormWrapper>
        <Field>
          <Label>Accomplishment</Label>
          <TextFieldContainer value={curentStory?.description} readOnly />
        </Field>
        {/* Render Story Field */}
        {renderStoryField()}

        {/* Render Story Skills */}
        {renderSkills()}
      </FormWrapper>

      {curentStory?.skills.length < MAX_SKILLS_SELECTED ? (
        <AddSkillsContainer onClick={onAddNewSkill}>
          <AddIcon />
          <AddTitle>Add another skill</AddTitle>
        </AddSkillsContainer>
      ) : null}
    </Wrapper>
  )
}
