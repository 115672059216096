import { memo } from 'react'
import { CoachService } from 'builder/modules/careerCoaching'
import Colors from 'builder/styles/colors'
import { CareerServices } from './constants'
import { Container, ServiceLabel, Service } from './styles'

interface Props {
  coachServices: Array<CoachService>
}

const themeColors = (service: string) => {
  let bgColor = ''
  let color = ''
  switch (service) {
    case CareerServices.PERSONAL_BRANDING:
      bgColor = Colors.Blue10
      color = Colors.Blue50
      break

    case CareerServices.JOB_SEARCH_STRATEGY:
      bgColor = Colors.Indigo10
      color = Colors.Indigo50
      break

    case CareerServices.CAREER_TRANSITION:
      bgColor = Colors.Beige10
      color = Colors.Beige50
      break

    case CareerServices.INTERVIEW_PREP:
      bgColor = Colors.Green10
      color = Colors.Green50
      break

    case CareerServices.SALARY_NEGOTIATION:
      bgColor = Colors.Amber10
      color = Colors.Amber40
      break

    case CareerServices.WORKPLACE_CHALLENGES:
      bgColor = '#E6F1F4' // As the color code is not defined in builder colors
      color = '#5A747A'
      break

    default:
      break
  }
  return { bgColor, color }
}

const CoachServices = ({ coachServices }: Props) => {
  return (
    <Container>
      {coachServices?.map((service: { name: string; description: string }, index: number) => (
        <Service service={themeColors(service.name)} key={index}>
          <ServiceLabel>{service.name}</ServiceLabel>
        </Service>
      ))}
    </Container>
  )
}

export default memo(CoachServices)
