import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { fadeInStyles } from '../styles'

export const PreviewContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 92px;
  height: 92px;
  border-radius: 4px;
  background-color: ${Colors.Neutral90};
`

export const Group = styled.div<{ fade?: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  ${props => props.fade && fadeInStyles};
`

export const PhotoLayer = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
`

export const AvatarSpinnerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  background-color: ${Colors.Neutral90};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
`
