import { useEffect, useState } from 'react'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'

export const useHelpScout = (isResumeEditor: boolean | undefined) => {
  const { isMidDesktop } = useMediaQueries()
  const [isHelpScoutPresent, setIsHelpScoutPresent] = useState(false)

  useEffect(() => {
    const helperBeaconElement = document.getElementById('beacon-container')
    setIsHelpScoutPresent(!!helperBeaconElement)

    if (helperBeaconElement) {
      const beaconButtonCollection = document.getElementsByClassName('BeaconFabButtonFrame')
      if (beaconButtonCollection.length > 0) {
        const beaconButton = beaconButtonCollection[0] as HTMLElement
        beaconButton.style.bottom = isResumeEditor ? (isMidDesktop ? '90px' : '4.2rem') : 'none'
        beaconButton.style.right = isResumeEditor ? '16px' : 'none'

        const beaconContainerCollection = document.getElementsByClassName('BeaconContainer')
        if (beaconContainerCollection.length > 0) {
          const beaconContainer = beaconContainerCollection[0] as HTMLElement
          beaconContainer.style.bottom = isResumeEditor ? (isMidDesktop ? '155px' : '8rem') : 'none'
          beaconContainer.style.right = isResumeEditor ? '24px' : 'none'
        }
      }
    }
  }, [isMidDesktop, isResumeEditor])

  return { isHelpScoutPresent }
}
