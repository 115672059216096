import { UseFormReturn } from 'react-hook-form'
import * as SimpleForm from 'builder/components/SimpleForm'
import { TextField } from 'builder/components/TextField'
import { useI18n } from 'builder/hooks/useI18n'
import { AutoApplyForm } from 'builder/modules/autoApply/types'
import { validateEmail as validateEmailRegex } from 'builder/utils/regexValidations'
import { AsyncAutosuggest } from 'builder/components/AsyncAutosuggest'
import { createNewLocationSuggestionsApiFetcher } from 'builder/components/FindJob/utils/createNewSuggestionsApiFetcher'
import { PhoneInput } from 'builder/components/PhoneInput/PhoneInput'

type Props = {
  form: UseFormReturn<
    Pick<
      AutoApplyForm,
      'socialLinksAttributes' | 'location' | 'fullAddress' | 'email' | 'phoneNumber'
    >
  >
}

const TRANSLATION = `builder.auto_apply.form.contact_information`

export const ContactInfoForm = (props: Props) => {
  const { form } = props

  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = form
  const { i18n } = useI18n()
  const required = i18n.t(`${TRANSLATION}.field_is_required`)

  const validateEmail = (val: string) => {
    if (!validateEmailRegex(val)) {
      return i18n.t(`${TRANSLATION}.invalid_email`)
    }
    return true
  }

  return (
    <>
      <SimpleForm.Row position="relative">
        <AsyncAutosuggest
          {...register('location', { required })}
          name="location"
          fetchItems={async (searchString: string) => {
            const result = await createNewLocationSuggestionsApiFetcher(searchString)
            return result.map(item => ({ text: item.formatted }))
          }}
          onChange={event => {
            setValue('location', event.target.value)
          }}
          value={watch('location')}
          onSuggestionSelected={(_, selected) => {
            setValue('location', selected?.suggestion?.text || '')
          }}
        >
          {inputProps => (
            <TextField
              {...inputProps}
              label={i18n.t(`${TRANSLATION}.location.label`)}
              placeholder={i18n.t(`${TRANSLATION}.location.placeholder`)}
              error={errors.location?.message}
            />
          )}
        </AsyncAutosuggest>
      </SimpleForm.Row>
      <SimpleForm.Row position="relative">
        <TextField
          {...register('fullAddress', { required })}
          label={i18n.t(`${TRANSLATION}.full_address.label`)}
          placeholder={i18n.t(`${TRANSLATION}.full_address.placeholder`)}
          error={errors.fullAddress?.message}
        />
      </SimpleForm.Row>
      <SimpleForm.Row position="relative">
        <TextField
          {...register('email', { validate: validateEmail, required })}
          name="email"
          label={i18n.t(`${TRANSLATION}.email.label`)}
          placeholder={i18n.t(`${TRANSLATION}.email.placeholder`)}
          error={errors.email?.message}
        />
      </SimpleForm.Row>
      <SimpleForm.Row position="relative">
        <PhoneInput
          {...register('phoneNumber', { required })}
          label={i18n.t(`${TRANSLATION}.phone.label`)}
          placeholder={i18n.t(`${TRANSLATION}.phone.placeholder`)}
          error={errors.phoneNumber?.message}
          value={watch('phoneNumber') || '+1'}
          onChange={phone => setValue('phoneNumber', phone)}
        />
      </SimpleForm.Row>
    </>
  )
}
