import { forwardRef } from 'react'
import { useSelector } from 'react-redux'
import type { Resume } from '@rio/types'

import { Optional, UserDocument } from 'packages/types'
import { useNetworkStatus } from 'builder/hooks/useNetworkStatus'
import { selectors } from 'builder/modules/rendering'
import PdfPreview from 'builder/components/PdfPreview'
import SsrPreview from 'builder/components/SsrPreview'
import OfflinePreview from 'builder/components/OfflinePreview'
import { DocumentTypes, RENDERERS } from 'builder/modules/constants'

type Primitive = string | number | boolean | null

type PreviewComponentType = typeof PdfPreview | typeof SsrPreview | typeof OfflinePreview

type DocumentType = {
  template?: Optional<Primitive | Primitive[]>
  id: number
  color: string
  spacing: number
}
type PreviewProps = {
  height: number
  width: number
  document: DocumentType | UserDocument | Resume
  documentId: number
  type: DocumentTypes
  onPaginationChange: (state: { currentPage: number; totalPagesCount: number }) => void
}

const Preview = forwardRef((props: PreviewProps, ref) => {
  let renderer = useSelector(selectors.renderer)
  let isOnline = useNetworkStatus()

  let PreviewComponent: PreviewComponentType =
    renderer === RENDERERS.server ? SsrPreview : PdfPreview
  if (!isOnline) PreviewComponent = OfflinePreview

  return <PreviewComponent {...{ ref, ...props, renderer }} />
})

export default Preview
