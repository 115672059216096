import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Shadows from 'builder/styles/shadows'

export const MessageNote = styled.div`
  text-align: center;
  max-width: 360px;
  margin: 32px auto 0;
  ${Typography.Caption};
  color: ${Colors.Neutral50};

  & a,
  & span {
    color: ${Colors.Blue50};
    cursor: pointer;

    &:hover {
      color: ${Colors.Blue70};
    }
  }
`

export const MessageAction = styled.div`
  margin-top: 24px;
  text-align: center;
`

export const FormNote = styled.div`
  margin-top: 12px;
  margin-bottom: -24px;
  ${Typography.Caption};
  color: ${Colors.Neutral50};

  & a,
  & span {
    color: ${Colors.Blue50};
    cursor: pointer;

    &:hover {
      color: ${Colors.Blue70};
    }
  }
`

export const Loading = styled.div`
  margin: 0 auto;
  width: 64px;
  height: 64px;
  border-radius: 32px;
  background-color: ${Colors.White};
  display: flex;
  align-items: center;
  justify-content: center;
  ${Shadows.lightWithBorder.mid};
`
