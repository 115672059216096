export const formatNumber = (number: number) => {
  const positiveNumber = Math.abs(number)

  const formattedNumber =
    positiveNumber < 1000
      ? positiveNumber.toLocaleString()
      : positiveNumber < 1000000
      ? Math.round(positiveNumber / 1000) + 'K'
      : Math.round(positiveNumber / 1000000) + 'M'

  if (positiveNumber <= 1000) {
    return '$' + '1K'
  }

  return '$' + formattedNumber
}

export const getWidth = (value1: number, value2: number) => Math.round((value1 / value2) * 100)
