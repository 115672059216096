import { FC } from 'react'
import { Link } from 'react-router-dom'
import { FeatureIconName } from 'builder/components/FeatureIcon'
import * as Styled from '../styles'

export const ActionListItem: FC<{
  iconName: FeatureIconName
  header: string
  text: string
  link?: string
  onClick?: () => void
}> = item => {
  const content = (
    <Styled.ListItem onClick={item.onClick}>
      <Styled.ListItemIcon name={item.iconName} />
      <Styled.ListItemTextContainer>
        <Styled.ListItemTextHeader>{item.header}</Styled.ListItemTextHeader>
        <Styled.ListItemText>{item.text}</Styled.ListItemText>
      </Styled.ListItemTextContainer>
      <Styled.ListItemArrowIcon />
    </Styled.ListItem>
  )

  return item.link ? (
    <Link to={item.link} target="_blank">
      {content}
    </Link>
  ) : (
    content
  )
}
