import { css } from 'styled-components'
import { blinking } from 'builder/styles/keyframes'
import Colors, { hexToRgba } from './colors'

const Shadow = css`
  box-shadow: 0 14px 16px -10px ${hexToRgba(Colors.Neutral30, 0.7)},
    0 20px 40px -8px ${hexToRgba(Colors.Neutral30, 0.12)};
`

const placeholderBackground = css`
  background-color: ${Colors.Neutral15};
  animation: ${blinking} 2s infinite linear;
`

export default {
  Shadow,
  placeholderBackground,
}
