import {
  Container,
  Content,
  Header,
  Option,
  Options,
  Service,
  AvailableDaysCheckbox,
} from './styles'
import { CareerDays } from './constants'

interface AvailableDaysProps {
  handleDaysChange: (name: string) => void
  availableDays: string[]
}

export const AvailableDays = ({ handleDaysChange, availableDays }: AvailableDaysProps) => {
  return (
    <Container>
      <Content>
        <Header>What days would you be free to meet with a coach?</Header>
        <Options>
          {CareerDays.map((day, index) => (
            <Option htmlFor={day.name} key={index} checked={availableDays?.includes(day.name)}>
              <AvailableDaysCheckbox
                id={day.name}
                checked={availableDays?.includes(day.name)}
                onChange={() => handleDaysChange(day.name)}
              />
              <Service>{day.name}</Service>
            </Option>
          ))}
        </Options>
      </Content>
    </Container>
  )
}
