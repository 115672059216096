import WILL_BE_BACK_LOGO from './assets/images/will_be_back.svg'
import WILL_BE_BACK_ACTIVATED_LOGO from './assets/images/will_be_back_activated.svg'

import NEED_GUIDANCE_LOGO from './assets/images/need_guidance.svg'
import NEED_GUIDANCE_ACTIVATED_LOGO from './assets/images/need_guidance_activated.svg'

import DONT_LIKE_SERVICE_LOGO from './assets/images/dont_like_service.svg'
import DONT_LIKE_SERVICE_ACTIVATED_LOGO from './assets/images/dont_like_service_activated.svg'

export const FLOW_IDS = {
  willBeBack: 1,
  needGuidance: 2,
  dontLikeTheService: 3,
}

type TranslatorFunction = (key: string) => string

export const ABANDONMENT_RESUME_FLOWS = (translatorFunction?: TranslatorFunction) => [
  {
    id: FLOW_IDS.willBeBack,
    titleLineOne: translatorFunction?.('builder.in_app_abandonment.will_be_back.title_line_one'),
    titleLineTwo: translatorFunction?.('builder.in_app_abandonment.will_be_back.title_line_two'),
    logo: WILL_BE_BACK_LOGO,
    logoClicked: WILL_BE_BACK_ACTIVATED_LOGO,
  },
  {
    id: FLOW_IDS.needGuidance,
    titleLineOne: translatorFunction?.('builder.in_app_abandonment.need_guidance.title_line_one'),
    titleLineTwo: translatorFunction?.('builder.in_app_abandonment.need_guidance.title_line_two'),
    logo: NEED_GUIDANCE_LOGO,
    logoClicked: NEED_GUIDANCE_ACTIVATED_LOGO,
  },
  {
    id: FLOW_IDS.dontLikeTheService,
    titleLineOne: translatorFunction?.(
      'builder.in_app_abandonment.dont_like_service.title_line_one',
    ),
    titleLineTwo: translatorFunction?.(
      'builder.in_app_abandonment.dont_like_service.title_line_two',
    ),
    logo: DONT_LIKE_SERVICE_LOGO,
    logoClicked: DONT_LIKE_SERVICE_ACTIVATED_LOGO,
  },
]

export const getFlow = (cardId: number, translatorFunction?: TranslatorFunction) =>
  ABANDONMENT_RESUME_FLOWS(translatorFunction).find(elm => elm.id === cardId)

export const HAS_ABANDONMENT_RESUME_MODAL_BEEN_SHOW = 'HAS_ABANDONMENT_RESUME_MODAL_BEEN_SHOW'
