import styled from 'styled-components'
import { FeatureIcon as DefaultFeatureIcon } from 'builder/components/FeatureIcon'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Icon24 from 'builder/components/Icon'
import Typography, { FontWeights } from 'builder/styles/typography'
import { CareerProfileLinkGuard } from '../CareerProfileLinkGuard'

export const ContainerLink = styled(CareerProfileLinkGuard)`
  display: flex;
  padding: 4px;
  gap: 12px;
  align-items: center;
  will-change: color;
  transition: color 0.1s ease-out;

  ${Media.Hover`
    &:hover {
      color: ${Colors.Blue60};
    }
  `}
`

export const FeatureIcon = styled(DefaultFeatureIcon)`
  width: 32px;
  height: 32px;
`

export const Text = styled.div`
  flex: auto;
`

export const LoadingBadge = styled(Icon24.Completeness50Percent)`
  color: ${Colors.Neutral30};
`

export const NewBadge = styled.div`
  ${Typography.Tiny};
  ${FontWeights.DemiBold};
  padding: 2px 6px;
  border-radius: 100px;
  color: ${Colors.Blue50};
  background-color: ${Colors.Blue50}1A;
`
