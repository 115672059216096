import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { throttle } from 'lodash'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { ITabsAction } from '../../types'
import BookCoachButton from '../Tabs/BookCoachButton'
import MobileTabsMenu from './MobileTabsMenu'
import * as Styled from './styles'

interface IMobileTabsProps {
  selectedIndex: number
  activeTabTitle: string
  setIsMenuVisible: Dispatch<SetStateAction<boolean>>
  isMenuVisible: boolean
  tabsActions: ITabsAction[]
  handleTabClick: (arg: number) => void
}

export const MobileTabs = ({
  selectedIndex,
  activeTabTitle,
  isMenuVisible,
  setIsMenuVisible,
  tabsActions,
  handleTabClick,
}: IMobileTabsProps) => {
  const { features } = useFeaturesConfig()
  const [isSticky, setIsSticky] = useState(false)

  // Convert from static to fixed on scroll
  useEffect(() => {
    const handleScroll = throttle(() => {
      if (window.scrollY > 75) setIsSticky(true)
      else setIsSticky(false)
    }, 100)

    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const { isPhone } = useMediaQueries()

  const onClose = () => setIsMenuVisible(false)

  // Reusable title and arrow container
  const renderTabTitle = () => {
    return (
      <>
        <Styled.TabTitle isSticky={isSticky}>{activeTabTitle}</Styled.TabTitle>
        <Styled.IconContainer>
          <Styled.ArrowIcon />
        </Styled.IconContainer>
      </>
    )
  }

  if (!isPhone) return null

  return (
    <Styled.MainContainer>
      <Styled.MobileTabsContainer onClick={() => setIsMenuVisible(true)}>
        {renderTabTitle()}
      </Styled.MobileTabsContainer>
      <Styled.MobileStickyTabsContainer isSticky={isSticky} onClick={() => setIsMenuVisible(true)}>
        {renderTabTitle()}
      </Styled.MobileStickyTabsContainer>
      {isMenuVisible && (
        <MobileTabsMenu
          tabsActions={tabsActions}
          onClose={onClose}
          selectedIndex={selectedIndex}
          handleTabClick={handleTabClick}
        />
      )}
      {features?.careerCoaching && <BookCoachButton />}
    </Styled.MainContainer>
  )
}
