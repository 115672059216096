import Colors from 'builder/styles/colors'
import { Circle, CompactWrapper, ProgressText, ScoreText } from './styles'

interface Props {
  score: number
}

const CompactProgressBar = ({ score }: Props) => {
  return (
    <CompactWrapper>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="16" cy="16" r="15" stroke={Colors.Blue20} strokeWidth="2" fill="none" />
        <ProgressText x="16" y="16" textAnchor="middle" dominantBaseline="central">
          <ScoreText>{score}</ScoreText>
        </ProgressText>
        <Circle
          d="M16 1C19.6526 1 23.1798 2.33279 25.9197 4.74833C28.6596 7.16387 30.4239 10.4962 30.8817 14.12"
          stroke={Colors.Blue50}
          strokeWidth="2"
          fill="none"
          score={score}
          cx="16"
          cy="16"
          r="15"
          transform="rotate(-90 16 16)"
          circleCir={Math.PI * 2 * 15}
        />
      </svg>
    </CompactWrapper>
  )
}

export default CompactProgressBar
