import * as React from 'react'
import * as Styled from './styles'
import { BadgeProps } from './types/types'

type Props = {
  children?: React.ReactNode
} & BadgeProps

export const Badge = ({ children, size = 'small', variant, dark, ...props }: Props) => {
  const style = {
    '--badge-text-color': dark ? `var(--white)` : `var(--${variant}-50)`,
    '--badge-bg-color': `var(--${variant}-${dark ? 40 : 10})`,
  } as React.CSSProperties

  return (
    <Styled.Wrapper style={style} size={size} {...props}>
      {children}
    </Styled.Wrapper>
  )
}
