import Avatar from './Avatar'

import { View, ViewContent, ViewTitle, ViewSubtitle, ViewInfo, TextPlaceholder } from './styles'

const HistoryViewPlaceholder = () => {
  return (
    <View>
      <Avatar />
      <ViewContent>
        <ViewTitle>
          <TextPlaceholder size={20} />
        </ViewTitle>
        <ViewSubtitle>
          <TextPlaceholder size={30} />
        </ViewSubtitle>
      </ViewContent>
      <ViewInfo>
        <ViewTitle>
          <TextPlaceholder size={15} />
        </ViewTitle>
        <ViewSubtitle>
          <TextPlaceholder size={10} />
        </ViewSubtitle>
      </ViewInfo>
    </View>
  )
}

export default HistoryViewPlaceholder
