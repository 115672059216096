import { ResumeEditorStore } from 'builder/modules/resumeEditor'
import { GeneralEditorStore } from 'builder/modules/generalEditor'

import EditorWithContent from './EditorWithContent'
import EditorWithoutContent from './EditorWithoutContent'
interface Props {
  editorState: GeneralEditorStore
  resumeState: ResumeEditorStore
}

const ShokumukeirekishoEditor = (props: Props): JSX.Element => {
  const { editorState, resumeState } = props
  const { resumeTemplates, levels } = editorState
  const resume = resumeState.resume && { ...resumeState.resume }
  const { length } = resumeTemplates
  const resumeDoesNotExist = !resume || length === 0

  if (resumeDoesNotExist) {
    return <EditorWithoutContent />
  }
  return <EditorWithContent {...{ levels, resume }} />
}
export default ShokumukeirekishoEditor
