import { AutoApplyStatus } from 'builder/modules/jobTracking'
import { i18n } from 'builder/utils/i18n'
import { selectors as jobSearchSelectors } from 'builder/modules/jobSearch'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import {
  AutoApplyLabel,
  ApplyStatusIcon,
  AutoApplyLabelContent,
  AutoApplyTooltipContent,
  AutoApplyTooltip,
} from './styles'
import CircleCheckSvg from './assets/circleCheck.svg'
import CircleClockSvg from './assets/circleClock.svg'
import CircleErrorSvg from './assets/circleError.svg'

export const AutoApplyStatusLabel = ({
  autoApplyStatus,
  ...rest
}: {
  autoApplyStatus: AutoApplyStatus
  setAllowOverflow?: ((val: boolean) => void) | undefined
}) => {
  const autoApplyBubbles = useTypedSelector(jobSearchSelectors.autoApplyBubbles)

  let autoApplyStatusIcon = CircleClockSvg
  if (autoApplyStatus === 'processing') {
    autoApplyStatusIcon = CircleClockSvg
  }
  if (autoApplyStatus === 'auto_applied') {
    autoApplyStatusIcon = CircleCheckSvg
  }
  if (autoApplyStatus === 'error') {
    autoApplyStatusIcon = CircleErrorSvg
  }

  if (autoApplyBubbles.running) {
    return null
  }
  return (
    <AutoApplyLabel autoApplyStatus={autoApplyStatus} {...rest}>
      <AutoApplyTooltip
        placement="bottom"
        content={
          <AutoApplyTooltipContent>
            {i18n.t(`builder.job_tracking.auto_apply.tooltip.${autoApplyStatus}`)}
          </AutoApplyTooltipContent>
        }
      >
        <AutoApplyLabelContent>
          <ApplyStatusIcon src={autoApplyStatusIcon} />
          {i18n.t(`builder.job_tracking.auto_apply.labels.${autoApplyStatus}`)}
        </AutoApplyLabelContent>
      </AutoApplyTooltip>
    </AutoApplyLabel>
  )
}
