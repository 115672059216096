import { Dispatch, SetStateAction, useMemo } from 'react'
import ModalOverlay from 'builder/components/ModalOverlay'
import { Icon20 } from 'builder/components/Icon'
import { i18n } from 'builder/utils/i18n'
import * as Styled from './styles'
import { PROMOTO_BANNER_STEPS } from './constants'

interface IPromoteBannerModalProps {
  isBannerVisible: boolean
  onClose: () => void
  currentStep: number
  setCurrentStep: Dispatch<SetStateAction<number>>
  steps?: { imgUrl: string; title: string; subtitle: string; caption: string }[]
  lastStepClick?: () => void
}

export const PromoteBannerModal = ({
  onClose,
  isBannerVisible,
  currentStep,
  setCurrentStep,
  steps = PROMOTO_BANNER_STEPS,
  lastStepClick,
}: IPromoteBannerModalProps) => {
  const currStepDetails = steps[currentStep]
  const isLastStep = useMemo(() => currentStep === steps.length - 1, [currentStep, steps])

  const handlePrimaryClick = () => {
    if (isLastStep) {
      window.open(
        'https://chrome.google.com/webstore/detail/resumeio-job-tracker/aelljdlolpakdoeongoklpkheedfjkio',
        '_blank',
      )
      lastStepClick?.()
    } else setCurrentStep(prev => prev + 1)
  }

  if (!isBannerVisible) return null
  const subtile =
    currStepDetails.subtitle !== '' ? i18n.t(currStepDetails.subtitle) : currStepDetails.subtitle

  return (
    <ModalOverlay contentSlideDuration={150} onClick={onClose}>
      <Styled.PromoteBannerContainer>
        <Styled.CloseContainer onClick={onClose}>
          <Icon20.Close />
        </Styled.CloseContainer>
        <>
          <Styled.VisualContainer>
            <Styled.Image src={currStepDetails.imgUrl} alt={subtile} />
          </Styled.VisualContainer>
          <Styled.BottomContainer>
            <Styled.ContentContainer>
              <Styled.Caption>{i18n.t(currStepDetails.caption)}</Styled.Caption>
              <Styled.Title>{i18n.t(currStepDetails.title)}</Styled.Title>
              <Styled.Description>{subtile}</Styled.Description>
            </Styled.ContentContainer>
            <Styled.ControlsContainer>
              <Styled.DotsContainer>
                {steps.map((_, index) => (
                  <Styled.Dot key={index} isActive={index === currentStep} />
                ))}
              </Styled.DotsContainer>

              <Styled.PrimaryButton onClick={handlePrimaryClick} isLastStep={isLastStep}>
                {isLastStep ? 'Install Chrome Extension' : 'Next'}
              </Styled.PrimaryButton>
            </Styled.ControlsContainer>
          </Styled.BottomContainer>
        </>
      </Styled.PromoteBannerContainer>
    </ModalOverlay>
  )
}
