import { useEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'

import { useI18n } from 'builder/hooks/useI18n'
import useWebsiteHost from 'builder/components/CIO-Dasboard-2.0/hooks/useWebsiteHost'

import { ButtonTheme } from 'builder/components/Button'
import JOBS_IMG from '../assets/job-card.png'
import {
  Container,
  ImageContainer,
  InfoContainer,
  Image,
  TextContainer,
  Header,
  Subheader,
  ActionButton,
  ActionsContainer,
} from './styles'

const TRANSLATION = 'builder.job_search.chrome_extension.empty_state'

type Props = {
  hideBanner: () => void
  getExtension: () => void
}

export const ChromeExtensionEmptyState = ({ hideBanner, getExtension }: Props) => {
  const { i18n } = useI18n()
  const { getHost } = useWebsiteHost()

  useEffect(() => {
    trackInternalEvent('view_banner_chrome', {
      host: getHost,
      label: 'job_search',
      source: 'empty_state',
    })
  }, [])

  return (
    <Container>
      <InfoContainer>
        <ImageContainer>
          <Image src={JOBS_IMG} />
        </ImageContainer>

        <TextContainer>
          <Header>{i18n.t(`${TRANSLATION}.header`)}</Header>
          <Subheader>{i18n.t(`${TRANSLATION}.sub_header`)}</Subheader>
        </TextContainer>
      </InfoContainer>
      <ActionsContainer>
        <ActionButton onClick={hideBanner} theme={ButtonTheme.ghost}>
          {i18n.t(`${TRANSLATION}.cancel_action`)}
        </ActionButton>
        <ActionButton onClick={getExtension}>
          {i18n.t(`${TRANSLATION}.approve_action`)}
        </ActionButton>
      </ActionsContainer>
    </Container>
  )
}
