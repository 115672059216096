import { Icon24 } from 'builder/components/Icon'
import { Panel } from './types'

export const panels: Panel[] = [
  {
    name: 'dashboard',
    Icon: Icon24.Home,
    to: '/',
  },
]
