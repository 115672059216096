import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useI18n } from 'builder/hooks/useI18n'
import Button, { ButtonTheme } from 'builder/components/Button'
import { actions } from 'builder/modules/careerProfile'
import Icon24 from 'builder/components/Icon'
import { Container, TextContainer, Description, Header, ButtonsContainer } from './styles'

export const ErrorBanner = () => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onRetakeAssessment = () => {
    dispatch(actions.setInitialChatState())
    navigate(`/career-profile/chat`)
  }

  const onRetry = () => {
    dispatch(actions.postChatbotAnswers())
  }

  return (
    <Container>
      <TextContainer>
        <Header>
          <Icon24.Error2 /> {i18n.t(`builder.career_profile.status_modal.failure.title`)}
        </Header>
        <Description>{i18n.t(`builder.career_profile.status_modal.failure.subtitle`)}</Description>
      </TextContainer>
      <ButtonsContainer>
        <Button theme={ButtonTheme.alternative} onClick={onRetry}>
          {i18n.t('builder.career_profile.status_modal.try_again')}
        </Button>
        <Button theme={ButtonTheme.ghost} onClick={onRetakeAssessment}>
          {i18n.t('builder.career_profile.status_modal.retake_assessment')}
        </Button>
      </ButtonsContainer>
    </Container>
  )
}
