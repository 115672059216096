import { useDispatch } from 'react-redux'
import { DateRangePickerValue } from 'packages/types'
import {
  AIResumeEducationCardData,
  AIResumeWorkExpCardData,
  actions,
} from 'builder/modules/resumeEditor'
import { AIResumeQuestionnaireCardType } from 'builder/modules/constants'

type QuestionnaireCard = AIResumeWorkExpCardData | AIResumeEducationCardData

const useQuestionnaireCard = (
  cardType: AIResumeQuestionnaireCardType,
  cardData: QuestionnaireCard,
) => {
  const dispatch = useDispatch()

  const updateSimpleField = (property: string, value: string) => {
    dispatch(
      actions.setQuestionnaireCardData({
        cardType,
        data: {
          ...cardData,
          [property]: value,
        },
      }),
    )
  }

  const updateDateRange = (value: DateRangePickerValue) => {
    dispatch(
      actions.setQuestionnaireCardData({
        cardType,
        data: {
          ...cardData,
          date: {
            ...cardData.date,
            ...value,
          },
        },
      }),
    )
  }

  const onEditClick = (emptyCardsIds: string[] = []) => {
    // Uncollapse this card because we want to edit it
    dispatch(
      actions.setQuestionnaireCardData({
        cardType,
        data: {
          ...cardData,
          isCollapsed: false,
        },
      }),
    )

    // Delete any empty cards
    for (const id of emptyCardsIds) {
      dispatch(
        actions.deleteQuestionnaireCard({
          cardType,
          deleteCardId: id,
        }),
      )
    }

    // Collapse all the cards except the one we're editing
    const excludeCards = [cardData.id]
    dispatch(
      actions.collapseQuestionnaireCards({
        cardType,
        excludeCardsIds: excludeCards,
      }),
    )
  }

  const onDelete = () => {
    dispatch(
      actions.deleteQuestionnaireCard({
        cardType,
        deleteCardId: cardData.id,
      }),
    )
  }

  return {
    updateDateRange,
    updateSimpleField,
    onEditClick,
    onDelete,
  }
}

export default useQuestionnaireCard
