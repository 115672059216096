import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Spinner } from 'builder/components/Spinner'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: ${Colors.Neutral10};
`

export const Content = styled.div`
  border-radius: 6px;
  background-color: ${Colors.White};
  width: 100%;
  max-width: 544px;
  padding: 48px 32px 56px;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const StyledSpinner = styled(Spinner)`
  margin-bottom: 12px;
`

export const Title = styled.h2`
  ${Typography.S};
  ${FontWeights.DemiBold};
  margin-bottom: 8px;
`

export const Description = styled.div``
