import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Mixins from 'builder/styles/mixins'
import * as Grid from 'builder/components/Grid'
import { Badge } from 'builder/components/Badge'

export const Container = styled.div`
  flex-grow: 1;
  width: 100%;
  background-color: ${Colors.Neutral10};
  padding: 60px 0 80px;

  ${Media.Phone`
    padding: 40px 0 60px;
  `};
`

export const SectionContainer = styled.div`
  background-color: ${Colors.White};
  ${Mixins.Shadow};
  margin-bottom: 48px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const SectionTitle = styled.div`
  padding: 25px 40px;
  background-color: ${Colors.Neutral100};
  color: ${Colors.White};
  ${Typography.M};
  font-weight: 600;
`

export const SectionContent = styled.div`
  padding: 32px 40px 48px;
`

export const Label = styled.div`
  display: flex;
  align-items: center;
  ${Typography.Caption};
  font-weight: 600;
  margin-bottom: 16px;
  white-space: nowrap;

  &:after {
    content: '';
    height: 1px;
    margin-left: 8px;
    background-color: ${Colors.Neutral20};
    flex-grow: 1;
  }
`

export const Group = styled.div`
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const ItemName = styled.div`
  margin-top: 8px;
  ${Typography.Tiny};
  color: ${Colors.Neutral50};
`

export const ComponentPreview = styled.div`
  max-width: 400px;

  & + & {
    margin-top: 16px;
  }
`

export const GridItem = styled(Grid.Cell)`
  min-height: 128px;
  background: #dbdeff;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const BadgeStyled = styled(Badge)`
  margin: 0 5px 5px 0;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: 10px;
  }
`

export const Row = styled.div`
  display: flex;

  > * + * {
    margin-left: 10px;
  }
`
