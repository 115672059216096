import styled, { css } from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Icon from 'builder/components/Icon'

export const Title = styled.div`
  ${Typography.XL};
  font-weight: 700;
  color: ${Colors.Blue50};
  margin-bottom: 12px;

  ${Media.Phone`
    width: 100%;
    text-align: left;
  `}
`

export const Subtitle = styled.div`
  margin-bottom: 32px;
  text-align: center;

  ${Media.Phone`
    margin-bottom: 24px;
    text-align: left;
    width: 100%;
  `}
`
type ButtonsProps = {
  altMod?: boolean
}

export const Buttons = styled.div<ButtonsProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: ${Colors.Neutral10};
  border-radius: 8px;

  &:not(:last-child) {
    margin-bottom: 48px;
  }

  ${Media.Phone`
    max-width: 100%;
    margin-bottom: 32px;
  `};
`

type SocialButtonProps = {
  altMod?: boolean
}

export const SocialNetwork = styled.div`
  display: flex;
  align-items: center;
`

export const NetworkIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`

export const ChevronIcon = styled(Icon.Chevron)`
  color: ${Colors.Blue50};
`

type ControlButtonsProps = {
  justifyContent?: 'center' | 'space-between'
}

export const ControlButtons = styled.div<ControlButtonsProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent = 'space-between' }) => justifyContent};
`

export const SocialButton = styled.a<SocialButtonProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 32px;
  border-bottom: 1px solid ${Colors.Neutral20};
  transition: background-color 0.1s ease, color 0.1s ease, border-color 0.1s ease;
  cursor: pointer;

  &:first-child {
    border-radius: 8px 8px 0 0;
  }

  &:last-child {
    border: none;
    margin: 0;
    border-radius: 0 0 8px 8px;
  }

  &:hover {
    color: ${Colors.Blue50};
    background-color: ${hexToRgba(Colors.Blue20, 0.5)};
  }

  ${({ altMod }) =>
    altMod &&
    css`
      border: 1px solid ${Colors.Neutral20};
      margin-bottom: 8px;
      border-radius: 4px;
      justify-content: center;
      font-weight: 600;
      padding: 12px 32px;

      &:first-child {
        border-radius: 4px;
      }

      &:last-child {
        border: 1px solid ${Colors.Neutral20};
        margin-bottom: 0;
        border-radius: 4px;
      }

      &:hover {
        color: ${Colors.Blue50};
        background: none;
        border-color: ${Colors.Blue50};
      }

      ${NetworkIcon} {
        margin-right: 8px;
      }
    `}
`
