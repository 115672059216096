import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Sizes from 'builder/styles/sizes'

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  border-radius: ${Sizes.S};
  padding: ${Sizes.L} ${Sizes.L} ${Sizes.XL};
  gap: ${Sizes.S};
  background-color: ${Colors.Indigo10};
  align-items: center;
`

export const Body = styled.div`
  max-width: 544px;
  display: flex;
  flex-flow: column;
  gap: ${Sizes.XS};
  text-align: center;
  align-items: center;
`

export const Image = styled.img`
  height: 140px;
`

export const Title = styled.div`
  ${Typography.Subhead};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral100};
`

export const Description = styled.div`
  color: ${Colors.Neutral60};
`
