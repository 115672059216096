import { memo } from 'react'
import Anchor from '../Anchor'
import PowerStatement from './PowerStatement'
interface Props {
  sectionId: string
}

const PowerStatementSection = (props: Props): JSX.Element => {
  const { sectionId } = props
  return (
    <Anchor id={sectionId} scrollOffsetTop={70}>
      <PowerStatement />
    </Anchor>
  )
}

export default memo(PowerStatementSection)
