import { FC } from 'react'
import {
  CareerInsightsPageType,
  CareerInsightsProcessingStatus,
  selectors,
} from 'builder/modules/careerProfile'
import { useI18n } from 'builder/hooks/useI18n'
import { useCareerInsightsStatus } from 'builder/components/CareerInsights'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import Tooltip from 'builder/components/Tooltip'
import { ContainerLink, FeatureIcon, LoadingBadge, NewBadge, Text } from './styles'

const translationKey = 'builder.career_profile.navigation_links'

export const CareerProfileLink: FC<{
  type: CareerInsightsPageType
  canHavePendingBadge?: boolean
  canHaveNewBadge?: boolean
}> = ({ type, canHavePendingBadge = true, canHaveNewBadge = true }) => {
  const { i18n } = useI18n()
  const status = useCareerInsightsStatus()
  const isPageMarkedAsNew = useTypedSelector(state => selectors.isPageMarkedAsNew(state, type))
  const isPending = canHavePendingBadge && status === CareerInsightsProcessingStatus.pending

  const loadingBadge = isPending ? <LoadingBadge /> : null
  const newBadge = canHaveNewBadge && isPageMarkedAsNew ? <NewBadge>New</NewBadge> : null

  let resultComponent: JSX.Element | null = null

  if (type === 'insights') {
    resultComponent = (
      <ContainerLink type={type} eventCode="click_profile_service">
        <FeatureIcon name="SalaryAnalyzerV2" />
        <Text>{i18n.t(`${translationKey}.career_insights`)}</Text>
        {loadingBadge || newBadge}
      </ContainerLink>
    )
  }

  if (type === 'pitch') {
    resultComponent = (
      <ContainerLink type={type} eventCode="click_profile_service">
        <FeatureIcon name="Pitch" />
        <Text>{i18n.t(`${translationKey}.professional_pitch`)}</Text>
        {loadingBadge || newBadge}
      </ContainerLink>
    )
  }

  if (type === 'cover-letter') {
    resultComponent = (
      <ContainerLink type={type} eventCode="click_profile_service">
        <FeatureIcon name="ResumeAndCoverLetters" />
        <Text>{i18n.t(`${translationKey}.cover_letter`)}</Text>
        {loadingBadge || newBadge}
      </ContainerLink>
    )
  }

  if (!resultComponent) {
    return null
  }

  return isPending ? (
    <Tooltip
      value={i18n.t(`builder.career_profile.loader.description_raw`)}
      width={280}
      textAlign="center"
      multiline={true}
    >
      {resultComponent}
    </Tooltip>
  ) : (
    resultComponent
  )
}
