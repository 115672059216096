import styled from 'styled-components'
import { Container as BaseContainer } from 'builder/components/Container'
import Media from 'builder/styles/media'
import { Typography, FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import { HorizontalTransitionFade } from 'builder/components/HorizontalTransitionFade'

export const Wrapper = styled.div`
  padding-bottom: 80px;
  overflow: hidden;

  ${Media.Tablet`
    padding-bottom: 48px;
  `};
`

export const Container = styled(HorizontalTransitionFade)``

export const ContentContainer = styled(BaseContainer)`
  margin-top: 48px;
  display: flex;
  gap: 10%;
  justify-content: space-between;

  ${Media.Tablet`
    margin-top: 32px;
    margin-bottom: 64px;
  `};
`

export const LeftColumn = styled.div`
  flex-grow: 2;
`

export const RightColumn = styled.div`
  min-width: 350px;
`
export const TabletJobSummary = styled.div`
  margin-top: 32px;
`

export const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 36px;
  margin-bottom: 36px;
  border-bottom: 1px solid ${Colors.Neutral15};
`

export const CompanyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`

export const CompanyJobs = styled.div`
  color: ${Colors.Neutral50};
  white-space: nowrap;
`

export const Value = styled.div`
  ${Typography.M};
`

export const Title = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};
`

export const Label = styled.span`
  display: inline-block;
  background: ${Colors.Neutral10};
  ${Typography.Caption};
  padding: 6px 10px;
  margin-right: 12px;
  margin-bottom: 12px;
  border-radius: 4px;
  white-space: nowrap;
`

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto 0;
`
