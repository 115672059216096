import { useCallback } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useDispatch } from 'react-redux'
import { useI18n } from 'builder/hooks/useI18n'
import { usePitchTextParts } from 'builder/components/CareerInsights'
import { useUser } from 'builder/hooks/useUser'
import { actions } from 'builder/modules/careerProfile'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import { AssessmentResultSample } from '../AssessmentResultSample'

const translationKey = 'builder.career_profile.professional_pitch'

export const ProfessionalPitchView = () => {
  const { i18n } = useI18n()
  const pitchTextParts = usePitchTextParts()
  const user = useUser()
  const dispatch = useDispatch()

  const onCopyClick = useCallback(() => {
    trackInternalEvent('click_copy_professional_pitch_page')
  }, [])

  useEffectOnMount(() => {
    trackInternalEvent('enter_professional_pitch_page')
    dispatch(actions.setSeenPage('pitch'))
  })

  return (
    <AssessmentResultSample
      title={i18n.t(`${translationKey}.title`)}
      description={i18n.t(`${translationKey}.description`, { name: user?.firstName })}
      textParts={pitchTextParts}
      snackbarOnCopyText={i18n.t(`${translationKey}.snackbar_copy_text`)}
      onCopyClick={onCopyClick}
    />
  )
}
