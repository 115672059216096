import { useState, useCallback } from 'react'
import type { Optional } from '@rio/types'

type InputObject = Record<string, Optional<string>>
type OutputObject<I> = { [K in keyof I]: string }

// Converts all `null` and `undefined` object values to an empty string
const normalize = <T extends InputObject>(object: T) => {
  const normalized: InputObject = {}
  for (let key in object) normalized[key] = object[key] == null ? '' : object[key]
  return normalized as OutputObject<T>
}

export type CustomElement = {
  target: {
    name: string
    value: string
  }
}

// Simplifies form fields routine
export function useFields<T extends InputObject>(
  initialState: T,
): [OutputObject<T>, (e: React.ChangeEvent<HTMLInputElement> | CustomElement) => void] {
  const [fields, setFields] = useState<OutputObject<T>>(() => normalize(initialState))

  const handleFieldChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | CustomElement) => {
      const { name, value } = event.target
      setFields(values => ({ ...values, [name]: value || '' }))
    },
    [],
  )

  return [fields, handleFieldChange]
}
