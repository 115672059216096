import EditorField from 'builder/components/EditorField'
import { TextField } from 'builder/components/TextField'
import { useI18n } from 'builder/hooks/useI18n'
import { actions } from 'builder/modules/resumeEditor'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

export function FullName(props) {
  const namePronunciation = useSelector(state => state.resumeEditor.resume.namePronunciation)
  const dispatch = useDispatch()
  const { updateSimpleField } = actions
  const { i18n } = useI18n()

  const handleChange = React.useCallback(
    event => {
      const { name, value } = event.target
      dispatch(updateSimpleField({ name, value, debounce: true }))
    },
    [dispatch, updateSimpleField],
  )

  return (
    <>
      {/* First name */}
      <EditorField>
        <TextField
          label={i18n.t('builder.resume_editor.name_pronunciation')}
          value={namePronunciation || ''}
          name="namePronunciation"
          onChange={handleChange}
        />
      </EditorField>
    </>
  )
}
