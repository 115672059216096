import EventEmitter from 'eventemitter3'

let instance = null

class PreviewReloadService extends EventEmitter {
  constructor() {
    super()
    if (!instance) {
      instance = this
    }
    this.RESUME_PREVIEW_RELOAD_EVENT = 'RESUME_PREVIEW_RELOAD_EVENT'
    return instance
  }

  reloadResume(resume, reload) {
    instance.emit(this.RESUME_PREVIEW_RELOAD_EVENT, { resume, reload })
  }
}

export default new PreviewReloadService()
