import { useCallback } from 'react'
import Colors from 'builder/styles/colors'
import { i18n as I18n } from 'builder/utils/i18n'
import JOB_OFFER_IMAGE from '../../../../../app/assets/images/subscription_cancellation/card_jobOffer.png'
import DISCOUNT_IMAGE from '../../../../../app/assets/images/subscription_cancellation/card_discount.png'
import FEATURE_PROBLEM_IMAGE from '../../../../../app/assets/images/subscription_cancellation/card_featureProblem.png'
import DREAM_JOB_IMAGE from '../../../../../app/assets/images/subscription_cancellation/card_dreamJob.png'
import OTHER_REASONS_IMAGE from '../../../../../app/assets/images/subscription_cancellation/card_otherReasons.png'
import { Cards, PromotionTopic } from '../common/types'

const usePromotionCardValues = () => {
  const getCardTitle = useCallback((cardType: Cards) => {
    switch (cardType) {
      case Cards.JobOffer:
        return I18n.t('builder.subscription_cancellation.promotion_cards.job_offer')
      case Cards.Discount:
        return I18n.t('builder.subscription_cancellation.promotion_cards.discount')
      case Cards.FeatureOrProblem:
        return I18n.t('builder.subscription_cancellation.promotion_cards.feature')
      case Cards.DreamJob:
        return I18n.t('builder.subscription_cancellation.promotion_cards.dont_need')
      case Cards.OtherReasons:
        return I18n.t('builder.subscription_cancellation.promotion_cards.other')
      case Cards.DontNeedPromos:
        return I18n.t('builder.subscription_cancellation.promotion_cards.dont_need')
      default:
        return ''
    }
  }, [])

  const getCardImage = useCallback((cardType: Cards) => {
    switch (cardType) {
      case Cards.JobOffer:
        return JOB_OFFER_IMAGE
      case Cards.Discount:
        return DISCOUNT_IMAGE
      case Cards.FeatureOrProblem:
        return FEATURE_PROBLEM_IMAGE
      case Cards.DreamJob:
        return DREAM_JOB_IMAGE
      case Cards.OtherReasons:
        return OTHER_REASONS_IMAGE
      case Cards.DontNeedPromos:
        return DREAM_JOB_IMAGE
      default:
        return ''
    }
  }, [])

  const getCardStyles = useCallback((topic: PromotionTopic) => {
    switch (topic) {
      case Cards.JobOffer:
        return {
          backgroundColor: `${Colors.Blue10}`,
          borderColor: `${Colors.Blue20}`,
          buttonColor: `${Colors.Blue50}`,
          buttonHoverColor: `${Colors.Blue60}`,
          noticeTextColor: `${Colors.Blue90}`,
        }
      case Cards.Discount:
        return {
          backgroundColor: `${Colors.Indigo10}`,
          borderColor: `${Colors.Indigo20}`,
          buttonColor: `${Colors.Indigo90}`,
          buttonHoverColor: `${Colors.Indigo100}`,
          noticeTextColor: `${Colors.Indigo90}`,
        }
      case Cards.FeatureOrProblem:
        return {
          backgroundColor: `${Colors.Beige10}`,
          borderColor: `${Colors.Beige20}`,
          buttonColor: `${Colors.Blue50}`,
          buttonHoverColor: `${Colors.Blue60}`,
          noticeTextColor: `${Colors.Beige90}`,
        }
      case Cards.DreamJob:
        return {
          backgroundColor: `${Colors.Green10}`,
          borderColor: `${Colors.Green20}`,
          buttonColor: `${Colors.Green50}`,
          buttonHoverColor: `${Colors.Green60}`,
          noticeTextColor: `${Colors.Green90}`,
        }
      case Cards.OtherReasons:
        return {
          backgroundColor: `${Colors.Beige10}`,
          borderColor: `${Colors.Beige20}`,
          buttonColor: `${Colors.Blue50}`,
          buttonHoverColor: `${Colors.Blue60}`,
          noticeTextColor: `${Colors.Beige90}`,
        }
      case Cards.DontNeedPromos:
        return {
          backgroundColor: `${Colors.Neutral5}`,
          borderColor: `${Colors.Beige20}`,
          buttonHoverColor: `${Colors.Neutral10}`,
          buttonBorderColor: `${Colors.Blue20}`,
          noticeTextColor: `${Colors.Beige90}`,
        }
      case 'submitContent':
        return {
          backgroundColor: `${Colors.Beige10}`,
          borderColor: '',
          noticeTextColor: '',
          buttonHoverColor: '',
          buttonColor: '',
        }
      case 'errorSubmit':
        return {
          backgroundColor: `${Colors.Beige10}`,
          borderColor: '',
          noticeTextColor: '',
          buttonHoverColor: '',
          buttonColor: '',
        }
      default:
        return {
          backgroundColor: '',
          borderColor: '',
          noticeTextColor: '',
          buttonHoverColor: '',
          buttonColor: '',
        }
    }
  }, [])

  return {
    getCardTitle,
    getCardImage,
    getCardStyles,
  }
}

export default usePromotionCardValues
