import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Scrollbar, Mousewheel } from 'swiper'
import { useDispatch, useSelector } from 'react-redux'
import { FeatureIcon } from 'builder/components/FeatureIcon'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as userSelectors, UserType } from 'builder/modules/user'
import { actions as uiActions } from 'builder/modules/ui'
import { selectors as interviewSelector } from 'builder/modules/interview/interviewModule'
import { useI18n } from 'builder/hooks/useI18n'
import { useDashboardStatusUtils } from 'builder/views/CareerProfile/hooks/useDashboardStatusUtils'
import { useConfig } from 'builder/hooks/useConfig'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import * as Styled from './styles'
import { IMiniAppsButton } from './types'

const crown = require('../../assets/Crown.svg')

SwiperCore.use([Navigation, Scrollbar, Mousewheel])

const JobIconLayout = () => {
  const navigate = useNavigate()
  const { isDesktop } = useMediaQueries()
  const dispatch = useDispatch()
  const userType = useTypedSelector(userSelectors.postPremium)
  const { i18n } = useI18n()
  const { features } = useFeaturesConfig()
  const isPostPremiumActive = userType === UserType.postPremium
  const { actionHandler: careerProfileNavigationHandler } = useDashboardStatusUtils()

  const interviewUrl = useSelector(interviewSelector.getInterviewUrl)
  const interviewDomainUrl = useSelector(interviewSelector.getInterviewDomainUrl)

  const [isHovered, setIsHovered] = useState(Array(3).fill(false))
  const config = useConfig()
  const isCareerProfileEnabled = config?.features.careerProfile

  const handleMouseEnter = (index: number) => {
    setIsHovered(prevState => {
      const newButton = [...prevState]
      newButton[index] = true
      return newButton
    })
  }

  const handleMouseLeave = (index: number) => {
    setIsHovered(prevState => {
      const newButton = [...prevState]
      newButton[index] = false
      return newButton
    })
  }

  let careerProfileConditionalBtn: IMiniAppsButton | null = null
  if (isCareerProfileEnabled) {
    careerProfileConditionalBtn = {
      index: 5,
      enabled: features.careerProfile,
      iconName: 'CareerAssessment',
      name: i18n.t('builder.dashboard.service_links.career_insights.name'),
      label: 'career_profile',
      isPremiumService: false,
      cioDomainUrl: 'https://career.io/app/career-profile/insights',
      action: careerProfileNavigationHandler,
    }
  } else {
    careerProfileConditionalBtn = {
      index: 5,
      enabled: features.salaryAnalyzer,
      iconName: 'SalaryAnalyzer',
      name: i18n.t('builder.dashboard.service_links.salary_analyzer.name'),
      label: 'salary_analyzer',
      isPremiumService: true,
      isPostPremiumActive: isPostPremiumActive,
      cioDomainUrl: 'https://career.io/app/offer-analyzer',
      action: () => navigate('/offer-analyzer'),
    }
  }

  const buttons: IMiniAppsButton[] = [
    {
      index: 0,
      enabled: true,
      iconName: 'Documents',
      name: i18n.t('builder.dashboard.service_links.documents.name'),
      label: 'documents',
      action: () => navigate('/resumes'),
    },
    {
      index: 1,
      enabled: features.jobSearch,
      iconName: 'JobSearch',
      name: i18n.t('builder.dashboard.service_links.job_search.name'),
      label: 'job_search',
      isPremiumService: true,
      cioDomainUrl: 'https://career.io/app/job-search?view=recommendation',
      action: () => navigate('/job-search'),
    },
    {
      index: 2,
      enabled: features.interviewSchool,
      iconName: 'InterviewPrep',
      name: i18n.t('builder.dashboard.service_links.interview_prep.name'),
      label: 'interview_prep',
      isPremiumService: true,
      isPostPremiumActive: isPostPremiumActive,
      cioDomainUrl: interviewDomainUrl,
      action: () => navigate(interviewUrl),
    },
    {
      index: 3,
      enabled: features.careerPlans,
      iconName: 'JobSearchStrategy',
      name: i18n.t('builder.dashboard.service_links.job_search_strategy.name'),
      label: 'job_search_strategy',
      isPremiumService: true,
      isPostPremiumActive: isPostPremiumActive,
      cioDomainUrl: 'https://career.io/app/career-plans/execute-a-job-search',
      action: () => navigate('/career-plans/execute-a-job-search'),
    },
    {
      index: 4,
      enabled: features.jobTracking,
      iconName: 'JobTracker',
      name: i18n.t('builder.dashboard.service_links.job_tracker.name'),
      label: 'job_tracking',
      isPremiumService: true,
      isPostPremiumActive: isPostPremiumActive,
      cioDomainUrl: 'https://career.io/app/job-tracking?view=recommendation',
      action: () => navigate('/job-tracking'),
    },
    careerProfileConditionalBtn,
  ]
  const showMiniApps = buttons.filter(button => button.enabled).length === 6

  const redirectToIndex = async (index: number) => {
    trackInternalEvent('click_dash_mini_app', { label: buttons?.[index]?.label })
    const service = buttons?.[index]

    if (service?.isPostPremiumActive) {
      dispatch(uiActions.setTrackJobPaywall(true))
    } else {
      service.action && service.action()
    }
  }

  const renderImage = (button: IMiniAppsButton) => {
    if (button?.isPostPremiumActive) {
      return crown
    }
  }

  const renderAppButton = (button: IMiniAppsButton) => {
    const imageSrc = renderImage(button)
    return (
      <Styled.Button
        key={button.index}
        onClick={() => redirectToIndex(button.index)}
        onMouseOver={() => handleMouseEnter(button.index)}
        onMouseOut={() => handleMouseLeave(button.index)}
        isHovered={isHovered[button.index]}
        isTabWidth={button.index}
      >
        <FeatureIcon name={button.iconName} />
        {imageSrc && <Styled.CrownContainer src={imageSrc} />}
        <Styled.Text isHovered={isHovered[button.index]}>{button.name}</Styled.Text>
      </Styled.Button>
    )
  }

  if (isDesktop) {
    return showMiniApps ? (
      <Styled.MiniAppsMainContainer>
        <Styled.MiniAppsContainer>
          {buttons.map(button => renderAppButton(button))}
        </Styled.MiniAppsContainer>
      </Styled.MiniAppsMainContainer>
    ) : (
      <></>
    )
  }

  return showMiniApps ? (
    <Styled.MiniAppsWrapper>
      <Swiper
        grabCursor
        slidesPerView="auto"
        navigation={{
          prevEl: '.career-goal-prev-button',
          nextEl: '.career-goal-next-button',
        }}
        direction="horizontal"
        scrollbar={true}
        mousewheel={true}
      >
        {buttons.map(button => (
          <SwiperSlide key={button.iconName}>{renderAppButton(button)}</SwiperSlide>
        ))}
      </Swiper>
    </Styled.MiniAppsWrapper>
  ) : (
    <></>
  )
}

export default JobIconLayout
