import Transition from 'react-transition-group/Transition'
import Button, { ButtonSize, ButtonTheme } from 'builder/components/Button'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { NonModalDialogProps } from '../../types'
import { Buttons, Content, Description, DialogContainer, Title, CloseButton } from './styles'

/**
 * Implementation of component from design system
 * @todo Implement 'progress' state
 */
export const NonModalDialog = (props: NonModalDialogProps) => {
  const {
    size = 'M',
    placement = 'bottom',
    position = 'fixed',
    title,
    description,
    customButtons,
    onOkButtonClick,
    okButtonText,
    onCancelButtonClick,
    cancelButtonText,
    isOpen,
    onCloseClick,
    onCloseAnimationEnd,
  } = props

  const { isPhone } = useMediaQueries()
  const hasButtons =
    (onOkButtonClick && okButtonText) || (onCancelButtonClick && cancelButtonText) || customButtons
  const buttonSize = isPhone || size === 'S' ? ButtonSize.small : ButtonSize.default
  const hasCloseButton = !!onCloseClick && (isPhone || size === 'S')

  return (
    <Transition
      in={isOpen}
      timeout={{
        enter: 100,
        exit: 300,
      }}
      mountOnEnter
      unmountOnExit
      onExited={onCloseAnimationEnd}
    >
      {state => (
        <DialogContainer
          $size={size}
          $placement={placement}
          $position={position}
          $transitionState={state}
          $hasCloseButton={hasCloseButton}
        >
          <Content>
            {title && <Title>{title}</Title>}
            {description && <Description>{description}</Description>}
          </Content>
          {hasButtons && (
            <Buttons>
              {customButtons || (
                <>
                  {onCancelButtonClick && cancelButtonText && (
                    <Button
                      onClick={onCancelButtonClick}
                      theme={ButtonTheme.ghost}
                      size={buttonSize}
                    >
                      {cancelButtonText}
                    </Button>
                  )}
                  {onOkButtonClick && okButtonText && (
                    <Button onClick={onOkButtonClick} theme={ButtonTheme.default} size={buttonSize}>
                      {okButtonText}
                    </Button>
                  )}
                </>
              )}
            </Buttons>
          )}
          {hasCloseButton && <CloseButton onClick={onCloseClick} />}
        </DialogContainer>
      )}
    </Transition>
  )
}
