import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors, actions } from 'builder/modules/careerProfile'
import { FetchStatuses } from 'builder/modules/constants'
import { selectors as userSelectors } from 'builder/modules/user'

export const useCareerInsightsLoadingEffect = () => {
  const dispatch = useDispatch()
  const isUserFetched = useTypedSelector(userSelectors.isFetched)
  const fetchCareerInsightsStatus = useTypedSelector(selectors.fetchCareerInsightsStatus)

  useEffect(() => {
    if (isUserFetched && fetchCareerInsightsStatus === FetchStatuses.notAsked) {
      dispatch(actions.startCareerInsightsPolling())
    }
  }, [isUserFetched, fetchCareerInsightsStatus, dispatch])
}
