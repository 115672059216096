import { useEffect, useState } from 'react'
import ModalOverlay from 'builder/components/ModalOverlay'
import { LocalStorageSignUpDataKeys } from 'builder/modules/signUp'
import ErrorLogger from 'builder/services/ErrorLogger'
import * as Styles from './styles'

import ModalHeader from './ModalHeader'
import ModalBody from './ModalBody'
import ModalFooter from './ModalFooter'

const CioRioInfoModal = () => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    try {
      let cioRioModalFlag = localStorage.getItem(
        LocalStorageSignUpDataKeys.SHOW_RIO_CIO_INFO_MODAL_FLAG,
      )
      cioRioModalFlag = cioRioModalFlag ? JSON.parse(cioRioModalFlag) : null
      if (cioRioModalFlag) {
        setIsOpen(true)
      }
      localStorage.removeItem(LocalStorageSignUpDataKeys.SHOW_RIO_CIO_INFO_MODAL_FLAG)
    } catch (error) {
      ErrorLogger.log(error)
    }
  }, [])

  const handleClose = () => {
    setIsOpen(false)
  }

  if (!isOpen) {
    return null
  }

  return (
    <ModalOverlay overlayFadeDuration={150} contentSlideDuration={150}>
      <Styles.Container>
        <ModalHeader handleClose={handleClose} />
        <Styles.DescriptionSection>
          <ModalBody />
          <ModalFooter handleClose={handleClose} />
        </Styles.DescriptionSection>
      </Styles.Container>
    </ModalOverlay>
  )
}

export default CioRioInfoModal
