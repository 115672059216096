import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { ButtonSize, ButtonTheme } from 'builder/components/Button'
import { actions } from 'builder/modules/careerCoaching'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as userSelectors } from 'builder/modules/user'

import { InnerContainer, DownloadFailedText, TryAgainButton } from './styles'

const ContentFailedToDownload = () => {
  const dispatch = useDispatch()
  const user = useTypedSelector(userSelectors.userData)
  const participantId = user?.id

  const tryAgain = useCallback(
    () => dispatch(actions.fetchCoachSessions({ participantId })),
    [dispatch, participantId],
  )

  return (
    <InnerContainer>
      <DownloadFailedText>It was not possible to download content</DownloadFailedText>
      <TryAgainButton theme={ButtonTheme.ghost} onClick={tryAgain} size={ButtonSize.small}>
        Try again
      </TryAgainButton>
    </InnerContainer>
  )
}

export default ContentFailedToDownload
