import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { Button } from 'builder/components/Button'

export const Container = styled.div<{ isSessions: boolean }>`
  margin: ${props => props.isSessions && '16px'};
`

export const SessionCardContainer = styled.div`
  transition: all 150ms ease-out;
  gap: 24px;
  display: flex;
  align-self: center;
  flex-direction: column;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
  transition: all 150ms ease-out;
`

export const ShowMoreButton = styled(Button)`
  background: ${Colors.Neutral5};
  color: ${Colors.Neutral50};
  transition: all 150ms ease-out;
  display: flex;
  padding: 16px 24px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 12px;
  cursor: pointer;

  &:hover {
    background: ${Colors.Neutral5};
    color: ${Colors.Neutral50};
  }
`
