import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { trackInternalEvent } from '@rio/tracking'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import * as SimpleForm from 'builder/components/SimpleForm'
import { useI18n } from 'builder/hooks/useI18n'
import { queryClient } from 'builder/components/Providers/Providers'
import { AutoApplyForm } from 'builder/modules/autoApply/types'
import { selectors } from 'builder/modules/autoApply/autoApplyModule'
import { Routes, StartCompleteScreenRoute, TotalPageCount } from '../../constants'
import { Container, FillWarning, LayoutContainer, Title, TitleWrapper } from '../styles'
import { ConfirmModal } from '../ConfirmModal/ConfirmModal'
import { usePatchForm } from '../../hooks/usePatchForm'
import { usePrompt } from '../../hooks/usePrompt'
import { FormContainer } from './styles'
import { JobPreferencesForm2 } from './JobPreferencesForm2'

const TRANSLATION = 'builder.auto_apply.form.job_preferences_2'

export const JobPreferencesForm2Container = () => {
  const formState = useSelector(selectors.form)
  const isEditMode = formState.autoApplyDataCompleted
  const form = useForm<
    Pick<
      AutoApplyForm,
      'countriesAuthorizedToWorkIn' | 'targetLocations' | 'jobPreferenceAttributes'
    >
  >({
    mode: 'onChange',
  })

  const { i18n } = useI18n()
  const navigate = useNavigate()
  const { isPromptVisible, handleCancel, handleConfirm } = usePrompt({
    when: true,
    ignoreParentRoute: isEditMode ? ['auto-apply', 'job-search'] : ['auto-apply'],
  })

  const { mutateAsync: patchForm, isLoading } = usePatchForm()

  const patchTargetLocation = () => {
    const locations = [...formState.targetLocations]
    const newTargetLocation = form.getValues('targetLocations')[0]
    locations.splice(0, 1, newTargetLocation)
    return locations
  }

  const onNextStep = async () => {
    const mapCountries = form.getValues('countriesAuthorizedToWorkIn').map(item => item.value)
    const data = {
      ...form.getValues(),
      countriesAuthorizedToWorkIn: mapCountries,
      targetLocations: patchTargetLocation(),
      autoApplyDataCompleted: true,
    }

    // @ts-expect-error countries
    await patchForm(data)

    if (!isEditMode) {
      queryClient.invalidateQueries(['useGetCareerProfile'])
    }

    const path = isEditMode
      ? '/job-search?view=recommendation'
      : `/auto-apply/${StartCompleteScreenRoute.COMPLETED}`

    navigate(path)
  }

  const onBackClick = () => {
    form.clearErrors()
    navigate(`/auto-apply/${Routes.JOB_PREFERENCES_1}`)
  }

  useEffect(() => {
    trackInternalEvent('aa_job_preferences_location', {
      label: 'total_job_search_solution',
    })
  }, [])

  useEffect(() => {
    const { countriesAuthorizedToWorkIn, jobPreferenceAttributes, targetLocations } = formState
    form.setValue('countriesAuthorizedToWorkIn', countriesAuthorizedToWorkIn)
    form.setValue('targetLocations', targetLocations)
    form.setValue('jobPreferenceAttributes', jobPreferenceAttributes)
  }, [form, formState])

  return (
    <LayoutContainer>
      <Container>
        <TitleWrapper>
          <Title>{i18n.t(`${TRANSLATION}.title`)}</Title>
          <FillWarning>{i18n.t(`${TRANSLATION}.sub_title`)}</FillWarning>
        </TitleWrapper>
        <FormContainer>
          <SimpleForm.Form
            submitButtonText={i18n.t(`${TRANSLATION}.next`)}
            onBackClick={onBackClick}
            onSubmit={form.handleSubmit(onNextStep)}
            isPending={isLoading}
            processIndicator={{
              totalStep: TotalPageCount,
              currentStep: 4,
            }}
          >
            <JobPreferencesForm2 form={form} />
          </SimpleForm.Form>
        </FormContainer>
      </Container>
      {isPromptVisible && <ConfirmModal onClose={handleCancel} onConfirm={handleConfirm} />}
    </LayoutContainer>
  )
}
