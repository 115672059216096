import { Tabs, Tab } from 'builder/components/Tabs'

interface Props {
  tabs: string[]
  activeTab: string
  onTabSelect: (item: string) => void
}

const CareerPageTabs = ({ tabs, activeTab, onTabSelect }: Props) => {
  return (
    <Tabs>
      {tabs.map(tab => (
        <Tab key={tab} isActive={tab === activeTab} onClick={() => onTabSelect(tab)}>
          {tab}
        </Tab>
      ))}
    </Tabs>
  )
}

export default CareerPageTabs
