import styled, { css } from 'styled-components'
import Media from 'builder/styles/media'

export const Container = styled.div<{ fullWidth?: boolean; quarterWidth?: boolean }>(
  ({ fullWidth, quarterWidth }) => css`
    flex: 0 0 ${fullWidth ? '100%' : quarterWidth ? 'calc(20% - 10px)' : 'calc(50% - 20px)'};
    margin-right: ${quarterWidth ? '20px' : '40px'};

    ${Media.Phone`
      flex: 0 0 100%;
      margin-right: 0;
      margin-bottom: 20px;
    `};

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
  `,
)
