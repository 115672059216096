import { useEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'

import { useI18n } from 'builder/hooks/useI18n'
import { useChromeExtension } from 'builder/hooks/useChromeExtension'
import useWebsiteHost from 'builder/components/CIO-Dasboard-2.0/hooks/useWebsiteHost'

import { ButtonTheme } from 'builder/components/Button'
import { useChromeExtensionBanner } from '../hooks/useChromeExtensionBanner'
import {
  Container,
  ImageContainer,
  InfoContainer,
  Image,
  TextContainer,
  Header,
  Subheader,
  ActionButton,
  ActionsContainer,
} from '../styles'
import JOBS_IMG from '../assets/job-details.png'

const TRANSLATION = 'builder.job_search.chrome_extension.job_details'

export const ChromeExtensionJobDetailsBanner = () => {
  const { i18n } = useI18n()
  const { getHost } = useWebsiteHost()
  const { isInstalled, canInstallExtension } = useChromeExtension()
  const { showBanner, hideBanner, getExtension } = useChromeExtensionBanner({ type: 'job_listing' })

  useEffect(() => {
    if (showBanner && !isInstalled && canInstallExtension) {
      trackInternalEvent('view_banner_chrome', {
        host: getHost,
        label: 'job_search',
        source: 'job_card',
      })
    }
  }, [])

  if (!showBanner || isInstalled || !canInstallExtension) {
    return null
  }

  return (
    <Container $isJobDetails>
      <InfoContainer $isJobDetails>
        <TextContainer $isJobDetails>
          <Header>{i18n.t(`${TRANSLATION}.header`)}</Header>
          <Subheader>{i18n.t(`${TRANSLATION}.sub_header`)}</Subheader>
        </TextContainer>
        <ActionsContainer>
          <ActionButton onClick={hideBanner} theme={ButtonTheme.ghost}>
            {i18n.t(`${TRANSLATION}.cancel_action`)}
          </ActionButton>
          <ActionButton onClick={getExtension}>
            {i18n.t(`${TRANSLATION}.approve_action`)}
          </ActionButton>
        </ActionsContainer>
      </InfoContainer>
      <ImageContainer>
        <Image src={JOBS_IMG} />
      </ImageContainer>
    </Container>
  )
}
