import { UserDocumentTemplate } from 'packages/types'
import { GeneralEditorStore } from 'builder/modules/generalEditor'
import { DocumentTypes, FORMATS } from 'builder/modules/constants'

export const isResume = (documentType: string): boolean => documentType === 'resume'

export function getTemplates(
  editorState: GeneralEditorStore,
  documentType: string,
): UserDocumentTemplate[] {
  return isResume(documentType) ? editorState.resumeTemplates : editorState.coverLetterTemplates
}

export function getUpdatedTemplateAndFormat(
  showOneDownloadButton: boolean,
  type: DocumentTypes,
  template: string,
  format: FORMATS,
) {
  // Free docx download will be controlled by the A/B test
  const downloadFreeDOCXTemplate =
    type === DocumentTypes.resume && showOneDownloadButton && format === FORMATS.txt
  // Overwrite the document template with the free DOCX template
  // if the user is downloading a free DOCX
  const updatedTemplate = downloadFreeDOCXTemplate ? 'athens_lite' : template
  const updatedFormat = downloadFreeDOCXTemplate ? FORMATS.docx : format

  return { updatedTemplate, updatedFormat }
}
