import styled, { css, keyframes } from 'styled-components'
import Button from 'builder/components/Button'
import { Tab } from 'builder/components/Tabs'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import { TabsContainer } from 'builder/components/Tabs/styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.Neutral20};
  ${TabsContainer} {
    border-bottom: none;
  }
  ${Media.Phone`
    margin-right: 20px;
  `};
  ${Media.Tablet`
    margin-right: 20px;
  `};
  ${Media.Laptop`
    margin-right: 20px;
  `};
`

export const CardsContainer = styled.div`
  padding-top: 32px;
  display: flex;
  gap: 20px;
  overflow-x: overlay;
  height: 400px;
  ${Media.Phone`
    padding-top: 16px;
  `};
`

export const CustomTab = styled(Tab)`
  ${Typography.Body};
  ${FontWeights.Regular};
  padding-bottom: 21px;
`

const CustomTablet = css`
  ${Typography.Body};
  ${FontWeights.Regular};

  gap: 4px;
  padding-right: 12px;
  padding-left: 8px;
  height: 36px;
`

export const CustomTabletDesktopButton = styled(Button)`
  ${CustomTablet};
  ${Media.Phone`
    display: none;
  `}
`
export const ButtonWrapper = styled.div`
  display: none;
  ${Media.Phone`
    margin-top: 16px;
    margin-top: 24px;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    padding: 0 20px;

  `}
  ${Media.Tablet`
    padding: 0 20px;
  `};
  ${Media.Laptop`
    padding: 0 20px;
  `};
`
export const CustomMobileButton = styled(Button)`
  ${CustomTablet};
  ${FontWeights.Medium};
  width: 100%;
`
export const Shine = keyframes`
 to {
    background-position: 315px 0, 0 0, 0 190px, 50px 195px;
  }
`
export const CardSkeleton = styled.div`
  min-width: 352px;
  max-width: 352px;
  height: 336px;
  border-radius: var(--S, 16px);
  background: ${Colors.Neutral5};
  background-image: linear-gradient(
    90deg,
    ${Colors.Neutral5} 0px,
    ${Colors.Neutral10} 45px,
    ${Colors.Neutral5} 90px
  );
  background-size: 300px;
  animation: ${Shine} 0.7s infinite cubic-bezier(0.03, 0.26, 0.5, 0.8);
`
