import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import { actions, CareerPathStep, selectors } from 'builder/modules/careerPath2'
import { CareerPath2Questionnaire } from 'builder/components/CareerPath2Questionnaire'
import CareerPathJobDetails from 'builder/components/CareerPathJobDetails'

const ExploreCareersView = () => {
  const dispatch = useDispatch()
  const step = useTypedSelector(selectors.step)

  useEffectOnMount(() => {
    dispatch(actions.setIsExploreCareers(true))
    dispatch(actions.setStep(CareerPathStep.QUESTIONNAIRE))
  })

  if (step === CareerPathStep.ADD_TO_PATH) {
    return <CareerPathJobDetails />
  }

  return <CareerPath2Questionnaire />
}

export default ExploreCareersView
