import EditorField from 'builder/components/EditorField'
import { Select } from 'builder/components/Select'
import { numberOfEmployeesOptions } from '../../constants'
import { useWorkExperienceCardContext } from '../../ExpandableCardContext'
import { useShokumuTranslations } from '../../useShokumuTranslations'

const EmployeeCount = (): JSX.Element => {
  const {
    item: { employerProfilesAttributes },
    onCardValueChange,
  } = useWorkExperienceCardContext()
  const { i18n } = useShokumuTranslations()
  const label = i18n('work_experience.employer_details.employee_count')

  const handleEmployeeCountSelect = (value: string) => {
    onCardValueChange(
      { employerProfilesAttributes: { ...employerProfilesAttributes, noOfEmployee: value } },
      false,
    )
  }

  return (
    <EditorField>
      <Select
        label={label}
        selected={employerProfilesAttributes?.noOfEmployee || ''}
        onSelect={handleEmployeeCountSelect}
        options={numberOfEmployeesOptions}
      />
    </EditorField>
  )
}
export default EmployeeCount
