import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Sizes from 'builder/styles/sizes'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${Sizes['2XS']};
  margin-bottom: ${Sizes.L};
`
const Skill = css`
  display: inline;
  padding: 10px;
  padding: 2px 6px;
  border-radius: ${Sizes['3XS']};
  ${Typography.Caption}
  ${FontWeights.Regular}
  color: ${Colors.Neutral90};
`

export const MatchedSkill = styled.span`
  ${Skill};
  color: ${Colors.Green50};
  background: ${Colors.Green10};
`

export const NotMatchedSkill = styled.span`
  ${Skill};
  color: ${Colors.Neutral90};
  background: ${Colors.Neutral10};
`
