import styled, { css } from 'styled-components'

export const Container = styled.div<{ isTopBarOpen: boolean }>`
  display: flex;
  flex: auto;
  flex-direction: column;

  ${props =>
    props.isTopBarOpen &&
    css`
      z-index: -1;
    `}
`
