import { Link } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'

import { UserProfile } from '../../UserProfile/UserProfile'
import { Container, SubMenuIcon } from './styles'

export const ProfileAvatar = () => {
  return (
    <Link to="/career-profile/profile" onClick={() => trackInternalEvent('click_career_profile')}>
      <Container>
        <UserProfile />
        <SubMenuIcon />
      </Container>
    </Link>
  )
}
