import { ReactChild, ReactFragment, ReactPortal } from 'react'
import { LoadingText, SubLoadingText } from './styles'

const Header = (props: {
  title: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined
  subtitle: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined
}) => {
  return (
    <>
      <LoadingText>{props.title}</LoadingText>
      <SubLoadingText>{props.subtitle}</SubLoadingText>
    </>
  )
}

export default Header
