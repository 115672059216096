import styled from 'styled-components'
import Media from 'builder/styles/media'

export const WizardContainer = styled.div`
  padding: 80px 0;

  ${Media.Phone`
    padding: 64px 0;
  `}
`
