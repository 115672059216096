import styled from 'styled-components'
interface Props {
  width: string
  height: string
}
export const Spacing = styled.div<Props>`
  background-color: transparent;
  width: ${props => props.width};
  height: ${props => props.height};
`
