import { i18n as I18n } from 'builder/utils/i18n'

export const translate = (subPath, ...rest) => {
  return I18n.t(`builder.resume_editor.share_modal.stats.${subPath}`, ...rest)
}

export const formatDescriptionHtml = (total, recent) => {
  const getTranslationKey = () => {
    if (recent > 5) return 'many_recent_views'
    if (recent > 1 && recent <= 5) return 'few_recent_views'
    if (recent === 1) return 'one_recent_view'
    if (!recent && total) return 'no_recent_views'
    return 'no_views'
  }

  const key = getTranslationKey()
  const amount = translate(`description.${key}.amount`, { number: `<b>${recent}</b>` })
  const note = translate(`description.${key}.note`)

  return `${amount}<br />${note}`
}

export const formatViewDuration = seconds => {
  const minutes = Math.round(seconds / 60)

  return minutes ? translate('viewed_for', { minutes }) : translate('viewed_quickly')
}

export const formatViewDownloads = (formats = []) => {
  if (!formats.length) return ''

  return translate('saved', {
    formats: formats.map(format => format.toUpperCase()).join(translate('and')),
  })
}
