import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const BookCoachButton = styled.button<{ isBooked: boolean; isHovered: boolean }>`
  position: relative;
  width: ${props => (props.isBooked ? 245 : 221)}px;
  height: 56px;
  border: none;
  background: ${props => (props.isHovered ? Colors.Blue10 : Colors.Neutral5)};
  border-radius: 63px;
  cursor: ${props => (!props.isBooked ? 'pointer' : 'auto')};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;

  ${Media.Phone`
    width: 100%;
    margin-top: 24px;
  `}
`

export const BookCoachText = styled.div<{ isHovered: boolean }>`
  ${Typography.Body};
  ${FontWeights.Medium};
  position: absolute;
  left: 24px;
  display: flex;
  align-items: center;
  text-align: right;
  color: ${props => (props.isHovered ? Colors.Blue50 : Colors.Neutral80)};

  ${Media.Phone`
    ${Typography.Subhead};
  `}
`

export const CoachGroup = styled.img<{ isBooked: boolean }>`
  position: absolute;
  right: 0px;
  height: 48px;
  width: ${props => (!props.isBooked ? 71 : 48)}px;
  border-radius: 1000px;
  display: flex;
  justify-self: flex-end;
`

export const NextCoachContainer = styled.div`
  position: absolute;
  left: 24px;
  ${Typography.Caption};
  ${FontWeights.Medium};
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  ${Media.Phone`
    left: 120px;
  `}
`

export const NextCoachTitle = styled.div`
  ${Typography.Caption};
  ${FontWeights.Medium};
  display: flex;
  align-items: center;
  text-align: right;
  color: ${Colors.Neutral50};
`

export const NextCoachTime = styled.div`
  ${Typography.Caption};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
  display: flex;
  align-items: center;
  text-align: right;
`
