import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Icon from 'builder/components/Icon'
import Sizes from 'builder/styles/sizes'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Header = styled.div`
  ${Typography.Caps};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral80};
  text-transform: uppercase;
`

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Sizes.XS};
  margin-top: 12px;
`

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${Sizes['3XS']};
  ${Typography.Caption};
  color: ${Colors.Neutral80};
`

export const TickIcon = styled(Icon.Tick)`
  width: 20px;
  height: 20px;
  color: ${Colors.Green40};
`
