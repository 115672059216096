import { useI18n } from 'builder/hooks/useI18n'

import Colors from 'builder/styles/colors'
import Tooltip from 'builder/components/Tooltip'
import { AutoApplyWidget } from 'builder/modules/jobTracking'

import { TRANSLATION } from '../constants'
import * as Styles from './styles'

type Props = {
  autoApplyStatus: AutoApplyWidget['autoApplyStatus']
}

export const Stats = ({ autoApplyStatus }: Props) => {
  const { i18n } = useI18n()

  const data = [
    {
      enabled: true,
      name: i18n.t(`${TRANSLATION}.processing.sub_header`),
      tooltip: i18n.t(`${TRANSLATION}.processing.tooltip`),
      count: autoApplyStatus.processing || 0,
      hoverColor: Colors.Indigo10,
      subHeaderColor: Colors.Indigo50,
      icon: <Styles.SubHeaderIconProcessing />,
    },
    {
      enabled: true,
      name: i18n.t(`${TRANSLATION}.auto_applied.sub_header`),
      tooltip: i18n.t(`${TRANSLATION}.auto_applied.tooltip`),
      count: autoApplyStatus.autoApplied || 0,
      hoverColor: Colors.Blue10,
      subHeaderColor: Colors.Blue50,
      icon: <Styles.SubHeaderIconApplied />,
    },

    {
      enabled: !!autoApplyStatus.error,
      name: i18n.t(`${TRANSLATION}.error.sub_header`),
      tooltip: i18n.t(`${TRANSLATION}.error.tooltip`),
      count: autoApplyStatus.error || 0,
      hoverColor: Colors.Red10,
      subHeaderColor: Colors.Red50,
      icon: <Styles.SubHeaderIconError />,
    },
  ].filter(item => item.enabled)

  return (
    <Styles.Container>
      {data.map(item => (
        <Styles.Box key={item.name} $hoverColor={item.hoverColor}>
          <Tooltip width={250} value={item.tooltip} multiline position="bottom">
            <Styles.TextContainer>
              <Styles.HeaderContainer>
                <Styles.HeaderText>
                  {item.count}{' '}
                  <Styles.HeaderCaption>{i18n.t(`${TRANSLATION}.jobs`)}</Styles.HeaderCaption>
                </Styles.HeaderText>
              </Styles.HeaderContainer>
              <Styles.SubHeaderContainer>
                {item.icon}
                <Styles.SubHeader $color={item.subHeaderColor}>{item.name}</Styles.SubHeader>
              </Styles.SubHeaderContainer>
            </Styles.TextContainer>
          </Tooltip>
        </Styles.Box>
      ))}
    </Styles.Container>
  )
}
