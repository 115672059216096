import { lazy, Suspense } from 'react'
import { Skeleton } from './Skeleton'
import { DemandChartProps } from './types'

const ChartComponent = lazy(() =>
  import('./DemandChart').then(module => ({ default: module.DemandChart })),
)

export const DemandChart = (props: DemandChartProps) => (
  <Suspense fallback={<Skeleton />}>
    <ChartComponent {...props} />
  </Suspense>
)
