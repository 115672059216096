import styled from 'styled-components'
import { Colors, hexToRgba } from 'builder/styles/colors'

const grayShadow = `
  0px 2px 10px -1px rgba(205, 211, 213, 0.2),
  0px 8px 40px -4px rgba(205, 211, 213, 0.4)
`

const greenShadow = `
  0px 2px 10px -1px ${(hexToRgba(Colors.Green20), 0.5)},
  0px 8px 40px -4px ${Colors.Green20}
`

const Container = styled.div<{ color?: string }>`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border-radius: 50%;
  box-shadow: ${grayShadow};
`

export const LayerOne = styled(Container)`
  width: 140px;
  height: 140px;
  background-color: ${Colors.Neutral5};
`

export const LayerTwo = styled(Container)`
  width: 110px;
  height: 110px;
  background-image: linear-gradient(
    0deg,
    ${hexToRgba(Colors.White, 0.5)},
    ${hexToRgba(Colors.White, 0.5)}
  );
  background-color: ${Colors.Neutral5};
`

export const LayerThree = styled(Container)`
  width: 80px;
  height: 80px;
  align-items: center;
  background-color: ${Colors.White};
`

export const GreenLayerOne = styled(LayerOne)`
  background-color: ${Colors.Green10};
  box-shadow: ${greenShadow};
`

export const GreenLayerTwo = styled(LayerTwo)`
  background-color: ${Colors.Green10};
  box-shadow: ${greenShadow};
`

export const GreenLayerThree = styled(LayerThree)`
  box-shadow: ${greenShadow};
`
