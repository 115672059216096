import styled from 'styled-components'
import AsyncSelect from 'react-select/async'
import { TransitionStatus } from 'react-transition-group'
import Colors from 'builder/styles/colors'
import Shadows from 'builder/styles/shadows'
import Typography from 'builder/styles/typography'

export const NoOptionMessage = styled.div`
  color: ${Colors.Neutral40};
`

export const StyledMultiSelect = styled(AsyncSelect)`
  .select__control {
    border: none;
    outline: none;
    width: 100%;
    min-height: 48px;
    cursor: text;
    border-radius: 4px;
    box-shadow: none;
    background-color: ${Colors.Neutral10};
  }

  .select__placeholder {
    color: ${Colors.Neutral50};
  }

  .select__menu {
    margin: 0;
    width: 100%;
    box-shadow: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: ${Colors.Neutral10};
    border-top: 2px solid ${Colors.Blue50};
  }

  .select__option {
    background-color: transparent;

    &:hover {
      cursor: pointer;
      color: ${Colors.Blue50};
      background-color: ${Colors.Blue20};
    }
  }

  .select__indicators {
    display: none;
  }

  .select__multi-value {
    ${Shadows.light.low};
    height: 32px;
    display: flex;
    border-radius: 4px;
    padding: 8px 4px 6px 4px;
    align-items: center;
    background-color: ${Colors.White};
  }

  .select__multi-value__label {
    color: ${Colors.Neutral90};
  }

  .select__multi-value__remove {
    padding: 0;
    color: ${Colors.Neutral40};

    &:hover {
      cursor: pointer;
      color: ${Colors.Blue50};
      background-color: transparent;
    }
  }
`

export const ErrorMessage = styled.div`
  ${Typography.Caption};
  margin-top: 4px;
  color: ${Colors.Red50};
`

export const Bar = styled.div<{ hasError?: boolean; hasWarning?: boolean; in: TransitionStatus }>`
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: ${({ hasError, hasWarning }) => {
    if (hasError) return Colors.Red50
    if (hasWarning) return Colors.Amber40
    return Colors.Blue50
  }};
  border-radius: 0 0 2px 2px;
  pointer-events: none;

  ${props =>
    ({
      entering: 'opacity: 1; transform: scaleX(1); transition: transform 0.1s ease;',
      entered: 'opacity: 1; transform: scaleX(1)',
      exiting: 'opacity: 0; transform: scaleX(1); transition: opacity 0.1s ease;',
      exited: 'opacity: 0; transform: scaleX(0.5)',
      unmounted: null,
    }[props.in])};
`
