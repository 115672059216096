import { memo } from 'react'
import { Industry, Container } from './styles'

interface Props {
  industriesFunctions: Array<string>
}

const CoachSpecialities = ({ industriesFunctions }: Props) => {
  return (
    <Container>
      {industriesFunctions?.map((industry, index) => (
        <Industry key={index}>{industry}</Industry>
      ))}
    </Container>
  )
}

export default memo(CoachSpecialities)
