import { useCallback } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import BadgeBanner from './BadgeBanner'

interface Props {
  onClickGenerate: () => void
}

const GenerateAICVBanner = (props: Props) => {
  const { onClickGenerate } = props

  const handleBannerGenerateClick = useCallback(() => {
    onClickGenerate()
    trackInternalEvent('click_ai_cover_letter_banner')
  }, [onClickGenerate])
  return <BadgeBanner onBannerGenerateClick={handleBannerGenerateClick} />
}

export default GenerateAICVBanner
