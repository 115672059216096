import { SortableElement } from 'react-sortable-hoc'
import { AccomplishmentsListItem, ItemCheck, ItemDescription, DragIcon } from '../style'

type ListItemProps = {
  handleAccomplishmentSelect: (value: boolean, id: number) => void
  isMaxSelection: boolean
  isSelected: boolean
  index: number
  item: {
    id: number
    isSelected: boolean
    description: string
    rank: number
  }
}

export const AccomplishmentsLists = SortableElement(
  ({ item, isSelected, isMaxSelection, index, handleAccomplishmentSelect }: ListItemProps) => (
    <AccomplishmentsListItem
      isMaxSelection={!isSelected ? isMaxSelection : false}
      id={String(item.id)}
    >
      <ItemCheck
        id="select_check"
        isMaxSelection={!isSelected ? isMaxSelection : false}
        isSelected={isSelected}
        onClick={() => handleAccomplishmentSelect(item.isSelected, item.id)}
      >
        {item.isSelected && index + 1}
      </ItemCheck>

      <ItemDescription isMaxSelection={!isSelected ? isMaxSelection : false}>
        {item.description}
      </ItemDescription>
      {isSelected ? <DragIcon /> : null}
    </AccomplishmentsListItem>
  ),
)
