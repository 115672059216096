import CollapseSection from 'builder/components/CollapseSection'
import { formatDate } from 'builder/utils/formatDate'
import { Distribution, DistributionDates } from 'builder/modules/resumeDistribution'
import { RecruiterList } from '../RecruiterList/RecruiterList'

interface RecruiterSectionProps {
  distributions: DistributionDates
}

export const RecruiterSection = ({ distributions }: RecruiterSectionProps) => {
  const getFormatedDate = (distribution: Distribution) => {
    const { day, month, year } = distribution || {}
    return formatDate(new Date(`${month}-${day}-${year}`), 'MMM d, yyyy')
  }

  return (
    <>
      {distributions.data.map((distribution, index) => {
        const isFirstSection = index === 0
        return (
          <CollapseSection
            key={distribution.unixFormat}
            hideExpand={isFirstSection}
            hideDivider={isFirstSection}
            disableHover={isFirstSection}
            isExpanded={isFirstSection}
            text={getFormatedDate(distribution)}
          >
            <RecruiterList distribution={distribution} />
          </CollapseSection>
        )
      })}
    </>
  )
}
