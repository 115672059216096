import { memo } from 'react'
import SpecialityChip from '../SpecialityChip'
import { Container, Title } from '../CoachIndustries/styles'

interface Props {
  jobFunctions: Array<string>
}

const CoachJobFunctions = ({ jobFunctions }: Props) => {
  return (
    <Container>
      <Title>Job Function</Title>
      <SpecialityChip specialities={jobFunctions} />
    </Container>
  )
}

export default memo(CoachJobFunctions)
