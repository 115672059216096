import Icon from 'builder/components/Icon'
import { SpinnerField } from './styles'

const FieldSpinnerLoader = () => {
  return (
    <SpinnerField>
      <Icon.LoaderQuarter width={16} height={16} />
    </SpinnerField>
  )
}

export default FieldSpinnerLoader
