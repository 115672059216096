import { Resume } from 'packages/types'
import { i18n as I18n } from 'builder/utils/i18n'
import { SectionNames } from 'builder/modules/resumeEditor'
import Card from 'builder/components/Card'
import { sectionTitleTranslation } from '../DocumentEditor/ATSResumeEditor/builderSectionsConfig'
import { TemplateName } from '../Helper/constants'
import { AccomplishmentsSectionItem, SectionData } from '../Section/types'
import { WorkExperienceKeywordsToggler } from '../AiFeatures/Keywords'
import { SkillsSuggestionsToggler } from './components/SkillsSuggestionsToggler/SkillsSuggestionsToggler'

export default function (resume: Resume): SectionData[] {
  const { sectionsOrder, template } = resume
  const sectionTitle = sectionTitleTranslation(template, I18n.t)
  const isTemplatePrague = template === TemplateName.Prague
  const isTemplateShanghai = template === TemplateName.Shanghai

  const sections: SectionData[] = [
    {
      id: SectionNames.accomplishments,
      single: true,
      title: sectionTitle(SectionNames.accomplishments),
      customTitle: resume.sectionTitles.accomplishments,
      CardComponent: Card.Accomplishments,
      items: [{ id: null, cid: null, data: resume.accomplishments } as AccomplishmentsSectionItem],
      sortable: true,
      renamable: true,
      hidden: !isTemplateShanghai,
      useUpdateSimpleField: true,
    },
    {
      id: SectionNames.workExperiences,
      title: sectionTitle(SectionNames.workExperiences),
      customTitle: resume.sectionTitles.workExperiences,
      addButtonText: resume.workExperiences.length
        ? I18n.t('builder.resume_editor.work_experience_section.add_one_more')
        : I18n.t('builder.resume_editor.work_experience_section.add'),
      CardComponent: Card.WorkExperience,
      items: resume.workExperiences,
      hintText: I18n.t('builder.resume_editor.work_experience_section.hint'),
      sortable: true,
      renamable: true,
      badgeComponents: [WorkExperienceKeywordsToggler],
    },
    {
      id: SectionNames.educations,
      title: sectionTitle(SectionNames.educations),
      customTitle: resume.sectionTitles.educations,
      addButtonText: resume.educations.length
        ? I18n.t('builder.resume_editor.educations_section.add_one_more')
        : I18n.t('builder.resume_editor.educations_section.add'),
      CardComponent: Card.Education,
      items: resume.educations,
      hintText: I18n.t('builder.resume_editor.educations_section.hint'),
      sortable: true,
      renamable: true,
    },
    {
      id: SectionNames.skills,
      title: sectionTitle(SectionNames.skills),
      customTitle: resume.sectionTitles.skills,
      addButtonText: resume.skills.length
        ? I18n.t('builder.resume_editor.skills_section.add_one_more')
        : I18n.t('builder.resume_editor.skills_section.add'),
      CardComponent: isTemplatePrague ? Card.CategorizedSkill : Card.Skill,
      items: resume.skills,
      hintText: I18n.t('builder.resume_editor.skills_section.hint'),
      sortable: true,
      renamable: true,
      badgeComponents: [SkillsSuggestionsToggler],
    },
    {
      id: SectionNames.courses,
      title: sectionTitle(SectionNames.courses),
      customTitle: resume.sectionTitles.courses,
      addButtonText: resume.courses.length
        ? I18n.t('builder.resume_editor.courses_section.add_one_more')
        : I18n.t('builder.resume_editor.courses_section.add'),
      CardComponent: Card.Course,
      items: resume.courses,
      sortable: true,
      renamable: true,
      removable: true,
      hidden: !sectionsOrder.includes('courses') && resume.courses.length === 0,
    },
    {
      id: SectionNames.internships,
      title: sectionTitle(SectionNames.internships),
      customTitle: resume.sectionTitles.internships,
      addButtonText: resume.internships.length
        ? I18n.t('builder.resume_editor.internships_section.add_one_more')
        : I18n.t('builder.resume_editor.internships_section.add'),
      CardComponent: Card.WorkExperience,
      items: resume.internships,
      sortable: true,
      renamable: true,
      removable: true,
      hidden: !sectionsOrder.includes('internships') && resume.internships.length === 0,
    },
    {
      id: SectionNames.activities,
      title: sectionTitle(SectionNames.activities),
      customTitle: resume.sectionTitles.activities,
      addButtonText: resume.activities.length
        ? I18n.t('builder.resume_editor.activities_section.add_one_more')
        : I18n.t('builder.resume_editor.activities_section.add'),
      CardComponent: Card.Activity,
      items: resume.activities,
      sortable: true,
      renamable: true,
      removable: true,
      hidden: !sectionsOrder.includes('activities') && resume.activities.length === 0,
    },
    {
      id: SectionNames.hobbies,
      title: sectionTitle(SectionNames.hobbies),
      customTitle: resume.sectionTitles.hobbies,
      single: true,
      CardComponent: Card.Hobby,
      items: resume.hobbies,
      sortable: true,
      renamable: true,
      removable: true,
      hidden: !sectionsOrder.includes('hobbies') && resume.hobbies.length === 0,
    },
    {
      id: SectionNames.languages,
      title: sectionTitle(SectionNames.languages),
      customTitle: resume.sectionTitles.languages,
      addButtonText: resume.languages.length
        ? I18n.t('builder.resume_editor.languages_section.add_one_more')
        : I18n.t('builder.resume_editor.languages_section.add'),
      CardComponent: Card.Language,
      items: resume.languages,
      sortable: true,
      renamable: true,
      removable: true,
      hidden: !sectionsOrder.includes('languages') && resume.languages.length === 0,
    },
    {
      id: SectionNames.socialProfiles,
      title: sectionTitle(SectionNames.socialProfiles),
      customTitle: resume.sectionTitles.socialProfiles,
      addButtonText: resume.socialProfiles.length
        ? I18n.t('builder.resume_editor.links_section.add_one_more')
        : I18n.t('builder.resume_editor.links_section.add'),
      CardComponent: Card.Social,
      items: resume.socialProfiles,
      sortable: true,
      renamable: true,
      hintText: I18n.t('builder.resume_editor.links_section.hint'),
    },
    {
      id: SectionNames.references,
      title: sectionTitle(SectionNames.references),
      customTitle: resume.sectionTitles.references,
      addButtonText: resume.references.length
        ? I18n.t('builder.resume_editor.references_section.add_one_more')
        : I18n.t('builder.resume_editor.references_section.add'),
      CardComponent: Card.Reference,
      items: resume.references,
      sortable: true,
      renamable: true,
      removable: true,
      hidden: !sectionsOrder.includes('references') && resume.references.length === 0,
    },
    ...resume.customSections.map(section => ({
      id: section.id || section.externalId,
      title: I18n.t('builder.resume_editor.untitled'),
      customTitle: section.title,
      addButtonText: section.items.length
        ? I18n.t('builder.resume_editor.add_one_more_item')
        : I18n.t('builder.resume_editor.add_item'),
      CardComponent: Card.Custom,
      items: section.items,
      sortable: true,
      removable: true,
      renamable: true,
      isCustom: true,
      sortId: `custom:${section.externalId}`,
    })),
  ]

  return [
    ...sections
      .filter(section => !section.hidden)
      .sort((a, b) => {
        return (
          sectionsOrder.indexOf(a.sortId || (a.id as string)) -
          sectionsOrder.indexOf(b.sortId || (b.id as string))
        )
      }),
    ...sections.filter(section => section.hidden),
  ]
}

type SectionDataKey = Exclude<keyof SectionData, 'CardComponent' | 'items'>

type SectionGroup<T extends SectionDataKey> = Partial<{ [key in T]: SectionData[T] }> & {
  sections: SectionData[]
}

export const groupSectionsBy = <T extends SectionDataKey>(
  sections: SectionData[],
  key: T,
): SectionGroup<T>[] => {
  const groupsMap: Map<SectionData[T], SectionData[]> = new Map()

  sections.forEach(section => {
    const value = section[key]

    if (groupsMap.has(value)) {
      groupsMap.get(value)?.push(section)
    } else {
      groupsMap.set(value, [section])
    }
  })

  return Array.from(groupsMap.entries()).map(
    ([value, sections]) =>
      ({
        [key]: value,
        sections,
      } as SectionGroup<T>),
  )
}
