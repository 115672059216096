import { memo } from 'react'
import expertiseIcon from '../img/job.svg'
import { Expertise, Container, CoachExpertiseIcon } from './styles'
interface Props {
  careerStages: string[]
}

const CoachExpertise = ({ careerStages }: Props) => {
  return (
    <Container>
      <CoachExpertiseIcon src={expertiseIcon} alt="coach expertise" />
      <Expertise>
        Expertise with
        {careerStages.map((level: string) => {
          return ` · ${level} `
        })}
      </Expertise>
    </Container>
  )
}

export default memo(CoachExpertise)
