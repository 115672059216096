import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Shadows from 'builder/styles/shadows'

interface Props {
  checked: boolean
}

export const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  margin-bottom: 47px;
`
export const Card = styled.div<Props>`
  ${Shadows.template.low};
  width: 100%;
  height: 68px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 12px;
  border-radius: 5px;

  &:last-of-type {
    margin-bottom: 34px;
  }

  ${({ checked }) =>
    checked ? `border: 2px solid ${Colors.Blue50}` : `border: 2px solid ${Colors.White}`}
`
export const Details = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
`
export const Field = styled.div`
  flex: 0 0 calc(50% - 20px);
  margin: 24px 0;
  width: 100%;

  ${Media.Phone`
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0;

    &:last-of-type {
      margin-bottom: 0;
    }
  `};
`
export const IconImage = styled.img``

export const Box = styled.div`
  width: 100%;
  margin-top: 25px 0 47px 0;
  text-align: left;
`
export const BoxItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  position: relative;
`
export const Header = styled.div`
  ${Typography.Body}
  margin: 0;
  padding: 0;
`
export const SubHeader = styled.div`
  ${Typography.Caption}
  margin: 0;
  padding: 0;

  color: ${Colors.Neutral50};
`
