import { useCallback, useRef, useState } from 'react'

import { i18n } from 'builder/utils/i18n'
import { formatDate } from 'builder/utils/formatDate'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useClickOutside } from 'builder/hooks/useClickOutside'
import { BriefExpertDoc, BriefResume } from 'builder/modules/panel'
import { DocumentPreview } from 'builder/components/Panel/Document/styles'
import { formatDocumentPreviewUrl } from 'builder/utils/formatDocumentPreviewUrl'
import { ReplaceResumePopup } from 'builder/views/ResumeDistributionDashboardView/components/DashboardHeader/ReplaceResumePopup/ReplaceResumePopup'

import {
  DESKTOP_PREVIEW_WIDTH,
  PHONE_PREVIEW_WIDTH,
} from 'builder/components/Panel/Document/constants'
import { DocumentTypes } from 'builder/modules/constants'

import FieldSpinnerLoader from '../../FormLayout/FieldSpinnerLoader'
import {
  ChangeResumeButton,
  DocumentPreviewContainer,
  ResumeBuilderPopupContainer,
  ResumeSelectionContainer,
  RightBottonShadow,
  SelectLeft,
  SelectResumeDate,
  SelectResumeTitle,
  SelectRight,
} from '../styles'
import SelectResumeModal from '../../SelectResumeModal'

type Props = {
  resumePayload: BriefResume | BriefExpertDoc | null
  isParserLoading: boolean
  handleFileInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleBuilderResumeChange: (data: BriefResume | BriefExpertDoc) => void
}

export const ResumeBuilderPreview = ({
  resumePayload,
  isParserLoading,
  handleFileInputChange,
  handleBuilderResumeChange,
}: Props) => {
  const { isPhone } = useMediaQueries()
  const [showPopup, setShowPopup] = useState(false)
  const [showResumeSelection, setResumeSelection] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  useClickOutside(containerRef, () => setShowPopup(false))

  const previewUrl = useCallback(
    document => {
      return formatDocumentPreviewUrl(document, {
        size: isPhone ? PHONE_PREVIEW_WIDTH : DESKTOP_PREVIEW_WIDTH,
      })
    },
    [isPhone],
  )

  const getThumbnailImg = () => {
    if (resumePayload?.type === DocumentTypes.trDocument && resumePayload?.thumbnail?.url) {
      return resumePayload?.thumbnail?.url
    }
    return previewUrl(resumePayload)
  }

  const getDocumentName = () => {
    if (resumePayload?.type === DocumentTypes.trDocument && resumePayload?.title) {
      return resumePayload?.title
    }

    const name = resumePayload?.name || i18n.t('builder.dashboard.untitled')
    return resumePayload?.jobTitle ? `${name}, ${resumePayload?.jobTitle}` : name
  }

  const formatUpdateDate = () => {
    const date = resumePayload?.updatedAt || resumePayload?.createdAt || new Date().toISOString()
    return formatDate(new Date(date), 'dd MMM, HH:mm')
  }

  return (
    <ResumeSelectionContainer>
      <SelectLeft>
        <SelectResumeTitle>{getDocumentName()}</SelectResumeTitle>
        <SelectResumeDate>
          {i18n.t(`builder.resume_distribution.landing.modal.updated_at`, {
            dateString: formatUpdateDate(),
          })}
        </SelectResumeDate>
        <ChangeResumeButton onClick={() => setShowPopup(!showPopup)}>
          {isParserLoading ? (
            <FieldSpinnerLoader />
          ) : (
            i18n.t(`builder.resume_distribution.landing.form_personal_detail.change_resume`)
          )}
        </ChangeResumeButton>
        {showPopup && (
          <ResumeBuilderPopupContainer>
            <ReplaceResumePopup
              setResumeSelection={setResumeSelection}
              handleFileInputChange={handleFileInputChange}
              ref={containerRef}
            />
          </ResumeBuilderPopupContainer>
        )}

        {showResumeSelection && (
          <SelectResumeModal
            onClose={() => setResumeSelection(false)}
            submitSelectedResume={handleBuilderResumeChange}
          />
        )}
      </SelectLeft>
      <SelectRight>
        <DocumentPreviewContainer>
          {resumePayload && (
            <DocumentPreview showUnavailablePreview={false} src={getThumbnailImg()} />
          )}
        </DocumentPreviewContainer>
        <RightBottonShadow />
      </SelectRight>
    </ResumeSelectionContainer>
  )
}
