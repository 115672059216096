import { SectionContainer, SectionContent } from 'builder/components/ProfileSummary/styles'
import { SectionHint } from 'builder/components/Section'
import SectionTitle from 'builder/components/SectionTitle'
import EditorRow from 'builder/components/EditorRow'
import { useShokumuTranslations } from '../../useShokumuTranslations'
import EmploymentDuration from './EmploymentDuration'
import Employer from './Employer'
import Department from './Department'
import EmploymentType from './EmploymentType'
import JobDescription from './JobDescription'

export const EmploymentDetails = (): JSX.Element => {
  const { i18n } = useShokumuTranslations()
  const title = i18n('work_experience.employment_details.title')
  const description = i18n('work_experience.employment_details.hint.description')
  const leadership = i18n('work_experience.employment_details.hint.leadership')
  const clientInfo = i18n('work_experience.employment_details.hint.client_info')
  const results = i18n('work_experience.employment_details.hint.results')
  const brief = i18n('work_experience.employment_details.hint.brief')

  return (
    <SectionContainer>
      <SectionContainer style={{ marginBottom: '10px' }}>
        <SectionTitle title={title} />
      </SectionContainer>
      <SectionContent>
        <SectionHint>
          {description}
          <ul>
            <li>{leadership}</li>
            <li>{clientInfo}</li>
            <li>{results}</li>
            <li>{brief}</li>
          </ul>
        </SectionHint>
        <EditorRow>
          <EmploymentDuration />
          <Employer />
        </EditorRow>
        <EditorRow>
          <Department />
          <EmploymentType />
        </EditorRow>
        <EditorRow>
          <JobDescription />
        </EditorRow>
      </SectionContent>
    </SectionContainer>
  )
}
