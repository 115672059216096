import { useI18n } from 'builder/hooks/useI18n'

import { Icon20 } from 'builder/components/Icon'

import { CustomBadge } from './style'
const TRANSLATION_KEY = 'builder.interview_prep_view.interview_dashboard'

export const AvailablePremiumBanner = () => {
  const { i18n } = useI18n()

  return (
    <CustomBadge variant="indigo">
      <Icon20.Crown /> {i18n.t(`${TRANSLATION_KEY}.available_premium`)}
    </CustomBadge>
  )
}
