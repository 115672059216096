import { useI18n } from 'builder/hooks/useI18n'
import { useSelect } from 'builder/hooks/useSelect'
import Icon from 'builder/components/Icon'
import useWizardSteps from '../../hooks/useWizardSteps'
import useWizard from '../../hooks/useWizard'
import {
  Container,
  Label,
  Field,
  FieldIcon,
  Menu,
  List,
  Item,
  ItemStep,
  ItemStepInner,
  ItemName,
} from './styles'

export const WizardStepper = () => {
  const { i18n } = useI18n()
  const { downloadPdf } = useWizard()
  const { currentStep, steps, currentStepIndex, setCurrentStep } = useWizardSteps()
  const { isOpen, ref, handleToggle, handleSelect } = useSelect({
    onSelect: setCurrentStep,
  })

  return (
    <Container ref={ref}>
      <Label>{i18n.t(currentStep.translationKey)}</Label>

      <Field onClick={handleToggle}>
        Step {currentStepIndex + 1} of {steps.length + 1}
        <FieldIcon show={!isOpen}>
          <Icon.Chevron />
        </FieldIcon>
      </Field>

      <Menu isOpen={isOpen}>
        <List>
          {steps.map((option, index) => (
            <Item
              key={option.id}
              isSelected={option.id === currentStep.id}
              isDone={option.done}
              onClick={() => handleSelect(option.id)}
            >
              <ItemStep>
                <ItemStepInner>{option.done ? <Icon.Tick /> : index + 1}</ItemStepInner>
              </ItemStep>
              <ItemName>{i18n.t(option.translationKey)}</ItemName>
            </Item>
          ))}
          <Item isSelected={false} isDone={false} onClick={downloadPdf}>
            <ItemStep>
              <ItemStepInner>{steps.length + 1}</ItemStepInner>
            </ItemStep>
            <ItemName>Download Resume</ItemName>
          </Item>
        </List>
      </Menu>
    </Container>
  )
}
