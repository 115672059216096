import { ActionButton } from './styles'

interface ContinueButtonProps {
  continueNext: () => void
}

export const ContinueButton = ({ continueNext }: ContinueButtonProps) => {
  return (
    <ActionButton onClick={continueNext} data-testid="continue-button">
      Continue
    </ActionButton>
  )
}
