import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Select from 'builder/components/Select'

export const Container = styled.div``

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${Colors.Neutral20};
`

export const PreviewContainer = styled.div`
  position: relative;
  width: 100%;
  height: 142px;
  overflow: hidden;
  background-color: ${Colors.Neutral10};
  padding: 16px;
  border-radius: 0 0 4px 4px;
  display: flex;
  justify-content: center;
`

export const Preview = styled.div`
  width: 196px;
  height: 200px;
  background-color: ${Colors.White};
  border-radius: 4px;
  overflow: hidden;

  ${Media.Phone`
    width: 303px;
    height: 310px;
  `};
`

export const PreviewDivider = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: ${Colors.Neutral10};
`

export const DropdownSelect = styled(Select)`
  border-radius: 3px 3px 0 0;
`
