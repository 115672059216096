import { useEffect, useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import { useSelector } from 'react-redux'
import ja from 'date-fns/locale/ja'
import { TextField } from 'builder/components/TextField'
import { Label, LabelContent } from 'builder/components/DateRangePicker/styles'
import { i18n } from 'builder/utils/i18n'
import { selectors } from 'builder/modules/init'
import { parseDateForDatePicker } from '../../../../../../../rendering/templates/shared/utils/formatJapaneseDate'
import './styles.css'
import { DateFormats } from '../../constants/constants'

interface Props {
  value: string
  label: string
  onChange: (notification: { name: string; value: boolean }) => void
}

const CustomDatePicker = (props: Props): JSX.Element => {
  const { value, onChange, label } = props
  const locale = useSelector(selectors.locale)

  const [dateFormat, setDateFormat] = useState<string>(DateFormats.DAY_MONTH_YEAR)

  useEffect(() => {
    if (locale === 'ja-JP') {
      registerLocale(locale, ja)
      setDateFormat(DateFormats.JAPANESE_YEAR_MONTH_DAY)
    }
  }, [locale])
  return (
    <>
      <Label>
        <LabelContent>{label || i18n.t('builder.resume_editor.submission_date')}</LabelContent>
      </Label>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-expect-error: Legacy code is not compatible with  @types/react-datepicker */}
      <DatePicker
        customInput={<TextField />}
        selected={parseDateForDatePicker(value)}
        onChange={onChange}
        showMonthDropdown
        showYearDropdown
        yearDropdownItemNumber={5}
        calendarStartDay={1}
        {...{ dateFormat, locale }}
      />
    </>
  )
}

export default CustomDatePicker
