import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/user'
import { Icon20 } from 'builder/components/Icon'
import { useI18n } from 'builder/hooks/useI18n'
import { QuestionTemplate } from 'builder/modules/interview/types'
import Button from 'builder/components/Button'
import { useCreateInterviewFromQuestionTemplate } from 'builder/views/Interview/hooks/useCreateInterviewFromQuestionTemplate'
import Colors from 'builder/styles/colors'
import { QUESTION_DURATION } from 'builder/views/Interview/constants'
import { useInfiniteScrollInterviews } from 'builder/views/Interview/hooks/useInfiniteScrollInterviews'
import { PremiumDialog } from '../../../PremiumDialog/PremiumDialog'
import { Modal } from '../../../Modal/Modal'
import { Badge } from '../../../Badge/Badge'
import {
  Top,
  HowToAnswer,
  TopLeft,
  PremiumWarning,
  Bottom,
  Question,
  Container,
  Duration,
  Content,
} from './styles'

const TRANSLATION = 'builder.interview_prep_view.interview_dashboard.practice_this'

type PracticeThisQuestionModalProps = {
  trackingParameter: 'interview_dash_recommendation' | 'interview_dash_questions' | 'library'
  onClose: () => void
  onConfirmation?: () => void
  question: QuestionTemplate
}

export const PracticeThisQuestionModal = (props: PracticeThisQuestionModalProps) => {
  const { onClose, question, onConfirmation, trackingParameter } = props
  const { data: allInterviews } = useInfiniteScrollInterviews({
    perPage: 0,
  })
  const { i18n } = useI18n()
  const [showPremiumDialog, setShowPremiumDialog] = useState(false)

  const {
    data: newInterviewFromQuestionTemplate,
    mutate: postCreateInterviewFromQuestionTemplate,
    isLoading: isLoadingInterviewFromQuestionTemplate,
  } = useCreateInterviewFromQuestionTemplate()
  const navigate = useNavigate()

  const isPremiumUser = useTypedSelector(selectors.premiumAccess)
  const isFreeUserCapableStartInterview = !isPremiumUser && allInterviews?.pages[0].total === 0
  const isEligible = isPremiumUser || isFreeUserCapableStartInterview
  const isLoading = isLoadingInterviewFromQuestionTemplate

  useEffect(() => {
    if (newInterviewFromQuestionTemplate !== undefined) {
      navigate('/interview-preparation/interview-player')
    }
  }, [newInterviewFromQuestionTemplate, navigate])

  const onCreateInterviewClick = () => {
    onConfirmation && onConfirmation()
    if (!isEligible) {
      setShowPremiumDialog(true)
      return
    }
    trackInternalEvent('start_interview', {
      label: 'interview_prep',
      parameter: trackingParameter,
    })
    if (question) {
      postCreateInterviewFromQuestionTemplate(question.id)
    }
  }

  if (showPremiumDialog) {
    return (
      <PremiumDialog
        trackingParameter={trackingParameter}
        onClose={() => setShowPremiumDialog(false)}
      />
    )
  }

  return (
    <Modal
      onClose={onClose}
      title={
        <Top>
          <TopLeft>
            <Badge $question={true} $status="initiated">
              {i18n.t(`${TRANSLATION}.question`)}
            </Badge>
            <Duration>
              <Icon20.Clock stroke={Colors.Blue80} />
              {i18n.t(`${TRANSLATION}.min`, {
                minutes: QUESTION_DURATION,
              })}
            </Duration>
          </TopLeft>
        </Top>
      }
    >
      <Container>
        <Question>{question.content}</Question>
        <Content>
          <HowToAnswer>{i18n.t(`${TRANSLATION}.how_to_answer_v2`)}:</HowToAnswer>
          {question?.hint}
        </Content>
        <Bottom>
          <Button isLoading={isLoading} onClick={onCreateInterviewClick}>
            {i18n.t(`${TRANSLATION}.practice_question`)}
          </Button>
          {!isEligible && (
            <PremiumWarning>
              <Icon20.Crown color={Colors.Indigo70} /> {i18n.t(`${TRANSLATION}.available_premium`)}
            </PremiumWarning>
          )}
        </Bottom>
      </Container>
    </Modal>
  )
}
