import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import Button from 'builder/components/Button'
import { JobSuggestionType } from 'builder/modules/interview/types'
import { actions, selectors } from 'builder/modules/interview/interviewModule'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import InterViewPrepS from '../../assets/InterviewPrepS.webm'
import InterViewPrepL from '../../assets/InterviewPrepL.webm'
import { JobTitleAsyncAutosuggest } from '../JobTitleAsyncAutosuggest/JobTitleAsyncAutosuggest'
import { useCreateInterviewFromQuestionTemplate } from '../../hooks/useCreateInterviewFromQuestionTemplate'
import { ShowHints } from '../ShowHints/ShowHints'
import { useMutateJobTitle } from '../../hooks/useMutateJobTitle'
import { useInfiniteScrollQuestionTemplate } from '../../hooks/useInfiniteScrollQuestionTemplate'
import {
  Container,
  Right,
  Left,
  Title,
  Title2,
  LeftBottom,
  Buttons,
  VideoWrapper,
  StyledVideo,
  CustomEditor,
  Content,
  Row,
  Items,
  Item,
  Tick,
  ItemsWrapper,
  LeftTop,
} from './styles'

const TRANSLATION = 'builder.interview_prep_view.start_screen'

const InterviewStartView = () => {
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const jobTitleId = useSelector(selectors.jobTitleId)
  const { isDesktop } = useMediaQueries()
  useEffect(() => {
    if (jobTitleId) {
      navigate('/interview-preparation/dashboard')
      return
    }
    trackInternalEvent('visit_test_interview', {
      label: 'interview_prep',
    })
  }, [])

  const [jobTitle, setJobTitle] = useState('')
  const [hasError, setHasError] = useState(false)
  const [showHints, setShowHints] = useState(false)

  const [selectedJobTitle, setSelectedJobTitle] = useState<JobSuggestionType>()

  const {
    mutateAsync: postCreateInterviewFromQuestionTemplate,
    status: createInterviewFromQuestionTemplateStatus,
  } = useCreateInterviewFromQuestionTemplate()

  const questionTemplate = useInfiniteScrollQuestionTemplate({
    jobTitleId: selectedJobTitle?.id,
    questionType: 'background',
    perPage: 1,
    total: 1,
  })

  const { mutateAsync: postJobTitle } = useMutateJobTitle()
  const questions = (questionTemplate?.data?.pages || []).flatMap(page => page.items)

  const isQuestionLoading = Boolean(selectedJobTitle?.id && questions.length === 0)
  const isInterviewLoading =
    isQuestionLoading || createInterviewFromQuestionTemplateStatus === 'loading'

  const onSuggestionSelected = (suggestion: JobSuggestionType) => {
    setSelectedJobTitle(suggestion)
    setJobTitle(suggestion.text)
    dispatch(actions.setJobTitleId(suggestion.id))
    dispatch(actions.setJobTitle(suggestion.text))
    setHasError(false)
    setShowHints(true)
  }

  const onCreateInterviewClick = async () => {
    if (!selectedJobTitle) {
      setHasError(true)
      return
    }

    postJobTitle(selectedJobTitle.id)
    await postCreateInterviewFromQuestionTemplate(questions[0].id)
    trackInternalEvent('start_interview', {
      label: 'interview_prep',
      parameter: 'test',
    })
    navigate('/interview-preparation/interview-player', {
      state: {
        forceStartLoading: true,
      },
    })
  }

  const onSkipClick = () => {
    if (!selectedJobTitle) {
      setHasError(true)
      return
    }
    postJobTitle(selectedJobTitle.id)
    trackInternalEvent('click_skip_start_button', {
      label: 'interview_prep',
    })
    navigate('/interview-preparation/dashboard')
  }

  return (
    <Container>
      <Content>
        <Row>
          <Left>
            <LeftTop>
              <Title>{i18n.t(`${TRANSLATION}.title_3`)}</Title>
              <ItemsWrapper>
                <Title2>{i18n.t(`${TRANSLATION}.you_will_get`)}:</Title2>
                <Items>
                  <Item>
                    <Tick /> {i18n.t(`${TRANSLATION}.item_1`)}
                  </Item>
                  <Item>
                    <Tick />
                    {i18n.t(`${TRANSLATION}.item_2`)}
                  </Item>
                  <Item>
                    <Tick />
                    {i18n.t(`${TRANSLATION}.item_3`)}
                  </Item>
                </Items>
              </ItemsWrapper>
              <CustomEditor>
                <JobTitleAsyncAutosuggest
                  jobTitle={jobTitle}
                  onChange={e => setJobTitle(e.target.value)}
                  onChangeSuggestion={onSuggestionSelected}
                  hasError={hasError}
                  trackingParameter="test"
                  placeholder={i18n.t(`${TRANSLATION}.provide_current_job`)}
                />
              </CustomEditor>
              {showHints && (
                <ShowHints
                  hints={[i18n.t(`${TRANSLATION}.hint_0`), i18n.t(`${TRANSLATION}.hint_1`)]}
                />
              )}
            </LeftTop>
            <LeftBottom>
              <Buttons>
                <Button theme="ghost" onClick={onSkipClick}>
                  {i18n.t(`${TRANSLATION}.explore`)}
                </Button>
                <Button
                  isLoading={isInterviewLoading}
                  disabled={isInterviewLoading}
                  onClick={onCreateInterviewClick}
                >
                  {i18n.t(`${TRANSLATION}.start_interview`)}
                </Button>
              </Buttons>
            </LeftBottom>
          </Left>
          <Right>
            <VideoWrapper>
              <StyledVideo
                src={isDesktop ? InterViewPrepL : InterViewPrepS}
                key={0}
                autoPlay
                muted
                loop
              />
            </VideoWrapper>
          </Right>
        </Row>
      </Content>
    </Container>
  )
}

export default InterviewStartView
