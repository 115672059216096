import { useI18n } from 'builder/hooks/useI18n'
import { PersonalEditorField } from './PersonalEditorField'
import PropTypes from 'prop-types'

export function Email(props) {
  const { i18n } = useI18n()
  const { value, name } = props

  return (
    <>
      {/* Email */}
      <PersonalEditorField
        label={i18n.t('builder.resume_editor.email')}
        value={value || ''}
        name={name}
      />
    </>
  )
}

Email.propTypes = {
  label: PropTypes.any,
  value: PropTypes.any,
  name: PropTypes.any,
}
