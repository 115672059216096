import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { NonModalDialog } from 'builder/components/NonModalDialog'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/panel'
import { useI18n } from 'builder/hooks/useI18n'
import { DocumentTypes } from 'builder/modules/constants'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'

const TRANSLATION = `builder.auto_tailoring.back_to_overview_dialog`

export const BackToOverviewDialog = () => {
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const { isPhone } = useMediaQueries()
  const [isForceClosed, setIsForceClosed] = useState(false)
  const autoTailoredResumes = useTypedSelector(store =>
    selectors.documentsByType(store, DocumentTypes.resume, true),
  )
  const documentInProgress = autoTailoredResumes.find(document => document.draft)
  const isOpen = !isPhone && !!documentInProgress && !isForceClosed

  const onForceClose = () => {
    setIsForceClosed(true)
  }

  const onViewClick = () => {
    if (documentInProgress) {
      navigate(`/resumes/${documentInProgress.id}/edit`)
    }
  }

  return (
    <NonModalDialog
      isOpen={isOpen}
      onCloseClick={onForceClose}
      onCancelButtonClick={onForceClose}
      title={i18n.t(`${TRANSLATION}.title`)}
      description={i18n.t(`${TRANSLATION}.description`)}
      okButtonText={i18n.t(`${TRANSLATION}.view`)}
      cancelButtonText={i18n.t(`${TRANSLATION}.cancel`)}
      onOkButtonClick={onViewClick}
    />
  )
}
