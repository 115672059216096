/** Resolution in physical pixels to the resolution in CSS pixels */
export const DPR = window.devicePixelRatio || 1

/** Aspect ratio of PDF files */
export const A4_FACTOR = 0.71

/** Aspect ratio of PDF US Letter formate files */
export const US_LETTER_FACTOR = 0.772

/**
 * Base URL for API requests.
 */
export const API_BASE_URL = '/api/app'
export const BASE_URL = '/api'

/** Basename for routing */
export const APP_BASE_PATH = '/app'

/** Available rendering engine names */
export enum RENDERERS {
  server = 'server',
  client = 'client',
}

/** Supported document export formats */
export enum FORMATS {
  docx = 'docx',
  pdf = 'pdf',
  txt = 'txt',
  tr_pdf = 'tr_pdf',
  tr_doc = 'tr_doc',
}

export type FormatType = `${FORMATS}`

/**
 * Supported user document types ("resume" and "cover_letter").
 * Defined as enum to make it easier to iterate and build conditions.
 * Corresponds to a `UserDocumentType` defined globally in "types/document.d.ts".
 */
export enum DocumentTypes {
  trDocument = 'tr_document',
  resume = 'resume',
  coverLetter = 'cover_letter',
}

/** Line spacing properties */
export const SPACING = {
  min: -1,
  max: 1,
  step: 0.5,
}

/** Name of the loading process states */
export enum FetchStatuses {
  notAsked = 'NOT_ASKED',
  loading = 'LOADING',
  loaded = 'LOADED',
  failed = 'FAILED',
}

export enum ResumeValidationStatus {
  notStarted = 'NOT_STARTED',
  uploading = 'UPLOADING',
  validated = 'VALIDATED',
  failed = 'FAILED',
}

export enum ResumeUploadStatus {
  notStarted = 'NOT_STARTED',
  uploaded = 'UPLOADED',
  failed = 'FAILED',
}

export enum ResumeUploadStep {
  validation = 'VALIDATION',
  parsing = 'PARSING',
}

export enum PrefillResumeEvents {
  seeStartScreen = 'see_resume_start_screen',
  clickCrossButtonStartScreen = 'click_cross_button_start_screen',
  clickCreateResume = 'click_create_resume_start_screen',
  clickUploadResume = 'click_upload_resume_start_screen',
  completeResumeUpload = 'complete_resume_upload',
  clickChangeFile = 'click_change_file_upload_screen',
  clickDone = 'click_done_upload_screen',
  completeResumeParsing = 'complete_resume_parsing',
  seeResumeUploadError = 'see_resume_upload_error_screen',
  seeResumeParsingError = 'see_resume_parsing_error_screen',
}

export enum AIResumeDraftEvents {
  clickCreateWithAI = 'click_create_with_ai_start_screen',
  seeLinkedInPrefillscreen = 'see_linkedin_prefill_screen',
  clickSkip = 'click_skip',
  provideLinkedInLink = 'provide_linkedin_link',
  clickContinue = 'click_continue',
  seeLinkedInProfileScreen = 'see_linkedin_profile_screen',
  clickGenerate = 'click_generate',
  clickTryAgain = 'click_try_again',
  seeLinkedInProfileErrorScreen = 'see_linkedin_profile_error_screen',
  clickQuestionnaire = 'click_questionnaire',
  clickAnotherLink = 'click_another_link',
  seeWorkExperienceStep = 'see_work_experience_step',
  clickAddMore = 'click_add_more',
  seeDesiredJobStep = 'see_desired_job_step',
  seeEducationStep = 'see_education_step',
  seeSkillsStep = 'see_skills_step',
  addSuggestedSkill = 'add_suggested_skill',
  addSkill = 'add_skill',
  seeProfessionalHighlightsStep = 'see_professional_highlights_step',
  seeCareerGoalsStep = 'see_career_goals_step',
  clickStartRecording = 'click_start_recording',
  clickRetakeRecording = 'click_retake_recording',
  clickStopRecording = 'click_stop_recording',
  clickTextInput = 'click_text_input',
  clickVoiceInput = 'click_voice_input',
  seeRecordingError = 'see_recording_error',
  clickCreateResume = 'click_create_resume',
  startGenerating = 'start_generating_ai_resume',
  generateSuccessfully = 'generate_ai_resume_successfully',
  generateError = 'generate_ai_resume_error',
  clickStartOver = 'click_start_over',
  seeMicrophoneAccessErrorScreen = 'see_microphone_access_error_screen',
  seeMicrophoneBrowserErrorScreen = 'see_microphone_browser_error_screen',
}

export enum AIResumeDraftEventLabels {
  aiResume = 'ai_resume',
  linkedInPrefillScreen = 'linkedin_prefill_screen_ai_resume',
  linkedInProfileScreen = 'profile_screen_ai_resume',
  linkedInProfileErrorScreen = 'linkedin_profile_error_screen_ai_resume',
  linkedInProfile = 'linkedin_profile',
  workExperience = 'work_experience_ai_resume',
  desiredJob = 'desired_job_ai_resume',
  education = 'education_ai_resume',
  skills = 'skills_ai_resume',
  careerGoals = 'career_goals_ai_resume',
  professionalHighlights = 'professional_highlights_ai_resume',
  questionnaire = 'questionnaire',
  generateError = 'ai_resume_generation_error',
}

export enum UseExampleEvents {
  clickUseAnExampleStartScreen = 'click_use_an_example_start_screen',
  seeExamplesLibrary = 'see_examples_library',
  clickExample = 'click_example',
  clickCategory = 'click_category',
  clickSearchBar = 'click_search_bar',
  clickSearchResult = 'click_search_result',
  seeEmptySearchResults = 'see_empty_search_results',
  clickStartWithAnotherOption = 'click_start_with_another_option',
  seeExamplePreview = 'see_example_preview',
  clickUseExample = 'click_use_example',
  prefillResumeSuccessfully = 'prefill_resume_successfully',
}

export enum UseExampleEventLabels {
  examplesPrefill = 'examples_prefill',
}

export enum CoverLetterRegenerationStatus {
  notStarted = 'NOT_STARTED',
  generating = 'GENERATING',
  generated = 'GENERATED',
  failed = 'FAILED',
}

export enum KeyCodes {
  esc = 27,
  arrowDown = 40,
  arrowUp = 38,
  enter = 13,
  space = 32,
}

export enum ResumeIDStatuses {
  skipped = 'skipped',
  scheduled = 'scheduled',
  failed = 'failed',
  done = 'done',
}

export enum CritiqueReviewStatus {
  pending = 'pending',
  failed = 'failed',
  finished = 'finished',
}

export enum AIResumeLinkedInSteps {
  linkedInPrefill = 'linkedin_prefill',
  linkedInProfile = 'linkedin_profile',
  linkedInError = 'linkedin_error',
}

export enum AIResumeQuestionnaireCardType {
  workExperience = 'workExperience',
  education = 'education',
}

export enum AIResumeFlows {
  LinkedIn = 'LinkedIn',
  Questionnaire = 'Questionnaire',
}

export enum GenerateResumeStatus {
  notStarted = 'NOT_STARTED',
  processing = 'PROCESSING',
  finished = 'FINISHED',
  failed = 'Failed',
}

export enum GenerateAiProfileStatus {
  notStarted = 'NOT_STARTED',
  processing = 'PROCESSING',
  finished = 'FINISHED',
  failed = 'Failed',
}

export enum AIProfileGenerateType {
  generate = 'generate',
  improve = 'improve',
}

export enum SpeechToTextStatus {
  notStarted = 'NOT_STARTED',
  processing = 'PROCESSING',
  finished = 'FINISHED',
  failed = 'Failed',
}

export enum AIResumeWarningModalTypes {
  closeFlow = 'CLOSE_FLOW',
  dataLost = 'DATA_LOST',
  removeJob = 'REMOVE_JOB',
  retake = 'RETAKE',
  switchToVoice = 'SWITCH_TO_VOICE',
  switchToText = 'SWITCH_TO_TEXT',
}

export enum AIResumeWarningModalActions {
  stay = 'STAY',
  confirmClose = 'CONFIRM_CLOSE',
  confirmDataLost = 'CCONFIRM_DATA_LOST',
  retake = 'RETAKE',
  removeJob = 'REMOVE_JOB',
  switchToVoice = 'SWITCH_TO_VOICE',
  switchToText = 'SWITCH_TO_TEXT',
}

export enum VoiceInputErrors {
  unsupportedBrowser = 'UNSUPPORTED_BROWSER',
  permissionDenied = 'PERMISSION_DENIED',
  unknown = 'UNKNOWN',
}

export const CIO_CREATE_ACCOUNT_URL = 'https://career.io/app/create-account'
export const CIO_JOB_SEARCH_URL = 'https://career.io/app/job-search?view=recommendation'
export const CIO_OFFER_ANALYZER_URL = 'https://career.io/app/offer-analyzer'

export const HOST_NAMES = {
  english: ['resume.io', 'career.io', 'cvapp.ie', 'cvapp.nz'],
  staging: ['staging.resume.io', 'staging.career.io'],
  test: ['resume-test.io', 'resume-io'], // resume-io is for shipyard urls
  local: ['localhost'],
}

export const CRITIQUE_EXPIRY_DAYS = 60
