import { getLocalStorageBannerKey } from 'builder/components/CIO-Dasboard-2.0/hooks/constants'
import { EDashboardLocalStoreKeys } from 'builder/components/CIO-Dasboard-2.0/types'
import { AbandonmentLocalStorageKeys } from 'builder/modules/ui'
import { authApiInstance } from 'builder/modules/apiClient/apiClient'
import { authRedirectService, LeavingOptions } from 'builder/services/AuthRedirectService'
import { ETrailModalLocalKeys } from 'builder/components/PremiumRemindModal/types'

type LogoutOptions = LeavingOptions & {
  // disable this flag if you don't want to force invalidate session via the backend.
  //
  // TODO: remove this flag when JWT auth is fully released. Normally, we don't
  // need to perform a request when the Access Token isn't there, but we have to
  // support this behaviour because there are still users who don't have a token,
  // but have a session cookie.
  invalidateSession?: boolean
}

export class AuthService {
  private setAccessToken(accessToken: string) {
    localStorage.setItem('API_ACCESS_TOKEN', accessToken)
  }

  private removeAccessToken() {
    localStorage.removeItem('API_ACCESS_TOKEN')
    localStorage.removeItem('IS_CHECKED_FIRST_LOGIN')
    localStorage.removeItem(EDashboardLocalStoreKeys.IS_WELCOME_MODAL_SHOWN)
    localStorage.removeItem('INTAKE_PAGE')
    localStorage.removeItem(getLocalStorageBannerKey(0))
    localStorage.removeItem(getLocalStorageBannerKey(1))
    localStorage.removeItem(getLocalStorageBannerKey(2))
    localStorage.removeItem(AbandonmentLocalStorageKeys.SHOW_ABANDONMENT_MODAL_FLAG)
    localStorage.removeItem(ETrailModalLocalKeys.lastShownTrialModal)
    localStorage.removeItem(ETrailModalLocalKeys.trailModalPeriod)
    localStorage.removeItem('rdPopupFlag')
  }

  getAccessToken() {
    return localStorage.getItem('API_ACCESS_TOKEN')
  }

  async refreshAccessToken() {
    const {
      data: { token },
    } = await authApiInstance.post('/auth/refresh')

    this.setAccessToken(token)
    return token
  }

  login(accessToken: string): void {
    this.setAccessToken(accessToken)
  }

  async logout(options: LogoutOptions = {}) {
    const { invalidateSession = true, ...leavingOptions } = options

    // explicitly destroy the current session - both access and refresh tokens
    // will become invalid and can no longer be used by other clients
    try {
      if (invalidateSession) await authApiInstance.delete('/auth')
    } catch {}

    // wipe api token from the local storage
    this.removeAccessToken()

    // redirect user to the login page. Save current page path in the local storage if needed
    authRedirectService.leaveApp(leavingOptions)
  }

  isLoggedIn() {
    return !!this.getAccessToken()
  }
}

export const authService = new AuthService()
