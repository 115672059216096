import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Title = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};
`

export const Content = styled.div`
  color: ${Colors.Neutral50};
  margin-top: 32px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  max-height: calc(100vh - 264px);
  overflow: hidden auto;

  ${Media.Phone`
    margin-top: 20px;
    gap: 12px;
  `}
`

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;

  overflow-y: auto;
  overflow-x: hidden;
`

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 260px;
  align-content: center;
  align-items: center;
`
