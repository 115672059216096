import * as React from 'react'
import { useLocation, useParams } from 'react-router-dom'

export const withRouter = <T,>(Component: React.ComponentType<T>) => {
  return (props: T) => {
    let location = useLocation()
    let params = useParams()
    return <Component {...props} location={location} params={params} />
  }
}
