import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import { Icon20 } from 'builder/components/Icon'

export const Slider = styled.div`
  width: 237px;
  gap: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${Media.Phone`
    width: 100%;
  `};
`

export const SliderButtonContainer = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const SliderButton = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.Blue50};

  &:hover {
    cursor: pointer;
  }
`

export const SlidePointersContainer = styled(SliderButtonContainer)``

export const SliderPointer = styled.div<{ highlight: boolean }>`
  width: ${props => (props.highlight ? '16px' : '6px')};
  height: 6px;
  border-radius: 50%;
  border-radius: ${props => (props.highlight ? '8px' : null)};
  background-color: ${props => (props.highlight ? Colors.Blue50 : Colors.Neutral20)};
`

export const RightSlider = styled(Icon20.ChevronDown)`
  color: ${Colors.Green80};
  transform: rotate(-90deg);
`

export const LeftSlider = styled(RightSlider)`
  transform: rotate(90deg);
`
