import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import styled, { css, createGlobalStyle } from 'styled-components'
import Icon, { Icon20, Icon24 } from 'builder/components/Icon'
import Avatar from 'builder/components/Avatar'
import Tooltip from 'builder/components/Tooltip'
import Colors from 'builder/styles/colors'
import { Typography, FontWeights } from 'builder/styles/typography'
import Mixins from 'builder/styles/mixins'
import Media from 'builder/styles/media'
import Shadows from 'builder/styles/shadows'
import { AutoApplyStatus } from 'builder/modules/jobTracking'
import { Button } from '../Button'

export const COLUMN_WIDTH = 288
const COLUMN_GAP = 9
const COLUMN_COUNT = 5
const BOARD_WIDTH = COLUMN_WIDTH * COLUMN_COUNT + COLUMN_GAP * (COLUMN_COUNT - 1)

export const BodyScrollBlocker = createGlobalStyle`
  body {
    overflow: hidden;
  }
`

const statusColors = {
  applied: {
    background: Colors.Beige10,
    color: Colors.Beige50,
    hover: Colors.Beige20,
  },
  interview: {
    background: Colors.Indigo10,
    color: Colors.Indigo50,
    hover: Colors.Indigo20,
  },
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* background-color: ${Colors.Neutral10}; */
`

export const Header = styled.div<{ fromDashboard?: boolean }>`
  position: relative;
  width: 100%;
  max-width: ${BOARD_WIDTH + 64}px;
  margin: 0 auto;
  padding: ${({ fromDashboard }) => (fromDashboard ? 0 : '0 32px')};
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${props =>
    props.fromDashboard &&
    css`
      max-width: 1200px;
      margin: 0 auto;
      padding: 20px 40px 0;
    `}

  ${Media.Phone`
    padding: 20px 20px 0;
  `}
`

export const HeaderTitle = styled.div`
  ${Typography.L};
  margin-right: 24px;

  ${Media.Phone`
    margin-right: auto;
  `}
`

export const Content = styled.div<{ fromDashboard?: boolean; cardsContent?: boolean }>`
  display: flex;
  flex-direction: row;
  margin: auto 0px auto 20px;
  height: 100%;
  user-select: none;
  overflow: hidden;
  background-color: ${Colors.White};
  ${props =>
    props.fromDashboard &&
    css`
      max-width: 100%;
    `}
  ${props =>
    props.cardsContent &&
    css`
      ${Media.Phone`
        margin-left: 0px;
     `}
    `}
`

export const ContentWrapper = styled.div`
  display: flex;
  user-select: none;
  overflow-y: hidden;
  overflow-x: auto;
  width: 100%;
  background-color: ${Colors.White};
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }

  ${Media.Tablet`
    scroll-padding: 0 32px;
  `}

  ${Media.Phone`
    scroll-padding: 0;
  `}
`

export const ContentInner = styled.div<{ fromDashboard?: boolean }>(({ fromDashboard }) => {
  return css`
    display: flex;
    flex-direction: row;
    max-height: 100%;
    padding: 22px 0px 22px 0;

    ${fromDashboard &&
    css`
      max-width: 1200px;
      margin: 0 auto;
      padding: 22px 0px 22px 40px;

      ${Media.Tablet`
        padding: 22px 0px 22px 20px;
      `}
    `}

    ${Media.Phone`
    padding: 0;
    padding-top: 22px;
    & > :first-child {
      margin-left: ${fromDashboard ? '0px' : '20px'};
    }
  `}
  `
})

export const ColumnOuterContainer = styled.div`
  height: 100%;
  border-radius: 12px;

  margin-right: ${COLUMN_GAP}px;
  width: ${COLUMN_WIDTH}px;
`

export const ColumnParent = styled.div<{
  isDragging?: boolean
  isDraggedOver?: boolean
  status?: string
}>`
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  margin-right: ${COLUMN_GAP}px;
  width: ${COLUMN_WIDTH}px;
  ${({ isDragging, isDraggedOver, status }) =>
    isDragging && isDraggedOver
      ? status === 'wishlist'
        ? css`
            background-color: ${Colors.Blue10};
          `
        : status === 'auto_apply'
        ? css`
            background-color: ${Colors.Indigo10};
          `
        : status === 'applied'
        ? css`
            background-color: ${Colors.Beige10};
          `
        : status === 'interview'
        ? css`
            background-color: ${Colors.Indigo10};
          `
        : status === 'offer'
        ? css`
            background-color: ${Colors.Green10};
          `
        : css`
            background-color: ${Colors.Neutral10};
          `
      : ''}
`

export const ColumnContainer = styled.div<{
  isDragging?: boolean
  isDraggedOver?: boolean
  status?: string
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 4px;
  flex-shrink: 0;
  /* max-height: 100%; */
  height: fit-content;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  &:last-of-type {
    margin-right: 0px;
  }

  ${Media.Tablet`
    scroll-snap-align: start;
  `}

  ${Media.Phone`
    width: 100%;
    margin-right: 0;
    scroll-snap-align: center;
  `}

  &:before {
    content: '';
    position: absolute;
    top: 28px;
    left: -6px;
    right: -6px;
    bottom: 0;
    background-color: ${Colors.Neutral15};
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.2s;
  }

  ${({ isDragging, isDraggedOver }) =>
    isDragging &&
    isDraggedOver &&
    css`
      &:before {
        /* opacity: 1; */
        height: 100%;
      }
    `}

  ${({ status }) => {
    return status === 'wishlist'
      ? css`
          background-color: ${Colors.Blue10};
        `
      : status === 'auto_apply'
      ? css`
          background-color: ${Colors.Indigo10};
        `
      : status === 'applied'
      ? css`
          background-color: ${Colors.Beige10};
        `
      : status === 'interview'
      ? css`
          background-color: ${Colors.Indigo10};
        `
      : status === 'offer'
      ? css`
          background-color: ${Colors.Green10};
        `
      : css`
          background-color: ${Colors.Neutral10};
        `
  }}
`

export const ColumnHeader = styled.div<{ status: string }>`
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  /* padding: 15px;
  padding-bottom: 5px !important; */
  padding: 12px 20px;
  padding-right: 4px;
  min-height: 24px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  ${({ status }) =>
    status === 'wishlist'
      ? css`
          background-color: ${Colors.Blue10};
        `
      : status === 'auto_apply'
      ? css`
          background-color: ${Colors.Indigo10};
        `
      : status === 'applied'
      ? css`
          background-color: ${Colors.Beige10};
        `
      : status === 'interview'
      ? css`
          background-color: ${Colors.Indigo10};
        `
      : status === 'offer'
      ? css`
          background-color: ${Colors.Green10};
        `
      : css`
          background-color: ${Colors.Neutral10};
        `}
`

export const ColumnTitle = styled.span<{
  status?: string
}>`
  margin-left: 8px;
  flex-grow: 1;
  font-size: 19px;
  font-weight: 400;
  align-self: flex-end;

  ${({ status }) => {
    if (status !== 'wishlist') {
      return ''
    }
    return css`
      display: flex;
      align-items: center;
    `
  }}

  ${({ status }) => {
    return status === 'wishlist'
      ? css`
          color: ${Colors.Neutral100};
        `
      : status === 'recommended'
      ? css`
          color: ${Colors.Beige70};
        `
      : status === 'auto_apply'
      ? css`
          color: ${Colors.Indigo90};
        `
      : status === 'applied'
      ? css`
          color: ${Colors.Beige70};
        `
      : status === 'interview'
      ? css`
          color: ${Colors.Indigo70};
        `
      : status === 'offer'
      ? css`
          color: ${Colors.Neutral100};
        `
      : css`
          color: ${Colors.Neutral60};
        `
  }}
`

export const ColumnTitleJobsCount = styled.span`
  ${Typography.Body}
  ${FontWeights.Regular}
  margin-left: 8px;
  opacity: 0.48;
`

export const ColumnCount = styled.span`
  flex-shrink: 0;
  text-align: center;
  min-width: 20px;
  padding: 0;
  background-color: ${Colors.Neutral20};
  border-radius: 10px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
`

export const ColumnContent = styled.div<{
  isSafari: boolean
  canInstallExtension: boolean
  fromDashboard?: boolean
}>`
  position: relative;
  overflow-y: auto;
  flex-grow: 1;
  padding-left: 4px;
  padding-right: 4px;
  max-height: calc(100vh - 225px);
  max-height: ${({ canInstallExtension }) =>
    canInstallExtension ? 'calc(100vh - 300px)' : 'calc(100vh - 225px)'};

  &::-webkit-scrollbar {
    width: 0px;
    background-color: transparent;
  }

  ${({ isSafari }) =>
    isSafari &&
    Media.Phone`
    z-index: 1;
  `}

  ${Media.AbovePhone`
    /* width: calc(100% + 10px); */

    &::-webkit-scrollbar {
      width: 0px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${Colors.Neutral20};
      border: none;
      border-radius: 4px;
      border: 2px solid ${Colors.Neutral10};
      transition: border-color 0.1s ease;

      &:hover {
        background-color: ${Colors.Neutral30};
      }
    }
  `};

  ${props =>
    props.fromDashboard &&
    css`
      overflow-y: hidden;
      max-height: 500px;
    `}
`

export const CardContainer = styled.div<{ isDragging: boolean }>`
  padding: 16px;
  margin-bottom: 4px;
  background-color: ${Colors.White};
  border-radius: 8px;
  cursor: pointer;
  ${props => props.isDragging && Mixins.Shadow};
  width: 100%;
  ${Shadows.light.low};

  /* ${Media.AbovePhone`
    max-width: ${COLUMN_WIDTH}px;
  `}; */

  &:focus {
    outline: none;
    ${({ isDragging }) =>
      !isDragging &&
      css`
        box-shadow: 0 0 0 1px ${Colors.Blue50} inset;
      `};
  }
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }
`

export const CardCompany = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular};
  margin-top: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const CardLogo = styled(Avatar)`
  /* float: right;
  margin-left: 16px; */
`

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CardPosition = styled.div`
  color: ${Colors.Neutral50};
  ${Typography.Caption};
  ${FontWeights.Regular};
  text-overflow: ellipsis;
  overflow: hidden;
`

export const CardInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 2px;
`

export const CardTag = styled.div`
  margin-right: 6px;
  margin-top: 6px;
  padding: 2px 8px;
  ${Typography.Tiny};
  background-color: ${Colors.Neutral10};
  color: ${Colors.Neutral50};
  border-radius: 10px;
`

export const CardDate = styled(CardTag)`
  transition: background-color 0.15s, color 0.15s;

  &:hover {
    background-color: ${Colors.Neutral15};
    color: ${Colors.Neutral60};
  }
`

export const CardIcon = styled(Tooltip)`
  display: flex;
  margin-right: 6px;
  margin-top: 6px;
  color: ${Colors.Neutral40};
  transition: color 0.15s;

  &:hover {
    color: ${Colors.Neutral50};
  }

  & svg {
    pointer-events: none;
    width: 20px;
    height: 20px;
  }
`

export const CardIconLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  display: flex;
  color: inherit;

  &:hover {
    color: ${Colors.Blue50};
  }
`

export const AddButton = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 4px 8px;
  border: none;
  border-radius: 6px;
  background: ${Colors.Neutral15};
  color: ${Colors.Neutral50};
  ${Typography.Caption};
  font-weight: 600;
  transition: background-color 0.1s;
  cursor: pointer;

  &:focus,
  &:hover {
    outline: none;
    background-color: ${Colors.Neutral20};
  }
`

export const AddButtonIcon = styled(Icon.Add)`
  margin-right: 4px;
`

export const RunOnboarding = styled.button`
  ${Typography.Body}
  border: none;
  padding: 0;
  background: transparent;
  color: ${Colors.Blue50};
  cursor: pointer;
  flex-shrink: 0;

  &:focus {
    outline: none;
  }

  &:hover {
    color: ${Colors.Blue70};
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    position: relative;
    top: -2px;
  }
`

export const PlaceholderTop = styled.div`
  height: 26px;
  max-width: 120px;
  border-radius: 6px;
  margin-bottom: 8px;
  ${Mixins.placeholderBackground};
`

export const PlaceholderButton = styled.div`
  height: 32px;
  border-radius: 6px;
  margin-bottom: 8px;
  ${Mixins.placeholderBackground};
`

export const PlaceholderCard = styled.div<{ big?: boolean }>`
  height: ${({ big }) => (big ? 134 : 106)}px;
  margin-bottom: 8px;
  border-radius: 6px;
  background: ${Colors.White};
  padding: 16px;
  display: flex;
  justify-content: space-between;
`

export const PlaceholderText = styled.div`
  padding-top: 4px;

  &::before,
  &::after {
    content: '';
    display: block;
    height: 14px;
    margin-bottom: 12px;
    width: 80px;
    border-radius: 3px;
    ${Mixins.placeholderBackground};
  }

  &::after {
    width: 120px;
  }
`

export const PlaceholderAvatar = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  ${Mixins.placeholderBackground};
`

export const PlaceholderContainer = styled.div`
  padding: 22px 32px;
  width: 100%;
  max-width: ${BOARD_WIDTH + 64}px;
  margin: 0 auto;

  ${Media.Phone`
    padding: 24px 20px;
  `}
`

export const PlaceholderContent = styled.div`
  width: ${BOARD_WIDTH}px;
  display: flex;

  ${Media.Phone`
    overflow-x: hidden;
    width: 100%;
  `}
`

export const PlaceholderColumn = styled.div`
  width: ${COLUMN_WIDTH}px;
  margin-right: ${COLUMN_GAP}px;

  &:last-child {
    margin-right: 0;
  }

  ${Media.Phone`
    width: calc(100vw - 100px);
  `}
`

export const RightSide = styled.div`
  display: flex;
  align-items: center;
`

export const CardNotifiy = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  width: 100%;
  border-radius: 8px;
`

export const CardNotifiyContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 137px;
  height: 40px;
`

export const CardNotifiyTitle = styled.div`
  ${Typography.Body}
  color: ${Colors.Neutral100};
`

export const CardNotifiyText = styled.div`
  ${Typography.Caption}
  letter-spacing: 0.3px;
  color: ${Colors.Neutral50};
`

export const UpgradeButtonContainer = styled.a`
  ${Typography.Caption};
  ${FontWeights.DemiBold};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  gap: 2px;
  font-size: 15px;
  line-height: 18px;
  border-radius: 360px;
  background-color: #282b8f;
  color: ${Colors.White};
  transition: background-color 0.15s;
  margin-top: 16px;
  height: 36px;

  &:hover,
  &:active {
    background: ${Colors.Indigo90};
  }
`

export const UpgradeButtonIcon = styled(Icon20.Crown)`
  margin-right: 4px;
`

export const StyledButton = styled(Button)`
  ${Typography.Caption};
  ${FontWeights.Medium};
  width: 100%;
  margin: 10px 0;
`

export const DeleteContainer = styled.div<{ isDragging: boolean }>`
  position: fixed;
  bottom: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
  visibility: hidden;

  ${({ isDragging }) =>
    isDragging &&
    css`
      visibility: visible;
    `}
`

export const DeleteText = styled.label<{ isDragging?: boolean; isDraggedOver?: boolean }>`
  color: ${Colors.Neutral50};
  transition: 0.3s;

  ${({ isDragging, isDraggedOver }) =>
    isDragging &&
    isDraggedOver &&
    css`
      display: none;
    `}
`
export const DeleteIconContainer = styled.div<{ isDragging?: boolean; isDraggedOver?: boolean }>`
  background-color: ${Colors.Red20};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 45px;
  height: 45px;
  transition: 0.3s;

  ${({ isDragging, isDraggedOver }) =>
    isDragging &&
    isDraggedOver &&
    css`
      width: 250px !important;
      height: 250px !important;
      margin-right: -50px;
      margin-bottom: -50px;
      background-color: ${Colors.Red20};
      transition: 0.3s;
    `}
`

export const RecycleIcon = styled(Icon24.Trash)`
  margin-right: 4px;
  color: ${Colors.Red50};
  margin-left: 2px;
`

export const PlusIcon = styled(Icon24.Add)<{
  status?: string
}>`
  margin-right: 17px;
  margin-left: 2px;
  cursor: pointer;
  height: 20px;
  width: 20px;

  ${({ status }) => {
    return status === 'wishlist'
      ? css`
          color: ${Colors.Blue40};
        `
      : status === 'interview'
      ? css`
          color: ${Colors.Indigo40};
        `
      : status === 'applied'
      ? css`
          color: ${Colors.Beige40};
        `
      : status === 'interview'
      ? css`
          color: ${Colors.Indigo40};
        `
      : status === 'offer'
      ? css`
          color: ${Colors.Green40};
        `
      : css`
          color: ${Colors.Neutral40};
        `
  }}
`

export const AddButtonBottom = styled.button<{
  status?: string
}>`
  border: 1px solid;
  background: transparent;
  border-radius: 12px;
  width: 100%;
  padding: 8px 16px;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  cursor: pointer;

  ${({ status }) => {
    return status === 'wishlist'
      ? css`
          border-color: ${Colors.Blue20};
          color: ${Colors.Blue50};
        `
      : status === 'auto_apply'
      ? css`
          border-color: ${Colors.Indigo20};
          color: ${Colors.Indigo50};
        `
      : status === 'applied'
      ? css`
          border-color: ${Colors.Beige20};
          color: ${Colors.Beige50};
        `
      : status === 'interview'
      ? css`
          border-color: ${Colors.Indigo20};
          color: ${Colors.Indigo50};
        `
      : status === 'offer'
      ? css`
          border-color: ${Colors.Green20};
          color: ${Colors.Green50};
        `
      : css`
          border-color: ${Colors.Neutral20};
          color: ${Colors.Neutral50};
        `
  }}

  &:hover {
    // Hard coded color to match the figma design, as used colors are not available in theme
    ${({ status }) => {
      return status === 'wishlist'
        ? css`
            background-color: ${'#C2E4Fc'};
          `
        : status === 'auto_apply'
        ? css`
            background-color: ${'#daddfd'};
          `
        : status === 'applied'
        ? css`
            background-color: ${'#E0DCD1'};
          `
        : status === 'interview'
        ? css`
            background-color: ${'#daddfd'};
          `
        : status === 'offer'
        ? css`
            background-color: ${'#C5E3D1'};
          `
        : css`
            background-color: ${Colors.Neutral20};
          `
    }}
  }
`
export const ResumeScore = styled.div<{ score: number }>`
  border-top: 1px solid ${Colors.Neutral10};
  margin-top: 8px;
  padding-top: 8px;
  position: relative;
  display: flex;
  height: 35px;
  align-items: center;
  justify-content: ${({ score }) => (score < 70 ? 'space-between' : '')};
`
export const Score = styled.div<{ score: number }>`
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${props =>
    props.score <= 30
      ? Colors.Red50
      : props.score > 30 && props.score <= 70
      ? Colors.Amber50
      : Colors.Green50};
  margin-left: 36px;
`
export const Text = styled.div`
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
  line-height: 13px;
  text-align: center;
  margin-left: 4px;
`

export const CardButton = styled(Button)<{
  status: keyof typeof statusColors
}>`
  ${Typography.Caption};
  ${FontWeights.Regular};
  width: 100%;
  border-radius: 4px;
  margin-top: 8px;
  height: 28px;
  background-color: ${props => statusColors[props.status].background};
  color: ${props => statusColors[props.status].color};

  &:hover {
    background-color: ${props => statusColors[props.status].hover};
  }
`
export const ButtonWrapper = styled.div<{
  isImproveResume?: boolean
  addScoreSeparator?: boolean
}>`
  width: ${({ isImproveResume }) => (isImproveResume ? '85px' : '100%')};
  ${({ addScoreSeparator }) => (addScoreSeparator ? 'margin-top: 5px;' : '')};
`

export const TailorYourResume = styled(Button)`
  ${Typography.Caption};
  ${FontWeights.Regular};
  width: 100%;
  border-radius: 4px;
  margin-top: 8px;
  background-color: ${Colors.Blue10};
  color: ${Colors.Blue50};
  height: 28px;

  &:hover {
    background-color: ${Colors.Blue20};
  }
`
export const AutoApplyCardButton = styled(Button)<{ secondary?: boolean }>`
  ${Typography.Caption};
  ${FontWeights.Regular};
  width: 100%;
  border-radius: 4px;
  margin-top: 8px;
  background-color: ${Colors.Blue10};
  height: 28px;
  ${props =>
    props.secondary
      ? css`
          background-color: ${Colors.White};
        `
      : css`
          background-color: ${Colors.Blue10};
        `}
  color: ${Colors.Blue50};

  &:hover {
    ${props =>
      props.secondary
        ? css`
            background-color: #c3e5fe24;
          `
        : css`
            background-color: ${Colors.Blue20};
          `}
  }
`
export const AutoApplyLabel = styled.div<{ autoApplyStatus: AutoApplyStatus }>`
  height: 24px;
  width: auto;
  display: inline-block;
  & > :last-child {
    margin-right: 5px;
  }
  background: ${props => {
    if (props.autoApplyStatus === 'processing') {
      return Colors.Indigo10
    }
    if (props.autoApplyStatus === 'auto_applied') {
      return Colors.Blue10
    }
    if (props.autoApplyStatus === 'error') {
      return Colors.Red10
    }
  }};
  color: ${props => {
    if (props.autoApplyStatus === 'processing') {
      return Colors.Indigo80
    }
    if (props.autoApplyStatus === 'auto_applied') {
      return Colors.Blue80
    }
    if (props.autoApplyStatus === 'error') {
      return Colors.Red80
    }
  }};
  ${FontWeights.Medium};
  font-size: 16px;
  border-radius: var(--size-3xs);
  padding-left: var(--size-2xs);
  padding-right: var(--size-2xs);
  text-align: center;
`
export const AutoApplyLabelContent = styled.div`
  opacity: 70%;
  display: flex;
  align-items: center;
  & > :first-child {
    margin-right: 4px;
  }
`
export const AutoApplyTooltipContent = styled.div`
  ${Typography.Caption};
  ${Shadows.dark.mid};
  font-weight: 400;
  letter-spacing: 0.3px;
  white-space: normal;
  color: ${Colors.White};
  background: ${Colors.Neutral90};
`

export const AutoApplyTooltip = styled(Tippy)`
  background: ${Colors.Neutral90};
  max-width: 242px !important;
  padding: 8px 12px !important;
`

export const ApplyStatusIcon = styled.img`
  width: 15px;
`

export const AutoApplyTitleBtn = styled.div`
  cursor: pointer;
  ${Typography.ButtonS};
  font-weight: 400;
  color: ${Colors.Blue50};
  margin-left: auto;
`
export const AutoApplyTitleIcon = styled.img`
  margin-left: auto;
`

export const DashboardScroll = styled.div<{ isSideMenuOpen: boolean }>`
  // Add div with min-width to make the job tracker dashboard scrollable inside the container.
  min-width: ${({ isSideMenuOpen }) =>
    `calc((100vw - 1200px - ${isSideMenuOpen ? 236 : 45}px) / 2)`};
`
