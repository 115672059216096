import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import { Icon20 } from 'builder/components/Icon'
import CIO_LOGO_URL from 'images/countries/cio-logo.svg'

export const SuperAppButton = styled.a`
  display: block;
  cursor: pointer;
  width: 124px;
  height: 40px;
  background-image: url(${CIO_LOGO_URL});
  background-color: ${Colors.Neutral5};
  background-position: 11px center;
  background-size: auto 28px;
  border-radius: 32px;
  color: ${Colors.Neutral100};
  margin-right: 24px;
  transition: background-color 0.15s;

  &:hover {
    background-color: ${Colors.Blue10};
  }
`

export const UpgradeButtonContainer = styled.a`
  ${Typography.Caption};
  ${FontWeights.DemiBold};
  display: flex;
  align-items: center;
  padding: 8px 11px 8px 8px;
  margin-right: 32px;
  border-radius: 4px;
  background-color: ${Colors.Indigo50};
  color: ${Colors.White};
  transition: background-color 0.15s;

  &:hover {
    background-color: ${Colors.Indigo60};
  }

  &:active {
    background-color: ${Colors.Indigo70};
  }

  ${Media.Phone`
    margin-right: 12px;
  `};
`

export const UpgradeButtonIcon = styled(Icon20.Crown)`
  margin-right: 4px;
`
