import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { panels } from './constants'
import * as Styled from './styles'
import { MenuPanel } from './components/MenuPanel'
import { WelcomeModalButtonPanel } from './components/MenuPanel/WelcomeModalButtonPanel'

export const MenuPanels = () => {
  const { isPhone } = useMediaQueries()
  const location = useLocation()

  const promoBanner = localStorage.getItem('promoBanner')

  const isDashboardPage = useMemo(() => {
    return location.pathname === '/'
  }, [location.pathname])

  return (
    <Styled.Wrapper>
      {isPhone && isDashboardPage && <WelcomeModalButtonPanel />}
      {panels.map(panel => (
        <MenuPanel
          key={panel.name}
          {...panel}
          to={promoBanner === '1' ? '/?tr_promo_banner=1' : '/'}
        />
      ))}
    </Styled.Wrapper>
  )
}
