import * as Styles from './styles'

const brokenBannerImage = require('images/Career-foundation/banner_broken.gif')
const alertImage = require('images/Career-foundation/Alert.svg')

interface Props {
  title: string
  subTitle: string
  leftButton: string
  rightButton: string
  toggle: () => void
  viewMyResume: () => void
}

const BrokenBanner = ({
  title,
  subTitle,
  leftButton,
  rightButton,
  toggle,
  viewMyResume,
}: Props) => {
  return (
    <Styles.Container>
      <Styles.BannerLeftContent>
        <Styles.Title>{title}</Styles.Title>
        <Styles.Text>{subTitle}</Styles.Text>
        <Styles.ButtonContainer>
          <Styles.TryLaterButton onClick={toggle}>{leftButton}</Styles.TryLaterButton>
          <Styles.ViewMyResumeButton onClick={viewMyResume}>
            {rightButton}
          </Styles.ViewMyResumeButton>
        </Styles.ButtonContainer>
      </Styles.BannerLeftContent>

      <Styles.BannerRightContent>
        <Styles.BrokenCardContainer>
          <Styles.AlertContainer src={alertImage} />
          <Styles.BrokenResumeContainer src={brokenBannerImage} />
        </Styles.BrokenCardContainer>
      </Styles.BannerRightContent>
    </Styles.Container>
  )
}

export default BrokenBanner
