import { useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import ModalOverlay from 'builder/components/ModalOverlay'
import { actions } from 'builder/modules/coverLetterEditor'
import { i18n as I18n } from 'builder/utils/i18n'
import { useClickOutside } from 'builder/hooks/useClickOutside'
import { GenerateButton, Star } from 'builder/components/GenerateAICVBanner/styles'
import starWhite from 'builder/components/GenerateAICVBanner/StarWhite.svg'
import { ButtonWrapper, Cancel, CloseIcon, Container, Content, Title, Wrapper } from './styles'

interface Props {
  coverLetterId: number
  isGeneratingCoverLetter: boolean
}

const ConfirmationModal = (props: Props) => {
  const { coverLetterId, isGeneratingCoverLetter } = props
  const dispatch = useDispatch()
  const container = useRef<HTMLDivElement>(null)

  useEffect(() => {
    trackInternalEvent('see_regenerate_confirmation_screen')
  }, [])

  const handleRegenerate = () => {
    dispatch(actions.regenerateCoverLetter({ id: coverLetterId }))
    trackInternalEvent('click_confirm_regenerate_cover_letter')
  }

  const handleClose = () =>
    !isGeneratingCoverLetter ? dispatch(actions.setShowConfirmRegenerateModal(false)) : () => {}

  const handleClickOutside = (e: MouseEvent) => {
    e.stopPropagation()
    handleClose()
  }

  useClickOutside(container, !isGeneratingCoverLetter ? handleClickOutside : () => {})
  return (
    <ModalOverlay overlayFadeDuration={300} contentSlideDuration={300}>
      <Container ref={container}>
        <Wrapper>
          <Title>{I18n.t('builder.ai_cover_letter.regenerate_dialog.title')}</Title>
          <CloseIcon onClick={handleClose} />
        </Wrapper>
        <Content>{I18n.t('builder.ai_cover_letter.regenerate_dialog.content')}</Content>
        <ButtonWrapper>
          <Cancel theme="ghost" onClick={handleClose}>
            {I18n.t('builder.resume_optimizer.job_posting.cancel')}
          </Cancel>
          <GenerateButton
            style={{ width: 'auto' }}
            onClick={handleRegenerate}
            disabled={isGeneratingCoverLetter}
          >
            <Star $showAnimation={isGeneratingCoverLetter} src={starWhite} alt="Star Image" />
            {I18n.t(
              isGeneratingCoverLetter
                ? 'builder.ai_cover_letter.generating'
                : 'builder.ai_cover_letter.regenerate',
            )}
          </GenerateButton>
        </ButtonWrapper>
      </Container>
    </ModalOverlay>
  )
}

export default ConfirmationModal
