import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'

import { ErrorLayout } from './ErrorLayout'
import NO_ROLE from './assets/no_role.png'
import { NoRoleImage } from './styles'

export const NoTargetRoleAndNoLocation = () => {
  const navigate = useNavigate()

  return (
    <ErrorLayout
      Images={<NoRoleImage src={NO_ROLE} alt="no_role_no_location" />}
      translationKey="no_target_role_no_location"
      onClick={() => {
        trackInternalEvent('view_profile_editor', { label: 'recommended_jobs' })
        navigate(
          '/career-profile/edit?section=jobPreferences&returnUrl=/job-search?view=recommendation',
        )
      }}
    />
  )
}
