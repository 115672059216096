import { useRef } from 'react'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/resumeEditor'
import { useI18n } from 'builder/hooks/useI18n'
import useImproveResumePanel from '../../hooks/useImproveResumePanel'
import { Header, Section } from '../../styles'
import { SuggestionContainer, SuggestionList, Suggestion, PercentSuggestion } from './styles'

export const CompletenessSuggests = () => {
  const { i18n } = useI18n()
  const containerRef = useRef<HTMLDivElement | null>(null)
  const listRef = useRef<HTMLDivElement | null>(null)

  const { suggestions } = useTypedSelector(selectors.scoreSuggestions)
  const { handleSuggestionClick, getSuggestionScore } = useImproveResumePanel()

  return (
    <Section>
      <Header>
        {i18n.t(
          'builder.resume_editor.keywords.improve_resume_panel.completeness_suggests.title_text',
        )}
      </Header>
      <SuggestionContainer ref={containerRef}>
        <SuggestionList ref={listRef}>
          {suggestions.map(suggestion => (
            <Suggestion key={suggestion.key} onClick={() => handleSuggestionClick(suggestion)}>
              <PercentSuggestion>+{getSuggestionScore(suggestion)}</PercentSuggestion>
              {suggestion.text}
            </Suggestion>
          ))}
        </SuggestionList>
      </SuggestionContainer>
    </Section>
  )
}
