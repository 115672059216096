import styled from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import { fadeInStyles } from '../styles'

export const RotateWrapper = styled.div`
  position: absolute;
  z-index: 4;
  left: 50%;
  bottom: 60px;
  transform: translateX(-50%);
  width: 340px;
  pointer-events: none;
  ${fadeInStyles};
`

export const RotateTitle = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral60};
  text-align: center;
`

export const RotateControl = styled.div`
  position: relative;
  overflow: hidden;
  pointer-events: auto;
  padding-top: 16px;
  padding-bottom: 8px;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 16px;
    bottom: 0;
    width: 48px;
    z-index: 4;
    background: linear-gradient(to right, ${Colors.Neutral100}, ${hexToRgba(Colors.Neutral100, 0)});
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
    transform: scaleX(-1);
  }
`

export const RotateTrack = styled.div`
  position: relative;
  margin-left: 50%;
  left: -182px;
  width: 364px;

  &:before {
    content: '';
    position: absolute;
    left: -4px;
    right: -4px;
    height: 4px;
    top: 2px;
    background-image: url(${require('images/builder/avatar-editor/rotate-slider-bg.svg')});
    background-repeat: space no-repeat;
  }
`

export const RotateMarkers = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -2px;
`

export const RotateMarker = styled.div`
  position: relative;
  width: 8px;
  display: flex;
  flex-flow: column;
  align-items: center;

  &:before {
    content: '';
    display: block;
    position: relative;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #535864;
  }
`

export const RotateMarkerLabel = styled.div<{ isNegative: boolean }>`
  position: relative;
  margin: 8px 0 0;
  font-size: 14px;
  line-height: 12px;
  text-align: center;
  color: ${Colors.Neutral60};
  opacity: 0.64;

  &:before,
  &:after {
    position: absolute;
    width: 16px;
    top: 0;
  }

  &:before {
    content: '-';
    color: ${props => (props.isNegative ? 'inherit' : 'transparent')};
    right: 100%;
    text-align: right;
  }

  &:after {
    content: '°';
    left: 100%;
    text-align: left;
  }
`

export const RotateValueMarker = styled(RotateMarker)`
  position: absolute;
  top: 16px;
  left: calc(50% - 4px);
  pointer-events: none;

  &:before {
    background-color: ${Colors.Blue50};
    box-shadow: 0 0 4px 4px ${Colors.Neutral100};
  }

  & ${RotateMarkerLabel} {
    opacity: 1;
    background-color: ${Colors.Neutral100};

    &:before {
      background: linear-gradient(
        to left,
        ${Colors.Neutral100} 15%,
        ${hexToRgba(Colors.Neutral100, 0)} 100%
      );
    }

    &:after {
      background: linear-gradient(
        to right,
        ${Colors.Neutral100} 15%,
        ${hexToRgba(Colors.Neutral100, 0)} 100%
      );
    }
  }
`
