export const CareerDays = [
  {
    name: 'Monday',
    checked: false,
  },
  {
    name: 'Tuesday',
    checked: false,
  },
  {
    name: 'Wednesday',
    checked: false,
  },
  {
    name: 'Thursday',
    checked: false,
  },
  {
    name: 'Friday',
    checked: false,
  },
  {
    name: 'Saturday',
    checked: false,
  },
  {
    name: 'Sunday',
    checked: false,
  },
]
