import { useState, Fragment } from 'react'
import Lottie from 'react-lottie'
import { trackInternalEvent } from '@rio/tracking'
import { Button } from 'builder/components/Button'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { IWidgetsStatus } from 'builder/modules/panel'
import CircularProgressBar from '../FloatingCIOHelperButton/CircularProgressBar'
import * as Styled from './styles'
import { CheckBoxLabel } from './components/CheckBoxLabel'
import { DropDownContentProps } from './constants'
import CompletionImageURL from './assets/img/completionIllustration.png'
import animationData from './assets/Confetti.json'

interface Props {
  dropDownContent: DropDownContentProps[]
  heading: string
  subHeading: string
  action: (name: string) => void
  handleClose: () => void
  handleStepCompleteClick: (status: IWidgetsStatus, stepId?: number) => void
  totalSteps: number
  completedSteps: number
  animationHeading: string
  animationSubHeading: string
  helperLabel: string
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
}

export const HelperModal = ({
  dropDownContent,
  heading,
  subHeading,
  action,
  handleClose,
  handleStepCompleteClick,
  totalSteps,
  completedSteps,
  animationHeading,
  animationSubHeading,
  helperLabel,
}: Props) => {
  const [showContent, setshowContent] = useState([true, false, false])
  const [isHovered, setIsHovered] = useState(false)
  const [showFinishedAnimation, setShowFinishedAnimation] = useState(false)

  const { isPhone, isTablet } = useMediaQueries()

  const onSelect = (index: number) => {
    setshowContent(prevShowContent => {
      // If the selected index is already true, set it to false
      const updatedShows = prevShowContent.map((value, i) => (i === index ? !value : false))
      return updatedShows
    })
  }

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const handleCheckBoxClick = (content: DropDownContentProps) => {
    handleStepCompleteClick(
      content?.status === IWidgetsStatus.PENDING
        ? IWidgetsStatus.COMPLETED
        : IWidgetsStatus.PENDING,
      content?.id,
    )
    if (content?.status === IWidgetsStatus.PENDING && completedSteps + 1 === totalSteps) {
      trackInternalEvent('helper_done', {
        label: helperLabel,
      })
      setTimeout(() => {
        setShowFinishedAnimation(true)
        setTimeout(() => {
          setShowFinishedAnimation(false)
        }, 3000)
      }, 1000)
    }
  }

  const actionTickComplete = (name?: string) => {
    if (name) {
      action(name)
    }
  }

  return (
    <>
      <Styled.Container>
        {!isPhone && (
          <Styled.CloseButton
            data-testid="close-button"
            onHover={isHovered}
            onClick={handleClose}
            onMouseOver={handleMouseEnter}
            onMouseOut={handleMouseLeave}
          >
            <Styled.CloseIcon onHover={isHovered} />
          </Styled.CloseButton>
        )}
        <Styled.Content>
          {!showFinishedAnimation ? (
            <Styled.SubContent>
              <Styled.TextContainer>
                <Styled.Heading>{heading}</Styled.Heading>
                <Styled.SubHeading>{subHeading}</Styled.SubHeading>
              </Styled.TextContainer>
              <Styled.ProgressBarContainer>
                <CircularProgressBar totalSteps={totalSteps} completedSteps={completedSteps} />
              </Styled.ProgressBarContainer>

              {dropDownContent.map((content, index) => (
                <Fragment key={index}>
                  <Styled.ContentLabel isLastStep={index === dropDownContent.length - 1}>
                    <CheckBoxLabel
                      onChange={() => onSelect(index)}
                      label={content.title}
                      isVisible={showContent[index]}
                      selected={content.status === IWidgetsStatus.COMPLETED}
                      onSelected={() => handleCheckBoxClick(content)}
                    />

                    <Styled.DropdownContainer showContent={showContent[index]}>
                      <Styled.Wrapper
                        onClick={() => {
                          actionTickComplete(content?.name)
                        }}
                      >
                        {content.visual}
                      </Styled.Wrapper>
                      <Styled.Text>{content.text}</Styled.Text>
                      <Button
                        size="small"
                        onClick={() => {
                          actionTickComplete(content?.name)
                        }}
                      >
                        {content.buttonName}
                      </Button>
                    </Styled.DropdownContainer>
                  </Styled.ContentLabel>
                  {!(index === dropDownContent.length - 1) && <Styled.Line />}
                </Fragment>
              ))}
            </Styled.SubContent>
          ) : (
            <Styled.AnimationSubContent>
              <Styled.AnimationContainer>
                <Lottie options={defaultOptions} width="100%" height={isTablet ? '15%' : '285px'} />
              </Styled.AnimationContainer>
              <Styled.Image src={CompletionImageURL} alt="Steps Completed Illustration" />
              <Styled.Description>
                <Styled.Heading>{animationHeading}</Styled.Heading>
                <Styled.SubHeading>{animationSubHeading}</Styled.SubHeading>
              </Styled.Description>
            </Styled.AnimationSubContent>
          )}
        </Styled.Content>
      </Styled.Container>
    </>
  )
}
