import { useMutation, useQueryClient } from '@tanstack/react-query'
import { baseClient } from 'builder/modules/apiClient'

export const useInterviewDelete = (onSuccess?: () => void) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (interviewId: number) => {
      const res = await baseClient.delete(`/interview_prep/interviews/${interviewId}`)
      return res.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['useInterviews'])
      onSuccess && onSuccess()
    },
  })
}
