import { useRef, useState } from 'react'
import { Icon56 } from 'builder/components/Icon'
import LineProgressBar from 'builder/views/Interview/components/InterviewPlayerView/QuestionListSideBar/LineProgressBar'
import { useI18n } from 'builder/hooks/useI18n'
import { InterviewQuestion } from 'builder/modules/interview/types'
import { useClickOutside } from 'builder/hooks/useClickOutside'
import {
  SidebarWrapper,
  Container,
  ImageContainer,
  Title,
  Subtitle,
  Question,
  Line,
  ListItemContainer,
  CloseIcon,
} from './styles'

import { QuestionListItem } from './QuestionListItem/QuestionListItem'
import { QuestionStatus } from './types'

interface Props {
  title: string
  subtitle: string
  currentIndex?: number
  onClose: () => void
  open: boolean
  questions?: InterviewQuestion[]
}

export const QuestionListSideBar = ({
  onClose,
  title,
  subtitle,
  currentIndex = 0,
  open,
  questions,
}: Props) => {
  const { i18n } = useI18n()
  const ref = useRef<HTMLDivElement>(null)
  useClickOutside(ref, onClose)

  const getStatus =
    (index: number) =>
    (currentIndex: number): QuestionStatus => {
      if (index === currentIndex) {
        return 'selected'
      }
      return index < currentIndex ? 'completed' : 'unselected'
    }

  const [enabledCloseAnimation, setEnabledCloseAnimation] = useState(false)
  const handleAnimationEnd = () => {
    if (enabledCloseAnimation) {
      setEnabledCloseAnimation(false)
    }
  }

  if (!open && !enabledCloseAnimation) {
    return null
  }

  return (
    <SidebarWrapper $open={open} onAnimationEnd={handleAnimationEnd} ref={ref}>
      <ImageContainer>
        <Icon56.Star />
        <CloseIcon
          onClick={() => {
            setEnabledCloseAnimation(true)
            onClose()
          }}
        />
      </ImageContainer>
      <Container>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <Question>{i18n.t('builder.interview_prep_view.interview_player.questions')}</Question>
        <Line />
        <ListItemContainer>
          {questions?.map((q, index) => (
            <QuestionListItem
              key={q.id}
              index={index + 1}
              question={q.content}
              status={getStatus(index)(currentIndex)}
            />
          ))}
        </ListItemContainer>
      </Container>
      {questions && (
        <LineProgressBar score={Math.floor((currentIndex / questions?.length) * 100)} />
      )}
    </SidebarWrapper>
  )
}
