import PropTypes from 'prop-types'

import { Container, Header, Title, Description } from './styles'

const SignInSection = ({ title, description, children }) => {
  return (
    <Container>
      <Header>
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
      </Header>
      {children}
    </Container>
  )
}

SignInSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.any,
}

export default SignInSection
