import { useMemo } from 'react'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/careerProfile'
import { useCareerInsightsLoadingEffect } from './useCareerInsightsLoadingEffect'

export const usePitchTextParts = () => {
  const careerInsightsData = useTypedSelector(selectors.careerInsightsData)
  const results = careerInsightsData?.professionalPitchResults

  useCareerInsightsLoadingEffect()

  return useMemo(() => {
    if (!results) {
      return []
    }
    return [results.positioning, results.diff, results.results, results.close]
  }, [results])
}
