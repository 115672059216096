import styled from 'styled-components'
import { TransitionStatus } from 'react-transition-group'
import { Typography } from 'builder/styles/typography'
import { Colors } from 'builder/styles/colors'

export const Wrapper = styled.div`
  position: relative;
  ${Typography.Body};
  .react-tel-input input {
    width: 100%;
    background-color: ${Colors.Neutral10};
    height: 48px;
    border: none;
    border-radius: 3px;
    font-size: inherit;
    ${Typography.Caption};
  }

  .flag-dropdown {
    border: none;
    background-color: ${Colors.Neutral10};
    .selected-flag {
      height: 70%;
      margin-top: 8px;
      border-right: 1px solid ${Colors.Neutral20};
      background-color: ${Colors.Neutral10} !important;
    }
    &.open {
      background-color: ${Colors.Neutral10} !important;
    }
    .selected-flag:hover {
      background-color: ${Colors.Neutral10};
    }
  }
`

export const LabelContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
`

export const Label = styled.label<{ hasError?: boolean }>`
  display: flex;
  align-items: center;
  ${Typography.Caption};
  color: ${({ hasError }) => (hasError ? Colors.Red50 : Colors.Neutral50)};
  justify-content: space-between;
`

export const LabelContent = styled.div`
  display: inline-block;
`

export const Bar = styled.div<{
  $hasError?: boolean
  $hasWarning?: boolean
  $in: TransitionStatus
}>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: ${({ $hasError, $hasWarning }) => {
    if ($hasError) return Colors.Red50
    if ($hasWarning) return Colors.Amber40
    return Colors.Blue50
  }};
  border-radius: 0 0 2px 2px;
  pointer-events: none;

  ${props =>
    ({
      entering: 'opacity: 1; transform: scaleX(1); transition: transform 0.1s ease;',
      entered: 'opacity: 1; transform: scaleX(1)',
      exiting: 'opacity: 0; transform: scaleX(1); transition: opacity 0.1s ease;',
      exited: 'opacity: 0; transform: scaleX(0.5)',
      unmounted: null,
    }[props.$in])};
`

export const Required = styled.span`
  color: ${Colors.Red50};
`
