import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import { TooltipQuestion } from 'builder/components/Tooltip/variations'
import { Icon24 } from 'builder/components/Icon'
import Tooltip from 'builder/components/Tooltip/Tooltip'

export const Container = styled.div`
  ${Typography.Caption};
  gap: 4px;
  display: flex;
  align-items: center;
  color: ${Colors.Blue50};
`

export const DesktopHintContainer = styled(TooltipQuestion)<typeof TooltipQuestion>`
  & > div {
    width: 354px;
    left: auto;
    right: 0;

    & > svg {
      left: -15px;
      right: auto;
      color: ${Colors.Neutral90};
    }
  }
`

export const TipsTitle = styled.div`
  ${Typography.Caption};
  color: ${Colors.Blue50};

  &:hover {
    color: ${Colors.Blue60};
  }
`

export const HintIcon = styled(Icon24.HintOutlined)`
  width: 20px;
  height: 20px;
  color: ${Colors.Blue50};

  &:hover {
    color: ${Colors.Blue60};
  }
`

export const TipsContainer = styled.div`
  width: 50px;
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 4px;

  &:hover {
    ${TipsTitle} {
      color: ${Colors.Blue60};
    }

    ${HintIcon} {
      color: ${Colors.Blue60};
    }
  }
`

export const TooltipQuestionWrapper = styled(Tooltip)`
  & > div {
    width: 353px;
    left: auto;
    right: 375px;

    & > svg {
      left: -15px;
      right: auto;
      color: ${Colors.Neutral90};
    }

    .hint-icon {
      color: ${Colors.Blue50};

      &:hover {
        color: ${Colors.Blue60};
      }
    }
  }

  .tips-container {
    width: auto;
  }
`

export const ToolTipIcon = styled.img`
  width: 50px;
  cursor: pointer;
  height: 20px;
  margin-bottom: 10px;
`
