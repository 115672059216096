import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Button from 'builder/components/Button'
import { Badge } from 'builder/components/Badge'

export const Container = styled.div`
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-flow: column nowrap;

  background-color: ${Colors.Neutral70};
`

export const Toolbar = styled.div`
  height: 64px;
  flex-shrink: 0;
  background-color: ${Colors.Neutral100};
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${Colors.White};
  padding: 0 20px;
  position: relative;
`

export const CloseWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const CloseButton = styled.div`
  color: ${Colors.White};
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  margin-right: -4px;

  &:hover {
    color: ${Colors.Blue50};
  }
`

export const MobileTemplateSettingsIcon = styled.div`
  color: ${Colors.White};
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Content = styled.div`
  flex: 1 0 0;
  overflow-y: auto;
  padding: 0 20px;
`

export const Preview = styled.div`
  width: 928px;
  height: ${928 / 0.71}px;
  border-radius: 6px;
  margin: 60px auto;
  background-color: ${Colors.White};
  background-image: url(${require('images/templates/png/new_york.png')});
  background-size: cover;
`

export const PreviewBox = styled.div`
  position: relative;
  margin: 20px auto;
`

export const Buttons = styled.div`
  position: relative;
  display: flex;
  z-index: 4;
  transform: translateZ(0);
`

export const Actions = styled.div`
  position: relative;
  display: flex;
  margin-left: 8px;
`

export const ActionsButton = styled(Button)`
  width: 36px;
  height: 36px;
  padding: 0;
`

export const MobilePaginationPanel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6px;

  ${Typography.Tiny};
  color: ${Colors.White};
  text-align: center;
`

export const MobilePaginationCounter = styled.div`
  display: inline-block;
  margin: 0 4px;
  font-variant-numeric: tabular-nums;
`

export const MobilePaginationButton = styled.div<{ isEnabled: boolean }>`
  display: inline-block;
  visibility: ${props => (props.isEnabled ? 'visible' : 'hidden')};
`

export const MobilePaginationPrev = styled(MobilePaginationButton)`
  transform: scaleX(-1);
`

export const MobilePaginationNext = styled(MobilePaginationButton)``

export const TemplateLabel = styled(Badge).attrs({ size: 'medium', variant: 'neutral' })`
  position: absolute;
  z-index: 5;
  top: 8px;
  right: 8px;
  transform: translateZ(0);
`
