import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { createSuggestionsApiFetcher } from 'builder/components/AsyncAutosuggest'
import { Icon24 } from 'builder/components/Icon'

import { useLocalStorage } from 'builder/hooks/useLocalStorage'
import { useClickOutside } from 'builder/hooks/useClickOutside'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { useI18n } from 'builder/hooks/useI18n'

import {
  actions as jobSearchActions,
  selectors as jobSearchSelectors,
} from 'builder/modules/jobSearch'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { AlertTypes } from 'builder/modules/jobSearch/types'
import { createNewLocationSuggestionsApiFetcher } from '../utils/createNewSuggestionsApiFetcher'
import { NearMe } from '../styles'
import { Filters, TabType } from '../types'
import { SearchSuggestionType } from '../AutoSuggestField/types'
import { UpdateParamsType } from '../useJobSearch'
import { DEFAULT_FILTERS } from '../JobFilters/constants'
import {
  getRecentLocationSuggestion,
  generateLocationSuggestion as _generateLocationSuggestion,
  handleLocationParameterEvent,
} from './utils'

import {
  WidthContainer,
  Bar,
  Fields,
  CenteredTextMessage,
  FixedContainer,
  SearchButton,
  MajorAutoSuggestField,
  Separator,
  CustomItemSuggestion,
} from './styles'
import {
  DefaultSuggestion,
  LOCAL_STORAGE_JS_HISTORY_QUERY_KEY,
  LOCAL_STORAGE_JS_HISTORY_LOCATIONS_KEY,
} from './constants'
import { FakeSearchInput } from './FakeSearchInput/FakeSearchInput'

export const SEARCH_BAR_HEIGHT = 204

export type SearchBarType =
  | 'fixed'
  | 'scrollable_initial'
  | 'scrollable_expanded'
  | 'job'
  | undefined

interface Props {
  searchBarType: SearchBarType
  showBlackValues: boolean
  onSearch?: () => void
  term: string
  timeInterval: string
  nearMeLocation?: SearchSuggestionType
  locationText: string
  selectedLocation?: SearchSuggestionType
  setScrollableSearchBarType?: (type: SearchBarType) => void
  handleChangeSelectedTabId?: (tab: TabType) => void
  updateSearchParams?: (obj: UpdateParamsType) => void
  setTerm: (term: string) => void
  setTimeInterval: (a: string) => void
  setLocationText: (location: string) => void
  setSelectedLocation: (location?: SearchSuggestionType) => void
  selectedTabId?: TabType
  filters?: Filters
}

const SearchBar = ({
  showBlackValues,
  searchBarType,
  onSearch,
  term,
  nearMeLocation,
  locationText,
  selectedLocation,
  setScrollableSearchBarType,
  handleChangeSelectedTabId,
  updateSearchParams,
  setTerm,
  setLocationText,
  setSelectedLocation,
  selectedTabId,
  filters = DEFAULT_FILTERS,
}: Props) => {
  const { features } = useFeaturesConfig()

  const fixedContainerRef = useRef(null)

  const [recentSearches, setRecentSearches] = useLocalStorage<string[]>(
    LOCAL_STORAGE_JS_HISTORY_QUERY_KEY,
    [],
  )
  const [recentLocations, setRecentLocations] = useLocalStorage<string[]>(
    LOCAL_STORAGE_JS_HISTORY_LOCATIONS_KEY,
    [],
  )

  const [defaultJobTitles, setDefaultJobTitles] = useState<DefaultSuggestion[]>([])
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const { i18n } = useI18n()
  const handleSearchFunctionRef = useRef(() => {})

  const enableJobAlertFeature = useTypedSelector(jobSearchSelectors.enableJobAlertFeature)

  useClickOutside(fixedContainerRef, () => {
    if (searchBarType !== 'scrollable_expanded') {
      return
    }
    if (setScrollableSearchBarType) {
      setScrollableSearchBarType('scrollable_initial')
    }
  })

  const dispatch = useDispatch()

  useEffect(() => {
    const jobTitles: DefaultSuggestion[] = []
    if (recentSearches.length) {
      const history = recentSearches
        .slice(-3)
        .reverse()
        .map(query => ({ text: query }))
      jobTitles.push({ title: 'Recent', suggestions: history })
    }
    setDefaultJobTitles(jobTitles)
  }, [recentSearches])

  const handleSearch = useCallback(async () => {
    if (searchBarType === 'scrollable_expanded' && setScrollableSearchBarType) {
      setScrollableSearchBarType('scrollable_initial')
    }

    if (handleChangeSelectedTabId) {
      handleChangeSelectedTabId(TabType.search)
    }

    trackInternalEvent('search_for_job', {
      term,
      location: handleLocationParameterEvent(selectedLocation, nearMeLocation),
      period: filters.postedWithinDays,
    })
    setShowErrorMessage(term === '' && !filters.onlyRemoteJobs && !selectedLocation)
    // must have some title or location to do the search
    if (term === '' && !selectedLocation) {
      return
    }

    if (updateSearchParams) {
      updateSearchParams({})
    }
    if (onSearch) {
      onSearch()
    }
  }, [
    onSearch,
    dispatch,
    handleChangeSelectedTabId,
    nearMeLocation,
    searchBarType,
    selectedLocation,
    setScrollableSearchBarType,
    term,
    updateSearchParams,
    filters.onlyRemoteJobs,
  ])

  // make the event listener to have the latest version of handleSearch
  handleSearchFunctionRef.current = handleSearch
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleSearchFunctionRef.current()
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  const recentLocationSuggestion = useMemo(
    () => getRecentLocationSuggestion(recentLocations),
    [recentLocations],
  )

  const generateLocationSuggestion = useMemo(() => {
    const nearMeLocationFormatted = (
      <>
        {nearMeLocation?.formatted}&nbsp;
        <NearMe>({i18n.t('builder.job_search.search_bar.location.near_me')})</NearMe>
      </>
    )
    return _generateLocationSuggestion(
      i18n.t,
      false,
      nearMeLocation,
      nearMeLocationFormatted,
      recentLocationSuggestion,
    )
  }, [i18n, nearMeLocation, recentLocationSuggestion])

  const handleQuerySuggestionSelected = useCallback(
    (selectedSuggestion: SearchSuggestionType) => {
      setTerm(selectedSuggestion.text)
      setShowErrorMessage(false)
      const findIndex = recentSearches.findIndex(
        search => search.toLowerCase() === selectedSuggestion.text.toLowerCase(),
      )
      if (findIndex !== -1) {
        recentSearches.splice(findIndex, 1)
      }
      setRecentSearches([...recentSearches, selectedSuggestion.text])
    },
    [recentSearches, setRecentSearches, setTerm],
  )

  const handleLocationSuggestionSelected = useCallback(
    (selectedItem: SearchSuggestionType) => {
      setShowErrorMessage(false)
      setLocationText(
        selectedItem.locationType === 'near_me' && nearMeLocation
          ? nearMeLocation.formatted || ''
          : selectedItem.formatted || '',
      )
      setSelectedLocation(selectedItem)

      trackInternalEvent('pick_location', {
        label: 'job_search',
      })

      if (selectedItem?.locationType === 'location_id') {
        const newLocation = JSON.stringify(selectedItem)
        const findIndex = recentLocations
          .map(l => JSON.parse(l))
          .findIndex(l => l.value === selectedItem.value && l.formatted === selectedItem.formatted)
        if (findIndex !== -1) {
          recentLocations.splice(findIndex, 1)
        }
        setRecentLocations([...recentLocations, newLocation])
      }
    },
    [nearMeLocation, recentLocations, setLocationText, setRecentLocations, setSelectedLocation],
  )

  const handleChangeTerm = useCallback(
    (term: string) => {
      setTerm(term)
      setShowErrorMessage(false)
    },
    [setTerm, recentLocations, setLocationText, setRecentLocations, setSelectedLocation],
  )

  if (searchBarType === 'scrollable_initial') {
    return (
      <FakeSearchInput
        value={term}
        onClear={() => setTerm('')}
        onClick={() => {
          if (setScrollableSearchBarType) {
            setScrollableSearchBarType('scrollable_expanded')
          }
        }}
      />
    )
  }

  return (
    <FixedContainer
      $searchBarType={searchBarType}
      ref={fixedContainerRef}
      $isDashboardV2={features.superApp || features.international}
      $hasMissingFields={showErrorMessage}
    >
      <WidthContainer>
        <Bar>
          <Fields $searchBarType={searchBarType}>
            <MajorAutoSuggestField
              value={showBlackValues ? '' : term}
              onClick={() => {
                if (selectedTabId === TabType.recommendation && handleChangeSelectedTabId) {
                  handleChangeSelectedTabId(TabType.search)
                }
              }}
              overwritePopupWidth={481}
              onChange={handleChangeTerm}
              placeholder={i18n.t('builder.job_search.search_bar.placeholders.term')}
              icon={<Icon24.NewSearch />}
              defaultSuggestions={defaultJobTitles}
              fetchItems={createSuggestionsApiFetcher('job-title', {
                limit: 5,
              })}
              onClear={() => handleChangeTerm('')}
              onSuggestionSelected={handleQuerySuggestionSelected}
              renderCustomItemSuggestion={suggestion => {
                if (enableJobAlertFeature) {
                  return (
                    <CustomItemSuggestion
                      onClick={event => {
                        dispatch(
                          jobSearchActions.setAlertType({
                            type: AlertTypes.create,
                            editCustomAlert: {
                              targetRole: suggestion.text,
                              location: selectedLocation?.formatted,
                              locationId:
                                typeof selectedLocation?.value === 'string'
                                  ? parseInt(selectedLocation.value)
                                  : undefined,
                            },
                          }),
                        )
                        event.stopPropagation()
                      }}
                    >
                      {i18n.t('builder.job_search.create_job_alert')}
                    </CustomItemSuggestion>
                  )
                }
                return null
              }}
            />
            <Separator />
            <MajorAutoSuggestField
              overwritePopupWidth={481}
              value={showBlackValues ? '' : locationText}
              onClick={() => {
                if (selectedTabId === TabType.recommendation && handleChangeSelectedTabId) {
                  handleChangeSelectedTabId(TabType.search)
                }
              }}
              onChange={e => {
                setLocationText(e)
                setSelectedLocation(undefined)
              }}
              onBlur={() => {
                if (!selectedLocation) {
                  setLocationText('')
                }
              }}
              onSuggestionSelected={handleLocationSuggestionSelected}
              placeholder="Location"
              icon={<Icon24.NewLocation />}
              renderIconMenu={() => <Icon24.NewLocation />}
              defaultSuggestions={generateLocationSuggestion}
              getSuggestionValue={suggestion => suggestion.formatted || suggestion.text}
              fetchItems={createNewLocationSuggestionsApiFetcher}
            />
            <SearchButton onClick={handleSearch}>
              {i18n.t('builder.job_search.search_bar.button')}
            </SearchButton>
          </Fields>
        </Bar>
      </WidthContainer>
      {showErrorMessage && (
        <CenteredTextMessage>
          {i18n.t('builder.job_search.search_bar.empty_search_input')}
        </CenteredTextMessage>
      )}
    </FixedContainer>
  )
}

export default SearchBar
