import { useCallback, FC, useState } from 'react'
import throttle from 'lodash/throttle'
import { Answer, Question } from 'builder/modules/careerProfile'
import { EditIcon } from 'builder/components/DocumentEditor/AICoverLetter/styles'
import SparkesIcon from './assets/sparkles.svg'
import {
  MessageLineContainer,
  AvatarContainer,
  StyledOuterDiv,
  StyledText,
  HintContainer,
  EditMessageButton,
  StyledOuterDivHint,
  StyledTextHint,
} from './styles'
import { ChatMessageType } from './types'
import { BubbleCorner } from './BubbleCorner'

export const MessageLine: FC<{
  type: ChatMessageType
  children?: Question | Answer
  avatar?: JSX.Element
  last?: boolean
  content?: JSX.Element
  setEditMessage?: (message: Answer | null) => void
  handleEditTracking?: () => void
  amountOfWords?: number
}> = ({
  type,
  children,
  avatar,
  last,
  setEditMessage,
  content,
  handleEditTracking,
  amountOfWords,
}) => {
  const avatarC = <AvatarContainer $type={type}>{avatar}</AvatarContainer>
  const [showEdit, _setShowEdit] = useState(false)
  const setShowEdit = useCallback(
    throttle((toggle: boolean) => {
      _setShowEdit(toggle)
    }, 600),
    [],
  )

  return (
    <MessageLineContainer
      $type={type}
      onClick={() => setShowEdit(true)}
      onMouseEnter={() => setShowEdit(true)}
      onMouseLeave={() => setTimeout(() => setShowEdit(false), 500)}
    >
      {/* keep the padding */}
      {last && avatar && type === 'asked' ? avatarC : null}

      {showEdit && type === 'answer' && setEditMessage && (children || content) && (
        <EditMessageButton
          onClick={() => {
            setEditMessage(children || null)
            if (handleEditTracking) {
              handleEditTracking()
            }
          }}
        >
          <EditIcon />
        </EditMessageButton>
      )}

      {!showEdit && type === 'answer' && (
        <EditMessageButton style={{ opacity: 0 }}>
          <EditIcon />
        </EditMessageButton>
      )}

      {!last && avatar && type === 'asked' ? (
        <div style={{ height: 0, opacity: 0 }}>{avatarC}</div>
      ) : null}
      {type === 'asked' ? (
        <div>
          <StyledOuterDiv
            $type={type}
            $hasOneOrTwoWords={amountOfWords === 1 || amountOfWords === 2}
          >
            {content || (children && <StyledText>{children.text}</StyledText>)}
            <BubbleCorner type={type} />
          </StyledOuterDiv>
          {(children as Question)?.hint && last && type === 'asked' ? (
            <HintContainer>
              <img src={SparkesIcon} />
              <StyledOuterDivHint>
                <StyledTextHint>{(children as Question)?.hint}</StyledTextHint>
              </StyledOuterDivHint>
            </HintContainer>
          ) : null}
        </div>
      ) : (
        <StyledOuterDiv $type={type} $hasOneOrTwoWords={amountOfWords === 1 || amountOfWords === 2}>
          {content || (children && <StyledText>{children.text}</StyledText>)}
          <BubbleCorner type={type} />
        </StyledOuterDiv>
      )}
      {last && avatar && type === 'answer' ? avatarC : null}

      {/* keep the padding */}
      {!last && avatar && type === 'answer' ? <div style={{ opacity: 0 }}>{avatarC}</div> : null}
    </MessageLineContainer>
  )
}
