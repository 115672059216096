import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { useRef, useEffect, useCallback } from 'react'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { actions, selectors } from 'builder/modules/careerCoaching'
import { trackingInfo } from 'builder/modules/careerCoaching/constants'
import CareerCoachingPayment from 'builder/components/CareerCoachingPayment'
import FrequentCoachingQuestions from 'builder/components/FrequentCoachingQuestions'

import { Container, TopContainer, BottomContainer } from './styles'
import { Footer } from './components/Footer'
import { CareerCouchingPlanType } from './types'

const useUpdateBodyClass = (className: string) => {
  const location = useLocation()

  useEffect(() => {
    document.body.className = className
    return () => {
      document.body.className = ''
    }
  }, [location.pathname, className])
}

const CareerCoachingPaymentView = (): JSX.Element => {
  const dispatch = useDispatch()
  const location = useLocation()
  const isMounted = useRef(false)
  const intakePreferences = localStorage.getItem('INTAKE_PREFERENCES')
  const parsedPreferences = intakePreferences ? JSON.parse(intakePreferences) : null

  const isUserRedirectTo = useTypedSelector(selectors.isUserRedirectTo)
  const {
    id,
    value: { services, careerStages },
  } = useTypedSelector(selectors.intakeTestDetails)

  useUpdateBodyClass('coaching')

  const getPlan = useCallback(
    (services: string[], careerStages: string): CareerCouchingPlanType => {
      if (careerStages === 'Executive') return 'executive_coaching_plan'

      // temporary disabled, will be back in https://teamtalent.atlassian.net/browse/CPLAN-933
      // if (services.length === 1 && services[0] === 'Job Search Strategy') {
      //   return 'job_search_plan'
      // }

      return 'career_service_plan'
    },
    [],
  )

  const getPlanType = useCallback(() => {
    if (parsedPreferences) {
      const { services: parsedServices, careerStages: parsedCareerStages } = parsedPreferences
      return getPlan(parsedServices, parsedCareerStages)
    }

    return getPlan(services, careerStages)
  }, [parsedPreferences, careerStages, services, getPlan])

  const planType = getPlanType()

  const getPaymentPage = useCallback(() => {
    switch (planType) {
      case 'executive_coaching_plan':
        return 'executive'
      case 'job_search_plan':
        return 'execute_a_job_search'
      default:
        return 'coach_marketplace'
    }
  }, [planType])

  useEffect(() => {
    const { searchParams } = new URL(window.location.href)
    const paymentReason = searchParams.get('payment_reason')

    if (isUserRedirectTo && !paymentReason) {
      dispatch(actions.fetchRedirectRoutes({ currentUrl: location.pathname }))
      dispatch(actions.setUserRedirectState(false))
    }
  }, [dispatch, isUserRedirectTo, location.pathname])

  useEffect(() => {
    dispatch(actions.fetchIntakeTestDetails())
  }, [dispatch])

  useEffect(() => {
    if (id && !isMounted?.current) {
      isMounted.current = true

      const params = { planType }
      dispatch(actions.fetchCoachingPlans(params))

      trackInternalEvent('coaching_plans', {
        ...trackingInfo,
        payment_page_name: getPaymentPage(),
      })
    }
  }, [dispatch, planType, id, services, careerStages, getPaymentPage])

  useEffect(() => {
    return () => {
      dispatch(actions.setCoachingPlans([]))
    }
  }, [dispatch])

  return (
    <Container>
      <TopContainer>
        <CareerCoachingPayment />
      </TopContainer>
      <BottomContainer>
        <FrequentCoachingQuestions />
      </BottomContainer>
      <Footer />
    </Container>
  )
}

export default CareerCoachingPaymentView
