import { ReactElement, useCallback } from 'react'
import { useI18n } from 'builder/hooks/useI18n'
import { formatDate } from 'builder/utils/formatDate'
import {
  Candidate,
  Distribution,
  SubscriptionStatus,
  Weeks,
} from 'builder/modules/resumeDistribution'

import { Icon20 } from 'builder/components/Icon'
import { TooltipQuestion } from 'builder/components/Tooltip/variations'

import { TRANSLATION } from '../constants'
import { Box, Header, Section, SubHeader, TooltipWrapper } from '../styles'
import { ActionButton, LoadingButton, ButtonWrapper } from './styles'

type IProps = {
  hideSchedule: boolean
  isFromWidget?: boolean
  candidate?: Candidate
  nextDistribution: Distribution
  isLoading: boolean
  action: () => void
}

export const NextSchedule = ({
  candidate,
  nextDistribution,
  isLoading,
  action,
  hideSchedule,
  isFromWidget,
}: IProps) => {
  const { i18n } = useI18n()

  const getDate = useCallback(() => {
    const { day, month, year } = nextDistribution || {}
    if (day && month && year) {
      return formatDate(new Date(`${month}-${day}-${year}`), 'MMM do, yyyy')
    }
  }, [nextDistribution])

  const getHeaderSubheader = useCallback(() => {
    const data: { header?: string; subheader?: string } = {}

    if (candidate?.distributionStatus === SubscriptionStatus.active) {
      data.header = getDate()
      data.subheader = i18n.t(`${TRANSLATION}.next_schedule`)
    } else {
      data.header = i18n.t(`${TRANSLATION}.pause_header`)

      if (candidate?.distributionWeek === Weeks.fourth) {
        data.subheader = i18n.t(`${TRANSLATION}.pause_subheader_cycle_ended`)
      } else {
        data.subheader = i18n.t(`${TRANSLATION}.pause_subheader`)
      }
    }

    return data
  }, [candidate?.distributionStatus, candidate?.distributionWeek, getDate, i18n])

  const getIconAndLabel = useCallback(() => {
    const data: { label?: string; icon?: ReactElement } = {}

    if (candidate?.distributionStatus === SubscriptionStatus.active) {
      data.label = i18n.t(`${TRANSLATION}.pause_action`)
      data.icon = <Icon20.Pause />
    } else {
      data.label = i18n.t(`${TRANSLATION}.resume_action`)
      data.icon = <Icon20.Play />
    }
    return data
  }, [candidate?.distributionStatus, i18n])

  if (hideSchedule) return <></>

  return (
    <Section $isFromWidget={isFromWidget}>
      <Box>
        <Header $isFromWidget={isFromWidget}>{getHeaderSubheader().header}</Header>
        <SubHeader>
          {getHeaderSubheader().subheader}
          {candidate?.distributionStatus === SubscriptionStatus.active && (
            <TooltipWrapper>
              <TooltipQuestion textAlign="left" multiline width={isFromWidget ? 240 : 300}>
                {i18n.t(`${TRANSLATION}.next_schedule_tooltip`, { date: getDate() })}
              </TooltipQuestion>
            </TooltipWrapper>
          )}
        </SubHeader>
      </Box>
      {!isFromWidget && (
        <ButtonWrapper>
          {isLoading ? (
            <LoadingButton />
          ) : (
            <ActionButton onClick={() => action()}>
              {getIconAndLabel().icon}
              {getIconAndLabel().label}
            </ActionButton>
          )}
        </ButtonWrapper>
      )}
    </Section>
  )
}
