import { useNavigate } from 'react-router-dom'
import { useCallback, useEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useSelector } from 'react-redux'
import Button from 'builder/components/Button'
import { selectors as userSelectors } from 'builder/modules/user'
import { trackingInfo } from 'builder/modules/careerCoaching/constants'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import groupURL from './assets/group.svg'

import {
  Container,
  Content,
  ButtonContainer,
  Heading,
  SubHeader,
  CareerEntryImage,
  Name,
  Footer,
  SupportUrl,
  Description,
} from './styles'

const NoCoachAvailable = () => {
  const { isPhone } = useMediaQueries()
  const user = useSelector(userSelectors.userData)
  const navigate = useNavigate()

  useEffect(() => {
    trackInternalEvent('enter_no_coach_available_list', { ...trackingInfo })
  }, [])

  const handleClick = useCallback(() => {
    trackInternalEvent('click_continue_button_no_coach_available_list', { ...trackingInfo })

    navigate(`/sessions`)
  }, [navigate])

  const supportUrl = <SupportUrl href="mailto:coaching@career.io">coaching@career.io</SupportUrl>

  return (
    <Container data-testid="no-coach-available">
      <CareerEntryImage src={groupURL} />
      <Content>
        <Heading>Sorry, finding a coach match is taking longer than expected</Heading>
        <SubHeader>
          We&apos;ll send you a match within 1 business day. Check your email for the update,
          <Name>{user?.email}.</Name>
        </SubHeader>
      </Content>
      <ButtonContainer>
        <Button onClick={handleClick}>Continue</Button>
      </ButtonContainer>

      <Footer>
        {isPhone ? (
          <>
            <Description>If you have questions about this coaching service,</Description>
            <Description>
              reach out to our support via email
              {supportUrl}
            </Description>
          </>
        ) : (
          <Description>
            If you have questions about this coaching service, reach out to our support via email
            {supportUrl}
          </Description>
        )}
      </Footer>
    </Container>
  )
}

export default NoCoachAvailable
