import PropTypes from 'prop-types'
import { useI18n } from 'builder/hooks/useI18n'
import { Icon24 } from 'builder/components/Icon'
import { SPACING } from 'builder/modules/constants'
import { SpacingContainer, SpacingIcon, SpacingButton, Tooltip, TooltipValue } from './styles'
import { trackInternalEvent } from '@rio/tracking'
import { useCallback } from 'react'

const SpacingControl = ({ value, isSupported, onChange, isTopbarLayout }) => {
  const { i18n } = useI18n()
  const FontSizeIcon = isSupported ? Icon24.FontSize : Icon24.FontSizeLock

  const changeSpacing = useCallback(
    operation => {
      const oldValue = value
      const newValue = operation === 'decrease' ? value - SPACING.step : value + SPACING.step
      trackInternalEvent('change_line_spacing', {
        label: isTopbarLayout ? 'builder' : 'templates',
        direction: operation,
        old_value: oldValue,
        new_value: newValue,
      })
      onChange(normalize(newValue))
    },
    [isTopbarLayout, onChange, value],
  )

  const onButtonClick = useCallback(
    operation => {
      changeSpacing(operation)
    },
    [changeSpacing],
  )

  return (
    <SpacingContainer>
      <SpacingButton
        isDisabled={!isSupported || value === SPACING.min}
        onClick={() => onButtonClick('decrease')}
        isTopbarLayout={isTopbarLayout}
      >
        <Icon24.Remove />
      </SpacingButton>

      <SpacingIcon>
        <FontSizeIcon />
      </SpacingIcon>

      <SpacingButton
        isDisabled={!isSupported || value === SPACING.max}
        onClick={() => onButtonClick('increase')}
        isTopbarLayout={isTopbarLayout}
      >
        <Icon24.Add />
      </SpacingButton>

      {isSupported && (
        <Tooltip small>
          {i18n.t('builder.resume_editor.spacing')}
          <TooltipValue>{prettify(value)}</TooltipValue>
        </Tooltip>
      )}

      {!isSupported && <Tooltip>{i18n.t('builder.resume_editor.no_spacing')}</Tooltip>}
    </SpacingContainer>
  )
}

SpacingControl.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  isSupported: PropTypes.bool,
  isCustomizationModal: PropTypes.bool,
  isTopbarLayout: PropTypes.bool,
}

SpacingControl.defaultProps = {
  value: 0,
  isSupported: false,
}

export default SpacingControl

// Protects the value from going out of bounds [-1, 1]
// Haven't seen this kind of error, but anyway better to do that
const normalize = value => Math.min(Math.max(value, SPACING.min), SPACING.max)

// Converts the value to a user-friendly format [50%, 150%]
const prettify = value => `${100 + Math.round(50 * value)}%`
