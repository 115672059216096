import { useI18n } from 'builder/hooks/useI18n'
import { Container, Body, Description, Header, Spinner } from './styles'

export const ProcessingBanner = () => {
  const { i18n } = useI18n()
  const description = i18n.t(`builder.career_profile.status_modal.processing.subtitle`)
  return (
    <Container>
      <Body>
        <Header>
          <Spinner />
          {i18n.t(`builder.career_profile.status_modal.processing.title`)}
        </Header>
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      </Body>
    </Container>
  )
}
