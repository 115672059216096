import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Button from 'builder/components/Button'

const { Neutral90, Red50, White, Indigo50 } = Colors

export const Container = styled.div`
  width: 520px;
  border-radius: 16px;
  background: ${White};
  padding: 82px 74px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  ${Media.Phone`
    width: 343px;
    padding: 42px 20px;
  `};
`

export const Heading = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};
  color: ${Neutral90};
  text-align: center;
  margin-bottom: 12px;
  ${Media.Phone`
    ${Typography.S};
    margin-bottom: 8px;
  `}
`
export const Subheading = styled.div`
  ${Typography.Caption};
  color: ${Red50};
  text-align: center;
`

export const GenerationFailedImg = styled.img`
  width: 178px;
  height: 168px;
  margin-bottom: 36px;
  ${Media.Phone`
    width: 128px;
    height: 121px;
    margin-bottom: 16px;
  `}
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  ${Media.Phone`
    margin: 16px;
  `}
`

export const TryAgain = styled(Button)`
  padding: 12px 24px;
  background-color: ${Indigo50};
  ${Typography.Body};
  ${FontWeights.Medium};
  &:hover {
    background-color: ${Colors.Indigo60};
  }
`

export const StartOver = styled(Button)`
  padding: 12px 24px;
  margin-right: 16px;
  ${Typography.Body};
  ${FontWeights.Medium};
`
