import Foldable from 'builder/components/Foldable'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/sevenStories'
import StoriesList from './StoriesList'
import {
  StoriesTitleWrapper,
  StoriesWrapper,
  Title,
  StoriesTitleContainer,
  StoryTitle,
  OpenSectionIcon,
  CloseSectionIcon,
  FoldableContainer,
  RowWrapper,
} from './styles'

type Props = {
  handleShowStory: (id: number) => void
  showStoryId: number
}

const Stories = ({ handleShowStory, showStoryId }: Props) => {
  const assessmentResult = useTypedSelector(selectors.assessmentResult)

  const getRenderStoriesTitle = () => {
    return (
      <>
        {assessmentResult.accomplishments.map((result, index) => (
          <StoriesTitleContainer key={index}>
            <RowWrapper>
              <StoriesTitleWrapper>
                <StoryTitle>Story {index + 1}</StoryTitle>
                <div>{result.title}</div>
              </StoriesTitleWrapper>
              {result.id === showStoryId ? (
                <OpenSectionIcon onClick={() => handleShowStory(0)} />
              ) : (
                <CloseSectionIcon onClick={() => handleShowStory(result.id)} />
              )}
            </RowWrapper>
            <FoldableContainer>
              <Foldable isOpen={result.id === showStoryId}>
                <StoriesList
                  story={[
                    result.story1,
                    result.story2,
                    result.story3,
                    result.story4,
                    result.story5,
                    result.story6,
                    result.story7,
                  ]}
                />
              </Foldable>
            </FoldableContainer>
          </StoriesTitleContainer>
        ))}
      </>
    )
  }

  return (
    <StoriesWrapper>
      <Title>Your Seven Stories</Title>
      {getRenderStoriesTitle()}
    </StoriesWrapper>
  )
}

export default Stories
