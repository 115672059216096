import styled from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'

export const Illustration = styled.img`
  display: block;
  width: 100%;
`

export const Photo = styled.img`
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid ${Colors.Indigo50};
  background-color: ${Colors.White};
  box-shadow: 0 16px 24px ${hexToRgba(Colors.Indigo50, 0.24)};
`
