import { useState } from 'react'
import { useSelector } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { Icon24, Icon20, Icon16 } from 'builder/components/Icon'
import { PercentageCircle } from 'builder/components/PercentageCircle/PercentageCircle'
import Colors from 'builder/styles/colors'
import {
  DailySuggestionsError,
  InterviewTemplate,
  QuestionTemplate,
  Status,
} from 'builder/modules/interview/types'

import { QUESTION_DURATION } from 'builder/views/Interview/constants'
import { useDailySuggestions } from 'builder/views/Interview/hooks/useDailySuggestions'
import { selectors } from 'builder/modules/interview/interviewModule'
import { useInterview } from 'builder/views/Interview/hooks/useInterview'
import { ChangeJobModal } from '../ChangeJobModal/ChangeJobModal'
import { Logo } from '../Logo/Logo'
import { PracticeThisInterviewModal } from '../Modals/PracticeThisModal/PracticeThisInterviewModal'
import { PracticeThisQuestionModal } from '../Modals/PracticeThisModal/PracticeThisQuestionModal'
import { BadgeDark } from '../../Badge/BadgeDark'
import { InitialRequest } from '../DashboardView'
import {
  MyInterviewTitle,
  Wrapper,
  TopLeft,
  TopRight,
  Top,
  For,
  Interviews,
  InterviewCard,
  SelectedJobTitle,
  CardTop,
  DoneStatus,
  Duration,
  JobTitle,
  CardBottom,
  Question,
  SubInfo,
  Completed,
  CompletedCount,
  Stats,
  CardSkeleton,
  Spinner,
} from './styles'

const TRANSLATION = 'builder.interview_prep_view.interview_dashboard.my_interviews'

interface Props {
  setInitialRequest: React.Dispatch<React.SetStateAction<InitialRequest | undefined>>
}

export const MyInterviews = ({ setInitialRequest }: Props) => {
  const { i18n } = useI18n()
  const jobTitleId = useSelector(selectors.jobTitleId)
  const jobTitle = useSelector(selectors.jobTitle)
  const { data: dailySuggestions } = useDailySuggestions(jobTitleId, setInitialRequest, true)

  const [showJobTitleModal, setShowJobTitleModal] = useState(false)
  const [selectedInterview, setSelectedInterview] = useState<InterviewTemplate>()
  const [selectedQuestion, setSelectedQuestion] = useState<QuestionTemplate>()
  const isLoading =
    !dailySuggestions || (dailySuggestions as unknown as DailySuggestionsError).error
  const interview = dailySuggestions?.interview_template
  const companyInterview = dailySuggestions?.company_interview
  const questionTemplates = dailySuggestions?.question_templates

  const completedInterview = interview?.status === 'completed' ? 1 : 0
  const completedCompanyInterview = companyInterview?.status === 'completed' ? 1 : 0

  const completedQuestion =
    questionTemplates?.filter(question => question.status !== 'initiated').length || 0
  const completedCount = completedInterview + completedCompanyInterview + completedQuestion
  const totalCount =
    (questionTemplates?.length || 0) + (companyInterview ? 1 : 0) + (interview ? 1 : 0)

  const { data: interviewData } = useInterview({ interviewId: companyInterview?.interview_id })

  const percent = (completedCount / totalCount) * 100

  const onInterviewCardClick = (interview: InterviewTemplate) => {
    if (interview.status === 'initiated' || interview.status === 'cancelled') {
      setSelectedInterview(interview)
      trackInternalEvent('pick_interview', {
        label: 'interview_prep',
        parameter: 'interview_dash_recommendation',
      })
    }
  }

  const onQuestionCardClick = (question: QuestionTemplate) => {
    if (question.status === 'initiated' || question.status === 'cancelled') {
      setSelectedQuestion(question)
      trackInternalEvent('pick_interview', {
        label: 'interview_prep',
        parameter: 'interview_dash_recommendation',
      })
    }
  }

  const renderStatus = (status: Status, duration: number, isQuestion: boolean) => {
    switch (status) {
      case 'completed':
        return (
          <DoneStatus>
            <Icon24.CareerTick /> {i18n.t(`${TRANSLATION}.done`)}
          </DoneStatus>
        )
      case 'cancelled':
      case 'started':
      case 'initiated':
        return (
          <Duration $question={isQuestion}>
            <Icon20.Clock />
            {i18n.t(`${TRANSLATION}.min`, {
              minutes: duration,
            })}
          </Duration>
        )
    }
  }

  const interviewCard = (interview: InterviewTemplate) => {
    const interviewHasAllFeedbackQuestion =
      interviewData && interviewData?.answered_questions === interviewData?.questions_count

    const isSomeFeedbackProcessing = !!interviewData?.questions.find(q => !q.answer?.feedback)

    return (
      <InterviewCard
        key={interview.id}
        $status={interview?.status}
        $question={false}
        onClick={() => onInterviewCardClick(interview)}
      >
        <CardTop>
          {renderStatus(interview.status, interview.questions_count * QUESTION_DURATION, false)}
          {interviewHasAllFeedbackQuestion ||
            (interview.status === 'initiated' && (
              <BadgeDark $status={interview.status} $question={false}>
                {interviewHasAllFeedbackQuestion && isSomeFeedbackProcessing && <Spinner />}
                {i18n.t(`${TRANSLATION}.interview`)}
              </BadgeDark>
            ))}
        </CardTop>
        <CardBottom>
          {interview.logo_url && <Logo src={interview.logo_url} />}
          <JobTitle $status={interview.status}>{jobTitle}</JobTitle>
          <SubInfo>
            {interview.company_name
              ? i18n.t(`${TRANSLATION}.at`, {
                  company: interview.company_name,
                })
              : i18n.t(`${TRANSLATION}.test_interview`)}
            {interview.questions_count > 1 && (
              <> • {i18n.t(`${TRANSLATION}.questions`, { count: interview.questions_count })}</>
            )}
          </SubInfo>
        </CardBottom>
      </InterviewCard>
    )
  }

  const questionCards = (questions: QuestionTemplate[]) => {
    return questions.map(question => (
      <InterviewCard
        key={question.id}
        $status={question.status}
        $question={true}
        onClick={() => onQuestionCardClick(question)}
      >
        <CardTop>
          {renderStatus(question.status, QUESTION_DURATION, true)}
          {question.status === 'started' && <Spinner />}
          {question.status !== 'started' && (
            <BadgeDark $status={question.status} $question={true}>
              {i18n.t(`${TRANSLATION}.question`, { count: 1 })}
            </BadgeDark>
          )}
        </CardTop>
        <CardBottom>
          <Question $status={question.status}>{question.content}</Question>
        </CardBottom>
      </InterviewCard>
    ))
  }

  return (
    <Wrapper>
      <Top>
        <TopLeft>
          <MyInterviewTitle>{i18n.t(`${TRANSLATION}.my_interviews`)}</MyInterviewTitle>{' '}
          {jobTitle && (
            <div>
              <For>{i18n.t(`${TRANSLATION}.for`)}</For>{' '}
              <SelectedJobTitle onClick={() => setShowJobTitleModal(true)}>
                {jobTitle}
                <Icon16.Settings />
              </SelectedJobTitle>
            </div>
          )}
        </TopLeft>
        <TopRight>
          <Stats>
            <CompletedCount>
              {completedCount} of {totalCount}
            </CompletedCount>
            <Completed>{i18n.t(`${TRANSLATION}.completed`)}</Completed>
          </Stats>
          <PercentageCircle percentage={percent} width={36} color={Colors.Blue50} strokeWidth={6} />
        </TopRight>
      </Top>
      <Interviews>
        {isLoading && [1, 2, 3, 4].map(i => <CardSkeleton key={i} />)}
        {!isLoading && interview && interviewCard(interview)}
        {!isLoading && questionTemplates && questionCards(questionTemplates)}
        {!isLoading && companyInterview && interviewCard(companyInterview)}
      </Interviews>
      {showJobTitleModal && (
        <ChangeJobModal
          trackingParameter="interview_dash"
          onClose={() => setShowJobTitleModal(false)}
          jobSuggestion={{
            text: jobTitle,
            id: jobTitleId,
          }}
        />
      )}
      {selectedInterview && (
        <PracticeThisInterviewModal
          trackingParameter="interview_dash_recommendation"
          isTestInterview={!selectedInterview?.company_name}
          interview={selectedInterview}
          interviewId={companyInterview?.interview_id}
          onClose={() => {
            setSelectedInterview(undefined)
          }}
        />
      )}
      {selectedQuestion && (
        <PracticeThisQuestionModal
          trackingParameter="interview_dash_recommendation"
          question={selectedQuestion}
          onClose={() => {
            setSelectedQuestion(undefined)
          }}
        />
      )}
    </Wrapper>
  )
}
