import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  selectors as jobSearchSelectors,
  actions as jobSearchActions,
} from 'builder/modules/jobSearch'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { AlertTypes } from 'builder/modules/jobSearch/types'
import { useQueryParam } from 'builder/hooks/useQueryParam'
import { TabType } from '../types'
import { PARAM_OPEN_JOB_ALERT } from '../SearchBar/constants'
import { DeleteAlert } from './DeleteAlert/DeleteAlert'
import { ConfirmationCreateAlert } from './ConfirmationCreateAlert/ConfirmationCreateAlert'
import { FormAlertModal, FormAlertModalType } from './FormAlertModal/FormAlertModal'
import { ManageYourJobAlerts } from './ManageYourJobAlerts/ManageYourJobAlerts'
interface Props {
  selectedTab?: TabType
}
export const AlertModal = ({ selectedTab }: Props) => {
  const enableJobAlertFeature = useTypedSelector(jobSearchSelectors.enableJobAlertFeature)
  const alert = useTypedSelector(jobSearchSelectors.alert)
  const [openJobAlert, setOpenJobAlert] = useQueryParam(PARAM_OPEN_JOB_ALERT)
  const dispatch = useDispatch()
  useEffect(() => {
    if (enableJobAlertFeature && openJobAlert === 'true') {
      setOpenJobAlert(null)
      dispatch(jobSearchActions.setAlertType({ type: AlertTypes.manage }))
    }
  }, [enableJobAlertFeature, openJobAlert])

  if (!enableJobAlertFeature) {
    return null
  }
  switch (alert.type) {
    case AlertTypes.manage:
      return <ManageYourJobAlerts selectedTab={selectedTab} />
    case AlertTypes.create:
      return <FormAlertModal mode={FormAlertModalType.create} />
    case AlertTypes.edit:
      return <FormAlertModal mode={FormAlertModalType.edit} />
    case AlertTypes.delete:
      return <DeleteAlert />
    case AlertTypes.confirmation_success:
      return <ConfirmationCreateAlert />
    default:
      return null
  }
}
