import { memo } from 'react'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import useResumeWithAI from '../useResumeWithAI'
import QuestionnaireProgress from '../QuestionnaireProgress'
import {
  QuestionnaireStep,
  StepTitle,
  StepHeading,
  StepDescription,
  ProgressContainer,
} from './styles'
import ActionButtons from './ActionButtons'

export const QuestionnaireFlow = memo(() => {
  const {
    continueButtonText,
    step,
    header,
    handleBack,
    handleContinue,
    renderQuestionnaireStep,
    progress,
    showSkipButton,
  } = useResumeWithAI()

  const { isPhone } = useMediaQueries()

  return (
    <QuestionnaireStep>
      <StepHeading isStepWithTabs={[5, 6].includes(step)}>
        <StepTitle>{header.title}</StepTitle>
        <StepDescription>{header.description}</StepDescription>
      </StepHeading>
      {renderQuestionnaireStep}
      {!isPhone && (
        <ActionButtons
          {...{ continueButtonText, currentStep: step, handleBack, handleContinue, showSkipButton }}
        />
      )}
      <ProgressContainer>
        {isPhone && (
          <ActionButtons
            {...{
              continueButtonText,
              currentStep: step,
              handleBack,
              handleContinue,
              showSkipButton,
            }}
          />
        )}
        <QuestionnaireProgress {...{ progress }} />
      </ProgressContainer>
    </QuestionnaireStep>
  )
})
