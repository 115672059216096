import { CustomAlertResponse, CustomAlertType } from 'builder/modules/jobSearch/types'

export const transformerCustomAlert = (response: CustomAlertResponse): CustomAlertType => {
  return {
    frequency: response.frequency,
    id: response.id,
    location: response.location,
    onlyRemoteJobs: response.only_remote_jobs,
    locationId: response.location_id,
    targetRole: response.target_role,
  }
}
