import { useEditor } from '@tiptap/react'
import Placeholder from '@tiptap/extension-placeholder'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Underline from '@tiptap/extension-underline'
import Strike from '@tiptap/extension-strike'
import ListItem from '@tiptap/extension-list-item'
import BulletList from '@tiptap/extension-bullet-list'
import OrderedList from '@tiptap/extension-ordered-list'
import Link from '@tiptap/extension-link'
import History from '@tiptap/extension-history'
import CharacterCount from '@tiptap/extension-character-count'

import { Optional } from 'packages/types'
import { useCallback, useEffect, useRef } from 'react'
import {
  AnimationWrapper,
  EditorWrapper,
  RelativeDiv,
} from 'builder/components/RichTextArea/styles'
import { useRichTextAreaActions, shouldUseAiPhrases } from 'builder/components/RichTextArea'
import { RichTextAreaContext } from 'builder/context/RichTextAreaContext'
import { SuggestionConfig } from 'builder/components/RichTextArea/types'
import { shouldUseAiProfileSummary } from 'builder/components/RichTextArea/utils'
import { Toolbar } from '../Toolbar/Toolbar'
import { ProgressStatus, ProgressBar } from '../ProgressBar/ProgressBar'
import { BottomToolbar } from '../BottomToolbar'
import { replaceCurrentContent } from '../utils'
import * as Styles from './styles'

export interface TiptapEditorProps {
  jobTitle?: Optional<string>
  label?: string
  labelBadges?: JSX.Element[]
  locale?: string
  placeholder?: string
  autoFocus?: boolean
  value?: string
  onChange: (html: string) => void
  recommendedLength?: number
  suggestionConfig?: Optional<SuggestionConfig>
  isAnimating?: boolean
  onAnimationEnd?: () => void
}

export const TiptapEditor = (props: TiptapEditorProps) => {
  const {
    jobTitle = '',
    label,
    placeholder,
    autoFocus,
    value,
    onChange,
    recommendedLength,
    suggestionConfig,
    locale = 'en',
    labelBadges,
    isAnimating,
    onAnimationEnd,
  } = props
  const editor = useEditor({
    onUpdate: ({ editor }) => onChange(editor.getHTML()),
    extensions: [
      Document,
      Paragraph,
      Text,
      Bold,
      Italic,
      Underline,
      Strike,
      ListItem,
      BulletList,
      OrderedList,
      Link.configure({
        openOnClick: false,
        autolink: false,
      }),
      History,
      CharacterCount,
      Placeholder.configure({ placeholder }),
    ],
    content: value,
    autofocus: autoFocus ? 'end' : false,
  })

  const editorContainerRef = useRef<HTMLDivElement>(null)

  const handleAnimationEnd = useCallback(() => {
    const scrollableElement = editor?.options.element.children[0]

    if (scrollableElement) {
      scrollableElement.scrollTop = scrollableElement.scrollHeight
    }

    onAnimationEnd?.()
  }, [editor, onAnimationEnd])

  const {
    animationPlaceholderRef,
    isSuggestionsOpen,
    toggleSuggestions,
    toggleAiProfileSummary,
    suggestionQuery,
    onChangeSuggestionQuery,
    handleOpenHints,
    handleCloseHints,
    isFocused,
    isHintsOpened,
    toggleAiProfileSummaryResult,
    isSuggestionVariantsOpen,
    toggleSuggestionVariants,
  } = useRichTextAreaActions({ ...props, onAnimationEnd: handleAnimationEnd })

  useEffect(() => {
    if (editor && editor.getHTML() !== value) {
      replaceCurrentContent(editor, value || '')
    }
  }, [editor, value])

  if (!editor) {
    // editor is initialized in effect so it is null on first render
    return null
  }

  const textLength: number = editor.storage.characterCount.characters()
  const isAiPhrases = shouldUseAiPhrases(suggestionConfig?.scope, locale)
  const isAiProfileSummary = shouldUseAiProfileSummary(suggestionConfig?.scope, locale)
  const contextValue = {
    jobTitle,
    isSuggestionsOpen,
    toggleSuggestions,
    toggleAiProfileSummary,
    suggestionQuery,
    onChangeSuggestionQuery,
    openHints: handleOpenHints,
    closeHints: handleCloseHints,
    isFocused,
    isHintsOpened,
    hideHintsButtons: () => {},
    isHintsButtonsVisible: false,
    toggleAiProfileSummaryResult,
    isSuggestionVariantsOpen,
    toggleSuggestionVariants,
  }

  return (
    <RichTextAreaContext.Provider value={contextValue}>
      <Styles.Group>
        <Styles.Label>
          <Styles.LabelText>{label}</Styles.LabelText>
          {labelBadges && <Styles.LabelBadges>{labelBadges}</Styles.LabelBadges>}
        </Styles.Label>
        <Styles.TextArea>
          <Toolbar editor={editor} />

          <RelativeDiv>
            {isAnimating && (
              <AnimationWrapper>
                <Styles.AIGeneratedDetails ref={animationPlaceholderRef} />
              </AnimationWrapper>
            )}
            <EditorWrapper isAnimated={!!isAnimating} ref={editorContainerRef}>
              <Styles.EditorContent editor={editor} />
            </EditorWrapper>
          </RelativeDiv>

          <BottomToolbar
            {...{ ...props, locale, isAiPhrases, isAiProfileSummary, editor, editorContainerRef }}
          />

          <ProgressBar
            textLength={textLength}
            recommendedLength={recommendedLength}
            isFocused={editor.isFocused}
          />
        </Styles.TextArea>

        {recommendedLength && (
          <ProgressStatus textLength={textLength} recommendedLength={recommendedLength} />
        )}
      </Styles.Group>
    </RichTextAreaContext.Provider>
  )
}
