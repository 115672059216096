import styled, { css } from 'styled-components'
import { ResumeCritiqueStatuses as STATUSES } from 'builder/modules/resumeReview'
import Typography from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import { Icon20 } from 'builder/components/Icon'
import { Button } from 'builder/components/Button'

export const StatusContainer = styled.div<{ status: STATUSES }>`
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 0 0 6px 6px;
  background-color: ${Colors.Indigo10};
  color: ${Colors.Indigo80};

  ${props =>
    props.status === STATUSES.failed &&
    css`
      background-color: ${Colors.Red10};
      color: ${Colors.Neutral90};
    `}

  ${Media.Tablet`
    align-items: flex-start;
  `};
`

export const StatusContainerStatic = styled.div<{ status: STATUSES }>`
  display: flex;
  align-items: center;
  padding: 16px;
  width: 100%;
  max-width: 1120px;
  height: 88px;
  margin-bottom: 24px;
  margin-top: -10px;
  border-radius: 0 0 6px 6px;
  background-color: ${Colors.Indigo10};
  color: ${Colors.Indigo80};

  ${props =>
    props.status === STATUSES.failed &&
    css`
      background-color: ${Colors.Red10};
      color: ${Colors.Neutral90};
    `}

  ${Media.Phone`
    margin-top: 24px;
    align-items: flex-start;
    height: auto;
  `};
`

export const StatusVisual = styled.div`
  display: flex;
  flex-direction: row-reverse;
  flex-shrink: 0;
  margin-right: 16px;

  ${Media.Tablet`
    flex-direction: column-reverse;
    margin-right: 0;
    margin-left: 48px;
  `};

  ${Media.Phone`
    display: none;
  `};
`

export const StatusVisualCritque = styled.div`
  display: flex;
  flex-direction: row-reverse;
  flex-shrink: 0;
  margin-right: 28px;
  margin-left: 16px;
  position: relative;

  ${Media.Tablet`
    flex-direction: column-reverse;
    margin-left: 14px;  
  `};

  ${Media.Phone`
    flex-direction: column-reverse;
    margin-right: 0;
    margin-left: 48px;  
  `};
`

const borderedCircleStyles = css`
  position: relative;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  box-shadow: 0 0 0 4px ${Colors.Indigo10};
  border-radius: 50%;

  &:not(:last-child) {
    margin-left: -8px;
  }

  ${Media.Tablet`
    &:not(:last-child) {
      margin-left: 0;
      margin-top: -4px;
    }
  `};
`

export const StatusIconWrapper = styled.div<{ status: STATUSES }>`
  ${borderedCircleStyles};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.Indigo80};
  color: ${Colors.White};

  ${props =>
    props.status === STATUSES.failed &&
    css`
      background-color: ${Colors.Red50};
      box-shadow: none;
    `}
`

export const StatusIcon = styled.svg`
  width: 24px;
  height: 24px;
`

export const StatusPhoto = styled.img`
  ${borderedCircleStyles};
`

export const StatusPhotoCritiuqePage = styled.img`
  ${borderedCircleStyles};
  width: 56px;
  height: 56px;
`

export const StatusPhotoStar = styled.img`
  position: absolute;
  top: 32px;
  left: 30px;
`

export const StatusInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;

  ${Media.Tablet`
    order: -1;
    flex-direction: column;
    align-items: flex-start;
  `};
`

export const StatusInnerCritiquePage = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;

  ${Media.Phone`
    order: -1;
    flex-direction: column;
    align-items: flex-start;
  `};
`

export const StatusContent = styled.div`
  flex-grow: 1;
`

export const StatusTitle = styled.div`
  ${Typography.Body};
  font-weight: 600;
`

export const StatusText = styled.div`
  ${Typography.Caption};
`

export const StatusButton = styled(Button)<{ status?: STATUSES }>`
  flex-shrink: 0;
  margin-left: 32px;

  ${Media.Tablet`
    margin-top: 12px;
    margin-left: 0;
  `};

  ${props =>
    props.status === STATUSES.finished &&
    css`
      background-color: ${Colors.Indigo80};

      &:hover {
        background-color: ${Colors.Indigo90};
      }
    `};
`

export const StatusButtonIcon = styled(Icon20.ChevronDown)`
  margin-right: -8px;
  margin-left: 2px;
  transform: rotate(-90deg);
`
