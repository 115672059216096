import { CareerPathGrades, CareerPathRoleOccupationMatrix } from 'builder/modules/careerPath'
import { getCardById } from 'builder/modules/careerPath/utils'

export const getNearestNormalizedConnections = (level: CareerPathGrades, connections: string[]) => {
  const normalizedConnections: {
    left: number[]
    right: number[]
    same: number[]
  } = {
    left: [],
    right: [],
    same: [],
  }

  if (!level || !connections.length) return normalizedConnections

  let leftLevel = ''
  let rightLevel = ''
  switch (level) {
    case 'low':
      rightLevel = 'medium'
      break
    case 'high':
      leftLevel = 'medium'
      break
    default:
      leftLevel = 'low'
      rightLevel = 'high'
  }

  connections.reduce((accum, connection) => {
    const parts = connection.split('-')

    if (leftLevel === parts[0] && parts[1]) normalizedConnections.left.push(Number(parts[1]))
    if (rightLevel === parts[0] && parts[1]) normalizedConnections.right.push(Number(parts[1]))
    if (level === parts[0] && parts[1]) normalizedConnections.same.push(Number(parts[1]))

    return normalizedConnections
  }, normalizedConnections)

  return normalizedConnections
}

export const getAllConnections = (id: string, matrix: CareerPathRoleOccupationMatrix) => {
  const choice = getCardById(id, matrix)

  const connects: string[] = choice?.connections ? [...choice.connections] : []

  Object.keys(matrix).forEach(level => {
    matrix[level as CareerPathGrades].reduce((accum, card) => {
      if (card?.id && card.connections?.includes(id) && !connects.includes(card.id))
        connects.push(card.id)

      return connects
    }, connects)
  })

  return connects
}

export const isNearest = (sourceGrade: string, checkGrade: string): boolean =>
  (sourceGrade === 'low' && ['low', 'medium'].includes(checkGrade)) ||
  (sourceGrade === 'medium' && ['low', 'medium', 'high'].includes(checkGrade)) ||
  (sourceGrade === 'high' && ['medium', 'high'].includes(checkGrade))
