export const getStep = (step: number) => {
  let stepName = ''

  switch (step) {
    case 1:
      stepName = 'services'
      break
    case 3:
      stepName = 'career_stage'
      break
    case 4:
      stepName = 'job_functions'
      break
    case 5:
      stepName = 'industries'
      break
    case 6:
      stepName = 'location'
      break
    case 7:
      stepName = 'available_days'
      break
    case 8:
      stepName = 'available_times'
  }

  return stepName
}
