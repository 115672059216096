import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'
type PropTypes = {
  isDisabled?: boolean
}

export const Content = styled.div`
  position: relative;
  transform: rotateY(180deg);
  width: 100%;

  ${Media.AboveTablet`
    width: 70%;
  `};
`

const Scroll = styled.div`
  height: 100%;
  overflow-y: auto;

  ${Media.Tablet`
    height: 86%;
  `};

  &::-webkit-scrollbar {
    width: 12px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${Colors.Neutral90};
    border: 4px solid ${Colors.Neutral70};
    border-radius: 6px;

    &:hover {
      background-color: ${Colors.Neutral100};
    }
  }
`

export const ContentInner = styled(Scroll)`
  width: 100%;
`

export const PreviewBox = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  transition: transform 0.4s ease, opacity 0.4s ease;
  transform: rotateY(180deg);
`

export const Pagination = styled.div`
  position: absolute;
  z-index: 3;
  width: 100px;
  left: calc(50% - 50px);
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  padding: 6px;
  background-color: ${Colors.Neutral100};
  border-radius: 18px;
  user-select: none;
  transition: opacity 0.2s;
  transition-delay: '0.2s';
  opacity: 1;
  pointer-events: 'auto';
  transform: rotateY(180deg);
  bottom: 24px;

  ${Media.Tablet`
    top: -56px;
    bottom: unset;
  `};

  ${Media.Phone`
    top: -70px;
  `};
`

export const PaginationButton = styled.div<PropTypes>(props => {
  const { isDisabled } = props

  return `
  border-radius: 50%;
  color: ${Colors.White};
  opacity: ${isDisabled ? 0.3 : 1};
  pointer-events: ${isDisabled ? 'none' : 'auto'};
  cursor: pointer;

  &:hover {
    background-color: ${Colors.Neutral80};
  }

  & svg {
    display: block;
  }
`
})

export const PaginationPrev = styled(PaginationButton)<PropTypes>`
  transform: rotate(180deg);
`

export const PaginationNext = styled(PaginationButton)<PropTypes>``

export const PaginationCounter = styled.div`
  flex-grow: 1;
  text-align: center;
  min-width: 30px;
  margin: 0 4px;
  color: ${Colors.White};
  ${Typography.Caption};
  font-variant-numeric: tabular-nums;
`
