import { useDispatch } from 'react-redux'
import { Step, CallBackProps } from 'react-joyride'

import { useI18n } from 'builder/hooks/useI18n'
import { useUser } from 'builder/hooks/useUser'
import { actions } from 'builder/modules/panel'
import { actions as userActions } from 'builder/modules/user'
import { selectors as resumeSelectors } from 'builder/modules/resumeEditor'

import { JoyRide } from 'builder/components/JoyRide'
import { setSpotlightsStyles } from 'builder/components/JoyRide/utils'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

const TRANSLATION = 'builder.auto_apply.editor_bubble'

export const JobSearchSolutionBubble = () => {
  const user = useUser()
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const { isPhone } = useMediaQueries()
  const resume = useTypedSelector(resumeSelectors.resume)

  const steps: Step[] = [
    {
      title: i18n.t(`${TRANSLATION}.header`),
      placement: 'bottom',
      disableBeacon: true,
      target: '#job-search-solution',
      content: i18n.t(`${TRANSLATION}.subheader`),
    },
  ]

  const setTourStatus = (activate: boolean) => {
    dispatch(actions.setUIStatesConfigStatus({ total_job_search_tour_completed: activate }))
    dispatch(userActions.setUIUserState({ totalJobSearchTourCompleted: activate }))
  }

  const onCallback = (data: CallBackProps) => {
    if (['skip', 'close'].includes(data.action) && !user?.uiStates?.totalJobSearchTourCompleted) {
      setTourStatus(true)
    }

    if (data.lifecycle === 'tooltip') {
      if (data.index === 0) {
        if (isPhone) {
          setSpotlightsStyles({ height: '74px' })
        } else {
          setSpotlightsStyles({
            marginTop: '12px',
            marginLeft: '9px',
            width: '255px',
            height: '52px',
            borderRadius: '16px',
          })
        }
      }
    }
  }

  if (resume?.showPrefillResumeModal || user?.uiStates?.totalJobSearchTourCompleted) {
    return <></>
  }

  return (
    <JoyRide
      run
      steps={steps}
      styles={{ options: { overlayColor: 'rgba(0, 0, 0, 0.2)' } }}
      callback={onCallback}
      isStepsCountEnabled={false}
      finalButtonLabel={i18n.t(`${TRANSLATION}.button`)}
    />
  )
}
