import React from 'react'
import type { Transition } from 'history'
import { useBlocker } from 'builder/hooks/useBlockerReactRouter'

type PromptProps = {
  when: boolean
  message?: string
  ignoreParentRoute?: string[]
}

export function usePrompt({ when, message, ignoreParentRoute }: PromptProps) {
  const [isPromptVisible, setIsPromptVisible] = React.useState(false)
  const [currentTx, setCurrentTx] = React.useState<Transition>()

  const blocker = React.useCallback(
    tx => {
      if (!message) {
        setIsPromptVisible(true)
        setCurrentTx(tx)
      } else {
        if (window.confirm(message)) tx.retry()
      }
    },
    [message],
  )

  const handleConfirm = () => {
    setIsPromptVisible(false)
    if (currentTx) currentTx.retry()
  }

  const handleCancel = () => {
    setIsPromptVisible(false)
  }

  useBlocker(blocker, when, ignoreParentRoute)

  return {
    isPromptVisible,
    handleCancel,
    handleConfirm,
  }
}
