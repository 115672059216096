import styled from 'styled-components'
import Colors from 'builder/styles/colors'

// Magic modal
export const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`

export const Content = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.3);
`

export const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${Colors.Neutral90};
`
