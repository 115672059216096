import Colors from 'builder/styles/colors'

import FEATURE_CREATE_AI from './assets/images/feature_create_ai.svg'
import FEATURE_RESUME_EXAMPLES from './assets/images/feature_resume_exp.svg'
import FEATURE_EXPERT_HELP from './assets/images/feature_expert_help.svg'
import FEATURE_JOB_ADJUST from './assets/images/feature_job_adjust.svg'

import FEATURE_ACTIVATED_CREATE_AI from './assets/images/feature_create_ai_activated.svg'
import FEATURE_ACTIVATED_RESUME_EXAMPLES from './assets/images/feature_resume_exp_activated.svg'
import FEATURE_ACTIVATED_EXPERT_HELP from './assets/images/feature_expert_help_activated.svg'
import FEATURE_ACTIVATED_JOB_ADJUST from './assets/images/feature_job_adjust_activated.svg'

import FEATURE_FULL_CREATE_AI from './assets/images/desc_create_ai.svg'
import FEATURE_FULL_RESUME_EXAMPLES from './assets/images/desc_resume_exp.svg'
import FEATURE_FULL_EXPERT_HELP from './assets/images/desc_expert_help.svg'
import FEATURE_FULL_JOB_ADJUST from './assets/images/desc_job_adjust.svg'
import FEATURE_FULL_JOB_ADJUST_MOBILE from './assets/images/desc_job_adjust_mobile.svg'

type TranslatorFunction = (key: string) => string

export const ABANDONMENT_FLOW_SERVICES = (translatorFunction?: TranslatorFunction) => [
  {
    id: 1,
    serviceType: 'Start Service',
    serviceTitle: translatorFunction?.('builder.plans_page_abandonment.start_service.title'),
    serviceLogo: FEATURE_CREATE_AI,
    serviceLogoClicked: FEATURE_ACTIVATED_CREATE_AI,
    serviceDescTitle: translatorFunction?.(
      'builder.plans_page_abandonment.start_service.description_title',
    ),
    serviceDesc: translatorFunction?.('builder.plans_page_abandonment.start_service.description'),
    serviceDescImg: FEATURE_FULL_CREATE_AI,
    serviceDescImgMobile: FEATURE_FULL_CREATE_AI,
    serviceRedirectBtnText: translatorFunction?.(
      'builder.plans_page_abandonment.start_service.button_text',
    ),
    serviceRedirectBtnLogo: true,
    seviceAdditionalComponent: false,
    serviceNavigateLink: '',
    serviceTrackingReason: 'how_to_start',
    serviceTrackingAcceptedReason: 'try_AI',
    backgroundColor: `${Colors.Indigo10}`,
    borderColor: `${Colors.Indigo20}`,
    buttonColor: `${Colors.Indigo50}`,
    buttonHoverColor: `${Colors.Indigo100}`,
    noticeTextColor: `${Colors.Indigo90}`,
    phoneContentCardHeight: '336px',
    phoneContentHeight: '160px',
    phoneContentMarginTop: '24px',
    tabletContentMarginTop: '24px',
    tabletContentHeight: '184px',
    phoneContentImgHeight: '100px',
    phoneContentImgWidth: '160px',
    tabletContentImgHeight: '140px',
    tabletContentImgWidth: '224px',
  },
  {
    id: 2,
    serviceType: 'Resume Example',
    serviceTitle: translatorFunction?.('builder.plans_page_abandonment.example.title'),
    serviceLogo: FEATURE_RESUME_EXAMPLES,
    serviceLogoClicked: FEATURE_ACTIVATED_RESUME_EXAMPLES,
    serviceDescTitle: translatorFunction?.(
      'builder.plans_page_abandonment.example.description_title',
    ),
    serviceDesc: translatorFunction?.('builder.plans_page_abandonment.example.description'),
    serviceDescImg: FEATURE_FULL_RESUME_EXAMPLES,
    serviceDescImgMobile: FEATURE_FULL_RESUME_EXAMPLES,
    serviceRedirectBtnText: translatorFunction?.(
      'builder.plans_page_abandonment.example.button_text',
    ),
    serviceRedirectBtnLogo: false,
    seviceAdditionalComponent: false,
    serviceNavigateLink: '',
    serviceTrackingReason: 'example',
    serviceTrackingAcceptedReason: 'Examples',
    backgroundColor: `${Colors.Blue10}`,
    borderColor: `${Colors.Blue20}`,
    buttonColor: `${Colors.Blue50}`,
    buttonHoverColor: `${Colors.Blue60}`,
    noticeTextColor: `${Colors.Blue90}`,
    phoneContentCardHeight: '380px',
    phoneContentHeight: '204px',
    phoneContentMarginTop: '24px',
    tabletContentMarginTop: '20px',
    tabletContentHeight: '192px',
    phoneContentImgHeight: '104px',
    phoneContentImgWidth: '98px',
    tabletContentImgHeight: '148px',
    tabletContentImgWidth: '140px',
  },
  {
    id: 3,
    serviceType: 'Expert Help',
    serviceTitle: translatorFunction?.('builder.plans_page_abandonment.expert_help.title'),
    serviceLogo: FEATURE_EXPERT_HELP,
    serviceLogoClicked: FEATURE_ACTIVATED_EXPERT_HELP,
    serviceDescTitle: translatorFunction?.(
      'builder.plans_page_abandonment.expert_help.description_title',
    ),
    serviceDesc: translatorFunction?.('builder.plans_page_abandonment.expert_help.description'),
    serviceDescImg: FEATURE_FULL_EXPERT_HELP,
    serviceDescImgMobile: FEATURE_FULL_EXPERT_HELP,
    serviceRedirectBtnText: translatorFunction?.(
      'builder.plans_page_abandonment.expert_help.button_text',
    ),
    serviceRedirectBtnLogo: false,
    seviceAdditionalComponent: true,
    serviceNavigateLink:
      'https://topresume.com/resume-writing?pt=UWYxd6Xe2VTS0&utm_source=resumeio&utm_medium=rioplanspageabandonment&utm_campaign=tr_promocard_in_rioplanspageabandonment&utm_content=v1',
    serviceTrackingReason: 'expert_help',
    serviceTrackingAcceptedReason: 'Expert_help',
    backgroundColor: `${Colors.Green10}`,
    borderColor: `${Colors.Green20}`,
    buttonColor: `${Colors.Green50}`,
    buttonHoverColor: `${Colors.Green60}`,
    noticeTextColor: `${Colors.Green90}`,
    phoneContentCardHeight: '344px',
    phoneContentHeight: '168px',
    phoneContentMarginTop: '15px',
    tabletContentMarginTop: '24px',
    tabletContentHeight: '197px',
    phoneContentImgHeight: '115px',
    phoneContentImgWidth: '125px',
    tabletContentImgHeight: '140px',
    tabletContentImgWidth: '153px',
  },
  {
    id: 4,
    serviceType: 'Job Adjustment',
    serviceTitle: translatorFunction?.('builder.plans_page_abandonment.job_adjustment.title'),
    serviceLogo: FEATURE_JOB_ADJUST,
    serviceLogoClicked: FEATURE_ACTIVATED_JOB_ADJUST,
    serviceDescTitle: translatorFunction?.(
      'builder.plans_page_abandonment.job_adjustment.description_title',
    ),
    serviceDesc: translatorFunction?.('builder.plans_page_abandonment.job_adjustment.description'),
    serviceDescImg: FEATURE_FULL_JOB_ADJUST,
    serviceDescImgMobile: FEATURE_FULL_JOB_ADJUST_MOBILE,
    serviceRedirectBtnText: translatorFunction?.(
      'builder.plans_page_abandonment.job_adjustment.button_text',
    ),
    serviceRedirectBtnLogo: true,
    seviceAdditionalComponent: false,
    serviceNavigateLink: '',
    serviceTrackingReason: 'adjust the CV',
    serviceTrackingAcceptedReason: 'Tailoring',
    backgroundColor: `${Colors.Indigo10}`,
    borderColor: `${Colors.Indigo20}`,
    buttonColor: `${Colors.Indigo50}`,
    buttonHoverColor: `${Colors.Indigo100}`,
    noticeTextColor: `${Colors.Indigo90}`,
    phoneContentCardHeight: '324px',
    phoneContentHeight: '160px',
    phoneContentMarginTop: '8px',
    tabletContentMarginTop: '16px',
    tabletContentHeight: '192px',
    phoneContentImgHeight: '120px',
    phoneContentImgWidth: '130px',
    tabletContentImgHeight: '164px',
    tabletContentImgWidth: '180px',
  },
]

export const getServiceType = (cardId: number, translatorFunction?: TranslatorFunction) =>
  ABANDONMENT_FLOW_SERVICES(translatorFunction).find(elm => elm.id === cardId)
