import { LocationForm, IJobPreferencesForm } from '../resumeDistribution'

export type PercentOfDaysWillingToTravel = '0' | '20' | '50' | '100'
export enum WorkingTypes {
  office = 'office',
  remote = 'remote',
  hybrid = 'hybrid',
}
export type SalaryPlan = 'monthly' | 'annually'
export type ExpectedStartWork = 'immediate' | 'one_month' | 'three_months' | 'six_months'

export type JobPreferenceAttributes = {
  expectedSalary?: number
  currentSalary?: number
  currentCurrency: string
  expectedSalaryPlan: SalaryPlan
  currentSalaryPlan: SalaryPlan
  expectedCurrency: string
  expectedWorkingTypes: Array<WorkingTypes>
  openForBusinessTrips?: boolean
  willingToRelocate: boolean
  willingToRelocateAtYourOwnCost: boolean
  expectedStartWork: ExpectedStartWork
  fullyVaccinatedAgreementAgainstCovid?: boolean
  hasRestrictionLimiting?: boolean
  openToRemoteOpportunities?: boolean
}

export type Profile = {
  id: number
  firstName: string
  lastName: string
  currentJobTitle: string
  gender: string
  resumeUrl: string
  race: string
  location: string
  fullAddress: string
  email: string
  phoneNumber: string
  targetRole: string
  countriesAuthorizedToWorkIn: { value: string; label: string }[]
  socialLinksAttributes: Array<{
    title: string
    link: string
  }>

  jobPreferenceAttributes?: JobPreferenceAttributes
  coursesAttributes: Array<{
    course: string
    institution: string
    dateFrom: string
    dateUntil: string
  }>
  autoApplyDataCompleted: boolean
  targetLocations: string[]
  resumeUploadedAt: string
  profilePicture: string
  userType: string
}

export type AutoApplyForm = Omit<Profile, 'resumeUploadedAt' | 'profilePicture' | 'userType'>

export type AutoApplyStore = {
  form: AutoApplyForm
  uploadedResume?: File
  uploadedResumeLink: string
  selectedResumeId: number
  selectedTRResumeId: number
  lastResumeAction: 'upload' | 'select'
  resumeDistributionForm: ResumeDistributionForm
}

export type ResumeDistributionForm = {
  location: LocationForm
  jobPreferences: IJobPreferencesForm
}
