import styled from 'styled-components'
import Media from 'builder/styles/media'

export const VisualContainer = styled.div`
  position: absolute;
  width: 268px;
  height: 214px;
  left: 0px;
  top: 0px;

  ${Media.Phone`
    width: 242px;
    height: 212px;
  `}
`

export const VisualImageContainer = styled.div`
  position: absolute;
  width: 203px;
  height: 177.62px;
  left: 32px;
  top: 22.5px;
  z-index: 1;

  ${Media.Phone`
    left: 20px;
  `}
`

export const VisualImage = styled.img`
  width: 100%;
  height: 100%;
`
export const UserImage = styled.img`
  position: absolute;
  z-index: 100;
  top: 117px;
  width: 44px;
  left: 80px;
  height: 44px;
  border-radius: 50%;
  padding: 2px;
`
