import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Sizes from 'builder/styles/sizes'

export const Column = styled.div`
  display: flex;
  flex-flow: column;
`

export const Container = styled(Column)`
  gap: ${Sizes.L};
  max-width: 672px;
`

export const Section = styled(Column)`
  gap: ${Sizes.M};
`

export const SectionHeader = styled.div`
  ${Typography.S};
  ${FontWeights.DemiBold};
`

export const ItemList = styled(Column)`
  gap: ${Sizes.L};
`

export const Item = styled(Column)`
  gap: ${Sizes['2XS']};
`

export const ItemHeader = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};
`

export const ItemDuration = styled.div`
  color: ${Colors.Neutral50};
`

export const ItemDescription = styled.div``

export const BadgeList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${Sizes.XS};
`

export const Badge = styled.div`
  padding: 6px 10px;
  border-radius: ${Sizes['3XS']};
  background-color: ${Colors.Neutral10};
  white-space: nowrap;
`
