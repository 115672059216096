import { Fragment } from 'react'
import {
  ShadowContainer,
  ProcessWrapper,
  SpinnerWrapper,
  SpinnerContainer,
  TextWrapper,
} from './styles'

export const Process = () => {
  return (
    <Fragment>
      <ShadowContainer />
      <ProcessWrapper>
        <SpinnerWrapper>
          <SpinnerContainer />
        </SpinnerWrapper>
        <TextWrapper>Building Career Ways…</TextWrapper>
      </ProcessWrapper>
    </Fragment>
  )
}
