import styled, { css } from 'styled-components'
import Button, { ButtonSize } from 'builder/components/Button'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div<{ $isJobDetails?: boolean }>`
  display: flex;
  margin: 0 0 16px;
  border-radius: var(--Spacing-2XS, 8px);
  background: ${Colors.Blue10};

  ${({ $isJobDetails }) =>
    $isJobDetails &&
    css`
      margin: 24px 0;
    `}
`

export const InfoContainer = styled.div<{ $isJobDetails?: boolean }>`
  display: flex;
  gap: 12px;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  width: 50%;

  ${({ $isJobDetails }) =>
    $isJobDetails &&
    css`
      gap: 16px;
    `}
`

export const TextContainer = styled.div<{ $isJobDetails?: boolean }>`
  display: flex;
  gap: 4px;
  flex-direction: column;

  ${({ $isJobDetails }) =>
    $isJobDetails &&
    css`
      gap: 8px;
    `}
`

export const Header = styled.div`
  ${Typography.Caps}
  ${FontWeights.DemiBold}

  color: ${Colors.Blue80};
`

export const Subheader = styled.div`
  ${Typography.Caption}
  ${FontWeights.Regular}
  
  color: ${Colors.Blue80};
`

export const ActionsContainer = styled.div`
  display: flex;
  gap: 12px;
`

export const ActionButton = styled(Button).attrs({ size: ButtonSize.small })`
  width: fit-content;
`

export const ImageContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
`

export const Image = styled.img`
  height: 156px;
  object-fit: cover;
  border-radius: var(--Spacing-2XS, 8px);
`
