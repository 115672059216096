import { Fragment } from 'react'
import * as Styles from './styles'

const person1 = require('images/critique-review/Person1.svg')
const person2 = require('images/critique-review/Person2.svg')

interface Props {
  isDiscountAvailable: boolean
  handleTRBannerClick?: () => void
}

const DiscountDesktopBanner = ({ isDiscountAvailable, handleTRBannerClick }: Props) => {
  return (
    <Fragment>
      <Styles.Container>
        <Styles.Title>
          {isDiscountAvailable
            ? 'Make sure your resume gets you to the interview - get expert help with a discount of up to 60%'
            : 'Make sure that your resume gets you to the interview - get expert help in writing a resume'}
        </Styles.Title>
        <Styles.Gradient />
        <Styles.Button onClick={handleTRBannerClick}>Get Expert Help</Styles.Button>

        <Styles.PopupPhoto src={person1} isPerson1={true} />
        <Styles.PopupPhoto src={person2} isPerson1={false} />
      </Styles.Container>
    </Fragment>
  )
}

export default DiscountDesktopBanner
