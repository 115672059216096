import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Icon20 } from 'builder/components/Icon'

export const Wrapper = styled.a`
  ${Typography.Caption};
  ${FontWeights.DemiBold};
  background: ${Colors.Indigo80};
  color: ${Colors.White};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  padding: 6px 12px 6px 10px;
  transition: background-color 0.25s;

  &:hover,
  &:active {
    background: ${Colors.Indigo90};
  }
`

export const Icon = styled(Icon20.Crown)`
  margin-right: 2px;
`
