import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { memo, useCallback, useState } from 'react'
import Icon from 'builder/components/Icon'
import Colors from 'builder/styles/colors'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import * as Styled from './styles'
import arrowInCircleImage from './images/arrow.svg'

type Props = {
  items: JSX.Element[]
}

SwiperCore.use([Navigation])

const prevButtonClassName = 'progression-slider-prev'
const nextButtonClassName = 'progression-slider-next'

const ProgressionSliderComponent = ({ items }: Props) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  const [isEnd, setIsEnd] = useState(false)
  const [isBeginning, setIsBeginning] = useState(true)
  const mediaQueries = useMediaQueries()
  const swiperSlideWidth = mediaQueries.isPhone ? '230px' : '270px'

  const onSwiperEvent = useCallback(({ realIndex, isEnd, isBeginning }: SwiperCore) => {
    setIsEnd(isEnd)
    setIsBeginning(isBeginning)
    setCurrentSlideIndex(realIndex)
  }, [])

  return (
    <Styled.Container>
      <Styled.SwiperContainer>
        <Swiper
          initialSlide={currentSlideIndex}
          grabCursor
          slidesPerView="auto"
          spaceBetween={8}
          navigation={{
            prevEl: `.${prevButtonClassName}`,
            nextEl: `.${nextButtonClassName}`,
          }}
          onInit={onSwiperEvent}
          onSlideChange={onSwiperEvent}
          onToEdge={onSwiperEvent}
          onFromEdge={onSwiperEvent}
        >
          {items.map((item, index) => (
            <SwiperSlide
              key={`template-${index}`}
              style={{ minWidth: swiperSlideWidth, width: swiperSlideWidth }}
            >
              <Styled.SlideContent $isLast={index === items.length - 1}>
                {item}
                {index < items.length - 1 && (
                  <Styled.SlideJoiner alt="arrow" src={arrowInCircleImage} />
                )}
              </Styled.SlideContent>
            </SwiperSlide>
          ))}
        </Swiper>

        <Styled.Gradient $position="start" $isVisible={isEnd && !isBeginning} />
        <Styled.Gradient $position="end" $isVisible={!isEnd} />
      </Styled.SwiperContainer>

      <Styled.NavigationControls>
        <Styled.NavigationButton className="progression-slider-prev">
          <Icon.ChevronLeft color={Colors.Neutral50} />
        </Styled.NavigationButton>

        <Styled.NavigationButton className="progression-slider-next">
          <Icon.ChevronRight color={Colors.Neutral50} />
        </Styled.NavigationButton>
      </Styled.NavigationControls>
    </Styled.Container>
  )
}

export const ProgressionSlider = memo(ProgressionSliderComponent)
