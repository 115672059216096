import {
  ListItemCollapse,
  ListItemColumn,
  ListItemHeader,
  ListItemSubText,
  ListItemText,
} from 'builder/components/List'
import { Recruiter } from 'builder/modules/resumeDistribution'

interface MobileListProps {
  recruiter: Recruiter
}

export const MobileList = ({ recruiter }: MobileListProps) => {
  const { city, state, country } = recruiter.location || {}
  const loaction = `${city}, ${state} ${country}`

  return (
    <ListItemCollapse
      header={
        <ListItemColumn
          columnWidth={1.25}
          header={<ListItemHeader text={recruiter.name} />}
          subText={<ListItemSubText text={recruiter.jobTitle} />}
        />
      }
    >
      <>
        <ListItemColumn
          columnWidth={0.75}
          text={<ListItemText text={recruiter.company} />}
          subText={<ListItemSubText text={loaction} />}
        />
        <ListItemColumn
          columnWidth={0.75}
          columnTextPadding="12px 0 24px 0"
          subText={<ListItemSubText text={recruiter.industry} />}
        />
      </>
    </ListItemCollapse>
  )
}
