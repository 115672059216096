import { createRef, Component } from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { Overlay, Modal } from './styles'

const classNames = {
  appearActive: 'is-visible',
  enterActive: 'is-visible',
  enterDone: 'is-visible',
}

class MobileSlideModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    children: PropTypes.any,
  }

  overlayRef = createRef()
  modalRef = createRef()

  componentDidMount() {
    const { isOpen } = this.props
    if (isOpen) this.handleOpen()
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props
    const { isOpen: wasOpen } = prevProps

    if (!wasOpen && isOpen) this.handleOpen()
    if (wasOpen && !isOpen) this.handleClose()
  }

  handleOpen = () => {
    this.pageScrollTop = window.pageYOffset
    disableBodyScroll(this.modalRef.current)
  }

  componentWillUnmount() {
    enableBodyScroll(this.modalRef.current)
  }

  handleClose = () => {
    enableBodyScroll(this.modalRef.current)
    window.scrollTo(0, this.pageScrollTop)
  }

  handleOverlayClick = e => {
    const { onClose } = this.props
    if (onClose && e.target === this.overlayRef.current) onClose()
  }

  render() {
    const { isOpen, children } = this.props

    return (
      <CSSTransition classNames={classNames} in={isOpen} timeout={300} unmountOnExit appear>
        <Overlay ref={this.overlayRef} onClick={this.handleOverlayClick}>
          <Modal ref={this.modalRef}>{children}</Modal>
        </Overlay>
      </CSSTransition>
    )
  }
}

export default MobileSlideModal
