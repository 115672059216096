import { useMemo } from 'react'
import CIO_LOGO_URL from 'images/countries/cio-logo.svg'
import THANK_YOU_ILLUSTRATION from './assets/img/thankyou.png'
import {
  Container,
  Header,
  Anchor,
  Logo,
  Image,
  ImageWrapper,
  Description,
  Title,
  SubTitle,
  Footer,
  Caption,
  ContactLink,
} from './styles'

export const ThankYouView = () => {
  const getContactPageLink = useMemo(() => {
    let host = 'career.io'
    if (window?.location?.host) {
      host = window?.location?.host
    }
    return `https://${host}/contact`
  }, [])

  return (
    <>
      <Header>
        <Anchor aria-label="Logo" data-testid="cio-anchor-tag" href="/">
          <Logo src={CIO_LOGO_URL} alt="Cio logo" />
        </Anchor>
      </Header>
      <Container>
        <ImageWrapper>
          <Image src={THANK_YOU_ILLUSTRATION} alt="Thank you" />
        </ImageWrapper>
        <Description>
          <Title data-testid="description-title">Thank you for signing up for Career.io</Title>
          <SubTitle data-testid="description-subtitle">
            We just sent you an email to confirm your email address. <br /> Click the link in the
            email, and you’ll be signed in.
          </SubTitle>
        </Description>
        <Footer>
          <Caption data-testid="footer-title">Didn’t receive a link?</Caption>
          <ContactLink href={getContactPageLink} data-testid="footer-anchor-tag">
            Contact support
          </ContactLink>
        </Footer>
      </Container>
    </>
  )
}

export default ThankYouView
