import styled from 'styled-components'
import Colors from 'builder/styles/colors'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
`

export const ImageWrapper = styled.div`
  height: 40px;
  width: 59px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${require('./assets/welcome_button_texture.svg')});
  background-position: top;
  background-size: cover;
  background-color: ${Colors.Blue10};
  border-radius: 8px;
  margin-right: 12px;
`

export const ImagePlay = styled.img<{ isHovered?: boolean }>`
  width: 28px;
  height: 28px;
  margin-top: 3.2px;
  transition: opacity 0.25s ease-in-out;
  opacity: ${props => (props.isHovered ? 1 : 0.75)};
`

export const Text = styled.div<{ isHovered?: boolean }>`
  color: ${props => (props.isHovered ? Colors.Blue50 : Colors.Neutral90)};
  transition: color 0.25s;
`

export const Divider = styled.div`
  width: 1px;
  height: 32px;
  margin-left: 25px;
  margin-right: 25px;
  background: ${Colors.Neutral15};
`
