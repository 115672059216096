import { CoachingSessions } from '../CoachingSessions'
import { ComplementaryBox } from '../ComplementaryBox'
import { Container } from './styles'

interface PackProps {
  subscriptionExtensionInMonths: string
  features: string[]
}

interface BodyProps {
  pack: PackProps
}

export const Body = ({ pack }: BodyProps) => {
  return (
    <Container>
      <CoachingSessions {...{ pack }} />
      <ComplementaryBox {...{ pack }} />
    </Container>
  )
}
