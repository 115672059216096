import { all, call, put, takeLatest } from 'redux-saga/effects'
import ErrorLogger from 'builder/services/ErrorLogger'
import { apiClient, baseApiClient } from 'builder/modules/apiClient'
import { FetchStatuses } from '../constants'
import { navigate } from '../navigate'
import { actions } from './careerCoachingModule'
import { REDIRECTROUTES } from './constants'

export function* fetchCareerLevelsSaga() {
  try {
    const { data } = yield call(apiClient.get, `/coaching/career-levels`)

    yield put(actions.setCareerLevels(data.careerLevels))
  } catch (error) {
    ErrorLogger.log(error)
  }
}

export function* fetchServicesOfferedSaga() {
  try {
    const { data } = yield call(apiClient.get, `/coaching/career-services`)

    yield put(actions.setServicesOffered(data.careerServices))
  } catch (error) {
    ErrorLogger.log(error)
  }
}

export function* fetchIndustriesSaga() {
  try {
    const { data } = yield call(apiClient.get, `/coaching/career-industries`)

    yield put(actions.setIndustries(data.careerIndustries))
  } catch (error) {
    ErrorLogger.log(error)
  }
}

export function* filterCoachesSaga() {
  try {
    yield put(actions.setFetchCoachesStatus(FetchStatuses.loading))

    const { data } = yield call(apiClient.get, `/coaching/coach-listing`)

    yield put(actions.setCoaches(data.data))

    yield put(actions.setFetchCoachesStatus(FetchStatuses.loaded))
  } catch (error) {
    yield put(actions.setFetchCoachesStatus(FetchStatuses.failed))
    ErrorLogger.log(error)
  }
}

export function* fetchCoachDetailsSaga({ payload }: ReturnType<typeof actions.fetchCoachDetails>) {
  const { coachId, careerLevel } = payload

  try {
    const params = {
      coach_id: coachId,
      level: careerLevel,
    }

    const { data } = yield call(apiClient.get, `/coaching/career-coach-detail`, {
      params,
    })

    yield put(actions.setCoachDetails(data.coach))
    // set first service as default selected
    yield put(actions.setSelectedService(data.coach.services[0].name))
    // set first service duration as default duration selected
    yield put(actions.setSelectedDuration(data.coach.services[0].sessions[0].duration / 60))
    // set first service duration price as default price
    yield put(actions.setSelectedDurationPrice(data.coach.services[0].sessions[0].price))
  } catch (error) {
    ErrorLogger.log(error)
  }
}

export function* fetchCoachingPlansSaga({
  payload,
}: ReturnType<typeof actions.fetchCoachingPlans>) {
  const { planType } = payload

  try {
    yield put(actions.setFetchCoachingPlansStatus(FetchStatuses.loading))

    const params = {
      plan_type: planType,
    }

    const { data } = yield call(apiClient.get, `/payment-plans/list-coaching-plans`, {
      params,
    })
    yield put(actions.setCoachingPlans(data.data))

    yield put(actions.setFetchCoachingPlansStatus(FetchStatuses.loaded))
  } catch (error) {
    yield put(actions.setFetchCoachingPlansStatus(FetchStatuses.failed))
    ErrorLogger.log(error)
  }
}

export function* fetchCoachConfirmationSaga({
  payload,
}: ReturnType<typeof actions.fetchCoachConfirmation>) {
  try {
    yield put(actions.setAssignCoachStatus(FetchStatuses.loading))

    const { data } = yield call(
      baseApiClient.post,
      `/live/cio/live/coachingCoachApi/coach_assignment.php`,
      payload,
      {
        baseURL: process.env.CIO_ENTERPRISE_BASE_URL,
        headers: {
          Authorization: `Bearer ${process.env.CIO_ENTERPRISE_TOKEN}`,
          'content-type': 'multipart/form-data',
        },
      },
    )

    yield put(actions.setCoachConfirmation(data.coach))

    yield put(actions.setAssignCoachStatus(FetchStatuses.loaded))
  } catch (error) {
    yield put(actions.setAssignCoachStatus(FetchStatuses.failed))
    ErrorLogger.log(error)
  }
}

export function* fetchCoachBookingSlotSaga() {
  try {
    const { data } = yield call(apiClient.get, `/coaching/get-coach-booking-slot`)

    yield put(actions.setCoachBookingSlot(data.slotTime))
  } catch (error) {
    ErrorLogger.log(error)
  }
}

export function* fetchSuggestedIndustriesSaga() {
  try {
    const { data } = yield call(apiClient.get, `/coaching/career-industries?suggested=true&limit=7`)

    const suggestedIndustries = data?.careerIndustries.map(
      ({ id, name }: { id: number; name: string }) => ({
        value: id,
        label: name,
      }),
    )

    yield put(actions.setSuggestedIndustries(suggestedIndustries))
  } catch (error) {
    ErrorLogger.log(error)
  }
}

export function* fetchIntakeTestDetailsSaga() {
  try {
    yield put(actions.fetchIntakeTestStatus(FetchStatuses.loading))
    const { data } = yield call(
      apiClient.get,
      `/coaching/user-coach-preferences/current-user-preference`,
    )
    yield put(actions.setIntakeTestDetails(data?.data))
    yield put(actions.fetchIntakeTestStatus(FetchStatuses.loaded))
  } catch (error) {
    ErrorLogger.log(error)
    yield put(actions.fetchIntakeTestStatus(FetchStatuses.failed))
  }
}

export function* createIntakeTestDetailsSaga({
  payload,
}: ReturnType<typeof actions.createIntakeTestDetails>) {
  try {
    yield put(actions.setIntakeTestStatus(FetchStatuses.loading))

    yield call(apiClient.post, `coaching/user-coach-preferences`, payload)

    yield put(actions.setIntakeTestStatus(FetchStatuses.loaded))
  } catch (error) {
    yield put(actions.setIntakeTestStatus(FetchStatuses.failed))

    ErrorLogger.log(error)
  }
}

export function* updateIntakeTestDetailsSaga({
  payload,
}: ReturnType<typeof actions.updateIntakeTestDetails>) {
  try {
    yield put(actions.setIntakeTestStatus(FetchStatuses.loading))

    yield call(apiClient.patch, `coaching/user-coach-preferences/${payload?.id}`, payload)

    yield put(actions.setIntakeTestStatus(FetchStatuses.loaded))
  } catch (error) {
    yield put(actions.setIntakeTestStatus(FetchStatuses.failed))

    ErrorLogger.log(error)
  }
}

export function* fetchCoachSessionsSaga({
  payload,
}: ReturnType<typeof actions.fetchCoachSessions>) {
  try {
    yield put(actions.setCoachSessionsStatus(FetchStatuses.loading))
    const { data } = yield call(
      baseApiClient.post,
      `/live/cio/live/coachingCoachApi/v2/session_details.php`,
      payload,
      {
        baseURL: process.env.CIO_ENTERPRISE_BASE_URL,
        headers: {
          Authorization: `Bearer ${process.env.CIO_ENTERPRISE_TOKEN}`,
          'content-type': 'multipart/form-data',
        },
      },
    )
    yield put(actions.setCoachSessions(data))

    if (data.response.participant_status === 'Unmatched') {
      yield call(filterCoachesSaga)
    }

    yield put(actions.setCoachSessionsStatus(FetchStatuses.loaded))
  } catch (error) {
    yield put(actions.setCoachSessionsStatus(FetchStatuses.failed))

    ErrorLogger.log(error)
  }
}

export function* fetchRedirectRoutesSaga({
  payload,
}: ReturnType<typeof actions.fetchRedirectRoutes>) {
  const { currentUrl } = payload

  try {
    const { data } = yield call(apiClient.get, `/coaching/redirection`)

    if (data.success) {
      const route = data.redirectTo as keyof typeof REDIRECTROUTES
      const redirectUrl = REDIRECTROUTES[route]

      if (currentUrl !== redirectUrl) {
        navigate(redirectUrl, { replace: true })
      }
    }
  } catch (error) {
    ErrorLogger.log(error)
  }
}

/** Bind side-effect handlers */
export const sagas = function* saga() {
  yield all([takeLatest(actions.fetchCareerLevels, fetchCareerLevelsSaga)])
  yield all([takeLatest(actions.filterCoaches, filterCoachesSaga)])
  yield all([takeLatest(actions.fetchServicesOffered, fetchServicesOfferedSaga)])
  yield all([takeLatest(actions.fetchIndustries, fetchIndustriesSaga)])
  yield all([takeLatest(actions.fetchCoachDetails, fetchCoachDetailsSaga)])
  yield all([takeLatest(actions.fetchCoachingPlans, fetchCoachingPlansSaga)])
  yield all([takeLatest(actions.fetchCoachConfirmation, fetchCoachConfirmationSaga)])
  yield all([takeLatest(actions.fetchCoachBookingSlot, fetchCoachBookingSlotSaga)])
  yield all([takeLatest(actions.fetchSuggestedIndustries, fetchSuggestedIndustriesSaga)])
  yield all([takeLatest(actions.fetchIntakeTestDetails, fetchIntakeTestDetailsSaga)])
  yield all([takeLatest(actions.createIntakeTestDetails, createIntakeTestDetailsSaga)])
  yield all([takeLatest(actions.updateIntakeTestDetails, updateIntakeTestDetailsSaga)])
  yield all([takeLatest(actions.fetchCoachSessions, fetchCoachSessionsSaga)])
  yield all([takeLatest(actions.fetchRedirectRoutes, fetchRedirectRoutesSaga)])
}
