import { forwardRef, useState } from 'react'
import { Transition } from 'react-transition-group'
import * as Styled from './styles'

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  isOpen?: boolean
  isDisabled: boolean
  isPlaceholder: boolean
  hasError: boolean
  hasWarning: boolean
  children: React.ReactNode
}

type Ref = React.ForwardedRef<HTMLButtonElement>

export const SelectButton = forwardRef((props: Props, ref: Ref) => {
  const { isOpen, isDisabled, isPlaceholder, hasError, hasWarning, children, ...rest } = props
  const [isFocused, setFocused] = useState(false)

  return (
    <Styled.Button
      ref={ref}
      $isOpen={isOpen}
      $isDisabled={isDisabled}
      $isPlaceholder={isPlaceholder}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      {...rest}
    >
      {/* Displayed value */}
      {children}

      {/* Arrow icon */}
      <Styled.Icon $isOpen={isOpen} $isDisabled={isDisabled} />

      {/* Animated bottom bar */}
      <Transition in={isOpen || isFocused || hasError || hasWarning} timeout={100}>
        {state => (
          <Styled.Bar state={state} isOpen={isOpen} hasError={hasError} hasWarning={hasWarning} />
        )}
      </Transition>
    </Styled.Button>
  )
})
