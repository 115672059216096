import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
export const Container = styled.div`
  display: flex;
  align-items: center;

  flex-direction: column;

  margin-top: 20px;
  ${Media.Phone`
    margin-top: 0;
  `};
`

export const Title = styled.div`
  ${Typography.S};
  ${FontWeights.Medium};
  padding: 12px 0;
`

export const Subtitle = styled.div`
  color: ${Colors.Neutral50};
  text-align: center;

  ${Typography.Body};
  ${FontWeights.Regular};

  & b {
    color: ${Colors.Neutral90};

    ${Typography.Body};
    ${FontWeights.Medium};
  }
  padding-bottom: 48px;
  ${Media.Phone`
    padding-bottom: 20px;
  `};
`
export const Img = styled.img`
  width: 209px;
  height: 196px;
  flex-shrink: 0;
`
