import { forwardRef, memo } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from 'builder/modules/resumeEditor'
import { MouseClickEvent } from 'builder/modules/ui'
import { ResumeValidationStatus } from 'builder/modules/constants'
import { ALLOWED_FILES } from '../constants'
import { FileInput } from '../styles'

const FileSelector = forwardRef<HTMLInputElement, unknown>((props, ref) => {
  const dispatch = useDispatch()

  const handleClick = (event: MouseClickEvent) => {
    event.stopPropagation()
  }
  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedResume = event.target.files?.[0]

    if (selectedResume) {
      dispatch(actions.setResumeValidationStatus(ResumeValidationStatus.uploading))
      dispatch(actions.setResumeForPrefill(selectedResume))
    }
  }

  return (
    <FileInput
      type="file"
      ref={ref}
      onClick={handleClick}
      onChange={handleFileInputChange}
      accept={ALLOWED_FILES}
    />
  )
})

export default memo(FileSelector)
