import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import { ConfigScopesEnum } from 'builder/modules/init'
import { useI18n } from 'builder/hooks/useI18n'
import { useConfig } from 'builder/hooks/useConfig'
import SocialButton from 'builder/components/SocialButton'

import { Container, Buttons, Note } from './styles'

const SignInMethods = ({ onButtonClick }) => {
  const { i18n } = useI18n()
  const config = useConfig(ConfigScopesEnum.signUp)

  // Copy `location.search` to persist "current_region_prefix"
  const location = useLocation()

  // Super app uses a simplified sign up flow (without document creation)
  const signUpPath = config?.features.superApp ? '/create-account' : '/create-resume'

  return (
    <Container>
      <Buttons>
        <SocialButton onClick={onButtonClick} network="facebook" href="/auth/facebook?">
          {i18n.t('builder.sign_in.methods.facebook')}
        </SocialButton>

        <SocialButton onClick={onButtonClick} network="google" href="/auth/google_oauth2?">
          {i18n.t('builder.sign_in.methods.google')}
        </SocialButton>

        <SocialButton onClick={onButtonClick} network="linkedin" href="/auth/linkedin?">
          {i18n.t('builder.sign_in.methods.linkedin')}
        </SocialButton>

        <SocialButton onClick={onButtonClick} network="email">
          {i18n.t('builder.sign_in.methods.email')}
        </SocialButton>
      </Buttons>

      <Note>
        {i18n.t('builder.sign_in.im_not_registered')}
        {' — '}
        <Link to={signUpPath + location.search}>{i18n.t('builder.sign_in.sign_up')}</Link>
      </Note>
    </Container>
  )
}

SignInMethods.propTypes = {
  onButtonClick: PropTypes.func,
}

SignInMethods.defaultProps = {
  onButtonClick: () => {},
}

export default SignInMethods
