import Button from 'builder/components/Button'
import { Container, Content, Visual, Title, Text, Extra } from './styles'

// Reload the current page without the browser cache
// See https://developer.mozilla.org/docs/Web/API/Location/reload
const forceReload = () => window.location.reload(true)

// The error screen doesn't use translations anymore since it's out of I18nContext scope
// and i18n might be a reason of crashing
const Fallback = () => (
  <Container>
    <Content>
      <Visual />
      <Title>Something went wrong</Title>
      <Text>
        Please, try to reload the page. If the problem occurs again —{' '}
        <a href="/contact">contact support</a>
      </Text>
      <Button onClick={forceReload}>Reload the page</Button>
      <Extra>
        <a href="/app">Open Dashboard</a>
      </Extra>
    </Content>
  </Container>
)

export default Fallback
