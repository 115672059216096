import { useState, useCallback, useRef } from 'react'
import { useClickOutside } from 'builder/hooks/useClickOutside'

type UseSelectProps = {
  onSelect: (id: string) => void
}

export const useSelect = ({ onSelect }: UseSelectProps) => {
  const [isOpen, setOpening] = useState(false)
  const ref = useRef<HTMLDivElement | null>(null)

  useClickOutside(ref, () => {
    if (!isOpen) return
    setOpening(false)
  })

  const handleToggle = useCallback(() => {
    setOpening(isOpen => !isOpen)
  }, [])

  const handleSelect = useCallback(
    id => {
      onSelect(id)
      setOpening(false)
    },
    [onSelect],
  )

  return {
    isOpen,
    ref,
    handleToggle,
    handleSelect,
  }
}
