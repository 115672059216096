import styled from 'styled-components'
import { Colors } from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Shadows from 'builder/styles/shadows'

export const Container = styled.div`
  ${Shadows.lightWithBorder.low};
  width: 544px;
  min-height: 256px;
  padding: 32px;
  cursor: pointer;
  border-radius: 16px;
  background: ${Colors.White};
  border: 1px solid ${Colors.Neutral10};

  &:hover {
    ${Shadows.lightWithBorder.mid};
  }

  &:active {
    background-color: ${Colors.Neutral20};
  }

  ${Media.Tablet`
    width: 100%;
  `}

  ${Media.Phone`
    width: 100%;
    min-height: 196px;
  `}
`
