import { useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { actions, Label, selectors } from 'builder/modules/careerPlanning'

import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { updateSearchParams } from 'builder/modules/careerPlanning/utils'

import { Container, Contents } from '../../styles'
import Header from './components/Header'
import Content from './components/Content'

const MainContent = () => {
  const dispatch = useDispatch()
  const targetRef = useRef<HTMLDivElement>(null)

  const step = useTypedSelector(selectors.step)
  const headers = useTypedSelector(selectors.headers)
  const isObserving = useTypedSelector(selectors.isObserving)
  const isTopBarOpen = useTypedSelector(selectors.isTopBarOpen)
  const filterOptions = useTypedSelector(selectors.filterOptions)
  const appliedFilter = filterOptions.find(option => option.isActive)?.status

  const allContents = useTypedSelector(selectors.allContents)
  const openContents = useTypedSelector(selectors.openContents)
  const completedContents = useTypedSelector(selectors.completedContents)
  const contentOffset = useTypedSelector(selectors.contentOffset)
  const isScrollToView = useTypedSelector(selectors.isScrollToView)
  const secondLevelId = useTypedSelector(selectors.secondLevelId)
  const triggerEvent = useTypedSelector(selectors.triggerEvent)
  const { isTablet, isPhone } = useMediaQueries()

  const getContents = (appliedFilter: string | undefined) => {
    if (appliedFilter) {
      switch (appliedFilter) {
        case 'all':
          return allContents
        case 'completed':
          return completedContents
        default:
          return openContents
      }
    }
  }

  const mainContents = getContents(appliedFilter)

  const getOffset = useCallback((): number => {
    let offsetTop = contentOffset - 65

    if (isPhone) {
      return (offsetTop = contentOffset - 162)
    } else if (isTablet) {
      return (offsetTop = contentOffset - 139)
    }

    return offsetTop
  }, [contentOffset, isPhone, isTablet])

  const scrollToView = (offsetTop: number): void => {
    window.scrollTo({
      top: offsetTop,
      behavior: 'auto',
    })
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && entry.target.attributes[1].nodeValue) {
            const topLevelId = headers.find(header =>
              header.secondLevel.find(
                level => `${level.id}` === entry.target.attributes[1].nodeValue,
              ),
            )

            const subSection = topLevelId?.secondLevel.find(
              ele => `${ele.id}` === entry.target.attributes[1]?.nodeValue,
            )

            if (!triggerEvent) {
              dispatch(actions.setTriggerEvent(true))

              trackInternalEvent('enter_subsection', {
                section_name: topLevelId?.title,
                subsection_name: subSection?.title,
                ...Label,
              })
            }

            if (topLevelId?.id && step !== topLevelId.id) {
              dispatch(actions.setStep(topLevelId.id))
            }

            const lastSection = localStorage.getItem('userLastSection')
            const parsedLastSection = lastSection ? JSON.parse(lastSection) : null

            parsedLastSection?.cpSecondLevelId > 1
              ? dispatch(actions.setSecondLevelId(parsedLastSection.cpSecondLevelId))
              : dispatch(actions.setSecondLevelId(+entry.target.attributes[1].nodeValue))

            const params = {
              cpTopLevelId: topLevelId?.id,
              cpSecondLevelId: +entry.target.attributes[1].nodeValue,
            }

            if (params.cpTopLevelId && params.cpSecondLevelId) {
              updateSearchParams(params.cpTopLevelId, params.cpSecondLevelId)
            }

            localStorage.setItem('userLastSection', JSON.stringify(params))
            dispatch(actions.setIsScrollToView(false))
          }
        })
      },
      { rootMargin: '-50% 0px' },
    )

    const contentsRef = document.querySelectorAll('.content')

    if (isObserving) {
      contentsRef.forEach(content => observer.unobserve(content))

      setTimeout(() => dispatch(actions.setIsObserving(false)), 2000)
    } else {
      contentsRef.forEach(content => observer.observe(content))
    }
  }, [dispatch, headers, isObserving, step, triggerEvent])

  useEffect(() => {
    if (isScrollToView) {
      const offsetTop = getOffset()

      scrollToView(offsetTop)
    }
  }, [contentOffset, secondLevelId, isScrollToView, getOffset])

  return (
    <Container ref={targetRef}>
      {mainContents?.map(content => {
        const { id, title, description, contents } = content

        if (contents.length) {
          return (
            <Contents key={id} className="content" value={id}>
              <Header {...{ title, description, id }} />
              <Content {...{ contents, isTopBarOpen }} />
            </Contents>
          )
        }
        return null
      })}
    </Container>
  )
}

export default MainContent
