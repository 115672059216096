import aceJobInterviewImg from 'builder/components/PremiumServicesModal/assets/aceJobInterviewImg.svg'
import executeJobSearchImg from 'builder/components/PremiumServicesModal/assets/executeJobSearchImg.svg'
import exploreNextStepsImg from 'builder/components/PremiumServicesModal/assets/exploreNextStepsImg.svg'
import findTrackJobsImg from 'builder/components/PremiumServicesModal/assets/findTrackJobsImg.svg'
import firstNintyDaysPlanImg from 'builder/components/PremiumServicesModal/assets/firstNintyDaysPlanImg.svg'
import getPromotionImg from 'builder/components/PremiumServicesModal/assets/getPromotionImg.svg'
import knowWorthImg from 'builder/components/PremiumServicesModal/assets/knowWorthImg.svg'
import optimizeResumeImg from 'builder/components/PremiumServicesModal/assets/optimizeResumeImg.svg'
import tailorResumeImg from 'builder/components/PremiumServicesModal/assets/tailorResumeImg.svg'
import careerPlans from 'builder/components/PremiumServicesModal/assets/career_plans.svg'
import careerAdvice from 'builder/components/PremiumServicesModal/assets/career_advice.svg'
import exploreCareers from 'builder/components/PremiumServicesModal/assets/explore_careers.svg'

const premiumServiceModalData = [
  {
    id: 'interview_prep',
    imgSrc: aceJobInterviewImg,
    redirectUrl: '/app/interview-preparation/dashboard',
  },
  {
    id: 'job_search_strategy',
    imgSrc: executeJobSearchImg,
    redirectUrl: '/app/career-plans/execute-a-job-search',
  },
  { id: 'career_path', imgSrc: exploreNextStepsImg, redirectUrl: '/app/career-path' },
  { id: 'explore_your_next_steps', imgSrc: exploreNextStepsImg, redirectUrl: '/app/career-path' },
  { id: 'job_search', imgSrc: findTrackJobsImg, redirectUrl: '/app/job-search' },
  {
    id: 'first_90_days_plan',
    imgSrc: firstNintyDaysPlanImg,
    redirectUrl: '/app/career-plans/first-90-days',
  },
  {
    id: 'plan_to_promotion',
    imgSrc: getPromotionImg,
    redirectUrl: '/app/career-plans/path-to-promotion',
  },
  { id: 'salary_analyzer', imgSrc: knowWorthImg, redirectUrl: '/app/offer-analyzer' },
  { id: 'resume_optimizer', imgSrc: optimizeResumeImg, redirectUrl: '' },
  { id: 'resume_tailoring', imgSrc: tailorResumeImg, redirectUrl: '' },
  { id: 'explore_careers', imgSrc: exploreCareers, redirectUrl: '/app/explore-careers' },
  { id: 'professional_resources', imgSrc: careerAdvice, redirectUrl: '/career-advice' },
  { id: 'job_tracking', imgSrc: findTrackJobsImg, redirectUrl: '/app/job-tracking' },
  { id: 'career_planning', imgSrc: careerPlans, redirectUrl: '/app/career-plans' },
]
export default premiumServiceModalData
