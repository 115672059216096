import { useCallback, useEffect, useState } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useDispatch } from 'react-redux'

import { selectors as userSelectors } from 'builder/modules/user'
import {
  selectors as renderingSelectors,
  actions as renderingActions,
} from 'builder/modules/rendering'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { billingPlanPageURL, goToBillingPlanPage } from 'builder/utils/goToBillingPlanPage'

const MODAL_SHOWN_STORAGE_KEY = 'rd_promo_shown'

export const useResumeDistributionPromoModal = () => {
  const wasRdPromoShown = localStorage.getItem(MODAL_SHOWN_STORAGE_KEY)

  const dispatch = useDispatch()
  const features = useFeaturesConfig()

  const upgradeUrl = billingPlanPageURL({
    upgradeURL: useTypedSelector(userSelectors.getUpgradeUrl),
  })
  const userData = useTypedSelector(userSelectors.userData)
  const rdUpsellPromoModalVisible = useTypedSelector(renderingSelectors.rdUpsellPromoModalVisible)
  const showUpsellRdPromoModal = useTypedSelector(renderingSelectors.showUpsellRdPromoModal)

  const getPlan = useCallback((): string | boolean => {
    if (features.features.rdPromo) {
      return features.features.rdPromo.rdPromoPlan as string
    }
    return false
  }, [features])

  const shouldModalOpen = useCallback(() => {
    return (
      (rdUpsellPromoModalVisible &&
        !wasRdPromoShown &&
        getPlan() &&
        features?.features?.resumeDistribution) ||
      showUpsellRdPromoModal
    )
  }, [
    features?.features?.resumeDistribution,
    getPlan,
    rdUpsellPromoModalVisible,
    wasRdPromoShown,
    showUpsellRdPromoModal,
  ])

  const [isOpen, setIsOpen] = useState(shouldModalOpen)

  function setRDPromoShown() {
    localStorage.setItem(MODAL_SHOWN_STORAGE_KEY, 'true')
  }

  function handleModalClose() {
    dispatch(renderingActions.closeRDUpsellPromoModal())
    dispatch(renderingActions.toggleRDUpsellPromoModal(false))

    setRDPromoShown()

    if (userData?.scopes.autoApply && userData?.scopes.resumeDistribution) {
      dispatch(renderingActions.showRDUpsellGetStartedModal())
    }
  }

  const showWho = useCallback(() => {
    if (getPlan() === 'flat') {
      return 'TR_customers'
    } else {
      return 'TR_non_converted'
    }
  }, [getPlan])

  function redirectToPlans() {
    trackInternalEvent('click_rd_upgrade_button', { who: showWho() })
    goToBillingPlanPage({
      upgradeURL: upgradeUrl || '/app/billing/plans',
      plan: 'sub_usa_rd_promo',
    })
  }

  function redirectToPayment() {
    trackInternalEvent('click_rd_upgrade_button', { who: showWho() })

    const plan = 'sub_usa_rd_promo'
    window.open(`/app/billing/payment?plan=${plan}`, '_self')
  }

  function redirectToCheckout() {
    if (getPlan() === 'flat') {
      redirectToPayment()
    } else {
      redirectToPlans()
    }
  }

  function getCTAText() {
    return getPlan() === 'flat'
      ? 'builder.resume_distribution.promo.cta.unlock'
      : 'builder.resume_distribution.promo.cta.upgrade'
  }

  function getPriceFromPlan() {
    if (getPlan() === 'flat') {
      return '$4.95'
    } else if (getPlan() === 'regular') {
      return '$24.95'
    }
  }

  function shouldRenderPlanRenewalNotice() {
    return getPlan() === 'flat'
  }

  useEffect(() => {
    if (shouldModalOpen()) {
      trackInternalEvent('show_promo_modal', {
        who: showWho(),
        type: 'RD',
      })
    }
    setIsOpen(shouldModalOpen())
  }, [rdUpsellPromoModalVisible, showUpsellRdPromoModal, shouldModalOpen, showWho])

  return {
    isOpen,
    handleModalClose,
    redirectToCheckout,
    getCTAText,
    getPriceFromPlan,
    shouldRenderPlanRenewalNotice,
  }
}
