import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import { AlignType } from 'builder/components/Checkbox/types'

export const CheckboxWrapper = styled.div<{ align?: AlignType }>`
  position: relative;
  margin-right: 12px;
  width: 18px;
  height: 18px;
`

export const IconWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.White};
  z-index: 1;
`

export const CheckboxItem = styled.input<{ invalid: boolean }>`
  appearance: none;
  border: 2px solid ${Colors.Neutral40};
  will-change: background-color;
  transition: background-color 0.1s ease, border-color 0.1s ease;
  padding: 7px;
  border-radius: 3px;
  display: inline-block;
  position: relative;
  vertical-align: top;
  cursor: pointer;
  outline: none;

  :checked {
    border-color: ${Colors.Blue50};
    background-color: ${Colors.Blue50};

    :hover {
      border-color: ${Colors.Blue60};
      background-color: ${Colors.Blue60};

      ${props =>
        props.invalid &&
        css`
          border-color: ${Colors.Red60};
          background-color: ${Colors.Red60};
        `}
    }
  }

  :hover {
    border-color: ${props => (props.invalid ? Colors.Red60 : Colors.Blue50)};
  }

  ${props =>
    props.invalid &&
    css`
      border-color: ${Colors.Red50};

      :checked {
        border-color: ${Colors.Red50};
        background: ${Colors.Red50};
      }
    `}

  :disabled,
  :disabled:hover {
    cursor: not-allowed;
    border-color: ${Colors.Neutral30};
    background: ${Colors.Neutral30};
  }
`

export const Label = styled.label<{ align?: AlignType; invalid: boolean; disabled: boolean }>`
  ${Typography.Caption};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  user-select: none;
  cursor: ${p => (p.disabled ? 'not-allowed' : 'pointer')};

  ${({ align }) => {
    switch (align) {
      case 'top':
        return `
          align-items: flex-start;
        `
    }
  }};

  ${({ invalid }) => invalid && `color: ${Colors.Red50};`};
`
