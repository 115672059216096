import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Card = styled.div<{ backgroundColor: string; open: boolean }>`
  width: 100%;
  height: ${({ open }) => (open ? '208px' : '0px')};
  border-radius: 16px;
  background-color: ${props => props.backgroundColor};
  flex: 1 0 21%;
  display: flex;
  position: relative;
  bottom: 0;
  overflow: hidden;
`

export const JobInfo = styled.div`
  padding: 0;
  position: absolute;
  bottom: 20px;
  width: 60%;
`

export const Header = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

export const HeaderText = styled.label<{ color: string }>`
  ${Typography.TextS};
  ${FontWeights.Medium};
  padding: 20px 0 0px 20px;
  color: ${props => props.color};
`

export const Body = styled.div`
  margin-top: 0px;
`

export const PrimaryText = styled.label`
  ${Typography.TextS};
  ${FontWeights.DemiBold};
`

export const SecondaryText = styled.label<{ color: string }>`
  ${Typography.Tiny};
  ${FontWeights.Regular};
  display: block;
  margin-top: 6px;
  padding: 0 20px;
  color: ${props => props.color};
`

export const ButtonContainer = styled.div`
  margin-top: 20px;
  padding: 0 20px;

  ${Media.Phone`
    margin-top: 25px;
  `}
`

export const PrimaryButton = styled.button<{ score: string }>`
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  ${FontWeights.Medium};
  ${Typography.Tiny}
  display: block;

  ${props =>
    props.score === 'low' &&
    css`
      background-color: ${Colors.Red20};
      color: ${Colors.Neutral90};
    `};

  ${props =>
    props.score === 'medium' &&
    css`
      background-color: ${Colors.Amber20};
      color: ${Colors.Neutral90};
    `};

  ${props =>
    props.score === 'high' &&
    css`
      background-color: ${Colors.Blue80};
      color: ${Colors.White};
    `};

  &:hover {
    ${props =>
      props.score === 'low' &&
      css`
        background-color: ${Colors.Red30};
      `};

    ${props =>
      props.score === 'medium' &&
      css`
        background-color: ${Colors.Amber30};
      `};

    ${props =>
      props.score === 'high' &&
      css`
        background-color: ${Colors.Blue90};
      `};
  }
`

export const MarketingImageContainer = styled.div`
  overflow: hidden;
  z-index: 99999;
  position: absolute;
  bottom: 0;
  right: 0;
`

export const MarketingImage = styled.img<{ score: string }>`
  border-bottom-right-radius: 16px;

  /* ${props =>
    props.score === 'low' &&
    css`
      box-shadow: 0 0 50px ${Colors.Red30};
    `};

  ${props =>
    props.score === 'medium' &&
    css`
      box-shadow: 0 0 50px ${Colors.Amber30};
    `};

  ${props =>
    props.score === 'high' &&
    css`
      box-shadow: 0 0 50px ${Colors.Blue60};
    `}; */
`
