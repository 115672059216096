export enum MessageTypesEnum {
  Loaded = 'loaded',
  Resize = 'resize',
  LinkClick = 'link_click',
  Error = 'error',
}

// TopResume event message payloads we can accept from the iframe
export type ResizeMessagePayload = {
  type: MessageTypesEnum.Loaded | MessageTypesEnum.Resize
  height: number
}
export type ClickLinkMessagePayload = { type: MessageTypesEnum.LinkClick; url: string }

export type ValidMessagePayload = ResizeMessagePayload | ClickLinkMessagePayload
