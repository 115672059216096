import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { CareerTask, useCareerTasks } from 'builder/components/CareerGoalCard'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { actions, selectors } from 'builder/modules/user'
import { FetchStatuses } from 'builder/modules/constants'
import CIO_LOGO_INLINE_URL from 'images/countries/cio-icon.svg'

import { Sources } from './types'
import * as Styles from './styles'

interface SuperAppPromoViewProps {
  source: Sources
}

// What card we need to put in center
const cardSelectionMap = {
  [Sources.jobTracking]: { taskName: 'job_search', locale: 'job_tracking' },
  [Sources.jobSearch]: { taskName: 'job_search', locale: 'job_search' },
  [Sources.interviewPrep]: { taskName: 'interview_prep', locale: 'interview_prep' },
}

export const SuperAppPromoView = ({ source }: SuperAppPromoViewProps) => {
  const dispatch = useDispatch()
  const { i18n } = useI18n()
  const status = useTypedSelector(selectors.superAppLoginStatus)
  const { taskName, locale } = cardSelectionMap[source]

  const { isLoaded, careerTasks } = useCareerTasks()

  // Move feature card in center
  const orderedTasks: CareerTask[] = useMemo(() => {
    const featureCard = careerTasks.find(task => task.name === taskName)
    if (!featureCard) return careerTasks.slice(1, 6)

    const restTasks = careerTasks
      .filter(task => task.name !== taskName && task.name !== 'cover_letter')
      .slice(1, 5)

    return [...restTasks.slice(0, 2), featureCard, ...restTasks.slice(2)]
  }, [careerTasks, taskName])

  const handleActionClick = () => {
    trackInternalEvent('click_cio_activation_button', { source })
    dispatch(actions.loginToSuperApp())
  }

  const descriptionHtml = useMemo(() => {
    return i18n.t(`builder.super_app_promotion.description`, {
      premium_user: `<b>${i18n.t(`builder.super_app_promotion.premium_user`)}</b>`,
    })
  }, [i18n])

  const titleText = useMemo(() => {
    return i18n.t('builder.super_app_promotion.title', {
      feature: i18n.t(`builder.navbar.${locale}`),
    })
  }, [i18n, locale])

  return (
    <Styles.Container>
      <Styles.LogoImage src={CIO_LOGO_INLINE_URL} />
      <Styles.Title>{titleText}</Styles.Title>
      <Styles.Description dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
      <Styles.ActionButton isLoading={status === FetchStatuses.loading} onClick={handleActionClick}>
        {i18n.t(`builder.super_app_promotion.action`)}
      </Styles.ActionButton>
      <Styles.ActionDescription>
        {i18n.t(`builder.super_app_promotion.action_description`)}
      </Styles.ActionDescription>
      <Styles.Cards>
        {isLoaded && (
          <Styles.CardsList>
            {orderedTasks.map(task => (
              <Styles.CareerGoalCard key={task.name} careerTask={task} interactive={false} />
            ))}
          </Styles.CardsList>
        )}
      </Styles.Cards>
    </Styles.Container>
  )
}
