/**
 * It would be better to always get the client from `I18nContext` context (for example via hook):
 * ```
 * // Recommended (use only inside of React-tree)
 * import { useI18n } from 'builder/hooks/useI18n'
 * const MyComponent = () => {
 *   const { i18n } = useI18n()
 *   return <div>{i18n.t('some.key')}</div>
 * }
 * ```
 *
 * But to perform a migration and make everything backward compatible we need to have JS util now:
 * ```
 * // Backward compatible (not recommended, but it works anywhere)
 * import { i18n } from 'builder/utils/i18n'
 * i18n.t('some.key')
 * ```
 *
 * So we are making both approaches work by reexporting i18n utils.
 */
export { i18n } from 'builder/context/I18nContext'
