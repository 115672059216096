import { Component } from 'react'
import PropTypes from 'prop-types'
import jump from 'jump.js'
import AnchorManager from 'builder/services/AnchorManager'

class Anchor extends Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    delay: PropTypes.number,
    scrollOffsetTop: PropTypes.number,
    children: PropTypes.node,
  }

  static defaultProps = {
    delay: 0,
    scrollOffsetTop: 110,
  }

  componentDidMount() {
    AnchorManager.attachAnchor(this.props.id, this.handleMessageReceive)
  }

  componentWillUnmount() {
    AnchorManager.detachAnchor(this.props.id)
    clearTimeout(this.jumpTimer)
  }

  handleMessageReceive = options => {
    this.jumpTimer = setTimeout(this.jumpToContainer, this.props.delay)
  }

  jumpToContainer = () => {
    if (!this.containerRef) return

    jump(this.containerRef, {
      offset: -1 * this.props.scrollOffsetTop,
      duration: distance => Math.min(Math.abs(distance) * 0.3 + 140, 400),
    })
  }

  render() {
    return <div ref={ref => (this.containerRef = ref)}>{this.props.children}</div>
  }
}

export default Anchor
