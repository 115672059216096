import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Icon24 } from 'builder/components/Icon'
import { TooltipQuestion } from 'builder/components/Tooltip/variations'
import DragHandle from 'builder/components/DragHandle'
import Media from 'builder/styles/media'
import Shadows from 'builder/styles/shadows'
import Tooltip from 'builder/components/Tooltip/Tooltip'

export const Container = styled.div``

export const TipsTitle = styled.div`
  ${Typography.Caption};
  color: ${Colors.Blue50};
  ${FontWeights.Regular};

  &:hover {
    color: ${Colors.Blue60};
  }
`

export const HintIcon = styled(Icon24.HintOutlined)`
  width: 20px;
  height: 20px;
  color: ${Colors.Blue50};

  &:hover {
    color: ${Colors.Blue60};
  }
`

export const TipsContainer = styled.div`
  width: 50px;
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 4px;

  &:hover {
    ${TipsTitle} {
      color: ${Colors.Blue60};
    }

    ${HintIcon} {
      color: ${Colors.Blue60};
    }
  }
`

export const TooltipQuestionWrapper = styled(Tooltip)`
  & > div {
    width: 353px;
    left: auto;
    right: 375px;

    & > svg {
      left: -15px;
      right: auto;
      color: ${Colors.Neutral90};
    }

    .hint-icon {
      color: ${Colors.Blue50};

      &:hover {
        color: ${Colors.Blue60};
      }
    }
  }

  .tips-container {
    width: auto;
  }
`

export const TooltipQuestionWrapperTablet = styled(TooltipQuestion)<typeof TooltipQuestion>`
  & > div {
    width: 353px;
  }
`

export const AccomplishmentsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 8px 0 0 0;
`

export const AccomplishmentsListItem = styled.li<{ isMaxSelection: boolean }>`
  border-radius: 8px;
  border: 1px solid ${Colors.Neutral15};
  background: ${Colors.White};
  padding: 16px;
  gap: 12px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0 0 0;
  transition: all ease-out 0.6s;

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`

export const ItemCheck = styled.div<{ isSelected: boolean; isMaxSelection: boolean }>`
  ${Typography.Caption};
  ${FontWeights.Regular};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;

  cursor: pointer;
  background-color: ${props =>
    props.isSelected ? Colors.Blue10 : props.isMaxSelection ? Colors.Neutral10 : ''};
  color: ${Colors.Blue50};
  transition: all ease-out 0.6s;

  ${props =>
    !props.isSelected &&
    css`
      border: 1px solid ${Colors.Neutral30};
    `}
`

export const ItemDescription = styled.div<{ isMaxSelection: boolean }>`
  ${FontWeights.Regular}
  ${Typography.Body}
  flex: 1 0 0;
  color: ${props => (props.isMaxSelection ? Colors.Neutral60 : Colors.Neutral90)};
  transition: all ease-out 0.6s;
`

export const DragIcon = styled(DragHandle)`
  width: 20px;
  height: 20px;
  opacity: 1;
`

export const Divider = styled.div<{ isItemSelected: boolean }>`
  background: ${Colors.Neutral20};
  height: 0.5px;
  margin: 16px auto;
  max-width: 350px;
  opacity: ${props => (props.isItemSelected ? 1 : 0)};
  overflow: hidden;
  animation: expand 1s forwards;

  @keyframes expand {
    0% {
      width: 50px;
    }
    100% {
      width: 100%;
    }
  }
`

export const ModalContainer = styled.div`
  ${Shadows.dark.high};
  width: 544px;
  padding: 32px;
  border-radius: 8px;
  background: ${Colors.White};
  display: flex;
  flex-direction: column;
  position: relative;

  ${Media.Phone`
    padding: 20px;
  `}
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`

export const ModalTitle = styled.div`
  ${Typography.S};
  ${FontWeights.DemiBold};
`

export const CloseIcon = styled(Icon24.CloseLarge)`
  cursor: pointer;
  color: ${Colors.Neutral30};
`

export const ModalButtonContainer = styled.div`
  text-align: right;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 32px;
`

export const ToolTipIcon = styled.img`
  width: 50px;
  cursor: pointer;
  height: 20px;
  margin-bottom: 10px;
`
