import { FormEvent, ChangeEvent } from 'react'

import {
  Container,
  Question,
  Form as StyledForm,
  Input,
  JobTitle,
  Location,
  Wrapper,
  StyledButton,
} from './styles'

interface Props {
  value: string
  jobTitle: string
  location: string
  isSubmitted: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onSubmit: (e: FormEvent<HTMLFormElement>, value: string) => void
}

const Form = ({ value, onChange, onSubmit, jobTitle, location }: Props) => {
  return (
    <Container data-testid="form-container">
      <Question>
        What&apos;s an accurate total pay for <JobTitle>{jobTitle}</JobTitle> in{' '}
        <Location>{location || 'US'}</Location> with all years of experience?
      </Question>
      <StyledForm onSubmit={e => onSubmit(e, value)}>
        <Wrapper>
          <Input
            type="text"
            value={value}
            onChange={onChange}
            placeholder="Enter the amount that seems correct"
          />
          {/* TODO: Show Last time <Message /> in next iteration */}
        </Wrapper>
        <StyledButton type="submit">Send</StyledButton>
      </StyledForm>
    </Container>
  )
}

export default Form
