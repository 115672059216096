export const SERVICES = [
  { id: 1, text: 'Resume Builder' },
  { id: 2, text: 'Resume Optimizer' },
  { id: 3, text: 'Job Search' },
  { id: 4, text: 'Job Tracker' },
  { id: 5, text: 'Salary Analyzer' },
  { id: 6, text: 'Interview Prep' },
  { id: 7, text: 'Career Plans' },
  { id: 8, text: 'Career Pathways' },
]

export const PROMO_SERVICES = [
  { id: 1, text: 'Cover Letter Builder' },
  { id: 2, text: 'Job Tracker' },
  { id: 3, text: 'Interview Prep' },
  { id: 4, text: 'Career Pathways' },
  { id: 5, text: 'Job Search Strategy' },
  { id: 6, text: 'Headshots', subText: '(coming soon)' },
  { id: 7, text: 'Salary Analyzer' },
  { id: 8, text: 'Career Plans' },
]

export const PROMO_MOBILE_SERVICES = [
  { id: 1, text: 'Cover Letter Builder' },
  { id: 2, text: 'Interview Prep' },
  { id: 3, text: 'Job Search Strategy' },
]
