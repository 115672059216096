import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import { rotation } from 'builder/styles/keyframes'

const { Caption, Body, Tiny } = Typography
const { White, Neutral5, Neutral50, Neutral90, Blue10, Blue50, Indigo40, Indigo50 } = Colors

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  ${Media.Tablet`
    flex-direction: column;
    gap: 8px;
  `}
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  background: ${Neutral5};
  ${Media.AboveTablet`
    &:hover {
      cursor: pointer;
      background: ${Blue10};
    }
  `}
  ${Media.Tablet`
    flex-direction: row;
    border-radius: 6px;
    padding-right: 20px;
  `}
  ${Media.Phone`
    border-radius: 4px;
    padding-right: 16px;
  `}
`

export const CreateWithAICard = styled(Card)`
  position: relative;
  ${Media.AboveTablet`
    &:hover {
      background: rgba(241, 242, 255, 0.6);
    }
  `}
`

export const CardImgContainer = styled.div`
  height: 168px;
  width: 222px;
  ${Media.Tablet`
    height: 110px;
    width: 160px;
  `}
  ${Media.Phone`
    height: 104px;
    width: 110px;
  `}
`

export const CardImg = styled.img`
  height: 108px;
  width: 145px;
  transition: transform 0.4s ease;
  ${Media.AboveTablet`
    ${Card}:hover & {
      transform: scale(1.04);
    }
  `}
`

export const CreateNewResumeCardImg = styled(CardImg)`
  margin-top: 36px;
  margin-left: 39px;
  ${Media.Tablet`
    margin-top: 16px;
    margin-left: 20px;
    width: 108px;
    height: 78px;
  `}
  ${Media.Phone`
    margin-top: 20px;
    margin-left: 16px;
    width: 88px;
    height: 55px;
  `}
`

export const CreateWithAICardImg = styled(CardImg)`
  margin-top: 36px;
  margin-left: 38px;
  ${Media.Tablet` 
    margin-top: 12px;
    margin-left: 22px;
    width: 120px;
    height: 89px;
  `}
  ${Media.Phone`
    margin-top: 16px;
    margin-left: 16px;
    width: 88px;
    height: 66px;
  `}
`

export const UseExampleCardImg = styled(CardImg)`
  width: 204px;
  height: 104px;
  margin-top: 36px;
  margin-left: 9.5px;
  ${Media.Tablet`
    margin-top: 12px;
    margin-left: 6px;
    width: 136px;
    height: 79px;
  `}
  ${Media.Phone`
    margin-top: 16px;
    margin-left: 8px;
    width: 100px;
    height: 72px;
  `}
`

export const UploadResumeCardImg = styled(CardImg)`
  margin-top: 36px;
  margin-left: 38px;
  ${Media.Tablet`
    margin-top: 16px;
    margin-left: 10px;
    width: 118px;
    height: 83px;
  `}
  ${Media.Phone`
    margin-top: 16px;
    margin-left: 16px;
    width: 88px;
    height: 70px;
  `}
`

export const CardDescription = styled.div`
  width: 181px;
  text-align: center;
  margin-bottom: 32px;
  ${Media.Tablet`
    width: 320px;
    text-align: unset;
    margin-bottom: unset;
  `}
  ${Media.Phone`
    width: 185px;
  `}
`

export const CardTitle = styled.div`
  color: ${Neutral90};
  ${FontWeights.DemiBold};
  ${Body}
  margin-bottom: 4px;
  margin-left: auto;
  margin-right: auto;
  ${Media.AboveTablet`
    ${Card}:hover & {
      color: ${Blue50};
    }
    max-width: 103px;
  `}
  ${Media.Phone`
     ${Caption};
  `}
`

export const CreateWithAICardTitle = styled(CardTitle)`
  ${Media.AboveTablet`
    ${Card}:hover & {
      color: ${Indigo50};
    }
  `}
`

export const UploadResumeCardTitle = styled(CardTitle)`
  ${Media.AboveTablet`
    max-width: 121px;
  `}
`

export const CardSubTitle = styled.div`
  color: ${Neutral50};
  ${Tiny};
`

export const AIPoweredBadge = styled.div`
  padding: 4px 8px;
  border-radius: 4px;
  background: rgba(219, 222, 255, 0.5);
  color: ${Colors.Indigo60};
  ${Tiny};
  ${FontWeights.DemiBold};
  position: absolute;
  top: 14px;
  right: 12px;
  transition: background 0.4s ease, color 0.4s ease;
  ${Media.AboveTablet`
    ${Card}:hover & {
     color: ${White};
     background: ${Indigo40};
    }
  `}
  ${Media.Tablet`
    top: 10px;
    right: 10px;
    padding: 2px 8px;
  `}
  ${Media.Phone`
    display: none;
  `}
`

export const LoadingCard = styled.div`
  width: 222px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: ${Neutral5};
  ${Media.Tablet`
    width: 100%;
    height: 110px;
    border-radius: 6px;
  `}
  ${Media.Phone`
    height: 104px;
    border-radius: 4px;
  `}
`

export const Spinner = styled.img`
  height: 24px;
  width: 24px;
  animation: ${rotation} 0.5s infinite linear;
`
