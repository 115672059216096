import styled, { keyframes } from 'styled-components'

import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import { Icon20 } from 'builder/components/Icon'
import Typography from 'builder/styles/typography'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`
const bigToNormalSize = keyframes`
  from {
    transform: scale(1.4);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`

const moveToUp = keyframes`
  from {
    opacity: 0;
    bottom: -15px;
  }
  
  to {
    opacity: 1;
    bottom: 0;
  }
`

export const ResumeUploaded = styled.div<{ progressing: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${props => (props.progressing ? 176 : 144)}px;
  border-radius: 8px;
  margin-bottom: 32px;
  border: 1px solid ${Colors.Neutral15};

  ${props => Media.Phone`
    padding-top: ${props.progressing ? 156 : 126}px;
  `};

  @media (max-width: 374px) {
    padding-top: ${props => (props.progressing ? 136 : 104)}px;
  }
`

export const GreenTick = styled(Icon20.Tick)`
  border-radius: 50%;
  margin-bottom: 12px;
  color: ${Colors.White};
  position: relative;
  background-color: ${Colors.Green50};
  animation: 0.4s ${moveToUp} ease-in;
`

export const ResumeUploadedText = styled.div`
  ${Typography.Caption};

  margin-bottom: 12px;
  color: ${Colors.Neutral50};
  animation: 0.4s ${fadeIn} ease-in;
`

export const ResumeUploadingText = styled(ResumeUploadedText)`
  animation: 0.4s ${bigToNormalSize} ease-in;
`

export const ResumeUploadProgressBarContainer = styled.div`
  width: 201px;
  position: relative;
`

export const ResumeUploadProgressBarWrapper = styled.div<{ progressPercentage: number }>`
  width: inherit;
  position: relative;
  background-color: ${Colors.Neutral15};
  height: 4px;
  margin-bottom: ${props => (props.progressPercentage < 100 ? 152 : 24)}px;

  ${props => Media.Phone`
    margin-bottom: ${props.progressPercentage < 100 ? 158 : 24}px; 
  `};

  @media (max-width: 374px) {
    margin-bottom: ${props => (props.progressPercentage < 100 ? 138 : 24)}px;
  }
`

export const ResumeUploadProgressBar = styled.div<{ progressPercentage: number }>`
  width: ${props => props.progressPercentage}%;
  height: 4px;
  background-color: ${Colors.Blue50};
  border-radius: 32px;
  display: flex;
  transition: width 0.5s;
  justify-content: start;
  visibility: ${props => (props.progressPercentage === 0 ? 'hidden' : 'visible')};
`
