import { useNavigate } from 'react-router-dom'
import { useI18n } from 'builder/hooks/useI18n'
import Button, { ButtonTheme } from 'builder/components/Button'
import { BackButtonMobile } from 'builder/components/FindJob/BackButtonMobile/BackButtonMobile'
import { CenterContainer, Image, Subtitle, Title } from './styles'

import ImageSrc from './image.png'

const TRANSLATION = 'builder.job_search.job_no_longer_available'
interface Props {
  onBack?: () => void
}

export const JobNoLongerAvailable = ({ onBack }: Props) => {
  const { i18n } = useI18n()
  const navigate = useNavigate()

  return (
    <>
      {onBack && <BackButtonMobile onClick={onBack} />}
      <CenterContainer>
        <Image src={ImageSrc} />
        <Title>{i18n.t(`${TRANSLATION}.title`)}</Title>
        <Subtitle>{i18n.t(`${TRANSLATION}.subtitle`)}</Subtitle>
        <Button
          theme={ButtonTheme.default}
          onClick={() => {
            navigate(`/job-search`)
          }}
        >
          {i18n.t(`${TRANSLATION}.button`)}
        </Button>
      </CenterContainer>
    </>
  )
}
