import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Button from 'builder/components/Button'
import CareerPath2Slider from 'builder/components/CareerPath2Slider'
import { actions, CareerPathStep } from 'builder/modules/careerPath2'

import { BottomContainer, ButtonContainer } from '../../styles'

const Footer = () => {
  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    dispatch(actions.setValidationError(false))
    dispatch(actions.setStep(CareerPathStep.QUESTIONNAIRE))
  }, [dispatch])

  return (
    <BottomContainer>
      <CareerPath2Slider currentSlide="SKILLS" />
      <ButtonContainer>
        <Button theme="ghost" onClick={handleClick}>
          Back
        </Button>
        <Button type="submit">Build Career Path</Button>
      </ButtonContainer>
    </BottomContainer>
  )
}

export default Footer
