import styled, { keyframes } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Select from 'builder/components/Select'
import { RadioGroup } from 'builder/components/RadioGroup'
import {
  ButtonsContainer,
  BottomContainer,
  RowsContainer,
} from 'builder/components/SimpleForm/styles'
import Media from 'builder/styles/media'

const rotation = keyframes`
  from {
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
  }

  to {
    transform: translateX(-50%) translateY(-50%) rotate(360deg);
  }
`

export const FormContainer = styled.div<{ $heightOfOthers?: number }>`
  margin-top: 24px;
  width: 100%;

  form {
    height: calc(100vh - ${({ $heightOfOthers }) => $heightOfOthers ?? '245'}px);
    display: flex;
    flex-direction: column;

    ${Media.Phone`
      height: calc(100vh - 280px);
   `};
  }

  ${RowsContainer} {
    padding-bottom: 30px;
  }

  ${ButtonsContainer} {
    margin-top: 0;
  }

  ${BottomContainer} {
    bottom: 0;
    padding-bottom: var(--size-m);
    background-color: ${Colors.White};
    position: sticky;

    ${Media.Phone`
      bottom: 0;
      padding-bottom: 10px;
   `};
  }
`

export const Spinner = styled.div`
  position: absolute;
  width: 10px;
  transform: translateX(-50%) translateY(-50%);
  animation: ${rotation} 0.5s infinite linear;
  top: 10px;
  right: 10px;
`

export const Salary = styled.div`
  display: flex;
  gap: var(--size-2xs);
`

export const CurrencySelect = styled(Select)`
  width: 100px;
`

export const SalaryPlanSelect = styled(Select)`
  width: 125px;
`
export const SmallTitle = styled.div`
  color: ${Colors.Neutral50};
  ${Typography.Caption};
  ${FontWeights.Regular};
  padding-bottom: 5px;
`

export const TravelRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: row;
`

export const Courses = styled.div`
  display: flex;
  gap: var(--size-xs);
  flex-direction: column;
`

export const CourseContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--size-s);
  padding: 0 var(--size-s) var(--size-s) var(--size-s);
`

export const Course = styled.div`
  border: 1px solid ${Colors.Neutral15};
  border-radius: 4px;
  background-color: ${Colors.White};
`

export const CourseTitle = styled.div`
  color: ${Colors.Neutral90};
  ${Typography.Caption};
  ${FontWeights.DemiBold};
  text-transform: capitalize;
`

export const CourseDates = styled.div`
  color: ${Colors.Neutral50};
  ${Typography.Caption};
  ${FontWeights.Regular};
`

export const StartEndDate = styled.div`
  display: flex;
  gap: var(--size-xs);
`
export const ResumeBox = styled.div`
  border: 1px solid ${Colors.Neutral15};
  border-radius: var(--size-2xs);
  padding: var(--size-s);
  margin-bottom: var(--size-s);
  display: flex;
  gap: var(--size-s);
  justify-content: space-between;
`

export const ResumeBoxLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--size-m);
  justify-content: space-between;
`

export const ResumeBoxRight = styled.div`
  width: 104px;
  img {
    width: 104px;
    height: 106px;
  }
`

export const FileInput = styled.input`
  display: none;
`

export const ResumeName = styled.div`
  ${Typography.Caption};
  ${FontWeights.Regular};
`

export const ResumeDate = styled.div`
  color: ${Colors.Neutral50};
  ${Typography.Caption};
  ${FontWeights.Regular};
`

export const ResumeNameAndDate = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--size-3xs);
`
export const Required = styled.span`
  color: ${Colors.Red50};
`

export const SelectInfoText = styled.div`
  margin-top: 4px;
  color: ${Colors.Neutral50};

  ${Typography.Caption};
  ${FontWeights.Regular};
`
