import styled from 'styled-components'
import { HorizontalTransitionFade } from 'builder/components/HorizontalTransitionFade'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'

export const CentralColumn = styled.div`
  width: auto;
  margin: 0;

  ${Media.AboveTablet`
    width: 736px;
    margin: auto;
  `}

  ${Media.AboveTablet`
    width: 736px;
    margin: auto;
  `}
`

export const Section = styled.div`
  display: flex;
  flex-flow: column;
  gap: 64px;
  padding: 64px;
  border-radius: 16px;

  ${Media.Phone`
    gap: 24px;
    padding: 24px;
  `}
`

export const Container = styled(HorizontalTransitionFade)`
  display: flex;
  flex-flow: column;
  gap: 128px;
  padding-bottom: 128px;

  ${Media.Tablet`
    gap: 96px;
    padding-bottom: 96px;
  `}

  ${Media.Phone`
    gap: 32px;
    padding-bottom: 32px;
  `}
`

export const MainSections = styled.div`
  display: flex;
  flex-flow: column;
  gap: 64px;

  ${Media.Tablet`
    gap: 48px;
  `}

  ${Media.Phone`
    gap: 20px;
  `}
`

export const LoaderWrapper = styled.div`
  flex: auto;
  padding-bottom: 64px;
  display: flex;
  flex-flow: column;
`

export const SelectResumeModalHeader = styled.div`
  ${Typography.Subhead};
  ${FontWeights.DemiBold};
`
