import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'

export const HeadingContainer = styled.div`
  position: absolute;
  height: 72px;
  left: 0px;
  right: 0px;
  top: 0px;
  background: rgba(255, 255, 255, 0.92);
  backdrop-filter: blur(4px);
`

export const HeaderBottomBorder = styled.div`
  opacity: 0;
  ${Media.Tablet`
    border-bottom: 1px solid ${Colors.Neutral90};
    opacity: 0.08;
  `}
`

export const NavBar = styled.div`
  height: inherit;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

export const Logo = styled.img`
  width: 294.25px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`
