import { Icon24 } from 'builder/components/Icon'
import Colors from 'builder/styles/colors'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/init'
import usePromotionCardValues from '../../../hooks/usePromotionCardValues'
import { Cards } from '../../../common/types'
import { useCancellationFormContext } from '../FormSection'
import { Card } from './Card'
import { CardContainer } from './CardContainer'
import { CardImage } from './CardImage'
import { CardSection } from './CardSection'
import { CardTitle } from './CardTitle'

/**
 * The dimensions for the styles used for each component have been referred from the figma
 * https://www.figma.com/file/wdX0fbRVlodq1Is8l1GwoV/APP-1372%3A-Subscription-cancelation?node-id=1%3A2&t=sJ8R8w5Ggv06RqH5-0
 */

const PromotionCards = ({
  threePromoSubCancellation,
}: {
  threePromoSubCancellation: boolean | undefined
}) => {
  const { activeCard, handlePromotionClick, views } = useCancellationFormContext()
  const { getCardImage, getCardTitle } = usePromotionCardValues()
  const showToggleArrow = !views.isDesktop
  const arrowStyles = {
    fill: Colors.Blue50,
    marginLeft: views.isPhone ? '12px' : '',
  }
  const locale = useTypedSelector(selectors.locale)
  let filteredCards = Object.values(Cards)

  if (threePromoSubCancellation) {
    filteredCards = filteredCards.filter(card => card !== Cards.DreamJob)
  } else {
    filteredCards = filteredCards.filter(card => card !== Cards.DontNeedPromos)
  }

  return (
    <CardContainer isCardClicked={!!activeCard}>
      {filteredCards.map(card => {
        if (card === Cards.Discount && locale === 'nl-NL') {
          return null
        }

        return (
          <CardSection
            activeCard={activeCard}
            key={card}
            onClick={() => handlePromotionClick(activeCard === card ? null : card)}
            topic={card}
          >
            <Card activeCard={activeCard} topic={card}>
              <CardImage src={getCardImage(card)} />
              <CardTitle>{getCardTitle(card)}</CardTitle>
              {showToggleArrow && <Icon24.ToggleArrow style={arrowStyles} />}
            </Card>
          </CardSection>
        )
      })}
    </CardContainer>
  )
}

export default PromotionCards
