import styled from 'styled-components'
import { Shadows } from 'builder/styles/shadows'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Plate = styled.div`
  position: absolute;
  z-index: 1;
  left: 40px;
  right: 40px;
  top: 48px;
  padding: 16px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  box-shadow: 0 16px 24px ${hexToRgba(Colors.Green50, 0.08)},
    inset 0 -1px 0 0 ${hexToRgba(Colors.Green70, 0.16)}, inset 0 1px 0 0 ${Colors.White};
  background-color: ${hexToRgba(Colors.Green20, 0.5)};
`

export const Photo = styled.img`
  ${Shadows.light.low};
  width: 40px;
  height: 40px;
  margin-bottom: 12px;
  border-radius: 50%;
  background-color: ${Colors.White};
`

export const TextContainer = styled.div`
  gap: 2px;
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
`

export const Text = styled.div`
  ${Typography.Tiny}
  ${FontWeights.Medium};
  color: ${Colors.Green80};
  text-align: center;
`

export const Gradient = styled.div`
  width: 100%;
  height: 117px;
  position: absolute;
  top: 46px;
  border-radius: 16px;
  background: linear-gradient(
    to bottom,
    ${hexToRgba(Colors.Neutral5, 0)},
    ${Colors.Neutral5} 100%,
    ${Colors.Neutral5} 0%
  );
`
