import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react'
import { SuggestionSelectedEventData } from 'react-autosuggest'
import { trackInternalEvent } from '@rio/tracking'
import { useFields } from 'builder/hooks/useFields'
import { TextField } from 'builder/components/TextField'
import { FieldNote } from 'builder/components/FieldNote'
import {
  ApiSection,
  AsyncAutosuggest,
  createNewSuggestionsApiFetcher,
} from 'builder/components/AsyncAutosuggest'
import { CareerPathQuestionnaireFields, CareerPathQuestionnaireFormProps } from '../../types'
import { BetaBanner } from '../BetaBanner'
import * as Styles from './styles'

const DEFAULT_COUNTRY = 'United States'
const TRACK_UNSELECTED_DELAY = 2000
const LABEL = {
  label: 'career_pathways',
}

const careerPathsURL = require('./assets/img/career-paths.png')

const emptyErrors = {
  occupation: '',
  location: '',
}

// TODO: translation
export const Form = ({
  fields: initialValues,
  onChange,
  onSubmit,
}: CareerPathQuestionnaireFormProps) => {
  const [errors, setErrors] =
    useState<{ [key in keyof CareerPathQuestionnaireFields]: string }>(emptyErrors)
  const [fields, handleUpdate] = useFields(initialValues)

  // Persist recently selected values to prevent extra "type_career_occupation" event calls
  const selectedValuesRef =
    useRef<{ [key in keyof CareerPathQuestionnaireFields]: string }>(initialValues)

  const handleSuggestionSelected =
    (field: keyof CareerPathQuestionnaireFields) =>
    (
      e: FormEvent<HTMLInputElement>,
      selected: SuggestionSelectedEventData<CareerPathQuestionnaireFields>,
    ) => {
      const value = selected.suggestionValue
      selectedValuesRef.current[field] = value
      const event = field === 'location' ? 'select_career_location' : 'select_career_job_title'
      trackInternalEvent(event, {
        [field]: value,
        ...LABEL,
      })

      handleUpdate({
        target: {
          name: field,
          value,
        },
      })

      onChange(field, value)
    }

  useEffect(() => {
    const interval = setTimeout(() => {
      if (fields.occupation !== selectedValuesRef.current.occupation) {
        trackInternalEvent('type_career_job_title', {
          occupation: fields.occupation,
          ...LABEL,
        })
      }
      if (fields.location !== selectedValuesRef.current.location) {
        trackInternalEvent('type_career_location', {
          location: fields.location,
          ...LABEL,
        })
      }
    }, TRACK_UNSELECTED_DELAY)

    return () => {
      clearTimeout(interval)
    }
  }, [fields])

  const validateFields = () => {
    let isValid = true
    const newErrors = Object.assign({}, errors)

    if (!fields.occupation) {
      newErrors.occupation = 'This field is required'
      isValid = false
    }

    if (fields.occupation !== selectedValuesRef.current.occupation) {
      newErrors.occupation = 'Please, try another occupation and select an option from the list'
      isValid = false
    }

    if (fields.location && fields.location !== selectedValuesRef.current.location) {
      newErrors.location = 'Please, try another location and select an option from the list'
      isValid = false
    }

    return { isValid, newErrors }
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    const { newErrors, isValid } = validateFields()

    setErrors(newErrors)

    if (isValid) onSubmit(e)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrors(emptyErrors)

    // Condition is need to cut off unnecessary events (e.g. onClick)
    if (e.type === 'change' && e?.target) {
      handleUpdate({ target: { name: e.target.name, value: e.target.value } })
      if (e.target.name === 'location') {
        onChange('location', e.target.value)
      }
    }
  }

  return (
    <Styles.Container>
      <Styles.Title>Provide your recent job title and desired location</Styles.Title>
      <Styles.FormContainer onSubmit={handleSubmit}>
        <BetaBanner />
        <Styles.Card>
          <Styles.Image src={careerPathsURL} />
        </Styles.Card>
        <Styles.FieldsContainer>
          <Styles.FieldContainer>
            <AsyncAutosuggest<CareerPathQuestionnaireFields>
              name="occupation"
              fetchItems={createNewSuggestionsApiFetcher('job-titles', {
                section: ApiSection.CareerPages,
              })}
              value={fields.occupation}
              onChange={handleChange}
              onSuggestionSelected={handleSuggestionSelected('occupation')}
            >
              {inputProps => (
                <TextField
                  {...inputProps}
                  placeholder="Your recent job title"
                  label="Recent job title"
                  error={errors.occupation}
                />
              )}
            </AsyncAutosuggest>
          </Styles.FieldContainer>
          <Styles.FieldContainer>
            <AsyncAutosuggest<CareerPathQuestionnaireFields>
              name="location"
              fetchItems={createNewSuggestionsApiFetcher('locations', {
                section: ApiSection.CareerPages,
                country: DEFAULT_COUNTRY,
              })}
              value={fields.location}
              onChange={handleChange}
              onSuggestionSelected={handleSuggestionSelected('location')}
            >
              {inputProps => (
                <TextField
                  {...inputProps}
                  label="Desired location (US only)"
                  placeholder="Your desired location"
                  error={errors.location}
                />
              )}
            </AsyncAutosuggest>
            <FieldNote description="This data helps determine the average salaries and count opening jobs" />
          </Styles.FieldContainer>
        </Styles.FieldsContainer>
        <Styles.ButtonContainer>
          <Styles.FormButton type="submit">Build My Paths</Styles.FormButton>
        </Styles.ButtonContainer>
      </Styles.FormContainer>
    </Styles.Container>
  )
}
