import { useCallback } from 'react'
import { useSearchParams, useLocation } from 'react-router-dom'

type Options = {
  replace?: boolean
}

export const useQueryParam = <T extends string>(
  name: string,
): [T | null, (newValue: T | null, options?: Options) => void] => {
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const value = searchParams.get(name) as T

  const setValue = useCallback(
    (newValue: T | null, options?: Options) => {
      const queryParams = new URLSearchParams(location.search)
      newValue ? queryParams.set(name, newValue) : queryParams.delete(name)
      setSearchParams(queryParams, options)
    },
    [location.search, name, setSearchParams],
  )

  return [value, setValue]
}
