import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import DocumentImagePreview from 'builder/components/DocumentImagePreview'
import Shadows from 'builder/styles/shadows'
import Icon24 from 'builder/components/Icon'

export const DocumentPopup = styled.div`
  position: absolute;
  right: 5%;
  top: 10%;
  width: 30%;
  min-width: 312px;
  max-width: 320px;
  border-radius: 8px;
  box-shadow: 0px 0px 1px rgb(15 56 113 / 32%), 0px 2px 4px -1px rgb(15 56 113 / 8%),
    0px 8px 20px -4px rgb(15 56 113 / 12%);

  ${Media.Tablet`
    left: 148px;
    top: 335px;
    width:320px;
  `}

  ${Media.Phone`
    left: 0px;
    top: 105%;
    z-index: 1;
    width: 100%;
    max-width: 400px;
  `}
`

export const NewDocumentContainer = styled.div`
  ${Typography.Tiny};
  height: 208px;
  background: ${Colors.White};
  border-radius: 8px;

  & > :first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  & > :last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  ${Media.Phone`
    width: 100%;
  `}
`

export const DocumentOptionsContainer = styled.div`
  ${Typography.Tiny};
  height: 104px;
  min-width: 312px;
  border: 1px solid ${Colors.Neutral15};
  cursor: pointer;
  ${Media.Phone`
    width: 100%;
  `}
`

export const DocumentOptions = styled.div`
  width: 98%;
  height: 96px;
  margin-top: 3.5px;
  margin-left: 3.8px;
  border-radius: 4px;
  display: flex;
  ${DocumentOptionsContainer}:hover & {
    background-color: ${Colors.Blue10};
  }
`

export const DocumentOptionsGradient = styled.div`
  position: relative;
  width: 98%;
  height: 24px;
  display: flex;
  z-index: 10;
  margin-left: 3.8px;
  top: -24px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  ${DocumentOptionsContainer}:hover & {
    background: linear-gradient(180deg, rgba(234, 246, 255, 0) 0%, ${Colors.Blue10} 100%);
  }
`

export const OptionPreview = styled(DocumentImagePreview)`
  width: 80px;
  margin: 8px 0px 0px 8px;
  border-radius: 2px;
  border: 1px solid ${Colors.Neutral15};
  border-bottom: none;
  background: linear-gradient(180deg, #f7f9fc 0%, ${Colors.White} 100%);
  border-image: linear-gradient(to bottom, ${Colors.Neutral15} 60%, ${Colors.White} 100%);
  border-image-slice: 1;
`

export const OptionRight = styled.div`
  margin: 12px 0 0 16px;
`

export const OptionTitle = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral90};
  height: 24px;
  width: 192px;

  ${DocumentOptionsContainer}:hover & {
    color: ${Colors.Blue50};
  }
`
export const OptionContent = styled.div`
  ${Typography.Tiny};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
  width: 192px;
  height: 32px;
`

export const SectionWrapper = styled.div`
  margin-bottom: 12px;
`

export const DocumentScore = styled.div<{ isAiRefresher: boolean }>`
  height: 36px;
  width: fit-content;
  display: flex;
  background: ${Colors.Neutral5};
  border-radius: 6px;
  margin-bottom: 20px;
  &:hover {
    background: ${Colors.Blue10};
  }
`
export const EmploymentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Title = styled.div`
  ${Typography.Caption};
  ${FontWeights.Regular};
  padding: 8px;
  max-width: 236px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${Media.Phone`
    max-width: 123px;
  `}
`

export const EmploymentImage = styled.img`
  background: ${Colors.White};
  margin: 8px 0px 8px 10px;
  ${Shadows.lightWithBorder.low};
  border-radius: 4px;
`

export const Percentile = styled.div<{ score: number; isFirstDocumentFree: boolean }>`
  ${Typography.Tiny};
  ${FontWeights.DemiBold};
  color: ${props => (props.isFirstDocumentFree ? Colors.Indigo60 : Colors.White)};
  background: green;
  border-radius: 4px;
  text-align: center;
  height: 20px;
  margin: 8px 0px 8px 8px;
  padding: 2px 4.5px;
  white-space: nowrap;
  background: ${props =>
    props.isFirstDocumentFree
      ? Colors.Indigo20
      : props.score <= 30
      ? Colors.Red40
      : props.score > 30 && props.score <= 70
      ? Colors.Amber40
      : Colors.Green40};
  &:after {
    content: '%';
  }
`

export const LockIcon = styled(Icon24.Lock)`
  margin: -6px -4px -4px;
`
