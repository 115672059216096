import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Title = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};
`

export const Content = styled.div`
  color: ${Colors.Neutral50};
  margin-bottom: 20px;
`

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
`

export const Warning = styled.div`
  color: ${Colors.Neutral50};
  display: flex;
  ${Typography.Caption};
`

export const NoteTitle = styled.div`
  ${FontWeights.Bold};
  margin-right: 3px;
`

export const NoteContent = styled.div`
  text-align-last: center;
  ${Typography.Caption};
`
