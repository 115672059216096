import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useNavigate } from 'react-router-dom'
import ModalOverlay from 'builder/components/ModalOverlay'
import { Icon20 } from 'builder/components/Icon'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { apiClient } from 'builder/modules/apiClient'
import playImageButton from '../../assets/welcome_modal_play_Buttons.svg'
import { EDashboardLocalStoreKeys } from '../../types'
import * as Styled from './styles'
import { SERVICES, PROMO_SERVICES, PROMO_MOBILE_SERVICES } from './constants'
import WelcomeVideoOverlay from './WelcomeVideoOverlay'

interface IWelcomeModalProps {
  setIsWelcomeModalOpen: Dispatch<SetStateAction<boolean>>
  isWelcomeModalOpen: boolean
  getHost: string
  isCioRedirectOpen: boolean
  title?: string
  subtitle?: string
  isPVRedirect?: boolean
}

interface IService {
  text: string
  id: number
  subText?: string
}

export const WelcomeModal: FC<IWelcomeModalProps> = ({
  setIsWelcomeModalOpen,
  isWelcomeModalOpen,
  getHost,
  title,
  subtitle,
  isCioRedirectOpen,
  isPVRedirect,
}: IWelcomeModalProps) => {
  const { isPhone } = useMediaQueries()
  const navigate = useNavigate()
  const [services, setServices] = useState<IService[]>(
    isCioRedirectOpen ? PROMO_SERVICES : SERVICES,
  )
  const [isShowVideo, setIsShowVideo] = useState(false)

  useEffect(() => {
    let servicesToDisplay = []
    if (isCioRedirectOpen) {
      servicesToDisplay = isPhone ? PROMO_MOBILE_SERVICES : PROMO_SERVICES
    } else {
      servicesToDisplay = isPhone ? SERVICES.slice(0, 4) : SERVICES
    }
    setServices(servicesToDisplay)
  }, [isPhone, isCioRedirectOpen])

  useEffect(() => {
    if (isWelcomeModalOpen && !isCioRedirectOpen) {
      localStorage.setItem(EDashboardLocalStoreKeys.IS_WELCOME_MODAL_SHOWN, 'true')
    }
  }, [isWelcomeModalOpen, isCioRedirectOpen])

  const handleClose = async () => {
    setIsWelcomeModalOpen(false)
    if (isCioRedirectOpen) navigate('/')
  }

  const handleRedirect = async () => {
    setIsWelcomeModalOpen(false)
    trackInternalEvent('click_cio_activation_button')
    if (isCioRedirectOpen) {
      const { data } = await apiClient.post('/career-identity/activate', {})
      window.open(data.loginLink, '_self')
    }
  }

  const onVideoPlay = () => {
    if (!isShowVideo) {
      trackInternalEvent('click_play_video', {
        label: 'welcome_modal',
        website_host: getHost,
      })
    }
    setIsShowVideo(!isShowVideo)
  }

  if (!isWelcomeModalOpen) return null

  return (
    <>
      <ModalOverlay
        onClick={handleClose}
        backgroundOpacity={0.6}
        overlayFadeDuration={350}
        contentSlideDuration={350}
      >
        <Styled.Container>
          {!isCioRedirectOpen && !isPVRedirect ? (
            <Styled.ImageContainer onClick={onVideoPlay}>
              <Styled.PlayImage src={playImageButton} />
            </Styled.ImageContainer>
          ) : (
            <Styled.PromoImageContainer />
          )}
          <Styled.Close onClick={handleClose}>
            <Icon20.Close />
          </Styled.Close>
          <Styled.DescriptionSection>
            <Styled.Description>
              <Styled.Header>
                <Styled.Title>
                  {title ||
                    (isCioRedirectOpen
                      ? `Your resume has been downloaded! But that's just the beginning.`
                      : `Welcome to the world's first complete job search and career platform!`)}
                </Styled.Title>
              </Styled.Header>
              <Styled.SubTitle>
                {subtitle ||
                  (isCioRedirectOpen
                    ? 'See how our powerful tools help you achieve your job seeking goals.'
                    : 'There is everything you need to manage each step in your career all in one place.')}
              </Styled.SubTitle>
            </Styled.Description>
            <Styled.ServicesSection>
              <Styled.ServicesSectionHeading>Whatever you need:</Styled.ServicesSectionHeading>
              <Styled.ServicesContainer>
                {services.map((service: IService) => (
                  <Styled.ServiceItem isPhone={isPhone} key={service.id}>
                    <Styled.GreenTick />
                    <Styled.Service>{service.text}</Styled.Service>
                    {service.subText && (
                      <Styled.ServiceSubText>{service.subText}</Styled.ServiceSubText>
                    )}
                  </Styled.ServiceItem>
                ))}
                {isPhone && !isCioRedirectOpen && (
                  <Styled.ServiceItem isPhone={isPhone}>
                    <Styled.GreenTick />
                    <Styled.Service>4 more services</Styled.Service>
                  </Styled.ServiceItem>
                )}
              </Styled.ServicesContainer>
            </Styled.ServicesSection>
            <Styled.ButtonContainer>
              <Styled.StartButton onClick={isCioRedirectOpen ? handleRedirect : handleClose}>
                {isCioRedirectOpen ? 'Try Now' : 'Get Started'}
              </Styled.StartButton>
            </Styled.ButtonContainer>
          </Styled.DescriptionSection>
        </Styled.Container>
      </ModalOverlay>
      {!isCioRedirectOpen && (
        <WelcomeVideoOverlay isShowVideo={isShowVideo} setIsShowVideo={onVideoPlay} />
      )}
    </>
  )
}
