import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { trackInternalEvent } from '@rio/tracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

import {
  selectors as renderingSelectors,
  actions as renderingActions,
} from 'builder/modules/rendering'

const MODAL_SHOWN_STORAGE_KEY = 'rd_upsell_shown'

export const useResumeDistributionUpsellModal = () => {
  const dispatch = useDispatch()
  const rdUpsellModalVisible = useTypedSelector(renderingSelectors.rdUpsellModalVisible)

  const shouldModalOpen = useCallback(() => {
    return rdUpsellModalVisible
  }, [rdUpsellModalVisible])

  const [isOpen, setIsOpen] = useState(shouldModalOpen)

  function setRDUpsellShown() {
    localStorage.setItem(MODAL_SHOWN_STORAGE_KEY, 'true')
  }

  useEffect(() => {
    if (shouldModalOpen()) {
      trackInternalEvent('show_upsell_plans_page')
    }
    setIsOpen(shouldModalOpen())
  }, [rdUpsellModalVisible, shouldModalOpen])

  function handleModalClose() {
    dispatch(renderingActions.closeRDUpsellModal())
    setRDUpsellShown()
  }

  return {
    isOpen,
    handleModalClose,
  }
}
