import { SectionNames } from 'builder/modules/resumeEditor'

interface ModalData {
  title: string
  subtitle: string
  cta: string
  tips: string[]
}

// TODO: i18n
export const MODALS: Record<string, ModalData> = {
  [SectionNames.workExperiences]: {
    title: 'Writing the work experience section',
    subtitle: `This is your professional story told in dates, roles & bullet points.
      It’s the core of most resumes.`,
    cta: 'Got it! Let’s Get Started!',
    tips: [
      `Use bullet points describing your achievements/milestones below each job
      (Achieved X, measured by Y, by doing Z).`,
      'Use verbs that describe actions, cut out needless filler words.',
      'Describe jobs from most recent to oldest, going back no further than 10 years.',
    ],
  },
  [SectionNames.profile]: {
    title: 'Writing the personal summary',
    subtitle: `The most important facts told in 2-4 short sentences,
      inviting the reader to find out more below.`,
    cta: 'Ok! Let’s Go!',
    tips: [
      `The opening states your role, usually noting the scope of your experience
      and/or your biggest achievement.`,
      `The second priority is providing 1-3 more examples of your important accomplishments
      or qualifications.`,
      `The third priority is describing your work ethic, additional skills or qualities.`,
    ],
  },
}
