import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { Icon24 } from 'builder/components/Icon'
import Media from 'builder/styles/media'
import checkmark from './assets/checkmark.svg'

export const Overlay = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background: #000000ba;
  z-index: 9999;
  height: 100vh;
  width: 100vw;
`

export const RootModalContainer = styled.div`
  font-family: 'TT Commons', system-ui, -apple-system, 'Segoe UI', Roboto, Ubuntu, Cantarell,
    'Noto Sans', sans-serif;
  background-color: ${Colors.White};
  border-radius: 8px;
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 974px;
  max-height: 547px;

  ${Media.Phone`
    border-radius: 0;
    flex-direction: column-reverse;
    max-width: 100%;
    max-height: 100%;
  `}
`

export const ModalSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  height: 100%;
  padding: 40px;

  ${Media.Phone`
    width: 100%;
    padding: 24px;
  `}
`

export const ModalImageSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  border-radius: 0 8px 8px 0;
  align-items: center;
  width: 50%;
  height: 100%;
  background-color: #f3f4f8;

  ${Media.Phone`
    border-radius: 0;
    width: 100%;
    max-height: 195px;
  `}
`

export const CloseIcon = styled(Icon24.Close)`
  display: block;
  color: ${Colors.Neutral50};
  transition: color 0.3s;
  &:hover {
    color: ${Colors.Blue50};
  }
`

export const Close = styled.div`
  display: flex;
  justify-self: flex-end;
  right: 0;
  height: fit-content;
  width: fit-content;
  margin: 32px 32px 0 32px;
  background: ${Colors.White};
  border-radius: 32px;
  color: ${Colors.Neutral30};
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${Colors.Blue10};
  }
  ${Media.Phone`
    margin: 20px;
  `}
`

export const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  ${Media.Phone`
    position: absolute;
    top: 0;
    right: 0;
  `}
`

export const PromoHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;

  ${Media.Phone`
    gap: 8px;
  `}
`

export const PromoTitle = styled.h2`
  font-size: 33px;
  font-weight: 600;
  color: ${Colors.Neutral90};
  line-height: 36px;

  ${Media.Phone`
    font-size: 23px;
    line-height: 24px;
  `}
`

export const PromoDescription = styled.p`
  color: ${Colors.Neutral50};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

export const PromoFeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
`

export const PromoFeatureTitle = styled.h3`
  color: #303848;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 1.8px;
  text-transform: uppercase;

  ${Media.Phone`
    font-size: 12px;
    line-height: 16px;
  `}
`

export const PromoFeatures = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  list-style: none;
  padding: 0;
  margin: 0;

  ${Media.Phone`
    gap: 0;
  `}
`

export const PromoFeature = styled.li`
  color: ${Colors.Neutral80};
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  font-size: 16px;

  &:before {
    content: '';
    display: inline-block;
    position: relative;
    right: 4px;
    top: 5px;
    width: 20px;
    height: 20px;
    background-image: url(${checkmark});
    background-size: contain;
    background-repeat: no-repeat;
  }

  ${Media.Phone`
    font-size: 15px;
    line-height: 18px;
  `}
`

export const PromoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const CTAContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
`

export const CTAPrimary = styled.button`
  border-radius: 4px;
  border: none;
  background: ${Colors.Blue50};
  color: ${Colors.White};
  text-align: center;
  font-family: 'TT Commons', system-ui, -apple-system, 'Segoe UI', Roboto, Ubuntu, Cantarell,
    'Noto Sans', sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  display: inline-flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background: ${Colors.Blue60};
  }
  &:active {
    background: ${Colors.Blue70};
  }
  &:disabled {
    color: ${Colors.Neutral50};
    background-color: transparent;
    text-align: center;
    font-family: 'TT Commons', system-ui, -apple-system, 'Segoe UI', Roboto, Ubuntu, Cantarell,
      'Noto Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
  }
  ${Media.Phone`
    font-size: 16px;
    padding: 8px 12px;
    white-space: nowrap;
        line-height: 20px;
  `}
`

export const CTAOutline = styled.button`
  border-radius: 4px;
  border: 1px solid ${Colors.Neutral20};
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  display: inline-flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    border: 1px solid ${Colors.Blue50};
    color: ${Colors.Blue50};
  }
  &:active {
    background: ${Colors.Blue10};
  }
  &:disabled {
    color: ${Colors.Neutral50};
    background-color: transparent;
    text-align: center;
    font-family: 'TT Commons', system-ui, -apple-system, 'Segoe UI', Roboto, Ubuntu, Cantarell,
      'Noto Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
  }
  ${Media.Phone`
    font-size: 16px;
    padding: 8px 12px;
    white-space: nowrap;
    line-height: 20px;
  `}
`

export const PlanRenewalNoticeContainer = styled.div`
  display: flex;
  color: ${Colors.Neutral50};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.3px;
`
export const ModalSectionBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const Video = styled.video`
  width: 100%;
  object-fit: contain;

  ${Media.Phone`
    max-height: 195px;
    object-fit: cover;
    object-position: top;
  `}
`
