import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import DocumentImagePreview from 'builder/components/DocumentImagePreview'
import Media from 'builder/styles/media'
import Icon24 from 'builder/components/Icon'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding: 14px 16px;
  color: ${Colors.White};
  gap: 16px;
`

export const HeaderIcon = styled(Icon24.Completeness0Percent)``

export const HeaderText = styled.div`
  ${Typography.Caption};
  ${FontWeights.Regular};
  display: flex;
  align-items: center;
`

export const ResumePreview = styled(DocumentImagePreview)`
  width: 320px;
  margin-top: 12px;
  border-radius: 2px;
  margin-left: 16px;
  background: linear-gradient(180deg, #f7f9fc 0%, ${Colors.White} 100%);
  border-image: linear-gradient(to bottom, ${Colors.Neutral15} 60%, ${Colors.White} 100%);
  border-image-slice: 1;

  ${Media.Phone`
    width: 246px;
    margin-top: 9px;
  `}
`

export const Progress = styled.div`
  height: 3px;
  background-color: ${Colors.Neutral80};
  margin: 6px 16px 6px 16px;
`
