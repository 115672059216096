import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Button } from 'builder/components/Button'

export const BannerSection = styled.div<{
  isChromeExtensionInstalled: boolean
  isSidebar: boolean
  componentType: string
}>`
  width: 100%;
  border-radius: 12px;
  background-color: ${({ isChromeExtensionInstalled }) =>
    isChromeExtensionInstalled ? Colors.Green10 : Colors.Blue10};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ isSidebar }) => (isSidebar ? '16px' : '')};
  cursor: ${({ isSidebar }) => (isSidebar ? 'pointer' : '')};
  margin-bottom: ${({ componentType }) =>
    componentType === 'job_tracker_dashboard' ? '16px' : ''};

  &:hover {
    background-color: ${({ isSidebar, isChromeExtensionInstalled }) =>
      isSidebar && !isChromeExtensionInstalled ? Colors.Blue20 : ''};
  }
`

export const LeftSection = styled.div`
  display: flex;
  margin: 18px 20px;
  align-items: center;
`

export const DetailsSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`

export const Subtitle = styled.p`
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${Colors.Blue80};
`

export const Title = styled.p<{ isChromeExtensionInstalled: boolean }>`
  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${({ isChromeExtensionInstalled }) =>
    isChromeExtensionInstalled ? Colors.Green50 : Colors.Blue80};
`

export const Icon = styled.img`
  width: 32px;
  height: 32px;
`

export const ChromeButton = styled(Button)`
  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
  background-color: ${Colors.Blue10};
  border: 1px solid ${Colors.Blue30};
  height: 36px;
  margin: 16px 24px;

  &:hover {
    color: ${Colors.Blue50};
    background-color: ${Colors.Blue10};
    border: 1px solid ${Colors.Blue50};
  }
`
