import { useEffect } from 'react'
import { Optional } from 'packages/types'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors, actions } from 'builder/modules/panel'
import { FetchStatuses } from 'builder/modules/constants'

export const useNotApprovedAutoTailoredResume = (originalResumeId: Optional<number>) => {
  const dispatch = useDispatch()
  const status = useTypedSelector(selectors.documentsStatus)

  const notApprovedResume = useTypedSelector(store =>
    selectors.notApprovedAutoTailoredResume(store, originalResumeId),
  )

  useEffect(() => {
    if (status === FetchStatuses.notAsked) {
      dispatch(actions.fetchDocuments())
    }
  }, [status, dispatch])

  return notApprovedResume
}
