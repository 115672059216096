import { createAction, createReducer } from '@reduxjs/toolkit'
import { Store } from 'builder/modules/store'
import { FetchStatuses } from '../constants'
import {
  CareerLevels,
  CareerCoachingStore,
  FilterCoachesParams,
  Coaches,
  ServicesOffered,
  Industry,
  CoachDetailsParams,
  CoachDetails,
  CoachingPlans,
  CoachingPlansParams,
  CoachConfirmationParams,
  CoachIntakeTestDetailsProps,
  CreateIntakeTestProps,
  UpdateIntakeTestProps,
  CoachingSessionsProps,
  RedirectRoutesParams,
  SuggestedIndustry,
} from './types'
import { CAREERCOACHESPARAMS } from './constants'
// ---
// Action creators
// ---
export const actions = {
  setCareerLevels: createAction<CareerLevels[]>('builder/CAREER_COACHING_SET_CAREER_LEVELS'),
  fetchCareerLevels: createAction('builder/CAREER_COACHING_FETCH_CAREER_LEVELS'),
  updateCareerLevels: createAction<CareerLevels[]>('builder/CAREER_COACHING_UPDATE_CAREER_LEVELS'),
  filterCoaches: createAction<FilterCoachesParams>('builder/CAREER_COACHING_FILTER_COACHES'),
  setCoaches: createAction<Coaches[]>('builder/CAREER_COACHING_SET_COACHES'),
  fetchServicesOffered: createAction('builder/CAREER_COACHING_FETCH_SERVICES_OFFERED'),
  setServicesOffered: createAction<ServicesOffered[]>(
    'builder/CAREER_COACHING_SET_SERVICES_OFFERED',
  ),
  updateServicesOffered: createAction<ServicesOffered[]>(
    'builder/CAREER_COACHING_UPDATE_SERVICES_OFFERED',
  ),
  fetchIndustries: createAction('builder/CAREER_COACHING_FETCH_INDUSTRIES'),
  setIndustries: createAction<Industry[]>('builder/CAREER_COACHING_SET_INDUSTRIES'),
  updateIndustries: createAction<Industry[]>('builder/CAREER_COACHING_UPDATE_INDUSTRIES'),
  updateParams: createAction<FilterCoachesParams>('builder/CAREER_COACHING_UPDATE_PARAMS'),
  fetchCoachDetails: createAction<CoachDetailsParams>(
    'builder/CAREER_COACHING_FETCH_COACH_DETAILS',
  ),
  fetchCoachingPlans: createAction<CoachingPlansParams>(
    'builder/CAREER_COACHING_FETCH_PAYMENT_PLANS',
  ),
  fetchCoachConfirmation: createAction<CoachConfirmationParams>(
    'builder/CAREER_COACHING_FETCH_COACH_CONFIRMATION',
  ),
  setCoachDetails: createAction<CoachDetails>('builder/CAREER_COACHING_SET_COACH_DETAILS'),
  setCoachingPlans: createAction<CoachingPlans>('builder/CAREER_COACHING_SET_PAYMENT_PLANS'),
  setCoachConfirmation: createAction<CoachConfirmationParams>(
    'builder/CAREER_COACHING_SET_COACH_CONFIRMATION',
  ),
  setModalState: createAction<boolean>('builder/CAREER_COACHING_SET_MODAL_STATE'),
  setSelectedService: createAction<string>('builder/CAREER_COACHING_SET_SELECTED_SERVICE'),
  setSelectedDuration: createAction<number>('builder/CAREER_COACHING_SET_SELECTED_DURATION'),
  setSelectedDurationPrice: createAction<string>(
    'builder/CAREER_COACHING_SET_SELECTED_DURATION_PRICE',
  ),
  setOpenBookServiceModal: createAction<boolean>(
    'builder/CAREER_COACHING_SET_OPEN_BOOK_SERVICE_MODAL',
  ),
  fetchCoachBookingSlot: createAction('builder/CAREER_COACHING_FETCH_COACH_BOOKING_SLOT'),
  setCoachBookingSlot: createAction<string>('builder/CAREER_COACHING_SET_COACH_BOOKING_SLOT'),
  fetchSuggestedIndustries: createAction('builder/CAREER_COACHING_FETCH_SUGGESTED_INDUSTRIES'),
  setFetchCoachesStatus: createAction<FetchStatuses>(
    'builder/CAREER_COACHING_SET_FETCH_COACHES_STATUS',
  ),
  fetchIntakeTestDetails: createAction('builder/CAREER_COACHING_FETCH_INTAKE_TEST_DETAILS'),
  setIntakeTestDetails: createAction<CoachIntakeTestDetailsProps>(
    'builder/CAREER_COACHING_SET_INTAKE_TEST_DETAILS',
  ),
  createIntakeTestDetails: createAction<CreateIntakeTestProps>(
    'builder/CAREER_COACHING_CREATE_INTAKE_TEST_DETAILS',
  ),
  setIntakeTestStatus: createAction<FetchStatuses>(
    'builder/CAREER_COACHING_SET_INTAKE_TEST_STATUS',
  ),
  updateIntakeTestDetails: createAction<UpdateIntakeTestProps>(
    'builder/CAREER_COACHING_UPDATE_INTAKE_TEST_DETAILS',
  ),
  setFetchCoachingPlansStatus: createAction<FetchStatuses>(
    'builder/CAREER_COACHING_SET_FETCH_COACHING_PLANS_STATUS',
  ),
  setAssignCoachStatus: createAction<FetchStatuses>(
    'builder/CAREER_COACHING_SET_ASSIGN_COACH_STATUS',
  ),
  fetchCoachSessions: createAction<{ participantId: number | undefined }>(
    'builder/CAREER_COACHING_FETCH_COACH_SESSIONS',
  ),
  setCoachSessions: createAction<CoachingSessionsProps>(
    'builder/CAREER_COACHING_SET_COACH_SESSIONS',
  ),
  setCoachSessionsStatus: createAction<FetchStatuses>(
    'builder/CAREER_COACHING_SET_FETCH_SET_COACH_SESSIONS_STATUS',
  ),
  fetchRedirectRoutes: createAction<RedirectRoutesParams>(
    'builder/CAREER_COACHING_FETCH_REDIRECT_ROUTES',
  ),
  setUserRedirectState: createAction<boolean>('builder/CAREER_COACHING_SET_USER_REDIRECT_STATE'),
  fetchIntakeTestStatus: createAction<FetchStatuses>(
    'builder/CAREER_COACHING_FETCH_INTAKE_TEST_STATUS',
  ),
  setSuggestedIndustries: createAction<SuggestedIndustry[]>(
    'builder/CAREER_COACHING_SET_SUGGESTED_INDUSTRIES',
  ),
  setTargetedIndustries: createAction<SuggestedIndustry[]>(
    'builder/CAREER_COACHING_SET_TARGETED_INDUSTRIES',
  ),
}

// ---
// Selectors
// ---
export const selectors = {
  careerLevels: (state: Store) => state.careerCoaching.careerLevels,
  coaches: (state: Store) => state.careerCoaching.coaches,
  servicesOffered: (state: Store) => state.careerCoaching.servicesOffered,
  industries: (state: Store) => state.careerCoaching.industries,
  params: (state: Store) => state.careerCoaching.params,
  coachDetails: (state: Store) => state.careerCoaching.coachDetails,
  coachConfirmation: (state: Store) => state.careerCoaching.coachConfirmation,
  coachingPlans: (state: Store) => state.careerCoaching.coachingPlans,
  isModalOpen: (state: Store) => state.careerCoaching.isModalOpen,
  selectedService: (state: Store) => state.careerCoaching.selectedService,
  selectedDuration: (state: Store) => state.careerCoaching.selectedDuration,
  selectedDurationPrice: (state: Store) => state.careerCoaching.selectedDurationPrice,
  openBookServiceModal: (state: Store) => state.careerCoaching.openBookServiceModal,
  coachBookingSlot: (state: Store) => state.careerCoaching.coachBookingSlot,
  isCoachesLoading: (state: Store) =>
    state.careerCoaching.fetchCoachesStatus === FetchStatuses.loading,
  isCoachesLoaded: (state: Store) =>
    state.careerCoaching.fetchCoachesStatus === FetchStatuses.loaded,
  isCoachesFailed: (state: Store) =>
    state.careerCoaching.fetchCoachesStatus === FetchStatuses.failed,
  intakeTestDetails: (state: Store) => state.careerCoaching.intakeTestDetails,
  isIntakeTestLoading: (state: Store) =>
    state.careerCoaching.intakeTestStatus === FetchStatuses.loading,
  isIntakeTestLoaded: (state: Store) =>
    state.careerCoaching.intakeTestStatus === FetchStatuses.loaded,
  isIntakeTestFailed: (state: Store) =>
    state.careerCoaching.intakeTestStatus === FetchStatuses.failed,

  isCoachingPlansLoading: (state: Store) =>
    state.careerCoaching.fetchCoachingPlansStatus === FetchStatuses.loading,
  isCoachingPlansLoaded: (state: Store) =>
    state.careerCoaching.fetchCoachingPlansStatus === FetchStatuses.loaded,
  isCoachingPlansFailed: (state: Store) =>
    state.careerCoaching.fetchCoachingPlansStatus === FetchStatuses.failed,

  isCoachAssignLoading: (state: Store) =>
    state.careerCoaching.assignCoachStatus === FetchStatuses.loading,
  isCoachAssignLoaded: (state: Store) =>
    state.careerCoaching.assignCoachStatus === FetchStatuses.loaded,
  isCoachAssignFailed: (state: Store) =>
    state.careerCoaching.assignCoachStatus === FetchStatuses.failed,
  coachingSessions: (state: Store) => state.careerCoaching.coachingSessions,

  isCoachSessionsLoading: (state: Store) =>
    state.careerCoaching.coachingSessionsStatus === FetchStatuses.loading,
  isCoachSessionsLoaded: (state: Store) =>
    state.careerCoaching.coachingSessionsStatus === FetchStatuses.loaded,
  isCoachSessionsFailed: (state: Store) =>
    state.careerCoaching.coachingSessionsStatus === FetchStatuses.failed,

  isFetchIntakeTestLoading: (state: Store) =>
    state.careerCoaching.showIntakeTestStatus === FetchStatuses.loading,
  isFetchIntakeTestLoaded: (state: Store) =>
    state.careerCoaching.showIntakeTestStatus === FetchStatuses.loaded,
  isFetchIntakeTestFailed: (state: Store) =>
    state.careerCoaching.showIntakeTestStatus === FetchStatuses.failed,

  isUserRedirectTo: (state: Store) => state.careerCoaching.isUserRedirectTo,
  suggestedIndustries: (state: Store) => state.careerCoaching.suggestedIndustries,
  targetedIndustries: (state: Store) => state.careerCoaching.targetedIndustries,
}

// ---
// Reducer
// ---
const initialState: CareerCoachingStore = {
  fetchCoachesStatus: FetchStatuses.notAsked,
  isModalOpen: true,
  careerLevels: [],
  coaches: [],
  servicesOffered: [],
  industries: [],
  params: { ...CAREERCOACHESPARAMS },
  coachDetails: {
    id: null,
    coachAvatar: '',
    firstName: '',
    lastName: '',
    aboutMe: '',
    location: '',
    status: '',
    careerStages: [],
    services: [],
    industries: [],
    jobFunctions: [],
  },
  coachConfirmation: {
    id: null,
    coachAvatar: '',
    firstName: '',
    lastName: '',
    description: '',
    location: '',
    status: '',
    levels: [],
    services: [],
    industries: [],
    coachJobFunctions: [],
  },
  coachingPlans: [],
  selectedService: '',
  selectedDuration: null,
  selectedDurationPrice: null,
  openBookServiceModal: false,
  coachBookingSlot: '',
  intakeTestDetails: {
    value: {
      location: '',
      services: [],
      timezone: '',
      industries: [],
      focusTopic: '',
      careerStages: '',
      jobFunctions: [],
      availableDays: [],
      availableTimes: [],
    },
  },
  intakeTestStatus: FetchStatuses.notAsked,
  showIntakeTestStatus: FetchStatuses.loading,
  fetchCoachingPlansStatus: FetchStatuses.notAsked,
  assignCoachStatus: FetchStatuses.notAsked,
  coachingSessionsStatus: FetchStatuses.loading,
  coachingSessions: {
    status: null,
    response: {
      participant_status: '',
      status_message: '',
      coach_details: {
        coach_id: '',
        name: '',
        email: '',
        coach_availability: false,
        avatar: '',
      },
      package_details: {
        total_sessions: '',
        remaining_sessions: 0,
        package_name: '',
        expiration_date: '',
      },
      upcoming: [],
      previous: [],
    },
  },
  isUserRedirectTo: true,
  suggestedIndustries: [],
  targetedIndustries: [],
}

export const reducer = createReducer(initialState, reducer => {
  reducer.addCase(actions.setCareerLevels, (draft, action) => {
    // Add checked property
    draft.careerLevels = action.payload.map(careerLevel => {
      if (careerLevel.name === 'Junior') {
        return { ...careerLevel, checked: true }
      }
      return { ...careerLevel, checked: false }
    })
  })
  reducer.addCase(actions.updateCareerLevels, (draft, action) => {
    draft.careerLevels = action.payload
  })
  reducer.addCase(actions.setCoaches, (draft, action) => {
    draft.coaches = action.payload.map(coach => {
      return { ...coach, fullName: `${coach.firstName} ${coach.lastName}` }
    })
  })
  reducer.addCase(actions.setServicesOffered, (draft, action) => {
    // Add checked property
    draft.servicesOffered = action.payload.map(serviceOffered => {
      return { ...serviceOffered, checked: false }
    })
  })
  reducer.addCase(actions.updateServicesOffered, (draft, action) => {
    draft.servicesOffered = action.payload
  })
  reducer.addCase(actions.setIndustries, (draft, action) => {
    // Add checked property
    draft.industries = action.payload.map(industry => {
      return { ...industry, checked: false }
    })
  })
  reducer.addCase(actions.updateParams, (draft, action) => {
    draft.params = action.payload
  })
  reducer.addCase(actions.updateIndustries, (draft, action) => {
    draft.industries = action.payload
  })
  reducer.addCase(actions.setCoachDetails, (draft, action) => {
    draft.coachDetails = action.payload
  })
  reducer.addCase(actions.setCoachingPlans, (draft, action) => {
    draft.coachingPlans = action.payload
  })
  reducer.addCase(actions.setModalState, (draft, action) => {
    draft.isModalOpen = action.payload
  })
  reducer.addCase(actions.setSelectedService, (draft, action) => {
    draft.selectedService = action.payload
  })
  reducer.addCase(actions.setSelectedDuration, (draft, action) => {
    draft.selectedDuration = action.payload
  })
  reducer.addCase(actions.setSelectedDurationPrice, (draft, action) => {
    draft.selectedDurationPrice = action.payload
  })
  reducer.addCase(actions.setOpenBookServiceModal, (draft, action) => {
    draft.openBookServiceModal = action.payload
  })
  reducer.addCase(actions.setCoachBookingSlot, (draft, action) => {
    draft.coachBookingSlot = action.payload
  })
  reducer.addCase(actions.setFetchCoachesStatus, (draft, action) => {
    draft.fetchCoachesStatus = action.payload
  })
  reducer.addCase(actions.setIntakeTestDetails, (draft, action) => {
    draft.intakeTestDetails = action.payload
  })
  reducer.addCase(actions.setIntakeTestStatus, (draft, action) => {
    draft.intakeTestStatus = action.payload
  })
  reducer.addCase(actions.setFetchCoachingPlansStatus, (draft, action) => {
    draft.fetchCoachingPlansStatus = action.payload
  })
  reducer.addCase(actions.setAssignCoachStatus, (draft, action) => {
    draft.assignCoachStatus = action.payload
  })
  reducer.addCase(actions.setCoachSessions, (draft, action) => {
    draft.coachingSessions = action.payload
  })
  reducer.addCase(actions.setCoachSessionsStatus, (draft, action) => {
    draft.coachingSessionsStatus = action.payload
  })
  reducer.addCase(actions.setUserRedirectState, (draft, action) => {
    draft.isUserRedirectTo = action.payload
  })
  reducer.addCase(actions.fetchIntakeTestStatus, (draft, action) => {
    draft.showIntakeTestStatus = action.payload
  })
  reducer.addCase(actions.setSuggestedIndustries, (draft, action) => {
    draft.suggestedIndustries = action.payload
  })
  reducer.addCase(actions.setTargetedIndustries, (draft, action) => {
    draft.targetedIndustries = action.payload
  })
})
