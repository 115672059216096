import { memo } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from 'builder/modules/resumeEditor'
import { i18n as I18n } from 'builder/utils/i18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { TextAreaContainer } from '../styles'

const TextInputTab = () => {
  const dispatch = useDispatch()
  const {
    step,
    userInput: { pastAccomplishments, futureGoals },
  } = useTypedSelector(state => state.resumeEditor.aiResume.questionnaireData)
  const isFutureGoalsStep = step === 6
  const textAreaValue = isFutureGoalsStep ? futureGoals : pastAccomplishments

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    dispatch(
      isFutureGoalsStep
        ? actions.setFutureGoalsText(e.target.value)
        : actions.setPastAccomplishmentsText(e.target.value),
    )

  return (
    <TextAreaContainer
      value={textAreaValue}
      rows={6}
      placeholder={I18n.t('builder.ai_resume_draft.text_voice_input.text_placeholder')}
      onChange={handleOnChange}
    />
  )
}

export default memo(TextInputTab)
