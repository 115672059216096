import Colors from 'builder/styles/colors'
import { Icon20 } from 'builder/components/Icon'

import { useUser } from 'builder/hooks/useUser'
import { useI18n } from 'builder/hooks/useI18n'
import { UserType } from 'builder/modules/user'

const TRANSLATION = 'builder.side_menu'

export const useJobSearchSubMenu = () => {
  const user = useUser()
  const { i18n } = useI18n()

  const isPostPremiumActive = user?.billingInfo?.userType === UserType.postPremium
  const PremiumIcon = isPostPremiumActive && (
    <Icon20.Crown width={24} height={24} color={Colors.Indigo70} />
  )

  const jobSearchUrl = (topLevel: number, secondLevel: number) => {
    const queryParams = `?cp_top_level=${topLevel}&cp_second_level=${secondLevel}`
    return `/career-plans/execute-a-job-search${queryParams}`
  }

  const jobSearchSubMenu = [
    {
      to: jobSearchUrl(1, 1),
      text: i18n.t(`${TRANSLATION}.job_search_method_submenu.master_plan`),
      isPostPremium: isPostPremiumActive,
      endDecoration: PremiumIcon,
      eventParam: 'the_master_plan',
    },
    {
      to: jobSearchUrl(2, 3),
      text: i18n.t(`${TRANSLATION}.job_search_method_submenu.brand_yourself`),
      isPostPremium: isPostPremiumActive,
      endDecoration: PremiumIcon,
      eventParam: 'brand_yourself',
    },
    {
      to: jobSearchUrl(3, 7),
      text: i18n.t(`${TRANSLATION}.job_search_method_submenu.more_meetings`),
      isPostPremium: isPostPremiumActive,
      endDecoration: PremiumIcon,
      eventParam: 'get_more_meetings',
    },
    {
      to: jobSearchUrl(4, 11),
      text: i18n.t(`${TRANSLATION}.job_search_method_submenu.interview_win`),
      isPostPremium: isPostPremiumActive,
      endDecoration: PremiumIcon,
      eventParam: 'interview_and_win',
    },
    {
      to: jobSearchUrl(5, 12),
      text: i18n.t(`${TRANSLATION}.job_search_method_submenu.close_deal`),
      isPostPremium: isPostPremiumActive,
      endDecoration: PremiumIcon,
      eventParam: 'close_the_deal',
    },
  ]

  return { jobSearchSubMenu }
}
