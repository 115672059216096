import { useLocation, useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'

import { useI18n } from 'builder/hooks/useI18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/autoApply/autoApplyModule'

import Tooltip from 'builder/components/Tooltip'
import { useAutoApply } from 'builder/views/AutoApply/hooks/useAutoApply'
import { JobSearchSolutionBubble } from '../JobSearchSolutionBubble/JobSearchSolutionBubble'

import { AUTO_APPLY_VISITED_KEY, Routes, StartCompleteScreenRoute } from '../../constants'
import { useGetCareerProfile } from '../../hooks/useGetCareerProfile'

import {
  ArrowActionContainer,
  ArrowActionIcon,
  Container,
  Header,
  SubHeader,
  TextContainer,
  VerticalDivider,
} from './styles'

const TRANSLATION = 'builder.auto_apply.entry_point'

type Props = {
  hideDivider?: boolean
  showBubbles?: boolean
} & React.HTMLAttributes<HTMLDivElement>

export const JobSearchSolution = ({ hideDivider, showBubbles, ...rest }: Props) => {
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const location = useLocation()
  const { data } = useGetCareerProfile()
  const { isAutoApplyFeatureEnabled, isUserOnAutoApplyPlan, hasUserCompletedQuestionnaire } =
    useAutoApply()
  const isAutoApplyVisited = localStorage.getItem(AUTO_APPLY_VISITED_KEY)

  const form = useTypedSelector(selectors.form)
  const isAutoApplyFormCompleted = useTypedSelector(selectors.isAutoApplyFormCompleted)

  const showEntryPoint = () => {
    const isStartingFromAutoApplyFlow = localStorage.getItem(
      'provisioning-premium-with-3-auto-apply-credits',
    )

    return (
      !location.pathname.includes('auto-apply') &&
      (isUserOnAutoApplyPlan || isStartingFromAutoApplyFlow) &&
      isAutoApplyFeatureEnabled &&
      !hasUserCompletedQuestionnaire &&
      !isAutoApplyFormCompleted &&
      isAutoApplyVisited
    )
  }

  if (!showEntryPoint()) {
    return <></>
  }

  const navigateToAutoApplyFlow = () => {
    trackInternalEvent('tjss_nav_continue_button', {
      label: 'total_job_search_solution',
      page: location.pathname,
    })

    const isResumeUploaded = form.resumeUrl || data?.resumeUrl
    const redirectionPath = isResumeUploaded
      ? Routes.PERSONAL_INFO
      : StartCompleteScreenRoute.RESUME_SELECT

    navigate(`/auto-apply/${redirectionPath}`)
  }

  return (
    <>
      <Tooltip width={250} position="bottom" value={i18n.t(`${TRANSLATION}.tooltip`)} multiline>
        <Container onClick={navigateToAutoApplyFlow} {...rest}>
          <TextContainer>
            <Header>{i18n.t(`${TRANSLATION}.title`)}</Header>
            <SubHeader>{i18n.t(`${TRANSLATION}.start_subtitle`)}</SubHeader>
          </TextContainer>

          <ArrowActionContainer>
            <ArrowActionIcon />
          </ArrowActionContainer>
        </Container>
      </Tooltip>
      {!hideDivider && <VerticalDivider />}
      {showBubbles && <JobSearchSolutionBubble />}
    </>
  )
}
