import styled from 'styled-components'
import { VideoButton } from 'builder/components/YoutubeVideoPlayer/styles'
import Icon24 from 'builder/components/Icon'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  display: flex;
`

export const PlayIcon = styled(Icon24.Play)`
  display: none;
  margin: 0 2px;
  width: 20px;
  height: 20px;
  color: ${Colors.Blue50};
`

export const LogoContainer = styled.div`
  display: block;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const IconContainer = styled.div`
  border-radius: 4px;
  background: ${Colors.White};
  padding: 2px 6px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 4px rgba(15, 56, 113, 0.08), 0px 1px 2px rgba(15, 56, 113, 0.12),
    0px 0px 1px rgba(15, 56, 113, 0.32);
`

export const AutoApplyContainer = styled.div`
  display: flex;
  color: ${Colors.Neutral90};
  ${FontWeights.Regular};
  ${Typography.Body};
  align-items: center;
  background: ${Colors.White};
  cursor: pointer;
  border-radius: 12px;
  gap: 8px;

  &:hover {
    color: ${Colors.Blue50};

    ${PlayIcon} {
      display: block;
    }

    ${LogoContainer} {
      display: none;
    }

    ${IconContainer} {
      box-shadow: 0px 8px 20px rgba(15, 56, 113, 0.12), 0px 2px 4px rgba(15, 56, 113, 0.08),
        0px 0px 1px rgba(15, 56, 113, 0.32);
    }
  }
`

export const VideoContainer = styled.div`
  position: relative;
`

export const CloseButton = styled(VideoButton)`
  height: 28px;
  width: 28px;
  position: absolute;
  top: 32px;
  right: 32px;
  z-index: 1;

  ${Media.Phone`    
    top: 20px;
    right: 20px;
  `}
`

export const CloseIcon = styled(Icon24.Close)`
  color: ${Colors.Neutral50};
`

export const LogoIcon = styled.img`
  width: 16px;
  height: 12px;
`
