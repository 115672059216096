import { useCallback, useEffect, useRef, useState } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { Resume } from '@rio/types'
import { useDispatch } from 'react-redux'
import AvatarEditorModal from 'builder/components/AvatarEditorModal'
import EditorField from 'builder/components/EditorField'
import EditorRow from 'builder/components/EditorRow'
import PhotoUploadField from 'builder/components/PhotoUploadField'
import { SectionHeader } from 'builder/components/Section'
import SectionTitle from 'builder/components/SectionTitle'
import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import FocusManager from 'builder/services/FocusManager'
import {
  actions as editorActions,
  AvatarUploadPayload,
  selectors as editorSelectors,
} from 'builder/modules/resumeEditor'
import { actions as uiActions } from 'builder/modules/ui'
import { Container } from 'builder/components/PersonalDetailsSection/styles'
import { Address } from './Address'
import { AddressInFurigana } from './AddressInFurigana'
import { City } from './City'
import { Country } from './Country'
import { DateOfBirth } from './DateOfBirth'
import { Email } from './Email'
import { FirstName } from './FirstName'
import { FullName } from './FullName'
import { Gender } from './Gender'
import { LastName } from './LastName'
import { Phone } from './Phone'
import { PostalCode } from './PostalCode'
import { SubmissionDate } from './SubmissionDate'

export const JapanesePersonalDetailsSection = ({ resume }: { resume: Resume }) => {
  const dispatch = useDispatch()
  const { i18n } = useI18n()
  const templates = useTypedSelector(editorSelectors.templates)
  const isUploadingPhoto = useTypedSelector(state => state.resumeEditor.isUploadingPhoto)
  const isOnline = useTypedSelector(state => state.application.isOnline)

  const container = useRef<HTMLDivElement | null>(null)
  const [isAvatarEditorVisible, setAvatarEditorVisibility] = useState(false)

  const { locale, sectionTitles } = resume
  const currentTemplate = templates.find(template => template.id === resume.template)
  const supportsPhoto = currentTemplate ? currentTemplate.supportsPhoto : false
  const { isPhone } = useMediaQueries()

  // Connect to FocusManager
  useEffect(() => {
    const focus = (name: string) => {
      const targetField = container.current?.querySelector<HTMLInputElement>(`[name=${name}]`)
      if (targetField) targetField.focus()
    }

    const focusableFieldNames: Array<keyof Resume> = ['position', 'email', 'phoneNumber']
    focusableFieldNames.forEach(name => FocusManager.subscribe(name, () => focus(name)))
    return () => focusableFieldNames.forEach(name => FocusManager.unsubscribe(name))
  }, [])

  // Disable photo uploader window if user is offline
  const handleTogglePhotoUploader = useCallback(() => {
    if (!isOnline) return dispatch(uiActions.setConnectionLostSnackbarOpen(true))
    setAvatarEditorVisibility(value => !value)
  }, [dispatch, isOnline])

  // Handle avatar editor payload
  const handleUploadPhoto = useCallback(
    (payload: AvatarUploadPayload) => {
      dispatch(editorActions.uploadPhoto(payload))
      trackInternalEvent('change_avatar')
      setAvatarEditorVisibility(false)
    },
    [dispatch],
  )

  // Clear resume avatar
  const handleDeletePhoto = useCallback(() => {
    if (!window.confirm(i18n.t('builder.resume_editor.delete_photo_confirm'))) return
    dispatch(editorActions.deletePhoto())
    trackInternalEvent('delete_avatar')
  }, [dispatch, i18n])

  return (
    <Container ref={container}>
      {/* Section header */}
      <SectionHeader>
        <SectionTitle
          title={i18n.t('builder.resume_editor.personal_details')}
          customTitle={sectionTitles.details}
        />
      </SectionHeader>

      <EditorRow>
        <FullName />

        {/* Avatar */}
        <EditorField>
          <PhotoUploadField
            imageUrl={resume.avatar.blank ? '' : resume.avatar.thumbUrl}
            isUploading={isUploadingPhoto}
            isLocked={!supportsPhoto}
            onUpload={handleUploadPhoto}
            onDelete={handleDeletePhoto}
            onClick={isPhone ? null : handleTogglePhotoUploader}
          />
          {isAvatarEditorVisible && !isPhone && (
            <AvatarEditorModal
              avatar={resume.avatar}
              onSubmit={handleUploadPhoto}
              onClose={handleTogglePhotoUploader}
            />
          )}
        </EditorField>
      </EditorRow>

      <EditorRow>
        {/* First name */}
        <FirstName firstName={resume.firstName} />
        {/* Last name */}
        <LastName lastName={resume.lastName} />
      </EditorRow>

      <EditorRow>
        <DateOfBirth />
        <Gender gender="male" />
      </EditorRow>

      <EditorRow>
        <AddressInFurigana />
        <Address />
      </EditorRow>

      <EditorRow>
        <City city={resume.city} countryName={resume.countryName} locale={locale} />
        <Country countryName={resume.countryName} locale={locale} />
      </EditorRow>

      <EditorRow>
        <PostalCode postalCode={resume.postalCode} />
        <Phone phoneNumber={resume.phoneNumber} />
      </EditorRow>

      <EditorRow>
        <Email name="email" value={resume.email} />
        <SubmissionDate />
      </EditorRow>
    </Container>
  )
}
