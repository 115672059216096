import styled from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Button from 'builder/components/Button'
import visualUrlOld from 'images/builder/account/cancel-subscription/pause-visual.svg'
import { getLineHeightRatio } from '../../common/utils'

/**
 * The dimensions for the styles mentioned below have been referred from the figma
 * https://www.figma.com/file/wdX0fbRVlodq1Is8l1GwoV/APP-1372%3A-Subscription-cancelation?node-id=1%3A2&t=sJ8R8w5Ggv06RqH5-0
 */

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 544px;
  height: 568px;
  border-radius: 6px;
  background-color: ${Colors.White};

  ${Media.Phone`
    width: 343px;
    height: auto;
  `}
`

export const Close = styled.div`
  position: absolute;
  right: 32px;
  top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${Colors.White};
  color: ${hexToRgba(Colors.Neutral50)};
  transition: background-color 0.2s;
  cursor: pointer;

  ${Media.Phone`
    top: 20px;
    right: 20px;
  `}
`

export const Visual = styled.div`
  background-image: url(${visualUrlOld});
  background-size: cover;
  width: 544px;
  height: 220px;
  border-radius: 6px 6px 0 0;

  ${Media.Phone`
    width: 343px;
    height: 188px;
  `}
`

export const Content = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: left;

  ${Media.Phone`
    padding: 20px;
  `}
`

export const Title = styled.div`
  ${Typography.S};
  font-weight: 600;
  width: 480px;
  font-size: 26px;
  line-height: ${getLineHeightRatio(28, 26)};
  margin-bottom: 8px;
  ${Media.Phone`
    width: 303px;
    font-size: 23px;
    line-height: ${getLineHeightRatio(24, 23)};
  `}
`

export const Text = styled.div`
  color: ${hexToRgba(Colors.Neutral50)};
  width: 480px;
  font-size: 19px;
  line-height: ${getLineHeightRatio(24, 19)};
  & p:not(:last-child) {
    margin-bottom: 12px;
  }

  ${Media.Phone`
    width: 303px;
    font-size: 16px;
    line-height: ${getLineHeightRatio(20, 16)};
    & p:not(:last-child) {
      margin-bottom: 8px;
    }
  `}
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0px 32px 32px 32px;

  ${Media.Phone`
    flex-direction: column;
    padding: 0px 20px 20px 20px;
    & button:not(:last-child) {
      margin-bottom: 8px;
    }
  `}

  & ${Button} {
    margin-left: 16px;
    display: flex;
    font-size: 18px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    line-height: ${getLineHeightRatio(22, 18)};
    border-radius: 4px;
    height: 48px;
    width: 176px;

    ${Media.Phone`
      margin-left: 0px;
      width: 303px;
      font-size: 16px;
      line-height: ${getLineHeightRatio(20, 16)};
      height: 36px;
    `}
  }
`
