import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Button from 'builder/components/Button'
import Media from 'builder/styles/media'
import { PhrasesSuggestionContainer } from '../PhrasesSuggestion'
import { ScrollContainer } from '../ScrollContainer'

interface SuggestionProps {
  isAnimationActive: boolean
}

export const Container = styled(PhrasesSuggestionContainer)`
  overflow: visible;
  width: 480px;
  border-radius: 8px;

  ${Media.Phone`
    border-radius: 0;
  `}

  ${props => Media.Desktop`
    margin-left: 16px;
    left: 100%;
    top: 21%;
    transform: translate(-8px, -50%);

    ${
      props.isVisible &&
      css`
        transform: translate(0, -50%);
        opacity: 1;
      `
    }
  `}
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px 16px 16px;
`

export const Title = styled.p`
  color: ${Colors.Indigo70};
  font-size: var(--Text-Style-Size-CAPS, 14px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--Text-Style-Line-Height-CAPS, 20px);
  letter-spacing: 1.8px;
  text-transform: uppercase;
`

export const Description = styled.p`
  color: ${Colors.Neutral50};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.3px;
`

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${Colors.Neutral15};
`

export const Suggestion = styled.div<SuggestionProps>(({ isAnimationActive }) => {
  return css`
    color: ${Colors.Neutral90};
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 8px 16px 16px;
    min-height: 216px;

    ${isAnimationActive &&
    css`
      color: ${Colors.Indigo50};
    `}
  `
})

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  width: 100%;
  padding: 16px;
`

export const AttemptsLeft = styled.div`
  color: ${Colors.Indigo60};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.3px;
`

export const RetryButton = styled(Button)`
  padding: 8px 12px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  border: 1px solid ${Colors.Neutral20};
  color: ${Colors.Neutral70};
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  background-color: ${Colors.White};

  &:hover {
    background-color: ${Colors.White};
    border: 1px solid ${Colors.Indigo30};
    color: ${Colors.Indigo60};
  }
`

export const Icon = styled.div`
  path:first-child {
    fill: inherit;
  }
  path:not(:first-child) {
    fill: inherit;
  }
`

export const UseButton = styled(Button)`
  color: ${Colors.White};
  background-color: ${Colors.Indigo50};
  padding: 8px 24px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;

  &:hover {
    background-color: ${Colors.Indigo60};
  }
`

export const CloseButton = styled(Button)`
  position: absolute;
  top: 12px;
  right: 12px;
  color: ${Colors.Neutral40};
  transition: color 0.1s;
  z-index: 999;
  background-color: ${Colors.White};
  padding: 0;

  &:hover {
    background-color: ${Colors.White};
  }
`

export const CloseButtonIconContainer = styled.div`
  pointer-events: none;
`

export const Scroll = styled(ScrollContainer)`
  overflow-y: scroll;
  max-height: 300px;

  ${Media.Phone`
    height: 250px;
    max-height: 250px;
  `}
`
