import { createAction, createReducer } from '@reduxjs/toolkit'
import { sortBy } from 'lodash'
import { Store } from 'builder/modules/store'
import { FetchStatuses } from 'builder/modules/constants'
import { BriefResume } from 'builder/modules/panel'
import { TABS, CareerPathStep } from './constants'
import {
  Skill,
  SalaryDetails,
  JobProgression,
  CareerCardType,
  CareerPathStore,
  JobTitleClickCount,
  JobProgressionsPath,
  SalaryFeedbackParams,
  CareerPathPayloadType,
  SaveCareerPathsPayload,
  CareerPathRoleSummaryPayload,
  Course,
  CareerPagesJobDetailsPayload,
  SearchQuery,
  Job,
  JobDeletePayload,
  JobCreatePayload,
  JobCard,
  JobDemandParams,
  JobDemand,
  SalaryParams,
} from './types'

export const actions = {
  setStep: createAction<CareerPathStep>('builder/CAREER_PATH_SET_STEP'),
  setPrevStep: createAction<CareerPathStep>('builder/CAREER_PATH_SET_PREV_STEP'),
  setOccupation: createAction<string>('builder/CAREER_PATH_SET_OCCUPATION'),
  setSkills: createAction<Skill[]>('builder/CAREER_PATH_SET_SKILLS'),
  setOpenPreFillModal: createAction<boolean>('builder/CAREER_PATH_SET_OPEN_PRE_FILL_MODAL'),
  fetchJobTitles: createAction<JobProgression>('builder/CAREER_PATH_FETCH_JOB_TITLES'),
  setLevel1JobTitles: createAction<JobProgressionsPath[]>(
    'builder/CAREER_PATH_SET_LEVEL1_JOB_TITLES',
  ),
  setLevel2JobTitles: createAction<JobProgressionsPath[]>(
    'builder/CAREER_PATH_SET_LEVEL2_JOB_TITLES',
  ),
  setLevel3JobTitles: createAction<JobProgressionsPath[]>(
    'builder/CAREER_PATH_SET_LEVEL3_JOB_TITLES',
  ),
  setCareerPath1: createAction<[]>('builder/CAREER_PATH_SET_CAREER_PATH1'),
  setCareerPath1Level1: createAction<CareerPathPayloadType>(
    'builder/CAREER_PATH_SET_CAREER_PATH1_LEVEL1',
  ),
  setCareerPath1Level2: createAction<CareerPathPayloadType>(
    'builder/CAREER_PATH_SET_CAREER_PATH1_LEVEL2',
  ),
  setCareerPath1Level3: createAction<CareerPathPayloadType>(
    'builder/CAREER_PATH_SET_CAREER_PATH1_LEVEL3',
  ),
  setIsCareerPath1Set: createAction<boolean>('builder/CAREER_PATH_SET_IS_CAREER_PATH1_SET'),
  setSuggestedSkills: createAction<Skill[]>('builder/CAREER_PATH_SET_SUGGESTED_SKILLS'),
  setSuggestedSkillsCopy: createAction<Skill[]>('builder/CAREER_PATH_SET_SUGGESTED_SKILLS_COPY'),
  setValidationError: createAction<boolean>('builder/CAREER_PATH_SET_VALIDATION_ERROR'),
  fetchSuggestedSkills: createAction<string>('builder/CAREER_PATH_FETCH_OPTIONS_SKILLS'),
  fetchUserDocuments: createAction('builder/CAREER_PATH_FETCH_USER_DOCUMENTS'),
  setUserDocuments: createAction<BriefResume[]>('builder/CAREER_PATH_SET_USER_DOCUMENTS'),
  fetchResumeSkills: createAction<number | null>('builder/CAREER_PATH_FETCH_RESUME_SKILLS'),
  setResumeID: createAction<number | null>('builder/CAREER_PATH_SET_RESUME_ID'),
  setFetchJobProgressionStatus: createAction<FetchStatuses>(
    'builder/CAREER_PATH_SET_FETCH_JOB_PROGRESSION_STATUS',
  ),
  setAddToPathJobTitle: createAction<string | undefined>(
    'builder/CAREER_PATH_ADD_TO_PATH_JOB_TITLE',
  ),
  saveCareerPaths: createAction<SaveCareerPathsPayload>('builder/CAREER_PATH_SAVE_CAREER_PATHS'),
  setSkillsMatchScore: createAction<number>('builder/CAREER_PATH_SET_SKILLS_MATCH_SCORE'),
  setRequiredSkills: createAction<string[]>('builder/CAREER_PATH_SET_REQUIRED_SKILLS'),
  setJobDetails: createAction<CareerPathRoleSummaryPayload>('builder/CAREER_PATH_SET_JOB_DETAILS'),
  setFetchJobDetailsStatus: createAction<FetchStatuses>(
    'builder/CAREER_PATH_SET_FETCH_JOB_DETAILS_STATUS',
  ),
  fetchCareerCards: createAction('builder/CAREER_PATH_FETCH_CAREER_CARDS'),
  setAllCareerCards: createAction<CareerCardType[]>('builder/CAREER_PATH_SET_CAREER_CARDS'),
  fetchRequiredSkills: createAction<string>('builder/CAREER_PATH_FETCH_REQUIRED_SKILLS'),
  setLevel1JobTitleIndex: createAction<null | number | undefined>(
    'builder/CAREER_PATH_SET_LEVEL1_JOB_TITLE_INDEX',
  ),
  setLevel2JobTitleIndex: createAction<null | number | undefined>(
    'builder/CAREER_PATH_SET_LEVEL2_JOB_TITLE_INDEX',
  ),
  setLevel3JobTitleIndex: createAction<null | number | undefined>(
    'builder/CAREER_PATH_SET_LEVEL3_JOB_TITLE_INDEX',
  ),
  setCurrentLevel: createAction<null | number | undefined>('builder/CAREER_PATH_SET_CURRENT_LEVEL'),
  setSelectedLevel1JobTitleIndex: createAction<null | number | undefined>(
    'builder/CAREER_PATH_SET_SELECTED_LEVEL1_JOB_TITLE_INDEX',
  ),
  setSelectedLevel2JobTitleIndex: createAction<null | number | undefined>(
    'builder/CAREER_PATH_SET_SELECTED_LEVEL2_JOB_TITLE_INDEX',
  ),
  setSelectedLevel3JobTitleIndex: createAction<null | number | undefined>(
    'builder/CAREER_PATH_SET_SELECTED_LEVEL3_JOB_TITLE_INDEX',
  ),
  setEditCareerCardData: createAction<CareerCardType>('builder/CAREER_PATH_SET_EDIT_CAREER_CARDS'),
  updateCareerPath: createAction<SaveCareerPathsPayload>('builder/CAREER_PATH_UPDATE_CAREER_PATHS'),
  fetchJobTitlesLevelTwo: createAction<JobProgression>(
    'builder/CAREER_PATH_FETCH_JOB_TITLES_LEVEL_TWO',
  ),
  fetchJobTitlesLevelThree: createAction<JobProgression>(
    'builder/CAREER_PATH_FETCH_JOB_TITLES_LEVEL_THREE',
  ),
  setFetchGoalsStatus: createAction<FetchStatuses>('builder/CAREER_PATH_SET_FETCH_GOALS_STATUS'),
  setFetchJobDetailsPart1Status: createAction<FetchStatuses>(
    'builder/CAREER_PATH_SET_FETCH_JOB_DETAILS_PART_ONE_STATUS',
  ),
  setFetchJobDetailsPart2Status: createAction<FetchStatuses>(
    'builder/CAREER_PATH_SET_FETCH_JOB_DETAILS_PART_TWO_STATUS',
  ),
  setFetchJobDetailsPart3Status: createAction<FetchStatuses>(
    'builder/CAREER_PATH_SET_FETCH_JOB_DETAILS_PART_THREE_STATUS',
  ),
  setFetchJobDetailsPart4Status: createAction<FetchStatuses>(
    'builder/CAREER_PATH_SET_FETCH_JOB_DETAILS_PART_FOUR_STATUS',
  ),
  setAddToPathLevel: createAction<number>('builder/CAREER_PATH_SET_ADD_TO_PATH_LEVEL'),
  setJobTitleCount: createAction<JobTitleClickCount>(
    'builder/CAREER_PATH_SET_JOB_TITLE_CLICK_COUNT',
  ),
  setIsSwiperVisible: createAction<boolean>('builder/CAREER_PATH_SET_IS_SWIPER_VISIBLE'),
  setIsEditingNewAdded: createAction<boolean>('builder/CAREER_PATH_SET_IS_EDITING_NEW_ADDED'),
  setSelectedJobTitle: createAction<string | undefined>(
    'builder/CAREER_PATH_SET_SELECTED_JOB_TITLE',
  ),
  setIsExploreCareers: createAction<boolean>('builder/CAREER_PATH_SET_IS_EXPLORE_CAREERS'),
  setExploreCareersOccupation: createAction<string>(
    'builder/CAREER_PATH_SET_EXPLORE_CAREERS_OCCUPATION',
  ),
  setDeletePathsModalStatus: createAction<boolean>(
    'builder/CAREER_PATH_SET_DELETE_PATHS_MODAL_STATUS',
  ),
  setPathsCardIdToDelete: createAction<number | null>(
    'builder/CAREER_PATH_SET_PATHS_CARD_ID_TO_DELETE',
  ),
  deletePathsCard: createAction<{ id: number }>('builder/CAREER_PATH_DELETE_PATHS_CARD'),
  deletePaths: createAction<{ id: number }>('builder/CAREER_PATH_DELETE_PATHS'),
  setResumeSkills: createAction<string[]>('builder/CAREER_PATH_SET_RESUME_SKILLS'),
  fetchCourses: createAction<{ title: string; limit?: number }>(
    'builder/CAREER_PATH_FETCH_COURSES',
  ),
  setFetchCoursesStatus: createAction<FetchStatuses>('builder/CAREER_PATH_SET_COURSES_STATUS'),
  setCourses: createAction<Course[]>('builder/CAREER_PATH_SET_COURSES'),
  setExploreCareersLocation: createAction<string>(
    'builder/CAREER_PATH_SET_EXPLORE_CAREERS_LOCATION',
  ),
  setLocation: createAction<string>('builder/CAREER_PATH_SET_LOCATION'),
  fetchSalaryDetails: createAction<SalaryParams>('builder/CAREER_PATH_FETCH_SALARY_DETAILS'),
  setSalaryDetails: createAction<SalaryDetails>('builder/CAREER_PATH_SET_SALARY_DETAILS'),
  setFetchSalaryStatus: createAction<FetchStatuses>('builder/CAREER_PATH_SET_FETCH_SALARY_STATUS'),
  saveSalaryFeedback: createAction<SalaryFeedbackParams>(
    'builder/CAREER_PATH_SAVE_SALARY_FEEDBACK',
  ),
  fetchCareerPagesJobDetails: createAction<string | undefined>(
    'builder/CAREER_PATH_FETCH_CAREER_PAGES_JOB_DETAILS',
  ),
  setCareerPagesJobDetails: createAction<CareerPagesJobDetailsPayload>(
    'builder/CAREER_PATH_SET_CAREER_PAGES_JOB_DETAILS',
  ),
  setFetchCareerPagesJobDetailsStatus: createAction<FetchStatuses>(
    'builder/CAREER_PATH_SET_FETCH_CAREER_PAGES_JOB_DETAILS_STATUS',
  ),
  fetchRecommendedJobCard: createAction<SearchQuery>(
    'builder/CAREER_PATH_FETCH_RECOMMENDED_JOB_CARD',
  ),
  setJobCardStatus: createAction<FetchStatuses>('builder/CAREER_PATH_SET_JOB_CARD_STATUS'),
  setFetchRecommendedJobCard: createAction<Job[]>(
    'builder/CAREER_PATH_SET_FETCH_RECOMMENDED_JOB_CARD',
  ),
  fetchJobsRequest: createAction('builder/CAREER_PATH_FETCH_JOBS_REQUEST'),
  deleteJobCardRequest: createAction<JobDeletePayload>(
    'builder/CAREER_PATH_DELETE_JOB_CARD_REQUEST',
  ),
  deleteJob: createAction<JobDeletePayload>('builder/CAREER_PATH_DELETE_JOB'),
  createJobCardRequest: createAction<JobCreatePayload>(
    'builder/CAREER_PATH_CREATE_JOB_CARD_REQUEST',
  ),
  setJobsData: createAction<JobCard[]>('builder/CAREER_PATH_SET_JOBS_DATA'),
  addJob: createAction<JobCard>('builder/CAREER_PATH_ADD_JOB'),
  fetchJobDemand: createAction<JobDemandParams>('builder/CAREER_PATH_FETCH_JOB_DEMAND'),
  setJobDemandStatus: createAction<FetchStatuses>('builder/CAREER_PATH_SET_JOB_DEMAND_STATUS'),
  setJobDemand: createAction<JobDemand>('builder/CAREER_PATH_SET_JOB_DEMAND'),
  setFetchJobTitlesStatus: createAction<FetchStatuses>(
    'builder/CAREER_PATH_SET_FETCH_JOB_TITLES_STATUS',
  ),
  setActiveTab: createAction<string>('builder/CAREER_PATH_SET_ACTIVE_TAB'),
}

export const selectors = {
  step: (state: Store) => state.careerPath2.step,
  prevStep: (state: Store) => state.careerPath2.prevStep,
  occupation: (state: Store) => state.careerPath2.occupation,
  skills: (state: Store) => state.careerPath2.skills,
  suggestedSkills: (state: Store) => state.careerPath2.suggestedSkills,
  suggestedSkillsCopy: (state: Store) => state.careerPath2.suggestedSkillsCopy,
  openPrefillModal: (state: Store) => state.careerPath2.openPrefillModal,
  careerPaths: (state: Store) => state.careerPath2.careerPaths,
  level1JobTitles: (state: Store) => state.careerPath2.level1JobTitles,
  level2JobTitles: (state: Store) => state.careerPath2.level2JobTitles,
  level3JobTitles: (state: Store) => state.careerPath2.level3JobTitles,
  careerPath1: (state: Store) => state.careerPath2.careerPaths[0].careerPath1,
  isCareerPath1Set: (state: Store) => state.careerPath2.isCareerPath1Set,
  validationError: (state: Store) => state.careerPath2.validationError,
  userDocuments: (state: Store) => state.careerPath2.userDocuments,
  resumeID: (state: Store) => state.careerPath2.resumeID,
  isJobProgressionLoading: (state: Store) =>
    state.careerPath2.fetchJobProgressionStatus === FetchStatuses.loading,
  isJobProgressionLoaded: (state: Store) =>
    state.careerPath2.fetchJobProgressionStatus === FetchStatuses.loaded,
  isJobProgressionFailed: (state: Store) =>
    state.careerPath2.fetchJobProgressionStatus === FetchStatuses.failed,
  isJobDetailsLoading: (state: Store) =>
    state.careerPath2.fetchJobDetailsStatus === FetchStatuses.loading,
  isJobDetailsLoaded: (state: Store) =>
    state.careerPath2.fetchJobDetailsStatus === FetchStatuses.loaded,
  addToPathJobTitle: (state: Store) => state.careerPath2.addToPathJobTitle,
  hardSkills: (state: Store) => state.careerPath2.hardSkills,
  softSkills: (state: Store) => state.careerPath2.softSkills,
  skillsMatchScore: (state: Store) => state.careerPath2.skillsMatchScore,
  requiredSkills: (state: Store) => state.careerPath2.requiredSkills,
  jobDetails: (state: Store) => state.careerPath2.jobDetails,
  allCareerCards: (state: Store) => state.careerPath2.allCareerCards,
  level1JobTitleIndex: (state: Store) => state.careerPath2.level1JobTitleIndex,
  level2JobTitleIndex: (state: Store) => state.careerPath2.level2JobTitleIndex,
  level3JobTitleIndex: (state: Store) => state.careerPath2.level3JobTitleIndex,
  selectedLevel1JobTitleIndex: (state: Store) => state.careerPath2.selectedLevel1JobTitleIndex,
  selectedLevel2JobTitleIndex: (state: Store) => state.careerPath2.selectedLevel2JobTitleIndex,
  selectedLevel3JobTitleIndex: (state: Store) => state.careerPath2.selectedLevel3JobTitleIndex,
  currentLevel: (state: Store) => state.careerPath2.currentLevel,
  editCareerCardData: (state: Store) => state.careerPath2.editCareerCardData,
  goalsStatus: (state: Store) => state.careerPath2.fetchGoalsStatus,
  isJobDetailPart1Loading: (state: Store) =>
    state.careerPath2.fetchJobDetailsCount1Status === FetchStatuses.loading,
  isJobDetailPart2Loading: (state: Store) =>
    state.careerPath2.fetchJobDetailsCount2Status === FetchStatuses.loading,
  isJobDetailPart3Loading: (state: Store) =>
    state.careerPath2.fetchJobDetailsCount3Status === FetchStatuses.loading,
  isJobDetailPart4Loading: (state: Store) =>
    state.careerPath2.fetchJobDetailsCount4Status === FetchStatuses.loading,
  addToPathLevel: (state: Store) => state.careerPath2.addToPathLevel,
  jobTitleCounts: (state: Store) => state.careerPath2.jobTitleCounts,
  isSwiperVisible: (state: Store) => state.careerPath2.isSwiperVisible,
  isEditingNewAdded: (state: Store) => state.careerPath2.isEditingNewAdded,
  selectedJobTitle: (state: Store) => state.careerPath2.selectedJobTitle,
  isExploreCareers: (state: Store) => state.careerPath2.isExploreCareers,
  exploreCareersOccupation: (state: Store) => state.careerPath2.exploreCareersOccupation,
  isDeletePathsModalOpen: (state: Store) => state.careerPath2.isDeletePathsModalOpen,
  pathsCardIdToDelete: (state: Store) => state.careerPath2.pathsCardIdToDelete,
  resumeSkills: (state: Store) => state.careerPath2.resumeSkills,
  courses: (state: Store) => state.careerPath2.courses,
  isCoursesLoading: (state: Store) =>
    state.careerPath2.fetchCoursesStatus === FetchStatuses.loading,
  isCoursesLoaded: (state: Store) => state.careerPath2.fetchCoursesStatus === FetchStatuses.loaded,
  isCoursesFailed: (state: Store) => state.careerPath2.fetchCoursesStatus === FetchStatuses.failed,
  location: (state: Store) => state.careerPath2.location,
  exploreCareersLocation: (state: Store) => state.careerPath2.exploreCareersLocation,
  salaryDetails: (state: Store) => state.careerPath2.salaryDetails,
  isSalaryLoading: (state: Store) => state.careerPath2.fetchSalaryStatus === FetchStatuses.loading,
  isSalaryLoaded: (state: Store) => state.careerPath2.fetchSalaryStatus === FetchStatuses.loaded,
  isSalaryFailed: (state: Store) => state.careerPath2.fetchSalaryStatus === FetchStatuses.failed,
  careerPagesJobDetails: (state: Store) => state.careerPath2.careerPagesJobDetails,
  isCareerPagesJobDetailsLoading: (state: Store) =>
    state.careerPath2.fetchCareerPagesJobDetailsStatus === FetchStatuses.loading,
  isCareerPagesJobDetailsLoaded: (state: Store) =>
    state.careerPath2.fetchCareerPagesJobDetailsStatus === FetchStatuses.loaded,
  recommendedJobCard: (state: Store) => state.careerPath2.recommendedJobCard,
  isJobCardLoading: (state: Store) =>
    state.careerPath2.fetchJobCardStatus === FetchStatuses.loading,
  isJobCardLoaded: (state: Store) => state.careerPath2.fetchJobCardStatus === FetchStatuses.loaded,
  selectJobs: (state: Store) => state.careerPath2.wishlistJobCard,
  jobDemand: (state: Store) => state.careerPath2.jobDemand,
  isJobDemandLoading: (state: Store) =>
    state.careerPath2.fetchJobDemandStatus === FetchStatuses.loading,
  isJobDemandLoaded: (state: Store) =>
    state.careerPath2.fetchJobDemandStatus === FetchStatuses.loaded,
  isJobTitlesLoading: (state: Store) =>
    state.careerPath2.fetchJobTitlesStatus === FetchStatuses.loading,
  isJobTitlesLoaded: (state: Store) =>
    state.careerPath2.fetchJobTitlesStatus === FetchStatuses.loaded,
  activeTab: (state: Store) => state.careerPath2.activeTab,
}

const initialState: CareerPathStore = {
  step: CareerPathStep.QUESTIONNAIRE,
  prevStep: CareerPathStep.QUESTIONNAIRE,
  occupation: '',
  skills: [],
  suggestedSkills: [],
  suggestedSkillsCopy: [], // Copy is required in order to compare and store back on unselection
  openPrefillModal: false,
  level1JobTitles: [],
  level2JobTitles: [],
  level3JobTitles: [],
  careerPaths: [{ careerPath1: [] }],
  isCareerPath1Set: false,
  validationError: false,
  userDocuments: [],
  resumeID: null,
  fetchJobProgressionStatus: FetchStatuses.notAsked,
  fetchGoalsStatus: FetchStatuses.notAsked,
  addToPathJobTitle: '',
  hardSkills: [],
  softSkills: [],
  skillsMatchScore: 0,
  requiredSkills: [],
  jobDetails: {
    commonJobTitles: [],
    description: null,
    softSkills: [],
    hardSkills: [],
    certifications: [],
    companies: [],
    totals: {},
    salary: {},
    postingsTimeseries: [],
    educationLevels: [],
    jobTitle: null,
  },
  fetchJobDetailsStatus: FetchStatuses.notAsked,
  allCareerCards: [],
  level1JobTitleIndex: null,
  level2JobTitleIndex: null,
  level3JobTitleIndex: null,
  selectedLevel1JobTitleIndex: null,
  selectedLevel2JobTitleIndex: null,
  selectedLevel3JobTitleIndex: null,
  currentLevel: null,
  editCareerCardData: {
    id: 0,
    title: '',
    skills: [],
    pathways: { path1: [] },
  },
  fetchJobDetailsCount1Status: FetchStatuses.notAsked,
  fetchJobDetailsCount2Status: FetchStatuses.notAsked,
  fetchJobDetailsCount3Status: FetchStatuses.notAsked,
  fetchJobDetailsCount4Status: FetchStatuses.notAsked,
  addToPathLevel: 0,
  jobTitleCounts: { title: '', count: 0 },
  isSwiperVisible: false,
  isEditingNewAdded: false,
  selectedJobTitle: '',
  isExploreCareers: false,
  exploreCareersOccupation: '',
  isDeletePathsModalOpen: false,
  pathsCardIdToDelete: null,
  resumeSkills: [],
  courses: [],
  fetchCoursesStatus: FetchStatuses.notAsked,
  location: '',
  exploreCareersLocation: '',
  salaryDetails: {
    averageSalary: 0,
    educationLevelSalary: {
      bachelor: 0,
      master: 0,
    },
    experienceLevelSalary: {
      '10+': 0,
      '1to3': 0,
      '3to6': 0,
      '6to9': 0,
      fresher: 0,
    },
    maximumSalary: 0,
    minimumSalary: 0,
    title: '',
    topCitiesSalary: [],
    topStatesSalary: [],
  },
  fetchSalaryStatus: FetchStatuses.notAsked,
  careerPagesJobDetails: {
    faq: [],
    responsibilities: '',
    title: '',
    description: '',
    hardSkills: [],
    softSkills: [],
  },
  fetchCareerPagesJobDetailsStatus: FetchStatuses.notAsked,
  recommendedJobCard: [],
  fetchJobCardStatus: FetchStatuses.notAsked,
  wishlistJobCard: [],
  jobDemand: { jobsCount: 0, companiesCount: 0, companies: [] },
  fetchJobDemandStatus: FetchStatuses.notAsked,
  fetchJobTitlesStatus: FetchStatuses.notAsked,
  activeTab: TABS[0],
}

export const reducer = createReducer(initialState, reducer => {
  reducer.addCase(actions.setStep, (draft, action) => {
    draft.step = action.payload
  })
  reducer.addCase(actions.setPrevStep, (draft, action) => {
    draft.prevStep = action.payload
  })
  reducer.addCase(actions.setOccupation, (draft, action) => {
    draft.occupation = action.payload
  })
  reducer.addCase(actions.setSkills, (draft, action) => {
    draft.skills = action.payload
  })
  reducer.addCase(actions.setOpenPreFillModal, (draft, action) => {
    draft.openPrefillModal = action.payload
  })
  reducer.addCase(actions.setLevel1JobTitles, (draft, action) => {
    draft.level1JobTitles = action.payload
  })
  reducer.addCase(actions.setLevel2JobTitles, (draft, action) => {
    draft.level2JobTitles = action.payload
  })
  reducer.addCase(actions.setLevel3JobTitles, (draft, action) => {
    draft.level3JobTitles = action.payload
  })
  reducer.addCase(actions.setCareerPath1, (draft, action) => {
    draft.careerPaths[0].careerPath1 = action.payload
  })
  reducer.addCase(actions.setCareerPath1Level1, (draft, action) => {
    if (
      draft.careerPaths[0].careerPath1.length &&
      draft.careerPaths[0].careerPath1[0].title === action.payload.title
    ) {
      if (action.payload.isEditing) {
        draft.careerPaths[0].careerPath1[0] = {
          title: action.payload.title,
          score: action.payload.score,
        }
      } else {
        draft.careerPaths[0].careerPath1.pop()
        draft.careerPaths[0].careerPath1.pop()
        draft.careerPaths[0].careerPath1.pop()
        draft.level2JobTitles = []
        draft.level3JobTitles = []
      }
    } else {
      if (draft.careerPaths[0].careerPath1.length === 3) {
        draft.careerPaths[0].careerPath1.pop()
        draft.careerPaths[0].careerPath1.pop()
      } else if (draft.careerPaths[0].careerPath1.length === 2) {
        draft.careerPaths[0].careerPath1.pop()
      }
      draft.careerPaths[0].careerPath1[0] = action.payload
      draft.level3JobTitles = []
    }
  })
  reducer.addCase(actions.setCareerPath1Level2, (draft, action) => {
    if (
      draft.careerPaths[0].careerPath1.length > 2 &&
      draft.careerPaths[0].careerPath1[1].title === action.payload.title
    ) {
      if (action.payload.isEditing) {
        draft.careerPaths[0].careerPath1[1] = {
          title: action.payload.title,
          score: action.payload.score,
        }
      } else {
        draft.careerPaths[0].careerPath1.pop()
        draft.careerPaths[0].careerPath1.pop()
        draft.level3JobTitles = []
      }
    } else if (
      draft.careerPaths[0].careerPath1.length === 2 &&
      draft.careerPaths[0].careerPath1[1].title === action.payload.title
    ) {
      if (action.payload.isEditing) {
        draft.careerPaths[0].careerPath1[1] = {
          title: action.payload.title,
          score: action.payload.score,
        }
      } else {
        draft.careerPaths[0].careerPath1.pop()
        draft.level3JobTitles = []
      }
    } else {
      if (draft.careerPaths[0].careerPath1.length === 3) {
        draft.careerPaths[0].careerPath1.pop()
      }
      draft.careerPaths[0].careerPath1[1] = action.payload
    }
  })
  reducer.addCase(actions.setCareerPath1Level3, (draft, action) => {
    if (
      draft.careerPaths[0].careerPath1.length > 2 &&
      draft.careerPaths[0].careerPath1[2].title === action.payload.title
    ) {
      if (action.payload.isEditing) {
        draft.careerPaths[0].careerPath1[2] = {
          title: action.payload.title,
          score: action.payload.score,
        }
      } else {
        draft.careerPaths[0].careerPath1.pop()
      }
    } else {
      if (draft.careerPaths[0].careerPath1.length === 3) {
        draft.careerPaths[0].careerPath1.pop()
      }
      draft.careerPaths[0].careerPath1[2] = action.payload
    }
  })
  reducer.addCase(actions.setSuggestedSkills, (draft, action) => {
    draft.suggestedSkills = action.payload
  })
  reducer.addCase(actions.setSuggestedSkillsCopy, (draft, action) => {
    draft.suggestedSkillsCopy = action.payload
  })
  reducer.addCase(actions.setValidationError, (draft, action) => {
    draft.validationError = action.payload
  })
  reducer.addCase(actions.setUserDocuments, (draft, action) => {
    draft.userDocuments = action.payload
  })
  reducer.addCase(actions.setResumeID, (draft, action) => {
    draft.resumeID = action.payload
  })
  reducer.addCase(actions.setFetchJobProgressionStatus, (draft, action) => {
    draft.fetchJobProgressionStatus = action.payload
  })
  reducer.addCase(actions.setAddToPathJobTitle, (draft, action) => {
    draft.addToPathJobTitle = action.payload
  })
  reducer.addCase(actions.setSkillsMatchScore, (draft, action) => {
    draft.skillsMatchScore = action.payload
  })
  reducer.addCase(actions.setRequiredSkills, (draft, action) => {
    draft.requiredSkills = action.payload
  })
  reducer.addCase(actions.setJobDetails, (draft, action) => {
    draft.jobDetails = action.payload
  })
  reducer.addCase(actions.setFetchJobDetailsStatus, (draft, action) => {
    draft.fetchJobDetailsStatus = action.payload
  })
  reducer.addCase(actions.setAllCareerCards, (draft, action) => {
    draft.allCareerCards = action.payload
  })
  reducer.addCase(actions.setLevel1JobTitleIndex, (draft, action) => {
    draft.level1JobTitleIndex = action.payload
  })
  reducer.addCase(actions.setLevel2JobTitleIndex, (draft, action) => {
    draft.level2JobTitleIndex = action.payload
  })
  reducer.addCase(actions.setLevel3JobTitleIndex, (draft, action) => {
    draft.level3JobTitleIndex = action.payload
  })
  reducer.addCase(actions.setCurrentLevel, (draft, action) => {
    draft.currentLevel = action.payload
  })
  reducer.addCase(actions.setSelectedLevel1JobTitleIndex, (draft, action) => {
    draft.selectedLevel1JobTitleIndex = action.payload
  })
  reducer.addCase(actions.setSelectedLevel2JobTitleIndex, (draft, action) => {
    draft.selectedLevel2JobTitleIndex = action.payload
  })
  reducer.addCase(actions.setSelectedLevel3JobTitleIndex, (draft, action) => {
    draft.selectedLevel3JobTitleIndex = action.payload
  })
  reducer.addCase(actions.setEditCareerCardData, (draft, action) => {
    draft.editCareerCardData = action.payload
  })
  reducer.addCase(actions.setFetchGoalsStatus, (draft, action) => {
    draft.fetchGoalsStatus = action.payload
  })
  reducer.addCase(actions.setFetchJobDetailsPart1Status, (draft, action) => {
    draft.fetchJobDetailsCount1Status = action.payload
  })
  reducer.addCase(actions.setFetchJobDetailsPart2Status, (draft, action) => {
    draft.fetchJobDetailsCount2Status = action.payload
  })
  reducer.addCase(actions.setFetchJobDetailsPart3Status, (draft, action) => {
    draft.fetchJobDetailsCount3Status = action.payload
  })
  reducer.addCase(actions.setFetchJobDetailsPart4Status, (draft, action) => {
    draft.fetchJobDetailsCount4Status = action.payload
  })
  reducer.addCase(actions.setAddToPathLevel, (draft, action) => {
    draft.addToPathLevel = action.payload
  })
  reducer.addCase(actions.setJobTitleCount, (draft, action) => {
    draft.jobTitleCounts = action.payload
  })
  reducer.addCase(actions.setIsSwiperVisible, (draft, action) => {
    draft.isSwiperVisible = action.payload
  })
  reducer.addCase(actions.setIsEditingNewAdded, (draft, action) => {
    draft.isEditingNewAdded = action.payload
  })
  reducer.addCase(actions.setSelectedJobTitle, (draft, action) => {
    draft.selectedJobTitle = action.payload
  })
  reducer.addCase(actions.setIsExploreCareers, (draft, action) => {
    draft.isExploreCareers = action.payload
  })
  reducer.addCase(actions.setExploreCareersOccupation, (draft, action) => {
    draft.exploreCareersOccupation = action.payload
  })
  reducer.addCase(actions.setDeletePathsModalStatus, (draft, action) => {
    draft.isDeletePathsModalOpen = action.payload
  })
  reducer.addCase(actions.setPathsCardIdToDelete, (draft, action) => {
    draft.pathsCardIdToDelete = action.payload
  })
  reducer.addCase(actions.deletePaths, (draft, action) => {
    draft.allCareerCards = draft.allCareerCards.filter(card => card.id !== action.payload.id)
  })
  reducer.addCase(actions.setResumeSkills, (draft, action) => {
    draft.resumeSkills = action.payload
  })
  reducer.addCase(actions.setCourses, (draft, action) => {
    draft.courses = action.payload
  })
  reducer.addCase(actions.setFetchCoursesStatus, (draft, action) => {
    draft.fetchCoursesStatus = action.payload
  })
  reducer.addCase(actions.setLocation, (draft, action) => {
    draft.location = action.payload
  })
  reducer.addCase(actions.setExploreCareersLocation, (draft, action) => {
    draft.exploreCareersLocation = action.payload
  })
  reducer.addCase(actions.setSalaryDetails, (draft, action) => {
    draft.salaryDetails = action.payload
  })
  reducer.addCase(actions.setFetchSalaryStatus, (draft, action) => {
    draft.fetchSalaryStatus = action.payload
  })
  reducer.addCase(actions.setCareerPagesJobDetails, (draft, action) => {
    draft.careerPagesJobDetails = action.payload
  })
  reducer.addCase(actions.setFetchCareerPagesJobDetailsStatus, (draft, action) => {
    draft.fetchCareerPagesJobDetailsStatus = action.payload
  })
  reducer.addCase(actions.setFetchRecommendedJobCard, (draft, action) => {
    draft.recommendedJobCard = action.payload
  })
  reducer.addCase(actions.setJobCardStatus, (draft, action) => {
    draft.fetchJobCardStatus = action.payload
  })
  reducer.addCase(actions.setJobsData, (draft, action) => {
    draft.wishlistJobCard = sortBy(action.payload, ['positionNumber'])
  })
  reducer.addCase(actions.deleteJob, (draft, action) => {
    draft.wishlistJobCard = draft.wishlistJobCard.filter(job => job.id !== action.payload.id)
  })
  reducer.addCase(actions.addJob, (draft, action) => {
    const externalSlugId = action.payload.externalSlugId

    if (externalSlugId) {
      const filteredJobs = draft.wishlistJobCard.filter(
        job => job.externalSlugId !== externalSlugId,
      )
      draft.wishlistJobCard = sortBy([action.payload, ...filteredJobs], ['positionNumber'])
    } else {
      draft.wishlistJobCard = sortBy([action.payload, ...draft.wishlistJobCard], ['positionNumber'])
    }
  })
  reducer.addCase(actions.setJobDemand, (draft, action) => {
    draft.jobDemand = action.payload
  })
  reducer.addCase(actions.setJobDemandStatus, (draft, action) => {
    draft.fetchJobDemandStatus = action.payload
  })
  reducer.addCase(actions.setFetchJobTitlesStatus, (draft, action) => {
    draft.fetchJobTitlesStatus = action.payload
  })
  reducer.addCase(actions.setActiveTab, (draft, action) => {
    draft.activeTab = action.payload
  })
})
