export const eduDegreeOptions = [
  { id: 1, name: "Bachelor's Degree", key: "Bachelor's degree" },
  { id: 2, name: "Master's Degree", key: "Master's degree" },
  { id: 3, name: 'Other', key: 'Other' },
]

export const eduDegreeOptionsForOfferAnalyzer = [
  { id: 1, name: "Bachelor's Degree", key: 'Bachelor' },
  { id: 2, name: "Master's Degree", key: 'Master' },
]

export function getDegreeNameById(id: number) {
  const degree = eduDegreeOptionsForOfferAnalyzer.find(degree => degree.id === id)
  return degree ? degree.name : ''
}

export function getDegreeIdByName(name: string) {
  const degree = eduDegreeOptionsForOfferAnalyzer.find(degree => degree.name === name)
  return degree ? degree.id : -1
}

export function getDegreeKeyByName(name: string) {
  const degree = eduDegreeOptionsForOfferAnalyzer.find(degree => degree.name === name)
  return degree ? degree.key : ''
}
