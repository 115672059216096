import styled from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import { DocumentImagePreview } from 'builder/components/DocumentImagePreview/DocumentImagePreview'
import Shadows from 'builder/styles/shadows'

export const ResumeGradient = styled.div`
  background: linear-gradient(
    to bottom,
    ${hexToRgba(Colors.Blue10, 0)},
    ${Colors.Blue10} 100%,
    ${Colors.Blue10} 0%
  );
  position: absolute;
  bottom: 0;
  width: 54%;
  height: 70px;

  ${Media.Phone`
    bottom: -4px;
  `};
`
export const ResumeContainer = styled.div`
  padding-top: 32px;
  max-width: 284px;
  width: 100%;
  flex: 0 0 284px;

  ${Media.Phone`
    flex:0 0 264px;   
  `};

  ${Media.Phone`
    margin: 5px 0 24px 0;
    max-height:200px;
    height: 100%;
  `};
`
export const ResumeImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  height: 100%;
`
export const ResumeImage = styled(DocumentImagePreview)`
  ${Shadows.template.high};
  border-radius: 3px;
  max-width: 151px;
  max-height: 176px;
  width: 100%;
  height: 100%;
`
export const ResumeScoreContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
`
export const Score = styled.div<{ bgColor: string }>`
  min-width: 42px;
  height: 28px;
  ${Typography.Body};
  ${FontWeights.DemiBold};
  display: flex;
  align-items: center;
  color: ${Colors.Neutral10};
  flex: none;
  order: 0;
  flex-grow: 0;
  background: ${props => props.bgColor};
  border-radius: 6px;
  padding: 2px 6px;
`

export const Text = styled.div`
  border-radius: nullpx;
  ${Typography.Tiny};
  ${FontWeights.DemiBold};
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  color: ${Colors.Blue80};
  width: 53px;
  text-align: left;
  opacity: 0.6;
  margin-top: 8px;
`
