import { memo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Icon24 } from 'builder/components/Icon'
import Colors from 'builder/styles/colors'
import { i18n as I18n } from 'builder/utils/i18n'
import { apiClient } from 'builder/modules/apiClient'

import {
  CardDescription,
  CardSubtitle,
  CardTitle,
  ImageWrapper,
  FeatureImage,
  IconWrapper,
  CardLink,
  InfoContainer,
} from '../styles'
import CardWrapper from './CardWrapper'

export type FeatureObject = {
  featureTitle: string
  featureDesc: string
  featureIcon: string
  featureLink: string
}

type Props = {
  featureItem: FeatureObject
}

const FeatureCard = (props: Props) => {
  const { featureItem } = props
  const navigate = useNavigate()

  const arrowStyles = {
    fill: Colors.Blue50,
  }

  return (
    <CardWrapper>
      <InfoContainer>
        <ImageWrapper>
          <FeatureImage src={featureItem.featureIcon} alt={featureItem.featureTitle} />
        </ImageWrapper>
        <CardDescription>
          <CardTitle>{featureItem.featureTitle}</CardTitle>
          <CardSubtitle>{featureItem.featureDesc}</CardSubtitle>
        </CardDescription>
      </InfoContainer>
      <IconWrapper
        onClick={async () => {
          if (featureItem.featureLink !== '/') {
            const { data } = await apiClient.post('/career-identity/activate', {
              redirect_to: featureItem.featureLink,
            })
            window.open(data.loginLink, '_self')
          } else {
            navigate('/')
          }
        }}
      >
        <CardLink>
          {I18n.t('builder.subscription_cancellation.offered_feature_dialog.feature_card_link')}
        </CardLink>
        <Icon24.ToggleArrow style={arrowStyles} />
      </IconWrapper>
    </CardWrapper>
  )
}

export default memo(FeatureCard)
