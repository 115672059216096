export const GroupOne = () => (
  <svg width="127" height="21" viewBox="0 0 127 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 337625">
      <rect
        id="Rectangle 5380"
        x="30.1937"
        y="7.68848"
        width="96.4504"
        height="5.01041"
        rx="1.2526"
        fill="#E7F4ED"
      />
      <path
        id="Rectangle 5463"
        d="M0.132935 10.1917C0.132935 4.65738 4.61941 0.170898 10.1538 0.170898C15.6881 0.170898 20.1746 4.65738 20.1746 10.1917C20.1746 15.7261 15.6881 20.2125 10.1538 20.2125C4.61941 20.2125 0.132935 15.7261 0.132935 10.1917Z"
        fill="#96D0AD"
      />
      <path
        id="Vector 281"
        d="M6.39807 10.1937L8.90328 12.6989L13.9137 7.68848"
        stroke="white"
        strokeWidth="2.5052"
        strokeLinecap="round"
      />
    </g>
  </svg>
)

export const GroupTwo = () => (
  <svg width="160" height="28" viewBox="0 0 160 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 337624">
      <g id="Group 337617">
        <rect
          id="Rectangle 5380"
          x="30.1987"
          y="0.246094"
          width="129.018"
          height="6.26301"
          rx="1.2526"
          fill="#E7F4ED"
        />
        <rect
          id="Rectangle 5382"
          x="30.1986"
          y="11.5176"
          width="112.734"
          height="5.01041"
          rx="1.2526"
          fill="#E7F4ED"
        />
        <rect
          id="Rectangle 5381"
          x="30.1986"
          y="22.7891"
          width="60.1249"
          height="5.01041"
          rx="1.2526"
          fill="#E7F4ED"
        />
      </g>
      <rect
        id="Rectangle 5464"
        x="0.132935"
        y="4.00977"
        width="20.0416"
        height="20.0416"
        rx="10.0208"
        fill="#96D0AD"
      />
      <path
        id="Vector 280"
        d="M6.39856 14.0325L8.90376 16.5378L13.9142 11.5273"
        stroke="white"
        strokeWidth="2.5052"
        strokeLinecap="round"
      />
    </g>
  </svg>
)

export const GroupThree = () => (
  <svg width="136" height="21" viewBox="0 0 136 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 337623">
      <g id="Group 337618">
        <rect
          id="Rectangle 5380"
          x="30.199"
          y="2.07812"
          width="105.219"
          height="5.01041"
          rx="1.2526"
          fill="#EFF2F9"
        />
        <rect
          id="Rectangle 5381"
          x="30.199"
          y="12.1035"
          width="60.1249"
          height="6.26301"
          rx="1.2526"
          fill="#EFF2F9"
        />
      </g>
      <rect
        id="Rectangle 5465"
        x="0.132935"
        y="0.833008"
        width="20.0416"
        height="20.0416"
        rx="10.0208"
        fill="#EFF2F9"
      />
    </g>
  </svg>
)

export const GroupFour = () => (
  <svg width="136" height="21" viewBox="0 0 136 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 337622">
      <g id="Group 337619">
        <rect
          id="Rectangle 5380"
          x="30.199"
          y="2.15234"
          width="105.219"
          height="5.01041"
          rx="1.2526"
          fill="#EFF2F9"
        />
        <rect
          id="Rectangle 5381"
          x="30.199"
          y="13.4238"
          width="60.1249"
          height="5.01041"
          rx="1.2526"
          fill="#EFF2F9"
        />
      </g>
      <rect
        id="Rectangle 5466"
        x="0.132935"
        y="0.90625"
        width="20.0416"
        height="20.0416"
        rx="10.0208"
        fill="#EFF2F9"
      />
    </g>
  </svg>
)

export const GroupFive = () => (
  <svg width="136" height="22" viewBox="0 0 136 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 337621">
      <g id="Group 337620">
        <rect
          id="Rectangle 5380"
          x="30.1992"
          y="2.23438"
          width="105.219"
          height="6.26301"
          rx="1.2526"
          fill="#EFF2F9"
        />
        <rect
          id="Rectangle 5381"
          x="30.1991"
          y="13.5049"
          width="60.1249"
          height="6.26301"
          rx="1.2526"
          fill="#EFF2F9"
        />
      </g>
      <rect
        id="Rectangle 5467"
        x="0.132935"
        y="0.989258"
        width="20.0416"
        height="20.0416"
        rx="10.0208"
        fill="#EFF2F9"
      />
    </g>
  </svg>
)

export const GroupOneTablet = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="101" height="17" viewBox="0 0 101 17" fill="none">
    <rect x="24.012" y="6.76733" width="76.8589" height="3.99267" rx="0.998167" fill="#E7F4ED" />
    <path
      d="M0.057312 8.7622C0.057312 4.35202 3.63247 0.776855 8.04265 0.776855C12.4528 0.776855 16.028 4.35202 16.028 8.7622C16.028 13.1724 12.4528 16.7475 8.04265 16.7475C3.63247 16.7475 0.057312 13.1724 0.057312 8.7622Z"
      fill="#96D0AD"
    />
    <path
      d="M5.04999 8.76367L7.04632 10.76L11.039 6.76733"
      stroke="white"
      strokeWidth="1.99633"
      strokeLinecap="round"
    />
  </svg>
)

export const GroupTwoTablet = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="127" height="23" viewBox="0 0 127 23" fill="none">
    <rect x="24.0167" y="0.727295" width="102.811" height="4.99084" rx="0.998167" fill="#E7F4ED" />
    <rect x="24.0165" y="9.70923" width="89.8351" height="3.99267" rx="0.998167" fill="#E7F4ED" />
    <rect x="24.0165" y="18.6912" width="47.912" height="3.99267" rx="0.998167" fill="#E7F4ED" />
    <rect x="0.0574341" y="3.72656" width="15.9707" height="15.9707" rx="7.98534" fill="#96D0AD" />
    <path
      d="M5.05054 11.7134L7.04687 13.7097L11.0395 9.71704"
      stroke="white"
      strokeWidth="1.99633"
      strokeLinecap="round"
    />
  </svg>
)

export const GroupThreeTablet = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="108" height="17" viewBox="0 0 108 17" fill="none">
    <rect x="24.0167" y="1.65601" width="83.8461" height="3.99267" rx="0.998167" fill="#EFF2F9" />
    <rect x="24.0165" y="9.64502" width="47.912" height="4.99084" rx="0.998167" fill="#EFF2F9" />
    <rect x="0.057312" y="0.663818" width="15.9707" height="15.9707" rx="7.98534" fill="#EFF2F9" />
  </svg>
)

export const GroupFourTablet = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="108" height="17" viewBox="0 0 108 17" fill="none">
    <rect x="24.0167" y="1.60571" width="83.8461" height="3.99267" rx="0.998167" fill="#EFF2F9" />
    <rect x="24.0165" y="10.5876" width="47.912" height="3.99267" rx="0.998167" fill="#EFF2F9" />
    <rect x="0.057312" y="0.612793" width="15.9707" height="15.9707" rx="7.98534" fill="#EFF2F9" />
  </svg>
)

export const GroupFiveTablet = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="108" height="17" viewBox="0 0 108 17" fill="none">
    <rect x="24.0167" y="1.56152" width="83.8461" height="4.99084" rx="0.998167" fill="#EFF2F9" />
    <rect x="24.0165" y="10.5427" width="47.912" height="4.99084" rx="0.998167" fill="#EFF2F9" />
    <rect x="0.057312" y="0.56958" width="15.9707" height="15.9707" rx="7.98534" fill="#EFF2F9" />
  </svg>
)

export const GroupOnePhone = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="9" viewBox="0 0 50 9" fill="none">
    <rect x="12.0498" y="3.41187" width="37.7876" height="1.96299" rx="0.490748" fill="#E7F4ED" />
    <path
      d="M0.273438 4.39247C0.273438 2.22421 2.03116 0.466492 4.19942 0.466492C6.36768 0.466492 8.1254 2.22421 8.1254 4.39247C8.1254 6.56073 6.36768 8.31845 4.19942 8.31845C2.03116 8.31845 0.273438 6.56073 0.273438 4.39247Z"
      fill="#96D0AD"
    />
    <path
      d="M2.72754 4.39336L3.70903 5.37486L5.67202 3.41187"
      stroke="white"
      strokeWidth="0.981495"
      strokeLinecap="round"
    />
  </svg>
)

export const GroupTwoPhone = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="63" height="11" viewBox="0 0 63 11" fill="none">
    <rect x="12.0527" y="0.20697" width="50.547" height="2.45374" rx="0.490748" fill="#E7F4ED" />
    <rect x="12.0527" y="4.62274" width="44.1673" height="1.96299" rx="0.490748" fill="#E7F4ED" />
    <rect x="12.0527" y="9.03851" width="23.5559" height="1.96299" rx="0.490748" fill="#E7F4ED" />
    <rect x="0.273438" y="1.68201" width="7.85196" height="7.85196" rx="3.92598" fill="#96D0AD" />
    <path
      d="M2.72754 5.60888L3.70903 6.59037L5.67202 4.62738"
      stroke="white"
      strokeWidth="0.981495"
      strokeLinecap="round"
    />
  </svg>
)

export const GroupThreePhone = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="54" height="9" viewBox="0 0 54 9" fill="none">
    <rect x="12.0527" y="1.38153" width="41.2228" height="1.96299" rx="0.490748" fill="#EFF2F9" />
    <rect x="12.0527" y="5.30872" width="23.5559" height="2.45374" rx="0.490748" fill="#EFF2F9" />
    <rect x="0.273438" y="0.892944" width="7.85196" height="7.85196" rx="3.92598" fill="#EFF2F9" />
  </svg>
)

export const GroupFourPhone = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="54" height="9" viewBox="0 0 54 9" fill="none">
    <rect x="12.0527" y="1.12207" width="41.2228" height="1.96299" rx="0.490748" fill="#EFF2F9" />
    <rect x="12.0527" y="5.53784" width="23.5559" height="1.96299" rx="0.490748" fill="#EFF2F9" />
    <rect x="0.273438" y="0.633484" width="7.85196" height="7.85196" rx="3.92598" fill="#EFF2F9" />
  </svg>
)

export const GroupFivePhone = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="54" height="9" viewBox="0 0 54 9" fill="none">
    <rect x="12.0527" y="0.86731" width="41.2228" height="2.45374" rx="0.490748" fill="#EFF2F9" />
    <rect x="12.0527" y="5.28302" width="23.5559" height="2.45374" rx="0.490748" fill="#EFF2F9" />
    <rect x="0.273438" y="0.378662" width="7.85196" height="7.85196" rx="3.92598" fill="#EFF2F9" />
  </svg>
)
