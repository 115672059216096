import { queryClient } from 'builder/components/Providers/Providers'

export const invalidateCacheForInterviews = () => {
  return Promise.all([
    queryClient.invalidateQueries(['useInterviews']),
    queryClient.invalidateQueries(['useInterview']),
    queryClient.invalidateQueries(['useDailySuggestions']),
    queryClient.invalidateQueries(['useDownloadS3Asset']),
  ])
}
