import { FormEvent, memo, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { indexOfCaseInsensitive } from 'builder/utils/arrayUtils'
import { i18n as I18n } from 'builder/utils/i18n'
import { actions } from 'builder/modules/resumeEditor'
import { useAppSelector } from 'builder/hooks/useAppSelector'
import { TextField } from 'builder/components/TextField'
import { AsyncAutosuggest } from 'builder/components/AsyncAutosuggest'
import { AIResumeDraftEventLabels, AIResumeDraftEvents } from 'builder/modules/constants'
import { Div } from '../../styles'
import {
  SelectedSkillTag,
  SkillTagsContainer,
  SelectedSkillsTitle,
  SkillsContainer,
  RemoveSkillIcon,
} from './styles'
import SuggestedSkills from './SuggestedSkills'

type SelectedType = {
  suggestionValue: string
}

export const Skills = memo(() => {
  const dispatch = useDispatch()

  useEffect(() => {
    trackInternalEvent(AIResumeDraftEvents.seeSkillsStep, {
      label: AIResumeDraftEventLabels.aiResume,
    })
  }, [])

  const { suggestedSkills, currentSkills } = useAppSelector(
    state => state.resumeEditor.aiResume.questionnaireData.userInput,
  )

  const [inputValue, setInputValue] = useState<string>('')

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setInputValue(value)
  }

  const handleUpdateSkills = (selectedValue: string) => {
    const trimmedValue = selectedValue.trim()
    if (trimmedValue !== '') {
      dispatch(actions.updateCurrentSkills(trimmedValue))
      dispatch(actions.removeFromSuggestedSkills(trimmedValue))
      if (indexOfCaseInsensitive(currentSkills, trimmedValue) < 0) {
        trackInternalEvent(AIResumeDraftEvents.addSkill, {
          label: AIResumeDraftEventLabels.skills,
        })
        if (indexOfCaseInsensitive(suggestedSkills, trimmedValue) > -1) {
          trackInternalEvent(AIResumeDraftEvents.addSuggestedSkill, {
            label: AIResumeDraftEventLabels.skills,
          })
        }
      }
    }
    setInputValue('')
  }

  const handleInputKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleUpdateSkills(inputValue)
    }
  }

  const removeSkillHandler = (skill: string) => {
    dispatch(actions.removeFromCurrentSkills(skill))
  }

  const onSuggestionSelected = (e: FormEvent, selected: SelectedType) => {
    handleUpdateSkills(selected.suggestionValue)
  }

  const fetchSuggestedSkills = async (query: string) => {
    const inputValue = query.trim().toLowerCase()
    const inputLength = query.length

    return inputLength === 0
      ? [] // Show no suggestions when the input is empty
      : suggestedSkills
          .filter(suggestion => suggestion.toLowerCase().includes(inputValue))
          .map(item => ({ text: item }))
  }

  return (
    <SkillsContainer>
      {currentSkills.length > 0 && (
        <SkillTagsContainer data-testid="selected-skills">
          <SelectedSkillsTitle>
            {I18n.t('builder.ai_resume_draft.skills_screen.selected_skills')}
          </SelectedSkillsTitle>
          {currentSkills.map((item, index) => (
            <SelectedSkillTag key={index}>
              <Div>{item} </Div>
              <Div data-testid="remove-skill" onClick={() => removeSkillHandler(item)}>
                <RemoveSkillIcon />
              </Div>
            </SelectedSkillTag>
          ))}
        </SkillTagsContainer>
      )}
      <AsyncAutosuggest
        highlightedQuery
        name="title"
        fetchItems={fetchSuggestedSkills}
        value={inputValue}
        onChange={handleInputChange}
        onSuggestionSelected={onSuggestionSelected}
      >
        {inputProps => (
          <TextField
            {...inputProps}
            label={I18n.t('builder.ai_resume_draft.skills_screen.skills')}
            onKeyUp={handleInputKeyUp}
          />
        )}
      </AsyncAutosuggest>
      {suggestedSkills.length > 0 && <SuggestedSkills {...{ suggestedSkills }} />}
    </SkillsContainer>
  )
})
