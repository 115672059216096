import { Filter, HeadersType, Content } from 'builder/modules/careerPlanning'
import { getSearchParams } from 'builder/modules/careerPlanning/utils'

const { cpTopLevelId, cpSecondLevelId } = getSearchParams()

const clearSearchParams = () => {
  const newURL = window.location.href.split('?')[0]
  window.history.replaceState({}, '', newURL)
}

const getValue = (status: string, index: number) => {
  if (status === 'open') {
    return index !== 0
  }
  return index !== 1
}

const getContentsToFilter = (
  activeFilter: string,
  openContents: Content[],
  completedContents: Content[],
) => {
  // check for 'open' or 'completed' only
  if (activeFilter === 'open') return openContents
  else if (activeFilter === 'completed') return completedContents
}

export const getSecondLevelIds = (topLevelId: number, headers: HeadersType[]) => {
  return headers.map(header => {
    if (header.id === topLevelId) return header.secondLevel.map(level => level.id)

    return null
  })
}

export const getUpdatedFilterOptions = (filterOptions: Filter[], status: string) => {
  const updatedFilterOptions = [...filterOptions]

  if (status === 'open' || status === 'completed') {
    updatedFilterOptions[0] = {
      ...updatedFilterOptions[0],
      isActive: getValue(status, 0),
    }
    updatedFilterOptions[1] = {
      ...updatedFilterOptions[1],
      isActive: getValue(status, 1),
    }
  }

  return updatedFilterOptions
}

export const onTopLevelClickUpdateFilters = (
  activeFilter: string,
  filterOptions: Filter[],
  secondLevelIds: (number | null)[],
  openContents: Content[],
  completedContents: Content[],
) => {
  if (cpTopLevelId || cpSecondLevelId) {
    clearSearchParams()
  }

  const contentsToFilter = getContentsToFilter(activeFilter, openContents, completedContents)

  const filteredContents = contentsToFilter?.filter(content => secondLevelIds?.includes(content.id))

  if (!filteredContents?.length) {
    const updatedFilterOptions = getUpdatedFilterOptions(filterOptions, activeFilter)
    return updatedFilterOptions
  }
}

export const onSecondLevelClickUpdateFilters = (
  activeFilter: string,
  filterOptions: Filter[],
  secondLevelId: number,
  openContents: Content[],
  completedContents: Content[],
) => {
  if (cpTopLevelId || cpSecondLevelId) {
    clearSearchParams()
  }

  const contentsToFilter = getContentsToFilter(activeFilter, openContents, completedContents)

  const filteredContents = contentsToFilter?.filter(content => content.id === secondLevelId)

  if (!filteredContents?.length) {
    const updatedFilterOptions = getUpdatedFilterOptions(filterOptions, activeFilter)
    return updatedFilterOptions
  }
}
