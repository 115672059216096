import { memo, useEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { i18n as I18n } from 'builder/utils/i18n'
import Button from 'builder/components/Button'
import {
  FeaturesLayout,
  ViewLayoutContainer,
  Title,
  ExtraInfoDesc,
  BottomContainer,
  ExtraInfoContainer,
  ButtonContainer,
  CancelSubcriptionText,
} from '../styles'
import CardLayout from '../CardLayout/CardLayout'
import LimitedFeatureView from './LimitedFeatureView'

type Props = {
  onClose: () => void
  onContinueCancellation: () => void
}

const FeaturesContainer = (props: Props) => {
  const { onClose, onContinueCancellation } = props

  useEffect(() => {
    trackInternalEvent('see_loss_aversion_popup')
  }, [])

  return (
    <FeaturesLayout>
      <ViewLayoutContainer>
        <Title>{I18n.t('builder.subscription_cancellation.offered_feature_dialog.title')}</Title>
        <CardLayout />
        <ExtraInfoContainer>
          <ExtraInfoDesc>
            {I18n.t('builder.subscription_cancellation.offered_feature_dialog.amazing_tools_desc')}
          </ExtraInfoDesc>
        </ExtraInfoContainer>
        <BottomContainer>
          <Title>
            {I18n.t(
              'builder.subscription_cancellation.offered_feature_dialog.title_document_limited',
            )}
          </Title>
          <LimitedFeatureView />
          <ButtonContainer>
            <Button onClick={onClose}>
              {I18n.t(
                'builder.subscription_cancellation.offered_feature_dialog.stay_premium_button_text',
              )}
            </Button>
          </ButtonContainer>
          <CancelSubcriptionText onClick={onContinueCancellation}>
            {I18n.t(
              'builder.subscription_cancellation.offered_feature_dialog.continue_cancellation',
            )}
          </CancelSubcriptionText>
        </BottomContainer>
      </ViewLayoutContainer>
    </FeaturesLayout>
  )
}

export default memo(FeaturesContainer)
