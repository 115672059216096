import { ComponentPropsWithoutRef } from 'react'
import { Icon20 } from '../Icon'
import { CloseButton } from './styles'
import { ModalCloseProps } from './types'

type Props = ModalCloseProps & ComponentPropsWithoutRef<'button'>

export const ModalClose = (props: Props) => {
  return (
    <CloseButton {...props}>
      <Icon20.Close />
    </CloseButton>
  )
}
