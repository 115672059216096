import styled, { keyframes } from 'styled-components'
import Colors from 'builder/styles/colors'

const leftMove = keyframes`
  0%   {background-color: ${Colors.White}; top:0px;}
  16%  {background-color: ${Colors.Blue20}; top:20px;}
  32%  {background-color: ${Colors.Blue20}; top:20px;}
  48%  {background-color: ${Colors.Blue20}; top:20px;}
  64%  {background-color: ${Colors.Blue20}; top:40px;}
  80%  {background-color: ${Colors.Blue20}; top:40px; 
  width: 23px; border-top-right-radius: 4px; border-bottom-right-radius: 4px;}
  100% {background-color: ${Colors.Blue20}; top:40px; 
  width: 28px; border-top-right-radius: 0; border-bottom-right-radius: 0;}
`
const rightMove = keyframes`
  0%   {background-color: ${Colors.White}; top:0px; margin-left: 10px;}
  16%  {background-color: ${Colors.White}; top:0px; margin-left: 10px;}
  32%  {background-color: ${Colors.Blue40}; top:20px; margin-left: 10px;}
  48%  {background-color: ${Colors.Blue40}; top:40px; margin-left: 10px;}
  64%  {background-color: ${Colors.Blue40}; top:40px; margin-left: 10px;}
  80%  {background-color: ${Colors.Blue40}; top:40px; margin-left: 10px; 
  width: 23px; border-top-left-radius: 4px; border-bottom-left-radius: 4px; }
  100% {background-color: ${Colors.Blue20}; top:40px; margin-left:0px; 
  width: 28px; border-top-left-radius: 0; border-bottom-left-radius: 0;}
`
export const Square = styled.div`
  position: relative;
  width: 23px;
  height: 23px;
  border-radius: 4px;
`

export const Animation = styled.div`
  display: flex;
  height: 64px;
`

export const LeftSquare = styled(Square)`
  animation: 2s ${leftMove} ease-in-out;
  animation-iteration-count: infinite;
`

export const RightSquare = styled(Square)`
  animation: 2s ${rightMove} ease-in-out;
  animation-iteration-count: infinite;
`
