import { useState } from 'react'
import { selectors as userSelectors } from 'builder/modules/user'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import playImage from 'builder/components/CIO-Dasboard-2.0/assets/Play.svg'
import * as Styled from './styles'

export const PrepareForInterviews = () => {
  const userPhotoUrl = useTypedSelector(userSelectors.photoUrl)
  const [isHovered, setIsHovered] = useState(true)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }
  return (
    <Styled.Container
      onMouseOver={handleMouseEnter}
      onMouseOut={handleMouseLeave}
      onHover={isHovered}
    >
      <Styled.VisualImageContainer>
        <Styled.VisualImage src={userPhotoUrl} />
        <Styled.PlayImage src={playImage} />
      </Styled.VisualImageContainer>
    </Styled.Container>
  )
}
