import styled, { keyframes } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'

const fadeIn = keyframes`
  from { opacity: 0; transform: scale(0.8); }
  to   { opacity: 1; transform: scale(1); }
`

export const ButtonContainer = styled.div`
  position: fixed;
  z-index: 10;
  bottom: calc(40px + 60px + 16px);
  right: 40px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: ${Colors.Neutral90};
  background-image: url(${require(`images/builder/panel/tuner/floating-button.svg`)});
  background-position: 50% 50%;
  background-size: 32px auto;
  background-repeat: no-repeat;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.1);
  color: ${Colors.White};
  transition: background-color 0.2s, transform 0.2s linear;
  cursor: pointer;
  animation: ${fadeIn} 0.3s ease-out;
  user-select: none;

  &:hover {
    background-color: ${Colors.Neutral100};
  }

  &:active {
    transform: scale(0.96);
  }

  @media (max-height: 740px) {
    right: 20px;
    bottom: calc(10px + 60px + 12px);
  }
`

export const ButtonCounter = styled.div`
  position: absolute;
  top: -2px;
  right: -2px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${Colors.Red50};
  ${Typography.Caps};
  letter-spacing: 0;
  font-weight: 600;
`
