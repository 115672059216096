import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Container = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;

  ${Media.Phone`
    gap: 20px;
  `};

  ${Media.Tablet`
    padding-inline: 8px;
  `}
`

export const Header = styled.p`
  padding: 8px 0 8px;
  ${Typography.S};
  ${FontWeights.Medium};
`
