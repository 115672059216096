import React from 'react'
import * as Styles from './styles'
import { ControlWithLabel } from './components/ControlWithLabel'

type RadioGroupProps = {
  value: string | null
  onChange: (value: string) => void
  children: React.ReactElement[]
}

export const RadioGroup = ({ value, onChange, ...props }: RadioGroupProps) => {
  const onChangeHandler = (value: string) => {
    onChange(value)
  }

  return (
    <Styles.Wrapper {...props}>
      {React.Children.map(props.children, child =>
        React.cloneElement(child, {
          checked: value?.toString() === child.props.value,
          onChange: onChangeHandler,
        }),
      )}
    </Styles.Wrapper>
  )
}

RadioGroup.Control = ControlWithLabel
