import { useLayoutEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useClickOutside } from 'builder/hooks/useClickOutside'
import { useEscapeHandler } from 'builder/hooks/useEscapeHandler'
import { usePortalNode } from 'builder/hooks/usePortalNode'
import { useBodyScrollLock } from 'builder/hooks/useBodyScrollLock'
import { reflow } from 'builder/utils/reflow'
import { ITabsAction } from 'builder/components/CIO-Dasboard-2.0/types'
import * as Styled from './styles'

interface IMobileTabsMenuProps {
  tabsActions: ITabsAction[]
  onClose: () => void
  selectedIndex: number
  handleTabClick: (arg: number) => void
}

export const MobileTabsMenu = ({
  onClose,
  tabsActions,
  selectedIndex,
  handleTabClick,
}: IMobileTabsMenuProps) => {
  const [isVisible, setIsVisible] = useState(false)
  const portalNode = usePortalNode()

  const overlayRef = useRef<HTMLDivElement | null>(null)
  const menuRef = useRef<HTMLDivElement | null>(null)

  useClickOutside(menuRef, onClose)
  useEscapeHandler(onClose)

  useLayoutEffect(() => {
    if (overlayRef.current) reflow(overlayRef.current)
    if (menuRef.current) reflow(menuRef.current)
    setIsVisible(true)
  }, [])

  // Disable page scrolling while mobile sheet is open
  useBodyScrollLock(overlayRef)

  return ReactDOM.createPortal(
    <Styled.Overlay ref={overlayRef} isMenuVisible={isVisible}>
      <Styled.MobileTabsMenuContainer ref={menuRef} isMenuVisible={isVisible}>
        {tabsActions.map((action, index) => (
          <Styled.Item key={index} onClick={() => handleTabClick(action.index)}>
            <Styled.ItemContent>
              <Styled.ItemText selected={action.index === selectedIndex}>
                {action.text}
              </Styled.ItemText>
              <Styled.SelectedIcon selected={action.index === selectedIndex} />
            </Styled.ItemContent>
          </Styled.Item>
        ))}
      </Styled.MobileTabsMenuContainer>
      <Styled.CancelButton isMenuVisible={isVisible}>Cancel</Styled.CancelButton>
    </Styled.Overlay>,
    portalNode,
  )
}
