import { Fragment } from 'react'
import { ResumeIDStatuses } from 'builder/modules/constants'
import { selectors as panelSelectors } from 'builder/modules/panel'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useI18n } from 'builder/hooks/useI18n'
import ResumeBanner from './components/ResumeBanner/index'
import WelcomeBanner from './components/WelcomeBanner/index'

const bannerImage = require('images/Career-foundation/career_foundation_loader.gif')

const CareerFoundationBanner = () => {
  const resumeStatus = useTypedSelector(panelSelectors.resumeStatus)
  const { i18n } = useI18n()

  return (
    <Fragment>
      {resumeStatus === ResumeIDStatuses.failed || resumeStatus === ResumeIDStatuses.done ? (
        <ResumeBanner />
      ) : (
        <WelcomeBanner
          title={i18n.t('builder.panel.career_foundation_welcome_banner.title')}
          subTitle={i18n.t('builder.panel.career_foundation_welcome_banner.text')}
          bannerImage={bannerImage}
        />
      )}
    </Fragment>
  )
}

export default CareerFoundationBanner
