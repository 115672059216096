import styled from 'styled-components'
import Button from 'builder/components/Button'
import { Icon20, Icon24 } from 'builder/components/Icon'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

export const Container = styled.div`
  margin: 32px auto;
  display: flex;
  gap: 128px;

  ${Media.Tablet`    
    flex-direction: column;
    gap: 32px;
    padding: 32px 0;
  `}

  ${Media.Phone`    
    flex-direction: column;
    padding: 20px;
    gap: 16px;
  `}

  ${Media.Tablet`
    height: calc(100vh - 80px);
    margin: auto;
    padding: 32px 0;
  `}

  ${Media.Phone`    
    height: calc(100vh - 170px);
    margin: auto;
    padding: 20px;
  `}
`

export const VideoContainer = styled.div`
  ${Media.Tablet`    
    margin-top: 32px;
  `}

  ${Media.Phone`    
    margin-top: 16px;
  `}
`

export const InfoBox = styled.div`
  width: 442px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${Media.Tablet` 
    height: 100%;
    width: 488px;
  `}

  ${Media.Phone`  
    height: 100%;
    width: 100%;
  `}
`

export const InfoBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 56px;

  ${Media.Tablet`    
    gap: 48px;
  `}

  ${Media.Tablet`    
    gap: 16px;
  `}
`

export const HorizontalDivider = styled.div`
  width: 54px;
  height: 1px;
  background: ${Colors.Neutral15};
`

export const InfoIcon = styled.img<{ $width?: number; $height?: number }>`
  width: ${({ $width }) => ($width ? `${$width}px` : '42px')};
  height: ${({ $height }) => ($height ? `${$height}px` : '48px')};
  margin-bottom: 16px;
`

export const InfoHeadContiner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const InfoHeader = styled.div`
  color: ${Colors.Neutral90};

  ${Typography.M}
  ${FontWeights.Medium}
`

export const InfoSubHeader = styled.div`
  color: ${Colors.Neutral50};

  ${Typography.Body}
  ${FontWeights.Regular}
`

export const InfoActions = styled.div`
  width: inherit;
  display: flex;
  align-items: center;

  ${Media.Phone`    
    flex-direction: column-reverse;
    gap: 24px;
  `}
`

export const InfoActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

export const InfoActionIcon = styled(Icon20.Time)`
  margin-top: -2px;
  margin-right: 2px;
  fill: white;
  color: ${Colors.Neutral50};
`

export const InfoActionDescription = styled.div`
  color: ${Colors.Neutral50};

  ${Typography.Caption}
  ${FontWeights.Regular}
`

export const InfoActionDescriptionBold = styled.span`
  color: ${Colors.Neutral90};

  ${Typography.Caption}
  ${FontWeights.Medium}
`

export const InfoActionStart = styled(Button)`
  margin-left: auto;

  ${Media.Phone`
    width: 100%;
    margin-left: unset;
  `}
`

export const StartButton = styled(Button)`
  margin-top: 32px;
  width: fit-content;

  ${Media.Phone`    
    margin-left: auto;
  `}
`

export const VideoPlayerContainer = styled.div`
  width: 544px;
  height: 544px;
  border-radius: 24px;
  border: 1px solid ${Colors.Neutral15};
  position: relative;
  overflow: hidden;

  &.fullscreen {
    border-radius: unset;
    border: unset;
  }

  ${Media.Tablet`    
    width: 488px;
    height: 300px;
  `}

  ${Media.Phone`    
    width: 100%;
    height: 209px;
  `}
`

export const Video = styled.video`
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;

  &.fullscreen {
    position: absolute;
    bottom: 10px;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    object-fit: contain;
  }
`

export const Controls = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: inherit;
  height: inherit;
  position: absolute;
  top: 0;
`

export const VideoButton = styled.button`
  display: flex;
  height: 32px;
  width: 32px;
  background: none;
  border: none;
  color: inherit;
  font-size: 1.5em;
  cursor: pointer;
  border-radius: 50%;
  align-items: center;
  background-color: ${Colors.White};

  box-shadow: 0px 0px 1px 0px rgba(15, 56, 113, 0.56), 0px 6px 8px -2px rgba(15, 56, 113, 0.02),
    0px 16px 64px -8px rgba(15, 56, 113, 0.18);
`

export const PlayButton = styled(VideoButton)`
  height: 56px;
  width: 56px;
  position: absolute;
  border-radius: 40px;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(15px);
  padding: 16px;
`

export const SoundButton = styled(VideoButton)`
  position: absolute;
  bottom: 24px;
  right: 64px;

  &.fullscreen {
    bottom: 32px;
    right: 72px;
  }
`

export const FullscreenButton = styled(VideoButton)`
  position: absolute;
  bottom: 24px;
  right: 24px;

  &.fullscreen {
    bottom: 32px;
    right: 32px;
  }
`

export const ExitFullscreenButton = styled(VideoButton)`
  position: absolute;
  top: 32px;
  right: 32px;
`

export const PlayIcon = styled(Icon20.Play)`
  color: ${Colors.White};
  width: 24px;
  height: 24px;
`
export const PauseIcon = styled(Icon20.Pause)`
  color: ${Colors.White};
  width: 24px;
  height: 24px;
`
export const SoundIcon = styled(Icon24.Sound)`
  color: ${Colors.Neutral50};
  padding: 1px;
`
export const MuteIcon = styled(Icon20.Mute)`
  color: ${Colors.Neutral50};
  margin-top: 4px;
  margin-left: 2px;
`
export const FullScreenIcon = styled(Icon24.FullSize)`
  height: 20px;
  color: ${Colors.Neutral50};
`
export const CloseFullScreenIcon = styled(Icon20.Expand)`
  height: 20px;
  color: ${Colors.Neutral50};
`
export const CloseIcon = styled(Icon24.Close)`
  color: ${Colors.Neutral50};
`
