import styled from 'styled-components'
import Media from 'builder/styles/media'

export const Wrapper = styled.div`
  position: fixed;
  width: 720px;
  display: flex;
  justify-content: center;
  left: calc(50% - 360px);
  bottom: 16px;
  z-index: 1500;
  pointer-events: none;

  ${Media.Tablet`
    width: 100%;
    left: 0;
    padding: 0 32px;
  `}

  ${Media.Phone`
    width: 100%;
    left: 0;
    padding: 0 20px;
  `}
`
