import { useI18n } from 'builder/hooks/useI18n'
import { useConfig } from 'builder/hooks/useConfig'
import { authService } from 'builder/services/AuthService'

import { FaqIcon, Link, LinkContainer, Links, LogOutIcon, SettingsIcon } from './styles'

export const Settings = () => {
  const { i18n } = useI18n()
  const config = useConfig()

  return (
    <Links>
      <LinkContainer>
        <SettingsIcon />
        <Link href="/app/account">{i18n.t('builder.navbar.account_settings')}</Link>
      </LinkContainer>

      {config?.links.updates && (
        <Link href={config.links.updates} target="_blank" rel="noreferrer noopener">
          {i18n.t('builder.navbar.updates')}
        </Link>
      )}

      {config?.links.help && (
        <LinkContainer>
          <FaqIcon />
          <Link href={config.links.help} target="_blank" rel="noreferrer noopener">
            {i18n.t('builder.navbar.faq')}
          </Link>
        </LinkContainer>
      )}

      {/* TODO: Change logic during the migration to JWT-auth */}
      <LinkContainer>
        <LogOutIcon />
        <Link as="button" onClick={() => authService.logout()}>
          {i18n.t('builder.navbar.log_out')}
        </Link>
      </LinkContainer>
    </Links>
  )
}
