import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { TooltipQuestion } from 'builder/components/Tooltip/variations'
import { HorizontalTransitionFade } from 'builder/components/HorizontalTransitionFade'
import { Container as BaseContainer } from 'builder/components/Container'
import Icon from 'builder/components/Icon'
import Shadows from 'builder/styles/shadows'
import Media from 'builder/styles/media'
import Button from 'builder/components/Button'
import { CardContainer } from '../../styles'

export const Column = styled(HorizontalTransitionFade)``

export const DesktopContainer = styled(BaseContainer)`
  margin-top: 40px;
  display: flex;
  gap: 32px;
`

export const TabletContainer = styled(BaseContainer)`
  margin-top: 40px;

  ${Media.Phone`
    margin-top: 24px;
  `}

  & .swiper-container {
    position: relative;
    overflow: visible;
  }

  & .swiper-wrapper {
    display: flex;
    position: relative;
    transition-property: transform;
  }

  & .swiper-slide:not(:last-child) {
    margin-right: 32px;

    ${Media.Phone`
      margin-right: 24px;
      margin-bottom: 90px;
    `}
  }

  & .career-matrix-scrollbar {
    width: 100%;
    border-radius: 10px;
    background: ${Colors.Neutral15};
  }

  & .swiper-scrollbar-drag {
    height: 2px;
    width: 100%;
    position: relative;
    background: ${Colors.Blue50};
    border-radius: 10px;
    left: 0;
    top: 0;
  }

  & .swiper-scrollbar-cursor-drag {
    cursor: move;
  }
`

export const ScrollbarContainer = styled.div`
  width: 100%;
  margin-left: -40px;
  padding: 20px 40px 42px 40px;
  background: ${Colors.White};
  position: fixed;
  bottom: 0;
  z-index: 2;
  height: 64px;
  align-items: center;
  display: flex;
  flex-direction: column;

  ${Media.Tablet`
     margin-left: -20px;
     padding-left: 20px;
     padding-right: 20px;
  `}
`

export const DesktopOccupationsContainer = styled.div`
  display: flex;
  gap: 32px;
  position: relative;
`

export const ColumnHeader = styled.div`
  color: ${Colors.Neutral50};
  margin-bottom: 24px;
  display: flex;
  align-items: center;

  ${Media.Phone`
    margin-bottom: 16px;
  `}
`

export const CurrentCard = styled(CardContainer)`
  ${Shadows.light.low};

  &:hover {
    ${Shadows.light.mid};
  }
`

export const CurrenCardIcon = styled(Icon.Edit)`
  color: ${Colors.Blue60};
`

export const HintContainer = styled(TooltipQuestion)<typeof TooltipQuestion>`
  color: ${Colors.Neutral40};
  width: 18px;
  height: 18px;
  margin-left: 5px;

  &:hover {
    color: ${Colors.Neutral60};
  }
`

export const ButtonContainer = styled.div`
  position: fixed;
  bottom: 104px;
  right: 20px;

  ${Media.Phone`
    text-align: center;
    width: calc(100% - 40px);
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    bottom: 76px;
    right: 20px;
  `}
`

export const FormButton = styled(Button)`
  ${Media.Phone`
    width: 100%;
  `}
`
