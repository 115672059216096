import { all, put, call, takeLatest } from 'redux-saga/effects'
import { trackInternalEvent } from '@rio/tracking'

import { apiClient } from 'builder/modules/apiClient'
import ErrorLogger from 'builder/services/ErrorLogger'
import { FetchStatuses } from 'builder/modules/constants'

import { actions } from './uploadResumeModule'
import { UploadResumeStep } from './constants'

export function* fetchResumeDetailsSaga({ payload }: ReturnType<typeof actions.parsedDetails>) {
  try {
    yield put(actions.setparsingStatus(FetchStatuses.loading))

    // change baseURL to /api
    const {
      data: { data: resumeDetails },
    } = yield call(apiClient.post, `/post-cv`, payload, {
      baseURL: '/api',
      headers: {
        'content-type': 'multipart/form-data',
      },
    })

    trackInternalEvent('resume_uploaded')
    yield put(actions.setParsedResumeDetails(resumeDetails))
    yield put(actions.setparsingStatus(FetchStatuses.loaded))
  } catch (error) {
    trackInternalEvent('parsing_primary_data_error')
    yield put(
      actions.setError({
        hasError: true,
        errorMessage: 'We couldn’t parse your resume. Try uploading another resume',
      }),
    )

    yield put(actions.setparsingStatus(FetchStatuses.failed))
    yield put(actions.setStep(UploadResumeStep.UPLOAD_RESUME))
  }
}

export function* createUpsiderUserSaga({ payload }: ReturnType<typeof actions.createUpsiderUser>) {
  try {
    const { checksum, redirectTo, critiqueReview, isCioUser, emailId, ...restParams } = payload
    const { request } = yield call(
      apiClient.get,
      // eslint-disable-next-line max-len
      `/tr-leads?checksum=${checksum}&is_cio_user=${isCioUser}&email=${emailId}&redirect_to=${redirectTo}?critique_review=${critiqueReview}`,
      {
        params: restParams,
        baseURL: '/api',
      },
    )
    // Redirects the user to the dashboard
    if (!isCioUser) {
      window.location.href = request.responseURL
    }
  } catch (error) {
    yield put(
      actions.setError({
        hasError: true,
        errorMessage: 'Something went wrong. Try uploading another resume',
      }),
    )

    yield put(actions.setparsingStatus(FetchStatuses.failed))
    yield put(actions.setStep(UploadResumeStep.UPLOAD_RESUME))
    // Handle the error
    ErrorLogger.log(error)
  }
}

/** Bind side-effect handlers */
export const sagas = function* saga() {
  yield all([takeLatest(actions.parsedDetails, fetchResumeDetailsSaga)])
  yield all([takeLatest(actions.createUpsiderUser, createUpsiderUserSaga)])
}
