import { useState } from 'react'
import { LevelSelect } from 'builder/components/LevelSelect'
import { i18n } from 'builder/context/I18nContext'
import { ComponentPreview, Group, Label } from 'builder/components/UiKit/styles'
import { options } from './constants'

export const LevelSelectSection = () => {
  const [level, setLevel] = useState('novice')

  return (
    <Group>
      <Label>LevelSelect</Label>
      <ComponentPreview>
        <LevelSelect
          selected={level}
          onChange={id => setLevel(id)}
          label={i18n.t('builder.resume_editor.skills_card.level')}
          options={options}
        />
      </ComponentPreview>
    </Group>
  )
}
