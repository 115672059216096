import PropTypes from 'prop-types'

import { Container, Step, StepNumber, StepTitle } from './styles'

const Steps = ({ steps, currentStep }) => (
  <Container>
    {steps.map((item, i) => (
      <Step key={i}>
        <StepNumber visited={i <= currentStep}>{i + 1}</StepNumber>
        <StepTitle visited={i <= currentStep}>{item}</StepTitle>
      </Step>
    ))}
  </Container>
)

Steps.propTypes = {
  steps: PropTypes.array,
  currentStep: PropTypes.number,
}

export default Steps
