/**
 * Asynchronously reads the contents of the specified file
 * and returns a `data:` URL representing the file's data
 */
export function readFileAsBase64(file: File): Promise<string> {
  const reader = new FileReader()

  return new Promise<string>((resolve, reject) => {
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = error => reject(error)
  })
}
