import styled, { css, keyframes } from 'styled-components'
import { Link } from 'react-router-dom'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Shadows from 'builder/styles/shadows'
import Media from 'builder/styles/media'
import { Badge } from 'builder/components/Badge'
import { Z_INDEX } from '../../constants'
import { PremiumButton } from '../PremiumButton'

const fadeIn = keyframes`
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`

export const Container = styled.div<{ isPremium?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  margin-left: ${props => (props.isPremium ? '0' : '16px')};
`

export const UserDropdownTrigger = styled.div<{ isOpen: boolean; hasCompletenessBadge: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 1000px;
  padding: 4px 8px 4px 4px;
  cursor: pointer;
  gap: 8px;
  background-color: ${Colors.White};
  will-change: background-color;
  transition: background-color 0.2s ease-out;

  ${props =>
    props.isOpen &&
    css`
      background-color: ${Colors.Neutral5};
    `}

  ${props =>
    props.hasCompletenessBadge &&
    css`
      ${Media.Phone`
        padding: 4px;
        margin-right: 14px;
      `}
    `}

  &:hover {
    background-color: ${Colors.Neutral5};
  }
`

export const Title = styled.div`
  color: ${Colors.Neutral100};

  ${Media.Phone`
    display: none;
  `}
`

export const UserPhoto = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`

export const UserPhotoWrapper = styled.div`
  position: relative;
`

export const ProfileCompletedBadge = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 16px;
  height: 16px;
`

export const CompletenessBadge = styled(Badge)`
  min-width: 36px;
  border-radius: 100px;

  ${Media.Phone`
    top: 0;
    left: 26px;
    position: absolute;
    border: 2px solid ${Colors.White};
  `}
`

export const DropdownContainer = styled.menu`
  ${Shadows.lightWithBorder.high};
  position: absolute;
  z-index: ${Z_INDEX + 1};
  right: 0;
  top: 100%;
  padding: 12px 24px 24px;
  margin: 0;
  width: 352px;
  border-radius: 16px;
  background-color: ${Colors.White};
  display: flex;
  flex-flow: column;
  gap: 12px;

  transform-origin: 100% 0;
  animation: ${fadeIn} 0.25s;
  animation-fill-mode: forwards;

  ${Media.Phone`
    right: -20px;
    width: 100vw;
    border-radius: 0;
    top: calc(100% + 20px);
    height: calc(100vh - 80px);
  `};
`

export const ProfileLink = styled(Link)`
  --user-name-color: ${Colors.Neutral100};
  --my-profile-text-color: ${Colors.Neutral50};

  display: flex;
  flex-direction: column;
  padding: 8px 2px;
  gap: 2px;

  ${Media.Hover`
    &:hover {
      --user-name-color: ${Colors.Blue60};
      --my-profile-text-color: ${Colors.Blue60};
    }
  `}
`

export const UserName = styled.div`
  ${Typography.S};
  ${FontWeights.DemiBold};
  color: var(--user-name-color);
  will-change: color;
  transition: color 0.2s ease-out;
`

export const MyProfileText = styled.div`
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
  color: var(--my-profile-text-color);
  will-change: color;
  transition: color 0.2s ease-out;
`

export const ProfileContent = styled.div`
  flex-grow: 1;
`

export const ProfileLinks = styled.div``

export const Divider = styled.div`
  flex: 0 0 1px;
  background-color: ${Colors.Neutral15};
`

export const MainLinks = styled.div``

export const DefaultLink = styled.a``

export const RouterLink = styled(Link)``

export const LinkContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 4px;
  gap: 12px;
  transition: color 0.1s ease-out;
  cursor: pointer;
  border: none;
  background: none;
  margin: 0;

  ${Media.Hover`
    &:hover {
      color: ${Colors.Blue60};
    }
  `}
`

export const PremiumButtonStyled = styled(PremiumButton)`
  margin-top: 16px;
`
