import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Button, { ButtonSize } from 'builder/components/Button'

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  padding: 16px;
`

export const FooterSaveButton = styled(Button).attrs({ size: ButtonSize.small })``

export const FooterUpload = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  color: ${Colors.Neutral40};
  cursor: pointer;
  opacity: 0.64;
  transition: opacity 0.15s;

  &:hover {
    opacity: 1;
  }
`

export const FooterUploadIcon = styled.span`
  width: 24px;
  height: 24px;
  margin-right: 6px;
  background-image: url(${require('images/builder/avatar-editor/upload.svg')});
`

export const FooterUploadText = styled.span`
  ${Typography.Caption};
  font-weight: 600;
`

export const FooterUploadInput = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  visibility: hidden;
  pointer-events: none;
`
