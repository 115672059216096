// TODO: Consider migrating to "day.js" since it's lighter and has a better API.
// No doubt that `dayjs(string).add(7, 'day').format('DD/MM/YYYY')`
// is way better than `format(addDays(parseISO(string), 7), 'DD/MM/YYYY')`

import format from 'date-fns/format'
import formatDistance from 'date-fns/formatDistance'
import formatDistanceStrict from 'date-fns/formatDistanceStrict'
import parseISO from 'date-fns/parseISO'

// Locales (https://date-fns.org/docs/I18n#supported-languages)
import en from 'date-fns/locale/en-US' // International: resume.io
import nl from 'date-fns/locale/nl' // Netherlands: cvster.nl
import fr from 'date-fns/locale/fr' // France: cvapp.fr
import es from 'date-fns/locale/es' // Spain: cvapp.es
import fi from 'date-fns/locale/fi' // Finland: cvapp.fi
import it from 'date-fns/locale/it' // Italy: cvapp.it
import da from 'date-fns/locale/da' // Denmark: cv.dk
import sv from 'date-fns/locale/sv' // Sweden: cvkungen.se
import nb from 'date-fns/locale/nb' // Norway: cvapp.no
import pl from 'date-fns/locale/pl' // Poland: cveasy.pl
import cs from 'date-fns/locale/cs' // Czech: cvapp.cz
import ja from 'date-fns/locale/ja' // Japanese
import ko from 'date-fns/locale/ko' // Korean
import de from 'date-fns/locale/de' // Germany

import { capitalize } from 'builder/utils/capitalize'

interface LocalesState {
  supported: Record<string, Locale>
  active: Locale
}

const locales: LocalesState = {
  supported: {
    en,
    'nl-NL': nl,
    'es-ES': es,
    'sv-SE': sv,
    da,
    nb,
    'fr-FR': fr,
    it,
    pl,
    cs,
    fi,
    'ja-JP': ja,
    'ko-KR': ko,
    'de-DE': de,
  },
  active: en,
}

const preferredFormatLocaleString = {
  at: 'dd. MMMM yyyy',
  bg: 'dd MMMM yyyy r.',
  ch: 'dd. MMMM yyyy',
  cl: "dd 'de' MMMM 'de' yyyy",
  cz: 'dd. MMMM yyyy',
  de: 'dd. MMMM yyyy',
  dk: 'dd. MMMM yyyy',
  es: "dd 'de' MMMM 'de' yyyy",
  fi: 'dd. MMMM yyyy',
  fr: 'dd MMMM yyyy',
  gr: 'dd MMMM yyyy',
  ie: 'dd MMMM yyyy',
  it: 'dd MMMM yyyy',
  jp: 'dd MMMM yyyy',
  mx: "dd 'de' MMMM 'de' yyyy",
  nl: 'dd MMMM, yyyy',
  no: 'dd. MMMM yyyy',
  nz: 'dd MMMM yyyy',
  pl: 'dd MMMM yyyy',
  pt: "dd 'de' MMMM 'de' yyyy",
  ro: 'dd MMMM yyyy',
  rs: 'dd. MMMM yyyy.',
  se: 'dd MMMM yyyy',
  en: 'MMMM dd, yyyy',
} as unknown as Record<string, string>

export const setLocale = (name: string) => {
  if (locales.supported[name]) {
    locales.active = locales.supported[name]
  } else {
    console.error(`Format date: Locale ${name} is not supported`)
  }
}

/** ISO 8601 string, timestamp or Date class instance */
export type ValidInput = string | number | Date

// Since v2 date-fns functions don't accept string arguments, but only numbers or dates.
// From now on a string should be parsed using `parseISO` (ISO 8601) or `parse`.
export const parseDate = (value: ValidInput): number | Date => {
  return typeof value === 'string' ? parseISO(value) : value
}

const prepareInferredFormatString = (inferFormat?: boolean, formatString?: string) => {
  if (inferFormat) {
    const locale = locales.active.code || 'en'
    return preferredFormatLocaleString[locale] || preferredFormatLocaleString.en
  }
  return formatString || preferredFormatLocaleString.en
}

/** Returns the formatted date string in the given format and active locale */
export const formatDate = (
  value: ValidInput,
  formatString?: string,
  options: Parameters<typeof format>[2] = {},
  inferFormat?: boolean,
): string => {
  let formatStringToUse = prepareInferredFormatString(inferFormat, formatString)

  let formattedDate = format(parseDate(value), formatStringToUse, {
    locale: locales.active,
    ...options,
  })

  // Remove dot from dates that start with a short month name
  // Example: "mei. 2019" => "mei 2019"
  if (formatStringToUse.startsWith('LLL ')) {
    formattedDate = formattedDate.replace(/^(.{3})\.?(.*)$/, '$1$2')
  }

  // Replace hours divider for french locale
  // See https://en.wikipedia.org/wiki/Date_and_time_notation_in_France
  // Example: "14:35" => "14h35"
  if (formatStringToUse.includes('HH:mm') && locales.active === locales.supported['fr-FR']) {
    formattedDate = formattedDate.replace(':', 'h')
  }

  return capitalize(formattedDate)
}

interface DistanceFormattingOptions {
  /** Should not use helpers like 'almost', 'over', 'less than' and the like */
  strict?: boolean
  /* Should indicate if the second date is earlier/later than the first (add "ago" for example) */
  addSuffix?: boolean
}

/** Returns the distance between the given dates as a localized string */
export const distance = (
  dateFrom: ValidInput,
  dateTo: ValidInput,
  { strict, ...options }: DistanceFormattingOptions = {},
): string => {
  const method = strict ? formatDistanceStrict : formatDistance

  return method(parseDate(dateFrom), parseDate(dateTo), {
    locale: locales.active,
    ...options,
  })
}

/** Returns the distance between the given date and current time as a localized string */
export const fromNow = (date: ValidInput, options?: DistanceFormattingOptions): string => {
  return distance(date, new Date(), options)
}
