import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'

export const Container = styled.div<{ onHover: boolean }>`
  width: 304px;
  height: 172px;
  border-radius: var(--2XS, 8px);
  background: ${props => (props.onHover ? '#EDEEFE' : Colors.Indigo10)};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;

  ${Media.Phone`
    width: 100%;
  `}
`

export const VisualImageContainer = styled.div`
  position: relative;
  width: 236.826px;
  height: 236.826px;
  z-index: 1;
`

export const VisualImage = styled.img`
  width: 100%;
  height: 100%;
`
export const UserImage = styled.img`
  position: absolute;
  z-index: 100;
  top: 134px;
  width: 40px;
  left: 91.5px;
  height: 40px;
  border-radius: 50%;
  padding: 2px;
`
