import styled from 'styled-components'
import Media from 'builder/styles/media'

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0 48px;

  ${Media.Phone`
    margin-top: 32px;
  `}
`
