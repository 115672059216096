import { useCallback } from 'react'
import { Editor } from '@tiptap/react'
import Icon from 'builder/components/Icon'
import * as Styles from './styles'

type ButtonProps = {
  icon?: typeof Icon[keyof typeof Icon]
  onMouseDown?: () => void
  isActive?: boolean
  isDisabled?: boolean
}

const Button = ({ icon: ToolbarIcon = Icon.Add, onMouseDown, ...props }: ButtonProps) => (
  <Styles.Button
    onMouseDown={event => {
      event.preventDefault()
      onMouseDown && onMouseDown()
    }}
    {...props}
  >
    <ToolbarIcon />
  </Styles.Button>
)

type ToolbarProps = {
  editor: Editor
}

export const Toolbar = ({ editor }: ToolbarProps) => {
  const toggleLink = useCallback(() => {
    if (editor.isActive('link')) {
      editor.chain().focus().extendMarkRange('link').unsetLink().run()
    } else {
      const url = window.prompt('Enter the URL of the link:')

      // cancelled
      if (url === null) {
        return
      }

      // empty
      if (url === '') {
        editor.chain().focus().extendMarkRange('link').unsetLink().run()

        return
      }

      // update link
      editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
    }
  }, [editor])

  return (
    <Styles.Container>
      <Styles.Left>
        <Button
          icon={Icon.Bold}
          onMouseDown={() => editor.chain().focus().toggleBold().run()}
          isActive={editor.isActive('bold')}
        />
        <Button
          icon={Icon.Italic}
          onMouseDown={() => editor.chain().focus().toggleItalic().run()}
          isActive={editor.isActive('italic')}
        />
        <Button
          icon={Icon.Underline}
          onMouseDown={() => editor.chain().focus().toggleUnderline().run()}
          isActive={editor.isActive('underline')}
        />
        <Button
          icon={Icon.Strike}
          onMouseDown={() => editor.chain().focus().toggleStrike().run()}
          isActive={editor.isActive('strike')}
        />
        <Styles.Divider />
        <Button
          icon={Icon.OrderedList}
          onMouseDown={() => editor.chain().focus().toggleOrderedList().run()}
          isActive={editor.isActive('orderedList')}
        />
        <Button
          icon={Icon.UnorderedList}
          onMouseDown={() => editor.chain().focus().toggleBulletList().run()}
          isActive={editor.isActive('bulletList')}
        />
        <Styles.Divider />
        <Button
          icon={editor.isActive('link') ? Icon.UnbindLink : Icon.BindLink}
          onMouseDown={toggleLink}
          isActive={editor.isActive('link')}
          isDisabled={!editor.isActive('link') && editor.state.selection.empty}
        />
      </Styles.Left>
    </Styles.Container>
  )
}
