import styled from 'styled-components'

export const Container = styled.div`
  width: inherit;
  height: 32px;
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Wrapper = styled.div`
  width: inherit;
  height: 32px;
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
