export const FullTick = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10ZM10.0607 12.8536L14.7071 8.20711L13.2929 6.79289L9.00001 11.0858L6.70712 8.79289L5.29291 10.2071L7.93935 12.8536C8.52514 13.4393 9.47489 13.4393 10.0607 12.8536Z"
      fill="#339D5D"
    />
  </svg>
)
