import styled from 'styled-components'

import Media from 'builder/styles/media'

export const PromotionButtonContainer = styled.div`
  display: flex;
  flex-direction: row;

  ${Media.Tablet`
    flex-direction: column;
    margin-bottom: 32px;
    align-items: center;
    justify-content: center;
    `}

  ${Media.Phone`
    flex-direction: column;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
    `}
`
