import { Link, useParams } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import Button from 'builder/components/Button'
import * as Styles from './styles'

const isLowLeadsInSightsImage = require('images/critique-review/about-us-2.svg')

const InsightsBanner = () => {
  const params = useParams()
  const resumeId = Number(params.id)
  const resumeEditorUrl = `/resumes/${resumeId}/edit`

  return (
    <Styles.Footer>
      <Styles.FooterContainer>
        <Styles.FooterContent>
          <Styles.FooterTitle>Found Some Useful Insights?</Styles.FooterTitle>
          <Styles.FooterText>
            Take your time to rewrite your resume based on this in-depth critique and get your dream
            job with higher chances.
          </Styles.FooterText>
          <Button
            onClick={() => trackInternalEvent('click_edit_resume_button', { section: 'bottom' })}
            as={Link}
            to={resumeEditorUrl}
          >
            Edit My Resume
          </Button>
        </Styles.FooterContent>
        <Styles.FooterImage src={isLowLeadsInSightsImage} />
      </Styles.FooterContainer>
    </Styles.Footer>
  )
}

export default InsightsBanner
