import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import DocumentImagePreview from 'builder/components/DocumentImagePreview'
import Button from 'builder/components/Button'
import { fadeIn } from 'builder/styles/keyframes'

export const ReplacePopup = styled.div`
  position: absolute;
  z-index: 999;
  top: 50px;
  margin-left: 107px;
  width: 30%;
  min-width: 312px;
  max-width: 320px;
  border-radius: 8px;
  animation: ${fadeIn} 0.3s ease;

  ${Media.Tablet`
    top: 38px;
    width:320px;
  `}

  ${Media.Phone`
    position: absolute;
    padding: 0 20px;
    margin-left: 0;
    top: unset;
    left: 0;
    bottom: 85px;
    z-index: 1;
    width: 100%;
    max-width: 767px;
    min-width: 300px;
  `}
`

export const PopupContainer = styled.div`
  ${Typography.Tiny};
  height: 208px;
  background: ${Colors.White};
  border-radius: 8px;
  box-shadow: 0px 0px 1px rgb(15 56 113 / 32%), 0px 2px 4px -1px rgb(15 56 113 / 8%),
    0px 8px 20px -4px rgb(15 56 113 / 12%);

  & > :first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  & > :last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  ${Media.Phone`
    width: 100%;
  `}
`

export const OptionsContainer = styled.div`
  ${Typography.Tiny};
  height: 104px;
  min-width: 312px;
  border: 1px solid ${Colors.Neutral15};
  cursor: pointer;
  ${Media.Phone`
    width: 100%;
  `}
`

export const Options = styled.label`
  width: 98%;
  height: 96px;
  margin-top: 3.5px;
  margin-left: 3.8px;
  border-radius: 4px;
  display: flex;
  cursor: pointer;
  ${OptionsContainer}:hover & {
    background-color: ${Colors.Blue10};
  }
`

export const OptionsGradient = styled.div`
  position: relative;
  width: 98%;
  height: 24px;
  display: flex;
  z-index: 10;
  margin-left: 3.8px;
  top: -24px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  ${OptionsContainer}:hover & {
    background: linear-gradient(180deg, rgba(234, 246, 255, 0) 0%, ${Colors.Blue10} 100%);
  }
`

export const OptionPreview = styled(DocumentImagePreview)`
  width: 80px;
  margin: 8px 0px 0px 8px;
  border-radius: 2px;
  border: 1px solid ${Colors.Neutral15};
  border-bottom: none;
  background: linear-gradient(180deg, #f7f9fc 0%, ${Colors.White} 100%);
  border-image: linear-gradient(to bottom, ${Colors.Neutral15} 60%, ${Colors.White} 100%);
  border-image-slice: 1;
`

export const OptionRight = styled.div`
  margin: 12px 0 0 16px;
`

export const OptionTitle = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral90};
  height: 24px;
  width: 192px;

  ${OptionsContainer}:hover & {
    color: ${Colors.Blue50};
  }
`
export const OptionContent = styled.div`
  ${Typography.Tiny};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
  width: 192px;
  height: 32px;
`

export const SectionWrapper = styled.div`
  margin-bottom: 12px;
`

export const Title = styled.div`
  ${Typography.Caption};
  ${FontWeights.Regular};
  padding: 8px;
  max-width: 236px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${Media.Phone`
    max-width: 123px;
  `}
`

export const MobileBackgroundShadow = styled.div`
  ${Media.Phone`
    position: fixed;
    background: rgb(0 0 0 / 35%);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    animation: ${fadeIn} 0.3s ease;
`}
`

export const ButtonWrapper = styled.div`
  z-index: 999;
  position: absolute;
  left: 0;
  bottom: 20px;
  z-index: 1;
  width: 100%;
  max-width: 767px;
  min-width: 300px;
  padding: 0 20px;
  animation: ${fadeIn} 0.3s ease;
`

export const CancelButton = styled(Button)`
  width: 100%;
  border-radius: 8px;
  background-color: #fff;
  color: ${Colors.Blue50};

  &:hover {
    background-color: #fff;
    color: ${Colors.Blue50};
  }
`
