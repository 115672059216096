import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'

export const TextAreaWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 480px;
  height: 272px;
  & textarea {
    width: 408px;
    height: 192px;
    background: ${Colors.White};
  }
  & .textAreaField {
    width: 408px;
    background: ${Colors.White};
  }

  ${Media.Tablet`
    height: 192px;
    margin-bottom: 20px;
    & textarea {
        width: 476px;
        height: 192px;
    }
    & .textAreaField {
        width: 476px;
    }
  `}

  ${Media.Phone`
    width: 295px;
    height: 164px;
    margin-bottom: 20px;
    & textarea {
        width: 295px;
        height: 164px;
    }
    & .textAreaField {
        width: 295px;
    }
  `}
`
