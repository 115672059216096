import { ChangeEvent } from 'react'
import { TextAreaField, TextField } from 'builder/components/TextField'
import { StatusType } from '../../CustomInterviewView'
import { CustomTextFieldHeader, CustomTextFieldLabel } from './styles'

interface Props {
  container: React.ElementType
  type: typeof TextField | typeof TextAreaField
  label: string
  placeholder: string
  enableText?: boolean
  value?: string
  onChange: (value: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  status?: StatusType
  showSuccess?: boolean
  error?: boolean
  disabled: boolean
}
export const TextFieldWithSpinner = ({
  container: Container,
  type: Type,
  label,
  placeholder,
  value,
  onChange,
  disabled,
}: Props) => {
  return (
    <Container>
      <CustomTextFieldHeader>
        <CustomTextFieldLabel>{label}</CustomTextFieldLabel>
        {/* <Status
          type={status}
          enableText={enableText}
          showSuccess={showSuccess}
          showError={showError}
        /> */}
      </CustomTextFieldHeader>
      <Type placeholder={placeholder} value={value} onChange={onChange} disabled={disabled} />
    </Container>
  )
}
