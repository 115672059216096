import { useSelector } from 'react-redux'
import RirekishoEditor from 'builder/components/DocumentEditor/Japanese/RirekishoEditor'
import ShokumukeirekishoEditor from 'builder/components/DocumentEditor/ShokumukeirekishoEditor'
import ResumeEditor from 'builder/components/DocumentEditor/ResumeEditor'
import { Store } from 'builder/modules/store'
import { TemplateName } from 'builder/components/Helper/constants'

const { Rirekisho, Shokumukeirekisho } = TemplateName
interface TemplateBuilderbjectKeys {
  [key: string]: typeof RirekishoEditor | typeof ShokumukeirekishoEditor
}

const templatBuilderMapping: TemplateBuilderbjectKeys = {
  [Rirekisho]: RirekishoEditor,
  [Shokumukeirekisho]: ShokumukeirekishoEditor,
}

export const useResumeEditor = () => {
  const templateName = useSelector((state: Store) => state.resumeEditor?.resume?.template)

  return (templateName && templatBuilderMapping[templateName]) || ResumeEditor
}
