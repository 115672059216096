import styled, { css } from 'styled-components'
import Media from 'builder/styles/media'
import { CardContainer } from '../../styles'

export const Container = styled.div`
  width: 352px;
  min-height: 126px;
  position: relative;

  ${Media.Phone`
    width: 319px;
    min-height: 112px;
  `}
`

type ChosenType = {
  isFaded?: boolean
  isActive: boolean
}

const hoveredContainerStyles = css<ChosenType>`
  ${props =>
    props.isFaded &&
    !props.isActive &&
    css`
      opacity: 0.4;
    `}

  &:hover {
    opacity: 1;
  }
`

export const ChoiceCard = styled(CardContainer)<ChosenType>`
  ${hoveredContainerStyles};
  width: 100%;
`
