import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${Colors.White};
  border-radius: 40px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%),
    rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(15px);
  padding: 12px 24px;
  width: fit-content;
  cursor: pointer;
  z-index: 522;
  ${Media.Phone`
      width: 100%;
      text-align: center;
      margin-top: 16px;
  `};
`
