import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Sizes from 'builder/styles/sizes'
import { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.Neutral5};
  gap: ${Sizes.XS};
  padding: ${Sizes.S};
  border-radius: ${Sizes.XS};
`

export const TextContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${Sizes['3XS']};
  text-align: center;

  img {
    display: none;
  }

  b,
  span {
    ${FontWeights.DemiBold};
  }
`
