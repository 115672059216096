import { useMemo } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Position, Background, BackgroundColor } from '../types'
import { BACKGROUND_IMAGE_NAMES as IMAGE_NAMES, DEFAULT_BACKGROUND } from '../constants'
import { areEqualBackgrounds, getBackgroundColorForImage } from '../utils'
import AvatarPreview from '../AvatarPreview'
import { BackgroundGalleryWrapper, BackgroundOption, BackgroundGalleryDivider } from './styles'

interface GalleryProps {
  activeBackground: Background
  customColor: BackgroundColor
  imageUrl: string
  noBgImageUrl: string | null
  position: Position
  zoom: number
  angle: number
  onChange: (background: Background) => void
}

export const BackgroundGallery = ({
  activeBackground,
  customColor,
  imageUrl,
  noBgImageUrl,
  zoom,
  position,
  angle,
  onChange,
}: GalleryProps) => {
  const options: Background[] = useMemo(() => {
    const backgrounds = [null, ...IMAGE_NAMES].map(image => ({
      image,
      color: getBackgroundColorForImage(image, customColor),
    }))
    return [DEFAULT_BACKGROUND, ...backgrounds]
  }, [customColor])

  const activeSlideIndex = useMemo(() => {
    const index = options.findIndex(option => areEqualBackgrounds(option, activeBackground))
    return index < 0 ? undefined : index
  }, [activeBackground, options])

  return (
    <BackgroundGalleryWrapper>
      <Swiper
        initialSlide={activeSlideIndex}
        slideToClickedSlide
        slidesPerView="auto"
        centeredSlides
        centeredSlidesBounds
        grabCursor
      >
        {options.map((option, index) => (
          <SwiperSlide key={index}>
            <BackgroundOption
              isActive={areEqualBackgrounds(option, activeBackground)}
              onClick={() => onChange(option)}
            >
              <AvatarPreview
                imageUrl={areEqualBackgrounds(option, DEFAULT_BACKGROUND) ? imageUrl : noBgImageUrl}
                background={option}
                zoom={zoom}
                angle={angle}
                position={position}
                isLoading={noBgImageUrl === null}
              />
            </BackgroundOption>
            {index === 0 && <BackgroundGalleryDivider />}
          </SwiperSlide>
        ))}
      </Swiper>
    </BackgroundGalleryWrapper>
  )
}
