import styled from 'styled-components'
import { CentralColumn } from '../../styles'

export const Container = styled(CentralColumn)`
  display: flex;
  flex-flow: column;
  gap: 24px;
`

export const Paragraph = styled.p``
