import styled from 'styled-components'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import { getScoreBasedColor } from 'builder/modules/careerPath2/utils'

export const Container = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;

  ${Media.Tablet`
    padding-inline: 8px;
  `}
`

export const Header = styled.div`
  gap: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const MatchPercentage = styled.div<{ score: number }>`
  ${Typography.Tiny};
  ${FontWeights.DemiBold};
  padding: 1px 4px;
  border-radius: 4px;
  color: ${props => getScoreBasedColor('color', props.score)};
  background-color: ${props => getScoreBasedColor('bgColor', props.score)};
`

export const Heading = styled.div`
  ${Typography.S};
  ${FontWeights.Medium};
`
