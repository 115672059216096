import styled, { css } from 'styled-components'
import Drawer from 'builder/components/Drawer/Drawer'

export const Container = styled.li<{ position?: 'end' | 'start' }>`
  cursor: pointer;
  width: 100%;

  ${({ position }) =>
    position === 'end' &&
    css`
      margin-top: auto;
    `}
`

export const SubMenu = styled(Drawer)<{ position?: number }>`
  z-index: 2;
  ${({ position }) =>
    position &&
    css`
      top: ${position}px;
    `}
`
