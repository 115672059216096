import { SpellCheckStrategy } from '../types'
import SpellingError from './SpellingError/'
import SpellCheckEmitter from './spellCheckEmitter'

const spellCheckDecorator = (
  strategy: SpellCheckStrategy,
  spellCheckEmitter: SpellCheckEmitter,
) => ({
  strategy: strategy,
  component: SpellingError,
  props: { spellCheckEmitter: spellCheckEmitter },
})

const spellCheckPlugin = (strategy: SpellCheckStrategy) => {
  const spellCheckEmitter = new SpellCheckEmitter()

  return {
    emitter: spellCheckEmitter,
    decorators: [spellCheckDecorator(strategy, spellCheckEmitter)],
  }
}

export default spellCheckPlugin
