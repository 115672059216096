import * as Styled from './styles'

enum Links {
  termsLink = 'https://career.io/tos',
  privacyLink = 'https://career.io/privacy',
  topResumeTermsLink = 'https://www.topresume.com/terms',
  topResumePrivacyLink = 'https://www.topresume.com/privacy',
}

const ContentFooter = () => {
  return (
    <Styled.FooterContainer>
      <Styled.Footer>
        By uploading resume you agree with our{' '}
        <a href={Links.termsLink} target="_blank" rel="noreferrer noopener">
          Terms of use
        </a>{' '}
        and{' '}
        <a href={Links.privacyLink} target="_blank" rel="noreferrer noopener">
          Privacy Policy
        </a>
        , and topresume.com’s{' '}
        <a href={Links.topResumeTermsLink} target="_blank" rel="noreferrer noopener">
          Terms & Conditions
        </a>{' '}
        and{' '}
        <a href={Links.topResumePrivacyLink} target="_blank" rel="noreferrer noopener">
          Privacy Policy
        </a>
        .
      </Styled.Footer>
    </Styled.FooterContainer>
  )
}

export default ContentFooter
