import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import { ElementProps } from './types'
import { getColor } from './utils'

export const Wrapper = styled.div``

export const Label = styled.label`
  ${Typography.Caption};
  display: block;
  color: ${Colors.Neutral50};
  margin-bottom: 6px;
`

export const LabelValue = styled.span<ElementProps>`
  transition: color 0.2s;
  color: ${props => getColor(props) || Colors.Neutral30};
`

export const Selector = styled.div<ElementProps>`
  display: flex;
  position: relative;
  overflow: hidden;
  height: 48px;
  border-radius: 4px;
  background-color: ${props => getColor(props, 0.1) || Colors.Neutral10};
  transition: background-color 0.2s;
  transform: translateZ(0);
  pointer-events: ${props => (props.isDisabled ? 'none' : 'auto')};
`

export const Handle = styled.div<ElementProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transition: transform 0.2s, opacity 0.2s;
  will-change: transform, opacity;
  opacity: ${props => ((props.currentRating ?? 0) >= 0 ? 1 : 0)};

  &:before {
    content: '';
    position: absolute;
    left: -3px;
    right: -3px; /* overlay dividers */
    top: 0;
    height: 100%;
    border-radius: 4px;
    transition: background-color 0.2s;
    background-color: ${props => getColor(props) || Colors.Neutral30};
  }
`
