import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { BackButtonContainer, BackButtonText, LeftArrow, Title, Wrapper } from './styles'

type Props = {
  handlePreviousStep: () => void
}

const Header = ({ handlePreviousStep }: Props) => {
  const { isPhone } = useMediaQueries()

  return (
    <Wrapper>
      <BackButtonContainer onClick={handlePreviousStep}>
        <LeftArrow />
        {!isPhone ? <BackButtonText>Back</BackButtonText> : null}
      </BackButtonContainer>

      <Title>Your Seven Stories Exercise Result</Title>
    </Wrapper>
  )
}

export default Header
