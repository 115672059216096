import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const ScoreVariant = {
  positive: {
    color: Colors.Green40,
  },
  negative: {
    color: Colors.Amber40,
  },
}

export const Score = styled.div<{ fromSidebar: boolean }>`
  box-shadow: 0px 2px 4px -1px rgb(15 56 113 / 8%), 0px 8px 20px -4px rgb(15 56 113 / 12%);
  z-index: 999999;
  width: ${props => (props.fromSidebar ? '100px' : '50px')};
  height: ${props => (props.fromSidebar ? '100px' : '50px')};
  border-radius: 50%;
  border: 2px solid ${Colors.White};
  background-color: ${Colors.White};
  position: absolute;
  bottom: ${props => (props.fromSidebar ? '40px' : '10px')};
  right: ${props => (props.fromSidebar ? '100px' : '50px')};

  ${Media.Phone`
    right: 30px;
  `}
`

export const ScoreContent = styled.div`
  position: absolute;
  left: 4px;
  right: 4px;
  bottom: 4px;
  top: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  border-radius: 50%;
  background-color: ${Colors.White};
  text-align: center;
`

export const ScoreValue = styled.div<{ fromSidebar: boolean }>`
  ${props => (props.fromSidebar ? Typography.M : Typography.Tiny)};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral90};
`

export const ScoreLabel = styled.div<{ fromSidebar: boolean }>`
  color: ${Colors.Neutral50};
  ${FontWeights.Regular};
  font-size: ${props => (props.fromSidebar ? '14px' : '7.12px')};
  line-height: ${props => (props.fromSidebar ? '16px' : '7.12px')};
`
