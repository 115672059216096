import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import { Container as BaseContainer } from 'builder/components/Container'
import { HorizontalTransitionFade } from 'builder/components/HorizontalTransitionFade'

export const Container = styled(HorizontalTransitionFade)``

export const MainContainer = styled(BaseContainer)``

export const Wrapper = styled.div<{ step: number }>`
  display: flex;
  flex-direction: column;
  max-width: ${props => (props.step === 5 ? '100%' : '416px')};
  width: 100%;
  margin: 0 auto;
  padding-top: ${props => (props.step === 5 || props.step === 0 ? '0' : '48px')};

  ${Media.Phone`
    padding-top: 32px;
  `}
`

export const FooterContainer = styled.div`
  flex: 0 0 203px;
  position: sticky;
  bottom: 0;
  background-color: ${Colors.White};
  width: 100%;
`
