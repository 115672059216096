import { Interview } from 'builder/modules/interview/types'

export function getNumberOfQuestionOfInterview(interview: Interview) {
  switch (interview.kind) {
    case 'custom':
      return 8
    case 'one_question':
    case 'test_interview':
      return 1
    default:
      throw new Error(
        `Interview must have kind. interviewId:${interview.id} kind:${interview.kind}`,
      )
  }
}
