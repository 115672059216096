import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Button from 'builder/components/Button'

export const Actions = styled.div`
  position: relative;
  display: flex;
  margin-left: 8px;
`

export const ActionsButton = styled(Button)`
  width: 48px;
  padding: 0;
`

export const ActionsButtonBadge = styled.span<{ isVisible: boolean }>`
  position: absolute;
  right: -6px;
  top: -6px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: ${Colors.Red50};
  border: 2px solid ${Colors.White};
  transition: opacity 0.15s, visibility 0.15s;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
`
export const DownloadButton = styled(Button)``

export const DownloadMenu = styled.div`
  display: flex;
`
