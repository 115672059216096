import { useMemo } from 'react'
import { Transition } from 'react-transition-group'
import { FieldNoteProps, Variant } from './types'
import * as Styled from './styles'

export const FieldNote = ({ error, warning, description }: FieldNoteProps) => {
  const content = error || warning || description
  const variant: Variant = error ? 'error' : 'default'

  const props: React.HTMLAttributes<HTMLDivElement> = useMemo(() => {
    // Some error messages coming from API contain formatted HTML markup (such as links)
    if (typeof content === 'string') {
      return {
        dangerouslySetInnerHTML: { __html: content },
      }
    }

    return { children: content }
  }, [content])

  return (
    <Transition in={!!content} timeout={200} mountOnEnter unmountOnExit>
      {state => <Styled.FieldNoteContainer {...props} variant={variant} in={state} />}
    </Transition>
  )
}
