import { NavigateFunction } from 'react-router-dom'
import { Iframe, SectionWrapper, Title, Container, SubTitle, List, ListItem } from '../../styles'

type Props = {
  navigateTo: NavigateFunction
}

const benefits = [
  { id: 1, benefit: 'Consider your goals in working towards a promotion' },
  {
    id: 2,
    benefit: 'Build a plan to assess skills and competencies, as well as how to fill in any gaps',
  },
  { id: 3, benefit: 'Help you pitch your promotion to others' },
]

export const PathToPromotion = ({ navigateTo }: Props) => {
  return (
    <Container>
      <Iframe src="https://www.youtube.com/embed/8_PfQPn9SIY" />
      <SectionWrapper>
        <Title>
          Asking for a promotion can feel confusing. This 10-step plan provides activities and
          lessons that propel you towards achieving your promotion.
        </Title>
        <SubTitle>
          Together, we&apos;ll:
          <List>
            {benefits.map(({ id, benefit }) => (
              <ListItem key={id}>{benefit}</ListItem>
            ))}
          </List>
          After engaging in the Path to Promotion, you’ll have the data, network, and confidence you
          need to support your ask. If you’re looking for additional support,{' '}
          <span onClick={() => navigateTo('/career-coaching')}>book a session</span> with a coach,
          and we can help you.
        </SubTitle>
      </SectionWrapper>
    </Container>
  )
}
