import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { Media } from 'builder/styles/media'

export const Wrapper = styled.div`
  width: 352px;
  border: 12px solid ${Colors.Neutral5};
  border-radius: 24px;
  height: fit-content;
  gap: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${Media.Tablet`
    gap: 0;
  `};

  ${Media.Phone`
    gap: 0;
  `};
`
