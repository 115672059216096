import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Section = styled.div`
  gap: 32px;
  display: flex;
  flex-direction: column;

  ${Media.Tablet`
    padding-inline: 8px;
  `}
`

export const SectionHeading = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};
`

export const RoleDescription = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
`

export const Description = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
`

export const Paragraph = styled.div`
  color: ${Colors.Neutral50};
`

export const RequiredSkillsContainer = styled.div`
  padding: 40px 0;
  gap: 24px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${Colors.Neutral15};
`

export const SkillsSection = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`

export const SkillsText = styled.div``

export const Skills = styled.div`
  gap: 12px;
  display: flex;
  flex-wrap: wrap;
`

export const Skill = styled.div`
  ${Typography.Caption};
  padding: 6px 10px;
  border-radius: 4px;
  background-color: ${Colors.Neutral10};
`
