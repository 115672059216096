export const getLabel = () => {
  const path = window.location.pathname
  let label = ''
  if (path === '/app/job-search') {
    label = 'job-search'
  }

  if (path === '/app/account') {
    label = 'account_settings'
  }

  return label
}
