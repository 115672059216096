import times from 'lodash/times'

import {
  PlaceholderTop,
  PlaceholderButton,
  PlaceholderCard,
  PlaceholderText,
  PlaceholderAvatar,
  PlaceholderContainer,
  PlaceholderContent,
  PlaceholderColumn,
} from './styles'

const BoardPlaceholder = () => (
  <PlaceholderContainer>
    <PlaceholderContent>
      {times(5, columnIndex => (
        <PlaceholderColumn key={columnIndex}>
          <PlaceholderTop />
          <PlaceholderButton />

          {times(columnIndex % 2 ? 1 : 2, cardIndex => (
            <PlaceholderCard key={cardIndex} big={(cardIndex + columnIndex) % 2 === 1}>
              <PlaceholderText />
              <PlaceholderAvatar />
            </PlaceholderCard>
          ))}
        </PlaceholderColumn>
      ))}
    </PlaceholderContent>
  </PlaceholderContainer>
)

export default BoardPlaceholder
