import { Iframe } from './styles'

type Props = {
  src: string
}

const IFrame = ({ src }: Props) => {
  return <Iframe src={src} />
}

export default IFrame
