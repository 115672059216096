import { Component } from 'react'
import PropTypes from 'prop-types'
import PreviewReloadService from 'builder/services/PreviewReloadService'
import { connect } from 'react-redux'
import { bindActionToPromise } from 'builder/utils/bindActionToPromise'
import { actions as renderingActions } from 'builder/modules/rendering'
import ErrorLogger from 'builder/services/ErrorLogger'
import loaderImage from 'images/builder/resume-editor/resume-loader.svg'

import { Container, LoaderContainer, Loader, Image } from './styles'

const PIXEL_RATIO = window.devicePixelRatio || 1

class SsrPreview extends Component {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    document: PropTypes.object,
    fetchPreview: PropTypes.func.isRequired,
    onPaginationChange: PropTypes.func.isRequired,
  }

  state = {
    src: null,
    currentPage: 1,
    totalPagesCount: 1,
    lastRenderedWidth: null,
  }

  componentDidMount() {
    PreviewReloadService.on(PreviewReloadService.RESUME_PREVIEW_RELOAD_EVENT, this.updatePreview)
    if (this.props.document) this.updatePreview()
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.document && this.props.document) this.updatePreview()
  }

  componentWillUnmount() {
    PreviewReloadService.removeListener(
      PreviewReloadService.RESUME_PREVIEW_RELOAD_EVENT,
      this.updatePreview,
    )
  }

  updatePreview = async () => {
    try {
      const { document, width, fetchPreview, onPaginationChange } = this.props
      const page = this.state.currentPage
      const size = width * PIXEL_RATIO

      this.setState({ lastRenderedWidth: width })

      const { image, pageCount } = await fetchPreview({ document, page, size })

      onPaginationChange({
        currentPage: page - 1,
        totalPagesCount: pageCount,
      })

      this.setState({
        src: image,
        totalPagesCount: pageCount,
      })
    } catch (error) {
      ErrorLogger.log(error)
    }
  }

  nextPage() {
    const { currentPage, totalPagesCount } = this.state
    const nextPage = currentPage + 1
    this.props.onPaginationChange({
      currentPage: nextPage - 1,
      totalPagesCount,
    })
    this.setState({ currentPage: nextPage }, this.updatePreview)
  }

  previousPage() {
    const { currentPage, totalPagesCount } = this.state
    const prevPage = currentPage - 1
    this.props.onPaginationChange({
      currentPage: prevPage - 1,
      totalPagesCount,
    })
    this.setState({ currentPage: prevPage }, this.updatePreview)
  }

  reset() {}

  resize() {
    const { width } = this.props
    const { lastRenderedWidth } = this.state
    if (width - lastRenderedWidth > 50) this.updatePreview()
  }

  render() {
    const { width, height } = this.props
    const { src } = this.state

    return (
      <Container w={width} h={height}>
        {!src && (
          <LoaderContainer>
            <Loader src={loaderImage} />
          </LoaderContainer>
        )}
        {src && <Image src={src} />}
      </Container>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  fetchPreview: bindActionToPromise(dispatch, renderingActions.fetchServerPreview),
})

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(SsrPreview)
