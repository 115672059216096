import styled from 'styled-components'

import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 32px;
`

export const Header = styled.p`
  ${Typography.Caption};
  ${FontWeights.Regular};

  color: ${Colors.Neutral50};
`

export const CreditContainer = styled.div`
  display: flex;
  gap: 4px;
  padding: 4px;
  align-items: center;
`

export const CreditText = styled.p`
  ${Typography.Body};
  ${FontWeights.Regular};

  color: ${Colors.Neutral90};
`

export const CreditCount = styled.div`
  padding: 2px 6px;
  border-radius: 1000px;
  background: ${Colors.Blue10};

  ${Typography.Body};
  ${FontWeights.Regular};

  color: ${Colors.Blue50};
`

export const JobsContainer = styled.div``
