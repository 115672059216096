import { FC, useCallback } from 'react'
import Icon from 'builder/components/Icon'
import {
  Progress,
  ProgressLabel,
  ProgressInfo,
  ProgressCount,
  ProgressCounter,
  ProgressFace,
} from './styles'

type Range = { start: number; end: number } // start and end
type Ranges = {
  good: Range
  bad: Range
  // more can be added
}

interface TextAreaProgressRangeProps {
  length: number
  ranges: Ranges
  label: string
}

const TextAreaProgressRange: FC<TextAreaProgressRangeProps> = props => {
  const {
    length,
    ranges: { good, bad },
    label,
  } = props

  const showFaceIcon = useCallback(() => {
    if (length >= bad.start && length <= bad.end)
      return <Icon.FaceBad data-testid="icon-bad-face" />
    if (length >= good.start && length <= good.end)
      return <Icon.FaceGood data-testid="icon-good-face" />
    return <Icon.FaceNormal data-testid="icon-normal-face" />
  }, [length, bad, good])

  const currentLengthLabel = () => (length < good.start ? `${good.start}+` : `${good.end}`)

  return (
    <Progress style={{ alignItems: 'flex-start' }}>
      <ProgressLabel>{label}</ProgressLabel>

      <ProgressInfo isIdle={length === 0}>
        <ProgressCounter>
          <ProgressCount>{length}</ProgressCount>
          <span>&nbsp;/&nbsp;{currentLengthLabel()}</span>
        </ProgressCounter>
        <ProgressFace isVisible={length > 0}>{showFaceIcon()}</ProgressFace>
      </ProgressInfo>
    </Progress>
  )
}

export default TextAreaProgressRange
