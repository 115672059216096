import { memo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { UserData } from 'builder/modules/user'
import { useI18n } from 'builder/hooks/useI18n'
import { useConfig } from 'builder/hooks/useConfig'
import { useEscapeHandler } from 'builder/hooks/useEscapeHandler'
import { authService } from 'builder/services/AuthService'
import { useClickOutside } from 'builder/hooks/useClickOutside'
import { ToggleCallback } from '../../types'
import {
  DropdownContainer,
  ProfileName,
  ProfileNote,
  Links,
  Link,
  Container,
  UserDropdownTrigger,
  TriggerIcon,
  ProfileContainer,
  ProfileContent,
  LinkContainer,
  SettingsIcon,
  FaqIcon,
  LogOutIcon,
} from './styles'

interface Props {
  user: UserData
  isOpen: boolean
  onToggle: ToggleCallback
  iconColor?: string
}

const UserSettingsComponent = ({ user, isOpen, onToggle, iconColor }: Props) => {
  const { i18n } = useI18n()
  const config = useConfig()
  const userRef = useRef<HTMLDivElement | null>(null)
  const navigate = useNavigate()

  useClickOutside(userRef, () => onToggle(false))

  useEscapeHandler(() => onToggle(false))

  return (
    <Container ref={userRef} isPremium={user.hasPremiumFeatures}>
      <UserDropdownTrigger color={iconColor} isOpen={isOpen} onClick={() => onToggle(!isOpen)}>
        <TriggerIcon />
      </UserDropdownTrigger>

      {isOpen && (
        <DropdownContainer>
          <ProfileContainer>
            <ProfileContent>
              <ProfileName>{user.fullName}</ProfileName>
              <ProfileNote>{user.email}</ProfileNote>
            </ProfileContent>
          </ProfileContainer>

          <Links>
            <LinkContainer
              onClick={() => {
                trackInternalEvent('click_account_settings')
                navigate(`/account`)
              }}
            >
              <SettingsIcon />
              <Link>{i18n.t('builder.navbar.account_settings')}</Link>
            </LinkContainer>

            {config?.links.help && (
              <LinkContainer
                onClick={() => {
                  trackInternalEvent('click_faq')
                  window.open(config.links.help, '_blank')
                }}
              >
                <FaqIcon />
                <Link>{i18n.t('builder.navbar.faq')}</Link>
              </LinkContainer>
            )}

            {/* TODO: Change logic during the migration to JWT-auth */}
            <LinkContainer
              onClick={() => {
                // TODO: this needs to be sent differently. Currently is wrongly tracked only here.
                // trackInternalEvent('click_log_out')
                authService.logout()
              }}
            >
              <LogOutIcon />
              <Link>{i18n.t('builder.navbar.log_out')}</Link>
            </LinkContainer>
          </Links>
        </DropdownContainer>
      )}
    </Container>
  )
}

export const UserSettings = memo(UserSettingsComponent)
