import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Button from 'builder/components/Button'
import Sizes from 'builder/styles/sizes'
import { Icon20, Icon24 } from 'builder/components/Icon'

export const Close = styled.div`
  position: absolute;
  right: 32px;
  top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: ${Colors.Neutral10};
  color: ${Colors.Neutral50};
  transition: background-color 0.2s;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
  }

  ${Media.Phone`
    width: 24px;
    height: 24px;
    top: 20px;
    right: 20px;
  `};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.White};
  border-radius: ${Sizes.S};
  position: relative;
  max-width: 544px;
  width: 100%;
  box-shadow: 0px 16px 64px -8px rgba(15, 20, 30, 0.32), 0px 6px 8px -2px rgba(15, 20, 30, 0.08);
  padding: ${Sizes.L};

  ${Media.Phone`
    max-width: 343px;
    padding: 20px;
  `};
`

export const Title = styled.p`
  ${Typography.M};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral90};
  max-width: 416px;
  width: 100%;

  ${Media.Phone`
    font-size 23px;
    max-width: 271px;
  `};
`

export const Description = styled.p`
  ${Typography.Body};
  color: ${Colors.Neutral50};
  margin-top: 24px;
  margin-bottom: ${Sizes.XL};
  width: 100%;

  ${Media.Phone`
    margin-top: ${Sizes['2XS']};
    margin-bottom: 20px;
    max-width: 271px;
  `};
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: ${Colors.White};
  margin-top: 32px;
  gap: 8px;
`

export const KeepButton = styled(Button)`
  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${props => (props.isDisabled ? Colors.Neutral50 : Colors.White)};
  background-color: ${props => (props.isDisabled ? Colors.Neutral20 : Colors.Blue50)};
  border-radius: 4px;
  padding: 12px 24px;

  &:hover {
    background-color: ${Colors.Blue60};
  }

  ${Media.Phone`
    padding: 8px 12px;
  `};
`

export const SkipButton = styled(Button)`
  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
  background-color: ${Colors.White};
  border-radius: 4px;
  padding: 12px 24px;
  border: 1px solid ${Colors.Neutral20};

  &:hover {
    background-color: ${Colors.White};
    border: 1px solid ${Colors.Blue50};
    color: ${Colors.Blue50};
  }

  ${Media.Phone`
    padding: 8px 12px;
  `};
`

export const PlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background-color: ${Colors.Neutral5};
  padding: ${Sizes.S};
  gap: ${Sizes.XS};
`

export const OfferPlanContainer = styled(PlanContainer)`
  margin-top: ${Sizes.XS};
  padding-top: 24px;
  background-color: ${Colors.Blue10};
  border: 2px solid ${Colors.Blue50};
  position: relative;
`

export const OfferPlanTagContainer = styled.div`
  position: absolute;
  padding: 0 ${Sizes['2XS']};
  border-radius: ${Sizes['4XS']};
  background-color: ${Colors.Blue50};
  height: 20px;
  top: 0;
  left: ${Sizes.XS};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const OfferPlanTag = styled.span`
  color: ${Colors.White};
  ${Typography.Tiny};
  ${FontWeights.DemiBold};
`

export const PlanInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const CrownIcon = styled(Icon20.Crown)`
  width: 24px;
  height: 24px;
  color: ${Colors.Indigo60};
`

export const PlanTitle = styled.span`
  color: ${Colors.Neutral80};
  ${Typography.Subhead};
  width: 100%;

  ${Media.Phone`
    max-width: 126px;
  `};
`

export const PlanDescription = styled.p`
  color: ${Colors.Neutral50};
  ${Typography.Body};
  max-width: 304px;
  width: 100%;

  ${Media.Phone`
    display: none;
  `};
`

export const PlanDescriptionPhone = styled.p`
  color: ${Colors.Neutral50};
  ${Typography.Body};
  max-width: 250px;
  width: 100%;
  display: none;
  padding-left: 36px;

  ${Media.Phone`
    display: flex;
  `};
`

export const PlanPrice = styled.span`
  color: ${Colors.Neutral80};
  ${Typography.Body};
  margin-left: auto;
`

export const EyeIcon = styled(Icon24.View)`
  color: ${Colors.Blue90};
  opacity: 0.6;
`

export const PlanContainerFirstRow = styled.div`
  display: flex;
  gap: ${Sizes.XS};

  ${Media.Phone`
    align-items: center;
  `};
`
