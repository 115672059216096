import { memo } from 'react'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import ModalOverlay from '../ModalOverlay'
import FeaturesContainer from './FeaturesContainer/FeaturesContainer'

type Props = {
  onClose: () => void
  onContinueCancellation: () => void
}

export const SubscriptionCancellationModal = memo((props: Props) => {
  const { onClose, onContinueCancellation } = props
  const { isPhone } = useMediaQueries()

  return !isPhone ? (
    <ModalOverlay
      onClick={onClose}
      overlayFadeDuration={150}
      contentSlideDuration={150}
      fullScreen={isPhone}
    >
      <FeaturesContainer {...{ onClose, onContinueCancellation }} />
    </ModalOverlay>
  ) : (
    <FeaturesContainer {...{ onClose, onContinueCancellation }} />
  )
})
