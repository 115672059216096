import styled from 'styled-components'

import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const TableContainer = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 32px;
  width: 500px;

  ${Media.Tablet`
    padding-top: 16px;
    width: 488px;
  `}

  ${Media.Phone`
    padding-top: 12px;
    width: 100%;
  `}
`

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
  height: 88px;
  border-radius: 16px;
  background: ${Colors.White};
  box-shadow: 0px 0px 1px 0px rgba(15, 56, 113, 0.32), 0px 6px 8px -2px rgba(15, 56, 113, 0.02),
    0px 16px 64px -8px rgba(15, 56, 113, 0.18);

  ${Media.Tablet`
    border-radius: 8px;
    padding: 0 16px;
    margin: 0 16px;
  `}

  ${Media.Phone`
    margin: 0 12px;
  `}
`

export const VerticalLine = styled.div`
  width: 1px;
  height: 40px;
  display: block;
  margin: 29px 0;
  align-self: stretch;
  border-left: 1px solid rgb(217, 217, 217);
`

export const RecruiterBox = styled.div`
  width: 50%;
`
export const NextScheduleBox = styled.div`
  width: 50%;
`

export const RecruiterHeader = styled.div`
  text-align: center;
  color: ${Colors.Neutral90};

  ${Typography.Subhead}
  ${FontWeights.Medium}
`

export const RecruiterSubContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 4px;
`

export const RecruiterSubHeader = styled.div`
  text-align: center;
  color: ${Colors.Neutral50};

  ${Typography.Tiny}
  ${FontWeights.Regular}
`

export const RecruiterList = styled.div`
  ${Media.Tablet`
    padding: 0 16px;
  `}

  ${Media.Phone`
    padding: 0 12px;
  `}
`
