import * as React from 'react'
import { FetchStatuses } from 'builder/modules/constants'
import { useI18n } from 'builder/hooks/useI18n'
import { useImageLoader } from './hooks'

import {
  DocumentPreviewContainer,
  DocumentPreviewImage,
  DocumentPreviewPlaceholder,
  DocumentPreviewUnavailable,
} from './styles'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  src: string | null
  showUnavailablePreview?: boolean
}

export const DocumentImagePreview = ({ src, showUnavailablePreview = false, ...rest }: Props) => {
  const previewStatus = useImageLoader(src)
  const isReady = previewStatus === FetchStatuses.loaded && typeof src === 'string'
  const { i18n } = useI18n()

  return (
    <DocumentPreviewContainer {...rest}>
      {isReady ? <DocumentPreviewImage alt="Document" src={src} /> : <DocumentPreviewPlaceholder />}
      {showUnavailablePreview && (
        <DocumentPreviewUnavailable>
          {i18n.t('builder.dashboard.preview_unavailable')}
        </DocumentPreviewUnavailable>
      )}
    </DocumentPreviewContainer>
  )
}
