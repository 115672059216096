import { CompanySuggestion } from 'builder/components/CompanyAutosuggest'

export const fakeDelay = <T>(result: T, delay = 500): Promise<T> => {
  return new Promise(resolve => {
    setTimeout(() => resolve(result), delay)
  })
}

export const fetchFakeSuggestions = async (query: string) => {
  return fakeDelay([{ text: query }, { text: query + query }])
}

export const fetchFakeCompanySuggestions = async (query: string): Promise<CompanySuggestion[]> => {
  return fakeDelay([
    {
      name: query,
      company: query,
      logo: 'http://placekitten.com/40/40',
      link: `${query}.com`,
    },
    {
      name: `Google ${query}`,
      company: `Google ${query}`,
      link: `${query}.google.com`,
    },
  ])
}
