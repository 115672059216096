import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { Typography, FontWeights } from 'builder/styles/typography'
import Icon20 from 'builder/components/Icon'
import Shadows from 'builder/styles/shadows'
import Media from 'builder/styles/media'

export const ModalContainer = styled.div`
  ${Shadows.dark.high};
  width: 516px;
  padding: 32px;
  border-radius: 12px;
  background: ${Colors.White};
  gap: 24px;
  display: flex;
  flex-direction: column;
  position: relative;

  ${Media.Phone`
    padding: 20px;
  `}
`

export const ModalTitle = styled.div`
  ${Typography.Subhead};
  ${FontWeights.DemiBold};

  ${Media.Phone`
    max-width: calc(100% - 131px);
    ${Typography.S};
  `}
`

export const Close = styled.div`
  width: 28px;
  height: 28px;
  cursor: pointer;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Colors.Neutral10};
  position: absolute;
  top: 24px;
  right: 24px;

  ${Media.Phone`
    top: 20px;
    right: 20px; 
  `}
`

export const CloseIcon = styled(Icon20.Close)`
  color: ${Colors.Neutral70};

  &:hover {
    color: ${Colors.Blue50};
  }
`

export const ButtonContainer = styled.div`
  text-align: right;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: end;
`
