import { avatarUrl } from 'builder/modules/careerCoaching/constants'
import { PreviousUpcomingSessions } from 'builder/modules/careerCoaching'
import { formatUpcomingSesionDate } from 'builder/views/CareerPostPurchaseView/Utils/Utils'
import CheckImages from '../../../../assets/check.svg'
import {
  CoachImage,
  CoachImageContainer,
  Container,
  DetailsItem,
  DetailsList,
  SessionDetails,
  SessionTitle,
  SesssionDesc,
  TickImage,
  Text,
} from './styles'

interface SessionCardProps {
  session: PreviousUpcomingSessions
}

const sessionPoints = [
  `A link for the video session will be sent your email 24 hours before the scheduled
session time`,
  `Gather your career documents so you’ll have them on hand to discuss, ie. resume, cover
letter, LinkedIn`,
  `Prepare a list of topics you’d like to discuss to make the most of your time`,
]

export const SessionCard = ({ session }: SessionCardProps) => {
  const formatedDate = formatUpcomingSesionDate(
    session?.date,
    session?.start_time,
    session?.end_time,
  )

  return (
    <Container>
      <CoachImageContainer>
        <CoachImage src={session?.coach_avatar || avatarUrl} alt={session?.coach_name} />
      </CoachImageContainer>
      <SessionDetails>
        <SessionTitle>Your next session is on {formatedDate}</SessionTitle>
        <SesssionDesc>with {session?.coach_name}</SesssionDesc>
        <DetailsList>
          {sessionPoints.map((point, index) => (
            <DetailsItem key={index}>
              <TickImage src={CheckImages} />
              <Text>{point}</Text>
            </DetailsItem>
          ))}
        </DetailsList>
      </SessionDetails>
    </Container>
  )
}
