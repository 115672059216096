import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useMemo } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { isEmpty } from 'lodash'
import { actions, selectors } from 'builder/modules/resumeEditor'
import { i18n as I18n } from 'builder/utils/i18n'

import { useAppSelector } from 'builder/hooks/useAppSelector'
import { useAudioRecorder } from 'builder/hooks/useAudioRecorder'
import {
  AIResumeFlows,
  AIResumeLinkedInSteps,
  AIResumeWarningModalActions,
  AIResumeWarningModalTypes,
  SpeechToTextStatus,
  AIResumeQuestionnaireCardType,
  AIResumeDraftEvents,
  AIResumeDraftEventLabels,
} from 'builder/modules/constants'
import { useSuggestions } from 'builder/components/SkillAssistant/hooks'
import { parseDate } from 'builder/utils/formatDate'
import { TABS } from '../constants'
import WorkExperienceStep from './QuestionnaireFlow/WorkExperience'
import DesiredPosition from './QuestionnaireFlow/DesiredPosition'
import Education from './QuestionnaireFlow//Education'
import Skills from './QuestionnaireFlow//Skills'
import AccomplishmentsAndGoals from './QuestionnaireFlow/AccomplishmentsAndGoals'
import LinkedInFlow from './LinkedInFlow'
import QuestionnaireFlow from './QuestionnaireFlow'
import {
  getErrors,
  isWorkExpCardFilled,
  isWorkExpCardEmpty,
} from './QuestionnaireFlow/WorkExperience/WorkExperienceStep'
import { isEducationCardEmpty } from './QuestionnaireFlow/Education/Education'
import { showDataLossWarning } from './LinkedInFlow/LinkedInPrefill'

const useResumeWithAI = () => {
  const dispatch = useDispatch()
  const resume = useSelector(selectors.resume)
  const {
    aiResumeFlow,
    hideLinkedInFlow,
    linkedInData,
    questionnaireData: {
      step,
      progress,
      userInput,
      activeAccomplishmentsTab,
      accomplishmentsAudio,
      accomplishmentsAudioDuration,
      accomplishmentsVoiceProcessingStatus,
      activeGoalsTab,
      goalsAudio,
      goalsAudioDuration,
      goalsVoiceProcessingStatus,
    },
    warningModalAction,
  } = useAppSelector(store => store.resumeEditor.aiResume)
  const { currentStep } = linkedInData
  const { cards, confirmDeleteCardId } = userInput.workExperience
  const { cards: educationCards } = userInput.education

  const accomplishmentsAudioTabProps = useAudioRecorder({
    inputAudio: accomplishmentsAudio,
    inputAudioDuration: accomplishmentsAudioDuration,
  })
  const goalsAudioTabProps = useAudioRecorder({
    inputAudio: goalsAudio,
    inputAudioDuration: goalsAudioDuration,
  })
  const accomplishmentsVoiceProcessingFailed =
    accomplishmentsVoiceProcessingStatus === SpeechToTextStatus.failed
  const goalsVoiceProcessingFailed = goalsVoiceProcessingStatus === SpeechToTextStatus.failed
  const accomplishmentsVoiceProcessingComplete =
    accomplishmentsVoiceProcessingStatus === SpeechToTextStatus.finished
  const goalsVoiceProcessingComplete = goalsVoiceProcessingStatus === SpeechToTextStatus.finished
  const isFutureGoalsStep = step === 6

  const { desiredPosition: position, pastAccomplishments, currentSkills, futureGoals } = userInput
  // Using empty array, for now we don't need to call skills API with each update to the skills
  const currentSkillNames: Array<string> = []
  const suggestions = useSuggestions({
    locale: resume?.locale || 'en',
    position,
    currentSkillNames,
  })
  useEffect(() => {
    dispatch(actions.setSuggestedSkills(suggestions))
  }, [dispatch, suggestions])

  const { workExperience, education } = AIResumeQuestionnaireCardType

  useEffect(() => {
    let tempProgress = 0
    switch (step) {
      case 1:
        if (isWorkExpCardFilled(cards[0])) tempProgress = 15
        break
      case 2:
        tempProgress = 15
        if (!isEmpty(position)) tempProgress = 30
        break
      case 3:
        tempProgress = 30
        if (!isEducationCardEmpty(educationCards[0])) tempProgress = 45
        break
      case 4:
        tempProgress = 45
        if (currentSkills.length > 0) tempProgress = 60
        break
      case 5:
        tempProgress = 60
        if (!isEmpty(pastAccomplishments) || accomplishmentsAudio.length > 0) tempProgress = 80
        break
      case 6:
        tempProgress = 80
        if (!isEmpty(futureGoals) || goalsAudio.length > 0) tempProgress = 100
        break
    }
    dispatch(actions.updateQuestionnaireProgress(tempProgress))
  }, [
    accomplishmentsAudio.length,
    cards,
    currentSkills.length,
    dispatch,
    educationCards,
    futureGoals,
    goalsAudio.length,
    pastAccomplishments,
    position,
    step,
  ])

  const handleNext = useCallback(() => {
    dispatch(actions.setQuestionnaireStep(step + 1))
  }, [dispatch, step])

  const handlePrev = () => {
    dispatch(actions.setQuestionnaireStep(step - 1))
  }

  const handleBack = () => {
    if (step === 1) {
      if (hideLinkedInFlow) {
        // hide linkedin
        dispatch(actions.setShowAIResumeModal(false))
        dispatch(actions.setShowPrefillResumeModal(true))
      } else {
        dispatch(actions.setAIResumeLinkedInStep(AIResumeLinkedInSteps.linkedInPrefill))
        dispatch(actions.setAIResumeFlow(AIResumeFlows.LinkedIn))
      }
    } else {
      handlePrev()
    }
  }

  const handleMobileBack = () => {
    if (aiResumeFlow === AIResumeFlows.Questionnaire) {
      handleBack()
      return
    }

    if (
      currentStep === AIResumeLinkedInSteps.linkedInProfile ||
      currentStep === AIResumeLinkedInSteps.linkedInError
    ) {
      dispatch(actions.setAIResumeLinkedInStep(AIResumeLinkedInSteps.linkedInPrefill))
    } else {
      if (showDataLossWarning(userInput, linkedInData.linkedInProfileURL)) {
        dispatch(
          actions.setShowAIResumeWarningModal({
            showModal: true,
            type: AIResumeWarningModalTypes.dataLost,
          }),
        )
      } else {
        dispatch(actions.setShowAIResumeModal(false))
        dispatch(actions.setShowPrefillResumeModal(true))
      }
    }
  }

  const onGenerateResume = useCallback(
    () => dispatch(actions.generateResumeFromQuestionnaire()),
    [dispatch],
  )

  const handleContinue = useCallback(
    ({ isSkipAction }: { isSkipAction: boolean }) => {
      const eventName = isSkipAction
        ? AIResumeDraftEvents.clickSkip
        : AIResumeDraftEvents.clickContinue
      let label = ''
      switch (step) {
        case 1:
          label = AIResumeDraftEventLabels.workExperience
          // For work experience step, first verify if first work experience is filled
          if (!isWorkExpCardFilled(cards[0])) {
            const errors = getErrors(cards[0])
            dispatch(actions.setQuestionnaireWorkExpCardErrors(errors))
            return
          }

          for (const card of cards) {
            if (isWorkExpCardEmpty(card)) {
              // remove any empty work exp cards
              dispatch(
                actions.deleteQuestionnaireCard({
                  cardType: workExperience,
                  deleteCardId: card.id,
                }),
              )
            }
          }
          dispatch(actions.collapseQuestionnaireCards({ cardType: workExperience }))

          const workExpCards = userInput.workExperience.cards
          const [firstCard, ...restCards] = workExpCards
          let initialDesiredPosition = firstCard.position
          let latestDate = parseDate(firstCard.date.dateFrom || 0)
          if (restCards) {
            for (const card of restCards) {
              const currentDate = parseDate(card.date.dateFrom || 0)
              const currentPosition = card.position
              if (currentDate > latestDate && currentPosition) {
                latestDate = currentDate
                initialDesiredPosition = currentPosition
              }
            }
          }

          if (position === '' || initialDesiredPosition !== position) {
            dispatch(actions.setAIResumeDesiredPostion(initialDesiredPosition))
          }

          handleNext()
          break
        case 2:
          label = AIResumeDraftEventLabels.desiredJob
          handleNext()
          break
        case 3:
          label = AIResumeDraftEventLabels.education
          if (educationCards.length === 1) {
            if (!isEducationCardEmpty(educationCards[0])) {
              dispatch(
                actions.collapseQuestionnaireCards({
                  cardType: education,
                }),
              )
            }
            handleNext()
          } else {
            let totalCardsCount = educationCards.length
            let removedCardsCount = 0
            for (let i = 1; i < educationCards.length; i++) {
              const currentCard = educationCards[i]
              if (isEducationCardEmpty(currentCard)) {
                dispatch(
                  actions.deleteQuestionnaireCard({
                    cardType: education,
                    deleteCardId: currentCard.id,
                  }),
                )
                removedCardsCount += 1
              }
            }

            const firstCard = educationCards[0]
            const isFirstCardEmpty = isEducationCardEmpty(firstCard)
            if (removedCardsCount < totalCardsCount - 1) {
              if (isFirstCardEmpty) {
                // if there's atleast one filled card,
                // and the first one is empty, remove the first one
                dispatch(
                  actions.deleteQuestionnaireCard({
                    cardType: education,
                    deleteCardId: firstCard.id,
                  }),
                )
              }

              dispatch(actions.collapseQuestionnaireCards({ cardType: education }))
            } else {
              // if there are no filled cards
              dispatch(
                actions.collapseQuestionnaireCards({
                  cardType: education,
                  excludeCardsIds: isFirstCardEmpty ? [firstCard.id] : [],
                }),
              )
            }

            handleNext()
          }
          break
        case 4:
          label = AIResumeDraftEventLabels.skills
          handleNext()
          break
        case 5:
        case 6:
          // For Past Accomplishments and Future Goals step
          if (isSkipAction) {
            trackInternalEvent(AIResumeDraftEvents.clickSkip, {
              label: isFutureGoalsStep
                ? AIResumeDraftEventLabels.careerGoals
                : AIResumeDraftEventLabels.professionalHighlights,
            })
            isFutureGoalsStep ? onGenerateResume() : handleNext()
            return
          }
          const activeTab = isFutureGoalsStep ? activeGoalsTab : activeAccomplishmentsTab
          if (activeTab === TABS.voice_input) {
            // For voice input tab
            const { audioChunks, duration, isRecording, onStartRecording, onStopRecording } =
              isFutureGoalsStep ? goalsAudioTabProps : accomplishmentsAudioTabProps

            if (!isRecording) {
              // If it is not recording
              const audioProcessingFailed = isFutureGoalsStep
                ? goalsVoiceProcessingFailed
                : accomplishmentsVoiceProcessingFailed
              const audioProcessingComplete = isFutureGoalsStep
                ? goalsVoiceProcessingComplete
                : accomplishmentsVoiceProcessingComplete

              if (audioChunks.length === 0 || audioProcessingFailed) {
                if (audioChunks.length === 0) {
                  trackInternalEvent(AIResumeDraftEvents.clickStartRecording, {
                    label: isFutureGoalsStep
                      ? AIResumeDraftEventLabels.careerGoals
                      : AIResumeDraftEventLabels.professionalHighlights,
                  })
                } else {
                  trackInternalEvent(AIResumeDraftEvents.clickRetakeRecording, {
                    label: isFutureGoalsStep
                      ? AIResumeDraftEventLabels.careerGoals
                      : AIResumeDraftEventLabels.professionalHighlights,
                  })
                }
                // If audio is not recorded it will start recording or if processing has failed,
                // it will retake
                dispatch(
                  isFutureGoalsStep
                    ? actions.setGoalsVoiceProcessingStatus(SpeechToTextStatus.notStarted)
                    : actions.setAccomplishmentsVoiceProcessingStatus(
                        SpeechToTextStatus.notStarted,
                      ),
                )
                onStartRecording()
              } else {
                trackInternalEvent(
                  isFutureGoalsStep
                    ? AIResumeDraftEvents.clickCreateResume
                    : AIResumeDraftEvents.clickContinue,
                  {
                    label: isFutureGoalsStep
                      ? AIResumeDraftEventLabels.careerGoals
                      : AIResumeDraftEventLabels.professionalHighlights,
                  },
                )
                if (!audioProcessingComplete) {
                  // If audio is recorded and not yet processed
                  //, it will be sent to the speech to text API
                  dispatch(actions.extractTextFromVoiceInput({ audioBlob: audioChunks }))
                } else if (audioProcessingComplete) {
                  // If audio is recorded and already processed
                  //, it will not be sent to the speech to text API
                  isFutureGoalsStep ? onGenerateResume() : handleNext()
                }
              }
            } else {
              trackInternalEvent(AIResumeDraftEvents.clickStopRecording, {
                label: isFutureGoalsStep
                  ? AIResumeDraftEventLabels.careerGoals
                  : AIResumeDraftEventLabels.professionalHighlights,
                duration: duration,
              })
              // If it is recording
              onStopRecording()
              dispatch(
                isFutureGoalsStep
                  ? actions.setGoalsAudioDuration(duration)
                  : actions.setAccomplishmentsAudioDuration(duration),
              )
            }
          } else {
            // For Text input
            trackInternalEvent(
              isFutureGoalsStep
                ? AIResumeDraftEvents.clickCreateResume
                : AIResumeDraftEvents.clickContinue,
              {
                label: isFutureGoalsStep
                  ? AIResumeDraftEventLabels.careerGoals
                  : AIResumeDraftEventLabels.professionalHighlights,
              },
            )
            isFutureGoalsStep ? onGenerateResume() : handleNext()
          }
      }
      if (![5, 6].includes(step)) {
        trackInternalEvent(eventName, {
          label: label,
        })
      }
    },
    [
      activeAccomplishmentsTab,
      activeGoalsTab,
      cards,
      dispatch,
      education,
      educationCards,
      goalsAudioTabProps,
      goalsVoiceProcessingFailed,
      goalsVoiceProcessingComplete,
      handleNext,
      isFutureGoalsStep,
      onGenerateResume,
      accomplishmentsAudioTabProps,
      accomplishmentsVoiceProcessingFailed,
      accomplishmentsVoiceProcessingComplete,
      position,
      step,
      userInput.workExperience.cards,
      workExperience,
    ],
  )

  const toggleActiveTabOfOtherStep = useCallback(() => {
    const activeTab = isFutureGoalsStep ? activeGoalsTab : activeAccomplishmentsTab

    switch (activeTab) {
      case TABS.text_input:
        // Switch active tab of the other step, depending on the entered text
        if (isFutureGoalsStep) {
          if (pastAccomplishments.length === 0) {
            dispatch(actions.setActiveAccomplishmentsTab(TABS.voice_input))
          }
        } else {
          if (futureGoals.length === 0) {
            dispatch(actions.setActiveGoalsTab(TABS.voice_input))
          }
        }
        break
      case TABS.voice_input:
        // Switch active tab of the other step, depending on the recorded voice
        if (isFutureGoalsStep) {
          if (accomplishmentsAudio.length === 0) {
            dispatch(actions.setActiveAccomplishmentsTab(TABS.text_input))
          }
        } else {
          if (goalsAudio.length === 0) {
            dispatch(actions.setActiveGoalsTab(TABS.text_input))
          }
        }
        break
    }
  }, [
    accomplishmentsAudio.length,
    activeAccomplishmentsTab,
    activeGoalsTab,
    dispatch,
    isFutureGoalsStep,
    goalsAudio.length,
    pastAccomplishments.length,
    futureGoals.length,
  ])

  const onTabChange = useCallback(() => {
    const { audioChunks } = isFutureGoalsStep ? goalsAudioTabProps : accomplishmentsAudioTabProps
    const activeTab = isFutureGoalsStep ? activeGoalsTab : activeAccomplishmentsTab
    const textFromRedux = isFutureGoalsStep ? futureGoals : pastAccomplishments

    if (activeTab === TABS.text_input) {
      if (textFromRedux.length > 0) {
        // Show warning modal - switch to voice, if the text has been entered
        dispatch(
          actions.setShowAIResumeWarningModal({
            showModal: true,
            type: AIResumeWarningModalTypes.switchToVoice,
          }),
        )
      } else {
        // Switch to voice tab directly, if the text has not been entered
        trackInternalEvent(AIResumeDraftEvents.clickVoiceInput, {
          label: isFutureGoalsStep
            ? AIResumeDraftEventLabels.careerGoals
            : AIResumeDraftEventLabels.professionalHighlights,
        })
        dispatch(
          isFutureGoalsStep
            ? actions.setActiveGoalsTab(TABS.voice_input)
            : actions.setActiveAccomplishmentsTab(TABS.voice_input),
        )
        toggleActiveTabOfOtherStep()
      }
    } else if (activeTab === TABS.voice_input) {
      if (audioChunks.length > 0) {
        // Show warning modal - switch to text, if the audio has been recorded
        dispatch(
          actions.setShowAIResumeWarningModal({
            showModal: true,
            type: AIResumeWarningModalTypes.switchToText,
          }),
        )
      } else {
        // Switch to text tab directly, if the audio has not been recorded
        trackInternalEvent(AIResumeDraftEvents.clickTextInput, {
          label: isFutureGoalsStep
            ? AIResumeDraftEventLabels.careerGoals
            : AIResumeDraftEventLabels.professionalHighlights,
        })
        dispatch(
          isFutureGoalsStep
            ? actions.setActiveGoalsTab(TABS.text_input)
            : actions.setActiveAccomplishmentsTab(TABS.text_input),
        )
        toggleActiveTabOfOtherStep()
      }
    }
  }, [
    activeAccomplishmentsTab,
    activeGoalsTab,
    dispatch,
    futureGoals,
    goalsAudioTabProps,
    isFutureGoalsStep,
    pastAccomplishments,
    accomplishmentsAudioTabProps,
    toggleActiveTabOfOtherStep,
  ])

  const handleClose = () => {
    dispatch(
      actions.setShowAIResumeWarningModal({
        showModal: true,
        type: AIResumeWarningModalTypes.closeFlow,
      }),
    )
  }

  const renderFlow = useMemo(() => {
    if (aiResumeFlow === AIResumeFlows.LinkedIn) return <LinkedInFlow />
    if (aiResumeFlow === AIResumeFlows.Questionnaire) return <QuestionnaireFlow />
  }, [aiResumeFlow])

  const header = useMemo(() => {
    switch (step) {
      case 1:
        return {
          title: I18n.t('builder.ai_resume_draft.work_experience.title'),
          description: I18n.t('builder.ai_resume_draft.work_experience.description'),
        }
      case 2:
        return {
          title: I18n.t('builder.ai_resume_draft.desired_job.title'),
          description: I18n.t('builder.ai_resume_draft.desired_job.description'),
        }
      case 3:
        return {
          title: I18n.t('builder.ai_resume_draft.education.title'),
          description: I18n.t('builder.ai_resume_draft.education.description'),
        }
      case 4:
        return {
          title: I18n.t('builder.ai_resume_draft.skills_screen.title'),
          description: I18n.t('builder.ai_resume_draft.skills_screen.description'),
        }
      case 5:
        return {
          title: I18n.t('builder.ai_resume_draft.professional_highlights_screen.title'),
          description: I18n.t('builder.ai_resume_draft.professional_highlights_screen.description'),
        }
      case 6:
        return {
          title: I18n.t('builder.ai_resume_draft.career_goals.title'),
          description: I18n.t('builder.ai_resume_draft.career_goals.description'),
        }
      default:
        return {
          title: '',
          description: '',
        }
    }
  }, [step])

  const renderQuestionnaireStep = useMemo(() => {
    switch (step) {
      case 1:
        return <WorkExperienceStep />
      case 2:
        return <DesiredPosition />
      case 3:
        return <Education />
      case 4:
        return <Skills />
      case 5:
      case 6:
        const activeTab = isFutureGoalsStep ? activeGoalsTab : activeAccomplishmentsTab
        const audioTabProps = isFutureGoalsStep ? goalsAudioTabProps : accomplishmentsAudioTabProps
        return <AccomplishmentsAndGoals {...{ activeTab, onTabChange }} {...audioTabProps} />
    }
  }, [
    step,
    activeAccomplishmentsTab,
    activeGoalsTab,
    goalsAudioTabProps,
    isFutureGoalsStep,
    onTabChange,
    accomplishmentsAudioTabProps,
  ])

  const continueButtonText = useMemo(() => {
    const baseTranslationKey = 'builder.ai_resume_draft.'
    let translationKey = baseTranslationKey
    if (step === 5 || step === 6) {
      // For past accomplishments and future goals tab
      const { isRecording, audioChunks } = isFutureGoalsStep
        ? goalsAudioTabProps
        : accomplishmentsAudioTabProps
      const activeTab = isFutureGoalsStep ? activeGoalsTab : activeAccomplishmentsTab
      const voiceProcessingFailed = isFutureGoalsStep
        ? goalsVoiceProcessingFailed
        : accomplishmentsVoiceProcessingFailed

      if (activeTab === TABS.voice_input) {
        if (!isRecording) {
          if (audioChunks.length === 0) {
            // If recording has not started yet
            translationKey = baseTranslationKey + 'text_voice_input.start_recording'
          } else if (voiceProcessingFailed) {
            // If recording has failed
            translationKey = baseTranslationKey + 'text_voice_input.retake'
          } else if (isFutureGoalsStep && audioChunks.length > 0) {
            // If voice has been recorded for Future Goals
            translationKey = baseTranslationKey + 'text_voice_input.create_resume'
          }
        } else {
          // If recording is in progress
          translationKey = baseTranslationKey + 'text_voice_input.stop_recording'
        }
      } else if (isFutureGoalsStep && activeTab === TABS.text_input) {
        // If active tab for Future goals is Text input
        translationKey = baseTranslationKey + 'text_voice_input.create_resume'
      }
    }
    if (translationKey === baseTranslationKey) {
      // If none of the above conditions have met
      translationKey = baseTranslationKey + 'continue'
    }

    return I18n.t(translationKey)
  }, [
    activeAccomplishmentsTab,
    activeGoalsTab,
    isFutureGoalsStep,
    step,
    goalsAudioTabProps,
    goalsVoiceProcessingFailed,
    accomplishmentsAudioTabProps,
    accomplishmentsVoiceProcessingFailed,
  ])

  const showSkipButton = useMemo(() => {
    switch (step) {
      case 1:
        return false
      case 2:
        return isEmpty(position)
      case 3:
        return isEducationCardEmpty(educationCards[0])
      case 4:
        return currentSkills.length === 0
      case 5:
      case 6:
        const { isRecording, audioChunks } =
          step === 6 ? goalsAudioTabProps : accomplishmentsAudioTabProps
        const textToCheck = step === 6 ? futureGoals : pastAccomplishments
        return !(isRecording || audioChunks.length > 0 || textToCheck.length > 0)
      default:
        return true
    }
  }, [
    accomplishmentsAudioTabProps,
    currentSkills.length,
    educationCards,
    futureGoals,
    goalsAudioTabProps,
    pastAccomplishments,
    position,
    step,
  ])

  useEffect(() => {
    const { audioChunks } = isFutureGoalsStep ? goalsAudioTabProps : accomplishmentsAudioTabProps
    const audioFromRedux = isFutureGoalsStep ? goalsAudio : accomplishmentsAudio
    const activeTab = isFutureGoalsStep ? activeGoalsTab : activeAccomplishmentsTab

    if (audioFromRedux.length === 0 && audioChunks.length > 0 && activeTab === TABS.voice_input) {
      dispatch(
        isFutureGoalsStep
          ? actions.setGoalsAudio([...audioChunks])
          : actions.setAccomplishmentsAudio([...audioChunks]),
      )
    }
  }, [
    dispatch,
    isFutureGoalsStep,
    activeAccomplishmentsTab,
    activeGoalsTab,
    accomplishmentsAudio,
    accomplishmentsAudioTabProps,
    goalsAudio,
    goalsAudioTabProps,
  ])

  useEffect(() => {
    if (warningModalAction) {
      if (warningModalAction === AIResumeWarningModalActions.confirmDataLost) {
        dispatch(actions.setShowAIResumeModal(false))
        dispatch(actions.setShowPrefillResumeModal(true))
      }

      if (warningModalAction === AIResumeWarningModalActions.confirmClose) {
        dispatch(actions.setShowAIResumeModal(false))
        dispatch(actions.setShowPrefillResumeModal(false))
        dispatch(actions.resetAIResumeData())
      }

      if (warningModalAction === AIResumeWarningModalActions.removeJob) {
        dispatch(
          actions.deleteQuestionnaireCard({
            cardType: AIResumeQuestionnaireCardType.workExperience,
            deleteCardId: confirmDeleteCardId,
          }),
        )
      }

      if (step === 5 || step === 6) {
        // Past Accomplishments and Future Goals Step
        const activeTab = isFutureGoalsStep ? activeGoalsTab : activeAccomplishmentsTab
        const audioTabProps = isFutureGoalsStep ? goalsAudioTabProps : accomplishmentsAudioTabProps

        switch (warningModalAction) {
          case AIResumeWarningModalActions.retake:
            if (activeTab === TABS.voice_input) {
              const { onStartRecording } = audioTabProps
              dispatch(
                isFutureGoalsStep
                  ? actions.setGoalsVoiceProcessingStatus(SpeechToTextStatus.notStarted)
                  : actions.setAccomplishmentsVoiceProcessingStatus(SpeechToTextStatus.notStarted),
              )
              onStartRecording()
            }
            break
          case AIResumeWarningModalActions.switchToVoice:
            dispatch(
              isFutureGoalsStep
                ? actions.setActiveGoalsTab(TABS.voice_input)
                : actions.setActiveAccomplishmentsTab(TABS.voice_input),
            )
            dispatch(
              isFutureGoalsStep
                ? actions.setFutureGoalsText('')
                : actions.setPastAccomplishmentsText(''),
            )
            toggleActiveTabOfOtherStep()
            break
          case AIResumeWarningModalActions.switchToText:
            const { onClearRecording } = audioTabProps
            onClearRecording()
            dispatch(
              isFutureGoalsStep
                ? actions.setFutureGoalsText('')
                : actions.setPastAccomplishmentsText(''),
            )
            dispatch(
              isFutureGoalsStep ? actions.setGoalsAudio([]) : actions.setAccomplishmentsAudio([]),
            )
            dispatch(
              isFutureGoalsStep
                ? actions.setGoalsAudioDuration(0)
                : actions.setAccomplishmentsAudioDuration(0),
            )
            dispatch(
              isFutureGoalsStep
                ? actions.setActiveGoalsTab(TABS.text_input)
                : actions.setActiveAccomplishmentsTab(TABS.text_input),
            )
            dispatch(
              isFutureGoalsStep
                ? actions.setGoalsVoiceProcessingStatus(SpeechToTextStatus.notStarted)
                : actions.setAccomplishmentsVoiceProcessingStatus(SpeechToTextStatus.notStarted),
            )
            toggleActiveTabOfOtherStep()
            break
        }
      }
      dispatch(actions.setAIResumeWarningModalAction(null))
    }
  }, [
    step,
    activeGoalsTab,
    activeAccomplishmentsTab,
    dispatch,
    isFutureGoalsStep,
    warningModalAction,
    accomplishmentsAudioTabProps,
    goalsAudioTabProps,
    toggleActiveTabOfOtherStep,
    confirmDeleteCardId,
  ])

  return {
    continueButtonText,
    renderFlow,
    progress,
    isFutureGoalsStep,
    step,
    header,
    handleBack,
    handleMobileBack,
    handleContinue,
    handleClose,
    renderQuestionnaireStep,
    showSkipButton,
  }
}

export default useResumeWithAI
