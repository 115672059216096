import { useState } from 'react'
import { selectors as userSelectors } from 'builder/modules/user'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import TrackJobsImage from '../../assets/img/TrackJobs.svg'
import * as Styled from './styles'

export const TrackJobs = () => {
  const userPhotoUrl = useTypedSelector(userSelectors.photoUrl)
  const [isHovered, setIsHovered] = useState(true)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }
  return (
    <Styled.Container
      onMouseOver={handleMouseEnter}
      onMouseOut={handleMouseLeave}
      onHover={isHovered}
    >
      <Styled.VisualImageContainer>
        <Styled.VisualImage src={TrackJobsImage} />
        {userPhotoUrl && <Styled.UserImage src={userPhotoUrl} />}
      </Styled.VisualImageContainer>
    </Styled.Container>
  )
}
