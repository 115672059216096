import { memo } from 'react'
import { i18n } from 'builder/utils/i18n'
import ModalOverlay from 'builder/components/ModalOverlay'
import { ButtonSize } from 'builder/components/Button'
import { InputChangeEvent } from 'builder/modules/ui'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import {
  ButtonContainer,
  Cancel,
  CloseIcon,
  Container,
  Description,
  HeaderSection,
  InputField,
  Save,
  Title,
} from './styles'
import { EditFields } from './types'

type EditJobTitleProps = {
  newJobTitle: string
  newEmployerName: string
  isSaveButtonDisabled: boolean
  onJobTitleChange: (event: InputChangeEvent) => void
  onCompanyNameChange: (event: InputChangeEvent) => void
  onSaveJobDetails: () => void
  onCancelJobDetails: () => void
  onCancelEditJobDetails: () => void
}

const EditJobTitleMobile = memo(
  ({
    newJobTitle,
    newEmployerName,
    onJobTitleChange,
    onCompanyNameChange,
    onCancelJobDetails,
    onSaveJobDetails,
    onCancelEditJobDetails,
    isSaveButtonDisabled,
  }: EditJobTitleProps) => {
    const { isPhone } = useMediaQueries()
    const buttonSize = !isPhone ? ButtonSize.default : ButtonSize.small

    const MapEditFields = [
      {
        key: EditFields.jobTitle,
        label: i18n.t('builder.resume_optimizer.edit_job_details.job_title'),
        value: newJobTitle,
        onValueChange: onJobTitleChange,
      },
      {
        key: EditFields.companyName,
        label: i18n.t('builder.resume_optimizer.edit_job_details.company_name'),
        value: newEmployerName,
        onValueChange: onCompanyNameChange,
      },
    ]

    return (
      <ModalOverlay>
        <Container>
          <HeaderSection>
            <Title>{i18n.t('builder.resume_optimizer.edit_job_details.edit_job_title')}</Title>
            <CloseIcon onClick={onCancelEditJobDetails} />
          </HeaderSection>
          <Description>{i18n.t('builder.ai_cover_letter.edit_job_details.content')}</Description>
          {MapEditFields.map(field => (
            <InputField
              key={field.key}
              label={field.label}
              value={field.value}
              onChange={field.onValueChange}
            />
          ))}
          <ButtonContainer>
            <Cancel size={buttonSize} theme="ghost" onClick={onCancelJobDetails}>
              {i18n.t('builder.resume_optimizer.job_posting.cancel')}
            </Cancel>
            <Save size={buttonSize} onClick={onSaveJobDetails} isDisabled={isSaveButtonDisabled}>
              {i18n.t('builder.resume_optimizer.edit_job_details.save')}
            </Save>
          </ButtonContainer>
        </Container>
      </ModalOverlay>
    )
  },
)

export default EditJobTitleMobile
