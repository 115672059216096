import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Avatar = styled.img`
  width: 88px;
  height: 88px;
  border-radius: 50%;
  margin-bottom: 16px;

  ${Media.Phone`
    box-sizing: border-box;
    width: 64px;
    height: 64px;
  `}
`

export const Name = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};
  text-align: center;
  margin-bottom: 8px;
`

export const Occupation = styled.div`
  ${Typography.Subhead};
`
