import { memo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { i18n as I18n } from 'builder/utils/i18n'
import { actions } from 'builder/modules/resumeEditor'
import {
  AIResumeDraftEventLabels,
  AIResumeDraftEvents,
  AIResumeLinkedInSteps,
} from 'builder/modules/constants'
import { useAppSelector } from 'builder/hooks/useAppSelector'
import avatar from '../../../../../../app/assets/images/builder/ai-resume/linkedin-avatar.svg'
import {
  LinkedInProfileHeading,
  LinkedInProfileCard,
  Name,
  Designation,
  Avatar,
  Div,
  Actions,
  TryAnotherLink,
  Revert,
  GenerateButton,
} from './styles'

const LinkedInProfile = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    trackInternalEvent(AIResumeDraftEvents.seeLinkedInProfileScreen, {
      label: AIResumeDraftEventLabels.aiResume,
    })
  }, [])

  const { linkedInProfileData } = useAppSelector(store => store.resumeEditor.aiResume.linkedInData)

  const { firstName, lastName, headline } = linkedInProfileData
  const onTryAnotherLink = () => {
    trackInternalEvent(AIResumeDraftEvents.clickTryAgain, {
      label: AIResumeDraftEventLabels.linkedInProfileScreen,
    })
    dispatch(actions.setAIResumeLinkedInStep(AIResumeLinkedInSteps.linkedInPrefill))
  }

  const onGenerateClick = () => {
    trackInternalEvent(AIResumeDraftEvents.clickGenerate, {
      label: AIResumeDraftEventLabels.linkedInProfileScreen,
    })
    dispatch(actions.generateResumeFromLinkedIn())
  }

  return (
    <>
      <LinkedInProfileHeading>
        {I18n.t('builder.ai_resume_draft.linkedin.profile.title')}
      </LinkedInProfileHeading>
      <LinkedInProfileCard>
        <Avatar src={avatar} alt="LinkedIn Avatar" />
        <Div>
          <Name>
            {firstName} {lastName}
          </Name>
          <Designation>{headline}</Designation>
        </Div>
      </LinkedInProfileCard>
      <Actions>
        <TryAnotherLink onClick={onTryAnotherLink}>
          <Revert />
          {I18n.t('builder.ai_resume_draft.use_another_link')}
        </TryAnotherLink>
        <GenerateButton onClick={onGenerateClick}>
          {I18n.t('builder.ai_resume_draft.generate')}
        </GenerateButton>
      </Actions>
    </>
  )
}

export default memo(LinkedInProfile)
