import { css } from 'styled-components'
import Colors from 'builder/styles/colors'

type ScrollBarOptions = {
  background?: string
}

export const formatScrollBarStyles = (options?: ScrollBarOptions) => {
  const background = options?.background || Colors.Neutral10

  return css`
    &::-webkit-scrollbar {
      width: 12px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${Colors.Neutral40};
      border-radius: 24px;
      border: 4px solid ${background};
    }
  `
}
