import { Component } from 'react'
import { Portal } from 'react-portal'
import PropTypes from 'prop-types'
import Transition from 'react-transition-group/Transition'

import { Container, Content, Overlay } from './styles'

const defaultWindowStyle = {
  transform: 'translateY(1500px)',
  transition: `transform 0.4s ease`,
}

const transitionWindowStyles = {
  exited: { transform: 'translateY(1500px)' },
  entered: { transform: 'translateY(0)' },
  exiting: { transform: 'translateY(1500px)' },
}

const defaultOverlayStyle = {
  opacity: 0,
  transition: `opacity 0.4s ease`,
}

const transitionOverlayStyles = {
  exited: { opacity: 0 },
  entered: { opacity: 0.5 },
  exiting: { opacity: 0 },
}

class MagicModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    children: PropTypes.element,
  }

  constructor(props) {
    super(props)
    this.root = document.createElement('div')
  }

  componentDidMount() {
    document.body.appendChild(this.root)
  }

  componentWillUnmount() {
    document.body.removeChild(this.root)
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen && !prevProps.isOpen) {
      document.body.style.overflow = 'hidden'
    } else if (!this.props.isOpen && prevProps.isOpen) {
      document.body.style.overflow = ''
    }
  }

  render() {
    const { children, isOpen } = this.props

    return (
      <Portal node={this.root}>
        <Transition
          in={isOpen}
          timeout={{
            enter: 100,
            exit: 300,
          }}
          mountOnEnter
          unmountOnExit
        >
          {state => {
            return (
              <Container>
                <Content
                  style={{
                    ...defaultWindowStyle,
                    ...transitionWindowStyles[state],
                  }}
                >
                  {children}
                </Content>
                <Overlay
                  style={{
                    ...defaultOverlayStyle,
                    ...transitionOverlayStyles[state],
                  }}
                />
              </Container>
            )
          }}
        </Transition>
      </Portal>
    )
  }
}

export default MagicModal
