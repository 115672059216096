import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'

interface CircleButtonOptions {
  direction?: 'left' | 'right'
  size?: number
}

export const CircleButton = styled.button<CircleButtonOptions>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  width: ${props => props.size || 48}px;
  height: ${props => props.size || 48}px;
  border: none;
  border-radius: 50%;
  background-color: ${Colors.Blue50};
  color: ${Colors.White};
  transition: background-color 0.3s ease;

  ${props =>
    props.direction === 'left' &&
    css`
      transform: rotate(180deg);
    `}

  &:focus,
  &:hover {
    outline: none;
    background-color: ${Colors.Blue70};
  }
`
