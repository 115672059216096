import styled from 'styled-components'

import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const CustomTextFieldHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 4px;
`

export const CustomTextFieldLabel = styled.div`
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
  margin-bottom: 4px;
`
