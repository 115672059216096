import { useEffect } from 'react'
import { connect } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { FetchStatuses } from 'builder/modules/constants'
import { actions } from 'builder/modules/jobTracking'
import JobTracking from 'builder/components/JobTracking'
import { Store } from 'builder/modules/store'

// TODO: Move data connections to "JobTracking" component
// once it is rewritten in the functional style.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const JobTrackingView = (props: any) => {
  // Track the page opening
  useEffect(() => {
    trackInternalEvent('visit_job_tracking')
  }, [])

  return <JobTracking {...props} />
}

// ---
// Connect to data
// ---
const mapStateToProps = (state: Store) => ({
  jobs: state.jobTracking.jobs,
  isJobsLoading: state.jobTracking.fetchStatus !== FetchStatuses.loaded,
  columnNames: state.jobTracking.columnNames,
})

const mapDispatchToProps = {
  fetchJobs: actions.fetchJobsRequest,
  createJob: actions.createJobRequest,
  deleteJob: actions.deleteJobRequest,
  editJob: actions.editJobRequest,
  move: actions.moveRequest,
  reorder: actions.reorderRequest,
}

export default connect(mapStateToProps, mapDispatchToProps)(JobTrackingView)
