import { YouTubeProps, YouTubeEvent } from 'react-youtube'
import { useEffect, useRef, useState } from 'react'
import {
  CloseFullScreenIcon,
  CloseIcon,
  Controls,
  ExitFullscreenButton,
  FullscreenButton,
  FullScreenIcon,
  MuteIcon,
  PauseIcon,
  PlayButton,
  PlayIcon,
  SoundButton,
  SoundIcon,
  Video,
  VideoPlayerContainer,
} from './styles'

type Options = {
  rel?: number
  loop?: number
  mute?: number
  autoplay?: number
  playsinline?: number
  enablejsapi?: number
  modestbranding?: number
  controls?: number
}

type Props = {
  videoId: string
  onPlay?: () => void
  onPause?: () => void
  onMute?: () => void
  onUnMute?: () => void
  onToggleFullScreen?: () => void
  onEnded?: () => void
  onAutoPlay?: () => void
  options: Options
  className?: string
} & YouTubeProps

export const YoutubeVideoPlayer = ({
  videoId,
  options,
  className,
  onPlay,
  onPause,
  onMute,
  onUnMute,
  onToggleFullScreen,
  onEnded,
  onAutoPlay,
  ...rest
}: Props) => {
  const playerRef = useRef<HTMLDivElement>(null)
  const [videoRef, setVideoRef] = useState<YouTubeEvent>(() => ({ data: null, target: null }))
  const [isHover, setIsHover] = useState(false)
  const [isMuted, setIsMuted] = useState(true)
  const [isPlaying, setIsPlaying] = useState(true)
  const [isFullScreen, setIsFullScreen] = useState(false)

  const onPlayerReady: YouTubeProps['onReady'] = event => {
    setVideoRef(event)
    if (onAutoPlay) onAutoPlay()
  }

  const togglePlayPause = () => {
    if (isPlaying) {
      videoRef.target?.pauseVideo()
      if (onPause) onPause()
    } else {
      videoRef?.target?.playVideo()
      if (onPlay) onPlay()
    }
    setIsPlaying(!isPlaying)
  }

  const toggleMuteUnMute = () => {
    if (isMuted) {
      videoRef?.target?.unMute()
      if (onUnMute) onUnMute()
    } else {
      videoRef?.target?.mute()
      if (onMute) onMute()
    }
    setIsMuted(!isMuted)
  }

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      if (playerRef.current?.requestFullscreen) {
        playerRef.current?.requestFullscreen()
      }
      setIsFullScreen(true)
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      }
      setIsFullScreen(false)
    }
    if (onToggleFullScreen) onToggleFullScreen()
  }

  const onVideoEnded = () => {
    setIsPlaying(false)
    if (onEnded) onEnded()
  }

  const handleFullScreenChange = () => {
    setIsFullScreen(!!document.fullscreenElement)
  }

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullScreenChange)
    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange)
    }
  }, [])

  return (
    <VideoPlayerContainer
      ref={playerRef}
      onBlur={() => setIsHover(false)}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onPointerEnter={() => setIsHover(true)}
      className={`${className} ${isFullScreen ? 'fullscreen' : ''}`}
    >
      <Video
        videoId={videoId}
        opts={{
          playerVars: {
            playsinline: 1,
            enablejsapi: 1,
            origin: window.location.origin,
            ...options,
          },
        }}
        onReady={onPlayerReady}
        onEnd={onVideoEnded}
        className={isFullScreen ? 'fullscreen' : ''}
        {...rest}
      />

      <Controls>
        {isHover && (
          <PlayButton onClick={togglePlayPause} className={isFullScreen ? 'fullscreen' : ''}>
            <PauseIcon />
          </PlayButton>
        )}
        {!isPlaying && (
          <PlayButton onClick={togglePlayPause} className={isFullScreen ? 'fullscreen' : ''}>
            <PlayIcon />
          </PlayButton>
        )}
        <SoundButton onClick={toggleMuteUnMute} className={isFullScreen ? 'fullscreen' : ''}>
          {isMuted ? <MuteIcon /> : <SoundIcon />}
        </SoundButton>
        <FullscreenButton className={isFullScreen ? 'fullscreen' : ''} onClick={toggleFullScreen}>
          {isFullScreen ? <CloseFullScreenIcon /> : <FullScreenIcon />}
        </FullscreenButton>
        {isFullScreen && (
          <ExitFullscreenButton onClick={toggleFullScreen}>
            <CloseIcon />
          </ExitFullscreenButton>
        )}
      </Controls>
    </VideoPlayerContainer>
  )
}
