export enum SectionNames {
  details = 'details',
  profile = 'profile',
  workExperiences = 'workExperiences',
  educations = 'educations',
  courses = 'courses',
  internships = 'internships',
  activities = 'activities',
  skills = 'skills',
  languages = 'languages',
  hobbies = 'hobbies',
  references = 'references',
  custom = 'custom',
  socialProfiles = 'socialProfiles',
  accomplishments = 'accomplishments',
  powerStatement = 'powerStatement',
}
