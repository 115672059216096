import { Container, Text, Value } from './styles'

interface Props {
  text: string
  value: string
}

const Label = ({ text, value }: Props) => {
  return (
    <Container>
      <Text>{text}</Text>
      <Value>{value}</Value>
    </Container>
  )
}

export default Label
