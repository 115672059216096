import styled from 'styled-components'
import Colors from 'builder/styles/colors'

export const Container = styled.div`
  position: relative;
  border-radius: 24px;
  border: 1px solid ${Colors.Neutral15};
  display: flex;
  flex-direction: column;
`

export const HeaderContainer = styled.div`
  border-radius: 12px;
  background: ${Colors.Neutral5};
  margin: 16px 16px 0;
`

export const ListContainer = styled.div`
  margin: 0 31px 31px;
  position: relative;
`

export const PremiumContainerBlur = styled.div`
  width: 542px;
  height: 195px;
  flex-shrink: 0;
  background: rgba(255, 255, 255, 0.2);

  backdrop-filter: blur(6px);
  position: absolute;
  left: -31px;
`

export const SkeletonContainer = styled.div`
  margin-top: 8px;
`
