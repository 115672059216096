import { useDispatch } from 'react-redux'
import { useUser } from 'builder/hooks/useUser'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { actions as renderingActions } from 'builder/modules/rendering'
import { actions as uiActions } from 'builder/modules/ui'
import { UserType } from 'builder/modules/user'

export const useResumeDistribution = () => {
  const user = useUser()
  const dispatch = useDispatch()
  const { features } = useFeaturesConfig()
  const isUserRdPromotedWithoutPlan =
    features?.rdPromo?.rdPromoPlan && !user?.scopes.resumeDistribution

  const showRDUpsellPromoModal = () => {
    if (user?.billingInfo.userType === UserType.postPremium) {
      dispatch(uiActions.setTrackJobPaywall(true))
    }

    if (isUserRdPromotedWithoutPlan) {
      dispatch(renderingActions.toggleRDUpsellPromoModal(true))
      return true
    }
  }

  return { showRDUpsellPromoModal, isUserRdPromotedWithoutPlan }
}
