import styled from 'styled-components'
import { TransitionStatus } from 'react-transition-group'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'
import { TooltipQuestion } from 'builder/components/Tooltip/variations'
import { TextFieldIconPosition, TextFieldInputTheme } from './types'

export const Group = styled.div``

export const InputWrapper = styled.div`
  position: relative;
`

export const Bar = styled.div<{ hasError?: boolean; hasWarning?: boolean; in: TransitionStatus }>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: ${({ hasError, hasWarning }) => {
    if (hasError) return Colors.Red50
    if (hasWarning) return Colors.Amber40
    return Colors.Blue50
  }};
  border-radius: 0 0 2px 2px;
  pointer-events: none;

  ${props =>
    ({
      entering: 'opacity: 1; transform: scaleX(1); transition: transform 0.1s ease;',
      entered: 'opacity: 1; transform: scaleX(1)',
      exiting: 'opacity: 0; transform: scaleX(1); transition: opacity 0.1s ease;',
      exited: 'opacity: 0; transform: scaleX(0.5)',
      unmounted: null,
    }[props.in])};
`

export const Input = styled.input<{
  inputTheme: TextFieldInputTheme
  iconPosition?: TextFieldIconPosition
}>`
  border-radius: 3px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: ${props => (props.iconPosition === 'left' ? '36px' : '16px')};
  padding-right: ${props => (props.iconPosition === 'right' ? '36px' : '16px')};
  width: 100%;
  display: block;
  caret-color: ${Colors.Blue50};
  background-color: ${props => (props.inputTheme === 'white' ? Colors.White : Colors.Neutral10)};
  outline: none;
  border: 0;
  color: ${Colors.Neutral90};
  transition: color 0.1s ease;

  textarea& {
    resize: none;
  }

  &:disabled {
    pointer-events: none;
    color: ${Colors.Neutral50};
  }

  &::placeholder {
    color: ${Colors.Neutral40};
    opacity: 1;
  }
`

export const InputIconWrapper = styled.span<{ iconPosition: TextFieldIconPosition }>`
  position: absolute;
  ${props => props.iconPosition}: 8px;
  top: 50%;
  transform: translateY(-50%);
  color: ${Colors.Blue50};
  pointer-events: none;

  & svg {
    display: block;
  }
`

export const LabelContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
`

export const Label = styled.label<{ hasError?: boolean }>`
  display: flex;
  align-items: center;
  ${Typography.Caption};
  color: ${({ hasError }) => (hasError ? Colors.Red50 : Colors.Neutral50)};
`

export const LabelContent = styled.div`
  display: inline-block;
`

export const LabelHint = styled(TooltipQuestion)`
  margin-left: 6px;

  ${Media.Tablet`
    display: none;
  `};
`

export const Required = styled.span`
  color: ${Colors.Red50};
`
