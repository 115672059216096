import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Icon32 } from 'builder/components/Icon'
import Colors from 'builder/styles/colors'
import Button, { ButtonSize } from 'builder/components/Button'

export const Container = styled.div``

export const Divider = styled.div`
  height: 1px;
  margin: 0 24px;
  background: ${Colors.Neutral15};
`

export const PremiumContainer = styled.div`
  padding: 20px 24px;
  display: flex;
  gap: 32px;
  align-items: center;
`

export const PremiumTextContainer = styled.div`
  display: flex;
  gap: 12px;
`

export const PremiumText = styled.p`
  ${Typography.Body}
  ${FontWeights.Regular}

  color: ${Colors.Neutral90};
`

export const PremiumIcon = styled(Icon32.Crown)`
  width: 50px;
  margin-right: -5px;
  color: ${Colors.Indigo50};
`

export const PremiumContainerAction = styled(Button).attrs({
  size: ButtonSize.small,
})`
  background-color: ${Colors.Indigo50};
`
