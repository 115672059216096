import SwiperCore, { Navigation, Scrollbar, Mousewheel, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'

import { CoachBanner } from '../Banner/Coach/CoachBanner'
import { JobSearchBanner } from '../Banner/JobSearch/JobSearchBanner'
import { Container, ContainerPhone } from './styles'

SwiperCore.use([Navigation, Scrollbar, Mousewheel, Autoplay])

export const BannersContainer = () => {
  const { isPhone } = useMediaQueries()
  const { features } = useFeaturesConfig()

  if (isPhone) {
    return (
      <ContainerPhone>
        <Swiper
          grabCursor
          slidesPerView="auto"
          direction="horizontal"
          scrollbar={true}
          mousewheel={true}
        >
          {features.careerCoaching && (
            <SwiperSlide>
              <CoachBanner />
            </SwiperSlide>
          )}
          {features.jobSearchPlan && (
            <SwiperSlide>
              <JobSearchBanner />
            </SwiperSlide>
          )}
        </Swiper>
      </ContainerPhone>
    )
  }

  return (
    <Container>
      {features.careerCoaching && <CoachBanner />}
      {features.jobSearchPlan && <JobSearchBanner />}
    </Container>
  )
}
