import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Button from 'builder/components/Button'
import { COLUMN_WIDTH } from '../styles'
import { Typography, FontWeights } from 'builder/styles/typography'

export const Container = styled.form`
  position: relative;
  padding: 16px;
  margin-bottom: 8px;
  background-color: ${Colors.White};
  border-radius: 6px;

  ${Media.AbovePhone`
    max-width: ${COLUMN_WIDTH}px;
  `};
`

export const Input = styled.input`
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  caret-color: ${Colors.Blue50};

  &::placeholder {
    opacity: 1;
    color: ${Colors.Neutral50};
  }
`

export const Actions = styled.div`
  display: flex;
  margin-top: 16px;
`

export const SaveButton = styled(Button).attrs({ size: 'small' })``

export const CancelButton = styled.button`
  ${Typography.ButtonS};
  ${FontWeights.DemiBold};
  margin: 0 0 0 4px;
  padding: 8px;
  border: none;
  background: none;
  color: ${Colors.Blue50};
  transition: color 0.2s;
  cursor: pointer;

  &:hover {
    color: ${Colors.Blue70};
  }
`
