import { formatDate } from 'builder/utils/formatDate'
import { i18n } from 'builder/utils/i18n'

export const formatDateRange = payload => {
  const { dateFrom, dateUntil, isMonthFromHidden, isMonthUntilHidden, isDateUntilPresent } = payload

  const strFrom = dateFrom && formatDate(dateFrom, isMonthFromHidden ? 'yyyy' : 'LLL yyyy')

  const strUntil = isDateUntilPresent
    ? i18n.t('builder.date_range.present')
    : dateUntil && formatDate(dateUntil, isMonthUntilHidden ? 'yyyy' : 'LLL yyyy')

  return [strFrom, strUntil].filter(x => x).join(' - ')
}
