import { Fragment } from 'react'
import uniqueId from 'lodash/uniqueId'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useConstant } from 'builder/hooks/useConstant'
import { LevelConnectionProps } from './types'
import * as Styles from './styles'

export const Level1 = ({ type, connections, gradient: { from, to } }: LevelConnectionProps) => {
  const { isPhone } = useMediaQueries()
  const width = isPhone ? 24 : 32

  const id1 = useConstant(() => uniqueId('l11'))
  const id2 = useConstant(() => uniqueId('l12'))
  const id3 = useConstant(() => uniqueId('l13'))

  return (
    <Styles.Container
      level={1}
      type={type}
      width={width}
      height="304"
      viewBox={`0 0 ${width} 304`}
      xmlns="http://www.w3.org/2000/svg"
      isPhone={isPhone}
    >
      {connections.includes(1) && (
        <Fragment>
          <path d={`M0 2H${width}`} stroke={`url(#${id1})`} strokeWidth="4" />
          <defs>
            <linearGradient
              id={id1}
              x1={isPhone ? 4.5 : 6}
              y1="1.00002"
              x2={isPhone ? 19.5 : 26}
              y2="1.00002"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor={from} />
              <stop offset="1" stopColor={to} />
            </linearGradient>
          </defs>
        </Fragment>
      )}
      {connections.includes(2) && (
        <Fragment>
          <path
            d={
              isPhone
                ? 'M0 2V2C6.62742 2 12 7.37258 12 14V134C12 140.627 17.3726 146 24 146V146'
                : 'M0 2V2C8.83656 2 16 9.16344 16 18V136C16 144.837 23.1634 152 32 152V152'
            }
            stroke={`url(#${id2})`}
            strokeWidth="4"
          />
          <defs>
            <linearGradient
              id={id2}
              x1="6"
              y1={isPhone ? -301.979 : -164.997}
              x2="26"
              y2={isPhone ? -301.979 : -164.997}
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor={from} />
              <stop offset="1" stopColor={to} />
            </linearGradient>
          </defs>
        </Fragment>
      )}
      {connections.includes(3) && (
        <Fragment>
          <path
            d={
              isPhone
                ? 'M0 2V2C6.62742 2 12 7.37258 12 14V276C12 282.627 17.3726 288 24 288V288'
                : 'M0 2V2C8.83656 2 16 9.16344 16 18V286C16 294.837 23.1634 302 32 302V302'
            }
            stroke={`url(#${id3})`}
            strokeWidth="4"
          />
          <defs>
            <linearGradient
              id={id3}
              x1="6"
              y1={isPhone ? -301.979 : -331.995}
              x2="26"
              y2={isPhone ? -301.979 : -331.995}
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor={from} />
              <stop offset="1" stopColor={to} />
            </linearGradient>
          </defs>
        </Fragment>
      )}
    </Styles.Container>
  )
}
