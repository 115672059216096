import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { HorizontalTransitionFadeV2 } from 'builder/components/HorizontalTransitionFadeV2'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Sizes from 'builder/styles/sizes'
import { Icon24 } from 'builder/components/Icon'
import AutoSuggestField from 'builder/components/FindJob/AutoSuggestField'

export const Animation = styled(HorizontalTransitionFadeV2)``

export const Container = styled.div`
  width: 100%;
  max-width: calc(736px + ${Sizes.M} * 2);
`

export const Content = styled.div`
  padding: ${Sizes.XL} ${Sizes.M} 0;
  gap: ${Sizes.M};
  display: flex;
  flex-flow: column;
`

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${Typography.M};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral100};
  gap: ${Sizes.XS};

  ${Media.Tablet`
    justify-content: start;
  `}

  ${Media.Phone`
    justify-content: start;
    flex-flow: column;
    align-items: start;
  `}
`

export const EditAccountLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: ${Sizes['3XS']};
  ${Typography.Caption};
  ${FontWeights.Medium};
  color: ${Colors.Blue50};
  will-change: color;
  transition: color 0.2s ease-out;

  ${Media.Hover`
    &:hover {
      color: ${Colors.Blue60};
    }
  `}
`

export const Body = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${Sizes.XL};
`

export const PersonalDetailsContainer = styled.div<{ value: string }>``

export const JobPreferenceContainer = styled.div<{ value: string }>``

export const Footer = styled.div`
  background-color: ${Colors.White};
  position: sticky;
  bottom: 0;
  z-index: 3;
  padding: ${Sizes.M};
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${Sizes['2XS']};
`

export const ItemContainer = styled.div`
  border: 1px solid ${Colors.Neutral15};
  border-radius: 4px;
  background-color: ${Colors.White};
  display: flex;
  flex-direction: column;
  padding: 24px 20px;
  margin-top: 12px;
  gap: ${Sizes.XS};

  ${Media.Phone`
    padding: 20px 18px;
  `}
`

export const ItemTitleContainer = styled.div`
  display: flex;
  gap: ${Sizes['3XS']};
  align-items: center;
`

export const ItemTitle = styled.span`
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral90};
  ${Typography.Caption};
`

export const RoleIcon = styled(Icon24.CircleWithLines)``

export const LocationIcon = styled(Icon24.NewLocation)`
  color: ${Colors.Neutral100};
`

export const ItemHint = styled.p`
  color: ${Colors.Neutral50};
  ${Typography.Caption};
`

export const AutoSuggestContainer = styled.div<{ $isExpanded?: boolean }>`
  background-color: ${Colors.Neutral10};
  border-radius: 12px;
  z-index: 2;
  width: 100%;

  ${props =>
    props.$isExpanded &&
    css`
      background: ${Colors.White};
    `}
`

export const ItemError = styled.p`
  ${Typography.Caption};
  color: ${Colors.Red50};
`

export const SearchInputIcon = styled(Icon24.Search)`
  color: ${Colors.Neutral40};
`

export const LocationInputIcon = styled(Icon24.NewLocation)`
  color: ${Colors.Neutral40};
`

export const HeadersContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  height: fit-content;
  top: 128px;
  margin-right: 8px;
`

export const OptionText = styled.div<{ isSelected: boolean }>`
  ${FontWeights.Medium};
  color: ${Colors.Neutral60};
  cursor: pointer;
  white-space: nowrap;
  ${Typography.Caption};
  padding: 10px ${Sizes.XS};

  ${props =>
    props.isSelected &&
    `
    color: ${Colors.Blue50};
    border-radius: ${Sizes.XS};
    background-color: ${Colors.Blue10};
  `}

  &:hover {
    color: ${Colors.Blue50};
  }
`

export const Screen = styled.div<{ isTablet: boolean }>`
  display: flex;
  position: relative;
  margin: 0 auto;
  ${props => props.isTablet && `width: 100%;`}
  ${props => !props.isTablet && `left: -160px;`}
`

export const CustomAutoSuggestField = styled(AutoSuggestField)`
  background-color: ${Colors.Neutral10};
  border-radius: ${Sizes.XS};
`
