import { useCallback, useEffect, useMemo, useState } from 'react'
import * as React from 'react'
import { useI18n } from 'builder/hooks/useI18n'
import { MODES, DEFAULT_BACKGROUND } from '../constants'
import { Position, AvatarEditor, Background, BackgroundColor } from '../types'
import { areEqualBackgrounds, getBackgroundColorForImage } from '../utils'
import VerticalZoomSlider from '../VerticalZoomSlider'
import RotateSlider from '../RotateSlider'
import BackgroundGallery from '../BackgroundGallery'
import BackgroundColorPicker from '../BackgroundColorPicker'
import {
  CropperContainer,
  CropperPreviewContainer,
  CropperTitle,
  CropperBackgroundPreview,
  CropperAvatarEditor,
  CropperRulers,
  CropperPreviewLoader,
  RotateLeftButton,
  RotateRightButton,
  RotateButtonContent,
  RotateButtonLabel,
  RotateButtonIcon,
} from './styles'
import { useAngle, useDimensions } from './hooks'

interface CropperProps {
  mode: MODES
  editorRef: React.RefObject<AvatarEditor>
  imageUrl: string
  noBgImageUrl: string | null
  x: number
  y: number
  zoom: number
  angle: number
  background: Background
  onMove: (position: Position) => void
  onZoom: (zoom: number) => void
  onRotate: (angle: number) => void
  onBackgroundChange: (background: Background) => void
}

export const Cropper = ({
  mode,
  editorRef,
  imageUrl,
  noBgImageUrl,
  zoom,
  angle,
  x,
  y,
  background,
  onMove,
  onZoom,
  onRotate,
  onBackgroundChange,
}: CropperProps) => {
  const { i18n } = useI18n()

  // Dimensions
  const cropSquareSize = 240
  const [previewContainerRef, previewWidth, previewHeight] = useDimensions()
  const borderX = (previewWidth - cropSquareSize) / 2
  const borderY = (previewHeight - cropSquareSize) / 2

  // Preview image
  const [isImageLoaded, setImageLoaded] = useState<boolean>(false)
  const hasCustomBg = !areEqualBackgrounds(background, DEFAULT_BACKGROUND)
  const previewImageUrl = hasCustomBg ? noBgImageUrl : imageUrl

  // Custom color
  const [customColor, setCustomColor] = useState<BackgroundColor>(() => background.color)

  // Manage preview image loading state
  useEffect(() => {
    setImageLoaded(false)
  }, [previewImageUrl])

  const handleImageLoad = useCallback(() => setImageLoaded(true), [])

  // Split angle value
  const { baseAngle, freeAngle } = useAngle(angle)

  // Preserve a link of the position object to avoid extra updates inside of `react-avatar-editor`
  const position = useMemo<Position>(() => ({ x, y }), [x, y])

  const handleFreeRotate = useCallback(
    (newFreeAngle: number) => {
      onRotate(baseAngle + newFreeAngle)
    },
    [baseAngle, onRotate],
  )

  const handleCustomColorChange = useCallback(
    (newCustomColor: BackgroundColor) => {
      setCustomColor(newCustomColor)

      // Override active background color if there is a custom pattern selected
      if (background.color) {
        onBackgroundChange({
          ...background,
          color: getBackgroundColorForImage(background.image, newCustomColor),
        })
      }
    },
    [background, onBackgroundChange],
  )

  return (
    <CropperContainer>
      {mode === MODES.crop && (
        <CropperTitle>{i18n.t('builder.avatar_editor.cropper.title')}</CropperTitle>
      )}

      <CropperPreviewContainer ref={previewContainerRef}>
        {/* Active background preview */}
        <CropperBackgroundPreview size={cropSquareSize} background={background} />

        {/* Avatar image preview/editor */}
        {previewImageUrl && (
          <CropperAvatarEditor
            ref={editorRef}
            image={previewImageUrl}
            crossOrigin="anonymous"
            scale={zoom}
            rotate={angle}
            position={position}
            width={cropSquareSize}
            height={cropSquareSize}
            border={[borderX, borderY]}
            borderRadius={3}
            color={[15, 20, 31, 0.88]}
            onPositionChange={onMove}
            onLoadSuccess={handleImageLoad}
          />
        )}

        {/* Rotation rulers */}
        {mode === MODES.crop && <CropperRulers size={cropSquareSize} />}

        {!isImageLoaded && <CropperPreviewLoader size={cropSquareSize} />}
      </CropperPreviewContainer>

      {/* Free rotation controls */}
      {mode === MODES.crop && <RotateSlider value={freeAngle} onChange={handleFreeRotate} />}

      {/* Vertical scaling controls */}
      {mode === MODES.crop && <VerticalZoomSlider value={zoom} onChange={onZoom} />}

      {/* Simple rotation controls */}
      {mode === MODES.crop && (
        <React.Fragment>
          <RotateLeftButton onClick={() => onRotate(angle - 90)}>
            <RotateButtonContent>
              <RotateButtonLabel>-90°</RotateButtonLabel>
              <RotateButtonIcon />
            </RotateButtonContent>
          </RotateLeftButton>
          <RotateRightButton onClick={() => onRotate(angle + 90)}>
            <RotateButtonContent>
              <RotateButtonLabel>+90°</RotateButtonLabel>
              <RotateButtonIcon />
            </RotateButtonContent>
          </RotateRightButton>
        </React.Fragment>
      )}

      {/* Background colors */}
      {mode === MODES.background && (
        <BackgroundColorPicker value={customColor} onChange={handleCustomColorChange} />
      )}

      {/* Backgrounds selector */}
      {mode === MODES.background && (
        <BackgroundGallery
          activeBackground={background}
          customColor={customColor}
          imageUrl={imageUrl}
          noBgImageUrl={noBgImageUrl}
          zoom={zoom}
          angle={angle}
          position={position}
          onChange={onBackgroundChange}
        />
      )}
    </CropperContainer>
  )
}
