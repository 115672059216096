import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { CareerPlan } from '../types'
import Get5URL from '../assets/images/get5.svg'
import First90DaysURL from '../assets/images/first-90-days.svg'
import PromotionURL from '../assets/images/path-to-promotion.svg'
import CustomPlanURL from '../assets/images/custom-plan.svg'

type Result = {
  careerPlans: CareerPlan[]
}

export const useCareerPlans = (): Result => {
  const navigate = useNavigate()
  const careerPlans = useMemo(() => {
    const plans: Array<CareerPlan> = [
      {
        name: 'execute_a_job_search',
        label: 'New',
        title: 'Job Search Plan',
        text: 'Follow a step-by-step strategy for a better job search and faster results',
        action: () => navigate('/career-plans/execute-a-job-search'),
        visual: Get5URL,
      },
      {
        name: 'first_90_days',
        label: 'New',
        title: 'First 90 Days Plan',
        text: 'Your expert guide to achieving success in your first 90 days of a new job',
        action: () => navigate('/career-plans/first-90-days'),
        visual: First90DaysURL,
      },
      {
        name: 'path_to_promotion',
        label: 'New',
        title: 'Pathway to Promotion',
        text: 'A customized plan to put you on a pathway to promotion',
        action: () => navigate('/career-plans/path-to-promotion'),
        visual: PromotionURL,
      },
      {
        name: 'custom_plan',
        label: 'New',
        title: 'Custom Plan',
        text: 'Following our personal plan, you will be able to get your dream job',
        action: () => navigate('/career-plans/custom-plan'),
        visual: CustomPlanURL,
      },
    ]

    return plans
  }, [navigate])

  return { careerPlans }
}
