import times from 'lodash/times'
import * as Styles from './styles'

export const SkeletonCompanies = () => (
  <div>
    {times(5).map(key => (
      <Styles.SkeletonCompanyLine key={key}>
        <Styles.SkeletonCompany>
          <Styles.SkeletonCircle />
          <Styles.SkeletonCompanyName />
        </Styles.SkeletonCompany>
        <Styles.SkeletonCompanyPositions />
      </Styles.SkeletonCompanyLine>
    ))}
  </div>
)
