export const initialCareerLevels = [
  { name: 'Junior', description: 'Entry level with only a few years experience', checked: false },
  { name: 'Professional', description: 'Experienced and/or a mid-level manager', checked: false },
  { name: 'Executive', description: 'High-level manager or C-suite member', checked: false },
]

export const CAREERCOACHESPARAMS = {
  careerLevels: '',
  careerServiceId: [],
  careerIndustryId: [],
}

export const trackingInfo = {
  label: 'career_coaching',
}

export const REDIRECTROUTES = {
  onboarding_form: '/onboarding-form',
  coach_listing: '/coach-listing',
  sessions: '/sessions',
}

export const avatarUrl = require('./assets/img/coach-avatar.svg')
