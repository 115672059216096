import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Button from 'builder/components/Button'
import Icon, { Icon24 } from 'builder/components/Icon'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${Media.Tablet`
    padding-inline: 8px;
  `}

  ${Media.Phone`
    justify-content: flex-start;
  `}
`

export const ArrowContainer = styled.button`
  left: 40px;
  width: 80px;
  height: 32px;
  border: none;
  display: flex;
  flex-shrink: 0;
  cursor: pointer;
  align-items: center;
  border-radius: 20px;
  justify-content: flex-start;
  background: ${Colors.Neutral10};
  padding: 4px 16px 4px 8px;

  & svg {
    min-width: 24px;
    color: ${Colors.Neutral50};
  }

  &:hover {
    background: ${Colors.Blue10};
  }

  ${Media.Phone`
    width: 40px;
  `}
`

export const LeftArrow = styled(Icon.Chevron)`
  transform: rotate(0.5turn);

  ${ArrowContainer}:hover & {
    color: ${Colors.Blue50};
  }
`

export const Text = styled.span`
  ${Typography.Caption};
  ${FontWeights.DemiBold};

  color: ${Colors.Neutral50};

  ${ArrowContainer}:hover & {
    color: ${Colors.Blue50};
  }
`

export const Heading = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};
  width: 100%;
  text-align: center;

  ${Media.Phone`
    ${Typography.S};
  `}
`

export const AddIcon = styled(Icon24.Add)`
  color: ${Colors.Neutral50};

  ${Media.Phone`
    color: ${Colors.White}
  `}
`

export const StyledButton = styled(Button)`
  gap: 8px;
  display: flex;

  &:hover ${AddIcon} {
    color: ${Colors.Blue50};
  }

  ${Media.Phone`
    z-index: 1;
    position: fixed;
    bottom: 32px;
    width: calc(100% - 40px);
  `}
`

export const Content = styled.div`
  display: flex;
`

export const LeftContent = styled.div`
  width: 640px;
  gap: 40px;
  display: flex;
  flex-direction: column;
`

export const AboutSection = styled.div`
  gap: 32px;
  display: flex;
  flex-direction: column;
`

export const CommonJobTitles = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
`

export const SectionHeading = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};
`

export const JobTitles = styled.div`
  gap: 12px;
  display: flex;
  flex-wrap: wrap;
`

export const JobTitle = styled.div`
  ${Typography.Caption};
  padding: 6px 10px;
  border-radius: 4px;
  background-color: ${Colors.Neutral10};
`

export const RoleDescription = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
`

export const Description = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
`

export const Paragraph = styled.div`
  color: ${Colors.Neutral50};
`

export const RequiredSkillsContainer = styled.div`
  padding: 40px 0;
  gap: 24px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${Colors.Neutral15};
`

export const SkillsSection = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`

export const SkillsText = styled.div``

export const Skills = styled.div`
  gap: 12px;
  display: flex;
  flex-wrap: wrap;
`

export const Skill = styled(JobTitle)``
