import { AxiosError } from 'axios'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { apiClient, baseClient } from 'builder/modules/apiClient'
import ErrorLogger from 'builder/services/ErrorLogger'

import { FetchStatuses } from '../constants'
import { BriefResume } from '../panel'
import { SubscriptionStatus, actions } from '.'

/** Bind side-effect handlers */
function* fetchPopulateFormDetailsForResumeSaga(
  action: ReturnType<typeof actions.fetchPopulateFormDetailsForResume>,
) {
  try {
    yield put(actions.setParsingStatus(true))
    const { resumeId } = action.payload
    const { data } = yield call(apiClient.get, `/resumes/${resumeId}`)
    yield put(actions.setPopulationFormDetailsForResume(data))
  } catch (error) {
    ErrorLogger.log(error)
  } finally {
    yield put(actions.setParsingStatus(false))
  }
}

function* fetchCandidateSaga() {
  try {
    yield put(actions.setFetchCandidateStatus(FetchStatuses.loading))
    const { data } = yield call(baseClient.get, `/resume-distribution/candidates`)
    yield put(actions.setFetchCandidateStatus(FetchStatuses.loaded))
    yield put(actions.setCandidate(data))
  } catch (error) {
    yield put(actions.setFetchCandidateStatus(FetchStatuses.failed))
    yield put(actions.setCandidate())
    ErrorLogger.log(error)
  }
}

function* fetchDistributionDatesSaga() {
  try {
    yield put(actions.setFetchDistributionDatesStatus(FetchStatuses.loading))
    const { data } = yield call(baseClient.get, `/resume-distribution/candidates/dates`)
    yield put(actions.setFetchDistributionDatesStatus(FetchStatuses.loaded))
    yield put(actions.setDistributionDates(data))
  } catch (error) {
    yield put(actions.setFetchDistributionDatesStatus(FetchStatuses.failed))
    ErrorLogger.log(error)
  }
}

function* fetchRecruitersSaga(action: ReturnType<typeof actions.fetchRecruiters>) {
  const { date, page, count } = action.payload || {}
  try {
    yield put(actions.setFetchRecruitersStatus({ [date as number]: FetchStatuses.loading }))
    const { data } = yield call(baseClient.get, `/resume-distribution/candidates/recruiters`, {
      params: { date, page },
    })
    yield put(actions.setFetchRecruitersStatus({ [date as number]: FetchStatuses.loaded }))

    if (count) {
      yield put(actions.setDistributedRecruitersCount(data?.pagination?.totalRecords || 0))
    } else if (page) {
      yield put(actions.setPaginatedRecruiters({ date: date as number, data }))
    } else {
      yield put(actions.setRecruiters({ [date as number]: data }))
    }
  } catch (error) {
    yield put(actions.setFetchRecruitersStatus({ [date as number]: FetchStatuses.failed }))
    ErrorLogger.log(error)
  }
}

function* fetchRecruitersCountSaga(action: ReturnType<typeof actions.fetchRecruitersCount>) {
  try {
    const { data } = yield call(baseClient.get, `/resume-distribution/recruiters/count`, {
      params: action.payload,
    })
    yield put(actions.setRecruitersCount(data.total))
  } catch (error) {
    ErrorLogger.log(error)
  }
}

function* postCandidateSaga(action: ReturnType<typeof actions.postCandidate>) {
  try {
    yield put(actions.setPostCandidateStatus(FetchStatuses.loading))
    yield call(baseClient.post, `/resume-distribution/candidates`, action.payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    yield put(actions.setPostCandidateStatus(FetchStatuses.loaded))
  } catch (error) {
    const err = error as AxiosError<Error>
    if (err.response?.status === 400 && err.response?.data.message.includes('resume file')) {
      yield put(actions.setCandidateResumeFailed(true))
    }
    if (err.response?.status && err.response?.status >= 500) {
      yield put(actions.setServerFailed(true))
    }
    yield put(actions.setPostCandidateStatus(FetchStatuses.failed))
    ErrorLogger.log(error)
  }
}

function* patchCandidateSaga(action: ReturnType<typeof actions.patchCandidate>) {
  try {
    yield put(actions.setPatchCandidateStatus(FetchStatuses.loading))
    yield call(baseClient.patch, `/resume-distribution/candidates`, action.payload)
    yield put(actions.setPatchCandidateStatus(FetchStatuses.loaded))
  } catch (error) {
    yield put(actions.setPatchCandidateStatus(FetchStatuses.failed))
    ErrorLogger.log(error)
  }
}

function* postCandidateResumeSaga(action: ReturnType<typeof actions.postCandidateResume>) {
  try {
    const resume = action.payload
    const { id: resumeId } = resume as BriefResume
    const isTrDocument = resume?.type === 'tr_document'
    const key = isTrDocument ? 'document_id' : `resume_${resumeId ? 'id' : 'file'}`
    const value = resumeId ? resumeId.toString() : (resume as Blob | string)

    const bodyFormData = new FormData()
    bodyFormData.append(key, value)

    yield put(actions.setPostCandidateResumeStatus(FetchStatuses.loading))
    yield call(baseClient.post, `/resume-distribution/candidates/resume`, bodyFormData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    yield put(actions.setPostCandidateResumeStatus(FetchStatuses.loaded))
  } catch (error) {
    yield put(actions.setPostCandidateResumeStatus(FetchStatuses.failed))
    ErrorLogger.log(error)
  }
}

function* parseResumeFileSaga(action: ReturnType<typeof actions.parseResumeForPrefill>) {
  try {
    const resumeFile: File = action.payload
    const formData = new FormData()
    formData.append('file', resumeFile)

    yield put(actions.setParsingStatus(true))

    const { data } = yield call(apiClient.post, '/resume-parser/parse-resume-data', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    let linkedinUrl = ''
    data?.socialUrls?.forEach((urlObj: { source: string; url: string }) => {
      if (urlObj?.source?.toLowerCase()?.includes('linkedin')) {
        linkedinUrl = urlObj?.url
      }
    })

    yield put(
      actions.setPopulationFormDetailsForResume({
        ...data,
        email: data?.emails?.[0],
        jobTitle: data?.positions?.[0]?.titleNormalized,
        linkedin: linkedinUrl,
      }),
    )
  } catch (error) {
    ErrorLogger.log(error)
  } finally {
    yield put(actions.setParsingStatus(false))
  }
}

function* createSubscriptionSaga(action: ReturnType<typeof actions.postSubscriptionCreate>) {
  try {
    yield put(actions.setPostSubscriptionCreateStatus(FetchStatuses.loading))
    yield call(baseClient.post, `resume-distribution/candidates/subscriptions`, action.payload)
    yield put(actions.setSubscriptionStatus(SubscriptionStatus.active))
    yield put(actions.setPostSubscriptionCreateStatus(FetchStatuses.loaded))
  } catch (error) {
    yield put(actions.setPostSubscriptionCreateStatus(FetchStatuses.failed))
    ErrorLogger.log(error)
  }
}

function* pauseSubscriptionSaga(action: ReturnType<typeof actions.postSubscriptionPause>) {
  try {
    yield put(actions.setPostSubscriptionPauseStatus(FetchStatuses.loading))
    yield call(baseClient.post, `resume-distribution/candidates/subscription/pause`, action.payload)
    yield put(actions.setSubscriptionStatus(SubscriptionStatus.paused))
    yield put(actions.setPostSubscriptionPauseStatus(FetchStatuses.loaded))
  } catch (error) {
    yield put(actions.setPostSubscriptionPauseStatus(FetchStatuses.failed))
    ErrorLogger.log(error)
  }
}

function* resumeSubscriptionSaga(action: ReturnType<typeof actions.postSubscriptionResume>) {
  try {
    yield put(actions.setPostSubscriptionResumeStatus(FetchStatuses.loading))
    yield call(
      baseClient.post,
      `resume-distribution/candidates/subscription/resume`,
      action.payload,
    )
    yield put(actions.setPostSubscriptionResumeStatus(FetchStatuses.loaded))
    yield put(actions.setSubscriptionStatus(SubscriptionStatus.active))
  } catch (error) {
    yield put(actions.setPostSubscriptionResumeStatus(FetchStatuses.failed))
    ErrorLogger.log(error)
  }
}

export const sagas = function* saga() {
  yield all([
    takeLatest(actions.fetchPopulateFormDetailsForResume, fetchPopulateFormDetailsForResumeSaga),
    takeLatest(actions.fetchCandidate, fetchCandidateSaga),
    takeLatest(actions.fetchDistributionDates, fetchDistributionDatesSaga),
    takeLatest(actions.fetchRecruiters, fetchRecruitersSaga),
    takeLatest(actions.fetchRecruitersCount, fetchRecruitersCountSaga),
    takeLatest(actions.postCandidateResume, postCandidateResumeSaga),
    takeLatest(actions.postCandidate, postCandidateSaga),
    takeLatest(actions.patchCandidate, patchCandidateSaga),
    takeLatest(actions.parseResumeForPrefill, parseResumeFileSaga),
    takeLatest(actions.postSubscriptionPause, pauseSubscriptionSaga),
    takeLatest(actions.postSubscriptionResume, resumeSubscriptionSaga),
    takeLatest(actions.postSubscriptionCreate, createSubscriptionSaga),
  ])
}
