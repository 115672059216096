import { JobStatusOption } from './types'

export enum JobStatusModalStep {
  DEFAULT,
  WORKINGONCV,
  JOBSEARCHING,
  APPLYINGJOBS,
  INTERVIEWING,
  HIRED,
}

export const jobStatusOptions: JobStatusOption[] = [
  { id: 1, status: '✍️  Working on my resume/CV', checked: false },
  { id: 2, status: '🔍  Actively job searching', checked: false },
  { id: 3, status: '✔️  Applying jobs', checked: false },
  { id: 4, status: '🎙️  Currently interviewing', checked: false },
  { id: 5, status: '💼  I’ve been hired!', checked: false },
]
