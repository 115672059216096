import Get5ReviewSkills from './components/Get5ReviewSkills'
import Get5SevenStoriesIntro from './components/Get5SevenStoriesIntro'
import AccomplishmentProgressBar from './components/AccomplishmentProgressBar'

import { Wrapper, FooterContainer, MainContainer, Container } from './styles'
import Footer from './components/Footer'
import Header from './components/Header'
import AccomplishmentStepOne from './components/AccomplishmentStepOne'
import { useGetFiveAccomplishmentHook } from './hooks/useGetFiveAccomplishmentsHook'
import { AccomplishmentsRank } from './components/AccomplishmentsRank'
import { DescribeAccomplishments } from './components/DescribeAccomplishments'
import AssessmentResult from './components/AssessmentResult'
import ConfirmModal from './components/AccomplishmentsRank/components/ConfirmModal'

const GetFiveAccomplishments = () => {
  const {
    step,
    handleSortEnd,
    handleContinue,
    getPlaceHolder,
    getHeaderTitle,
    handleNextStep,
    isMaxSelection,
    handleSkillSelect,
    accomplishmentData,
    handlePreviousStep,
    accomplishmentsSkills,
    handleAddAccomplishment,
    maxAccomplishmentsLength,
    handleAccomplishmentChange,
    handleAccomplishmentSelect,
    onSortEnd,
    selectedAccomplishment,
    onNextStory,
    onPrevStory,
    currentStoryIndex,
    onAddNewSkill,
    handleCloseConfirmModal,
    handleSubmitConfirmModal,
    showConfirmModal,
    onStoryChange,
    onSkillChange,
    isSkillError,
    getProgress,
    handleShowStory,
    showStoryId,
  } = useGetFiveAccomplishmentHook()

  const progress = getProgress(currentStoryIndex + 1, selectedAccomplishment.length)

  const getStepsRender = () => {
    switch (step) {
      case 0:
        return <Get5SevenStoriesIntro {...{ handleNextStep }} />
      case 1:
        return (
          <AccomplishmentStepOne
            {...{
              getPlaceHolder,
              accomplishmentData,
              handleAddAccomplishment,
              maxAccomplishmentsLength,
              handleAccomplishmentChange,
            }}
          />
        )
      case 2:
        return (
          <AccomplishmentsRank
            {...{
              accomplishmentData,
              selectedAccomplishment,
              handleAccomplishmentSelect,
              isMaxSelection,
              onSortEnd,
              handleCloseConfirmModal,
              handleSubmitConfirmModal,
              showConfirmModal,
            }}
          />
        )
      case 3:
        return (
          <DescribeAccomplishments
            {...{
              selectedAccomplishment,
              currentStoryIndex,
              onAddNewSkill,
              onStoryChange,
              onSkillChange,
              isSkillError,
            }}
          />
        )
      case 4:
        return (
          <Get5ReviewSkills
            {...{
              handleSortEnd,
              handleSkillSelect,
              accomplishmentsSkills,
            }}
          />
        )
      case 5:
        return <AssessmentResult {...{ handleShowStory, showStoryId, handlePreviousStep }} />
    }
  }

  return (
    <>
      {showConfirmModal && (
        <ConfirmModal {...{ handleCloseConfirmModal, handleSubmitConfirmModal }} />
      )}
      <Container timeout={0}>
        <MainContainer>
          <Wrapper {...{ step }}>
            {step !== 0 && step !== 5 && <Header {...{ step, getHeaderTitle }} />}
            {getStepsRender()}
            {step !== 0 && step !== 5 && (
              <FooterContainer>
                <AccomplishmentProgressBar {...{ progress }} />
                <Footer
                  {...{
                    handlePreviousStep,
                    handleContinue,
                    step,
                    onNextStory,
                    onPrevStory,
                  }}
                />
              </FooterContainer>
            )}
          </Wrapper>
        </MainContainer>
      </Container>
    </>
  )
}

export default GetFiveAccomplishments
