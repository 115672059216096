import { Container, StyledButton, FooterText } from './styles'

interface PackProps {
  name: string
}

interface FooterProps {
  pack: PackProps
  handleClick: (name: string) => void
}

export const Footer = ({ pack, handleClick }: FooterProps) => {
  return (
    <Container>
      <StyledButton
        onClick={() => {
          handleClick(pack.name)
        }}
      >
        Select Package
      </StyledButton>
      <FooterText>
        The subscription will automatically renew to the $24.95/month plan. It can be canceled at
        any time.
      </FooterText>
    </Container>
  )
}
