import styled from 'styled-components'

import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import { Icon20 } from 'builder/components/Icon'
import Media from 'builder/styles/media'

export const StepContainer = styled.div``

export const ProgressbarContainer = styled.div`
  width: 100%;
`

export const ProgressbarText = styled.div`
  ${FontWeights.Medium};
  ${Typography.Caption};
  margin-top: 8px;
  margin-bottom: 16px;
  letter-spacing: 0.386px;
  display: flex;
  flex-direction: row;
  gap: 8px;
`

export const ProgressbarIcon = styled.div`
  width: 20px;
  height: 20px;
`

export const ProgressbarTextIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`

export const ProgressbarCountText = styled.div`
  ${FontWeights.Medium};
  color: ${Colors.Neutral80};

  ${Media.Phone`
    ${Typography.Body};
  `}
`

export const ProgressbarInfoText = styled.div`
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
`

export const ProgressBar = styled.div`
  width: 100%;
  height: 4px;
  background-color: ${Colors.Neutral10};
  border-radius: 2px;
  margin-bottom: 20px;
`

export const Progress = styled.div<{ percentage: number }>`
  width: ${props => (props.percentage > 0 ? props.percentage : 1)}%;
  height: 100%;
  background-color: ${Colors.Blue50};
  border-radius: 10px;
  transition: width ease-in-out 0.5s;
`

export const WithProgressIcon = styled(Icon20.SuccessCircle)`
  color: ${Colors.Green50};
`

export const BlankProgressIcon = styled(Icon20.StatusNew)`
  color: ${Colors.Neutral50};
`
