import { useState } from 'react'
import { Icon48 } from '../../../../../components/Icon/Icon'
import { usePermission } from '../hooks/usePermissions'
import { UserMediaType, useGetStream } from '../hooks/useGetStream'
import { Container, IconContainer } from './style'

export const MicVideoControls = () => {
  const [mic, setMic] = useState(true)
  const [video, setVideo] = useState(true)

  const audioStream = useGetStream(UserMediaType.audio)
  const audioVideoStream = useGetStream(UserMediaType.video)

  const cameraPermission = usePermission('camera')
  const microphonePermission = usePermission('microphone')

  const onMicClick = () => {
    setMic(!mic)
    audioStream.stream?.getAudioTracks().forEach(track => {
      track.enabled = !mic
    })

    audioVideoStream.stream?.getAudioTracks().forEach(track => {
      track.enabled = !mic
    })
  }

  const onVideoClick = () => {
    setVideo(!video)
    audioVideoStream.stream?.getVideoTracks().forEach(track => {
      track.enabled = !video
    })
  }
  const isMicOn =
    mic &&
    !!audioStream.stream &&
    !!audioStream.stream?.active &&
    microphonePermission === 'granted'
  const isWebcanOn =
    video &&
    !!audioVideoStream.stream &&
    !!audioVideoStream.stream.active &&
    cameraPermission === 'granted'

  return (
    <Container>
      <IconContainer $enableRedBackground={!isMicOn} onClick={onMicClick}>
        {isMicOn ? <Icon48.MicOnIcon /> : <Icon48.MicOffIcon />}
      </IconContainer>
      <IconContainer $enableRedBackground={!isWebcanOn} onClick={onVideoClick}>
        {isWebcanOn ? <Icon48.VideoOnIcon /> : <Icon48.VideoOffIcon />}
      </IconContainer>
    </Container>
  )
}
