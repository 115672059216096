export type Coordinates = {
  x: number
  y: number
}

type Options = {
  mousePosition: Coordinates
  element: HTMLElement
  range: number
}

/**
 * Returns values in range by mouse position relative to the center of given element
 *
 * @param {Options}     options
 * @param {Coordinates} options.mousePosition – coordinates of cursor relative to page
 * @param {HTMLElement} options.element       – cursor coordinates are calculating over this element
 * @param {number}      options.range         – relative values calculates within this range
 */
const calcPreviewRotation = ({ mousePosition, element, range }: Options): Coordinates => {
  const rectangle = element.getBoundingClientRect()

  const width = rectangle.right - rectangle.left
  const height = rectangle.bottom - rectangle.top

  const relativeX = mousePosition.x - rectangle.left
  const relativeY = mousePosition.y - rectangle.top

  /*
    Find offset within the range by mouse position

    1. Find mouse position within the rectangle in percent
       A = (relativeX * 100) / width

    2. Calculate new value in range between min and max value
       B = range * A / 100

    3. Substract half of the range distance to get value relative to rectangle center
       B - range / 2
  */
  const offsetX = (range * relativeX * 100) / width / 100 - range / 2
  const offsetY = (range * relativeY * 100) / height / 100 - range / 2

  return {
    x: Math.round(offsetX),
    y: Math.round(offsetY),
  }
}

export default calcPreviewRotation
