export enum SevenStoriesSteps {
  INTRO,
  ACCOMPLISHMENTS,
  RANK_ACCOMPLISHMENTS,
  BUILD_STORIES,
  RANK_SKILLS,
  RESULT,
}

export const defaultStory = {
  story_1: '',
  story_2: '',
  story_3: '',
  story_4: '',
  story_5: '',
  story_6: '',
  story_7: '',
}

export const defaultSkill = {
  id: 1,
  rank: 1,
  isSelected: false,
  text: '',
}

export const defaultAccomplishments = [
  {
    id: 1,
    rank: 1,
    isSelected: false,
    description: '',
    stories: defaultStory,
    skills: [defaultSkill],
    error: '',
  },
  {
    id: 2,
    rank: 2,
    isSelected: false,
    description: '',
    stories: defaultStory,
    skills: [defaultSkill],
    error: '',
  },
  {
    id: 3,
    rank: 3,
    isSelected: false,
    description: '',
    stories: defaultStory,
    skills: [defaultSkill],
    error: '',
  },
  {
    id: 4,
    rank: 4,
    isSelected: false,
    description: '',
    stories: defaultStory,
    skills: [defaultSkill],
    error: '',
  },
  {
    id: 5,
    rank: 5,
    isSelected: false,
    description: '',
    stories: defaultStory,
    skills: [defaultSkill],
    error: '',
  },
  {
    id: 6,
    rank: 6,
    isSelected: false,
    description: '',
    stories: defaultStory,
    skills: [defaultSkill],
    error: '',
  },
  {
    id: 7,
    rank: 7,
    isSelected: false,
    description: '',
    stories: defaultStory,
    skills: [defaultSkill],
    error: '',
  },
]

export const MAX_LENGTH = 30

export const accomplishmentTipsText =
  'List enjoyable accomplishments from your past including youth, school, early career,and present. Be specific and include concrete examples and tasks.\n\n' +
  'Example “There was the time when I enjoyed [an accomplishment] . . .”'

export const accomplishmentRankTipsText = `Select the accomplishments and rank them using 
  the dragging tool`

export const describeAccomplishmentsText = `Enjoyable skills are patterns that run through our
  lives and careers. Your answers here are key to this 
  exercise. List up to 5 skills you think are
  meaningful.`

export const skillsRankTipsText =
  'When you uncover your enjoyable skills, you’ll be better able to identify jobs that allow you to use them, and recognize other jobs that don’t quite fit.'

export const defaultStep = 1

export const MAX_ACCOMPLISMENT_LIMIT = 6

export const MAX_SKILLS_SELECTION_LIMIT = 8

export const StoryList = [
  {
    id: 1,
    label: 'What about it did you enjoy most?',
    placeholder: 'What about it did you enjoy most?',
    type: 'text',
    name: 'story',
  },
  {
    id: 2,
    label: 'What did you do best?',
    placeholder: 'What did you do best?',
    type: 'textarea',
    name: 'story',
  },
  {
    id: 3,
    label: 'What was your key motivator?',
    placeholder: 'What was your key motivator?',
    type: 'text',
    name: 'story',
  },
  {
    id: 4,
    label: 'What led up to your getting involved?',
    placeholder: '(e.g. I was assigned it, I thought it up)',
    type: 'textarea',
    name: 'story',
  },
  {
    id: 5,
    label: 'What was your relationship with others?',
    placeholder: '(e.g. leader, worked alone, team member)',
    type: 'textarea',
    name: 'story',
  },
  {
    id: 6,
    label: 'Describe the environment in which you performed',
    placeholder: 'Describe the environment in which you performed',
    type: 'textarea',
    name: 'story',
  },
  {
    id: 7,
    label: 'What was the subject matter?',
    placeholder: 'What was the subject matter?',
    type: 'text',
    name: 'textarea',
  },
]

export const SKILL_ERROR = 'Enter at least one enjoyable skill to advance to next story.'

export const minAccomplishment = 7

export const MAX_SKILLS_SELECTED = 10

export const label = {
  label: 'job_search_assessment',
}
