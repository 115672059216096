import { useI18n } from 'builder/hooks/useI18n'
import Button, { ButtonSize, ButtonTheme } from 'builder/components/Button'
import { TRANSLATION_PREFIX } from '../InterviewPlayerView'
import {
  BodyContainer,
  CloseIcon,
  CloseIconContainer,
  Container,
  Dialog,
  FooterContainer,
  HeaderContainer,
  RemainingTitleText,
  RemainingValueText,
  SubTitle,
  Title,
} from './styles'
import ImageTimeTickingSrc from './assets/timeTicking.png'
interface Props {
  isLastQuestion: boolean
  remainingTime: string
  onCancel: () => void
  onConfirmation: () => void
}
export const TimeTickingModal = ({
  remainingTime,
  isLastQuestion,
  onCancel,
  onConfirmation,
}: Props) => {
  const { i18n } = useI18n()

  return (
    <Dialog>
      <Container>
        <HeaderContainer>
          <div>
            <img src={ImageTimeTickingSrc}></img>
            <Title>{i18n.t(`${TRANSLATION_PREFIX}.ticking_modal_title`)}</Title>
          </div>
          <CloseIconContainer onClick={onCancel}>
            <CloseIcon />
          </CloseIconContainer>
        </HeaderContainer>

        <SubTitle>{i18n.t(`${TRANSLATION_PREFIX}.ticking_modal_subtitle`)}</SubTitle>
        <BodyContainer>
          <div>
            <RemainingTitleText>
              {i18n.t(`${TRANSLATION_PREFIX}.ticking_modal_time_remaining`)}
            </RemainingTitleText>
            <RemainingValueText>{remainingTime}</RemainingValueText>
          </div>
        </BodyContainer>

        <FooterContainer>
          <Button theme={ButtonTheme.ghost} size={ButtonSize.small} onClick={onCancel}>
            {i18n.t(`${TRANSLATION_PREFIX}.dismiss`)}
          </Button>
          <Button theme={ButtonTheme.default} size={ButtonSize.small} onClick={onConfirmation}>
            {isLastQuestion
              ? i18n.t(`${TRANSLATION_PREFIX}.complete_interview`)
              : i18n.t(`${TRANSLATION_PREFIX}.next_question`)}
          </Button>
        </FooterContainer>
      </Container>
    </Dialog>
  )
}
