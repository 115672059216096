import styled, { css } from 'styled-components'
import { Colors } from 'builder/styles/colors'
import { Spinner } from 'builder/components/Spinner'
import { Media } from 'builder/styles/media'
import { Container as BaseContainer } from 'builder/components/Container'
import { HorizontalTransitionFade } from 'builder/components/HorizontalTransitionFade'
import { Button } from 'builder/components/Button'
import { FontWeights, Typography } from 'builder/styles/typography'
import { Shadows } from 'builder/styles/shadows'

export const Container = styled.div`
  overflow: hidden;
`

export const BackgroundContainer = styled(HorizontalTransitionFade)``

export const MatrixContainer = styled(BaseContainer)`
  overflow: hidden;
  margin-top: 40px;
`

export const CardRect = styled.rect`
  fill: ${Colors.Neutral5};
`

export const SConnection = styled.path`
  stroke-dashoffset: 32;
  stroke-dasharray: 32;
  fill: none;
`

export const MConnection = styled.path`
  stroke-dashoffset: 183;
  stroke-dasharray: 183;
  fill: none;
`

export const LConnection = styled.path`
  stroke-dashoffset: 357;
  stroke-dasharray: 357;
  fill: none;
`

const AbsoluteCenter = css`
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
`

export const ShadowContainer = styled.div`
  ${AbsoluteCenter};
  background: radial-gradient(50% 50% at 50% 50%, #fff 58.58%, rgba(255, 255, 255, 0) 100%);

  top: 120px;
  width: 1120px;
  height: 460px;
  opacity: 0.5;

  ${Media.Tablet`
    width: 724px;
    height: 460px;
    opacity: 0.8;
  `}

  ${Media.Phone`
    width: 375px;
    height: 428px;
    opacity: 0.8;
  `}
`

export const ContentWrapper = styled.div`
  ${AbsoluteCenter};
  top: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

export const ProcessWrapper = styled(ContentWrapper)`
  top: -40px;
`

export const SpinnerWrapper = styled.div`
  width: 64px;
  height: 64px;

  background: ${Colors.White};
  ${Shadows.template.high};
  border-radius: 50%;

  align-self: center;
  margin-bottom: 24px;
  position: relative;
`

export const SpinnerContainer = styled(Spinner)`
  ${AbsoluteCenter};
`

export const TextWrapper = styled.div`
  align-self: center;
  position: relative;
  width: 100%;
  text-align: center;
`

export const StaticText = styled.div`
  ${Typography.Body};
  align-self: center;
  max-width: 396px;
  text-align: center;

  ${Media.Phone`
    max-width: 304px;
  `}
`

export const AnimatedText = styled.div<{ isActive: boolean; isPrevious: boolean }>`
  ${Typography.Subhead};
  ${AbsoluteCenter};

  opacity: 0;
  transform: translateY(30px);
  transition: transform 400ms ease-in-out, opacity 300ms ease-in-out;

  ${props =>
    props.isActive &&
    css`
      opacity: 1;
      transform: translateY(0);
    `};

  ${props =>
    props.isPrevious &&
    css`
      opacity: 0;
      transform: translateY(-30px);
    `};
`

export const Title = styled.div`
  ${Typography.S};
  ${FontWeights.DemiBold};
  text-align: center;
  margin-bottom: 12px;
`

export const ChangeButton = styled(Button)`
  margin-top: 24px;
`
