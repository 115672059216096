import { CareerPathGrades } from 'builder/modules/careerPath/types'
import { CareerPathJobCardTone } from 'builder/components/CareerPathJobCard'

export enum CareerPathStep {
  QUESTIONNAIRE,
  LEVEL_CHOICE,
  CONFIRMATION,
}

export const ToneByGrade: { [key in CareerPathGrades]: CareerPathJobCardTone } = {
  low: 'green',
  medium: 'blue',
  high: 'indigo',
}

export const DEFAULT_LOCATION = 'United States'
