import FAILURE_ICON from '../../../../../app/assets/images/snackbar-failure-icon.svg'

import { Container, IconImage, Text } from './styles'

const FailureSnackbar = ({ text }: { text: string }) => (
  <Container>
    <IconImage src={FAILURE_ICON} />
    <Text>{text}</Text>
  </Container>
)

export default FailureSnackbar
