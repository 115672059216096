import styled, { css } from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
import { Typography, FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Button from 'builder/components/Button'
import { TextField } from 'builder/components/TextField'
import { Icon24, Icon20 } from 'builder/components/Icon'
import Shadows from 'builder/styles/shadows'
import { rotation } from 'builder/styles/keyframes'

const SmallPhoneMedia = (...args) => css`
  @media (max-width: 374px) {
    ${css(...args)};
  }
`

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${Media.Tablet`
    max-width: 416px;
    margin: 0 auto;
  `};
`

export const Header = styled.div`
  display: flex;
  margin-bottom: 30px;

  ${Media.Laptop`
    flex-direction: column;
    margin-bottom: -8px;
  `};
`

export const HeaderContent = styled.div`
  flex-grow: 1;
  margin-right: 48px;

  ${Media.Laptop`
    margin-bottom: 32px;
    margin-right: 0;
    text-align: center;
  `};

  ${Media.Phone`
    margin: 0 0 24px;
  `};
`

export const HeaderTitle = styled.div`
  ${Typography.XL};
  font-weight: bold;
`

export const HeaderDescription = styled.div`
  color: ${Colors.Neutral50};
  margin-top: 12px;
`

export const Column = styled.div`
  flex: 1;
`

export const PreviewContainer = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 384px;
  pointer-events: none;
  margin-left: 95px;

  ${Media.Tablet`
    width: 100%;
  `};

  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    right: 29px;
    top: -55px;
    width: 125px;
    height: 62px;
    background-image: url(${require('images/builder/coreg/preview-visual.svg')});
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: contain;

    ${Media.Tablet`
      display: none;
    `};
  }

  &:after {
    content: '';
    position: absolute;
    left: -50px;
    right: -50px;
    bottom: -49px;
    border-bottom: 40px solid ${Colors.White};
    height: 150px;
    background: linear-gradient(to bottom, ${hexToRgba(Colors.White, 0)}, ${Colors.White});
  }
`

export const PreviewInner = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 4px 4px 0 0;
  background-color: ${Colors.Neutral10};
  box-shadow: 0 0 1px rgba(15, 56, 113, 0.16), 0 16px 64px -8px rgba(15, 56, 113, 0.16);

  &:before {
    content: '';
    display: block;
    padding-bottom: 92%;
  }
`

export const PreviewImage = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
`

export const Form = styled.form`
  position: relative;
  display: flex;

  ${Media.Tablet`
    flex-direction: column;
  `};
`

export const Fieldset = styled.div`
  flex-grow: 1;
`

export const FormError = styled.div`
  ${Typography.Caption};
  padding: 12px 16px;
  margin-bottom: 24px;
  border-radius: 3px;
  background-color: ${hexToRgba(Colors.Red50, 0.15)};
  color: ${Colors.Red50};
`

export const FormRow = styled.div`
  display: flex;
  margin-bottom: 22px;

  ${SmallPhoneMedia`
    margin-bottom: 0;
    flex-direction: column;
  `};
`

export const Field = styled(TextField).attrs({
  inputIconPosition: 'right',
})`
  flex-grow: 1;
  width: 50%;
  margin-right: 12px;

  &:last-child {
    margin-right: 0;
  }

  ${SmallPhoneMedia`
    width: 100%;
    margin-bottom: 20px;
  `};
`

export const FieldSuccessIcon = styled(Icon20.SuccessCircle)`
  color: ${Colors.Green50};
`

export const FieldWarningIcon = styled(Icon20.WarningCircle)`
  color: ${Colors.Red50};
`

export const FormActions = styled.div`
  display: flex;
  margin-top: 32px;
  justify-content: space-between;

  ${Media.Laptop`
    margin-top: 36px;
  `}

  ${Media.Phone`
    justify-content: center;
  `};
`

export const FormCheckbox = styled.div`
  padding-top: 10px;
`

export const BackButton = styled(Button).attrs({ theme: 'ghost' })`
  ${Media.Phone`
    display: none;
  `};
`

export const SubmitButton = styled(Button)`
  position: relative;

  ${props =>
    props.isSending &&
    css`
      pointer-events: none;
    `};
`

export const SubmitButtonText = styled.span`
  opacity: ${props => (props.isSending ? 0 : 1)};
`

export const SubmitButtonSpinner = styled(Icon24.Spinner)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -12px 0 0 -12px;
  animation: ${rotation} 0.5s infinite linear;
`

export const ServicesContainer = styled.div`
  flex-shrink: 0;
  padding-top: 50px;
  margin-left: 95px;
  background-color: ${Colors.White};
  position: relative;
  max-width: 384px;

  ${Media.Laptop`
    width: 100%;
    max-width: 100%;
    padding-top: 0;
    margin-left: 0;
    margin-bottom: 32px;
  `};

  ${Media.Phone`
    padding-top: 0;
    margin-bottom: 30px;
    padding-bottom: 0;
    border-bottom: none;
  `};
`

export const ServiceContainer = styled.label`
  ${Shadows.light.low};
  display: block;
  margin-bottom: 32px;
  cursor: ${props =>
    ['connected', 'disabled', 'complete'].includes(props.state) ? 'default' : 'pointer'};
  border-radius: 6px;

  ${Media.Phone`
    margin-bottom: 24px;
  `};

  ${props =>
    (props.theme === 'card' || props.theme === 'bordered') &&
    css`
      background-color: ${Colors.White};
      border-radius: 6px;
      margin-bottom: 8px;

      ${Media.Phone`
        margin-bottom: 6px;
      `};
    `}

  ${props =>
    props.theme === 'bordered' &&
    css`
      border: 1px solid ${Colors.Neutral20};
    `}

  &:last-child {
    margin-bottom: 0;
  }
`
export const ServiceInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

export const ServiceHead = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 24px 16px 24px;

  ${SmallPhoneMedia`
    padding: 12px;
  `};
`

export const ServiceLogo = styled.div`
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-right: 16px;
  background-size: contain;
  background-image: url(${props => require(`images/builder/coreg/services/${props.name}.png`)});
  background-repeat: no-repeat;

  ${SmallPhoneMedia`
    width: 32px;
    height: 32px;
    margin-right: 12px;
  `};
`

export const ServiceContent = styled.div`
  flex-grow: 1;
`

export const ServiceTitle = styled.div`
  font-weight: 600;
  ${SmallPhoneMedia`
    margin-bottom: -3px;
  `};
`

export const ServiceStatus = styled.span`
  ${Typography.Caption};
  font-weight: 600;
  color: ${Colors.Green70};

  &:before {
    content: ' • ';
  }
`

export const ServiceText = styled.div`
  ${Typography.Tiny};
  color: ${Colors.Neutral50};

  & a {
    color: ${Colors.Blue50};
  }

  & a:hover {
    color: ${Colors.Blue70};
  }
`

export const ServiceToggle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  position: relative;
  width: 18px;
  height: 18px;
  margin-left: 16px;
  border: 1px solid ${Colors.Blue50};
  border-radius: 4px;
  color: ${Colors.White};

  ${SmallPhoneMedia`
    margin-left: 10px;
  `};

  ${props =>
    props.state === 'checked' &&
    css`
      background-color: ${Colors.Blue50};
    `}

  ${props =>
    props.state === 'connected' &&
    css`
      background-color: ${Colors.Neutral40};
      border-color: ${Colors.Neutral40};
    `}

  ${props =>
    props.state === 'complete' &&
    css`
      width: 30px;
      height: 30px;
      background-color: ${Colors.Green50};
      border-radius: 50%;
      border: 7px solid ${Colors.Green10};

      ${SmallPhoneMedia`
        display: none;
      `};
    `}

  ${props =>
    props.state === 'disabled' &&
    css`
      background-color: ${Colors.Neutral40};
      border-color: ${Colors.Neutral40};
    `}
`

export const ServiceCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
`

export const SuccessContainer = styled.div`
  max-width: 543px;
  margin: 0 auto;
  text-align: center;
`

export const SuccessHeader = styled.div`
  margin-bottom: 24px;
  max-width: 544px;
`

export const SuccessTitle = styled.div`
  ${Typography.L};
  ${FontWeights.DemiBold};
  margin-bottom: 11px;
`

export const SuccessDescription = styled.div`
  margin-top: 8px;
  color: ${Colors.Neutral50};
`

export const SuccessServices = styled.div`
  text-align: left;
  margin-bottom: 8px;
`

export const SuccessBackButton = styled(Button)`
  margin-top: 24px;
`

export const ModalContainer = styled.div`
  position: relative;
  z-index: 999;
  width: 100%;
  max-width: 680px;
  background-color: ${Colors.White};
  border-radius: 6px;
  padding: 32px;
`

export const ModalClose = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 16px;
  color: ${Colors.Neutral30};
  cursor: pointer;

  &:hover {
    color: ${Colors.Blue50};
  }
`

export const ModalCloseIcon = styled(Icon24.CloseLarge)`
  display: block;
`

export const Link = styled(RouterLink)`
  color: ${Colors.Blue50};
`

export const ServiceWebsites = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 16px 24px 16px;
  border-top: 1px solid ${Colors.Neutral15};
  list-style: none;
  margin: 0;

  ${SmallPhoneMedia`
    padding: 12px 12px 4px 12px;
  `};
`

export const ServiceWebsite = styled.li`
  ${Shadows.light.low};
  display: flex;
  align-items: center;
  margin: 0 8px 8px 0;
  padding: 4px;
  border-radius: 32px;

  ${SmallPhoneMedia`
    margin-right: 5px;
  `};
`

export const ServiceWebsiteLogo = styled.span`
  width: 20px;
  height: 20px;
  background-size: contain;
  background-image: url(${props => require(`images/builder/coreg/services/${props.name}.png`)});
  background-repeat: no-repeat;
  margin-right: 4px;
`

export const ServiceWebsiteTitle = styled.span`
  ${Typography.Tiny};
  color: ${Colors.Neutral90};
  padding-right: 12px;

  ${SmallPhoneMedia`
    padding-right: 7px;
  `};
`
