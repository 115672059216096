import { Resume } from 'packages/types'
import { useEffect, useState } from 'react'
import { baseClient } from 'builder/modules/apiClient'
import { BriefResume } from 'builder/modules/panel'

export const useJobTitleFromLatestUpdateResume = () => {
  const [loading, setLoading] = useState(true)
  const [resume, setResume] = useState<Resume>()
  useEffect(() => {
    async function request() {
      try {
        const responseResumes = await baseClient.get<{ resumes: BriefResume[] }>(`/app/resumes`)
        responseResumes.data.resumes.sort(
          (a: BriefResume, b: BriefResume) => Date.parse(b.updatedAt!) - Date.parse(a.updatedAt!),
        )

        const lastUpdateResume = responseResumes.data.resumes[0]
        if (!lastUpdateResume) {
          setResume(undefined)
          return
        }

        const latestResumes = await baseClient.get<Resume>(`/app/resumes/${lastUpdateResume?.id}`)
        setResume(latestResumes.data)
      } finally {
        setLoading(false)
      }
    }

    request()
  }, [])
  return { loading, resume }
}
