import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { Fragment, useState, useCallback } from 'react'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { actions, selectors, Label, SearchQuery, SalaryParams } from 'builder/modules/careerPath2'
import { Container, PathText, JobTitles, JobTitle, ArrowRight } from './styles'

const CareerPath = () => {
  const dispatch = useDispatch()
  const location = useTypedSelector(selectors.location)
  const careerPath1 = useTypedSelector(selectors.careerPath1)
  const [selectedJobTitle, setSelectedJobTitle] = useState(careerPath1[0].title)

  const handleClick = useCallback(
    (careerPath: string | undefined) => {
      if (careerPath && careerPath !== selectedJobTitle) {
        setSelectedJobTitle(careerPath)
        dispatch(actions.setSelectedJobTitle(careerPath))

        const queryParams: SearchQuery = {
          query: careerPath,
          location: location,
        }

        const salaryParams: SalaryParams = {
          title: careerPath,
          location: location || null,
        }

        dispatch(actions.fetchJobsRequest())
        dispatch(actions.fetchRecommendedJobCard(queryParams))
        dispatch(actions.fetchSalaryDetails(salaryParams))
        dispatch(actions.setAddToPathJobTitle(careerPath))
        dispatch(actions.fetchCareerPagesJobDetails(careerPath))
        dispatch(actions.fetchCourses({ title: careerPath, limit: 12 }))
        dispatch(actions.fetchJobDemand({ job_title: careerPath, location: location || '' }))

        trackInternalEvent('click_career_job_title_tag', {
          ...Label,
          title: careerPath,
        })
      }
    },
    [dispatch, location, selectedJobTitle],
  )

  return (
    <Container>
      <PathText>Path:</PathText>
      <JobTitles>
        {careerPath1.map((careerPath, index) => (
          <Fragment key={index}>
            <JobTitle
              onClick={() => handleClick(careerPath?.title)}
              isSelected={selectedJobTitle === careerPath.title}
            >
              {careerPath.title}
            </JobTitle>
            {index < careerPath1.length - 1 && <ArrowRight />}
          </Fragment>
        ))}
      </JobTitles>
    </Container>
  )
}

export default CareerPath
