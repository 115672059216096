import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Button from 'builder/components/Button'
import { TRLogo } from 'builder/components/Icon/20x20'

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 544px;
  background-color: ${Colors.White};
  border-radius: 16px;
  z-index: 999;
  padding: 32px;

  ${Media.Phone`
    max-width: 343px;
    padding: 19px;
  `};
`

export const Close = styled.div`
  position: absolute;
  width: 28px;
  height: 28px;
  top: 28px;
  right: 28px;
  border-radius: 32px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.Neutral30};
  cursor: pointer;
  transition: color 0.1s;
  background-color: ${Colors.Neutral5};

  &:hover {
    color: ${Colors.Blue50};
  }

  ${Media.Phone`
    top: 20px;
    right: 20px;
    padding: 2px;
  `};
`

export const Title = styled.div`
  ${Typography.S};
  ${FontWeights.Medium};
  width: 384px;
  margin-bottom: 16px;

  ${Media.Phone`
    width: 251px;
  `};
`

export const ResumeList = styled.div`
  height: 400px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  ${Media.Phone`
    width: 303px;
    height: 250px;  
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 12px;
  `};
`

export const ResumeCard = styled.div<{ isSelected: boolean }>`
  position: relative;
  display: flex;
  height: auto;
  max-height: 260px;
  width: 218px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 8px;
  border: ${props =>
    props?.isSelected ? `2px solid ${Colors.Blue50}` : `1px solid ${Colors.Neutral15}`};
  cursor: pointer;
  transition: border 0.2s ease-in-out, color 0.2s ease-in-out;

  &:hover {
    transition: border 0.2s ease-in-out, color 0.2s ease-in-out;
    border: ${props =>
      props?.isSelected ? `2px solid ${Colors.Blue50}` : `1px solid ${Colors.Blue50}`};
  }

  ${Media.Phone`
    width: 303px;
    flex-direction: row;
    height: 124px;  
    gap: 16px;
    padding: 12px;
    align-items: center;
  `};
`

export const DocumentPreviewContainer = styled.div`
  flex-shrink: 0;
  width: 104px;
  margin-bottom: 12px;
  max-height: 231px;

  ${Media.Phone`
    width: 72px;
    height: 100px;  
    margin-bottom: 0;
  `};
`

export const DescriptionResumeCard = styled.div`
  display: flex;
  flex-direction: column;
`

export const NameResume = styled.div`
  ${Typography.Body};
  width: 100%;
  max-width: 189px;
  color: ${Colors.Neutral90};
`

export const DateResume = styled.div`
  ${Typography.Body}
  width: 100%;
  max-width: 173px;
  color: ${Colors.Neutral50};

  ${Media.Phone`
    ${Typography.Caption};
    max-width: 162px;
  `};
`

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 8px;
  padding-top: 16px;
`

export const CloseButton = styled(Button)`
  display: flex;
  max-width: 103px;
  height: 48px;
  padding: 12px 24px;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 4px;
  background-color: ${Colors.White};
  color: ${Colors.Neutral90};
  border: 1px solid ${Colors.Neutral20};
  transition: border 0.2s ease-in-out, color 0.2s ease-in-out;

  &:hover {
    transition: border 0.2s ease-in-out, color 0.2s ease-in-out;
    border: 1px solid ${Colors.Blue50};
    background-color: ${Colors.White};
    color: ${Colors.Blue50};
  }

  ${Media.Phone`
   ${Typography.Caption};
    height: 44px;
    max-width: 94px;
  `};
`

export const ConfirmButton = styled(Button)`
  display: flex;
  max-width: 110px;
  width: 100%;
  height: 48px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: ${Colors.Blue50};

  ${Media.Phone`
    ${Typography.Caption};
    height: 44px;
    max-width: 100px;
  `};
`

export const CreateResumeImage = styled.img`
  flex-shrink: 0;
  width: 104px;
  max-height: 231px;

  ${Media.Phone`
    width: 72px;
    height: 100px;  
    margin-bottom: 0;
  `};
`

export const TrResumeLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: ${Colors.Neutral5};
  border: 1px solid ${Colors.Blue40};
  padding: 8px 8px;
  border-radius: 8px;

  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;

  ${Media.Phone`
    border: unset;
    padding: 6px;
    top: 6px;
    right: 6px;
  `}
`

export const TrResumeLabelIcon = styled(TRLogo)``
export const TrResumeLabelText = styled.div`
  ${Typography.Caption}
  ${FontWeights.Regular}
`
