import * as React from 'react'
import * as Styled from './styles'
import { Columns } from './types/types'

type ContainerProps = {
  children: React.ReactNode
} & Columns &
  React.HTMLAttributes<HTMLDivElement>

export const Container = ({ children, ...props }: ContainerProps) => {
  return <Styled.Wrapper {...props}>{children}</Styled.Wrapper>
}
