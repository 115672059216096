import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { PromotionButton } from '../styles'

export const SubmitButton = styled(PromotionButton)`
  align-self: flex-end;
`

export const BugSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`

export const BugSectionDiscountContainer = styled.div`
  display: flex;
  gap: 32px;
`

export const BugSectionDiscountContainerNl = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const BugSectionDiscountLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 296px;
`

export const BugSectionDiscountImage = styled.img`
  display: flex;
  justify-content: center;
  align-self: flex-end;
`

export const BugSectionDiscountImageNl = styled.img`
  display: flex;
  justify-content: center;
`

export const BugSectionDiscountTitle = styled.p`
  ${FontWeights.DemiBold};
  ${Typography.Subhead};
  color: ${Colors.Blue50};
`

export const BugSectionDiscountTitleNl = styled.p`
  ${FontWeights.DemiBold};
  ${Typography.Subhead};
  color: ${Colors.Neutral80};
  margin-top: 16px;
`

export const BugSectionDiscountDescription = styled.p`
  ${FontWeights.Regular};
  ${Typography.Caption};
  color: ${Colors.Neutral60};
`

export const DiscountButton = styled(PromotionButton)`
  margin-top: 16px;
`

export const BackToBuilderButton = styled(PromotionButton)`
  margin-top: 32px;
`

export const LimitedSectionDiscountContainer = styled.div`
  display: flex;
  gap: 16px;
`

export const LimitedSectionDiscountLeft = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 342px;
`

export const LimitedSectionDiscountTitle = styled.p`
  ${FontWeights.DemiBold};
  ${Typography.Body};
  color: ${Colors.Blue50};
`

export const LimitedSectionDiscountDescription = styled.p`
  ${FontWeights.Regular};
  ${Typography.Body};
  color: ${Colors.Neutral60};
`

export const TextAreaWrapper = styled.div`
  display: flex;
  width: 100%;

  & textarea {
    width: 100%;
    height: 144px;
    background: ${Colors.White};
  }

  & .textAreaField {
    width: 100%;
  }
`
