import { useCallback } from 'react'
import { ColumnName, Job } from 'builder/modules/jobTracking'

export const useIsStatusChangeAllowed = (job?: Job) => {
  const isStatusChangeAllowed = useCallback(
    (newStatus: ColumnName) => {
      if (newStatus === ColumnName.recommended) {
        return false
      }

      if (newStatus === ColumnName.deleted) {
        return true
      }
      if (job?.autoApplyStatus === 'error') {
        return true
      }

      if (newStatus !== ColumnName.wishlist && job?.status === ColumnName.auto_apply) {
        return false
      }
      if (newStatus === ColumnName.auto_apply && job?.autoApplyStatus === 'auto_applied') {
        return false
      }

      if (
        job?.status === ColumnName.auto_apply &&
        newStatus !== ColumnName.auto_apply &&
        job?.isAgentAssigned
      ) {
        return false
      }

      return true
    },
    [job],
  )

  return { isStatusChangeAllowed }
}
