import styled from 'styled-components'
import Media from 'builder/styles/media'
import Button from 'builder/components/Button'
import { Colors, hexToRgba } from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 304px;
  background: ${Colors.Neutral5};

  ${Media.Phone`
    height: 444px;
  `};
`
export const Title = styled.div`
  position: absolute;
  height: 36px;
  top: 56px;
  color: ${Colors.Neutral90};
  ${Typography.M};
  ${FontWeights.DemiBold};

  ${Media.Tablet`
    left: 20px;
    top: 56px;
  `};

  ${Media.Phone`
    ${Typography.M};
    ${FontWeights.DemiBold};
    top: 210px;
    width: 100%;
    padding-left:10px;
    margin: 0px;
    left: 50%;
    transform: translate(-50%);
    padding-left:25px;
  `};
`
export const Text = styled.div`
  position: absolute;
  width: 352px;
  height: 72px;
  top: 104px;
  color: ${Colors.Neutral90};
  ${Typography.Body};
  ${FontWeights.Regular};

  ${Media.Tablet`
    left: 20px;
  `};

  ${Media.Phone`
    ${Typography.Caption};
    top: 238px;
    width: 100%;
    height: 40px;
    padding:10px;
    left: 50%;
    transform: translate(-50%);
    padding-left:25px;
  `};

  @media (max-width: 280px) {
    top: 258px;
  }
`

export const ViewMyResumeButton = styled(Button)`
  gap: 10px;
  height: 48px;
  top: 200px;
  width: 174px;
  border-radius: 4px;
  background: ${Colors.Blue50};
  margin: 10px;

  ${Media.Tablet`
    top: 200px;
  `};

  ${Media.Phone`
    position: absolute;
    width: 90%;
    top: 308px;
    border-radius: 4px;
    left: 5%;
    right: 5%;
    margin:0px;
  `}
`

export const TryLaterButton = styled(Button)`
  gap: 10px;
  top: 200px;
  width: 114px;
  border: solid ${Colors.Neutral10};
  border-radius: 4px;
  color: ${Colors.Neutral90};
  background: ${Colors.Neutral5};
  &:hover {
    background: ${Colors.Neutral5};
  }

  ${Media.Tablet`
    top: 200px;
  `};

  ${Media.Phone`
    position: absolute;
    width: 90%;
    top: 364px;
    border-radius: 4px;
    left: 5%;
    right: 5%;
  `}
`

export const BrokenCardContainer = styled.button`
  width: 293px;
  margin-right: 102px;
  height: 293px;
  border: solid ${Colors.Neutral5};

  ${Media.Tablet`
    height: 273px;
    position:absolute;
    right: 56px;
    margin-right:12px;
  `}

  ${Media.Phone`
    position:absolute;
    width: 196.33px;
    height: 176.33px;
    top: 21.44px;
    margin: 0;
    left: 50%;
    transform: translate(-50%);
  `}
`

export const BrokenResumeContainer = styled.img`
  width: 293px;
  margin-right: 102px;
  margin-top: 32px;
  max-height: 293px;
  height: auto;

  ${Media.Tablet`
    height: 253px;
    right: 56px;
    margin-top: 32px;
    margin-right:8px;
  `}

  ${Media.Phone`
    position:absolute;
    width: 196.33px;
    height: 166.33px;
    top: 10.44px;
    margin: 0;
    left: 50%;
    transform: translate(-50%);
    z-index:-1;
  `}
`

export const AlertContainer = styled.img`
  position: absolute;
  width: 40px;
  height: 40px;
  margin-left: 268px;
  top: 23px;

  ${Media.Tablet`
    top: 16px;
  `}

  ${Media.Phone`
    top: 4px;
    width: 21.6px;
    height: 21.6px;
    margin-left: 84px;
  `}
`

export const BrokenCardGradient = styled.div`
  width: 293px;
  height: 101.2px;
  position: absolute;
  top: 205px;
  bottom: 0;
  margin-right: 102px;
  background: linear-gradient(
    to bottom,
    ${hexToRgba(Colors.Neutral5, 0)},
    ${Colors.Neutral5} 100%,
    ${Colors.Neutral5} 0%
  );

  ${Media.Tablet`
    top: 185px;
    margin-right:4px;
  `};

  ${Media.Phone`
    height: 71.2px;
    top: 95px;
    width: 196.33px;
    margin: 0;
    left: 50%;
    transform: translate(-50%);
  `}
`

export const BannerLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  margin-right: 32px;

  ${Media.Phone`
    margin-right: 0;
    margin-bottom: 20px;
  `};
`

export const BannerRightContent = styled.div`
  display: flex;
  align-items: center;

  ${Media.Tablet`
    width: auto;
  `};

  ${Media.Phone`
    width: 100%;
  `};
`

export const ButtonContainer = styled.div`
  float: left;
`
