import EditorField from 'builder/components/EditorField'
import { TextField } from 'builder/components/TextField'
import { useI18n } from 'builder/hooks/useI18n'
import { actions } from 'builder/modules/resumeEditor'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export function Dependants() {
  const { i18n } = useI18n()
  const { updateSimpleField } = actions
  const numberOfDependents = useSelector(state => state.resumeEditor.resume.numberOfDependents)
  const dispatch = useDispatch()

  const handleChange = useCallback(
    event => {
      dispatch(
        updateSimpleField({
          name: 'numberOfDependents',
          value: event.target.value,
        }),
      )
    },
    [updateSimpleField, dispatch],
  )

  return (
    <>
      <EditorField>
        <TextField
          label={i18n.t('builder.resume_editor.professional_summary.dependants')}
          name="numberOfDependents"
          type="number"
          value={numberOfDependents || ''}
          onChange={handleChange}
          min={0}
        />
      </EditorField>
    </>
  )
}
