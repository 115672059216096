import { useEffect, useState, useMemo, memo } from 'react'
import type { CSSProperties } from 'react'
import { clampNumber } from 'builder/utils/clampNumber'
import { Background, Position, Ratio } from '../types'
import { loadImage, getImageRatio } from '../utils'
import BackgroundPreview from '../BackgroundPreview'
import AvatarSpinner from '../AvatarSpinner'
import { PreviewContainer, Group, PhotoLayer, AvatarSpinnerContainer } from './styles'

interface PreviewProps {
  background: Background
  imageUrl: string | null
  position: Position
  zoom: number
  angle: number
  isLoading: boolean
}

const AvatarPreviewComponent = ({
  background,
  imageUrl,
  position,
  zoom,
  angle,
  isLoading,
}: PreviewProps) => {
  const [ratio, setRatio] = useState<Ratio | null>(null)

  useEffect(() => {
    setRatio(null)

    if (imageUrl) {
      loadImage(imageUrl).then(image => setRatio(getImageRatio(image)))
    }
  }, [imageUrl])

  const photoStyle: CSSProperties | null = useMemo(() => {
    if (!ratio) return null

    const translate = {
      x: clampNumber(position.x, ratio.x / 2 / zoom, 1 - ratio.x / 2 / zoom),
      y: clampNumber(position.y, ratio.y / 2 / zoom, 1 - ratio.y / 2 / zoom),
    }

    return {
      [ratio.y > ratio.x ? 'height' : 'width']: zoom * 100 + '%',
      transform: `translate(-${translate.x * 100}%, -${translate.y * 100}%)`,
    }
  }, [position, ratio, zoom])

  return (
    <PreviewContainer>
      <Group fade>
        <BackgroundPreview background={background} size={92} />

        {isLoading ? (
          <AvatarSpinnerContainer>
            <AvatarSpinner size={20} />
          </AvatarSpinnerContainer>
        ) : (
          <div>
            {imageUrl && photoStyle && (
              <Group style={{ transform: `rotate(${angle}deg)` }}>
                <PhotoLayer src={imageUrl} style={photoStyle} />
              </Group>
            )}
          </div>
        )}
      </Group>
    </PreviewContainer>
  )
}

export const AvatarPreview = memo(AvatarPreviewComponent)
