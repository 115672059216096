import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import { MenuItem } from 'builder/components/Menu'
import Media from 'builder/styles/media'

export const MenuItemWrapper = styled(MenuItem)<{ styles?: string }>`
  ${({ styles }) => styles};
`

export const Container = styled.div<{ active?: boolean }>`
  display: flex;
  margin: 0 4px 16px;
  padding: 8px 3px;
  border-radius: 12px;

  &:hover {
    background: ${({ active }) => (active ? Colors.Blue10 : Colors.Neutral5)};
  }

  ${({ active }) =>
    active &&
    css`
      color: ${Colors.Blue50};
      background: ${Colors.Blue10};
    `}

  ${Media.Phone`
    padding: 0;
    margin: 12px 0;
  `};
`
