import uniqBy from 'lodash/uniqBy'
import sortBy from 'lodash/sortBy'
import parseISO from 'date-fns/parseISO'
import { createAction, createReducer } from '@reduxjs/toolkit'
import { FetchStatuses } from 'builder/modules/constants'
import { Store } from 'builder/modules/store'
import { selectors as appSelectors } from 'builder/modules/init'
import { ResumeReviewStore, ResumeCritique } from './types'
import { overrideCritiqueFields } from './utils'

export const actions = {
  sendResume: createAction<{ resumeId: number }>('builder/SEND_RESUME_TO_REVIEW'),
  fetchCritiques: createAction('builder/FETCH_RESUME_CRITIQUES'),
  setCritiquesFetchStatus: createAction<FetchStatuses>('builder/SET_RESUME_CRITIQUES_STATUS'),
  setCritiques: createAction<ResumeCritique[]>('builder/SET_RESUME_CRITIQUES'),
}

export const selectors = {
  scenario: (state: Store) => appSelectors.feature(state, 'resumeReviewScenario') ?? null,
  critiquesFetchStatus: (state: Store) => state.resumeReview.critiquesFetchStatus,
  critiques: (state: Store) => state.resumeReview.critiques,
  critique: (state: Store, resumeId: number) => {
    return state.resumeReview.critiques.find(critique => critique.resumeId === resumeId)
  },
}

const initialState: ResumeReviewStore = {
  critiquesFetchStatus: FetchStatuses.notAsked,
  critiques: [],
}

export const reducer = createReducer(initialState, reducer => {
  reducer.addCase(actions.setCritiquesFetchStatus, (draft, action) => {
    draft.critiquesFetchStatus = action.payload
  })

  reducer.addCase(actions.setCritiques, (draft, action) => {
    // Modify critique fields (fake review ending time)
    const newCritiques = action.payload.map(overrideCritiqueFields)
    // Marge arrays and remove duplicates (only one critique per resume)
    const critiques: ResumeCritique[] = uniqBy([...newCritiques, ...draft.critiques], 'resumeId')
    // Sort critiques by their creating date (recent ones first)
    draft.critiques = sortBy(critiques, critique => -parseISO(critique.createdAt).getTime())
  })
})
