import styled, { css } from 'styled-components'
import { Colors, hexToRgba } from 'builder/styles/colors'
import { Typography, FontWeights } from 'builder/styles/typography'
import { rotation } from 'builder/styles/keyframes'
import Icon24 from '../Icon'

export const CardVisualWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 212px;
  flex-shrink: 0;
  border-radius: 16px 16px 0 0;
`

export const CardVisualGradient = styled.div<{ bgColor: string }>`
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${props => `
    linear-gradient(
      to bottom,
      ${hexToRgba(props.bgColor, 0)} 0%,
      ${props.bgColor} 100%)
    `};
`

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  padding: 24px;
`

export const CardTitle = styled.div`
  ${Typography.Body};
  ${FontWeights.DemiBold};
  margin-bottom: 4px;
`

export const CardText = styled.div`
  ${Typography.Caption};
  flex-grow: 1;
  margin-bottom: 16px;
`

export const CardButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background-color: var(--color-tone-20);
  transition: background-color 0.15s, color 0.15s;
`

export const CardContainer = styled.button<{
  completed?: boolean
  clickable: boolean
  resourceCard?: boolean
}>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  width: 268px;
  height: 376px;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 16px;
  background: var(--color-tone-10);
  color: var(--color-tone-80);
  text-align: left;
  cursor: ${props => (props.clickable ? 'pointer' : 'default')};
  user-select: none;

  ${props =>
    props.clickable &&
    css`
      &:hover ${CardButton} {
        background-color: var(--color-tone-50);
        color: var(--white);
      }
    `}

  ${props =>
    props.resourceCard &&
    css`
      background-color: ${Colors.Neutral5};
      &:hover {
        background-color: ${Colors.Neutral10};
      }
    `}

    
  ${props =>
    props.completed &&
    css`
      background-color: ${Colors.Neutral5};
      color: ${Colors.Neutral70};

      & ${CardVisualGradient} {
        background: linear-gradient(
          to bottom,
          ${hexToRgba(Colors.Neutral5, 0)} 0%,
          ${Colors.Neutral5} 100%
        );
      }

      & ${CardText} {
        color: ${Colors.Neutral50};
      }

      & ${CardButton} {
        background-color: ${Colors.Green10};
        color: ${Colors.Green50};
      }
    `};
`

export const Spinner = styled(Icon24.Spinner)`
  color: ${Colors.Blue50};
  animation: ${rotation} 0.5s infinite linear;
  left: 4px;
  top: 4px;
`

export const SpinnerContainer = styled.div`
  width: 32px;
  height: 32px;
  left: 24px;
  top: 320px;
  background: ${Colors.Blue20};
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
`
export const PrefillText = styled.div`
  color: ${Colors.Blue50};
  ${Typography.Tiny};
  ${FontWeights.DemiBold};
  align-items: center;
  margin: 8px;
`

export const LoaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
`

export const ResourceCardVisualWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 212px;
  flex-shrink: 0;
  border-radius: 16px 16px 0 0;
`
