import { selectors } from 'builder/modules/careerPath2'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

import { Colors } from 'builder/styles/colors'
import { Container } from './styles'

type Props = {
  level: number
}

export const DefaultConnection = ({ level }: Props) => {
  const { isPhone } = useMediaQueries()
  const isSwiperVisible = useTypedSelector(selectors.isSwiperVisible)

  return (
    <Container
      height="8"
      fill="none"
      level={level}
      width={isPhone ? '48' : '56'}
      xmlns="http://www.w3.org/2000/svg"
      isSwiperVisible={isSwiperVisible}
      viewBox={isPhone ? '0 0 48 8' : '0 0 56 8'}
    >
      <path d={isPhone ? 'M0 2H48' : 'M0 2H56'} stroke={Colors.Neutral15} strokeWidth="8" />
    </Container>
  )
}
