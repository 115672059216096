import styled, { css } from 'styled-components'
import { animated } from '@react-spring/web'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import DocumentImagePreview from 'builder/components/DocumentImagePreview'
import Media from 'builder/styles/media'
import Shadows from 'builder/styles/shadows'

interface PercentProps {
  score: number
}

export const TopWrapper = styled.div``

export const LeftContainer = styled.div`
  display: flex;
  margin-bottom: 16px;

  ${Media.Phone`
    margin-bottom: 9px;
  `}
`
export const ScoreContainer = styled.div`
  margin-left: 16px;

  ${Media.Phone`
  margin-left: 12px;
  `}
`

export const CompanyLogo = styled.img<{ size: number }>(({ size }) => {
  return css`
    width: ${size}px;
    height: ${size}px;
    ${Shadows.lightWithBorder.low};
    border-radius: 4px;

    ${Media.Phone`
      width: ${size}px;
      height: ${size}px;
    `};
  `
})

export const CompanyDesignation = styled.div`
  /* Desktop and Tablet/Caption • Regular */
  ${Typography.Caption}
  ${FontWeights.Regular}
  width: 256px;
  height: 20px;
  color: ${Colors.White};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 8px;

  ${Media.Phone`
    width: 192px;  
    height: 16px; 
  `};
`

export const Percent = styled(animated.div)`
  display: inline-block;
  ${Typography.Tiny};
  ${FontWeights.DemiBold}
  border-radius: 4px;
  margin-right: 6px;
  color: ${Colors.White};
  font-variant-numeric: tabular-nums;
  width: fit-content;
  height: 20px;
  padding: 2px 4.5px;

  &:after {
    content: '%';
  }

  ${Media.Phone`
    padding: 1px 2px;
    height: fit-content;
  `}
`

export const PercentTitle = styled.div`
  /* Desktop and Tablet/Caption • Regular */
  ${Typography.Caption}
  ${FontWeights.Regular};
  color: ${Colors.Red40};
`

export const PercentWrapper = styled.div`
  display: flex;
`

export const Progress = styled.div`
  height: 3px;
  background-color: ${Colors.Neutral80};
  margin-top: 6px;
`

export const ProgressInner = styled(animated.div)`
  height: 3px;
  width: 100%;
  transform-origin: left;
`

export const ResumePreview = styled(DocumentImagePreview)`
  width: 320px;
  margin-top: 12px;
  border-radius: 2px;
  background: linear-gradient(180deg, #f7f9fc 0%, ${Colors.White} 100%);
  border-image: linear-gradient(to bottom, ${Colors.Neutral15} 60%, ${Colors.White} 100%);
  border-image-slice: 1;

  ${Media.Phone`
    width: 252px;
  `}
`

export const Container = styled.div<PercentProps>`
  width: 352px;
  height: 236px;
  position: relative;
  border-radius: 16px;
  left: 96px;
  top: 64px;
  padding: 16px;
  background: ${Colors.Neutral100};
  overflow: hidden;
  background: radial-gradient(
      100% 116.2% at 0% 0%,
      rgba(254, 125, 139, 0.12) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(180deg, rgba(30, 37, 50, 0.8) 0%, rgba(30, 37, 50, 0) 100%);

  ${({ score }) => {
    switch (true) {
      case score > 70:
        return `
        border: 1px solid ${Colors.Green40};
          ${Percent} {
            background-color: ${Colors.Green40};
          }
          ${PercentTitle} {
            color: ${Colors.Green40};
          }
          ${ProgressInner}{
            background: ${Colors.Green40};
          }
        `
      case score > 30:
        return `
        border: 1px solid ${Colors.Amber40};
          ${Percent} {
            background-color: ${Colors.Amber40};
          }
          ${PercentTitle} {
            color: ${Colors.Amber40};
          }
          ${ProgressInner}{
            background: ${Colors.Amber40};
          }
        `
      default:
        return `
        border: 1px solid ${Colors.Red40};
          ${Percent} {
            background-color: ${Colors.Red40};
          }
          ${PercentTitle} {
            color: ${Colors.Red40};
          }
          ${ProgressInner}{
            background: ${Colors.Red40};
          }
        `
    }
  }}
  ${Media.Phone`
    width: 277.42px;
    height: 234px;
    left: 32px;
    top: 0px;
    padding: 12px;
  `};
`
