import styled from 'styled-components'
import Media from 'builder/styles/media'
import { Container as BaseContainer } from 'builder/components/Container'
import { HorizontalTransitionFade } from 'builder/components/HorizontalTransitionFade'

export const Container = styled(HorizontalTransitionFade)`
  width: calc(100% - 40px);
  padding-top: 128px;
  margin: 0 auto;

  ${Media.Phone`
    padding-top: 88px;
  `}
`

export const FormContainer = styled(BaseContainer)`
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  ${Media.Phone`
    flex-grow: 1;
  `};
`
