import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 16px;
`
