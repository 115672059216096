import { useMutation } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'

export type UploadInterviewVideoParams = {
  url: string
  blob: Blob
  signal: AbortSignal
}

export const useUploadInterviewVideo = () => {
  return useMutation({
    mutationFn: async (params: UploadInterviewVideoParams): Promise<AxiosResponse<Response>> => {
      const { url, blob } = params
      const response = await axios.put<Response>(url, blob, {
        signal: params.signal,
      })

      return response
    },
  })
}
