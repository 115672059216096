import { createReducer } from '@reduxjs/toolkit'
import find from 'lodash/find'
import trim from 'lodash/trim'
import { v4 as uuidv4 } from 'uuid'
import {
  EnumerableResumeSectionType,
  Resume,
  ResumeCustomSectionCard,
  ResumeSection,
} from 'packages/types'
import { WritableDraft } from 'immer/dist/internal'
import { addIfNotExists, indexOfCaseInsensitive } from 'builder/utils/arrayUtils'
import { TABS } from 'builder/components/FillResumeModal/constants'
import { UseExample } from 'builder/components/FillResumeModal/UseExample/types'
import {
  AIResumeLinkedInSteps,
  AIResumeFlows,
  FetchStatuses,
  ResumeUploadStatus,
  ResumeValidationStatus,
  GenerateResumeStatus,
  SpeechToTextStatus,
  GenerateAiProfileStatus,
} from '../constants'
import { newRecordFor } from './newRecord'
import { SectionNames } from './sectionNames'
import { STEP_NAMES, steps } from './stepNames'
import { actions } from './resumeEditorActions'
import {
  AIProfile,
  AIResume,
  JobPostingAPIData,
  PrefillProviders,
  PrefillStatuses,
  QuestionnaireData,
  ResumeEditorStore,
  ResumeScoreAPIData,
} from './types'

const initialJobPostingAPIData: JobPostingAPIData = {
  recommendedJobTitle: '',
  employerName: '',
  visibleSkills: [''],
  jobPostingId: 0,
  isManagementJob: false,
  visibleJobTitle: '',
  recommendedSkills: [''],
  resumeScore: 0,
  apiScore: 0,
  averageScore: 0,
  jobPostingLink: '',
  keywords: [],
  recommendedKeywords: [],
}

const initialResumeScoreAPIData: ResumeScoreAPIData = {
  jobPostingId: 0,
  employerName: '',
  suggestedJobTitle: '',
  averageScore: 0,
  keywords: [],
}

const initialOptimizerManualInputData = {
  companyName: '',
  jobTitle: '',
  role: '',
}

const initialLinkedInState = {
  currentStep: AIResumeLinkedInSteps.linkedInPrefill,
  linkedInProfileURL: '',
  fetchingLinkedInProfile: false,
  linkedInProfileData: {
    firstName: '',
    headline: '',
    lastName: '',
  },
}

const initialDateRangePickerValue = {
  dateFrom: '',
  dateUntil: '',
  isMonthFromHidden: false,
  isDateUntilPresent: false,
  isMonthUntilHidden: false,
}

const initialAiProfileState: AIProfile = {
  isClickedOnAiCVEditJobDetails: false,
  isClickedOnAiCVEditModalIcon: false,
  isEditJobDetailsFocused: false,
  generateAiProfileStatus: GenerateAiProfileStatus.notStarted,
  showPaywall: false,
  actionType: null,
  shouldAnimateResultPopupContent: true,
  shouldAnimateEditorFieldContent: false,
  summary: null,
  isProfileSummaryWithTailoredResumeModalOpen: false,
  includeJobPosting: false,
}

const initialQuestionnaireState: QuestionnaireData = {
  step: 1,
  progress: 0,
  activeAccomplishmentsTab: TABS.voice_input,
  accomplishmentsVoiceProcessingStatus: SpeechToTextStatus.notStarted,
  accomplishmentsVoiceInputError: null,
  accomplishmentsAudio: [],
  accomplishmentsAudioDuration: 0,
  goalsVoiceProcessingStatus: SpeechToTextStatus.notStarted,
  goalsVoiceInputError: null,
  activeGoalsTab: TABS.voice_input,
  goalsAudio: [],
  goalsAudioDuration: 0,
  userInput: {
    workExperience: {
      cards: [
        {
          id: uuidv4(),
          position: '',
          company: '',
          date: initialDateRangePickerValue,
          errors: {
            position: false,
            company: false,
            dateFrom: false,
            dateUntil: false,
          },
          isCollapsed: false,
        },
      ],
      confirmDeleteCardId: '',
    },
    desiredPosition: '',
    education: {
      cards: [
        {
          id: uuidv4(),
          institute: '',
          degree: '',
          date: initialDateRangePickerValue,
          isCollapsed: false,
        },
      ],
    },
    currentSkills: [],
    suggestedSkillsSource: [],
    suggestedSkills: [],
    pastAccomplishments: '',
    futureGoals: '',
  },
}

const initialAIResumeState: AIResume = {
  showAIResumeModal: false,
  aiResumeFlow: AIResumeFlows.LinkedIn,
  hideLinkedInFlow: false,
  linkedInData: initialLinkedInState,
  questionnaireData: initialQuestionnaireState,
  generateResumeStatus: GenerateResumeStatus.notStarted,
  showWarningModal: false,
  warningModalType: null,
  warningModalAction: null,
}

const initialUseExampleState: UseExample = {
  showUseExampleModal: false,
  resumeExamples: [],
  fetchingResumeExamples: false,
  showChangeTemplateTooltip: false,
}

const initialState: ResumeEditorStore = {
  isLoading: false,
  isSyncing: false,
  prefill: {
    status: PrefillStatuses.notAsked,
    provider: PrefillProviders.topresume,
    resumeId: null,
  },
  isUploadingPhoto: false,
  resume: null,
  currentWizardStepId: STEP_NAMES.details,
  openCardId: null,
  wizardSteps: steps.map(step => ({ ...step, done: false })),
  levels: null,
  templates: [],
  error: null,
  isOptimizerModalOpen: false,
  editJobPosting: false,
  isResumeOptimizerBannerOpen: true,
  openedAISuggestionsPopup: null,
  viewGenerateCLBannerEventTracked: false,
  optimizerSection: {
    jobPostingLinkForModal: '',
    fetchScoreStatus: FetchStatuses.notAsked,
    fetchJobPostingDataStatus: FetchStatuses.notAsked,
    saveJobPostingDataStatus: FetchStatuses.notAsked,
    deleteJobPostingStatus: FetchStatuses.notAsked,
    editJobDetailsStatus: FetchStatuses.notAsked,
    showManualInputScreen: false,
    temporaryJobPostingAPIData: initialJobPostingAPIData,
    resumeScoreAPIData: initialResumeScoreAPIData,
    jobPostingAPIData: initialJobPostingAPIData,
    manualInput: initialOptimizerManualInputData,
  },
  prefillResumeSection: {
    resumeValidationStatus: ResumeValidationStatus.notStarted,
    selectedResume: null,
    isSavingPrefilledResume: false,
    validationError: null,
    resumeUploadStatus: ResumeUploadStatus.notStarted,
    error: null,
    errorSource: null,
  },
  isEditJobDetails: false,
  showToolTip: false,
  companyName: '',
  jobTitle: '',
  animatePersonalDetailsSection: false,
  animateResumeOptimizerBanner: false,
  isClickedOnDeleteJobPosting: false,
  isClickedOnEditJobDetails: false,
  isFromJobTracker: false,
  isOpenSavingInfoBanner: false,
  changeJobPostingLink: false,
  isClickedOnAiCVBanner: false,
  // AI Cover Letter
  AICoverLetter: {
    isClickedOnAiCVEditJobDetails: false,
    isClickedOnAiCVEditModalIcon: false,
    isEditJobDetailsFocused: false,
    isOpenAICVOptimizerModal: false,
    generateAICoverLetterStatus: FetchStatuses.notAsked,
    aICVEditedJobTitle: initialResumeScoreAPIData.suggestedJobTitle,
    aICVEditedCompanyName: initialResumeScoreAPIData.employerName,
    isShowUntailoreView: false,
  },
  // AI PROFILE SUMMARY
  aiProfile: initialAiProfileState,
  aiResume: initialAIResumeState,
  useExample: initialUseExampleState,
  viewTailoringBannerEventTracked: false,
  highlightedElementData: null,
  openedKeywordsModalType: null,
  isImproveResumePanelOpen: false,
  isHelpWithWritingOpen: false,
  isAIProfileSummaryResultOpen: false,
  // Auto Tailoring
  autoTailoring: {
    fetchStatus: FetchStatuses.notAsked,
    openedModal: null,
    isApproveModalOpen: false,
    autoTailoredResume: null,
    jobPostingData: null,
    keywordsMap: {},
    autoTailoredResumeApproveStatus: FetchStatuses.notAsked,
  },
  originalResume: null,
  resumeEventTrackedSections: {},
}

function getSectionCards(
  resume: Resume,
  sectionName: number | EnumerableResumeSectionType,
  isCustom?: boolean,
): ResumeCustomSectionCard[] | ResumeSection {
  if (typeof sectionName === 'number' || isCustom) {
    const sectionIndex = resume.customSections.findIndex(el => el.id === sectionName)
    return resume.customSections[sectionIndex].items
  }

  return resume[sectionName]
}

export const reducer = createReducer(initialState, reducer => {
  // we need this helper because typescript works bad with action reducers
  const updateDraft = <T, K extends keyof T>(
    object: WritableDraft<T>,
    key: K,
    value: WritableDraft<T>[K],
  ) => {
    object[key] = value
  }

  const addDraftToArray = <T>(
    array: WritableDraft<T>[],
    item: WritableDraft<T>,
    position: 'before' | 'after' = 'after',
  ) => {
    if (position === 'before') {
      array.unshift(item)
    } else {
      array.push(item)
    }
  }

  reducer.addCase(actions.changeEditorState, (draft, { payload }) => {
    draft = Object.assign(draft, payload)
  })
  reducer.addCase(actions.fetchResumeRequest, draft => {
    draft.isLoading = true
    draft.resume = null
    draft.error = null
    draft.viewGenerateCLBannerEventTracked = false
    draft.viewTailoringBannerEventTracked = false
    draft.isImproveResumePanelOpen = false
  })
  reducer.addCase(actions.fetchResumeFail, (draft, action) => {
    draft.isLoading = false
    draft.error = action.payload
  })
  reducer.addCase(actions.fetchResumeSuccess, (draft, action) => {
    draft.isLoading = false
    draft.resume = action.payload
  })
  reducer.addCase(actions.updateSimpleField, (draft, action) => {
    if (!draft.resume) return

    const { name, value } = action.payload

    updateDraft(draft.resume, name, value)

    draft.isSyncing = true
  })
  reducer.addCase(actions.updateCard, (draft, action) => {
    if (!draft.resume) return

    const { sectionId, cardId, values, isCustom } = action.payload
    const cards = getSectionCards(draft.resume, sectionId, isCustom)

    const index = cards.findIndex(el => el.id === cardId)
    cards[index] = {
      ...cards[index],
      ...values,
    }
    draft.isSyncing = true
  })
  reducer.addCase(actions.addCard, (draft, { payload }) => {
    if (!draft.resume) return

    const { sectionName, cardId, options = {} } = payload
    const { position = 'after', fields, shouldOpen } = options
    const customSectionIndex = draft.resume.customSections.findIndex(
      el => el.id === sectionName || el.externalId === sectionName,
    )
    let card: ReturnType<typeof newRecordFor> | undefined

    if (customSectionIndex !== -1) {
      card = newRecordFor(SectionNames.custom, cardId, fields)
      draft.resume.customSections[customSectionIndex].items.push(card)
    } else if (sectionName in draft.resume) {
      const enumerableSectionName = sectionName as EnumerableResumeSectionType

      if (
        draft.resume[enumerableSectionName].length === 0 &&
        !draft.resume.sectionsOrder.some(el => el === enumerableSectionName) &&
        // TODO: remove this hack after moving section order update to separate action
        enumerableSectionName !== SectionNames.socialProfiles
      ) {
        draft.resume.sectionsOrder.push(enumerableSectionName)
      }

      card = newRecordFor(enumerableSectionName, cardId, fields)
      const section = draft.resume[enumerableSectionName]

      addDraftToArray(section, card, position)
    }

    draft.isSyncing = true
    if (shouldOpen && card) draft.openCardId = card.id
  })
  reducer.addCase(actions.deleteCard, (draft, { payload }) => {
    if (!draft.resume) return

    const { sectionName, cardId, isCustom } = payload

    const cards = getSectionCards(draft.resume, sectionName, isCustom)

    const index = cards.findIndex(el => el.id === cardId)
    cards.splice(index, 1)

    draft.isSyncing = true
  })
  reducer.addCase(actions.moveCard, (draft, { payload }) => {
    if (!draft.resume) return

    const { oldIndex, newIndex, sectionId } = payload
    const { resume } = draft
    const cards =
      typeof sectionId === 'number'
        ? find(resume.customSections, { id: sectionId })?.items
        : resume[sectionId]

    if (cards) {
      const [removed] = cards.splice(oldIndex, 1)
      cards.splice(newIndex, 0, removed)

      draft.isSyncing = true
    }
  })
  reducer.addCase(actions.moveSection, (draft, { payload }) => {
    if (!draft.resume) return

    const { offset, sectionId } = payload
    const { sectionsOrder } = draft.resume
    const oldIndex = sectionsOrder.indexOf(sectionId)

    if (oldIndex !== -1) {
      const [removed] = sectionsOrder.splice(oldIndex, 1)
      sectionsOrder.splice(oldIndex + offset, 0, removed)

      draft.isSyncing = true
    }
  })
  reducer.addCase(actions.setCustomSection, (draft, action) => {
    if (!draft.resume) return

    const { externalId } = action.payload
    const section = {
      externalId,
      items: [],
    }
    draft.resume.customSections.push(section)
    draft.resume.sectionsOrder.push(`custom:${externalId}`)
    draft.isSyncing = true
  })
  reducer.addCase(actions.renameSection, (draft, action) => {
    if (!draft.resume) return

    const { id, value } = action.payload
    // Edit `sectionTitles` object if it's not a custom section
    if (id in SectionNames) {
      if (value && trim(value).length > 0) {
        draft.resume.sectionTitles[id] = trim(value).slice(0, 80) // max length is 80 chars
      } else {
        // Unset key if the string is empty or has been reverted
        delete draft.resume.sectionTitles[id]
      }
    } else {
      const customSection = draft.resume.customSections.find(el => el.id === id)
      if (customSection) {
        customSection.title = value
      }
    }
    draft.isSyncing = true
  })
  reducer.addCase(actions.deleteSection, (draft, action) => {
    if (!draft.resume) return

    const { id, options } = action.payload
    const sortId = options.isCustom ? options.sortId : id
    if (typeof id === 'number' || options.isCustom) {
      draft.resume.customSections = draft.resume.customSections.filter(el => el.id !== id)
    } else {
      draft.resume[id] = []
      delete draft.resume.sectionTitles[id]
    }
    draft.resume.sectionsOrder = draft.resume.sectionsOrder.filter(el => el !== sortId)
    if (id === SectionNames.references) draft.resume.referencesUponRequest = false
    draft.isSyncing = true
  })
  reducer.addCase(actions.updateResumeFail, (draft, action) => {
    draft.isSyncing = false
    draft.error = action.payload || null
  })
  reducer.addCase(actions.updateResumeSuccess, (draft, action) => {
    draft.isSyncing = false
    draft.resume = action.payload
  })
  reducer.addCase(actions.updateRecommendedJobsForJobTitle, draft => {
    draft.isSyncing = true
  })
  reducer.addCase(actions.updateRecommendedJobsForJobTitleSuccess, draft => {
    draft.isSyncing = false
  })
  reducer.addCase(actions.updateRecommendedJobsForJobTitleFail, draft => {
    draft.isSyncing = false
  })
  reducer.addCase(actions.setCurrentWizardStep, (draft, action) => {
    draft.currentWizardStepId = action.payload
  })
  reducer.addCase(actions.updateWizardStep, (draft, action) => {
    const step = draft.wizardSteps.find(step => step.id === action.payload.id)
    if (step) {
      Object.assign(step, action.payload)
    }
  })
  reducer.addCase(actions.openCard, (draft, action) => {
    draft.openCardId = action.payload
  })
  reducer.addCase(actions.setPrefillStatus, (draft, action) => {
    draft.prefill = { ...draft.prefill, status: action.payload }
  })
  reducer.addCase(actions.setPrefillProvider, (draft, action) => {
    draft.prefill = { ...draft.prefill, provider: action.payload }
  })
  reducer.addCase(actions.setPrefillResumeId, (draft, action) => {
    draft.prefill = { ...draft.prefill, resumeId: action.payload }
  })
  reducer.addCase(actions.setOpenOptimizerModal, (draft, action) => {
    draft.isOptimizerModalOpen = action.payload
  })
  reducer.addCase(actions.setIsEditJobPosting, (draft, action) => {
    draft.editJobPosting = action.payload
  })
  reducer.addCase(actions.setFetchResumeScoreStatus, (draft, action) => {
    draft.optimizerSection.fetchScoreStatus = action.payload
  })
  reducer.addCase(actions.setSaveJobPostingDataStatus, (draft, action) => {
    draft.optimizerSection.saveJobPostingDataStatus = action.payload
  })
  reducer.addCase(actions.setJobPostingLinkForModal, (draft, action) => {
    draft.optimizerSection.jobPostingLinkForModal = action.payload
  })
  reducer.addCase(actions.setResumeOptimizerBannerOpen, (draft, action) => {
    draft.isResumeOptimizerBannerOpen = action.payload
  })
  reducer.addCase(actions.animatePersonalDetailsSection, (draft, action) => {
    draft.animatePersonalDetailsSection = action.payload
  })
  reducer.addCase(actions.animateResumeOptimizerBanner, (draft, action) => {
    draft.animateResumeOptimizerBanner = action.payload
  })
  reducer.addCase(actions.setResumeScoreAPIResponse, (draft, action) => {
    draft.optimizerSection.resumeScoreAPIData = action.payload
  })
  reducer.addCase(actions.setFetchJobPostingDataStatus, (draft, action) => {
    draft.optimizerSection.fetchJobPostingDataStatus = action.payload
  })
  reducer.addCase(actions.setDeleteJobPostingStatus, (draft, action) => {
    draft.optimizerSection.deleteJobPostingStatus = action.payload
  })
  reducer.addCase(actions.editJobDetailsStatus, (draft, action) => {
    draft.optimizerSection.editJobDetailsStatus = action.payload
  })
  reducer.addCase(actions.resetResumeScoreAPIResponse, draft => {
    draft.optimizerSection.resumeScoreAPIData = { ...initialResumeScoreAPIData }
  })
  reducer.addCase(actions.setShowManualInputScreen, (draft, action) => {
    draft.optimizerSection.showManualInputScreen = action.payload
  })
  reducer.addCase(actions.setTemporaryJobPostingAPIData, (draft, action) => {
    draft.optimizerSection.temporaryJobPostingAPIData = action.payload
  })
  reducer.addCase(actions.saveJobPostingAPIData, draft => {
    draft.optimizerSection.jobPostingAPIData = {
      ...draft.optimizerSection.temporaryJobPostingAPIData,
    }
    draft.optimizerSection.temporaryJobPostingAPIData = { ...initialJobPostingAPIData }
  })
  reducer.addCase(actions.resetJobPostingAPIData, draft => {
    draft.isResumeOptimizerBannerOpen = true
    draft.optimizerSection.resumeScoreAPIData = { ...initialResumeScoreAPIData }
    draft.optimizerSection.temporaryJobPostingAPIData = { ...initialJobPostingAPIData }
    draft.optimizerSection.jobPostingAPIData = { ...initialJobPostingAPIData }
  })
  reducer.addCase(actions.setOpenedAISuggestionsPopup, (draft, action) => {
    draft.openedAISuggestionsPopup = action.payload
  })
  reducer.addCase(actions.setIsEditJobDetails, (draft, action) => {
    draft.isEditJobDetails = action.payload
  })
  reducer.addCase(actions.ShowToolTip, (draft, action) => {
    draft.showToolTip = action.payload
  })
  reducer.addCase(actions.setEditJobTitle, (draft, action) => {
    draft.jobTitle = action.payload
  })
  reducer.addCase(actions.setEditCompanyName, (draft, action) => {
    draft.companyName = action.payload
  })
  reducer.addCase(actions.setisClickedonDeleteJobPosting, (draft, action) => {
    draft.isClickedOnDeleteJobPosting = action.payload
  })
  reducer.addCase(actions.setChangeJobPostingLink, (draft, action) => {
    draft.changeJobPostingLink = action.payload
  })
  reducer.addCase(actions.setisClickedonEditJobDetails, (draft, action) => {
    draft.isClickedOnEditJobDetails = action.payload
  })
  reducer.addCase(actions.refreshAISuggestions, draft => {
    if (!draft.resume) return
    const visibleSkills = draft.optimizerSection.jobPostingAPIData.visibleSkills ?? []
    const currentSkills = (draft.resume.skills ?? [])
      .map(item => (item.skill ? item.skill.trim() : ''))
      .filter(Boolean)
    const recommendedSkills = draft.optimizerSection.jobPostingAPIData.recommendedSkills ?? []
    const visibleJobTitle = draft.optimizerSection.jobPostingAPIData.visibleJobTitle ?? ''
    const currentJobTitle = draft.resume.position
    const recommendedJobTitle = draft.optimizerSection.jobPostingAPIData.recommendedJobTitle ?? ''
    const openedAISuggestionsPopup = draft.openedAISuggestionsPopup

    if (openedAISuggestionsPopup !== 'skills') {
      // Add any missing skills to the visible skills
      if (recommendedSkills.length > 0) {
        const missingSkills = recommendedSkills.filter(
          skill => !visibleSkills.includes(skill) && !currentSkills.includes(skill),
        )
        if (missingSkills.length > 0) {
          draft.optimizerSection.jobPostingAPIData.visibleSkills = [
            ...visibleSkills,
            ...missingSkills,
          ]
        }
      }

      // Remove any visible skills that are a part of current skills - if there is no open popup
      if (currentSkills.length > 0) {
        const filteredVisibleSkills = visibleSkills.filter(skill => !currentSkills.includes(skill))
        draft.optimizerSection.jobPostingAPIData.visibleSkills = filteredVisibleSkills
      }
    }

    if (openedAISuggestionsPopup !== 'jobTitle') {
      // Add recommended title if it is not same as the current title
      if (currentJobTitle !== recommendedJobTitle && visibleJobTitle !== recommendedJobTitle) {
        draft.optimizerSection.jobPostingAPIData.visibleJobTitle = recommendedJobTitle
      }

      // Remove visible title if it is same as the current title - if there is no open popup
      if (visibleJobTitle === currentJobTitle) {
        draft.optimizerSection.jobPostingAPIData.visibleJobTitle = ''
      }
    }
  })
  reducer.addCase(actions.setResumeOptimizerManualInput, (draft, action) => {
    const updatedValue = { ...draft.optimizerSection.manualInput }
    const { name, value } = action.payload
    updatedValue[name] = value
    draft.optimizerSection.manualInput = updatedValue
  })
  reducer.addCase(actions.setSummaryStatus, (draft, action) => {
    draft.aiProfile.generateAiProfileStatus = action.payload
  })
  reducer.addCase(actions.resetSummaryStatus, draft => {
    const { generateAiProfileStatus: initialStatus } = initialAiProfileState
    draft.aiProfile.generateAiProfileStatus = initialStatus
  })
  reducer.addCase(actions.resetAIProfileSummaryFlow, draft => {
    draft.aiProfile = initialAiProfileState
  })
  reducer.addCase(actions.toggleAIProfileSummaryPaywall, draft => {
    draft.aiProfile.showPaywall = !draft.aiProfile.showPaywall
  })
  reducer.addCase(actions.setAiProfileSummaryText, (draft, action) => {
    draft.aiProfile.summary = action.payload
  })
  reducer.addCase(actions.setAIProfileActionType, (draft, action) => {
    draft.aiProfile.actionType = action.payload
  })
  reducer.addCase(actions.setAIProfileResultContentAnimation, (draft, action) => {
    draft.aiProfile.shouldAnimateResultPopupContent = action.payload
  })
  reducer.addCase(actions.setAIProfileEditorFieldAnimation, (draft, action) => {
    draft.aiProfile.shouldAnimateEditorFieldContent = action.payload
  })
  reducer.addCase(actions.resetResumeOptimizerManualInput, draft => {
    draft.optimizerSection.manualInput = { ...initialOptimizerManualInputData }
  })
  reducer.addCase(actions.setFromJobTracker, (draft, action) => {
    draft.isFromJobTracker = action.payload
  })
  reducer.addCase(actions.setOpenSavingInfoBanner, (draft, action) => {
    draft.isOpenSavingInfoBanner = action.payload
  })
  reducer.addCase(actions.setResumeValidationStatus, (draft, action) => {
    draft.prefillResumeSection.resumeValidationStatus = action.payload
  })
  reducer.addCase(actions.setResumeForPrefill, (draft, action) => {
    draft.prefillResumeSection.selectedResume = action.payload
  })
  reducer.addCase(actions.setIsSavingPrefilledResume, (draft, action) => {
    draft.prefillResumeSection.isSavingPrefilledResume = action.payload
  })
  reducer.addCase(actions.setResumeValidationError, (draft, action) => {
    draft.prefillResumeSection.validationError = action.payload.error
    draft.prefillResumeSection.errorSource = action.payload.errorSource
  })
  reducer.addCase(actions.setShowPrefillResumeModal, (draft, action) => {
    if (!draft.resume) return
    draft.resume.showPrefillResumeModal = action.payload
  })
  reducer.addCase(actions.setResumeUploadStatus, (draft, action) => {
    draft.prefillResumeSection.resumeUploadStatus = action.payload
  })
  reducer.addCase(actions.setPrefillResumeGenericError, (draft, action) => {
    draft.prefillResumeSection.error = action.payload.error
    draft.prefillResumeSection.errorSource = action.payload.errorSource
  })
  reducer.addCase(actions.setIsClickedOnAiCVBanner, (draft, action) => {
    draft.isClickedOnAiCVBanner = action.payload
  })
  reducer.addCase(actions.setIsEditJobDetailsFocused, (draft, action) => {
    draft.AICoverLetter.isEditJobDetailsFocused = action.payload
  })
  reducer.addCase(actions.setIsClickedOnAiCVEditJobDetails, (draft, action) => {
    draft.AICoverLetter.isClickedOnAiCVEditJobDetails = action.payload
  })
  reducer.addCase(actions.setIsClickedOnAiCVEditModalIcon, (draft, action) => {
    draft.AICoverLetter.isClickedOnAiCVEditModalIcon = action.payload
  })
  reducer.addCase(actions.setOpenAICVOptimizerModal, (draft, action) => {
    draft.AICoverLetter.isOpenAICVOptimizerModal = action.payload
  })
  reducer.addCase(actions.setGenerateAICoverLetterStatus, (draft, action) => {
    draft.AICoverLetter.generateAICoverLetterStatus = action.payload
  })
  reducer.addCase(actions.setAICVEditedJobTitle, (draft, action) => {
    draft.AICoverLetter.aICVEditedJobTitle = action.payload
  })
  reducer.addCase(actions.setAICVEditedCompanyName, (draft, action) => {
    draft.AICoverLetter.aICVEditedCompanyName = action.payload
  })
  reducer.addCase(actions.setShowUntailoreView, (draft, action) => {
    draft.AICoverLetter.isShowUntailoreView = action.payload
  })
  reducer.addCase(actions.setResumeJobDetails, (draft, action) => {
    if (!draft.resume) return
    draft.resume.jobTitle = action.payload.jobTitle
    draft.resume.employerName = action.payload.employerName
  })
  reducer.addCase(actions.setGenerateCLBannerEventTracked, (draft, action) => {
    draft.viewGenerateCLBannerEventTracked = action.payload
  })
  reducer.addCase(actions.setShowAIResumeModal, (draft, action) => {
    draft.aiResume.showAIResumeModal = action.payload
  })
  reducer.addCase(actions.setAIResumeFlow, (draft, action) => {
    draft.aiResume.aiResumeFlow = action.payload
  })
  reducer.addCase(actions.setHideLinkedInFlow, (draft, action) => {
    draft.aiResume.hideLinkedInFlow = action.payload
  })
  reducer.addCase(actions.setAIResumeLinkedInStep, (draft, action) => {
    draft.aiResume.linkedInData.currentStep = action.payload
  })
  reducer.addCase(actions.setLinkedInProfileURL, (draft, action) => {
    draft.aiResume.linkedInData.linkedInProfileURL = action.payload
  })
  reducer.addCase(actions.setLinkedInProfileFetchStatus, (draft, action) => {
    draft.aiResume.linkedInData.fetchingLinkedInProfile = action.payload
  })
  reducer.addCase(actions.setLinkedInProfileData, (draft, action) => {
    draft.aiResume.linkedInData.linkedInProfileData = action.payload
  })
  reducer.addCase(actions.setQuestionnaireStep, (draft, action) => {
    draft.aiResume.questionnaireData.step = action.payload
  })
  reducer.addCase(actions.updateQuestionnaireProgress, (draft, action) => {
    draft.aiResume.questionnaireData.progress = action.payload
  })
  reducer.addCase(actions.setQuestionnaireCardData, (draft, action) => {
    const { cardType, data } = action.payload

    for (const card of draft.aiResume.questionnaireData.userInput[cardType].cards) {
      if (card.id === data.id) {
        Object.assign(card, data)
      }
    }
  })
  reducer.addCase(actions.setConfirmDeleteQuestionnaireCardId, (draft, action) => {
    const { deleteCardId } = action.payload
    draft.aiResume.questionnaireData.userInput.workExperience.confirmDeleteCardId = deleteCardId
  })
  reducer.addCase(actions.deleteQuestionnaireCard, (draft, action) => {
    const { cardType, deleteCardId } = action.payload
    const cards = draft.aiResume.questionnaireData.userInput[cardType].cards
    const index = cards.findIndex(card => card.id === deleteCardId)
    cards.splice(index, 1)
  })
  reducer.addCase(actions.collapseQuestionnaireCards, (draft, action) => {
    const { cardType, excludeCardsIds = [] } = action.payload
    for (const card of draft.aiResume.questionnaireData.userInput[cardType].cards) {
      if (!excludeCardsIds.includes(card.id)) {
        card.isCollapsed = true
      }
    }
  })
  reducer.addCase(actions.pushEmptyEducationInQuestionnaire, draft => {
    draft.aiResume.questionnaireData.userInput.education.cards.push({
      id: uuidv4(),
      institute: '',
      degree: '',
      date: initialDateRangePickerValue,
      isCollapsed: false,
    })
  })
  reducer.addCase(actions.pushEmptyWorkExpInQuestionnaire, draft => {
    draft.aiResume.questionnaireData.userInput.workExperience.cards.push({
      id: uuidv4(),
      position: '',
      company: '',
      date: initialDateRangePickerValue,
      isCollapsed: false,
    })
  })
  reducer.addCase(actions.setQuestionnaireWorkExpCardErrors, (draft, action) => {
    draft.aiResume.questionnaireData.userInput.workExperience.cards[0].errors = action.payload
  })
  reducer.addCase(actions.setAIResumeDesiredPostion, (draft, action) => {
    draft.aiResume.questionnaireData.userInput.desiredPosition = action.payload
  })
  reducer.addCase(actions.resetQuestionnaireData, draft => {
    draft.aiResume.questionnaireData = initialQuestionnaireState
  })
  reducer.addCase(actions.updateCurrentSkills, (draft, action) => {
    draft.aiResume.questionnaireData.userInput.currentSkills = addIfNotExists(
      draft.aiResume.questionnaireData.userInput.currentSkills,
      action.payload,
    )
  })
  reducer.addCase(actions.removeFromCurrentSkills, (draft, action) => {
    const payload = action.payload
    const indexOfRemovedSuggestion = indexOfCaseInsensitive(
      draft.aiResume.questionnaireData.userInput.suggestedSkillsSource,
      payload,
    )
    if (indexOfRemovedSuggestion >= 0) {
      draft.aiResume.questionnaireData.userInput.suggestedSkills = [
        ...draft.aiResume.questionnaireData.userInput.suggestedSkills,
        draft.aiResume.questionnaireData.userInput.suggestedSkillsSource[indexOfRemovedSuggestion],
      ]
    }
    draft.aiResume.questionnaireData.userInput.currentSkills =
      draft.aiResume.questionnaireData.userInput.currentSkills.filter(item => item !== payload)
  })
  reducer.addCase(actions.setSuggestedSkills, (draft, action) => {
    const payload = action.payload
    draft.aiResume.questionnaireData.userInput.suggestedSkillsSource = payload
    draft.aiResume.questionnaireData.userInput.suggestedSkills = payload
  })
  reducer.addCase(actions.removeFromSuggestedSkills, (draft, action) => {
    draft.aiResume.questionnaireData.userInput.suggestedSkills =
      draft.aiResume.questionnaireData.userInput.suggestedSkills.filter(
        item => item.toLowerCase() !== action.payload.toLowerCase(),
      )
  })
  reducer.addCase(actions.resetAIResumeData, draft => {
    draft.aiResume = initialAIResumeState
  })
  reducer.addCase(actions.setGenerateResumeStatus, (draft, action) => {
    draft.aiResume.generateResumeStatus = action.payload
  })
  reducer.addCase(actions.setActiveAccomplishmentsTab, (draft, action) => {
    draft.aiResume.questionnaireData.activeAccomplishmentsTab = action.payload
  })
  reducer.addCase(actions.setActiveGoalsTab, (draft, action) => {
    draft.aiResume.questionnaireData.activeGoalsTab = action.payload
  })
  reducer.addCase(actions.setAccomplishmentsVoiceProcessingStatus, (draft, action) => {
    draft.aiResume.questionnaireData.accomplishmentsVoiceProcessingStatus = action.payload
  })
  reducer.addCase(actions.setGoalsVoiceProcessingStatus, (draft, action) => {
    draft.aiResume.questionnaireData.goalsVoiceProcessingStatus = action.payload
  })
  reducer.addCase(actions.setAccomplishmentsAudio, (draft, action) => {
    draft.aiResume.questionnaireData.accomplishmentsAudio = action.payload
  })
  reducer.addCase(actions.setGoalsAudio, (draft, action) => {
    draft.aiResume.questionnaireData.goalsAudio = action.payload
  })
  reducer.addCase(actions.setAccomplishmentsAudioDuration, (draft, action) => {
    draft.aiResume.questionnaireData.accomplishmentsAudioDuration = action.payload
  })
  reducer.addCase(actions.setGoalsAudioDuration, (draft, action) => {
    draft.aiResume.questionnaireData.goalsAudioDuration = action.payload
  })
  reducer.addCase(actions.setPastAccomplishmentsText, (draft, action) => {
    draft.aiResume.questionnaireData.userInput.pastAccomplishments = action.payload
  })
  reducer.addCase(actions.setFutureGoalsText, (draft, action) => {
    draft.aiResume.questionnaireData.userInput.futureGoals = action.payload
  })
  reducer.addCase(actions.setAccomplishmentsVoiceInputError, (draft, action) => {
    draft.aiResume.questionnaireData.accomplishmentsVoiceInputError = action.payload
  })
  reducer.addCase(actions.setGoalsVoiceInputError, (draft, action) => {
    draft.aiResume.questionnaireData.goalsVoiceInputError = action.payload
  })
  reducer.addCase(actions.setShowAIResumeWarningModal, (draft, action) => {
    const { showModal, type } = action.payload
    draft.aiResume.showWarningModal = showModal
    draft.aiResume.warningModalType = (showModal && type) || null
  })
  reducer.addCase(actions.setAIResumeWarningModalAction, (draft, action) => {
    draft.aiResume.warningModalAction = action.payload
  })
  reducer.addCase(actions.setShowUseExamplePopup, (draft, action) => {
    draft.useExample.showUseExampleModal = action.payload
  })
  reducer.addCase(actions.fetchingResumeExamples, (draft, action) => {
    draft.useExample.fetchingResumeExamples = action.payload
  })
  reducer.addCase(actions.setResumeExamples, (draft, action) => {
    draft.useExample.resumeExamples = action.payload
  })
  reducer.addCase(actions.setShowChangeTemplateTooltip, (draft, action) => {
    draft.useExample.showChangeTemplateTooltip = action.payload
  })
  reducer.addCase(actions.setTailorBannerEventTracked, (draft, action) => {
    draft.viewTailoringBannerEventTracked = action.payload
  })
  reducer.addCase(actions.setHighlightedElement, (draft, action) => {
    draft.highlightedElementData = action.payload
  })
  reducer.addCase(actions.closeHighlightedElement, draft => {
    draft.highlightedElementData = null
  })
  reducer.addCase(actions.setOpenedKeywordsModalType, (draft, action) => {
    draft.openedKeywordsModalType = action.payload
  })
  reducer.addCase(actions.setIsImproveResumePanelOpen, (draft, action) => {
    draft.isImproveResumePanelOpen = action.payload.status
  })
  reducer.addCase(actions.setIsHelpWithWritingOpen, (draft, action) => {
    draft.isHelpWithWritingOpen = action.payload.status
  })
  reducer.addCase(actions.setIsAIProfileSummaryResultOpen, (draft, action) => {
    draft.isAIProfileSummaryResultOpen = action.payload.status
  })
  reducer.addCase(actions.setAutoTailoringModalStep, (draft, action) => {
    draft.autoTailoring.openedModal = action.payload
  })
  reducer.addCase(actions.setAutoTailoringApproveModalStatus, (draft, action) => {
    draft.autoTailoring.isApproveModalOpen = action.payload
  })
  reducer.addCase(actions.setAutoTailoringFetchStatus, (draft, action) => {
    draft.autoTailoring.fetchStatus = action.payload
  })
  reducer.addCase(actions.setAutoTailoredResume, (draft, action) => {
    draft.autoTailoring.autoTailoredResume = action.payload
  })
  reducer.addCase(actions.setOriginalResume, (draft, action) => {
    draft.originalResume = action.payload
  })
  reducer.addCase(actions.approveResumeSuccess, draft => {
    if (!draft.resume) return
    draft.resume.draft = false
  })
  reducer.addCase(actions.setJobPostingDataForAutoTailoring, (draft, action) => {
    draft.autoTailoring.jobPostingData = action.payload
    draft.autoTailoring.keywordsMap = action.payload.keywords.reduce((acc, keyword) => {
      return { ...acc, [keyword]: true }
    }, {} as Record<string, boolean>)
  })
  reducer.addCase(actions.toggleKeywordForAutoTailoring, (draft, action) => {
    draft.autoTailoring.keywordsMap[action.payload] =
      !draft.autoTailoring.keywordsMap[action.payload]
  })
  reducer.addCase(actions.setAllKeywordForAutoTailoring, (draft, action) => {
    for (const keyword in draft.autoTailoring.keywordsMap) {
      draft.autoTailoring.keywordsMap[keyword] = action.payload
    }
  })
  reducer.addCase(actions.setApproveAutoTailoredResumeStatus, (draft, action) => {
    draft.autoTailoring.autoTailoredResumeApproveStatus = action.payload
  })
  reducer.addCase(actions.setIsProfileSummaryWithTailoredResumeModalOpen, (draft, action) => {
    draft.aiProfile.isProfileSummaryWithTailoredResumeModalOpen = action.payload
  })
  reducer.addCase(actions.setIsIncludeJobPostingToProfileSummary, (draft, action) => {
    draft.aiProfile.includeJobPosting = action.payload
  })
  reducer.addCase(actions.setResumeEventTrackedSections, (draft, action) => {
    draft.resumeEventTrackedSections = action.payload
  })
})
