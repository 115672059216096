import styled, { css } from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'
import { Media } from 'builder/styles/media'
import ArrowLeft from './assets/arrow-left.svg'
import ArrowRight from './assets/arrow-right.svg'

export const Wrapper = styled.div<{ marqueeEffect?: boolean; showShadow?: boolean }>`
  position: relative;
  user-select: none;

  &:before,
  &:after {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    width: calc(50vw);
    background: linear-gradient(
      90deg,
      ${hexToRgba(Colors.White, 0)} 0%,
      ${Colors.White} 128px,
      ${Colors.White} 100%
    );
  }

  &:before {
    right: ${({ showShadow }) => `calc(100% ${showShadow ? '- 50px' : '+ 32px'})`};
    transform: scaleX(-1);
  }

  &:after {
    left: ${({ showShadow }) => `calc(100% ${showShadow ? '- 50px' : '+ 32px'})`};
  }

  & .swiper-container {
    position: relative;
    overflow: visible;
  }

  & .swiper-wrapper {
    display: flex;
    position: relative;
    transition-property: transform;

    ${props =>
      props?.marqueeEffect &&
      css`
        transition-timing-function: linear;
      `}
  }

  & .swiper-slide {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
  }

  & .swiper-slide:not(:last-child) {
    margin-right: 16px;

    ${Media.Tablet`
      margin-right: 8px;
    `}
  }

  // Classes required for swiper container
  :root {
    --swiper-navigation-size: 44px;
  }
  & .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: 42%;
    width: calc(var(--swiper-navigation-size) / 44 * 27);
    height: var(--swiper-navigation-size);
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    display: none;
  }
  & .swiper-button-prev:after,
  .swiper-button-next:after {
    width: 40px;
    height: 40px;
    background: ${Colors.White};
    border-radius: 50%;
    display: flex;
    align-items: flex-end;
    border: 1px solid ${Colors.Neutral10};
    justify-content: center;
    box-shadow: 0px 0px 1px rgba(15, 56, 113, 0.32), 0px 0px 0px rgba(15, 56, 113, 0.12),
      0px 0px 1px rgba(15, 56, 113, 0.08);
  }
  & .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    left: -20px;
    right: auto;
  }
  & .swiper-button-prev:after,
  .swiper-container-rtl .swiper-button-next:after {
    content: url('${ArrowLeft}');
  }
  & .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    right: -20px;
    left: auto;
  }
  & .swiper-button-next:after,
  .swiper-container-rtl .swiper-button-prev:after {
    content: url('${ArrowRight}');
  }
  & .swiper-button-lock {
    display: none;
  }
`
