import PropTypes from 'prop-types'
import { formatDocumentPreviewUrl } from 'builder/utils/formatDocumentPreviewUrl'
import DocumentImagePreview from 'builder/components/DocumentImagePreview'

import {
  Container,
  Divider,
  PreviewContainer,
  Preview,
  PreviewDivider,
  DropdownSelect,
} from './styles'

const DocumentSelect = ({ options, documentId, label, onChange }) => {
  const findOptionById = id => options.find(option => option.id === id)
  const selectedDocument = findOptionById(documentId)
  const { id } = selectedDocument || {}
  const imageSrc = documentId ? formatDocumentPreviewUrl(selectedDocument) : null

  return (
    <Container>
      <DropdownSelect label={label} selected={id || null} onSelect={onChange} options={options} />
      <Divider />
      <PreviewContainer>
        <Preview>
          <DocumentImagePreview src={imageSrc} />
        </Preview>
        <PreviewDivider />
      </PreviewContainer>
    </Container>
  )
}

DocumentSelect.propTypes = {
  options: PropTypes.array,
  documentId: PropTypes.number,
  label: PropTypes.string,
  onChange: PropTypes.func,
}

export default DocumentSelect
