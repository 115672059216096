import styled, { keyframes } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Icon, { Icon24 } from 'builder/components/Icon'
import Media from 'builder/styles/media'

const moveUp = keyframes`
  from {
    transform: translateY(70%);
  }
  to {
    transform: translateY(0%);
  }
`

export const Container = styled.div`
  width: 544px;
  border-radius: 16px;
  background: ${Colors.White};
  border: 8px;
  padding: 32px;
  animation: ${moveUp} 0.5s ease-out forwards;

  ${Media.Phone`
    width: 343px;
    padding: 20px
  `};
`

export const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const FlexBox = styled.div`
  display: flex;
`

export const Title = styled.div`
  margin-right: 16px;
  flex-grow: 1;
  ${FontWeights.DemiBold};
  ${Typography.Subhead}
  ${Media.Phone`
    ${Typography.S}
  `};
`

export const CloseIcon = styled(Icon24.Add)`
  background: ${Colors.Neutral10};
  fill: ${Colors.Neutral70};
  border-radius: 50%;
  height: 28px;
  width: 28px;
  cursor: pointer;
  transform: rotate(225deg);

  &:hover {
    background: ${Colors.Blue50};
    fill: ${Colors.White};
  }

  ${Media.Phone`
    height: 23px;
    width: 35px;
  `}
`

export const Content = styled.div`
  color: ${Colors.Neutral50};
  /* Desktop and Tablet/Body • Regular */
  ${Typography.Body}
  ${FontWeights.Regular};
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;
  width: 100%;
  justify-content: space-between;
  ${Media.Phone`
    margin-top: 20px;
  `}
`

export const Revert = styled(Icon.Revert)`
  color: ${Colors.Blue50};
  height: 20px;
  width: 20px;
`

export const TryAnotherLink = styled.div`
  ${Typography.Caption}
  ${FontWeights.DemiBold};
  color: ${Colors.Blue50};
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 9px;
  margin-right: 10px;
`

export const LeftAlign = styled.div`
  text-align: left;
`
