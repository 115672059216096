import styled from 'styled-components'
import Colors from 'builder/styles/colors'

export const Progress = styled.div`
  height: 2px;
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
`
export const ProgressInner = styled.div`
  height: 100%;
  width: 100%;
  background: ${Colors.Blue50};
  transition: transform 0.35s ease;
  transform-origin: 0 50%;
`
