import { useEffect, useState } from 'react'
import { DateRangePickerChangeValue, DateRangePickerProps } from '../types'
import {
  convertToExternal,
  convertToInternal,
  isPresent,
  convertToFieldValue,
  isDateHidden,
} from '../utils'
import { useDateField } from './useDateField'

export const useDateRangePicker = (props: DateRangePickerProps) => {
  const { isUntilToday, onChange = () => {} } = props

  const {
    value: startValue,
    setValue: setStartValue,
    month: startMonth,
    setMonth: setStartMonth,
    year: startYear,
    setYear: setStartYear,
    isPickerOpen: isStartPickerOpen,
    setIsPickerOpen: setIsStartPickerOpen,
    onYearChange: onStartYearChange,
    onInputValueChange: onStartInputValueChange,
    onDateSelect: onStartDateSelect,
    onInputBlur: onStartInputBlur,
  } = useDateField({ props, dateField: 'dateFrom', isMonthHiddenField: 'isMonthFromHidden' })

  const {
    value: endValue,
    setValue: setEndValue,
    month: endMonth,
    setMonth: setEndMonth,
    year: endYear,
    setYear: setEndYear,
    isPickerOpen: isEndPickerOpen,
    setIsPickerOpen: setIsEndPickerOpen,
    onYearChange: onEndYearChange,
    onInputValueChange: onEndInputValueChange,
    onDateSelect: onEndDateSelect,
    onInputBlur: onEndInputBlur,
  } = useDateField({
    props,
    dateField: 'dateUntil',
    isMonthHiddenField: 'isMonthUntilHidden',
    canSetDateUntilPresent: true,
  })

  const [maxMonth, setMaxMonth] = useState<number | null>(null)
  const [maxYear, setMaxYear] = useState(Infinity)

  const [nowMonth, setNowMonth] = useState<number | null>(null)
  const [nowYear, setNowYear] = useState(0)

  const isPresentEndValue = isPresent(endValue)

  const maxStartMonth = (endValue && !isPresentEndValue && endMonth) || maxMonth
  const maxStartYear = (endValue && !isPresentEndValue && endYear) || maxYear

  const minEndMonth = (startValue && startMonth) || null
  const minEndYear = (startValue && startYear) || 0

  const maxEndMonth = maxMonth
  const maxEndYear = maxYear

  const isStartDateHidden = isDateHidden(startValue)
  const isEndDateHidden = isDateHidden(endValue)

  const showIsPresent =
    !startValue ||
    startYear < nowYear ||
    !!(startYear === nowYear && (!startMonth || (startMonth && nowMonth && startMonth <= nowMonth)))

  useEffect(() => {
    const [, month, year] = convertToInternal(new Date().toISOString())

    setNowMonth(month)
    setNowYear(year)

    if (isPresentEndValue || isUntilToday) {
      setMaxMonth(month)
      setMaxYear(year)
    } else {
      setMaxMonth(null)
      setMaxYear(Infinity)
    }
  }, [isPresentEndValue, isUntilToday])

  useEffect(() => {
    if (!isStartPickerOpen || startValue) {
      return
    }

    if (maxStartYear >= nowYear) {
      setStartYear(nowYear)
    } else {
      setStartYear(maxStartYear)
    }
    // this should happen only on closing of picker panel
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStartPickerOpen])

  useEffect(() => {
    if (!isEndPickerOpen || endValue) {
      return
    }

    if (minEndYear <= nowYear) {
      setEndYear(nowYear)
    } else {
      setEndYear(minEndYear)
    }
    // this should happen only on closing of picker panel
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEndPickerOpen])

  const handlePresentToggle = (isDateUntilPresent: boolean) => {
    const changes: DateRangePickerChangeValue = {
      isDateUntilPresent,
    }

    const [, month, year] = convertToInternal(new Date().toISOString())

    if (isDateUntilPresent) {
      setEndMonth(null)
      setEndYear(year)
      changes.dateUntil = null
    } else {
      setEndMonth(month)
      setEndYear(year)
      changes.dateUntil = convertToExternal(month, year)
    }

    setEndValue(
      convertToFieldValue(month, year, {
        isDatePresent: isDateUntilPresent,
      }),
    )

    onChange(changes)
  }

  return {
    startValue,
    startMonth,
    startYear,
    endValue,
    endMonth,
    endYear,
    maxStartMonth,
    maxStartYear,
    minEndMonth,
    minEndYear,
    maxEndMonth,
    maxEndYear,
    showIsPresent,
    nowMonth,
    nowYear,
    setEndMonth,
    setEndYear,
    setStartMonth,
    setStartYear,
    setStartValue,
    setEndValue,
    isPresentEndValue,
    handlePresentToggle,
    isStartDateHidden,
    isEndDateHidden,
    isStartPickerOpen,
    isEndPickerOpen,
    setIsStartPickerOpen,
    setIsEndPickerOpen,
    onStartYearChange,
    onStartInputValueChange,
    onStartDateSelect,
    onStartInputBlur,
    onEndYearChange,
    onEndInputValueChange,
    onEndDateSelect,
    onEndInputBlur,
  }
}
