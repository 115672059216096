import { memo, Fragment } from 'react'
import { selectors } from 'builder/modules/careerCoaching'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import Header from './components/Header'
import { HEADER_TEXT_DESKTOP, HEADER_SUBTEXT_DESKTOP } from './constants'
import { Container } from './styles'
import NoCoachAvailable from './components/NoCoachAvailable'
import Footer from './components/Footer'

interface SessionTabsProps {
  isLoaded: boolean
  isFailed: boolean
}

const SessionTabs = ({ isLoaded, isFailed }: SessionTabsProps): JSX.Element => {
  const coaches = useTypedSelector(selectors.coaches)

  if ((isLoaded || isFailed) && !coaches.length) {
    return (
      <Container>
        <NoCoachAvailable />
      </Container>
    )
  }

  return (
    <Fragment>
      {isLoaded && (
        <Container>
          <Header heading={HEADER_TEXT_DESKTOP} description={HEADER_SUBTEXT_DESKTOP} />
          <Footer />
        </Container>
      )}
    </Fragment>
  )
}
export default memo(SessionTabs)
