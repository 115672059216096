import styled, { keyframes } from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Icon24 } from 'builder/components/Icon'

export const Container = styled.div`
  display: none;
  width: 100%;
  padding: 24px 0 4px;
  justify-content: center;
  align-items: center;
  background-color: white;
  flex-direction: column;

  ${Media.Tablet`
    display: flex;
  `}

  ${Media.Phone`
    display: none;
  `}
`

export const TabSwitcher = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;
`

export const TabInSwitcher = styled.p`
  color: ${Colors.Blue50};
  ${FontWeights.Medium};
  ${Typography.Subhead};
`

const fadeIn = keyframes`
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`

export const DropdownContainer = styled.div`
  box-shadow: 0px 0px 1px 0px rgba(15, 56, 113, 0.32), 0px 6px 8px -2px rgba(15, 56, 113, 0.02),
    0px 16px 64px -8px rgba(15, 56, 113, 0.18);
  position: absolute;
  left: -8px;
  top: calc(100% + 12px);
  width: 352px;
  padding: 4px;
  border-radius: 16px;
  background-color: ${Colors.White};
  z-index: 2;
  display: flex;
  flex-direction: column;

  transform-origin: 100% 0;
  animation: ${fadeIn} 0.25s;
  animation-fill-mode: forwards;
`

export const TabContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
`

export const Tab = styled.p<{ isActive: boolean }>`
  color: ${props => (props.isActive ? Colors.Blue50 : Colors.Neutral80)};
  ${FontWeights.Medium};
  ${Typography.Body};
  cursor: ${props => (props.isActive ? 'default' : 'pointer')};
`

export const Tick = styled(Icon24.TickLarge)`
  color: ${Colors.Blue50};
  margin-left: auto;
`

export const ArrowDown = styled(Icon24.Chevron)`
  margin-top: 1px;
  color: ${Colors.Blue50};
  transform: rotate(90deg);
`
