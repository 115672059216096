import { FC } from 'react'
import BackgroundPreview from 'builder/components/AvatarEditorModal/BackgroundPreview'
import { BackgroundColor as BackgroundColorType } from 'builder/components/AvatarEditorModal/types'

import { Button, CustomColor, AddIcon, ActiveIcon } from './styles'

interface ColorSelectButtonProps {
  active: boolean
  size: number
  color: BackgroundColorType
  onClick: () => void
}

export const ColorSelectButton: FC<ColorSelectButtonProps> = ({ color, active, size, onClick }) => {
  const isCustomSelected = active && color
  const isActiveIconVisible = Boolean(isCustomSelected && size > 24)

  return (
    <Button active={active} size={size} onClick={onClick}>
      {isCustomSelected && (
        <CustomColor>
          <BackgroundPreview size={size} background={{ color, image: null }} />
        </CustomColor>
      )}
      <AddIcon data-visible={!isCustomSelected} />
      <ActiveIcon data-visible={isActiveIconVisible} />
    </Button>
  )
}
