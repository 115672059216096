import Colors from 'builder/styles/colors'
import { TailorYourResume } from '../TailorYourResume'
import { CoverLetter } from '../CoverLetter'
import { AceJobInterview } from '../AceJobInterview'
import { ExploreYourNextSteps } from '../ExploreYourNextSteps'
import { FindTrackJobs } from '../FindTrackJobs'
import { ResumeScore } from '../ResumeScore'
import { useBannerData } from '../../hooks/useBannerData'
import * as Styled from './styles'

import { defaultOptimizerScore } from './constant'

const MainBanner = ({ selectedIndex }: { selectedIndex: number }) => {
  const {
    currentIndex,
    filteredData,
    isHovered,
    isPhone,
    resume,
    latestDocument,
    letter,
    hasLetter,
    userPhotoUrl,
    handleClick,
    handleMouseEnter,
    handleMouseLeave,
    onBannerClick,
  } = useBannerData({ selectedIndex })

  const {
    img = '',
    title = '',
    text = '',
    tone10 = '',
    tone15 = '',
    tone20 = '',
    tone50 = '',
    tone80 = '',
    tone100 = '',
    tag = '',
    tagImg = '',
    tagHoverImg = '',
    flagTag = '',
    bannerName = '',
  } = filteredData[currentIndex]

  // Render Banner images
  const renderBannerImages = () => {
    switch (bannerName) {
      case 'optimize_your_resume':
        return (
          <ResumeScore
            document={latestDocument}
            optimizerScore={latestDocument || defaultOptimizerScore}
          />
        )
      case 'tailor_your_resume':
        return <TailorYourResume document={resume} />
      case 'create_a_cover_letter':
        return <CoverLetter document={letter} hasLetter={hasLetter} />
      case 'ace_job_interview':
        return <AceJobInterview userPhotoUrl={userPhotoUrl} />
      case 'explore_your_next_steps':
        return <ExploreYourNextSteps userPhotoUrl={userPhotoUrl} />
      case 'find_track_jobs':
        return <FindTrackJobs userPhotoUrl={userPhotoUrl} />
      case 'career_assessment':
        return (
          <Styled.ImageContainer bannerName={bannerName}>
            <Styled.PlayImage src={img} maxHeight="200px" />
          </Styled.ImageContainer>
        )
      default:
        return (
          <Styled.ImageContainer bannerName={bannerName}>
            <Styled.PlayImage src={img} />
          </Styled.ImageContainer>
        )
    }
  }

  const renderTag = (tagText?: string, isAIPowerd?: boolean, isAdviceBanner?: boolean) => {
    return (
      <Styled.TagContainer
        isAdviceBanner={isAdviceBanner}
        isAIPowerd={isAIPowerd}
        color={isHovered ? tone20 : `${tone20}7f`}
        textColor={isHovered ? tone100 : tone80}
        isResumedistribution={bannerName === 'resume_distribution'}
      >
        {tagImg && <Styled.StarImage src={isHovered ? tagHoverImg : tagImg} />}
        <Styled.TagLine
          isResumedistribution={bannerName === 'resume_distribution'}
          textColor={isHovered ? tone80 : tone100}
        >
          {tagText}
        </Styled.TagLine>
      </Styled.TagContainer>
    )
  }

  // Render the Tag based on Banner type
  const renderBannerTag = (tag: string | undefined) => {
    if (tag) {
      switch (tag) {
        case 'Recommended Step':
          return renderTag(tag, false)
        case 'AI-powered':
          return renderTag(tag, true)
        case 'Expert Content':
          return renderTag(tag, false)
        default:
          break
      }
    }
  }

  return (
    <>
      <Styled.Container
        isJobSearch={currentIndex === 1}
        isOptimizedResume={currentIndex === 2}
        isExploreCareers={currentIndex === 3}
        background={isHovered ? tone15 : tone10}
        onMouseOver={handleMouseEnter}
        onMouseOut={handleMouseLeave}
        onClick={onBannerClick}
        isRioJobIcon={selectedIndex === 0}
      >
        {renderBannerImages()}

        {!isPhone && (
          <Styled.Content>
            {flagTag && (
              <Styled.FlagTag isExploreCareers={bannerName === 'explore_careers'}>
                {flagTag}
              </Styled.FlagTag>
            )}
            <Styled.Title color={tone80}>{title}</Styled.Title>
            <Styled.Text color={tone80}>{text}</Styled.Text>
          </Styled.Content>
        )}

        <Styled.ActionContainer>
          {renderBannerTag(tag)}
          {isPhone && (
            <>
              {flagTag && (
                <Styled.FlagTag isExploreCareers={bannerName === 'explore_careers'}>
                  {flagTag}
                </Styled.FlagTag>
              )}
              <Styled.Title color={tone80}>{title}</Styled.Title>
            </>
          )}

          <Styled.NextBannerButton background={isHovered ? tone50 : tone20} onClick={handleClick}>
            <Styled.RightArrow color={isHovered ? Colors.Neutral10 : tone50} />
          </Styled.NextBannerButton>
        </Styled.ActionContainer>
      </Styled.Container>
    </>
  )
}

export default MainBanner
