import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { trackingInfo } from 'builder/modules/careerCoaching/constants'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/careerCoaching'
import actionPlanURL from './assets/img/action_plan.svg'
import activeBanner from './assets/img/active_banner.svg'
import {
  Container,
  Content,
  ActionImageContainer,
  ActionImage,
  ActionDetails,
  Header,
  SubHeader,
  ActionButton,
  RightArrow,
  Footer,
  MailLink,
  Description,
} from './styles'

const CareerPurchaseActionBanner = () => {
  const navigate = useNavigate()
  const coachingSessions = useTypedSelector(selectors.coachingSessions)
  const isActive = coachingSessions?.response.previous.length > 0
  const { isPhone } = useMediaQueries()

  const handleClick = () => {
    trackInternalEvent('click_career_plan_card', {
      ...trackingInfo,
      career_plan_name: 'post_purchase',
      source: 'coaching',
    })

    isActive && navigate('/career-plans/custom-plan')
  }

  const supportUrl = <MailLink href="mailto:coaching@career.io">coaching@career.io</MailLink>

  return (
    <Container>
      <Content {...{ isActive }} onClick={handleClick}>
        <ActionImageContainer>
          <ActionImage src={isActive ? activeBanner : actionPlanURL} alt="news-letter" />
        </ActionImageContainer>
        <ActionDetails>
          <Header {...{ isActive }}>
            {isActive ? 'View your action plan' : 'Your action plan will show here.'}
          </Header>
          {!isPhone && (
            <SubHeader {...{ isActive }}>
              A personalized plan from your coach to guide you through your next steps and provide
              support in reaching your goals.
            </SubHeader>
          )}
        </ActionDetails>
        {isActive && (
          <ActionButton onClick={handleClick} data-testid="action-content">
            <RightArrow />
          </ActionButton>
        )}
      </Content>

      <Footer>
        {isPhone ? (
          <>
            <Description>If you have questions about this coaching service,</Description>
            <Description>
              reach out to our support via email
              {supportUrl}
            </Description>
          </>
        ) : (
          <Description>
            If you have questions about this coaching service, reach out to our support via email
            {supportUrl}
          </Description>
        )}
      </Footer>
    </Container>
  )
}

export default CareerPurchaseActionBanner
