import { SectionContainer, SectionContent } from 'builder/components/ProfileSummary/styles'
import RichTextArea from 'builder/components/RichTextArea/RichTextArea'
import { SectionHeader, SectionHint } from 'builder/components/Section'
import SectionTitle from 'builder/components/SectionTitle'
import { useUpdateSimpleShokomuField } from 'builder/hooks/useUpdateSimpleShokumuField'
import { useShokumuTranslations } from '../../useShokumuTranslations'

export const JobSummary = (): JSX.Element => {
  const { fieldValue, updateField } = useUpdateSimpleShokomuField('profile')
  const { i18n } = useShokumuTranslations()
  const title = i18n('work_summary.title')
  const hint = i18n('work_summary.hint')
  const tip = i18n('work_summary.tip')

  return (
    <SectionContainer>
      <SectionHeader>
        <SectionTitle title={title} />
        <SectionHint>{hint}</SectionHint>
      </SectionHeader>
      <SectionContent>
        <RichTextArea
          boldButtonDisable
          italicButtonDisable
          linkButtonDisable
          value={fieldValue as string}
          onChange={updateField}
        />
      </SectionContent>
      <SectionHint>{tip}</SectionHint>
    </SectionContainer>
  )
}
