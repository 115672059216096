import * as React from 'react'
import { ThemeProvider } from 'styled-components'
import { Align, BaseTooltipProps, HorizontalPosition, VerticalPosition } from './types'
import { Container, TooltipBody } from './styles'
import { TooltipPointer } from './components/TooltipPointer'
import { transformMultiline } from './utils'

type PlacementProps =
  | {
      position?: HorizontalPosition
      align?: 'center'
    }
  | {
      position?: VerticalPosition
      align?: Align
    }

export type TooltipProps = {
  // shifts tooltip if it has VerticalPosition and align === 'left' | 'right'
  offset?: number
} & BaseTooltipProps &
  PlacementProps &
  React.HTMLAttributes<HTMLDivElement>

export const Tooltip: React.FC<TooltipProps> = ({
  value = '',
  multiline = false,
  position = 'top',
  align = 'center',
  width,
  zIndex = 10,
  textAlign = 'left',
  children,
  offset,
  ...rest
}) => {
  const body = transformMultiline(value)

  return (
    <ThemeProvider theme={{ position, align, multiline, offset }}>
      <Container {...rest}>
        <TooltipBody style={{ textAlign, zIndex, width }}>
          {body}
          <TooltipPointer />
        </TooltipBody>

        {children}
      </Container>
    </ThemeProvider>
  )
}

export default React.memo(Tooltip)
