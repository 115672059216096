import { useMemo } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

export const useIsDashboardOpen = () => {
  const location = useLocation()

  const isDashboardOpen = useMemo(() => {
    return (
      matchPath('/', location.pathname) ||
      matchPath('/resumes', location.pathname) ||
      matchPath('/tr-documents', location.pathname) ||
      matchPath('/cover-letters', location.pathname) ||
      matchPath('/auto-tailored-resumes', location.pathname)
    )
  }, [location.pathname])

  return isDashboardOpen
}
