import { Resume } from 'packages/types'
import { simpleFieldRule, sectionRule } from './resumeScoreRules'
import { ResumeFieldSuggest } from './resumeScoreRules/types'

const SUGGESTIONS_SIZE = 6

export const getScoreSuggestions = (resume: Resume | null) => {
  let totalPoints = 10
  let totalSuggestions: ResumeFieldSuggest[] = []

  if (!resume) {
    return { points: totalPoints, suggestions: [] }
  }

  for (const field of simpleFieldRule.getFields()) {
    const fieldScore = simpleFieldRule.getScore(resume, field)
    totalPoints += fieldScore.points
    if (fieldScore.suggest) {
      totalSuggestions.push(fieldScore.suggest)
    }
  }

  for (const field of sectionRule.getFields()) {
    const fieldScore = sectionRule.getScore(resume, field)
    totalPoints += fieldScore.points
    if (fieldScore.suggest) {
      totalSuggestions.push(fieldScore.suggest)
    }
  }

  return {
    /**
     * @deprecated Use resume.resumeScore if possible
     */
    points: totalPoints,
    suggestions: totalSuggestions.sort((a, b) => b.points - a.points).slice(0, SUGGESTIONS_SIZE),
  }
}
