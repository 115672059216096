import { useCallback } from 'react'
import findIndex from 'lodash/findIndex'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/resumeEditor'
import useWizard from './useWizard'

const useWizardSteps = () => {
  const { currentStep, setCurrentStep, updateStepState } = useWizard()
  const steps = useTypedSelector(selectors.wizardSteps)

  const currentStepIndex = findIndex(steps, { id: currentStep.id })
  const isFirstStep = currentStepIndex === 0
  const isLastStep = steps.length === currentStepIndex + 1

  const nextStep = useCallback(() => {
    setCurrentStep(steps[currentStepIndex + 1].id)
    updateStepState({ id: steps[currentStepIndex].id, done: true })
  }, [currentStepIndex, steps, setCurrentStep, updateStepState])

  const prevStep = useCallback(() => {
    setCurrentStep(steps[currentStepIndex - 1].id)
  }, [currentStepIndex, steps, setCurrentStep])

  return {
    steps,
    currentStep,
    currentStepIndex,
    isLastStep,
    isFirstStep,
    setCurrentStep,
    nextStep,
    prevStep,
  }
}

export default useWizardSteps
