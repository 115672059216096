import { AsyncAutosuggest, createSuggestionsApiFetcher } from 'builder/components/AsyncAutosuggest'
import EditorField from 'builder/components/EditorField'
import { TextField } from 'builder/components/TextField'
import { useI18n } from 'builder/hooks/useI18n'
import { actions as editorActions } from 'builder/modules/resumeEditor'
import * as React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

export function City(props) {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const { city, countryName, locale } = props

  const handleSimpleFieldUpdate = React.useCallback(
    event => {
      const { name, value } = event.target
      dispatch(editorActions.updateSimpleField({ name, value, debounce: true }))
    },
    [dispatch],
  )

  return (
    <>
      {/* City */}
      <EditorField>
        <AsyncAutosuggest
          highlightedQuery
          fetchItems={createSuggestionsApiFetcher('city', {
            locale,
            country: countryName || '',
          })}
          name="city"
          value={city || ''}
          onChange={handleSimpleFieldUpdate}
        >
          {inputProps => <TextField {...inputProps} label={i18n.t('builder.resume_editor.city')} />}
        </AsyncAutosuggest>
      </EditorField>
    </>
  )
}
City.propTypes = {
  city: PropTypes.string,
  countryName: PropTypes.string,
  locale: PropTypes.string,
  label: PropTypes.string,
}
