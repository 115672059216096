import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import { fadeIn } from 'builder/styles/keyframes'
import Media from 'builder/styles/media'
import Shadows from 'builder/styles/shadows'

interface ToggleButtonOptions {
  direction?: 'left' | 'right'
  size?: number
}

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 1120px;
`

export const SwiperContainer = styled.div`
  position: relative;
  overflow: hidden;
`

export const SliderContent = styled.div`
  width: 100%;
  max-width: 1120px;
  animation: ${fadeIn} 0.3s ease;
  animation-fill-mode: forwards;
  position: relative;
  overflow: hidden;

  .swiper-container {
    position: relative;
    overflow: visible;
  }

  .swiper-wrapper {
    display: flex;
    position: relative;
    transition-property: transform;
    padding-bottom: 10px;
  }

  .swiper-slide-active,
  .swiper-slide-next,
  .swiper-slide-prev {
    opacity: 1;
  }
`

export const ToggleButton = styled.button<ToggleButtonOptions>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  width: ${props => props.size || 48}px;
  height: ${props => props.size || 48}px;
  border: none;
  border-radius: 50%;
  background-color: ${Colors.Neutral5};
  transition: background-color 0.2s ease;

  ${Shadows.lightWithBorder.low};

  &:hover {
    background-color: ${Colors.White};
    ${Shadows.lightWithBorder.mid};
  }

  ${props =>
    props.direction === 'left' &&
    css`
      transform: rotate(180deg);
    `}

  position: absolute;
  z-index: 2;
  top: calc(42%);

  ${Media.Phone`
      display: none;
    `}
`

export const ToggleLeftButton = styled(ToggleButton).attrs({
  direction: 'right',
})`
  right: -22px;
`

export const ToggleRightButton = styled(ToggleButton).attrs({
  direction: 'left',
})`
  left: -22px;
`

export const NavigationButtons = styled.div`
  width: 100%;

  ${Media.Tablet`
    display: none;
  `}
`
