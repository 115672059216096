import { useAutoApply } from 'builder/views/AutoApply/hooks/useAutoApply'
import Icon24 from 'builder/components/Icon'
import Tooltip from 'builder/components/Tooltip'
import { useI18n } from 'builder/hooks/useI18n'
import { useJobSearch } from '../useJobSearch'
import { Container, FilterButton, ClearButton, ChildrenContainer } from './styles'
import PostedDate from './PostedDate'
import Distance from './Distance'
import { DEFAULT_FILTERS } from './constants'

interface Props {
  children: React.ReactNode
}

export const JobFilters = ({ children }: Props) => {
  const { isAutoApplyFeatureEnabled } = useAutoApply()
  const { i18n } = useI18n()
  const { filters, setFilters } = useJobSearch()

  const clearFilters = () => {
    setFilters(DEFAULT_FILTERS)
  }

  const handleAutoApplyFilterToggle = () => {
    setFilters(prevFilters => ({
      ...prevFilters,
      onlyAutoApplyJobs: !filters.onlyAutoApplyJobs,
    }))
  }

  return (
    <Container>
      <ClearButton onClick={clearFilters}>
        <Icon24.Close />
      </ClearButton>
      <Tooltip
        textAlign="center"
        position="bottom"
        value={i18n.t('builder.job_search.filters.tooltips.remote')}
      >
        <FilterButton
          selected={filters.onlyRemoteJobs}
          onClick={() =>
            setFilters(prevFilters => ({
              ...prevFilters,
              onlyRemoteJobs: !filters.onlyRemoteJobs,
            }))
          }
        >
          {i18n.t('builder.job_search.filters.titles.remote')}
        </FilterButton>
      </Tooltip>

      {isAutoApplyFeatureEnabled && (
        <Tooltip
          position="bottom"
          multiline={true}
          value={i18n.t('builder.job_search.filters.tooltips.auto_apply')}
        >
          <FilterButton selected={filters.onlyAutoApplyJobs} onClick={handleAutoApplyFilterToggle}>
            {i18n.t('builder.job_search.filters.titles.auto_apply')}
          </FilterButton>
        </Tooltip>
      )}
      <PostedDate
        selected={filters.postedWithinDays}
        onSelect={(postedWithinDays: number) =>
          setFilters(prevFilters => ({ ...prevFilters, postedWithinDays }))
        }
      />
      <Distance
        selected={filters.radius}
        onSelect={(radius: number) => setFilters(prevFilters => ({ ...prevFilters, radius }))}
      />
      <ChildrenContainer>{children}</ChildrenContainer>
    </Container>
  )
}
