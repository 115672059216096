import timeURL from './img/time.svg'
import topicsURL from './img/topics.svg'
import actionPlansURL from './img/actionPlans.svg'

export const ABOUT_SESSIONS = [
  {
    id: 1,
    label: '90% of clients meet their goals if they have a coach',
    description:
      'Confirmed the <a target="_blank" href="https://www.forbes.com/sites/forbescoachescouncil/2018/08/14/three-steps-to-overcoming-resistance/?sh=741076335eae">ATD’s study</a>. Our coaches have a proven track record of helping individuals achieve their career goals.',
    url: timeURL,
  },
  {
    id: 2,
    label: '95% Placement Rate',
    description:
      'Confirmed Careerminds’ data. Most of our clients land new jobs after going through our coaching series.',
    url: topicsURL,
  },
  {
    id: 3,
    label: 'Flexible and Accessible',
    description: 'We offer flexible coaching with 50-minute sessions available 18/7.',
    url: actionPlansURL,
  },
]
