import styled from 'styled-components'
import { TransitionProps } from './types'

export const Wrapper = styled.div<TransitionProps>`
  opacity: 0;
  transform: translateX(10px);
  transition: opacity ${props => props.durationMs}ms ease-out,
    transform
      ${props => (props.durationMs >= 500 ? props.durationMs - 100 : props.durationMs * 0.8)}ms
      ease-out;

  ${props => {
    return {
      entering: 'opacity: 1; transform: translateX(0);',
      entered: 'opacity: 1; transform: none',
      exiting: 'opacity: 0; translateX(10px);',
      exited: null,
      unmounted: null,
    }[props?.transitionState || 'entered']
  }};
`
