import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import debounce from 'lodash/debounce'
import trim from 'lodash/trim'
import {
  DraftBlockType,
  DraftEditorCommand,
  EditorState,
  getDefaultKeyBinding,
  RichUtils,
} from 'draft-js'
import PluginEditor from '@draft-js-plugins/editor/lib/Editor'
import { stateFromHTML } from 'draft-js-import-html'
import { stateToHTML } from 'draft-js-export-html'
import { trackInternalEvent } from '@rio/tracking'
import { matchPath, useLocation } from 'react-router-dom'
import nanoid from 'nanoid'
import { useDispatch } from 'react-redux'
import { useSpellchecker } from 'builder/hooks/useSpellchecker'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { handleTextWithBullets, handleTextWithHeaders } from 'builder/utils/richTextUtils'
import { actions as resumeActions } from 'builder/modules/resumeEditor'
import { IncorrectWord, RichTextAreaActions, StyleTypes } from './types'
import { Props } from './RichTextArea'

const SUGGESTIONS_CHECK_KEY = 'SUGGESTIONS_WERE_SHOWN_ON_FOCUS'

const preProcessContent = (content: string) => handleTextWithBullets(handleTextWithHeaders(content))

export const useRichTextAreaActions = (props: Props): RichTextAreaActions => {
  const {
    jobTitle = '',
    suggestionConfig,
    value = '',
    onChange = () => {},
    locale = 'en',
    autoFocus,
    isAnimating,
    onAnimationEnd,
  } = props

  const dispatch = useDispatch()
  const { spellchecker, supportedLocales } = useSpellchecker()
  const supportsLocale = useCallback(
    (locale: string) => {
      return supportedLocales.includes(locale) && !['hu', 'ro', 'gr'].includes(locale)
    },
    [supportedLocales],
  )
  const [isSuggestionsOpen, setIsSuggestionsOpen] = useState(false)
  const [isSuggestionVariantsOpen, setIsSuggestionVariantsOpen] = useState(false)

  const [isFocused, setIsFocused] = useState(false)
  const [isHintsOpened, setIsHintsOpened] = useState(false)
  const [suggestionQuery, setSuggestionQuery] = useState('')
  const [textLength, setTextLength] = useState(0)
  const [checkedText, setCheckedText] = useState('')
  const [isSpellCheckerEnabled, setIsSpellCheckerEnabled] = useState(supportsLocale(locale))
  const [isSpellCheckerPending, setIsSpellCheckerPending] = useState(false)
  const [isSpellingChecked, setIsSpellingChecked] = useState(false)
  const [isForceRenderComplete, setIsForceRenderComplete] = useState(true)
  const contentState = stateFromHTML(preProcessContent(value))
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState))
  const { pathname } = useLocation()
  const isCoverLetterEditor = matchPath('cover-letters/:id/edit', pathname)
  const incorrectWordsFromStorage = localStorage.getItem('SPELL_CHECKER_INCORRECT_WORDS')
  const editorsWithSpellCheckFromStorage = localStorage.getItem('EDITORS_WITH_SPELL_CHECK')
  const incorrectWords: IncorrectWord[] = useMemo(
    () => (incorrectWordsFromStorage ? JSON.parse(incorrectWordsFromStorage) : []),
    [incorrectWordsFromStorage],
  )
  const editorsWithSpellCheck: string[] = useMemo(
    () => (editorsWithSpellCheckFromStorage ? JSON.parse(editorsWithSpellCheckFromStorage) : []),
    [editorsWithSpellCheckFromStorage],
  )

  const editorRef = useRef<PluginEditor>(null)
  const editorId = useRef(nanoid())
  const animationPlaceholderRef = useRef<HTMLTextAreaElement>(null)

  const resume = useTypedSelector(state => state.resumeEditor.resume)
  const isHelpWithWritingOpen = useTypedSelector(state => state.resumeEditor.isHelpWithWritingOpen)
  const isTailorOverviewEditor = resume && resume.autoTailored && resume.draft
  const toggleAiProfileSummary = () => {
    dispatch(resumeActions.setIsHelpWithWritingOpen({ status: !isHelpWithWritingOpen }))
  }

  /**
   * Sets value if provided or toggle otherwise
   */
  const toggleAiProfileSummaryResult = useCallback(
    (state: { isOpen?: boolean }) => {
      dispatch(
        resumeActions.setIsAIProfileSummaryResultOpen({
          status: state ? !!state.isOpen : !state,
        }),
      )
    },
    [dispatch],
  )

  const toggleSuggestions = (state: { isOpen?: boolean; trigger?: string }) => {
    const { isOpen, trigger } = state
    const newState = isOpen || !isSuggestionsOpen

    setIsSuggestionsOpen(newState)

    if (newState) {
      setIsHintsOpened(false)
      dispatch(resumeActions.setIsHelpWithWritingOpen({ status: false }))
      trackInternalEvent('open_pre_written_phrases_dialog', {
        section: suggestionConfig?.scope,
        trigger,
      })
    }
  }

  const toggleSuggestionVariants = (newState?: { isOpen?: boolean }) => {
    setIsSuggestionVariantsOpen(state => {
      return newState && 'isOpen' in newState ? !!newState.isOpen : !state
    })
  }

  const onChangeSuggestionQuery = (text: string) => setSuggestionQuery(text)

  const handleOpenHints = () => {
    toggleSuggestions({ isOpen: false })
    setIsHintsOpened(true)

    trackInternalEvent('click_resume_section_expert_tips_button', {
      section: suggestionConfig?.scope,
      resume_id: resume?.id,
    })
  }

  const handleCloseHints = () => setIsHintsOpened(false)

  // Needed to re-apply decorators
  const forceRender = useCallback(() => {
    const contentState = editorState.getCurrentContent()
    const newEditorStateInstance = EditorState.createWithContent(
      contentState,
      editorState.getDecorator(),
    )
    const copyOfEditorState = EditorState.set(newEditorStateInstance, {
      selection: editorState.getSelection(),
      undoStack: editorState.getUndoStack(),
      redoStack: editorState.getRedoStack(),
      lastChangeType: editorState.getLastChangeType(),
    })
    setEditorState(copyOfEditorState)
  }, [editorState])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkSpelling = useCallback(
    debounce((text: string) => {
      // we should check it one more time because something could changed during the debaunce time
      if (!trim(text).length || text === checkedText) {
        setIsSpellCheckerPending(false)
        return
      }
      setIsForceRenderComplete(false)

      return spellchecker
        .checkSpelling(text, locale)
        .then((result: IncorrectWord[]) => {
          if (result.length > 0) {
            const updatedWords = [...incorrectWords]
            // Only add new suggestions to the local storage
            result.forEach(obj => {
              const existingIndex = updatedWords.findIndex(item => item.word === obj.word)
              if (existingIndex !== -1) {
                // If the word already exists, update it
                updatedWords[existingIndex] = obj
              } else {
                // If the word doesn't exist, add it to the list
                updatedWords.push(obj)
              }
            })
            localStorage.setItem('SPELL_CHECKER_INCORRECT_WORDS', JSON.stringify(updatedWords))
          }
          setCheckedText(text)
          setIsSpellCheckerPending(false)
        })
        .catch(() => {
          setIsSpellCheckerEnabled(false)
          setIsSpellCheckerPending(false)
        })
    }, 2000),
    [],
  )

  const handleCheckSpelling = useCallback(
    (text: string) => {
      if (!isSpellCheckerEnabled || !isFocused) return

      setIsSpellCheckerPending(!(!trim(text).length || text === checkedText))
      setIsSpellingChecked(true)

      checkSpelling(text)
    },
    [checkSpelling, checkedText, isFocused, isSpellCheckerEnabled],
  )

  const checkAndUpdateStateFocus = (prevEditorState: EditorState, nextEditorState: EditorState) => {
    /** This logic handles focus changes in a controlled way
     * It was required because we encountered an issue with cursor jumping to beginning
     * when editor was focused or on pressing backspace. It is only required
     * when typing for the first time in the editor.
     */
    const prevSelection = prevEditorState.getSelection()
    const nextSelection = nextEditorState.getSelection()
    /** This condition is checking if the selection (cursor) has moved
     * from the beginning to the second position,
     * both the starting and ending points are the same,
     * and neither the previous nor the next selection (from editor states) has focus.
     * If satisfies, it modifies the next selection to have focus
     * and prevents the cursor from jumping to the start */
    if (
      prevSelection.getAnchorKey() === nextSelection.getAnchorKey() &&
      prevSelection.getAnchorOffset() === 0 &&
      nextSelection.getAnchorOffset() === 1 &&
      prevSelection.getFocusKey() === nextSelection.getFocusKey() &&
      prevSelection.getFocusOffset() === 0 &&
      nextSelection.getFocusOffset() === 1 &&
      !prevSelection.getHasFocus() &&
      !nextSelection.getHasFocus()
    ) {
      const fixedSelection = nextSelection.merge({ hasFocus: true })
      return EditorState.forceSelection(nextEditorState, fixedSelection)
    }
    return nextEditorState
  }

  const handleChange = useCallback(
    (editorStateProp: EditorState) => {
      if (!onChange) return

      const onChangeHandler = onChange as (value: string) => void

      const currentContentState = editorStateProp.getCurrentContent()
      const oldContentState = editorState.getCurrentContent()

      const newValue = currentContentState.hasText() ? stateToHTML(currentContentState) : ''
      const oldValue = oldContentState.hasText() ? stateToHTML(oldContentState) : ''

      const plainText = currentContentState.getPlainText()
      const updatedState = checkAndUpdateStateFocus(editorState, editorStateProp)
      setEditorState(updatedState)
      setTextLength(plainText.length)

      if (oldValue !== newValue) {
        onChangeHandler(newValue)

        if (isSpellCheckerEnabled) {
          handleCheckSpelling(plainText)
        }
      }
    },
    [editorState, handleCheckSpelling, isSpellCheckerEnabled, onChange],
  )

  const handleToggleInline = (inlineStyle: StyleTypes) => {
    handleChange(RichUtils.toggleInlineStyle(editorState, inlineStyle))
  }

  const handleToggleBlock = (blockType: DraftBlockType) => {
    handleChange(RichUtils.toggleBlockType(editorState, blockType))
  }

  const focusEditor = () => {
    editorRef.current?.focus()
  }

  const updateEditorsWithSpellCheck = useCallback(
    (isSpellCheckerEnabled: boolean) => {
      let updatedEditorsWithSpellCheck = [...editorsWithSpellCheck]
      if (isSpellCheckerEnabled && !updatedEditorsWithSpellCheck.includes(editorId.current)) {
        updatedEditorsWithSpellCheck.push(editorId.current)
      } else if (!isSpellCheckerEnabled) {
        updatedEditorsWithSpellCheck = updatedEditorsWithSpellCheck.filter(
          id => id !== editorId.current,
        )
      }
      localStorage.setItem('EDITORS_WITH_SPELL_CHECK', JSON.stringify(updatedEditorsWithSpellCheck))
    },
    [editorsWithSpellCheck],
  )

  const handleToggleSpellChecker = () => {
    if (isSpellCheckerPending) return

    setIsSpellCheckerEnabled(!isSpellCheckerEnabled)
  }

  const shouldHidePlaceholder = useMemo(() => {
    const contentState = editorState.getCurrentContent()
    return contentState.hasText() || contentState.getBlockMap().first().getType() !== 'unstyled'
  }, [editorState])

  const handleFocus = () => {
    setIsFocused(true)

    if (isCoverLetterEditor || isTailorOverviewEditor) return

    const isTunerTipsEnabled = window.location.search.includes('tuner_tips')

    const storedSuggestions = localStorage.getItem(SUGGESTIONS_CHECK_KEY)
    // Additional check to avoid JSON parsing error
    const parsedSuggestionsState = storedSuggestions ? JSON.parse(storedSuggestions) : null
    const suggestionsState = Array.isArray(parsedSuggestionsState) ? parsedSuggestionsState : []
    const suggestionsWereShown = suggestionsState.includes(suggestionConfig?.scope)

    if (!suggestionsWereShown && !isTunerTipsEnabled) {
      if (suggestionConfig?.scope === 'profile') {
        toggleAiProfileSummary()
      } else {
        toggleSuggestions({ isOpen: true, trigger: 'focus_field' })
      }
      suggestionsState.push(suggestionConfig?.scope)
      const updatedSuggestionState = suggestionsState.filter(Boolean)
      if (updatedSuggestionState.length > 0) {
        localStorage.setItem(SUGGESTIONS_CHECK_KEY, JSON.stringify(updatedSuggestionState))
      }
    }
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  const handleChangeSuggestionQuery = (text: string) => {
    setSuggestionQuery(text)
  }

  const handleTab = (e: React.KeyboardEvent<Element>) => {
    const maxDepth = 1
    const newEditorState = RichUtils.onTab(e, editorState, maxDepth)
    if (newEditorState !== editorState) {
      handleChange(newEditorState)
    }
  }

  const handleKeyBinding = (event: React.KeyboardEvent<Element>) => {
    if (event.key === 'Tab') {
      handleTab(event)
    }
    return getDefaultKeyBinding(event)
  }

  const handleKeyCommand = (command: DraftEditorCommand, editorState: EditorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      handleChange(newState)
      return 'handled'
    }
    return 'not-handled'
  }

  const animateContent = useCallback(
    (ref: RefObject<HTMLTextAreaElement>, content: string, onAnimationComplete: () => void) => {
      if (!content) {
        return () => {}
      }

      const value = content?.replace(/<\/p><p><br><\/p><p>/g, '\n\n').replace(/<[^>]*>/g, '') ?? ''
      const l = value.length
      let i = 0

      requestAnimationFrame(() => {
        if (ref.current) ref.current.value = ''
      })

      const interval = setInterval(() => {
        requestAnimationFrame(() => {
          if (ref.current) {
            const { scrollHeight, scrollTop, clientHeight } = ref.current

            ref.current.value = value.substring(0, i + 1)

            if (scrollHeight - scrollTop > clientHeight) {
              ref.current.scrollTop = ref.current.scrollHeight
            }
          }

          if (i + 1 >= l) {
            onAnimationComplete()
            clearInterval(interval)
          }

          i++
        })
      }, 10)

      return () => clearInterval(interval)
    },
    [],
  )

  useEffect(() => {
    if (autoFocus) {
      focusEditor()
    }
  }, [autoFocus])

  useEffect(() => {
    handleChangeSuggestionQuery('')
  }, [jobTitle])

  useEffect(() => {
    updateEditorsWithSpellCheck(isSpellCheckerEnabled)
    setIsForceRenderComplete(false)
  }, [incorrectWords, isSpellCheckerEnabled, updateEditorsWithSpellCheck])

  useEffect(() => {
    if (!isForceRenderComplete) {
      forceRender()
      setIsForceRenderComplete(true)
    }
  }, [isForceRenderComplete, forceRender])

  useEffect(() => {
    setIsSpellCheckerEnabled(supportsLocale(locale))
  }, [locale, supportsLocale])

  useEffect(() => {
    if (isSpellCheckerEnabled && isFocused) {
      const contentState = editorState.getCurrentContent()
      const plainText = contentState.getPlainText()

      // Conditionally trigger the spell check on focus
      const triggerSpellCheck = !!plainText && (isSpellCheckerPending || plainText !== checkedText)
      if (triggerSpellCheck) {
        handleCheckSpelling(plainText)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused, isSpellCheckerEnabled])

  useEffect(() => {
    if (isAnimating && value) {
      const terminateAnimation = animateContent(animationPlaceholderRef, value, () => {
        if (onAnimationEnd) onAnimationEnd()
      })

      return () => terminateAnimation()
    }
  }, [animateContent, isAnimating, value, onAnimationEnd])

  return {
    animationPlaceholderRef,
    editorId,
    editorRef,
    editorState,
    isFocused,
    isHintsOpened,
    isSpellCheckerEnabled,
    isSpellCheckerPending,
    isSpellingChecked,
    suggestionQuery,
    textLength,
    shouldHidePlaceholder,

    isSuggestionsOpen,
    focusEditor,
    onChangeSuggestionQuery,
    handleChange,
    handleOpenHints,
    handleCloseHints,
    handleToggleInline,
    handleToggleBlock,
    handleToggleSpellChecker,
    handleFocus,
    handleBlur,
    handleKeyBinding,
    handleKeyCommand,
    supportsLocale,
    toggleSuggestions,
    toggleAiProfileSummary,
    toggleAiProfileSummaryResult,
    setEditorState,
    toggleSuggestionVariants,
    isSuggestionVariantsOpen,
  }
}
