import * as Styled from './styles'

interface Props {
  name: string | undefined
  email: string | undefined
  onContinue: () => void
  createNew: () => void
  photoImage: string | undefined
  inputEmail: string
}

const AlreadyLoggedIn = ({ name, email, onContinue, createNew, photoImage, inputEmail }: Props) => {
  return (
    <Styled.Container>
      <Styled.UserDetailsContainer>
        <Styled.ImageContainer src={photoImage} />
        <Styled.Title>{name}</Styled.Title>
        <Styled.Email>{email}</Styled.Email>
        <Styled.ButtonContainer>
          {email !== inputEmail && (
            <Styled.Button isCreateNew={true} onClick={createNew}>
              Create New Account
            </Styled.Button>
          )}
          <Styled.Button isCreateNew={false} onClick={onContinue}>
            Continue as {name}
          </Styled.Button>
        </Styled.ButtonContainer>
      </Styled.UserDetailsContainer>
    </Styled.Container>
  )
}

export default AlreadyLoggedIn
