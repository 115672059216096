import styled from 'styled-components'
import Colors from 'builder/styles/colors'

export const CoachLists = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
`
export const CoachListsItem = styled.li`
  img {
    border-radius: 100%;
    width: 100%;
    border-radius: 100%;
    border: 4px solid ${Colors.Indigo10};
    background-color: ${Colors.Indigo10};
  }

  position: relative;
  z-index: 3;
  margin-left: -40px;
  cursor: pointer;
  &:nth-child(1) {
    margin-left: 0;
  }
  &:nth-child(2) {
    z-index: 2;
  }
  &:nth-child(3) {
    z-index: 1;
  }
`
