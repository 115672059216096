import styled from 'styled-components'
import { EditorContent as Editor } from '@tiptap/react'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import { AIGeneratedDetails as DefaultAIGeneratedDetails } from 'builder/components/RichTextArea/styles'

export const Group = styled.div`
  display: block;
  width: 100%;
  position: relative;
`

export const Label = styled.label`
  display: flex;
  ${Typography.Caption};
  color: ${Colors.Neutral50};
  margin-bottom: 6px;
  justify-content: space-between;
`

export const LabelText = styled.span``

export const LabelBadges = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;

  &:empty {
    display: none;
  }
`

export const TextArea = styled.div`
  position: relative;
  border-radius: 3px;
  caret-color: ${Colors.Blue50};
  background-color: ${Colors.Neutral10};
`

export const EditorContent = styled(Editor)`
  position: relative;
  overflow: hidden;

  /* user-select: none breaks the editor in ios safari  */
  user-select: auto;

  .ProseMirror {
    margin: 0;
    padding: 8px 16px 16px;

    min-height: 200px;
    max-height: 420px;

    overflow: auto;
    cursor: text;
  }

  .ProseMirror:focus {
    outline: none;
  }

  /* Placeholder (at the top) */
  .ProseMirror p.is-editor-empty:first-child::before {
    color: ${Colors.Neutral50};
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  .ProseMirror ol,
  .ProseMirror ul {
    padding: 4px 0 4px 24px;
    margin: 0;
  }

  .ProseMirror a {
    color: ${Colors.Blue50};
  }
`

export const AIGeneratedDetails = styled(DefaultAIGeneratedDetails)`
  max-height: 420px;
`
