import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import Button, { ButtonSize } from 'builder/components/Button'
import { Icon20 } from 'builder/components/Icon'
import { actions as uiActions } from 'builder/modules/ui'
import ModalOverlay from 'builder/components/ModalOverlay'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { i18n as I18n } from 'builder/utils/i18n'
import * as Styles from './styles'
import paywallMain from './assets/img/paywallMain.svg'

type PaywallProps = {
  limitPaywallOpen: boolean
  setLimitPaywallOpen: (val: boolean) => void
  from: string
}

const TRANSLATION = 'builder.limit_paywall_modal'

const LimitPaywallModal: React.FC<PaywallProps> = ({
  limitPaywallOpen,
  setLimitPaywallOpen,
  from,
}) => {
  const dispatch = useDispatch()
  const { isPhone } = useMediaQueries()

  const features = [
    { id: 1, text: I18n.t(`${TRANSLATION}.features.feature_1`) },
    { id: 2, text: I18n.t(`${TRANSLATION}.features.feature_2`) },
    { id: 3, text: I18n.t(`${TRANSLATION}.features.feature_3`) },
  ]

  const handleUpgrade = () => {
    setLimitPaywallOpen(false)
    dispatch(uiActions.openPaywall())
    trackInternalEvent('click_upgrade_paywall', {
      label: from,
    })
  }

  const handleSkip = () => {
    trackInternalEvent('skip_paywall', {
      label: from,
    })
    setLimitPaywallOpen(false)
  }

  const handleClose = () => {
    trackInternalEvent('close_paywall', {
      label: from,
    })
    setLimitPaywallOpen(false)
  }

  useEffect(() => {
    trackInternalEvent('view_paywall', {
      label: from,
    })
  }, [from])

  if (!limitPaywallOpen) {
    return null
  }

  return (
    <ModalOverlay overlayFadeDuration={150} contentSlideDuration={150}>
      <Styles.Container>
        <Styles.Close onClick={handleClose}>
          <Icon20.Close />
        </Styles.Close>
        <Styles.Visual src={paywallMain} />
        <Styles.DescriptionSection>
          <Styles.Description>
            <Styles.Header>
              <Styles.PremiumBadge>
                <Styles.PremiumIcon />
                <Styles.PremiumText>{I18n.t(`${TRANSLATION}.text`)}</Styles.PremiumText>
              </Styles.PremiumBadge>
              <Styles.Title>{I18n.t(`${TRANSLATION}.title`)}</Styles.Title>
            </Styles.Header>
            <Styles.SubTitle>{I18n.t(`${TRANSLATION}.subtitle`)}</Styles.SubTitle>
          </Styles.Description>
          <Styles.UnlockFeaturesSection>
            <Styles.UnlockText>{I18n.t(`${TRANSLATION}.unlock`)}</Styles.UnlockText>
            <Styles.FeaturesContainer>
              {features.map(feature => (
                <Styles.FeatureItem key={feature.id}>
                  <Styles.GreenTick />
                  <Styles.Feature>{feature.text}</Styles.Feature>
                </Styles.FeatureItem>
              ))}
            </Styles.FeaturesContainer>
          </Styles.UnlockFeaturesSection>
          <Styles.ButtonContainer>
            <Button
              size={!isPhone ? ButtonSize.default : ButtonSize.small}
              theme="ghost"
              onClick={handleSkip}
            >
              {I18n.t(`${TRANSLATION}.skip_button`)}
            </Button>
            <Button size={!isPhone ? ButtonSize.default : ButtonSize.small} onClick={handleUpgrade}>
              {I18n.t(`${TRANSLATION}.upgrade_button`)}
            </Button>
          </Styles.ButtonContainer>
        </Styles.DescriptionSection>
      </Styles.Container>
    </ModalOverlay>
  )
}

export default LimitPaywallModal
