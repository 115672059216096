import type { CoverLetter, ExpertDoc } from '@rio/types'
import { FetchStatuses } from 'builder/modules/constants'
import { BriefResume } from '../panel'

export enum ResumeCritiqueStatuses {
  pending = 'pending',
  finished = 'finished',
  failed = 'failed',
}

export interface ResumeCritiqueBase {
  id: number
  resumeId: number
  status: ResumeCritiqueStatuses
  iframeUrl?: string
  reviewer: {
    name?: string | null
    photoUrl?: string
  }
  createdAt: string
  reviewedAt?: string | null
  popupViewedAt: string | null
}

export type ResumeCritique = ResumeCritiqueBase

interface IncompleteResumeCritique extends ResumeCritiqueBase {
  status: Exclude<ResumeCritiqueStatuses, ResumeCritiqueStatuses.finished>
}

interface FinishedResumeCritique extends ResumeCritiqueBase {
  status: ResumeCritiqueStatuses.finished
  iframeUrl: string
  popupViewedAt: string | null
  reviewer: {
    name: string
    photoUrl: string
  }
  reviewedAt: string
}

export type ResumeReviews = IncompleteResumeCritique | FinishedResumeCritique

export type BriefLetter = CoverLetter

export interface PanelStore {
  checkSum: string
  documents: Array<BriefResume | BriefLetter | ExpertDoc>
  documentsStatus: FetchStatuses
  critiqueStatus: ResumeCritiqueStatuses
  resumeId: number
  triggeredCritiqueStatus: ResumeCritique
  critiques: ResumeReviews[]
  isTopResumeUser: boolean
  critiquePageCount: boolean
}

export interface ReviewResumeID {
  resumeId: number | null
}
