import { useMemo } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { FeatureCard } from 'builder/hooks/useFeatureCards'

export const useCurrentPageFeature = (features: FeatureCard[]) => {
  const location = useLocation()

  return useMemo(() => {
    return features.find(feature => {
      const paths = feature.paths || [feature.to]
      return paths.some(path => path && matchPath(path, location.pathname))
    })
  }, [features, location.pathname])
}
