import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { Icon20 } from 'builder/components/Icon'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  width: 352px;
  gap: 24px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 12px;
  background-color: ${Colors.White};
`

export const Content = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Heading = styled.h1`
  ${Typography.S};
  ${FontWeights.DemiBold};
`

export const Circle = styled.div`
  width: 28px;
  height: 28px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  background-color: ${Colors.Neutral10};
  cursor: pointer;
`

export const CloseIcon = styled(Icon20.Close)`
  color: ${Colors.Neutral50};

  &:hover {
    color: ${Colors.Blue50};
  }
`

export const Text = styled.p`
  color: ${Colors.Neutral50};
`

export const Buttons = styled.div`
  gap: 8px;
  display: flex;
  justify-content: flex-end;
`
