import { Fragment } from 'react'

import {
  Container,
  Content,
  TopContent,
  Header,
  Option,
  Options,
  SubLabel,
  OptionContent,
  OptionContentBox,
  EntryFocusCheckbox,
} from './styles'
import { INITIALCAREERLEVELS } from './constants'
interface CareerEntryProps {
  onEntryFocusChange: (name: string) => void
  services: string[]
}

const EntryFocus = ({ onEntryFocusChange, services }: CareerEntryProps) => {
  return (
    <Container>
      <Content>
        <TopContent>
          <Header>What would you like to focus on?</Header>
        </TopContent>
      </Content>
      <Options>
        {INITIALCAREERLEVELS.map(careerLevel => (
          <Option
            htmlFor={careerLevel.name}
            key={careerLevel.name}
            checked={services?.includes(careerLevel.name)}
          >
            <OptionContent>
              <EntryFocusCheckbox
                onChange={() => {
                  onEntryFocusChange(careerLevel.name)
                }}
                id={careerLevel.name}
                checked={services?.includes(careerLevel.name)}
              />
              <OptionContentBox>
                <Fragment>{careerLevel.name}</Fragment>
                <SubLabel>{careerLevel.description}</SubLabel>
              </OptionContentBox>
            </OptionContent>
          </Option>
        ))}
      </Options>
    </Container>
  )
}

export default EntryFocus
