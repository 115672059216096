import styled, { css, keyframes } from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${Media.Desktop`
    justify-content: unset;
    margin-left: 0;
  `};

  ${Media.Tablet`
    width: 372px;
    margin-left: 0;
  `};

  ${Media.Phone`
    width: 100%;
  `};
`

const BackgroundAnimation = keyframes`
    0% { background-position: 0% 51%;  }
   50% { background-position: 100% 50%;}
  100% { background-position: 0% 51%;  }
`

export const BackgroundContainer = styled.div<{
  $animationOn: boolean
  $warnning: boolean
}>`
  ${({ $animationOn }) =>
    $animationOn &&
    css`
      animation: ${BackgroundAnimation} 1s ease infinite;
    `}
  background: ${({ $warnning }) =>
    $warnning ? `#CF760D` : `linear-gradient(139deg, #59aef9, #59aef9, ${Colors.Indigo40})`};
  background-size: 600% 600%;
  width: 100%;
  height: 100%;
  border-radius: 24px;
  display: flex;
  align-items: center;
`

const animations = {
  circle1: keyframes`
   0% {r: 6}
  14% {r: 7}
  28% {r: 8}
  42% {r: 6}
  56% {r: 8}
  70% {r: 7}
  86% {r: 5}
`,
  circle2: keyframes`
   0% {r: 6}
  14% {r: 10}
  28% {r: 14}
  42% {r: 12}
  56% {r: 10}
  70% {r: 12}
  86% {r: 10}
`,
  circle3: keyframes`
   0% {r:6}
  14% {r:16}
  28% {r:20}
  42% {r:22}
  56% {r:16}
  70% {r:20}
  86% {r:16}
`,
}

export const Circle = styled.circle<{
  $animationOn: boolean
  $animationName: keyof typeof animations
}>`
  stroke: white;
  stroke-width: 2;
  stroke-miterlimit: 10;
  ${props =>
    props.$animationOn &&
    css`
      animation: ${animations[props.$animationName]} ease-in-out 1s infinite;
    `}
`

export const IconContainer = styled.div`
  height: 48px;
  width: 48px;
  color: ${Colors.White};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StatusText = styled.span`
  ${Typography.Body};
  ${FontWeights.Medium}
  color: ${Colors.White};
`

export const RotationAnimation = keyframes`
  0%   { transform: rotate(0deg);   }
  100% { transform: rotate(360deg);  }
`

export const RevokePermissionHintText = styled.div`
  ${Typography.Caption};
  ${FontWeights.Regular}
  color: ${Colors.Neutral90};
  margin-top: 8px;
  ${Media.Tablet`
  text-align: center;

  `};
`

export const HowToDoThat = styled.div`
  ${Typography.Caption};
  ${FontWeights.Regular}
  color: ${Colors.Blue50};
  margin-top: 8px;
  cursor: pointer;
  z-index: 1;
  ${Media.Tablet`
  text-align: center;

  `};
`

export const Spinner = styled.div`
  animation: ${RotationAnimation} ease-in-out 1s infinite;
`
