import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useI18n } from 'builder/hooks/useI18n'

import { Step } from '../Step'
import {
  Container,
  EllipseOne,
  EllipseTwo,
  EllipsisContainer,
  Image,
  ImageContainer,
  Shadow,
} from './styles'
import BOARD from './assets/board.svg'
import BOARD_DESKTOP from './assets/board-desktop.svg'

interface IProps {
  isCompleted: boolean
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  jobsNeeded: number
}

export const SaveJobs = ({ isCompleted, isOpen, setIsOpen, jobsNeeded }: IProps) => {
  const { isTablet, isPhone } = useMediaQueries()
  const [showHover, setShowHover] = useState(false)
  const navigate = useNavigate()
  const { i18n } = useI18n()

  return (
    <Step
      customHeight={72}
      title={i18n.t('builder.dashboard_v2.steps.save_jobs.title', {
        jobsNeeded: isCompleted ? 5 : jobsNeeded,
      })}
      subtitle={i18n.t('builder.dashboard_v2.steps.save_jobs.subtitle')}
      isCompleted={isCompleted}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      handleOnClick={() => {
        navigate('/job-tracking')
      }}
      buttonText={i18n.t('builder.dashboard_v2.steps.save_jobs.button_text')}
      useSecondaryTheme
      analyticsEventsParam="job_tracker"
      Images={
        <>
          <Container>
            <ImageContainer>
              <Image src={isTablet || isPhone ? BOARD : BOARD_DESKTOP} />
              <Shadow />
            </ImageContainer>

            <EllipsisContainer>
              <EllipseOne className={showHover ? 'hover' : ''} />
              <EllipseTwo className={showHover ? 'hover' : ''} />
            </EllipsisContainer>
          </Container>
        </>
      }
      setShowHover={setShowHover}
    />
  )
}
