import * as Styled from './styles'
const cioLogo = require('../assets/CIO_Logo.svg')

export const PoweredByBanner = () => {
  return (
    <Styled.HeaderAside>
      <Styled.SubText>Powered by</Styled.SubText>
      <Styled.PoweredBy src={cioLogo} />
      <Styled.LogoName>Career.io</Styled.LogoName>
    </Styled.HeaderAside>
  )
}
