import styled from 'styled-components'
import { Media } from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import { Logo as LogoComponent } from 'builder/components/Logo'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

export const Navbar = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 1;
  padding: 0 32px;
  height: 80px;

  ${Media.Tablet`
    height: 72px;
    padding: 0 20px;
  `};

  ${Media.Phone`
    height: 64px;
  `};
`

export const Logo = styled(LogoComponent)`
  cursor: pointer;
`

export const CloseButton = styled.button`
  display: flex;
  color: ${Colors.Neutral40};
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;

  &:hover,
  &:focus-visible {
    color: ${Colors.Blue50};
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`
