import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import { Button } from 'builder/components/Button'

export const Container = styled.div``

export const CoachDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;

  ${Media.Phone`
    flex-direction:column;
    gap: 16px;
    align-items: flex-start;
  `};
`

export const CoachImage = styled.div``

export const CoachAvatar = styled.img``

export const CoachCaption = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  ${Media.Phone`
    gap: 4px;
  `};
`

export const Heading = styled.div`
  ${Typography.S};
  ${FontWeights.Medium};

  ${Media.Phone`
    ${Typography.Subhead};
  `};
`

export const Caption = styled.div`
  color: ${Colors.Neutral50};
`

export const ActionDetails = styled.div`
  ${Media.Phone`
    width: 100%;
  `};
`

export const StyledButton = styled(Button)`
  ${Media.Phone`
    width: 100%;
  `};
`
