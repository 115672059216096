import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'

export const DefaultTag = styled.div`
  padding: 4px 12px;
  color: ${Colors.Green50};
  border-radius: 24px;
  background: ${Colors.White};
  ${Typography.Caption}
`

export const LoadingTag = styled(DefaultTag)`
  border: 1px solid ${Colors.Neutral15};
  background: transparent;
  padding: 3px 12px;
  color: ${Colors.Neutral50};
`

export const EmptyTag = styled.div`
  background-color: ${Colors.Neutral10};
  border-radius: 24px;
  width: 104px;
  height: 28px;
`
