import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { actions, selectors } from 'builder/modules/panel'
import {
  selectors as critiqueSelectors,
  actions as critiqueActions,
} from 'builder/modules/resumeCritiqueReview'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import * as Styled from './styles'
import TRCIOHeader from './components/TRCIOHeader'

const TopResumeCritiqueFetchView = () => {
  const dispatch = useDispatch()

  const tunerResumeId = useTypedSelector(selectors.tunerResumeId)
  const isTopResumeUser = useTypedSelector(critiqueSelectors.isTopResumeUser)
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(actions.fetchDocuments())
  }, [dispatch])

  useEffect(() => {
    if (tunerResumeId) {
      dispatch(
        critiqueActions.updateIsTopResumeUserFlag({
          resumeId: tunerResumeId,
          isTopResumeUser: true,
        }),
      )
    }
  }, [dispatch, tunerResumeId])

  useEffect(() => {
    if (isTopResumeUser && tunerResumeId) {
      // Redirects the user to the Critique Review Page
      navigate(`/resumes/${tunerResumeId}/critique?lowLeads=1`)
    }
  }, [isTopResumeUser, navigate, tunerResumeId])

  return (
    <Styled.Container>
      <TRCIOHeader />
      <Styled.SpinnerContainer>
        <Styled.Spinner />
        <Styled.LoadingText>Loading your resume review...</Styled.LoadingText>
      </Styled.SpinnerContainer>
    </Styled.Container>
  )
}

export default TopResumeCritiqueFetchView
