import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import image from './images/cover-letter-preview-background.png'

export const Container = styled.div`
  user-select: none;
  padding: 32px 32px 0;
  width: 100%;
  max-height: 280px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  background-image: url(${image});
  background-size: 317px auto;
  background-position: top left;
  position: relative;
  background-color: ${Colors.Neutral5};

  ${Media.Phone`
    padding: 24px 24px 0;
  `}
`

export const PreviewContainer = styled.div`
  flex: auto;
  max-width: 410px;
  max-height: 100%;
  display: flex;
  position: relative;
  margin-bottom: 1px;
  overflow: hidden;
  box-shadow: 0px 14.360248565673828px 57.44099426269531px -7.180124282836914px ${Colors.Blue80}14;
`

export const Toolbar = styled.img`
  width: 200px;
  background-color: ${Colors.Neutral10};
  border-radius: 4px;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: 20px;
  z-index: 2;
  box-shadow: 0px 2.8230338096618652px 7.057584285736084px -1.4115169048309326px ${Colors.Blue80}1f;
`
