import styled from 'styled-components'
import Media from 'builder/styles/media'
import { FontWeights } from 'builder/styles/typography'
import { getLineHeightRatio } from 'builder/components/SubscriptionCancellationFormNew/common/utils'
import Colors from 'builder/styles/colors'

export const BackButton = styled.span`
  font-family: 'TT Commons';
  ${FontWeights.Medium};
  text-align: center;
  color: ${Colors.Blue50};
  margin-left: 4px;

  ${Media.Tablet`
    font-size: 19px;
    line-height: ${getLineHeightRatio(24, 19)};
  `}

  ${Media.Phone`
    font-size: 16px;
    line-height: ${getLineHeightRatio(16, 20)};
  `}
`
