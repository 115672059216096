import type { UserDocumentTemplate } from '@rio/types'
import { useQuery } from '@tanstack/react-query'
import { apiClient } from 'builder/modules/apiClient'

const templateThumbnailsKey = Symbol('template thumbnails')

export const useTemplateThumbnails = (coverLetterId: number) => {
  return useQuery({
    staleTime: Infinity,
    queryKey: [templateThumbnailsKey, coverLetterId],
    queryFn: async () => {
      const [{ data: letterTemplate }, { data: resumeTemplates }] = await Promise.all([
        apiClient.get<UserDocumentTemplate>(`/cover-letters/${coverLetterId}/template`),
        apiClient.get<UserDocumentTemplate[]>(`/resumes/templates`),
      ])

      return {
        coverLetter: letterTemplate.thumbnail,
        resume: resumeTemplates.find(t => t.id === letterTemplate.id)?.thumbnail || '',
      }
    },
  })
}
