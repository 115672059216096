import { useEffect, useState } from 'react'
import { parse as parseQuery } from 'query-string'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import {
  actions,
  selectors,
  ResumeReviewScenarios,
  ResumeCritiqueStatuses,
} from 'builder/modules/resumeReview'
import { ResumeReviewPopupUi } from './ResumeReviewPopupUi'

const POPUP_DISPLAYING_DELAY = 30 * 1000

/** Connects to the store to get a critique and determine if it is time to display the popup now */
export const ResumeReviewPopup = ({ resumeId }: { resumeId: number }) => {
  const dispatch = useDispatch()
  const scenario = useTypedSelector(selectors.scenario)
  const critique = useTypedSelector(state => selectors.critique(state, resumeId))
  const [isVisible, toggleVisibility] = useState(false)
  const status = critique?.status

  // Fetching flow
  useEffect(() => {
    if (scenario !== ResumeReviewScenarios.embedded) return

    // Do not mix with the tuner flow
    if (parseQuery(window.location.search).tuner_tips) return

    // Try to fetch a critique for the resume
    dispatch(actions.fetchCritiques())
  }, [dispatch, scenario])

  // Displaying flow
  useEffect(() => {
    // There is no critique for the active resume
    if (!status) return

    // Format the local storage item key including resume id and its current critique status
    const localStorageKey = `RESUME_${resumeId}_${status.toUpperCase()}_REVIEW_POPUP_SHOWN`

    // Check that the popup for the current status hasn't been shown before
    if (localStorage.getItem(localStorageKey)) return

    // Show popup after the delay and remember that in LS (so the same popup won't be shown again)
    const timer = setTimeout(() => {
      toggleVisibility(true)
      localStorage.setItem(localStorageKey, 'true')
    }, POPUP_DISPLAYING_DELAY)

    // Reset timeout on unmount
    return () => clearTimeout(timer)
  }, [dispatch, status, resumeId])

  // Render the popup if it is time to do that and there is a valid critique to show
  const shouldRender = isVisible && critique && critique.status !== ResumeCritiqueStatuses.failed

  // Track user interaction with the popup
  const handleClose = () => {
    toggleVisibility(false)
    trackInternalEvent('close_resume_critique_banner', { source: 'resume_editor' })
  }

  if (shouldRender) {
    return <ResumeReviewPopupUi critique={critique} onClose={handleClose} />
  }

  return null
}
