import { useMutation } from '@tanstack/react-query'
import { useEffect, useRef } from 'react'
import { baseClient } from 'builder/modules/apiClient'
export interface JobTitle {
  standardTitle: string
  id: number
}

export const useJobTitles = () => {
  const abortControllerRef = useRef(new AbortController())
  useEffect(() => {
    return () => abortControllerRef.current.abort()
  }, [])

  return useMutation(['jobTitles'], async (jobTitle: string, limit = 10): Promise<JobTitle[]> => {
    const res = await baseClient.get('career-data/v1/job-titles', {
      params: {
        startswith: jobTitle,
        limit,
      },
      signal: abortControllerRef.current.signal,
    })
    return res.data
  })
}
