import styled from 'styled-components'

export const BottomBar = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  display: flex;
  flex-flow: row nowrap;
  height: 40px;
  align-items: center;
  justify-content: center;
  bottom: -40px;
`

export const SyncStatusWrapper = styled.div`
  position: absolute;
  left: 0;
`
