import { FC, useState } from 'react'
import { useTransition, config } from '@react-spring/web'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { ButtonTheme } from 'builder/components/Button'
import { analyticEventsLabel, translationKey } from '../../constants'
import * as Styled from './styles'

export const Feedback: FC = () => {
  const { i18n } = useI18n()
  const [isGreetVisible, setIsGreedVisible] = useState(false)
  const transition = useTransition(isGreetVisible, {
    config: config.default,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  const onYesClick = () => {
    trackInternalEvent('click_feedback_answer', {
      answer: 'yes',
      label: analyticEventsLabel,
    })
    setIsGreedVisible(true)
  }

  const onNoClick = () => {
    trackInternalEvent('click_feedback_answer', {
      answer: 'no',
      label: analyticEventsLabel,
    })
    setIsGreedVisible(true)
  }

  return (
    <Styled.Container>
      <Styled.Text>{i18n.t(`${translationKey}.feedback.was_it_useful`)}</Styled.Text>
      <Styled.ButtonsContainer>
        <Styled.Button theme={ButtonTheme.ghost} size="small" onClick={onYesClick}>
          {i18n.t(`${translationKey}.feedback.button_yes`)}
        </Styled.Button>
        <Styled.Button theme={ButtonTheme.ghost} size="small" onClick={onNoClick}>
          {i18n.t(`${translationKey}.feedback.button_no`)}
        </Styled.Button>
      </Styled.ButtonsContainer>
      {transition((style, item) =>
        item ? (
          <Styled.Greet style={style}>
            {i18n.t(`${translationKey}.feedback.thanks_for_feedback`)}
          </Styled.Greet>
        ) : null,
      )}
    </Styled.Container>
  )
}
