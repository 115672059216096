import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import { HorizontalTransitionFade } from 'builder/components/HorizontalTransitionFade'
import Sizes from 'builder/styles/sizes'
import { CareerProfileCompletenessState } from 'builder/components/CareerProfile'
import { BAR_HEIGHT } from 'builder/components/Navigation'

export const Container = styled(HorizontalTransitionFade)`
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
  padding-bottom: 52px;
  display: grid;
  gap: ${Sizes.XL} ${Sizes.S};
  grid-template-columns: auto 352px;
  grid-template-rows: auto;
  grid-template-areas: 'header sidebar' 'content sidebar';
  align-items: start;

  ${Media.Tablet`
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'header' 'sidebar' 'content';
  `}

  ${Media.Phone`
    max-width: 100%;
    gap: ${Sizes.S};
  `}
`

export const Header = styled.div`
  grid-area: header;
  display: flex;
  flex-flow: column;
  gap: ${Sizes.S};
`

export const Sidebar = styled.div`
  grid-area: sidebar;
  display: flex;
  flex-flow: column;
  border-radius: ${Sizes.S};
  border: 1px solid ${Colors.Neutral10};

  ${Media.AboveTablet`
    position: sticky;
    top: ${BAR_HEIGHT}px;
  `}
`

export const Completeness = styled(CareerProfileCompletenessState)`
  // to overlap parent's border
  margin: -1px;
`

export const LinkList = styled.div`
  padding: ${Sizes.M};
`

export const Content = styled.div`
  grid-area: content;
  display: flex;
  flex-flow: column;
  gap: ${Sizes.XL};
`

export const RecommendedJobsLinkContainer = styled(Link)`
  display: flex;
  gap: ${Sizes.XS};
  align-items: center;
  padding: 4px;
  will-change: color;
  transition: color 0.1s ease-out;

  ${Media.Hover`
    &:hover {
      color: ${Colors.Blue60};
    }
  `}
`
