import { Container, CustomImg, Result, Title } from './styles'

interface Props {
  imgLink: string
  title: string
  result: string
}

export const Display = ({ imgLink, title, result }: Props) => {
  return (
    <Container>
      <CustomImg src={imgLink} />
      <Title>{title}</Title>
      <Result>{result}</Result>
    </Container>
  )
}
