import { ReactNode, memo } from 'react'
import { Icon20 } from 'builder/components/Icon'
import { useAppSelector } from 'builder/hooks/useAppSelector'
import { UploadModalContainer, DesktopClose as Close, UploadContainer } from '../styles'

type Props = {
  onClose: () => void
  children: ReactNode
}

const FileUploadContainer = (props: Props) => {
  const { onClose, children } = props
  const CloseIcon = Icon20.Close

  const resumeValidationStatus = useAppSelector(
    state => state.resumeEditor.prefillResumeSection.resumeValidationStatus,
  )

  return (
    <UploadModalContainer {...{ resumeValidationStatus }}>
      <Close onClick={onClose}>
        <CloseIcon />
      </Close>
      <UploadContainer {...{ resumeValidationStatus }}>{children}</UploadContainer>
    </UploadModalContainer>
  )
}

export default memo(FileUploadContainer)
