import { Currency } from 'builder/modules/resumeDistribution'

export const CURRENCY_SIGN: Record<Currency, string> = {
  USD: '$',
  EUR: '€',
  GBP: '£',
  CAD: '$',
  AUD: '$',
}

export const CURRENCY = [
  { id: 'USD', name: 'USD' },
  { id: 'EUR', name: 'EUR' },
  { id: 'GBP', name: 'GBP' },
  { id: 'CAD', name: 'CAD' },
  { id: 'AUD', name: 'AUD' },
]

export const PERIOD = [
  { id: 'year', name: 'Annually' },
  { id: 'hour', name: 'Hourly' },
]
