import { useState, useEffect, useCallback } from 'react'
import xor from 'lodash/xor'

/**
 * Simplifies forms fields routine
 */
export const useFields = initialState => {
  const [fields, setFields] = useState(initialState)

  const handleFieldChange = useCallback((name, value) => {
    setFields(values => ({ ...values, [name]: value || '' }))
  }, [])

  return [fields, handleFieldChange]
}

/**
 * Provides checkboxes logic for services
 */
export const useToggleableNames = services => {
  const formatNames = items => items.filter(item => !item.connected).map(item => item.name)
  const [checked, setChecked] = useState(() => formatNames(services))

  // update local state when prop is changed
  useEffect(() => {
    setChecked(formatNames(services))
  }, [services])

  // public handler to enable/disable value
  const toggle = useCallback(name => setChecked(xor(checked, [name])), [checked])

  return [checked, toggle]
}
