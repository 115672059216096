export const getTimePeriod = (timeString: string): string => {
  return timeString.endsWith('pm') ? 'pm' : 'am'
}

export const formatUpcomingSesionDate = (
  sessionDate: string | number | Date,
  startTime: string,
  endTime: string,
) => {
  // Format the date, start time, and end time
  const date = new Date(sessionDate)
  const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'long' })
  const formattedDate = date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
  })

  const sessionStartTime = startTime.replace(' pm', '').replace(' am', '')
  const sessionEndTime = endTime.replace(' pm', '').replace(' am', '')
  const timePeriod = getTimePeriod(startTime)

  // Combine everything into the desired format
  return `${dayOfWeek}, ${formattedDate} at ${sessionStartTime} - ${sessionEndTime} ${timePeriod}`
}

export const formatPreviousSessionsDate = (dateString: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  }
  const date = new Date(dateString)
  return date.toLocaleDateString('en-US', options)
}
