import { useNavigate } from 'react-router-dom'
import { useI18n } from 'builder/hooks/useI18n'
import EmailImgSrc from '../../assets/email.png'
import {
  Button,
  Container,
  ContainerButton,
  ContainerText,
  EmailImg,
  SubTitle,
  Title,
} from './styles'
const TRANSLATION = 'builder.job_search.alert_modal.card_recommended_disabled'

export const BlockedRecommendedAlert = () => {
  const { i18n } = useI18n()

  const navigate = useNavigate()

  const onClick = async () => {
    const params = '/job-search?view=recommendation&open_job_alert=true'
    const url = `/career-profile/edit?section=jobPreferences&returnUrl=${encodeURIComponent(
      params,
    )}`

    navigate(url)
  }

  return (
    <Container>
      <EmailImg src={EmailImgSrc} />
      <ContainerText>
        <Title> {i18n.t(`${TRANSLATION}.title`)}</Title>

        <SubTitle>{i18n.t(`${TRANSLATION}.subtitle`)}</SubTitle>
      </ContainerText>
      <ContainerButton>
        <Button onClick={onClick}>{i18n.t(`${TRANSLATION}.button`)}</Button>
      </ContainerButton>
    </Container>
  )
}
