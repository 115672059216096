import { useI18n } from 'builder/hooks/useI18n'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { ExternalIcon } from '../components/SideMenu/styles'

const TRANSLATION = 'builder.side_menu'

export const useOtherSubMenu = () => {
  const { i18n } = useI18n()
  const { features } = useFeaturesConfig()

  const othersSubMenu = [
    {
      to: '/career-plans/custom-plan',
      text: i18n.t(`${TRANSLATION}.others_submenu.career_plan`),
      eventParam: 'custom_career_plan',
      enabled: features?.careerPlans,
    },
    {
      to: '#',
      href: 'https://career.io/career-advice',
      text: i18n.t(`${TRANSLATION}.others_submenu.career_advice_blog`),
      endDecoration: <ExternalIcon />,
      eventParam: 'career_advice',
      enabled: features?.careerAdvice,
    },
    {
      to: '/career-path',
      text: i18n.t(`${TRANSLATION}.others_submenu.career_pathways`),
      eventParam: 'career_path',
      enabled: features?.careerPath,
    },
    {
      to: '/explore-careers',
      text: i18n.t(`${TRANSLATION}.others_submenu.explore_careers`),
      eventParam: 'explore_careers',
      enabled: features?.careerExplorer,
    },
    {
      to: '/career-plans/first-90-days',
      text: i18n.t(`${TRANSLATION}.others_submenu.first_90_days`),
      eventParam: 'first_90_days',
      enabled: features?.careerPlans,
    },
    {
      to: '/career-plans/path-to-promotion',
      text: i18n.t(`${TRANSLATION}.others_submenu.promotions`),
      eventParam: 'get_a_promotion',
      enabled: features?.careerPlans,
    },
  ]

  return { othersSubMenu }
}
