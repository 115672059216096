import { forwardRef, memo, useEffect, useState } from 'react'
import Icon from 'builder/components/Icon'
import { AlignType } from './types'

import { CheckboxWrapper, IconWrapper, CheckboxItem, Label } from './styles'

type Ref = React.ForwardedRef<HTMLInputElement>
type CheckboxProps = {
  label?: string | React.ReactNode
  checked?: boolean
  align?: AlignType
  className?: string
  invalid?: boolean
  disabled?: boolean
} & React.InputHTMLAttributes<HTMLInputElement>

export const Checkbox = memo(
  forwardRef(
    (
      {
        label,
        align,
        className,
        onChange,
        checked = false,
        invalid = false,
        disabled = false,
        ...props
      }: CheckboxProps,
      ref: Ref,
    ) => {
      const [checkedState, setChecked] = useState(checked)

      useEffect(() => {
        setChecked(checked)
      }, [checked])

      const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
          onChange(e)
        }

        setChecked(!checkedState)
      }

      return (
        <Label align={align} invalid={invalid} disabled={disabled}>
          <CheckboxWrapper className={className} align={align}>
            {checkedState && (
              <IconWrapper>
                <Icon.Tick />
              </IconWrapper>
            )}
            <CheckboxItem
              ref={ref}
              type="checkbox"
              checked={checkedState}
              invalid={invalid}
              disabled={disabled}
              onChange={onChangeHandler}
              {...props}
            />
          </CheckboxWrapper>
          {label}
        </Label>
      )
    },
  ),
)
