import styled from 'styled-components'
import { Media } from 'builder/styles/media'
import { Container as BaseContainer } from 'builder/components/Container'

export const Container = styled(BaseContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  padding-top: 40px;
  padding-bottom: 120px;

  ${Media.Tablet`
    padding-bottom: 80px;
  `};

  ${Media.Phone`
    padding-top: 8px;
    padding-bottom: 40px;
  `};
`
