import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import { gradient } from 'builder/styles/keyframes'

export const Skeleton = styled.div<{ width?: number; height?: number; marginTop?: number }>`
  animation: ${gradient} 1s linear infinite;
  background: ${Colors.Neutral10};
  background: linear-gradient(
    to right,
    ${Colors.Neutral10} 5%,
    #eaeef6 15%,
    ${Colors.Neutral10} 25%
  );
  border-radius: 2px;
  width: ${p => (p.width ? `${p.width}px` : '100%')};
  height: ${p => (p.height ? `${p.height}px` : '100%')};
  background-size: 100vw 100vh;
  ${p =>
    p.marginTop &&
    css`
      margin-top: ${p.marginTop}px;
    `}
`
