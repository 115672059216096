import { createAction, handleActions } from 'redux-actions'
import produce from 'immer'
import union from 'lodash/union'

export const SERVICES_INFO = {
  resume_library: {
    title: 'Resume-Library',
    domain: 'resume-library.com',
    disclaimer: `By submitting this form you are indicating that you have read and agree to
      <a href="https://www.resume-library.com/terms">Resume-Library’s terms of service.</a>`,
  },
  zip_recruiter: {
    title: 'ZipRecruiter',
    domain: 'ziprecruiter.com',
    disclaimer: `By submitting this form I agree to the ZipRecruiter
      <a href="https://www.ziprecruiter.com/terms">Terms of Use</a> and acknowledge I have read the
      <a href="https://www.ziprecruiter.com/privacy">Privacy Policy</a> and agree to receive daily
      job alert emails. I also agree I understand I may modify or remove my resume or change my
      privacy settings at any time by visiting my ZipRecruiter profile at ZipRecruiter.com.
      By uploading my resume, I agree to let ZipRecruiter use my resume to improve matching, create
      a job seeker account, and include my resume in the Resume Database so that employers can find
      me in their candidate searches.`,
  },
  recruit_robin: {
    title: 'Recruit Robin',
    domain: 'recruitrobin.com',
    websites: [
      {
        title: 'Nationale Vacaturebank',
        name: 'vacaturebank',
      },
      {
        title: 'Werkzoeken.nl',
        name: 'werkzeken',
      },
      {
        title: 'Recruit Robin',
        name: 'recruit_robin',
      },
    ],
    disclaimer: `By clicking &laquo;Post This Resume&raquo;, I agree to the Recruit Robin
      <a href="/legal/recruit_robin_terms_of_use.pdf" target='_blank'>Terms of Use</a>
      and acknowledge I have read the
      <a href="/legal/recruit_robin_privacy_policy.pdf" target='_blank'>Privacy Policy</a>
      and agree to receive daily job alert emails. I also agree I understand I may modify or remove
      my resume or change my privacy settings at any time by visiting my Recruit Robin profile at
      RecruitRobin.com. By uploading my resume, I agree to let Recruit Robin use my resume to
      improve matching, create a job seeker account, and include my resume in the Resume Database
      so that employers can find me in their candidate searches.`,
  },
}

// ---
// Selectors
// ---
export const selectors = {
  isInitialized: state => state.coRegistration.isInitialized,
  isResumeReady: state => state.coRegistration.isResumeReady,
  isSupported: state => selectors.services(state).length > 0,
  isPopupOpen: state => state.coRegistration.isPopupOpen,
  isModalOpen: state => state.coRegistration.isModalOpen,
  sendingState: state => {
    const { isSending, isSent, errors } = state.coRegistration
    return { isSending, isSent, errors }
  },
  services: state => state.coRegistration.services,
  unusedServices: state => selectors.services(state).filter(service => !service.connected),
}

// ---
// Action creators
// ---
export const actions = {
  initialize: createAction('CO_REG/INITIALIZE'),
  initializeSuccess: createAction('CO_REG/INITIALIZE_SUCCESS'),
  connect: createAction('CO_REG/CONNECT'),
  connectStart: createAction('CO_REG/CONNECT_START'),
  connectSuccess: createAction('CO_REG/CONNECT_SUCCESS'),
  connectFail: createAction('CO_REG/CONNECT_FAIL'),
  open: createAction('CO_REG/OPEN'),
  close: createAction('CO_REG/CLOSE'),
  togglePopup: createAction('CO_REG/TOGGLE_POPUP'),
  toggleModal: createAction('CO_REG/TOGGLE_MODAL'),
  toggleResumeStatus: createAction('CO_REG/TOGGLE_RESUME_STATUS'),
}

// ---
// Reducer
// ---
const initialState = {
  isInitialized: false,
  isResumeReady: false,
  isPopupOpen: false,
  isModalOpen: false,
  isSending: false,
  isSent: false,
  errors: [],
  services: [],
}

export const reducer = handleActions(
  {
    [actions.initializeSuccess]: produce((draft, action) => {
      const availableNames = action.payload.availableBoards
      const connectedNames = action.payload.coRegs.map(service => service.provider)

      draft.services = union(availableNames, connectedNames).map(name => ({
        name,
        connected: connectedNames.includes(name),
        ...SERVICES_INFO[name],
      }))

      draft.isInitialized = true
    }),

    [actions.connectStart]: produce(draft => {
      draft.isSending = true
    }),

    [actions.connectSuccess]: produce((draft, action) => {
      const coRegs = action.payload
      const newNames = coRegs.filter(coReg => coReg.success).map(coReg => coReg.provider)

      const errors = coRegs.reduce((list, coReg) => {
        if (!coReg.errors) return list

        const formattedErrors = coReg.errors.map(error => {
          const { title } = SERVICES_INFO[coReg.provider]
          return `${title}: ${error}`
        })

        return list.concat(formattedErrors)
      }, [])

      draft.services = draft.services.map(service => ({
        ...service,
        connected: service.connected || newNames.includes(service.name),
      }))

      draft.isSending = false
      draft.isSent = errors.length === 0
      draft.errors = errors
    }),

    [actions.connectFail]: produce((draft, action) => {
      draft.errors = [action.payload]
      draft.isSending = false
    }),

    [actions.togglePopup]: produce((draft, action) => {
      draft.isPopupOpen = action.payload
    }),

    [actions.toggleModal]: produce((draft, action) => {
      draft.isModalOpen = action.payload
    }),

    [actions.toggleResumeStatus]: produce((draft, action) => {
      draft.isResumeReady = action.payload
    }),

    [actions.close]: produce(draft => {
      draft.isSent = false
    }),
  },
  initialState,
)
