import styled from 'styled-components'
import Icon24 from 'builder/components/Icon'
import Colors from 'builder/styles/colors'

export const Links = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  gap: 4px;
  padding: 0 12px 12px;
`

export const LinkContainer = styled.div`
  display: flex;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${Colors.Neutral5};
  }
`

export const SettingsIcon = styled(Icon24.Settings2)`
  color: ${Colors.Neutral90};
`

export const FaqIcon = styled(Icon24.Info)`
  color: ${Colors.Neutral90};
`

export const LogOutIcon = styled(Icon24.LogOut)`
  color: ${Colors.Neutral90};
`

export const Link = styled.a`
  display: block;
  color: ${Colors.Neutral90};
  transition: color 0.2s;
  cursor: pointer;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  text-align: start;
`
