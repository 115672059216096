import { useEffect, useState } from 'react'
import { Hint } from './style'

interface Props {
  hints: string[]
}

export const ShowHints = ({ hints }: Props) => {
  const [hintIndex, setHintIndex] = useState(0)
  useEffect(() => {
    const id = setInterval(() => {
      setHintIndex(hintIndex => {
        return (hintIndex || 0 + 1) % hints.length
      })
    }, 15000)

    return () => {
      clearInterval(id)
    }
  }, [])

  return <Hint>{hints[hintIndex]}</Hint>
}
