import { useEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'

import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'

import Button, { ButtonSize } from 'builder/components/Button'
import { Bottom, Content, Title, Modal } from './styles'

export const TRANSLATION = 'builder.auto_apply.confirm_modal'

type Props = {
  onClose: () => void
  onConfirm: () => void
}

export const ConfirmModal = ({ onClose, onConfirm }: Props) => {
  const { i18n } = useI18n()
  const { isPhone } = useMediaQueries()

  const onCloseModal = (type: 'cancel' | 'close') => {
    const eventName =
      type === 'cancel'
        ? 'tjss_closing_modal_clicking_cancel'
        : 'tjss_closing_modal_clicking_close_button'
    trackInternalEvent(eventName, { label: 'total_job_search_solution' })
    onClose()
  }

  const onConfirmModal = () => {
    trackInternalEvent('tjss_closing_modal_clicking_yes', { label: 'total_job_search_solution' })
    onConfirm()
  }

  useEffect(() => {
    trackInternalEvent('tjss_closing_modal', {
      label: 'total_job_search_solution',
    })
  }, [])

  return (
    <Modal
      onClose={() => onCloseModal('close')}
      title={<Title>{i18n.t(`${TRANSLATION}.title`)}</Title>}
    >
      <Content>{i18n.t(`${TRANSLATION}.content`)}</Content>

      <Bottom>
        <Button
          theme="ghost"
          size={isPhone ? ButtonSize.small : ButtonSize.default}
          onClick={() => onCloseModal('cancel')}
        >
          {i18n.t(`${TRANSLATION}.cancel`)}
        </Button>
        <Button size={isPhone ? ButtonSize.small : ButtonSize.default} onClick={onConfirmModal}>
          {i18n.t(`${TRANSLATION}.confirm`)}
        </Button>
      </Bottom>
    </Modal>
  )
}
