import styled from 'styled-components'
import { Colors } from 'builder/styles/colors'
import { Typography } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Container = styled.div`
  padding: 12px 20px 12px 16px;
  background: ${Colors.Blue10};
  margin-bottom: 16px;
  border-radius: 8px;
  display: flex;

  ${Media.Phone`
    width: 100%;
  `};
`

export const Image = styled.img`
  height: 24px;
  margin-right: 8px;
`
export const Text = styled.div`
  ${Typography.Caption};
`
