import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { baseClient } from 'builder/modules/apiClient'
import { actions } from 'builder/modules/interview/interviewModule'
import { NewInterviewResponse } from 'builder/modules/interview/types'

type Params = {
  jobTitleCode: number
  company?: string
  jobDescription?: string
}
export const useNewInterview = () => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  return useMutation(
    ['newInterview'],
    async (params: Params): Promise<NewInterviewResponse> => {
      const res = await baseClient.post('interview_prep/interviews', {
        data_api_job_title_id: params.jobTitleCode,
        company_name: params.company,
        job_description: params.jobDescription,
      })
      dispatch(actions.setInterviewId(res.data.id))
      return res.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['useInterviews'])
        queryClient.invalidateQueries(['useDailySuggestions'])
        queryClient.invalidateQueries(['useCompanyInterviews'])
      },
    },
  )
}
