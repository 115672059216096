import Button, { ButtonSize } from 'builder/components/Button'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { NotifyMeContainer, Title, Subtitle, ButtonContainer, SubContainer } from './styles'

type Props = {
  handleCancel: () => void
  handleNotify: () => void
}

const NotifyMe = ({ handleCancel, handleNotify }: Props) => {
  const { isPhone } = useMediaQueries()

  return (
    <NotifyMeContainer>
      <SubContainer>
        <Title>Career planning is on the way!</Title>
        <Subtitle>Meanwhile, you can self-educate about titles from your shortlist.</Subtitle>
      </SubContainer>
      <ButtonContainer>
        <Button
          theme="ghost"
          onClick={handleCancel}
          size={isPhone ? ButtonSize.small : ButtonSize.default}
        >
          Not Now
        </Button>
        <Button onClick={handleNotify} size={isPhone ? ButtonSize.small : ButtonSize.default}>
          Notify Me
        </Button>
      </ButtonContainer>
    </NotifyMeContainer>
  )
}

export default NotifyMe
