import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import { CentralColumn } from '../../styles'
import backgroundImageDesktop from './images/background.png'
import backgroundImageMobile from './images/background-mobile.png'

export const Container = styled.div`
  // mobile first
  padding: 32px 24px 48px;
  background-color: ${Colors.Blue10};
  overflow: hidden;
  position: relative;
  background-image: none;
  border-radius: 16px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.45;
    background-image: url(${backgroundImageMobile});
    background-position: calc(50% + 6px) -200px;
    background-size: 590px;
    pointer-events: none;
    z-index: 0;
  }

  ${Media.Tablet`
    &::before {
      background-position: calc(50% + 6px) -236px;
      background-size: 707px;
    }
  `}

  ${Media.AboveTablet`
    padding: 36px 32px 48px;
    background-image: url(${backgroundImageDesktop});
    background-position: top;

    &::before {
      content: none;
    }
  `}
`

export const Content = styled(CentralColumn)`
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 26px;

  ${Media.Phone`
    gap: 24px;
  `}
`

export const UserPhoto = styled.img`
  width: 170px;
  height: 170px;
  border-radius: 50%;
  border: 3px solid ${Colors.White};

  ${Media.Phone`
    width: 100px;
    height: 100px;
    border-width: 2px;
  `}
`

export const TextContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 12px;
`

export const TextBold = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};
  color: ${Colors.Blue100};
  text-align: center;
`

export const Text = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Regular};
  color: ${Colors.Blue90};
  text-align: center;
`
