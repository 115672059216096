import { FC, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  CareerInsightsPageType,
  CareerInsightsProcessingStatus,
  actions,
  selectors,
} from 'builder/modules/careerProfile'
import { useCareerInsightsStatus } from 'builder/components/CareerInsights'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { FetchStatuses } from 'builder/modules/constants'
import { PageLoader } from '../PageLoader'

export const StatusRouteGuard: FC<{ type: CareerInsightsPageType }> = ({ type, children }) => {
  const dispatch = useDispatch()

  const careerInsightsStatus = useCareerInsightsStatus()
  const fetchCareerInsightsStatus = useTypedSelector(selectors.fetchCareerInsightsStatus)
  const careerInsightsReady = careerInsightsStatus === CareerInsightsProcessingStatus.success

  const isLoading =
    fetchCareerInsightsStatus !== FetchStatuses.failed &&
    fetchCareerInsightsStatus !== FetchStatuses.loaded

  useEffect(() => {
    if (!isLoading && !careerInsightsReady) {
      dispatch(actions.setCareerInsightsStatusModalType(type))
    }
  }, [isLoading, careerInsightsReady, dispatch, type])

  return isLoading ? (
    <PageLoader />
  ) : careerInsightsReady ? (
    <>{children}</>
  ) : (
    <Navigate to="/" replace />
  )
}
