import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { parse as parseQuery, stringify as formatQuery } from 'query-string'
import { actions as renderingActions } from 'builder/modules/rendering'
import { actions as resumeReviewActions } from 'builder/modules/resumeReview'
import { DocumentTypes as TYPES, FormatType } from 'builder/modules/constants'

/** Checks document related query params to start downloading and review flows */
export const useDocumentQueryHandler = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const {
      download_document_type: type,
      download_document_id: id,
      download_document_format: format,
      download_document_template: template,
      ...rest
    } = parseQuery(location.search, { parseNumbers: true })

    // The only param required to download a file is `id`
    if (typeof id === 'number') {
      // Call rendering engine to generate the file
      dispatch(
        renderingActions.download({
          id,
          type: type as TYPES,
          format: format as FormatType,
          template: template as string,
        }),
      )
      // Send resume to review
      if (type !== TYPES.coverLetter) dispatch(resumeReviewActions.sendResume({ resumeId: id }))
      // Clean the URL
      navigate(`${location.pathname}?${formatQuery(rest)}`, { replace: true })
    }
  }, [dispatch, navigate, location.pathname, location.search])
}
