import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Sizes from 'builder/styles/sizes'
import Icon, { Icon20 } from 'builder/components/Icon'

export const Container = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: ${Sizes.XS};
`

export const Item = styled.div<{ isPassive?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: ${Sizes['2XS']};
  color: ${props => (props.isPassive ? Colors.Neutral40 : Colors.Neutral80)};
  align-items: center;
`

export const Completeness50Icon = styled(Icon.Completeness50Percent)`
  width: 24px;
  height: 24px;
`

export const ArrowTargetIconWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  border-radius: 4px;
  background: ${Colors.Indigo10};
  color: ${Colors.Indigo50};
`

export const ArrowTargetIcon = styled(Icon20.ArrowTarget2)`
  width: 24px;
  height: 24px;
`
