import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { FontWeights } from 'builder/styles/typography'

export const WarningEmail = styled.span`
  color: ${Colors.Blue50};
  cursor: pointer;

  &:hover {
    color: ${Colors.Blue60};
  }

  & b {
    ${FontWeights.DemiBold};
  }
`
