/* eslint-disable max-len */
import { useMemo, useEffect } from 'react'

import * as React from 'react'
import { trackInternalEvent } from '@rio/tracking'
import {
  useTimeLeftFormatter,
  ResumeCritique,
  ResumeCritiqueStatuses as STATUSES,
} from 'builder/modules/resumeReview'
import { ButtonSize, ButtonTheme } from 'builder/components/Button'
import { Icon20, Icon24 } from 'builder/components/Icon'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { IFRAME_PARAMS } from 'builder/modules/resumeCritiqueReview/constants'

import {
  StatusContainer,
  StatusVisual,
  StatusIconWrapper,
  StatusIcon,
  StatusPhoto,
  StatusInner,
  StatusContent,
  StatusTitle,
  StatusText,
  StatusButton,
  StatusButtonIcon,
} from './styles'

interface ResumeReviewStatusUiProps extends React.HTMLAttributes<HTMLDivElement> {
  critique: ResumeCritique
  onClose: () => void
}

export const ResumeReviewStatusUi = ({ critique, onClose, ...rest }: ResumeReviewStatusUiProps) => {
  const { status } = critique
  const timeLeft = useTimeLeftFormatter(critique)
  const { isTablet } = useMediaQueries()

  useEffect(() => {
    trackInternalEvent('see_resume_critique_banner', {
      source: 'dashboard',
      status,
    })
  }, [status])

  // TODO: i18n
  const title = useMemo(() => {
    if (status === STATUSES.failed) return 'There was a technical issue reviewing your resume'
    if (status === STATUSES.finished) return 'Your Resume Review is Ready'
    return 'Your Resume Review is Almost Ready'
  }, [status])

  // TODO: i18n
  const text = useMemo(() => {
    if (status === STATUSES.failed) {
      return 'Our team is working to solve the problem. It may take up to several days. Apologies and thank you for your patience!'
    }
    if (status === STATUSES.finished) {
      return `Personalized tips by resume expert ${critique.reviewer.name} on how you can improve your resume`
    }
    return `Our experts are writing a comprehensive review of your resume – check back in ${timeLeft}!`
  }, [status, timeLeft, critique.reviewer.name])

  const onClickStatusIconWrapper = () => {
    if (status === STATUSES.failed) {
      trackInternalEvent('click_resume_critique_error_icon', {
        section: 'banner',
      })
    }
  }

  const onClickStatusFailedButton = () => {
    onClose()
    trackInternalEvent('close_resume_critique_error')
  }

  const onClickStatusButtonFinished = (url: string) => {
    trackInternalEvent('click_read_resume_critique_button', {
      section: 'banner',
      source: 'dashboard',
    })
    window.open(`${url}${IFRAME_PARAMS}`)
  }

  return (
    <StatusContainer {...rest} status={status}>
      <StatusVisual>
        {status === STATUSES.pending && !isTablet && (
          <StatusPhoto src={require('images/builder/panel/resume-review-status/photo-2.jpg')} />
        )}
        {status === STATUSES.pending && (
          <StatusPhoto src={require('images/builder/panel/resume-review-status/photo-1.jpg')} />
        )}
        {status === STATUSES.finished && <StatusPhoto src={critique.reviewer.photoUrl} />}

        <StatusIconWrapper onClick={onClickStatusIconWrapper} status={status}>
          <StatusIcon as={status === STATUSES.failed ? Icon24.Close : Icon20.Star} />
        </StatusIconWrapper>
      </StatusVisual>

      <StatusInner>
        <StatusContent>
          <StatusTitle>{title}</StatusTitle>
          <StatusText>{text}</StatusText>
        </StatusContent>

        {/* TODO: i18n */}
        {status === STATUSES.pending && (
          <StatusButton size={ButtonSize.small} isDisabled>
            {timeLeft} left
          </StatusButton>
        )}

        {/* TODO: i18n */}
        {status === STATUSES.finished && (
          <StatusButton
            status={status}
            onClick={() => onClickStatusButtonFinished(critique.iframeUrl)}
            size={ButtonSize.small}
          >
            Read the Review <StatusButtonIcon />
          </StatusButton>
        )}

        {/* TODO: i18n */}
        {status === STATUSES.failed && (
          <StatusButton
            onClick={onClickStatusFailedButton}
            theme={ButtonTheme.destructive}
            size={ButtonSize.small}
          >
            Close
          </StatusButton>
        )}
      </StatusInner>
    </StatusContainer>
  )
}
