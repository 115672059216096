import styled from 'styled-components'
import { Icon20 } from 'builder/components/Icon'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  height: 100px;
  background: ${Colors.Neutral5};
  border-radius: 12px;
  padding: 16px;
  display: flex;
  gap: 12px;
`

export const CoachingSessions = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`

export const GreenTick = styled(Icon20.Tick)`
  color: ${Colors.Green50};
`

export const Feature = styled.div`
  display: flex;
  gap: 12px;
`

export const FeaturesText = styled.div`
  ${Typography.Caption};
`

export const CompHeading = styled.div`
  ${FontWeights.DemiBold};
  ${Typography.Caption};
`

export const BrandLogo = styled.img`
  align-self: flex-start;
  padding-top: 4px;
`
