import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { trackInternalEvent } from '@rio/tracking'

import { useI18n } from 'builder/hooks/useI18n'
import { useUser } from 'builder/hooks/useUser'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import {
  actions as resumeDistributionActions,
  Currency as CurrencyType,
  IJobPreferencesForm,
} from 'builder/modules/resumeDistribution'
import { selectors, actions } from 'builder/modules/autoApply/autoApplyModule'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { goToBillingPlanPage } from 'builder/utils/goToBillingPlanPage'
import { parseFormData } from 'builder/views/ResumeDistributionView/utils'

import * as SimpleForm from 'builder/components/SimpleForm'
import { JobPreferencesBanner } from 'builder/views/ResumeDistributionView/components/JobPreferencesForm/JobPreferencesBanner/JobPreferencesBanner'
import { JobPreferences } from 'builder/views/ResumeDistributionView/components/Forms/JobPreferences/JobPreferences'
import { Container, FillWarning, LayoutContainer, Title, TitleWrapper } from '../../styles'

import { StartCompleteScreenRoute, TotalRdPageCount } from '../../../constants'
import { FormContainer } from '../styles'
import { Column, Section } from './styles'

const TRANSLATION = 'builder.auto_apply.resume_distribution.job_preferences'
const RD_AUTO_APPLY_KEY = 'resume-distribution-auto-apply'

export const JobPreferencesContainer = () => {
  const { i18n } = useI18n()
  const user = useUser()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [queryParams] = useSearchParams()
  const { isPhone, isTablet } = useMediaQueries()

  const autoApplyForm = useTypedSelector(selectors.form)
  const formState = useTypedSelector(selectors.resumeDistributionForm)

  const getCurrency = () => {
    const currency = (autoApplyForm.jobPreferenceAttributes?.expectedCurrency || '') as CurrencyType
    const currencies: CurrencyType[] = ['USD', 'EUR', 'GBP', 'CAD', 'AUD']
    return currencies.includes(currency) ? currency : 'USD'
  }

  const getDefaultValues = () => {
    const expectedSalary = autoApplyForm.jobPreferenceAttributes?.expectedSalary?.toString() || ''
    return {
      ...formState.jobPreferences,
      salaryCurrency: getCurrency(),
      salaryAmount: expectedSalary,
    }
  }

  const form = useForm<IJobPreferencesForm>({
    mode: 'onChange',
    defaultValues: getDefaultValues(),
  })

  const redirectOnPayments = () => {
    goToBillingPlanPage({
      upgradeURL: user?.billingInfo?.upgradeUrl,
      referrerPage: 'resumeDistribution',
      onSuccessRedirect: '/app/auto-apply/rd/job-preferences?submit_resume=true',
    })
  }

  const storeToLocalStorage = (data: FormData) => {
    localStorage.removeItem(RD_AUTO_APPLY_KEY)
    const stringifiedData = JSON.stringify(Array.from(data.entries()))
    localStorage.setItem(RD_AUTO_APPLY_KEY, stringifiedData)
  }

  const submitResumeAfterPayment = () => {
    const serializedFormData = localStorage.getItem(RD_AUTO_APPLY_KEY)
    const parsedData = parseFormData(serializedFormData)

    if (parsedData) {
      dispatch(resumeDistributionActions.postCandidate(parsedData))
      trackInternalEvent('rd_start_distributing', { label: 'total_job_search_solution' })

      localStorage.removeItem(RD_AUTO_APPLY_KEY)
      navigate(`/auto-apply/rd/${StartCompleteScreenRoute.RD_COMPLETED}`)
    }
  }

  const onNextStep = (jobPreferences: IJobPreferencesForm) => {
    const bodyFormData = new FormData()
    bodyFormData.append('career_profile_id', autoApplyForm.id.toString())
    bodyFormData.append('first_name', autoApplyForm?.firstName || '')
    bodyFormData.append('last_name', autoApplyForm?.lastName || '')
    bodyFormData.append('job_title', autoApplyForm?.currentJobTitle || '')
    bodyFormData.append('email', autoApplyForm?.email || '')
    bodyFormData.append('full_address', autoApplyForm?.fullAddress || '')
    bodyFormData.append('preferences[country_id]', jobPreferences.country?.id || '')
    bodyFormData.append('preferences[state_id]', jobPreferences.state?.id || '')
    bodyFormData.append('preferences[industry_id]', jobPreferences.industry.toString() || '')
    bodyFormData.append('preferences[remote]', jobPreferences.remote === 'true' ? 'yes' : 'no')
    bodyFormData.append('preferences[desired_salary][currency]', jobPreferences.salaryCurrency)
    bodyFormData.append('preferences[desired_salary][amount]', jobPreferences.salaryAmount)
    bodyFormData.append('preferences[desired_salary][per]', jobPreferences.salaryPeriod)

    const linkedin = autoApplyForm.socialLinksAttributes.find(item => item.title === 'linkedin')
    if (linkedin?.link) {
      bodyFormData.append('linkedin_url', linkedin.link)
    }

    if (!user?.scopes.resumeDistribution) {
      storeToLocalStorage(bodyFormData)
      redirectOnPayments()
      return
    }

    dispatch(resumeDistributionActions.postCandidate(bodyFormData))
    dispatch(actions.updateResumeDistributionForm({ jobPreferences }))
    trackInternalEvent('rd_start_distributing', {
      label: 'total_job_search_solution',
    })
    navigate(`/auto-apply/rd/${StartCompleteScreenRoute.RD_COMPLETED}`)
  }

  const onBackClick = () => {
    form.clearErrors()
    navigate(`/auto-apply/rd/${StartCompleteScreenRoute.COMPLETED}`)
  }

  useEffect(() => {
    trackInternalEvent('rd_job_preferences', {
      label: 'total_job_search_solution',
    })

    if (queryParams.get('submit_resume')) {
      submitResumeAfterPayment()
    }
  }, [])

  return (
    <LayoutContainer>
      {!isPhone && !isTablet && (
        <Column>
          <Section />
        </Column>
      )}
      <Container>
        <TitleWrapper>
          <Title>{i18n.t(`${TRANSLATION}.title`)}</Title>
          <FillWarning>{i18n.t(`${TRANSLATION}.sub_title`)}</FillWarning>
        </TitleWrapper>
        {(isPhone || isTablet) && <JobPreferencesBanner data={form.getValues()} />}
        <FormContainer>
          <SimpleForm.Form
            submitButtonText={i18n.t(`${TRANSLATION}.next_button`)}
            onBackClick={onBackClick}
            onSubmit={form.handleSubmit(onNextStep)}
            processIndicator={{
              totalStep: TotalRdPageCount,
              currentStep: 0,
            }}
            autoComplete="off"
          >
            <JobPreferences form={form} setStateFromField={() => {}} isAutoCompleteForm={true} />
          </SimpleForm.Form>
        </FormContainer>
      </Container>
      {!isPhone && !isTablet && (
        <Column>
          <Section>
            <JobPreferencesBanner data={form.getValues()} />
          </Section>
        </Column>
      )}
    </LayoutContainer>
  )
}
