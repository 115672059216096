import { useI18n } from 'builder/hooks/useI18n'
import { useConfig } from 'builder/hooks/useConfig'
import { ConfigScopesEnum } from 'builder/modules/init'

import { BillingItemList } from './BillingItemList'
import {
  BillingFormContainer,
  CTAPrimary,
  Disclaimer,
  PaymentMethodIconsContainer,
  PaymentMethodsContainer,
  TopBadgeText,
  TopBadgeTextContainer,
  WeAcceptLabel,
} from './styles'
import { BillingItemData } from './BillingItem'

type Props = {
  plans: Array<BillingItemData>
  ctaLabel: string
  disclaimer: string
  topBadgeText?: string
  onCTAClick?: () => void
  shouldShowTopBadge: boolean
}

export const BillingForm = ({
  plans,
  ctaLabel,
  disclaimer,
  topBadgeText,
  shouldShowTopBadge,
  onCTAClick,
}: Props) => {
  const { i18n } = useI18n()
  const countryConfig = useConfig(ConfigScopesEnum.country)

  return (
    <BillingFormContainer>
      {shouldShowTopBadge && topBadgeText && (
        <TopBadgeTextContainer>
          <TopBadgeText>{topBadgeText}</TopBadgeText>
        </TopBadgeTextContainer>
      )}

      <BillingItemList items={plans} />
      <CTAPrimary onClick={onCTAClick}>{ctaLabel}</CTAPrimary>
      <PaymentMethodsContainer>
        <WeAcceptLabel>
          {i18n.t('builder.resume_distribution.upsell_modal.we_accept')}
        </WeAcceptLabel>
        <PaymentMethodIconsContainer>
          {countryConfig?.paymentMethods?.map((method, index) => (
            <img key={index} src={method.iconUrl} alt={method.title} title={method.title} />
          ))}
        </PaymentMethodIconsContainer>
      </PaymentMethodsContainer>

      <Disclaimer>*{disclaimer}</Disclaimer>
    </BillingFormContainer>
  )
}
