import { SectionNames } from 'builder/modules/resumeEditor'

export const translationKeyAliases: Record<string, string> = {
  [SectionNames.skills]: 'builder.resume_editor.area_of_expertise',
  [SectionNames.workExperiences]: 'builder.resume_editor.professional_experience',
  [SectionNames.socialProfiles]: 'builder.resume_editor.links',
  [SectionNames.internships]: 'builder.resume_editor.additional_experience',
  [SectionNames.courses]: 'builder.resume_editor.professional_training',
  [SectionNames.activities]: 'builder.resume_editor.licenses_and_certifications',
  [SectionNames.educations]: 'builder.resume_editor.education',
}
