import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import { Icon20 } from 'builder/components/Icon'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  width: 544px;
  background-color: ${Colors.White};
  border-radius: 16px;
  z-index: 999;

  ${Media.Phone`
    width: 343px;
  `};
`

export const Visual = styled.video`
  width: 100%;
  border-radius: 16px 16px 0 0;
`

export const Close = styled.div`
  position: absolute;
  width: 28px;
  height: 28px;
  top: 32px;
  right: 32px;
  z-index: 10;
  border-radius: 32px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.Neutral30};
  cursor: pointer;
  transition: color 0.1s;
  background-color: ${Colors.White};

  &:hover {
    color: ${Colors.Blue50};
  }

  ${Media.Phone`
    top: 20px;
    right: 20px;
    padding: 2px;
  `};
`

export const DescriptionSection = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  padding: 32px;

  ${Media.Phone`
    gap: 16px;
    padding: 20px;
  `};
`

export const Description = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: flex-start;

  ${Media.Phone`
    gap: 4px;
  `};
`

export const Header = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: flex-start;

  ${Media.Phone`
    gap: 8px;
  `};
`

export const PremiumText = styled.div`
  ${FontWeights.DemiBold};
  color: ${Colors.Indigo70};
`

export const PremiumIcon = styled(Icon20.Crown)`
  width: 24px;
  height: 24px;
  color: ${Colors.Indigo70};
`

export const Title = styled.div`
  ${Typography.S};
  ${FontWeights.DemiBold};
`

export const SubTitle = styled.div`
  color: ${Colors.Neutral50};
`

export const UnlockFeaturesSection = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;

  ${Media.Phone`
    gap: 12px;
  `};
`

export const Logo = styled.img`
  border-radius: 8px;
  position: relative;
  top: -2px;
  height: 24px;
  width: 24px;
`

export const LogoText = styled.span`
  ${FontWeights.Medium};
  color: ${Colors.Neutral80};
`

export const UnlockText = styled.div`
  ${Typography.Caps};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral80};
`

export const FeaturesContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: start;
  padding: 12px;
  width: 50%;
  background-color: ${Colors.Neutral5};
`

export const FeatureColumn = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  justify-content: start;
  align-items: flex-start;
  background-color: ${Colors.Neutral5};
`

export const FeatureItem = styled.div`
  display: flex;
  gap: 4px;
`

export const Feature = styled.div`
  ${Typography.Caption};
`

export const GreenTick = styled(Icon20.Tick)`
  color: ${Colors.Green40};
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 4px;

  ${Media.Phone`
    gap: 8px;
  `};
`
