import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const NotificationContainer = styled.label<{ $enablePointer: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  padding: 24px 0;
  border-bottom: 1px solid ${Colors.Neutral20};

  ${({ $enablePointer }) => $enablePointer && `cursor: pointer;`}

  outline: none;

  ${Media.Phone`
    padding: 20px 0;
  `};

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
`

export const NotificationContent = styled.div`
  flex-grow: 1;
`

export const NotificationName = styled.div``

export const NotificationText = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
`

export const NotificationInput = styled.input`
  position: absolute;
  top: 50%;
  right: 0;
  opacity: 0;
  pointer-events: none;
`

export const NotificationToggle = styled.div<{ checked: boolean }>`
  position: relative;
  flex-shrink: 0;
  margin-left: 24px;
  width: 30px;
  height: 18px;
  border-radius: 9px;
  transition: background-color 0.15s ease;
  background-color: ${props => (props.checked ? Colors.Blue50 : Colors.Neutral20)};

  &:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: ${Colors.White};
    transition: transform 0.14s ease;
    transform: translateX(${props => (props.checked ? 12 : 0)}px);
  }
`

export const ManagerButton = styled.div`
  color: ${Colors.Blue50};
  ${Typography.Body};
  ${FontWeights.Regular}
  cursor: pointer;
`
