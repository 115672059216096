import { SortableContainer } from 'react-sortable-hoc'
import { AccSkillsType } from 'builder/modules/sevenStories'

import ReviewSkill from '../ReviewSkill'
import { SkillsContainer } from '../../styles'

type Props = {
  flag: boolean
  isSelectionAllowed: boolean
  accomplishmentsSkills: AccSkillsType[]
  handleSelect: (id: number, storyID: number) => void
  handleSortEnd: (oldIndex: number, newIndex: number) => void
}

type ChildParam = {
  children: JSX.Element[]
}

type SortEndParams = {
  oldIndex: number
  newIndex: number
}

const Droppable = SortableContainer(({ children }: ChildParam) => (
  <SkillsContainer>{children}</SkillsContainer>
))

const DroppableWrapper = ({
  flag,
  handleSelect,
  handleSortEnd,
  isSelectionAllowed,
  accomplishmentsSkills,
}: Props) => {
  const onSortEnd = ({ oldIndex, newIndex }: SortEndParams) => {
    handleSortEnd(oldIndex, newIndex)
  }

  return (
    <Droppable lockAxis="y" useDragHandle onSortEnd={onSortEnd} useWindowAsScrollContainer>
      {accomplishmentsSkills
        .filter(skill => skill.isSelected === flag)
        .map(({ id, text, storyID, isSelected }, index) => (
          <ReviewSkill
            key={index}
            {...{
              id,
              text,
              index,
              storyID,
              isSelected,
              handleSelect,
              isSelectionAllowed,
            }}
          />
        ))}
    </Droppable>
  )
}

export default DroppableWrapper
