import { useState } from 'react'
import { Column, ComponentPreview, Group, Label, Row } from 'builder/components/UiKit/styles'
import { RadioGroup } from 'builder/components/RadioGroup'
import { Radio } from 'builder/components/Radio'
import { radioGroupOptions } from './constants'

export const RadioGroupSection = () => {
  const [value, setValue] = useState<string | null>(null)

  return (
    <Group>
      <Label>RadioControl</Label>
      <ComponentPreview>
        <Row>
          <Radio />
          <Radio checked />
          <Radio invalid />
          <Radio disabled />
          <Radio disabled checked invalid />
        </Row>
      </ComponentPreview>
      <br />
      <Label>RadioGroup</Label>
      <ComponentPreview>
        <Column>
          <RadioGroup.Control value="regular" label="Regular radio control" readOnly />
          <RadioGroup.Control value="checked" checked label="Checked radio control" readOnly />
          <RadioGroup.Control value="invalid" label="Invalid radio control" invalid readOnly />
          <RadioGroup.Control value="disabled" label="Disabled radio control" disabled readOnly />
          <RadioGroup.Control
            value="disabledChecked"
            checked
            label="Disabled and checked radio control"
            disabled
            readOnly
          />
          <hr />
          <span>Your favourite ninja turtle</span>
          <RadioGroup
            value={value}
            onChange={value => {
              alert(`My favourite ninja is ${value}`)
              setValue(value)
            }}
          >
            {radioGroupOptions.map(option => (
              <RadioGroup.Control key={option.value} name={option.value} {...option} />
            ))}
          </RadioGroup>
        </Column>
      </ComponentPreview>
    </Group>
  )
}
