import styled, { css } from 'styled-components'
import { colord } from 'colord'
import Colors from 'builder/styles/colors'
import { Icon24 } from 'builder/components/Icon'

interface ColorBaseProps {
  size: number
}

interface ButtonProps {
  active: boolean
}

const ColorBase = styled.div<ColorBaseProps>`
  position: relative;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-tap-highlight-color: transparent;

  color: ${({ color }) =>
    color && colord(color).brightness() > 0.627 ? Colors.Neutral100 : Colors.White};

  &:last-child {
    margin-right: 0;
  }
`

export const CustomColor = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  bottom: 4px;
  left: 4px;
  border-radius: 50%;
  overflow: hidden;
`

export const AddIcon = styled.div`
  position: relative;
  width: 10px;
  height: 10px;
  transition: transform 0.1s, visibility 0.1s;
  transform: scale(1);

  &[data-visible='false'] {
    visibility: hidden;
    transform: scale(0);
  }

  &:after,
  &:before {
    content: '';
    position: absolute;
    background-color: currentColor;
    transition: transform 0.2s;
  }

  &:before {
    left: 0;
    top: calc(50% - 1px);
    width: 100%;
    height: 2px;
  }

  &:after {
    top: 0;
    left: calc(50% - 1px);
    width: 2px;
    height: 100%;
  }
`

export const ActiveIcon = styled(Icon24.Tick)`
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  margin: -12px 0 0 -12px;
  transition: transform 0.1s ease;
  transform: scale(1);

  &[data-visible='false'] {
    transform: scale(0);
  }
`

export const Button = styled(ColorBase)<ButtonProps>`
  cursor: pointer;
  /* Instagram-like linear gradient (fallback) */
  background: linear-gradient(45deg, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
  /* Conic gradient (according to the design) */
  background: conic-gradient(
    from 135deg at 50% 50%,
    #e43a3a 0deg,
    #e0ca53 45deg,
    #67c745 95deg,
    #42daa3 145deg,
    #31bbe7 190deg,
    #3851d6 230deg,
    #8f00ff 280deg,
    #e736a0 320deg,
    #c94949 360deg
  );

  ${props =>
    !props.active &&
    css`
      color: ${Colors.White};
    `}

  &:before {
    content: '';
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    background-color: ${Colors.Neutral100};
    border-radius: 50%;
  }

  &:hover ${AddIcon}:before {
    transform: scaleX(1.2);
  }

  &:hover ${AddIcon}:after {
    transform: scaleY(1.2);
  }
`
