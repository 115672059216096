import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'

const commonCss = css`
  content: '';
  position: absolute;
  border-style: solid;
`

export const PopoverContainer = styled.div`
  background: transparent;

  &[data-placement^='top'] > .tippy-arrow:before {
    ${commonCss}

    bottom: -9px;
    left: 0;
    border-width: 9px 9px 0;
    border-top-color: ${Colors.Neutral15};
  }

  &[data-placement^='top'] > .tippy-arrow:after {
    ${commonCss}

    bottom: -8px;
    left: 0;
    border-width: 9px 9px 0;
    border-color: transparent;
    border-top-color: initial;
  }

  &[data-placement^='bottom'] > .tippy-arrow:before {
    ${commonCss}

    top: -9px;
    left: 0;
    border-width: 0 9px 9px;
    border-bottom-color: ${Colors.Neutral15};
  }

  &[data-placement^='bottom'] > .tippy-arrow:after {
    ${commonCss}

    top: -8px;
    left: 0;
    border-width: 0 9px 9px;
    border-color: transparent;
    border-bottom-color: initial;
  }

  &[data-placement^='left'] > .tippy-arrow:before {
    ${commonCss}

    right: -9px;
    border-width: 9px 0 9px 9px;
    border-left-color: ${Colors.Neutral15};
  }

  &[data-placement^='left'] > .tippy-arrow:after {
    ${commonCss}

    right: -8px;
    border-width: 9px 0 9px 9px;
    border-color: transparent;
    border-left-color: initial;
  }

  &[data-placement^='right'] > .tippy-arrow:before {
    ${commonCss}

    left: -9px;
    border-width: 9px 9px 9px 0;
    border-right-color: ${Colors.Neutral15};
  }

  &[data-placement^='right'] > .tippy-arrow:after {
    ${commonCss}

    left: -8px;
    border-width: 9px 9px 9px 0;
    border-color: transparent;
    border-right-color: initial;
  }
`

export const PopoverArrow = styled.div`
  color: ${Colors.White};
  height: 16px;
  width: 16px;
`
