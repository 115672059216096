import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import { Button, ButtonTheme } from 'builder/components/Button'
import { TextAreaField } from 'builder/components/TextField'
import Icon24 from 'builder/components/Icon'
import { Revert, TryAnotherLink } from '../LinkedInFlow/styles'

const { Neutral10, Neutral90, Neutral50, Blue50, Blue60 } = Colors

export const Div = styled.div``
export const Span = styled.span``
export const LeftAlign = styled.div`
  text-align: left;
`

export const QuestionnaireStep = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-grow: 1;
`
export const StepHeading = styled.div<{ isStepWithTabs: boolean }>(({ isStepWithTabs }) => {
  return css`
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: ${isStepWithTabs ? 16 : 40}px;
    text-align: center;
  `
})

export const StepTitle = styled.p`
  ${Typography.M};
  ${FontWeights.DemiBold};
  color: ${Neutral90};
`

export const StepDescription = styled.p`
  ${Typography.Body};
  color: ${Neutral50};
`

export const ActionBar = styled.div<{ onlyShowPrimaryButton: boolean; marginTop?: number }>(
  ({ onlyShowPrimaryButton, marginTop = 32 }) => {
    return css`
      display: flex;
      flex-direction: row;
      justify-content: ${onlyShowPrimaryButton ? 'center' : 'space-between'};
      width: 100%;
      margin-top: ${marginTop}px;
    `
  },
)

export const NextStepAction = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  ${Media.Phone`
    width: 100%;
    flex-flow: column-reverse;
    gap: 16px;
  `}
`
export const SkipButton = styled.button`
  ${Typography.Body}
  ${FontWeights.Medium}
  display: inline-flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  text-align: center;
  padding: 12px 16px;
  border: none;
  &:hover,
  &:focus-visible {
    color: ${Blue50};
  }
`

export const ContinueButton = styled(Button).attrs({
  theme: ButtonTheme.alternative,
})(({ isDisabled }) => {
  return css`
    ${Typography.Body}
    ${FontWeights.Medium}
    background: ${isDisabled ? Colors.Neutral20 + ' !important' : ''}
  `
})

export const ProgressContainer = styled.div`
  width: 100%;
  margin-top: auto;
`

// ----- Voice input screen styles - for Past Accomplishments and Future Goals steps ----

export const VoiceInputStaticImage = styled.img`
  width: 242px;
  height: 242px;
  ${Media.Phone`
    width: 225px !important;
    height: 197px;
  `}
`
export const VoiceInputAnimation = styled(VoiceInputStaticImage)`
  width: 276px;
`

export const HorizontalStack = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
`

export const VerticalStack = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const VoiceInputTimer = styled.p`
  ${Typography.Body}
  font-weight: 500;
`

export const RecordingStartedText = styled.p`
  ${Typography.Body}
  margin-top: 4px;
  color: ${Colors.Neutral50};
  ${Media.AbovePhone`
    margin-top: 8px;
  `}
`

export const RecordingErrorIcon = styled(Icon24.RedWarning)`
  margin-right: 4px;
`

export const RecordingErrorText = styled.p`
  ${Typography.Body}
  color: ${Colors.Red50};
  text-align: center;
`

export const VoiceInputTimerContainer = styled.div`
  display: flex;
  align-items: center;
  ${Media.AbovePhone`
    margin-top: 16px;
  `}
  ${Media.Phone`
    margin-bottom: 16px;
  `}
`

export const RetakeButton = styled(TryAnotherLink)`
  color: ${Neutral90};
  padding: 8px;
  margin-right: 4px;
`

export const RetakeIcon = styled(Revert)`
  color: ${Neutral90};
`

// ----- Text input screen styles - for Past Accomplishments and Future Goals steps ----

export const TextAreaContainer = styled(TextAreaField)`
  margin-top: 32px;
  width: 100%;
`

// -------------- Styles common to WorkExperience and Education Steps ---------------------- //

export const CardContainer = styled.div`
  width: 100%;
  ${Media.Phone`
    margin-top: -20px;
  `}
`

export const CardWrapper = styled.div<{ withTopMargin: boolean }>`
  margin-top: ${props => (props.withTopMargin ? '16px' : '0px')};
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const CollapsedCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 4px;
  border-radius: 8px;
  border: 2px solid ${Neutral10};
`

export const CardTitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${Typography.Body};
  color: ${Neutral90};
`

export const CardDurationRow = styled.div`
  ${Typography.Caption};
  color: ${Neutral50};
  text-align: left;
`

export const CardEditIconContainer = styled.div`
  &:hover {
    cursor: pointer;
    & path {
      stroke: ${Blue60};
    }
  }
`

export const CardDeleteIconContainer = styled.div`
  height: 16px;
  text-align: end;
  & svg:hover {
    cursor: pointer;
  }
`
