import { useEffect, useState } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useDispatch, useSelector } from 'react-redux'
import { EWidgetsName, IWidgetsInfo, actions, selectors } from 'builder/modules/panel'
import { actions as eventActions } from 'builder/modules/events'
import { selectors as interviewSelector } from 'builder/modules/interview/interviewModule'
import { useQueryParam } from 'builder/hooks/useQueryParam'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { APP_BASE_PATH } from 'builder/modules/constants'
import FloatingCIOHelperButton from '../../FloatingCIOHelperButton'
import * as Styles from './styles'
import { PremierVirtualLabelData } from './constants'

const VIRTUAL_EVENTS_LABEL = 'virtual_events'
const PVHelperButton = () => {
  const dispatch = useDispatch()
  const [isHelperModalOpen, setIsHelperModalOpen] = useState<boolean>(false)
  const [isUserLoggingForFirstTime, setIsUserLoggingForFirstTime] = useState<boolean>(false)
  const [queryParamValue] = useQueryParam('pv_event_id')
  const tunerResumeId = useTypedSelector(selectors.tunerResumeId)
  const interviewUrl = useSelector(interviewSelector.getInterviewUrl)

  const handleClose = () => {
    if (isUserLoggingForFirstTime) {
      setIsUserLoggingForFirstTime(false)
      dispatch(eventActions.setHelperAnimation(true))
      setTimeout(() => {
        setIsHelperModalOpen(false)
      }, 500)
    } else {
      setIsHelperModalOpen(false)
    }
  }

  useEffect(() => {
    if (queryParamValue) {
      setIsUserLoggingForFirstTime(true)
      setIsHelperModalOpen(true)
      trackInternalEvent('helper_modal_view', { label: VIRTUAL_EVENTS_LABEL })
      trackInternalEvent('registered_hiring_event', {
        label: VIRTUAL_EVENTS_LABEL,
        parameter: 'app',
        event_id: queryParamValue,
      })
    }
  }, [])

  useEffect(() => {
    if (!isUserLoggingForFirstTime && isHelperModalOpen) {
      trackInternalEvent('helper_corner_view', { label: VIRTUAL_EVENTS_LABEL })
    }
  }, [isHelperModalOpen, isUserLoggingForFirstTime])

  const onStepActionClick = (stepName: string) => {
    switch (stepName) {
      case 'build_resume':
        dispatch(actions.fetchDocuments())
        if (tunerResumeId) {
          trackInternalEvent('helper_cta_click', {
            label: VIRTUAL_EVENTS_LABEL,
            target: 'resume_builder',
            type: 'optimize_resume',
          })
          window.open(`${APP_BASE_PATH}/resumes/${tunerResumeId}/edit`, '_blank')
        } else {
          trackInternalEvent('helper_cta_click', {
            label: VIRTUAL_EVENTS_LABEL,
            target: 'resume_builder',
            type: 'create_resume',
          })
          dispatch(actions.createDocument({ type: 'resume', target: '_blank' }))
        }

        break
      case 'pitch':
        window.open(
          `${APP_BASE_PATH}/career-plans/execute-a-job-search?cp_top_level=2&cp_second_level=6`,
          '_blank',
        )
        trackInternalEvent('helper_cta_click', { label: VIRTUAL_EVENTS_LABEL, target: 'pitch' })
        break
      case 'interview_prep':
        window.open(`${APP_BASE_PATH}${interviewUrl}`, '_blank')
        trackInternalEvent('helper_cta_click', {
          label: VIRTUAL_EVENTS_LABEL,
          target: 'interviews',
        })
        break
      default:
        return null
    }
  }

  const TrackingEvents = (option: IWidgetsInfo) => {
    if (option.name === 'build_resume') {
      trackInternalEvent('helper_action_completed', {
        label: VIRTUAL_EVENTS_LABEL,
        target: 'resume_builder',
      })
    } else if (option.name === 'pitch') {
      trackInternalEvent('helper_action_completed', {
        label: VIRTUAL_EVENTS_LABEL,
        target: 'pitch',
      })
    } else if (option.name === 'interview_prep') {
      trackInternalEvent('helper_action_completed', {
        label: VIRTUAL_EVENTS_LABEL,
        target: 'interviews',
      })
    }
  }

  return (
    <Styles.FloatingButtonContainer>
      <FloatingCIOHelperButton
        widgetFetchCondition={true}
        setModalAsCentre={setIsUserLoggingForFirstTime}
        modalAsCentre={isUserLoggingForFirstTime}
        labelPayload={PremierVirtualLabelData}
        currentWidgetName={EWidgetsName.PREMIER_VIRTUAL}
        helperLabel={VIRTUAL_EVENTS_LABEL}
        {...{
          onStepActionClick,
          isHelperModalOpen,
          setIsHelperModalOpen,
          handleClose,
          TrackingEvents,
        }}
      />
    </Styles.FloatingButtonContainer>
  )
}

export default PVHelperButton
