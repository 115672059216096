import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
import Media from 'builder/styles/media'
import { Logo } from 'builder/components/Logo'
import Colors from 'builder/styles/colors'
import { Z_INDEX } from '../NavBar/styles'

export const Container = styled.nav`
  display: none;
  position: sticky;
  z-index: ${Z_INDEX};
  width: 100%;
  top: 0;
  left: 0;
  padding: 0 20px;
  justify-content: center;
  background-color: white;

  ${Media.Phone`
    display: flex;

    li {
      list-style-type: none;
    }
  `};
`

export const LogoLink = styled(RouterLink)`
  width: 100%;
  display: flex;
  transition: opacity 0.2s, visibility 0.2s;
  margin: 16px 0;
`

export const LogoStyled = styled(Logo)`
  width: 133px;
  height: 40px;
`

export const CustomLogo = styled.img`
  width: 133px;
  height: 40px;
`

export const ProfileWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 16px;
`

export const Profile = styled.div`
  display: flex;
  margin: 6px 0;
  padding: 8px 6px;
  border-radius: 12px;

  &:hover {
    background: ${Colors.Neutral5};
  }
`
