import { Fragment } from 'react'
import PropTypes from 'prop-types'
import trim from 'lodash/trim'
import { useI18n } from 'builder/hooks/useI18n'
import { useDispatch } from 'react-redux'
import { CompanyAutosuggest } from 'builder/components/CompanyAutosuggest'
import { useFields } from 'builder/hooks/useFields'
import { SnackbarTypes, actions } from 'builder/modules/ui'

import { Container, Input, Actions, SaveButton, CancelButton } from './styles'

const QuickAdd = ({ onSubmit, onCancel, columns }) => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()

  const emptyCard = {
    company: '',
    logo: '',
    location: '',
    link: '',
  }
  const [fields, handleUpdate] = useFields(emptyCard)

  const handleSuggestionSelect = (e, { suggestion }) => {
    for (const name in suggestion) {
      const target = { name, value: suggestion[name] }
      handleUpdate({ target })
    }
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (trim(fields.company).length) {
      onSubmit(fields)
    } else {
      dispatch(
        actions.setSnackBarOpen({
          status: true,
          type: SnackbarTypes.failure,
          text: i18n.t('builder.job_tracking.quick_add.error'),
        }),
      )
      setTimeout(() => {
        dispatch(
          actions.setSnackBarOpen({
            status: false,
            type: SnackbarTypes.failure,
            text: '',
          }),
        )
      }, 1000)
    }
  }

  return (
    <Container onSubmit={handleSubmit}>
      <CompanyAutosuggest
        value={fields.company}
        onChange={handleUpdate}
        onSuggestionSelected={handleSuggestionSelect}
      >
        {inputProps => (
          <Fragment>
            <Input
              {...inputProps}
              placeholder={i18n.t('builder.job_tracking.quick_add.company_placeholder')}
              autoFocus
            />
          </Fragment>
        )}
      </CompanyAutosuggest>

      <Actions>
        <SaveButton type="submit">{i18n.t('builder.job_tracking.quick_add.submit')}</SaveButton>
        <CancelButton type="button" onClick={onCancel}>
          {i18n.t('builder.job_tracking.quick_add.cancel')}
        </CancelButton>
      </Actions>
    </Container>
  )
}

QuickAdd.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
}

export default QuickAdd
