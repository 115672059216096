import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import BaseButton from 'builder/components/Button'
import Media from 'builder/styles/media'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  padding: 40px 48px 84px;
  background-color: ${Colors.Neutral5};
  width: fit-content;
  height: fit-content;
  min-width: 386px;

  &.job-search {
    background-color: ${Colors.Green10};
    padding-bottom: 45px;
  }

  ${Media.Tablet`
    padding: 40px 20px 32px;

    &.job-search {
      padding-top: 40px;
    }
  `};

  ${Media.Phone`
    max-width: 335px;
    width: 100%;
    padding: 20px;

    &.job-search {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    align-items: flex-start;
  `};
`

export const Title = styled.p`
  ${Typography.S};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};

  &.job-search {
    color: ${Colors.Green80};
  }

  ${Media.Tablet`
    ${Typography.Body};
    order: 2;
  `};

  ${Media.Phone`
    ${Typography.Subhead};
  `};
`

export const Subtitle = styled.p`
  ${Typography.Body};
  color: ${Colors.Neutral90};
  margin-top: 8px;
  text-align: center;
  max-width: 448px;

  &.job-search {
    color: ${Colors.Green80};
    margin-top: 8px;
  }

  ${Media.Tablet`
    ${Typography.Caption};
    order: 3;
    margin-top: 8px;
  `};

  ${Media.Phone`
    ${Typography.Body};
    margin-top: 8px;
    text-align: start;
  `};
`

export const Button = styled(BaseButton)`
  margin-top: 16px;
  ${Typography.Caption};
  ${FontWeights.Medium};
  padding: 8px 12px;

  &.job-search {
    margin-top: 16px;
  }

  ${Media.Tablet`
    order: 4;
    margin-top: 42px;
  `};

  ${Media.Phone`
    margin-top: 48px;
    &.job-search {
      margin-top: 58px;
    }
  `};
`
