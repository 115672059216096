import { memo, useEffect, useMemo, useRef } from 'react'
import { Transition } from 'react-transition-group'
import { ContentBlock, EditorState } from 'draft-js'
import Editor from '@draft-js-plugins/editor'
import { Optional } from '@rio/types'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import { RichTextAreaContext } from 'builder/context/RichTextAreaContext'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import Colors from 'builder/styles/colors'
import { clampNumber } from 'builder/utils/clampNumber'
import {
  AIGeneratedDetails,
  AnimationWrapper,
  Bar,
  EditorWrapper,
  Group,
  Label,
  RelativeDiv,
  TextArea,
} from './styles'
import RichTextToolbar from './RichTextToolbar'
import createSpellcheckPlugin from './spellCheckPlugin'
import { findWithRegex } from './spellCheckPlugin/searchMistake'
import TextAreaProgress from './TextAreaProgress'
import TextAreaProgressRange from './TextAreaProgressRange'
import { DecoratorCallback, IncorrectWord, Ranges, SuggestionConfig } from './types'
import { useRichTextAreaActions } from './useRichTextAreaActions'
import createLinkPlugin from './linkPlugin'
import createListPlugin from './listPlugin'
import SpellCheckDropdown from './spellCheckPlugin/SpellCheckDropdown'
import { shouldUseAiPhrases, shouldUseAiProfileSummary } from './utils'

export interface Props {
  autoFocus?: boolean
  onChange: ((value: string) => void) | ((event: React.ChangeEvent) => void)
  suggestionConfig?: Optional<SuggestionConfig>
  label?: string
  value?: string
  height?: number
  locale?: string
  jobTitle?: Optional<string>
  supportedLocales?: string[]
  boldButtonDisable?: boolean
  italicButtonDisable?: boolean
  linkButtonDisable?: boolean
  disableToolbar?: boolean
  placeholder?: string
  recommendedLength?: number
  ranges?: Ranges
  recommendedRangeLabel?: string
  isAnimating?: boolean
  onAnimationEnd?: () => void
}

const linkPlugin = createLinkPlugin()
const listPlugin = createListPlugin()

const RichTextArea = (props: Props) => {
  const {
    jobTitle = '',
    suggestionConfig,
    height = 200,
    disableToolbar,
    label = '',
    locale = 'en',
    boldButtonDisable = false,
    linkButtonDisable = false,
    italicButtonDisable = false,
    placeholder = '',
    recommendedLength,
    ranges,
    recommendedRangeLabel = '',
    isAnimating,
    value = '',
  } = props

  const {
    animationPlaceholderRef,
    editorId,
    editorRef,
    editorState,
    isFocused,
    isHintsOpened,
    isSpellCheckerEnabled,
    isSpellCheckerPending,
    isSpellingChecked,
    suggestionQuery,
    textLength,
    shouldHidePlaceholder,
    isSuggestionsOpen,
    focusEditor,
    onChangeSuggestionQuery,
    handleChange,
    handleOpenHints,
    handleCloseHints,
    handleToggleInline,
    handleToggleBlock,
    handleToggleSpellChecker,
    handleFocus,
    handleBlur,
    handleKeyBinding,
    handleKeyCommand,
    supportsLocale,
    toggleSuggestions,
    toggleAiProfileSummary,
    toggleAiProfileSummaryResult,
    setEditorState,
    isSuggestionVariantsOpen,
    toggleSuggestionVariants,
  } = useRichTextAreaActions(props)

  const editorContainerRef = useRef<HTMLDivElement>(null)

  const highlightWordsStrategy = (contentBlock: ContentBlock, callback: DecoratorCallback) => {
    const ignoredWordsFromStorage = localStorage.getItem('SPELL_CHECKER_IGNORED_WORDS')
    const incorrectWordsFromStorage = localStorage.getItem('SPELL_CHECKER_INCORRECT_WORDS')
    const editorsWithSpellCheckFromStorage = localStorage.getItem('EDITORS_WITH_SPELL_CHECK')

    const ignoredWords = new Set(ignoredWordsFromStorage ? JSON.parse(ignoredWordsFromStorage) : [])
    const incorrectWords: IncorrectWord[] = incorrectWordsFromStorage
      ? JSON.parse(incorrectWordsFromStorage)
      : []
    const editorsWithSpellCheck: string[] = editorsWithSpellCheckFromStorage
      ? JSON.parse(editorsWithSpellCheckFromStorage)
      : []
    // Use local storage to disable the highlighting of the incorrect words
    if (!editorsWithSpellCheck.includes(editorId.current)) return
    incorrectWords.forEach(item => {
      if (ignoredWords.has(item.word)) return
      const WRONG_SPELLING_REGEXP = new RegExp(item.word, 'g')
      findWithRegex(WRONG_SPELLING_REGEXP, contentBlock, callback)
    })
  }
  const spellCheckPlugin = createSpellcheckPlugin(highlightWordsStrategy)

  const { isPhone, isTablet } = useMediaQueries()

  const isSpellCheckerAvailable = !(isPhone || isTablet)
  const isAiPhrases = shouldUseAiPhrases(suggestionConfig?.scope, locale)
  const isAiProfileSummary = shouldUseAiProfileSummary(suggestionConfig?.scope, locale)

  const showRecommendedLengthProgressBar = recommendedLength && !ranges
  const showRecommendedRangeProgressBar = !recommendedLength && ranges

  const barScale = useMemo(() => {
    if (textLength && recommendedLength) {
      return clampNumber(textLength / recommendedLength, 0, 1)
    } else if (textLength && ranges) {
      return clampNumber(textLength / ranges.good.start, 0, 1)
    }

    return 1
  }, [ranges, recommendedLength, textLength])

  const barColor = useMemo(() => {
    if (textLength === 0) return Colors.Blue50

    if (recommendedLength) {
      if (textLength / recommendedLength >= 1) return Colors.Green50
      if (textLength / recommendedLength > 0.33) return Colors.Amber40
      return Colors.Orange40
    } else if (ranges) {
      const { good, bad } = ranges
      if (textLength >= bad.start && textLength <= bad.end) return Colors.Red40
      if (textLength >= good.start && textLength <= good.end) return Colors.Green40
      return Colors.Amber40
    }

    return Colors.Blue50
  }, [ranges, recommendedLength, textLength])

  const contextValue = {
    jobTitle,
    isSuggestionsOpen,
    toggleSuggestions,
    toggleAiProfileSummary,
    toggleAiProfileSummaryResult,
    suggestionQuery,
    onChangeSuggestionQuery,
    openHints: handleOpenHints,
    closeHints: handleCloseHints,
    isFocused,
    isHintsOpened,
    hideHintsButtons: () => {},
    isHintsButtonsVisible: false,
    isSuggestionVariantsOpen,
    toggleSuggestionVariants,
  }

  // sets new value if it is updated from outside of component
  useEffect(() => {
    const currentValue = stateToHTML(editorState.getCurrentContent())

    if (value !== currentValue) {
      const contentState = stateFromHTML(value)
      const newState = EditorState.createWithContent(contentState)
      setEditorState(newState)
    }

    // needs to react only on value change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <RichTextAreaContext.Provider value={contextValue}>
      <Group>
        <Label> {label} </Label>
        <TextArea {...{ height }}>
          {!disableToolbar && (
            <RichTextToolbar
              onChange={handleChange}
              editorState={editorState}
              onToggleInline={handleToggleInline}
              onToggleBlock={handleToggleBlock}
              focusEditor={focusEditor}
              onToggleSpellChecker={handleToggleSpellChecker}
              isSpellCheckerEnabled={isSpellCheckerEnabled}
              isSpellCheckerVisible={
                isSpellCheckerAvailable && supportsLocale(locale) && isSpellingChecked
              }
              isSpellCheckerPending={isSpellCheckerPending}
              suggestionConfig={suggestionConfig}
              locale={locale}
              boldButtonDisable={boldButtonDisable}
              italicButtonDisable={italicButtonDisable}
              linkButtonDisable={linkButtonDisable}
              isAiPhrases={isAiPhrases}
              isAiProfileSummary={isAiProfileSummary}
              editorContainerRef={editorContainerRef}
            />
          )}

          <RelativeDiv>
            {isAnimating && (
              <AnimationWrapper>
                <AIGeneratedDetails ref={animationPlaceholderRef} />
              </AnimationWrapper>
            )}
            <EditorWrapper isAnimated={!!isAnimating} ref={editorContainerRef}>
              <Editor
                placeholder={shouldHidePlaceholder ? undefined : placeholder}
                editorState={editorState}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                keyBindingFn={handleKeyBinding}
                handleKeyCommand={handleKeyCommand}
                plugins={[spellCheckPlugin, linkPlugin, listPlugin]}
                ref={editorRef}
              />
              <SpellCheckDropdown
                {...{
                  editorState,
                  handleChange,
                  setEditorState,
                  spellCheckEmitter: spellCheckPlugin.emitter,
                }}
              />
            </EditorWrapper>
          </RelativeDiv>

          <Transition in={isFocused} timeout={100}>
            {state => <Bar in={state} scale={barScale} bgColor={barColor} />}
          </Transition>
        </TextArea>

        {showRecommendedLengthProgressBar && (
          <TextAreaProgress length={textLength} recommendedLength={recommendedLength} />
        )}

        {showRecommendedRangeProgressBar && (
          <TextAreaProgressRange
            length={textLength}
            ranges={ranges}
            label={recommendedRangeLabel}
          />
        )}
      </Group>
    </RichTextAreaContext.Provider>
  )
}

export default memo(RichTextArea)
