import { FormEvent, useRef } from 'react'
import { SuggestionSelectedEventData } from 'react-autosuggest'
import { useFields } from 'builder/hooks/useFields'
import { TextField } from 'builder/components/TextField'
import Select from 'builder/components/Select'
import {
  ApiSection,
  AsyncAutosuggest,
  createNewSuggestionsApiFetcher,
} from 'builder/components/AsyncAutosuggest'
import { CareerOnboardFields } from './types'
import { Container, Header, Content, FieldContainer, List } from './styles'
import { timeZoneOptions } from './timeZone'

interface LocationProps {
  onLocationhandleChange: (field: string, value: string) => void
  onHandleTimeZoneChange: (timezone: string) => void
  onHandleLocationChange: (location: string) => void
  timezone: string
  location: string
}

const DEFAULT_COUNTRY = 'United States'

const Location = ({
  onLocationhandleChange,
  onHandleTimeZoneChange,
  onHandleLocationChange,
  timezone,
  location,
}: LocationProps) => {
  const [fields, handleUpdate] = useFields({
    location: '',
    initialValues: '',
  })

  const handleTimeZoneChange = (timezone: number) => {
    const selectedOption = timeZoneOptions.find(option => option.id === timezone)
    const timeZoneName = selectedOption ? selectedOption.name.split(':')[0].trim() : ''

    onHandleTimeZoneChange(timeZoneName)
  }

  // Persist recently selected values to prevent extra "type_career_occupation" event calls
  const selectedValuesRef = useRef<{ [key in keyof CareerOnboardFields]: string }>({ location: '' })

  const handleSuggestionSelected =
    (field: keyof CareerOnboardFields) =>
    (
      e: FormEvent<HTMLInputElement>,
      selected: SuggestionSelectedEventData<CareerOnboardFields>,
    ) => {
      const value = selected.suggestionValue
      selectedValuesRef.current[field] = value

      onHandleLocationChange(value)

      handleUpdate({
        target: {
          name: field,
          value,
        },
      })

      onLocationhandleChange(field, value)
    }

  const handleChange = (e: { type: string; target: { name: string; value: string } }) => {
    // Condition is need to cut off unnecessary events (e.g. onClick)
    if (e.type === 'change' && e?.target) {
      handleUpdate({ target: { name: e.target.name, value: e.target.value } })
      if (e.target.name === 'location') {
        onLocationhandleChange('location', e.target.value)
      }
    }
  }

  // eslint-disable-next-line array-callback-return
  const selectedTimeZone = timeZoneOptions.find(
    item => item?.name?.split(':')[0]?.trim() === timezone,
  )

  return (
    <Container>
      <Content>
        <Header>Where are you located?</Header>
        <List>
          <FieldContainer>
            <Select
              label="Time Zone"
              options={timeZoneOptions}
              selected={selectedTimeZone?.id || 0}
              onSelect={handleTimeZoneChange}
            />
          </FieldContainer>
          <FieldContainer>
            <AsyncAutosuggest<CareerOnboardFields>
              name="location"
              fetchItems={createNewSuggestionsApiFetcher('locations', {
                section: ApiSection.CareerPages,
                country: DEFAULT_COUNTRY,
              })}
              value={fields.location || location}
              onChange={handleChange}
              onSuggestionSelected={handleSuggestionSelected('location')}
            >
              {inputProps => (
                <TextField {...inputProps} label="Location" placeholder="New York, NY" />
              )}
            </AsyncAutosuggest>
          </FieldContainer>
        </List>
      </Content>
    </Container>
  )
}
export default Location
