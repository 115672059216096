import { useMemo } from 'react'
import styled, { css } from 'styled-components'
import {
  Cards,
  PromotionTopic,
} from 'builder/components/SubscriptionCancellationFormNew/common/types'
import { getLineHeightRatio } from 'builder/components/SubscriptionCancellationFormNew/common/utils'
import usePromotionCardValues from 'builder/components/SubscriptionCancellationFormNew/hooks/usePromotionCardValues'
import { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

interface Props {
  topic: PromotionTopic
}

export const PromotionTitle = styled.p<Props>(({ topic }) => {
  const { getCardStyles } = usePromotionCardValues()
  const cardStyles = getCardStyles(topic)
  const conditionalStyles = useMemo(() => {
    switch (topic) {
      case Cards.FeatureOrProblem:
        return css`
          ${Media.Tablet`
              margin-top: 32px;
          `}
          ${Media.Phone`
              margin-top: 20px;
          `}
        `
      case Cards.OtherReasons:
        return css`
          ${Media.Tablet`
              margin-top: 32px;
          `}
          ${Media.Phone`
              margin-top: 20px;
          `}
        `
      default:
        return css``
    }
  }, [topic])

  return css`
    ${FontWeights.DemiBold};
    font-size: 27px;
    line-height: ${getLineHeightRatio(28, 27)};
    color: ${cardStyles.noticeTextColor};
    ${conditionalStyles}

    ${Media.Tablet`
        margin-top: 24px;
        text-align: center;
    `}

    ${Media.Phone`
        margin-top: 16px;
        font-size: 23px;
        line-height: ${getLineHeightRatio(24, 23)};
        text-align: center;
    `}
  `
})
