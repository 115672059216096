import styled from 'styled-components'

import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { MenuItemContent } from 'builder/components/Menu'
import { Badge } from 'builder/components/Badge'

export const PendingItemsContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: 12px;
  margin: 8px 20px 16px 20px;
  padding: 16px;
  border-radius: 16px;
  background: ${Colors.Neutral5};
`

export const ProfileInfo = styled.div`
  ${Typography.Caption}
  ${FontWeights.Regular}
`

export const PendingItem = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

export const PendingItemText = styled.div`
  color: ${Colors.Neutral50};
  ${Typography.Caption}
  ${FontWeights.Medium}
`

export const BadgePercentage = styled(Badge)`
  border-radius: 4px;
`

export const MenuItem = styled.div``
export const MenuItemCont = styled(MenuItemContent)`
  padding: 12px;
  color: ${Colors.Neutral90};
  ${Typography.Body}
  ${FontWeights.Regular}
`
