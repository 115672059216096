import { useEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import CareerCoachingBanner from 'builder/components/CareerCoachingBanner'
import CareerCoachingMainPage from 'builder/components/CareerCoachingMainPage/CareerCoachingMainPage'

import { trackingInfo } from 'builder/modules/careerCoaching/constants'
import { actions, selectors } from 'builder/modules/careerCoaching'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { Container } from './styles'

const CoachMarketPlaceView = (): JSX.Element => {
  const dispatch = useDispatch()
  const location = useLocation()
  const intakeTestDetails = useTypedSelector(selectors.intakeTestDetails)

  useEffect(() => {
    const storagePreferences = localStorage.getItem('INTAKE_PREFERENCES')
    const preferences = storagePreferences ? JSON.parse(storagePreferences) : null

    dispatch(actions.fetchIntakeTestDetails())

    if (preferences || intakeTestDetails?.id) {
      dispatch(actions.fetchRedirectRoutes({ currentUrl: location.pathname }))
      dispatch(actions.setUserRedirectState(false))
    }
  }, [dispatch, intakeTestDetails?.id, location.pathname])

  useEffect(
    () =>
      trackInternalEvent('enter_coaching_main_page', {
        ...trackingInfo,
        plan_name: 'coach_marketplace',
      }),
    [],
  )

  // Load embed script
  useEffect(() => {
    const script = document.createElement('script')
    script.src = '//embed.typeform.com/next/embed.js'
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <Container>
      <CareerCoachingBanner />
      <CareerCoachingMainPage />
    </Container>
  )
}

export default CoachMarketPlaceView
