import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'

export const VisualContainer = styled.div`
  position: absolute;
  width: 268px;
  height: 214px;
  left: 0px;
  top: 0px;

  ${Media.Phone`
    width: 242px;
    height: 212px;
  `}
`

export const VisualImageContainer = styled.div`
  position: absolute;
  width: 244px;
  height: 212px;
  right: 15px;
  z-index: 1;

  ${Media.Phone`
    right: 0px;
  `}
`

export const VisualImage = styled.img`
  width: 100%;
  height: 100%;
`

export const CritiqueContainer = styled.ul`
  position: absolute;
  top: 0;
  right: 0;
  list-style-type: none;
  padding: 0;
  margin: 0;
  top: 48px;
  right: 22px;
`

export const CritiqueListItem = styled.li`
  display: flex;
  padding: 4px;
  align-items: center;
  margin-bottom: 4px;
  border-radius: 24px;
  background-color: ${Colors.White};
`
export const CritiqueCheck = styled.img``

export const CritiqueText = styled.span`
  ${Typography.Tiny}
  padding-left: 4px;
`
