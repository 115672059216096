import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import { Section } from '../../styles'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;

  ${Media.Tablet`
    gap: 16px;
  `}

  ${Media.Phone`
    grid-template-columns: 1fr;
    gap: 12px;
  `}
`

export const Image = styled.img`
  width: 100%;
`

export const HeaderSection = styled(Section)`
  background-color: ${Colors.Blue10};
  color: ${Colors.Blue90};
  grid-column: 1 / span 2;

  ${Media.Phone`
    grid-column: 1;
  `}
`

export const HeaderImage = styled.img`
  width: 300px;

  ${Media.Tablet`
    width: 180px;
  `}

  ${Media.Phone`
    width: 134px;
  `}
`
