const ExpandSvg = ({ fillColor }: { fillColor: string }) => {
  return (
    <svg width="7" height="9" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.41421 4.75736L0.585786 1.92893L2 0.514719L6.24264 4.75736L2 9L0.585786 7.58579L3.41421 4.75736Z"
        fill={fillColor}
      />
    </svg>
  )
}

export default ExpandSvg
