import { i18n } from 'builder/utils/i18n'
import { useUser } from 'builder/hooks/useUser'
import { billingPlanPageURL, goToBillingPlanPage } from 'builder/utils/goToBillingPlanPage'
import * as Styles from './styled'

type PremiumButtonProps = React.ComponentProps<typeof Styles.Wrapper>

export const PremiumButton = ({ ...rest }: PremiumButtonProps) => {
  const user = useUser()

  return (
    <Styles.Wrapper
      onClick={() => {
        if (user) {
          goToBillingPlanPage({ upgradeURL: user?.billingInfo.upgradeUrl })
        }
      }}
      href={user ? billingPlanPageURL({ upgradeURL: user?.billingInfo.upgradeUrl }) : null}
      {...rest}
    >
      <Styles.Icon />

      {i18n.t('builder.navbar.upgrade_now_button')}
    </Styles.Wrapper>
  )
}
