import { Card } from '../styles'

type CardWrapperProps = {
  children: React.ReactNode
}

const CardWrapper = ({ children }: CardWrapperProps) => {
  return <Card>{children}</Card>
}

export default CardWrapper
