import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'

export const ProgressbarContainer = styled.div``

export const ProgressWizard = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
`

export const ProgressItem = styled.li`
  display: flex;
  align-items: center;
`

export const Count = styled.div<{
  isActive?: boolean
}>`
  ${Typography.Caption};
  ${FontWeights.DemiBold};
  background-color: ${props => (props.isActive ? Colors.Blue50 : Colors.Neutral20)};
  color: ${props => (props.isActive ? Colors.White : Colors.Neutral50)};
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 12px;
`

export const Title = styled.div``

export const Divider = styled.div`
  background-color: ${Colors.Neutral15};
  width: 32px;
  height: 1px;
  margin: 0 8px;
`
