import styled, { createGlobalStyle } from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'

export const Container = styled.div<{ withImage: boolean }>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  background-color: ${({ withImage }) => (withImage ? Colors.White : Colors.Neutral10)};
  overflow: hidden;
  transition: background-color 0.4s ease;
`

export const Navbar = styled.div`
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 100%;
  padding: 22px 32px;
  z-index: 100;

  ${Media.Phone`
    height: 46px;
    padding: 0 20px;
  `};
`

export const StepsWrapper = styled.div`
  position: absolute;
  width: 700px;
  left: calc(50% - 350px);
  display: flex;
  align-items: center;
  justify-content: center;

  ${Media.Tablet`
    display: none;
  `};
`

export const Main = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const CloseButton = styled.a`
  width: 24px;
  height: 24px;
  color: ${Colors.Neutral30};
  cursor: pointer;
  transition: color 0.2s ease;

  &:hover {
    color: ${Colors.Blue50};
  }
`

// Hide Helpscout Beacon on this page
export const GlobalStyle = createGlobalStyle`
  #beacon-container {
    display: none;
  }
`
