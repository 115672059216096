import { useState } from 'react'
import { Options } from './types'
import { Container } from './styles'

const ShowMoreText = ({ more = ' ... more', charLimit, text }: Options) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const showOption = text.length > charLimit && !isExpanded

  return (
    <Container onClick={() => setIsExpanded(true)}>
      {isExpanded ? text : text.substring(0, charLimit)}
      {showOption && <span>{more}</span>}
    </Container>
  )
}

export default ShowMoreText
