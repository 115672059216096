import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const OptimizeScoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 53px;
  height: 68px;
`
export const Score = styled.div<{ bgColor: string }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 2px 6px;
  gap: 10px;
  background: ${props => props.bgColor};
  border-radius: 6px;
  margin-bottom: 8px;
  color: ${Colors.White};
`

export const Text = styled.div`
  height: 32px;
  width: 53px;
  ${Typography.Tiny};
  ${FontWeights.DemiBold};
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  color: ${Colors.Blue80};
  opacity: 0.6;

  ${Media.Phone`
    top: -3px;
    position: relative;
  `}
`
