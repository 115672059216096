import { UserDocumentTemplate } from 'packages/types'

const greekTemplate = [
  'london',
  'vienna',
  'moscow',
  'santiago',
  'singapore',
  'cape_town',
  'paris',
  'geneva',
  'vancouver',
  'milan',
  'copenhagen',
]

//  To filter the template list while locale is greek
export const filterGreekTemplates = (templates: UserDocumentTemplate[], locale: string | null) => {
  const isGreekLocale = locale === 'gr'
  return isGreekLocale
    ? templates.filter((template: UserDocumentTemplate) => greekTemplate.includes(template.id))
    : templates
}
