import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const NewTag = styled.div<{ color?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: absolute;
  width: 45px;
  height: 24px;
  ${FontWeights.Medium};
  ${Typography.Caption};
  left: 20px;
  top: 20px;
  background: ${props => props.color || Colors.Green40};
  border-radius: 6px;
  color: white;
`
