import { getMediaRecorder } from './getMediaRecorder'

export function getMimeType(stream: MediaStream, media: 'audio' | 'video'): Promise<string> {
  return new Promise(resolve => {
    const mediaRecorder = getMediaRecorder(stream, media)
    mediaRecorder.ondataavailable = event => resolve(event.data.type)
    mediaRecorder.start()
    setTimeout(() => mediaRecorder.stop(), 200)
  })
}
