import { useI18n } from 'builder/hooks/useI18n'
import * as Styled from './styles'

export const ComingSoonTag = () => {
  const { i18n } = useI18n()

  return (
    <Styled.ComingSoonTagContainer>
      <Styled.ComingSoonTag>
        {i18n.t('builder.dashboard.service_data.comming_soon')}
      </Styled.ComingSoonTag>
    </Styled.ComingSoonTagContainer>
  )
}
