/* eslint-disable @typescript-eslint/no-unused-vars */
import { useNavigate } from 'react-router-dom'
import { useForm, useFormContext } from 'react-hook-form'
import { trackInternalEvent } from '@rio/tracking'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import * as SimpleForm from 'builder/components/SimpleForm'
import { useI18n } from 'builder/hooks/useI18n'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { AutoApplyForm } from 'builder/modules/autoApply/types'
import { selectors } from 'builder/modules/autoApply/autoApplyModule'
import { Routes, TotalPageCount, StartCompleteScreenRoute } from '../../constants'
import { Container, FillWarning, LayoutContainer, Title, TitleWrapper } from '../styles'
import { ConfirmModal } from '../ConfirmModal/ConfirmModal'
import { usePatchForm } from '../../hooks/usePatchForm'
import { usePrompt } from '../../hooks/usePrompt'
import { FormContainer } from './styles'
import { JobPreferencesForm1 } from './JobPreferencesForm1'

const TRANSLATION = 'builder.auto_apply.form.job_preferences_1'

export const JobPreferencesForm1Container = () => {
  const formState = useSelector(selectors.form)
  const form = useForm<Pick<AutoApplyForm, 'targetRole' | 'jobPreferenceAttributes'>>({
    mode: 'onChange',
  })

  useEffect(() => {
    const { targetRole, jobPreferenceAttributes } = formState
    form.setValue('targetRole', targetRole)
    form.setValue('jobPreferenceAttributes', jobPreferenceAttributes)
  }, [form, formState])

  const { i18n } = useI18n()
  const navigate = useNavigate()
  const { features } = useFeaturesConfig()
  const { mutateAsync: patchForm, isLoading } = usePatchForm()
  const { isPromptVisible, handleCancel, handleConfirm } = usePrompt({
    when: true,
    ignoreParentRoute: ['auto-apply'],
  })

  const onNextStep = async () => {
    await patchForm(form.getValues())

    navigate(`/auto-apply/${Routes.JOB_PREFERENCES_2}`)
  }

  const onBackClick = () => {
    form.clearErrors()
    navigate(`/auto-apply/${Routes.CONTACT_INFO}`)
  }

  useEffect(() => {
    trackInternalEvent('aa_job_preferences_title', {
      label: 'total_job_search_solution',
    })
  }, [])

  return (
    <LayoutContainer>
      <Container>
        <TitleWrapper>
          <Title>{i18n.t(`${TRANSLATION}.title`)}</Title>
          <FillWarning>{i18n.t(`${TRANSLATION}.sub_title`)}</FillWarning>
        </TitleWrapper>
        <FormContainer>
          <SimpleForm.Form
            submitButtonText={i18n.t(`${TRANSLATION}.next`)}
            onBackClick={onBackClick}
            onSubmit={form.handleSubmit(onNextStep)}
            isPending={isLoading}
            processIndicator={{
              totalStep: TotalPageCount,
              currentStep: 3,
            }}
          >
            <JobPreferencesForm1 form={form} />
          </SimpleForm.Form>
        </FormContainer>
      </Container>
      {isPromptVisible && <ConfirmModal onClose={handleCancel} onConfirm={handleConfirm} />}
    </LayoutContainer>
  )
}
