import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { formatDocumentPreviewUrl } from 'builder/utils/formatDocumentPreviewUrl'
import { useResume } from 'builder/hooks/useResume'
import { actions } from 'builder/modules/resumeEditor'
import Logo from 'builder/components/DocumentEditor/JobPosting/Images/CompanyLogo.svg'
import {
  Container,
  CompanyLogo,
  CompanyDesignation,
  DataWrapper,
  LeftContainer,
  Progress,
  ProgressInner,
  ResumePreview,
  Hostname,
  EditIcon,
  Wrapper,
} from './styles'

interface Props {
  resumeId: number
  tailoredDesignation: string
  hostName: string
}

const AICVDataResultView = ({ resumeId, tailoredDesignation, hostName }: Props) => {
  const resume = useResume(resumeId)
  const dispatch = useDispatch()
  const previewImageSrc = useMemo(() => {
    if (!resume) return null

    return formatDocumentPreviewUrl(resume, { size: 352 })
  }, [resume])

  const handleEditJobDetails = useCallback(() => {
    dispatch(actions.setIsClickedOnAiCVEditModalIcon(true))
    dispatch(actions.setOpenAICVOptimizerModal(false))
    dispatch(actions.setIsClickedOnAiCVBanner(false))
    trackInternalEvent('click_edit_job_posting', { label: 'job_posting_result_screen' })
  }, [dispatch])

  return (
    <Container>
      <LeftContainer>
        <CompanyLogo src={Logo} />
        <DataWrapper>
          <Wrapper>
            <CompanyDesignation>{tailoredDesignation}</CompanyDesignation>
            <EditIcon onClick={handleEditJobDetails} />
          </Wrapper>
          {hostName && <Hostname>{hostName}</Hostname>}
        </DataWrapper>
      </LeftContainer>
      <Progress>
        <ProgressInner />
      </Progress>
      <ResumePreview src={previewImageSrc} />
    </Container>
  )
}

export default AICVDataResultView
