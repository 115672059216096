import React, { Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { actions, selectors } from 'builder/modules/offerAnalyzer'
import { useI18n } from 'builder/hooks/useI18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import Select from 'builder/components/Select'
import { TextFieldWrapper } from '../JobOffer/styles'
import { Row, Field } from './styles'
import {
  getDegreeNameById,
  getDegreeIdByName,
  eduDegreeOptionsForOfferAnalyzer,
} from './eduDegreeOptions'
import {
  getExperinceNameById,
  getExperinceIdByName,
  yearsOfExperienceOptionsForOfferAnalyzer,
} from './yearsOfExperienceOptions'

const JobOfferProComDetails = () => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()

  const { educationalQualification, yearsOfExperience, baseSalary, additionalIncentives, equity } =
    useTypedSelector(selectors.fields)
  const errors = useTypedSelector(selectors.errors)

  const setFieldValue = (
    name: 'educationalQualError' | 'workExpError' | 'baseSalaryError',
    keyValue: 'baseSalary' | 'educationalQualification' | 'yearsOfExperience',
    value: string | number,
  ) => {
    dispatch(actions.setErrors({ ...errors, [name]: '' }))
    dispatch(actions.updateFields({ [keyValue]: value }))
  }

  const isValidNumber = (value: string) => {
    const reg = /^[0-9,.$]+$/
    if (value === '' || reg.test(value)) return true
    return false
  }

  const onSalaryChange = (value: string) => {
    if (isValidNumber(value)) {
      setFieldValue('baseSalaryError', 'baseSalary', value)
    }
  }

  const onIncentiveChange = (value: string) => {
    if (isValidNumber(value)) {
      dispatch(actions.updateFields({ additionalIncentives: value }))
    }
  }

  return (
    <Fragment>
      <Field>
        <Select
          label={i18n.t('builder.offer_analyzer.education_level')}
          options={eduDegreeOptionsForOfferAnalyzer}
          error={errors.educationalQualError}
          onSelect={(value: number) =>
            setFieldValue(
              'educationalQualError',
              'educationalQualification',
              getDegreeNameById(value),
            )
          }
          selected={getDegreeIdByName(educationalQualification)}
        />
      </Field>

      <Row style={{ padding: 0 }}>
        <Field>
          <Select
            label={i18n.t('builder.offer_analyzer.work_experience')}
            options={yearsOfExperienceOptionsForOfferAnalyzer}
            error={errors.workExpError}
            onSelect={(value: number) =>
              setFieldValue('workExpError', 'yearsOfExperience', getExperinceNameById(value))
            }
            selected={getExperinceIdByName(yearsOfExperience)}
          />
        </Field>

        <Field>
          <TextFieldWrapper
            type="text"
            error={errors.baseSalaryError}
            label={i18n.t('builder.offer_analyzer.base_salary')}
            placeholder={i18n.t('builder.offer_analyzer.salary_input_placeholder')}
            value={baseSalary}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              onSalaryChange(e.currentTarget.value)
            }}
          />
        </Field>
      </Row>

      <Row style={{ marginBottom: '40px' }}>
        <Field>
          <TextFieldWrapper
            type="text"
            label={i18n.t('builder.offer_analyzer.cash_bonus')}
            placeholder={i18n.t('builder.offer_analyzer.bonus_input_placeholder')}
            value={additionalIncentives}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              onIncentiveChange(e.currentTarget.value)
            }}
          />
        </Field>

        <Field>
          <TextFieldWrapper
            min={0}
            label={i18n.t('builder.offer_analyzer.equity')}
            placeholder={i18n.t('builder.offer_analyzer.equity_input_placeholder')}
            type="number"
            step={0.1}
            value={equity}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              dispatch(actions.updateFields({ equity: e.currentTarget.value }))
            }
          />
        </Field>
      </Row>
    </Fragment>
  )
}

export default JobOfferProComDetails
