import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { trackInternalEvent } from '@rio/tracking'
import { Container } from 'builder/components/Container'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { actions, selectors } from 'builder/modules/resumeDistribution'
import { Spinner } from 'builder/components/Spinner'
import useWebsiteHost from 'builder/components/CIO-Dasboard-2.0/hooks/useWebsiteHost'

import DashboardHeader from './components/DashboardHeader'
import DashboardBanner from './components/DashboardBanner'
import { RecruiterSection } from './components/RecruiterSection/RecruiterSection'
import { SpinnerWrapper, Wrapper } from './styles'

export const ResumeDistributionDashboardView = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { getHost } = useWebsiteHost()

  const candidate = useTypedSelector(selectors.candidate)
  const distributionDates = useTypedSelector(selectors.distributionDates)
  const distributedRecruiterCount = useTypedSelector(selectors.distributedRecruiterCount)

  const hasLoaded = () => {
    return distributionDates && distributedRecruiterCount > 0
  }

  useEffect(() => {
    trackInternalEvent('rd_dashboard', { website_host: getHost })
    dispatch(actions.fetchRecruiters({ count: true }))
    dispatch(actions.fetchCandidate())
    dispatch(actions.fetchDistributionDates())
  }, [dispatch, getHost])

  useEffect(() => {
    if (candidate && !candidate?.fileUploadId) {
      navigate('/resume-distribution/invalid-document')
    }
  }, [candidate, navigate])

  if (!candidate || !hasLoaded()) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    )
  }

  return (
    <Wrapper>
      <Container>
        <DashboardHeader candidate={candidate} />
      </Container>
      <Container>
        <DashboardBanner
          totalRecruiters={distributedRecruiterCount}
          nextDistribution={distributionDates.nextDistribution}
        />
      </Container>
      <RecruiterSection distributions={distributionDates} />
    </Wrapper>
  )
}
