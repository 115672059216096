import { useMemo } from 'react'
import groupBy from 'lodash/groupBy'
import { useI18n } from 'builder/hooks/useI18n'
import { FeatureCard } from 'builder/hooks/useFeatureCards'
import { Category } from '../types'

export const useCategories = (features: FeatureCard[]): Category[] => {
  const { i18n } = useI18n()

  return useMemo(() => {
    const featureGroups = groupBy(features, 'categoryName')

    const categories: Category[] = Object.entries(featureGroups).map(([categoryName, cards]) => ({
      title: i18n.t(`builder.navigation.categories.${categoryName}.title`),
      features: cards,
    }))

    return categories
  }, [features, i18n])
}
