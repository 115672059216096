import { format } from 'date-fns'
import I18n from 'i18n-js'
import {
  CareerProfileCourseData,
  CareerProfileEducationData,
  CareerProfileWorkExperienceData,
} from 'builder/modules/careerProfile'
import { translationKey } from '../../consts'

export function formatStringDate(stringDate: string, formatString: string): string {
  const date = new Date(stringDate)
  return format(date, formatString)
}

export function getCareerProfileWorkExperienceText(
  i18n: typeof I18n,
  { title, employer, city }: CareerProfileWorkExperienceData,
): string | null {
  const params = { title, employer, city }

  if (title && employer && city) {
    return i18n.t(`${translationKey}.details.work_experience_mask`, params)
  }

  if (title && employer) {
    return i18n.t(`${translationKey}.details.work_experience_mask_title_employer`, params)
  }

  if (title && city) {
    return i18n.t(`${translationKey}.details.work_experience_mask_title_city`, params)
  }

  if (employer && city) {
    return i18n.t(`${translationKey}.details.work_experience_mask_employer_city`, params)
  }

  if (title) {
    return i18n.t(`${translationKey}.details.work_experience_mask_title`, params)
  }

  if (employer) {
    return i18n.t(`${translationKey}.details.work_experience_mask_employer`, params)
  }

  if (city) {
    return i18n.t(`${translationKey}.details.work_experience_mask_city`, params)
  }

  return null
}

export function getCareerProfileEducationText(
  i18n: typeof I18n,
  { degree, school, city }: CareerProfileEducationData,
): string | null {
  const params = { degree, school, city }

  if (degree && school && city) {
    return i18n.t(`${translationKey}.details.education_mask`, params)
  }

  if (degree && school) {
    return i18n.t(`${translationKey}.details.education_mask_degree_school`, params)
  }

  if (degree && city) {
    return i18n.t(`${translationKey}.details.education_mask_degree_city`, params)
  }

  if (school && city) {
    return i18n.t(`${translationKey}.details.education_mask_school_city`, params)
  }

  if (degree) {
    return i18n.t(`${translationKey}.details.education_mask_degree`, params)
  }

  if (school) {
    return i18n.t(`${translationKey}.details.education_mask_school`, params)
  }

  if (city) {
    return i18n.t(`${translationKey}.details.education_mask_city`, params)
  }

  return null
}

export function getCareerProfileCourseText(
  i18n: typeof I18n,
  { course, institution }: CareerProfileCourseData,
): string | null {
  const params = { course, institution }

  if (course && institution) {
    return i18n.t(`${translationKey}.details.courses_mask`, params)
  }

  if (course) {
    return i18n.t(`${translationKey}.details.courses_mask_course`, params)
  }

  if (institution) {
    return i18n.t(`${translationKey}.details.courses_mask_institution`, params)
  }

  return null
}
