import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useI18n } from 'builder/hooks/useI18n'
import { IJobPreferencesForm, actions, selectors } from 'builder/modules/resumeDistribution'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { ComposeParams } from '../types'

import {
  InfoBanner,
  InfoBannerCounter,
  InfoBannerHeader,
  TooltipWrapper,
  Column,
  TooltipQuestionWrapper,
} from './styles'

interface JobPreferencesBannerProps {
  data: IJobPreferencesForm
}

const TRANSLATION = 'builder.resume_distribution.landing.job_preferences_form.banner'

export const JobPreferencesBanner = ({ data }: JobPreferencesBannerProps) => {
  const { i18n } = useI18n()
  const { isPhone } = useMediaQueries()
  const dispatch = useDispatch()
  const count = useTypedSelector(selectors.recruitersCount)

  const composeParams = useCallback((): ComposeParams => {
    const { industry, state } = data

    const params: ComposeParams = {}
    if (state?.id) params.stateId = state?.id
    if (industry) params.industryId = industry

    return params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.state?.id, data.industry])

  useEffect(() => {
    const fetchRecruitesNumber = async () => {
      const params = composeParams()
      dispatch(actions.fetchRecruitersCount(params))
    }
    fetchRecruitesNumber()
  }, [composeParams, data.industry, data.remote, data.state, dispatch])

  const renderTooltip = () => {
    return (
      <TooltipWrapper>
        <TooltipQuestionWrapper
          textAlign="left"
          align={isPhone ? 'unset' : 'center'}
          position={isPhone ? 'left' : 'top'}
          multiline
        >
          {i18n.t(`${TRANSLATION}.tooltip`)}
        </TooltipQuestionWrapper>
      </TooltipWrapper>
    )
  }

  return (
    <InfoBanner>
      <Column size={0.5}>
        <InfoBannerHeader>{i18n.t(`${TRANSLATION}.header`)}</InfoBannerHeader>
        {!isPhone && renderTooltip()}
      </Column>
      <Column position={isPhone ? 'end' : 'start'}>
        <InfoBannerCounter>{count}</InfoBannerCounter>
        {isPhone && renderTooltip()}
      </Column>
    </InfoBanner>
  )
}
