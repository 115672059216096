import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  color: ${Colors.Neutral50};
  ${Typography.Caption};
`

export const Info = styled.div<{ isIdle?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  padding-right: ${props => (props.isIdle ? 0 : 32)}px;
  transition: padding-right 0.3s ease;
`

export const Face = styled.div<{ isVisible: boolean }>`
  position: absolute;
  right: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #f9d1e0;
  margin-left: 8px;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transform: scale(${props => (props.isVisible ? 1 : 0.6)});
  transition: transform 0.3s ease, opacity 0.3s ease;
`

export const Counter = styled.div`
  color: ${Colors.Neutral50};
  display: flex;
  align-items: baseline;
`

export const Count = styled.div`
  color: ${Colors.Neutral90};
`

export const Bar = styled.div<{ in: string; scale: number; bgColor?: string }>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: ${props => props.bgColor || Colors.Blue50};
  border-radius: 0 0 2px 2px;
  pointer-events: none;
  transform-origin: ${props => (props.scale === 1 ? '50% 0' : '0 0')};
  transition: transform 0.2s ease, background-color 0.2s ease, opacity 0.2s ease;

  ${props =>
    props.in &&
    {
      entering: css`
        opacity: 1;
        transform: scaleX(${props.scale});
      `,

      entered: css`
        opacity: 1;
        transform: scaleX(${props.scale});
      `,

      exiting: css`
        opacity: 0;
        transform: scaleX(${props.scale});
      `,

      exited: css`
        opacity: 0;
        transform: scaleX(0);
      `,
    }[props.in]};
`
