import styled from 'styled-components'
import Colors from 'builder/styles/colors'

export const ScrollContainer = styled.div`
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 12px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${Colors.Neutral20};
    border: 4px solid ${Colors.White};
    border-radius: 6px;

    &:hover {
      background-color: ${Colors.Neutral30};
    }
  }
`
