import { IVisualProps } from 'builder/components/CIO-Dasboard-2.0/types'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import AiTag from '../../Tags/AiTag'
import * as Styled from './styles'

export const TrackJobs = ({
  userPhotoUrl,
  imgUrl,
  alt,
  colorScheme,
  isMouseOver,
}: IVisualProps) => {
  const { features } = useFeaturesConfig()
  return (
    <Styled.VisualContainer>
      {(features?.aiCoverLetters || features.aiResumeDraft) && (
        <AiTag isMouseOver={isMouseOver} colorScheme={colorScheme} />
      )}
      <Styled.VisualImageContainer>
        <Styled.VisualImage src={imgUrl} alt={alt} />
        {userPhotoUrl && <Styled.UserImage src={userPhotoUrl} alt={alt} />}
      </Styled.VisualImageContainer>
    </Styled.VisualContainer>
  )
}
