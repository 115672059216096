import styled from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Media from 'builder/styles/media'
import { DocumentImagePreview } from 'builder/components/DocumentImagePreview/DocumentImagePreview'
import Shadows from 'builder/styles/shadows'

export const ResumeGradient = styled.div`
  background: linear-gradient(
    to bottom,
    ${hexToRgba(Colors.Blue10, 0)},
    ${Colors.Blue10} 100%,
    ${Colors.Blue10} 0%
  );
  position: absolute;
  bottom: 0;
  width: 54%;
  height: 70px;

  ${Media.Phone`
     bottom: -4px;
  `};
`
export const CoverLetterContainer = styled.div`
  padding-top: 26px;
  max-width: 284px;
  width: 100%;
  flex: 0 0 284px;

  ${Media.Phone`
     flex:0 0 264px;   
  `};

  ${Media.Phone`
    margin: 10px 0 24px 0;
    max-height:200px;
    height: 100%;
  `};
`
export const CoverLetterImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;

  ${Media.Phone`
     height: 100%;
  `};
`
export const CoverLetterImage = styled(DocumentImagePreview)`
  ${Shadows.template.high};
  border-radius: 3px;
  max-width: 151px;
  max-height: 176px;
  width: 100%;
  height: 100%;
`

export const CoverLetterIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: absolute;
  top: -17px;
  right: 47px;
  display: flex;
  flex-direction: column;
  background: rgba(195, 229, 254, 0.5);
  border-radius: 50%;
  cursor: pointer;
  height: 48px;
  width: 48px;
`
export const CoverLetterIconImage = styled.img``
