import { useI18n } from 'builder/hooks/useI18n'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'

import { ExternalIcon } from '../components/SideMenu/styles'

const TRANSLATION = 'builder.side_menu'

export const useDocumentSubMenu = () => {
  const { i18n } = useI18n()
  const { features } = useFeaturesConfig()

  const topResumeQueryParams = () => {
    const params: { [key: string]: string } = {
      pt: 'UWYxd6Xe2VTS0',
      utm_content: 'resume_writing_services',
    }

    if (features?.superApp) {
      params.utm_source = 'careerio'
      params.utm_medium = 'ciomenu'
      params.utm_campaign = 'tr_promo_in_ciomenu'
    }

    if (features?.international) {
      params.utm_source = 'resumeio'
      params.utm_medium = 'riomenu'
      params.utm_campaign = 'tr_promo_in_riomenu'
    }

    const queryParams = new URLSearchParams(params)
    return `?${queryParams}`
  }

  const documentSubMenu = [
    {
      to: '/resumes',
      text: i18n.t(`${TRANSLATION}.documents_submenu.resumes`),
      eventParam: 'resumes',
      enabled: true,
    },
    {
      to: '/cover-letters',
      text: i18n.t(`${TRANSLATION}.documents_submenu.cover_letters`),
      eventParam: 'cover_letters',
      enabled: true,
    },
    {
      to: '#',
      href: `https://topresume.com/resume-writing${topResumeQueryParams()}`,
      text: i18n.t(`${TRANSLATION}.documents_submenu.resume_writer`),
      endDecoration: <ExternalIcon />,
      eventParam: 'writing_services',
      enabled: features?.resumeWriting,
    },
  ]

  return { documentSubMenu }
}
