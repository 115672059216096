import { UserStore } from 'builder/modules/user'
import { ResumeEditorStore } from 'builder/modules/resumeEditor'
import { GeneralEditorStore } from 'builder/modules/generalEditor'
import { useResumeEditor } from 'builder/hooks/useResumeEditor'

type PropsType = {
  editorState: GeneralEditorStore
  resumeState: ResumeEditorStore
  isDataLoaded: boolean
  user: UserStore
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function ResumeEditorSwitcher({ editorState, resumeState, user }: PropsType): JSX.Element {
  const ResumeEditorComponent = useResumeEditor()

  return <ResumeEditorComponent {...{ editorState, resumeState, user }} />
}
