import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Wrapper = styled.div`
  width: 100%;
  background: white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 60vh;
`

export const Title = styled.div`
  ${Typography.M};
  ${FontWeights.Medium};
  margin-top: 30px;
  margin-bottom: 15px;
  max-width: 70%;
  text-align: center;
`

export const Description = styled.div<{ $width?: number }>`
  color: ${Colors.Neutral50};
  max-width: ${({ $width }) => $width || 420}px;
  text-align: center;
`
