import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

export const Container = styled.div`
  padding: 12px 32px 32px 32px;
  display: flex;
  gap: 8px;
  margin-top: auto;

  ${Media.Phone`
    padding: 12px 24px 24px 24px;
   `}
`

export const ClockIcon = styled.img``

export const Text = styled.div`
  color: ${Colors.Neutral50};
`
