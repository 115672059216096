import { ValidateResponse } from 'builder/components/SimpleEmailForm/types'
import { apiClient } from 'builder/modules/apiClient'

/**
 * Check given email address using a backend service
 */
export const emailExist = async (value: string) => {
  const { data } = await apiClient.post<ValidateResponse>(`/sign-up/validate`, {
    email: value,
  })
  if (
    data?.activatedBy &&
    (data?.activatedBy.includes('resume.io') ||
      data?.activatedBy.includes('staging.resume.io') ||
      data?.activatedBy.includes('staging.career.io') ||
      data?.activatedBy.includes('career.io'))
  ) {
    return true
  }
  return false
}
