import styled, { css } from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Icon24 from 'builder/components/Icon'
import {
  desktopSlideIn,
  desktopSlideOut,
  mobileSlideIn,
  mobileSlideOut,
} from 'builder/styles/keyframes'

export const SidebarWrapper = styled.div<{ $open: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.White};
  border-radius: var(--S, 16px);
  padding: 0 20px;
  overflow: auto;
  z-index: 501;
  width: 375px;
  top: 0;

  animation: ${({ $open }) =>
    $open
      ? css`
          ${desktopSlideIn} 0.15s ease-in
        `
      : css`
          ${desktopSlideOut} 0.15s ease-out
        `};

  bottom: 0;
  position: absolute;
  margin: 8px;
  ${Media.AbovePhone`
    width: 456px;
    left: 5px;
  `}

  ${Media.Phone`
    left: 0;
  `}

  ${({ $open }) =>
    css`
      ${Media.Phone`
           animation: ${
             $open
               ? css`
                   ${mobileSlideIn} 0.30s ease-in
                 `
               : css`
                   ${mobileSlideOut} 0.30s ease-out
                 `
           };
      `};
    `}
`

export const CloseIcon = styled(Icon24.Close)`
  color: ${Colors.Neutral50};
  ${Media.Hover`
    &:hover {
      cursor: pointer;
    }
  `}
`

export const ImageContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 20px;
`

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`

export const Title = styled.div`
  ${Typography.S};
  ${FontWeights.Medium};
  padding-top: 16px;
  padding-bottom: 8px;
  color: ${Colors.Neutral90};
  flex-direction: column;
`

export const Subtitle = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular};
  padding-top: 2px;
  line-height: 20px;
  color: ${Colors.Neutral50};
`

export const Question = styled.div`
  ${Typography.Caps};
  ${FontWeights.DemiBold};
  padding-top: 24px;
  line-height: 16px;
  color: ${Colors.Neutral50};
  letter-spacing: 1.6px;
  text-transform: uppercase;
`

export const Line = styled.div`
  margin-top: 12px;
  height: 1px;
  width: 100%;
  background-color: ${Colors.Neutral15};
`
export const ListItemContainer = styled.div`
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
`
