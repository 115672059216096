import { useQuery } from '@tanstack/react-query'
import { baseClient } from 'builder/modules/apiClient'

interface FuzzyJobTitles {
  id: number
  score: number
  standardTitle: string
}

export const useFuzzyJobTitles = (jobTitleText: string | null, limit: number) => {
  return useQuery(
    ['useFuzzyJobTitles', jobTitleText],
    async () => {
      const response = await baseClient.get<FuzzyJobTitles[]>(`/career-data/v1/fuzzy-job-titles`, {
        params: {
          query: jobTitleText,
          limit,
        },
      })
      return response.data
    },
    {
      enabled: !!jobTitleText,
      useErrorBoundary: false,
    },
  )
}
