import styled from 'styled-components'
import { animated } from '@react-spring/web'
import Colors from 'builder/styles/colors'
import Shadows from 'builder/styles/shadows'
import { Icon24 } from 'builder/components/Icon'

export const Container = styled(animated.div)`
  ${Shadows.lightWithBorder.low};

  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 36px;
  position: fixed;
  bottom: 104px;
  right: 25px;
  z-index: 3;
  background-color: ${Colors.White};
`

export const DashedCircle = styled.div`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed ${Colors.Blue20};
`

export const BlueTick = styled(Icon24.Tick)`
  color: ${Colors.Blue50};
`
