import { FC, useState } from 'react'
import { Transition } from 'react-transition-group'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import { Wrapper } from './styles'

/**
 * Another version of HorizontalTransitionFade with solved bug:
 * now you can put content with fixed position inside animation container (f.e. Modals)
 */
export const HorizontalTransitionFadeV2: FC<{
  durationMs?: number
}> = ({ durationMs = 500, children, ...props }) => {
  const [isMounted, setIsMounted] = useState(false)

  useEffectOnMount(() => {
    setTimeout(() => {
      setIsMounted(true)
    })
  })

  return (
    <Transition
      in={isMounted}
      timeout={{
        appear: 0,
        enter: durationMs,
        exit: 0,
      }}
    >
      {state => (
        <Wrapper transitionState={state} durationMs={durationMs} {...props}>
          {children}
        </Wrapper>
      )}
    </Transition>
  )
}
