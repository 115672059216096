import styled from 'styled-components'
import Media from 'builder/styles/media'

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
`

export const Content = styled.div<{ isOpen: boolean }>`
  width: ${({ isOpen }) => `calc(100vw - ${isOpen ? '250px' : '50px'})`};
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  ${Media.Tablet`
    width: 100%;
    padding-left: 80px;
  `}

  ${Media.Phone`
    width: 100%;
    padding-left: 0;
    padding-bottom: 70px
  `}
`
