const paypalSupportedCurrencies = [
  { name: 'Australian dollar', id: 'AUD' },
  { name: 'Brazilian real 2', id: 'BRL' },
  { name: 'Canadian dollar', id: 'CAD' },
  { name: 'Chinese Renmenbi 3', id: 'CNY' },
  { name: 'Czech koruna', id: 'CZK' },
  { name: 'Danish krone', id: 'DKK' },
  { name: 'Euro', id: 'EUR' },
  { name: 'Hong Kong dollar', id: 'HKD' },
  { name: 'Hungarian forint 1', id: 'HUF' },
  { name: 'Israeli new shekel', id: 'ILS' },
  { name: 'Japanese yen 1', id: 'JPY' },
  { name: 'Malaysian ringgit 3', id: 'MYR' },
  { name: 'Mexican peso', id: '	MXN' },
  { name: 'New Taiwan dollar 1', id: 'TWD' },
  { name: 'New Zealand dollar', id: 'NZD' },
  { name: 'Norwegian krone', id: 'NOK' },
  { name: 'Philippine peso', id: 'PHP' },
  { name: 'Polish złoty', id: 'PLN' },
  { name: 'Pound sterling', id: 'GBP' },
  { name: 'Russian ruble', id: 'RUB' },
  { name: 'Singapore dollar', id: 'SGD' },
  { name: 'Swedish krona', id: 'SEK' },
  { name: 'Swiss franc	', id: 'CHF' },
  { name: 'Thai baht	', id: 'THB' },
  { name: 'United States dollar', id: 'USD' },
]
export default paypalSupportedCurrencies
