import ReactDOM from 'react-dom'
import { EditorState } from 'draft-js'
import Icon from 'builder/components/Icon'
import { i18n as I18n } from 'builder/utils/i18n'
import SpellCheckEmitter from '../spellCheckEmitter'
import { IncorrectWord } from '../../types'
import {
  SuggestionDropdown,
  SuggestionItem,
  SuggestionItemUnknown,
  SuggestionIcon,
  SuggestionDivider,
  SuggestionIgnore,
} from './styles'
import { useSpellCheckDropdownActions } from './useSpellCheckDropdownActions'

export interface Props {
  spellCheckEmitter: SpellCheckEmitter
  editorState: EditorState
  setEditorState: React.Dispatch<React.SetStateAction<EditorState>>
  handleChange: (editorStateProp: EditorState) => void
}

const SpellCheckDropdown = (props: Props) => {
  const incorrectWordsFromStorage = localStorage.getItem('SPELL_CHECKER_INCORRECT_WORDS')
  const incorrectWords: IncorrectWord[] = incorrectWordsFromStorage
    ? JSON.parse(incorrectWordsFromStorage)
    : []

  const {
    isOpen,
    top,
    left,
    word,
    handleMouseOver,
    handleMouseLeave,
    handleIgnoreClick,
    handleSuggestionItemClick,
  } = useSpellCheckDropdownActions(props)

  const incorrectWord = incorrectWords.find(item => item.word === word)

  if (!incorrectWord) return null

  return ReactDOM.createPortal(
    <SuggestionDropdown
      isOpen={isOpen}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      style={{ top: `${top}px`, left: `${left}px` }}
    >
      {incorrectWord?.suggestions.map(suggestion => (
        <SuggestionItem key={suggestion} onClick={() => handleSuggestionItemClick(suggestion)}>
          <SuggestionIcon>
            <Icon.Tick />
          </SuggestionIcon>
          {suggestion}
        </SuggestionItem>
      ))}
      {!incorrectWord?.suggestions.length && (
        <SuggestionItemUnknown>
          {I18n.t('builder.resume_editor.spell_checker_unknown_word')}
        </SuggestionItemUnknown>
      )}
      <SuggestionDivider />
      <SuggestionIgnore onClick={handleIgnoreClick}>
        <SuggestionIcon>
          <Icon.InvisibleSmall />
        </SuggestionIcon>
        Ignore
      </SuggestionIgnore>
    </SuggestionDropdown>,
    document.body,
  )
}

export default SpellCheckDropdown
