const EVENT_NAME = 'JOY_RIDES_JOB_SEARCH_TRACKING_EVENT'
export const VERSION_AUTO_APPLY_BUBBLES = 'v2'

interface Payload {
  enable: boolean
  running: boolean
  showGuideButton: boolean
  stepIndex: number
  version?: 'v2'
  showFirstPage: boolean
}

export class JoyRidesJobSearchTrackingUtils {
  static getLocalStorageKey(userId: number) {
    return EVENT_NAME + '_' + userId
  }

  static saveLocalStorage(userId: number, payload: Payload) {
    const key = this.getLocalStorageKey(userId)

    localStorage.setItem(key, JSON.stringify(payload))
  }

  static readLocalStorage(userId: number): Payload {
    const key = this.getLocalStorageKey(userId)
    try {
      const value = localStorage.getItem(key)
      if (!value) {
        return {
          enable: false,
          running: false,
          showGuideButton: false,
          stepIndex: 0,
          showFirstPage: false,
        }
      }
      return JSON.parse(value)
    } catch (error) {
      return {
        enable: false,
        running: false,
        showGuideButton: false,
        stepIndex: 0,
        showFirstPage: false,
      }
    }
  }
}
