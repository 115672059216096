import { Container, SessionsHeading, Feature, GreenTick, FeaturesText } from './styles'

interface PackProps {
  features: string[]
}

interface CoachingSessionsProps {
  pack: PackProps
}

export const CoachingSessions = ({ pack }: CoachingSessionsProps) => {
  return (
    <Container>
      <SessionsHeading>Coaching Sessions:</SessionsHeading>
      {pack?.features.map((feature, index) => {
        return (
          <Feature key={index}>
            <GreenTick />
            <FeaturesText>{feature}</FeaturesText>
          </Feature>
        )
      })}
    </Container>
  )
}
