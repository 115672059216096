import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const SubHeader = styled.p`
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
  white-space: nowrap;
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
`
