import { useMemo, useCallback } from 'react'
import { ResumeWorkExperience } from 'packages/types'
import Base from 'builder/components/Card/Base'
import { useI18n } from 'builder/hooks/useI18n'
import { CardContentWrapper } from 'builder/components/Card/styles'
import { formatDateRange } from 'builder/utils/formatDateRange'
import { withAutoFocus } from 'builder/hocs/AutoFocusHoc'
import ExpandableCardContext from '../../ExpandableCardContext'
import { EmployerProfile } from './EmployerProfile'
import { EmploymentDetails } from './EmploymentDetails'

type WorkExperienceCardProps = {
  item: ResumeWorkExperience
  sectionId: string
  onChange: (sectionId: string, cardId: number, values: unknown, debounce: boolean) => void
}

const WorkExperienceCard: React.FC<WorkExperienceCardProps> = props => {
  const { item, sectionId, onChange, ...restProps } = props
  const { i18n } = useI18n()
  const { title, employer } = item

  const cardTitle = useMemo(() => {
    const joiningString = ' ' + i18n.t('builder.resume_editor.labels.at') + ' '

    return (
      /* addind space before and after at */
      [title, employer].filter(Boolean).join(joiningString) ||
      i18n.t('builder.resume_editor.not_specified')
    )
  }, [i18n, employer, title])
  const cardSubtitle = formatDateRange(item)

  const onCardValueChange = useCallback(
    (values: unknown, debounce?: boolean) => {
      onChange(sectionId, item.id as number, values, debounce as boolean)
    },
    [onChange, sectionId, item.id],
  )

  return (
    <Base
      {...{ title: cardTitle, subtitle: cardSubtitle, item, sectionId, onChange, ...restProps }}
    >
      <CardContentWrapper>
        <ExpandableCardContext.Provider value={{ item, sectionId, onCardValueChange }}>
          <EmploymentDetails />
          <EmployerProfile />
        </ExpandableCardContext.Provider>
      </CardContentWrapper>
    </Base>
  )
}

export default withAutoFocus(WorkExperienceCard)
