import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Icon24 from 'builder/components/Icon'

export const Container = styled.div<{ padding?: string }>`
  display: flex;
  justify-content: space-between;
`

export const Column = styled.div<{ size?: number; position?: 'start' | 'center' | 'end' }>`
  flex: ${props => props.size || 1};
  display: flex;
  justify-content: ${props =>
    props?.position === 'center' ? 'center' : `flex-${props?.position}`};
`

export const FormWrapper = styled.div`
  width: 418px;

  ${Media.Phone`
    width: 100%;
  `};
`

export const FormHeader = styled.div`
  text-align: center;
  padding: 8px 0 24px;

  ${Media.Phone`
    padding: 0 0 24px;
  `};
`

export const Header = styled.div`
  ${Typography.M};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
  padding: 12px;
`

export const SubHeader = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
  display: flex;
  align-items: center;
  padding: 0 10px;
`

export const ColumnSection = styled.div`
  display: flex;
`

export const Section = styled.div`
  margin: 0 32px;
  width: 160px;
`

export const InfoContainer = styled.div`
  display: flex;
  gap: 8px;
  padding: 12px;
  border-radius: 8px;

  background: ${Colors.Blue10};
`

export const ExtensionIcon = styled(Icon24.Extension)`
  color: ${Colors.Blue50};
  width: 30px;
  margin-top: -2px;
`

export const InfoText = styled.div`
  ${Typography.Tiny}
  ${FontWeights.Regular}
`
