import { useRef, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { useI18n } from 'builder/hooks/useI18n'
import { FieldContainer, FieldInput } from './styles'

const Field = ({ name, value, multiline, onChange, onBlur }) => {
  const { i18n } = useI18n()
  const input = useRef()
  // const label = i18n.t(`builder.job_tracking.edit_modal.${name}_label`)
  const placeholder = i18n.t(`builder.job_tracking.edit_modal.${name}_holder`)
  const tagName = multiline ? 'textarea' : 'input'

  useLayoutEffect(() => {
    if (multiline) {
      input.current.style.height = 'auto'
      input.current.style.height = `${input.current.scrollHeight}px`
    }
  }, [multiline, value])

  return (
    <FieldContainer multiline={multiline}>
      <FieldInput
        as={tagName}
        ref={input}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
      />
    </FieldContainer>
  )
}

Field.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  multiline: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
}

Field.defaultProps = {
  value: '',
}

export default Field
