import { createImageUrlFormatter } from '@rio/cloudflare-image'
import { DPR } from 'builder/modules/constants'

export const formatImageUrl = createImageUrlFormatter({
  base: window.location.origin,
  defaults: {
    format: 'auto', // convert to modern format
    fit: 'scale-down', // prevent upscale
    dpr: DPR, // use device pixel ratio
  },
})
