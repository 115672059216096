import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'

import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'

import { FetchStatuses } from 'builder/modules/constants'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { actions, selectors } from 'builder/modules/resumeDistribution'
import { actions as jobSearchActions } from 'builder/modules/jobSearch'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { ResumeDistributionRoutes } from '../../constants'

import {
  ActionButton,
  AnimationBox,
  CompleteButton,
  Container,
  IconContainer,
  InfoBox,
  InfoHeadContiner,
  InfoHeader,
  InfoIcon,
  InfoItem,
  InfoItems,
  InfoItemSpinner,
  InfoItemText,
  InfoItemTickIcon,
  InfoSubHeader,
  NewInfoBox,
  ResumeDistributionHeader,
  ResumeDistributionSection,
  ResumeDistributionSubheader,
  ResumeDistributionToggle,
  ResumeDistributionToggleContainer,
  ResumeDistributionTootltip,
  ToggleContainer,
  Tooltip,
  TooltipIcon,
  Video,
} from './styles'
import SlideVideo from './assets/slide.webm'
import SuccessIcon from './assets/success-icon.png'

const TRANSLATION = 'builder.auto_apply.complete_screen'

export const AutoApplyCompletedView = () => {
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const features = useFeaturesConfig()
  const { isPhone, isTablet } = useMediaQueries()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [completeResumeDistribution, setCompleteResumeDistribution] = useState<boolean>(false)

  const candidate = useTypedSelector(selectors.candidate)
  const candidateFetchStatus = useTypedSelector(selectors.candidateFetchStatus)
  const isFetchingCandidate = candidateFetchStatus === FetchStatuses.loading
  const isTabletOrMobile = isPhone || isTablet

  const translation = `${TRANSLATION}.${isLoading || isFetchingCandidate ? 'loading' : 'completed'}`
  const showResumeDistributionFlow =
    features.showResumeDistribution() && !isFetchingCandidate && !candidate

  const toggleResumeDistribution = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    e.preventDefault()

    setCompleteResumeDistribution(!completeResumeDistribution)
    if (!completeResumeDistribution) {
      trackInternalEvent('rd_toggle_turn_on', {
        label: 'total_job_search_solution',
      })
    }
  }

  const onClick = () => {
    const link = completeResumeDistribution
      ? `/auto-apply/rd/${ResumeDistributionRoutes.JOB_PREFERENCES}`
      : '/job-search?view=recommendation'

    if (link.includes('/job-search')) {
      dispatch(jobSearchActions.startAutoApplyBubble({ enableFirstPage: true }))
    }

    navigate(link)
  }

  useEffect(() => {
    trackInternalEvent('enter_success_screen', {
      label: 'total_job_search_solution',
    })
    dispatch(actions.fetchCandidate())
    setTimeout(() => {
      setIsLoading(false)
    }, 5000)
  }, [])

  const resumeDistributionToggle = (
    <ResumeDistributionSection onClick={toggleResumeDistribution}>
      <ResumeDistributionToggleContainer>
        <ResumeDistributionHeader>
          {i18n.t(`${translation}.resume_distribution.header`)}
        </ResumeDistributionHeader>
        <ResumeDistributionTootltip>
          <Tooltip icon={<TooltipIcon />} position="top" multiline width={isPhone ? 200 : 300}>
            {i18n.t(`${translation}.resume_distribution.tooltip`)}
          </Tooltip>
        </ResumeDistributionTootltip>
        <ToggleContainer>
          <ResumeDistributionToggle
            checked={completeResumeDistribution}
            onChange={e => {
              e.stopPropagation()
              e.preventDefault()
            }}
          />
        </ToggleContainer>
      </ResumeDistributionToggleContainer>
      {completeResumeDistribution && (
        <ResumeDistributionSubheader>
          {i18n.t(`${translation}.resume_distribution.sub_header`)}
        </ResumeDistributionSubheader>
      )}
    </ResumeDistributionSection>
  )

  return (
    <Container>
      {!isLoading && !isFetchingCandidate ? (
        <NewInfoBox>
          <InfoIcon src={SuccessIcon} />

          <InfoHeadContiner>
            <InfoHeader>{i18n.t(`${translation}.header`)}</InfoHeader>
            <InfoSubHeader>{i18n.t(`${translation}.sub_header`)}</InfoSubHeader>
          </InfoHeadContiner>

          {!isTabletOrMobile && showResumeDistributionFlow && resumeDistributionToggle}

          {!isTabletOrMobile && (
            <CompleteButton onClick={onClick}>
              {i18n.t(
                `${translation}${completeResumeDistribution ? '.resume_distribution' : ''}.button`,
              )}
            </CompleteButton>
          )}
        </NewInfoBox>
      ) : (
        <InfoBox>
          <InfoHeadContiner>
            <InfoHeader>{i18n.t(`${translation}.header`)}</InfoHeader>
            <InfoSubHeader>{i18n.t(`${translation}.sub_header`)}</InfoSubHeader>
          </InfoHeadContiner>

          <InfoItems>
            {[...Array(5).keys()].map((number: number) =>
              number === 2 && !showResumeDistributionFlow ? (
                <></>
              ) : (
                <InfoItem key={number}>
                  <IconContainer>
                    {isLoading || isFetchingCandidate ? <InfoItemSpinner /> : <InfoItemTickIcon />}
                  </IconContainer>
                  <InfoItemText>{i18n.t(`${translation}.items.item_${number + 1}`)}</InfoItemText>
                </InfoItem>
              ),
            )}
          </InfoItems>
        </InfoBox>
      )}
      <AnimationBox>
        <Video src={SlideVideo} muted autoPlay playsInline loop />
      </AnimationBox>
      {!isLoading && isTabletOrMobile && showResumeDistributionFlow && resumeDistributionToggle}
      {!isLoading && isTabletOrMobile && (
        <ActionButton onClick={onClick}>
          {i18n.t(
            `${translation}${completeResumeDistribution ? '.resume_distribution' : ''}.button`,
          )}
        </ActionButton>
      )}
    </Container>
  )
}
