import { Fragment, useState } from 'react'
import { Optional } from 'packages/types'
import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import Icon, { Icon20 } from 'builder/components/Icon'
import ActionMenu from 'builder/components/ActionMenu'

import { TooltipV2 } from 'builder/components/Tooltip'
import { SectionIconList, SectionIcon } from './styles'

type Props = {
  onEdit: Optional<() => void>
  onRevert: Optional<() => void>
  onDelete: Optional<() => void>
  isForcedVisible?: boolean
}

const SectionActions = ({ onEdit, onRevert, onDelete, isForcedVisible }: Props) => {
  const { i18n } = useI18n()
  const { isPhone } = useMediaQueries()
  const [isSheetOpen, toggleSheet] = useState(false)
  const options = []

  if (onEdit) {
    options.push({
      icon: <Icon.Edit />,
      iconSmall: <Icon20.Edit />,
      text: i18n.t('builder.resume_editor.rename'),
      onClick: onEdit,
    })
  }

  if (onRevert) {
    options.push({
      icon: <Icon.Revert />,
      iconSmall: <Icon20.Revert />,
      text: i18n.t('builder.resume_editor.revert_section_name'),
      onClick: onRevert,
    })
  }

  if (onDelete) {
    options.push({
      icon: <Icon.Trash />,
      iconSmall: <Icon20.Trash />,
      text: i18n.t('builder.resume_editor.delete'),
      onClick: onDelete,
    })
  }

  const isSheetMode = isPhone && options.length > 1

  return (
    <SectionIconList $isForcedVisible={isForcedVisible}>
      {!isSheetMode &&
        options.map((action, i) => (
          <TooltipV2 key={i} content={action.text}>
            <SectionIcon onClick={action.onClick}>{action.iconSmall}</SectionIcon>
          </TooltipV2>
        ))}

      {isSheetMode && (
        <Fragment>
          <SectionIcon onClick={() => toggleSheet(true)}>
            <Icon.More />
          </SectionIcon>

          {isSheetOpen && <ActionMenu onClose={() => toggleSheet(false)} actions={options} />}
        </Fragment>
      )}
    </SectionIconList>
  )
}

export default SectionActions
