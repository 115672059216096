import styled from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Header = styled.div`
  position: absolute;
  left: 8.96%;
  right: -22.39%;
  top: 24px;
  bottom: 88.3%;
  color: ${Colors.Neutral40};
  ${Typography.Caps};
  ${FontWeights.DemiBold};
  display: flex;
  align-items: center;
`

export const Title = styled.div`
  position: absolute;
  left: 8.96%;
  right: 8.96%;
  top: 50px;
  bottom: 78.72%;
  color: ${Colors.Neutral80};
  ${Typography.Body};
  ${FontWeights.DemiBold};
  display: flex;
  align-items: center;
`

export const Text = styled.div`
  position: absolute;
  left: 8.96%;
  right: 17.16%;
  top: 62px;
  bottom: 67.55%;
  color: ${Colors.Neutral80};
  ${Typography.Caption};
  ${FontWeights.Regular};
`

export const VisualContainer = styled.div`
  position: absolute;
  width: 268px;
  height: 240px;
  left: 0px;
  top: 136px;
  color: ${Colors.Neutral5};
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`

export const MainContainer = styled.div`
  color: ${Colors.Neutral5};
  width: 268px;
  height: 240px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`

export const ImageContainer = styled.img`
  flex-shrink: 0;
  width: 74px;
  height: 68px;
  margin-left: 2px;
  margin-top: 2px;
  border-radius: 6px;
`

export const Loader = styled.img`
  flex-shrink: 0;
  width: 120px;
  height: 60px;
  margin-left: 10px;
  margin-top: 7px;
`

export const SubContainer = styled.div`
  width: 220px;
  height: 72px;
  margin-left: 24px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 0;
  border: none;
  background: ${Colors.White};
  border-radius: 8px;
`
export const ResourceCardVisualGradient = styled.div`
  width: 100%;
  height: 172px;
  position: absolute;
  top: 204px;
  bottom: 0;
  left: 0px;
  right: 0;
  border-radius: 16px;
  background: linear-gradient(
    to bottom,
    ${hexToRgba(Colors.Neutral5, 0)},
    ${Colors.Neutral5} 100%,
    ${Colors.Neutral5} 0%
  );
`

export const Container = styled.a``
