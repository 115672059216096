import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'

import Button from 'builder/components/Button'
import { Icon20 } from 'builder/components/Icon'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { actions, selectors, CareerPathStep, Label } from 'builder/modules/careerPath2'

import CareerPath from './components/CareerPath'
import UserProfile from './components/UserProfile'
import {
  Text,
  LeftArrow,
  Container,
  EditButton,
  StyledButton,
  ArrowContainer,
  HeaderContainer,
} from './styles'

const ConfirmPath = () => {
  const dispatch = useDispatch()
  const { isPhone } = useMediaQueries()
  const step = useTypedSelector(selectors.step)
  const skills = useTypedSelector(selectors.skills)
  const occupation = useTypedSelector(selectors.occupation)
  const careerPath1 = useTypedSelector(selectors.careerPath1)
  const editCareerCardData = useTypedSelector(selectors.editCareerCardData)
  const isEditingNewAdded = useTypedSelector(selectors.isEditingNewAdded)

  const handleClick = useCallback(() => {
    if (step === CareerPathStep.CONFIRM_MY_PATH) {
      dispatch(actions.setStep(CareerPathStep.JOB_PROGRESSION))
    } else {
      dispatch(actions.setStep(CareerPathStep.VIEW_CARDS))
    }

    trackInternalEvent('click_career_details_page_back_button', {
      ...Label,
    })
  }, [dispatch, step])

  const handleConfirm = useCallback(() => {
    const skillsValues = skills.map(skill => skill.value)

    const params = {
      title: occupation,
      skills: skillsValues,
      pathways: {
        path1: careerPath1,
      },
    }

    if (editCareerCardData.id) {
      dispatch(actions.updateCareerPath(params))
    } else {
      dispatch(actions.saveCareerPaths(params))
    }

    dispatch(actions.setStep(CareerPathStep.SUCCESS))

    trackInternalEvent('click_confirm_pathway_page', {
      ...Label,
      starting_title: occupation,
      level1_title: careerPath1[0].title,
      level2_title: careerPath1[1]?.title,
      level3_title: careerPath1[2]?.title,
    })
  }, [skills, occupation, careerPath1, editCareerCardData.id, dispatch])

  const setCareerPath = useCallback(() => {
    switch (editCareerCardData.pathways.path1.length) {
      case 1: {
        const paramsLevel1 = {
          query: editCareerCardData.title,
          selectedValue: editCareerCardData.pathways.path1[0].title,
        }

        dispatch(actions.fetchJobTitles(paramsLevel1))
        dispatch(actions.setSelectedLevel1JobTitleIndex(0))
        dispatch(actions.setSelectedLevel2JobTitleIndex(null))
        dispatch(actions.setSelectedLevel3JobTitleIndex(null))

        break
      }
      case 2: {
        const paramsLevel1 = {
          query: editCareerCardData.title,
          selectedValue: editCareerCardData.pathways.path1[0].title,
        }
        const paramsLevel2 = {
          level: 2,
          query: editCareerCardData.pathways.path1[0].title,
          selectedValue: editCareerCardData.pathways.path1[1].title,
        }

        dispatch(actions.fetchJobTitles(paramsLevel1))
        dispatch(actions.fetchJobTitlesLevelTwo(paramsLevel2))
        dispatch(actions.setSelectedLevel1JobTitleIndex(0))
        dispatch(actions.setSelectedLevel2JobTitleIndex(0))
        dispatch(actions.setSelectedLevel3JobTitleIndex(null))

        break
      }
      case 3: {
        const paramsLevel1 = {
          query: editCareerCardData.title,
          selectedValue: editCareerCardData.pathways.path1[0].title,
        }
        const paramsLevel2 = {
          level: 2,
          query: editCareerCardData.pathways.path1[0].title,
          selectedValue: editCareerCardData.pathways.path1[1].title,
        }
        const paramsLevel3 = {
          level: 3,
          query: editCareerCardData.pathways.path1[1].title,
          selectedValue: editCareerCardData.pathways.path1[2].title,
        }

        dispatch(actions.fetchJobTitles(paramsLevel1))
        dispatch(actions.fetchJobTitlesLevelTwo(paramsLevel2))
        dispatch(actions.fetchJobTitlesLevelThree(paramsLevel3))
        dispatch(actions.setSelectedLevel1JobTitleIndex(0))
        dispatch(actions.setSelectedLevel2JobTitleIndex(0))
        dispatch(actions.setSelectedLevel3JobTitleIndex(0))

        break
      }
    }
  }, [dispatch, editCareerCardData.pathways.path1, editCareerCardData.title])

  const handleChangeMyPath = useCallback(() => {
    if (!isEditingNewAdded) setCareerPath()

    dispatch(actions.setStep(CareerPathStep.JOB_PROGRESSION))
    dispatch(actions.setPrevStep(CareerPathStep.CONFIRM_MY_PATH))

    trackInternalEvent('click_change_my_path_button', {
      ...Label,
      starting_title: occupation,
      level1_title: careerPath1[0].title,
      level2_title: careerPath1[1]?.title,
      level3_title: careerPath1[2]?.title,
    })
  }, [dispatch, isEditingNewAdded, setCareerPath, occupation, careerPath1])

  const getStepBasedButton = useCallback(() => {
    if (step === CareerPathStep.CHANGE_MY_PATH) {
      if (isPhone) {
        return (
          <EditButton onClick={handleChangeMyPath}>
            <Icon20.Edit />
          </EditButton>
        )
      }

      return <Button onClick={handleChangeMyPath}>Change My Path</Button>
    }

    return <StyledButton onClick={handleConfirm}>Confirm My Path</StyledButton>
  }, [handleChangeMyPath, handleConfirm, isPhone, step])

  return (
    <Container>
      <HeaderContainer>
        <ArrowContainer onClick={handleClick}>
          <LeftArrow />
          <Text>Back</Text>
        </ArrowContainer>
        <UserProfile />
        {getStepBasedButton()}
      </HeaderContainer>
      <CareerPath />
    </Container>
  )
}

export default ConfirmPath
