import { useQuery } from '@tanstack/react-query'
import { baseClient } from 'builder/modules/apiClient'
import { DailySuggestionsResponse, DailySuggestionsError } from 'builder/modules/interview/types'
import { DAILY_REGULAR_POLL_INTERVAL, POLLING_REFRESH_INTERVAL_IN_MILLIS } from '../constants'
import { InitialRequest } from '../components/DashboardView/DashboardView'
import { usePerformanceOnce } from './usePerformanceOnce'

export const useDailySuggestions = (
  jobTitleId?: number,
  setInitialRequest?: React.Dispatch<React.SetStateAction<InitialRequest | undefined>>,
  enabledPerformanceMetric = false,
) => {
  const { registerOncePerformance, stopRegisterPerformance } = usePerformanceOnce({
    enabled: enabledPerformanceMetric,
    metricKey: `daily-suggestion-request`,
    tags: { jobTitleId },
    successCallBack: () => {
      if (setInitialRequest) {
        setInitialRequest(prevState =>
          prevState === undefined ? undefined : { ...prevState, dailyRequest: true },
        )
      }
    },
  })

  return useQuery(
    ['useDailySuggestions', jobTitleId],
    async () => {
      registerOncePerformance()
      const response = await baseClient.get<DailySuggestionsResponse | DailySuggestionsError>(
        `/interview_prep/daily_suggestions?data_api_job_title_id=${jobTitleId}`,
      )
      return response.data
    },
    {
      enabled: !!jobTitleId,
      refetchInterval: (data?: DailySuggestionsResponse) => {
        if (!(data as unknown as DailySuggestionsError)?.error) {
          stopRegisterPerformance()
          return DAILY_REGULAR_POLL_INTERVAL
        }
        return POLLING_REFRESH_INTERVAL_IN_MILLIS
      },
    },
  )
}
