import { useI18n } from 'builder/hooks/useI18n'
import * as Styled from './styles'

const Loader = require('./assets/Loader.gif')
const TickMark = require('./assets/tick.png')
const CardToolTipImage = require('./assets/Union.png')

export const CareercardLoaderTooltip = () => {
  const { i18n } = useI18n()

  const text = [
    { data: i18n.t('builder.panel.card_loader_tooltip.text_line1') },
    {
      data: i18n.t('builder.panel.card_loader_tooltip.text_line2'),
    },
  ]

  return (
    <Styled.CareerCardLoaderContainer>
      <Styled.ToolTip src={CardToolTipImage} />
      <Styled.CareerCardImageTitle>
        {i18n.t('builder.panel.card_loader_tooltip.title')}
      </Styled.CareerCardImageTitle>
      <Styled.CareerCardIconLoading src={Loader} />
      <Styled.TextContainer>
        {text.map((key, index) => (
          <Styled.TextSubContainer key={index}>
            <Styled.TickMark src={TickMark} />
            <Styled.Text>{key.data}</Styled.Text>
          </Styled.TextSubContainer>
        ))}
      </Styled.TextContainer>
    </Styled.CareerCardLoaderContainer>
  )
}
