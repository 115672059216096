export enum EBannerLocalStorageKeys {
  BANNER_IDLE_TIME_LIMIT_IN_SECONDS = 'bannerIdleTimeLimitInSeconds',
}

export const getLocalStorageBannerKey = (activeTab: number) => {
  return `queue_status_banners_tabs_${activeTab}`
}

export interface IBannerQueueJson {
  idleBannerName: string
  idleTimeStart: Date
}

export const getBannerIdleTimeLimitSeconds = () => {
  let idleSecondsLimit = localStorage.getItem(
    EBannerLocalStorageKeys.BANNER_IDLE_TIME_LIMIT_IN_SECONDS,
  )
  if (idleSecondsLimit) {
    return JSON.parse(idleSecondsLimit)
  }
  // 259200 Seconds = 3 Days
  localStorage.setItem(EBannerLocalStorageKeys.BANNER_IDLE_TIME_LIMIT_IN_SECONDS, '259200')
  return 259200
}
