import { memo } from 'react'
import { TextField } from 'builder/components/TextField'
import { Icon24 } from 'builder/components/Icon'
import DateRangePicker from 'builder/components/DateRangePicker'
import { i18n as I18n } from 'builder/utils/i18n'
import { AIResumeEducationCardData } from 'builder/modules/resumeEditor'
import { formatDateRange } from 'builder/utils/formatDateRange'
import { AIResumeQuestionnaireCardType } from 'builder/modules/constants'
import {
  Card,
  CollapsedCard,
  CardTitleRow,
  CardDurationRow,
  CardEditIconContainer,
  CardDeleteIconContainer,
  Span,
  Div,
} from '../styles'
import useQuestionnaireCard from '../useQuestionnaireCard'
import { isEducationCardEmpty } from './Education'

type EducationCardProps = {
  cardData: AIResumeEducationCardData
  allCards: AIResumeEducationCardData[]
}

const EducationCard = (props: EducationCardProps) => {
  const { cardData, allCards } = props
  const { institute, degree, date, isCollapsed } = cardData

  const { updateSimpleField, updateDateRange, onEditClick, onDelete } = useQuestionnaireCard(
    AIResumeQuestionnaireCardType.education,
    cardData,
  )

  const onInstituteChange = (event: React.BaseSyntheticEvent) => {
    updateSimpleField('institute', event.target.value)
  }

  const onDegreeChange = (event: React.BaseSyntheticEvent) => {
    updateSimpleField('degree', event.target.value)
  }

  const onEducationCardEdit = () => {
    const emptyCards = []
    for (const card of allCards) {
      if (card.id !== cardData.id && isEducationCardEmpty(card)) {
        emptyCards.push(card.id)
      }
    }
    onEditClick(emptyCards)
  }

  if (isCollapsed) {
    return (
      <CollapsedCard>
        <CardTitleRow>
          <Div>
            {(institute || degree) && <Span>{[institute, degree].filter(Boolean).join(', ')}</Span>}
          </Div>
          <CardEditIconContainer onClick={onEducationCardEdit}>
            <Icon24.EditAlt />
          </CardEditIconContainer>
        </CardTitleRow>
        <CardDurationRow>{formatDateRange(date)}</CardDurationRow>
      </CollapsedCard>
    )
  }

  return (
    <>
      {allCards.length > 1 && (
        <CardDeleteIconContainer>
          <Icon24.RemoveAlt onClick={onDelete} />
        </CardDeleteIconContainer>
      )}
      <Card>
        <TextField
          label={I18n.t('builder.ai_resume_draft.education.institution_name')}
          name="institute"
          value={institute}
          onChange={onInstituteChange}
        />
        <TextField
          label={I18n.t('builder.ai_resume_draft.education.degree')}
          name="degree"
          value={degree}
          onChange={onDegreeChange}
        />
        <DateRangePicker
          value={date}
          onChange={updateDateRange}
          currentlyLabel={I18n.t('builder.date_range_picker.currently.study')}
        />
      </Card>
    </>
  )
}

export default memo(EducationCard)
