import { memo, useState, useEffect, useRef, useMemo } from 'react'
import { CSSTransition } from 'react-transition-group'
import { matchPath, useLocation } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import TRxCIO from 'builder/views/ResumeCritiqueView/assets/img/TRxCIO.svg'
import { useFeatureCards } from 'builder/hooks/useFeatureCards'
import useWebsiteHost from '../CIO-Dasboard-2.0/hooks/useWebsiteHost'
import { Bar } from './components/Bar'
import { Menu } from './components/Menu'
import { MENU_TRANSITION_DURATION, TRANSLUCENT_ROUTES } from './constants'
import { Container, BannerImageTRxCio, TRxCIOBannerContainer } from './styles'

const NavigationComponent = () => {
  const location = useLocation()
  const prevLocation = useRef(location)
  const features = useFeatureCards()
  const { getHost } = useWebsiteHost()

  const [isMenuOpen, toggleMenu] = useState(false)
  const [isUserDropdownOpen, toggleUserDropdown] = useState(false)

  const isTranslucent = TRANSLUCENT_ROUTES.some(route => matchPath(route, location.pathname))
  const critiqueQueryParam = new URLSearchParams(location.search)
  const dashboardParams = critiqueQueryParam.get('fromDashboard')
  const isCritiquePage = location?.pathname?.includes('/critique')
  const isFromDashBoard = dashboardParams === '1'

  const hideNavigationBar = useMemo(() => {
    return (
      location.pathname?.includes('/premium-trail-access-loading') ||
      location.pathname?.includes('/premium-trail-activated') ||
      location.pathname?.includes('/premium-trail-onboarding')
    )
  }, [location.pathname])

  // Close all dropdowns and popups when user navigates to another route
  useEffect(() => {
    if (prevLocation.current === location) return
    prevLocation.current = location

    toggleUserDropdown(false)
    toggleMenu(false)
  }, [location])

  useEffect(() => {
    if (isMenuOpen) {
      trackInternalEvent('open_super_app_menu', { website_host: getHost })
    }
  }, [getHost, isMenuOpen])

  if (hideNavigationBar) {
    return <></>
  }

  return (
    <Container isTranslucent={isTranslucent}>
      {/* Fixed navigation bar */}
      {isCritiquePage && !isFromDashBoard ? (
        <TRxCIOBannerContainer>
          <BannerImageTRxCio src={TRxCIO} alt="Top Resume and Career.io" />{' '}
        </TRxCIOBannerContainer>
      ) : (
        <Bar
          isMenuOpen={isMenuOpen}
          isUserDropdownOpen={isUserDropdownOpen}
          isTransparent={isTranslucent}
          onUserDropdownToggle={toggleUserDropdown}
          onMenuToggle={toggleMenu}
          features={features}
        />
      )}

      {/* Fullscreen menu popup */}
      <CSSTransition
        in={isMenuOpen}
        classNames="menu-transition"
        timeout={MENU_TRANSITION_DURATION}
        mountOnEnter
        unmountOnExit
        appear
      >
        <Menu features={features} onToggle={toggleMenu} />
      </CSSTransition>
    </Container>
  )
}

export const Navigation = memo(NavigationComponent)
