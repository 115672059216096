import { FC, useState, useEffect, useMemo } from 'react'
import * as React from 'react'
import throttle from 'lodash/throttle'
import { useI18n } from 'builder/hooks/useI18n'
import { useAppSelector } from 'builder/hooks/useAppSelector'
import ChangeTemplateTooltip from '../FillResumeModal/UseExample/ChangeTemplateTooltip'
import { ButtonContainer, Button, ButtonText, ButtonIcon } from './styles'

interface FloatingPreviewButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  isSaving: boolean
}

const SCROLL_OFFSET = 30 // little lag in pixels for button collapsing

const FloatingPreviewButton: FC<FloatingPreviewButtonProps> = ({ isSaving, ...rest }) => {
  const { i18n } = useI18n()
  const showChangeTemplateTooltip = useAppSelector(
    store => store.resumeEditor.useExample.showChangeTemplateTooltip,
  )

  const [collapsed, setCollapsed] = useState(false)

  const handleScroll = useMemo(
    () =>
      throttle(() => {
        const maxScrollHeight =
          document.documentElement.scrollHeight -
          document.documentElement.clientHeight -
          SCROLL_OFFSET

        // collapsed if scroll on the top or page is fully scrolled down
        const shouldBeCollapsed = window.scrollY > SCROLL_OFFSET && window.scrollY < maxScrollHeight
        setCollapsed(shouldBeCollapsed)
      }, 250),
    [setCollapsed],
  )

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  return (
    <ButtonContainer>
      <Button collapsed={collapsed} {...rest}>
        <ButtonText collapsed={collapsed}>
          {i18n.t('builder.resume_editor.preview_and_download')}
        </ButtonText>
        <ButtonIcon isSaving={isSaving} />
      </Button>
      {showChangeTemplateTooltip && <ChangeTemplateTooltip position="top" />}
    </ButtonContainer>
  )
}

export default React.memo(FloatingPreviewButton)
