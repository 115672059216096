import styled from 'styled-components'
import Colors from 'builder/styles/colors'

export const Overlay = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-tap-highlight-color: transparent;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${Colors.Neutral100};
    transition: opacity 0.3s ease;
    opacity: 0;
  }

  &.is-visible:before {
    opacity: 0.7;
  }
`

export const Modal = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: ${Colors.White};
  transition: transform 0.3s ease;
  transform: translateX(100%);

  .is-visible & {
    transform: translateX(0%);
  }
`
