import styled from 'styled-components'
import Media from 'builder/styles/media'
import { Container as BaseContainer } from 'builder/components/Container'

export const Container = styled(BaseContainer)`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  ${Media.Phone`
    align-items: flex-start;
  `};
`

export const Wrapper = styled.div`
  padding: 42px 0 40px;

  ${Media.Phone`
    padding: 32px 0 36px;
  `};
`

export const Content = styled.div`
  width: 100%;
  max-width: 416px;

  ${Media.Phone`
    max-width: 100%;
  `};
`
