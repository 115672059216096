import { useI18n } from 'builder/hooks/useI18n'

import {
  ManagerButton,
  NotificationContainer,
  NotificationContent,
  NotificationName,
  NotificationText,
} from './styles'

interface Props {
  name: string
  onClick: () => void
}

const NotificationJobAlert = ({ name, onClick }: Props) => {
  const { i18n } = useI18n()

  return (
    <NotificationContainer $enablePointer={false}>
      <NotificationContent>
        <NotificationName>
          {i18n.t(`builder.account.email_notifications.${name}.name`)}
        </NotificationName>
        <NotificationText>
          {i18n.t(`builder.account.email_notifications.${name}.text`)}
        </NotificationText>
      </NotificationContent>
      <ManagerButton onClick={onClick}>
        {i18n.t(`builder.account.email_notifications.${name}.button`)}
      </ManagerButton>
    </NotificationContainer>
  )
}

export default NotificationJobAlert
