import { memo } from 'react'
import PropTypes from 'prop-types'
import Icon from 'builder/components/Icon'
import ColorSelectButton from 'builder/components/ColorSelectButton'
import { useI18n } from 'builder/hooks/useI18n'
import { Container, ColorList, Color, DisabledColor, ActiveIcon, Tooltip } from './styles'

const ColorSelect = ({ onSelect, onAdd, options, selected, size }) => {
  const { i18n } = useI18n()

  const canAddColor = !!onAdd
  const isCustomSelected = !options.includes(selected)

  return (
    <Container>
      <ColorList>
        {options.map(color => (
          <Color
            size={size}
            key={color}
            color={color}
            selected={color === selected}
            onClick={() => onSelect(color)}
          >
            <ActiveIcon data-visible={color === selected} />
          </Color>
        ))}

        {canAddColor && options.length > 0 && (
          <ColorSelectButton
            color={selected}
            active={isCustomSelected}
            size={size}
            onClick={onAdd}
          />
        )}

        {options.length === 0 &&
          new Array(canAddColor ? 6 : 5).fill(0).map((color, index) =>
            index === 0 ? (
              <Color size={size} key={index} selected>
                <ActiveIcon />
              </Color>
            ) : (
              <DisabledColor size={size} key={index}>
                <Tooltip>{i18n.t('builder.resume_editor.no_color')}</Tooltip>
                <Icon.Lock />
              </DisabledColor>
            ),
          )}
      </ColorList>
    </Container>
  )
}

ColorSelect.propTypes = {
  options: PropTypes.array,
  selected: PropTypes.any,
  onSelect: PropTypes.func.isRequired,
  onAdd: PropTypes.func,
  size: PropTypes.number,
}

ColorSelect.defaultProps = {
  size: 32,
}

export default memo(ColorSelect)
