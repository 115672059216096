import { i18n } from 'builder/utils/i18n'
import { SectionNames } from 'builder/modules/resumeEditor'
import FocusManager from 'builder/services/FocusManager'
import { CheckFn } from '../types'

export const checkEmail: CheckFn = ({ email }) => {
  const isComplete = typeof email === 'string' && email.length > 0

  return {
    sectionId: SectionNames.details,
    progress: isComplete ? 1 : 0,
    id: 'no_email',
    title: i18n.t('builder.resume_tuner.tips.no_email.title'),
    description: i18n.t('builder.resume_tuner.tips.no_email.description'),
    subject: i18n.t('builder.resume_tuner.tips.no_email.subject'),
    label: i18n.t('builder.resume_tuner.issues.no_email'),
    action: () => FocusManager.focus('email'),
    actionText: i18n.t('builder.resume_tuner.tips.no_email.action_text'),
  }
}
