import Logo from '../../../../assets/images/logo.svg'
import {
  Container,
  BrandLogo,
  CoachingSessions,
  CompHeading,
  Feature,
  GreenTick,
  FeaturesText,
} from './styles'

interface ComplementaryBoxProps {
  pack: {
    subscriptionExtensionInMonths: string
  }
}

export const ComplementaryBox = ({ pack }: ComplementaryBoxProps) => {
  return (
    <Container>
      <BrandLogo src={Logo} />
      <CoachingSessions>
        <CompHeading>Complimentary Career.io premium subscription:</CompHeading>
        <Feature>
          <GreenTick />
          <FeaturesText>{pack?.subscriptionExtensionInMonths}</FeaturesText>
        </Feature>
      </CoachingSessions>
    </Container>
  )
}
