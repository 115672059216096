import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import { TooltipQuestion } from 'builder/components/Tooltip/variations'

export const Column = styled.div<{ size?: number; position?: 'start' | 'center' | 'end' }>`
  flex: ${props => props.size || 1};
  display: flex;
  justify-content: ${props =>
    props?.position === 'center' ? 'center' : `flex-${props?.position}`};

  ${Media.Tablet`
    justify-content: space-between;
  `};

  ${Media.Phone`
    align-items: center;
    justify-content: end;
  `}
`
export const InfoBanner = styled.div`
  margin: 88% 0 0 0;
  width: 160px;
  border-radius: 16px;
  background: ${Colors.Blue10};
  padding: 16px;

  ${Media.Tablet`
    display: flex;
    flex-direction: column;
    margin: 0;
    width: 100%;
  `};

  ${Media.Phone`
    display: flex;
    flex-direction: row;
    margin: 0;
    width: 100%;
  `};
`

export const InfoBannerHeader = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral90};
  display: flex;
  align-items: center;
`

export const InfoBannerCounter = styled.div`
  ${Typography.XL};
  ${FontWeights.Medium};
  color: ${Colors.Blue50};
  padding-top: 16px;

  ${Media.Tablet`
    padding-top: 4px;
  `};

  ${Media.Phone`
    padding-top: 0;
  `};
`

export const TooltipWrapper = styled.div`
  ${Media.Phone`
    padding-left: 8px;
  `};
`

export const TooltipQuestionWrapper = styled(TooltipQuestion)<{ align: any }>`
  & div > svg {
    color: ${Colors.Neutral90};
  }
`
