import { Process } from './Process'
import { Failed } from './Failed'
import * as Styles from './styles'

type Props = {
  isFailed: boolean
}

export const Loader = ({ isFailed }: Props) => {
  return <Styles.Container>{isFailed ? <Failed /> : <Process />}</Styles.Container>
}
