import { useMemo } from 'react'
import { BriefResume, BriefLetter, BriefExpertDoc } from 'builder/modules/panel'
import Colors from 'builder/styles/colors'
import * as Styled from './styles'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  document: BriefResume | BriefLetter | BriefExpertDoc
}

export const OptimizeScore = ({ document }: Props) => {
  const { resumeScore, averageScore, jobTitle } = document
  const Score = !jobTitle ? resumeScore : averageScore

  const bgColor = useMemo(() => {
    if (Score <= 30) return Colors.Red40
    if (Score > 30 && Score <= 70) return Colors.Amber40
    return Colors.Green40
  }, [Score])
  return (
    <Styled.OptimizeScoreContainer>
      <Styled.Score bgColor={bgColor}>{Score}%</Styled.Score>
      <Styled.Text>Resume Score</Styled.Text>
    </Styled.OptimizeScoreContainer>
  )
}
