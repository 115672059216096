import { trackInternalEvent } from '@rio/tracking'
import { ButtonSize } from 'builder/components/Button'
import { selectors } from 'builder/modules/careerPath2'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

import {
  Text,
  Image,
  Title,
  Content,
  Container,
  TabletImage,
  StyledButton,
  TabletContainer,
} from './styles'

const coachesUrl = require('./assets/img/coaches-img.svg')

const CoachingBanner = () => {
  const { isTablet } = useMediaQueries()
  const isExploreCareers = useTypedSelector(selectors.isExploreCareers)

  const MainContainer = isTablet ? TabletContainer : Container
  const MainImage = isTablet ? TabletImage : Image

  return (
    <MainContainer>
      <MainImage src={coachesUrl} alt="coaches-img" />
      <Content>
        <Title>Do you want to master a new profession?</Title>
        <Text>Turn to the help of our coach</Text>
      </Content>
      <StyledButton
        size={ButtonSize.small}
        onClick={() => {
          window.open('/app/career-coaching', '_blank')

          trackInternalEvent('click_book_a_coach', {
            label: isExploreCareers ? 'explore_careers' : 'career_path2',
          })
        }}
      >
        Book My Coach
      </StyledButton>
    </MainContainer>
  )
}

export default CoachingBanner
