import { useQuery } from '@tanstack/react-query'
import { Resume } from '@rio/types'
import { selectors } from 'builder/modules/resumeEditor'
import { apiClient } from 'builder/modules/apiClient'
import { useTypedSelector } from './useTypedSelector'

const resumeQueryId = Symbol('resume')

export const useResume = (id: number | null): Resume | null => {
  const editedResume = useTypedSelector(selectors.resume)

  // Check that the resume in the state has the same ID as we need
  const hasMatchingEditedResume = editedResume?.id === id

  // Fetch the resume from the API if it doesn't
  const { data: fetchedResume } = useQuery(
    [resumeQueryId, id],
    async ({ signal }) => {
      const response = await apiClient.get<Resume>(`/resumes/${id}`, { signal })
      return response.data
    },
    {
      enabled: !hasMatchingEditedResume && !!id,
    },
  )

  return hasMatchingEditedResume ? editedResume : fetchedResume ?? null
}
