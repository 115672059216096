import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography from 'builder/styles/typography'

export const FooterWrapper = styled.div`
  ${Typography.Caption};
  display: flex;
  justify-content: center;
  color: ${Colors.Neutral50};

  ${Media.Phone`
    flex-direction: column;
    align-items: center;
    text-align: center;
  `};
`

export const Description = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
`

export const MailLink = styled.a`
  ${Typography.Caption};
  color: ${Colors.Blue50};
`
