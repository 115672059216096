import { IVisualProps } from 'builder/components/CIO-Dasboard-2.0/types'
import playImage from 'builder/components/CIO-Dasboard-2.0/assets/Play.svg'
import AiTag from '../../Tags/AiTag'
import * as Styled from './styles'

export const JobInterviewVisual = ({
  userPhotoUrl,
  alt,
  isMouseOver,
  colorScheme,
}: IVisualProps) => {
  return (
    <Styled.VisualContainer>
      <AiTag isMouseOver={isMouseOver} colorScheme={colorScheme} />
      <Styled.VisualImageContainer>
        <Styled.VisualImage src={userPhotoUrl} alt={alt} />
      </Styled.VisualImageContainer>
      <Styled.PlayImage src={playImage} />
    </Styled.VisualContainer>
  )
}
