import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'

export const DefaultDiv = styled.div``

export const DocumentGridWrapper = styled.div`
  ${Media.AbovePhone`margin: 20px;`}
  ${Media.Phone`margin: 5px;`}
`

export const DocumentNameText = styled.div`
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;

  &:hover {
    color: ${Colors.Blue50};
  }
`
