import { trackInternalEvent } from '@rio/tracking'
import Button from 'builder/components/Button'
import { RESUME_WRITING_SERVICE_PAGE_URL } from 'builder/modules/resumeReview'
import {
  Footer,
  FooterContainer,
  FooterContent,
  FooterTitle,
  FooterText,
  FooterImage,
} from './styles'

const StruggleToWriteFooter = () => {
  const trackEvent = () => {
    trackInternalEvent('click_resume_get_professional_help_button', {
      section: 'bottom',
    })
  }

  return (
    <Footer data-cta="get-help">
      <FooterContainer>
        <FooterContent>
          <FooterTitle>Struggling to Write?</FooterTitle>
          <FooterText>
            Your resume is one of your most important career tools. But many professionals have no
            idea where to start, what to include, and what to leave off of their resumes.
            That&rsquo;s where our partner, TopResume comes in. They can carefully craft your career
            story to target the job that you want to land.
          </FooterText>
          <Button
            onClick={trackEvent}
            as="a"
            target="_blank"
            rel="noreferrer noopener"
            href={RESUME_WRITING_SERVICE_PAGE_URL}
          >
            Get Professional Help
          </Button>
        </FooterContent>
        <FooterImage src={require('images/builder/resume-critique/get-help-cta-visual.svg')} />
      </FooterContainer>
    </Footer>
  )
}

export default StruggleToWriteFooter
