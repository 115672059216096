import styled from 'styled-components'
import Icon24 from 'builder/components/Icon'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Dialog = styled.div`
  position: absolute;
  left: 40px;
  bottom: 124px;
  z-index: 1;

  ${Media.Phone`
    display: flex;
    width: 100%;
    justify-content: center;
    left: 0;
    bottom: 116px;
  `}
`

export const Container = styled.div`
  bottom: 124px;
  padding: 24px;
  max-width: 348px;
  border-radius: var(--XS, 12px);
  background: ${Colors.White};
  box-shadow: 0px 8px 20px -4px rgba(15, 56, 113, 0.12), 0px 2px 4px -1px rgba(15, 56, 113, 0.08),
    0px 0px 1px 0px rgba(15, 56, 113, 0.32);
`
export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Title = styled.div`
  ${Typography.Subhead};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral90};
  margin: 16px 0;
`

export const SubTitle = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
  margin-bottom: 8px;
`

export const RemainingTitleText = styled.div`
  ${Typography.Tiny};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
`
export const RemainingValueText = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Regular};
  color: ${Colors.Neutral90};
  text-align: center;
  padding-top: 4px;
`

export const BodyContainer = styled.div`
  width: 100%;
  height: 80px;
  flex-shrink: 0;
  background-color: ${Colors.Neutral5};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FooterContainer = styled.div`
  padding-top: 24px;
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  width: 100%;
`

export const CloseIconContainer = styled.div`
  margin-left: 4px;
  height: 24px;
  width: 24px;
  background-color: ${Colors.Neutral10};
  color: ${Colors.Neutral50};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${Media.Hover`
    &:hover {
      cursor: pointer;
    }
  `};
`

export const CloseIcon = styled(Icon24.Close)`
  height: 20px;
  width: 20px;
`
