import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'

import { actions } from 'builder/modules/dashboard'
import { useI18n } from 'builder/hooks/useI18n'

import {
  Box,
  Container,
  Header,
  SubHeader,
  Image,
  SuccessImage,
  ActionButton,
  ImageContainer,
} from './styles'

import CoverImage from './assets/image.png'
import SuccessImageSrc from './assets/success.png'

export const SuccessState = () => {
  const dispatch = useDispatch()
  const { i18n } = useI18n()
  const navigate = useNavigate()

  const handleExploreJobs = () => {
    navigate('job-search')
  }

  useEffect(() => {
    dispatch(actions.updateStepperSuccessStateRequest())
    trackInternalEvent('stepper_success_state')
  }, [dispatch])

  return (
    <Container>
      <ImageContainer position="left">
        <Image position="left" flip src={CoverImage} />
      </ImageContainer>
      <Box>
        <SuccessImage src={SuccessImageSrc} />
        <Header>{i18n.t('builder.dashboard_v2.steps.sucess_state.title')}</Header>
        <SubHeader>{i18n.t('builder.dashboard_v2.steps.sucess_state.subtitle')}</SubHeader>
        <ActionButton onClick={handleExploreJobs}>
          {i18n.t('builder.dashboard_v2.steps.sucess_state.action')}
        </ActionButton>
      </Box>
      <ImageContainer position="right">
        <Image position="right" src={CoverImage} />
      </ImageContainer>
    </Container>
  )
}
