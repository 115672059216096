import { ResumeSocialProfile } from '@rio/types'
import { i18n } from 'builder/utils/i18n'
import { SectionNames } from 'builder/modules/resumeEditor'
import { addCardTo } from '../utils/addCardTo'
import { openCard } from '../utils/openCard'
import { CheckFn } from '../types'

const isValidCard = ({ link }: ResumeSocialProfile): boolean => {
  return typeof link === 'string' && link.length > 0
}

export const checkLinks: CheckFn = ({ socialProfiles: links }) => {
  const isComplete = links.filter(isValidCard).length > 0

  return {
    sectionId: SectionNames.socialProfiles,
    progress: isComplete ? 1 : 0,
    id: 'no_links',
    title: i18n.t('builder.resume_tuner.tips.no_links.title'),
    description: i18n.t('builder.resume_tuner.tips.no_links.description'),
    subject: i18n.t('builder.resume_tuner.tips.no_links.subject'),
    label: i18n.t('builder.resume_tuner.issues.no_links'),
    actionText: i18n.t('builder.resume_tuner.tips.no_links.action_text'),
    action: () => {
      const invalidCard = links.find(card => isValidCard(card) === false)
      invalidCard ? openCard(invalidCard) : addCardTo(SectionNames.socialProfiles)
    },
  }
}
