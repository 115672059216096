import { IVisualProps } from 'builder/components/CIO-Dasboard-2.0/types'
import NewTag from '../../Tags/NewTag'
import * as Styled from './styles'

export const JobSearchVisual = ({ imgUrl, alt }: IVisualProps) => {
  return (
    <Styled.VisualContainer>
      <NewTag />
      <Styled.VisualImageContainer>
        <Styled.VisualImage src={imgUrl} alt={alt} />
      </Styled.VisualImageContainer>
    </Styled.VisualContainer>
  )
}
