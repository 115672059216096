import { useMemo } from 'react'
import { useConfig } from 'builder/hooks/useConfig'

export const rioDomainList = ['resume.io', 'staging.resume.io']
export const cioDomainList = ['career.io', 'staging.career.io']

const useWebsiteHost = () => {
  const config = useConfig()
  const isSuperApp = config?.features?.superApp || false

  const getHost = useMemo(() => {
    if (window?.location) {
      return window?.location?.host
    }
    return isSuperApp ? 'career.io' : 'resume.io'
  }, [isSuperApp])

  return { getHost }
}

export default useWebsiteHost
