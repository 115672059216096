import { baseClient } from 'builder/modules/apiClient'

export type Item = {
  id: string
  name: string
}
export type TransformedItem = {
  id: string
  text: string
}

let lastCountrySelected: string
let lastStateSelected: string
let cachedCountriesList: TransformedItem[] = []
let cachedStatesList: TransformedItem[] = []
let cachedCityList: TransformedItem[] = []
let cachedIndustriesList: TransformedItem[] = []

const transformArray = (arr: Item[]): TransformedItem[] => {
  return arr.map(item => ({ id: item.id, text: item.name }))
}

const filterBy = (arr: TransformedItem[], query?: string): TransformedItem[] => {
  if (!query) return arr
  return arr.filter(item => item.text.toLowerCase().includes(query.toLowerCase()))
}

export const fetchCountriesSuggestions = async (query?: string, onlyUsa?: boolean) => {
  try {
    if (cachedCountriesList.length) {
      if (onlyUsa) {
        const usa = cachedCountriesList.find(item => item.text === 'United States')
        return usa ? [usa] : []
      }
      return filterBy(cachedCountriesList, query)
    }

    const url = `resume-distribution/datasets/countries`
    const response = await baseClient.get(url)
    const transformedArray = transformArray(response.data.data)
    cachedCountriesList = transformedArray

    if (onlyUsa) {
      const usa = transformedArray.find(item => item.text === 'United States')
      return usa ? [usa] : []
    }
    return filterBy(cachedCountriesList, query)
  } catch (error) {
    return []
  }
}

export const fetchStatesSuggestions = (countryId: string) => async (query?: string) => {
  try {
    if (lastCountrySelected === countryId && cachedStatesList.length) {
      return filterBy(cachedStatesList, query)
    }

    const url = `resume-distribution/datasets/countries/${countryId}/states`
    const response = await baseClient.get(url)

    const transformedArray = transformArray(response.data.data)
    cachedStatesList = transformedArray
    lastCountrySelected = countryId
    return filterBy(cachedStatesList, query)
  } catch (error) {
    return []
  }
}

export const fetchCitySuggestions = (state: string) => async (query?: string) => {
  try {
    if (lastStateSelected === state && cachedCityList.length) {
      return filterBy(cachedCityList, query)
    }

    const url = `/resume-distribution/datasets/states/${state}/cities`
    const response = await baseClient.get(url)

    const transformedArray = transformArray(response.data?.data)
    cachedCityList = transformedArray
    lastStateSelected = state
    return filterBy(cachedCityList, query)
  } catch (error) {
    return []
  }
}

export const fetchIndustriesSuggestions = async (category?: 'all' | 'top') => {
  try {
    if (cachedIndustriesList.length) {
      return cachedIndustriesList
    }

    const url = `resume-distribution/datasets/industries?category=${category || 'all'}`
    const response = await baseClient.get(url)
    cachedIndustriesList = response.data.data
    return response.data.data
  } catch (error) {
    return []
  }
}

export function convertFormDataToObject<T>(formData: FormData): T {
  const payload: Record<string, never> = {}

  for (const [key, value] of formData.entries()) {
    const keys = key.split(/[[\]]+/).filter(Boolean)

    keys.reduce((acc, k, i) => {
      acc[k] = acc[k] || (i === keys.length - 1 ? value : {})
      return acc[k]
    }, payload)
  }
  return payload as T
}
