import styled from 'styled-components'
import Typography from 'builder/styles/typography'
import Colors from 'builder/styles/colors'

export const Container = styled.div`
  width: 100%;
  gap: 12px;
  flex-wrap: wrap;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`

export const Industry = styled.div`
  ${Typography.Caption}
  display: flex;
  padding: 6px 10px;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.Neutral10};
  border-radius: 4px;
`
