import * as React from 'react'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useI18n } from 'builder/hooks/useI18n'

import { ButtonTheme } from 'builder/components/Button'
import { FileInput } from 'builder/components/PhotoUploadField/styles'
import { ALLOWED_FILES } from 'builder/components/FillResumeModal/constants'
import { MouseClickEvent } from 'builder/modules/ui'
import {
  ReplacePopup,
  PopupContainer,
  OptionsContainer,
  Options,
  OptionPreview,
  OptionRight,
  OptionTitle,
  OptionContent,
  OptionsGradient,
  CancelButton,
  ButtonWrapper,
  MobileBackgroundShadow,
} from './styles'

import resumePlaceholder from './../assets/replace-resume-placeholder.png'
import uploadResume from './../assets/upload-resume.png'

const TRANSLATION = `builder.resume_distribution.dashboard.header.resume_replace`

type Ref = React.ForwardedRef<HTMLDivElement>
interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onClick'> {
  type?: string
  setResumeSelection: (prop: boolean) => void
  handleFileInputChange: (prop: React.ChangeEvent<HTMLInputElement>) => void
}

export const ReplaceResumePopup = React.forwardRef((props: Props, ref: Ref) => {
  const { setResumeSelection } = props
  const { i18n } = useI18n()
  const { isPhone } = useMediaQueries()

  const handleUploadClick = (event: MouseClickEvent) => {
    event.stopPropagation()
  }

  const ResumeOptions = [
    {
      icon: uploadResume,
      title: i18n.t(`${TRANSLATION}.upload_resume_popup_title`),
      content: i18n.t(`${TRANSLATION}.upload_resume_popup_content`),
      htmlId: 'file-resume-replace-upload',
      element: (
        <FileInput
          type="file"
          onClick={handleUploadClick}
          onChange={props.handleFileInputChange}
          accept={ALLOWED_FILES}
          id="file-resume-replace-upload"
        />
      ),
    },
    {
      icon: resumePlaceholder,
      title: i18n.t(`${TRANSLATION}.replace_resume_popup_title`),
      content: i18n.t(`${TRANSLATION}.replace_resume_popup_content`),
      onClick: () => {
        setResumeSelection(true)
      },
    },
  ]

  return (
    <MobileBackgroundShadow>
      <ReplacePopup ref={ref}>
        <PopupContainer>
          {ResumeOptions.map((option, index) => (
            <OptionsContainer key={index} onClick={option.onClick}>
              <Options htmlFor={option.htmlId}>
                <OptionPreview src={option.icon} />
                <OptionRight>
                  <OptionTitle>{option.title}</OptionTitle>
                  <OptionContent>{option.content}</OptionContent>
                </OptionRight>
              </Options>
              {option.element}
              <OptionsGradient />
            </OptionsContainer>
          ))}
        </PopupContainer>
      </ReplacePopup>
      {isPhone && (
        <ButtonWrapper>
          <CancelButton theme={ButtonTheme.ghost}>
            {i18n.t(`${TRANSLATION}.mobile_cancel_button`)}
          </CancelButton>
        </ButtonWrapper>
      )}
    </MobileBackgroundShadow>
  )
})
