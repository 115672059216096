import { memo } from 'react'
import { i18n } from 'builder/utils/i18n'
import ModalOverlay from 'builder/components/ModalOverlay'
import { ButtonSize } from 'builder/components/Button'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { ButtonContainer, Cancel, CloseIcon, Container, HeaderSection, Save, Title } from './styles'

type EditJobTitleProps = {
  handleDeleteJobPosting: () => void
  handleCancelDeleteJobPosting: () => void
}

const DeleteJobPosting = memo(
  ({ handleDeleteJobPosting, handleCancelDeleteJobPosting }: EditJobTitleProps) => {
    const { isPhone } = useMediaQueries()
    const buttonSize = isPhone ? ButtonSize.small : ButtonSize.default

    return (
      <ModalOverlay overlayFadeDuration={300} contentSlideDuration={300}>
        <Container>
          <HeaderSection>
            <Title>{i18n.t('builder.resume_optimizer.edit_job_details.delete_message')}</Title>
            <CloseIcon onClick={handleCancelDeleteJobPosting} />
          </HeaderSection>
          <ButtonContainer>
            <Cancel size={buttonSize} theme="ghost" onClick={handleCancelDeleteJobPosting}>
              {i18n.t('builder.resume_optimizer.job_posting.cancel')}
            </Cancel>
            <Save size={buttonSize} onClick={handleDeleteJobPosting}>
              {i18n.t('builder.account.delete_dialog.delete')}
            </Save>
          </ButtonContainer>
        </Container>
      </ModalOverlay>
    )
  },
)

export default DeleteJobPosting
