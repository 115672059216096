import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StepNumber = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: ${({ visited }) => (visited ? Colors.Blue50 : Colors.Neutral20)};
  display: flex;
  align-items: center;
  justify-content: center;
  ${Typography.Caption};
  color: ${({ visited }) => (visited ? Colors.White : Colors.Neutral50)};
  font-weight: 600;

  margin-right: 12px;
  transition: background-color 0.2s ease, color 0.2s ease;
`

export const StepTitle = styled.div`
  color: ${({ visited }) => (visited ? Colors.Neutral90 : Colors.Neutral50)};
  transition: color 0.2s ease;
`

export const Step = styled.div`
  display: flex;
  align-items: center;

  &:after {
    content: '';
    width: 32px;
    height: 1px;
    background-color: ${Colors.Neutral20};
    margin: 0 8px;
    color: ${Colors.Neutral20};
  }

  &:last-child:after {
    display: none;
  }
`
