import styled from 'styled-components'
import Colors from 'builder/styles/colors'

export const Container = styled.div`
  display: flex;
  height: 336px;
  width: 100%;
  gap: 20px;
  position: relative;
`

export const Card = styled.div`
  display: flex;
  width: 360px;
  height: 336px;
  flex-shrink: 0;
  border-radius: var(--S, 16px);
  background: linear-gradient(180deg, ${Colors.Neutral5} 0%, rgba(247, 249, 252, 0) 42.81%);
`

export const TextContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
`
