import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Button from 'builder/components/Button'
import { Typography, FontWeights } from 'builder/styles/typography'
import Shadows from 'builder/styles/shadows'

// Components
export const Container = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  padding-top: 24px;
  padding-bottom: 58px;
  margin: 0;

  ${Media.Tablet`
    padding-bottom: 58px;
    padding-left: 44px;
    padding-right: 44px;
    justify-content: center;
    align-items: center;
  `};

  ${Media.Phone`
    padding-top: 32px
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
  `};
  //padding: 28px 160px 58px 160px;
`
export const Content = styled.div`
  max-width: 1120px;
  margin: 0 auto;

  ${Media.Tablet`
    width: 100%;
    align-items: center;
  `};

  ${Media.Phone`
    width: 100%;
    justify-content: center;
  `};
`
export const Title = styled.div`
  ${Typography.S};
  ${FontWeights.DemiBold};

  width: 404px;
  margin-bottom: 40px;

  ${Media.Tablet`
    margin-left: 0px;
  `};

  ${Media.Phone`
    width: 335px;
    margin-left: 0px;
    margin-bottom: 25px;
  `};
`

export const MainTitle = styled.div`
  ${Typography.S};
  ${FontWeights.DemiBold};

  width: 100%;
  margin-bottom: 40px;

  ${Media.Tablet`
    margin-left: 0px;
  `};

  ${Media.Phone`
    width: 335px;
    margin-left: 0px;
    margin-bottom: 25px;
  `};
`

export const SubTitle = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Regular};
  color: ${Colors.Neutral90};
  margin-bottom: 20px;
  width: 242px;

  ${Media.Tablet`
    margin-left: 0;
    width: 242px;
  `};

  ${Media.Phone`
    margin-left: 0;
    width: 287px;
  `};
`
export const Section = styled.div`
  width: 100%;
  margin-top: 2px;

  ${Media.AboveTablet`
    height: 231px;
    margin-top: 56px;
  `};

  ${Media.Tablet`
    margin-top: 56px;
  `};

  ${Media.Phone`
    margin-top: 48px;
  `};
`
export const SectionTitle = styled.div`
  ${Typography.M};
  margin-bottom: 8px;
  ${FontWeights.DemiBold}

  ${Media.Phone`
    margin-left: 0;
  `};
`
export const SectionInner = styled.div`
  width: 100%;
  border-radius: 6px;
  background-color: ${Colors.White};

  ${Media.Tablet`
    width: 680px;
  `}

  ${Media.Phone`
    width: 100%;
    border-radius: 0;
  `};
`
export const Row = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-flow: row wrap;

  &:last-child {
    margin-bottom: 0;
  }

  ${Media.Phone`
    flex-direction: column;
    margin-bottom: 0;

    &:first-child {
      margin-bottom: 20px;
    }
  `};
`
export const Card = styled.div`
  ${Shadows.lightWithBorder.low};
  width: 1120px;
  height: 231px;
  border-radius: 16px;
  align-items: center;
  display: flex;

  ${Media.AboveTablet`
    padding-left: 48px;
  `}

  ${Media.Tablet`
    width: 680px;
    height: 280px;
    padding-left: 48px;
  `}

  ${Media.Phone`
    width: 335px;
    height: 460px;
    flex-direction: column;
    padding: 0px;
  `}
`
export const NegotiateCardContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 32px;

  ${Media.Tablet`
    width: 680px;
    flex-wrap: wrap;
    gap: 24px;
  `}

  ${Media.Phone`
    width: 335px;
    flex-direction: column;
    justify-content: center;
    gap: 0px;
  `}
`
export const NegotiateCard = styled.div`
  ${Shadows.lightWithBorder.low};
  border-radius: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:last-of-type {
    margin-right: 0;
  }

  ${Media.AboveTablet`
    width: 352px;
    height: 224px;
    margin-right: 0;
  `}

  ${Media.Tablet`
    width: 328px;
    height: 256px;
    margin-right: 0;
  `}

  ${Media.Phone`
    width: 335px;
    height: 256px;
    margin-right: 0;
    margin-bottom: 16px
  `}
`
export const NegotiateCardHeading = styled.div`
  ${Typography.Caps};
  ${FontWeights.DemiBold};
  color: ${Colors.Blue50};
  text-transform: uppercase;
  width: 280px;
  margin-bottom: 12px;
`
export const NegotiateCardText = styled.div`
  ${Typography.TextS};
  color: ${Colors.Neutral90};
  width: 298px;
  margin-bottom: 12px;

  ${Media.Tablet`
    width: 280px;
  `};

  ${Media.Phone`
    width: 280px;
  `};
`
export const ButtonContainer = styled.div`
  display: flex;

  ${Media.Tablet`
    margin-top: 32px
  `}
`
export const GaugeContainer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${Media.Phone`
    width: 100%;
  `}
`
export const ButtonHolder = styled.div`
  display: flex;
  flex-basis: auto;
`
export const OfferDetailsContainer = styled.div`
  display: flex;

  ${Media.AboveTablet`
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 54px;
    margin-right: 54px;
  `}

  ${Media.Tablet`
    width: 100%;
    flex-direction: column;
    margin-left: 64px;
  `}

  ${Media.Phone`
    flex-direction: column;
    margin-left: 0px;
    padding-left: 24px;
    padding-right: 24px;
  `}
`
export const NoOfferDetailsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 48px;

  ${Media.Tablet`
    margin-right: 32px;
  `}

  ${Media.Phone`
    padding: 24px;
    flex-direction: column;
    width: 100%;
    margin-right: 0px;
  `}
`
export const NoOfferDetailsLeftPanel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${Media.Phone`
   flex-direction: column;
   margin-bottom: 64px;
  `}
`

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`
export const BoxItem = styled.div`
  ${Typography.Caption};
  ${FontWeights.Regular};

  color: ${Colors.Neutral50};

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
`
export const StyledButton = styled(Button)`
  margin-right: 10px;
`
export const SectionSubTitle = styled(SubTitle)`
  ${Typography.Subhead};
  color: ${Colors.Neutral90};
  margin-bottom: 16px;

  ${Media.Tablet`
    margin-bottom: 24px;
  `}

  ${Media.Phone`
    margin-bottom: 24px;
  `}
`
export const Header = styled.div`
  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${Colors.Indigo90};
  width: 108px;

  ${Media.Tablet`
    width: 130px;
    ${Typography.Subhead};
  `}

  ${Media.Phone`
    ${Typography.Subhead};
  `}
`
export const HeaderBox = styled.div`
  display: flex;
  margin-bottom: 24px;

  ${Media.Phone`
    padding: 0px;
  `}
`
export const IconsHolder = styled.div`
  display: flex;
  height: 80px;
  width: 80px;
  justify-content: center;
  align-items: center;
  margin-right: 32px;
`
export const IconImage = styled.img`
  height: 80px;
  width: 80px;
`
export const NoOfferTitle = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Regular};
  color: ${Colors.Neutral90};

  ${Media.Phone`
    margin-left: 0;
    margin-top: 32px
  `};
`
export const ContentHolder = styled.div``
