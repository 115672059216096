import times from 'lodash/times'
import { Skeleton, SkeletonAtomsColumns } from './styles'

export const SkeletonColumns = () => (
  <SkeletonAtomsColumns>
    {times(3).map(key => (
      <Skeleton key={key} />
    ))}
  </SkeletonAtomsColumns>
)
