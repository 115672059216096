import { trackInternalEvent } from '@rio/tracking'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { actions as uiActions } from 'builder/modules/ui'
import { selectors as panelSelectors } from 'builder/modules/panel'
import { ResumeIDStatuses } from 'builder/modules/constants'
import { IService } from 'builder/components/CIO-Dasboard-2.0/types'
import ServiceCard from 'builder/components/CIO-Dasboard-2.0/Components/ServiceCard'
import useWebsiteHost from 'builder/components/CIO-Dasboard-2.0/hooks/useWebsiteHost'
import JobTrackerExtensionBanner from '../JobTrackerExtensionBanner'
import { useCareerServiceTitle } from '../../hooks/useCareerServiceTitle'
import * as Styled from './styles'

type Props = {
  services: IService[]
  onServiceCardClick: (name: string | undefined) => void
  tabTitle: string | undefined
  isSuperApp: boolean | undefined
}

export const CareerGoalGrid = ({ services, onServiceCardClick, tabTitle }: Props) => {
  const dispatch = useDispatch()
  const resumeStatus = useTypedSelector(panelSelectors.resumeStatus)
  const tab = useCareerServiceTitle({ name: tabTitle })

  const getLoaderStatus = (key: string | null, name: string) => {
    if (key) {
      return (name === 'resume' || name === 'tuner') && resumeStatus === ResumeIDStatuses.skipped
    }
    return false
  }

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const key = queryParams.get('tr_promo_banner')

  // Get current host
  const { getHost } = useWebsiteHost()

  return (
    <>
      {tab?.index === 0 && (
        <JobTrackerExtensionBanner showOnboarding componentType="job_tracker_dashboard" />
      )}
      <Styled.Wrapper>
        {services.map(service => (
          <ServiceCard
            key={service.title}
            service={service}
            isLoading={getLoaderStatus(key, service.title)}
            onClick={async () => {
              trackInternalEvent('click_feature_card', {
                label: tabTitle,
                target: service.targetEventName,
                website_host: getHost,
                source: 'dashboard',
              })
              service?.isComingSoon && onServiceCardClick(service?.cardName)
              if (service?.isPostPremiumActive) {
                dispatch(uiActions.setTrackJobPaywall(true))
              } else {
                service.action && service.action()
              }
            }}
          />
        ))}
      </Styled.Wrapper>
    </>
  )
}
