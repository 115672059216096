import styled, { css, keyframes } from 'styled-components'
import { animated } from '@react-spring/web'
import Media from 'builder/styles/media'

const moveRightAnimation = keyframes`
  from {
    right: 30%;
    bottom: 15%;
  }
  to {
    right: 0%;
    bottom: 0;
  }
`

export const ModalContainer = styled(animated.div)<{
  $modalAsCentre?: boolean
  $isOverlay?: boolean
  $isHelper: boolean
  $helperAnimation?: boolean
}>`
  ${props =>
    props.$modalAsCentre
      ? css`
          display: flex;
          justify-content: center;
          align-items: center;
          position: fixed;
          left: 0;
          right: ${props.$isHelper ? '0' : '-100vh'};
          bottom: ${props.$isHelper ? '0' : '-100vh'};
          top: 0;
          z-index: ${props.$isOverlay ? 12000 : 0};
        `
      : css`
          flex-direction: column;
          position: fixed;
          right: 25px;
          bottom: ${props.$isHelper ? '32px' : '-100vh'};
          z-index: 9999999999;
        `}

  opacity: ${props => (props.$isHelper ? '100' : '1')};
  transition: height 0.5s ease-in-out, opacity 0.3s ease-in-out, bottom 0.4s ease-in-out;

  ${Media.Phone`
    left: 0;
    right: 0;
    top: initial;
    display: initial;
    justify-content: initial;
    align-items: initial;
    animation: none;
  `}

  ${props =>
    props.$isHelper
      ? Media.Phone`
      bottom: 0px;
    `
      : Media.Phone`
      bottom: -100vh;
    `}

  ${props =>
    props.$helperAnimation &&
    css`
      animation: ${moveRightAnimation} 0.5s ease-in-out;
    `}
`
