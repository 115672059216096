import styled from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Button = styled.div`
  ${Typography.Caption};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding: 6px 14px;
  width: 100%;
  border-radius: 4px;
  background-color: ${hexToRgba(Colors.Blue10, 0)};
  color: ${Colors.Blue50};
  font-weight: 600;
  cursor: pointer;
  outline: none;
  transition: background-color 0.15s;

  ${Media.Hover`
    &:hover {
      background-color: ${hexToRgba(Colors.Blue10, 0.5)};
    }
  `};
`

export const ButtonText = styled.span``

export const IconWrapper = styled.div`
  margin-right: 2px;
`
