import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { i18n } from 'builder/utils/i18n'
import Toggle from 'builder/components/Toggle'
import { ExtraContainer } from './styles'

class SkillsExtra extends PureComponent {
  static propTypes = {
    updateSimpleField: PropTypes.func,
    hideSkillLevel: PropTypes.bool,
  }

  handleChange = event => {
    const { updateSimpleField } = this.props
    if (updateSimpleField) {
      updateSimpleField('hideSkillLevel', event.target.checked, false)
    }
  }

  render() {
    const { hideSkillLevel } = this.props
    return (
      <ExtraContainer>
        <Toggle
          label={i18n.t(`builder.resume_editor.skills_hide_level`)}
          checked={hideSkillLevel}
          onChange={this.handleChange}
        />
      </ExtraContainer>
    )
  }
}

export default SkillsExtra
