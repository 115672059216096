/**
 * Converts a base64 formatted string (like "data:image/png;base64,abcde") to `File`
 * making it possible to send data as a form payload
 */
export function convertBase64ToFile(url: string, filename: string): File {
  const [meta, data] = url.split(';base64,')
  const [, type] = meta.split(':') // e.g. "image/jpeg"

  const source = atob(data)
  const blobArray = new Uint8Array(source.length)

  for (let i = 0; i < source.length; i++) blobArray[i] = source.charCodeAt(i)

  return new File([blobArray], filename, { type })
}
