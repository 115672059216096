import styled from 'styled-components'
import Colors from 'builder/styles/colors'

export const TabsContainer = styled.div`
  position: relative;
  display: flex;
  border-bottom: 1px solid ${Colors.Neutral20};
`

export const Tab = styled.div<{ isActive: boolean }>`
  color: ${props => (props.isActive ? Colors.Neutral90 : Colors.Neutral50)};
  margin-right: 24px;
  padding-bottom: 16px;
  transition: color 0.1s;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: ${props => (props.isActive ? Colors.Neutral90 : Colors.Blue50)};
  }
`

export const TabSelector = styled.div<{ width: number; offset: number }>`
  display: ${props => (props.width ? 'block' : 'none')};
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100px;
  height: 2px;
  background-color: ${Colors.Blue50};
  transform-origin: 0 0;
  transition: transform 0.3s;
  transform: ${props => `translateX(${props.offset}px) scaleX(${props.width / 100})`};
`
