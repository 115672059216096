import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'

import { Step, actions, selectors } from 'builder/modules/dashboard'
import {
  selectors as profileSelectors,
  actions as profileActions,
} from 'builder/modules/careerProfile'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import Spinner from 'builder/components/Spinner'

import { FetchStatuses } from 'builder/modules/constants'
import { useAutoApply } from 'builder/views/AutoApply/hooks/useAutoApply'
import { Onboarding } from '../Onboarding/Onboarding'
import { SendResumeStep } from './Steps/SendResume'
import { SaveJobs } from './Steps/SaveJobs/SaveJobs'
import { HelpYouStep } from './Steps/HelpYou/HelpYou'
import { ExploreJobs } from './Steps/ExploreJobs/ExploreJobs'
import { CreateResumeStep } from './Steps/CreateResume/CreateResume'

import { StepsProgress } from './StepsProgress/StepsProgress'
import { SuccessState } from './SuccessState/SuccessState'

import { Container, StepsContainer } from './styles'
import { CareerProfileStep } from './Steps/CareerProfile/CareerProfile'
import { MergedStepData, stepperDefaultState, Steps } from './types'

interface IProps {
  showStepper?: boolean
}

export const Stepper = ({ showStepper }: IProps) => {
  const [activeStep, setActiveStep] = useState(stepperDefaultState)
  const [mappedSteps, setMappedSteps] = useState<MergedStepData[]>([])
  const dispatch = useDispatch()
  const stepsData = useTypedSelector(selectors.stepsData)
  const stepsAreLoaded = useTypedSelector(selectors.isLoaded)
  const stepperSuccessStateSeen = useTypedSelector(selectors.stepperSuccessStateSeen)
  const features = useFeaturesConfig()
  const [runOnboarding, setRunOnboarding] = useState<boolean>(false)
  const isAutoApplyComplete = useTypedSelector(profileSelectors.isAutoApplyComplete)
  const fetchIsAutoApplyCompleteStatus = useTypedSelector(
    profileSelectors.fetchIsAutoApplyCompleteStatus,
  )

  const { isAutoApplyFeatureEnabled } = useAutoApply()

  const handleActiveStep = (step: Step) => {
    setActiveStep({ ...stepperDefaultState, [step]: true })
  }

  const mapStepsData = useCallback(() => {
    const steps: Steps = {
      [Step.RESUME_CREATION]: { enabled: true, order: 0 },
      [Step.APPLY_FOR_YOU]: { enabled: isAutoApplyFeatureEnabled, order: 1 },
      [Step.RESUME_DISTRIBUTION]: { enabled: features?.showResumeDistribution(), order: 2 },
      [Step.PERSONALIZED_JOBS]: { enabled: true, order: 3 },
      [Step.JOB_TRACKER_WISHLIST]: { enabled: !isAutoApplyFeatureEnabled, order: 4 },
      [Step.CAREER_PROFILE]: { enabled: features.features.careerProfile, order: 5 },
    }

    const mappedData = Object.keys(stepsData)
      .map(step => ({
        id: step as Step,
        ...steps[step as Step],
        ...stepsData[step as Step],
      }))
      .filter(step => step.enabled)
      .sort((a, b) => a.order - b.order)

    return mappedData
  }, [stepsData]) // eslint-disable-line react-hooks/exhaustive-deps

  const getCompletedStepsCount = useCallback(
    (stepsData: MergedStepData[]) => {
      return (
        (stepsData.filter(step => step.completed).length || 0) +
        (isAutoApplyFeatureEnabled && isAutoApplyComplete ? 1 : 0)
      )
    },
    [isAutoApplyFeatureEnabled, isAutoApplyComplete],
  )

  const setDefaultActiveStep = useCallback(
    (stepsData: MergedStepData[]) => {
      const firstIncompleteStep = stepsData.find(step =>
        step.id === Step.APPLY_FOR_YOU ? !isAutoApplyComplete : !step.completed,
      )
      if (firstIncompleteStep) {
        handleActiveStep(firstIncompleteStep.id)
      }
    },
    [isAutoApplyComplete],
  )

  const initStepsData = useCallback(() => {
    const mappedData = mapStepsData()
    setDefaultActiveStep(mappedData)

    if (getCompletedStepsCount(mappedData) !== mappedData.length) {
      trackInternalEvent('see_stepper_widget')
    }

    setMappedSteps(mappedData)
  }, [getCompletedStepsCount, mapStepsData, setDefaultActiveStep])

  useEffect(() => {
    dispatch(actions.fetchStepsDataRequest())
    dispatch(actions.fetchStepperSuccessStateRequest())
    dispatch(profileActions.fetchIsAutoApplyComplete())

    const timer = setTimeout(() => {
      setRunOnboarding(true)
    }, 900)

    return () => {
      clearTimeout(timer)
      setMappedSteps([])
      dispatch(actions.setStepsLoadedStatus(false))
    }
  }, [dispatch])

  useEffect(() => {
    if (stepsAreLoaded && fetchIsAutoApplyCompleteStatus === FetchStatuses.loaded) initStepsData()
  }, [initStepsData, stepsAreLoaded, fetchIsAutoApplyCompleteStatus])

  const allStepsAreCompleted =
    !!mappedSteps.length && getCompletedStepsCount(mappedSteps) === mappedSteps.length
  const showResumeDistribution = features?.showResumeDistribution()

  const steps = useMemo(() => {
    const sortedSteps = []
    const completedSteps = []

    const CreateResume = (
      <CreateResumeStep
        isCompleted={stepsData.resume_creation.completed}
        isOpen={activeStep[Step.RESUME_CREATION]}
        setIsOpen={() => handleActiveStep(Step.RESUME_CREATION)}
        score={stepsData.resume_creation.score}
        resumeId={stepsData.resume_creation.resume_id}
      />
    )

    if (stepsData.resume_creation.completed) {
      sortedSteps.push(CreateResume)
      completedSteps.push(Step.RESUME_CREATION)
    }

    const HelpYou = (
      <HelpYouStep
        isCompleted={isAutoApplyComplete}
        isOpen={activeStep[Step.APPLY_FOR_YOU]}
        setIsOpen={() => handleActiveStep(Step.APPLY_FOR_YOU)}
      />
    )

    if (isAutoApplyComplete && isAutoApplyFeatureEnabled) {
      sortedSteps.push(HelpYou)
      completedSteps.push(Step.APPLY_FOR_YOU)
    }

    const SendResume = (
      <SendResumeStep
        isCompleted={stepsData.resume_distribution.completed}
        isOpen={activeStep[Step.RESUME_DISTRIBUTION]}
        setIsOpen={() => handleActiveStep(Step.RESUME_DISTRIBUTION)}
      />
    )

    if (stepsData.resume_distribution.completed && showResumeDistribution) {
      sortedSteps.push(SendResume)
      completedSteps.push(Step.RESUME_DISTRIBUTION)
    }

    const ExploreJobsStep = (
      <ExploreJobs
        isCompleted={stepsData.personalized_jobs.completed}
        isOpen={activeStep[Step.PERSONALIZED_JOBS]}
        setIsOpen={() => handleActiveStep(Step.PERSONALIZED_JOBS)}
      />
    )

    if (stepsData.personalized_jobs.completed) {
      sortedSteps.push(ExploreJobsStep)
      completedSteps.push(Step.PERSONALIZED_JOBS)
    }

    const SaveJobsStep = (
      <SaveJobs
        isCompleted={stepsData.job_tracker_wishlist.completed}
        isOpen={activeStep[Step.JOB_TRACKER_WISHLIST]}
        setIsOpen={() => handleActiveStep(Step.JOB_TRACKER_WISHLIST)}
        jobsNeeded={stepsData.job_tracker_wishlist.needed}
      />
    )

    if (stepsData.job_tracker_wishlist.completed && !isAutoApplyFeatureEnabled) {
      sortedSteps.push(SaveJobsStep)
      completedSteps.push(Step.JOB_TRACKER_WISHLIST)
    }

    const CareerProfile = (
      <CareerProfileStep
        isCompleted={stepsData.career_profile.completed}
        score={stepsData.career_profile.current_score || 0}
        isOpen={activeStep[Step.CAREER_PROFILE]}
        setIsOpen={() => handleActiveStep(Step.CAREER_PROFILE)}
      />
    )

    if (stepsData.career_profile.completed && features.features.careerProfile) {
      sortedSteps.push(CareerProfile)
      completedSteps.push(Step.CAREER_PROFILE)
    }

    if (!completedSteps.includes(Step.RESUME_CREATION)) {
      sortedSteps.push(CreateResume)
    }

    if (!completedSteps.includes(Step.APPLY_FOR_YOU) && isAutoApplyFeatureEnabled) {
      sortedSteps.push(HelpYou)
    }

    if (!completedSteps.includes(Step.RESUME_DISTRIBUTION) && showResumeDistribution) {
      sortedSteps.push(SendResume)
    }

    if (!completedSteps.includes(Step.PERSONALIZED_JOBS)) {
      sortedSteps.push(ExploreJobsStep)
    }

    if (!completedSteps.includes(Step.JOB_TRACKER_WISHLIST) && !isAutoApplyFeatureEnabled) {
      sortedSteps.push(SaveJobsStep)
    }

    if (!completedSteps.includes(Step.CAREER_PROFILE) && features.features.careerProfile) {
      sortedSteps.push(CareerProfile)
    }

    return sortedSteps.map((element, index) => <Fragment key={index}>{element}</Fragment>)
    // eslint-disable-next-line max-len
  }, [stepsData, activeStep, isAutoApplyComplete]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      {!stepsAreLoaded || fetchIsAutoApplyCompleteStatus === FetchStatuses.loading ? (
        <Spinner />
      ) : (
        <>
          <StepsProgress
            showSuccessState={allStepsAreCompleted && !stepperSuccessStateSeen}
            completedSteps={getCompletedStepsCount(mappedSteps)}
            totalSteps={mappedSteps.length}
            showProgress={!!showStepper}
          />
          {allStepsAreCompleted && !stepperSuccessStateSeen && <SuccessState />}
          {showStepper && !allStepsAreCompleted && (
            <>
              {runOnboarding && <Onboarding />}
              <StepsContainer id="first-step-onboarding">{steps}</StepsContainer>
            </>
          )}
        </>
      )}
    </Container>
  )
}
