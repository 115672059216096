/* eslint-disable react/no-unescaped-entities */
import { Container, LoadingImage } from './styles'
import loadingScreen1URL from './assets/img/loadingscreen1.gif'
import Header from './header'
const LoadingScreen1 = () => {
  return (
    <Container>
      <Header
        title="Scanning by the main keywords"
        subtitle="Give us a moment and don't close this window, we are preparing your resume review"
      />
      <LoadingImage src={loadingScreen1URL}></LoadingImage>
    </Container>
  )
}

export default LoadingScreen1
