import { useContext, useEffect, useMemo } from 'react'
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom'
import type { History, Blocker, Transition } from 'history'

/**
 * Blocks all navigation attempts. This is useful for preventing the page from
 * changing until some condition is met, like saving form data.
 *
 * @see https://reactrouter.com/api/useBlocker
 */
export function useBlocker(blocker: Blocker, when: boolean, ignoreParentRoute?: string[]) {
  const navigator = useContext(NavigationContext).navigator as History
  const ignoreRoutes = useMemo(() => ignoreParentRoute, [])

  useEffect(() => {
    if (!when) return

    const unblock = navigator.block((tx: Transition) => {
      if (!ignoreRoutes || !ignoreRoutes.some(route => tx.location.pathname.includes(route))) {
        const autoUnblockingTx = {
          ...tx,
          retry() {
            /**
             * Automatically unblock the transition so it can
             * play all the way through before retrying it.
             */
            unblock()
            tx.retry()
          },
        }

        blocker(autoUnblockingTx)
      } else {
        unblock()
        tx.retry()
      }
    })

    return unblock
  }, [navigator, blocker, when, ignoreRoutes])
}
