import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'
import { selectors } from 'builder/modules/careerCoaching'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { CareerCoachingStepProgress } from 'builder/components/CareerCoachingStepProgress'
import { coachPackageProgress } from 'builder/components/CareerCoachingStepProgress/components/constants'
import { APP_BASE_PATH } from 'builder/modules/constants'

import { Body } from './components/Body'
import { Header } from './components/Header'
import { Footer } from './components/Footer'
import { Container, Sections, Divider, Package, Badge } from './styles'

const PackageCard = (): JSX.Element => {
  const [query] = useSearchParams()
  const paymentReason = query.get('payment_reason')
  const queryParams = query.get('on_success_redirect')
  const redirectTo = queryParams || '/app/coach-listing'
  const coachingPlans = useTypedSelector(selectors.coachingPlans)

  const getUrlToOpen = useCallback(
    (paymentReason: string | null, name: string) => {
      if (paymentReason) {
        return (
          APP_BASE_PATH +
          // eslint-disable-next-line max-len
          `/billing/payment?plan=${name}&on_success_redirect=${redirectTo}&payment_reason=${paymentReason}`
        )
      }

      return APP_BASE_PATH + `/billing/payment?plan=${name}&on_success_redirect=${redirectTo}`
    },
    [redirectTo],
  )

  const handleClick = useCallback(
    (name: string) => {
      // clear preferences on selecting a package
      localStorage.removeItem('INTAKE_PREFERENCES')

      const urlToOpen = getUrlToOpen(paymentReason, name)
      window.open(urlToOpen, '_self')
    },
    [getUrlToOpen, paymentReason],
  )

  return (
    <>
      <CareerCoachingStepProgress activatedPage={coachPackageProgress} title="Choose Plan" />
      <Container>
        {coachingPlans.map((pack, index: number) => {
          return (
            <Package key={index}>
              {pack.discount && <Badge>{pack.discount}</Badge>}
              <Sections>
                <Header {...{ pack }} />
                <Divider />
                <Body {...{ pack }} />
                <Footer {...{ pack, handleClick }} />
              </Sections>
            </Package>
          )
        })}
      </Container>
    </>
  )
}

export default PackageCard
