import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useCallback } from 'react'
import { selectors as uiSelectors } from 'builder/modules/ui'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { actions, selectors, Label } from 'builder/modules/careerPlanning'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'

import CioLimitModal from 'builder/components/CioLimitModal'
import UpcomingCareersModal from 'builder/components/UpcomingCareersModal'
import CareerPlanningContent from 'builder/components/CareerPlanningContent'
import CareerPlanningFindCoach from 'builder/components/CareerPlanningFindCoach'

import tunerURL from '../../components/CioLimitModal/assets/resume.jpg'
import { Container, FixedContainer } from './styles'
import TopBar from './components/TopBar'
import SideBar from './components/SideBar'
import Visual from './assets/imgs/visual.svg'

type Value = 'skip' | 'close'

const careerPlanningFeatures = [
  { id: 1, text: 'Resume builder and optimizer' },
  { id: 2, text: 'All-in-one job tracker' },
  { id: 3, text: 'AI interview prep' },
]

const title = "Harness the industry's best job search method"
const subTitle =
  ' Get the digital tools and proven strategies you need to run a successful job search.'

const CareerPlanningView = () => {
  const dispatch = useDispatch()
  const { isTablet } = useMediaQueries()
  const { features } = useFeaturesConfig()

  const showLimitModal = useTypedSelector(selectors.showLimitModal)
  const showUpcomingCareersModal = useTypedSelector(uiSelectors.showUpcomingCareersModal)

  useEffectOnMount(() => {
    dispatch(actions.fetchHeadings())
    dispatch(actions.fetchProgress())

    dispatch(actions.setIsScrollToView(true))
    dispatch(actions.setIsObserving(true))

    trackInternalEvent('enter_career_plan_page', Label)
  })

  const triggerEvent = useCallback((text: Value) => {
    if (title) {
      if (text === 'close') {
        trackInternalEvent('close_paywall', { ...Label })
      } else {
        trackInternalEvent('skip_paywall', { ...Label })
      }
    }
  }, [])

  const handleClose = (text: Value) => {
    dispatch(actions.setShowLimitModal(false))
    triggerEvent(text)
  }

  return (
    <>
      {showLimitModal && (
        <CioLimitModal
          title={title}
          subTitle={subTitle}
          features={careerPlanningFeatures}
          text="UNLOCK STATE-OF-THE-ART TOOLS INCLUDING"
          cancelButtom="Skip"
          upgradebutton="Upgrade Now"
          tunerImg={tunerURL}
          onClose={handleClose}
        />
      )}
      {showUpcomingCareersModal && (
        <UpcomingCareersModal
          visual={Visual}
          trackingLabel="execute_a_job_search"
          eventName="click_career_coaching_banner"
          title="Professionals at the service of your career"
          text="With personalized guidance and expert advice, we empower you to navigate the
                complexities of the job market and make informed decisions about your career path."
        />
      )}
      <Container>
        {isTablet ? (
          <TopBar />
        ) : (
          <FixedContainer>
            <SideBar />
          </FixedContainer>
        )}
        <CareerPlanningContent />
        {!isTablet && (
          <FixedContainer>
            {features.careerCoaching && (
              <CareerPlanningFindCoach trackingLabel="execute_a_job_search" />
            )}
          </FixedContainer>
        )}
      </Container>
    </>
  )
}

export default CareerPlanningView
