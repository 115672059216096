import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { Icon20 } from 'builder/components/Icon'
import Colors from 'builder/styles/colors'
import {
  ButtonContainer,
  CancelSubcriptionText,
} from 'builder/components/SubscriptionCancellationModal/styles'
import { i18n as I18n } from 'builder/utils/i18n'
import { InternalEvents } from 'builder/components/SubscriptionCancellationFormNew/common/types'
import { useCancellationFormContext } from '../FormSection'
import { BackButton } from './BackButton'
import { BackButtonContainer } from './BackButtonContainer'
import { ParentContainer } from './ParentContainer'
import { CancellationContainer, ButtonConfirm } from './styles'

/**
 * The dimensions for the styles used for each component have been referred from the figma
 * https://www.figma.com/file/wdX0fbRVlodq1Is8l1GwoV/APP-1372%3A-Subscription-cancelation?node-id=1%3A2&t=sJ8R8w5Ggv06RqH5-0
 */

const BackAndCancelButtons = () => {
  const { handleOpenFeaturesOfferModal, handlePromotionClick } = useCancellationFormContext()
  const navigate = useNavigate()

  const arrowStyles = { fill: Colors.Blue50 }
  return (
    <ParentContainer>
      <CancellationContainer>
        <ButtonContainer>
          <ButtonConfirm
            onClick={() => {
              trackInternalEvent(InternalEvents.abort)
              navigate('/')
            }}
          >
            {I18n.t('builder.subscription_cancellation.cards.keep_subscription')}
          </ButtonConfirm>
        </ButtonContainer>
        <CancelSubcriptionText onClick={handleOpenFeaturesOfferModal}>
          {I18n.t('builder.subscription_cancellation.cards.continue_cancellation')}
        </CancelSubcriptionText>
        <BackButtonContainer>
          <Icon20.ArrowLeft style={arrowStyles} />
          <BackButton onClick={() => handlePromotionClick(null)}>
            {I18n.t('builder.subscription_cancellation.cards.back')}
          </BackButton>
        </BackButtonContainer>
      </CancellationContainer>
    </ParentContainer>
  )
}

export default BackAndCancelButtons
