import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Transition from 'react-transition-group/Transition'

import { actions as uiActions, SnackbarTypes } from 'builder/modules/ui'
import LoadingSnackbar from './LoadingSnackbar'
import CancelableSnackbar from './CancelableSnackbar'
import WarningSnackbar from './WarningSnackbar'
import SuccessSnackBar from './SuccessSnackbar'
import FailureSnackbar from './FailureSnackbar'
import SimpleSnackbar from './SimpleSnackbar'
import OpenableSnackbar from './OpenableSnackbar'

import { Wrapper } from './styles'

const defaultStyle = {
  transform: 'translateY(64px)',
  transition: `transform 0.3s ease`,
}

const transitionStyles = {
  exited: { transform: 'translateY(64px)' },
  entered: { transform: 'translateY(0)' },
  exiting: { transform: 'translateY(64px)' },
}

class SnackBar extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    text: PropTypes.string,
    type: PropTypes.string,
    setCancelableSnackbarOpen: PropTypes.func,
    isShaking: PropTypes.bool,
    Icon: PropTypes.func,
    timer: PropTypes.number,
    setSnackbarShaking: PropTypes.func,
    url: PropTypes.string,
  }

  static defaultProps = {
    type: SnackbarTypes.loading,
  }

  componentDidUpdate(prevProps) {
    if (this.props.isShaking && !prevProps.isShaking) {
      setTimeout(() => this.props.setSnackbarShaking(false), 300)
    }
  }

  get currentSnackBar() {
    switch (this.props.type) {
      case SnackbarTypes.cancelable:
        return CancelableSnackbar

      case SnackbarTypes.warning:
        return WarningSnackbar

      case SnackbarTypes.success:
        return SuccessSnackBar

      case SnackbarTypes.failure:
        return FailureSnackbar

      case SnackbarTypes.simple:
        return SimpleSnackbar

      case SnackbarTypes.openable:
        return OpenableSnackbar

      case SnackbarTypes.loading:
      default:
        return LoadingSnackbar
    }
  }

  handleDismiss = () => {
    this.props.setCancelableSnackbarOpen({ status: false })
  }

  handleOpen = redirectUrl => {
    window.open(redirectUrl)
  }

  render() {
    const { isOpen, text, isShaking, url } = this.props
    const CurrentSnackBar = this.currentSnackBar

    return (
      <Transition
        in={isOpen}
        timeout={{
          enter: 100,
          exit: 300,
        }}
        mountOnEnter
        unmountOnExit
      >
        {state => (
          <Wrapper
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            <CurrentSnackBar
              text={text}
              onDismiss={this.handleDismiss}
              onOpen={() => this.handleOpen(url)}
              isShaking={isShaking}
            />
          </Wrapper>
        )}
      </Transition>
    )
  }
}

// ---
// Connect to data
// ---
function mapStateToProps(state) {
  return {
    isOpen: state.ui.isSnackBarOpen,
    text: state.ui.snackBarText,
    type: state.ui.snackBarType,
    isShaking: state.ui.isSnackBarShaking,
    url: state.ui.snackBarUrl,
  }
}

const mapDispatchToProps = {
  setCancelableSnackbarOpen: uiActions.setCancelableSnackbarOpen,
  setSnackbarShaking: uiActions.setSnackbarShaking,
}

export default connect(mapStateToProps, mapDispatchToProps)(SnackBar)
