import styled from 'styled-components'
import Colors from 'builder/styles/colors'

export const CompanyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`

export const CompanyJobs = styled.div`
  color: ${Colors.Neutral50};
  white-space: nowrap;
`
