import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import { fadeIn } from 'builder/styles/keyframes'
import { Typography, FontWeights } from 'builder/styles/typography'
import { Icon20 } from 'builder/components/Icon'

export const MainPart = styled.div`
  flex-shrink: 0;
  padding: 32px 44px 44px;

  ${Media.Phone`
    padding: 16px 20px 32px;
  `};
`

export const MobileMenuContainer = styled.div<{ barHeight: number }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(100% + 2px);
  background-color: ${Colors.White};
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  left: -1px;
  z-index: 10;
  animation: ${fadeIn} 0.3s ease;
  top: ${props => props.barHeight}px;
  height: ${props => `calc(100vh - ${props.barHeight}px)`};
`

export const NameBlock = styled.div`
  margin-bottom: 24px;
`

export const UserName = styled.div`
  ${Typography.XL};
  ${FontWeights.DemiBold};
`

export const OutLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  width: 100%;
  padding: 44px;
  background-color: ${Colors.Neutral10};

  ${Media.Phone`
    padding: 32px 20px;
  `};
`

export const OutLink = styled.a`
  ${Typography.Subhead};
  display: block;
  cursor: pointer;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const PremiumStatus = styled.div`
  margin-top: 4px;
  color: ${Colors.Neutral50};
`

export const Links = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const MenuLinkWrapper = styled.div`
  position: relative;
  margin-right: 32px;
  margin-bottom: 16px;

  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
`

export const MenuLink = styled(NavLink)<{ $isActive: boolean }>`
  ${Typography.Subhead};
  transition: color 0.15s;
  color: ${p => (p.$isActive ? Colors.Neutral50 : 'inherit')};

  &:hover {
    color: ${p => (p.$isActive ? Colors.Neutral50 : Colors.Blue50)};
  }

  &.active {
    color: ${Colors.Neutral50};
  }
`

export const UpgradeButtonContainer = styled.a`
  ${Typography.Caption};
  ${FontWeights.DemiBold};
  display: flex;
  align-items: center;
  padding: 8px 11px 8px 8px;
  margin-right: 32px;
  margin-bottom: 20px;
  border-radius: 4px;
  background-color: ${Colors.Indigo50};
  color: ${Colors.White};
  transition: background-color 0.15s;

  &:hover {
    background-color: ${Colors.Indigo60};
  }

  &:active {
    background-color: ${Colors.Indigo70};
  }
`

export const UpgradeButtonIcon = styled(Icon20.Crown)`
  margin-right: 4px;
`
