import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Shadows from 'builder/styles/shadows'
import BuilderButton from 'builder/components/Button'
import Media from 'builder/styles/media'
import { FeatureIcon } from 'builder/components/FeatureIcon'

const visibleStyles = css`
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
`

const hiddenStyles = css`
  visibility: hidden;
  opacity: 0;
  transform: translateY(24px);
`

const transformStyle = css`
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;
`

export const NotificationContainer = styled.div<{ isVisible?: boolean }>`
  position: fixed;
  width: 100%;
  padding: 16px;
  bottom: 0;
  display: flex;
  justify-content: center;

  ${transformStyle}
  ${props => (props.isVisible ? visibleStyles : hiddenStyles)};
  z-index: 99999;
`

export const Notification = styled.div`
  ${Shadows.lightWithBorder.mid};
  width: 100%;
  max-width: 800px;
  padding: 24px;
  background-color: ${Colors.White};
  border-radius: 12px;
`

export const Separator = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${Media.Phone`
    flex-direction: column;
  `}
`

export const Button = styled(BuilderButton)`
  ${Media.Phone`
    margin-top: 12px;
    flex: 1;
  `}
`

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;

  ${Button}:first-child {
    margin-right: 8px;
  }

  ${Media.Phone`
    width: 100%;
  `}
`

export const InterviewIcon = styled(FeatureIcon)`
  min-width: 48px;
  width: 48px;
  height: 48px;
  margin-right: 16px;
`

export const Title = styled.div`
  ${Typography.Subhead}
  font-weight: 600;
`

export const Subtitle = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
`
