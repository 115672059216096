import styled from 'styled-components'
import Icon24, { Icon20 } from 'builder/components/Icon'
import Colors from 'builder/styles/colors'
import { rotation } from 'builder/styles/keyframes'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'

export const LoaderContainer = styled.div<{ status: boolean; isGoalCardSection: boolean }>`
  height: 28px;
  width: ${props => (props.status ? 97 : 144)}px;
  margin-left: ${props => (props.isGoalCardSection ? -10 : 14)}px;
  top: 12px;
  position: absolute;
  background: ${Colors.White};
  box-shadow: ${props =>
    props.isGoalCardSection
      ? `0px 0px 1px rgba(15, 56, 113, 0.12)`
      : `0px 0px 1px rgba(15, 56, 113, 0.32), 0px 1px 2px rgba(15, 56, 113, 0.12),
    0px 2px 4px rgba(15, 56, 113, 0.08)`};
  border-radius: 30px;

  ${props => Media.Phone`
    border-radius: 15px;
    left: ${props.isGoalCardSection ? 23 : -9.5}px;
    width: 95px;
    height: 28px;
  `}
`
export const Spinner = styled(Icon24.Spinner)`
  color: ${Colors.Indigo80};
  animation: ${rotation} 0.5s infinite linear;
  position: absolute;
  left: 4px;
  top: 2px;

  ${Media.Phone`
    top: 2px;
  `}
`

export const RightArrow = styled(Icon20.ChevronDown)`
  color: ${Colors.Neutral40};
  transform: rotate(-90deg);
  position: absolute;
  right: 3px;
  top: 4.5px;
`

export const PrefillText = styled.div<{ status: boolean }>`
  position: absolute;
  left: ${props => (props.status ? 31.07 : 35)}%;
  right: ${props => (props.status ? 11.65 : 4)}%;
  top: 25%;
  bottom: 25%;
  ${Typography.Tiny};
  ${FontWeights.Regular};
  display: flex;
  align-items: center;
  color: ${Colors.Neutral90};

  ${Media.Phone`
    left:28px;
  `}
`

export const PopupPhoto = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-top: 4px;
  margin-left: 23px;
`

export const PopupIconWrapper = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  border-radius: 50%;
  margin-top: 4px;
  margin-left: 4px;
  color: ${Colors.White};
  box-shadow: 4px 0 0 0 ${Colors.White};
  background-color: ${Colors.Indigo80};
  border-radius: 50%;
`

export const PopupIcon = styled(Icon20.Star)`
  margin-top: -5px;
  width: 20px;
  height: 20px;

  ${Media.Phone`
    margin-top: -2px;
  `}
`
