export enum ReasonType {
  use = 'reason_to_use',
  cancel = 'reason_to_cancel',
}

export enum ReasonToUse {
  help = 'help_writing_resume',
  designs = 'resume_template_designs',
  management = 'resume_management',
  speed = 'speed',
  other = 'other',
}

export enum ReasonToCancel {
  notUsing = 'not_using_service',
  difficulties = 'technical_difficulties',
  bug = 'technical_issue',
  expensive = 'too_expensive',
  other = 'other',
}

export type Reason = ReasonToUse | ReasonToCancel

export type SelectedState = Record<ReasonType, Reason | null>
export type CustomAnswersState = Record<ReasonType, string>
export type SubmitPayload = Record<ReasonType, string>
