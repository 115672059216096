import { jobPostingPlaceholderLink } from '../../../constants'
import Dots from '../../../Images/dots_icon.svg'
import JobsFieldImage from '../../../Images/JobsField.svg'
import {
  DataContainer,
  DotsImage,
  InputWrapper,
  JobPostingText,
  JobsField,
  SuccessIcon,
} from './styles'

interface Props {
  jobPostingLink: string
  isValidLink: boolean
}

const InitialView = ({ jobPostingLink, isValidLink }: Props) => {
  return (
    <DataContainer>
      <DotsImage src={Dots} alt="Dots Icon" />
      <InputWrapper>
        <JobPostingText
          name="jobPostingLink"
          placeholder={isValidLink ? jobPostingLink : jobPostingPlaceholderLink}
          disabled={true}
          isSuccess={isValidLink}
        />
        {isValidLink && <SuccessIcon />}
      </InputWrapper>
      <JobsField src={JobsFieldImage} alt="JobsField companies" />
    </DataContainer>
  )
}

export default InitialView
