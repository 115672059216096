import * as Styled from './styles'
import dragDropURL from './assets/img/drag-drop.svg'

interface Props {
  onDragLeave: () => void
  onDrop: (e: React.ChangeEvent<HTMLInputElement>) => void
  drag: boolean
}

const DragAndDrop = (props: Props) => {
  const { onDragLeave, onDrop, drag } = props

  return (
    <Styled.Container onDragLeave={onDragLeave} drag={drag}>
      <Styled.ContainerContent>
        <Styled.Image src={dragDropURL} />
        <Styled.Text>Drop your resume here</Styled.Text>
        <Styled.SubText>Maximum size: 5MB</Styled.SubText>
        <Styled.DropzoneInput type="file" onChange={onDrop} />
      </Styled.ContainerContent>
    </Styled.Container>
  )
}

export default DragAndDrop
