import { RENDERERS, FORMATS } from 'builder/modules/constants'
import { apiClient } from 'builder/modules/apiClient'
import ReactPdfDownload from './ReactPdfDownload'
import SsrDownload from './SsrDownload'
import DocxDownload from './DocxDownload'
import TxtDownload from './TxtDownload'

class DownloadManager {
  renderer = RENDERERS.client

  getDownloadClass(document) {
    // Export
    if (document.format === FORMATS.docx) return DocxDownload
    if (document.format === FORMATS.txt) return TxtDownload

    // PDF download
    if (this.renderer === RENDERERS.server) return SsrDownload
    return ReactPdfDownload
  }

  async getDocumentData({ id, type, template }) {
    const namespace = type === 'resume' ? 'resumes' : 'cover-letters'
    const { data } = await apiClient.get(`/${namespace}/${id}`)

    return template ? { ...data, template } : data
  }

  async download(document) {
    const DownloadClass = this.getDownloadClass(document)
    const documentData = await this.getDocumentData(document)

    const download = new DownloadClass(documentData, this.renderer)
    return download.start()
  }
}

export default new DownloadManager()
