import { useEffect, useMemo, useRef } from 'react'
import { CallBackProps, LIFECYCLE, Step, TooltipRenderProps } from 'react-joyride'
import { trackInternalEvent } from '@rio/tracking'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useI18n } from 'builder/hooks/useI18n'
import { JoyRide } from 'builder/components/JoyRide'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import {
  selectors as selectorJobTracking,
  actions as jobTrackingActions,
} from 'builder/modules/jobTracking'
import { FetchStatuses } from 'builder/modules/constants'
import {
  selectors as jobSearchSelectors,
  actions as jobSearchActions,
} from 'builder/modules/jobSearch'
import { store } from 'builder/modules/store'
import { actions as userActions } from 'builder/modules/user'
import { useAutoApply } from 'builder/views/AutoApply/hooks/useAutoApply'
import { Job } from '../JobSearchResult/types'
import { TabType } from '../types'
import { CustomTooltip } from './CustomTooltip/CustomTooltip'
import { useMediaQueryAlternative } from './useMediaQueryAlternative'
import { StartScreen } from './StartScreen/StartScreen'
import {
  JoyRidesJobSearchTrackingUtils,
  VERSION_AUTO_APPLY_BUBBLES,
} from './JoyRidesJobSearchTrackingUtils'

const TRANSLATION = 'builder.job_search.onboarding'
export const BACKGROUND_COLOR_OVERLAY = 'rgba(0, 0, 0, 0.7)'
interface Props {
  recommendations?: Job[]
  recommendationIsLoading?: boolean
  searchResults?: Job[]
  searchResultsIsLoading?: boolean
  handleChangeSelectedTabId?: (tab: TabType) => void
  setRecommendActiveJob?: React.Dispatch<React.SetStateAction<Job | null>>
  setSearchActiveJob?: React.Dispatch<React.SetStateAction<Job | null>>
}

function fixSpotlightPosition(target: string, enableBorderForMobile = false) {
  const spotLightElement = document.querySelector<HTMLElement>('.react-joyride__spotlight')
  if (spotLightElement) {
    const elem = document.querySelector<HTMLElement>(target)
    if (!elem) {
      return
    }
    let padding = 0
    let halfPadding = 0
    if (enableBorderForMobile) {
      padding = 8
      halfPadding = padding / 2
    }

    const elemPosition = elem.getBoundingClientRect()
    spotLightElement.style.position = 'absolute'
    spotLightElement.style.width = `${elemPosition.width + padding}px`
    spotLightElement.style.height = `${elemPosition.height + padding}px`
    spotLightElement.style.left = `${elemPosition.left - halfPadding}px`
    spotLightElement.style.top = `${elemPosition.top - halfPadding}px`
  }
}

export const JoyRidesJobSearchTrackingSteps = ({
  recommendations,
  recommendationIsLoading,
  handleChangeSelectedTabId,
  setRecommendActiveJob,
  setSearchActiveJob,
  searchResults,
  searchResultsIsLoading,
}: Props) => {
  const { isUserOnAutoApplyPlan } = useAutoApply()
  const cards = useTypedSelector(selectorJobTracking.selectJobs)
  const cardsStatus = useTypedSelector(selectorJobTracking.selectFetchStatus)
  const navigate = useNavigate()

  const { i18n } = useI18n()
  const features = useFeaturesConfig()

  const dispatch = useDispatch()

  const isPhone = useMediaQueryAlternative('(max-width: 767px)')
  const isDesktop = useMediaQueryAlternative('(min-width: 1023px)')

  const autoApplyBubbles = useTypedSelector(jobSearchSelectors.autoApplyBubbles)
  const running = useTypedSelector(jobSearchSelectors.runAutoApplyBubbles)

  const cancelBubbles = () => {
    dispatch(jobSearchActions.disableAutoApplyBubble())
    dispatch(jobTrackingActions.fetchJobsRequest())
  }

  // First one
  const autoApplyButtonJSStep: Step = useMemo(
    () => ({
      title: i18n.t(`${TRANSLATION}.step_0.title`),
      content: i18n.t(`${TRANSLATION}.step_0.content`),
      disableBeacon: true,
      placement: isDesktop ? 'top' : 'bottom',
      target: '#auto_apply_button',
      spotlightPadding: isPhone ? 10 : 4,
      styles: {
        spotlight: {
          borderRadius: isPhone ? '4px' : '8px',
          padding: '10px',
        },
      },
      data: {
        trackingEventNameOnClick: 'click_aa_onboarding_next',
        fixSpotlightPositionParams: {
          enableBorderForMobile: !isDesktop,
        },
        onClick: () => {
          document.querySelector<HTMLDivElement>(`#auto_apply_button`)?.click()
          dispatch(jobSearchActions.goNextAutoApplyStep())
        },
      },
    }),
    [isDesktop, isPhone],
  )

  // Job  Tracker step
  const jobTrackerMenu: Step = useMemo(
    () => ({
      title: 'See your Auto Apply job queue',
      content: 'Add new jobs, remove old ones, and track your past applications here.',
      disableBeacon: true,
      placement: isPhone ? 'top' : 'right',
      target: isPhone ? '#job-tracking-menu-mobile' : '#job-tracking-menu',
      spotlightPadding: 0,
      styles: {
        spotlight: { borderRadius: '12px' },
      },
      data: {
        trackingEventNameOnClick: 'click_aa_onboarding_next',
        onClick: () => {
          dispatch(jobSearchActions.goNextAutoApplyStep())
          navigate('/job-tracking')
        },
      },
    }),
    [isPhone],
  )

  const recommendationTabStepTarget = isPhone ? '#job-search-menu-mobile' : '#recommendations'
  // last step
  const recommendationTabStep: Step = useMemo(
    () => ({
      title: i18n.t(`${TRANSLATION}.step_3.title`),
      content: i18n.t(`${TRANSLATION}.step_3.content`),
      placement: 'right',
      target: recommendationTabStepTarget,
      spotlightPadding: 0,
      styles: {
        spotlight: { borderRadius: '8px' },
      },
      data: {
        trackingEventNameOnClick: 'click_job_search_tab',
        onClick: () => {
          cancelBubbles()
          navigate('/job-search?view=recommendation')
        },
      },
    }),
    [recommendationTabStepTarget],
  )

  const autoApplyColumn = document.querySelector<HTMLDivElement>(`#auto_apply`)
  const cardItems = autoApplyColumn?.querySelectorAll(`[data-testid="job-card"]`)
  const selectAutoApplyColumnStepTarget =
    isPhone && cardItems && cardItems?.length >= 2
      ? `#auto_apply [data-testid="job-card"]`
      : '#auto_apply'
  // autoapply columns
  const selectAutoApplyColumnStep: Step = useMemo(
    () => ({
      title: i18n.t(`${TRANSLATION}.step_2.title`),
      content: i18n.t(`${TRANSLATION}.step_2.content`),
      disableBeacon: true,
      placement: isPhone ? 'bottom' : 'left',
      target: selectAutoApplyColumnStepTarget,
      spotlightPadding: 8,
      data: {
        nextButtonLabel: i18n.t(`${TRANSLATION}.tooltip.continue`),
        trackingEventNameOnClick: 'click_aa_onboarding_next',
        onReady: () => {
          const autoApplyColumn = document.querySelector<HTMLDivElement>(`#auto_apply`)
          const cardItems = autoApplyColumn?.querySelectorAll(`[data-testid="job-card"]`)
          const spotLightElement = document.querySelector<HTMLElement>('.react-joyride__spotlight')
          const cardPositionPosition = autoApplyColumn?.getBoundingClientRect()

          if (
            isPhone &&
            cardPositionPosition &&
            cardItems &&
            cardItems?.length >= 2 &&
            spotLightElement
          ) {
            spotLightElement.style.position = 'fixed'
            spotLightElement.style.width = `${cardPositionPosition.width + 8}px`
            spotLightElement.style.height = ``
            spotLightElement.style.left = `${cardPositionPosition.left - 4}px`
            spotLightElement.style.top = `${cardPositionPosition.top - 4}px`
            const bottom = cardPositionPosition.bottom - cardPositionPosition.height
            spotLightElement.style.bottom = `${bottom > 72 ? 72 : bottom}px`
          }

          autoApplyColumn?.scrollIntoView({ inline: 'center', block: 'end' })
        },
      },
    }),
    [isPhone, autoApplyColumn, cardItems, selectAutoApplyColumnStepTarget],
  )
  // first step for mobiles and tablet
  const stepTouchScreenSelectCard: Step | undefined = useMemo(() => {
    const target = '#card' + autoApplyBubbles.selectedJob?.data?.external_slug_id ?? ''
    return {
      title: i18n.t(`${TRANSLATION}.step_touchscreen.title`),
      content: i18n.t(`${TRANSLATION}.step_touchscreen.content`),
      placement: isPhone ? 'bottom' : 'top',
      disableBeacon: true,
      target,
      spotlightPadding: 8,
      styles: {
        spotlight: { top: '100px' },
      },
      data: {
        onReady: () => {
          const elem = document.querySelector<HTMLDivElement>(target)
          elem?.scrollIntoView({ block: 'center', inline: 'center' })
        },
        onClick: () => {
          const elem = document.querySelector<HTMLDivElement>(target)
          elem?.click()
          dispatch(jobSearchActions.goNextAutoApplyStep())
        },
        trackingEventNameOnClick: 'click_aa_onboarding_next',
      },
    }
  }, [i18n, autoApplyBubbles.selectedJob, setRecommendActiveJob])

  useEffect(() => {
    const removeFunction = store.subscribe(() => {
      const state = store.getState()
      const userId = state?.user?.data?.id

      if (!userId || !state.jobSearch.autoApplyBubbles.initialized) return
      JoyRidesJobSearchTrackingUtils.saveLocalStorage(userId, {
        enable: state.jobSearch.autoApplyBubbles.userStartedAutoApplyBubbles,
        showGuideButton: state.jobSearch.autoApplyBubbles.showAutoApplyGuideButton,
        stepIndex: state.jobSearch.autoApplyBubbles.stepIndex,
        running: state.jobSearch.autoApplyBubbles.running,
        version: VERSION_AUTO_APPLY_BUBBLES,
        showFirstPage: state.jobSearch.autoApplyBubbles.showFirstPage,
      })
    })
    dispatch(userActions.fetchUserRequest({ silent: true }))
    dispatch(jobSearchActions.fetchJobSearchBubbleFeatureFlag())
    return removeFunction
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const actionFunctionRef = useRef((event: Event) => {})

  const currentUrl = new URL(window.location.href)
  const path = currentUrl.pathname.replace('/app/', '')

  useEffect(() => {
    const addEventHandlerId = setInterval(() => {
      const spotLightElement = document.querySelector<HTMLElement>('.react-joyride__spotlight')
      if (!spotLightElement) {
        return
      }
      // hide scroll overflow
      document.body.style.overflow = 'hidden'
      // force parent to have a white color to joy ride works
      document.body.style.background = 'white'
      spotLightElement.addEventListener('click', e => actionFunctionRef.current(e))
      clearInterval(addEventHandlerId)
    }, 100)

    // Prevent scroll movement on iOS devices
    const stopScrollMovement = (e: Event) => e.preventDefault()
    if (running) {
      document.addEventListener('touchmove', stopScrollMovement, { passive: false })
    }

    return () => {
      // restore overflow
      document.body.style.overflow = 'auto'
      document.body.style.background = ''
      clearInterval(addEventHandlerId)
      if (running) {
        document.removeEventListener('touchmove', stopScrollMovement)
      }
    }
  }, [running])

  // Logic to select the first card
  useEffect(() => {
    if (window.location.pathname === '/app/job-tracking') {
      return
    }
    if (!autoApplyBubbles.initialized || autoApplyBubbles.selectedJob?.done) {
      return
    }

    if (!features.features.autoApplyBubbles || !isUserOnAutoApplyPlan) {
      dispatch(
        jobSearchActions.setSelectAutoApplyBubbleJob({
          data: null,
          tab: null,
          done: true,
        }),
      )
      return
    }

    if (recommendationIsLoading || cardsStatus === FetchStatuses.loading) {
      return
    }

    const autoAppliedCardsExternalIds = new Set(
      cards.filter(c => c.status === 'auto_apply').map(c => c.externalSlugId),
    )

    const wishListCardsExternalIds = new Set(
      cards.filter(c => c.status === 'wishlist').map(c => c.externalSlugId),
    )

    const nonAutoAppliedRecommendCard = (recommendations || [])
      .filter(el => !el.is_easy_apply)
      .find(
        r =>
          !autoAppliedCardsExternalIds.has(r.external_slug_id) &&
          !wishListCardsExternalIds.has(r.external_slug_id),
      )

    if (nonAutoAppliedRecommendCard) {
      dispatch(
        jobSearchActions.setSelectAutoApplyBubbleJob({
          data: nonAutoAppliedRecommendCard,
          tab: TabType.recommendation,
          done: true,
        }),
      )
      return
    }

    if (searchResultsIsLoading) {
      return
    }

    const nonAutoAppliedSearchResultCard = (searchResults || [])
      .filter(el => !el.is_easy_apply)
      .find(
        r =>
          !autoAppliedCardsExternalIds.has(r.external_slug_id) &&
          !wishListCardsExternalIds.has(r.external_slug_id),
      )

    if (nonAutoAppliedSearchResultCard) {
      dispatch(
        jobSearchActions.setSelectAutoApplyBubbleJob({
          data: nonAutoAppliedSearchResultCard,
          tab: TabType.search,
          done: true,
        }),
      )
      return
    }

    dispatch(
      jobSearchActions.setSelectAutoApplyBubbleJob({
        data: null,
        tab: null,
        done: true,
      }),
    )
  }, [
    recommendations,
    recommendationIsLoading,
    cards,
    searchResults,
    searchResultsIsLoading,
    setRecommendActiveJob,
    autoApplyBubbles.selectedJob,
    isUserOnAutoApplyPlan,
    running,
    isDesktop,
    cardsStatus,
    features.features.autoApplyBubbles,
    autoApplyBubbles,
    dispatch,
    handleChangeSelectedTabId,
    setSearchActiveJob,
  ])

  useEffect(() => {
    if (
      window.location.pathname === '/app/job-search' &&
      autoApplyBubbles.initialized &&
      autoApplyBubbles.userStartedAutoApplyBubbles &&
      !autoApplyBubbles.running &&
      autoApplyBubbles.selectedJob &&
      handleChangeSelectedTabId &&
      autoApplyBubbles.selectedJob.tab &&
      setRecommendActiveJob &&
      setSearchActiveJob
    ) {
      handleChangeSelectedTabId(autoApplyBubbles.selectedJob.tab)

      if (isDesktop) {
        if (autoApplyBubbles.selectedJob.tab === TabType.recommendation) {
          setRecommendActiveJob(autoApplyBubbles.selectedJob.data)
        } else {
          setSearchActiveJob(autoApplyBubbles.selectedJob.data)
        }
      }
      dispatch(jobTrackingActions.removeAutoApplyBubbleDummyCard())
      dispatch(jobSearchActions.setRunAutoApplyBubble({ running: true }))
      trackInternalEvent('start_aa_onboarding', {
        params: autoApplyBubbles.showFirstPage ? 'questionary' : 'auto_apply_guide_button',
      })
    }
  }, [
    isDesktop,
    handleChangeSelectedTabId,
    autoApplyBubbles.initialized,
    autoApplyBubbles.userStartedAutoApplyBubbles,
    autoApplyBubbles.running,
    autoApplyBubbles.selectedJob,
    autoApplyBubbles.showFirstPage,
    dispatch,
    autoApplyBubbles.selectedJob?.tab,
    setRecommendActiveJob,
    setSearchActiveJob,
  ])

  const steps = useMemo(() => {
    if (isDesktop) {
      return [
        autoApplyButtonJSStep,
        jobTrackerMenu,
        selectAutoApplyColumnStep,
        recommendationTabStep,
      ]
    }
    // Mobile and Tablet
    if (stepTouchScreenSelectCard) {
      return [
        stepTouchScreenSelectCard,
        autoApplyButtonJSStep,
        jobTrackerMenu,
        selectAutoApplyColumnStep,
        recommendationTabStep,
      ]
    }

    return []
  }, [
    autoApplyButtonJSStep,
    isDesktop,
    jobTrackerMenu,
    recommendationTabStep,
    selectAutoApplyColumnStep,
    stepTouchScreenSelectCard,
  ])

  if (
    running &&
    autoApplyBubbles.showFirstPage &&
    autoApplyBubbles.stepIndex === 0 &&
    handleChangeSelectedTabId
  ) {
    return (
      <StartScreen
        onClick={() => {
          dispatch(jobSearchActions.closeFirstPageApplyBubble())
          trackInternalEvent('start_aa_demo')
        }}
        onSkip={() => {
          cancelBubbles()
          trackInternalEvent('click_skip_aa_onboarding', {
            label: path,
            totalSteps: steps.length,
            currentStep: 0,
          })
        }}
      />
    )
  }
  return (
    <JoyRide
      run={running}
      steps={steps}
      showDefaultButtons={false}
      stepIndex={autoApplyBubbles.stepIndex}
      styles={{
        overlay: {
          position: 'fixed',
        },
        options: {
          zIndex: 10000,
          overlayColor: BACKGROUND_COLOR_OVERLAY,
        },
        spotlight: {
          borderRadius: '12px',
          padding: '10px var(--Spacing-2XS, 8px) 10px var(--Spacing-XS, 12px)',
        },
      }}
      callback={(action: CallBackProps) => {
        if (action.lifecycle === LIFECYCLE.READY) {
          if (action.step?.data?.onReady) {
            setTimeout(action.step.data.onReady)
          }
          if (!isDesktop) {
            setTimeout(() => {
              fixSpotlightPosition(
                action.step.target as string,
                action.step.data.fixSpotlightPositionParams?.enableBorderForMobile,
              )
            })
          }
        }
      }}
      customToolTip={(props: TooltipRenderProps) => {
        const spotLightElement = document.querySelector<HTMLElement>('.react-joyride__spotlight')
        if (spotLightElement) {
          spotLightElement.style.cursor = 'pointer'
        }
        actionFunctionRef.current = () => {
          const targetElement = props.step.target
          if (typeof targetElement === 'string') {
            if (props.step.data?.onClick) {
              props.step.data?.onClick()
            } else {
              dispatch(jobSearchActions.goNextAutoApplyStep())
            }

            if (props.step.data?.trackingEventNameOnClick) {
              trackInternalEvent(props.step.data?.trackingEventNameOnClick, {
                label: path,
                title: String(props.step.title),
              })
            }

            if (props.index === steps.length - 1) {
              trackInternalEvent('complete_aa_onboarding')
            }
          }
        }
        const handleSkipButton = () => {
          cancelBubbles()

          trackInternalEvent('click_skip_aa_onboarding', {
            label: path,
            totalSteps: steps.length,
            currentStep: props.index + 1,
          })
        }

        return (
          <CustomTooltip
            {...props}
            onClick={e => actionFunctionRef.current(e as unknown as Event)}
            onSkip={handleSkipButton}
          />
        )
      }}
    />
  )
}
