import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { useI18n } from 'builder/hooks/useI18n'
import { ButtonTheme } from 'builder/components/Button'
import Icon24 from 'builder/components/Icon'
import { TooltipV2 } from 'builder/components/Tooltip'
import { VideoWrapper } from '../../Start/styles'
import { usePermission } from '../hooks/usePermissions'
import { Modal } from '../../Modal/Modal'
import { UserMediaType, useGetStream } from '../hooks/useGetStream'
import {
  Video,
  Text,
  LargeButton,
  Hint,
  IconContainer,
  Line,
  AllGoodButton,
  LineContainer,
  TestSoundText,
  VideoContainer,
  VideoIcon,
  VideoOffIcon,
  VideoBlank,
  ToggleWrapper,
  Title,
  HelpImage,
} from './styles'
import Image from './assets/Image.svg'
import TestSound from './assets/TestSound.webm'

const TRANSLATION_PREFIX = 'builder.interview_prep_view.video_mic_modal'
export type PAGE = 'initial' | 'help'
interface Props {
  onClose: () => void
  initialPage: PAGE
}

export const VideoMicModal = ({ onClose, initialPage = 'initial' }: Props) => {
  const navigate = useNavigate()
  const videoRef = useRef<HTMLVideoElement>(null)
  const testSoundRef = useRef<HTMLAudioElement>(null)
  const { i18n } = useI18n()
  const [page, setPage] = useState(initialPage)

  const audioStream = useGetStream(UserMediaType.audio)
  const audioVideoStream = useGetStream(UserMediaType.video)

  const microphonePermission = usePermission('microphone')
  const isMicOn = microphonePermission === 'granted' && audioStream.stream?.active

  function regainPermission(p: PermissionState) {
    if (p === 'granted' && initialPage === 'initial') {
      audioVideoStream.askForPermission()
      audioStream.askForPermission()
    }
  }

  usePermission('camera', regainPermission)
  usePermission('microphone', regainPermission)

  useEffect(() => {
    if (videoRef.current && audioVideoStream.stream) {
      videoRef.current.srcObject = audioVideoStream.stream
    }
  }, [audioVideoStream.stream])

  const onHelpClick = () => {
    if (initialPage === 'help') {
      return onClose()
    }
    setPage(page === 'help' ? 'initial' : 'help')
  }

  const onTestSound = () => {
    testSoundRef.current?.play()
  }

  const onBeforeClose = () => {
    if (initialPage === 'help') {
      onClose()
      return
    }
    if (initialPage === 'initial' && page === 'help') {
      setPage('initial')
      return
    }

    if (isMicOn) {
      onClose()
      return
    }

    return navigate('/interview-preparation/dashboard')
  }

  return (
    <Modal
      onClose={onBeforeClose}
      width={352}
      title={
        <Title>
          {page === 'help'
            ? i18n.t(`${TRANSLATION_PREFIX}.help_title`)
            : i18n.t(`${TRANSLATION_PREFIX}.title`)}
        </Title>
      }
    >
      <ToggleWrapper $show={page === 'help'}>
        <HelpImage src={Image} />
        {i18n.t(`${TRANSLATION_PREFIX}.help_content`)}
        <AllGoodButton onClick={onHelpClick}>
          {i18n.t(`${TRANSLATION_PREFIX}.dismiss`)}
        </AllGoodButton>
      </ToggleWrapper>
      <ToggleWrapper $show={page === 'initial'}>
        <TooltipV2 content={i18n.t(`${TRANSLATION_PREFIX}.hint`)} multiline={true}>
          <VideoWrapper>
            <VideoContainer>
              {audioVideoStream.stream ? (
                <>
                  <VideoIcon />
                  <Video ref={videoRef} autoPlay muted playsInline />
                </>
              ) : (
                <>
                  <VideoOffIcon />
                  <VideoBlank />
                </>
              )}
            </VideoContainer>
          </VideoWrapper>
        </TooltipV2>
        <LineContainer>
          <Line>
            <IconContainer>
              <Icon24.Sound />
            </IconContainer>
            <div>
              <Text>{i18n.t(`${TRANSLATION_PREFIX}.sound`)}</Text>
              <TestSoundText onClick={onTestSound}>
                {i18n.t(`${TRANSLATION_PREFIX}.sound_test`)}
              </TestSoundText>
            </div>
          </Line>
          <Line>
            {isMicOn ? (
              <>
                <IconContainer>
                  <Icon24.Mic />
                </IconContainer>
                <Text>{i18n.t(`${TRANSLATION_PREFIX}.mic`)}</Text>
              </>
            ) : (
              <>
                <IconContainer>
                  <Icon24.MicClosed color="#FB4458" />
                </IconContainer>
                <Text>{i18n.t(`${TRANSLATION_PREFIX}.micFail`)}</Text>
              </>
            )}
          </Line>
        </LineContainer>
        <LargeButton theme={ButtonTheme.ghost} onClick={onHelpClick}>
          {i18n.t(`${TRANSLATION_PREFIX}.need_help`)}
        </LargeButton>
        <AllGoodButton isDisabled={!isMicOn} onClick={onClose}>
          {i18n.t(`${TRANSLATION_PREFIX}.all_good`)}
        </AllGoodButton>
        <Hint>{i18n.t(`${TRANSLATION_PREFIX}.hint`)}</Hint>
        <audio src={TestSound} ref={testSoundRef} />
      </ToggleWrapper>
    </Modal>
  )
}
