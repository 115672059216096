import styled from 'styled-components'

export const Container = styled.div`
  display: inline-flex;
  width: 40px;
  height: 40px;

  & svg {
    width: 100%;
    height: 100%;
  }
`
