import { FC } from 'react'
import type { Optional, ResumeExample } from '@rio/types'
import { useI18n } from 'builder/hooks/useI18n'
import {
  ExampleItem,
  ExamplePreview,
  ExampleRating,
  ExamplesGroupContainer,
  ExamplesGroupCount,
  ExamplesGroupHeader,
  ExamplesGroupTitle,
  ExampleTitle,
  Star,
} from './styles'

interface ExamplesGroupProps {
  categoryId: number
  categoryName: Optional<string>
  examples: ResumeExample[]
  onSelect: (id: number) => void
}

const ExamplesGroup: FC<ExamplesGroupProps> = ({
  categoryId,
  categoryName,
  examples,
  onSelect,
}) => {
  const { i18n } = useI18n()

  if (examples.length === 0) return null

  return (
    <ExamplesGroupContainer id={`category-${categoryId}`}>
      <ExamplesGroupHeader>
        <ExamplesGroupTitle>{categoryName}</ExamplesGroupTitle>
        <ExamplesGroupCount>
          {i18n.t('builder.prefill.resumes_count', {
            count: examples.length,
          })}
        </ExamplesGroupCount>
      </ExamplesGroupHeader>

      {examples.map(({ id, jobTitle, previewImageUrl, ratingScore }) => (
        <ExampleItem onClick={() => onSelect(id)} key={`example-${id}`}>
          <ExamplePreview>
            <img loading="lazy" src={previewImageUrl} />
          </ExamplePreview>
          <ExampleTitle>{jobTitle}</ExampleTitle>
          <ExampleRating>
            <Star />
            <Star />
            <Star />
            <Star />
            <Star disabled={ratingScore < 4.5} />
          </ExampleRating>
        </ExampleItem>
      ))}
    </ExamplesGroupContainer>
  )
}

export default ExamplesGroup
