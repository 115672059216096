import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography from 'builder/styles/typography'
import DocumentImagePreview from 'builder/components/DocumentImagePreview'
import Button from 'builder/components/Button'
import Icon24 from 'builder/components/Icon'
import { Container as BaseContainer } from 'builder/components/Container'

export const TunerContainer = styled.div<{ highlightColor: string }>`
  position: relative;
  overflow: hidden;
  padding-top: 64px;
  padding-bottom: 64px;
  background: ${props => `linear-gradient(98deg, #e6f3ff 0%, ${props.highlightColor} 100%)`};
  color: ${Colors.Indigo80};

  ${Media.Tablet`
    padding-top: 40px;
    padding-bottom: 40px;
  `};

  ${Media.Phone`
    padding-top: 24px;
    padding-bottom: 24px;
  `};
`

export const TunerContent = styled(BaseContainer)`
  display: flex;
  justify-content: space-between;

  ${Media.Tablet`
    flex-direction: column;
    max-width: 504px;
  `};
`

export const TunerMain = styled.div`
  flex-grow: 1;
  margin-right: 128px;

  ${Media.Laptop`
    margin-right: 40px;
  `};

  ${Media.Tablet`
    margin-right: 0;
    margin-bottom: 24px;
    width: 100%;
  `};

  ${Media.Phone`
    margin-bottom: 16px;
  `};
`

export const TunerTitle = styled.div`
  margin-bottom: 8px;
  ${Typography.L};
  font-weight: 600;

  ${Media.Tablet`
    text-align: center;
  `};
`

export const TunerText = styled.div`
  margin-bottom: 24px;

  ${Media.Tablet`
    text-align: center;
  `};

  ${Media.Phone`
    margin-bottom: 16px;
  `};
`

export const PreviewContainer = styled.div<{ highlightColor: string }>`
  position: relative;
  width: 384px;
  margin-right: 52px;
  margin-bottom: -64px;
  flex-shrink: 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 6px;
    background-color: ${Colors.White};
    opacity: 0.4;
    transform: translate(2%, 5%) rotate(2deg);
  }

  &:after {
    content: '';
    position: absolute;
    height: 84px;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${props =>
      `linear-gradient(180deg,
        ${hexToRgba(props.highlightColor, 0)} 0%,
        ${hexToRgba(props.highlightColor, 0.4)} 100%
      )`};
  }

  ${Media.Laptop`
    margin-right: 0;

    &:before {
      display: none;
    }
  `};

  ${Media.Tablet`
    order: 3;
    width: 100%;
    height: 212px;
    margin-bottom: -40px;
  `};

  ${Media.Phone`
    height: 144px;
    margin-bottom: -24px;

    &:after {
      height: 60px;
    }
  `};
`

export const PreviewImage = styled(DocumentImagePreview)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  border-radius: 6px;
`

export const PreviewFixButton = styled(Button)`
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;

  ${Media.Phone`
    bottom: 32px;
  `};
`

export const ScoreContainer = styled.div`
  display: flex;

  ${Media.Tablet`
    flex-direction: column;
    margin-bottom: 34px;
  `};
`

export const ScoreContent = styled.div`
  width: 80px;
  flex-shrink: 0;
  padding-left: 16px;
  margin-right: 24px;
  text-align: right;

  ${Media.Tablet`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
    padding-left: 0;
  `};
`

export const ScoreValue = styled.div`
  ${Typography.XL};
  font-weight: 600;
`

export const ScoreLabel = styled.div`
  ${Typography.Caption};
`

export const ScoreRail = styled.div`
  position: relative;
  width: 4px;
  flex-shrink: 0;

  ${Media.Tablet`
    width: 100%;
    height: 4px;
  `};

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: currentColor;
    opacity: 0.2;
  }
`

export const ScoreBar = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: currentColor;

  ${Media.Tablet`
    right: auto;
    top: 0;
  `};
`

export const ScoreEmoji = styled.div<{ emoji: string }>`
  position: absolute;
  left: calc(50% - 20px);
  top: -20px;
  width: 40px;
  height: 40px;
  background-image: url(${props => require(`images/builder/panel/tuner/${props.emoji}.svg`)});

  ${Media.Tablet`
    left: calc(100% - 20px);
    top: calc(50% - 20px);
  `};
`

export const TaskContainer = styled(Link)`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding: 12px 16px;
  border-radius: 6px;
  background-color: ${Colors.White};

  ${Media.Phone`
    margin-bottom: 8px;
    padding-left: 12px;
    padding-right: 12px;
  `};

  &:last-child {
    margin-bottom: 0;
  }
`

export const TaskContent = styled.div`
  flex-grow: 1;
`

export const TaskHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

export const TaskTitle = styled.div<{ done: boolean }>`
  margin-right: 8px;
  font-weight: 600;

  ${props =>
    props.done &&
    css`
      text-decoration: line-through;
      color: ${Colors.Neutral50};
    `}
`

export const TaskScore = styled.div<{ done: boolean }>`
  padding: 2px 4px;
  border-radius: 4px;
  background-color: ${hexToRgba(Colors.Orange40, 0.12)};
  ${Typography.Tiny};
  font-weight: 600;
  color: ${Colors.Orange40};

  ${props =>
    props.done &&
    css`
      background-color: ${hexToRgba(Colors.Neutral50, 0.12)};
      color: ${Colors.Neutral50};
    `}
`

export const TaskText = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};

  ${Media.Phone`
    ${Typography.Tiny};
  `};
`

export const TaskSide = styled.div`
  flex-shrink: 0;
  margin-left: 20px;

  ${Media.Phone`
    margin-left: 16px;
  `};
`

export const TaskArrowIcon = styled(Icon24.Chevron)`
  color: ${Colors.Neutral40};
  transition: color 0.15s;

  ${TaskContainer}:hover & {
    color: ${Colors.Blue50};
  }
`

export const TaskSuccessIcon = styled(Icon24.Success)`
  color: ${Colors.Green50};
`
