import { memo, useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { ButtonSize } from 'builder/components/Button'
import { useClickOutside } from 'builder/hooks/useClickOutside'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { actions } from 'builder/modules/resumeEditor'
import { i18n as I18n } from 'builder/utils/i18n'
import {
  AIResumeDraftEventLabels,
  AIResumeDraftEvents,
  AIResumeWarningModalActions,
  AIResumeWarningModalTypes,
} from 'builder/modules/constants'
import { ActionButton, ButtonContainer, Container, Content, Title, TopWrapper } from './styles'

const WarningModal = () => {
  const isPhone = useMediaQueries()
  const containerRef = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()

  const {
    warningModalType,
    questionnaireData: { step },
  } = useTypedSelector(state => state.resumeEditor.aiResume)
  const buttonSize = !isPhone ? ButtonSize.default : ButtonSize.small
  const isFutureGoalsStep = step === 6

  const baseTranslationKey = 'builder.ai_resume_draft.'

  const {
    title,
    content,
    primaryButtonText,
    secondaryButtonText,
    primaryButtonAction,
    secondaryButtonAction,
    eventName,
  } = useMemo(() => {
    let title = ''
    let content = ''
    let primaryButtonText = ''
    let secondaryButtonText = ''
    let primaryButtonAction: AIResumeWarningModalActions | null = null
    let secondaryButtonAction: AIResumeWarningModalActions | null = null
    let eventName: AIResumeDraftEvents | null = null

    switch (warningModalType) {
      case AIResumeWarningModalTypes.closeFlow:
        title = baseTranslationKey + 'data_loss_screen.title'
        content = baseTranslationKey + 'data_loss_screen.description'
        primaryButtonText = baseTranslationKey + 'stay'
        secondaryButtonText = baseTranslationKey + 'confirm'
        primaryButtonAction = AIResumeWarningModalActions.stay
        secondaryButtonAction = AIResumeWarningModalActions.confirmClose
        break
      case AIResumeWarningModalTypes.dataLost:
        title = baseTranslationKey + 'data_loss_screen.title'
        content = baseTranslationKey + 'data_loss_screen.description'
        primaryButtonText = baseTranslationKey + 'stay'
        secondaryButtonText = baseTranslationKey + 'confirm'
        primaryButtonAction = AIResumeWarningModalActions.stay
        secondaryButtonAction = AIResumeWarningModalActions.confirmDataLost
        break
      case AIResumeWarningModalTypes.removeJob:
        title = baseTranslationKey + 'remove_job_screen.title'
        content = ''
        primaryButtonText = baseTranslationKey + 'remove_job_screen.remove_job'
        secondaryButtonText = baseTranslationKey + 'cancel'
        primaryButtonAction = AIResumeWarningModalActions.removeJob
        secondaryButtonAction = AIResumeWarningModalActions.stay
        break
      case AIResumeWarningModalTypes.retake:
        title = baseTranslationKey + 'text_voice_input.warning_screens.retake.title'
        content = baseTranslationKey + 'text_voice_input.warning_screens.retake.description'
        primaryButtonText = baseTranslationKey + 'stay'
        secondaryButtonText = baseTranslationKey + 'text_voice_input.retake'
        primaryButtonAction = AIResumeWarningModalActions.stay
        secondaryButtonAction = AIResumeWarningModalActions.retake
        eventName = AIResumeDraftEvents.clickRetakeRecording
        break
      case AIResumeWarningModalTypes.switchToVoice:
        title = baseTranslationKey + 'text_voice_input.warning_screens.switch_to_voice.title'
        content =
          baseTranslationKey + 'text_voice_input.warning_screens.switch_to_voice.description'
        primaryButtonText = baseTranslationKey + 'stay'
        secondaryButtonText =
          baseTranslationKey + 'text_voice_input.warning_screens.switch_to_voice.switch'
        primaryButtonAction = AIResumeWarningModalActions.stay
        secondaryButtonAction = AIResumeWarningModalActions.switchToVoice
        eventName = AIResumeDraftEvents.clickVoiceInput
        break
      case AIResumeWarningModalTypes.switchToText:
        title = baseTranslationKey + 'text_voice_input.warning_screens.switch_to_text.title'
        content = baseTranslationKey + 'text_voice_input.warning_screens.switch_to_text.description'
        primaryButtonText = baseTranslationKey + 'stay'
        secondaryButtonText =
          baseTranslationKey + 'text_voice_input.warning_screens.switch_to_text.switch'
        primaryButtonAction = AIResumeWarningModalActions.stay
        secondaryButtonAction = AIResumeWarningModalActions.switchToText
        eventName = AIResumeDraftEvents.clickTextInput
        break
    }
    return {
      title,
      content,
      eventName,
      primaryButtonText,
      secondaryButtonText,
      primaryButtonAction,
      secondaryButtonAction,
    }
  }, [warningModalType])

  const handleClickOutside = (e: MouseEvent) => {
    e.stopPropagation()
  }

  const handlePrimaryAction = () => {
    dispatch(actions.setAIResumeWarningModalAction(primaryButtonAction))
    dispatch(actions.setShowAIResumeWarningModal({ showModal: false }))
  }

  const handleSecondaryAction = () => {
    dispatch(actions.setAIResumeWarningModalAction(secondaryButtonAction))
    dispatch(actions.setShowAIResumeWarningModal({ showModal: false }))
    if (eventName) {
      trackInternalEvent(eventName, {
        label: isFutureGoalsStep
          ? AIResumeDraftEventLabels.careerGoals
          : AIResumeDraftEventLabels.professionalHighlights,
      })
    }
  }

  useClickOutside(containerRef, handleClickOutside)

  return (
    <Container ref={containerRef}>
      <TopWrapper>
        <Title>{I18n.t(title)}</Title>
      </TopWrapper>
      {!!content && <Content>{I18n.t(content)}</Content>}
      <ButtonContainer>
        <ActionButton theme="ghost" size={buttonSize} onClick={handleSecondaryAction}>
          {I18n.t(secondaryButtonText)}
        </ActionButton>
        <ActionButton size={buttonSize} onClick={handlePrimaryAction}>
          {I18n.t(primaryButtonText)}
        </ActionButton>
      </ButtonContainer>
    </Container>
  )
}

export default memo(WarningModal)
