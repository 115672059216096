import styled from 'styled-components'

import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  width: 100%;
  padding-top: 146px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${Media.Phone`
    padding-top: 120px;
  `}
`

export const Header = styled.header`
  width: 100%;
`

export const Anchor = styled.a`
  position: absolute;
  top: 20px;
  left: 32px;
  z-index: 1;

  ${Media.Tablet`
    top: 20px;
    left: 20px;
  `}
`

export const Logo = styled.img`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  width: 130px;
  height: 32px;
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const Image = styled.img`
  width: 295px;
  height: 302px;
  flex-shrink: 0;
`

export const Description = styled.div`
  width: 544px;
  margin: 0 auto;
  margin-top: 32.82px;
  text-align: center;
  gap: 8px;

  ${Media.Phone`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px
  `}
`

export const Title = styled.div`
  ${Typography.M};
  ${FontWeights.Medium};
  text-align: center;
  padding-bottom: 12px;

  ${Media.Phone`
    width: 335px;
    ${Typography.S};
  `}
`

export const SubTitle = styled.div`
  text-align: center;
  width: 544px;

  ${Media.Phone`
    width: 335px;
    ${Typography.Caption};
  `}
`

export const EmailId = styled.span`
  ${FontWeights.DemiBold};
`

export const Footer = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  gap: 4px;

  ${Media.Phone`
    margin:  148px 0 64px 0;
  `}
`

export const Caption = styled.div`
  color: ${Colors.Neutral50};
  ${Typography.Caption};
`

export const ContactLink = styled.a`
  color: ${Colors.Blue50};
  ${Typography.Caption};
`
