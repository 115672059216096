import uniqueId from 'lodash/uniqueId'
import { useConstant } from 'builder/hooks/useConstant'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { SiblingConnectionProps } from './types'
import * as Styles from './styles'

export const Sibling = ({ type, gradient: { from, to } }: SiblingConnectionProps) => {
  const { isPhone } = useMediaQueries()
  const height = isPhone ? 16 : 32

  const id = useConstant(() => uniqueId('s'))

  return (
    <Styles.SiblingContainer
      type={type}
      isPhone={isPhone}
      width="4"
      height={height}
      viewBox={`0 0 4 ${height}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d={`M2 0L2 12L2 ${height}`} stroke={`url(#${id})`} strokeWidth="4" />
      <defs>
        <linearGradient
          id={id}
          x1="2.99998"
          y1={isPhone ? 4.5 : 6}
          x2="2.99998"
          y2={isPhone ? 19.5 : 26}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={from} />
          <stop offset="1" stopColor={to} />
        </linearGradient>
      </defs>
    </Styles.SiblingContainer>
  )
}
