import styled, { keyframes } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

const Shine = keyframes`
 to {
    background-position: 315px 0, 0 0, 0 190px, 50px 195px;
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${Media.Phone`
    margin-right: 20px;
  `};
  ${Media.Tablet`
    margin-right: 20px;
  `};
  ${Media.Laptop`
    margin-right: 20px;
  `};
`

export const Interrogation = styled.div`
  margin: 4px;
  margin-left: 12px;
  height: 48px;
  width: 48px;
  min-width: 48px;
  background: ${Colors.Blue10};

  color: ${Colors.Blue50};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const QuestionLibraryButton = styled.div`
  ${Typography.Body};
  ${FontWeights.Medium};
  border-radius: 63px;
  border: 1px solid ${Colors.Neutral15};
  background: ${Colors.White};
  padding-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${Media.Hover`
    &:hover {
      cursor: pointer;
      background-color: ${Colors.Blue10};
      color: ${Colors.Blue50};

    }

   &:hover ${Interrogation} {
     background-color: ${Colors.Blue50};
     color: ${Colors.White};
   }
  `};
`

export const TitleContainer = styled.div`
  display: flex;
  gap: 12px;
  height: 32px;
`

export const Title = styled.div`
  ${Typography.S};
  ${FontWeights.Medium};
`

export const QuestionCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 20px;
  overflow: auto;
  grid-gap: 32px;
  padding-bottom: 20px;
  -ms-overflow-style: none;
  scrollbar-width: none;

  ${Media.Tablet`
    grid-gap: 24px;
  `};

  ${Media.Phone`
    grid-gap: 12px;
  `};
`

export const CardSkeleton = styled.div`
  min-width: 335px;
  height: 186px;
  border-radius: var(--S, 16px);
  border: var(--Zero, 1px) solid ${Colors.Neutral15};
  background: ${Colors.Neutral5};
  background-image: linear-gradient(
    90deg,
    ${Colors.Neutral5} 0px,
    ${Colors.Neutral10} 45px,
    ${Colors.Neutral5} 90px
  );
  background-size: 300px;
  animation: ${Shine} 0.7s infinite cubic-bezier(0.03, 0.26, 0.5, 0.8);
`

export const DesktopContainer = styled.div`
  ${Media.Phone`
    display: none;
  `};
`

export const MobileContainer = styled.div`
  display: none;

  ${Media.Phone`
    display: flex;
  `};
`
