import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Sizes from 'builder/styles/sizes'
import Typography from 'builder/styles/typography'
import Icon24 from 'builder/components/Icon'
import Media from 'builder/styles/media'
import { Size } from './types'

export const Container = styled.div<{ $size: Size }>`
  display: flex;
  flex-flow: column;
  background-color: ${Colors.Neutral5};
  gap: ${Sizes.XS};
  padding: ${Sizes.S};
  border-radius: ${Sizes.XS};
  ${Typography.Caption};
  position: relative;

  ${props =>
    props.$size === 'm' &&
    css`
      padding: ${Sizes.L} ${Sizes.M};
      border-radius: ${Sizes.S};
    `}
`

export const TextContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${Sizes.S};
  align-items: center;
`

export const Text = styled.div<{ $size: Size }>`
  ${props =>
    props.$size === 'm' &&
    css`
      ${Typography.Body};
      text-align: center;
    `}
`

export const PendingItemsContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${Sizes['2XS']};
`

export const PendingItem = styled.div`
  display: flex;
  gap: ${Sizes['2XS']};
  align-items: center;
`

export const PendingItemText = styled.div`
  color: ${Colors.Neutral50};
`

export const Divider = styled.div`
  height: 1px;
  background-color: ${Colors.Neutral15};
`

export const CloseResultIcon = styled(Icon24.Close)`
  position: absolute;
  top: ${Sizes.S};
  right: ${Sizes.S};
  cursor: pointer;
  color: ${Colors.Neutral40};
  transition: color 0.2s ease-out;

  ${Media.Hover`
    &:hover {
      color: ${Colors.Neutral50};
    }
  `}
`
