/* eslint-disable react/no-unescaped-entities */
import { Container, LoadingImage } from './styles'
import loadingScreen2URL from './assets/img/loadingscreen2.gif'
import Header from './header'
const LoadingScreen2 = () => {
  return (
    <Container>
      <Header
        title="Parsing primary info"
        subtitle="Give us a moment and don't close this window, we are preparing your resume review"
      />
      <LoadingImage src={loadingScreen2URL}></LoadingImage>
    </Container>
  )
}

export default LoadingScreen2
