import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

export const Button = styled.div`
  position: fixed;
  bottom: 28px;
  right: 32px;
  z-index: 20000;
  background: ${Colors.White};
  color: ${Colors.Neutral90};
  display: inline-flex;
  padding: 8px 24px 8px 32px;
  align-items: center;
  gap: 2px;
  border-radius: 24px;
  box-shadow: 0px 0px 1px 0px rgba(15, 56, 113, 0.32), 0px 1px 2px 0px rgba(15, 56, 113, 0.12),
    0px 2px 4px 0px rgba(15, 56, 113, 0.08);
  cursor: pointer;
  &:hover {
    color: ${Colors.Blue50};
    background: ${Colors.White};
  }

  ${Media.Tablet`
    top: 88px;
    right: 32px;
    bottom: unset;
  `}
`
