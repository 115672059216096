import { trackInternalEvent } from '@rio/tracking'
import { useEffect, useMemo } from 'react'
import ModalOverlay from 'builder/components/ModalOverlay'
import { useI18n } from 'builder/hooks/useI18n'
import { ButtonSize, ButtonTheme } from 'builder/components/Button'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { formatDocumentPreviewUrl } from 'builder/utils/formatDocumentPreviewUrl'
import DocumentImagePreview from 'builder/components/DocumentImagePreview'
import PdfPreview from 'builder/components/PdfPreview'
import loaderImage from 'images/builder/resume-editor/resume-loader.svg'
import { TailoringSections } from '../AutoTailoringModal/components/TailoringSections/TailoringSections'
import { Score } from '../AutoTailoringModal/components/Score/Score'
import { FreeUserDeclaration } from '../AutoTailoringModal/components/FreeUserDeclaration/FreeUserDeclaration'
import {
  Container,
  Content,
  ProcessWrapper,
  ProcessHeader,
  LoadingIcon,
  Title,
  Description,
  PreviewWrapper,
  PreviewHeader,
  PreviewDescription,
  DocumentPreviewContainer,
  FailedIcon,
  ArrowTargetIcon,
  ActionContainer,
  CloseButton,
  LoaderContainer,
  Loader,
  ProcessContent,
  ProcessFooter,
  Note,
  ActionButton,
  ActionButtonWithIcon,
  DocumentPreviewWrapper,
  DESKTOP_PREVIEW_WIDTH,
  PHONE_PREVIEW_WIDTH,
} from './styles'
import { useAutoTailoringModal } from './hooks/useAutoTailoringModal'

export const AutoTailoringModal = () => {
  const { i18n } = useI18n()
  const { isPhone } = useMediaQueries()
  const {
    isLoading,
    isFailed,
    titleText,
    descriptionText,
    isCompleted,
    isPremiumUser,
    previewDescription,
    handleRetryClick,
    handleClose,
    handleUnlock,
    handleClickEditManually,
    autoTailoredResume,
    score,
    isJobPostingSaving,
  } = useAutoTailoringModal()

  useEffect(() => {
    if (isCompleted && score) {
      trackInternalEvent('see_auto_tailoring_result_screen', {
        mode: isPremiumUser ? 'premium' : 'free',
        score: score,
      })
    } else if (isFailed) {
      trackInternalEvent('see_auto_tailoring_error_screen')
    }
  }, [isCompleted, isFailed, isPremiumUser, score])

  const showPremiumAd = isCompleted && !isPremiumUser
  const buttonSize = isPhone ? ButtonSize.small : ButtonSize.default

  const previewComponent = useMemo(() => {
    if (!autoTailoredResume) return null

    // For free users we don't create a resume in DB, so we can't use the document preview,
    // But we are able to use pure client-side pdf renderer
    if (isPremiumUser) {
      const resumeSrc = autoTailoredResume ? formatDocumentPreviewUrl(autoTailoredResume) : ''
      return (
        <DocumentPreviewWrapper>
          <DocumentImagePreview src={resumeSrc} />
        </DocumentPreviewWrapper>
      )
    } else {
      return (
        <PdfPreview
          width={isPhone ? PHONE_PREVIEW_WIDTH : DESKTOP_PREVIEW_WIDTH}
          document={autoTailoredResume}
          onPaginationChange={() => {}}
        />
      )
    }
  }, [autoTailoredResume, isPremiumUser, isPhone])

  return (
    <ModalOverlay onClose={handleClose}>
      <Container>
        <Content>
          <ProcessWrapper>
            <ProcessContent>
              <ProcessHeader>
                {isLoading && <LoadingIcon />}
                {isFailed && <FailedIcon />}
                <Title>{titleText}</Title>
              </ProcessHeader>
              <Description>{descriptionText}</Description>
              <TailoringSections isPassive={isFailed} isLoading={isLoading} />
            </ProcessContent>

            {showPremiumAd && <FreeUserDeclaration />}

            {!isLoading && (
              <ProcessFooter>
                {showPremiumAd && (
                  <Note>
                    {i18n.t(
                      'builder.auto_tailoring.tailoring_model.result.free_user.complete_manually_text',
                    )}
                  </Note>
                )}
                <ActionContainer>
                  {isFailed && (
                    <ActionButtonWithIcon
                      onClick={handleRetryClick}
                      theme={ButtonTheme.alternative}
                      size={buttonSize}
                    >
                      <ArrowTargetIcon />
                      {i18n.t('builder.auto_tailoring.tailoring_model.error.retry_button')}
                    </ActionButtonWithIcon>
                  )}
                  {showPremiumAd && (
                    <ActionButton
                      onClick={handleClickEditManually}
                      theme={ButtonTheme.ghost}
                      size={buttonSize}
                      isDisabled={isJobPostingSaving}
                    >
                      {i18n.t(
                        'builder.auto_tailoring.tailoring_model.result.free_user.edit_manually_button_text',
                      )}
                    </ActionButton>
                  )}
                  {showPremiumAd && (
                    <ActionButtonWithIcon
                      onClick={handleUnlock}
                      theme={ButtonTheme.alternative}
                      size={buttonSize}
                      isDisabled={isJobPostingSaving}
                    >
                      <ArrowTargetIcon />
                      {i18n.t(
                        'builder.auto_tailoring.tailoring_model.result.free_user.unlock_button',
                      )}
                    </ActionButtonWithIcon>
                  )}
                </ActionContainer>
              </ProcessFooter>
            )}
          </ProcessWrapper>
          <PreviewWrapper>
            <PreviewHeader>
              <Score />
              <PreviewDescription>{i18n.t(previewDescription as string)}</PreviewDescription>
            </PreviewHeader>
            <DocumentPreviewContainer>
              {isLoading || !previewComponent ? (
                <LoaderContainer>
                  <Loader src={loaderImage} />
                </LoaderContainer>
              ) : (
                <DocumentPreviewWrapper>{previewComponent}</DocumentPreviewWrapper>
              )}
            </DocumentPreviewContainer>
          </PreviewWrapper>
        </Content>
        {(isFailed || (isCompleted && !isPremiumUser)) && <CloseButton onClick={handleClose} />}
      </Container>
    </ModalOverlay>
  )
}
