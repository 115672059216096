import { forwardRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import { useI18n } from 'builder/hooks/useI18n'

import { Container, Icon, Title, Subtitle } from './styles'

const OfflinePreview = forwardRef((props, ref) => {
  const { i18n } = useI18n()

  const noop = () => {}
  useImperativeHandle(ref, () => ({
    prevPage: noop,
    resize: noop,
    nextPage: noop,
  }))

  return (
    <Container w={props.width} h={props.height}>
      <Icon />
      <Title>{i18n.t('builder.resume_editor.no_connection')}</Title>
      <Subtitle>{i18n.t('builder.resume_editor.preview_is_not_available')}</Subtitle>
    </Container>
  )
})

OfflinePreview.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
}

export default OfflinePreview
