import { useTransition } from '@react-spring/web'
import { LottieAnimation } from 'builder/components/LottieAnimation'
import confetti from './assets/confetti.json'
import { transitionValues } from './constants'
import { Text, Wrapper, Container, InnerContainer, Image, AnimationContainer } from './styles'

const thumbsUpUrl = require('./assets/img/thumbs-up.svg')

const animationOptions = {
  loop: false,
  autoPlay: true,
  animationData: confetti,
}

interface Props {
  isGreetVisible: boolean
}

const Greet = ({ isGreetVisible }: Props) => {
  const transition = useTransition(isGreetVisible, transitionValues)

  return transition((style, item) =>
    item ? (
      <Wrapper style={style}>
        <AnimationContainer>
          <LottieAnimation width="100%" height="100%" options={animationOptions} />
        </AnimationContainer>
        <Container data-testid="greet-container">
          <InnerContainer>
            <Image src={thumbsUpUrl} alt="thumbs-up" />
            <Text>Thanks for your Feedback!</Text>
          </InnerContainer>
        </Container>
      </Wrapper>
    ) : null,
  )
}

export default Greet
