import { trackInternalEvent } from '@rio/tracking'
import snakeCase from 'lodash/snakeCase'

type StepEventCode = 'details' | 'profile' | 'work_experiences' | 'educations' | 'skills'

export const trackStepVisit = (stepName: string) => {
  const stepCode = snakeCase(stepName) as StepEventCode

  trackInternalEvent(`visit_wizard_${stepCode}_step`)
}
