import styled from 'styled-components'
import Typography from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

export const Content = styled.div`
  padding-bottom: 6px;
  flex: 1 1 auto;
`

export const Company = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};

  margin-bottom: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Title = styled.div`
  ${Typography.Body};
  height: 45px;

  color: ${Colors.Neutral90};
  font-weight: 500;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${Media.Phone`
    height: 42px;
  `}
`
