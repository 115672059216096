import { trackInternalEvent } from '@rio/tracking'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useI18n } from 'builder/hooks/useI18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/user'
import { selectors as initSelectors } from 'builder/modules/init'
import { useAutoApply } from 'builder/views/AutoApply/hooks/useAutoApply'
import { PauseModal } from '../PauseModal'
import {
  Container,
  Title,
  Item,
  ItemContainer,
  ButtonsContainer,
  PremiumButton,
  CancelSubcriptionText,
  Header,
  Items,
  ItemNumberContainer,
  ItemNumber,
  Subtitle,
  Content,
  LeftSide,
  WideFeatureSlotTextContainer,
  WideFeatureSlot,
  FeatureSlotTitle,
  WideFeatureSlotImage,
  ModalImageSectionContent,
  NarrowFeatureSlotsContainer,
  FeatureSlotDescription,
  FeatureSlot,
  TabletTitle,
} from './styles'
import ICON from './assets/icon.png'

interface SubscriptionCancellationAutoApplyWarningProps {
  onContinueCancellation: () => void
}

const ITEMS = (t: (key: string) => string) => [
  {
    text: t('builder.subscription_cancellation_v4.auto_apply.items.one'),
  },
  {
    text: t('builder.subscription_cancellation_v4.auto_apply.items.two'),
  },
  {
    text: t('builder.subscription_cancellation_v4.auto_apply.items.three'),
  },
]

export const SubscriptionCancellationAutoApplyWarning = ({
  onContinueCancellation,
}: SubscriptionCancellationAutoApplyWarningProps) => {
  const navigate = useNavigate()
  const { i18n } = useI18n()
  const [isPauseModalOpen, setIsPauseModalOpen] = useState(false)
  const userData = useTypedSelector(selectors.userData)
  const alreadyGotFreePausePeriodDuringCancellation =
    userData?.billingInfo?.alreadyGotFreePausePeriodDuringCancellation
  const locale = useTypedSelector(initSelectors.locale)
  const { getAutoApplyCredit, hasUserCompletedQuestionnaire, redirectToAutoApplyQuestionnaire } =
    useAutoApply()
  const { creditCount, creditCountUsed } = getAutoApplyCredit()
  const creditLeft = creditCount - creditCountUsed

  useEffect(() => {
    trackInternalEvent('see_aa_page')
  }, [])

  return (
    <Container>
      <Header>{i18n.t('builder.subscription_cancellation_v4.auto_apply.header')}</Header>
      <TabletTitle>
        {i18n.t('builder.subscription_cancellation_v4.auto_apply.title', {
          credit_left: creditLeft,
        })}
      </TabletTitle>
      <Content>
        <LeftSide>
          <Title>
            {i18n.t('builder.subscription_cancellation_v4.auto_apply.title', {
              credit_left: creditLeft,
            })}
          </Title>
          <Subtitle>{i18n.t('builder.subscription_cancellation_v4.auto_apply.subtitle')}</Subtitle>
          <Items>
            {ITEMS(i18n.t).map((item, index) => (
              <ItemContainer key={item.text}>
                <ItemNumberContainer>
                  <ItemNumber>{index + 1}</ItemNumber>
                </ItemNumberContainer>
                <Item>{item.text}</Item>
              </ItemContainer>
            ))}
          </Items>
          <ButtonsContainer>
            <PremiumButton
              onClick={() => {
                trackInternalEvent('promo_acceptance', { promo: 'AA', where: 'cancellation' })
                hasUserCompletedQuestionnaire
                  ? navigate('/job-search')
                  : redirectToAutoApplyQuestionnaire()
              }}
            >
              {i18n.t('builder.subscription_cancellation_v4.auto_apply.premium_button')}
            </PremiumButton>
            <CancelSubcriptionText
              onClick={() => {
                if (alreadyGotFreePausePeriodDuringCancellation || locale === 'nl-NL') {
                  onContinueCancellation()
                } else {
                  setIsPauseModalOpen(true)
                }
              }}
            >
              {i18n.t('builder.subscription_cancellation_v4.auto_apply.cancel_button')}
            </CancelSubcriptionText>
          </ButtonsContainer>
        </LeftSide>
        <ModalImageSectionContent>
          <WideFeatureSlot>
            <WideFeatureSlotTextContainer>
              <FeatureSlotTitle>
                {i18n.t(
                  'builder.subscription_cancellation_v4.auto_apply.feature_slots.first.title',
                )}
              </FeatureSlotTitle>
              <FeatureSlotDescription maxWidth={184}>
                {i18n.t(
                  'builder.subscription_cancellation_v4.auto_apply.feature_slots.first.description',
                )}
              </FeatureSlotDescription>
            </WideFeatureSlotTextContainer>
            <WideFeatureSlotImage src={ICON} />
          </WideFeatureSlot>
          <NarrowFeatureSlotsContainer>
            <FeatureSlot variant="indigo">
              <FeatureSlotTitle>
                {i18n.t(
                  'builder.subscription_cancellation_v4.auto_apply.feature_slots.second.title',
                )}
              </FeatureSlotTitle>
              <FeatureSlotDescription maxWidth={139}>
                {i18n.t(
                  'builder.subscription_cancellation_v4.auto_apply.feature_slots.second.description',
                )}
              </FeatureSlotDescription>
            </FeatureSlot>
            <FeatureSlot variant="green">
              <FeatureSlotTitle>
                {i18n.t(
                  'builder.subscription_cancellation_v4.auto_apply.feature_slots.third.title',
                )}
              </FeatureSlotTitle>
              <FeatureSlotDescription maxWidth={139}>
                {i18n.t(
                  'builder.subscription_cancellation_v4.auto_apply.feature_slots.third.description',
                )}
              </FeatureSlotDescription>
            </FeatureSlot>
          </NarrowFeatureSlotsContainer>
        </ModalImageSectionContent>
      </Content>
      {isPauseModalOpen && (
        <PauseModal
          onContinueCancellation={onContinueCancellation}
          onClose={() => setIsPauseModalOpen(false)}
        />
      )}
    </Container>
  )
}
