import styled, { css } from 'styled-components'
import Icon24 from 'builder/components/Icon'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import { rotation } from 'builder/styles/keyframes'
import { Shine } from '../../../DashboardView/ActiveInterviews/style'

export const Container = styled.div<{ $showSidebar: boolean }>`
  display: ${props => (props.$showSidebar ? 'flex' : 'none')};
  position: relative;
  flex-direction: column;
  background: ${Colors.White};
  padding: 24px;
  border-radius: var(--S, 16px);
  margin: 8px;
  margin-left: 0;
  flex-shrink: 0;
  width: 456px;

  ${Media.AboveTablet`
    display: flex;
  `};

  ${Media.Tablet`
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 553;
  `};

  ${Media.Phone`
      position: absolute;
      top: 90px;
      right: 0;
      bottom: 0;
      z-index: 553;
      margin: 0;
      width: unset;
      max-width: 456px;
  `};
`
export const QuestionTitle = styled.div`
  display: none;

  ${Media.AboveTablet`
    ${Typography.Body};
    ${FontWeights.Medium};
    display: block;
    color: ${Colors.Neutral50};
    margin-left: 13px
  `};
`

export const Question = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-shrink: 0;
  margin-bottom: 33px;
`

export const DisplayContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
`

export const Text = styled.div<{ $isFree: boolean }>`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral60};
  flex-shrink: 0;
  white-space: pre-wrap;
  ${({ $isFree }) =>
    $isFree &&
    `
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  `}
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 4px;
`

export const ButtonContainer = styled.div`
  display: flex;

  ${Media.Phone`
      display: none;
  `};
`

export const PrevNextButton = styled.div<{ $disabled: boolean; $marginRight?: number }>`
  ${props => props.$marginRight && `margin-right: ${props.$marginRight}px`};
  z-index: 554;
  width: 24px;
  height: 24px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  ${props => {
    if (props.$disabled) {
      return css`
        background: ${Colors.Neutral5};
        & path {
          color: ${Colors.Neutral30};
          fill: currentColor;
        }
      `
    }

    return css`
      background: ${Colors.Neutral10};
      & path {
        color: ${Colors.Neutral50};
        fill: currentColor;
      }
    `
  }}

  ${props =>
    !props.$disabled &&
    `
  ${Media.Hover`
  &:hover {  
    
      cursor: pointer;
    }
`}
  
  `};

  ${Media.Phone`
    width: 40px;
    height: 40px;
  `};
`

export const Body = styled.div<{ $isPremium: boolean }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  position: relative;
  ${({ $isPremium }) => ($isPremium ? `overflow: auto` : `overflow: hidden`)};
`

export const CloseIcon = styled(Icon24.Close)`
  display: none;
  z-index: 554;
  ${Media.Tablet`
      display: block;
      margin-left: 24px;
      color: ${Colors.Neutral50};
      
  `};
  ${Media.Hover`
    &:hover {  
        cursor: pointer;
      }
  `};
`

export const CustomBadgeCloseIconContainer = styled.div`
  display: flex;
  align-items: center;

  ${Media.Phone`
      display: flex;
      width: 100%;
      justify-content: space-between;
  `};
`

export const FooterMobile = styled.div`
  display: none;
  padding-top: 20px;
  ${Media.Phone`
      ${Typography.Body};
      ${FontWeights.Medium};
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      color: ${Colors.Neutral50};
  `};
`

export const LoadingBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 93px;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`

export const LoadingTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const LoadingText = styled.div`
  ${Typography.Caption};
  ${FontWeights.Regular};
  max-width: 280px;
  text-align: center;
  padding-top: 8px;
`

export const Spinner = styled(Icon24.Spinner)`
  display: flex;
  animation: ${rotation} 0.6s infinite linear;
  height: 32px;
  width: 32px;
`

export const SkeletonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  width: 100%;
`

export const Skeleton = styled.div<{ $height: number }>`
  background-color: ${Colors.Neutral5};
  border-radius: 6px;
  width: 100%;
  height: ${({ $height }) => $height}px;
  background: ${Colors.Neutral5};
  background-image: linear-gradient(
    90deg,
    ${Colors.Neutral5} 0px,
    ${Colors.Neutral10} 45px,
    ${Colors.Neutral5} 90px
  );
  background-size: ${({ $height }) => 2 * $height}px;
  animation: ${Shine} 0.7s infinite cubic-bezier(0.03, 0.26, 0.5, 0.8);
`
