import { all, put, call, takeLatest } from 'redux-saga/effects'
import { apiClient } from 'builder/modules/apiClient'
import { FetchStatuses } from 'builder/modules/constants'
import { actions, initialState } from './offerAnalyzerModule'

export function* fetchOfferSuggestedSaga({
  payload,
}: ReturnType<typeof actions.fetchOfferSuggested>) {
  try {
    yield put(actions.setFetchAnalyzerStatus(FetchStatuses.loading))

    const { data: analyzedOfferDetails, message } = yield call(
      apiClient.post,
      `/offer-analyzer/internal_create`,
      payload,
    )
    if (analyzedOfferDetails) {
      yield put(actions.setAnalyzedOfferDetails(analyzedOfferDetails.data))
      yield put(actions.setFetchAnalyzerStatus(FetchStatuses.loaded))
    } else if (message && message.contains('This function is not available')) {
      yield put(actions.setAnalyzedOfferDetails(initialState.analyzedOfferDetails))
      yield put(actions.setFetchAnalyzerStatus(FetchStatuses.failed))
    }
  } catch (error) {
    yield put(actions.setAnalyzedOfferDetails(initialState.analyzedOfferDetails))
    yield put(actions.setFetchAnalyzerStatus(FetchStatuses.failed))
  }
}

/** Bind side-effect handlers */
export const sagas = function* saga() {
  yield all([takeLatest(actions.fetchOfferSuggested, fetchOfferSuggestedSaga)])
}
