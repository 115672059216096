import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { Typography } from 'builder/styles/typography'
import { Icon20 } from 'builder/components/Icon'

export const AlertContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${Colors.Neutral15};
  border-radius: 4px;
  padding: 16px 24px;
  text-align: left;
`

export const AlertIcon = styled(Icon20.WarningCircle)`
  flex-shrink: 0;
  margin-right: 16px;
  color: ${Colors.Amber40};
`

export const AlertText = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral60};
  flex-grow: 1;
`
