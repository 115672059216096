import { useDispatch } from 'react-redux'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { trackInternalEvent } from '@rio/tracking'
import { baseClient } from 'builder/modules/apiClient'
import ErrorLogger from 'builder/services/ErrorLogger'
import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import useWebsiteHost from 'builder/components/CIO-Dasboard-2.0/hooks/useWebsiteHost'
import {
  AutoSuggestItem,
  CandidatePayload,
  IJobPreferencesForm,
  ResumeDetails,
  actions,
  selectors,
} from 'builder/modules/resumeDistribution'
import { selectors as userSelectors } from 'builder/modules/user'
import { actions as uiActions, SnackbarTypes } from 'builder/modules/ui'

import * as SimpleForm from 'builder/components/SimpleForm'
import { BriefResume } from 'builder/modules/panel'
import { FetchStatuses } from 'builder/modules/constants'
import { goToBillingPlanPage } from 'builder/utils/goToBillingPlanPage'
import { ERDSteps } from '../../types'
import { fileToBase64, stringifyFormData } from '../../utils'
import { JobPreferences } from '../Forms/JobPreferences/JobPreferences'

import {
  Column,
  Container,
  FormHeader,
  Header,
  Section,
  SubHeader,
  FormWrapper,
  InfoContainer,
  ExtensionIcon,
  InfoText,
} from './styles'
import { JobPreferencesBanner } from './JobPreferencesBanner/JobPreferencesBanner'
import { convertFormDataToObject } from './utils'

const TRANSLATION = 'builder.resume_distribution.landing.job_preferences_form'

interface IProps {
  setActiveStep: (step: ERDSteps) => void
}

export const JobPreferencesForm = ({ setActiveStep }: IProps) => {
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { getHost } = useWebsiteHost()
  const { isPhone, isTablet } = useMediaQueries()

  const userAccountData = useTypedSelector(userSelectors.userData)
  const upgradeUrl = useTypedSelector(userSelectors.getUpgradeUrl)
  const formState = useTypedSelector(selectors.jobPreferencesFields)
  const personalDetails = useTypedSelector(selectors.personalDetailsFields)
  const candidate = useTypedSelector(selectors.candidate)
  const candidatePostStatus = useTypedSelector(selectors.candidatePostStatus)
  const candidatePatchStatus = useTypedSelector(selectors.candidatePatchStatus)
  const candidateResumePostStatus = useTypedSelector(selectors.candidateResumePostStatus)
  const candidateResumeFailed = useTypedSelector(selectors.candidateResumeFailed)
  const serverFailed = useTypedSelector(selectors.serverFailed)
  const isSubscriptionActive = useTypedSelector(selectors.isSubscriptionActive)

  const isEdit = useTypedSelector(selectors.isEdit)
  const resume: ResumeDetails | undefined = useTypedSelector(selectors.selectedResumeDetails)
  const isLoading =
    candidatePostStatus === FetchStatuses.loading || candidatePatchStatus === FetchStatuses.loading

  const form = useForm<IJobPreferencesForm>({
    defaultValues: { ...formState },
  })

  const setFieldValue = useCallback(
    (field: string, value?: number | string | AutoSuggestItem) => {
      dispatch(actions.setJobPreferencesForm({ [field]: value }))
    },
    [dispatch],
  )

  const storeToLocalStorage = async (data: FormData) => {
    const stringifiedData = stringifyFormData(data)
    localStorage.setItem('resume-distribution', stringifiedData)

    const file = data.get('resume_file') as File
    if (file) {
      const base64 = await fileToBase64(file)
      localStorage.setItem('resume-distribution-file', base64)
      localStorage.setItem('resume-distribution-file-name', file.name)
    }
  }

  const redirectOnPayments = () => {
    goToBillingPlanPage({
      upgradeURL: upgradeUrl,
      referrerPage: 'resumeDistribution',
      onSuccessRedirect: '/app/resume-distribution?submit_resume=true',
    })
  }

  const openSnackBar = useCallback(() => {
    setTimeout(() => {
      dispatch(
        uiActions.setSnackBarOpen({
          status: true,
          type: SnackbarTypes.success,
          text: i18n.t(`${TRANSLATION}.edit_info`),
        }),
      )
    }, 1000)

    setTimeout(() => {
      dispatch(uiActions.closeSnackBar())
    }, 5000)
  }, [dispatch, i18n])

  const editCandidate = async (bodyFormData: FormData) => {
    const payload = convertFormDataToObject<CandidatePayload>(bodyFormData)

    if (personalDetails?.email === candidate?.email) {
      payload.email = undefined
    }

    try {
      if (!isSubscriptionActive) {
        await baseClient.post('resume-distribution/candidates/subscriptions', {})
      }

      if (resume) {
        dispatch(actions.postCandidateResume(resume))
      }

      dispatch(actions.patchCandidate(payload))
    } catch (err) {
      ErrorLogger.log(err)
    }
  }

  const handleSubmit = async () => {
    const { id: resumeId, type: resumeType } = (resume as BriefResume) || {}

    const bodyFormData = new FormData()
    bodyFormData.append('first_name', personalDetails?.firstName || '')
    bodyFormData.append('last_name', personalDetails?.lastName || '')
    bodyFormData.append('job_title', personalDetails?.jobTitle || '')
    bodyFormData.append('email', personalDetails?.email || '')
    bodyFormData.append('full_address', personalDetails?.fullAddress || '')
    bodyFormData.append('preferences[country_id]', formState.country?.id || '')
    bodyFormData.append('preferences[state_id]', formState.state?.id || '')
    bodyFormData.append('preferences[industry_id]', formState.industry.toString() || '')
    bodyFormData.append('preferences[remote]', formState.remote === 'true' ? 'yes' : 'no')
    bodyFormData.append('preferences[desired_salary][currency]', formState.salaryCurrency)
    bodyFormData.append('preferences[desired_salary][amount]', formState.salaryAmount)
    bodyFormData.append('preferences[desired_salary][per]', formState.salaryPeriod)
    if (personalDetails?.linkedin) {
      bodyFormData.append('linkedin_url', personalDetails?.linkedin || '')
    }

    if (resume) {
      // @ts-expect-error resume type
      const isTrDocument = resumeType === 'tr_document'
      const key = isTrDocument ? 'document_id' : `resume_${resumeId ? 'id' : 'file'}`
      bodyFormData.append(key, resumeId ? resumeId.toString() : (resume as string | Blob))
    }

    trackInternalEvent('rd_start_distributing', {
      website_host: getHost,
    })

    if (!userAccountData?.hasPremiumFeatures) {
      await storeToLocalStorage(bodyFormData)
      redirectOnPayments()
      return
    }

    if (isEdit) {
      editCandidate(bodyFormData)
    } else {
      dispatch(actions.postCandidate(bodyFormData))
    }
  }

  const checkSubmit = useCallback(() => {
    if (
      candidatePostStatus === FetchStatuses.loaded ||
      candidatePatchStatus === FetchStatuses.loaded ||
      candidateResumePostStatus === FetchStatuses.loaded
    ) {
      if (isEdit) {
        openSnackBar()
      }
      dispatch(actions.setPostCandidateStatus(FetchStatuses.notAsked))
      dispatch(actions.setPatchCandidateStatus(FetchStatuses.notAsked))
      dispatch(actions.setPostCandidateResumeStatus(FetchStatuses.notAsked))
      dispatch(actions.setIsEdit(false))
      navigate('/resume-distribution/dashboard')
    }

    if (candidateResumeFailed) {
      navigate('/resume-distribution/invalid-document')
      dispatch(actions.setCandidateResumeFailed(false))
      return
    }

    if (
      candidatePostStatus === FetchStatuses.failed ||
      candidatePatchStatus === FetchStatuses.failed ||
      candidateResumePostStatus === FetchStatuses.failed
    ) {
      if (serverFailed) {
        navigate('/resume-distribution/server-error')
        dispatch(actions.setServerFailed(false))
      }
    }
  }, [
    candidatePatchStatus,
    candidatePostStatus,
    candidateResumePostStatus,
    candidateResumeFailed,
    serverFailed,
    dispatch,
    isEdit,
    navigate,
    openSnackBar,
  ])

  useEffect(() => {
    trackInternalEvent('rd_job_preferences', { website_host: getHost })
  }, [getHost])

  useEffect(() => {
    checkSubmit()
  }, [candidatePatchStatus, candidatePostStatus, candidateResumePostStatus, checkSubmit])

  return (
    <Container>
      {!isPhone && !isTablet && (
        <Column>
          <Section />
        </Column>
      )}

      <Column>
        <FormWrapper>
          <FormHeader>
            <Header>{i18n.t(`${TRANSLATION}.header`)}</Header>
            <SubHeader>{i18n.t(`${TRANSLATION}.sub_header`)}</SubHeader>
          </FormHeader>

          {(isPhone || isTablet) && <JobPreferencesBanner data={form.getValues()} />}

          <SimpleForm.Form
            submitButtonText={i18n.t(`${TRANSLATION}.distribute_button`)}
            onSubmit={form.handleSubmit(handleSubmit)}
            onBackClick={() => setActiveStep(ERDSteps.FORM_STEP_1)}
            isDisabled={isLoading}
            isPending={isLoading}
            autoComplete="off"
          >
            <JobPreferences form={form} setStateFromField={setFieldValue} />

            <InfoContainer>
              <ExtensionIcon />
              <InfoText>{i18n.t(`${TRANSLATION}.cycle_info`)}</InfoText>
            </InfoContainer>
          </SimpleForm.Form>
        </FormWrapper>
      </Column>

      {!isPhone && !isTablet && (
        <Column>
          <Section>
            <JobPreferencesBanner data={form.getValues()} />
          </Section>
        </Column>
      )}
    </Container>
  )
}
