import styled from 'styled-components'
import Colors from 'builder/styles/colors'

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
`

export const ActivedDot = styled.div`
  border-radius: var(--2XS, 8px);
  background: ${Colors.Blue50};
  height: 6px;
  width: 16px;
`

export const DisabledDot = styled.div`
  border-radius: var(--2XS, 8px);
  background: ${Colors.Neutral20};
  height: 6px;
  width: 6px;
`
