import type { ResumeExample } from '@rio/types'

const match = (example: ResumeExample, query?: string) => {
  if (!query) return true
  if (!example.jobTitle) return false

  return example.jobTitle.toLowerCase().indexOf(query.toLowerCase()) >= 0
}

export const findExamples = (examples: ResumeExample[], query?: string): ResumeExample[] => {
  const results: ResumeExample[] = []

  for (let i = 0; i < examples.length; i++) {
    let example = examples[i]
    if (match(example, query)) results.push(example)
    if (results.length >= 20) break
  }

  return results
}
