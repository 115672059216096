import {
  ContentState,
  DraftBlockType,
  EditorState,
  Modifier,
  RichUtils,
  SelectionState,
} from 'draft-js'
import { BlockTypes } from './listPlugin'

/**
 * Determine whether the start of the paragraph indicates that it is part of an ordered list
 */
export const shouldEnterOl = (text: string, olRegex: RegExp) => {
  return olRegex.test(text)
}

/**
 * Determine whether the start of the paragraph indicates that it is part of an unordered list
 */
export const shouldEnterUl = (text: string, ulChars: string[]) => {
  return ulChars.includes(text[0])
}

/**
 * Start a list of the desired type and remove the characters which were typed for starting the list
 */
export const startList = (
  editorState: EditorState,
  content: ContentState,
  blockKey: string | null,
  blockType: DraftBlockType,
) => {
  if (!blockKey) {
    blockKey = content.getFirstBlock().getKey()
  }
  const selectionToReplace = new SelectionState({
    anchorKey: blockKey,
    anchorOffset: 0,
    focusKey: blockKey,
    focusOffset: blockType === BlockTypes.OL ? 3 : 2,
  })

  let updatedContent = Modifier.replaceText(content, selectionToReplace, ' ')

  let updatedState = EditorState.push(editorState, updatedContent, 'insert-characters')

  // Toggle the block type to the desired list type (OL or UL)
  updatedState = RichUtils.toggleBlockType(updatedState, blockType)

  // Force the new selection after toggling the block type
  updatedState = EditorState.forceSelection(updatedState, updatedContent.getSelectionAfter())

  return updatedState
}
