import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'

import OFFLINE_ICON_URL from 'images/builder/resume-editor/offline-sync.svg'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 6px;
  background-color: ${Colors.White};
  overflow: hidden;
  width: ${props => `${props.w}px`};
  height: ${props => `${props.h}px`};
  text-align: center;
  padding: 8px;
`

export const Icon = styled.div`
  width: 40px;
  height: 40px;
  margin-bottom: 8px;
  background-image: url(${OFFLINE_ICON_URL});
`

export const Title = styled.div`
  ${Typography.Subhead};
  font-weight: 600;
`

export const Subtitle = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
  max-width: 350px;
`
