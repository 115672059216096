import CareerPlanningFindCoach from 'builder/components/CareerPlanningFindCoach'
import { TopContainer } from '../../styles'

import Filters from './components/Filters'
import Header from './components/Header'

type Props = {
  isTablet: boolean
  isLoaded: boolean
  isFindCoachActive: boolean
}

const TopContent = ({ isTablet, isLoaded, isFindCoachActive }: Props) => {
  return (
    <>
      <TopContainer>
        <Header />
        {isTablet && isFindCoachActive && (
          <CareerPlanningFindCoach trackingLabel="execute_a_job_search" />
        )}
      </TopContainer>

      <Filters isLoaded={isLoaded} />
    </>
  )
}

export default TopContent
