import { useI18n } from 'builder/hooks/useI18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as PanelSelectors, AIRefreshStatus } from 'builder/modules/panel'
import { Icon20 } from 'builder/components/Icon'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import * as Styled from './styles'

interface IProps {
  id: number
}

export const ResumeRefresher = ({ id }: IProps) => {
  const { i18n } = useI18n()
  const { isPhone } = useMediaQueries()

  const aiJob = useTypedSelector(PanelSelectors.aiJobStatusById(id))
  const isAiRefresherPending = aiJob.status === AIRefreshStatus.PENDING
  const isAiRefresherFailed =
    aiJob.status === AIRefreshStatus.FAILURE || aiJob.status === AIRefreshStatus.RETRY

  return (
    <>
      {isAiRefresherPending && !isAiRefresherFailed ? (
        <Styled.LoadingDocument
          src={require('../assets/Resume_Scanning_Desktop.webm')}
          autoPlay
          loop
          muted
        />
      ) : (
        <>
          <Styled.FailedDocument src={require('../assets/FailedResume.svg')} />
          <Styled.Badge>
            <Styled.Text>
              <Icon20.Close />
              {isPhone
                ? i18n.t('builder.resume_tuner.ai_refresher.error')
                : i18n.t('builder.resume_tuner.ai_refresher.some_thing_went_wrong')}
            </Styled.Text>
          </Styled.Badge>
        </>
      )}
    </>
  )
}
