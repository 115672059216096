import React from 'react'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors, ColumnName } from 'builder/modules/jobTracking'
import Colors from 'builder/styles/colors'
import Icon24 from 'builder/components/Icon'
import { useJobTrackingUtilities } from '../useJobTrackingUtilities'
import { Job } from '../JobSearchResult/types'

import { TabType } from '../types'
import { ActionButton, DefaultHeartIcon } from './styles'

interface Props {
  job: Job & { position?: number }
  selectedTabId?: TabType
}

export const HeartIconButton = ({ job, selectedTabId }: Props) => {
  const { isPresent, handleAddJobBoard } = useJobTrackingUtilities(job, selectedTabId)

  // load job tracking jobs
  const cards = useTypedSelector(selectors.selectJobs)
  const jobTrackingCard = cards.find(
    card =>
      card.company === job.company &&
      card.title === job.job_title &&
      card.externalSlugId === job.external_slug_id &&
      card.status !== ColumnName.recommended,
  )

  const prevent = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleAddCard = (event: React.MouseEvent<HTMLDivElement>) => {
    prevent(event)
    handleAddJobBoard()
  }
  return (
    <ActionButton
      onClick={jobTrackingCard?.autoApplyStatus ? () => {} : handleAddCard}
      isDisabled={Boolean(jobTrackingCard?.autoApplyStatus)}
    >
      {isPresent && <Icon24.HeartFullFilled />}

      {!isPresent && <DefaultHeartIcon color={Colors.Neutral50} />}
    </ActionButton>
  )
}
