import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Icon, { Icon36, Icon20 } from 'builder/components/Icon'
import { rotation, gradient } from 'builder/styles/keyframes'
import Sizes from 'builder/styles/sizes'
import Button from 'builder/components/Button'
import { ModalClose } from 'builder/components/ModalClose'

export const DESKTOP_PREVIEW_WIDTH = 377
export const PHONE_PREVIEW_WIDTH = 288

export const Container = styled.div`
  border-radius: ${Sizes.S};
  width: 975px;
  min-height: 550px;
  background-color: ${Colors.White};
  position: relative;
  overflow: hidden;
  display: flex;

  ${Media.Phone`
    border-radius: ${Sizes.XS};
    min-height: 0;
  `};
`

export const Content = styled.div`
  width: 100%;
  min-height: 100%;
  display: grid;
  grid: 'processing preview' / 1fr 1fr;

  ${Media.Phone`
    grid: 'preview' 220px 'processing' auto / 1fr;
  `}
`

export const Header = styled.div`
  ${Typography.M};
  ${FontWeights.Medium};
  text-align: center;
`

export const ProcessWrapper = styled.div`
  grid-area: processing;
  padding: 42px 40px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;

  ${Media.Phone`
    padding: 24px;
    gap: 16px;
  `}
`

export const ProcessContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const ProcessHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${Sizes.XS};
  width: 100%;
`

export const LoadingIcon = styled(Icon.SpinnerV2)`
  width: 42px;
  height: 42px;
  fill: transparent;
  stroke: ${Colors.Blue50};
  animation: ${rotation} 0.5s linear infinite;
`

export const FailedIcon = styled(Icon36.Error3)`
  width: 28px;
  height: 28px;
`

export const Title = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};

  ${Media.Phone`
    ${Typography.S};
  `}
`

export const Description = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular};
  margin-top: 8px;
`

export const PreviewWrapper = styled.div`
  grid-area: preview;
  padding: 36px 58px;
  background-color: ${Colors.Neutral5};
  overflow: hidden;
  position: relative;

  ${Media.Phone`
    padding: 32px;
  `}
`

export const PreviewHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${Sizes['2XS']};
`

export const PreviewDescription = styled.div`
  ${Typography.Caption};
  ${FontWeights.DemiBold};
  color: ${Colors.Blue80};
  opacity: 0.6;
`

export const DocumentPreviewWrapper = styled.div`
  border-radius: 6px;
  border: 1px solid ${hexToRgba(Colors.Neutral60, 0.16)};
  box-shadow: 0px 6px 8px -2px rgba(15, 56, 113, 0.02), 0px 16px 64px -8px rgba(15, 56, 113, 0.18);
  width: ${DESKTOP_PREVIEW_WIDTH}px;
  overflow: hidden;

  ${Media.Phone`
    width: ${PHONE_PREVIEW_WIDTH}px;
  `}
`

export const DocumentPreviewContainer = styled.div`
  flex-shrink: 0;
  margin-top: 32px;
  position: absolute;

  ${Media.Phone`
    left: 50%;
    transform: translateX(-50%);
    margin-top: 16px;
  `}
`

export const ArrowTargetIcon = styled(Icon20.ArrowTarget2)`
  width: 24px;
  height: 24px;
`

export const ProcessFooter = styled.div`
  display: flex;
  flex-flow: column;
  gap: 12px;
`

export const Note = styled.div`
  ${Typography.Tiny};
  color: ${Colors.Neutral50};
`

export const ActionContainer = styled.div`
  display: flex;
  gap: ${Sizes.S};

  ${Media.Phone`
    justify-content: flex-end;
  `}
`

export const ActionButton = styled(Button)``

export const ActionButtonWithIcon = styled(Button)`
  gap: ${Sizes['3XS']};
  padding-right: ${Sizes.M};
  padding-left: ${Sizes.S};
`

export const CloseButton = styled(ModalClose)`
  position: absolute;
  top: 34px;
  right: 32px;

  ${Media.Phone`
    top: 24px;
    right: 24px;
  `}
`

export const Loader = styled.img`
  height: 100%;
  width: 100%;
`

export const LoaderContainer = styled.div`
  animation: ${gradient} 1s linear infinite;
  background: ${Colors.Neutral10};
  background: linear-gradient(
    to right,
    ${Colors.Neutral10} 5%,
    #eaeef6 15%,
    ${Colors.Neutral10} 25%
  );
  background-size: 100vw 100vh;
  width: ${DESKTOP_PREVIEW_WIDTH}px;

  ${Media.Phone`
    width: ${PHONE_PREVIEW_WIDTH}px;
  `}
`
