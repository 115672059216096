import styled from 'styled-components'
import Colors from 'builder/styles/colors'

export const StickContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 40px;
  height: 136px;
`

export const IconContainer = styled.div<{ index?: number }>`
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background: ${Colors.White};
  border: ${props => (props?.index === 0 ? `2px solid ${Colors.Blue50}` : 'none')};
  padding: 4px;
`

export const IconImage = styled.img`
  width: 100%;
  height: 100%;
`
