import axios, { AxiosResponse } from 'axios'
import { baseApiConfig, baseAppConfig, baseConfig } from './constants'
import {
  accessTokenGetterInterceptor,
  unauthorizedErrorInterceptor,
  authorizationHeaderSetterInterceptor,
} from './interceptors'

export const apiClient = axios.create(baseConfig)
// The user is redirected to the login page if they are not authenticated to make the request.
// We assume their session has expired. Keep an eye on it during migration to JWT authentication.
apiClient.interceptors.response.use(accessTokenGetterInterceptor, unauthorizedErrorInterceptor)
apiClient.interceptors.request.use(authorizationHeaderSetterInterceptor)

export const baseClient = axios.create(baseApiConfig)
baseClient.interceptors.response.use(accessTokenGetterInterceptor, unauthorizedErrorInterceptor)
baseClient.interceptors.request.use(authorizationHeaderSetterInterceptor)

export const authApiInstance = axios.create(baseConfig)
authApiInstance.interceptors.request.use(authorizationHeaderSetterInterceptor)

export const baseApiClient = axios.create(baseConfig)
export const baseAppClient = axios.create(baseAppConfig)

export { AxiosResponse as ApiResponse }
