import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors, { hexToRgba } from 'builder/styles/colors'

export const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
  display: flex;
  gap: 32px;

  ${Media.Tablet`
    gap: 16px;
    padding: 0 20px;
  `}
`

export const ContainerPhone = styled.div`
  padding-bottom: 47px;
  position: relative;
  user-select: none;

  &:before,
  &:after {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    width: calc(50vw);
    background: linear-gradient(
      90deg,
      ${hexToRgba(Colors.White, 0)} 0%,
      ${Colors.White} 128px,
      ${Colors.White} 100%
    );
  }

  &:before {
    right: calc(100% + 32px);
    transform: scaleX(-1);
  }

  &:after {
    left: calc(100% + 32px);
  }

  & .swiper-container {
    position: relative;
    overflow: visible;
  }

  & .swiper-wrapper {
    display: flex;
    position: relative;
    transition-property: transform;
  }

  & .swiper-slide {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
  }

  & .swiper-slide:not(:last-child) {
    margin-right: 8px;
  }

  ${Media.Tablet`
    padding: 0 20px;
  `}
`
