import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { actions, selectors } from 'builder/modules/resumeEditor'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { BannerTypes } from './types'

export const useResumeTailoringBanner = (location: 'builder' | 'header') => {
  const dispatch = useDispatch()
  const seeBannerEventTracked = useTypedSelector(
    state => state.resumeEditor.viewTailoringBannerEventTracked,
  )
  const score = useTypedSelector(selectors.resumeScore)

  const onBannerClick = useCallback(() => {
    trackInternalEvent('click_resume_optimizer_banner', { score })
    dispatch(actions.setOpenOptimizerModal(true))
  }, [dispatch, score])

  const bannerOption = useMemo(() => {
    return BannerTypes.TARGET
  }, [])

  useEffect(() => {
    /** Check to prevent event from being called twice */
    if (!seeBannerEventTracked) {
      dispatch(actions.setTailorBannerEventTracked(true))
      trackInternalEvent('see_resume_optimizer_banner')
    }
  }, [bannerOption, dispatch, location, seeBannerEventTracked])

  return { bannerOption, onBannerClick }
}
