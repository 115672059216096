import styled from 'styled-components'
import { FeatureIcon } from 'builder/components/FeatureIcon'

import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Container = styled.div`
  margin: 0 auto;
  display: flex;
  max-width: 1125px;
  gap: 100px;
  padding: 32px 20px;
  ${Media.Tablet`
    flex-direction: column;
    gap: 36px;
  `};
`

export const Box = styled.div`
  flex: 1;
`

export const AnimationContainer = styled.div`
  max-width: 544px;
  max-height: 544px;
  border-radius: 16px;
  background: ${Colors.Neutral5};
  display: flex;
  align-items: center;

  ${Media.Tablet`
    height: 336px;
  `}

  ${Media.Phone`
    max-width: 340px;
    height: 208px;
  `}
`

export const AnimationVideo = styled.video.attrs({ autoPlay: true, muted: true, loop: true })`
  width: 544px;

  ${Media.Tablet`
    height: 336px;
  `}

  ${Media.Phone`
    width: 340px;
    height: 208px;
  `}
`

export const Instruction = styled.div`
  display: flex;
  flex-direction: column;
  height: 544px;

  ${Media.Tablet`
    max-width: 540px;
    height: 404px;
  `}

  ${Media.Phone`
    max-width: 340px;
    height: 430px;
  `}
`

export const Presentation = styled.div`
  flex: 1;
`

export const Actions = styled.div`
  flex: none;
`

export const Icon = styled(FeatureIcon).attrs({ name: 'Documents' })`
  width: 24px;
  height: 24px;
`

export const Section = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  color: ${Colors.Neutral90};
  ${Typography.Body};
  ${FontWeights.Regular};
  margin-bottom: 24px;
`

export const Dot = styled.span`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${Colors.Neutral50};
`

export const StepSection = styled.span`
  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${Colors.Neutral50};
`

export const Header = styled.div`
  ${Typography.M};
  ${FontWeights.Medium};
  margin-bottom: 12px;
`

export const Description = styled.div`
  margin-bottom: 32px;
`
