import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useState } from 'react'
import { actions } from 'builder/modules/panel'
import useWebsiteHost from 'builder/components/CIO-Dasboard-2.0/hooks/useWebsiteHost'
import playImageButton from 'builder/components/Navigation/components/WelcomeModalButton/assets/welcome_modal_play_Buttons_hover.svg.svg'
import { useI18n } from 'builder/hooks/useI18n'
import * as Styles from './styled'

export const WelcomeModalButton = () => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const { getHost } = useWebsiteHost()
  const [isHovered, setIsHovered] = useState<boolean>(false)

  const handleOpenModal = () => {
    dispatch(actions.setOpenWelcomeModal(true))

    trackInternalEvent('click_play_video', {
      label: 'navigation',
      website_host: getHost,
    })
  }

  return (
    <Styles.Wrapper
      onClick={handleOpenModal}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Styles.ImageWrapper>
        <Styles.ImagePlay isHovered={isHovered} src={playImageButton} />
      </Styles.ImageWrapper>
      <Styles.Text isHovered={isHovered}>{i18n.t('builder.navigation.modal_button')}</Styles.Text>
      <Styles.Divider />
    </Styles.Wrapper>
  )
}
