import { useCallback, useState } from 'react'
import * as React from 'react'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'
import type { Optional } from '@rio/types'
import { Link } from 'react-router-dom'
import { useI18n } from 'builder/hooks/useI18n'
import { FetchStatuses } from 'builder/modules/constants'
import { UserData } from 'builder/modules/user'
import Button, { ButtonTheme } from 'builder/components/Button'
import { FormSection } from './FormSection'
import { validate, formatSubmitPayload } from './utils'
import {
  ReasonType,
  ReasonToUse,
  ReasonToCancel,
  Reason,
  SubmitPayload,
  SelectedState,
  CustomAnswersState,
} from './types'
import { ErrorMessage, Footer, FooterText, FooterButtons } from './styles'

const reasonGroups = {
  [ReasonType.use]: ReasonToUse,
  [ReasonType.cancel]: ReasonToCancel,
}

interface FormProps {
  status: FetchStatuses
  errorMessage: Optional<string>
  availableFreePeriod: UserData['availableFreePeriod']
  onSubmit: (payload: SubmitPayload) => void
}

export const SubscriptionCancellationForm = ({ status, errorMessage, onSubmit }: FormProps) => {
  const { i18n } = useI18n()

  const [selectedReasons, setSelectedReasons] = useState<SelectedState>(
    mapValues(reasonGroups, () => null),
  )

  const [customAnswers, setCustomAnswers] = useState<CustomAnswersState>(
    mapValues(reasonGroups, () => ''),
  )

  const isDisabled = status === FetchStatuses.loading || !validate(selectedReasons, customAnswers)

  const cancelSubscription = () => {
    onSubmit(formatSubmitPayload(selectedReasons, customAnswers))
  }

  const handleReasonSelect = useCallback((type: ReasonType, value: Reason) => {
    setSelectedReasons(fields => ({ ...fields, [type]: value }))
  }, [])

  const handleCustomAnswerChange = useCallback((type: ReasonType, value: string) => {
    setCustomAnswers(fields => ({ ...fields, [type]: value }))
  }, [])

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (isDisabled) return
    cancelSubscription()
  }

  return (
    <form onSubmit={handleFormSubmit}>
      {map(ReasonType, type => (
        <FormSection
          key={type}
          type={type}
          reasons={reasonGroups[type]}
          selectedReason={selectedReasons[type]}
          customAnswer={customAnswers[type]}
          onReasonSelect={handleReasonSelect}
          onCustomAnswerChange={handleCustomAnswerChange}
        />
      ))}

      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

      <Footer>
        <FooterText>{i18n.t('builder.subscription_cancellation.form.are_you_sure')}</FooterText>
        <FooterButtons>
          <Button theme={ButtonTheme.ghost} as={Link} to="/">
            {i18n.t('builder.subscription_cancellation.form.button_no')}
          </Button>
          <Button type="submit" isDisabled={isDisabled}>
            {i18n.t('builder.subscription_cancellation.form.button_yes')}
          </Button>
        </FooterButtons>
      </Footer>
    </form>
  )
}
