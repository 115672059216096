import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Shadows from 'builder/styles/shadows'
import Sizes from 'builder/styles/sizes'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Container = styled.div`
  background-color: ${Colors.White};
  border-radius: ${Sizes.XS};
  ${Shadows.dark.high};
  overflow: hidden;
  max-width: 352px;
`

export const WrapperText = styled.div`
  padding: ${Sizes.M};
  border-radius: ${Sizes.XS};
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
`

export const Img = styled.img`
  width: 100%;
  height: auto;
  max-height: 192px;
  ${Media.Phone`
    max-height: 188px;
  `}
`
export const Title = styled.div`
  ${Typography.Subhead};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral90};
  margin-bottom: ${Sizes['2XS']};
`
export const Content = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
  margin-bottom: ${Sizes.M};
`
