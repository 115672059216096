import styled from 'styled-components'
import { Typography } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'

export const Title = styled.div`
  ${Typography.Tiny};
  height: 24px;
  cursor: pointer;
  padding: 4px 6px;
  border-radius: 2px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.Neutral50};
  background: ${Colors.Neutral10};

  &:hover,
  &:focus-visible {
    color: ${Colors.Blue60};
  }
`

export const Container = styled.div`
  gap: 5px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

export const SuggestedSkillsText = styled.div`
  ${Typography.Caption};
  height: 20px;
  left: 176px;
  top: 366px;
  white-space: nowrap;
  color: ${Colors.Neutral80};
`
