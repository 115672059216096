import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Icon24 from 'builder/components/Icon'
import Media from 'builder/styles/media'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
export const ActionButtonsLeft = styled.div`
  display: flex;
  gap: 4px;
`
export const MenuButton = styled(Icon24.DashboardBuilder)`
  padding: 8px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  background-color: ${Colors.Neutral15};
  cursor: pointer;
  ${Media.Phone`
      display: hidden;
  `};
`

export const DashboardButton = styled.div`
  padding: 8px 16px;
  background-color: ${Colors.Neutral15};
  border-radius: 32px;
  cursor: pointer;
`

export const CloseIcon = styled(Icon24.Close)`
  min-width: 40px;
  min-height: 40px;
  padding: 8px;
  border-radius: 50%;
  background-color: ${Colors.Neutral15};
  cursor: pointer;
`
