export const HEADER_SUBTEXT_MOBILE = [
  "We've carefully vetted each of these Coaches.",
  'Choose the one who feels right for you. If your Coach',
  "isn't a fit, you can switch after your first session.",
]

export const HEADER_SUBTEXT_DESKTOP = [
  "We've carefully vetted each of these Coaches. Choose the one who feels right for you. If your",
  "Coach isn't a fit, you can switch after your first session.",
]

export const HEADER_TEXT_MOBILE = 'Here are your top matches'

export const HEADER_TEXT_DESKTOP = 'Here are your top coach matches'

export const FOOTER =
  'If you have questions about this coaching service, reach out to our support team via email at coaching@career.io.'
