import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const TopContainer = styled.div`
  gap: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${Media.Tablet`
    gap: 32px;
    width: 100%
  `};

  ${Media.Phone`
    gap: 32px;
    width: 100%
  `};
`

export const BottomContainer = styled.div`
  background: ${Colors.White};
  padding: 72px 0px;

  ${Media.Tablet`
    gap: 32px;
    padding: 72px 44px;
  `}

  ${Media.Phone`
    gap: 32px;
    padding: 56px 20px;
  `};
`
