import styled, { css, keyframes } from 'styled-components'
import Typography from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Icon from 'builder/components/Icon'

const moveUp = keyframes`
  from {
    transform: translateY(30%);
  }
  to {
    transform: translateY(0%);
  }
`

export const Container = styled.div<{ animate?: boolean }>(({ animate }) => {
  return css`
    position: relative;
    margin-bottom: 40px;
    ${animate &&
    css`
      animation: ${moveUp} 0.3s ease-out forwards;
    `}
  `
})

export const ShowButton = styled.div`
  ${Typography.Caption};
  font-weight: 600;
  color: ${Colors.Blue50};
  cursor: pointer;
  display: inline-block;

  &:hover {
    color: ${Colors.Blue70};
  }
`

export const ShowButtonText = styled.span`
  display: inline-block;
  vertical-align: middle;
`

export const ShowButtonIcon = styled(Icon.ToggleArrow)`
  display: inline-block;
  vertical-align: middle;
  transform: rotate(90deg);

  &[data-open='true'] {
    transform: translateY(2px) rotate(-90deg);
  }
`

export const AdditionalFields = styled.div`
  padding-bottom: 24px;
`
