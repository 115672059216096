import careerPlanningURL from './img/career_planning.svg'
import jobStrategyURL from './img/job_strategy.svg'
import personalBrandingURL from './img/personal_branding.svg'
import interviewPrepURL from './img/interview_prep.svg'
import salaryNegotiationURL from './img/salary_negotiation.svg'
import workplaceChallengesURL from './img/workplace_challenges.svg'

export const getThemeColors = (service: string) => {
  let bgColor = ''
  let color = ''
  switch (service) {
    case 'Personal Branding':
      bgColor = '#EAF6FF'
      color = '#0F3871'
      break
    case 'Job Search Strategy':
      bgColor = '#F1F2FF'
      color = '#282B8F'
      break
    case 'Career Planning Strategy':
      bgColor = '#F3F1EB'
      color = '#3C372D'
      break
    case 'Interview Prep':
      bgColor = '#E7F4ED'
      color = '#094025'
      break
    case 'Salary Negotiation':
      bgColor = '#FFF2CC'
      color = '#582C0C'
      break
    case 'Workplace Challenges':
      bgColor = '#E6F1F4'
      color = '#1E3E46'
      break

    default:
      break
  }
  return { bgColor: bgColor, color: color }
}

export const COACH_SERVICES = [
  {
    id: 1,
    name: 'Job Search Strategy',
    description:
      'Feeling like you could make more money with the skills you have now? Want to pursue a new industry? Uncover hidden jobs with the help of your coach through upskilling and reskilling. We can help with your resume, cover letter, and interviewing skills, as well as how to network and find job leads.',
    url: jobStrategyURL,
  },
  {
    id: 2,
    name: 'Career Planning Strategy',
    description:
      'Whether you’re exploring your options, wanting to go after that promotion, or feeling like it’s timefor a change, you’re likely to succeed if you have a plan. We can help you identify your skills, interests, and values to develop career goals and a step-by-step plan to achieve those goals.',
    url: careerPlanningURL,
  },
  {
    id: 3,
    name: 'Personal Branding',
    description:
      'Professional branding is essential in finding your dream job today. Work with your coach to find and use your value propositions to define your brand. We can help with leveraging your brand across your resume and LinkedIn profiles to communicate who you are in the marketplace so you can get noticed and land your dream job.',
    url: personalBrandingURL,
  },
  {
    id: 4,
    name: 'Interview Prep',
    description:
      'Got the interview or know it’s coming? You can never be too prepared. We can help you feel confident for any type of interview, whether it be behavior-based, virtual, phone, etc. You’ll even get guidance on your post-interview follow-up plan.',
    url: interviewPrepURL,
  },
  {
    id: 5,
    name: 'Salary Negotiation',
    description:
      'Are you anticipating an offer? Just received one? Or just accepted one? We can help you evaluate an offer, find out what is negotiable, or provide guidance after you’ve accepted. Think of your salary as a component of the total compensation and benefits package to make sure you’re valued.',
    url: salaryNegotiationURL,
  },
  {
    id: 6,
    name: 'Workplace Challenges',
    description:
      'Our career coaches will guide you to identify solutions that can change your behavior or help in developing a specific skill to achieve your career goal. We can help you manage your career transitions and deal with career challenges within your workplace.',
    url: workplaceChallengesURL,
  },
]
