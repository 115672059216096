import { SortEndHandler, SortableContainer } from 'react-sortable-hoc'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { Accomplishment } from 'builder/modules/sevenStories'
import { AccomplishmentsList, Container, Divider } from './style'
import { ToolTip } from './components/ToolTip'
import { AccomplishmentsLists } from './components/AccomplishmentsList'

type Props = {
  accomplishmentData: Accomplishment[]
  handleAccomplishmentSelect: (value: boolean, id: number) => void
  onSortEnd: SortEndHandler
  selectedAccomplishment: Accomplishment[]
  isMaxSelection: boolean
  handleCloseConfirmModal: () => void
  handleSubmitConfirmModal: () => void
  showConfirmModal: boolean
}

type ChildrenProps = {
  children: string | JSX.Element | JSX.Element[]
}

export const AccomplishmentsRank = ({
  accomplishmentData,
  handleAccomplishmentSelect,
  onSortEnd,
  isMaxSelection,
  selectedAccomplishment,
}: Props) => {
  const { isPhone } = useMediaQueries()
  // Required component only
  const isItemSelected = selectedAccomplishment.filter(item => item.isSelected).length > 0
  const newAccomplishmentData = accomplishmentData.filter(item => !item.isSelected)

  const DraggableList = SortableContainer(({ children }: ChildrenProps) => (
    <AccomplishmentsList>{children}</AccomplishmentsList>
  ))

  const SortableList = (items: Accomplishment[], isSelected: boolean) => {
    return (
      <DraggableList
        lockToContainerEdges={false}
        useDragHandle={!isSelected}
        shouldCancelStart={e => (e.target as HTMLInputElement).id === 'select_check'}
        onSortEnd={onSortEnd}
        lockAxis="y"
      >
        {items
          ?.filter(item => item.description !== '')
          .map((item, index) => (
            <AccomplishmentsLists
              key={index}
              {...{
                index,
                item,
                isSelected,
                isMaxSelection,
                handleAccomplishmentSelect,
                onSortEnd,
              }}
            />
          ))}
      </DraggableList>
    )
  }

  return (
    <>
      <Container>
        {!isPhone && <ToolTip />}
        {SortableList(selectedAccomplishment, true)}
        {isItemSelected && <Divider {...{ isItemSelected }} />}
        <AccomplishmentsList>{SortableList(newAccomplishmentData, false)}</AccomplishmentsList>
      </Container>
    </>
  )
}
