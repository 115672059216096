import * as Styled from './styles'

type Props = {
  careerTarget: string
  userPhotoUrl: string
}

export const CareerRoleVisual = ({ careerTarget, userPhotoUrl }: Props) => {
  return (
    <Styled.Plate>
      <Styled.Photo alt="User photo" src={userPhotoUrl} />
      <Styled.Text>{careerTarget}</Styled.Text>
    </Styled.Plate>
  )
}
