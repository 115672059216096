import { Option } from './styles'

type LevelOptionProps = {
  disabled: boolean
  rating?: number
  id: string
  inputName: string
  onSelect: (id: string) => void
  checked: boolean
}

export const LevelOption = ({
  disabled,
  rating,
  id,
  inputName,
  onSelect,
  checked,
}: LevelOptionProps) => {
  return (
    <Option rating={rating} currentRating={rating} isDisabled={disabled} checked={checked}>
      <input
        type="radio"
        name={inputName}
        value={id}
        checked={checked}
        onChange={() => onSelect(id)}
        disabled={disabled}
      />
    </Option>
  )
}
