import { memo, useRef } from 'react'
import { UserData } from 'builder/modules/user'
import { useI18n } from 'builder/hooks/useI18n'
import { useConfig } from 'builder/hooks/useConfig'
import { useEscapeHandler } from 'builder/hooks/useEscapeHandler'
import { authService } from 'builder/services/AuthService'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useClickOutside } from 'builder/hooks/useClickOutside'
import { ToggleCallback } from '../../types'
import {
  DropdownContainer,
  ProfileLink,
  ProfilePhoto,
  ProfilePhotoImage,
  ProfileContent,
  ProfileName,
  ProfileNote,
  ProfileArrow,
  Links,
  Link,
  PremiumButtonStyled,
  TopBlock,
  Container,
  UserDropdownTrigger,
  UserPhoto,
} from './styles'

interface Props {
  user: UserData
  photoUrl: string
  isOpen: boolean
  onToggle: ToggleCallback
}

const UserDropdownComponent = ({ user, photoUrl, isOpen, onToggle }: Props) => {
  const { i18n } = useI18n()
  const config = useConfig()
  const { isPhone } = useMediaQueries()
  const userRef = useRef<HTMLDivElement | null>(null)

  useClickOutside(userRef, () => onToggle(false))

  useEscapeHandler(() => onToggle(false))

  return (
    <>
      <Container ref={userRef} isPremium={user.hasPremiumFeatures}>
        <UserDropdownTrigger isOpen={isOpen} onClick={() => onToggle(!isOpen)}>
          <UserPhoto src={photoUrl} alt={user.fullName} />
        </UserDropdownTrigger>

        {isOpen && (
          <DropdownContainer>
            <TopBlock>
              <ProfileLink to="/account">
                <ProfilePhoto>
                  <ProfilePhotoImage src={photoUrl} alt={user.fullName} />
                </ProfilePhoto>
                <ProfileContent>
                  <ProfileName>{user.fullName}</ProfileName>
                  <ProfileNote>{i18n.t('builder.navbar.account_settings')}</ProfileNote>
                </ProfileContent>
                <ProfileArrow />
              </ProfileLink>
              {!user?.hasPremiumFeatures && isPhone && <PremiumButtonStyled />}
            </TopBlock>

            <Links>
              {config?.links.updates && (
                <Link href={config.links.updates} target="_blank" rel="noreferrer noopener">
                  {i18n.t('builder.navbar.updates')}
                </Link>
              )}

              {config?.links.help && (
                <Link href={config.links.help} target="_blank" rel="noreferrer noopener">
                  {i18n.t('builder.navbar.faq')}
                </Link>
              )}

              {/* TODO: Change logic during the migration to JWT-auth */}
              <Link as="button" onClick={() => authService.logout()} secondary>
                {i18n.t('builder.navbar.log_out')}
              </Link>
            </Links>
          </DropdownContainer>
        )}
      </Container>
    </>
  )
}

export const UserDropdown = memo(UserDropdownComponent)
