import { useDispatch } from 'react-redux'
import { useState, useCallback } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { Label, actions, selectors, JobProgressionsPath } from 'builder/modules/careerPath2'

import CardDetails from '../CardDetails'
import {
  Tick,
  Title,
  AddIcon,
  TimeIcon,
  MoreIcon,
  Container,
  SkillScore,
  HalfStatus,
  TitleValue,
  TitleContainer,
  AverageTimeSpan,
  SubTitleContainer,
} from './styles'

const Time = require('./assets/img/Time.svg')

type Props = {
  level?: number
  cardIndex?: number
  title?: JobProgressionsPath
}

type LevelType = null | number | undefined

const Card = ({ level, title, cardIndex }: Props) => {
  const { isLaptop, isTablet } = useMediaQueries()
  const dispatch = useDispatch()
  const [showCard, setShowCard] = useState(false)

  const careerPath1 = useTypedSelector(selectors.careerPath1)
  const level1JobTitles = useTypedSelector(selectors.level1JobTitles)
  const level2JobTitles = useTypedSelector(selectors.level2JobTitles)
  const level3JobTitles = useTypedSelector(selectors.level3JobTitles)
  const jobTitleCounts = useTypedSelector(selectors.jobTitleCounts)

  const setLevelJobTitleIndex = useCallback(
    (level: LevelType) => {
      switch (level) {
        case 1:
          dispatch(actions.setLevel1JobTitleIndex(cardIndex))
          break
        case 2:
          dispatch(actions.setLevel2JobTitleIndex(cardIndex))
          break
        case 3:
          dispatch(actions.setLevel3JobTitleIndex(cardIndex))
          break
      }
    },
    [dispatch, cardIndex],
  )

  const setSelectedLevelJobTitleIndex = useCallback(
    (level: LevelType) => {
      switch (level) {
        case 1:
          dispatch(actions.setSelectedLevel1JobTitleIndex(0))
          break
        case 2:
          dispatch(actions.setSelectedLevel2JobTitleIndex(0))
          break
        case 3:
          dispatch(actions.setSelectedLevel3JobTitleIndex(0))
          break
      }
    },
    [dispatch],
  )

  const clearLevelJobTitleIndex = useCallback(
    (level: LevelType) => {
      switch (level) {
        case 1:
          dispatch(actions.setLevel1JobTitleIndex(null))
          break
        case 2:
          dispatch(actions.setLevel2JobTitleIndex(null))
          break
        case 3:
          dispatch(actions.setLevel3JobTitleIndex(null))
          break
      }
    },
    [dispatch],
  )

  const handleMouseEnter = useCallback(() => {
    if (title && !isTablet) {
      setShowCard(true)
      setLevelJobTitleIndex(level)
      dispatch(actions.setCurrentLevel(level))

      trackInternalEvent('hover_job_title_on_job_progression', {
        ...Label,
        title: title.target,
      })
    }
  }, [title, isTablet, setLevelJobTitleIndex, level, dispatch])

  const onMouseLeave = useCallback(() => {
    setShowCard(false)
    clearLevelJobTitleIndex(level)
    dispatch(actions.setCurrentLevel(null))
  }, [level, clearLevelJobTitleIndex, dispatch])

  const createCareerPath = useCallback(
    (selectedTitle: string, level?: number) => {
      const data = {
        level,
        title: selectedTitle,
        score: Number(title?.matchScore),
      }
      switch (level) {
        case 1: {
          dispatch(actions.setCareerPath1Level1(data))
          break
        }
        case 2: {
          dispatch(actions.setCareerPath1Level2(data))
          break
        }
        case 3: {
          dispatch(actions.setCareerPath1Level3(data))
          dispatch(actions.setIsCareerPath1Set(true))
          break
        }
      }
    },
    [dispatch, title?.matchScore],
  )

  const sortJobTitles = useCallback(
    (level: number, selectedJobTitle: string, jobTitles: JobProgressionsPath[]) => {
      let levelJobTitleMap: JobProgressionsPath[]
      const index = jobTitles.findIndex(jobTitle => jobTitle?.target === selectedJobTitle)

      if (index > -1) {
        switch (level) {
          case 1: {
            levelJobTitleMap = [level1JobTitles[index]]

            level1JobTitles.forEach((val, mapIndex: number) => {
              if (mapIndex !== index) {
                levelJobTitleMap.push(val)
              }
              return val
            })

            dispatch(actions.setLevel1JobTitles(levelJobTitleMap))
            break
          }
          case 2: {
            levelJobTitleMap = [level2JobTitles[index]]

            level2JobTitles.forEach((val, mapIndex: number) => {
              if (mapIndex !== index) {
                levelJobTitleMap.push(val)
              }
              return val
            })

            dispatch(actions.setLevel2JobTitles(levelJobTitleMap))
            break
          }
          case 3: {
            levelJobTitleMap = [level3JobTitles[index]]

            level3JobTitles.forEach((val, mapIndex: number) => {
              if (mapIndex !== index) {
                levelJobTitleMap.push(val)
              }
              return val
            })
            dispatch(actions.setLevel3JobTitles(levelJobTitleMap))
            break
          }
        }
      }
    },
    [dispatch, level1JobTitles, level2JobTitles, level3JobTitles],
  )

  const addSorting = useCallback(
    (clickedTitle: string) => {
      switch (level) {
        case 1: {
          sortJobTitles(1, clickedTitle, level1JobTitles)
          break
        }
        case 2: {
          sortJobTitles(2, clickedTitle, level2JobTitles)
          break
        }
        case 3: {
          sortJobTitles(3, clickedTitle, level3JobTitles)
          break
        }
      }
    },
    [level, sortJobTitles, level1JobTitles, level2JobTitles, level3JobTitles],
  )

  const clearIndexes = useCallback(
    (level?: number) => {
      if (level) {
        switch (level) {
          case 3: {
            dispatch(actions.setSelectedLevel3JobTitleIndex(null))
            break
          }
          case 2: {
            dispatch(actions.setSelectedLevel2JobTitleIndex(null))
            dispatch(actions.setSelectedLevel3JobTitleIndex(null))
            break
          }
          case 1: {
            dispatch(actions.setSelectedLevel1JobTitleIndex(null))
            dispatch(actions.setSelectedLevel2JobTitleIndex(null))
            dispatch(actions.setSelectedLevel3JobTitleIndex(null))
            break
          }
        }
      }
    },
    [dispatch],
  )

  const handleClick = useCallback(
    (title?: string) => {
      if (title) {
        addSorting(title)
        clearIndexes(level)
        createCareerPath(title, level)

        const fetchJobTitlesCondition = level !== 3

        const jobTitleIndexInCareerPath1 = careerPath1.findIndex(
          JobTitle => JobTitle.title === title && JobTitle.level === level,
        )

        if (jobTitleIndexInCareerPath1 === -1) {
          if (fetchJobTitlesCondition) {
            const params = {
              level,
              query: title,
            }

            dispatch(actions.fetchJobTitles(params))
          }

          setSelectedLevelJobTitleIndex(level)
        }

        trackInternalEvent('click_job_title_on_job_progression', {
          ...Label,
          level,
          title,
        })
      }
    },
    [
      level,
      dispatch,
      addSorting,
      careerPath1,
      clearIndexes,
      createCareerPath,
      setSelectedLevelJobTitleIndex,
    ],
  )

  const getSelected = useCallback(
    (title?: string) => {
      let isSelected = false

      switch (level) {
        case 1:
          isSelected = careerPath1.length > 0 && careerPath1[0].title === title
          break
        case 2:
          isSelected = careerPath1.length > 1 && careerPath1[1].title === title
          break
        case 3:
          isSelected = careerPath1.length > 2 && careerPath1[2].title === title
          break
      }

      return isSelected
    },
    [careerPath1, level],
  )

  const handleResponsiveVersionClick = useCallback(() => {
    const selectedTitle = title?.target
    if (jobTitleCounts.title === selectedTitle && jobTitleCounts.count === 1) {
      setShowCard(false)
      handleClick(title?.target)
      clearLevelJobTitleIndex(level)

      dispatch(actions.setJobTitleCount({ title: selectedTitle, count: 2 }))
    } else if (jobTitleCounts.title === selectedTitle && jobTitleCounts.count === 2) {
      setShowCard(false)
      handleClick(title?.target)
      clearLevelJobTitleIndex(level)

      dispatch(actions.setJobTitleCount({ title: '', count: 0 }))
      clearLevelJobTitleIndex(level)
      clearIndexes(level)
    } else {
      setShowCard(true)
      setLevelJobTitleIndex(level)

      dispatch(actions.setCurrentLevel(level))
      dispatch(actions.setJobTitleCount({ title: selectedTitle, count: 1 }))

      trackInternalEvent('hover_job_title_on_job_progression', {
        ...Label,
        title: title?.target,
      })
    }
  }, [
    level,
    title,
    dispatch,
    handleClick,
    clearIndexes,
    jobTitleCounts,
    setLevelJobTitleIndex,
    clearLevelJobTitleIndex,
  ])

  const handleOnClick = useCallback(() => {
    if (isTablet) {
      handleResponsiveVersionClick()
    } else {
      handleClick(title?.target)
    }
  }, [isTablet, title, handleClick, handleResponsiveVersionClick])

  const getResponsiveIcons = useCallback(() => {
    const { title: jobTitle, count: jobCount } = jobTitleCounts

    return getSelected(title?.target) ? (
      <Tick />
    ) : jobTitle === title?.target && jobCount === 1 ? (
      <AddIcon />
    ) : null
  }, [title, getSelected, jobTitleCounts])

  return (
    <Container
      cardIndex={cardIndex}
      content={title?.target}
      onMouseLeave={onMouseLeave}
      onMouseEnter={handleMouseEnter}
      titleSelected={getSelected(title?.target)}
      onClick={() => title?.target && handleOnClick()}
      clickCount={jobTitleCounts.title === title?.target ? jobTitleCounts.count : -1}
    >
      {title ? (
        <TitleContainer>
          <Title>
            <TitleValue>{title.target}</TitleValue>
            {isTablet ? getResponsiveIcons() : getSelected(title?.target) ? <Tick /> : <AddIcon />}
          </Title>
          {isLaptop ? (
            <SubTitleContainer>
              <TimeIcon src={Time} />
              <AverageTimeSpan
                IsYearOrMonth={
                  title?.timeSpan.includes('years') || title?.timeSpan.includes('months')
                }
              >
                ≈ {title?.timeSpan}
              </AverageTimeSpan>
              <HalfStatus score={title?.matchScore} />
              <SkillScore score={title?.matchScore}>{title?.matchScore}%</SkillScore>
            </SubTitleContainer>
          ) : null}
        </TitleContainer>
      ) : (
        <MoreIcon />
      )}

      {title && showCard && (
        <CardDetails
          isLaptop={isLaptop}
          title={title.target}
          level={Number(level)}
          id={Number(cardIndex)}
          skills={title?.skills}
          skillMatch={Number(title?.matchScore)}
          averageTransitionTime={title?.timeSpan}
        />
      )}
    </Container>
  )
}

export default Card
