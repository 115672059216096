import { all, put, call, takeLatest, takeEvery } from 'redux-saga/effects'
import { apiClient, ApiResponse } from 'builder/modules/apiClient'
import ErrorLogger from 'builder/services/ErrorLogger'
import { EditorConfigApiPayload } from './types'
import { actions } from './generalEditorModule'

function* fetchEditorPayloadSaga() {
  try {
    const url = `/general/editor-payload`
    const response: ApiResponse<EditorConfigApiPayload> = yield call(apiClient.get, url)
    yield put(actions.fetchEditorPayloadSuccess(response.data))
  } catch (error) {
    ErrorLogger.log(error)
  }
}

function* testForceChooseSaga(action: ReturnType<typeof actions.testForceChoose>) {
  try {
    const { experiment, choice } = action.payload
    yield call(apiClient.post, `/tests/${experiment}/choose`, { choice })
  } catch (error) {
    ErrorLogger.log(error)
  }
}

// Export
export const sagas = function* saga() {
  yield all([
    takeEvery(actions.testForceChoose, testForceChooseSaga),
    takeLatest(actions.fetchEditorPayloadRequest, fetchEditorPayloadSaga),
  ])
}
