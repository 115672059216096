import { useCallback, useMemo, memo } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useConfig } from 'builder/hooks/useConfig'
import Icon, { Icon20 } from 'builder/components/Icon'
import { ActionMenu } from 'builder/components/ActionMenu'
import { actions } from 'builder/modules/resumeEditor'
import { useI18n } from 'builder/hooks/useI18n'

type Props = {
  onEditMenuClose: () => void
}

export const EditMenuMobile = memo(({ onEditMenuClose }: Props) => {
  const dispatch = useDispatch()
  const config = useConfig()
  const { i18n } = useI18n()
  const isResumeOptimizerEnabled = config?.features.resumeOptimizer

  const handleEditClick = useCallback(() => {
    dispatch(actions.setisClickedonEditJobDetails(true))
    trackInternalEvent('click_edit_job_title_resume_optimizer')
  }, [dispatch])

  const handleChangeLink = useCallback(() => {
    dispatch(actions.setOpenOptimizerModal(true))
    dispatch(actions.setIsEditJobPosting(true))
  }, [dispatch])

  const handleDeleteClick = useCallback(() => {
    dispatch(actions.setisClickedonDeleteJobPosting(true))
  }, [dispatch])

  const menuActions = useMemo(() => {
    const EditActions = [
      {
        text: i18n.t('builder.resume_optimizer.edit_job_details.edit_job_title'),
        icon: <Icon.Edit />,
        onClick: handleEditClick,
      },
      {
        text: i18n.t('builder.resume_optimizer.edit_job_details.clear_job_title'),
        icon: <Icon20.Trash />,
        onClick: handleDeleteClick,
      },
    ]

    if (isResumeOptimizerEnabled) {
      EditActions.splice(1, 0, {
        text: i18n.t('builder.resume_optimizer.edit_job_details.change_job_link'),
        icon: <Icon.BindLink />,
        onClick: handleChangeLink,
      })
    }

    return EditActions
  }, [isResumeOptimizerEnabled, i18n, handleChangeLink, handleDeleteClick, handleEditClick])

  return <ActionMenu actions={menuActions} onClose={onEditMenuClose} />
})
