import styled from 'styled-components'
import { FetchStatuses } from 'builder/modules/constants'

export const Iframe = styled.iframe.attrs({ frameBorder: 0 })`
  width: 100%;
  height: 100vh;

  &[data-status=${FetchStatuses.loading}] {
    opacity: 0;
    pointer-events: none;
  }
`
