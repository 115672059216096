import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import { Icon20 } from 'builder/components/Icon'
import Typography, { FontWeights } from 'builder/styles/typography'

export const LearnSection = styled.div`
  width: fit-content;
  padding: 2px 8px;
  gap: 4px;
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  color: ${Colors.Neutral50};
  background-color: ${Colors.Neutral10};
`

export const LearnText = styled.div`
  ${Typography.Caption};
  ${FontWeights.Medium};
`

export const ArrowDown = styled(Icon20.ChevronDown)`
  cursor: pointer;
`

export const Wrapper = styled.div`
  width: 100%;
  gap: 16px;
  padding: 32px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${Colors.Neutral5};

  ${Media.Phone`
    gap: 8px;
    padding: 20px;
  `}
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const HeaderTitle = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
`

export const Close = styled(Icon20.Close)`
  width: 28px;
  height: 28px;
  cursor: pointer;
  border-radius: 50%;
  color: ${Colors.Neutral50};
  background-color: ${Colors.White};

  &:hover {
    color: ${Colors.Blue50};
  }
`

export const Container = styled.div`
  gap: 32px;
  display: flex;
  flex-direction: column;
`

export const Iframe = styled.iframe.attrs({ frameBorder: 0 })`
  width: 100%;
  height: 377px;
`

export const SectionWrapper = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const Title = styled.div`
  ${Typography.Subhead};
  ${FontWeights.DemiBold};
  width: 100%;
  color: ${Colors.Neutral90};
`

export const SubTitle = styled.div`
  width: 100%;
  color: ${Colors.Neutral60};

  & > span {
    color: ${Colors.Blue50};

    &:hover {
      cursor: pointer;
    }
  }
`

export const List = styled.ul`
  margin: 8px 0;
  padding-inline-start: 27px;
`

export const ListItem = styled.li``
