import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { memo, useCallback, useEffect, useState } from 'react'
import { useI18n } from 'builder/hooks/useI18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { actions, selectors } from 'builder/modules/resumeEditor'
import { InputChangeEvent } from 'builder/modules/ui'
import { FetchStatuses } from 'builder/modules/constants'
import MobileEditView from './EditJobDetails/MobileEditView'
import NormalEditView from './EditJobDetails/NormalEditView'
import { EditMenuMobile } from './EditMenuMobile'
import EditMenu from './EditMenu'
import DeleteJobPosting from './DeleteJobPosting/DeleteJobPosting'
import { FormState } from './EditJobDetails/types'

type Props = {
  tailoredJobTitle: string
}

const EditView = memo(({ tailoredJobTitle }: Props) => {
  const { i18n } = useI18n()
  const { isPhone } = useMediaQueries()
  const dispatch = useDispatch()

  const isEditJobDetails = useTypedSelector(state => state.resumeEditor.isEditJobDetails)
  const jobPostingLink = useTypedSelector(
    state => state.resumeEditor.optimizerSection.jobPostingAPIData.jobPostingLink,
  )
  const editJobDetailsStatus = useTypedSelector(
    state => state.resumeEditor.optimizerSection.editJobDetailsStatus,
  )
  const jobPostingAPIData = useTypedSelector(
    state => state.resumeEditor.optimizerSection.jobPostingAPIData,
  )
  const resumeOptimizerData = useTypedSelector(selectors.jobPostingAPIData)
  const { recommendedJobTitle, employerName } = resumeOptimizerData

  const [newJobTitle, setNewJobTitle] = useState(recommendedJobTitle)
  const [newEmployerName, setNewEmployerName] = useState(employerName)
  const [formState, setFormState] = useState<FormState>(FormState.NOT_SAVED)
  const [isJobDetailsFocused, setJobDetailsFocus] = useState(false)

  const resumeId = useTypedSelector(state => state.resumeEditor.resume?.id)
  const { jobPostingId } = jobPostingAPIData

  useEffect(() => {
    setNewJobTitle(recommendedJobTitle)
    setNewEmployerName(employerName)
  }, [recommendedJobTitle, employerName])

  useEffect(() => {
    if (editJobDetailsStatus === FetchStatuses.loaded) {
      setFormState(FormState.SAVED)
    } else if (editJobDetailsStatus === FetchStatuses.failed) {
      setFormState(FormState.NOT_SAVED)
    } else {
      setFormState(FormState.SAVE)
    }
  }, [editJobDetailsStatus, i18n])

  const handleDeleteJobPosting = useCallback(() => {
    if (resumeId) {
      dispatch(actions.deleteJobPosting({ jobPostingId, resumeId }))
      trackInternalEvent('click_confirm_delete_job_title_resume_optimizer')
    }
  }, [dispatch, jobPostingId, resumeId])

  const handleEditJobTitleDetails = useCallback(() => {
    if (!resumeId) return
    dispatch(
      actions.editJobDetails({
        jobTitle: newJobTitle || '',
        companyName: newEmployerName || '',
        jobPostingLink,
        resumeId,
        successMessage: i18n.t('builder.resume_optimizer.edit_job_details.success_message'),
      }),
    )
    trackInternalEvent('click_save_job_title_resume_optimizer')
    setJobDetailsFocus(false)
  }, [dispatch, i18n, jobPostingLink, newEmployerName, newJobTitle, resumeId])

  const isClickedOnEditJobDetails = useTypedSelector(
    state => state.resumeEditor.isClickedOnEditJobDetails,
  )
  const isClickedOnDeleteJobPosting = useTypedSelector(
    state => state.resumeEditor.isClickedOnDeleteJobPosting,
  )

  const isClickedOnAiCVBanner = useTypedSelector(state => state.resumeEditor.isClickedOnAiCVBanner)

  const handleCancelJobDetails = () => {
    dispatch(actions.setisClickedonEditJobDetails(false))
  }

  const handleJobDetailsFocus = () => {
    setJobDetailsFocus(true)
  }

  const handleEditMenuClose = () => {
    dispatch(actions.setIsEditJobDetails(false))
  }

  const handleCancelDeleteJobPosting = () => {
    dispatch(actions.setisClickedonDeleteJobPosting(false))
  }
  const handleonClickDeleteJobPosting = () => {
    dispatch(actions.setisClickedonDeleteJobPosting(true))
    trackInternalEvent('click_clear_job_title_resume_optimizer')
  }

  const handleLinkChange = useCallback(() => {
    dispatch(actions.setOpenOptimizerModal(true))
    dispatch(actions.setIsEditJobPosting(true))
    dispatch(actions.setChangeJobPostingLink(true))
    trackInternalEvent('click_change_job_link_resume_optimizer')
  }, [dispatch])

  const handleEditJobDetails = useCallback(() => {
    dispatch(actions.setIsEditJobDetails(true))
  }, [dispatch])

  const handleRefresh = useCallback(() => {
    setNewJobTitle(recommendedJobTitle)
    setNewEmployerName(employerName)
    trackInternalEvent('click_revert_job_title_resume_optimizer')
    setJobDetailsFocus(false)
  }, [employerName, recommendedJobTitle])

  const handleJobTitleChange = (e: InputChangeEvent) => {
    setNewJobTitle(e.target.value)
  }

  const handleCompanyNameChange = (e: InputChangeEvent) => {
    setNewEmployerName(e.target.value)
  }
  const isRefreshEditDetails =
    newJobTitle !== recommendedJobTitle || newEmployerName !== employerName

  const isJobPostingLinked = recommendedJobTitle !== null && recommendedJobTitle !== ''

  const showMobileEditView =
    isClickedOnEditJobDetails && (isPhone || (isClickedOnAiCVBanner && !isJobPostingLinked))

  const handleEditRef = () => {
    if (!isRefreshEditDetails) {
      dispatch(actions.setIsEditJobDetails(false))
    }
  }

  useEffect(() => {
    if (isEditJobDetails && isPhone) {
      trackInternalEvent('click_edit_menu_resume_optimizer')
    } else if (isEditJobDetails) {
      trackInternalEvent('click_edit_job_title_resume_optimizer')
    }
  }, [isEditJobDetails, isPhone])

  const isSaveButtonDisabled = !newJobTitle || !newEmployerName

  return (
    <>
      {isEditJobDetails ? (
        !isPhone ? (
          <NormalEditView
            recommendedJobTitle={newJobTitle || ''}
            employerName={newEmployerName || ''}
            onRefresh={handleRefresh}
            onJobTitleChange={handleJobTitleChange}
            onCompanyNameChange={handleCompanyNameChange}
            formState={formState}
            onSaveJobDetails={handleEditJobTitleDetails}
            isRefresh={isRefreshEditDetails}
            onJobDetailsFocus={handleJobDetailsFocus}
            isJobDetailsFocused={isJobDetailsFocused}
            onClose={handleEditRef}
          />
        ) : (
          <EditMenuMobile onEditMenuClose={handleEditMenuClose} />
        )
      ) : (
        <EditMenu
          tailoredJobTitle={tailoredJobTitle}
          onPencilClick={handleEditJobDetails}
          onLinkChange={handleLinkChange}
          onDelete={handleonClickDeleteJobPosting}
        />
      )}

      {showMobileEditView && (
        <MobileEditView
          isSaveButtonDisabled={isSaveButtonDisabled}
          newJobTitle={newJobTitle || ''}
          newEmployerName={newEmployerName || ''}
          onJobTitleChange={handleJobTitleChange}
          onCompanyNameChange={handleCompanyNameChange}
          onSaveJobDetails={handleEditJobTitleDetails}
          onCancelJobDetails={handleCancelJobDetails}
          onCancelEditJobDetails={handleCancelJobDetails}
        />
      )}
      {isClickedOnDeleteJobPosting && (
        <DeleteJobPosting
          handleDeleteJobPosting={handleDeleteJobPosting}
          handleCancelDeleteJobPosting={handleCancelDeleteJobPosting}
        />
      )}
    </>
  )
})

export default EditView
