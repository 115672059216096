import { isValidElement, useRef, memo } from 'react'
import * as React from 'react'
import { TabsContainer, Tab } from './styles'

type Child = React.ReactElement<React.ComponentProps<typeof Tab>>

interface TabsProps extends React.HTMLAttributes<HTMLDivElement> {
  children: Child[]
}

export const PillTabs = memo(({ children, ...rest }: TabsProps) => {
  const wrapper = useRef<HTMLDivElement | null>(null)
  const activeTab = useRef<HTMLDivElement | null>(null)

  const tabs = React.Children.map(children, (child: Child) => {
    if (!isValidElement(child) || !child.props.isActive) return child

    return React.cloneElement(child, { ...child.props, ref: activeTab })
  })

  return (
    <TabsContainer ref={wrapper} {...rest}>
      {tabs}
    </TabsContainer>
  )
})
