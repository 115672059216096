/* eslint-disable @typescript-eslint/ban-ts-comment */
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Transition } from 'react-transition-group'
import { FieldNote } from 'builder/components/FieldNote'

import { TextFieldProps as Props, TagNames, Element } from './types'
import * as Styled from './styles'

export const TextFieldComponent = <T extends TagNames = 'input'>(
  {
    className,
    active: isActive,
    label,
    hint,
    tagName,
    inputTheme = 'default',
    inputIcon = null,
    inputIconPosition = 'left',
    error,
    errorNoMsg = false,
    warning,
    description,
    onFocus = () => {},
    onBlur = () => {},
    required,
    badge,
    ...rest
  }: Props<T>,
  ref: React.ForwardedRef<Element<T>>,
) => {
  const [isFocused, toggleFocus] = useState(false)
  const inputRef = useRef<Element<T> | null>(null)
  const hasError = !!error || errorNoMsg
  const hasWarning = !!warning
  const isBarVisible = isFocused || isActive || hasError || hasWarning

  // Expose the input node to make it possible to call methods like `.focus()`.
  // Used by react-autocomplete for instance.
  // See https://reactjs.org/docs/hooks-reference.html#useimperativehandle
  useImperativeHandle(ref, () => inputRef.current as Element<T>)

  const handleFocus = useCallback(
    (event: React.FocusEvent<Element<T>>) => {
      // @ts-expect-error
      onFocus(event)
      toggleFocus(true)
    },
    [onFocus],
  )

  const handleBlur = useCallback(
    (event: React.FocusEvent<Element<T>>) => {
      // @ts-expect-error
      onBlur(event)
      toggleFocus(false)
    },
    [onBlur],
  )

  // Fix react-autocomplete bug (input focused without event call)
  useEffect(() => {
    if (!isFocused && inputRef.current === document.activeElement) {
      inputRef.current?.focus()
    }
  }, [isFocused])

  return (
    <Styled.Group className={className}>
      {label && (
        <Styled.LabelContainer>
          <Styled.Label
            hasError={hasError}
            style={{ alignItems: 'center', justifyContent: 'space-between' }}
          >
            <span style={{ alignItems: 'center' }}>
              <Styled.LabelContent>{label}</Styled.LabelContent>
              {required && <Styled.Required> *</Styled.Required>}
              {hint && !badge && <Styled.LabelHint multiline>{hint}</Styled.LabelHint>}
            </span>
          </Styled.Label>
          {badge}
        </Styled.LabelContainer>
      )}

      <Styled.InputWrapper>
        {/* @ts-expect-error */}
        <Styled.Input
          {...rest}
          as={tagName ?? 'input'}
          ref={inputRef}
          onFocus={handleFocus}
          onBlur={handleBlur}
          inputTheme={inputTheme}
          iconPosition={inputIcon === null ? undefined : inputIconPosition}
        />

        {inputIcon && (
          <Styled.InputIconWrapper iconPosition={inputIconPosition}>
            {inputIcon}
          </Styled.InputIconWrapper>
        )}

        <Transition in={isBarVisible} timeout={100}>
          {state => <Styled.Bar in={state} hasError={hasError} hasWarning={hasWarning} />}
        </Transition>
      </Styled.InputWrapper>

      <FieldNote error={error} warning={warning} description={description} />
    </Styled.Group>
  )
}

export const TextField = forwardRef<Element<'input'>, Props<'input'>>(TextFieldComponent)

export const TextAreaField = forwardRef<Element<'textarea'>, Props<'textarea'>>(TextFieldComponent)

TextAreaField.defaultProps = {
  tagName: 'textarea',
}
