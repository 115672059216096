import Icon24 from 'builder/components/Icon'
import { TooltipV2 } from 'builder/components/Tooltip'
import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { DocumentButton, DocumentButtonIcon } from '../styles'
import { Badge, BadgeIconWrapper, BadgeIconDefault, BadgeIconHovered } from './styles'

interface Props {
  onClick: (e: React.MouseEvent) => void
  isDisabled?: boolean
}

export const AutoApply = (props: Props) => {
  const { isDisabled, onClick } = props
  const { i18n } = useI18n()
  const { isPhone } = useMediaQueries()
  return (
    <DocumentButton onClick={onClick} isDisabled={isDisabled}>
      <DocumentButtonIcon as={Icon24.IconSend} />
      {i18n.t('builder.auto_tailoring.dashboard.auto_apply')}
      <TooltipV2
        multiline
        value={i18n.t('builder.auto_tailoring.dashboard.ready_tooltip')}
        width={240}
      >
        {isPhone ? (
          <Badge>{i18n.t('builder.auto_tailoring.dashboard.ready_mobile')}</Badge>
        ) : (
          <Badge>
            {i18n.t('builder.auto_tailoring.dashboard.ready')}
            <BadgeIconWrapper>
              <BadgeIconDefault />
              <BadgeIconHovered />
            </BadgeIconWrapper>
          </Badge>
        )}
      </TooltipV2>
    </DocumentButton>
  )
}
