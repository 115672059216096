import styled from 'styled-components'

import { Badge } from 'builder/components/Badge'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const CustomBadge = styled(Badge)`
  display: inline-flex;
  padding: 4px 16px;
  border-radius: 40px;
  height: 32px;
  align-items: center;
  width: fit-content;
  color: ${Colors.Indigo70};
  ${FontWeights.DemiBold};
  ${Typography.Body};
`
