import { memo } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { actions } from 'builder/modules/resumeEditor'
import { i18n as I18n } from 'builder/utils/i18n'
import { useAppSelector } from 'builder/hooks/useAppSelector'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { UseExampleEvents } from 'builder/modules/constants'
import UseExampleImg from '../images/use-example.png'
import UseExampleImgTab from '../images/use-example-tablet.png'
import UseExampleImgMobile from '../images/use-example-mobile.png'
import SpinnerImg from '../images/spinner.png'
import {
  Card,
  CardImgContainer,
  UseExampleCardImg,
  CardDescription,
  CardTitle,
  CardSubTitle,
  LoadingCard,
  Spinner,
} from './styles'

type Props = {
  onClose: () => void
}

const UseExample = (props: Props) => {
  const { onClose } = props

  const { resumeExamples, fetchingResumeExamples } = useAppSelector(
    store => store.resumeEditor.useExample,
  )
  const dispatch = useDispatch()
  const { isPhone, isTablet } = useMediaQueries()

  const handleCardClick = () => {
    trackInternalEvent(UseExampleEvents.clickUseAnExampleStartScreen)
    if (resumeExamples.length) {
      dispatch(actions.setShowUseExamplePopup(true))
      onClose()
    } else if (!fetchingResumeExamples) {
      dispatch(actions.fetchResumeExamples())
    }
  }

  const getImgSrc = () => {
    if (isPhone) {
      return UseExampleImgMobile
    }
    if (isTablet) {
      return UseExampleImgTab
    }

    return UseExampleImg
  }

  if (fetchingResumeExamples) {
    return (
      <LoadingCard>
        <Spinner src={SpinnerImg} />
      </LoadingCard>
    )
  }

  return (
    <Card onClick={handleCardClick}>
      <CardImgContainer>
        <UseExampleCardImg src={getImgSrc()} />
      </CardImgContainer>
      <CardDescription>
        <CardTitle>
          {I18n.t('builder.prefill_resume.options_view.options.use_example.title')}
        </CardTitle>
        <CardSubTitle>
          {I18n.t('builder.prefill_resume.options_view.options.use_example.description')}
        </CardSubTitle>
      </CardDescription>
    </Card>
  )
}

export default memo(UseExample)
