import { memo, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import useDebouncedEffect from 'use-debounced-effect'
import { i18n as I18n } from 'builder/utils/i18n'
import { TextField } from 'builder/components/TextField'
import { useAppSelector } from 'builder/hooks/useAppSelector'
import { QuestionnaireData, actions } from 'builder/modules/resumeEditor'
import {
  AIResumeDraftEventLabels,
  AIResumeDraftEvents,
  AIResumeFlows,
  AIResumeWarningModalTypes,
  FetchStatuses,
} from 'builder/modules/constants'
import { isWorkExpCardEmpty } from '../QuestionnaireFlow/WorkExperience/WorkExperienceStep'
import { isEducationCardEmpty } from '../QuestionnaireFlow/Education/Education'
import {
  Heading,
  SubHeading,
  DesktopButtonContainer,
  MobileButtonContainer,
  CancelButton,
  ContinueButton,
  TextFieldContainer,
  ValidationLoadingIcon,
  ValidationSuccessIcon,
  ValidationFailIcon,
  SkipButton,
  FlexBox,
  LoadingIcon,
} from './styles'
import { linkedInProfileRegex } from './constants'

const getStatusIcon = (status: FetchStatuses | null): JSX.Element => {
  switch (status) {
    case FetchStatuses.loading:
      return <ValidationLoadingIcon />
    case FetchStatuses.loaded:
      return <ValidationSuccessIcon />
    case FetchStatuses.failed:
      return <ValidationFailIcon />
    default:
      return <></>
  }
}

export const showDataLossWarning = (
  userInput: QuestionnaireData['userInput'],
  linkedInProfileURL: string,
) => {
  const {
    workExperience,
    desiredPosition,
    education,
    currentSkills,
    pastAccomplishments,
    futureGoals,
  } = userInput

  if (
    linkedInProfileURL ||
    desiredPosition ||
    currentSkills.length ||
    pastAccomplishments ||
    futureGoals
  ) {
    return true
  }

  for (const card of workExperience.cards) {
    if (!isWorkExpCardEmpty(card)) {
      return true
    }
  }

  for (const card of education.cards) {
    if (!isEducationCardEmpty(card)) {
      return true
    }
  }

  return false
}

const LinkedInPrefill = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    trackInternalEvent(AIResumeDraftEvents.seeLinkedInPrefillscreen, {
      label: AIResumeDraftEventLabels.aiResume,
    })
  }, [])

  const {
    linkedInData: { linkedInProfileURL, fetchingLinkedInProfile },
    questionnaireData: { userInput },
  } = useAppSelector(store => store.resumeEditor.aiResume)

  const isLinkValid = linkedInProfileRegex.test(linkedInProfileURL.toLowerCase())

  const [linkValidationStatus, setLinkValidationStatus] = useState<FetchStatuses>(
    FetchStatuses.notAsked,
  )

  const [error, setError] = useState<string | null>(null)

  useDebouncedEffect(
    () => {
      let eventStatus = ''
      if (linkedInProfileURL) {
        if (isLinkValid) {
          setLinkValidationStatus(FetchStatuses.loaded)
          setError(null)
          eventStatus = 'ok'
        } else {
          setLinkValidationStatus(FetchStatuses.failed)
          setError(I18n.t('builder.ai_resume_draft.linkedin.link_incorrect'))
          eventStatus = 'failed'
        }
        trackInternalEvent(AIResumeDraftEvents.provideLinkedInLink, {
          link: linkedInProfileURL,
          status: eventStatus,
          label: AIResumeDraftEventLabels.linkedInPrefillScreen,
        })
      }
    },
    1000,
    [linkedInProfileURL],
  )

  useEffect(() => {
    if (
      !linkedInProfileURL &&
      error === I18n.t('builder.ai_resume_draft.linkedin.link_incorrect')
    ) {
      setError(null)
    }
  }, [linkedInProfileURL, error])

  const handleChange = (event: { target: { value: string } }) => {
    const { value } = event.target
    dispatch(actions.setLinkedInProfileURL(value))
    if (!value) {
      setLinkValidationStatus(FetchStatuses.notAsked)
      return
    }

    if (linkValidationStatus !== FetchStatuses.loading) {
      setLinkValidationStatus(FetchStatuses.loading)
    }
  }

  const onContinue = () => {
    trackInternalEvent(AIResumeDraftEvents.clickContinue, {
      label: AIResumeDraftEventLabels.linkedInPrefillScreen,
    })
    if (error) {
      return
    }

    if (linkedInProfileURL === '') {
      setError(I18n.t('builder.ai_resume_draft.linkedin.link_empty'))
    } else {
      let updatedUrl = linkedInProfileURL.toLowerCase()
      // If protocol was not provided, add it.
      updatedUrl =
        updatedUrl.startsWith('https') || updatedUrl.startsWith('http')
          ? updatedUrl
          : `https://${updatedUrl}`
      // The regular expression doesn't match the URL if it doesn't start with www.
      // So, we need to add www. to the URL if it doesn't have it.
      updatedUrl = updatedUrl.replace(/:\/\/(?!www\.)/, '://www.')
      dispatch(actions.fetchLinkedInProfile({ linkedInProfileURL: updatedUrl }))
    }
  }

  const onBack = () => {
    if (showDataLossWarning(userInput, linkedInProfileURL)) {
      dispatch(
        actions.setShowAIResumeWarningModal({
          showModal: true,
          type: AIResumeWarningModalTypes.dataLost,
        }),
      )
    } else {
      dispatch(actions.setShowAIResumeModal(false))
      dispatch(actions.setShowPrefillResumeModal(true))
    }
  }

  const onSkip = () => {
    trackInternalEvent(AIResumeDraftEvents.clickSkip, {
      label: AIResumeDraftEventLabels.linkedInPrefillScreen,
    })
    dispatch(actions.setAIResumeFlow(AIResumeFlows.Questionnaire))
  }

  const renderContinueButtonStatus = () => {
    if (!fetchingLinkedInProfile) return I18n.t('builder.ai_resume_draft.continue')

    return <LoadingIcon />
  }

  const showSkipButton = !isLinkValid

  return (
    <>
      <Heading>{I18n.t('builder.ai_resume_draft.linkedin.title')}</Heading>
      <SubHeading>{I18n.t('builder.ai_resume_draft.linkedin.description')}</SubHeading>
      <TextFieldContainer>
        <TextField
          value={linkedInProfileURL}
          name="linkedInURL"
          placeholder={I18n.t('builder.ai_resume_draft.linkedin.paste_link')}
          onChange={handleChange}
          inputIcon={getStatusIcon(linkValidationStatus)}
          inputIconPosition="right"
          error={error}
        />
      </TextFieldContainer>

      <DesktopButtonContainer>
        <CancelButton theme="ghost" onClick={onBack}>
          {I18n.t('builder.ai_resume_draft.back')}
        </CancelButton>
        <FlexBox>
          {showSkipButton && (
            <SkipButton theme="ghost" onClick={onSkip}>
              {I18n.t('builder.ai_resume_draft.skip')}
            </SkipButton>
          )}
          <ContinueButton onClick={onContinue} isDisabled={fetchingLinkedInProfile}>
            {renderContinueButtonStatus()}
          </ContinueButton>
        </FlexBox>
      </DesktopButtonContainer>

      <MobileButtonContainer>
        <ContinueButton onClick={onContinue} isDisabled={fetchingLinkedInProfile}>
          {renderContinueButtonStatus()}
        </ContinueButton>
        {showSkipButton && (
          <SkipButton theme="ghost" onClick={onSkip}>
            {I18n.t('builder.ai_resume_draft.skip')}
          </SkipButton>
        )}
      </MobileButtonContainer>
    </>
  )
}

export default memo(LinkedInPrefill)
