import { useEffect, useState } from 'react'
import { EventCode, trackInternalEvent } from '@rio/tracking'
import CIO_LOGO_URL from 'images/countries/cio-logo.svg'
import RIO_LOGO_URL from 'images/countries/rio-logo.svg'

import OnboardSteps from './components/Steps/Steps'
import { OnboardStepsAction } from './types'
import { Anchor, LayoutContainer, Logo, Header } from './styles'

const AiEnhanceOnboard = () => {
  const hostName = window.location.hostname
  const [activeStep, setActiveSteps] = useState<number>(+OnboardStepsAction.STEP_1)

  useEffect(() => {
    trackInternalEvent(`ai_refresh_promo_${activeStep}` as EventCode)
  }, [activeStep])

  return (
    <>
      <Header>
        <Anchor href="/">
          <Logo
            src={hostName.includes('career.io') ? CIO_LOGO_URL : RIO_LOGO_URL}
            data-testid="cio-logo-icon"
            alt={`${hostName} logo`}
          />
        </Anchor>
      </Header>
      <LayoutContainer>
        <OnboardSteps activeStep={activeStep} setStep={setActiveSteps} />
      </LayoutContainer>
    </>
  )
}

export default AiEnhanceOnboard
