import { takeEvery, takeLatest, delay, select, all, call, put } from 'redux-saga/effects'
import { trackInternalEvent } from '@rio/tracking'
import { EnumerableResumeSectionType, Resume, Card } from '@rio/types'
import { isEqualWith, merge } from 'lodash'
import { selectors } from 'builder/modules/resumeEditor/resumeEditorSelectors'
import { selectors as initSelectors } from 'builder/modules/init'
import { convertHtmlToText } from 'builder/utils/convertHtmlToText'
import { actions, ResumeEventTrackedSectionsType } from 'builder/modules/resumeEditor'
import jwtService from 'builder/services/JwtService'
import { camelToSnake } from 'builder/utils/camelToSnake'
import { isCardEmpty } from './resumeSectionCardTrackRules'

const OTHER_SECTIONS_ANALYTICS_LABEL = 'other'
const PROFILE_SUMMARY_ANALYTICS_LABEL = 'profile'
const POSITION_ANALYTICS_LABEL = 'job_title'

/*
  Sending internal events of resume updates observing
 */

// Object for store resume data between updates
let persistedResumes: Record<string, Resume> = {}

export function persistValues(resume: Resume) {
  persistedResumes[resume.id] = resume
}

function trackResumeChange(resume: Resume, resumeEventTrackedSections: string[]) {
  const oldResume = persistedResumes[resume.id]

  if (!oldResume) return false

  const trackingSectionList = [
    'courses',
    'educations',
    'languages',
    'skills',
    'workExperiences',
    'socialProfiles',
    'activities',
    'hobbies',
    'internships',
    'references',
    'customSections',
  ] as EnumerableResumeSectionType[]

  const eventTrackingOtherSectionList = [
    'activities',
    'hobbies',
    'internships',
    'references',
    'customSections',
  ] as EnumerableResumeSectionType[]

  function isEqualExcludingPosition(obj1: Card, obj2: Card) {
    return isEqualWith(obj1, obj2, (val1, val2, key) => {
      if (key === 'position' || key === 'updatedAt' || key === 'employerProfilesAttributes')
        return true
      return undefined
    })
  }

  // Other Section Check
  const eventSectionName = (section: EnumerableResumeSectionType) => {
    if (eventTrackingOtherSectionList.includes(section)) {
      return OTHER_SECTIONS_ANALYTICS_LABEL
    } else {
      return camelToSnake(section)
    }
  }

  const updatedSections = new Set(resumeEventTrackedSections[resume.id])

  // Profile Summary Check
  const newProfileSummary = convertHtmlToText(resume.profile || '')
  const oldProfileSummary = convertHtmlToText(oldResume.profile || '')

  if (newProfileSummary !== oldProfileSummary) {
    updatedSections.add(PROFILE_SUMMARY_ANALYTICS_LABEL)
  }

  // Job Title Check
  const newJobTitle = resume.position || ''
  const oldJobTitle = oldResume.position || ''

  if (newJobTitle !== oldJobTitle) {
    updatedSections.add(POSITION_ANALYTICS_LABEL)
  }

  // Sections Check
  trackingSectionList.forEach(section => {
    const newCards: Card[] = resume[section] || []
    const oldCards: Card[] = oldResume[section] || []

    newCards.forEach(newCard => {
      const oldCard = oldCards.find((card: Card) => card.id === newCard.id)

      if (!oldCard && !isCardEmpty(newCard, section)) {
        updatedSections.add(eventSectionName(section))
      } else if (oldCard && !isEqualExcludingPosition(newCard, oldCard)) {
        updatedSections.add(eventSectionName(section))
      } else {
        persistValues(resume)
      }
    })

    oldCards.forEach(oldCard => {
      if (!newCards.some((newCard: Card) => newCard.id === oldCard.id)) {
        updatedSections.add(eventSectionName(section))
      }
    })
  })

  return Array.from(updatedSections)
}

function* fetchResumeSuccessSaga(action: ReturnType<typeof actions.fetchResumeSuccess>) {
  // persist fetched resume for change fields tracking
  if (action.payload) {
    persistValues(action.payload)
  }
}

function* trackResumeChangeSaga() {
  // Select the resume data and the tracked sections state
  const resume: Resume = yield select(selectors.resume)
  const resumeId = resume.id

  const resumeEventTrackedSections: ResumeEventTrackedSectionsType = yield select(
    selectors.resumeEventTrackedSections,
  )

  // Track changes in resume sections
  const updatedTrackedSections = trackResumeChange(
    resume,
    resumeEventTrackedSections[resumeId] || [],
  )

  if (updatedTrackedSections) {
    const newSectionsToTrack = updatedTrackedSections.filter(
      section => !resumeEventTrackedSections[resumeId]?.includes(section),
    )

    if (newSectionsToTrack.length) {
      newSectionsToTrack.forEach(section => {
        trackInternalEvent('change_resume_field_content', {
          section,
          resume_id: resumeId,
        })
      })

      // Merge the new sections into the existing tracked sections for the resume
      const mergedSections = merge({}, resumeEventTrackedSections, {
        [resumeId]: [...(resumeEventTrackedSections[resumeId] || []), ...newSectionsToTrack],
      })

      // Persist the updated resume and dispatch updated sections to the state
      persistValues(resume)
      yield put(actions.setResumeEventTrackedSections(mergedSections))
    }
  }
}

/*
  Saga sends description and skills to job recommendation API
*/
function* updateJobRecommendationsSaga(): Generator<object, void, Resume> {
  const superApp = yield select(initSelectors.feature, 'superApp')
  if (!superApp) return
  const resume = yield select(selectors.resume)
  const { profile, skills } = resume
  try {
    yield call(jwtService.client.post, `${process.env.JOB_REC_API_BASE}/profile`, {
      description: profile,
      skills: skills.map(({ skill }) => skill),
    })
  } catch {}
}

function* delayedUpdateJobRecommendations() {
  yield delay(60 * 1000) // Delay of 60 seconds
  yield updateJobRecommendationsSaga()
}

export const trackingSagas = function* trackingSaga() {
  yield all([
    takeEvery(actions.fetchResumeSuccess, fetchResumeSuccessSaga),
    takeLatest(actions.updateResumeSuccess, trackResumeChangeSaga),
    takeLatest(actions.updateResumeSuccess, delayedUpdateJobRecommendations),
  ])
}
