import { HTMLAttributes } from 'react'
import { ItemHeader } from './styles'

type ListItemHeaderProps = {
  text: string
} & HTMLAttributes<HTMLDivElement>

export const ListItemHeader = ({ text, ...rest }: ListItemHeaderProps) => {
  return <ItemHeader {...rest}>{text}</ItemHeader>
}
