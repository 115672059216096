import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import {
  Categories,
  CategoriesList,
  CategoriesItemSkeleton,
  CategoriesTitle,
  Content,
  ExampleItem,
  ExamplePreviewSkeleton,
  ExampleRating,
  ExamplesGroupContainer,
  ExamplesGroupCountSkeleton,
  ExamplesGroupHeader,
  ExamplesGroupTitleSkeleton,
  ExamplesContainer,
  ExamplesList,
  Star,
} from './styles'

const SkeletonItem = () => (
  <ExampleItem>
    <ExamplePreviewSkeleton />
    <ExampleRating>
      <Star disabled />
      <Star disabled />
      <Star disabled />
      <Star disabled />
      <Star disabled />
    </ExampleRating>
  </ExampleItem>
)

const Skeleton = () => {
  const { i18n } = useI18n()
  const { isPhone } = useMediaQueries()

  return (
    <Content>
      {!isPhone && (
        <Categories>
          <CategoriesList>
            <CategoriesTitle>{i18n.t('builder.prefill.category')}</CategoriesTitle>
            <CategoriesItemSkeleton width={90} />
            <CategoriesItemSkeleton width={70} />
            <CategoriesItemSkeleton width={85} />
            <CategoriesItemSkeleton width={60} />
            <CategoriesItemSkeleton width={70} />
          </CategoriesList>
        </Categories>
      )}

      <ExamplesContainer>
        <ExamplesList>
          <ExamplesGroupContainer>
            <ExamplesGroupHeader>
              <ExamplesGroupTitleSkeleton />
              <ExamplesGroupCountSkeleton />
            </ExamplesGroupHeader>

            <SkeletonItem />
            <SkeletonItem />
            <SkeletonItem />
            <SkeletonItem />
            <SkeletonItem />
            <SkeletonItem />
          </ExamplesGroupContainer>
        </ExamplesList>
      </ExamplesContainer>
    </Content>
  )
}

export default Skeleton
