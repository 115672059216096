import Avatar from 'builder/components/Avatar'
import { AvatarWrapper } from './styles'

interface ListItemAvatarProps {
  src: string
  alt?: string
  size?: number
  color?: string
}

export const ListItemAvatar = ({ src, alt, size, color }: ListItemAvatarProps) => {
  return (
    <AvatarWrapper>
      <Avatar src={src} alt={alt} size={size} color={color} />
    </AvatarWrapper>
  )
}
