import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as panelSelectors, actions as PanelActions } from 'builder/modules/panel'
import {
  actions as critiqueActions,
  ResumeCritiqueStatuses,
  selectors as critiqueSelectors,
} from 'builder/modules/resumeCritiqueReview'
import {
  LoadingScreen1,
  LoadingScreen2,
  LoadingScreen3,
  Footer,
} from 'builder/components/LoadingScreen'
import { Container } from './styles'

const UpsiderLoadingPlatform = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  dispatch(PanelActions.fetchDocuments())
  const tunerResumeId = useTypedSelector(panelSelectors.tunerResumeId)
  const checksum = localStorage.getItem('IS_CRITIQUE_CHECKSUM')

  useEffect(() => {
    if (tunerResumeId !== null) {
      dispatch(
        critiqueActions.fetchCioCritiques({
          resumeId: tunerResumeId,
          checkSum: checksum,
        }),
      )
    }
  }, [checksum, dispatch, tunerResumeId])
  const CritiqueResumeReviewData = useTypedSelector(critiqueSelectors.triggeredCritiqueStatus)

  useEffect(() => {
    let critiqueInterval: NodeJS.Timeout
    critiqueInterval = setInterval(() => {
      if (CritiqueResumeReviewData?.status === ResumeCritiqueStatuses.pending && tunerResumeId) {
        dispatch(critiqueActions.fetchCritiqueReviewStatus({ resumeId: tunerResumeId }))
      } else {
        clearInterval(critiqueInterval)
      }
    }, 30000)
    return () => clearInterval(critiqueInterval)
  }, [CritiqueResumeReviewData?.status, dispatch, tunerResumeId])

  const [showLoadingScreen2, setShowLoadingScreen2] = useState(false)

  useEffect(() => {
    if (
      tunerResumeId !== null &&
      CritiqueResumeReviewData?.status === ResumeCritiqueStatuses.pending
    ) {
      const timeoutId = setTimeout(() => {
        setShowLoadingScreen2(true)
      }, 5000)
      return () => clearTimeout(timeoutId)
    }
  }, [tunerResumeId, CritiqueResumeReviewData])

  const renderScreen = () => {
    if (
      tunerResumeId !== null &&
      CritiqueResumeReviewData?.status === ResumeCritiqueStatuses.pending
    ) {
      return showLoadingScreen2 ? <LoadingScreen2 /> : <LoadingScreen1 />
    } else if (CritiqueResumeReviewData?.status === ResumeCritiqueStatuses.finished) {
      setTimeout(() => {
        navigate(`/resumes/${tunerResumeId}/critique?lowLeads=1&v2=1`)
      }, 5000)
      return <LoadingScreen3 />
    } else {
      return <div>Something went wrong</div>
    }
  }

  return <>{renderScreen()}</>
}

export const UpsiderPlatformV2 = () => {
  return (
    <>
      <UpsiderLoadingPlatform />
      <Container>
        <Footer />
      </Container>
    </>
  )
}
