import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors, actions } from 'builder/modules/careerProfile'
import { FetchStatuses } from 'builder/modules/constants'
import { useIsCareerProfileAvailable } from './useIsCareerProfileAvailable'

export const useCareerProfileFeaturesInit = (isResumeEditorPage: boolean) => {
  const dispatch = useDispatch()
  const fetchCareerInsightsStatus = useTypedSelector(selectors.fetchCareerInsightsStatus)
  const isCareerProfileCompletenessFetchNeeded = useTypedSelector(
    selectors.isCompletenessFetchNeeded,
  )
  const isAvailable = useIsCareerProfileAvailable()
  const shouldInit = isAvailable && !isResumeEditorPage

  useEffect(() => {
    if (shouldInit && fetchCareerInsightsStatus === FetchStatuses.notAsked) {
      dispatch(actions.startCareerInsightsPolling())
    }
  }, [shouldInit, fetchCareerInsightsStatus, dispatch])

  useEffect(() => {
    if (shouldInit && isCareerProfileCompletenessFetchNeeded) {
      dispatch(actions.fetchCompleteness())
    }
  }, [shouldInit, isCareerProfileCompletenessFetchNeeded, dispatch])
}
