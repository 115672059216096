import EditorField from 'builder/components/EditorField'
import { Select } from 'builder/components/Select'
import { useAppSelector } from 'builder/hooks/useAppSelector'
import { useI18n } from 'builder/hooks/useI18n'
import { useWorkExperienceCardContext } from '../../ExpandableCardContext'

// TODO: use from translations when yes and no values are added in translations
const getStockListingOptions = (locale: string) => {
  if (locale === 'ja-JP') {
    return [
      { id: 'Yes', name: 'はい' },
      { id: 'No', name: 'いいえ' },
    ]
  }

  return [
    { id: 'Yes', name: 'Yes' },
    { id: 'No', name: 'No' },
  ]
}

const StockListings = (): JSX.Element => {
  const {
    item: { employerProfilesAttributes },
    onCardValueChange,
  } = useWorkExperienceCardContext()
  const resume = useAppSelector(store => store.resumeEditor.resume)
  const { i18n } = useI18n()
  const label = i18n.t(
    'builder.resume_editor.shokumukeirekisho.work_experience.employer_details.stock_listing',
  )

  const handleStockListingSelect = (value: string) => {
    onCardValueChange(
      { employerProfilesAttributes: { ...employerProfilesAttributes, stockListing: value } },
      false,
    )
  }

  return (
    <EditorField>
      <Select
        label={label}
        selected={employerProfilesAttributes?.stockListing || ''}
        options={getStockListingOptions(resume?.locale || '')}
        onSelect={handleStockListingSelect}
      />
    </EditorField>
  )
}
export default StockListings
