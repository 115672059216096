import { Transition } from 'react-transition-group'
import { useI18n } from 'builder/hooks/useI18n'
import Icon from 'builder/components/Icon'
import Colors from 'builder/styles/colors'
import { clampNumber } from 'builder/utils/clampNumber'

import * as Styles from './styles'

interface BarProps {
  textLength: number
  recommendedLength?: number
  isFocused?: boolean
}

type BarPropsHelper = Pick<BarProps, 'recommendedLength' | 'textLength'>

const barColor = ({ textLength, recommendedLength }: BarPropsHelper) => {
  if (!recommendedLength) return Colors.Blue50

  if (textLength === 0) return Colors.Blue50
  if (textLength / recommendedLength >= 1) return Colors.Green50
  if (textLength / recommendedLength > 0.33) return Colors.Amber40

  return Colors.Orange40
}

const barScale = ({ textLength, recommendedLength }: BarPropsHelper) => {
  if (textLength && recommendedLength) {
    return clampNumber(textLength / recommendedLength, 0, 1)
  }

  return 1
}

export const ProgressBar = ({ isFocused, textLength, recommendedLength }: BarProps) => {
  return (
    <Transition in={isFocused} timeout={100}>
      {state => (
        <Styles.Bar
          in={state}
          scale={barScale({ textLength, recommendedLength })}
          bgColor={barColor({ textLength, recommendedLength })}
        />
      )}
    </Transition>
  )
}

interface ProgressProps {
  textLength: number
  recommendedLength: number
}

const FaceIcon = ({ textLength, recommendedLength }: ProgressProps) => {
  if (textLength >= recommendedLength) return <Icon.FaceGood />
  if (textLength > recommendedLength / 3) return <Icon.FaceNormal />
  return <Icon.FaceBad />
}

export const ProgressStatus = (props: ProgressProps) => {
  const { textLength, recommendedLength } = props
  const { i18n } = useI18n()

  return (
    <Styles.Container>
      <div>
        {i18n.t('builder.resume_editor.phrase_suggestion.progress_label', {
          count: recommendedLength,
        })}
      </div>

      <Styles.Info isIdle={textLength === 0}>
        <Styles.Counter>
          <Styles.Count>{textLength}</Styles.Count>
          {textLength < recommendedLength && <span>&nbsp;/&nbsp;{recommendedLength}+</span>}
        </Styles.Counter>

        <Styles.Face isVisible={textLength > 0}>
          <FaceIcon {...props} />
        </Styles.Face>
      </Styles.Info>
    </Styles.Container>
  )
}
