import { BlueStep, StepsCounter, GrayStep } from './styles'

type Props = {
  totalStep: number
  currentStep: number
}
export const ProcessIndicator = (props: Props) => {
  const { totalStep, currentStep } = props
  return (
    <StepsCounter>
      {Array.from({ length: totalStep }, (_, i) => i).map(s =>
        s === currentStep ? <BlueStep key={s} /> : <GrayStep key={s} />,
      )}
    </StepsCounter>
  )
}
