import styled, { css } from 'styled-components'
import Media from 'builder/styles/media'
import Button from 'builder/components/Button'
import Colors from 'builder/styles/colors'

export const Container = styled.div<{ page: number }>`
  max-width: 416px;
  width: 100%;
  margin: 0 auto;
  padding: ${({ page }) => (page === 0 ? '40px 0' : '40px 0 200px 0')};
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${Media.Phone`
    max-width: 100%;
    margin: 0;
  `};

  ${({ page }) => {
    return Media.Phone`
     padding: ${() => (page === 0 ? '24px 20px 0 20px' : '24px 20px 200px 20px')};
    `
  }}
`

export const FooterContainer = styled.div<{ isLocalization?: boolean }>`
  width: 416px;
  position: fixed;
  bottom: 0;
  background-color: ${Colors.White};
  padding: 20px 0 20px 0;

  ${Media.Phone`
    left: 0;
    bottom: 70px;
    width: 100%;
    padding-bottom: 11px;
    padding: 20px;
  `};

  ${({ isLocalization }) =>
    isLocalization &&
    css`
      ${Media.Phone`
          bottom: 0;
        `};
    `}
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;

  ${Media.Phone`
    margin-bottom: 28px;
  `};
`

export const RightActionContainer = styled.div`
  display: flex;
`

export const SkipButton = styled(Button)`
  margin-right: 8px;
  box-shadow: none;

  &:hover {
    box-shadow: none;
  }
`
