import { ReactNode } from 'react'
import { Container } from './Styles'

interface Props {
  children: ReactNode
  fullWidth?: boolean
  quarterWidth?: boolean
}

const EditorField = (props: Props) => {
  const { fullWidth, quarterWidth, children, ...rest } = props
  return (
    <Container {...{ fullWidth, quarterWidth }} {...rest}>
      {children}
    </Container>
  )
}

export default EditorField
