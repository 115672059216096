import { memo, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import {
  AIResumeDraftEventLabels,
  AIResumeDraftEvents,
  AIResumeWarningModalTypes,
  SpeechToTextStatus,
  VoiceInputErrors,
} from 'builder/modules/constants'
import { actions } from 'builder/modules/resumeEditor'
import { VoiceInputTabProps } from 'builder/modules/resumeEditor/types'
import Colors from 'builder/styles/colors'
import { i18n as I18n } from 'builder/utils/i18n'
import { formatToTimestamp } from 'builder/utils/formatNumberInput'
import voiceInputNotStartedImage from '../../../../../../../app/assets/images/builder/ai-resume/voice-input-not-started.png'
import voiceInputAnimation from '../../../../../../../app/assets/images/builder/ai-resume/voice-input-animation.gif'
import voiceInputCompletedImage from '../../../../../../../app/assets/images/builder/ai-resume/voice-input-completed.png'
import { MAX_VOICE_INPUT_LENGTH } from '../../constants'
import {
  VoiceInputAnimation,
  VoiceInputStaticImage,
  RecordingStartedText,
  VoiceInputTimer,
  VoiceInputTimerContainer,
  RecordingErrorText,
  RecordingErrorIcon,
  RetakeButton,
  RetakeIcon,
} from '../styles'

const VoiceInputTab = memo((props: VoiceInputTabProps) => {
  const { audioChunks, duration, errorWhileRecording, isRecording, onStopRecording } = props
  const dispatch = useDispatch()
  const {
    step,
    accomplishmentsVoiceProcessingStatus,
    accomplishmentsVoiceInputError,
    goalsVoiceProcessingStatus,
    goalsVoiceInputError,
  } = useTypedSelector(state => state.resumeEditor.aiResume.questionnaireData)

  const isFutureGoalsStep = step === 6
  const voiceInputProcessingFailed =
    (isFutureGoalsStep ? goalsVoiceProcessingStatus : accomplishmentsVoiceProcessingStatus) ===
    SpeechToTextStatus.failed
  const isPermissionIssue = errorWhileRecording.includes('Permission denied') // If the user has denied microphone access
  const isBrowserIssue = errorWhileRecording.includes(
    'is not a valid value for enumeration PermissionName',
  ) // For browsers like firefox, which doesn't support the permission API

  const currentErrorMessage = useMemo(() => {
    return isFutureGoalsStep ? goalsVoiceInputError : accomplishmentsVoiceInputError
  }, [isFutureGoalsStep, goalsVoiceInputError, accomplishmentsVoiceInputError])

  const handleRetake = () => {
    dispatch(
      actions.setShowAIResumeWarningModal({
        showModal: true,
        type: AIResumeWarningModalTypes.retake,
      }),
    )
  }

  useEffect(() => {
    if (isRecording && duration >= MAX_VOICE_INPUT_LENGTH) {
      // When duration has reached the limit
      onStopRecording()
    }
  }, [duration, isRecording, onStopRecording])

  useEffect(() => {
    if (errorWhileRecording) {
      // Stop the recording if there has been some error while recording
      const [errorMessage, errorEventName] = isBrowserIssue
        ? [VoiceInputErrors.unsupportedBrowser, AIResumeDraftEvents.seeMicrophoneBrowserErrorScreen]
        : isPermissionIssue
        ? [VoiceInputErrors.permissionDenied, AIResumeDraftEvents.seeMicrophoneAccessErrorScreen]
        : [VoiceInputErrors.unknown, AIResumeDraftEvents.seeRecordingError]

      if (currentErrorMessage !== errorMessage) {
        trackInternalEvent(errorEventName, {
          label: isFutureGoalsStep
            ? AIResumeDraftEventLabels.careerGoals
            : AIResumeDraftEventLabels.professionalHighlights,
        })
        onStopRecording()
        dispatch(
          isFutureGoalsStep
            ? actions.setGoalsVoiceProcessingStatus(SpeechToTextStatus.failed)
            : actions.setAccomplishmentsVoiceProcessingStatus(SpeechToTextStatus.failed),
        )
        dispatch(
          isFutureGoalsStep
            ? actions.setGoalsVoiceInputError(errorMessage)
            : actions.setAccomplishmentsVoiceInputError(errorMessage),
        )
      }
    }
  }, [
    accomplishmentsVoiceInputError,
    currentErrorMessage,
    dispatch,
    errorWhileRecording,
    isBrowserIssue,
    isFutureGoalsStep,
    isPermissionIssue,
    goalsVoiceInputError,
    onStopRecording,
  ])

  return (
    <>
      {isRecording ? (
        <VoiceInputAnimation src={voiceInputAnimation} />
      ) : audioChunks.length === 0 ? (
        <VoiceInputStaticImage src={voiceInputNotStartedImage} />
      ) : (
        <VoiceInputStaticImage src={voiceInputCompletedImage} />
      )}
      {voiceInputProcessingFailed && currentErrorMessage && (
        <RecordingErrorText>
          <RecordingErrorIcon />
          {I18n.t(
            `builder.ai_resume_draft.text_voice_input.${
              currentErrorMessage === VoiceInputErrors.unsupportedBrowser
                ? 'browser_issue'
                : currentErrorMessage === VoiceInputErrors.permissionDenied
                ? 'permission_issue'
                : 'processing_failed'
            }`,
          )}
        </RecordingErrorText>
      )}
      {!voiceInputProcessingFailed && (
        <VoiceInputTimerContainer>
          {audioChunks.length > 0 && (
            <RetakeButton
              style={{ color: Colors.Neutral90, marginRight: '4px', padding: '8px' }}
              onClick={handleRetake}
            >
              <RetakeIcon style={{ color: Colors.Neutral90 }} />
              {I18n.t(`builder.ai_resume_draft.text_voice_input.retake`)}
            </RetakeButton>
          )}
          <VoiceInputTimer>
            {formatToTimestamp(duration)} / {formatToTimestamp(MAX_VOICE_INPUT_LENGTH)}
          </VoiceInputTimer>
        </VoiceInputTimerContainer>
      )}
      {isRecording && (
        <RecordingStartedText>
          {I18n.t('builder.ai_resume_draft.text_voice_input.recording')}
        </RecordingStartedText>
      )}
    </>
  )
})

export default VoiceInputTab
