import { useMemo } from 'react'
import { ButtonSize } from 'builder/components/Button/types'
import { useI18n } from 'builder/hooks/useI18n'
import { ButtonsWrapper, Content, ContentWrapper, Title } from '../styles'
import { TailoringButtonsGroup } from '../TailoringButtonsGroup/TailoringButtonsGroup'
import { PercentWrapper, PercentNeeded, TryAgainButton } from './styles'

interface Props {
  resumeScore: number
  onTryAgainClick: () => void
  onStartManualTailoring: () => void
  onStartAutoTailoring: () => void
  buttonSize: ButtonSize
  isSavingJobPostingData: boolean
}

const BottomContainerResultView = (props: Props) => {
  const {
    resumeScore,
    onTryAgainClick,
    onStartManualTailoring,
    onStartAutoTailoring,
    buttonSize,
    isSavingJobPostingData,
  } = props
  const { i18n } = useI18n()
  const requiredScore = 100 - resumeScore

  const matchTitle = useMemo(() => {
    if (resumeScore < 30) {
      return i18n.t('builder.resume_optimizer.job_posting.poor_match.title')
    } else if (resumeScore > 70) {
      return i18n.t('builder.resume_optimizer.job_posting.good_match.title')
    } else {
      return i18n.t('builder.resume_optimizer.job_posting.normal_match.title')
    }
  }, [resumeScore, i18n])

  return (
    <>
      <Title>{matchTitle}</Title>
      <ContentWrapper $direction="row">
        <PercentWrapper>
          <PercentNeeded>{requiredScore}</PercentNeeded>
        </PercentWrapper>
        <Content>{i18n.t('builder.resume_optimizer.job_posting.poor_match.content')}</Content>
      </ContentWrapper>
      <ButtonsWrapper>
        <TryAgainButton
          size={buttonSize}
          isTryAgain={true}
          onClick={onTryAgainClick}
          isDisabled={isSavingJobPostingData}
        >
          {i18n.t('builder.resume_optimizer.job_posting.try_again')}
        </TryAgainButton>
        <TailoringButtonsGroup
          isSavingJobPostingData={isSavingJobPostingData}
          buttonSize={buttonSize}
          onStartManualTailoring={onStartManualTailoring}
          onStartAutoTailoring={onStartAutoTailoring}
        />
      </ButtonsWrapper>
    </>
  )
}

export default BottomContainerResultView
