import PropTypes from 'prop-types'

import { Container, Text, Spinner } from './styles'

const LoadingSnackBar = ({ text }) => (
  <Container>
    <Spinner />
    <Text>{text}</Text>
  </Container>
)

LoadingSnackBar.propTypes = {
  text: PropTypes.string,
}

export default LoadingSnackBar
