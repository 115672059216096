import styled from 'styled-components'

import Sizes from 'builder/styles/sizes'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: ${Colors.White};
  ${Media.Phone`
    flex-direction: column;
    align-items: flex-start;
  `}
  margin-right: 40px;
`

export const IconContainer = styled.div`
  display: flex;
  padding: ${Sizes.S} 0px;
  justify-content: center;
  align-items: center;
  gap: ${Sizes['3XS']};
  border-bottom: 3px solid ${Colors.Blue50};
  & svg path {
    margin: 5px;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  border-bottom: 1px solid ${Colors.Neutral15};
  gap: ${Sizes.L};

  ${Media.Phone`
    justify-content: flex-start;
    & > :first-child {
      padding-left: 0px;
    }
    & > :first-child > :first-child {
      padding-left: 0px;
    }
  `}
`

export const AutoApplyContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${Sizes['3XS']};
  background: ${Colors.White};
  padding: 8px 0;
`

export const AutoApplyButton = styled.div`
  display: flex;
  gap: ${Sizes['3XS']};
  color: ${Colors.Blue50};
  font-weight: ${FontWeights.Bold};
  ${Typography.Body};
  align-items: center;
  background: ${Colors.White};
  padding: 8px 12px 8px 0;
  border-radius: 12px;
`

export const Bubble = styled.div`
  border-radius: 100%;
  color: ${Colors.Blue50};
  background: ${Colors.Blue10};
  padding: ${Sizes['3XS']};
  flex-shrink: 0;
  height: 30px;
`

export const SettingIconContainer = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
