import { Attachment } from 'packages/types'
import { FormatType } from '../constants'

export const trDocFileContent = (trDocFiles: Attachment[], format: FormatType) => {
  let fileFormat = format
  if (fileFormat === 'tr_doc') {
    fileFormat = 'docx'
  } else if (fileFormat === 'tr_pdf') {
    fileFormat = 'pdf'
  }

  const file = trDocFiles?.find(file => {
    if (format === 'docx') {
      return file?.filename?.endsWith('docx') || file?.filename?.endsWith('doc')
    }

    return file?.filename?.endsWith(fileFormat)
  })

  const trDocFileURL = file?.url
  const trDocFileContentType = file?.content_type
  const trDocFileName = file?.filename

  return {
    trDocFileURL,
    trDocFileContentType,
    trDocFileName,
  }
}
