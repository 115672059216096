export const addIfNotExists = <T>(arr: T[], inputValue: T) => {
  const arrayOfLowerCaseString = arr.map(val => String(val).toLowerCase())
  return !arrayOfLowerCaseString.includes(String(inputValue).toLowerCase())
    ? [...arr, inputValue]
    : arr
}

export const indexOfCaseInsensitive = (arr: string[], inputValue: string) => {
  const arrayLowerCase = arr.map(val => val.toString().toLowerCase())
  const inputLowerCase = inputValue.toLowerCase()
  return arrayLowerCase.indexOf(inputLowerCase)
}
