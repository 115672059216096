import styled from 'styled-components'
import Media from 'builder/styles/media'

export const Wrapper = styled.div`
  width: 100%;
  height: 180px;
  display: grid;
  place-items: center;

  ${Media.Tablet`
    height: 240px;
  `}
`
