import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/careerPlanning'
import CareerPlanningProgress from 'builder/components/CareerPlanningProgress/CareerPlanningProgress'
import CareerPlanningLevels from 'builder/components/CareerPlanningLevels'
import { SideBarContainer } from './styles'

const SideBar = () => {
  const progress = useTypedSelector(selectors.progress)

  return (
    <SideBarContainer>
      <CareerPlanningProgress score={progress} isDeskTop={true} isMobile={false} />
      <CareerPlanningLevels />
    </SideBarContainer>
  )
}

export default SideBar
