import styled, { keyframes } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Button from 'builder/components/Button'
import { Icon20, Icon24 } from 'builder/components/Icon'

export const TooltipTrack = styled.div`
  position: absolute;
  width: 360px;
  left: calc(100% + 80px);
  top: 0;
  bottom: 0;
  z-index: 10;
  pointer-events: none;

  @media (min-width: 1760px) {
    left: calc(100% + 7vw);
  }
`

const appear = keyframes`
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
`

export const TooltipContainer = styled.div`
  position: sticky;
  left: 0;
  top: 120px;
  top: max(120px, 15vh);
  width: 100%;
  padding: 20px;
  pointer-events: auto;
  border-radius: 6px;
  background-color: ${Colors.White};
  box-shadow: 0 7px 30px rgba(23, 68, 130, 0.15), 0 0 1px rgba(23, 68, 130, 0.2);
  animation: ${appear} 0.3s 0.3s ease-out backwards;

  &:before {
    content: '';
    position: absolute;
    top: calc(50% - 8px);
    left: -8px;
    width: 16px;
    height: 16px;
    border-radius: 0 0 0 3px;
    background-color: inherit;
    transform: rotate(45deg);
  }
`

export const TooltipBullets = styled.div`
  display: flex;
  margin-bottom: 12px;
`

export const TooltipBullet = styled.div<{ isActive?: boolean }>`
  width: ${props => (props.isActive ? '16px' : '6px')};
  height: 6px;
  margin-right: 6px;
  border-radius: 3px;
  background-color: ${props => (props.isActive ? Colors.Blue50 : Colors.Neutral20)};
  transition: width 0.25s, background-color 0.25s;
`

export const TooltipTitle = styled.div`
  font-weight: 600;
`

export const TooltipText = styled.div`
  margin-bottom: 16px;
  ${Typography.Caption};
`

export const TooltipProgress = styled.div`
  background-color: ${Colors.Neutral20};
  margin-bottom: 8px;
`

export const TooltipProgressBar = styled.div`
  height: 3px;
  background-color: ${Colors.Green60};
  transform-origin: 0 0;
  transition: transform 0.25s;
`

export const TooltipStatus = styled.div`
  display: flex;
  ${Typography.Caption};
  color: ${Colors.Neutral50};
`

export const TooltipStatusLabel = styled.div`
  flex-grow: 1;
`

export const TooltipStatusCounter = styled.div`
  margin-left: 12px;
  text-align: right;
`

export const TooltipStatusIcon = styled(Icon20.StatusDone)`
  margin-left: 4px;
  color: ${Colors.Neutral40};
  transition: color 0.25s;

  &[data-done='true'] {
    color: ${Colors.Green50};
  }
`

export const TooltipActions = styled.div`
  margin-top: 16px;

  & ${Button} {
    margin-right: 8px;
  }
`

export const TooltipClose = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  padding: 8px;
  cursor: pointer;
  color: ${Colors.Neutral40};

  &:hover {
    color: ${Colors.Blue50};
  }
`

export const TooltipCloseIcon = styled(Icon24.Close)``
