import { useEffect, useReducer } from 'react'
import isEqual from 'lodash/isEqual'
import { matchMediaQueries } from 'builder/utils/matchMediaQueries'

const usePureState = <T>(initiator: () => T) => {
  return useReducer(
    (prevState: T, nextState: T) => {
      // if states the equal then skip the update
      if (isEqual(prevState, nextState)) {
        return prevState
      }

      return nextState
    },
    undefined,
    () => initiator(),
  )
}

export const useMediaQueries = () => {
  const [mediaQueries, setMediaQueries] = usePureState(() => matchMediaQueries())

  useEffect(() => {
    const listener = () => setMediaQueries(matchMediaQueries())

    window.addEventListener('resize', listener)
    return () => window.removeEventListener('resize', listener)
  }, [setMediaQueries])

  return mediaQueries
}
