import styled from 'styled-components'
import Media from 'builder/styles/media'

export const Container = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 32px;

  ${Media.Phone`
    gap: 27px;
  `}
`
