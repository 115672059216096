import styled from 'styled-components'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'

export const Container = styled.div`
  position: relative;
  min-height: 900px;
  max-width: 1120px;
  width: calc(100% - 40px);
  margin: 0 auto;
  padding: 34px 0 80px;
  gap: 48px;
  display: flex;
  flex-direction: column;

  ${Media.Tablet`
    gap: 48px;
    padding-bottom: 48px;
  `}

  ${Media.Phone`
    max-width: 100%;
    width: 100%
    display: flex;
    flex-direction: column;
  `}
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Text = styled.h4`
  ${FontWeights.DemiBold};
  ${Typography.M};
  display: flex;
  justify-content: center;
`

export const SubText = styled.p`
  display: flex;
  justify-content: center;
  color: ${Colors.Neutral50};
`

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
`

export const Cards = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
`

export const CardLogo = styled.img`
  align-self: center;
`

export const Accept = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 4px;
  ${Typography.Caption};
  color: ${Colors.Neutral50};
`

export const FooterText = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  text-align: center;
  width: 736px;
  ${Typography.Caption};
  color: ${Colors.Neutral50};

  ${Media.Phone`
    width: 100%;
  `}
`
