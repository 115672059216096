import { Component } from 'react'
import PropTypes from 'prop-types'
import { sortableContainer, sortableElement } from 'react-sortable-hoc'
import Anchor from 'builder/components/Anchor'
import SectionComponent from 'builder/components/Section'
import nanoid from 'nanoid'

const Droppable = sortableContainer(({ children }) => <div>{children}</div>)
const Draggable = sortableElement(({ sortId, children }) => <div data-id={sortId}>{children}</div>)

const propTypes = {
  index: PropTypes.number.isRequired,
  section: PropTypes.object.isRequired,
  onCardMove: PropTypes.func.isRequired,
  levels: PropTypes.object,
  locale: PropTypes.string.isRequired,
  hideSkillLevel: PropTypes.bool,
  foldableDuration: PropTypes.number,
  isCardOpen: PropTypes.func.isRequired,
  onAdd: PropTypes.func,
  onCardDelete: PropTypes.func,
  onCardChange: PropTypes.func,
  onCardToggle: PropTypes.func,
  template: PropTypes.string,
  updateSimpleField: PropTypes.func,
  position: PropTypes.string,
  skills: PropTypes.array.isRequired,
  isDragging: PropTypes.bool,
  isRecommendedLengthHidden: PropTypes.bool,
  setContentOffset: PropTypes.func,
  triggerScroll: PropTypes.bool,
}

export class Section extends Component {
  static propTypes = propTypes

  state = {
    draggableIndex: -1,
    customId: nanoid(),
  }

  preventSelection = e => {
    e.preventDefault()
    return false
  }

  handleSortStart = ({ index }) => {
    // Fix cursor
    document.addEventListener('selectstart', this.preventSelection)

    return this.setState({ draggableIndex: index })
  }

  handleCardMove = ({ oldIndex, newIndex }) => {
    document.removeEventListener('selectstart', this.preventSelection)

    if (oldIndex !== newIndex) {
      this.props.onCardMove({ oldIndex, newIndex, sectionId: this.props.section.id })
    }

    this.setState({ draggableIndex: -1 })
  }

  render() {
    const {
      section,
      levels,
      locale,
      hideSkillLevel,
      isCardOpen,
      onCardDelete,
      onCardChange,
      onCardToggle,
      foldableDuration,
      template,
      updateSimpleField,
      position,
      isRecommendedLengthHidden,
      setContentOffset,
      triggerScroll,
      ...rest
    } = this.props
    const { draggableIndex, customId } = this.state
    const { hidden, CardComponent } = section

    if (hidden) return null

    return (
      <Anchor id={section.id}>
        <SectionComponent
          item={section}
          setContentOffset={setContentOffset}
          triggerScroll={triggerScroll}
          {...{ ...rest, template, locale, position, hideSkillLevel, updateSimpleField }}
        >
          <Droppable
            updateBeforeSortStart={this.handleSortStart}
            onSortEnd={this.handleCardMove}
            useWindowAsScrollContainer
            useDragHandle
          >
            {section.items.map((item, index) => (
              <Draggable key={item.cid || item.id || customId} index={index}>
                <Anchor id={item.cid || item.id || customId}>
                  <CardComponent
                    item={item}
                    sectionId={section.id}
                    sectionTitle={section.title}
                    levels={levels}
                    locale={locale}
                    hideSkillLevel={hideSkillLevel}
                    foldableDuration={foldableDuration}
                    isOpen={isCardOpen(item.cid || item.id || customId)}
                    isDragging={draggableIndex === index}
                    onChange={section.useUpdateSimpleField ? updateSimpleField : onCardChange}
                    onDelete={onCardDelete}
                    onToggle={onCardToggle}
                    template={template}
                    jobTitle={position}
                    isRecommendedLengthHidden={isRecommendedLengthHidden}
                  />
                </Anchor>
              </Draggable>
            ))}
          </Droppable>
        </SectionComponent>
      </Anchor>
    )
  }
}

const SortableSection = props => {
  const { id, sortId, sortable } = props.section

  if (sortable) {
    return (
      <Draggable sortId={sortId || id} index={props.index}>
        <Section {...props} />
      </Draggable>
    )
  }

  return <Section {...props} />
}

SortableSection.propTypes = propTypes

export default SortableSection
