import { useEffect } from 'react'
import nanoid from 'nanoid'
import { useConstant } from './useConstant'
import { useKeyPress } from './useKeyPress'
import { useEvent } from './useEvent'

let mountedNodeIds: string[] = []

/** Trigger a given callback when user clicks "Esc" keyboard button */
export const useEscapeHandler = (handler: () => void) => {
  // Copy function to a ref to avoid unnecessary deps checks
  const handlerCallback = useEvent(handler)

  // Unique id of the node
  const id = useConstant(() => nanoid())

  // 27 is a keycode of "Escape" and "Esc" keyboard buttons
  const keyPressed = useKeyPress(27)

  // Trigger the handler only if this node is the last mounted one
  useEffect(() => {
    if (keyPressed && mountedNodeIds.at(-1) === id) handlerCallback()
  }, [handlerCallback, id, keyPressed])

  // Manage the list of mounted nodes
  useEffect(() => {
    const nodeId = id
    mountedNodeIds.push(nodeId)

    return () => {
      mountedNodeIds = mountedNodeIds.filter(value => value !== nodeId)
    }
  }, [id])
}
