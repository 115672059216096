import styled from 'styled-components'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'

export const WelcomeContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
  height: 304px;
  background: ${Colors.Neutral5};

  ${Media.Phone`
    height: 340px;
  `};
`

export const WelcomeTitle = styled.div`
  position: absolute;
  width: 352px;
  height: 40px;

  top: 90px;
  color: ${Colors.Neutral90};
  ${Typography.L};
  ${FontWeights.DemiBold};

  ${Media.Tablet`
    ${Typography.M};
    ${FontWeights.DemiBold};
    height: 36px;
    left: 20px;
    top: 104px;
  `};

  ${Media.Phone`
    ${Typography.M};
    ${FontWeights.DemiBold};
    width: 100%;
    height: 30px;
    top: 230px;
    padding-left:20px;
    margin: 0px;
    left: 50%;
    transform: translate(-50%);
  `};
`

export const WelcomeText = styled.div`
  position: absolute;
  width: 352px;
  height: 48px;
  top: 154px;
  ${Typography.Body};
  color: ${Colors.Neutral90};
  ${FontWeights.Regular};

  ${Media.Tablet`
    left: 20px;
    top: 152px;
  `};

  ${Media.Phone`
    ${Typography.Caption};
    width: 100%;
    height: 40px;
    top: 258px;
    padding-top:10px;
    padding-left:20px;
    left: 50%;
    transform: translate(-50%);
  `};
`
export const WelcomeImgContainer = styled.img`
  margin-right: 102px;
  top: 96px;
  width: 260px;

  ${Media.Tablet`
    right: 16px;
  `};

  ${Media.Phone`
  position:absolute;
    margin: 0;
    left: 50%;
    transform: translate(-50%);
    top: 66px;
    height: 96px
    width: 200px;
  `};
`

export const BannerLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  margin-right: 32px;

  ${Media.Phone`
    margin-right: 0;
    margin-bottom: 20px;
  `};
`

export const BannerRightContent = styled.div`
  display: flex;
  align-items: center;

  ${Media.Tablet`
    width: auto;
  `};

  ${Media.Phone`
    width: 100%;
  `};
`
