import { useState, useEffect, useRef } from 'react'

/**
 * A simple version of https://github.com/Swizec/useDimensions
 */
export const useDimensions = (): [(HTMLElement: HTMLElement | null) => void, number, number] => {
  const [node, setNode] = useState<HTMLElement | null>(null)
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    const measure = () => {
      if (!node) return
      setWidth(node.clientWidth)
      setHeight(node.clientHeight)
    }

    measure()
    window.addEventListener('resize', measure)

    return () => {
      window.removeEventListener('resize', measure)
    }
  }, [node])

  return [setNode, width, height]
}

/**
 * Divides the angle value into two variables.
 * That allows us to change the values separately (from the free rotation slider mostly)
 */
export const useAngle = (angle: number): { baseAngle: number; freeAngle: number } => {
  const prevAngle = useRef<number>(0)
  const prevBaseAngle = useRef<number>(0)

  let freeAngle = angle % 90
  let baseAngle = angle - freeAngle

  // Preserve the current base angle when user reaches the free angle limit
  // For example, `90-45` should NOT be converted to `0+45` when an active base angle is 90 deg
  if (Math.abs(prevBaseAngle.current - angle) <= 45) {
    baseAngle = prevBaseAngle.current
    freeAngle = angle - baseAngle
  }

  // Preserve the current free angle value in case if a user rotated by -90 or 90 deg
  // For example, `90+45` after rotation by -90 must be `0+45` (not `90-45`)
  if (Math.abs(angle - prevAngle.current) === 90) {
    const diff = angle - prevAngle.current // +90 or -90
    baseAngle = prevBaseAngle.current + diff
    freeAngle = angle - baseAngle
  }

  // Limit a free angle value in [-45, 45] bounds
  // For example, `0+80` must be converted to `90-10`
  if (freeAngle > 45) {
    baseAngle += 90
    freeAngle -= 90
  }

  prevAngle.current = angle
  prevBaseAngle.current = baseAngle

  return { baseAngle, freeAngle }
}
