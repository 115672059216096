import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Shadows from 'builder/styles/shadows'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const NotifyMeContainer = styled.div`
  ${Shadows.light.mid};
  z-index: 1;
  width: 736px;
  padding: 24px;
  border-radius: 12px;
  background: ${Colors.White};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);

  ${Media.Tablet`
    width: calc(100% - 32px);
    left: 16px;
    transform: translate(0%)
  `}

  ${Media.Phone`
    width: calc(100% - 32px);
    left: 16px;
    gap: 20px;
    padding: 20px;
    min-height: 166px;
    flex-direction: column;
    align-items: flex-start;
  `}
`

export const SubContainer = styled.div`
  ${Media.Phone`
    gap: 8px;
    display: flex;
    flex-direction: column;
  `}
`

export const Title = styled.div`
  ${Typography.Subhead};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral90};
`

export const Subtitle = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
`

export const ButtonContainer = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${Media.Phone`
    width: 100%;
  `}
`
