import { useState } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useUser } from 'builder/hooks/useUser'
import { useI18n } from 'builder/hooks/useI18n'
import {
  Overlay,
  ModalRootContainer,
  ModalTitle,
  ModalContent,
  ModalCardsContainer,
  Close,
  CloseIcon,
  ModalCardHeader,
} from './styles'
import { Card } from './Card'
import connectedWithRecruiters from './assets/connected-with-recruiters.jpg'
import applyOnYourBehalf from './assets/apply-on-your-behalf.jpg'

export const IncludedInYourSubModal = () => {
  const user = useUser()
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const { i18n } = useI18n()

  const userGotCredits = () => {
    return user?.scopes.autoApply && user?.scopes.resumeDistribution
  }

  const shouldOpenModal = () => {
    const rdModal = searchParams.get('rd_modal')
    return !userGotCredits() && rdModal === '1'
  }

  const stripRDModalQueryParam = () => {
    const url = window.location.href
    let urlWithoutRDModalParam = url.replace(/&?rd_modal=1/, '')
    if (urlWithoutRDModalParam.endsWith('?')) {
      urlWithoutRDModalParam = urlWithoutRDModalParam.slice(0, -1)
    }
    window.history.pushState({}, document.title, urlWithoutRDModalParam)
  }

  const handleModalClose = () => {
    stripRDModalQueryParam()
    setIsModalOpen(false)
    setSearchParams('') // This is required to make the onboarding trigger correctly after the modal is closed
    trackInternalEvent('close_included_in_sub_modal')
  }

  const navigateToResumeDistribution = () => {
    trackInternalEvent('activate_feature_from_included_in_sub_modal', { feature: 'RD' })
    navigate('/resume-distribution')
    setIsModalOpen(false)
  }

  if (!isModalOpen) {
    if (shouldOpenModal()) {
      trackInternalEvent('show_included_in_sub_modal')
      setIsModalOpen(true)
    } else {
      return null
    }
  }

  return (
    <Overlay id="included-in-your-sub-modal">
      <ModalRootContainer>
        <ModalContent>
          <ModalCardHeader>
            <ModalTitle>{i18n.t('builder.included_in_your_subscription.modal_title')}</ModalTitle>
            <Close onClick={handleModalClose}>
              <CloseIcon />
            </Close>
          </ModalCardHeader>
          <ModalCardsContainer>
            <Card
              title={i18n.t('builder.included_in_your_subscription.resume_distribution.title')}
              description={i18n.t(
                'builder.included_in_your_subscription.resume_distribution.description',
              )}
              image={connectedWithRecruiters}
              handleCtaClick={navigateToResumeDistribution}
              ctaText={i18n.t('builder.included_in_your_subscription.resume_distribution.cta')}
            />
            <Card
              title={i18n.t('builder.included_in_your_subscription.auto_apply.title')}
              description={i18n.t('builder.included_in_your_subscription.auto_apply.description')}
              image={applyOnYourBehalf}
              ctaDisabled={true}
            />
          </ModalCardsContainer>
        </ModalContent>
      </ModalRootContainer>
    </Overlay>
  )
}
