import { useMemo, useCallback, useState, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { ResumeSkillLevel } from 'packages/types'
import { useI18n } from 'builder/hooks/useI18n'
import { TextField } from 'builder/components/TextField'
import AddCardButton from 'builder/components/AddCardButton'
import SkillsExtra from 'builder/components/Section/SkillsExtra'
import { LevelSelect } from 'builder/components/LevelSelect'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as generalEditorSelectors } from 'builder/modules/generalEditor'
import { actions, SectionNames, UpdateSimpleFieldPayload } from 'builder/modules/resumeEditor'
import { generateRandomId } from 'builder/utils/generateRandomId'
import Foldable from 'builder/components/Foldable'
import {
  Container,
  NewSkill,
  Field,
  Header,
  SkillName,
  SkillLevel,
  Row,
  CloseButton,
  FoldableContent,
} from './styles'
import { NewSkillContainerProps } from './types'

const defaultSkillLevel = 'expert'
const defaultSkillName = null
const animationDuration = 200

export const NewSkillContainer = ({ resumeTailored }: NewSkillContainerProps) => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const updateSimpleField = (name: string, value: boolean, debounce: boolean) =>
    dispatch(actions.updateSimpleField({ name, value, debounce } as UpdateSimpleFieldPayload))
  const levels = useTypedSelector(generalEditorSelectors.levels)
  const [skillLevel, setSkillLevel] = useState<ResumeSkillLevel>(defaultSkillLevel)
  const [skillName, setSkillName] = useState<string | null>(defaultSkillName)
  const [isAddOpen, setIsAddOpen] = useState<boolean>(false)
  const newSkillContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isAddOpen) {
      const timeout = setTimeout(() => {
        newSkillContainerRef.current?.scrollIntoView({ behavior: 'smooth' })
      }, animationDuration)
      return () => clearTimeout(timeout)
    }
  }, [isAddOpen])

  const addButtonText = useMemo(() => {
    return resumeTailored
      ? resumeTailored.skills.length
        ? i18n.t('builder.resume_editor.skills_section.add_one_more')
        : i18n.t('builder.resume_editor.skills_section.add')
      : null
  }, [i18n, resumeTailored])

  const levelOptions = [
    {
      translation: i18n.t('builder.resume_editor.skills_card.select_level'),
      name: '',
      rating: undefined,
    },
    ...(levels.skill ?? []),
  ].map(level => ({
    id: level.name,
    name: level.translation,
    rating: level.rating,
  }))

  const handleLevelSelect = (value: string) => {
    setSkillLevel(value as ResumeSkillLevel)
  }

  const handleChangeSkillName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSkillName(event.target.value)
  }

  const formatSkillName = useMemo(() => {
    if (!skillName) {
      return i18n.t('builder.resume_editor.not_specified')
    } else return skillName
  }, [i18n, skillName])

  const handleAddCard = useCallback(() => {
    if (skillName) {
      dispatch(
        actions.addCard({
          sectionName: SectionNames.skills,
          cardId: generateRandomId(),
          options: {
            position: 'after',
            fields: { skill: skillName, level: skillLevel },
          },
        }),
      )
      setSkillLevel(defaultSkillLevel)
      setSkillName(defaultSkillName)
    }
  }, [skillName, skillLevel, dispatch])

  const handleAddNewCardClick = useCallback(() => {
    setIsAddOpen(true)
    handleAddCard()
  }, [handleAddCard])

  const handleCloseClick = useCallback(() => {
    setIsAddOpen(false)
    handleAddCard()
  }, [handleAddCard])

  const handleKeyup = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        setIsAddOpen(false)
        handleAddCard()
      }
    },
    [handleAddCard],
  )

  return (
    <Container>
      <Foldable isOpen={isAddOpen} duration={animationDuration}>
        <FoldableContent>
          <SkillsExtra
            hideSkillLevel={resumeTailored && resumeTailored.hideSkillLevel}
            updateSimpleField={updateSimpleField}
          />
          <NewSkill ref={newSkillContainerRef}>
            <Header>
              <SkillName>{formatSkillName}</SkillName>
              {resumeTailored && !resumeTailored.hideSkillLevel && (
                <SkillLevel>{skillLevel}</SkillLevel>
              )}
            </Header>
            <Row>
              <Field>
                <TextField
                  autoFocus={true}
                  label={i18n.t('builder.resume_editor.skills_card.skill')}
                  onChange={handleChangeSkillName}
                  onKeyUp={handleKeyup}
                  value={skillName || ''}
                  name="skill"
                />
              </Field>
              <Field>
                <LevelSelect
                  label={i18n.t('builder.resume_editor.skills_card.level')}
                  selected={skillLevel || undefined}
                  options={levelOptions}
                  onChange={handleLevelSelect}
                  disabled={resumeTailored ? resumeTailored.hideSkillLevel : true}
                />
              </Field>
            </Row>
            <CloseButton onClick={handleCloseClick} />
          </NewSkill>
        </FoldableContent>
      </Foldable>
      <AddCardButton onAdd={handleAddNewCardClick} text={addButtonText} />
    </Container>
  )
}
