import { memo } from 'react'
import Icon from 'builder/components/Icon'
import Button from 'builder/components/Button'
import { i18n as I18n } from 'builder/utils/i18n'
import { ActionsButtonBadge } from 'builder/components/PreviewPanel/components/DownloadMenuLayout/styles'
import DocumentActionMenu from 'builder/components/DocumentActionMenu'
import CoRegPopup, { CoRegPopupPositions } from 'builder/components/PreviewPanel/CoRegPopup'

import {
  Actions,
  ActionsButton,
  Buttons,
  CloseButton,
  CloseWrapper,
  MobileTemplateSettingsIcon,
  Toolbar,
} from './styles'
import { PreviewToolbarProps } from './types'

const PreviewToolbar = (props: PreviewToolbarProps) => {
  const {
    type,
    isResume,
    isDownloading,
    currentDocument,
    handleSheetOpen,
    handleDownloadDocument,
    handleActionMenuClose,
    handleShareClick,
    handleActionMenuOpen,
    isReadyToCoReg,
    isActionMenuOpen,
    isCoRegPopupOpen,
    openCoReg,
    toggleCoRegPopup,
    onClose,
    showUpdatedMenuLayout,
  } = props
  return (
    <Toolbar>
      <MobileTemplateSettingsIcon onClick={handleSheetOpen}>
        <Icon.Settings />
      </MobileTemplateSettingsIcon>
      <Buttons>
        <>
          <Button
            onClick={showUpdatedMenuLayout ? handleActionMenuOpen : handleDownloadDocument}
            size={isResume ? 'small' : 'default'}
            isDisabled={isDownloading}
          >
            {I18n.t(
              `builder.resume_editor.${
                showUpdatedMenuLayout ? 'download_document_menu.button_text' : 'download'
              }`,
            )}
          </Button>

          {isResume && (
            <Actions>
              {!showUpdatedMenuLayout && (
                <ActionsButton onClick={handleActionMenuOpen} isDisabled={isDownloading}>
                  <Icon.More />
                </ActionsButton>
              )}

              <ActionsButtonBadge
                isVisible={isReadyToCoReg && !isActionMenuOpen && !isCoRegPopupOpen}
              />

              {isActionMenuOpen && (
                <DocumentActionMenu
                  position="bottom"
                  documentType={type}
                  documentId={currentDocument.id}
                  onClose={handleActionMenuClose}
                  onDownload={handleDownloadDocument}
                  onShare={handleShareClick}
                />
              )}

              {isCoRegPopupOpen && (
                <CoRegPopup
                  position={CoRegPopupPositions.bottom}
                  onClick={() => openCoReg({ resumeId: currentDocument.id, source: 'pop_up' })}
                  onClose={() => toggleCoRegPopup(false)}
                />
              )}
            </Actions>
          )}
        </>
      </Buttons>
      <CloseWrapper>
        <CloseButton onClick={onClose}>
          <Icon.CloseLarge />
        </CloseButton>
      </CloseWrapper>
    </Toolbar>
  )
}

export default memo(PreviewToolbar)
