import { useCallback, useEffect, useRef, useState } from 'react'
import * as React from 'react'
import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Icon from 'builder/components/Icon'
import {
  SliderContainer,
  SliderContent,
  SliderPlaceholder,
  ServicesLeftButton,
  ServicesRightButton,
  NavigationButtons,
  SectionHeading,
  HeaderText,
  Card,
  Text,
  SubText,
  SliderContainerBorder,
  SwiperContainer,
} from './styles'
import { getThemeColors, COACH_SERVICES } from './constants'

SwiperCore.use([Navigation])

const ServicesSlider = () => {
  const [slideIndex, setSlideIndex] = useState(0)

  const prevRef: React.RefObject<HTMLButtonElement> = useRef(null)
  const nextRef: React.RefObject<HTMLButtonElement> = useRef(null)

  const [services, setServices] = useState('')
  useEffect(() => {
    setServices('coaching')
  }, [services])

  const handleSlideChange = useCallback(({ realIndex }) => {
    setSlideIndex(realIndex)
  }, [])

  return (
    <SliderContainer data-testid="course-swiper">
      <SectionHeading>
        <HeaderText>Services</HeaderText>
        <NavigationButtons>
          <ServicesRightButton ref={nextRef} data-testid="leftButton">
            <Icon.Chevron />
          </ServicesRightButton>
          <ServicesLeftButton ref={prevRef} data-testid="rightButton">
            <Icon.Chevron />
          </ServicesLeftButton>
        </NavigationButtons>
      </SectionHeading>

      {COACH_SERVICES.length ? (
        <SwiperContainer>
          <SliderContent>
            <Swiper
              initialSlide={slideIndex}
              slidesPerView="auto"
              loop={true}
              spaceBetween={16}
              navigation={{
                prevEl: prevRef.current,
                nextEl: nextRef.current,
              }}
              className="mySwiper"
              slideToClickedSlide={true}
              onSlideChange={handleSlideChange}
            >
              {COACH_SERVICES.map(({ id, name, description, url }) => (
                <SwiperSlide key={`template-${id}`} style={{ width: '100%' }}>
                  <Card service={getThemeColors(name)} key={id}>
                    <img src={url} />
                    <Text>{name}</Text>
                    <SubText>{description}</SubText>
                  </Card>
                </SwiperSlide>
              ))}
            </Swiper>
          </SliderContent>
        </SwiperContainer>
      ) : (
        <SliderPlaceholder />
      )}
      <SliderContainerBorder />
    </SliderContainer>
  )
}

export default ServicesSlider
