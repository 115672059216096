import * as Styled from './styles'
import avatar01Url from './images/avatar-01.png'
import avatar02Url from './images/avatar-02.png'
import avatar03Url from './images/avatar-03.png'
import { Props } from './types'

export const CoachAvatars = (props: Props) => {
  return (
    <Styled.Container {...props}>
      <Styled.Avatar src={avatar01Url} alt="Coach 1" />
      <Styled.Avatar src={avatar02Url} alt="Coach 2" />
      <Styled.Avatar src={avatar03Url} alt="Coach 3" />
    </Styled.Container>
  )
}
