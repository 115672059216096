import React from 'react'
import { Menu, MenuItem, MenuItemContent } from 'builder/components/Menu'

import { useMenuItems } from '../../hooks/useMenuItems'
import { Profile } from '../Profile/Profile'
import { SubMenu } from './SubMenu'
import { LeftDrawer } from './styles'

type IProps = React.HTMLAttributes<HTMLDivElement> & {
  id?: string
  isOpen: boolean
  width: string
  onClickLink?: (link: string) => void
}

const MenuComponent = ({ id, isOpen, width, onClickLink, ...rest }: IProps) => {
  const { menuItems, isActiveSubMenu } = useMenuItems()

  return (
    <LeftDrawer {...rest} id={id} width={width} isOpen={isOpen}>
      <Menu>
        <Profile width={width} open={isOpen} isSubMenuActive={isActiveSubMenu} />
        {menuItems.map(item => (
          <MenuItem
            position={item.position || 'start'}
            key={item.text}
            width={width}
            subMenu={item?.subMenu && <SubMenu items={item?.subMenu} onClickLink={onClickLink} />}
          >
            <MenuItemContent
              {...item}
              open={isOpen}
              subMenu={!!item?.subMenu}
              onClickLink={onClickLink}
            >
              {item.text}
            </MenuItemContent>
          </MenuItem>
        ))}
      </Menu>
    </LeftDrawer>
  )
}

export default React.memo(MenuComponent)
