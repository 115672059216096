import { Fragment, useMemo } from 'react'
import { i18n as I18n } from 'builder/utils/i18n'
import Tooltip from 'builder/components/Tooltip/Tooltip'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as resumeEditorSelectors, PrefillStatuses } from 'builder/modules/resumeEditor'
import { PrefillPopover } from 'builder/components/PrefillPopover'
import { useAppSelector } from 'builder/hooks/useAppSelector'
import { MODES } from './types'
import { SyncLocallyIcon, SyncRemoteIcon, SyncStatusContainer } from './styles'

interface Props {
  updatedAt: string
  mode: MODES
  documentId: number
}

export const SyncStatus = ({ updatedAt, mode, documentId }: Props) => {
  const isOnline = useAppSelector(state => state.application.isOnline)
  const isSyncing = useAppSelector(state => state.resumeEditor.isSyncing)

  const { status: prefillingStatus } = useTypedSelector(resumeEditorSelectors.prefill)
  const isPrefilling = prefillingStatus === PrefillStatuses.scheduled
  const text = useMemo(() => {
    if (!isOnline && isSyncing) return I18n.t('builder.resume_editor.saving_locally')
    if (!isOnline) return I18n.t('builder.resume_editor.offline')
    if (isPrefilling) return I18n.t('builder.resume_editor.prefilling')
    if (isSyncing) return I18n.t('builder.resume_editor.saving')

    return I18n.t('builder.resume_editor.saved')
  }, [isOnline, isSyncing, isPrefilling])

  return (
    <Fragment>
      {isOnline ? (
        <SyncStatusContainer>
          <SyncRemoteIcon isSyncing={isSyncing || isPrefilling} isDark={mode === MODES.wizard} />
          {text}
          <PrefillPopover documentId={documentId} />
        </SyncStatusContainer>
      ) : (
        <Tooltip
          value={I18n.t('builder.resume_editor.every_change_is_saved', { updatedAt })}
          position="bottom"
          multiline
        >
          <SyncStatusContainer>
            <SyncLocallyIcon isSyncing={isSyncing} isDark={mode === MODES.wizard} />
            {text}
          </SyncStatusContainer>
        </Tooltip>
      )}
    </Fragment>
  )
}
