import styled, { css } from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'
import { Icon20 } from 'builder/components/Icon'
import Button, { ButtonSize } from 'builder/components/Button'

const discountVisualUrl = require('images/builder/account/cancel-subscription/discount-visual.svg')

export const ErrorMessage = styled.div`
  padding: 16px 20px;
  margin-bottom: 40px;
  border-radius: 3px;
  background-color: ${hexToRgba(Colors.Red50, 0.15)};
  color: ${Colors.Red50};
`

export const SectionContainer = styled.div`
  margin-bottom: 48px;

  ${Media.Phone`
    margin-bottom: 40px;
  `};

  &:last-child {
    margin-bottom: 0;
  }
`

export const SectionTitle = styled.div`
  margin-bottom: 24px;
  ${Typography.S};
  font-weight: 600;

  ${Media.Phone`
    margin-bottom: 16px;
  `};
`

export const Radio = styled.label<{ checked?: boolean }>`
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  ${Typography.Subhead};
  color: ${props => (props.checked ? Colors.Neutral90 : Colors.Neutral60)};
  transition: color 0.15s;
  cursor: pointer;

  ${Media.Phone`
    ${Typography.Body};
    margin-bottom: 12px;
  `};

  &:hover {
    color: ${Colors.Neutral90};
  }

  &:last-child {
    margin-bottom: 0;
  }
`

export const RadioControl = styled.span<{ checked?: boolean }>`
  position: relative;
  flex-shrink: 0;
  margin-top: 2px;
  width: 24px;
  height: 24px;
  border: 1px solid ${Colors.Neutral40};
  border-radius: 50%;
  transition: border-color 0.15s;

  ${Radio}:hover & {
    border-color: ${Colors.Blue50};
  }

  ${Media.Phone`
    margin-top: 0;
  `};

  ${props =>
    props.checked &&
    css`
      border-color: ${Colors.Blue50};
      border-width: 2px;
    `}

  &:before {
    content: '';
    position: absolute;
    top: calc(50% - 6px);
    left: calc(50% - 6px);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${Colors.Blue50};
    opacity: ${props => (props.checked ? 1 : 0)};
  }
`

export const RadioText = styled.span`
  margin-left: 16px;
  flex-grow: 1;
`

export const TextFieldWrapper = styled.div`
  padding: 8px 0;

  ${Media.Phone`
    padding: 4px 0 0;
  `};
`

export const Notice = styled.div<{ topic?: 'default' | 'discount' }>`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  padding: 20px;
  border-radius: 6px;
  background-color: ${props => (props.topic === 'discount' ? Colors.Indigo10 : Colors.Blue10)};

  ${props =>
    props.topic === 'discount' &&
    css`
      &:after {
        content: '';
        flex-shrink: 0;
        width: 160px;
        height: 108px;
        margin: 0 12px 12px 20px;
        background-image: url(${discountVisualUrl});
        background-size: contain;

        ${Media.Phone`
          display: none;
        `};
      }
    `};
`

export const NoticeContent = styled.div`
  flex-grow: 1;
`

export const NoticeTitle = styled.div`
  font-weight: 600;
`

export const NoticeDescription = styled.div`
  ${Typography.Caption};
  margin-bottom: 12px;
`

export const NoticeButton = styled(Button).attrs({ size: ButtonSize.small })``

export const NoticeUpgradeButton = styled(NoticeButton)`
  display: inline-flex;
  align-items: center;
  padding-left: 8px;
  background-color: ${props => (props.isDisabled ? Colors.Neutral60 : Colors.Indigo80)};

  &:hover,
  &:active {
    background-color: ${props => (props.isDisabled ? Colors.Neutral60 : Colors.Indigo90)};
  }
`

export const NoticeUpgradeButtonIcon = styled(Icon20.Crown)`
  margin-right: 4px;
`

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 48px;
  border-top: 1px solid ${Colors.Neutral15};

  ${Media.Phone`
    padding-top: 32px;
  `};
`

export const FooterText = styled.div`
  max-width: 160px;
  color: ${Colors.Neutral50};
`

export const FooterButtons = styled.div`
  display: flex;
  flex-shrink: 0;

  & ${Button} {
    margin-left: 16px;
  }
`
