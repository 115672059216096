import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography from 'builder/styles/typography'
import Icon, { Icon20 } from 'builder/components/Icon'
import { CircleButton } from 'builder/components/Button'
import { formatScrollBarStyles } from '../mixins'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: ${Colors.Neutral10};
`

export const Viewport = styled.div`
  ${formatScrollBarStyles()}
  overflow-y: auto;
  height: 100%;
`

export const Footer = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding: 20px 24px;
  background: ${Colors.White};
  justify-content: space-between;
  border-top: 1px solid ${Colors.Neutral10};

  ${Media.Phone`
    flex-direction: column;
    align-items: stretch;
    padding: 20px;

    & :first-child {
      margin-bottom: 12px;
    }
  `}
`

export const Rating = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 30%;
`

export const RatingStars = styled.div`
  margin-right: 16px;
`

export const Star = styled(Icon20.Star)<{ disabled?: boolean }>`
  width: 20px;
  height: 20px;
  fill: ${props => (props.disabled ? Colors.Neutral30 : Colors.Orange40)};
`

export const RatingNumber = styled.div`
  ${Typography.Tiny};
  text-align: center;
  line-height: 32px;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background: ${Colors.Indigo70};
  color: ${Colors.White};
  font-weight: 600;
`

export const Chevron = styled(Icon.Chevron)`
  fill: ${Colors.Blue50};
  transform: rotate(180deg);
  margin-right: 4px;
  width: 24px;
  height: 24px;
`

export const BackButton = styled.button`
  ${Typography.ButtonM}
  text-align: left;
  border: none;
  display: flex;
  align-items: center;
  background: transparent;
  padding: 0;
  font-weight: 600;
  color: ${Colors.Blue50};
  width: 30%;
  cursor: pointer;

  &:focus,
  &:hover {
    outline: none;
    color: ${Colors.Blue70};

    ${Chevron} {
      fill: ${Colors.Blue70};
    }
  }
`

export const Preview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 32px;

  ${Media.Phone`
    padding: 92px 40px 32px;
  `}
`

export const CategoryIcon = styled.div<{ url: string }>`
  width: 40px;
  height: 40px;
  margin-bottom: 4px;
  background: url(${props => props.url}) no-repeat center;
  background-size: 100%;
`

export const CategoryName = styled.div`
  ${Typography.Caps}
  letter-spacing: 1.8px;
  color: ${Colors.Blue50};
  margin-bottom: 8px;
  font-weight: 600;
`

export const JobTitle = styled.div`
  ${Typography.L}
  margin-bottom: 24px;
  font-weight: bold;

  ${Media.Phone`
    text-align: center;
  `}
`

export const Image = styled.div`
  width: 100%;
  max-width: 592px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;

  & img {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 141%;
  }
`

export const NavigationButton = styled(CircleButton)`
  position: absolute;
  bottom: calc(50% - 48px);

  ${Media.Phone`
    width: 40px;
    height: 40px;
    bottom: calc(50% - 40px);
  `}
`

export const PrevButton = styled(NavigationButton).attrs({
  direction: 'left',
})`
  left: 32px;

  ${Media.Tablet`
    left: 16px;
  `}

  ${Media.Phone`
    left: 20px;
  `}
`

export const NextButton = styled(NavigationButton)`
  right: 32px;

  ${Media.Tablet`
    right: 24px;
  `}

  ${Media.Phone`
    right: 32px;
  `}
`
