import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Icon20 } from 'builder/components/Icon'
import Colors from 'builder/styles/colors'

export const Container = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`

export const SessionsHeading = styled.div`
  ${FontWeights.DemiBold};
`

export const Feature = styled.div`
  display: flex;
  gap: 12px;
`

export const GreenTick = styled(Icon20.Tick)`
  color: ${Colors.Green50};
`

export const FeaturesText = styled.div`
  ${Typography.Caption};
`
