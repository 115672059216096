import EditorField from 'builder/components/EditorField'
import { Select } from 'builder/components/Select'
import { employmentTypeOptions } from '../../constants'
import { useWorkExperienceCardContext } from '../../ExpandableCardContext'
import { useShokumuTranslations } from '../../useShokumuTranslations'

const EmploymentType = (): JSX.Element => {
  const { item, onCardValueChange } = useWorkExperienceCardContext()
  const { i18n } = useShokumuTranslations()
  const label = i18n('work_experience.employment_details.type')

  const handleLevelSelect = (value: string) => {
    onCardValueChange({ employmentType: value }, false)
  }

  return (
    <EditorField>
      <Select
        label={label}
        selected={item.employmentType as string}
        onSelect={handleLevelSelect}
        options={employmentTypeOptions}
      />
    </EditorField>
  )
}
export default EmploymentType
