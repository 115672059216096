import { Optional } from 'packages/types'

export enum ReasonType {
  cancel = 'reason_to_cancel',
}

export enum Cards {
  JobOffer = 'jobOffer',
  Discount = 'discount',
  FeatureOrProblem = 'featureOrProblem',
  DreamJob = 'dreamJob',
  DontNeedPromos = 'dontNeedPromos',
  OtherReasons = 'otherReasons',
}

export enum FeedbackType {
  Feedback = Cards.FeatureOrProblem,
  OtherReason = Cards.OtherReasons,
}

export enum InternalEvents {
  visit = 'visit_subscription_cancellation',
  abort = 'abort_subscription_cancellation',
  pause = 'pause_subscription',
  cancel = 'cancel_subscription',
  choice = 'cancel_reason_choice',
  acceptPromo = 'promo_acceptance',
  viewPauseModal = 'see_subscription_pause_modal',
  closePauseModal = 'close_subscription_pause_modal',
}

export type SubmitPayload = Record<ReasonType, string>

export type MediaQueriesObject = {
  mediaQueries: {
    hover: boolean
    isPhone: boolean
    isTablet: boolean
    isLaptop: boolean
    isDesktop: boolean
  }
}

export type PromotionTopic = Optional<Cards | 'submitContent' | 'errorSubmit'>

export type ViewPortTypes = Pick<
  MediaQueriesObject['mediaQueries'],
  'isDesktop' | 'isPhone' | 'isTablet'
>
