import { Component, createRef, RefObject } from 'react'
import { ResumeSkill } from 'packages/types'
import { i18n } from 'builder/utils/i18n'

import AddCardButton from 'builder/components/AddCardButton'
import { SkillAssistant } from 'builder/components/SkillAssistant'
import { withConfig } from 'builder/components/Config'

import SectionTitle from 'builder/components/SectionTitle'
import { TunerSectionTooltips } from 'builder/components/Tuner'
import DialogModal from 'builder/components/DialogModal'
import { SectionNames } from 'builder/modules/resumeEditor'
import { TemplateName } from '../Helper/constants'
import ReferenceExtra from './ReferenceExtra'
import SkillsExtra from './SkillsExtra'

import { SectionHint } from './SectionHint'
import { SectionHeader } from './SectionHeader'
import { SectionContainer, SectionContent } from './styles'
import { SectionData } from './types'

type Props = {
  config: object
  item: SectionData
  isDragging?: boolean
  onRename: (options: { id: SectionData['id']; value: string | null }) => void
  onAdd?: (
    sectionId: SectionData['id'],
    options?: { position?: 'before' | 'after'; fields?: object },
  ) => void
  onDelete: (
    sectionId: SectionData['id'],
    options?: { sortId?: string; isCustom?: boolean },
  ) => void
  template?: string
  locale: string
  position?: string
  skills: ResumeSkill[]
  hideSkillLevel?: boolean
  updateSimpleField: (sectionId: string, cardId: string, values: object, debounce?: boolean) => void
  setContentOffset: (offset: number) => void
  triggerScroll?: boolean
}

class NewSection extends Component<Props> {
  state = {
    isModalOpen: false,
  }

  ref: RefObject<HTMLDivElement>

  constructor(props: Props) {
    super(props)
    this.ref = createRef()
  }

  componentDidUpdate(prevProps: Props) {
    const { triggerScroll, setContentOffset } = this.props

    if (triggerScroll && !prevProps.triggerScroll && this.ref.current) {
      setContentOffset(this.ref.current.offsetTop)
    }
  }

  handleAddCard = () => {
    const { item, onAdd } = this.props
    onAdd?.(item.id)
  }

  handleAddSkill = (skill: string) => {
    const { onAdd } = this.props
    const options = {
      position: 'before' as const,
      fields: {
        skill,
      },
    }
    onAdd?.('skills', options)
  }

  handleRename = (value: string | null) => {
    const { item, onRename } = this.props
    onRename({ id: item.id, value })
  }

  handleDelete = () => {
    this.setState({ isModalOpen: true })
  }

  handleModalConfirm = () => {
    this.setState({ isModalOpen: false })
    const { item, onDelete } = this.props
    onDelete(item.id, { sortId: item.sortId, isCustom: item.isCustom })
  }

  handleModalCancel = () => {
    this.setState({ isModalOpen: false })
  }

  render() {
    const {
      item,
      isDragging,
      onAdd,
      template,
      skills,
      locale,
      position,
      hideSkillLevel,
      updateSimpleField,
    } = this.props

    const hideExperienceLevel = template === TemplateName.Prague

    const {
      isCustom,
      id,
      title,
      customTitle,
      hintText,
      addButtonText,
      single,
      sortable,
      removable,
      renamable,
      items,
      badgeComponents,
    } = item

    const stringSectionId = `${id}`

    return (
      <SectionContainer
        isDragging={isDragging}
        ref={this.ref}
        className="content"
        value={stringSectionId}
      >
        <DialogModal
          title={i18n.t('builder.resume_editor.delete_section')}
          text={i18n.t('builder.resume_editor.sure_to_delete_section')}
          primaryButtonText={i18n.t('builder.resume_editor.delete')}
          secondaryButtonText={i18n.t('builder.resume_editor.cancel')}
          isOpen={this.state.isModalOpen}
          primaryAction={this.handleModalConfirm}
          secondaryAction={this.handleModalCancel}
          closeAction={this.handleModalCancel}
        />
        {(title || customTitle) && (
          <SectionHeader>
            <SectionTitle
              {...{
                id,
                title,
                customTitle,
                sortable,
                renamable,
                removable,
                isDragging,
                isCustom,
                badgeComponents,
                onRename: this.handleRename,
                onDelete: this.handleDelete,
              }}
            />
            {hintText && <SectionHint>{hintText}</SectionHint>}
            {id === SectionNames.references && <ReferenceExtra />}
            {id === SectionNames.skills && !!items?.length && !hideExperienceLevel && (
              <SkillsExtra hideSkillLevel={hideSkillLevel} updateSimpleField={updateSimpleField} />
            )}
            {id === SectionNames.skills && (
              <SkillAssistant
                onAddSkill={this.handleAddSkill}
                skills={skills}
                locale={locale}
                position={position}
              />
            )}
          </SectionHeader>
        )}
        <SectionContent isDragging={isDragging}>
          {this.props.children}
          {onAdd && !single && <AddCardButton onAdd={this.handleAddCard} text={addButtonText} />}
        </SectionContent>

        <TunerSectionTooltips sectionId={stringSectionId} />
      </SectionContainer>
    )
  }
}

export default withConfig(NewSection)
