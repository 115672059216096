import { ChangeEvent, KeyboardEventHandler, useRef } from 'react'
import { Transition } from 'react-transition-group'
import { i18n } from 'builder/utils/i18n'

import { TextField } from 'builder/components/TextField'
import { useClickOutside } from 'builder/hooks/useClickOutside'
import { isMonthHidden, isDateTooLate, isDateTooEarly } from './utils'

import PickerPanel from './PickerPanel'

import { Container, Row, FieldWrapper, Label, LabelContent, LabelHint } from './styles'
import { DateRangePickerProps } from './types'
import { useDateRangePicker } from './hooks/useDateRangePicker'

const DesktopPicker = (props: DateRangePickerProps) => {
  const {
    currentlyLabel,
    dateFromHighlightId,
    dateUntilHighlightId,
    errors: { startError, endError } = {
      startError: false,
      endError: false,
    },
  } = props

  const container = useRef<HTMLDivElement>(null)

  const {
    startMonth,
    maxStartMonth,
    startYear,
    maxStartYear,
    endMonth,
    maxEndMonth,
    maxEndYear,
    minEndMonth,
    minEndYear,
    endYear,
    endValue,
    startValue,
    showIsPresent,
    isPresentEndValue,
    handlePresentToggle,
    isStartDateHidden,
    isEndDateHidden,
    isStartPickerOpen,
    isEndPickerOpen,
    setIsStartPickerOpen,
    setIsEndPickerOpen,
    onEndDateSelect,
    onStartDateSelect,
    onStartInputBlur,
    onEndInputBlur,
    onEndInputValueChange,
    onStartInputValueChange,
    onStartYearChange,
    onEndYearChange,
  } = useDateRangePicker(props)

  useClickOutside(container, () => {
    setIsStartPickerOpen(false)
    setIsEndPickerOpen(false)
  })

  const handleEnter = () => {}

  const handleEntering = () => {}

  const handleExiting = () => {}

  const openStartPicker = () => {
    setIsStartPickerOpen(true)
    setIsEndPickerOpen(false)
  }

  const openEndPicker = () => {
    setIsStartPickerOpen(false)
    setIsEndPickerOpen(true)
  }

  const closeStartPicker = () => setIsStartPickerOpen(false)

  const closeEndPicker = () => setIsEndPickerOpen(false)

  const handleStartYearChange = (newStartYear: number) => {
    if (
      startMonth &&
      maxStartMonth &&
      maxStartYear &&
      isDateTooLate(startMonth, newStartYear, maxStartMonth, maxStartYear)
    ) {
      onStartYearChange(maxStartMonth, maxStartYear)
    } else {
      onStartYearChange(startMonth, newStartYear)
    }
  }

  const handleEndYearChange = (newEndYear: number) => {
    if (
      endMonth &&
      maxEndMonth &&
      maxEndYear &&
      isDateTooLate(endMonth, newEndYear, maxEndMonth, maxEndYear)
    ) {
      onEndYearChange(maxEndMonth, maxEndYear)
    } else if (
      minEndMonth &&
      minEndYear &&
      isDateTooEarly(endMonth, newEndYear, minEndMonth, minEndYear)
    ) {
      onEndYearChange(minEndMonth, minEndYear)
    } else {
      onEndYearChange(endMonth, newEndYear)
    }
  }

  const handleStartInputChange = (e: ChangeEvent) => {
    const newValue = (e.target as HTMLInputElement)?.value
    onStartInputValueChange(newValue)
  }

  const handleEndInputChange = (e: ChangeEvent) => {
    const newValue = (e.target as HTMLInputElement)?.value
    onEndInputValueChange(newValue)
  }

  const handleStartChoose = (month: number | null, year: number, shouldClosePanel = true) => {
    onStartDateSelect(month, year, shouldClosePanel)
  }

  const handleEndChoose = (month: number | null, year: number, shouldClosePanel = true) => {
    onEndDateSelect(month, year, shouldClosePanel)
  }

  const handleStartInputBlur = () => {
    onStartInputBlur()
  }

  const handleEndInputBlur = () => {
    onEndInputBlur()
  }

  const handleTab = () => {
    if (isEndPickerOpen) closeEndPicker()
    if (isStartPickerOpen) closeStartPicker()
  }

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = e => {
    let keyCode = e.keyCode

    // tab
    if (keyCode === 9) {
      handleTab()
    }
  }

  return (
    <Container onKeyDown={handleKeyDown} ref={container}>
      <Label>
        <LabelContent $hasError={startError || endError}>
          {i18n.t('builder.date_range_picker.title')}
        </LabelContent>
        <LabelHint multiline>{i18n.t('builder.date_range_picker.hint')}</LabelHint>
      </Label>
      <Row>
        <FieldWrapper>
          <TextField
            name="startDate"
            onFocus={openStartPicker}
            onBlur={handleStartInputBlur}
            onChange={handleStartInputChange}
            value={startValue || ''}
            active={isStartPickerOpen}
            placeholder={i18n.t('builder.date_range_picker.placeholder')}
            autoComplete="off"
            errorNoMsg={startError}
            data-highlight-id={dateFromHighlightId}
            data-testid="start-date-input"
          />
        </FieldWrapper>
        <FieldWrapper>
          <TextField
            name="endDate"
            onFocus={openEndPicker}
            onBlur={handleEndInputBlur}
            onChange={handleEndInputChange}
            value={endValue || ''}
            active={isEndPickerOpen}
            placeholder={i18n.t('builder.date_range_picker.placeholder')}
            autoComplete="off"
            errorNoMsg={endError}
            data-highlight-id={dateUntilHighlightId}
            data-testid="end-date-input"
          />
        </FieldWrapper>
      </Row>

      <Transition
        in={isStartPickerOpen}
        timeout={0}
        onEnter={handleEnter}
        onEntering={handleEntering}
        onExiting={handleExiting}
        unmountOnExit
        appear
      >
        <PickerPanel
          month={startMonth}
          year={startYear}
          maxMonth={maxStartMonth}
          maxYear={maxStartYear}
          onChangeYear={handleStartYearChange}
          onChoose={handleStartChoose}
          noActiveMonth={isStartDateHidden || isMonthHidden(startValue)}
          noActiveYear={isStartDateHidden}
          testId="start-date-picker-panel"
        />
      </Transition>

      <Transition
        in={isEndPickerOpen}
        timeout={0}
        onEnter={handleEnter}
        onEntering={handleEntering}
        onExiting={handleExiting}
        unmountOnExit
        appear
      >
        <PickerPanel
          month={endMonth}
          year={endYear}
          minMonth={minEndMonth}
          minYear={minEndYear}
          maxMonth={maxEndMonth}
          maxYear={maxEndYear}
          onChangeYear={handleEndYearChange}
          onChoose={handleEndChoose}
          showIsPresent={showIsPresent}
          isPresent={isPresentEndValue}
          onIsPresentToggle={handlePresentToggle}
          alignRight
          currentlyLabel={currentlyLabel}
          noActiveMonth={isEndDateHidden || isMonthHidden(endValue)}
          noActiveYear={isEndDateHidden}
          testId="end-date-picker-panel"
        />
      </Transition>
    </Container>
  )
}

export default DesktopPicker
