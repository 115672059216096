import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useUser } from 'builder/hooks/useUser'
import { useI18n } from 'builder/hooks/useI18n'
import { ButtonTheme } from 'builder/components/Button'
import { actions, selectors } from 'builder/modules/careerProfile'
import { PreFillModal as ResumeSelect } from 'builder/components/CareerPath2Skills/components/PreFillModal'
import {
  Close,
  CloseIcon,
} from 'builder/components/CareerPath2Skills/components/PreFillModal/styles'
import { useDocuments } from 'builder/components/MergeDocumentsModal/hooks'
import { selectors as userSelectors } from 'builder/modules/user'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { ResumeValidationStatus } from 'builder/modules/constants'
import {
  ResumeOptionButton,
  ResumeOptionsContainer,
  SelectedResumeContainer,
  OptionIcon,
  UserAvatar,
  UploadContainer,
  FilenameWrapper,
  ChatBotAvatarContainer,
  MessageSeparator,
} from './styles'
import Document from './ResumeSelector/Document'
import MenuIcon from './assets/admin-menu-icon.svg'
import UploadIcon from './assets/upload_file.svg'
import { MessageLine } from './MessageLine'
import DocumentIcon from './assets/document_icon.svg'
import OptionsView from './ResumeUpload/OptionsView'
import UploadResume from './ResumeUpload/UploadResume'
import ChatBotAvatar from './assets/chatbot_avatar.svg'

const ModalHeader = ({ onClose }: { onClose: () => void }) => {
  return (
    <>
      <Close onClick={onClose}>
        <CloseIcon />
      </Close>
    </>
  )
}

export const ChatOutro = ({ hideAvatar = false }: { hideAvatar?: boolean }) => {
  const user = useUser()
  const photoUrl = useTypedSelector(userSelectors.photoUrl)
  const selectedResume = useSelector(selectors.selectedResume)
  const latestResume = useSelector(selectors.latestResume)
  const { i18n } = useI18n()
  const [isSelectModalOpen, setSelectModalOpen] = useState<boolean>(false)
  const [isUploadExpanded, setUploadExpanded] = useState<boolean>(false)

  const dispatch = useDispatch()
  const { resumes } = useDocuments()

  const { resumeValidationStatus, error: parseError } = useTypedSelector(
    state => state.resumeEditor.prefillResumeSection,
  )

  const resumeUploadingNotStarted = resumeValidationStatus === ResumeValidationStatus.notStarted
  const uploadFailed = resumeValidationStatus === ResumeValidationStatus.failed || parseError

  const selectedResumeText = useSelector(selectors.selectedResumeText)
  const uploadedFilename = useSelector(selectors.uploadedFilename)

  useEffect(() => {
    // on mount after fetching resumes, pre-select the last if no resume selected yet.
    if (!latestResume && resumes.length) {
      dispatch(actions.setLatestResume(resumes[resumes.length - 1]))
    }
  }, [resumes])

  useEffect(() => {
    if (latestResume || selectedResume) {
      setSelectModalOpen(false)
    }
  }, [latestResume, selectedResume])

  if ((uploadedFilename && selectedResumeText) || selectedResume) {
    const resumeDesc = uploadedFilename || selectedResume?.name || `${user?.firstName} Resume`

    return (
      <>
        <MessageLine
          type="asked"
          avatar={<ChatBotAvatarContainer src={ChatBotAvatar} alt="chatbot avatar" />}
          last={!hideAvatar}
          content={<>{i18n.t('builder.career_profile.chat.outro.has_resume')}</>}
        />

        <MessageLine
          type="answer"
          avatar={<UserAvatar src={photoUrl} />}
          last={true}
          content={
            <FilenameWrapper>
              <img src={DocumentIcon} style={{ marginRight: '8px' }} /> <span>{resumeDesc}</span>
            </FilenameWrapper>
          }
        />
      </>
    )
  }

  return (
    <>
      <MessageLine
        type="asked"
        avatar={<ChatBotAvatarContainer src={ChatBotAvatar} alt="chatbot avatar" />}
        last={true}
        content={<>{i18n.t('builder.career_profile.chat.outro.has_resume')}</>}
      />

      <MessageSeparator />
      <MessageSeparator />

      <ResumeOptionsContainer>
        {latestResume && (!isUploadExpanded || uploadFailed) ? (
          <SelectedResumeContainer
            onClick={() => {
              dispatch(actions.setSelectedResume(latestResume))
              trackInternalEvent('click_last_resume', {
                label: 'career_assessment',
              })
            }}
          >
            <Document
              document={latestResume}
              isPostPremium={true}
              isFirstDocumentFree={true}
              miniature
            />
          </SelectedResumeContainer>
        ) : null}

        {resumes.length > 1 && (!isUploadExpanded || uploadFailed) && (
          <ResumeOptionButton
            theme={ButtonTheme.ghost}
            onClick={() => {
              setSelectModalOpen(true)
              trackInternalEvent('click_select_resume_from_list', {
                label: 'career_assessment',
              })
            }}
          >
            <OptionIcon src={MenuIcon} />
            {i18n.t('builder.career_profile.chat.options.select')}
          </ResumeOptionButton>
        )}

        {(!isUploadExpanded || uploadFailed) && (
          <ResumeOptionButton
            theme={ButtonTheme.ghost}
            onClick={() => {
              setUploadExpanded(true)
              trackInternalEvent('click_upload_resume', {
                label: 'career_assessment',
              })
            }}
          >
            <OptionIcon src={UploadIcon} />
            {i18n.t('builder.career_profile.chat.options.upload')}
          </ResumeOptionButton>
        )}

        {isUploadExpanded && (
          <UploadContainer>
            {resumeUploadingNotStarted ? (
              <OptionsView />
            ) : (
              <UploadResume
                onSubmit={file => {
                  dispatch(actions.parseResume(file))
                }}
              />
            )}
          </UploadContainer>
        )}

        {/* Disabled because we dont need it in first version */}
        {/* {(!isUploadExpanded || uploadFailed) && (
          <ResumeOptionButton
            theme={ButtonTheme.ghost}
            onClick={() => {
              trackInternalEvent('click_create_new_resume', {
                label: 'career_assessment',
              })
            }}
          >
            <OptionIcon src={PlusIcon} />
            {i18n.t('builder.career_profile.chat.options.create')}
          </ResumeOptionButton>
        )} */}
      </ResumeOptionsContainer>

      {isSelectModalOpen ? (
        <ResumeSelect
          footer={<></>}
          header={<ModalHeader onClose={() => setSelectModalOpen(false)} />}
          onSelect={resume => {
            dispatch(actions.setSelectedResume(resume))
            setSelectModalOpen(false)
          }}
        />
      ) : null}
    </>
  )
}
