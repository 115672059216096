import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Checkbox from 'builder/components/Checkbox'
import Shadows from 'builder/styles/shadows'

export const Container = styled.div`
  background-color: ${Colors.White};
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  align-items: flex-start;
`

export const Content = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
`

export const Header = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};
  text-align: center;
`

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  width: 100%;
`

export const AvailableTimeCheckbox = styled(Checkbox)`
  input[type='checkbox'] {
    border-color: ${Colors.Neutral30};
    transition: border ease-in 0.2s;

    &:checked {
      border-color: ${Colors.Blue50};
    }
  }
`

export const Option = styled.label<{ checked: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: ${props => (props.checked ? `8px 15px` : `9px 16px`)};
  border: ${props =>
    props.checked ? `2px solid ${Colors.Blue50}` : `1px solid ${Colors.Neutral15}`};
  border-radius: 8px;
  width: 100%;
  cursor: pointer;
  transition: shadow ease-in 0.3s;

  &:hover {
    ${Shadows.lightWithBorder.low};

    ${AvailableTimeCheckbox} {
      input[type='checkbox'] {
        border-color: ${Colors.Blue50};
      }
    }
  }
`

export const Service = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const label = styled.div`
  height: 44px;
`

export const TimeText = styled.div``

export const RangeText = styled.div`
  color: ${Colors.Neutral50};
`
