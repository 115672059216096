import { BaseSyntheticEvent, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useAppSelector } from 'builder/hooks/useAppSelector'
import { actions } from 'builder/modules/resumeEditor'
import { FetchStatuses } from 'builder/modules/constants'
import { i18n as I18n } from 'builder/utils/i18n'
import Button from 'builder/components/Button'
import { TextField, TextAreaField } from 'builder/components/TextField'
import {
  Container,
  TopWrapper,
  FlexBox,
  Title,
  CloseIcon,
  Content,
  Actions,
  Revert,
  TryAnotherLink,
  LeftAlign,
} from './styles'

type JobPostingFieldErrors = {
  companyName: boolean
  jobTitle: boolean
  role: boolean
}

interface ManualInputProps {
  onClose: () => void
}

const ManualInput = ({ onClose }: ManualInputProps) => {
  const {
    resume,
    optimizerSection: { fetchScoreStatus, manualInput },
  } = useAppSelector(store => store.resumeEditor)

  const dispatch = useDispatch()

  const [errors, setErrors] = useState<JobPostingFieldErrors>({
    companyName: false,
    jobTitle: false,
    role: false,
  })

  useEffect(() => {
    trackInternalEvent('see_resume_optimizer_error_screen', { label: 'with_manual_input' })
  }, [])
  const isLoading = fetchScoreStatus === FetchStatuses.loading

  const EmptyError = <LeftAlign>{I18n.t('builder.ai_resume_draft.linkedin.link_empty')}</LeftAlign>

  const onFieldChange = (event: BaseSyntheticEvent) => {
    const { name, value } = event.target
    dispatch(actions.setResumeOptimizerManualInput(event.target))

    if (value && errors[name as keyof JobPostingFieldErrors]) {
      setErrors({
        ...errors,
        [name]: false,
      })
    }
  }

  const onTryAgain = () => {
    trackInternalEvent('click_try_again_resume_optimizer_error_screen', {
      label: 'with_manual_input',
    })
    dispatch(actions.setShowManualInputScreen(false))
  }

  const onContinue = () => {
    const { companyName, jobTitle, role } = manualInput
    if (!companyName || !jobTitle || !role) {
      setErrors({
        companyName: !companyName,
        jobTitle: !jobTitle,
        role: !role,
      })
    } else {
      trackInternalEvent('click_continue_resume_optimizer_error_screen')
      dispatch(
        actions.checkResumeScore({
          jobPostingManualInput: {
            companyName,
            jobTitle,
            description: role,
          },
          resumeId: resume?.id,
        }),
      )
    }
  }
  return (
    <Container>
      <TopWrapper>
        <FlexBox>
          <Title>{I18n.t('builder.resume_optimizer.job_posting.no_match.title')}</Title>
          <CloseIcon onClick={onClose} />
        </FlexBox>
        <Content>{I18n.t('builder.resume_optimizer.job_posting.no_match.content')}</Content>
        <TextField
          value={manualInput.companyName}
          name="companyName"
          onChange={onFieldChange}
          placeholder={I18n.t('builder.resume_optimizer.job_posting.no_match.company_name')}
          error={errors.companyName && EmptyError}
        />
        <TextField
          value={manualInput.jobTitle}
          name="jobTitle"
          onChange={onFieldChange}
          placeholder={I18n.t('builder.resume_optimizer.job_posting.no_match.job_title')}
          error={errors.jobTitle && EmptyError}
        />
        <TextAreaField
          value={manualInput.role}
          name="role"
          onChange={onFieldChange}
          rows={6}
          placeholder={I18n.t('builder.resume_optimizer.job_posting.no_match.role_description')}
          error={errors.role && EmptyError}
        />
      </TopWrapper>
      <Actions>
        <TryAnotherLink onClick={onTryAgain}>
          <Revert />
          {I18n.t('builder.resume_optimizer.job_posting.no_match.try_another_link')}
        </TryAnotherLink>
        <Button onClick={onContinue} isLoading={isLoading} isDisabled={isLoading}>
          {I18n.t('builder.resume_optimizer.job_posting.continue')}
        </Button>
      </Actions>
    </Container>
  )
}

export default ManualInput
