import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Icon24 from 'builder/components/Icon'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Title = styled.div`
  ${Typography.S};
  ${FontWeights.Medium};
`

export const Link = styled.div`
  display: flex;
  align-items: center;
  color: ${Colors.Blue50};
  cursor: pointer;
`

export const ExploreIcon = styled(Icon24.Chevron)`
  color: ${Colors.Blue50};
`
