import PropTypes from 'prop-types'

import {
  SelectPlaceholderWrapper,
  SelectPlaceholderLabel,
  SelectPlaceholderContainer,
  SelectPlaceholderText,
  SelectPlaceholderButton,
} from './styles'

const SelectPlaceholder = ({ label, text, buttonText, onButtonClick }) => (
  <SelectPlaceholderWrapper>
    <SelectPlaceholderLabel>{label}</SelectPlaceholderLabel>
    <SelectPlaceholderContainer>
      <SelectPlaceholderText>{text}</SelectPlaceholderText>
      <SelectPlaceholderButton onClick={onButtonClick}>{buttonText}</SelectPlaceholderButton>
    </SelectPlaceholderContainer>
  </SelectPlaceholderWrapper>
)

SelectPlaceholder.propTypes = {
  label: PropTypes.string,
  text: PropTypes.string,
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func,
}

export default SelectPlaceholder
