import times from 'lodash/times'
import {
  Container,
  SkeletonCircle,
  SkeletonCompany,
  SkeletonCompanyName,
  SkeletonCompanyLine,
  SkeletonCompanyPositions,
} from './styles'

export const SkeletonCompanies = () => (
  <Container>
    {times(5).map(key => (
      <SkeletonCompanyLine key={key}>
        <SkeletonCompany>
          <SkeletonCircle />
          <SkeletonCompanyName />
        </SkeletonCompany>
        <SkeletonCompanyPositions />
      </SkeletonCompanyLine>
    ))}
  </Container>
)
