import styled, { keyframes } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Icon24 } from 'builder/components/Icon'
import Button from 'builder/components/Button'
import Media from 'builder/styles/media'

const moveUp = keyframes`
  from {
    transform: translateY(70%);
  }
  to {
    transform: translateY(0%);
  }
`

export const ErrorContiner = styled.div`
  width: 544px;
  border-radius: 16px;
  background: ${Colors.White};
  border: 8px;
  padding: 32px;
  animation: ${moveUp} 0.5s ease-out forwards;

  ${Media.Phone`
    width: 343px;
    padding:16px
  `};
`

export const ErrorTitle = styled.div`
  width: 436px;
  margin-right: 16px;
  /* Desktop and Tablet/S • DemiBold */
  ${Typography.S}
  ${FontWeights.DemiBold};
`

export const ErrorCloseIcon = styled(Icon24.Add)`
  background: ${Colors.Neutral10};
  fill: ${Colors.Neutral70};
  border-radius: 50%;
  height: 28px;
  width: 28px;
  cursor: pointer;
  transform: rotate(225deg);

  &:hover {
    background: ${Colors.Blue50};
    fill: ${Colors.White};
  }

  ${Media.Phone`
    height: 23px;
    width: 35px;
  `}
`

export const ErrorContent = styled.div`
  margin-top: 12px;
  color: ${Colors.Neutral50};
  /* Desktop and Tablet/Body • Regular */
  ${Typography.Body}
  ${FontWeights.Regular};
  ${Media.Phone`
    margin-top: 8px;
  `}
`

export const ErrorTryAgainButton = styled(Button)`
  margin-top: 32px;
  float: right;

  ${Media.Phone`
    margin-top: 20px;
  `}
`

export const TopWrapper = styled.div`
  display: flex;
`
