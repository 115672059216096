import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Button from 'builder/components/Button'
import Media from 'builder/styles/media'

export const Title = styled.div`
  ${Typography.Subhead}
  ${FontWeights.DemiBold}
  ${Media.Phone`
    ${Typography.S};
  `}
`

export const Content = styled.div`
  ${Typography.Caption}
  ${FontWeights.Regular};
  color: ${Colors.Neutral60};
`

export const ButtonWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  ${Media.Phone`
  margin-top: 20px;
  `}
`

export const GenerateCVButton = styled(Button)`
  color: ${Colors.White};
  background: ${Colors.Indigo50};
  cursor: pointer;
  float: right;
`
export const Star = styled.img`
  margin-right: 4px;
  height: 24px;
`
