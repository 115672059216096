import { Icon24 } from 'builder/components/Icon'
import { Action } from 'builder/components/ActionMenu'

export const MENU_ACTIONS: Action[] = [
  {
    text: 'Install extension',
    icon: <Icon24.Extension />,
    badge: 'free',
    onClick: () => alert('Hola!'),
  },
  {
    marked: true,
    icon: <Icon24.Combine />,
    text: 'Merge documents',
    onClick: () => alert('Hola!'),
  },
  {
    icon: <Icon24.Trash />,
    text: 'Remove document',
    onClick: () => alert('Hola!'),
  },
  {
    icon: <Icon24.Link />,
    text: 'Share a link',
    description: 'Send it to anyone! Get feedback or show your progress.',
    divider: true,
    onClick: () => alert('Hola!'),
  },
]
