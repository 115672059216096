import styled, { css } from 'styled-components'
import Media from 'builder/styles/media'
import { Typography, FontWeights } from 'builder/styles/typography'
import Button from 'builder/components/Button'
import { Icon20 } from 'builder/components/Icon'
import Tabs, { Tab } from 'builder/components/Tabs'

export const DocumentsHeader = styled.div<{ isNewDashHeader?: boolean }>`
  position: relative;
  margin-bottom: 48px;

  ${Media.Tablet`
    margin-bottom: 40px;
  `}

  ${Media.Phone`
    margin-bottom: 32px;
  `}

  ${props =>
    props.isNewDashHeader &&
    css`
      padding-top: 25px;
    `}
`

export const DocumentsTitle = styled.div<{
  isInternationalization?: boolean
}>`
  ${FontWeights.DemiBold};
  ${Typography.M}
  margin-bottom: 16px;
  margin-top: ${props => props.isInternationalization && 56}px;
  width: fit-content;
`

export const DocumentsTabs = styled(Tabs)``

export const DocumentsTab = styled(Tab)`
  padding-bottom: 8px;

  ${Media.Phone`
    width: 50%;
    margin-right: 0;
    text-align: center;
  `}
`

export const DocumentsHeaderButton = styled(Button)`
  ${Media.AbovePhone`
    position: absolute;
    top: 26px;
    right: 0;
    padding-left: 4px;
  `};

  ${Media.Phone`
    margin-top: 16px;
    width: 100%;
  `};
`

export const DocumentsHeaderButtonIcon = styled(Icon20.Add)`
  margin-right: 2px;
`
