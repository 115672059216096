import { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors, actions } from 'builder/modules/careerProfile'
import { useClickOutside } from 'builder/hooks/useClickOutside'

import { MobileDrawerMenu } from 'builder/features/navigation/components/MobileMenu/MobileMoreMenu/MobileMoreMenu'

import { Header } from '../Header/Header'
import { Container, MenuComponent } from './styles'
import { IProps } from './types'

const WIDTH = '236px'

export const ResumeSideMenu = ({ isOnline, builderType }: IProps) => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const [drawerMenu, setDrawerMenu] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const isMobileProfileMenuOpen = useTypedSelector(selectors.isMobileProfileMenuOpen)

  const handleMobileMenu = (state: boolean) => {
    setDrawerMenu(state)
    dispatch(actions.setMobileProfileMenu(state))
  }

  useClickOutside(containerRef, () => handleMobileMenu(false))

  return (
    <>
      <Header isOnline={isOnline} builderType={builderType} isOpen={isOpen} setOpen={setIsOpen} />
      {isOpen && (
        <Container>
          <MenuComponent isOpen={isOpen} width={WIDTH} />
        </Container>
      )}
      <MobileDrawerMenu
        ref={containerRef}
        open={drawerMenu || isMobileProfileMenuOpen}
        setOpen={handleMobileMenu}
      />
    </>
  )
}
