import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import { Typography, FontWeights } from 'builder/styles/typography'

export const Title = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};
  display: flex;
  align-items: center;

  ${Media.Tablet`
    ${Typography.S};
  `}
`

export const Value = styled.div`
  ${Typography.M};
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  &:hover {
    cursor: pointer;

    ${Title} {
      color: ${Colors.Blue50};
    }

    ${Value} {
      color: ${Colors.Blue50};
    }
  }

  ${Media.Tablet`
    &:hover {

      ${Title} {
        color: inherit;
      }

      ${Value} {
        color: inherit;
      }
    }
  `}
`
