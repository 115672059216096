import { Modifier, EditorState, EditorChangeType } from 'draft-js'

const insertSuggestion = (
  editorState: EditorState,
  text: string,
  startIndex: number,
  endIndex: number,
  blockKey: string,
) => {
  const currentSelectionState = editorState.getSelection()
  const suggestionTextSelection = currentSelectionState.merge({
    anchorKey: blockKey,
    anchorOffset: startIndex,
    focusOffset: endIndex,
    focusKey: blockKey,
  })

  let suggestionReplacedContent = Modifier.replaceText(
    editorState.getCurrentContent(),
    suggestionTextSelection,
    text,
    editorState.getCurrentInlineStyle(),
  )

  const newEditorState = EditorState.push(
    editorState,
    suggestionReplacedContent,
    'insert-spellcheck-suggestion' as EditorChangeType,
  )

  return newEditorState
}

export default insertSuggestion
