import { Store } from 'builder/modules/store'
import { SubscriptionStatus } from './types'

// ---
// Selectors
// ---
export const selectors = {
  isEdit: (state: Store) => !!state.resumeDistribution.isEdit,
  isResumeSelected: (state: Store) => !!state.resumeDistribution.selectedResumeDetails,
  selectedResumeDetails: (state: Store) => state.resumeDistribution.selectedResumeDetails,
  formResumeFetchDetails: (state: Store) => state.resumeDistribution.formResumeFetchDetails,
  isParsing: (state: Store) => state.resumeDistribution.isParsing,
  jobPreferencesFields: (state: Store) => state.resumeDistribution.form.jobPreferences,
  personalDetailsFields: (state: Store) => state.resumeDistribution.form.personalDetails,
  locationDetailsFields: (state: Store) => state.resumeDistribution.form.locationDetails,

  // Candidate
  candidate: (state: Store) => state.resumeDistribution.candidate,
  candidateFetchStatus: (state: Store) => state.resumeDistribution.candidateFetchStatus,
  candidatePostStatus: (state: Store) => state.resumeDistribution.candidatePostStatus,
  candidatePatchStatus: (state: Store) => state.resumeDistribution.candidatePatchStatus,
  candidateResumePostStatus: (state: Store) => state.resumeDistribution.candidateResumePostStatus,
  candidateResumeFailed: (state: Store) => state.resumeDistribution.candidateResumeFailed,
  serverFailed: (state: Store) => state.resumeDistribution.serverFailed,
  isSubscriptionActive: (state: Store) =>
    state.resumeDistribution.candidate?.subscriptions?.filter(
      subscription =>
        subscription.subscriptionStatus === SubscriptionStatus.active ||
        subscription.subscriptionStatus === SubscriptionStatus.paused,
    )?.length,

  // Distribution dates
  distributionDates: (state: Store) => state.resumeDistribution.distributionDates,
  distributionDatesFetchStatus: (state: Store) =>
    state.resumeDistribution.distributionDatesFetchStatus,

  // Recruiters
  recruiters: (state: Store, date: number) => state.resumeDistribution.recruiters[date],
  recruitersCount: (state: Store) => state.resumeDistribution.recruitersCount,
  recruitersFetchStatus: (state: Store, date: number) =>
    state.resumeDistribution.recruitersFetchStatus[date],
  distributedRecruiterCount: (state: Store) => state.resumeDistribution.distributedRecruiterCount,

  // Subscriptions
  subscriptionPostPauseStatus: (state: Store) =>
    state.resumeDistribution.subscriptionPostPauseStatus,
  subscriptionPostResumeStatus: (state: Store) =>
    state.resumeDistribution.subscriptionPostResumeStatus,
  subscriptionPostCreateStatus: (state: Store) =>
    state.resumeDistribution.subscriptionPostCreateStatus,
}
