import { memo, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { selectors as userSelectors, UserBillingStatus, UserType } from 'builder/modules/user'
import { useUser } from 'builder/hooks/useUser'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { FeatureCard } from 'builder/hooks/useFeatureCards'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useCurrentPageFeature } from 'builder/components/Navigation/hooks/useCurrentPageFeature'
import { useConfig } from 'builder/hooks/useConfig'
import CIO_LOGO_URL from 'images/countries/cio-logo.svg'
import { LOGO_ELEMENT_ID } from '../../constants'
import { ToggleCallback } from '../../types'
import { UserDropdown } from '../UserDropdown'
import { BurgerButton } from '../BurgerButton'
import { PremiumButton } from '../PremiumButton'
import { WelcomeModalButton } from '../WelcomeModalButton'
import { PremiumTracker } from '../PremiumTracker'
import { calculateDaysDifference } from '../PremiumTracker/utils'
import { UserDropdownWithCareerProfile } from '../UserDropdownWithCareerProfile'
import { Container, Content, LogoLink, LogoStyled, RightColumn, CustomLogo } from './styles'

interface Props {
  isMenuOpen: boolean
  isUserDropdownOpen: boolean
  isTransparent?: boolean
  onUserDropdownToggle: ToggleCallback
  onMenuToggle: ToggleCallback
  features: FeatureCard[]
}

const BarComponent = ({
  features,
  isMenuOpen,
  isUserDropdownOpen,
  isTransparent,
  onMenuToggle,
  onUserDropdownToggle,
}: Props) => {
  const config = useConfig()
  const user = useUser()
  const { isPhone } = useMediaQueries()
  const photoUrl = useTypedSelector(userSelectors.photoUrl)
  const currentPageFeature = useCurrentPageFeature(features)
  const isLogoHidden = currentPageFeature && isPhone
  const location = useLocation()
  const isCioApp = config?.features.superApp
  const isCareerProfileFeatureAvailable = config?.features.careerProfile

  const promoBanner = localStorage.getItem('promoBanner')

  const daysLeftPremium = useMemo(
    () =>
      user?.billingInfo?.premiumEndsAt
        ? calculateDaysDifference(user?.billingInfo?.premiumEndsAt) + 1
        : 0,
    [user?.billingInfo.premiumEndsAt],
  )

  const isUpsiderLoaderPage = useMemo(() => {
    return location.pathname?.includes('/upsider-loading')
  }, [location.pathname])

  const isDashboardPage = useMemo(() => {
    return location.pathname === '/'
  }, [location.pathname])

  const showPremiumTracker = useMemo(() => {
    return (
      daysLeftPremium <= 7 &&
      user?.billingStatus !== UserBillingStatus.subscription &&
      user?.billingInfo.userType &&
      [UserType.premium, UserType.postPremium].includes(user?.billingInfo.userType)
    )
  }, [daysLeftPremium, user])

  return (
    <Container isTransparent={isTransparent}>
      <Content>
        {!isLogoHidden && !isUpsiderLoaderPage && (
          <LogoLink
            aria-label="Logo"
            isMenuOpen={false}
            to={promoBanner === '1' ? '/?tr_promo_banner=1' : '/'}
          >
            <LogoStyled id={LOGO_ELEMENT_ID} variant="default" />
          </LogoLink>
        )}
        {!isUpsiderLoaderPage && (
          <BurgerButton
            feature={currentPageFeature}
            isActive={isMenuOpen}
            onClick={() => onMenuToggle(!isMenuOpen)}
          />
        )}
        {isUpsiderLoaderPage && (
          <LogoLink
            aria-label="Logo"
            isMenuOpen={false}
            to={promoBanner === '1' ? '/?tr_promo_banner=1' : '/'}
          >
            <CustomLogo src={CIO_LOGO_URL} />
          </LogoLink>
        )}
        {!isUpsiderLoaderPage && (
          <RightColumn isMenuOpen={false}>
            {!isPhone && isCioApp && isDashboardPage && <WelcomeModalButton />}
            {!user?.hasPremiumFeatures &&
              user?.billingInfo.userType !== UserType.postPremium &&
              !isPhone && <PremiumButton />}
            {showPremiumTracker && <PremiumTracker daysLeftPremium={daysLeftPremium} />}
            {user &&
              (!isCareerProfileFeatureAvailable ? (
                <UserDropdown
                  user={user}
                  photoUrl={photoUrl}
                  isOpen={isUserDropdownOpen}
                  onToggle={onUserDropdownToggle}
                />
              ) : (
                <UserDropdownWithCareerProfile
                  user={user}
                  photoUrl={photoUrl}
                  isOpen={isUserDropdownOpen}
                  onToggle={onUserDropdownToggle}
                />
              ))}
          </RightColumn>
        )}
      </Content>
    </Container>
  )
}

export const Bar = memo(BarComponent)
