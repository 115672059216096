import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Container = styled.div`
  pointer-events: all;
  box-shadow: 0px 8px 20px -4px rgba(15, 20, 30, 0.24);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  z-index: 5;

  ${Media.Phone`
    display: block;
  `}
`

export const Wrapper = styled.div`
  padding: 12px 16px;
  background-color: ${Colors.Neutral90};
  display: flex;
  gap: 8px;
  align-items: center;
`

export const IconImage = styled.img``

export const Text = styled.div`
  color: ${Colors.White};
  ${Typography.Caption};
`

export const OpenButton = styled.div`
  display: flex;
  padding: 14px 16px;
  background-color: ${Colors.Neutral90};
  gap: 12px;
  border-left: 1px solid ${Colors.Neutral70};
  ${Typography.Caption};
  font-weight: ${FontWeights.DemiBold};
  color: ${Colors.White};
  cursor: pointer;
  word-wrap: break-word;

  ${Media.Phone`
    padding: 0 16px 12px 0;
    border-left: 0;
    flex-direction: row-reverse;
  `}
`
