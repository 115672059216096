export enum ButtonSize {
  default = 'default',
  small = 'small',
}

export enum ButtonTheme {
  default = 'default',
  alternative = 'alternative',
  confirmative = 'confirmative',
  destructive = 'destructive',
  ghost = 'ghost',
  secondary = 'secondary',
}
