import styled from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'

export const Photo = styled.img`
  position: absolute;
  z-index: 2;
  left: 50%;
  bottom: 24px;
  transform: translateX(-50%);
  width: 154px;
  height: 154px;
  border-radius: 50%;
  background-color: ${Colors.White};
  border: 3px solid ${Colors.White};
`

export const IconCircle = styled.div`
  position: absolute;
  z-index: 3;
  bottom: 24px;
  left: calc(50% - 77px);
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${hexToRgba(Colors.Green20, 0.5)};
  box-shadow: 0px 8px 20px ${hexToRgba(Colors.Green50, 0.12)},
    inset 0 -1px 0 0 ${hexToRgba(Colors.Green70, 0.16)}, inset 0 1px 0 0 ${Colors.White};
  backdrop-filter: blur(8px);
  color: ${Colors.Green70};
`
