import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { useState, useEffect, useCallback } from 'react'
import { trackingInfo } from 'builder/modules/careerCoaching/constants'
import Coach from '../../../CareerCoachingSessions/components/SessionTabs/components/PlanNewSession/components/Coaches/components/Coach'
import { Container, Card, HeaderText, StyledButton, Cards, Buttons, CardsWrapper } from './styles'
import { COACHES } from './constants'

const CoachCardsAnimation = () => {
  const navigate = useNavigate()

  const [currentCardIndex, setCurrentCardIndex] = useState(0)

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentCardIndex(prevIndex => (prevIndex + 1) % COACHES.length)
    }, 3000)

    return () => clearTimeout(timer)
  }, [currentCardIndex])

  const handleClick = useCallback(() => {
    navigate(`/onboarding-form`)

    trackInternalEvent('click_find_my_coach_button', {
      ...trackingInfo,
      plan_name: 'coach_marketplace',
      button_place: 'footer',
    })
  }, [navigate])

  return (
    <Container>
      <HeaderText>Meet the coaches</HeaderText>
      <CardsWrapper>
        <Cards>
          {COACHES.map((coach, index) => (
            <Card
              key={index}
              $isActive={index === currentCardIndex}
              style={
                index === currentCardIndex
                  ? { zIndex: 2 }
                  : index !== 3 && index === currentCardIndex + 1
                  ? { zIndex: 1 }
                  : index === currentCardIndex - 1
                  ? { zIndex: 1 }
                  : { zIndex: 0 }
              }
            >
              <Coach key={coach.id} {...{ coach }} />
            </Card>
          ))}
        </Cards>
      </CardsWrapper>
      <Buttons>
        <StyledButton onClick={handleClick}>Find a Coach</StyledButton>
      </Buttons>
    </Container>
  )
}

export default CoachCardsAnimation
