import ErrorLogger from 'builder/services/ErrorLogger'

/**
 * Converts a File object to a base64 string.
 * @param {File} file - The File object to convert.
 * @returns {Promise<string>} A promise resolving to the base64 string.
 */
export const fileToBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = e => resolve(e.target?.result as string)
    reader.onerror = reject
  })

/**
 * Parses serialized form data string to FormData object.
 * @param {string | null} serializedFormData - The serialized form data string.
 * @returns {FormData | undefined} The parsed FormData object.
 */
export const parseFormData = (serializedFormData: string | null): FormData | undefined => {
  const formData = new FormData()
  if (!serializedFormData) return

  const parsedData = JSON.parse(serializedFormData)
  parsedData.forEach((item: [string, string | Blob]) => formData.append(item[0], item[1]))
  return formData
}

/**
 * Converts FormData object to a serialized JSON string.
 * @param {FormData} data - The FormData object to stringify.
 * @returns {string} The serialized JSON string.
 */
export const stringifyFormData = (data: FormData): string => {
  return JSON.stringify(Array.from(data.entries()))
}

/**
 * Converts a base64 string to a File object.
 * @param {string} base64 - The base64 string.
 * @param {string} fileName - The name of the file.
 * @returns {Promise<File | null>} promise resolving to the File object or null if an error occurs.
 */
export const base64ToFile = async (base64: string, fileName: string): Promise<File | null> => {
  try {
    const base64Parts = base64.split(',')
    const fileFormat = base64Parts[0]?.split(';')[0]?.split(':')[1]
    const res = await fetch(base64)
    const blob = await res.blob()
    return new File([blob], fileName, { type: fileFormat })
  } catch (err) {
    ErrorLogger.log(err)
    return null
  }
}
