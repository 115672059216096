import { useEffect, useContext } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import ModalOverlay from 'builder/components/ModalOverlay'
import { useI18n } from 'builder/hooks/useI18n'
import { SnackBar } from 'builder/components/SnackBarV2'
import { SnackBarTypes } from 'builder/components/SnackBarV2/types'
import { SnackBarContext } from 'builder/components/SnackBarV2/SnackBarContext'
import { FetchStatuses } from 'builder/modules/constants'
import { actions } from 'builder/modules/resumeEditor'
import { useOverviewModal } from './hooks/useOverviewModal'
import { Toolbar } from './components/Toolbar/Toolbar'
import { Container, Wrapper } from './styles'
import { TailoredResumeEdit } from './components/TailoredResumeEdit/TailoredResumeEdit'
import { DocumentPreview } from './components/DocumentPreview/DocumentPreview'
import { ApproveModal } from './components/ApproveModal/ApproveModal'

export const OverviewModal = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const {
    handleOpenApproveModal,
    resume,
    isApproveModalOpen,
    approveTailoredResumeStatus,
    isResumeLoading,
  } = useOverviewModal()
  const { openSnackBar } = useContext(SnackBarContext)
  const { i18n } = useI18n()

  useEffect(() => {
    if (!resume && !isResumeLoading) {
      const resumeId = Number(params.id)
      openSnackBar({
        text: i18n.t('builder.auto_tailoring.overview.error_load_resume.text'),
        type: SnackBarTypes.failure,
        action: {
          onClick: () => {
            dispatch(actions.fetchResumeRequest({ id: resumeId }))
          },
          text: i18n.t('builder.auto_tailoring.overview.error_load_resume.action_button_text'),
        },
      })
    }
  }, [
    approveTailoredResumeStatus,
    dispatch,
    i18n,
    isResumeLoading,
    openSnackBar,
    params.id,
    resume,
  ])

  useEffect(() => {
    if (approveTailoredResumeStatus === FetchStatuses.failed) {
      openSnackBar({
        text: i18n.t('builder.auto_tailoring.overview.save_error_text'),
        type: SnackBarTypes.failure,
        isDismissible: true,
      })
    }
  }, [approveTailoredResumeStatus, i18n, openSnackBar])

  useEffect(() => {
    trackInternalEvent('see_auto_tailoring_overview_screen')
  }, [])

  return (
    <ModalOverlay fullScreen onClose={handleOpenApproveModal}>
      <Container>
        <Toolbar />
        <Wrapper>
          <DocumentPreview />
          <TailoredResumeEdit />
        </Wrapper>
        {isApproveModalOpen && <ApproveModal />}
        <SnackBar />
      </Container>
    </ModalOverlay>
  )
}
