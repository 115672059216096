import { Component } from 'react'
import PropTypes from 'prop-types'
import { i18n as I18n } from 'builder/utils/i18n'
import Icon from 'builder/components/Icon'

import {
  Button,
  SocialItemContainer,
  SocialInfo,
  SocialImage,
  SocialName,
  SocialUpdating,
  UpdatingIcon,
} from './styles'

class SocialItem extends Component {
  static propTypes = {
    item: PropTypes.object,
    socialUpdating: PropTypes.object,
    onDisconnect: PropTypes.func,
  }

  handleDisconnectSocial = item => {
    this.props.onDisconnect(item.provider)
  }

  handleConnectSocial = item => {
    window.location.assign(item.connectionUrl)
  }

  render() {
    const { item, socialUpdating } = this.props
    const isHandlePresent = item.connected && item.handle

    const { icon, name } = {
      facebook: {
        icon: <Icon.Facebook />,
        name: I18n.t('builder.account.facebook'),
      },
      linkedin: {
        icon: <Icon.Linkedin />,
        name: I18n.t('builder.account.linkedin'),
      },
      google_oauth2: {
        icon: <Icon.Google />,
        name: I18n.t('builder.account.google'),
      },
    }[item.provider] || { icon: null, name: '' }

    return (
      <SocialItemContainer>
        <SocialInfo>
          <SocialImage>{icon}</SocialImage>

          <SocialName>
            {name}
            {isHandlePresent && ' • '}
            {isHandlePresent && (
              <a href={item.profileUrl} target="_blank" rel="noreferrer noopener">
                {item.handle}
              </a>
            )}
          </SocialName>
        </SocialInfo>

        {socialUpdating[item.provider] ? (
          <SocialUpdating>
            <UpdatingIcon />
            {I18n.t('builder.account.disconnect')}
          </SocialUpdating>
        ) : (
          <Button
            onClick={
              item.connected
                ? () => this.handleDisconnectSocial(item)
                : () => this.handleConnectSocial(item)
            }
          >
            {item.connected
              ? I18n.t('builder.account.disconnect')
              : I18n.t('builder.account.connect')}
          </Button>
        )}
      </SocialItemContainer>
    )
  }
}

export default SocialItem
