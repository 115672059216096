import styled from 'styled-components'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import EditorField from 'builder/components/EditorField'
import { Icon20 } from 'builder/components/Icon'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  padding-top: 24px;
  margin: 0;

  ${Media.Tablet`
    margin-bottom: 100px;
  `};

  ${Media.Phone`
    margin-bottom: 100px;
  `};
`

export const Content = styled.div`
  width: 1120px;
  margin: 0 auto;

  ${Media.Tablet`
    width: 100%;
  `};

  ${Media.Phone`
    width: 100%;
  `};
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${Media.AboveTablet`
    height: 560px;
    align-items: center;
    justify-content: space-between;
  `};

  ${Media.Tablet`
    height: 100%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 56px;
  `};

  ${Media.Phone`
    height: 100%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 48px;
  `};
`

export const Left = styled.div`
  width: 448px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  ${Media.Tablet`
    width: 504px;
  `};

  ${Media.Phone`
    width: 335px;
  `};
`

export const LeftTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

export const LeftBottom = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
`

export const Right = styled.div`
  width: 544px;
  height: 560px;
  border: none;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;

  ${Media.Tablet`
    width: 504px;
    height: auto;
  `};

  ${Media.Phone`
    width: 335px;
    height: auto;
  `};
`

export const CustomEditor = styled(EditorField)`
  margin: 0;
`

export const Title = styled.div`
  ${Typography.M};
  ${FontWeights.Medium};
  padding-top: 20px;
`
export const Title2 = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  color: ${Colors.Neutral50};
  ${FontWeights.DemiBold};
  letter-spacing: 1.8px;
  text-transform: uppercase;
`

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const Item = styled.div`
  display: flex;
  gap: 4px;
`

export const Tick = styled(Icon20.Tick)`
  color: ${Colors.Blue50};
`
export const Buttons = styled.div`
  display: flex;
  gap: 10px;

  ${Media.Phone`
    width: 335px;
    position: fixed;
    bottom: 70px;
    z-index: 10;
    background: ${Colors.White};
    flex-direction: column-reverse;
    padding-bottom: 24px;
  `};
`

export const VideoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
`

export const StyledVideo = styled.video`
  width: 100%;
  height: auto;
  border-radius: 16px;
`
