import { TooltipV2 } from 'builder/components/Tooltip'
import { useI18n } from 'builder/hooks/useI18n'
import { IconDefault, IconHovered, IconWrapper } from './styles'

interface Props {
  tooltipText?: string
}

const TRANSLATION = 'builder.resume_editor.keywords'

export const KeywordsInfoIcon = (props: Props) => {
  const { tooltipText } = props
  const { i18n } = useI18n()
  const text = tooltipText || i18n.t(`${TRANSLATION}.popup.tooltip`)

  return (
    <TooltipV2 value={text} width={242} multiline={true}>
      <IconWrapper>
        <IconDefault />
        <IconHovered />
      </IconWrapper>
    </TooltipV2>
  )
}
