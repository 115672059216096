import { memo } from 'react'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { GenerateAiProfileStatus } from 'builder/modules/constants'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import ModalOverlay from '../../ModalOverlay'
import ProcessAnimation from './ProcessAnimation'
import GenerationFailed from './GenerationFailed'
import { Props } from './types'

const AiAssistantProcessing = (props: Props) => {
  const { isPhone } = useMediaQueries()
  const { generateAiProfileStatus } = useTypedSelector(store => store.resumeEditor.aiProfile)
  if (
    generateAiProfileStatus !== GenerateAiProfileStatus.failed &&
    generateAiProfileStatus !== GenerateAiProfileStatus.processing
  ) {
    return null
  }
  return (
    <ModalOverlay overlayFadeDuration={300} contentSlideDuration={300} fullScreen={isPhone}>
      {generateAiProfileStatus === GenerateAiProfileStatus.failed && (
        <GenerationFailed {...props} />
      )}
      {generateAiProfileStatus === GenerateAiProfileStatus.processing && <ProcessAnimation />}
    </ModalOverlay>
  )
}

export default memo(AiAssistantProcessing)
