import { selectors as userSelectors } from 'builder/modules/user'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { usePitchTextParts } from '../../../hooks/usePitchTextParts'
import * as Styled from './styles'

export const ProfessionalPitchPreview = () => {
  const photoUrl = useTypedSelector(userSelectors.photoUrl)
  const pitchText = usePitchTextParts()
  return pitchText ? (
    <Styled.Container>
      <Styled.Content>
        <Styled.Avatar src={photoUrl} alt="User Avatar" />

        <Styled.MessageContainer>
          <Styled.Message>{pitchText}</Styled.Message>
        </Styled.MessageContainer>
      </Styled.Content>
    </Styled.Container>
  ) : null
}
