import { useEffect, useState, useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import { actions as userActions, selectors as userSelectors } from 'builder/modules/user'
import { selectors, actions } from 'builder/modules/panel'
import { useConfig } from 'builder/hooks/useConfig'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import MobileTabs from 'builder/components/CIO-Dasboard-2.0/Components/MobileTabs'
import TabletTabs from 'builder/components/CIO-Dasboard-2.0/Components/TabletTabs'
import MainBanner from 'builder/components/CIO-Dasboard-2.0/Components/MainBanner/MainBanner'
import { WelcomeModal } from 'builder/components/CIO-Dasboard-2.0/Components/WelcomeModal/WelcomeModal'
import Tabs from 'builder/components/CIO-Dasboard-2.0/Components/Tabs'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { useCareerServiceTitle } from 'builder/components/Panel/CareerGoal/hooks/useCareerServiceTitle'
import { EDashboardLocalStoreKeys } from '../types'
import useWebsiteHost from '../hooks/useWebsiteHost'
import JobIconLayout from '../Components/JobIconLayout'
import { Container } from './styles'

export const FIND_NEW_JOB = 'Find a New Job'
export const EXCEL_AT_JOB = 'Excel at Your Job'
export const CHANGE_CAREER = 'Change Career'

export type NavigationTab = typeof FIND_NEW_JOB | typeof EXCEL_AT_JOB | typeof CHANGE_CAREER

export const TABS_INDEXES = (
  t?: (key: string) => string,
): Record<NavigationTab, { text: string; index: number }> => ({
  [FIND_NEW_JOB]: {
    text: t?.('builder.dashboard.banner_action.find_new_job') || '',
    index: 0,
  },
  [EXCEL_AT_JOB]: {
    text: t?.('builder.dashboard.banner_action.excel_at_your_job') || '',
    index: 1,
  },
  [CHANGE_CAREER]: {
    text: t?.('builder.dashboard.banner_action.change_career') || '',
    index: 2,
  },
})

export const INDEXES_TABS: Record<number, NavigationTab> = {
  0: FIND_NEW_JOB,
  1: EXCEL_AT_JOB,
  2: CHANGE_CAREER,
}

const ActionBanner = () => {
  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState<boolean>(false)
  const [isCioRedirectOpen, setIsCioRedirectOpen] = useState<boolean>(false)
  const [isPVRedirect, setIsPVRedirect] = useState(false)
  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const isUserLoggingForFirstTime = useTypedSelector(userSelectors.isUserLoggingFirstTime)
  const selectedIndex = useTypedSelector(selectors.activeTabId)
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const { getHost } = useWebsiteHost()
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const paymentSuccessStatus = urlParams.get('paymentSuccess')
  const config = useConfig()
  const isCioDashBoard = config?.features.superApp
  const isRioDashBoard = config?.features.international
  const isCareerProfileEnabled = config?.features.careerProfile
  const { features } = useFeaturesConfig()
  const tab = useCareerServiceTitle({ index: selectedIndex })

  const handleSelectedIndexChange = useCallback(
    (selectedIndex: number) => {
      trackInternalEvent('click_dash_tab', {
        label: tab?.name,
        website_host: getHost,
      })
      dispatch(actions.setActiveTabID({ tabId: selectedIndex }))
    },
    [dispatch, getHost, tab?.name],
  )

  const handleTabClick = useCallback(
    (index: number) => {
      localStorage.setItem(EDashboardLocalStoreKeys.ACTIVE_TAB, JSON.stringify(index))
      handleSelectedIndexChange(index)
      setIsMenuVisible(false)
    },
    [handleSelectedIndexChange],
  )

  const tabsActions = useMemo(
    () => [
      TABS_INDEXES(i18n.t)[FIND_NEW_JOB],
      TABS_INDEXES(i18n.t)[EXCEL_AT_JOB],
      TABS_INDEXES(i18n.t)[CHANGE_CAREER],
    ],
    [i18n],
  )

  const isCheckedFirstLogin = localStorage.getItem('IS_CHECKED_FIRST_LOGIN')
  const isWelcomeModalShown = localStorage.getItem(EDashboardLocalStoreKeys.IS_WELCOME_MODAL_SHOWN)
  const isUserPVRedirect = localStorage.getItem(EDashboardLocalStoreKeys.IS_PV_REDIRECT)

  useEffectOnMount(() => {
    setIsPVRedirect(!!isUserPVRedirect)
    if (!isCheckedFirstLogin && !isWelcomeModalShown) {
      dispatch(userActions.fetchFirstTimeLoginStatus())
    }
    if (!features.dashboardNavigationTabs) {
      handleTabClick(0)
    }
  })

  useEffect(() => {
    if (
      !isCheckedFirstLogin &&
      !isWelcomeModalShown &&
      isUserLoggingForFirstTime &&
      isCioDashBoard &&
      !isCareerProfileEnabled
    ) {
      setIsWelcomeModalOpen(true)
      setIsCioRedirectOpen(false)
      isPVRedirect
        ? trackInternalEvent('see_cio_event_welcome_modal')
        : trackInternalEvent('see_cio_welcome_modal', {
            website_host: getHost,
          })
    }
  }, [
    dispatch,
    getHost,
    isCheckedFirstLogin,
    isCioDashBoard,
    isPVRedirect,
    isRioDashBoard,
    isUserLoggingForFirstTime,
    isWelcomeModalShown,
    paymentSuccessStatus,
    isCareerProfileEnabled,
  ])

  const activeTabTitle = tabsActions.find(tab => tab.index === selectedIndex)?.text || ''

  const tabsProps = {
    activeTabTitle,
    isMenuVisible,
    setIsMenuVisible,
    tabsActions,
    selectedIndex,
    handleTabClick,
  }

  // For RIO if AB Test is ON, then only we show MiniApps
  // While in Case of CIO we show MiniApps for all
  const renderMiniApps = () => {
    if (selectedIndex !== 0) return

    if ((features.dashboardV2 && !features.dashboardV3) || isRioDashBoard || isCioDashBoard) {
      return <JobIconLayout />
    }
    return <></>
  }

  return (
    <Container>
      <WelcomeModal
        setIsWelcomeModalOpen={setIsWelcomeModalOpen}
        isWelcomeModalOpen={isWelcomeModalOpen}
        getHost={getHost}
        isCioRedirectOpen={isCioRedirectOpen}
        isPVRedirect={isPVRedirect}
        title={
          isPVRedirect
            ? 'You have registered for the event and got access to the world’s first complete job search and career platform!'
            : ''
        }
        subtitle={
          isPVRedirect
            ? 'There is everything you need to manage each step in your career all in one place.'
            : ''
        }
      />
      {features.dashboardNavigationTabs && (
        <>
          <MobileTabs {...tabsProps} />
          <TabletTabs {...tabsProps} />
          <Tabs
            onSelectedIndexChange={handleSelectedIndexChange}
            selectedIndex={selectedIndex}
            buttons={tabsActions}
          />
        </>
      )}
      <MainBanner selectedIndex={selectedIndex} />
      {renderMiniApps()}
    </Container>
  )
}

export default ActionBanner
