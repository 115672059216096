import { Icon20 } from 'builder/components/Icon'
import ModalOverlay from 'builder/components/ModalOverlay'
import { Job } from 'builder/modules/jobTracking'
import InterviewCard, { CardType } from '../InterviewCard'

import {
  Close,
  HeaderImage,
  HeaderTitle,
  IconContainer,
  InlineContainer,
  LeftSide,
  ModalContainer,
  RightSide,
  Title,
} from './styles'

type Props = {
  cardType: CardType
  title: string
  job: Partial<Job>
  onClose: () => void
  action: React.ReactNode
}

const ModalWrapper: React.FC<Props> = ({
  children,
  cardType = 'default',
  title,
  job,
  onClose,
  action,
}) => {
  return (
    <ModalOverlay>
      <ModalContainer>
        <Close onClick={onClose}>
          <Icon20.Close />
        </Close>

        <LeftSide>
          <div>
            <InlineContainer>
              <IconContainer>
                <HeaderImage name="InterviewPrep" />
              </IconContainer>
              <HeaderTitle>Interview Prep</HeaderTitle>
            </InlineContainer>

            <Title>{title}</Title>

            {children}
          </div>

          {action}
        </LeftSide>

        <RightSide isGreen={cardType === 'default'}>
          <InterviewCard type={cardType} job={job} />
        </RightSide>
      </ModalContainer>
    </ModalOverlay>
  )
}

export default ModalWrapper
