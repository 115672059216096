import { Course as CourseType } from 'builder/modules/careerPath2'
import { Container, CaourseImage, Image, LogoContainer, Logo } from './styles'

interface HeaderProps {
  course: CourseType
}

export const Header = ({ course }: HeaderProps) => {
  return (
    <Container>
      <CaourseImage>
        <Image src={course.image ?? ''} />
      </CaourseImage>
      <LogoContainer>
        <Logo src={course?.logo ?? ''} />
      </LogoContainer>
    </Container>
  )
}
