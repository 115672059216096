import styled, { css } from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'

type WizardStepperItemProps = {
  isDone: boolean
  isSelected: boolean
}
type WizardStepperFieldIconProps = {
  show: boolean
}
type WizardStepperMenuProps = {
  isOpen: boolean
}

export const Container = styled.div`
  display: inline-flex;
  align-items: center;
  user-select: none;
  position: relative;
`
export const Label = styled.div`
  margin-right: 16px;
  ${Typography.Caption}
  color: ${Colors.Neutral50};

  ${Media.Phone`
    display: none;
  `}
`
export const Field = styled.div`
  padding: 6px 32px 6px 16px;
  display: inline-flex;
  align-items: center;
  outline: none;
  border: 0;
  border-radius: 16px;
  position: relative;
  ${Typography.Caption}
  line-height: 1;
  font-weight: 600;
  background-color: ${Colors.Blue10};
  color: ${Colors.Blue50};
  cursor: pointer;
  transition: background-color 0.125s ease;

  &:hover {
    background-color: ${Colors.Blue20};
  }
`
export const FieldIcon = styled.div<WizardStepperFieldIconProps>`
  width: 24px;
  height: 24px;
  display: flex;
  margin-left: 2px;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: 8px;
  transition: transform 0.1s ease;
  transform: translateY(-50%) rotateZ(${({ show }) => (show ? '90deg' : '-90deg')});
`
export const Menu = styled.div<WizardStepperMenuProps>`
  border-radius: 0 0 3px 3px;
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  width: 335px;
  background-color: ${Colors.White};
  max-height: 80vh;
  overflow: hidden;
  padding: 8px 0;
  border-radius: 4px;
  box-shadow: 0px 2px 14px rgba(23, 68, 130, 0.15), 0px 0px 1px rgba(23, 68, 130, 0.2);

  visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};
  opacity: ${props => (props.isOpen ? 1 : 0)};

  transform: scale(${props => (props.isOpen ? 1 : 0.65)}) translateZ(0);
  transform-origin: 100% 0%;

  transition: opacity 0.15s ease-out, visibility 0.15s,
    transform 0.15s ${props => (props.isOpen ? 'ease-out' : 'ease-in')};

  ${Media.Phone`
    width: 255px;
  `}
`
export const List = styled.div`
  transition: opacity 0.12s ease;
  overflow: auto;
  max-height: calc(80vh - 20px);
`
export const ItemStep = styled.div`
  position: relative;
  font-weight: 600;

  &::before {
    content: '';
    position: absolute;
    width: 2px;
    height: 300%;
    top: -100%;
    left: 50%;
    margin-left: -1px;
    background-color: ${Colors.Neutral10};
  }
`
export const ItemStepInner = styled.span`
  position: relative;
  font-style: normal;
  background-color: ${Colors.Neutral20};
  color: ${Colors.Neutral50};
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
`
export const Item = styled.div<WizardStepperItemProps>`
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  color: ${({ isSelected }) => (isSelected ? Colors.Blue50 : Colors.Neutral90)};
  ${Typography.Body}
  transition: background-color 0.125s ease, color 0.125s ease;
  cursor: ${({ isSelected }) => (isSelected ? '' : 'pointer')};

  &:hover {
    color: ${Colors.Blue50};
    background-color: ${Colors.Blue10};
  }

  &:first-child {
    ${ItemStep}::before {
      top: 0;
    }
  }

  &:last-child {
    ${ItemStep}::before {
      top: auto;
      bottom: 0;
    }
  }

  ${({ isSelected, isDone }) =>
    (isDone || isSelected) &&
    css`
      ${ItemStepInner} {
        color: ${Colors.White};
        background-color: ${Colors.Blue50};
      }
    `}
`
export const ItemName = styled.div`
  margin-left: 12px;
`
