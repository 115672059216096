import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Store } from 'builder/modules/store'
import { ActiveInterviewTab, InterviewStore } from './types'

export const selectors = {
  jobTitleId: (state: Store) =>
    state.interview.jobTitleId || localStorage.jobTitleId ? Number(localStorage.jobTitleId) : 0,
  jobTitle: (state: Store) => state.interview.jobTitle || (localStorage.jobTitle as string),
  interviewId: (state: Store) =>
    state.interview.interviewId || localStorage.interviewId ? Number(localStorage.interviewId) : 0,
  activeInterviewTab: (state: Store) => state.interview.activeInterviewTab,
  getInterviewUrl: (state: Store) =>
    `/interview-preparation/${state.interview.jobTitleId ? 'dashboard' : 'start'}`,
  getInterviewDomainUrl: (state: Store) =>
    `https://career.io/app/interview-preparation/interview-preparation/${
      state.interview.jobTitleId ? 'dashboard' : 'start'
    }`,
}

const initialState: InterviewStore = {
  jobTitleId: 0,
  jobTitle: '',
  interviewId: 0,
  activeInterviewTab: 'in_progress',
}

const { reducer, actions } = createSlice({
  name: 'interview',
  initialState,
  reducers: {
    setJobTitleId: (state, action: PayloadAction<number>) => {
      state.jobTitleId = action.payload
      localStorage.setItem('jobTitleId', String(action.payload))
    },
    setJobTitle: (state, action: PayloadAction<string>) => {
      state.jobTitle = action.payload
      localStorage.setItem('jobTitle', String(action.payload))
    },
    setInterviewId: (state, action: PayloadAction<number>) => {
      state.interviewId = action.payload
      localStorage.setItem('interviewId', String(action.payload))
    },
    setActiveInterviewTab: (state, action: PayloadAction<ActiveInterviewTab>) => {
      state.activeInterviewTab = action.payload
    },
  },
})

export { reducer, actions }
