import { selectors } from 'builder/modules/careerPath2'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

import Header from './components/Header'
import Companies from './components/Companies'
import { Container } from './styles'

const CompaniesList = () => {
  const location = useTypedSelector(selectors.location)
  const { companies } = useTypedSelector(selectors.jobDemand)
  const selectedJobTitle = useTypedSelector(selectors.selectedJobTitle)
  const isExploreCareers = useTypedSelector(selectors.isExploreCareers)
  const isJobDemandLoading = useTypedSelector(selectors.isJobDemandLoading)
  const exploreCareersLocation = useTypedSelector(selectors.exploreCareersLocation)
  const exploreCareersOccupation = useTypedSelector(selectors.exploreCareersOccupation)

  const jobLocation = isExploreCareers ? exploreCareersLocation : location
  const jobTitle = !isExploreCareers ? selectedJobTitle : exploreCareersOccupation

  return (
    <Container>
      <Header isLoading={isJobDemandLoading} />
      <Companies
        jobTitle={jobTitle}
        companies={companies}
        jobLocation={jobLocation}
        isLoading={isJobDemandLoading}
      />
    </Container>
  )
}

export default CompaniesList
