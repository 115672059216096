import { Fragment } from 'react'
import { useI18n } from 'builder/hooks/useI18n'
import * as Styled from './styles'
import loader from './assets/loader.png'

const images = [
  { Img: require('./assets/image_1.png') },
  { Img: require('./assets/image_2.png') },
  { Img: require('./assets/image_3.png') },
]

export const ResourceVisual = () => {
  const { i18n } = useI18n()

  return (
    <Styled.Container href="https://career.io/career-advice">
      <Styled.MainContainer>
        <Styled.Header>{i18n.t('builder.panel.resources_card.header')}</Styled.Header>
        <Styled.Title>{i18n.t('builder.panel.resources_card.new_title')}</Styled.Title>
        <Styled.Text>{i18n.t('builder.panel.resources_card.text')}</Styled.Text>
      </Styled.MainContainer>
      <Styled.VisualContainer>
        {images.map((data, index) => (
          <Fragment key={index}>
            <Styled.SubContainer>
              <Styled.ImageContainer src={data.Img} />
              <Styled.Loader src={loader} />
            </Styled.SubContainer>
          </Fragment>
        ))}
      </Styled.VisualContainer>
      <Styled.ResourceCardVisualGradient />
    </Styled.Container>
  )
}
