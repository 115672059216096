import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;

  ${Media.Phone`
    width: 100%;
  `}
`

export const SalaryInfo = styled.div`
  display: flex;
  gap: 4px;
  align-items: flex-end;
`

export const Salary = styled.div`
  ${Typography.XL};
  ${FontWeights.Medium};
`

export const Duration = styled.div`
  margin-bottom: 3px;

  & > span {
    color: ${Colors.Blue50};
  }

  & > span:hover {
    cursor: pointer;
  }
`

export const DurationText = styled.span``

export const Text = styled.div`
  color: ${Colors.Neutral50};
`
