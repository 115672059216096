import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Icon24 } from 'builder/components/Icon'
import Shadows from 'builder/styles/shadows'

export const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const TabsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  position: relative;
`

export const TabTitle = styled.span`
  ${Typography.L};
  transition: all 0.1s ease-in;
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
`

export const IconContainer = styled.div`
  height: 40px;
  width: 40px;
  background-color: ${Colors.Blue10};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
`

export const ArrowIcon = styled(Icon24.Chevron)<{ isVisible: boolean }>`
  color: ${Colors.Blue50};
  cursor: pointer;
  transform: rotate(${props => (props.isVisible ? '270deg' : '90deg')});
  transition: all 0.2s ease-in;
`

export const MenuContainer = styled.div<{ isMenuVisible: boolean }>`
  padding: 10px 0;
  border-radius: 12px;
  background-color: ${Colors.White};
  opacity: ${props => (props.isMenuVisible ? 1 : 0)};
  transition-property: opacity, transform;
  transition-timing-function: ease-in-out;
  transition-duration: 0.1s;
  border-radius: 6px;
  transition-delay: 0.05s;
  transition-duration: 0.25s;
  position: absolute;
  width: 259px;
  height: 184px;
  top: 60px;
  left: 87.5%;
  background: ${Colors.White};
  z-index: 500;
  ${Shadows.lightWithBorder.high}
`

export const Item = styled.div`
  padding: 6px 16px;
  cursor: pointer;
  height: 56px;
  width: 247px;
`

export const ItemContent = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: space-between;
`

export const ItemIcon = styled.div`
  display: flex;
  flex-shrink: 0;
  color: ${Colors.Blue50};
  margin-right: 8px;
`

export const ItemText = styled.div<{ selected: boolean }>`
  transition: color 0.15s;
  color: ${props => (props.selected ? Colors.Blue50 : Colors.Neutral90)};
  ${Typography.S};
  ${FontWeights.Medium};
  ${Item}:hover && {
    color: ${Colors.Blue50};
  }
`

export const SelectedIcon = styled(Icon24.TickLarge)<{ selected: boolean }>`
  color: ${Colors.Blue50};
  display: ${props => (props.selected ? 'block' : 'none')};
`
