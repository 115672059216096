import styled, { css } from 'styled-components'
import { TransitionStatus } from 'react-transition-group'
import Colors from 'builder/styles/colors'
import Shadows from 'builder/styles/shadows'
import Sizes from 'builder/styles/sizes'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import DefaultButton from 'builder/components/Button'
import { ModalClose } from 'builder/components/ModalClose'
import { NonModalDialogPlacement, NonModalDialogPosition, NonModalDialogSize } from '../../types'

type ContainerProps = {
  $size: NonModalDialogSize
  $placement: NonModalDialogPlacement
  $position: NonModalDialogPosition
  $transitionState: TransitionStatus
  $hasCloseButton: boolean
}

const sSizeCss = css`
  --content-gap: ${Sizes['2XS']};
  flex-direction: column;
  right: var(--bottom-spacing);
  padding: ${Sizes.M};
`

const animationOffset = '128px'

const animations = {
  top: {
    entering: null,
    entered: css`
      transform: translateY(0);
    `,
    exiting: css`
      transform: translateY(-${animationOffset});
    `,
    exited: css`
      transform: translateY(-${animationOffset});
    `,
    unmounted: null,
  },
  bottom: {
    entering: null,
    entered: css`
      transform: translateY(0);
    `,
    exiting: css`
      transform: translateY(${animationOffset});
    `,
    exited: css`
      transform: translateY(${animationOffset});
    `,
    unmounted: null,
  },
}

export const DialogContainer = styled.div<ContainerProps>(props => {
  const { $size, $placement, $position, $transitionState } = props
  return css`
    --top-spacing: 88px;
    --bottom-spacing: 24px;
    --content-gap: 0;
    --width: 736px;

    position: ${$position};
    display: flex;
    justify-content: space-between;
    ${Shadows.lightWithBorder.mid};
    background-color: ${Colors.White};
    border-radius: ${Sizes.XS};
    padding: ${Sizes.M};
    gap: ${Sizes.M};
    width: var(--width);
    z-index: 5; // same as snackbar
    transition: transform 0.3s ease;

    ${animations[$placement][$transitionState]};

    ${$placement === 'top' &&
    css`
      top: var(--top-spacing);
    `}

    ${$placement === 'bottom' &&
    css`
      bottom: var(--bottom-spacing);
    `}

    ${$size === 'M' &&
    css`
      left: calc(50% - var(--width) / 2);
    `}

    ${$size === 'S' &&
    css`
      --width: 352px;
      ${sSizeCss}
    `}

    ${Media.Phone`
      --top-spacing: 80px;
      --bottom-spacing: 16px;
      --width: calc(100% - var(--bottom-spacing) * 2);
      ${sSizeCss}
    `}
  `
})

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--content-gap);
`

export const Title = styled.h2`
  ${Typography.Subhead};
  ${FontWeights.DemiBold};
  display: flex;
  gap: ${Sizes.S};
`

export const Description = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
`

export const Buttons = styled.div`
  display: flex;
  gap: ${Sizes['2XS']};
  justify-content: flex-end;
`

export const Button = styled(DefaultButton)`
  &:only-child {
    margin-left: auto;
  }
`

export const CloseButton = styled(ModalClose)`
  position: absolute;
  top: ${Sizes.M};
  right: ${Sizes.M};
`
