import { selectors } from 'builder/modules/careerPath2'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import RoleDescription from './components/RoleDescription'

import { Section } from './styles'

const AboutJobTitle = () => {
  const isLoading = useTypedSelector(selectors.isCareerPagesJobDetailsLoading)
  const { description } = useTypedSelector(selectors.careerPagesJobDetails)

  return (
    <Section>
      <RoleDescription description={description} isSkeleton={isLoading} />
    </Section>
  )
}

export default AboutJobTitle
