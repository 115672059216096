import { apiClient } from 'builder/modules/apiClient'
import { all, put, call, takeLatest, select } from 'redux-saga/effects'
import { trackMarketingEvent, trackInternalEvent } from '@rio/tracking'
import { actions, selectors } from './signUpModule'
import { navigate } from 'builder/modules/navigate'

const RESUME_SIGN_UP_FORM_KEY = 'RESUME_SIGN_UP_FORM'
const COVER_LETTER_SIGN_UP_FORM_KEY = 'COVER_LETTER_SIGN_UP_FORM'

function* getSignUpPayloadSaga(action) {
  const { data } = yield call(apiClient.get, `/sign-up/payload`)

  yield put(actions.setSignUpPayload(data))
}

function* signUpSaga(action) {
  try {
    const { type, email, isSuperApp } = action.payload

    const { data } = yield call(apiClient.post, `/sign-up/register`, {
      signUp: action.payload,
    })

    if (data.success) {
      // Redirect to the document editor
      const isResume = type === 'resume'
      const controller = isResume ? 'resumes' : 'cover-letters'

      navigate(`/${controller}/${data.firstDocumentId}/edit`, { replace: true })

      // Clear persisted data
      localStorage.removeItem(isResume ? RESUME_SIGN_UP_FORM_KEY : COVER_LETTER_SIGN_UP_FORM_KEY)
      yield put(actions.clearSignUpInfo())
      // Trigger related actions and sagas
      yield put(actions.signUpComplete())

      trackInternalEvent('complete_email_sign_up')

      // Track successful sign ups
      trackMarketingEvent('Sign up', 'Complete Registration')

      // tvscientific complete registration
      if (isSuperApp) {
        ;(function (j) {
          let l = 'tvscientific-pix-o-918ddc26-8b46-437a-81a6-ca2909177441'
          let e = encodeURIComponent
          let d = document
          let w = window.location
          let p = d.createElement('IMG')
          let s =
            w.protocol +
            '//tvspix.com/t.png?t=' +
            new Date().getTime() +
            '&l=' +
            l +
            '&u3=' +
            e(w.href) +
            '&u1=complete_registration&u4=' +
            e(j.orderId) +
            '&u5=' +
            e(j.lastTouchChannel) +
            '&u12=CIO'
          p.setAttribute('src', s)
          p.setAttribute('height', '0')
          p.setAttribute('width', '0')
          p.setAttribute('alt', '')
          p.style.display = 'none'
          p.style.position = 'fixed'
          d.body.appendChild(p)
        })({
          orderId: btoa(email), // required
          lastTouchChannel: '', // optional
        })
      }

      // end tvscientific complete registration
    } else {
      const registerErrors = data.errors || []
      const emailTaken = registerErrors.indexOf('email_taken') !== -1
      yield put(actions.setSuggestedEmail(''))
      if (emailTaken) {
        yield put(actions.setEmailErrorCode('TAKEN'))
        yield put(actions.toggleEmailValidating())
        return
      }
      yield put(actions.setEmailErrorCode('INVALID'))
    }
    yield put(actions.toggleEmailValidating())
  } catch (error) {}
}

function* checkEmailDomainSaga({ email }) {
  const { data } = yield call(apiClient.post, `/sign-up/mail-speller`, { email })
  return data.success && data.suggested ? data.suggested : ''
}

function* loadSignUpInfoSaga({ payload }) {
  try {
    const { type, ...data } = payload
    const storageKey = type === 'resume' ? RESUME_SIGN_UP_FORM_KEY : COVER_LETTER_SIGN_UP_FORM_KEY
    const storageItem = localStorage.getItem(storageKey)
    const storageValue = storageItem ? JSON.parse(storageItem) : {}
    const valueInStore = yield select(selectors.signUpInfo)

    yield call(persistSignUpInfoSaga, {
      payload: { type, ...valueInStore, ...storageValue, ...data },
    })
  } catch (error) {}
}

function* persistSignUpInfoSaga({ payload }) {
  try {
    const { type, ...data } = payload
    const storageKey = type === 'resume' ? RESUME_SIGN_UP_FORM_KEY : COVER_LETTER_SIGN_UP_FORM_KEY
    const storageItem = localStorage.getItem(storageKey)
    const storageValue = storageItem ? JSON.parse(storageItem) : {}
    const valueInStore = yield select(selectors.signUpInfo)

    const newSignUpInfoState = { ...valueInStore, ...storageValue, ...data }

    localStorage.setItem(storageKey, JSON.stringify(newSignUpInfoState))
    yield put(actions.setSignUpInfo(newSignUpInfoState))
  } catch (error) {}
}

function* validateEmailSaga(action) {
  const { email, resolve } = action.payload

  yield put(actions.toggleEmailValidating())

  const isEmailSuggested = yield select(selectors.isEmailSuggested)

  if (!isEmailSuggested) {
    const suggestedEmail = yield call(checkEmailDomainSaga, { email })

    if (suggestedEmail) {
      trackInternalEvent('see_sign_up_email_suggestion')
      yield put(actions.setSuggestedEmail(suggestedEmail))
      yield put(actions.toggleEmailValidating())
      return
    }
  }

  const { data } = yield call(apiClient.post, `/sign-up/validate`, { email })

  if (data.success) {
    yield put(actions.toggleEmailValidating())
    resolve()
  } else {
    const emailTaken = data.errorCode === 'email_taken'
    trackInternalEvent('see_sign_up_email_error', { error: data.errorCode || 'invalid_email' })

    if (emailTaken) {
      yield put(actions.toggleEmailValidating())
      yield put(actions.setEmailErrorCode('TAKEN'))
      return
    }

    yield put(actions.setEmailErrorCode('INVALID'))
    yield put(actions.toggleEmailValidating())
  }
}

export const sagas = function* saga() {
  yield all([
    takeLatest(actions.getSignUpPayload, getSignUpPayloadSaga),
    takeLatest(actions.signUpRequest, signUpSaga),
    takeLatest(actions.persistSignUpInfo, persistSignUpInfoSaga),
    takeLatest(actions.loadSignUpInfo, loadSignUpInfoSaga),
    takeLatest(actions.validateEmail, validateEmailSaga),
  ])
}
