import { createRef, Component } from 'react'
import PropTypes from 'prop-types'
import loaderImage from 'images/builder/resume-editor/resume-loader.svg'

import { Container, LoaderContainer, Loader } from './styles'

export default class PdfPreview extends Component {
  static propTypes = {
    document: PropTypes.object,
    width: PropTypes.number,
    height: PropTypes.number,
  }

  state = {
    isLoading: true,
    RendererComponent: null,
  }

  constructor(props) {
    super(props)
    this.renderer = createRef()
  }

  componentDidMount = () => {
    import(/* webpackChunkName: "pdf-preview-renderer" */ './Renderer').then(module => {
      this.setState({ RendererComponent: module.default })
    })
  }

  nextPage() {
    const { current } = this.renderer
    if (current) current.nextPage()
  }

  previousPage() {
    const { current } = this.renderer
    if (current) current.previousPage()
  }

  reset() {
    const { current } = this.renderer
    if (current) current.reset()
  }

  resize() {
    const { current } = this.renderer
    if (current) current.resize()
  }

  handlePageRender = () => {
    this.setState({ isLoading: false })
  }

  render() {
    const { width, height, document } = this.props
    const { RendererComponent, isLoading } = this.state

    return (
      <Container w={width} h={height}>
        {isLoading && (
          <LoaderContainer>
            <Loader src={loaderImage} />
          </LoaderContainer>
        )}
        {RendererComponent && document && (
          <RendererComponent
            ref={this.renderer}
            onPageRender={this.handlePageRender}
            {...this.props}
          />
        )}
      </Container>
    )
  }
}
