import { useCallback } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useConfig } from 'builder/hooks/useConfig'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/resumeEditor'
import BadgeBanner from './BadgeBanner'

interface Props {
  onClickGenerate: () => void
}

const GenerateAICVBanner = (props: Props) => {
  const config = useConfig()
  const { onClickGenerate } = props
  const showAICoverLetter = config?.features.aiCoverLetters
  const score = useTypedSelector(selectors.resumeScore)

  const handleBannerGenerateClick = useCallback(() => {
    onClickGenerate()
    trackInternalEvent('click_ai_cover_letter_banner', { score })
  }, [onClickGenerate, score])

  if (showAICoverLetter) {
    return <BadgeBanner onBannerGenerateClick={handleBannerGenerateClick} />
  }
  return null
}

export default GenerateAICVBanner
