import { memo, useRef } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import FileSelector from 'builder/components/FillResumeModal/UploadResume/FileSelector/FileSelector'
import { useI18n } from 'builder/hooks/useI18n'
import {
  CardDescription,
  CardSubtitle,
  CardTitle,
  ImageWrapper,
  UploadResumeImage,
} from 'builder/components/FillResumeModal/OptionsViewOld/OptionCards/styles'
// eslint-disable-next-line import/no-absolute-path
import uploadResumeImg from '/app/assets/images/builder/fill-resume/layout-two/upload-resume.png'
import Colors from 'builder/styles/colors'
import Icon24 from 'builder/components/Icon'
import { Card, CardContainer, IconWrapper } from './styles'

interface Props {
  direction: 'row' | 'column'
  justifyCenter?: boolean
  showArrow?: boolean
  subtitleFixedWidth?: number
  eventLabel?: string
}

export const UploadResume = memo(
  ({
    direction,
    justifyCenter = true,
    showArrow = false,
    subtitleFixedWidth,
    eventLabel,
  }: Props) => {
    const { i18n } = useI18n()
    const fileInputRef = useRef<HTMLInputElement>(null)

    const handleCardClick = () => {
      eventLabel && trackInternalEvent('click_upload_resume', { label: eventLabel })
      fileInputRef.current?.click()
    }

    return (
      <Card onClick={handleCardClick} $direction={direction} $justifyCenter={justifyCenter}>
        <ImageWrapper>
          <UploadResumeImage src={uploadResumeImg} alt="Upload Resume" />
        </ImageWrapper>
        <CardDescription>
          <CardTitle>{i18n.t('builder.prefill_resume.upload_resume')}</CardTitle>
          <CardSubtitle $fixedWidth={subtitleFixedWidth}>
            {i18n.t('builder.prefill_resume.upload_resume_instructions')}
          </CardSubtitle>
        </CardDescription>
        {showArrow && (
          <IconWrapper>
            <Icon24.ToggleArrow style={{ fill: Colors.Neutral40 }} />
          </IconWrapper>
        )}
        <FileSelector ref={fileInputRef} />
      </Card>
    )
  },
)

const OptionCards = () => {
  return (
    <CardContainer>
      <UploadResume direction="column" />
    </CardContainer>
  )
}

export default memo(OptionCards)
