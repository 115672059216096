import { Fragment } from 'react'
import findDreamJobBack from '../../../assets/img/find-dream-job-back.png'
import findDreamJobFront from '../../../assets/img/find-dream-job-front.png'

import * as Styles from './styles'
const divisionImage = require('images/critique-review/divisionImage.svg')

const FindDreamJob = () => {
  const resumeEditorUrl = `/app/career-plans/execute-a-job-search`

  return (
    <Fragment>
      <Styles.DivisionImage src={divisionImage} />
      <Styles.Container data-testid="find-job-banner">
        <Styles.Title>
          Follow a step-by-step strategy for a better job search and faster results
        </Styles.Title>
        <Styles.ImagePreview src={findDreamJobBack} />
        <Styles.ImageSide src={findDreamJobFront} />
        <Styles.Gradient />
        <Styles.Button href={resumeEditorUrl} left={90} data-testid="cta-button">
          Find Dream Job
        </Styles.Button>
      </Styles.Container>
    </Fragment>
  )
}

export default FindDreamJob
