import { trackInternalEvent } from '@rio/tracking'

import { useEffect } from 'react'
import { useI18n } from 'builder/hooks/useI18n'
import { ModalTemplate } from 'builder/components/CareerProfile'

import TWITTER from '../RecommendationsErrors/assets/twitter.png'
import AMAZON from '../RecommendationsErrors/assets/amazon.png'
import APPLE from '../RecommendationsErrors/assets/apple.png'
import GOOGLE from '../RecommendationsErrors/assets/google.png'
import AIRBNB from '../RecommendationsErrors/assets/airbnb.png'
import RESUME from './assets/resume.png'
import {
  DoneButton,
  ImageContainer,
  EllipseOne,
  EllipseTwo,
  EllipseThree,
  ResumeImage,
  ResumeImageContainer,
  AmazonImage,
  AppleImage,
  GoogleImage,
  AirbnbImage,
  TwitterImage,
  ButtonsContainer,
} from './styles'

interface CheckProfileModalProps {
  onClose: () => void
  onClick: () => void
  eventLabel: 'recommended_jobs' | 'career_profile'
}

export const CheckProfileModal = ({ onClose, onClick, eventLabel }: CheckProfileModalProps) => {
  const { i18n } = useI18n()

  useEffect(() => {
    trackInternalEvent('show_created_profile_modal', { label: eventLabel })
  }, [eventLabel])

  return (
    <ModalTemplate
      onClose={onClose}
      header={
        <ImageContainer>
          <GoogleImage src={GOOGLE} alt="google" />
          <AmazonImage src={AMAZON} alt="amazon" />
          <AppleImage src={APPLE} alt="apple" />
          <TwitterImage>
            <img src={TWITTER} alt="twitter" />
          </TwitterImage>
          <AirbnbImage>
            <img src={AIRBNB} alt="airbnb" />
          </AirbnbImage>
          <ResumeImageContainer>
            <ResumeImage src={RESUME} />
          </ResumeImageContainer>
          <EllipseOne />
          <EllipseTwo />
          <EllipseThree />
        </ImageContainer>
      }
      title={i18n.t('builder.job_search.check_profile_modal.title')}
      description={i18n.t('builder.job_search.check_profile_modal.description')}
      buttons={
        <ButtonsContainer>
          <DoneButton onClick={onClick}>
            {i18n.t('builder.job_search.check_profile_modal.done_button')}
          </DoneButton>
        </ButtonsContainer>
      }
    />
  )
}
