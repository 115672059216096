import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { FontWeights } from 'builder/styles/typography'

export const Subtitle = styled.p`
  b {
    color: ${Colors.Neutral100};
    ${FontWeights.DemiBold}
  }
`

export const Image = styled.img`
  width: 100%;
  height: 280px;
  object-fit: cover;
`
