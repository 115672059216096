import { CallBackProps, Step } from 'react-joyride'
import { useDispatch } from 'react-redux'
import { JoyRide } from 'builder/components/JoyRide'
import { useI18n } from 'builder/hooks/useI18n'
import { actions, selectors } from 'builder/modules/resumeEditor'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

const DEFAULT_STEP_DATA: Partial<Step> = {
  disableBeacon: true,
  placement: 'top',
  disableScrolling: false,
  spotlightClicks: true,
  disableOverlayClose: false,
  disableCloseOnEsc: false,
}

export const ElementHighlight = () => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const data = useTypedSelector(selectors.highlightedElementData)

  const onCallback = (callbackProps: CallBackProps) => {
    if (['skip', 'close'].includes(callbackProps.action)) {
      dispatch(actions.closeHighlightedElement(callbackProps))
    }
  }

  return data ? (
    <JoyRide
      run
      steps={[
        {
          ...DEFAULT_STEP_DATA,
          ...data,
        },
      ]}
      callback={onCallback}
      isStepsCountEnabled={false}
      finalButtonLabel={
        data.buttonLabel || i18n.t(`builder.resume_editor.element_highlight.default_button_text`)
      }
    />
  ) : null
}
