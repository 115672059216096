import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { trackInternalEvent } from '@rio/tracking'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import * as SimpleForm from 'builder/components/SimpleForm'
import { useI18n } from 'builder/hooks/useI18n'
import { AutoApplyForm } from 'builder/modules/autoApply/types'
import { selectors } from 'builder/modules/autoApply/autoApplyModule'
import { Container, FillWarning, LayoutContainer, Title, TitleWrapper } from '../styles'
import { Routes, StartCompleteScreenRoute, TotalPageCount } from '../../constants'
import { ConfirmModal } from '../ConfirmModal/ConfirmModal'
import { usePrompt } from '../../hooks/usePrompt'
import { usePatchForm } from '../../hooks/usePatchForm'
import { FormContainer } from './styles'
import { PersonalInfoForm } from './PersonalInfoForm'

const TRANSLATION = 'builder.auto_apply.form.personal_information'

export const PersonalInfoFormContainer = () => {
  const formState = useSelector(selectors.form)

  const form = useForm<
    Pick<AutoApplyForm, 'firstName' | 'lastName' | 'currentJobTitle' | 'gender' | 'race'>
  >({
    mode: 'onChange',
  })

  const { mutateAsync: patchForm, isLoading } = usePatchForm()

  const { i18n } = useI18n()
  const navigate = useNavigate()
  const { isPromptVisible, handleCancel, handleConfirm } = usePrompt({
    when: true,
    ignoreParentRoute: ['auto-apply'],
  })

  const onNextStep = async () => {
    await patchForm(form.getValues())
    navigate(`/auto-apply/${Routes.CONTACT_INFO}`)
  }

  const onBackClick = () => {
    form.clearErrors()
    navigate(`/auto-apply/${StartCompleteScreenRoute.RESUME_SELECT}`)
  }

  useEffect(() => {
    trackInternalEvent('aa_personal_information', {
      label: 'total_job_search_solution',
    })
  }, [])

  useEffect(() => {
    const { firstName, lastName, currentJobTitle, gender, race } = formState
    form.setValue('firstName', firstName)
    form.setValue('lastName', lastName)
    form.setValue('currentJobTitle', currentJobTitle)
    form.setValue('gender', gender)
    form.setValue('race', race)
  }, [formState])

  return (
    <LayoutContainer>
      <Container>
        <TitleWrapper>
          <Title>{i18n.t(`${TRANSLATION}.title`)}</Title>
          <FillWarning>{i18n.t(`${TRANSLATION}.sub_title`)}</FillWarning>
        </TitleWrapper>
        <FormContainer>
          <SimpleForm.Form
            submitButtonText={i18n.t(`${TRANSLATION}.next`)}
            onBackClick={onBackClick}
            onSubmit={form.handleSubmit(onNextStep)}
            isPending={isLoading}
            processIndicator={{
              totalStep: TotalPageCount,
              currentStep: 1,
            }}
          >
            <PersonalInfoForm form={form} />
          </SimpleForm.Form>
        </FormContainer>
      </Container>
      {isPromptVisible && <ConfirmModal onClose={handleCancel} onConfirm={handleConfirm} />}
    </LayoutContainer>
  )
}
