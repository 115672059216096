import { useQuery } from '@tanstack/react-query'
import { baseClient } from 'builder/modules/apiClient'
import { InterviewAnswer } from 'builder/modules/interview/types'
import { POLLING_REFRESH_INTERVAL_IN_MILLIS } from '../constants'

import { usePerformanceOnce } from './usePerformanceOnce'

const isFeedbackReceived = (data?: InterviewAnswer) =>
  data &&
  typeof data.content === 'string' &&
  data.feedback &&
  typeof data.feedback.content === 'string' &&
  typeof data.feedback.ideal_answer === 'string'

export const useInterviewAnswer = (questionId?: number, enabledPerformanceMetric = false) => {
  const { registerOncePerformance, stopRegisterPerformance } = usePerformanceOnce({
    enabled: enabledPerformanceMetric,
    metricKey: `answer_request-${questionId}`,
    tags: { questionId },
  })
  return useQuery(
    ['question', questionId],
    async () => {
      const endpoint = `/interview_prep/questions/${questionId}/answer`
      registerOncePerformance()
      const response = await baseClient.get<InterviewAnswer>(endpoint)

      if (isFeedbackReceived(response.data)) {
        stopRegisterPerformance()
      }
      return response.data
    },
    {
      enabled: !!questionId,
      refetchInterval: (data?: InterviewAnswer) => {
        if (isFeedbackReceived(data)) {
          return false
        }
        return POLLING_REFRESH_INTERVAL_IN_MILLIS
      },
    },
  )
}
