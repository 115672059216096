export enum Step {
  RESUME_CREATION = 'resume_creation',
  RESUME_DISTRIBUTION = 'resume_distribution',
  PERSONALIZED_JOBS = 'personalized_jobs',
  JOB_TRACKER_WISHLIST = 'job_tracker_wishlist',
  APPLY_FOR_YOU = 'apply_for_you',
  CAREER_PROFILE = 'career_profile',
}

export type StepsData = {
  [Step.RESUME_CREATION]: {
    completed: boolean
    resume_id?: number
    score?: number
  }
  [Step.RESUME_DISTRIBUTION]: {
    completed: boolean
  }
  [Step.PERSONALIZED_JOBS]: {
    completed: boolean
  }
  [Step.JOB_TRACKER_WISHLIST]: {
    completed: boolean
    needed: number
  }
  [Step.APPLY_FOR_YOU]: {
    completed: boolean
  }
  [Step.CAREER_PROFILE]: {
    completed: boolean
    current_score?: number
  }
}

export interface DashboardStore {
  isLoaded: boolean
  stepsData: StepsData
  stepperSuccessStateSeen: boolean
}
