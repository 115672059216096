import PropTypes from 'prop-types'

import { Container, Image, Title, Content, ImageContainer } from './styles'

const ImageWrapper = ({ image, title }) => {
  if (image) {
    return (
      <ImageContainer>
        <Image src={image} alt={title} width={392} height={302} />
      </ImageContainer>
    )
  }
  return null
}

const SignInMessage = ({ image, title, children }) => {
  return (
    <Container>
      <ImageWrapper image={image} title={title} />
      <Title>{title}</Title>
      <Content>{children}</Content>
    </Container>
  )
}

SignInMessage.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
}

ImageWrapper.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
}

export default SignInMessage
