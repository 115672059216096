import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Icon24 } from 'builder/components/Icon'
import { actions as uiActions } from 'builder/modules/ui'
import { actions as panelActions } from 'builder/modules/panel'
import Button, { ButtonSize } from 'builder/components/Button'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useAppSelector } from 'builder/hooks/useAppSelector'
import ModalOverlay from '../ModalOverlay'

import * as Styles from './styles'

type Props = {
  id: number
}

const TREditModal = ({ id }: Props) => {
  const { isPhone } = useMediaQueries()
  const dispatch = useDispatch()
  const { panel } = useAppSelector(store => store)
  const { duplicationUrl, isDuplicationLoading } = panel
  const navigate = useNavigate()

  const handleClose = useCallback(() => dispatch(uiActions.closeTREditModal(id)), [dispatch, id])
  const handleDuplication = () => {
    dispatch(panelActions.duplicateTRDocument({ id: id }))
  }

  useEffect(() => {
    if (duplicationUrl) {
      navigate(duplicationUrl.replace('app', ''))
      dispatch(panelActions.setDuplicationUrl(''))
      dispatch(uiActions.closeTREditModal(id))
    }
  }, [duplicationUrl, navigate, dispatch, id])

  return (
    <ModalOverlay
      onClick={handleClose}
      overlayFadeDuration={150}
      contentSlideDuration={150}
      backgroundOpacity={0.4}
    >
      <Styles.Container>
        <Styles.Close onClick={handleClose}>
          <Icon24.CloseLarge />
        </Styles.Close>
        <Styles.DescriptionSection>
          <Styles.Description isDuplicationLoading={isDuplicationLoading}>
            <Styles.Header>
              <Styles.Title>
                {isDuplicationLoading
                  ? 'Your document is loading'
                  : 'Clicking “edit” will automatically duplicate your document.'}
              </Styles.Title>
            </Styles.Header>
            <Styles.SubTitle>
              {isDuplicationLoading
                ? 'Please double check the information in your document — minor details can be lost during the duplication process.'
                : `This means potentially losing quality and formatting. When applying for jobs, we
                  recommend using the expert document. Duplicates will be located in My Resumes.`}
            </Styles.SubTitle>
          </Styles.Description>
          <Styles.ButtonContainer>
            <Button
              theme="ghost"
              onClick={handleClose}
              size={!isPhone ? ButtonSize.default : ButtonSize.small}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDuplication}
              size={!isPhone ? ButtonSize.default : ButtonSize.small}
              isLoading={isDuplicationLoading}
              isDisabled={isDuplicationLoading}
            >
              Duplicate
            </Button>
          </Styles.ButtonContainer>
        </Styles.DescriptionSection>
      </Styles.Container>
    </ModalOverlay>
  )
}

export default TREditModal
