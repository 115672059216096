import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { avatarUrl } from 'builder/modules/careerCoaching/constants'
import { PreviousUpcomingSessions } from 'builder/modules/careerCoaching'
import { formatPreviousSessionsDate } from 'builder/views/CareerPostPurchaseView/Utils/Utils'
import {
  Container,
  CoachDetails,
  CoachImage,
  CoachCaption,
  CaptionName,
  CaptionPosition,
  SessionDate,
  SessionTime,
  SessionTimeContainer,
} from './styles'

interface SessionCardProps {
  session: PreviousUpcomingSessions
}

export const SessionCard = ({ session }: SessionCardProps) => {
  const { isPhone } = useMediaQueries()

  const date = formatPreviousSessionsDate(session?.date)

  return (
    <Container>
      <CoachDetails>
        <CoachImage alt={session?.coach_name} src={session?.coach_avatar || avatarUrl} />
        <CoachCaption>
          <CaptionName>{session?.coach_name}</CaptionName>
          {!isPhone && <CaptionPosition>Career Coach</CaptionPosition>}
          {isPhone && (
            <>
              <SessionDate>{date}</SessionDate>
              <SessionTime>
                {session?.start_time?.replace('pm', '')?.replace('am', '')} - {session?.end_time}
              </SessionTime>
            </>
          )}
        </CoachCaption>
      </CoachDetails>

      {!isPhone && (
        <SessionTimeContainer>
          <SessionDate>{date}</SessionDate>
          <SessionTime>
            {session?.start_time?.replace('pm', '')?.replace('am', '')} - {session?.end_time}
          </SessionTime>
        </SessionTimeContainer>
      )}
    </Container>
  )
}
