export function getMediaRecorder(stream: MediaStream, media: 'audio' | 'video'): MediaRecorder {
  const supportedMimeTypeForVideo = ['video/webm', 'video/mp4']
  const supportedMimeTypeForAudio = ['video/webm', 'audio/mp4']
  const supportedMineType =
    media === 'video' ? supportedMimeTypeForVideo : supportedMimeTypeForAudio

  const recorder = new MediaRecorder(stream, {
    mimeType: supportedMineType.find(MediaRecorder.isTypeSupported),
  })

  return recorder
}
