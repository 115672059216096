import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import { Icon20 } from 'builder/components/Icon'
import Button from 'builder/components/Button'
import Colors from 'builder/styles/colors'

export const Container = styled.div<{ isDocummentSection: boolean }>`
  width: ${props => (props.isDocummentSection ? 192 : 268)}px;
  height: ${props => (props.isDocummentSection ? 272 : 336)}px;
  background: #1a1c6a66;
  border-radius: 16px;
  position: absolute;
  z-index: 2;
  cursor: pointer;
  backdrop-filter: blur(8px);

  ${Media.Phone`
  width: 104px;
  height: 146px;
  `}
`

export const SubContainer = styled.div<{ isDocummentSection: boolean }>`
  margin-top: ${props => (props.isDocummentSection ? 79 : 104)}px;

  ${Media.Phone`
  margin-top: 10px;
  `}
`

export const Text = styled.div<{ isDocummentSection: boolean }>`
  ${FontWeights.Medium};
  ${props => (props.isDocummentSection ? Typography.Caption : Typography.Body)};
  color: var(--Neutral-White, #fff);
  text-align: center;
  margin-bottom: 12px;
`

export const Crown = styled(Icon20.Crown)`
  color: var(--Neutral-White, #fff);
  width: 40px;
  height: 40px;
  margin: auto;
  display: block;

  ${Media.Phone`
  width: var(--L, 32px);
  height: var(--L, 32px);
  flex-shrink: 0;
  `}
`

export const UpgradeNow = styled(Button)`
  position: absolute;
  ${Typography.Caption};
  background: ${Colors.Indigo50};
  color: var(--Neutral-White, #fff);
  left: 50%;
  transform: translate(-50%);
  width: 121px;
  height: auto;
  padding: 8px 16px;

  &:hover,
  &:active,
  &:focus-visible {
    background: ${Colors.Indigo60};
  }
  ${Media.Phone`
  display: flex;
  width: 77px;
  padding: var(--2XS, 8px) var(--S, 16px);
  justify-content: center;
  align-items: center;
`}
`
