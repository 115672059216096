import styled from 'styled-components'
import { HexColorInput } from 'react-colorful'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'

export const Container = styled.div`
  position: relative;
  width: 200px;
  border-radius: 9px;
  background-color: ${Colors.Neutral100};

  ${Media.Tablet`
    width: 250px;
  `}

  ${Media.AboveTablet`
    & .react-colorful {
      width: 100%;
      height: 180px;
    }

    & .react-colorful__hue {
      border-radius: 0;
    }
  `}

  ${Media.Tablet`
    & .react-colorful {
      width: 100%;
      height: 228px;
    }

    & .react-colorful__saturation {
      border-bottom-width: 14px;
    }

    & .react-colorful__hue {
      height: 28px;
    }

    & .react-colorful__hue-pointer,
    & .react-colorful__saturation-pointer {
      width: 32px;
      height: 32px;
      border-width: 3px;
    }
  `}
`

export const PanelContainer = styled.div`
  padding: 12px;
  color: ${Colors.White};
`

export const Field = styled.div`
  position: relative;
  width: 80px;
  margin: 0 auto;
  ${Typography.Caption};

  &:before {
    content: '#';
    position: absolute;
    left: 0;
    top: 5px;
    width: 16px;
    opacity: 0.4;
    text-align: right;
    pointer-events: none;
  }
`

export const HexInput = styled(HexColorInput)`
  display: block;
  padding: 4px 6px 4px 18px;
  width: 100%;
  background: none;
  border: 1px solid ${Colors.Neutral80};
  border-radius: 4px;
  text-transform: uppercase;
  font-variant-numeric: tabular-nums;
  caret-color: ${Colors.Blue50};

  &::placeholder {
    color: ${Colors.White};
    opacity: 0.4;
  }
`

export const FieldBar = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  border-radius: 0 0 4px 4px;
  opacity: 0;
  transform: scaleX(0);
  background-color: ${Colors.Blue50};
  transition: transform 0.1s 0.1s, opacity 0.1s;
  pointer-events: none;

  ${HexInput}:focus + & {
    opacity: 1;
    transform: scaleX(1);
    transition: transform 0.1s 0s, opacity 0.1s;
  }
`
