import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { CallBackProps } from 'react-joyride'
import { useNavigate } from 'react-router-dom'
import { useUser } from 'builder/hooks/useUser'
import { selectors, actions, CareerInsightsProcessingStatus } from 'builder/modules/careerProfile'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { CareerProfileLink } from 'builder/components/CareerProfile'
import { UploadResumeModal } from 'builder/components/FindJob/UploadResumeModal/UploadResumeModal'
import { FetchStatuses } from 'builder/modules/constants'
import { useCareerInsightsStatus } from 'builder/components/CareerInsights'
import { CreateProfile } from 'builder/components/FindJob/RecommendationsErrors/CreateProfile'
import TARGET_LOCATION from 'builder/views/CareerProfile/TargetRoleDialog/assets/target_location.png'
import { useI18n } from 'builder/hooks/useI18n'
import { TargetLocationImage } from 'builder/components/FindJob/styles'
import { JoyRide } from 'builder/components/JoyRide'
import { CheckProfileModal } from 'builder/components/FindJob/CheckProfileModal'
import {
  FeatureIcon,
  Text,
} from 'builder/components/CareerProfile/components/CareerProfileLink/styles'
import { useQueryParam } from 'builder/hooks/useQueryParam'
import { TargetRoleDialog } from '../../TargetRoleDialog'
import { PageLoader } from '../PageLoader'
import { RECOMMENDATIONS_ONBOARDING_WAS_SEEN_KEY } from '../../types'
import {
  Container,
  Header,
  Content,
  Sidebar,
  LinkList,
  Completeness,
  RecommendedJobsLinkContainer,
} from './styles'
import { MainProfileInfo } from './components/MainProfileInfo'
import { InsightsBanner } from './components/InsightsBanner'
import { ProfileDetails } from './components/ProfileDetails'
import { ProcessingBanner } from './components/ProcessingBanner'
import { ErrorBanner } from './components/ErrorBanner'

export const MyCareerProfileView = () => {
  const user = useUser()
  const dispatch = useDispatch()
  const insightsFetchStatus = useTypedSelector(selectors.fetchCareerInsightsStatus)
  const profileFetchStatus = useTypedSelector(selectors.fetchCareerProfileStatus)
  const insightsProcessingStatus = useCareerInsightsStatus()
  const isCareerAssessmentRequired = useTypedSelector(selectors.isCareerAssessmentRequired)
  const careerProfileData = useTypedSelector(selectors.careerProfileData)
  const [showCreateProfileTargetRole, setShowCreateProfileTargetRole] = useState(false)
  const { i18n } = useI18n()
  const [showUploadResumeModal, setShowUploadResumeModal] = useState(false)
  const [showRecommendationsOnboarding, setShowRecommendationsOnboarding] = useState(false)
  const [showCareerProfileModal, setShowCareerProfileModal] = useState(false)
  const fetchIsNewProfileStatus = useTypedSelector(selectors.fetchIsNewProfileStatus)
  const wasProfilePrefilled = useTypedSelector(selectors.wasProfilePrefilled)
  const navigate = useNavigate()
  const [wasProfileEdited] = useQueryParam('was_profile_edited')

  const isLoading =
    insightsFetchStatus === FetchStatuses.notAsked ||
    insightsFetchStatus === FetchStatuses.loading ||
    profileFetchStatus === FetchStatuses.notAsked ||
    profileFetchStatus === FetchStatuses.loading

  const isInsightsPending = insightsProcessingStatus === CareerInsightsProcessingStatus.pending
  const isInsightsFailed = insightsProcessingStatus === CareerInsightsProcessingStatus.failure

  useEffectOnMount(() => {
    if (profileFetchStatus === FetchStatuses.notAsked) {
      dispatch(actions.fetchCareerProfile())
    }
  })

  useEffect(() => {
    if (
      careerProfileData?.newProfile === true &&
      fetchIsNewProfileStatus === FetchStatuses.notAsked
    ) {
      dispatch(actions.prefillProfileAndFetchProfile())
    }
  }, [careerProfileData, dispatch, fetchIsNewProfileStatus])

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null
    if (
      careerProfileData?.newProfile === false &&
      wasProfileEdited &&
      !localStorage.getItem(RECOMMENDATIONS_ONBOARDING_WAS_SEEN_KEY)
    ) {
      timeout = setTimeout(() => setShowRecommendationsOnboarding(true), 3000)
    }

    return () => {
      timeout && clearTimeout(timeout)
    }
  }, [careerProfileData, wasProfileEdited])

  useEffect(() => {
    if (wasProfilePrefilled) {
      setShowCareerProfileModal(true)
    }
  }, [wasProfilePrefilled])

  const recommendationJoyrideOnCallback = (callbackProps: CallBackProps) => {
    if (['close', 'skip'].includes(callbackProps.action)) {
      localStorage.setItem(RECOMMENDATIONS_ONBOARDING_WAS_SEEN_KEY, 'true')
    }

    if (callbackProps.action === 'close') {
      navigate('/job-search?view=recommendation')
    }
  }

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (
        <Container timeout={0}>
          {user && careerProfileData?.newProfile === false && (
            <Header>
              <MainProfileInfo />
              {isInsightsPending ? (
                <ProcessingBanner />
              ) : isInsightsFailed ? (
                <ErrorBanner />
              ) : isCareerAssessmentRequired ? (
                <InsightsBanner />
              ) : null}
            </Header>
          )}
          {careerProfileData?.newProfile === true && (
            <CreateProfile onClick={() => setShowCreateProfileTargetRole(true)} />
          )}
          <Sidebar>
            <Completeness size="m" />
            <LinkList>
              <CareerProfileLink type="insights" />
              <RecommendedJobsLinkContainer to="/job-search?view=recommendation">
                <FeatureIcon name="JobTracker" />
                <Text>{i18n.t(`builder.career_profile.navigation_links.recommended_jobs`)}</Text>
              </RecommendedJobsLinkContainer>
              <CareerProfileLink type="pitch" />
              <CareerProfileLink type="cover-letter" />
            </LinkList>
          </Sidebar>
          <Content>
            <ProfileDetails />
          </Content>
        </Container>
      )}

      <JoyRide
        run={showRecommendationsOnboarding}
        steps={[
          {
            title: i18n.t(`builder.career_profile.recommendations_joy.title`),
            placement: 'right',
            disableBeacon: true,
            target: '#recommendations',
          },
        ]}
        callback={recommendationJoyrideOnCallback}
        skipButtonLabel={i18n.t(`builder.career_profile.recommendations_joy.skip`)}
        finalButtonLabel={i18n.t(`builder.career_profile.recommendations_joy.button`)}
        showSkipOnLast
        isStepsCountEnabled={false}
      />

      {showCareerProfileModal && (
        <CheckProfileModal
          onClose={() => {
            setShowCareerProfileModal(false)
          }}
          onClick={() => {
            setShowCareerProfileModal(false)
          }}
          eventLabel="career_profile"
        />
      )}

      {showCreateProfileTargetRole && (
        <TargetRoleDialog
          setClose={() => setShowCreateProfileTargetRole(false)}
          includeLocation
          eventLabel="career_profile"
          showSkip
          locationData={{
            buttonText: i18n.t('builder.career_profile.creation.target_location.button'),
            subtitle: i18n.t('builder.career_profile.creation.target_location.subtitle'),
            Image: <TargetLocationImage src={TARGET_LOCATION} alt="target-location" />,
          }}
          roleData={{
            buttonText: i18n.t('builder.career_profile.creation.target_role.button'),
            subtitle: i18n.t('builder.career_profile.creation.target_role.subtitle'),
          }}
          handleAfterSave={() => setShowUploadResumeModal(true)}
        />
      )}

      {showUploadResumeModal && (
        <UploadResumeModal
          onClose={() => setShowUploadResumeModal(false)}
          goBack={() => {
            setShowUploadResumeModal(false)
            setShowCreateProfileTargetRole(true)
          }}
          eventLabel="career_profile"
          redirectUrl="/career-profile/edit?returnUrl=/career-profile/profile?was_profile_edited=true"
        />
      )}
    </>
  )
}
