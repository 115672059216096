import { forwardRef, memo } from 'react'
import { HexColorPicker } from 'react-colorful'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { Container, PanelContainer, Field, HexInput, FieldBar } from './styles'

interface Props {
  hex?: string
  onChange?: (color: string) => void
}

const ColorPicker = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { hex, onChange, ...rest } = props
  const { isTablet } = useMediaQueries()

  // Do not pass `null` as `color` property value to the picker components.
  // Happens when user changes a template and the new template forces `resume.color` to `null`.
  // `defaultProps` don't handle that, they use a fallback value only if prop value is `undefined`.
  const color = hex === null ? undefined : hex

  return (
    <Container ref={ref} {...rest}>
      <HexColorPicker color={color} onChange={onChange} />

      {/* Mobile picker is bigger but doesn't have the editable field */}
      {!isTablet && (
        <PanelContainer>
          <Field>
            <HexInput color={color} onChange={onChange} />
            <FieldBar />
          </Field>
        </PanelContainer>
      )}
    </Container>
  )
})

export default memo(ColorPicker)
