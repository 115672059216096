import FEEDBACK_SUBMITTED_IMAGE from '../../../../../../../app/assets/images/subscription_cancellation/feedback_submitted.png'
import { useCancellationFormContext } from '../FormSection'
import { PromotionTitle } from '../PromotionSection/PromotionTitle'
import { FeedbackSubmitContainer } from './Container'

/**
 * The dimensions for the styles used for each component have been referred from the figma
 * https://www.figma.com/file/wdX0fbRVlodq1Is8l1GwoV/APP-1372%3A-Subscription-cancelation?node-id=1%3A2&t=sJ8R8w5Ggv06RqH5-0
 */

const FeedbackSubmitResult = () => {
  const { successfulSubmit, promotionTitleTopic, promotionTitle } = useCancellationFormContext()

  return (
    <FeedbackSubmitContainer>
      {successfulSubmit && <img src={FEEDBACK_SUBMITTED_IMAGE} alt="" />}
      <PromotionTitle topic={promotionTitleTopic}>{promotionTitle}</PromotionTitle>
    </FeedbackSubmitContainer>
  )
}

export default FeedbackSubmitResult
