import { useI18n } from 'builder/hooks/useI18n'
import { TooltipQuestion } from 'builder/components/Tooltip/variations'

import { SubscriptionStatus, Weeks, selectors } from 'builder/modules/resumeDistribution'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useUser } from 'builder/hooks/useUser'

import { Box, Header, Section, SubHeader, TooltipWrapper } from '../styles'
import { DistributionCycle } from '../DistributionCycle/DistributionCycle'
import { TRANSLATION } from '../constants'

type IProps = {
  totalRecruiters: number
  distributionWeek: Weeks
  isFromWidget?: boolean
}

export const RecruitersInfo = ({ totalRecruiters, distributionWeek, isFromWidget }: IProps) => {
  const user = useUser()
  const { i18n } = useI18n()

  const candidate = useTypedSelector(selectors.candidate)
  const isStatusPaused = candidate?.distributionStatus === SubscriptionStatus.paused && isFromWidget

  return (
    <Section>
      <Box>
        <Header
          $isFromWidget={isFromWidget}
          $isDisabled={(isStatusPaused || !user?.hasPremiumFeatures) && isFromWidget}
        >
          {totalRecruiters}
        </Header>
        <SubHeader>
          {i18n.t(`${TRANSLATION}.recruiters_review${isFromWidget ? '_widget' : ''}`)}
          <TooltipWrapper>
            <TooltipQuestion textAlign="left" multiline width={isFromWidget ? 285 : 300}>
              {i18n.t(`${TRANSLATION}.recruiters_review_tooltip`, { number: totalRecruiters })}
            </TooltipQuestion>
          </TooltipWrapper>
        </SubHeader>
      </Box>
      <DistributionCycle isFromWidget={isFromWidget} distributionWeek={distributionWeek} />
    </Section>
  )
}
