import * as Styled from './styles'
const playButtton = require('../../assets/PlayIcon.svg')

export const AceJobInterview = ({ userPhotoUrl }: { userPhotoUrl: string | undefined }) => {
  return (
    <Styled.AceJobContainer>
      <Styled.AceJobUserImageContainer>
        <Styled.AceJobImage src={userPhotoUrl} />
        <Styled.AceJobPlayImageContainer>
          <Styled.AceJobPlayImage src={playButtton} />
        </Styled.AceJobPlayImageContainer>
      </Styled.AceJobUserImageContainer>
    </Styled.AceJobContainer>
  )
}
