import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Icon, { Icon20 } from 'builder/components/Icon'
import { rotation } from 'builder/styles/keyframes'
import Media from 'builder/styles/media'
import Button from 'builder/components/Button'

export const Div = styled.div``
export const FlexBox = styled.div`
  display: flex;
`

// LinkedInPrefill styles
export const Heading = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral90};
  text-align: center;
  margin-bottom: 12px;
  ${Media.Phone`
    ${Typography.S};
    margin-bottom: 8px;
  `}
`

export const SubHeading = styled.div`
  color: ${Colors.Neutral50};
  text-align: center;
  ${Typography.Body};
  margin-bottom: 40px;
  ${Media.Phone`
    margin-bottom: 32px;
  `}
`

export const DesktopButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 32px;
  ${Media.Phone`
    display: none;
  `}
`

export const MobileButtonContainer = styled.div`
  display: none;
  ${Media.Phone`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: 21px;
  `}
`

export const CancelButton = styled(Button)`
  padding: 12px 24px;
  ${Media.Phone`
    display: none;
  `}
`

export const ContinueButton = styled(Button)(({ isDisabled }) => {
  return css`
    width: 120px;
    padding: 12px 24px;
    background-color: ${isDisabled ? Colors.Neutral20 : Colors.Indigo50};
    ${Typography.Body};
    ${FontWeights.Medium};
    &:hover {
      background-color: ${Colors.Indigo60};
    }
    ${Media.Phone`
      width: 100%;
    `}
  `
})

export const TextFieldContainer = styled.div`
  width: 100%;
`

export const ValidationLoadingIcon = styled(Icon.Spinner)`
  color: ${Colors.Blue50};
  animation: ${rotation} 0.5s linear infinite;
`

export const ValidationSuccessIcon = styled(Icon.Success)`
  fill: ${Colors.Green50};
`

export const ValidationFailIcon = styled(Icon20.WarningCircle)`
  color: ${Colors.Red50};
  width: 16px;
  height: 16px;
`
export const SkipButton = styled(Button)`
  box-shadow: none;
  &:hover {
    box-shadow: none;
  }
`

export const LoadingIcon = styled(Icon.Spinner)`
  color: ${Colors.Neutral50};
  animation: ${rotation} 0.5s linear infinite;
`

// LinkedInProfile styles
export const LinkedInProfileHeading = styled(Heading)`
  margin-bottom: 42px;
  ${Media.Phone`
    margin-bottom: 32px;
  `}
`
export const LinkedInProfileCard = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 15.5px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid ${Colors.Neutral10};
  background: ${Colors.Neutral5};
`

export const Name = styled.div`
  ${Typography.Body};
  color: ${Colors.Neutral90};
  margin-bottom: 4px;
`

export const Designation = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
`

export const Avatar = styled.img`
  width: 52px;
  height: 52px;
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;
  width: 100%;
  justify-content: space-between;
  ${Media.Phone`
    margin-top: auto;
    margin-bottom: 45px;
    flex-direction: column-reverse;
    gap: 16px;
  `}
`
export const TryAnotherLink = styled.div`
  ${Typography.Caption}
  ${FontWeights.DemiBold};
  color: ${Colors.Blue50};
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 9px;
`

export const Revert = styled(Icon.Revert)`
  color: ${Colors.Blue50};
  height: 20px;
  width: 20px;
`

export const GenerateButton = styled(Button)`
  padding: 12px 24px;
  background: ${Colors.Indigo50};
  ${Typography.Body};
  ${FontWeights.Medium};
  &:hover {
    background-color: ${Colors.Indigo60};
  }
  ${Media.Phone`
    width: 100%;
  `}
`

// LinkedInError styles
export const GoToQuestionnaireButton = styled(GenerateButton)``
export const LinkedInErrorHeading = styled(Heading)`
  margin-bottom: 12px;
  ${Media.Phone`
    ${Typography.S};
    margin-bottom: 8px;
  `}
`
export const LinkedInErrorSubheading = styled(SubHeading)``

export const LinkedInErrorImg = styled.img`
  width: 88px;
  height: 120px;
  margin-top: 48px;
  margin-bottom: 12px;
  ${Media.Phone`
    margin-top: 100px;
    margin-bottom: 24px;
  `}
`
