export class AudioPlayerUtils {
  bufferSource: AudioBufferSourceNode
  startedAudio = false
  stopSound() {
    if (this.startedAudio) {
      this.bufferSource.stop()
      this.startedAudio = false
    }
  }

  private async playAudioFromAudioBuffer(audioCtx: AudioContext, audioData: AudioBuffer) {
    return new Promise(resolve => {
      this.bufferSource = audioCtx.createBufferSource()
      this.bufferSource.buffer = audioData
      const gainNode = audioCtx.createGain()
      this.bufferSource.connect(gainNode)
      gainNode.connect(audioCtx.destination)
      this.bufferSource.start()
      this.startedAudio = true
      this.bufferSource.onended = resolve
    })
  }

  async playAudioFromBlob(blob: Blob) {
    if (blob.type !== 'audio/mpeg') {
      return
    }
    const audioCtx = new AudioContext()
    return new Promise<void>(resolve => {
      const reader = new FileReader()

      reader.readAsArrayBuffer(blob)
      reader.onload = async event => {
        const arrayBuffer = event.target?.result
        if (arrayBuffer instanceof ArrayBuffer) {
          const audioData = await audioCtx.decodeAudioData(arrayBuffer)
          await this.playAudioFromAudioBuffer(audioCtx, audioData)
        }
        resolve()
      }
    })
  }
}
