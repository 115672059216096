import { useMemo } from 'react'
import { useSpring, config } from '@react-spring/web'
import { i18n as I18n } from 'builder/utils/i18n'
import { formatDocumentPreviewUrl } from 'builder/utils/formatDocumentPreviewUrl'
import { useResume } from 'builder/hooks/useResume'
import Logo from '../../../Images/CompanyLogo.svg'
import {
  Container,
  CompanyLogo,
  CompanyDesignation,
  ScoreContainer,
  LeftContainer,
  Percent,
  Progress,
  ProgressInner,
  PercentWrapper,
  PercentTitle,
  ResumePreview,
} from './styles'

interface Props {
  resumeId: number
  resumeScore: number
  tailoredDesignation: string
}

const ResultView = ({ resumeId, resumeScore, tailoredDesignation }: Props) => {
  const resume = useResume(resumeId)
  const previewImageSrc = useMemo(() => {
    if (!resume) return null

    return formatDocumentPreviewUrl(resume, { size: 352 })
  }, [resume])

  const props = useSpring({
    config: config.slow,
    from: { resumeScore: 0 },
    to: { resumeScore: Math.min(resumeScore, 100) },
  })

  const matchStatus = useMemo(() => {
    if (resumeScore < 30) {
      return I18n.t('builder.resume_optimizer.job_posting.poor_match.score_title')
    } else if (resumeScore > 70) {
      return I18n.t('builder.resume_optimizer.job_posting.good_match.score_title')
    } else {
      return I18n.t('builder.resume_optimizer.job_posting.normal_match.score_title')
    }
  }, [resumeScore])

  return (
    <Container score={resumeScore}>
      <LeftContainer>
        <CompanyLogo size={48} src={Logo} />
        <ScoreContainer>
          <CompanyDesignation>{tailoredDesignation}</CompanyDesignation>
          <PercentWrapper>
            <Percent>{resumeScore}</Percent>
            <PercentTitle>{matchStatus}</PercentTitle>
          </PercentWrapper>
        </ScoreContainer>
      </LeftContainer>
      <Progress>
        <ProgressInner
          style={{
            transform: props.resumeScore.to(value => `scaleX(${value / 100})`),
          }}
        />
      </Progress>
      <ResumePreview src={previewImageSrc} />
    </Container>
  )
}

export default ResultView
