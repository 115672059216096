import styled, { keyframes } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Icon24 } from 'builder/components/Icon'
import Media from 'builder/styles/media'
import Button from 'builder/components/Button'

const { Blue50, Neutral10, Neutral50, Neutral70, White } = Colors

const moveUp = keyframes`
  from {
    transform: translateY(70%);
  }
  to {
    transform: translateY(0%);
  }
`

export const Container = styled.div`
  width: 544px;
  border-radius: 16px;
  background: ${White};
  border: 8px;
  padding: 32px;
  animation: ${moveUp} 0.5s ease-out forwards;

  ${Media.Phone`
    width: 343px;
    padding:16px
  `};
`

export const Title = styled.div`
  width: 436px;
  margin-right: 16px;
  ${Typography.S}
  ${FontWeights.DemiBold};
`

export const CloseIcon = styled(Icon24.Add)`
  background: ${Neutral10};
  fill: ${Neutral70};
  border-radius: 50%;
  height: 28px;
  width: 28px;
  cursor: pointer;
  transform: rotate(225deg);

  &:hover {
    background: ${Blue50};
    fill: ${White};
  }

  ${Media.Phone`
    height: 23px;
    width: 35px;
  `}
`

export const Content = styled.div`
  margin-top: 12px;
  color: ${Neutral50};
  ${Typography.Body}
  ${FontWeights.Regular};
  ${Media.Phone`
    margin-top: 8px;
  `}
`

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 32px;

  ${Media.Phone`
    margin-top: 20px;
  `}
`

export const ActionButton = styled(Button)`
  ${Media.AbovePhone`
    padding: 12px 24px;
  `}
`

export const TopWrapper = styled.div`
  display: flex;
`
