import { memo, Fragment, useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import useDebouncedEffect from 'use-debounced-effect'
import ColorSelect from 'builder/components/ColorSelect'
import ColorPickerControl from './ColorPickerControl'

// Wrapper to update and render selected colors instantly.
// Calls the parent's callback with a debounce
const Colors = ({ selected, options, supportsCustomColor, onSelect }) => {
  const [isOpen, setOpen] = useState(false)
  const [localColor, setLocalColor] = useState(selected)

  // Update local value using incoming props
  useEffect(() => {
    setLocalColor(selected)
  }, [selected])

  // Debounce the callback to avoid extra updates and re-renders of `DocumentCustomizationModal`
  useDebouncedEffect(
    () => {
      if (localColor !== selected) onSelect(localColor)
    },
    350,
    [localColor],
  )

  const handleChange = useCallback(value => setLocalColor(value), [])
  const handlePickerOpen = useCallback(() => setOpen(true), [])
  const handlePickerClose = useCallback(() => setOpen(false), [])

  return (
    <Fragment>
      <ColorSelect
        selected={localColor}
        options={options}
        onSelect={onSelect}
        onAdd={supportsCustomColor ? handlePickerOpen : null}
      />
      {isOpen && (
        <ColorPickerControl hex={localColor} onChange={handleChange} onClose={handlePickerClose} />
      )}
    </Fragment>
  )
}

Colors.propTypes = {
  options: PropTypes.array,
  selected: PropTypes.string,
  supportsCustomColor: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
}

export default memo(Colors)
