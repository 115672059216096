import { useCallback } from 'react'
import { ResumeLanguage } from 'packages/types'
import { CardContentWrapper } from 'builder/components/Card/styles'
import EditorRow from 'builder/components/EditorRow'
import { LanguageLevelData } from 'builder/modules/generalEditor'
import Base from 'builder/components/Card/Base'
import { useI18n } from 'builder/hooks/useI18n'
import ExpandableCardContext from '../../ExpandableCardContext'
import SelectLevel from './SelectLevel'
import Certificate from './Certificate'
import CertificateDate from './CertificateDate'
import Language from './Language'

interface Props {
  levels: {
    language?: LanguageLevelData[]
  }
  item: ResumeLanguage
  sectionId: string
  onChange: (sectionId: string, cardId: number, values: unknown, debounce: boolean) => void
}

export const LanguageCard = (props: Props): JSX.Element => {
  const { levels, item, sectionId, onChange, ...restProps } = props
  const { language, level, id } = item
  const { i18n } = useI18n()

  const cardTitle = language || i18n.t('builder.resume_editor.not_specified')
  const cardSubtitle = level ? i18n.t(`builder.resume_editor.language_level.${level}`) : ''

  const onCardValueChange = useCallback(
    (values: unknown, debounce?: boolean) => {
      onChange(sectionId, id as number, values, debounce as boolean)
    },
    [onChange, sectionId, id],
  )

  return (
    <Base
      {...{ title: cardTitle, subtitle: cardSubtitle, item, sectionId, onChange, ...restProps }}
    >
      <ExpandableCardContext.Provider value={{ item, sectionId, onCardValueChange }}>
        <CardContentWrapper>
          <EditorRow>
            <Language />
            <SelectLevel {...{ levels }} />
          </EditorRow>
          <EditorRow>
            <Certificate />
            <CertificateDate />
          </EditorRow>
        </CardContentWrapper>
      </ExpandableCardContext.Provider>
    </Base>
  )
}
