import { trackInternalEvent } from '@rio/tracking'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { JobSearchNotificationType } from 'builder/modules/jobSearch/types'
import { actions as jobSearchActions } from 'builder/modules/jobSearch'
import { TooltipV2 } from 'builder/components/Tooltip'
import Icon24 from 'builder/components/Icon'
import Toggle from 'builder/components/Toggle'
import EmailImgSrc from '../../assets/email.png'
import { useMutationRecommendationAlert } from '../../hooks/useMutationRecommendationAlert'
import { getLabel } from '../../getLabel'
import {
  Container,
  ContainerImage,
  ContainerText,
  ContainerTooltip,
  ContainerTooltipItems,
  EmailImg,
  MobileContainer,
  SubTitle,
  Title,
} from './styles'

const TRANSLATION = 'builder.job_search.alert_modal'

interface Props {
  isEnabled: boolean
}
export const RecommendedAlert = ({ isEnabled }: Props) => {
  const { i18n } = useI18n()
  const { isPhone } = useMediaQueries()
  const mutationRecommendationAlert = useMutationRecommendationAlert()
  const dispatch = useDispatch()

  const [isChecked, setIsChecked] = useState(isEnabled)

  useEffect(() => setIsChecked(isEnabled), [isEnabled])

  const handleToggleChange = async () => {
    try {
      const newValue = !isChecked
      setIsChecked(newValue)
      trackInternalEvent('switch_recommended_alert', {
        label: getLabel(),
        parameter: newValue ? 'on' : 'off',
      })
      await mutationRecommendationAlert.mutateAsync({ active: newValue })
    } catch (e) {
      dispatch(jobSearchActions.setNotification(JobSearchNotificationType.snackbar_fail))
    }
  }
  const text = (
    <ContainerText>
      <Title> {i18n.t(`${TRANSLATION}.card_recommended.title`)}</Title>

      <SubTitle>{i18n.t(`${TRANSLATION}.card_recommended.subtitle`)}</SubTitle>
    </ContainerText>
  )

  const tooltipAndToggle = (
    <ContainerTooltip>
      <TooltipV2
        position="top"
        zIndex={12001}
        multiline
        content={i18n.t(`${TRANSLATION}.card_recommended.tooltip`)}
      >
        <ContainerTooltipItems>
          <Icon24.Info />
          <Toggle
            disabled={mutationRecommendationAlert.isLoading}
            onClick={handleToggleChange}
            checked={isChecked}
          />
        </ContainerTooltipItems>
      </TooltipV2>
    </ContainerTooltip>
  )

  if (isPhone) {
    return (
      <Container>
        <MobileContainer>
          <EmailImg src={EmailImgSrc} />
          {tooltipAndToggle}
        </MobileContainer>
        {text}
      </Container>
    )
  }
  return (
    <Container>
      <ContainerImage>
        <EmailImg src={EmailImgSrc} />
      </ContainerImage>
      {text}
      {tooltipAndToggle}
    </Container>
  )
}
