import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Button from 'builder/components/Button'
import { TextAreaField } from 'builder/components/TextField'
import { Icon20 } from 'builder/components/Icon'

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 420px;
  text-align: center;
`

export const Step = styled.div<{ isVisible?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
  transition: opacity 0.2s, visibility 0.2s;
`

export const Content = styled(Step)``

export const Title = styled.div`
  ${Typography.S};
  font-weight: 600;
  margin-bottom: 8px;
`

export const Subtitle = styled.div`
  max-width: 400px;
`

export const Rating = styled.div`
  width: 100%;
  max-width: 360px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0;

  ${Media.Phone`
    padding: 0 8px;
  `};
`

export const RatingItemIcon = styled.div<{ index: number }>`
  width: 40px;
  height: 40px;
  transition: transform 0.1s ease;
  background-image: url(${require('images/builder/trustpilot/faces_sprite.png')});
  background-size: cover;
  background-position-x: ${({ index }) => `${-40 * index}px`};

  ${props => Media.Phone`
    width: 32px;
    height: 32px;
    background-position-x: ${-32 * props.index}px;
  `};
`

export const RatingItem = styled.div<{ isActive: boolean; isDisabled: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  transition: opacity 0.1s ease;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.4;
    `}

  & ${RatingItemIcon} {
    ${({ isActive }) =>
      isActive &&
      css`
        transform: scale(1.25);
      `}
  }

  &:hover ${RatingItemIcon} {
    transform: scale(1.25);
  }
`

export const RatingItemText = styled.div`
  position: absolute;
  bottom: -24px;
  min-width: 80px;
  left: calc(50% - 40px);
  ${Typography.Tiny};
  color: ${Colors.Neutral50};
`

export const Form = styled(Step)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`

export const FormTitle = styled.div`
  margin-bottom: 20px;
  ${Typography.Subhead};
  font-weight: 600;
`

export const TextArea = styled(TextAreaField)`
  width: 100%;
  margin-bottom: 24px;
`

export const SendButton = styled(Button)``

export const Success = styled(Step)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`

export const SuccessIcon = styled(Icon20.Tick)`
  margin-bottom: 20px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 4px solid ${Colors.Green20};
  background-color: ${Colors.Green20};
  color: ${Colors.Green50};
`
