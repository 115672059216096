import axios, { AxiosError, AxiosResponse } from 'axios'
import { CoverLetter, UserDocumentTemplate } from '@rio/types'
import { trackInternalEvent } from '@rio/tracking'
import getByPath from 'lodash/get'
import { all, put, call, take, takeEvery, takeLatest, select, delay } from 'redux-saga/effects'
import { apiClient, baseClient } from 'builder/modules/apiClient'
import { actions as renderingActions } from 'builder/modules/rendering'
import { actions as userActions } from 'builder/modules/user'
import PreviewReloadService from 'builder/services/PreviewReloadService'
import ErrorLogger from 'builder/services/ErrorLogger'
import { CoverLetterRegenerationStatus } from '../constants'
import { actions, selectors } from './coverLetterModule'

const DEBOUNCE_DELAY = 300

function* fetchCoverLetterSaga({ payload }: { payload: { id: number } }) {
  try {
    const url = `/cover-letters/${payload.id}`
    const { data: coverLetter }: AxiosResponse<CoverLetter> = yield call(apiClient.get, url)

    yield put(renderingActions.checkDocument(coverLetter))
    yield take(renderingActions.checkDocumentComplete)

    yield put(actions.fetchCoverLetterSuccess(coverLetter))
  } catch (error) {
    // Customize the error message for 404
    if (axios.isAxiosError(error) && error.response?.status === 404) {
      const axiosError = error as AxiosError
      axiosError.message = `${axiosError.message}. The requested cover letter might not exist.`
      ErrorLogger.log(axiosError)
    } else ErrorLogger.log(error)
    yield put(actions.fetchCoverLetterFail(error as Error))
  }
}

function* updateCoverLetterSaga({ payload }: ReturnType<typeof actions.updateSimpleField>) {
  try {
    if (payload.debounce === true) yield delay(DEBOUNCE_DELAY)

    const coverLetter: CoverLetter = yield select(selectors.coverLetter)

    let color = coverLetter.color

    // Override cover letter color if user changed its template
    if (payload.name === 'template') {
      const templates: UserDocumentTemplate[] = yield select(selectors.templates)
      const template = templates.find(t => t.id === coverLetter.template)
      color = getByPath(template, 'settings.color.default', null)
    }

    // Send the patched cover letter to the backend
    const { data: updatedCoverLetter }: AxiosResponse<CoverLetter> = yield call(
      apiClient.patch,
      `/cover-letters/${coverLetter.id}`,
      { ...coverLetter, color },
    )

    // Handle on critical changes (like switching to Chinese language)
    yield put(renderingActions.checkDocument(updatedCoverLetter))
    yield take(renderingActions.checkDocumentComplete)

    // Update the cover letter in the store
    yield put(actions.updateCoverLetterSuccess(updatedCoverLetter))

    // Reload the preview (immediately if template was changed)
    const refresh = ['template', 'color'].includes(payload.name)
    PreviewReloadService.reloadResume(coverLetter, refresh)
  } catch (error) {
    ErrorLogger.log(error)
    yield put(actions.updateCoverLetterFail(error as Error))
  }
}

function* regenerateCoverLetterSaga({
  payload,
}: ReturnType<typeof actions.fetchCoverLetterRequest>) {
  try {
    yield put(actions.setAIRegenerationStatus(CoverLetterRegenerationStatus.generating))
    const {
      status,
      data: { success },
    }: {
      status: number
      data: { success: boolean }
    } = yield call(baseClient.post, `/job-postings/regenerate-cover-letter`, null, {
      params: {
        id: payload.id,
      },
    })
    if (status === 200 && success) {
      yield call(fetchCoverLetterSaga, { payload: { id: payload.id } })

      const coverLetter: CoverLetter = yield select(selectors.coverLetter)
      if (coverLetter.content) {
        yield put(actions.updateSimpleField({ name: 'openCl', value: true }))
      }

      yield put(actions.setShowConfirmRegenerateModal(false))
      yield put(actions.setAIRegenerationStatus(CoverLetterRegenerationStatus.generated))
      // To refresh the user attempts
      yield put(userActions.fetchUserRequest({ silent: true }))
      trackInternalEvent(`generate_cover_letter_succesfully`)
    } else {
      yield put(actions.setShowConfirmRegenerateModal(false))
      yield put(actions.setAIRegenerationStatus(CoverLetterRegenerationStatus.failed))
    }
  } catch (error) {
    yield put(actions.setAIRegenerationStatus(CoverLetterRegenerationStatus.failed))
    // Customize the error message for 404
    if (axios.isAxiosError(error) && error.response?.status === 404) {
      const axiosError = error as AxiosError
      axiosError.message = `${axiosError.message}. The requested cover letter might not exist.`
      ErrorLogger.log(axiosError)
    } else ErrorLogger.log(error)
    yield put(actions.fetchCoverLetterFail(error as Error))
  }
}

// Export
export const sagas = function* saga() {
  yield all([
    takeEvery(actions.fetchCoverLetterRequest, fetchCoverLetterSaga),
    takeLatest(actions.updateSimpleField, updateCoverLetterSaga),
    takeEvery(actions.regenerateCoverLetter, regenerateCoverLetterSaga),
  ])
}
