import styled, { css } from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Button from 'builder/components/Button'

export const ModalContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 544px;
  padding: 48px;
  border-radius: 6px;
  background-color: ${Colors.White};
`

export const ModalClose = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  padding: 16px;
  color: ${Colors.Neutral30};
  cursor: pointer;

  &:hover {
    color: ${Colors.Blue50};
  }
`

export const ModalScore = styled.div<{ color: string; progress: number }>`
  position: relative;
  overflow: hidden;
  width: 276px;
  height: 138px;
  margin: 0 auto 24px;
  border-radius: 138px 138px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  ${props => css`
    color: ${props.color};
    background-image: conic-gradient(
      from -90deg at 50% 100%,
      ${props.color} 0% ${props.progress * 50}%,
      ${hexToRgba(props.color, 0.2)} 0% 100%
    );

    &:before {
      content: '';
      position: absolute;
      top: 8px;
      left: 8px;
      right: 8px;
      bottom: 0;
      background-color: #fff;
      background-image: linear-gradient(
        to bottom,
        ${hexToRgba(props.color, 0.16)} 0%,
        ${Colors.White} 100%
      );
      border-radius: 130px 130px 0 0;
    }
  `}
`

export const ModalScoreContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ModalScoreEmoji = styled.div<{ emoji: string }>`
  margin-right: 12px;
  width: 40px;
  height: 40px;
  background-image: url(${props => require(`images/builder/panel/tuner/${props.emoji}.svg`)});
`

export const ModalScoreValue = styled.div`
  ${Typography.XL};
  font-weight: 700;
`

export const ModalScoreLabel = styled.div`
  position: relative;
  margin-top: 8px;
  ${Typography.Caption};
`

export const ModalTitle = styled.div`
  margin-bottom: 8px;
  ${Typography.M};
  font-weight: 600;
  text-align: center;
`

export const ModalText = styled.div`
  margin-bottom: 32px;
  text-align: center;
`

export const ModalTasks = styled.div`
  margin: 0 -48px -48px;
`

export const ModalTask = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 24px;
  border-top: 1px solid ${Colors.Neutral30};
  cursor: pointer;

  &[data-done='true'] {
    pointer-events: none;
  }
`

export const ModalTaskContent = styled.div`
  flex-grow: 1;
`

export const ModalTaskHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

export const ModalTaskTitle = styled.div`
  margin-right: 8px;
  font-weight: 600;
  transition: color 0.2s;

  ${ModalTask}:hover & {
    color: ${Colors.Blue50};
  }

  [data-done='true'] & {
    text-decoration: line-through;
    color: ${Colors.Neutral50};
  }
`

export const ModalTaskScore = styled.div`
  padding: 2px 4px;
  border-radius: 4px;
  background-color: ${hexToRgba(Colors.Green60, 0.12)};
  ${Typography.Tiny};
  font-weight: 600;
  color: ${Colors.Green60};

  [data-done='true'] & {
    background-color: ${hexToRgba(Colors.Neutral50, 0.12)};
    color: ${Colors.Neutral50};
  }
`

export const ModalTaskText = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
`

export const ModalTaskSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-left: 32px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${Colors.Blue50};
  color: ${Colors.White};
  transition: background-color 0.2s;

  ${ModalTask}:hover & {
    background-color: ${Colors.Blue60};
  }

  [data-done='true'] & {
    background-color: ${Colors.Green50};
  }
`

export const ModalActions = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ModalButton = styled(Button)`
  width: calc(50% - 6px);
  gap: 8px;
`
