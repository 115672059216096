import styled, { css } from 'styled-components'
import { Spinner } from 'builder/components/Spinner'
import { FontWeights, Typography } from 'builder/styles/typography'

const AbsoluteCenter = css`
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
`

export const SpinnerContainer = styled(Spinner)`
  ${AbsoluteCenter};
`

export const Container = styled.div`
  ${AbsoluteCenter};
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Header = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Title = styled.div`
  ${Typography.S};
  ${FontWeights.Medium};
`

export const StaticText = styled.div`
  ${FontWeights.Medium};
`
