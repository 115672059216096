import styled from 'styled-components'
import Media from 'builder/styles/media'

export const Wrapper = styled.div`
  width: 100%;
  gap: 16px;
  display: flex;
  flex-direction: column;

  ${Media.Desktop`
    max-width: 544px;
  `}

  ${Media.Tablet`
    padding-top: 48px;
  `}

  ${Media.Phone`
    padding-top: 32px;
  `}
`

export const Container = styled.div`
  padding: 0;
  width: 100%;
  gap: 96px;
  display: flex;
  flex: auto;
  flex-direction: column;

  ${Media.Tablet`
    gap: 32px;
  `}

  ${Media.Phone`
    gap: 32px;
  `}
`

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;

  ${Media.Tablet`
    gap: 24px;
    padding-bottom: 32px;
  `}

  ${Media.Phone`
    padding-bottom: 16px;
  `};
`

export const Contents = styled.div<{ value: number }>`
  gap: 48px;
  display: flex;
  flex-direction: column;
`
