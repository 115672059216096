import { useMutation } from '@tanstack/react-query'
import { DeepPartial } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { baseClient } from 'builder/modules/apiClient'
import { AutoApplyForm } from 'builder/modules/autoApply/types'
import { actions } from 'builder/modules/autoApply/autoApplyModule'
import { mapCareerResponse } from './mappers'

export const usePatchForm = () => {
  const dispatch = useDispatch()
  return useMutation({
    mutationFn: async (params: DeepPartial<AutoApplyForm>) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { firstName: dump, lastName: dump2, ...filtered } = params

      const response = await baseClient.patch(`/career/v1/profile`, {
        careerProfile: filtered,
      })
      const mappedData = mapCareerResponse(response.data)
      dispatch(actions.updateForm(mappedData))
      return response.data
    },
  })
}
