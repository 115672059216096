import { ReactElement } from 'react'
import { ListWrapper } from './styles'

interface ListProps {
  children: ReactElement | ReactElement[]
}

export const List = ({ children }: ListProps) => {
  return <ListWrapper>{children}</ListWrapper>
}
