import Spinner from 'builder/components/Spinner'
import {
  DocumentPlaceholderOverlay,
  DocumentThumbnail,
  ThumbnailPlaceholderContainer,
} from './styles'
import DocumentIcon from './Icons/ResumeThumbnail.png'

export const DocumentThumbnailPlaceholder = () => {
  return (
    <ThumbnailPlaceholderContainer>
      <DocumentPlaceholderOverlay>
        <Spinner />
      </DocumentPlaceholderOverlay>
      <DocumentThumbnail src={DocumentIcon} />
    </ThumbnailPlaceholderContainer>
  )
}
