import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { avatarUrl } from 'builder/modules/careerCoaching/constants'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import ModalOverlay from 'builder/components/ModalOverlay'
import { selectors as userSelectors } from 'builder/modules/user'
import { actions } from 'builder/modules/careerCoaching'
import { Container, Content, TopContent, Header, SubHeader, Avatar, StyledButton } from './styles'

interface Coach {
  id: number | null
  firstName: string
  lastName: string
  location: string
  aboutMe: string
  coachAvatar: string
  careerStages: string[]
}

interface Props {
  handleConfirmationModalClose: () => void
  coachDetails: Coach
}

const CoachConfirmationModal = ({
  handleConfirmationModalClose,
  coachDetails,
}: Props): JSX.Element => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useTypedSelector(userSelectors.userData)

  const handleConfirmation = useCallback(() => {
    dispatch(actions.setUserRedirectState(false))

    handleConfirmationModalClose()
    navigate(`/sessions`)
  }, [dispatch, handleConfirmationModalClose, navigate])

  return (
    <ModalOverlay>
      <Container data-testid="coach-confirmed-modal">
        <Content>
          <TopContent>
            <Avatar src={coachDetails.coachAvatar || avatarUrl} />
            <Header>Confirmed</Header>
            <SubHeader
              dangerouslySetInnerHTML={{
                __html: `You’ve chosen ${coachDetails?.firstName} ${coachDetails?.lastName}
              as your coach. They’ll send a scheduling link to your
              email, <b>${user?.email}</b>, within 24 hours.`,
              }}
            />
            <StyledButton size="small" onClick={handleConfirmation}>
              Okay
            </StyledButton>
          </TopContent>
        </Content>
      </Container>
    </ModalOverlay>
  )
}

export default CoachConfirmationModal
