import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { actions, selectors } from 'builder/modules/resumeEditor'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

export const useOverviewModal = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const resume = useTypedSelector(selectors.resume)
  const isApproveModalOpen = useTypedSelector(selectors.isAutoTailoringApproveModalOpen)
  const resumeOptimizerData = useTypedSelector(selectors.jobPostingAPIData)
  const score = resumeOptimizerData?.averageScore
  const approveTailoredResumeStatus = useTypedSelector(selectors.approveAutoTailoredResumeStatus)
  const isResumeLoading = useTypedSelector(selectors.isResumeLoading)

  const handleOpenApproveModal = useCallback(() => {
    dispatch(actions.setAutoTailoringApproveModalStatus(true))
  }, [dispatch])

  const handleCloseApproveModal = useCallback(() => {
    dispatch(actions.setAutoTailoringApproveModalStatus(false))
  }, [dispatch])

  const handleRetryClick = useCallback(() => {}, [])

  const handleApprove = useCallback(() => {
    dispatch(actions.approveResume())
  }, [dispatch])

  const handleLeave = useCallback(() => {
    navigate('/?tab=resume&autotailored=true')
  }, [navigate])

  return {
    score,
    resume,
    isApproveModalOpen,
    approveTailoredResumeStatus,
    isResumeLoading,
    handleRetryClick,
    handleOpenApproveModal,
    handleCloseApproveModal,
    handleApprove,
    handleLeave,
  }
}
