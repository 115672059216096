import { i18n } from 'builder/utils/i18n'
import { SectionNames } from 'builder/modules/resumeEditor'
import FocusManager from 'builder/services/FocusManager'
import { convertHtmlToText } from 'builder/utils/convertHtmlToText'
import { CheckFn } from '../types'

export const checkSummaryContent: CheckFn = ({ position, profile }) => {
  const summaryText = convertHtmlToText(profile || '')
  const hasPosition = typeof position === 'string' && position.length > 0
  const isComplete = hasPosition && summaryText.toLowerCase().includes(position.toLowerCase())

  return {
    sectionId: SectionNames.profile,
    progress: isComplete ? 1 : 0,
    id: 'no_job_title_in_summary',
    title: i18n.t('builder.resume_tuner.tips.no_job_title_in_summary.title'),
    description: i18n.t('builder.resume_tuner.tips.no_job_title_in_summary.description'),
    subject: i18n.t('builder.resume_tuner.tips.no_job_title_in_summary.subject'),
    label: i18n.t('builder.resume_tuner.issues.no_job_title_in_summary'),
    action: () => FocusManager.focus(SectionNames.profile),
    actionText: i18n.t('builder.resume_tuner.tips.no_job_title_in_summary.action_text'),
  }
}
