import styled from 'styled-components'
import Shadows from 'builder/styles/shadows'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

const Z_INDEX = 550

export const PopoverContainer = styled.div`
  ${Shadows.light.high};
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 104px;
  right: 24px;
  max-width: 352px;
  border-radius: 8px;
  background: ${Colors.White};
  padding: 24px;
  z-index: 10;

  ${Media.Laptop`
    z-index: ${Z_INDEX + 1};
    top: 50%;
    left: 0;
    right: 0;
    margin: auto;
    transform: translateY(-50%);
  `};
`

export const PopoverMobileOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${Colors.Neutral90};
  opacity: 0.9;
  z-index: ${Z_INDEX};
`
