import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

const { Neutral5, Neutral90, Neutral50, White } = Colors

export const TabsContainer = styled.div`
  position: relative;
  display: flex;
  background-color: ${Neutral5};
  border-radius: 12px;
  padding: 4px;
`

export const Tab = styled.div<{ isActive: boolean; isDisabled?: boolean }>(props => {
  const { isActive, isDisabled } = props
  return css`
    ${Typography.Caption}
    ${FontWeights.Medium}
    transition: color 0.1s;
    cursor: pointer;
    pointer-events: ${isDisabled ? 'none' : ''};
    padding: 8px 12px;
    color: ${isActive ? Neutral90 : Neutral50};
    ${isActive
      ? {
          'border-radius': '8px',
          'background-color': `${White}`,
          'box-shadow':
            '0px 2px 4px 0px rgba(15, 56, 113, 0.08), 0px 1px 2px 0px rgba(15, 56, 113, 0.12)',
        }
      : {}}
  `
})
