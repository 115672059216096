import { memo } from 'react'
import PropTypes from 'prop-types'
import { useImageLoaded } from './hooks'
import { ViewAvatarContainer, ViewAvatarImage } from './styles'

const Avatar = ({ seed, ...rest }) => {
  const src = seed ? `${process.env.HEADSOME_HOST}/44/${seed}.svg` : null
  const loaded = useImageLoaded(src)

  return (
    <ViewAvatarContainer {...rest} loaded={loaded}>
      {src && <ViewAvatarImage loaded={loaded} src={src} />}
    </ViewAvatarContainer>
  )
}

Avatar.propTypes = {
  seed: PropTypes.string,
}

export default memo(Avatar)
