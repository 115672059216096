import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Button, { ButtonTheme } from 'builder/components/Button'

const getBgColor = (theme: ButtonTheme) => {
  switch (theme) {
    case 'default':
      return Colors.Blue50
    case 'ghost':
      return Colors.White
  }
}

export const FiltersWrapper = styled.div<{ showFilters: boolean; isTopBarOpen: boolean }>`
  z-index: ${props => (props.isTopBarOpen ? 0 : 1)};
  gap: 8px;
  display: flex;
  padding-block: 16px;
  border-top: 1px solid ${Colors.Neutral15};

  position: sticky;
  top: 130px;
  background-color: ${Colors.White};
  transition: top 0.3s ease, background-color 0.3s ease;

  ${props =>
    !props.showFilters &&
    css`
      top: 0;
      background-color: transparent;
    `}

  @media (min-width: 1024px) {
    padding-block: 14px;
    position: sticky;
    top: 70px;
    background-color: ${Colors.White};
  }
`

export const StyledButton = styled(Button)`
  color: ${props => props.isDisabled && Colors.Neutral30};
  background-color: ${props => (props.isDisabled ? Colors.White : getBgColor(props.theme))};

  ${props =>
    props.isDisabled &&
    css`
      box-shadow: inset 0 0 0 1px ${Colors.Neutral20};
    `}
`
