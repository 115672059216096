import { Container, Title, SubTitle } from './styles'

type Props = {
  title: string
  subTitle: string
}

const Heading = ({ title, subTitle }: Props) => {
  return (
    <Container>
      <Title>{title}</Title>
      <SubTitle>{subTitle}</SubTitle>
    </Container>
  )
}

export default Heading
