import { useRef, MouseEvent } from 'react'
import { Link } from 'react-router-dom'
import { useBodyScrollLock } from 'builder/hooks/useBodyScrollLock'
import { useI18n } from 'builder/hooks/useI18n'
import { useConfig } from 'builder/hooks/useConfig'
import { SuperAppPromotionType } from 'builder/modules/init'
import { UserData } from 'builder/modules/user'

import { billingPlanPageURL, goToBillingPlanPage } from 'builder/utils/goToBillingPlanPage'
import { PrimaryLinks, SecondaryLinks } from '../../types'
import {
  MainPart,
  NameBlock,
  OutLink,
  OutLinks,
  PremiumStatus,
  Links,
  UserName,
  UpgradeButtonContainer,
  UpgradeButtonIcon,
  MobileMenuContainer,
  MenuLinkWrapper,
  MenuLink,
} from './styles'

interface MobileMenuProps {
  user: UserData
  barHeight: number
  primaryLinks: PrimaryLinks
  secondaryLinks: SecondaryLinks
  handleLinkClick: (event: MouseEvent<HTMLAnchorElement>) => void
  toggleMenu: (isOpen: boolean) => void
}

export const MobileMenu = ({
  user,
  barHeight,
  primaryLinks,
  secondaryLinks,
  handleLinkClick,
  toggleMenu,
}: MobileMenuProps) => {
  const menuRef = useRef<HTMLDivElement | null>(null)
  const { i18n } = useI18n()
  const config = useConfig()
  const superAppPromotion = config?.features.superAppPromotionType
  const areLinksVisible = superAppPromotion !== SuperAppPromotionType.new
  const dashboardLink = primaryLinks.find(link => link.name === 'dashboard')

  useBodyScrollLock(menuRef)

  return (
    <MobileMenuContainer ref={menuRef} barHeight={barHeight}>
      <MainPart>
        <NameBlock>
          <UserName>{user.fullName}</UserName>
          {user.billingStatus !== 'free' && (
            <PremiumStatus>{i18n.t('builder.navbar.premium_membership')}</PremiumStatus>
          )}
        </NameBlock>

        <Links>
          {user.billingStatus === 'free' && user.visitedPlans && (
            <UpgradeButtonContainer
              onClick={() => {
                if (user) {
                  goToBillingPlanPage({ upgradeURL: user?.billingInfo?.upgradeUrl })
                }
              }}
              href={billingPlanPageURL({ upgradeURL: user.billingInfo.upgradeUrl })}
            >
              <UpgradeButtonIcon />
              {i18n.t('builder.navbar.upgrade_now_button')}
            </UpgradeButtonContainer>
          )}

          {areLinksVisible ? (
            primaryLinks.map(({ name, isActive, ...link }) => (
              <MenuLinkWrapper key={name}>
                <MenuLink
                  $isActive={!!isActive}
                  data-name={name}
                  onClick={handleLinkClick}
                  {...link}
                />
              </MenuLinkWrapper>
            ))
          ) : (
            <MenuLinkWrapper>
              {dashboardLink && (
                <MenuLink
                  $isActive={!!dashboardLink.isActive}
                  data-name={dashboardLink.name}
                  onClick={handleLinkClick}
                  {...dashboardLink}
                />
              )}
            </MenuLinkWrapper>
          )}
        </Links>
      </MainPart>

      <OutLinks>
        {secondaryLinks.map((link, index) => (
          <OutLink
            key={index}
            onClick={() => toggleMenu(false)}
            as={'to' in link ? Link : 'a'}
            {...link}
          />
        ))}
      </OutLinks>
    </MobileMenuContainer>
  )
}
