import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'builder/components/Providers/Providers'
import { baseClient } from 'builder/modules/apiClient'

export const useMutationDeleteInterviewAnswerQuestion = () => {
  return useMutation({
    mutationFn: async (questionId: number) => {
      const response = await baseClient.delete(`/interview_prep/questions/${questionId}/answer`)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['useInterviews'])
      queryClient.invalidateQueries(['useInterview'])
    },
  })
}
