import { Fragment, useCallback, memo } from 'react'
import SwiperCore, { Scrollbar } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { trackInternalEvent } from '@rio/tracking'
import {
  CareerPathGrades,
  CareerPathRoleOccupationMatrix,
  CareerPathRoleOccupationMatrixItem,
} from 'builder/modules/careerPath'
import Icon from 'builder/components/Icon'

import { CareerPathJobCardTone } from 'builder/components/CareerPathJobCard'
import { MatrixCard } from '../MatrixCard'
import * as Styles from './styles'

export const ANIMATION_TIMEOUT_DELAY = 300
export const ACTIVATION_TIMEOUT_DELAY = 10

const ToneByType: { [key in CareerPathGrades]: CareerPathJobCardTone } = {
  low: 'green',
  medium: 'blue',
  high: 'indigo',
}

SwiperCore.use([Scrollbar])

type Props = {
  onNext: (id: string) => void
  matrix: CareerPathRoleOccupationMatrix
  isSwiped: boolean
  onCardSelect: (id: string | null) => void
  activeCardId: string | null
  isAnyActivated: boolean
}

export const Matrix = memo(
  ({ onNext, matrix, isSwiped, onCardSelect, activeCardId, isAnyActivated }: Props) => {
    const isButtonShown = isSwiped && activeCardId

    const handleActivateCard = useCallback(
      (id: string | null) => {
        onCardSelect(id)
      },
      [onCardSelect],
    )

    const handleNext = useCallback(() => {
      if (activeCardId) {
        trackInternalEvent('choose_career_card', {
          card: activeCardId,
          label: 'career_pathways',
        })
        onNext(activeCardId)
      }
    }, [activeCardId, onNext])

    if (isSwiped) {
      return (
        <Fragment>
          <Styles.TabletContainer>
            <Swiper
              grabCursor
              slidesPerView="auto"
              scrollbar={{ el: '.career-matrix-scrollbar' }}
              slideToClickedSlide
            >
              {matrix &&
                Object.keys(matrix).map((level, index) => (
                  <SwiperSlide key={`${level}`}>
                    <Styles.Column timeout={ANIMATION_TIMEOUT_DELAY * (index + 1)}>
                      {matrix[level as CareerPathGrades]?.map(
                        (item: CareerPathRoleOccupationMatrixItem) => (
                          <MatrixCard
                            key={item.id}
                            item={item}
                            matrix={matrix}
                            activeCardId={activeCardId}
                            isAnyActivated={isAnyActivated}
                            onProcess={handleActivateCard}
                            actionIcon={<Icon.Chevron />}
                            tone={ToneByType[level as CareerPathGrades]}
                          />
                        ),
                      )}
                    </Styles.Column>
                  </SwiperSlide>
                ))}
            </Swiper>
            <Styles.ScrollbarContainer>
              <div className="career-matrix-scrollbar" />
            </Styles.ScrollbarContainer>
          </Styles.TabletContainer>
          {isButtonShown && (
            <Styles.ButtonContainer>
              <Styles.FormButton onClick={handleNext}>Continue</Styles.FormButton>
            </Styles.ButtonContainer>
          )}
        </Fragment>
      )
    }

    return (
      <Styles.DesktopContainer>
        <Styles.DesktopOccupationsContainer>
          {matrix &&
            Object.keys(matrix).map((level, index) => (
              <Styles.Column timeout={ANIMATION_TIMEOUT_DELAY * (index + 1)} key={level}>
                {matrix[level as CareerPathGrades]?.map(
                  (item: CareerPathRoleOccupationMatrixItem) => (
                    <MatrixCard
                      key={item.id}
                      item={item}
                      matrix={matrix}
                      activeCardId={activeCardId}
                      isAnyActivated={isAnyActivated}
                      onToggle={handleActivateCard}
                      onProcess={handleNext}
                      actionIcon={<Icon.Chevron />}
                      tone={ToneByType[level as CareerPathGrades]}
                    />
                  ),
                )}
              </Styles.Column>
            ))}
        </Styles.DesktopOccupationsContainer>
      </Styles.DesktopContainer>
    )
  },
)
