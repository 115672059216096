import { CSSProperties, memo, useEffect } from 'react'
import { numberToDelimited } from 'builder/utils/numberToDelimited'
import { CareerPathJobCardProps } from 'builder/components/CareerPathJobCard/types'
import { useHover } from 'builder/hooks/useHover'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { selectors } from 'builder/modules/careerPath'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import * as Styles from './styles'

export const CareerPathJobCard = memo(
  ({
    id = '',
    occupation,
    location,
    jobSummary,
    actionIcon,
    tone,
    children,
    isActive = false,
    onToggle,
    onProcess,
    ...rest
  }: CareerPathJobCardProps) => {
    const { hoverRef, isHovering } = useHover<HTMLDivElement>()
    const { isPhone } = useMediaQueries()
    const selectedOccupation = useTypedSelector(selectors.occupation)

    const handleClick = () => onProcess && onProcess(isActive ? null : id)

    const style = {
      '--color-background': tone ? `var(--${tone}-10)` : 'var(--white)',
      '--color-background-accent': tone ? `var(--${tone}-20)` : 'var(--white)',
      '--color-special': tone ? `var(--${tone}-60)` : 'var(--white)',
      '--color-special-background': tone ? `var(--${tone}-30)` : 'var(--white)',
    } as CSSProperties

    useEffect(() => {
      onToggle && onToggle(isHovering ? id : null)

      return () => {
        onToggle && onToggle(null)
      }
    }, [isHovering, id, onToggle])

    return (
      <Styles.Container {...rest} style={style} onClick={handleClick} hasAccent={isActive}>
        {occupation ? (
          <Styles.TitleContainer>
            <Styles.JobTitle currentRole={selectedOccupation === occupation}>
              {occupation}
            </Styles.JobTitle>
            {selectedOccupation === occupation ? (
              <Styles.Badge>
                <Styles.BadgeText>Current role</Styles.BadgeText>
              </Styles.Badge>
            ) : null}
          </Styles.TitleContainer>
        ) : (
          <Styles.EmptyJobTitleText>Current role</Styles.EmptyJobTitleText>
        )}
        {location && (
          <Styles.TextContainer>
            <Styles.Title title={location}>{location}</Styles.Title>
          </Styles.TextContainer>
        )}
        {jobSummary && (
          <Styles.Details>
            <Styles.TextContainer>
              <Styles.Title>Avg. Salary</Styles.Title>{' '}
              <Styles.Value>
                {jobSummary?.salary?.median
                  ? `$${numberToDelimited(jobSummary.salary.median)}`
                  : 'N/A'}
              </Styles.Value>
            </Styles.TextContainer>
            <Styles.TextContainer>
              <Styles.Title>Job openings</Styles.Title>{' '}
              <Styles.Value>
                {jobSummary?.jobPostings ? numberToDelimited(jobSummary.jobPostings) : 'N/A'}
              </Styles.Value>
            </Styles.TextContainer>
          </Styles.Details>
        )}
        {children}
        {actionIcon && isHovering ? (
          <Styles.IconActive timeout={0}>
            <Styles.Text>Select</Styles.Text>
            {actionIcon}
          </Styles.IconActive>
        ) : null}
        {!isPhone && <Styles.HoverArea ref={hoverRef} />}
      </Styles.Container>
    )
  },
)
