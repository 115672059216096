import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const SummaryContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`

export const Title = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};
  margin-bottom: 24px;
`

export const Description = styled.div`
  color: ${Colors.Neutral50};
  margin-bottom: 40px;
`

export const RectangleContainer = styled.div`
  height: 1px;
  width: 100%;
  background: ${Colors.Neutral15};
  margin-bottom: 40px;
`
