import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { FontWeights } from 'builder/styles/typography'
import { Icon20 } from 'builder/components/Icon'

export const Wrapper = styled.div`
  background-color: ${Colors.White};
  margin-top: 32px;
`

export const ProgressTextWrapper = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 16px;
`

export const ProgressText = styled.div`
  ${FontWeights.Medium};
  color: ${Colors.Neutral80};
`

export const ProgressCompletedText = styled.span`
  ${FontWeights.Medium};
  color: ${Colors.Neutral50};
`

export const Progress = styled.div`
  height: 4px;
  border-radius: 2px;
  background-color: ${Colors.Neutral10};
`

export const ProgressBar = styled.div<{ progress: number }>`
  width: ${props => `${props.progress}%`};
  height: inherit;
  border-radius: inherit;
  background-color: ${Colors.Blue50};
  transition: width 0.15s ease-out;
`

export const SuccessIcon = styled(Icon20.SuccessCircle)`
  color: ${Colors.Green50};
`
