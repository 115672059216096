import styled, { css, keyframes } from 'styled-components'
import { Shadows } from 'builder/styles/shadows'
import { Colors, hexToRgba } from 'builder/styles/colors'
import { fadeIn } from 'builder/styles/keyframes'

const appear = keyframes`
  0% {
    transform: scaleY(0.75);
    opacity: 0;
  }

  100% {
    transform: scaleY(1);
    opacity: 1;
  }
`

export const Options = styled.div`
  ${Shadows.light.low};
  position: absolute;
  z-index: 10;
  width: 100%;
  max-height: 156px;
  overflow-y: auto;
  top: 100%;
  left: 0;
  right: 0;
  background-color: ${Colors.Neutral10};
  border-radius: 0 0 4px 4px;
  transform-origin: 50% 0;
  animation: ${appear} 0.15s ease-out;
  outline: none;

  &::-webkit-scrollbar {
    width: 12px;
    background-color: transparent;
    border-right: 2px solid transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${Colors.Neutral30};
    border: 4px solid ${Colors.Neutral10};
    border-radius: 6px;

    &:hover {
      background-color: ${Colors.Blue50};
    }
  }
`

export const OptionsList = styled.div`
  padding: 6px 0;
  animation: ${fadeIn} 0.15s 0.05s ease-out backwards;
`

export const OptionContent = styled.div<{ isSelected: boolean; isActive: boolean }>`
  padding: 6px 16px;
  color: ${props => (props.isSelected ? Colors.Neutral40 : Colors.Neutral90)};
  cursor: pointer;

  ${props =>
    props.isActive &&
    css`
      color: ${Colors.Blue50};
      background-color: ${hexToRgba(Colors.Blue20, 0.5)};
    `};
`
