import { Optional } from 'packages/types'
import { Primitive } from 'react-hook-form'
import { A4_FACTOR, US_LETTER_FACTOR } from 'builder/modules/constants'
import { LETTER_FORMAT_TEMPLATES, TemplateName } from '../constants'

function templateHeightFactorSelector(currentDocument: {
  template?: Optional<Primitive | Primitive[]>
}) {
  const isLetterFormat = LETTER_FORMAT_TEMPLATES?.includes(
    currentDocument?.template as TemplateName,
  )
  return isLetterFormat ? US_LETTER_FACTOR : A4_FACTOR
}

export default templateHeightFactorSelector
