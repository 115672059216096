import { memo, useState, useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import useDebouncedEffect from 'use-debounced-effect'
import { useI18n } from 'builder/hooks/useI18n'
import ColorPicker from 'builder/components/ColorPicker'
import { ColorPickerPanelContainer, Title, PanelActions, PanelAction } from './styles'

const ColorPickerPanel = ({ selected, onSelect, onSubmit, onCancel }) => {
  const { i18n } = useI18n()
  const defaultColor = useRef(selected)
  const [localColor, setLocalColor] = useState(selected)

  // Debounce the callback to avoid extra updates and re-renders of the parent component
  useDebouncedEffect(
    () => {
      if (localColor !== selected) onSelect(localColor)
    },
    350,
    [localColor],
  )

  const handleCancel = useCallback(() => onCancel(defaultColor.current), [onCancel])

  const handleSubmit = useCallback(() => {
    defaultColor.current = localColor
    onSubmit(localColor)
  }, [localColor, onSubmit])

  // Reset changes when the picker is going to be unmounted
  useEffect(() => handleCancel, [handleCancel])

  return (
    <ColorPickerPanelContainer>
      <Title>{i18n.t('builder.resume_editor.color_select')}</Title>
      <ColorPicker hex={localColor} onChange={setLocalColor} />

      <PanelActions>
        <PanelAction onClick={handleCancel}>{i18n.t('builder.resume_editor.cancel')}</PanelAction>
        <PanelAction onClick={handleSubmit} primary>
          {i18n.t('builder.resume_editor.save')}
        </PanelAction>
      </PanelActions>
    </ColorPickerPanelContainer>
  )
}

ColorPickerPanel.propTypes = {
  selected: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default memo(ColorPickerPanel)
