import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography from 'builder/styles/typography'
import { Shadows } from 'builder/styles/shadows'

export const SectionTitle = styled.div`
  ${Typography.L};
  font-weight: 600;
  margin-bottom: 32px;

  ${Media.Phone`
    margin-bottom: 16px;
  `}
`

export const SectionContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  ${Media.Tablet`
    flex-direction: column;
  `}
`

export const SectionCard = styled.a`
  ${Shadows.lightWithBorder.low};
  width: calc(50% - 16px);
  padding: 80px;
  border-radius: 16px;
  background-color: ${Colors.White};
  text-align: center;
  margin-bottom: 32px;
  transition: box-shadow 0.2s;

  &:hover,
  &:focus-visible {
    ${Shadows.lightWithBorder.mid};
  }

  ${Media.Tablet`
    width: 100%;
    margin-bottom: 32px;
  `}

  ${Media.Phone`
    padding: 32px 16px;
    margin-bottom: 24px;
  `}
`

export const SectionCardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  margin: 0 auto;

  ${Media.Phone`
    padding: 0 16px;
  `}
`

export const SectionCardImage = styled.img`
  display: block;
  width: 100%;
  max-width: 350px;
  margin: 0 auto 32px;

  ${Media.Phone`
    margin-bottom: 24px;
  `}
`

export const SectionCardTitle = styled.strong`
  ${Typography.Subhead};
  font-weight: 600;
  margin-bottom: 2px;

  ${Media.Phone`
    ${Typography.Body};
    margin-bottom: 4px;
  `}
`

export const SectionCardText = styled.div`
  margin-bottom: 24px;

  ${Media.Phone`
    margin-bottom: 20px;
  `}
`
