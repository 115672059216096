import styled from 'styled-components'
import DocumentImagePreview from 'builder/components/DocumentImagePreview'
import Colors, { hexToRgba } from 'builder/styles/colors'

export const Container = styled.div`
  height: 100%;
`

export const ImagePreview = styled(DocumentImagePreview)`
  border-radius: 3px;
`

export const Add = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${hexToRgba(Colors.Blue20, 0.5)};
  box-shadow: 0px 24px 40px ${hexToRgba(Colors.Blue50, 0.16)},
    inset 0 -1px 0 0 ${hexToRgba(Colors.Blue70, 0.16)}, inset 0 1px 0 0 ${Colors.White};
  backdrop-filter: blur(8px);

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 2px;
    transform: translate(-50%, -50%);
    background-color: ${Colors.Blue50};
  }

  &::before {
    transform: translate(-50%, -50%) rotate(90deg);
  }
`
