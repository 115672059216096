import { trackInternalEvent } from '@rio/tracking'
import CareerPlanCard from 'builder/components/CareerPlanCard'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import CareerPlanSuggestion from 'builder/components/CareerPlanSuggestion'
import { useCareerPlans } from 'builder/components/CareerPlanCard/hooks/useCareerPlans'
import {
  Header,
  Heading,
  Container,
  SubHeading,
  TopContainer,
  MainContainer,
  CardsContainer,
} from './styles'

const CareerPlansView = () => {
  const { careerPlans } = useCareerPlans()

  useEffectOnMount(() => {
    trackInternalEvent('enter_career_plans_page')
  })

  return (
    <Container timeout={0}>
      <MainContainer>
        <TopContainer>
          <Header>
            <Heading>Career Plans</Heading>
            <SubHeading>
              Here are career plans that contain videos, educational materials, as well as
              checklists with tasks that will help you advance your career or find a new job.
            </SubHeading>
          </Header>
          <CardsContainer>
            {careerPlans.map(careerPlan => (
              <CareerPlanCard key={careerPlan.name} careerPlan={careerPlan} />
            ))}
          </CardsContainer>
        </TopContainer>
        <CareerPlanSuggestion />
      </MainContainer>
    </Container>
  )
}

export default CareerPlansView
