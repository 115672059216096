import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const ProgressCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const BackgroundCircle = styled.circle`
  stroke: ${Colors.Neutral15};
  fill: transparent;
`

export const ProgressNumber = styled.span`
  ${Typography.Caption};
  ${FontWeights.DemiBold};
  color: ${Colors.Blue50};
  position: absolute;
`

export const ProgressRing = styled.svg`
  transform: rotate(-90deg);
  stroke: ${Colors.Blue50};
  stroke-width: 4;
`

export const ProgressRingCircle = styled.circle<{ $circumference: number }>`
  stroke: ${Colors.Blue50};
  stroke-dasharray: ${props => props.$circumference};
  stroke-dashoffset: ${props => props.offset};
  transition: stroke-dashoffset 0.35s;
`

export const CompletedContainer = styled.div`
  color: ${Colors.White};
  background-color: ${Colors.Blue50};
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`
