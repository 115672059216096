import { memo } from 'react'
import { useI18n } from 'builder/hooks/useI18n'
import { selectors as careerProfileSelectors } from 'builder/modules/careerProfile'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { translationKey } from '../../consts'
import {
  BadgeList,
  Container,
  Item,
  ItemDescription,
  ItemDuration,
  ItemHeader,
  ItemList,
  SectionHeader,
  Section,
  Badge,
} from './styles'
import {
  formatStringDate,
  getCareerProfileCourseText,
  getCareerProfileEducationText,
  getCareerProfileWorkExperienceText,
} from './helpers'

export const ProfileDetails = memo(() => {
  const { i18n } = useI18n()
  const careerProfileData = useTypedSelector(careerProfileSelectors.careerProfileData)
  const dateFormat = i18n.t(`${translationKey}.details.date_format`)
  const present = i18n.t(`${translationKey}.details.present`)

  // we should filter "not specified" fields from editor.
  // most of them defined by two title fields, except city

  const workExperiences =
    careerProfileData?.workExperiencesAttributes
      .map(item => ({
        ...item,
        title: (item.title || item.employer) && getCareerProfileWorkExperienceText(i18n, item),
      }))
      .filter(item => !!item.title) || []

  const educations =
    careerProfileData?.educationsAttributes
      .map(item => ({
        ...item,
        title: (item.school || item.degree) && getCareerProfileEducationText(i18n, item),
      }))
      .filter(item => !!item.title) || []

  const courses =
    careerProfileData?.coursesAttributes
      .map(item => ({
        ...item,
        title: (item.course || item.institution) && getCareerProfileCourseText(i18n, item),
      }))
      .filter(item => !!item.title) || []

  const skills = careerProfileData?.skillsAttributes.filter(item => !!item.skill) || []

  const hasDataToDisplay =
    workExperiences.length || educations.length || courses.length || skills.length

  return hasDataToDisplay ? (
    <Container>
      {workExperiences.length ? (
        <Section>
          <SectionHeader>{i18n.t(`${translationKey}.details.work_experience_title`)}</SectionHeader>
          <ItemList>
            {workExperiences.map(item => (
              <Item key={item.id}>
                {item.title && <ItemHeader>{item.title}</ItemHeader>}
                {item.dateFrom && (
                  <ItemDuration>
                    {formatStringDate(item.dateFrom, dateFormat)} -
                    {item.dateUntil ? formatStringDate(item.dateUntil, dateFormat) : present}
                  </ItemDuration>
                )}
                {item.description && (
                  <ItemDescription dangerouslySetInnerHTML={{ __html: item.description }} />
                )}
              </Item>
            ))}
          </ItemList>
        </Section>
      ) : null}

      {educations.length ? (
        <Section>
          <SectionHeader>{i18n.t(`${translationKey}.details.education_title`)}</SectionHeader>
          <ItemList>
            {educations.map(item => (
              <Item key={item.id}>
                {item.title && <ItemHeader>{item.title}</ItemHeader>}
                {item.dateFrom && (
                  <ItemDuration>
                    {formatStringDate(item.dateFrom, dateFormat)} -
                    {item.dateUntil ? formatStringDate(item.dateUntil, dateFormat) : present}
                  </ItemDuration>
                )}
                {item.description && (
                  <ItemDescription dangerouslySetInnerHTML={{ __html: item.description }} />
                )}
              </Item>
            ))}
          </ItemList>
        </Section>
      ) : null}

      {courses.length ? (
        <Section>
          <SectionHeader>{i18n.t(`${translationKey}.details.courses_title`)}</SectionHeader>
          <ItemList>
            {courses.map(item => (
              <Item key={item.id}>
                {item.title && <ItemHeader>{item.title}</ItemHeader>}
                {item.dateFrom && (
                  <ItemDuration>
                    {formatStringDate(item.dateFrom, dateFormat)}&nbsp;-&nbsp;
                    {item.dateUntil ? formatStringDate(item.dateUntil, dateFormat) : present}
                  </ItemDuration>
                )}
              </Item>
            ))}
          </ItemList>
        </Section>
      ) : null}

      {skills.length ? (
        <Section>
          <SectionHeader>{i18n.t(`${translationKey}.details.skills_title`)}</SectionHeader>
          <BadgeList>
            {skills.map(item => (
              <Badge key={item.id}>{item.skill}</Badge>
            ))}
          </BadgeList>
        </Section>
      ) : null}
    </Container>
  ) : null
})
