import { Fragment, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import {
  PrefillProviders,
  PrefillStatuses,
  actions as resumeEditorActions,
  selectors as resumeEditorSelectors,
} from 'builder/modules/resumeEditor'
import { Icon20 } from 'builder/components/Icon'
import { ButtonSize, ButtonTheme } from 'builder/components/Button'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

import { PrefillPopoverFailure } from './PrefillPopover'
import {
  PrefillPopupAction,
  PrefillPopupActions,
  PrefillPopupCloseButton,
  PrefillPopupHead,
  PrefillPopupHeaderContainer,
  PrefillPopupLogo,
  PrefillPopupLogoContainer,
  PrefillPopupLogoIcon,
  PrefillPopupRequestText,
  PrefillPopupText,
} from './styles'

interface LinkedinMergePopupProps {
  documentId: number
}

export const LinkedinMergePopup = ({ documentId }: LinkedinMergePopupProps) => {
  const dispatch = useDispatch()
  const { i18n } = useI18n()
  const { isLaptop } = useMediaQueries()
  const { status } = useTypedSelector(resumeEditorSelectors.prefill)
  const navigate = useNavigate()

  const handleResumeMergeAgree = useCallback(() => {
    trackInternalEvent('confirm_linkedin_resume_prefill')
    dispatch(resumeEditorActions.mergeLinkedinResume({ resumeId: documentId }))
    navigate(`/resumes/${documentId}/edit`, { replace: true })
  }, [navigate, dispatch, documentId])

  const handleResumeMergeCancel = useCallback(() => {
    trackInternalEvent('reject_linkedin_resume_prefill')
    dispatch(resumeEditorActions.rejectLinkedinResume({ resumeId: documentId }))
  }, [dispatch, documentId])

  const handleClose = useCallback(() => {
    trackInternalEvent(`close_linkedin_prefill_status_popup`, { resume_id: documentId })
    dispatch(resumeEditorActions.setPrefillStatus(PrefillStatuses.skipped))
  }, [documentId, dispatch])

  const handleRedirectToEditor = () => {
    navigate(`/resumes/${documentId}/edit`, { replace: true })
    dispatch(resumeEditorActions.setPrefillStatus(PrefillStatuses.done))
  }

  const isFailed = status === PrefillStatuses.failed || status === PrefillStatuses.mergeFailed

  return (
    <Fragment>
      {isFailed ? (
        <PrefillPopoverFailure
          provider={PrefillProviders.linkedin}
          status={status}
          onClose={handleClose}
        />
      ) : (
        <Fragment>
          <Fragment>
            {status === PrefillStatuses.mergedAuto && (
              <PrefillPopupCloseButton onClick={handleClose} />
            )}
            <PrefillPopupHeaderContainer center>
              <PrefillPopupLogoContainer>
                <PrefillPopupLogo service={PrefillProviders.linkedin} />
                <PrefillPopupLogoIcon>
                  <Icon20.Tick />
                </PrefillPopupLogoIcon>
              </PrefillPopupLogoContainer>
              <PrefillPopupHead center={isLaptop}>
                {status === PrefillStatuses.mergedAuto
                  ? i18n.t(`builder.resume_editor.prefill.modal.linkedin.success_head`)
                  : i18n.t(`builder.resume_editor.prefill.modal.linkedin.ready_head`)}
              </PrefillPopupHead>
            </PrefillPopupHeaderContainer>
            {status === PrefillStatuses.mergedAuto ? (
              <Fragment>
                <PrefillPopupText center={isLaptop}>
                  {i18n.t(`builder.resume_editor.prefill.modal.linkedin.success_auto_merge`)}
                </PrefillPopupText>
                <PrefillPopupActions>
                  <PrefillPopupAction
                    $wide={isLaptop}
                    theme={ButtonTheme.ghost}
                    size={isLaptop ? ButtonSize.default : ButtonSize.small}
                    onClick={handleClose}
                  >
                    {i18n.t(`builder.resume_editor.prefill.modal.linkedin.success_check_later`)}
                  </PrefillPopupAction>
                  <PrefillPopupAction
                    $wide
                    size={isLaptop ? ButtonSize.default : ButtonSize.small}
                    onClick={handleRedirectToEditor}
                  >
                    {i18n.t(`builder.resume_editor.prefill.modal.linkedin.success_show_me`)}
                  </PrefillPopupAction>
                </PrefillPopupActions>
              </Fragment>
            ) : (
              <Fragment>
                <PrefillPopupText center={isLaptop}>
                  {i18n.t(`builder.resume_editor.prefill.modal.linkedin.ready_text`)}
                </PrefillPopupText>
                <PrefillPopupRequestText center={isLaptop}>
                  {i18n.t(`builder.resume_editor.prefill.modal.linkedin.ready_question`)}
                </PrefillPopupRequestText>
                <PrefillPopupActions>
                  <PrefillPopupAction
                    $wide={isLaptop}
                    theme={ButtonTheme.ghost}
                    size={isLaptop ? ButtonSize.default : ButtonSize.small}
                    onClick={handleResumeMergeCancel}
                  >
                    {i18n.t(
                      'builder.resume_editor.prefill.modal.' +
                        `${PrefillProviders.linkedin}.ready_cancel_button`,
                    )}
                  </PrefillPopupAction>
                  <PrefillPopupAction
                    $wide={isLaptop}
                    size={isLaptop ? ButtonSize.default : ButtonSize.small}
                    onClick={handleResumeMergeAgree}
                  >
                    {i18n.t(
                      'builder.resume_editor.prefill.modal.' +
                        `${PrefillProviders.linkedin}.ready_agree_button`,
                    )}
                  </PrefillPopupAction>
                </PrefillPopupActions>
              </Fragment>
            )}
          </Fragment>
        </Fragment>
      )}
    </Fragment>
  )
}
