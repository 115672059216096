import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect, useMemo } from 'react'
import { last, take } from 'lodash'
import { Answer, Question, actions, selectors } from 'builder/modules/careerProfile'
import { FetchStatuses } from 'builder/modules/constants'
import { useUser } from 'builder/hooks/useUser'

export const useChatbot = ({ handleScroll }: { handleScroll: () => void }) => {
  const dispatch = useDispatch()
  const user = useUser()
  const questions = useSelector(selectors.questions)
  const asked = useSelector(selectors.asked)
  const answers = useSelector(selectors.answers)
  const persona = useSelector(selectors.persona)
  const fetchQuestionsStatus = useSelector(selectors.fetchQuestionsStatus)
  const isDataSentDuringCurrentSession = useSelector(selectors.isDataSentDuringCurrentSession)
  const selectedResumeText = useSelector(selectors.selectedResumeText)
  const selectedResume = useSelector(selectors.selectedResume)
  const isSyncedWithLocalStorage = useSelector(selectors.isSyncedWithLocalStorage)
  const structuredResumeData = useSelector(selectors.structuredResumeData)
  const areAllTextQuestionsAnswered = questions.length === answers.length
  const isChatbotCompleted = !!(
    persona &&
    areAllTextQuestionsAnswered &&
    selectedResumeText &&
    structuredResumeData
  )
  const [isBotWriting, setBotWriting] = useState<boolean>(false)
  const isLoading = [FetchStatuses.loading, FetchStatuses.notAsked].includes(fetchQuestionsStatus)

  useEffect(() => {
    if (
      isSyncedWithLocalStorage &&
      user &&
      (fetchQuestionsStatus === FetchStatuses.notAsked ||
        fetchQuestionsStatus === FetchStatuses.failed)
    ) {
      dispatch(actions.fetchQuestions())
    }
  }, [isSyncedWithLocalStorage, user, fetchQuestionsStatus, dispatch])

  // introduce first question after loading and selecting persona.
  useEffect(() => {
    // wait until presona is selected to introduce questions.
    if (!persona) {
      return
    }
    if (questions.length && fetchQuestionsStatus === FetchStatuses.loaded && asked.length === 0) {
      setBotWriting(true)
      setTimeout(() => {
        dispatch(actions.setAsked([{ ...questions[0], time: Date.now() }]))
        setBotWriting(false)
      }, 1000)
    }
  }, [fetchQuestionsStatus, persona])

  // First, reply last question's default answer.
  useEffect(() => {
    if (!answers.length) {
      return
    }

    // if all questions already asked, send last response and end conversation.
    if (areAllTextQuestionsAnswered) {
      return
    }

    // if last question is unanswered.
    if (Number(last(asked)?.time) >= Number(last(answers)?.time)) {
      return
    }

    const lastQuestionAsked = last(asked)
    const lastResponse = lastQuestionAsked?.response
    const nextQuestionIndex = questions.findIndex(({ id }) => id === lastQuestionAsked?.id) + 1

    // next question.
    const nextQ = questions[nextQuestionIndex]
    const newQuestions: Question[] = []

    if (lastResponse) {
      newQuestions.push({ ...lastResponse, time: Date.now() })
    }
    if (nextQ) {
      newQuestions.push({ ...nextQ, time: Date.now() + 1 })
    }

    setBotWriting(true)
    if (newQuestions.length > 1) {
      for (let i = 0; i < newQuestions.length; i++) {
        const timeout = i * 1800 || 1000
        setTimeout(() => {
          dispatch(actions.setAsked([...asked, ...take(newQuestions, i + 1)]))
          if (i === newQuestions.length - 1) {
            setBotWriting(false)
          }
        }, timeout)
      }
    } else {
      dispatch(actions.setAsked([...asked, ...newQuestions]))
    }
  }, [answers.length, areAllTextQuestionsAnswered])

  useEffect(() => {
    handleScroll()
  }, [answers, asked, selectedResume, persona])

  useEffect(() => {
    if (!user || !isChatbotCompleted || !structuredResumeData) {
      return
    }

    if (!isDataSentDuringCurrentSession) {
      dispatch(actions.postChatbotAnswers())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChatbotCompleted, isDataSentDuringCurrentSession])

  const [editMessage, setEditMessage] = useState<Answer | null>(null)

  const disableChat = useMemo(() => {
    if (isLoading) {
      return true
    }

    if (isBotWriting) {
      return true
    }

    if (editMessage) {
      return false
    }

    if (Number(last(answers)?.time) > Number(last(asked)?.time)) {
      return true
    }
    const lastQ = asked.find(({ id }) => id === last(questions)?.id)
    if (!lastQ || !lastQ?.time) {
      return false
    }
    return Number(lastQ?.time) < Number(last(answers)?.time)
  }, [answers, questions, asked, editMessage, isBotWriting, isLoading])

  return {
    questions,
    asked,
    answers,
    persona,
    fetchQuestionsStatus,
    selectedResume,
    selectedResumeText,
    isDataSentDuringCurrentSession,
    setEditMessage,
    disableChat,
    isBotWriting,
    setBotWriting,
    isLoading,
    isChatbotCompleted,
    areAllTextQuestionsAnswered,
    editMessage,
    structuredResumeData,
  }
}

export default useChatbot
