import { Container, Header, Description } from './styles'

interface Props {
  aboutMe?: string
}

const AboutCoach = ({ aboutMe }: Props) => {
  return (
    <Container>
      <Header>About</Header>
      {aboutMe && <Description dangerouslySetInnerHTML={{ __html: aboutMe }} />}
    </Container>
  )
}

export default AboutCoach
