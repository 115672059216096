import { useEffect, useState } from 'react'
import { apiClient } from 'builder/modules/apiClient'

export const useTrustpilot = () => {
  const [iframeUrl, setIframeUrl] = useState<string | null>(null)

  useEffect(() => {
    apiClient.post(`/feedback/trustpilot-link`).then(response => {
      if (response.data.success) setIframeUrl(response.data.link)
    })
  }, [])

  return { iframeUrl }
}
