import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Description = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: flex-start;

  ${Media.Phone`
    gap: 8px;
  `};
`

export const Header = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: flex-start;

  ${Media.Phone`
    gap: 8px;
  `};
`

export const Title = styled.div`
  ${Typography.S};
  ${FontWeights.DemiBold};
`

export const SubTitle = styled.div`
  color: ${Colors.Neutral50};
  ${Typography.Body};
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;

  ${Media.Phone`
    gap: 8px;
  `};
`
