import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import { CircleButton } from 'builder/components/Button'
import Media from 'builder/styles/media'

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  gap: 16px;
`

export const SwiperContainer = styled.div`
  position: relative;

  & .swiper-container {
    position: relative;
    overflow: hidden;

    ${Media.Phone`
      padding: 0 24px;
    `}
  }

  & .swiper-wrapper {
    display: flex;
    position: relative;
    transition-property: transform;
  }
`

export const Gradient = styled.div<{ $position: 'start' | 'end'; $isVisible: boolean }>`
  background: linear-gradient(270deg, ${Colors.Neutral5} 0%, transparent 100%);
  position: absolute;
  top: 0;
  height: 100%;
  width: 56px;
  pointer-events: none;
  opacity: 0;
  will-change: opacity;
  transition: opacity 0.2s ease-out;

  ${props =>
    props.$isVisible &&
    css`
      opacity: 1;
    `}

  ${props =>
    props.$position === 'end' &&
    css`
      right: 0;
    `}

  ${props =>
    props.$position === 'start' &&
    css`
      transform: rotate(180deg);
      left: 0;
    `}
`

export const SlideContent = styled.div<{ $isLast?: boolean }>`
  position: relative;
  padding: 24px;
  width: 100%;
  border-radius: 16px;
  background-color: ${Colors.White};

  // $isLast is used because library adds wrapper
  // for every slide and we can't use :last-child
  ${props =>
    props.$isLast &&
    css`
      background: linear-gradient(238.12deg, #cac9ff -5.25%, #dfe8f9 81.15%, #c4c0ff 103.89%);
    `}
`

export const SlideJoiner = styled.img`
  position: absolute;
  height: 37px;
  right: -20px;
  top: 34px;
  // it needs to overlap sibling container
  z-index: 1;
`

export const NavigationControls = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  gap: 8px;

  ${Media.Phone`
    padding: 0 24px;
  `}
`

export const NavigationButton = styled(CircleButton)`
  background-color: ${Colors.Neutral15};
  transition: background-color 0.2s ease-out, opacity 0.2s ease-out;

  &:focus {
    background-color: ${Colors.Neutral15};
  }

  &:hover {
    background-color: ${Colors.Neutral20};
  }

  &:disabled {
    background-color: ${Colors.Neutral15};
    opacity: 50%;
    cursor: default;
  }

  ${Media.Phone`
    width: 36px;
    height: 36px;
  `}
`
