import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useCallback } from 'react'
import { useI18n } from 'builder/hooks/useI18n'
import { useCoverLetterTextParts } from 'builder/components/CareerInsights'
import { useUser } from 'builder/hooks/useUser'
import Button, { ButtonSize, ButtonTheme } from 'builder/components/Button'
import { actions as panelActions } from 'builder/modules/panel'
import { actions as careerProfileActions } from 'builder/modules/careerProfile'
import { DocumentTypes } from 'builder/modules/constants'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import { AssessmentResultSample } from '../AssessmentResultSample'
import * as Styled from './styles'

const translationKey = 'builder.career_profile.cover_letter_sample'

export const CoverLetterSampleView = () => {
  const dispatch = useDispatch()
  const { i18n } = useI18n()
  const coverLetterTextParts = useCoverLetterTextParts()
  const user = useUser()

  const onOpenInEditor = () => {
    const content = coverLetterTextParts.map(item => `<p>${item}</p>`).join('')
    dispatch(
      panelActions.createDocument({
        type: DocumentTypes.coverLetter,
        details: { content },
      }),
    )
    trackInternalEvent('click_open_sample_in_cover_letter_builder')
  }

  const onCopyClick = useCallback(() => {
    trackInternalEvent('click_copy_cover_letter_sample')
  }, [])

  useEffectOnMount(() => {
    trackInternalEvent('enter_cover_letter_sample_page')
    dispatch(careerProfileActions.setSeenPage('cover-letter'))
  })

  return (
    <AssessmentResultSample
      title={i18n.t(`${translationKey}.title`)}
      description={i18n.t(`${translationKey}.description`, { name: user?.firstName })}
      textParts={coverLetterTextParts}
      snackbarOnCopyText={i18n.t(`${translationKey}.snackbar_copy_text`)}
      onCopyClick={onCopyClick}
    >
      <Button size={ButtonSize.small} theme={ButtonTheme.secondary} onClick={onOpenInEditor}>
        <Styled.Icon name="ResumeAndCoverLetters" />
        &nbsp;
        {i18n.t(`${translationKey}.open_in_editor`)}
      </Button>
    </AssessmentResultSample>
  )
}
