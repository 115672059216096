import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  ${FontWeights.DemiBold};
  ${Typography.Caps};
  display: flex;
  align-items: center;
  gap: 8px;
`

export const Counter = styled.span`
  color: ${Colors.Neutral90};
`

export const CounterCaption = styled.span`
  color: ${Colors.Neutral50};
`
