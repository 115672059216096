import styled from 'styled-components'
import Shadows from 'builder/styles/shadows'
import DocumentImagePreview from 'builder/components/DocumentImagePreview'

export const Container = styled.div``

export const ImagePreview = styled(DocumentImagePreview)`
  ${Shadows.template.high};
  border-radius: 3px;
  height: 100%;
`
