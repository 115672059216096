import { useState, useRef, useEffect, useCallback } from 'react'
import * as React from 'react'
import { Transition } from 'react-transition-group'
import { useI18n } from 'builder/hooks/useI18n'
import Icon from 'builder/components/Icon'
import Tooltip from 'builder/components/Tooltip'
import {
  DocumentNameContainer,
  DocumentNameTitleWrapper,
  DocumentNameTitle,
  DocumentNameInternalLink,
  DocumentNameInputWrapper,
  DocumentNameInput,
  DocumentNameHiddenTitle,
  DocumentNameBar,
  DocumentNameIconWrapper,
  DocumentNameIcon,
} from './styles'

interface Props {
  value: string
  link: string
  isCareerIoDomain?: boolean
  navigateBackToRio?: (feature: string) => void
  onChange: (name: string) => void
}

export const DocumentName = ({ link, onChange, ...props }: Props) => {
  const { i18n } = useI18n()
  const [value, setValue] = useState(props.value)
  const [isEditing, toggleEditing] = useState(false)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [isEditIconVisible, setIsEditIconVisible] = useState<boolean>(false)

  // Update the internal `value` using `props.value` (getDerivedStateFromProps replacement)
  // See https://reactjs.org/docs/hooks-faq.html#how-do-i-implement-getderivedstatefromprops
  if (!isEditing && props.value !== value) setValue(props.value)

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus()
      inputRef.current.select()
    }
  }, [isEditing])

  useEffect(() => {
    if (props.value.includes(i18n.t('builder.resume_editor.untitled'))) {
      setIsEditIconVisible(true)
    } else {
      setIsEditIconVisible(false)
    }
  }, [i18n, props.value])

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      toggleEditing(false)
      onChange(e.target.value.trim() || i18n.t('builder.dashboard.untitled'))
    },
    [i18n, onChange],
  )

  const handleKeyUp = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && inputRef.current) inputRef.current.blur()
  }, [])

  return (
    <DocumentNameContainer>
      <DocumentNameTitleWrapper>
        {!isEditing && (
          <DocumentNameTitle>
            <DocumentNameInternalLink to={link}>{value}</DocumentNameInternalLink>

            <DocumentNameIconWrapper isEditIconVisible={isEditIconVisible}>
              <Tooltip value={i18n.t('builder.dashboard.rename')}>
                <DocumentNameIcon onClick={() => toggleEditing(true)}>
                  <Icon.Edit />
                </DocumentNameIcon>
              </Tooltip>
            </DocumentNameIconWrapper>
          </DocumentNameTitle>
        )}

        {isEditing && (
          <DocumentNameInputWrapper>
            <DocumentNameInput
              value={value}
              placeholder={i18n.t('builder.resume_editor.untitled')}
              onKeyUp={handleKeyUp}
              onChange={e => setValue(e.target.value)}
              onBlur={handleBlur}
              ref={inputRef}
            />

            <DocumentNameHiddenTitle>{value}</DocumentNameHiddenTitle>
          </DocumentNameInputWrapper>
        )}

        <Transition in={isEditing} timeout={100} appear>
          {state => <DocumentNameBar in={state} />}
        </Transition>
      </DocumentNameTitleWrapper>
    </DocumentNameContainer>
  )
}

export default React.memo(DocumentName)
