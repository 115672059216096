import { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import useHighlight from './hooks/useHighlight'
import { ItemSubphrase, ItemSubphraseIcon, ItemSubphraseJoiner } from './styles'

const Subphrase = ({ item, onClick }) => {
  const { digest, isActive, highlight } = item
  const content = useHighlight(highlight)

  const handleClick = useCallback(
    event => {
      event.preventDefault()
      if (!isActive) onClick(item)
    },
    [item, onClick, isActive],
  )

  return (
    <ItemSubphrase
      key={digest}
      className={isActive ? 'is-subphrase-added' : ''}
      onClick={handleClick}
      href="#"
    >
      <ItemSubphraseIcon />
      <ItemSubphraseJoiner>&nbsp;</ItemSubphraseJoiner>
      {content}
    </ItemSubphrase>
  )
}

Subphrase.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default memo(Subphrase)
