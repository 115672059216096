import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { FeatureIcon } from 'builder/components/FeatureIcon'
import Media from 'builder/styles/media'
import Icon24 from 'builder/components/Icon'
import { CentralColumn, Section } from '../../styles'

const flexColumn = css`
  display: flex;
  flex-flow: column;
`

export const Container = styled(Section)`
  background-color: ${Colors.Neutral5};
  padding-bottom: 96px;

  ${Media.Phone`
    padding-bottom: 24px;
  `}
`

export const MainSectionContent = styled(CentralColumn)`
  ${flexColumn};
  gap: 48px;

  ${Media.Phone`
    gap: 24px;
  `}
`

export const MainSectionTextContainer = styled.div`
  ${flexColumn};
  gap: 16px;

  ${Media.Phone`
    gap: 12px;
  `}
`

export const MainSectionTextHeader = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral100};
`

export const MainSectionText = styled.div`
  color: ${Colors.Neutral60};
`

export const ListContainer = styled.div`
  ${flexColumn};
  gap: 8px;
`

export const ListItem = styled.div`
  --icon-color: ${Colors.Neutral40};

  padding: 16px 24px;
  display: flex;
  gap: 16px;
  align-items: center;
  border: 1px solid ${Colors.Neutral15};
  border-radius: 12px;
  background-color: ${Colors.White};
  will-change: background-color, border-color;
  transition: all 0.1s ease-out;
  cursor: pointer;

  ${Media.Hover`
    &:hover {
      --icon-color: ${Colors.Blue50};
      border-color: ${Colors.Blue50};
    }
  `}

  ${Media.Phone`
    padding: 16px;
    gap: 12px;
    align-items: flex-start;
  `}
`

export const ListItemIcon = styled(FeatureIcon)`
  --icon-size: 48px;
  width: var(--icon-size);
  height: var(--icon-size);

  ${Media.Phone`
    --icon-size: 32px;
  `}
`

export const ListItemTextContainer = styled.div`
  padding: 4px 0;
  flex: auto;

  ${Media.Phone`
    padding: 0;
  `}
`

export const ListItemTextHeader = styled.div`
  ${FontWeights.Medium};
`

export const ListItemText = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
`

export const ListItemArrowIcon = styled(Icon24.ChevronRight)`
  transition: color 0.1s ease-out;
  color: var(--icon-color);

  ${Media.Phone`
    display: none;
  `}
`

export const Coaching = styled(ListItem)`
  border: none;
  background-color: ${Colors.Neutral10};

  ${Media.Hover`
    &:hover {
      background-color: ${Colors.Neutral15};
    }
  `}

  ${Media.Phone`
    flex-flow: column;
    padding: 16px 24px;
  `}
`
