import { i18n as I18n } from 'builder/utils/i18n'
import { ButtonSize } from 'builder/components/Button'
import {
  ErrorCloseIcon,
  ErrorContiner,
  ErrorContent,
  ErrorTitle,
  ErrorTryAgainButton,
  TopWrapper,
} from './styles'

interface JobPostingErrorProps {
  onClose: () => void
  onTryAgain: () => void
  ButtonSize: ButtonSize
}

export const ErrorState = ({ onClose, onTryAgain, ButtonSize }: JobPostingErrorProps) => {
  return (
    <ErrorContiner>
      <TopWrapper>
        <ErrorTitle>
          {I18n.t('builder.resume_optimizer.job_posting.check_score_error.title')}
        </ErrorTitle>
        <ErrorCloseIcon onClick={onClose} />
      </TopWrapper>
      {I18n.t('builder.resume_optimizer.job_posting.check_score_error.content')
        .split('! ')
        .map((str, index) => (
          <ErrorContent key={index}>
            {str}
            {index === 0 && '!'}
          </ErrorContent>
        ))}
      <ErrorTryAgainButton size={ButtonSize} isTryAgain={true} onClick={onTryAgain}>
        {I18n.t('builder.resume_optimizer.job_posting.try_again')}
      </ErrorTryAgainButton>
    </ErrorContiner>
  )
}
