import styled, { css } from 'styled-components'

import { PromotionTopic } from 'builder/components/SubscriptionCancellationFormNew/common/types'
import Media from 'builder/styles/media'

interface Props {
  topic: PromotionTopic
}

export const PromotionContentV7 = styled.div<Props>(() => {
  return css`
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 0 40px;
    justify-content: space-between;

    ${Media.Tablet`
        flex-direction: column-reverse;
        align-items: center;
        margin: 0;
    `}

    ${Media.Phone`
        flex-direction: column-reverse;
        align-items: center;
        margin: 0;
    `}
  `
})
