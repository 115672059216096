import { useI18n } from 'builder/hooks/useI18n'
import { TRANSLATION_PREFIX } from '../InterviewPlayerView'
import { Text, Container, Divider } from './styles'

interface Props {
  text: string
  onYesClick: () => void
  onNoClick: () => void
}
export const ConfirmationDialog = ({ text, onNoClick, onYesClick }: Props) => {
  const { i18n } = useI18n()
  return (
    <Container>
      <Text>{text}</Text>
      <Divider />
      <Text $enabledHover onClick={onNoClick}>
        {i18n.t(`${TRANSLATION_PREFIX}.no_word`)}
      </Text>
      <Text $enabledHover onClick={onYesClick}>
        {i18n.t(`${TRANSLATION_PREFIX}.yes_word`)}
      </Text>
    </Container>
  )
}
