import { useEffect, useRef, useState } from 'react'
import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { FetchStatuses } from 'builder/modules/constants'
import {
  ResumeCritique as IResumeCritique,
  ResumeCritiqueStatuses,
  RESUME_WRITING_SERVICE_PAGE_URL,
} from 'builder/modules/resumeReview'
import { selectors as critiqueSelectors } from 'builder/modules/resumeCritiqueReview'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { Container, Iframe, Spinner } from './styles'
import { MessageTypesEnum, ValidMessagePayload } from './types'

const isValidMessagePayload = (data: unknown): data is ValidMessagePayload => {
  return typeof data === 'object' && data !== null && 'type' in data
}

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  critique?: IResumeCritique
}

// TODO: Real iframe URL
export const ResumeCritique = ({ critique, ...props }: Props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [iframeStatus, setIframeStatus] = useState(FetchStatuses.loading)
  const [height, setHeight] = useState(600)
  const iframe = useRef<HTMLIFrameElement>(null)

  // Free version of critique for Recruiter.com
  const critiqueQueryParams = new URLSearchParams(location.search)
  const critiqueKey = critiqueQueryParams.get('review') === '1'
  const freeCritique = useTypedSelector(critiqueSelectors.triggeredCritiqueStatus)
  const freeCritiqueStatus = critiqueKey && freeCritique?.status === ResumeCritiqueStatuses.pending
  const isLowLeadsKey = critiqueQueryParams.get('lowLeads') === '1'

  const ResumeCritiqueStatus = iframeStatus === FetchStatuses.loading
  // Leave the page if review is failed
  useEffect(() => {
    if (critique?.status === ResumeCritiqueStatuses.failed) navigate('/', { replace: true })
  }, [critique?.status, navigate])

  const invokeFunctionByType = (data: ValidMessagePayload) => {
    const { type } = data

    // Resize the iframe
    if (type === MessageTypesEnum.Loaded || type === MessageTypesEnum.Resize) {
      setHeight(data.height)
    }

    // Track button clicks
    if (type === MessageTypesEnum.LinkClick) {
      const isResumeWritingLink = data.url.match(/(cv|resume)-writing/i)

      if (isResumeWritingLink) {
        trackInternalEvent('click_resume_get_professional_help_button', { section: 'review_body' })
        window.open(RESUME_WRITING_SERVICE_PAGE_URL)
      } else {
        window.open(data.url)
      }
    }
  }

  // Catch messages coming from the iframe
  useEffect(() => {
    const handlePostMessage = ({ data, source }: MessageEvent<unknown>) => {
      // Ignore events coming from other sources (e.g. browser extensions)
      if (!iframe.current || source !== iframe.current.contentWindow) return
      // Make sure the payload has proper structure
      if (isValidMessagePayload(data)) {
        invokeFunctionByType(data)
      }
    }

    window.addEventListener('message', handlePostMessage)
    return () => window.removeEventListener('message', handlePostMessage)
  }, [])

  const renderIframe = () => {
    if (critiqueKey && freeCritique?.status === ResumeCritiqueStatuses.finished) {
      return (
        <Iframe
          ref={iframe}
          src={freeCritique?.iframeUrl}
          style={{ height }}
          scrolling="no"
          data-status={iframeStatus}
          onLoad={() => setIframeStatus(FetchStatuses.loaded)}
        />
      )
    } else if (critique?.status === ResumeCritiqueStatuses.finished) {
      return (
        <Iframe
          ref={iframe}
          src={critique.iframeUrl}
          style={{ height }}
          scrolling="no"
          data-status={iframeStatus}
          onLoad={() => setIframeStatus(FetchStatuses.loaded)}
        />
      )
    } else if (isLowLeadsKey && critique?.iframeUrl) {
      return (
        <Iframe
          ref={iframe}
          src={critique?.iframeUrl}
          style={{ height }}
          scrolling="no"
          data-status={iframeStatus}
          onLoad={() => setIframeStatus(FetchStatuses.loaded)}
        />
      )
    }
  }

  return (
    <Container {...props}>
      {(freeCritiqueStatus || ResumeCritiqueStatus) && <Spinner />}

      {renderIframe()}
    </Container>
  )
}
