import { useI18n } from 'builder/hooks/useI18n'
import {
  Container,
  ImageContainer,
  Image,
  TextContainer,
  TextHeader,
  TextSubheader,
} from './styles'

import ANIMATION from './assets/animation.gif'

export const ChromeHoverPromote = () => {
  const { i18n } = useI18n()

  return (
    <Container>
      <ImageContainer>
        <Image src={ANIMATION} />
      </ImageContainer>
      <TextContainer>
        <TextHeader>
          {i18n.t('builder.job_tracking.chrome_extension_hover_popover.header')}
        </TextHeader>
        <TextSubheader>
          {i18n.t('builder.job_tracking.chrome_extension_hover_popover.sub_header')}
        </TextSubheader>
      </TextContainer>
    </Container>
  )
}
