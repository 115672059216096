import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

export const ContentWrapper = styled.div`
  color: ${Colors.Neutral50};
`

export const List = styled.ul`
  margin: 0;
  padding-left: 25px;
`

export const ListItem = styled.li`
  margin-bottom: 10px;

  li:last-child {
    margin-bottom: 0;
  }

  ${Media.Phone`
    margin-bottom: 5px;
  `}
`

export const ShowMore = styled.div`
  > span {
    cursor: pointer;
    padding-left: 10px;
    color: ${Colors.Blue50};
  }
`
