import { useCallback, useRef } from 'react'
import * as React from 'react'
import { useI18n } from 'builder/hooks/useI18n'
import { ACCEPT } from '../constants'
import {
  FooterContainer,
  FooterSaveButton,
  FooterUpload,
  FooterUploadInput,
  FooterUploadIcon,
  FooterUploadText,
} from './styles'

interface FooterProps {
  onFileChoose: (file: File) => void
  onSave: () => void
}

export const Footer = ({ onFileChoose, onSave }: FooterProps) => {
  const { i18n } = useI18n()

  const fileInput = useRef<HTMLInputElement>(null)

  const handleUploadClick = useCallback(() => {
    if (fileInput.current) fileInput.current.click()
  }, [])

  const handleFileChoose = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) onFileChoose(e.target.files[0])
    },
    [onFileChoose],
  )

  return (
    <FooterContainer>
      <FooterUpload onClick={handleUploadClick}>
        <FooterUploadInput
          type="file"
          accept={ACCEPT}
          ref={fileInput}
          onChange={handleFileChoose}
        />
        <FooterUploadIcon />
        <FooterUploadText>{i18n.t('builder.avatar_editor.upload_new')}</FooterUploadText>
      </FooterUpload>

      <FooterSaveButton onClick={onSave}>{i18n.t('builder.avatar_editor.save')}</FooterSaveButton>
    </FooterContainer>
  )
}
