import { memo, useRef } from 'react'
import { useBodyScrollLock } from 'builder/hooks/useBodyScrollLock'
import { useEscapeHandler } from 'builder/hooks/useEscapeHandler'
import { FeatureCard } from 'builder/hooks/useFeatureCards'
import { i18n } from 'builder/utils/i18n'
import { useCategories } from '../../hooks/useCategories'
import { ToggleCallback } from '../../types'
import {
  Container,
  Divider,
  ScrollContainer,
  Content,
  Wrapper,
  LeftMenu,
  ServicePannel,
  MenuGrid,
  EmptyHeader,
} from './styles'
import { MenuPanels } from './components/MenuPanels'
import { MenuCategory } from './MenuCategory'
interface Props {
  onToggle: ToggleCallback
  features: FeatureCard[]
}

export const MenuComponent = ({ onToggle, features }: Props) => {
  const columns = useCategories(features)
  const isFullMenu = features.length > 8
  const isMiniMenu = features.length <= 8

  const scrollContainerRef = useRef<HTMLDivElement | null>(null)
  useBodyScrollLock(scrollContainerRef, { reserveScrollBarGap: true })
  useEscapeHandler(() => onToggle(false))

  const renderlocalMenuCategory = (isLeft: boolean) => {
    const halfLength = Math.ceil(features.length / 2)
    const leftFeatures = features.slice(0, halfLength)
    const rightFeatures = features.slice(halfLength, features.length)
    const title = isLeft ? i18n.t('builder.localize_titles.career_services') : ''

    return <MenuCategory category={{ title, features: isLeft ? leftFeatures : rightFeatures }} />
  }

  const renderMenuCategories = (startIndex: number, endIndex: number, leftColumn: boolean) => {
    return columns?.slice(startIndex, endIndex)?.map((category, columnIndex) => {
      const obj = category.features.find(o => o.isLeftColumn === leftColumn)
      const position = obj?.isLeftColumn === leftColumn

      return position && <MenuCategory key={columnIndex} category={category} />
    })
  }

  return (
    <Container>
      <Divider />
      <ScrollContainer ref={scrollContainerRef}>
        <Content>
          <LeftMenu>
            <MenuPanels />
            <ServicePannel>{isFullMenu && renderMenuCategories(0, 5, true)}</ServicePannel>
          </LeftMenu>
          <div>
            {isMiniMenu ? (
              <Wrapper>
                <MenuGrid>{renderlocalMenuCategory(true)}</MenuGrid>
                <MenuGrid>
                  <EmptyHeader />
                  {renderlocalMenuCategory(false)}
                </MenuGrid>
              </Wrapper>
            ) : (
              <Wrapper>
                <MenuGrid>{renderMenuCategories(0, 3, false)}</MenuGrid>
                <MenuGrid>{renderMenuCategories(3, 5, false)}</MenuGrid>
              </Wrapper>
            )}
          </div>
        </Content>
      </ScrollContainer>
    </Container>
  )
}

export const Menu = memo(MenuComponent)
