import { useEffect, useRef, useState } from 'react'
import throttle from 'lodash/throttle'
import { Resume } from '@rio/types'
import { analyzeResume } from '../utils/analyzeResume'
import { Task } from '../types'

export const useThrottledTaskAnalyzer = (resume: Resume, taskId: string | null, delay: number) => {
  const [task, setTask] = useState<Task | null>(null)

  const perform = useRef(
    throttle((resume: Resume, id: string | null) => {
      // Doesn't perform analysis if the tuner editor is not enabled by query
      if (!id) return setTask(null)

      const task = analyzeResume(resume)?.tasks?.find(task => task.id === id)
      setTask(task || null)
    }, delay),
  )

  useEffect(() => {
    perform.current(resume, taskId)
  }, [resume, taskId])

  return task
}
