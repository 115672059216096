import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { Typography, FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  width: 100%;
  max-width: 544px;
  margin: 0 auto;
`

export const Header = styled.div`
  margin-bottom: 32px;
  text-align: center;
`

export const Title = styled.div`
  ${Typography.XL};
  ${FontWeights.DemiBold};
  margin-bottom: 16px;
`

export const Subtitle = styled.div`
  color: ${Colors.Neutral50};
`

export const Content = styled.div`
  width: 100%;
  max-width: 416px;
  margin: 0 auto;
`
