import styled from 'styled-components'
import Typography from 'builder/styles/typography'

export const Container = styled.div`
  gap: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const Location = styled.p`
  width: 100%;
  ${Typography.Tiny};
`

export const CoachLocationIcon = styled.img``
