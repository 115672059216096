import styled from 'styled-components'
import Button, { ButtonTheme } from 'builder/components/Button'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;

  max-width: 480px;
  margin: 0 auto;
  height: calc(100vh - 200px);

  ${Media.Phone`
    padding: 0 20px;
  `}
`

export const ImageContainer = styled.div`
  width: 238px;
  height: 219px;
`

export const Image = styled.img`
  width: 232px;
  height: 192px;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  text-align: center;
`

export const TextHeader = styled.div`
  color: ${Colors.Neutral90};

  ${Typography.L}
  ${FontWeights.Medium}
`

export const TextSubHeader = styled.div`
  color: ${Colors.Neutral50};

  ${Typography.Body}
  ${FontWeights.Regular}
`

export const ContactSupport = styled.a`
  color: ${Colors.Blue50};

  &:hover {
    border-bottom: 1px solid ${Colors.Blue50};
  }
`

export const ContinueButton = styled(Button).attrs({ theme: ButtonTheme.ghost })``
