import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Icon20, Icon24 } from 'builder/components/Icon'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { actions } from 'builder/modules/resumeEditor'
import { i18n as I18n } from 'builder/utils/i18n'
import ModalOverlay from '../../ModalOverlay'
import { Container, ActionBar, BackButton, ChevronLeft, CloseButton } from './styles'
import ViewAllExamples from './ViewAllExamples'
import { ResumeExampleContextProvider } from './ResumeExampleContext'
import { ResumeExample } from './types'
import SelectedResumeExamplePrev from './SelectedResumeExample'

const UseExample = () => {
  const dispatch = useDispatch()
  const { isPhone } = useMediaQueries()

  const [selectedResumeExample, setSelectedResumeExample] = useState<ResumeExample | null>(null)
  const isResumePreview = !!selectedResumeExample

  useEffect(() => {
    // to handle browser back scenarios
    return () => {
      dispatch(actions.setShowUseExamplePopup(false))
    }
  }, [dispatch])

  const onBack = () => {
    if (isResumePreview) {
      setSelectedResumeExample(null)
    } else {
      dispatch(actions.setShowUseExamplePopup(false))
      dispatch(actions.setShowPrefillResumeModal(true))
    }
  }

  const onClose = () => {
    dispatch(actions.setShowUseExamplePopup(false))
  }

  return (
    <ModalOverlay overlayFadeDuration={300} contentSlideDuration={300} fullScreen={isPhone}>
      <Container isResumePreview={isResumePreview}>
        <ActionBar>
          <BackButton isResumePreview={isResumePreview} onClick={onBack}>
            <ChevronLeft>
              <Icon24.Chevron />
            </ChevronLeft>
            {I18n.t('builder.use_example.back')}
          </BackButton>
          <CloseButton isResumePreview={isResumePreview} onClick={onClose}>
            <Icon20.Close />
          </CloseButton>
        </ActionBar>
        <ResumeExampleContextProvider {...{ selectedResumeExample, setSelectedResumeExample }}>
          {isResumePreview ? <SelectedResumeExamplePrev /> : <ViewAllExamples />}
        </ResumeExampleContextProvider>
      </Container>
    </ModalOverlay>
  )
}

export default UseExample
