import { useState, useRef, useMemo, useEffect } from 'react'
import * as React from 'react'
import { apiClient } from 'builder/modules/apiClient'
import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import TrustpilotModal from 'builder/components/TrustpilotModal'
import { useTrustpilot } from '../hooks'
import {
  Container,
  Content,
  Title,
  Subtitle,
  Rating,
  RatingItem,
  RatingItemIcon,
  RatingItemText,
  Form,
  FormTitle,
  TextArea,
  SendButton,
  Success,
  SuccessIcon,
} from './styles'

enum STEPS {
  rate = 'RATE',
  form = 'FORM',
  done = 'DONE',
}

export const FeedbackForm = () => {
  const { i18n } = useI18n()
  const [rating, setRating] = useState<number | null>(null)
  const [isSubmitted, setSubmitted] = useState(false)
  const [isTrustpilotOpen, setTrustpilotOpen] = useState(false)
  const [message, setMessage] = useState('')
  const { iframeUrl } = useTrustpilot()
  const { isTablet } = useMediaQueries()
  const textArea = useRef<HTMLTextAreaElement | null>(null)

  const ratings = [
    i18n.t('builder.after_sale.feedback_form.bad'),
    i18n.t('builder.after_sale.feedback_form.poor'),
    i18n.t('builder.after_sale.feedback_form.average'),
    i18n.t('builder.after_sale.feedback_form.great'),
    i18n.t('builder.after_sale.feedback_form.excellent'),
  ]

  const step: STEPS = useMemo(() => {
    if (isSubmitted) return STEPS.done
    if (rating && rating <= 3) return STEPS.form
    return STEPS.rate
  }, [isSubmitted, rating])

  useEffect(() => {
    if (step === STEPS.form) setTimeout(() => textArea.current?.focus(), 150)
  }, [step])

  const handleItemClick = (value: number) => {
    setRating(value)

    if (value > 3) {
      apiClient.post(`/feedback/save-rating`, { rating: value })
      setSubmitted(true)
      // Trustpilot modal is available on desktops only
      if (!isTablet && iframeUrl) setTrustpilotOpen(true)
    }
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!rating || !message.trim().length) return

    apiClient.post(`/feedback/rate`, { message, rating })
    setSubmitted(true)
  }

  const handleMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value)
  }

  return (
    <Container>
      <Content isVisible={step === STEPS.rate}>
        <Title>{i18n.t('builder.after_sale.feedback_form.how_would_you_rate')}</Title>
        <Subtitle>{i18n.t('builder.after_sale.feedback_form.help_us_improve_service')}</Subtitle>

        <Rating>
          {ratings.map((stage, index) => (
            <RatingItem
              key={index}
              isActive={rating === index + 1}
              isDisabled={rating !== null && rating !== index + 1}
              onClick={() => handleItemClick(index + 1)}
            >
              <RatingItemIcon index={index} />
              <RatingItemText>{stage}</RatingItemText>
            </RatingItem>
          ))}
        </Rating>
      </Content>

      <Form as="form" isVisible={step === STEPS.form} onSubmit={handleSubmit}>
        <FormTitle>{i18n.t('builder.after_sale.feedback_form.what_went_wrong')}</FormTitle>
        <TextArea
          ref={textArea}
          value={message}
          placeholder={i18n.t('builder.after_sale.feedback_form.placeholder')}
          onChange={handleMessageChange}
          rows={3}
        />
        <SendButton type="submit">
          {i18n.t('builder.after_sale.feedback_form.send_feedback')}
        </SendButton>
      </Form>

      <Success isVisible={step === STEPS.done}>
        <SuccessIcon />
        <Title>{i18n.t('builder.after_sale.feedback_form.success_title')}</Title>
        <Subtitle>{i18n.t('builder.after_sale.feedback_form.success_text')}</Subtitle>
      </Success>

      {isTrustpilotOpen && iframeUrl && (
        <TrustpilotModal iframeUrl={iframeUrl} onClose={() => setTrustpilotOpen(false)} />
      )}
    </Container>
  )
}
