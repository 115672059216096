import { cloneElement } from 'react'
import PropTypes from 'prop-types'
import { useLocation, Routes } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { AnimationWrapper, TransitionWrapper } from './styles'

const RouteTransitionSwitch = ({ children, direction, duration, animationEffect }) => {
  const classNames = `route-transition-switch-${animationEffect}`
  const location = useLocation()

  return (
    <TransitionWrapper>
      <TransitionGroup
        childFactory={child =>
          cloneElement(child, { direction, duration, animationEffect, classNames })
        }
        component={null}
      >
        <CSSTransition key={location.key} classNames={classNames} timeout={duration}>
          <AnimationWrapper direction={direction} duration={duration}>
            <Routes location={location}>{children}</Routes>
          </AnimationWrapper>
        </CSSTransition>
      </TransitionGroup>
    </TransitionWrapper>
  )
}

RouteTransitionSwitch.propTypes = {
  children: PropTypes.node,
  direction: PropTypes.string,
  duration: PropTypes.number,
  animationEffect: PropTypes.string,
}

RouteTransitionSwitch.defaultProps = {
  direction: 'forward',
  duration: 300,
  animationEffect: 'slide',
}

export default RouteTransitionSwitch
