import { Icon20 } from 'builder/components/Icon'
import * as Styles from './styles'
import { EHeaderMediaType } from './constants'

interface IProps {
  handleClose: () => void
  headerMedia: string
  headerMediaType: EHeaderMediaType
}

const ModalHeader = ({ handleClose, headerMedia, headerMediaType }: IProps) => {
  return (
    <>
      <Styles.Close onClick={handleClose} data-testid="modal-close-button">
        <Icon20.Close />
      </Styles.Close>
      {headerMediaType === EHeaderMediaType.IMAGE ? (
        <Styles.Visual src={headerMedia} data-testid="modal-visual-image" />
      ) : (
        <Styles.VisualVideo
          src={headerMedia}
          autoPlay
          controls={false}
          muted
          data-testid="modal-visual-video"
        />
      )}
    </>
  )
}

export default ModalHeader
