import { accomplishmentRankTipsText } from 'builder/modules/sevenStories'
import { HintIcon, TipsContainer, TipsTitle, TooltipQuestionWrapper } from '../style'

export const ToolTip = () => {
  return (
    <TooltipQuestionWrapper value={accomplishmentRankTipsText} position="right" multiline>
      <TipsContainer className="tips-container">
        <TipsTitle>Tips</TipsTitle>
        <HintIcon className="hint-icon" />
      </TipsContainer>
    </TooltipQuestionWrapper>
  )
}
