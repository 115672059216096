import { Fragment } from 'react'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { LinkedinMergePopup } from 'builder/components/PrefillPopover'
import {
  selectors as resumeEditorSelectors,
  PrefillProviders,
  PrefillStatuses,
} from 'builder/modules/resumeEditor'
import { PopoverContainer, PopoverMobileOverlay } from './styles'

// We need to show Merge popup in the dashboard only if it has these statuses
const MERGE_POPUP_VISIBLE_STATUSES = [
  PrefillStatuses.readyToMerge,
  PrefillStatuses.failed,
  PrefillStatuses.mergedAuto,
]

export const Popover = () => {
  const { provider, status, resumeId } = useTypedSelector(resumeEditorSelectors.prefill)
  const { isDesktop, isLaptop } = useMediaQueries()

  const isLinkedinMergePopupShowed =
    isDesktop &&
    resumeId &&
    provider === PrefillProviders.linkedin &&
    MERGE_POPUP_VISIBLE_STATUSES.includes(status)

  if (!isLinkedinMergePopupShowed) return null

  return (
    <Fragment>
      {isLaptop && <PopoverMobileOverlay />}
      <PopoverContainer>
        {isLinkedinMergePopupShowed && <LinkedinMergePopup documentId={resumeId} />}
      </PopoverContainer>
    </Fragment>
  )
}
