import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { UseQueryResult } from '@tanstack/react-query'
import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import Button from 'builder/components/Button'
import { Container } from 'builder/components/Container'
import { selectors as jobSearchSelectors } from 'builder/modules/jobSearch'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import JobDetails, { JobDetailsSkeleton } from '../JobDetails'
import { generateQueryString, getLocationFromServer } from '../utils'
import { SearchSuggestionType } from '../AutoSuggestField/types'
import { ManagerJobAlertButton } from '../AlertModal/ManageJobAlertButton/ManageJobAlertButton'
import { Job } from '../JobSearchResult/types'
import { AlertModal } from '../AlertModal/AlertModal'
import JobSearchNotification from '../JobItem/JobSearchNotification'
import {
  ButtonContainer,
  Content,
  CustomSearchBar,
  DesktopOnlyVisibleContainer,
  JobDetailsContainer,
  List,
  ManagerJobAlertButtonContainer,
  MoreJobColumnContainer,
  MoreJobColumnTitle,
  Title,
  TitleContainer,
} from './styles'
import { Card } from './components/Card/Card'
import { SkeletonCard } from './components/Card/SkeletonCard'
import { JobNoLongerAvailable } from './components/JobNoLongerAvailable/JobNoLongerAvailable'
interface Props {
  job: Job | undefined
  cardJobs: UseQueryResult<Job[] | undefined, unknown>
  nearMeLocation: SearchSuggestionType | undefined | null
  setNearMeLocation: (e: SearchSuggestionType | undefined | null) => void
}

export const JobAlertPage = ({ job, cardJobs, nearMeLocation, setNearMeLocation }: Props) => {
  const { i18n } = useI18n()
  const { customAlertId } = useParams()

  const [term, setTerm] = useState('')
  const [timeInterval, setTimeInterval] = useState('0')

  const [locationText, setLocationText] = useState('')
  const [selectedLocation, setSelectedLocation] = useState<SearchSuggestionType>()
  const [showMoreLikeThisCardsMobile, setShowMoreLikeThisCardsMobile] = useState(false)

  const [activeJob, setActiveJob] = useState<Job>()
  const { isDesktop } = useMediaQueries()

  useEffect(() => {
    if (job) {
      setActiveJob(job)
    }
  }, [job])

  const navigate = useNavigate()

  useEffect(() => {
    async function handleLocation() {
      try {
        const location = await getLocationFromServer()
        setNearMeLocation(location)
      } catch (error) {
        setNearMeLocation(null)
      }
    }
    handleLocation()
  }, [])
  const searchForm = useTypedSelector(jobSearchSelectors.searchForm)

  const searchBar = (
    <CustomSearchBar
      searchBarType="job"
      showBlackValues={false}
      term={term}
      timeInterval={timeInterval}
      nearMeLocation={nearMeLocation || undefined}
      locationText={locationText}
      selectedLocation={selectedLocation}
      setTerm={setTerm}
      setTimeInterval={setTimeInterval}
      setLocationText={setLocationText}
      setSelectedLocation={setSelectedLocation}
      onSearch={() => {
        const params = {
          query: term,
          within_n_days: timeInterval,
          only_remote_jobs: Boolean(searchForm.filters.onlyRemoteJobs),
          location: nearMeLocation?.formatted || undefined,
          location_latitude: nearMeLocation?.lat || undefined,
          location_longitude: nearMeLocation?.lng || undefined,
          location_id: nearMeLocation?.value,
        }
        const queryStrings = generateQueryString(params)
        navigate(`/job-search?view=search&${queryStrings}`)
      }}
    />
  )

  if (job && Date.parse(job?.expired_at) < Date.now()) {
    const showSearchBar = showMoreLikeThisCardsMobile || isDesktop
    return (
      <Container>
        {showSearchBar ? searchBar : undefined}
        {showSearchBar && (
          <>
            <ManagerJobAlertButton />
            <AlertModal />
          </>
        )}
        <JobNoLongerAvailable
          onBack={
            showMoreLikeThisCardsMobile ? undefined : () => setShowMoreLikeThisCardsMobile(true)
          }
        />
      </Container>
    )
  }

  if (!isDesktop && showMoreLikeThisCardsMobile) {
    return (
      <Container>
        <AlertModal />
        {searchBar}
        <TitleContainer>
          <ManagerJobAlertButton />
          <Title>{i18n.t('builder.job_search.job_page.title')}</Title>
        </TitleContainer>
        <List>
          {(cardJobs.data || []).map(job => (
            <Card
              isMobileTable={true}
              key={job.external_slug_id}
              job={job}
              onClick={() => {
                setActiveJob(job)
                setShowMoreLikeThisCardsMobile(false)
              }}
            />
          ))}

          {cardJobs.isLoading === undefined && (
            <>
              <SkeletonCard isMobileTable={true} />
              <SkeletonCard isMobileTable={true} />
              <SkeletonCard isMobileTable={true} />
              <SkeletonCard isMobileTable={true} />
              <SkeletonCard isMobileTable={true} />
            </>
          )}
        </List>
      </Container>
    )
  }

  return (
    <Container>
      <AlertModal />
      <DesktopOnlyVisibleContainer>{searchBar}</DesktopOnlyVisibleContainer>
      <DesktopOnlyVisibleContainer>
        <ManagerJobAlertButtonContainer>
          <ManagerJobAlertButton />
        </ManagerJobAlertButtonContainer>
      </DesktopOnlyVisibleContainer>
      <Content>
        <JobDetailsContainer>
          {!activeJob && <JobDetailsSkeleton />}
          {activeJob && (
            <JobDetails
              job={activeJob}
              setApplyDialog={() => {}}
              goBack={() => setShowMoreLikeThisCardsMobile(true)}
            />
          )}
        </JobDetailsContainer>
        <DesktopOnlyVisibleContainer>
          <MoreJobColumnContainer>
            <MoreJobColumnTitle>
              {i18n.t('builder.job_search.job_page.column_title')}
            </MoreJobColumnTitle>
            <List>
              {(cardJobs.data || []).slice(0, 4).map(cardJob => (
                <Card
                  isMobileTable={false}
                  key={cardJob.external_slug_id}
                  job={cardJob}
                  onClick={() => {
                    if (window.location.pathname.includes('recommend-alert')) {
                      return navigate(`/job-search/recommend-alert/${cardJob.external_slug_id}`)
                    }

                    if (window.location.pathname.includes('custom-alert')) {
                      return navigate(
                        `/job-search/custom-alert/${customAlertId}/${cardJob.external_slug_id}`,
                      )
                    }
                  }}
                />
              ))}
              {cardJobs.data === undefined && (
                <>
                  <SkeletonCard />
                  <SkeletonCard />
                  <SkeletonCard />
                  <SkeletonCard />
                  <SkeletonCard />
                </>
              )}
            </List>
            <ButtonContainer>
              <Button
                theme="ghost"
                onClick={() => {
                  const params = {
                    query: job?.job_title,
                    location: job?.location,
                    location_id: job?.location_id,
                  }
                  const queryStrings = generateQueryString(params)
                  navigate(`/job-search?${queryStrings}`)
                }}
              >
                {i18n.t('builder.job_search.job_page.see_more_jobs')}
              </Button>
            </ButtonContainer>
          </MoreJobColumnContainer>
        </DesktopOnlyVisibleContainer>
      </Content>
      <JobSearchNotification />
    </Container>
  )
}
