import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import Button, { ButtonSize } from 'builder/components/Button'
import { Icon20 } from 'builder/components/Icon'
import { actions as uiActions } from 'builder/modules/ui'
import ModalOverlay from 'builder/components/ModalOverlay'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import * as Styles from './styles'
import tunerURL from './assets/img/tuner.gif'

const features = [
  { id: 1, text: 'Access to all templates' },
  { id: 2, text: 'Unlimited resume downloads' },
  { id: 3, text: 'Unlimited cover letters' },
]
const LABEL = {
  label: 'tuner',
}

const TunerPrePaywallModal = () => {
  const dispatch = useDispatch()
  const { isPhone } = useMediaQueries()
  const [isOpen, setIsOpen] = useState(Boolean(localStorage.getItem('IS_TUNER_MODAL_OPEN')))

  const handleUpgrade = () => {
    localStorage.removeItem('IS_TUNER_MODAL_OPEN')
    dispatch(uiActions.openPaywall())
    trackInternalEvent('click_upgrade_resume_tuner_paywall', LABEL)
  }

  const handleClose = () => {
    localStorage.removeItem('IS_TUNER_MODAL_OPEN')
    trackInternalEvent('click_skip_resume_tuner_paywall', LABEL)
    setIsOpen(Boolean(localStorage.getItem('IS_TUNER_MODAL_OPEN')))
  }

  useEffect(() => {
    if (isOpen) trackInternalEvent('see_tuner_paywall', LABEL)
  }, [isOpen])

  if (!isOpen) {
    return null
  }

  return (
    <ModalOverlay overlayFadeDuration={150} contentSlideDuration={150}>
      <Styles.Container>
        <Styles.Close onClick={handleClose}>
          <Icon20.Close />
        </Styles.Close>
        <Styles.Visual src={tunerURL} />
        <Styles.DescriptionSection>
          <Styles.Description>
            <Styles.Header>
              <Styles.PremiumBadge>
                <Styles.PremiumIcon />
                <Styles.PremiumText>Available for Premium</Styles.PremiumText>
              </Styles.PremiumBadge>
              <Styles.Title>Tune up your resume!</Styles.Title>
            </Styles.Header>
            <Styles.SubTitle>
              Improve your resume step by step using smart suggestions that will help you craft an
              incredible resume and land your dream job.
            </Styles.SubTitle>
          </Styles.Description>
          <Styles.UnlockFeaturesSection>
            <Styles.UnlockText>Also Unlock:</Styles.UnlockText>
            <Styles.FeaturesContainer>
              {features.map(feature => (
                <Styles.FeatureItem key={feature.id}>
                  <Styles.GreenTick />
                  <Styles.Feature>{feature.text}</Styles.Feature>
                </Styles.FeatureItem>
              ))}
            </Styles.FeaturesContainer>
          </Styles.UnlockFeaturesSection>
          <Styles.ButtonContainer>
            <Button
              size={!isPhone ? ButtonSize.default : ButtonSize.small}
              theme="ghost"
              onClick={handleClose}
            >
              Skip
            </Button>
            <Button size={!isPhone ? ButtonSize.default : ButtonSize.small} onClick={handleUpgrade}>
              Upgrade Now
            </Button>
          </Styles.ButtonContainer>
        </Styles.DescriptionSection>
      </Styles.Container>
    </ModalOverlay>
  )
}

export default TunerPrePaywallModal
