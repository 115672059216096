import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { ElementProps } from '../../types'
import { getColor } from '../../utils'

export const Option = styled.label<
  {
    rating?: number
    checked: boolean
  } & ElementProps
>`
  display: inline-block;
  position: relative;
  flex-grow: 1;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: -1px;
    height: 100%;
    background-color: ${props => getColor(props, 0.1) || Colors.Blue10};
    transition: background-color 0.1s;
    border-radius: 4px;
  }

  &:hover:before {
    background-color: ${props => getColor(props, 0.2)};
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 1px;
    height: 14px;
    margin-top: -7px;
    background-color: ${props => getColor(props, 0.3) || Colors.Neutral30};
  }

  &:first-child:after {
    display: none;
  }

  & input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
  }
`
