import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import DocumentImagePreview from 'builder/components/DocumentImagePreview'

export const Container = styled.div`
  width: 100%;
  background-color: ${Colors.Blue10};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  padding-top: 24px;
`

export const OptimizeResume = styled.div`
  display: flex;
  position: relative;
  left: 10px;
`

export const Wrapper = styled.div`
  border-radius: 8px;
  height: 185px;
`

export const ScoreSection = styled.div`
  height: fit-content;
  top: 33px;
  position: relative;
  width: 45px;
  margin-left: 20px;
`

export const Title = styled.div`
  ${Typography.Tiny};
  ${FontWeights.DemiBold};
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  color: ${Colors.Blue80};
  opacity: 0.6;
`

export const VisualGradient = styled.div`
  position: relative;
  height: 37px;
  bottom: 37px;
  background: linear-gradient(to bottom, ${Colors.White} 0%, ${Colors.Blue10} 100%);
  z-index: 100;
`

export const ResumeScore = styled.div<{ score: number }>`
  ${FontWeights.Bold};
  display: flex;
  border-radius: 6px;
  padding: 3px 6px;
  align-items: center;
  justify-content: center;
  color: ${Colors.White};
  margin-bottom: 8px;
  background: ${props =>
    props.score <= 30 ? Colors.Red40 : props.score <= 70 ? Colors.Amber40 : Colors.Green40};

  &:after {
    content: '%';
  }
`

export const ImagePreview = styled(DocumentImagePreview)`
  width: 129px;
  height: 184px;
`

export const Image = styled.img`
  border-radius: 8px;
  height: 184px;
`
