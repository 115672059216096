import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Button, { ButtonSize, ButtonTheme } from 'builder/components/Button'
import Typography, { FontWeights } from 'builder/styles/typography'

export const TooltipBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px;
  width: 352px;
  border-radius: 16px;
  background: ${Colors.White};

  box-shadow: 0px 0px 1px 0px rgba(15, 56, 113, 0.32), 0px 2px 4px -1px rgba(15, 56, 113, 0.08),
    0px 8px 20px -4px rgba(15, 56, 113, 0.12);
`

export const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const TooltipTitle = styled.div`
  color: ${Colors.Neutral90};

  ${Typography.Body};
  ${FontWeights.Medium};
`

export const TooltipSubHeader = styled.div`
  color: ${Colors.Neutral50};

  ${Typography.Caption};
  ${FontWeights.Regular};
`

export const TooltipFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Steps = styled.div`
  color: ${Colors.Neutral50};

  ${Typography.Caption};
  ${FontWeights.Medium};
`

export const Actions = styled.div`
  display: flex;
  gap: 8px;
  margin-left: auto;
`

export const SkipButton = styled(Button).attrs({
  size: ButtonSize.small,
  theme: ButtonTheme.ghost,
})``

export const NextButton = styled(Button).attrs({ size: ButtonSize.small })``
