import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Container = styled.div`
  gap: 20px;
  display: flex;
  justify-content: flex-start;

  ${Media.Phone`
    gap: 12px;
    display: flex;
    flex-direction: column;
  `}
`

export const Avatar = styled.img`
  width: 104px;
  height: 104px;
  border-radius: 50%;
  object-fit: cover;

  ${Media.Phone`
    width: 80px;
    height: 80px;
  `}
`

export const Info = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: flex-start;
  padding-top: 8px;
  gap: 8px;
`

export const Name = styled.p`
  ${Typography.Subhead};
  ${FontWeights.Medium};

  ${Media.Phone`
    ${Typography.S};
  `}
`
