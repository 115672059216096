import { all, call, put, takeLatest } from 'redux-saga/effects'
import ErrorLogger from 'builder/services/ErrorLogger'
import { apiClient } from '../apiClient'
import { actions } from './sevenStoriesModule'
import { AccomplishmentPayload } from './types'

function* setAddAccomplishmentSaga(action: { payload: AccomplishmentPayload[] }) {
  try {
    const params = { accomplishments: action.payload }
    yield call(apiClient.post, '/career-planning/assessments/accomplishments', params)
    yield put(actions.setAccomplishmentStatus({ isSuccess: true }))
  } catch (error) {
    yield put(actions.setAccomplishmentStatus({ isSuccess: false }))
  }
}

function* setSaveAccomplishmentsSaga({
  payload,
}: ReturnType<typeof actions.setSaveAccomplishments>) {
  try {
    yield call(apiClient.post, '/career-planning/assessments/seven-stories-exercise', payload)
    yield put(actions.setAccomplishmentStatus({ isSuccess: true }))
    const { data } = yield call(
      apiClient.get,
      '/career-planning/assessments/seven-stories-exercise',
    )
    yield put(actions.setAssessmentResult(data))
    yield put(actions.setAccomplishmentStatus({ isSuccess: false }))
  } catch (error) {
    ErrorLogger.log(error)
    yield put(actions.setAccomplishmentStatus({ isSuccess: false }))
  }
}

function* fetchAssessmentResultSagas() {
  try {
    const { data } = yield call(
      apiClient.get,
      '/career-planning/assessments/seven-stories-exercise',
    )
    yield put(actions.setAssessmentResult(data))
  } catch (error) {
    ErrorLogger.log(error)
  }
}

export const sagas = function* saga() {
  yield all([takeLatest(actions.setAddAccomplishment, setAddAccomplishmentSaga)])
  yield all([takeLatest(actions.setSaveAccomplishments, setSaveAccomplishmentsSaga)])
  yield all([takeLatest(actions.fetchAssessmentResult, fetchAssessmentResultSagas)])
}
