import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import isThisYear from 'date-fns/isThisYear'
import { actions, selectors } from 'builder/modules/careerPath2'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as userSelectors } from 'builder/modules/user'
import { formatDate, parseDate } from 'builder/utils/formatDate'
import { formatDocumentPreviewUrl } from 'builder/utils/formatDocumentPreviewUrl'
import { BriefResume } from 'builder/modules/panel/types'
import {
  Container,
  Resume,
  ResumeImage,
  ResumeDetails,
  UserName,
  UserDesignation,
  UpdatedAt,
} from './styles'

type UpdatedAt = string | undefined | null

export type ResumeProps = {
  onSelect?: (resume: BriefResume) => void
  miniature?: BriefResume
}

export const Resumes: React.FC<ResumeProps> = props => {
  const dispatch = useDispatch()
  const resumeID = useTypedSelector(selectors.resumeID)
  const user = useTypedSelector(userSelectors.userData)
  const documents = useTypedSelector(selectors.userDocuments)

  useEffect(() => {
    dispatch(actions.fetchUserDocuments())
  }, [])

  const formatUpdatedDate = useCallback((updatedAt: UpdatedAt) => {
    if (updatedAt) {
      const date = parseDate(updatedAt)
      const format = isThisYear(date) ? 'd MMMM, HH:mm' : 'd MMMM yyyy, HH:mm'

      return formatDate(date, format)
    }
  }, [])

  const handleClick = useCallback(
    (resumeID: number) => {
      dispatch(actions.setResumeID(resumeID))
    },
    [dispatch],
  )

  if (props?.miniature) {
    return (
      <>
        <ResumeImage src={formatDocumentPreviewUrl(props.miniature)} />
        <ResumeDetails>
          <UserName>{user?.fullName},</UserName>
          <UserDesignation>{props.miniature.name}</UserDesignation>
          <UpdatedAt>Updated {formatUpdatedDate(props.miniature.updatedAt)}</UpdatedAt>
        </ResumeDetails>
      </>
    )
  }

  return (
    <Container>
      {documents?.map(resume => (
        <Resume
          key={resume.id}
          isSelected={resumeID === resume.id}
          onClick={() => (props.onSelect ? props.onSelect(resume) : handleClick(resume.id))}
        >
          <ResumeImage src={formatDocumentPreviewUrl(resume)} />
          <ResumeDetails>
            <UserName>{user?.fullName},</UserName>
            <UserDesignation>{resume.name}</UserDesignation>
            <UpdatedAt>Updated {formatUpdatedDate(resume.updatedAt)}</UpdatedAt>
          </ResumeDetails>
        </Resume>
      ))}
    </Container>
  )
}
