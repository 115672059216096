import { useCallback } from 'react'
import { useNavigate, useMatch } from 'react-router-dom'

const useRedirect = () => {
  const navigate = useNavigate()
  const match = useMatch('/resumes/:id/wizard')

  const redirectToEditor = useCallback(() => {
    if (match) navigate(`/resumes/${match.params.id}/edit?preview`, { replace: true })
  }, [match, navigate])

  return {
    redirectToEditor,
  }
}

export default useRedirect
