import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import sortBy from 'lodash/sortBy'
import { actions, selectors } from 'builder/modules/panel'
import { selectors as userSelectors } from 'builder/modules/user'
import {
  actions as careerPathsActions,
  selectors as careerPathsSelectors,
} from 'builder/modules/careerPath2'
import { DocumentTypes, FetchStatuses } from 'builder/modules/constants'
import { selectors as interviewSelector } from 'builder/modules/interview/interviewModule'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useResume } from 'builder/hooks/useResume'
import { useConfig } from 'builder/hooks/useConfig'
import { useAnalyzer } from 'builder/components/Tuner'
import { CareerTask } from '../types'
import {
  CareerMatrixVisual,
  CareerRolesVisual,
  DocumentCreateVisual,
  DocumentPreviewVisual,
  JobFinderVisual,
  InterviewPrepVisual,
  OfferAnalyzerVisual,
  ResumeTunerVisual,
  ResourceVisual,
} from '../components/CareerGoalCardVisual'
import { TopResumeVisual } from '../components/CareerGoalCardVisual/TopResumeVisual'

type Result = {
  isLoaded: boolean
  careerTasks: CareerTask[]
}

// TODO: Connect cards to the real data and APIs.
export const useCareerTasks = (): Result => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const config = useConfig()

  // Get user data
  const userPhotoUrl = useTypedSelector(userSelectors.photoUrl)

  // Find user documents
  const documentsStatus = useTypedSelector(selectors.documentsStatus)
  const resume = useTypedSelector(state => selectors.lastCreated(state, DocumentTypes.resume))
  const letter = useTypedSelector(state => selectors.lastCreated(state, DocumentTypes.coverLetter))

  // Find and analyze the resume
  const tunerResumeId = useTypedSelector(selectors.tunerResumeId)
  const tunerResume = useResume(tunerResumeId)
  const tunerReport = useAnalyzer(tunerResume)

  // Career pathways v2
  const careerCards = useTypedSelector(careerPathsSelectors.allCareerCards)
  const careerGoalsStatus = useTypedSelector(careerPathsSelectors.goalsStatus)

  const interviewUrl = useSelector(interviewSelector.getInterviewUrl)

  // Start fetching data if needed
  useEffect(() => {
    if (documentsStatus === FetchStatuses.notAsked) dispatch(actions.fetchDocuments())
    if (careerGoalsStatus === FetchStatuses.notAsked) {
      dispatch(careerPathsActions.fetchCareerCards())
    }
  }, [dispatch, documentsStatus, careerGoalsStatus])

  // Data fetching status
  const isLoaded = useMemo(() => {
    if (documentsStatus !== FetchStatuses.loaded) return false
    if (tunerResumeId && !tunerResume) return false
    return true
  }, [documentsStatus, tunerResumeId, tunerResume])

  // Serialize career tasks data
  const careerTasks = useMemo(() => {
    // Check tasks completeness
    const hasCareerTargets = !!careerCards.length
    const hasResume = resume !== null
    const hasLetter = letter !== null
    const isTunerCompleted = tunerResume !== null && tunerReport?.tasks?.every(t => t.done)

    // Career Pathways v2 texts
    const defaultTitle = 'Explore Your Career Paths'
    const completedTitle = 'Check Your Career Paths'
    const completedText = 'Collect the desired roles with info to your career path.'
    const defaultText = 'Easily build out the path to your ideal career and salary goals.'

    const tasks: Array<CareerTask | false | undefined> = [
      {
        tone: 'blue',
        name: 'resume',
        title: 'Create Your Resume',
        text: 'Take a first step towards success by creating a resume.',
        action: () => {
          if (!tunerResume) {
            dispatch(actions.createDocument({ type: DocumentTypes.resume }))
          } else {
            navigate(`/resumes/${tunerResume?.id}/edit`)
          }
        },
        completed: hasResume,
        visual: hasResume ? (
          <DocumentPreviewVisual document={resume} />
        ) : (
          <DocumentCreateVisual type={DocumentTypes.resume} />
        ),
      },
      hasResume && {
        tone: 'neutral',
        name: 'top_resume',
        title: '',
        text: '',
        completed: false,
        action: () =>
          window.open(
            'https://topresume.com/resume-writing?pt=UWYxd6Xe2VTS0&utm_source=careerio&utm_medium=cioplatformcard&utm_campaign=tr_promocard_in_ciodash&utm_content=v1',
            '_blank',
          ),
        visual: <TopResumeVisual />,
      },
      tunerResume !== null && {
        tone: 'blue',
        name: 'tuner',
        title: 'Improve Your Resume',
        text: 'Boost your job search chances with our resume improvement tips.',
        completed: isTunerCompleted,
        visual: <ResumeTunerVisual resume={tunerResume} report={tunerReport} />,
        action: () => {
          trackInternalEvent('click_improve_resume')
          navigate(`/resumes/${tunerResume.id}/edit`)
        },
      },
      config?.features.jobSearch && {
        tone: 'indigo',
        name: 'job_search',
        title: 'Explore & Track Jobs',
        text: 'Explore best matched jobs and add at least one to the job tracker.',
        action: () => navigate('/job-search'),
        visual: <JobFinderVisual userPhotoUrl={userPhotoUrl} />,
      },
      {
        tone: 'blue',
        name: 'cover_letter',
        title: 'Create a Cover Letter',
        text: 'Use templates and custom tools to speak directly to the employer.',
        action: () => dispatch(actions.createDocument({ type: DocumentTypes.coverLetter })),
        completed: hasLetter,
        visual: hasLetter ? (
          <DocumentPreviewVisual document={letter} />
        ) : (
          <DocumentCreateVisual type={DocumentTypes.coverLetter} />
        ),
      },
      {
        tone: 'green',
        name: 'interview_prep',
        title: 'Ace Job Interviews',
        text: 'Prepare for at least one interview and get detailed AI feedback.',
        action: () => navigate(interviewUrl),
        visual: <InterviewPrepVisual userPhotoUrl={userPhotoUrl} />,
      },
      config?.features.salaryAnalyzer && {
        tone: 'indigo',
        name: 'salary_analyzer',
        title: 'Know Your Worth',
        text: 'Compare your salary to compensation reported by others.',
        action: () => navigate('/offer-analyzer'),
        visual: <OfferAnalyzerVisual userPhotoUrl={userPhotoUrl} />,
      },
      config?.features.careerPath && {
        tone: 'beige',
        name: 'career_path',
        title: hasCareerTargets ? completedTitle : defaultTitle,
        text: hasCareerTargets ? completedText : defaultText,
        action: () => navigate('/career-path'),
        completed: hasCareerTargets,
        visual: hasCareerTargets ? (
          <CareerRolesVisual careerCards={careerCards} userPhotoUrl={userPhotoUrl} />
        ) : (
          <CareerMatrixVisual userPhotoUrl={userPhotoUrl} />
        ),
      },
      config?.features.contentCard && {
        tone: 'neutral',
        name: 'professional_resources',
        title: '',
        text: '',
        action: () => '',
        visual: <ResourceVisual />,
      },
    ]

    // Don't show tasks we're not ready to suggest yet
    const availableTasks = tasks.filter((task): task is CareerTask => !!task)

    // Place completed tasks first
    return sortBy(availableTasks, task => !task.completed)
  }, [
    careerCards,
    resume,
    letter,
    tunerResume,
    tunerReport,
    userPhotoUrl,
    config,
    dispatch,
    navigate,
  ])

  return { isLoaded, careerTasks }
}
