import styled from 'styled-components'
import Button, { ButtonSize } from 'builder/components/Button'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'

export const Container = styled.div`
  position: relative;
  display: flex;
  gap: 12px;
  margin: 0 32px;
  height: 148px;
`

export const Box = styled.div`
  flex: 1;
  height: 148px;
  border-radius: 16px;
  background: linear-gradient(180deg, #f7f9fc 0%, rgba(247, 249, 252, 0) 100%);
`

export const TextContainer = styled.div`
  position: absolute;
  height: 148px;
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`

export const Header = styled.p`
  width: 304px;
  text-align: center;

  ${Typography.Caption}
  ${FontWeights.Regular}
  color: ${Colors.Neutral90};
`

export const Action = styled(Button).attrs({ size: ButtonSize.small })``

export const CreditsContainer = styled.div`
  display: flex;
  gap: 12px;
  margin: 0 32px;
  height: 232px;
  padding-top: 36px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Credits = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 4px 12px;
  border-radius: 24px;
  background: ${Colors.Blue10};
`
export const CreditText = styled.p`
  ${Typography.Caption}
  ${FontWeights.Regular}
  color: ${Colors.Blue80};
`
export const CreditCount = styled.p`
  ${Typography.Caption}
  ${FontWeights.Regular}
  color: ${Colors.Blue60};
`
