import { Container, Title, Link, ExploreIcon } from './styles'

type Props = {
  title: string
  Tabs: string[]
  activeTab: string
  onExplore: () => void
  isSalaryLoaded: boolean
}

const Header = ({ Tabs, title, activeTab, onExplore, isSalaryLoaded }: Props) => {
  const isLinkVisible = isSalaryLoaded && activeTab === Tabs[0]

  return (
    <Container>
      <Title>{title}</Title>
      {isLinkVisible && (
        <Link onClick={onExplore}>
          Explore More <ExploreIcon />
        </Link>
      )}
    </Container>
  )
}

export default Header
