/* eslint-disable max-len */
import { useMemo } from 'react'
import { UserDocumentType } from '@rio/types'
import { trackInternalEvent } from '@rio/tracking'
import {
  selectors,
  useTimeLeftFormatter,
  ResumeCritique,
  ResumeReviewScenarios as SCENARIOS,
  ResumeCritiqueStatuses as STATUSES,
  checkIfCritiqueExpired,
} from 'builder/modules/resumeReview'
import { DocumentTypes } from 'builder/modules/constants'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { Icon20, Icon24 } from 'builder/components/Icon'
import { IFRAME_PARAMS } from 'builder/modules/resumeCritiqueReview/constants'
import {
  DocumentReviewStatusTooltip,
  DocumentReviewStatusLink,
  DocumentReviewStatusVisual,
  DocumentReviewStatusText,
  DocumentReviewStatusArrow,
  DocumentReviewStatusIconWrapper,
  DocumentReviewStatusIcon,
  DocumentReviewStatusPhoto,
} from './styles'

/** Just renders the statusbar with the given data. Nothing else. */
export const DocumentReviewStatusUi = ({ critique }: { critique: ResumeCritique }) => {
  const { isPhone } = useMediaQueries()
  const { status, createdAt } = critique

  const timeLeft = useTimeLeftFormatter(critique)

  const text = useMemo(() => {
    if (status === STATUSES.failed) return isPhone ? 'Failed' : 'Failed to review'
    if (status === STATUSES.finished) return isPhone ? 'Review' : 'Read Review'
    return 'In Review'
  }, [isPhone, status])

  // TODO: i18n
  const tooltip = useMemo(() => {
    if (status === STATUSES.failed) {
      return 'Our team is working to solve the problem. It may take up to several days. Apologies and thank you for your patience!'
    }
    if (status === STATUSES.finished) {
      return `Personalized tips by resume expert ${critique.reviewer.name} on how you can improve your resume`
    }
    return `Our resume expert is writing personalized tips about how you can improve your resume. Check back in ${timeLeft}.`
  }, [status, critique.reviewer.name, timeLeft])

  const onClickStatusTooltip = () => {
    if (status === STATUSES.failed) {
      trackInternalEvent('click_resume_critique_error_icon', {
        section: 'resume_preview',
      })
    }

    if (status === STATUSES.finished) {
      trackInternalEvent('click_read_resume_critique_button', {
        section: 'resume_preview',
        source: 'dashboard',
      })
    }
  }

  const isCritiqueExpired = useMemo(() => checkIfCritiqueExpired(createdAt), [createdAt])

  if (isCritiqueExpired) return <></>

  return (
    <DocumentReviewStatusTooltip
      onClick={onClickStatusTooltip}
      value={tooltip}
      multiline
      textAlign="center"
    >
      <DocumentReviewStatusLink
        to=""
        status={status}
        onClick={e => {
          e.preventDefault()
          if (critique.iframeUrl) {
            window.open(`${critique.iframeUrl}${IFRAME_PARAMS}`)
          }
        }}
      >
        {(status === STATUSES.finished || status === STATUSES.failed) && (
          <DocumentReviewStatusVisual>
            <DocumentReviewStatusIconWrapper status={status}>
              <DocumentReviewStatusIcon
                as={status === STATUSES.failed ? Icon24.Close : Icon20.Star}
              />
            </DocumentReviewStatusIconWrapper>

            {status === STATUSES.finished && !isPhone && (
              <DocumentReviewStatusPhoto src={critique.reviewer.photoUrl} />
            )}
          </DocumentReviewStatusVisual>
        )}

        <DocumentReviewStatusText>{text}</DocumentReviewStatusText>
        {status === STATUSES.finished && <DocumentReviewStatusArrow />}
      </DocumentReviewStatusLink>
    </DocumentReviewStatusTooltip>
  )
}

/** Connects to the store to get a critique */
export const DocumentReviewStatus = ({ id, type }: { id: number; type: UserDocumentType }) => {
  const scenario = useTypedSelector(selectors.scenario)
  const critique = useTypedSelector(state => selectors.critique(state, id))

  if (scenario !== SCENARIOS.embedded) return null

  // Do not render banner if there is no critique for the document
  if (!critique || type !== DocumentTypes.resume) return null

  return <DocumentReviewStatusUi critique={critique} />
}
