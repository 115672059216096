import { selectors } from 'builder/modules/careerPath2'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

import Header from './components/Header'
import { Loader } from './components/Loader'
import JobTitles from './components/JobTitles'

import { Container } from './styles'

const CareerPathJobProgression = (): JSX.Element => {
  const level1JobTitles = useTypedSelector(selectors.level1JobTitles)
  const isFailed = useTypedSelector(selectors.isJobProgressionFailed)
  const isLoaded = useTypedSelector(selectors.isJobProgressionLoaded)

  return (
    <Container>
      <Header />
      {!isLoaded && !level1JobTitles.length ? <Loader isFailed={isFailed} /> : <JobTitles />}
    </Container>
  )
}

export default CareerPathJobProgression
