import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'

import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { capitalize } from 'builder/utils/capitalize'
import LloydContent from 'builder/components/LloydContent'
import { actions, selectors } from 'builder/modules/lloyd'
import { selectors as uiSelectors } from 'builder/modules/ui'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { selectors as userSelectors } from 'builder/modules/user'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import UpcomingCareersModal from 'builder/components/UpcomingCareersModal'
import CareerPlanningFindCoach from 'builder/components/CareerPlanningFindCoach'

import Visual from './assets/img/visual.svg'
import { Container, FixedContainer, Spinner } from './styles'

export const LloydView = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const { isTablet } = useMediaQueries()
  const { features } = useFeaturesConfig()
  const user = useTypedSelector(userSelectors.userData)

  const pathName = window.location.pathname.split('/')[3]
  const trackingLabel = pathName.replaceAll('-', '_')

  const isLloydAuthenticated = useTypedSelector(selectors.isLloydAuthenticated)
  const showUpcomingCareersModal = useTypedSelector(uiSelectors.showUpcomingCareersModal)

  const planSlug = params.planSlug || 'first-90-days'
  const pageTitle = planSlug.split('-').join(' ')
  const iframeUrl = `${process.env.LLOYD_APP_HOST}/plans/${planSlug}`

  const jsonString = JSON.stringify({
    firstname: user?.firstName,
    lastname: user?.lastName,
    email: user?.email,
    type: 'member',
  })
  const base64Token = btoa(encodeURIComponent(jsonString))

  useEffectOnMount(() => {
    trackInternalEvent('enter_career_plan_page', { label: trackingLabel })
    dispatch(actions.autoLoginCIOUser(base64Token))
  })

  const getSubTitle = () => {
    switch (planSlug) {
      case 'first-90-days':
        return 'Ensure you start your new job with confidence and stellar performance.'
      case 'path-to-promotion':
        return 'Confidently prepare and plan for your next promotion.'
      case 'custom-plan':
        return 'A personalized plan from your coach to guide you through your next steps and provide support in reaching your goals.'
      default:
        return ''
    }
  }

  if (isLloydAuthenticated) {
    return (
      <>
        {showUpcomingCareersModal && (
          <UpcomingCareersModal
            visual={Visual}
            trackingLabel={trackingLabel}
            eventName="click_career_coaching_banner"
            title="Professionals at the service of your career"
            text="With personalized guidance and expert advice, we empower you to navigate the
                complexities of the job market and make informed decisions about your career path."
          />
        )}
        <Container>
          <LloydContent
            src={iframeUrl}
            planSlug={planSlug}
            subTitle={getSubTitle()}
            title={capitalize(pageTitle)}
            trackingLabel={trackingLabel}
          />
          {!isTablet && (
            <FixedContainer>
              {features.careerCoaching && (
                <CareerPlanningFindCoach feature="lloyd" {...{ trackingLabel }} />
              )}
            </FixedContainer>
          )}
        </Container>
      </>
    )
  }

  return <Spinner />
}
