import { numberToDelimited } from 'builder/utils/numberToDelimited'
import { SkeletonValue } from '../Skeletons'
import * as CommonStyles from '../../styles'
import * as Styles from './styles'

type Props = {
  jobPostings?: number | null
  isSkeleton: boolean
}

export const JobPostingsInfo = ({ jobPostings, isSkeleton = false }: Props) => {
  if (!jobPostings && !isSkeleton) return null

  return (
    <Styles.Container>
      <CommonStyles.Title>Total Job Openings</CommonStyles.Title>
      {isSkeleton ? (
        <SkeletonValue />
      ) : (
        typeof jobPostings === 'number' && (
          <CommonStyles.Value>{numberToDelimited(jobPostings)}</CommonStyles.Value>
        )
      )}
    </Styles.Container>
  )
}
