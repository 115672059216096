import { Fragment } from 'react'
import * as Styles from './styles'

export const Failed = () => {
  return (
    <Fragment>
      <Styles.ShadowContainer />
      <Styles.ContentWrapper>
        <Styles.Title>Something went wrong</Styles.Title>
        <Styles.StaticText>
          Please, try changing your recent job title and desired location and update your path
        </Styles.StaticText>
      </Styles.ContentWrapper>
    </Fragment>
  )
}
