import { createAction, createReducer } from '@reduxjs/toolkit'
import { Store } from 'builder/modules/store'
import { FetchStatuses } from 'builder/modules/constants'
import {
  Filter,
  Content,
  ActionItem,
  HeadersType,
  FetchActionItems,
  CareerPlanningStore,
  SaveLastSectionParams,
} from './types'
import { filterOptions } from './constants'

// ---
// Action creators
// ---
export const actions = {
  setFetchActionItemsStatus: createAction<FetchStatuses>(
    'builder/CAREER_PLANNING_SET_FETCH_ACTION_ITEMS_STATUS',
  ),
  fetchActionItems: createAction<FetchActionItems>('builder/CAREER_PLANNING_FETCH_ACTION_ITEMS'),
  setActionItems: createAction<ActionItem[]>('builder/CAREER_PLANNING_SET_ACTION_ITEMS'),
  saveCompletedActionItem: createAction<{ id: number; isCompleted: boolean }>(
    'builder/CAREER_PLANNING_SAVE_COMPLETED_ACTION_ITEM',
  ),
  setNavigationData: createAction<HeadersType[]>('builder/CAREER_PLANNING_SET_NAVIGATION_DATA'),
  fetchHeadings: createAction('builder/CAREER_PLANNING_FETCH_HEADINGS'),
  setStep: createAction<number>('builder/CAREER_PLANNING_SET_STEP'),
  setSecondLevelId: createAction<number>('builder/CAREER_PLANNING_SET_SECOND_LEVEL_ID'),
  fetchProgress: createAction('builder/CAREER_PLANNING_FETCH_PROGRESS'),
  setProgress: createAction<number>('builder/CAREER_PLANNING_SET_PROGRESS'),
  setShowAnimation: createAction<boolean>('builder/CAREER_PLANNING_SET_SHOW_ANIMATION'),
  setShowLimitModal: createAction<boolean>('builder/CAREER_PLANNING_SET_SHOW_LIMIT_MODAL'),
  saveUserLastSection: createAction<SaveLastSectionParams>(
    'builder/CAREER_PLANNING_SAVE_USER_LAST_SECTION',
  ),
  fetchUserLastSection: createAction('builder/CAREER_PLANNING_FETCH_USER_LAST_SECTION'),
  updateFilterOptions: createAction<Filter[]>('builder/CAREER_PLANNING_UPDATE_FILTER_OPTIONS'),
  setAllContents: createAction<Content[]>('builder/CAREER_PLANNING_SET_ALL_CONTENTS'),
  setContentOffset: createAction<number>('builder/CAREER_PLANNING_SET_CONTENT_OFFSET'),
  setCurrentPage: createAction<number>('builder/CAREER_PLANNING_SET_CURRENT_PAGE'),
  startAnimation: createAction<boolean>('builder/CAREER_PLANNING_START_ANIMATION'),
  setSelectedId: createAction<number>('builder/CAREER_PLANNING_SET_SELECTED_ID'),
  updatePagesReceived: createAction<number>('builder/CAREER_PLANNING_UPDATE_PAGES_RECEIVED'),
  setIsObserving: createAction<boolean>('builder/CAREER_PLANNING_UPDATE_IS_OBSERVING'),
  setShowFilters: createAction<boolean>('builder/CAREER_PLANNING_SET_SHOW_FILTERS'),
  setIsScrollToView: createAction<boolean>('builder/CAREER_PLANNING_SET_IS_SCROLL_TO_VIEW'),
  setIsTopBarOpen: createAction<boolean>('builder/CAREER_PLANNING_SET_IS_TOP_BAR_OPEN'),
  setOpenContents: createAction<Content[]>('builder/CAREER_PLANNING_SET_OPEN_CONTENTS'),
  setCompletedContents: createAction<Content[]>('builder/CAREER_PLANNING_SET_COMPLETED_CONTENTS'),
  setFirstContentOffset: createAction<number>('builder/CAREER_PLANNING_SET_FIRST_CONTENT_OFFSET'),
  setTriggerEvent: createAction<boolean>('builder/CAREER_PLANNING_SET_TRIGGER_EVENT'),
}

// ---
// Selectors
// ---
export const selectors = {
  fetchActionItemsStatus: (state: Store) => state.careerPlanning.fetchActionItemsStatus,
  actionItems: (state: Store) => state.careerPlanning.actionItems,
  isContentLoaded: (state: Store) =>
    state.careerPlanning.fetchActionItemsStatus === FetchStatuses.loaded,
  isContentLoading: (state: Store) =>
    state.careerPlanning.fetchActionItemsStatus === FetchStatuses.loading,
  isContentFailed: (state: Store) =>
    state.careerPlanning.fetchActionItemsStatus === FetchStatuses.failed,
  headers: (state: Store) => state.careerPlanning.headers,
  step: (state: Store) => state.careerPlanning.step,
  secondLevelId: (state: Store) => state.careerPlanning.secondLevelId,
  progress: (state: Store) => state.careerPlanning.progress,
  showAnimation: (state: Store) => state.careerPlanning.showAnimation,
  showLimitModal: (state: Store) => state.careerPlanning.showLimitModal,
  filterOptions: (state: Store) => state.careerPlanning.filters,
  allContents: (state: Store) => state.careerPlanning.allContents,
  currentPage: (state: Store) => state.careerPlanning.currentPage,
  startAnimation: (state: Store) => state.careerPlanning.startAnimation,
  selectedId: (state: Store) => state.careerPlanning.selectedId,
  contentOffset: (state: Store) => state.careerPlanning.contentOffset,
  pagesReceived: (state: Store) => state.careerPlanning.pagesReceived,
  isObserving: (state: Store) => state.careerPlanning.isObserving,
  showFilters: (state: Store) => state.careerPlanning.showFilters,
  isScrollToView: (state: Store) => state.careerPlanning.isScrollToView,
  isTopBarOpen: (state: Store) => state.careerPlanning.isTopBarOpen,
  openContents: (state: Store) => state.careerPlanning.openContents,
  completedContents: (state: Store) => state.careerPlanning.completedContents,
  firstContentOffset: (state: Store) => state.careerPlanning.firstContentOffset,
  triggerEvent: (state: Store) => state.careerPlanning.triggerEvent,
}

// ---
// Reducer
// ---
const initialState: CareerPlanningStore = {
  fetchActionItemsStatus: FetchStatuses.notAsked,
  actionItems: [],
  headers: [],
  step: 1,
  secondLevelId: 0,
  progress: 0,
  showAnimation: false,
  showLimitModal: false,
  filters: filterOptions,
  allContents: [],
  startAnimation: false,
  selectedId: null,
  currentPage: 0,
  contentOffset: 0,
  pagesReceived: [],
  isObserving: false,
  showFilters: false,
  isScrollToView: false,
  isTopBarOpen: false,
  openContents: [],
  completedContents: [],
  firstContentOffset: null,
  triggerEvent: false,
}

export const reducer = createReducer(initialState, reducer => {
  reducer.addCase(actions.setFetchActionItemsStatus, (draft, action) => {
    draft.fetchActionItemsStatus = action.payload
  })
  reducer.addCase(actions.setActionItems, (draft, action) => {
    draft.actionItems = action.payload
  })
  reducer.addCase(actions.setNavigationData, (draft, action) => {
    draft.headers = action.payload
  })
  reducer.addCase(actions.setStep, (draft, action) => {
    draft.step = action.payload
  })
  reducer.addCase(actions.setSecondLevelId, (draft, action) => {
    draft.secondLevelId = action.payload
  })
  reducer.addCase(actions.setProgress, (draft, action) => {
    draft.progress = action.payload
  })
  reducer.addCase(actions.setShowAnimation, (draft, action) => {
    draft.showAnimation = action.payload
  })
  reducer.addCase(actions.setShowLimitModal, (draft, action) => {
    draft.showLimitModal = action.payload
  })
  reducer.addCase(actions.updateFilterOptions, (draft, action) => {
    draft.filters = action.payload
  })
  reducer.addCase(actions.setAllContents, (draft, action) => {
    draft.allContents = action.payload
  })
  reducer.addCase(actions.setCurrentPage, (draft, action) => {
    draft.currentPage = action.payload
  })
  reducer.addCase(actions.startAnimation, (draft, action) => {
    draft.startAnimation = action.payload
  })
  reducer.addCase(actions.setSelectedId, (draft, action) => {
    draft.selectedId = action.payload
  })
  reducer.addCase(actions.setContentOffset, (draft, action) => {
    draft.contentOffset = action.payload
  })
  reducer.addCase(actions.updatePagesReceived, (draft, action) => {
    const pages = [...draft.pagesReceived, action.payload]
    draft.pagesReceived = pages
  })
  reducer.addCase(actions.setIsObserving, (draft, action) => {
    draft.isObserving = action.payload
  })
  reducer.addCase(actions.setShowFilters, (draft, action) => {
    draft.showFilters = action.payload
  })
  reducer.addCase(actions.setIsScrollToView, (draft, action) => {
    draft.isScrollToView = action.payload
  })
  reducer.addCase(actions.setIsTopBarOpen, (draft, action) => {
    draft.isTopBarOpen = action.payload
  })
  reducer.addCase(actions.setOpenContents, (draft, action) => {
    draft.openContents = action.payload
  })
  reducer.addCase(actions.setCompletedContents, (draft, action) => {
    draft.completedContents = action.payload
  })
  reducer.addCase(actions.setFirstContentOffset, (draft, action) => {
    draft.firstContentOffset = action.payload
  })
  reducer.addCase(actions.setTriggerEvent, (draft, action) => {
    draft.triggerEvent = action.payload
  })
})
