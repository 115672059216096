import { memo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { i18n as I18n } from 'builder/utils/i18n'
import { actions } from 'builder/modules/resumeEditor'
import {
  AIResumeFlows,
  AIResumeLinkedInSteps,
  AIResumeDraftEventLabels,
  AIResumeDraftEvents,
} from 'builder/modules/constants'
import linkedInError from '../../../../../../app/assets/images/builder/ai-resume/linkedin-error.png'
import {
  LinkedInErrorHeading,
  LinkedInErrorSubheading,
  Actions,
  TryAnotherLink,
  Revert,
  GoToQuestionnaireButton,
  LinkedInErrorImg,
} from './styles'

const LinkedInError = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    trackInternalEvent(AIResumeDraftEvents.seeLinkedInProfileErrorScreen, {
      label: AIResumeDraftEventLabels.aiResume,
    })
  }, [])

  const onTryAnotherLink = () => {
    trackInternalEvent(AIResumeDraftEvents.clickAnotherLink, {
      label: AIResumeDraftEventLabels.linkedInProfileErrorScreen,
    })
    dispatch(actions.setAIResumeLinkedInStep(AIResumeLinkedInSteps.linkedInPrefill))
  }

  const onGoToQuestionnaire = () => {
    trackInternalEvent(AIResumeDraftEvents.clickQuestionnaire, {
      label: AIResumeDraftEventLabels.linkedInProfileErrorScreen,
    })
    dispatch(actions.setAIResumeFlow(AIResumeFlows.Questionnaire))
  }

  return (
    <>
      <LinkedInErrorImg src={linkedInError} alt="Linkedin Profile fetch error" />
      <LinkedInErrorHeading>
        {I18n.t('builder.ai_resume_draft.linkedin.empty_profile.title')}
      </LinkedInErrorHeading>
      <LinkedInErrorSubheading>
        {I18n.t('builder.ai_resume_draft.linkedin.empty_profile.description')}
      </LinkedInErrorSubheading>
      <Actions>
        <TryAnotherLink onClick={onTryAnotherLink}>
          <Revert />
          {I18n.t('builder.ai_resume_draft.use_another_link')}
        </TryAnotherLink>
        <GoToQuestionnaireButton onClick={onGoToQuestionnaire}>
          {I18n.t('builder.ai_resume_draft.linkedin.empty_profile.go_to_questionaire')}
        </GoToQuestionnaireButton>
      </Actions>
    </>
  )
}

export default memo(LinkedInError)
