import { useI18n } from 'builder/hooks/useI18n'
import { useUser } from 'builder/hooks/useUser'
import { selectors } from 'builder/modules/panel'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

import {
  CHANGE_CAREER,
  EXCEL_AT_JOB,
  FIND_NEW_JOB,
  INDEXES_TABS,
  NavigationTab,
} from 'builder/components/CIO-Dasboard-2.0/ActionBanner/ActionBanner'

import { Box, CompleteIcon, Container, Header, Name, Progress, Steps, SubHeader } from './styles'

const TRANSLATION = 'builder.dashboard_v2.tab_switcher'

interface IProps {
  totalSteps: number
  completedSteps: number
  showSuccessState: boolean
  showProgress: boolean
}

export const StepsProgress = ({
  showSuccessState,
  totalSteps,
  completedSteps,
  showProgress,
}: IProps) => {
  const user = useUser()
  const { i18n } = useI18n()
  const activeTabIndex = useTypedSelector(selectors.activeTabId)
  const isCompleted = totalSteps === completedSteps

  const selectedTab = INDEXES_TABS[activeTabIndex]
  const TABS_DESCRIPTIONS: Record<NavigationTab, string> = {
    [FIND_NEW_JOB]: i18n.t(`${TRANSLATION}.find_new_job`),
    [EXCEL_AT_JOB]: i18n.t(`${TRANSLATION}.excel_at_job`),
    [CHANGE_CAREER]: i18n.t(`${TRANSLATION}.change_career`),
  }

  return (
    <Container isOnboarding={!user?.uiStates?.dashv2TourCompleted}>
      <Box>
        <Header>
          {i18n.t(`${TRANSLATION}.hi`)}, <Name>{user?.firstName}!</Name>
        </Header>
        <SubHeader>
          {isCompleted && !showSuccessState && activeTabIndex === 0
            ? i18n.t(`${TRANSLATION}.find_new_job_completed`)
            : TABS_DESCRIPTIONS[selectedTab]}
        </SubHeader>
      </Box>
      <Box>
        {showProgress && (!isCompleted || showSuccessState) && (
          <Progress success={showSuccessState}>
            {showSuccessState && <CompleteIcon />}
            <Steps>
              {completedSteps} {i18n.t('builder.dashboard_v2.steps.progress.of')} {totalSteps}
            </Steps>
            {i18n.t('builder.dashboard_v2.steps.progress.completed')}
          </Progress>
        )}
      </Box>
    </Container>
  )
}
