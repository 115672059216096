import { apiClient } from 'builder/modules/apiClient'
import { APP_BASE_PATH } from 'builder/modules/constants'
import { LocalStorageSignUpDataKeys } from 'builder/modules/signUp'
import { i18n } from 'builder/utils/i18n'
import { ValidateErrorCodes, ValidateOptions, ValidateResponse } from '../types'

/**
 * Check given email address using a backend service
 */
export const validateEmail = async (
  value: string,
  options: ValidateOptions = {},
  disabledI18n?: boolean,
) => {
  const { data } = await apiClient.post<ValidateResponse>(`/sign-up/validate`, {
    email: value,
  })

  if (
    data?.activatedBy &&
    (data?.activatedBy.includes('resume.io') || data?.activatedBy.includes('resume.test'))
  ) {
    localStorage.setItem(LocalStorageSignUpDataKeys.SHOW_RIO_CIO_INFO_MODAL_FLAG, 'true')
  }

  // Email is not allowed to be registered in our database
  if (!data.success && data.errorCode === ValidateErrorCodes.invalid) {
    return disabledI18n ? 'Invalid email' : i18n.t('builder.sign_up.invalid_email')
  }

  // We already have a user with this email
  if (options.allowAvailableOnly && !data.success && data.errorCode === ValidateErrorCodes.taken) {
    const link = `<a href="${APP_BASE_PATH}/auth/sign-in">${
      disabledI18n ? 'Log In' : i18n.t('builder.sign_up.log_in')
    }</a>`
    return disabledI18n
      ? `This email is already registered. ${link} or try another email`
      : i18n.t('builder.sign_up.email_is_taken', { logInLink: link })
  }

  return null
}
