import styled from 'styled-components'
import { animated } from '@react-spring/web'
import Media from 'builder/styles/media'
import Sizes from 'builder/styles/sizes'
import Colors from 'builder/styles/colors'
import DefaultButton from 'builder/components/Button'
import Typography, { FontWeights } from 'builder/styles/typography'
import { CentralColumn } from '../../styles'

export const Container = styled(CentralColumn)`
  display: flex;
  align-items: center;
  border-radius: ${Sizes['2XS']};
  gap: ${Sizes.M};
  padding: ${Sizes.M};
  justify-content: space-between;
  background-color: ${Colors.Neutral5};
  position: relative;
  ${FontWeights.Medium};
  ${Typography.Body};

  ${Media.Phone`
    ${Typography.Caption};
    flex-flow: column;
    align-items: center;
  `}
`

export const Text = styled.div`
  flex: 1 1 50%;
`

export const ButtonsContainer = styled.div`
  flex: 1 1 50%;
  display: flex;
  align-items: center;
  gap: ${Sizes.M};

  ${Media.Phone`
    align-self: stretch;
  `}
`

export const Button = styled(DefaultButton)`
  background-color: ${Colors.White};
  flex: 1 1 50%;
`

export const Greet = styled(animated.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${Colors.Neutral5};
  border-radius: ${Sizes['2XS']};
  padding: ${Sizes.M};
`
