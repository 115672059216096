import { snakeCase } from 'lodash'
import { useEffect, useState } from 'react'
import { selectors } from 'builder/modules/careerPath2'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

export const useRecommendedCourses = () => {
  const [width, setWidth] = useState(window.innerWidth)
  const [isSwiperEnabled, setIsSwiperEnabled] = useState(false)
  const { isDesktop, isLaptop, isTablet, isPhone } = useMediaQueries()

  const courses = useTypedSelector(selectors.courses)
  const occupation = useTypedSelector(selectors.occupation)
  const isCoursesFailed = useTypedSelector(selectors.isCoursesFailed)
  const isCoursesLoading = useTypedSelector(selectors.isCoursesLoading)
  const isExploreCareers = useTypedSelector(selectors.isExploreCareers)
  const exploreCareersOccupation = useTypedSelector(selectors.exploreCareersOccupation)

  const jobTitle = isExploreCareers ? exploreCareersOccupation : occupation
  const eventLabel = snakeCase(isExploreCareers ? 'explore careers' : 'career path')

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    // Event listener to track window resize
    window.addEventListener('resize', handleWindowSizeChange)

    // Cleanup the event listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  useEffect(() => {
    let isEnabled = false

    if ((isLaptop || isDesktop) && courses.length > 3) {
      isEnabled = true
    } else if (!isPhone && isTablet && courses.length > 2) {
      isEnabled = true
    } else if (isPhone && courses.length > 3) {
      isEnabled = true
    } else {
      isEnabled = false
    }

    setIsSwiperEnabled(isEnabled)
  }, [courses, isDesktop, isLaptop, isPhone, isTablet, width])

  return {
    courses,
    jobTitle,
    eventLabel,
    isCoursesFailed,
    isCoursesLoading,
    isSwiperEnabled,
  }
}
