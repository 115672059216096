import { Container, Bar, Handler, Footer } from './styles'
import Label from './components/Label'

interface Props {
  avgSalary: string
  minSalary: string
  maxSalary: string
}

const SalaryGraph = ({ avgSalary, minSalary, maxSalary }: Props) => {
  return (
    <Container>
      <Label text="Average" value={avgSalary} />
      <Bar>
        <Handler />
      </Bar>
      <Footer>
        <Label text="Low" value={minSalary} />
        <Label text="High" value={maxSalary} />
      </Footer>
    </Container>
  )
}

export default SalaryGraph
