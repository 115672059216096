import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'

export const Container = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  width: 100%;

  ${Media.Laptop`
    padding: 0 20px;
  `}
`

export const Banner = styled.div`
  max-width: 1120px;
  width: 100%;
  padding: 42px 117px 42px 64px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 62px;
  color: ${Colors.Green90};
  background-color: ${Colors.Green10};

  ${Media.Tablet`
    padding: 56px 32px;
    gap: 32px;
  `};

  ${Media.Phone`
    padding: 48px 24px;
    justify-content: center;
    flex-direction: column;
    gap: 32px;
  `};
`

export const CoachImg = styled.img`
  ${Media.Tablet`
    width: 100%;
  `};

  ${Media.Phone`
    width: 217px;
  `};
`
