import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

export const ModalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 92px 40px;
  width: 100%;
  max-width: 544px;
  background-color: ${Colors.White};
  border-radius: 6px;

  ${Media.Phone`
    max-width: none;
    height: 100%;
    padding: 40px 20px;
    border-radius: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  `};
`

export const Close = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const DesktopClose = styled(Close)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 16px;
  color: ${Colors.Neutral40};
  transition: color 0.15s;

  &:hover {
    color: ${Colors.Blue50};
  }
`

export const MobileClose = styled(Close)`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: ${Colors.Neutral30};
  color: ${Colors.White};
`
