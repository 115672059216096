import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { selectors } from 'builder/modules/offerAnalyzer'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

const Gauge = ({ isLoading }) => {
  const [count, setCount] = useState(0)
  const [isRunning, setIsRunning] = useState(isLoading)
  const requestRef = useRef()
  const previousTimeRef = useRef(0)
  const incrementRef = useRef(true)
  const percentageRef = useRef(0)
  const lastRunRef = useRef(0)

  let iCurrentSpeed = 0
  let iTargetSpeed = 0
  let bDecrement = null
  let job = null

  const analyzedOfferDetails = useTypedSelector(selectors.analyzedOfferDetails)

  useEffect(() => {
    const animate = time => {
      const maxFPS = 40
      const timeStamp = 1000 / maxFPS

      if (time < lastRunRef.current + timeStamp) {
        requestAnimationFrame(animate)
        return
      }
      lastRunRef.current = time

      setCount(prevCount => {
        if (incrementRef.current) {
          const sixtiethPart = percentageRef.current / 60
          const threeHundredthPart = percentageRef.current / 300
          if (percentageRef.current > 9999) return prevCount + sixtiethPart
          else if (percentageRef.current > 499) return prevCount + threeHundredthPart
          return prevCount + 1
        } else {
          return prevCount - 1
        }
      })

      if (requestRef.current !== null) {
        previousTimeRef.current = time
        requestRef.current = requestAnimationFrame(animate)
      }
    }

    requestRef.current = requestAnimationFrame(animate)
    return () => cancelAnimationFrame(requestRef.current)
  }, [])

  useEffect(() => {
    const currentCount = Math.round(count)
    const percentage = formatPercentage(analyzedOfferDetails?.speedometerPercentageNeedle)

    drawWithInputValue(currentCount)

    if (isLoading) {
      if (count >= 55) incrementRef.current = false
      if (count <= 45) incrementRef.current = true
    } else {
      percentageRef.current = percentage
      if (percentage > currentCount) {
        incrementRef.current = true
      }
      if (percentage < currentCount) {
        incrementRef.current = false
      }
      if (percentage === currentCount) {
        requestRef.current = null
        setIsRunning(false)
      }
    }
  }, [count, isLoading, analyzedOfferDetails, drawWithInputValue])

  function degToRad(angle) {
    // Degrees to radians
    return (angle * Math.PI) / 180
  }

  function drawLine(options, line) {
    // Draw a line using the line object passed in
    options.ctx.beginPath()
    // Set attributes of open
    options.ctx.globalAlpha = line.alpha
    options.ctx.lineWidth = line.lineWidth
    options.ctx.fillStyle = line.fillStyle
    options.ctx.strokeStyle = line.fillStyle
    options.ctx.moveTo(line.from.X, line.from.Y)
    // Plot the line
    options.ctx.lineTo(line.to.X, line.to.Y)
    options.ctx.stroke()
  }

  function createLine(fromX, fromY, toX, toY, fillStyle, lineWidth, alpha) {
    // Create a line object using Javascript object notation
    return {
      from: {
        X: fromX,
        Y: fromY,
      },
      to: {
        X: toX,
        Y: toY,
      },
      fillStyle: fillStyle,
      lineWidth: lineWidth,
      alpha: alpha,
    }
  }

  function applyDefaultContextSettings(options) {
    /* Helper function to revert to gauges
     * default settings
     */
    options.ctx.lineWidth = 2
    options.ctx.globalAlpha = 0.5
    options.ctx.strokeStyle = 'rgb(255, 255, 255)'
    options.ctx.fillStyle = 'rgb(255,255,255)'
  }

  function drawTicks(options) {
    let tickvalue = options.levelRadius - 7
    let iTick = 0
    let gaugeOptions = options.gaugeOptions
    let iTickRad = 0
    let onArchX
    let onArchY
    let innerTickX
    let innerTickY
    let fromX
    let fromY
    let line
    let toX
    let toY

    applyDefaultContextSettings(options)
    const gRadius = gaugeOptions.radius - 3
    const cenX = options.center.X
    const cenY = options.center.Y

    // Tick every 20 degrees (small ticks)
    for (iTick = -45; iTick <= 225; iTick += 13.5) {
      iTickRad = degToRad(iTick)
      /* Calculate the X and Y of both ends of the
       * line I need to draw at angle represented at Tick.
       * The aim is to draw the a line starting on the
       * coloured arc and continueing towards the outer edge
       * in the direction from the center of the gauge.
       */
      onArchX = gRadius - Math.cos(iTickRad) * tickvalue
      onArchY = gRadius - Math.sin(iTickRad) * tickvalue

      innerTickX = gRadius - Math.cos(iTickRad) * gRadius
      innerTickY = gRadius - Math.sin(iTickRad) * gRadius

      fromX = cenX - gRadius + onArchX
      fromY = cenY - gRadius + onArchY

      toX = cenX - gRadius + innerTickX
      toY = cenY - gRadius + innerTickY

      // Create a line expressed in JSON
      let color = ''
      if (iTick < 60) {
        color = '#FB4458'
      } else if (iTick < 120) {
        color = '#5660E8'
      } else if (iTick < 200) {
        color = '#1A91F0'
      }
      line = createLine(fromX, fromY, toX, toY, color, 1, 1)

      // Draw the line
      drawLine(options, line)
    }
  }

  function drawSpeedometerColourArc(options) {
    const cenX = options.center.X
    const cenY = options.center.Y
    const lvlRadius = options.levelRadius
    const ctx = options.ctx

    let quadrants = [
      {
        angleStart: Math.PI * -0.5,
        angleEnd: 0,
        x1: cenX,
        y1: cenY - lvlRadius,
        x2: cenX + lvlRadius,
        y2: cenY,
        colorStops: [
          { stop: 0, color: 'rgba(86, 96, 232, .2)' },
          { stop: 1, color: 'rgba(26, 145, 240, .2)' },
        ],
      },
      {
        angleStart: 0,
        angleEnd: Math.PI * 0.25,
        x1: cenX + lvlRadius,
        y1: cenY,
        x2: cenX,
        y2: cenY + lvlRadius,
        colorStops: [
          { stop: 0, color: 'rgba(26, 145, 240, .2)' },
          { stop: 1, color: 'rgba(26, 145, 240, .2)' },
        ],
      },
      {
        angleStart: Math.PI * 0.75,
        angleEnd: Math.PI,
        x1: cenX,
        y1: cenY + lvlRadius,
        x2: cenX - lvlRadius,
        y2: cenY,
        colorStops: [
          { stop: 0, color: 'rgba(251, 68, 88, .2)' },
          { stop: 1, color: 'rgba(251, 68, 88, .2)' },
        ],
      },
      {
        angleStart: Math.PI,
        angleEnd: Math.PI * 1.5,
        x1: cenX - lvlRadius,
        y1: cenY,
        x2: cenX,
        y2: cenY - lvlRadius,
        colorStops: [
          { stop: 0, color: 'rgba(251, 68, 88, .2)' },
          { stop: 1, color: 'rgba(86, 96, 232, .2)' },
        ],
      },
    ]

    for (let i = 0; i < quadrants.length; ++i) {
      let quad = quadrants[i]
      let grad = ctx.createLinearGradient(quad.x1, quad.y1, quad.x2, quad.y2)
      // Color stops.
      for (let j = 0; j < quad.colorStops.length; ++j) {
        let cs = quad.colorStops[j]
        grad.addColorStop(cs.stop, cs.color)
      }
      // Draw arc.
      ctx.beginPath()
      ctx.arc(cenX, cenY, lvlRadius, quad.angleStart, quad.angleEnd)
      ctx.strokeStyle = grad
      ctx.lineWidth = 24
      ctx.stroke()
    }
  }

  function convertSpeedToAngle(options) {
    /* Helper function to convert a speed to the
     * equivelant angle.
     */
    let iSpeed = options.speed / 10
    let iSpeedAsAngle = iSpeed * 13.5
    return iSpeedAsAngle * 2 - 45
  }

  function drawNeedle(options) {
    /* Draw the needle in a nice read colour at the
     * angle that represents the options.speed value.
     */
    const gaugeOptions = options.gaugeOptions
    const gRadius = gaugeOptions.radius - 10
    const gCenY = gaugeOptions.center.Y
    const cenX = options.center.X
    let tickvalue = options.levelRadius - 15

    let iSpeedAsAngle = convertSpeedToAngle(options)
    let iSpeedAsAngleRad = degToRad(iSpeedAsAngle)
    let innerTickX = gRadius - Math.cos(iSpeedAsAngleRad) * tickvalue
    let innerTickY = gRadius - Math.sin(iSpeedAsAngleRad) * tickvalue
    let fromX = cenX - gRadius + innerTickX
    let fromY = gCenY - gRadius + innerTickY
    let endNeedleX = gRadius - Math.cos(iSpeedAsAngleRad) * (gRadius + 15)
    let endNeedleY = gRadius - Math.sin(iSpeedAsAngleRad) * (gRadius + 15)
    let toX = cenX - gRadius + endNeedleX
    let toY = gCenY - gRadius + endNeedleY
    let line = createLine(fromX, fromY, toX, toY, 'rgba(251, 68, 88, 1)', 3, 0.8)
    drawLine(options, line)
  }

  const getRoundOffPercent = percent => (percent ? percent.replace('%', '') : '0')

  const formatPercentage = percent => {
    if (percent) {
      return Math.round(Number(percent.replace('%', '')))
    }
    return 0
  }

  function drawProgressValue(options) {
    const { ctx, gaugeOptions } = options
    let progressTxtVal

    if (isRunning) {
      progressTxtVal = Math.round(count)
    } else {
      progressTxtVal = getRoundOffPercent(analyzedOfferDetails.speedometerPercentage)
    }

    const progressTxtPrcnt = '%'
    const progressTxt = `${analyzedOfferDetails?.flagMsgBelowSpeedometer} average`
    ctx.fillStyle = '#1E2532'

    const gCenX = gaugeOptions.center.X
    const gCenY = gaugeOptions.center.Y
    const gRadius = gaugeOptions.radius

    ctx.font = '30px arial'
    ctx.textBaseline = 'middle'
    ctx.fontWeight = 'bold'
    ctx.textAlign = 'center'
    ctx.fillText(progressTxtVal, gCenX, gCenY - gRadius / 5)

    ctx.font = 'bold 15px arial'
    ctx.textBaseline = 'bottom'
    ctx.fontWeight = 'bold'
    ctx.fillText(
      progressTxtPrcnt,
      gCenX + ctx.measureText(progressTxtVal).width + 8,
      gCenY - gRadius / 5 + 10,
    )

    ctx.font = '15px arial'
    ctx.fontWeight = '400'
    ctx.fillStyle = '#828BA2'
    ctx.fillText(progressTxt, gCenX, gCenY + gRadius / 7)
  }

  function buildOptionsAsJSON(canvas, iSpeed) {
    /* Setting for the speedometer
     * Alter these to modify its look and feel
     */

    let centerX = 108
    let centerY = 108
    let radius = 103
    let outerRadius = 108

    // Create a speedometer object using Javascript object notation

    let scale = window.devicePixelRatio
    let ctx = canvas.getContext('2d')
    ctx.scale(scale, scale)

    return {
      ctx: ctx,
      speed: iSpeed,
      center: {
        X: centerX,
        Y: centerY,
      },
      levelRadius: radius - 10,
      gaugeOptions: {
        center: {
          X: centerX,
          Y: centerY,
        },
        radius: radius,
      },
      radius: outerRadius,
    }
  }

  function clearCanvas(options) {
    options.ctx.clearRect(0, 0, 216, 216)
    applyDefaultContextSettings(options)
  }

  function draw() {
    /* Main entry point for drawing the speedometer
     * If canvas is not support alert the user.
     */
    let canvas = document.getElementById('speedometer')

    let size = 216
    canvas.style.width = size + 'px'
    canvas.style.height = size + 'px'
    let scale = window.devicePixelRatio // Change to 1 on retina screens to see blurry canvas.
    canvas.width = size * scale
    canvas.height = size * scale

    let options = null

    // Canvas good?
    if (canvas !== null && canvas.getContext) {
      options = buildOptionsAsJSON(canvas, iCurrentSpeed)

      // Clear canvas
      clearCanvas(options)

      // Draw speeometer colour arc
      drawSpeedometerColourArc(options)

      // Draw tick marks
      drawTicks(options)

      // Draw the needle and base
      drawNeedle(options)

      drawProgressValue(options)
    } else {
      alert('Canvas not supported by your browser!')
    }

    if (iTargetSpeed === iCurrentSpeed) {
      clearTimeout(job)
      return
    } else if (iTargetSpeed < iCurrentSpeed) {
      bDecrement = true
    } else if (iTargetSpeed > iCurrentSpeed) {
      bDecrement = false
    }

    if (bDecrement) {
      if (iCurrentSpeed - 10 < iTargetSpeed) iCurrentSpeed = iCurrentSpeed - 1
      else iCurrentSpeed = iCurrentSpeed - 5
    } else {
      if (iCurrentSpeed + 10 > iTargetSpeed) iCurrentSpeed = iCurrentSpeed + 1
      else iCurrentSpeed = iCurrentSpeed + 5
    }
    setTimeout(draw(), 5)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function drawWithInputValue(txtSpeed) {
    if (txtSpeed) {
      iTargetSpeed = txtSpeed

      // Sanity checks
      if (isNaN(iTargetSpeed)) {
        iTargetSpeed = 0
      } else if (iTargetSpeed < 0) {
        iTargetSpeed = 0
      } else if (iTargetSpeed > 100) {
        iTargetSpeed = 100
      }
      setTimeout(draw(), 5)
    }
  }

  return (
    <div>
      <canvas id="speedometer" />
    </div>
  )
}

Gauge.propTypes = {
  isLoading: PropTypes.bool,
}

export default Gauge
