import { useEffect, useState } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { actions as userActions, selectors as userSelectors } from 'builder/modules/user'
import { EWidgetsName, IWidgetsInfo } from 'builder/modules/panel'
import { selectors as interviewSelector } from 'builder/modules/interview/interviewModule'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import FloatingCIOHelperButton from '../FloatingCIOHelperButton'
import * as Styles from './styles'
import { JobSearchLabelData } from './constants'

interface IProps {
  resumeEditor?: boolean
}

const JobSearchButton = ({ resumeEditor }: IProps) => {
  const navigate = useNavigate()
  const features = useFeaturesConfig()

  const isPremiumUser = useTypedSelector(userSelectors.premiumAccess)
  const isResumeDownloaded = useTypedSelector(userSelectors.isResumeDownloaded)

  const interviewUrl = useSelector(interviewSelector.getInterviewUrl)

  const [isHelperModalOpen, setIsHelperModalOpen] = useState<boolean>(false)
  const [isUserResumeDownloaded, setIsUserResumeDownloaded] = useState<boolean>(false)
  const dispatch = useDispatch()

  const handleClose = () => {
    setIsHelperModalOpen(false)
    dispatch(userActions.setIsResumeDownloaded(false))
  }

  useEffect(() => {
    let modalCentreFlag = localStorage.getItem('jobSearchHelperModalFlag')
    if (features?.features.jobHelper && isResumeDownloaded && !modalCentreFlag) {
      setIsHelperModalOpen(true)
      setIsUserResumeDownloaded(true)
      localStorage.setItem('jobSearchHelperModalFlag', '1')
      trackInternalEvent('helper_modal_view', { label: 'resume_builder' })
    } else {
      setIsUserResumeDownloaded(false)
    }
  }, [features, isResumeDownloaded])

  useEffect(() => {
    if (!isUserResumeDownloaded && isHelperModalOpen) {
      trackInternalEvent('helper_corner_view', { label: 'resume_builder' })
    }
  }, [isHelperModalOpen, isUserResumeDownloaded])

  const onStepActionClick = (stepName: string) => {
    switch (stepName) {
      case 'improve_resume':
        navigate('/career-plans/execute-a-job-search?cp_top_level=2&cp_second_level=3')
        trackInternalEvent('helper_cta_click', { label: 'resume_builder', target: 'resume_video' })
        break
      case 'apply_jobs':
        navigate('/job-search')
        trackInternalEvent('helper_cta_click', { label: 'resume_builder', target: 'job_search' })
        break
      case 'interview_prep':
        navigate(interviewUrl)
        trackInternalEvent('helper_cta_click', { label: 'resume_builder', target: 'interviews' })
        break
      default:
        return null
    }
  }

  const TrackingEvents = (option: IWidgetsInfo) => {
    if (option.name === 'improve_resume') {
      trackInternalEvent('helper_action_completed', {
        label: 'resume_builder',
        target: 'resume_video',
      })
    } else if (option.name === 'apply_jobs') {
      trackInternalEvent('helper_action_completed', {
        label: 'resume_builder',
        target: 'apply_jobs_video',
      })
    } else if (option.name === 'interview_prep') {
      trackInternalEvent('helper_action_completed', {
        label: 'resume_builder',
        target: 'interviews',
      })
    }
  }

  return (
    <Styles.FloatingButtonContainer>
      <FloatingCIOHelperButton
        setModalAsCentre={setIsUserResumeDownloaded}
        modalAsCentre={isUserResumeDownloaded}
        labelPayload={JobSearchLabelData}
        currentWidgetName={EWidgetsName.JOB_SEARCH}
        widgetFetchCondition={isPremiumUser}
        helperLabel="resume_builder"
        {...{
          resumeEditor,
          onStepActionClick,
          isHelperModalOpen,
          setIsHelperModalOpen,
          handleClose,
          TrackingEvents,
        }}
      />
    </Styles.FloatingButtonContainer>
  )
}

export default JobSearchButton
