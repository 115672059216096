import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { fromNow } from 'builder/utils/formatDate'
import Avatar from './Avatar'

import { formatViewDuration, formatViewDownloads } from './utils'

import { View, ViewContent, ViewTitle, ViewSubtitle, ViewInfo } from './styles'

const HistoryView = ({ view }) => {
  const subtitle = useMemo(() => {
    const duration = formatViewDuration(view.duration)
    const saved = formatViewDownloads(view.downloadFormats)
    return [duration, saved].filter(Boolean).join(' · ')
  }, [view.downloadFormats, view.duration])

  return (
    <View>
      <Avatar seed={view.visitorId} />
      <ViewContent>
        <ViewTitle>{view.visitorName}</ViewTitle>
        <ViewSubtitle>{subtitle}</ViewSubtitle>
      </ViewContent>
      <ViewInfo>
        <ViewTitle>{fromNow(view.createdAt, { addSuffix: true })}</ViewTitle>
        <ViewSubtitle>{view.location}</ViewSubtitle>
      </ViewInfo>
    </View>
  )
}

HistoryView.propTypes = {
  view: PropTypes.object.isRequired,
}

export default HistoryView
