import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  ${Media.Phone`
    display: none;
  `};
`

export const Title = styled.div``

export const DotList = styled.div`
  display: flex;
  gap: 12px;
`

export const Dot = styled.div<{ isActive: boolean }>`
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: ${props => (props.isActive ? Colors.Blue50 : Colors.Neutral20)};
`
