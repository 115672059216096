import { Resume, ResumeField } from 'packages/types'
import { i18n as I18n } from 'builder/utils/i18n'
import { isTruthy } from 'builder/utils/richTextUtils'
import { SectionNames } from '../sectionNames'
import { FieldRule, ResumeFieldSuggest } from './types'

// ---
// Score
// ---
const getScore = <K extends keyof Resume>(resume: Resume, field: FieldRule<K>) => {
  const hasValue = isTruthy(resume[field.key] as ResumeField)
  const points = hasValue ? field.points : 0
  let suggest: ResumeFieldSuggest | null = null

  if (!hasValue && field.includeSuggest) {
    suggest = {
      type: 'simpleField',
      fieldId: field.fieldId,
      sectionId: field.sectionId,
      text: field.text,
      points: field.points,
      key: `${field.key}_${field.points}`,
    }
  }

  return { points, suggest }
}

// ---
// Fields
// ---
const getFields = <K extends keyof Resume>() =>
  [
    {
      key: 'profile', // summary
      fieldId: 'profile',
      sectionId: SectionNames.profile,
      points: 15,
      text: I18n.t('builder.resume_score.add_summary'),
      includeSuggest: true,
    },
    {
      key: 'position', // position you are applying for
      fieldId: 'position',
      sectionId: SectionNames.details,
      points: 10,
      text: I18n.t('builder.resume_score.add_job_title'),
      includeSuggest: true,
    },
    {
      key: 'email', // email
      fieldId: 'email',
      sectionId: SectionNames.details,
      points: 5,
      text: I18n.t('builder.resume_score.add_email'),
      includeSuggest: true,
    },
    {
      key: 'countryName', // country name
      fieldId: 'countryName',
      sectionId: SectionNames.details,
      points: 2,
      text: I18n.t('builder.resume_score.add_country_name'),
      includeSuggest: true,
    },
    {
      key: 'city', // city
      fieldId: 'city',
      sectionId: SectionNames.details,
      points: 2,
      text: I18n.t('builder.resume_score.add_city'),
      includeSuggest: true,
    },
    {
      key: 'phoneNumber', // phone number
      fieldId: 'phoneNumber',
      sectionId: SectionNames.details,
      points: 1,
      text: I18n.t('builder.resume_score.add_phone_number'),
      includeSuggest: true,
    },
  ] as FieldRule<K>[]

export const simpleFieldRule = {
  getFields,
  getScore,
}
