import { Swiper, SwiperSlide } from 'swiper/react'
import { useI18n } from 'builder/hooks/useI18n'

import Rome from '../../../assets/rome.png'
import Berlin from '../../../assets/berlin.jpeg'
import CapeTown from '../../../assets/cape_town.png'
import Dublin from '../../../assets/dublin.png'
import NewYork from '../../../assets/new_york.png'
import Geneva from '../../../assets/geneva.png'

import {
  Caption,
  Container,
  Slide,
  Image,
  CaptionHeader,
  CaptionSubHeader,
  SliderContainer,
  Overlay,
} from './styles'

const TRANSLATION = `builder.onboarding_ai_enhanced.slides`

const SliderAnimation = () => {
  const { i18n } = useI18n()
  const slides = [
    {
      header: i18n.t(`${TRANSLATION}.rome`),
      subHeader: i18n.t(`${TRANSLATION}.rome_used`),
      image: Rome,
    },
    {
      header: i18n.t(`${TRANSLATION}.berlin`),
      subHeader: i18n.t(`${TRANSLATION}.berlin_used`),
      image: Berlin,
    },
    {
      header: i18n.t(`${TRANSLATION}.cape_town`),
      subHeader: i18n.t(`${TRANSLATION}.cape_town_used`),
      image: CapeTown,
    },
    {
      header: i18n.t(`${TRANSLATION}.dublin`),
      subHeader: i18n.t(`${TRANSLATION}.dublin_used`),
      image: Dublin,
    },
    {
      header: i18n.t(`${TRANSLATION}.new_york`),
      subHeader: i18n.t(`${TRANSLATION}.new_york_used`),
      image: NewYork,
    },
    {
      header: i18n.t(`${TRANSLATION}.geneva`),
      subHeader: i18n.t(`${TRANSLATION}.geneva_used`),
      image: Geneva,
    },
  ]

  return (
    <Container>
      <Overlay position="left" />
      <Swiper
        loop={true}
        speed={800}
        autoplay={true}
        initialSlide={2}
        slidesPerView={1.9}
        centeredSlides={true}
      >
        {slides.map((s, index) => (
          <SwiperSlide key={index}>
            {({ isActive, isNext }: never) => (
              <>
                <Caption active={isActive}>
                  <CaptionHeader>{s.header}</CaptionHeader>
                  <CaptionSubHeader>{s.subHeader}</CaptionSubHeader>
                </Caption>
                <SliderContainer isNext={isNext} active={isActive}>
                  <Slide active={isActive}>
                    <Image active={isActive} srcSet={s.image} />
                  </Slide>
                </SliderContainer>
              </>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
      <Overlay position="right" />
    </Container>
  )
}

export default SliderAnimation
