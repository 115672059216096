import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import { PhrasesSuggestionContainer } from 'builder/components/PhrasesSuggestion'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled(PhrasesSuggestionContainer)`
  overflow: visible;
  width: 358px;
  padding: 6px;
  border-radius: 8px;
  display: flex;
  flex-flow: column nowrap;
  gap: 4px;

  ${props => Media.Phone`
    left: 50%;
    bottom: 50px;
    transform: translate(-50%, 8px);

    ${
      props.isVisible &&
      css`
        transform: translate(-50%, 0);
        opacity: 1;
      `
    }
  `}

  ${props => Media.Desktop`
    margin-left: 16px;
    left: 100%;
    top: 12%;
    transform: translate(-8px, -50%);

    ${
      props.isVisible &&
      css`
        transform: translate(0, -50%);
        opacity: 1;
      `
    }
  `}
`

export const Title = styled.div`
  ${Typography.Caps};
  ${FontWeights.DemiBold};
  color: ${Colors.Indigo70};
  padding: 12px 12px 0;
`

export const Divider = styled.div`
  flex: 0 0 1px;
  margin: 0 12px;
  background: ${Colors.Neutral15};
`
