import { memo } from 'react'
import Anchor from '../Anchor'
import ProfileSummary from './ProfileSummary'
interface Props {
  sectionId: string
}

const ProfileSection = (props: Props): JSX.Element => {
  const { sectionId } = props
  return (
    <Anchor id={sectionId} scrollOffsetTop={70}>
      <ProfileSummary />
    </Anchor>
  )
}

export default memo(ProfileSection)
