const StarSvg = ({ fillColor }: { fillColor: string }) => {
  return (
    <svg width="25" height="24" viewBox="0 1 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.6">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.4824 4.87511C7.35338 4.37496 6.64311 4.37496 6.5141 4.87511L6.25168 5.89242C6.20622 6.06862 6.06862 6.20622 5.89242 6.25168L4.87511 6.5141C4.37496 6.64312 4.37496 7.35338 4.87511 7.4824L5.89242 7.74482C6.06862 7.79028 6.20622 7.92788 6.25168 8.10408L6.5141 9.12139C6.64312 9.62154 7.35338 9.62153 7.4824 9.12139L7.74482 8.10408C7.79028 7.92788 7.92788 7.79028 8.10408 7.74482L9.12139 7.4824C9.62154 7.35338 9.62153 6.64311 9.12139 6.5141L8.10408 6.25168C7.92788 6.20622 7.79028 6.06862 7.74482 5.89242L7.4824 4.87511ZM14.6602 6.70026C14.3506 5.49991 12.6459 5.49991 12.3363 6.70026L11.3856 10.3856L7.70026 11.3363C6.49991 11.6459 6.49991 13.3506 7.70026 13.6602L11.3856 14.6109L12.3363 18.2962C12.6459 19.4966 14.3506 19.4966 14.6602 18.2962L15.6109 14.6109L19.2962 13.6602C20.4966 13.3506 20.4966 11.6459 19.2962 11.3363L15.6109 10.3856L14.6602 6.70026ZM12.7118 10.8495L13.4982 7.8007L14.2847 10.8495C14.3938 11.2724 14.7241 11.6027 15.1469 11.7118L18.1958 12.4982L15.1469 13.2847C14.7241 13.3938 14.3938 13.7241 14.2847 14.1469L13.4982 17.1958L12.7118 14.1469C12.6027 13.7241 12.2724 13.3938 11.8495 13.2847L8.8007 12.4982L11.8495 11.7118C12.2724 11.6027 12.6027 11.2724 12.7118 10.8495Z"
          fill={fillColor}
        />
      </g>
    </svg>
  )
}

export default StarSvg
