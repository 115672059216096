import {
  Container,
  HeaderText,
  Cards,
  Card,
  CardImg,
  Info,
  Text,
  SubText,
  BorderBottom,
} from './styles'

import { ABOUT_SESSIONS } from './constants'

const AboutCoachingSessions = () => {
  return (
    <Container>
      <HeaderText>Personalized Coaching with Professional Experts</HeaderText>
      <Cards>
        {ABOUT_SESSIONS.map(({ id, label, url, description }) => {
          return (
            <Card key={id}>
              <CardImg src={url} />
              <Info>
                <Text>{label}</Text>
                <SubText dangerouslySetInnerHTML={{ __html: description }} />
              </Info>
            </Card>
          )
        })}
      </Cards>
      <BorderBottom />
    </Container>
  )
}

export default AboutCoachingSessions
