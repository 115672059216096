import { Component } from 'react'
import PropTypes from 'prop-types'
import { matchPath } from 'react-router-dom'
import startCase from 'lodash/startCase'
import { trackInternalEvent, trackMarketingEvent } from '@rio/tracking'
import { i18n as I18n } from 'builder/utils/i18n'
import { withRouter } from 'builder/hocs/withRouter'
import { SignInLayout } from 'builder/components/SignInLayout'
import Section from 'builder/components/SignInSection'
import Methods from 'builder/components/SignInMethods'
import * as SimpleForm from 'builder/components/SimpleForm'
import Message from 'builder/components/SignInMessage'
import { TextField } from 'builder/components/TextField'
import { Spinner } from 'builder/components/Spinner'
import { Button } from 'builder/components/Button'

import { MessageNote, MessageAction, FormNote, Loading } from './styles'

import inboxImage from 'images/builder/sign-in/inbox.png'
import supportImage from 'images/builder/sign-in/support.svg'
import errorImage from 'images/error.svg'

const steps = {
  welcome: 'WELCOME',
  email: 'EMAIL',
  password: 'PASSWORD',
  inboxMagicLink: 'INBOX_MAGIC_LINK',
  yahooInboxMagicLink: 'YAHOO_INBOX_MAGIC_LINK',
  checkingLink: 'CHECKING_LINK',
  invalidLink: 'INVALID_LINK',
  inboxPasswordLink: 'INBOX_PASSWORD_LINK',
}

class SignIn extends Component {
  hostname = window.location.hostname
  contactLinkURL = this.hostname === 'career.io' ? 'https://career.io/contact-us' : '/contact'

  state = {
    step: this.props.isWidget ? steps.email : steps.welcome,
    email: '',
    password: '',
    magicLinkJustSent: false,
  }

  static propTypes = {
    location: PropTypes.object.isRequired,
    signInMethod: PropTypes.string,
    checkEmail: PropTypes.func.isRequired,
    checkMagicLink: PropTypes.func.isRequired,
    isEmailChecking: PropTypes.bool,
    emailCheckingError: PropTypes.string,
    sendMagicLink: PropTypes.func.isRequired,
    sendPasswordLink: PropTypes.func.isRequired,
    signIn: PropTypes.func.isRequired,
    isSignInFetching: PropTypes.bool,
    signInError: PropTypes.string,
    match: PropTypes.object,
    params: PropTypes.object,
    magicLinkCheckError: PropTypes.string,
    magicLinkEmail: PropTypes.string,
    isWidget: PropTypes.bool,
  }

  componentDidUpdate(prevProps) {
    const { signInMethod, magicLinkCheckError, magicLinkEmail } = this.props

    if (magicLinkCheckError && magicLinkCheckError !== prevProps.magicLinkCheckError) {
      this.setState({ email: magicLinkEmail ?? '' })
      this.showStep(steps.invalidLink)
    }

    if (signInMethod !== prevProps.signInMethod) {
      if (signInMethod === 'magic_link') this.sendMagicLink()
      if (signInMethod === 'password') this.showStep(steps.password)
    }
  }

  componentDidMount() {
    const { location, params } = this.props
    if (matchPath('/auth/magic/:token', location.pathname)) {
      this.showStep(steps.checkingLink)
      this.props.checkMagicLink(params.token)
    }

    trackInternalEvent(`visit_sign_in_page`)
  }

  componentWillUnmount() {
    clearTimeout(this._sendLinktimeoutId)
  }

  showStep = step => {
    this.setState({ step })
  }

  handleMethodClick = network => {
    trackMarketingEvent('Sign In', `Choose ${startCase(network)} Method`)
    trackInternalEvent(`choose_social_login_method`, { network })
    if (network === 'email') this.showStep(steps.email)
  }

  handleInputChange = event => {
    const { name, value } = event.target
    this.setState({
      [name]: value,
    })
  }

  sendMagicLink = () => {
    const { email } = this.state
    const chromeExtension = window.location.href.split('?')[1] === 'chrome_extension=true'

    this.props.sendMagicLink({ email, chrome_extension: chromeExtension })

    this.setState({ magicLinkJustSent: true }, () => {
      this._sendLinktimeoutId = setTimeout(() => {
        this.setState({ magicLinkJustSent: false })
      }, 10000)
    })

    const stepName = email.toLowerCase().includes('@yahoo')
      ? steps.yahooInboxMagicLink
      : steps.inboxMagicLink

    this.showStep(stepName)
  }

  sendPasswordLink = () => {
    const { email } = this.state
    this.props.sendPasswordLink(email)
    this.showStep(steps.inboxPasswordLink)
    trackInternalEvent('request_password_reset')
  }

  handleEmailSubmit = () => {
    const { email } = this.state
    if (email) this.props.checkEmail(email)
  }

  handlePasswordSubmit = () => {
    const { email, password } = this.state
    if (password) this.props.signIn({ email, password })
  }

  render() {
    const { step, email, password, magicLinkJustSent } = this.state
    const { signInError, isSignInFetching, isEmailChecking, emailCheckingError, isWidget } =
      this.props

    return (
      <SignInLayout controlsHidden={step === steps.checkingLink || isWidget}>
        {step === steps.welcome && (
          <Section
            title={I18n.t('builder.sign_in.title')}
            description={I18n.t('builder.sign_in.welcome')}
          >
            <Methods onButtonClick={this.handleMethodClick} />
          </Section>
        )}

        {step === steps.email && (
          <Section
            title={I18n.t('builder.sign_in.title')}
            description={I18n.t('builder.sign_in.enter_email')}
          >
            <SimpleForm.Form
              onBackClick={() => this.showStep(steps.welcome)}
              onSubmit={this.handleEmailSubmit}
              isDisabled={isEmailChecking}
            >
              <SimpleForm.Row>
                <TextField
                  type="email"
                  name="email"
                  label={I18n.t('builder.sign_in.email_label')}
                  value={email}
                  onChange={this.handleInputChange}
                  autoFocus={true}
                  error={emailCheckingError}
                />
              </SimpleForm.Row>
            </SimpleForm.Form>
          </Section>
        )}

        {step === steps.password && (
          <Section
            title={I18n.t('builder.sign_in.title')}
            description={I18n.t('builder.sign_in.enter_password')}
          >
            <SimpleForm.Form
              onBackClick={() => this.showStep(steps.email)}
              onSubmit={this.handlePasswordSubmit}
              isDisabled={isSignInFetching}
            >
              <SimpleForm.Row>
                <TextField
                  type="password"
                  name="password"
                  label={I18n.t('builder.sign_in.password_label')}
                  value={password}
                  onChange={this.handleInputChange}
                  autoFocus={true}
                  error={signInError}
                />
              </SimpleForm.Row>
              <FormNote>
                <span onClick={this.sendPasswordLink}>
                  {I18n.t('builder.sign_in.forgot_password')}
                </span>
              </FormNote>
            </SimpleForm.Form>
          </Section>
        )}

        {step === steps.inboxMagicLink && (
          <div>
            <Message image={inboxImage} title={I18n.t('builder.sign_in.check_your_inbox')}>
              {I18n.t('builder.sign_in.magic_link_sent')} <strong>{email}</strong>.
              <br />
              {I18n.t('builder.sign_in.click_magic_link')}
            </Message>
            <MessageNote>
              {I18n.t('builder.sign_in.didnt_receive_magic')}{' '}
              <a href={this.contactLinkURL} target="_blank" rel="noreferrer noopener">
                {I18n.t('builder.sign_in.contact_support')}
              </a>
            </MessageNote>
          </div>
        )}

        {step === steps.invalidLink && (
          <div>
            <Message
              image={errorImage}
              title={I18n.t(`builder.sign_in.${email ? 'link_expired' : 'link_is_invalid'}`)}
            />
            {email && (
              <MessageAction>
                <Button onClick={this.sendMagicLink}>
                  {I18n.t('builder.sign_in.send_new_magic_link')}
                </Button>
              </MessageAction>
            )}
            <MessageNote>
              {email && (
                <span onClick={this.sendPasswordLink}>
                  {I18n.t('builder.sign_in.or_create_password')}
                </span>
              )}
              {!email && (
                <span onClick={() => this.showStep(steps.welcome)}>
                  {I18n.t('builder.sign_in.log_in_into_account')}
                </span>
              )}
            </MessageNote>
          </div>
        )}

        {step === steps.yahooInboxMagicLink && (
          <div>
            <Message image={supportImage} title={I18n.t('builder.sign_in.yahoo_title')}>
              {I18n.t('builder.sign_in.yahoo.having_troubles')}{' '}
              <strong>{I18n.t('builder.sign_in.yahoo.didnt_receive')}</strong>{' '}
              {I18n.t('builder.sign_in.yahoo.open_inbox')}{' '}
              <strong>{I18n.t('builder.sign_in.yahoo.contacts_icon')}</strong>
              {I18n.t('builder.sign_in.yahoo.add_contact')}{' '}
              <strong>{I18n.t('builder.sign_in.yahoo.support_email')}</strong>
              {I18n.t('builder.sign_in.yahoo.request_new_link')}
            </Message>
            <MessageAction>
              <Button onClick={this.sendMagicLink} isDisabled={magicLinkJustSent}>
                {I18n.t('builder.sign_in.send_new_magic_link')}
              </Button>
            </MessageAction>
          </div>
        )}

        {step === steps.inboxPasswordLink && (
          <div>
            <Message image={inboxImage} title={I18n.t('builder.sign_in.check_your_inbox')}>
              {I18n.t('builder.sign_in.sent_password_link')} <strong>{email}</strong>
              {I18n.t('builder.sign_in.follow_password_link')}
            </Message>
            <MessageNote>
              {I18n.t('builder.sign_in.please')}{' '}
              <a href={this.contactLinkURL} target="_blank" rel="noreferrer noopener">
                {I18n.t('builder.sign_in.contact_support').toLowerCase()}
              </a>{' '}
              {I18n.t('builder.sign_in.didnt_receive_password_letter')}
            </MessageNote>
          </div>
        )}

        {step === steps.checkingLink && (
          <Loading>
            <Spinner />
          </Loading>
        )}
      </SignInLayout>
    )
  }
}

export default withRouter(SignIn)
