import { useRef, useCallback } from 'react'
import axios, { AxiosResponse } from 'axios'
import { trackInternalEvent } from '@rio/tracking'
import { useCompleteInterviewVideo } from 'builder/views/Interview/hooks/useCompleteInterviewVideo'
import { useInterviewPresignedUrl } from 'builder/views/Interview/hooks/useInterviewPresignedUrl'
import { useUploadInterviewVideo } from 'builder/views/Interview/hooks/useUploadInterviewVideo'
import { useMutationInterviewAnswerQuestion } from 'builder/views/Interview/hooks/useMutationInterviewAnswerQuestion'
import { useMutationDeleteInterviewAnswerQuestion } from 'builder/views/Interview/hooks/useMutationDeleteInterviewAnswerQuestion'
import { InterviewAnswer } from 'builder/modules/interview/types'
import { PerformanceLoggerWithMainDsn as PerformanceLogger } from 'builder/services/PerformanceLogger'

import ErrorLogger from 'builder/services/ErrorLogger'

interface MediaRequests {
  currentPart: number
  partRequests: Record<number, Promise<AxiosResponse<Response>>>
}
interface QuestionRequest {
  questionId: number
  isInterrupted: boolean
  answerRequest: InterviewAnswer
  presignedRequest: Record<number, Promise<InterviewAnswer>>
  completedRequest: Promise<Response> | undefined
  stopProcessing: Promise<void> | undefined
  abortController: AbortController
  requests: {
    video: MediaRequests
    audio: MediaRequests
  }
}

export const useManageRecordingInterview = () => {
  const questionsRef = useRef<Record<number, QuestionRequest>>({})
  const errorRef = useRef<Error[]>([])

  const { mutateAsync: getPresignedUrl } = useInterviewPresignedUrl()
  const { mutateAsync: uploadChunk } = useUploadInterviewVideo()
  const { mutateAsync: completeFile } = useCompleteInterviewVideo()
  const { mutateAsync: startAnswer } = useMutationInterviewAnswerQuestion()
  const { mutateAsync: deleteAnswer } = useMutationDeleteInterviewAnswerQuestion()

  const errorHandler = useCallback(async (where: string, questionId: number, error: unknown) => {
    if (error instanceof Error) {
      if (errorRef.current.includes(error)) {
        return
      }
      errorRef.current.push(error)

      if (axios.isAxiosError(error)) {
        trackInternalEvent('error_interview', {
          label: 'interview_prep',
          code: error.code,
          url: error.config.url,
          name: error.name,
          questionId,
        })
      }
    }
    const requestWasAborted = axios.isAxiosError(error) && error.code === 'ERR_CANCELED'
    if (!requestWasAborted) {
      ErrorLogger.log(error, { tag: 'interview-player' })
    }
  }, [])

  interface PerformanceLoggerHandler<T> {
    identify: string
    tags?: QuestionRequest
    operation: string
    promise: Promise<T>
  }
  const performanceLoggerHandler = useCallback(
    async <T>({ identify, tags, promise }: PerformanceLoggerHandler<T>): Promise<T> => {
      PerformanceLogger.operationFull({
        key: identify,
        tags,
      })
      const value = await promise

      PerformanceLogger.operationEnd(identify)

      return value
    },
    [],
  )

  const makePreSignRequest = useCallback(async (questionId, answerId, partNumber) => {
    const question = questionsRef.current[questionId]

    const presignedRequest = getPresignedUrl({
      questionId,
      answerId,
      partNumber,
      signal: question.abortController.signal,
    })

    question.presignedRequest[partNumber] = performanceLoggerHandler({
      identify: `presignedUrl-request-${questionId}`,
      operation: 'presignedUrl-request',
      tags: question,
      promise: presignedRequest,
    })
    return question.presignedRequest[partNumber]
  }, [])

  const makeUploadChuckRequest = useCallback(
    async ({
      questionId,
      media,
      preSignResponse,
      blob,
    }: {
      questionId: number
      media: 'video' | 'audio'
      blob: Blob
      preSignResponse: InterviewAnswer
    }) => {
      try {
        const question = questionsRef.current[questionId]
        const requests = question.requests[media]

        const uploadChunkPromise = uploadChunk({
          url:
            media === 'video' ? preSignResponse.video_upload_url : preSignResponse.audio_upload_url,
          blob,
          signal: question.abortController.signal,
        })

        const addedPerformanceLoggerPromise = performanceLoggerHandler({
          identify: `chunk-request-${media}-${questionId}-${requests.currentPart}`,
          operation: 'chunk-request',
          tags: question,
          promise: uploadChunkPromise,
        })
        requests.partRequests[requests.currentPart] = addedPerformanceLoggerPromise
        requests.currentPart++
        return addedPerformanceLoggerPromise
      } catch (error) {
        errorHandler('MakeUploadChuckRequest', questionId, error)
      }
    },
    [],
  )

  const makeCompleteRequest = useCallback(async (questionId, audioMetadata, videoMetadata) => {
    try {
      const question = questionsRef.current[questionId]

      const videoEtagsPromises = Object.entries(question.requests.video.partRequests)
        .sort((a, b) => Number(a[0]) - Number(b[0]))
        .map(([, value]) => value)

      const audioEtagsPromises = Object.entries(question.requests.audio.partRequests)
        .sort((a, b) => Number(a[0]) - Number(b[0]))
        .map(([, value]) => value)

      const videoEtagsResolved = await Promise.all(videoEtagsPromises)

      const audioEtagsResolved = await Promise.all(audioEtagsPromises)
      const videoEtags = videoEtagsResolved.map((r, i) => ({
        part_number: i + 1,
        etag: r.headers.etag || '',
      }))

      const audioEtags = audioEtagsResolved.map((r, i) => ({
        part_number: i + 1,
        etag: r.headers.etag || '',
      }))
      if (!videoMetadata && !audioMetadata) {
        throw Error('At least one metadata must be provide')
      }
      if (question.completedRequest) {
        throw Error('This function must be called one time per question')
      }
      const metadata = videoMetadata || audioMetadata

      const completeRequestPromise = completeFile({
        answerId: question.answerRequest.id,
        questionId,
        answerDuration: metadata.duration,
        totalBytes: metadata.totalBytes,
        body: {
          audio: audioEtags,
          video: videoEtags,
        },
        signal: question.abortController.signal,
      })

      question.completedRequest = performanceLoggerHandler({
        identify: `complete-request-${questionId}`,
        operation: 'complete-request',
        tags: question,
        promise: completeRequestPromise,
      })
      return question.completedRequest
    } catch (error) {
      errorHandler('MakeCompleteRequest', questionId, error)
    }
  }, [])

  const createNewAnswer = useCallback(
    async ({
      questionId,
      mimeTypeForAudio,
      mimeTypeForVideo,
    }: {
      questionId: number
      mimeTypeForAudio: string
      mimeTypeForVideo: string
    }) => {
      try {
        const answerRequest = await startAnswer({ questionId, mimeTypeForAudio, mimeTypeForVideo })
        const question: QuestionRequest = {
          questionId,
          answerRequest,
          presignedRequest: {},
          completedRequest: undefined,
          stopProcessing: undefined,
          isInterrupted: false,
          abortController: new AbortController(),
          requests: {
            video: {
              currentPart: 1,
              partRequests: {},
            },
            audio: {
              currentPart: 1,
              partRequests: {},
            },
          },
        }

        questionsRef.current[questionId] = question
        makePreSignRequest(questionId, answerRequest.id, 1)
        return question
      } catch (error) {
        errorHandler('createNewAnswer', questionId, error)
      }
    },
    [],
  )

  const uploadBlobChunkPart = useCallback(
    async ({
      questionId,
      media,
      blob,
    }: {
      questionId: number
      media: 'video' | 'audio'
      blob: Blob
    }) => {
      try {
        const question = questionsRef.current[questionId]
        const requests = question.requests[media]
        let preSignResponse: InterviewAnswer = await question.presignedRequest[requests.currentPart]

        if (!preSignResponse) {
          preSignResponse = await makePreSignRequest(
            questionId,
            question.answerRequest.id,
            requests.currentPart,
          )
        }

        const uploadChuckRequest = await makeUploadChuckRequest({
          questionId,
          media,
          blob,
          preSignResponse,
        })

        return uploadChuckRequest
      } catch (error) {
        errorHandler('uploadBlobChunkPart', questionId, error)
      }
    },
    [],
  )

  const stopAnswer = useCallback(async (questionId, audioStopPromise, videoStopPromise) => {
    const question = questionsRef.current[questionId]
    if (question.stopProcessing) {
      throw new Error('This function must be executed on time for question')
    }
    question.stopProcessing = new Promise(async (resolve, reject) => {
      const preSignRequests = Object.values(question.presignedRequest)
      const videoRequests = Object.values(question.requests.video.partRequests)
      const audioRequests = Object.values(question.requests.audio.partRequests)
      try {
        const [audioMetadata, videoMetadata] = await Promise.all([
          audioStopPromise,
          videoStopPromise,
          preSignRequests,
          audioRequests,
          videoRequests,
        ])
        await makeCompleteRequest(questionId, audioMetadata, videoMetadata)
        resolve(undefined)
      } catch (error) {
        errorHandler('stopAnswer', questionId, error)
        reject(error)
      }
    })
  }, [])

  const stopInterview = useCallback(async () => {
    try {
      const questions = Object.values(questionsRef.current)
      const notInterruptedQuestions = questions.filter(question => !question.isInterrupted)

      await Promise.all(notInterruptedQuestions.map(question => question.stopProcessing))

      const questionNotCompleted = notInterruptedQuestions.find(
        questions => questions.completedRequest === undefined,
      )
      if (questionNotCompleted) {
        throw new Error('All questions must be completed')
      }
      await Promise.all(questions.map(q => q.completedRequest))
    } catch (error) {
      errorHandler('stopInterview', -1, error)
      throw error
    }
  }, [])

  const interruptedAnswer = useCallback(async questionId => {
    const question = questionsRef.current[questionId]
    if (question) {
      question.isInterrupted = true
      question.abortController.abort('Interrupted answer')
      deleteAnswer(questionId)
    }
  }, [])

  return {
    interruptedAnswer,
    createNewAnswer,
    uploadBlobChunkPart,
    stopAnswer,
    stopInterview,
  }
}
