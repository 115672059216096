import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { Icon20 } from 'builder/components/Icon'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Container = styled.div`
  gap: 87px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${Media.Tablet`
    gap: 32px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: scroll;
    width: 100%;

    &::-webkit-scrollbar {
      width: 1px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      border: none;
    }
  `}

  ${Media.Phone`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: scroll;
    width: 100%;
    gap: 14px;
    padding-bottom: 32px;

    &::-webkit-scrollbar {
      width: 1px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      border: none;
    }
  `}
`

export const PathText = styled.div`
  color: ${Colors.Neutral50};
`

export const JobTitles = styled.div`
  gap: 16.17px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${Media.Phone`
    gap: 12.17px;
  `}
`

export const JobTitle = styled.div<{ isSelected: boolean }>`
  ${Typography.Subhead};
  padding: 12px 20px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ isSelected }) => isSelected && Colors.Blue50};
  background-color: ${({ isSelected }) => (isSelected ? Colors.Blue10 : Colors.Neutral10)};

  &:hover {
    cursor: pointer;
    color: ${Colors.Blue50};
    background-color: ${Colors.Blue10};
  }
`

export const ArrowRight = styled(Icon20.ArrowLeft)`
  transform: rotate(180deg);
`
