import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { actions, selectors } from 'builder/modules/careerPath2'

import Button, { ButtonTheme } from 'builder/components/Button'
import ModalOverlay from 'builder/components/ModalOverlay'
import { BriefResume } from 'builder/modules/panel/types'
import { Resumes } from './components/Resume'
import Header from './components/Header'

import { ModalContainer, ButtonContainer } from './styles'

export type PreFillModalProps = {
  onSelect?: (resume: BriefResume) => void
  setResumeID?: () => void
  handleCloseModal?: () => void
  setOpenPreFillModal?: () => void
  header?: JSX.Element
  footer?: JSX.Element
}

export const PreFillModal: React.FC<PreFillModalProps> = props => {
  const dispatch = useDispatch()
  const resumeID = useTypedSelector(selectors.resumeID)

  const handleCloseModal = useCallback(() => {
    dispatch(actions.setResumeID(null))
    dispatch(actions.setOpenPreFillModal(false))
  }, [dispatch])

  const handleSubmitModal = useCallback(() => {
    dispatch(actions.fetchResumeSkills(resumeID))
  }, [dispatch, resumeID])

  return (
    <ModalOverlay
      onClose={props.handleCloseModal ? props.handleCloseModal : handleCloseModal}
      overlayFadeDuration={150}
      contentSlideDuration={0}
    >
      <ModalContainer>
        {props.header ? props.header : <Header onClose={handleCloseModal} />}
        {props.onSelect ? <Resumes onSelect={props.onSelect} /> : <Resumes />}
        {props.footer ? (
          props.footer
        ) : (
          <ButtonContainer>
            <Button theme={ButtonTheme.ghost} onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button type="submit" onClick={handleSubmitModal}>
              Pre-fill
            </Button>
          </ButtonContainer>
        )}
      </ModalContainer>
    </ModalOverlay>
  )
}
