import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useI18n } from 'builder/hooks/useI18n'
import { useResumeDistribution } from 'builder/views/ResumeDistributionView/hooks/useResumeDistribution'

import { Step } from '../Step'
import {
  EllipseOne,
  EllipseThree,
  EllipseTwo,
  ResumeImage,
  EllipsisContainer,
  ResumeImageGradient,
  PersonOne,
  PersonTwo,
  PersonThree,
  Container,
  ImageContainer,
} from './styles'
import RESUME from './assets/resume.png'
import PERSON_ONE from './assets/person-one.jpeg'
import PERSON_TWO from './assets/person-two.jpeg'
import PERSON_THREE from './assets/person-three.jpeg'

interface Props {
  isCompleted: boolean
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export const SendResumeStep = ({ isCompleted, isOpen, setIsOpen }: Props) => {
  const [showHover, setShowHover] = useState(false)

  const { i18n } = useI18n()
  const navigate = useNavigate()
  const { showRDUpsellPromoModal } = useResumeDistribution()

  const onNavigate = () => {
    if (showRDUpsellPromoModal()) return
    navigate('/resume-distribution')
  }

  return (
    <Step
      customHeight={58}
      customTabletHeight={64}
      title={i18n.t('builder.dashboard_v2.steps.send_resume.title')}
      subtitle={i18n.t('builder.dashboard_v2.steps.send_resume.subtitle')}
      isCompleted={isCompleted}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      handleOnClick={onNavigate}
      buttonText={i18n.t('builder.dashboard_v2.steps.send_resume.button_text')}
      analyticsEventsParam="resume_distribution"
      Images={
        <Container>
          <ImageContainer>
            <ResumeImage src={RESUME} />
            <ResumeImageGradient className={showHover ? 'hover' : ''} />
            <PersonOne src={PERSON_ONE} />
            <PersonTwo src={PERSON_TWO} />
            <PersonThree src={PERSON_THREE} />
          </ImageContainer>
          <EllipsisContainer>
            <EllipseOne className={showHover ? 'hover' : ''} />
            <EllipseTwo className={showHover ? 'hover' : ''} />
            <EllipseThree className={showHover ? 'hover' : ''} />
          </EllipsisContainer>
        </Container>
      }
      setShowHover={setShowHover}
    />
  )
}
