import { useMemo } from 'react'
import getValue from 'lodash/get'
import isEmptyValue from 'lodash/isEmpty'
import type { Resume } from '@rio/types'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors, Step } from 'builder/modules/resumeEditor'
import { Card } from '../types'

/**
 * Checks that passed part of resume is empty.
 * Fields are considered empty if they are `null`, `undefined`, or empty strings/arrays/objects
 */
const isEmpty = (target: Resume | Step | Card | null, fieldNames: string[]) => {
  if (!target) return true
  return fieldNames.every(name => isEmptyValue(getValue(target, name)))
}

/** Returns an array of empty resume section ids */
const useEmptyStepIds = (): string[] => {
  const { steps, resume } = useTypedSelector(state => ({
    steps: selectors.wizardSteps(state),
    resume: selectors.resume(state),
  }))

  return useMemo(() => {
    // find the sections that where edited
    const emptySteps = steps.filter(({ id, simpleFieldNames, cardFieldNames }) => {
      // check profile fields
      if (simpleFieldNames && isEmpty(resume, simpleFieldNames)) return true

      // check section cards
      if (cardFieldNames) {
        // @ts-expect-error TODO fix TS error
        const cards: Card[] = resume[id]
        return cards.every(card => isEmpty(card, cardFieldNames))
      }

      return false
    })

    return emptySteps.map(step => step.id)
  }, [resume, steps])
}

export default useEmptyStepIds
