import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Wrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  color: ${Colors.Neutral40};
  transition: opacity 0.15s;
  cursor: ${props => (props.isDragging ? 'grabbing' : 'grab')};

  &:before {
    content: '';
    position: absolute;
    left: -8px;
    right: -8px;
    top: -8px;
    bottom: -8px;
  }

  & svg {
    display: block;
  }

  ${Media.Desktop`
    opacity: ${props => (props.isDragging || !props.showDragDropOnHover ? 1 : 0)};
  `};

  ${Media.Laptop`
    opacity: 1;
  `};
`

export const Tooltip = styled.div`
  position: absolute;
  left: -4px;
  bottom: 100%;
  margin-bottom: 6px;

  display: ${props => (props.isDragging ? 'none' : 'block')};
  padding: 5px 8px;
  border-radius: 3px;
  background-color: ${Colors.Neutral80};

  ${Typography.Tiny};
  font-weight: 400;
  color: ${Colors.Neutral10};
  white-space: nowrap;

  user-select: none;
  pointer-events: none;

  opacity: 0;
  transition: opacity 0.2s ease;
  transition-delay: 0;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 0 0 2px 0;
    transform: translate(-50%, -50%) rotateZ(45deg);
    background-color: ${Colors.Neutral80};
    top: 100%;
    left: 14px;
  }

  ${Media.Hover`
    ${Wrapper}:hover & {
      opacity: 1;
      transition-delay: 0.4s;
    }
  `}

  ${Media.Phone`
    display: none;
  `}
`
