import { useEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { ButtonSize, ButtonTheme } from 'builder/components/Button'
import { i18n as I18n } from 'builder/utils/i18n'
import Logo from './cio-products-logo.svg'
import {
  Banner,
  BannerContainer,
  BannerTitle,
  BannerContent,
  BannerImage,
  ContentWrapper,
  CloseButton,
  OpenButton,
} from './styles'

interface Props {
  onClose: () => void
  onOpen: () => void
}

const SavingInfoBanner = ({ onClose, onOpen }: Props) => {
  useEffect(() => {
    trackInternalEvent('see_resume_optimizer_banner')
  }, [])

  return (
    <BannerContainer>
      <Banner>
        <BannerImage src={Logo} alt="Product Image" />
        <ContentWrapper>
          <BannerTitle>Your Resume Score was saved to Job Tracker</BannerTitle>
          <BannerContent>Explore Job Tracker to manage your job applications</BannerContent>
        </ContentWrapper>
        <CloseButton size={ButtonSize.small} theme={ButtonTheme.ghost} onClick={onClose}>
          {I18n.t('builder.resume_editor.prefill.modal.close')}
        </CloseButton>
        <OpenButton size={ButtonSize.small} onClick={onOpen}>
          Open
        </OpenButton>
      </Banner>
    </BannerContainer>
  )
}

export default SavingInfoBanner
