import styled from 'styled-components'
import Icon24 from 'builder/components/Icon'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Button } from '../Button/Button'

export const Container = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  color: ${Colors.White};
  padding: 0 20px;
  padding-top: 22px;
`

export const CloseIconContainer = styled.div`
  border-radius: 40px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%),
    rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(15px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-right: 16px;
  flex-shrink: 0;
  z-index: 522;
  cursor: pointer;
`

export const CreatedAt = styled.div`
  ${Typography.Caption};
  ${FontWeights.Regular};
`

export const TabletButtonContainer = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
  gap: 8px;

  ${Media.AbovePhone`
    display: flex;
    justify-content: flex-end;
    margin-left:24px;
  `};

  ${Media.Phone`
    display: none;
  `};
`
export const FeedbackButton = styled(Button)`
  ${Media.AboveTablet`
    display: none;
  `};
`

export const MobileFeedbackButton = styled(Button)`
  display: none;

  ${Media.Phone`
    display: block;

  `};
`

export const Title = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Regular};
  flex-shrink: 0;
`

export const TextContainer = styled.div`
  width: 100%;
`

export const ListIcon = styled(Icon24.ListIcon)`
  width: 20px;
  height: 20px;
  margin-right: 8px;

  & path {
    stroke: currentColor;
  }
`
