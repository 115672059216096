import { apiClient } from 'builder/modules/apiClient'
import { SuggestResponse } from '../types'

/**
 * Find a typo in given email address and suggest the fix
 */
export const suggestEmail = async (email: string) => {
  const { data } = await apiClient.post<SuggestResponse>(`/sign-up/mail-speller`, { email })

  if (data.success && data.suggested) return data.suggested

  return null
}
