import * as Styled from './styles'

type Props = {
  title: string
  subtitle: string
  children: React.ReactNode
}

export const StepLayout = ({ title, subtitle, children }: Props) => {
  return (
    <Styled.Container>
      <Styled.Header>
        <Styled.Title>{title}</Styled.Title>
        <Styled.Subtitle>{subtitle}</Styled.Subtitle>
      </Styled.Header>

      <Styled.Content>{children}</Styled.Content>
    </Styled.Container>
  )
}
