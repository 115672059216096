import PropTypes from 'prop-types'
import { Container, Text, Icon } from './styles'

const WarningSnackBar = ({ text }) => (
  <Container>
    <Icon />
    <Text>{text}</Text>
  </Container>
)

WarningSnackBar.propTypes = {
  text: PropTypes.string,
}

export default WarningSnackBar
