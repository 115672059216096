import { memo } from 'react'
import times from 'lodash/times'
import { FeatureCard } from 'builder/hooks/useFeatureCards'
import { useI18n } from 'builder/hooks/useI18n'
import { ButtonContainer, ButtonIcon, ButtonDot, ButtonText } from './styles'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isActive: boolean
  feature?: FeatureCard
}

const BurgerButtonComponent = ({ isActive, ...rest }: Props) => {
  const { i18n } = useI18n()
  return (
    <ButtonContainer isActive={isActive} {...rest}>
      <ButtonIcon>
        {times(9, index => (
          <ButtonDot key={index} />
        ))}
      </ButtonIcon>
      <ButtonText>{i18n.t('builder.navbar.services')}</ButtonText>
    </ButtonContainer>
  )
}

export const BurgerButton = memo(BurgerButtonComponent)
