import styled, { css } from 'styled-components'
import Button, { ButtonSize, ButtonTheme } from 'builder/components/Button'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: 32px;

  width: 416px;
  max-width: 416px;
  padding-top: 65px;
  margin: 0 auto;
  height: calc(100vh - 110px);

  ${Media.Phone`
    padding: 65px 20px 0 20px;
    width: 100%;
    height: calc(100vh - 160px);
  `}
`

export const ImageContainer = styled.div`
  width: 150px;
  height: 160px;
`

export const Image = styled.img`
  width: 150px;
  height: 160px;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  text-align: center;
`

export const TextHeader = styled.div`
  color: ${Colors.Neutral90};

  ${Typography.L}
  ${FontWeights.Medium}
`

export const TextSubHeader = styled.div`
  color: ${Colors.Neutral50};

  ${Typography.Body}
  ${FontWeights.Regular}
`

export const UploadSection = styled.div<{ $hasError?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: inherit;
  padding: 20px 0;
  border-radius: 8px;
  position: relative;

  border: 1px solid ${Colors.Neutral15};
  ${({ $hasError }) =>
    $hasError &&
    css`
      border-bottom: 2px solid ${Colors.Red50};
    `}
`

export const UploadHeader = styled.div`
  ${Typography.Caption}
  ${FontWeights.Regular}
`

export const UploadButton = styled(Button).attrs({
  theme: ButtonTheme.ghost,
  size: ButtonSize.small,
})``

export const ContactContaier = styled.div`
  ${Typography.Caption}
  ${FontWeights.Regular}
  color: ${Colors.Neutral50};

  position: absolute;
  bottom: 12px;

  ${Media.Phone`
    bottom: 20px;
  `}
`

export const ContactSupport = styled.a`
  color: ${Colors.Blue50};

  &:hover {
    border-bottom: 1px solid ${Colors.Blue50};
  }
`

export const ResumePopupContainer = styled.div`
  position: absolute;
  top: 325px;
  right: 240px;
`

export const SpinnerWrapper = styled.div`
  margin: 28% auto;
  padding: 16px;
  border-radius: 32px;
  box-shadow: 0px 8px 20px -4px rgba(15, 56, 113, 0.12), 0px 2px 4px -1px rgba(15, 56, 113, 0.08),
    0px 0px 1px 0px rgba(15, 56, 113, 0.32);
`
