import React, { useMemo } from 'react'
import Colors from 'builder/styles/colors'
import { Score, ScoreContent } from './styles'

type Props = {
  score: number
}

export const TunerScoreSmall: React.FC<Props> = ({ score }) => {
  const backgroundImage = useMemo(() => {
    if (score >= 70) {
      return `conic-gradient(
        ${Colors.Green50} 0% ${score}%,
        ${Colors.Neutral15} 0% 100%)`
    }

    if (score >= 30) {
      return `conic-gradient(
        ${Colors.Amber50} 0% ${score}%,
        ${Colors.Neutral15} 0% 100%
      )`
    }

    return `conic-gradient(
      ${Colors.Red50} 0% ${score}%,
      ${Colors.Neutral15} 0% 100%
    )`
  }, [score])

  return (
    <Score style={{ backgroundImage }} fromSidebar={true}>
      <ScoreContent />
    </Score>
  )
}
