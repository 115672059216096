import { memo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { i18n as I18n } from 'builder/utils/i18n'
import { useAppSelector } from 'builder/hooks/useAppSelector'
import { AIResumeEducationCardData, actions } from 'builder/modules/resumeEditor'
import {
  AIResumeQuestionnaireCardType,
  AIResumeDraftEventLabels,
  AIResumeDraftEvents,
} from 'builder/modules/constants'
import AddMoreButton from '../../AddMoreButton'
import { CardContainer, CardWrapper } from '../styles'
import EducationCard from './EducationCard'

export const isEducationCardEmpty = (card: AIResumeEducationCardData) => {
  const {
    institute,
    degree,
    date: { dateFrom, dateUntil, isDateUntilPresent },
  } = card

  if (institute || degree || dateFrom || dateUntil || isDateUntilPresent) {
    return false
  }

  return true
}

export const Education = memo(() => {
  const { cards } = useAppSelector(
    store => store.resumeEditor.aiResume.questionnaireData.userInput.education,
  )
  const cardsCount = cards.length

  const dispatch = useDispatch()

  useEffect(() => {
    trackInternalEvent(AIResumeDraftEvents.seeEducationStep, {
      label: AIResumeDraftEventLabels.aiResume,
    })
  }, [])

  const onAdd = () => {
    trackInternalEvent(AIResumeDraftEvents.clickAddMore, {
      label: AIResumeDraftEventLabels.education,
    })
    const latestEducationCard = cards[cardsCount - 1]
    // If the latest card isn't atleast partially filled, do nothing
    if (isEducationCardEmpty(latestEducationCard)) {
      return
    }

    // Collapse the latest card
    dispatch(
      actions.setQuestionnaireCardData({
        cardType: AIResumeQuestionnaireCardType.education,
        data: {
          ...latestEducationCard,
          isCollapsed: true,
        },
      }),
    )

    // Push an empty work exp card in cards array
    dispatch(actions.pushEmptyEducationInQuestionnaire())
  }

  return (
    <CardContainer>
      {cards.map((card, index) => (
        <CardWrapper key={card.id} withTopMargin={index > 0}>
          <EducationCard cardData={card} allCards={cards} />
        </CardWrapper>
      ))}
      {cardsCount < 3 && (
        <AddMoreButton {...{ onAdd }} text={I18n.t('builder.ai_resume_draft.education.add')} />
      )}
    </CardContainer>
  )
})
