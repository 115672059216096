import { memo } from 'react'
import { Icon20, Icon24 } from 'builder/components/Icon'
import { i18n as I18n } from 'builder/utils/i18n'
import { Div, MobileBackButton, ChevronLeft, MobileCloseButton } from '../styles'
import useResumeWithAI from '../useResumeWithAI'

const MobileActionBar = memo(() => {
  const { handleMobileBack, handleClose } = useResumeWithAI()

  return (
    <Div>
      <MobileBackButton onClick={handleMobileBack}>
        <ChevronLeft>
          <Icon24.Chevron />
        </ChevronLeft>
        {I18n.t('builder.ai_resume_draft.back')}
      </MobileBackButton>
      <MobileCloseButton onClick={handleClose}>
        <Icon20.Close />
      </MobileCloseButton>
    </Div>
  )
})

export default MobileActionBar
