import { memo } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { i18n as I18n } from 'builder/utils/i18n'
import { Icon24 } from 'builder/components/Icon'
import Colors from 'builder/styles/colors'
import { PrefillResumeEvents } from 'builder/modules/constants'
import { useConfig } from 'builder/hooks/useConfig'
import CreateResumeImg from '../../../../../../app/assets/images/builder/fill-resume/layout-two/create-resume.png'
import {
  CreateNewResumeCard,
  CardDescription,
  CardSubtitle,
  CardTitle,
  ImageWrapper,
  CreateResumeImage,
  IconWrapper,
} from './styles'

type Props = {
  onClose: () => void
  onClick?: () => void
  customTitle?: string
  eventLabel?: string
}

const CreateNewResume = (props: Props) => {
  const { onClose, onClick, customTitle, eventLabel } = props
  const arrowStyles = {
    fill: Colors.Neutral40,
  }

  const config = useConfig()
  const useNewPhrases = config?.features.newStartScreen === 'old_design_new_text'

  const handleCardClick = () => {
    eventLabel
      ? trackInternalEvent('click_add_manually', { label: eventLabel })
      : trackInternalEvent(PrefillResumeEvents.clickCreateResume)
    onClick && onClick()
    onClose()
  }

  const title =
    customTitle ||
    (useNewPhrases
      ? I18n.t('builder.prefill_resume.options_view.options.create_new_resume.title')
      : I18n.t('builder.prefill_resume.create_resume'))
  const description = useNewPhrases
    ? I18n.t('builder.prefill_resume.options_view.options.create_new_resume.description')
    : I18n.t('builder.prefill_resume.choose_blank_template')

  return (
    <CreateNewResumeCard onClick={handleCardClick}>
      <ImageWrapper>
        <CreateResumeImage src={CreateResumeImg} alt="Create New Resume" />
      </ImageWrapper>
      <CardDescription>
        <CardTitle>{title}</CardTitle>
        <CardSubtitle>{description}</CardSubtitle>
      </CardDescription>
      <IconWrapper>
        <Icon24.ToggleArrow style={arrowStyles} />
      </IconWrapper>
    </CreateNewResumeCard>
  )
}

export default memo(CreateNewResume)
