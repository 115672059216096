/**
 * Match with project media queries
 */
export function matchMediaQueries(width = window.innerWidth) {
  return {
    hover: matchMedia ? matchMedia('(hover)').matches : true,
    isPhone: width <= 767,
    isTablet: width <= 1023,
    isLaptop: width <= 1199,
    isDesktop: width >= 1200,
    isDesktopWithDash: width >= 1450,
    isMidDesktop: width <= 1400,
  }
}
