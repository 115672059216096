import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 100%;
  max-width: 550px;
  background-color: ${Colors.White};
  border-radius: 6px;

  ${Media.Phone`
    max-width: none;
    height: 100%;
    padding: 20px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  `};
`
export const TopLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const Title = styled.div`
  ${Typography.S};
  ${FontWeights.Bold};
`

export const Content = styled.div`
  color: ${Colors.Neutral50};
  margin-bottom: 20px;
`

export const Bottom = styled.div`
  padding-top: 24px;
  display: flex;
  justify-content: end;
  align-items: baseline;
  gap: 8px;
`
