import styled, { css, keyframes } from 'styled-components'

import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

import { Icon20 } from 'builder/components/Icon'
import { MenuItemContent } from 'builder/components/Menu'

const slideIn = keyframes`
  from { transform: translateY(100%); }
  to { transform: translateY(0); }
`

const slideInColor = keyframes`
  from { background: rgba(30, 37, 50, 0); }
  to { background: rgba(30, 37, 50, 0.8); }
`

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: 1001;
  animation: ${slideInColor} 0.5s ease-in;
  background: rgba(30, 37, 50, 0.8);
`

export const Drawer = styled.div`
  position: fixed;
  bottom: 0;
  background: #fff;
  border-radius: 24px 24px 0 0;
  width: 100%;
  min-height: 550px;
  animation: ${slideIn} 0.5s ease-out;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 20px 16px 20px;
`

export const HeaderTitle = styled.div<{ isSubMenu?: boolean; isCareerProfile?: boolean }>`
  color: ${Colors.Neutral90};
  ${Typography.Subhead};
  ${FontWeights.Medium};

  ${({ isSubMenu }) =>
    isSubMenu &&
    css`
      margin: auto;
    `}

  ${({ isCareerProfile }) =>
    isCareerProfile &&
    css`
      color: ${Colors.Neutral50};
      ${Typography.Caps};
      ${FontWeights.DemiBold};
    `}
`

export const HeaderClose = styled(Icon20.Close)`
  margin-left: auto;
`

export const BackButton = styled.div`
  cursor: pointer;
`

export const HeaderBack = styled(Icon20.ChevronDown)`
  transform: rotate(90deg);
  path {
    fill: ${Colors.Neutral100};
  }
`

export const MenuItem = styled.div``
export const MenuItemCont = styled(MenuItemContent)`
  padding: 12px;
  ${Typography.Body};
  ${FontWeights.Medium};
`

export const SubMenuIcon = styled(Icon20.ChevronDown)`
  transform: rotate(-90deg);

  path {
    fill: ${Colors.Neutral50};
  }
`

export const ExternalIcon = styled(Icon20.ArrowLeft)`
  transform: rotate(135deg);
`

export const Label = styled.div`
  padding: 2px 4px;
  border-radius: 4px;
  background: ${Colors.Blue50};
  color: ${Colors.White};
  ${Typography.Tiny};
  ${FontWeights.DemiBold};
`

export const MenuContainer = styled.div``
