import { skillsRankTipsText } from 'builder/modules/sevenStories'
import { HintIcon, TipsContainer, TipsTitle, TooltipQuestionWrapper } from './styles'

const Tips = () => {
  return (
    <TooltipQuestionWrapper value={skillsRankTipsText} position="right" multiline>
      <TipsContainer className="tips-container">
        <TipsTitle>Tips</TipsTitle>
        <HintIcon className="hint-icon" />
      </TipsContainer>
    </TooltipQuestionWrapper>
  )
}

export default Tips
