import { useEffect, useCallback } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { SimpleLayout } from 'builder/components/SimpleLayout'
import CoRegistrationForm from 'builder/components/CoRegistration'
import CoRegistrationSuccess from 'builder/components/CoRegistration/Success'
import { actions, selectors } from 'builder/modules/coRegistration'

import { Container, Copyright } from './styles'
import { useNavigate, useParams } from 'react-router-dom'

const ResumeCoRegView = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const isInitialized = useSelector(selectors.isInitialized)
  const isSupported = useSelector(selectors.isSupported)
  const services = useSelector(selectors.services, shallowEqual)
  const unusedServices = useSelector(selectors.unusedServices, shallowEqual)
  const { isSending, isSent, errors } = useSelector(selectors.sendingState, shallowEqual)
  const isComplete = isSupported && (isSent || unusedServices.length === 0)

  const resumeId = parseInt(params.id, 10)
  const backUrl = `/resumes/${resumeId}/edit`

  const handleBack = useCallback(() => navigate(backUrl), [backUrl, navigate])
  const handleSubmit = useCallback(payload => dispatch(actions.connect(payload)), [dispatch])

  // dispatch mount/unmount handlers
  useEffect(() => {
    dispatch(actions.initialize())
    return () => dispatch(actions.close())
  }, [dispatch])

  // close page if feature is not supported
  useEffect(() => {
    if (isInitialized && !isSupported) handleBack()
  }, [isInitialized, isSupported, handleBack])

  return (
    <SimpleLayout onCloseClick={handleBack}>
      {!isComplete && (
        <Container>
          <CoRegistrationForm
            resumeId={resumeId}
            services={services}
            backUrl={backUrl}
            isSending={isSending}
            errors={errors}
            onSubmit={handleSubmit}
          />
          <Copyright>
            {services.map(service => (
              <div key={service.name} dangerouslySetInnerHTML={{ __html: service.disclaimer }} />
            ))}
          </Copyright>
        </Container>
      )}
      {isComplete && (
        <Container center>
          <CoRegistrationSuccess services={services} onBack={handleBack} />
        </Container>
      )}
    </SimpleLayout>
  )
}

export default ResumeCoRegView
