import { useMutation } from '@tanstack/react-query'
import { AxiosRequestConfig } from 'axios'
import { useDispatch } from 'react-redux'
import { baseClient } from 'builder/modules/apiClient'
import { actions } from 'builder/modules/autoApply/autoApplyModule'
import { AutoApplyForm, Profile } from 'builder/modules/autoApply/types'
import { mapCareerResponse } from './mappers'

export const useUploadResume = (
  onUploadProgress?: AxiosRequestConfig['onUploadProgress'],
  onSuccess?: (data: AutoApplyForm) => void,
) => {
  const dispatch = useDispatch()
  return useMutation({
    mutationFn: async (resume: File) => {
      dispatch(actions.uploadResume(resume))
      const response = await baseClient.post<Profile>(
        `/career/v1/profile/upload-resume`,
        {
          resume,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress,
        },
      )
      dispatch(actions.updateForm(response.data))
      return mapCareerResponse(response.data)
    },
    onSuccess,
  })
}
