import { FC } from 'react'
import { useSelector } from 'react-redux'
import { useI18n } from 'builder/hooks/useI18n'
import { selectors as interviewSelector } from 'builder/modules/interview/interviewModule'
import { UserData } from 'builder/modules/user'
import { ChatPersona } from 'builder/modules/careerProfile'
import { BottomCard } from '../BottomCard/BottomCard'
import { translationKey } from '../../constants'
import { ProfessionalPitchPreview } from '../previews/ProfessionalPitchPreview'
import { AiCoverLetterPreview } from '../previews/AiCoverLetterPreview'
import { InterviewPrepPreview } from '../previews/InterviewPrepPreview'
import { OptimizedResumePreview } from '../previews/OptimizedResumePreview'
import { useLinkClickHandlers } from '../../hooks/useLinkClickHandlers'
import { SectionHeader } from '../SectionHeader'
import * as Styled from './styles'
import bannerImageUrl from './images/take-action.png'

export const BottomCardList: FC<{
  user: UserData
  userType: ChatPersona
}> = ({ user, userType }) => {
  const { i18n } = useI18n()
  const {
    onPitchLinkClick,
    onCoverLetterLinkClick,
    onInterviewPrepLinkClick,
    onOptimizeResumeClick,
  } = useLinkClickHandlers('click_assessment_next_banners')

  const isPremiumUser = !!user.hasPremiumFeatures
  const interviewUrl = useSelector(interviewSelector.getInterviewUrl)

  return (
    <Styled.Container>
      <Styled.HeaderSection>
        <SectionHeader
          headerText={i18n.t(`${translationKey}.time_to_take_action`, { name: user.firstName })}
          description={i18n.t(`${translationKey}.action_section_by_user_type.${userType}`)}
          isReversed={true}
        >
          <Styled.HeaderImage src={bannerImageUrl} alt="Banner image" />
        </SectionHeader>
      </Styled.HeaderSection>

      <BottomCard
        headerText={i18n.t(`${translationKey}.bottom_cards.pitch_card.title`)}
        text={i18n.t(`${translationKey}.bottom_cards.pitch_card.description`)}
        buttonText={i18n.t(`${translationKey}.bottom_cards.pitch_card.button_text`)}
        buttonLink={isPremiumUser ? '/career-profile/pitch' : undefined}
        buttonClickHandler={!isPremiumUser ? onPitchLinkClick : undefined}
      >
        <ProfessionalPitchPreview />
      </BottomCard>
      <BottomCard
        headerText={i18n.t(`${translationKey}.bottom_cards.cover_letter_card.title`)}
        text={i18n.t(`${translationKey}.bottom_cards.cover_letter_card.description`)}
        buttonText={i18n.t(`${translationKey}.bottom_cards.cover_letter_card.button_text`)}
        buttonLink={isPremiumUser ? '/career-profile/cover-letter' : undefined}
        buttonClickHandler={!isPremiumUser ? onCoverLetterLinkClick : undefined}
      >
        <AiCoverLetterPreview />
      </BottomCard>
      <BottomCard
        headerText={i18n.t(`${translationKey}.bottom_cards.optimized_resume_card.title`)}
        text={i18n.t(`${translationKey}.bottom_cards.optimized_resume_card.description`)}
        buttonText={i18n.t(`${translationKey}.bottom_cards.optimized_resume_card.button_text`)}
        buttonClickHandler={onOptimizeResumeClick}
      >
        <OptimizedResumePreview />
      </BottomCard>
      <BottomCard
        headerText={i18n.t(`${translationKey}.bottom_cards.interview_prep_card.title`)}
        text={i18n.t(`${translationKey}.bottom_cards.interview_prep_card.description`)}
        buttonText={i18n.t(`${translationKey}.bottom_cards.interview_prep_card.button_text`)}
        buttonLink={interviewUrl}
        buttonClickHandler={!isPremiumUser ? onInterviewPrepLinkClick : undefined}
      >
        <InterviewPrepPreview />
      </BottomCard>
    </Styled.Container>
  )
}
