import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const Section = styled.div`
  height: 72px;
  border-radius: 16px;
  background: ${Colors.Neutral5};
`
export const EmptySection = styled.div`
  height: 240px;
  flex-shrink: 0;
  border-radius: 16px;
  background: linear-gradient(180deg, #f7f9fc 0%, rgba(247, 249, 252, 0) 42.81%);
`

export const EmptySectionText = styled.div`
  max-width: 396px;
  color: ${Colors.Neutral90};
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 32px auto;

  ${Media.Phone`
    max-width: 330px;
  `};
`
