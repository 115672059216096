import { Slider, SliderPointer, SlidePointersContainer } from './styles'

const SLIDES = ['QUESTIONNAIRE', 'SKILLS', 'JOB_LEVEL']

const CareerPath2Slider = ({ currentSlide = 'QUESTIONNAIRE' }): JSX.Element => {
  return (
    <Slider>
      <SlidePointersContainer>
        {SLIDES.map(slide => (
          <SliderPointer key={slide} highlight={currentSlide === slide} />
        ))}
      </SlidePointersContainer>
    </Slider>
  )
}

export default CareerPath2Slider
