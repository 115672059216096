import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Container = styled.div`
  width: 544px;
  height: 544px;
  overflow: hidden;
  display: flex;
  border-radius: 16px;

  ${Media.Tablet`
    height: 336px;
  `}

  ${Media.Phone`
    height: 208px;
    width: 340px;
  `}

  // Classes required for swiper container
  .swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
  }

  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
  }

  .swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;
  }
`

export const Overlay = styled.div<{ position: 'left' | 'right' }>`
  border-radius: 16px;
  position: absolute;
  width: 48px;
  z-index: 2;
  height: inherit;
  background: linear-gradient(90deg, #f7f9fc 0%, rgba(247, 249, 252, 0) 100%),
    linear-gradient(90deg, #f7f9fc 0%, rgba(247, 249, 252, 0) 100%);

  ${props =>
    props.position === 'right' &&
    css`
      transform: translateX(496px) rotate(180deg);

      ${Media.Phone`
        transform: translateX(292px) rotate(180deg);
      `}
    `}
`

export const SliderContainer = styled.div<{ isNext: boolean; active?: boolean }>`
  display: flex;
  min-width: 285px;
  justify-content: ${props => (props.isNext ? 'flex-start' : 'flex-end')};
  align-items: center;

  ${props =>
    css`
      ${Media.Tablet`
        height: 336px;
        align-items: ${props?.active ? 'normal' : 'baseline'};
      `}

      ${Media.Phone`
        height: 208px;
        min-width: 180px;
        align-items: ${props?.active ? 'normal' : 'baseline'};
      `}
    `}
`

export const Slide = styled.figure<{ active: boolean }>`
  margin: 32px 0;
  width: 285px;
  transform: scale(${props => (props.active ? 1 : 0.7)});
  transition: transform 0.8s ease;

  ${Media.Phone`
    margin: 19px 0px;
    min-width: 180px;
  `}
`

export const Caption = styled.figcaption<{ active?: boolean }>`
  text-align: center;
  padding-top: 32px;

  opacity: ${props => (props.active ? 1 : 0)};
  transition: opacity 0.5s ease;

  ${Media.Phone`
    padding-top: 19px;
  `}
`

export const CaptionHeader = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};

  ${Media.Phone`
    ${Typography.Tiny};
    ${FontWeights.Regular};
  `}
`

export const CaptionSubHeader = styled.div`
  ${Typography.Tiny};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};

  ${Media.Phone`
    font-size: 8.685px;
    font-style: normal;
    font-weight: 400;
    line-height: 9.926px;
    letter-spacing: 0.186px;
  `}
`

export const Image = styled.img<{ active?: boolean }>`
  width: inherit;
  border-radius: 1px;

  ${Media.Phone`
    width: 180px;
  `}

  ${props =>
    props.active &&
    css`
      box-shadow: 0px 16px 64px -8px rgba(15, 56, 113, 0.18),
        0px 6px 8px -2px rgba(15, 56, 113, 0.02), 0px 0px 1px 0px rgba(15, 56, 113, 0.32);
    `}
`
