import { trackInternalEvent } from '@rio/tracking'
import { selectors as userSelectors } from 'builder/modules/user'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

import AMAZON from './assets/amazon.png'
import APPLE from './assets/apple.png'
import GOOGLE from './assets/google.png'
import MICROSOFT from './assets/microsoft.png'
import META from './assets/meta.png'
import IBM from './assets/ibm.png'
import {
  EllipseOne,
  EllipseTwo,
  EllipseThree,
  ImageContainer,
  AvatarIcon,
  AvatarIconContainer,
  GoogleImage,
  AmazonImage,
  MetaImage,
  IbmImage,
  MicrosoftImage,
  AppleImage,
  CustomContainer,
} from './styles'
import { ErrorLayout } from './ErrorLayout'

export const CreateProfile = ({ onClick } : { onClick: () => void }) => {
  const photoUrl = useTypedSelector(userSelectors.photoUrl)

  return (
    <ErrorLayout
      Images={
        <ImageContainer>
          <EllipseOne />
          <EllipseTwo />
          <EllipseThree />
          <GoogleImage src={GOOGLE} alt="google" />
          <AmazonImage src={AMAZON} alt="amazon" />
          <MetaImage src={META} alt="meta" />
          <IbmImage src={IBM} alt="ibm" />
          <MicrosoftImage src={MICROSOFT} alt="microsoft" />
          <AppleImage src={APPLE} alt="apple" />
          <AvatarIconContainer>
            <AvatarIcon src={photoUrl} />
          </AvatarIconContainer>
        </ImageContainer>
      }
      translationKey="create_profile"
      onClick={() => {
        trackInternalEvent('click_get_started_career_profile')
        onClick()
      }}
      CustomContainer={CustomContainer}
      subtitleClassname="expanded"
      titleClassname="collapsed"
    />
  )
}
