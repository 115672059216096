import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'
import {
  TransactionContext,
  Transport,
  Transaction,
  Envelope,
  Primitive,
  Span as SpanInterface,
} from '@sentry/types'

const SENTRY_DSN_KEY = process.env.SENTRY_BUILDER_PERFORMANCE_LOGGER_DSN
const SENTRY_DSN_KEY_PROD = process.env.SENTRY_BUILDER_ERROR_LOGGER_DSN
const SENTRY_DSN_KEY_STAGING = process.env.SENTRY_BUILDER_ERROR_LOGGER_DSN_STAGING
class FakeTransport implements Transport {
  send(envelope: Envelope) {
    const [, events] = envelope
    const [, eventInfo] = events[0]

    if (typeof eventInfo === 'object' && 'type' in eventInfo && eventInfo.type === 'transaction') {
      const { transaction, start_timestamp: startTimestamp, timestamp, tags } = eventInfo
      if (startTimestamp && timestamp) {
        const duration = (timestamp - startTimestamp).toFixed(4)
        console.warn(`Performance Logger: Transaction ${transaction} took ${duration}sec`, tags)
      }
    }

    return Promise.resolve()
  }

  flush() {
    return Promise.resolve(true)
  }
}

const makeTransport = () => {
  if (process.env.NODE_ENV === 'development') return () => new FakeTransport()

  return Sentry.makeFetchTransport
}

type StatusCode = 'ok' | 'internal_error'
type Tags = Record<string, Primitive>

class PerformanceLogger {
  hub: Sentry.Hub
  transaction?: Transaction
  spans: { [key: string]: SpanInterface }

  constructor(customDSN?: string) {
    const client = new Sentry.BrowserClient({
      dsn: customDSN || SENTRY_DSN_KEY,

      integrations: [
        new Sentry.Integrations.HttpContext(),
        new BrowserTracing({
          startTransactionOnLocationChange: false,
          startTransactionOnPageLoad: false,
        }),
      ],

      environment: process.env.NODE_ENV,

      // add sampling for production
      tracesSampler: samplingContext => {
        if (process.env.NODE_ENV === 'development') return 1

        // Sample rate for server side rendering is bigger because happens less frequently
        if (samplingContext.transactionContext?.name === 'server-rendering') return 0.01

        if (
          ['interview-dashboard-page', 'interview-feedback-page', 'interview-player-page'].includes(
            samplingContext.transactionContext?.name,
          )
        )
          return 0.5

        // Default sample rate
        return 0.00125
      },

      // use fake transport in development
      transport: makeTransport(),
      stackParser: Sentry.defaultStackParser,
      beforeSend(event) {
        return event // Returning `null` prevents the event from being sent
      },
    })

    const hub = new Sentry.Hub(client)

    this.hub = hub
    this.spans = {}
  }

  setUser({ id, email }: Sentry.User) {
    this.hub.run(() => {
      Sentry.setUser({ id, email })
    })
  }

  listen(info: TransactionContext) {
    this.hub.run(currentHub => {
      this.transaction = currentHub.startTransaction(info)
    })
  }

  skipCurrent() {
    this.hub.run(() => {
      if (this.transaction) {
        // manually drop transaction
        this.transaction.sampled = false
      }
    })
  }

  operation(op: string, config = {}) {
    this.hub.run(() => {
      if (this.transaction && !this.spans[op]) {
        this.spans[op] = this.transaction.startChild({ op, ...config })
      }
    })
  }

  operationEnd(op: string) {
    this.hub.run(() => {
      if (this.transaction && this.spans[op]) {
        this.spans[op].finish()
        delete this.spans[op]
      }
    })
  }

  operationFull(config: any = {}) {
    this.hub.run(() => {
      if (this.transaction && !this.spans[config.key]) {
        this.spans[config.key] = this.transaction.startChild(config)
      }
    })
  }

  finish(info: { status?: StatusCode; tags?: Tags } = {}) {
    return new Promise(resolve => {
      this.hub.run(() => {
        if (this.transaction) {
          if (info.status) {
            this.transaction.setStatus(info.status)
          }

          if (info.tags) {
            const { tags = {}, ...rest } = this.transaction.toContext()
            this.transaction.updateWithContext({
              ...rest,
              tags: { ...tags, ...info.tags },
            })
          }

          for (const spanId of Object.keys(this.spans)) {
            this.operationEnd(spanId)
          }

          this.transaction.finish()
          delete this.transaction

          this.spans = {}
          resolve(undefined)
        }
      })
    })
  }
}

const getDSN = () => {
  if (process.env.NODE_ENV === 'staging') {
    return SENTRY_DSN_KEY_STAGING
  }
  if (process.env.NODE_ENV === 'production') {
    return SENTRY_DSN_KEY_PROD
  }
}
const PerformanceLoggerWithMainDsn = new PerformanceLogger(getDSN())

export { PerformanceLogger, PerformanceLoggerWithMainDsn }
export default new PerformanceLogger()
