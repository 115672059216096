export enum CareerPathStep {
  QUESTIONNAIRE,
  SKILLS,
  JOB_PROGRESSION,
  ADD_TO_PATH,
  CONFIRM_MY_PATH,
  SUCCESS,
  VIEW_CARDS,
  CHANGE_MY_PATH,
}

export const LEVELS = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
}

export const Label = {
  label: 'career_path2',
}

export const SKILLS_WIDTH_PERCENTAGE = 50

export const COLORS = ['green', 'amber', 'red']

export const selectOccupationFieldText =
  'Please, try another occupation and select an option from the list'

export const selectLocationFieldText =
  'Please, try another location and select an option from the list'

export const selectBothFieldsText =
  'Try another occupation, location and select an option from the list'

export const requiredFieldText = 'This field is required'

export const TABS = ['Overview', 'Salary']

export const SUCCESS_MESSAGE = 'Job Card has been saved to Job Tracker'

export const TOP_PAYING_TABS = ['City', 'States']

// Companies logo URL for Companies Hiring section
export const logoURL = 'https://staging.resume.io/logo?name='
