import styled from 'styled-components'
import { useState } from 'react'
import * as Grid from 'builder/components/Grid'
import RichTextArea from 'builder/components/RichTextArea/RichTextArea'
import { TiptapEditor } from 'builder/components/TiptapEditor/Editor'
import { Section } from '../../Section'

const Code = styled.code`
  font-family: 'Fira Code', monospace;
  font-size: 12px;
  color: #222;
  padding: 12px;
  background-color: #fafafa;

  display: block;
  overflow: auto;
`

type Props = {
  editor: typeof TiptapEditor
  label?: string
  placeholder?: string
}

export const EditorWithResult = ({ editor: Editor, label = '', placeholder = '' }: Props) => {
  const [html, setHtml] = useState('')

  return (
    <>
      <Editor label={label} placeholder={placeholder} value={html} onChange={setHtml} />

      <Code>
        <pre>{html}</pre>
      </Code>
    </>
  )
}

const placeholder = 'Enter some text...'
export const RichTextEditorSection = () => {
  return (
    <Section title="Rich text editor">
      <Grid.Container>
        <Grid.Cell desktop={6} tablet={4} phone={4}>
          <EditorWithResult editor={RichTextArea} label="Draft js" placeholder={placeholder} />
        </Grid.Cell>
        <Grid.Cell desktop={6} tablet={4} phone={4}>
          <EditorWithResult editor={TiptapEditor} label="Tiptap" placeholder={placeholder} />
        </Grid.Cell>
      </Grid.Container>
    </Section>
  )
}
