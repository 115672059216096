export const ABOUT_SESSIONS = [
  {
    id: 1,
    label: 'Find Your Perfect Coach',
    description: 'Complete our 3-minute onboarding form so we can find the ideal coach for you.',
  },
  {
    id: 2,
    label: 'Select Your Package and Make a Payment',
    description: 'Choose a package that aligns with your needs and proceed with payment.',
  },
  {
    id: 3,
    label: 'Handpicked Coaches Just for You',
    description: 'Choose from a curated list of coaches specifically selected for you.',
  },
  {
    id: 4,
    label: 'Book Your First Coaching Session',
    description:
      'Schedule your initial 50-minute session with your selected coach and embark on your transformative journey.',
  },
]
