import { Optional, ProfilePicture, ResumeSkillLevel } from 'packages/types'
import { RecommendationsRequiredAttributesType } from 'builder/components/FindJob/types'
import { FetchStatuses } from '../constants'
import { BriefResume } from '../panel'

export interface Answer {
  id: string
  text: string
  time?: number
}

export interface Question {
  id: string
  text: string
  hint?: string
  response?: Question
  time?: number
}

export type ChatPersona = 'explorer' | 'starter' | 'hurdler' | 'job_seeker'

export type PaywallModalType = 'pitch' | 'cover-letter' | 'interview-prep'

export type CareerInsightsPageType = 'insights' | 'pitch' | 'cover-letter'

export type TemporalModalType = 'insights-result' | 'promotion'

export interface QuestionFromApiResponse {
  question: string
  hint: string
  response: string
}

export interface QuestionListApiResponse {
  assessmentQuestions: QuestionFromApiResponse[]
}

export interface ResumeParserApiResponse {
  firstName: string
  middleName: string
  lastName: string
  summary: string
  pdf: string
  location: {
    formatted: string
    street: string
    city: string
    state: string
    country: string
    countryCode: string
  }
  emails: string[]
  profession: string
  positions: {
    title: string
    titleNormalized: string
    organization: string
    startDate: string
    endDate: string
    description: string
    location: {
      formatted: string
      street: string
      city: string
      state: string
      country: string
      countryCode: string
    }
    managementLevel: string
  }[]
  educations: {
    organization: string
    degree: string
    startDate: string
    endDate: string
    location: {
      formatted: string
      street: string
      city: string
      state: string
      country: string
      countryCode: string
    }
    educationLevel: string
  }[]
  socialUrls: string[]
  phoneNumbers: {
    countryCode: string
    countryName: string
    nationalNumber: string
  }[]
  languages: string[]
  detectedLanguage: string
  skills: {
    name: string
    numMonths: number
  }[]
  rawText: string
  error?: string
}

export interface ChatbotResultData {
  name: string
  userType: ChatPersona
  questionAnswers: Record<string, string>
  cvText: string
  currentJobPosition?: string
  targetPosition?: string
  includeCoverLetter?: boolean // true by default in backend
  cvStructured: StructuredResumeData
}

export enum CareerInsightsProcessingStatus {
  success = 'success',
  failure = 'failure',
  retry = 'retry',
  pending = 'pending',
}

export type CareerInsightsJobListData = {
  jobTitle: string[]
  salaryRange: string[]
  jobDescription: string[]
}

export type CareerInsightsSkillListData = {
  skill: string[]
  softHard: string[]
  coursesRecommendation: string[]
}

export type CareerAssessmentsResults = {
  careerPaths: string | undefined
  nextMove: string | undefined
  transferableSkills: string | undefined
  skillsCourses: string | undefined
  dataForGraphics: {
    progressionList: CareerInsightsJobListData
    transferrableSkillsList: CareerInsightsJobListData
    skillCoursesList: CareerInsightsSkillListData
  }
}

export type ProfessionalPitchResults = {
  diff: string
  close: string
  results: string
  positioning: string
}

export type CoverLetterResults = {
  close: string
  pitch: string
  results: string
  introduction: string
}

export type CareerInsightsData = {
  careerAssessmentId: number
  status: CareerInsightsProcessingStatus
  careerAssessmentResults: CareerAssessmentsResults | null
  professionalPitchResults: ProfessionalPitchResults | null
  coverLetterResults: CoverLetterResults | null
  currentJobPosition: string
}

export type CareerProfileCompletenessPendingKey = 'target_role' | 'career_assessment'

export type CareerProfileCompletenessPendingItem = {
  key: CareerProfileCompletenessPendingKey
  value: number
}

export type CareerProfileCompletenessApiResponse = {
  completenessState: number
  completenessPending: CareerProfileCompletenessPendingItem[]
}

export type CareerProfileWorkExperienceData = {
  id: Optional<number | string>
  title?: Optional<string>
  employer?: Optional<string>
  city?: Optional<string>
  dateFrom?: Optional<string>
  dateUntil?: Optional<string>
  description?: Optional<string>
}

export type CareerProfileSkillData = {
  id: Optional<number | string>
  skill?: Optional<string>
  level?: Optional<ResumeSkillLevel>
}

export type CareerProfileEducationData = {
  id: Optional<number | string>
  school?: Optional<string>
  degree?: Optional<string>
  city?: Optional<string>
  dateFrom?: Optional<string>
  dateUntil?: Optional<string>
  description?: Optional<string>
}

export type CareerProfileCourseData = {
  id: Optional<number | string>
  course?: Optional<string>
  institution?: Optional<string>
  dateFrom?: Optional<string>
  dateUntil?: Optional<string>
}

export type CareerProfileData = {
  id: Optional<number>
  email: string
  location: string
  hideSkillLevel: boolean
  targetRole: string | null
  targetLocations: string[] | null
  profilePicture: ProfilePicture
  workExperiencesAttributes: CareerProfileWorkExperienceData[]
  skillsAttributes: CareerProfileSkillData[]
  educationsAttributes: CareerProfileEducationData[]
  coursesAttributes: CareerProfileCourseData[]
  newProfile: boolean
  autoApplyDataCompleted: boolean
} & Readonly<{
  userType: ChatPersona
}>

export type CareerProfileCardsType = Extract<
  keyof CareerProfileData,
  'workExperiencesAttributes' | 'skillsAttributes' | 'educationsAttributes' | 'coursesAttributes'
>

export class UpdateCareerProfileCardPayload<
  T extends CareerProfileCardsType = CareerProfileCardsType,
> {
  fieldName: T
  cardUpdate: {
    id: number
  } & Partial<CareerProfileData[T][number]>
}

export class MoveCareerProfileCardPayload<
  T extends CareerProfileCardsType = CareerProfileCardsType,
> {
  fieldName: T
  oldIndex: number
  newIndex: number
}

export class DeleteCareerProfileCardPayload<
  T extends CareerProfileCardsType = CareerProfileCardsType,
> {
  fieldName: T
  id: number
}

export type StructuredResumeData = {
  workExperiencesAttributes: Omit<CareerProfileWorkExperienceData, 'id'>[]
  skillsAttributes: Omit<CareerProfileSkillData, 'id'>[]
  educationsAttributes: Omit<CareerProfileEducationData, 'id'>[]
  coursesAttributes: Omit<CareerProfileCourseData, 'id'>[]
}

export interface CareerProfileStore {
  chat: {
    fetchQuestionsStatus: FetchStatuses
    fetchSelectedResumeStatus: FetchStatuses
    questions: Question[]
    asked: Question[]
    answers: Answer[]
    selectedResumeText: string | null
    selectedResume: BriefResume | null
    structuredResumeData: StructuredResumeData | null
    latestResume: BriefResume | null
    persona: ChatPersona | null
    isDataSentDuringCurrentSession: boolean
    uploadedFilename: string | null
  }
  careerInsights: {
    fetchCareerInsightsStatus: FetchStatuses
    data: CareerInsightsData | null
  }
  openedPaywallModalType: PaywallModalType | null
  isOptimizeResumeModalOpened: boolean
  completeness: {
    fetchStatus: FetchStatuses
    percent: number | null
    pendingItems: CareerProfileCompletenessPendingItem[]
    isResultClosed: boolean
  }
  seenCareerProfilePages: Record<CareerInsightsPageType, boolean>
  profile: {
    fetchStatus: FetchStatuses
    data: CareerProfileData | null
    isPhotoUploading: boolean
  }
  isSyncedWithLocalStorage: boolean
  careerInsightsStatusModalType: CareerInsightsPageType | null
  seenTemporalModals: Record<TemporalModalType, boolean>
  floatingHelperPromotionDays: string[] // array of string dates
  autoApply: {
    isComplete: boolean
    fetchStatus: FetchStatuses
  }
  mobileProfileMenu: boolean
  onboarding: {
    isNewProfile: boolean
    fetchIsNewProfileStatus: FetchStatuses
    structuredResumeData: StructuredResumeData | null
    wasProfilePrefilled: boolean
  }
  recommendations: {
    missingAttributes: RecommendationsRequiredAttributesType[]
  }
}

export type LocalStorageCareerProfileState = Pick<
  CareerProfileStore,
  'chat' | 'seenCareerProfilePages' | 'seenTemporalModals' | 'floatingHelperPromotionDays'
> & {
  completeness: Pick<CareerProfileStore['completeness'], 'isResultClosed'>
}
