import { useDispatch } from 'react-redux'
import { memo, useEffect, useState } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { selectors, actions } from 'builder/modules/careerCoaching'
import { selectors as userSelectors } from 'builder/modules/user'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import CoachDetails from 'builder/components/CoachDetails'
import ModalOverlay from 'builder/components/ModalOverlay'
import CoachConfirmationModal from 'builder/components/CoachConfirmationModal'
import { trackingInfo } from 'builder/modules/careerCoaching/constants'
import { FetchStatuses } from 'builder/modules/constants'
import { ButtonSize } from 'builder/components/Button'
import {
  Container,
  MainContent,
  Close,
  CloseIcon,
  ModalHeading,
  Title,
  StyledButton,
  ModalFooter,
} from './styles'

interface Props {
  handleModalClose: () => void
}

const CoachDetailsView = ({ handleModalClose }: Props): JSX.Element => {
  const dispatch = useDispatch()
  const user = useTypedSelector(userSelectors.userData)
  const isCoachAssignLoaded = useTypedSelector(selectors.isCoachAssignLoaded)
  const isCoachAssignLoading = useTypedSelector(selectors.isCoachAssignLoading)

  const [showConfirmation, setShowConfirmation] = useState(false)

  const coaches = useTypedSelector(selectors.coaches)
  let coachDetails = useTypedSelector(selectors.coachDetails)

  coaches?.forEach(coach => {
    if (coach.id.toString() === localStorage.getItem('coachId')) {
      coachDetails = coach
    }
  })

  const handleConfirmationModalOpen = () => {
    trackInternalEvent('click_button_choose_coach_profile_modal', {
      ...trackingInfo,
      coach_name: `${coachDetails.firstName} ${coachDetails.lastName}`,
    })

    const params = {
      coachId: coachDetails.id,
      participantId: user?.id,
    }
    dispatch(actions.fetchCoachConfirmation(params))
  }

  useEffect(() => {
    if (isCoachAssignLoaded) {
      setShowConfirmation(true)

      // Set to default status
      dispatch(actions.setAssignCoachStatus(FetchStatuses.notAsked))
    }
  }, [dispatch, isCoachAssignLoaded])

  const handleConfirmationModalClose = () => {
    trackInternalEvent('click_okay_coach_assigned', {
      ...trackingInfo,
      coach_name: `${coachDetails.firstName} ${coachDetails.lastName}`,
    })

    setShowConfirmation(false)
    handleModalClose()
  }

  return (
    <>
      {showConfirmation ? (
        <CoachConfirmationModal
          coachDetails={coachDetails}
          handleConfirmationModalClose={handleConfirmationModalClose}
        />
      ) : (
        <ModalOverlay overlayFadeDuration={150} contentSlideDuration={0}>
          <Container data-testid="coach-details-view">
            <ModalHeading>
              <Title>Coach Details</Title>
              <Close
                data-testid="close-button"
                onClick={() => {
                  handleModalClose()
                  trackInternalEvent('close_coach_profile_modal', {
                    ...trackingInfo,
                    coach_name: `${coachDetails.firstName} ${coachDetails.lastName}`,
                  })
                }}
              >
                <CloseIcon />
              </Close>
            </ModalHeading>
            <MainContent>
              <CoachDetails />
            </MainContent>
            <ModalFooter>
              <StyledButton
                isDisabled={isCoachAssignLoading}
                onClick={handleConfirmationModalOpen}
                size={ButtonSize.small}
              >
                Choose Coach
              </StyledButton>
            </ModalFooter>
          </Container>
        </ModalOverlay>
      )}
    </>
  )
}

export default memo(CoachDetailsView)
