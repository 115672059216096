import { throttle } from 'lodash'
import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useEffectOnMount } from './useEffectOnMount'

type Props<T extends HTMLElement> = {
  elementRef: RefObject<T>
  onDragStart?: () => void
  onDragEnd?: (event: DragData) => void
}

type PositionData = {
  x: number
  y: number
}

export type DragData = PositionData & {
  deltaX: number
  deltaY: number
}

const calculatePositionData = (initial: PositionData, current: PositionData) => {
  const deltaX = current.x - initial.x
  const deltaY = current.y - initial.y
  return { x: current.x, y: current.y, deltaX, deltaY }
}

export const useElementDrag = <T extends HTMLElement>(props: Props<T>) => {
  const { elementRef, onDragStart, onDragEnd } = props
  const [isDragging, setIsDragging] = useState(false)
  const [initialPosition, setInitialPosition] = useState({ x: 0, y: 0 })
  const [draggingPosition, setDraggingPosition] = useState({ x: 0, y: 0 })
  const initialPositionRef = useRef({ x: 0, y: 0 })
  const currentPositionRef = useRef({ x: 0, y: 0 })

  const handleMouseMove = useMemo(
    () =>
      throttle(
        (event: MouseEvent | TouchEvent) => {
          const touch = 'touches' in event ? event.touches[0] : event
          setDraggingPosition({ x: touch.clientX, y: touch.clientY })
        },
        50,
        { leading: true, trailing: true },
      ),
    [],
  )

  const handleMouseUp = useCallback(() => {
    setIsDragging(false)

    if (initialPositionRef.current && currentPositionRef.current && onDragEnd) {
      onDragEnd(calculatePositionData(initialPositionRef.current, currentPositionRef.current))
    }

    document.removeEventListener('pointermove', handleMouseMove)
    document.removeEventListener('touchmove', handleMouseMove)
    document.removeEventListener('pointerup', handleMouseUp)
    document.removeEventListener('touchend', handleMouseUp)
    document.removeEventListener('touchcancel', handleMouseUp)
  }, [onDragEnd, handleMouseMove])

  const handleMouseDown = useCallback(
    (event: MouseEvent) => {
      const initialPosition = { x: event.clientX, y: event.clientY }
      setIsDragging(true)
      setDraggingPosition(initialPosition)
      setInitialPosition(initialPosition)
      onDragStart?.()

      document.addEventListener('pointermove', handleMouseMove)
      document.addEventListener('touchmove', handleMouseMove)
      document.addEventListener('pointerup', handleMouseUp)
      document.addEventListener('touchend', handleMouseUp)
      document.addEventListener('touchcancel', handleMouseUp)
    },
    [onDragStart, handleMouseMove, handleMouseUp],
  )

  useEffect(() => {
    currentPositionRef.current = draggingPosition
  }, [draggingPosition])

  useEffect(() => {
    initialPositionRef.current = initialPosition
  }, [initialPosition])

  useEffect(() => {
    const element = elementRef.current
    if (!element) return

    element.addEventListener('pointerdown', handleMouseDown)

    return () => {
      element.removeEventListener('pointerdown', handleMouseDown)
    }
  }, [handleMouseDown, elementRef])

  useEffectOnMount(() => {
    return () => {
      console.log('destroy')
      document.removeEventListener('pointermove', handleMouseMove)
      document.removeEventListener('touchmove', handleMouseMove)
      document.removeEventListener('pointerup', handleMouseUp)
      document.removeEventListener('touchend', handleMouseUp)
      document.removeEventListener('touchcancel', handleMouseUp)
    }
  })

  return { isDragging, draggingPosition: calculatePositionData(initialPosition, draggingPosition) }
}
