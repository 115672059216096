import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import { BAR_HEIGHT, Z_INDEX } from '../Navigation/constants'

export const Container = styled.div`
  position: fixed;
  z-index: ${Z_INDEX};
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${Colors.White};
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: ${BAR_HEIGHT}px;
  padding: 0 30px;
  width: 100%;
`

export const RightColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 280px);

  ${Media.Tablet`
    width: calc(100% - 250px);
  `};
`
