import Button from 'builder/components/Button'
import { useI18n } from 'builder/hooks/useI18n'

import { Container, Title, Subtitle } from './styles'

interface Props {
  translationKey: string
  Images: React.ReactNode
  onClick: () => void
  CustomContainer?: React.FunctionComponent
  subtitleClassname?: string
  titleClassname?: string
}

export const ErrorLayout = ({
  Images,
  translationKey,
  onClick,
  CustomContainer,
  subtitleClassname,
  titleClassname,
}: Props) => {
  const { i18n } = useI18n()

  const Cont = CustomContainer || Container

  return (
    <Cont>
      {Images}
      <Title className={titleClassname}>
        {i18n.t(`builder.job_search.recommendations.${translationKey}.title`)}
      </Title>
      <Subtitle className={subtitleClassname}>
        {i18n.t(`builder.job_search.recommendations.${translationKey}.subtitle`)}
      </Subtitle>
      <Button onClick={onClick}>
        {i18n.t(`builder.job_search.recommendations.${translationKey}.button`)}
      </Button>
    </Cont>
  )
}
