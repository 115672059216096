import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useConfig } from 'builder/hooks/useConfig'
import { useI18n } from 'builder/hooks/useI18n'
import { ConfigScopesEnum } from 'builder/modules/init'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as userSelectors, UserType } from 'builder/modules/user'
import { selectors as interviewSelector } from 'builder/modules/interview/interviewModule'
import { FeatureIconName } from 'builder/components/FeatureIcon'
import { useMediaQueries } from './useMediaQueries'
import { useFeaturesConfig } from './featureConfig/useFeaturesConfig'

export type FeatureCategoryName = 'documents' | 'development' | 'jobs' | 'goals' | 'expert_content'

export type FeatureCard = {
  enabled: boolean
  to?: string
  paths?: string[]
  categoryName: FeatureCategoryName
  name: string
  iconName?: FeatureIconName | ''
  title: string
  text: string
  isBeta?: boolean
  isLeftColumn?: boolean
  eventTarget?: string
  isPremiumService?: boolean
  cioDomainUrl?: string
  isCareerURL?: boolean
  isPostPremiumActive?: boolean
  isInternationalization?: boolean
}

type RawFeatureCard = Omit<FeatureCard, 'title' | 'text'>

// TODO update or remove unused features
export const useFeatureCards = (): FeatureCard[] => {
  const config = useConfig(ConfigScopesEnum.app)
  const features = useFeaturesConfig()
  const { i18n } = useI18n()
  const { isDesktop } = useMediaQueries()
  const userType = useTypedSelector(userSelectors.postPremium)
  const interviewUrl = useSelector(interviewSelector.getInterviewUrl)

  return useMemo(() => {
    if (!config) return []

    const rawFeatures: RawFeatureCard[] = [
      // Document
      {
        enabled: features?.showResumeDistribution(),
        categoryName: 'documents',
        name: 'resume_distribution',
        to: '/resume-distribution',
        iconName: 'ResumeDistribution',
        isLeftColumn: isDesktop,
        isPremiumService: false,
        eventTarget: 'resume_distribution',
      },
      {
        enabled: true,
        categoryName: 'documents',
        name: 'documents',
        to: '/resumes',
        paths: ['/resumes', '/cover-letters'],
        iconName: 'Documents',
        isLeftColumn: isDesktop,
        eventTarget: 'documents',
        isPremiumService: false,
      },
      {
        enabled: features.features.resumeWriting,
        to: 'https://topresume.com/resume-writing?pt=UWYxd6Xe2VTS0&utm_source=careerio&utm_medium=ciomenu&utm_campaign=tr_promo_in_ciomenu&utm_content=resume_writing_services',
        categoryName: 'documents',
        name: 'pro_resume_writing_latest',
        iconName: 'ProResumeWriting',
        isLeftColumn: isDesktop,
        eventTarget: 'pro_resume_writing',
        isPremiumService: false,
        cioDomainUrl:
          'https://topresume.com/resume-writing?pt=UWYxd6Xe2VTS0&utm_source=resumeio&utm_medium=riomenu&utm_campaign=tr_promo_in_riomenu&utm_content=resume_writing_services',
      },

      // Jobs
      {
        enabled: features.features.jobSearch,
        categoryName: 'jobs',
        name: 'job_search',
        to: `/job-search?view=recommendation`,
        iconName: 'JobSearch',
        isLeftColumn: false,
        eventTarget: 'job_search',
        isPremiumService: true,
      },
      {
        enabled: features.features.jobTracking,
        categoryName: 'jobs',
        name: 'job_tracking',
        to: '/job-tracking',
        iconName: 'JobTracker',
        isLeftColumn: false,
        eventTarget: 'job_tracking',
        isPremiumService: true,
        isPostPremiumActive: userType === UserType.postPremium,
      },
      {
        enabled: features.features.salaryAnalyzer,
        categoryName: 'jobs',
        name: 'salary_analyzer',
        to: '/offer-analyzer',
        paths: ['/offer-analyzer', '/offer-analyzer-result'],
        iconName: 'SalaryAnalyzer',
        isLeftColumn: false,
        eventTarget: 'salary_analyzer',
        isPremiumService: true,
        isPostPremiumActive: userType === UserType.postPremium,
      },
      {
        enabled: features.features.virtualHiringEvents,
        categoryName: 'jobs',
        name: 'virtual_hiring_events',
        to: '/my-events/',
        isCareerURL: true,
        iconName: 'Events',
        isBeta: true,
        isLeftColumn: false,
        eventTarget: 'virtual_events',
        isPremiumService: true,
      },
      {
        enabled: features.features.jobSearchPlan,
        categoryName: 'jobs',
        name: 'job_search_strategy',
        to: '/career-plans/execute-a-job-search',
        iconName: 'JobSearchStrategy',
        isLeftColumn: false,
        eventTarget: 'job_search_strategy',
        isPremiumService: true,
        isPostPremiumActive: userType === UserType.postPremium,
      },

      // Development

      {
        enabled: features.features.interviewSchool,
        categoryName: 'development',
        name: 'interview_prep',
        to: interviewUrl,
        iconName: 'InterviewPrep',
        isLeftColumn: false,
        eventTarget: 'interview_prep',
        isPremiumService: true,
        isPostPremiumActive: userType === UserType.postPremium,
      },
      {
        enabled: features.features.careerCoaching,
        categoryName: 'development',
        name: 'career_coaching',
        to: '/career-coaching',
        iconName: 'CareerCoaching',
        isLeftColumn: false,
        eventTarget: 'career_coaching',
        isPremiumService: true,
      },
      {
        enabled: features.features.careerPlans,
        categoryName: 'development',
        name: 'career_planning',
        to: '/career-plans',
        iconName: 'Planning',
        isLeftColumn: false,
        eventTarget: 'career_planning',
        isPremiumService: true,
      },

      // Goals
      {
        enabled: config?.features.careerProfile,
        categoryName: 'goals',
        name: 'career_assessment',
        to: '/career-profile/insights',
        iconName: 'CareerAssessment',
        isBeta: true,
        isLeftColumn: false,
        eventTarget: 'career_profile',
        isPremiumService: false,
      },
      {
        enabled: features.features.careerPath,
        categoryName: 'goals',
        name: 'career_path',
        to: '/career-path',
        iconName: 'CareerPath',
        isBeta: true,
        isLeftColumn: false,
        eventTarget: 'career_path',
        isPremiumService: true,
      },
      {
        enabled: features.features.careerExplorer,
        categoryName: 'goals',
        name: 'explore_careers',
        to: '/explore-careers',
        iconName: 'ExploreCareers',
        isBeta: true,
        isLeftColumn: false,
        eventTarget: 'explore_careers',
        isPremiumService: true,
      },

      // Expert Content
      {
        enabled: features.features.careerAdvice,
        categoryName: 'expert_content',
        name: 'career_advice',
        to: '/career-advice/',
        isCareerURL: true,
        iconName: 'Resource',
        isBeta: true,
        isLeftColumn: false,
        eventTarget: 'professional_resources',
        isPremiumService: true,
      },
    ]

    return rawFeatures
      .filter(feature => feature.enabled)
      .map(feature => ({
        ...feature,
        title: i18n.t(`builder.navigation.items.${feature.name}.title`),
        text: i18n.t(`builder.navigation.items.${feature.name}.description`),
      }))
  }, [config, features, i18n, isDesktop, userType])
}
