import { useEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { TunerNewScore } from '../TunerNewScore'
import bannerImage from './bannerImage.svg'
import {
  BannerImage,
  BannerSection,
  ImageScoreSection,
  MainSection,
  MainSectionText,
  MainSectionTitle,
} from './styles'

type WishlistBannerProps = {
  onClick: (event: React.MouseEvent) => void
}

export const WishlistBanner = ({ onClick }: WishlistBannerProps) => {
  const { i18n } = useI18n()

  useEffect(() => {
    trackInternalEvent('view_banner_optimizer')
  }, [])

  return (
    <BannerSection onClick={onClick}>
      <MainSection>
        <MainSectionText>
          <MainSectionTitle>
            {i18n.t('builder.job_tracking.tailor_your_resume_banner.title')}
          </MainSectionTitle>
        </MainSectionText>
        <ImageScoreSection>
          <TunerNewScore score={39} />
          <BannerImage src={bannerImage} />
        </ImageScoreSection>
      </MainSection>
    </BannerSection>
  )
}
