import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Button } from 'builder/components/Button'
import Media from 'builder/styles/media'

export const Container = styled.div`
  width: 656px;
  height: 100vh;
  border-radius: 6px;
  background: ${Colors.White};
  position: absolute;
  right: 0;
  box-shadow: 0px 6px 8px -2px rgba(15, 56, 113, 0.02), 0px 16px 64px -8px rgba(15, 56, 113, 0.18);
  z-index: 999;
  margin-top: -80px;

  ${Media.Phone`
  width: 80%;
`};
`

export const PaddedContainer = styled.div`
  padding: 32px 32px 0px 32px;
`

export const Header = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`

export const Back = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
  left: 32px;
  top: 32px;
  background: ${Colors.Neutral5};
  border-radius: 40px;
  padding: 7px;
`

export const BackIcon = styled.img`
  padding: 7px;
`

export const Title = styled.div`
  display: flex;
  ${Typography.Subhead};
  ${FontWeights.Medium};
  color: ${Colors.Neutral100};
  margin: 16px 0;
`

export const SubTitle = styled.div`
  display: flex;
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
  margin-bottom: 25px;
`

export const ResumeListBlock = styled.div`
  overflow-y: auto;
  padding: 0px 20px 20px 30px;
  height: calc(100vh - 220px);
  max-height: 100%;

  &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${Colors.Neutral20};
    border: none;
    border-radius: 4px;
    border: 2px solid ${Colors.Neutral10};
    transition: border-color 0.1s ease;

    &:hover {
      background-color: ${Colors.Neutral30};
    }
  }
`

export const ResumeListItem = styled.div<{ isActive: boolean }>`
  display: flex;
  border: 1px solid ${Colors.Neutral15};
  border-radius: 8px;
  padding: 8px;
  background: ${Colors.White};
  margin-bottom: 8px;
  cursor: pointer;

  background: ${props => (props.isActive ? Colors.Blue10 : Colors.White)};

  &:focus,
  &:hover {
    background: ${Colors.Blue10};
  }
`

export const ResumeImage = styled.img`
  cursor: pointer;
`

export const ResumeText = styled.div`
  padding: 0px 15px;
`

export const ResumeTitle = styled.h2`
  ${Typography.Body};
  color: ${Colors.Neutral90};
  margin-bottom: 0;
`

export const ResumeDate = styled.span`
  color: ${Colors.Neutral50};
  ${Typography.Caption};
`

export const ButtonContainer = styled.div`
  border-top: 1px solid ${Colors.Neutral15};
  position: fixed;
  bottom: 25px;
  background: #fff;
  width: 100%;
  display: block;
`

export const TailorResumeButton = styled(Button)`
  top: 15px;
  left: 30px;
`
