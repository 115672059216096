import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { i18n as I18n } from 'builder/utils/i18n'
import { Icon20 } from 'builder/components/Icon'

import {
  ServiceContainer,
  ServiceInner,
  ServiceHead,
  ServiceLogo,
  ServiceContent,
  ServiceTitle,
  ServiceStatus,
  ServiceText,
  ServiceToggle,
  ServiceCheckbox,
  ServiceWebsites,
  ServiceWebsite,
  ServiceWebsiteLogo,
  ServiceWebsiteTitle,
} from './styles'

const Service = ({
  name,
  title,
  domain,
  checked,
  connected,
  theme,
  websites,
  onToggle,
  disabled,
  ...rest
}) => {
  const state = useMemo(() => {
    if (connected && !onToggle) return 'complete'
    if (connected && !disabled) return 'connected'
    if (checked && !disabled) return 'checked'
    if (disabled) return 'disabled'
    return 'unchecked'
  }, [checked, connected, disabled, onToggle])

  const text = useMemo(() => {
    if (!connected) return I18n.t(`builder.coreg.services.${name}.description`)

    const link = `<a href="http://${domain}" target="_blank" rel="noreferrer noopener">${domain}</a>`
    return I18n.t('builder.coreg.services.how_to_update', { link })
  }, [domain, connected, name])

  return (
    <ServiceContainer theme={theme} state={state} {...rest}>
      <ServiceInner>
        <ServiceHead>
          <ServiceLogo name={name} />
          <ServiceContent>
            <ServiceTitle>
              {title}
              {connected && (
                <ServiceStatus>{I18n.t('builder.coreg.services.connected')}</ServiceStatus>
              )}
            </ServiceTitle>
            <ServiceText
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            />
          </ServiceContent>
          <ServiceToggle state={state}>
            {!connected && !disabled && <ServiceCheckbox checked={checked} onChange={onToggle} />}
            {state !== 'unchecked' && <Icon20.Tick />}
          </ServiceToggle>
        </ServiceHead>

        {websites && (
          <ServiceWebsites>
            {websites.map(website => (
              <ServiceWebsite key={website.name}>
                <ServiceWebsiteLogo name={website.name} />
                <ServiceWebsiteTitle>{website.title}</ServiceWebsiteTitle>
              </ServiceWebsite>
            ))}
          </ServiceWebsites>
        )}
      </ServiceInner>
    </ServiceContainer>
  )
}

Service.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  domain: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(['default', 'card', 'bordered']),
  onToggle: PropTypes.func,
  checked: PropTypes.bool,
  connected: PropTypes.bool,
  websites: PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string, name: PropTypes.string })),
  disabled: PropTypes.bool,
}

Service.defaultProps = {
  theme: 'default',
}

export default Service
