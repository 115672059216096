import styled from 'styled-components'
import Media from 'builder/styles/media'

export const ImagesContainer = styled.div`
  width: 213px;
  height: 207px;
  margin-top: 36px;
  position: relative;

  ${Media.Tablet`
    width: 170px;
    height: 164px;
    order: 1;
    margin-top: 0;
    margin-bottom: 29px;
  `};

  ${Media.Phone`
    width: 84px;
    height: 81px;
    margin-bottom: 26px;
  `};
`

export const BlankContainer = styled.div`
  border-radius: 8px;
  background: linear-gradient(180deg, #ffffff 56.71%, rgba(234, 246, 255, 0) 100%);
  width: 190px;
  height: 203px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px 0 0 15px;

  svg {
    fill: none;
  }

  ${Media.Tablet`
    width: 151px;
    height: 161px;
    gap: 11px;
    padding: 11px 0 0 11px;
  `};

  ${Media.Phone`
    width: 74px;
    height: 79px;
    gap: 6px;
    padding: 6px 0 0 6px;
  `};
`

export const Logo = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 141px;
  height: 187px;

  ${Media.Tablet`
    width: 113px;
    height: 148px;
  `};

  ${Media.Phone`
    width: 55px;
    height: 73px;
  `};
`
