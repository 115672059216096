import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useDebouncedEffect } from 'builder/hooks/useDebouncedEffect'
import { actions, CareerPathStep, selectors } from 'builder/modules/careerPath'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { Form } from './components/Form'
import { CareerPathQuestionnaireFields } from './types'

import * as Styles from './styles'

const DEBOUNCE_DELAY = 300
const LABEL = {
  label: 'career_pathways',
}

// TODO: translation
export const CareerPathQuestionnaire = () => {
  const location = useTypedSelector(selectors.location)
  const occupation = useTypedSelector(selectors.occupation)

  useEffect(() => {
    trackInternalEvent('enter_career_path_welcome', LABEL)
  }, [])

  const dispatch = useDispatch()

  useDebouncedEffect(
    () => {
      if (occupation) {
        dispatch(actions.fetchDetails({ occupation, location }))
      }
    },
    DEBOUNCE_DELAY,
    [occupation, location],
  )

  const handleChange = (name: keyof CareerPathQuestionnaireFields, value: string) => {
    if (name === 'location') dispatch(actions.setLocation(value))
    if (name === 'occupation') dispatch(actions.setOccupation(value))
  }

  const handleSubmit = () => {
    trackInternalEvent('confirm_career_path_welcome', LABEL)
    dispatch(actions.setStep(CareerPathStep.LEVEL_CHOICE))
    dispatch(actions.setPrevStep(CareerPathStep.QUESTIONNAIRE))
    dispatch(actions.fetchOccupationMatrix({ occupation, location }))
  }

  return (
    <Styles.Container timeout={0}>
      <Styles.FormContainer>
        <Form fields={{ occupation, location }} onChange={handleChange} onSubmit={handleSubmit} />
      </Styles.FormContainer>
    </Styles.Container>
  )
}
