import { useEffect } from 'react'

export const useDebouncedEffect = (
  callback: React.EffectCallback,
  delay: number,
  deps: React.DependencyList = [],
) => {
  useEffect(() => {
    const handler = setTimeout(callback, delay)
    return () => clearTimeout(handler)
  }, [delay, ...deps]) // eslint-disable-line react-hooks/exhaustive-deps
}
