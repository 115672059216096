import { useEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { ButtonSize } from 'builder/components/Button/types'
import { useI18n } from 'builder/hooks/useI18n'
import { Button, ButtonsWrapper, Content, ContentWrapper, Title } from '../styles'
import { List, ListIcon, ListItem } from './styles'

interface Props {
  onContinueClick: () => void
  onCancelClick: () => void
  buttonSize: ButtonSize
}

const TRANSLATION_KEY = 'builder.resume_optimizer.job_posting'

export const BottomContainerNotEnoughDataView = ({
  onContinueClick,
  onCancelClick,
  buttonSize,
}: Props) => {
  const { i18n } = useI18n()

  useEffect(() => {
    trackInternalEvent('see_auto_tailoring_missing_info_screen')
  }, [])

  return (
    <>
      <Title>{i18n.t(`${TRANSLATION_KEY}.missed_data.title`)}</Title>
      <ContentWrapper>
        <Content>{i18n.t(`${TRANSLATION_KEY}.missed_data.sub_title`)}</Content>
        <List>
          <ListItem>
            <ListIcon />
            {i18n.t(`${TRANSLATION_KEY}.missed_data.profile_summary`)}
          </ListItem>
          <ListItem>
            <ListIcon />
            {i18n.t(`${TRANSLATION_KEY}.missed_data.work_experience`)}
          </ListItem>
        </List>
      </ContentWrapper>
      <ButtonsWrapper $justify="right">
        <Button size={buttonSize} theme="ghost" onClick={onCancelClick}>
          {i18n.t(`${TRANSLATION_KEY}.cancel`)}
        </Button>
        <Button size={buttonSize} onClick={onContinueClick}>
          {i18n.t(`${TRANSLATION_KEY}.continue`)}
        </Button>
      </ButtonsWrapper>
    </>
  )
}
