import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import { BadgeProps } from './types/types'

export const Wrapper = styled.div<Pick<BadgeProps, 'size'>>`
  ${Typography.Tiny};
  ${FontWeights.DemiBold};
  color: var(--badge-text-color);
  background: var(--badge-bg-color);
  vertical-align: middle;
  display: inline-flex;
  justify-content: center;
  padding: ${p => (p.size === 'medium' ? '4px 6px' : '1px 4px')};
  border-radius: 2px;
`
