import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { rotation } from 'builder/styles/keyframes'
import DocumentImagePreview from 'builder/components/DocumentImagePreview'
import Media from 'builder/styles/media'

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 14px 0px;
`

export const LoadingIcon = styled.img`
  color: ${Colors.Blue50};
  animation: ${rotation} 0.5s linear infinite;
  margin-left: 17.5px;
`

export const LoadingText = styled.p`
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: white;
  margin-left: 17.5px;
`

export const ResumePreview = styled(DocumentImagePreview)`
  width: 320px;
  margin-top: 12px;
  border-radius: 2px;
  margin-left: 16px;
  background: linear-gradient(180deg, #f7f9fc 0%, ${Colors.White} 100%);
  border-image: linear-gradient(to bottom, ${Colors.Neutral15} 60%, ${Colors.White} 100%);
  border-image-slice: 1;

  ${Media.Phone`
    width: 246px;
    margin-top: 9px;
  `}
`

export const Progress = styled.div`
  height: 3px;
  background-color: ${Colors.Neutral80};
  margin: 6px 16px 6px 16px;
`
