import { LOGO_ELEMENT_ID } from 'builder/components/Navigation'
import { LogoLink, LogoStyled } from './styles'

export const Bar = () => {
  return (
    <LogoLink aria-label="Logo" to="/">
      <LogoStyled id={LOGO_ELEMENT_ID} variant="default" />
    </LogoLink>
  )
}
