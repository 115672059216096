import * as React from 'react'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/init'
import useWebsiteHost, {
  cioDomainList,
} from 'builder/components/CIO-Dasboard-2.0/hooks/useWebsiteHost'
import { LogoContainer } from './styles'

interface LogoProps extends React.HTMLAttributes<HTMLSpanElement> {
  variant?: 'default' | 'white'
}

export const Logo = ({ variant = 'default', ...other }: LogoProps) => {
  const logo = useTypedSelector(selectors.logo)
  const imageUrl = logo?.[variant]
  const { getHost } = useWebsiteHost()
  const isCioDomain = cioDomainList.includes(getHost)

  if (process.env.NODE_ENV === 'development' && !logo) {
    throw new Error('Logo component must be rendered when config with logo is available')
  }

  return <LogoContainer imageUrl={imageUrl} isCioDomain={isCioDomain} {...other} />
}
