import styled from 'styled-components'
import Media from 'builder/styles/media'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 1200px;
  width: 100vw;
  padding: 24px 40px;
  margin: 40px auto;

  ${Media.Desktop`
    margin: auto;
    width: 1220px;
  `};

  ${Media.Phone`
    padding: 0 0 0 20px;
  `};

  ${Media.Tablet`
    padding: 0 0 0 20px;
  `};

  ${Media.Laptop`
    padding: 0 0 0 20px;
  `};
`
