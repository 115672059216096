import styled from 'styled-components'
import DefaultSpinner from 'builder/components/Spinner'
import { FetchStatuses } from 'builder/modules/constants'

export const Container = styled.div`
  position: relative;
`

export const Iframe = styled.iframe.attrs({ frameBorder: 0 })`
  width: 100%;
  border: none;

  &[data-status=${FetchStatuses.loading}] {
    opacity: 0;
    pointer-events: none;
  }
`

export const Spinner = styled(DefaultSpinner)`
  position: absolute;
  top: calc(50% - 18px);
  left: calc(50% - 18px);
`
