import { useNavigate } from 'react-router-dom'
import { authRedirectService } from 'builder/services/AuthRedirectService'
import { useI18n } from 'builder/hooks/useI18n'
import { ButtonTheme } from 'builder/components/Button'
import { MessageLine } from './MessageLine'
import { ChatBotAvatarContainer, SignOption } from './styles'
import ChatBotAvatar from './assets/chatbot_avatar.svg'

// @TODO handle redirect back after signup
export const ChatSignup = () => {
  const navigate = useNavigate()
  const { i18n } = useI18n()

  const redirectToAuth = () => {
    // Save the current page URL to open it back after successful logging in / signing up
    // and perform the access checks again
    authRedirectService.persistPath(window.location.pathname + window.location.search)
    // Open proper funnel page
    navigate(`/auth/sign-in`, { replace: true })
  }

  return (
    <>
      <MessageLine
        type="asked"
        avatar={<ChatBotAvatarContainer src={ChatBotAvatar} alt="chatbot avatar" />}
        last={true}
        content={<>{i18n.t('builder.career_profile.chat.outro.register')}</>}
      />
      <SignOption onClick={() => redirectToAuth()} theme={ButtonTheme.ghost}>
        {i18n.t('builder.sign_in.title')}
      </SignOption>
      <SignOption onClick={() => redirectToAuth()} theme={ButtonTheme.ghost}>
        {i18n.t('builder.sign_in.sign_up')}
      </SignOption>
    </>
  )
}
