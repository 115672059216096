import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'
import { Icon20 } from 'builder/components/Icon'
import { rotation } from 'builder/styles/keyframes'

import SYNCING_URL from 'images/builder/resume-editor/syncing.svg'
import SYNCING_DARK_URL from 'images/builder/resume-editor/syncing-dark.svg'
import SYNCED_URL from 'images/builder/resume-editor/synced.svg'
import SYNCED_LOCALLY_URL from 'images/builder/resume-editor/synced-locally.svg'

import { CoRegPopupPositions } from './CoRegPopup'
import { MODES } from './types'

export const Container = styled.div<{ mode: MODES }>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 50%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  background-color: ${({ mode }) => (mode === MODES.wizard ? Colors.Neutral10 : Colors.Neutral60)};
  color: ${({ mode }) => (mode === MODES.wizard ? Colors.Neutral50 : Colors.White)};
  user-select: none;
  z-index: 0;

  @media (min-width: 1920px) {
    width: calc(100% - 960px);
  }

  ${Media.Laptop`
    display: none;
  `};
`

export const PreviewBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 72px;
  bottom: 40px;
  left: 74px;
  right: 74px;
`

export const PreviewWrapper = styled.div`
  display: inline-block;
  position: relative;
`

export const FullSizeOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const FullSizeIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: ${Colors.Blue50};
  color: ${Colors.White};
  transform: scale(0);
  transition: transform 0.1s ease;

  ${FullSizeOverlay}:hover & {
    transform: scale(1);
  }
`

export const SyncStatusContainer = styled.div`
  ${Typography.Tiny};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`

const SyncIcon = styled.div<{ isSyncing: boolean }>`
  margin-right: 4px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    width: 24px;
    height: 24px;
    display: block;
    background-size: 100% 100%;
    background-position: center center;

    ${props =>
      props.isSyncing &&
      css`
        animation: ${rotation} 0.5s infinite linear;
      `}
  }
`

export const SyncRemoteIcon = styled(SyncIcon)<{ isDark: boolean; isSyncing: boolean }>(props => {
  const { isDark, isSyncing } = props
  const syncingURL = isDark ? SYNCING_DARK_URL : SYNCING_URL
  return css`
    &::before {
      background-image: url(${isSyncing ? syncingURL : SYNCED_URL});
    }
  `
})

export const SyncLocallyIcon = styled(SyncIcon)<{ isDark: boolean; isSyncing: boolean }>(props => {
  const { isDark, isSyncing } = props
  const syncingURL = isDark ? SYNCING_DARK_URL : SYNCING_URL
  return css`
    &::before {
      background-image: url(${isSyncing ? syncingURL : SYNCED_LOCALLY_URL});
    }
  `
})

export const CoRegPopupContainer = styled.div<{
  isVisible: boolean
  position: CoRegPopupPositions
}>`
  display: flex;
  position: absolute;
  z-index: 5;
  width: 464px;
  margin-bottom: 12px;
  padding: 16px 40px 16px 20px;
  border-radius: 6px;
  background-color: ${Colors.Neutral100};
  box-shadow: 0 0 1px rgba(23, 68, 130, 0.2), 0 2px 14px rgba(23, 68, 130, 0.15);
  color: ${Colors.White};

  opacity: 0;
  transition-property: opacity, transform;
  transition-timing-function: ease-out;
  transition-duration: 0.15s;

  &:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background-color: inherit;
  }

  ${props => {
    switch (props.position) {
      case CoRegPopupPositions.top:
        return css`
          right: -12px;
          bottom: 100%;
          transform-origin: 90% 110%;
          transform: scale(0.5) translateZ(0);

          &:after {
            right: 37px;
            bottom: -5px;
            transform: translateX(50%) rotate(45deg);
          }
        `
      case CoRegPopupPositions.bottom:
        return css`
          right: -12px;
          top: calc(100% + 26px);

          &:after {
            right: 31px;
            top: -5px;
            transform: translateX(50%) rotate(45deg);
          }

          ${Media.Phone`
            right: -92px;

            &:after {
              right: 110px;
            }
          `}
        `
    }
  }};

  ${props =>
    props.isVisible &&
    css`
      transform: scale(1) translateZ(0);
      opacity: 1;
    `}

  ${Media.Phone`
    width: 350px;
  `};
`

export const CoRegPopupContent = styled.div`
  flex-grow: 1;
  padding-bottom: 8px;
  position: relative;

  ${Media.Phone`
    padding-bottom: 0;
  `};
`

export const CoRegPopupTitle = styled.div`
  ${Typography.Subhead};
  font-weight: 600;

  ${Media.Phone`
    ${Typography.Body};
    margin-bottom: 8px;
  `};
`

export const CoRegPopupText = styled.div`
  ${Typography.Caption};
  margin-bottom: 20px;
  color: ${Colors.Neutral50};
  max-width: 320px;

  ${Media.Phone`
    ${Typography.Tiny};
    padding-right: 45px;
  `};
`

export const CoRegPopupButtonIcon = styled(Icon20.ArrowLeft)`
  transform: rotate(180deg);
`

export const CoRegPopupVisual = styled.div`
  align-self: center;
  flex-shrink: 0;
  width: 101px;
  height: 108px;
  margin-left: 8px;
  background-image: url(${require('images/builder/coreg/job-board.svg')});
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 0;
  bottom: 0;

  ${Media.Phone`
    right: -30px;
    bottom: -20px;
  `};
`

export const CoRegPopupClose = styled.div`
  position: absolute;
  display: flex;
  top: 8px;
  right: 8px;
  padding: 8px;
  color: ${Colors.Neutral70};
  transition: color 0.15s;
  cursor: pointer;

  &:hover {
    color: ${Colors.White};
  }
`
