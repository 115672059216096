import { createAction, createReducer } from '@reduxjs/toolkit'
import { Store } from 'builder/modules/store'
import { FetchStatuses } from 'builder/modules/constants'
import { CareerPathStep } from './constants'
import {
  CareerPathGoal,
  CareerPathRoleDetailsPayload,
  CareerPathRoleOccupationMatrix,
  CareerPathRoleOccupationMatrixPayload,
  CareerPathRoleSummaryPayload,
  CareerPathStore,
} from './types'

// ---
// Action creators
// ---
export const actions = {
  setStep: createAction<CareerPathStep>('builder/CAREER_PATH_SET_STEP'),
  setPrevStep: createAction<CareerPathStep>('builder/CAREER_PATH_SET_PREV_STEP'),
  setChoice: createAction<string>('builder/CAREER_PATH_SET_CHOICE'),
  setFetchDetailsStatus: createAction<FetchStatuses>(
    'builder/CAREER_PATH_SET_FETCH_DETAILS_STATUS',
  ),
  setFetchMatrixStatus: createAction<FetchStatuses>('builder/CAREER_PATH_SET_FETCH_MATRIX_STATUS'),
  setFetchSummaryStatus: createAction<FetchStatuses>(
    'builder/CAREER_PATH_SET_FETCH_SUMMARY_STATUS',
  ),
  setFetchGoalStatus: createAction<FetchStatuses>('builder/CAREER_PATH_SET_FETCH_GOAL_STATUS'),
  setOccupation: createAction<string>('builder/CAREER_PATH_SET_OCCUPATION'),
  setLocation: createAction<string>('builder/CAREER_PATH_SET_LOCATION'),
  fetchDetails: createAction<{ occupation: string; location?: string }>(
    'builder/CAREER_PATH_FETCH_DETAILS',
  ),
  setDetails: createAction<CareerPathRoleDetailsPayload>('builder/CAREER_PATH_SET_DETAILS'),
  fetchOccupationMatrix: createAction<{ occupation: string; location?: string }>(
    'builder/CAREER_PATH_FETCH_OCCUPATION_MATRIX',
  ),
  setOccupationMatrix: createAction<CareerPathRoleOccupationMatrixPayload>(
    'builder/CAREER_PATH_SET_OCCUPATION_MATRIX',
  ),
  fetchSummary: createAction<{ occupation: string; location?: string; countofapicalls: number }>(
    'builder/CAREER_PATH_FETCH_SUMMARY',
  ),
  setSummary: createAction<CareerPathRoleSummaryPayload>('builder/CAREER_PATH_SET_SUMMARY'),
  confirmGoal: createAction<CareerPathGoal>('builder/CAREER_PATH_CONFIRM_GOAL'),
  fetchGoal: createAction('builder/CAREER_PATH_FETCH_GOAL'),
  setGoal: createAction<{ careerGoal: CareerPathGoal }>('builder/CAREER_PATH_SET_GOAL'),
}

// ---
// Selectors
// ---
export const selectors = {
  step: (state: Store) => state.careerPath.step,
  prevStep: (state: Store) => state.careerPath.prevStep,
  occupation: (state: Store) => state.careerPath.occupation,
  location: (state: Store) => state.careerPath.location,
  jobSummary: (state: Store) => state.careerPath.jobSummary,
  matrix: (state: Store) => state.careerPath.matrix,
  choiceId: (state: Store) => state.careerPath.choiceId,
  summary: (state: Store) => state.careerPath.summary,
  goal: (state: Store) => state.careerPath.goal,
  target: (state: Store) => state.careerPath.goal?.targetTitle || null,
  goalStatus: (state: Store) => state.careerPath.fetchGoalStatus,
  isDetailsLoading: (state: Store) => state.careerPath.fetchDetailsStatus === FetchStatuses.loading,
  isDetailsLoaded: (state: Store) => state.careerPath.fetchDetailsStatus === FetchStatuses.loaded,
  isMatrixLoading: (state: Store) => state.careerPath.fetchMatrixStatus === FetchStatuses.loading,
  isMatrixFailed: (state: Store) => state.careerPath.fetchMatrixStatus === FetchStatuses.failed,
  isMatrixLoaded: (state: Store) => state.careerPath.fetchMatrixStatus === FetchStatuses.loaded,
  isSummaryLoading: (state: Store) => state.careerPath.fetchSummaryStatus === FetchStatuses.loading,
  isSummaryLoaded: (state: Store) => state.careerPath.fetchSummaryStatus === FetchStatuses.loaded,
}

// ---
// Reducer
// ---

const initialState: CareerPathStore = {
  fetchDetailsStatus: FetchStatuses.notAsked,
  fetchMatrixStatus: FetchStatuses.notAsked,
  fetchSummaryStatus: FetchStatuses.notAsked,
  fetchGoalStatus: FetchStatuses.notAsked,
  step: CareerPathStep.QUESTIONNAIRE,
  prevStep: CareerPathStep.QUESTIONNAIRE,
  matrix: {
    low: [],
    medium: [],
    high: [],
  },
  occupation: '',
  location: '',
  jobSummary: {},
  choiceId: null,
  summary: {
    commonJobTitles: [],
    description: null,
    softSkills: [],
    hardSkills: [],
    certifications: [],
    companies: [],
    totals: {},
    salary: {},
    postingsTimeseries: [],
    educationLevels: [],
  },
  goal: null,
}

export const reducer = createReducer(initialState, reducer => {
  reducer.addCase(actions.setStep, (draft, action) => {
    draft.step = action.payload
  })
  reducer.addCase(actions.setPrevStep, (draft, action) => {
    draft.prevStep = action.payload
  })
  reducer.addCase(actions.setChoice, (draft, action) => {
    draft.choiceId = action.payload
  })
  reducer.addCase(actions.setFetchDetailsStatus, (draft, action) => {
    draft.fetchDetailsStatus = action.payload
  })
  reducer.addCase(actions.setFetchMatrixStatus, (draft, action) => {
    draft.fetchMatrixStatus = action.payload
  })
  reducer.addCase(actions.setFetchSummaryStatus, (draft, action) => {
    draft.fetchSummaryStatus = action.payload
  })
  reducer.addCase(actions.setFetchGoalStatus, (draft, action) => {
    draft.fetchGoalStatus = action.payload
  })
  reducer.addCase(actions.setOccupation, (draft, action) => {
    draft.occupation = action.payload
  })
  reducer.addCase(actions.setLocation, (draft, action) => {
    draft.location = action.payload
  })
  reducer.addCase(actions.fetchDetails, draft => {
    draft.jobSummary = initialState.jobSummary
    draft.matrix = initialState.matrix
    draft.fetchMatrixStatus = initialState.fetchMatrixStatus
  })
  reducer.addCase(actions.fetchSummary, draft => {
    draft.summary = initialState.summary
    draft.fetchSummaryStatus = initialState.fetchSummaryStatus
  })
  reducer.addCase(actions.setDetails, (draft, action) => {
    draft.jobSummary = {
      salary: action.payload.salary,
      jobPostings: action.payload.jobPostings,
    }
  })
  reducer.addCase(actions.setOccupationMatrix, (draft, action) => {
    // Sort occupations by grade
    draft.matrix = action.payload.reduce((accum: CareerPathRoleOccupationMatrix, item) => {
      if (item) {
        const newItem = { ...item, occupation: item.title }

        return {
          ...accum,
          [item.grade]: [...accum[item.grade], newItem],
        }
      }
      return accum
    }, initialState.matrix)
  })
  reducer.addCase(actions.setSummary, (draft, action) => {
    draft.summary = action.payload
  })
  reducer.addCase(actions.setGoal, (draft, action) => {
    draft.goal = action.payload.careerGoal
  })
})
