import { i18n as I18n } from 'builder/utils/i18n'

const TRANSLATION = 'builder.dashboard.career_services'

type ServiceTitle = {
  text?: string
  name?: string
  index?: number
}

export const useCareerServiceTitle = ({ index, name }: ServiceTitle): ServiceTitle => {
  const tabs = [
    { text: I18n.t(`${TRANSLATION}.job_search`), index: 0, name: 'find_a_job' },
    { text: I18n.t(`${TRANSLATION}.career_development`), index: 1, name: 'excel_at_your_job' },
    { text: I18n.t(`${TRANSLATION}.career_change`), index: 2, name: 'career_change' },
  ]

  return tabs.find(tab => tab.index === index || tab.name === name) || {}
}
