export enum SnackBarTypes {
  simple = 'simple',
  success = 'success',
  failure = 'failure',
}

export interface SnackBarContextType {
  isOpen: boolean
  text: string
  type?: SnackBarTypes | null
  isDismissible?: boolean
  action?: SnackBarAction
  openSnackBar: (params: OpenSnackBarParams) => void
  closeSnackBar: () => void
  clearSnackBarState: () => void
  bottom?: SnackBarPosition
}

export interface SnackBarAction {
  onClick?: () => void
  text?: string
}

export interface SnackBarPosition {
  phone?: number
  desktop?: number
}

export interface SnackBarContextProviderProps {
  children: React.ReactNode
}

export interface OpenSnackBarParams {
  text: string
  type?: SnackBarTypes
  isDismissible?: boolean
  action?: SnackBarAction
  autoDismissTime?: number
  bottom?: SnackBarPosition
}
