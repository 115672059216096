import { animated } from '@react-spring/web'
import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Shadows from 'builder/styles/shadows'
import Typography, { FontWeights } from 'builder/styles/typography'

export const ModalContainer = styled(animated.div)`
  ${Shadows.lightWithBorder.low};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  width: 100%;
  max-width: 352px;
  background-color: ${Colors.White};
  border-radius: 12px;
  position: fixed;
  right: 25px;
  bottom: 32px;
  z-index: 9999999999; // make modal appear on top of the question icon button and feedback button

  ${Media.Phone`
    left: 0;
    right: 0;
    bottom: 16px;
    margin: 0 auto;
    padding: 20px;
    max-width: calc(100% - 32px);
  `}
`

export const ModalContent = styled.div`
  padding: 0;
`

export const Heading = styled.div`
  ${Typography.Subhead};
  ${FontWeights.DemiBold};

  max-width: 236px;
  margin-bottom: 8px;

  ${Media.Phone`
    max-width: 247px;
  `}
`

export const SubHeading = styled.div`
  ${Typography.Body};

  margin-bottom: 16px;
  color: ${Colors.Neutral50};

  ${Media.Phone`
    margin-bottom: 8px;
  `}
`

export const Options = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 24px;

  ${Media.Phone`
    margin-bottom: 20px;
  `}
`

export const Option = styled.div<{ checked: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: ${props => (props.checked ? '2px' : '1px')} solid
    ${props => (props.checked ? Colors.Blue50 : Colors.Neutral15)};
  border-radius: 8px;
  padding: 12px 15px 12px 12px;

  &:hover {
    cursor: pointer;
    color: ${Colors.Blue50};
    border-color: ${Colors.Blue50};
  }

  &:hover span {
    border-color: ${Colors.Blue50};
  }
`

export const OptionText = styled.div`
  ${Typography.Body};

  max-width: 251px;
`

export const ButtonContainer = styled.div`
  width: 100%;
  gap: 8px;
  display: flex;
  justify-content: flex-end;
`

export const Close = styled.div`
  position: absolute;
  width: 28px;
  height: 28px;
  top: 24px;
  right: 24px;
  border-radius: 32px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.Neutral70};
  cursor: pointer;
  transition: color 0.1s;
  background-color: ${Colors.Neutral10};

  &:hover {
    color: ${Colors.Blue50};
    background-color: ${Colors.Blue10};
  }

  ${Media.Phone`
    top: 20px;
    right: 20px;
  `}
`

export const Radio = styled.label<{ checked: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  transition: color 0.15s;
  color: ${props => (props.checked ? Colors.Neutral90 : Colors.Neutral60)};

  &:hover {
    color: ${Colors.Neutral90};
  }
`

export const RadioControl = styled.span<{ checked: boolean }>`
  width: 18px;
  height: 18px;
  outline: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${Colors.Neutral30};
  transition: background-color 0.1s ease, border-color 0.1s ease;

  ${Radio}:hover & {
    border-color: ${Colors.Blue50};
  }

  ${props =>
    props.checked &&
    css`
      border-width: 6px;
      border-color: ${Colors.Blue50};
    `}

  &:before {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${Colors.White};
    opacity: ${props => (props.checked ? 1 : 0)};
  }
`
