import { useState } from 'react'
import { trackInternalEvent } from '@rio/tracking'

import { useI18n } from 'builder/hooks/useI18n'
import useWebsiteHost from 'builder/components/CIO-Dasboard-2.0/hooks/useWebsiteHost'

import ModalOverlay from 'builder/components/ModalOverlay'
import { YoutubeVideoPlayer } from 'builder/components/YoutubeVideoPlayer/YoutubeVideoPlayer'

import {
  AutoApplyContainer,
  CloseButton,
  CloseIcon,
  Container,
  IconContainer,
  LogoContainer,
  LogoIcon,
  PlayIcon,
  VideoContainer,
} from './styles'
import CIO from './assets/cio.png'

const YOUTUBE_VIDEO_ID = '7RhI58DHWdo'

const OPTIONS = {
  rel: 0,
  controls: 0,
  loop: 1,
  mute: 1,
  autoplay: 1,
  modestbranding: 0,
}

export const AutoApplyGuide = () => {
  const { i18n } = useI18n()
  const { getHost } = useWebsiteHost()

  const [openVideo, setOpenVideo] = useState(false)

  const onOpenModal = () => {
    trackInternalEvent('click_auto_apply_guide', { host: getHost })
    setOpenVideo(true)
  }

  const onCloseModal = () => {
    trackInternalEvent('auto_apply_how_to_video_close', { host: getHost })
    setOpenVideo(false)
  }

  const onAutoPlay = () => {
    trackInternalEvent('auto_apply_how_to_video_start', { host: getHost })
  }

  const onUnMute = () => {
    trackInternalEvent('auto_apply_how_to_video_unmute', { host: getHost })
  }

  const onEnded = () => {
    trackInternalEvent('auto_apply_how_to_video_finish', { host: getHost })
  }

  return (
    <Container>
      <AutoApplyContainer onClick={onOpenModal}>
        {i18n.t(`builder.job_tracking.auto_apply_guide_button`)}
        <IconContainer>
          <LogoContainer>
            <LogoIcon src={CIO} />
          </LogoContainer>
          <PlayIcon />
        </IconContainer>
      </AutoApplyContainer>
      {openVideo && (
        <ModalOverlay>
          <VideoContainer>
            <CloseButton onClick={onCloseModal}>
              <CloseIcon />
            </CloseButton>
            <YoutubeVideoPlayer
              onAutoPlay={onAutoPlay}
              onUnMute={onUnMute}
              onEnded={onEnded}
              videoId={YOUTUBE_VIDEO_ID}
              options={OPTIONS}
            />
          </VideoContainer>
        </ModalOverlay>
      )}
    </Container>
  )
}
