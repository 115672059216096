import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { trackInternalEvent } from '@rio/tracking'

import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as userSelectors, actions as userActions } from 'builder/modules/user'
import { TABS, Label, actions, selectors, CareerPathStep } from 'builder/modules/careerPath2'

import AddToPath from './components/AddToPath'
import JobDetails from './components/JobDetails'
import ConfirmPath from './components/ConfirmPath'

import { Container } from './styles'
import NotifyMe from './components/JobDetails/components/NotifyMe'
import CareerPageTabs from './components/JobDetails/components/CareerPageTabs'

const CareerPathJobDetails = () => {
  const dispatch = useDispatch()
  const step = useTypedSelector(selectors.step)
  const activeTab = useTypedSelector(selectors.activeTab)
  const occupation = useTypedSelector(selectors.occupation)
  const userData = useTypedSelector(userSelectors.userData)
  const isExploreCareers = useTypedSelector(selectors.isExploreCareers)
  const selectedJobTitle = useTypedSelector(selectors.selectedJobTitle)
  const exploreCareersOccupation = useTypedSelector(selectors.exploreCareersOccupation)

  useEffect(() => {
    if (step === CareerPathStep.CONFIRM_MY_PATH) {
      trackInternalEvent('enter_confirm_pathway_page', Label)
    } else if (isExploreCareers) {
      trackInternalEvent('enter_career_page', {
        label: 'explore_careers',
        job_title: exploreCareersOccupation,
      })
    }
  }, [step, occupation, isExploreCareers, exploreCareersOccupation])

  const getPath = () => {
    if (step === CareerPathStep.ADD_TO_PATH) return <AddToPath />

    return <ConfirmPath />
  }

  const [showNotify, setShowNotify] = useState(
    !userData?.emailNotifications?.career && step === CareerPathStep.CHANGE_MY_PATH,
  )

  const handleNotify = () => {
    let payload = { name: 'career', value: true }
    dispatch(userActions.toggleEmailNotification(payload))

    trackInternalEvent('click_notify_about_plan_button', Label)
  }

  const handleCancel = () => setShowNotify(false)

  const handleTabSelect = (tab: string) => {
    dispatch(actions.setActiveTab(tab))

    trackInternalEvent('click_career_page_tab', {
      tab_name: tab,
      label: isExploreCareers ? 'explore_careers' : 'career_path2',
      job_title: isExploreCareers ? exploreCareersOccupation : selectedJobTitle,
    })
  }

  return (
    <Container>
      {getPath()}
      <CareerPageTabs tabs={TABS} activeTab={activeTab} onTabSelect={handleTabSelect} />
      <JobDetails activeTab={activeTab} tabs={TABS} />
      {!userData?.emailNotifications?.career &&
      step === CareerPathStep.CHANGE_MY_PATH &&
      showNotify ? (
        <NotifyMe handleCancel={handleCancel} handleNotify={handleNotify} />
      ) : null}
    </Container>
  )
}

export default CareerPathJobDetails
