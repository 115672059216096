import { useNavigate } from 'react-router-dom'
import { useI18n } from 'builder/hooks/useI18n'

import ImageSrc from '../assets/error-image.png'
import {
  ContactSupport,
  Container,
  ContinueButton,
  Image,
  ImageContainer,
  TextContainer,
  TextHeader,
  TextSubHeader,
} from './styles'

const TRANSLATION = 'builder.resume_distribution.landing.server_error'

export const ServerError = () => {
  const { i18n } = useI18n()
  const navigate = useNavigate()

  const onClick = () => {
    navigate('/')
  }

  return (
    <Container>
      <ImageContainer>
        <Image src={ImageSrc} />
      </ImageContainer>

      <TextContainer>
        <TextHeader>{i18n.t(`${TRANSLATION}.header`)}</TextHeader>
        <TextSubHeader>
          {i18n.t(`${TRANSLATION}.sub_header`)}{' '}
          <ContactSupport href="/contact" target="_blank">
            {i18n.t(`${TRANSLATION}.contact_support`)}
          </ContactSupport>
        </TextSubHeader>
      </TextContainer>

      <ContinueButton onClick={onClick}>{i18n.t(`${TRANSLATION}.button`)}</ContinueButton>
    </Container>
  )
}
