import { ISideMenuItems, ISubMenu } from '../../../types'
import { MenuItem, MenuItemCont } from './styles'

interface IProps {
  type: 'menu' | 'submenu'
  menu: ISideMenuItems[]
  subMenuAction: () => void
  mainMenuAction: (item: ISideMenuItems) => void
}

export const MenuItems = ({ type, menu, subMenuAction, mainMenuAction }: IProps) => {
  const onClickMenu = (item: ISubMenu) => {
    if (type === 'menu') return mainMenuAction(item)
    subMenuAction()
  }

  return (
    <>
      {menu.map((item, index) => (
        <MenuItem key={index} onClick={() => onClickMenu(item)}>
          <MenuItemCont
            to={item.to}
            open={true}
            href={item.href}
            isPostPremium={item.isPostPremium}
            endDecoration={item?.endDecoration}
            startDecoration={item?.startDecoration}
            startDecorationActive={item?.startDecorationActive}
            eventParam={item?.eventParam}
          >
            {item.text}
          </MenuItemCont>
        </MenuItem>
      ))}
    </>
  )
}
