import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const JobSearchInfoContainer = styled.div`
  padding: 32px 0;
  margin: 0px 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  border-top: 1px solid ${Colors.Neutral15};
`

export const JobSearchInfoHeader = styled.div`
  margin-right: auto;
  ${Typography.Subhead};
  ${FontWeights.Medium};
`

export const JobSearchInfoTitle = styled.p`
  ${Typography.S};
  ${FontWeights.Regular};
  color: ${Colors.Neutral100};
`

export const JobSearchInfoSubTitle = styled.p`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
  margin-top: 8px;
`

export const JobSearchInfoFeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
`

export const JobSearchFeatureContainer = styled.div<{
  open: boolean
  backgroundColor: string
}>`
  height: ${props => (props.open ? '264px' : '72px')};
  width: 100%;
  transition: height 0.2s ease-in-out;
  border-radius: 16px;
  cursor: ${({ open }) => (!open ? 'pointer' : 'static')};
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 10000;
`

export const JobSearchFeatureHeader = styled.div<{
  backgroundColor: string
}>`
  display: flex;
  border-radius: 16px;
  width: 100%;
  align-items: center;
  padding: 22px 20px;
  z-index: 10;
  position: absolute;
  top: 0;
  background-color: ${({ backgroundColor }) => backgroundColor};
`

export const JobSearchFeatureTitle = styled.span`
  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
`

export const JobSearchFeatureSerialNumber = styled.span`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral70};
  margin-right: 9px;
`

export const JobSearchBannerContainer = styled.div<{
  open: boolean
}>`
  width: 100%;
  border-radius: 16px;
  background-color: transparent;
  display: flex;
  position: relative;
  height: ${({ open }) => (open ? '264px' : '0px')};
  overflow: hidden;
`

export const BannerContentContainer = styled.div<{ long?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 220px;
  margin-left: 24px;
  position: absolute;
  bottom: ${({ long }) => (long ? '19px' : '24px')};
`

export const BannerTitle = styled.span<{ color: string }>`
  ${Typography.Subhead};
  ${FontWeights.Medium};
  color: ${({ color }) => color};
`

export const BannerSubtitle = styled.label<{ color: string }>`
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${props => props.color};
`

export const BannerButtonContainer = styled.div`
  margin-top: 15px;
`

export const BannerPrimaryButton = styled.button<{ btnBgColor: string; btnBgHoverColor: string }>`
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  ${FontWeights.Regular};
  ${Typography.Caption}
  display: block;
  background-color: ${({ btnBgColor }) => btnBgColor};
  color: ${Colors.White};

  &:hover {
    background-color: ${({ btnBgHoverColor }) => btnBgHoverColor};
  }
`

export const BannerImageContainer = styled.div<{ top?: number }>`
  position: absolute;
  right: 0;
  top: ${({ top }) => `${top || 0}px`};
  z-index: 99999;
`

export const BannerImage = styled.img`
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 16px;
`
