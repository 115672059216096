import { useEffect, useRef } from 'react'
import { useI18n } from 'builder/hooks/useI18n'
import { Icon16 } from 'builder/components/Icon/Icon'
import {
  Container,
  CustomVideo,
  RecordingContainer,
  RecordingIconContainer,
  YouText,
} from './styles'

interface Props {
  srcObject: MediaStream
  isPhone: boolean
  isRecording: boolean
}

export const Webcam = ({ srcObject, isPhone, isRecording }: Props) => {
  const { i18n } = useI18n()
  const playerRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.srcObject = srcObject
    }
  }, [srcObject])
  return (
    <Container>
      <CustomVideo ref={playerRef} autoPlay muted playsInline />
      {!isPhone && <YouText>{i18n.t('builder.interview_prep_view.interview_player.you')}</YouText>}
      {isRecording && (
        <RecordingContainer>
          <RecordingIconContainer>
            <Icon16.RecordingIcon />
          </RecordingIconContainer>
          {i18n.t(
            `builder.interview_prep_view.interview_player.${
              isPhone ? 'recording_abbreviation' : 'recording'
            }`,
          )}
        </RecordingContainer>
      )}
    </Container>
  )
}
