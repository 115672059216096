import { useCallback, useEffect, useRef, useState } from 'react'
import kebabCase from 'lodash/kebabCase'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import ModalOverlay from 'builder/components/ModalOverlay'
import { Icon24 } from 'builder/components/Icon'
import useWizard from '../../hooks/useWizard'
import { MODALS } from './constants'

import {
  ModalContainer,
  ModalClose,
  ModalTitle,
  ModalSubtitle,
  TipsList,
  Tip,
  TipIcon,
  TipText,
  ModalButton,
} from './styles'

export const WizardTips = () => {
  const { currentStep } = useWizard()
  const { isPhone } = useMediaQueries()

  const shownStepIds = useRef<string[]>([])
  const [openStepId, setOpenStepId] = useState<string | null>(null)
  const close = useCallback(() => setOpenStepId(null), [])

  useEffect(() => {
    // The fullscreen modal blocks users from working on their resume
    // so we decided to make the tips available on big screens only
    if (isPhone) return
    // Show tips before two hardest steps only
    if (currentStep.id in MODALS === false) return
    // Do not open the same modal again
    if (shownStepIds.current.includes(currentStep.id)) return

    setOpenStepId(currentStep.id)
    shownStepIds.current.push(currentStep.id)
  }, [currentStep.id, isPhone])

  // Render nothing if there is no data for the current wizard step or it's unsupported resolution
  if (!openStepId || isPhone) return null

  const modal = MODALS[openStepId]

  return (
    <ModalOverlay onClick={close} overlayFadeDuration={250} contentSlideDuration={250}>
      <ModalContainer>
        <ModalTitle>{modal.title}</ModalTitle>
        <ModalSubtitle>{modal.subtitle}</ModalSubtitle>
        <TipsList>
          {modal.tips.map((tip, index) => (
            <Tip key={index}>
              <TipIcon name={`${kebabCase(openStepId)}-${index + 1}`} />
              <TipText>{tip}</TipText>
            </Tip>
          ))}
        </TipsList>
        <ModalButton onClick={close}>{modal.cta}</ModalButton>

        <ModalClose onClick={close}>
          <Icon24.CloseLarge />
        </ModalClose>
      </ModalContainer>
    </ModalOverlay>
  )
}
