import { Fragment, useState, useCallback, MouseEvent } from 'react'
import { UserData } from 'builder/modules/user'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import Icon from 'builder/components/Icon'
import { MobileMenu } from '../MobileMenu'

import { SecondaryLinks, PrimaryLinks } from '../../types'
import { CloseMenuButton, OpenMenuButton } from './styles'

interface MobileNavbarProps {
  user: UserData
  barHeight: number
  primaryLinks: PrimaryLinks
  secondaryLinks: SecondaryLinks
  onPrimaryLinkClick: (event: MouseEvent<HTMLAnchorElement>) => void
}

export const MobileNavbar = ({
  user,
  barHeight,
  primaryLinks,
  secondaryLinks,
  onPrimaryLinkClick,
}: MobileNavbarProps) => {
  const { isTablet } = useMediaQueries()
  const [isMenuVisible, toggleMenu] = useState(!isTablet)

  const handleLinkClick = useCallback(
    event => {
      toggleMenu(false)
      onPrimaryLinkClick(event)
    },
    [onPrimaryLinkClick],
  )

  return (
    <Fragment>
      {isMenuVisible && (
        <MobileMenu
          user={user}
          barHeight={barHeight}
          primaryLinks={primaryLinks}
          secondaryLinks={secondaryLinks}
          handleLinkClick={handleLinkClick}
          toggleMenu={toggleMenu}
        />
      )}

      {isMenuVisible ? (
        <CloseMenuButton onClick={() => toggleMenu(false)}>
          <Icon.BurgerClose />
        </CloseMenuButton>
      ) : (
        <OpenMenuButton onClick={() => toggleMenu(true)}>
          <Icon.Burger />
        </OpenMenuButton>
      )}
    </Fragment>
  )
}
