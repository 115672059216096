import { useI18n } from 'builder/hooks/useI18n'
import { Icon20 } from 'builder/components/Icon'
import { CompanyInterview } from 'builder/modules/interview/types'
import { QUESTION_DURATION } from 'builder/views/Interview/constants'
import { TRANSLATION_KEY } from '../CompanyInterview'
import { Logo } from '../../Logo/Logo'
import { Badge } from '../../../Badge/Badge'
import {
  Subtitle,
  JobTitle,
  BadgesContainer,
  InterviewCardContainer,
  TimeIcon,
  Header,
  Bottom,
} from './styles'

export type SmallInterviewCardProps = {
  companyInterview: CompanyInterview
  onClick: () => void
}

export const Card = ({ companyInterview, onClick }: SmallInterviewCardProps) => {
  const { i18n } = useI18n()

  return (
    <InterviewCardContainer onClick={onClick}>
      <Header>
        <TimeIcon>
          <Icon20.Time />
          {i18n.t(`${TRANSLATION_KEY}.min`, {
            minutes: companyInterview.questions_count * QUESTION_DURATION,
          })}
        </TimeIcon>
        <BadgesContainer>
          <Badge $question={false} $status="initiated">
            {i18n.t(`${TRANSLATION_KEY}.interview`)}
          </Badge>
        </BadgesContainer>
      </Header>
      <Bottom>
        <Logo src={companyInterview.logo_url} />
        <JobTitle>{companyInterview.job_title}</JobTitle>
        <Subtitle>{`${i18n.t(`${TRANSLATION_KEY}.at_preposition`)}  ${
          companyInterview.company_name
        } • ${companyInterview.questions_count} ${i18n.t(
          `${TRANSLATION_KEY}.questions`,
        )}`}</Subtitle>
      </Bottom>
    </InterviewCardContainer>
  )
}
