import { useCancellationFormContext } from '../../FormSection'
import DesktopViewV7 from './DesktopViewV7'
import MobileAndTabletView from './MobileAndTabletView'
import MobileAndTabletViewV7 from './MobileAndTabletViewV7'
import DesktopView from './DesktopView'

const ContentView = ({ showV7 }: { showV7: boolean }) => {
  const { views } = useCancellationFormContext()
  if (views.isDesktop) {
    if (showV7) {
      return <DesktopViewV7 />
    } else {
      return <DesktopView />
    }
  } else {
    if (showV7) {
      return <MobileAndTabletViewV7 />
    } else {
      return <MobileAndTabletView />
    }
  }
}

export default ContentView
