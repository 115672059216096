import styled, { css, ThemeProps } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import { Shadows } from 'builder/styles/shadows'
import { TooltipProps } from 'builder/components/Tooltip/Tooltip'
import { Align, Position } from './types'

const getTranslateY = (offset: number, position?: Position) => {
  if (position === 'top' || position === 'bottom') {
    return `translateY(${position === 'top' ? `-${offset}px` : `${offset}px`})`
  }
}

const getTranslateX = (align?: Align) => {
  return `translateX(${align === 'center' ? '-50%' : 0})`
}

export const TooltipBody = styled.div<ThemeProps<TooltipProps>>`
  ${Typography.Caption};
  ${Shadows.dark.mid};
  font-weight: 400;
  letter-spacing: 0.3px;
  white-space: ${props => (props.theme.multiline ? 'normal' : 'nowrap')};
  position: absolute;
  color: ${Colors.White};
  padding: 8px 12px;
  width: ${props => (props.theme.multiline ? props.theme.width || '200px' : 'auto')};
  border-radius: 4px;
  background-color: ${Colors.Neutral90};
  user-select: none;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  transform: ${(props: ThemeProps<TooltipProps>) => getTranslateY(8, props.theme.position)};
  ${props => `${props.theme.position === 'top' ? 'bottom' : 'top'}: 100%`};

  ${props =>
    props.theme.align === 'center' &&
    css`
      left: 50%;
      transform: ${(props: ThemeProps<TooltipProps>) =>
        `${getTranslateY(8, props.theme.position)} ${getTranslateX(props.theme.align)}`};
    `};

  ${props =>
    props.theme.align === 'left' &&
    css`
      left: ${props.theme.offset ?? 0}px;
    `};

  ${props =>
    props.theme.align === 'right' &&
    css`
      right: ${props.theme.offset ?? 0}px;
    `};

  ${props =>
    props.theme.position === 'right' &&
    css`
      bottom: initial;
      top: 50%;
      transform: translateY(-50%) translateX(calc(100% + 8px));
    `}

  ${props =>
    props.theme.position === 'left' &&
    css`
      bottom: initial;
      left: initial;
      top: 50%;
      transform: translateY(-50%) translateX(calc(-100% - 8px));
    `}
`

export const Container = styled.div<ThemeProps<TooltipProps>>`
  position: relative;

  &:hover,
  &:focus-within {
    ${TooltipBody} {
      opacity: 1;
      transform: ${(props: ThemeProps<TooltipProps>) =>
        `${getTranslateY(16, props.theme.position)} ${getTranslateX(props.theme.align)}`};

      ${props =>
        props.theme.position === 'right' &&
        css`
          transform: translateY(-50%) translateX(calc(100% + 16px));
        `};

      ${props =>
        props.theme.position === 'left' &&
        css`
          transform: translateY(-50%) translateX(calc(-100% - 16px));
        `};
    }
  }
`
