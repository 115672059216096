import styled from 'styled-components'
import Mixins from 'builder/styles/mixins'
import Colors, { hexToRgba } from 'builder/styles/colors'

export const Container = styled.div`
  position: relative;

  /* react-autosuggest changes the cursor over text fields to 'pointer', but we don't need that */
  & [aria-controls] {
    cursor: initial;
  }

  /* library uses 'ul'/'li' tags as list and element wrappers, so we have to reset default styles */
  & .react-autosuggest__suggestions-list,
  & .react-autosuggest__suggestion {
    margin: 0;
    padding: 0;
    list-style: none;
  }
`

export const Menu = styled.div<{ isEmpty: boolean }>`
  display: ${props => (props.isEmpty ? 'none' : 'block')};
  position: absolute;
  z-index: 3;
  top: 100%;
  left: 0;
  right: 0;
  padding: 6px 0;
  border-radius: 0 0 3px 3px;
  ${Mixins.Shadow};
  background-color: ${Colors.Neutral10};
  transition: transform 0.14s ease;
  transform-origin: 50% 0;
  max-height: 400px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 2px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${Colors.Neutral30};
    border-radius: 6px;
  }
`

export const MenuItem = styled.div<{ isHighlighted: boolean }>`
  padding: 6px 16px;
  cursor: pointer;
  color: ${props => (props.isHighlighted ? Colors.Blue50 : Colors.Neutral90)};
  background-color: ${props =>
    props.isHighlighted ? hexToRgba(Colors.Blue20, 0.5) : 'transparent'};
`
