import { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { i18n as I18n } from 'builder/utils/i18n'
import trim from 'lodash/trim'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useResume } from 'builder/hooks/useResume'
import Checkbox from 'builder/components/Checkbox'
import { useFields, useToggleableNames } from './hooks'
import Preview from './Preview'
import Services from './Services'

import {
  Container,
  Header,
  HeaderContent,
  HeaderTitle,
  HeaderDescription,
  Form,
  Fieldset,
  FormError,
  FormRow,
  Field,
  FieldSuccessIcon,
  FieldWarningIcon,
  FormActions,
  FormCheckbox,
  BackButton,
  Column,
  SubmitButton,
  SubmitButtonText,
  SubmitButtonSpinner,
  Link,
} from './styles'

const CoRegistration = ({ resumeId, services, backUrl, onSubmit, isSending, errors }) => {
  const resume = useResume(resumeId)
  const [checkedNames, toggleService] = useToggleableNames(services)
  const [fields, setField] = useFields({
    city: '',
    zip_code: '',
    desired_job_title: '',
    first_name: '',
    last_name: '',
    terms_agree: false,
  })
  const [dirty, setDirty] = useState({})

  // prefill form with resume fields
  useEffect(() => {
    if (!resume) return

    const prefill = (name, value) => {
      if (!value) return
      setField(name, value)
      setDirty(items => ({ ...items, [name]: true }))
    }

    prefill('city', resume.city)
    prefill('zip_code', resume.postalCode)
    prefill('desired_job_title', resume.position)
    prefill('first_name', resume.firstName)
    prefill('last_name', resume.lastName)
  }, [resume, setField])

  const handleSubmit = event => {
    event.preventDefault()
    let isValid = fields.terms_agree && checkedNames.length > 0

    for (const name in fields) {
      isValid = isValid && validate(fields[name])
      setDirty(items => ({ ...items, [name]: true }))
    }

    isValid && onSubmit({ ...fields, boards: checkedNames, resume_id: resumeId })
  }

  const handleChange = event => {
    const { name, value } = event.target

    setField(name, value)
    setDirty(values => ({ ...values, [name]: true }))
  }

  const { isDesktop } = useMediaQueries()

  return (
    <Container>
      <Column>
        <Header>
          <HeaderContent>
            <HeaderTitle>{I18n.t('builder.coreg.form.title')}</HeaderTitle>
            <HeaderDescription>{I18n.t('builder.coreg.form.description')}</HeaderDescription>
          </HeaderContent>
        </Header>

        {!isDesktop && (
          <Services services={services} checkedNames={checkedNames} toggleService={toggleService} />
        )}

        <Form onSubmit={handleSubmit}>
          <Fieldset>
            {errors.map((error, index) => (
              <FormError key={index}>{error}</FormError>
            ))}
            <FormRow>
              <Field
                name="desired_job_title"
                label={I18n.t('builder.coreg.form.job_title')}
                placeholder={I18n.t('builder.coreg.form.job_title_placeholder')}
                value={fields.desired_job_title}
                onChange={handleChange}
                inputIcon={dirty.desired_job_title && fieldIcon(fields.desired_job_title)}
                disabled={isSending}
              />
            </FormRow>
            <FormRow>
              <Field
                name="first_name"
                label={I18n.t('builder.coreg.form.first_name')}
                value={fields.first_name}
                onChange={handleChange}
                inputIcon={dirty.first_name && fieldIcon(fields.first_name)}
                disabled={isSending}
              />
              <Field
                name="last_name"
                label={I18n.t('builder.coreg.form.last_name')}
                value={fields.last_name}
                onChange={handleChange}
                inputIcon={dirty.last_name && fieldIcon(fields.last_name)}
                disabled={isSending}
              />
            </FormRow>
            <FormRow>
              <Field
                name="city"
                label={I18n.t('builder.coreg.form.city')}
                placeholder={I18n.t('builder.coreg.form.city_placeholder')}
                value={fields.city}
                onChange={handleChange}
                inputIcon={dirty.city && fieldIcon(fields.city)}
                disabled={isSending}
              />
              <Field
                name="zip_code"
                label={I18n.t('builder.coreg.form.zip_code')}
                placeholder={I18n.t('builder.coreg.form.zip_code_placeholder')}
                value={fields.zip_code}
                onChange={handleChange}
                inputIcon={dirty.zip_code && fieldIcon(fields.zip_code)}
                disabled={isSending}
              />
            </FormRow>
            <FormCheckbox>
              <Checkbox
                name="terms_agree"
                label={I18n.t('builder.coreg.form.agree')}
                onChange={() => setField('terms_agree', !fields.terms_agree)}
                checked={Boolean(fields.terms_agree)}
                invalid={dirty.terms_agree && !fields.terms_agree}
              />
            </FormCheckbox>
            <FormActions>
              {backUrl && (
                <BackButton as={Link} to={backUrl}>
                  {I18n.t('builder.coreg.form.back')}
                </BackButton>
              )}
              <SubmitButton isSending={isSending} type="submit">
                <SubmitButtonText isSending={isSending}>
                  {I18n.t('builder.coreg.form.submit')}
                </SubmitButtonText>
                {isSending && <SubmitButtonSpinner />}
              </SubmitButton>
            </FormActions>
          </Fieldset>
        </Form>
      </Column>
      {isDesktop && (
        <Column>
          <Preview resume={resume} />
          <Services services={services} checkedNames={checkedNames} toggleService={toggleService} />
        </Column>
      )}
    </Container>
  )
}

CoRegistration.propTypes = {
  resumeId: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSending: PropTypes.bool,
  services: PropTypes.array,
  errors: PropTypes.array,
  backUrl: PropTypes.string,
}

const validate = value => trim(value).length > 0

const fieldIcon = value => (validate(value) ? <FieldSuccessIcon /> : <FieldWarningIcon />)

export default memo(CoRegistration)
