import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import { Icon20 } from 'builder/components/Icon'

type Props = {
  $isActive?: boolean
}

export const StyledButton = styled.button<Props>`
  border: none;
  outline: none;
  padding: 0 6px;
  width: 32px;
  border-radius: 4px;
  background-color: ${Colors.Indigo10};
  color: ${Colors.Indigo50};
  transition: background-color 0.1s, color 0.1s;
  cursor: pointer;
  display: flex;

  ${props =>
    props.$isActive &&
    css`
      background-color: ${Colors.Indigo50};
      color: ${Colors.White};
    `}

  ${Media.Hover`
    &:hover {
      background-color: ${Colors.Indigo40};
      color: ${Colors.White};
    }
  `}
`

export const Icon = styled(Icon20.ArrowTarget2)`
  // this needed for correct useClickOutside behavior, because clicks on SVGElement are ignored
  pointer-events: none;
`
