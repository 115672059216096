import styled from 'styled-components'
import { Icon24 } from 'builder/components/Icon'
import Colors, { hexToRgba } from 'builder/styles/colors'

export const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 8px;
  background-color: ${Colors.Neutral10};
`

export const ButtonContainer = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 24px;
  margin: 0;
  border: none;
  background: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.15s, color 0.15s;

  &:hover,
  &:focus-visible {
    background-color: ${hexToRgba(Colors.Blue20, 0.5)};
    color: ${Colors.Blue50};
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${Colors.Neutral20};
  }
`

export const ButtonIcon = styled.div`
  display: flex;
  flex-shrink: 0;
  margin-right: 16px;
  color: ${Colors.Neutral50};
  transition: color 0.15s;

  ${ButtonContainer}:hover & {
    color: ${Colors.Blue50};
  }
`

export const ButtonText = styled.div`
  flex-grow: 1;
`

export const ButtonArrow = styled(Icon24.Chevron)`
  margin-left: 16px;
  flex-shrink: 0;
  color: ${Colors.Blue50};
`
