import styled from 'styled-components'
import Media from 'builder/styles/media'
import { Container as BaseContainer } from 'builder/components/Container'
import { HorizontalTransitionFade } from 'builder/components/HorizontalTransitionFade'

export const Container = styled(HorizontalTransitionFade)`
  padding-bottom: 80px;
  width: calc(100% - 10px);

  ${Media.Tablet`
    padding-bottom: 48px;
  `};

  ${Media.Phone`
    min-height: calc(100vh - 64px);
    height: calc(100vh - 64px);
    padding-bottom: 48px;
    justify-content: space-between;
    flex-direction: column;
    display: flex;
    width: inherit;
  `}
`

export const FormContainer = styled(BaseContainer)`
  display: flex;
  margin-top: 40px;
  flex-flow: row wrap;
  justify-content: center;

  ${Media.Phone`
    margin-top: 32px;
    flex-grow: 1;
  `}
`
