import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Icon24 from 'builder/components/Icon'
import Button from 'builder/components/Button'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  max-width: 1120px;
  width: calc(100% - 40px);
  margin: 0 auto;
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: flex-start;
  position: fixed;
  width: 100%;
  max-width: 736px;
  background-color: ${Colors.White};
  border-radius: 16px;
  z-index: 999;
  padding: 32px 16px 32px 32px;
  max-height: 700px;
  position: fixed;

  ${Media.Phone`
    padding: 20px 10px 20px 20px;
    gap: 12px;
    height: 80%;
    max-width: 90%;
  `}
`

export const MainContent = styled.div`
  gap: 40px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  overflow-y: auto;

  ${Media.Phone`
    padding-right: 10px;
  `}

  ::-webkit-scrollbar {
    width: 5.41px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${Colors.Neutral20};
    border-radius: 3px;
  }

  ${Media.Tablet`
    gap: 24px;
  `}
`

export const BookServiceContainer = styled.div`
  width: calc(100% - 40px);
  padding: 16px 0;
  position: fixed;
  top: calc(100vh - 50vh);
  background-color: ${Colors.White};
`

export const StyledButton = styled(Button)`
  width: 160px;
  height: 48px;
`

export const Close = styled.div`
  position: absolute;
  top: 0;
  right: 32px;
  padding-left: 2px;
  padding-top: 2px;
  width: 28px;
  height: 28px;
  background: ${Colors.Neutral10};
  border-radius: 32px;
  color: ${Colors.Neutral30};
  cursor: pointer;
  transition: color 0.1s;
  top: 32px;

  ${Media.Phone`
    top: 22px;
  `}
`

export const CloseIcon = styled(Icon24.Close)`
  display: block;
`

export const Title = styled.p`
  height: 28px;
  ${Typography.S};
  ${FontWeights.Medium};
`

export const ModalHeading = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 46px;

  ${Media.Phone`
    padding-bottom: 28px;
  `}
`

export const ModalFooter = styled.div`
  display: flex;
  justify-content: end;
  padding-top: 80px;

  ${Media.Phone`
    padding-top: 40px;
  `}
`
