import styled from 'styled-components'
import Media from 'builder/styles/media'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 50%;
  max-width: 960px;
  min-height: 100vh;

  ${Media.Laptop`
    width: 100%;
    margin: 0 auto;
  `};

  ${Media.Desktop`
    max-width: 960px;
  `};
`
