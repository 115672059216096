import styled from 'styled-components'
import { Typography, FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import { Media } from 'builder/styles/media'

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
  user-select: none;

  ${Media.Tablet`
    margin-bottom: 24px;
  `};

  ${Media.Phone`
    margin-bottom: 16px;
  `};
`

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  margin-right: 32px;
  gap: 16px;

  ${Media.Phone`
    flex-direction: column;
    align-items: flex-start;
  `};
`

export const HeaderAside = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  width: 174px;

  ${Media.Tablet`
    width: auto;
  `};

  ${Media.Phone`
    width: 100%;
  `};
`

export const Title = styled.h1`
  ${Typography.M};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral90};
`

export const Premium = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background-color: ${Colors.Indigo10};
  border-radius: 40px;
  padding: 4px 10px;
`

export const PremiumText = styled.div`
  ${FontWeights.DemiBold};
  color: ${Colors.Indigo70};
`

export const Icon = styled.div`
  color: ${Colors.Indigo70};
`

export const PoweredBy = styled.img`
  width: 24px;
  height: 24px;
  margin-bottom: -1px;
`

export const SubText = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
  padding-right: 4px;
`

export const LogoName = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral90};
`
