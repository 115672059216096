import { trackInternalEvent } from '@rio/tracking'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useI18n } from 'builder/hooks/useI18n'
import { InternalEvents } from 'builder/components/SubscriptionCancellationFormNew/common/types'
import { ButtonTheme } from 'builder/components/Button'
import { useResumeTailoringBanner } from 'builder/components/DocumentEditor/ResumeTailoringBanner/useResumeTailoringBanner'
import { useJobTitleFromLatestUpdateResume } from 'builder/components/FindJob/hooks/useJobTitleFromLatestUpdateResume'
import { useConfig } from 'builder/hooks/useConfig'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import {
  Container,
  Title,
  Item,
  ItemContainer,
  ButtonsContainer,
  PremiumButton,
  CancelSubcriptionText,
  Icon,
  Header,
  Links,
  ItemButton,
  ItemBold,
  FeedBackContainer,
  WrittenFeedback,
  FeedbackAuthor,
  FeedbackAuthorAvatar,
  FeedbackAuthorData,
  FeedbackAuthorName,
  CareerUser,
  Feedback,
  FeedBackTextContainer,
  Quotes,
  ModalVideoContainer,
  IconMobileContainer,
  IconMobile,
  VideoPreview,
  ModalVideoIframe,
} from './styles'
import ARROW_TARGET_ICON from './assets/icons/arrow_target.svg'
import INTERVIEW_PREP_ICON from './assets/icons/interview_prep.svg'
import JOB_SEARCH_ICON from './assets/icons/job_search.svg'
import FEEDBACK_AUTHOR_AVATAR from './assets/feedback_author.png'
import VIDEO_PREVIEW_WEB from './assets/video_preview_web.png'
import VIDEO_PREVIEW_TABLET from './assets/video_preview_tablet.png'
import VIDEO_PREVIEW_PHONE from './assets/video_preview_phone.png'

interface SubscriptionCancellationViewSocialProofProps {
  onContinueCancellation: () => void
}

const ITEMS = (t: (key: string) => string) => [
  {
    id: 'tailoring',
    src: ARROW_TARGET_ICON,
    text: t('builder.subscription_cancellation_v4.social_proof.items.tailoring.text'),
    buttonText: t('builder.subscription_cancellation_v4.social_proof.items.tailoring.button_text'),
    boldText: t('builder.subscription_cancellation_v4.social_proof.items.tailoring.bold_text'),
  },
  {
    id: 'interview_prep',
    src: INTERVIEW_PREP_ICON,
    text: t('builder.subscription_cancellation_v4.social_proof.items.practice.text'),
    buttonText: t('builder.subscription_cancellation_v4.social_proof.items.practice.button_text'),
    boldText: t('builder.subscription_cancellation_v4.social_proof.items.practice.bold_text'),
  },
  {
    id: 'salary_analyzer',
    src: JOB_SEARCH_ICON,
    text: t('builder.subscription_cancellation_v4.social_proof.items.salaries.text'),
    buttonText: t('builder.subscription_cancellation_v4.social_proof.items.salaries.button_text'),
    boldText: t('builder.subscription_cancellation_v4.social_proof.items.salaries.bold_text'),
  },
]

export const SubscriptionCancellationViewSocialProof = ({
  onContinueCancellation,
}: SubscriptionCancellationViewSocialProofProps) => {
  const navigate = useNavigate()
  const { i18n } = useI18n()
  const [showVideoModal, setShowVideoModal] = useState(false)
  const { onBannerClick: openTailoring } = useResumeTailoringBanner('builder')
  const latestUpdatedResume = useJobTitleFromLatestUpdateResume()
  const config = useConfig()
  const { isPhone, isTablet } = useMediaQueries()
  const isRioDomain = config?.features.international
  const videoSrc = isRioDomain ? 'qzew2LkMMvg' : '4u3VgN9H3b8'

  useEffect(() => {
    trackInternalEvent('see_social_proof_page')
  }, [])

  const buttonsOnClick: Record<string, () => void> = {
    tailoring: () => {
      navigate(`/resumes/${latestUpdatedResume.resume?.id}/edit`)
      openTailoring()
    },
    interview_prep: () => navigate('/interview-preparation/start'),
    salary_analyzer: () => navigate('/offer-analyzer'),
  }

  return (
    <>
      <Container>
        <Header>{i18n.t('builder.subscription_cancellation_v4.social_proof.header')}</Header>
        <Title>{i18n.t('builder.subscription_cancellation_v4.social_proof.title')}</Title>
        <Links>
          {ITEMS(i18n.t).map(item => (
            <ItemContainer key={item.src}>
              <Icon src={item.src} />
              <IconMobileContainer>
                <IconMobile src={item.src} />
                <Item>
                  {item.text}
                  <ItemBold>{item.boldText}</ItemBold>
                </Item>
              </IconMobileContainer>
              <ItemButton
                theme={ButtonTheme.secondary}
                onClick={() => {
                  trackInternalEvent('promo_acceptance', { where: 'cancellation', promo: item.id })
                  buttonsOnClick[item.id]()
                }}
              >
                {item.buttonText}
              </ItemButton>
            </ItemContainer>
          ))}
        </Links>
        <FeedBackContainer>
          <VideoPreview
            src={
              isPhone ? VIDEO_PREVIEW_PHONE : isTablet ? VIDEO_PREVIEW_TABLET : VIDEO_PREVIEW_WEB
            }
            onClick={() => {
              setShowVideoModal(true)
              trackInternalEvent('click_cio_video', { where: 'cancellation' })
            }}
          />
          <WrittenFeedback>
            <FeedbackAuthor>
              <FeedbackAuthorAvatar src={FEEDBACK_AUTHOR_AVATAR} />
              <FeedbackAuthorData>
                <FeedbackAuthorName>Stefan Johnson</FeedbackAuthorName>
                <CareerUser>
                  {i18n.t('builder.subscription_cancellation_v4.social_proof.career_io_user')}
                </CareerUser>
              </FeedbackAuthorData>
            </FeedbackAuthor>
            <FeedBackTextContainer>
              <Quotes>’’</Quotes>
              <Feedback>
                {i18n.t('builder.subscription_cancellation_v4.social_proof.feedback')}
              </Feedback>
            </FeedBackTextContainer>
          </WrittenFeedback>
        </FeedBackContainer>
        <ButtonsContainer>
          <PremiumButton
            onClick={() => {
              trackInternalEvent(InternalEvents.abort)
              navigate('/')
            }}
          >
            {i18n.t('builder.subscription_cancellation_v4.social_proof.premium_button')}
          </PremiumButton>
          <CancelSubcriptionText onClick={onContinueCancellation}>
            {i18n.t('builder.subscription_cancellation_v4.social_proof.cancel_button')}
          </CancelSubcriptionText>
        </ButtonsContainer>
      </Container>
      {showVideoModal && (
        <ModalVideoContainer
          onClick={() => {
            setShowVideoModal(false)
            trackInternalEvent('close_video', { where: 'cancellation' })
          }}
        >
          <ModalVideoIframe
            allowFullScreen={false}
            allow="autoplay"
            src={`https://www.youtube.com/embed/${videoSrc}?autoplay=1&rel=0&controls=0`}
          />
        </ModalVideoContainer>
      )}
    </>
  )
}
