import styled from 'styled-components'
import Media from 'builder/styles/media'
import { Typography, FontWeights } from 'builder/styles/typography'
import Shadows from 'builder/styles/shadows'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Button from 'builder/components/Button'
import { Container as BaseContainer } from 'builder/components/Container'
import { CareerGoalCard as Card } from 'builder/components/CareerGoalCard'

export const Container = styled(BaseContainer)`
  padding-top: 64px;
  padding-bottom: 24px;
  text-align: center;
  overflow: hidden;

  ${Media.Tablet`
    padding-top: 48px;
  `};

  ${Media.Phone`
    padding-top: 24px;
  `};
`

export const LogoImage = styled.img`
  ${Shadows.lightWithBorder.mid};
  display: block;
  width: 40px;
  height: 40px;
  margin: 0 auto 16px;
  border-radius: 12px;
  background-color: ${Colors.White};
`

export const Title = styled.div`
  ${Typography.L};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral90};
  margin-bottom: 16px;
`

export const Description = styled.div`
  ${FontWeights.Medium};
  display: block;
  margin: 0 auto 24px auto;
  max-width: 545px;

  b {
    font-weight: inherit;
    color: ${Colors.Blue50};
  }
`

export const ActionButton = styled(Button)`
  margin-bottom: 16px;

  ${Media.Phone`
    width: 100%;
  `};
`

export const ActionDescription = styled.div`
  ${Typography.Tiny};
  display: block;
  color: ${Colors.Blue80};
  margin: 0 auto 48px auto;
  max-width: 352px;
  opacity: 0.4;

  ${Media.Phone`
    margin-bottom: 32px;
  `};
`

export const Cards = styled.div`
  margin: 0 -100vw;
  display: flex;
  justify-content: center;
`

export const CardsList = styled.div`
  display: flex;
  transform-origin: 50% 0;
  transform: scale(0.82835);

  ${Media.Tablet`
    transform: scale(0.8);
  `};
`

export const CareerGoalCard = styled(Card)`
  position: relative;
  top: 38px;
  margin: 0 12px;

  &:after {
    content: '';
    position: absolute;
    left: -1px;
    bottom: -1px;
    right: -1px;
    height: 110px;
    background: linear-gradient(
      to bottom,
      ${hexToRgba(Colors.White, 0)} 0%,
      ${Colors.White} 70px,
      ${Colors.White} 100%
    );
  }

  &:nth-child(3) {
    top: 0;

    &:after {
      display: none;
    }
  }
`
