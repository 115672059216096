import { Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { actions, selectors } from 'builder/modules/offerAnalyzer'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useI18n } from 'builder/hooks/useI18n'
import { TextField } from 'builder/components/TextField'
import {
  ApiSection,
  AsyncAutosuggest,
  createNewSuggestionsApiFetcher,
} from 'builder/components/AsyncAutosuggest'
import { Field } from './styles'
import RestrictionNotice from './RestrictionNotice'

const noticeImage = require('images/job_search/job-search-banner.svg')

const JobOfferDetails = () => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()

  const { jobTitle, companyName, location } = useTypedSelector(selectors.fields)
  const errors = useTypedSelector(selectors.errors)

  const setFieldValue = (
    name: 'titleError' | 'companyError' | 'locationError',
    keyValue: 'jobTitle' | 'companyName' | 'location',
    value: string | number,
  ) => {
    dispatch(actions.setErrors({ ...errors, [name]: '' }))
    dispatch(actions.updateFields({ [keyValue]: value }))
  }

  const handleFetchJobTitles = async (query: string) => {
    const fetcher = createNewSuggestionsApiFetcher('job-titles', {
      section: ApiSection.CareerPages,
    })
    const jobTitles = await fetcher(query)
    dispatch(actions.setLastFetchedJobTitles(jobTitles.map(({ text }) => text)))
    return jobTitles
  }

  const handleFetchLocations = async (query: string) => {
    const fetcher = createNewSuggestionsApiFetcher('locations', {
      section: ApiSection.CareerPages,
    })
    const locations = await fetcher(query)
    dispatch(actions.setLastFetchedLocations(locations.map(({ text }) => text)))
    return locations
  }
  return (
    <Fragment>
      <Field>
        <AsyncAutosuggest
          name={i18n.t('builder.offer_analyzer.job_title')}
          fetchItems={handleFetchJobTitles}
          value={jobTitle}
          debounceInterval={300}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setFieldValue('titleError', 'jobTitle', (e.target as HTMLInputElement).value)
          }
        >
          {inputProps => (
            <TextField
              {...inputProps}
              error={errors.titleError}
              placeholder={i18n.t('builder.offer_analyzer.job_title_input_placeholder')}
              label={i18n.t('builder.offer_analyzer.job_title')}
            />
          )}
        </AsyncAutosuggest>
      </Field>
      <Field>
        <TextField
          name="companyName"
          value={companyName}
          error={errors.companyError}
          placeholder={i18n.t('builder.offer_analyzer.company_input_placeholder')}
          label={i18n.t('builder.offer_analyzer.company_name')}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setFieldValue('companyError', 'companyName', e.currentTarget.value)
          }
        />
      </Field>
      <Field>
        <AsyncAutosuggest
          name={i18n.t('builder.offer_analyzer.location')}
          fetchItems={handleFetchLocations}
          value={location}
          debounceInterval={200}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setFieldValue('locationError', 'location', (e.target as HTMLInputElement).value)
          }
        >
          {inputProps => (
            <TextField
              {...inputProps}
              error={errors.locationError}
              placeholder={i18n.t('builder.offer_analyzer.location_input_placeholder')}
              label={i18n.t('builder.offer_analyzer.location')}
            />
          )}
        </AsyncAutosuggest>
      </Field>
      <RestrictionNotice noticeImage={noticeImage} />
    </Fragment>
  )
}
export default JobOfferDetails
