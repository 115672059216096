import times from 'lodash/times'
import * as Styles from './styles'

export const SkeletonColumns = () => (
  <Styles.SkeletonAtomsColumns>
    {times(3).map(key => (
      <Styles.Skeleton key={key} />
    ))}
  </Styles.SkeletonAtomsColumns>
)
