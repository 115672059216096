import { SuggestionSelectedEventData } from 'react-autosuggest'
import { trackInternalEvent } from '@rio/tracking'
import { AsyncAutosuggest } from 'builder/components/AsyncAutosuggest'
import { TextField } from 'builder/components/TextField'
import { useI18n } from 'builder/hooks/useI18n'
import { JobSuggestionType } from 'builder/modules/interview/types'
import { useJobTitles } from 'builder/hooks/useJobTitles'

const TRANSLATION_KEY = 'builder.interview_prep_view.job_title_async_autosuggest'

interface Props {
  jobTitle?: string
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void
  onChangeSuggestion: (value: JobSuggestionType) => void
  hasError?: boolean
  disabled?: boolean
  trackingParameter: 'test' | 'custom' | 'interview_dash' | 'library'
  placeholder?: string
}

export const JobTitleAsyncAutosuggest = ({
  jobTitle,
  onChangeSuggestion,
  onChange,
  hasError,
  disabled,
  trackingParameter,
  placeholder,
}: Props) => {
  const { i18n } = useI18n()
  const { mutateAsync: getJobTitle } = useJobTitles()
  const onSuggestionSelected = (
    _: React.FormEvent<unknown>,
    { suggestion }: SuggestionSelectedEventData<JobSuggestionType>,
  ) => {
    onChangeSuggestion(suggestion)
    trackInternalEvent('pick_up_title', {
      label: 'interview_prep',
      term: suggestion.text,
      parameter: trackingParameter,
    })
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e)
  }

  return (
    <AsyncAutosuggest<JobSuggestionType>
      highlightedQuery
      value={jobTitle}
      onChange={handleChange}
      onSuggestionSelected={onSuggestionSelected}
      fetchItems={async (query: string) => {
        const res = await getJobTitle(query)
        if (jobTitle !== query) {
          trackInternalEvent('typing_job_tile', {
            label: 'interview_prep',
            term: query,
            parameter: trackingParameter,
            jobTitlesLength: res.length,
          })
        }

        return res.map(title => ({
          text: title.standardTitle,
          id: title.id,
        }))
      }}
    >
      {inputProps => (
        <TextField
          {...inputProps}
          error={hasError}
          name="jobTitle"
          label={
            hasError
              ? i18n.t(`${TRANSLATION_KEY}.error_message`)
              : i18n.t(`${TRANSLATION_KEY}.label`)
          }
          placeholder={placeholder || i18n.t(`${TRANSLATION_KEY}.placeholder_v2`)}
          disabled={disabled}
        />
      )}
    </AsyncAutosuggest>
  )
}
