import styled from 'styled-components'
import Media from 'builder/styles/media'

export const ParentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  width: 928px;
  justify-content: center;

  ${Media.Tablet`
    width: 540px;
    margin-top: 32px;
  `}

  ${Media.Phone`
    width: 335px;
    margin-top: 24px;
    margin-bottom: 24px
  `}
`
