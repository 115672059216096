import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Button } from 'builder/components/Button'
import { Icon20 } from 'builder/components/Icon'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;
`

export const ActionButton = styled(Button)`
  width: 32px;
  height: 32px;
  border-radius: 8px;
  padding: 0;
  border: none;
  background-color: ${Colors.Green20};
  color: ${Colors.Green50};
  margin-top: auto;
  transition: background-color 150ms ease-out 0s;
  position: absolute;
  right: 24px;
  bottom: 24px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;

  ${Media.Phone`
    bottom: 20px;
  `};
`

export const Content = styled.div<{ isActive: boolean }>`
  gap: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 16px;
  background: ${props => (props.isActive ? Colors.Green10 : Colors.Neutral5)};
  transition: background-color 150ms ease-out;
  width: 100%;
  position: relative;
  cursor: pointer;
  margin-bottom: 16px;

  &:hover {
    background: ${props => (props.isActive ? '#E2F1E8' : '')};

    ${ActionButton} {
      background: ${Colors.Green50};
      color: ${Colors.White};
    }
  }

  ${Media.Tablet`
    gap: 24px;
  `};

  ${Media.Phone`
    flex-direction: column;
    text-align:center;
    gap: 48px;
`};
`
export const ActionImageContainer = styled.div`
  padding: 24px 0 0 40px;
  width: 268px;
  height: 208px;
`

export const ActionImage = styled.img`
  width: 100%;
`

export const Header = styled.div<{ isActive: boolean }>`
  ${Typography.S};
  ${FontWeights.DemiBold};
  width: 100%;
  color: ${props => props.isActive && Colors.Green80};

  ${Media.Phone`
    padding: 0 0 24px 24px;
    text-align:left;
  `};
`

export const SubHeader = styled.div<{ isActive: boolean }>`
  color: ${props => (props.isActive ? Colors.Green80 : Colors.Neutral50)};
  width: 592px;

  ${Media.Tablet`
    width: 339px;
  `};
`

export const ActionDetails = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;

  ${Media.Phone`
    width: 100%;
  `};
`

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;

  ${Media.Phone`
    flex-direction: column;
  `};
`

export const Description = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
`

export const MailLink = styled.a`
  ${Typography.Caption};
  color: ${Colors.Blue50};
  margin: 0 2px;
`

export const RightArrow = styled(Icon20.ChevronDown)`
  transform: rotate(-90deg);
  transition: color 150ms ease-out;
`
