import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import { CentralColumn, Section } from '../../styles'

export const Container = styled(Section)`
  background-color: ${Colors.Blue10};
  color: ${Colors.Blue90};
`

export const HeaderImage = styled.img`
  width: 280px;

  ${Media.Tablet`
    width: 210px;
  `};

  ${Media.Phone`
    width: 160px;
  `};
`

export const ListContainer = styled(CentralColumn)`
  display: grid;
  grid-template-columns: 302px auto;
  gap: 16px;

  ${Media.Phone`
    grid-template-columns: auto;
  `};
`

export const ListHeader = styled.div`
  ${FontWeights.Medium}
`

export const MobileDescription = styled.div`
  display: flex;
  flex-flow: column;
  background-color: ${Colors.Neutral5};
  padding: 16px;
  gap: 2px;
  border-radius: 8px;
  color: ${Colors.Blue90};
  ${Typography.Caption};
`

export const MobileDescriptionHeader = styled.div`
  ${FontWeights.Medium};
`

export const MobileDescriptionText = styled.div``
