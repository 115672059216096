import { progressStep } from '../constants'
import { Container, Title, DotList, Dot } from './styles'

interface ProgressbarProps {
  activatedPage: number[]
  title: string
}

export const TabletProgressbar = ({ activatedPage, title }: ProgressbarProps) => {
  const renderStep = () => {
    return progressStep?.map(step => {
      return <Dot key={step?.page} isActive={activatedPage.includes(step?.page)} />
    })
  }

  return (
    <Container>
      <Title>{title}</Title>
      <DotList>{renderStep()}</DotList>
    </Container>
  )
}
