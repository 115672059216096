import { memo } from 'react'
import Icon from 'builder/components/Icon'
import {
  Pagination,
  PaginationLeftButton,
  PaginationRightButton,
  PaginationPageNumber,
} from './styles'

type Props = {
  hasPreviousPage: boolean
  hasNextPage: boolean
  currentPage: number
  totalPagesNumber: number
  handlePreviousPageClick: () => void
  handleNextPageClick: () => void
}

export const PaginationLayout = memo((props: Props) => {
  const {
    hasPreviousPage,
    hasNextPage,
    currentPage,
    totalPagesNumber,
    handlePreviousPageClick,
    handleNextPageClick,
  } = props

  return (
    <Pagination>
      <PaginationLeftButton onClick={handlePreviousPageClick} isDisabled={!hasPreviousPage}>
        <Icon.Chevron />
      </PaginationLeftButton>
      <PaginationPageNumber>
        {currentPage} / {totalPagesNumber}
      </PaginationPageNumber>
      <PaginationRightButton onClick={handleNextPageClick} isDisabled={!hasNextPage}>
        <Icon.Chevron />
      </PaginationRightButton>
    </Pagination>
  )
})
