// ---
// Reflow will trigger the browser to synchronously calculate the style and layout*.
// This is also called reflow or layout thrashing, and is common performance bottleneck.
//
// This method is mostly use for onEnter callback inside <Transition /> component.
//
// Resources:
// - https://gist.github.com/paulirish/5d52fb081b3570c81e3a
// - https://github.com/reactjs/react-transition-group/blob/master/src/CSSTransition.js#L142
// ---
export function reflow(element: HTMLElement) {
  // eslint-disable-next-line
  element.offsetTop
}
