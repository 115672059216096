import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

export const WizardHeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 32px;
  background-color: ${Colors.White};
  border-bottom: 1px solid ${Colors.Neutral15};
  z-index: 3;

  ${Media.Laptop`
    width: 100%;
  `}

  ${Media.Desktop`
    max-width: 960px;
  `}

  ${Media.Phone`
    padding: 16px 20px;
  `}
`
