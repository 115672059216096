import React from 'react'
import { useAutoApply } from 'builder/views/AutoApply/hooks/useAutoApply'

import { Banner } from './Banner/Banner'
import { Widget } from './Widget/Widget'
import { WidgetLoading } from './Widget/WidgetLoading'

const AutoApply = () => {
  const { isUserOnAutoApplyPlan, hasUserCompletedQuestionnaire, isCareerProfileDataLoading } =
    useAutoApply()

  if (isCareerProfileDataLoading) {
    return <WidgetLoading />
  }

  return isUserOnAutoApplyPlan && hasUserCompletedQuestionnaire ? <Widget /> : <Banner />
}

export default React.memo(AutoApply)
