import { useCallback, useEffect, useRef, useState } from 'react'
import * as React from 'react'
import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Icon from 'builder/components/Icon'
import {
  SliderContainer,
  SliderContent,
  SliderPlaceholder,
  TestimonialsLeftButton,
  TestimonialsRightButton,
  NavigationButtons,
  SectionHeading,
  HeaderText,
  Card,
  SubText,
  Name,
  Testimonial,
  SwiperContainer,
} from './styles'
import { TESTIMONIALS } from './constants'

SwiperCore.use([Navigation])

const Testimonials = () => {
  const [slideIndex, setSlideIndex] = useState(0)

  const prevRef: React.RefObject<HTMLButtonElement> = useRef(null)
  const nextRef: React.RefObject<HTMLButtonElement> = useRef(null)
  const [feedbacks, setFeedbacks] = useState('')

  useEffect(() => {
    setFeedbacks('coaching')
  }, [feedbacks])

  const handleSlideChange = useCallback(({ realIndex }) => {
    setSlideIndex(realIndex)
  }, [])

  return (
    <SliderContainer data-testid="testimonials-swiper">
      <SectionHeading>
        <HeaderText>What users are saying about our coaches</HeaderText>
        <NavigationButtons>
          <TestimonialsRightButton ref={nextRef} data-testid="rightButton">
            <Icon.Chevron />
          </TestimonialsRightButton>
          <TestimonialsLeftButton ref={prevRef} data-testid="leftButton">
            <Icon.Chevron />
          </TestimonialsLeftButton>
        </NavigationButtons>
      </SectionHeading>

      {TESTIMONIALS.length ? (
        <SwiperContainer>
          <SliderContent>
            <Swiper
              navigation={{
                prevEl: prevRef.current,
                nextEl: nextRef.current,
              }}
              initialSlide={slideIndex}
              slidesPerView="auto"
              loop={true}
              spaceBetween={16}
              slideToClickedSlide={true}
              onSlideChange={handleSlideChange}
            >
              {TESTIMONIALS.map(({ id, description, name }) => (
                <SwiperSlide key={`template-${id}`} style={{ width: '100%', maxWidth: '1000px' }}>
                  <Card key={id}>
                    <Testimonial>
                      <Name>{name}</Name>
                      <SubText>{description}</SubText>
                    </Testimonial>
                  </Card>
                </SwiperSlide>
              ))}
            </Swiper>
          </SliderContent>
        </SwiperContainer>
      ) : (
        <SliderPlaceholder />
      )}
    </SliderContainer>
  )
}

export default Testimonials
