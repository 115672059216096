import ModalOverlay from 'builder/components/ModalOverlay'
import Button, { ButtonTheme } from 'builder/components/Button'
import { useI18n } from 'builder/hooks/useI18n'
import { SkipButton } from '../SkipButton/SkipButton'
import StartScreenImg from '../assets/startScreen.gif'
import { BACKGROUND_COLOR_OVERLAY } from '../JoyRidesJobSearchTrackingSteps'
import { Container, Content, Img, Title, WrapperText } from './styles'

const TRANSLATION = 'builder.job_search.onboarding.start_screen'

interface IProps {
  onSkip: (event: React.MouseEvent<HTMLElement>) => void
  onClick: () => void
}

export const StartScreen = ({ onSkip, onClick }: IProps) => {
  const { i18n } = useI18n()

  return (
    <ModalOverlay
      overlayFadeDuration={350}
      contentSlideDuration={350}
      background={BACKGROUND_COLOR_OVERLAY}
    >
      <Container>
        <Img src={StartScreenImg} />
        <WrapperText>
          <Title>{i18n.t(`${TRANSLATION}.title`)}</Title>
          <Content>{i18n.t(`${TRANSLATION}.content`)}</Content>

          <Button theme={ButtonTheme.default} onClick={onClick}>
            {i18n.t(`${TRANSLATION}.button`)}
          </Button>
        </WrapperText>
      </Container>
      <SkipButton onSkip={onSkip} />
    </ModalOverlay>
  )
}
