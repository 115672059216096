import styled from 'styled-components'
import Media from 'builder/styles/media'

export const ExploreCareersContainer = styled.div`
  max-width: 284px;
  width: 100%;
  flex: 0 0 284px;

  ${Media.Phone`
    flex:0 0 264px;   
    margin-bottom:24px;
    max-height:200px;
    height: 100%;
  `};
`
export const ExploreCareersImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  height: 100%;

  ${Media.Phone`
    margin-top: 20px;
  `};
`
export const ExploreCareersImage = styled.img``

export const ExploreCareersUserContainer = styled.div`
  position: absolute;
  left: 32px;
  bottom: 53px;
  border-radius: 50%;

  ${Media.Phone`
    bottom: 75px;
     left: 33px;
  `};
`
export const ExploreCareersUserImage = styled.img`
  height: 50px;
  width: 50px;
  padding: 2px;
  border-radius: 50%;
`
