let instance = null

class FocusManager {
  constructor() {
    if (!instance) {
      instance = this
    }

    this._focusHandlers = new Map()
    return instance
  }

  subscribe(id, fn) {
    this._focusHandlers.set(id, fn)
  }

  unsubscribe(id) {
    this._focusHandlers.delete(id)
  }

  focus(id) {
    const focusHandler = this._focusHandlers.get(id)
    if (!focusHandler) return
    setTimeout(focusHandler, 1)
  }
}

export default new FocusManager()
