import styled from 'styled-components'
import OpenableSnackbar from 'builder/components/SnackBar/OpenableSnackbar'
import { Text, OpenButton } from 'builder/components/SnackBar/OpenableSnackbar/styles'
import Typography, { FontWeights } from 'builder/styles/typography'

export const SnackbarContainer = styled.div`
  position: absolute;
  width: 100%;
  z-index: 1;
  transform: translateY(24px);
  transition: transform 0.3s ease;
`

export const Snackbar = styled(OpenableSnackbar)`
  width: fit-content;
  margin: 0 auto;

  ${Text} {
    ${Typography.Body}
  }

  ${OpenButton} {
    ${Typography.Caption}
    ${FontWeights.DemiBold}
  }
`
