import { useI18n } from 'builder/hooks/useI18n'
import { Container, Header, ItemWrapper, Item, TickIcon } from './styles'

const bullets = ['item_1', 'item_2', 'item_3']

export const FreeUserDeclaration = () => {
  const { i18n } = useI18n()

  return (
    <Container>
      <Header>{i18n.t('builder.auto_tailoring.tailoring_model.result.free_user.title')}</Header>
      <ItemWrapper>
        {bullets.map(bulletKey => (
          <Item key={bulletKey}>
            <TickIcon />
            {i18n.t(`builder.auto_tailoring.tailoring_model.result.free_user.bullets.${bulletKey}`)}
          </Item>
        ))}
      </ItemWrapper>
    </Container>
  )
}
