import styled from 'styled-components'
import { gradient } from 'builder/styles/keyframes'
import Colors from 'builder/styles/colors'

export const Skeleton = styled.div`
  animation: ${gradient} 1s linear infinite;
  background: ${Colors.Neutral10};
  background: linear-gradient(
    to right,
    ${Colors.Neutral10} 5%,
    #eaeef6 15%,
    ${Colors.Neutral10} 25%
  );
  border-radius: 2px;
  width: 100%;
  height: 100%;
  background-size: 100vw 100vh;
`

export const SkeletonRow = styled(Skeleton)`
  height: 32px;
`

export const SkeletonGraph = styled(Skeleton)`
  height: 198px;
`

export const SkeletonInfo = styled(Skeleton)`
  width: 123px;
  height: 36px;
`

export const SkeletonCircle = styled(Skeleton)`
  width: 48px;
  height: 48px;
  border-radius: 50%;
`

export const Container = styled.div`
  width: 100%;
`

export const SkeletonCompanyLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`

export const SkeletonCompany = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`

export const SkeletonCompanyName = styled(Skeleton)`
  width: 120px;
  height: 24px;
`

export const SkeletonCompanyPositions = styled(Skeleton)`
  width: 88px;
  height: 24px;
`

export const SkeletonAtomsColumns = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;
  height: 32px;
`

export const SkeletonAtomsRows = styled(SkeletonAtomsColumns)`
  height: auto;
  flex-direction: column;
`
