import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { actions, CareerPathStep, getCardById, selectors } from 'builder/modules/careerPath'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { CareerPathHeader } from 'builder/components/CareerPathHeader'
import { Matrix } from 'builder/components/CareerPathLevelChoice/components/Matrix'
import { useDebouncedEffect } from 'builder/hooks/useDebouncedEffect'
import { Loader } from './components/Loader'
import Form from './components/Form'
import * as Styles from './styles'

export const ANIMATION_TIMEOUT_DELAY = 300
export const ACTIVATION_TIMEOUT_DELAY = 10

const LABEL = {
  label: 'career_pathways',
}

export const CareerPathLevelChoice = () => {
  const dispatch = useDispatch()
  const { isLaptop } = useMediaQueries()
  const [activeCardId, setActiveCardId] = useState<string | null>(null)
  // state to check if any card is selected/activated
  const [isAnyActivated, setIsAnyActivated] = useState<boolean>(false)

  const matrix = useTypedSelector(selectors.matrix)
  const location = useTypedSelector(selectors.location)
  const occupation = useTypedSelector(selectors.occupation)
  const isFailed = useTypedSelector(selectors.isMatrixFailed)
  const isLoaded = useTypedSelector(selectors.isMatrixLoaded)

  // Avoid blinking when one card becomes not active till another one becomes active
  useDebouncedEffect(() => setIsAnyActivated(Boolean(activeCardId)), ACTIVATION_TIMEOUT_DELAY, [
    activeCardId,
  ])

  const handleInputChange = () => {
    setActiveCardId(null)
    setIsAnyActivated(false)
  }

  const handleActivateCard = useCallback((id: string | null) => {
    if (id) trackInternalEvent('select_career_card', { card: id, ...LABEL })
    setActiveCardId(id)
  }, [])

  const handleNext = useCallback(
    (id: string) => {
      const { occupation } = getCardById(id, matrix) || {}

      occupation && dispatch(actions.fetchSummary({ occupation, location, countofapicalls: 0 }))
      dispatch(actions.setChoice(id))
      dispatch(actions.setStep(CareerPathStep.CONFIRMATION))
      dispatch(actions.setPrevStep(CareerPathStep.LEVEL_CHOICE))
    },
    [dispatch, matrix, location],
  )

  useEffect(() => {
    trackInternalEvent('enter_career_path_page', LABEL)
  }, [])

  useEffect(() => {
    // No sense to load matrix w/o start occupation
    if (!isLoaded) {
      dispatch(actions.fetchOccupationMatrix({ occupation, location }))
    }
  }, [dispatch, isLoaded, location, occupation])

  return (
    <Styles.Container>
      <CareerPathHeader>Choose your desired role</CareerPathHeader>
      <Form isAnyActivated={isAnyActivated} onInputChange={handleInputChange} />
      {!isLoaded ? (
        <Loader isFailed={isFailed} />
      ) : (
        <Matrix
          onNext={handleNext}
          matrix={matrix}
          isSwiped={isLaptop}
          onCardSelect={handleActivateCard}
          activeCardId={activeCardId}
          isAnyActivated={isAnyActivated}
        />
      )}
    </Styles.Container>
  )
}
