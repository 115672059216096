import styled from 'styled-components'
import Media from 'builder/styles/media'

export const Main = styled.main`
  flex-grow: 1;
  padding: 64px 40px;

  ${Media.Tablet`
    padding-left: 20px;
    padding-right: 20px;
  `}
`
