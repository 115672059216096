import { useMemo } from 'react'
import { Resume } from '@rio/types'
import { Report } from 'builder/components/Tuner'
import { useI18n } from 'builder/hooks/useI18n'
import { formatDocumentPreviewUrl } from 'builder/utils/formatDocumentPreviewUrl'
import Colors from 'builder/styles/colors'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/panel'
import { DocumentTypes } from 'builder/modules/constants'
import {
  Container,
  ImagePreview,
  Score,
  ScoreContent,
  ScoreLabel,
  ScoreValue,
  Percent,
} from './styles'

type Props = {
  resume: Resume
  report: Report
}

export const TunnerScore = () => {
  const { i18n } = useI18n()

  const resumes = useTypedSelector(store => selectors.documentsByType(store, DocumentTypes.resume))
  // Get the last resume's score values
  const lastResume = resumes[resumes.length - 1]
  const resumeScore = lastResume.resumeScore
  const averageScore = lastResume.averageScore
  const jobTitle = lastResume.jobTitle
  const bannerScore = jobTitle !== null && jobTitle !== '' ? averageScore : resumeScore

  const barColor = useMemo(() => {
    if (bannerScore > 70) return Colors.Green40
    if (bannerScore > 30) return Colors.Amber40
    return Colors.Red40
  }, [bannerScore])

  return (
    <Score barColor={barColor} progress={bannerScore / 100}>
      <ScoreContent>
        <ScoreValue>
          {bannerScore}
          <Percent>%</Percent>
        </ScoreValue>
        <ScoreLabel>{i18n.t('builder.resume_tuner.banner.score')}</ScoreLabel>
      </ScoreContent>
    </Score>
  )
}

export const ResumeTunerVisual = ({ resume }: Props) => {
  const imageSrc = formatDocumentPreviewUrl(resume, { size: 256 })

  return (
    <Container>
      <ImagePreview src={imageSrc} />
      <TunnerScore />
    </Container>
  )
}
