import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import { Icon20 } from 'builder/components/Icon'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div<{ isCritiquePage?: boolean }>`
  display: absolute;
  height: 80px;
  left: 160px;
  margin-top: ${props => !props.isCritiquePage && -25}px;
  margin-bottom: ${props => !props.isCritiquePage && 15}px;
  background: ${Colors.Indigo10};

  ${Media.Tablet`
    height: 100px;
  `};

  ${Media.Phone`
    height: 144px;
  `};

  @media (max-width: 280px) {
    height: 160px;
  }
`

export const PopupPhoto = styled.img`
  width: 48px;
  height: 48px;
  position: absolute;
  margin-top: 40px;
  margin-left: 46px;
  flex-shrink: 0;
  border-radius: 50%;
  margin-top: 16px;
  margin-left: 16px;
`

export const PopupIconWrapper = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 50%;
  margin-top: 38px;
  margin-left: 48px;
  color: ${Colors.White};
  box-shadow: 0 0 0 4px ${Colors.Indigo10};
  background-color: ${Colors.Indigo80};
`

export const PopupIcon = styled(Icon20.Star)`
  width: 24px;
  height: 24px;
`
export const Title = styled.div`
  position: absolute;
  height: 24px;
  padding-top: 28px;
  padding-left: 84px;
  ${Typography.Body};
  ${FontWeights.DemiBold};
  display: flex;
  align-items: center;
  color: ${Colors.Indigo80};

  ${Media.Phone`
    padding-top: 5px;
    height: 45px;
    padding-left: 16px;
    ${Typography.Body};
    ${FontWeights.DemiBold};
    margin-bottom: 4px;
    color: ${Colors.Indigo80};
  `};
`

export const Text = styled.div`
  position: absolute;
  height: 20px;
  padding-top: 50px;
  padding-left: 84px;
  ${Typography.Caption};
  ${FontWeights.Regular};
  display: flex;
  align-items: center;
  color: ${Colors.Indigo80};

  ${Media.Tablet`
    margin-top:10px;
    width: 550px;
  `};

  ${Media.Phone`
    width: 100%;
    height: 85px;
    margin-top: -5px;
    padding-left: 16px;
    padding-right: 16px;
    ${Typography.Caption};
    ${FontWeights.Regular};
    flex-grow: 1;
    color: ${Colors.Indigo80};
  `};

  @media (max-width: 280px) {
    margin-top: 5px;
  }
`

const Button = styled.div`
  ${Typography.Caption};
  ${FontWeights.DemiBold};
  border-radius: 4px;
  color: ${Colors.White};
  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px;
  gap: 2px;

  position: absolute;
  height: 36px;
`

export const ReviewButton = styled(Button)<{ disabled?: boolean }>`
  width: ${props => (props.disabled ? 88 : 148)}px;
  background-color: ${props => (props.disabled ? Colors.Neutral20 : Colors.Indigo80)};
  margin-top: 22px;
  right: 15px;
  color: ${props => (props.disabled ? Colors.Neutral50 : Colors.White)};

  ${Media.Phone`
  margin-top: 90px;
  left: 17px;
  `};

  @media (max-width: 280px) {
    margin-top: 110px;
  }
`

export const RightArrow = styled(Icon20.ChevronDown)`
  transform: rotate(-90deg);
  position: absolute;
  right: 3px;
`
