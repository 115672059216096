import { FC, useMemo } from 'react'
import { useI18n } from 'builder/hooks/useI18n'
import Icon from 'builder/components/Icon'
import {
  Progress,
  ProgressLabel,
  ProgressInfo,
  ProgressCount,
  ProgressCounter,
  ProgressFace,
} from './styles'

interface TextAreaProgressProps {
  length: number
  recommendedLength: number
}

const TextAreaProgress: FC<TextAreaProgressProps> = ({ length, recommendedLength }) => {
  const { i18n } = useI18n()

  const FaceIcon = useMemo(() => {
    if (length >= recommendedLength) return Icon.FaceGood
    if (length > recommendedLength / 3) return Icon.FaceNormal
    return Icon.FaceBad
  }, [length, recommendedLength])

  return (
    <Progress>
      <ProgressLabel>
        {i18n.t('builder.resume_editor.phrase_suggestion.progress_label', {
          count: recommendedLength,
        })}
      </ProgressLabel>

      <ProgressInfo isIdle={length === 0}>
        <ProgressCounter>
          <ProgressCount>{length}</ProgressCount>
          {length < recommendedLength && <span>&nbsp;/&nbsp;{recommendedLength}+</span>}
        </ProgressCounter>

        <ProgressFace isVisible={length > 0}>
          <FaceIcon />
        </ProgressFace>
      </ProgressInfo>
    </Progress>
  )
}

export default TextAreaProgress
