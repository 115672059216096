import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'

import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { actions, ColumnName, JobCreatePayload, selectors } from 'builder/modules/jobTracking'
import { stripHtml } from 'builder/utils/stripHTML'

import { Job } from './JobSearchResult/types'
import { TabType } from './types'

type JobTrackingUtilities = {
  isPresent: boolean
  handleAddJobBoard: () => void
  newTrackerCard: JobCreatePayload
}

export const useJobTrackingUtilities = (
  job: Job & { position?: number },
  selectedTabId?: TabType,
): JobTrackingUtilities => {
  const dispatch = useDispatch()

  const newTrackerCard: JobCreatePayload = {
    status: ColumnName.wishlist,
    title: job.job_title,
    company: job.company,
    link: job.url,
    description: stripHtml(job.description),
    open: false,
    external_slug_id: job.external_slug_id,
    location: job.location,
    logo: job.company_logo,
  }

  const cards = useTypedSelector(selectors.selectJobs)

  const jobTrackingCard = cards.find(card => {
    return card.externalSlugId === newTrackerCard.external_slug_id
  })

  const isPresent = !!jobTrackingCard && jobTrackingCard.status !== ColumnName.recommended
  const handleAddJobBoard = () => {
    if (!isPresent) {
      trackInternalEvent('add_to_job_tracker', {
        title: job.job_title,
        label: 'icon',
        position: job.position,
        parameter: selectedTabId,
      })
    }

    isPresent
      ? dispatch(actions.deleteJobRequest({ id: jobTrackingCard.id }))
      : dispatch(actions.createJobRequest(newTrackerCard))
  }

  return {
    isPresent,
    handleAddJobBoard,
    newTrackerCard,
  }
}
