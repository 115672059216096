import map from 'lodash/map'
import { useI18n } from 'builder/hooks/useI18n'
import { MODES } from '../constants'
import { TabsContainer, TabsItem } from './styles'

interface TabsProps {
  value: MODES
  onChange: (value: MODES) => void
}

export const Tabs = ({ value, onChange }: TabsProps) => {
  const { i18n } = useI18n()

  return (
    <TabsContainer>
      {map(MODES, mode => (
        <TabsItem isActive={value === mode} key={mode} onClick={() => onChange(mode)}>
          {i18n.t(`builder.avatar_editor.tabs.${mode}`)}
        </TabsItem>
      ))}
    </TabsContainer>
  )
}
