import styled from 'styled-components'
import Colors from 'builder/styles/colors'

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 601;
  display: flex;
  background-color: ${Colors.Neutral100};
`

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 80px);
  width: 100%;
`
