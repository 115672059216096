import { useNavigate } from 'react-router-dom'

import { useEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'

import { ButtonSize } from 'builder/components/Button'
import { StartCompleteScreenRoute } from '../../constants'
import {
  Container,
  InfoHeader,
  InfoIcon,
  InfoHeadContiner,
  InfoSubHeader,
  InfoActions,
  InfoActionIcon,
  InfoActionDescription,
  InfoActionStart,
  InfoActionDescriptionBold,
  StartButton,
  InfoBoxContainer,
  InfoActionContainer,
  HorizontalDivider,
  VideoContainer,
  InfoBox,
} from './styles'
import starImage from './assets/star-image.png'
import { VideoPlayer } from './VideoPlayer'

const TRANSLATION = `builder.auto_apply.start_screen`

export const StartScreenView = () => {
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const { isPhone, isTablet } = useMediaQueries()

  const isTabletOrMobile = isPhone || isTablet

  const onStart = () => {
    navigate(`/auto-apply/${StartCompleteScreenRoute.RESUME_SELECT}`)
  }

  useEffect(() => {
    trackInternalEvent('enter_welcome_screen', {
      label: 'total_job_search_solution',
    })
  }, [])

  const InfoActionComponent = (
    <InfoActions>
      <InfoActionDescription>
        <InfoActionIcon />
        {i18n.t(`${TRANSLATION}.time`)}
        <InfoActionDescriptionBold>
          {i18n.t(`${TRANSLATION}.time_minutes`)}
        </InfoActionDescriptionBold>
      </InfoActionDescription>
      {isTabletOrMobile && (
        <InfoActionStart onClick={onStart}>{i18n.t(`${TRANSLATION}.button`)}</InfoActionStart>
      )}
    </InfoActions>
  )

  return (
    <Container>
      <InfoBox>
        <InfoBoxContainer>
          <InfoIcon $width={isPhone ? 42 : 72} $height={isPhone ? 48 : 83} src={starImage} />
          <InfoHeadContiner>
            <InfoHeader>{i18n.t(`${TRANSLATION}.header`)}</InfoHeader>
            <InfoSubHeader>{i18n.t(`${TRANSLATION}.sub_header`)}</InfoSubHeader>
            {!isTabletOrMobile && (
              <StartButton size={isPhone ? ButtonSize.small : ButtonSize.default} onClick={onStart}>
                {i18n.t(`${TRANSLATION}.button`)}
              </StartButton>
            )}
          </InfoHeadContiner>

          {isTabletOrMobile && (
            <VideoContainer>
              <VideoPlayer />
            </VideoContainer>
          )}
        </InfoBoxContainer>

        {isTabletOrMobile ? (
          InfoActionComponent
        ) : (
          <InfoActionContainer>
            <HorizontalDivider />
            {InfoActionComponent}
          </InfoActionContainer>
        )}
      </InfoBox>
      {!isTabletOrMobile && <VideoPlayer />}
    </Container>
  )
}
