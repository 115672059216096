import styled, { css } from 'styled-components'
import { Optional } from 'packages/types'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import {
  Cards,
  PromotionTopic,
} from 'builder/components/SubscriptionCancellationFormNew/common/types'
import usePromotionCardValues from 'builder/components/SubscriptionCancellationFormNew/hooks/usePromotionCardValues'

interface Props {
  topic: PromotionTopic
  activeCard: Optional<Cards>
}

export const CardSection = styled.div<Props>(({ activeCard, topic }) => {
  const { getCardStyles } = usePromotionCardValues()
  const cardStyles = getCardStyles(topic)
  const isCardClicked = activeCard === topic
  const isOtherCardClicked = activeCard && !isCardClicked
  return css`
    box-sizing: border-box;
    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: ${Colors.Neutral15};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 186px;
    height: 160px;
    background: ${isCardClicked ? cardStyles.backgroundColor : ''};

    & img {
      filter: ${isOtherCardClicked ? 'grayscale(1)' : ''};
    }

    & > div {
      border-radius: 16px;
    }

    &:hover {
      & img {
        filter: grayscale(0);
      }

      & > div {
        background-color: ${cardStyles.backgroundColor};
        border-radius: 12px;
        width: calc(100% - 8px);
        height: 152px;
        margin: 4px;
      }
    }

    ${Media.Tablet`
      width: 538px !important;
      height: 85px;
      border: none;
      &:hover {
        & > div {
          background-color: inherit;
        }
      }
    `}

    ${Media.Phone`
      display: grid;
      width: 333px !important;
      height: 80px;
      border: none;
      &:hover {
        & > div {
          background-color: inherit;
        }
      }
    `};
  `
})
