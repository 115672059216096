import { useEffect, useMemo } from 'react'
import * as React from 'react'
import type { Resume } from '@rio/types'

import constructSectionsData from 'builder/components/SectionsList/constructSectionsData'
import { SkillLevelData, LanguageLevelData } from 'builder/modules/generalEditor'
import { STEP_NAMES } from 'builder/modules/resumeEditor'

import PersonalDetailsSection from 'builder/components/PersonalDetailsSection'
import ProfileSummary from 'builder/components/ProfileSummary'
import Anchor from 'builder/components/Anchor'
import { Section } from 'builder/components/SectionsList/SortableSection'
import { SectionData } from 'builder/components/Section'

import { WizardTips } from './components/WizardTips'
import { trackStepVisit } from './utils'
import useWizard from './hooks/useWizard'
import { WizardContainer } from './styles'

type WizardProps = {
  resume: Resume
  levels?: {
    language: LanguageLevelData[]
    skill: SkillLevelData[]
  }
}

export const Wizard: React.FC<WizardProps> = ({ resume, levels }) => {
  const {
    currentStep,
    updateCard,
    addCard,
    toggleCard,
    isCardOpen,
    deleteCard,
    moveCard,
    renameSection,
  } = useWizard()

  const currentSection: SectionData | null = useMemo(() => {
    const sections = constructSectionsData(resume)
    const section = sections.find(item => item.id === currentStep.id)
    return section ? { ...section, sortable: false } : null
  }, [resume, currentStep.id])

  // Track the first step visit on first component mount
  useEffect(() => {
    trackStepVisit(STEP_NAMES.details)
  }, [])

  return (
    <WizardContainer>
      {currentStep.id === STEP_NAMES.details && (
        <Anchor id={STEP_NAMES.details} scrollOffsetTop={70}>
          <PersonalDetailsSection resume={resume} />
        </Anchor>
      )}
      {currentStep.id === STEP_NAMES.profile && (
        <Anchor id={STEP_NAMES.profile} scrollOffsetTop={70}>
          <ProfileSummary />
        </Anchor>
      )}
      {currentSection && (
        <Section
          key={currentStep.id}
          section={currentSection}
          levels={levels}
          hideSkillLevel={resume.hideSkillLevel}
          locale={resume.locale}
          foldableDuration={0}
          onRename={renameSection}
          onAdd={addCard}
          isCardOpen={isCardOpen}
          onCardChange={updateCard}
          onCardDelete={deleteCard}
          onCardMove={moveCard}
          onCardToggle={toggleCard}
        />
      )}

      <WizardTips />
    </WizardContainer>
  )
}
