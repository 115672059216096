import { Course as CourseType } from 'builder/modules/careerPath2'
import { Content, Title, Description } from './styles'

interface BodyProps {
  course: CourseType
}

export const Body = ({ course }: BodyProps) => {
  return (
    <Content>
      <Title>{course.title ?? ''}</Title>
      <Description>{course.description ?? ''}</Description>
    </Content>
  )
}
