import React from 'react'
import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'

import { useUser } from 'builder/hooks/useUser'
import { useI18n } from 'builder/hooks/useI18n'
import { Recruiter, Candidate, SubscriptionStatus } from 'builder/modules/resumeDistribution'
import useWebsiteHost from 'builder/components/CIO-Dasboard-2.0/hooks/useWebsiteHost'

import { List, ListItem, ListItemColumn } from 'builder/components/List'
import { ActionsSkeleton, RecruiterListSkeleton } from '../Skeleton/Skeleton'

import { TRANSLATION } from '../constants'
import * as Styles from './styles'

type RecruitersListProps = {
  candidate: Candidate
  recruiters: Recruiter[]
  isRecruitersLoading: boolean
}

export const RecruiterList = ({
  candidate,
  recruiters,
  isRecruitersLoading,
}: RecruitersListProps) => {
  const user = useUser()
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const { getHost } = useWebsiteHost()

  const isSubscriptionPaused = candidate.distributionStatus === SubscriptionStatus.paused

  const getLocation = (location: Recruiter['location']) => {
    return `${location.city}, ${location.state} ${location.country}`
  }

  const onClickViewRecruiters = () => {
    trackInternalEvent('rd_click_view_all_recruiters', { website_host: getHost })
    navigate('/resume-distribution/dashboard')
  }

  const ActionComponent = (
    <Styles.ActionContainer>
      <Styles.ShadowEffect />
      <Styles.ActionBtn onClick={onClickViewRecruiters}>
        {i18n.t(`${TRANSLATION}.action_btn`)}
      </Styles.ActionBtn>
    </Styles.ActionContainer>
  )

  if (isRecruitersLoading) {
    return (
      <Styles.SkeletonContainer>
        <RecruiterListSkeleton />
        <ActionsSkeleton />
      </Styles.SkeletonContainer>
    )
  }

  if (user?.hasPremiumFeatures && isSubscriptionPaused) {
    return (
      <>
        <Styles.PauseContainer>
          <Styles.PauseContainerText>
            {i18n.t(`${TRANSLATION}.pause_state`)}
          </Styles.PauseContainerText>
        </Styles.PauseContainer>
        {ActionComponent}
      </>
    )
  }

  return (
    <Styles.Container>
      <List>
        {recruiters?.slice(0, 3).map(recruiter => (
          <React.Fragment key={recruiter.name}>
            <ListItem padding="12px 0">
              <ListItemColumn
                header={<Styles.ListHeader text={recruiter.name} />}
                subText={<Styles.ListSubHeader text={recruiter.jobTitle} />}
              />
              <ListItemColumn
                header={<Styles.ListHeader text={recruiter.company} />}
                subText={<Styles.ListSubHeader text={getLocation(recruiter.location)} />}
              />
            </ListItem>
            <Styles.ListDivider />
          </React.Fragment>
        ))}
      </List>
      {!!recruiters?.length && ActionComponent}
    </Styles.Container>
  )
}
