import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Icon from 'builder/components/Icon'
import Media from 'builder/styles/media'

export const Container = styled.div`
  height: 64px;
  width: 100%;
  background-color: ${Colors.Neutral100};
  position: relative;
  top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  ${Media.AboveTablet`
    justify-content: center;
  `};
`

export const CloseIcon = styled(Icon.CloseLarge)`
  position: absolute;
  top: 20px;
  right: 32px;
  color: ${Colors.White};
  cursor: pointer;
`

export const ActionContainer = styled.div`
  gap: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.White};

  ${Media.Tablet`
    margin-left: 20px;
  `};
`
