import { useI18n } from 'builder/hooks/useI18n'
import { TRANSLATION_PREFIX } from '../InterviewPlayerView'
import { ActionButtonsLeft, CloseIcon, DashboardButton, MenuButton, Wrapper } from './styles'

type Props = {
  onSideMenuClick: () => void
  onDashboardClick: () => void
  onCloseClick: () => void
}

export const ActionButtons = (props: Props) => {
  const { onSideMenuClick, onDashboardClick, onCloseClick } = props
  const { i18n } = useI18n()

  return (
    <Wrapper>
      <ActionButtonsLeft>
        <MenuButton onClick={onSideMenuClick} />
        <DashboardButton onClick={onDashboardClick}>
          {i18n.t(`${TRANSLATION_PREFIX}.dashboard`)}
        </DashboardButton>
      </ActionButtonsLeft>
      <CloseIcon onClick={onCloseClick} />
    </Wrapper>
  )
}
