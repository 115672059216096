import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { Typography, FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Container = styled.div`
  width: 100%;
  max-width: 416px;
  margin: 0 auto;
`

export const Header = styled.div`
  margin-bottom: 40px;
  text-align: center;

  ${Media.Phone`
    margin-bottom: 32px;
  `}
`

export const Title = styled.div`
  ${Typography.XL};
  ${FontWeights.DemiBold};
  margin-bottom: 16px;
`

export const Description = styled.div`
  color: ${Colors.Neutral50};
`
