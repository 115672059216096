import { differenceWith, isEqual, intersectionWith } from 'lodash'
import { baseClient } from 'builder/modules/apiClient'
import Colors from 'builder/styles/colors'

import { ColumnName, Columns, JobCard, JobProgressionsPath } from './types'

export const fetchSkillsAndMatchScore = async (
  jobProgressionsPath: JobProgressionsPath[],
  skillsLabel: string[],
) => {
  const promises = await jobProgressionsPath.map(async (paths: JobProgressionsPath) => {
    let titles: JobProgressionsPath = {
      percentage: 0,
      target: '',
      timeSpan: '',
      matchScore: 0,
      skills: [],
    }
    const jobProgressionsPathPromises = new Promise(async resolve => {
      const params = { title: paths.target }
      await baseClient
        .get('/career-data/v1/title-skills', { params })
        .then(result => {
          const { data } = result

          const { hardSkills, softSkills } = data
          const requiredSkills = [...hardSkills, ...softSkills]

          const uniqueSkills = differenceWith(requiredSkills, skillsLabel, isEqual)
          const commonSkills = intersectionWith(requiredSkills, skillsLabel, isEqual)

          const matchScore = Math.ceil((commonSkills.length / requiredSkills.length) * 100)
          const skills = [...commonSkills, ...uniqueSkills]

          titles = { ...paths, matchScore, skills }
        })
        .catch(err => console.log(err))
      resolve(titles)
    })
    return jobProgressionsPathPromises
  })
  const jobProgressionsPathPromise = await Promise.all(promises)
  return jobProgressionsPathPromise
}

export const getScoreBasedColor = (property: string, score = 0) => {
  if (property === 'color') {
    if (score <= 20) {
      return Colors.Red50
    }

    if (score <= 50) {
      return Colors.Amber50
    }

    return Colors.Green50
  }

  if (score <= 20) {
    return Colors.Red10
  }

  if (score <= 50) {
    return Colors.Amber10
  }

  return Colors.Green10
}

const COLUMN_NAMES = Object.values(ColumnName)

export const columnize = (jobs: JobCard[]) => {
  const columns: Partial<Columns> = {}
  COLUMN_NAMES.forEach(name => {
    columns[name] = jobs.filter(job => job.status === name)
  })
  return columns as Columns
}
