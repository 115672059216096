import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as panelSelectors } from 'builder/modules/panel'
import useWebsiteHost from 'builder/components/CIO-Dasboard-2.0/hooks/useWebsiteHost'
import { actions, selectors } from 'builder/modules/careerCoaching'
import { navigate } from 'builder/modules/navigate'
import { useCareerServiceTitle } from 'builder/components/Panel/CareerGoal/hooks/useCareerServiceTitle'
import * as Styled from './styles'

const coachGroup = require('builder/components/CIO-Dasboard-2.0/assets/CoachGroup.svg')
const coach = require('builder/components/CIO-Dasboard-2.0/assets/CoachImage.svg')

const BookCoachButton = () => {
  const [isCoachBooked, setIsCoachBooked] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const slot = useSelector(selectors.coachBookingSlot)
  const dispatch = useDispatch()
  const { getHost } = useWebsiteHost()
  const selectedIndex = useTypedSelector(panelSelectors.activeTabId)
  const tab = useCareerServiceTitle({ index: selectedIndex })

  useEffect(() => {
    // fetching next slot date
    dispatch(actions.fetchCoachBookingSlot())
  }, [dispatch])

  const handleBookCoach = () => {
    navigate('/career-coaching')

    setIsCoachBooked(true)
    trackInternalEvent('click_book_a_coach', {
      label: tab?.name,
      website_host: getHost,
    })
  }

  // hover functionality
  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <>
      <Styled.BookCoachButton
        onMouseOver={handleMouseEnter}
        onMouseOut={handleMouseLeave}
        onClick={handleBookCoach}
        isBooked={isCoachBooked}
        isHovered={!isCoachBooked && isHovered}
      >
        {isCoachBooked ? (
          <Styled.NextCoachContainer>
            <Styled.NextCoachTitle>Next coach session</Styled.NextCoachTitle>
            <Styled.NextCoachTime>{slot}</Styled.NextCoachTime>
          </Styled.NextCoachContainer>
        ) : (
          <Styled.BookCoachText isHovered={!isCoachBooked && isHovered}>
            Book a Coach
          </Styled.BookCoachText>
        )}
        <Styled.CoachGroup isBooked={isCoachBooked} src={isCoachBooked ? coach : coachGroup} />
      </Styled.BookCoachButton>
    </>
  )
}

export default BookCoachButton
