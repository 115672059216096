/* eslint-disable max-len */
import { useCallback, useMemo, useState } from 'react'

import * as React from 'react'
import { ResumeCritique, ResumeCritiqueStatuses as STATUSES } from 'builder/modules/resumeReview'
import { ResumeReviewStatusUi } from './ResumeReviewStatusUi'

interface ResumeReviewStatusProps extends React.HTMLAttributes<HTMLDivElement> {
  critique: ResumeCritique
}

export const ResumeReviewStatusHost = ({ critique, ...rest }: ResumeReviewStatusProps) => {
  const { status, resumeId } = critique
  const [isOpen, toggle] = useState(true)
  const closedLocalStorageKey = `RESUME_${resumeId}_REVIEW_BANNER_CLOSED`

  const shouldRender = useMemo(() => {
    // Don't display a failed critique banner again if user closed if before
    if (status === STATUSES.failed && localStorage.getItem(closedLocalStorageKey)) return false
    // Hide banner if user just clicked "Close" button
    return isOpen
  }, [status, isOpen, closedLocalStorageKey])

  const handleClose = useCallback(() => {
    toggle(false)
    localStorage.setItem(closedLocalStorageKey, 'true')
  }, [closedLocalStorageKey])

  if (shouldRender) {
    return <ResumeReviewStatusUi {...rest} critique={critique} onClose={handleClose} />
  }

  return null
}
