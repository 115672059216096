import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Icon24 from 'builder/components/Icon'
import Media from 'builder/styles/media'

export const ProgressContainer = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 8px;
  position: relative;
  background: ${Colors.Blue50};
  padding: 3px;
`

export const ProgressBar = styled.div`
  width: 30px;
  text-align: center;
  border-radius: 8px;
  padding: 0 4px;
  position: absolute;
  left: 7.5px;
  bottom: -4px;
  background: ${Colors.White};

  color: ${Colors.White};
  ${Typography.Tiny};
  ${FontWeights.DemiBold};
`

export const ProgressBarStep = styled.div`
  width: 29px;
  height: 29px;
  flex: 0 0 29px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: ${Colors.White};
  color: ${Colors.Neutral90};

  ${Typography.Caption}
  ${FontWeights.DemiBold}

  ${Media.Phone`
    background: ${Colors.Blue10};
  `}
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const Header = styled.div`
  color: ${Colors.Neutral90};

  ${Typography.Caption}
  ${FontWeights.Medium}
`
export const SubHeader = styled.div`
  color: ${Colors.Neutral50};

  ${Typography.Tiny}
  ${FontWeights.Regular}
`

export const ArrowActionContainer = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 8px;
  background: ${Colors.Neutral10};
  display: flex;
  align-items: center;
  justify-content: center;

  ${Media.Phone`
    background: ${Colors.Blue20};
  `}
`
export const ArrowActionIcon = styled(Icon24.ArrowRight)``

export const VerticalDivider = styled.div`
  border-right: 1px solid ${Colors.Neutral15};
  height: 24px;
  margin: 0 24px;
`

export const Container = styled.div`
  gap: 12px;
  display: flex;
  padding: 8px 12px;
  align-items: center;
  background: white;
  border-radius: 16px;
  cursor: pointer;
  background: ${Colors.White};

  &:hover {
    background: ${Colors.Blue10};

    ${ArrowActionContainer} {
      background: ${Colors.Blue20};
    }

    ${ProgressBarStep} {
      background: ${Colors.Blue10};
    }

    ${SubHeader} {
      color: ${Colors.Blue80};
    }
  }

  &:active {
    background: ${Colors.Blue20};

    ${ArrowActionContainer} {
      background: ${Colors.Blue20};
    }

    ${ProgressBarStep} {
      background: ${Colors.Blue20};
    }

    ${SubHeader} {
      color: ${Colors.Blue90};
    }
  }

  ${Media.Phone`
    width: 100%;
    justify-content: space-between;
    background: ${Colors.Blue10};
    padding: 12px 16px;
  `}
`
