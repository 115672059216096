import { SyntheticEvent } from 'react'
import JobTracker from 'builder/components/FeatureIcon/icons/jobTracker/enabled.svg'
import { Wrapper } from './styles'

export const Logo = ({ src = JobTracker }) => {
  const onLoadImage = (e: SyntheticEvent<HTMLImageElement>) => {
    if (Math.abs(e.currentTarget.naturalWidth / e.currentTarget.naturalHeight) > 1.5) {
      e.currentTarget.src = JobTracker
    }
    e.currentTarget.height = 40
  }

  const onErrorImage = (e: SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = JobTracker
  }

  return (
    <Wrapper>
      <img src={src} onLoad={onLoadImage} onError={onErrorImage} />
    </Wrapper>
  )
}
