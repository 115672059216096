import styled from 'styled-components'
import Media from 'builder/styles/media'
import { CircleButton } from 'builder/components/Button'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;
  margin-top: 64px;
  display: flex;
  flex-direction: column;

  ${Media.Laptop`
    padding: 0 20px;
  `}

  ${Media.Phone`
    margin-top: 48px;
  `}
`

export const BorderBottom = styled.div`
  height: 1px;
  width: 100%;
  max-width: 1120px;
  margin: auto;
  background: ${Colors.Neutral15};
  margin-top: 64px;

  ${Media.Phone`
    margin-top: 48px;
  `}
`

export const Cards = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  ${Media.Tablet`
    gap: 8px;
    width: 540px;
    margin: auto;
  `}

  ${Media.Phone`
    width: 352px;
  `}
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px 0px 0px;
  gap: 4px;
  width: 276px;

  &:first-child {
    ${Media.Tablet`
      padding-top: 32px;
    `}
  }

  ${Media.Tablet`
    width: 100%;
  `}
`

export const NavigationButton = styled(CircleButton).attrs({
  size: 28,
})`
  ${Media.Phone`
    bottom: calc(50% - 40px);
  `}
`

export const StepsButton = styled(NavigationButton)`
  min-width: 28px;
  height: 28px;

  ${Media.Tablet`
    margin-top:-5px;
  `}
`

export const StepsHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-self: start;
  position: relative;
`

export const Divider = styled.div`
  width: 48px;
  height: 1px;
  background: ${Colors.Blue30};
  margin-top: 12px;

  ${Media.Tablet`
    position: absolute;
    top: 50px;
    left: -10px;
    transform: rotate(90deg);
  `}
`

export const Text = styled.p`
  ${FontWeights.Medium};
  align-self: start;
  margin-left: 12px;
  width: 175px;

  ${Media.Tablet`
    width: 100%
  `}

  ${Media.Phone`
    width: 100%
  `}
`

export const SubText = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
  padding: 0 0 0 15px;
  width: 225px;

  ${Media.Tablet`
    width: 100%;
    padding: 0 0 0 40px;
  `}

  ${Media.Phone`
    width: 100%;
    padding: 0 0 0 40px;
  `}
`

export const HeaderText = styled.div`
  ${FontWeights.Medium};
  ${Typography.S};
`
