import { useEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { UseExampleEventLabels, UseExampleEvents } from 'builder/modules/constants'
import { useResumeExampleContext } from '../ResumeExampleContext'
import { ExamplesContainer, ResumeCard, ResumeTitle, ResumeImg, ViewIcon } from '../styles'
import { ResumeExamplesProps } from '../types'
import view from './view.png'

const ResumeExamples: React.FC<ResumeExamplesProps> = ({ scrollableDivRef }) => {
  const { filteredResumeExamples, setSelectedResumeExample } = useResumeExampleContext()

  useEffect(() => {
    trackInternalEvent(UseExampleEvents.seeExamplesLibrary, {
      label: UseExampleEventLabels.examplesPrefill,
    })
  }, [])

  return (
    <ExamplesContainer ref={scrollableDivRef}>
      {filteredResumeExamples.map(example => (
        <ResumeCard
          key={example.id}
          onClick={() => {
            trackInternalEvent(UseExampleEvents.clickExample, {
              label: UseExampleEventLabels.examplesPrefill,
              jobTitle: example.jobTitle,
            })
            setSelectedResumeExample(example)
          }}
        >
          <ResumeTitle>{example.jobTitle}</ResumeTitle>
          <ResumeImg src={example.compressedPreviewImageUrl || example.previewImageUrl} />
          <ViewIcon src={view} alt="View resume example" />
        </ResumeCard>
      ))}
    </ExamplesContainer>
  )
}

export default ResumeExamples
