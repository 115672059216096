import { useRef, useState, useEffect } from 'react'
import { throttle } from 'lodash'
import { i18n as I18n } from 'builder/utils/i18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { Heading, CategoriesAndExamples } from '../styles'
import Categories from './Categories'
import JobTitle from './JobTitle'
import ResumeExamples from './ResumeExamples'

const ViewAllExamples = () => {
  const title = I18n.t('builder.use_example.choose_example')
  const [scrollState, setScrollState] = useState({
    isSticky: true,
    isInTop: true,
    previousScrollPosition: 0,
  })
  const scrollableDivRef = useRef<HTMLDivElement | null>(null)
  const { isPhone } = useMediaQueries()

  useEffect(() => {
    const handleScroll = throttle(() => {
      if (scrollableDivRef.current) {
        const { scrollTop } = scrollableDivRef.current

        setScrollState(prevState => {
          const isSticky =
            scrollTop <= 0 || (scrollTop < prevState.previousScrollPosition && scrollTop !== 0)
          const isInTop = scrollTop <= 0

          if (
            isSticky !== prevState.isSticky ||
            isInTop !== prevState.isInTop ||
            prevState.previousScrollPosition !== scrollTop
          ) {
            return {
              isSticky,
              isInTop,
              previousScrollPosition: scrollTop,
            }
          }
          return prevState
        })
      }
    }, 100)

    const scrollableDiv = scrollableDivRef.current
    scrollableDiv?.addEventListener('scroll', handleScroll)

    return () => {
      scrollableDiv?.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      <Heading hide={!scrollState.isInTop && isPhone}>{title}</Heading>
      <JobTitle />
      <CategoriesAndExamples>
        <Categories hide={isPhone && !scrollState.isSticky} />
        <ResumeExamples scrollableDivRef={scrollableDivRef} />
      </CategoriesAndExamples>
    </>
  )
}

export default ViewAllExamples
