import { Fragment } from 'react'
import { numberToDelimited } from 'builder/utils/numberToDelimited'
import { CareerPathItem } from 'builder/modules/careerPath'
import { SkeletonCompanies } from '../Skeletons/SkeletonCompanies'
import * as Styles from './styles'

type Props = {
  companies: Array<CareerPathItem>
  isSkeleton: boolean
}

export const Companies = ({ companies, isSkeleton = false }: Props) => {
  if (!companies.length && !isSkeleton) return null

  return isSkeleton ? (
    <SkeletonCompanies />
  ) : (
    <Fragment>
      {companies.map(
        company =>
          typeof company?.jobPostings === 'number' && (
            <Styles.CompanyContainer key={company.title}>
              <div>{company.title}</div>
              <Styles.CompanyJobs>
                {numberToDelimited(company.jobPostings)}{' '}
                {company.jobPostings === 1 ? 'job' : 'jobs'}
              </Styles.CompanyJobs>
            </Styles.CompanyContainer>
          ),
      )}
    </Fragment>
  )
}
