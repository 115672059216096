import styled from 'styled-components'

import Media from 'builder/styles/media'

export const PromotionListContainerV7 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${Media.Tablet`
    flex-direction: column;
  `}

  ${Media.Phone`
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
  `}
`
