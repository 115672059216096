import { useQuery } from '@tanstack/react-query'
import { baseClient } from 'builder/modules/apiClient'
import { CustomAlertType } from 'builder/modules/jobSearch/types'
import { transformerCustomAlert } from './utils'

export const useGetCustomAlert = (id?: string) => {
  return useQuery(
    ['useGetCustomAlert', id],
    async (): Promise<CustomAlertType> => {
      const res = await baseClient.get(`/job-alerts/v1/custom-subscriptions/${id}`)
      return transformerCustomAlert(res.data)
    },
    {
      enabled: !!id,
      useErrorBoundary: false,
    },
  )
}
