import { stringify } from 'query-string'
import { eventChannel } from 'redux-saga'
import { all, put, call, takeLatest, takeEvery, select, take, fork } from 'redux-saga/effects'
import { trackInternalEvent, trackMarketingEvent } from '@rio/tracking'
import { i18n as I18n } from 'builder/utils/i18n'
import { apiClient } from 'builder/modules/apiClient'
import jwtService from 'builder/services/JwtService'
import DownloadManager from 'builder/services/DownloadManager'
import ErrorLogger from 'builder/services/ErrorLogger'
import PerformanceLogger from 'builder/services/PerformanceLogger'
import RenderingConfigService from 'builder/services/RenderingConfigService'
import { FORMATS, RENDERERS, DocumentTypes } from 'builder/modules/constants'
import { createInterceptor, needOnline } from 'builder/modules/interceptors'
import { actions, selectors } from './renderingModule'
import {
  actions as appActions,
  ConfigScopesEnum,
  selectors as appSelectors,
} from 'builder/modules/init'
import { actions as userActions, selectors as userSelectors } from 'builder/modules/user'
import { selectors as resumeEditorSelectors } from 'builder/modules/resumeEditor'
import { selectors as letterEditorSelectors } from 'builder/modules/coverLetterEditor'
import { actions as uiActions, DOWNLOADED_COVER_LETTER_KEY } from 'builder/modules/ui'
import { analyzeResume } from 'builder/components/Tuner'
import { convertHtmlToText } from 'builder/utils/convertHtmlToText'
import { PanelSessionStorageKeys } from 'builder/modules/panel/types'
import { DownloadTRDocumentStorageKeys } from './types'
import { downloadFile } from 'builder/utils/downloadFile'
import { trDocFileContent } from './utils'
import { goToBillingPlanPage } from 'builder/utils/goToBillingPlanPage'

function* callAndMeasure(operationName, fn, ...args) {
  PerformanceLogger.operation(operationName)
  const result = yield call(fn, ...args)
  PerformanceLogger.operationEnd(operationName)

  return result
}

function loadWorker(renderer) {
  return import(/* webpackChunkName: "rendering-core" */ '@rio/web-worker')
}

/**
 * Generates a document preview using Client Side Rendering (Worker).
 * Resolves a promise with URL
 */
export function* fetchClientPreviewSaga(action) {
  try {
    yield put(actions.setPreviewGenerating(true))
    const preferRenderer = yield select(selectors.renderer)
    const { document } = action.payload

    // Speed up the first render by simultaneous fetching the configuration and rendering core
    const [config, { renderWorker }] = yield all([
      // fetch rendering config for `document.locale`
      callAndMeasure('loadConfig', RenderingConfigService.getConfig, document.locale),
      // async import the worker as a separate chunk
      callAndMeasure('loadRendererWorker', loadWorker, preferRenderer),
    ])

    // render a PDF using the worker
    const url = yield* callAndMeasure('generatePdfWithRendererCore', renderWorker, {
      document,
      config,
    })

    action.payload.resolve(url)
  } catch (error) {
    action.payload.reject(error)
  } finally {
    yield put(actions.setPreviewGenerating(false))
  }
}

/**
 * Generates a document preview using SSR.
 * Resolves a promise with base64-encoded image and pages count
 */
function* fetchServerPreviewSaga(action) {
  try {
    yield put(actions.setPreviewGenerating(true))
    const { document, page, size } = action.payload

    // fetch rendering config for `document.locale`
    const config = yield* callAndMeasure(
      'loadConfig',
      RenderingConfigService.getConfig,
      document.locale,
    )
    const data = { document, config, page, size }

    // generate a document preview using `/render` method
    const methodUrl = `${process.env.SSR_HOST}/render`
    const response = yield* callAndMeasure(
      'generatePdfPreviewOnServer',
      jwtService.client.post,
      methodUrl,
      data,
    )

    action.payload.resolve(response.data)
    yield put(actions.fetchPreviewDone())
  } catch (error) {
    action.payload.reject(error)
  } finally {
    yield put(actions.setPreviewGenerating(false))
  }
}

/**
 * Is requested document template premium or free
 */
function* hasPremiumTemplate({ id, type, format }) {
  const isResume = type === DocumentTypes.resume

  // free template available only for PDF format
  if (format !== FORMATS.pdf) return true

  // try to get the document and templates from store
  const editorSelectors = isResume ? resumeEditorSelectors : letterEditorSelectors
  const document = yield select(editorSelectors[isResume ? DocumentTypes.resume : 'coverLetter'])
  const templates = yield select(editorSelectors.templates)

  // same document is opened in editor and templates are loaded
  if (document && document.id === id && templates.length) {
    return templates.find(t => t.id === document.template).premium
  }

  // fallback: request the template from API
  const namespace = isResume ? 'resumes' : 'cover-letters'
  const response = yield call(apiClient.get, `/${namespace}/${id}/template`)
  return response.data.premium
}

/**
 * Is downloading resume supports selected docx template
 */
function* documentSupportsDocx({ id, type }) {
  const isResume = type === DocumentTypes.resume

  const editorSelectors = isResume ? resumeEditorSelectors : letterEditorSelectors

  const document = yield select(editorSelectors[isResume ? 'resume' : 'coverLetter'])
  const templates = yield select(editorSelectors.templates)

  if (document && document.id === id && templates.length) {
    return templates.find(t => document.template === t.id).supportedFormats.includes('docx')
  }

  const namespace = isResume ? 'resumes' : 'cover-letters'
  const response = yield call(apiClient.get, `/${namespace}/${id}/template`)

  return response.data.supportedFormats.includes('docx')
}

/**
 * Reports document downloading to ClickHouse
 */
function* trackDocumentDownloading({ id, type, format, template }) {
  const isResume = type === DocumentTypes.resume
  const eventCode = `download_${type}`
  const eventPayload = {
    format,
    [`${type}_id`]: id,
    n_symbols_work_exp_field: [],
    template: template,
  }

  // Extend event payload for resumes
  if (isResume) {
    // Fetch resume
    const { data: resume } = yield call(apiClient.get, `/resumes/${id}`)

    // Add tuner score
    eventPayload.tuner_score = analyzeResume(resume).score

    // Add an array with work experiences descriptions lengths
    if (resume.workExperiences.length) {
      const descriptionsLengths = resume.workExperiences.map(
        item => convertHtmlToText(item.description).length,
      )

      eventPayload.n_symbols_work_exp_field = descriptionsLengths
    }
  }

  trackInternalEvent(eventCode, eventPayload)
  trackMarketingEvent('Click Download', eventCode, { format })
  trackMarketingEvent('Purchase funnel', 'Document downloaded', {
    eventLabel: format,
    documentType: type,
  })
}

/**
 * Only premium members can download files.
 * Exceptions: requested file format is .txt or the document has a free template
 */
function* canUserDownload(document) {
  // user requested a free file format
  if (document.format === FORMATS.txt) return true

  // wait if user data is not loaded yet
  const userFetched = yield select(userSelectors.isFetched)
  if (!userFetched) yield take(userActions.setAccount)

  // user has premium access
  const userHasPremiumAccess = yield select(userSelectors.premiumAccess)
  if (userHasPremiumAccess) return true

  // document has a free template
  const docHasPremiumTemplate = yield call(hasPremiumTemplate, document)
  return !docHasPremiumTemplate
}

function* sendDownloadFeedbackSaga(id) {
  try {
    yield call(apiClient.post, `/resumes/${id}/send_feedback`)
  } catch (error) {
    ErrorLogger.log(error, { tag: 'download-error' })
  }
}

/**
 * Runs a document downloading process
 */
function* downloadSaga(action) {
  const {
    id,
    type = DocumentTypes.resume,
    format = FORMATS.pdf,
    showSnackBar = true,
    resolve = () => {},
    reject = () => {},
    trDocFiles = [],
    template = '',
    aiTaskId = '',
  } = action.payload

  const rdPromo = yield select(appSelectors.feature, 'rdPromo')
  const user = yield select(userSelectors.userData)

  const isFreeDOCXTemplate = template === 'athens_lite'
  // Overwrite the document template if user is downloading the free DOCX template
  const downloadSettings = {
    id,
    type,
    format,
    ...(isFreeDOCXTemplate ? { template: template } : {}),
  }
  const { trDocFileURL, trDocFileName } = trDocFileContent(trDocFiles, format)

  // Actions for Docx downloading, check if we should use another template
  // This is not needed for the free DOCX template
  if (!isFreeDOCXTemplate && format === FORMATS.docx) {
    const status = yield call(docxDownloadPrerequisites, { id, type })
    downloadSettings.template = status.template
    if (!status.continue) return
  }

  yield put(actions.setDocumentDownloading(true))

  // Allow direct download for the free DOCX template
  const userCanDownloadFile = isFreeDOCXTemplate
    ? true
    : yield call(canUserDownload, { id, type, format })

  // already has premium or requested a free file: initiate download right away
  if (userCanDownloadFile) {
    if (rdPromo?.rdPromoPlan && localStorage.getItem('rd_promo_shown') !== 'true') {
      yield put(actions.showRDUpsellPromoModal())
    } else if (user?.scopes?.autoApply && user?.scopes?.resumeDistribution) {
      yield put(actions.showRDUpsellGetStartedModal())
    }

    yield put(userActions.setIsResumeDownloaded(true))
    if (showSnackBar) {
      const normalizedFormat = format === 'tr_pdf' ? 'pdf' : format === 'tr_doc' ? 'docx' : format
      const text = I18n.t(`builder.dashboard.generating_${normalizedFormat}`)
      yield put(uiActions.setSnackBarOpen({ status: true, text }))
    }

    const renderer = yield select(selectors.renderer)
    const isResume = type === DocumentTypes.resume
    const editorSelectors = isResume ? resumeEditorSelectors : letterEditorSelectors

    const document = isResume ? yield select(editorSelectors.resume) : null
    const taskIdExists = !!((document && document.aiTaskId) || aiTaskId)

    try {
      if ((format === FORMATS.tr_pdf || format === FORMATS.tr_doc) && trDocFileURL) {
        downloadFile(trDocFileURL, trDocFileName)
        trackInternalEvent('download_tr_document')
      }
      DownloadManager.renderer = renderer
      yield call({ context: DownloadManager, fn: DownloadManager.download }, downloadSettings)
      resolve()

      // Track successful document downloads
      yield trackDocumentDownloading({ id, type, format, template })

      if (type === DocumentTypes.resume && taskIdExists) {
        yield sendDownloadFeedbackSaga(id)
      }
    } catch (error) {
      // fallback to SSR if the PDF-file fails to download
      // (an internal worker error occurs or the timeout exceeded)
      if (format === FORMATS.pdf) {
        yield put(actions.fallback({ reason: 'Download failed', message: error.toString() }))
        yield take(actions.fallbackComplete)
        const fallback = yield select(selectors.renderer)
        // restart the download only if another renderer is available
        if (fallback !== renderer) return yield put(actions.download(action.payload))
      }

      ErrorLogger.log(error, { tag: 'download-error' })
      reject(error)
    }

    // hide snackbar
    yield put(uiActions.setSnackBarOpen({ status: false }))
    // after download experience modal
    yield call(showAfterDownloadExperience, { id, type })
  } else {
    if (rdPromo?.rdPromoPlan && localStorage.getItem('rd_promo_shown') !== 'true') {
      yield put(actions.showRDUpsellPromoModal())
    } else {
      // place document id and format to the session storage since we loose it in query params
      if (format === FORMATS.tr_pdf || format === FORMATS.tr_doc) {
        sessionStorage.setItem(DownloadTRDocumentStorageKeys.DOWNLOAD_WHEN_PREMIUM, format)
        sessionStorage.setItem(DownloadTRDocumentStorageKeys.TR_DOC_ID, id)
      }

      // account is free
      const plansUrl = yield select(userSelectors.getUpgradeUrl)

      goToBillingPlanPage({
        upgradeUrl: plansUrl,
        document: id,
        documentType: type,
        documentFormat: format,
        documentTemplate: template,
      })
    }
  }

  yield put(actions.setDocumentDownloading(false))
}

function* downloadMergedDocumentsSaga(action) {
  // This saga expects to receive `resume` and `coverLetter` as objects with `renderingToken` key.
  // It's just easier for us since we call this saga from the merging modal only.
  // If we will add the merging functionality to some other places (e.g. the builder),
  // we should refactor the code with getting document IDs only.
  const {
    coverLetter,
    resume,
    showSnackBar = true,
    resolve = () => {},
    reject = () => {},
  } = action.payload

  yield put(actions.setDocumentDownloading(true))

  const userCanDownloadFile = yield call(canUserDownload, {
    id: resume.id,
    type: DocumentTypes.resume,
    format: FORMATS.pdf,
  })

  if (userCanDownloadFile) {
    if (showSnackBar) {
      const text = I18n.t(`builder.dashboard.generating_${FORMATS.pdf}`)
      yield put(uiActions.setSnackBarOpen({ status: true, text }))
    }

    try {
      const jwtToken = yield jwtService.getToken()

      const queryString = stringify({
        token: jwtToken,
        documentTokens: [coverLetter.renderingToken, resume.renderingToken],
      })
      const url = `${process.env.SSR_HOST}/merge-pdfs?${queryString}`
      window.location = url

      resolve()
    } catch (error) {
      reject(error)
    }

    yield put(uiActions.setSnackBarOpen({ status: false }))
  } else {
    // TODO: download merged file after redirect to dashboard
    const plansUrl = yield select(userSelectors.getUpgradeUrl)
    goToBillingPlanPage({
      upgradeURL: plansUrl,
      coverLetterId: coverLetter.id,
      resumeId: resume.id,
      documentType: 'merged',
    })
  }

  yield put(actions.setDocumentDownloading(false))
}

function* docxDownloadPrerequisites({ id, type }) {
  const selectedTemplate = sessionStorage.getItem(PanelSessionStorageKeys.COVER_LETTER_TEMPLATE)
  if (selectedTemplate) {
    sessionStorage.removeItem(PanelSessionStorageKeys.COVER_LETTER_TEMPLATE)
    return { continue: true, template: selectedTemplate }
  }

  const isDocumentSupportsDocx = yield call(documentSupportsDocx, { id, type })

  // Don't show modal window if document's template is available in docx format
  if (isDocumentSupportsDocx) {
    return { continue: true }
  }

  // Open docx template select modal and wait for user's action
  yield put(uiActions.setDocxModalDocumentId(id))
  yield put(uiActions.setDocxModalDocumentType(type))

  // Take one of two possible modal actions: close or template select
  const modalAction = yield take([uiActions.docxModalClosed, uiActions.docxModalClosedDownload])

  // Both actions should close the modal
  yield put(uiActions.setDocxModalDocumentId(null))
  yield put(uiActions.setDocxModalDocumentType(null))

  // Abort downloading process if user didn't select fallback template
  if (modalAction.type === uiActions.docxModalClosed().type) {
    return { continue: false }
  }

  // Continue with selected fallback template
  return { continue: true, template: modalAction.payload.template }
}

/**
 * Checks a document to detect unsupported things
 */
function* checkDocumentSaga({ payload: document }) {
  if (document.locale === 'zh-HK') {
    yield put(actions.fallback({ reason: 'Chinese language' }))
    yield take(actions.fallbackComplete)
  }

  yield put(actions.checkDocumentComplete())
}

/**
 * Update application UI on document download
 */
function* showAfterDownloadExperience({ id, type }) {
  // Show cover letter after-download experience modal (the one promoting resume builder)
  if (type === DocumentTypes.coverLetter) {
    // Makes sense only for users that don't have premium yet.
    const userHasPremiumAccess = yield select(userSelectors.premiumAccess)
    // Do not show the modal twice for the same cover letter.
    const downloadedLetterIds = JSON.parse(localStorage.getItem(DOWNLOADED_COVER_LETTER_KEY)) || []
    const hasUserSeenModalForThisCoverLetter = downloadedLetterIds.includes(id)

    if (!userHasPremiumAccess && !hasUserSeenModalForThisCoverLetter) {
      yield put(uiActions.openCoverLetterModal({ id }))
      // Prevent opening both modals at the same time
      return
    }
  }

  // Count downloads count using the local storage
  const downloadsRecorded = JSON.parse(localStorage.getItem('DOCUMENT_DOWNLOADS_COUNT')) || 0
  const downloadsCount = downloadsRecorded + 1
  localStorage.setItem('DOCUMENT_DOWNLOADS_COUNT', JSON.stringify(downloadsCount))

  // Rate modal appears after the third download of a document of any type
  if (downloadsCount === 3) yield put(uiActions.openRateModal())
}

function trackVisibility(state) {
  if (state === 'hidden') {
    PerformanceLogger.skipCurrent()
  }
}

function pickName(renderer) {
  return `${renderer}-rendering`
}

function* renderingLogger() {
  let renderNumber = 1
  while (true) {
    const startAction = yield take([actions.fetchClientPreview, actions.fetchServerPreview])
    const renderer = yield select(selectors.renderer)
    const name = pickName(renderer)

    const { document } = startAction.payload

    PerformanceLogger.listen({
      name,
      op: 'rendering',
      tags: {
        renderNumber: renderNumber++,
        documentType: document.type,
        template: document.template,
        locale: document.locale,
        pixelRatio: Number.parseInt(window.devicePixelRatio) || 1,
        memory: window.navigator.deviceMemory,
        viewportWidth: Math.floor(window.innerWidth / 100) * 100,
      },
    })

    // check that user still waiting result on first render
    trackVisibility(window.document.visibilityState)

    const finishAction = yield take([actions.fetchPreviewDone, actions.fallback])
    const status =
      finishAction.type === actions.fetchPreviewDone.toString() ? 'ok' : 'internal_error'

    PerformanceLogger.finish({
      status,
      tags: finishAction.payload,
    })
  }
}

function* trackVisibilityChange() {
  const visibilityChannel = eventChannel(emitter => {
    const handler = () => emitter(window.document.visibilityState)

    window.document.addEventListener('visibilitychange', handler)

    return () => window.document.removeEventListener('visibilitychange', handler)
  })

  while (true) {
    const state = yield take(visibilityChannel)
    yield call(trackVisibility, state)
  }
}

/**
 * Finite automata that switches the app to SSR if the worker took long or there was an error
 */
export function* rendererAutomate() {
  let nextState = yield call(idle)
  while (true) {
    nextState = yield call(nextState)
  }
}

const nameToFunc = {
  [RENDERERS.client]: client,
  [RENDERERS.server]: server,
}

export function* idle() {
  // wait for the full config
  let status
  do {
    status = yield take(appActions.updateConfig)
  } while (status.payload.scope !== ConfigScopesEnum.app)

  // pick and run renderer
  const renderer = yield select(selectors.renderer)
  return nameToFunc[renderer]
}

export function* client({ isFallback } = {}) {
  // run client rendering
  const task = yield takeLatest(actions.fetchClientPreview, fetchClientPreviewSaga)

  if (isFallback) {
    yield put(appActions.setFeatureValue({ name: 'renderer', value: RENDERERS.client }))
    yield put(actions.fallbackComplete())
  }

  // when fallback is here stop current task and switch to the next renderer
  yield take(actions.fallback)
  task.cancel()

  const fallbackRenderer = yield select(selectors.fallback)
  return nameToFunc[fallbackRenderer].bind(null, { isFallback: true })
}

export function* server({ isFallback } = {}) {
  // run server rendering
  const task = yield takeLatest(actions.fetchServerPreview, fetchServerPreviewSaga)

  if (isFallback) {
    yield put(appActions.setFeatureValue({ name: 'renderer', value: RENDERERS.server }))
    yield put(actions.fallbackComplete())
  }

  // when fallback is here stop current task and switch to the next renderer
  let fallback
  do {
    fallback = yield take(actions.fallback)
    // skip chinese language fallbacks because this renderer supports it
  } while (fallback.payload?.reason === 'Chinese language')

  task.cancel()

  // TODO: Provide a scenario in case if SSR is broken
  return server.bind(null, { isFallback: true })
}

// Export
export const sagas = function* saga() {
  yield all([
    fork(renderingLogger),
    fork(trackVisibilityChange),
    fork(rendererAutomate),
    takeEvery(actions.download, createInterceptor([needOnline]), downloadSaga),
    takeEvery(actions.downloadMergedDocuments, downloadMergedDocumentsSaga),
    takeLatest(actions.checkDocument, checkDocumentSaga),
  ])
}
