import { useQuery } from '@tanstack/react-query'
import { fetchRecommendations } from '../../utils'

export const useGetRecommendationJob = (
  enabled: boolean,
  latitude?: number,
  longitude?: number,
) => {
  return useQuery(
    ['useGetRecommendationJob', latitude, longitude],
    async () => {
      const { jobs } = await fetchRecommendations(0, latitude, longitude)
      return jobs
    },
    {
      enabled,
      useErrorBoundary: false,
    },
  )
}
