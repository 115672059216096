import { Link } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { useUser } from 'builder/hooks/useUser'
import { selectors as careerProfileSelectors } from 'builder/modules/careerProfile'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import Icon24 from 'builder/components/Icon'
import Button, { ButtonTheme } from 'builder/components/Button'
import { useCareerProfileEditorUrlParams } from 'builder/components/CareerProfile'
import { translationKey } from '../../consts'
import {
  Container,
  UserAvatar,
  UserInfoContainer,
  UserInfo,
  UserName,
  UserInfoItem,
  ButtonContent,
  UserTypeText,
} from './styles'

export const MainProfileInfo = () => {
  const { i18n } = useI18n()
  const user = useUser()
  const profilePictureUrl = useTypedSelector(careerProfileSelectors.profilePictureUrl)
  const careerProfileData = useTypedSelector(careerProfileSelectors.careerProfileData)
  const editorUrlParams = useCareerProfileEditorUrlParams()
  const editorRoute = `/career-profile/edit?${editorUrlParams}`

  return (
    user &&
    careerProfileData && (
      <Container>
        <UserAvatar src={profilePictureUrl} alt={user.fullName} />
        <UserInfoContainer>
          <UserInfo>
            <UserName>{user.fullName}</UserName>
            {careerProfileData.location && (
              <UserInfoItem>
                <Icon24.NewLocation />
                {careerProfileData.location}
              </UserInfoItem>
            )}
            {careerProfileData.userType && (
              <UserTypeText>
                {i18n.t(`builder.career_profile.chat.persona.${careerProfileData.userType}`)}
              </UserTypeText>
            )}
          </UserInfo>
          {careerProfileData.newProfile === false && (
            <Link to={editorRoute}>
              <Button
                theme={ButtonTheme.secondary}
                onClick={() => {
                  trackInternalEvent('click_edit_profile')
                }}
              >
                <ButtonContent>
                  <Icon24.Edit />
                  {i18n.t(`${translationKey}.edit_my_profile`)}
                </ButtonContent>
              </Button>
            </Link>
          )}
        </UserInfoContainer>
      </Container>
    )
  )
}
