import { useI18n } from 'builder/hooks/useI18n'
import * as Styles from './styles'
import cioURL from './assets/img/ciologo.png'
import rioURL from './assets/img/riologo.png'

const features = [
  { id: 1, text: 'Resume Tuner' },
  { id: 2, text: 'Job Search' },
  { id: 3, text: 'Job Tracker' },
  { id: 4, text: 'Interview Prep' },
  { id: 5, text: 'Salary Analyzer' },
  { id: 6, text: 'Career Planning' },
  { id: 7, text: 'Career Pathways' },
]

const ModalBody = () => {
  const { i18n } = useI18n()

  const renderFeatures = (featuresList: { id: number; text: string }[]) => {
    return featuresList?.slice(0, 4).map(feature => (
      <Styles.FeatureItem key={feature.id}>
        <Styles.GreenTick />
        <Styles.Feature>
          {i18n.t(`builder.panel.cio_rio_info_modal.${feature.text}`)}
        </Styles.Feature>
      </Styles.FeatureItem>
    ))
  }

  return (
    <>
      <Styles.Description>
        <Styles.Header>
          <Styles.Title>{i18n.t(`builder.panel.cio_rio_info_modal.title`)}</Styles.Title>
        </Styles.Header>
        <Styles.SubTitle>
          {i18n.t(`builder.panel.cio_rio_info_modal.text_1`)} <Styles.Logo src={rioURL} />{' '}
          <Styles.LogoText>Resume.io</Styles.LogoText>{' '}
          {i18n.t(`builder.panel.cio_rio_info_modal.text_2`)} <Styles.Logo src={cioURL} />
          <Styles.LogoText>Career.io</Styles.LogoText>{' '}
          {i18n.t(`builder.panel.cio_rio_info_modal.text_3`)}
        </Styles.SubTitle>
      </Styles.Description>
      <Styles.UnlockFeaturesSection>
        <Styles.UnlockText>
          {i18n.t(`builder.panel.cio_rio_info_modal.unlock_text`)}:
        </Styles.UnlockText>
        <Styles.FeatureColumn>
          <Styles.FeaturesContainer>
            {renderFeatures(features.slice(0, 4))}
          </Styles.FeaturesContainer>
          <Styles.FeaturesContainer>{renderFeatures(features.slice(4))}</Styles.FeaturesContainer>
        </Styles.FeatureColumn>
      </Styles.UnlockFeaturesSection>
    </>
  )
}

export default ModalBody
