import { CoachLists, CoachListsItem } from './style'

const coach1 = require('../../assets/Coach-1.png')
const coach2 = require('../../assets/Coach-2.png')
const coach3 = require('../../assets/Coach-3.png')
export const CoachList = () => {
  return (
    <CoachLists>
      <CoachListsItem>
        <img src={coach1} />
      </CoachListsItem>
      <CoachListsItem>
        <img src={coach2} />
      </CoachListsItem>
      <CoachListsItem>
        <img src={coach3} />
      </CoachListsItem>
    </CoachLists>
  )
}
