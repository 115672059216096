import styled, { keyframes } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Icon20 } from 'builder/components/Icon'
import Media from 'builder/styles/media'
import Employment from '../../../Images/Employment.svg'

const moveUp = keyframes`
  from {
    transform: translateY(30%);
  }
  to {
    transform: translateY(0%);
  }
`

export const radialGradient =
  'radial-gradient(100% 116.2% at 0% 0%, rgba(73, 81, 99, 0.2) 0%, rgba(0, 0, 0, 0) 100%)'

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 352px;
  height: 236px;
  border-radius: 16px;
  top: 60px;
  left: 96px;

  background: ${radialGradient},
    linear-gradient(180deg, rgba(30, 37, 50, 0.8) 0%, rgba(30, 37, 50, 0) 100%);

  ${Media.Phone`
    width:279px;
    left:32px;
    top: 0px;
    z-index:1;
  `}
`

export const DotsImage = styled.img`
  position: relative;
  left: 16px;
  top: 4px;
  margin-bottom: 10px;
  width: 35px;
  height: 23px;
`
export const InputWrapper = styled.div`
  display: flex;
`

type JobPostingTextAdditionalProps = {
  isSuccess?: boolean
}

export const JobPostingText = styled.input.attrs<JobPostingTextAdditionalProps>(props => ({
  isSuccess: props.isSuccess || false,
}))`
  ${Typography.Caption};
  ${FontWeights.Regular};
  position: relative;
  left: 16px;
  border: 2px solid
    ${(props: JobPostingTextAdditionalProps) => (props.isSuccess ? Colors.Green50 : Colors.Blue50)};
  border-radius: 8px;
  width: 320px;
  height: 36px;
  padding-left: 32px;
  background-image: url(${Employment});
  background-repeat: no-repeat;
  background-position: 8px center;
  animation: ${moveUp} 0.5s ease-out forwards;

  ::placeholder {
    color: ${Colors.White};
    width: 240px;
    text-overflow: ellipsis;
  }

  ${Media.Phone`
    width: 254px;
    left: 13px;

    ::placeholder {
      width: 174px;
    }

  `}
`

export const SuccessIcon = styled(Icon20.Tick)`
  fill: ${Colors.White};
  background: ${Colors.Green50};
  border-radius: 50%;
  height: 16px;
  width: 16px;
  position: relative;
  top: 10px;
  right: 10px;
`

export const JobsField = styled.img`
  position: relative;
  left: 16px;
  top: 4px;
  width: 320px;
  height: 152px;
  animation: ${moveUp} 0.5s ease-out forwards;

  ${Media.Phone`
    width: 254px;
    top: -8px;
    left: 13px;
  `}
`

export const JobPostingTitle = styled.div`
  ${Typography.Subhead};
  ${FontWeights.DemiBold}
  width: 480px;
  height: 28px;
  margin-bottom: 12px;
  color: ${Colors.Neutral90};

  ${Media.Phone`
    ${Typography.S};
    ${FontWeights.DemiBold}
    width: 303px;
    height: 48px;
  `}
`
