import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  border-radius: var(--XS, 12px);
  background: ${Colors.White};
  box-shadow: 0px 6px 8px -2px rgba(15, 20, 30, 0.08), 0px 16px 64px -8px rgba(15, 20, 30, 0.32);
  overflow: hidden;
  max-width: 352px;
`

export const Body = styled.div`
  margin: 24px;
`

export const Title = styled.div`
  ${Typography.Subhead};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral90};
  padding-bottom: 8px;
`

export const Text = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral90};
  padding-bottom: 24px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
`
