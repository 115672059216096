export enum EditFields {
  jobTitle = 'jobTitle',
  companyName = 'companyName',
}

export enum FormState {
  SAVED = 'saved',
  NOT_SAVED = 'not_saved',
  SAVE = 'save',
}
