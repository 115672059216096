import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { i18n as I18n } from 'builder/utils/i18n'
import Icon, { Icon20 } from 'builder/components/Icon'
import ActionMenu from 'builder/components/ActionMenu'

import {
  Tooltip,
  Header,
  HeaderInner,
  HeaderContent,
  HeaderTitle,
  HeaderSubtitle,
  ToggleIcon,
  ToggleIconInner,
  DeleteIcon,
  DragIcon,
  MobileIcon,
} from './styles'

class CardTitle extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    onMoveUp: PropTypes.func,
    onMoveDown: PropTypes.func,
    onToggle: PropTypes.func,
    onDelete: PropTypes.func,
    isOpen: PropTypes.bool,
    isDragging: PropTypes.bool,
    disableMove: PropTypes.bool,
  }

  static defaultProps = {
    subtitle: '',
    disableMove: false,
  }

  state = {
    isSheetOpen: false,
  }

  get actionSheetOptions() {
    const { onToggle, onDelete } = this.props

    const options = [
      {
        icon: <Icon.Edit />,
        text: I18n.t('builder.resume_editor.edit'),
        onClick: onToggle,
      },
      {
        icon: <Icon.Trash />,
        text: I18n.t('builder.resume_editor.delete'),
        onClick: onDelete,
      },
    ]

    return options
  }

  handleActionSheetOpen = e => {
    this.setState({ isSheetOpen: true })
    e.stopPropagation()
  }

  handleActionSheetClose = () => {
    this.setState({ isSheetOpen: false })
  }

  render() {
    const { title, subtitle, isOpen, isDragging, onToggle, onDelete, disableMove } = this.props

    return (
      <Header>
        <HeaderInner onClick={onToggle}>
          <HeaderContent>
            <HeaderTitle active={isOpen}>{title}</HeaderTitle>
            {subtitle && <HeaderSubtitle>{subtitle}</HeaderSubtitle>}
          </HeaderContent>

          <MobileIcon onClick={this.handleActionSheetOpen}>
            <Icon.More />
          </MobileIcon>

          <ToggleIcon>
            <Tooltip>
              {isOpen
                ? I18n.t('builder.resume_editor.collapse')
                : I18n.t('builder.resume_editor.expand')}
            </Tooltip>
            <ToggleIconInner isOpen={isOpen}>
              <Icon.ToggleArrow />
            </ToggleIconInner>
          </ToggleIcon>
        </HeaderInner>

        {!disableMove && <DragIcon isDragging={isDragging} />}

        <DeleteIcon onClick={onDelete}>
          <Tooltip>{I18n.t('builder.resume_editor.delete')}</Tooltip>
          <Icon20.Trash />
        </DeleteIcon>

        {this.state.isSheetOpen && (
          <ActionMenu onClose={this.handleActionSheetClose} actions={this.actionSheetOptions} />
        )}
      </Header>
    )
  }
}

export default CardTitle
