import styled from 'styled-components'
import { animated } from '@react-spring/web'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

interface PercentProps {
  points: number
}

export const SuggestionContainer = styled.div`
  display: block;
  user-select: none;
  position: sticky;
  top: 0px;
  background-color: ${Colors.White};
  z-index: 2;

  ${Media.Phone`
    &:before,
    &:after {
      width: 20px;
    }
  `};

  &:before {
    right: 100%;
  }

  &:after {
    left: 100%;
  }
`

export const Percent = styled(animated.div)<PercentProps>`
  display: inline-block;
  ${Typography.Tiny};
  ${FontWeights.DemiBold}
  border-radius: 4px;
  margin-right: 8px;
  color: ${Colors.White};
  font-variant-numeric: tabular-nums;
  width: fit-content;
  height: 20px;
  padding: 2px 4.5px;
  min-width: 40px;
  text-align: right;
  ${({ points }) => {
    switch (true) {
      case points > 70:
        return `
          background-color: ${Colors.Green40};
        `
      case points > 30:
        return `
          background-color: ${Colors.Amber40};
        `
      default:
        return `
          background-color: ${Colors.Red40};
        `
    }
  }}

  &:after {
    content: '%';
  }
`

export const PercentSuggestion = styled(animated.div)`
  display: flex;
  justify-content: center;
  align-content: center;
  ${Typography.Caption};
  font-size: 14px;
  font-weight: 400;
  color: ${Colors.Green60};
  background-color: ${Colors.Green10};
  font-variant-numeric: tabular-nums;
  border-radius: 4px;
  min-width: 32px;
  &:after {
    content: '%';
  }
`

export const SuggestionList = styled.div`
  display: flex;
  flex-flow: row wrap;

  ${Media.Phone`
    gap: 16px;
  `};
`

export const Suggestion = styled.div`
  flex: 0 0 50%;
  display: flex;
  gap: 8px;
  padding: 6px 0;
  cursor: pointer;
  width: 100%;

  ${Typography.Caption};

  ${Media.Phone`
    flex: 0 0 100%;
    padding: 0;
  `};

  &:hover {
    color: ${Colors.Blue50};
  }

  &:hover ${PercentSuggestion} {
    color: ${Colors.Blue50};
  }
`
