import { useI18n } from 'builder/hooks/useI18n'
import Button from 'builder/components/Button'
import { TRANSLATION_KEY } from '../ActiveInterviews'
import { Card, TextContainer, Container } from './styles'

interface IProps {
  onClick: () => void
  type: 'complete' | 'incomplete'
}

export const BlankCards = ({ onClick, type }: IProps) => {
  const { i18n } = useI18n()

  return (
    <Container>
      <Card />
      <Card />
      <Card />
      <TextContainer>
        {i18n.t(`${TRANSLATION_KEY}.no_${type}_interview_finished`)}
        <div>
          <Button onClick={onClick}>{i18n.t(`${TRANSLATION_KEY}.new_interview`)}</Button>
        </div>
      </TextContainer>
    </Container>
  )
}
