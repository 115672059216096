import { Icon20 } from 'builder/components/Icon'
import {
  Wrapper,
  Progress,
  ProgressBar,
  ProgressCompletedText,
  ProgressText,
  ProgressTextWrapper,
  SuccessIcon,
} from './styles'

type Props = {
  progress?: number
}

const AccomplishmentProgressBar = ({ progress }: Props) => {
  return (
    <Wrapper>
      <ProgressTextWrapper>
        {Number(progress) > 0 ? <SuccessIcon /> : <Icon20.StatusNew />}
        <ProgressText>
          {progress}% <ProgressCompletedText>Completed</ProgressCompletedText>
        </ProgressText>
      </ProgressTextWrapper>
      <Progress>
        <ProgressBar progress={Number(progress)} />
      </Progress>
    </Wrapper>
  )
}

export default AccomplishmentProgressBar
