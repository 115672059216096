import { SectionContainer, SectionContent } from 'builder/components/ProfileSummary/styles'
import RichTextArea from 'builder/components/RichTextArea/RichTextArea'
import { SectionHeader, SectionHint } from 'builder/components/Section'
import SectionTitle from 'builder/components/SectionTitle'
import { TunerSectionTooltips } from 'builder/components/Tuner'
import { useI18n } from 'builder/hooks/useI18n'
import { useUpdateSimpleField } from 'builder/hooks/useUpdateSimpleField'
import { SectionNames } from 'builder/modules/resumeEditor'
import FocusManager from 'builder/services/FocusManager'
import PropTypes from 'prop-types'
import { memo, useCallback, useEffect, useRef } from 'react'

const SimpleProfileSummary = ({ value, customTitle }) => {
  const { i18n } = useI18n()
  const richEditor = useRef()
  const { updateSimpleField } = useUpdateSimpleField()
  /** @type {import('@rio/types').Resume | null} */

  useEffect(() => {
    FocusManager.subscribe(SectionNames.profile, () => {
      if (richEditor.current) richEditor.current.focusEditor()
    })
    return () => FocusManager.unsubscribe(SectionNames.profile)
  }, [])

  const handleDescriptionChange = useCallback(
    value => updateSimpleField({ name: 'profile', value, debounce: true }),
    [updateSimpleField],
  )

  return (
    <SectionContainer>
      <SectionHeader>
        <SectionTitle
          title={i18n.t('builder.resume_editor.professional_summary.title')}
          customTitle={customTitle}
        />
        <SectionHint>{i18n.t('builder.resume_editor.professional_summary.label')}</SectionHint>
      </SectionHeader>
      <SectionContent>
        {/* TODO: Add label to i18n if AB test succeeds */}
        <RichTextArea
          rows={10}
          placeholder={i18n.t('builder.resume_editor.professional_summary.placeholder')}
          value={value || ''}
          onChange={handleDescriptionChange}
          boldButtonDisable
          italicButtonDisable
          linkButtonDisable
        />
      </SectionContent>

      <TunerSectionTooltips sectionId={SectionNames.profile} />
    </SectionContainer>
  )
}

SimpleProfileSummary.propTypes = {
  value: PropTypes.string,
  customTitle: PropTypes.string,
  onChange: PropTypes.func,
  onRename: PropTypes.func,
  locale: PropTypes.string,
}

export default memo(SimpleProfileSummary)
