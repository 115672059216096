import { useQuery } from '@tanstack/react-query'
import { baseClient } from 'builder/modules/apiClient'
import { CareerPathwaysJobTitleResponse } from 'builder/modules/interview/types'

export const useJobTitle = (jobTitle?: number) => {
  return useQuery(
    ['jobTitle', jobTitle],
    async (): Promise<CareerPathwaysJobTitleResponse> => {
      const res = await baseClient.get(`career-data/v1/job-titles/${jobTitle}`)
      return res.data
    },
    {
      enabled: !!jobTitle,
    },
  )
}
