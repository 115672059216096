import { useState, useEffect, useRef } from 'react'
import { getLogoStyle } from './utils'
import { Steps } from './types'
import { Container, Video, Logo } from './styles'

import WEBM_VIDEO_URL from './assets/logo-animation.webm'
import MP4_VIDEO_URL from './assets/logo-animation.mp4'

interface Props {
  onEnd: () => void
}

export const SplashScreenAnimation = ({ onEnd }: Props) => {
  const [step, setStep] = useState(Steps.start)

  const videoRef = useRef<HTMLVideoElement | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (step === Steps.finish) onEnd()
  }, [step, onEnd])

  const nextStep = () => {
    setStep(step => Math.min(step + 1, Steps.finish))
  }

  const handleSignTransitionEnd = () => {
    if (step === Steps.videoToSign || step === Steps.signMove) nextStep()
  }

  const handleContainerTransitionEnd = () => {
    if (step === Steps.signToLogo) nextStep()
  }

  return (
    <Container
      ref={containerRef}
      isVisible={step < Steps.signToLogo}
      onTransitionEnd={handleContainerTransitionEnd}
    >
      <Logo
        style={getLogoStyle(step, videoRef.current)}
        isVisible={step > Steps.videoLoaded}
        onTransitionEnd={handleSignTransitionEnd}
      />

      <Video
        isVisible={step <= Steps.videoLoaded}
        ref={videoRef}
        width={640}
        height={320}
        onLoadedData={() => setStep(Steps.videoLoaded)}
        onEnded={() => setStep(Steps.videoToSign)}
        playsInline
        autoPlay
        muted
      >
        <source src={WEBM_VIDEO_URL} type="video/webm" />
        <source src={MP4_VIDEO_URL} type="video/mp4" />
      </Video>
    </Container>
  )
}
