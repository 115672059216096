import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'

export const InterviewCardContainer = styled.div`
  width: 268px;
  height: 336px;
  flex-shrink: 0;
  border-radius: var(--S, 16px);

  border: 1px solid ${Colors.Neutral15};
  background: ${Colors.White};
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${Media.Hover`
    &:hover  {
      cursor: pointer;
      box-shadow: 0px 8px 20px -4px rgba(15, 56, 113, 0.12),
                  0px 2px 4px -1px rgba(15, 56, 113, 0.08), 
                  0px 0px 1px 0px rgba(15, 56, 113, 0.32);
    }
  `};
`

export const SmallNewInterviewCardContainer = styled.div`
  max-width: 268px;
  min-width: 268px;
  height: 364px;
  border-radius: var(--S, 16px);

  border: 1px solid ${Colors.Neutral15};

  background: ${Colors.White};
  display: flex;
  flex-direction: column;
  padding: 12px;
  align-items: center;

  ${Media.Hover`
    &:hover  {
      cursor: pointer;
      box-shadow: 0px 8px 20px -4px rgba(15, 56, 113, 0.12),
                  0px 2px 4px -1px rgba(15, 56, 113, 0.08), 
                  0px 0px 1px 0px rgba(15, 56, 113, 0.32);
    }
  `};
`

export const TimeIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${Colors.Neutral90};
  & path {
    fill: ${Colors.White};
  }
`

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
`

export const CreateNewInterview = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
`
export const JobTitle = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
  padding-bottom: var(--size-3xs);

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-top: var(--size-xs);
`
export const Subtitle = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
`

export const Header = styled.div`
  ${Typography.Caption};
  ${FontWeights.Medium};
  color: ${Colors.Neutral50};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
`

export const BadgesContainer = styled.div`
  padding-top: 14px;
  padding-bottom: 8px;
`
