import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/sevenStories'
import { SummaryContainer, Title, Description, RectangleContainer } from './styles'

const Summary = () => {
  const assessmentResult = useTypedSelector(selectors.assessmentResult)

  return (
    <SummaryContainer>
      <Title>Assess and Reflect Your Result</Title>
      <Description dangerouslySetInnerHTML={{ __html: assessmentResult.summary }} />
      <RectangleContainer />
    </SummaryContainer>
  )
}

export default Summary
