import { trackInternalEvent } from '@rio/tracking'
import { selectors, Label } from 'builder/modules/careerPath2'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { numberToDelimited } from 'builder/utils/numberToDelimited'
import Loader from '../../../JobPostings/components/Loader'

import { Container, Heading, Ratio, Number } from './styles'

type Props = {
  isLoading: boolean
}

const Header = ({ isLoading }: Props) => {
  const location = useTypedSelector(selectors.location)
  const { companiesCount } = useTypedSelector(selectors.jobDemand)
  const selectedJobTitle = useTypedSelector(selectors.selectedJobTitle)
  const isExploreCareers = useTypedSelector(selectors.isExploreCareers)
  const exploreCareersLocation = useTypedSelector(selectors.exploreCareersLocation)
  const exploreCareersOccupation = useTypedSelector(selectors.exploreCareersOccupation)

  const jobLocation = isExploreCareers ? exploreCareersLocation : location
  const jobTitle = isExploreCareers ? exploreCareersOccupation : selectedJobTitle

  const handleClick = () => {
    const title = jobTitle?.split(' ').join('+')
    const location = jobLocation?.split(' ').join('+')

    trackInternalEvent('click_companies_hiring_link', {
      job_title: jobTitle,
      label: isExploreCareers ? 'explore_careers' : Label.label,
    })

    if (location) {
      window.open(`/app/job-search?query=${title}&location=${location}&view=search`, '_blank')
      return
    }

    window.open(`/app/job-search?query=${title}&location=${null}&view=search`, '_blank')
  }

  return (
    <Container onClick={handleClick}>
      <Heading>Companies Hiring</Heading>
      {isLoading ? (
        <Loader />
      ) : (
        <Ratio>
          <Number>{numberToDelimited(companiesCount)}</Number>
        </Ratio>
      )}
    </Container>
  )
}

export default Header
