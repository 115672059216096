import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Icon24 from 'builder/components/Icon'

export const Wrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 650;
  background-color: ${Colors.White};
`

export const Top = styled.div`
  width: 236px;
  background-color: ${Colors.White};
  height: 50px;

  display: flex;
  align-items: center;
  padding-left: 10px;
`

export const Close = styled(Icon24.Close)`
  cursor: pointer;
`
