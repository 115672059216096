import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { Banner, CoachImg, Container } from './styles'
import BannerContent from './components/BannerContent'
import careerCoachingURL from './assets/img/career_coaching.svg'
import careerCoachingMobileURL from './assets/img/career_coaching_mobile.svg'

const CareerCoachingBanner = (): JSX.Element => {
  const { isPhone, isTablet } = useMediaQueries()

  return (
    <Container>
      <Banner>
        {isPhone && <CoachImg src={careerCoachingURL} data-testid="coach-img-phone" />}
        <BannerContent />
        {!isPhone && (
          <CoachImg
            src={isTablet ? careerCoachingMobileURL : careerCoachingURL}
            data-testid="coach-img-desktop-tablet"
          />
        )}
      </Banner>
    </Container>
  )
}

export default CareerCoachingBanner
