import { Component } from 'react'
import PropTypes from 'prop-types'
import { Transition } from 'react-transition-group'
import { i18n as I18n } from 'builder/utils/i18n'

import { Button, Updating, UpdatedIcon, UpdatingIcon } from './styles'

class UpdateAccountButton extends Component {
  static propTypes = {
    isUpdating: PropTypes.bool,
    disable: PropTypes.bool,
    onClick: PropTypes.func,
  }

  static defaultProps = {
    isUpdating: false,
    disable: false,
    onClick: () => {},
  }

  render() {
    const { isUpdating, disable, onClick } = this.props
    return (
      <Transition in={isUpdating} timeout={{ enter: 0, exit: 1000 }}>
        {state => {
          switch (state) {
            case 'exited':
            case 'entering':
              return (
                <Button onClick={onClick} disable={disable}>
                  {I18n.t('builder.account.save_account')}
                </Button>
              )
            case 'entered':
              return (
                <Updating>
                  <UpdatingIcon />
                  {I18n.t('builder.account.saving')}
                </Updating>
              )
            case 'exiting':
              return (
                <Updating>
                  <UpdatedIcon />
                  {I18n.t('builder.account.saving')}
                </Updating>
              )
          }
        }}
      </Transition>
    )
  }
}

export default UpdateAccountButton
