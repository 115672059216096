import { useEffect, useRef } from 'react'
import * as React from 'react'
import { Background } from '../types'
import { areEqualBackgrounds, drawBackground } from '../utils'
import { DEFAULT_BACKGROUND } from '../constants'
import { BackgroundPreviewCanvas } from './styles'

interface PreviewProps extends React.HTMLAttributes<HTMLCanvasElement> {
  size: number
  background: Background
}

const pixelRatio = window.devicePixelRatio || 1

export const BackgroundPreview = ({ size, background, ...rest }: PreviewProps) => {
  const ref = useRef<HTMLCanvasElement>(null)
  const lastBackground = useRef<Background>(DEFAULT_BACKGROUND)

  useEffect(() => {
    if (ref.current) {
      // prevent extra canvas updates
      const hasBgChanged = !areEqualBackgrounds(lastBackground.current, background)
      const hasSizeChanged = ref.current.width !== size * pixelRatio

      if (hasBgChanged || hasSizeChanged) {
        ref.current.width = size * pixelRatio
        ref.current.height = size * pixelRatio
        drawBackground(ref.current, background)
        lastBackground.current = background
      }
    }
  }, [size, background])

  return <BackgroundPreviewCanvas ref={ref} size={size} {...rest} />
}
