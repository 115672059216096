import { memo } from 'react'
import { avatarUrl } from 'builder/modules/careerCoaching/constants'
import { Container, Avatar, Info, Name } from './styles'
import CoachExpertise from './components/CoachExpertise'
import CoachLocation from './components/CoachLocation'

interface Props {
  name: string
  location: string
  avatar: string
  careerStages: string[]
}

const AboutCoach = ({ avatar, name, careerStages, location }: Props) => {
  return (
    <Container>
      <Avatar src={avatar || avatarUrl} />
      <Info>
        <Name>{name}</Name>
        <CoachExpertise {...{ careerStages }} />
        <CoachLocation {...{ location }} />
      </Info>
    </Container>
  )
}

export default memo(AboutCoach)
