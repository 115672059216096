import { API_BASE_URL } from 'builder/modules/constants'

export default class TxtDownload {
  constructor(document) {
    this.document = document
  }

  async start() {
    // TXT files are rendered by Rails, so we just open an API endpoint returning a text file.
    // It might require a refactoring later.
    const url = `${API_BASE_URL}/resumes/${this.document.id}/txt-download`

    window.location = url

    return url
  }
}
