import { useDispatch } from 'react-redux'
import { useTransition } from '@react-spring/web'
import Button from 'builder/components/Button'
import { Icon20 } from 'builder/components/Icon'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import {
  actions,
  selectors,
  JobStatusModalStep,
  jobStatusOptions,
} from 'builder/modules/enterpriseModal'
import { transitionValues } from './constants'
import * as Styled from './styles'

const JobStatusConfirmationModal = () => {
  const dispatch = useDispatch()
  const step = useTypedSelector(selectors.step)
  const jobStatus = useTypedSelector(selectors.jobStatus)
  const isModalOpen = useTypedSelector(selectors.isJobStatusConfirmationModalOpen)
  const transition = useTransition(isModalOpen, transitionValues)

  const getNextContent = () => {
    switch (step) {
      case JobStatusModalStep.WORKINGONCV:
        return 'finished with your Resume/CV'
      case JobStatusModalStep.JOBSEARCHING:
        return 'started applying for jobs'
      case JobStatusModalStep.APPLYINGJOBS:
        return 'started interviewing'
      case JobStatusModalStep.INTERVIEWING:
        return 'landed a job'
    }
  }

  const handleClose = () => {
    dispatch(actions.closeJobStatusConfirmationModal())
  }

  const handleSelectNext = () => {
    const currentJobStatusID = jobStatus.find(status => status.checked)?.id

    if (currentJobStatusID) {
      dispatch(actions.clearJobStatus())

      const nextJobStatus = jobStatusOptions.map(status =>
        status.id === currentJobStatusID + 1 ? { ...status, checked: true } : status,
      )

      dispatch(actions.updateJobStatus(nextJobStatus))
    }
  }

  const handleAnswered = () => {
    handleSelectNext()
    dispatch(actions.closeJobStatusConfirmationModal())
    if (step === JobStatusModalStep.INTERVIEWING) {
      dispatch(actions.openJobStatusHiredModal())
      const newJobStatus = [...jobStatusOptions, { ...jobStatusOptions[4], checked: true }]
      dispatch(actions.sendUpdatedJobStatus(newJobStatus))
    } else {
      dispatch(actions.openJobStatusModal())
    }
  }

  return transition((style, item) =>
    item ? (
      <Styled.ModalContainer style={style}>
        <Styled.ModalContent>
          <Styled.Close onClick={handleClose}>
            <Icon20.Close />
          </Styled.Close>
          <Styled.Heading>Have you {getNextContent()}?</Styled.Heading>
          <Styled.SubHeading>
            Please help your career rep understand where you are in your job search so they can best
            assist you
          </Styled.SubHeading>
          <Styled.ButtonContainer>
            <Button theme="ghost" size="small" onClick={handleClose}>
              Not Yet
            </Button>
            <Button size="small" onClick={handleAnswered}>
              Yes
            </Button>
          </Styled.ButtonContainer>
        </Styled.ModalContent>
      </Styled.ModalContainer>
    ) : null,
  )
}

export default JobStatusConfirmationModal
