import { Job } from 'builder/modules/jobTracking'
import Colors from 'builder/styles/colors'
import { CardLogo } from '../../styles'
import { Layers } from '../Layers'
import { CardTag } from './CardTag'
import { CardTitle } from './CardTitle'
import { CardContainer, CardContainerGreen, CardDivider, SizedBox } from './styles'
import { CardInfo } from './CardInfo'

export type CardType = 'empty' | 'loading' | 'error' | 'default'

type InterviewCardProps = {
  type: CardType
  job: Partial<Job>
}

export const InterviewCard: React.FC<InterviewCardProps> = ({ type = 'default', job }) => {
  const Container = type === 'default' ? CardContainerGreen : CardContainer

  return (
    <Container>
      <SizedBox size={16} />

      <Layers type={type}>
        <CardLogo size={44} src={job?.logo} alt={job?.company} style={{ margin: 0 }} />
      </Layers>

      <SizedBox size={32} />

      <CardTag type={type} />

      <SizedBox size={16} />

      <CardTitle type={type} job={job} />

      <CardDivider color={type === 'default' ? Colors.Green20 : Colors.Neutral15} />

      <CardInfo type={type} />
    </Container>
  )
}

export default InterviewCard
