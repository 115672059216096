import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
  padding: 16px 0;

  ${Media.Phone`
    width: 100%;
  `};
`

export const Header = styled.div`
  ${Typography.Subhead};
  ${FontWeights.DemiBold};
  width: 100%;
  text-align: center;
`

export const SubHeader = styled.div`
  width: 438px;
  text-align: center;
  color: ${Colors.Neutral50};
  margin: auto;

  ${Media.Phone`
    width: 100%;
  `};
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`
