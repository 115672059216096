import styled from 'styled-components'
import Icon from 'builder/components/Icon'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Button from 'builder/components/Button'

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 540px;
  background-color: ${Colors.White};
  border-radius: 6px;
  z-index: 999;
  padding: 32px 40px;

  ${Media.Phone`
    padding: 20px;
  `};
`

export const Close = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
  color: ${Colors.Neutral30};
  cursor: pointer;
  transition: color 0.1s;

  &:hover {
    color: ${Colors.Blue50};
  }
`

export const CloseIcon = styled(Icon.CloseLarge)`
  display: block;
`

export const Title = styled.div`
  ${Typography.S};
  font-weight: 600;
  margin-bottom: 4px;
`

export const Text = styled.div`
  margin-bottom: 60px;

  ${Media.Phone`
    margin-bottom: 32px;
  `};
`

export const BottomWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${Media.Phone`
    flex-flow: column;
  `};
`

export const RightBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${Media.Phone`
    width: 100%;
    justify-content: center;
  `};
`

export const LeftBlock = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;

  ${Media.Phone`
    margin-bottom: 24px;
  `};
`

export const PrimaryButton = styled(Button)`
  margin-right: 12px;
`
