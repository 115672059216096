import { all, takeEvery, take, select, put, call } from 'redux-saga/effects'
import { trackInternalEvent, trackMarketingEvent } from '@rio/tracking'
import queryString from 'query-string'
import { apiClient } from 'builder/modules/apiClient'
import { selectors as appSelectors } from 'builder/modules/init/initModule'
import { actions, selectors } from './coRegistrationModule'
import {
  actions as resumeEditorActions,
  RESUME_REMOTE_UPDATE_SUCCESS,
  selectors as resumeEditorSelectors,
} from 'builder/modules/resumeEditor'
import { matchMediaQueries } from 'builder/utils/matchMediaQueries'
import { navigate } from 'builder/modules/navigate'

// How many point a resume should have to display the CTA popup
const MIN_RESUME_SCORE = 85

// Fetch co-regs payload to initialize module
function* initializeSaga() {
  // do not load settings twice
  if (yield select(selectors.isInitialized)) return
  // load from the API
  const { data } = yield call(apiClient.get, `/co-regs`)
  // save user settings in the state
  yield put(actions.initializeSuccess(data))
  // subscribe on resume changes only if there is unused services
  const unusedServices = yield select(selectors.unusedServices)
  if (unusedServices.length) yield call(watchResumeUpdates)
}

// Create a subscriber/channel to listen resume updates
function* watchResumeUpdates() {
  const isEnabledForCountry = yield select(appSelectors.feature, 'coReg')
  // enable co-registration by query param ?co_reg=true
  const { co_reg: isManuallyEnabled } = queryString.parse(window.location.search, {
    parseBooleans: true,
  })
  const isAvailable = isEnabledForCountry || isManuallyEnabled

  while (true) {
    yield take([RESUME_REMOTE_UPDATE_SUCCESS, resumeEditorActions.fetchResumeSuccess])
    // get resume score
    const resumeScore = yield select(resumeEditorSelectors.resumeScore)
    // resume ready for publishing
    if (resumeScore >= MIN_RESUME_SCORE) {
      if (!isAvailable) return
      // show counter if resume score is big enough
      yield put(actions.toggleResumeStatus(true))
      // show popup if user hasn't close popup before
      const wasPopupClosed = localStorage.getItem('CO_REG_POPUP_CLOSED')
      if (!wasPopupClosed) yield put(actions.togglePopup(true))
      // stop watcher
      return
    }
  }
}

// Mark the popup as viewed
function* togglePopupSaga({ payload: isOpen }) {
  if (isOpen) trackInternalEvent('see_co_registration_popup')
  if (!isOpen) localStorage.setItem('CO_REG_POPUP_CLOSED', true)
}

// Post the resume to job boards
function* connectSaga({ payload }) {
  yield put(actions.connectStart())
  try {
    // send form data to the API method
    const { data } = yield call(apiClient.post, `/co-regs`, payload)
    // update the list of connected services
    yield put(actions.connectSuccess(data))
    // track event only if data was successfully sent
    const { isSent } = yield select(selectors.sendingState)

    if (isSent) {
      trackMarketingEvent('Co-Reg', 'Post Resume')
    }
    trackInternalEvent('complete_co_registration', { status: isSent ? 'success' : 'fail' })
  } catch (error) {
    trackInternalEvent('complete_co_registration', { status: 'fail' })
    yield put(actions.connectFail(error.message || 'Internal server error'))
  }
}

// Redirect to form or display modal
function* openSaga(action) {
  const { resumeId, source } = action.payload
  const { isPhone } = matchMediaQueries()
  // close popup if opened
  if (yield select(selectors.isPopupOpen)) yield put(actions.togglePopup(false))
  // open page if there are services to connect or resolution is too small to display modal
  const unusedServices = yield select(selectors.unusedServices)
  if (unusedServices.length || isPhone) {
    trackInternalEvent('visit_co_registration_page', { source })
    return navigate(`/resumes/${resumeId}/co-reg`)
  }
  // display modal if user is already registered on all boards
  trackInternalEvent('see_co_registration_modal')
  yield put(actions.toggleModal(true))
}

// Export
export const sagas = function* saga() {
  yield all([
    takeEvery(actions.initialize, initializeSaga),
    takeEvery(actions.togglePopup, togglePopupSaga),
    takeEvery(actions.open, openSaga),
    takeEvery(actions.connect, connectSaga),
  ])
}
