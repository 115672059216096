import { memo } from 'react'
import PropTypes from 'prop-types'
import Icon from 'builder/components/Icon'
import { Button, ButtonText, IconWrapper } from './styles'

const NewAddCardButton = ({ onAdd, text, ...otherProps }) => (
  <Button tabIndex="0" onClick={onAdd} {...otherProps}>
    <IconWrapper>
      <Icon.AddSmall />
    </IconWrapper>
    <ButtonText>{text}</ButtonText>
  </Button>
)

NewAddCardButton.propTypes = {
  text: PropTypes.string,
  onAdd: PropTypes.func,
}

export default memo(NewAddCardButton)
