import { useQuery } from '@tanstack/react-query'
import { baseClient } from 'builder/modules/apiClient'
import { Job } from '../../JobSearchResult/types'

export const useGetJob = (externalSlugId?: string) => {
  return useQuery(
    ['useGetJob', externalSlugId],
    async () => {
      const response = await baseClient.get<Job>(`/jobs/v1/search/${externalSlugId}`)
      return response.data
    },
    {
      enabled: !!externalSlugId,
    },
  )
}
