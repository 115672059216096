import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Container = styled.div`
  display: block;
  user-select: none;
  position: sticky;
  top: 0px;
  background-color: ${Colors.White};
  z-index: 2;

  ${Media.Phone`
    &:before,
    &:after {
      width: 20px;
    }
  `};

  &:before {
    right: 100%;
  }

  &:after {
    left: 100%;
  }
`

export const Section = styled.div<{ isPageScrolled: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${props =>
    !props.isPageScrolled &&
    css`
      margin-top: 20px;
    `}
`

export const List = styled.div`
  display: flex;
  flex-flow: row wrap;

  ${Media.Phone`
    gap: 16px;
  `};
`

export const Item = styled.div`
  flex: 0 0 50%;
  display: flex;
  gap: 8px;
  padding: 8px 0;
  cursor: pointer;
  width: 100%;

  ${Typography.Caption};

  ${Media.Phone`
    flex: 0 0 100%;
    padding: 0;
  `};

  &:hover {
    color: ${Colors.Indigo60};
  }
`

export const Icon = styled.div`
  padding: 0 2px;
  background-color: ${Colors.Indigo10};
  color: ${Colors.Indigo50};
  fill: ${Colors.Indigo10};
  border-radius: 4px;

  &:hover {
    color: ${Colors.Indigo60};
  }

  &:hover ${Item} {
    color: ${Colors.Indigo60};
  }
`

export const KeywordsCounter = styled.div`
  color: ${Colors.Neutral90};
  font-size: 14px;
  font-weight: 600;
`
