import EditorField from 'builder/components/EditorField'
import { TextField } from 'builder/components/TextField'
import { useWorkExperienceCardContext } from '../../ExpandableCardContext'
import { useShokumuTranslations } from '../../useShokumuTranslations'

const BusinessType = (): JSX.Element => {
  const {
    item: { employerProfilesAttributes },
    onCardValueChange,
  } = useWorkExperienceCardContext()

  const { i18n } = useShokumuTranslations()
  const label = i18n('work_experience.employer_details.business_type')

  const updateBusinessType = (e: React.ChangeEvent<HTMLInputElement>) => {
    onCardValueChange(
      {
        employerProfilesAttributes: {
          ...employerProfilesAttributes,
          businessType: e.target.value,
        },
      },
      true,
    )
  }

  return (
    <EditorField>
      <TextField
        label={label}
        name="businessType"
        onChange={updateBusinessType}
        value={employerProfilesAttributes?.businessType || ''}
      />
    </EditorField>
  )
}
export default BusinessType
