import Spinner from 'builder/components/Spinner'
import { SpinnerContainer } from './styles'

export const Loading = () => {
  return (
    <SpinnerContainer>
      <Spinner />
    </SpinnerContainer>
  )
}
