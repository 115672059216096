import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { trackInternalEvent } from '@rio/tracking'
import { ChangeEvent, useEffect } from 'react'

import Button from 'builder/components/Button'
import { TextField } from 'builder/components/TextField'
import DragAndDrop from 'builder/components/DragAndDrop'
import { actions, selectors } from 'builder/modules/uploadResume'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as userSelectors, actions as userActions } from 'builder/modules/user'
import { UploadResumeStep } from 'builder/modules/uploadResume/constants'
import { useDelayUnmount } from 'builder/components/Panel/CIOCritiqueReview/UploadResume/components/CustomHooks/useDelayUnmount'

import * as Styled from './styles'
import UploadDefault from './components/UploadDefault'
import UploadComplete from './components/UploadComplete'

type Payload = { email: string }

const FILESIZELIMITINMB = 5
const VALIDFILETYPES = ['png', 'jpg', 'doc', 'docx', 'pdf', 'txt', 'odf', 'gif', 'rtf']

const getFileSizeInMB = (fileSizeInBytes: number) => {
  return parseInt((fileSizeInBytes / (1024 * 1024)).toFixed(2))
}

const UploadResume = () => {
  const dispatch = useDispatch()
  const email = useTypedSelector(selectors.email)
  const error = useTypedSelector(selectors.errors)
  const isDragging = useTypedSelector(selectors.isDragging)
  const selectedFile = useTypedSelector(selectors.selectedFile)
  const isFileSelected = useTypedSelector(selectors.isFileSelected)
  const uploadProgress = useTypedSelector(selectors.uploadProgress)
  const parsingProgress = useTypedSelector(selectors.parsingProgress)
  const showDragBox = useDelayUnmount(isDragging, 380)
  const step = useTypedSelector(selectors.step)

  const userDetails = useTypedSelector(userSelectors.userData)
  const isUserFetched = useTypedSelector(userSelectors.isFetched)
  const apiAccessToken = localStorage.getItem('API_ACCESS_TOKEN')

  useEffect(() => {
    if (!isUserFetched && apiAccessToken) {
      dispatch(userActions.fetchUserRequest())
    }
  }, [apiAccessToken, dispatch, isUserFetched])

  const isDisabled = !isFileSelected || error.hasError

  const form = useForm({
    mode: 'onChange',
    defaultValues: { email: '' },
  })

  const isValidEmailFormat = (email: string) => {
    return /\S+@\S+\.\S+/.test(email)
  }

  const handleDragOver = () => dispatch(actions.setIsDragging(true))
  const handleDragLeave = () => dispatch(actions.setIsDragging(false))

  const goBack = () => {
    dispatch(actions.setIsFileSelected(false))
    dispatch(actions.setStep(UploadResumeStep.UPLOAD_RESUME))
    if (uploadProgress === 100) {
      dispatch(actions.setUploadProgress(-100))
    } else {
      dispatch(actions.setUploadProgress(0))
    }
  }

  const fileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.setIsFileSelected(false))

    dispatch(actions.setStep(UploadResumeStep.RESUME_SELECTED))

    if (uploadProgress === 100) {
      dispatch(actions.setUploadProgress(-100))
    } else {
      dispatch(actions.setUploadProgress(0))
    }
    dispatch(actions.setIsFileSelected(true))

    dispatch(actions.setError({ hasError: false, errorMessage: '' }))

    trackInternalEvent('click_upload_cv_button')
    trackInternalEvent('ulp_cta_click', { label: 'upload_resume' })

    if (e.target.files) {
      const { name: fileName } = e.target.files[0]
      const fileExtension = fileName.split('.').pop()
      const fileSize = getFileSizeInMB(e.target.files[0].size)

      if (fileExtension && !VALIDFILETYPES.includes(fileExtension)) {
        dispatch(actions.setError({ hasError: true, errorMessage: 'Incorrect document format' }))

        trackInternalEvent('resume_uploading_error', { errorType: 'Incorrect document format' })
      } else if (fileSize > FILESIZELIMITINMB) {
        dispatch(
          actions.setError({
            hasError: true,
            errorMessage: `The document should be less than ${FILESIZELIMITINMB}MB`,
          }),
        )

        trackInternalEvent('resume_uploading_error', {
          errorType: `The document should be less than ${FILESIZELIMITINMB}MB`,
        })
      } else if (!navigator.onLine) {
        dispatch(
          actions.setError({
            hasError: true,
            errorMessage: 'Something went wrong. Try uploading another resume',
          }),
        )

        trackInternalEvent('resume_uploading_error', {
          errorType: 'Something went wrong. Try uploading another resume',
        })
      } else {
        dispatch(actions.setSelectedFile(e.target.files[0]))
      }
    }
  }

  const handleOnDrop = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    dispatch(actions.setIsDragging(false))
    fileChange(e)
  }

  const isEmailValid = async (): Promise<boolean> => {
    // Check if Email Empty or File Upload is incomplete
    if (!email || uploadProgress < 100) return false

    // Check Email format
    const isValidFormat = isValidEmailFormat(email)
    return isValidFormat
  }

  const handleSubmit = async ({ email }: Payload) => {
    // All Email Validation check when clicked on "Send" button
    const validEmail = await isEmailValid()
    if (!validEmail) {
      dispatch(actions.setIsInValidEmail(true))
      form.setError('email', { type: 'custom', message: 'Invalid Email' })
      return
    }

    if (!userDetails) {
      dispatch(actions.parsedDetails({ upload: selectedFile, email }))
      parsingProgress > 0 && dispatch(actions.setParsingProgress(0))

      dispatch(actions.setStep(UploadResumeStep.SENDING_RESUME))
    } else {
      dispatch(actions.setStep(UploadResumeStep.USER_AVAILABLE))
    }
    trackInternalEvent('ulp_resume_uploaded')
  }

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value
    dispatch(actions.setEmail(email))
    dispatch(actions.setIsInValidEmail(false))
    form.clearErrors('email')
  }

  useEffect(() => {
    if (isFileSelected) {
      setTimeout(() => {
        if (uploadProgress < 100) {
          dispatch(actions.setUploadProgress(25))
        }
      }, 1000)
    }
  }, [dispatch, uploadProgress, isFileSelected])

  return showDragBox ? (
    <DragAndDrop onDragLeave={handleDragLeave} drag={isDragging} onDrop={e => handleOnDrop(e)} />
  ) : (
    <Styled.Form hasError={error.hasError} onSubmit={form.handleSubmit(handleSubmit)}>
      {step !== UploadResumeStep.UPLOAD_RESUME && (
        <Styled.TextFieldContainer hasError={Object.keys(form.formState.errors).length > 0}>
          <TextField
            autoFocus
            {...form.register('email', {
              required: 'This field is required',
              onChange: handleEmailChange,
            })}
            type="email"
            value={email}
            placeholder="Your email"
            error={form.formState.errors.email?.message}
          />
        </Styled.TextFieldContainer>
      )}
      {isFileSelected && !error.hasError ? (
        <UploadComplete fileChange={e => fileChange(e)} progress={uploadProgress} />
      ) : (
        <UploadDefault
          onDragOver={handleDragOver}
          drag={isDragging}
          fileChange={e => fileChange(e)}
          error={error}
        />
      )}

      {step !== UploadResumeStep.UPLOAD_RESUME && (
        <Styled.ButtonContainer>
          <Button theme="ghost" type="button" isDisabled={isDisabled} onClick={goBack}>
            Back
          </Button>
          <Button type="submit" isDisabled={isDisabled}>
            Send
          </Button>
        </Styled.ButtonContainer>
      )}
    </Styled.Form>
  )
}

export default UploadResume
