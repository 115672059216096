import styled from 'styled-components'
import { rotation } from 'builder/styles/keyframes'
import SPINNER_URL from './assets/spinner.svg'

export const Spinner = styled.div`
  width: 32px;
  height: 32px;
  background-image: url(${SPINNER_URL});
  background-size: 100% 100%;
  background-position: center center;
  animation: ${rotation} 0.6s infinite linear;
`
