import { memo } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { AIResumeDraftEvents, AIResumeFlows } from 'builder/modules/constants'
import { actions } from 'builder/modules/resumeEditor'
import { Icon24 } from 'builder/components/Icon'
import Tooltip from 'builder/components/Tooltip'
import Colors from 'builder/styles/colors'
import { i18n as I18n } from 'builder/utils/i18n'
import { useConfig } from 'builder/hooks/useConfig'
import {
  CreateWithAICard,
  CardDescription,
  CardSubtitle,
  CreateWithAICardTitle,
  ImageWrapper,
  CreateWithAIImage,
  CreateWithAIArrowIcon,
  AIPoweredBadge,
  CardTitleContainer,
  AIStarImg,
} from './styles'
import aiImg from './aiImg.svg'
import starImg from './starImg.svg'

type Props = {
  onClose: () => void
}

const CreateWithAI = (props: Props) => {
  const { onClose } = props
  const arrowStyles = {
    fill: Colors.Neutral40,
  }

  const config = useConfig()
  const isUSBasedUser = config?.features.geoipCode === 'US'
  const useNewPhrases = config?.features.newStartScreen === 'old_design_new_text'

  const dispatch = useDispatch()

  const title = useNewPhrases
    ? I18n.t('builder.prefill_resume.options_view.options.create_with_ai.title')
    : I18n.t('builder.ai_resume_draft.create_with_ai')

  const descriptionOld = isUSBasedUser
    ? I18n.t('builder.ai_resume_draft.description')
    : I18n.t('builder.ai_resume_draft.description_non_linkedin')

  const descriptionNew = isUSBasedUser
    ? I18n.t('builder.prefill_resume.options_view.options.create_with_ai.description')
    : I18n.t('builder.prefill_resume.options_view.options.create_with_ai.description_non_linkedin')

  const description = useNewPhrases ? descriptionNew : descriptionOld

  const handleCardClick = () => {
    trackInternalEvent(AIResumeDraftEvents.clickCreateWithAI)
    dispatch(actions.resetAIResumeData())
    if (!isUSBasedUser) {
      dispatch(actions.setAIResumeFlow(AIResumeFlows.Questionnaire))
      dispatch(actions.setHideLinkedInFlow(true))
    }
    dispatch(actions.setShowAIResumeModal(true))
    onClose()
  }

  return (
    <CreateWithAICard onClick={handleCardClick}>
      <ImageWrapper>
        <CreateWithAIImage src={aiImg} alt="Create with AI" />
      </ImageWrapper>
      <CardDescription>
        <CardTitleContainer>
          <CreateWithAICardTitle>{title}</CreateWithAICardTitle>
          <Tooltip value={I18n.t('builder.ai_resume_draft.ai_powered')}>
            <AIPoweredBadge>
              <AIStarImg src={starImg} alt="AI star" />
            </AIPoweredBadge>
          </Tooltip>
        </CardTitleContainer>
        <CardSubtitle>{description}</CardSubtitle>
      </CardDescription>
      <CreateWithAIArrowIcon>
        <Icon24.ToggleArrow style={arrowStyles} />
      </CreateWithAIArrowIcon>
    </CreateWithAICard>
  )
}

export default memo(CreateWithAI)
