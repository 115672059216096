import { memo } from 'react'
import { useConfig } from 'builder/hooks/useConfig'
import { CardContainer } from './styles'

import CreateNewResume from './CreateNewResume'
import CreateWithAI from './CreateWithAI'
import UploadResume from './UploadResume'
import UseExample from './UseExample'

type Props = {
  onClose: () => void
}

const OptionCards = (props: Props) => {
  const { onClose } = props

  const config = useConfig()

  const showAIResumeDraft = config?.features.aiResumeDraft
  const showUseExamples = config?.features.startWithExamples
  const showUploadResume = config?.features.uploadResume

  return (
    <CardContainer>
      {showUploadResume && <UploadResume />}
      {showAIResumeDraft && <CreateWithAI {...{ onClose }} />}
      {showUseExamples && <UseExample {...{ onClose }} />}
      <CreateNewResume {...{ onClose }} />
    </CardContainer>
  )
}

export default memo(OptionCards)
