import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 40px 20px;
  background-color: ${Colors.Neutral10};
`

export const Content = styled.div`
  width: 100%;
  max-width: 415px;
  text-align: center;
`

export const Visual = styled.div`
  max-width: 320px;
  margin: 0 auto 32px;
  background-image: url(${require('images/error.svg')});
  background-size: contain;
  background-repeat: no-repeat;

  &:before {
    content: '';
    display: block;
    padding-bottom: 72.727%;
  }
`

export const Title = styled.div`
  ${Typography.S};
  font-weight: 600;
  margin-bottom: 8px;
`

const TextBlock = styled.div`
  & a {
    color: ${Colors.Blue50};
  }

  & a:hover {
    color: ${Colors.Blue70};
  }
`

export const Text = styled(TextBlock)`
  margin-bottom: 32px;
`

export const Extra = styled(TextBlock)`
  ${Typography.ButtonM};
  margin-top: 20px;
`
