import styled from 'styled-components'
import Icon24 from 'builder/components/Icon'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 160px);
  overflow: hidden;

  max-width: 1200px;
  width: 100vw;
  padding: 0 40px;
  margin: 40px auto;

  ${Media.Phone`
    margin: 0 20px;
  `};
`

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;

  ${Media.Phone`
    flex-direction: column;
    gap: 15px;
  `};
`

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

export const LeftArrowIcon = styled(Icon24.ChevronLeft)`
  padding-left: 3px;
`
export const BackButton = styled.div`
  ${Typography.Caption};
  ${FontWeights.DemiBold};
  display: inline-flex;
  padding: 4px 16px 4px 8px;
  align-items: center;
  border-radius: 20px;
  background: ${Colors.Neutral10};
  align-items: center;
  color: ${Colors.Neutral50};
  height: 32px;

  ${Media.Hover`
    &:hover {
      cursor: pointer;
      background: ${Colors.Blue10};
      color: ${Colors.Blue50}
    }
  `};

  & path {
    fill: currentColor;
  }

  ${Media.Phone`
    display: flex;
    padding: 16px 8px;
    height: 24px;
    width: 40px;
  `};
`

export const Title = styled.div`
  ${Typography.M};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
  max-width: 416px;
`

export const Body = styled.div`
  display: flex;
  justify-content: center;
  width: 416px;
  flex-direction: column;
  margin: 0 auto;
  ${Media.Phone`
    width: 100%;
  `};
`

export const Hint = styled.div`
  ${Typography.Caption};
  ${FontWeights.Medium};
  color: ${Colors.Neutral50};
  padding-top: 4px;
`

export const Divider = styled.div`
  ${Typography.Caps};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral50};
  display: flex;
  align-items: center;
  font-weight: 600;
  margin: 24px 0;
  white-space: nowrap;

  &:after,
  &:before {
    content: '';
    height: 1px;
    background-color: ${Colors.Neutral15};
    flex-grow: 1;
  }

  &:after {
    margin-left: 8px;
  }

  &:before {
    margin-right: 8px;
  }
`

export const CustomTextField = styled.div`
  margin-bottom: 16px;
`

export const CustomTextAreaField = styled.div`
  margin-top: 16px;
  & textarea {
    height: 120px;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding-top: 36px;
  padding-bottom: 40px;

  ${Media.Phone`
  padding-bottom: 0px;

  `};
`

export const DesktopContainer = styled.div`
  ${Media.Phone`
    display: none;
  `};
`

export const MobileContainer = styled.div`
  display: none;

  ${Media.Phone`
  display: flex;
    align-items: center;

  `};
`

export const DummyDiv = styled.div`
  width: 50px;
`
