import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'

export const ProgressText = styled.text`
  display: flex;
  align-items: center;
  text-align: center;
`

export const ScoreText = styled.tspan`
  ${FontWeights.DemiBold};
  ${Typography.S};
  text-align: center;
  color: ${Colors.Blue90};
`

export const PercentageText = styled.tspan`
  ${Typography.Caption};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral90};
`

export const Circle = styled.circle<{ score: number; circleCir: number }>`
  fill: 'none';
  stroke-linecap: 'round';
  transition: stroke-dasharray 0.8s ease-in;
  stroke-dasharray: ${props => (props.score / 100) * props.circleCir} ${props => props.circleCir};
`
