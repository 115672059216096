import { useI18n } from 'builder/hooks/useI18n'
import { useChromeExtension } from 'builder/hooks/useChromeExtension'
import { ChromeExtensionEmptyState } from '../../ChromeExtension/EmptyState/EmptyState'
import { useChromeExtensionBanner } from '../../ChromeExtension/hooks/useChromeExtensionBanner'

import {
  Title,
  Subtitle,
  NoSearchResultsImage,
  ChromeExtensionBanner,
  EmptySearchContainer,
  NoResultSearchTextContainer,
  NoResultSearchContainer,
} from './styles'
import NO_SEARCH_RESULTS from './assets/no_search_results.png'

export const NoResultsSearch = () => {
  const { i18n } = useI18n()
  const { isInstalled, canInstallExtension } = useChromeExtension()
  const { showBanner, hideBanner, getExtension } = useChromeExtensionBanner({ type: 'empty_state' })
  const showChromeExtensionBanner = canInstallExtension && !isInstalled && showBanner

  return (
    <EmptySearchContainer>
      <NoResultSearchContainer>
        <NoSearchResultsImage src={NO_SEARCH_RESULTS} />
        <NoResultSearchTextContainer>
          <Title>{i18n.t(`builder.job_search.job_search_results.empty_results.title`)}</Title>
          <Subtitle withMaxWidth>
            {i18n.t(`builder.job_search.job_search_results.empty_results.subtitle`)}
          </Subtitle>
        </NoResultSearchTextContainer>
      </NoResultSearchContainer>
      {showChromeExtensionBanner && (
        <ChromeExtensionBanner>
          <ChromeExtensionEmptyState hideBanner={hideBanner} getExtension={getExtension} />
        </ChromeExtensionBanner>
      )}
    </EmptySearchContainer>
  )
}
