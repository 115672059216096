import styled from 'styled-components'
import Typography from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import { Icon24 } from 'builder/components/Icon'

export const NotificationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 14px 60px;
  background-color: ${Colors.Indigo80};
  color: ${Colors.White};
  ${Typography.Caption};

  ${Media.Tablet`
    padding-left: 44px;
    padding-right: 44px;
  `}

  ${Media.Phone`
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    padding: 12px 60px 12px 20px;
  `}
`

export const NotificationLogo = styled.div`
  width: 81px;
  height: 14px;
  background-image: url(${require('images/builder/panel/topresume-notification/logo.svg')});
  background-size: contain;

  ${Media.Tablet`
    display: none;
  `}
`

export const NotificationDivider = styled.div`
  margin: 0 12px;
  width: 1px;
  height: 16px;
  background-color: ${Colors.White};
  opacity: 0.2;

  ${Media.Tablet`
    display: none;
  `}
`

export const NotificationTitle = styled.div`
  font-weight: 600;
  margin-right: 8px;

  ${Media.Phone`
    margin-right: 0;
  `}
`

export const NotificationText = styled.div`
  opacity: 0.56;
`

export const NotificationClose = styled(Icon24.Close)`
  position: absolute;
  top: calc(50% - 12px);
  right: 32px;
  opacity: 0.32;
  transition: opacity 0.2s;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  ${Media.Tablet`
    right: 12px;
  `}

  ${Media.Phone`
    top: 12px;
  `}
`
