import { PureComponent } from 'react'
import MediaQueries from 'builder/components/MediaQueries'

export const withAutoFocus = Component => {
  return class AutoFocusHoc extends PureComponent {
    render() {
      return (
        <MediaQueries>
          {matches => <Component autoFocus={!matches.isPhone} {...this.props} />}
        </MediaQueries>
      )
    }
  }
}
