import SwiperCore, { Navigation, Scrollbar, Mousewheel, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { trackInternalEvent } from '@rio/tracking'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { actions as uiActions } from 'builder/modules/ui'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as userSelectors } from 'builder/modules/user'
import { selectors as panelSelectors } from 'builder/modules/panel'
import { ResumeIDStatuses } from 'builder/modules/constants'
import { IService, EDashboardLocalStoreKeys } from 'builder/components/CIO-Dasboard-2.0/types'
import ServiceCard from 'builder/components/CIO-Dasboard-2.0/Components/ServiceCard'
import useWebsiteHost, {
  rioDomainList,
} from 'builder/components/CIO-Dasboard-2.0/hooks/useWebsiteHost'

import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import * as Styled from './styles'

type Props = {
  services: IService[]
  onServiceCardClick: (name: string | undefined) => void
  marqueeEffect?: boolean
  unClickable?: boolean
  showShadow?: boolean
  showNavigationButton?: boolean
}

SwiperCore.use([Navigation, Scrollbar, Mousewheel, Autoplay])

export const CareerGoalSlider = ({
  services,
  onServiceCardClick,
  marqueeEffect,
  unClickable,
  showShadow,
  showNavigationButton,
}: Props) => {
  const { isPhone, isTablet } = useMediaQueries()
  const resumeStatus = useTypedSelector(panelSelectors.resumeStatus)
  const userType = useTypedSelector(userSelectors.postPremium)

  const getLoaderStatus = (key: string | null, name: string) => {
    if (key) {
      return (name === 'resume' || name === 'tuner') && resumeStatus === ResumeIDStatuses.skipped
    }
    return false
  }

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const key = queryParams.get('tr_promo_banner')
  const dispatch = useDispatch()
  const { getHost } = useWebsiteHost()
  const isRioDomain = rioDomainList.includes(getHost)
  const activetab = localStorage.getItem(EDashboardLocalStoreKeys.ACTIVE_TAB)
  const label = activetab !== null ? activetab : 'find_a_job'

  const swiperConfig = marqueeEffect
    ? {
        speed: 2500,
        loop: true,
        autoplay: {
          delay: 0.5,
          disableOnInteraction: false,
        },
      }
    : {}

  return (
    <Styled.Wrapper
      showShadow={isPhone || isTablet ? false : showShadow}
      marqueeEffect={marqueeEffect}
    >
      <Swiper
        grabCursor
        slidesPerView="auto"
        navigation={!isPhone && !isTablet && showNavigationButton}
        direction="horizontal"
        scrollbar={true}
        mousewheel={true}
        {...swiperConfig}
      >
        {services.map(service => (
          <SwiperSlide key={service.title}>
            <ServiceCard
              key={service.title}
              service={service}
              isLoading={getLoaderStatus(key, service.title)}
              onClick={async () => {
                if (unClickable) {
                  return
                }
                trackInternalEvent('click_feature_card', {
                  target: service.targetEventName,
                  label: label,
                  source: 'dashboard',
                })
                service?.isComingSoon && onServiceCardClick(service?.cardName)
                if (
                  !service?.isComingSoon &&
                  isRioDomain &&
                  service?.isPremiumService &&
                  userType === 'free'
                ) {
                  dispatch(
                    uiActions.setPremiumServiceModal({
                      isOpen: true,
                      serviceType: service?.targetEventName,
                    }),
                  )
                  return
                }
                if (service?.isPostPremiumActive) {
                  dispatch(uiActions.setTrackJobPaywall(true))
                } else {
                  service.action && service.action()
                }
              }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Styled.Wrapper>
  )
}
