import { SortableElement } from 'react-sortable-hoc'
import { Skill, Container, RadioBtn, DragIcon } from './styles'

type Props = {
  id: number
  index: number
  text: string
  storyID: number
  isSelected: boolean
  isSelectionAllowed: boolean
  handleSelect: (id: number, storyID: number) => void
}

type ChildParam = {
  children: JSX.Element[]
}

const Draggable = SortableElement(({ children }: ChildParam) => <Container>{children}</Container>)

const ReviewSkill = ({
  id,
  text,
  index,
  storyID,
  isSelected,
  handleSelect,
  isSelectionAllowed,
}: Props) => {
  return (
    <Draggable index={index} disabled={!isSelected}>
      <RadioBtn
        {...{ index, isSelected, storyID, isSelectionAllowed }}
        onClick={() => handleSelect(id, storyID)}
      >
        {isSelected && index + 1}
      </RadioBtn>
      <Skill>{text}</Skill>
      <DragIcon {...{ isSelected }} />
    </Draggable>
  )
}

export default ReviewSkill
