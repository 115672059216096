// Any functions that format numbers can be added here

/** This function will return number in the timestamp format HH:MM
 * e.g. 300 => 05:00
 */
export const formatToTimestamp = (timer: number) => {
  const minutes = Math.floor(timer / 60)
  const seconds = timer % 60

  return `${(minutes < 10 ? '0' : '') + minutes}:${(seconds < 10 ? '0' : '') + seconds}`
}
