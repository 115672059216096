import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'
import { useAutoApply } from 'builder/views/AutoApply/hooks/useAutoApply'
import { actions as jobSearchActions } from 'builder/modules/jobSearch'

const AUTO_APPLY_URL = '/auto-apply/start'
const RECOMMENDED_JOBS = '/job-search?view=recommendation'

export const useRedirectToAutoApply = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { hasUserCompletedQuestionnaire } = useAutoApply()

  const redirectToAutoApply = useCallback(() => {
    if (hasUserCompletedQuestionnaire) {
      dispatch(jobSearchActions.startAutoApplyBubble({ enableFirstPage: true }))
      navigate(RECOMMENDED_JOBS)
    } else {
      navigate(AUTO_APPLY_URL)
    }
  }, [dispatch, hasUserCompletedQuestionnaire, navigate])

  return { redirectToAutoApply }
}
