import { BriefLetter, BriefResume } from 'builder/modules/panel'
import { formatDocumentPreviewUrl } from 'builder/utils/formatDocumentPreviewUrl'
import * as Styled from './styles'
const resume = require('../../assets/Resume.svg')
const googleLogo = require('../../assets/google-logo.svg')
const appleLogo = require('../../assets/apple-logo.svg')
const amazoneLogo = require('../../assets/amazone-logo.svg')

type Props = {
  document: BriefResume | BriefLetter | null | undefined
}

export const TailorYourResume = (props: Props) => {
  const companyLogo = [googleLogo, appleLogo, amazoneLogo]

  const { document } = props
  const imageSrc = document ? formatDocumentPreviewUrl(document, { size: 256 }) : resume

  return (
    <Styled.ResumeContainer>
      <Styled.ResumeImageContainer>
        <Styled.ResumeImage src={imageSrc} />
        <Styled.ResumeGradient />
        <Styled.ResumeBrandLogoContainer>
          {companyLogo.map((logo, index) => {
            return <Styled.CompanyLogoImage src={logo} key={index} />
          })}
        </Styled.ResumeBrandLogoContainer>
      </Styled.ResumeImageContainer>
    </Styled.ResumeContainer>
  )
}
