import { Component } from 'react'
import PropTypes from 'prop-types'
import ModalOverlay from 'builder/components/ModalOverlay'
import Checkbox from 'builder/components/Checkbox'
import Button from 'builder/components/Button'

import { i18n as I18n } from 'builder/utils/i18n'

import {
  Container,
  Close,
  CloseIcon,
  Title,
  Text,
  RightBlock,
  LeftBlock,
  PrimaryButton,
  BottomWrapper,
} from './styles'

const { localStorage } = window

class DialogModal extends Component {
  static propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    primaryButtonText: PropTypes.string,
    secondaryButtonText: PropTypes.string,
    isOpen: PropTypes.bool,
    swapButtons: PropTypes.bool,
    // used for generating local storage key when show checkbox is true
    dontShowAgainId: PropTypes.string,
    closeAction: PropTypes.func,
    primaryAction: PropTypes.func,
    dontShowAgaianClicked: PropTypes.func,
    secondaryAction: PropTypes.func,
  }

  static defaultProps = {
    title: 'Title',
    primaryButtonText: 'Confirm',
    secondaryButtonText: 'Cancel',
    text: '',
    dontShowAgainId: undefined,
    isOpen: false,
    swapButtons: false,
    primaryAction: () => {},
    dontShowAgaianClicked: () => {},
    secondaryAction: () => {},
  }

  state = {
    dontShowAgain: false,
    dontShowAgainItem: false,
  }

  componentDidUpdate(prevProps) {
    const shouldTriggerActionWithoutOpeningModal =
      !prevProps.isOpen && this.props.isOpen && this.state.dontShowAgainItem

    if (shouldTriggerActionWithoutOpeningModal) {
      this.props.primaryAction()
    }

    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({ dontShowAgain: false })
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      dontShowAgainItem: JSON.parse(
        localStorage.getItem(`DO_NOT_SHOW_DIALOG_MODAL_${nextProps.dontShowAgainId}`),
      ),
    }
  }

  handleCheckboxChange = event => {
    this.setState({ dontShowAgain: event.target.checked })
    this.props.dontShowAgaianClicked()
  }

  handleConfirm = () => {
    if (this.state.dontShowAgain) {
      localStorage.setItem(`DO_NOT_SHOW_DIALOG_MODAL_${this.props.dontShowAgainId}`, true)
    }

    this.props.primaryAction()
  }

  get buttonsProps() {
    const { swapButtons, primaryButtonText, secondaryAction, secondaryButtonText } = this.props

    const primary = {
      onClick: this.handleConfirm,
      children: primaryButtonText,
    }
    const secondary = {
      onClick: secondaryAction,
      children: secondaryButtonText,
    }

    const buttons = [primary]
    if (this.props.secondaryAction) {
      if (swapButtons) {
        buttons.unshift(secondary)
      } else {
        buttons.push(secondary)
      }
    }
    return buttons
  }

  render() {
    const { dontShowAgainId, title, text, closeAction, isOpen } = this.props
    const buttons = this.buttonsProps

    return (
      isOpen &&
      !this.state.dontShowAgainItem && (
        <ModalOverlay onClick={closeAction} overlayFadeDuration={150} contentSlideDuration={150}>
          <Container>
            {closeAction && (
              <Close onClick={closeAction}>
                <CloseIcon />
              </Close>
            )}

            <Title>{title}</Title>
            <Text>{text}</Text>

            <BottomWrapper>
              <LeftBlock>
                {dontShowAgainId && (
                  <Checkbox
                    label={I18n.t('builder.resume_editor.dont_ask_again')}
                    onChange={this.handleCheckboxChange}
                    checked={this.state.dontShowAgain}
                  />
                )}
              </LeftBlock>
              <RightBlock>
                <PrimaryButton theme="ghost" {...buttons[0]} />
                {buttons.length > 1 ? <Button {...buttons[1]} /> : null}
              </RightBlock>
            </BottomWrapper>
          </Container>
        </ModalOverlay>
      )
    )
  }
}

export default DialogModal
