import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { actions } from 'builder/modules/signIn'
import CreatePassword from 'builder/components/CreatePassword'

const CreatePasswordView = props => {
  const { token } = useParams()
  return <CreatePassword token={token} {...props} />
}
// ---
// Connect to data
// ---
const mapStateToProps = state => ({
  error: state.signIn.passwordSavingError,
  isSaving: state.signIn.isPasswordSaving,
  isSaved: state.signIn.isPasswordSaved,
})

const mapDispatchToProps = {
  save: actions.savePasswordRequest,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePasswordView)
