import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { Accomplishment, accomplishmentTipsText } from 'builder/modules/sevenStories'
import {
  Title,
  Wrapper,
  Container,
  TipsContainer,
  TitleContainer,
  TextFieldContainer,
  TooltipQuestionWrapper,
  TooltipQuestionWrapperTablet,
  HintIcon,
  TipsTitle,
} from './styles'

type Props = {
  accomplishmentData: Accomplishment[]
  getPlaceHolder: (index: number) => string
  handleAccomplishmentChange: (value: string, index: number) => void
}

const Accomplishments = ({
  getPlaceHolder,
  accomplishmentData,
  handleAccomplishmentChange,
}: Props) => {
  const { isPhone, isTablet } = useMediaQueries()

  const renderAccomplishments = accomplishmentData.map((acc, index) => {
    const isTipsVisible = index === 0 && !isPhone

    return (
      <Wrapper key={index} isError={!!acc.error}>
        <TitleContainer>
          <Title>{`Accomplishment ${index + 1}`}</Title>
          {isTipsVisible ? (
            <>
              {isTablet ? (
                <TooltipQuestionWrapperTablet
                  value={accomplishmentTipsText}
                  position="bottom"
                  multiline
                >
                  <TipsContainer className="tips-container">
                    <TipsTitle>Tips</TipsTitle>
                    <HintIcon className="hint-icon" />
                  </TipsContainer>
                </TooltipQuestionWrapperTablet>
              ) : (
                <TooltipQuestionWrapper value={accomplishmentTipsText} position="right" multiline>
                  <TipsContainer className="tips-container">
                    <TipsTitle>Tips</TipsTitle>
                    <HintIcon className="hint-icon" />
                  </TipsContainer>
                </TooltipQuestionWrapper>
              )}
            </>
          ) : null}
        </TitleContainer>
        <TextFieldContainer
          error={acc.error}
          value={acc.description}
          placeholder={getPlaceHolder(index)}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleAccomplishmentChange(event.target.value, index)
          }
        />
      </Wrapper>
    )
  })

  return <Container>{renderAccomplishments}</Container>
}

export default Accomplishments
