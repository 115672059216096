import styled from 'styled-components'
import Typography from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

const headerIconUrl = require('images/builder/account/cancel-subscription/header-icon.svg')

export const Container = styled.div`
  flex-grow: 1;
  padding: 60px 20px 100px;
  border-top: 1px solid ${Colors.Neutral15};
  background-color: ${Colors.White};

  ${Media.Phone`
    border-top: none;
    padding-top: 8px;
    padding-bottom: 80px;
  `};
`

export const Content = styled.div`
  max-width: 544px;
  margin: 0 auto;
`

export const Header = styled.header`
  padding-bottom: 48px;
  border-bottom: 1px solid ${Colors.Neutral15};
  margin-bottom: 48px;
  text-align: center;

  ${Media.Phone`
    padding-bottom: 40px;
    margin-bottom: 40px;
  `};

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
`

export const HeaderIcon = styled.div`
  width: 80px;
  height: 80px;
  margin: 0 auto 16px;
  background-image: url(${headerIconUrl});
  background-size: contain;
`

export const Title = styled.h1`
  ${Typography.XL};
  font-weight: 700;
  margin-bottom: 20px;

  ${Media.Phone`
    margin-bottom: 8px;
  `};
`

export const Description = styled.div`
  ${Typography.Subhead};
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
`
