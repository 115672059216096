import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { trackInternalEvent, trackMarketingEvent } from '@rio/tracking'
import { bindActionToPromise } from 'builder/utils/bindActionToPromise'
import PreviewPanel from 'builder/components/PreviewPanel'
import DocumentPreviewModal from 'builder/components/DocumentPreviewModal'
import DocumentCustomizationModal from 'builder/components/DocumentCustomizationModal'
import FloatingPreviewButton from 'builder/components/FloatingPreviewButton'
import { withMediaQueries } from 'builder/components/MediaQueries'
import { withConfig } from 'builder/components/Config'
import { Container, LeftSide } from './styles'
import { actions } from 'builder/modules/resumeEditor'
import { actions as coverLetterActions } from 'builder/modules/coverLetterEditor'
import { actions as panelActions } from 'builder/modules/panel'
import { actions as renderingActions } from 'builder/modules/rendering'
import { actions as coRegActions } from 'builder/modules/coRegistration'
import { actions as generalEditorActions } from 'builder/modules/generalEditor'
import { withRouter } from 'builder/hocs/withRouter'
import { navigate } from 'builder/modules/navigate'
import TunerPrePaywallModal from 'builder/components/TunerPrePaywallModal'
import { ResumeEditorSwitcher } from './ResumeEditorSwitcher'
import { JobPosting } from 'builder/components/DocumentEditor/JobPosting/JobPosting'
import AiCoverletterTailoredView from 'builder/components/DocumentEditor/AICoverLetter/AiCoverletterTailoredView'
import SavingInfoBanner from 'builder/components/JobTracking/SavingInfoBanner/SavingInfoBanner'
import { AICoverLetter } from 'builder/components/DocumentEditor/JobPosting/AiCoverLetter'
import EditAIJobDetails from 'builder/components/DocumentEditor/AICoverLetter/EditAIJobDetails'
import { FetchStatuses } from 'builder/modules/constants'
import { ErrorState } from 'builder/components/DocumentEditor/AICoverLetter/ErrorState'
import { ButtonSize } from 'builder/components/Button'
import AbandonmentModal from 'builder/components/AbandonmentModal'
import AbandonmentResumeModal from 'builder/components/AbandonmentResumeModal'
import { HAS_ABANDONMENT_RESUME_MODAL_BEEN_SHOW } from 'builder/components/AbandonmentResumeModal/constants'
import { UserType } from 'builder/modules/user'
import Paywall from 'builder/components/AiFeatures/Paywall'

class ResumeEditorView extends Component {
  static propTypes = {
    editorState: PropTypes.object.isRequired,
    resumeState: PropTypes.object.isRequired,
    coverLetterState: PropTypes.object,
    panelState: PropTypes.object,
    user: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    mediaQueries: PropTypes.object.isRequired,
    fetchResumeRequest: PropTypes.func.isRequired,
    downloadResume: PropTypes.func,
    isDownloading: PropTypes.bool,
    fetchEditorPayloadRequest: PropTypes.func.isRequired,
    updateTemplate: PropTypes.func.isRequired,
    updateSimpleField: PropTypes.func.isRequired,
    dispatch: PropTypes.func,
    location: PropTypes.object,
    initializeCoRegistration: PropTypes.func.isRequired,
    setOpenSavingInfoBanner: PropTypes.func.isRequired,
    setIsClickedOnAiCVBanner: PropTypes.func.isRequired,
    resetResumeOptimizerProcess: PropTypes.func.isRequired,
    setOpenOptimizerModal: PropTypes.func.isRequired,
    setIsClickedOnAiCVEditJobDetails: PropTypes.func.isRequired,
    setShowAICoverLetterPaywall: PropTypes.func,
    toggleAIProfileSummaryPaywall: PropTypes.func,
    fetchDocuments: PropTypes.func,
    resetGenerateAICoverLetterStatus: PropTypes.func,
    config: PropTypes.object.isRequired,
    isPlansPageAbandonmentModalOpen: PropTypes.bool,
  }

  state = {
    hasAbandonmentModalBeenShown: false,
    isUserLeaving: false,
    isFullScreen: false,
  }

  abandonmentModalListener = e => {
    if (e.pageY <= 5) {
      this.setState({ isUserLeaving: true })
    }
  }

  componentDidMount() {
    this.init()
  }

  init() {
    const {
      params: routeParams,
      location,
      mediaQueries,
      user,
      panelState,
      fetchDocuments,
      config,
    } = this.props

    // fetch data and initialize related modules
    const resumeId = parseInt(routeParams.id)
    this.props.fetchResumeRequest(resumeId, config?.features?.resumeOptimizer)
    this.props.fetchEditorPayloadRequest()
    this.props.initializeCoRegistration()
    // show the preview modal on mobiles
    const params = queryString.parse(location.search, { parseNumbers: true })
    if ('preview' in params && !mediaQueries.isDesktop) this.setState({ isFullScreen: true })
    // track the page opening
    trackInternalEvent('visit_resume_editor', { resume_id: resumeId, editor: 'builder' })

    const isDataLayerPushed = localStorage.getItem('DATA_LAYER_PUSH')
    const hasAbandonmentModalBeenShown = localStorage.getItem(
      HAS_ABANDONMENT_RESUME_MODAL_BEEN_SHOW,
    )

    if (hasAbandonmentModalBeenShown === 'true') {
      this.setState({ hasAbandonmentModalBeenShown: true })
    }

    if (user?.data?.socialAccount && !(isDataLayerPushed === 'true')) {
      localStorage.setItem('DATA_LAYER_PUSH', 'true')
      trackMarketingEvent('Sign up', 'Complete Registration')
    }

    if (panelState.documentsStatus !== 'LOADED') {
      // If someone has opened a resume directly, we call this action to get info of user documents
      fetchDocuments()
    }

    document.addEventListener('mousemove', this.abandonmentModalListener)
  }

  componentWillUnmount() {
    document.removeEventListener('mousemove', this.abandonmentModalListener)
  }

  componentDidUpdate(prevProps) {
    const { resumeState } = this.props
    const {
      isClickedOnAiCVBanner,
      optimizerSection: {
        jobPostingAPIData: { recommendedJobTitle },
      },
      AICoverLetter: { isShowUntailoreView },
    } = resumeState

    const isJobPostingLinked = recommendedJobTitle !== null && recommendedJobTitle !== ''

    const AICoverletterTailoredView =
      isClickedOnAiCVBanner && isJobPostingLinked && !isShowUntailoreView

    if (AICoverletterTailoredView) {
      trackInternalEvent('see_job_posting_selection_screen')
    }

    if (resumeState.error) navigate('/resumes')

    const prevResumeId = parseInt(prevProps.params.id)
    const newResumeId = parseInt(this.props.params.id)

    if (prevResumeId !== newResumeId) {
      this.init()
    }
  }

  handleFullSizeOpen = e => {
    e.preventDefault()
    this.setState({ isFullScreen: true })
  }

  handleFloatingButtonClick = () => {
    this.setState({ isFullScreen: true })
    trackInternalEvent('open_full_screen_resume_preview')
  }

  handleFullSizeClose = () => {
    this.setState({ isFullScreen: false })
  }

  handleBannerClose = () => {
    this.props.setOpenSavingInfoBanner(false)
  }

  handleBannerOpen = () => {
    navigate('/job-tracking')
    trackInternalEvent('open_job_tracking')
  }

  handleAbandonmentModalClose = () => {
    this.setState({ hasAbandonmentModalBeenShown: true, isUserLeaving: false })
  }

  handleSimpleFieldUpdate = (name, value, debounce) => {
    this.props.updateSimpleField(name, value, debounce)

    if (name === 'name') trackInternalEvent('rename_resume')
    if (name === 'color') trackInternalEvent('change_resume_color')
    if (name === 'locale') trackInternalEvent('change_resume_locale', { locale: value })
  }

  handleTemplateUpdate = id => {
    this.props.updateTemplate(id)
    trackInternalEvent('change_resume_template', { template: id })
  }

  render() {
    const {
      editorState,
      resumeState,
      coverLetterState,
      user,
      mediaQueries,
      isDownloading,
      downloadResume,
      params,
      setShowAICoverLetterPaywall,
      toggleAIProfileSummaryPaywall,
      setIsClickedOnAiCVBanner,
      resetGenerateAICoverLetterStatus,
      isPlansPageAbandonmentModalOpen,
      config,
    } = this.props
    const resumeId = parseInt(params.id)

    const { hasAbandonmentModalBeenShown, isFullScreen, isUserLeaving } = this.state
    const {
      resume,
      isSyncing,
      isOptimizerModalOpen,
      isOpenSavingInfoBanner,
      changeJobPostingLink,
      isClickedOnAiCVBanner,
      optimizerSection: {
        jobPostingAPIData: { recommendedJobTitle },
      },
      AICoverLetter: {
        generateAICoverLetterStatus,
        isOpenAICVOptimizerModal,
        isClickedOnAiCVEditModalIcon,
        isShowUntailoreView,
      },
      aiProfile,
    } = resumeState

    const {
      aiGenerationSection: { showPaywall: showAICoverLetterPaywall },
    } = coverLetterState

    const { showPaywall: showAIProfilePaywall } = aiProfile

    const showAIFeaturesPaywall = showAICoverLetterPaywall || showAIProfilePaywall

    const isDataLoaded = resume && editorState.isLoaded

    const PreviewModal = !mediaQueries.isPhone ? DocumentCustomizationModal : DocumentPreviewModal

    const showResumeOptimizerModal = isOptimizerModalOpen && resume && user.data
    const isJobPostingLinked = recommendedJobTitle !== null && recommendedJobTitle !== ''
    const AICoverletterTailoredView =
      isClickedOnAiCVBanner && isJobPostingLinked && !isShowUntailoreView
    const AiCoverletterNormalView =
      (isClickedOnAiCVBanner && !isJobPostingLinked) ||
      isOpenAICVOptimizerModal ||
      isShowUntailoreView

    const showGenerateCLErrorStateModal = generateAICoverLetterStatus === FetchStatuses.failed
    const onRetryAIGenerateErrorStateModal = () => {
      resetGenerateAICoverLetterStatus()
      setIsClickedOnAiCVBanner(true)
    }
    const errorStateButtonSize = !mediaQueries.isPhone ? ButtonSize.default : ButtonSize.small
    const inBuilderAbandonmentFlag = config?.features.inBuilderAbandonment || false
    const showAbandonmentModal =
      !hasAbandonmentModalBeenShown &&
      [UserType.free, UserType.postPremium].includes(user?.data?.billingInfo.userType) &&
      isUserLeaving &&
      inBuilderAbandonmentFlag &&
      user.data.showAbandonmentFlows

    const handleClosePaywall = () => {
      if (showAICoverLetterPaywall) {
        setShowAICoverLetterPaywall()
      } else {
        toggleAIProfileSummaryPaywall()
      }
    }

    return (
      <>
        <TunerPrePaywallModal />
        <Container>
          <LeftSide>
            <ResumeEditorSwitcher
              editorState={editorState}
              resumeState={resumeState}
              isDataLoaded={isDataLoaded}
              user={user}
            />
          </LeftSide>

          {/*
        Unmount PreviewPanel when DocumentPreviewModal is visible. Reasons:
        - prevent render if block is invisible - reduce the load of user's device
        - prevent parallel tasks - pdfjs worker can't do more than one in parallel
        */}
          {!isFullScreen && mediaQueries.isDesktop && (
            <PreviewPanel
              type="resume"
              onFullSize={this.handleFullSizeOpen}
              documentId={resumeId}
              isSyncing={isSyncing}
              onDownload={downloadResume}
              isDownloading={isDownloading}
              currentDocument={resume}
              isFullScreen={isFullScreen}
            />
          )}

          {isFullScreen && ( // TODO: remove it after DocumentPreviewModal speed up & refactoring.
            <PreviewModal // Make sure nothing is rendered if panel isn't shown
              type="resume"
              isSyncing={isSyncing}
              isDownloading={isDownloading}
              currentDocument={resume}
              editorState={editorState}
              onClose={this.handleFullSizeClose}
              onDownload={downloadResume}
              updateSimpleField={this.handleSimpleFieldUpdate}
              updateTemplate={this.handleTemplateUpdate}
              config={this.props.config}
              registeredAt={user.data.registeredAt}
              showAbandonmentFlows={user.data.showAbandonmentFlows}
            />
          )}

          {mediaQueries.isLaptop && isDataLoaded && (
            <FloatingPreviewButton isSaving={isSyncing} onClick={this.handleFloatingButtonClick} />
          )}
        </Container>
        {AICoverletterTailoredView && <AiCoverletterTailoredView />}
        {showResumeOptimizerModal && <JobPosting resumeId={resumeId} />}
        {AiCoverletterNormalView && <AICoverLetter resumeId={resumeId} />}
        {isClickedOnAiCVEditModalIcon && <EditAIJobDetails />}
        {isOpenSavingInfoBanner && !mediaQueries.isPhone && !changeJobPostingLink && (
          <SavingInfoBanner onClose={this.handleBannerClose} onOpen={this.handleBannerOpen} />
        )}
        {showAIFeaturesPaywall && (
          <Paywall onClose={handleClosePaywall} isAiProfile={showAIProfilePaywall} />
        )}
        {isPlansPageAbandonmentModalOpen && <AbandonmentModal />}

        {showGenerateCLErrorStateModal && (
          <ErrorState
            onClose={resetGenerateAICoverLetterStatus}
            onTryAgain={onRetryAIGenerateErrorStateModal}
            ButtonSize={errorStateButtonSize}
          />
        )}
        {showAbandonmentModal && (
          <AbandonmentResumeModal
            handleClose={this.handleAbandonmentModalClose}
            hasUserVisitedPlans={user.data.visitedPlans}
            email={user.data.email}
            fullName={user.data.fullName}
          />
        )}
      </>
    )
  }
}

// ---
// Connect to data
// ---
function mapStateToProps(state) {
  return {
    user: state.user,
    isDownloading: state.rendering.isDocumentDownloading,
    editorState: state.generalEditor,
    resumeState: state.resumeEditor,
    coverLetterState: state.coverLetterEditor,
    panelState: state.panel,
    isPlansPageAbandonmentModalOpen: state.ui.isPlansPageAbandonmentModalOpen,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: dispatch,
    fetchResumeRequest: (id, isResumeOptimizerFeatureOn) =>
      dispatch(actions.fetchResumeRequest({ id, isResumeOptimizerFeatureOn })),
    fetchEditorPayloadRequest: () => dispatch(generalEditorActions.fetchEditorPayloadRequest()),
    updateTemplate: id => dispatch(actions.updateTemplate({ id })),
    updateSimpleField: (name, value, debounce) =>
      dispatch(actions.updateSimpleField({ name, value, debounce })),
    downloadResume: bindActionToPromise(dispatch, renderingActions.download),
    initializeCoRegistration: () => dispatch(coRegActions.initialize()),
    resetResumeOptimizerProcess: () => dispatch(actions.resetResumeOptimizerProcess()),
    setOpenSavingInfoBanner: isOpen => dispatch(actions.setOpenSavingInfoBanner(isOpen)),
    setOpenOptimizerModal: isOpen => dispatch(actions.setOpenOptimizerModal(isOpen)),
    setIsClickedOnAiCVBanner: isClicked => dispatch(actions.setIsClickedOnAiCVBanner(isClicked)),
    setIsClickedOnAiCVEditJobDetails: isEdit =>
      dispatch(actions.setIsClickedOnAiCVEditJobDetails(isEdit)),
    setShowAICoverLetterPaywall: () => dispatch(coverLetterActions.setShowAICoverLetterPaywall()),
    toggleAIProfileSummaryPaywall: () => dispatch(actions.toggleAIProfileSummaryPaywall()),
    fetchDocuments: () => dispatch(panelActions.fetchDocuments()),
    resetGenerateAICoverLetterStatus: () =>
      dispatch(actions.setGenerateAICoverLetterStatus(FetchStatuses.notAsked)),
  }
}

export default withConfig(
  withMediaQueries(connect(mapStateToProps, mapDispatchToProps)(withRouter(ResumeEditorView))),
)
