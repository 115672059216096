import { ReactNode } from 'react'
import { ColumnName } from 'builder/modules/jobTracking'
import { Icon20 } from 'builder/components/Icon'
import { useI18n } from 'builder/hooks/useI18n'
import { statusesStyles } from '../CardStatusDrawer/StatusSelect'
import { CurrentStatus, StatusContainer } from './styles'

interface IProps {
  status: ColumnName
  onClick: () => void
  children: ReactNode
}

export const Status = ({ status, onClick, children }: IProps) => {
  const { i18n } = useI18n()

  const { icon, color } = statusesStyles[status]
  const label = i18n.t(`builder.job_tracking.columns.${status}`)
  return (
    <StatusContainer>
      <CurrentStatus color={color} onClick={onClick}>
        {icon}
        {label}
        <Icon20.ChevronDown />
      </CurrentStatus>
      {children}
    </StatusContainer>
  )
}
