import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Icon24 from 'builder/components/Icon'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  width: 100%;
  gap: 16px;
  display: flex;
  flex-direction: column;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Heading = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};
`

export const Ratio = styled.div`
  gap: 8px;
  display: flex;
`

export const ArrowContainer = styled.div`
  background-color: ${Colors.Green10};
`

export const UpArrow = styled(Icon24.ArrowUp)`
  color: ${Colors.Green60};
`

export const Number = styled.div``

export const Separator = styled.div`
  border: 1px solid ${Colors.Neutral15};
`
