import { Optional } from 'packages/types'
import { Question, ResumeParserApiResponse, StructuredResumeData } from './types'

export const getDataLocalStorageKey = (userId: Optional<number>): string => {
  return `career-profile-data-${userId || 'user'}`
}

export const filterUnansweredQuestions = (lastAnswerT: number, asked: Question[]) => {
  return asked.filter(a => Number(a?.time) < lastAnswerT)
}

export const getTodaysDateString = () => new Date().toDateString()

export const createResumeFromParsedData = (data: ResumeParserApiResponse) =>
  ({
    workExperiencesAttributes: data.positions.map(item => ({
      title: item.title,
      employer: item.organization,
      city: item.location.city,
      dateFrom: item.startDate,
      dateUntil: item.endDate,
      description: item.description,
    })),
    skillsAttributes: data.skills.map(item => ({
      skill: item.name,
      level: undefined,
    })),
    educationsAttributes: data.educations.map(item => ({
      school: item.organization,
      degree: item.degree,
      city: item.location.city,
      dateFrom: item.startDate,
      dateUntil: item.endDate,
      description: undefined,
    })),
    // unfortunately there is no courses info in parsed data
    coursesAttributes: [],
  } as StructuredResumeData)
