import styled from 'styled-components'
import { Button } from 'builder/components/Button'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const CancellationContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
`

export const ButtonConfirm = styled(Button)`
  ${Typography.Body};
  ${FontWeights.Medium};
  background-color: ${Colors.White};
  border-radius: 4px;
  color: ${Colors.Neutral90};
  border: 1px solid ${Colors.Neutral20};

  &:hover {
    background-color: ${Colors.White};
    color: ${Colors.Blue50};
    border: 1px solid ${Colors.Blue50};
  }
`
