import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { FontWeights } from 'builder/styles/typography'

const getBgColor = (referenceId: number) => {
  if ([1, 5].includes(referenceId)) return { default: Colors.Blue10, hover: Colors.Blue20 }
  if ([2, 6].includes(referenceId)) return { default: Colors.Indigo10, hover: Colors.Indigo20 }
  if ([3, 7].includes(referenceId)) return { default: Colors.Green10, hover: Colors.Green20 }
  if ([4, 8].includes(referenceId)) return { default: Colors.Amber10, hover: Colors.Amber20 }
}

export const Container = styled.div<{ referenceId: number }>`
  ${FontWeights.Medium};
  width: 100%;
  height: 48px;
  border-radius: 4px;
  padding-left: 16px;
  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: ${props => getBgColor(props.referenceId)?.default};

  &:hover {
    cursor: pointer;
    background-color: ${props => getBgColor(props.referenceId)?.hover};
  }
`

export const Icon = styled.img``
