import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Button, { ButtonSize } from 'builder/components/Button'
import Spinner from 'builder/components/Spinner'

export const Box = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Header = styled.div`
  ${Typography.S};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
`

export const SubHeader = styled.div`
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
  display: flex;
  align-items: center;
`

export const Text = styled.div`
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${Colors.Neutral90};
  padding-right: 25px;

  ${Media.Phone`
    padding-right: 10px;
    width: 80%;
  `}
`

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  ${Media.Phone`
    flex-direction: column;
  `}
`

export const ActionButton = styled(Button).attrs({ size: ButtonSize.small })`
  ${Media.Phone`
    margin-top: 15px;
    width: 100%;
  `}
`

export const SpinnerButton = styled(Spinner)`
  width: 23px;
  height: 23px;
`

export const LoadingButton = styled.div`
  padding: 0 75px;
`
