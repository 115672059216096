import { useMutation } from '@tanstack/react-query'
import { baseClient } from 'builder/modules/apiClient'

export const useTrDocParse = () => {
  return useMutation({
    mutationFn: async (documentId: number) => {
      const response = await baseClient.post(
        `/app/resume-parser/parse-top-resume-doc?document_id=${documentId}`,
        {},
      )
      return response.data
    },
  })
}
