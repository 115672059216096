import { useI18n } from 'builder/hooks/useI18n'
import { useSelector } from 'react-redux'
import { PersonalEditorField } from './PersonalEditorField'

export function AddressInFurigana(props) {
  const { i18n } = useI18n()
  const addressPronunciation = useSelector(state => state.resumeEditor.resume.addressPronunciation)

  return (
    <>
      {/* Address in Furigana */}
      <PersonalEditorField
        label={i18n.t('builder.resume_editor.address_pronunciation')}
        value={addressPronunciation || ''}
        name="addressPronunciation"
      />
    </>
  )
}
