import { useDispatch } from 'react-redux'
import { Fragment, useCallback, useState } from 'react'
import { Transition } from 'react-transition-group'
import { trackInternalEvent } from '@rio/tracking'
import { isEqual, difference, differenceWith, intersectionWith } from 'lodash'
import { CSSObjectWithLabel, StylesConfig, components, MultiValueRemoveProps } from 'react-select'
import { Icon20 } from 'builder/components/Icon'

import { baseClient } from 'builder/modules/apiClient'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors, actions, Skill, Label } from 'builder/modules/careerPath2'

import { StyledMultiSelect, ErrorMessage, NoOptionMessage, Bar } from './styles'

const MultiSelect = () => {
  const dispatch = useDispatch()
  const skills = useTypedSelector(selectors.skills)
  const validationError = useTypedSelector(selectors.validationError)
  const suggestedSkills = useTypedSelector(selectors.suggestedSkills)
  const suggestedSkillsCopy = useTypedSelector(selectors.suggestedSkillsCopy)

  const [isFocused, setIsFocused] = useState(true)

  const MultiValueRemove = (props: MultiValueRemoveProps) => {
    return (
      <div>
        <components.MultiValueRemove {...props}>
          <Icon20.Close />
        </components.MultiValueRemove>
      </div>
    )
  }

  // on focus add styles to container
  const customStyles: StylesConfig = {
    container: (provided: CSSObjectWithLabel) => {
      return {
        ...provided,
      }
    },
  }

  const uniqueSuggestedSkills = useCallback(
    (newValues: Skill[]) => {
      const uniqueSuggestedSkills = differenceWith(suggestedSkills, newValues, isEqual)

      dispatch(actions.setSuggestedSkills(uniqueSuggestedSkills))
    },
    [dispatch, suggestedSkills],
  )

  const restoreSuggestedSkills = useCallback(
    (unSelectedSkill: Skill[]) => {
      const isSkillPresent = intersectionWith(suggestedSkillsCopy, unSelectedSkill, isEqual)

      if (isSkillPresent.length) {
        dispatch(actions.setSuggestedSkills([...suggestedSkills, ...isSkillPresent]))
      }
    },
    [dispatch, suggestedSkills, suggestedSkillsCopy],
  )

  const trackSelectEvent = useCallback((label: string) => {
    trackInternalEvent('select_skill', { ...Label, skill: label })
  }, [])

  const trackDeleteEvent = useCallback((label: string) => {
    trackInternalEvent('delete_skill', { ...Label, skill: label })
  }, [])

  const handleChange = useCallback(
    (newValues: Skill[] | [], actionMeta) => {
      dispatch(actions.setValidationError(false))

      // update suggested skills to have unique values
      uniqueSuggestedSkills(newValues)

      // get unselected skill
      const unSelectedSkill = difference(skills, newValues)

      // restore unselected skill if present in suggested skills
      if (unSelectedSkill.length) {
        restoreSuggestedSkills(unSelectedSkill)
      }

      // add skills to select
      dispatch(actions.setSkills(newValues))

      if (actionMeta.action === 'select-option') {
        trackSelectEvent(actionMeta.option.label)
      } else {
        trackDeleteEvent(actionMeta.removedValue.label)
      }
    },
    [
      skills,
      dispatch,
      trackSelectEvent,
      trackDeleteEvent,
      uniqueSuggestedSkills,
      restoreSuggestedSkills,
    ],
  )

  // API call to get remote data
  const promiseOptions = useCallback(async (inputValue: string) => {
    trackInternalEvent('type_skill', { ...Label, skill: inputValue })

    const params = {
      startswith: inputValue,
      limit: 5,
    }

    const { data } = await baseClient.get('/career-data/v1/skills', { params })

    const options = data.map((skill: string) => ({
      value: skill.toLowerCase(),
      label: skill,
    }))

    return options
  }, [])

  const customNoOptionsMessage = () => {
    return <NoOptionMessage>No options</NoOptionMessage>
  }

  const handleFocus = useCallback(() => {
    setIsFocused(true)
  }, [setIsFocused])

  const handleBlur = useCallback(() => {
    setIsFocused(false)
  }, [setIsFocused])

  return (
    <Fragment>
      <StyledMultiSelect
        onFocus={handleFocus}
        onBlur={handleBlur}
        components={{ MultiValueRemove }}
        isMulti
        autoFocus
        noOptionsMessage={customNoOptionsMessage}
        isSearchable
        name="skills"
        value={skills}
        styles={customStyles}
        classNamePrefix="select"
        openMenuOnClick={false}
        loadOptions={promiseOptions}
        placeholder="Add your skills"
        onChange={(newValue, actionMeta) =>
          Array.isArray(newValue) && handleChange(newValue, actionMeta)
        }
      />
      <Transition in={isFocused} timeout={100}>
        {state => <Bar in={state} hasError={validationError} />}
      </Transition>
      {validationError && (
        <ErrorMessage>
          For a more accurate skill gap calculation, enter at least 5 skills
        </ErrorMessage>
      )}
    </Fragment>
  )
}

export default MultiSelect
