import styled from 'styled-components'
import Media from 'builder/styles/media'
import { Container as BaseContainer } from 'builder/components/Container'
import { HorizontalTransitionFade } from 'builder/components/HorizontalTransitionFade'

export const Container = styled(HorizontalTransitionFade)`
  margin: 0 auto;
  max-width: 416px;
  width: calc(100% - 40px);
`

export const FormContainer = styled(BaseContainer)`
  padding: 0;
  display: flex;
  margin-top: 64px;
  flex-flow: row wrap;
  justify-content: center;

  ${Media.Tablet`
    margin-top: 80px;
  `}

  ${Media.Phone`
    margin-top: 64px;
  `}
`
