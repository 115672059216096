export const TESTIMONIALS = [
  {
    id: 1,
    label: 'Good',
    description:
      'Jody was a pleasure to work with. She made some very helpful suggestions regarding my resume and took over the task of updating it beyond my expectations. She was very easy to talk to, answered all of my questions and offered a lot of encouragement. I highly recommend Jody as a career consultant!',
    name: 'David Birdsell • Lead UX/UI designer',
  },
  {
    id: 2,
    label: 'Nice',
    description:
      'Heather has been an invaluable asset to my job search. Her empathy and insightful guidance have been instrumental in navigating todays competitive job market. Drawing on her extensive experience, she has provided me with countless suggestions and advice, which have been crucial in preparing for upcoming interviews. I have found her to be a consummate professional, who has instilled in me hope for the future.',
    name: 'Ethan Sullivan • Customer Support Representative',
  },
  {
    id: 3,
    label: 'Good',
    description:
      'Anne-Marie has been brilliant. She helped me with a multitude of points, ranging from how to approach 1st interviews to improving my confidence after searching through LinkedIn that was hurting my confidence. She conpletely understands the way the industry works and is able to think in my best interest for the many interviews I have going on. Simply a super star.',
    name: 'Isabella Thompson • Social Media Manager',
  },
  {
    id: 4,
    label: 'Nice',
    description:
      'Barry explained everything clearly. He set achievable goals for me to accomplish to begin my work search journey. I am grateful for the guidance and I felt that he understood where I am coming from and has the knowledge to help me get where I want to be.',
    name: 'James Sanchez • Human Resources Manager',
  },
]
