import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { stripHtml } from 'builder/utils/stripHTML'
import { actions, selectors, Job, ColumnName, JobCreatePayload } from 'builder/modules/careerPath2'

type JobTrackingUtilities = {
  isPresent: boolean
  handleAddJobBoard: () => void
}

export const useJobTracker = (job: Job): JobTrackingUtilities => {
  const dispatch = useDispatch()

  const newTrackerCard: JobCreatePayload = {
    status: ColumnName.wishlist,
    title: job.jobTitle,
    company: job.company,
    link: job.url,
    description: stripHtml(job.snippet),
    open: false,
    slug: job.slug,
    location: job.location,
    logo: job.companyLogo,
  }

  const cards = useTypedSelector(selectors.selectJobs)
  const isExploreCareers = useTypedSelector(selectors.isExploreCareers)

  const jobTrackingCard = cards.find(card => {
    const cardSlugNumber = String(card.externalSlugId)
    const newTrackerSlugNumber = (newTrackerCard.slug?.match(/\d+$/) || [])[0] || ''

    return cardSlugNumber === newTrackerSlugNumber
  })

  const isPresent = !!jobTrackingCard && jobTrackingCard.status === ColumnName.wishlist

  const handleAddJobBoard = () => {
    if (isPresent) {
      dispatch(actions.deleteJobCardRequest({ id: jobTrackingCard.id }))
    } else {
      trackInternalEvent('add_to_job_tracker', {
        title: job.jobTitle,
        label: isExploreCareers ? 'explore_careers' : 'career_path2',
        position: job.position,
      })

      dispatch(actions.createJobCardRequest(newTrackerCard))
    }
  }

  return {
    isPresent,
    handleAddJobBoard,
  }
}
