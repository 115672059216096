import { all, put, call, takeLatest } from 'redux-saga/effects'
import { apiClient } from 'builder/modules/apiClient'
import ErrorLogger from 'builder/services/ErrorLogger'
import { actions } from 'builder/modules/lloyd'

export function* autoLoginCIOUserSaga({ payload }: ReturnType<typeof actions.autoLoginCIOUser>) {
  try {
    yield call(apiClient.get, `/users/initialize?token=${payload}`, {
      withCredentials: true,
      baseURL: process.env.LLOYD_APP_HOST,
    })

    yield put(actions.setIsLloydAuthenticated(true))
  } catch (error) {
    ErrorLogger.log(error)
  }
}

/** Bind side-effect handlers */
export const sagas = function* saga() {
  yield all([takeLatest(actions.autoLoginCIOUser, autoLoginCIOUserSaga)])
}
