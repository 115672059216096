import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

export const Divider = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  color: ${Colors.Neutral40};

  ${Media.Phone`
    margin: 16px 0;
  `};

  &:after,
  &:before {
    content: '';
    flex-grow: 1;
    height 1px;
    background-color: ${Colors.Neutral15};
  }

  &:after {
    margin-left: 8px;
  }

  &:before {
    margin-right: 8px;
  }
`

export const Footer = styled.div`
  margin-top: 24px;
  color: ${Colors.Neutral50};
  text-align: center;

  & a {
    color: ${Colors.Blue50};
  }

  & a:hover {
    color: ${Colors.Blue60};
  }
`
