import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'

export const Container = styled.div`
  padding-top: 8px;
  display: flex;
  gap: 8px;
  margin-top: auto;
`

export const MetaIcon = styled.img``

export const Text = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`
