import styled from 'styled-components'
import { getLineHeightRatio } from 'builder/components/SubscriptionCancellationFormNew/common/utils'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const CardTitle = styled.p`
  ${Typography.M};
  ${FontWeights.Medium};
  width: 155px;
  font-size: 19px;
  line-height: ${getLineHeightRatio(24, 19)};
  text-align: center;
  color: ${Colors.Neutral90};
  margin-top: 9px;

  ${Media.Tablet`
    width: 396px;
    text-align: left;
    margin: 0px;
    `}

  ${Media.Phone`
    width: 211px;
    font-size: 16px;
    line-height: ${getLineHeightRatio(20, 16)};
    text-align: left;
    margin: 0px;
    `}
`
