import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import CareerPlanningFindCoach from 'builder/components/CareerPlanningFindCoach'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'

import Iframe from './components/IFrame'
import Heading from './components/Heading'
import Description from './components/Description'
import { MainContent, Section } from './styles'

type Props = {
  src: string
  title: string
  subTitle: string
  planSlug: string
  trackingLabel: string
}

const LloydContent = ({ src, title, subTitle, trackingLabel, planSlug }: Props) => {
  const { isTablet } = useMediaQueries()
  const { features } = useFeaturesConfig()
  const showDescription = planSlug === 'first-90-days' || planSlug === 'path-to-promotion'

  return (
    <MainContent>
      <Section>
        <Heading {...{ title, subTitle }} />
        {showDescription && <Description {...{ planSlug }} />}
        {features.careerCoaching && isTablet && (
          <CareerPlanningFindCoach feature="lloyd" {...{ trackingLabel }} />
        )}
      </Section>
      <Iframe src={src} />
    </MainContent>
  )
}

export default LloydContent
