import { trackInternalEvent } from '@rio/tracking'
import { selectors } from 'builder/modules/careerPath2'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { fullContent, shortContent, jobSearchUrl } from './constants'
import {
  Image,
  Header,
  Content,
  Paragraph,
  Container,
  ImageWrapper,
  StyledButton,
  InnerContainer,
} from './styles'

const imgUrl = require('./assets/img/job-search.svg')

const JobSearchBanner = () => {
  const { isPhone } = useMediaQueries()
  const content = isPhone ? shortContent : fullContent
  const isExploreCareers = useTypedSelector(selectors.isExploreCareers)

  return (
    <Container>
      <InnerContainer>
        <Content>
          <Header>Master your job search</Header>
          <Paragraph>{content}</Paragraph>
          <StyledButton
            onClick={() => {
              window.open(jobSearchUrl, '_blank')

              trackInternalEvent('click_career_plan_card', {
                source: 'career_page',
                career_plan_name: 'execute_a_job_search',
                label: isExploreCareers ? 'explore_careers' : 'career_path2',
              })
            }}
          >
            Learn More
          </StyledButton>
        </Content>
        <ImageWrapper>
          <Image src={imgUrl} alt="job-search" />
        </ImageWrapper>
      </InnerContainer>
    </Container>
  )
}

export default JobSearchBanner
