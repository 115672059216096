import { FC, useState } from 'react'
import type { ResumeExample, ResumeExampleCategory, Optional } from '@rio/types'
import { useI18n } from 'builder/hooks/useI18n'
import Button from 'builder/components/Button'
import Icon from 'builder/components/Icon'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import DialogModal from 'builder/components/DialogModal'
import {
  BackButton,
  CategoryIcon,
  CategoryName,
  Chevron,
  Container,
  Footer,
  Image,
  JobTitle,
  NextButton,
  PrevButton,
  Preview,
  Rating,
  RatingNumber,
  RatingStars,
  Star,
  Viewport,
} from './styles'

interface ExamplePreviewProps {
  example: ResumeExample
  category: ResumeExampleCategory
  onClose: () => void
  onSelect: (id: number) => void
  onNextClick: () => void
  onPrevClick: () => void
  isLast: boolean
  isFirst: boolean
}

const ExamplePreview: FC<ExamplePreviewProps> = ({
  example,
  category,
  onClose,
  onSelect,
  onNextClick,
  onPrevClick,
  isLast,
  isFirst,
}) => {
  const { i18n } = useI18n()
  const { isPhone } = useMediaQueries()
  const [prefillId, setPrefillId] = useState<Optional<number>>(null)

  return (
    <Container>
      <Viewport>
        <Preview>
          {category.iconUrl && <CategoryIcon url={category.iconUrl} />}
          <CategoryName>{category.name}</CategoryName>
          <JobTitle>{example.jobTitle}</JobTitle>
          <Image>
            <img src={example.previewImageUrl} />
          </Image>
        </Preview>
      </Viewport>

      {!isFirst && (
        <PrevButton onClick={onPrevClick}>
          <Icon.Chevron />
        </PrevButton>
      )}

      {!isLast && (
        <NextButton onClick={onNextClick}>
          <Icon.Chevron />
        </NextButton>
      )}

      <Footer>
        {!isPhone && (
          <BackButton onClick={onClose}>
            <Chevron />
            <span>{i18n.t('builder.prefill.back_to_catalog')}</span>
          </BackButton>
        )}

        <Button onClick={() => setPrefillId(example.id)}>
          {i18n.t('builder.prefill.edit_example')}
        </Button>

        {isPhone && (
          <Button theme="ghost" onClick={onClose}>
            {i18n.t('builder.prefill.back_to_catalog')}
          </Button>
        )}

        {!isPhone && (
          <Rating>
            <RatingStars>
              <Star />
              <Star />
              <Star />
              <Star />
              <Star disabled={example.ratingScore < 4.5} />
            </RatingStars>
            <RatingNumber>{example.ratingScore}</RatingNumber>
          </Rating>
        )}
      </Footer>

      <DialogModal
        title={i18n.t('builder.prefill.confirmation_title')}
        text={i18n.t('builder.prefill.confirmation_text')}
        primaryButtonText={i18n.t('builder.prefill.confirmation_decline')}
        secondaryButtonText={i18n.t('builder.prefill.confirmation_accept')}
        isOpen={!!prefillId}
        primaryAction={() => setPrefillId(null)}
        secondaryAction={() => prefillId && onSelect(prefillId)}
        closeAction={() => setPrefillId(null)}
      />
    </Container>
  )
}

export default ExamplePreview
