import React from 'react'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors, ColumnName } from 'builder/modules/jobTracking'
import { AutoApplyStatusLabel } from 'builder/components/JobTracking/AutoApplyStatusLabel'
import { selectors as jobSearchSelectors } from 'builder/modules/jobSearch'
import { useAutoApply } from 'builder/views/AutoApply/hooks/useAutoApply'
import { Job } from '../JobSearchResult/types'
import { MetasAutoApply } from '../JobDetails/styles'
import { HeartIconButton } from '../HeartIconButton/HeartIconButton'
import { getDefaultLogoForCompany } from '../utils/getDefaultLogoForCompany'
import * as Meta from './Meta'

import { Container, Content, Logo, Title, Company, CompanyName, Position, Metas } from './styles'

interface Props {
  job: Job & { position?: number }
  isActive: boolean
  onClick: () => void
}

const JobItem = ({ job, isActive, onClick }: Props) => {
  const autoApplyBubbles = useTypedSelector(jobSearchSelectors.autoApplyBubbles)

  const { isUserOnAutoApplyPlan } = useAutoApply()
  // load job tracking jobs
  const cards = useTypedSelector(selectors.selectJobs)
  const jobTrackingCard = cards.find(
    card =>
      card.company === job.company &&
      card.title === job.job_title &&
      card.externalSlugId === job.external_slug_id &&
      card.status !== ColumnName.recommended,
  )
  const isAutoAppliedJob =
    jobTrackingCard?.status === ColumnName.auto_apply || jobTrackingCard?.autoApplyStatus

  return (
    <Container id={'card' + job.external_slug_id} isActive={isActive} onClick={onClick}>
      <Logo src={job.company_logo || getDefaultLogoForCompany()} />
      <Content>
        <Title>
          <Company>
            <CompanyName>{job.company}</CompanyName>
            <HeartIconButton job={job} />
          </Company>
          <span>
            <Position>{job.job_title}</Position>
          </span>
        </Title>
        <Metas>
          <Meta.Location job={job} />
          <Meta.Remote job={job} />
          <Meta.CreatedAt job={job} />
        </Metas>
        {isUserOnAutoApplyPlan && isAutoAppliedJob && !autoApplyBubbles.running && (
          <MetasAutoApply>
            <Meta.AutoApplyStatus />
            {jobTrackingCard.autoApplyStatus && (
              <AutoApplyStatusLabel autoApplyStatus={jobTrackingCard.autoApplyStatus} />
            )}
          </MetasAutoApply>
        )}
      </Content>
    </Container>
  )
}

export default React.memo(JobItem)
