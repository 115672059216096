import { createElement, useMemo, ReactElement } from 'react'

// Makes color highlighted React elements from text string
function transformToColored(text: string): Array<ReactElement> {
  // Convert "[text]" to "__[text]__" format
  // to make it easier to parse and replace the text with React nodes
  const templatedText = text.replace(/\[([^\]]+)?]/g, '__[$1]__')

  return templatedText.split('__').map((text, index) => {
    return createElement(index % 2 ? 'mark' : 'span', { key: index }, text)
  })
}

// Makes bold highlighted React elements from text string
function transformToBold(text: string): Array<ReactElement> {
  return text.split('**').map((text, index) => {
    return createElement(index % 2 ? 'b' : 'span', { key: index }, transformToColored(text))
  })
}

/**
 * Makes highlighted text bold or colored
 *
 * @example
 * useHighlight("Foo**bar**baz")
 * <span>foo</span><b>bar</b><span>baz</span>
 *
 * useHighlight("Foo[bar]baz")
 * <span>foo</span><mark>[bar]</mark><span>baz</span>
 */

const useHighlight = (highlight: string): Array<ReactElement> => {
  return useMemo(() => {
    return transformToBold(highlight)
  }, [highlight])
}

export default useHighlight
