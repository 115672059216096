import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'

export const Wrapper = styled.div`
  margin-top: 40px;
`

export const Container = styled.ul`
  margin-top: 24px;
  padding-left: 29px;
  color: ${Colors.Neutral50};
`

export const Item = styled.span`
  padding-left: 5px;
  ${Typography.Subhead};
`
