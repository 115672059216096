import { HelpModalLabelPayload } from '../types'

export const JobSearchLabelData: HelpModalLabelPayload = {
  buttonTitle: 'Land your dream job',
  buttonDescription: 'Follow the steps and be prepared',
  modalTitle: 'Land your dream job',
  modalDescription: 'Follow the steps below and be prepared',
  allStepCompletionStateTitle: 'Congratulations!',
  allStepCompletionStateSubTitle:
    'Keep improving your professional level and you will have no equal',
}
