import { Message, MessageContainer } from './styles'

interface Props {
  commonError?: string
  occupationError?: string
  locationError?: string
}

const ErrorMessage = ({ commonError, occupationError, locationError }: Props) => {
  return (
    <MessageContainer>
      {commonError ? (
        <Message>{commonError}</Message>
      ) : (
        <>
          {occupationError && <Message>{occupationError}</Message>}
          {locationError && <Message>{locationError}</Message>}
        </>
      )}
    </MessageContainer>
  )
}

export default ErrorMessage
