import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { Icon24 } from 'builder/components/Icon'
import Media from 'builder/styles/media'
import checkmark from './assets/checkmark.svg'
import headerImage from './assets/headerImage.svg'

export const Overlay = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background: #000000ba;
  z-index: 9999;
  height: 100vh;
  width: 100vw;
`

export const RootModalContainer = styled.div`
  font-family: 'TT Commons', system-ui, -apple-system, 'Segoe UI', Roboto, Ubuntu, Cantarell,
    'Noto Sans', sans-serif;
  background-color: ${Colors.White};
  border-radius: 8px;
  display: flex;
  align-items: center;
  width: 544px;
  max-width: 544px;

  ${Media.Phone`
    width: 90%;
  `}
`

export const ModalSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`

export const CloseIcon = styled(Icon24.Close)`
  display: block;
  color: ${Colors.Neutral50};
  transition: color 0.3s;
  &:hover {
    color: ${Colors.Blue50};
  }
`

export const Close = styled.div`
  display: flex;
  justify-self: flex-end;
  right: 0;
  height: fit-content;
  width: fit-content;
  margin: 32px 32px 0 32px;
  background: ${Colors.White};
  border-radius: 32px;
  color: ${Colors.Neutral30};
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${Colors.Blue10};
  }

  ${Media.Tablet`
    margin: 30px;
  `}

  ${Media.Phone`
    margin: 15px;
  `}
`

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

export const NotEnoughCreditsHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;

  ${Media.Phone`
    gap: 8px;
  `}
`

export const NotEnoughCreditsTitle = styled.h2`
  font-size: 25px;
  font-weight: 600;
  color: ${Colors.Neutral90};
  line-height: 36px;

  ${Media.Phone`
    font-size: 20px;
    line-height: 24px;
  `}
`

export const NotEnoughCreditsDescription = styled.p`
  color: ${Colors.Neutral50};
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

export const NotEnoughCreditsFeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 24px;
`

export const NotEnoughCreditsFeatureTitle = styled.h3`
  color: #303848;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 1.8px;
  text-transform: uppercase;

  ${Media.Phone`
    font-size: 12px;
    line-height: 16px;
  `}
`

export const NotEnoughCreditsFeatures = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  list-style: none;
  margin: 0;
  background-color: var(--neutral-5);
  border-radius: var(--2XS, 8px);
  padding: 0.5em 1em;
`

export const NotEnoughCreditsFeature = styled.li`
  color: ${Colors.Neutral80};
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  font-size: 16px;

  &:before {
    content: '';
    display: inline-block;
    float: left;
    right: 4px;
    top: 5px;
    background-image: url(${checkmark});
    width: 20px;
    height: 22px;
    margin-right: 4px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  ${Media.Phone`
    font-size: 15px;
    line-height: 20px;
  `}
`

export const NotEnoughCreditsTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 30px 30px 30px;
`

export const HeaderImage = styled.div`
  border-radius: 8px 8px 0px 0px;
  background-image: url(${headerImage});
  width: 100%;
  height: 280px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 1em;

  ${Media.Phone`
    height: 195px;
  `}
`

export const CTAContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`

export const CTAPrimary = styled.button`
  border-radius: 4px;
  border: none;
  background: ${Colors.Blue50};
  color: ${Colors.White};
  text-align: center;
  font-family: 'TT Commons', system-ui, -apple-system, 'Segoe UI', Roboto, Ubuntu, Cantarell,
    'Noto Sans', sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  display: inline-flex;
  padding: 12px 40px;
  justify-content: center;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background: ${Colors.Blue60};
  }
  &:active {
    background: ${Colors.Blue70};
  }
  &:disabled {
    color: ${Colors.Neutral50};
    background-color: transparent;
    text-align: center;
    font-family: 'TT Commons', system-ui, -apple-system, 'Segoe UI', Roboto, Ubuntu, Cantarell,
      'Noto Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
  }
  ${Media.Phone`
    font-size: 16px;
    padding: 8px 12px;
    white-space: nowrap;
    line-height: 20px;
  `}
`
