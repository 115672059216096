import { useEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import Button, { ButtonSize } from 'builder/components/Button'
import premiumServiceModalData from 'builder/components/PremiumServicesModal/constants'
import { Icon20 } from 'builder/components/Icon'
import ModalOverlay from 'builder/components/ModalOverlay'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { selectors as uiSelectors, actions as uiActions } from 'builder/modules/ui'
import { goToBillingPlanPage } from 'builder/utils/goToBillingPlanPage'
import * as Styles from './styles'

const features = [
  { id: 1, text: 'Access to all templates' },
  { id: 2, text: 'Unlimited resume downloads' },
  { id: 3, text: 'Unlimited cover letters' },
]

type PaywallProps = {
  source: string | undefined
}

const PremiumServicesModal: React.FC<PaywallProps> = ({ source }) => {
  const { isPhone } = useMediaQueries()
  const premiumServicesSelector = useTypedSelector(uiSelectors.showPremiumServiceModal)
  const dispatch = useDispatch()

  /*
   handle redirect to premium services only for resume.io
  */
  const handleUpgrade = () => {
    dispatch(uiActions.setPremiumServiceModal({ isOpen: false, serviceType: undefined }))
    goToBillingPlanPage({
      onSuccessRedirect: premiumServiceModalData.find(elm => elm.id === source)?.redirectUrl,
    })
    trackInternalEvent('click_upgrade_button')
  }
  /*
   handle premium services modal skip
  */
  const handleSkip = () => {
    dispatch(uiActions.setPremiumServiceModal({ isOpen: false, serviceType: undefined }))
  }
  /*
   handle premium services modal close up
  */
  const handleClose = () => {
    dispatch(uiActions.setPremiumServiceModal({ isOpen: false, serviceType: undefined }))
  }

  useEffect(() => {
    trackInternalEvent('see_paywall_pop_up', {
      service: source,
    })
  }, [source])

  if (!premiumServicesSelector.isOpen) {
    return null
  }

  return (
    <ModalOverlay overlayFadeDuration={150} contentSlideDuration={150}>
      <Styles.Container>
        <Styles.Close onClick={handleClose}>
          <Icon20.Close />
        </Styles.Close>
        <Styles.Visual src={premiumServiceModalData.find(elm => elm.id === source)?.imgSrc} />
        <Styles.DescriptionSection>
          <Styles.Description>
            <Styles.Header>
              <Styles.PremiumBadge>
                <Styles.PremiumIcon />
                <Styles.PremiumText>Available for Premium</Styles.PremiumText>
              </Styles.PremiumBadge>
              <Styles.Title>Boost Your Career</Styles.Title>
            </Styles.Header>
            <Styles.SubTitle>
              Get access to career services, a digital platform that supports your professional
              freedom and success
            </Styles.SubTitle>
          </Styles.Description>
          <Styles.UnlockFeaturesSection>
            <Styles.UnlockText>Also Unlock:</Styles.UnlockText>
            <Styles.FeaturesContainer>
              {features.map(feature => (
                <Styles.FeatureItem key={feature.id}>
                  <Styles.GreenTick />
                  <Styles.Feature>{feature.text}</Styles.Feature>
                </Styles.FeatureItem>
              ))}
            </Styles.FeaturesContainer>
          </Styles.UnlockFeaturesSection>
          <Styles.ButtonContainer>
            <Button
              size={!isPhone ? ButtonSize.default : ButtonSize.small}
              theme="ghost"
              onClick={handleSkip}
            >
              Cancel
            </Button>
            <Button size={!isPhone ? ButtonSize.default : ButtonSize.small} onClick={handleUpgrade}>
              Upgrade Now
            </Button>
          </Styles.ButtonContainer>
        </Styles.DescriptionSection>
      </Styles.Container>
    </ModalOverlay>
  )
}

export default PremiumServicesModal
