import { createAction, createReducer } from '@reduxjs/toolkit'
import type { Resume } from '@rio/types'
import { DocumentTypes, FetchStatuses } from 'builder/modules/constants'
import { Store } from 'builder/modules/store'
import { SignUpInfo, SignUpStore } from './types'

// ---
// Actions
// ---

export const actions = {
  // Setters
  setEmailErrorCode: createAction<string>('signUp/SET_EMAIL_ERROR'),
  setSignUpInfo: createAction<Partial<SignUpInfo>>('signUp/SET_SIGN_UP_INFO'),
  clearSignUpInfo: createAction('signUp/CLEAR_SIGN_UP_INFO'),
  setSignUpPayload: createAction<
    Pick<SignUpStore, 'resumeTemplates' | 'coverLetterTemplates' | 'heroTemplateName' | 'tests'>
  >('signUp/SET_SIGN_UP_PAYLOAD'),
  setSuggestedEmail: createAction<string>('signUp/SET_SUGGESTED_EMAIL'),
  setParsedResume: createAction<Partial<Resume>>('signUp/SET_PARSED_RESUME'),
  toggleEmailValidating: createAction<boolean>('signUp/TOGGLE_EMAIL_CHECKING'),

  // Requests
  signUpRequest: createAction<{ type: DocumentTypes; isSuperApp: boolean } & SignUpInfo>(
    'signUp/SIGN_UP_REQUEST',
  ),
  signUpComplete: createAction('signUp/SIGN_UP_COMPLETE'),
  persistSignUpInfo: createAction<{ type: DocumentTypes } & Partial<SignUpInfo>>(
    'signUp/SIGN_UP_PERSIST_FORM',
  ),
  validateEmail: createAction<{ email: string }>('signUp/SIGN_UP_VALIDATE_EMAIL'),
  loadSignUpInfo: createAction<{ type: DocumentTypes }>('signUp/SIGN_UP_LOAD_INFO'),
  getSignUpPayload: createAction('signUp/GET_SIGN_UP_PAYLOAD'),
  setIsThankYouPageVisible: createAction('signUp/SET_IS_THANK_YOU_PAGE_VISIBLE'),
  emailMarketingConsent: createAction('signUp/EMAIL_MARKETING_CONSENT'),
}

// --
// Selectors
// --
export const selectors = {
  signUpInfo: (state: Store) => state.signUp.signUpInfo,
  isEmailSuggested: (state: Store) => state.signUp.isEmailSuggested,
}

// ---
// Reducer
// ---

const initialState: SignUpStore = {
  resumeTemplates: [],
  coverLetterTemplates: [],
  heroTemplateName: null,
  emailErrorCode: '',
  suggestedEmail: '',
  isEmailSuggested: false,
  isEmailValidating: false,
  signUpProcess: FetchStatuses.notAsked,
  signUpInfo: {
    template: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    confirm_email: false,
  },
  tests: {},
  isThankYouPageVisible: false,
}

export const reducer = createReducer(initialState, reducer => {
  reducer.addCase(actions.setSignUpPayload, (draft, action) => {
    draft.resumeTemplates = action.payload.resumeTemplates
    draft.coverLetterTemplates = action.payload.coverLetterTemplates
    draft.heroTemplateName = action.payload.heroTemplateName
    draft.tests = action.payload.tests
  })

  reducer.addCase(actions.setEmailErrorCode, (draft, action) => {
    draft.emailErrorCode = action.payload
  })

  reducer.addCase(actions.setSuggestedEmail, (draft, action) => {
    draft.suggestedEmail = action.payload
    if (action.payload) draft.isEmailSuggested = true
  })

  reducer.addCase(actions.setSignUpInfo, (draft, action) => {
    draft.signUpInfo = {
      ...draft.signUpInfo,
      ...action.payload,
    }
  })

  reducer.addCase(actions.toggleEmailValidating, draft => {
    draft.isEmailValidating = !draft.isEmailValidating
  })

  reducer.addCase(actions.signUpRequest, draft => {
    draft.signUpProcess = FetchStatuses.loading
  })

  reducer.addCase(actions.signUpComplete, draft => {
    draft.signUpProcess = FetchStatuses.loaded
  })

  reducer.addCase(actions.clearSignUpInfo, draft => {
    draft.signUpInfo = {
      template: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      confirm_email: false,
    }
  })

  reducer.addCase(actions.setIsThankYouPageVisible, (draft, action) => {
    if (action.payload === true) {
      draft.isThankYouPageVisible = true
    }
  })
})
