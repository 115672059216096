import { useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import EditorField from 'builder/components/EditorField'
import { useI18n } from 'builder/hooks/useI18n'
import { actions } from 'builder/modules/resumeEditor'
import { Store } from 'builder/modules/store'
import { formatDate } from '../../../../../../rendering/templates/shared/utils/formatJapaneseDate'
import CustomDatePicker from '../CustomComponent/CustomDatePicker/CustomDatePicker'

export const DateOfBirth = (): JSX.Element => {
  const { i18n } = useI18n()
  const birthDate = useSelector((state: Store) => state.resumeEditor.resume?.birthDate)
  const nameRef = useRef('birthDate')
  const { updateSimpleField } = actions
  const dispatch = useDispatch()
  const updateDateRange = useCallback(
    value => {
      const birthDate = formatDate(value)
      dispatch(
        updateSimpleField({
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          name: nameRef.current as any,
          value: birthDate,
          debounce: true,
        }),
      )
    },
    [dispatch, updateSimpleField],
  )

  return (
    <>
      <EditorField>
        <CustomDatePicker
          value={birthDate || ''}
          onChange={updateDateRange}
          label={i18n.t('builder.resume_editor.dob')}
        />
      </EditorField>
    </>
  )
}
