import { Sessions, Container, Price, PerSession } from './styles'

interface PackProps {
  description: string
  amount: string
  numberOfSessions: number
}

interface HeaderProps {
  pack: PackProps
}

export const Header = ({ pack }: HeaderProps) => {
  const sessionAmount = pack?.amount?.replace(/\$|,/g, '') ?? ''
  const perSession = Math.round(parseFloat(sessionAmount) / pack.numberOfSessions)

  return (
    <Container>
      <Sessions>{pack?.description}</Sessions>
      <Price>{pack?.amount}</Price>
      <PerSession>${perSession}/session</PerSession>
    </Container>
  )
}
