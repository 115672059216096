import { Listbox } from '@headlessui/react'
import { SelectOption, SelectValue } from '../../types'
import * as Styled from './styles'

type Props<V> = {
  options: SelectOption<V>[]
}

export const DesktopOptions = <V extends SelectValue>({ options }: Props<V>) => {
  return (
    <Listbox.Options as={Styled.Options}>
      <Styled.OptionsList>
        {options.map(option => (
          <Listbox.Option as="div" key={option.id ?? 'empty'} value={option.id}>
            {({ active, selected }) => (
              <Styled.OptionContent isActive={active} isSelected={selected}>
                {option.name}
              </Styled.OptionContent>
            )}
          </Listbox.Option>
        ))}
      </Styled.OptionsList>
    </Listbox.Options>
  )
}
