import LocationIcon from '../../../../asset/LocationIcon.svg'
import JobIcon from '../../../../asset/JobIcon.svg'
import MoneyIcon from '../../../../asset/MoneyIcon.svg'
import { Container, MetaIcon, Text } from './styles'

interface MetaProps {
  value: string
}

interface Props {
  icon: string
  value: string
}

const Base = ({ icon, value }: Props) => (
  <Container>
    <MetaIcon src={icon} />
    <Text>{value}</Text>
  </Container>
)

export const Location = ({ value }: MetaProps) => <Base icon={LocationIcon} value={value ?? ''} />

export const Type = ({ value }: MetaProps) => <Base icon={JobIcon} value={value ?? ''} />

export const Salary = ({ value }: MetaProps) => <Base icon={MoneyIcon} value={value ?? ''} />
