import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const PromoteChipContainer = styled.div`
  display: inline-flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  background: ${Colors.Blue20};
  position: fixed;
  bottom: 0px;
  right: 156px;
  border-radius: 8px 8px 0px 0px;
  cursor: pointer;
`

export const AddIconContainer = styled.div`
  width: 24px;
  height: 24px;
`

export const AddIcon = styled.img`
  width: 100%;
  height: 100%;
`

export const AddExtensionTag = styled.p`
  ${Typography.Caption};
  ${FontWeights.Medium};
  color: ${Colors.Blue80};
`

export const PromoteBannerContainer = styled.div`
  width: 366px;
  height: 416px;
  border-radius: 16px;
  background: ${Colors.White};
  position: relative;
`

export const CloseContainer = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: ${Colors.Neutral5};
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.Neutral50};

  &:hover {
    color: ${Colors.Blue50};
  }
`

export const VisualContainer = styled.div`
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background: ${Colors.White};
  max-height: 204px;
`

export const Image = styled.img`
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`

export const BottomContainer = styled.div`
  background: ${Colors.White};
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`

export const ContentContainer = styled.div`
  padding: 16px 23px 24px;
  height: 144px;
`

export const Caption = styled.p`
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${Colors.Neutral60};
`

export const Title = styled.p`
  ${Typography.Subhead};
  ${FontWeights.Medium};
  color: #000;
  margin: 8px 0px;
`

export const Description = styled.p`
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${Colors.Neutral60};
`

export const ControlsContainer = styled.div`
  padding: 8px 24px 24px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 16px;
`

export const DotsContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  width: 85px;
  margin-right: 16px;
`

export const Dot = styled.div<{ isActive?: boolean }>`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: ${({ isActive }) => (isActive ? Colors.Blue50 : Colors.Neutral15)};
  transition: width 0.2s ease;
  margin-right: 8px;
`

export const PrimaryButton = styled.div<{ isLastStep: boolean }>`
  height: 36px;
  padding: 8px 12px;
  text-align: center;
  border-radius: 4px;
  border: ${({ isLastStep }) => `1px solid ${isLastStep ? Colors.Blue50 : Colors.Neutral20}`};
  color: ${({ isLastStep }) => (isLastStep ? Colors.White : Colors.Neutral90)};
  background: ${({ isLastStep }) => (isLastStep ? Colors.Blue50 : Colors.White)};
  height: 100%;
  width: 217px;
  cursor: pointer;
  ${Typography.Caption};
  ${FontWeights.Medium};

  &:hover {
    border: ${({ isLastStep }) => `1px solid ${isLastStep ? Colors.Blue60 : Colors.Blue50}`};
    color: ${({ isLastStep }) => (isLastStep ? Colors.White : Colors.Blue50)};
    background: ${({ isLastStep }) => (isLastStep ? Colors.Blue60 : Colors.White)};
  }
`
