import styled from 'styled-components'
import Colors from 'builder/styles/colors'

export const Container = styled.div`
  display: flex;
`

export const Step = styled.div<{ done: boolean }>`
  width: 10px;
  height: 2px;
  background-color: ${({ done }) => (done ? Colors.Blue50 : Colors.Neutral10)};
`
