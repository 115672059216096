import styled from 'styled-components'
import Sizes from 'builder/styles/sizes'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import { ModalClose } from 'builder/components/ModalClose'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Sizes.XS};
`

export const FoldableContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Sizes.XS};
`

export const CardContentWrapper = styled.div`
  padding: 4px 20px 24px;

  ${Media.Phone`
    padding: 0;
  `};
`

export const Field = styled.div<{ fullwidth?: boolean }>`
  flex: 0 0 ${props => (props.fullwidth ? '100%' : 'calc(50% - 20px)')};
  width: ${props => (props.fullwidth ? '100%' : 'calc(50% - 20px)')};
  margin-right: 40px;

  ${Media.Phone`
    flex: 0 0 100%;
    margin-right: 0;
    margin-bottom: 20px;
  `};

  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
`

export const Row = styled.div`
  display: flex;
  flex-flow: row wrap;
`

export const NewSkill = styled.div`
  display: flex;
  flex-flow: column;
  padding: 16px 20px 24px;
  border: 1px solid ${Colors.Neutral15};
  border-radius: 4px;
  background-color: ${Colors.White};
  gap: 24px;
  position: relative;
`

export const Header = styled.div`
  display: flex;
  flex-flow: column;
`

export const SkillName = styled.span`
  ${Typography.Caption};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral90};
`

export const SkillLevel = styled.span`
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
`

export const CloseButton = styled(ModalClose)`
  position: absolute;
  top: 16px;
  right: 20px;
`
