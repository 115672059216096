import styled from 'styled-components'
import Media from 'builder/styles/media'

export const MainContent = styled.div`
  width: 100%;
  gap: 48px;
  display: flex;
  flex-direction: column;

  ${Media.Desktop`
    max-width: 736px;
  `}
`

export const Section = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
`
