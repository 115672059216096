import { memo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { TextField } from 'builder/components/TextField'
import { useAppSelector } from 'builder/hooks/useAppSelector'
import { actions } from 'builder/modules/resumeEditor'
import { AIResumeDraftEventLabels, AIResumeDraftEvents } from 'builder/modules/constants'
import { useI18n } from 'builder/hooks/useI18n'
import { TextFieldContainer } from './styles'

export const DesiredPosition = memo(() => {
  const { desiredPosition } = useAppSelector(
    store => store.resumeEditor.aiResume.questionnaireData.userInput,
  )
  const dispatch = useDispatch()
  const { i18n } = useI18n()

  useEffect(() => {
    trackInternalEvent(AIResumeDraftEvents.seeDesiredJobStep, {
      label: AIResumeDraftEventLabels.aiResume,
    })
  }, [])

  const handleChange = (event: React.BaseSyntheticEvent) => {
    const { value } = event.target
    dispatch(actions.setAIResumeDesiredPostion(value))
  }

  return (
    <TextFieldContainer>
      <TextField
        value={desiredPosition}
        name="desiredPosition"
        label={i18n.t('builder.ai_resume_draft.desired_job.desired_job_title')}
        onChange={handleChange}
      />
    </TextFieldContainer>
  )
})
