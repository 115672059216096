import { LoadingAnimation } from 'builder/components/LoadingAnimation/LoadingAnimation'
import { LoadingScreenItem } from 'builder/modules/interview/types'
import { Wrapper, Title, Description } from './styles'

type LoadingScreenProps = {
  content: LoadingScreenItem
}

export const LoadingScreen = ({ content }: LoadingScreenProps) => {
  return (
    <Wrapper>
      <LoadingAnimation />
      <Title>{content.title}</Title>
      <Description $width={content.customContentWidth}>{content.content}</Description>
    </Wrapper>
  )
}
