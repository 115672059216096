import { i18n } from 'builder/utils/i18n'
import { SectionNames } from 'builder/modules/resumeEditor/sectionNames'
import FocusManager from 'builder/services/FocusManager'
import { CheckFn } from '../types'

export const checkPhone: CheckFn = ({ phoneNumber }) => {
  const isComplete = typeof phoneNumber === 'string' && phoneNumber.length > 0

  return {
    sectionId: SectionNames.details,
    progress: isComplete ? 1 : 0,
    id: 'no_phone',
    title: i18n.t('builder.resume_tuner.tips.no_phone.title'),
    description: i18n.t('builder.resume_tuner.tips.no_phone.description'),
    subject: i18n.t('builder.resume_tuner.tips.no_phone.subject'),
    label: i18n.t('builder.resume_tuner.issues.no_phone'),
    action: () => FocusManager.focus('phoneNumber'),
    actionText: i18n.t('builder.resume_tuner.tips.no_phone.action_text'),
  }
}
