import styled, { css } from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import { Icon24 } from 'builder/components/Icon'
import { FontWeights } from 'builder/styles/typography'
import Icon from './components/TopContent/components/Icon'

export const Container = styled.div`
  position: relative;

  &.progress-bar-transition-enter {
    opacity: 0;
  }

  &.progress-bar-transition-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }

  &.progress-bar-transition-exit {
    opacity: 1;
  }

  &.progress-bar-transition-exit-active {
    opacity: 0;
    transition: opacity 150ms;
  }
`

export const SectionName = styled.div`
  width: 100%;
  ${FontWeights.Medium};
`

export const CloseSectionIcon = styled(Icon24.Chevron)<{ $isOpen: boolean }>`
  color: ${props => (!props.$isOpen ? Colors.Neutral30 : Colors.Blue60)};
  transform: ${props => (props.$isOpen ? 'rotate(-90deg)' : 'rotate(90deg)')};
  transition: transform 0.3s ease-in-out;
`

export const Wrapper = styled.div`
  width: 100%;
  height: 56px;
  gap: 12px;
  display: flex;
  align-items: center;
  background: ${Colors.White};
  border-bottom: 1px solid ${Colors.Neutral15};
  position: fixed;
  top: 79px;
  left: 0;
  padding-inline: 20px;
  z-index: 2;
`

export const FoldableContainer = styled.div<{ $isOpen: boolean }>`
  width: 100%;
  z-index: 1;
  padding: 32px 20px;
  position: absolute;
  top: 57px;
  left: 0;
  background-color: ${Colors.White};

  ${Media.Phone`
    padding-top: 16px;
  `}

  &.menu-transition-enter {
    opacity: 0;
  }

  &.menu-transition-enter-active {
    opacity: 1;
    transition: opacity ${300 * 0.75}ms;
  }

  &.menu-transition-exit {
    opacity: 1;
  }

  &.menu-transition-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }

  &::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100vh;
    left: 0;
    bottom: 0;
    top: 100%;
    background-color: ${Colors.Neutral100};
    opacity: 0.6;
    transition: opacity 100ms;

    ${props =>
      !props.$isOpen &&
      css`
        opacity: 0;
      `}
  }

  ${Media.Laptop`
    top: 54px;
  `}
`

export const IconWrapper = styled.div<{ notify?: boolean }>`
  width: 24px;
  height: 24px;
  position: relative;

  ::before {
    content: '';
    position: absolute;
    right: 0;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${Colors.Blue50};
    opacity: ${props => (props.notify ? 1 : 0)};
    transition: opacity 0.3s;
  }
`

export const FilterIcon = styled(Icon)<{ $showFilters: boolean }>`
  color: ${props => (props.$showFilters ? Colors.Blue60 : Colors.Neutral30)};
`

export const Divider = styled.div`
  width: 1px;
  height: 24px;
  background-color: ${Colors.Neutral15};
`
