import * as React from 'react'
import {
  DocumentNameContainer,
  DocumentNameTitleWrapper,
  DocumentNameTitle,
} from 'builder/components/DocumentName/styles'
import { DocumentNameText } from './styles'

interface Props {
  value: string
}

export const DocumentName = ({ value }: Props) => {
  return (
    <DocumentNameContainer>
      <DocumentNameTitleWrapper>
        <DocumentNameTitle>
          <DocumentNameText>{value}</DocumentNameText>
        </DocumentNameTitle>
      </DocumentNameTitleWrapper>
    </DocumentNameContainer>
  )
}

export default React.memo(DocumentName)
