import { useEffect, useRef, useState } from 'react'
import Icon24 from 'builder/components/Icon'
import { useI18n } from 'builder/hooks/useI18n'
import { useHover } from 'builder/hooks/useHover'
import { Interview, InterviewAnswer, InterviewQuestion } from 'builder/modules/interview/types'
import Spinner from 'builder/components/Spinner'
import ErrorLogger from 'builder/services/ErrorLogger'
import { TRANSLATION_PREFIX } from '../../FeedbackView'
import { Button } from '../Button/Button'
import { VideoHeader } from '../VideoHeader/VideoHeader'
import {
  Container,
  NextForwardButton,
  ControlContainer,
  PauseIcon,
  BackIcon,
  PlayIcon,
  PlayPauseContainer,
  FooterContainer,
  FullTimeline,
  PlayTimeline,
  TimelineContainer,
  Time,
  TimelineTimeContainer,
  CustomVideo,
  MobileButtonContainer,
  LoadingContainer,
} from './styles'

interface Props {
  selectedQuestionIndex: number
  hasPremiumFeatures: boolean
  setSelectedQuestionIndex: React.Dispatch<React.SetStateAction<number>>
  onNextQuestion: () => void
  onPrevQuestion: () => void
  answer?: InterviewAnswer
  toggleSidebar: () => void
  showInterviewHistoryDialog: () => void
  interview?: Interview
  questions: InterviewQuestion[]
  stopPerformance: () => void
  selectedQuestion?: InterviewQuestion
}

const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60)
  const secondsLeft = Math.floor(seconds % 60)
  return `${minutes.toString()}:${secondsLeft.toString().padStart(2, '0')}`
}

type PlayingStatus = 'loading' | 'stopped' | 'playing'

export const VideoPlayer = ({
  toggleSidebar,
  interview,
  selectedQuestionIndex,
  setSelectedQuestionIndex,
  onNextQuestion,
  onPrevQuestion,
  showInterviewHistoryDialog,
  answer,
  hasPremiumFeatures,
  questions,
  stopPerformance,
}: Props) => {
  const [videoPlaying, setVideoPlaying] = useState<PlayingStatus>('loading')
  const [progress, setProgress] = useState(0)
  const { hoverRef, isHovering } = useHover<HTMLDivElement>()
  const videoRef = useRef<HTMLVideoElement>(null)

  const selectAnswer = interview?.questions[selectedQuestionIndex].answer

  const { duration, currentTime } = {
    duration: answer?.duration || 0,
    currentTime: videoRef?.current?.currentTime || 0,
  }

  useEffect(() => {
    async function forceVideoToBeLoaded() {
      if (!selectAnswer || !videoRef.current) return
      try {
        setVideoPlaying('loading')

        videoRef.current.src = selectAnswer.video || selectAnswer.audio
        // fix bug for ios 16, force ios to download
        await videoRef?.current?.play()
        videoRef?.current?.pause()
      } catch (error) {
        ErrorLogger.log(error, { tag: 'interview_feedback_play' })
      }
    }
    forceVideoToBeLoaded()
  }, [selectAnswer])

  const { i18n } = useI18n()

  const startPlaying = async () => {
    try {
      await videoRef?.current?.play()
      setVideoPlaying('playing')
    } catch (error) {
      ErrorLogger.log(error, { tag: 'interview_feedback_play' })
    }
  }
  const stopPlaying = () => {
    videoRef?.current?.pause()
    setVideoPlaying('stopped')
  }
  const togglePlaying = () => {
    if (videoPlaying === 'loading') return
    if (videoPlaying === 'stopped') {
      startPlaying()
    } else {
      stopPlaying()
    }
  }

  const resetVideo = () => {
    stopPlaying()
    setProgress(0)
    if (videoRef.current) {
      videoRef.current.currentTime = 0
    }
  }

  const handleNextVideo = () => {
    onNextQuestion()
    resetVideo()
  }
  const handlePrevVideo = () => {
    onPrevQuestion()
    resetVideo()
  }

  const handleTimeUpdate = () => {
    setProgress(() => {
      if (videoRef.current && videoRef.current.currentTime > 0) {
        const percentage = (videoRef.current.currentTime / duration) * 100
        if (percentage >= 100) {
          return 100
        }
        return percentage
      }
      return 0
    })
  }

  const isLoading = videoPlaying === 'loading'
  const isPlaying = videoPlaying === 'playing'

  return (
    <Container>
      <VideoHeader
        stopPerformance={stopPerformance}
        showInterviewHistoryDialog={showInterviewHistoryDialog}
        toggleSidebar={toggleSidebar}
        hasPremiumFeatures={hasPremiumFeatures}
        interview={interview}
      />

      <CustomVideo
        ref={videoRef}
        playsInline
        onCanPlay={() => setVideoPlaying('stopped')}
        onWaiting={() => setVideoPlaying('loading')}
        onTimeUpdate={handleTimeUpdate}
        onEnded={handleNextVideo}
        onPause={() => setVideoPlaying('stopped')}
      />

      <ControlContainer ref={hoverRef} onClick={togglePlaying}>
        {!isHovering && isLoading && (
          <LoadingContainer>
            <Spinner />
            {i18n.t(`${TRANSLATION_PREFIX}.video_loading_v2`)}
          </LoadingContainer>
        )}
        {isHovering && (
          <>
            <NextForwardButton>
              <BackIcon onClick={handlePrevVideo} />
            </NextForwardButton>

            <PlayPauseContainer>
              {isLoading && <Spinner />}
              {!isLoading && (isPlaying ? <PauseIcon /> : <PlayIcon />)}
            </PlayPauseContainer>

            <NextForwardButton>
              <Icon24.ForwardIcon onClick={handleNextVideo} />
            </NextForwardButton>
          </>
        )}
      </ControlContainer>
      <FooterContainer>
        <TimelineTimeContainer>
          <TimelineContainer $videoCount={questions.length}>
            {questions.map((question, i) => (
              <FullTimeline key={question.id} onClick={() => setSelectedQuestionIndex(i)}>
                {i === selectedQuestionIndex && <PlayTimeline $progress={progress} />}
              </FullTimeline>
            ))}
          </TimelineContainer>
          <Time>
            {formatTime(currentTime)} / {formatTime(duration)}
          </Time>
        </TimelineTimeContainer>
        {i18n.t(`${TRANSLATION_PREFIX}.question`)} {selectedQuestionIndex + 1}
        <MobileButtonContainer>
          {hasPremiumFeatures && (
            <Button onClick={showInterviewHistoryDialog}>
              {i18n.t(`${TRANSLATION_PREFIX}.history`)}
            </Button>
          )}
          <Button onClick={toggleSidebar}>{i18n.t(`${TRANSLATION_PREFIX}.feedback`)}</Button>
        </MobileButtonContainer>
      </FooterContainer>
    </Container>
  )
}
