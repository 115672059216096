import styled from 'styled-components'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'

export const Wrapper = styled.footer`
  ${Typography.Tiny};
  width: 100%;
  max-width: calc(544px + 80px);
  padding: 0 40px 20px;
  margin: 0 auto;
  text-align: center;
  color: ${Colors.Neutral50};

  ${Media.Tablet`
    padding-left: 20px;
    padding-right: 20px;
  `}

  & a {
    color: ${Colors.Blue50};
  }

  & a:hover {
    color: ${Colors.Blue70};
  }
`
