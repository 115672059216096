import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Button, { ButtonSize } from 'builder/components/Button'

export const DropzoneContainer = styled.div<{ isDragOver: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin: 48px;
  padding: 56px 20px;
  text-align: center;
  cursor: pointer;
  color: ${Colors.Neutral60};
  transition: color 0.15s;

  &:hover {
    color: ${Colors.Neutral50};
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border: 13px dashed ${Colors.Neutral60};
    border-image: url(${require('images/builder/avatar-editor/dropzone-border.svg')});
    border-image-slice: 13;
    border-image-repeat: round;
    pointer-events: none;
    transition: opacity 0.15s;
    opacity: ${props => (props.isDragOver ? 0.64 : 0.2)};
  }

  &:hover:after {
    opacity: 0.64;
  }
`

export const DropzoneIcon = styled.div`
  width: 48px;
  height: 40px;
  margin: 0 auto 20px;
  background-image: url(${require('images/builder/avatar-editor/dropzone-icon.svg')});
  background-repeat: no-repeat;
  background-size: contain;
`

export const DropzoneCaption = styled.div`
  max-width: 240px;
  margin-bottom: 24px;
`

export const DropzoneInput = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
`

export const DropzoneButton = styled(Button).attrs({ size: ButtonSize.small })``

export const DropzoneNote = styled.div`
  position: absolute;
  bottom: 24px;
  left: 20px;
  right: 20px;
  ${Typography.Caption};
  color: ${Colors.Neutral60};
  opacity: 0.48;
`
