import { memo } from 'react'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { Icon20 } from 'builder/components/Icon'
import { useAppSelector } from 'builder/hooks/useAppSelector'
import { AIResumeFlows, GenerateResumeStatus } from 'builder/modules/constants'
import ModalOverlay from '../../ModalOverlay'
import { Container, DesktopClose } from './styles'
import AIAssistedHeader from './AIAssistedHeader'
import LinkedInFlow from './LinkedInFlow'
import QuestionnaireFlow from './QuestionnaireFlow'
import MobileActionBar from './MobileActionBar'
import ProcessAnimation from './ProcessAnimation'
import WarningModal from './WarningModal'
import GenerationFailed from './GenerationFailed'
import useResumeWithAI from './useResumeWithAI'

const CreateResumeWithAI = () => {
  const { isPhone } = useMediaQueries()
  const { aiResumeFlow, generateResumeStatus, showWarningModal } = useAppSelector(
    store => store.resumeEditor.aiResume,
  )
  const { handleClose } = useResumeWithAI()

  const isLinkedInFlow = aiResumeFlow === AIResumeFlows.LinkedIn
  const isQuestionnaireFlow = aiResumeFlow === AIResumeFlows.Questionnaire
  const onClose = () => {
    handleClose()
  }

  const renderFlow = () => {
    if (isLinkedInFlow) return <LinkedInFlow />
    if (isQuestionnaireFlow) return <QuestionnaireFlow />
  }

  return (
    <ModalOverlay overlayFadeDuration={300} contentSlideDuration={300} fullScreen={isPhone}>
      {showWarningModal ? (
        <WarningModal />
      ) : (
        <>
          {generateResumeStatus === GenerateResumeStatus.failed && <GenerationFailed />}
          {generateResumeStatus === GenerateResumeStatus.processing && <ProcessAnimation />}
          {generateResumeStatus === GenerateResumeStatus.notStarted && (
            <Container {...{ isLinkedInFlow }}>
              {isPhone ? (
                <MobileActionBar />
              ) : (
                <DesktopClose onClick={onClose}>
                  <Icon20.Close />
                </DesktopClose>
              )}
              <AIAssistedHeader />
              {renderFlow()}
            </Container>
          )}
        </>
      )}
    </ModalOverlay>
  )
}

export default memo(CreateResumeWithAI)
