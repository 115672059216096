import { useCallback, useMemo } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import {
  Cards,
  InternalEvents,
} from 'builder/components/SubscriptionCancellationFormNew/common/types'
import { MouseClickEvent } from 'builder/modules/ui'
import { i18n as I18n } from 'builder/utils/i18n'
import { useConfig } from 'builder/hooks/useConfig'
import { useCancellationFormContext } from '../../FormSection'
import { PromotionButtonContainer } from './PromotionButtonContainer'
import { PromotionButton } from './PromotionButton'
import { GettingStartedText } from './GettingStartedText'

const Button = () => {
  const {
    activeCard,
    isACIORegisteredUser,
    isPromotionButtonLoading,
    promotionButtonClickHandler,
    promotionButtonText,
    promotionTopic,
  } = useCancellationFormContext()
  const config = useConfig()
  const isSubscriptionV4Enabled = config?.features.subCancellationV4 || false

  const promotionParameterForTracking = useMemo(() => {
    switch (promotionTopic) {
      case Cards.OtherReasons:
      case Cards.FeatureOrProblem:
        return 'support'
      case Cards.JobOffer:
        return isSubscriptionV4Enabled ? '90_days' : 'offer_analyzer'
      case Cards.Discount:
        return 'discount'
      case Cards.DreamJob:
        return isSubscriptionV4Enabled ? 'find_track_jobs' : 'job_search'
      default:
        return 'unknown'
    }
  }, [promotionTopic, isSubscriptionV4Enabled])

  const onPromotionButtonClick = useCallback(
    (e: MouseClickEvent) => {
      trackInternalEvent(InternalEvents.acceptPromo, {
        promo: promotionParameterForTracking,
        where: 'cancellation',
      })
      trackInternalEvent(InternalEvents.abort, { reason: promotionParameterForTracking })
      promotionButtonClickHandler(e)
    },
    [promotionParameterForTracking, promotionButtonClickHandler],
  )

  const showGettingStartedText =
    !isACIORegisteredUser && [Cards.JobOffer, Cards.DreamJob].includes(activeCard as Cards)

  return (
    <PromotionButtonContainer>
      <PromotionButton
        isLoading={isPromotionButtonLoading}
        onClick={onPromotionButtonClick}
        topic={promotionTopic}
      >
        {promotionButtonText}
      </PromotionButton>
      {!isSubscriptionV4Enabled && showGettingStartedText && (
        <GettingStartedText topic={promotionTopic}>
          {I18n.t('builder.subscription_cancellation.promotion_section.get_started')}
        </GettingStartedText>
      )}
    </PromotionButtonContainer>
  )
}

export default Button
