import { Component } from 'react'
import { UserBillingStatus } from 'builder/modules/user'
import DialogModal from 'builder/components/DialogModal'
import { i18n as I18n } from 'builder/context/I18nContext'
import PropTypes from 'prop-types'
import { formatDate } from 'builder/utils/formatDate'

class AccountDeletionModal extends Component {
  static propTypes = {
    account: PropTypes.object,
    deleteConfirmHandler: PropTypes.func,
    isModalOpen: PropTypes.bool,
    closeModalHandler: PropTypes.func,
  }

  static defaultProps = {
    account: {},
    deleteConfirmHandler: () => {},
    isModalOpen: false,
    closeModalHandler: () => {},
  }

  handleDeleteModalConfirm = () => {
    this.props.deleteConfirmHandler()
  }

  isPremiumUser = account => {
    return account?.billingStatus === UserBillingStatus.premium
  }

  isSubscriptionUser = account => {
    return account?.billingStatus === UserBillingStatus.subscription
  }

  isFree = account => {
    return account?.billingStatus === UserBillingStatus.free
  }

  handleDowngrade = subscriptionStatus => {
    return window.location.assign('/app/account/cancel-subscription')
  }

  handleCloseModal = () => {
    this.props.closeModalHandler()
  }

  formatMembershipEndDate = () => {
    if (this.props.account.billingInfo?.premiumEndsAt) {
      return formatDate(this.props.account.billingInfo?.premiumEndsAt, 'MMMM do, yyyy')
    }
  }

  render() {
    if (this.isSubscriptionUser(this.props.account)) {
      return (
        <DialogModal
          title={I18n.t('builder.account.delete_dialog.title')}
          text={I18n.t('builder.account.delete_dialog.text.for_subscription_user')}
          primaryButtonText={I18n.t('builder.account.delete_dialog.delete')}
          secondaryButtonText={I18n.t('builder.account.delete_dialog.downgrade')}
          isOpen={this.props.isModalOpen}
          primaryAction={this.handleDeleteModalConfirm}
          secondaryAction={this.handleDowngrade}
          closeAction={this.handleCloseModal}
        />
      )
    } else if (this.isPremiumUser(this.props.account)) {
      return (
        <DialogModal
          title={I18n.t('builder.account.delete_dialog.title')}
          text={I18n.t('builder.account.delete_dialog.text.for_premium_user', {
            membershipEndDate: this.formatMembershipEndDate(),
          })}
          primaryButtonText={I18n.t('builder.account.delete_dialog.delete')}
          secondaryButtonText={I18n.t('builder.account.delete_dialog.keep')}
          isOpen={this.props.isModalOpen}
          primaryAction={this.handleDeleteModalConfirm}
          secondaryAction={this.handleCloseModal}
          closeAction={this.handleCloseModal}
        />
      )
    }
    return (
      <DialogModal
        title={I18n.t('builder.account.delete_dialog.title')}
        text={I18n.t('builder.account.delete_dialog.text.for_free_user')}
        primaryButtonText={I18n.t('builder.account.delete_dialog.delete')}
        secondaryButtonText={I18n.t('builder.account.delete_dialog.keep_free_account')}
        isOpen={this.props.isModalOpen}
        primaryAction={this.handleDeleteModalConfirm}
        secondaryAction={this.handleCloseModal}
        closeAction={this.handleCloseModal}
      />
    )
  }
}

export default AccountDeletionModal
