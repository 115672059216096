import { useState, useCallback, useRef, useEffect } from 'react'

export function useHover<T extends HTMLElement>() {
  const [isHovering, setIsHovering] = useState(false)

  const handleMouseOver = useCallback(() => setIsHovering(true), [])
  const handleMouseOut = useCallback(() => setIsHovering(false), [])

  const hoverRef = useRef<T | null>(null)

  useEffect(() => {
    const node = hoverRef.current
    if (node) {
      node.addEventListener('mouseenter', handleMouseOver)
      node.addEventListener('mouseleave', handleMouseOut)

      return () => {
        node.removeEventListener('mouseenter', handleMouseOver)
        node.removeEventListener('mouseleave', handleMouseOut)
      }
    }
  }, [handleMouseOver, handleMouseOut])

  return { hoverRef, isHovering }
}
