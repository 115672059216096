import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { A4_FACTOR } from 'builder/modules/constants'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const DocumentPreviewContainer = styled.div`
  position: relative;
  overflow: hidden;
  background-color: ${Colors.White};

  &:before {
    content: '';
    display: block;
    padding-bottom: ${100 / A4_FACTOR}%;
  }
`

export const DocumentPreviewUnavailable = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
  max-width: '100%';
  text-align: center;
  position: absolute;
  left: 0;
  top: 50px;
  right: 0;
  bottom: 0;

  ${Media.Phone`
    ${Typography.Tiny};
    top: 25px;
  `}
`

export const DocumentPreviewPlaceholder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-image: url(${require('images/builder/panel/document/placeholder.svg')});
  background-repeat: no-repeat;
  background-size: contain;
`

export const DocumentPreviewImage = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
`
