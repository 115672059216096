import styled from 'styled-components'
import Typography from 'builder/styles/typography'
import Colors from 'builder/styles/colors'

export const Label = styled.label<{ disabled?: boolean }>`
  ${Typography.Caption};
  color: ${Colors.Neutral90};
  gap: 12px;
  display: flex;
  align-items: center;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  width: 100%;
`
