import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

export const ResultContainer = styled.div`
  padding-top: 40px;

  ${Media.Tablet`
    padding-top: 48px;
  `}

  ${Media.Phone`
    padding-top: 0;
  `}
`

export const ContentContainer = styled.div`
  gap: 128px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 56px;

  ${Media.Tablet`
    padding-top: 34px;
  `}

  ${Media.Phone`
    padding-top: 32px;
  `}
`

export const LeftContentWrapper = styled.div`
  max-width: 640px;

  ${Media.Tablet`
    max-width: 100%;
  `}
`

export const RightContentWrapper = styled.div`
  max-width: 352px;
`

export const RightRectangleContainer = styled.div`
  height: 1px;
  width: 100%;
  background: ${Colors.Neutral15};
  margin: 32px 0;
`
