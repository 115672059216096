import { AsyncAutosuggest, createSuggestionsApiFetcher } from 'builder/components/AsyncAutosuggest'
import Base from 'builder/components/Card/Base'
import DateRangePicker from 'builder/components/DateRangePicker'
import { TextField } from 'builder/components/TextField'
import { i18n } from 'builder/utils/i18n'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import { formatDateRange } from 'utils/formatDateRange'

import { CardContentWrapper, Field, Row } from 'builder/components/Card/styles'
import { withAutoFocus } from '../../../../../hocs/AutoFocusHoc'

class Education extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    item: PropTypes.object,
    autoFocus: PropTypes.bool,
    sectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    locale: PropTypes.string,
  }

  formatTitle(item) {
    const joiningString = ' ' + i18n.t('builder.resume_editor.labels.at') + ' '

    return (
      [item.degree, item.school].filter(x => x).join(joiningString) ||
      i18n.t('builder.resume_editor.not_specified')
    )
  }

  updateSimpleField = e => {
    const { onChange, sectionId, item } = this.props
    onChange(sectionId, item.id, { [e.target.name]: e.target.value }, true)
  }

  updateDescription = val => {
    const { onChange, sectionId, item } = this.props
    onChange(sectionId, item.id, { description: val }, true)
  }

  updateDateRange = val => {
    const { onChange, sectionId, item } = this.props
    onChange(sectionId, item.id, val, true)
  }

  render() {
    const { item, autoFocus, locale, ...otherProps } = this.props
    const title = this.formatTitle(item)
    const titleDates = formatDateRange(item)

    const educationSchoolProps = {
      item,
      locale,
      autoFocus,
      updateSimpleField: this.updateSimpleField,
    }
    const educationDateProps = {
      item,
      updateDateRange: this.updateDateRange,
    }
    const educationFacultyProps = {
      item,
      updateSimpleField: this.updateSimpleField,
    }
    const educationDepartmentProps = {
      item,
      updateSimpleField: this.updateSimpleField,
    }

    return (
      <Base title={title} subtitle={titleDates} item={item} {...otherProps}>
        <CardContentWrapper>
          <Row>
            <EducationDate {...educationDateProps} />

            <EducationSchool {...educationSchoolProps} />
          </Row>

          <Row>
            <EducationFaculty {...educationFacultyProps} />

            <EducationDepartment {...educationDepartmentProps} />
          </Row>
        </CardContentWrapper>
      </Base>
    )
  }
}

function EducationSchool(props) {
  const { locale, item, updateSimpleField, autoFocus } = props

  return (
    <>
      <Field>
        <AsyncAutosuggest
          name="school"
          fetchItems={createSuggestionsApiFetcher('university', { limit: 4, locale })}
          value={item.school || ''}
          onChange={updateSimpleField}
        >
          {inputProps => (
            <TextField
              {...inputProps}
              autoFocus={autoFocus}
              label={i18n.t('builder.resume_editor.educations_card.school')}
            />
          )}
        </AsyncAutosuggest>
      </Field>
    </>
  )
}
EducationSchool.propTypes = {
  locale: PropTypes.string,
  item: PropTypes.object,
  updateSimpleField: PropTypes.func,
  autoFocus: PropTypes.bool,
}

function EducationDate(props) {
  const { item, updateDateRange } = props

  return (
    <>
      <Field>
        <DateRangePicker
          value={item}
          onChange={updateDateRange}
          currentlyLabel={i18n.t('builder.date_range_picker.currently.study')}
        />
      </Field>
    </>
  )
}
EducationDate.propTypes = {
  item: PropTypes.object,
  updateDateRange: PropTypes.func,
}

function EducationFaculty(props) {
  const { item, updateSimpleField } = props

  return (
    <>
      <Field>
        <TextField
          label={i18n.t('builder.resume_editor.educations_card.faculty')}
          name="faculty"
          onChange={updateSimpleField}
          value={item.faculty || ''}
        />
      </Field>
    </>
  )
}
EducationFaculty.propTypes = {
  item: PropTypes.object,
  updateSimpleField: PropTypes.func,
}

function EducationDepartment(props) {
  const { item, updateSimpleField } = props

  return (
    <>
      <Field>
        <TextField
          label={i18n.t('builder.resume_editor.educations_card.department')}
          name="department"
          onChange={updateSimpleField}
          value={item.department || ''}
        />
      </Field>
    </>
  )
}
EducationDepartment.propTypes = {
  item: PropTypes.object,
  updateSimpleField: PropTypes.func,
}

export const EducationCard = withAutoFocus(Education)
