import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
`
export const IdContainer = styled.div`
  ${Typography.Caption};
  width: 32px;
  height: 26px;
  border-radius: 667px;
  color: ${Colors.Neutral40};
  background-color: ${Colors.Neutral10};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Title = styled.div`
  ${Typography.S};
  ${FontWeights.Medium};
`

export const Description = styled.div`
  color: ${Colors.Neutral50};
`
