export const Label = {
  label: 'execute_a_job_search',
}

export const filterOptions = [
  {
    id: 1,
    status: 'open',
    isActive: true,
    isDisabled: false,
  },
  {
    id: 2,
    status: 'completed',
    isActive: false,
    isDisabled: false,
  },
  {
    id: 3,
    status: 'all',
    isActive: false,
    isDisabled: false,
  },
]

export const allContentParams = {
  type: 'all',
  page: [1, 2, 3, 4, 5],
}
