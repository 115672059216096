import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import { Logo as LogoComponent } from 'builder/components/Logo'
import { LOGO_SIGN_WIDTH } from './constants'

export const Container = styled.div<{ isVisible: boolean }>`
  position: fixed;
  z-index: 9999999999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${Colors.White};
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: opacity 0.4s ease;
`

export const Video = styled.video<{ isVisible: boolean }>`
  width: 640px;
  height: 320px;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: opacity 0.1s cubic-bezier(1, 0, 1, 0);

  ${Media.Phone`
    max-width: 640px;
    width: 100%;
    height: auto;
  `}
`

export const Logo = styled(LogoComponent)<{ isVisible: boolean }>`
  position: absolute;
  background-size: auto 32px;
  width: ${LOGO_SIGN_WIDTH}px;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: opacity 0.1s cubic-bezier(0, 1, 0, 1), transform 0.4s ease;
  pointer-events: none;
`
