import { useRef, useCallback, useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { useClickOutside } from 'builder/hooks/useClickOutside'
import { useConfig } from 'builder/hooks/useConfig'
import { useEscapeHandler } from 'builder/hooks/useEscapeHandler'
import { useUser } from 'builder/hooks/useUser'
import { useI18n } from 'builder/hooks/useI18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { Icon24 } from 'builder/components/Icon'
import { selectors as userSelectors } from 'builder/modules/user'
import { authService } from 'builder/services/AuthService'
import { isIFrame } from 'builder/utils/isIFrame'
import * as Styles from './styles'

interface AvatarNavigationProps {
  hasFeatures?: boolean
  hasUserName?: boolean
  dashboardPath?: string
}

export const AvatarNavigation = ({
  hasFeatures = false,
  hasUserName = false,
  dashboardPath = '/',
  ...other
}: AvatarNavigationProps) => {
  const config = useConfig()
  const user = useUser()
  const { i18n } = useI18n()
  const containerRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const photoUrl = useTypedSelector(userSelectors.photoUrl)

  const handleClose = useCallback(() => setIsOpen(false), [setIsOpen])
  const handleToggle = useCallback(() => setIsOpen(!isOpen), [setIsOpen, isOpen])
  const isRioDomain = config?.features.international
  if (isRioDomain) dashboardPath = '/'

  useClickOutside(containerRef, handleClose)
  useEscapeHandler(handleClose)
  useEffect(() => {
    // track open event
    if (isOpen) trackInternalEvent('open_account_menu')
  }, [isOpen])

  if (!config) return null

  const hideMenuItems = config.features.isWidget && isIFrame()

  return (
    <Styles.Container ref={containerRef} hasFeatures={hasFeatures} {...other}>
      {user && (
        <Styles.AvatarContainer isMenuOpen={false}>
          <Styles.Avatar onClick={handleToggle}>
            {hasUserName && <Styles.AvatarName isOpen={isOpen}>{user.fullName}</Styles.AvatarName>}
            <Styles.AvatarDropdownTrigger isOpen={isOpen}>
              <Styles.AvatarImage src={photoUrl} />
            </Styles.AvatarDropdownTrigger>
          </Styles.Avatar>

          {isOpen && (
            <Styles.MenuContainer>
              <Styles.MenuLinks onClick={handleClose}>
                {hasFeatures && (
                  <Fragment>
                    <Link
                      to={{
                        pathname: dashboardPath,
                      }}
                    >
                      <Styles.MenuItem>
                        <Styles.MenuIcon>
                          <Icon24.Home />
                        </Styles.MenuIcon>
                        <Styles.MenuItemText>
                          <Styles.MenuItemTitle>
                            {i18n.t('builder.navbar.dashboard')}
                          </Styles.MenuItemTitle>
                          <Styles.MenuItemDescription>
                            {i18n.t('builder.navbar.dashboard_description')}
                          </Styles.MenuItemDescription>
                        </Styles.MenuItemText>
                      </Styles.MenuItem>
                    </Link>
                    <Styles.MenuDivider />
                  </Fragment>
                )}
                {!hideMenuItems && (
                  <>
                    <Link to="/account">
                      <Styles.MenuItem>
                        <Styles.MenuItemText>
                          <Styles.MenuItemTitle>
                            {i18n.t('builder.navbar.account_settings')}
                          </Styles.MenuItemTitle>
                        </Styles.MenuItemText>
                      </Styles.MenuItem>
                    </Link>
                    <a
                      href={config.links.updates || 'https://updates.resume.io/'}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <Styles.MenuItem>
                        <Styles.MenuItemText>
                          <Styles.MenuItemTitle>
                            {i18n.t('builder.navbar.updates')}
                          </Styles.MenuItemTitle>
                        </Styles.MenuItemText>
                      </Styles.MenuItem>
                    </a>

                    <a href={config.links.help} target="_blank" rel="noreferrer noopener">
                      <Styles.MenuItem>
                        <Styles.MenuItemText>
                          <Styles.MenuItemTitle>
                            {i18n.t('builder.navbar.faq')}
                          </Styles.MenuItemTitle>
                        </Styles.MenuItemText>
                      </Styles.MenuItem>
                    </a>

                    <Styles.MenuItem onClick={() => authService.logout()}>
                      <Styles.MenuItemText>
                        <Styles.MenuItemTitle>
                          {i18n.t('builder.navbar.log_out')}
                        </Styles.MenuItemTitle>
                      </Styles.MenuItemText>
                    </Styles.MenuItem>
                  </>
                )}
              </Styles.MenuLinks>
            </Styles.MenuContainer>
          )}
        </Styles.AvatarContainer>
      )}
    </Styles.Container>
  )
}
