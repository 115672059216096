import { useDispatch } from 'react-redux'
import { UpdateSimpleFieldPayload, actions } from 'builder/modules/resumeEditor'

const useUpdateSimpleField = () => {
  const dispatch = useDispatch()

  const updateSimpleField = ({ debounce = true, ...rest }: UpdateSimpleFieldPayload) => {
    dispatch(actions.updateSimpleField({ ...rest, debounce }))
  }

  return { updateSimpleField }
}

export { useUpdateSimpleField }
