import { ToneNames } from 'builder/styles/colors'
import {
  CardContainer,
  DetailsContainer,
  IconContainer,
  TitleContainer,
  Title,
  LocationTime,
} from './styles'
import Timer from './Timer'
import { getLocationTime } from './utils'
import { EventBannerImage } from './EventBanner'

interface IEventCardProps {
  colorTone: ToneNames
  imgUrl: string
  title: string
  location: string
  dateTime: Date
  id: string
  isStarted: boolean
}

export const EventCard = ({
  colorTone,
  dateTime,
  imgUrl,
  location,
  title,
  isStarted,
}: IEventCardProps) => {
  return (
    <CardContainer color={colorTone}>
      <DetailsContainer>
        <IconContainer>
          <EventBannerImage src={imgUrl} />
        </IconContainer>
        <TitleContainer>
          <Title color={colorTone}>{title}</Title>
          <LocationTime color={colorTone}>{getLocationTime(location, dateTime)}</LocationTime>
        </TitleContainer>
      </DetailsContainer>
      <Timer dateTime={dateTime} colorTone={colorTone} isStarted={isStarted} />
    </CardContainer>
  )
}
