import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { TextAreaField, TextField } from 'builder/components/TextField'
import Button from 'builder/components/Button'
import { InterviewLocationParams, JobSuggestionType } from 'builder/modules/interview/types'
import { useUser } from 'builder/hooks/useUser'
import { JobTitleAsyncAutosuggest } from '../JobTitleAsyncAutosuggest/JobTitleAsyncAutosuggest'
import { PremiumDialog } from '../PremiumDialog/PremiumDialog'
import { ShowHints } from '../ShowHints/ShowHints'

import { useNewInterview } from '../../hooks/useNewInterview'
import { ProgressIndicatorDot } from './components/ProgressIndicatorDot/ProgressIndicatorDot'
import { TextFieldWithSpinner } from './components/TextFieldWithSpinner/TextFieldWithSpinner'
import {
  BackButton,
  Body,
  ButtonContainer,
  Center,
  Container,
  CustomTextAreaField,
  CustomTextField,
  DesktopContainer,
  DummyDiv,
  LeftArrowIcon,
  MobileContainer,
  Title,
  Top,
} from './styles'

export type StatusType = 'idle' | 'parsing' | 'success' | 'error'

type Fields = 'job_title' | 'company' | 'job_description'

type FormFields = Record<Fields, { id?: number; value: string; error: boolean; disabled: boolean }>
export const TRANSLATION_KEY = 'builder.interview_prep_view.custom_interview'
const initialFormFields = {
  job_title: {
    value: '',
    error: false,
    disabled: false,
  },
  company: {
    value: '',
    error: false,
    disabled: false,
  },
  job_description: {
    value: '',
    error: false,
    disabled: false,
  },
}

export const CustomInterviewView = () => {
  const { i18n } = useI18n()
  const { isPhone } = useMediaQueries()
  const navigate = useNavigate()
  const user = useUser()
  const isFreePlan = !user?.hasPremiumFeatures
  const [showPremiumModal, setShowPremiumModal] = useState(false)
  const { mutateAsync: postInterview, status: interviewStatus } = useNewInterview()
  const [formFields, setFormFields] = useState<FormFields>(initialFormFields)
  const location = useLocation()
  const interviewLocationParams = location.state as InterviewLocationParams | undefined
  const isLoading = interviewStatus === 'loading'
  useEffect(() => {
    if (!interviewLocationParams) {
      return
    }
    setFormFields({
      job_title: {
        value: interviewLocationParams.jobTitle?.text || '',
        error: false,
        id: interviewLocationParams.jobTitle?.id,
        disabled: !!interviewLocationParams.jobTitle,
      },
      company: {
        value: interviewLocationParams.company || '',
        error: false,
        disabled: !!interviewLocationParams.company,
      },
      job_description: {
        value: interviewLocationParams.description || '',
        error: false,
        disabled: false,
      },
    })
  }, [interviewLocationParams])

  const onSuggestionSelected = (suggestion: JobSuggestionType) => {
    setFormFields(prevFormFields => ({
      ...prevFormFields,
      job_title: { ...prevFormFields.job_title, value: suggestion.text, id: suggestion.id },
    }))
  }

  const handleChangeInput =
    (field: Fields) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFormFields(prevFormFields => ({
        ...prevFormFields,
        [field]: { value: e.target.value, error: false },
      }))
    }

  const onCreateInterviewClick = async () => {
    if (formFields.job_title.value.length === 0 || !formFields.job_title.id) {
      return setFormFields(prevFormFields => ({
        ...prevFormFields,
        job_title: { ...prevFormFields.job_title, error: true },
      }))
    }
    if (isFreePlan) {
      return setShowPremiumModal(true)
    }

    if (formFields.job_title.id) {
      trackInternalEvent('paste_title', {
        label: 'interview_prep',
      })
    }
    if (formFields.company.value) {
      trackInternalEvent('paste_company', {
        label: 'interview_prep',
        term: formFields.company.value,
      })
    }

    if (formFields.job_description.value) {
      trackInternalEvent('paste_description', {
        label: 'interview_prep',
      })
    }

    trackInternalEvent('start_interview', {
      label: 'interview_prep',
      parameter: 'custom',
    })

    await postInterview({
      jobTitleCode: formFields.job_title.id,
      company: formFields.company.value,
      jobDescription: formFields.job_description.value,
    })
    navigate('/interview-preparation/interview-player')
  }

  return (
    <Container>
      <Top>
        <BackButton onClick={() => navigate('/interview-preparation/dashboard')}>
          <LeftArrowIcon />
          {!isPhone && i18n.t(`${TRANSLATION_KEY}.back`)}
        </BackButton>
        <Title>{i18n.t(`${TRANSLATION_KEY}.title`)}</Title>
        <DummyDiv />
      </Top>
      <Center>
        <Body>
          <CustomTextField>
            <JobTitleAsyncAutosuggest
              jobTitle={formFields.job_title.value}
              onChange={handleChangeInput('job_title')}
              onChangeSuggestion={onSuggestionSelected}
              hasError={formFields.job_title.error}
              disabled={formFields.job_title.disabled}
              trackingParameter="custom"
            />
          </CustomTextField>
          <TextFieldWithSpinner
            container={CustomTextField}
            type={TextField}
            label={i18n.t(`${TRANSLATION_KEY}.company_label`)}
            placeholder={i18n.t(`${TRANSLATION_KEY}.company_placeholder`)}
            {...formFields.company}
            onChange={handleChangeInput('company')}
          />
          <TextFieldWithSpinner
            container={CustomTextAreaField}
            type={TextAreaField}
            label={i18n.t(`${TRANSLATION_KEY}.job_description_label`)}
            placeholder={i18n.t(`${TRANSLATION_KEY}.job_description_placeholder`)}
            {...formFields.job_description}
            onChange={handleChangeInput('job_description')}
          />
          {showPremiumModal && (
            <PremiumDialog trackingParameter="custom" onClose={() => setShowPremiumModal(false)} />
          )}

          <ButtonContainer>
            <Button isLoading={isLoading} onClick={isLoading ? undefined : onCreateInterviewClick}>
              {i18n.t(`${TRANSLATION_KEY}.create_interview`)}
            </Button>
            <MobileContainer>
              <ProgressIndicatorDot />
            </MobileContainer>
          </ButtonContainer>
          {isLoading && (
            <ShowHints
              hints={[i18n.t(`${TRANSLATION_KEY}.hint_0`), i18n.t(`${TRANSLATION_KEY}.hint_1`)]}
            />
          )}
        </Body>
        <DesktopContainer>
          <ProgressIndicatorDot />
        </DesktopContainer>
      </Center>
    </Container>
  )
}
