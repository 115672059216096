import { useEffect, useState } from 'react'
import { FetchStatuses } from 'builder/modules/constants'

export const useImageLoader = (url: string | null) => {
  const [status, setStatus] = useState(FetchStatuses.notAsked)

  useEffect(() => {
    let mounted = true

    if (url) {
      setStatus(FetchStatuses.loading)

      const image = new Image()
      image.onload = () => mounted && setStatus(FetchStatuses.loaded)
      image.onerror = () => mounted && setStatus(FetchStatuses.failed)
      image.src = url
    }

    return () => {
      mounted = false
    }
  }, [url])

  return status
}
