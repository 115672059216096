import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { actions as uiActions, selectors as uiSelectors } from 'builder/modules/ui'
import ModalOverlay from 'builder/components/ModalOverlay'
import Button, { ButtonSize } from 'builder/components/Button'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { actions as panelActions, selectors as panelSelectors } from 'builder/modules/panel'
import { actions as editorActions } from 'builder/modules/resumeEditor'
import { DocumentTypes } from 'builder/modules/constants'
import {
  Text,
  Title,
  Close,
  Content,
  CloseIcon,
  Container,
  Illustration,
  ButtonContainer,
} from './styles'
import Visual from './assets/visual.svg'

const CreateYourResumeModal = () => {
  const dispatch = useDispatch()
  const { isPhone } = useMediaQueries()

  const isModalOpen = useTypedSelector(uiSelectors.isCreateResumeOpen)
  const isTailorYourResume = useTypedSelector(panelSelectors.isTailorResume)

  const handleClick = () => {
    dispatch(panelActions.createDocument({ type: DocumentTypes.resume }))
    dispatch(uiActions.closeCreateResumeModal())

    if (isTailorYourResume) {
      dispatch(editorActions.setOpenOptimizerModal(true))
    }
  }

  const handleClose = () => {
    dispatch(uiActions.closeCreateResumeModal())
  }

  return (
    <>
      {isModalOpen && (
        <ModalOverlay onClick={handleClick} overlayFadeDuration={150} contentSlideDuration={150}>
          <Container>
            <Close onClick={handleClose}>
              <CloseIcon />
            </Close>
            <Illustration src={Visual} />
            <Content>
              <Title>First of all, you need to create a resume</Title>
              <Text>
                Craft an impressive resume by selecting one of our highly effective resume
                templates, significantly enhancing your chances of securing a job.
              </Text>
              <ButtonContainer>
                <Button theme="ghost" size="small" onClick={handleClose}>
                  Not Now
                </Button>
                <Button
                  onClick={handleClick}
                  size={isPhone ? ButtonSize.small : ButtonSize.default}
                >
                  Create Resume
                </Button>
              </ButtonContainer>
            </Content>
          </Container>
        </ModalOverlay>
      )}
    </>
  )
}

export default CreateYourResumeModal
