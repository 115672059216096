import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { actions } from 'builder/modules/user'
import AccountSettings from 'builder/components/AccountSettings'
import { selectors as appSelectors } from 'builder/modules/init'

class AccountView extends Component {
  static propTypes = {
    user: PropTypes.object,
    error: PropTypes.string,
    config: PropTypes.object,
    isUpdating: PropTypes.bool,
    socialUpdating: PropTypes.object,
    updateUser: PropTypes.func,
    deleteUser: PropTypes.func,
    toggleEmailNotification: PropTypes.func.isRequired,
    disconnectSocial: PropTypes.func,
  }

  componentDidMount() {
    trackInternalEvent('visit_account_settings')
  }

  render() {
    const {
      user,
      error,
      config,
      isUpdating,
      updateUser,
      deleteUser,
      toggleEmailNotification,
      disconnectSocial,
      socialUpdating,
    } = this.props

    return (
      <div>
        {user && (
          <AccountSettings
            account={user}
            error={error}
            onUpdate={updateUser}
            onDelete={deleteUser}
            isUpdating={isUpdating}
            socialUpdating={socialUpdating}
            onEmailNotificationToggle={toggleEmailNotification}
            onDisconnectSocial={disconnectSocial}
            config={config}
          />
        )}
      </div>
    )
  }
}

// ---
// Connect to data
// ---
function mapStateToProps(state) {
  return {
    user: state.user.data,
    error: state.user.error,
    config: appSelectors.config(state),
    isUpdating: state.user.isUpdating,
    socialUpdating: state.user.socialUpdating,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateUser: data => dispatch(actions.updateUserRequest(data)),
    deleteUser: () => dispatch(actions.deleteUserRequest()),
    toggleEmailNotification: payload => dispatch(actions.toggleEmailNotification(payload)),
    disconnectSocial: provider => dispatch(actions.disconnectSocialRequest(provider)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountView)
