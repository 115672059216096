import discover from './assets/discover.svg'
import activate from './assets/activate.svg'
import relax from './assets/relax.svg'

export const PROMOTO_BANNER_STEPS = [
  {
    imgUrl: discover,
    title: 'builder.promote_banners.job_tracking.discover.title',
    subtitle: 'builder.promote_banners.job_tracking.discover.sub_title',
    caption: 'builder.promote_banners.job_tracking.discover.caption',
  },
  {
    imgUrl: activate,
    title: 'builder.promote_banners.job_tracking.active.title',
    subtitle: '',
    caption: 'builder.promote_banners.job_tracking.active.caption',
  },
  {
    imgUrl: relax,
    title: 'builder.promote_banners.job_tracking.relax.title',
    subtitle: 'builder.promote_banners.job_tracking.relax.subtitle',
    caption: 'builder.promote_banners.job_tracking.relax.caption',
  },
]
