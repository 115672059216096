import styled from 'styled-components'
import YouTube from 'react-youtube'

import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Icon24, { Icon20 } from '../Icon'

export const VideoPlayerContainer = styled.div`
  width: 736px;
  height: 415px;
  border-radius: 24px;
  border: 1px solid ${Colors.Neutral15};
  position: relative;
  overflow: hidden;
  background-color: ${Colors.White};

  &.fullscreen {
    border-radius: unset;
    border: unset;
  }

  ${Media.Phone`    
    max-width: 359px;
    height: 203px;
  `}
`

export const Video = styled(YouTube)`
  & > iframe {
    position: absolute;
    width: 100%;
    height: 100%;

    &.fullscreen {
      position: absolute;
      bottom: 10px;
      width: 100vw;
      height: 100vh;
      background: rgba(0, 0, 0, 0.5);
      object-fit: contain;
    }
  }
`

export const Controls = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: inherit;
  height: inherit;
  max-width: inherit;
  position: absolute;
  top: 0;
`

export const VideoButton = styled.button`
  display: flex;
  height: 32px;
  width: 32px;
  background: none;
  border: none;
  color: inherit;
  font-size: 1.5em;
  cursor: pointer;
  border-radius: 50%;
  align-items: center;
  background-color: ${Colors.White};

  box-shadow: 0px 0px 1px 0px rgba(15, 56, 113, 0.56), 0px 6px 8px -2px rgba(15, 56, 113, 0.02),
    0px 16px 64px -8px rgba(15, 56, 113, 0.18);

  &:hover {
    background-color: ${Colors.Blue10};

    svg {
      color: ${Colors.Blue50};
    }
  }
`

export const PlayButton = styled(VideoButton)`
  height: 56px;
  width: 56px;
  position: absolute;
  border-radius: 40px;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(15px);
  padding: 16px;

  &:hover {
    background-color: unset;
    background: rgba(0, 0, 0, 0.3);

    svg {
      color: ${Colors.White};
    }
  }
`

export const SoundButton = styled(VideoButton)`
  position: absolute;
  bottom: 32px;
  right: 72px;

  ${Media.Phone`    
    bottom: 20px;
    right: 58px;
  `}

  &.fullscreen {
    bottom: 32px;
    right: 72px;

    ${Media.Phone`    
      bottom: 20px;
      right: 58px;
    `}
  }
`

export const FullscreenButton = styled(VideoButton)`
  position: absolute;
  bottom: 32px;
  right: 32px;

  ${Media.Phone`    
    bottom: 20px;
    right: 20px;
  `}

  &.fullscreen {
    bottom: 32px;
    right: 32px;

    ${Media.Phone`    
      bottom: 20px;
      right: 20px;
    `}
  }
`

export const ExitFullscreenButton = styled(VideoButton)`
  position: absolute;
  top: 32px;
  right: 32px;

  ${Media.Phone`    
    top: 20px;
    right: 20px;
  `}
`

export const PlayIcon = styled(Icon20.Play)`
  color: ${Colors.White};
  width: 24px;
  height: 24px;
`

export const PauseIcon = styled(Icon20.Pause)`
  color: ${Colors.White};
  width: 24px;
  height: 24px;
`

export const SoundIcon = styled(Icon24.Sound)`
  color: ${Colors.Neutral50};
  padding: 1px;
`

export const MuteIcon = styled(Icon20.Mute)`
  color: ${Colors.Neutral50};
  margin-top: 4px;
  margin-left: 2px;
`

export const FullScreenIcon = styled(Icon24.FullSize)`
  height: 20px;
  color: ${Colors.Neutral50};
`

export const CloseFullScreenIcon = styled(Icon20.Expand)`
  height: 20px;
  color: ${Colors.Neutral50};
`

export const CloseIcon = styled(Icon24.Close)`
  color: ${Colors.Neutral50};
`
