import styled from 'styled-components'
import Media from 'builder/styles/media'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 0 36px 0;

  ${Media.IphoneSE`
    width: fit-content;
  `}
`
