export const calculateDaysDifference = (endDate?: string | null): number => {
  if (!endDate) return 0

  const premiumEndsAt = new Date(endDate).getTime()
  const presentDate = new Date().getTime()

  const timeDifferenceInMilliseconds = premiumEndsAt - presentDate
  const daysDifference = Math.floor(timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24))

  return daysDifference
}
