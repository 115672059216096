import * as Styled from './styles'

type Props = {
  textParts: string[] | undefined
}

export const TextSection = ({ textParts }: Props) => {
  return (
    <>
      {!!textParts?.length && (
        <Styled.Container>
          {textParts.map((item, index) => (
            <Styled.Paragraph key={index}>{item}</Styled.Paragraph>
          ))}
        </Styled.Container>
      )}
    </>
  )
}
