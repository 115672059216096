import { useLayoutEffect } from 'react'
import { useConstant } from './useConstant'

export const usePortalNode = () => {
  const node = useConstant(() => document.createElement('div'))

  useLayoutEffect(() => {
    document.body.appendChild(node)
    return () => {
      document.body.removeChild(node)
    }
  }, [node])

  return node
}
