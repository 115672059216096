import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  width: 278px;
  display: flex;
  padding: 8px 8px 16px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: var(--Spacing-S, 16px);
  background: ${Colors.White};
  box-shadow: 0px 0px 1px 0px rgba(15, 56, 113, 0.32), 0px 6px 8px -2px rgba(15, 56, 113, 0.02),
    0px 16px 64px -8px rgba(15, 56, 113, 0.18);
`

export const ImageContainer = styled.div`
  height: 140px;
`

export const Image = styled.img`
  border-radius: 8px;
  height: 140px;
  width: 262px;
  object-fit: cover;
  align-self: stretch;
`

export const TextContainer = styled.div`
  display: flex;
  padding: 0px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
`

export const TextHeader = styled.p`
  ${Typography.Body};
  ${FontWeights.Medium};

  color: ${Colors.Neutral90};
`
export const TextSubheader = styled.p`
  ${Typography.Caption};
  ${FontWeights.Regular};

  color: ${Colors.Neutral50};
`
