import { useI18n } from 'builder/hooks/useI18n'
import Icon24 from 'builder/components/Icon'

import { ButtonTheme } from 'builder/components/Button'
import { CustomButton, SubHead } from './styles'
const TRANSLATION = 'builder.job_search.alert_modal'

interface Props {
  onClick: () => void
}
export const CreateCustomAlertButton = ({ onClick }: Props) => {
  const { i18n } = useI18n()
  return (
    <CustomButton theme={ButtonTheme.ghost} onClick={onClick}>
      <SubHead>
        <Icon24.Add /> {i18n.t(`${TRANSLATION}.create_new_alert`)}
      </SubHead>
    </CustomButton>
  )
}
