import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Wrapper = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`

export const Heading1 = styled.h1`
  ${Typography.L}
  ${FontWeights.DemiBold};
`

export const Paragraph = styled.p`
  color: ${Colors.Neutral50};
`
