import useWizardSteps from '../../hooks/useWizardSteps'
import { Progress, ProgressInner } from './styles'

export const WizardProgress = () => {
  const { steps, currentStepIndex } = useWizardSteps()
  const progress = (currentStepIndex + 1) / (steps.length + 1)

  return (
    <Progress>
      <ProgressInner style={{ transform: `scaleX(${progress})` }} />
    </Progress>
  )
}
