import { memo } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Mousewheel } from 'swiper'
import { Course as CourseType } from 'builder/modules/careerPath2'
import Icon from 'builder/components/Icon'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { CourseCard } from '../CourseCard'
import {
  Container,
  SwiperContainer,
  NavigationButtons,
  ServicesRightButton,
  ServicesLeftButton,
} from './styles'
import {
  handleOnInit,
  handleOnReachEnd,
  handleOnSlideChange,
  handleOnReachBeginning,
} from './utils'

interface CoursesCardProps {
  jobTitle: string
  eventLabel: string
  courses: CourseType[]
  isSwiperEnabled: boolean
}

SwiperCore.use([Navigation, Mousewheel])

const SwiperComponent = ({ courses, jobTitle, eventLabel, isSwiperEnabled }: CoursesCardProps) => {
  const { isPhone } = useMediaQueries()

  return (
    <Container data-testid="course-swiper">
      {courses.length && (
        <>
          <NavigationButtons>
            <ServicesRightButton data-testid="leftButton" className="course-prev-button">
              <Icon.Chevron />
            </ServicesRightButton>
            <ServicesLeftButton data-testid="rightButton" className="course-next-button">
              <Icon.Chevron />
            </ServicesLeftButton>
          </NavigationButtons>

          <SwiperContainer>
            <Swiper
              grabCursor
              mousewheel
              slideToClickedSlide
              slidesPerView="auto"
              initialSlide={0}
              spaceBetween={isPhone ? 12 : 24}
              navigation={{
                prevEl: '.course-prev-button',
                nextEl: '.course-next-button',
              }}
              onInit={handleOnInit}
              onReachEnd={() => handleOnReachEnd}
              onReachBeginning={handleOnReachBeginning}
              onSlideChange={swiper => handleOnSlideChange(swiper)}
            >
              {courses?.map(course => (
                <SwiperSlide key={`template-${course?.title}`} style={{ width: '100%' }}>
                  <CourseCard
                    key={course?.title}
                    isSwiperEnabled={isSwiperEnabled}
                    {...{ course, jobTitle, eventLabel }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </SwiperContainer>
        </>
      )}
    </Container>
  )
}

export default memo(SwiperComponent)
