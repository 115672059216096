import { i18n as I18n } from 'builder/utils/i18n'
import WILL_BE_BACK_DESCRIPTION from './assets/images/desc_will_be_back.svg'
import { FLOW_IDS } from './constants'

import {
  PromotionContent,
  PromotionTitle,
  PromotionButton,
  PromotionButtonContainer,
  PromotionImage,
  PromotionContainerRow,
} from './styles'

type Props = {
  handleClose: () => void
}

const WillBeBack: React.FC<Props> = ({ handleClose }: Props) => {
  return (
    <PromotionContainerRow activeCardId={FLOW_IDS.willBeBack}>
      <PromotionContent>
        <PromotionTitle>
          {I18n.t('builder.in_app_abandonment.will_be_back.subtitle')}
        </PromotionTitle>
        <PromotionButtonContainer>
          <PromotionButton onClick={handleClose}>
            {I18n.t('builder.in_app_abandonment.will_be_back.button_text')}
          </PromotionButton>
        </PromotionButtonContainer>
      </PromotionContent>
      <PromotionImage>
        <img src={WILL_BE_BACK_DESCRIPTION} />
      </PromotionImage>
    </PromotionContainerRow>
  )
}

export default WillBeBack
