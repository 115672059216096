import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { Typography } from 'builder/styles/typography'
import BaseButton from 'builder/components/Button'
import Media from 'builder/styles/media'
import Badges from 'builder/components/Badges'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${Media.Phone`
    padding-top: 20px;
  `}
`

export const TitleContainer = styled.div`
  width: 100%;

  ${Media.Phone`
    padding: 0 20px 0;
  `}
`

export const Title = styled.div`
  ${Typography.XL};
  color: ${Colors.Blue50};
  font-weight: 700;
  margin-bottom: 8px;
  text-align: center;
  user-select: none;

  ${Media.Tablet`
    margin-bottom: 24px;
  `}

  ${Media.Phone`
    ${Typography.S};
    margin-bottom: 12px;
  `}
`

export const Subtitle = styled.div`
  text-align: center;
  margin-bottom: 18px;
  user-select: none;

  ${Media.Tablet`
    display: none;
  `}
`

export const TemplatePreviewContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`

export const TemplatePreview = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: ${Colors.White};
  transition: transform 0.3s ease, box-shadow 0.4s ease;
  z-index: 1;
`

export const TemplatePreviewWhite = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 8px;
  background-color: ${Colors.White};
  border: 0;
  z-index: 1;
`

export const ClickableArea = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(1.05);
  left: 0;
  top: 0;
  display: none;
  z-index: 2;
`

export const Button = styled(BaseButton)`
  display: none;
  z-index: 3;
`

export const TemplatesContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  user-select: none;
`

export const TemplateNameContainer = styled.div`
  position: absolute;
  width: 100%;
  top: -52px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: default;
  transition: transform 0.3s;

  ${Media.Phone`
    top: -38px;
  `}
`
export const TemplateName = styled.div`
  display: flex;
  align-items: center;
`

export const TemplateNameText = styled.div`
  font-weight: 600;

  ${Media.Phone`
    ${Typography.Caption};
  `}
`

export const TemplateBadge = styled.div`
  width: 20px;
  height: 20px;
  margin-left: 4px;
  background-image: url(${require(`images/builder/sign-up/crown.svg`)});
  background-size: contain;
  z-index: 999;
  cursor: pointer;

  ${Media.Phone`
    display: none;
  `}
`

export const TemplateUsageNumber = styled.div`
  ${Typography.Tiny};
  color: ${Colors.Neutral50};
`

export const PlanBadges = styled(Badges).attrs({ topic: 'plan' })`
  position: absolute;
  bottom: 16px;
  left: 16px;
  transition: transform 0.3s;
  z-index: 1;
`

export const FormatBadges = styled(Badges)`
  position: absolute;
  bottom: 16px;
  right: 16px;
  transition: transform 0.3s;
  z-index: 1;
`

export const TemplatesContainer = styled.div`
  position: relative;

  *:focus {
    outline: 0;
    outline: none;
  }

  .slick-current ${FormatBadges} {
    transform: translate(
      ${props => props.badgesHorizontalMargin}px,
      ${props => props.badgesVerticalMargin}px
    );
  }

  .slick-current ${PlanBadges} {
    transform: translate(
      ${props => -props.badgesHorizontalMargin}px,
      ${props => props.badgesVerticalMargin}px
    );
  }

  .slick-current ${TemplateNameContainer} {
    transform: translateY(-12px);
  }

  .slick-current ${TemplatePreview} {
    transform: scale(1.04);
    box-shadow: 0px 20px 40px -8px rgba(186, 200, 227, 0.2),
      0px 12px 24px -8px rgba(186, 200, 227, 0.3);
  }

  .slick-current ${TemplatePreviewWhite} {
    transform: scale(1.04);
    box-shadow: 0px 20px 40px -8px rgba(186, 200, 227, 0.2),
      0px 12px 24px -8px rgba(186, 200, 227, 0.3);
  }

  .slick-current {
    & ${ClickableArea} {
      display: block;
    }

    ${Media.Phone`
      transform: scale(1);
    `};

    ${Button} {
      display: block;
    }
  }
`

export const Template = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => `${height}px`};
  border-radius: 4px;
  flex-shrink: 0;
  cursor: pointer;
  margin: 70px 16px 40px;

  ${Media.Tablet`
    margin: 60px 12px 40px;
  `};

  ${Media.Phone`
    margin: 60px 8px 40px;
  `};
`

const TemplatesButton = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: ${Colors.Blue50};
  color: ${Colors.White};
  border-radius: 50%;
  z-index: 2;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${Colors.Blue70};
  }

  ${Media.Phone`
    display: none;
  `}
`

export const TemplatesLeftButton = styled(TemplatesButton)`
  left: 60px;
  top: calc(50% - 24px);
  transform: rotate(180deg);

  ${Media.Tablet`
    left: 24px;
  `}
`

export const TemplatesRightButton = styled(TemplatesButton)`
  right: 60px;
  top: calc(50% - 24px);

  ${Media.Tablet`
    right: 24px;
  `}
`

export const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`

export const ProgressBarWrapper = styled.div`
  width: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;

  ${Media.Tablet`
    display: flex;
  `}
`

export const ProgressBarContainer = styled.div`
  width: 100%;
  max-width: 184px;
`
