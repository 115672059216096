import Icon from 'builder/components/Icon'
import { IconWrapper } from './styles'

interface ListItemIconProps {
  icon: React.SVGAttributes<typeof Icon>
}

export const ListItemIcon = ({ icon }: ListItemIconProps) => {
  return <IconWrapper data-testid="list-item-icon">{icon}</IconWrapper>
}
