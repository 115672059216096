import Checkbox from 'builder/components/Checkbox'
import { Card, Details, Header, SubHeader } from './styles'

const BenefitsTermsCard = (props: {
  header: string
  description: string
  checked: boolean
  onChange: React.ChangeEventHandler<HTMLInputElement>
  icon: React.ReactNode
}) => {
  const { header, description, checked, onChange, icon } = props

  return (
    <Card checked={checked}>
      <Checkbox checked={checked} onChange={onChange} />
      <Details>
        <Header>{header}</Header>
        <SubHeader>{description}</SubHeader>
      </Details>
      {icon}
    </Card>
  )
}

export default BenefitsTermsCard
