import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { CareerInsightsProcessingStatus, selectors, actions } from 'builder/modules/careerProfile'

export const useDashboardStatusUtils = (trackEvent?: boolean) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const careerInsightsData = useTypedSelector(selectors.careerInsightsData)
  const processingStatus = careerInsightsData?.status
  const isChatbotCompleted = useSelector(selectors.isDataSentDuringCurrentSession)

  let actionHandler = () => {
    if (trackEvent) {
      trackInternalEvent('click_get_started_assessment')
    }
    navigate('/career-profile/chat')
  }

  if (isChatbotCompleted) {
    if (processingStatus === CareerInsightsProcessingStatus.success) {
      actionHandler = () => {
        navigate('/career-profile/insights')
      }
    }
    if (processingStatus === CareerInsightsProcessingStatus.pending) {
      actionHandler = () => {
        dispatch(actions.setCareerInsightsStatusModalType('insights'))
      }
    }
  }

  return {
    actionHandler,
  }
}
