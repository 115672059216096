import styled, { keyframes } from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
import Typography from 'builder/styles/typography'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Shadows from 'builder/styles/shadows'
import { Icon24 } from 'builder/components/Icon'
import { Z_INDEX } from '../../constants'
import { PremiumButton } from '../PremiumButton'

const fadeIn = keyframes`
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`

export const Container = styled.div<{ isPremium?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  margin-left: ${props => (props.isPremium ? '0' : '16px')};
`

export const UserDropdownTrigger = styled.button<{ isOpen: boolean }>`
  position: relative;
  display: flex;
  overflow: hidden;
  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 50%;
  background: none;
  cursor: pointer;
  outline: none;

  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
  }

  &:before {
    border: 1px solid ${hexToRgba(Colors.Neutral90, 0.04)};
  }

  &:after {
    border: 2px solid ${Colors.Blue50};
    opacity: ${props => (props.isOpen ? 1 : 0)};
    transition: opacity 0.2s;
  }

  &:hover:after,
  &:focus-visible:after {
    opacity: 1;
  }

  &:active:after {
    border-color: ${Colors.Blue60};
  }
`

export const UserPhoto = styled.img`
  width: 100%;
  height: 100%;
`

export const DropdownContainer = styled.menu`
  ${Shadows.lightWithBorder.mid};
  position: absolute;
  z-index: ${Z_INDEX + 1};
  right: 0;
  top: calc(100% + 12px);
  padding: 0;
  margin: 0;
  width: 280px;
  border-radius: 8px;
  background-color: ${Colors.White};

  transform-origin: 100% 0;
  animation: ${fadeIn} 0.25s;
  animation-fill-mode: forwards;
`

export const ProfileLink = styled(RouterLink)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const TopBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-bottom: 1px solid ${Colors.Neutral15};
`

export const ProfilePhoto = styled.div`
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  margin-right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 50%;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    border: 1px solid ${hexToRgba(Colors.Neutral90, 0.04)};
  }
`

export const ProfilePhotoImage = styled.img`
  width: 100%;
  height: 100%;
`

export const ProfileContent = styled.div`
  flex-grow: 1;
`

export const ProfileName = styled.div`
  font-weight: 600;
  transition: color 0.2s;

  ${ProfileLink}:hover & {
    color: ${Colors.Blue60};
  }
`

export const ProfileNote = styled.div`
  ${Typography.Tiny};
  color: ${Colors.Neutral50};
`

export const ProfileArrow = styled(Icon24.Chevron)`
  margin-left: 12px;
  color: ${Colors.Neutral30};
  transition: color 0.2s;

  ${ProfileLink}:hover & {
    color: ${Colors.Blue50};
  }
`

export const Links = styled.div`
  padding: 12px 0;
`

export const Link = styled.a<{ secondary?: boolean }>`
  display: block;
  padding: 6px 20px;
  color: ${props => (props.secondary ? Colors.Neutral50 : Colors.Neutral90)};
  transition: color 0.2s;
  cursor: pointer;
  border: none;
  background: none;
  margin: 0;

  &:hover {
    color: ${Colors.Blue60};
  }
`

export const PremiumButtonStyled = styled(PremiumButton)`
  margin-top: 16px;
`
