import { useEffect, useState } from 'react'
import { baseClient } from 'builder/modules/apiClient'

interface Response {
  id: number
  email: string
  targetRole: string | null
  hideSkillLevel: boolean
}

export const useGetProfile = () => {
  const [loading, setLoading] = useState(true)
  const [response, setResponse] = useState<Response>()
  useEffect(() => {
    async function request() {
      try {
        const response = await baseClient.get<Response>(`/career/v1/profile`)
        setResponse(response.data)
      } finally {
        setLoading(false)
      }
    }

    request()
  }, [])
  return { loading, response }
}
