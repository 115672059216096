import styled, { css, keyframes } from 'styled-components'

import Typography, { FontWeights } from 'builder/styles/typography'
import { ResumeValidationStatus } from 'builder/modules/constants'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'

import CircleOne from '../../../../../app/assets/images/builder/fill-resume/circle-one.png'
import CircleTwo from '../../../../../app/assets/images/builder/fill-resume/circle-two.png'
import CircleThree from '../../../../../app/assets/images/builder/fill-resume/circle-three.png'
import CircleFour from '../../../../../app/assets/images/builder/fill-resume/circle-four.png'

const { White, Neutral10, Neutral50, Blue50, Red50 } = Colors
const { Caption, S } = Typography
const { Regular } = FontWeights

/**  ----------- Common styles ----------- **/
export const Div = styled.div``

export const DesktopClose = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  top: 20px;
  right: 20px;
  width: 28px;
  height: 28px;
  color: ${Neutral50};
  background: ${Neutral10};
  border-radius: 32px;
  transition: color 0.15s;
  ${Media.AboveTablet`
    &:hover {
      color: ${Blue50};
    }
  `}
`

/**  ----------- FileSelector styles ----------- **/
export const FileInput = styled.input`
  display: none;
`

/**  ----------- FileUploadContainer styles ----------- **/
export const UploadModalContainer = styled.div<{
  resumeValidationStatus?: ResumeValidationStatus
}>(props => {
  const { resumeValidationStatus } = props
  const isResumeUploaded = resumeValidationStatus === ResumeValidationStatus.validated
  return css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    overflow: hidden;
    background-color: ${White};
    border-radius: 16px;
    z-index: 30;
    height: 428px;
    width: 604px;
    ${Media.Phone`
      height: ${isResumeUploaded ? '311px' : '374px'};
      width: 343px;
  `};
  `
})

export const ContainerBase = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
`

export const UploadContainer = styled(ContainerBase)<{
  resumeValidationStatus: ResumeValidationStatus
}>(props => {
  const { resumeValidationStatus } = props
  return css`
    ${resumeValidationStatus === ResumeValidationStatus.uploading &&
    css`
      height: 324px;
      ${Media.Phone`
        height: 247px;
      `}
    `};
    ${resumeValidationStatus === ResumeValidationStatus.validated &&
    css`
      height: 292px;
      width: 500px;
      ${Media.Phone`
          height: 234px;
          width: 310px;
      `}
    `};
    ${resumeValidationStatus === ResumeValidationStatus.failed &&
    css`
      width: 406px;
      height: 260px;
      ${Media.Phone`
          width: 311px;
          height: 242px;
      `}
    `}
  `
})

export const TitleBase = styled.p`
  ${S};
  font-weight: 600;
  text-align: center;
`

/**  ----------- FileUploadProgress styles ----------- **/
export const UploadingTitle = styled(TitleBase)`
  margin-bottom: 24px;
`

export const UploadIllustration = styled.img`
  height: 160px;
  ${Media.Phone`
    height: 117px;
  `}
`

const changeBackground = keyframes`
  0% {
    background-image: none;
  }
  25% {
    background-image: url(${CircleOne});
  }
  50% {
    background-image: url(${CircleTwo});
  }
  75% {
    background-image: url(${CircleThree});
  }
  100% {
    background-image: url(${CircleFour});
  }
`
export const UploadIllustrationWrapper = styled.div`
  width: 272px;
  height: 272px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  animation: ${changeBackground} 3s forwards;
  ${Media.Phone`
    width: 199px;
    height: 199px;
  `}
`

/**  ----------- FileUploadSuccess styles ----------- **/
export const UploadSuccessIllustration = styled.img`
  height: 120px;
  ${Media.Phone`
    height: 96px;
  `}
`

export const SuccessTitle = styled(TitleBase)`
  margin-bottom: 12px;
  margin-top: 26px;
`

export const SuccessCaption = styled.div`
  ${Caption};
  ${Regular}
  text-align: center;
  color: ${Neutral50};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
`

/**  ----------- FileUploadFailed styles ----------- **/
export const UploadFailedIllustration = styled.img`
  height: 100px;
  ${Media.Phone`
    height: 72px;
  `}
`

export const FailedTitle = styled(TitleBase)`
  margin-top: 16px;
`
export const ErrorMessage = styled.p`
  ${Caption}
  ${Regular};
  color: ${Red50};
  margin: 12px 0 16px 0;
  width: 406px;
  ${Media.Phone`
    width: 311px;
  `}
`

/**  ----------- ActionLayout styles ----------- **/
export const ButtonContainer = styled.div<{
  showDoneButton: boolean
  addSpaceWhenNotVisible?: boolean
}>(props => {
  const { showDoneButton, addSpaceWhenNotVisible } = props
  return css`
    ${showDoneButton
      ? css`
          display: flex;
          flex-direction: row;
          justify-content: center;
          text-align: center;
          margin-top: 42px;
          & button:first-child {
            margin-right: 16px;
          }
          ${Media.Phone`
          margin-top: 16px;
        `}
        `
      : addSpaceWhenNotVisible
      ? 'margin-top: 100px;'
      : ''}
  `
})
