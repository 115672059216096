import { Fragment } from 'react'
import { CareerPathPayloadType } from 'builder/modules/careerPath2'
import {
  PathContainer,
  PathText,
  Ellipse,
  JobTitleContainer,
  JobTitle,
  VerticalLine,
  SkillMatchScore,
  SkillMatchScoreText,
} from './styles'

type Props = {
  titles: CareerPathPayloadType[]
}

export const PathDetails = ({ titles }: Props) => {
  return (
    <PathContainer>
      {typeof titles !== 'string' && (
        <Fragment>
          <PathText>Path:</PathText>
          {titles.map((value: CareerPathPayloadType, index: number) => (
            <Fragment key={`${value?.title + '-' + index}`}>
              <JobTitleContainer>
                <Ellipse score={value?.score} />
                <JobTitle>{value?.title || ''}</JobTitle>
                <SkillMatchScore score={value?.score}>
                  <SkillMatchScoreText score={value?.score}>
                    {value?.score || 0}%
                  </SkillMatchScoreText>{' '}
                </SkillMatchScore>
              </JobTitleContainer>
              {index + 1 !== titles.length && <VerticalLine />}
            </Fragment>
          ))}
        </Fragment>
      )}
    </PathContainer>
  )
}
