import map from 'lodash/map'
import groupBy from 'lodash/groupBy'
import { Colors } from 'builder/styles/colors'
import { Section } from '../../Section'
import { Group, ItemName, Label } from '../../styles'
import { ColorsList, ColorsItem, ColorsItemPreview } from './styles'

export const ColorsSection = () => {
  const colorNames = Object.keys(Colors).sort((a, b) => parseInt(a) - parseInt(b))
  const colorGroups = groupBy(colorNames, name => name.replace(/[0-9]/g, ''))

  return (
    <Section title="Colors">
      {map(colorGroups, (group, groupName) => (
        <Group key={groupName}>
          <Label>{groupName}</Label>
          <ColorsList>
            {group.map(colorName => (
              <ColorsItem key={colorName}>
                <ColorsItemPreview color={Colors[colorName as keyof typeof Colors]} />
                <ItemName>{colorName}</ItemName>
              </ColorsItem>
            ))}
          </ColorsList>
        </Group>
      ))}
    </Section>
  )
}
