import { useEffect, useState } from 'react'
import throttle from 'lodash/throttle'

export const useIsScrolled = () => {
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = throttle(() => {
      if (window.scrollY > 75) setIsScrolled(true)
      else setIsScrolled(false)
    }, 100)

    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return { isScrolled }
}
