import * as React from 'react'
import { Columns } from 'builder/components/Grid/types/types'
import * as Styled from './styles'

type CellProps = {
  children?: React.ReactNode
} & Columns &
  React.HTMLAttributes<HTMLDivElement>

export const Cell = ({ children, ...props }: CellProps) => (
  <Styled.Cell {...props}>{children}</Styled.Cell>
)
