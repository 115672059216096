import { useLayoutEffect, useRef, useState } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { Icon20 } from 'builder/components/Icon'
import { reflow } from 'builder/utils/reflow'
import { useI18n } from 'builder/hooks/useI18n'
import Tooltip from 'builder/components/Tooltip'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import { suggestionScopeToSectionName } from '../RichTextArea'
import useAiProfileSummary from './hooks/useAiProfileSummary'
import {
  Container,
  Header,
  Content,
  Title,
  Badge,
  InfoText,
  Actions,
  ActionButton,
  Separator,
  TooltipDescription,
  AdditionalActionsContainer,
} from './styles'
import { Props } from './types'
import { AI_PROFILE_SUMMARY_MODAL } from './constants'

const AiProfileSummary: React.FC<Props> = (props: Props) => {
  const {
    suggestionConfig: { scope },
  } = props
  const { i18n } = useI18n()
  const [isVisible, setVisible] = useState(false)
  const container = useRef<HTMLDivElement | null>(null)
  const {
    badgeText,
    badgeTooltipText,
    infoText,
    isImproveActive,
    handleGenerate,
    handleImprove,
    renderPrewrittenAction,
    isProfileEmpty,
    isWorkExperienceEmpty,
    remainingAIGenerationAttempts: remainingAttempts,
    renderKeywordsAction,
  } = useAiProfileSummary({ ...props }, container)

  const { isPhone, isTablet } = useMediaQueries()

  // Start entering transition
  useLayoutEffect(() => {
    if (container.current) reflow(container.current)
    setVisible(true)
  }, [])

  useEffectOnMount(() => {
    trackInternalEvent('open_ai_writer_dialog', {
      section: suggestionScopeToSectionName(scope),
      profile_empty: isProfileEmpty,
      experience_empty: isWorkExperienceEmpty,
      counter: remainingAttempts === Infinity ? 'unlimited' : remainingAttempts,
    })
  })

  return (
    <>
      <Container id={AI_PROFILE_SUMMARY_MODAL} ref={container} isVisible={isVisible}>
        <Content>
          <Header>
            <Title>
              {i18n.t('builder.ai_profile_summary.assistance_modal.title')}
              <Icon20.Featured />
            </Title>
            <Tooltip
              value={<TooltipDescription dangerouslySetInnerHTML={{ __html: badgeTooltipText }} />}
              position="top"
              align={isPhone || isTablet ? 'right' : 'center'}
              multiline
              width={260}
              offset={remainingAttempts === Infinity ? -9 : -22}
            >
              <Badge>{badgeText}</Badge>
            </Tooltip>
          </Header>
          <InfoText>{infoText}</InfoText>
          <Actions>
            <ActionButton onClick={handleGenerate}>
              {i18n.t('builder.ai_profile_summary.option.generate')}
            </ActionButton>
            {isImproveActive && (
              <>
                <Separator />
                <ActionButton onClick={handleImprove}>
                  {i18n.t('builder.ai_profile_summary.option.improve')}
                </ActionButton>
              </>
            )}
          </Actions>
        </Content>
        <AdditionalActionsContainer>
          {renderPrewrittenAction()}
          {renderKeywordsAction()}
        </AdditionalActionsContainer>
      </Container>
    </>
  )
}

export default AiProfileSummary
