import { ButtonTheme, ButtonSize } from 'builder/components/Button'
import { Icon20 } from 'builder/components/Icon'
import { formatSalary } from '../../../../utils'
import { ACTION_BUTTONS } from '../../constants'
import {
  Text,
  Emoji,
  Figure,
  Actions,
  ArrowUp,
  Heading,
  Container,
  ArrowDown,
  IconWrapper,
  StyledButton,
} from './styles'
const emojiUrl = require('./assets/img/emoji.svg')

interface Props {
  accuracy: string
  averageSalary: number
  isFormVisible: boolean
  onFeed: (item: string) => void
}

const getIcon = (actionItem: string) => {
  switch (actionItem) {
    case ACTION_BUTTONS[0]:
      return <ArrowUp />
    case ACTION_BUTTONS[1]:
      return <Icon20.ThumbsUp />
    default:
      return <ArrowDown />
  }
}

const Questionnare = ({ onFeed, accuracy, isFormVisible, averageSalary }: Props) => {
  return (
    <Container isFormVisible={isFormVisible}>
      <Heading>
        <Emoji src={emojiUrl} alt="emoji" />
        <Text data-testid="questionnare">
          How accurate does <Figure>${formatSalary(averageSalary)}</Figure> look to you for a year?
        </Text>
      </Heading>
      <Actions>
        {ACTION_BUTTONS.map(item => (
          <StyledButton
            key={item}
            isSelected={item === accuracy}
            onClick={() => onFeed(item)}
            size={ButtonSize.small}
            theme={ButtonTheme.ghost}
          >
            <IconWrapper>{getIcon(item)}</IconWrapper> {item}
          </StyledButton>
        ))}
      </Actions>
    </Container>
  )
}

export default Questionnare
