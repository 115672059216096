import { Fragment, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import EntryTest from 'builder/components/CareerEntryTest/EntryTest'
import { actions, selectors } from 'builder/modules/careerCoaching'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

const CareerOnboardgView = (): JSX.Element => {
  const dispatch = useDispatch()
  const location = useLocation()
  const isUserRedirectTo = useTypedSelector(selectors.isUserRedirectTo)

  useEffect(() => {
    if (isUserRedirectTo) {
      dispatch(actions.fetchRedirectRoutes({ currentUrl: location.pathname }))
      dispatch(actions.setUserRedirectState(false))
    }
  }, [dispatch, isUserRedirectTo, location.pathname])

  return (
    <Fragment>
      <EntryTest />
    </Fragment>
  )
}

export default CareerOnboardgView
