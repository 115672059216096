import { CardRect } from './styles'

export const Background = () => {
  return (
    <svg
      width="1504"
      height="572"
      viewBox="0 0 1504 572"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <CardRect width="352" height="104" rx="24" id="e11"></CardRect>
      <CardRect x="384" width="352" height="572" rx="24" id="e12"></CardRect>
      <CardRect x="768" width="352" height="572" rx="24" id="e13"></CardRect>
      <CardRect x="1152" width="352" height="572" rx="24" id="e14"></CardRect>
      <CardRect x="356" y="52" width="24" height="8" id="c1" />
      <CardRect x="740" y="52" width="24" height="8" id="c2" />
      <CardRect x="1124" y="52" width="24" height="8" id="c3" />
    </svg>
  )
}
