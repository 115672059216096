import EditorField from 'builder/components/EditorField'
import { TextField } from 'builder/components/TextField'
import { useI18n } from 'builder/hooks/useI18n'
import { actions as editorActions } from 'builder/modules/resumeEditor'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
export function Phone(props) {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const { phoneNumber } = props
  const handleSimpleFieldUpdate = React.useCallback(
    event => {
      const { name, value } = event.target
      dispatch(editorActions.updateSimpleField({ name, value, debounce: true }))
    },
    [dispatch],
  )

  return (
    <>
      {/* Phone */}
      <EditorField>
        <TextField
          label={i18n.t('builder.resume_editor.phone')}
          type="phone"
          value={phoneNumber || ''}
          name="phoneNumber"
          onChange={handleSimpleFieldUpdate}
        />
      </EditorField>
    </>
  )
}
Phone.propTypes = {
  phoneNumber: PropTypes.string,
}
