import styled from 'styled-components'
import { Colors } from 'builder/styles/colors'
import { Typography, FontWeights } from 'builder/styles/typography'
import { getScoreBasedColor } from 'builder/modules/careerPath2/utils'

export const PathContainer = styled.div``

export const PathText = styled.div`
  ${Typography.Caps};
  ${FontWeights.DemiBold};
  width: 55px;
  height: 20px;
  left: 32px;
  top: 84px;
  display: flex;
  align-items: center;
  color: ${Colors.Neutral50};
  margin-bottom: 12px;
`

export const JobTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const Ellipse = styled.div<{ score?: number }>`
  width: 6px;
  height: 6px;
  left: 32px;
  top: 125px;
  background-color: ${props => getScoreBasedColor('color', props.score)};
  border-radius: 3px;
`

export const JobTitle = styled.div`
  height: 24px;
  left: 50px;
  top: 116px;
  display: flex;
  align-items: center;
  color: ${Colors.Neutral80};
  margin: -7px 8px 12px 12px;
`

export const SkillMatchScore = styled.div<{ score?: number }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1px 4px;
  gap: 10px;
  height: 20px;
  background-color: ${props => getScoreBasedColor('bgColor', props.score)};
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top: -4px;
`

export const SkillMatchScoreText = styled.span<{ score?: number }>`
  ${Typography.Tiny};
  ${FontWeights.DemiBold};
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => getScoreBasedColor('color', props.score)};
  flex: none;
  order: 0;
  flex-grow: 0;
`

export const VerticalLine = styled.div`
  width: 0px;
  height: 26px;
  border: 1px solid ${Colors.Neutral20};
  margin: -23px 0 0 2px;
`
