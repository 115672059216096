import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Button from 'builder/components/Button'
import Sizes from 'builder/styles/sizes'

export const Container = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  background-color: ${Colors.White};
  padding-top: 8px;
  width: fit-content;

  ${Media.Tablet`
    width: 100%;
    margin-top: 30px;
    padding-right: 32px;
    padding-left: 32px;
  `};

  ${Media.Phone`
    margin-top: 24px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 22px;
  `};
`

export const Title = styled.p`
  ${Typography.L};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
  max-width: 480px;
  margin-top: 12px;

  ${Media.Tablet`
    display: none;
  `};
`

export const Subtitle = styled.p`
  ${Typography.Body};
  color: ${Colors.Neutral90};
  max-width: 447px;
  margin-top: 32px;

  ${Media.Tablet`
    margin-top: unset;
    max-width: unset;
  `};
`

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 32px;

  ${Media.Phone`
    margin-top: ${Sizes.S};
    gap: ${Sizes.S};
  `};
`

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${Sizes.XS};
`

export const Item = styled.p`
  ${Typography.Body};
  color: ${Colors.Neutral90};
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: ${Sizes.XS};
  margin-top: 60px;
  align-items: center;

  ${Media.Tablet`
    margin-top: 52px;
  `};

  ${Media.Phone`
    flex-direction: column;
    margin-top: 32px;
    gap: ${Sizes['2XS']};
  `}
`

export const PremiumButton = styled(Button)`
  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${Colors.White};
  background-color: ${Colors.Blue50};
  border-radius: 4px;
  width: 206px;
  padding: 12px 24px;

  &:hover {
    background-color: ${Colors.Blue60};
  }

  ${Media.Phone`
    width: 100%;
  `};
`

export const CancelSubcriptionText = styled.p`
  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${Colors.Neutral50};
  cursor: pointer;

  &:hover {
    color: ${Colors.Blue50};
  }
`

export const ItemNumberContainer = styled.div`
  display: flex;
  width: 18px;
  height: 18px;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.Blue10};
  border-radius: 100%;
`

export const ItemNumber = styled.span`
  color: ${Colors.Blue50};
  ${Typography.Caps};
  ${FontWeights.DemiBold};
  letter-spacing: unset;
`

export const Header = styled.span`
  color: ${Colors.Neutral50};
  ${Typography.Caps};
`

export const Content = styled.div`
  display: flex;
  gap: 28px;

  ${Media.Tablet`
    flex-direction: column;
    gap: 40px;
  `}

  ${Media.Phone`
    gap: 28px;
  `}
`

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;

  ${Media.Tablet`
    order: 2;  
  `}
`

export const WideFeatureSlot = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
  border-radius: 16px;
  background: ${Colors.Blue10};
  padding: ${Sizes.XL} ${Sizes.L};
  gap: 16px;

  ${Media.Tablet`
    width: 33.3%;
    padding: ${Sizes.L} ${Sizes.M};
  `}

  ${Media.Phone`
    width: 100%;
    padding: ${Sizes.S} ${Sizes.M};
    border-radius: ${Sizes['3XS']};
  `}
`

export const WideFeatureSlotTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${Colors.Blue80};
`

export const FeatureSlotTitle = styled.h3`
  margin-bottom: ${Sizes['2XS']};
  font-size: 39px;
  line-height: 40px;
  ${FontWeights.Medium};

  ${Media.Phone`
    font-size: 33px;
    line-height: 34px;
  `}
`

export const WideFeatureSlotImage = styled.img`
  width: 150px;
  height: 104px;

  ${Media.Tablet`
    display: none;
  `}

  ${Media.Phone`
    display: flex;
  `}
`

export const ModalImageSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  gap: ${Sizes['2XS']};

  ${Media.Tablet`
    order: 1;
    flex-direction: row;
  `}

  ${Media.Phone`
    gap: ${Sizes['2XS']};
    flex-direction: column;
  `}
`

export const NarrowFeatureSlotsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${Sizes['2XS']};

  ${Media.Tablet`
    width: 66.6%;
  `}

  ${Media.Phone`
    width: 100%;
  `}
`

export const FeatureSlot = styled.div<{ variant: string }>`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: none;
  padding: ${Sizes.XL} ${Sizes.L};
  background-color: ${props => (props.variant === 'green' ? Colors.Green10 : Colors.Indigo10)};
  color: ${props => (props.variant === 'green' ? Colors.Green80 : Colors.Indigo80)};

  ${Media.Tablet`
    padding: 45px ${Sizes.M};
    width: 50%;
  `}

  ${Media.Phone`
    padding: ${Sizes.M};
    border-radius: ${Sizes['3XS']};
  `}
`

export const FeatureSlotDescription = styled.p<{ maxWidth: number }>`
  ${Typography.Body};
  max-width: ${props => props.maxWidth}px;

  ${Media.Tablet`
    max-width: unset;
  `}

  ${Media.Phone`
    font-size: 16px;
    line-height: 20px:
  `}
`

export const TabletTitle = styled.p`
  ${Typography.L};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
  max-width: 520px;
  margin-top: 12px;
  display: none;
  margin-bottom: 36px;

  ${Media.Tablet`
    display: flex;
  `};

  ${Media.Phone`
    max-width: unset;
    margin-bottom: 28px;
  `};
`
