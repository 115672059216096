import formatISO from 'date-fns/formatISO'
import parseISO from 'date-fns/parseISO'
import isPast from 'date-fns/isPast'
import addMinutes from 'date-fns/addMinutes'
import findLatest from 'date-fns/max'
import { calculateDaysDifference } from 'builder/components/Navigation/components/PremiumTracker/utils'
import { CRITIQUE_EXPIRY_DAYS } from '../constants'
import { ResumeCritique, ResumeCritiqueStatuses as STATUSES } from './types'

const REVIEW_DURATION_IN_MINUTES = 5 * 60

/** Overrides review ending time */
export const overrideCritiqueFields = (critique: ResumeCritique) => {
  // Do not override failed critiques
  if (critique.status === STATUSES.failed) return critique

  // TopResume performs a review in ~2 minutes, but we need to keep loading state for 5 hours
  let endingDate = addMinutes(parseISO(critique.createdAt), REVIEW_DURATION_IN_MINUTES)
  let status = STATUSES.pending

  // Keep real ending time if it's later than fake ending time (just in case)
  if (critique.reviewedAt) {
    const realReviewDate = parseISO(critique.reviewedAt)
    endingDate = findLatest([realReviewDate, endingDate])
  }

  // Mark the critique as "failed" if it's still empty after 5 hours
  // or change status to "finished" if we've got an iframe url
  if (isPast(endingDate)) {
    status = critique.iframeUrl ? STATUSES.finished : STATUSES.failed
  }

  // Override critique fields
  return {
    ...critique,
    status,
    reviewedAt: formatISO(endingDate),
  } as ResumeCritique
}

/** Creates a fake critique */
export const createFakeCritique = (fields: Partial<ResumeCritique>) => {
  return {
    id: 0,
    resumeId: 0,
    status: STATUSES.pending,
    createdAt: formatISO(Date.now()),
    reviewer: {},
    ...fields,
  } as ResumeCritique
}

export const checkIfCritiqueExpired = (critiqueCreatedAt: string) => {
  const differenceInDays = Math.abs(calculateDaysDifference(critiqueCreatedAt))

  return differenceInDays >= CRITIQUE_EXPIRY_DAYS
}
