import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Icon24 } from 'builder/components/Icon'
import Tooltip from 'builder/components/Tooltip/Tooltip'

export const Wrapper = styled.div``

export const Container = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
  text-align: center;

  ${Media.Phone`
    text-align: left;
  `}
`

export const Title = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};
`

export const SubTitle = styled.div`
  max-width: 416px;
  margin-bottom: 24px;
  align-self: center;
  color: ${Colors.Neutral50};

  ${Media.Phone`
    margin-bottom: 9px;
    align-self: flex-start;
  `}
`

export const TipsTitle = styled.div`
  ${Typography.Caption};
  color: ${Colors.Blue50};

  &:hover {
    color: ${Colors.Blue60};
  }
`

export const HintIcon = styled(Icon24.HintOutlined)`
  width: 20px;
  height: 20px;
  color: ${Colors.Blue50};

  &:hover {
    color: ${Colors.Blue60};
  }
`

export const TipsContainer = styled.div`
  gap: 4px;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  margin: 8px 0 25px;
  width: 50px;

  &:hover {
    ${TipsTitle} {
      color: ${Colors.Blue60};
    }

    ${HintIcon} {
      color: ${Colors.Blue60};
    }
  }
`

export const TooltipQuestionWrapper = styled(Tooltip)`
  & > div {
    width: 311px;
    left: 140px;

    & > svg {
      left: 52px;
    }

    .hint-icon {
      color: ${Colors.Blue50};

      &:hover {
        color: ${Colors.Blue60};
      }
    }
  }

  .tips-container {
    width: auto;
  }
`

export const ToolTip = styled.img`
  width: 50px;
  cursor: pointer;
  height: 20px;
  margin-bottom: 10px;
`

export const ThirdDescription = styled.a`
  text-decoration: underline;
`
