import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Container = styled.div`
  display: flex;
  gap: 32px;
  padding: 32px;
  border-radius: 16px;
  border: 1px solid ${Colors.Neutral15};
  cursor: pointer;

  ${Media.Phone`
     padding: 24px;
     gap: 20px;
     flex-direction: column;
  `};
`

export const CoachImageContainer = styled.div`
  width: 176px;
  height: 176px;
  border-radius: 50%;

  ${Media.Phone`
    width: 216px;
    height: 216px;
    margin: auto;
  `}
`
export const CoachImage = styled.img`
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
`

export const SessionDetails = styled.div``

export const SessionTitle = styled.div`
  ${Typography.S};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
  margin-bottom: 8px;

  ${Media.Phone`
    ${Typography.Subhead};
  `}
`
export const SesssionDesc = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral90};
`

export const DetailsList = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const DetailsItem = styled.div`
  color: ${Colors.Neutral50};
  display: flex;
  gap: 12px;

  ${Media.Phone`
    ${Typography.Caption};
  `};
`
export const TickImage = styled.img`
  width: 24px;
  height: 24px;
`

export const Text = styled.p``
