import { useCallback } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useUpdateSimpleField } from 'builder/hooks/useUpdateSimpleField'
import { useAppSelector } from 'builder/hooks/useAppSelector'
import Anchor from 'builder/components/Anchor'
import EditorTitle from 'builder/components/EditorTitle'
import { TunerEditorProvider } from 'builder/components/Tuner'
import { SectionNames } from 'builder/modules/resumeEditor'
import { i18n as I18n } from 'builder/utils/i18n'
import loaderImage from 'images/builder/resume-editor/editor-loader.svg'

import {
  EditorContent,
  EditorPanel,
  EditorScroll,
  Loader,
  LoaderContainer,
  UserNavigation,
} from '../styles'
import JapaneseSectionsList from './JapaneseSectionsList'
import { JapanesePersonalDetailsSection } from './PersonalDetails/JapanesePersonalDetailsSection'
import { CustomSection } from './Sections/CustomSection/CustomSection'
import { ProfessionalSummary } from './Sections/ProfessionalSummary/ProfessionalSummary'
import { Spacing } from './common/Spacing'

const RirekishoEditor: React.FC = () => {
  const {
    generalEditor: { resumeTemplates, levels },
    resumeEditor: { resume },
    application: { isOnline },
  } = useAppSelector(state => state)

  const { updateSimpleField } = useUpdateSimpleField()

  const handleResumeNameChange = useCallback(
    (value: string) => {
      updateSimpleField({ name: 'name', value })
      trackInternalEvent('rename_resume')
    },
    [updateSimpleField],
  )

  if (!resume || resumeTemplates.length === 0) {
    return (
      <EditorPanel>
        <LoaderContainer>
          <Loader src={loaderImage} />
        </LoaderContainer>
      </EditorPanel>
    )
  }

  return (
    <EditorPanel>
      {isOnline && <UserNavigation hasFeatures />}

      <EditorScroll>
        <EditorContent>
          <EditorTitle
            onChange={handleResumeNameChange}
            value={resume.name ? resume.name : I18n.t('builder.resume_editor.untitled')}
          />

          <Spacing height="40px" width="100%" />

          <TunerEditorProvider {...{ resume }}>
            <Anchor id={SectionNames.details} scrollOffsetTop={70}>
              <JapanesePersonalDetailsSection {...{ resume }} />
            </Anchor>

            <JapaneseSectionsList
              {...{
                resume,
                levels,
              }}
            />

            <ProfessionalSummary {...{ resume }} />
            <CustomSection />
          </TunerEditorProvider>
        </EditorContent>
      </EditorScroll>
    </EditorPanel>
  )
}

export default RirekishoEditor
