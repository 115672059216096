import { FC, useCallback, useState } from 'react'
import type { UserDocumentTemplateId } from '@rio/types'
import { trackInternalEvent } from '@rio/tracking'
import { useSelector, useDispatch } from 'react-redux'
import { useI18n } from 'builder/hooks/useI18n'
import { actions as uiActions } from 'builder/modules/ui'
import { selectors as userSelectors } from 'builder/modules/user'
import ModalOverlay from 'builder/components/ModalOverlay'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { PanelSessionStorageKeys } from 'builder/modules/panel'
import Icon from 'builder/components/Icon'
import TemplateSlider from './TemplatesSlider'
import { useDocxTemplates, useDocument } from './hooks'
import { CloseButton, Container, DownloadButton, Text, Title } from './styles'

interface DocxModalProps {
  documentId: number
  documentType: string
}

const DocxModal: FC<DocxModalProps> = ({ documentId, documentType }) => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const { isPhone } = useMediaQueries()
  const docxTemplates = useDocxTemplates(documentType)
  const currentDocument = useDocument(documentId, documentType)
  const premiumRequired = documentType === 'resume'
  const hasPremiumAccess = useSelector(userSelectors.premiumAccess)
  const canDownload = !premiumRequired || hasPremiumAccess

  const [template, setTemplate] = useState<UserDocumentTemplateId>('stockholm')

  const handleClose = useCallback(() => {
    return dispatch(uiActions.docxModalClosed())
  }, [dispatch])

  const handleDownload = useCallback(() => {
    sessionStorage.setItem(PanelSessionStorageKeys.COVER_LETTER_TEMPLATE, template)
    // Track in ClickHouse
    trackInternalEvent('click_docx_upgrade_now_button')
    return dispatch(uiActions.docxModalClosedDownload({ template }))
  }, [template, dispatch])

  return (
    <ModalOverlay
      fullScreen={isPhone}
      onClick={handleClose}
      overlayFadeDuration={250}
      contentSlideDuration={250}
    >
      <Container>
        <CloseButton onClick={handleClose}>
          <Icon.CloseLarge />
        </CloseButton>

        <DownloadButton onClick={handleDownload}>
          {canDownload
            ? i18n.t('builder.resume_editor.docx_template_select.download')
            : i18n.t('builder.resume_editor.docx_template_select.upgrade')}
        </DownloadButton>

        <Title>
          {canDownload
            ? i18n.t('builder.resume_editor.docx_template_select.download_title')
            : i18n.t('builder.resume_editor.docx_template_select.upgrade_title')}
        </Title>

        <Text>{i18n.t('builder.resume_editor.docx_template_select.description')}</Text>

        <TemplateSlider
          document={currentDocument}
          templates={docxTemplates}
          onChange={setTemplate}
        />
      </Container>
    </ModalOverlay>
  )
}

export default DocxModal
