import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { SuggestionSelectedEventData } from 'react-autosuggest'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { TextField } from 'builder/components/TextField'
import { Icon20 } from 'builder/components/Icon'
import Button, { ButtonSize, ButtonTheme } from 'builder/components/Button'
import Select from 'builder/components/Select'
import {
  actions as jobSearchActions,
  selectors as jobSearchSelectors,
} from 'builder/modules/jobSearch'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

import { AsyncAutosuggest } from 'builder/components/AsyncAutosuggest'
import { JobSuggestionType } from 'builder/modules/interview/types'
import {
  AlertTypes,
  FrequencyType,
  JobSearchNotificationType,
} from 'builder/modules/jobSearch/types'
import { useJobTitles } from 'builder/hooks/useJobTitles'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { DialogModalV2 } from 'builder/components/DialogModal'

import Toggle from 'builder/components/Toggle'
import { useNewCustomAlert } from '../hooks/useNewCustomAlert'
import { createNewLocationSuggestionsApiFetcher } from '../../utils/createNewSuggestionsApiFetcher'
import { useMutationCustomAlert } from '../hooks/useMutationCustomAlert'
import { useTranslatedFrequenciesString } from '../hooks/useTranslatedFrequenciesString'
import { getLabel } from '../getLabel'
import {
  DeleteJobAlertContainer,
  ButtonsContainer,
  WrapperButtons,
  Field,
  Content,
  RemoteContainer,
  ErrorContainer,
} from './styles'
const TRANSLATION = 'builder.job_search.alert_modal.form_alert'

export enum FormAlertModalType {
  create = 'create',
  edit = 'edit',
}

interface Props {
  mode: FormAlertModalType
}
type SelectedValue =
  | { id?: number; text: string; value?: any | undefined; formatted?: string }
  | undefined

interface Form {
  text: string
  selectedValue: SelectedValue
}
interface Field {
  desiredRole: Form
  desiredLocation: Form
}
export const FormAlertModal = ({ mode }: Props) => {
  const { i18n } = useI18n()
  const alert = useTypedSelector(jobSearchSelectors.alert)
  const { frequencies } = useTranslatedFrequenciesString()
  const { isPhone } = useMediaQueries()
  const locationText = alert.editCustomAlert?.location || ''

  const locationObj: SelectedValue = {
    text: locationText,
    value: alert.editCustomAlert?.locationId || undefined,
  }

  const [formFields, setFormFields] = useState<Field>({
    desiredRole: {
      text: alert.editCustomAlert?.targetRole || '',
      selectedValue: alert.editCustomAlert?.targetRole
        ? {
            text: alert.editCustomAlert?.targetRole,
          }
        : undefined,
    },
    desiredLocation: {
      text: locationText,
      selectedValue: alert.editCustomAlert?.location ? locationObj : undefined,
    },
  })
  const [frequency, setFrequency] = useState<FrequencyType>(
    alert.editCustomAlert?.frequency || frequencies[0].id,
  )

  const [isRemoteEnabled, setIsRemoteEnabled] = useState(alert.editCustomAlert?.onlyRemoteJobs)

  const [checkFields, setCheckFields] = useState(false)

  const newCustomAlert = useNewCustomAlert()
  const mutationCustomAlert = useMutationCustomAlert()
  const { mutateAsync: getJobTitle } = useJobTitles()
  const dispatch = useDispatch()

  const handleChangeInput =
    (field: keyof typeof formFields) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFormFields(prevFormFields => ({
        ...prevFormFields,
        [field]: {
          text: e.target.value,
          selectedValue: undefined,
        },
      }))
    }
  const onSuggestionSelected =
    (field: keyof typeof formFields) =>
    (_: React.FormEvent<unknown>, { suggestion }: SuggestionSelectedEventData<any>) => {
      setFormFields(prevFormFields => ({
        ...(prevFormFields || {}),
        [field]: { text: suggestion.formatted || suggestion.text, selectedValue: suggestion },
      }))
    }

  const handleEmptyValues = () => {
    let hasEmptyValues = false
    if (!formFields.desiredRole.selectedValue) {
      setFormFields(prevFormFields => ({
        ...prevFormFields,
        desiredRole: {
          text: '',
          selectedValue: undefined,
        },
      }))

      hasEmptyValues = true
    }

    if (!formFields.desiredLocation.selectedValue) {
      setFormFields(prevFormFields => ({
        ...prevFormFields,
        desiredLocation: {
          text: '',
          selectedValue: undefined,
        },
      }))

      if (!isRemoteEnabled || formFields.desiredLocation.text) {
        hasEmptyValues = true
      }
    }
    return hasEmptyValues
  }
  const onConfirmation = async () => {
    setCheckFields(true)
    if (handleEmptyValues()) {
      return
    }

    if (mode === FormAlertModalType.create) {
      try {
        await newCustomAlert.mutateAsync({
          targetRole: formFields.desiredRole.selectedValue?.text,
          location:
            formFields.desiredLocation.selectedValue?.formatted ||
            formFields.desiredLocation.selectedValue?.text,
          locationId: parseInt(formFields.desiredLocation.selectedValue?.value),
          onlyRemoteJobs: isRemoteEnabled,
          frequency,
        })
        trackInternalEvent('create_custom_alert', {
          label: getLabel(),
        })
        dispatch(jobSearchActions.setAlertType({ type: AlertTypes.confirmation_success }))
      } catch (e) {
        dispatch(jobSearchActions.setNotification(JobSearchNotificationType.snackbar_fail))
      }
    }

    if (mode === FormAlertModalType.edit && alert.editCustomAlert) {
      try {
        await mutationCustomAlert.mutateAsync({
          id: alert.editCustomAlert.id,
          targetRole: formFields.desiredRole.selectedValue?.text,
          location:
            formFields.desiredLocation.selectedValue?.formatted ||
            formFields.desiredLocation.selectedValue?.text,
          locationId: formFields.desiredLocation.selectedValue?.id,
          onlyRemoteJobs: isRemoteEnabled,
          frequency,
        })
        dispatch(jobSearchActions.setAlertType({ type: AlertTypes.manage }))
      } catch (e) {
        dispatch(jobSearchActions.setNotification(JobSearchNotificationType.snackbar_fail))
      }
    }
  }

  return (
    <DialogModalV2
      onClose={() => dispatch(jobSearchActions.setAlertType({ type: undefined }))}
      title={i18n.t(`${TRANSLATION}.title_${mode}`)}
      subtitle={i18n.t(`${TRANSLATION}.subtitle`)}
    >
      <Content>
        <Field>
          <AsyncAutosuggest<JobSuggestionType>
            highlightedQuery
            value={formFields.desiredRole.text}
            onChange={handleChangeInput('desiredRole')}
            onSuggestionSelected={onSuggestionSelected('desiredRole')}
            fetchItems={async (query: string) => {
              const res = await getJobTitle(query)

              return res.map(title => ({
                text: title.standardTitle,
                id: title.id,
              }))
            }}
          >
            {inputProps => (
              <TextField
                {...inputProps}
                error={checkFields && !formFields.desiredRole.selectedValue}
                name="jobTitle"
                label={i18n.t(`${TRANSLATION}.desired_role`)}
                placeholder={i18n.t(`${TRANSLATION}.desired_role_placeholder`)}
              />
            )}
          </AsyncAutosuggest>
        </Field>

        {checkFields && !formFields.desiredRole.selectedValue && (
          <ErrorContainer>{i18n.t(`${TRANSLATION}.error_target_role`)}</ErrorContainer>
        )}

        <Field>
          <AsyncAutosuggest
            fetchItems={createNewLocationSuggestionsApiFetcher}
            onChange={handleChangeInput('desiredLocation')}
            value={formFields.desiredLocation.text}
            onSuggestionSelected={onSuggestionSelected('desiredLocation')}
            shouldRenderSuggestions={() => true}
            getSuggestionValue={suggestion => suggestion.formatted || suggestion.text}
          >
            {inputProps => (
              <TextField
                {...inputProps}
                label={i18n.t(`${TRANSLATION}.desired_location`)}
                error={checkFields && !isRemoteEnabled && !formFields.desiredLocation.selectedValue}
                placeholder={i18n.t(`${TRANSLATION}.desired_location_placeholder`)}
              />
            )}
          </AsyncAutosuggest>
        </Field>

        <RemoteContainer>
          <Toggle
            onClick={() => {
              setIsRemoteEnabled(prevIsRemoteEnabled => !prevIsRemoteEnabled)
            }}
            checked={isRemoteEnabled}
          />
          <span>{i18n.t(`${TRANSLATION}.remote_label`)}</span>
        </RemoteContainer>
        {checkFields && !isRemoteEnabled && !formFields.desiredLocation.selectedValue && (
          <ErrorContainer>{i18n.t(`${TRANSLATION}.error_location`)}</ErrorContainer>
        )}
        <Field>
          <Select<FrequencyType>
            label={i18n.t(`${TRANSLATION}.frequency`)}
            selected={frequency}
            onSelect={setFrequency}
            disabled={frequencies.length === 1}
            options={frequencies}
          />
        </Field>
      </Content>

      <ButtonsContainer>
        <DeleteJobAlertContainer
          onClick={() => {
            if (mode === FormAlertModalType.edit && alert.editCustomAlert) {
              dispatch(
                jobSearchActions.setAlertType({
                  type: AlertTypes.delete,
                  editCustomAlert: {
                    location: formFields.desiredLocation.selectedValue?.text,
                    locationId: formFields.desiredLocation.selectedValue?.value,
                    targetRole: formFields.desiredRole.selectedValue?.text,
                    id: alert.editCustomAlert.id,
                    frequency,
                  },
                }),
              )
            }
          }}
        >
          {mode === FormAlertModalType.edit && (
            <>
              <Icon20.TrashIcon /> {i18n.t(`${TRANSLATION}.delete_job_alert`)}
            </>
          )}
        </DeleteJobAlertContainer>
        <WrapperButtons>
          <Button
            size={isPhone ? ButtonSize.small : ButtonSize.default}
            theme={ButtonTheme.ghost}
            onClick={() => dispatch(jobSearchActions.setAlertType({ type: AlertTypes.manage }))}
          >
            {i18n.t(`${TRANSLATION}.back`)}
          </Button>
          <Button
            size={isPhone ? ButtonSize.small : ButtonSize.default}
            theme={ButtonTheme.default}
            isDisabled={newCustomAlert.isLoading || mutationCustomAlert.isLoading}
            isLoading={newCustomAlert.isLoading}
            onClick={onConfirmation}
          >
            {i18n.t(`${TRANSLATION}.confirmation_${mode}`)}
          </Button>
        </WrapperButtons>
      </ButtonsContainer>
    </DialogModalV2>
  )
}
