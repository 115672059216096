import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import mixins from 'builder/styles/mixins'

export const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 16px;
`

export const InfoContainerFull = styled(InfoContainer)`
  width: 100%;
`

export const InfoItem = styled.div`
  display: flex;
  align-items: center;
  color: ${Colors.Green70};
  ${Typography.Caption}

  img {
    margin-right: 4px;
  }
`

export const InfoItemLoading = styled.div<{ animated?: boolean }>`
  width: 100%;
  height: 24px;
  border-radius: 2px;
  background-color: ${Colors.Neutral10};
  ${props => props.animated && mixins.placeholderBackground}
`
