import { memo } from 'react'
import { Header, Cards, Card, Text, SubText, Container } from './styles'

import { getThemeColors } from './constants'

interface CareerService {
  name: string
  description: string
}

interface Props {
  services: Array<CareerService>
}

const CoachServices = ({ services }: Props) => {
  return (
    <Container>
      <Header>Services Offered</Header>
      <Cards>
        {services.map((careerService: CareerService) => {
          return (
            <Card service={getThemeColors(careerService.name)} key={careerService.name}>
              <Text>{careerService.name}</Text>
              <SubText>{careerService?.description}</SubText>
            </Card>
          )
        })}
      </Cards>
    </Container>
  )
}

export default memo(CoachServices)
