import { Container, FormContainer } from './styles'
import { Form } from './components/Form'

export const CareerPathSuccess = () => {
  return (
    <Container timeout={0}>
      <FormContainer>
        <Form />
      </FormContainer>
    </Container>
  )
}
