import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'

export const VisualContainer = styled.div`
  position: absolute;
  width: 268px;
  height: 214px;
  left: 0px;
  top: 0px;

  ${Media.Phone`
    width: 242px;
    height: 212px;
  `}
`

export const VisualImageContainer = styled.div``

export const VisualImage = styled.img`
  width: 100%;
  height: 100%;
`

export const VisualIndIndicatorContainer = styled.div`
  position: absolute;
  width: 38px;
  height: 28px;
  bottom: 20px;
  right: 26px;
  z-index: 1;
`

export const VisualIndIndicatorImage = styled.img`
  width: 100%;
  height: 100%;
`

export const VisualGradient = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px;
  background: linear-gradient(to bottom, ${Colors.Neutral10} 0%, ${Colors.Neutral5} 100%);
  opacity: 0.6;
`
