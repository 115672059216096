import { Wrapper, Heading1, Paragraph } from './styles'

const Heading = () => {
  return <Heading1>Job Search Strategy</Heading1>
}

const SubHeading = () => {
  return <Paragraph>Following our personal plan, you will be able to get your dream job</Paragraph>
}

const Header = () => {
  return (
    <Wrapper>
      <Heading />
      <SubHeading />
    </Wrapper>
  )
}

export default Header
