import { TextAreaField } from 'builder/components/TextField'
import { Cards } from 'builder/components/SubscriptionCancellationFormNew/common/types'
import { useCancellationFormContext } from '../../FormSection'
import PromotionButton from '../PromotionButton'
import { PromotionContent } from '../PromotionContent'
import { PromotionDescription } from '../PromotionDescription'
import { PromotionTitle } from '../PromotionTitle'
import { TextAreaWrapper } from '../TextAreaWrapper'
import { PromotionImage } from '../PromotionImage'

const DesktopView = () => {
  const {
    activeCard,
    isACIORegisteredUser,
    promotionDescription,
    promotionImage,
    promotionTitle,
    promotionTitleTopic,
    promotionTopic,
    textAreaChangeHandler,
    textAreaPlaceholder,
    textAreaValue,
  } = useCancellationFormContext()

  const showPromotionImage = [Cards.JobOffer, Cards.Discount, Cards.DreamJob].includes(
    activeCard as Cards,
  )
  const showTextAreaForFeedback = [Cards.FeatureOrProblem, Cards.OtherReasons].includes(
    activeCard as Cards,
  )

  return (
    <>
      <PromotionContent topic={promotionTopic}>
        <PromotionTitle topic={promotionTitleTopic}>{promotionTitle}</PromotionTitle>
        <PromotionDescription topic={promotionTopic}>{promotionDescription}</PromotionDescription>
        <PromotionButton />
      </PromotionContent>
      {showTextAreaForFeedback && (
        <TextAreaWrapper>
          <TextAreaField
            className="textAreaField"
            placeholder={textAreaPlaceholder}
            value={textAreaValue}
            onChange={textAreaChangeHandler}
          />
        </TextAreaWrapper>
      )}
      {showPromotionImage && (
        <PromotionImage isACIORegisteredUser={isACIORegisteredUser} topic={promotionTopic}>
          <img src={promotionImage} />
        </PromotionImage>
      )}
    </>
  )
}

export default DesktopView
