import type { ResumeAvatarTransform } from '@rio/types'
import { convertBase64ToFile } from 'builder/utils/convertBase64ToFile'
import {
  BACKGROUND_IMAGE_NAMES,
  BRIGHT_BACKGROUND_COLORS as BRIGHT_COLORS,
  MAX_OUTPUT_SQUARE_SIDE,
  OUTPUT_IMAGE_QUALITY,
} from './constants'
import { Background, BackgroundColor, BackgroundImage, Ratio } from './types'

export const getImageRatio = (image: HTMLImageElement): Ratio => ({
  x: Math.min(1, 1 / (image.width / image.height)),
  y: Math.min(1, 1 / (image.height / image.width)),
})

export const loadImage = (src: string): Promise<HTMLImageElement> => {
  return new Promise(resolve => {
    const image = new Image()
    image.crossOrigin = 'anonymous'
    image.onload = () => resolve(image)
    image.src = src
  })
}

interface FormatOptions {
  image: HTMLImageElement
  zoom: number
  x: number
  y: number
  angle: number
  background: Background
}

/**
 * Formats the internal component fields into the backend compatible object
 */
export const formatTransform = (options: FormatOptions): ResumeAvatarTransform => {
  const { image, zoom, x, y, angle, background } = options

  const ratio = getImageRatio(image)

  const rectX = x - (0.5 / zoom) * ratio.x
  const rectY = y - (0.5 / zoom) * ratio.y

  const rect = {
    x: rectX,
    y: rectY,
    width: (x - rectX) * 2,
    height: (y - rectY) * 2,
  }

  return {
    rect,
    zoom,
    angle: angle % 360,
    customBackground: background,
  }
}

/**
 * Makes the square image smaller to fit into a side limit if necessary
 */
export const limitSquareSize = (square: HTMLCanvasElement, limit: number): HTMLCanvasElement => {
  if (square.width <= limit) return square

  const canvas = document.createElement('canvas')
  canvas.width = limit
  canvas.height = limit
  canvas.getContext('2d')?.drawImage(square, 0, 0, limit, limit)

  return canvas
}

/**
 * Converts image (by URL) to base64 string by drawing the image into canvas
 */
export const getBase64ByImageUrl = async (imageUrl: string) => {
  const image = await loadImage(imageUrl)
  const canvas = document.createElement('canvas')
  canvas.width = image.width
  canvas.height = image.height

  canvas.getContext('2d')?.drawImage(image, 0, 0)

  return canvas.toDataURL('image/jpeg', 0.8)
}

/**
 * Fills canvas with a passed custom background
 */
export const drawBackground = async (canvas: HTMLCanvasElement, { image, color }: Background) => {
  const { width, height } = canvas

  const ctx = canvas.getContext('2d')
  if (!ctx) return canvas

  ctx.clearRect(0, 0, width, height)

  // Fill with color or gradient
  if (color) {
    if (Array.isArray(color)) {
      const gradient = ctx.createLinearGradient(0, 0, width, height)
      gradient.addColorStop(0, color[0])
      gradient.addColorStop(1, color[color.length - 1])
      ctx.fillStyle = gradient
    } else {
      ctx.fillStyle = color
    }

    ctx.fillRect(0, 0, width, height)
  }

  // Draw pattern or photo
  if (image) {
    const format = image.startsWith('pattern') ? 'png' : 'jpg'
    const imageUrl = require(`images/builder/avatar-editor/bgs/${image}.${format}`) as string
    const imageElement = await loadImage(imageUrl)
    ctx.drawImage(imageElement, 0, 0, width, height)
  }

  return canvas
}

/**
 * Well-performing comparison of the two background color string or arrays
 */
export const areEqualBackgroundColors = (color1: BackgroundColor, color2: BackgroundColor) => {
  const string1 = Array.isArray(color1) ? color1.join() : color1
  const string2 = Array.isArray(color2) ? color2.join() : color2
  return string1 === string2
}

/**
 * Well-performing comparison of the two background objects
 */
export const areEqualBackgrounds = (bg1: Background, bg2: Background): boolean => {
  if (bg1.image !== bg2.image) return false
  return areEqualBackgroundColors(bg1.color, bg2.color)
}

/**
 * Returns a pseudo-random background color for a specific image by its name.
 * Applies a custom color if image  has transparent areas
 */
export const getBackgroundColorForImage = (
  image: BackgroundImage,
  customColor?: BackgroundColor,
): BackgroundColor => {
  // solid background (color only)
  if (!image) return customColor || BRIGHT_COLORS[0]

  // choose color for a pattern bg
  if (image.startsWith('pattern')) {
    const index = BACKGROUND_IMAGE_NAMES.indexOf(image)
    return customColor || BRIGHT_COLORS[(index + 1) % BRIGHT_COLORS.length]
  }

  // photo backgrounds do not need a bg color
  return null
}

/**
 * Composes resulting image before sending to the backend
 */
export const composeOutputImageFile = async (
  previewCanvas: HTMLCanvasElement,
  background: Background,
): Promise<File> => {
  // Create new canvas
  const canvas = document.createElement('canvas')
  canvas.width = previewCanvas.width
  canvas.height = previewCanvas.height

  // Draw layers
  await drawBackground(canvas, background)
  canvas.getContext('2d')?.drawImage(previewCanvas, 0, 0)

  // Resize canvas is the image is too big
  const resizedCanvas = limitSquareSize(canvas, MAX_OUTPUT_SQUARE_SIDE)

  // Compress and convert to base64
  const base64 = resizedCanvas.toDataURL('image/jpeg', OUTPUT_IMAGE_QUALITY)

  return convertBase64ToFile(base64, 'avatar.jpg')
}
