import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Mixins from 'builder/styles/mixins'
import { Icon24, Icon20 } from 'builder/components/Icon'
import { rotation } from 'builder/styles/keyframes'
import Media from 'builder/styles/media'

export const transitionDurationMs = 150

export const Container = styled.div<{ isVisible: boolean; wide?: boolean }>`
  position: absolute;
  overflow: hidden;
  z-index: 15;
  width: ${props => (props.wide ? 560 : 420)}px;
  max-width: 100vw;

  border-radius: 3px;
  background: ${Colors.White};
  box-shadow: 0px 0px 1px rgba(23, 68, 130, 0.2), 0px 2px 14px rgba(23, 68, 130, 0.15);

  opacity: 0;
  transform-origin: 0 0;
  transition-property: opacity, transform;
  transition-timing-function: ease-out;
  transition-duration: ${transitionDurationMs}ms;

  ${props => Media.Phone`
    width: 100vw;
    left: 50%;
    bottom: calc(100% + 12px);
    transform: translate(-50%, 8px);
    border-radius: 0;

    ${
      props.isVisible &&
      css`
        transform: translate(-50%, 0);
        opacity: 1;
      `
    }
  `}

  ${props => Media.AbovePhone`
    right: -35px;
    bottom: calc(100% + 12px);
    transform: translate(0, 8px);

    ${
      props.isVisible &&
      css`
        transform: translate(0, 0);
        opacity: 1;
      `
    }
  `}

  ${props => Media.Desktop`
    margin-left: 16px;
    left: 100%;
    top: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-8px, -50%);

    ${
      props.isVisible &&
      css`
        transform: translate(0, -50%);
        opacity: 1;
      `
    }
  `}

  mark {
    background: transparent;
    color: ${Colors.Blue50};
  }
`

export const Header = styled.div`
  position: relative;
  border-bottom: 1px solid ${Colors.Neutral15};
`

export const SearchIcon = styled(Icon24.Zoom)`
  position: absolute;
  left: 12px;
  top: 12px;
  color: ${Colors.Neutral40};
`

export const CloseButton = styled.button`
  border: none;
  padding: 0;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 12px;
  right: 16px;
  color: ${Colors.Neutral40};
  transition: color 0.2s ease;
  cursor: pointer;

  &:focus,
  &:hover {
    outline: none;
    color: ${Colors.Blue70};
  }
`

export const SearchField = styled.input`
  display: block;
  width: 100%;
  background: none;
  padding: 13px 68px 11px 44px;
  margin: 0;
  border: none;
  color: ${Colors.Neutral90};

  &::placeholder {
    color: ${Colors.Neutral50};
  }
`

export const SearchSpinner = styled(Icon24.Spinner)`
  position: absolute;
  top: 12px;
  right: 44px;
  color: ${Colors.Neutral40};
  animation: ${rotation} 0.5s infinite linear;
`

export const Content = styled.div``

export const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 4px 12px;
  border-bottom: 1px solid ${Colors.Neutral15};

  &:last-child {
    border-bottom: none;
  }
`

export const Tag = styled.div<{ isActive: boolean }>`
  ${Typography.Caption};
  margin: 4px;
  padding: 4px 8px;
  border-radius: 3px;
  background-color: ${Colors.Neutral10};
  color: ${Colors.Neutral60};
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background-color: ${Colors.Blue10};
    color: ${Colors.Blue50};
  }

  ${props =>
    props.isActive &&
    css`
      background-color: ${Colors.Blue50};
      color: ${Colors.White};
      pointer-events: none;
    `}
`

export const Note = styled.div`
  ${Typography.Caption};
  padding: 12px 16px;
  border-bottom: 1px solid ${Colors.Neutral15};
  color: ${Colors.Neutral50};

  &:last-child {
    border-bottom: none;
  }
`

export const Scroll = styled.div`
  overflow-y: scroll;
  max-height: 300px;

  &::-webkit-scrollbar {
    width: 12px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${Colors.Neutral20};
    border: 4px solid ${Colors.White};
    border-radius: 6px;

    &:hover {
      background-color: ${Colors.Neutral30};
    }
  }
`

export const Group = styled.div`
  border-bottom: 1px solid ${Colors.Neutral15};
  height: auto;

  &:last-child {
    border-bottom: none;
  }
`

export const GroupTitle = styled.div<{ groupType?: 'most_popular' }>`
  ${Typography.Caps};
  padding: 8px 16px 6px;
  color: ${Colors.Neutral50};
  text-transform: uppercase;

  ${props =>
    props.groupType === 'most_popular' &&
    css`
      color: ${Colors.Amber40};
    `}

  svg {
    margin-right: 8px;
  }
`

const ItemBase = styled.div<{ hasSubphrases: boolean }>`
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 6px 16px;

  ${props =>
    props.hasSubphrases &&
    css`
      align-items: flex-start;
      ${Typography.Caption};
    `}

  &:last-child {
    padding-bottom: 16px;
  }
`

export const Item = styled(ItemBase)`
  cursor: ${props => (props.hasSubphrases ? 'default' : 'pointer')};
  transition: color 0.15s;

  &.is-phrase-hover {
    color: ${Colors.Blue50};
  }

  &.is-phrase-added {
    color: ${Colors.Neutral50};
    pointer-events: none;

    mark {
      color: ${Colors.Neutral50};
    }
  }

  &:focus {
    outline: none;
    color: ${Colors.Blue50};

    &.is-phrase-added {
      color: ${Colors.Neutral50};
    }
  }
`

export const ItemText = styled.div`
  flex-grow: 1;

  & b {
    font-weight: 600;
  }
`

export const ItemSubphrase = styled.a`
  position: relative;
  border-radius: 0 3px 3px 0;
  margin-left: -3px;
  padding: 1px 3px;

  transition: color 0.2s, background-color 0.2s;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:focus,
  &:hover,
  .is-phrase-hover &,
  .is-phrase-hover &.is-subphrase-added {
    color: ${Colors.Blue50};
  }

  &:focus,
  &:hover {
    background-color: ${Colors.Blue10};
    /* Prevent hover loss when cursor is between lines */
    border-top: 2px solid ${Colors.White};
    border-bottom: 2px solid ${Colors.White};
  }

  &:active {
    color: ${Colors.Neutral50};
    background-color: ${Colors.Neutral10};
  }

  &.is-subphrase-added {
    color: ${Colors.Neutral50};
    pointer-events: none;
  }
`

export const ItemSubphraseIcon = styled.span`
  position: absolute;
  top: 0;
  left: -10px;
  width: 10px;
  height: 18px;
  background-image: url(${require('images/builder/phrase-suggestion/subphrase.svg')});
  opacity: 0;
  transition: opacity 0.2s;

  ${ItemSubphrase}:focus &,
  ${ItemSubphrase}:hover & {
    opacity: 1;
  }
`

// Glue the blue icon and the first subphrase word together by `&nbsp;`.
// The icon stays on a previous line without this hack.
export const ItemSubphraseJoiner = styled.span`
  font-size: 0;
  line-height: 0;
  opacity: 0;
`

export const ItemSubphraseDivider = styled.span`
  padding: 0 3px;
  margin-right: 3px;
  color: ${Colors.Neutral40};

  &:last-child {
    display: none;
  }
`

const ItemIconsBase = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin-right: 16px;
  border-radius: 50%;
`

export const ItemIcons = styled(ItemIconsBase)`
  padding: 6px;
  background-color: ${Colors.Neutral10};
  color: ${Colors.Neutral50};
  transition: color 0.2s, background-color 0.2s;
  will-change: color, background-color;
  cursor: pointer;

  .is-phrase-hover & {
    background-color: ${Colors.Blue10};
    color: ${Colors.Blue50};
  }

  .is-phrase-added & {
    background-color: #e3f6ec;
    color: ${Colors.Green50};
  }
  & svg {
    pointer-events: none;
  }
`

export const ItemArrowIcon = styled(Icon20.ArrowLeft)`
  display: block;
  will-change: opacity, transform;
  transition-property: transform, opacity;
  transition-duration: 0.2s;

  .is-phrase-added & {
    transform: translateX(-10px);
    opacity: 0;
  }
`

export const ItemCheckIcon = styled(Icon20.Tick)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px 0 0 -10px;
  opacity: 0;
  transform: scale(0.6);
  will-change: opacity, transform;
  transition-property: transform, opacity;
  transition-duration: 0.2s;
  transition-delay: 0.1s;

  .is-phrase-added & {
    transform: scale(1);
    opacity: 1;
  }
`

export const PlaceholderItem = styled(ItemBase)``

export const PlaceholderItemIcons = styled(ItemIconsBase)`
  ${Mixins.placeholderBackground};
`

export const PlaceholderText = styled.div`
  display: inline-block;
  width: 100%;
  height: 1em;
  border-radius: 3px;
  ${Mixins.placeholderBackground};
  vertical-align: middle;

  &:last-child {
    width: 50%;
  }
`
