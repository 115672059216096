import times from 'lodash/times'
import * as Styles from './styles'

export const SkeletonRows = () => (
  <Styles.SkeletonAtomsRows>
    {times(3).map(key => (
      <Styles.SkeletonRow key={key} />
    ))}
  </Styles.SkeletonAtomsRows>
)
