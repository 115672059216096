import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'

export const FooterContainer = styled.div`
  width: 100%;
  margin-top: 122px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;

  ${Media.Tablet`
    margin-bottom: 20px;
    margin-top: 20px ;
  `};

  ${Media.Phone`
    margin-bottom: 32px;
  `};
`

export const Footer = styled.div`
  ${Typography.Tiny};

  width: 544px;
  text-align: center;
  margin-bottom: 24px;
  color: ${Colors.Neutral50};

  & a {
    color: ${Colors.Blue50};
  }

  & a:hover {
    color: ${Colors.Blue70};
  }

  ${Media.Tablet`
    margin-bottom: 20px;
  `};

  ${Media.Phone`
    width: 335px;
    margin-bottom: 32px;
  `};

  @media (max-width: 374px) {
    width: calc(100% - 32px);
  }
`
