import { Accomplishment } from 'builder/modules/sevenStories'
import { AddAccomplishmentContainer, AddIcon, AddTitle } from './styles'

type Props = {
  accomplishmentData: Accomplishment[]
  handleAddAccomplishment: () => void
  maxAccomplishmentsLength: number
}

const AddAccomplishments = ({
  handleAddAccomplishment,
  accomplishmentData,
  maxAccomplishmentsLength,
}: Props) => {
  return (
    <>
      {accomplishmentData.length < maxAccomplishmentsLength ? (
        <AddAccomplishmentContainer onClick={handleAddAccomplishment}>
          <AddIcon />
          <AddTitle>Add an accomplishment</AddTitle>
        </AddAccomplishmentContainer>
      ) : null}
    </>
  )
}

export default AddAccomplishments
