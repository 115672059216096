import styled from 'styled-components'
import Media from 'builder/styles/media'

export const Container = styled.div`
  max-width: 1120px;
  width: calc(100% - 20px);
  gap: 32px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-top: 40px;
  position: relative;

  ${Media.Phone`
    gap: 16px;
    padding-top: 24px;
  `}
`
