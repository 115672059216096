import { Menu, MenuItem, MenuItemContent } from 'builder/components/Menu'
import { ISubMenu } from 'builder/features/navigation'

interface IProps {
  items: ISubMenu[]
  onClickLink?: (link: string) => void
}

export const SubMenu = ({ items, onClickLink }: IProps) => {
  return (
    <Menu>
      {items.map(item => (
        <MenuItem key={item.text}>
          <MenuItemContent
            to={item.to}
            open={true}
            href={item.href}
            active={item.active}
            isPostPremium={item.isPostPremium}
            endDecoration={item.endDecoration}
            startDecoration={item.startDecoration}
            eventParam={item.eventParam}
            onClickLink={onClickLink}
          >
            {item.text}
          </MenuItemContent>
        </MenuItem>
      ))}
    </Menu>
  )
}
