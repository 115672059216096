import { useState, useEffect, useRef, useCallback } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useDispatch } from 'react-redux'
import _debounce from 'lodash/debounce'
import { Icon24 } from 'builder/components/Icon'
import { useClickOutside } from 'builder/hooks/useClickOutside'
import { actions } from 'builder/modules/resumeEditor'
import { i18n as I18n } from 'builder/utils/i18n'
import { UseExampleEvents, UseExampleEventLabels } from 'builder/modules/constants'
import {
  JobTitleSearchContainer,
  JobTitleSearch,
  SuggestedTitlesContainer,
  SuggestedTitleList,
  SuggestedTitleItem,
  NoResultsContainer,
  NoResults,
  NoResultsImg,
  NoResultsText,
  AnotherOptionBtn,
} from '../styles'
import { useResumeExampleContext } from '../ResumeExampleContext'
import { ResumeExample } from '../types'
import emptySearch from './empty-search.svg'

const JobTitle = () => {
  const dispatch = useDispatch()

  const {
    jobTitles,
    selectedJobTitle,
    setSelectedJobTitle,
    resumeExamples,
    setSelectedResumeExample,
  } = useResumeExampleContext()

  const [searchedTitle, setSearchedTitle] = useState<string>('')
  const [suggestedTitles, setSuggestedTitles] = useState<string[]>(jobTitles)
  const [showSuggestedTitles, setShowSuggestedTitles] = useState<boolean>(false)

  const ref = useRef<HTMLDivElement>(null)

  useClickOutside(ref, () => setShowSuggestedTitles(false))

  const trackEmptySearchEvent = (searchedTitle: string) => {
    trackInternalEvent(UseExampleEvents.seeEmptySearchResults, {
      label: UseExampleEventLabels.examplesPrefill,
      searchQuery: searchedTitle,
    })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedTrackEmptySearchEvent = useCallback(_debounce(trackEmptySearchEvent, 1000), [])
  useEffect(() => {
    setSuggestedTitles(
      jobTitles.filter(title => title.toLowerCase().includes(searchedTitle.toLowerCase().trim())),
    )
  }, [searchedTitle, jobTitles])

  useEffect(() => {
    if (searchedTitle && !suggestedTitles.length) {
      debouncedTrackEmptySearchEvent(searchedTitle)
    }
  }, [searchedTitle, suggestedTitles.length, debouncedTrackEmptySearchEvent])

  const onTitleChange = (e: React.BaseSyntheticEvent) => {
    setSearchedTitle(e.target.value)
    if (selectedJobTitle) {
      setSelectedJobTitle('')
    }
  }

  const onSelectTitle = (title: string) => {
    trackInternalEvent(UseExampleEvents.clickSearchResult, {
      label: UseExampleEventLabels.examplesPrefill,
      jobTitle: title,
    })
    const resumeSelected = resumeExamples.find(ex => ex.jobTitle === title) as ResumeExample
    setSelectedResumeExample(resumeSelected)
  }

  const onStartWithAnotherOption = () => {
    trackInternalEvent(UseExampleEvents.clickStartWithAnotherOption, {
      label: UseExampleEventLabels.examplesPrefill,
    })
    dispatch(actions.setShowUseExamplePopup(false))
    dispatch(actions.setShowPrefillResumeModal(true))
  }

  return (
    <JobTitleSearchContainer ref={ref}>
      <Icon24.Search />
      <JobTitleSearch
        placeholder={I18n.t('builder.use_example.job_title_placeholder')}
        value={selectedJobTitle || searchedTitle}
        onChange={onTitleChange}
        onFocus={() => {
          trackInternalEvent(UseExampleEvents.clickSearchBar, {
            label: UseExampleEventLabels.examplesPrefill,
          })
          setShowSuggestedTitles(true)
        }}
      />
      {showSuggestedTitles && (
        <SuggestedTitlesContainer>
          <SuggestedTitleList>
            {suggestedTitles.map((title, index) => (
              <SuggestedTitleItem key={`${title} ${index}`} onClick={() => onSelectTitle(title)}>
                {title}
              </SuggestedTitleItem>
            ))}
          </SuggestedTitleList>
        </SuggestedTitlesContainer>
      )}
      {searchedTitle && !suggestedTitles.length && (
        <NoResultsContainer>
          <NoResults>
            <NoResultsImg src={emptySearch} />
            <NoResultsText>
              {I18n.t('builder.use_example.no_results.role_not_found')}{' '}
              <AnotherOptionBtn theme="ghost" onClick={onStartWithAnotherOption}>
                {I18n.t('builder.use_example.no_results.another_option')}
              </AnotherOptionBtn>
            </NoResultsText>
          </NoResults>
        </NoResultsContainer>
      )}
    </JobTitleSearchContainer>
  )
}

export default JobTitle
