import { useEffect, useRef, useState } from 'react'
import { throttle } from 'lodash'
import { trackInternalEvent } from '@rio/tracking'
import { useDispatch } from 'react-redux'
import {
  CHANGE_CAREER,
  EXCEL_AT_JOB,
  FIND_NEW_JOB,
  INDEXES_TABS,
  TABS_INDEXES,
} from 'builder/components/CIO-Dasboard-2.0/ActionBanner/ActionBanner'
import { useI18n } from 'builder/hooks/useI18n'
import useWebsiteHost from 'builder/components/CIO-Dasboard-2.0/hooks/useWebsiteHost'
import MobileTabsMenu from 'builder/components/CIO-Dasboard-2.0/Components/MobileTabs/MobileTabsMenu'
import { selectors, actions } from 'builder/modules/panel'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

import {
  ArrowDown,
  ArrowRight,
  ArrowRightBackground,
  TopContainer,
  Tab,
  TabContainer,
  TabInSwitcher,
  TabSwitcher,
  Container,
} from './styles'

export const TabsSwitcherMobile = () => {
  const { i18n } = useI18n()
  const activeTabIndex = useTypedSelector(selectors.activeTabId)
  const [isTabsMenuVisible, setIsTabsMenuVisible] = useState(false)
  const ref = useRef<HTMLDivElement | null>(null)
  const dispatch = useDispatch()
  const { getHost } = useWebsiteHost()

  useEffect(() => {
    const scrollHappened = throttle(() => {
      if (ref.current) {
        if (window.scrollY > 98) {
          ref.current.style.position = 'fixed'
          ref.current.style.bottom = '80px'
        } else {
          ref.current.style.position = 'absolute'
          ref.current.style.bottom = '-18px'
        }
      }
    }, 20)

    window.addEventListener('scroll', scrollHappened, { passive: true })
    return () => window.removeEventListener('scroll', scrollHappened)
  }, [])

  const selectedTab = INDEXES_TABS[activeTabIndex]

  return (
    <Container>
      <TopContainer>
        <TabContainer onClick={() => setIsTabsMenuVisible(true)}>
          <Tab>
            {selectedTab === 'Change Career'
              ? i18n.t('builder.navbar_v2.switch_career')
              : TABS_INDEXES(i18n.t)[selectedTab].text}
          </Tab>
          <ArrowDown />
        </TabContainer>
      </TopContainer>
      <TabSwitcher ref={ref} onClick={() => setIsTabsMenuVisible(true)}>
        <TabInSwitcher>
          {selectedTab === 'Change Career'
            ? i18n.t('builder.navbar_v2.switch_career')
            : TABS_INDEXES(i18n.t)[selectedTab].text}
        </TabInSwitcher>
        <ArrowRightBackground>
          <ArrowRight />
        </ArrowRightBackground>
      </TabSwitcher>
      {isTabsMenuVisible && (
        <MobileTabsMenu
          handleTabClick={index => {
            setIsTabsMenuVisible(false)
            trackInternalEvent('click_dash_tab', {
              label: INDEXES_TABS[index],
              website_host: getHost,
            })
            dispatch(actions.setActiveTabID({ tabId: index }))
          }}
          onClose={() => setIsTabsMenuVisible(false)}
          selectedIndex={activeTabIndex}
          tabsActions={[
            TABS_INDEXES(i18n.t)[FIND_NEW_JOB],
            TABS_INDEXES(i18n.t)[EXCEL_AT_JOB],
            {
              ...TABS_INDEXES(i18n.t)[CHANGE_CAREER],
              text: i18n.t('builder.navbar_v2.switch_career'),
            },
          ]}
        />
      )}
    </Container>
  )
}
