import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'
import { Icon24 } from 'builder/components/Icon'

export const Container = styled.div`
  pointer-events: all;
  max-width: 640px;
  display: flex;
  align-items: center;
  background-color: ${Colors.Neutral100};
  border-radius: 4px;
  padding: 12px 16px 12px 12px;

  ${Media.Phone`
    max-width: 300px;
  `};
`

export const Text = styled.div`
  ${Typography.Caption};
  color: ${Colors.White};
`

export const Icon = styled(Icon24.SnackbarWarning)`
  margin-right: 8px;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
`
