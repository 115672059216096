import { useMemo } from 'react'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { Skeleton, SkeletonContainer, SkeletonTitle } from './styles'

export const NoEvents = ({ title }: { title: string }) => {
  const { isPhone, isTablet } = useMediaQueries()
  const numberOfSkeletons = useMemo(() => (isPhone ? 1 : isTablet ? 2 : 3), [isPhone, isTablet])
  return (
    <SkeletonContainer>
      {new Array(numberOfSkeletons).fill(0).map((el, i) => (
        <Skeleton key={i} />
      ))}
      <SkeletonTitle>{title}</SkeletonTitle>
    </SkeletonContainer>
  )
}
