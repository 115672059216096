import React, { useEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { trackingInfo } from 'builder/modules/careerCoaching/constants'
import { timeZoneOptions } from '../CareerLocation/timeZone'
import CheckURL from './assets/Frame.svg'

import {
  Container,
  Intake,
  Content,
  Header,
  SubHeader,
  ButtonContainer,
  SummaryContainer,
  SummaryCard,
  SummaryCardTitle,
  SummaryCardBadge,
  SummaryCardBadgeContainer,
  SummaryCardHeader,
  EditButton,
  SummaryCardWrapper,
  ViewPackages,
} from './styles'

interface IndustriesProps {
  label: string
  value: string
}

interface IntakeTestProps {
  formData: {
    preferences: {
      services: string[]
      focusTopic: string
      careerStages: string
      jobFunctions: string[]
      availableDays: string[]
      availableTimes: string[]
      industries: IndustriesProps[]
      location: string
      timezone: string
      linkedinUrl: string
    }
  }
  handleEdit: (page: number) => void
  onIntakeSubmit: () => void
  isExecutive?: boolean
}

interface TimeZoneProps {
  id: number
  name: string
  key: string
}

interface TimeSlots {
  [key: string]: string
}

interface MultiDataProps {
  value: number | string
  label: string
}

const IntakeTest: React.FC<IntakeTestProps> = ({
  formData,
  handleEdit,
  onIntakeSubmit,
  isExecutive,
}) => {
  useEffect(() => {
    trackInternalEvent('enter_finish_onboarding_test', { ...trackingInfo })
  }, [])

  // Intake test selcted data maping
  const IntakeData = [
    {
      title: 'Career Stage',
      data: formData?.preferences?.careerStages,
      isMultiple: false,
      step: 1,
    },
    {
      title: 'Services',
      data: formData?.preferences?.services,
      isMultiple: true,
      step: 2,
    },
    {
      title: 'Job Functions',
      data: formData?.preferences?.jobFunctions,
      isMultiple: true,
      step: 4,
    },
    {
      title: 'Industries',
      data: formData?.preferences?.industries,
      isMultiple: true,
      step: 5,
    },
    {
      title: 'Location',
      data: [
        {
          value: formData?.preferences?.location,
          label: formData?.preferences?.timezone,
        },
      ],
      isMultiple: false,
      step: 6,
    },
    {
      title: 'Available Days',
      data: formData?.preferences?.availableDays,
      isMultiple: true,
      step: 7,
    },
    {
      title: 'Available Times',
      data: formData?.preferences?.availableTimes,
      isMultiple: true,
      step: 8,
    },
  ]

  const newOnBoardingIntakeData = [
    {
      title: 'Career Stage',
      data: formData?.preferences?.careerStages,
      isMultiple: false,
      step: 1,
    },
    {
      title: 'Request',
      data: formData?.preferences?.focusTopic,
      isMultiple: false,
      step: 2,
    },
    {
      title: 'LinkedIn',
      data: formData?.preferences?.linkedinUrl,
      isMultiple: false,
      step: 3,
    },
  ]

  const selectedTimes: TimeSlots = {
    '9am-12pm': 'Morning (9am-12pm)',
    '12pm-3pm': 'Afternoon (12pm - 3pm)',
    '3pm-6pm': 'Late afternoon (3pm - 6pm)',
    '6pm-9pm': 'Evening (6pm - 9pm)',
  }

  let selectedTimeZone: TimeZoneProps | undefined

  // Render intake step data
  const renderIntakeData = (step: number, data: string[] | MultiDataProps[] | string) => {
    const stepsWithStringData = isExecutive ? [1, 2, 3] : [1]
    const stepsWithArrayData = [2, 4, 7, 8]
    const stepsWithMultiObjectData = [5, 6]

    if (stepsWithStringData.includes(step)) {
      return <SummaryCardBadge>{data}</SummaryCardBadge>
    } else if (stepsWithArrayData.includes(step)) {
      if (step === 8) {
        return (data as string[])?.map((item: string) => {
          return <SummaryCardBadge key={item}>{selectedTimes[item]}</SummaryCardBadge>
        })
      }

      return (data as string[])?.map((item: string) => {
        return <SummaryCardBadge key={item}>{item}</SummaryCardBadge>
      })
    } else if (stepsWithMultiObjectData.includes(step)) {
      return (data as MultiDataProps[])?.map((item: { value: number | string; label: string }) => {
        if (step === 6) {
          selectedTimeZone = timeZoneOptions.find(
            time => time.name.split(':')[0].trim() === item?.label,
          )

          return (
            <SummaryCardWrapper key={item?.value}>
              {item?.value && <SummaryCardBadge key={item?.value}>{item?.value}</SummaryCardBadge>}
              {selectedTimeZone?.key && (
                <SummaryCardBadge key={item?.label}>{selectedTimeZone?.key}</SummaryCardBadge>
              )}
            </SummaryCardWrapper>
          )
        }
        return <SummaryCardBadge key={item?.label}>{item?.label}</SummaryCardBadge>
      })
    }
  }

  const InTakePlayload = isExecutive ? newOnBoardingIntakeData : IntakeData
  return (
    <Container>
      <Intake src={CheckURL} />
      <Content>
        <Header>You have completed the onboarding!</Header>
        <SubHeader>
          {isExecutive
            ? 'We’ll write you in your email to match the best coach with your availability.'
            : 'Now that we have collected some information, We can match you with coaches based on your   choices and availability.'}
        </SubHeader>
      </Content>
      <ButtonContainer>
        <ViewPackages type="button" onClick={onIntakeSubmit}>
          View Packages
        </ViewPackages>
      </ButtonContainer>

      <SummaryContainer>
        {InTakePlayload?.map(item => {
          return (
            <SummaryCard key={item.step}>
              <SummaryCardHeader>
                <SummaryCardTitle>{item.title}</SummaryCardTitle>
                <EditButton
                  data-testid="edit-button"
                  onClick={() => {
                    handleEdit(item.step)
                  }}
                />
              </SummaryCardHeader>
              <SummaryCardBadgeContainer>
                {renderIntakeData(item.step, item.data)}
              </SummaryCardBadgeContainer>
            </SummaryCard>
          )
        })}
      </SummaryContainer>
    </Container>
  )
}
export default IntakeTest
