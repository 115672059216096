import styled, { css } from 'styled-components'
import Typography from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  max-width: 968px;
  margin: 0 auto;
  padding: 42px 20px 24px;

  ${Media.Laptop`
    max-width: 544px;
  `};

  ${Media.Tablet`
    padding-top: 32px;
  `};

  ${props =>
    props.center &&
    css`
      justify-content: center;
      padding-bottom: 80px;
    `}
`

export const Copyright = styled.div`
  ${Typography.Tiny};
  width: 100%;
  margin: 0 auto;
  padding-top: 57px;
  text-align: center;
  color: ${Colors.Neutral30};

  ${Media.Laptop`
    padding-top: 32px;
  `};

  & a {
    color: ${Colors.Blue50};
  }

  & a:hover {
    color: ${Colors.Blue70};
  }
`
