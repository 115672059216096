export const CareerCoachTitles = [
  'Struggling with your Job Search? Talk to a career coach!',
  'Feeling stuck in your career? Talk to a career coach!',
  "Feeling like it's time for a career change? Talk to a career coach!",
]

export const CareerCoachDescription = [
  'Our professional coaches can get you past the job search blockers, help you land interviews and achieve your career goals.',
  'Our professional coaches will guide you through key decisions to get you unstuck and confidently taking the next steps to achieve your career goals.',
  'Our professional coaches will guide you from unsure to fully confident and committed to your next best career path.',
]
