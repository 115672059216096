import { useMemo } from 'react'
import { Container, Step } from './styles'

interface Props {
  doneStep: number
  totalSteps: number
}
export const Progress = ({ doneStep, totalSteps }: Props) => {
  const steps = useMemo(
    () => Array.from({ length: totalSteps - 1 }, (_, i) => i < doneStep),
    [doneStep, totalSteps],
  )

  return (
    <Container>
      {steps.map((done, i) => (
        <Step key={i} done={done} />
      ))}
    </Container>
  )
}
