import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import { Modal as ModalComponent } from 'builder/views/Interview/components/Modal/Modal'
import Media from 'builder/styles/media'

export const Modal = styled(ModalComponent).attrs({ $width: 544 })`
  padding: 32px;
  gap: 32px;

  ${Media.Phone`
    width: 100%;
    padding: 20px;
  `}
`

export const Title = styled.p`
  ${Typography.S};
  ${FontWeights.DemiBold};
`

export const Content = styled.div`
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
  padding: 12px 0 32px;

  ${Media.Phone`
    padding: 8px 0 20px;
  `}
`

export const Bottom = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`
