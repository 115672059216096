import { memo } from 'react'
import locationIcon from 'builder/components/CoachDetails/components/PersonalDetails/components/img/location.svg'
import { Container, Location, CoachLocationIcon } from './styles'

interface Props {
  location: string
}

const CoachLocation = ({ location }: Props) => {
  return (
    <Container>
      <CoachLocationIcon src={locationIcon} alt="coach location" />
      <Location>{location}</Location>
    </Container>
  )
}

export default memo(CoachLocation)
