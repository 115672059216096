import styled, { css } from 'styled-components'
import { colord } from 'colord'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'
import { Icon24 } from 'builder/components/Icon'

export const Container = styled.div`
  display: block;
`

export const ColorList = styled.div`
  height: 46px;
  display: flex;
  flex-flow: row nowrap;
  user-select: none;
  align-items: center;
`

export const ColorBase = styled.div`
  position: relative;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-tap-highlight-color: transparent;

  color: ${({ color }) =>
    color && colord(color).brightness() > 0.627 ? Colors.Neutral100 : Colors.White};

  &:last-child {
    margin-right: 0;
  }
`

export const Color = styled(ColorBase)`
  background-color: ${({ color }) => color || Colors.Neutral100};
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: calc(50% - 4px);
    left: calc(50% - 4px);
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: currentColor;
    transition: transform 0.1s ease;
    transform: scale(0);
  }

  ${({ color }) =>
    (!color || colord(color).brightness() < 0.295) &&
    css`
      box-shadow: 0 0 0 2px ${hexToRgba(Colors.White, 0.2)} inset;
    `}

  &:hover::before {
    transform: scale(${({ selected }) => (selected ? 0 : 1)});
  }
`

export const DisabledColor = styled(ColorBase)`
  cursor: default;
  background-color: ${Colors.Neutral70};
  color: ${Colors.White};
  box-shadow: none;

  ${Media.Phone`
    border: none;
  `};

  &::before {
    display: none;
  }
`

export const ActiveIcon = styled(Icon24.Tick)`
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  margin: -12px 0 0 -12px;
  transition: transform 0.1s ease;
  transform: scale(1);

  &[data-visible='false'] {
    transform: scale(0);
  }
`

export const Tooltip = styled.div`
  position: absolute;
  z-index: 3;
  width: 140px;
  top: 100%;
  margin-top: 21px;
  left: 50%;
  transform: translate(-50%, 0px);
  padding: 5px 8px;
  border-radius: 3px;
  background-color: ${Colors.Neutral100};
  ${Typography.Tiny};
  text-align: center;
  color: ${Colors.White};
  opacity: 0;
  user-select: none;
  pointer-events: none;
  transition: opacity 0.2s ease, transform 0.2s ease;
  transition-delay: 0ms;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 0 0 2px 0;
    transform: translate(-50%, 50%) rotateZ(45deg);
    background-color: ${Colors.Neutral100};
    bottom: 100%;
    left: 50%;
  }

  ${DisabledColor}:hover & {
    opacity: 1;
    transform: translate(-50%, 0px);
    transition-delay: 0.2s;
  }

  ${({ small }) =>
    small &&
    css`
      width: auto;
      white-space: nowrap;
    `}

  ${Media.Phone`
    display: none;
  `}
`
