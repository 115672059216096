import { useState } from 'react'
import { Button, ButtonSize, ButtonTheme } from 'builder/components/Button'
import { ActionMenu } from 'builder/components/ActionMenu'
import { Icon24 } from 'builder/components/Icon'
import * as ActionGroup from 'builder/components/ActionGroup'
import { Section } from '../../Section'
import { ComponentPreview, Group, Label } from '../../styles'
import { MENU_ACTIONS } from './constants'
import { ButtonsList } from './styles'

export const ButtonsSection = () => {
  const [isMenuOpen, toggleMenu] = useState(false)

  return (
    <Section title="Buttons">
      <Group>
        <Label>Button</Label>
        <ButtonsList>
          {Object.values(ButtonSize).map(size =>
            Object.values(ButtonTheme).map(theme => (
              <Button key={theme} theme={theme} size={size}>
                {theme} {size !== ButtonSize.default ? size : ''}
              </Button>
            )),
          )}
        </ButtonsList>
      </Group>

      <Group>
        <Label>ActionGroup</Label>
        <ComponentPreview>
          <ActionGroup.Container>
            <ActionGroup.Button icon={<Icon24.Google />}>Google Something</ActionGroup.Button>
            <ActionGroup.Button icon={<Icon24.Linkedin />}>Open LinkedIn</ActionGroup.Button>
            <ActionGroup.Button icon={<Icon24.Mail />}>Sign up with Email</ActionGroup.Button>
          </ActionGroup.Container>
        </ComponentPreview>
      </Group>

      <Group>
        <Label>ActionMenu</Label>
        <ComponentPreview style={{ position: 'relative' }}>
          <Button onClick={() => toggleMenu(true)}>Open Menu</Button>
          {isMenuOpen && (
            <ActionMenu
              position="bottom"
              align="left"
              actions={MENU_ACTIONS}
              onClose={() => toggleMenu(false)}
            />
          )}
        </ComponentPreview>
      </Group>
    </Section>
  )
}
