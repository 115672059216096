import { useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import EditorField from 'builder/components/EditorField'
import { useI18n } from 'builder/hooks/useI18n'
import { Store } from 'builder/modules/store'
import { useUpdateSimpleField } from 'builder/hooks/useUpdateSimpleField'
import CustomDatePicker from 'builder/components/DocumentEditor/Japanese/CustomComponent/CustomDatePicker/CustomDatePicker'
import { formatDate } from '../../../../../../../rendering/templates/shared/utils/formatJapaneseDate'

export const SubmissionDate = (): JSX.Element => {
  const { i18n } = useI18n()
  const submissionDate = useSelector(
    (state: Store) => state.resumeEditor.resume?.resumeSubmissionDate,
  )
  const nameRef = useRef('resumeSubmissionDate')
  const { updateSimpleField } = useUpdateSimpleField()
  const updateDateRange = useCallback(
    value => {
      const submissionDate = formatDate(value)
      updateSimpleField({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        name: nameRef.current as any,
        value: submissionDate,
        debounce: true,
      })
    },
    [updateSimpleField],
  )

  return (
    <EditorField>
      <CustomDatePicker
        value={submissionDate || ''}
        onChange={updateDateRange}
        label={i18n.t('builder.resume_editor.submission_date')}
      />
    </EditorField>
  )
}
