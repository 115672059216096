export function downloadFile(url: string, name = 'Document'): void {
  // Create an invisible link
  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = url
  // Sanitize the filename
  a.download = name.replace(/[/\\?%*:|"<>]/g, '')

  document.body.appendChild(a)
  // Start downloading
  a.click()
  // Cleanup
  document.body.removeChild(a)
}
