import { Fragment, useState } from 'react'
import Foldable from 'builder/components/Foldable'
import {
  Line,
  Header,
  OpenIcon,
  Question,
  Container,
  Questions,
  CloseIcon,
  AnswerContent,
  QuestionContent,
  QuestionContainer,
  Footer,
  FAQLink,
} from './styles'

import { FAQS } from './constants'

const FrequentCoachingQuestions = () => {
  const [questionId, setQuestionId] = useState<number>()

  const handleToggle = (id: number) => {
    questionId && questionId > -1 ? setQuestionId(-1) : setQuestionId(id)
  }

  return (
    <Container>
      <Header>Frequently Asked Questions</Header>
      <Questions>
        {FAQS.map(faq => (
          <Fragment key={faq.id}>
            <Question>
              <QuestionContainer onClick={() => handleToggle(faq.id)}>
                <QuestionContent isOpted={questionId === faq.id}>{faq.question}</QuestionContent>
                {questionId === faq.id ? <OpenIcon /> : <CloseIcon />}
              </QuestionContainer>
              <Foldable isOpen={questionId === faq.id}>
                <AnswerContent>{faq.answer}</AnswerContent>
              </Foldable>
            </Question>
            {faq.id < 4 && <Line />}
          </Fragment>
        ))}
      </Questions>
      <Footer>
        {"Didn't find what you're looking for? — "}
        <FAQLink target="_blank" href="https://help.career.io/" rel="noreferrer">
          Use our FAQ
        </FAQLink>
      </Footer>
    </Container>
  )
}

export default FrequentCoachingQuestions
