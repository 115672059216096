import PropTypes from 'prop-types'
import { i18n as I18n } from 'builder/utils/i18n'
import Service from './Service'

import {
  SuccessContainer,
  SuccessHeader,
  SuccessTitle,
  SuccessDescription,
  SuccessServices,
  SuccessBackButton,
} from './styles'

const Success = ({ services, itemTheme, onBack, ...rest }) => {
  const connectedServices = services.filter(service => service.connected)
  const complete = connectedServices.length === services.length
  const title = I18n.t(`builder.coreg.success.${complete ? 'complete_title' : 'title'}`)

  return (
    <SuccessContainer {...rest}>
      <SuccessHeader>
        <SuccessTitle>{title}</SuccessTitle>
        <SuccessDescription>{I18n.t('builder.coreg.success.description')}</SuccessDescription>
      </SuccessHeader>
      <SuccessServices>
        {connectedServices.map(service => (
          <Service {...service} key={service.name} theme={itemTheme} />
        ))}
      </SuccessServices>
      {onBack && (
        <SuccessBackButton onClick={onBack}>
          {I18n.t('builder.coreg.success.back')}
        </SuccessBackButton>
      )}
    </SuccessContainer>
  )
}

Success.propTypes = {
  services: PropTypes.array,
  itemTheme: PropTypes.string,
  onBack: PropTypes.func,
}

Success.defaultProps = {
  itemTheme: 'card',
}

export default Success
