import styled, { css } from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
const { White, Neutral100 } = Colors

export const Topbar = styled.div<{ previewWidth: number }>`
  position: absolute;
  left: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: -60px;
  ${({ previewWidth }) =>
    previewWidth < 450 &&
    css`
      left: 50%;
      margin-left: -225px;
      width: 450px;
    `};
`

export const ButtonContainerRight = styled.div`
  display: flex;
  flex-direction: row;
`
export const Divider = styled.div`
  flex-shrink: 0;
  width: 1px;
  background-color: ${hexToRgba(White)};
  opacity: 0.4;
  margin: 8px;
`

export const SelectTemplateButtonContainer = styled.div`
  position: relative;
`

export const SelectTemplateButton = styled.span`
  padding: 0 12px 0 6px;
  height: 38px;
  border-radius: 19px;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${hexToRgba(White)};
  cursor: pointer;
  background-color: ${hexToRgba(Neutral100, 0)};
  transition: background-color 0.1s ease;

  &:hover {
    background-color: ${hexToRgba(Neutral100, 0.15)};
  }
`

export const SelectTemplateIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
`

export const SelectTemplateText = styled.p`
  ${Typography.Caption};
`
