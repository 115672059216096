import { useMemo } from 'react'
import { createInternalEventHandler } from '@rio/tracking'
import { selectors } from 'builder/modules/init'
import { useI18n } from 'builder/hooks/useI18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import Button from 'builder/components/Button'
import * as PanelStyled from '../styles'
import {
  SectionTitle,
  SectionContent,
  SectionCard,
  SectionCardContent,
  SectionCardImage,
  SectionCardTitle,
  SectionCardText,
} from './styles'

interface Card {
  name: string
  url: string
  imageUrl: string
  title: string
  text: string
  action: string
}

export const PartnersSection = () => {
  const locale = useTypedSelector(selectors.locale)
  const { i18n } = useI18n()
  const topResumeInfo = ((): { url: string; imageUrl: string } => {
    switch (locale) {
      case 'de-DE':
        return {
          url: 'https://topcv.de/lebenslauferstellung?pt=ZbACB82g6oMod&utm_source=resumeio&utm_medium=riowebsitemenu&utm_campaign=tr_promo_in_riomenu&utm_content=v1&utm_term=cvappde',
          imageUrl: require('images/builder/panel/partners/topcv.png'),
        }
      case 'de-AT':
        return {
          url: 'https://topcv.de/lebenslauferstellung?pt=DGqJ2DvwvhqTS&utm_source=resumeio&utm_medium=riowebsitemenu&utm_campaign=tr_promo_in_riomenu&utm_content=v1&utm_term=lebenslaufappat',
          imageUrl: require('images/builder/panel/partners/topcv.png'),
        }
      case 'de-CH':
        return {
          url: 'https://topcv.de/lebenslauferstellung?pt=MWfxEmMaieq5Y&utm_source=resumeio&utm_medium=riowebsitemenu&utm_campaign=tr_promo_in_riomenu&utm_content=v1&utm_term=lebenslaufappch',
          imageUrl: require('images/builder/panel/partners/topcv.png'),
        }
      case 'fr-FR':
        return {
          url: 'https://topcv.fr/redaction-cv?pt=v056MOEaR6X6M&utm_source=resumeio&utm_medium=riowebsitemenu&utm_campaign=tr_promo_in_riomenu&utm_content=v1&utm_term=cvappfr',
          imageUrl: require('images/builder/panel/partners/topcv.png'),
        }
      case 'en-GB':
        return {
          url: 'https://topcv.co.uk/cv-writing?pt=YBVEnUqplL1DW&utm_source=resumeio&utm_medium=riowebsitemenu&utm_campaign=tr_promo_in_riomenu&utm_content=v1&utm_term=resumeiouk',
          imageUrl: require('images/builder/panel/partners/topcv.png'),
        }
      case 'en-NZ':
        return {
          url: 'https://nz.topcv.com/cv-writing?pt=lY1hzm8PWF8fd&utm_source=resumeio&utm_medium=riowebsitemenu&utm_campaign=tr_promo_in_riomenu&utm_content=v1&utm_term=cvappnz',
          imageUrl: require('images/builder/panel/partners/topcv.png'),
        }
      case 'en-IE':
        return {
          url: 'https://topcv.com/cv-writing?pt=hPwrrDN8fLqd7&utm_source=resumeio&utm_medium=riowebsitemenu&utm_campaign=tr_promo_in_riomenu&utm_content=v1&utm_term=cvappie',
          imageUrl: require('images/builder/panel/partners/topcv.png'),
        }
      default:
        return {
          url: 'https://topresume.com/resume-writing?utm_source=resumeio&utm_medium=iocustomerdash&utm_campaign=tr-resumewritingoffer-resumeiodash&utm_content=ordernow-cta',
          imageUrl: require('images/builder/panel/partners/topresume.png'),
        }
    }
  })()

  const cards: Card[] = useMemo(() => {
    const cardList = [
      {
        name: 'top_resume',
        url: topResumeInfo.url,
        imageUrl: topResumeInfo.imageUrl,
        title: i18n.t('builder.panel.partners.topresume.title'),
        text: i18n.t('builder.panel.partners.topresume.text'),
        action: i18n.t('builder.panel.partners.topresume.action'),
      },
    ]

    // replace Resume Rabbit by Recruit Robin card only for NL locale
    if (locale === 'nl-NL') {
      cardList.push({
        name: 'recruit_robin',
        url: 'https://www.recruitrobin.com/',
        imageUrl: require('images/builder/panel/partners/recruit_robin.png'),
        title: 'Deel je cv in 1 klik met 3 vacaturesites',
        text: `Dan kunnen duizenden bedrijven je vinden en benaderen. Helemaal gratis.`,
        action: 'Deel mijn CV',
      })
    } else {
      cardList.push({
        name: 'resume_rabbit',
        url: 'https://www.resumerabbit.com/',
        imageUrl: require('images/builder/panel/partners/resumerabbit.png'),
        title: i18n.t('builder.panel.partners.resumerabbit.title'),
        text: i18n.t('builder.panel.partners.resumerabbit.text'),
        action: i18n.t('builder.panel.partners.resumerabbit.action'),
      })
    }

    return cardList
  }, [i18n, locale, topResumeInfo.imageUrl, topResumeInfo.url])

  return (
    <PanelStyled.Section>
      <SectionTitle>{i18n.t('builder.panel.partners.title')}</SectionTitle>
      <SectionContent>
        {cards.map(card => (
          <SectionCard
            key={card.name}
            href={card.url}
            target="_blank"
            rel="noreferrer noopener"
            onClick={createInternalEventHandler('click_partner_link', { partner: card.name })}
          >
            <SectionCardImage src={card.imageUrl} alt={card.title} />
            <SectionCardContent>
              <SectionCardTitle>{card.title}</SectionCardTitle>
              <SectionCardText>{card.text}</SectionCardText>
              <Button>{card.action}</Button>
            </SectionCardContent>
          </SectionCard>
        ))}
      </SectionContent>
    </PanelStyled.Section>
  )
}
