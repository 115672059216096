import React from 'react'
import { TooltipRenderProps } from 'react-joyride'

import { useI18n } from 'builder/hooks/useI18n'

import {
  Actions,
  NextButton,
  SkipButton,
  Steps,
  TooltipBody,
  TooltipContent,
  TooltipFooter,
  TooltipTitle,
  TooltipSubHeader,
} from './styles'

interface IProps {
  skipButtonLabel: string
  nextButtonLabel: string
  finalButtonLabel: string
  isStepsCountEnabled?: boolean
  showSkipOnLast?: boolean
}

export const Tooltip: React.FC<TooltipRenderProps & IProps> = ({
  step,
  size,
  index,
  isLastStep,
  skipProps,
  closeProps,
  primaryProps,
  tooltipProps,
  skipButtonLabel,
  nextButtonLabel,
  finalButtonLabel,
  isStepsCountEnabled = true,
  showSkipOnLast = false,
}) => {
  const { i18n } = useI18n()

  return (
    <TooltipBody {...tooltipProps} style={{ ...step.styles.tooltipContainer, textAlign: 'start' }}>
      <TooltipContent>
        {step.title && <TooltipTitle>{step.title}</TooltipTitle>}
        <TooltipSubHeader>{step.content}</TooltipSubHeader>
      </TooltipContent>
      <TooltipFooter>
        {isStepsCountEnabled && (
          <Steps>
            {index + 1} {i18n.t('builder.dashboard_v2.steps.progress.of')} {size}
          </Steps>
        )}
        <Actions>
          {(!isLastStep || showSkipOnLast) && (
            <SkipButton {...skipProps}>{skipButtonLabel}</SkipButton>
          )}
          {!isLastStep && <NextButton {...primaryProps}>{nextButtonLabel}</NextButton>}
          {isLastStep && <NextButton {...closeProps}>{finalButtonLabel}</NextButton>}
        </Actions>
      </TooltipFooter>
    </TooltipBody>
  )
}
