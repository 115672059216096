import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import { Typography, FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import { IconBoxProps, BarProps } from './types'

export const Container = styled.div`
  ${Typography.S};
  ${FontWeights.Medium};
  text-align: center;
  position: relative;
  margin-bottom: 2px;
  padding: 0 32px;
`

export const Input = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0;
  margin: 0;
  border: none;
  font: inherit;
  line-height: inherit;
  text-align: center;
  caret-color: ${Colors.Blue50};

  &::placeholder {
    color: ${Colors.Neutral50};
  }
`

export const IconBox = styled.div<IconBoxProps>(({ isEditIconVisible }) => {
  return css`
    color: ${Colors.Neutral40};
    position: absolute;
    left: 100%;
    margin-left: 2px;
    top: 50%;
    margin-top: -11px;
    width: 24px;
    height: 24px;
    visibility: hidden;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease, visibility 0.3s ease;

    ${Media.Hover`
    &:hover {
      color: ${Colors.Blue50};
      opacity: 1;
      visibility: visible;
    }

    ${Container}:hover & {
      visibility: visible;
      opacity: 1;
    }
  `};

    & svg {
      display: block;
    }

    ${isEditIconVisible &&
    css`
      opacity: 1;
      visibility: visible;
    `}
  `
})

export const Panel = styled.div`
  display: inline-block;
  position: relative;
  max-width: 100%;
  vertical-align: top;
`

export const TextBox = styled.div`
  display: inline-block;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  height: 34px;
  vertical-align: top;
`

export const Text = styled.div`
  max-width: 100%;
  white-space: pre;
  min-width: 3.2em;
  display: inline-block;
  text-align: center;
  visibility: hidden;
  pointer-events: none;
`

export const Bar = styled.div<BarProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: ${Colors.Blue50};
  pointer-events: none;
  transition: opacity 0.1s ease, transform 0.1s ease;

  ${props => {
    switch (props.in) {
      case 'entering':
        return css`
          opacity: 1;
          transform: scaleX(1);
        `
      case 'entered':
        return css`
          opacity: 1;
          transform: scaleX(1);
        `
      case 'exiting':
        return css`
          opacity: 0;
          transform: scaleX(1);
        `
      case 'exited':
        return css`
          opacity: 0;
          transform: scaleX(0.5);
        `
      default:
        return ''
    }
  }};
`
