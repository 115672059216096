import ModalOverlay from 'builder/components/ModalOverlay'
import Button, { ButtonTheme } from 'builder/components/Button'
import { ModalContainer, ModalTitle, CloseIcon, Header, ModalButtonContainer } from '../style'

type Props = {
  handleCloseConfirmModal: () => void
  handleSubmitConfirmModal: () => void
}

const ConfirmModal = ({ handleCloseConfirmModal, handleSubmitConfirmModal }: Props) => {
  return (
    <ModalOverlay
      overlayFadeDuration={150}
      contentSlideDuration={0}
      onClose={handleCloseConfirmModal}
    >
      <ModalContainer>
        <Header>
          <ModalTitle>Are you sure you want to proceed?</ModalTitle>
          <CloseIcon onClick={handleCloseConfirmModal} />
        </Header>
        <div>
          You have less than seven accomplishments selected. To get a comprehensive result, we
          encourage having seven accomplishments.
        </div>
        <ModalButtonContainer>
          <Button theme={ButtonTheme.ghost} onClick={handleCloseConfirmModal}>
            Cancel
          </Button>
          <Button onClick={handleSubmitConfirmModal}>Confirm</Button>
        </ModalButtonContainer>
      </ModalContainer>
    </ModalOverlay>
  )
}

export default ConfirmModal
