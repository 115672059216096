import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { TABS, actions, selectors, Label } from 'builder/modules/careerPath2'

import Loader from '../Loader'
import Header from './components/Header'
import Salary from './components/Salary'
import Feedback from './components/Feedback'
import { Container, InnerContainer } from './styles'

const SalaryRange = () => {
  const dispatch = useDispatch()
  const activeTab = useTypedSelector(selectors.activeTab)
  const isSalaryFailed = useTypedSelector(selectors.isSalaryFailed)
  const isSalaryLoaded = useTypedSelector(selectors.isSalaryLoaded)
  const isSalaryLoading = useTypedSelector(selectors.isSalaryLoading)
  const isExploreCareers = useTypedSelector(selectors.isExploreCareers)
  const selectedJobTitle = useTypedSelector(selectors.selectedJobTitle)
  const exploreCareersOccupation = useTypedSelector(selectors.exploreCareersOccupation)
  const { averageSalary, minimumSalary, maximumSalary } = useTypedSelector(selectors.salaryDetails)

  const label = isExploreCareers ? 'explore_careers' : Label.label
  const jobTitle = !isExploreCareers ? selectedJobTitle : exploreCareersOccupation

  const handleExploreMore = () => {
    dispatch(actions.setActiveTab(TABS[1]))
    window.scrollTo({ top: 0, behavior: 'smooth' })

    trackInternalEvent('click_explore_more_salary', { label })
  }

  if (isSalaryFailed) {
    return null
  }

  return (
    <Container>
      <Header
        Tabs={TABS}
        title="Salary Range"
        activeTab={activeTab}
        onExplore={handleExploreMore}
        isSalaryLoaded={isSalaryLoaded}
      />
      {isSalaryLoading ? (
        <Loader />
      ) : (
        <InnerContainer>
          <Salary {...{ averageSalary, minimumSalary, maximumSalary, label, jobTitle }} />
          <Feedback {...{ averageSalary }} />
        </InnerContainer>
      )}
    </Container>
  )
}

export default SalaryRange
