import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
`

export const Heading = styled.div`
  ${Typography.S};
  ${FontWeights.Medium};
`

export const Description = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
  color: ${Colors.Neutral50};
`
