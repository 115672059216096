import { Fragment } from 'react'
import MultiSelect from '../MultiSelect'
import { SkillsText, FieldContainer, FieldsContainer, SkillsTextContainer } from '../../styles'

interface Industries {
  label: string
  value: string
}
interface InputFieldProps {
  onChange: (industries: Industries[]) => void
}

const InputField = ({ onChange }: InputFieldProps) => {
  return (
    <Fragment>
      <SkillsTextContainer>
        <SkillsText>Industries</SkillsText>
      </SkillsTextContainer>
      <FieldsContainer>
        <FieldContainer>
          <MultiSelect onChange={onChange} />
        </FieldContainer>
      </FieldsContainer>
    </Fragment>
  )
}

export default InputField
