import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Button from 'builder/components/Button'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  width: 100%;
  border-radius: 16px;
  padding: 60px 64px;
  color: ${Colors.Green80};
  background-color: ${Colors.Green10};
  align-self: center;

  ${Media.Tablet`
    width: calc(100% - 16px);
  `}

  ${Media.Phone`
    padding: 32px 20px;
  `}
`

export const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${Media.Phone`
    flex-direction: column;
  `}
`

export const Content = styled.div`
  max-width: 558px;
  order: 1;

  ${Media.Phone`
    order: 2;
    text-align: center;
  `}
`

export const Header = styled.div`
  ${Typography.S};
  ${FontWeights.DemiBold};
  margin-bottom: 12px;

  ${Media.Phone`
    margin-bottom: 8px;
  `}
`

export const Paragraph = styled.p`
  margin-bottom: 32px;

  ${Media.Phone`
    ${Typography.Caption};
    margin-bottom: 20px;
  `}
`

export const Image = styled.img``

export const StyledButton = styled(Button)`
  background-color: ${Colors.Green80};

  ${Media.Phone`
    width: 100%;
  `}

  &:hover,
  &:active,
  &:focus-visible {
    background-color: ${Colors.Green80};
  }
`

export const ImageWrapper = styled.div`
  padding: 0 93px 0 58px;
  order: 2;

  ${Media.Tablet`
    padding: 12px 41px;
  `}

  ${Media.Phone`
    order: 1;
    padding: 0;
    margin-bottom: 12px;
  `}
`
