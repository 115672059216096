import Colors from 'builder/styles/colors'
import { Circle, PercentageText, ProgressText, ScoreText } from './styles'

interface Props {
  score: number
}

const ProgressBar = ({ score }: Props) => {
  return (
    <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="48" cy="48" r="46.5" stroke={Colors.Blue20} strokeWidth="3" fill="none" />
      <ProgressText x="50" y="48" textAnchor="middle" dominantBaseline="central">
        <ScoreText>{score}</ScoreText>
        <PercentageText dy="6" dx="2">
          %
        </PercentageText>
      </ProgressText>
      <Circle
        d="M48 1.5C59.3232 1.5 70.2573 5.63166 78.751 13.1198C87.2447 20.608 92.7142 30.9381 94.1333 42.172"
        stroke={Colors.Blue50}
        strokeWidth="3"
        fill="none"
        score={score}
        cx="48"
        cy="48"
        r="46.5"
        transform="rotate(-90 48 48)"
        circleCir={Math.PI * 2 * 46.5}
      />
    </svg>
  )
}

export default ProgressBar
