import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useCallback, useEffect, useState } from 'react'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { actions } from 'builder/modules/resumeEditor'
import { InputChangeEvent } from 'builder/modules/ui'
import MobileEditView from 'builder/components/ResumeScore/JobTitleSection/EditJobDetails/MobileEditView'

const EditAIJobDetails = () => {
  const { isPhone } = useMediaQueries()
  const dispatch = useDispatch()
  const isEditJobDetails = useTypedSelector(state => state.resumeEditor.isEditJobDetails)
  const AICoverLetter = useTypedSelector(state => state.resumeEditor.AICoverLetter)
  const { aICVEditedJobTitle, aICVEditedCompanyName } = AICoverLetter
  const resumeScoreAPIData = useTypedSelector(
    state => state.resumeEditor.optimizerSection.resumeScoreAPIData,
  )
  const { suggestedJobTitle, employerName } = resumeScoreAPIData
  const isAICVEditedCompanyNameEmpty = !aICVEditedCompanyName || aICVEditedCompanyName.length === 0
  const isAICVEditedJobTitleEmpty = !aICVEditedJobTitle || aICVEditedJobTitle.length === 0

  const [newJobTitle, setNewJobTitle] = useState(
    isAICVEditedJobTitleEmpty ? suggestedJobTitle : aICVEditedJobTitle,
  )
  const [newEmployerName, setNewEmployerName] = useState(
    isAICVEditedCompanyNameEmpty ? employerName : aICVEditedCompanyName,
  )

  const handleEditJobTitleDetails = useCallback(() => {
    dispatch(actions.setAICVEditedJobTitle(newJobTitle))
    dispatch(actions.setAICVEditedCompanyName(newEmployerName))
    dispatch(actions.setIsClickedOnAiCVEditModalIcon(false))
    dispatch(actions.setOpenAICVOptimizerModal(true))
    trackInternalEvent('click_save_changes', { label: 'job_posting_result_screen' })
  }, [dispatch, newEmployerName, newJobTitle])

  const handleCancelJobDetails = () => {
    dispatch(actions.setIsClickedOnAiCVEditModalIcon(false))
    dispatch(actions.setOpenAICVOptimizerModal(true))
  }

  const handleJobTitleChange = (e: InputChangeEvent) => {
    setNewJobTitle(e.target.value)
  }

  const handleCompanyNameChange = (e: InputChangeEvent) => {
    setNewEmployerName(e.target.value)
  }

  useEffect(() => {
    if (isEditJobDetails && isPhone) {
      trackInternalEvent('click_edit_menu_resume_optimizer')
    } else if (isEditJobDetails) {
      trackInternalEvent('click_edit_job_title_resume_optimizer')
    }
  }, [isEditJobDetails, isPhone])

  const isSaveButtonDisabled = !newJobTitle || !newEmployerName

  return (
    <MobileEditView
      newJobTitle={newJobTitle}
      newEmployerName={newEmployerName}
      isSaveButtonDisabled={isSaveButtonDisabled}
      onJobTitleChange={handleJobTitleChange}
      onCompanyNameChange={handleCompanyNameChange}
      onSaveJobDetails={handleEditJobTitleDetails}
      onCancelJobDetails={handleCancelJobDetails}
      onCancelEditJobDetails={handleCancelJobDetails}
    />
  )
}

export default EditAIJobDetails
