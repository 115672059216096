import { IVisualProps } from 'builder/components/CIO-Dasboard-2.0/types'
import CompaniesIconsStick from 'builder/components/CIO-Dasboard-2.0/Components/ServiceCard/Components/Visuals/OptimizeResumeVisual/CompaniesIconsStick'
import addIcon from 'builder/components/CIO-Dasboard-2.0/assets/Add.svg'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/panel'
import { DocumentTypes } from 'builder/modules/constants'
import AiTag from '../../Tags/AiTag'
import { OptimizeScore } from './OptimizeScore/OptimizeScore'
import * as Styled from './styles'
import Preview from './Preview'
import CreateDocument from './CreateDocument'

export const OptimizeResumeVisual = ({
  colorScheme,
  isMouseOver,
  isShowAdd,
  isShowIcons,
  isShowScore,
  isShowResume,
  isCoverLetter,
}: IVisualProps) => {
  const resume = useTypedSelector(state => selectors.lastCreated(state, DocumentTypes.resume))
  const letter = useTypedSelector(state => selectors.lastCreated(state, DocumentTypes.coverLetter))
  const latestDocument = useTypedSelector(selectors.documents)[0]

  const doc = isShowResume ? resume : letter || null

  return (
    <Styled.VisualContainer>
      <AiTag isMouseOver={isMouseOver} colorScheme={colorScheme} isCoverLetter={isCoverLetter} />
      <Styled.VisualImageContainer isCreateCoverLetter={isShowAdd}>
        {doc ? (
          <Preview document={doc} />
        ) : (
          <CreateDocument type={isShowResume ? DocumentTypes.resume : DocumentTypes.coverLetter} />
        )}
      </Styled.VisualImageContainer>
      {isShowIcons && (
        <Styled.CompaniesIconsStickContainer>
          <CompaniesIconsStick />
        </Styled.CompaniesIconsStickContainer>
      )}
      {isShowScore && (
        <Styled.ScoreContainer>
          {resume && <OptimizeScore document={latestDocument} />}
        </Styled.ScoreContainer>
      )}
      {isShowAdd && (
        <Styled.AddIconContainer>
          <Styled.AddIconImage src={addIcon} alt="Add" />
        </Styled.AddIconContainer>
      )}
    </Styled.VisualContainer>
  )
}
