import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { formatDocumentPreviewUrl } from 'builder/utils/formatDocumentPreviewUrl'
import placeholder from 'images/builder/resume-editor/resume-loader.svg'

import { PreviewContainer, PreviewInner, PreviewImage } from './styles'

const Preview = ({ resume, ...rest }) => {
  const src = useMemo(() => {
    if (!resume) return placeholder

    return formatDocumentPreviewUrl(resume, { size: 800 })
  }, [resume])

  return (
    <PreviewContainer {...rest}>
      <PreviewInner>
        <PreviewImage src={src} />
      </PreviewInner>
    </PreviewContainer>
  )
}

Preview.propTypes = {
  resume: PropTypes.object,
}

export default Preview
