import React from 'react'
import { DrawerContainer } from './styles'

type Ref = React.ForwardedRef<HTMLDivElement>

interface IProps {
  width?: string
  isOpen?: boolean
  isSubMenu?: boolean
  className?: string
  children: React.ReactNode
  [x: string]: unknown
}

const Drawer = React.forwardRef((props: IProps, ref: Ref) => {
  return (
    <DrawerContainer
      ref={ref}
      className={props.className}
      width={props.width}
      isOpen={props.isOpen}
      isSubMenu={props.isSubMenu}
      {...props}
    >
      {props.children}
    </DrawerContainer>
  )
})

export default Drawer
