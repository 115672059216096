import React from 'react'

import { Container, Title, Button, LogoContainer } from './styles'

type Props = {
  logoSrc: string
  title: string
  buttonText: string
  buttonOnClick: (event: React.MouseEvent) => void
  backgroundColor?: string
}

const ActionItem: React.FC<Props> = ({
  logoSrc,
  title,
  buttonText,
  buttonOnClick,
  backgroundColor,
}: Props) => {
  return (
    <Container backgroundColor={backgroundColor} onClick={buttonOnClick}>
      <LogoContainer>
        <img src={logoSrc} />
      </LogoContainer>
      <Title>{title}</Title>
      <Button backgroundColor={backgroundColor}>{buttonText}</Button>
    </Container>
  )
}

export default ActionItem
