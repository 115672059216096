import styled from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import DocumentImagePreview from 'builder/components/DocumentImagePreview'

export const Container = styled.aside`
  width: 352px;
  height: 333px;
  position: relative;
  border-radius: 8px;
  flex-shrink: 0;
  background: ${Colors.Blue10};
  text-align: center;
`

export const Title = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Medium};
  margin-left: auto;
  margin-right: auto;
  width: 320px;
  padding-top: 24px;
  color: ${Colors.Blue80};
`

export const ImagePreview = styled(DocumentImagePreview)<{ isResumeTroubled?: boolean }>`
  border-radius: 3px;
  height: 210px;
  width: 232.0000457763672px;
  left: ${props => (props.isResumeTroubled ? '40' : '60')}px;
  top: 10px;
  z-index: 2;
`

export const ImageBack = styled.img`
  position: absolute;
  left: 89px;
  top: 117px;
  width: auto;
  height: auto;
  z-index: 1;
`

export const OptimizeScoreContainer = styled.div`
  position: absolute;
  z-index: 2;
  left: 262px;
  top: 150px;
  width: auto;
  height: auto;
`

export const Score = styled.div<{ bgColor: string }>`
  padding: 2px 8px 0px 8px;
  display: flex;
  align-items: center;
  background: ${props => props.bgColor};
  border-radius: 8px;
  color: ${Colors.White};
  z-index: 3;
`

export const ScoreNumber = styled.span`
  ${Typography.TextScore}
  ${FontWeights.DemiBold}
`

export const ScorePercent = styled.span`
  ${Typography.Subhead}
  ${FontWeights.DemiBold}
`

export const Gradient = styled.div`
  width: 352px;
  height: 101.2px;
  position: absolute;
  top: 232px;
  background: linear-gradient(
    to bottom,
    ${hexToRgba(Colors.Blue10, 0)},
    ${Colors.Blue10} 100%,
    ${Colors.Blue10} 0%
  );
  border-radius: 8px;
  z-index: 15;
`

export const Button = styled.a<{ left?: number }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  position: absolute;
  width: fit-content;
  height: 48px;
  left: ${props => (props.left ? props.left : '91')}px;
  top: 261px;
  background: ${Colors.Blue50};
  border-radius: 4px;
  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${Colors.White};
  z-index: 16;
`
