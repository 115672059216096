import { useI18n } from 'builder/hooks/useI18n'
import * as Styled from './styles'

type Props = {
  email: string
  onAgree: () => void
}

export const Warning = ({ email, onAgree }: Props) => {
  const { i18n } = useI18n()

  const [login, domain] = email.split('@')

  return (
    <>
      {i18n.t('builder.sign_up.did_you_mean')}
      {` `}
      <Styled.WarningEmail onClick={onAgree}>
        {login}@<b>{domain}</b>
      </Styled.WarningEmail>
      ?
    </>
  )
}
