import { useCallback } from 'react'
import { capitalize } from 'lodash'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import {
  Skill,
  Label,
  actions,
  selectors,
  SearchQuery,
  SalaryParams,
  CareerCardType,
  CareerPathStep,
  CareerPathPayloadType,
} from 'builder/modules/careerPath2'

import { Container } from './styles'
import { Header } from './components/Header'
import { PathDetails } from './components/PathDetails'

type Props = {
  cardData: CareerCardType
  titles: CareerPathPayloadType[]
}

export const CompletedPathCard = ({ titles, cardData }: Props) => {
  const dispatch = useDispatch()
  const occupation = useTypedSelector(selectors.occupation)

  const startingTitle = cardData.title
  const level1Title = cardData.pathways.path1[0]?.title || ''
  const level2Title = cardData.pathways.path1[1]?.title || ''
  const level3Title = cardData.pathways.path1[2]?.title || ''

  const setCareerPath = useCallback(() => {
    switch (cardData.pathways.path1.length) {
      case 1: {
        dispatch(actions.setCareerPath1Level1({ ...cardData.pathways.path1[0], isEditing: true }))
        break
      }
      case 2: {
        dispatch(actions.setCareerPath1Level1({ ...cardData.pathways.path1[0], isEditing: true }))
        dispatch(actions.setCareerPath1Level2({ ...cardData.pathways.path1[1], isEditing: true }))
        break
      }
      case 3: {
        dispatch(actions.setCareerPath1Level1({ ...cardData.pathways.path1[0], isEditing: true }))
        dispatch(actions.setCareerPath1Level2({ ...cardData.pathways.path1[1], isEditing: true }))
        dispatch(actions.setCareerPath1Level3({ ...cardData.pathways.path1[2], isEditing: true }))
        break
      }
    }
  }, [dispatch, cardData.pathways.path1])

  const handleDeleteCard = useCallback(
    (e: React.MouseEvent, id: number) => {
      e.stopPropagation()
      dispatch(actions.setPathsCardIdToDelete(id))
      dispatch(actions.setDeletePathsModalStatus(true))
    },
    [dispatch],
  )

  const handleEditCard = useCallback(() => {
    const skills: Skill[] = cardData.skills.map((skill: string) => ({
      label: capitalize(skill),
      value: skill.toLowerCase(),
    }))

    dispatch(actions.setStep(CareerPathStep.CHANGE_MY_PATH))
    dispatch(actions.setPrevStep(CareerPathStep.VIEW_CARDS))
    dispatch(actions.setEditCareerCardData(cardData))
    dispatch(actions.setIsEditingNewAdded(occupation === startingTitle))
    dispatch(actions.setOccupation(startingTitle))
    dispatch(actions.setSkills(skills))
    dispatch(actions.setAddToPathJobTitle(level1Title))
    dispatch(actions.setSelectedJobTitle(level1Title))
    setCareerPath()

    const queryParams: SearchQuery = {
      query: level1Title,
      location: null,
    }

    const salaryParams: SalaryParams = {
      title: level1Title,
      location: null,
    }

    dispatch(actions.fetchJobsRequest())
    dispatch(actions.fetchRecommendedJobCard(queryParams))
    dispatch(actions.fetchSalaryDetails(salaryParams))
    dispatch(actions.setAddToPathJobTitle(level1Title))
    dispatch(actions.fetchCareerPagesJobDetails(level1Title))
    dispatch(actions.fetchCourses({ title: level1Title, limit: 12 }))
    dispatch(actions.fetchJobDemand({ job_title: level1Title, location: '' }))

    trackInternalEvent('click_edit_button', {
      ...Label,
      level1_title: level1Title,
      level2_title: level2Title,
      level3_title: level3Title,
      starting_title: startingTitle,
    })
  }, [
    dispatch,
    cardData,
    occupation,
    level1Title,
    level2Title,
    level3Title,
    startingTitle,
    setCareerPath,
  ])

  return (
    <Container onClick={handleEditCard}>
      <Header {...{ cardData, handleDeleteCard }} />
      <PathDetails titles={titles} />
    </Container>
  )
}
