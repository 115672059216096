import { ContinueButton } from '../ContinueButton'
import GroupURL from './assets/Groups.svg'
import { Container, Content, ButtonContainer, Heading, SubHeader, CareerEntryImage } from './styles'

interface CareerEntryProps {
  continueNext: () => void
}

const CareerEntry = ({ continueNext }: CareerEntryProps) => {
  return (
    <Container>
      <CareerEntryImage src={GroupURL} />
      <Content>
        <Heading>Match with a coach to reach your professional goals</Heading>
        <SubHeader>
          Fill out the onboarding form to help us match you with a career coach experienced in your
          industry and goals. Afterward, we’ll show you a list of coaches aligned with your needs
          and you can choose the right one for you.
        </SubHeader>
      </Content>
      <ButtonContainer>
        <ContinueButton {...{ continueNext }} />
      </ButtonContainer>
    </Container>
  )
}

export default CareerEntry
