import Colors from 'builder/styles/colors'
import * as Styled from './styles'
import UploadImg from './assets/img/UploadImg'

interface Props {
  score: number
}

const ProgressBar = ({ score }: Props) => {
  return (
    <svg viewBox="-4 -5 36 36" width={272} height={272}>
      <path
        strokeWidth="0.1"
        d="M13 -2.6
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        stroke="#eee"
        fill="none"
      />
      <UploadImg />
      <Styled.Circle
        fill="none"
        progressPercentage={score}
        d="M13 -2.6
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        stroke={`${Colors.Blue50}`}
      />
    </svg>
  )
}

export default ProgressBar
