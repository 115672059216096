import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
} from 'react'
import { Transition, TransitionStatus } from 'react-transition-group'
import { i18n as I18n } from 'builder/utils/i18n'
import Icon from 'builder/components/Icon'
import Tooltip from 'builder/components/Tooltip'
import { Container, Input, IconBox, Panel, TextBox, Text, Bar } from './styles'
import { EditorTitleProps } from './types'

const EditorTitle: React.FC<EditorTitleProps> = ({ value, onChange }) => {
  const [inputValue, setInputValue] = useState(value)
  const [isEditIconVisible, setIsEditIconVisible] = useState<boolean>(false)
  const [prevValue, setPrevValue] = useState<string | null>(null)
  const [isFocused, setIsFocused] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (prevValue !== value) {
      setInputValue(value)
      setPrevValue(value)
    }
    if (value.includes(I18n.t('builder.resume_editor.untitled'))) {
      setIsEditIconVisible(true)
    } else {
      setIsEditIconVisible(false)
    }
  }, [value, prevValue])

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }, [])

  const handleFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      setIsFocused(false)
      setPrevValue(null)
      const trimmedValue = e.target.value.trim()
      onChange(trimmedValue)
    },
    [onChange],
  )

  const handleKeyUp = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      (e.target as HTMLInputElement).blur()
    }
  }, [])

  const handleEditClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus()
      inputRef.current.select()
    }
  }, [])

  return (
    <Container>
      <Panel>
        <TextBox>
          <Input
            value={inputValue}
            placeholder={I18n.t('builder.resume_editor.untitled')}
            ref={inputRef}
            onKeyUp={handleKeyUp}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          <Transition in={isFocused} timeout={100}>
            {(state: TransitionStatus) => <Bar in={state} />}
          </Transition>
          <Text>{inputValue}</Text>
        </TextBox>
        <IconBox onClick={handleEditClick} isEditIconVisible={isEditIconVisible}>
          <Tooltip value={I18n.t('builder.dashboard.rename')}>
            <Icon.Edit />
          </Tooltip>
        </IconBox>
      </Panel>
    </Container>
  )
}

export default EditorTitle
